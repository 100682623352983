

import * as Locale from "../../libraries/Locale.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as TeamTypes from "./TeamTypes.mjs";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              teams: field.required("teams", JsonDecode.array(TeamTypes.Index.Team.decode))
            };
    });

function index(wire) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Team",
                    VAL: "Index"
                  }
                }), decode);
}

var decode$1 = JsonDecode.object(function (field) {
      return {
              allowedForemen: field.required("allowedForemen", JsonDecode.array(TeamTypes.Edit.$$Worker.decode)),
              allowedWorkers: field.required("allowedWorkers", JsonDecode.array(TeamTypes.Edit.$$Worker.decode)),
              availableTerritories: field.required("availableTerritories", JsonDecode.array(TeamTypes.Edit.Territory.decode))
            };
    });

var decode$2 = JsonDecode.object(function (field) {
      return {
              team: field.required("team", TeamTypes.Edit.Team.decode),
              allowedForemen: field.required("allowedForemen", JsonDecode.array(TeamTypes.Edit.$$Worker.decode)),
              allowedWorkers: field.required("allowedWorkers", JsonDecode.array(TeamTypes.Edit.$$Worker.decode)),
              availableTerritories: field.required("availableTerritories", JsonDecode.array(TeamTypes.Edit.Territory.decode))
            };
    });

var decode$3 = JsonDecode.object(function (field) {
      return {
              team: field.required("team", TeamTypes.Edit.Team.decode)
            };
    });

var $$Response = {};

var Delete = {
  $$Response: $$Response
};

function new_(wire) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Team",
                    VAL: "New"
                  }
                }), decode$1);
}

function show(wire, uuid) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Team",
                    VAL: {
                      NAME: "Edit",
                      VAL: uuid
                    }
                  }
                }), decode$2);
}

function create(wire, team) {
  return Backend.decode(Backend.post(undefined, undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Team",
                    VAL: "Index"
                  }
                }, {
                  NAME: "Obj",
                  VAL: {
                    name: team.name,
                    description: Js_null.fromOption(team.description),
                    foreman_uuid: Js_null.fromOption(team.foremanUuid),
                    worker_uuids: team.workerUuids,
                    skill_uuids: team.skillUuids,
                    territory_uuid: Js_null.fromOption(team.territoryUuid),
                    temporary_workers: team.temporaryWorkers.map(function (w) {
                          return {
                                  uuid: Js_null.fromOption(w.uuid),
                                  userUuid: w.userUuid,
                                  dateFrom: Locale.T.isoDate(w.dateFrom),
                                  dateTo: Locale.T.isoDate(w.dateTo)
                                };
                        })
                  }
                }), decode$3);
}

function update(wire, uuid, team) {
  return Backend.decode(Backend.patch(undefined, undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Team",
                    VAL: {
                      NAME: "Edit",
                      VAL: uuid
                    }
                  }
                }, {
                  NAME: "Obj",
                  VAL: {
                    name: team.name,
                    description: Js_null.fromOption(team.description),
                    foreman_uuid: Js_null.fromOption(team.foremanUuid),
                    worker_uuids: team.workerUuids,
                    skill_uuids: team.skillUuids,
                    territory_uuid: Js_null.fromOption(team.territoryUuid),
                    temporary_workers: team.temporaryWorkers.map(function (w) {
                          return {
                                  uuid: Js_null.fromOption(w.uuid),
                                  user_uuid: w.userUuid,
                                  date_from: Locale.T.isoDate(w.dateFrom),
                                  date_to: Locale.T.isoDate(w.dateTo)
                                };
                        })
                  }
                }), decode$3);
}

function destroy(wire, uuid) {
  return Backend.$$delete(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "Team",
                VAL: {
                  NAME: "Edit",
                  VAL: uuid
                }
              }
            });
}

var Index_Response = {};

var Index = {
  $$Response: Index_Response,
  index: index
};

var Edit_New = {
  $$Response: {}
};

var Edit_Show = {
  $$Response: {}
};

var Edit_Post = {
  $$Response: {}
};

var Edit = {
  New: Edit_New,
  Show: Edit_Show,
  Post: Edit_Post,
  Patch: undefined,
  Delete: Delete,
  new_: new_,
  show: show,
  create: create,
  update: update,
  destroy: destroy
};

export {
  Index ,
  Edit ,
}
/* decode Not a pure module */
