

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LabeledModuleCss from "/home/runner/work/planado/planado/client/rescript/components/inputs/Labeled.module.css";

var styles = LabeledModuleCss;

function Labeled(Props) {
  var label = Props.label;
  var id = Props.id;
  var children = Props.children;
  var tmp = {
    className: styles.label
  };
  if (id !== undefined) {
    tmp.htmlFor = Caml_option.valFromOption(id);
  }
  return React.createElement("div", {
              className: styles.group
            }, React.createElement("label", tmp, label), children);
}

var make = Labeled;

export {
  make ,
}
/* styles Not a pure module */
