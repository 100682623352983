import React, { Component } from 'react'
import classNames from 'classnames'

export default class Footer extends Component {
  componentDidMount() {
    const {
      actions: { hidePopover }
    } = this.props
    let exceptClicked = false

    this.container.addEventListener(
      'click',
      _evt => {
        exceptClicked = true
      },
      false
    )

    document.addEventListener(
      'click',
      _evt => {
        if (!exceptClicked) {
          hidePopover()
        }

        exceptClicked = false
      },
      false
    )
  }

  render() {
    const { popovers } = this.props

    return (
      <div>
        <div ref={el => (this.container = el)}>
          {popovers.map(({ targetId, node, position, modal }) => {
            const classes = classNames({
              'p-popover': !modal,
              'dict-modal': modal,
              [`popover-for__${targetId}`]: !modal,
              [`dict-modal-for__${targetId}`]: modal,
              bottom: !modal
            })

            return (
              <div
                key={targetId}
                className={classes}
                style={modal ? {} : { ...position }}
                onClick={e => {
                  e.stopPropagation()
                }}
              >
                {node}
              </div>
            )
          })}
        </div>

        {popovers.some(p => p.modal) ? (
          <div className="p-popover__overlay" />
        ) : null}
      </div>
    )
  }
}
