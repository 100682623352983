

import * as Units from "../../../../../../../../../types/Units.mjs";
import * as React from "react";
import * as DayTimeline_IntervalModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/day_timeline/components/timeline/bar/components/interval/DayTimeline_Interval.module.css";

var styles = DayTimeline_IntervalModuleCss;

function make(param) {
  return {
          left: Units.Px.toString(param.left),
          width: Units.Px.toString(Units.Px.max(param.width, Units.Px.fromInt(2)))
        };
}

function DayTimeline_Interval(Props) {
  var position = Props.position;
  var style = make(position);
  return React.createElement("div", {
              className: styles.interval,
              style: style
            });
}

var make$1 = React.memo(DayTimeline_Interval);

export {
  make$1 as make,
}
/* styles Not a pure module */
