import React from 'react'
import classNames from 'classnames'

import CurrentJobsLink from 'planado/containers/map/current_jobs_link'
import Checkbox from 'planado/components/input/checkbox'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import * as styles from './styles.module.css'

const statuses = [
  'scheduled',
  'published',
  'en_route',
  'started',
  'suspended',
  'finished'
]

const StatusFilter = withContext(({ onToggle, status, checked, ctx }) => {
  return (
    <li>
      <Checkbox
        onToggle={() => onToggle(status)}
        checked={checked}
        invert
        label={ctx.t('js.map.job_filter.' + status)}
        wrapperClasses={{ 'react-checkbox_map': true }}
      />
    </li>
  )
})

const StatusesFilter = props => {
  return (
    <ul className={styles.jobStatus}>
      {statuses.map(s => (
        <StatusFilter
          key={s}
          checked={!props.forceUncheck && props.checked[s]}
          status={s}
          onToggle={props.onToggle}
        />
      ))}
    </ul>
  )
}

class JobFilter extends React.Component {
  get statusFilter() {
    if (this.props.open) {
      return (
        <StatusesFilter
          onToggle={this.props.onStatusToggle}
          forceUncheck={!this.props.showJobs}
          checked={this.props.checkedStatuses}
        />
      )
    } else {
      return <div />
    }
  }

  render() {
    const { ctx, onToggle, showJobs, onFilterToggle, open } = this.props

    const togglerClass = classNames({
      fa: true,
      'fa-chevron-down': !open,
      'fa-chevron-up': open,
      [styles.filterToggler]: true
    })

    return (
      <div className={styles.jobFilter}>
        <div className={togglerClass} onClick={onFilterToggle} />
        <Checkbox
          onToggle={onToggle}
          label={ctx.t('js.map.job_filter.show_jobs')}
          invert
          checked={showJobs}
          wrapperClasses={{ 'react-checkbox_map': true }}
        />
        {this.statusFilter}
        {showJobs ? <CurrentJobsLink /> : null}
      </div>
    )
  }
}

export default withContext(JobFilter)
