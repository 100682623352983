

import Viewport_link from "planado/containers/map/viewport_link";

var make = Viewport_link;

export {
  make ,
}
/* make Not a pure module */
