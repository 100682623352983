

import * as RCore from "../../../../../libraries/RCore.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Schedule_State from "../../../lib/state/Schedule_State.mjs";
import * as Schedule_Types_Job from "../../../lib/types/job/Schedule_Types_Job.mjs";

function initial(jobs) {
  return {
          all: jobs.length,
          published: 0,
          enRoute: 0,
          started: 0,
          suspended: 0,
          successfullyFinished: 0,
          unsuccessfullyFinished: 0
        };
}

function make(jobs) {
  return RCore.$$Array.reduce(jobs, initial(jobs), (function (stats, job) {
                var match = Schedule_Types_Job.AssignedJob.status(job);
                var match$1 = Schedule_Types_Job.AssignedJob.resolution(job);
                switch (match) {
                  case "Published" :
                      return {
                              all: stats.all,
                              published: stats.published + 1 | 0,
                              enRoute: stats.enRoute,
                              started: stats.started,
                              suspended: stats.suspended,
                              successfullyFinished: stats.successfullyFinished,
                              unsuccessfullyFinished: stats.unsuccessfullyFinished
                            };
                  case "EnRoute" :
                      return {
                              all: stats.all,
                              published: stats.published,
                              enRoute: stats.enRoute + 1 | 0,
                              started: stats.started,
                              suspended: stats.suspended,
                              successfullyFinished: stats.successfullyFinished,
                              unsuccessfullyFinished: stats.unsuccessfullyFinished
                            };
                  case "Started" :
                      return {
                              all: stats.all,
                              published: stats.published,
                              enRoute: stats.enRoute,
                              started: stats.started + 1 | 0,
                              suspended: stats.suspended,
                              successfullyFinished: stats.successfullyFinished,
                              unsuccessfullyFinished: stats.unsuccessfullyFinished
                            };
                  case "Suspended" :
                      return {
                              all: stats.all,
                              published: stats.published,
                              enRoute: stats.enRoute,
                              started: stats.started,
                              suspended: stats.suspended + 1 | 0,
                              successfullyFinished: stats.successfullyFinished,
                              unsuccessfullyFinished: stats.unsuccessfullyFinished
                            };
                  case "Finished" :
                      if (match$1 !== undefined) {
                        if (match$1.successful) {
                          return {
                                  all: stats.all,
                                  published: stats.published,
                                  enRoute: stats.enRoute,
                                  started: stats.started,
                                  suspended: stats.suspended,
                                  successfullyFinished: stats.successfullyFinished + 1 | 0,
                                  unsuccessfullyFinished: stats.unsuccessfullyFinished
                                };
                        } else {
                          return {
                                  all: stats.all,
                                  published: stats.published,
                                  enRoute: stats.enRoute,
                                  started: stats.started,
                                  suspended: stats.suspended,
                                  successfullyFinished: stats.successfullyFinished,
                                  unsuccessfullyFinished: stats.unsuccessfullyFinished + 1 | 0
                                };
                        }
                      } else {
                        return stats;
                      }
                  default:
                    return stats;
                }
              }));
}

function use() {
  var jobs = Schedule_State.useAssignedJobs();
  var match = React.useState(function () {
        return make(jobs);
      });
  var setStats = match[1];
  var stats = match[0];
  React.useEffect((function () {
          var nextStats = make(jobs);
          if (Caml_obj.notequal(nextStats, stats)) {
            setStats(function (param) {
                  return nextStats;
                });
          }
          
        }), [jobs]);
  return stats;
}

export {
  initial ,
  make ,
  use ,
}
/* react Not a pure module */
