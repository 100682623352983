// extracted by mini-css-extract-plugin
export var addButton = "addButton-A1eDeg8GCDorz_CMFdPo";
export var button = "button-z2hzGX9C_6AuC2DcS2yv";
export var buttonInvisible = "buttonInvisible-FqAdMICL3xnuovpqJpZW";
export var contactName = "contactName-uh32ruzoIuxdbHJiWGZL";
export var hint = "hint-TZXPEWx_56J_ZtSgqhHc";
export var primary = "primary-TBcmyjEKp5iPTlQHVxrk";
export var primaryLabel = "primaryLabel-t4GmvVGQ0zHwHQq1o8nY";
export var title = "title-YHZ2n3wAhAV3syzHbKiz";
export var trashIcon = "trashIcon-NSWBWtLyOK2gMRnilCdo";
export var wrapper = "wrapper-O_IzWQjTiqZCMdwRzgWR";
export var wrapperPrimary = "wrapperPrimary-uCi36fkhBCBjOLmmUgfM";