

import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";

var txh = Utils.Translations.txh;

var message$p = Utils.Translations.th("js.black_hole.blocked.message_html");

function messageWhiteLabel$p(name, ctx) {
  return txh("js.black_hole.blocked.message_html_white_label")({
              name: name
            }, ctx);
}

function AccessBlocked(Props) {
  var wire = Props.wire;
  var ctx = wire.ctx;
  var match = ctx.branding;
  var tmp;
  if (match !== undefined) {
    var brandName = match.brandName;
    tmp = brandName !== undefined ? messageWhiteLabel$p(brandName, ctx) : message$p(ctx);
  } else {
    tmp = message$p(ctx);
  }
  return React.createElement("div", {
              className: "access-disabled note note-info"
            }, tmp);
}

var make = AccessBlocked;

export {
  make ,
}
/* message' Not a pure module */
