

import * as Uuid from "../bindings/uuid/Uuid.mjs";
import * as RCore from "../libraries/RCore.mjs";
import * as React from "react";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as MomentRe from "../bindings/moment/MomentRe.mjs";
import * as Core__Int from "@rescript/core/lib/es6/src/Core__Int.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as JsonDecode from "../libraries/JsonDecode.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";

var equal = (function(i1, i2) { return i1 === i2 });

function map(f, value) {
  return f(value.toString());
}

var decode = Json_Decode$JsonCombinators.map(Json_Decode$JsonCombinators.$$float, (function (prim) {
        return prim;
      }));

function decodeOpaque(json) {
  return json;
}

function isNumeric(str) {
  var regex = /^[0-9]+$/;
  return regex.test(str);
}

function decode$1(pk) {
  if (isNumeric(pk)) {
    return {
            NAME: "BigInt",
            VAL: pk
          };
  } else if (Uuid.test(pk)) {
    return {
            NAME: "Uuid",
            VAL: Uuid.fromString(pk)
          };
  } else {
    return ;
  }
}

function encode(pk) {
  if (pk.NAME === "Uuid") {
    return Uuid.toString(pk.VAL);
  } else {
    return pk.VAL.toString();
  }
}

function toInt(day) {
  if (day === "Saturday") {
    return 6;
  } else if (day === "Tuesday") {
    return 2;
  } else if (day === "Thursday") {
    return 4;
  } else if (day === "Friday") {
    return 5;
  } else if (day === "Wednesday") {
    return 3;
  } else if (day === "Monday") {
    return 1;
  } else {
    return 0;
  }
}

function fromInt(day) {
  switch (day) {
    case 0 :
        return "Sunday";
    case 1 :
        return "Monday";
    case 2 :
        return "Tuesday";
    case 3 :
        return "Wednesday";
    case 4 :
        return "Thursday";
    case 5 :
        return "Friday";
    case 6 :
        return "Saturday";
    default:
      return ;
  }
}

var WeekDay = {
  toInt: toInt,
  fromInt: fromInt
};

var asSeconds = MomentRe.Duration.asSeconds;

var Duration = {
  asSeconds: asSeconds
};

var empty_date = null;

var empty_time = null;

var empty = {
  date: empty_date,
  time: empty_time
};

var JsDateTime = {
  empty: empty
};

function fromJs(dateTime) {
  return {
          date: Caml_option.null_to_opt(dateTime.date),
          time: Caml_option.null_to_opt(dateTime.time)
        };
}

function toJs(dateTime) {
  return {
          date: Js_null.fromOption(dateTime.date),
          time: Js_null.fromOption(dateTime.time)
        };
}

var DateTime_empty = {
  date: undefined,
  time: undefined
};

var DateTime = {
  empty: DateTime_empty,
  fromJs: fromJs,
  toJs: toJs
};

function fromString(string) {
  var time24hoursWithLeadingZero = /^((0[0-9]|1[0-9]|2[0-3]):[0-5][0-9])|(24:00)$/;
  if (!time24hoursWithLeadingZero.test(string)) {
    return ;
  }
  var numbers = RCore.$$Array.filterMap((function (__x) {
            return Js_string.split(":", __x);
          })(string), (function (x) {
          return Core__Int.fromString(x, undefined);
        }));
  return [
          numbers[0],
          numbers[1]
        ];
}

function addLeadingZero(number) {
  if (number < 10) {
    return "0" + number.toString();
  } else {
    return number.toString();
  }
}

function addTrailingZero(number) {
  if (number === 0) {
    return number.toString() + "0";
  } else {
    return number.toString();
  }
}

function unsafeFromPair(hours, mins) {
  return [
          hours,
          mins
        ];
}

function fromDuration(duration) {
  return [
          MomentRe.Duration.hours(duration),
          MomentRe.Duration.minutes(duration)
        ];
}

function fromPair(hours, mins) {
  if (hours >= 0 && hours <= 24 && mins >= 0 && mins <= 59) {
    return [
            hours,
            mins
          ];
  }
  
}

function encode$1(param) {
  return addLeadingZero(param[0]) + ":" + addTrailingZero(param[1]);
}

function toString(param) {
  var hours = param[0];
  var match = hours === 24 ? [
      addLeadingZero(0),
      addTrailingZero(0)
    ] : [
      addLeadingZero(hours),
      addTrailingZero(param[1])
    ];
  return match[0] + ":" + match[1];
}

function minutesFromStartOfDay(param) {
  return Math.imul(param[0], 60) + param[1] | 0;
}

function roundMinutes(param, ceilTo) {
  var mins = param[1];
  var remainder = Caml_int32.mod_(mins, ceilTo);
  return [
          param[0],
          mins - remainder | 0
        ];
}

function to24(time) {
  if (time[0] === 0 && time[1] === 0) {
    return [
            24,
            0
          ];
  } else {
    return time;
  }
}

function isEqual(param, param$1) {
  if (Caml_obj.equal(param[0], param$1[0])) {
    return Caml_obj.equal(param[1], param$1[1]);
  } else {
    return false;
  }
}

var decode$2 = JsonDecode.fromVariant(JsonDecode.string, fromString);

var HoursMinutes = {
  fromString: fromString,
  fromDuration: fromDuration,
  unsafeFromPair: unsafeFromPair,
  fromPair: fromPair,
  toString: toString,
  encode: encode$1,
  minutesFromStartOfDay: minutesFromStartOfDay,
  addLeadingZero: addLeadingZero,
  roundMinutes: roundMinutes,
  isEqual: isEqual,
  to24: to24,
  decode: decode$2
};

var Time = {
  WeekDay: WeekDay,
  Duration: Duration,
  JsDateTime: JsDateTime,
  DateTime: DateTime,
  HoursMinutes: HoursMinutes
};

var TemplateEvent = {};

var BackgroundTaskEvent = {};

function make(path) {
  return {
          uuid: Uuid.createV4(),
          path: path,
          state: "Sent",
          createdAt: new Date(),
          updatedAt: new Date()
        };
}

function update(state, r) {
  return {
          uuid: r.uuid,
          path: r.path,
          state: state,
          createdAt: r.createdAt,
          updatedAt: new Date()
        };
}

var $$Request = {
  make: make,
  update: update
};

var $$Event = {};

var Tracker = {
  $$Event: $$Event
};

var Socket = {};

function use() {
  return React.useRef(null);
}

function toOption(el) {
  return Caml_option.nullable_to_opt(el.current);
}

var ReactRef = {
  use: use,
  toOption: toOption
};

function Id_toString(prim) {
  return prim.toString();
}

function Id_toInt(prim) {
  return prim;
}

function Id_toUuid(prim) {
  return prim;
}

function Id_fromJson(prim) {
  return prim;
}

function Id_fromString(prim) {
  return prim;
}

function Id_fromNumber(prim) {
  return prim;
}

function Id_fromInt(prim) {
  return prim;
}

function Id_fromUuid(prim) {
  return prim;
}

var Id = {
  equal: equal,
  toString: Id_toString,
  toInt: Id_toInt,
  toUuid: Id_toUuid,
  fromJson: Id_fromJson,
  fromString: Id_fromString,
  fromNumber: Id_fromNumber,
  fromInt: Id_fromInt,
  fromUuid: Id_fromUuid,
  map: map,
  decode: decode,
  decodeOpaque: decodeOpaque
};

var PK = {
  decode: decode$1,
  encode: encode
};

var $$Symbol = {
  make: (function (prim) {
      return window.Symbol();
    })
};

export {
  Id ,
  PK ,
  Time ,
  TemplateEvent ,
  BackgroundTaskEvent ,
  $$Request ,
  Tracker ,
  Socket ,
  $$Symbol ,
  ReactRef ,
}
/* decode Not a pure module */
