

import * as El from "../../../../libraries/El.mjs";
import * as Icon from "../../../../Icon.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Billing_Components_PlanPrice from "./Billing_Components_PlanPrice.mjs";

function planName$p(param) {
  var suffix = param.suffix;
  var code = param.code;
  return function (ctx) {
    var name = Utils.Translations.t("js.billing.plan_name." + code)(ctx);
    if (suffix !== undefined) {
      return name + " (" + suffix + ")";
    } else {
      return name;
    }
  };
}

function Billing_Components_PlanHeader(Props) {
  var ctx = Props.ctx;
  var onMouseEnter = Props.onMouseEnter;
  var onMouseLeave = Props.onMouseLeave;
  var onClick = Props.onClick;
  var hoveredPlan = Props.hoveredPlan;
  var order = Props.order;
  var current = Props.current;
  var planType = Props.planType;
  var className = Billing_Components_PlanPrice.Utils.planClass(hoveredPlan, order, planType);
  return React.createElement("th", {
              className: className,
              onClick: onClick,
              onMouseEnter: onMouseEnter,
              onMouseLeave: onMouseLeave
            }, current ? React.createElement("span", undefined, React.createElement("i", {
                        className: Icon.style(undefined, undefined, "check")
                      }), El.space) : null, planName$p(planType.name)(ctx));
}

var make = Billing_Components_PlanHeader;

export {
  make ,
}
/* El Not a pure module */
