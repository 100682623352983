// extracted by mini-css-extract-plugin
export var addResolutionButton = "add-resolution-button-MqompOfyLHGTfv6WvHTT";
export var dropdown = "dropdown-REUFwTYTVyVG7ADv27gS";
export var dropdownListItem = "dropdown-list-item-NmQdVC3oK2k1sGiIepmB";
export var dropdownListItemEmpty = "dropdown-list-item-empty-BG9uQxs3oUKolpbSW6o3";
export var dropdownResolutionBlock = "dropdown-resolution-block-VmdBFH9Cd1L8hjGNbuCw";
export var dropdownResolutionList = "dropdown-resolution-list-Gr5NfTXqwlsYX5TioUXE";
export var dropdownResolutionsError = "dropdown-resolutions-error-LAaPC2uUzPVfBBeAJCDr";
export var dropdownWrapper = "dropdown-wrapper-UEgzxDCLFu6vCaJvAc6t";
export var heading = "heading-AqhC05JKnrpr2SHIlaiv";
export var headingWrapper = "heading-wrapper-for9IwMnRjJpyvnt_M_v";
export var hint = "hint-cACxDpP4ICZ32AgnEJhi";
export var isActive = "is-active-FG0uzHRM8L2Y2_CGUYEb";
export var noResolutions = "no-resolutions-bGBYOF9t206Ea7R37gpJ";
export var resolutionDelete = "resolution-delete-Z1Z8A1Rb3Wfw3hryV2y_";
export var resolutionDrag = "resolution-drag-UGdwtv3Sb317eq3aQ3O1";
export var resolutionItem = "resolution-item-WK3leoRjmzX2iBckU30k";
export var resolutionItemSuccessful = "resolution-item-successful-BIL1INknKJ817xM8xdbt";
export var resolutionListWrapper = "resolution-list-wrapper-lMKVVDmWICQnvtmu0UCz";
export var resolutionsFailure = "resolutions-failure-GT5MEShton9Mu9WXW6D9";
export var resolutionsHeading = "resolutions-heading-XwG9UUnYbxCSVcVOIU8l";
export var resolutionsSuccess = "resolutions-success-T1xFI0ODy3PWpqin2zR2";
export var selectResolutionsButton = "select-resolutions-button-SF_N2bpXQm1y1oVHIj85";
export var selectResolutionsButtonIcon = "select-resolutions-button-icon-A2UMB_dOUBdhLd9K9X6I";