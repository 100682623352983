import React from 'react'
import FormInput from 'planado/components/input/form_input'
import BaseFormInput from 'planado/components/input/base_form_input'
import Switch from 'planado/components/input/switch'
import Checkbox from 'planado/components/input/checkbox'
import { withContext } from 'planado/utils/contextConsumer.jsx'

const Resolution = withContext(
  ({
    name,
    isCommentRequired,
    isSystem,
    hasComment,
    onEditClick,
    onDeleteClick,
    ctx
  }) => {
    let commentRequiredText

    if (hasComment && isCommentRequired) {
      commentRequiredText = ctx.t('js.resolutions.list.comment.required')
    } else if (hasComment && !isCommentRequired) {
      commentRequiredText = ctx.t('js.resolutions.list.comment.optional')
    } else {
      commentRequiredText = ctx.t('js.resolutions.list.comment.none')
    }

    let buttonBlock

    if (isSystem) {
      buttonBlock = <div className="resolution-list-item-buttons" />
    } else {
      buttonBlock = (
        <div className="resolution-list-item-buttons">
          <span className="btn btn-default" onClick={onDeleteClick}>
            <span className="fa fa-trash-alt" />
          </span>

          <span className="btn btn-default" onClick={onEditClick}>
            <span className="fa fa-pencil-alt" />
          </span>
        </div>
      )
    }

    return (
      <div className="resolution-list-item resolution-list-item-show">
        <div className="resolution-list-item-name">{name}</div>

        <div className="resolution-list-item-note-required">
          {commentRequiredText}
        </div>

        {buttonBlock}
      </div>
    )
  }
)

const DeletedResolution = withContext(({ name, onRestoreClick, ctx }) => (
  <div className="resolution-list-item resolution-list-item-show resolution-list-item-show_deleted">
    <div className="resolution-list-item-name resolution-list-item-name_deleted">
      {name}
    </div>

    <div className="resolution-list-item-note-required">
      {ctx.t('js.resolutions.list.deleted')}
    </div>

    <div className="resolution-list-item-buttons">
      <span className="btn btn-default btn-sm" onClick={onRestoreClick}>
        {ctx.t('js.resolutions.list.restore')}
      </span>
    </div>
  </div>
))

const EditResolution = withContext(
  ({
    name,
    inputId,
    isCommentRequired,
    isSuccessful,
    hasComment,
    setResolution,
    onCancelClick,
    headerLabel,
    ctx
  }) => {
    let noteRequiredBlock

    if (hasComment) {
      noteRequiredBlock = (
        <BaseFormInput horizontal labelText="">
          <Checkbox
            checked={isCommentRequired}
            label={ctx.t('js.resolutions.form.has_comment.required')}
            onToggle={() =>
              setResolution('isCommentRequired', !isCommentRequired)
            }
          />
        </BaseFormInput>
      )
    } else {
      noteRequiredBlock = null
    }

    const isSuccessfulStates = [
      {
        name: ctx.t('js.resolutions.form.type.successful'),
        value: true
      },
      {
        name: ctx.t('js.resolutions.form.type.unsuccessful'),
        value: false
      }
    ]

    const hasNoteStates = [
      {
        name: ctx.t('js.resolutions.form.has_comment.yes'),
        value: true
      },
      {
        name: ctx.t('js.resolutions.form.has_comment.no'),
        value: false
      }
    ]

    return (
      <div className="resolution-list-item resolution-list-item-new">
        <span className="resolution-list-item_header">
          <span className="resolution-list-item_header_new">{headerLabel}</span>
          <a
            href="#"
            onClick={onCancelClick}
            className="resolution-list-item_header_close"
          >
            {ctx.t('js.resolutions.form.header.cancel')}
          </a>
        </span>

        <FormInput
          id={inputId('name')}
          type="text"
          labelText={ctx.t('js.resolutions.form.name')}
          value={name || ''}
          horizontal
          maxLength={100}
          autoFocus
          onValueChange={newValue => setResolution('name', newValue)}
        />

        <BaseFormInput
          id={inputId('is-successful')}
          horizontal
          labelText={ctx.t('js.resolutions.form.type.label')}
        >
          <Switch
            states={isSuccessfulStates}
            value={isSuccessful || false}
            autoWidth
            onValueChange={value => setResolution('isSuccessful', value)}
          />
        </BaseFormInput>

        <BaseFormInput
          id={inputId('has-note')}
          horizontal
          labelText={ctx.t('js.resolutions.form.has_comment.label')}
        >
          <Switch
            states={hasNoteStates}
            value={hasComment}
            autoWidth
            onValueChange={value => setResolution('hasComment', value)}
          />
        </BaseFormInput>

        {noteRequiredBlock}
      </div>
    )
  }
)

const RenderResolution = withContext(
  ({
    props: {
      name,
      hasComment,
      isSystem,
      isSuccessful,
      isCommentRequired,
      resolutionId,
      destroy,
      isEditing
    },
    inputId,
    deleteResolution,
    setResolution,
    restoreResolution,
    cancelAddResolution,
    editResolution,
    ctx
  }) => {
    const updateFunc = (key, value) => setResolution(resolutionId, key, value)

    if (destroy) {
      const onRestoreClick = () => restoreResolution(resolutionId)

      return (
        <DeletedResolution
          key={resolutionId}
          onRestoreClick={onRestoreClick}
          name={name}
        />
      )
    } else if (resolutionId > 0 && !isEditing) {
      const onDeleteClick = () => deleteResolution(resolutionId)

      const onEditClick = () => editResolution(resolutionId)

      return (
        <Resolution
          key={resolutionId}
          onDeleteClick={onDeleteClick}
          onEditClick={onEditClick}
          name={name}
          isCommentRequired={isCommentRequired}
          isSystem={isSystem}
          hasComment={hasComment}
        />
      )
    } else if (resolutionId > 0) {
      const headerLabel = ctx.t('js.resolutions.form.header.edit')

      const onCancelClick = evt => {
        evt.preventDefault()

        restoreResolution(resolutionId)
      }

      return (
        <EditResolution
          key={resolutionId}
          inputId={inputId}
          headerLabel={headerLabel}
          setResolution={updateFunc}
          onCancelClick={onCancelClick}
          name={name}
          isCommentRequired={isCommentRequired}
          isSuccessful={isSuccessful}
          hasComment={hasComment}
        />
      )
    } else {
      const headerLabel = ctx.t('js.resolutions.form.header.add')

      const onCancelClick = evt => {
        evt.preventDefault()

        cancelAddResolution(resolutionId)
      }

      return (
        <EditResolution
          key={resolutionId}
          inputId={inputId}
          headerLabel={headerLabel}
          setResolution={updateFunc}
          onCancelClick={onCancelClick}
          name={name}
          isCommentRequired={isCommentRequired}
          isSuccessful={isSuccessful}
          hasComment={hasComment}
        />
      )
    }
  }
)

export default withContext(
  ({
    resolutions,
    deleteResolution,
    addResolution,
    setResolution,
    restoreResolution,
    cancelAddResolution,
    editResolution,
    inputId,
    formDisabled,
    ctx
  }) => {
    const renderFunc = props => (
      <RenderResolution
        {...{
          props,
          inputId,
          deleteResolution,
          setResolution,
          restoreResolution,
          cancelAddResolution,
          editResolution
        }}
      />
    )
    const wasSuccessful = resolution =>
      'initialValue' in resolution
        ? resolution.initialValue.isSuccessful
        : resolution.isSuccessful

    const successfulResolutions = resolutions
      .filter(x => wasSuccessful(x) && x.resolutionId > 0)
      .map(renderFunc)
    const unsuccessfulResolutions = resolutions
      .filter(x => !wasSuccessful(x) && x.resolutionId > 0)
      .map(renderFunc)
    const newResolutions = resolutions
      .filter(x => x.resolutionId < 0)
      .map(renderFunc)

    const onAddButtonClick = e => {
      e.preventDefault()

      addResolution()
    }

    return (
      <div className="resolution-list">
        <div className="resolution-list-item resolution-list-heading resolution-list-heading_successful">
          <span className="text-semibold">
            {ctx.t('js.resolutions.list.successful')}
          </span>
        </div>

        {successfulResolutions}

        <div className="resolution-list-item resolution-list-heading resolution-list-heading_unsuccessful">
          <span className="text-semibold">
            {ctx.t('js.resolutions.list.unsuccessful')}
          </span>
        </div>

        {unsuccessfulResolutions}

        {newResolutions}

        <div className="resolution-list-item resolution-list-add-button">
          <button
            className="btn-link add-link"
            type="button"
            onClick={onAddButtonClick}
            disabled={formDisabled}
          >
            <span className="fa fa-plus" />
            &nbsp;
            {ctx.t('js.resolutions.list.add_resolution')}
          </button>
        </div>
      </div>
    )
  }
)
