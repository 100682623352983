import React from 'react'
import { Select } from 'planado/components/input'
import Spinner from 'planado/components/input/spinner'
import { withContext } from 'planado/utils/contextConsumer.jsx'

const Interval = withContext(
  ({
    id,
    name,
    onPeriodChange,
    onPeriodCountChange,
    period,
    periodCount,
    ctx
  }) => {
    const INTERVAL_OPTIONS = [
      { value: 'day', label: ctx.t('js.recurring_jobs.intervals.day') },
      { value: 'week', label: ctx.t('js.recurring_jobs.intervals.week') },
      { value: 'month', label: ctx.t('js.recurring_jobs.intervals.month') },
      { value: 'year', label: ctx.t('js.recurring_jobs.intervals.year') }
    ]

    return (
      <div className="interval">
        <label className="interval__label" htmlFor={id}>
          {ctx.t('js.recurring_jobs.labels.interval')}
        </label>

        <div className="interval__inputs">
          <div className="interval__period-count">
            <Spinner
              onValueChange={v => onPeriodCountChange(v)}
              name={name.periodCount}
              min={1}
              value={periodCount}
              dom={ctx.dom}
              id={id}
            />
          </div>

          <div className="interval__period">
            <Select
              name={name.period}
              clearable={false}
              onChange={value => onPeriodChange(value)}
              value={period}
              options={INTERVAL_OPTIONS}
            />
          </div>
        </div>
      </div>
    )
  }
)

export default Interval
