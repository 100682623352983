

import * as El from "../../../../../libraries/El.mjs";
import * as Fun from "../../../../../libraries/Fun.mjs";
import * as RCore from "../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../types/Types.mjs";
import * as Units from "../../../../../types/Units.mjs";
import * as Utils from "../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../../../../common/Optional.mjs";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_DND from "../../../lib/Schedule_DND.mjs";
import * as Types_Territory from "../../../../../types/Types_Territory.mjs";
import * as Schedule_Popover from "../../popovers/popover/Schedule_Popover.mjs";
import * as Schedule_Constants from "../../../lib/Schedule_Constants.mjs";
import * as Schedule_Types_Job from "../../../lib/types/job/Schedule_Types_Job.mjs";
import * as Schedule_JobPreview from "../../popovers/job_preview/Schedule_JobPreview.mjs";
import * as MultipleAssigneesUtils from "../../../../../types/MultipleAssigneesUtils.mjs";
import * as Schedule_TimelineHooks from "../../../lib/hooks/Schedule_TimelineHooks.mjs";
import * as Schedule_Types_JobCore from "../../../lib/types/job/Schedule_Types_JobCore.mjs";
import * as Schedule_TimelineJobModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/timeline/job/Schedule_TimelineJob.module.css";

function use(elementRef, job, startedOutside, finishedOutside, draggableForAssignee) {
  var wire = AppContext.useWire();
  var canDrag = draggableForAssignee && Schedule_Types_Job.ScheduledJob.hasRightsToEdit(job, wire) && (!startedOutside || !finishedOutside);
  var draggableJob_uuid = Schedule_Types_Job.ScheduledJob.uuid(job);
  var draggableJob_template = Schedule_Types_Job.ScheduledJob.template(job);
  var draggableJob_assignees = Schedule_Types_Job.ScheduledJob.assignees(job);
  var draggableJob_address = Schedule_Types_Job.ScheduledJob.address(job);
  var draggableJob_scheduledAt = Schedule_Types_Job.ScheduledJob.scheduledAt(job);
  var draggableJob_duration = Schedule_Types_Job.ScheduledJob.duration(job);
  var draggableJob = {
    uuid: draggableJob_uuid,
    template: draggableJob_template,
    bar: undefined,
    assignees: draggableJob_assignees,
    address: draggableJob_address,
    scheduledAt: draggableJob_scheduledAt,
    duration: draggableJob_duration
  };
  var match = Schedule_DND.DND.Draggable.use(elementRef, canDrag, Schedule_DND.DND.Context.makePayload(draggableJob), Schedule_Types_Job.ScheduledJob.assignees(job).length > 0 ? Schedule_Constants.dndScheduledJob : Schedule_Constants.dndUnassignedJob, undefined);
  var dragRef = match[0];
  React.useEffect((function () {
          var dragElement = Types.ReactRef.toOption(dragRef);
          if (dragElement !== undefined) {
            Caml_option.valFromOption(dragElement).setAttribute("data-prevent-scroll", "true");
          }
          
        }), []);
  return [
          dragRef,
          match[1]
        ];
}

var styles = Schedule_TimelineJobModuleCss;

var minJobWidth = Units.Px.fromInt(30);

function byStatus(job) {
  var match = Schedule_Types_Job.ScheduledJob.status(job);
  switch (match) {
    case "EnRoute" :
        return styles.enRoute;
    case "Started" :
        return styles.started;
    case "Suspended" :
        return styles.suspended;
    case "Canceled" :
        return "";
    case "Finished" :
        var match$1 = Schedule_Types_Job.ScheduledJob.resolution(job);
        var className = match$1 !== undefined && match$1.successful ? styles.successful : styles.unsuccessful;
        return El.Cn.concat(styles.finished, className);
    default:
      return styles.posted;
  }
}

function byHeight(size) {
  switch (size) {
    case "Full" :
        return styles.full;
    case "Half" :
        return styles.half;
    case "OneThird" :
        return styles.oneThird;
    
  }
}

function byWidth(width) {
  if (Caml_obj.lessthan(width, minJobWidth)) {
    return styles.compact;
  } else {
    return "";
  }
}

function outer(job, size, startedOutside, finishedOutside, active, draggableForAssignee) {
  return El.Cn.concat(El.Cn.concat(El.Cn.concat(El.Cn.concat(El.Cn.concat(El.Cn.concat(styles.job, byHeight(size)), byStatus(job)), startedOutside ? styles.startedOutside : ""), finishedOutside ? styles.finishedOutside : ""), active ? styles.active : ""), draggableForAssignee ? "" : styles.notDraggable);
}

function inner(size, width) {
  return El.Cn.concat(El.Cn.concat(styles.inner, byHeight(size)), byWidth(width));
}

function get(job, size, width, startedOutside, finishedOutside, active, draggableForAssignee) {
  return [
          outer(job, size, startedOutside, finishedOutside, active, draggableForAssignee),
          inner(size, width)
        ];
}

function make(top, left, width) {
  return {
          left: Units.Px.toString(left),
          top: Units.Px.toString(top.contents),
          width: Units.Px.toString(width)
        };
}

function Schedule_TimelineJob$JobPreview(Props) {
  var close = Props.close;
  var element = Props.element;
  var clickCoordinate = Props.clickCoordinate;
  var job = Props.job;
  var clickTargetPosition_1 = element.getBoundingClientRect();
  var clickTargetPosition = {
    NAME: "DomRect",
    VAL: clickTargetPosition_1
  };
  return React.createElement(Schedule_Popover.make, {
              clickCoordinate: clickCoordinate,
              clickTargetPosition: clickTargetPosition,
              children: React.createElement(Schedule_JobPreview.make, {
                    job: job,
                    close: close
                  })
            });
}

var JobPreview = {
  make: Schedule_TimelineJob$JobPreview
};

var headline$p = Utils.Translations.tr("js.schedule.v2.job.headline");

var styles$1 = Schedule_TimelineJobModuleCss;

function Schedule_TimelineJob$JobTemplate(Props) {
  var job = Props.job;
  var match = AppContext.useWire();
  var title = Schedule_Types_Job.ScheduledJob.title(job);
  return React.createElement("div", {
              className: styles$1.template
            }, title !== undefined ? title : headline$p(match.ctx));
}

var minJobWidth$1 = Units.Px.fromInt(60);

var minJobWidthWithIcon = Units.Px.fromInt(30);

var styles$2 = Schedule_TimelineJobModuleCss;

function Schedule_TimelineJob$JobFooter$StatusIcon(Props) {
  var job = Props.job;
  var match = Schedule_Types_Job.ScheduledJob.status(job);
  var className;
  switch (match) {
    case "EnRoute" :
        className = styles$2.enRoute;
        break;
    case "Started" :
        className = styles$2.started;
        break;
    default:
      className = undefined;
  }
  if (className !== undefined) {
    return React.createElement(React.Fragment, undefined, React.createElement("div", {
                    className: El.Cn.concat(styles$2.iconBackground, className)
                  }), React.createElement("div", {
                    className: El.Cn.concat(styles$2.icon, className)
                  }));
  } else {
    return null;
  }
}

function Schedule_TimelineJob$JobFooter$Address(Props) {
  var job = Props.job;
  var address = Schedule_Types_Job.ScheduledJob.address(job);
  var territory = Schedule_Types_Job.ScheduledJob.territory(job);
  if (address !== undefined) {
    var address$1 = Caml_option.valFromOption(address);
    if (territory === undefined) {
      return React.createElement("div", {
                  className: styles$2.address
                }, Schedule_Types_JobCore.Address.toString(address$1));
    }
    var address$2 = Schedule_Types_JobCore.Address.toString(address$1);
    var territoryName = Types_Territory.name(territory);
    return React.createElement("div", {
                className: styles$2.address
              }, address$2 + " (" + territoryName + ")");
  }
  if (territory === undefined) {
    return null;
  }
  var territoryName$1 = Types_Territory.name(territory);
  return React.createElement("div", {
              className: styles$2.address
            }, territoryName$1);
}

function Schedule_TimelineJob$JobFooter(Props) {
  var job = Props.job;
  var width = Props.width;
  if (Caml_obj.lessthan(width, minJobWidth$1) && RCore.$$Option.isSome(Schedule_Types_Job.ScheduledJob.template(job))) {
    return React.createElement(Optional.make, {
                show: Caml_obj.greaterthan(width, minJobWidthWithIcon),
                children: React.createElement(Schedule_TimelineJob$JobFooter$StatusIcon, {
                      job: job
                    })
              });
  } else {
    return React.createElement(React.Fragment, undefined, React.createElement(Schedule_TimelineJob$JobFooter$Address, {
                    job: job
                  }), React.createElement(Schedule_TimelineJob$JobFooter$StatusIcon, {
                    job: job
                  }));
  }
}

function Schedule_TimelineJob(Props) {
  var elementRef = Props.elementRef;
  var job = Props.job;
  var size = Props.size;
  var top = Props.top;
  var left = Props.left;
  var width = Props.width;
  var startedOutside = Props.startedOutside;
  var finishedOutside = Props.finishedOutside;
  var onScrollStart = Props.onScrollStart;
  var bar = Props.bar;
  var wire = AppContext.useWire();
  var assignees = Schedule_Types_Job.ScheduledJob.assignees(job);
  var draggableForAssignee = MultipleAssigneesUtils.draggableForAssignee(assignees, bar, wire.ctx.features);
  var elementRef$1 = React.useMemo((function () {
          if (elementRef !== undefined) {
            return elementRef;
          } else {
            return React.createRef();
          }
        }), []);
  var match = use(elementRef$1, job, startedOutside, finishedOutside, draggableForAssignee);
  var match$1 = Schedule_TimelineHooks.JobComponentListeners.use(onScrollStart, elementRef$1, job);
  var clickCoordinate = match$1[0];
  var match$2 = get(job, size, width, startedOutside, finishedOutside, RCore.$$Option.isSome(clickCoordinate) || match[1], draggableForAssignee);
  var match$3 = Fun.both(clickCoordinate, Types.ReactRef.toOption(elementRef$1));
  var previewBlock = match$3 !== undefined ? React.createElement(Schedule_TimelineJob$JobPreview, {
          close: match$1[1],
          element: match$3[1],
          clickCoordinate: match$3[0],
          job: job
        }) : null;
  var style = make(top, left, width);
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  ref: elementRef$1,
                  className: match$2[0],
                  style: style
                }, React.createElement(Optional.make, {
                      show: !startedOutside,
                      children: React.createElement("div", {
                            className: match$2[1]
                          }, React.createElement(Schedule_TimelineJob$JobTemplate, {
                                job: job
                              }), React.createElement(Schedule_TimelineJob$JobFooter, {
                                job: job,
                                width: width
                              }))
                    })), previewBlock);
}

var make$1 = React.memo(Schedule_TimelineJob);

export {
  JobPreview ,
  make$1 as make,
}
/* styles Not a pure module */
