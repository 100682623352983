// extracted by mini-css-extract-plugin
export var btnMoreOptions = "btn-more-options-JQWDtdC1CgXNDo2ochVw";
export var clientFormHeading = "client-form-heading-XZzH379nq4tjvuEifMet";
export var clientFormHeadingRight = "client-form-heading-right-vnbfeJrvcC2m5rVZzNhT";
export var clientFormHeadingTitle = "client-form-heading-title-iupTG78Ou9NthCeiHLbu";
export var clientFormHeadingTitleDesc = "client-form-heading-title-desc-axwIzx81RoJEazdXSsaw";
export var clientFormHeadingTitleName = "client-form-heading-title-name-rDmproPSJTqt_VuvmIB7";
export var closeButton = "close-button-peIJxKK37aIojLZbg42h";
export var dropdown = "dropdown-YzzMIWYDM5yXjR5Y0MMw";
export var dropdownMenu = "dropdown-menu-buQSffrV7nBOKSDjuE5n";
export var dropdownOptions = "dropdown-options-j20RD9hE1HKcHwOdy3u7";
export var faCircle = "fa-circle-SYufwiIH8zvpxEYTMHp9";
export var opened = "opened-RhDV1PeS5gMYQU5CPuWx";