

import * as El from "../../../../libraries/El.mjs";
import * as Types from "../../../../types/Types.mjs";
import * as React from "react";
import * as Schedule_ButtonModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/button/Schedule_Button.module.css";

var styles = Schedule_ButtonModuleCss;

function Schedule_Button(Props) {
  var onClickOpt = Props.onClick;
  var activeOpt = Props.active;
  var classNameOpt = Props.className;
  var idOpt = Props.id;
  var disabledOpt = Props.disabled;
  var children = Props.children;
  var onClick = onClickOpt !== undefined ? onClickOpt : (function (prim) {
        
      });
  var active = activeOpt !== undefined ? activeOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var id = idOpt !== undefined ? idOpt : "";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var buttonRef = Types.ReactRef.use();
  var className$1 = El.Cn.concat(El.Cn.concat(styles.button, active ? styles.active : ""), className);
  return React.createElement("button", {
              ref: buttonRef,
              className: className$1,
              id: id,
              disabled: disabled,
              type: "button",
              onClick: onClick
            }, children);
}

var make = Schedule_Button;

export {
  make ,
}
/* styles Not a pure module */
