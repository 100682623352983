import React, { Component } from 'react'
import classNames from 'classnames'
import { withContext } from 'planado/utils/contextConsumer.jsx'
class JobsSearch extends Component {
  constructor(props) {
    super(props)

    this.state = {
      query: '',
      showNotFoundMessage: false
    }
  }

  get notFoundMessage() {
    return this.state.showNotFoundMessage ? (
      <p className="search-job__msg help-block">
        {this.props.ctx.t('js.jobs.index.job_not_found')}
      </p>
    ) : null
  }

  onKeyPress = e => {
    if (e.key === 'Enter' && this.state.query.trim().length > 0) {
      this.findJob()
    }
  }

  onBlur = () => {
    this.setState({
      showNotFoundMessage: false
    })
  }

  onChange = _ => {
    this.setState({
      query: this.input.value,
      showNotFoundMessage: false
    })
  }

  findJob = () => {
    const { query } = this.state

    if (query.length > 0) {
      this.props.onSearch(query, () => {
        this.setState({ showNotFoundMessage: true })
        this.input.focus()
      })
    }
  }

  render() {
    const { ctx } = this.props
    const { query, showNotFoundMessage } = this.state

    const classes = classNames({
      'search-job': true,
      'has-warning': showNotFoundMessage
    })

    return (
      <div className={classes}>
        <input
          className="search-job__input"
          ref={i => (this.input = i)}
          type="text"
          value={query}
          autoComplete="off"
          onKeyUp={this.onKeyPress}
          onChange={this.onChange}
          onBlur={this.onBlur}
          placeholder={ctx.t('js.jobs.placeholders.search_by_number')}
        />

        <button
          className="search-job__btn"
          onClick={() => this.findJob()}
          disabled={query.trim().length === 0}
        />

        {this.notFoundMessage}
      </div>
    )
  }
}

export default withContext(JobsSearch)
