import React, { Component, Fragment, useState, useEffect } from 'react'
import classNames from 'classnames'

import { updateFieldsErrors, validateField, isEmpty } from '../validators'
import { INPUT_MAX_LENGTH } from 'planado/constants/admin/templates'
import { Select } from 'planado/components/input'
import FormInput from 'planado/components/input/form_input'
import FileInput from 'planado/components/input/file_input'
import AutocompleteInput from './autocomplete'
import Hint from 'planado/components/hint'
import { SessionContext } from 'planado/utils/context.js'
import * as styles from './styles.module.css'
import CheckboxInput from 'planado/components/input/checkbox'

const updateCanValidate = props => state => {
  if (!state.canValidate && !state.menuIsOpen && !isEmpty(props.errors)) {
    return { canValidate: true }
  } else {
    return {}
  }
}

export class Input extends Component {
  constructor(props) {
    super(props)

    this.state = {
      canValidate: false,
      menuIsOpen: false
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(updateCanValidate(nextProps))
  }

  menuVisibilityChangeHandler(isOpen) {
    const {
      isCustomField,
      ctx,
      getPossibleFieldNames,
      focusHandler,
      dataType,
      name = ''
    } = this.props

    if (isOpen) {
      getPossibleFieldNames(ctx, isCustomField, 'input', name, dataType)
    }

    focusHandler(isOpen)
    this.setState({
      menuIsOpen: isOpen
    })
  }

  changeHandler(value) {
    const {
      setCustomField,
      idx,
      isCustomField,
      getPossibleFieldNames,
      dataType,
      ctx
    } = this.props

    getPossibleFieldNames(ctx, isCustomField, 'input', value, dataType)
    setCustomField(idx, 'name', value, isCustomField)

    if (this.state.canValidate) {
      validateField(value, 'input', this.props, ctx)
    }
  }

  selectHandler(value) {
    const { setCustomField, idx, isCustomField, ctx } = this.props

    setCustomField(idx, 'name', value, isCustomField)

    validateField(value, 'input', this.props, ctx)
  }

  blurHandler(value = '') {
    const { ctx } = this.props

    updateFieldsErrors('input', this.props)

    if (value.length > 0) {
      validateField(value, 'input', this.props, ctx)
    }
  }

  render() {
    const {
      idx,
      name,
      isCustomField,
      possibleFieldNames,
      errors,
      inputName,
      dataType
    } = this.props

    const section = isCustomField ? 'custom_fields' : 'report_fields'

    const placeholder = ctx => {
      switch (dataType) {
        case 'string':
          return ctx.t(
            `js.templates.custom_fields.placeholders.${section}.input`
          )
        case 'integer':
          return ctx.t(
            `js.templates.custom_fields.placeholders.${section}.integer`
          )
        case 'decimal':
          return ctx.t(
            `js.templates.custom_fields.placeholders.${section}.decimal`
          )
      }
    }

    const labelText = ctx => {
      switch (dataType) {
        case 'string':
          return ctx.t(`js.templates.custom_fields.labels.${section}.input`)
        case 'integer':
          return ctx.t(`js.templates.custom_fields.labels.${section}.integer`)
        case 'decimal':
          return ctx.t(`js.templates.custom_fields.labels.${section}.decimal`)
      }
    }

    const wrapperClass = {
      [styles.integerField]: dataType === 'integer',
      [styles.decimalField]: dataType === 'decimal',
      'pd-form-group_input': dataType === 'string'
    }

    return (
      <SessionContext.Consumer>
        {ctx => (
          <Fragment>
            <AutocompleteInput
              maxLength={INPUT_MAX_LENGTH}
              name={inputName(idx, 'name')}
              placeholder={placeholder(ctx)}
              labelText={labelText(ctx)}
              wrapperClass={wrapperClass}
              possibleValue={possibleFieldNames.input}
              value={name || ''}
              errors={errors}
              blurHandler={this.blurHandler.bind(this)}
              setValue={this.changeHandler.bind(this)}
              selectHandler={this.selectHandler.bind(this)}
              menuVisibilityChangeHandler={this.menuVisibilityChangeHandler.bind(
                this
              )}
            >
              {isCustomField ? null : (
                <Hint
                  content={ctx.t(
                    `js.templates.custom_fields.hints.${dataType}`
                  )}
                  modifiers={{ hint_label: true }}
                />
              )}
            </AutocompleteInput>
          </Fragment>
        )}
      </SessionContext.Consumer>
    )
  }
}

Input.defaultProps = {
  errors: []
}

export class File extends Component {
  constructor(props) {
    super(props)

    this.state = {
      canValidate: false,
      menuIsOpen: false
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(updateCanValidate(nextProps))
  }

  menuVisibilityChangeHandler(isOpen) {
    const {
      isCustomField,
      ctx,
      getPossibleFieldNames,
      focusHandler,
      name = ''
    } = this.props

    if (isOpen) {
      getPossibleFieldNames(ctx, isCustomField, 'file', name)
    }

    focusHandler(isOpen)
    this.setState({
      menuIsOpen: isOpen
    })
  }

  nameChangeHandler(value) {
    const {
      setCustomField,
      idx,
      isCustomField,
      getPossibleFieldNames,
      ctx
    } = this.props

    setCustomField(idx, 'name', value, isCustomField)
    getPossibleFieldNames(ctx, isCustomField, 'file', value)

    if (this.state.canValidate) {
      validateField(value, 'file', this.props, ctx)
    }
  }

  nameSelectHandler(value) {
    const { setCustomField, idx, isCustomField, ctx } = this.props

    setCustomField(idx, 'name', value, isCustomField)

    if (this.state.canValidate) {
      validateField(value, 'file', this.props, ctx)
    }
  }

  nameBlurHandler(value = '') {
    const { ctx } = this.props

    updateFieldsErrors('file', this.props)

    if (value.length > 0) {
      validateField(value, 'file', this.props, ctx)
    }
  }

  fileSelectedHandler(file) {
    const { idx, isCustomField, setCustomFieldFile } = this.props
    setCustomFieldFile(idx, file, isCustomField)
  }

  fileClearedHandler() {
    const { idx, isCustomField, setCustomFieldFile } = this.props
    setCustomFieldFile(idx, null, isCustomField)
  }

  render() {
    const {
      idx,
      name,
      value,
      inputName,
      errors,
      possibleFieldNames
    } = this.props

    return (
      <SessionContext.Consumer>
        {ctx => (
          <div className="pd-form-group-container">
            <AutocompleteInput
              maxLength={INPUT_MAX_LENGTH}
              name={inputName(idx, 'name')}
              placeholder={ctx.t(
                'js.templates.custom_fields.placeholders.file'
              )}
              labelText={ctx.t(
                'js.templates.custom_fields.labels.input_read_only'
              )}
              wrapperClass={{
                'pd-form-group-half': true,
                'pd-form-group_file': true
              }}
              possibleValue={possibleFieldNames.file}
              value={name || ''}
              errors={errors}
              blurHandler={this.nameBlurHandler.bind(this)}
              setValue={this.nameChangeHandler.bind(this)}
              selectHandler={this.nameSelectHandler.bind(this)}
              menuVisibilityChangeHandler={this.menuVisibilityChangeHandler.bind(
                this
              )}
            />

            <div className="pd-form-group pd-form-group-equal-size">
              <label className="pd-label">
                {ctx.t('js.templates.custom_fields.labels.file')}
              </label>

              <FileInput
                id={inputName(idx, 'value')}
                name={inputName(idx, 'value')}
                value={value}
                btnText={ctx.t('js.templates.custom_fields.buttons.file')}
                onFileSelected={this.fileSelectedHandler.bind(this)}
                onFileCleared={this.fileClearedHandler.bind(this)}
                onFileDelete={this.fileClearedHandler.bind(this)}
                limit={7 * 1024 * 1024}
                limitMsg={ctx.t('js.jobs.messages.payload_too_large')}
              />
            </div>
          </div>
        )}
      </SessionContext.Consumer>
    )
  }
}

File.defaultProps = {
  errors: []
}

export class Dictionary extends Component {
  constructor(props) {
    super(props)

    this.state = {
      canValidate: false
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(updateCanValidate(nextProps))
  }

  menuVisibilityChangeHandler(isOpen) {
    const {
      ctx,
      isCustomField,
      getPossibleFieldNames,
      focusHandler,
      name = ''
    } = this.props

    if (isOpen) {
      getPossibleFieldNames(ctx, isCustomField, 'dictionary', name)
    }

    focusHandler(isOpen)
  }

  nameChangeHandler(value) {
    const {
      setCustomField,
      idx,
      isCustomField,
      getPossibleFieldNames,
      ctx
    } = this.props

    setCustomField(idx, 'name', value, isCustomField)
    getPossibleFieldNames(ctx, isCustomField, 'dictionary', value)

    if (this.state.canValidate) {
      validateField(value, 'dictionary', this.props, ctx)
    }
  }

  nameSelectHandler(value) {
    const { setCustomField, idx, isCustomField, ctx } = this.props

    setCustomField(idx, 'name', value, isCustomField)

    if (this.state.canValidate) {
      validateField(value, 'dictionary', this.props, ctx)
    }
  }

  nameBlurHandler(value) {
    const { ctx } = this.props

    updateFieldsErrors('dictionary', this.props)

    if (value.length > 0) {
      validateField(value, 'dictionary', this.props, ctx)
    }
  }

  dictionaryChangeHandler = value => {
    const { setCustomField, errors, idx, isCustomField } = this.props

    setCustomField(idx, 'dictionaryUuid', value, isCustomField)

    const error = [errors[0] || [], []]
    setCustomField(idx, 'errors', error, isCustomField)
  }

  render() {
    const {
      idx,
      name,
      value,
      dictionaryUuid,
      dictionaries,
      isCustomField,
      inputName,
      possibleFieldNames,
      errors = []
    } = this.props

    const placeholder = ctx => {
      if (!isCustomField) {
        return ctx.t('js.templates.custom_fields.placeholders.dictionary_input')
      } else {
        return ctx.t(
          'js.templates.custom_fields.placeholders.dictionary_input_read_only'
        )
      }
    }

    const selectClasses = classNames({
      'pd-form-group': true,
      'pd-form-group-half': true,
      'pd-form-group_select_error': errors[1] && errors[1].length > 0
    })

    const dictionaryOptions = dictionaries.map(({ uuid, name }) => ({
      value: uuid,
      label: name
    }))

    return (
      <SessionContext.Consumer>
        {ctx => (
          <div className="pd-form-group-container">
            <select
              className="hidden"
              name={inputName(idx, 'value')}
              defaultValue={value}
            >
              <option value={value}>{value}</option>
            </select>

            <AutocompleteInput
              maxLength={INPUT_MAX_LENGTH}
              name={inputName(idx, 'name')}
              placeholder={placeholder(ctx)}
              labelText={ctx.t(
                'js.templates.custom_fields.labels.input_read_only'
              )}
              wrapperClass={{
                'pd-form-group-half': true,
                'pd-form-group_dictionary': true
              }}
              possibleValue={possibleFieldNames.dictionary}
              value={name || ''}
              errors={errors[0]}
              blurHandler={this.nameBlurHandler.bind(this)}
              setValue={this.nameChangeHandler.bind(this)}
              selectHandler={this.nameSelectHandler.bind(this)}
              menuVisibilityChangeHandler={this.menuVisibilityChangeHandler.bind(
                this
              )}
            >
              {!isCustomField ? (
                <Hint
                  content={ctx.t(
                    'js.templates.custom_fields.hints.dictionary_input'
                  )}
                  modifiers={{ hint_label: true }}
                />
              ) : null}
            </AutocompleteInput>

            <div className={selectClasses}>
              <label className="pd-label">
                {ctx.t('js.templates.custom_fields.labels.dictionary')}

                <Hint
                  content={ctx.t(
                    'js.templates.custom_fields.hints.dictionary_select'
                  )}
                  modifiers={{ hint_label: true }}
                />
              </label>

              <Select
                autosize={false}
                id={inputName(idx, 'dictionary')}
                name={inputName(idx, 'dictionary')}
                clearable={false}
                onChange={this.dictionaryChangeHandler}
                placeholder={ctx.t(
                  'js.templates.custom_fields.placeholders.dictionary_select'
                )}
                value={dictionaryUuid}
                options={dictionaryOptions}
              />

              {errors[1] && errors[1].length > 0 ? (
                <div className="pd-control-error">
                  {errors[1].map((x, idx) => (
                    <div key={idx}>{x}</div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        )}
      </SessionContext.Consumer>
    )
  }
}

Dictionary.defaultProps = {
  errors: []
}

export class Image extends Component {
  constructor(props) {
    super(props)

    this.state = {
      canValidate: false
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(updateCanValidate(nextProps))
  }

  menuVisibilityChangeHandler(isOpen) {
    const {
      ctx,
      isCustomField,
      getPossibleFieldNames,
      focusHandler,
      name = ''
    } = this.props

    if (isOpen) {
      getPossibleFieldNames(ctx, isCustomField, 'image', name)
    }

    focusHandler(isOpen)
  }

  changeHandler(value) {
    const {
      setCustomField,
      idx,
      isCustomField,
      getPossibleFieldNames,
      ctx
    } = this.props

    getPossibleFieldNames(ctx, isCustomField, 'image', value)
    setCustomField(idx, 'name', value, isCustomField)

    if (this.state.canValidate) {
      validateField(value, 'image', this.props, ctx)
    }
  }

  selectHandler(value) {
    const { setCustomField, idx, isCustomField, ctx } = this.props

    setCustomField(idx, 'name', value, isCustomField)

    if (this.state.canValidate) {
      validateField(value, 'image', this.props, ctx)
    }
  }

  blurHandler(value = '') {
    const { ctx } = this.props
    updateFieldsErrors('image', this.props)

    if (value.length > 0) {
      validateField(value, 'image', this.props, ctx)
    }
  }

  useGalleryCheckboxHandler() {
    const {
      setCustomField,
      idx,
      isCustomField,
      useGallery = false
    } = this.props

    setCustomField(
      idx,
      'useGallery',
      !useGallery,
      isCustomField
    )
  }

  render() {
    const {
      idx,
      name,
      inputName,
      errors,
      possibleFieldNames,
      useGallery = false
    } = this.props

    return (
      <SessionContext.Consumer>
        {ctx => (
          <Fragment>
            <AutocompleteInput
              maxLength={INPUT_MAX_LENGTH}
              name={inputName(idx, 'name')}
              placeholder={ctx.t(
                'js.templates.custom_fields.placeholders.image'
              )}
              labelText={ctx.t('js.templates.custom_fields.labels.image')}
              wrapperClass={{ 'pd-form-group_image': true }}
              possibleValue={possibleFieldNames.image}
              value={name || ''}
              errors={errors}
              blurHandler={this.blurHandler.bind(this)}
              setValue={this.changeHandler.bind(this)}
              selectHandler={this.selectHandler.bind(this)}
              menuVisibilityChangeHandler={this.menuVisibilityChangeHandler.bind(
                this
              )}
            >
              <Hint
                content={ctx.t('js.templates.custom_fields.hints.image')}
                modifiers={{ hint_label: true }}
              />
            </AutocompleteInput>
            <div
              className={`${styles.useGalleryCheckbox} pd-form-group pd-form-group-half`}
            >
              <CheckboxInput
                label={ctx.t(
                  'js.templates.custom_fields.labels.report_fields.use_gallery'
                )}
                checked={useGallery}
                onToggle={this.useGalleryCheckboxHandler.bind(this)}
              />
            </div>
          </Fragment>
        )}
      </SessionContext.Consumer>
    )
  }
}

Image.defaultProps = {
  errors: []
}

export class Checkbox extends Component {
  constructor(props) {
    super(props)

    this.state = {
      canValidate: false
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(updateCanValidate(nextProps))
  }

  menuVisibilityChangeHandler(isOpen) {
    const {
      ctx,
      isCustomField,
      getPossibleFieldNames,
      focusHandler,
      name = ''
    } = this.props

    if (isOpen) {
      getPossibleFieldNames(ctx, isCustomField, 'checkbox', name)
    }

    focusHandler(isOpen)
  }

  changeHandler(value) {
    const {
      setCustomField,
      idx,
      isCustomField,
      getPossibleFieldNames,
      ctx
    } = this.props

    setCustomField(idx, 'name', value, isCustomField)
    getPossibleFieldNames(ctx, isCustomField, 'checkbox', value)

    if (this.state.canValidate) {
      validateField(value, 'checkbox', this.props, ctx)
    }
  }

  selectHandler(value) {
    const { setCustomField, idx, isCustomField, ctx } = this.props

    setCustomField(idx, 'name', value, isCustomField)

    if (this.state.canValidate) {
      validateField(value, 'checkbox', this.props, ctx)
    }
  }

  blurHandler(value = '') {
    const { ctx } = this.props
    updateFieldsErrors('checkbox', this.props)

    if (value.length > 0) {
      validateField(value, 'checkbox', this.props, ctx)
    }
  }

  render() {
    const { idx, name, inputName, errors, possibleFieldNames } = this.props

    return (
      <SessionContext.Consumer>
        {ctx => (
          <Fragment>
            <AutocompleteInput
              maxLength={INPUT_MAX_LENGTH}
              name={inputName(idx, 'name')}
              placeholder={ctx.t(
                'js.templates.custom_fields.placeholders.checkbox'
              )}
              labelText={ctx.t('js.templates.custom_fields.labels.checkbox')}
              wrapperClass={{ 'pd-form-group_checkbox': true }}
              possibleValue={possibleFieldNames.checkbox}
              value={name || ''}
              errors={errors}
              blurHandler={this.blurHandler.bind(this)}
              setValue={this.changeHandler.bind(this)}
              selectHandler={this.selectHandler.bind(this)}
              menuVisibilityChangeHandler={this.menuVisibilityChangeHandler.bind(
                this
              )}
            >
              <Hint
                content={ctx.t('js.templates.custom_fields.hints.checkbox')}
                modifiers={{ hint_label: true }}
              />
            </AutocompleteInput>
          </Fragment>
        )}
      </SessionContext.Consumer>
    )
  }
}

Checkbox.defaultProps = {
  errors: []
}

export class Action extends Component {
  constructor(props) {
    super(props)

    this.state = {
      canValidate: false,
      menuIsOpen: false
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(updateCanValidate(nextProps))
  }

  menuVisibilityChangeHandler(isOpen) {
    const {
      ctx,
      isCustomField,
      getPossibleFieldNames,
      focusHandler,
      name = ''
    } = this.props

    if (isOpen) {
      getPossibleFieldNames(ctx, isCustomField, 'action', name)
    }

    focusHandler(isOpen)
    this.setState({
      menuIsOpen: isOpen
    })
  }

  changeHandler(value) {
    const {
      setCustomField,
      idx,
      isCustomField,
      getPossibleFieldNames,
      ctx
    } = this.props

    getPossibleFieldNames(ctx, isCustomField, 'action', value)
    setCustomField(idx, 'name', value, isCustomField)

    if (this.state.canValidate) {
      validateField(value, 'action', this.props, ctx)
    }
  }

  selectHandler(value) {
    const { setCustomField, idx, isCustomField, ctx } = this.props

    setCustomField(idx, 'name', value, isCustomField)

    validateField(value, 'action', this.props, ctx)
  }

  blurHandler(value) {
    const { ctx } = this.props
    updateFieldsErrors('action', this.props)

    if (value.length > 0) {
      validateField(value, 'action', this.props, ctx)
    }
  }

  render() {
    const { idx, name, possibleFieldNames, errors, inputName } = this.props

    return (
      <SessionContext.Consumer>
        {ctx => (
          <Fragment>
            <AutocompleteInput
              maxLength={INPUT_MAX_LENGTH}
              name={inputName(idx, 'name')}
              placeholder={ctx.t(
                'js.templates.custom_fields.placeholders.action'
              )}
              labelText={ctx.t('js.templates.custom_fields.labels.action')}
              wrapperClass={{ 'pd-form-group_action': true }}
              possibleValue={possibleFieldNames.action}
              value={name || ''}
              errors={errors}
              blurHandler={this.blurHandler.bind(this)}
              setValue={this.changeHandler.bind(this)}
              selectHandler={this.selectHandler.bind(this)}
              menuVisibilityChangeHandler={this.menuVisibilityChangeHandler.bind(
                this
              )}
            >
              {
                <Hint
                  content={ctx.t('js.templates.custom_fields.hints.action')}
                  modifiers={{ hint_label: true }}
                />
              }
            </AutocompleteInput>
          </Fragment>
        )}
      </SessionContext.Consumer>
    )
  }
}

Action.defaultProps = {
  errors: []
}

export class Barcode extends Component {
  state = { canValidate: false }

  componentWillReceiveProps(nextProps) {
    this.setState(() => updateCanValidate(nextProps))
  }

  menuVisibilityChangeHandler = isOpen => {
    const {
      ctx,
      isCustomField,
      getPossibleFieldNames,
      focusHandler,
      name = ''
    } = this.props

    if (isOpen) {
      getPossibleFieldNames(ctx, isCustomField, 'barcode', name)
    }

    focusHandler(isOpen)
  }

  changeHandler = value => {
    const {
      setCustomField,
      idx,
      isCustomField,
      getPossibleFieldNames,
      ctx
    } = this.props

    getPossibleFieldNames(ctx, isCustomField, 'barcode', value)
    setCustomField(idx, 'name', value, isCustomField)

    if (this.state.canValidate) {
      validateField(value, 'barcode', this.props, ctx)
    }
  }

  selectHandler = value => {
    const { setCustomField, idx, isCustomField, ctx } = this.props

    setCustomField(idx, 'name', value, isCustomField)

    if (this.state.canValidate) {
      validateField(value, 'barcode', this.props, ctx)
    }
  }

  blurHandler = (value = '') => {
    const { ctx } = this.props
    updateFieldsErrors('barcode', this.props)

    if (value.length > 0) {
      validateField(value, 'barcode', this.props, ctx)
    }
  }

  render() {
    const { idx, name, inputName, errors, possibleFieldNames } = this.props

    return (
      <SessionContext.Consumer>
        {ctx => (
          <Fragment>
            <AutocompleteInput
              maxLength={INPUT_MAX_LENGTH}
              name={inputName(idx, 'name')}
              placeholder={ctx.t(
                'js.templates.custom_fields.placeholders.barcode'
              )}
              labelText={ctx.t('js.templates.custom_fields.labels.barcode')}
              wrapperClass={{ 'pd-form-group_barcode': true }}
              possibleValue={possibleFieldNames.barcode}
              value={name || ''}
              errors={errors}
              blurHandler={this.blurHandler}
              setValue={this.changeHandler}
              selectHandler={this.selectHandler}
              menuVisibilityChangeHandler={this.menuVisibilityChangeHandler}
            >
              <Hint
                content={ctx.t('js.templates.custom_fields.hints.barcode')}
                modifiers={{ hint_label: true }}
              />
            </AutocompleteInput>
          </Fragment>
        )}
      </SessionContext.Consumer>
    )
  }
}

export const Generic = props => {
  const [canValidate, setCanValidate] = useState(false)
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const {
    ctx,
    idx,
    possibleFieldNames,
    errors,
    setCustomField,
    isCustomField,
    inputName,
    getPossibleFieldNames,
    focusHandler,
    fieldType,
    dataType,
    name = ''
  } = props

  useEffect(() => {
    if (!canValidate && !menuIsOpen && !isEmpty(errors)) {
      setCanValidate(true)
    }
  }, [props])

  const menuVisibilityChangeHandler = isOpen => {
    if (isOpen) {
      getPossibleFieldNames(ctx, isCustomField, fieldType, name, dataType)
    }

    focusHandler(isOpen)
    setMenuIsOpen(isOpen)
  }

  const changeHandler = value => {
    getPossibleFieldNames(ctx, isCustomField, fieldType, value, dataType)
    setCustomField(idx, 'name', value, isCustomField)
    validateField(value, fieldType, props, ctx)

    if (canValidate) {
      validateField(value, fieldType, props, ctx)
    }
  }

  const selectHandler = value => {
    setCustomField(idx, 'name', value, isCustomField)
    validateField(value, fieldType, props, ctx)
  }

  const blurHandler = (value = '') => {
    updateFieldsErrors(fieldType, props)

    if (value.length > 0) {
      validateField(value, fieldType, props, ctx)
    }
  }

  const section = isCustomField ? 'custom_fields' : 'report_fields'

  const placeholder = ctx =>
    ctx.t(`js.templates.custom_fields.placeholders.${section}.${dataType}`)

  const labelText = ctx =>
    ctx.t(`js.templates.custom_fields.labels.${section}.${dataType}`)

  const wrapperClass = {
    [styles[`${dataType}Field`]]: true
  }

  return (
    <SessionContext.Consumer>
      {ctx => (
        <AutocompleteInput
          maxLength={INPUT_MAX_LENGTH}
          name={inputName(idx, 'name')}
          placeholder={placeholder(ctx)}
          labelText={labelText(ctx)}
          wrapperClass={wrapperClass}
          possibleValue={possibleFieldNames[fieldType]}
          value={name || ''}
          errors={errors}
          blurHandler={blurHandler}
          setValue={changeHandler}
          selectHandler={selectHandler}
          menuVisibilityChangeHandler={menuVisibilityChangeHandler}
        >
          {isCustomField ? null : (
            <Hint
              content={ctx.t(`js.templates.custom_fields.hints.${dataType}`)}
              modifiers={{ hint_label: true }}
            />
          )}
        </AutocompleteInput>
      )}
    </SessionContext.Consumer>
  )
}

export const Link = props => {
  const [canValidate, setCanValidate] = useState(false)
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const {
    ctx,
    idx,
    possibleFieldNames,
    errors,
    setCustomField,
    isCustomField,
    inputName,
    getPossibleFieldNames,
    focusHandler,
    fieldType,
    dataType,
    name = '',
    urlValue
  } = props

  const section = isCustomField ? 'custom_fields' : 'report_fields'

  useEffect(() => {
    if (!canValidate && !menuIsOpen && !isEmpty(errors)) {
      setCanValidate(true)
    }
  }, [props])

  const menuVisibilityChangeHandler = isOpen => {
    if (isOpen) {
      getPossibleFieldNames(ctx, isCustomField, fieldType, name, dataType)
    }

    focusHandler(isOpen)
    setMenuIsOpen(isOpen)
  }

  const changeHandler = value => {
    getPossibleFieldNames(ctx, isCustomField, fieldType, value, dataType)
    setCustomField(idx, 'name', value, isCustomField)
    validateField(value, fieldType, props, ctx)

    if (canValidate) {
      validateField(value, fieldType, props, ctx)
    }
  }

  const urlChangeHandler = value => {
    setCustomField(idx, 'urlValue', value, isCustomField)
  }

  const selectHandler = value => {
    setCustomField(idx, 'name', value, isCustomField)
    validateField(value, fieldType, props, ctx)
  }

  const blurHandler = (value = '') => {
    updateFieldsErrors(fieldType, props)

    if (value.length > 0) {
      validateField(value, fieldType, props, ctx)
    }
  }

  const removeSpaces = string => string.replace(/\\s/g, '')

  const onBlurUrl = () => {
    let url
    if (urlValue === '' || urlValue == 'https://' || urlValue == 'http://') {
      url = ''
    } else if (urlValue.match(/^(http|https):\/\/.*/)) {
      url = removeSpaces(urlValue)
    } else {
      url = `https://${removeSpaces(urlValue)}`
    }
    setCustomField(idx, 'urlValue', url, isCustomField)
  }

  const wrapperClass = {
    [styles[`${fieldType}Field`]]: true,
    'pd-form-group-equal-size': !isCustomField,
    'pd-form-group-half': isCustomField
  }

  return (
    <SessionContext.Consumer>
      {ctx => (
        <div className="pd-form-group-container">
          <AutocompleteInput
            maxLength={INPUT_MAX_LENGTH}
            name={inputName(idx, 'name')}
            placeholder={ctx.t(
              `js.templates.custom_fields.placeholders.${section}.link`
            )}
            labelText={ctx.t(
              `js.templates.custom_fields.labels.${section}.link`
            )}
            wrapperClass={wrapperClass}
            possibleValue={possibleFieldNames[fieldType]}
            value={name || ''}
            errors={errors}
            blurHandler={blurHandler}
            setValue={changeHandler}
            selectHandler={selectHandler}
            menuVisibilityChangeHandler={menuVisibilityChangeHandler}
          />

          {isCustomField && (
            <div className="pd-form-group pd-form-group-half">
              <label className="pd-label">
                {ctx.t(
                  'js.templates.custom_fields.labels.custom_fields.url_input'
                )}
              </label>

              <FormInput
                type="text"
                id={`template[custom_fields][${idx}][url_input]`}
                placeholder={'https://example.com'}
                value={urlValue || ''}
                onValueChange={urlChangeHandler}
                wrapperClass={{ 'pd-form-group': false }}
                maxLength={1000}
                onBlur={onBlurUrl}
              />
            </div>
          )}
        </div>
      )}
    </SessionContext.Consumer>
  )
}

export const Currency = props => {
  const {
    ctx,
    idx,
    possibleFieldNames,
    errors,
    setCustomField,
    isCustomField,
    inputName,
    getPossibleFieldNames,
    focusHandler,
    fieldType,
    dataType,
    name,
    currency = ctx.currency,
    useCurrencyFractionalUnit = false,
    currencyValue = ''
  } = props

  const possibleFields = possibleFieldNames[fieldType]
    ? possibleFieldNames[fieldType].filter(f => f.currency === ctx.currency)
    : []

  const [canValidate, setCanValidate] = useState(false)
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  useEffect(() => {
    if (!canValidate && !menuIsOpen && !isEmpty(errors)) {
      setCanValidate(true)
    }
  }, [props])

  const currencyValueToDisplay = (() => {
    if (currencyValue === '' || useCurrencyFractionalUnit) {
      return currencyValue
    } else {
      const [dollars, _cents] = currencyValue.split(/[,.]/)
      return dollars
    }
  })()

  const formatCurrencyValue = () => {
    if (currencyValue === '') {
      return ''
    } else {
      return String(parseFloat(currencyValue).toFixed(2))
    }
  }

  useEffect(() => {
    setCustomField(idx, 'currency', currency, isCustomField)
    setCustomField(
      idx,
      'useCurrencyFractionalUnit',
      useCurrencyFractionalUnit,
      isCustomField
    )

    setCustomField(idx, 'currencyValue', currencyValue, isCustomField)
  }, [])

  const menuVisibilityChangeHandler = isOpen => {
    if (isOpen) {
      getPossibleFieldNames(ctx, isCustomField, fieldType, name, dataType)
    }

    focusHandler(isOpen)
    setMenuIsOpen(isOpen)
  }

  const changeHandler = value => {
    getPossibleFieldNames(ctx, isCustomField, fieldType, value, dataType)
    setCustomField(idx, 'name', value, isCustomField)
    validateField(value, fieldType, props, ctx)

    if (canValidate) {
      validateField(value, fieldType, props, ctx)
    }
  }

  const currencyValueChangeHandler = value => {
    const decimalRegex = /^[0-9]{1,12}([,.][0-9]{0,2})?$/
    const integerRegex = /^[0-9]{1,12}$/

    if (value.length === 0) {
      setCustomField(idx, 'currencyValue', value, isCustomField)
    } else if (useCurrencyFractionalUnit && value.match(decimalRegex)) {
      setCustomField(idx, 'currencyValue', value, isCustomField)
    } else if (value.match(integerRegex)) {
      const [_dollars, cents] = currencyValue.split(/[,.]/)
      const newValue = `${value}.${cents}`
      setCustomField(idx, 'currencyValue', newValue, isCustomField)
    }
  }

  const currencyBlurHandler = () =>
    setCustomField(idx, 'currencyValue', formatCurrencyValue(), isCustomField)

  const selectPossibleFieldHandler = value => {
    const possibleField = possibleFields.find(f => f.name === value)

    if (possibleField) {
      setCustomField(idx, 'name', value, isCustomField)
      setCustomField(idx, 'currency', possibleField.currency, isCustomField)
      setCustomField(
        idx,
        'useCurrencyFractionalUnit',
        possibleField.useCurrencyFractionalUnit,
        isCustomField
      )
      validateField(value, fieldType, props, ctx)
    }
  }

  const blurHandler = (value = '') => {
    updateFieldsErrors(fieldType, props)

    if (value.length > 0) {
      validateField(value, fieldType, props, ctx)
    }
  }

  const checkboxHandler = () => {
    setCustomField(
      idx,
      'useCurrencyFractionalUnit',
      !useCurrencyFractionalUnit,
      isCustomField
    )
  }

  const section = isCustomField ? 'custom_fields' : 'report_fields'

  const wrapperClass = {
    [styles[`${fieldType}Field`]]: true,
    'pd-form-group-half': isCustomField,
    'pd-form-group-full': !isCustomField
  }

  const currencySymbol = ctx.t(`common.currency_signs.${currency}`)

  return (
    <div>
      <div className="pd-form-group-container">
        <AutocompleteInput
          maxLength={INPUT_MAX_LENGTH}
          name={inputName(idx, 'name')}
          placeholder={ctx.t(
            `js.templates.custom_fields.placeholders.${section}.${fieldType}`
          )}
          labelText={ctx.t(
            `js.templates.custom_fields.labels.${section}.${fieldType}`
          )}
          wrapperClass={wrapperClass}
          possibleValue={possibleFields}
          value={name || ''}
          errors={errors}
          blurHandler={blurHandler}
          setValue={changeHandler}
          selectHandler={selectPossibleFieldHandler}
          menuVisibilityChangeHandler={menuVisibilityChangeHandler}
        />
        {isCustomField ? (
          <div className="pd-form-group pd-form-group-equal-size">
            <label className="pd-label">
              {ctx.t(
                'js.templates.custom_fields.labels.custom_fields.currency_value_input_with_symbol',
                { symbol: currencySymbol }
              )}
            </label>

            <FormInput
              type="text"
              id={`template[custom_fields][${idx}][currency_value_input]`}
              value={currencyValueToDisplay}
              onValueChange={currencyValueChangeHandler}
              wrapperClass={{ 'pd-form-group': false }}
              maxLength={15}
              onBlur={currencyBlurHandler}
            />
          </div>
        ) : null}
      </div>

      <div
        className={`${styles.currencyFractionCheckbox} pd-form-group pd-form-group-half`}
      >
        <CheckboxInput
          label={ctx.t(
            'js.templates.custom_fields.labels.custom_fields.use_currency_fractional_unit'
          )}
          checked={useCurrencyFractionalUnit}
          onToggle={checkboxHandler}
        />
      </div>
    </div>
  )
}

export const Signature = props => {
  const [canValidate, setCanValidate] = useState(false)
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const {
    ctx,
    idx,
    possibleFieldNames,
    errors = [],
    setCustomField,
    isCustomField,
    inputName,
    getPossibleFieldNames,
    focusHandler,
    fieldType,
    dataType,
    name = '',
    customFields,
    signedFieldUuid
  } = props

  useEffect(() => {
    if (!canValidate && !menuIsOpen && !isEmpty(errors)) {
      setCanValidate(true)
    }
  }, [props])

  const menuVisibilityChangeHandler = isOpen => {
    if (isOpen) {
      getPossibleFieldNames(ctx, isCustomField, fieldType, name, dataType)
    }

    focusHandler(isOpen)
    setMenuIsOpen(isOpen)
  }

  const changeHandler = value => {
    getPossibleFieldNames(ctx, isCustomField, fieldType, value, dataType)
    setCustomField(idx, 'name', value, isCustomField)
    validateField(value, fieldType, props, ctx)

    if (canValidate) {
      validateField(value, fieldType, props, ctx)
    }
  }

  const selectHandler = value => {
    setCustomField(idx, 'name', value, isCustomField)
    validateField(value, fieldType, props, ctx)
  }

  const blurHandler = (value = '') => {
    updateFieldsErrors(fieldType, props)

    if (value.length > 0) {
      validateField(value, fieldType, props, ctx)
    }
  }

  const wrapperClass = {
    [styles[`${fieldType}Field`]]: true,
    'pd-form-group-half': true
  }

  const signatureFieldSelectHandler = value => {
    setCustomField(idx, 'signedFieldUuid', value, isCustomField)
    const remainingErrors = [errors[0] || [], []]
    setCustomField(idx, 'errors', remainingErrors, isCustomField)
  }

  const signFieldOptions = customFields
    .filter(
      f =>
        f.fieldType === 'file' &&
        f.name !== null &&
        f.name !== '' &&
        !f.isDestroyed
    )
    .map(f => {
      return {
        value: f.fieldUuid,
        label: f.name
      }
    })

  return (
    <div className="pd-form-group-container">
      <AutocompleteInput
        maxLength={INPUT_MAX_LENGTH}
        name={inputName(idx, 'name')}
        placeholder={ctx.t(
          'js.templates.custom_fields.placeholders.report_fields.signature'
        )}
        labelText={ctx.t(
          'js.templates.custom_fields.labels.report_fields.signature'
        )}
        wrapperClass={wrapperClass}
        possibleValue={possibleFieldNames[fieldType]}
        value={name || ''}
        errors={errors[0]}
        blurHandler={blurHandler}
        setValue={changeHandler}
        selectHandler={selectHandler}
        menuVisibilityChangeHandler={menuVisibilityChangeHandler}
      >
        {isCustomField ? null : (
          <Hint
            content={ctx.t('js.templates.custom_fields.hints.signature')}
            modifiers={{ hint_label: true }}
          />
        )}
      </AutocompleteInput>

      <div className={'pd-form-group pd-form-group-half'}>
        <label className="pd-label">
          {ctx.t(
            'js.templates.custom_fields.labels.report_fields.signature_field_select'
          )}

          <Hint
            content={ctx.t(
              'js.templates.custom_fields.hints.signature_field_select'
            )}
            modifiers={{ hint_label: true }}
          />
        </label>

        <Select
          autosize={false}
          id={inputName(idx, 'dictionary')}
          name={inputName(idx, 'dictionary')}
          clearable={false}
          onChange={signatureFieldSelectHandler}
          placeholder={ctx.t(
            'js.templates.custom_fields.placeholders.report_fields.signature_field_select'
          )}
          value={signedFieldUuid}
          options={signFieldOptions}
          noResultsText={ctx.t(
            'js.templates.custom_fields.placeholders.report_fields.signature_no_file_fields_to_select'
          )}
        />

        {errors[1] && errors[1].length > 0 ? (
          <div className="pd-control-error">
            {errors[1].map((x, idx) => (
              <div key={idx}>{x}</div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export const Button = props => {
  const [canValidate, setCanValidate] = useState(false)
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const {
    ctx,
    idx,
    possibleFieldNames,
    errors = [],
    setCustomField,
    isCustomField,
    inputName,
    getPossibleFieldNames,
    reusableButton,
    focusHandler,
    fieldType,
    dataType,
    name = '',
    urlValue
  } = props

  const section = isCustomField ? 'custom_fields' : 'report_fields'

  useEffect(() => {
    if (!canValidate && !menuIsOpen && !isEmpty(errors)) {
      setCanValidate(true)
    }
  }, [props])

  const menuVisibilityChangeHandler = isOpen => {
    if (isOpen) {
      getPossibleFieldNames(ctx, isCustomField, fieldType, name, dataType)
    }

    focusHandler(isOpen)
    setMenuIsOpen(isOpen)
  }

  const changeHandler = value => {
    getPossibleFieldNames(ctx, isCustomField, fieldType, value, dataType)
    setCustomField(idx, 'name', value, isCustomField)
    validateField(value, fieldType, props, ctx)

    if (canValidate) {
      validateField(value, fieldType, props, ctx)
    }
  }

  const addUrlSchema = () => {
    let strippedValue = urlValue.replace(/^\s+/, '').replace(/\s+$/, '')

    if (strippedValue !== '' && !strippedValue.match(/^http(s)?:\/\/.*/)) {
      setCustomField(idx, 'urlValue', `https://${strippedValue}`, isCustomField)
    } else {
      setCustomField(idx, 'urlValue', strippedValue, isCustomField)
    }
  }

  const buttonUrlChangeHandler = value => {
    setCustomField(idx, 'urlValue', value, isCustomField)
    const remainingErrors = [errors[0] || [], []]
    setCustomField(idx, 'errors', remainingErrors, isCustomField)
  }

  const selectPossibleFieldHandler = value => {
    const possibleField = possibleFieldNames.button.find(f => f.name === value)

    if (possibleField) {
      setCustomField(idx, 'name', value, isCustomField)
      setCustomField(
        idx,
        'reusableButton',
        possibleField.reusableButton,
        isCustomField
      )

      validateField(value, fieldType, props, ctx)
    }
  }

  const blurHandler = (value = '') => {
    updateFieldsErrors(fieldType, props)

    if (value.length > 0) {
      validateField(value, fieldType, props, ctx)
    }
  }

  const checkboxHandler = () => {
    setCustomField(idx, 'reusableButton', !reusableButton, isCustomField)
  }

  const wrapperClass = {
    [styles[`${fieldType}Field`]]: true,
    'pd-form-group-half': true
  }

  return (
    <div>
      <div className="pd-form-group-container">
        <AutocompleteInput
          maxLength={INPUT_MAX_LENGTH}
          name={inputName(idx, 'name')}
          placeholder={ctx.t(
            `js.templates.custom_fields.placeholders.${section}.button`
          )}
          labelText={ctx.t(
            'js.templates.custom_fields.labels.report_fields.button'
          )}
          wrapperClass={wrapperClass}
          possibleValue={possibleFieldNames.button}
          value={name || ''}
          errors={errors[0]}
          blurHandler={blurHandler}
          setValue={changeHandler}
          selectHandler={selectPossibleFieldHandler}
          menuVisibilityChangeHandler={menuVisibilityChangeHandler}
        />

        <div className="pd-form-group pd-form-group-half">
          <label className="pd-label">
            {ctx.t('js.templates.custom_fields.labels.custom_fields.url_input')}
          </label>
          <Hint
            content={ctx.t('js.templates.custom_fields.hints.button')}
            modifiers={{ hint_label: true }}
          />

          <FormInput
            type="text"
            id={`template[custom_fields][${idx}][url_input]`}
            placeholder={'https://example.com'}
            value={urlValue || ''}
            onValueChange={buttonUrlChangeHandler}
            onBlur={addUrlSchema}
            wrapperClass={{ 'pd-form-group': false }}
            maxLength={1000}
            errors={errors[1]}
          />
        </div>
      </div>

      <div
        className={`${styles.currencyFractionCheckbox} pd-form-group pd-form-group-half`}
      >
        <CheckboxInput
          label={ctx.t(
            'js.templates.custom_fields.labels.report_fields.reusable_button'
          )}
          checked={reusableButton}
          onToggle={checkboxHandler}
        >
          <Hint
            content={ctx.t('js.templates.custom_fields.hints.reusable_button')}
            modifiers={{ hint_label: true }}
          />
        </CheckboxInput>
      </div>
    </div>
  )
}
