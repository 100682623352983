

import * as React from "react";
import * as DayTimeline_CurrentTimeHook from "./DayTimeline_CurrentTimeHook.mjs";
import * as DayTimeline_CurrentTimeModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/day_timeline/components/current_time/DayTimeline_CurrentTime.module.css";

var styles = DayTimeline_CurrentTimeModuleCss;

function DayTimeline_CurrentTime(Props) {
  var part = Props.part;
  var ref = DayTimeline_CurrentTimeHook.use();
  var className;
  className = part === "Line" ? styles.line : styles.triangle;
  return React.createElement("div", {
              ref: ref,
              className: className
            });
}

var make = React.memo(DayTimeline_CurrentTime);

export {
  make ,
}
/* styles Not a pure module */
