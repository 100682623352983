

import * as Utils from "./utils/Utils.mjs";
import * as React from "react";
import * as AppContext from "./context/AppContext.mjs";

function Portal(Props) {
  var root = Props.root;
  var children = Props.children;
  var wire = AppContext.useWire();
  var div = React.useMemo((function () {
          return Utils.createElement("div");
        }), []);
  React.useEffect((function () {
          Utils.appendChild(root, div);
          return (function () {
                    Utils.removeChild(root, div);
                  });
        }), []);
  return Utils.createPortal(React.createElement("div", {
                  onClick: wire.subscriptions.click.trigger
                }, children), div);
}

var make = Portal;

var $$default = Portal;

export {
  make ,
  $$default as default,
}
/* Utils Not a pure module */
