

import * as React from "react";
import * as Subscription from "../Subscription.mjs";

function Make($star) {
  var make = function () {
    var item = {
      contents: undefined
    };
    var subscriptions_onDragStart = Subscription.make();
    var subscriptions_onDrag = Subscription.make();
    var subscriptions_onDragEnd = Subscription.make();
    var subscriptions = {
      onDragStart: subscriptions_onDragStart,
      onDrag: subscriptions_onDrag,
      onDragEnd: subscriptions_onDragEnd
    };
    return {
            subscriptions: subscriptions,
            item: item,
            dropHandlers: {
              contents: []
            }
          };
  };
  var context = React.createContext();
  var use = function () {
    return React.useContext(context);
  };
  return {
          makePayload: (function (prim) {
              return prim;
            }),
          payload: (function (prim) {
              return prim;
            }),
          make: make,
          context: context,
          use: use
        };
}

export {
  Make ,
}
/* react Not a pure module */
