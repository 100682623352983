

import * as Locale from "../../../libraries/Locale.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function MonthDay(Props) {
  var prevDay = Props.prevDay;
  var day = Props.day;
  var wire = AppContext.useWire();
  var showMonth = prevDay !== undefined ? !Locale.T.equalMonth(Caml_option.valFromOption(prevDay), day) : true;
  return Locale.T.fmtM(showMonth ? "MonthAbbrAndDay" : "Day", wire)(day).toUpperCase();
}

var make = MonthDay;

export {
  make ,
}
/* Locale Not a pure module */
