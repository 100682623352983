import React from 'react'
import Engine from '../engines/google'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import * as styles from '../styles.module.css'

class GoogleViewport extends React.Component {
  constructor(props) {
    super(props)

    const {
      ctx,
      openJob,
      onJobClick,
      onWorkersJobClick,
      onBoundsChange,
      apiKey
    } = props

    this.engine = new Engine({
      ctx,
      openJob,
      onJobClick,
      onWorkersJobClick,
      onBoundsChange,
      apiKey
    })
  }

  componentDidMount() {
    this.engine.start(this.viewport, this.props.channel)
    this.props.onEngineStart(this.engine)
  }

  render() {
    return (
      <div
        className={`${styles.viewport} google-viewport`}
        ref={v => {
          this.viewport = v
        }}
      >
        <div className={styles.mapIcon}>
          <i className="fa fa-map-o" />
        </div>
      </div>
    )
  }
}

export default withContext(GoogleViewport)
