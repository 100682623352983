// extracted by mini-css-extract-plugin
export var buttonLoading = "button-loading-ePGvTkuSvKEISksEPVDl";
export var clientsList = "clients-list-iOveHtDSs9Qk1qMx3jnE";
export var clientsListBtn = "clients-list__btn-aJ0KkrmffVIV9W20UK8W";
export var clientsListDesc = "clients-list__desc-nC1LdsPfUUw_rycFHPT_";
export var clientsListHeadline = "clients-list__headline-K25OM_77YkN3UBEpt_7Z";
export var clientsTable = "clients-table-VArT3nxqNdwTGAxQcdrX";
export var clientsTableAddress = "clients-table-address-zLHg2yqOvSUncHE91A5N";
export var clientsTableName = "clients-table-name-KQXJuPdut2XnKxGNMIuq";
export var clientsTablePhone = "clients-table-phone-zm1mnQe04k5XnernWwzR";
export var isLoading = "is-loading-cNgIE9bLQFPeBFOp1HpY";