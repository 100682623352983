

import * as Uuid from "../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../libraries/RCore.mjs";
import * as Locale from "../../../../../libraries/Locale.mjs";
import * as JsonDecode from "../../../../../libraries/JsonDecode.mjs";
import * as Types_Date from "../../../../../types/Types_Date.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Types_Territory from "../../../../../types/Types_Territory.mjs";
import * as Schedule_Types_JobStatus from "./Schedule_Types_JobStatus.mjs";

var Entity = {};

function uuid(param) {
  return param.uuid;
}

function name(param) {
  return param.name;
}

function decode(field) {
  return {
          uuid: field.required("uuid", Uuid.decode),
          name: field.required("name", JsonDecode.string)
        };
}

var Entity$1 = {
  uuid: uuid,
  name: name,
  decode: decode
};

function decode$1(field) {
  var match = field.required("type", JsonDecode.string);
  switch (match) {
    case "client" :
        return {
                NAME: "Client",
                VAL: decode(field)
              };
    case "site" :
        return {
                NAME: "Site",
                VAL: decode(field)
              };
    default:
      return JsonDecode.cannotDecode("unknown client type");
  }
}

var Client = {
  Entity: Entity$1,
  decode: decode$1
};

function uuid$1(param) {
  return param.uuid;
}

function name$1(param) {
  return param.name;
}

function decode$2(field) {
  return {
          uuid: field.required("uuid", Uuid.decode),
          name: field.required("name", JsonDecode.string)
        };
}

var Template = {
  uuid: uuid$1,
  name: name$1,
  decode: decode$2
};

function uuid$2(param) {
  return param.uuid;
}

function name$2(param) {
  return param.name;
}

function decode$3(field) {
  return {
          uuid: field.required("uuid", Uuid.decode),
          name: field.required("name", JsonDecode.string)
        };
}

var Skill = {
  uuid: uuid$2,
  name: name$2,
  decode: decode$3
};

function fromString(s) {
  return s;
}

function toString(s) {
  return s;
}

var decode$4 = JsonDecode.map(JsonDecode.string, fromString);

var Address = {
  fromString: fromString,
  toString: toString,
  decode: decode$4
};

var decode$5 = JsonDecode.object(function (field) {
      return {
              name: field.required("name", JsonDecode.string),
              successful: field.required("successful", JsonDecode.bool)
            };
    });

function makeFromDecoded(title) {
  return {
          title: RCore.$$Option.flatMap(title, (function (s) {
                  return s;
                }))
        };
}

var decode$6 = JsonDecode.oneOf([
      JsonDecode.object(function (field) {
            return {
                    title: RCore.$$Option.flatten(field.optional("title", JsonDecode.option(JsonDecode.string)))
                  };
          }),
      JsonDecode.custom(function (param) {
            return {
                    title: undefined
                  };
          })
    ]);

var CustomUI = {
  makeFromDecoded: makeFromDecoded,
  decode: decode$6
};

function decode$7(field) {
  return {
          uuid: field.required("uuid", Uuid.decode),
          status: field.required("status", Schedule_Types_JobStatus.decode),
          duration: field.required("scheduledDurationMin", JsonDecode.$$int),
          template: field.required("template", JsonDecode.option(JsonDecode.object(decode$2))),
          client: field.required("client", JsonDecode.option(JsonDecode.object(decode$1))),
          territory: field.required("territory", JsonDecode.option(Types_Territory.decode)),
          skillUuids: field.required("skillUuids", JsonDecode.array(Uuid.decode)),
          address: field.required("formattedAddress", JsonDecode.option(decode$4)),
          scheduledAt: field.required("scheduledAt", JsonDecode.option(Types_Date.decode)),
          enRouteAt: field.required("enRouteAt", JsonDecode.option(Types_Date.decode)),
          startedAt: field.required("startedAt", JsonDecode.option(Types_Date.decode)),
          suspendedAt: field.required("suspendedAt", JsonDecode.option(Types_Date.decode)),
          finishedAt: field.required("finishedAt", JsonDecode.option(Types_Date.decode)),
          resolution: field.required("resolution", JsonDecode.option(decode$5)),
          customUi: field.required("customUi", decode$6)
        };
}

function uuid$3(param) {
  return param.uuid;
}

function status(param) {
  return param.status;
}

function duration(param) {
  return param.duration;
}

function template(param) {
  return param.template;
}

function client(param) {
  return param.client;
}

function territory(param) {
  return param.territory;
}

function address(param) {
  return param.address;
}

function scheduledAt(param) {
  return param.scheduledAt;
}

function scheduledFinishAt(job) {
  var scheduledAt$1 = scheduledAt(job);
  if (scheduledAt$1 !== undefined) {
    return Caml_option.some(Locale.T.nextMinutes(duration(job), Caml_option.valFromOption(scheduledAt$1)));
  }
  
}

function enRouteAt(param) {
  return param.enRouteAt;
}

function suspendedAt(param) {
  return param.suspendedAt;
}

function finishedAt(param) {
  return param.finishedAt;
}

function resolution(param) {
  return param.resolution;
}

function title(param) {
  var template = param.template;
  var name = param.customUi.title;
  if (name !== undefined) {
    return name;
  } else if (template !== undefined) {
    return template.name;
  } else {
    return ;
  }
}

var Status;

function MakeEntity(funarg) {
  var uuid = function (param) {
    return param.uuid;
  };
  var name = function (param) {
    return param.name;
  };
  var decode = function (field) {
    return {
            uuid: field.required("uuid", Uuid.decode),
            name: field.required("name", JsonDecode.string)
          };
  };
  return {
          uuid: uuid,
          name: name,
          decode: decode
        };
}

var Resolution = {};

export {
  Status ,
  Entity ,
  MakeEntity ,
  Client ,
  Template ,
  Skill ,
  Address ,
  Resolution ,
  CustomUI ,
  decode$7 as decode,
  uuid$3 as uuid,
  status ,
  duration ,
  template ,
  client ,
  territory ,
  address ,
  scheduledAt ,
  scheduledFinishAt ,
  enRouteAt ,
  suspendedAt ,
  finishedAt ,
  resolution ,
  title ,
}
/* decode Not a pure module */
