

import * as Uuid from "../../../../../../../../bindings/uuid/Uuid.mjs";
import * as React from "react";
import * as AppContext from "../../../../../../../../context/AppContext.mjs";
import * as Schedule_State from "../../../../../../lib/state/Schedule_State.mjs";
import * as WeekTimeline_Job from "./job/WeekTimeline_Job.mjs";
import * as Schedule_Types_Job from "../../../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as WeekTimeline_State from "../../../../lib/state/WeekTimeline_State.mjs";
import * as WeekTimeline_Interval from "./interval/WeekTimeline_Interval.mjs";
import * as WeekTimeline_BarJobGroups from "./job_groups/WeekTimeline_BarJobGroups.mjs";
import * as WeekTimeline_AssignJobBlocks from "./assign_job_blocks/WeekTimeline_AssignJobBlocks.mjs";
import * as WeekTimeline_BarBusinessHours from "./business_hours/WeekTimeline_BarBusinessHours.mjs";
import * as WeekTimeline_IntervalPositions from "../../../../lib/positions/WeekTimeline_IntervalPositions.mjs";
import * as WeekTimeline_AssignedJobPositions from "../../../../lib/positions/WeekTimeline_AssignedJobPositions.mjs";
import * as WeekTimeline_BarModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/timeline/bar/WeekTimeline_Bar.module.css";

function WeekTimeline_BarJobs$Jobs(Props) {
  var jobs = Props.jobs;
  var assignee = Props.assignee;
  return jobs.map(function (param) {
              var job = param[1];
              return React.createElement(WeekTimeline_Job.make, {
                          job: job,
                          position: param[0],
                          bar: assignee,
                          key: Uuid.toString(Schedule_Types_Job.AssignedJob.uuid(job))
                        });
            });
}

function WeekTimeline_BarJobs$Intervals(Props) {
  var intervals = Props.intervals;
  return intervals.map(function (position, index) {
              return React.createElement(WeekTimeline_Interval.make, {
                          position: position,
                          key: index.toString()
                        });
            });
}

var styles = WeekTimeline_BarModuleCss;

function WeekTimeline_BarJobs(Props) {
  var assignee = Props.assignee;
  var jobs = Props.jobs;
  var workingIntervals = Props.workingIntervals;
  var wire = AppContext.useWire();
  var period = Schedule_State.usePeriod();
  var sizes = WeekTimeline_State.useSizes();
  var jobsPositions = WeekTimeline_AssignedJobPositions.make(wire, period, sizes, jobs);
  var intervals = WeekTimeline_IntervalPositions.make(wire, period, sizes, workingIntervals);
  return React.createElement("div", {
              className: styles.jobs
            }, React.createElement(WeekTimeline_BarJobs$Intervals, {
                  intervals: intervals
                }), React.createElement(WeekTimeline_BarJobs$Jobs, {
                  jobs: jobsPositions,
                  assignee: assignee
                }), React.createElement(WeekTimeline_BarJobGroups.make, {
                  bar: assignee,
                  jobs: jobsPositions
                }), React.createElement(WeekTimeline_AssignJobBlocks.make, {
                  assignee: assignee
                }), React.createElement(WeekTimeline_BarBusinessHours.make, {
                  assignee: assignee
                }));
}

var make = React.memo(WeekTimeline_BarJobs);

export {
  styles ,
  make ,
}
/* styles Not a pure module */
