

import * as Catalog_Types from "../Catalog_Types.mjs";

function fromProps(ctx, category) {
  if (category !== undefined) {
    return {
            TAG: "Edit",
            _0: category.uuid,
            _1: {
              name: category.name,
              description: category.description,
              priceDefault: category.priceDefault,
              currency: category.currency,
              categoryUuid: category.categoryUuid,
              unitUuid: category.unitUuid,
              externalId: category.externalId,
              gtin: category.gtin,
              archived: category.archived
            }
          };
  } else {
    return {
            TAG: "New",
            _0: Catalog_Types.Product.Form.empty(ctx)
          };
  }
}

function form(s) {
  if (s.TAG === "New") {
    return s._0;
  } else {
    return s._1;
  }
}

function updateForm(state, newForm) {
  if (state.TAG === "New") {
    return {
            TAG: "New",
            _0: newForm
          };
  } else {
    return {
            TAG: "Edit",
            _0: state._0,
            _1: newForm
          };
  }
}

function reducer(state, action) {
  var form$1 = form(state);
  switch (action.TAG) {
    case "SetName" :
        return updateForm(state, {
                    name: action._0,
                    description: form$1.description,
                    priceDefault: form$1.priceDefault,
                    currency: form$1.currency,
                    categoryUuid: form$1.categoryUuid,
                    unitUuid: form$1.unitUuid,
                    externalId: form$1.externalId,
                    gtin: form$1.gtin,
                    archived: form$1.archived
                  });
    case "SetDescription" :
        return updateForm(state, {
                    name: form$1.name,
                    description: action._0,
                    priceDefault: form$1.priceDefault,
                    currency: form$1.currency,
                    categoryUuid: form$1.categoryUuid,
                    unitUuid: form$1.unitUuid,
                    externalId: form$1.externalId,
                    gtin: form$1.gtin,
                    archived: form$1.archived
                  });
    case "SetCategory" :
        return updateForm(state, {
                    name: form$1.name,
                    description: form$1.description,
                    priceDefault: form$1.priceDefault,
                    currency: form$1.currency,
                    categoryUuid: action._0,
                    unitUuid: form$1.unitUuid,
                    externalId: form$1.externalId,
                    gtin: form$1.gtin,
                    archived: form$1.archived
                  });
    case "SetUnit" :
        return updateForm(state, {
                    name: form$1.name,
                    description: form$1.description,
                    priceDefault: form$1.priceDefault,
                    currency: form$1.currency,
                    categoryUuid: form$1.categoryUuid,
                    unitUuid: action._0,
                    externalId: form$1.externalId,
                    gtin: form$1.gtin,
                    archived: form$1.archived
                  });
    case "SetGTIN" :
        return updateForm(state, {
                    name: form$1.name,
                    description: form$1.description,
                    priceDefault: form$1.priceDefault,
                    currency: form$1.currency,
                    categoryUuid: form$1.categoryUuid,
                    unitUuid: form$1.unitUuid,
                    externalId: form$1.externalId,
                    gtin: action._0,
                    archived: form$1.archived
                  });
    case "SetPriceDefault" :
        var price = action._0;
        if (price !== undefined) {
          return updateForm(state, {
                      name: form$1.name,
                      description: form$1.description,
                      priceDefault: price.toString(),
                      currency: form$1.currency,
                      categoryUuid: form$1.categoryUuid,
                      unitUuid: form$1.unitUuid,
                      externalId: form$1.externalId,
                      gtin: form$1.gtin,
                      archived: form$1.archived
                    });
        } else {
          return updateForm(state, {
                      name: form$1.name,
                      description: form$1.description,
                      priceDefault: undefined,
                      currency: form$1.currency,
                      categoryUuid: form$1.categoryUuid,
                      unitUuid: form$1.unitUuid,
                      externalId: form$1.externalId,
                      gtin: form$1.gtin,
                      archived: form$1.archived
                    });
        }
    case "SetExternalId" :
        return updateForm(state, {
                    name: form$1.name,
                    description: form$1.description,
                    priceDefault: form$1.priceDefault,
                    currency: form$1.currency,
                    categoryUuid: form$1.categoryUuid,
                    unitUuid: form$1.unitUuid,
                    externalId: action._0,
                    gtin: form$1.gtin,
                    archived: form$1.archived
                  });
    case "SetArchived" :
        return updateForm(state, {
                    name: form$1.name,
                    description: form$1.description,
                    priceDefault: form$1.priceDefault,
                    currency: form$1.currency,
                    categoryUuid: form$1.categoryUuid,
                    unitUuid: form$1.unitUuid,
                    externalId: form$1.externalId,
                    gtin: form$1.gtin,
                    archived: action._0
                  });
    
  }
}

export {
  fromProps ,
  form ,
  updateForm ,
  reducer ,
}
/* Catalog_Types Not a pure module */
