

import * as Case from "../../../utils/Case.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.mjs";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";

function fromString(status) {
  switch (status) {
    case "finished" :
        return "Finished";
    case "in_progress" :
        return "InProgress";
    case "waiting" :
        return "Waiting";
    default:
      return "Unknown";
  }
}

var decode = JsonDecode.map(JsonDecode.string, fromString);

var decode$1 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              status: field.required("status", decode),
              success: RCore.$$Option.getOr(field.required("success", JsonDecode.option(JsonDecode.bool)), true),
              errors: RCore.$$Option.getOr(field.required("errors", JsonDecode.option(JsonDecode.array(JsonDecode.string))), []),
              jobsCreated: RCore.$$Option.getOr(field.required("jobsCreated", JsonDecode.option(JsonDecode.$$int)), 0),
              jobsUpdated: RCore.$$Option.getOr(field.required("jobsUpdated", JsonDecode.option(JsonDecode.$$int)), 0),
              clientsCreated: RCore.$$Option.getOr(field.required("clientsCreated", JsonDecode.option(JsonDecode.$$int)), 0),
              clientsUpdated: RCore.$$Option.getOr(field.required("clientsUpdated", JsonDecode.option(JsonDecode.$$int)), 0),
              sitesCreated: RCore.$$Option.getOr(field.required("sitesCreated", JsonDecode.option(JsonDecode.$$int)), 0),
              sitesUpdated: RCore.$$Option.getOr(field.required("sitesUpdated", JsonDecode.option(JsonDecode.$$int)), 0),
              usersCreated: RCore.$$Option.getOr(field.required("usersCreated", JsonDecode.option(JsonDecode.$$int)), 0),
              usersUpdated: RCore.$$Option.getOr(field.required("usersUpdated", JsonDecode.option(JsonDecode.$$int)), 0)
            };
    });

function get(wire, uuid) {
  return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Job",
                    VAL: {
                      NAME: "UploadShow",
                      VAL: uuid
                    }
                  }
                }), decode$1);
}

function getCurrent(wire) {
  return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Job",
                    VAL: "UploadStatus"
                  }
                }), decode$1);
}

var empty = {
  errors: []
};

var decode$2 = JsonDecode.object(function (field) {
      return {
              errors: RCore.$$Option.getOr(field.optional("errors", JsonDecode.array(JsonDecode.string)), [])
            };
    });

function fromString$1(str) {
  return Core__Result.getOr(Json_Decode$JsonCombinators.decode(Case.camelize(JSON.parse(str)), decode$2), empty);
}

var $$Request = {};

function post(wire, file) {
  var data = new FormData();
  data.append("data", file, undefined);
  return Backend.decode(Backend.post(undefined, true, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Job",
                    VAL: "Upload"
                  }
                }, {
                  NAME: "FormData",
                  VAL: data
                }), decode$1);
}

var Show_Response = {
  Status: {}
};

var Show = {
  $$Response: Show_Response,
  get: get,
  getCurrent: getCurrent
};

var Upload_Error = {
  fromString: fromString$1
};

var Upload = {
  $$Error: Upload_Error,
  $$Request: $$Request,
  post: post
};

export {
  Show ,
  Upload ,
}
/* decode Not a pure module */
