

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as EncodeJson from "../../libraries/EncodeJson.mjs";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";
import * as Pages_Skills_Types from "./Pages_Skills_Types.mjs";

var decode = JsonDecode.object(function (field) {
      return field.required("skills", JsonDecode.array(Pages_Skills_Types.Skill.decode));
    });

function get(wire) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Skills",
                    VAL: "Index"
                  }
                }), decode);
}

function encodeSkill(skill) {
  return EncodeJson.object([
              [
                "uuid",
                EncodeJson.string(Uuid.toString(skill.uuid))
              ],
              [
                "name",
                EncodeJson.string(skill.name)
              ],
              [
                "order_no",
                EncodeJson.$$int(skill.orderNo)
              ],
              [
                "visible",
                EncodeJson.bool(skill.visible)
              ],
              [
                "is_new",
                EncodeJson.bool(skill.isNew)
              ]
            ]);
}

function encode(skills) {
  return EncodeJson.object([[
                "skills",
                EncodeJson.array(encodeSkill)(skills)
              ]]);
}

function patch(wire, skills) {
  return Backend.decode(Backend.patch(undefined, undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Skills",
                    VAL: "Index"
                  }
                }, {
                  NAME: "Obj",
                  VAL: Utils.jsonToObj(encode(skills))
                }), decode);
}

function get$1(wire, uuid) {
  return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Skills",
                    VAL: {
                      NAME: "UsedBy",
                      VAL: Uuid.toString(uuid)
                    }
                  }
                }), Pages_Skills_Types.UsedBy.decode);
}

var UsedBy = {
  get: get$1
};

var Get = {
  get: get
};

var Patch = {
  patch: patch
};

export {
  Get ,
  Patch ,
  UsedBy ,
}
/* decode Not a pure module */
