

import * as El from "../../../../../../../../../libraries/El.mjs";
import * as Units from "../../../../../../../../../types/Units.mjs";
import * as React from "react";
import * as Schedule_State from "../../../../../../../lib/state/Schedule_State.mjs";
import * as WeekTimeline_State from "../../../../../lib/state/WeekTimeline_State.mjs";
import * as WeekTimeline_BarBusinessHoursHook from "./WeekTimeline_BarBusinessHoursHook.mjs";
import * as WeekTimeline_BarBusinessHoursModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/timeline/bar/components/business_hours/WeekTimeline_BarBusinessHours.module.css";

var styles = WeekTimeline_BarBusinessHoursModuleCss;

function WeekTimeline_BarBusinessHours$Component(Props) {
  var assignee = Props.assignee;
  var period = Props.period;
  var sizes = Props.sizes;
  var jobs = Props.jobs;
  var blocks = WeekTimeline_BarBusinessHoursHook.use(assignee, period, sizes, jobs);
  if (blocks !== undefined) {
    return blocks.map(function (param) {
                var status = param[1];
                var left = param[0];
                var tmp;
                if (status !== undefined) {
                  switch (status) {
                    case "Posted" :
                        tmp = styles.posted;
                        break;
                    case "EnRoute" :
                        tmp = styles.enRoute;
                        break;
                    case "Started" :
                        tmp = styles.started;
                        break;
                    case "Suspended" :
                        tmp = styles.suspended;
                        break;
                    case "FinishedSuccessful" :
                        tmp = styles.finishedSuccessful;
                        break;
                    case "FinishedUnsuccessful" :
                        tmp = styles.finishedUnsuccessful;
                        break;
                    
                  }
                } else {
                  tmp = "";
                }
                return React.createElement("div", {
                            key: Units.Px.toString(left),
                            className: El.Cn.concat(styles.line, tmp),
                            style: {
                              left: Units.Px.toString(left)
                            }
                          });
              });
  } else {
    return null;
  }
}

var make = React.memo(WeekTimeline_BarBusinessHours$Component);

function WeekTimeline_BarBusinessHours(Props) {
  var assignee = Props.assignee;
  var period = Schedule_State.usePeriod();
  var jobs = Schedule_State.useAssignedJobs();
  var sizes = WeekTimeline_State.useSizes();
  return React.createElement(make, {
              assignee: assignee,
              period: period,
              sizes: sizes,
              jobs: jobs
            });
}

var make$1 = React.memo(WeekTimeline_BarBusinessHours);

export {
  make$1 as make,
}
/* styles Not a pure module */
