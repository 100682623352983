

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";
import * as Pages_Territories_Types from "./Pages_Territories_Types.mjs";

var decode = JsonDecode.object(function (field) {
      return field.required("territories", JsonDecode.array(Pages_Territories_Types.Index.Territory.decode));
    });

function index(wire) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Territories",
                    VAL: "Index"
                  }
                }), decode);
}

function show(wire, uuid) {
  return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Territories",
                    VAL: {
                      NAME: "Edit",
                      VAL: uuid
                    }
                  }
                }), Pages_Territories_Types.Show.Territory.decode);
}

var Show = {
  show: show
};

function get(wire, uuid) {
  return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Territories",
                    VAL: {
                      NAME: "UsedBy",
                      VAL: Uuid.toString(uuid)
                    }
                  }
                }), Pages_Territories_Types.UsedBy.decode);
}

var UsedBy = {
  get: get
};

function update(wire, territories) {
  var territoryToJs = function (territory) {
    return {
            uuid: territory.uuid,
            order_no: territory.orderNo
          };
  };
  return Backend.decode(Backend.patch(undefined, undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Territories",
                    VAL: "UpdateOrder"
                  }
                }, {
                  NAME: "Obj",
                  VAL: {
                    territories: territories.map(territoryToJs)
                  }
                }), decode);
}

var UpdateOrder = {
  update: update
};

function create(wire, territory) {
  var match = territory.file;
  var tmp;
  tmp = match !== undefined && match.TAG === "New" ? ({
        name: match.name,
        body: match.body
      }) : undefined;
  return Backend.discard(Backend.post(undefined, true, undefined, true, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Territories",
                    VAL: "Index"
                  }
                }, {
                  NAME: "Obj",
                  VAL: {
                    name: territory.name,
                    geojsonFile: tmp,
                    deleteFile: RCore.$$Option.isSome(territory.file)
                  }
                }));
}

var Create = {
  create: create
};

function update$1(wire, uuid, territory) {
  var match = territory.file;
  var tmp;
  tmp = match !== undefined && match.TAG === "New" ? ({
        name: match.name,
        body: match.body
      }) : undefined;
  return Backend.discard(Backend.patch(undefined, true, undefined, true, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Territories",
                    VAL: {
                      NAME: "Edit",
                      VAL: uuid
                    }
                  }
                }, {
                  NAME: "Obj",
                  VAL: {
                    uuid: uuid,
                    name: territory.name,
                    geojsonFile: tmp,
                    deleteFile: RCore.$$Option.isNone(territory.file)
                  }
                }));
}

var Update = {
  update: update$1
};

var decode$1 = JsonDecode.object(function (field) {
      return field.required("territories", JsonDecode.array(Pages_Territories_Types.UsedLayout.Territory.decode));
    });

function validate(wire, territory, uuid) {
  var match = territory.file;
  var tmp;
  tmp = match !== undefined && match.TAG === "New" ? ({
        name: match.name,
        body: match.body
      }) : undefined;
  return Backend.decode(Backend.post(undefined, true, undefined, true, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Territories",
                    VAL: "Validate"
                  }
                }, {
                  NAME: "Obj",
                  VAL: {
                    uuid: uuid,
                    name: territory.name,
                    geojsonFile: tmp
                  }
                }), decode$1);
}

function $$delete(wire, uuid) {
  return Backend.$$delete(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "Territories",
                VAL: {
                  NAME: "Edit",
                  VAL: uuid
                }
              }
            });
}

var Delete = {
  $$delete: $$delete
};

var Index = {
  index: index
};

var Validate = {
  validate: validate
};

var Errors;

export {
  Index ,
  Show ,
  UsedBy ,
  UpdateOrder ,
  Create ,
  Update ,
  Validate ,
  Delete ,
  Errors ,
}
/* decode Not a pure module */
