

import * as React from "react";
import * as DayTimeline_State from "../../../lib/state/DayTimeline_State.mjs";
import * as Schedule_TimelineJob from "../../../../../components/timeline/job/Schedule_TimelineJob.mjs";
import * as DayTimeline_UnassignedJobAssignJob from "./assign_button/DayTimeline_UnassignedJobAssignJob.mjs";

function DayTimeline_UnassignedJob(Props) {
  var position = Props.position;
  var job = Props.job;
  var match = DayTimeline_State.useTimelineSubscriptions();
  var width = position.width;
  var left = position.left;
  var top = position.top;
  var finishedOutside = position.finishedOutside;
  var startedOutside = position.startedOutside;
  var onScrollStart = match.onScrollStart;
  var match$1 = position.size.contents;
  var size;
  switch (match$1) {
    case "Full" :
        size = "Full";
        break;
    case "Half" :
        size = "Half";
        break;
    case "OneThird" :
        size = "OneThird";
        break;
    
  }
  return React.createElement(DayTimeline_UnassignedJobAssignJob.make, {
              position: position,
              job: job,
              children: (function (elementRef) {
                  return React.createElement(Schedule_TimelineJob.make, {
                              elementRef: elementRef,
                              job: {
                                TAG: "Unassigned",
                                _0: job
                              },
                              size: size,
                              top: top,
                              left: left,
                              width: width,
                              startedOutside: startedOutside,
                              finishedOutside: finishedOutside,
                              onScrollStart: onScrollStart,
                              bar: undefined
                            });
                })
            });
}

var make = DayTimeline_UnassignedJob;

export {
  make ,
}
/* react Not a pure module */
