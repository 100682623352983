

import * as RCore from "../../../../libraries/RCore.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../../common/Optional.mjs";
import * as JobUpload from "../JobUpload.mjs";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as Context_Types from "../../../../context/Context_Types.mjs";
import * as JobIndexSearch from "./JobIndexSearch.mjs";
import * as JobIndexBulkButtons from "./JobIndexBulkButtons.mjs";
import * as JobIndexExportButton from "./JobIndexExportButton.mjs";

var title$p = Utils.Translations.tr("js.jobs.index.title");

var count$p = Utils.Translations.txr("js.jobs.index.bulk.count");

function JobIndexHeader(Props) {
  var onJobSearch = Props.onJobSearch;
  var pickingForBulk = Props.pickingForBulk;
  var exportJobs = Props.exportJobs;
  var operationSet = Props.operationSet;
  var count = Props.count;
  var createBulkOperation = Props.createBulkOperation;
  var send = Props.send;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var match$1;
  if (operationSet !== undefined) {
    if (operationSet.TAG === "Uuids") {
      var uuids = operationSet._0;
      match$1 = uuids.length !== 0 ? [
          false,
          uuids.length
        ] : [
          true,
          undefined
        ];
    } else {
      var exit = 0;
      if (typeof count !== "object") {
        match$1 = [
          true,
          undefined
        ];
      } else {
        exit = 1;
      }
      if (exit === 1) {
        var jobs = count._0;
        match$1 = [
          jobs === 0,
          jobs - operationSet._0.length | 0
        ];
      }
      
    }
  } else {
    match$1 = [
      true,
      undefined
    ];
  }
  var bulkCount = match$1[1];
  var disableBulkButtons = match$1[0];
  var match$2 = ctx.permissions;
  var match$3;
  if (match$2 !== undefined) {
    var match$4 = match$2.jobs;
    match$3 = match$4 === "ViewEdit" ? [
        true,
        false
      ] : (
        match$4 === "ViewEditDelete" ? [
            true,
            true
          ] : [
            false,
            false
          ]
      );
  } else {
    match$3 = [
      false,
      false
    ];
  }
  var match$5 = ctx.permissions;
  var hasExportPermission = match$5 !== undefined ? match$5.jobsExport === "View" : false;
  return React.createElement("div", {
              className: "j-header"
            }, React.createElement("div", {
                  className: "j-header__hl"
                }, title$p(ctx)), React.createElement("button", {
                  className: "j-header__refresh-btn btn btn-32px",
                  onClick: (function (param) {
                      send("RefreshJobs");
                    })
                }), React.createElement("div", {
                  className: "j-header__divider"
                }), pickingForBulk ? React.createElement(React.Fragment, undefined, React.createElement("span", {
                        className: "j-header__bulk-count"
                      }, RCore.$$Option.getOr(RCore.$$Option.map(bulkCount, (function (v) {
                                  return count$p({
                                              count: v
                                            }, ctx);
                                })), null)), React.createElement(JobIndexBulkButtons.Cancel.make, {
                        onClick: (function () {
                            send("CancelOperationPicking");
                          })
                      }), React.createElement(Optional.make, {
                        show: match$3[1],
                        children: React.createElement(JobIndexBulkButtons.Delete.make, {
                              onSubmit: (function () {
                                  createBulkOperation("Delete");
                                }),
                              disabled: disableBulkButtons,
                              bulkCount: RCore.$$Option.getOr(bulkCount, 0)
                            })
                      }), React.createElement(JobIndexBulkButtons.Assign.make, {
                        onSubmit: (function (assignees) {
                            createBulkOperation({
                                  TAG: "AssignMultiple",
                                  _0: assignees
                                });
                          }),
                        disabled: disableBulkButtons,
                        bulkCount: RCore.$$Option.getOr(bulkCount, 0)
                      }), React.createElement(JobIndexBulkButtons.Schedule.make, {
                        onSubmit: (function (date) {
                            createBulkOperation({
                                  TAG: "Schedule",
                                  _0: date
                                });
                          }),
                        disabled: disableBulkButtons,
                        bulkCount: RCore.$$Option.getOr(bulkCount, 0)
                      })) : React.createElement(React.Fragment, undefined, React.createElement(JobIndexSearch.make, {
                        onSearch: onJobSearch
                      }), React.createElement(JobUpload.Button.make, {}), React.createElement(Optional.make, {
                        show: hasExportPermission,
                        children: React.createElement(JobIndexExportButton.make, {
                              exportJobs: exportJobs
                            })
                      }), React.createElement(Optional.make, {
                        show: Context_Types.Features.hasFlag("bulkOperations", ctx.features) && match$3[0],
                        children: React.createElement(JobIndexBulkButtons.Pick.make, {
                              onClick: (function () {
                                  send("PickForOperation");
                                })
                            })
                      })));
}

var make = JobIndexHeader;

export {
  make ,
}
/* title' Not a pure module */
