

import * as El from "../../../libraries/El.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Route from "../../../types/Route.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Template_API from "./Template_API.mjs";
import * as JobSettingsTabs from "../JobSettingsTabs.mjs";
import * as JobSettingsHeader from "../JobSettingsHeader.mjs";

var templates$p = Utils.Translations.tr("js.admin.settings.title.templates");

var addTemplate$p = Utils.Translations.tr("js.templates.index.add_template");

function Template_Index(Props) {
  var wire = Props.wire;
  var match = React.useState(function () {
        
      });
  var navigate = wire.navigate;
  var ctx = wire.ctx;
  var setTemplates = match[1];
  var templates = match[0];
  var openTemplate = function (route) {
    return function ($$event) {
      $$event.preventDefault();
      navigate(undefined, route);
    };
  };
  var response = function (data) {
    if (data.TAG !== "Ok") {
      return ;
    }
    var templates = data._0.templates;
    setTemplates(function (param) {
          return templates;
        });
  };
  React.useEffect((function () {
          Backend.$$finally(Template_API.Index.index(wire), response);
        }), []);
  if (templates === undefined) {
    return null;
  }
  var templatesList = templates.length > 0 ? React.createElement("table", {
          className: "table table-hover"
        }, React.createElement("tbody", undefined, templates.map(function (t) {
                  return React.createElement("tr", {
                              key: Uuid.toString(t.uuid)
                            }, React.createElement("td", undefined, React.createElement("a", {
                                      href: Route.pagePath(undefined, {
                                            NAME: "Template",
                                            VAL: {
                                              NAME: "Edit",
                                              VAL: t.uuid
                                            }
                                          }),
                                      onClick: openTemplate({
                                            NAME: "Template",
                                            VAL: {
                                              NAME: "Edit",
                                              VAL: t.uuid
                                            }
                                          })
                                    }, t.name)));
                }))) : null;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "page-container narrow"
                }, React.createElement(JobSettingsHeader.make, {
                      ctx: ctx
                    }), React.createElement("div", {
                      className: "settings-page settings-page_template row"
                    }, React.createElement(JobSettingsTabs.make, {
                          wire: wire,
                          tab: "Templates"
                        }), React.createElement("div", {
                          className: "tab-content"
                        }, React.createElement("div", {
                              className: "tab-pane active"
                            }, React.createElement("div", {
                                  className: "panel"
                                }, React.createElement("div", {
                                      className: "panel-body"
                                    }, React.createElement("div", {
                                          className: "panel-body__hl"
                                        }, templates$p(ctx)), React.createElement("a", {
                                          className: "add-link btn btn-outline btn-32px",
                                          href: Route.pagePath(undefined, {
                                                NAME: "Template",
                                                VAL: {
                                                  NAME: "New",
                                                  VAL: undefined
                                                }
                                              }),
                                          onClick: (function ($$event) {
                                              $$event.preventDefault();
                                              navigate(undefined, {
                                                    NAME: "Template",
                                                    VAL: {
                                                      NAME: "New",
                                                      VAL: undefined
                                                    }
                                                  });
                                            })
                                        }, React.createElement("i", {
                                              className: Icon.style(undefined, undefined, "plus")
                                            }), El.space, addTemplate$p(ctx)), templatesList)))))));
}

var make = Template_Index;

export {
  make ,
}
/* templates' Not a pure module */
