

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as React from "react";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";
import * as Types_Address from "../../types/Types_Address.mjs";
import * as Types_Contact from "../../types/Types_Contact.mjs";
import * as JobEdit_JobType from "../job/edit/lib/types/JobEdit_JobType.mjs";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";
import Client_select from "planado/components/input/client_select";

function encode(value) {
  var uuid;
  var name;
  if (value === undefined) {
    return ;
  }
  if (value.TAG === "Client") {
    var match = value._0;
    if (match.TAG === "Organization") {
      var match$1 = match._0;
      if (match$1.TAG !== "Edit") {
        return ;
      }
      var match$2 = match$1._0;
      uuid = match$2.uuid;
      name = match$2.name;
    } else {
      var match$3 = match._0;
      if (match$3.TAG !== "Edit") {
        return ;
      }
      var match$4 = match$3._0;
      uuid = match$4.uuid;
      name = match$4.name;
    }
  } else {
    var match$5 = value._0;
    if (match$5.TAG !== "Edit") {
      return ;
    }
    var match$6 = match$5._0;
    var match$7 = match$6.client;
    var name$1 = match$6.name;
    var uuid$1 = match$6.uuid;
    if (match$7 !== undefined) {
      return {
              label: Js_null.fromOption(RCore.$$Option.getOr(name$1, "") + " - " + RCore.$$Option.getOr(match$7.name, "")),
              value: Js_null.fromOption(Uuid.toString(uuid$1)),
              type: "site",
              address: null,
              data: null
            };
    } else {
      return {
              label: Js_null.fromOption(name$1),
              value: Js_null.fromOption(Uuid.toString(uuid$1)),
              type: "site",
              address: null,
              data: null
            };
    }
  }
  return {
          label: Js_null.fromOption(RCore.$$Option.getOr(name, "")),
          value: Js_null.fromOption(Uuid.toString(uuid)),
          type: "site",
          address: null,
          data: null
        };
}

function decode(field) {
  var unknown = field.required("type", JsonDecode.string);
  switch (unknown) {
    case "client" :
        if (field.required("organization", JsonDecode.bool)) {
          return {
                  TAG: "Organization",
                  _0: JobEdit_JobType.Client.Organization.Edit.decode(field)
                };
        } else {
          return {
                  TAG: "Individual",
                  _0: JobEdit_JobType.Client.Individual.Edit.decode(field)
                };
        }
    case "site" :
        return {
                TAG: "Site",
                _0: JobEdit_JobType.Site.Edit.decode(field)
              };
    default:
      return JsonDecode.cannotDecode("Unknown client type: " + unknown);
  }
}

var FoundClient = {
  decode: decode
};

var decode$1 = JsonDecode.object(function (field) {
      return field.required("data", JsonDecode.object(function (field) {
                      return {
                              value: decode(field),
                              contacts: field.required("contacts", JsonDecode.array(Types_Contact.decode)),
                              floor: field.required("floor", JsonDecode.option(JsonDecode.string)),
                              apartment: field.required("apartment", JsonDecode.option(JsonDecode.string)),
                              entranceNo: field.required("entranceNo", JsonDecode.option(JsonDecode.string)),
                              addressDescription: field.required("addressDescription", JsonDecode.option(JsonDecode.string)),
                              territoryUuid: field.required("territoryUuid", JsonDecode.option(Uuid.decode)),
                              address: field.required("address", JsonDecode.option(Types_Address.decode))
                            };
                    }));
    });

function decodeJson(json) {
  if (json === null) {
    return ;
  }
  var a = Json_Decode$JsonCombinators.decode(json, decode$1);
  if (a.TAG === "Ok") {
    return a._0;
  }
  console.log(a._0);
}

var Result = {
  FoundClient: FoundClient,
  decode: decode$1,
  decodeJson: decodeJson
};

function ClientSelect(Props) {
  var id = Props.id;
  var name = Props.name;
  var value = Props.value;
  var _type = Props._type;
  var onValueChange = Props.onValueChange;
  var placeholder = Props.placeholder;
  var readOnly = Props.readOnly;
  var tmp = {
    id: id,
    value: Js_null.fromOption(encode(value)),
    type: _type,
    onValueChange: (function (json) {
        onValueChange(decodeJson(json));
      }),
    readOnly: readOnly
  };
  if (name !== undefined) {
    tmp.name = name;
  }
  if (placeholder !== undefined) {
    tmp.placeholder = placeholder;
  }
  return React.createElement(Client_select, tmp);
}

var make = ClientSelect;

export {
  Result ,
  make ,
}
/* decode Not a pure module */
