

import * as El from "../../../../El.mjs";
import * as Utils from "../../../../../utils/Utils.mjs";
import * as React from "react";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as Bindings_Slate from "../../../../../bindings/Bindings_Slate.mjs";
import * as Liquid_Variable from "../../../parser/Liquid_Variable.mjs";
import * as Liquid_EditorTemplate from "../../lib/Liquid_EditorTemplate.mjs";
import * as Liquid_EditorAddSubstitutionButtonModuleCss from "/home/runner/work/planado/planado/client/rescript/libraries/liquid/editor/components/add_substitution_button/Liquid_EditorAddSubstitutionButton.module.css";

var styles = Liquid_EditorAddSubstitutionButtonModuleCss;

var text$p = Utils.Translations.tr("js.settings.sms.add_substitution");

function Liquid_EditorAddSubstitutionButton(Props) {
  var editor = Props.editor;
  var match = AppContext.useWire();
  var onClick = function (_event) {
    var variableElement = [Liquid_EditorTemplate.makeVariableElement(Liquid_Variable.empty)];
    Bindings_Slate.SlateTransforms.insertNodes(editor, variableElement);
    Bindings_Slate.React.ReactEditor.focus(editor);
  };
  return React.createElement("button", {
              className: styles.button,
              type: "button",
              onClick: onClick
            }, React.createElement("span", {
                  className: El.Cn.concat("fa fa-plus", styles.icon)
                }), El.nbsp, text$p(match.ctx));
}

var make = Liquid_EditorAddSubstitutionButton;

export {
  make ,
}
/* styles Not a pure module */
