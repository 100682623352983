import React from 'react'
import FieldsList from './fields_list'
import { withContext } from 'planado/utils/contextConsumer.jsx'

export default withContext(
  ({
    reportFields,
    customFields,
    dictionaries,
    actions,
    possibleFieldNames = {},
    ctx
  }) => {
    const CUSTOM_FIELD = false

    const types = [
      {
        fieldType: 'image',
        dataType: 'attachment',
        text: ctx.t('js.templates.custom_fields.options.image'),
        new: false,
        available: true
      },
      {
        fieldType: 'input',
        dataType: 'string',
        text: ctx.t('js.templates.custom_fields.options.input'),
        new: false,
        available: true
      },
      {
        fieldType: 'checkbox',
        dataType: 'boolean',
        text: ctx.t('js.templates.custom_fields.options.checkbox'),
        new: false,
        available: true
      },
      {
        fieldType: 'dictionary',
        dataType: 'dictionary',
        text: ctx.t('js.templates.custom_fields.options.dictionary'),
        new: false,
        available: true
      },
      {
        fieldType: 'action',
        dataType: 'boolean',
        text: ctx.t('js.templates.custom_fields.options.action'),
        new: false,
        available: true
      },
      {
        fieldType: 'barcode',
        dataType: 'string',
        text: ctx.t('js.templates.custom_fields.options.barcode'),
        new: false,
        available: true
      },
      {
        fieldType: 'file',
        dataType: 'attachment',
        text: ctx.t('js.templates.custom_fields.options.file'),
        new: false,
        available: true
      },
      {
        fieldType: 'signature',
        dataType: 'attachment',
        text: ctx.t('js.templates.custom_fields.options.signature'),
        new: false,
        available: ctx.features.flags.includes('signature')
      },
      {
        fieldType: 'currency',
        dataType: 'currency',
        text: ctx.t('js.templates.custom_fields.options.currency'),
        new: false,
        available: true
      },
      {
        fieldType: 'input',
        dataType: 'decimal',
        text: ctx.t('js.templates.custom_fields.options.decimal'),
        new: false,
        available: true
      },
      {
        fieldType: 'input',
        dataType: 'integer',
        text: ctx.t('js.templates.custom_fields.options.integer'),
        new: false,
        available: true
      },
      {
        fieldType: 'date_picker',
        dataType: 'date',
        text: ctx.t('js.templates.custom_fields.options.date'),
        new: false,
        available: true
      },
      {
        fieldType: 'time_picker',
        dataType: 'time',
        text: ctx.t('js.templates.custom_fields.options.time'),
        new: false,
        available: true
      },
      {
        fieldType: 'datetime_picker',
        dataType: 'datetime',
        text: ctx.t('js.templates.custom_fields.options.datetime'),
        new: false,
        available: true
      },
      {
        fieldType: 'duration',
        dataType: 'duration',
        text: ctx.t('js.templates.custom_fields.options.duration'),
        new: true,
        available: true
      },
      {
        fieldType: 'location',
        dataType: 'location',
        text: ctx.t('js.templates.custom_fields.options.location'),
        new: true,
        available: true
      },
      {
        fieldType: 'link',
        dataType: 'url',
        text: ctx.t('js.templates.custom_fields.options.link'),
        new: false,
        available: true
      },
      {
        fieldType: 'button',
        dataType: 'url',
        text: ctx.t('js.templates.custom_fields.options.button'),
        new: false,
        available: ctx.features.flags.includes('api')
      }
    ]

    const onDragEnd = (fields) =>
      actions.moveCustomField(fields, CUSTOM_FIELD)

    const availableTypes = types.filter(t => t.available)

    return (
      <FieldsList
        isCustomField={CUSTOM_FIELD}
        types={
          ctx.features.flags.includes('newFields')
            ? availableTypes
            : availableTypes.filter(t => !t.new)
        }
        fields={reportFields}
        customFields={customFields}
        dictionaries={dictionaries}
        possibleFieldNames={possibleFieldNames}
        actions={actions}
        onDragEnd={onDragEnd}
      />
    )
  }
)
