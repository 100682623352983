import React from 'react'
import Hint from 'planado/components/hint'
import CatalogLink from 'planado/containers/admin/templates/catalog_link'
import { withContext } from 'planado/utils/contextConsumer.jsx'

export default withContext(({
  ctx,
  availableServices,
  availableServiceCategories,
  setServices,
  setCategories,
  selectedServices,
  selectedServiceCategories
}) => (
  <section className="form-section">
    <h2 className="form-section__title">
      {ctx.t('js.templates.services.title')}
      <Hint
        content={ctx.t('js.templates.services.hint')}
        modifiers={{ hint_title: true }}
      />
    </h2>

    <div className="form-container">
      <CatalogLink
        availableServices={availableServices}
        availableServiceCategories={availableServiceCategories}
        setServices={setServices}
        setCategories={setCategories}
        selectedServices={selectedServices}
        selectedServiceCategories={selectedServiceCategories}
      />
    </div>
  </section>
))
