import { requestCounter } from 'planado/globals.js'
import { camelize, decamelize } from 'planado/utils/index.js'
import {
  credentials,
  defaultHeaders,
  defaultHeadersWithContentType
} from 'planado/utils/network_config.js'
import digest from 'planado/utils/digest.js'

const appendHeaders = (existingHeaders, newHeaders) => {
  for (const key in newHeaders) {
    if (existingHeaders.has(key)) {
      existingHeaders.set(key, newHeaders[key])
    } else {
      existingHeaders.append(key, newHeaders[key])
    }
  }

  return existingHeaders
}

export const makeSignatureHeader = ctx => {
  const now = Date.now() / 1000
  const signature = digest(
    ctx.tenantUuid.toString() + '-' + (now - (now % 300)).toString()
  )

  return { 'X-Request-Signature': signature }
}

const withOptionalSignatureHeader = (existingHeaders, ctx) => {
  if (ctx && ctx.features.flags.includes('webApiSignature')) {
    return appendHeaders(
      existingHeaders || new Headers({}),
      makeSignatureHeader(ctx)
    )
  } else {
    return existingHeaders
  }
}

export async function fetchJSON(url, ctx) {
  const requestId = requestCounter.register()

  const data = await await fetch(url, {
    ...credentials,
    headers: withOptionalSignatureHeader(defaultHeaders, ctx)
  }).then(
    r => {
      requestCounter.unregister(requestId)

      if (r.status >= 200 && r.status < 300) {
        return r.json()
      } else {
        return Promise.reject(r)
      }
    },
    e => (requestCounter.unregister(requestId), console.error(e))
  )

  return data
}

const optionsWithSignatureHeader = (options, ctx) => {
  return {
    ...options,
    headers: withOptionalSignatureHeader(options.headers, ctx)
  }
}

export async function fetchJSONC(url, ctx, headers = {}) {
  const requestId = requestCounter.register()

  const data = await await fetch(url, {
    ...credentials,
    headers: withOptionalSignatureHeader(
      appendHeaders(defaultHeaders, headers),
      ctx
    )
  }).then(
    r => {
      requestCounter.unregister(requestId)

      if (r.status >= 200 && r.status < 300) {
        return r.json().then(camelize)
      } else {
        return Promise.reject(r)
      }
    },
    e => (requestCounter.unregister(requestId), console.error(e))
  )

  return data
}

export async function sendRequest(url, ctx, options = {}) {
  const requestId = requestCounter.register()

  const params = Object.assign(
    {},
    credentials,
    optionsWithSignatureHeader(options, ctx)
  )

  const data = await await fetch(url, params).then(
    r => {
      requestCounter.unregister(requestId)

      if (r.status >= 200 && r.status < 300) {
        return r.json().then(camelize)
      } else {
        return Promise.reject(r)
      }
    },
    e => (requestCounter.unregister(requestId), console.error(e))
  )

  return data
}

export async function sendJSONRequest(url, ctx, { body, ...options }) {
  // TODO: remove the undefined coercion once all `undefined`-able parts are coerced to null
  //
  // 1. Address select (fields can be undefined)
  return sendRequest(url, ctx, {
    ...options,
    headers: defaultHeadersWithContentType,
    body: JSON.stringify(decamelize(body), (k, v) =>
      v === undefined ? null : v
    )
  })
}

export function redirect(url) {
  window.location = url
}
