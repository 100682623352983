

import * as RCore from "../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../../../common/Optional.mjs";
import * as FileInput_Preview_RemoveButton from "./FileInput_Preview_RemoveButton.mjs";
import * as FileInput_Preview_DownloadButton from "./FileInput_Preview_DownloadButton.mjs";
import * as FileInput_PreviewModuleCss from "/home/runner/work/planado/planado/client/rescript/components/file/file_input/components/preview/FileInput_Preview.module.css";

var styles = FileInput_PreviewModuleCss;

function FileInput_Preview_File(Props) {
  var url = Props.url;
  var file = Props.file;
  var removeFile = Props.removeFile;
  var readOnly = Props.readOnly;
  var fileSize = "(" + Utils.Translations.fileSize(RCore.$$Option.getOr(file.size, 0)) + ")";
  return React.createElement("div", {
              className: styles.fileWrapper
            }, React.createElement("div", {
                  className: styles.fileNameWrapper
                }, React.createElement("div", {
                      className: styles.fileName
                    }, file.name), React.createElement("div", undefined, fileSize)), React.createElement("div", {
                  className: styles.imageButtons
                }, React.createElement(Optional.make, {
                      show: !readOnly,
                      children: React.createElement(FileInput_Preview_RemoveButton.make, {
                            removeFile: removeFile,
                            decoration: "Dark"
                          })
                    }), React.createElement(FileInput_Preview_DownloadButton.make, {
                      url: url,
                      name: file.name,
                      decoration: "Dark"
                    })));
}

var make = FileInput_Preview_File;

export {
  make ,
}
/* styles Not a pure module */
