import React from 'react'
import classNames from 'classnames'

const Switch = ({ states, onValueChange, name, value, autoWidth = false, emptyDefaultValue = false }) => {
  const tabs = () =>
    states.map((item, i) => {
      const active = value === null ? i === 0 : value === item.value
      const className = classNames({
        'switch-tabs__item': true,
        'switch-tabs__item_autowidth': autoWidth,
        'is-active': active
      })

      return (
        <button
          type="button"
          onClick={_ => onValueChange(item.value)}
          key={i}
          data-name={name}
          data-value={item.value}
          className={className}
        >
          {item.name}
        </button>
      )
    })

  const inputValue = () => {
    if (emptyDefaultValue) {
      return ''
    } else if (value === null || value === undefined) {
      return states[0].value
    } else {
      return value
    }
  }

  return (
    <div className="switch">
      <input
        value={inputValue()}
        className="switch__input"
        name={name || ''}
        type="hidden"
      />

      <div className="switch-tabs">{tabs()}</div>
    </div>
  )
}

export default Switch
