

import * as Types from "../../types/Types.mjs";
import * as React from "react";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as DomUtils from "../../DomUtils.mjs";
import * as AppContext from "../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Webapi2_Event from "../webapi/Webapi2_Event.mjs";

function useOnClickOutside(disabledOpt, elementRef, handler) {
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var match = AppContext.useWire();
  var subscriptions = match.subscriptions;
  React.useEffect((function () {
          var element = Types.ReactRef.toOption(elementRef);
          if (element === undefined) {
            return ;
          }
          if (disabled) {
            return ;
          }
          var element$1 = Caml_option.valFromOption(element);
          var onClick = function ($$event) {
            if (!DomUtils.contains(element$1, $$event.target)) {
              return handler();
            }
            
          };
          var unsub = subscriptions.click.subscribe(onClick);
          return (function () {
                    unsub();
                  });
        }), [
        elementRef.current,
        handler,
        disabled
      ]);
}

function useToggleOnClick1(elementRef, shown, setShow) {
  var match = AppContext.useWire();
  var subscriptions = match.subscriptions;
  React.useEffect((function () {
          var unsub = subscriptions.click.subscribe(function ($$event) {
                var buttonEl = elementRef.current;
                if (buttonEl == null) {
                  return ;
                }
                var targetNode = Webapi2_Event.fromMouseEvent($$event).target;
                var show = buttonEl.contains(targetNode) ? !shown : false;
                setShow(show);
              });
          return (function () {
                    unsub();
                  });
        }), [
        shown,
        elementRef
      ]);
}

function useOnEscPress(disabledOpt, handler) {
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var match = AppContext.useWire();
  var subscriptions = match.subscriptions;
  React.useEffect((function () {
          if (disabled) {
            return ;
          }
          var unsub = subscriptions.keydown.subscribe(function (param) {
                var $$event = param[0];
                var match = $$event.code;
                if (match === "Escape") {
                  return handler();
                } else {
                  return param[1]($$event);
                }
              });
          return (function () {
                    unsub();
                  });
        }), [
        disabled,
        handler
      ]);
}

function useOnMount(handler) {
  React.useEffect((function () {
          handler();
        }), []);
}

function useLocked(locked) {
  var match = React.useState(function () {
        return locked;
      });
  var setLocked = match[1];
  var lock = React.useCallback((function () {
          setLocked(function (param) {
                return true;
              });
        }), []);
  var unlock = React.useCallback((function () {
          setLocked(function (param) {
                return false;
              });
        }), []);
  return [
          match[0],
          lock,
          unlock
        ];
}

function useId(id) {
  return React.useMemo((function () {
                if (id !== undefined) {
                  return id;
                } else {
                  return "id-" + Js_math.random_int(0, 1000000000).toString();
                }
              }), [id]);
}

function usePrevious(value) {
  var refValue = React.useRef(undefined);
  React.useEffect((function () {
          refValue.current = value;
        }), [value]);
  return refValue.current;
}

function useCustomCompareEffect1(callback, deps, comparator) {
  var dependencies = React.useRef(undefined);
  React.useEffect((function () {
          var old = dependencies.current;
          var exit = 0;
          if (!(old !== undefined && comparator(Caml_option.valFromOption(old), deps))) {
            exit = 1;
          }
          if (exit === 1) {
            dependencies.current = Caml_option.some(deps);
            callback();
          }
          
        }), [deps]);
}

var InfiniteScroll;

export {
  InfiniteScroll ,
  useOnClickOutside ,
  useToggleOnClick1 ,
  useOnEscPress ,
  useOnMount ,
  useLocked ,
  useId ,
  usePrevious ,
  useCustomCompareEffect1 ,
}
/* Types Not a pure module */
