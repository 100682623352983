

import * as El from "../../../../libraries/El.mjs";
import * as Tour from "../../../../libraries/tours/Tour.mjs";
import * as Modal from "../../../modal/Modal.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Title from "../../../common/Title.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../../common/Optional.mjs";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JobFormBody from "./components/body/JobFormBody.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.mjs";
import * as Context_Types from "../../../../context/Context_Types.mjs";
import * as Backend_Errors from "../../../../libraries/backend/Backend_Errors.mjs";
import * as JobForm_Footer from "./components/footer/JobForm_Footer.mjs";
import * as JobForm_Heading from "./components/heading/JobForm_Heading.mjs";
import * as JobForm_History from "./components/history/JobForm_History.mjs";
import * as JobForm_Reports from "./components/reports/JobForm_Reports.mjs";
import * as JobForm_Services from "./components/services/JobForm_Services.mjs";
import * as JobForm_Validator from "../lib/utils/JobForm_Validator.mjs";
import * as JobEdit_StateHooks from "../lib/state/JobEdit_StateHooks.mjs";
import * as JobForm_LinkedJobs from "./components/linked_jobs/JobForm_LinkedJobs.mjs";
import * as JobForm_StatsPanel from "./components/stats_panel/JobForm_StatsPanel.mjs";
import * as JobForm_CancelPopup from "./components/cancel_popup/JobForm_CancelPopup.mjs";
import * as JobForm_FinishPopup from "./components/finish_popup/JobForm_FinishPopup.mjs";
import * as JobFormModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/edit/form/JobForm.module.css";

var styles = JobFormModuleCss;

function validateCustomFields(wire, customFields) {
  var match = JobForm_Validator.CustomFields.validate(customFields, wire);
  if (!match[0]) {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
  var errors_customFields = match[1];
  var errors = {
    job: /* [] */0,
    response: Backend_Errors.empty,
    customFields: errors_customFields
  };
  return {
          TAG: "Error",
          _0: {
            TAG: "CustomFieldErrors",
            _0: errors
          }
        };
}

var confirmRemoval$p = Utils.Translations.tr("js.jobs.modals.delete.title");

var confirmationBody$p = Utils.Translations.txh("js.jobs.modals.delete.body");

var remove$p = Utils.Translations.tr("js.jobs.modals.delete.ok");

function JobForm$JobBody(Props) {
  var wire = Props.wire;
  var job = Props.job;
  var setLock = Props.setLock;
  var providedServices = job.providedServices;
  var availableServices = job.availableServices;
  var reportFields = job.reportFields;
  var status = job.status;
  var customFieldsErrors = JobEdit_StateHooks.useCustomFieldsErrors();
  var jobErrors = JobEdit_StateHooks.useJobErrors();
  var dispatch = JobEdit_StateHooks.useDispatch();
  return React.createElement("form", {
              className: "job-form job-form-centered"
            }, React.createElement("div", {
                  className: "job-form-body"
                }, React.createElement(JobFormBody.make, {
                      wire: wire,
                      job: job,
                      dispatch: dispatch,
                      setLock: setLock,
                      customFieldsErrors: customFieldsErrors,
                      jobErrors: jobErrors
                    }), React.createElement(Optional.make, {
                      show: Context_Types.Features.hasFlag("catalog", wire.ctx.features) && availableServices.length > 0 || providedServices.length > 0,
                      children: React.createElement(JobForm_Services.make, {
                            ctx: wire.ctx,
                            availableServices: availableServices,
                            orderedServices: job.orderedServices,
                            providedServices: providedServices,
                            setProvidedServices: (function (v) {
                                dispatch({
                                      TAG: "Job",
                                      _0: {
                                        TAG: "SetProvidedServices",
                                        _0: v
                                      }
                                    });
                              }),
                            setOrderedServices: (function (v) {
                                dispatch({
                                      TAG: "Job",
                                      _0: {
                                        TAG: "SetOrderedServices",
                                        _0: v
                                      }
                                    });
                              }),
                            status: status
                          })
                    }), React.createElement(Optional.make, {
                      show: reportFields.length > 0,
                      children: React.createElement(JobForm_Reports.make, {
                            wire: wire,
                            reportFields: reportFields,
                            addFile: (function (fieldUuid, file) {
                                dispatch({
                                      TAG: "Job",
                                      _0: {
                                        TAG: "SetReportFieldFile",
                                        _0: fieldUuid,
                                        _1: file
                                      }
                                    });
                              }),
                            removeFile: (function (fieldUuid, uuid) {
                                dispatch({
                                      TAG: "Job",
                                      _0: {
                                        TAG: "RemoveReportFieldFile",
                                        _0: fieldUuid,
                                        _1: uuid
                                      }
                                    });
                              }),
                            jobUuid: job.uuid,
                            trackMovements: job.trackMovements,
                            status: status,
                            odometerM: job.odometerM,
                            setOdometerM: (function (v) {
                                dispatch({
                                      TAG: "Job",
                                      _0: {
                                        TAG: "SetOdometerM",
                                        _0: v
                                      }
                                    });
                              }),
                            updateReportField: (function (field) {
                                dispatch({
                                      TAG: "Job",
                                      _0: {
                                        TAG: "UpdateReportField",
                                        _0: field
                                      }
                                    });
                              }),
                            setLock: setLock
                          })
                    })));
}

function JobForm(Props) {
  var subroute = Props.subroute;
  var onClose = Props.onClose;
  var onCopy = Props.onCopy;
  var onNextJob = Props.onNextJob;
  var onCompletionJob = Props.onCompletionJob;
  var onRemove = Props.onRemove;
  var onFinish = Props.onFinish;
  var onCancel = Props.onCancel;
  var removeJob = Props.removeJob;
  var toggleHistory = Props.toggleHistory;
  var hideConfirmation = Props.hideConfirmation;
  var showHistory = Props.showHistory;
  var locked = Props.locked;
  var saving = Props.saving;
  var setLock = Props.setLock;
  var confirmation = Props.confirmation;
  var confirmationLocked = Props.confirmationLocked;
  var submitWorkflowError = Props.submitWorkflowError;
  var submitJob = Props.submitJob;
  var cancelJob = Props.cancelJob;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var job = JobEdit_StateHooks.useJob();
  var customFields = job.customFields;
  var resolutionComment = job.resolutionComment;
  var resolution = job.resolution;
  var status = job.status;
  var templateUuid = job.templateUuid;
  var serialNo = job.serialNo;
  var uuid = job.uuid;
  var tour = Tour.use(wire);
  React.useEffect((function () {
          if (typeof subroute === "object" && subroute.NAME === "New" && templateUuid !== undefined) {
            tour.trigger({
                  NAME: "jobFormOpenedByTemplate",
                  VAL: Caml_option.valFromOption(templateUuid)
                });
            return ;
          }
          
        }), []);
  var submitForm = function (values) {
    var submitJob$1 = function () {
      submitJob(values);
    };
    var res = Core__Result.flatMap(Core__Result.flatMap(validateCustomFields(wire, customFields), (function () {
                var ctx = wire.ctx;
                var errors = JobForm_Validator.ClientSite.validate(ctx, job);
                if (errors === undefined) {
                  return {
                          TAG: "Ok",
                          _0: undefined
                        };
                }
                var errors_customFields = [];
                var errors$1 = {
                  job: errors,
                  response: Backend_Errors.empty,
                  customFields: errors_customFields
                };
                return {
                        TAG: "Error",
                        _0: {
                          TAG: "ClientSiteErrors",
                          _0: errors$1
                        }
                      };
              })), (function () {
            return {
                    TAG: "Ok",
                    _0: undefined
                  };
          }));
    if (res.TAG === "Ok") {
      return submitJob$1();
    } else {
      return submitWorkflowError(res._0);
    }
  };
  var setExternalId = JobEdit_StateHooks.useSetExternalId();
  var renderLinkedJobs = function () {
    var match = job.nextJobs;
    var match$1 = job.completionJobs;
    if (uuid !== undefined && match !== undefined && match$1 !== undefined && (match.length > 1 || match$1.length > 0)) {
      return React.createElement(JobForm_LinkedJobs.make, {
                  nextJobs: match,
                  completionJobs: match$1,
                  currentJobUuid: Caml_option.valFromOption(uuid),
                  subroute: subroute
                });
    } else {
      return null;
    }
  };
  var tmp;
  if (subroute.NAME === "Edit") {
    switch (confirmation) {
      case "None" :
          tmp = null;
          break;
      case "Removal" :
          tmp = React.createElement(Modal.make, {
                wire: wire,
                onClose: hideConfirmation,
                show: true,
                children: React.createElement(Modal.Dialog.make, {
                      className: "p-modal-confirmation",
                      level: "Nested",
                      children: null
                    }, React.createElement(Modal.Header.make, {
                          children: confirmRemoval$p(ctx)
                        }), React.createElement("div", {
                          className: "p-modal-body"
                        }, confirmationBody$p({
                              serialNo: serialNo
                            }, ctx)), React.createElement(Modal.Footer.make, {
                          children: null
                        }, React.createElement(Modal.CloseButton.make, {
                              ctx: ctx
                            }), React.createElement("button", {
                              className: "p-modal-btn btn btn-danger",
                              disabled: confirmationLocked,
                              onClick: removeJob(subroute.VAL.uuid)
                            }, remove$p(ctx))))
              });
          break;
      case "Finish" :
          tmp = React.createElement(Modal.make, {
                wire: wire,
                onClose: hideConfirmation,
                show: true,
                children: React.createElement(Modal.Dialog.make, {
                      className: "p-modal-confirmation",
                      level: "Nested",
                      children: React.createElement(JobForm_FinishPopup.make, {
                            possibleResolutions: job.possibleResolutions,
                            onClose: hideConfirmation,
                            submitForm: submitForm,
                            locked: locked,
                            wire: wire,
                            initResolution: resolution,
                            initResolutionComment: resolutionComment,
                            status: status
                          })
                    })
              });
          break;
      case "Cancel" :
          tmp = React.createElement(Modal.make, {
                wire: wire,
                onClose: hideConfirmation,
                show: true,
                children: React.createElement(Modal.Dialog.make, {
                      className: "p-modal-confirmation",
                      level: "Nested",
                      children: React.createElement(JobForm_CancelPopup.make, {
                            possibleResolutions: RCore.$$Array.keep(job.possibleResolutions, (function (r) {
                                    return !r.isSuccessful;
                                  })),
                            initResolution: resolution,
                            initResolutionComment: resolutionComment,
                            status: status,
                            onClose: hideConfirmation,
                            cancelJob: cancelJob,
                            locked: locked,
                            wire: wire
                          })
                    })
              });
          break;
      
    }
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Title.make, {
                  route: wire.route,
                  ctx: ctx,
                  values: {
                    serial_no: serialNo
                  },
                  wire: wire
                }), React.createElement(Modal.Dialog.make, {
                  className: "p-job-form-dialog",
                  children: null
                }, React.createElement(Optional.make, {
                      show: locked,
                      children: Modal.bodyOverlay
                    }), React.createElement("div", {
                      className: El.Cn.concat("p-modal-header", styles.headerTop)
                    }, subroute.NAME === "Edit" ? (
                        uuid !== undefined && serialNo !== undefined ? React.createElement(JobForm_Heading.EditJobHeading.make, {
                                serialNo: serialNo,
                                uuid: Caml_option.valFromOption(uuid),
                                job: job,
                                onClose: onClose,
                                onCancel: onCancel,
                                onRemove: onRemove,
                                onFinish: onFinish,
                                onCopy: onCopy,
                                onNextJob: onNextJob,
                                onCompletionJob: onCompletionJob,
                                toggleHistory: toggleHistory,
                                triggerSubmit: (function (values) {
                                    submitForm(values);
                                  }),
                                printLayouts: job.printLayouts,
                                locked: locked,
                                saving: saving,
                                setLock: setLock,
                                wire: wire
                              }) : null
                      ) : React.createElement(JobForm_Heading.CreateJobHeading.make, {
                            externalId: job.externalId,
                            onAddExternalIdClicked: (function () {
                                setExternalId("");
                              }),
                            onClose: onClose,
                            triggerSubmit: (function () {
                                submitForm([]);
                              }),
                            locked: locked,
                            wire: wire,
                            foremanError: job.foremanError
                          })), React.createElement("div", {
                      className: "p-modal-body"
                    }, React.createElement(Optional.make, {
                          show: status === "Finished",
                          children: React.createElement(JobForm_StatsPanel.make, {
                                wire: wire,
                                job: job
                              })
                        }), uuid !== undefined ? React.createElement(Optional.make, {
                            show: showHistory,
                            children: React.createElement(JobForm_History.make, {
                                  wire: wire,
                                  uuid: Caml_option.valFromOption(uuid),
                                  toggleHistory: toggleHistory
                                })
                          }) : null, React.createElement(JobForm$JobBody, {
                          wire: wire,
                          job: job,
                          setLock: setLock
                        }), tmp), renderLinkedJobs(), React.createElement(JobForm_Footer.make, {
                      wire: wire,
                      job: job
                    })));
}

var make = JobForm;

export {
  make ,
}
/* styles Not a pure module */
