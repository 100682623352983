

import * as Uuid from "../../../../../bindings/uuid/Uuid.mjs";
import * as JsonDecode from "../../../../../libraries/JsonDecode.mjs";
import * as Schedule_Types_Mode from "../../types/Schedule_Types_Mode.mjs";
import * as Schedule_StorageType from "./Schedule_StorageType.mjs";
import * as Schedule_Types_Settings from "../../types/Schedule_Types_Settings.mjs";
import * as Schedule_Types_JobDuration from "../../types/Schedule_Types_JobDuration.mjs";

function decodeTerritories(field) {
  return field.required("territories", JsonDecode.array(Uuid.decode));
}

function decodeSkills(field) {
  return field.required("skills", Schedule_Types_Settings.Filter.Skills.decode);
}

function decodeAssignees(field) {
  return field.required("assignees", JsonDecode.oneOf([
                  Schedule_Types_Settings.Filter.Assignees.decode,
                  JsonDecode.custom(function (param) {
                        return "All";
                      })
                ]));
}

function decode(wire) {
  return JsonDecode.object(function (field) {
              var match = field.required("version", Schedule_StorageType.Version.decode);
              if (match === "V1") {
                return {
                        territories: decodeTerritories(field),
                        skills: decodeSkills(field),
                        assigneesFilter: decodeAssignees(field),
                        view: field.required("view", Schedule_StorageType.View.decode),
                        mode: Schedule_Types_Mode.initial(wire),
                        jobDuration: "Scheduled"
                      };
              } else {
                var mode = field.optional("mode", Schedule_Types_Mode.decode);
                return {
                        territories: decodeTerritories(field),
                        skills: decodeSkills(field),
                        assigneesFilter: decodeAssignees(field),
                        view: field.required("view", Schedule_StorageType.View.decode),
                        mode: mode !== undefined ? mode : Schedule_Types_Mode.initial(wire),
                        jobDuration: field.required("jobDuration", Schedule_Types_JobDuration.decode)
                      };
              }
            });
}

export {
  decode ,
}
/* Uuid Not a pure module */
