import React, { Component } from 'react'
import { timeToSeconds } from 'planado/utils/time/index.js'

export class BusinessHours extends Component {
  shouldComponentUpdate({ scale }) {
    return scale !== this.props.scale
  }

  render() {
    const { ctx, marks, marksWidth, businessHours } = this.props

    if (!businessHours.show || businessHours.start === businessHours.finish) {
      return null
    } else {
      const marksCount = marks.reduce((acc, day) => acc + day.marks.length, 0)
      const markWidth = marksWidth / marksCount

      const start = timeToSeconds(businessHours.seconds, ctx)
      const finish = timeToSeconds(businessHours.finish, ctx)

      const width = Math.round(
        markWidth *
          (start < finish
            ? 24 - (finish - start) / 3600
            : Math.abs(finish - start) / 3600)
      )

      const m = marks.reduce(
        (acc, day) =>
          acc.concat(
            day.marks.map(({ time: key, mark, offset }) => {
              const time = timeToSeconds(mark, ctx)
              const diff = finish - time

              if (diff < 3600 && diff > 0) {
                return (
                  <div
                    className="chart-business-hours__unobtainable"
                    style={{
                      left:
                        offset + Math.round(markWidth * Math.abs(diff / 3600)),
                      width,
                    }}
                    key={`${key}${Math.random()}`}
                  />
                )
              } else if (diff === 0) {
                return (
                  <div
                    className="chart-business-hours__unobtainable"
                    style={{
                      left: offset,
                      width,
                    }}
                    key={`${key}${Math.random()}`}
                  />
                )
              } else {
                return null
              }
            })
          ),
        []
      )

      return <div className="chart-business-hours">{m}</div>
    }
  }
}
