

import * as React from "react";
import * as ContextJs from "planado/utils/context.js";

var SessionContextType = {};

function use() {
  return React.useContext(ContextJs.SessionContext);
}

function useWire() {
  return React.useContext(ContextJs.SessionContext).wire;
}

function useCtx() {
  return React.useContext(ContextJs.SessionContext).wire.ctx;
}

function useLang() {
  var match = React.useContext(ContextJs.SessionContext).wire;
  return match.ctx.localizator.lang;
}

function useTenantUuid() {
  var match = React.useContext(ContextJs.SessionContext).wire;
  return match.ctx.tenantUuid;
}

var provider = ContextJs.SessionContext.Provider;

function AppContext$Provider(Props) {
  var value = Props.value;
  var children = Props.children;
  return React.createElement(provider, {
              value: value,
              children: children
            });
}

var Provider = {
  provider: provider,
  make: AppContext$Provider
};

export {
  SessionContextType ,
  use ,
  useWire ,
  useCtx ,
  useLang ,
  useTenantUuid ,
  Provider ,
}
/* provider Not a pure module */
