import I18n from 'i18n-js'

I18n.strftime = function (date, format) {
  let padding = number => `0${number.toString()}`.substr(-2)

  let options = this.lookup('date') || {}
  let meridianOptions = I18n.meridian()

  options = this.prepareOptions(options)

  let weekDay = date.getDay()
  let day = date.getDate()
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let hour = date.getHours()
  let hour12 = hour
  let meridian = hour > 11 ? 1 : 0
  let secs = date.getSeconds()
  let mins = date.getMinutes()
  let offset = date.getTimezoneOffset()
  let absOffsetHours = Math.floor(Math.abs(offset / 60))
  let absOffsetMinutes = Math.abs(offset) - absOffsetHours * 60
  let timezoneoffset =
    (offset > 0 ? '-' : '+') +
    (absOffsetHours.toString().length < 2
      ? `0${absOffsetHours}`
      : absOffsetHours) +
    (absOffsetMinutes.toString().length < 2
      ? `0${absOffsetMinutes}`
      : absOffsetMinutes)

  if (hour12 > 12) {
    hour12 = hour12 - 12
  } else if (hour12 === 0) {
    hour12 = 12
  }

  format = format.replace('%a', options.abbr_day_names[weekDay])
  format = format.replace('%A', options.day_names[weekDay])
  format = format.replace('%b', options.abbr_month_names[month])

  if (
    format.indexOf('%d') !== -1 ||
    format.indexOf('%e') !== -1 ||
    format.indexOf('%-d') !== -1
  ) {
    format = format.replace('%B', options.month_names[month])
  } else {
    let standaloneMonths = options.standalone_month_names || {}
    let monthName = standaloneMonths[month] || options.month_names[month]
    format = format.replace('%B', monthName)
  }

  format = format.replace('%d', padding(day))
  format = format.replace('%e', day)
  format = format.replace('%-d', day)
  format = format.replace('%H', padding(hour))
  format = format.replace('%-H', hour)
  format = format.replace('%I', padding(hour12))
  format = format.replace('%-I', hour12)
  format = format.replace('%m', padding(month))
  format = format.replace('%-m', month)
  format = format.replace('%M', padding(mins))
  format = format.replace('%-M', mins)
  format = format.replace('%p', meridianOptions[meridian])
  format = format.replace('%S', padding(secs))
  format = format.replace('%-S', secs)
  format = format.replace('%w', weekDay)
  format = format.replace('%y', padding(year))
  format = format.replace('%-y', padding(year).replace(/^0+/, ''))
  format = format.replace('%Y', year)
  format = format.replace('%z', timezoneoffset)

  return format
}

I18n.pluralization['ru'] = count => {
  if (count % 10 === 1 && count % 100 !== 11) {
    return ['one']
  } else if ([2, 3, 4].indexOf(count % 10) >= 0 && [12, 13, 14].indexOf(count % 100) < 0) {
    return ['few']
  } else if (count % 10 === 0 || [5, 6, 7, 8, 9].indexOf(count % 10) >= 0 || [11, 12, 13, 14].indexOf(count % 100) >= 0) {
    return ['many']
  } else {
    return ['other']
  }
}

I18n.pluralization['ar'] = count => {
  if (count === 0) {
    return ['zero']
  } else if (count === 1) {
    return ['one']
  } else if (count === 2) {
    return ['two']
  } else {
    const mod100 = count % 100

    if (mod100 >= 3 && mod100 <= 10) {
      return ['few']
    } else if (mod100 >= 11 && mod100 <= 99) {
      return ['many']
    } else {
      return ['other']
    }
  }
}

window.I18n = I18n

export default I18n
