

import * as Fun from "../libraries/Fun.mjs";
import * as RCore from "../libraries/RCore.mjs";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";

var extensions = Belt_SetString.fromArray([
      "3dv",
      "ai",
      "amf",
      "art",
      "ase",
      "awg",
      "blp",
      "bmp",
      "bw",
      "cd5",
      "cdr",
      "cgm",
      "cit",
      "cmx",
      "cpt",
      "cr2",
      "cur",
      "cut",
      "dds",
      "dib",
      "djvu",
      "dxf",
      "e2d",
      "ecw",
      "egt",
      "emf",
      "eps",
      "exif",
      "fs",
      "gbr",
      "gif",
      "gpl",
      "grf",
      "hdp",
      "heif",
      "icns",
      "ico",
      "iff",
      "int",
      "inta",
      "jfif",
      "jng",
      "jp2",
      "jpeg",
      "jpg",
      "jps",
      "jxr",
      "lbm",
      "liff",
      "max",
      "miff",
      "mng",
      "msp",
      "nef",
      "nitf",
      "nrrd",
      "odg",
      "ota",
      "pam",
      "pbm",
      "pc1",
      "pc2",
      "pc3",
      "pcf",
      "pct",
      "pcx",
      "pdd",
      "pdn",
      "pgf",
      "pgm",
      "PI1",
      "PI2",
      "PI3",
      "pict",
      "png",
      "pnm",
      "pns",
      "ppm",
      "psb",
      "psd",
      "psp",
      "px",
      "pxm",
      "pxr",
      "qfx",
      "ras",
      "raw",
      "rgb",
      "rgba",
      "rle",
      "sct",
      "sgi",
      "sid",
      "stl",
      "sun",
      "svg",
      "sxd",
      "tga",
      "tif",
      "tiff",
      "v2d",
      "vnd",
      "vrml",
      "vtf",
      "wdp",
      "webp",
      "wmf",
      "x3d",
      "xar",
      "xbm",
      "xcf",
      "xpm"
    ]);

var extensionRegex = /[#?]/;

function extensionFromUrl(url) {
  var parts = RCore.$$Option.getOr(RCore.$$Option.flatMap(url.split(extensionRegex)[0], Fun.id), "").split(".");
  var part = parts[parts.length - 1 | 0];
  if (part !== undefined) {
    return part.trim().toLowerCase();
  } else {
    return "";
  }
}

function isImage(url) {
  return Belt_SetString.has(extensions, extensionFromUrl(url));
}

export {
  isImage ,
}
/* extensions Not a pure module */
