

import * as Units from "../../../../../../../types/Units.mjs";
import * as Locale from "../../../../../../../libraries/Locale.mjs";
import * as WeekTimeline_Constants from "../../../lib/WeekTimeline_Constants.mjs";

function scheduledAt(period, sizes, param) {
  var offsetInMinutes = Units.Px.toInt(Units.Px.multiplyWithInt(Units.Px.round(Units.Px.divideByInt(Units.Px.divide(param[0], sizes.minute), 5)), 5));
  return Locale.T.nextMinutes(offsetInMinutes, period[0]);
}

var FindByCoordinateInTimeline = {
  scheduledAt: scheduledAt
};

function make(wire, period, sizes) {
  var now = Locale.T.now(wire);
  var isToday = Locale.T.equalDay(now, period[0]);
  var startOfDay = (WeekTimeline_Constants.minutesInHour << 3);
  var startOfDay$1;
  if (isToday) {
    var nowInMinutes = Locale.T.dateToMinutes(wire, now);
    var halfOfViewport = Units.Px.toFloat(Units.Px.divide(sizes.viewport, sizes.minute)) / 2.0;
    startOfDay$1 = Math.max(startOfDay, nowInMinutes - (halfOfViewport | 0) | 0);
  } else {
    startOfDay$1 = startOfDay;
  }
  var x = Units.Px.inverse(Units.Px.min(Units.Px.multiplyWithInt(sizes.minute, startOfDay$1), Units.Px.minus(sizes.timeline, sizes.viewport)));
  return {
          x: x,
          y: Units.Px.zero
        };
}

var InitialPosition = {
  make: make
};

export {
  FindByCoordinateInTimeline ,
  InitialPosition ,
}
/* Locale Not a pure module */
