let gmapsPromiseResolve = null

export const gmapsPromise = new Promise(
  (resolve, _) => (gmapsPromiseResolve = resolve)
)

export const initGmaps = gmapsPromiseResolve

class RequestCounter {
  requests = []

  register = () => {
    const id = this.requests.length
    this.requests = [...this.requests, id]
    return id
  }

  unregister = (requestId) => {
    this.requests = this.requests.filter((item) => item !== requestId)
  }

  get isEmpty() {
    return this.requests.length === 0
  }
}

export const requestCounter = new RequestCounter()
