

import * as Wire from "../../../../../../types/Wire.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as Locale from "../../../../../../libraries/Locale.mjs";

var nonBusinessHoursBlockWidth = Units.Px.fromInt(5);

function startOfDay(wire, period, date) {
  var startOfDay$1 = (function (__x) {
        return Locale.T.startOf("day", __x);
      })(Locale.T.min(Locale.T.max(date, period[0]), period[1]));
  var match = Wire.businessHours(wire);
  if (match !== undefined) {
    return Locale.T.add(match.start, startOfDay$1);
  } else {
    return startOfDay$1;
  }
}

function endOfDay(wire, period, date) {
  var date$1 = Locale.T.min(Locale.T.max(date, period[0]), period[1]);
  var match = Wire.businessHours(wire);
  if (match !== undefined) {
    return Locale.T.add(match.finish, (function (__x) {
                    return Locale.T.startOf("day", __x);
                  })(date$1));
  } else {
    return Locale.T.endOf("day", date$1);
  }
}

function updateStartAt(wire, period, startAt) {
  return Locale.T.max(startAt, startOfDay(wire, period, startAt));
}

function fixFinishAt(wire, finishAt) {
  if (Locale.T.dateToMinutes(wire, finishAt) === 0) {
    return Locale.T.subtract(Locale.T.duration(1.0, "milliseconds"), finishAt);
  } else {
    return finishAt;
  }
}

function inPeriod_(period, startAt, finishAt) {
  return Locale.T.intersectInterval(period, [
              startAt,
              finishAt
            ]);
}

function updateFinishAt(wire, period, finishAt) {
  var date = fixFinishAt(wire, finishAt);
  return Locale.T.min(date, endOfDay(wire, period, date));
}

function isVisible(wire, period, startAt, finishAt) {
  var finishAt$1 = fixFinishAt(wire, finishAt);
  if (inPeriod_(period, startAt, finishAt$1)) {
    var daysBetween = Locale.T.isoWeekday(finishAt$1) - Locale.T.isoWeekday(startAt) | 0;
    var match = Wire.businessHours(wire);
    if (match === undefined) {
      return true;
    }
    if (daysBetween >= 2) {
      return true;
    }
    var jobInterval = [
      startAt,
      finishAt$1
    ];
    var firstDayInterval_0 = startOfDay(wire, period, startAt);
    var firstDayInterval_1 = endOfDay(wire, period, startAt);
    var firstDayInterval = [
      firstDayInterval_0,
      firstDayInterval_1
    ];
    var lastDayInterval_0 = startOfDay(wire, period, finishAt$1);
    var lastDayInterval_1 = endOfDay(wire, period, finishAt$1);
    var lastDayInterval = [
      lastDayInterval_0,
      lastDayInterval_1
    ];
    if (Locale.T.intersectInterval(firstDayInterval, jobInterval)) {
      return true;
    } else {
      return Locale.T.intersectInterval(lastDayInterval, jobInterval);
    }
  } else {
    return false;
  }
}

function startedOutside(wire, period, startAt) {
  if (Locale.T.lessThan(startAt, startOfDay(wire, period, startAt))) {
    return true;
  } else {
    return Locale.T.moreThan(startAt, endOfDay(wire, period, startAt));
  }
}

function finishedOutside(wire, period, finishAt) {
  var finishAt$1 = fixFinishAt(wire, finishAt);
  var match = Wire.businessHours(wire);
  if (Locale.T.moreThan(finishAt$1, period[1])) {
    return true;
  }
  if (match === undefined) {
    return false;
  }
  var startOfDay = Locale.T.startOf("day", finishAt$1);
  var finish = Locale.T.add(match.finish, startOfDay);
  return Locale.T.moreThan(finishAt$1, finish);
}

export {
  nonBusinessHoursBlockWidth ,
  startOfDay ,
  endOfDay ,
  updateStartAt ,
  updateFinishAt ,
  isVisible ,
  startedOutside ,
  finishedOutside ,
}
/* nonBusinessHoursBlockWidth Not a pure module */
