

import * as El from "../../../../../libraries/El.mjs";
import * as Uuid from "../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../libraries/RCore.mjs";
import * as Units from "../../../../../types/Units.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Assignee_Types from "../../../../../types/Assignee_Types.mjs";
import * as Schedule_State from "../../../lib/state/Schedule_State.mjs";
import * as Schedule_TimelineSidebarHook from "./Schedule_TimelineSidebarHook.mjs";
import * as Schedule_Scroll_InitialPosition from "../../scroll/Schedule_Scroll_InitialPosition.mjs";
import * as Schedule_TimelineSidebarModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/timeline/sidebar/Schedule_TimelineSidebar.module.css";

var styles = Schedule_TimelineSidebarModuleCss;

function Schedule_TimelineSidebar$AssigneeComponent(Props) {
  var assignee = Props.assignee;
  var activeAssigneeUuid = Props.activeAssigneeUuid;
  var onActiveAssigneeChanged = Props.onActiveAssigneeChanged;
  var elementRef = Schedule_TimelineSidebarHook.use(onActiveAssigneeChanged, Assignee_Types.Assignee.uuid(assignee));
  var className = El.Cn.concat(styles.assignee, activeAssigneeUuid !== undefined && Caml_obj.equal(Caml_option.valFromOption(activeAssigneeUuid), Assignee_Types.Assignee.uuid(assignee)) ? styles.selected : "");
  return React.createElement("div", {
              ref: elementRef,
              className: className
            }, React.createElement("div", {
                  className: styles.text
                }, Assignee_Types.Assignee.name(assignee)));
}

function makeScrollableStyle(initialPositionY) {
  var initialPositionY$1 = Units.Px.toString(Units.Px.abs(initialPositionY));
  return {
          transform: "translate(0px, -" + initialPositionY$1 + ")"
        };
}

function Schedule_TimelineSidebar(Props) {
  var activeAssigneeUuid = Props.activeAssigneeUuid;
  var elementRef = Props.elementRef;
  var assignees = Props.assignees;
  var onActiveAssigneeChanged = Props.onActiveAssigneeChanged;
  var initialPositionY = Schedule_Scroll_InitialPosition.useInitialPositionY();
  var assigneesFilter = Schedule_State.useAssigneesFilter();
  var displayedAssignees = assigneesFilter === "All" ? assignees : RCore.$$Array.keep(assignees, (function (a) {
            if (a.TAG === "Worker") {
              return false;
            } else {
              return true;
            }
          }));
  return React.createElement("div", {
              className: styles.sidebar
            }, React.createElement("div", {
                  ref: elementRef,
                  style: makeScrollableStyle(initialPositionY)
                }, displayedAssignees.map(function (assignee) {
                      return React.createElement(Schedule_TimelineSidebar$AssigneeComponent, {
                                  assignee: assignee,
                                  activeAssigneeUuid: activeAssigneeUuid,
                                  onActiveAssigneeChanged: onActiveAssigneeChanged,
                                  key: Uuid.toString(Assignee_Types.Assignee.uuid(assignee))
                                });
                    })));
}

var make = React.memo(Schedule_TimelineSidebar);

export {
  styles ,
  makeScrollableStyle ,
  make ,
}
/* styles Not a pure module */
