

import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../types/Types.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as Schedule_Scroll from "../../../../components/scroll/Schedule_Scroll.mjs";
import * as DayTimeline_Types from "../types/DayTimeline_Types.mjs";
import * as Shared_Lib_Reducer from "../../../../../../shared/lib/Shared_Lib_Reducer.mjs";
import * as Shared_Lib_Debounce from "../../../../../../shared/lib/Shared_Lib_Debounce.mjs";
import * as DayTimeline_StateTypes from "./DayTimeline_StateTypes.mjs";
import * as DayTimeline_UtilsSizes from "../utils/DayTimeline_UtilsSizes.mjs";
import * as Schedule_TimelineHooks from "../../../../lib/hooks/Schedule_TimelineHooks.mjs";
import * as Schedule_UtilsTimeline from "../../../../lib/utils/Schedule_UtilsTimeline.mjs";
import * as DayTimeline_StateContext from "./DayTimeline_StateContext.mjs";
import * as DayTimeline_StateReducer from "./DayTimeline_StateReducer.mjs";
import * as DayTimeline_InitialPosition from "../DayTimeline_InitialPosition.mjs";
import * as Schedule_Scroll_InitialPosition from "../../../../components/scroll/Schedule_Scroll_InitialPosition.mjs";

function useSizes(wire, showUnscheduledJobs, businessHours, state, dispatch) {
  var match = Shared_Lib_Debounce.debounce(300, undefined, (function () {
          var sizes = DayTimeline_UtilsSizes.get(wire, showUnscheduledJobs, businessHours);
          if (Caml_obj.notequal(sizes, state.sizes)) {
            return dispatch({
                        TAG: "SizesChanged",
                        _0: sizes
                      });
          }
          
        }));
  var onResize = match.f;
  React.useEffect((function () {
          var sizes = DayTimeline_UtilsSizes.get(wire, showUnscheduledJobs, businessHours);
          if (Caml_obj.notequal(sizes, state.sizes)) {
            dispatch({
                  TAG: "SizesChanged",
                  _0: sizes
                });
          }
          return wire.subscriptions.resize.subscribe(function (param) {
                      onResize();
                    });
        }), [
        showUnscheduledJobs,
        businessHours,
        state.sizes
      ]);
}

function useScroll(wire, sizes, timelineSubscriptions, dispatch) {
  var elements = DayTimeline_Types.DomElements.use();
  var initialPosition = DayTimeline_InitialPosition.use(wire, sizes);
  React.useEffect((function () {
          var elements$1 = RCore.$$Array.filterMap([
                elements.scrollAreaEl,
                elements.viewportEl,
                elements.sidebarEl,
                elements.scaleEl,
                elements.unassignedEl
              ], (function (el) {
                  return Types.ReactRef.toOption(el);
                }));
          if (elements$1.length !== 5) {
            return ;
          }
          var scrollArea = elements$1[0];
          var viewport = elements$1[1];
          var sidebar = elements$1[2];
          var scale = elements$1[3];
          var unassigned = elements$1[4];
          var scrollElements = [
            {
              element: sidebar,
              direction: "Vertical"
            },
            {
              element: scale,
              direction: "Horizontal"
            },
            {
              element: unassigned,
              direction: "Horizontal"
            }
          ];
          var onScrollStart = function (param) {
            timelineSubscriptions.onScrollStart.trigger([
                  param.x,
                  param.y
                ]);
          };
          var onScrollEnd = function (param) {
            timelineSubscriptions.onScrollEnd.trigger([
                  param.x,
                  param.y
                ]);
          };
          var scroll = Schedule_Scroll.init(initialPosition, onScrollStart, onScrollEnd, scrollElements, viewport, Caml_option.some(scrollArea), undefined, undefined);
          if (scroll !== undefined) {
            dispatch({
                  TAG: "ScrollInitialized",
                  _0: scroll.subscriptions
                });
            return scroll.onDestroy;
          }
          
        }), []);
  return elements;
}

function useContext() {
  var wire = AppContext.useWire();
  var businessHours = Schedule_State.useBusinessHours();
  var showUnscheduledJobs = Schedule_State.useShowUnscheduledJobs();
  var initialSizes = React.useMemo((function () {
          return DayTimeline_UtilsSizes.get(wire, showUnscheduledJobs, businessHours);
        }), []);
  var context = Shared_Lib_Reducer.use(DayTimeline_StateReducer.reducer, DayTimeline_StateTypes.State.initial(initialSizes));
  var dispatch = context[1];
  var state = context[0];
  useSizes(wire, showUnscheduledJobs, businessHours, state, dispatch);
  var elements = useScroll(wire, DayTimeline_StateTypes.StateSelector.sizes(state), DayTimeline_StateTypes.StateSelector.timelineSubscriptions(state), dispatch);
  Schedule_TimelineHooks.TimelineSubscriptions.use(Schedule_UtilsTimeline.contentEl(elements.viewportEl), state.timelineSubscriptions);
  Schedule_TimelineHooks.TimelineSubscriptions.use(Types.ReactRef.toOption(elements.unassignedEl), state.timelineSubscriptions);
  var setInitialPosition = Schedule_Scroll_InitialPosition.useSetInitialPosition();
  React.useEffect((function () {
          return DayTimeline_StateTypes.StateSelector.timelineSubscriptions(state).onScrollEnd.subscribe(function (param) {
                      setInitialPosition({
                            x: Units.Px.zero,
                            y: param[1]
                          });
                    });
        }), []);
  return [
          elements,
          context
        ];
}

function useState() {
  return DayTimeline_StateContext.use()[0];
}

function useDispatch() {
  return DayTimeline_StateContext.use()[1];
}

function useSizes$1() {
  var state = useState();
  return DayTimeline_StateTypes.StateSelector.sizes(state);
}

function useTimelineSubscriptions() {
  var state = useState();
  return DayTimeline_StateTypes.StateSelector.timelineSubscriptions(state);
}

function useScrollSubscriptions() {
  var state = useState();
  return DayTimeline_StateTypes.StateSelector.scrollSubscriptions(state);
}

function useAssignJobBlocks() {
  var state = useState();
  return DayTimeline_StateTypes.StateSelector.assignJobBlocks(state);
}

export {
  useContext ,
  useDispatch ,
  useSizes$1 as useSizes,
  useTimelineSubscriptions ,
  useScrollSubscriptions ,
  useAssignJobBlocks ,
}
/* Types Not a pure module */
