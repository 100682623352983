import { Component } from 'react'
import ymaps from '../yandex/ymaps'
import gmaps from './gmaps'
import { eventsDecorator, supportEvents } from '../events'
import { areEqual } from 'planado/utils/index.js'
import { GEOLOCATION_OPTIONS } from '../constants'
import { MapEngineContext } from './map'

class MarkerEngine {
  createMarker(coordinates, options = {}) {
    this.coordinates = coordinates
    this.options = options

    this.marker = new (gmaps.getApi().Marker)({
      position: coordinates,
      ...options,
    })

    this.setOptions()
  }

  getMarker() {
    return this.marker || null
  }

  setCoordinates(coordinates) {
    this.marker.setPosition(coordinates)
  }

  setOption(name, value) {
    this.marker.set(name, value)
  }

  setOptions() {
    const { options } = this
    Object.keys(options).forEach((name) => this.setOption(name, options[name]))
  }

  setEvent(name, cb) {
    this.marker.addListener(name, cb)
  }

  destroy() {
    this.marker.setMap(null)
    this.marker = null
  }
}

class Marker extends Component {
  static contextType = MapEngineContext

  engine = null

  getCoordinates() {
    return new Promise((resolve) => {
      const { coordinates } = this.props
      if (coordinates !== null) {
        resolve(gmaps.getCoords(coordinates))
      } else {
        ymaps
          .getApi()
          .geolocation.get(GEOLOCATION_OPTIONS)
          .done((location) => {
            resolve(gmaps.getCoords(location.geoObjects.position))
          })
      }
    })
  }

  componentDidMount() {
    this.getCoordinates().then((coordinates) => {
      const { options } = this.props

      this.engine = new MarkerEngine()
      this.engine.createMarker(coordinates, options)

      this.setupEvents()
      this.defaultCoordinates = coordinates
      this.context.mapEngine.addMarker(this.engine.getMarker())
    })
  }

  componentWillReceiveProps(nextProps) {
    const { coordinates } = nextProps

    this.updateCoordinates(coordinates)
  }

  updateOptions(options = {}) {
    const { options: currentOptions = {} } = this.props

    Object.keys(options).forEach((name) => {
      if (!currentOptions || options[name] !== currentOptions[name]) {
        this.engine.setOption(name, options[name])
      }
    })
  }

  updateCoordinates(coordinates) {
    if (coordinates === null) {
      this.engine.setCoordinates(this.defaultCoordinates)
      this.context.mapEngine.setCenterSmoothly(this.defaultCoordinates)
    } else {
      const currentCoordinates =
        this.props.coordinates !== null
          ? this.props.coordinates
          : this.defaultCoordinates

      if (!areEqual(coordinates, currentCoordinates)) {
        this.engine.setCoordinates(gmaps.getCoords(coordinates))
      }
    }
  }

  shouldComponentUpdate() {
    return false
  }

  componentWillUnmount() {
    this.engine.destroy()
  }

  getEngine() {
    return this.engine || null
  }

  render() {
    return null
  }
}

Marker.defaultProps = {
  coordinates: null,
  options: {
    draggable: true,
    icon: {
      path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
      fillColor: '#5ebd5e',
      fillOpacity: 1,
      strokeColor: '#000',
      strokeWeight: 2,
      scale: 1,
    },
  },
}

export default eventsDecorator(Marker, supportEvents['google']['marker'])
