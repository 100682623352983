import * as React from 'react'
import { Map, Marker } from '../map'
import { isGeolocation, latlongRegex } from 'planado/utils/geolocation'
import classNames from 'classnames'
import LocationInput from './input'
import { disablePlacemarkHint, getStatusPlacemarkHint } from 'planado/storage'
import { BLANK_ADDRESS } from 'planado/types/address'
import { withContext } from 'planado/utils/contextConsumer.jsx'

const fixGeolocationPrecision = ({ latitude, longitude }) => ({
  latitude: latitude.toFixed(6),
  longitude: longitude.toFixed(6)
})

const LocationMap = withContext(
  ({
    id,
    geolocation,
    tempGeolocation,
    changeLocation,
    mapType = 'yandex',
    mapApiKey,
    ctx
  }) => {
    const placemarkHandle = e => {
      disablePlacemarkHint(ctx.storageType)

      let geolocation
      switch (mapType) {
        case 'google': {
          geolocation = {
            latitude: Number(e.latLng.lat()),
            longitude: Number(e.latLng.lng())
          }
          break
        }
        case 'yandex': {
          if (e.get('type') === 'click') {
            const coords = e.get('coords')
            geolocation = {
              latitude: Number(coords[0]),
              longitude: Number(coords[1])
            }
          } else if (e.get('type') === 'dragend') {
            const coords = e.get('target').geometry.getCoordinates()
            geolocation = {
              latitude: Number(coords[0]),
              longitude: Number(coords[1])
            }
          } else {
            geolocation = null
          }

          break
        }
      }

      changeLocation({
        isResolved: true,
        geolocation: fixGeolocationPrecision(geolocation)
      })
    }

    let coords
    if (geolocation) {
      coords = [geolocation.latitude, geolocation.longitude]
    } else if (tempGeolocation) {
      coords = [tempGeolocation.latitude, tempGeolocation.longitude]
    } else {
      coords = null
    }

    return (
      <Map
        id={id}
        mapType={mapType}
        apiKey={mapApiKey}
        width="100%"
        height="100%"
        zoom={13}
        center={coords}
        onClick={placemarkHandle}
      >
        <Marker
          mapType={mapType}
          onDragend={placemarkHandle}
          coordinates={coords}
        />
      </Map>
    )
  }
)

class LocationPopup extends React.Component {
  state = {
    state: {
      isLoaderShowing: false,
      isEdit: false
    }
  }

  get data() {
    return { ...this.props, ...this.state.state }
  }

  resetState = () =>
    this.setState(() => ({ state: { isLoaderShowing: false, isEdit: false } }))

  componentWillReceiveProps() {
    this.resetState()
  }

  closePopup = () => {
    this.props.onClose()
    this.resetState()
  }

  savePopup = () => {
    let { formatted, geolocation, saveLocation, isEdit = false } = this.data

    if (isEdit) {
      if (this.locationInput) {
        this.locationInput.onEnterHandle(
          {
            type: 'keypress',
            key: 'Enter'
          },
          'savePopup'
        )
      }
    } else {
      const isGeo = isGeolocation(geolocation)
      if (geolocation && isGeo && (!formatted || latlongRegex(formatted))) {
        formatted = `${geolocation.latitude}, ${geolocation.longitude}`
      } else if (!isGeo && formatted && latlongRegex(formatted)) {
        formatted = null
      }

      saveLocation({
        ...BLANK_ADDRESS,
        geolocationResolved: true,
        formatted,
        geolocation
      })

      this.closePopup()
    }
  }

  get address() {
    const { formatted, ctx } = this.data

    if (!formatted) {
      return null
    } else {
      return (
        <div className="map-component__map-options map-component__map-options_top">
          <div className="map-component__map-hint map-component__map-hint_light">
            {`${ctx.t('js.address_input.address')}: ${formatted}`}
          </div>
        </div>
      )
    }
  }

  get hint() {
    const { ctx } = this.data

    if (!getStatusPlacemarkHint(ctx.storageType)) {
      return (
        <div className="map-component__map-options">
          <div className="map-component__map-hint">
            {ctx.t('js.address_input.placemarkHint')}
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  changeLocation = (obj, shouldSavePopup = false) => {
    if (!this.props.readOnly) {
      if (shouldSavePopup) {
        this.setState(
          () => ({ state: { ...obj, isLoaderShowing: true } }),
          () => {
            setTimeout(() => {
              this.savePopup()
              this.setState(({ state }) => ({
                state: { ...state, isLoaderShowing: false }
              }))
            }, 1000)
          }
        )
      } else {
        this.setState(({ state }) => ({ state: { ...state, ...obj } }))
      }
    }
  }

  get body() {
    const {
      id,
      geolocation,
      tempGeolocation,
      isResolved,
      mapType,
      mapApiKey
    } = this.data

    return (
      <div className="modal-body modal-body_location">
        <LocationMap
          id={id}
          geolocation={geolocation}
          tempGeolocation={tempGeolocation}
          isResolved={isResolved}
          changeLocation={this.changeLocation}
          mapType={mapType}
          mapApiKey={mapApiKey}
        />

        {this.hint}

        {this.address}
      </div>
    )
  }

  get footer() {
    const { geolocation, id, isLoaderShowing = false, ctx } = this.data
    const buttonClasses = classNames({
      btn: true,
      'btn-primary': true,
      'btn-submit': true,
      'is-loading': isLoaderShowing
    })

    const { readOnly } = this.props

    const cancelLabel = readOnly
      ? ctx.t('js.address_input.cancel_read_only')
      : ctx.t('js.address_input.cancel')

    const closeButton = readOnly ? null : (
      <button
        disabled={isLoaderShowing}
        className={buttonClasses}
        type="button"
        onClick={this.savePopup}
      >
        {ctx.t('js.address_input.save')}
      </button>
    )

    return (
      <div className="modal-footer modal-footer_location">
        <LocationInput
          readOnly={readOnly}
          ref={el => (this.locationInput = el)}
          geolocation={geolocation}
          changeLocation={this.changeLocation}
          id={id}
        />

        <div>
          <button
            type="button"
            className="btn btn-default"
            onClick={this.closePopup}
          >
            {cancelLabel}
          </button>

          {closeButton}
        </div>
      </div>
    )
  }

  render() {
    const { ctx } = this.data

    return (
      <div className="modal-dialog modal-dialog_location">
        <div className="modal-header modal-header_location">
          <div className="modal-header__title">
            {ctx.t('js.address_input.location')}
          </div>

          <div className="close" onClick={this.closePopup}>
            <span className="fa fa-times" />
          </div>
        </div>

        {this.body}

        {this.footer}
      </div>
    )
  }
}

export default withContext(LocationPopup)
