

import * as RCore from "../../../../../libraries/RCore.mjs";
import * as Locale from "../../../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Lib_SortArray from "../../../../../libraries/Belt_Lib_SortArray.mjs";
import * as Schedule_Types_Job from "../../../lib/types/job/Schedule_Types_Job.mjs";
import * as WeekCalendar_Types from "./WeekCalendar_Types.mjs";
import * as Schedule_IntervalTree from "../../../lib/Schedule_IntervalTree.mjs";
import * as WeekCalendar_Constants from "./WeekCalendar_Constants.mjs";

function index(job) {
  return job.index;
}

function start(job) {
  return job.start;
}

function finish(job) {
  return job.finish;
}

var IntervalTree = Schedule_IntervalTree.Make({
      index: index,
      start: start,
      finish: finish
    });

function findGap(_positionOpt, _indexOpt, columnWidth, job) {
  while(true) {
    var positionOpt = _positionOpt;
    var indexOpt = _indexOpt;
    var position = positionOpt !== undefined ? positionOpt : 0.0;
    var index = indexOpt !== undefined ? indexOpt : 0;
    var minWidth = columnWidth / WeekCalendar_Constants.numberOfJobsInColumn;
    var rendered = RCore.$$Array.keep(job.children.contents, (function (j) {
            if (j.rendered.contents) {
              return !j.hidden.contents;
            } else {
              return false;
            }
          }));
    var found = rendered.every((function(position){
        return function (j) {
          return position !== j.left.contents;
        }
        }(position)));
    if (position > minWidth * (WeekCalendar_Constants.numberOfVisibleJobs - 1.0)) {
      return ;
    }
    if (found) {
      return position;
    }
    var j = rendered[index];
    if (j === undefined) {
      return ;
    }
    var position$1 = j.left.contents + minWidth;
    var index$1 = index + 1 | 0;
    _indexOpt = index$1;
    _positionOpt = position$1;
    continue ;
  };
}

function setPosition(width) {
  return function (job) {
    if (job.rendered.contents) {
      return ;
    }
    if (Caml_obj.equal(job.children.contents, [])) {
      job.rendered.contents = true;
      job.width.contents = width + 1.0;
      job.left.contents = 0.0;
      return ;
    }
    var minWidth = width / WeekCalendar_Constants.numberOfJobsInColumn + 5.0;
    var gap = findGap(undefined, undefined, width, job);
    if (gap !== undefined) {
      job.rendered.contents = true;
      job.width.contents = width - gap + 1.0;
      job.left.contents = gap;
    } else {
      job.rendered.contents = true;
      job.hidden.contents = true;
      job.width.contents = minWidth;
      job.left.contents = minWidth * WeekCalendar_Constants.numberOfVisibleJobs;
    }
  };
}

function updatePosition(width) {
  return function (job) {
    var widthOfJob = width / WeekCalendar_Constants.numberOfJobsInColumn;
    var offset = job.left.contents / widthOfJob + 1.0;
    job.left.contents = job.left.contents - offset;
  };
}

function sortByDuration(__x) {
  return Belt_Lib_SortArray.stableSortBy(__x, (function (a, b) {
                return (a.finish - a.start | 0) - (b.finish - b.start | 0) | 0;
              }));
}

function sortByPosition(positions) {
  return Belt_Lib_SortArray.stableSortBy(positions, (function (a, b) {
                  return b.left.contents - a.left.contents | 0;
                })).toReversed();
}

function filterByBusinessHours(wire, weekDay, jobs, businessHours) {
  if (businessHours === undefined) {
    return jobs;
  }
  var startOfDay = Math.imul(Locale.T.Schedule.startDurationToHours(businessHours.start), 60);
  var finishOfDay = Locale.T.Schedule.durationToMinutes(businessHours.finish);
  return RCore.$$Array.keep(jobs, (function (job) {
                var job$1 = WeekCalendar_Types.Position.updateJobInterval(job, weekDay);
                var startAt = Schedule_Types_Job.ScheduledJob.startAt(job$1);
                var finishAt = Schedule_Types_Job.ScheduledJob.finishAt(job$1);
                if (Locale.T.dateToMinutes(wire, startAt) < finishOfDay) {
                  return Locale.T.dateToMinutes(wire, finishAt) > startOfDay;
                } else {
                  return false;
                }
              }));
}

function positions(wire, businessHours, weekDay, width, jobs) {
  var filtered = filterByBusinessHours(wire, weekDay, jobs, businessHours);
  var positions$1 = sortByDuration(filtered.map(WeekCalendar_Types.Position.make(wire, businessHours, weekDay)));
  var tree = IntervalTree.make(positions$1);
  positions$1.forEach(function (job) {
        job.children.contents = RCore.$$Array.keep(IntervalTree.rangeSearch(undefined, tree, job.start + 1 | 0, job.finish - 1 | 0), (function (j) {
                return j.index !== job.index;
              }));
      });
  positions$1.forEach(setPosition(width));
  positions$1.forEach(updatePosition(width));
  return sortByPosition(positions$1).map(function (p) {
              return [
                      p,
                      filtered[p.index]
                    ];
            });
}

export {
  positions ,
}
/* IntervalTree Not a pure module */
