

import * as El from "../../../libraries/El.mjs";
import * as Fun from "../../../libraries/Fun.mjs";
import * as Url from "../../../bindings/url/Url.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Link from "../../common/Link/Link.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Hooks from "../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Title from "../../common/Title.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Errors from "../../../Errors.mjs";
import * as Switch from "../../inputs/Switch.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Popover from "../../common/Popover/Popover.mjs";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Optional from "../../common/Optional.mjs";
import * as Core__Int from "@rescript/core/lib/es6/src/Core__Int.mjs";
import * as FormInput from "../../inputs/FormInput.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as WebhookAPI from "./WebhookAPI.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as WebhookTypes from "./WebhookTypes.mjs";
import * as MessengerTypes from "../../messenger/MessengerTypes.mjs";
import * as WebhookEditModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/webhook/WebhookEdit.module.css";

var t = Utils.Translations.t;

var tr = Utils.Translations.tr;

var tx = Utils.Translations.tx;

var clientCrud$p = tr("js.admin.api.show.webhook_types.client_crud");

var cancel$p = tr("js.admin.api.modals.cancel");

var delete$p = tr("js.admin.api.show.delete");

var deleteConfirmation$p = tr("js.admin.api.modals.delete_confirmation");

var deleteQuestion$p = tr("js.admin.api.modals.delete_question");

var hasErrors$p = t("js.errors.form_contains_errors");

var jobCrud$p = tr("js.admin.api.show.webhook_types.job_crud");

var jobEvents$p = tr("js.admin.api.show.webhook_types.job_events");

var name$p = t("js.admin.api.show.webhook.name");

var new$p = t("js.admin.api.show.new");

var old$p = t("js.admin.api.show.old");

var save$p = tr("common.save");

var secret$p = t("js.admin.api.show.secret");

var siteCrud$p = tr("js.admin.api.show.webhook_types.site_crud");

var title$p = t("js.admin.api.show.webhook.title");

function tooMany$p(c) {
  return tx("js.admin.api.errors.too_many_webhooks")({
              count: WebhookTypes.maxWebhooks
            }, c);
}

var blank$p = tx("js.admin.api.errors.blank");

var invalid$p = tx("js.admin.api.errors.invalid");

var version$p = tr("js.admin.api.show.version");

var activeDescription$p = tr("js.admin.api.show.webhook.webhook_active_description");

var lockedDescription$p = tr("js.admin.api.show.webhook.webhook_locked_description");

var edit$p = tr("js.admin.api.show.webhook.edit");

var suspended$p = tr("js.admin.api.show.webhook.suspended");

var locked$p = tr("js.admin.api.show.webhook.locked");

var newWebhook$p = tr("js.titles.integrations.webhooks.new");

var active$p = tr("js.admin.api.show.webhook.active");

var styles = WebhookEditModuleCss;

var clientCrud = [
  "client_created",
  "client_updated",
  "client_removed"
];

var siteCrud = [
  "site_created",
  "site_updated",
  "site_removed"
];

var jobCrud = [
  "job_created",
  "job_updated",
  "job_removed"
];

var jobEvents = [
  "job_posted",
  "job_scheduled",
  "job_published",
  "job_finished",
  "job_started",
  "job_assigned",
  "job_en_route",
  "job_suspended",
  "job_canceled",
  "job_printed",
  "message_sent"
];

function WebhookEdit$StringInputs(Props) {
  var ctx = Props.ctx;
  var new_ = Props.new_;
  var state = Props.state;
  var sync = Props.sync;
  var submitted = Props.submitted;
  var errors = Props.errors;
  var wrapperClass = Js_dict.fromList({
        hd: [
          "pd-form-group",
          false
        ],
        tl: {
          hd: [
            styles.input,
            true
          ],
          tl: /* [] */0
        }
      });
  var updateUrl = function (v) {
    sync({
          uuid: state.uuid,
          url: Url.make(v),
          urlInput: v,
          secret: state.secret,
          name: state.name,
          eventTypes: state.eventTypes,
          status: state.status,
          version: state.version,
          alternativeVersions: state.alternativeVersions
        });
  };
  var onBlur = function (param) {
    var normalized = Url.normalizeStringToURL(state.urlInput);
    sync({
          uuid: state.uuid,
          url: Url.make(normalized),
          urlInput: normalized,
          secret: state.secret,
          name: state.name,
          eventTypes: state.eventTypes,
          status: state.status,
          version: state.version,
          alternativeVersions: state.alternativeVersions
        });
  };
  var tmp = {
    _type: "text",
    value: state.urlInput,
    onValueChange: updateUrl,
    onBlur: onBlur,
    labelText: "URL*",
    maxLength: 300,
    wrapperClass: wrapperClass,
    placeholder: "https://example.com/handle-planado"
  };
  var tmp$1 = submitted ? RCore.$$Option.map(Errors.get("urlField", errors), Core__List.toArray) : [];
  if (tmp$1 !== undefined) {
    tmp.errors = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement("div", {
              className: styles.hookParams
            }, React.createElement(FormInput.make, {
                  _type: "text",
                  value: RCore.$$Option.getOr(state.name, ""),
                  onValueChange: (function (v) {
                      sync({
                            uuid: state.uuid,
                            url: state.url,
                            urlInput: state.urlInput,
                            secret: state.secret,
                            name: Fun.optString(v),
                            eventTypes: state.eventTypes,
                            status: state.status,
                            version: state.version,
                            alternativeVersions: state.alternativeVersions
                          });
                    }),
                  labelText: name$p(ctx),
                  maxLength: 100,
                  wrapperClass: wrapperClass,
                  autoFocus: new_
                }), React.createElement(FormInput.make, tmp), React.createElement(FormInput.make, {
                  _type: "text",
                  value: RCore.$$Option.getOr(state.secret, ""),
                  onValueChange: (function (v) {
                      sync({
                            uuid: state.uuid,
                            url: state.url,
                            urlInput: state.urlInput,
                            secret: Fun.optString(v),
                            name: state.name,
                            eventTypes: state.eventTypes,
                            status: state.status,
                            version: state.version,
                            alternativeVersions: state.alternativeVersions
                          });
                    }),
                  labelText: secret$p(ctx),
                  maxLength: 100,
                  wrapperClass: wrapperClass
                }));
}

function WebhookEdit$VersionsBlock(Props) {
  var ctx = Props.ctx;
  var state = Props.state;
  var sync = Props.sync;
  var match = state.alternativeVersions;
  var oldVersion;
  var newVersion;
  var len = match.length;
  if (len !== 2) {
    if (len !== 3) {
      return null;
    }
    var oldVersion$1 = match[0];
    var newVersion$1 = match[2];
    oldVersion = oldVersion$1;
    newVersion = newVersion$1;
  } else {
    var oldVersion$2 = match[0];
    var newVersion$2 = match[1];
    oldVersion = oldVersion$2;
    newVersion = newVersion$2;
  }
  return React.createElement("div", undefined, React.createElement("label", undefined, version$p(ctx)), React.createElement(Switch.$$String.make, {
                  onValueChange: (function (v) {
                      var v$1 = RCore.$$Option.flatMap(Fun.optString(v), (function (x) {
                              return Core__Int.fromString(x, undefined);
                            }));
                      sync({
                            uuid: state.uuid,
                            url: state.url,
                            urlInput: state.urlInput,
                            secret: state.secret,
                            name: state.name,
                            eventTypes: state.eventTypes,
                            status: state.status,
                            version: v$1 !== undefined ? v$1 : state.version,
                            alternativeVersions: state.alternativeVersions
                          });
                    }),
                  value: state.version.toString(),
                  states: [
                    {
                      name: old$p(ctx),
                      value: oldVersion.toString()
                    },
                    {
                      name: new$p(ctx),
                      value: newVersion.toString()
                    }
                  ]
                }));
}

function errorMessage(error) {
  return React.createElement("div", {
              className: "pd-control-error"
            }, error);
}

function processEventsError(submitted, errors) {
  var error = Core__List.get(RCore.$$Option.getOr(Errors.get("eventsFields", errors), /* [] */0), 0);
  if (error !== undefined && submitted) {
    return [
            styles.pickFromListError,
            errorMessage(error)
          ];
  } else {
    return [
            styles.pickFromList,
            null
          ];
  }
}

function onToggle(state, sync) {
  return function (w) {
    if (Js_array.includes(w, state.eventTypes)) {
      return sync({
                  uuid: state.uuid,
                  url: state.url,
                  urlInput: state.urlInput,
                  secret: state.secret,
                  name: state.name,
                  eventTypes: Js_array.filter((function (x) {
                          return x !== w;
                        }), state.eventTypes),
                  status: state.status,
                  version: state.version,
                  alternativeVersions: state.alternativeVersions
                });
    } else {
      return sync({
                  uuid: state.uuid,
                  url: state.url,
                  urlInput: state.urlInput,
                  secret: state.secret,
                  name: state.name,
                  eventTypes: Belt_Array.concatMany([
                        [w],
                        state.eventTypes
                      ]),
                  status: state.status,
                  version: state.version,
                  alternativeVersions: state.alternativeVersions
                });
    }
  };
}

function eventSubtype(header, elements, wh) {
  return React.createElement("div", {
              className: styles.eventTypes
            }, React.createElement("label", undefined, header), React.createElement("ul", {
                  className: styles.integrationsWebhooks
                }, elements.map(wh)));
}

function WebhookEdit$EventsBlock(Props) {
  var ctx = Props.ctx;
  var state = Props.state;
  var sync = Props.sync;
  var submitted = Props.submitted;
  var errors = Props.errors;
  var wh = function (__x) {
    var webhooks = state.eventTypes;
    var onToggle$1 = onToggle(state, sync);
    return React.createElement("li", {
                key: __x
              }, React.createElement("label", {
                    className: El.Cn.concat("checkbox", styles.integrationsWebhooksLabel)
                  }, React.createElement("input", {
                        checked: Js_array.includes(__x, webhooks),
                        type: "checkbox",
                        onChange: (function (_event) {
                            onToggle$1(__x);
                          })
                      }), tr("js.admin.api.show.event_types." + __x)(ctx)));
  };
  var match = processEventsError(submitted, errors);
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: match[0]
                }, eventSubtype(jobCrud$p(ctx), jobCrud, wh), React.createElement("hr", undefined), eventSubtype(jobEvents$p(ctx), jobEvents, wh), React.createElement("hr", undefined), eventSubtype(clientCrud$p(ctx), clientCrud, wh), React.createElement("hr", undefined), eventSubtype(siteCrud$p(ctx), siteCrud, wh)), match[1]);
}

function WebhookEdit$DeleteButton(Props) {
  var uuid = Props.uuid;
  var wire = Props.wire;
  var locked = Props.locked;
  var match = React.useState(function () {
        return false;
      });
  var setConfirmation = match[1];
  var id = Hooks.useId(undefined);
  React.useEffect((function () {
          return wire.subscriptions.click.subscribe(function (param) {
                      setConfirmation(function (param) {
                            return false;
                          });
                    });
        }), []);
  var showPopover = function ($$event) {
    $$event.stopPropagation();
    $$event.preventDefault();
    setConfirmation(function (x) {
          return !x;
        });
  };
  return React.createElement(React.Fragment, undefined, React.createElement("button", {
                  className: "btn btn-danger",
                  id: id,
                  disabled: locked,
                  type: "button",
                  onClick: showPopover
                }, delete$p(wire.ctx)), React.createElement(Popover.make, {
                  footer: wire.footer,
                  show: match[0],
                  buttonId: id,
                  position: "right",
                  children: React.createElement("div", undefined, React.createElement("h5", undefined, deleteQuestion$p(wire.ctx)), React.createElement("footer", {
                            className: styles.confirmationFooter
                          }, React.createElement("button", {
                                className: "btn btn-default",
                                disabled: locked,
                                onClick: (function (param) {
                                    setConfirmation(function (param) {
                                          return false;
                                        });
                                  })
                              }, cancel$p(wire.ctx)), React.createElement("button", {
                                className: "btn btn-danger",
                                disabled: locked,
                                onClick: (function (param) {
                                    Backend.finallyOnSuccess(WebhookAPI.Delete.destroy(uuid, wire), (function () {
                                            wire.navigate(undefined, {
                                                  NAME: "Integrations",
                                                  VAL: "API"
                                                });
                                          }));
                                  })
                              }, deleteConfirmation$p(wire.ctx))))
                }));
}

function WebhookEdit(Props) {
  var wire = Props.wire;
  var uuid = Props.uuid;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return {
                uuid: Uuid.createV4(),
                url: undefined,
                urlInput: "",
                secret: undefined,
                name: undefined,
                eventTypes: [],
                status: "Enabled",
                version: 3,
                alternativeVersions: []
              };
      });
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setLoaded = match$1[1];
  var match$2 = Hooks.useLocked(false);
  var unlock = match$2[2];
  var lock = match$2[1];
  var locked = match$2[0];
  var match$3 = React.useState(function () {
        return title$p(ctx);
      });
  var setTitle = match$3[1];
  var persistedTitle = match$3[0];
  var match$4 = React.useState(function () {
        return false;
      });
  var setSubmitted = match$4[1];
  var submitted = match$4[0];
  var match$5 = React.useState(function () {
        return /* [] */0;
      });
  var setErrors = match$5[1];
  var errors = match$5[0];
  var sync = function (s) {
    setState(function (param) {
          return s;
        });
  };
  var showError = function (text, wire) {
    wire.subscriptions.messenger.trigger({
          TAG: "Show",
          _0: MessengerTypes.Message.make(undefined, "Danger", {
                TAG: "Text",
                _0: text
              })
        });
  };
  var onSubmit = function (param) {
    setSubmitted(function (param) {
          return true;
        });
    if (Core__List.length(errors) > 0) {
      return showError(hasErrors$p(wire.ctx), wire);
    } else {
      lock();
      if (uuid !== undefined) {
        return Backend.$$finally(WebhookAPI.Update.patch(wire, state, Caml_option.valFromOption(uuid)), (function (param) {
                      wire.navigate(undefined, {
                            NAME: "Integrations",
                            VAL: "API"
                          });
                    }));
      } else {
        return Backend.$$finally(WebhookAPI.Create.post(wire, state), (function (__x) {
                      if (__x.TAG === "Ok") {
                        return wire.navigate(undefined, {
                                    NAME: "Integrations",
                                    VAL: "API"
                                  });
                      }
                      var match = __x._0;
                      if (typeof match !== "object" || !(match.TAG === "HTTPError" && Js_string.includes("Enough", match._0[1]))) {
                        return ;
                      } else {
                        return showError(tooMany$p(wire.ctx), wire);
                      }
                    }));
      }
    }
  };
  var toggleStatus = function (param) {
    setState(function (param) {
          return {
                  uuid: state.uuid,
                  url: state.url,
                  urlInput: state.urlInput,
                  secret: state.secret,
                  name: state.name,
                  eventTypes: state.eventTypes,
                  status: WebhookTypes.Status.inverse(state.status),
                  version: state.version,
                  alternativeVersions: state.alternativeVersions
                };
        });
  };
  React.useEffect((function () {
          if (uuid !== undefined) {
            Backend.$$finally(WebhookAPI.Show.$$fetch(wire, Caml_option.valFromOption(uuid)), (function (__x) {
                    if (__x.TAG === "Ok") {
                      var response = __x._0;
                      var name = response.name;
                      if (name !== undefined) {
                        setTitle(function (param) {
                              return name;
                            });
                      } else {
                        setTitle(function (param) {
                              return title$p(ctx);
                            });
                      }
                      setState(function (param) {
                            return response;
                          });
                      return setLoaded(function (param) {
                                  return true;
                                });
                    }
                    var match = __x._0;
                    if (typeof match !== "object") {
                      return unlock();
                    }
                    if (match.TAG !== "HTTPError") {
                      return unlock();
                    }
                    var tmp = match._0[0];
                    if (typeof tmp !== "object" && tmp === "NotFound") {
                      return wire.navigate(undefined, "Unknown");
                    } else {
                      return unlock();
                    }
                  }));
          } else {
            setLoaded(function (param) {
                  return true;
                });
          }
        }), []);
  React.useEffect((function () {
          setSubmitted(function (param) {
                return false;
              });
          setErrors(function (param) {
                if (state.urlInput === "") {
                  return {
                          hd: [
                            "urlField",
                            blank$p({
                                  field: "URL"
                                }, ctx)
                          ],
                          tl: /* [] */0
                        };
                } else if (RCore.$$Option.isNone(state.url)) {
                  return {
                          hd: [
                            "urlField",
                            invalid$p({
                                  field: "URL"
                                }, ctx)
                          ],
                          tl: /* [] */0
                        };
                } else {
                  return /* [] */0;
                }
              });
        }), [state]);
  var disabled = state.status === "Suspended";
  var tmp;
  if (RCore.$$Option.isSome(uuid)) {
    var match$6 = state.status;
    var tmp$1;
    switch (match$6) {
      case "Enabled" :
      case "Disabled" :
          tmp$1 = edit$p(ctx);
          break;
      case "Suspended" :
          tmp$1 = suspended$p(ctx);
          break;
      case "Locked" :
          tmp$1 = locked$p(ctx);
          break;
      
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement("strong", undefined, persistedTitle), El.space, El.mdash, El.space, tmp$1);
  } else {
    tmp = React.createElement("strong", undefined, newWebhook$p(ctx));
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Title.make, {
                  route: wire.route,
                  ctx: ctx,
                  values: {
                    name: persistedTitle
                  },
                  wire: wire
                }), React.createElement(Optional.make, {
                  show: match$1[0],
                  children: null
                }, React.createElement("header", {
                      className: styles.header
                    }, React.createElement("div", undefined, tmp), React.createElement(Link.make, {
                          wire: wire,
                          route: {
                            NAME: "Integrations",
                            VAL: "API"
                          },
                          className: styles.close,
                          children: React.createElement("i", {
                                className: Icon.style(undefined, undefined, "times")
                              })
                        })), React.createElement("form", {
                      className: styles.webhookForm,
                      onSubmit: (function (prim) {
                          prim.preventDefault();
                        })
                    }, React.createElement("fieldset", {
                          disabled: disabled
                        }, React.createElement("div", undefined, React.createElement(WebhookEdit$StringInputs, {
                                  ctx: ctx,
                                  new_: RCore.$$Option.isNone(uuid),
                                  state: state,
                                  sync: sync,
                                  submitted: submitted,
                                  errors: errors
                                }), React.createElement(WebhookEdit$VersionsBlock, {
                                  ctx: ctx,
                                  state: state,
                                  sync: sync
                                }), React.createElement("div", {
                                  className: styles.active
                                }, React.createElement("label", {
                                      className: "checkbox"
                                    }, React.createElement("input", {
                                          checked: state.status === "Enabled",
                                          type: "checkbox",
                                          onChange: toggleStatus
                                        }), active$p(ctx)), React.createElement("div", {
                                      className: styles.activeDescription
                                    }, state.status === "Locked" ? React.createElement("div", {
                                            className: styles.locked
                                          }, lockedDescription$p(ctx)) : activeDescription$p(ctx))), React.createElement("hr", undefined), React.createElement(WebhookEdit$EventsBlock, {
                                  ctx: ctx,
                                  state: state,
                                  sync: sync,
                                  submitted: submitted,
                                  errors: errors
                                })))), React.createElement("footer", {
                      className: styles.footer
                    }, uuid !== undefined ? React.createElement(WebhookEdit$DeleteButton, {
                            uuid: Caml_option.valFromOption(uuid),
                            wire: wire,
                            locked: locked
                          }) : null, React.createElement("button", {
                          className: El.Cn.concat("btn btn-default", styles.cancel),
                          type: "button",
                          onClick: (function (param) {
                              wire.navigate(undefined, {
                                    NAME: "Integrations",
                                    VAL: "API"
                                  });
                            })
                        }, cancel$p(ctx)), React.createElement("button", {
                          className: "btn btn-primary",
                          disabled: locked || disabled,
                          type: "button",
                          onClick: onSubmit
                        }, save$p(ctx)))));
}

var make = WebhookEdit;

export {
  make ,
}
/* clientCrud' Not a pure module */
