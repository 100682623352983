import { createStore } from 'planado/stores/utils'
import reducer from 'planado/reducers/admin/recurring_jobs_index'

const INITIAL_STATE = {
  templates: [],
  recurring_jobs: [],
  popover: []
}

export default props => {
  const initialState = {
    ...INITIAL_STATE,
    ...props
  }

  return createStore(reducer, initialState, { logger: { diff: true } })
}
