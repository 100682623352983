import { mapWorkerLocationsPath, mapWorkerJobsPath } from 'planado/routes.js'
import { fetchJSON } from 'planado/utils/network.js'
import { camelize } from 'planado/utils/index.js'
import { isDevelopment } from 'rscrpt/utils/Utils.mjs'
import momentTimezone from 'moment-timezone'
import * as MapPageFakeData from 'rscrpt/components/map/MapPageFakeData.mjs'

const action =
  (type, value = null) =>
    (v) =>
      value === null ? { type } : { type, [value]: v }

export const toggleLocationBlock = action('MAP_TOGGLE_LOCATION_BLOCK', 'block')
export const setLocation = action('MAP_SET_LOCATION', 'location')
export const startEngine = action('MAP_START_ENGINE', 'engine')
export const showWorkersWithStaleLocation = action(
  'MAP_SHOW_WORKERS_WO_LOCATION'
)
export const hideWorkersWithStaleLocation = action(
  'MAP_HIDE_WORKERS_WO_LOCATION'
)
export const toggleJobStatusFilter = action(
  'MAP_TOGGLE_JOB_STATUS_FILTER',
  'status'
)
export const togglePanelJobs = action('MAP_TOGGLE_PANEL_JOBS')
export const togglePanelStatusFilter = action('MAP_TOGGLE_PANEL_STATUS_FILTER')
export const hideFocusedJob = action('MAP_HIDE_FOCUSED_JOB')
export const toggleFocusedJob = action('MAP_TOGGLE_FOCUSED_JOB', 'job')
export const toggleFocusedWorker = action('MAP_TOGGLE_FOCUSED_WORKER', 'worker')
export const hideFocusedWorker = action('MAP_HIDE_FOCUSED_WORKER')
export const toggleWorkerJobs = action('MAP_TOGGLE_WORKER_JOBS')
export const addLocation = action('MAP_ADD_LOCATION', 'location')
export const updateLocation = action('MAP_UPDATE_LOCATION', 'location')
export const addJob = action('MAP_ADD_JOB', 'job')
export const removeJob = action('MAP_REMOVE_JOB', 'job')
export const updateJob = action('MAP_UPDATE_JOB', 'job')
export const setHistoryDate = action('MAP_SET_HISTORY_DATE', 'date')
export const setLocations = action('MAP_SET_LOCATIONS', 'locations')
export const setJobs = action('MAP_SET_JOBS', 'jobs')

export const nextLocation = () => {
  return {
    type: 'MAP_NEXT_LOCATION',
    events: {
      nextLocation: null,
    },
  }
}

export const prevLocation = () => {
  return {
    type: 'MAP_PREV_LOCATION',
    events: {
      prevLocation: null,
    },
  }
}

function fetchHistory(dispatch, state) {
  const { historyWorkerUuid, historyDate, engine } = state

  if (isDevelopment(engine.ctx.wire)) {
    const stringDatesEqual = (first, second) =>
      first.slice(0, 10) == second.slice(0, 10)

    fetchJSON(
      mapWorkerLocationsPath(historyWorkerUuid, historyDate),
      engine.ctx
    ).then(realLocations =>
      dispatch(
        setLocations(
          camelize([
            ...realLocations,
            ...MapPageFakeData.locations.filter(
              l =>
                l.worker_uuid === historyWorkerUuid &&
                stringDatesEqual(l.effective_at, historyDate)
            )
          ])
        )
      )
    )

    fetchJSON(
      mapWorkerJobsPath(historyWorkerUuid, historyDate),
      engine.ctx
    ).then(realJobs =>
      dispatch(
        setJobs(
          camelize([
            ...realJobs,
            ...MapPageFakeData.jobs.filter(
              j =>
                j.assignees.workers.some(w => w.uuid === historyWorkerUuid) &&
                stringDatesEqual(j.scheduled_at, historyDate)
            )
          ])
        )
      )
    )
  } else {
    fetchJSON(
      mapWorkerLocationsPath(historyWorkerUuid, historyDate),
      engine.ctx
    ).then((locations) => dispatch(setLocations(camelize(locations))))

    fetchJSON(
      mapWorkerJobsPath(historyWorkerUuid, historyDate),
      engine.ctx
    ).then((jobs) => dispatch(setJobs(camelize(jobs))))
  }
}

export function changeHistoryDate(isoDate) {
  return (dispatch, getState) => {
    dispatch(setHistoryDate(isoDate))

    fetchHistory(dispatch, getState())
  }
}

export const setWorkerHistory = (worker, date) => {
  return {
    type: 'MAP_SET_WORKER_HISTORY',
    worker,
    date,
  }
}

export const openWorkerHistory = (worker) => {
  return (dispatch, getState) => {
    const { currentTime } = getState()
    const historyDate = momentTimezone(currentTime).startOf('day').format()

    dispatch(setWorkerHistory(worker, historyDate))

    fetchHistory(dispatch, getState())
  }
}

export const resetWorkerHistory = action('MAP_RESET_WORKER_HISTORY')
