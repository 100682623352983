

import * as Caml_option from "rescript/lib/es6/caml_option.js";

function readWritePermission(permission, loginWeb, paid) {
  if (loginWeb) {
    if (!paid && permission !== "Denied") {
      return "Read";
    } else {
      return permission;
    }
  } else {
    return "Denied";
  }
}

function permissions(permissions$1, paid, owner) {
  return {
          jobs: owner ? "Delete" : readWritePermission(permissions$1.jobs, permissions$1.loginWeb, paid),
          schedule: owner ? "Write" : readWritePermission(permissions$1.schedule, permissions$1.loginWeb, paid),
          clients: owner ? "Write" : readWritePermission(permissions$1.clients, permissions$1.loginWeb, paid),
          teams: owner ? "Write" : readWritePermission(permissions$1.teams, permissions$1.loginWeb, paid),
          loginWeb: owner ? true : permissions$1.loginWeb,
          map: owner ? true : permissions$1.loginWeb && permissions$1.map,
          shifts: owner ? true : permissions$1.loginWeb && permissions$1.shifts,
          admin: paid && permissions$1.loginWeb ? permissions$1.admin : owner,
          loginMobile: permissions$1.loginMobile,
          createJobsMobile: paid ? permissions$1.loginMobile && permissions$1.createJobsMobile : false,
          completeJobs: paid ? permissions$1.loginMobile && permissions$1.completeJobs : false,
          jobsExport: owner ? true : permissions$1.loginWeb && permissions$1.jobsExport
        };
}

function fromShow(userData) {
  return {
          uuid: Caml_option.some(userData.uuid),
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          password: undefined,
          mobilePhone: userData.mobilePhone,
          timezone: userData.timezone,
          paid: userData.paid,
          permissions: permissions(userData.permissions, userData.paid, userData.owner),
          skillUuids: userData.skillUuids,
          territoryUuid: userData.territoryUuid,
          customFieldValues: userData.customFieldValues,
          customFields: userData.customFields,
          dictionaries: userData.dictionaries
        };
}

function fromNew(userData) {
  return {
          uuid: undefined,
          firstName: undefined,
          lastName: undefined,
          email: undefined,
          password: undefined,
          mobilePhone: undefined,
          timezone: undefined,
          paid: userData.paid,
          permissions: userData.permissions,
          skillUuids: [],
          territoryUuid: undefined,
          customFieldValues: [],
          customFields: userData.customFields,
          dictionaries: userData.dictionaries
        };
}

function reducer(state, action) {
  if (typeof action !== "object") {
    var init = state.permissions;
    return {
            uuid: state.uuid,
            firstName: state.firstName,
            lastName: state.lastName,
            email: state.email,
            password: state.password,
            mobilePhone: state.mobilePhone,
            timezone: state.timezone,
            paid: true,
            permissions: {
              jobs: init.jobs,
              schedule: init.schedule,
              clients: init.clients,
              teams: init.teams,
              loginWeb: init.loginWeb,
              map: init.map,
              shifts: init.shifts,
              admin: init.admin,
              loginMobile: true,
              createJobsMobile: init.createJobsMobile,
              completeJobs: true,
              jobsExport: init.jobsExport
            },
            skillUuids: state.skillUuids,
            territoryUuid: state.territoryUuid,
            customFieldValues: state.customFieldValues,
            customFields: state.customFields,
            dictionaries: state.dictionaries
          };
  }
  switch (action.TAG) {
    case "SetFirstName" :
        return {
                uuid: state.uuid,
                firstName: action._0,
                lastName: state.lastName,
                email: state.email,
                password: state.password,
                mobilePhone: state.mobilePhone,
                timezone: state.timezone,
                paid: state.paid,
                permissions: state.permissions,
                skillUuids: state.skillUuids,
                territoryUuid: state.territoryUuid,
                customFieldValues: state.customFieldValues,
                customFields: state.customFields,
                dictionaries: state.dictionaries
              };
    case "SetLastName" :
        return {
                uuid: state.uuid,
                firstName: state.firstName,
                lastName: action._0,
                email: state.email,
                password: state.password,
                mobilePhone: state.mobilePhone,
                timezone: state.timezone,
                paid: state.paid,
                permissions: state.permissions,
                skillUuids: state.skillUuids,
                territoryUuid: state.territoryUuid,
                customFieldValues: state.customFieldValues,
                customFields: state.customFields,
                dictionaries: state.dictionaries
              };
    case "SetMobilePhone" :
        return {
                uuid: state.uuid,
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                password: state.password,
                mobilePhone: action._0,
                timezone: state.timezone,
                paid: state.paid,
                permissions: state.permissions,
                skillUuids: state.skillUuids,
                territoryUuid: state.territoryUuid,
                customFieldValues: state.customFieldValues,
                customFields: state.customFields,
                dictionaries: state.dictionaries
              };
    case "SetTimezone" :
        return {
                uuid: state.uuid,
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                password: state.password,
                mobilePhone: state.mobilePhone,
                timezone: action._0,
                paid: state.paid,
                permissions: state.permissions,
                skillUuids: state.skillUuids,
                territoryUuid: state.territoryUuid,
                customFieldValues: state.customFieldValues,
                customFields: state.customFields,
                dictionaries: state.dictionaries
              };
    case "SetEmail" :
        return {
                uuid: state.uuid,
                firstName: state.firstName,
                lastName: state.lastName,
                email: action._0,
                password: state.password,
                mobilePhone: state.mobilePhone,
                timezone: state.timezone,
                paid: state.paid,
                permissions: state.permissions,
                skillUuids: state.skillUuids,
                territoryUuid: state.territoryUuid,
                customFieldValues: state.customFieldValues,
                customFields: state.customFields,
                dictionaries: state.dictionaries
              };
    case "SetPassword" :
        return {
                uuid: state.uuid,
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                password: action._0,
                mobilePhone: state.mobilePhone,
                timezone: state.timezone,
                paid: state.paid,
                permissions: state.permissions,
                skillUuids: state.skillUuids,
                territoryUuid: state.territoryUuid,
                customFieldValues: state.customFieldValues,
                customFields: state.customFields,
                dictionaries: state.dictionaries
              };
    case "SetPaid" :
        var paid = action._1;
        return {
                uuid: state.uuid,
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                password: state.password,
                mobilePhone: state.mobilePhone,
                timezone: state.timezone,
                paid: paid,
                permissions: permissions(state.permissions, paid, action._0),
                skillUuids: state.skillUuids,
                territoryUuid: state.territoryUuid,
                customFieldValues: state.customFieldValues,
                customFields: state.customFields,
                dictionaries: state.dictionaries
              };
    case "SetPermissions" :
        return {
                uuid: state.uuid,
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                password: state.password,
                mobilePhone: state.mobilePhone,
                timezone: state.timezone,
                paid: state.paid,
                permissions: permissions(action._0, state.paid, action._1),
                skillUuids: state.skillUuids,
                territoryUuid: state.territoryUuid,
                customFieldValues: state.customFieldValues,
                customFields: state.customFields,
                dictionaries: state.dictionaries
              };
    case "SetSkillUuids" :
        return {
                uuid: state.uuid,
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                password: state.password,
                mobilePhone: state.mobilePhone,
                timezone: state.timezone,
                paid: state.paid,
                permissions: state.permissions,
                skillUuids: action._0,
                territoryUuid: state.territoryUuid,
                customFieldValues: state.customFieldValues,
                customFields: state.customFields,
                dictionaries: state.dictionaries
              };
    case "SetTerritoryUuid" :
        return {
                uuid: state.uuid,
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                password: state.password,
                mobilePhone: state.mobilePhone,
                timezone: state.timezone,
                paid: state.paid,
                permissions: state.permissions,
                skillUuids: state.skillUuids,
                territoryUuid: action._0,
                customFieldValues: state.customFieldValues,
                customFields: state.customFields,
                dictionaries: state.dictionaries
              };
    case "SetCustomFieldValues" :
        return {
                uuid: state.uuid,
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                password: state.password,
                mobilePhone: state.mobilePhone,
                timezone: state.timezone,
                paid: state.paid,
                permissions: state.permissions,
                skillUuids: state.skillUuids,
                territoryUuid: state.territoryUuid,
                customFieldValues: action._0,
                customFields: state.customFields,
                dictionaries: state.dictionaries
              };
    
  }
}

export {
  fromShow ,
  fromNew ,
  reducer ,
}
/* No side effect */
