import * as React from 'react'
import { SessionContext } from './context.js'

export const withContext = (Component) => {
  return React.forwardRef((props, ref) => {
    return (
      <SessionContext.Consumer>
        {(ctx) => <Component {...props} ctx={ctx} ref={ref} />}
      </SessionContext.Consumer>
    )
  })
}
