

import * as Fun from "../../../../../../libraries/Fun.mjs";
import * as Wire from "../../../../../../types/Wire.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as WeekTimeline_Utils from "../../lib/utils/WeekTimeline_Utils.mjs";
import * as Schedule_Types_JobCore from "../../../../lib/types/job/Schedule_Types_JobCore.mjs";
import * as Schedule_UtilsTimeline from "../../../../lib/utils/Schedule_UtilsTimeline.mjs";
import * as WeekTimeline_Constants from "../../lib/WeekTimeline_Constants.mjs";
import * as Webapi__Dom__HtmlElement from "rescript-webapi/lib/es6/src/Webapi/Dom/Webapi__Dom__HtmlElement.mjs";
import * as DayTimeline_DragLayerGrid from "../../../day_timeline/components/drag_layer/DayTimeline_DragLayerGrid.mjs";
import * as WeekTimeline_DragLayerGrid from "./WeekTimeline_DragLayerGrid.mjs";
import * as WeekTimeline_ActiveDayUtils from "../active_day/lib/WeekTimeline_ActiveDayUtils.mjs";
import * as WeekTimeline_DragLayerModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/drag_layer/WeekTimeline_DragLayer.module.css";

function getJobWidth(sizes, param) {
  var duration = param.duration;
  var days = Caml_int32.div(duration, WeekTimeline_Constants.minutesInDay);
  var minutes = Caml_int32.mod_(duration, WeekTimeline_Constants.minutesInDay);
  return Units.Px.plus(Units.Px.multiplyWithInt(sizes.day, days), Units.Px.multiplyWithInt(sizes.minute, minutes));
}

function setJobWidth(sizes, param, payload) {
  var style = RCore.$$Option.map(Webapi__Dom__HtmlElement.ofElement(param.jobEl), (function (prim) {
          return prim.style;
        }));
  if (style !== undefined) {
    Caml_option.valFromOption(style).setProperty("width", Units.Px.toString(getJobWidth(sizes, payload)));
    return ;
  }
  
}

var styles = WeekTimeline_DragLayerModuleCss;

function addElements(param) {
  var templateEl = document.createElement("div");
  templateEl.setAttribute("class", styles.template);
  var addressEl = document.createElement("div");
  addressEl.setAttribute("class", styles.address);
  var innerEl = document.createElement("div");
  innerEl.setAttribute("class", styles.inner);
  innerEl.appendChild(templateEl);
  innerEl.appendChild(addressEl);
  var innerContainerEl = document.createElement("div");
  innerContainerEl.setAttribute("class", styles.innerContainer);
  innerContainerEl.appendChild(innerEl);
  param.jobEl.appendChild(innerContainerEl);
  return [
          templateEl,
          addressEl
        ];
}

var template$p = Utils.Translations.t("js.schedule.v2.job.headline");

function hideJobContent(param) {
  param.jobEl.innerHTML = "";
}

function showJobContent(wire, elements, param, width) {
  hideJobContent(elements);
  var match = addElements(elements);
  var addressEl = match[1];
  var templateEl = match[0];
  var templateElStyle = RCore.$$Option.map(Webapi__Dom__HtmlElement.ofElement(templateEl), (function (prim) {
          return prim.style;
        }));
  var addressElStyle = RCore.$$Option.map(Webapi__Dom__HtmlElement.ofElement(addressEl), (function (prim) {
          return prim.style;
        }));
  var match$1 = Fun.both(templateElStyle, addressElStyle);
  if (match$1 !== undefined) {
    var width$1 = Units.Px.minus(width, Units.Px.fromInt(12));
    match$1[0].setProperty("width", Units.Px.toString(width$1));
    match$1[1].setProperty("width", Units.Px.toString(width$1));
  }
  var address = RCore.$$Option.mapWithDefault(param.address, "", Schedule_Types_JobCore.Address.toString);
  var template = RCore.$$Option.mapWithDefault(param.template, template$p(Wire.ctx(wire)), (function (param) {
          return param.name;
        }));
  addressEl.textContent = address;
  templateEl.textContent = template;
  var templateElClassList = templateEl.classList;
  var addressElClassList = addressEl.classList;
  setTimeout((function () {
          templateElClassList.add(styles.shown);
          addressElClassList.add(styles.shown);
        }), 10);
}

function setActiveDayJobWidth(wire, sizes, elements, payload, inActiveDay, activeDay) {
  var jobEl = elements.jobEl;
  var style = RCore.$$Option.map(Webapi__Dom__HtmlElement.ofElement(jobEl), (function (prim) {
          return prim.style;
        }));
  var match = Fun.both(activeDay, style);
  if (match === undefined) {
    return ;
  }
  var style$1 = match[1];
  var classList = jobEl.classList;
  var isExpanded = classList.contains(styles.expanded);
  var prevWidth = style$1.getPropertyValue("width");
  var nextWidth = Units.Px.round(Units.Px.multiplyWithInt(match[0].sizes.minute, payload.duration));
  if (inActiveDay && isExpanded && prevWidth !== Units.Px.toString(nextWidth)) {
    showJobContent(wire, elements, payload, nextWidth);
    style$1.setProperty("width", Units.Px.toString(nextWidth));
    return ;
  } else if (inActiveDay && !isExpanded) {
    showJobContent(wire, elements, payload, nextWidth);
    style$1.setProperty("width", Units.Px.toString(nextWidth));
    classList.add(styles.expanded);
    return ;
  } else if (!inActiveDay && isExpanded) {
    hideJobContent(elements);
    setJobWidth(sizes, elements, payload);
    classList.remove(styles.expanded);
    return ;
  } else {
    return ;
  }
}

function getJobPosition(wire, filter, contentEl, dragPreviewOffset, activeDay) {
  var match = (function (__x) {
        return Schedule_UtilsTimeline.ConvertPoint.fromNodeToPage(contentEl, __x);
      })((function (__x) {
            return WeekTimeline_DragLayerGrid.snapToGrid(wire, filter, activeDay, __x);
          })((function (__x) {
                return Schedule_UtilsTimeline.ConvertPoint.fromPageToNode(contentEl, __x);
              })(dragPreviewOffset)));
  var x = Units.Px.toString(match[0]);
  var y = Units.Px.toString(match[1]);
  return "translate(" + x + ", " + y + ")";
}

function setJobPosition(wire, filter, contentEl, param, dragPreviewOffset, activeDay) {
  var style = RCore.$$Option.map(Webapi__Dom__HtmlElement.ofElement(param.jobEl), (function (prim) {
          return prim.style;
        }));
  if (style !== undefined) {
    Caml_option.valFromOption(style).setProperty("transform", getJobPosition(wire, filter, contentEl, dragPreviewOffset, activeDay));
    return ;
  }
  
}

function inViewport(viewportEl, dragPreviewOffset) {
  var rect = viewportEl.getBoundingClientRect();
  var x = Units.Px.minus(dragPreviewOffset[0], Units.Px.fromFloat(rect.x));
  if (Units.Px.equalOrMoreThan(x, Units.Px.zero)) {
    return Units.Px.equalOrMoreThan(Units.Px.minus(Units.Px.fromFloat(rect.width), x), Units.Px.zero);
  } else {
    return false;
  }
}

function setJobDate(wire, filter, period, sizes, contentEl, viewportEl, elements, payload, dragPreviewOffset, inActiveDay, activeDay) {
  var activeDayContentEl = RCore.$$Option.flatMap(activeDay, (function (param) {
          return Schedule_UtilsTimeline.contentEl(param.elements.viewportEl);
        }));
  var match = Fun.both(activeDay, activeDayContentEl);
  var scheduledAt;
  var exit = 0;
  if (match !== undefined && inActiveDay) {
    var match$1 = match[0];
    var coordinate = DayTimeline_DragLayerGrid.snapToGrid(wire, filter, Schedule_UtilsTimeline.ConvertPoint.fromPageToNode(match[1], dragPreviewOffset));
    scheduledAt = WeekTimeline_ActiveDayUtils.FindByCoordinateInTimeline.scheduledAt(match$1.period, match$1.sizes, coordinate);
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var coordinate$1 = DayTimeline_DragLayerGrid.snapToGrid(wire, filter, Schedule_UtilsTimeline.ConvertPoint.fromPageToNode(contentEl, dragPreviewOffset));
    scheduledAt = WeekTimeline_Utils.FindByCoordinateInTimeline.scheduledAt(wire, period, sizes, coordinate$1);
  }
  var attribute;
  if (inViewport(viewportEl, dragPreviewOffset)) {
    var scheduledAtStr = Locale.T.fmtM("Time", wire)(scheduledAt);
    var scheduledFinishAtStr = Locale.T.fmtM("Time", wire)(Locale.T.nextMinutes(payload.duration, scheduledAt));
    attribute = scheduledAtStr + "–" + scheduledFinishAtStr;
  } else {
    attribute = "none";
  }
  elements.jobEl.setAttribute("data-scheduled-at", attribute);
}

function inActiveDay(contentEl, dragPreviewOffset, activeDay, activeDayOffset) {
  var match = Fun.both(activeDay, activeDayOffset);
  if (match === undefined) {
    return false;
  }
  var match$1 = match[1];
  var match$2 = Schedule_UtilsTimeline.ConvertPoint.fromPageToNode(contentEl, dragPreviewOffset);
  var y = match$2[1];
  if (Caml_obj.lessequal(match$1[0], y)) {
    return Caml_obj.lessequal(y, match$1[1]);
  } else {
    return false;
  }
}

function fixDragPreviewOffset(dragPreviewOffset, mouseOffset, sizes, payload) {
  var jobWidth = getJobWidth(sizes, payload);
  var jobHeight = Units.Px.fromInt(50);
  var x = Units.Px.lessThan(Units.Px.plus(dragPreviewOffset[0], jobWidth), mouseOffset[0]) ? mouseOffset[0] : dragPreviewOffset[0];
  var y = Units.Px.lessThan(Units.Px.plus(dragPreviewOffset[1], jobHeight), mouseOffset[1]) ? mouseOffset[1] : dragPreviewOffset[1];
  return [
          x,
          y
        ];
}

function showDragLayer(wire, sizes, filter, period, viewportEl, contentEl, elements, payload, dragPreviewOffset, mouseOffset, activeDay, activeDayOffset) {
  var containerEl = elements.containerEl;
  var dragPreviewOffset$1 = fixDragPreviewOffset(dragPreviewOffset, mouseOffset, sizes, payload);
  var inActiveDay$1 = inActiveDay(contentEl, dragPreviewOffset$1, activeDay, activeDayOffset);
  if (inActiveDay$1) {
    setActiveDayJobWidth(wire, sizes, elements, payload, inActiveDay$1, activeDay);
  } else {
    setJobWidth(sizes, elements, payload);
  }
  setJobPosition(wire, filter, contentEl, elements, dragPreviewOffset$1, activeDay);
  setJobDate(wire, filter, period, sizes, contentEl, viewportEl, elements, payload, dragPreviewOffset$1, inActiveDay$1, activeDay);
  if (!containerEl.classList.contains(styles.visible)) {
    containerEl.classList.add(styles.visible);
    return ;
  }
  
}

function updateDragLayer(wire, sizes, filter, period, viewportEl, contentEl, elements, payload, dragPreviewOffset, mouseOffset, activeDay, activeDayOffset) {
  var dragPreviewOffset$1 = fixDragPreviewOffset(dragPreviewOffset, mouseOffset, sizes, payload);
  setJobPosition(wire, filter, contentEl, elements, dragPreviewOffset$1, activeDay);
  var inActiveDay$1 = inActiveDay(contentEl, dragPreviewOffset$1, activeDay, activeDayOffset);
  setJobDate(wire, filter, period, sizes, contentEl, viewportEl, elements, payload, dragPreviewOffset$1, inActiveDay$1, activeDay);
  setActiveDayJobWidth(wire, sizes, elements, payload, inActiveDay$1, activeDay);
}

function hideDragLayer(param) {
  var containerEl = param.containerEl;
  if (containerEl.classList.contains(styles.visible)) {
    containerEl.classList.remove(styles.visible);
    return ;
  }
  
}

export {
  fixDragPreviewOffset ,
  showDragLayer ,
  updateDragLayer ,
  hideDragLayer ,
}
/* styles Not a pure module */
