import React from 'react'
import { ChartLink, SideBarLink } from 'planado/schedule/containers'
import * as styles from '../styles.module.css'

export default ({ editable = false }) => (
  <div className={styles.schedule}>
    <div id="main-menu" role="schedule" className={styles.menu}>
      <SideBarLink />
    </div>

    <div id="content-wrapper">
      <div id="schedule" data-editable={editable} className={styles.chart}>
        <div className="chart-wrapper">
          <ChartLink />
        </div>
      </div>
    </div>
  </div>
)
