

import * as Utils from "./Utils.mjs";
import * as PromiseF from "../libraries/PromiseF.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Webapi2_DataUrl from "../libraries/webapi/Webapi2_DataUrl.mjs";
import * as Webapi2_FileReader from "../libraries/webapi/Webapi2_FileReader.mjs";
import * as Webapi2_ArrayBuffer from "../libraries/webapi/Webapi2_ArrayBuffer.mjs";

function isImage(contentType) {
  var match = contentType.split("/")[0];
  if (match === "image") {
    return true;
  } else {
    return false;
  }
}

function getDimensions(file) {
  return PromiseF.make(function (resolve, reject) {
              var image = new Image();
              image.addEventListener("error", reject);
              image.addEventListener("load", (function (_event) {
                      resolve({
                            width: image.width,
                            height: image.height
                          });
                    }));
              image.src = URL.createObjectURL(file);
            });
}

var $$Image$1 = {
  isImage: isImage,
  getDimensions: getDimensions
};

function $$process(file) {
  return PromiseF.make(function (resolve, reject) {
              var reader = new FileReader();
              reader.addEventListener("error", (function (_event) {
                      reject("readingFileError");
                    }));
              reader.addEventListener("load", (function (_event) {
                      var dataUrl = Webapi2_FileReader.AsDataUrl.result(reader);
                      if (dataUrl === undefined) {
                        return reject("gettingDataUrlError");
                      }
                      var match = Webapi2_DataUrl.parse(Caml_option.valFromOption(dataUrl));
                      if (match === undefined) {
                        return reject("gettingBase64ValueError");
                      }
                      var base64 = match.data;
                      if (base64.TAG !== "Base64") {
                        return reject("gettingBase64ValueError");
                      }
                      var base64$1 = base64._0;
                      var checksum = Utils.digest(Webapi2_ArrayBuffer.fromBase64(base64$1));
                      var props_size = file.size | 0;
                      var props_name = file.name;
                      var props = {
                        base64: base64$1,
                        checksum: checksum,
                        size: props_size,
                        name: props_name,
                        height: undefined,
                        width: undefined
                      };
                      if (isImage(file.type)) {
                        var __x = getDimensions(file);
                        return PromiseF.$$finally((function (param) {
                                      resolve({
                                            base64: base64$1,
                                            checksum: checksum,
                                            size: props_size,
                                            name: props_name,
                                            height: param.height,
                                            width: param.width
                                          });
                                    }), (function (_error) {
                                      resolve(props);
                                    }), __x);
                      } else {
                        return resolve(props);
                      }
                    }));
              reader.readAsDataURL(file);
            });
}

export {
  $$Image$1 as $$Image,
  $$process ,
}
/* Utils Not a pure module */
