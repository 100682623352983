

import * as Fun from "../../../../../../libraries/Fun.mjs";
import * as Wire from "../../../../../../types/Wire.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../types/Types.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_DND from "../../../../lib/Schedule_DND.mjs";
import * as Assignee_Types from "../../../../../../types/Assignee_Types.mjs";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as WeekTimeline_State from "../../lib/state/WeekTimeline_State.mjs";
import * as Schedule_UtilsTimeline from "../../../../lib/utils/Schedule_UtilsTimeline.mjs";
import * as WeekTimeline_Constants from "../../lib/WeekTimeline_Constants.mjs";
import * as Schedule_Types_Timeline from "../../../../lib/types/Schedule_Types_Timeline.mjs";
import * as WeekTimeline_ActiveDayState from "../active_day/lib/state/WeekTimeline_ActiveDayState.mjs";
import * as WeekTimeline_DragLayerActions from "./WeekTimeline_DragLayerActions.mjs";
import * as WeekTimeline_DragLayerElements from "./WeekTimeline_DragLayerElements.mjs";

function findActiveDayOffset(wire, filter, activeDay) {
  if (activeDay === undefined) {
    return ;
  }
  var bar = activeDay.bar;
  var assignees = Schedule_Types_Timeline.Bars.Assignees.filter(wire, filter);
  var assigneeIndex = RCore.$$Array.findIndexOpt(assignees, (function (jobAssignee) {
          return Caml_obj.equal(Assignee_Types.Assignee.uuid(jobAssignee), Assignee_Types.Assignee.uuid(bar));
        }));
  if (assigneeIndex === undefined) {
    return ;
  }
  var bar$1 = Units.Px.multiplyWithInt(Units.Px.plus(WeekTimeline_Constants.rowHeight, WeekTimeline_Constants.barGapHeight), assigneeIndex);
  var y1 = Units.Px.plus(bar$1, WeekTimeline_Constants.rowHeight);
  var y2 = Units.Px.plus(y1, WeekTimeline_Constants.activeDayHeight);
  return [
          y1,
          y2
        ];
}

function use(viewportEl) {
  var wire = AppContext.useWire();
  var match = Schedule_DND.DND.Context.use();
  var item = match.item;
  var subscriptions = match.subscriptions;
  var activeDay = WeekTimeline_ActiveDayState.useActiveDay();
  var sizes = WeekTimeline_State.useSizes();
  var filter = Schedule_State.useFilter();
  var period = Schedule_State.usePeriod();
  var elements = React.useMemo(WeekTimeline_DragLayerElements.make, []);
  var rafId = React.useRef(undefined);
  var onDragEnd = function () {
    WeekTimeline_DragLayerActions.hideDragLayer(elements);
  };
  React.useEffect((function () {
          var contentEl = Schedule_UtilsTimeline.contentEl(viewportEl);
          var viewportEl$1 = Types.ReactRef.toOption(viewportEl);
          var match = Fun.both(viewportEl$1, contentEl);
          if (match === undefined) {
            return ;
          }
          var contentEl$1 = match[1];
          var viewportEl$2 = match[0];
          Wire.footer(wire).appendChild(elements.containerEl);
          var activeDayOffset = findActiveDayOffset(wire, filter, activeDay);
          var unsubscribeFromDragStart = subscriptions.onDragStart.subscribe(function () {
                var match = item.contents;
                if (match !== undefined) {
                  return WeekTimeline_DragLayerActions.showDragLayer(wire, sizes, filter, period, viewportEl$2, contentEl$1, elements, Schedule_DND.DND.Context.payload(match.payload), match.dragPreviewOffset, match.mouseOffset, activeDay, activeDayOffset);
                }
                
              });
          var unsubscribeFromDrag = subscriptions.onDrag.subscribe(function () {
                RCore.$$Option.forEach(rafId.current, (function (prim) {
                        cancelAnimationFrame(prim);
                      }));
                rafId.current = Caml_option.some(requestAnimationFrame(function (_time) {
                          var match = item.contents;
                          if (match !== undefined) {
                            WeekTimeline_DragLayerActions.updateDragLayer(wire, sizes, filter, period, viewportEl$2, contentEl$1, elements, Schedule_DND.DND.Context.payload(match.payload), match.dragPreviewOffset, match.mouseOffset, activeDay, activeDayOffset);
                          }
                          rafId.current = undefined;
                        }));
              });
          var unsubscribeFromDragEnd = subscriptions.onDragEnd.subscribe(onDragEnd);
          return (function () {
                    Wire.footer(wire).removeChild(elements.containerEl);
                    unsubscribeFromDragStart();
                    unsubscribeFromDrag();
                    unsubscribeFromDragEnd();
                  });
        }), [
        sizes,
        filter,
        period,
        activeDay
      ]);
}

function WeekTimeline_DragLayer(Props) {
  var viewportEl = Props.viewportEl;
  use(viewportEl);
  return null;
}

var make = React.memo(WeekTimeline_DragLayer);

export {
  make ,
}
/* make Not a pure module */
