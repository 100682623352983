

import * as RCore from "../../../../../libraries/RCore.mjs";
import * as Units from "../../../../../types/Units.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";

function make(groups, param) {
  var position = param[0];
  return {
          index: groups.length,
          start: position.left,
          finish: Units.Px.plus(position.left, position.width),
          jobs: [{
              TAG: "Assigned",
              _0: param[1]
            }]
        };
}

function findGroupIndex(groups, param) {
  var position = param[0];
  return RCore.$$Array.findIndexOpt(groups, (function (param) {
                if (Units.Px.lessThan(param.start, Units.Px.plus(position.left, position.width))) {
                  return Units.Px.moreThan(param.finish, position.left);
                } else {
                  return false;
                }
              }));
}

function makeGroups(jobs) {
  return RCore.$$Array.reduce(jobs, [], (function (groups, job) {
                var index = findGroupIndex(groups, job);
                if (index !== undefined) {
                  return groups.map(function (group) {
                              if (group.index === index) {
                                var position = job[0];
                                var finish = Units.Px.plus(position.left, position.width);
                                return {
                                        index: group.index,
                                        start: Units.Px.moreThan(group.start, position.left) ? position.left : group.start,
                                        finish: Units.Px.lessThan(group.finish, finish) ? finish : group.finish,
                                        jobs: Belt_Array.concatMany([
                                              group.jobs,
                                              [{
                                                  TAG: "Assigned",
                                                  _0: job[1]
                                                }]
                                            ])
                                      };
                              } else {
                                return group;
                              }
                            });
                } else {
                  return Belt_Array.concatMany([
                              groups,
                              [make(groups, job)]
                            ]);
                }
              }));
}

var Group = {};

export {
  Group ,
  makeGroups ,
}
/* No side effect */
