

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Bindings_Slate from "../../../../../../bindings/Bindings_Slate.mjs";
import * as Shared_Lib_Array from "../../../../../../shared/lib/Shared_Lib_Array.mjs";
import * as Liquid_EditorTypes from "../../../lib/Liquid_EditorTypes.mjs";
import * as Liquid_EditorTemplate from "../../../lib/Liquid_EditorTemplate.mjs";

function makeMarkup(lookups, filters) {
  var lookups$1 = lookups.join(Liquid_EditorTypes.lookupDelimeter);
  var filterWithArgs = filters.map(function (param) {
        var args = param.args;
        var name = param.name;
        if (Caml_obj.notequal(args, [])) {
          return name + Liquid_EditorTypes.filterArgsDelimeter + args.join(Liquid_EditorTypes.argsDelimeter);
        } else {
          return name;
        }
      });
  var filters$1 = filterWithArgs.join(Liquid_EditorTypes.filterDelimeter);
  return lookups$1 + (
          filters$1 === "" ? "" : Liquid_EditorTypes.filterDelimeter
        ) + filters$1;
}

function makeVariable(param, suggestion) {
  var filters = param.filters;
  var lookups = param.lookups;
  var match;
  switch (suggestion.TAG) {
    case "Filter" :
        match = [
          lookups,
          Belt_Array.concatMany([
                filters,
                [{
                    name: suggestion._0.key,
                    args: []
                  }]
              ])
        ];
        break;
    case "FilterArg" :
        var match$1 = Shared_Lib_Array.last(filters);
        var last = match$1[1];
        match = last !== undefined ? [
            lookups,
            Belt_Array.concatMany([
                  match$1[0],
                  [{
                      name: last.name,
                      args: Belt_Array.concatMany([
                            last.args,
                            [Liquid_EditorTypes.Filter.Arg.Value.key(suggestion._0.value)]
                          ])
                    }]
                ])
          ] : [
            lookups,
            filters
          ];
        break;
    case "Subtitution" :
        match = [
          Belt_Array.concatMany([
                lookups,
                [suggestion._0.key]
              ]),
          filters
        ];
        break;
    
  }
  var filters$1 = match[1];
  var lookups$1 = match[0];
  return {
          markup: makeMarkup(lookups$1, filters$1),
          lookups: lookups$1,
          filters: filters$1
        };
}

function use(editor, substitutions, variable) {
  var match = AppContext.useWire();
  var ctx = match.ctx;
  return function (suggestion) {
    var selection = Bindings_Slate.Editor.getSelection(editor);
    if (selection === undefined) {
      return ;
    }
    var selection$1 = Caml_option.valFromOption(selection);
    if (!Bindings_Slate.SlateRange.isCollapsed(selection$1)) {
      return ;
    }
    Bindings_Slate.SlateTransforms.select(editor, selection$1);
    Bindings_Slate.SlateTransforms.$$delete(editor);
    var nextVariable = makeVariable(variable, suggestion);
    var nodes = [Liquid_EditorTemplate.makeVariableElement(nextVariable)];
    Bindings_Slate.SlateTransforms.insertNodes(editor, nodes);
    var nextSuggestions = Liquid_EditorTypes.Suggestion.make(ctx, nextVariable, substitutions);
    if (Caml_obj.equal(nextSuggestions, [])) {
      return Bindings_Slate.SlateTransforms.move(undefined, editor);
    }
    
  };
}

var useInsertVariable = use;

export {
  useInsertVariable ,
}
/* AppContext Not a pure module */
