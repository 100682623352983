// extracted by mini-css-extract-plugin
export var additionalActions = "additional-actions-k4_13mrhH_EdtyCsh4Me";
export var buttonDelete = "button-delete-QVz1XR8D2_vL3Sxy5evB";
export var faTimes = "fa-times-RpMISgauPCTTg3VCXYgy";
export var modalButtonAccept = "modal-button-accept-Qb1TxYWObR8FEAKivjfR";
export var modalContent = "modal-content-BhXcgwdwD7KNpLHcOI8p";
export var modalForm = "modal-form-n5HuGrNkg1pYqGyoACWw";
export var modalFormFooter = "modal-form-footer-vcitEIHCwvj3wvZ990WI";
export var modalFormFooterDivider = "modal-form-footer-divider-kPjhUfLeOAKjlPwF4nFA";
export var modalFormHeader = "modal-form-header-S4JtU1Yie7KqifmHMepR";
export var modalFormHeaderClose = "modal-form-header-close-k9Q5oaAfWB42C3NfcAIL";
export var modalFormSection = "modal-form-section-pRE3W8AI10Wvtug4onu8";
export var modalFormSectionModal = "modal-form-section-modal-vU9mM2CxEdxBVP2lVAQK";
export var modalFormSectionModalA = "modal-form-section-modal-a-OgfEnMuHQHSQwz1D_Apc";
export var modalFormSectionText = "modal-form-section-text-iJwYD_2BtiqgfOrIDTLB";
export var modalOverlay = "modal-overlay-LAyocl69vMTUEJb2_Z25";
export var msgWarn = "msgWarn-wyFrjsGtiEke2bmFBpdB";
export var spinner = "spinner-To5AH0SMF6yJeDGF1_WM";
export var submitBtn = "submitBtn-nEXlg1qqrJ9YCFS3H9gR";