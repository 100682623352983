

import * as Utils from "../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Switch from "../../../../../../inputs/Switch.mjs";

var individual$p = Utils.Translations.t("js.clients.labels.add_individual");

var organization$p = Utils.Translations.t("js.clients.labels.add_organization");

var site$p = Utils.Translations.t("js.clients.labels.add_site");

function JobForm_ClientSiteSwitch(Props) {
  var ctx = Props.ctx;
  var onValueChange = Props.onValueChange;
  var value = Props.value;
  return React.createElement("div", {
              className: "pd-form-group-container pd-form-group-container__huge_padding"
            }, React.createElement("div", {
                  className: "pd-form-group"
                }, React.createElement(Switch.$$String.make, {
                      onValueChange: onValueChange,
                      value: value,
                      states: [
                        {
                          name: individual$p(ctx),
                          value: "individual"
                        },
                        {
                          name: organization$p(ctx),
                          value: "organization"
                        },
                        {
                          name: site$p(ctx),
                          value: "site"
                        }
                      ]
                    })));
}

var make = JobForm_ClientSiteSwitch;

export {
  make ,
}
/* individual' Not a pure module */
