

import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as UI_Page from "../../ui/UI_Page.mjs";
import * as AppContext from "../../context/AppContext.mjs";
import * as UI_PageTabs from "../../ui/UI_PageTabs.mjs";
import * as UI_PageHeader from "../../ui/UI_PageHeader.mjs";
import * as Pages_User_Custom_Fields from "./users/Pages_User_Custom_Fields.mjs";
import * as Pages_Custom_FieldsModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/custom_fields/Pages_Custom_Fields.module.css";

var users$p = Utils.Translations.tr("js.custom_fields.index.tabs.users");

var customFields$p = Utils.Translations.tr("js.titles.custom_fields.index");

var settings$p = Utils.Translations.tr("js.admin.settings.title.settings");

var styles = Pages_Custom_FieldsModuleCss;

function Pages_Custom_Fields(Props) {
  var ctx = AppContext.useCtx();
  return React.createElement(UI_Page.make, {
              children: null
            }, React.createElement(UI_PageHeader.make, {
                  wrapperClassName: styles.headerWrapper,
                  children: null
                }, settings$p(ctx), React.createElement("span", undefined, customFields$p(ctx))), React.createElement(UI_PageTabs.make, {
                  className: styles.wrapper,
                  children: React.createElement(UI_PageTabs.Tab.make, {
                        route: {
                          NAME: "CustomFields",
                          VAL: "Users"
                        },
                        renderLabel: (function () {
                            return users$p(ctx);
                          }),
                        children: React.createElement(Pages_User_Custom_Fields.make, {})
                      })
                }));
}

var make = Pages_Custom_Fields;

export {
  make ,
}
/* users' Not a pure module */
