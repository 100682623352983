

import * as Uuid from "../../../../../bindings/uuid/Uuid.mjs";
import * as Utils from "../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../../../../common/Optional.mjs";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var returnDefault$p = Utils.Translations.tr("js.jobs.index.views.return_default");

var modalButton$p = Utils.Translations.tr("js.jobs.index.views.settings.modal_button");

function SettingsPreviewContent(Props) {
  var hiddenViews = Props.hiddenViews;
  var activeView = Props.activeView;
  var selectView = Props.selectView;
  var openSettings = Props.openSettings;
  var returnDefaultView = Props.returnDefaultView;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var classes = function (uuid) {
    if (Caml_obj.equal(activeView, uuid)) {
      return "JobsViews-popover__item active";
    } else {
      return "JobsViews-popover__item";
    }
  };
  return React.createElement("div", {
              className: "JobsViews-popover"
            }, React.createElement("nav", {
                  className: "JobsViews-popover__list"
                }, hiddenViews.map(function (v) {
                      return React.createElement("button", {
                                  key: Uuid.toString(v.uuid),
                                  className: classes(Caml_option.some(v.uuid)),
                                  type: "button",
                                  onClick: (function (param) {
                                      selectView(v.uuid);
                                    })
                                }, v.name);
                    }), React.createElement(Optional.make, {
                      show: hiddenViews.length > 0,
                      children: React.createElement("div", {
                            className: "JobsViews-popover__item JobsViews-popover__item_separator"
                          })
                    }), React.createElement("button", {
                      className: "JobsViews-popover__item",
                      type: "button",
                      onClick: (function (param) {
                          returnDefaultView();
                        })
                    }, returnDefault$p(ctx)), React.createElement("div", {
                      className: "JobsViews-popover__item JobsViews-popover__item_separator"
                    }), React.createElement("button", {
                      className: "JobsViews-popover__item",
                      type: "button",
                      onClick: (function (param) {
                          openSettings();
                        })
                    }, modalButton$p(ctx))));
}

var make = SettingsPreviewContent;

export {
  make ,
}
/* returnDefault' Not a pure module */
