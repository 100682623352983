

import * as React from "react";

function Make(ContextState) {
  var context = React.createContext(ContextState.initial);
  var use = function () {
    return React.useContext(context);
  };
  var makeProps = function (value, children, param) {
    return {
            value: value,
            children: children
          };
  };
  var make = context.Provider;
  var Provider = {
    makeProps: makeProps,
    make: make
  };
  return {
          initial: ContextState.initial,
          context: context,
          use: use,
          Provider: Provider
        };
}

export {
  Make ,
}
/* react Not a pure module */
