

import * as Fun from "../../Fun.mjs";
import * as RCore from "../../RCore.mjs";

var variableStart = "{{";

var partialTemplateParser = variableStart + ".*?}}?";

var anyStartingTag = variableStart;

var template = new RegExp("(" + partialTemplateParser + "|" + anyStartingTag + ")");

function make(source) {
  return {
          source: source,
          tokens: RCore.$$Array.filterMap(source.split(template), (function (__x) {
                  return RCore.$$Option.flatMap(__x, Fun.optString);
                }))
        };
}

function source(param) {
  return param.source;
}

function tokens(param) {
  return param.tokens;
}

export {
  make ,
  source ,
  tokens ,
}
/* template Not a pure module */
