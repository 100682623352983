import React from 'react'
import classNames from 'classnames'

const Checkbox = ({
  onToggle,
  label,
  id,
  name,
  disabled,
  invert,
  checked,
  wrapperClasses,
  inputClasses,
  checkboxClass,
  children
}) => {
  const onClick = e => {
    if (e.target.tagName != 'A') {
      e.preventDefault()

      if (e.target.classList.contains('prevent') || disabled) {
        onToggle(e, checked)
      } else {
        onToggle(e, !checked)
      }
    }
  }

  wrapperClasses = classNames({
    'react-checkbox': true,
    'react-checkbox_disabled': disabled,
    ...wrapperClasses
  })

  inputClasses = classNames({
    'react-checkbox__input': true,
    ...inputClasses
  })

  const labelClasses = classNames({
    'react-checkbox__label': true,
    'react-checkbox__label_checked': checked,
    'react-checkbox__label_invert': invert
  })

  const checkboxCN = classNames({
    'react-checkbox__label-text': true,
    ...checkboxClass
  })

  return (
    <div className={wrapperClasses} onClick={onClick}>
      <label className={labelClasses}>
        <input
          type="checkbox"
          readOnly={true}
          className={inputClasses}
          checked={checked}
          disabled={disabled}
          id={id}
          name={name}
        />

        <span className={checkboxCN}>
          {label}
          {children}
        </span>
      </label>
    </div>
  )
}

Checkbox.defaultProps = {
  onToggle: () => {},
  label: '',
  name: '',
  disabled: false,
  invert: false,
  checked: false,
  id: null,
  wrapperClasses: {},
  inputClasses: {}
}

export default Checkbox
