

import * as El from "../../../libraries/El.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Link from "../../common/Link/Link.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../common/Optional.mjs";

var name$p = Utils.Translations.tr("js.admin.users.user_invitation_table.name");

var email$p = Utils.Translations.tr("js.admin.users.user_invitation_table.email");

var mobilePhone$p = Utils.Translations.tr("js.admin.users.user_invitation_table.mobile_phone");

var paid$p = Utils.Translations.tr("js.admin.users.user_invitation_table.is_paid");

var sentAt$p = Utils.Translations.tr("js.admin.users.user_invitation_table.sent_at");

var userInvitations$p = Utils.Translations.tr("js.admin.users.index.user_invitations");

function UserInvitationTable(Props) {
  var wire = Props.wire;
  var userInvitations = Props.userInvitations;
  var ctx = wire.ctx;
  var userRows = userInvitations.map(function (i) {
        return React.createElement("tr", {
                    key: Uuid.toString(i.uuid)
                  }, React.createElement("td", undefined, React.createElement(Link.make, {
                            wire: wire,
                            route: {
                              NAME: "User",
                              VAL: {
                                NAME: "Field",
                                VAL: {
                                  NAME: "Edit",
                                  VAL: i.uuid
                                }
                              }
                            },
                            children: null
                          }, i.firstName, El.space, i.lastName)), React.createElement("td", undefined, i.email), React.createElement("td", undefined, i.mobilePhone), React.createElement("td", undefined, React.createElement(Optional.make, {
                            show: i.canCompleteJobs,
                            children: React.createElement("span", {
                                  className: El.Cn.concat(Icon.style(undefined, undefined, "check"), "text-success")
                                })
                          })), React.createElement("td", undefined, Utils.Translations.datetimeFromStr(i.createdAt, wire)));
      });
  if (userInvitations.length > 0) {
    return React.createElement("div", {
                className: "row"
              }, React.createElement("div", {
                    className: "col-lg-12"
                  }, React.createElement("h5", undefined, userInvitations$p(ctx)), React.createElement("div", {
                        className: "panel"
                      }, React.createElement("div", {
                            className: "panel-body"
                          }, React.createElement("table", {
                                className: "table table-responsive table-hover table-user"
                              }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, name$p(ctx)), React.createElement("th", undefined, email$p(ctx)), React.createElement("th", undefined, mobilePhone$p(ctx)), React.createElement("th", undefined, paid$p(ctx)), React.createElement("th", undefined, sentAt$p(ctx)))), React.createElement("tbody", undefined, userRows))))));
  } else {
    return null;
  }
}

var make = UserInvitationTable;

export {
  make ,
}
/* name' Not a pure module */
