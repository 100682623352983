

import * as Uuid from "../bindings/uuid/Uuid.mjs";
import * as RCore from "../libraries/RCore.mjs";
import * as Types from "../types/Types.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Shared_Lib_Option from "../shared/lib/Shared_Lib_Option.mjs";

var $$encodeURI = (function(query) { return encodeURIComponent(query) });

var $$decodeURI = (function(query) { return decodeURIComponent(query) });

function joinQuery(params) {
  var _i = 0;
  var _acc = "";
  while(true) {
    var acc = _acc;
    var i = _i;
    var match = params[i];
    var match$1 = params[i + 1 | 0];
    if (match === undefined) {
      return acc;
    }
    var v = match[1];
    var k = match[0];
    if (match$1 === undefined) {
      return acc + k + "=" + $$encodeURI(v);
    }
    _acc = acc + k + "=" + $$encodeURI(v) + "&";
    _i = i + 1 | 0;
    continue ;
  };
}

function makeQuery(query) {
  if (query.length !== 0) {
    return "?" + joinQuery(query);
  } else {
    return "";
  }
}

function param(params, name) {
  var match = RCore.$$Array.getBy(params, (function (param) {
          return Caml_obj.equal(param[0], name);
        }));
  if (match !== undefined) {
    return Caml_option.some(match[1]);
  }
  
}

function query(search, name) {
  return param(RCore.$$Array.filterMap((function (__x) {
                      return Js_string.split("&", __x);
                    })(Js_string.substr(1, search)), (function (v) {
                    var param = (function (__x) {
                          return Js_string.split("=", __x);
                        })(v);
                    if (param.length !== 2) {
                      return ;
                    }
                    var key = param[0];
                    var value = param[1];
                    return [
                            key,
                            $$decodeURI(value)
                          ];
                  })), name);
}

function queryId(search, name) {
  return RCore.$$Option.map(query(search, name), Types.Id.fromString);
}

function queryUuid(search, name) {
  return RCore.$$Option.map(query(search, name), Uuid.fromString);
}

function queryPK(search, name) {
  var match = Shared_Lib_Option.align(queryId(search, name), queryUuid(search, name));
  if (match === undefined) {
    return ;
  }
  switch (match.TAG) {
    case "Left" :
        return {
                NAME: "BigInt",
                VAL: match._0
              };
    case "Right" :
        return {
                NAME: "Uuid",
                VAL: match._0
              };
    case "Both" :
        return {
                NAME: "Uuid",
                VAL: match._1
              };
    
  }
}

export {
  $$encodeURI ,
  $$decodeURI ,
  joinQuery ,
  makeQuery ,
  param ,
  query ,
  queryId ,
  queryUuid ,
  queryPK ,
}
/* Uuid Not a pure module */
