

import * as Uuid from "../../../../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../../../../libraries/RCore.mjs";
import * as Units from "../../../../../../../../types/Units.mjs";
import * as Locale from "../../../../../../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Assignee_Types from "../../../../../../../../types/Assignee_Types.mjs";
import * as MultipleAssignee from "../../../../../../../../types/MultipleAssignee.mjs";
import * as Schedule_Types_Job from "../../../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as WeekTimeline_Constants from "../../../../lib/WeekTimeline_Constants.mjs";
import * as DayTimeline_IntervalPositions from "../../../../../day_timeline/lib/positions/DayTimeline_IntervalPositions.mjs";
import * as DayTimeline_AssignedJobPositions from "../../../../../day_timeline/lib/positions/DayTimeline_AssignedJobPositions.mjs";

var horizontalPadding = Units.Px.fromInt(16);

function viewport(showUnscheduledJobs) {
  var documentWidth = Units.Px.fromInt(document.documentElement.clientWidth);
  return Units.Px.minus(Units.Px.minus(Units.Px.minus(documentWidth, WeekTimeline_Constants.sidebarWidth), horizontalPadding), showUnscheduledJobs ? WeekTimeline_Constants.unscheduledJobsWidth : WeekTimeline_Constants.hiddenUnscheduledJobsWidth);
}

var optimalViewPortWidth = Units.Px.fromInt(1600);

var optimalHourWidth = Units.Px.divideByInt(optimalViewPortWidth, 9);

function hour(viewport) {
  if (Caml_obj.lessequal(viewport, optimalViewPortWidth)) {
    return Units.Px.divideByInt(viewport, 9);
  }
  var width = Units.Px.multiplyWithInt(optimalHourWidth, WeekTimeline_Constants.hoursInDay);
  if (Caml_obj.lessequal(width, viewport)) {
    return Units.Px.divideByInt(viewport, WeekTimeline_Constants.hoursInDay);
  } else {
    return optimalHourWidth;
  }
}

function make(showUnscheduledJobs) {
  var viewport$1 = viewport(showUnscheduledJobs);
  var hour$1 = hour(viewport$1);
  return {
          minute: Units.Px.divideByInt(hour$1, WeekTimeline_Constants.minutesInHour),
          step: Units.Px.divideByInt(hour$1, WeekTimeline_Constants.stepsInHour),
          hour: hour$1,
          viewport: viewport$1,
          timeline: Units.Px.multiplyWithInt(hour$1, WeekTimeline_Constants.hoursInDay)
        };
}

var DomElements = {};

function make$1(wire, jobs, assigneeUuid, period, sizes) {
  var jobs$1 = RCore.$$Array.keep(jobs, (function (job) {
          if (Schedule_Types_Job.AssignedJob.assignees(job).some(function (a) {
                  return Uuid.equal(MultipleAssignee.uuid(a), assigneeUuid);
                })) {
            return Locale.T.intersectInterval([
                        Schedule_Types_Job.AssignedJob.startAt(job),
                        Schedule_Types_Job.AssignedJob.finishAt(job)
                      ], period);
          } else {
            return false;
          }
        }));
  return DayTimeline_AssignedJobPositions.make(wire, undefined, period[0], sizes.minute, jobs$1);
}

var Jobs = {
  make: make$1
};

function makeArray(wire, workersTimelines, assigneeUuid, period, sizes) {
  var intervals = RCore.$$Array.keep(RCore.$$Option.getOr(RCore.$$Option.map(RCore.$$Array.getBy(workersTimelines, (function (u) {
                      return Uuid.equal(u.entityUuid, assigneeUuid);
                    })), (function (u) {
                  return u.timeline;
                })), []), (function (s) {
          return Locale.T.intersectInterval(period, [
                      s.from,
                      s.to_
                    ]);
        }));
  return DayTimeline_IntervalPositions.make(wire, undefined, period[0], sizes.minute, intervals);
}

var Interval = {
  makeArray: makeArray
};

function key(param) {
  return "key-" + Uuid.toString(Assignee_Types.Assignee.uuid(param.bar)) + "-" + param.weekDay.toString();
}

var ActiveDay = {
  key: key
};

function assignee(activeDay) {
  return activeDay.bar;
}

function assigneeUuid(activeDay) {
  return Assignee_Types.Assignee.uuid(activeDay.bar);
}

function weekDay(activeDay) {
  return activeDay.weekDay;
}

function period(activeDay) {
  return activeDay.period;
}

function jobs(activeDay) {
  return activeDay.jobs;
}

function sizes(activeDay) {
  return activeDay.sizes;
}

function elements(activeDay) {
  return activeDay.elements;
}

function scrollSubscriptions(activeDay) {
  return activeDay.scrollSubscriptions;
}

var ActiveDaySelector = {
  assignee: assignee,
  assigneeUuid: assigneeUuid,
  weekDay: weekDay,
  period: period,
  jobs: jobs,
  sizes: sizes,
  elements: elements,
  scrollSubscriptions: scrollSubscriptions
};

var State = {
  initial: {
    activeDay: undefined,
    hiddenActiveDay: undefined
  }
};

var Sizes = {
  make: make
};

export {
  Sizes ,
  DomElements ,
  Jobs ,
  Interval ,
  ActiveDay ,
  ActiveDaySelector ,
  State ,
}
/* horizontalPadding Not a pure module */
