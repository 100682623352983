

import * as React from "react";
import * as WeekTimeline_ActiveDayCurrentTimeHook from "./WeekTimeline_ActiveDayCurrentTimeHook.mjs";
import * as WeekTimeline_ActiveDayCurrentTimeModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/active_day/components/current_time/WeekTimeline_ActiveDayCurrentTime.module.css";

var styles = WeekTimeline_ActiveDayCurrentTimeModuleCss;

function WeekTimeline_ActiveDayCurrentTime(Props) {
  var activeDay = Props.activeDay;
  var ref = WeekTimeline_ActiveDayCurrentTimeHook.use(activeDay);
  return React.createElement("div", {
              ref: ref,
              className: styles.line
            });
}

var make = WeekTimeline_ActiveDayCurrentTime;

export {
  make ,
}
/* styles Not a pure module */
