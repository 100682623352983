

import * as Utils from "../../utils/Utils.mjs";
import * as PromiseF from "../../libraries/PromiseF.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.mjs";
import * as Context_Types from "../../context/Context_Types.mjs";
import * as Types_Spatial from "../../types/Types_Spatial.mjs";

function ready(ymapsApi) {
  return PromiseF.make(function (resolve, _reject) {
              ymapsApi.ready(function () {
                    resolve(ymapsApi);
                  });
            });
}

function getApi() {
  return window.ymaps;
}

function getApiResult() {
  var ymapsApi = window.ymaps;
  if (ymapsApi !== undefined) {
    return {
            TAG: "Ok",
            _0: Caml_option.valFromOption(ymapsApi)
          };
  } else {
    return {
            TAG: "Error",
            _0: "ApiNotAvailable"
          };
  }
}

function mapLanguage(lang) {
  if (typeof lang === "object" || !(lang === "uz_cyrl" || lang === "ru")) {
    return "en_US";
  } else {
    return "ru_RU";
  }
}

function url(ctx) {
  var mapLanguage$1 = mapLanguage(ctx.localizator.lang);
  var yandexGeocoderApiKey = ctx.mapApiKey;
  return "//api-maps.yandex.ru/2.1/?lang=" + mapLanguage$1 + "&apikey=" + yandexGeocoderApiKey;
}

function load(ctx) {
  return PromiseF.map(Utils.loadScript(url(ctx)), (function () {
                return getApiResult();
              }));
}

var Loader = {
  mapLanguage: mapLanguage,
  url: url,
  load: load
};

var CustomClass = {};

function createClass(ymapsApi, htmlString) {
  return ymapsApi.templateLayoutFactory.createClass(htmlString);
}

var TemplateLayoutFactory = {
  CustomClass: CustomClass,
  createClass: createClass
};

function toString(eventType) {
  switch (eventType) {
    case "Click" :
        return "click";
    case "MouseEnter" :
        return "mouseenter";
    case "MouseLeave" :
        return "mouseleave";
    case "MouseMove" :
        return "mousemove";
    
  }
}

function get($$event) {
  var match = $$event.get("coords");
  return Types_Spatial.$$Geolocation.make(match[0], match[1]);
}

function add(obj, $$event, handler) {
  obj.add(toString($$event), handler);
}

function string(v) {
  return v;
}

var auto = "auto";

function fromGeolocation(param) {
  return [
          param.latitude,
          param.longitude
        ];
}

var Coordinate = {
  fromGeolocation: fromGeolocation
};

function fromBoundingBox(bounds) {
  return [
          [
            Types_Spatial.Latitude.toNumber(bounds.southWest.latitude),
            Types_Spatial.Longitude.toNumber(bounds.southWest.longitude)
          ],
          [
            Types_Spatial.Latitude.toNumber(bounds.northEast.latitude),
            Types_Spatial.Longitude.toNumber(bounds.northEast.longitude)
          ]
        ];
}

var BoundingBox = {
  fromBoundingBox: fromBoundingBox
};

var Properties = {};

var Options = {};

var make = ((Constructor, arg1, arg2, arg3) => new Constructor(arg1, arg2, arg3));

function make$1(ymapsApi, coordinates, properties, options) {
  return make(ymapsApi.Polyline, coordinates, properties, options);
}

var Constructor = {};

var Options$1 = {};

var Properties$1 = {};

var _make = ((Constructor, arg1, arg2, arg3) => new Constructor(arg1, arg2, arg3));

function make$2(ymapsApi, coordinates, options, properties) {
  return _make(ymapsApi.Placemark, coordinates, options, properties);
}

var Options$2 = {};

var AutoFitToViewport = {};

var FitOptions = {
  AutoFitToViewport: AutoFitToViewport
};

function get$1(__x) {
  return __x.get("newZoom");
}

var Zoom = {
  get: get$1
};

var $$Event = {
  Zoom: Zoom
};

function add$1(obj, $$event, handler) {
  obj.add("boundschange", handler);
}

var _make$1 = ((Constructor, arg1, arg2, arg3) => new Constructor(arg1, arg2, arg3));

function make$3(ymapsApi, element, options) {
  return function (fitOptions) {
    return _make$1(ymapsApi.Map, element, options, fitOptions);
  };
}

function makeResult(element, options) {
  return Core__Result.map(getApiResult(), (function (api) {
                return make$3(api, element, options);
              }));
}

function addGeoObject(map, geoObject) {
  return map.geoObjects.add(geoObject);
}

function removeGeoObject(map, geoObject) {
  return map.geoObjects.remove(geoObject);
}

var position = {
  top: 100,
  right: 10,
  bottom: auto,
  left: auto
};

var defaultOptions = {
  position: position
};

var Options$3 = {};

var position$1 = {
  top: 45,
  right: 10,
  bottom: auto,
  left: auto
};

var defaultOptions_position = position$1;

var defaultOptions_panoramasItemMode = "off";

var defaultOptions$1 = {
  size: "small",
  position: defaultOptions_position,
  panoramasItemMode: defaultOptions_panoramasItemMode
};

function add$2(map, control, features) {
  switch (control) {
    case "Ruler" :
        var t = map.controls;
        t.add("rulerControl", {
              scaleLine: false,
              position: undefined
            });
        return ;
    case "Zoom" :
        var t$1 = map.controls;
        t$1.add("zoomControl", defaultOptions);
        return ;
    case "Views" :
        if (Context_Types.Features.hasFlag("yandexSatelliteView", features)) {
          var t$2 = map.controls;
          t$2.add("typeSelector", defaultOptions$1);
          return ;
        } else {
          return ;
        }
    
  }
}

function get$2(ymaps) {
  return PromiseF.fromJs(ymaps.geolocation.get({
                  provider: "yandex",
                  mapStateAutoApply: true,
                  autoReverseGeocode: false
                }));
}

function getUserGeolocation(ctx) {
  return load(ctx).then((function (ymaps) {
                if (ymaps.TAG === "Ok") {
                  return PromiseF.map(PromiseF.bind(ready(ymaps._0), get$2), (function (geolocationRes) {
                                return {
                                        TAG: "Ok",
                                        _0: geolocationRes.geoObjects.position
                                      };
                              }));
                } else {
                  return Promise.resolve({
                              TAG: "Error",
                              _0: "ApiNotAvailable"
                            });
                }
              }), (function (_e) {
                return Promise.resolve({
                            TAG: "Error",
                            _0: "LoadScriptError"
                          });
              }));
}

var defineArrow = (function (ymaps) {
    ymaps.modules.define("geoObject.Arrow", [
        'Polyline',
        'overlay.Arrow',
        'util.extend'
    ], function (provide, Polyline, ArrowOverlay, extend) {
        var Arrow = function (geometry, properties, options) {
            return new Polyline(geometry, properties, extend({}, options, {
                lineStringOverlay: ArrowOverlay
            }));
        };
        provide(Arrow);
    });
    ymaps.modules.define("overlay.Arrow", [
        'overlay.Polygon',
        'util.extend',
        'event.Manager',
        'option.Manager',
        'Event',
        'geometry.pixel.Polygon'
    ], function (provide, PolygonOverlay, extend, EventManager, OptionManager, Event, PolygonGeometry) {
        var domEvents = [
                'click',
                'contextmenu',
                'dblclick',
                'mousedown',
                'mouseenter',
                'mouseleave',
                'mousemove',
                'mouseup',
                'multitouchend',
                'multitouchmove',
                'multitouchstart',
                'wheel'
            ],
            ArrowOverlay = function (pixelGeometry, data, options) {
                this.events = new EventManager();
                this.options = new OptionManager(options);
                this._map = null;
                this._data = data;
                this._geometry = pixelGeometry;
                this._overlay = null;
            };
        ArrowOverlay.prototype = extend(ArrowOverlay.prototype, {
            getData: function () {
                return this._data;
            },
            setData: function (data) {
                if (this._data != data) {
                    var oldData = this._data;
                    this._data = data;
                    this.events.fire('datachange', {
                        oldData: oldData,
                        newData: data
                    });
                }
            },
            getMap: function () {
                return this._map;
            },
            setMap: function (map) {
                if (this._map != map) {
                    var oldMap = this._map;
                    if (!map) {
                        this._onRemoveFromMap();
                    }
                    this._map = map;
                    if (map) {
                        this._onAddToMap();
                    }
                    this.events.fire('mapchange', {
                        oldMap: oldMap,
                        newMap: map
                    });
                }
            },
            setGeometry: function (geometry) {
                if (this._geometry != geometry) {
                    var oldGeometry = geometry;
                    this._geometry = geometry;
                    if (this.getMap() && geometry) {
                        this._rebuild();
                    }
                    this.events.fire('geometrychange', {
                        oldGeometry: oldGeometry,
                        newGeometry: geometry
                    });
                }
            },
            getGeometry: function () {
                return this._geometry;
            },
            getShape: function () {
                return null;
            },
            isEmpty: function () {
                return false;
            },
            _rebuild: function () {
                this._onRemoveFromMap();
                this._onAddToMap();
            },
            _onAddToMap: function () {
                this._overlay = new PolygonOverlay(new PolygonGeometry(this._createArrowContours()));
                this._startOverlayListening();
                this._overlay.options.setParent(this.options);
                this._overlay.setMap(this.getMap());
            },
            _onRemoveFromMap: function () {
                this._overlay.setMap(null);
                this._overlay.options.setParent(null);
                this._stopOverlayListening();
            },
            _startOverlayListening: function () {
                this._overlay.events.add(domEvents, this._onDomEvent, this);
            },
            _stopOverlayListening: function () {
                this._overlay.events.remove(domEvents, this._onDomEvent, this);
            },
            _onDomEvent: function (e) {
                this.events.fire(e.get('type'), new Event({
                    target: this
                }, e));
            },
            _createArrowContours: function () {
                var contours = [],
                    lastTwoCoordinates = this.getGeometry().getCoordinates(),
                    arrowLength = calculateArrowLength(
                        lastTwoCoordinates,
                        this.options.get('arrowMinLength', 3),
                        this.options.get('arrowMaxLength', 6)
                    );
                contours.push(getContourFromLineCoordinates(lastTwoCoordinates));
                if (arrowLength > 0) {
                    var rotationAngle = getRotationAngle(lastTwoCoordinates[0], lastTwoCoordinates[1]),
                        rotatedCoordinates = rotate(lastTwoCoordinates, rotationAngle),
                        arrowAngle = this.options.get('arrowAngle', 30) / 180 * Math.PI,
                        arrowBeginningCoordinates = getArrowsBeginningCoordinates(
                            rotatedCoordinates,
                            arrowLength,
                            arrowAngle
                        ),
                        firstArrowCoordinates = rotate([
                            arrowBeginningCoordinates[0],
                            rotatedCoordinates[1]
                        ], -rotationAngle),
                        secondArrowCoordinates = rotate([
                            arrowBeginningCoordinates[1],
                            rotatedCoordinates[1]
                        ], -rotationAngle),
                        thirdArrowCoordinates = rotate([
                            arrowBeginningCoordinates[2],
                            rotatedCoordinates[1]
                        ], -rotationAngle),
                        fourthArrowCoordinates = rotate([
                            arrowBeginningCoordinates[3],
                            rotatedCoordinates[1]
                        ], -rotationAngle);
                    contours.push(getContourFromLineCoordinates(firstArrowCoordinates));
                    contours.push(getContourFromLineCoordinates(secondArrowCoordinates));
                    contours.push(getContourFromLineCoordinates(thirdArrowCoordinates));
                    contours.push(getContourFromLineCoordinates(fourthArrowCoordinates));
                }
                return contours;
            }
        });
        function getArrowsBeginningCoordinates (coordinates, arrowLength, arrowAngle) {
            var p1 = coordinates[0],
                p2 = coordinates[1],
                dx = arrowLength * Math.sin(arrowAngle),
                dxHalf = arrowLength * Math.sin(arrowAngle/2),
                yHalf = p2[1] - arrowLength * Math.cos(arrowAngle/2),
                y = p2[1] - arrowLength * Math.cos(arrowAngle);
            return [[p1[0] - dx, y], [p1[0] + dx, y], [p1[0] - dxHalf, yHalf], [p1[0] + dxHalf, yHalf]];
        }
        function rotate (coordinates, angle) {
            var rotatedCoordinates = [];
            for (var i = 0, l = coordinates.length, x, y; i < l; i++) {
                x = coordinates[i][0];
                y = coordinates[i][1];
                rotatedCoordinates.push([
                    x * Math.cos(angle) - y * Math.sin(angle),
                    x * Math.sin(angle) + y * Math.cos(angle)
                ]);
            }
            return rotatedCoordinates;
        }
        function getRotationAngle (p1, p2) {
            return Math.PI / 2 - Math.atan2(p2[1] - p1[1], p2[0] - p1[0]);
        }
        function getContourFromLineCoordinates (coords) {
            var contour = coords.slice();
            for (var i = coords.length - 2; i > -1; i--) {
                contour.push(coords[i]);
            }
            return contour;
        }
        function calculateArrowLength (coords, minLength, maxLength) {
            var linePixelLength = 0;
            for (var i = 1, l = coords.length; i < l; i++) {
                linePixelLength += getVectorLength(
                    coords[i][0] - coords[i - 1][0],
                    coords[i][1] - coords[i - 1][1]
                );
                if (linePixelLength / 3 > maxLength) {
                    return maxLength;
                }
            }
            var finalArrowLength = linePixelLength / 3;
            return finalArrowLength < minLength ? 0 : finalArrowLength;
        }
        function getVectorLength (x, y) {
            return Math.sqrt(x * x + y * y);
        }
        provide(ArrowOverlay);
    });
  });

var addArrow = (function (ymapsApi, map, updateArrow, coordinates, properties, options) {
      ymapsApi.modules.require(['geoObject.Arrow'], function (Arrow) {
        if (map) {
          const arrow = new Arrow(coordinates, properties, options);

          map.geoObjects.add(arrow);
          updateArrow(arrow)();
        };
      });
    });

var removeObject = (function (map, arrow) {
    if (map) {
      map.geoObjects.remove(arrow);
    }
  });

var ymapsApi = window.ymaps;

var Events_EventType = {};

var Events_Event = {
  $$Geolocation: {
    get: get
  }
};

var Events = {
  EventType: Events_EventType,
  $$Event: Events_Event,
  add: add
};

var IntOrString = {
  string: string
};

var GeoObject_Polyline = {
  Properties: Properties,
  Options: Options,
  make: make$1,
  events: (function (prim) {
      return prim.events;
    })
};

var GeoObject_Placemark = {
  Constructor: Constructor,
  Options: Options$1,
  Properties: Properties$1,
  make: make$2
};

var GeoObject = {
  Polyline: GeoObject_Polyline,
  Placemark: GeoObject_Placemark
};

var Map_Events = {
  EventType: {},
  $$Event: $$Event,
  add: add$1
};

function Map_geoObjects(prim) {
  return prim.geoObjects;
}

function Map_panTo(prim0, prim1, prim2) {
  prim0.panTo(prim1, prim2);
}

function Map_setBounds(prim0, prim1) {
  prim0.setBounds(prim1);
}

function Map_getZoom(prim) {
  return prim.getZoom();
}

function Map_events(prim) {
  return prim.events;
}

var $$Map = {
  Options: Options$2,
  FitOptions: FitOptions,
  Events: Map_Events,
  make: make$3,
  makeResult: makeResult,
  geoObjects: Map_geoObjects,
  addGeoObject: addGeoObject,
  removeGeoObject: removeGeoObject,
  panTo: Map_panTo,
  setBounds: Map_setBounds,
  getZoom: Map_getZoom,
  events: Map_events
};

var Controls_Option = {
  Views: {
    Size: {},
    PanoramaMode: {},
    Options: Options$3
  }
};

var Controls = {
  $$Option: Controls_Option,
  add: add$2
};

export {
  ready ,
  ymapsApi ,
  getApi ,
  getApiResult ,
  Loader ,
  TemplateLayoutFactory ,
  Events ,
  IntOrString ,
  Coordinate ,
  BoundingBox ,
  GeoObject ,
  $$Map ,
  Controls ,
  getUserGeolocation ,
  defineArrow ,
  addArrow ,
  removeObject ,
}
/* ymapsApi Not a pure module */
