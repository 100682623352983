

import * as JsonDecode from "../libraries/JsonDecode.mjs";

function fromString(lang) {
  switch (lang) {
    case "ar" :
        return "ar";
    case "bg" :
        return "bg";
    case "cs" :
        return "cs";
    case "en" :
        return "en";
    case "es" :
        return "es";
    case "fr" :
        return "fr";
    case "pl" :
        return "pl";
    case "ro" :
        return "ro";
    case "ru" :
        return "ru";
    case "uz" :
        return "uz";
    case "uz_cyrl" :
        return "uz_cyrl";
    default:
      return {
              NAME: "unknown",
              VAL: lang
            };
  }
}

function fromStringOpt(s) {
  switch (s) {
    case "ar" :
        return "ar";
    case "bg" :
        return "bg";
    case "cs" :
        return "cs";
    case "en" :
        return "en";
    case "es" :
        return "es";
    case "fr" :
        return "fr";
    case "pl" :
        return "pl";
    case "ro" :
        return "ro";
    case "ru" :
        return "ru";
    case "uz" :
        return "uz";
    case "uz_cyrl" :
        return "uz_cyrl";
    default:
      return ;
  }
}

function toString(lang) {
  if (typeof lang === "object") {
    return lang.VAL;
  } else if (lang === "bg") {
    return "bg";
  } else if (lang === "cs") {
    return "cs";
  } else if (lang === "en") {
    return "en";
  } else if (lang === "es") {
    return "es";
  } else if (lang === "fr") {
    return "fr";
  } else if (lang === "pl") {
    return "pl";
  } else if (lang === "ro") {
    return "ro";
  } else if (lang === "ru") {
    return "ru";
  } else if (lang === "uz") {
    return "uz";
  } else if (lang === "uz_cyrl") {
    return "uz_cyrl";
  } else {
    return "ar";
  }
}

function rtl(lang) {
  if (typeof lang === "object") {
    return false;
  } else {
    return !(lang === "uz_cyrl" || lang === "uz" || lang === "ru" || lang === "ro" || lang === "pl" || lang === "fr" || lang === "es" || lang === "en" || lang === "cs" || lang === "bg");
  }
}

var decode = JsonDecode.map(JsonDecode.string, fromString);

export {
  fromString ,
  fromStringOpt ,
  toString ,
  rtl ,
  decode ,
}
/* decode Not a pure module */
