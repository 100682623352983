

import * as El from "../../../libraries/El.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as React from "react";
import * as Portal from "../../../Portal.mjs";
import * as Optional from "../Optional.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Webapi__Dom__Element from "rescript-webapi/lib/es6/src/Webapi/Dom/Webapi__Dom__Element.mjs";
import * as Webapi__Dom__HtmlElement from "rescript-webapi/lib/es6/src/Webapi/Dom/Webapi__Dom__HtmlElement.mjs";
import * as PanelModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/Panel/Panel.module.css";

var documentElement = document.documentElement;

function getScrollWidth() {
  var bodyHtmlEl = RCore.$$Option.flatMap(Caml_option.nullable_to_opt(documentElement.querySelector("body")), Webapi__Dom__Element.asHtmlElement);
  if (bodyHtmlEl !== undefined) {
    return window.innerWidth - Caml_option.valFromOption(bodyHtmlEl).offsetWidth | 0;
  } else {
    return 15.0;
  }
}

function toPx(num) {
  return num.toString() + "px";
}

var styles = PanelModuleCss;

function Panel(Props) {
  var footer = Props.footer;
  var showOpt = Props.show;
  var domId = Props.domId;
  var positionOpt = Props.position;
  var classNameOpt = Props.className;
  var propagateClickOpt = Props.propagateClick;
  var offsetXOpt = Props.offsetX;
  var offsetYOpt = Props.offsetY;
  var children = Props.children;
  var show = showOpt !== undefined ? showOpt : true;
  var position = positionOpt !== undefined ? positionOpt : "bottom";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var propagateClick = propagateClickOpt !== undefined ? propagateClickOpt : false;
  var offsetX = offsetXOpt !== undefined ? offsetXOpt : 0.0;
  var offsetY = offsetYOpt !== undefined ? offsetYOpt : 0.0;
  var popover = React.useRef(null);
  var match = React.useState(function () {
        return {
                opacity: "0"
              };
      });
  var setStyle = match[1];
  React.useEffect((function () {
          var el = RCore.$$Option.flatMap(Caml_option.nullable_to_opt(popover.current), Webapi__Dom__HtmlElement.ofElement);
          if (el !== undefined && show) {
            var el$1 = Caml_option.valFromOption(el);
            var contentWidth = el$1.offsetWidth;
            var contentHeight = el$1.offsetHeight;
            setStyle(function (param) {
                  var el = document.getElementById(domId);
                  if (el == null) {
                    return {};
                  }
                  var elRect = el.getBoundingClientRect();
                  var top = elRect.top + documentElement.scrollTop;
                  var bottom = elRect.bottom + window.pageYOffset;
                  var left = elRect.left;
                  var right = elRect.right;
                  var width = elRect.width;
                  var componentBottom = bottom + contentHeight;
                  var bottomLeftStyle = function () {
                    return {
                            left: toPx(left),
                            top: toPx(bottom + 2.0)
                          };
                  };
                  var bottomCenterStyle = function () {
                    return {
                            left: toPx(left - contentWidth / 2.0 + width / 2.0 - offsetX),
                            top: toPx(bottom + offsetY + 2.0)
                          };
                  };
                  var bottomRightStyle = function () {
                    return {
                            right: toPx(window.innerWidth - getScrollWidth() - right - offsetX),
                            top: toPx(bottom + offsetY + 2.0)
                          };
                  };
                  var windowWidth = window.innerWidth - getScrollWidth();
                  var canFitLeft = contentWidth + left < windowWidth;
                  var canFitCenter = contentWidth + left - contentWidth / 2.0 + width / 2.0 - offsetX < windowWidth;
                  if (position === "left") {
                    return {
                            left: toPx(left + offsetX),
                            top: toPx(bottom + offsetY)
                          };
                  }
                  if (position === "bottomRight") {
                    return bottomRightStyle();
                  }
                  if (position === "bottom") {
                    if (canFitLeft) {
                      return bottomLeftStyle();
                    } else if (canFitCenter) {
                      return bottomCenterStyle();
                    } else {
                      return bottomRightStyle();
                    }
                  }
                  if (position === "right") {
                    return {
                            left: toPx(left + width + contentWidth + offsetX),
                            top: toPx(top + offsetY)
                          };
                  }
                  if (position === "bottomCenter") {
                    return bottomCenterStyle();
                  }
                  if (position === "top") {
                    var scrollHeight = documentElement.scrollHeight;
                    return {
                            bottom: toPx(scrollHeight - top - offsetY + 2.0),
                            left: toPx(left + offsetX)
                          };
                  }
                  if (position === "bottomLeft") {
                    return bottomLeftStyle();
                  }
                  var windowHeight = window.innerHeight;
                  var fitsHorizontally = contentWidth + left < windowWidth;
                  var fitsVertically = bottom < contentHeight || windowHeight > componentBottom;
                  if (fitsHorizontally) {
                    if (fitsVertically) {
                      return {
                              left: toPx(left),
                              top: toPx(bottom + 2.0)
                            };
                    } else {
                      return {
                              left: toPx(left + offsetX),
                              top: toPx(bottom - contentHeight - 17.0)
                            };
                    }
                  } else {
                    return {
                            left: toPx(windowWidth - contentWidth),
                            top: fitsVertically ? toPx(bottom + 2.0) : toPx(bottom - contentHeight - 17.0)
                          };
                  }
                });
          }
          
        }), [show]);
  var onClick = propagateClick ? (function (prim) {
        
      }) : (function (prim) {
        prim.stopPropagation();
      });
  return React.createElement(Optional.make, {
              show: show,
              children: React.createElement(Portal.make, {
                    root: footer,
                    children: React.createElement("div", {
                          ref: popover,
                          className: El.Cn.concat(styles.panel, className),
                          role: "dialog",
                          style: match[0],
                          onClick: onClick
                        }, children)
                  })
            });
}

var Position = {};

var make = Panel;

export {
  Position ,
  make ,
}
/* documentElement Not a pure module */
