

import * as Types from "../../../../../types/Types.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_TimelineSidebarModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/timeline/sidebar/Schedule_TimelineSidebar.module.css";

var styles = Schedule_TimelineSidebarModuleCss;

function use(onActiveAssigneeChanged, uuid) {
  var elementRef = Types.ReactRef.use();
  var active = React.useRef(false);
  React.useEffect((function () {
          var element = Types.ReactRef.toOption(elementRef);
          if (element === undefined) {
            return ;
          }
          var element$1 = Caml_option.valFromOption(element);
          var resetActive = function (element) {
            element.classList.remove(styles.active);
            active.current = false;
          };
          var unsub = onActiveAssigneeChanged.subscribe(function ($$event) {
                if ($$event === undefined) {
                  if (active.current) {
                    return resetActive(element$1);
                  } else {
                    return ;
                  }
                }
                var assigneeUuid = Caml_option.valFromOption($$event);
                if (!active.current && Caml_obj.equal(uuid, assigneeUuid)) {
                  element$1.classList.add(styles.active);
                  active.current = true;
                  return ;
                } else if (active.current && Caml_obj.notequal(uuid, assigneeUuid)) {
                  return resetActive(element$1);
                } else {
                  return ;
                }
              });
          return (function () {
                    unsub();
                  });
        }), []);
  return elementRef;
}

export {
  use ,
}
/* styles Not a pure module */
