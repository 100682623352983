

import * as Wire from "../../../types/Wire.mjs";
import * as Modal from "../../../components/modal/Modal.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Title from "../../../components/common/Title.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Errors from "../../../Errors.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Optional from "../../../components/common/Optional.mjs";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as MessengerTypes from "../../../components/messenger/MessengerTypes.mjs";
import * as Pages_Site_API from "./Pages_Site_API.mjs";
import * as Pages_Site_Body from "./Pages_Site_Body.mjs";
import * as RelatedEntities from "../../../components/common/RelatedEntities/RelatedEntities.mjs";
import * as Pages_Site_Types from "./Pages_Site_Types.mjs";
import * as Pages_Site_Footer from "./Pages_Site_Footer.mjs";
import * as Pages_Site_Heading from "./Pages_Site_Heading.mjs";
import * as Pages_SiteModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/sites/edit/Pages_Site.module.css";

var styles = Pages_SiteModuleCss;

var created$p = Utils.Translations.t("js.sites.messages.created");

var destroyed$p = Utils.Translations.t("js.sites.messages.destroyed");

var updated$p = Utils.Translations.t("js.sites.messages.updated");

function showErrorNotification(messenger, ctx) {
  messenger.trigger({
        TAG: "Show",
        _0: MessengerTypes.Message.make(undefined, "Danger", {
              TAG: "Text",
              _0: Utils.Translations.t("js.errors.internal_error")(ctx)
            })
      });
}

function Pages_Site(Props) {
  var wire = Props.wire;
  var subRoute = Props.subRoute;
  var messenger = wire.subscriptions.messenger;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        
      });
  var setStateOptional = match[1];
  var state = match[0];
  var setState = function (fun) {
    setStateOptional(function (current) {
          return RCore.$$Option.map(current, fun);
        });
  };
  var match$1 = React.useState(function () {
        
      });
  var setInitialName = match$1[1];
  var initialName = match$1[0];
  React.useEffect((function () {
          Backend.$$finally(Pages_Site_API.Get.send(wire, subRoute), (function (response) {
                  if (response.TAG !== "Ok") {
                    return showErrorNotification(messenger, ctx);
                  }
                  var data = response._0;
                  setStateOptional(function (param) {
                        return data;
                      });
                  setInitialName(function (param) {
                        return data.site.name;
                      });
                }));
        }), []);
  var isNewRecord = typeof subRoute === "object" ? false : true;
  var onClose = function () {
    Wire.closeModal(wire);
  };
  var unlock = function () {
    setState(function (current) {
          return {
                  canManage: current.canManage,
                  locked: false,
                  site: current.site,
                  addressConstraints: current.addressConstraints,
                  errors: current.errors
                };
        });
  };
  var onSubmitted = function () {
    Wire.closeModal(wire);
    wire.subscriptions.sites.trigger("SiteSubmitted");
  };
  var onDestroy = function () {
    if (state === undefined) {
      return ;
    }
    var uuid = state.site.uuid;
    if (uuid !== undefined) {
      setState(function (current) {
            return {
                    canManage: current.canManage,
                    locked: true,
                    site: current.site,
                    addressConstraints: current.addressConstraints,
                    errors: []
                  };
          });
      Backend.$$finally(Pages_Site_API.Delete.send(wire, Caml_option.valFromOption(uuid)), (function (r) {
              if (r.TAG === "Ok") {
                onSubmitted();
                return messenger.trigger({
                            TAG: "Show",
                            _0: MessengerTypes.Message.make(undefined, undefined, {
                                  TAG: "Text",
                                  _0: destroyed$p(ctx)
                                })
                          });
              }
              var match = r._0;
              if (typeof match === "object" && match.TAG === "HTTPError") {
                var match$1 = match._0;
                var tmp = match$1[0];
                if (typeof tmp !== "object" && tmp === "UnprocessableEntity") {
                  var text = match$1[1];
                  return setState(function (current) {
                              return {
                                      canManage: current.canManage,
                                      locked: false,
                                      site: current.site,
                                      addressConstraints: current.addressConstraints,
                                      errors: RCore.$$Array.filterMap(Core__List.toArray(Errors.parse(text)), Pages_Site_Types.$$Error.fromString)
                                    };
                            });
                }
                
              }
              showErrorNotification(messenger, ctx);
              unlock();
            }));
      return ;
    }
    
  };
  var onUpdate = function (evt) {
    evt.preventDefault();
    if (state === undefined) {
      return ;
    }
    var site = state.site;
    var uuid = site.uuid;
    if (uuid !== undefined) {
      setState(function (current) {
            return {
                    canManage: current.canManage,
                    locked: true,
                    site: current.site,
                    addressConstraints: current.addressConstraints,
                    errors: []
                  };
          });
      Backend.$$finally(Pages_Site_API.Patch.send(wire, Caml_option.valFromOption(uuid), site), (function (r) {
              if (r.TAG === "Ok") {
                onSubmitted();
                return messenger.trigger({
                            TAG: "Show",
                            _0: MessengerTypes.Message.make(undefined, undefined, {
                                  TAG: "Text",
                                  _0: updated$p(ctx)
                                })
                          });
              }
              var match = r._0;
              if (typeof match === "object" && match.TAG === "HTTPError") {
                var match$1 = match._0;
                var tmp = match$1[0];
                if (typeof tmp !== "object" && tmp === "UnprocessableEntity") {
                  var text = match$1[1];
                  return setState(function (current) {
                              return {
                                      canManage: current.canManage,
                                      locked: false,
                                      site: current.site,
                                      addressConstraints: current.addressConstraints,
                                      errors: RCore.$$Array.filterMap(Core__List.toArray(Errors.parse(text)), Pages_Site_Types.$$Error.fromString)
                                    };
                            });
                }
                
              }
              showErrorNotification(messenger, ctx);
              unlock();
            }));
      return ;
    }
    
  };
  var onCreate = function (evt) {
    evt.preventDefault();
    if (state !== undefined) {
      setState(function (current) {
            return {
                    canManage: current.canManage,
                    locked: true,
                    site: current.site,
                    addressConstraints: current.addressConstraints,
                    errors: []
                  };
          });
      Backend.$$finally(Pages_Site_API.Post.send(wire, state.site), (function (r) {
              if (r.TAG === "Ok") {
                onSubmitted();
                return messenger.trigger({
                            TAG: "Show",
                            _0: MessengerTypes.Message.make(undefined, undefined, {
                                  TAG: "Text",
                                  _0: created$p(ctx)
                                })
                          });
              }
              var match = r._0;
              if (typeof match === "object" && match.TAG === "HTTPError") {
                var match$1 = match._0;
                var tmp = match$1[0];
                if (typeof tmp !== "object" && tmp === "UnprocessableEntity") {
                  var text = match$1[1];
                  return setState(function (current) {
                              return {
                                      canManage: current.canManage,
                                      locked: false,
                                      site: current.site,
                                      addressConstraints: current.addressConstraints,
                                      errors: RCore.$$Array.filterMap(Core__List.toArray(Errors.parse(text)), Pages_Site_Types.$$Error.fromString)
                                    };
                            });
                }
                
              }
              showErrorNotification(messenger, ctx);
              unlock();
            }));
      return ;
    }
    
  };
  if (state === undefined) {
    return null;
  }
  var tmp = {
    pageType: "Site",
    listType: "JobsAndRec",
    compact: true,
    wire: wire
  };
  if (state.site.uuid !== undefined) {
    tmp.uuid = Caml_option.valFromOption(state.site.uuid);
  }
  return React.createElement(Modal.make, {
              wire: wire,
              onClose: onClose,
              show: true,
              children: null
            }, React.createElement(Title.make, {
                  route: wire.route,
                  ctx: ctx,
                  values: {
                    name: initialName
                  },
                  wire: wire
                }), React.createElement("form", {
                  className: styles.wrapper,
                  onSubmit: state.site.isNewRecord ? onCreate : onUpdate
                }, React.createElement("div", {
                      className: styles.header
                    }, React.createElement(Pages_Site_Heading.make, {
                          state: state,
                          setState: setState,
                          initialName: initialName
                        })), React.createElement("div", {
                      className: styles.body
                    }, React.createElement("div", {
                          className: styles.container
                        }, React.createElement(Pages_Site_Body.make, {
                              state: state,
                              setState: setState
                            }), React.createElement(Optional.make, {
                              show: !isNewRecord,
                              children: React.createElement("div", {
                                    className: styles.relatedEntities
                                  }, React.createElement(RelatedEntities.make, tmp))
                            }))), React.createElement("div", {
                      className: styles.footer
                    }, React.createElement(Pages_Site_Footer.make, {
                          state: state,
                          onDestroy: onDestroy
                        }))));
}

var make = Pages_Site;

export {
  make ,
}
/* styles Not a pure module */
