import { stringify } from 'qs'

const buildPath = (path, args = {}) => path + '?' + stringify(args)

export const jobsUpload = '/jobs/upload'
export const jobPath = id => `/jobs/${id}`
export const jobSchedulePath = id => `/jobs/${id}/schedule`
export const jobAssignPath = id => `/jobs/${id}/assign`
export const jobUnassignPath = id => `/jobs/${id}/unassign`
export const jobUnschedulePath = id => `/jobs/${id}/unschedule`
export const jobStartPath = id => `/jobs/${id}/start`
export const jobResumePath = id => `/jobs/${id}/resume`

export const fetchSchedulePath = '/schedule/fetch'

export const clientPath = uuid => `/clients/${uuid}`

export const mapWorkerLocationsPath = (worker_uuid, date) =>
  buildPath('/map/workers/locations', { worker_uuid, date })
export const mapWorkerJobsPath = (worker_uuid, date) =>
  buildPath('/map/workers/jobs', { worker_uuid, date })

export const templatePath = uuid => `/admin/templates/${uuid}`
export const templatesPath = '/admin/templates'

export const templatePossibleFieldNames = (read_only, type, name, data_type) =>
  buildPath(`/admin/templates/possible-field-names/${type}`, {
    read_only,
    name,
    data_type
  })
export const templateCheckName = (name, uuid) =>
  buildPath('/admin/templates/check-template-name', { name, uuid })


export const searchOrganizationPath = query =>
  buildPath('/clients/search', { organization: true, query })
export const searchAddressPath = query => buildPath('/sites/search', { query })

export const sitePath = uuid => `/sites/${uuid}`

export const newDictionaryPath = '/admin/dictionaries/new'
export const dictionaryPath = uuid => `/admin/dictionaries/${uuid}`
export const dictionariesPath = '/admin/dictionaries'

export const recurringJobsPath = '/admin/recurring_jobs'
export const recurringJobPath = uuid => `/admin/recurring_jobs/${uuid}`
export const newRecurringJobPath = uuid => ({
  pathname: '/admin/recurring_jobs/new',
  search: `?template=${uuid}`
})

export const settingsTransferPath = '/admin/settings/transfer'
export const settingsPath = '/admin/settings'
export const jobTypesPath = '/admin/settings/job_types'
export const resolutionsPath = '/admin/settings/resolutions'
export const skillsPath = '/admin/skills'
export const territoriesPath = '/admin/territories'

export const analyticsPath = name => `/analytics/${name}`
