

import * as JsonDecode from "../../../../libraries/JsonDecode.mjs";

function fromString(str) {
  switch (str) {
    case "actual" :
        return "Actual";
    case "scheduled" :
        return "Scheduled";
    default:
      return ;
  }
}

function toString(jobDuration) {
  if (jobDuration === "Scheduled") {
    return "scheduled";
  } else {
    return "actual";
  }
}

var decode = JsonDecode.fromVariant(JsonDecode.string, fromString);

export {
  fromString ,
  toString ,
  decode ,
}
/* decode Not a pure module */
