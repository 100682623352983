import React, { Component } from 'react'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import { str2geolocation } from 'planado/utils/geolocation'

class LocationInput extends Component {
  state = {
    hasError: false,
    isEdit: false
  }

  get data() {
    return { ...this.props, ...this.state }
  }

  get action() {
    const { isEdit, geolocation, readOnly } = this.data

    if (isEdit && !readOnly) {
      return (
        <button
          type="button"
          className="pd-form-location__input-enter"
          onClick={this.onEnterHandle}
        >
          <span className="fa fa-level-down" />
        </button>
      )
    } else if (!isEdit && geolocation && !readOnly) {
      return (
        <button
          type="button"
          className="pd-form-location__input-enter"
          onClick={this.onEnterHandle}
        >
          <span className="fa fa-times-circle" />
        </button>
      )
    } else {
      return null
    }
  }

  get errors() {
    const { ctx, hasError } = this.data
    if (hasError) {
      return (
        <div className="pd-control-error">
          {ctx.t('js.address_input.coordinates_error')}
        </div>
      )
    } else {
      return null
    }
  }

  onChangeHandle = event => {
    const value = event.target.value

    this.setState(() => ({
      geolocation: value,
      isEdit: true
    }))

    this.props.changeLocation({
      isEdit: true
    })
  }

  onEnterHandle = (event, shouldSavePopup = '') => {
    const { geolocation, changeLocation, isEdit } = this.data

    if (
      (event.key === 'Enter' && geolocation.length === 0) ||
      (!isEdit && event.type === 'click')
    ) {
      this.setState({
        geolocation: ''
      })

      const data = {
        isEdit: false,
        isResolved: false,
        geolocation: null
      }

      if (shouldSavePopup === 'savePopup') {
        changeLocation(data, true)
      } else {
        changeLocation(data)
      }
    } else if (
      isEdit &&
      (event.type === 'click' ||
        (event.type === 'keypress' && event.key === 'Enter'))
    ) {
      const coords = str2geolocation(geolocation)

      if (coords === null) {
        this.setState(() => ({ hasError: true }))
      } else {
        this.setState(() => ({
          hasError: false,
          geolocation: coords,
          isEdit: false
        }))

        const data = {
          isEdit: false,
          isResolved: true,
          geolocation: coords
        }

        if (shouldSavePopup === 'savePopup') {
          changeLocation(data, true)
        } else {
          changeLocation(data)
        }
      }
    }
  }

  render() {
    const { geolocation, id, ctx } = this.data

    let coords
    if (geolocation == null) {
      coords = ''
    } else if (typeof geolocation === 'string') {
      coords = geolocation
    } else {
      coords = `${geolocation.latitude}, ${geolocation.longitude}`
    }

    return (
      <div className="pd-form-group pd-form-group_location">
        <label htmlFor={`${id}__input`} className="pd-label">
          {ctx.t('js.address_input.coordinates')}
        </label>

        <div className="pd-form-location__input">
          <input
            id={`${id}__input`}
            className="pd-form-control"
            type="text"
            readOnly={this.props.readOnly}
            value={coords}
            onChange={this.onChangeHandle}
            onKeyPress={this.onEnterHandle}
          />

          {this.action}

          {this.errors}
        </div>
      </div>
    )
  }
}

export default withContext(LocationInput)
