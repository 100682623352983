

import * as El from "../../../../../../../../libraries/El.mjs";
import * as Fun from "../../../../../../../../libraries/Fun.mjs";
import * as Types from "../../../../../../../../types/Types.mjs";
import * as Units from "../../../../../../../../types/Units.mjs";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as WeekTimeline_ActiveDayButtonsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/active_day/components/buttons/WeekTimeline_ActiveDayButtons.module.css";

var styles = WeekTimeline_ActiveDayButtonsModuleCss;

function WeekTimeline_ActiveDayButtons(Props) {
  var sizes = Props.sizes;
  var scrollSubscriptions = Props.scrollSubscriptions;
  var scrollStep = React.useMemo((function () {
          return Units.Px.multiplyWithInt(sizes.hour, 3);
        }), [sizes.hour]);
  var onPrevClick = function (_event) {
    if (scrollSubscriptions !== undefined) {
      return scrollSubscriptions.onTriggerScroll.trigger([
                  "Left",
                  Caml_option.some(scrollStep)
                ]);
    }
    
  };
  var onNextClick = function (_event) {
    if (scrollSubscriptions !== undefined) {
      return scrollSubscriptions.onTriggerScroll.trigger([
                  "Right",
                  Caml_option.some(scrollStep)
                ]);
    }
    
  };
  var prevButton = Types.ReactRef.use();
  var nextButton = Types.ReactRef.use();
  React.useEffect((function () {
          var match = Fun.both(Types.ReactRef.toOption(prevButton), Types.ReactRef.toOption(nextButton));
          if (match === undefined) {
            return ;
          }
          var nextButton$1 = match[1];
          var prevButton$1 = match[0];
          prevButton$1.addEventListener("click", onPrevClick);
          nextButton$1.addEventListener("click", onNextClick);
          return (function () {
                    prevButton$1.removeEventListener("click", onPrevClick);
                    nextButton$1.removeEventListener("click", onNextClick);
                  });
        }), [scrollSubscriptions]);
  return React.createElement("div", {
              className: styles.container
            }, React.createElement("button", {
                  ref: prevButton,
                  className: El.Cn.concat(styles.button, styles.prev),
                  type: "button"
                }), React.createElement("button", {
                  ref: nextButton,
                  className: El.Cn.concat(styles.button, styles.next),
                  type: "button"
                }));
}

var make = WeekTimeline_ActiveDayButtons;

export {
  make ,
}
/* styles Not a pure module */
