

import IndexJsx from "planado/components/input/switch/index.jsx";

var State = {};

function Switch(funarg) {
  return {
          make: IndexJsx
        };
}

var Int = {
  make: IndexJsx
};

var Float = {
  make: IndexJsx
};

var Bool = {
  make: IndexJsx
};

var OptionalBool = {
  make: IndexJsx
};

var $$String = {
  make: IndexJsx
};

export {
  State ,
  Switch ,
  Int ,
  Float ,
  Bool ,
  OptionalBool ,
  $$String ,
}
/* Int Not a pure module */
