

import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as UI_Icon from "../../../ui/UI_Icon.mjs";
import * as UI_Button from "../../../ui/UI_Button.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Template_HeadingModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/template/Template_Heading.module.css";

var add$p = Utils.Translations.tr("js.templates.heading.add");

var edit$p = Utils.Translations.tr("js.templates.heading.edit");

var styles = Template_HeadingModuleCss;

function Template_Heading$Title(Props) {
  var name = Props.name;
  var isNewRecord = Props.isNewRecord;
  var ctx = Props.ctx;
  if (isNewRecord) {
    return React.createElement("div", {
                className: styles.title
              }, add$p(ctx));
  } else {
    return React.createElement("div", {
                className: styles.title
              }, name, React.createElement("span", {
                    className: styles.textLight
                  }, edit$p(ctx)));
  }
}

function Template_Heading(Props) {
  var name = Props.name;
  var isNewRecord = Props.isNewRecord;
  var initialName = React.useMemo((function () {
          return name;
        }), []);
  var match = AppContext.useWire();
  var navigate = match.navigate;
  var onClose = function ($$event) {
    $$event.preventDefault();
    navigate(undefined, {
          NAME: "Template",
          VAL: "Index"
        });
  };
  return React.createElement("div", {
              className: styles.formHeader
            }, React.createElement(Template_Heading$Title, {
                  name: initialName,
                  isNewRecord: isNewRecord,
                  ctx: match.ctx
                }), React.createElement(UI_Button.make, {
                  flavor: "close",
                  onClick: onClose,
                  children: React.createElement(UI_Icon.make, {
                        icon: "times"
                      })
                }));
}

var make = Template_Heading;

var $$default = Template_Heading;

export {
  make ,
  $$default as default,
}
/* add' Not a pure module */
