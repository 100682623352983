import { listToTree } from 'planado/utils/index.js'

export const sortJobs = js =>
  js.sort((a, b) => (a.lastActivityAt > b.lastActivityAt ? 1 : -1))

export const filterJobs = (jobs, predicate) => {
  return sortJobs(Object.values(jobs).filter(predicate))
}

function sortLocations(locations) {
  const swap = (i, j) => {
    const a = locations[i],
      b = locations[j]
    locations[i] = b
    locations[j] = a
  }

  for (let i = 2; i < locations.length; i++) {
    const current = locations[i],
      previous = locations[i - 1],
      prePrevious = locations[i - 2]

    if (
      current.id !== prePrevious.id &&
      current.id === previous.id &&
      current.jobUuid === prePrevious.jobUuid &&
      current.jobUuid !== previous.jobUuid
    ) {
      swap(i - 1, i)
    }
  }
}

export const unwrapLocations = locations => {
  const result = []

  locations.forEach(l => {
    if (l.jobUuids === null || l.jobUuids.length === 0) {
      result.push({
        ...l,
        jobUuid: null,
        distance: null,
        compositeId: l.uuid
      })
    } else {
      l.jobUuids.forEach((jobUuid, i) =>
        result.push({
          ...l,
          jobUuid: jobUuid,
          compositeId: `${l.uuid}-${jobUuid}`,
          distance: (l.distancesToDestination || [])[i] || null
        })
      )
    }
  })

  sortLocations(result)
  return result
}

const blockId = (no, location) => {
  return `${no}-${location.workerUuid}`
}

export const locationsToBlocks = locations => {
  const blocks = []

  for (
    let i = 0, current, previous = null, block;
    (current = locations[i++]);
    previous = current
  ) {
    if (previous === null || current.jobUuid !== previous.jobUuid) {
      block = [current]
      const id = blockId(blocks.length, current)
      blocks.push({
        id: id,
        locations: block,
        jobUuid: current.jobUuid,
        last: false
      })
    } else {
      block.push(current)
    }
  }

  if (blocks.length > 0) {
    blocks[blocks.length - 1].last = true
  }

  return blocks
}

export const getAccuracy = ({ accuracy }, measurementSystem) => {
  if (measurementSystem === 'imperial') {
    const miles = meterToMileRatio * accuracy
    const feet = miles / footToMileRatio

    if (feet <= 300) {
      return 'high'
    } else if (miles <= 1) {
      return 'moderate'
    } else {
      return 'low'
    }
  }
  if (accuracy <= 100) {
    return 'high'
  } else if (accuracy <= 1000) {
    return 'moderate'
  } else {
    return 'low'
  }
}

export const getAccuracyColor = (location, measurementSystem) => {
  switch (getAccuracy(location, measurementSystem)) {
    case 'high':
      return '#51a52e'
    case 'moderate':
      return '#ff9833'
    case 'low':
      return '#f64841'
  }
}

export const hasAddress = j =>
  j !== null && j.formattedAddress !== null && j.formattedAddress != ''

export const hasLocation = j => j !== null && j.location !== null

export const jobName = (ctx, { serialNo, templateName }) => {
  const tSerialNo = ctx.t('js.map.serial_no', { serialNo })

  if (templateName === null) {
    return tSerialNo
  } else {
    return `${tSerialNo} ${templateName}`
  }
}

export const groupLatestLocations = ls => listToTree(ls, l => l.workerUuid)

export const meterToMileRatio = 0.00062137
export const footToMileRatio = 0.000189394
