

import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as DictionaryTypes from "./DictionaryTypes.mjs";

function index(wire) {
  return Backend.get(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "Dictionary",
                VAL: "Index"
              }
            });
}

var Index = {
  index: index
};

var decode = JsonDecode.object(function (field) {
      if (field.required("isNew", JsonDecode.bool)) {
        return field.required("dictionary", DictionaryTypes.ResponseData.decode);
      }
      
    });

var Get = {
  decode: decode
};

function new_(wire) {
  return Backend.get(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "Dictionary",
                VAL: "New"
              }
            });
}

function show(wire, uuid) {
  return Backend.get(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "Dictionary",
                VAL: {
                  NAME: "Edit",
                  VAL: uuid
                }
              }
            });
}

function destroy(wire, uuid) {
  return Backend.$$delete(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "Dictionary",
                VAL: {
                  NAME: "Edit",
                  VAL: uuid
                }
              }
            });
}

var Show = {
  new_: new_,
  show: show,
  destroy: destroy
};

export {
  Index ,
  Get ,
  Show ,
}
/* decode Not a pure module */
