import React, { Component } from 'react'
import classNames from 'classnames'
import { filterBars } from 'planado/schedule/utils2/filter'

class BarLabel extends Component {
  shouldComponentUpdate = nextProps =>
    this.props.label.get('hoverStatus') !== nextProps.label.get('hoverStatus')

  componentDidMount = () =>
    this.props.label.on('change:hoverStatus', () => this.forceUpdate(), this)

  componentWillUnmount = () => this.props.label.off(null, null, this)

  get classes() {
    const { label } = this.props

    return classNames({
      'bar-label': true,
      'bar-label_icon-team': !label.unassigned() && label.isTeam(),
      'bar-label_icon-user': !label.unassigned() && !label.isTeam(),
      unassigned: label.unassigned(),
      'is-active': label.get('hoverStatus')
    })
  }

  render = () => (
    <div className={this.classes}>{this.props.label.get('name')}</div>
  )
}

export const BarLabels = ({ collection, filter }) => (
  <div className="bar-labels">
    <div className="padding" />
    {filterBars(collection, filter).map(bar => (
      <BarLabel label={bar} key={bar.id} />
    ))}
  </div>
)
