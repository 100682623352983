

import * as El from "../libraries/El.mjs";
import * as Hint from "../components/common/Hint/Hint.mjs";
import * as RCore from "../libraries/RCore.mjs";
import * as Utils from "../utils/Utils.mjs";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as UI_Icon from "./UI_Icon.mjs";
import * as Optional from "../components/common/Optional.mjs";
import * as FormInput from "../components/inputs/FormInput.mjs";
import * as UI_Button from "./UI_Button.mjs";
import * as AppContext from "../context/AppContext.mjs";
import * as Types_Contact from "../types/Types_Contact.mjs";
import * as UI_ContactListModuleCss from "/home/runner/work/planado/planado/client/rescript/ui/UI_ContactList.module.css";

var addContact$p = Utils.Translations.tr("js.contacts.list.add_contact");

var hintText$p = Utils.Translations.t("js.contacts.list.hint_text");

var primary$p = Utils.Translations.tr("js.contacts.list.labels.primary");

var contactName$p = Utils.Translations.t("js.contacts.list.labels.name");

var contactPhone$p = Utils.Translations.t("js.contacts.list.labels.phone");

var contactEmail$p = Utils.Translations.t("js.contacts.list.labels.email");

var title$p = Utils.Translations.tr("js.contacts.list.title");

var styles = UI_ContactListModuleCss;

function UI_ContactList$AddButton(Props) {
  var readOnly = Props.readOnly;
  var addContact = Props.addContact;
  var ctx = Props.ctx;
  return React.createElement(Optional.make, {
              show: !readOnly,
              children: React.createElement(UI_Button.make, {
                    flavor: "link",
                    className: styles.addButton,
                    onClick: (function (e) {
                        e.preventDefault();
                        addContact(e);
                      }),
                    children: null
                  }, React.createElement(UI_Icon.make, {
                        className: styles.iconAddModel,
                        icon: "plus"
                      }), El.space, addContact$p(ctx))
            });
}

function UI_ContactList$ContactInput(Props) {
  var contact = Props.contact;
  var onDeletePress = Props.onDeletePress;
  var editContact = Props.editContact;
  var readOnly = Props.readOnly;
  var isPrimary = Props.isPrimary;
  var isDeletable = Props.isDeletable;
  var isNameReadOnlyOpt = Props.isNameReadOnly;
  var highlightPrimaryOpt = Props.highlightPrimary;
  var ctx = Props.ctx;
  var isNameReadOnly = isNameReadOnlyOpt !== undefined ? isNameReadOnlyOpt : false;
  var highlightPrimary = highlightPrimaryOpt !== undefined ? highlightPrimaryOpt : true;
  var wrapperClassNames = El.Cn.concatIf(styles.wrapper, [
        isPrimary && highlightPrimary,
        styles.wrapperPrimary
      ]);
  var match = Types_Contact.extract(contact);
  var contactType = match[1];
  var values = match[0];
  return React.createElement("div", {
              className: wrapperClassNames
            }, React.createElement(Optional.make, {
                  show: isPrimary,
                  children: React.createElement("span", {
                        className: styles.primaryLabel
                      }, primary$p(ctx))
                }), React.createElement(FormInput.make, {
                  _type: "text",
                  value: RCore.$$Option.getOr(values.name, ""),
                  onValueChange: (function (name) {
                      editContact(Types_Contact.wrap({
                                value: values.value,
                                name: name
                              }, contactType));
                    }),
                  readOnly: readOnly || isNameReadOnly,
                  labelText: contactName$p(ctx),
                  maxLength: 50,
                  wrapperClass: Js_dict.fromList({
                        hd: [
                          styles.contactName,
                          true
                        ],
                        tl: /* [] */0
                      }),
                  inputClass: Js_dict.fromList({
                        hd: [
                          "pd-form-control",
                          true
                        ],
                        tl: {
                          hd: [
                            "contact-name",
                            true
                          ],
                          tl: /* [] */0
                        }
                      })
                }), React.createElement(FormInput.make, {
                  _type: "text",
                  value: RCore.$$Option.getOr(values.value, ""),
                  onValueChange: (function (value) {
                      editContact(value.includes("@") ? ({
                                TAG: "Email",
                                _0: {
                                  value: value,
                                  name: values.name
                                }
                              }) : ({
                                TAG: "Phone",
                                _0: {
                                  value: value,
                                  name: values.name
                                }
                              }));
                    }),
                  readOnly: readOnly,
                  labelText: contactType === "email" && RCore.$$Option.getOr(values.value, "").includes("@") ? contactEmail$p(ctx) : contactPhone$p(ctx),
                  maxLength: 50,
                  wrapperClass: Js_dict.fromList({
                        hd: [
                          "pd-form-group-equal-size",
                          true
                        ],
                        tl: /* [] */0
                      }),
                  inputClass: Js_dict.fromList({
                        hd: [
                          "pd-form-control",
                          true
                        ],
                        tl: {
                          hd: [
                            "contact-value",
                            true
                          ],
                          tl: /* [] */0
                        }
                      })
                }), isPrimary ? React.createElement("div", {
                    className: styles.hint
                  }, React.createElement(Hint.make, {
                        content: hintText$p(ctx),
                        thin: true
                      })) : React.createElement(UI_Button.make, {
                    className: El.Cn.concatIf(styles.button, [
                          !isDeletable || readOnly,
                          styles.buttonInvisible
                        ]),
                    onClick: onDeletePress,
                    children: React.createElement(UI_Icon.make, {
                          className: styles.trashIcon,
                          icon: "trash-alt"
                        })
                  }));
}

function UI_ContactList(Props) {
  var contacts = Props.contacts;
  var addContact = Props.addContact;
  var removeContact = Props.removeContact;
  var editContact = Props.editContact;
  var readOnlyOpt = Props.readOnly;
  var isFirstContactHiddenOpt = Props.isFirstContactHidden;
  var isFirstContactNameReadOnlyOpt = Props.isFirstContactNameReadOnly;
  var highlightPrimaryOpt = Props.highlightPrimary;
  var readOnly = readOnlyOpt !== undefined ? readOnlyOpt : false;
  var isFirstContactHidden = isFirstContactHiddenOpt !== undefined ? isFirstContactHiddenOpt : false;
  var isFirstContactNameReadOnly = isFirstContactNameReadOnlyOpt !== undefined ? isFirstContactNameReadOnlyOpt : false;
  var highlightPrimary = highlightPrimaryOpt !== undefined ? highlightPrimaryOpt : true;
  var ctx = AppContext.useCtx();
  var idxOffset = isFirstContactHidden ? 1 : 0;
  return React.createElement(React.Fragment, undefined, React.createElement("h2", {
                  className: styles.title
                }, title$p(ctx)), contacts.slice(idxOffset).map(function (contact, idx) {
                  var onDeletePress = function (evt) {
                    evt.preventDefault();
                    removeContact(idx + idxOffset | 0);
                  };
                  return React.createElement(UI_ContactList$ContactInput, {
                              contact: contact,
                              onDeletePress: onDeletePress,
                              editContact: (function (value) {
                                  editContact(idx + idxOffset | 0, value);
                                }),
                              readOnly: readOnly,
                              isPrimary: idx === 0 && !isFirstContactHidden,
                              isDeletable: idx > 0,
                              isNameReadOnly: idx === 0 && isFirstContactNameReadOnly,
                              highlightPrimary: highlightPrimary,
                              ctx: ctx,
                              key: (idx + idxOffset | 0).toString()
                            });
                }), React.createElement(UI_ContactList$AddButton, {
                  readOnly: readOnly,
                  addContact: addContact,
                  ctx: ctx
                }));
}

var make = UI_ContactList;

export {
  make ,
}
/* addContact' Not a pure module */
