

import * as El from "../../../../../../libraries/El.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Assignee_Types from "../../../../../../types/Assignee_Types.mjs";
import * as WeekTimeline_ActiveDayBar from "./components/bar/WeekTimeline_ActiveDayBar.mjs";
import * as WeekTimeline_ActiveDayScale from "./components/scale/WeekTimeline_ActiveDayScale.mjs";
import * as WeekTimeline_ActiveDayState from "./lib/state/WeekTimeline_ActiveDayState.mjs";
import * as WeekTimeline_ActiveDayScroll from "./components/scroll/WeekTimeline_ActiveDayScroll.mjs";
import * as WeekTimeline_ActiveDayStateTypes from "./lib/state/WeekTimeline_ActiveDayStateTypes.mjs";
import * as WeekTimeline_ActiveDayCurrentTime from "./components/current_time/WeekTimeline_ActiveDayCurrentTime.mjs";
import * as WeekTimeline_ActiveDayModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/active_day/WeekTimeline_ActiveDay.module.css";

function WeekTimeline_ActiveDay$ActiveDayComponent(Props) {
  var activeDay = Props.activeDay;
  return React.createElement(WeekTimeline_ActiveDayScroll.make, {
              activeDay: activeDay,
              children: null
            }, React.createElement(WeekTimeline_ActiveDayScale.make, {
                  activeDay: activeDay
                }), React.createElement(WeekTimeline_ActiveDayBar.make, {
                  activeDay: activeDay
                }), React.createElement(WeekTimeline_ActiveDayCurrentTime.make, {
                  activeDay: activeDay
                }));
}

var styles = WeekTimeline_ActiveDayModuleCss;

function WeekTimeline_ActiveDay(Props) {
  var jobAssignee = Props.assignee;
  var barWeekDay = Props.weekDay;
  var match = WeekTimeline_ActiveDayState.useState();
  var hiddenActiveDay = match.hiddenActiveDay;
  var activeDay = match.activeDay;
  if (activeDay !== undefined && Caml_obj.equal(Assignee_Types.Assignee.uuid(activeDay.bar), Assignee_Types.Assignee.uuid(jobAssignee)) && activeDay.weekDay === barWeekDay) {
    return React.createElement("div", {
                key: WeekTimeline_ActiveDayStateTypes.ActiveDay.key(activeDay),
                className: styles.container
              }, React.createElement(WeekTimeline_ActiveDay$ActiveDayComponent, {
                    activeDay: activeDay
                  }));
  }
  if (hiddenActiveDay !== undefined && Caml_obj.equal(Assignee_Types.Assignee.uuid(hiddenActiveDay.bar), Assignee_Types.Assignee.uuid(jobAssignee)) && hiddenActiveDay.weekDay === barWeekDay) {
    return React.createElement("div", {
                key: WeekTimeline_ActiveDayStateTypes.ActiveDay.key(hiddenActiveDay),
                className: El.Cn.concat(styles.container, styles.hidden)
              }, React.createElement(WeekTimeline_ActiveDay$ActiveDayComponent, {
                    activeDay: hiddenActiveDay
                  }));
  } else {
    return null;
  }
}

var make = WeekTimeline_ActiveDay;

export {
  make ,
}
/* styles Not a pure module */
