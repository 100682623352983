

import * as RCore from "../../../../RCore.mjs";
import * as Utils from "../../../../../utils/Utils.mjs";
import * as React from "react";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Liquid_EditorErrorsModuleCss from "/home/runner/work/planado/planado/client/rescript/libraries/liquid/editor/components/errors/Liquid_EditorErrors.module.css";

var styles = Liquid_EditorErrorsModuleCss;

var invalidSubstitutions$p = Utils.Translations.tr("js.settings.sms.add_substitution_error");

function key(error) {
  if (error === "MaxLengthError") {
    return "MaxLengthError";
  } else {
    return "InvalidSubtitutionError";
  }
}

function Liquid_EditorErrors(Props) {
  var errors = Props.errors;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  return RCore.$$Array.filterMap(errors, (function (error) {
                if (error === "MaxLengthError") {
                  return ;
                } else {
                  return Caml_option.some(React.createElement("div", {
                                  key: key(error),
                                  className: styles.error
                                }, invalidSubstitutions$p(ctx)));
                }
              }));
}

var make = Liquid_EditorErrors;

export {
  make ,
}
/* styles Not a pure module */
