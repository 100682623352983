

import * as El from "../../../../../../libraries/El.mjs";
import * as React from "react";
import * as Schedule_DND from "../../../../lib/Schedule_DND.mjs";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as WeekCalendar_Constants from "../../lib/WeekCalendar_Constants.mjs";
import * as WeekCalendar_VirtualCellModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_calendar/components/virtual_cell/WeekCalendar_VirtualCell.module.css";

var styles = WeekCalendar_VirtualCellModuleCss;

function WeekCalendar_VirtualCell(Props) {
  var scheduledAt = Props.hour;
  var dispatch = Schedule_State.useDispatch();
  var match = React.useState(function () {
        return false;
      });
  var setIsOver = match[1];
  var toggleIsOver = function () {
    setIsOver(function (prev) {
          return !prev;
        });
  };
  var onDrop = function (param) {
    setIsOver(function (param) {
          return false;
        });
    var match = Schedule_DND.DND.Context.payload(param.payload);
    dispatch({
          TAG: "Jobs",
          _0: {
            TAG: "JobScheduled",
            _0: match.uuid,
            _1: scheduledAt
          }
        });
  };
  var dropRef = Schedule_DND.DND.Droppable.use(true, toggleIsOver, toggleIsOver, [
        WeekCalendar_Constants.dndScheduledJob,
        WeekCalendar_Constants.dndUnscheduledJob,
        WeekCalendar_Constants.dndUnassignedJob
      ], onDrop, undefined);
  var className = El.Cn.concat(styles.cell, match[0] ? styles.isOver : "");
  return React.createElement("div", {
              ref: dropRef,
              className: className
            });
}

var make = React.memo(WeekCalendar_VirtualCell);

export {
  make ,
}
/* styles Not a pure module */
