

import IndexJsx from "planado/components/users/index.jsx";

var make = IndexJsx;

export {
  make ,
}
/* make Not a pure module */
