

import * as El from "../../../../libraries/El.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Checkbox from "../../../inputs/Checkbox.mjs";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserShowModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/user/UserShow.module.css";

var styles = UserShowModuleCss;

function UserPermissionCheckbox(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var label = Props.label;
  var disabledOpt = Props.disabled;
  var containerClass = Props.containerClass;
  var wrapperClassesOpt = Props.wrapperClasses;
  var inputClasses = Props.inputClasses;
  var hint = Props.hint;
  var children = Props.children;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var wrapperClasses = wrapperClassesOpt !== undefined ? wrapperClassesOpt : /* [] */0;
  var wrapper = Js_dict.fromList(Core__List.concat({
            hd: [
              "react-checkbox_users",
              true
            ],
            tl: /* [] */0
          }, wrapperClasses));
  var tmp = {
    onToggle: onChange,
    checked: value,
    label: label,
    disabled: disabled,
    wrapperClasses: wrapper
  };
  if (inputClasses !== undefined) {
    tmp.inputClasses = Caml_option.valFromOption(inputClasses);
  }
  return React.createElement("div", {
              className: styles.inputRow
            }, React.createElement("div", {
                  className: styles.inputLabel
                }, RCore.$$Option.getOr(children, null)), React.createElement("div", {
                  className: El.Cn.concat("form-input-checkbox", RCore.$$Option.getOr(containerClass, ""))
                }, React.createElement(Checkbox.make, tmp), RCore.$$Option.getOr(hint, null)));
}

var make = UserPermissionCheckbox;

export {
  make ,
}
/* styles Not a pure module */
