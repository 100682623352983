import jQuery from 'jquery'

const getScrollbarWidth = () => {
  let widthNoScroll = null
  let widthWithScroll = null
  let $outerDiv = jQuery('<div></div>')
  let $innerDiv = jQuery('<div></div>').css({ width: '100%' })

  $outerDiv
    .css({ height: '100px', msOverflowStyle: 'scrollbar', width: '100px' })
    .appendTo('body')
    .each(() => (widthNoScroll = $outerDiv.width()))
    .css({ overflow: 'scroll' })
    .append($innerDiv)
    .each(() => (widthWithScroll = $innerDiv.width()))
    .remove()

  return widthNoScroll - widthWithScroll
}

export default class ViewController {
  constructor($window, $document, $root) {
    this.scrollBarWidth = getScrollbarWidth()
    this.$window = $window
    this.$document = $document
    this.$root = $root
  }

  get viewPortWidth() {
    const bodyWidth = this.$root.width()
    const labelWidth = 170
    const sidebarWidth = 350

    return bodyWidth - labelWidth - sidebarWidth - this.scrollBarWidth
  }

  get sizes() {
    const labels = document.querySelector('.bar-labels')

    const windowHeight = this.$window.height()

    const viewableHeight =
      windowHeight -
      this.$root
        .find('#schedule')
        .css('paddingTop')
        .replace('px', '')

    const labelsHeight = labels.offsetHeight

    const sizes = {
      viewPortWidth: this.viewPortWidth,
      chartHeight: Math.max(viewableHeight + 100, labelsHeight + 100),
      chartWrapperHeight: viewableHeight,
      sidebarHeight: viewableHeight
    }

    return sizes
  }

  updateSizes = $schedule => {
    const labels = document.querySelector('.bar-labels')
    labels.removeAttribute('style')

    const sizes = this.sizes

    $schedule
      .find('.chart-wrapper')
      .css({ height: sizes.chartWrapperHeight + 'px' })
    $schedule.find('.schedule-chart').css({ height: sizes.chartHeight + 'px' })
    $schedule.find('.chart-bars').css({ height: sizes.chartHeight + 'px' })
    $schedule.find('.bar-labels').css({ height: sizes.chartHeight + 'px' })

    let sidebarHeight = this.$root.find('.schedule-sidebar').css('height')

    if (!sidebarHeight) {
      this.$root
        .find('.schedule-sidebar')
        .css({ height: sizes.sidebarHeight + 'px' })
    }
  }
}
