

import * as Fun from "../../../../../libraries/Fun.mjs";
import * as Uuid from "../../../../../bindings/uuid/Uuid.mjs";
import * as Modal from "../../../../modal/Modal.mjs";
import * as RCore from "../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../types/Types.mjs";
import * as React from "react";
import * as UI_Icon from "../../../../../ui/UI_Icon.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Carousel from "../../../../common/Carousel.mjs";
import * as DomUtils from "../../../../../DomUtils.mjs";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as JobForm_Carousel_Utils from "./JobForm_Carousel_Utils.mjs";

function use(initialImages) {
  var wire = AppContext.useWire();
  var match = React.useState(function () {
        return initialImages;
      });
  var setImages = match[1];
  var images = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setCurrentIndex = match$1[1];
  var currentIndex = match$1[0];
  React.useEffect((function () {
          var nextImages = initialImages.map(function (image) {
                var match = RCore.$$Array.getBy(images, (function (img) {
                        return Caml_obj.equal(img.uuid, image.uuid);
                      }));
                if (match !== undefined) {
                  return {
                          uuid: image.uuid,
                          url: image.url,
                          fieldName: image.fieldName,
                          fileName: image.fileName,
                          caption: image.caption,
                          rotationDeg: match.rotationDeg
                        };
                } else {
                  return image;
                }
              });
          if (Caml_obj.notequal(images, nextImages)) {
            setImages(function (param) {
                  return nextImages;
                });
          }
          
        }), [
        initialImages,
        images
      ]);
  var carouselRef = Types.ReactRef.use();
  React.useEffect((function () {
          var match = Fun.both(currentIndex, Types.ReactRef.toOption(carouselRef));
          if (match !== undefined) {
            DomUtils.focus(match[1].frame, undefined);
          }
          
        }), [
        images,
        currentIndex
      ]);
  var onClose = function () {
    setCurrentIndex(function (param) {
          
        });
  };
  var onViewChange = function (index) {
    setCurrentIndex(function (param) {
          return index;
        });
  };
  var onRotate = function (changeDeg, index) {
    setImages(function (images) {
          return images.map(function (image, idx) {
                      if (idx === index) {
                        return {
                                uuid: image.uuid,
                                url: image.url,
                                fieldName: image.fieldName,
                                fileName: image.fileName,
                                caption: image.caption,
                                rotationDeg: (image.rotationDeg + changeDeg | 0) % 360
                              };
                      } else {
                        return image;
                      }
                    });
        });
  };
  var views = React.useMemo((function () {
          return images.map(function (image) {
                      return Carousel.View.make(image.caption, image.url);
                    });
        }), [images]);
  var carousel;
  if (currentIndex !== undefined) {
    var footer = function (param) {
      var currentIndex = param.currentIndex;
      return React.createElement("div", {
                  className: "react-images-gallery-footer"
                }, React.createElement("div", {
                      className: "react-images-gallery-footer-info"
                    }, React.createElement("div", undefined, RCore.$$Option.mapWithDefault(images[currentIndex], "", (function (image) {
                                return image.caption;
                              }))), React.createElement("div", undefined, (currentIndex + 1 | 0).toString() + "/" + images.length.toString())), React.createElement("div", {
                      className: "react-images-gallery-control-panel-wrapper"
                    }, React.createElement("div", {
                          className: "react-images-gallery-control-panel"
                        }, React.createElement(UI_Icon.make, {
                              onClick: (function (param) {
                                  if (currentIndex === 0) {
                                    return ;
                                  } else {
                                    return setCurrentIndex(function (param) {
                                                return currentIndex - 1 | 0;
                                              });
                                  }
                                }),
                              className: "react-images-gallery-control-panel-icon",
                              icon: "chevron-left"
                            }), React.createElement(UI_Icon.make, {
                              onClick: (function (param) {
                                  if (currentIndex === (images.length - 1 | 0)) {
                                    return ;
                                  } else {
                                    return setCurrentIndex(function (param) {
                                                return currentIndex + 1 | 0;
                                              });
                                  }
                                }),
                              className: "react-images-gallery-control-panel-icon",
                              icon: "chevron-right"
                            }), React.createElement(UI_Icon.make, {
                              onClick: (function (param) {
                                  onRotate(-90, currentIndex);
                                }),
                              className: "react-images-gallery-control-panel-icon react-images-gallery-extra-gap",
                              icon: "arrow-rotate-left"
                            }), React.createElement(UI_Icon.make, {
                              onClick: (function (param) {
                                  onRotate(90, currentIndex);
                                }),
                              className: "react-images-gallery-control-panel-icon react-images-gallery-extra-gap",
                              icon: "arrow-rotate-right"
                            }), React.createElement(UI_Icon.make, {
                              onClick: (function (param) {
                                  setCurrentIndex(function (param) {
                                        
                                      });
                                }),
                              className: "react-images-gallery-control-panel-close-icon",
                              icon: "xmark"
                            }))), React.createElement("div", {
                      className: "react-images-gallery-footer-thumbnails"
                    }, images.map(function (image, index) {
                          return React.createElement("img", {
                                      key: Uuid.toString(image.uuid),
                                      className: index === currentIndex ? "react-images-gallery-footer-active-thumbnail" : "react-images-gallery-footer-thumbnail-image",
                                      src: image.url,
                                      onClick: (function (param) {
                                          setCurrentIndex(function (param) {
                                                return index;
                                              });
                                        })
                                    });
                        })));
    };
    carousel = React.createElement(Modal.make, {
          wire: wire,
          onClose: onClose,
          children: React.createElement(Carousel.make, {
                views: views,
                currentIndex: currentIndex,
                isModal: true,
                components: {
                  Footer: footer
                },
                frameProps: {
                  accessibility: true
                },
                modalProps: {
                  onClose: onClose
                },
                trackProps: {
                  onViewChange: onViewChange
                },
                styles: Carousel.Styles.make(RCore.$$Option.map(images[currentIndex], (function (param) {
                            return param.rotationDeg;
                          }))),
                ref: carouselRef
              })
        });
  } else {
    carousel = null;
  }
  var showCarouselImage = function (uuid) {
    var nextIndex = JobForm_Carousel_Utils.findIndexByUuid(images, uuid);
    if (Caml_obj.notequal(nextIndex, currentIndex)) {
      return setCurrentIndex(function (param) {
                  return nextIndex;
                });
    }
    
  };
  return {
          carousel: carousel,
          showCarouselImage: showCarouselImage
        };
}

export {
  use ,
}
/* Uuid Not a pure module */
