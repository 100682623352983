

import * as Url from "../../../bindings/url/Url.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function fromString(s) {
  switch (s) {
    case "disabled" :
        return "Disabled";
    case "enabled" :
        return "Enabled";
    case "locked" :
        return "Locked";
    case "suspended" :
        return "Suspended";
    default:
      return ;
  }
}

function toString(s) {
  switch (s) {
    case "Enabled" :
        return "enabled";
    case "Disabled" :
        return "disabled";
    case "Suspended" :
        return "suspended";
    case "Locked" :
        return "locked";
    
  }
}

function inverse(s) {
  switch (s) {
    case "Enabled" :
        return "Disabled";
    case "Suspended" :
        return "Suspended";
    case "Disabled" :
    case "Locked" :
        return "Enabled";
    
  }
}

var decode = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              url: field.required("url", Url.decode),
              name: field.required("name", JsonDecode.option(JsonDecode.string)),
              eventTypes: field.required("eventTypes", JsonDecode.array(JsonDecode.string)),
              status: field.required("status", JsonDecode.fromVariant(JsonDecode.string, fromString))
            };
    });

var Webhook = {
  decode: decode
};

var decode$1 = JsonDecode.object(function (field) {
      var url = field.required("url", Url.decode);
      return {
              uuid: field.required("uuid", Uuid.decode),
              url: Caml_option.some(url),
              urlInput: Url.href(url),
              secret: field.required("secret", JsonDecode.option(JsonDecode.string)),
              name: field.required("name", JsonDecode.option(JsonDecode.string)),
              eventTypes: field.required("eventTypes", JsonDecode.array(JsonDecode.string)),
              status: field.required("status", JsonDecode.fromVariant(JsonDecode.string, fromString)),
              version: field.required("version", JsonDecode.$$int),
              alternativeVersions: field.required("alternativeVersions", JsonDecode.array(JsonDecode.$$int))
            };
    });

var State = {
  decode: decode$1
};

var maxWebhooks = 10;

var Status = {
  toString: toString,
  inverse: inverse
};

export {
  maxWebhooks ,
  Status ,
  Webhook ,
  State ,
}
/* decode Not a pure module */
