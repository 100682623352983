

import * as IndexJsx from "planado/components/input/territories_select/index.jsx";

var make = IndexJsx.TerritoriesSelect;

export {
  make ,
}
/* make Not a pure module */
