

import * as Icon from "../../../../../Icon.mjs";
import * as RCore from "../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../types/Types.mjs";
import * as Utils from "../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../../../common/Optional.mjs";
import * as PromiseF from "../../../../../libraries/PromiseF.mjs";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as Utils_File from "../../../../../utils/Utils_File.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FieldErrors from "../../../../common/FieldError/FieldErrors.mjs";
import * as FileInput_Types from "../../lib/FileInput_Types.mjs";
import * as Shared_Lib_Option from "../../../../../shared/lib/Shared_Lib_Option.mjs";
import * as FileInput_UseUploader from "../../hooks/FileInput_UseUploader.mjs";
import * as Webapi__Dom__HtmlElement from "rescript-webapi/lib/es6/src/Webapi/Dom/Webapi__Dom__HtmlElement.mjs";
import * as FileInput_AddButtonModuleCss from "/home/runner/work/planado/planado/client/rescript/components/file/file_input/components/add_button/FileInput_AddButton.module.css";

var styles = FileInput_AddButtonModuleCss;

var select$p = Utils.Translations.tr("js.components.file_input.select");

function FileInput_AddButton(Props) {
  var onStartUpload = Props.onStartUpload;
  var onFinishUpload = Props.onFinishUpload;
  var sizeLimitMb = Props.sizeLimitMb;
  var onlyImagesOpt = Props.onlyImages;
  var onlyImages = onlyImagesOpt !== undefined ? onlyImagesOpt : false;
  var wire = AppContext.useWire();
  var inputRef = Types.ReactRef.use();
  var match = React.useState(function () {
        return [];
      });
  var setErrors = match[1];
  var showError = React.useCallback((function (error) {
          setErrors(function (_errors) {
                return [error];
              });
        }), []);
  var clearError = React.useCallback((function () {
          setErrors(function (_errors) {
                return [];
              });
        }), []);
  var onClick = function ($$event) {
    $$event.preventDefault();
    $$event.stopPropagation();
    var input = RCore.$$Option.flatMap(Types.ReactRef.toOption(inputRef), Webapi__Dom__HtmlElement.ofElement);
    if (input !== undefined) {
      Caml_option.valFromOption(input).click();
      return ;
    }
    
  };
  var match$1 = React.useState(function () {
        return false;
      });
  var setIsUploading = match$1[1];
  var upload = FileInput_UseUploader.use();
  var onChange = function ($$event) {
    var target = $$event.target;
    var jsFile = Shared_Lib_Option.flatten(target.files[0]);
    if (jsFile === undefined) {
      return ;
    }
    var jsFile$1 = Caml_option.valFromOption(jsFile);
    if ((jsFile$1.size | 0) > ((sizeLimitMb << 10) << 10)) {
      return showError({
                  TAG: "SizeLimitExceeded",
                  _0: sizeLimitMb
                });
    } else if (onlyImages && !Utils_File.$$Image.isImage(jsFile$1.type)) {
      return showError("NotAnImage");
    } else {
      setIsUploading(function (param) {
            return true;
          });
      onStartUpload();
      var __x = upload(jsFile$1);
      return PromiseF.$$finally((function (file) {
                    setIsUploading(function (param) {
                          return false;
                        });
                    clearError();
                    onFinishUpload({
                          TAG: "Ok",
                          _0: file
                        });
                  }), (function (error) {
                    setIsUploading(function (param) {
                          return false;
                        });
                    showError(error);
                    onFinishUpload({
                          TAG: "Error",
                          _0: error
                        });
                  }), __x);
    }
  };
  return React.createElement("div", {
              className: styles.container
            }, React.createElement("div", {
                  className: styles.buttonContainer
                }, React.createElement("input", {
                      ref: inputRef,
                      className: styles.input,
                      type: "file",
                      onChange: onChange
                    }), React.createElement("div", {
                      className: styles.icon
                    }, React.createElement("span", {
                          className: Icon.style(undefined, undefined, "upload")
                        })), React.createElement("div", {
                      className: styles.buttons
                    }, React.createElement("button", {
                          className: styles.button,
                          type: "button",
                          onClick: onClick
                        }, select$p(wire.ctx))), React.createElement(Optional.make, {
                      show: match$1[0],
                      children: React.createElement("div", {
                            className: styles.spinner
                          }, React.createElement("i", {
                                className: Icon.style("2x", true, "spinner")
                              }))
                    })), React.createElement(FieldErrors.make, {
                  customClass: styles.error,
                  errors: match[0].map(function (error) {
                        return FileInput_Types.$$Error.asText(error, wire.ctx);
                      })
                }));
}

var make = FileInput_AddButton;

export {
  make ,
}
/* styles Not a pure module */
