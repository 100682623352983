

import * as Uuid from "../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../libraries/RCore.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Utils_IsImage from "../../../../../utils/Utils_IsImage.mjs";

function extractCarouselImages(fieldName, attachments) {
  return RCore.$$Array.filterMap(attachments, (function (attachment) {
                var url = attachment.url;
                var fileName = attachment.name;
                if (url !== undefined && Utils_IsImage.isImage(fileName)) {
                  return {
                          uuid: attachment.uuid,
                          url: url,
                          fieldName: fieldName,
                          fileName: fileName,
                          caption: fieldName + " " + fileName,
                          rotationDeg: 0
                        };
                }
                
              }));
}

function removeDuplicates(attachments) {
  return RCore.$$Array.reduce(attachments, [], (function (acc, file) {
                if (acc.some(function (persisted) {
                        return Uuid.equal(persisted.uuid, file.uuid);
                      })) {
                  return acc;
                } else {
                  return Belt_Array.concatMany([
                              acc,
                              [file]
                            ]);
                }
              }));
}

function findIndexByUuid(images, uuid) {
  var index = images.findIndex(function (image) {
        return Caml_obj.equal(image.uuid, uuid);
      });
  if (index === -1) {
    return ;
  } else {
    return index;
  }
}

export {
  extractCarouselImages ,
  removeDuplicates ,
  findIndexByUuid ,
}
/* Uuid Not a pure module */
