import React from 'react'
import classNames from 'classnames'
import { showTime } from 'planado/utils/time/index.js'
import { locateJob, showLocations } from 'planado/map_commands'
import { hasLocation, jobName } from 'planado/utils/map'
import LocationItem from './location_item'
import JobAddress from './job_address'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import * as styles from './styles.module.css'

const blockInterval = (block, ctx) => {
  if (block.last) {
    const after = showTime(block.locations[0].effectiveAt, 'time', ctx)

    return ctx.t('js.map.locations.after', { time: after })
  } else {
    const dates = [
      block.locations[0].effectiveAt,
      block.locations[block.locations.length - 1].effectiveAt,
    ]

    return dates.map((d) => showTime(d, 'time', ctx)).join('—')
  }
}

const DistanceDescription = withContext(({ job, ctx }) => {
  if (job === null || job.addressUuid === null) {
    return <span />
  } else {
    return <span>{ctx.t('js.map.locations.distance')}</span>
  }
})

const Header = withContext(({ ctx, job, onClick }) => {
  if (job === null) {
    return <div />
  } else {
    return (
      <div className={styles.jobHeader} onClick={onClick}>
        <span>{jobName(ctx, job)}</span>
      </div>
    )
  }
})

const Subheader = withContext(({ job, block, onClick, ctx }) => {
  const text = `${blockInterval(block, ctx)} — ${ctx.t('js.map.locations.qty', {
    count: block.locations.length,
  })}`

  const clickable = job === null

  const classes = classNames({
    [styles.subheader]: true,
    [styles.clickable]: clickable,
  })

  return (
    <div className={classes} onClick={clickable ? onClick : null}>
      {text}
    </div>
  )
})

const LocationsList = withContext(
  ({
    job,
    open,
    locations,
    currentLocationId,
    engineChannel,
    onLocationClick,
    ctx,
  }) => {
    if (open) {
      const locs = locations.map((l) => {
        return (
          <LocationItem
            location={l}
            key={l.compositeId}
            showDistance={job !== null && job.addressUuid !== null}
            active={l.compositeId === currentLocationId}
            engineChannel={engineChannel}
            onClick={onLocationClick}
          />
        )
      })

      return (
        <ul className={styles.locations}>
          {locs}
          <li className={styles.locationDescription}>
            <DistanceDescription job={job} />
            &nbsp;
            <span className={styles.accuracy}>
              {ctx.t('js.map.locations.accuracy')}
            </span>
          </li>
        </ul>
      )
    } else {
      return <div />
    }
  }
)

const Toggler = ({ open, onClick }) => {
  const togglerClass = classNames({
    fa: true,
    'fa-chevron-right': !open,
    'fa-chevron-down': open,
    [styles.locationsToggler]: true,
  })

  return <div className={togglerClass} onClick={onClick} />
}

const BlockHeader = ({ onToggle, open, job, block, onJobLocateClick }) => {
  return (
    <div className={styles.headerBar}>
      <Header job={job} onClick={onToggle} />
      <JobAddress job={job} show={open} onClick={onJobLocateClick} />
      <Subheader job={job} block={block} onClick={onToggle} />
      <Toggler open={open} onClick={onToggle} />
    </div>
  )
}

class LocationBlock extends React.Component {
  render() {
    const blockClass = classNames({
      [styles.locationBlock]: true,
      [styles.open]: this.props.open,
    })

    const {
      job,
      open,
      block,
      onLocationToggle,
      currentLocationId,
      engineChannel,
    } = this.props

    return (
      <li className={blockClass}>
        <BlockHeader
          job={job}
          open={open}
          block={block}
          onToggle={this.onBlockToggle}
          onJobLocateClick={this.locateJob}
        />
        <LocationsList
          job={job}
          open={open}
          locations={block.locations}
          currentLocationId={currentLocationId}
          onLocationClick={onLocationToggle}
          engineChannel={engineChannel}
        />
      </li>
    )
  }

  // Engine interaction

  componentDidMount() {
    this.focusOnBlock(true)
  }

  componentDidUpdate({ open }) {
    if (!open && this.props.open) {
      this.focusOnBlock(false)
    }
  }

  locateJob = (evt) => {
    evt.stopPropagation()

    if (hasLocation(this.props.job)) {
      this.props.engineChannel.push(locateJob(this.props.job))
    }
  }

  onBlockToggle = () => {
    const { onBlockToggle, block } = this.props
    onBlockToggle(block)
  }

  focusOnBlock(initial) {
    if (this.props.open) {
      this.props.engineChannel.push(
        showLocations(this.props.block, this.props.job, initial)
      )
    }
  }
}

export default LocationBlock
