

import * as El from "../libraries/El.mjs";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UI_LabelModuleCss from "/home/runner/work/planado/planado/client/rescript/ui/UI_Label.module.css";

var styles = UI_LabelModuleCss;

function UI_Label(Props) {
  var htmlFor = Props.htmlFor;
  var className = Props.className;
  var children = Props.children;
  var tmp = {
    className: El.Cn.concatOpt(styles.label, className)
  };
  if (htmlFor !== undefined) {
    tmp.htmlFor = Caml_option.valFromOption(htmlFor);
  }
  return React.createElement("label", tmp, children);
}

var make = UI_Label;

export {
  make ,
}
/* styles Not a pure module */
