

import * as El from "../../../libraries/El.mjs";
import * as Title from "../../common/Title.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as UserAPI from "./UserAPI.mjs";
import * as UserShow from "./UserShow.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserShowState from "./UserShowState.mjs";
import * as MessengerTypes from "../../messenger/MessengerTypes.mjs";

var userCreated$p = Utils.Translations.t("js.admin.users.messages.user_created");

var userUpdated$p = Utils.Translations.t("js.admin.users.messages.user_updated");

var userDeleted$p = Utils.Translations.t("js.admin.users.messages.user_deleted");

function UserEdit(Props) {
  var wire = Props.wire;
  var mode = Props.mode;
  var navigate = wire.navigate;
  var messenger = wire.subscriptions.messenger;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        
      });
  var setUserName = match[1];
  var userName = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setUserData = match$1[1];
  var userData = match$1[0];
  var match$2 = React.useState(function () {
        return [];
      });
  var setErrors = match$2[1];
  var errors = match$2[0];
  var uuid = typeof mode === "object" ? Caml_option.some(mode.VAL) : undefined;
  var loadedShow = function (data) {
    if (data.TAG === "Ok") {
      var data$1 = data._0;
      setUserData(function (param) {
            return {
                    TAG: "Show",
                    _0: data$1
                  };
          });
      return setUserName(function (param) {
                  return {
                          firstName: data$1.firstName,
                          lastName: data$1.lastName
                        };
                });
    }
    var error = data._0;
    if (typeof error !== "object") {
      console.log(error);
      return ;
    }
    if (error.TAG === "HTTPError") {
      var tmp = error._0[0];
      if (typeof tmp !== "object") {
        if (tmp === "NotFound") {
          return navigate(undefined, {
                      NAME: "User",
                      VAL: "Index"
                    });
        }
        console.log(error);
        return ;
      } else {
        console.log(error);
        return ;
      }
    } else {
      console.log(error);
      return ;
    }
  };
  var loadedNew = function (data) {
    if (data.TAG === "Ok") {
      var data$1 = data._0;
      return setUserData(function (param) {
                  return {
                          TAG: "New",
                          _0: data$1
                        };
                });
    }
    var error = data._0;
    if (typeof error !== "object") {
      console.log(error);
      return ;
    }
    if (error.TAG === "HTTPError") {
      var tmp = error._0[0];
      if (typeof tmp !== "object") {
        if (tmp === "NotFound") {
          return navigate(undefined, {
                      NAME: "User",
                      VAL: "Index"
                    });
        }
        console.log(error);
        return ;
      } else {
        console.log(error);
        return ;
      }
    } else {
      console.log(error);
      return ;
    }
  };
  var showMessage = function (messageType, text) {
    messenger.trigger({
          TAG: "Show",
          _0: MessengerTypes.Message.make(undefined, messageType, {
                TAG: "Text",
                _0: text
              })
        });
  };
  var destroyed = function (data) {
    if (data.TAG === "Ok") {
      showMessage("Success", userDeleted$p(ctx));
      return navigate(undefined, {
                  NAME: "User",
                  VAL: "Index"
                });
    }
    var error = data._0;
    if (typeof error !== "object") {
      console.log(error);
      return ;
    }
    if (error.TAG === "HTTPError") {
      var tmp = error._0[0];
      if (typeof tmp !== "object") {
        if (tmp === "NotFound") {
          return navigate(undefined, {
                      NAME: "User",
                      VAL: "Index"
                    });
        }
        console.log(error);
        return ;
      } else {
        console.log(error);
        return ;
      }
    } else {
      console.log(error);
      return ;
    }
  };
  var onDelete = function ($$event) {
    $$event.preventDefault();
    if (uuid !== undefined) {
      return Backend.$$finally(UserAPI.Edit.destroy(wire, Caml_option.valFromOption(uuid)), destroyed);
    }
    
  };
  var onSubmit = function (res) {
    if (res.TAG === "Ok") {
      setErrors(function (param) {
            return [];
          });
      var msg = typeof mode === "object" ? userUpdated$p(ctx) : userCreated$p(ctx);
      showMessage("Success", msg);
      navigate(undefined, {
            NAME: "User",
            VAL: "Index"
          });
      return wire.reloadContext();
    }
    var error = res._0;
    if (typeof error !== "object") {
      console.log(error);
      return ;
    }
    if (error.TAG === "HTTPError") {
      var match = error._0;
      var tmp = match[0];
      if (typeof tmp !== "object") {
        if (tmp === "UnprocessableEntity") {
          var text = match[1];
          return setErrors(function (param) {
                      return UserAPI.Errors.decode(text);
                    });
        }
        console.log(error);
        return ;
      } else {
        console.log(error);
        return ;
      }
    } else {
      console.log(error);
      return ;
    }
  };
  var onShowSubmit = function (data) {
    if (uuid !== undefined) {
      return Backend.$$finally(UserAPI.Edit.submitShow(wire, Caml_option.valFromOption(uuid), data), onSubmit);
    }
    
  };
  var onNewSubmit = function (data) {
    Backend.$$finally(UserAPI.Edit.submitNew(wire, data), onSubmit);
  };
  React.useEffect((function () {
          if (uuid !== undefined) {
            Backend.$$finally(UserAPI.Edit.show(wire, Caml_option.valFromOption(uuid)), loadedShow);
          } else {
            Backend.$$finally(UserAPI.Edit.new_(wire), loadedNew);
          }
        }), []);
  var title = userName !== undefined ? React.createElement(Title.make, {
          route: wire.route,
          ctx: ctx,
          values: {
            name: El.Cn.concat(userName.firstName, userName.lastName)
          },
          wire: wire
        }) : null;
  if (userData === undefined) {
    return null;
  }
  var tmp;
  if (userData.TAG === "Show") {
    var data = userData._0;
    tmp = React.createElement(UserShow.make, {
          isNew: false,
          owner: data.owner,
          tenantHasSeats: data.tenantHasSeats,
          availableTerritories: data.availableTerritories,
          timezoneVariants: data.timezoneVariants,
          teams: data.teams,
          userData: UserShowState.fromShow(data),
          onDelete: onDelete,
          onSubmit: onShowSubmit,
          errors: errors
        });
  } else {
    var data$1 = userData._0;
    tmp = React.createElement(UserShow.make, {
          isNew: true,
          owner: false,
          tenantHasSeats: data$1.tenantHasSeats,
          availableTerritories: data$1.availableTerritories,
          timezoneVariants: data$1.timezoneVariants,
          teams: [],
          userData: UserShowState.fromNew(data$1),
          onDelete: onDelete,
          onSubmit: onNewSubmit,
          errors: errors
        });
  }
  return React.createElement(React.Fragment, undefined, title, React.createElement("div", {
                  id: "content-wrapper"
                }, React.createElement("div", {
                      className: "page-container narrow"
                    }, tmp)));
}

var make = UserEdit;

export {
  make ,
}
/* userCreated' Not a pure module */
