

import * as PromiseF from "./PromiseF.mjs";
import * as File_reader from "planado/utils/file_reader";

function base64Content(file) {
  return PromiseF.fromJs(File_reader.readFileInBase64(file));
}

export {
  base64Content ,
}
/* planado/utils/file_reader Not a pure module */
