

import * as Fun from "../../../../../../../../libraries/Fun.mjs";
import * as RCore from "../../../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../../../types/Types.mjs";
import * as Units from "../../../../../../../../types/Units.mjs";
import * as React from "react";
import * as Locale from "../../../../../../../../libraries/Locale.mjs";
import * as AppContext from "../../../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_State from "../../../../../../lib/state/Schedule_State.mjs";
import * as Webapi__Dom__HtmlElement from "rescript-webapi/lib/es6/src/Webapi/Dom/Webapi__Dom__HtmlElement.mjs";
import * as WeekTimeline_ActiveDayCurrentTimeModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/active_day/components/current_time/WeekTimeline_ActiveDayCurrentTime.module.css";

var styles = WeekTimeline_ActiveDayCurrentTimeModuleCss;

function positionLeft(wire, param) {
  var now = Locale.T.now(wire);
  if (!Locale.T.equalDay(now, param.period[0])) {
    return ;
  }
  var minutes = Locale.T.dateToMinutes(wire, now);
  return Caml_option.some(Units.Px.multiplyWithInt(param.sizes.minute, minutes));
}

function use(activeDay) {
  var wire = AppContext.useWire();
  var locked = Schedule_State.useLocked();
  var elementRef = Types.ReactRef.use();
  var setStyle = function () {
    requestAnimationFrame(function (_time) {
          var element = Types.ReactRef.toOption(elementRef);
          var htmlElement = RCore.$$Option.flatMap(element, Webapi__Dom__HtmlElement.ofElement);
          var match = Fun.both(element, htmlElement);
          if (match === undefined) {
            return ;
          }
          var element$1 = match[0];
          var left = positionLeft(wire, activeDay);
          if (left !== undefined) {
            match[1].style.setProperty("left", Units.Px.toString(Caml_option.valFromOption(left)));
            element$1.classList.add(styles.visible);
          } else {
            element$1.classList.remove(styles.visible);
          }
        });
  };
  React.useEffect((function () {
          setStyle();
        }), [
        activeDay.sizes,
        activeDay.period
      ]);
  React.useEffect((function () {
          if (locked) {
            return ;
          } else {
            return wire.subscriptions.timer.subscribe(function (_now) {
                        setStyle();
                      });
          }
        }), [
        locked,
        activeDay.sizes,
        activeDay.period
      ]);
  return elementRef;
}

export {
  use ,
}
/* styles Not a pure module */
