import React from 'react'

import {
  addWorkerJob,
  removeWorkerJob,
  locateWorkerJob
} from 'planado/map_commands'
import { showTime } from 'planado/utils/time/index.js'
import { jobName, hasLocation } from 'planado/utils/map'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import * as styles from './styles.module.css'

const Stats = withContext(
  ({
    status,
    scheduledAt,
    enRouteAt,
    startedAt,
    suspendedAt,
    finishedAt,
    ctx
  }) => {
    switch (status) {
      case 'published': {
        const scheduled = ctx.t('js.map.worker_jobs.scheduled', {
          time: showTime(new Date(scheduledAt), 'time', ctx)
        })

        return (
          <div className={styles.jobStats}>
            <span>{scheduled}</span>
          </div>
        )
      }
      case 'en_route': {
        const enRoute = ctx.t('js.map.worker_jobs.en_route', {
          time: showTime(new Date(enRouteAt), 'time', ctx)
        })
        const scheduled = ctx.t('js.map.worker_jobs.scheduled', {
          time: showTime(new Date(scheduledAt), 'time', ctx)
        })

        return (
          <div className={styles.jobStats}>
            <span>{enRoute}</span>
            <span>{scheduled}</span>
          </div>
        )
      }
      case 'started': {
        const started = ctx.t('js.map.worker_jobs.started', {
          time: showTime(new Date(startedAt), 'time', ctx)
        })
        const scheduled = ctx.t('js.map.worker_jobs.scheduled', {
          time: showTime(new Date(scheduledAt), 'time', ctx)
        })

        return (
          <div className={styles.jobStats}>
            <span>{started}</span>
            <span>{scheduled}</span>
          </div>
        )
      }
      case 'suspended': {
        const started = ctx.t('js.map.worker_jobs.started', {
          time: showTime(new Date(startedAt), 'time', ctx)
        })
        const suspended = ctx.t('js.map.worker_jobs.suspended', {
          time: showTime(new Date(suspendedAt), 'time', ctx)
        })

        return (
          <div className={styles.jobStats}>
            <span>{started}</span>
            <span>{suspended}</span>
          </div>
        )
      }
      case 'finished': {
        const started = ctx.t('js.map.worker_jobs.started', {
          time: showTime(new Date(startedAt), 'time', ctx)
        })
        const finished = ctx.t('js.map.worker_jobs.finished', {
          time: showTime(new Date(finishedAt), 'time', ctx)
        })

        return (
          <div className={styles.jobStats}>
            <span>{started}</span>
            <span>{finished}</span>
          </div>
        )
      }
      default:
        return <div />
    }
  }
)

class WorkerJobItem extends React.Component {
  constructor(props) {
    super(props)

    this.locateJob = this.locateJob.bind(this)
    this.onClick = () => props.onClick(props.job)
  }

  get locateButton() {
    if (hasLocation(this.props.job)) {
      return (
        <div className={styles.locateJob} onClick={this.locateJob}>
          <i className="fa fa-crosshairs" />
        </div>
      )
    } else {
      return <div />
    }
  }

  render() {
    const { ctx, job, focused } = this.props
    return (
      <li className={focused ? styles.active : ''}>
        <div className={styles.jobTitle} onClick={this.onClick}>
          {this.locateButton}
          <div onClick={this.locateJob}>{jobName(ctx, job)}</div>
          <Stats {...job} />
        </div>
      </li>
    )
  }

  // Engine interaction

  componentDidMount() {
    this.send(this.props, addWorkerJob)
  }

  componentWillUnmount() {
    this.send(this.props, removeWorkerJob)
  }

  componentDidUpdate(_prevProps) {
    this.send(this.props, removeWorkerJob)
    this.send(this.props, addWorkerJob)
  }

  locateJob() {
    if (hasLocation(this.props.job)) {
      this.props.engineChannel.push(locateWorkerJob(this.props.job))
    }
  }

  send({ engineChannel, job }, action) {
    if (hasLocation(job)) {
      engineChannel.push(action(job))
    }
  }
}

export default withContext(WorkerJobItem)
