

import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as JobIndexFilter_Reset from "../../common/JobIndexFilter_Reset.mjs";
import * as JobIndexFilter_Wrapper from "../../common/JobIndexFilter_Wrapper.mjs";
import * as JobIndexFilter_ClientSelectLink from "../../common/JobIndexFilter_ClientSelectLink.mjs";

var label$p = Utils.Translations.t("js.jobs.index.filters.clients");

function JobIndexFilter_Clients(Props) {
  var filterByClient = Props.filterByClient;
  var resetFilter = Props.resetFilter;
  var toggleFilter = Props.toggleFilter;
  var show = Props.show;
  var currentFilter = Props.currentFilter;
  var filterDisabled = Props.filterDisabled;
  var match = AppContext.useWire();
  var onResetClick = function (param) {
    resetFilter();
    toggleFilter({
            TAG: "System",
            _0: "Client"
          })(false);
  };
  var match$1 = currentFilter !== undefined ? [
      React.createElement(JobIndexFilter_Reset.make, {
            onResetClick: onResetClick
          }),
      React.createElement("div", {
            className: "j-filter__selected"
          }, currentFilter.name)
    ] : [
      null,
      null
    ];
  var value = currentFilter !== undefined ? ({
        label: currentFilter.name,
        value: currentFilter.uuid,
        type: "client",
        data: null,
        address: null
      }) : null;
  return React.createElement(JobIndexFilter_Wrapper.make, {
              toggleFilter: toggleFilter({
                    TAG: "System",
                    _0: "Client"
                  }),
              show: show,
              label: label$p(match.ctx),
              children: null
            }, match$1[0], React.createElement("div", {
                  className: "j-filter__list"
                }, React.createElement("div", {
                      className: "j-scheduled"
                    }, React.createElement(JobIndexFilter_ClientSelectLink.make, {
                          id: "clients-filter",
                          minimumResultsForSearch: 0,
                          allowClear: false,
                          type_: "site_and_client",
                          readOnly: filterDisabled,
                          value: value,
                          onValueChange: (function (address, param) {
                              if (address !== null) {
                                return filterByClient({
                                            uuid: address.value,
                                            name: address.label,
                                            type_: address.type
                                          });
                              } else {
                                return resetFilter();
                              }
                            })
                        }))), match$1[1]);
}

var make = JobIndexFilter_Clients;

export {
  make ,
}
/* label' Not a pure module */
