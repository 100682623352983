

import * as El from "../../../../../../../../../libraries/El.mjs";
import * as Fun from "../../../../../../../../../libraries/Fun.mjs";
import * as RCore from "../../../../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../../../../types/Types.mjs";
import * as Units from "../../../../../../../../../types/Units.mjs";
import * as Utils from "../../../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as AppContext from "../../../../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_DND from "../../../../../../../lib/Schedule_DND.mjs";
import * as Assignee_Types from "../../../../../../../../../types/Assignee_Types.mjs";
import * as Schedule_State from "../../../../../../../lib/state/Schedule_State.mjs";
import * as Schedule_Popover from "../../../../../../../components/popovers/popover/Schedule_Popover.mjs";
import * as Schedule_JobGroup from "../../../../../../../components/popovers/job_group/Schedule_JobGroup.mjs";
import * as WeekTimeline_State from "../../../../../lib/state/WeekTimeline_State.mjs";
import * as WeekTimeline_BarJobGroupsUtils from "./WeekTimeline_BarJobGroupsUtils.mjs";
import * as WeekTimeline_BarJobGroupsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/timeline/bar/components/job_groups/WeekTimeline_BarJobGroups.module.css";

var styles = WeekTimeline_BarJobGroupsModuleCss;

var dots$p = Utils.Translations.tr("js.schedule.v2.job.dots");

function WeekTimeline_BarJobGroups$Group(Props) {
  var bar = Props.bar;
  var param = Props.group;
  var finish = param.finish;
  var start = param.start;
  var match = AppContext.useWire();
  var currentDay = Schedule_State.useCurrentDay();
  var elementRef = Types.ReactRef.use();
  var match$1 = React.useState(function () {
        
      });
  var setClickCoordinate = match$1[1];
  var clickCoordinate = match$1[0];
  var closePreview = function () {
    setClickCoordinate(function (param) {
          
        });
  };
  var togglePreview = function ($$event) {
    if (!RCore.$$Option.isNone(clickCoordinate)) {
      return setClickCoordinate(function (param) {
                  
                });
    }
    var coordinate_0 = Units.Px.fromInt($$event.pageX);
    var coordinate_1 = Units.Px.fromInt($$event.pageY);
    var coordinate = [
      coordinate_0,
      coordinate_1
    ];
    setClickCoordinate(function (param) {
          return coordinate;
        });
  };
  var match$2 = React.useState(function () {
        return false;
      });
  var setTransparent = match$2[1];
  var setTransapent = function () {
    setTransparent(function (param) {
          return true;
        });
  };
  var match$3 = Schedule_DND.DND.Context.use();
  var dndSubscriptions = match$3.subscriptions;
  var timelineSubscriptions = WeekTimeline_State.useTimelineSubscriptions();
  React.useEffect((function () {
          var unsubscribeFromScrollStart = timelineSubscriptions.onScrollStart.subscribe(function (param) {
                setClickCoordinate(function (param) {
                      
                    });
              });
          var unsubscribeFromDragStart = dndSubscriptions.onDragStart.subscribe(setTransapent);
          var unsubscribeFromDragEnd = dndSubscriptions.onDragEnd.subscribe(function () {
                setClickCoordinate(function (param) {
                      
                    });
                setTransparent(function (param) {
                      return false;
                    });
              });
          return (function () {
                    unsubscribeFromScrollStart();
                    unsubscribeFromDragStart();
                    unsubscribeFromDragEnd();
                  });
        }), []);
  React.useEffect((function () {
          var element = Types.ReactRef.toOption(elementRef);
          if (element !== undefined) {
            Caml_option.valFromOption(element).setAttribute("data-prevent-scroll", "true");
          }
          
        }), []);
  var match$4 = Fun.both(clickCoordinate, Types.ReactRef.toOption(elementRef));
  var popoverBlock;
  if (match$4 !== undefined) {
    var clickTargetPosition_1 = match$4[1].getBoundingClientRect();
    var clickTargetPosition = {
      NAME: "DomRect",
      VAL: clickTargetPosition_1
    };
    popoverBlock = React.createElement(Schedule_Popover.make, {
          transparent: match$2[0],
          clickCoordinate: match$4[0],
          clickTargetPosition: clickTargetPosition,
          children: React.createElement(Schedule_JobGroup.make, {
                assigneeUuid: Assignee_Types.Assignee.uuid(bar),
                currentDay: currentDay,
                jobs: param.jobs,
                close: closePreview
              })
        });
  } else {
    popoverBlock = null;
  }
  var isCompact = Units.Px.lessThan(Units.Px.minus(finish, start), Units.Px.fromInt(30));
  var isHidden = Units.Px.lessThan(Units.Px.minus(finish, start), Units.Px.fromInt(14));
  var style = {
    left: Units.Px.toString(start),
    width: Units.Px.toString(Units.Px.minus(finish, start))
  };
  var tmp;
  switch (param.status) {
    case "Posted" :
        tmp = styles.posted;
        break;
    case "EnRoute" :
        tmp = styles.enRoute;
        break;
    case "Started" :
        tmp = styles.started;
        break;
    case "Suspended" :
        tmp = styles.suspended;
        break;
    case "FinishedSuccessful" :
        tmp = styles.finishedSuccessful;
        break;
    case "FinishedUnsuccessful" :
        tmp = styles.finishedUnsuccessful;
        break;
    
  }
  var className = El.Cn.concat(El.Cn.concat(styles.container, RCore.$$Option.isSome(clickCoordinate) ? styles.active : ""), tmp);
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  ref: elementRef,
                  className: className,
                  style: style,
                  onClick: togglePreview
                }, React.createElement("div", {
                      className: El.Cn.concat(El.Cn.concat(styles.inner, isCompact ? styles.compact : ""), isHidden ? styles.hidden : "")
                    }, dots$p(match.ctx))), popoverBlock);
}

function WeekTimeline_BarJobGroups(Props) {
  var bar = Props.bar;
  var jobs = Props.jobs;
  return RCore.$$Array.filterMap(WeekTimeline_BarJobGroupsUtils.makeGroups(RCore.$$Array.keep(jobs, (function (param) {
                        return param[0].hidden.contents;
                      }))), (function (group) {
                if (group.jobs.length === 1) {
                  return ;
                } else {
                  return Caml_option.some(React.createElement(WeekTimeline_BarJobGroups$Group, {
                                  bar: bar,
                                  group: group,
                                  key: group.index.toString()
                                }));
                }
              }));
}

var make = React.memo(WeekTimeline_BarJobGroups);

export {
  make ,
}
/* styles Not a pure module */
