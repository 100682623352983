import React from 'react'
import { GoogleAddressField } from 'planado/components/address_input'
import AddressInput_RegularAddress from 'rscrpt/components/address_input/regular_address/AddressInput_RegularAddress.mjs'
import FormInput from 'planado/components/input/form_input'
import { TerritoriesSelect } from 'planado/components/input/territories_select'
import Hint from 'planado/components/hint'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import { hasAssigneeWithRequiredTerritory } from 'planado/utils/possible_assignees'
import * as AddressInput_Types from 'rscrpt/components/address_input/AddressInput_Types.mjs'
import * as styles from './styles.module.css'

const dadataAddress = address => address === null
  ? AddressInput_Types.RegularAddress.makeFromString('')
  : address

const AddressBlock = withContext(
  ({
    address,
    setApartment,
    setFloor,
    setEntranceNo,
    setTerritory,
    setAddressDescription,
    setAddress,
    inputId,
    job,
    availableTerritories,
    possibleAssignees,
    wire,
    ctx,
    readOnly,
    constraints
  }) => {
    const {
      client, apartment, floor, entranceNo, addressDescription,
      territoryUuid, status
    } = job;

    let addressInput

    switch (ctx.addressSourceOptions) {
      case 'dadata':
        addressInput = (
          <AddressInput_RegularAddress
            readOnly={readOnly}
            wire={wire}
            initialAddress={dadataAddress(address)}
            onChange={setAddress}
            constraints={constraints}
          />
        )
        break
      case 'google':
      default:
        addressInput = (
          <GoogleAddressField
            id={inputId('format')}
            inputName="job[address_attributes]"
            wire={wire}
            readOnly={readOnly}
            onChange={setAddress}
            attributes={address}
            constraints={constraints}
          />
        )
    }

    let apartmentLabelText

    if (client !== null && client !== undefined && client.organization) {
      apartmentLabelText = ctx.t('js.jobs.labels.apartment_organization')
    } else if (
      (client !== null && client !== undefined) &&
      !client.organization &&
      (client.id || client.isNewRecord)
    ) {
      apartmentLabelText = ctx.t('js.jobs.labels.apartment_individual')
    } else {
      apartmentLabelText = ctx.t('js.jobs.labels.apartment_none')
    }

    const hasPerfectAssignee = hasAssigneeWithRequiredTerritory(
      possibleAssignees,
      territoryUuid,
      availableTerritories
    )

    const isFinished = status === 'finished'

    return (
      <div className="client-address-block pd-form-group-container__huge_padding">
        {addressInput}

        <div className="pd-form-group-container">
          <FormInput
            id={inputId('apartment')}
            name="job[apartment]"
            type="text"
            value={apartment || ''}
            wrapperClass={{ 'job-apartment': true, 'pd-form-group-sm': true }}
            labelText={apartmentLabelText}
            onValueChange={newValue => setApartment(newValue)}
            maxLength={50}
            readOnly={readOnly}
          />

          <FormInput
            id={inputId('floor')}
            name="job[floor]"
            type="text"
            value={floor || ''}
            wrapperClass={{ 'job-floor': true, 'pd-form-group-sm': true }}
            labelText={ctx.t('js.jobs.labels.floor')}
            onValueChange={newValue => setFloor(newValue)}
            maxLength={50}
            readOnly={readOnly}
          />

          <FormInput
            id={inputId('entrance_no')}
            name="job[entrance_no]"
            type="text"
            value={entranceNo || ''}
            wrapperClass={{ 'job-entrance_no': true, 'pd-form-group-sm': true }}
            labelText={ctx.t('js.sites.show.labels.entrance_no')}
            onValueChange={newValue => setEntranceNo(newValue)}
            maxLength={50}
            readOnly={readOnly}
          />
        </div>

        {ctx.features.flags.includes('territories') && (
          <div className="pd-form-group">
            <label className="pd-label" htmlFor={inputId('territory')}>
              {ctx.t('js.jobs.labels.territory')}
            </label>
            <Hint content={ctx.t('js.jobs.hints.territories')} />
            <TerritoriesSelect
              id={inputId('territory')}
              inputName="job[territory_uuid]"
              selectedTerritoryUuid={territoryUuid || ''}
              availableTerritories={availableTerritories}
              onChange={value => setTerritory(value)}
              placeholder={ctx.t(
                'js.components.territories_select.placeholder'
              )}
              disabled={readOnly}
            />
            {!isFinished && !hasPerfectAssignee && territoryUuid !== null && (
              <span className={styles.message}>
                {ctx.t('js.jobs.comments.no_assignees_with_territory')}
              </span>
            )}
          </div>
        )}

        <FormInput
          id={inputId('address_description')}
          name="job[address_description]"
          type="textarea"
          value={addressDescription || ''}
          wrapperClass={{ 'job-address_description': true }}
          labelText={ctx.t('js.sites.show.labels.address_description')}
          onValueChange={newValue =>
            setAddressDescription(newValue)
          }
          maxLength={500}
          readOnly={readOnly}
        />
      </div>
    )
  }
)

export default AddressBlock
