// extracted by mini-css-extract-plugin
export var addressButton = "address-button-vsDhIYkRJzjV0EqzPvin map-button-YDLwP0eFdcsPGxaYPuF1";
export var centerButtonGoogle = "center-button-google-ptwttPVeFYjWsdPj2AV5 map-button-YDLwP0eFdcsPGxaYPuF1";
export var centerButtonYandex = "center-button-yandex-OiwlBl4chCVIykD__tcs map-button-YDLwP0eFdcsPGxaYPuF1";
export var closeButton = "close-button-GrxBaX7KTtLIBbmgVbIn map-button-YDLwP0eFdcsPGxaYPuF1";
export var closeButtonDummy = "close-button-dummy-J2fWlZQTHVu9oF5lpQ1b map-button-YDLwP0eFdcsPGxaYPuF1";
export var date = "date-JEISBWiK9vRoUiP2MXhT";
export var dateClickable = "date-clickable-EiT_Lx2KSS84tKXMrcku date-JEISBWiK9vRoUiP2MXhT";
export var eventsCondition = "events-condition-o9RbUsT4GAiEKrP0DDow";
export var map = "map-nmbuImCK5DzrnHcGoZCb";
export var mapButton = "map-button-YDLwP0eFdcsPGxaYPuF1";
export var mapContainer = "map-container-sodlb7cUZC7RRLaibKl2";
export var mapDummyContainer = "map-dummy-container-glP0RjKqgrwYzlgVXBvB";
export var noMovements = "no-movements-_IJPbae2WReKFk2XB1cQ";