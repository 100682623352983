

import * as Types from "../../../../../types/Types.mjs";
import * as Utils from "../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Spinner from "../../../../common/Spinner/Spinner.mjs";
import * as Tooltip from "../../../../common/Tooltip/Tooltip.mjs";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as Schedule_State from "../../../lib/state/Schedule_State.mjs";
import * as Schedule_HeaderWarningModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/header/warning/Schedule_HeaderWarning.module.css";

var styles = Schedule_HeaderWarningModuleCss;

var warning$p = Utils.Translations.tx("js.schedule.v2.warning");

var jobs$p = Utils.Translations.txh("js.schedule.v2.jobs_count");

function Schedule_HeaderWarning(Props) {
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var loading = Schedule_State.useScheduledJobsLoading();
  var warning = Schedule_State.useScheduledJobsWarning();
  var warningRef = Types.ReactRef.use();
  if (loading) {
    return React.createElement(Spinner.make, {});
  }
  if (typeof warning !== "object") {
    return null;
  }
  var limit = warning._0;
  return React.createElement(Tooltip.make, {
              elementRef: warningRef,
              position: "bottom",
              wrapperClassName: styles.tooltip,
              content: warning$p({
                    count: limit
                  }, ctx),
              children: React.createElement("div", {
                    ref: warningRef,
                    className: styles.jobsCount
                  }, React.createElement("div", {
                        className: styles.icon
                      }, "⚠️"), React.createElement("div", {
                        className: styles.text
                      }, "> ", jobs$p({
                            count: limit
                          }, ctx)))
            });
}

var make = Schedule_HeaderWarning;

export {
  make ,
}
/* styles Not a pure module */
