import { now } from 'planado/utils/time/index.js'
import { setScheduleFilter } from 'planado/storage'
import jQuery from 'jquery'

export const setFilter = (ctx, controller, filter) => {
  const savedAt = now(ctx)

  setScheduleFilter(JSON.stringify({ filter, savedAt }))
  controller.updateSizes(jQuery('#schedule'))

  return { type: 'SET_FILTER', filter, savedAt }
}
