// extracted by mini-css-extract-plugin
export var action = "action-PHUWV3wHSm04EeyDTXkv";
export var attachmentField = "attachmentField-z5eU_YzzatAvunwZFoDI";
export var barcode = "barcode-bR9wr27XqVOkRl61EjpA";
export var button = "button-RcgPuPF4q0R6v4OMXtgj";
export var buttonField = "buttonField-jroMfRWOv_S4sfLm8yfQ";
export var checkbox = "checkbox-phenmzJHeRNhGz1UOUgi";
export var currency = "currency-I3LTultBrkCJxXV2zpS9";
export var currencyField = "currencyField-IQaAWS9eftVh3zXXK3ym";
export var currencyFractionCheckbox = "currencyFractionCheckbox-AU_lVIpkDwKsxu65j2_Y";
export var date = "date-Er5Po3UFbtrh3JPcIU4a";
export var dateField = "dateField-jrN4vVH_iHf_Ed_as7p_";
export var datetime = "datetime-wdkvOsLxgyxqQrph_vXR";
export var datetimeField = "datetimeField-ypKgt9CCbyo5TED4h74I";
export var decimal = "decimal-efx1UUxkg7isADIaM0N6";
export var decimalField = "decimalField-O19t5Q7ImRPGUpKFWojr";
export var dictionary = "dictionary-S3izI0tRm5byAVhfegWr";
export var duration = "duration-_4t33b9OuZoggAeDhJVB";
export var durationField = "durationField-NaYIVcWcFa1xu3xLdoXf";
export var fieldsLimit = "fieldsLimit-c8IrylT_EWZrNgX4n5Nh";
export var file = "file-uvZhoPiodIlUVywp3Hw0";
export var image = "image-jXTTQSeCLWP6tQaAbNYx";
export var input = "input-jXjRd_8oF3LWC0bwFw6E";
export var integer = "integer-xDx9T1bTYDltLhlUSK4W";
export var integerField = "integerField-OT1uf73NJhCBLxeUz_eN";
export var link = "link-LqTULlGSmEPXGocsHIdw";
export var linkField = "linkField-njgQHhTXCq2foilMAC4g";
export var location = "location-a_kRLs_Pzeftd1OvQZdE";
export var locationField = "locationField-eK8C88e8UYt0hkf6GbCQ";
export var signature = "signature-BMfOhXRUQyqTCZvtTRAf";
export var signatureField = "signatureField-flwfJNkXJDOORX7yEyYO";
export var time = "time-Gsfk6MMVksSRF3TDfjvu";
export var timeField = "timeField-FlrCMLZCYU9uMIeuVv51";
export var useGalleryCheckbox = "useGalleryCheckbox-aYnTt0fLqf591J9vsE0O";