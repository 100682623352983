

import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as $$Number from "../../libraries/Number.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as FormInput from "./FormInput.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var decimalFormat$p = Utils.Translations.t("js.components.decimal_input.format");

var invalidNumber$p = Utils.Translations.t("js.components.decimal_input.invalid_number");

function canBeDecimal(str) {
  var regex = /^-?[\d\s.,]{0,41}$/;
  return regex.test(str);
}

function DecimalInput(Props) {
  var id = Props.id;
  var name = Props.name;
  var value = Props.value;
  var onValueChange = Props.onValueChange;
  var disabled = Props.disabled;
  var labelText = Props.labelText;
  var wire = Props.wire;
  var errors = Props.errors;
  var wrapperClass = Props.wrapperClass;
  var ctx = wire.ctx;
  var formatNumber = function (number) {
    return $$Number.format(ctx.localizator.numbers.delimiter, ctx.localizator.numbers.separator, undefined, number);
  };
  var match = React.useState(function () {
        return formatNumber(value);
      });
  var setDisplayedValue = match[1];
  var displayedValue = match[0];
  var match$1 = React.useState(function () {
        return [];
      });
  var setErrors = match$1[1];
  var onChange = function (value) {
    if (canBeDecimal(value)) {
      return setDisplayedValue(function (param) {
                  return value;
                });
    }
    
  };
  var onBlur = function (_event) {
    var mbValidDecimal = $$Number.Decimal.fromString(displayedValue, wire.ctx);
    if (mbValidDecimal.TAG === "Ok") {
      var decimalString = mbValidDecimal._0;
      var formatted = formatNumber(decimalString);
      setErrors(function (param) {
            return [];
          });
      setDisplayedValue(function (param) {
            return formatted;
          });
      return onValueChange(decimalString);
    }
    switch (mbValidDecimal._0) {
      case "EmptyString" :
          setErrors(function (param) {
                return [];
              });
          setDisplayedValue(function (param) {
                return "";
              });
          return onValueChange("");
      case "ParseNumberStringError" :
          setErrors(function (param) {
                return [invalidNumber$p(wire.ctx)];
              });
          return onValueChange(displayedValue);
      case "DoesNotMatchDecimalRegex" :
          setErrors(function (param) {
                return [decimalFormat$p(wire.ctx)];
              });
          return onValueChange(displayedValue);
      
    }
  };
  var wrapperClass$1 = RCore.$$Option.map(wrapperClass, (function (cn) {
          return Js_dict.fromList({
                      hd: [
                        cn,
                        true
                      ],
                      tl: /* [] */0
                    });
        }));
  var tmp = {
    _type: "text",
    value: displayedValue,
    onValueChange: onChange,
    onBlur: onBlur,
    id: id,
    disabled: disabled,
    maxLength: 41,
    errors: Belt_Array.concatMany([
          RCore.$$Option.getOr(errors, []),
          match$1[0]
        ])
  };
  if (name !== undefined) {
    tmp.name = name;
  }
  if (labelText !== undefined) {
    tmp.labelText = labelText;
  }
  if (wrapperClass$1 !== undefined) {
    tmp.wrapperClass = Caml_option.valFromOption(wrapperClass$1);
  }
  return React.createElement(FormInput.make, tmp);
}

var make = DecimalInput;

var decimalInput = DecimalInput;

export {
  make ,
  decimalInput ,
}
/* decimalFormat' Not a pure module */
