

import * as Backend from "../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var load = JsonDecode.custom(function (json) {
      return {
              activated: json.activated,
              subdomain: json.subdomain,
              password: json.password,
              ownerEmail: Caml_option.nullable_to_opt(json.ownerEmail),
              redirect: json.redirect
            };
    });

function show(wire) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: "Login"
                }), load);
}

var $$Request = {};

function create(wire, email, password) {
  var req = {
    email: email,
    password: password
  };
  return Backend.decode(Backend.post(undefined, undefined, false, undefined, wire, {
                  NAME: "API",
                  VAL: "Login"
                }, {
                  NAME: "Obj",
                  VAL: req
                }), load);
}

var Post = {
  $$Request: $$Request,
  create: create
};

function getCaptchaApi() {
  return window.grecaptcha;
}

function Captcha_reset(prim) {
  prim.reset();
}

var Captcha = {
  reset: Captcha_reset,
  getCaptchaApi: getCaptchaApi
};

export {
  show ,
  Post ,
  Captcha ,
}
/* load Not a pure module */
