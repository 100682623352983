import { connect } from 'react-redux'
import Table from 'planado/components/admin/dictionaries/index/table'
import Header from 'planado/components/admin/dictionaries/index/header'

const TableC = {
  mapStateToProps: (state, _ownProps) => ({
    dictionaries: state.dictionaries
  }),
  mapDispatchToProps: (_dispatch, _ownProps) => ({})
}

export const TableLink = connect(
  TableC.mapStateToProps,
  TableC.mapDispatchToProps
)(Table)

const HeaderC = {
  mapStateToProps: (_state, _ownProps) => ({}),
  mapDispatchToProps: (_dispatch, _ownProps) => ({})
}

export const HeaderLink = connect(
  HeaderC.mapStateToProps,
  HeaderC.mapDispatchToProps
)(Header)
