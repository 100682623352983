export const str2geolocation = str => {
  str = str
    .trim()
    .replace(/\s+/g, ' ')
    .replace(/[,|.]\s*$/, '')
  const matchedSpaces = str.match(/[ ]/g)

  let separator
  if (matchedSpaces !== null) {
    separator = ' '

    if (str.charAt(str.indexOf(' ') - 1) === ',') {
      separator = ', '
    }
  } else {
    separator = ','
  }

  const coords = str
    .split(separator)
    .map(str => str.replace(',', '.'))
    .map(Number)

  if (
    coords.length === 2 &&
    coords.every(num => num >= -180 && num <= 180) &&
    coords.some(num => num >= -90 && num <= 90)
  ) {
    if (coords[0] >= -90 && coords[0] <= 90) {
      return {
        latitude: coords[0],
        longitude: coords[1]
      }
    } else {
      return {
        latitude: coords[1],
        longitude: coords[0]
      }
    }
  } else {
    return null
  }
}

export const isGeolocation = obj =>
  obj !== null && typeof obj === 'object' && obj.latitude && obj.longitude
    ? true
    : false

export const latlongRegex = str =>
  /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/.test(
    str
  )
