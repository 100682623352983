

import * as Utils from "../../../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../../../../../../libraries/Locale.mjs";
import * as AppContext from "../../../../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Types_Territory from "../../../../../../../../../types/Types_Territory.mjs";
import * as Schedule_Types_Job from "../../../../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as Schedule_Types_JobCore from "../../../../../../../lib/types/job/Schedule_Types_JobCore.mjs";
import * as WeekTimeline_UnassignedJobContentModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/unassigned/components/job/content/WeekTimeline_UnassignedJobContent.module.css";

var styles = WeekTimeline_UnassignedJobContentModuleCss;

var headline$p = Utils.Translations.tr("js.schedule.v2.job.headline");

function WeekTimeline_UnassignedJobContent$Template(Props) {
  var job = Props.job;
  var match = AppContext.useWire();
  var match$1 = Schedule_Types_Job.UnassignedJob.template(job);
  return React.createElement("div", {
              className: styles.template
            }, match$1 !== undefined ? match$1.name : headline$p(match.ctx));
}

function WeekTimeline_UnassignedJobContent$ScheduledAt(Props) {
  var job = Props.job;
  var wire = AppContext.useWire();
  var scheduledAt = Schedule_Types_Job.UnassignedJob.scheduledAt(job);
  if (scheduledAt === undefined) {
    return null;
  }
  var scheduledAt$1 = Caml_option.valFromOption(scheduledAt);
  var scheduledFinishAt = Locale.T.nextMinutes(Schedule_Types_Job.UnassignedJob.duration(job), scheduledAt$1);
  var interval;
  if (Locale.T.equalDay(scheduledAt$1, scheduledFinishAt)) {
    var a = Locale.T.fmtM("Time", wire)(scheduledAt$1);
    var b = Locale.T.fmtM("Time", wire)(scheduledFinishAt);
    interval = a + "–" + b;
  } else {
    var a$1 = Locale.T.fmtM("DateTimeNoYear", wire)(scheduledAt$1);
    var b$1 = Locale.T.fmtM("DateTimeNoYear", wire)(scheduledFinishAt);
    interval = a$1 + " – " + b$1;
  }
  return React.createElement("div", {
              className: styles.date
            }, interval);
}

function WeekTimeline_UnassignedJobContent$Address(Props) {
  var job = Props.job;
  var address = Schedule_Types_Job.UnassignedJob.address(job);
  var territory = Schedule_Types_Job.UnassignedJob.territory(job);
  if (address !== undefined) {
    var address$1 = Caml_option.valFromOption(address);
    if (territory === undefined) {
      return React.createElement("div", {
                  className: styles.address
                }, Schedule_Types_JobCore.Address.toString(address$1));
    }
    var address$2 = Schedule_Types_JobCore.Address.toString(address$1);
    var territoryName = Types_Territory.name(territory);
    return React.createElement("div", {
                className: styles.address
              }, address$2 + " (" + territoryName + ")");
  }
  if (territory === undefined) {
    return null;
  }
  var territoryName$1 = Types_Territory.name(territory);
  return React.createElement("div", {
              className: styles.address
            }, territoryName$1);
}

function WeekTimeline_UnassignedJobContent(Props) {
  var job = Props.job;
  return React.createElement(React.Fragment, undefined, React.createElement(WeekTimeline_UnassignedJobContent$Template, {
                  job: job
                }), React.createElement("div", {
                  className: styles.info
                }, React.createElement(WeekTimeline_UnassignedJobContent$ScheduledAt, {
                      job: job
                    }), React.createElement(WeekTimeline_UnassignedJobContent$Address, {
                      job: job
                    })));
}

var make = React.memo(WeekTimeline_UnassignedJobContent);

export {
  make ,
}
/* styles Not a pure module */
