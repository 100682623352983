

import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as React from "react";
import * as JobForm_Carousel from "../../hooks/JobForm_Carousel.mjs";
import * as JobForm_CustomField from "./JobForm_CustomField.mjs";
import * as JobForm_Carousel_Utils from "../../hooks/JobForm_Carousel_Utils.mjs";

function JobForm_CustomFields(Props) {
  var wire = Props.wire;
  var customFields = Props.customFields;
  var disabled = Props.disabled;
  var addFile = Props.addFile;
  var removeFile = Props.removeFile;
  var setLock = Props.setLock;
  var jobUuid = Props.jobUuid;
  var updateCustomField = Props.updateCustomField;
  var customFieldsErrors = Props.customFieldsErrors;
  var carouselImages = JobForm_Carousel_Utils.removeDuplicates(customFields.flatMap(function (field) {
            var attachments = field.value;
            if (attachments.TAG === "File") {
              return JobForm_Carousel_Utils.extractCarouselImages(field.name, attachments._0);
            } else {
              return [];
            }
          }));
  var match = JobForm_Carousel.use(carouselImages);
  var showCarouselImage = match.showCarouselImage;
  return React.createElement("div", undefined, customFields.map(function (field, index) {
                  return React.createElement(JobForm_CustomField.make, {
                              wire: wire,
                              disabled: disabled(field),
                              field: field,
                              addFile: addFile,
                              removeFile: removeFile,
                              updateCustomField: updateCustomField,
                              jobUuid: jobUuid,
                              setLock: setLock,
                              errors: RCore.$$Option.getOr(customFieldsErrors[index], []),
                              showCarouselImage: showCarouselImage,
                              key: "custom-field-" + index.toString()
                            });
                }), match.carousel);
}

var make = React.memo(JobForm_CustomFields);

var $$default = make;

export {
  make ,
  $$default as default,
}
/* make Not a pure module */
