

import * as Fun from "../Fun.mjs";
import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as Hooks from "../hooks/Hooks.mjs";
import * as RCore from "../RCore.mjs";
import * as Units from "../../types/Units.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as DND_Utils from "./DND_Utils.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DND_Constants from "./DND_Constants.mjs";
import * as Webapi2_Dom_TouchList from "../webapi/dom/Webapi2_Dom_TouchList.mjs";

function Make(Context) {
  var minDragOffset = Units.Px.fromInt(3);
  var use = function (elementRef, canDragOpt, payload, payloadType, param) {
    var canDrag = canDragOpt !== undefined ? canDragOpt : true;
    var elementRef$1 = React.useMemo((function () {
            if (elementRef !== undefined) {
              return elementRef;
            } else {
              return React.createRef();
            }
          }), []);
    var match = Context.use();
    var dropHandlers = match.dropHandlers;
    var item = match.item;
    var subscriptions = match.subscriptions;
    var match$1 = React.useState(function () {
          return false;
        });
    var setIsDragging = match$1[1];
    var isDragging = match$1[0];
    var dragStartCoordinate = React.useRef(undefined);
    var lastDragTouchCoordinate = React.useRef(undefined);
    var elementCursorShift = React.useRef(undefined);
    var startDrag = function (pageX, pageY, param) {
      dragStartCoordinate.current = [
        pageX,
        pageY
      ];
      elementCursorShift.current = [
        Units.Px.minus(pageX, param[1]),
        Units.Px.minus(pageY, param[0])
      ];
    };
    var onMouseDragStart = function ($$event) {
      var nodeClientOffset = DND_Utils.Offset.nodeClientOffset($$event.target);
      if (nodeClientOffset !== undefined && $$event.button !== 2) {
        $$event.preventDefault();
        return startDrag(Units.Px.fromInt($$event.pageX), Units.Px.fromInt($$event.pageY), nodeClientOffset);
      }
      
    };
    var onTouchDragStart = function ($$event) {
      var touchOpt = Webapi2_Dom_TouchList.item($$event.touches, 0);
      var nodeClientOffsetOpt = RCore.$$Option.flatMap(touchOpt, (function (touch) {
              return DND_Utils.Offset.nodeClientOffset(Webapi2_Dom_TouchList.$$Touch.target(touch));
            }));
      var match = Fun.both(touchOpt, nodeClientOffsetOpt);
      if (match === undefined) {
        return ;
      }
      var touch = match[0];
      startDrag(Webapi2_Dom_TouchList.$$Touch.pageX(touch), Webapi2_Dom_TouchList.$$Touch.pageY(touch), match[1]);
    };
    var confirmDragStart = function (pageX, pageY) {
      var match = Fun.both(dragStartCoordinate.current, elementCursorShift.current);
      if (match === undefined) {
        return ;
      }
      var shift = match[1];
      var match$1 = match[0];
      var moveX = Units.Px.minus(pageX, match$1[0]);
      var moveY = Units.Px.minus(pageY, match$1[1]);
      if (Caml_obj.greaterthan(Units.Px.abs(moveX), minDragOffset) || Caml_obj.greaterthan(Units.Px.abs(moveY), minDragOffset)) {
        dragStartCoordinate.current = undefined;
        elementCursorShift.current = undefined;
        item.contents = {
          payload: payload,
          payloadType: payloadType,
          elementCursorShift: shift,
          dragPreviewOffset: DND_Utils.Offset.dragPreviewOffset(pageX, pageY, shift),
          mouseOffset: [
            pageX,
            pageY
          ]
        };
        subscriptions.onDragStart.trigger();
        return setIsDragging(function (param) {
                    return true;
                  });
      }
      
    };
    var onConfirmMouseDragStart = function ($$event) {
      confirmDragStart(Units.Px.fromInt($$event.pageX), Units.Px.fromInt($$event.pageY));
    };
    var onConfirmTouchDragStart = function ($$event) {
      var touch = Webapi2_Dom_TouchList.item($$event.touches, 0);
      if (touch === undefined) {
        return ;
      }
      var touch$1 = Caml_option.valFromOption(touch);
      confirmDragStart(Webapi2_Dom_TouchList.$$Touch.pageX(touch$1), Webapi2_Dom_TouchList.$$Touch.pageY(touch$1));
    };
    var cancelDragStart = function () {
      dragStartCoordinate.current = undefined;
      elementCursorShift.current = undefined;
    };
    var onCancelMouseDragStart = function (_event) {
      cancelDragStart();
    };
    var onCancelTouchDragStart = function (_event) {
      cancelDragStart();
    };
    var drag = function (pageX, pageY) {
      var prevItem = item.contents;
      if (prevItem === undefined) {
        return ;
      }
      var offset = DND_Utils.Offset.dragPreviewOffset(pageX, pageY, prevItem.elementCursorShift);
      if (Caml_obj.notequal(prevItem.dragPreviewOffset, offset)) {
        item.contents = {
          payload: prevItem.payload,
          payloadType: prevItem.payloadType,
          elementCursorShift: prevItem.elementCursorShift,
          dragPreviewOffset: offset,
          mouseOffset: [
            pageX,
            pageY
          ]
        };
        lastDragTouchCoordinate.current = offset;
        return subscriptions.onDrag.trigger();
      }
      
    };
    var onMouseDrag = function ($$event) {
      drag(Units.Px.fromInt($$event.pageX), Units.Px.fromInt($$event.pageY));
    };
    var onTouchDrag = function ($$event) {
      $$event.preventDefault();
      var touch = Webapi2_Dom_TouchList.item($$event.touches, 0);
      if (touch === undefined) {
        return ;
      }
      var touch$1 = Caml_option.valFromOption(touch);
      drag(Webapi2_Dom_TouchList.$$Touch.pageX(touch$1), Webapi2_Dom_TouchList.$$Touch.pageY(touch$1));
    };
    var dragEnd = function (pageX, pageY) {
      var currentItem = item.contents;
      if (currentItem !== undefined) {
        item.contents = undefined;
        lastDragTouchCoordinate.current = undefined;
        var element = document.elementFromPoint(Units.Px.toInt(pageX), Units.Px.toInt(pageY));
        var element$1 = (element == null) ? undefined : Caml_option.some(element);
        var droppableUuid = RCore.$$Option.map(RCore.$$Option.flatMap(element$1, (function (__x) {
                    return Caml_option.nullable_to_opt(__x.getAttribute(DND_Constants.dataDroppableUuid));
                  })), Uuid.fromString);
        if (droppableUuid !== undefined) {
          var droppableUuid$1 = Caml_option.valFromOption(droppableUuid);
          dropHandlers.contents.forEach(function (param) {
                if (Caml_obj.equal(param.uuid, droppableUuid$1)) {
                  return param.handler(currentItem);
                }
                
              });
        } else {
          var droppableUuid$2 = RCore.$$Option.map(RCore.$$Option.flatMap(RCore.$$Option.flatMap(element$1, (function (__x) {
                          return Caml_option.nullable_to_opt(__x.closest("[" + DND_Constants.dataDroppableUuid + "]"));
                        })), (function (__x) {
                      return Caml_option.nullable_to_opt(__x.getAttribute(DND_Constants.dataDroppableUuid));
                    })), Uuid.fromString);
          if (droppableUuid$2 !== undefined) {
            var droppableUuid$3 = Caml_option.valFromOption(droppableUuid$2);
            dropHandlers.contents.forEach(function (param) {
                  if (Caml_obj.equal(param.uuid, droppableUuid$3)) {
                    return param.handler(currentItem);
                  }
                  
                });
          }
          
        }
      }
      subscriptions.onDragEnd.trigger();
      setIsDragging(function (param) {
            return false;
          });
    };
    var onMouseDragEnd = function ($$event) {
      dragEnd(Units.Px.fromInt($$event.pageX), Units.Px.fromInt($$event.pageY));
    };
    var onTouchDragEnd = function ($$event) {
      $$event.preventDefault();
      var match = lastDragTouchCoordinate.current;
      if (match !== undefined) {
        return dragEnd(match[0], match[1]);
      }
      
    };
    var onEscPress = function () {
      item.contents = undefined;
      subscriptions.onDragEnd.trigger();
      setIsDragging(function (param) {
            return false;
          });
      cancelDragStart();
    };
    React.useEffect((function () {
            var element = elementRef$1.current;
            if (element == null) {
              return ;
            }
            if (!(!isDragging && canDrag)) {
              return ;
            }
            var $$window$1 = window;
            element.addEventListener("mousedown", onMouseDragStart);
            $$window$1.addEventListener("mousemove", onConfirmMouseDragStart);
            $$window$1.addEventListener("mouseup", onCancelMouseDragStart);
            element.addEventListener("touchstart", onTouchDragStart);
            $$window$1.addEventListener("touchend", onCancelTouchDragStart);
            $$window$1.addEventListener("touchmove", onConfirmTouchDragStart, {
                  capture: true,
                  once: false,
                  passive: false
                });
            return (function () {
                      element.removeEventListener("mousedown", onMouseDragStart);
                      $$window$1.removeEventListener("mousemove", onConfirmMouseDragStart);
                      $$window$1.removeEventListener("mouseup", onCancelMouseDragStart);
                      element.removeEventListener("touchstart", onTouchDragStart);
                      $$window$1.removeEventListener("touchend", onCancelTouchDragStart);
                      $$window$1.addEventListener("touchmove", onConfirmTouchDragStart, {
                            capture: true,
                            once: false,
                            passive: false
                          });
                    });
          }), [
          elementRef$1,
          isDragging,
          canDrag,
          payload
        ]);
    React.useEffect((function () {
            if (!isDragging) {
              return ;
            }
            var $$window$1 = window;
            $$window$1.addEventListener("mousemove", onMouseDrag);
            $$window$1.addEventListener("mouseup", onMouseDragEnd);
            $$window$1.addEventListener("touchend", onTouchDragEnd);
            $$window$1.addEventListener("touchmove", onTouchDrag, {
                  capture: true,
                  once: false,
                  passive: false
                });
            return (function () {
                      $$window$1.removeEventListener("mousemove", onMouseDrag);
                      $$window$1.removeEventListener("mouseup", onMouseDragEnd);
                      $$window$1.removeEventListener("touchend", onTouchDragEnd);
                      $$window$1.removeEventListener("touchmove", onTouchDrag, {
                            capture: true,
                            passive: false
                          });
                    });
          }), [isDragging]);
    Hooks.useOnEscPress(!isDragging, onEscPress);
    return [
            elementRef$1,
            isDragging
          ];
  };
  return {
          minDragOffset: minDragOffset,
          use: use
        };
}

export {
  Make ,
}
/* Uuid Not a pure module */
