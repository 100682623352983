import React from 'react'
import classNames from 'classnames'
import { dictionariesPath } from 'planado/routes.js'
import FormInput from 'planado/components/input/form_input'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import Link from 'rscrpt/components/common/Link/Link.mjs'

import { Make as makeSortableList } from 'rscrpt/ui/UI_SortableList.mjs'

const { make: SortableList, Item: { make: SortableListItem }, Activator: { make: SortableListActivator } } = makeSortableList({
  getId: dictionary => dictionary.uuid,
})

const MAX_LENGTH = 200
const MAX_FIELDS = 200

const Field = withContext(
  ({
    ctx,
    uuid,
    idx,
    isNew = false,
    name,
    visible,
    valuesLength,
    validate,
    actions,
    errors = [],
  }) => {
    const onValueChange = (value) => {
      actions.changeValueName(idx, value)

      if (validate) {
        if (value.trim().length > 0) {
          actions.addValueError(idx, [])
        } else {
          actions.addValueError(idx, [
            ctx.t('js.admin.dictionaries.error.value'),
          ])
        }
      }
    }

    const classes = classNames({
      'dict-form__field': true,
      [`dict-form__field_${idx}`]: true,
      'dict-form__field_value': true,
      hidden: !visible,
    })

    return (
      <SortableListItem id={uuid} hasActivator={true}>
        <div className={classes}>
          <input
            name={'dictionary[values][][uuid]'}
            value={uuid || ''}
            type="hidden"
          />

          <input
            name={'dictionary[values][][visible]'}
            value={visible}
            type="hidden"
          />

          <input
            name={'dictionary[values][][order_no]'}
            value={idx}
            type="hidden"
          />

          <SortableListActivator id={uuid}>
            <div className="dict-form__field-drag" />
          </SortableListActivator>

          <FormInput
            id={`dictionary-value-${idx}`}
            name={'dictionary[values][][name]'}
            type="text"
            value={name || ''}
            wrapperClass={{
              'dict-form__field-input': true,
              'pd-form-group-sm': true,
            }}
            onValueChange={(v) => onValueChange(v)}
            maxLength={MAX_LENGTH}
            errors={errors}
          />

          <button
            className="dict-form__field-remove"
            onClick={() => actions.removeValue(idx, isNew)}
            disabled={valuesLength === 1}
            type="button"
          >
            <span className="fa fa-trash-alt" />
          </button>
        </div>
      </SortableListItem>
    )
  }
)

const FieldList = ({ values, validate, onDragEnd, actions }) => {
  return (
    <div>
      <SortableList items={values} onDragEnd={onDragEnd}>
        {
          values.map((v, i) => (
            <Field
              {...v}
              valuesLength={values.filter((v) => v.visible).length}
              validate={validate}
              actions={actions}
              key={v.uuid}
              idx={i}
            />
          ))
        }
      </SortableList>
    </div >
  )
}

const Form = withContext(
  ({
    dictionary: { uuid, name, values, errors = [] },
    actions: {
      changeDictionaryName,
      addValue,
      reorderValues,
      addNameError,
      removable,
      disableSubmitButton,
      sendForm,
      ...actions
    },
    isNew,
    validate,
    formSubmitted,
    deleteDictionary,
    onSubmitDictionary,
    ctx,
  }) => {
    const buttonId = 'dictionaries-show-remove-button'

    const initialValuesSize = React.useMemo(() => values.length, [])
    let newVisibleValuesSize = values.filter(v => v.visible).length
    const hasValuesLimit = !ctx.features.flags.includes('noDictionaryValuesLimit')

    const validateForm = (evt) => {
      evt.preventDefault()

      let hasError = false

      if (name.trim().length === 0) {
        addNameError([ctx.t('js.admin.dictionaries.error.name')])
        hasError = true
      }

      if (
        newVisibleValuesSize > MAX_FIELDS &&
        hasValuesLimit && (
          isNew ||
          initialValuesSize < MAX_FIELDS ||
          initialValuesSize < newVisibleValuesSize
        )
      ) {
        actions.addValueError(
          values.length - 1,
          [ctx.t('js.admin.dictionaries.error.too_many_items')]
        )
        hasError = true
      }

      let withTrimmedNames = values.map(v => { return { ...v, name: v.name.trim() } })

      withTrimmedNames.forEach((v, idx) => {
        if (v.visible && withTrimmedNames
          .slice(0, idx)
          .filter(t => t.visible)
          .map(t => t.name)
          .includes(v.name)
        ) {
          actions.addValueError(
            idx,
            [ctx.t('js.admin.dictionaries.error.value_already_exists')]
          )
          hasError = true
        }

        if (v.name.length === 0 && v.visible) {
          actions.addValueError(
            idx,
            [ctx.t('js.admin.dictionaries.error.value')]
          )
          hasError = true
        }
      })

      if (!hasError && !formSubmitted) {
        const dictValues = values.map((v, i) => ({ ...v, uuid: v.isNew ? null : v.uuid, orderNo: i }))
        const payload = { dictionary: { uuid, name, values: dictValues } }

        sendForm(payload, isNew, ctx, uuid, onSubmitDictionary)
        disableSubmitButton()
      }
    }

    const onNameChange = (value) => {
      changeDictionaryName(value)

      if (validate) {
        if (value.trim().length > 0) {
          addNameError([])
        } else {
          addNameError([ctx.t('js.admin.dictionaries.error.name')])
        }
      }
    }

    return (
      <div className="dict-form">
        <header className="dict-form__header">
          <div className="dict-form__header-text">
            {isNew ? (
              <b>{ctx.t('js.admin.dictionaries.new')}</b>
            ) : (
              <span>
                <b>{ctx.t('js.admin.dictionaries.dictionary')}</b> —{' '}
                {ctx.t('js.admin.dictionaries.edit')}
              </span>
            )}
          </div>

          <Link
            wire={ctx.wire}
            className="dict-form__header-close"
            pathname={dictionariesPath}
          >
            <span className="fa fa-times" />
          </Link>
        </header>

        <form noValidate="novalidate">
          <div className="dict-form__section">
            <div className="dict-form__section-content">
              <div className="dict-form__field">
                <FormInput
                  id="dictionary[name]"
                  name="dictionary[name]"
                  type="text"
                  value={name || ''}
                  wrapperClass={{
                    'dict-form__field-input': true,
                    'pd-form-group-sm': true,
                  }}
                  labelText={ctx.t('js.admin.dictionaries.dictionary_name')}
                  onValueChange={(v) => onNameChange(v)}
                  maxLength={MAX_LENGTH}
                  autoFocus
                  errors={errors}
                />
              </div>
            </div>
          </div>

          <div className="dict-form__section">
            <div className="dict-form__section-content">
              <div className="dict-form__section-hl">
                {ctx.t('js.admin.dictionaries.values')}
              </div>

              <FieldList
                values={values}
                actions={actions}
                onDragEnd={(values) => reorderValues(values)}
                validate={validate}
              />

              {(newVisibleValuesSize < MAX_FIELDS || !hasValuesLimit) &&
                <div className="dict-form__field">
                  <button
                    onClick={() => addValue()}
                    className="dict-form__add"
                    type="button"
                  >
                    <i className="fa fa-plus" />
                    {` ${ctx.t('js.admin.dictionaries.add_value')}`}
                  </button>
                </div>
              }
            </div>
          </div>

          <div className="dict-form__footer">
            {isNew ? null : (
              <button
                className="btn btn-32px btn-danger"
                type="button"
                id={buttonId}
                onClick={() => removable(ctx, uuid, buttonId, deleteDictionary)}
              >
                {ctx.t('js.admin.dictionaries.remove')}
              </button>
            )}

            <div className="dict-form__footer-divider" />

            <Link
              wire={ctx.wire}
              className="btn btn-32px"
              pathname={dictionariesPath}
            >
              {ctx.t('js.admin.dictionaries.cancel')}
            </Link>

            <button
              className="btn btn-32px btn-primary"
              onClick={validateForm}
              type="submit"
            >
              {isNew
                ? ctx.t('js.admin.dictionaries.add')
                : ctx.t('js.admin.dictionaries.save')}
            </button>
          </div>
        </form>
      </div>
    )
  }
)

export default Form
