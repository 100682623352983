

import * as React from "react";
import * as Checkbox from "../../../../inputs/Checkbox.mjs";

function JobIndexFilter_Checkbox(Props) {
  var name = Props.name;
  var onToggle = Props.onToggle;
  var checked = Props.checked;
  var disabled = Props.disabled;
  return React.createElement("div", {
              className: "j-filter__option"
            }, React.createElement(Checkbox.make, {
                  onToggle: disabled ? (function (prim) {
                        
                      }) : onToggle,
                  checked: checked,
                  label: name,
                  disabled: disabled
                }));
}

var make = JobIndexFilter_Checkbox;

export {
  make ,
}
/* react Not a pure module */
