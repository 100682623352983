

import * as IndexJsx from "planado/components/input/skills_select/index.jsx";

var make = IndexJsx.SkillsSelect;

export {
  make ,
}
/* make Not a pure module */
