

import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../../../../../../common/Optional.mjs";
import * as Context_Types from "../../../../../../../context/Context_Types.mjs";
import * as JobForm_ScheduleShiftsWarning from "./shifts_warning/JobForm_ScheduleShiftsWarning.mjs";
import * as JobForm_ScheduleOverlapsWarning from "./overlaps_warning/JobForm_ScheduleOverlapsWarning.mjs";

function JobForm_ScheduleWarnings(Props) {
  var wire = Props.wire;
  var jobUuid = Props.jobUuid;
  var assignees = Props.assignees;
  var scheduledAt = Props.scheduledAt;
  var scheduledDuration = Props.scheduledDuration;
  var status = Props.status;
  var jobFinished = status === "Finished";
  var assigneesSelected = Caml_obj.notequal(assignees, []);
  return React.createElement(Optional.make, {
              show: !jobFinished && assigneesSelected,
              children: null
            }, React.createElement(JobForm_ScheduleOverlapsWarning.make, {
                  wire: wire,
                  except: jobUuid,
                  assignees: assignees,
                  scheduledAt: scheduledAt,
                  scheduledDuration: scheduledDuration
                }), React.createElement(Optional.make, {
                  show: Context_Types.Features.hasFlag("shifts", wire.ctx.features) && assigneesSelected,
                  children: React.createElement(JobForm_ScheduleShiftsWarning.make, {
                        wire: wire,
                        assignees: assignees,
                        scheduledAt: scheduledAt,
                        scheduledDuration: scheduledDuration
                      })
                }));
}

var make = JobForm_ScheduleWarnings;

export {
  make ,
}
/* react Not a pure module */
