

import * as El from "../../../libraries/El.mjs";
import * as Link from "../../../components/common/Link/Link.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../../components/common/Optional.mjs";
import * as UI_Button from "../../../ui/UI_Button.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import Infinite_scroll from "planado/components/infinite_scroll";
import * as Pages_Sites_TableModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/sites/index/Pages_Sites_Table.module.css";

var siteName$p = Utils.Translations.tr("js.sites.index.table.name");

var siteClientName$p = Utils.Translations.tr("js.sites.index.table.client_name");

var siteAddress$p = Utils.Translations.tr("js.sites.index.table.address");

var sitePhone$p = Utils.Translations.tr("js.sites.index.table.phone");

var siteApartment$p = Utils.Translations.t("js.sites.index.table.apartment");

var emptyHeadline$p = Utils.Translations.tr("js.sites.index.empty_block.headline");

var emptyLoad$p = Utils.Translations.tr("js.sites.index.empty_block.load_sites");

var emptyLoadPermissions$p = Utils.Translations.tr("js.sites.index.empty_block.load_sites_permission");

var showMore$p = Utils.Translations.tr("js.addresses.index.show_more");

var styles = Pages_Sites_TableModuleCss;

function Pages_Sites_Table$TableHeading(Props) {
  var ctx = Props.ctx;
  return React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", {
                      className: styles.sitesTableName
                    }, siteName$p(ctx)), React.createElement("th", {
                      className: styles.sitesTableClient
                    }, siteClientName$p(ctx)), React.createElement("th", {
                      className: styles.sitesTableAddress
                    }, siteAddress$p(ctx)), React.createElement("th", {
                      className: styles.sitesTablePhone
                    }, sitePhone$p(ctx))));
}

function Pages_Sites_Table$Row(Props) {
  var wire = Props.wire;
  var uuid = Props.uuid;
  var name = Props.name;
  var client = Props.client;
  var address = Props.address;
  var phone = Props.phone;
  var formatted = address.formatted;
  var match = address.apartment;
  var addressText = match !== undefined && match !== "" ? (
      formatted !== undefined ? formatted + ", " + siteApartment$p(wire.ctx) + address.apartment : ""
    ) : RCore.$$Option.getOr(address.formatted, "");
  var clientBlock;
  if (client !== undefined) {
    var name$1 = client.name;
    clientBlock = name$1 !== undefined ? React.createElement(Link.make, {
            wire: wire,
            route: {
              NAME: "Site",
              VAL: "Index"
            },
            modals: {
              hd: {
                NAME: "Client",
                VAL: {
                  NAME: "Edit",
                  VAL: client.uuid
                }
              },
              tl: /* [] */0
            },
            children: name$1
          }) : null;
  } else {
    clientBlock = null;
  }
  return React.createElement("tr", undefined, React.createElement("td", undefined, React.createElement(Link.make, {
                      wire: wire,
                      route: {
                        NAME: "Site",
                        VAL: "Index"
                      },
                      modals: {
                        hd: {
                          NAME: "Site",
                          VAL: {
                            NAME: "Edit",
                            VAL: uuid
                          }
                        },
                        tl: /* [] */0
                      },
                      children: name
                    })), React.createElement("td", undefined, clientBlock), React.createElement("td", undefined, addressText), React.createElement("td", undefined, RCore.$$Option.getOr(phone, "")));
}

function Pages_Sites_Table(Props) {
  var state = Props.state;
  var refreshSites = Props.refreshSites;
  var wire = AppContext.useWire();
  var totalSites = state.totalSites;
  var sites = state.sites;
  var fetching = state.fetching;
  var ctx = wire.ctx;
  var onLoadMore = function (currentState) {
    var match = currentState.filter.bounds;
    var offset = match.offset;
    var newOffset = offset === 0 ? 50 : offset + match.limit | 0;
    var newLimit = offset > 200 ? 50 : 30;
    var newBounds = {
      offset: newOffset,
      limit: newLimit
    };
    refreshSites(undefined, {
          bounds: newBounds
        });
  };
  return React.createElement("div", {
              className: styles.sitesList
            }, sites.length > 0 ? React.createElement(Infinite_scroll, {
                    loadMore: (function () {
                        onLoadMore(state);
                      }),
                    hasMore: !fetching && sites.length !== totalSites && state.filter.bounds.offset <= 200,
                    children: React.createElement("table", {
                          className: styles.sitesTable
                        }, React.createElement(Pages_Sites_Table$TableHeading, {
                              ctx: ctx
                            }), React.createElement("tbody", undefined, sites.map(function (param) {
                                  var uuid = param.uuid;
                                  return React.createElement(Pages_Sites_Table$Row, {
                                              wire: wire,
                                              uuid: uuid,
                                              name: param.name,
                                              client: param.client,
                                              address: param.address,
                                              phone: param.phone,
                                              key: Uuid.toString(uuid)
                                            });
                                })))
                  }) : React.createElement("div", undefined, React.createElement("div", {
                        className: styles.sitesListHeadline
                      }, emptyHeadline$p(ctx)), React.createElement("div", {
                        className: styles.sitesListDesc
                      }, state.isAdmin ? React.createElement(Link.make, {
                              wire: wire,
                              modals: {
                                hd: {
                                  NAME: "Site",
                                  VAL: {
                                    NAME: "Upload",
                                    VAL: "Sites"
                                  }
                                },
                                tl: /* [] */0
                              },
                              children: emptyLoad$p(ctx)
                            }) : React.createElement("p", undefined, emptyLoad$p(ctx), React.createElement("br", undefined), emptyLoadPermissions$p(ctx)))), React.createElement(Optional.make, {
                  show: sites.length < totalSites,
                  children: React.createElement(UI_Button.make, {
                        className: El.Cn.concatIf(styles.sitesListBtn, [
                              fetching,
                              "is-loading"
                            ]),
                        type_: "button",
                        onClick: (function (param) {
                            onLoadMore(state);
                          }),
                        children: showMore$p(ctx)
                      })
                }));
}

var make = Pages_Sites_Table;

export {
  make ,
}
/* siteName' Not a pure module */
