

import * as DayTimeline_DragLayerModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/day_timeline/components/drag_layer/DayTimeline_DragLayer.module.css";

var styles = DayTimeline_DragLayerModuleCss;

function make() {
  var templateEl = document.createElement("div");
  templateEl.setAttribute("class", styles.template);
  var addressEl = document.createElement("div");
  addressEl.setAttribute("class", styles.address);
  var innerEl = document.createElement("div");
  innerEl.setAttribute("class", styles.inner);
  innerEl.appendChild(templateEl);
  innerEl.appendChild(addressEl);
  var innerContainerEl = document.createElement("div");
  innerContainerEl.setAttribute("class", styles.innerContainer);
  innerContainerEl.appendChild(innerEl);
  var jobEl = document.createElement("div");
  jobEl.setAttribute("class", styles.job);
  jobEl.appendChild(innerContainerEl);
  var containerEl = document.createElement("div");
  containerEl.setAttribute("class", styles.container);
  containerEl.appendChild(jobEl);
  return {
          containerEl: containerEl,
          jobEl: jobEl,
          templateEl: templateEl,
          addressEl: addressEl
        };
}

export {
  styles ,
  make ,
}
/* styles Not a pure module */
