

import * as Case from "../../../utils/Case.mjs";
import * as Title from "../../common/Title.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Portal from "../../../Portal.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as JsContext from "../../../libraries/JsContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as MessengerTypes from "../../messenger/MessengerTypes.mjs";
import * as RecurringJobAPI from "./RecurringJobAPI.mjs";
import * as RecurringJobFormJS from "./RecurringJobFormJS.mjs";
import * as Utils$1 from "planado/stores/utils";
import * as RecurringJobFormFooterJS from "./RecurringJobFormFooterJS.mjs";
import Recurring_jobs_show from "planado/stores/admin/recurring_jobs_show";

var jobDeleted$p = Utils.Translations.t("js.recurring_jobs.messages.job_deleted");

var jobCreated$p = Utils.Translations.t("js.recurring_jobs.messages.job_created");

var jobUpdated$p = Utils.Translations.t("js.recurring_jobs.messages.job_updated");

function RecurringJobEdit(Props) {
  var wire = Props.wire;
  var mode = Props.mode;
  var footer = wire.footer;
  var navigate = wire.navigate;
  var messenger = wire.subscriptions.messenger;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return {
                store: undefined,
                recurringJob: undefined
              };
      });
  var setState = match[1];
  var state = match[0];
  var showMessage = function (messageType, text) {
    messenger.trigger({
          TAG: "Show",
          _0: MessengerTypes.Message.make(undefined, messageType, {
                TAG: "Text",
                _0: text
              })
        });
  };
  var loaded = function (result) {
    if (result.TAG === "Ok") {
      var data = result._0;
      if (data === undefined) {
        return ;
      }
      var data$1 = Case.camelize(data);
      var store = Recurring_jobs_show(data$1, JsContext.get(wire));
      setState(function (param) {
            return {
                    store: Caml_option.some(store),
                    recurringJob: Caml_option.some(data$1)
                  };
          });
      Utils$1.connectDocumentToStore(Utils.$$document, footer, store);
      return ;
    }
    var e = result._0;
    if (typeof e !== "object") {
      console.error(e);
      return ;
    }
    if (e.TAG === "HTTPError") {
      var tmp = e._0[0];
      if (typeof tmp !== "object") {
        if (tmp === "NotFound") {
          return navigate(undefined, "Unknown");
        }
        console.error(e);
        return ;
      } else {
        console.error(e);
        return ;
      }
    } else {
      console.error(e);
      return ;
    }
  };
  var destroyed = function (result) {
    if (result.TAG !== "Ok") {
      return ;
    }
    showMessage("Success", jobDeleted$p(ctx));
    navigate(undefined, {
          NAME: "RecurringJob",
          VAL: "Index"
        });
  };
  React.useEffect((function () {
          if (mode.NAME === "Edit") {
            Backend.$$finally(RecurringJobAPI.Show.show(wire, mode.VAL), loaded);
          } else {
            var match = mode.VAL;
            Backend.$$finally(RecurringJobAPI.Show.new_(wire, match.template, match.client, match.site), loaded);
          }
        }), []);
  var store = state.store;
  if (store === undefined) {
    return null;
  }
  var data = state.recurringJob;
  if (data === undefined) {
    return null;
  }
  var store$1 = Caml_option.valFromOption(store);
  return React.createElement(React.Fragment, undefined, React.createElement(Title.make, {
                  route: wire.route,
                  ctx: ctx,
                  values: {
                    name: Caml_option.valFromOption(data).recurringJob.name
                  },
                  wire: wire
                }), React.createElement("div", {
                  id: "content-wrapper"
                }, React.createElement("div", {
                      className: "page-container"
                    }, React.createElement(RecurringJobFormJS.make, {
                          store: store$1,
                          wire: wire,
                          onSubmitRecurringJob: (function (uuid, json) {
                              setState(function (param) {
                                    return {
                                            store: state.store,
                                            recurringJob: Caml_option.some(json)
                                          };
                                  });
                              var msg = mode.NAME === "Edit" ? jobUpdated$p(ctx) : jobCreated$p(ctx);
                              showMessage("Success", msg);
                              navigate(undefined, {
                                    NAME: "RecurringJob",
                                    VAL: {
                                      NAME: "Edit",
                                      VAL: uuid
                                    }
                                  });
                            }),
                          deleteRecurringJob: (function (uuid) {
                              Backend.$$finally(RecurringJobAPI.Show.destroy(wire, uuid), destroyed);
                            })
                        }), React.createElement(Portal.make, {
                          root: footer,
                          children: React.createElement(RecurringJobFormFooterJS.make, {
                                store: store$1
                              })
                        }))));
}

var make = RecurringJobEdit;

export {
  make ,
}
/* jobDeleted' Not a pure module */
