

import Footer_link from "planado/containers/footer_link";

var make = Footer_link;

export {
  make ,
}
/* make Not a pure module */
