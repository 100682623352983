

import * as Shared_Lib_String from "../../../shared/lib/Shared_Lib_String.mjs";

var variableParser = new RegExp("\\[[^\\]]+\\]|(?:[\\x2D0-9A-Z_a-z])+\\??", "g");

function make(markup) {
  return Shared_Lib_String.matchh(markup, variableParser);
}

export {
  make ,
}
/* variableParser Not a pure module */
