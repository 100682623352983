

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as React from "react";
import * as Backend from "../backend/Backend.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Tour_Types from "./Tour_Types.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Subscription from "../Subscription.mjs";
import * as Tour from "@reactour/tour";

var anchor = Tour_Types.$$CSS.id;

function enabledOnStepEntrance(tour, step) {
  var match = step.label;
  return !(match === "enterJobDescription" || match === "enterClientFirstName" || match === "enterClientPhoneNumber");
}

function enabledOnEvent(tour, step, $$event) {
  var match = step.label;
  if (typeof $$event !== "object") {
    return false;
  }
  var variant = $$event.NAME;
  if (variant === "jobDescriptionUpdated") {
    if ($$event.VAL !== undefined) {
      return match === "enterJobDescription";
    } else {
      return false;
    }
  } else if (variant === "clientFirstNameUpdated") {
    if ($$event.VAL !== undefined) {
      return match === "enterClientFirstName";
    } else {
      return false;
    }
  } else if (variant === "clientPhoneNumberUpdated" && $$event.VAL !== undefined) {
    return match === "enterClientPhoneNumber";
  } else {
    return false;
  }
}

var NextButton = {
  enabledOnStepEntrance: enabledOnStepEntrance,
  enabledOnEvent: enabledOnEvent
};

function update(wire, tourName, status) {
  var intro;
  if (typeof status === "object") {
    var init = Tour_Types.TourProgress.Intro.empty;
    intro = {
      autoStart: init.autoStart,
      started: init.started,
      closed: init.closed,
      progress: status.VAL
    };
  } else if (status === "closed") {
    var init$1 = Tour_Types.TourProgress.Intro.empty;
    intro = {
      autoStart: init$1.autoStart,
      started: init$1.started,
      closed: true,
      progress: init$1.progress
    };
  } else if (status === "started") {
    var init$2 = Tour_Types.TourProgress.Intro.empty;
    intro = {
      autoStart: init$2.autoStart,
      started: true,
      closed: init$2.closed,
      progress: init$2.progress
    };
  } else {
    var init$3 = Tour_Types.TourProgress.Intro.empty;
    intro = {
      autoStart: init$3.autoStart,
      started: false,
      closed: false,
      progress: 0
    };
  }
  var patch = {
    intro: intro
  };
  Backend.patch(undefined, true, undefined, true, wire, {
        NAME: "API",
        VAL: "Attributes"
      }, {
        NAME: "Obj",
        VAL: {
          tour: patch
        }
      });
}

function track(wire, manager) {
  var percent = Math.round((manager.currentStep + 1 | 0) / manager.steps.length * 100.0) | 0;
  update(wire, "intro", {
        NAME: "progress",
        VAL: percent
      });
}

var Progress = {
  update: update,
  track: track
};

function makeState(userUuid) {
  return function (seed) {
    return {
            anchor: (function (css) {
                return anchor(seed, css);
              }),
            userUuid: userUuid,
            delayedUntil: {
              contents: undefined
            },
            nextButtonSubscription: Subscription.make()
          };
  };
}

function use(wire) {
  var manager = Tour.useTour();
  var state = wire.tour;
  return {
          trigger: (function ($$event) {
              var step = manager.steps[manager.currentStep];
              if (step !== undefined && manager.isOpen) {
                var nextStep = manager.steps[manager.currentStep + 1 | 0];
                var match = step.label;
                var goNext;
                if (typeof $$event === "object") {
                  var variant = $$event.NAME;
                  if (variant === "templateSelectShown") {
                    goNext = $$event.VAL ? match === "openTemplateSelect" : false;
                  } else if (variant === "jobScheduled") {
                    goNext = match === "pickCurrentDate" ? $$event.VAL[1] : false;
                  } else if (variant === "templateSelected") {
                    goNext = typeof match === "object" && match.NAME === "selectTemplate" ? Uuid.equal($$event.VAL, match.VAL) : false;
                  } else if (variant === "assigneeChosen" && match === "assignToYourself") {
                    var userUuid = state.userUuid;
                    goNext = userUuid !== undefined ? Uuid.equal($$event.VAL, Caml_option.valFromOption(userUuid)) : false;
                  } else {
                    goNext = false;
                  }
                } else {
                  goNext = $$event === "newClientChosen" ? match === "chooseNewClient" : (
                      $$event === "jobCreated" ? match === "addJob" : (
                          $$event === "assigneeSelectShown" ? match === "chooseAssignee" : false
                        )
                    );
                }
                if (goNext) {
                  var exit = 0;
                  if (nextStep !== undefined) {
                    var until = nextStep.delayUntil;
                    if (until !== undefined) {
                      manager.setIsOpen(false);
                      state.delayedUntil.contents = until;
                    } else {
                      exit = 2;
                    }
                  } else {
                    exit = 2;
                  }
                  if (exit === 2) {
                    track(wire, manager);
                    manager.setCurrentStep(manager.currentStep + 1 | 0);
                  }
                  if (nextStep !== undefined) {
                    state.nextButtonSubscription.trigger(enabledOnStepEntrance("intro", nextStep));
                  }
                  return true;
                }
                state.nextButtonSubscription.trigger(enabledOnEvent("intro", step, $$event));
                return false;
              }
              if (manager.isOpen) {
                return true;
              }
              var until$1 = state.delayedUntil.contents;
              if (until$1 !== undefined) {
                if (Caml_obj.equal(until$1, $$event)) {
                  state.delayedUntil.contents = undefined;
                  track(wire, manager);
                  manager.setCurrentStep(manager.currentStep + 1 | 0);
                  manager.setIsOpen(true);
                  return true;
                } else {
                  return false;
                }
              } else if ($$event === "start") {
                manager.setIsOpen(true);
                return true;
              } else {
                return true;
              }
            })
        };
}

function useState(userUuid) {
  return React.useMemo((function () {
                return makeState(userUuid);
              }), []);
}

var Manager = {};

export {
  NextButton ,
  Progress ,
  Manager ,
  makeState ,
  use ,
  useState ,
}
/* Uuid Not a pure module */
