// extracted by mini-css-extract-plugin
export var active = "active-iHFtOdFjC1RRsfX16BU2";
export var alignEnd = "align-end-fcIPA5onHiyldkE6PXiw";
export var button = "button-pSIY9J6NztoRasMqR3vw";
export var canceled = "canceled-RBV0IaKfJmg2H7MVq3oC";
export var dialog = "dialog-EVABXz_XhJTwY0Lu1DDe";
export var emptyFilter = "empty-filter-H1zvwT_diwRVlQrvc4Hi";
export var error = "error-i0YO5MBtKCpccNt3xxLk";
export var footer = "footer-g4yte3RCneUphN_0M_Zj";
export var group = "group-Ue5yrzEANbZH4KE0wnNd";
export var headerHeadline = "header-headline-owOvnSI1RQBWMhLpJEWV";
export var jobRemoved = "job-removed-bfH5b1oxLRlq4kx_M229";
export var jobsContainer = "jobs-container-Xjl9l_RXjQEwlKE3Au8O";
export var modalContainer = "modal-container-jZTiR4zYkcL2NoXRy9fc";
export var moreButton = "more-button-MlHsc6v64egSreS6fFWc";
export var retry = "retry-XK5GAUCmlBRHyCQiVUF4";
export var row = "row-grG_TeuPBLAm5sIzmakR";
export var skipped = "skipped-g31MeY4q8yhTLicL90bh";
export var spinner = "spinner-pgAa2HOM65yHVXkslWwh";
export var status = "status-r4uOy5fdJ75OgoBeq0pR";
export var success = "success-AfycDtB5ROR7IAupMOzw";
export var timesCircle = "times-circle-VXFvpX7fyvu2eu38oet1";
export var title = "title-VORxXiGzdF1vZ2yQX6Xw";