

import * as El from "../../../../../../libraries/El.mjs";
import * as Icon from "../../../../../../Icon.mjs";
import * as Uuid from "../../../../../../bindings/uuid/Uuid.mjs";
import * as Hooks from "../../../../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../types/Types.mjs";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../../../inputs/Checkbox.mjs";
import * as Optional from "../../../../../common/Optional.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Schedule_Header_FilterModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/header/right/filter/Schedule_Header_Filter.module.css";

var styles = Schedule_Header_FilterModuleCss;

function Schedule_Header_FilterComponent$Popover(Props) {
  var filterVariants = Props.filterVariants;
  var selectedFilterVariants = Props.selectedFilterVariants;
  var onToggle = Props.onToggle;
  var close = Props.close;
  var labelEmpty = Props.labelEmpty;
  var children = Props.children;
  var popoverRef = Types.ReactRef.use();
  Hooks.useOnClickOutside(undefined, popoverRef, close);
  Hooks.useOnEscPress(undefined, close);
  var children$1 = RCore.$$Option.getOr(children, null);
  var itemsBlock = Caml_obj.equal(filterVariants, []) ? React.createElement("div", {
          className: styles.empty
        }, labelEmpty) : React.createElement("div", {
          className: styles.items
        }, filterVariants.map(function (param) {
              var uuid = param.uuid;
              return React.createElement(Checkbox.make, {
                          onToggle: (function (param) {
                              onToggle(uuid);
                            }),
                          checked: selectedFilterVariants.some(function (u) {
                                return Caml_obj.equal(u, uuid);
                              }),
                          label: param.name,
                          wrapperClasses: Js_dict.fromList({
                                hd: [
                                  styles.item,
                                  true
                                ],
                                tl: /* [] */0
                              }),
                          key: Uuid.toString(uuid)
                        });
            }));
  return React.createElement("div", {
              ref: popoverRef,
              className: styles.popover
            }, children$1, itemsBlock);
}

function Schedule_Header_FilterComponent$Button(Props) {
  var disabled = Props.disabled;
  var selected = Props.selected;
  var active = Props.active;
  var togglePopover = Props.togglePopover;
  var resetFilter = Props.resetFilter;
  var label = Props.label;
  var labelSelected = Props.labelSelected;
  var textBlock = selected ? React.createElement(React.Fragment, undefined, labelSelected, React.createElement("div", {
              className: styles.resetButton,
              type: "button",
              onClick: resetFilter
            }, React.createElement("span", {
                  className: Icon.style(undefined, undefined, "times")
                }))) : label;
  var className = El.Cn.concat(El.Cn.concat(styles.button, selected ? styles.selected : ""), active ? styles.active : "");
  return React.createElement("button", {
              className: className,
              disabled: disabled,
              type: "button",
              onClick: togglePopover
            }, textBlock);
}

function toggle(uuids, uuid) {
  var opt = RCore.$$Option.map(RCore.$$Array.getBy(uuids, (function (u) {
              return Caml_obj.equal(u, uuid);
            })), (function (uuid) {
          return RCore.$$Array.keep(uuids, (function (u) {
                        return Caml_obj.notequal(u, uuid);
                      }));
        }));
  if (opt !== undefined) {
    return opt;
  } else {
    return Belt_Array.concatMany([
                uuids,
                [uuid]
              ]);
  }
}

function Schedule_Header_FilterComponent(Props) {
  var filterVariants = Props.filterVariants;
  var selectedFilterVariants = Props.selectedFilterVariants;
  var setFilter = Props.setFilter;
  var label = Props.label;
  var labelSelected = Props.labelSelected;
  var labelEmpty = Props.labelEmpty;
  var children = Props.children;
  var match = React.useState(function () {
        return false;
      });
  var setShow = match[1];
  var show = match[0];
  var togglePopover = function (_event) {
    setShow(function (param) {
          return !show;
        });
  };
  var closePopover = function () {
    setShow(function (param) {
          return false;
        });
  };
  var resetFilter = function ($$event) {
    $$event.stopPropagation();
    setShow(function (param) {
          return false;
        });
    setFilter([]);
  };
  var toggleFilterVariant = function (uuid) {
    setFilter(toggle(selectedFilterVariants, uuid));
  };
  var hasVariants = filterVariants.length > 0;
  var hasSelectedVariants = selectedFilterVariants.length > 0;
  var buttonBlock = React.createElement(Schedule_Header_FilterComponent$Button, {
        disabled: !hasVariants,
        selected: hasSelectedVariants,
        active: show,
        togglePopover: togglePopover,
        resetFilter: resetFilter,
        label: label,
        labelSelected: labelSelected
      });
  return React.createElement("div", {
              className: styles.container
            }, hasVariants ? React.createElement(React.Fragment, undefined, buttonBlock, React.createElement(Optional.make, {
                        show: show,
                        children: React.createElement(Schedule_Header_FilterComponent$Popover, {
                              filterVariants: filterVariants,
                              selectedFilterVariants: selectedFilterVariants,
                              onToggle: toggleFilterVariant,
                              close: closePopover,
                              labelEmpty: labelEmpty,
                              children: children
                            })
                      })) : buttonBlock);
}

var make = Schedule_Header_FilterComponent;

export {
  make ,
}
/* styles Not a pure module */
