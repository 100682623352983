// extracted by mini-css-extract-plugin
export var addButton = "add-button-WNYcVZR5o8XQG6nCdxcW";
export var addButtonLeft = "add-button-left-U6bw3yzdegxnflBVObIl add-button-WNYcVZR5o8XQG6nCdxcW";
export var description = "description-Qbyjj8mKVWVx0POuIAvG";
export var form = "form-qfy7ekBNLW3lSEDFVXh3";
export var formBody = "form-body-xylYdf2B01GgmuypjbuB";
export var formFooter = "form-footer-npek6OFaB0MUKOjcN1Jg";
export var formHeader = "form-header-MZxe5U0xO8KqJ1wNCFsc";
export var noSkills = "no-skills-Sji55mpSDZOgYJm1khko";
export var noSkillsContainer = "no-skills-container-deLamVNdj8cBCD4vd17f";
export var skillsList = "skills-list-Jj7JczYdPi2IwzMsadAO";
export var tags = "tags-MYNKz7ViRZQt_Ty6fu2z";