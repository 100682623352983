

import * as RCore from "../../libraries/RCore.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function some(v) {
  return Caml_option.some(v);
}

function align(fa, fb) {
  if (fa === undefined) {
    if (fb !== undefined) {
      return {
              TAG: "Right",
              _0: Caml_option.valFromOption(fb)
            };
    } else {
      return ;
    }
  }
  var a = Caml_option.valFromOption(fa);
  if (fb !== undefined) {
    return {
            TAG: "Both",
            _0: a,
            _1: Caml_option.valFromOption(fb)
          };
  } else {
    return {
            TAG: "Left",
            _0: a
          };
  }
}

function alt(fa1, fa2) {
  if (fa1 !== undefined) {
    return fa1;
  } else {
    return fa2;
  }
}

function flatten(value) {
  return RCore.$$Option.getOr(value, undefined);
}

export {
  some ,
  align ,
  alt ,
  flatten ,
}
/* No side effect */
