

import * as Fun from "../../libraries/Fun.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Profile from "../profile/Profile.mjs";
import * as ApiRoute from "../../types/ApiRoute.mjs";
import * as Password from "../password/Password.mjs";
import * as HeaderLogo from "./HeaderLogo.mjs";
import * as HeaderMenu from "./HeaderMenu.mjs";
import * as HeaderModuleCss from "/home/runner/work/planado/planado/client/rescript/components/header/Header.module.css";

var styles = HeaderModuleCss;

var billWarning$p = Utils.Translations.tr("js.billing.due_bill.notification");

var trialExpires$p = Utils.Translations.tx("common.estimated_trial_days");

var trialExpiresToday$p = Utils.Translations.t("common.trial_expires_today");

function Header$Warning(Props) {
  var param = Props.wire;
  var route = param.route;
  var ctx = param.ctx;
  var _uuid = ctx.dueBillUuid;
  if (_uuid !== undefined && route.path !== "Billing") {
    return React.createElement("div", {
                className: styles.headerInfo
              }, React.createElement("div", {
                    className: styles.headerWarning
                  }, ctx.owner ? React.createElement("a", {
                          href: ApiRoute.path({
                                NAME: "API",
                                VAL: {
                                  NAME: "Billing",
                                  VAL: "Index"
                                }
                              }),
                          target: "_blank"
                        }, billWarning$p(ctx)) : billWarning$p(ctx)));
  }
  var match = ctx.trial;
  if (match === undefined) {
    return null;
  }
  var expiresIn = match.expiresIn;
  if (expiresIn !== undefined && route.path !== "Billing") {
    return React.createElement("div", {
                className: styles.headerInfo
              }, React.createElement("div", {
                    className: styles.headerWarning
                  }, expiresIn !== 0 ? trialExpires$p({
                          count: expiresIn
                        }, ctx) : trialExpiresToday$p(ctx)));
  } else {
    return null;
  }
}

function Header(Props) {
  var wire = Props.wire;
  var subscriptions = wire.subscriptions;
  var match = React.useState(function () {
        
      });
  var setOpenedSection = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setPasswordModalOpened = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setProfileModalOpened = match$2[1];
  React.useEffect((function () {
          return subscriptions.click.subscribe(function (_event) {
                      setOpenedSection(function (param) {
                            
                          });
                    });
        }), []);
  var togglePasswordModal = function () {
    setPasswordModalOpened(function (prev) {
          return !prev;
        });
  };
  var toggleProfileModal = function () {
    setProfileModalOpened(function (prev) {
          return !prev;
        });
  };
  return React.createElement("header", {
              className: styles.header
            }, React.createElement("div", {
                  className: styles.content
                }, React.createElement(HeaderLogo.make, {
                      wire: wire
                    }), React.createElement(HeaderMenu.make, {
                      wire: wire,
                      openedSection: match[0],
                      setOpenedSection: setOpenedSection,
                      togglePasswordModal: togglePasswordModal,
                      toggleProfileModal: toggleProfileModal
                    })), React.createElement(Password.make, {
                  wire: wire,
                  onClose: Fun.omit(togglePasswordModal),
                  show: match$1[0]
                }), React.createElement(Profile.make, {
                  wire: wire,
                  onClose: Fun.omit(toggleProfileModal),
                  show: match$2[0]
                }), React.createElement(Header$Warning, {
                  wire: wire
                }));
}

var make = Header;

export {
  make ,
}
/* styles Not a pure module */
