

import * as Case from "../../../utils/Case.mjs";
import * as Title from "../../common/Title.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as JsContext from "../../../libraries/JsContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Template_API from "./Template_API.mjs";
import * as MessengerTypes from "../../messenger/MessengerTypes.mjs";
import * as Template_Types from "./Template_Types.mjs";
import * as Template_Form_JS from "./Template_Form_JS.mjs";
import * as Utils$1 from "planado/stores/utils";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";

var templateDeleted$p = Utils.Translations.t("js.templates.messages.template_deleted");

var templateCreated$p = Utils.Translations.t("js.templates.messages.template_created");

var templateUpdated$p = Utils.Translations.t("js.templates.messages.template_updated");

var serverError$p = Utils.Translations.t("js.templates.messages.server_error");

var formError$p = Utils.Translations.t("js.templates.messages.unprocessable_entity");

function Template_Edit(Props) {
  var wire = Props.wire;
  var mode = Props.mode;
  var footer = wire.footer;
  var navigate = wire.navigate;
  var match = wire.subscriptions;
  var templates = match.templates;
  var messenger = match.messenger;
  var ctx = wire.ctx;
  var match$1 = React.useState(function () {
        
      });
  var setStore = match$1[1];
  var store = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setResponseData = match$2[1];
  var responseData = match$2[0];
  var showMessage = function (messageType, text) {
    messenger.trigger({
          TAG: "Show",
          _0: MessengerTypes.Message.make(undefined, messageType, {
                TAG: "Text",
                _0: text
              })
        });
  };
  var loaded = function (data) {
    if (data.TAG === "Ok") {
      var json = data._0;
      if (json === undefined) {
        return ;
      }
      var data$1 = Case.camelize(json);
      var store = Template_Types.Store.make(data$1, JsContext.get(wire));
      setStore(function (param) {
            return Caml_option.some(store);
          });
      Utils$1.connectDocumentToStore(Utils.$$document, footer, store);
      if (typeof mode === "object" && mode.NAME === "New" && mode.VAL === undefined) {
        return ;
      }
      var templateFields = Json_Decode$JsonCombinators.decode(data$1, Template_API.Get.decode);
      if (templateFields.TAG === "Ok") {
        var templateFields$1 = templateFields._0;
        return setResponseData(function (param) {
                    return templateFields$1;
                  });
      }
      console.log(templateFields._0);
      return ;
    }
    var match = data._0;
    if (typeof match !== "object") {
      return ;
    }
    if (match.TAG !== "HTTPError") {
      return ;
    }
    var tmp = match._0[0];
    if (typeof tmp !== "object" && tmp === "NotFound") {
      return navigate(undefined, "Unknown");
    }
    
  };
  var destroyed = function (data) {
    if (data.TAG !== "Ok") {
      return ;
    }
    showMessage("Success", templateDeleted$p(ctx));
    templates.trigger("TemplateRemoved");
    navigate(undefined, {
          NAME: "Template",
          VAL: "Index"
        });
  };
  var deleteTemplate = function ($$event) {
    $$event.preventDefault();
    if (mode.NAME === "Edit") {
      return Backend.$$finally(Template_API.Show.destroy(wire, mode.VAL), destroyed);
    }
    
  };
  var onTemplateSubmitted = function (data) {
    setResponseData(function (param) {
          return data;
        });
    var msg = mode.NAME === "Edit" ? templateUpdated$p(ctx) : templateCreated$p(ctx);
    showMessage("Success", msg);
    templates.trigger("TemplateSubmitted");
    navigate(undefined, {
          NAME: "Template",
          VAL: {
            NAME: "Edit",
            VAL: data.uuid
          }
        });
  };
  React.useEffect((function () {
          if (mode.NAME === "Edit") {
            Backend.$$finally(Template_API.Show.show(wire, mode.VAL), loaded);
          } else {
            Backend.$$finally(Template_API.Show.new_(wire, mode.VAL), loaded);
          }
        }), []);
  var title = responseData !== undefined ? React.createElement(Title.make, {
          route: wire.route,
          ctx: ctx,
          values: {
            name: responseData.name
          },
          wire: wire
        }) : null;
  if (store !== undefined) {
    return React.createElement(React.Fragment, undefined, title, React.createElement("div", {
                    id: "content-wrapper"
                  }, React.createElement("div", {
                        className: "page-container"
                      }, React.createElement("div", {
                            className: "tpl-container_action"
                          }, React.createElement(Template_Form_JS.make, {
                                store: Caml_option.valFromOption(store),
                                wire: wire,
                                deleteTemplate: deleteTemplate,
                                onSubmitTemplate: onTemplateSubmitted,
                                showServerErrorMsg: (function (_event) {
                                    showMessage("Danger", serverError$p(ctx));
                                  }),
                                showFormErrorMsg: (function (_event) {
                                    showMessage("Warning", formError$p(ctx));
                                  }),
                                redirectToIndex: (function () {
                                    navigate(undefined, {
                                          NAME: "Template",
                                          VAL: "Index"
                                        });
                                  })
                              })))));
  } else {
    return null;
  }
}

var make = Template_Edit;

export {
  make ,
}
/* templateDeleted' Not a pure module */
