

import * as RCore from "../RCore.mjs";
import * as Hooks_IntersectionObserver from "./Hooks_IntersectionObserver.mjs";

function use() {
  var match = Hooks_IntersectionObserver.use(undefined, undefined, undefined);
  var entry = match.entry;
  return {
          rootRef: match.rootRef,
          elementRef: match.elementRef,
          entry: entry,
          isVisible: RCore.$$Option.mapWithDefault(entry, false, (function (prim) {
                  return prim.isIntersecting;
                }))
        };
}

export {
  use ,
}
/* Hooks_IntersectionObserver Not a pure module */
