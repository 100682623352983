

import * as Route from "../../types/Route.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../common/Optional.mjs";
import * as Context_Types from "../../context/Context_Types.mjs";

var templates$p = Utils.Translations.tr("js.admin.settings.title.templates");

var resolutions$p = Utils.Translations.tr("js.admin.settings.title.resolutions");

var jobTypes$p = Utils.Translations.tr("js.admin.settings.title.job_types");

var recurringJobs$p = Utils.Translations.tr("js.admin.settings.title.recurring_jobs");

var printLayouts$p = Utils.Translations.tr("js.admin.settings.title.print_layouts");

var clientForms$p = Utils.Translations.tr("js.admin.settings.title.client_forms");

function JobSettingsTabs(Props) {
  var param = Props.wire;
  var tab = Props.tab;
  var navigate = param.navigate;
  var ctx = param.ctx;
  var openTab = function (tab) {
    return function ($$event) {
      $$event.preventDefault();
      var route;
      switch (tab) {
        case "Templates" :
            route = {
              NAME: "Template",
              VAL: "Index"
            };
            break;
        case "Resolutions" :
            route = "ResolutionIndex";
            break;
        case "JobTypes" :
            route = "JobTypeIndex";
            break;
        case "RecurringJobs" :
            route = {
              NAME: "RecurringJob",
              VAL: "Index"
            };
            break;
        case "PrintLayouts" :
            route = {
              NAME: "PrintLayout",
              VAL: "Index"
            };
            break;
        case "ClientForms" :
            route = {
              NAME: "ClientForm",
              VAL: "Index"
            };
            break;
        
      }
      navigate(undefined, route);
    };
  };
  var match = ctx.permissions;
  var tmp;
  var exit = 0;
  if (match !== undefined && match.recurringJobs === "Restricted") {
    tmp = null;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement("li", {
          className: "RecurringJobs" === tab ? "active" : ""
        }, React.createElement("a", {
              href: Route.pagePath(undefined, {
                    NAME: "RecurringJob",
                    VAL: "Index"
                  }),
              onClick: openTab("RecurringJobs")
            }, recurringJobs$p(ctx)));
  }
  return React.createElement("ul", {
              className: "nav nav-tabs"
            }, React.createElement("li", {
                  className: "Templates" === tab ? "active" : ""
                }, React.createElement("a", {
                      href: Route.pagePath(undefined, {
                            NAME: "Template",
                            VAL: "Index"
                          }),
                      onClick: openTab("Templates")
                    }, templates$p(ctx))), React.createElement("li", {
                  className: "Resolutions" === tab ? "active" : ""
                }, React.createElement("a", {
                      href: Route.pagePath(undefined, "ResolutionIndex"),
                      onClick: openTab("Resolutions")
                    }, resolutions$p(ctx))), React.createElement("li", {
                  className: "JobTypes" === tab ? "active" : ""
                }, React.createElement("a", {
                      href: Route.pagePath(undefined, "JobTypeIndex"),
                      onClick: openTab("JobTypes")
                    }, jobTypes$p(ctx))), tmp, React.createElement(Optional.make, {
                  show: Context_Types.Features.hasFlag("printLayouts", ctx.features),
                  children: React.createElement("li", {
                        className: "PrintLayouts" === tab ? "active" : ""
                      }, React.createElement("a", {
                            href: Route.pagePath(undefined, {
                                  NAME: "PrintLayout",
                                  VAL: "Index"
                                }),
                            onClick: openTab("PrintLayouts")
                          }, printLayouts$p(ctx)))
                }), React.createElement(Optional.make, {
                  show: Context_Types.Features.hasFlag("clientTracking", ctx.features),
                  children: React.createElement("li", {
                        className: "ClientForms" === tab ? "active" : ""
                      }, React.createElement("a", {
                            href: Route.pagePath(undefined, {
                                  NAME: "ClientForm",
                                  VAL: "Index"
                                }),
                            onClick: openTab("ClientForms")
                          }, clientForms$p(ctx)))
                }));
}

var make = JobSettingsTabs;

export {
  make ,
}
/* templates' Not a pure module */
