

import * as Backend_Fetch from "./Backend_Fetch.mjs";
import * as Backend_Helper from "./Backend_Helper.mjs";

var JsonResult = {};

function get(headers, silent, redirectOnUnauthorizedStatus, wire, path) {
  return Backend_Fetch.$$fetch(undefined, headers, undefined, silent, undefined, redirectOnUnauthorizedStatus, wire, path);
}

function post(headers, silent, redirectOnUnauthorizedStatus, decamelize, wire, path, data) {
  return Backend_Fetch.$$fetch("Post", headers, Backend_Helper.BodyInit.make(decamelize, data), silent, Backend_Helper.BodyInit.isFormData(data), redirectOnUnauthorizedStatus, wire, path);
}

function patch(headers, silent, redirectOnUnauthorizedStatus, decamelize, wire, path, data) {
  return Backend_Fetch.$$fetch("Patch", headers, Backend_Helper.BodyInit.make(decamelize, data), silent, Backend_Helper.BodyInit.isFormData(data), redirectOnUnauthorizedStatus, wire, path);
}

function $$delete(headers, silent, redirectOnUnauthorizedStatus, wire, path) {
  return Backend_Fetch.$$fetch("Delete", headers, undefined, silent, undefined, redirectOnUnauthorizedStatus, wire, path);
}

function log(message, wire) {
  return Backend_Fetch.$$fetch("Post", undefined, "", undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "Log",
                VAL: message
              }
            });
}

var $$Error;

var Errors;

var $$Headers;

var Result;

var BodyInit = Backend_Helper.BodyInit;

var $$FormData = Backend_Helper.$$FormData;

var parseError = Backend_Helper.parseError;

var decode = Backend_Helper.decode;

var discard = Backend_Helper.discard;

var $$finally = Backend_Helper.$$finally;

var finallyOnSuccess = Backend_Helper.finallyOnSuccess;

export {
  $$Error ,
  Errors ,
  $$Headers ,
  Result ,
  JsonResult ,
  BodyInit ,
  $$FormData ,
  parseError ,
  decode ,
  discard ,
  $$finally ,
  finallyOnSuccess ,
  get ,
  post ,
  patch ,
  $$delete ,
  log ,
}
/* Backend_Fetch Not a pure module */
