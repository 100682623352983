import * as React from 'react'
import classNames from 'classnames'
import humps from 'humps'
import { debounce } from 'planado/utils/index.js'
import InfiniteScroll from 'planado/components/infinite_scroll'
import DayJobs from './day_jobs'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import Link from 'rscrpt/components/common/Link/Link.mjs'
import { jobsUpload } from 'planado/routes.js'
import IndeterminateCheckbox from 'rscrpt/components/inputs/IndeterminateCheckbox.mjs'
import Tooltip, {
  positionFromString,
} from 'rscrpt/components/common/Tooltip/Tooltip.mjs'
import * as styles from './styles.module.css'

class Table extends React.Component {
  table = null
  scroll = null
  headEl = null
  tableWrapper = null
  scrollWrapper = null

  state = {
    hasScroll: false,
    hasEventListener: false,
  }

  constructor(props) {
    super(props)

    this.onResize = debounce(this.onResize.bind(this), 200)
  }

  onResize = () => {
    if (this.table && this.tableWrapper) {
      const { hasScroll } = this.state

      if (
        this.table.offsetWidth > this.tableWrapper.offsetWidth &&
        !hasScroll
      ) {
        this.setState(() => ({ hasScroll: true }))
      } else if (
        this.table.offsetWidth <= this.tableWrapper.offsetWidth &&
        hasScroll
      ) {
        this.setState(() => ({ hasScroll: false }))
      }
    }
  }

  verticalScrollHandler = (_event) => {
    if (this.headEl && this.scrollWrapper) {
      const {
        ctx: { dom },
      } = this.props

      if (dom.topOffset > 28) {
        this.headEl.style.transform = `translate(0,${dom.topOffset - 28}px)`
        this.headEl.style.background = '#ebeef1'
      } else {
        this.headEl.style.transform = 'translate(0,0)'
        this.headEl.style.background = 'transparent'
      }

      if (dom.showHorizontalScroll) {
        this.scrollWrapper.style.display = 'block'
      } else if (!dom.supportHidingScroll) {
        this.scrollWrapper.style.display = 'none'
      }
    }
  }

  horizontalScrollHandler = (event) => {
    if (this.scrollWrapper && this.tableWrapper) {
      if (event.target === this.tableWrapper) {
        this.scrollWrapper.scrollLeft = this.tableWrapper.scrollLeft
      } else {
        this.tableWrapper.scrollLeft = this.scrollWrapper.scrollLeft
      }
    }
  }

  addEvents = () => {
    const { hasEventListener } = this.state

    if (
      !hasEventListener &&
      this.scrollWrapper &&
      this.tableWrapper &&
      this.table &&
      this.scroll
    ) {
      const {
        ctx: { dom },
      } = this.props

      dom.onResize(this.onResize)
      dom.window.addEventListener('scroll', this.verticalScrollHandler, false)

      this.scrollWrapper.addEventListener(
        'scroll',
        this.horizontalScrollHandler,
        false
      )

      this.tableWrapper.addEventListener(
        'scroll',
        this.horizontalScrollHandler,
        false
      )

      let hasScroll
      if (this.table.offsetWidth > this.tableWrapper.offsetWidth) {
        hasScroll = true
      } else {
        hasScroll = false
      }

      this.scrollWrapper.style.height = `${dom.scrollbarHeight}px`
      this.scroll.style.width = `${this.table.offsetWidth}px`

      this.setState(() => ({
        hasEventListener: true,
        hasScroll,
      }))
    }
  }

  componentDidMount = () => this.addEvents()

  componentDidUpdate = () => {
    if (this.scrollWrapper && this.scroll && this.table) {
      const {
        ctx: { dom },
      } = this.props

      const { hasEventListener } = this.state

      if (hasEventListener) {
        this.onResize()

        this.scrollWrapper.style.height = `${dom.scrollbarHeight}px`
        this.scroll.style.width = `${this.table.offsetWidth}px`
      } else {
        this.addEvents()
      }

      if (dom.showHorizontalScroll) {
        this.scrollWrapper.style.display = 'block'
      } else if (!dom.supportHidingScroll) {
        this.scrollWrapper.style.display = 'none'
      }
    }
  }

  componentWillUnmount = () => {
    const {
      ctx: { dom },
    } = this.props
    const { hasEventListener } = this.state

    if (hasEventListener && this.scrollWrapper && this.tableWrapper) {
      dom.removeResizeHandler(this.onResize)
      dom.window.removeEventListener(
        'scroll',
        this.verticalScrollHandler,
        false
      )

      this.scrollWrapper.removeEventListener(
        'scroll',
        this.horizontalScrollHandler,
        false
      )

      this.tableWrapper.removeEventListener(
        'scroll',
        this.horizontalScrollHandler,
        false
      )
    }
  }

  loadMoreJobsHandler = () => {
    const { numberOfJobsDisplayed, bounds, filterByBounds } = this.props

    filterByBounds({
      limit: bounds.offset > 200 ? 50 : 30,
      offset: numberOfJobsDisplayed,
    })
  }

  get thead() {
    const {
      columns,
      customFields,
      reportFields,
      count,
      pickingForBulk,
      operationSet,
      emptyOperationSet,
      pickFilterOperationSet,
      ctx,
    } = this.props

    const theadCN = (c) =>
      classNames({
        [styles.headerCell]: true,
        [styles.headerCellShort]: c === 'scheduledAt',
      })

    const checkboxValue = () => {
      if (operationSet === null) {
        return 'unchecked'
      } else {
        if (operationSet.type === 'filter') {
          return operationSet.excludeUuids.length === 0
            ? 'checked'
            : 'indeterminate'
        } else {
          return operationSet.uuids.length === 0 ? 'unchecked' : 'indeterminate'
        }
      }
    }

    const onCheckboxChange = (value) => {
      if (value === 'checked') {
        pickFilterOperationSet()
      } else {
        emptyOperationSet()
      }
    }

    const columnTranslationKey = (columnName) => {
      if (columnName == 'client') {
        return 'client_or_site'
      } else if (columnName == 'clientExternalId') {
        return 'client_or_site_external_id'
      } else {
        return humps.decamelize(columnName)
      }
    }

    const countJobs = count.exact || count.approx || 0

    const ref = React.createRef()

    const columnHeader = (column, ctx) => {
      if (column === 'odometerM' && ctx.localizator.measurementSystem === 'imperial') {
        return 'odometer_m_imperial'
      } else {
        return humps.decamelize(column)
      }
    }

    return (
      <thead ref={(el) => (this.headEl = el)} className={styles.header}>
        <tr>
          <th className={`${styles.headerCell} ${styles.headerCellShort}`}>
            {pickingForBulk ? (
              <div className={styles.serialNoBulk}>
                <Tooltip
                  wrapperClassName="bulk-tooltip"
                  content={ctx.t('js.jobs.index.bulk.too_many_jobs')}
                  elementRef={ref}
                  position={positionFromString('bottom')}
                  enabled={
                    countJobs > 1000 && checkboxValue() !== 'indeterminate'
                  }
                >
                  <div ref={ref} className={"bulk-op__header-checkbox-wrapper"}>
                    <IndeterminateCheckbox
                      value={checkboxValue()}
                      onChange={onCheckboxChange}
                      wrapperClasses={{ 'bulk-op__header-checkbox': true }}
                      disabled={
                        (countJobs === 0 || countJobs > 1000) &&
                        checkboxValue() !== 'indeterminate'
                      }
                    />
                  </div>
                </Tooltip>
                {ctx.t('js.jobs.index.heading.serial_no')}
              </div>
            ) : (
              ctx.t('js.jobs.index.heading.serial_no')
            )}
          </th>

          {columns.jobFields.map((c, i) => (
            <th key={i} className={theadCN(c)}>
              {ctx.t(`js.jobs.index.heading.${columnTranslationKey(c)}`)}
            </th>
          ))}

          {[...columns.customFields, ...columns.reportFields].map((column) => {
            const field = [...customFields, ...reportFields].find(
              (f) => f.uuid === column
            )
            return (
              <th key={column} className={styles.headerCell}>
                {field ? field.name : ''}
              </th>
            )
          })}

          {columns.virtual
            .filter(
              (c) =>
                c !== 'odometerM' ||
                (c === 'odometerM' && ctx.features.flags.includes('tracking'))
            )
            .map((c, i) => {
              return (
                <th key={i} className={styles.headerCell}>
                  {ctx.t(`js.jobs.index.heading.${columnHeader(c, ctx)}`)}
                </th>
              )
            })}
        </tr>
      </thead>
    )
  }

  get colNumber() {
    const {
      columns: { jobFields, customFields, reportFields, virtual },
    } = this.props
    return (
      jobFields.length +
      customFields.length +
      reportFields.length +
      virtual.length +
      1
    )
  }

  get tbody() {
    const {
      columns,
      count,
      jobsRefreshing,
      numberOfJobsDisplayed,
      jobs,
      isFilterEmpty,
      pickingForBulk,
      pickForOperation,
      operationSet,
      ctx,
    } = this.props

    if (jobsRefreshing && numberOfJobsDisplayed === 0) {
      return null
    } else if (numberOfJobsDisplayed > 0) {
      const countJobs = count.exact || count.approx || 0

      return jobs.map((group, index) => (
        <DayJobs
          {...group}
          colNumber={this.colNumber}
          key={group.date}
          columns={columns}
          onJobEdit={this.props.onJobEdit}
          numberOfJobs={group.jobs.reduce(
            (acc, { jobs }) => acc + jobs.length,
            0
          )}
          lastDay={
            countJobs !== numberOfJobsDisplayed && index === jobs.length - 1
          }
          pickingForBulk={pickingForBulk}
          pickForOperation={pickForOperation}
          operationSet={operationSet}
        />
      ))
    } else if (numberOfJobsDisplayed === 0 && isFilterEmpty) {
      return (
        <tbody>
          <tr colSpan={this.colNumber}>
            <td colSpan={this.colNumber}>
              <div className={styles.emptyJobs}>
                {ctx.t('js.jobs.index.empty.jobs.no_jobs_added')}
                <p>
                  {ctx.t('js.jobs.index.empty.jobs.you_can')}
                  <Link wire={ctx.wire} pathname={jobsUpload}>
                    {ctx.t('js.jobs.index.empty.jobs.upload')}
                  </Link>
                  {ctx.t('js.jobs.index.empty.jobs.jobs_from_file')}
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      )
    } else {
      return (
        <tbody>
          <tr colSpan={this.colNumber} className={styles.emptyFilter}>
            <td colSpan={this.colNumber}>
              {ctx.t('js.jobs.index.empty.filter')}
            </td>
          </tr>
        </tbody>
      )
    }
  }

  get moreButton() {
    const { ctx, jobsRefreshing, numberOfJobsDisplayed, count, loadingMore } =
      this.props

    const countJobs = count.exact || count.approx || 0

    if (!jobsRefreshing && numberOfJobsDisplayed < countJobs) {
      const btnStyles = classNames({
        [styles.moreButton]: true,
        [styles.moreButtonLoading]: loadingMore,
      })

      return (
        <button
          onClick={this.loadMoreJobsHandler}
          className={btnStyles}
          type="button"
        >
          {ctx.t('js.components.job_list.show_more')}
        </button>
      )
    } else {
      return null
    }
  }

  render = () => {
    const { hasScroll } = this.state

    const {
      jobsRefreshing,
      loadingMore,
      numberOfJobsDisplayed,
      count,
      bounds,
    } = this.props

    const classes = classNames({
      'tests-table-container': true,
      [styles.container]: true,
      [styles.containerLoading]: jobsRefreshing,
    })

    const countJobs = count.exact || count.approx || 0

    return (
      <div className={classes}>
        <div
          className={styles.scrollWrapper}
          style={hasScroll ? {} : { display: 'none' }}
          ref={(el) => (this.scrollWrapper = el)}
        >
          <div className={styles.scroll} ref={(el) => (this.scroll = el)} />
        </div>

        <div className={styles.wrapper} ref={(el) => (this.tableWrapper = el)}>
          <InfiniteScroll
            hasMore={
              !loadingMore &&
              numberOfJobsDisplayed !== countJobs &&
              bounds.offset <= 200
            }
            loadMore={this.loadMoreJobsHandler}
          >
            <table
              className={`${styles.table} table`}
              ref={(el) => (this.table = el)}
            >
              {this.thead}
              {this.tbody}
            </table>
          </InfiniteScroll>
        </div>

        {this.moreButton}
      </div>
    )
  }
}

export default withContext(Table)
