

import * as El from "../../libraries/El.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Currency from "../../libraries/Currency.mjs";
import * as DomUtils from "../../DomUtils.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FieldErrors from "../common/FieldError/FieldErrors.mjs";
import * as Shared_Lib_Reducer from "../../shared/lib/Shared_Lib_Reducer.mjs";
import * as CurrencyInputModuleCss from "/home/runner/work/planado/planado/client/rescript/components/inputs/CurrencyInput.module.css";

var styles = CurrencyInputModuleCss;

function calcCursorPosition(displayedValue, nextDisplayedValue, cursorPosition, charRemovedWith, delimiter) {
  if (cursorPosition === 0) {
    return cursorPosition;
  }
  var delimitersCount = RCore.$$Array.keep((function (__x) {
            return Js_string.split("", __x);
          })(nextDisplayedValue), (function (v) {
          return v === delimiter;
        })).length;
  var requiredOffset;
  if (delimitersCount === 0) {
    requiredOffset = 0;
  } else {
    var lengthDiff = displayedValue.length - nextDisplayedValue.length | 0;
    if (charRemovedWith !== undefined) {
      if (charRemovedWith === "Delete") {
        switch (lengthDiff) {
          case 0 :
              requiredOffset = 1;
              break;
          case 2 :
              requiredOffset = -1;
              break;
          default:
            requiredOffset = 0;
        }
      } else {
        requiredOffset = lengthDiff !== 2 ? 0 : -1;
      }
    } else {
      switch (lengthDiff) {
        case -2 :
            requiredOffset = 1;
            break;
        case 0 :
            requiredOffset = -1;
            break;
        default:
          requiredOffset = 0;
      }
    }
  }
  return cursorPosition + requiredOffset | 0;
}

function currencySign$p(code) {
  return Utils.Translations.t("common.currency_signs." + code);
}

var mustBeWithoutCents$p = Utils.Translations.t("js.components.currency_input.must_be_without_cents");

var formatError$p = Utils.Translations.t("js.components.currency_input.format");

function inputReducer(param) {
  var action = param[1];
  var state = param[0];
  if (typeof action !== "object") {
    switch (action) {
      case "Focus" :
          return {
                  TAG: "Update",
                  _0: {
                    inputRef: state.inputRef,
                    displayedValue: state.displayedValue,
                    focused: true,
                    blinking: state.blinking,
                    blinkTimeoutId: state.blinkTimeoutId,
                    errors: state.errors,
                    backendErrors: state.backendErrors,
                    parsingResult: state.parsingResult
                  }
                };
      case "Blur" :
          return {
                  TAG: "Update",
                  _0: {
                    inputRef: state.inputRef,
                    displayedValue: state.displayedValue,
                    focused: false,
                    blinking: state.blinking,
                    blinkTimeoutId: state.blinkTimeoutId,
                    errors: state.errors,
                    backendErrors: state.backendErrors,
                    parsingResult: state.parsingResult
                  }
                };
      case "Blink" :
          return {
                  TAG: "UpdateWithSideEffect",
                  _0: {
                    inputRef: state.inputRef,
                    displayedValue: state.displayedValue,
                    focused: state.focused,
                    blinking: false,
                    blinkTimeoutId: state.blinkTimeoutId,
                    errors: state.errors,
                    backendErrors: state.backendErrors,
                    parsingResult: state.parsingResult
                  },
                  _1: (function (param) {
                      var send = param.send;
                      var timeoutId = param.state.blinkTimeoutId;
                      if (timeoutId !== undefined) {
                        clearTimeout(Caml_option.valFromOption(timeoutId));
                      }
                      var blinkTimeoutId = setTimeout((function () {
                              send({
                                    TAG: "SetBlinking",
                                    _0: false,
                                    _1: undefined
                                  });
                            }), 300);
                      send({
                            TAG: "SetBlinking",
                            _0: true,
                            _1: Caml_option.some(blinkTimeoutId)
                          });
                    })
                };
      
    }
  } else {
    switch (action.TAG) {
      case "SetBlinking" :
          return {
                  TAG: "Update",
                  _0: {
                    inputRef: state.inputRef,
                    displayedValue: state.displayedValue,
                    focused: state.focused,
                    blinking: action._0,
                    blinkTimeoutId: action._1,
                    errors: state.errors,
                    backendErrors: state.backendErrors,
                    parsingResult: state.parsingResult
                  }
                };
      case "SetDisplayedValue" :
          return {
                  TAG: "Update",
                  _0: {
                    inputRef: state.inputRef,
                    displayedValue: action._0,
                    focused: state.focused,
                    blinking: state.blinking,
                    blinkTimeoutId: state.blinkTimeoutId,
                    errors: state.errors,
                    backendErrors: state.backendErrors,
                    parsingResult: state.parsingResult
                  }
                };
      case "UpdateGlobalStateValue" :
          var arg = action._1;
          var f = action._0;
          return {
                  TAG: "SideEffect",
                  _0: (function (_event) {
                      f(arg);
                    })
                };
      case "MoveCursor" :
          var newCursorPosition = action._0;
          return {
                  TAG: "SideEffect",
                  _0: (function (param) {
                      var input = DomUtils.extractDomElementFromRef(param.state.inputRef);
                      if (input !== undefined) {
                        return DomUtils.Input.setSelectionRange(Caml_option.valFromOption(input), newCursorPosition, newCursorPosition);
                      }
                      
                    })
                };
      case "SetErrors" :
          return {
                  TAG: "Update",
                  _0: {
                    inputRef: state.inputRef,
                    displayedValue: state.displayedValue,
                    focused: state.focused,
                    blinking: state.blinking,
                    blinkTimeoutId: state.blinkTimeoutId,
                    errors: action._0,
                    backendErrors: state.backendErrors,
                    parsingResult: state.parsingResult
                  }
                };
      case "SetParsingResult" :
          return {
                  TAG: "Update",
                  _0: {
                    inputRef: state.inputRef,
                    displayedValue: state.displayedValue,
                    focused: state.focused,
                    blinking: state.blinking,
                    blinkTimeoutId: state.blinkTimeoutId,
                    errors: state.errors,
                    backendErrors: state.backendErrors,
                    parsingResult: action._0
                  }
                };
      
    }
  }
}

function CurrencyInput(Props) {
  var param = Props.wire;
  var currency = Props.currency;
  var useCurrencyFractionalUnit = Props.useCurrencyFractionalUnit;
  var value = Props.value;
  var onValueChange = Props.onValueChange;
  var disabled = Props.disabled;
  var classNameWrapper = Props.classNameWrapper;
  var backendErrors = Props.backendErrors;
  var ctx = param.ctx;
  var separator = ctx.localizator.numbers.separator;
  var delimiter = ctx.localizator.numbers.delimiter;
  var format = function (formatOnTypeOpt, parsedInput) {
    var formatOnType = formatOnTypeOpt !== undefined ? formatOnTypeOpt : false;
    return Currency.format(delimiter, separator, undefined, formatOnType, useCurrencyFractionalUnit, parsedInput);
  };
  var inputRef = React.useRef(null);
  var parsingResult = value !== undefined ? Currency.parse(value, useCurrencyFractionalUnit, ctx, false) : Currency.parse("", useCurrencyFractionalUnit, ctx, false);
  var displayedValue;
  if (parsingResult.TAG === "Ok") {
    displayedValue = format(undefined, parsingResult._0);
  } else {
    var parsedInput = parsingResult._0;
    displayedValue = typeof parsedInput !== "object" ? "" : format(undefined, parsedInput._0);
  }
  var initialState = {
    inputRef: inputRef,
    displayedValue: displayedValue,
    focused: false,
    blinking: false,
    blinkTimeoutId: undefined,
    errors: /* [] */0,
    backendErrors: backendErrors,
    parsingResult: parsingResult
  };
  var match = Shared_Lib_Reducer.use(inputReducer, initialState);
  var send = match[1];
  var state = match[0];
  var parsingResult$1 = state.parsingResult;
  var displayedValue$1 = state.displayedValue;
  React.useEffect((function () {
          if (Caml_obj.notequal(backendErrors, state.backendErrors)) {
            send({
                  TAG: "SetErrors",
                  _0: backendErrors
                });
          }
          
        }), [backendErrors]);
  var inputClass = disabled ? El.Cn.concat(styles.input, styles.disabled) : (
      state.blinking ? El.Cn.concat(styles.input, styles.blink) : styles.input
    );
  var placeholder = disabled || state.focused ? "" : (
      useCurrencyFractionalUnit ? "0" + separator + "00" : "0"
    );
  var onBlur = function (_evt) {
    send("Blur");
    if (parsingResult$1.TAG !== "Ok") {
      return send({
                  TAG: "SetErrors",
                  _0: /* [] */0
                });
    }
    var parsedInput = parsingResult$1._0;
    var formatted = format(undefined, parsedInput);
    var decimalString = useCurrencyFractionalUnit ? parsedInput.decimalString : parsedInput.integerPart;
    send({
          TAG: "SetDisplayedValue",
          _0: formatted
        });
    send({
          TAG: "UpdateGlobalStateValue",
          _0: onValueChange,
          _1: decimalString
        });
  };
  var match$1 = React.useState(function () {
        return 0;
      });
  var setKeyDownCursorPosition = match$1[1];
  var keyDownCursorPosition = match$1[0];
  var onKeyDown = function (_event) {
    var cursorPosition = RCore.$$Option.getOr(RCore.$$Option.map(DomUtils.extractDomElementFromRef(inputRef), DomUtils.Input.selectionStart), 0);
    setKeyDownCursorPosition(function (param) {
          return cursorPosition;
        });
  };
  var onChange = function (evt) {
    var cursorPosition = RCore.$$Option.getOr(RCore.$$Option.map(DomUtils.extractDomElementFromRef(inputRef), DomUtils.Input.selectionStart), 0);
    var value = El.getStringValueFromEvent(evt);
    var lengthDecreasedByOne = (displayedValue$1.length - value.length | 0) === 1;
    var deletePressed = lengthDecreasedByOne && keyDownCursorPosition === cursorPosition;
    var backspacePressed = lengthDecreasedByOne && (keyDownCursorPosition - cursorPosition | 0) === 1;
    var charRemovedWith = deletePressed ? "Delete" : (
        backspacePressed ? "Backspace" : undefined
      );
    var parsingResult = Currency.parse(value, useCurrencyFractionalUnit, ctx, true);
    send({
          TAG: "SetParsingResult",
          _0: parsingResult
        });
    if (value === "-") {
      var newCursorPosition = calcCursorPosition(displayedValue$1, value, cursorPosition, charRemovedWith, delimiter);
      send({
            TAG: "MoveCursor",
            _0: newCursorPosition
          });
      send({
            TAG: "SetDisplayedValue",
            _0: value
          });
      return send({
                  TAG: "SetErrors",
                  _0: /* [] */0
                });
    }
    if (parsingResult.TAG === "Ok") {
      var parsedInput = parsingResult._0;
      var nextDisplayedValue = format(true, parsedInput);
      if (displayedValue$1 === nextDisplayedValue) {
        send("Blink");
      }
      var newCursorPosition$1 = calcCursorPosition(displayedValue$1, nextDisplayedValue, cursorPosition, charRemovedWith, delimiter);
      send({
            TAG: "MoveCursor",
            _0: newCursorPosition$1
          });
      send({
            TAG: "SetDisplayedValue",
            _0: nextDisplayedValue
          });
      send({
            TAG: "UpdateGlobalStateValue",
            _0: onValueChange,
            _1: parsedInput.decimalString
          });
      return send({
                  TAG: "SetErrors",
                  _0: /* [] */0
                });
    }
    var tmp = parsingResult._0;
    if (typeof tmp !== "object") {
      switch (tmp) {
        case "EmptyString" :
            send({
                  TAG: "SetDisplayedValue",
                  _0: ""
                });
            return send({
                        TAG: "UpdateGlobalStateValue",
                        _0: onValueChange,
                        _1: ""
                      });
        case "ParseNumberStringError" :
        case "InvalidFormat" :
            break;
        
      }
    } else {
      send("Blink");
      send({
            TAG: "MoveCursor",
            _0: cursorPosition
          });
      return send({
                  TAG: "SetErrors",
                  _0: {
                    hd: mustBeWithoutCents$p(ctx),
                    tl: /* [] */0
                  }
                });
    }
    send("Blink");
    send({
          TAG: "MoveCursor",
          _0: cursorPosition
        });
    send({
          TAG: "SetErrors",
          _0: {
            hd: formatError$p(ctx),
            tl: /* [] */0
          }
        });
  };
  return React.createElement("div", {
              className: El.Cn.concatOpt(styles.wrapper, classNameWrapper)
            }, React.createElement("div", {
                  className: styles.currencySign
                }, currencySign$p(currency)(ctx)), React.createElement("input", {
                  ref: inputRef,
                  className: inputClass,
                  disabled: disabled,
                  name: "currencyInput",
                  placeholder: placeholder,
                  value: displayedValue$1,
                  onKeyDown: onKeyDown,
                  onFocus: (function (param) {
                      send("Focus");
                    }),
                  onBlur: onBlur,
                  onChange: onChange
                }), React.createElement(FieldErrors.make, {
                  errors: Core__List.toArray(state.errors)
                }));
}

var CurrencyValue;

var make = CurrencyInput;

export {
  CurrencyValue ,
  make ,
}
/* styles Not a pure module */
