

import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Context from "../../context/Context.mjs";
import * as AppContext from "../../context/AppContext.mjs";
import * as HeaderTypes from "../header/HeaderTypes.mjs";
import * as NotFoundModuleCss from "/home/runner/work/planado/planado/client/rescript/components/errors/NotFound.module.css";

var styles = NotFoundModuleCss;

var title$p = Utils.Translations.tr("js.errors.oops");

var text$p = Utils.Translations.tr("js.errors.something_went_wrong_or_that_page_doesn_t_exist_yet");

var link$p = Utils.Translations.tr("js.errors.go_to_main_page");

function NotFound(Props) {
  var match = AppContext.useWire();
  var navigate = match.navigate;
  var ctx = match.ctx;
  var menu = HeaderTypes.Menu.make(ctx);
  var goToRoot = function ($$event) {
    $$event.preventDefault();
    if (!Context.authorized(ctx)) {
      return navigate(undefined, "Login");
    }
    var route = HeaderTypes.Menu.defaultRoute(menu);
    if (route !== undefined) {
      return navigate(undefined, route);
    }
    
  };
  return React.createElement("div", {
              className: styles.wrapper
            }, React.createElement("div", {
                  className: styles.code
                }, "404"), React.createElement("div", {
                  className: styles.oops
                }, title$p(ctx)), React.createElement("div", {
                  className: styles.separator
                }), React.createElement("div", {
                  className: styles.text
                }, text$p(ctx)), React.createElement("a", {
                  className: styles.link,
                  href: "javascript:;",
                  onClick: goToRoot
                }, link$p(ctx)));
}

var make = NotFound;

export {
  make ,
}
/* styles Not a pure module */
