

import * as Utils from "../../../../../../../../../utils/Utils.mjs";
import * as AppContext from "../../../../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_DND from "../../../../../../../lib/Schedule_DND.mjs";
import * as Assignee_Types from "../../../../../../../../../types/Assignee_Types.mjs";
import * as MessengerTypes from "../../../../../../../../messenger/MessengerTypes.mjs";
import * as Schedule_State from "../../../../../../../lib/state/Schedule_State.mjs";
import * as MultipleAssignee from "../../../../../../../../../types/MultipleAssignee.mjs";
import * as WeekTimeline_State from "../../../../../lib/state/WeekTimeline_State.mjs";
import * as Schedule_StateTypes from "../../../../../../../lib/state/Schedule_StateTypes.mjs";
import * as Schedule_UtilsTimeline from "../../../../../../../lib/utils/Schedule_UtilsTimeline.mjs";
import * as WeekTimeline_Constants from "../../../../../lib/WeekTimeline_Constants.mjs";
import * as WeekTimeline_ActiveDayUtils from "../../../lib/WeekTimeline_ActiveDayUtils.mjs";
import * as WeekTimeline_DragLayerActions from "../../../../drag_layer/WeekTimeline_DragLayerActions.mjs";

function use(param) {
  var sizes = param.sizes;
  var elements = param.elements;
  var period = param.period;
  var bar = param.bar;
  var match = AppContext.useWire();
  var messenger = match.subscriptions.messenger;
  var ctx = match.ctx;
  var allAssignees = ctx.allAssignees;
  var dispatch = Schedule_State.useDispatch();
  var timelineSizes = WeekTimeline_State.useSizes();
  var onDrop = function (param) {
    var contentEl = Schedule_UtilsTimeline.contentEl(elements.viewportEl);
    if (contentEl === undefined) {
      return ;
    }
    var payload = Schedule_DND.DND.Context.payload(param.payload);
    var assignees = payload.assignees;
    var dragPreviewOffset = WeekTimeline_DragLayerActions.fixDragPreviewOffset(param.dragPreviewOffset, param.mouseOffset, timelineSizes, payload);
    var coordinate = Schedule_UtilsTimeline.ConvertPoint.fromPageToNode(Caml_option.valFromOption(contentEl), dragPreviewOffset);
    var scheduledAt = WeekTimeline_ActiveDayUtils.FindByCoordinateInTimeline.scheduledAt(period, sizes, coordinate);
    var a = MultipleAssignee.PossibleAssignee.findByUuid(Assignee_Types.Assignee.uuid(bar), allAssignees);
    var transferredAssignees;
    if (a !== undefined) {
      var tmp;
      tmp = a.TAG === "Team" ? ({
            TAG: "Team",
            _0: a._0
          }) : ({
            TAG: "Worker",
            _0: {
              TAG: "Edit",
              _0: a._0
            }
          });
      transferredAssignees = MultipleAssignee.setMainAssignee(assignees, tmp);
    } else {
      transferredAssignees = assignees;
    }
    if (MultipleAssignee.workerNumberValid(transferredAssignees)) {
      return dispatch(Schedule_StateTypes.Action.jobSubmitted(payload.uuid, Caml_option.some(scheduledAt), transferredAssignees));
    } else {
      return messenger.trigger({
                  TAG: "Show",
                  _0: MessengerTypes.Message.make(undefined, "Danger", {
                        TAG: "Text",
                        _0: Utils.Translations.t("js.schedule.errors.too_many_assignees")(ctx)
                      })
                });
    }
  };
  return Schedule_DND.DND.Droppable.use(undefined, undefined, undefined, [
              WeekTimeline_Constants.dndScheduledJob,
              WeekTimeline_Constants.dndUnscheduledJob,
              WeekTimeline_Constants.dndUnassignedJob
            ], onDrop, undefined);
}

export {
  use ,
}
/* Utils Not a pure module */
