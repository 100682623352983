

import * as Hint from "../../../../../../common/Hint/Hint.mjs";
import * as Utils from "../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../../../../../common/Optional.mjs";
import * as SkillsSelect from "../../../../../../inputs/SkillsSelect.mjs";
import * as JobEdit_PossibleAssigneesUtils from "../../../../lib/utils/JobEdit_PossibleAssigneesUtils.mjs";
import * as JobForm_BodySkillsBlockModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/edit/form/components/body/skills_block/JobForm_BodySkillsBlock.module.css";

var skills$p = Utils.Translations.tr("js.jobs.labels.skills");

var skillsHint$p = Utils.Translations.t("js.jobs.hints.skills");

var skillsSelectPlaceholder$p = Utils.Translations.t("js.components.skills_select.placeholder");

var noAssigneesWithSkills$p = Utils.Translations.tr("js.jobs.comments.no_assignees_with_skills");

var styles = JobForm_BodySkillsBlockModuleCss;

function JobForm_BodySkillsBlock(Props) {
  var ctx = Props.ctx;
  var template = Props.template;
  var possibleAssignees = Props.possibleAssignees;
  var skillUuids = Props.skillUuids;
  var setSkills = Props.setSkills;
  var status = Props.status;
  var disabledOpt = Props.disabled;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  if (template === undefined) {
    return null;
  }
  var jobFinished = status === "Finished";
  var availableSkills = ctx.availableSkills.map(function (s) {
        return {
                uuid: s.uuid,
                name: s.name
              };
      });
  var hasPerfectAssignee = JobEdit_PossibleAssigneesUtils.hasAssigneeWithRequiredSkills(possibleAssignees, skillUuids);
  var showNoAssigneesWithSkillsWarning = !jobFinished && !hasPerfectAssignee;
  return React.createElement("div", {
              className: "pd-form-group pd-form-group-full"
            }, React.createElement("label", {
                  className: "pd-label"
                }, skills$p(ctx)), React.createElement(Hint.make, {
                  content: skillsHint$p(ctx)
                }), React.createElement(SkillsSelect.make, {
                  id: "job_skills_select",
                  inputName: "input-job_skills_select",
                  selectedSkillUuids: skillUuids,
                  onChange: setSkills,
                  availableSkills: availableSkills,
                  placeholder: skillsSelectPlaceholder$p(ctx),
                  disabled: disabled
                }), React.createElement(Optional.make, {
                  show: showNoAssigneesWithSkillsWarning,
                  children: React.createElement("span", {
                        className: styles.message
                      }, noAssigneesWithSkills$p(ctx))
                }));
}

var make = JobForm_BodySkillsBlock;

var $$default = JobForm_BodySkillsBlock;

export {
  make ,
  $$default as default,
}
/* skills' Not a pure module */
