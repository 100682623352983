

import * as Url from "../../../bindings/url/Url.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as PromiseF from "../../../libraries/PromiseF.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as Billing_Types from "./Billing_Types.mjs";
import * as Backend_Headers from "../../../libraries/backend/Backend_Headers.mjs";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";

function sendPriceRequest(wire, comment) {
  return Backend.post(undefined, undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "Billing",
                VAL: "RequestPricing"
              }
            }, {
              NAME: "Obj",
              VAL: {
                comment: comment
              }
            });
}

var RequestPrice = {
  sendPriceRequest: sendPriceRequest
};

function toRequestValue(result) {
  if (result !== undefined) {
    if (result === "Success") {
      return "success";
    } else {
      return "insufficient_funds";
    }
  }
  
}

var FakeResult = {
  toRequestValue: toRequestValue
};

var $$Request = {
  FakeResult: FakeResult
};

var $$Error = {};

var decodeError = JsonDecode.object(function (field) {
      return field.required("message", JsonDecode.string);
    });

function decode(response) {
  if (response.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
  var match = response._0;
  if (typeof match !== "object") {
    return {
            TAG: "Error",
            _0: "CallError"
          };
  }
  if (match.TAG !== "HTTPError") {
    return {
            TAG: "Error",
            _0: "CallError"
          };
  }
  var match$1 = match._0;
  var tmp = match$1[0];
  if (typeof tmp === "object") {
    return {
            TAG: "Error",
            _0: "CallError"
          };
  }
  if (tmp !== "PaymentRequired") {
    return {
            TAG: "Error",
            _0: "CallError"
          };
  }
  var json;
  try {
    json = JSON.parse(match$1[1]);
  }
  catch (raw_parseError){
    var parseError = Caml_js_exceptions.internalToOCamlException(raw_parseError);
    return {
            TAG: "Error",
            _0: {
              TAG: "ParseError",
              _0: parseError
            }
          };
  }
  var message = Json_Decode$JsonCombinators.decode(json, decodeError);
  if (message.TAG === "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PaymentError",
              _0: message._0
            }
          };
  } else {
    return {
            TAG: "Error",
            _0: {
              TAG: "JSONDecodeError",
              _0: message._0
            }
          };
  }
}

var EmptyResponse = {
  $$Error: $$Error,
  decodeError: decodeError,
  decode: decode
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              availablePlans: field.required("availablePlans", JsonDecode.array(Billing_Types.Plan.decode)),
              planTypes: field.required("planTypes", JsonDecode.array(Billing_Types.PlanType.decode)),
              currentSeats: field.required("currentSeats", JsonDecode.$$int),
              currentOrder: field.required("order", JsonDecode.option(Billing_Types.Order.decode)),
              order: field.required("order", JsonDecode.option(Billing_Types.Order.decode)),
              currentPlan: field.required("currentPlan", JsonDecode.option(Billing_Types.Plan.decode)),
              subscription: field.required("subscription", JsonDecode.option(Billing_Types.Subscription.decode)),
              unpaidBill: field.required("unpaidBill", JsonDecode.option(Billing_Types.UnpaidBill.decode)),
              latestPayments: field.required("latestPayments", JsonDecode.array(Billing_Types.Payment.decode)),
              canChangePlan: field.required("canChangePlan", JsonDecode.bool),
              card: field.required("card", JsonDecode.option(Billing_Types.Card.decode)),
              availablePaymentMethods: field.required("availablePaymentMethods", JsonDecode.array(Billing_Types.PaymentMethod.decode)),
              cryptoChillSettings: field.required("cryptochill", JsonDecode.option(Billing_Types.CryptoChillSettings.decode)),
              paddleSettings: field.required("paddle", JsonDecode.option(Billing_Types.PaddleSettings.decode)),
              paddleSubscription: field.required("paddleSubscription", JsonDecode.option(Billing_Types.PaddleSubscription.decode))
            };
    });

function show(wire) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Billing",
                    VAL: "Index"
                  }
                }), decode$1);
}

function create(fakeResult, wire, order) {
  return PromiseF.map(Backend.post(undefined, undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Billing",
                    VAL: "Index"
                  }
                }, {
                  NAME: "Obj",
                  VAL: {
                    plan_type_id: order.planTypeId,
                    months_cycle: Billing_Types.Order.Cycle.toInt(order.monthsCycle),
                    seats: order.paidSeats,
                    fake_result: toRequestValue(fakeResult)
                  }
                }), decode);
}

function updatePaddleSubscription(wire, planUuid, paddleSubscriptionId, seats) {
  return PromiseF.map(Backend.patch(undefined, undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Billing",
                    VAL: "PaddleSubscription"
                  }
                }, {
                  NAME: "Obj",
                  VAL: {
                    plan_uuid: planUuid,
                    paddle_subscription_id: paddleSubscriptionId,
                    seats: seats
                  }
                }), decode);
}

function create$1(wire) {
  return Backend.decode(Backend.post(undefined, undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Billing",
                    VAL: {
                      NAME: "Cards",
                      VAL: "Index"
                    }
                  }
                }, {
                  NAME: "Obj",
                  VAL: {}
                }), Billing_Types.Card.decode);
}

function show$1(wire, uuid) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Billing",
                    VAL: {
                      NAME: "Cards",
                      VAL: {
                        NAME: "Show",
                        VAL: uuid
                      }
                    }
                  }
                }), Billing_Types.Card.decode);
}

function $$delete(wire, uuid) {
  return Backend.$$delete(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "Billing",
                VAL: {
                  NAME: "Cards",
                  VAL: {
                    NAME: "Show",
                    VAL: uuid
                  }
                }
              }
            });
}

var Card = {
  create: create$1,
  show: show$1,
  $$delete: $$delete
};

var decode$2 = JsonDecode.object(function (field) {
      return {
              bill: field.required("bill", JsonDecode.option(Billing_Types.Bill.decode)),
              checkoutUrl: field.required("checkoutUrl", JsonDecode.option(Url.decode))
            };
    });

var $$Response = {
  decode: decode$2
};

function create$2(wire) {
  return function (order) {
    return Backend.decode(Backend.post(undefined, undefined, undefined, undefined, wire, {
                    NAME: "API",
                    VAL: {
                      NAME: "Billing",
                      VAL: "Wizard"
                    }
                  }, {
                    NAME: "Obj",
                    VAL: {
                      plan_type_id: order.planTypeId,
                      months_cycle: Billing_Types.Order.Cycle.toInt(order.monthsCycle),
                      seats: order.paidSeats,
                      organization_name: Js_null.fromOption(order.organizationName),
                      billing_address: Js_null.fromOption(order.billingAddress),
                      payment_method: Billing_Types.PaymentMethod.toString(order.paymentMethod)
                    }
                  }), decode$2);
  };
}

function preview(wire) {
  return function (order) {
    return Backend.decode(Backend.post(Backend_Headers.contentJsonOverridden, undefined, undefined, undefined, wire, {
                    NAME: "API",
                    VAL: {
                      NAME: "Billing",
                      VAL: "Preview"
                    }
                  }, {
                    NAME: "Obj",
                    VAL: {
                      plan_type_id: order.planTypeId,
                      months_cycle: Billing_Types.Order.Cycle.toInt(order.monthsCycle),
                      seats: order.paidSeats,
                      organization_name: Js_null.fromOption(order.organizationName),
                      billing_address: Js_null.fromOption(order.billingAddress),
                      payment_method: Billing_Types.PaymentMethod.toString(order.paymentMethod)
                    }
                  }), decode$2);
  };
}

var Order = {
  $$Response: $$Response,
  create: create$2,
  preview: preview
};

function pay(fakeResult, wire, uuid) {
  return PromiseF.map(Backend.patch(undefined, undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Billing",
                    VAL: {
                      NAME: "Bills",
                      VAL: {
                        NAME: "Pay",
                        VAL: uuid
                      }
                    }
                  }
                }, {
                  NAME: "Obj",
                  VAL: {
                    fake_result: toRequestValue(fakeResult)
                  }
                }), decode);
}

var Bills = {
  pay: pay
};

var Subscription_Response = {};

var Subscription = {
  $$Response: Subscription_Response,
  show: show,
  create: create,
  updatePaddleSubscription: updatePaddleSubscription
};

export {
  RequestPrice ,
  $$Request ,
  EmptyResponse ,
  Subscription ,
  Card ,
  Order ,
  Bills ,
}
/* decodeError Not a pure module */
