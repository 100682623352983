

import * as El from "../../../../../../libraries/El.mjs";
import * as Fun from "../../../../../../libraries/Fun.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Radio from "../../../../../inputs/Radio.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as Optional from "../../../../../common/Optional.mjs";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JobIndexFilter_Reset from "../../common/JobIndexFilter_Reset.mjs";
import * as JobIndexFilter_Wrapper from "../../common/JobIndexFilter_Wrapper.mjs";
import * as JobIndexFilter_DatesInputs from "../../common/JobIndexFilter_DatesInputs.mjs";
import * as JobIndexFilter_CustomWrapper from "../custom/JobIndexFilter_CustomWrapper.mjs";
import * as RadioModuleCss from "/home/runner/work/planado/planado/client/rescript/components/inputs/Radio.module.css";
import * as JobIndexFilter_DatesModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/index/job_index_filter/components/filters/JobIndexFilter_Dates.module.css";

var styles = JobIndexFilter_DatesModuleCss;

var enteredDate$p = Utils.Translations.tr("js.jobs.index.filters.dates.entered_date");

var enteredDatePredefined$p = Utils.Translations.tr("js.jobs.index.filters.dates.entered_date_predefined");

var filled$p = Utils.Translations.tr("js.jobs.index.filters.dates.filled.name");

var notFilled$p = Utils.Translations.tr("js.jobs.index.filters.dates.not_filled");

var any$p = Utils.Translations.t("js.jobs.index.filters.dates.filled.any");

var eql$p = Utils.Translations.t("js.jobs.index.filters.dates.filled.eql");

var eqlWithDate$p = Utils.Translations.txr("js.jobs.index.filters.dates.filled.eql_with_date");

var earlier$p = Utils.Translations.t("js.jobs.index.filters.dates.filled.earlier");

var earlierWithDate$p = Utils.Translations.txr("js.jobs.index.filters.dates.filled.earlier_with_date");

var later$p = Utils.Translations.t("js.jobs.index.filters.dates.filled.later");

var laterWithDate$p = Utils.Translations.txr("js.jobs.index.filters.dates.filled.later_with_date");

var daysAgo$p = Utils.Translations.tx("js.jobs.index.filters.dates.filled.days_ago");

var daysAgoWithDays$p = Utils.Translations.tx("js.jobs.index.filters.dates.filled.days_ago_with_days");

var interval$p = Utils.Translations.t("js.jobs.index.filters.dates.filled.interval.name");

var during$p = Utils.Translations.t("js.jobs.index.filters.dates.filled.interval.during");

var duringDays$p = Utils.Translations.tx("js.jobs.index.filters.dates.filled.interval.during_days");

var duringDaysWithDays$p = Utils.Translations.tx("js.jobs.index.filters.dates.filled.interval.during_days_with_days");

var intervalStartingDescription$p = Utils.Translations.tx("js.jobs.index.filters.dates.filled.interval.starting.description");

var intervalInDescription$p = Utils.Translations.tx("js.jobs.index.filters.dates.filled.interval.in.description");

var intervalStarting$p = Utils.Translations.tr("js.jobs.index.filters.dates.filled.interval.starting.name");

var intervalStartingDays$p = Utils.Translations.tx("js.jobs.index.filters.dates.filled.interval.starting.days_from_now");

var intervalIn$p = Utils.Translations.tr("js.jobs.index.filters.dates.filled.interval.in.name");

var intervalInDays$p = Utils.Translations.tx("js.jobs.index.filters.dates.filled.interval.in.days_from_now");

var intervalSpecificDescription$p = Utils.Translations.txr("js.jobs.index.filters.dates.filled.interval.specific_description");

var intervalHint$p = Utils.Translations.tr("js.jobs.index.filters.dates.filled.interval.hint");

var styles$1 = RadioModuleCss;

function JobIndexFilter_Dates$Radio(Props) {
  var onChange = Props.onChange;
  var checked = Props.checked;
  var wrapperClass = Props.wrapperClass;
  var inputClass = Props.inputClass;
  var disabled = Props.disabled;
  var labelText = Props.labelText;
  var children = Props.children;
  var tmp = {
    onChange: onChange,
    checked: checked,
    wrapperClass: wrapperClass,
    labelClass: styles$1.radio,
    disabled: disabled,
    children: RCore.$$Option.getOr(children, null)
  };
  if (labelText !== undefined) {
    tmp.labelText = labelText;
  }
  if (inputClass !== undefined) {
    tmp.inputClass = inputClass;
  }
  return React.createElement(Radio.make, tmp);
}

function make(value) {
  if (value === undefined) {
    return "Empty";
  }
  if (typeof value !== "object") {
    if (value === "NotFilled") {
      return "NotFilled";
    } else {
      return "Any";
    }
  }
  switch (value.TAG) {
    case "Eql" :
        return "Eql";
    case "Earlier" :
        if (value._0.TAG === "Relative") {
          return "EarlierRelative";
        } else {
          return "EarlierSpecific";
        }
    case "Later" :
        if (value._0.TAG === "Relative") {
          return "LaterRelative";
        } else {
          return "LaterSpecific";
        }
    case "Interval" :
        switch (value._0.TAG) {
          case "Starting" :
              return "IntervalStarting";
          case "In" :
              return "IntervalIn";
          case "Specific" :
              return "IntervalSpecific";
          
        }
    
  }
}

function activeSections(field) {
  var exit = 0;
  switch (field) {
    case "Empty" :
        return {
                noActive: false,
                filled: false,
                notFilled: false,
                any: false,
                eql: false,
                earlier: false,
                later: false,
                interval: false
              };
    case "NotFilled" :
        return {
                noActive: false,
                filled: false,
                notFilled: true,
                any: false,
                eql: false,
                earlier: false,
                later: false,
                interval: false
              };
    case "Any" :
        return {
                noActive: false,
                filled: true,
                notFilled: false,
                any: true,
                eql: false,
                earlier: false,
                later: false,
                interval: false
              };
    case "Eql" :
        return {
                noActive: false,
                filled: true,
                notFilled: false,
                any: false,
                eql: true,
                earlier: false,
                later: false,
                interval: false
              };
    case "EarlierRelative" :
    case "EarlierSpecific" :
        exit = 1;
        break;
    case "LaterRelative" :
    case "LaterSpecific" :
        exit = 2;
        break;
    default:
      return {
              noActive: false,
              filled: true,
              notFilled: false,
              any: false,
              eql: false,
              earlier: false,
              later: false,
              interval: true
            };
  }
  switch (exit) {
    case 1 :
        return {
                noActive: false,
                filled: true,
                notFilled: false,
                any: false,
                eql: false,
                earlier: true,
                later: false,
                interval: false
              };
    case 2 :
        return {
                noActive: false,
                filled: true,
                notFilled: false,
                any: false,
                eql: false,
                earlier: false,
                later: true,
                interval: false
              };
    
  }
}

function JobIndexFilter_Dates$Range(Props) {
  var start = Props.start;
  var range = Props.range;
  var wire = AppContext.useWire();
  var finish = Locale.T.nextDays(range)(start);
  var format = function (v) {
    return Locale.T.fmtM("Date", wire)(v);
  };
  return React.createElement(React.Fragment, undefined, format(start), El.ndash, format(finish));
}

function JobIndexFilter_Dates$SelectedValue(Props) {
  var value = Props.value;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var format = Locale.T.fmtM("Date", wire);
  var tmp;
  if (value !== undefined) {
    if (typeof value !== "object") {
      tmp = value === "NotFilled" ? notFilled$p(ctx) : any$p(ctx);
    } else {
      switch (value.TAG) {
        case "Eql" :
            tmp = eqlWithDate$p({
                  date: format(value._0)
                }, ctx);
            break;
        case "Earlier" :
            var date = value._0;
            tmp = date.TAG === "Relative" ? Utils.Translations.concat([
                    earlier$p(ctx),
                    daysAgoWithDays$p({
                          count: date._0
                        }, ctx)
                  ], ctx) : earlierWithDate$p({
                    date: format(date._0)
                  }, ctx);
            break;
        case "Later" :
            var date$1 = value._0;
            tmp = date$1.TAG === "Relative" ? Utils.Translations.concat([
                    later$p(ctx),
                    daysAgoWithDays$p({
                          count: date$1._0
                        }, ctx)
                  ], ctx) : laterWithDate$p({
                    date: format(date$1._0)
                  }, ctx);
            break;
        case "Interval" :
            var match = value._0;
            switch (match.TAG) {
              case "Starting" :
                  var match$1 = match._0;
                  var range = match$1[1];
                  var from = match$1[0];
                  var start = Locale.T.prevDays(from)(Locale.T.now(wire));
                  tmp = React.createElement("div", undefined, Utils.Translations.concat([
                            intervalStartingDescription$p({
                                  count: from
                                }, ctx),
                            duringDaysWithDays$p({
                                  count: range
                                }, ctx)
                          ], ctx), React.createElement("br", undefined), "(", React.createElement(JobIndexFilter_Dates$Range, {
                            start: start,
                            range: range
                          }), ")");
                  break;
              case "In" :
                  var match$2 = match._0;
                  var range$1 = match$2[1];
                  var from$1 = match$2[0];
                  var start$1 = Locale.T.nextDays(from$1)(Locale.T.now(wire));
                  tmp = React.createElement("div", undefined, Utils.Translations.concat([
                            intervalInDescription$p({
                                  count: from$1
                                }, ctx),
                            duringDaysWithDays$p({
                                  count: range$1
                                }, ctx)
                          ], ctx), React.createElement("br", undefined), "(", React.createElement(JobIndexFilter_Dates$Range, {
                            start: start$1,
                            range: range$1
                          }), ")");
                  break;
              case "Specific" :
                  var match$3 = match._0;
                  tmp = intervalSpecificDescription$p({
                        from: format(match$3[0]),
                        to: format(match$3[1])
                      }, ctx);
                  break;
              
            }
            break;
        
      }
    }
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: "j-filter__selected"
            }, tmp);
}

function JobIndexFilter_Dates$Wrapper(Props) {
  var toggleFilter = Props.toggleFilter;
  var label = Props.label;
  var show = Props.show;
  var onResetClick = Props.onResetClick;
  var hasValue = Props.hasValue;
  var renderSelected = Props.renderSelected;
  var leftButton = Props.leftButton;
  var rightButton = Props.rightButton;
  var disabled = Props.disabled;
  var predefined = Props.predefined;
  var children = Props.children;
  if (predefined) {
    return React.createElement(JobIndexFilter_Wrapper.make, {
                toggleFilter: toggleFilter,
                show: show,
                label: label,
                children: null
              }, React.createElement(Optional.make, {
                    show: hasValue,
                    children: React.createElement(JobIndexFilter_Reset.make, {
                          onResetClick: onResetClick
                        })
                  }), React.createElement("div", {
                    className: El.Cn.concat("j-filter__list", styles.predefinedContainer)
                  }, children), renderSelected);
  } else {
    return React.createElement(JobIndexFilter_CustomWrapper.make, {
                toggleFilter: toggleFilter,
                onResetClick: onResetClick,
                renderSelected: renderSelected,
                label: label,
                show: show,
                hasValue: hasValue,
                leftButton: leftButton,
                rightButton: rightButton,
                disabled: disabled,
                children: children
              });
  }
}

var $$default = {
  eqlValue: undefined,
  earlierRelative: 7,
  earlierSpecific: undefined,
  laterRelative: 7,
  laterSpecific: undefined,
  intervalStarting: [
    5,
    7
  ],
  intervalIn: [
    7,
    5
  ],
  intervalSpecific: [
    undefined,
    undefined
  ]
};

function makeWithDate(rest, value) {
  if (value === undefined) {
    return rest;
  }
  if (typeof value !== "object") {
    return rest;
  }
  switch (value.TAG) {
    case "Eql" :
        return {
                eqlValue: Caml_option.some(value._0),
                earlierRelative: rest.earlierRelative,
                earlierSpecific: rest.earlierSpecific,
                laterRelative: rest.laterRelative,
                laterSpecific: rest.laterSpecific,
                intervalStarting: rest.intervalStarting,
                intervalIn: rest.intervalIn,
                intervalSpecific: rest.intervalSpecific
              };
    case "Earlier" :
        var v = value._0;
        if (v.TAG === "Relative") {
          return {
                  eqlValue: rest.eqlValue,
                  earlierRelative: v._0,
                  earlierSpecific: rest.earlierSpecific,
                  laterRelative: rest.laterRelative,
                  laterSpecific: rest.laterSpecific,
                  intervalStarting: rest.intervalStarting,
                  intervalIn: rest.intervalIn,
                  intervalSpecific: rest.intervalSpecific
                };
        } else {
          return {
                  eqlValue: rest.eqlValue,
                  earlierRelative: rest.earlierRelative,
                  earlierSpecific: Caml_option.some(v._0),
                  laterRelative: rest.laterRelative,
                  laterSpecific: rest.laterSpecific,
                  intervalStarting: rest.intervalStarting,
                  intervalIn: rest.intervalIn,
                  intervalSpecific: rest.intervalSpecific
                };
        }
    case "Later" :
        var v$1 = value._0;
        if (v$1.TAG === "Relative") {
          return {
                  eqlValue: rest.eqlValue,
                  earlierRelative: rest.earlierRelative,
                  earlierSpecific: rest.earlierSpecific,
                  laterRelative: v$1._0,
                  laterSpecific: rest.laterSpecific,
                  intervalStarting: rest.intervalStarting,
                  intervalIn: rest.intervalIn,
                  intervalSpecific: rest.intervalSpecific
                };
        } else {
          return {
                  eqlValue: rest.eqlValue,
                  earlierRelative: rest.earlierRelative,
                  earlierSpecific: rest.earlierSpecific,
                  laterRelative: rest.laterRelative,
                  laterSpecific: Caml_option.some(v$1._0),
                  intervalStarting: rest.intervalStarting,
                  intervalIn: rest.intervalIn,
                  intervalSpecific: rest.intervalSpecific
                };
        }
    case "Interval" :
        var v$2 = value._0;
        switch (v$2.TAG) {
          case "Starting" :
              var match = v$2._0;
              return {
                      eqlValue: rest.eqlValue,
                      earlierRelative: rest.earlierRelative,
                      earlierSpecific: rest.earlierSpecific,
                      laterRelative: rest.laterRelative,
                      laterSpecific: rest.laterSpecific,
                      intervalStarting: [
                        match[0],
                        match[1]
                      ],
                      intervalIn: rest.intervalIn,
                      intervalSpecific: rest.intervalSpecific
                    };
          case "In" :
              var v$3 = v$2._0;
              return {
                      eqlValue: rest.eqlValue,
                      earlierRelative: rest.earlierRelative,
                      earlierSpecific: rest.earlierSpecific,
                      laterRelative: rest.laterRelative,
                      laterSpecific: rest.laterSpecific,
                      intervalStarting: rest.intervalStarting,
                      intervalIn: [
                        v$3[0],
                        v$3[1]
                      ],
                      intervalSpecific: rest.intervalSpecific
                    };
          case "Specific" :
              var v$4 = v$2._0;
              return {
                      eqlValue: rest.eqlValue,
                      earlierRelative: rest.earlierRelative,
                      earlierSpecific: rest.earlierSpecific,
                      laterRelative: rest.laterRelative,
                      laterSpecific: rest.laterSpecific,
                      intervalStarting: rest.intervalStarting,
                      intervalIn: rest.intervalIn,
                      intervalSpecific: [
                        Caml_option.some(v$4[0]),
                        Caml_option.some(v$4[1])
                      ]
                    };
          
        }
    
  }
}

function JobIndexFilter_Dates(Props) {
  var predefined = Props.predefined;
  var activeView = Props.activeView;
  var label = Props.label;
  var resetFilter = Props.resetFilter;
  var toggleFilter = Props.toggleFilter;
  var filterByField = Props.filterByField;
  var value = Props.value;
  var buttonsOpt = Props.buttons;
  var show = Props.show;
  var disabled = Props.disabled;
  var buttons = buttonsOpt !== undefined ? buttonsOpt : [];
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return make(value);
      });
  var setActiveField = match[1];
  var activeField = match[0];
  var match$1 = React.useState(function () {
        return $$default;
      });
  var setValues = match$1[1];
  var values = match$1[0];
  var activeSections$1 = activeSections(activeField);
  React.useEffect((function () {
          setActiveField(function (param) {
                return make(value);
              });
        }), [value]);
  React.useEffect((function () {
          if (predefined && RCore.$$Option.isNone(value)) {
            setActiveField(function (param) {
                  return "IntervalSpecific";
                });
          } else {
            setActiveField(function (param) {
                  return make(value);
                });
          }
          var newValues = makeWithDate($$default, value);
          setValues(function (param) {
                return newValues;
              });
        }), [activeView]);
  var onResetClick = function (param) {
    resetFilter();
    toggleFilter(false);
  };
  var filter = function (value, wrapper, newActiveField) {
    if (value !== undefined) {
      return filterByField(wrapper(Caml_option.valFromOption(value)));
    } else {
      return setActiveField(function (param) {
                  return newActiveField;
                });
    }
  };
  var setEql = function (eqlValue) {
    setValues(function (param) {
          return {
                  eqlValue: eqlValue,
                  earlierRelative: 7,
                  earlierSpecific: undefined,
                  laterRelative: 7,
                  laterSpecific: undefined,
                  intervalStarting: [
                    5,
                    7
                  ],
                  intervalIn: [
                    7,
                    5
                  ],
                  intervalSpecific: [
                    undefined,
                    undefined
                  ]
                };
        });
    filter(eqlValue, (function (v) {
            return {
                    TAG: "Eql",
                    _0: v
                  };
          }), "Eql");
  };
  var setEarlierRelative = function (earlierRelative) {
    setValues(function (param) {
          return {
                  eqlValue: values.eqlValue,
                  earlierRelative: earlierRelative,
                  earlierSpecific: values.earlierSpecific,
                  laterRelative: values.laterRelative,
                  laterSpecific: values.laterSpecific,
                  intervalStarting: values.intervalStarting,
                  intervalIn: values.intervalIn,
                  intervalSpecific: values.intervalSpecific
                };
        });
    setActiveField(function (param) {
          return "EarlierRelative";
        });
  };
  var onEarlierRelativeBlur = function () {
    RCore.$$Option.map(values.earlierRelative, (function (v) {
            filterByField({
                  TAG: "Earlier",
                  _0: {
                    TAG: "Relative",
                    _0: v
                  }
                });
          }));
  };
  var onEarlierRelativeButton = function () {
    setValues(function (param) {
          return $$default;
        });
    onEarlierRelativeBlur();
  };
  var onEarlierRelativeKeyPress = function (evt) {
    if (evt.key === "Enter") {
      RCore.$$Option.map(values.earlierRelative, (function (v) {
              filterByField({
                    TAG: "Earlier",
                    _0: {
                      TAG: "Relative",
                      _0: v
                    }
                  });
            }));
      return ;
    }
    
  };
  var setEarlierSpecific = function (earlierSpecific) {
    setValues(function (param) {
          return {
                  eqlValue: values.eqlValue,
                  earlierRelative: values.earlierRelative,
                  earlierSpecific: earlierSpecific,
                  laterRelative: values.laterRelative,
                  laterSpecific: values.laterSpecific,
                  intervalStarting: values.intervalStarting,
                  intervalIn: values.intervalIn,
                  intervalSpecific: values.intervalSpecific
                };
        });
    filter(earlierSpecific, (function (v) {
            return {
                    TAG: "Earlier",
                    _0: {
                      TAG: "Specific",
                      _0: v
                    }
                  };
          }), "EarlierSpecific");
  };
  var setLaterRelative = function (laterRelative) {
    setValues(function (param) {
          return {
                  eqlValue: values.eqlValue,
                  earlierRelative: values.earlierRelative,
                  earlierSpecific: values.earlierSpecific,
                  laterRelative: laterRelative,
                  laterSpecific: values.laterSpecific,
                  intervalStarting: values.intervalStarting,
                  intervalIn: values.intervalIn,
                  intervalSpecific: values.intervalSpecific
                };
        });
    setActiveField(function (param) {
          return "LaterRelative";
        });
  };
  var onLaterRelativeBlur = function () {
    RCore.$$Option.map(values.laterRelative, (function (v) {
            filterByField({
                  TAG: "Later",
                  _0: {
                    TAG: "Relative",
                    _0: v
                  }
                });
          }));
  };
  var onLaterRelativeButton = function () {
    setValues(function (param) {
          return $$default;
        });
    onLaterRelativeBlur();
  };
  var onLaterRelativeKeyPress = function (evt) {
    if (evt.key === "Enter") {
      RCore.$$Option.map(values.laterRelative, (function (v) {
              filterByField({
                    TAG: "Later",
                    _0: {
                      TAG: "Relative",
                      _0: v
                    }
                  });
            }));
      return ;
    }
    
  };
  var setLaterSpecific = function (laterSpecific) {
    setValues(function (param) {
          return {
                  eqlValue: values.eqlValue,
                  earlierRelative: values.earlierRelative,
                  earlierSpecific: values.earlierSpecific,
                  laterRelative: values.laterRelative,
                  laterSpecific: laterSpecific,
                  intervalStarting: values.intervalStarting,
                  intervalIn: values.intervalIn,
                  intervalSpecific: values.intervalSpecific
                };
        });
    filter(laterSpecific, (function (v) {
            return {
                    TAG: "Later",
                    _0: {
                      TAG: "Specific",
                      _0: v
                    }
                  };
          }), "LaterSpecific");
  };
  var setIntervalStarting = function (param) {
    var match = Fun.both(param[0], param[1]);
    if (match === undefined) {
      return setActiveField(function (param) {
                  return "IntervalStarting";
                });
    }
    var b = match[1];
    var a = match[0];
    setValues(function (param) {
          return {
                  eqlValue: values.eqlValue,
                  earlierRelative: values.earlierRelative,
                  earlierSpecific: values.earlierSpecific,
                  laterRelative: values.laterRelative,
                  laterSpecific: values.laterSpecific,
                  intervalStarting: [
                    a,
                    b
                  ],
                  intervalIn: values.intervalIn,
                  intervalSpecific: values.intervalSpecific
                };
        });
    setActiveField(function (param) {
          return "IntervalStarting";
        });
  };
  var onIntervalStartingBlur = function () {
    RCore.$$Option.map(Fun.both(values.intervalStarting[0], values.intervalStarting[1]), (function (v) {
            filterByField({
                  TAG: "Interval",
                  _0: {
                    TAG: "Starting",
                    _0: v
                  }
                });
          }));
  };
  var onIntervalStartingKeyPress = function (evt) {
    if (evt.key === "Enter") {
      RCore.$$Option.map(Fun.both(values.intervalStarting[0], values.intervalStarting[1]), (function (v) {
              filterByField({
                    TAG: "Interval",
                    _0: {
                      TAG: "Starting",
                      _0: v
                    }
                  });
            }));
      return ;
    }
    
  };
  var setIntervalIn = function (param) {
    var match = Fun.both(param[0], param[1]);
    if (match === undefined) {
      return setActiveField(function (param) {
                  return "IntervalIn";
                });
    }
    var b = match[1];
    var a = match[0];
    setValues(function (param) {
          return {
                  eqlValue: values.eqlValue,
                  earlierRelative: values.earlierRelative,
                  earlierSpecific: values.earlierSpecific,
                  laterRelative: values.laterRelative,
                  laterSpecific: values.laterSpecific,
                  intervalStarting: values.intervalStarting,
                  intervalIn: [
                    a,
                    b
                  ],
                  intervalSpecific: values.intervalSpecific
                };
        });
    setActiveField(function (param) {
          return "IntervalIn";
        });
  };
  var onIntervalInBlur = function () {
    RCore.$$Option.map(Fun.both(values.intervalIn[0], values.intervalIn[1]), (function (v) {
            filterByField({
                  TAG: "Interval",
                  _0: {
                    TAG: "In",
                    _0: v
                  }
                });
          }));
  };
  var onIntervalInKeyPress = function (evt) {
    if (evt.key === "Enter") {
      RCore.$$Option.map(Fun.both(values.intervalIn[0], values.intervalIn[1]), (function (v) {
              filterByField({
                    TAG: "Interval",
                    _0: {
                      TAG: "In",
                      _0: v
                    }
                  });
            }));
      return ;
    }
    
  };
  var onIntervalSpecificBlur = function () {
    var v = Fun.both(values.intervalSpecific[0], values.intervalSpecific[1]);
    if (v !== undefined) {
      filterByField({
            TAG: "Interval",
            _0: {
              TAG: "Specific",
              _0: v
            }
          });
      return ;
    } else {
      return setActiveField(function (param) {
                  return "IntervalSpecific";
                });
    }
  };
  var onIntervalSpecificButton = function () {
    setValues(function (param) {
          return $$default;
        });
    onIntervalSpecificBlur();
  };
  var setIntervalSpecific = function (start, finish) {
    setValues(function (param) {
          return {
                  eqlValue: values.eqlValue,
                  earlierRelative: values.earlierRelative,
                  earlierSpecific: values.earlierSpecific,
                  laterRelative: values.laterRelative,
                  laterSpecific: values.laterSpecific,
                  intervalStarting: values.intervalStarting,
                  intervalIn: values.intervalIn,
                  intervalSpecific: [
                    start,
                    finish
                  ]
                };
        });
    filter(Fun.both(start, finish), (function (v) {
            return {
                    TAG: "Interval",
                    _0: {
                      TAG: "Specific",
                      _0: v
                    }
                  };
          }), "IntervalSpecific");
  };
  var intervalHint = function (predicate) {
    if (value === undefined) {
      return null;
    }
    if (typeof value !== "object") {
      return null;
    }
    if (value.TAG !== "Interval") {
      return null;
    }
    var match = value._0;
    switch (match.TAG) {
      case "Starting" :
      case "In" :
          break;
      case "Specific" :
          return null;
      
    }
    var match$1 = match._0;
    var start = Locale.T.prevDays(predicate(match$1[0]))(Locale.T.now(wire));
    return React.createElement("div", {
                className: styles.intervalDays
              }, intervalHint$p(ctx), React.createElement("div", undefined, React.createElement(JobIndexFilter_Dates$Range, {
                        start: start,
                        range: match$1[1]
                      })));
  };
  var earlierLater = function (relativeField, relativeValue, onRelativeChange, specificField, specificValue, onSpecificChange, onBlur, onKeyPress) {
    return React.createElement(React.Fragment, undefined, React.createElement(JobIndexFilter_Dates$Radio, {
                    onChange: (function (param) {
                        onBlur();
                      }),
                    checked: activeField === relativeField,
                    wrapperClass: styles.innerRadio,
                    disabled: disabled,
                    children: React.createElement(JobIndexFilter_DatesInputs.Days.make, {
                          value: relativeValue,
                          onChange: onRelativeChange,
                          onBlur: (function (param) {
                              onBlur();
                            }),
                          onKeyPress: onKeyPress,
                          disabled: disabled,
                          label: daysAgo$p({
                                count: RCore.$$Option.getOr(relativeValue, 0)
                              }, ctx)
                        })
                  }), React.createElement(JobIndexFilter_Dates$Radio, {
                    onChange: (function (param) {
                        onSpecificChange(specificValue);
                      }),
                    checked: activeField === specificField,
                    wrapperClass: styles.innerRadio,
                    disabled: disabled,
                    children: React.createElement("div", undefined, React.createElement(JobIndexFilter_DatesInputs.$$Date.make, {
                              date: specificValue,
                              onDateChange: onSpecificChange,
                              disabled: disabled
                            }))
                  }));
  };
  var intervalItem = function (value, field, label, onChange, fstRowLabel, sndRowLabel, hint, onBlur, onKeyPress) {
    var secondValue = value[1];
    var firstValue = value[0];
    return React.createElement(JobIndexFilter_Dates$Radio, {
                onChange: (function (param) {
                    onBlur();
                  }),
                checked: activeField === field,
                wrapperClass: styles.innerRadio,
                inputClass: styles.multilineLabel,
                disabled: disabled,
                children: React.createElement("div", undefined, label, React.createElement(Optional.make, {
                          show: activeField === field,
                          children: null
                        }, React.createElement(JobIndexFilter_DatesInputs.Days.make, {
                              value: firstValue,
                              onChange: (function (v) {
                                  onChange([
                                        v,
                                        secondValue
                                      ]);
                                }),
                              onBlur: (function (param) {
                                  onBlur();
                                }),
                              onKeyPress: onKeyPress,
                              disabled: disabled,
                              label: fstRowLabel({
                                    count: RCore.$$Option.getOr(firstValue, 0)
                                  }, ctx)
                            }), during$p(ctx), React.createElement(JobIndexFilter_DatesInputs.Days.make, {
                              value: secondValue,
                              onChange: (function (v) {
                                  onChange([
                                        firstValue,
                                        v
                                      ]);
                                }),
                              onBlur: (function (param) {
                                  onBlur();
                                }),
                              onKeyPress: onKeyPress,
                              disabled: disabled,
                              label: sndRowLabel({
                                    count: RCore.$$Option.getOr(secondValue, 0)
                                  }, ctx)
                            }), hint))
              });
  };
  return React.createElement(JobIndexFilter_Dates$Wrapper, {
              toggleFilter: toggleFilter,
              label: label,
              show: show,
              onResetClick: onResetClick,
              hasValue: RCore.$$Option.isSome(value),
              renderSelected: React.createElement(JobIndexFilter_Dates$SelectedValue, {
                    value: value
                  }),
              leftButton: {
                onClick: (function (param) {
                    filterByField("Any");
                  }),
                checked: activeSections$1.filled,
                text: filled$p(ctx)
              },
              rightButton: {
                onClick: (function (param) {
                    filterByField("NotFilled");
                  }),
                checked: activeSections$1.notFilled,
                text: notFilled$p(ctx)
              },
              disabled: disabled,
              predefined: predefined,
              children: React.createElement("div", {
                    className: styles.container
                  }, React.createElement(Optional.make, {
                        show: activeSections$1.filled || predefined,
                        children: null
                      }, React.createElement("span", {
                            className: styles.subHeader
                          }, predefined ? enteredDatePredefined$p(ctx) : enteredDate$p(ctx)), React.createElement(Optional.make, {
                            show: !predefined,
                            children: React.createElement(JobIndexFilter_Dates$Radio, {
                                  onChange: (function (param) {
                                      filterByField("Any");
                                    }),
                                  checked: activeSections$1.any,
                                  wrapperClass: styles.radio,
                                  disabled: disabled,
                                  labelText: any$p(ctx)
                                })
                          }), React.createElement(JobIndexFilter_Dates$Radio, {
                            onChange: (function (param) {
                                onIntervalSpecificButton();
                              }),
                            checked: activeSections$1.interval,
                            wrapperClass: styles.radio,
                            disabled: disabled,
                            labelText: interval$p(ctx)
                          }), React.createElement(Optional.make, {
                            show: activeSections$1.interval,
                            children: null
                          }, React.createElement(JobIndexFilter_Dates$Radio, {
                                onChange: (function (param) {
                                    setIntervalSpecific(values.intervalSpecific[0], values.intervalSpecific[1]);
                                  }),
                                checked: activeField === "IntervalSpecific",
                                wrapperClass: styles.innerRadio,
                                disabled: disabled,
                                children: React.createElement(JobIndexFilter_DatesInputs.$$Range.make, {
                                      onDatesChange: setIntervalSpecific,
                                      value: values.intervalSpecific,
                                      buttons: buttons,
                                      disabled: disabled
                                    })
                              }), intervalItem(values.intervalStarting, "IntervalStarting", intervalStarting$p(ctx), setIntervalStarting, intervalStartingDays$p, duringDays$p, intervalHint(Fun.id), onIntervalStartingBlur, onIntervalStartingKeyPress), intervalItem(values.intervalIn, "IntervalIn", intervalIn$p(ctx), setIntervalIn, intervalInDays$p, duringDays$p, intervalHint(function (x) {
                                    return -x | 0;
                                  }), onIntervalInBlur, onIntervalInKeyPress)), React.createElement(JobIndexFilter_Dates$Radio, {
                            onChange: (function (param) {
                                setEql(values.eqlValue);
                              }),
                            checked: activeSections$1.eql,
                            wrapperClass: styles.radio,
                            disabled: disabled,
                            labelText: eql$p(ctx)
                          }), React.createElement(Optional.make, {
                            show: activeSections$1.eql,
                            children: React.createElement("div", {
                                  className: styles.innerRadio
                                }, React.createElement(JobIndexFilter_DatesInputs.$$Date.make, {
                                      date: values.eqlValue,
                                      onDateChange: setEql,
                                      disabled: disabled
                                    }))
                          }), React.createElement(JobIndexFilter_Dates$Radio, {
                            onChange: (function (param) {
                                onEarlierRelativeButton();
                              }),
                            checked: activeSections$1.earlier,
                            wrapperClass: styles.radio,
                            disabled: disabled,
                            labelText: earlier$p(ctx)
                          }), React.createElement(Optional.make, {
                            show: activeSections$1.earlier,
                            children: earlierLater("EarlierRelative", values.earlierRelative, setEarlierRelative, "EarlierSpecific", values.earlierSpecific, setEarlierSpecific, onEarlierRelativeBlur, onEarlierRelativeKeyPress)
                          }), React.createElement(JobIndexFilter_Dates$Radio, {
                            onChange: (function (param) {
                                onLaterRelativeButton();
                              }),
                            checked: activeSections$1.later,
                            wrapperClass: styles.radio,
                            disabled: disabled,
                            labelText: later$p(ctx)
                          }), React.createElement(Optional.make, {
                            show: activeSections$1.later,
                            children: earlierLater("LaterRelative", values.laterRelative, setLaterRelative, "LaterSpecific", values.laterSpecific, setLaterSpecific, onLaterRelativeBlur, onLaterRelativeKeyPress)
                          })))
            });
}

var make$1 = JobIndexFilter_Dates;

export {
  make$1 as make,
}
/* styles Not a pure module */
