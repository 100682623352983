

import * as El from "../../../../../../libraries/El.mjs";
import * as Url from "../../../../../../bindings/url/Url.mjs";
import * as Hint from "../../../../../common/Hint/Hint.mjs";
import * as Icon from "../../../../../../Icon.mjs";
import * as Uuid from "../../../../../../bindings/uuid/Uuid.mjs";
import * as Hooks from "../../../../../../libraries/hooks/Hooks.mjs";
import * as Modal from "../../../../../modal/Modal.mjs";
import * as Panel from "../../../../../common/Panel/Panel.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Route from "../../../../../../types/Route.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Button from "../../../../../common/Button.mjs";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as Backend from "../../../../../../libraries/backend/Backend.mjs";
import * as Runtime from "../../../../../../Runtime.mjs";
import * as Optional from "../../../../../common/Optional.mjs";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as ModalQuery from "../../../../../../types/ModalQuery.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JobEdit_API from "../../../lib/state/api/JobEdit_API.mjs";
import * as DropDownMenu from "../../../../../common/DropDownMenu/DropDownMenu.mjs";
import * as Context_Types from "../../../../../../context/Context_Types.mjs";
import * as TemplatesPopup from "../../../../../common/TemplatesPopup/TemplatesPopup.mjs";
import * as JobEdit_JobType from "../../../lib/types/JobEdit_JobType.mjs";
import * as JobForm_HeadingModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/edit/form/components/heading/JobForm_Heading.module.css";

var styles = JobForm_HeadingModuleCss;

var addExternalId$p = Utils.Translations.tr("js.jobs.dropdown.add_external_id");

var adding$p = Utils.Translations.tr("js.jobs.buttons.adding");

var create$p = Utils.Translations.tr("js.jobs.buttons.create");

var titleCreate$p = Utils.Translations.tr("js.jobs.header.title.create");

var closeCreation$p = Utils.Translations.tr("js.jobs.buttons.close_creation");

var enRoute$p = Utils.Translations.tr("js.jobs.dropdown.en_route");

var start$p = Utils.Translations.tr("js.jobs.dropdown.start");

var finish$p = Utils.Translations.tr("js.jobs.dropdown.finish");

var suspend$p = Utils.Translations.tr("js.jobs.dropdown.suspend");

var resume$p = Utils.Translations.tr("js.jobs.dropdown.resume");

var copy$p = Utils.Translations.tr("js.jobs.dropdown.copy");

var delete$p = Utils.Translations.tr("js.jobs.dropdown.delete");

var cancel$p = Utils.Translations.tr("js.jobs.dropdown.cancel");

var linkedJob$p = Utils.Translations.tr("js.jobs.linked.button");

var completionJob$p = Utils.Translations.tr("js.jobs.linked.completion");

var completionJobHint$p = Utils.Translations.tr("js.jobs.linked.completion_hint");

var newJob$p = Utils.Translations.tr("js.jobs.linked.new");

var newJobHint$p = Utils.Translations.tr("js.jobs.linked.new_hint");

function timeItem$p(s) {
  return Utils.Translations.txr("js.jobs.header.items." + s);
}

var saving$p = Utils.Translations.tr("js.jobs.buttons.saving");

var edit$p = Utils.Translations.tr("js.jobs.buttons.edit");

var historyHint$p = Utils.Translations.t("js.jobs.title.history_hint");

var closeEdit$p = Utils.Translations.tr("js.jobs.buttons.close_edit");

function status$p(s) {
  return Utils.Translations.t("js.jobs.statuses." + s);
}

var job$p = Utils.Translations.tr("js.jobs.header.title.job");

var serialNo$p = Utils.Translations.txr("js.jobs.header.title.serial_no");

var defaultLayout$p = Utils.Translations.tr("js.jobs.print.default_layout");

var renderErrors$p = Utils.Translations.tr("js.jobs.print.render_errors");

var followingErrors$p = Utils.Translations.tr("js.jobs.print.following_errors");

var close$p = Utils.Translations.tr("common.close");

function optionsButton(ref_) {
  return React.createElement("a", {
              ref: ref_,
              className: "btn btn-default btn-more-options",
              href: "#"
            }, React.createElement("span", {
                  key: "1",
                  className: Icon.style(undefined, undefined, "circle")
                }), React.createElement("span", {
                  key: "2",
                  className: Icon.style(undefined, undefined, "circle")
                }), React.createElement("span", {
                  key: "3",
                  className: Icon.style(undefined, undefined, "circle")
                }));
}

function JobForm_Heading$CreateJobHeading(Props) {
  var externalId = Props.externalId;
  var onAddExternalIdClicked = Props.onAddExternalIdClicked;
  var onClose = Props.onClose;
  var triggerSubmit = Props.triggerSubmit;
  var locked = Props.locked;
  var wire = Props.wire;
  var foremanError = Props.foremanError;
  var ctx = wire.ctx;
  var addExternalIdClickHandler = function ($$event) {
    $$event.preventDefault();
    onAddExternalIdClicked();
  };
  var onClose$1 = function ($$event) {
    $$event.preventDefault();
    onClose();
  };
  var dropdown = RCore.$$Option.isSome(externalId) ? null : React.createElement(DropDownMenu.make, {
          position: "right",
          button: optionsButton,
          className: "btn-more-options-dropdown",
          children: React.createElement("li", undefined, React.createElement("a", {
                    href: "#",
                    name: "add_external_id",
                    onClick: addExternalIdClickHandler
                  }, addExternalId$p(ctx)))
        });
  var closeButton = React.createElement("button", {
        className: "btn btn-default",
        type: "button",
        onClick: onClose$1
      }, closeCreation$p(ctx));
  var submitButtonText = locked ? adding$p(ctx) : create$p(ctx);
  var onClickSubmit = function ($$event) {
    $$event.preventDefault();
    triggerSubmit();
  };
  var submitButton = React.createElement("button", {
        className: "btn btn-primary btn-submit intercom-job-submit-button " + wire.tour.anchor("job-submit-button"),
        disabled: locked || foremanError,
        onClick: onClickSubmit
      }, submitButtonText);
  return React.createElement("header", {
              className: "p-modal-header__container"
            }, React.createElement("div", {
                  className: "p-modal-header__title"
                }, React.createElement("h1", undefined, React.createElement("span", {
                          className: "text-semibold"
                        }, titleCreate$p(ctx)))), React.createElement("div", {
                  className: "p-modal-header__buttons"
                }, dropdown, closeButton, submitButton));
}

var CreateJobHeading = {
  make: JobForm_Heading$CreateJobHeading
};

function JobForm_Heading$OptionsButton(Props) {
  var hasRightsToDelete = Props.hasRightsToDelete;
  var hasRightsToEdit = Props.hasRightsToEdit;
  var status = Props.status;
  var uuid = Props.uuid;
  var onFinishLinkClicked = Props.onFinishLinkClicked;
  var onSubmitLinkClicked = Props.onSubmitLinkClicked;
  var onCopyClicked = Props.onCopyClicked;
  var onCancelClicked = Props.onCancelClicked;
  var onRemoveClicked = Props.onRemoveClicked;
  var param = Props.wire;
  var ctx = param.ctx;
  var enRoute_isVisible = hasRightsToEdit && (status === "Published" || status === "Suspended");
  var enRoute_node = function () {
    return React.createElement("li", {
                key: JobEdit_JobType.Status.toString("EnRoute")
              }, React.createElement("a", {
                    href: "#",
                    onClick: onSubmitLinkClicked(JobEdit_JobType.Status.toString("EnRoute"))
                  }, enRoute$p(ctx)));
  };
  var enRoute = {
    isVisible: enRoute_isVisible,
    node: enRoute_node
  };
  var isVisible = hasRightsToEdit && [
      "Published",
      "EnRoute"
    ].some(function (s) {
        return s === status;
      });
  var start_node = function () {
    return React.createElement("li", {
                key: "start"
              }, React.createElement("a", {
                    href: "#",
                    onClick: onSubmitLinkClicked(JobEdit_JobType.Status.toString("Started"))
                  }, start$p(ctx)));
  };
  var start = {
    isVisible: isVisible,
    node: start_node
  };
  var finish_isVisible = hasRightsToEdit && status === "Started";
  var finish_node = function () {
    return React.createElement("li", {
                key: "finish"
              }, React.createElement("a", {
                    href: "#",
                    onClick: onFinishLinkClicked
                  }, finish$p(ctx)));
  };
  var finish = {
    isVisible: finish_isVisible,
    node: finish_node
  };
  var suspend_isVisible = hasRightsToEdit && (status === "Started" || status === "EnRoute");
  var suspend_node = function () {
    return React.createElement("li", {
                key: "suspend"
              }, React.createElement("a", {
                    href: "#",
                    onClick: onSubmitLinkClicked(JobEdit_JobType.Status.toString("Suspended"))
                  }, suspend$p(ctx)));
  };
  var suspend = {
    isVisible: suspend_isVisible,
    node: suspend_node
  };
  var resume_isVisible = hasRightsToEdit && status === "Suspended";
  var resume_node = function () {
    return React.createElement("li", {
                key: "resume"
              }, React.createElement("a", {
                    href: "#",
                    onClick: onSubmitLinkClicked(JobEdit_JobType.Status.toString("Started"))
                  }, resume$p(ctx)));
  };
  var resume = {
    isVisible: resume_isVisible,
    node: resume_node
  };
  var isVisible$1 = hasRightsToEdit && [
      "Published",
      "EnRoute",
      "Started",
      "Suspended"
    ].some(function (s) {
        return s === status;
      });
  var divider_node = function () {
    return React.createElement(DropDownMenu.Separator.make, {
                key: "sep"
              });
  };
  var divider = {
    isVisible: isVisible$1,
    node: divider_node
  };
  var cancel_isVisible = hasRightsToEdit && status === "Published";
  var cancel_node = function () {
    return React.createElement(React.Fragment, {
                children: null,
                key: "fragment"
              }, React.createElement(DropDownMenu.Separator.make, {
                    key: "sep"
                  }), React.createElement("li", {
                    key: "cancel"
                  }, React.createElement("a", {
                        href: "#",
                        onClick: onCancelClicked
                      }, cancel$p(ctx))));
  };
  var cancel = {
    isVisible: cancel_isVisible,
    node: cancel_node
  };
  var copy_node = function () {
    return React.createElement("li", {
                key: "copy"
              }, React.createElement("a", {
                    href: Route.pagePath({
                          hd: {
                            NAME: "Job",
                            VAL: {
                              NAME: "New",
                              VAL: {
                                TAG: "Copy",
                                _0: uuid
                              }
                            }
                          },
                          tl: /* [] */0
                        }, {
                          NAME: "Job",
                          VAL: "Index"
                        }),
                    target: "_blank",
                    onClick: onCopyClicked
                  }, copy$p(ctx)));
  };
  var copy = {
    isVisible: hasRightsToEdit,
    node: copy_node
  };
  var remove_node = function () {
    return React.createElement("li", {
                key: "remove"
              }, React.createElement("a", {
                    className: "button-remove",
                    href: Route.pagePath({
                          hd: {
                            NAME: "Job",
                            VAL: {
                              NAME: "Edit",
                              VAL: ModalQuery.Job.EditOptions.make(undefined, undefined, uuid)
                            }
                          },
                          tl: /* [] */0
                        }, {
                          NAME: "Job",
                          VAL: "Index"
                        }),
                    rel: "nofollow",
                    onClick: onRemoveClicked
                  }, delete$p(ctx)));
  };
  var remove = {
    isVisible: hasRightsToDelete,
    node: remove_node
  };
  var options = [
    enRoute,
    start,
    finish,
    suspend,
    resume,
    cancel,
    divider,
    copy,
    remove
  ];
  var visibleOptions = RCore.$$Array.filterMap(options, (function (param) {
          if (param.isVisible) {
            return Caml_option.some(param.node());
          }
          
        }));
  return React.createElement(Optional.make, {
              show: visibleOptions.length > 0,
              children: React.createElement(DropDownMenu.make, {
                    position: "right",
                    button: optionsButton,
                    children: visibleOptions
                  })
            });
}

function JobForm_Heading$LinkedJobOptions(Props) {
  var onNextJobClicked = Props.onNextJobClicked;
  var onCompletionJobClicked = Props.onCompletionJobClicked;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var buttonUuid = React.useMemo((function () {
          return Uuid.createV4();
        }), []);
  var match$1 = React.useState(function () {
        return false;
      });
  var setOpened = match$1[1];
  var opened = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setChooseTemplate = match$2[1];
  var dropdownButton = function (label, hint, onClick, chevron) {
    return React.createElement("div", {
                className: styles.linkedOption,
                onClick: onClick
              }, React.createElement("div", {
                    className: styles.linkedLabel
                  }, React.createElement("span", undefined, label), React.createElement(Optional.make, {
                        show: chevron,
                        children: React.createElement("i", {
                              className: Icon.style(undefined, undefined, "chevron-right")
                            })
                      })), React.createElement("span", {
                    className: styles.linkedHint
                  }, hint));
  };
  var buttonCN = opened ? El.Cn.concat("btn btn-default", styles.active) : "btn btn-default";
  var popover = React.useRef(null);
  Hooks.useOnClickOutside(undefined, popover, (function () {
          setOpened(function (param) {
                return false;
              });
        }));
  return React.createElement("div", {
              ref: popover,
              className: styles.dropdownContainer
            }, React.createElement("div", {
                  className: buttonCN,
                  id: Uuid.toString(buttonUuid),
                  onClick: (function (param) {
                      setOpened(function (prevState) {
                            return !prevState;
                          });
                    })
                }, linkedJob$p(ctx)), React.createElement(Panel.make, {
                  footer: match.footer,
                  show: opened,
                  domId: Uuid.toString(buttonUuid),
                  position: "bottomRight",
                  className: styles.dropdown,
                  children: match$2[0] ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                              className: styles.linkedOptionBack,
                              onClick: (function (evt) {
                                  evt.preventDefault();
                                  evt.stopPropagation();
                                  setChooseTemplate(function (param) {
                                        return false;
                                      });
                                })
                            }, React.createElement("i", {
                                  className: Icon.style(undefined, undefined, "chevron-left")
                                }), React.createElement("span", {
                                  className: styles.linkedLabelBack
                                }, newJob$p(ctx))), React.createElement(TemplatesPopup.make, {
                              options: ctx.templates,
                              minForFiltering: 5,
                              onClick: onNextJobClicked
                            })) : React.createElement(React.Fragment, undefined, dropdownButton(completionJob$p(ctx), completionJobHint$p(ctx), onCompletionJobClicked, false), dropdownButton(newJob$p(ctx), newJobHint$p(ctx), (function (evt) {
                                evt.preventDefault();
                                evt.stopPropagation();
                                setChooseTemplate(function (param) {
                                      return true;
                                    });
                              }), true))
                }));
}

function allLayouts(ctx, layouts, onClick) {
  return layouts.map(function (l) {
              if (l.TAG === "Default") {
                return React.createElement(React.Fragment, {
                            children: null,
                            key: "defaut"
                          }, React.createElement(DropDownMenu.Separator.make, {}), React.createElement("li", {
                                key: "default"
                              }, React.createElement("a", {
                                    href: "#",
                                    onClick: onClick(l)
                                  }, defaultLayout$p(ctx))));
              } else {
                return React.createElement("li", {
                            key: Uuid.toString(l.uuid)
                          }, React.createElement("a", {
                                href: "#",
                                onClick: onClick(l)
                              }, l.name));
              }
            });
}

function defaultLayoutOnly(ctx, layouts, onClick) {
  var filtered = Core__List.fromArray(RCore.$$Array.filterMap(layouts, (function (l) {
              if (l.TAG === "Default") {
                return l;
              }
              
            })));
  if (filtered) {
    return React.createElement("li", {
                key: "default"
              }, React.createElement("a", {
                    href: "#",
                    onClick: onClick(filtered.hd)
                  }, defaultLayout$p(ctx)));
  } else {
    return null;
  }
}

function JobForm_Heading$PrintJobButton$LayoutMenu(Props) {
  var layouts = Props.layouts;
  var onClick = Props.onClick;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var button = function (ref_) {
    return React.createElement(Button.make, {
                className: styles.printJob,
                forwardRef: ref_,
                children: null
              }, React.createElement("i", {
                    className: Icon.style(undefined, undefined, "print")
                  }), React.createElement("span", {
                    className: styles.openPrintLayouts
                  }, React.createElement("i", {
                        className: Icon.style(undefined, undefined, "caret-down")
                      })));
  };
  return React.createElement(DropDownMenu.make, {
              position: "right",
              button: button,
              children: Context_Types.Features.hasFlag("printLayouts", ctx.features) ? allLayouts(ctx, layouts, onClick) : defaultLayoutOnly(ctx, layouts, onClick)
            });
}

function JobForm_Heading$PrintJobButton(Props) {
  var setLock = Props.setLock;
  var layouts = Props.layouts;
  var uuid = Props.uuid;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return false;
      });
  var setInProgress = match[1];
  var inProgress = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setErrors = match$1[1];
  var errors = match$1[0];
  var printJob = function (layout) {
    return function (e) {
      setInProgress(function (param) {
            return true;
          });
      e.preventDefault();
      setLock(true);
      if (inProgress) {
        return ;
      }
      var startPolling = function (res) {
        if (res.TAG !== "Ok") {
          return ;
        }
        var requestUuid = res._0.requestUuid;
        var interval = {
          contents: undefined
        };
        var cancelInterval = function () {
          var i = interval.contents;
          if (i !== undefined) {
            return Runtime.Interval.cancel(Caml_option.valFromOption(i));
          }
          
        };
        var counter = {
          contents: 0
        };
        interval.contents = Caml_option.some(Runtime.Interval.every(1000, (function () {
                    var handle = function (response) {
                      if (response.TAG === "Ok") {
                        var match = response._0;
                        var exit = 0;
                        var r;
                        if (match.url !== undefined || match.errors !== undefined) {
                          r = match;
                          exit = 1;
                        }
                        if (exit === 1) {
                          setLock(false);
                          setInProgress(function (param) {
                                return false;
                              });
                          cancelInterval();
                          var url = r.url;
                          if (url !== undefined) {
                            window.open(Url.toString(Caml_option.valFromOption(url)), "_blank", undefined);
                          } else {
                            var errors = r.errors;
                            if (errors !== undefined) {
                              setErrors(function (param) {
                                    return errors;
                                  });
                            }
                            
                          }
                        }
                        
                      }
                      counter.contents = counter.contents + 1 | 0;
                      if (counter.contents > 100) {
                        return cancelInterval();
                      }
                      
                    };
                    Backend.$$finally(JobEdit_API.Print.Get.$$fetch(wire, requestUuid), handle);
                  })));
      };
      Backend.$$finally(JobEdit_API.Print.Post.post(wire, uuid, JobEdit_JobType.PrintLayout.uuid(layout)), startPolling);
    };
  };
  var len = layouts.length;
  var button;
  if (len !== 1) {
    button = len !== 0 ? React.createElement(JobForm_Heading$PrintJobButton$LayoutMenu, {
            layouts: layouts,
            onClick: printJob
          }) : null;
  } else {
    var single = layouts[0];
    button = React.createElement(Button.make, {
          onClick: printJob(single),
          className: styles.printJob,
          children: React.createElement("i", {
                className: Icon.style(undefined, undefined, "print")
              })
        });
  }
  var errors$1 = errors !== undefined ? React.createElement(Modal.make, {
          wire: wire,
          onClose: (function () {
              setErrors(function (param) {
                    
                  });
            }),
          children: React.createElement(Modal.Dialog.make, {
                children: null
              }, React.createElement(Modal.Header.make, {
                    children: React.createElement("h1", undefined, renderErrors$p(wire.ctx))
                  }), React.createElement(Modal.Body.make, {
                    children: null
                  }, React.createElement("div", {
                        className: styles.renderErrorsHeader
                      }, followingErrors$p(ctx)), React.createElement("ul", {
                        className: styles.renderErrors
                      }, errors.map(function (e, i) {
                            return React.createElement("li", {
                                        key: i.toString()
                                      }, e);
                          }))), React.createElement(Modal.Footer.make, {
                    children: React.createElement(Modal.CloseButton.make, {
                          ctx: ctx,
                          label: close$p(ctx)
                        })
                  }))
        }) : null;
  return React.createElement(React.Fragment, undefined, button, errors$1);
}

function JobForm_Heading$EditJobHeading(Props) {
  var serialNo = Props.serialNo;
  var uuid = Props.uuid;
  var job = Props.job;
  var onClose = Props.onClose;
  var onCancel = Props.onCancel;
  var onRemove = Props.onRemove;
  var onFinish = Props.onFinish;
  var onCopy = Props.onCopy;
  var onNextJob = Props.onNextJob;
  var onCompletionJob = Props.onCompletionJob;
  var toggleHistory = Props.toggleHistory;
  var triggerSubmit = Props.triggerSubmit;
  var printLayouts = Props.printLayouts;
  var locked = Props.locked;
  var saving = Props.saving;
  var setLock = Props.setLock;
  var wire = Props.wire;
  var resolutionComment = job.resolutionComment;
  var resolution = job.resolution;
  var status = job.status;
  var ctx = wire.ctx;
  var hasRightsToEdit = RCore.$$Option.getOr(RCore.$$Option.map(ctx.permissions, (function (p) {
              if (p.jobs === "ViewEdit") {
                return true;
              } else {
                return p.jobs === "ViewEditDelete";
              }
            })), false) || RCore.$$Option.getOr(RCore.$$Option.map(ctx.permissions, (function (p) {
              return p.admin === "View";
            })), false);
  var hasRightsToDelete = RCore.$$Option.getOr(RCore.$$Option.map(ctx.permissions, (function (p) {
              return p.jobs === "ViewEditDelete";
            })), false) || RCore.$$Option.getOr(RCore.$$Option.map(ctx.permissions, (function (p) {
              return p.admin === "View";
            })), false);
  var timeValues = [
    [
      "en_route_at",
      job.enRouteAt
    ],
    [
      "started_at",
      job.startedAt
    ],
    [
      "suspended_at",
      job.suspendedAt
    ],
    [
      "finished_at",
      job.finishedAt
    ]
  ];
  var timeItemNodes = RCore.$$Array.filterMap(timeValues, (function (param) {
          var timestamp = param[1];
          if (timestamp === undefined) {
            return ;
          }
          var name = param[0];
          var dateFormatted = Locale.T.fmtS("Date", wire)(timestamp);
          var timeFormatted = Locale.T.fmtS("Time", wire)(timestamp);
          var timeItem = timeItem$p(name)({
                date: dateFormatted,
                time: timeFormatted
              }, ctx);
          return (function () {
                    return React.createElement("div", {
                                key: name,
                                className: "heading-item"
                              }, timeItem);
                  });
        }));
  var timeItemBlock = timeItemNodes.length === 0 ? null : React.createElement("div", {
          className: "p-modal-header__events"
        }, timeItemNodes.map(function (funcEl) {
              return funcEl();
            }));
  var openResolutionModal = function ($$event) {
    $$event.preventDefault();
    switch (status) {
      case "Finished" :
          return onFinish();
      case "Canceled" :
          return onCancel();
      default:
        return ;
    }
  };
  var resolutionBlock;
  if (resolution !== undefined) {
    var resolutionClassNames = El.Cn.fromList({
          hd: [
            "label",
            true
          ],
          tl: {
            hd: [
              "label-resolution",
              true
            ],
            tl: {
              hd: [
                "pd-bs-label",
                true
              ],
              tl: {
                hd: [
                  "label-success",
                  resolution.isSuccessful
                ],
                tl: {
                  hd: [
                    "label-danger",
                    !resolution.isSuccessful
                  ],
                  tl: /* [] */0
                }
              }
            }
          }
        });
    var noteTag;
    if (resolutionComment !== undefined) {
      var trimmed = resolutionComment.trim();
      noteTag = trimmed.length === 0 ? null : React.createElement("span", {
              className: "label label-tag pd-bs-label tag-resolution-comment",
              onClick: openResolutionModal
            }, React.createElement("span", undefined, resolutionComment));
    } else {
      noteTag = null;
    }
    resolutionBlock = React.createElement("div", {
          className: "p-modal-header__resolution"
        }, React.createElement("span", {
              className: resolutionClassNames,
              onClick: openResolutionModal
            }, resolution.name), noteTag);
  } else {
    resolutionBlock = null;
  }
  var onSubmitLinkClicked = function (status) {
    return function ($$event) {
      $$event.preventDefault();
      triggerSubmit([[
              "status",
              status
            ]]);
    };
  };
  var onCancelClicked = function ($$event) {
    $$event.preventDefault();
    onCancel();
  };
  var onFinishClicked = function ($$event) {
    $$event.preventDefault();
    onFinish();
  };
  var onRemoveClicked = function ($$event) {
    $$event.preventDefault();
    onRemove();
  };
  var onCopyClicked = function ($$event) {
    $$event.preventDefault();
    onCopy();
  };
  var onNextJobClicked = function ($$event, templateUuid) {
    $$event.preventDefault();
    onNextJob(templateUuid);
  };
  var onCompletionJobClicked = function ($$event) {
    $$event.preventDefault();
    onCompletionJob();
  };
  var onSubmitButtonClicked = function ($$event) {
    $$event.preventDefault();
    triggerSubmit([]);
  };
  var label = saving ? saving$p(ctx) : edit$p(ctx);
  var buttonSubmit = React.createElement("button", {
        className: "btn btn-primary btn-submit",
        disabled: locked || job.foremanError,
        onClick: onSubmitButtonClicked
      }, label);
  var onClose$1 = function ($$event) {
    $$event.preventDefault();
    onClose();
  };
  var buttonClose = React.createElement("button", {
        className: "btn btn-default",
        onClick: onClose$1
      }, closeEdit$p(ctx), El.space, React.createElement("span", {
            className: Icon.style(undefined, undefined, "times")
          }));
  var toggleHistory$1 = function ($$event) {
    $$event.preventDefault();
    toggleHistory();
  };
  var historyButton = React.createElement(Hint.make, {
        content: historyHint$p(ctx),
        position: "Top",
        children: React.createElement("span", {
              className: styles.history,
              onClick: toggleHistory$1
            })
      });
  var statusString = status$p(JobEdit_JobType.Status.toString(status))(ctx);
  var jobHeaderTitle = React.createElement(React.Fragment, undefined, React.createElement("span", {
            className: "text-semibold"
          }, job$p(ctx), React.createElement("span", {
                className: "en-space"
              }, serialNo$p({
                    serial_no: serialNo.toString()
                  }, ctx))), React.createElement("span", {
            className: El.Cn.concat("text-light", styles.status)
          }, El.nbsp, El.mdash, El.nbsp, statusString));
  return React.createElement("header", {
              className: "p-modal-header__container"
            }, React.createElement("div", {
                  className: "p-modal-header__title"
                }, React.createElement("div", {
                      className: styles.headline
                    }, React.createElement("h1", undefined, jobHeaderTitle), historyButton), timeItemBlock), React.createElement("div", {
                  className: styles.buttons
                }, React.createElement(JobForm_Heading$OptionsButton, {
                      hasRightsToDelete: hasRightsToDelete,
                      hasRightsToEdit: hasRightsToEdit,
                      status: status,
                      uuid: uuid,
                      onFinishLinkClicked: onFinishClicked,
                      onSubmitLinkClicked: onSubmitLinkClicked,
                      onCopyClicked: onCopyClicked,
                      onCancelClicked: onCancelClicked,
                      onRemoveClicked: onRemoveClicked,
                      wire: wire
                    }), React.createElement(JobForm_Heading$PrintJobButton, {
                      setLock: setLock,
                      layouts: printLayouts,
                      uuid: uuid
                    }), React.createElement(Optional.make, {
                      show: hasRightsToEdit,
                      children: null
                    }, Context_Types.Features.hasFlag("linkedJobs", ctx.features) ? React.createElement(JobForm_Heading$LinkedJobOptions, {
                            onNextJobClicked: onNextJobClicked,
                            onCompletionJobClicked: onCompletionJobClicked
                          }) : null, buttonSubmit), buttonClose), resolutionBlock);
}

var EditJobHeading = {
  make: JobForm_Heading$EditJobHeading
};

export {
  CreateJobHeading ,
  EditJobHeading ,
}
/* styles Not a pure module */
