

import * as Units from "../../types/Units.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Webapi__Dom__Element from "rescript-webapi/lib/es6/src/Webapi/Dom/Webapi__Dom__Element.mjs";

function nodeClientOffset(node) {
  var element = Webapi__Dom__Element.ofNode(node);
  if (element === undefined) {
    return ;
  }
  var box = Caml_option.valFromOption(element).getBoundingClientRect();
  var top = Units.Px.plus(Units.Px.fromFloat(box.top), Units.Px.fromFloat(window.pageYOffset));
  var left = Units.Px.plus(Units.Px.fromFloat(box.left), Units.Px.fromFloat(window.pageXOffset));
  return [
          top,
          left
        ];
}

function dragPreviewOffset(pageX, pageY, param) {
  return [
          Units.Px.minus(pageX, param[0]),
          Units.Px.minus(pageY, param[1])
        ];
}

var Offset = {
  nodeClientOffset: nodeClientOffset,
  dragPreviewOffset: dragPreviewOffset
};

export {
  Offset ,
}
/* Webapi__Dom__Element Not a pure module */
