

import * as Units from "../../../../../../types/Units.mjs";
import * as DomUtils from "../../../../../../DomUtils.mjs";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as DayTimeline_UtilsSizes from "../../lib/utils/DayTimeline_UtilsSizes.mjs";

var compactHeight = Units.Px.toString(Units.Px.fromInt(37));

var contentHeight = Units.Px.toString(Units.Px.fromInt(164));

var compactContentHeight = Units.Px.toString(Units.Px.fromInt(101));

var marginWidth = Units.Px.toString(Units.Px.fromInt(16));

function use(compactMode, barCount) {
  var showUnscheduledJobs = Schedule_State.useShowUnscheduledJobs();
  var unscheduledJobsWidth = Units.Px.toString(DayTimeline_UtilsSizes.unscheduledJobsWidth(showUnscheduledJobs));
  return {
          height: compactMode ? compactHeight : (
              barCount === 1 ? compactContentHeight : contentHeight
            ),
          width: "calc(100% - " + unscheduledJobsWidth + " + " + marginWidth + ")"
        };
}

function viewport(barCount, width) {
  var hasScrollbar = barCount > 2;
  var scrollbarWidth = hasScrollbar ? Units.Px.plus(DomUtils.scrollbarWidth(), Units.Px.fromInt(2)) : Units.Px.zero;
  var width$1 = Units.Px.toString(Units.Px.plus(width, scrollbarWidth));
  return {
          width: width$1
        };
}

function timeline(width) {
  return {
          width: Units.Px.toString(width)
        };
}

export {
  use ,
  viewport ,
  timeline ,
}
/* compactHeight Not a pure module */
