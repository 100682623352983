

import * as Fun from "../libraries/Fun.mjs";
import * as Uuid from "../bindings/uuid/Uuid.mjs";
import * as RCore from "../libraries/RCore.mjs";
import * as Utils from "../utils/Utils.mjs";
import * as Locale from "../libraries/Locale.mjs";
import * as $$String from "rescript/lib/es6/string.js";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as QueryUtils from "../utils/QueryUtils.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PervasivesU from "rescript/lib/es6/pervasivesU.js";
import * as Serialization from "../libraries/Serialization.mjs";
import * as Types_Spatial from "./Types_Spatial.mjs";
import * as Pages_Sites_Types from "../pages/sites/index/Pages_Sites_Types.mjs";
import * as Schedule_Types_Job from "../components/schedule/lib/types/job/Schedule_Types_Job.mjs";
import * as Pages_Clients_Types from "../pages/clients/index/Pages_Clients_Types.mjs";
import * as Schedule_Types_Settings from "../components/schedule/lib/types/Schedule_Types_Settings.mjs";
import * as Schedule_Types_JobDuration from "../components/schedule/lib/types/Schedule_Types_JobDuration.mjs";

function uuidsToString(key, uuids) {
  var __x = Core__List.fromArray(uuids.map(function (uuid) {
            return "&" + key + "[]=" + Uuid.toString(uuid);
          }));
  return $$String.concat("", __x);
}

function workersWithTimeIntervalRequestParams(workerUuids, period, uuidsParamName, optionalParamsHandler, param) {
  var f = Serialization.$$Date.iso8601;
  var d1s = f(period[0]);
  var d2s = f(period[1]);
  var workers = uuidsToString(uuidsParamName !== undefined ? uuidsParamName : "uuids", workerUuids);
  var requiredParams = [
    [
      "from",
      d1s
    ],
    [
      "to",
      d2s
    ]
  ];
  var params = optionalParamsHandler !== undefined ? optionalParamsHandler(requiredParams) : requiredParams;
  return QueryUtils.makeQuery(params) + workers;
}

function path(route) {
  var match = route.VAL;
  var p;
  if (typeof match === "object") {
    var variant = match.NAME;
    if (variant === "ClientPortal") {
      var match$1 = match.VAL;
      if (typeof match$1 === "object") {
        var match$2 = match$1.VAL;
        p = typeof match$2 === "object" ? "/admin/client-portal/users/" + Uuid.toString(match$2.VAL) : "/admin/client-portal/users";
      } else {
        p = "/admin/client-portal/settings";
      }
    } else if (variant === "CustomFields") {
      p = "/admin/custom_fields/users";
    } else if (variant === "Shifts") {
      var match$3 = match.VAL;
      if (typeof match$3 === "object") {
        var variant$1 = match$3.NAME;
        p = variant$1 === "Delete" ? "/shifts/" + Uuid.toString(match$3.VAL) : (
            variant$1 === "Team" ? "/shifts/teams/" + Uuid.toString(match$3.VAL.VAL) : (
                variant$1 === "User" ? "/shifts/workers/" + Uuid.toString(match$3.VAL.VAL) : "/shifts"
              )
          );
      } else {
        p = match$3 === "TeamsConstructor" ? "/shifts/teams-constructor" : "/shifts/constructor";
      }
    } else if (variant === "Integrations") {
      var match$4 = match.VAL;
      if (typeof match$4 === "object") {
        if (match$4.NAME === "Webhooks") {
          var match$5 = match$4.VAL;
          p = typeof match$5 === "object" ? "/admin/integrations/webhooks/" + Uuid.toString(match$5.VAL) : "/admin/integrations/webhooks";
        } else {
          p = "/admin/integrations/amocrm/connect?amocrm_host=" + match$4.VAL.VAL;
        }
      } else {
        p = match$4 === "GenerateKey" ? "/admin/integrations/api/generate-key" : "/admin/integrations/api";
      }
    } else if (variant === "ScheduleOld") {
      var match$6 = match.VAL;
      p = "/schedule?period[]=" + match$6[0] + "&period[]=" + match$6[1];
    } else if (variant === "PrintLayout") {
      var match$7 = match.VAL;
      p = typeof match$7 === "object" ? (
          match$7.NAME === "Edit" ? "/admin/print_layouts/" + Uuid.toString(match$7.VAL) : "/admin/print_layouts/" + Uuid.toString(match$7.VAL) + "/download"
        ) : (
          match$7 === "New" ? "/admin/print_layouts/new" : "/admin/print_layouts"
        );
    } else if (variant === "Address") {
      p = "/sites/search?query=" + match.VAL.VAL;
    } else if (variant === "Workers") {
      p = "/workers/not_working";
    } else if (variant === "RelatedEntities") {
      p = "/related_entities" + match.VAL;
    } else if (variant === "Categories") {
      var match$8 = match.VAL;
      p = typeof match$8 === "object" ? (
          match$8.NAME === "Update" ? "/admin/categories/" + Uuid.toString(match$8.VAL) : "/admin/categories?" + Utils.stringify(match$8.VAL)
        ) : "/admin/categories";
    } else if (variant === "Job") {
      var match$9 = match.VAL;
      if (typeof match$9 === "object") {
        var variant$2 = match$9.NAME;
        if (variant$2 === "Print") {
          p = "/jobs/" + Uuid.toString(match$9.VAL) + ".pdf";
        } else if (variant$2 === "ReportButton") {
          p = "/jobs/" + match$9.VAL[0];
        } else if (variant$2 === "UploadShow") {
          p = "/jobs/upload/" + Uuid.toString(match$9.VAL);
        } else if (variant$2 === "Cancel") {
          p = "/jobs/" + Uuid.toString(match$9.VAL) + "/cancel";
        } else if (variant$2 === "FilterSettings") {
          p = "/jobs/filter_settings";
        } else if (variant$2 === "Edit") {
          p = "/jobs/" + Uuid.toString(match$9.VAL);
        } else if (variant$2 === "Find") {
          p = "/jobs/find";
        } else if (variant$2 === "History") {
          var match$10 = match$9.VAL;
          if (match$10.NAME === "Uuid") {
            p = "/jobs/" + Uuid.toString(match$10.VAL) + "/history";
          } else {
            var match$11 = match$10.VAL;
            p = "/jobs/" + Uuid.toString(match$11[0]) + "/history/" + match$11[1] + "/locations";
          }
        } else if (variant$2 === "Images") {
          p = "/jobs/" + Uuid.toString(match$9.VAL) + "/images.zip";
        } else if (variant$2 === "Territories") {
          var match$12 = match$9.VAL.VAL;
          p = "/jobs/territories/find?location[latitude]=" + Types_Spatial.Latitude.toString(match$12.latitude) + "&location[longitude]=" + Types_Spatial.Longitude.toString(match$12.longitude);
        } else {
          p = variant$2 === "Overlapping" ? "/jobs" : (
              variant$2 === "Filter" ? "/jobs.json?" + Utils.stringify(match$9.VAL) : "/jobs/view_settings"
            );
        }
      } else {
        p = match$9 === "Index" ? "/jobs" : (
            match$9 === "UploadStatus" ? "/jobs/upload/current" : (
                match$9 === "Export" ? "/jobs/export" : (
                    match$9 === "New" ? "/jobs/new" : (
                        match$9 === "Bulk" ? "/jobs/bulk" : "/jobs/upload"
                      )
                  )
              )
          );
      }
    } else if (variant === "Log") {
      p = "/log";
    } else if (variant === "Map") {
      var match$13 = match.VAL;
      var variant$3 = match$13.NAME;
      if (variant$3 === "Jobs") {
        var match$14 = match$13.VAL;
        var period = match$14.period;
        var templateUuid = RCore.$$Option.map(match$14.template, (function (uuid) {
                return [[
                          "template_uuid",
                          Uuid.toString(uuid)
                        ]];
              }));
        var territoryUuid = RCore.$$Option.map(match$14.territory, (function (uuid) {
                return [[
                          "territory_uuid",
                          Uuid.toString(uuid)
                        ]];
              }));
        var typeUuid = RCore.$$Option.map(match$14.jobType, (function (uuid) {
                return [[
                          "type_uuid",
                          Uuid.toString(uuid)
                        ]];
              }));
        var dateFilter;
        if (typeof period === "object") {
          if (period.NAME === "Date") {
            dateFilter = [[
                "date",
                Locale.T.formatM("DateISO")(period.VAL)
              ]];
          } else {
            var match$15 = period.VAL;
            var d1s = Locale.T.formatM("DateISO")(match$15[0]);
            var d2s = Locale.T.formatM("DateISO")(match$15[1]);
            dateFilter = [
              [
                "from",
                d1s
              ],
              [
                "to",
                d2s
              ]
            ];
          }
        } else {
          dateFilter = undefined;
        }
        var boundingBox = RCore.$$Option.map(match$14.boundingBox, (function (param) {
                var northEast = param.northEast;
                var southWest = param.southWest;
                return [
                        [
                          "bounding_box[south_west][latitude]",
                          Types_Spatial.Latitude.toString(southWest.latitude)
                        ],
                        [
                          "bounding_box[south_west][longitude]",
                          Types_Spatial.Longitude.toString(southWest.longitude)
                        ],
                        [
                          "bounding_box[north_east][latitude]",
                          Types_Spatial.Latitude.toString(northEast.latitude)
                        ],
                        [
                          "bounding_box[north_east][longitude]",
                          Types_Spatial.Longitude.toString(northEast.longitude)
                        ]
                      ];
              }));
        p = "/map/jobs" + QueryUtils.makeQuery(RCore.$$Array.keepMap([
                    typeUuid,
                    templateUuid,
                    territoryUuid,
                    dateFilter,
                    boundingBox
                  ], Fun.id).flat());
      } else {
        p = variant$3 === "Show" ? (
            match$13.VAL === "Workers" ? "/map/workers" : ""
          ) : (
            variant$3 === "Location" ? "/map/workers/locations/" + Uuid.toString(match$13.VAL) : "/map/jobs/" + Uuid.toString(match$13.VAL)
          );
      }
    } else if (variant === "Template") {
      var match$16 = match.VAL;
      p = typeof match$16 === "object" ? (
          match$16.NAME === "Edit" ? "/admin/templates/" + Uuid.toString(match$16.VAL) : "/admin/templates/new"
        ) : "/admin/templates";
    } else if (variant === "RenderRequest") {
      var match$17 = match.VAL;
      p = typeof match$17 === "object" ? "/render_requests/" + Uuid.toString(match$17.VAL) : "/render_requests";
    } else if (variant === "SignInByToken") {
      p = "/sign-in-by-token/" + match.VAL[0];
    } else if (variant === "Analytics") {
      p = "/analytics/" + match.VAL;
    } else if (variant === "Skills") {
      var match$18 = match.VAL;
      p = typeof match$18 === "object" ? "/admin/skills?used_by=" + match$18.VAL : "/admin/skills";
    } else if (variant === "Files") {
      var match$19 = match.VAL;
      p = typeof match$19 === "object" ? "/files/" + match$19.VAL + "/uploaded" : "/files";
    } else if (variant === "Bitrix24") {
      var match$20 = match.VAL;
      p = typeof match$20 === "object" ? (
          match$20.NAME === "Show" ? "/admin/integrations/bitrix24/" + match$20.VAL : "/admin/integrations/bitrix24/" + match$20.VAL + "/sync-deals"
        ) : (
          match$20 === "New" ? "/admin/integrations/bitrix24/new" : "/admin/integrations/bitrix24"
        );
    } else if (variant === "Tasks") {
      var match$21 = match.VAL;
      p = match$21.NAME === "Client" ? "/tasks/" + Uuid.toString(match$21.VAL) : "/tasks/" + Uuid.toString(match$21.VAL);
    } else if (variant === "Schedule") {
      var match$22 = match.VAL;
      if (typeof match$22 === "object") {
        var variant$4 = match$22.NAME;
        p = variant$4 === "Shifts" ? "/schedule/v2/shifts" : (
            variant$4 === "Job" ? "/schedule/v2/jobs/" + Uuid.toString(match$22.VAL) : "/schedule/v2/jobs"
          );
      } else {
        p = "/schedule/v2";
      }
    } else if (variant === "ClientForm") {
      var match$23 = match.VAL;
      p = typeof match$23 === "object" ? (
          match$23.NAME === "Edit" ? "/admin/client-forms/" + Uuid.toString(match$23.VAL) : "/admin/client-forms/" + Uuid.toString(match$23.VAL) + "/download"
        ) : "/admin/client-forms";
    } else if (variant === "Products") {
      var match$24 = match.VAL;
      p = typeof match$24 === "object" ? (
          match$24.NAME === "Update" ? "/admin/products/" + Uuid.toString(match$24.VAL) : "/admin/products?" + Utils.stringify(match$24.VAL)
        ) : "/admin/products";
    } else if (variant === "Billing") {
      var match$25 = match.VAL;
      if (typeof match$25 === "object") {
        if (match$25.NAME === "Cards") {
          var match$26 = match$25.VAL;
          p = typeof match$26 === "object" ? "/admin/billing/cards/" + Uuid.toString(match$26.VAL) : "/admin/billing/cards";
        } else {
          p = "/admin/billing/bills/" + Uuid.toString(match$25.VAL.VAL) + "/pay";
        }
      } else {
        p = match$25 === "PaddleSubscription" ? "/admin/billing/paddle-subscription" : (
            match$25 === "Preview" ? "/admin/billing/new" : (
                match$25 === "Wizard" ? "/admin/billing/wizard" : (
                    match$25 === "RequestPricing" ? "/admin/billing/wizard/request-pricing" : "/admin/billing"
                  )
              )
          );
      }
    } else if (variant === "GeneralSettings") {
      p = match.VAL === "TransferOwner" ? "/admin/settings/transfer" : "/admin/settings";
    } else if (variant === "BackgroundTasks") {
      var match$27 = match.VAL;
      p = typeof match$27 === "object" ? "/background-tasks/" + Uuid.toString(match$27.VAL) + "/cancel" : (
          match$27 === "Read" ? "/background-tasks/read" : "/background-tasks"
        );
    } else if (variant === "Territories") {
      var match$28 = match.VAL;
      p = typeof match$28 === "object" ? (
          match$28.NAME === "Edit" ? "/admin/territories/" + Uuid.toString(match$28.VAL) : "/admin/territories?used_by=" + match$28.VAL
        ) : (
          match$28 === "Validate" ? "/admin/territories/validate" : (
              match$28 === "UpdateOrder" ? "/admin/territories/order" : "/admin/territories"
            )
        );
    } else if (variant === "Activation") {
      p = "/activate/" + match.VAL;
    } else if (variant === "Site") {
      var match$29 = match.VAL;
      if (typeof match$29 === "object") {
        if (match$29.NAME === "Edit") {
          p = "/sites/" + Uuid.toString(match$29.VAL);
        } else {
          var filter = match$29.VAL;
          p = filter !== undefined ? "/sites?filter=" + JSON.stringify(Pages_Sites_Types.Filter.toJson(filter)) : "/sites";
        }
      } else {
        p = match$29 === "New" ? "/sites/new" : "/sites/upload";
      }
    } else if (variant === "Team") {
      var match$30 = match.VAL;
      p = typeof match$30 === "object" ? (
          match$30.NAME === "TemporaryWorkers" ? "/teams/" + Uuid.toString(match$30.VAL.VAL) + "/temporary-workers" : "/teams/" + Uuid.toString(match$30.VAL)
        ) : (
          match$30 === "New" ? "/teams/new" : "/teams"
        );
    } else if (variant === "User") {
      var match$31 = match.VAL;
      if (typeof match$31 === "object") {
        var match$32 = match$31.VAL;
        p = typeof match$32 === "object" ? "/admin/users/" + Uuid.toString(match$32.VAL) : (
            match$32 === "Create" ? "/admin/users" : "/admin/users/new"
          );
      } else {
        p = "/admin/users";
      }
    } else if (variant === "Units") {
      var match$33 = match.VAL;
      p = typeof match$33 === "object" ? (
          match$33.NAME === "Update" ? "/admin/catalog-units/" + Uuid.toString(match$33.VAL) : "/admin/catalog-units?" + Utils.stringify(match$33.VAL)
        ) : "/admin/catalog-units";
    } else if (variant === "BulkOperations") {
      var match$34 = match.VAL;
      p = match$34.NAME === "Show" ? "/bulk-operations/" + Uuid.toString(match$34.VAL[0]) : "/bulk-operations/" + Uuid.toString(match$34.VAL) + "/retry";
    } else if (variant === "Dictionary") {
      var match$35 = match.VAL;
      p = typeof match$35 === "object" ? "/admin/dictionaries/" + Uuid.toString(match$35.VAL) : (
          match$35 === "New" ? "/admin/dictionaries/new" : "/admin/dictionaries"
        );
    } else if (variant === "Client") {
      var match$36 = match.VAL;
      if (typeof match$36 === "object") {
        var variant$5 = match$36.NAME;
        if (variant$5 === "Lookup") {
          p = "/clients/search?query=" + match$36.VAL;
        } else if (variant$5 === "Edit") {
          p = "/clients/" + Uuid.toString(match$36.VAL);
        } else {
          var filter$1 = match$36.VAL;
          p = filter$1 !== undefined ? "/clients?filter=" + JSON.stringify(Pages_Clients_Types.Filter.toJson(filter$1)) : "/clients";
        }
      } else {
        p = match$36 === "New" ? "/clients/new" : "/clients/upload";
      }
    } else if (variant === "Services") {
      var match$37 = match.VAL;
      p = typeof match$37 === "object" ? (
          match$37.NAME === "Update" ? "/admin/services/" + Uuid.toString(match$37.VAL) : "/admin/services?" + Utils.stringify(match$37.VAL)
        ) : "/admin/services";
    } else if (variant === "RecurringJob") {
      var match$38 = match.VAL;
      if (typeof match$38 === "object") {
        if (match$38.NAME === "Edit") {
          p = "/admin/recurring_jobs/" + Uuid.toString(match$38.VAL);
        } else {
          var params = match$38.VAL;
          var site = params.site;
          var client = params.client;
          var variablePart = client !== undefined ? (
              site !== undefined ? "" : "&client=" + Uuid.toString(Caml_option.valFromOption(client))
            ) : (
              site !== undefined ? "&site=" + Uuid.toString(Caml_option.valFromOption(site)) : ""
            );
          p = "/admin/recurring_jobs/new?template=" + Uuid.toString(params.template) + variablePart;
        }
      } else {
        p = "/admin/recurring_jobs";
      }
    } else {
      p = "/recover/" + match.VAL;
    }
  } else {
    p = match === "AuditLog" ? "/admin/audit-log" : (
        match === "amoCRM" ? "/admin/integrations/amocrm" : (
            match === "Session" ? "/session" : (
                match === "SendActivationMail" ? "/send-activation-mail" : (
                    match === "ActivateFieldUser" ? "/users/activate" : (
                        match === "ResolutionIndex" ? "/admin/settings/resolutions" : (
                            match === "Recover" ? "/recover" : (
                                match === "UsedResources" ? "/used-resources" : (
                                    match === "Login" ? "/login" : (
                                        match === "Attributes" ? "/attributes" : (
                                            match === "Password" ? "/password" : (
                                                match === "JobTypeIndex" ? "/admin/settings/job_types" : "/profile"
                                              )
                                          )
                                      )
                                  )
                              )
                          )
                      )
                  )
              )
          )
      );
  }
  var match$39 = route.VAL;
  if (typeof match$39 !== "object") {
    return p;
  }
  var variant$6 = match$39.NAME;
  if (variant$6 === "Shifts") {
    var match$40 = match$39.VAL;
    if (typeof match$40 !== "object") {
      return p;
    }
    if (match$40.NAME !== "Index") {
      return p;
    }
    var match$41 = match$40.VAL;
    return p + "?date_from=" + match$41[0] + "&date_to=" + match$41[1] + uuidsToString("user_uuids", match$41[2]) + uuidsToString("team_uuids", match$41[3]);
  }
  if (variant$6 === "Workers") {
    var params$1 = match$39.VAL.VAL;
    var query = workersWithTimeIntervalRequestParams(params$1.workerUuids, params$1.period, undefined, undefined, undefined);
    return p + query;
  }
  if (variant$6 === "Job") {
    var match$42 = match$39.VAL;
    if (typeof match$42 !== "object") {
      return p;
    }
    var variant$7 = match$42.NAME;
    if (variant$7 === "Find") {
      return p + "?query=" + match$42.VAL;
    }
    if (variant$7 !== "Overlapping") {
      return p;
    }
    var params$2 = match$42.VAL;
    var except = params$2.except;
    var filterParam = [[
        "filter",
        "overlapping"
      ]];
    var exceptParam = except !== undefined ? [[
          "job_uuid",
          Uuid.toString(Caml_option.valFromOption(except))
        ]] : [];
    var addExtraParams = function (requiredParams) {
      return [
                filterParam,
                exceptParam,
                requiredParams
              ].flat();
    };
    var query$1 = workersWithTimeIntervalRequestParams(params$2.workerUuids, params$2.period, "worker_uuids", addExtraParams, undefined);
    return p + query$1;
  }
  if (variant$6 === "Log") {
    return p + "?message=" + match$39.VAL;
  }
  if (variant$6 === "Template") {
    var match$43 = match$39.VAL;
    if (typeof match$43 !== "object") {
      return p;
    }
    if (match$43.NAME !== "New") {
      return p;
    }
    var copy = match$43.VAL;
    if (copy !== undefined) {
      return p + "?copy=" + Uuid.toString(Caml_option.valFromOption(copy));
    } else {
      return p;
    }
  }
  if (variant$6 === "SignInByToken") {
    var redirect = match$39.VAL[1];
    if (redirect !== undefined) {
      return p + "?redirect=" + redirect;
    } else {
      return p;
    }
  }
  if (variant$6 === "Schedule") {
    var match$44 = match$39.VAL;
    if (typeof match$44 !== "object") {
      return p;
    }
    var variant$8 = match$44.NAME;
    if (variant$8 === "UnscheduledJobs") {
      var match$45 = match$44.VAL;
      var jobsAfter = match$45[1];
      var match$46 = match$45[0];
      var skills = match$46.skills;
      var territoriesStr = uuidsToString("territories", match$46.territories);
      var skillsStr = uuidsToString("skills", skills.uuids);
      var skillsModeStr = "&skills_mode=" + Schedule_Types_Settings.Filter.Skills.Mode.toString(skills.mode);
      var nextFromStr = jobsAfter !== undefined ? "&after=" + Schedule_Types_Job.JobsAfter.toInt(Caml_option.valFromOption(jobsAfter)).toString() : "";
      var jobDurationStr = "&job_duration=" + Schedule_Types_JobDuration.toString(match$45[2]);
      return p + "?unscheduled_jobs=true" + territoriesStr + skillsStr + skillsModeStr + nextFromStr + jobDurationStr;
    }
    if (variant$8 === "Shifts") {
      var period$1 = match$44.VAL.period;
      var f = Serialization.$$Date.iso8601;
      var from = f(period$1[0]);
      var to_ = f(period$1[1]);
      return p + QueryUtils.makeQuery([
                  [
                    "from",
                    from
                  ],
                  [
                    "to",
                    to_
                  ]
                ]);
    }
    if (variant$8 !== "ScheduledJobs") {
      return p;
    }
    var match$47 = match$44.VAL;
    var match$48 = match$47[0];
    var period$2 = match$48.period;
    var skills$1 = match$48.skills;
    var f$1 = Serialization.$$Date.iso8601;
    var from$1 = f$1(period$2[0]);
    var to_$1 = f$1(period$2[1]);
    var periodStr = QueryUtils.makeQuery([
          [
            "from",
            from$1
          ],
          [
            "to",
            to_$1
          ]
        ]);
    var territoriesStr$1 = uuidsToString("territories", match$48.territories);
    var skillsStr$1 = uuidsToString("skills", skills$1.uuids);
    var filterAssignedStr = "&filter_assigned=" + PervasivesU.string_of_bool(match$47[1]);
    var skillsModeStr$1 = "&skills_mode=" + Schedule_Types_Settings.Filter.Skills.Mode.toString(skills$1.mode);
    var jobDurationStr$1 = "&job_duration=" + Schedule_Types_JobDuration.toString(match$47[2]);
    return p + periodStr + territoriesStr$1 + skillsStr$1 + skillsModeStr$1 + filterAssignedStr + jobDurationStr$1 + "&unscheduled_jobs=false";
  }
  if (variant$6 === "Client" || variant$6 === "Site") {
    var match$49 = match$39.VAL;
    if (typeof match$49 === "object" && match$49.NAME === "Edit") {
      return p + "?form=true";
    } else {
      return p;
    }
  }
  if (variant$6 === "User") {
    var match$50 = match$39.VAL;
    if (typeof match$50 !== "object") {
      return p;
    }
    var variant$9 = match$50.NAME;
    if (variant$9 === "Custom") {
      return p + "?role=custom";
    } else if (variant$9 === "Field") {
      return p + "?role=field";
    } else {
      return p;
    }
  }
  if (variant$6 !== "BulkOperations") {
    return p;
  }
  var match$51 = match$39.VAL;
  if (typeof match$51 === "object" && match$51.NAME === "Show") {
    return p + "?" + Utils.stringify(match$51.VAL[1]);
  } else {
    return p;
  }
}

export {
  uuidsToString ,
  workersWithTimeIntervalRequestParams ,
  path ,
}
/* Uuid Not a pure module */
