

import * as Fun from "../../libraries/Fun.mjs";
import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as Wire from "../../types/Wire.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as MapAPI from "./MapAPI.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as MapJobs from "./jobs/MapJobs.mjs";
import * as MapTypes from "./MapTypes.mjs";
import * as Provider from "../../Provider.mjs";
import * as Websocket from "../../libraries/Websocket.mjs";
import * as ContextAPI from "../../libraries/ContextAPI.mjs";
import * as MapPanelJS from "./MapPanelJS.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Context_Auth from "../../context/Context_Auth.mjs";
import * as Subscription from "../../libraries/Subscription.mjs";
import * as MapFocusedJob from "./MapFocusedJob.mjs";
import * as MapViewportJS from "./MapViewportJS.mjs";
import * as MapColorsLegend from "./MapColorsLegend.mjs";
import * as MapPageFakeData from "./MapPageFakeData.mjs";
import * as MapAccuracyLegend from "./MapAccuracyLegend.mjs";
import * as Shared_Lib_Reducer from "../../shared/lib/Shared_Lib_Reducer.mjs";
import * as $$Map from "planado/stores/map";
import * as $$Map$1 from "planado/actions/map";
import * as Index from "planado/stores/map/index";
import * as MapShowModuleCss from "/home/runner/work/planado/planado/client/rescript/components/map/MapShow.module.css";

var workers$p = Utils.Translations.tr("js.map.workers");

var jobs$p = Utils.Translations.tr("js.map.jobs");

function empty(wire) {
  return {
          store: undefined,
          engineChannel: MapTypes.EngineChannel.make(),
          engineEvents: Subscription.make(),
          updates: Subscription.make(),
          currentJobs: [],
          focusedJob: undefined,
          prevRoute: wire.route
        };
}

function reducer(wire, mode) {
  return function (param) {
    var action = param[1];
    var state = param[0];
    if (typeof action !== "object") {
      switch (action) {
        case "BlurJob" :
            return {
                    TAG: "Update",
                    _0: {
                      store: state.store,
                      engineChannel: state.engineChannel,
                      engineEvents: state.engineEvents,
                      updates: state.updates,
                      currentJobs: state.currentJobs,
                      focusedJob: undefined,
                      prevRoute: state.prevRoute
                    }
                  };
        case "CloseJobModal" :
        case "NoOp" :
            return "NoUpdate";
        
      }
    } else {
      switch (action.TAG) {
        case "OpenJobModal" :
            return {
                    TAG: "Update",
                    _0: {
                      store: state.store,
                      engineChannel: state.engineChannel,
                      engineEvents: state.engineEvents,
                      updates: state.updates,
                      currentJobs: state.currentJobs,
                      focusedJob: state.focusedJob,
                      prevRoute: action._0
                    }
                  };
        case "Loaded" :
            var match = action._0;
            if (match.TAG !== "Ok") {
              return "NoUpdate";
            }
            var data = match._0;
            if (data === undefined) {
              return "NoUpdate";
            }
            var match$1 = wire.ctx.env;
            var stateData = match$1 === "development" ? MapPageFakeData.State.mergeWithReal(MapPageFakeData.State.make(wire), data) : data;
            var store = $$Map.createMapStore({
                  data: stateData,
                  branding: Js_null.fromOption(RCore.$$Option.map(wire.ctx.branding, ContextAPI.ContextJs.Branding.encode)),
                  engineChannel: state.engineChannel
                });
            var decodedJobs = MapAPI.Show.decode(stateData);
            return {
                    TAG: "Update",
                    _0: {
                      store: Caml_option.some(store),
                      engineChannel: state.engineChannel,
                      engineEvents: state.engineEvents,
                      updates: state.updates,
                      currentJobs: decodedJobs,
                      focusedJob: state.focusedJob,
                      prevRoute: state.prevRoute
                    }
                  };
        case "EngineEvent" :
            var jobUuid = action._0._0;
            var job = RCore.$$Array.getBy(state.currentJobs, (function (param) {
                    return Uuid.equal(param.uuid, jobUuid);
                  }));
            var match$2 = state.focusedJob;
            if (job === undefined) {
              if (match$2 !== undefined && mode === "Workers") {
                return {
                        TAG: "Update",
                        _0: {
                          store: state.store,
                          engineChannel: state.engineChannel,
                          engineEvents: state.engineEvents,
                          updates: state.updates,
                          currentJobs: state.currentJobs,
                          focusedJob: undefined,
                          prevRoute: state.prevRoute
                        }
                      };
              } else {
                return "NoUpdate";
              }
            }
            if (match$2 !== undefined) {
              if (mode !== "Workers") {
                return "NoUpdate";
              }
              if (Uuid.equal(job.uuid, match$2.uuid)) {
                return {
                        TAG: "Update",
                        _0: {
                          store: state.store,
                          engineChannel: state.engineChannel,
                          engineEvents: state.engineEvents,
                          updates: state.updates,
                          currentJobs: state.currentJobs,
                          focusedJob: undefined,
                          prevRoute: state.prevRoute
                        }
                      };
              }
              
            }
            if (mode === "Workers") {
              return {
                      TAG: "Update",
                      _0: {
                        store: state.store,
                        engineChannel: state.engineChannel,
                        engineEvents: state.engineEvents,
                        updates: state.updates,
                        currentJobs: state.currentJobs,
                        focusedJob: job,
                        prevRoute: state.prevRoute
                      }
                    };
            } else {
              return "NoUpdate";
            }
            break;
        
      }
    }
  };
}

var styles = MapShowModuleCss;

function MapPage(Props) {
  var wire = Props.wire;
  var mode = Props.mode;
  var navigate = wire.navigate;
  var route = wire.route;
  var subscriptions = wire.subscriptions;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        
      });
  var setBoundingBox = match[1];
  var socket = Context_Auth.useSocket();
  var channel = Websocket.useChannel(socket, {
        NAME: "mapV1",
        VAL: wire.ctx.tenantUuid
      });
  var match$1 = Shared_Lib_Reducer.use(reducer(wire, mode), empty(wire));
  var send = match$1[1];
  var state = match$1[0];
  var keyDown = function (param) {
    var next = param[1];
    var $$event = param[0];
    var match = $$event.code;
    var match$1 = state.store;
    switch (match) {
      case "ArrowDown" :
          if (match$1 !== undefined) {
            $$event.preventDefault();
            Caml_option.valFromOption(match$1).dispatch($$Map$1.nextLocation());
            return ;
          } else {
            return next($$event);
          }
      case "ArrowUp" :
          if (match$1 !== undefined) {
            $$event.preventDefault();
            Caml_option.valFromOption(match$1).dispatch($$Map$1.prevLocation());
            return ;
          } else {
            return next($$event);
          }
      default:
        return next($$event);
    }
  };
  var focusedJob = state.focusedJob;
  var updates = state.updates;
  var engineEvents = state.engineEvents;
  var engineChannel = state.engineChannel;
  var store = state.store;
  Websocket.useSubscription(channel, "location_update", (function (payload) {
          updates.trigger({
                NAME: "LocationUpdate",
                VAL: payload
              });
        }));
  Websocket.useSubscription(channel, "job_created", (function (payload) {
          updates.trigger({
                NAME: "JobCreated",
                VAL: payload
              });
        }));
  Websocket.useSubscription(channel, "job_updated", (function (payload) {
          updates.trigger({
                NAME: "JobUpdated",
                VAL: payload
              });
        }));
  Websocket.useSubscription(channel, "job_removed", (function (payload) {
          updates.trigger({
                NAME: "JobRemoved",
                VAL: payload
              });
        }));
  React.useEffect((function () {
          Backend.$$finally(MapAPI.Show.index(wire), (function (v) {
                  send({
                        TAG: "Loaded",
                        _0: v
                      });
                }));
          return engineEvents.subscribe(function (v) {
                      send({
                            TAG: "EngineEvent",
                            _0: v
                          });
                    });
        }), []);
  React.useEffect((function () {
          return subscriptions.keydown.subscribe(keyDown);
        }), [state]);
  var onBoundsChange = function (boundingBox) {
    var match = wire.ctx.mapType;
    if (typeof match === "object") {
      
    } else if (match === "Yandex") {
      wire.usedResources.track("yandexMapsRequest");
    } else {
      wire.usedResources.track("googleMapsRequest");
    }
    setBoundingBox(function (param) {
          return boundingBox;
        });
    send("BlurJob");
  };
  if (store === undefined) {
    return null;
  }
  var store$1 = Caml_option.valFromOption(store);
  var liStyle = function (x) {
    return Fun.choose(styles.active, "", x);
  };
  var openJob = function (uuid) {
    send({
          TAG: "OpenJobModal",
          _0: route
        });
    Wire.openJob(undefined, undefined, wire, uuid);
  };
  var unfocusJob = function () {
    send("BlurJob");
  };
  var tmp;
  tmp = mode === "Workers" ? React.createElement(MapPanelJS.make, {
          openJob: (function (id) {
              openJob(id);
            })
        }) : React.createElement(MapJobs.make, {
          wire: wire,
          channel: engineChannel,
          openJob: (function (id) {
              openJob(id);
            }),
          unfocusJob: unfocusJob,
          updates: updates,
          engineEvents: engineEvents,
          boundingBox: match[0]
        });
  var tmp$1;
  tmp$1 = mode === "Workers" ? React.createElement(MapAccuracyLegend.make, {
          wire: wire
        }) : React.createElement(MapColorsLegend.make, {
          wire: wire
        });
  return React.createElement(React.Fragment, undefined, React.createElement(Provider.make, {
                  store: store$1,
                  children: React.createElement(Index.Subscriptions, {
                        store: store$1,
                        channel: channel,
                        children: React.createElement("div", {
                              className: styles.map
                            }, React.createElement("div", {
                                  className: styles.panel
                                }, React.createElement("ul", {
                                      className: styles.mapMode
                                    }, React.createElement("li", {
                                          className: liStyle(mode === "Workers"),
                                          onClick: (function (param) {
                                              navigate(undefined, {
                                                    NAME: "MapShow",
                                                    VAL: "Workers"
                                                  });
                                            })
                                        }, workers$p(ctx)), React.createElement("li", {
                                          className: liStyle(mode !== "Workers"),
                                          onClick: (function (param) {
                                              navigate(undefined, {
                                                    NAME: "MapShow",
                                                    VAL: "Jobs"
                                                  });
                                            })
                                        }, jobs$p(ctx))), React.createElement("hr", {
                                      className: styles.bar
                                    }), tmp), React.createElement(MapViewportJS.make, {
                                  onBoundsChange: onBoundsChange,
                                  openJob: (function (id) {
                                      openJob(id);
                                    }),
                                  onJobClick: (function (uuid) {
                                      engineEvents.trigger({
                                            TAG: "JobClicked",
                                            _0: uuid
                                          });
                                    }),
                                  ctx: ctx
                                }), tmp$1)
                      })
                }), focusedJob !== undefined ? React.createElement(MapFocusedJob.make, {
                    wire: wire,
                    job: focusedJob,
                    onClick: (function (_event) {
                        openJob(focusedJob.uuid);
                      }),
                    onClose: (function (_event) {
                        send("BlurJob");
                      }),
                    channel: engineChannel,
                    key: Uuid.toString(focusedJob.uuid)
                  }) : null);
}

var State = {};

var make = MapPage;

export {
  State ,
  make ,
}
/* workers' Not a pure module */
