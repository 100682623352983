

import * as Fun from "../../../../../../../libraries/Fun.mjs";
import * as RCore from "../../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../../types/Types.mjs";
import * as Units from "../../../../../../../types/Units.mjs";
import * as Utils from "../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppContext from "../../../../../../../context/AppContext.mjs";
import * as Schedule_State from "../../../../../lib/state/Schedule_State.mjs";
import * as WeekTimeline_State from "../../../lib/state/WeekTimeline_State.mjs";
import * as WeekTimeline_Constants from "../../../lib/WeekTimeline_Constants.mjs";
import * as WeekTimeline_ScaleModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/timeline/scale/WeekTimeline_Scale.module.css";

function day$p(num) {
  return Utils.Translations.t("js.schedule.v2.abbr_day_names." + num.toString());
}

var styles = WeekTimeline_ScaleModuleCss;

function elementBounds(element) {
  var rect = element.getBoundingClientRect();
  return [
          Units.Px.minus(Units.Px.fromFloat(rect.left), WeekTimeline_Constants.sidebarWidth),
          Units.Px.minus(Units.Px.fromFloat(rect.right), WeekTimeline_Constants.sidebarWidth)
        ];
}

function inBounds(x, param) {
  if (Caml_obj.greaterequal(x, param[0])) {
    return Caml_obj.lessequal(x, param[1]);
  } else {
    return false;
  }
}

function use() {
  var locked = Schedule_State.useLocked();
  var active = React.useRef(false);
  var elementRef = Types.ReactRef.use();
  var match = WeekTimeline_State.useTimelineSubscriptions();
  var onMouseLeave = match.onMouseLeave;
  var onMouseMove = match.onMouseMove;
  var resetActive = function (element) {
    requestAnimationFrame(function (_time) {
          element.classList.remove(styles.active);
          active.current = false;
        });
  };
  React.useEffect((function () {
          var element = Types.ReactRef.toOption(elementRef);
          var bounds = RCore.$$Option.map(element, elementBounds);
          var match = Fun.both(element, bounds);
          if (match === undefined) {
            return ;
          }
          if (locked) {
            return ;
          }
          var bounds$1 = match[1];
          var element$1 = match[0];
          var unsubscribeFromMouseMove = onMouseMove.subscribe(function (coordinate) {
                var inBounds$1 = inBounds(coordinate[0], bounds$1);
                if (!active.current && inBounds$1) {
                  requestAnimationFrame(function (_time) {
                        element$1.classList.add(styles.active);
                        active.current = true;
                      });
                  return ;
                } else if (active.current && !inBounds$1) {
                  return resetActive(element$1);
                } else {
                  return ;
                }
              });
          var unsubscribeFromMouseLeave = onMouseLeave.subscribe(function () {
                resetActive(element$1);
              });
          return (function () {
                    resetActive(element$1);
                    unsubscribeFromMouseMove();
                    unsubscribeFromMouseLeave();
                  });
        }), [locked]);
  return elementRef;
}

function WeekTimeline_ScaleDay(Props) {
  var day = Props.day;
  var wire = AppContext.useWire();
  var weekDay = day$p(Locale.T.isoWeekday(day))(wire.ctx);
  var monthDay = Locale.T.fmtM("DayAndMonthAbbr", wire)(day).replace(".", "");
  var weekDay$1 = monthDay + ", " + weekDay;
  var elementRef = use();
  return React.createElement("div", {
              ref: elementRef,
              className: styles.day
            }, weekDay$1);
}

var make = React.memo(WeekTimeline_ScaleDay);

export {
  make ,
}
/* styles Not a pure module */
