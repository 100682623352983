

import * as Units from "../../../../../../../../../types/Units.mjs";
import * as React from "react";
import * as Locale from "../../../../../../../../../libraries/Locale.mjs";
import * as AppContext from "../../../../../../../../../context/AppContext.mjs";
import * as ModalQuery from "../../../../../../../../../types/ModalQuery.mjs";
import * as Assignee_Types from "../../../../../../../../../types/Assignee_Types.mjs";
import * as Schedule_State from "../../../../../../../lib/state/Schedule_State.mjs";
import * as Schedule_Popover from "../../../../../../../components/popovers/popover/Schedule_Popover.mjs";
import * as DayTimeline_State from "../../../../../lib/state/DayTimeline_State.mjs";
import * as Schedule_AddJobPanel from "../../../../../../../components/add_job_panel/Schedule_AddJobPanel.mjs";
import * as Schedule_UtilsTimeline from "../../../../../../../lib/utils/Schedule_UtilsTimeline.mjs";

function getScheduledAt(businessHours, currentDay, sizes, viewportEl, coordinate) {
  var match = Schedule_UtilsTimeline.ConvertPoint.fromPageToNode(viewportEl, coordinate);
  var offsetInMinutes = Units.Px.toInt(Units.Px.multiplyWithInt(Units.Px.round(Units.Px.divideByInt(Units.Px.divide(match[0], sizes.minute), 5)), 5));
  var startOfDay = businessHours !== undefined ? Locale.T.add(businessHours.start, currentDay) : currentDay;
  return Locale.T.nextMinutes(offsetInMinutes, startOfDay);
}

function DayTimeline_AddJobPopover(Props) {
  var assignee = Props.assignee;
  var coordinate = Props.coordinate;
  var viewportEl = Props.viewportEl;
  var close = Props.close;
  var match = AppContext.useWire();
  var openModal = match.openModal;
  var sizes = DayTimeline_State.useSizes();
  var currentDay = Schedule_State.useCurrentDay();
  var businessHours = Schedule_State.useBusinessHours();
  var scheduledAt = getScheduledAt(businessHours, currentDay, sizes, viewportEl, coordinate);
  var addJob = function (templateUuid) {
    return function ($$event) {
      $$event.preventDefault();
      var options = {
        TAG: "Schedule",
        _0: ModalQuery.Job.NewOptions.Schedule.make(Locale.T.formatM("DateTimeISO")(scheduledAt), {
              NAME: "Uuid",
              VAL: Assignee_Types.Assignee.uuid(assignee)
            }, templateUuid)
      };
      openModal({
            NAME: "Job",
            VAL: {
              NAME: "New",
              VAL: options
            }
          });
      close();
    };
  };
  var clickTargetPosition = {
    NAME: "Coordinate",
    VAL: coordinate
  };
  return React.createElement(Schedule_Popover.make, {
              clickTargetPosition: clickTargetPosition,
              children: React.createElement(Schedule_AddJobPanel.make, {
                    scheduledAt: scheduledAt,
                    addJob: addJob,
                    close: close
                  })
            });
}

var make = DayTimeline_AddJobPopover;

export {
  make ,
}
/* react Not a pure module */
