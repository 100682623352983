

import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as React from "react";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as Schedule_UtilsBusinessHours from "../../../../lib/utils/Schedule_UtilsBusinessHours.mjs";
import * as WeekCalendar_ScaleModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_calendar/components/scale/WeekCalendar_Scale.module.css";

var styles = WeekCalendar_ScaleModuleCss;

function WeekCalendar_Scale(Props) {
  var wire = AppContext.useWire();
  var businessHours = Schedule_State.useBusinessHours();
  var startOfDay = Schedule_UtilsBusinessHours.startOfDay(undefined, wire, businessHours);
  var hours = RCore.$$Array.make(Schedule_UtilsBusinessHours.hoursInDay(businessHours), startOfDay).map(function (d, i) {
        return Locale.T.nextHours(i)(d);
      });
  var cells = hours.map(function (hour, idx) {
        return React.createElement("div", {
                    key: idx.toString(),
                    className: styles.cell
                  }, Locale.T.fmtM("Time", wire)(hour));
      });
  return React.createElement("div", {
              className: styles.column
            }, cells);
}

var make = WeekCalendar_Scale;

export {
  make ,
}
/* styles Not a pure module */
