import { connect } from 'react-redux'

import { hideFocusedWorker } from 'planado/actions/map'
import FocusedWorker from 'planado/components/map/focused_worker'

const mapStateToProps = ({
  workers,
  latestLocations,
  focusedWorkerUuid,
  engineChannel,
  currentTime
}) => {
  return {
    worker: focusedWorkerUuid === null ? null : workers[focusedWorkerUuid],
    location: latestLocations[focusedWorkerUuid] || null,
    engineChannel,
    currentTime
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClose: () => dispatch(hideFocusedWorker())
  }
}

const FocusedWorkerLink = connect(
  mapStateToProps,
  mapDispatchToProps
)(FocusedWorker)

export default FocusedWorkerLink
