

import * as El from "../../../../../../../../../libraries/El.mjs";
import * as Icon from "../../../../../../../../../Icon.mjs";
import * as RCore from "../../../../../../../../../libraries/RCore.mjs";
import * as React from "react";
import * as Locale from "../../../../../../../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as JobForm_HistoryMapDateSelectorModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/edit/form/components/history/components/map/date_selector/JobForm_HistoryMapDateSelector.module.css";

var styles = JobForm_HistoryMapDateSelectorModuleCss;

function JobForm_HistoryMapDateSelector$DateButton(Props) {
  var wire = Props.wire;
  var role = Props.role;
  var date = Props.date;
  var onSelectDate = Props.onSelectDate;
  var selectedDate = Props.selectedDate;
  var base;
  if (typeof role !== "object") {
    switch (role) {
      case "Left" :
          base = El.Cn.concat(styles.dateButton, styles.left);
          break;
      case "Right" :
          base = El.Cn.concat(styles.dateButton, styles.right);
          break;
      default:
        base = styles.dateButton;
    }
  } else {
    base = styles.dateButton;
  }
  var className = Caml_obj.equal(date, selectedDate) ? El.Cn.concat(base, styles.selected) : base;
  var dateString = Locale.T.fmtM("DateShort", wire)(date);
  var onClickDateOffset = function ($$event) {
    $$event.preventDefault();
    if (typeof role !== "object") {
      return ;
    } else {
      return role._0();
    }
  };
  var prevButton;
  prevButton = typeof role !== "object" || role.TAG !== "LeftWithDateOffset" ? null : React.createElement("div", {
          className: El.Cn.concat(styles.offsetButton, styles.left),
          onClick: onClickDateOffset
        }, React.createElement("i", {
              className: Icon.style(undefined, undefined, "chevron-left")
            }));
  var nextButton;
  nextButton = typeof role !== "object" || role.TAG !== "RightWithDateOffset" ? null : React.createElement("div", {
          className: El.Cn.concat(styles.offsetButton, styles.right),
          onClick: onClickDateOffset
        }, React.createElement("i", {
              className: Icon.style(undefined, undefined, "chevron-right")
            }));
  var onClickSelectDate = function ($$event) {
    $$event.preventDefault();
    onSelectDate(date);
  };
  return React.createElement(React.Fragment, undefined, prevButton, React.createElement("div", {
                  className: className,
                  onClick: onClickSelectDate
                }, dateString), nextButton);
}

function JobForm_HistoryMapDateSelector$DateSelector(Props) {
  var wire = Props.wire;
  var onSelectDate = Props.onSelectDate;
  var selectedDate = Props.selectedDate;
  var dates = Props.dates;
  var match = React.useState(function () {
        var index = RCore.$$Array.findIndexOpt(dates, (function (__x) {
                return Locale.T.equal(__x, selectedDate);
              }));
        if (index !== undefined && index !== 0) {
          return index - 1 | 0;
        } else {
          return 0;
        }
      });
  var setIndex = match[1];
  var index = match[0];
  var datesCount = dates.length;
  var datesToDisplay = index < (dates.length - 3 | 0) ? dates.slice(index, 3) : dates.slice(-3);
  if (datesToDisplay.length !== 3) {
    return null;
  }
  var date1 = datesToDisplay[0];
  var date2 = datesToDisplay[1];
  var date3 = datesToDisplay[2];
  var match$1 = index === 0 ? [
      "Left",
      {
        TAG: "RightWithDateOffset",
        _0: (function () {
            setIndex(function (index) {
                  return index + 1 | 0;
                });
          })
      }
    ] : (
      index < (datesCount - 3 | 0) ? [
          {
            TAG: "LeftWithDateOffset",
            _0: (function () {
                setIndex(function (param) {
                      return index - 1 | 0;
                    });
              })
          },
          {
            TAG: "RightWithDateOffset",
            _0: (function () {
                setIndex(function (param) {
                      return index + 1 | 0;
                    });
              })
          }
        ] : [
          {
            TAG: "LeftWithDateOffset",
            _0: (function () {
                setIndex(function (param) {
                      return index - 1 | 0;
                    });
              })
          },
          "Right"
        ]
    );
  return React.createElement(React.Fragment, undefined, React.createElement(JobForm_HistoryMapDateSelector$DateButton, {
                  wire: wire,
                  role: match$1[0],
                  date: date1,
                  onSelectDate: onSelectDate,
                  selectedDate: selectedDate
                }), React.createElement(JobForm_HistoryMapDateSelector$DateButton, {
                  wire: wire,
                  role: "Center",
                  date: date2,
                  onSelectDate: onSelectDate,
                  selectedDate: selectedDate
                }), React.createElement(JobForm_HistoryMapDateSelector$DateButton, {
                  wire: wire,
                  role: match$1[1],
                  date: date3,
                  onSelectDate: onSelectDate,
                  selectedDate: selectedDate
                }));
}

function JobForm_HistoryMapDateSelector(Props) {
  var wire = Props.wire;
  var dates = Props.dates;
  var selectedDate = Props.selectedDate;
  var onSelectDate = Props.onSelectDate;
  var len = dates.length;
  var dateLayout;
  if (len >= 4) {
    dateLayout = React.createElement(JobForm_HistoryMapDateSelector$DateSelector, {
          wire: wire,
          onSelectDate: onSelectDate,
          selectedDate: selectedDate,
          dates: dates
        });
  } else {
    switch (len) {
      case 0 :
      case 1 :
          dateLayout = null;
          break;
      case 2 :
          var date1 = dates[0];
          var date2 = dates[1];
          dateLayout = React.createElement(React.Fragment, undefined, React.createElement(JobForm_HistoryMapDateSelector$DateButton, {
                    wire: wire,
                    role: "Left",
                    date: date1,
                    onSelectDate: onSelectDate,
                    selectedDate: selectedDate
                  }), React.createElement(JobForm_HistoryMapDateSelector$DateButton, {
                    wire: wire,
                    role: "Right",
                    date: date2,
                    onSelectDate: onSelectDate,
                    selectedDate: selectedDate
                  }));
          break;
      case 3 :
          var date1$1 = dates[0];
          var date2$1 = dates[1];
          var date3 = dates[2];
          dateLayout = React.createElement(React.Fragment, undefined, React.createElement(JobForm_HistoryMapDateSelector$DateButton, {
                    wire: wire,
                    role: "Left",
                    date: date1$1,
                    onSelectDate: onSelectDate,
                    selectedDate: selectedDate
                  }), React.createElement(JobForm_HistoryMapDateSelector$DateButton, {
                    wire: wire,
                    role: "Center",
                    date: date2$1,
                    onSelectDate: onSelectDate,
                    selectedDate: selectedDate
                  }), React.createElement(JobForm_HistoryMapDateSelector$DateButton, {
                    wire: wire,
                    role: "Right",
                    date: date3,
                    onSelectDate: onSelectDate,
                    selectedDate: selectedDate
                  }));
          break;
      
    }
  }
  return React.createElement("div", {
              className: styles.datesPanel
            }, dateLayout);
}

var make = JobForm_HistoryMapDateSelector;

export {
  make ,
}
/* styles Not a pure module */
