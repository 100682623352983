

import * as Wire from "../../../../../../types/Wire.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as MomentRe from "../../../../../../bindings/moment/MomentRe.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as WeekTimeline_PositionsHelper from "./WeekTimeline_PositionsHelper.mjs";

function endsInBH(start, startAt, finishAt) {
  var startOfDay = Locale.T.startOf("day", startAt);
  var startOfBusinessHours = Locale.T.add(start, startOfDay);
  if ((Locale.T.isoWeekday(finishAt) - Locale.T.isoWeekday(startAt) | 0) === 1) {
    return Locale.T.equalOrLessThan(finishAt, Locale.T.nextDay(startOfBusinessHours));
  } else {
    return false;
  }
}

function wholeInBusinessHours(start, finish, startAt, finishAt) {
  var startOfDay = Locale.T.startOf("day", startAt);
  var startOfBusinessHours = Locale.T.add(start, startOfDay);
  var endOfBusinessHours = Locale.T.add(finish, startOfDay);
  if (Locale.T.equalOrLessThan(finishAt, startOfBusinessHours)) {
    return true;
  } else if (endsInBH(start, startAt, finishAt)) {
    return Locale.T.equalOrMoreThan(startAt, endOfBusinessHours);
  } else {
    return false;
  }
}

function leftWithBH(wire, sizes, start, finish, startAt, finishAt) {
  if (wholeInBusinessHours(start, finish, startAt, finishAt)) {
    return ;
  }
  var startOfDay = Locale.T.startOf("day", startAt);
  var tmp;
  if (Locale.T.equalOrLessThan(startAt, startOfDay)) {
    tmp = Units.Px.zero;
  } else if (Locale.T.equalOrMoreThan(startAt, Locale.T.add(finish, startOfDay))) {
    tmp = sizes.day;
  } else {
    var mins = Locale.T.dateToMinutes(wire, startAt) - (MomentRe.Duration.asMinutes(start) | 0) | 0;
    tmp = Units.Px.plus(Units.Px.multiplyWithInt(sizes.minute, mins), WeekTimeline_PositionsHelper.nonBusinessHoursBlockWidth);
  }
  return Caml_option.some(tmp);
}

function get(wire, sizes, period, startAt, finishAt) {
  var startAt$1 = WeekTimeline_PositionsHelper.updateStartAt(wire, period, startAt);
  var match = Wire.businessHours(wire);
  var dayLeft = match !== undefined ? leftWithBH(wire, sizes, match.start, match.finish, startAt$1, finishAt) : Caml_option.some(Units.Px.multiplyWithInt(sizes.minute, Locale.T.dateToMinutes(wire, startAt$1)));
  return RCore.$$Option.flatMap(dayLeft, (function (px) {
                return Caml_option.some(Units.Px.plus(Units.Px.multiplyWithInt(sizes.day, Locale.T.isoWeekday(startAt$1)), px));
              }));
}

function firstDayWidth(wire, sizes, period, startAt, finishAt) {
  var startOfDay = WeekTimeline_PositionsHelper.startOfDay(wire, period, startAt);
  var endOfDay = WeekTimeline_PositionsHelper.endOfDay(wire, period, startAt);
  var isFullDayJob = Locale.T.equalOrLessThan(startAt, startOfDay) && Locale.T.equalOrMoreThan(finishAt, endOfDay);
  if (Locale.T.equalOrMoreThan(startAt, endOfDay)) {
    return Units.Px.zero;
  }
  if (isFullDayJob) {
    var isOneDayJob = Locale.T.equalDay(startAt, finishAt);
    return Units.Px.minus(sizes.day, isOneDayJob ? WeekTimeline_PositionsHelper.nonBusinessHoursBlockWidth : Units.Px.fromInt(4));
  }
  var finishAt$1 = Locale.T.equalOrMoreThan(finishAt, endOfDay) ? endOfDay : finishAt;
  var width = Units.Px.multiplyWithInt(sizes.minute, Locale.T.minutesBetween(finishAt$1, startAt));
  var match = Wire.businessHours(wire);
  var shiftWidth = match !== undefined && Locale.T.equalOrMoreThan(finishAt$1, endOfDay) ? WeekTimeline_PositionsHelper.nonBusinessHoursBlockWidth : Units.Px.zero;
  return Units.Px.plus(width, shiftWidth);
}

function lastDayWidth(wire, period, sizes, startAt, finishAt) {
  var match = Wire.businessHours(wire);
  if (match === undefined) {
    return Units.Px.multiplyWithInt(sizes.minute, Locale.T.minutesBetween(finishAt, WeekTimeline_PositionsHelper.startOfDay(wire, period, finishAt)));
  }
  var endOfDay = WeekTimeline_PositionsHelper.endOfDay(wire, period, finishAt);
  if (endsInBH(match.start, startAt, finishAt)) {
    return Units.Px.zero;
  }
  if (Locale.T.equalOrMoreThan(finishAt, endOfDay)) {
    return sizes.day;
  }
  var width = Units.Px.multiplyWithInt(sizes.minute, Locale.T.minutesBetween(finishAt, WeekTimeline_PositionsHelper.startOfDay(wire, period, finishAt)));
  var shiftWidth = Wire.businessHours(wire) !== undefined && Units.Px.notEqual(width, Units.Px.zero) ? Units.Px.fromInt(7) : Units.Px.zero;
  return Units.Px.plus(width, shiftWidth);
}

function otherDaysWidth(sizes, startAt, finishAt) {
  return Units.Px.multiplyWithInt(sizes.day, (Locale.T.isoWeekday(finishAt) - Locale.T.isoWeekday(startAt) | 0) - 1 | 0);
}

function get$1(wire, sizes, period, startAt, finishAt) {
  var startAt$1 = WeekTimeline_PositionsHelper.updateStartAt(wire, period, startAt);
  var finishAt$1 = WeekTimeline_PositionsHelper.updateFinishAt(wire, period, finishAt);
  var firstDayWidth$1 = firstDayWidth(wire, sizes, period, startAt$1, finishAt$1);
  if (Locale.T.equalDay(startAt$1, finishAt$1)) {
    return firstDayWidth$1;
  } else {
    return Units.Px.plus(Units.Px.plus(firstDayWidth$1, lastDayWidth(wire, period, sizes, startAt$1, finishAt$1)), otherDaysWidth(sizes, startAt$1, finishAt$1));
  }
}

function make(wire, period, sizes, workingIntervals) {
  return RCore.$$Array.filterMap(workingIntervals, (function (interval) {
                if (!interval.working) {
                  var startAt = interval.from;
                  var finishAt = interval.to_;
                  var left = get(wire, sizes, period, startAt, finishAt);
                  if (left !== undefined) {
                    return {
                            left: Caml_option.valFromOption(left),
                            width: get$1(wire, sizes, period, startAt, finishAt)
                          };
                  } else {
                    return ;
                  }
                }
                
              }));
}

var Left = {
  get: get
};

var Width = {
  get: get$1
};

export {
  Left ,
  Width ,
  make ,
}
/* Wire Not a pure module */
