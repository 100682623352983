

import * as Utils from "../../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as JobForm_HistoryHeaderModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/edit/form/components/history/components/header/JobForm_HistoryHeader.module.css";

var headline$p = Utils.Translations.tr("js.jobs.history.header.headline");

var styles = JobForm_HistoryHeaderModuleCss;

function JobForm_HistoryHeader$Title(Props) {
  var ctx = Props.ctx;
  return React.createElement("div", {
              className: styles.headline
            }, headline$p(ctx));
}

var Title = {
  make: JobForm_HistoryHeader$Title
};

export {
  Title ,
}
/* headline' Not a pure module */
