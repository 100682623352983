import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'
import LimitedInput from 'planado/components/input/limited_input'
import BaseFormInput from 'planado/components/input/base_form_input'

const FormInput = ({
  id,
  name = '',
  type,
  value,
  labelText,
  children,
  onValueChange,
  wrapperClass = {},
  labelClass = {},
  inputClass = {},
  errors,
  horizontal = false,
  maxLength,
  ...rest
}) => {
  const inputClassNames = classNames({
    'pd-form-control': true,
    ...inputClass
  })

  let formInput

  const inputEl = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      if (inputEl.current && rest.autoFocus) {
        inputEl.current.focus()
      }
    }, 10)
  }, [])

  if (maxLength) {
    formInput = (
      <LimitedInput
        id={id}
        name={name}
        type={type}
        value={value}
        onValueChange={onValueChange}
        className={inputClassNames}
        maxLength={maxLength}
        ref={inputEl}
        {...rest}
      />
    )
  } else {
    formInput = (
      <input
        id={id}
        name={name}
        type={type}
        value={value}
        onChange={evt => onValueChange(evt.target.value)}
        className={inputClassNames}
        ref={inputEl}
        {...rest}
      />
    )
  }

  return (
    <BaseFormInput
      id={id}
      labelText={labelText}
      labelClass={labelClass}
      wrapperClass={wrapperClass}
      horizontal={horizontal}
      errors={errors}
    >
      {children}
      {formInput}
    </BaseFormInput>
  )
}

export default FormInput
