

import * as Hint from "../../common/Hint/Hint.mjs";
import * as React from "react";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as Runtime from "../../../Runtime.mjs";
import * as Optional from "../../common/Optional.mjs";
import * as LimitedInput from "../../inputs/LimitedInput.mjs";
import * as AddressInput_Types from "../AddressInput_Types.mjs";
import * as AddressInput_Common from "../AddressInput_Common.mjs";
import * as AddressInput_ResetButton from "../reset_button/AddressInput_ResetButton.mjs";
import * as AddressInput_SuggestionsBlock from "../suggestions_block/AddressInput_SuggestionsBlock.mjs";

function keyDown(update, setFocused, focused, requestSuggestions, inputRef) {
  return function (suggestions, resolvedQueryConstraint) {
    return function (param) {
      var $$event = param[0];
      var lastSuggestion = suggestions.length - 1 | 0;
      var updateInputFromSuggestion = function (n) {
        var suggestion = suggestions[n];
        var match = suggestion !== undefined ? [
            AddressInput_Types.AddressConstraint.makeFromResponseItem(suggestion),
            n
          ] : [
            AddressInput_Types.AddressConstraint.makeFromString(resolvedQueryConstraint.formatted),
            0
          ];
        update(match[0]);
        return {
                TAG: "Suggestion",
                _0: match[1]
              };
      };
      if (suggestions.length <= 0) {
        return ;
      }
      var match = $$event.code;
      switch (match) {
        case "ArrowDown" :
            $$event.preventDefault();
            return setFocused(function (current) {
                        if (current === undefined) {
                          return ;
                        }
                        if (typeof current !== "object") {
                          return updateInputFromSuggestion(0);
                        }
                        var n = current._0;
                        if (n === lastSuggestion) {
                          update(resolvedQueryConstraint);
                          return "Input";
                        } else {
                          return updateInputFromSuggestion(n + 1 | 0);
                        }
                      });
        case "ArrowUp" :
            $$event.preventDefault();
            return setFocused(function (current) {
                        if (current === undefined) {
                          return ;
                        }
                        if (typeof current !== "object") {
                          return updateInputFromSuggestion(lastSuggestion);
                        }
                        var n = current._0;
                        if (n !== 0) {
                          return updateInputFromSuggestion(n - 1 | 0);
                        } else {
                          update(resolvedQueryConstraint);
                          return "Input";
                        }
                      });
        case "Enter" :
            $$event.preventDefault();
            if (focused === undefined) {
              return ;
            }
            if (typeof focused !== "object") {
              return ;
            }
            var chosenAddress = AddressInput_Types.AddressConstraint.makeFromResponseItem(suggestions[focused._0]);
            update(chosenAddress);
            requestSuggestions(chosenAddress.formatted);
            setFocused(function (param) {
                  
                });
            var ref = inputRef.current;
            if (!(ref == null)) {
              return ref.blur();
            } else {
              return ;
            }
        default:
          return param[1]($$event);
      }
    };
  };
}

function AddressInput_AddressConstraint(Props) {
  var wire = Props.wire;
  var initialConstraint = Props.initialConstraint;
  var onChange = Props.onChange;
  var suggestionsAmount = Props.suggestionsAmount;
  var disabled = Props.disabled;
  var match = React.useState(function () {
        return AddressInput_Types.AddressConstraint.makeFromSettings(initialConstraint);
      });
  var setConstraint = match[1];
  var constraint_ = match[0];
  var match$1 = React.useState(function () {
        return constraint_;
      });
  var setQueryConstraint = match$1[1];
  var queryConstraint = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setFocused = match$2[1];
  var focused = match$2[0];
  var match$3 = React.useState(function () {
        
      });
  var setResponse = match$3[1];
  var response = match$3[0];
  var inputId = React.useMemo((function () {
          return "form-input-formatted" + Js_math.random_int(0, 1000000).toString();
        }), []);
  var inputRef = React.useRef(null);
  var requestSuggestions = function (query) {
    AddressInput_Common.requestSuggestions("country", "settlement", undefined, suggestionsAmount, wire.ctx.dadataToken, query, (function (r) {
            wire.usedResources.track("dadataRequest");
            setResponse(function (param) {
                  return r.suggestions;
                });
          }), undefined);
  };
  var updateConstraint = function (constraint_) {
    setConstraint(function (param) {
          return constraint_;
        });
    onChange(AddressInput_Types.AddressConstraint.makeSettings(constraint_));
  };
  var onInputChange = function (value) {
    var constraint_ = AddressInput_Types.AddressConstraint.makeFromString(value);
    if (AddressInput_Common.needToDisplayOrRequest(value)) {
      requestSuggestions(value);
    }
    setQueryConstraint(function (param) {
          return constraint_;
        });
    if (value === "") {
      return updateConstraint(constraint_);
    } else {
      return setConstraint(function (param) {
                  return constraint_;
                });
    }
  };
  var onFocus = function (_evt) {
    setFocused(function (param) {
          return "Input";
        });
  };
  var onSelect = function (item) {
    return function (_evt) {
      var constraint_ = AddressInput_Types.AddressConstraint.makeFromResponseItem(item);
      requestSuggestions(constraint_.formatted);
      setFocused(function (param) {
            
          });
      updateConstraint(constraint_);
    };
  };
  var onBlur = function (_evt) {
    Runtime.Timeout.defer(200, (function () {
            setFocused(function (param) {
                  
                });
          }));
  };
  var onReset = function (_evt) {
    var emptyConstraint = AddressInput_Types.AddressConstraint.makeFromString("");
    setQueryConstraint(function (param) {
          return emptyConstraint;
        });
    setFocused(function (param) {
          
        });
    updateConstraint(emptyConstraint);
  };
  var keyDown$1 = keyDown(updateConstraint, setFocused, focused, requestSuggestions, inputRef);
  React.useEffect((function () {
          if (focused !== undefined && response !== undefined && queryConstraint !== undefined) {
            return wire.subscriptions.keydown.subscribe(keyDown$1(response, queryConstraint));
          }
          
        }), [
        focused,
        response,
        queryConstraint
      ]);
  return React.createElement("div", {
              className: "pd-form-group"
            }, React.createElement("label", {
                  className: "pd-label",
                  htmlFor: inputId
                }, AddressInput_Common.Translations.constraintLabel$p(wire.ctx)), React.createElement(Hint.make, {
                  content: AddressInput_Common.Translations.constraintHint$p(wire.ctx),
                  classes: AddressInput_Common.styles.constraintHint
                }), React.createElement("div", {
                  className: AddressInput_Common.styles.pdComplexFormControlContainer
                }, React.createElement(LimitedInput.make, {
                      maxLength: 200,
                      onValueChange: onInputChange,
                      value: constraint_.formatted,
                      id: inputId,
                      onFocus: onFocus,
                      onBlur: onBlur,
                      readOnly: false,
                      type_: "text",
                      className: "pd-form-control pd-form-control-with-icon",
                      disabled: disabled,
                      ref: inputRef
                    }), React.createElement(AddressInput_SuggestionsBlock.SuggestionsBlock.make, {
                      wire: wire,
                      address: {
                        TAG: "Constraint",
                        _0: constraint_
                      },
                      response: response,
                      focused: focused,
                      constraints: undefined,
                      onSelect: onSelect
                    }), React.createElement(Optional.make, {
                      show: constraint_.formatted !== "",
                      children: React.createElement(AddressInput_ResetButton.ResetButton.make, {
                            onReset: onReset,
                            inputType: "Constraint",
                            disabled: disabled
                          })
                    })));
}

var make = AddressInput_AddressConstraint;

export {
  make ,
}
/* Hint Not a pure module */
