

import * as RCore from "../../../../libraries/RCore.mjs";
import * as Units from "../../../../types/Units.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Subscription from "../../../../libraries/Subscription.mjs";
import * as Webapi__Dom__Node from "rescript-webapi/lib/es6/src/Webapi/Dom/Webapi__Dom__Node.mjs";
import * as Webapi__Dom__Element from "rescript-webapi/lib/es6/src/Webapi/Dom/Webapi__Dom__Element.mjs";
import * as Webapi2_Dom_TouchList from "../../../../libraries/webapi/dom/Webapi2_Dom_TouchList.mjs";
import * as Webapi__Dom__HtmlElement from "rescript-webapi/lib/es6/src/Webapi/Dom/Webapi__Dom__HtmlElement.mjs";

function make() {
  return {
          onTriggerScroll: Subscription.make()
        };
}

var Subscriptions = {
  make: make
};

var empty_x = Units.Px.zero;

var empty_y = Units.Px.zero;

var empty = {
  x: empty_x,
  y: empty_y
};

function minus(a, b) {
  return {
          x: Units.Px.minus(a.x, b.x),
          y: Units.Px.minus(a.y, b.y)
        };
}

var Coordinate = {
  empty: empty,
  minus: minus
};

function toString(scrollDirection) {
  switch (scrollDirection) {
    case "Vertical" :
        return "Vertical";
    case "Horizontal" :
        return "Horizontal";
    case "All" :
        return "All";
    
  }
}

var ScrollDirection = {
  toString: toString
};

var ScrollElement = {};

var emptyDimensions_width = Units.Px.zero;

var emptyDimensions_height = Units.Px.zero;

var emptyDimensions = {
  width: emptyDimensions_width,
  height: emptyDimensions_height
};

var emptyPosition_from = Units.Px.zero;

var emptyPosition_to_ = Units.Px.zero;

var emptyPosition = {
  from: emptyPosition_from,
  to_: emptyPosition_to_
};

function initial(initialPositionOpt, onScrollStartOpt, onScrollEndOpt, scrollElements, viewport, content, scrollArea, ignorePreventScroll, param) {
  var initialPosition = initialPositionOpt !== undefined ? initialPositionOpt : empty;
  var onScrollStart = onScrollStartOpt !== undefined ? onScrollStartOpt : (function (prim) {
        
      });
  var onScrollEnd = onScrollEndOpt !== undefined ? onScrollEndOpt : (function (prim) {
        
      });
  return {
          onScrollStart: onScrollStart,
          onScrollEnd: onScrollEnd,
          scrollElements: scrollElements,
          ignorePreventScroll: ignorePreventScroll,
          scrollAreaEl: scrollArea,
          viewportEl: viewport,
          contentEl: content,
          rafId: undefined,
          isDragging: false,
          isScrolling: false,
          isAnimationRunning: false,
          velocity: empty,
          position: initialPosition,
          dragOffset: empty,
          dragPosition: empty,
          dragStartPosition: empty,
          clientOffset: empty,
          scrollOffset: empty,
          dragOrigin: empty,
          clientOrigin: empty,
          viewport: emptyDimensions,
          content: emptyDimensions,
          edgeX: emptyPosition,
          edgeY: emptyPosition,
          wheelTimer: undefined
        };
}

function use(onScrollStart, onScrollEnd, initialPosition, scrollElements, viewport, content, scrollArea, ignorePreventScroll, param) {
  var initialState = initial(initialPosition, onScrollStart, onScrollEnd, scrollElements, viewport, content, scrollArea, ignorePreventScroll, undefined);
  var state = {
    contents: initialState
  };
  var setState = function (callback) {
    var prevState = state.contents;
    var nextState = callback(prevState);
    state.contents = nextState;
  };
  return {
          state: state,
          setState: setState
        };
}

var friction = Units.Px.fromFloat(0.95);

var clickEventThreshold = Units.Px.fromFloat(5.0);

var formNodes = [
  "input",
  "textarea",
  "button",
  "select",
  "label"
];

function applyDragForce(param) {
  if (param.state.contents.isDragging) {
    return param.setState(function (state) {
                var newrecord = Caml_obj.obj_dup(state);
                newrecord.velocity = minus(state.dragPosition, state.position);
                return newrecord;
              });
  }
  
}

function applyScrollForce(param) {
  if (param.state.contents.isScrolling) {
    return param.setState(function (state) {
                var newrecord = Caml_obj.obj_dup(state);
                newrecord.scrollOffset = empty;
                newrecord.velocity = state.scrollOffset;
                return newrecord;
              });
  }
  
}

function setScrollPosition(self) {
  applyDragForce(self);
  applyScrollForce(self);
  var match = self.state.contents;
  var edgeY = match.edgeY;
  var edgeX = match.edgeX;
  var position = match.position;
  var velocity = match.velocity;
  var velocity_x = Units.Px.multiply(velocity.x, friction);
  var velocity_y = Units.Px.multiply(velocity.y, friction);
  var velocity$1 = {
    x: velocity_x,
    y: velocity_y
  };
  var updatedX = Units.Px.plus(position.x, velocity_x);
  var updatedY = Units.Px.plus(position.y, velocity_y);
  var position_x = Units.Px.max(Units.Px.min(updatedX, edgeX.to_), edgeX.from);
  var position_y = Units.Px.max(Units.Px.min(updatedY, edgeY.to_), edgeY.from);
  var position$1 = {
    x: position_x,
    y: position_y
  };
  self.setState(function (state) {
        var newrecord = Caml_obj.obj_dup(state);
        newrecord.position = position$1;
        newrecord.velocity = velocity$1;
        return newrecord;
      });
}

function translateStr(x, y) {
  return "translate(" + Units.Px.toString(x) + ", " + Units.Px.toString(y) + ")";
}

function translateXStr(x) {
  return "translate(" + Units.Px.toString(x) + ", 0)";
}

function translateYStr(y) {
  return "translate(0, " + Units.Px.toString(y) + ")";
}

function setContentPosition(param) {
  var match = param.state.contents;
  var position = match.position;
  var htmlElement = Webapi__Dom__HtmlElement.ofElement(match.contentEl);
  if (htmlElement === undefined) {
    return ;
  }
  var style = Caml_option.valFromOption(htmlElement).style;
  style.setProperty("transform", translateStr(position.x, position.y));
}

function setElementsPosition(param) {
  var match = param.state.contents;
  var position = match.position;
  match.scrollElements.forEach(function (param) {
        var htmlElement = Webapi__Dom__HtmlElement.ofElement(param.element);
        if (htmlElement === undefined) {
          return ;
        }
        var style = Caml_option.valFromOption(htmlElement).style;
        var transformValue;
        switch (param.direction) {
          case "Vertical" :
              transformValue = translateYStr(position.y);
              break;
          case "Horizontal" :
              transformValue = translateXStr(position.x);
              break;
          case "All" :
              transformValue = translateStr(position.x, position.y);
              break;
          
        }
        style.setProperty("transform", transformValue);
      });
}

function isMoving(param) {
  var velocity = param.velocity;
  if (param.isDragging || param.isScrolling || Math.abs(Units.Px.toFloat(velocity.x)) >= 0.01) {
    return true;
  } else {
    return Math.abs(Units.Px.toFloat(velocity.y)) >= 0.01;
  }
}

function animate(self) {
  var setState = self.setState;
  var state = self.state;
  return function (param) {
    if (!state.contents.isAnimationRunning) {
      return ;
    }
    setScrollPosition(self);
    setContentPosition(self);
    setElementsPosition(self);
    var rafId = requestAnimationFrame(animate(self));
    var isAnimationRunning = isMoving(state.contents);
    setState(function (state) {
          var newrecord = Caml_obj.obj_dup(state);
          newrecord.isAnimationRunning = isAnimationRunning;
          newrecord.rafId = Caml_option.some(rafId);
          return newrecord;
        });
    if (!isAnimationRunning) {
      return state.contents.onScrollEnd(state.contents.position);
    }
    
  };
}

function startAnimationLoop(self) {
  var setState = self.setState;
  var state = self.state;
  setState(function (state) {
        var newrecord = Caml_obj.obj_dup(state);
        newrecord.isAnimationRunning = true;
        return newrecord;
      });
  var rafID = state.contents.rafId;
  if (rafID !== undefined) {
    cancelAnimationFrame(Caml_option.valFromOption(rafID));
  }
  setState(function (state) {
        var newrecord = Caml_obj.obj_dup(state);
        newrecord.rafId = Caml_option.some(requestAnimationFrame(animate(self)));
        return newrecord;
      });
  state.contents.onScrollStart(state.contents.position);
}

function elementDimensions(element) {
  return {
          width: Units.Px.fromInt(element.clientWidth),
          height: Units.Px.fromInt(element.clientHeight)
        };
}

function htmlElementDimensions(element) {
  var htmlElement = Webapi__Dom__HtmlElement.ofElement(element);
  if (htmlElement === undefined) {
    return {
            width: Units.Px.fromInt(element.clientWidth),
            height: Units.Px.fromInt(element.clientHeight)
          };
  }
  var htmlElement$1 = Caml_option.valFromOption(htmlElement);
  return {
          width: Units.Px.fromInt(htmlElement$1.offsetWidth),
          height: Units.Px.fromInt(htmlElement$1.offsetHeight)
        };
}

function hasPreventScrollAttr(node) {
  var element = Webapi__Dom__Element.ofNode(node);
  if (element !== undefined) {
    return RCore.$$Option.isSome(Caml_option.nullable_to_opt(Caml_option.valFromOption(element).closest("[data-prevent-scroll='true']")));
  } else {
    return false;
  }
}

function textNodeFromPoint(node, x, y) {
  var range = document.createRange();
  var nodes = Array.prototype.slice.call(node.childNodes);
  var x$1 = Units.Px.toFloat(x);
  var y$1 = Units.Px.toFloat(y);
  return nodes.some(function (node) {
              if (Webapi__Dom__Node.nodeType(node) !== "Text") {
                return false;
              }
              range.selectNodeContents(node);
              var rect = range.getBoundingClientRect();
              if (x$1 >= rect.left && y$1 >= rect.top && x$1 <= rect.right) {
                return y$1 <= rect.bottom;
              } else {
                return false;
              }
            });
}

var clearTextSelection = (function() {
    let selection
    if (window.getSelection !== undefined) {
      selection = window.getSelection()
    } else if (document.selection !== undefined) {
      selection = document.selection
    } else {
      selection = null
    }

    if (selection !== null && selection.removeAllRanges !== undefined) {
      selection.removeAllRanges();
    } else if (selection !== null && selection.empty !== undefined) {
      selection.empty();
    }
  });

function makeDragAngle(param) {
  return Math.round(Math.atan2(Units.Px.toFloat(param.y), Units.Px.toFloat(param.x)) * (180.0 / Math.PI));
}

function makeDragDirection(angle) {
  var absAngle = Math.abs(90.0 - Math.abs(angle));
  if (absAngle <= 90.0 - 40.0) {
    return "Horizontal";
  } else {
    return "Vertical";
  }
}

function setDragPosition(self, clientX, clientY, pageX, pageY) {
  if (!self.state.contents.isDragging) {
    return ;
  }
  var match = self.state.contents;
  var clientOrigin = match.clientOrigin;
  var dragOrigin = match.dragOrigin;
  var dragStartPosition = match.dragStartPosition;
  var dragOffset_x = Units.Px.minus(pageX, dragOrigin.x);
  var dragOffset_y = Units.Px.minus(pageY, dragOrigin.y);
  var dragOffset = {
    x: dragOffset_x,
    y: dragOffset_y
  };
  var clientOffset_x = Units.Px.minus(clientX, clientOrigin.x);
  var clientOffset_y = Units.Px.minus(clientY, clientOrigin.y);
  var clientOffset = {
    x: clientOffset_x,
    y: clientOffset_y
  };
  var dragPosition_x = Units.Px.plus(dragStartPosition.x, dragOffset_x);
  var dragPosition_y = Units.Px.plus(dragStartPosition.y, dragOffset_y);
  var dragPosition = {
    x: dragPosition_x,
    y: dragPosition_y
  };
  self.setState(function (state) {
        var newrecord = Caml_obj.obj_dup(state);
        newrecord.clientOffset = clientOffset;
        newrecord.dragPosition = dragPosition;
        newrecord.dragOffset = dragOffset;
        return newrecord;
      });
}

function focusOnFormInputElements(node) {
  var nodeName = node.nodeName.toLowerCase();
  return RCore.$$Option.isSome(RCore.$$Array.getBy(formNodes, (function (formNode) {
                    return formNode === nodeName;
                  })));
}

function scrollToPosition(scrollStep, direction, position) {
  var y = position.y;
  var x = position.x;
  if (direction === "Top") {
    return {
            x: x,
            y: Units.Px.plus(y, scrollStep)
          };
  } else if (direction === "Bottom") {
    return {
            x: x,
            y: Units.Px.minus(y, scrollStep)
          };
  } else if (direction === "Left") {
    return {
            x: Units.Px.plus(x, scrollStep),
            y: y
          };
  } else if (direction === "Center") {
    return position;
  } else {
    return {
            x: Units.Px.minus(x, scrollStep),
            y: y
          };
  }
}

function initListeners(initialPosition, onScrollStart, onScrollEnd, scrollElements, viewport, content, scrollArea, ignorePreventScroll, subscriptions) {
  var self = use(onScrollStart, onScrollEnd, initialPosition, scrollElements, viewport, content, scrollArea, ignorePreventScroll, undefined);
  var unsubscribeFromTriggerScroll = subscriptions.onTriggerScroll.subscribe(function (param) {
        var $staropt$star = param[1];
        var direction = param[0];
        var scrollStep = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : Units.Px.fromInt(5);
        self.setState(function (state) {
              var velocity_x = Units.Px.zero;
              var velocity_y = Units.Px.zero;
              var velocity = {
                x: velocity_x,
                y: velocity_y
              };
              var position = scrollToPosition(scrollStep, direction, state.position);
              var newrecord = Caml_obj.obj_dup(state);
              newrecord.position = position;
              newrecord.velocity = velocity;
              return newrecord;
            });
        startAnimationLoop(self);
      });
  var resizeObserver = new ResizeObserver((function (param) {
          var match = self.state.contents;
          var viewport = elementDimensions(match.viewportEl);
          var content = htmlElementDimensions(match.contentEl);
          var edgeX_from = Units.Px.min(Units.Px.plus(Units.Px.inverse(content.width), viewport.width), Units.Px.zero);
          var edgeX_to_ = Units.Px.zero;
          var edgeX = {
            from: edgeX_from,
            to_: edgeX_to_
          };
          var edgeY_from = Units.Px.min(Units.Px.plus(Units.Px.inverse(content.height), viewport.height), Units.Px.zero);
          var edgeY_to_ = Units.Px.zero;
          var edgeY = {
            from: edgeY_from,
            to_: edgeY_to_
          };
          self.setState(function (state) {
                var newrecord = Caml_obj.obj_dup(state);
                newrecord.edgeY = edgeY;
                newrecord.edgeX = edgeX;
                newrecord.content = content;
                newrecord.viewport = viewport;
                return newrecord;
              });
          startAnimationLoop(self);
        }));
  resizeObserver.observe(viewport);
  resizeObserver.observe(content);
  var onClick = function ($$event) {
    var match = self.state.contents.dragOffset;
    if (Caml_obj.greaterthan(Units.Px.max(Units.Px.abs(match.x), Units.Px.abs(match.y)), clickEventThreshold)) {
      $$event.preventDefault();
      $$event.stopPropagation();
      return ;
    }
    
  };
  var onWheel = function ($$event) {
    var setState = self.setState;
    $$event.preventDefault();
    setState(function (state) {
          var newrecord = Caml_obj.obj_dup(state);
          newrecord.scrollOffset = {
            x: Units.Px.inverse(Units.Px.fromFloat($$event.deltaX)),
            y: Units.Px.inverse(Units.Px.fromFloat($$event.deltaY))
          };
          newrecord.velocity = empty;
          newrecord.isScrolling = true;
          return newrecord;
        });
    startAnimationLoop(self);
    var wheelTimer = self.state.contents.wheelTimer;
    if (wheelTimer !== undefined) {
      clearTimeout(Caml_option.valFromOption(wheelTimer));
    }
    var timeoutId = setTimeout((function () {
            setState(function (state) {
                  var newrecord = Caml_obj.obj_dup(state);
                  newrecord.isScrolling = false;
                  return newrecord;
                });
          }), 80);
    return setState(function (state) {
                var newrecord = Caml_obj.obj_dup(state);
                newrecord.wheelTimer = Caml_option.some(timeoutId);
                return newrecord;
              });
  };
  var onMouseDown = function ($$event) {
    var node = $$event.target;
    var clientX = Units.Px.fromInt($$event.clientX);
    var clientY = Units.Px.fromInt($$event.clientY);
    var hasTextNode = textNodeFromPoint(node, clientX, clientY);
    var isScrollPrevented = self.state.contents.ignorePreventScroll ? false : hasPreventScrollAttr(node);
    if (!($$event.button !== 2 && !focusOnFormInputElements(node) && !hasTextNode && !isScrollPrevented)) {
      return ;
    }
    $$event.preventDefault();
    clearTextSelection();
    var pageX = Units.Px.fromInt($$event.pageX);
    var pageY = Units.Px.fromInt($$event.pageY);
    self.setState(function (state) {
          var newrecord = Caml_obj.obj_dup(state);
          newrecord.clientOrigin = {
            x: clientX,
            y: clientY
          };
          newrecord.dragOrigin = {
            x: pageX,
            y: pageY
          };
          newrecord.dragStartPosition = state.position;
          newrecord.isDragging = true;
          return newrecord;
        });
    setDragPosition(self, clientX, clientY, pageX, pageY);
    return startAnimationLoop(self);
  };
  var onMouseMove = function ($$event) {
    return setDragPosition(self, Units.Px.fromInt($$event.clientX), Units.Px.fromInt($$event.clientY), Units.Px.fromInt($$event.pageX), Units.Px.fromInt($$event.pageY));
  };
  var onMouseUp = function ($$event) {
    return self.setState(function (state) {
                var newrecord = Caml_obj.obj_dup(state);
                newrecord.isDragging = false;
                return newrecord;
              });
  };
  var onTouchStart = function ($$event) {
    var touch = Webapi2_Dom_TouchList.item($$event.touches, 0);
    if (touch === undefined) {
      return ;
    }
    var touch$1 = Caml_option.valFromOption(touch);
    var node = Webapi2_Dom_TouchList.$$Touch.target(touch$1);
    if (focusOnFormInputElements(node)) {
      return ;
    }
    var clientX = Webapi2_Dom_TouchList.$$Touch.clientX(touch$1);
    var clientY = Webapi2_Dom_TouchList.$$Touch.clientY(touch$1);
    var hasTextNode = textNodeFromPoint(node, clientX, clientY);
    var isScrollPrevented = self.state.contents.ignorePreventScroll ? false : hasPreventScrollAttr(node);
    if (!(!hasTextNode && !isScrollPrevented)) {
      return ;
    }
    $$event.preventDefault();
    clearTextSelection();
    var pageX = Webapi2_Dom_TouchList.$$Touch.pageX(touch$1);
    var pageY = Webapi2_Dom_TouchList.$$Touch.pageY(touch$1);
    self.setState(function (state) {
          var newrecord = Caml_obj.obj_dup(state);
          newrecord.clientOrigin = {
            x: clientX,
            y: clientY
          };
          newrecord.dragOrigin = {
            x: pageX,
            y: pageY
          };
          newrecord.dragStartPosition = state.position;
          newrecord.isDragging = true;
          return newrecord;
        });
    setDragPosition(self, clientX, clientY, pageX, pageY);
    return startAnimationLoop(self);
  };
  var onTouchMove = function ($$event) {
    var touch = Webapi2_Dom_TouchList.item($$event.touches, 0);
    if (touch === undefined) {
      return ;
    }
    if (!self.state.contents.isDragging) {
      return ;
    }
    var touch$1 = Caml_option.valFromOption(touch);
    return setDragPosition(self, Webapi2_Dom_TouchList.$$Touch.clientX(touch$1), Webapi2_Dom_TouchList.$$Touch.clientY(touch$1), Webapi2_Dom_TouchList.$$Touch.pageX(touch$1), Webapi2_Dom_TouchList.$$Touch.pageY(touch$1));
  };
  var onTouchEnd = function ($$event) {
    return self.setState(function (state) {
                var newrecord = Caml_obj.obj_dup(state);
                newrecord.isDragging = false;
                return newrecord;
              });
  };
  scrollArea.addEventListener("click", onClick);
  scrollArea.addEventListener("wheel", onWheel);
  var $$window$1 = window;
  scrollArea.addEventListener("mousedown", onMouseDown);
  $$window$1.addEventListener("mousemove", onMouseMove, {
        capture: false,
        once: false,
        passive: true
      });
  $$window$1.addEventListener("mouseup", onMouseUp);
  scrollArea.addEventListener("touchstart", onTouchStart);
  $$window$1.addEventListener("touchmove", onTouchMove, {
        capture: false,
        once: false,
        passive: true
      });
  $$window$1.addEventListener("touchend", onTouchEnd);
  return function () {
    unsubscribeFromTriggerScroll();
    resizeObserver.disconnect();
    scrollArea.removeEventListener("click", onClick);
    scrollArea.removeEventListener("wheel", onWheel);
    scrollArea.removeEventListener("mousedown", onMouseDown);
    $$window$1.removeEventListener("mousemove", onMouseMove, {
          capture: false,
          passive: true
        });
    $$window$1.removeEventListener("mouseup", onMouseUp);
    scrollArea.removeEventListener("touchstart", onTouchStart);
    $$window$1.removeEventListener("touchmove", onTouchMove, {
          capture: false,
          passive: true
        });
    $$window$1.removeEventListener("touchend", onTouchEnd);
  };
}

function init(initialPosition, onScrollStart, onScrollEnd, scrollElementsOpt, viewport, scrollAreaOpt, ignorePreventScrollOpt, param) {
  var scrollElements = scrollElementsOpt !== undefined ? scrollElementsOpt : [];
  var scrollArea = scrollAreaOpt !== undefined ? Caml_option.valFromOption(scrollAreaOpt) : viewport;
  var ignorePreventScroll = ignorePreventScrollOpt !== undefined ? ignorePreventScrollOpt : false;
  var content = viewport.firstElementChild;
  if (content == null) {
    return ;
  }
  var subscriptions = {
    onTriggerScroll: Subscription.make()
  };
  var onDestroy = initListeners(initialPosition, onScrollStart, onScrollEnd, scrollElements, viewport, content, scrollArea, ignorePreventScroll, subscriptions);
  return {
          onDestroy: onDestroy,
          subscriptions: subscriptions
        };
}

export {
  Subscriptions ,
  Coordinate ,
  ScrollDirection ,
  ScrollElement ,
  hasPreventScrollAttr ,
  makeDragAngle ,
  makeDragDirection ,
  init ,
}
/* friction Not a pure module */
