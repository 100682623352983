// extracted by mini-css-extract-plugin
export var btnGroup = "btn-group-iCNBIyDCOFZ2vvltjdLA";
export var chooseNotificationMethod = "choose-notification-method-AF9t7XClwu5HzggH7KLp";
export var completeJobsCheckbox = "complete-jobs-checkbox-h_yJWmWjMNNOPBlZeOxy";
export var contactInput = "contact-input-hMQZ1Gp3k0_q1wv7q9QQ";
export var createJobsCheckbox = "create-jobs-checkbox-GM0U46neHHDTXUJDdxWJ";
export var form = "form-rLCuPLtFkskjEZyNWpQJ";
export var formBody = "form-body-KxMtShJl6P5Qf5LeLY0c";
export var formFooter = "form-footer-GtkyA6lAB6goYkJeLpt1";
export var formGroup = "form-group-VnWeId6qpKX2SVWqF18V";
export var formHeader = "form-header-UqQ5kXMGzRna5Bs3slqx";
export var howToNotify = "how-to-notify-GBXu60lV23_8sLF1tgbV";
export var modalContainer = "modal-container-RKuPqhx1jQF01_2PzYZQ";
export var noFreeLicense = "no-free-license-WyDVo2BqhFkMTsk2Patm";
export var sendAgainCheckbox = "send-again-checkbox-DTobh4wq9Czc4SilhFwD";
export var textInput = "text-input-M6XG_gBd7oPdBi_NalNZ";