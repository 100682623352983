

import * as Units from "../../../../../../types/Units.mjs";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as DayTimeline_Types from "../types/DayTimeline_Types.mjs";
import * as Belt_Lib_SortArray from "../../../../../../libraries/Belt_Lib_SortArray.mjs";
import * as DayTimeline_Constants from "../DayTimeline_Constants.mjs";
import * as Schedule_IntervalTree from "../../../../lib/Schedule_IntervalTree.mjs";
import * as DayTimeline_PositionsHelper from "./DayTimeline_PositionsHelper.mjs";

function index(job) {
  return job.index;
}

var start = DayTimeline_Types.Position.start;

var finish = DayTimeline_Types.Position.finish;

var IntervalTree = Schedule_IntervalTree.Make({
      index: index,
      start: start,
      finish: finish
    });

function startedOutside(businessHours, currentDay, startAt) {
  if (businessHours === undefined) {
    return !Locale.T.equalDay(currentDay, startAt);
  }
  var startOfDay = Locale.T.add(businessHours.start, currentDay);
  return Locale.T.lessThan(startAt, startOfDay);
}

function finishedOutside(businessHours, currentDay, finishAt) {
  if (businessHours === undefined) {
    return !Locale.T.equalDay(currentDay, finishAt);
  }
  var finishOfDay = Locale.T.add(businessHours.finish, currentDay);
  return Locale.T.moreThan(finishAt, finishOfDay);
}

function makePosition(wire, businessHours, currentDay, sizeOfMinute, startAt, finishAt, index) {
  var startedOutside$1 = startedOutside(businessHours, currentDay, startAt);
  var finishedOutside$1 = finishedOutside(businessHours, currentDay, finishAt);
  var left = DayTimeline_PositionsHelper.left(wire, businessHours, currentDay, sizeOfMinute, startAt);
  var width = DayTimeline_PositionsHelper.width(wire, businessHours, currentDay, sizeOfMinute, left, finishAt);
  return {
          index: index,
          startedOutside: startedOutside$1,
          finishedOutside: finishedOutside$1,
          top: {
            contents: Units.Px.zero
          },
          left: left,
          width: width,
          height: {
            contents: Units.Px.zero
          },
          size: {
            contents: "Full"
          },
          rendered: {
            contents: false
          },
          hidden: {
            contents: false
          },
          children: {
            contents: []
          }
        };
}

function sortByWidth(__x) {
  return Belt_Lib_SortArray.stableSortBy(__x, (function (a, b) {
                return Units.Px.cmp(a.width, b.width);
              }));
}

function minHeight(rowHeight) {
  return Units.Px.divideByInt(rowHeight, DayTimeline_Constants.numberOfJobsInRow);
}

function sortByPosition(positions) {
  return Belt_Lib_SortArray.stableSortBy(positions, (function (a, b) {
                  return Units.Px.toInt(Units.Px.minus(b.top.contents, a.top.contents));
                })).toReversed();
}

var inBusinessHours = DayTimeline_PositionsHelper.inBusinessHours;

export {
  inBusinessHours ,
  IntervalTree ,
  makePosition ,
  sortByWidth ,
  minHeight ,
  sortByPosition ,
}
/* IntervalTree Not a pure module */
