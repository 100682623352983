

import * as Caml from "rescript/lib/es6/caml.js";
import * as RCore from "../../libraries/RCore.mjs";
import * as React from "react";
import * as Runtime from "../../Runtime.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LoaderModuleCss from "/home/runner/work/planado/planado/client/rescript/components/loader/Loader.module.css";

var styles = LoaderModuleCss;

function updateProgress(r, f) {
  if (r !== undefined) {
    return {
            intervalId: r.intervalId,
            request: r.request,
            progress: f(r.progress)
          };
  }
  
}

function make(onProgress, r) {
  var id = Runtime.Interval.every(200, (function () {
          onProgress(function (r) {
                return updateProgress(r, (function (progress) {
                              return Caml.int_min(progress + 10 | 0, 90);
                            }));
              });
        }));
  return {
          intervalId: id,
          request: r,
          progress: 0
        };
}

function stopWatching(current) {
  if (current !== undefined) {
    return Runtime.Interval.cancel(current.intervalId);
  }
  
}

function Loader(Props) {
  var subscriptions = Props.subscriptions;
  var match = React.useState(function () {
        
      });
  var setCurrentRequest = match[1];
  var currentRequest = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setTimeoutId = match$1[1];
  var timeoutId = match$1[0];
  var changeTimeoutId = function () {
    var timeoutId = Runtime.Timeout.defer(300, (function () {
            setCurrentRequest(function (current) {
                  stopWatching(current);
                });
          }));
    setTimeoutId(function (param) {
          return Caml_option.some(timeoutId);
        });
  };
  var onTrackerEvent = function (evt) {
    var match = evt._0;
    if (match.TAG === "Started") {
      if (match._1) {
        return ;
      }
      var req = match._0;
      return setCurrentRequest(function (current) {
                  stopWatching(current);
                  return make(setCurrentRequest, req);
                });
    }
    setCurrentRequest(function (current) {
          return updateProgress(current, (function (param) {
                        return 100;
                      }));
        });
    changeTimeoutId();
  };
  React.useEffect((function () {
          return subscriptions.tracker.subscribe(onTrackerEvent);
        }), []);
  React.useEffect((function () {
          return (function () {
                    stopWatching(currentRequest);
                  });
        }), [currentRequest]);
  React.useEffect((function () {
          return RCore.$$Option.flatMap(timeoutId, (function (id) {
                        return (function () {
                                  Runtime.Timeout.cancel(id);
                                });
                      }));
        }), [timeoutId]);
  var percent = RCore.$$Option.map(currentRequest, (function (param) {
          return param.progress;
        }));
  var style = percent !== undefined ? ({
        width: percent.toString() + "%"
      }) : ({
        display: "none"
      });
  return React.createElement("div", {
              className: styles.loader
            }, React.createElement("div", {
                  className: styles.bar,
                  style: style
                }));
}

var make$1 = Loader;

export {
  make$1 as make,
}
/* styles Not a pure module */
