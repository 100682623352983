

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as Types_Date from "../../../types/Types_Date.mjs";
import * as Types_Address from "../../../types/Types_Address.mjs";
import * as Types_Contact from "../../../types/Types_Contact.mjs";
import * as Types_Territory from "../../../types/Types_Territory.mjs";
import * as AddressInput_Types from "../../../components/address_input/AddressInput_Types.mjs";

function fieldToString(field) {
  return RCore.$$Option.getOr(field, "");
}

var decode = JsonDecode.object(function (field) {
      return {
              clientUuid: field.required("clientUuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              address: field.required("address", JsonDecode.string)
            };
    });

var $$Option = {
  decode: decode
};

var decode$1 = JsonDecode.array(decode);

var Lookup = {
  $$Option: $$Option,
  decode: decode$1
};

var decode$2 = JsonDecode.object(function (field) {
      return {
              externalId: field.required("externalId", JsonDecode.$$int),
              name: field.required("name", JsonDecode.$$int),
              firstName: field.required("firstName", JsonDecode.$$int),
              lastName: field.required("lastName", JsonDecode.$$int),
              middleName: field.required("middleName", JsonDecode.$$int),
              organizationName: field.required("organizationName", JsonDecode.$$int),
              mobilePhone: field.required("mobilePhone", JsonDecode.$$int),
              contactName: field.required("contactName", JsonDecode.$$int),
              workPhone: field.required("workPhone", JsonDecode.$$int),
              floor: field.required("floor", JsonDecode.$$int),
              apartment: field.required("apartment", JsonDecode.$$int),
              addressDescription: field.required("addressDescription", JsonDecode.$$int),
              entranceNo: field.required("entranceNo", JsonDecode.$$int)
            };
    });

function toString(key) {
  switch (key.TAG) {
    case "ExternalId" :
        return "externalId";
    case "OrganizationName" :
        return "organizationName";
    case "FirstName" :
        return "firstName";
    
  }
}

function fromString(param) {
  var text = param[1];
  switch (param[0]) {
    case "externalId" :
        return {
                TAG: "ExternalId",
                _0: text
              };
    case "firstName" :
        return {
                TAG: "FirstName",
                _0: text
              };
    case "organizationName" :
        return {
                TAG: "OrganizationName",
                _0: text
              };
    default:
      return ;
  }
}

function message(errors, key) {
  return RCore.$$Option.getOr(RCore.$$Option.map(RCore.$$Array.getBy(errors, (function (e) {
                        return toString(e) === key;
                      })), (function (error) {
                    return [error._0];
                  })), []);
}

var decode$3 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", JsonDecode.option(Uuid.decode)),
              isNewRecord: field.required("isNewRecord", JsonDecode.bool),
              organization: field.required("organization", JsonDecode.bool),
              showingExternalId: field.required("showingExternalId", JsonDecode.bool),
              externalId: field.required("externalId", JsonDecode.option(JsonDecode.string)),
              name: field.required("name", JsonDecode.option(JsonDecode.string)),
              firstName: field.required("firstName", JsonDecode.option(JsonDecode.string)),
              lastName: field.required("lastName", JsonDecode.option(JsonDecode.string)),
              middleName: field.required("middleName", JsonDecode.option(JsonDecode.string)),
              organizationName: field.required("organizationName", JsonDecode.option(JsonDecode.string)),
              contacts: field.required("contacts", JsonDecode.array(Types_Contact.decode)),
              floor: field.required("floor", JsonDecode.option(JsonDecode.string)),
              apartment: field.required("apartment", JsonDecode.option(JsonDecode.string)),
              addressDescription: field.required("addressDescription", JsonDecode.option(JsonDecode.string)),
              entranceNo: field.required("entranceNo", JsonDecode.option(JsonDecode.string)),
              territoryUuid: field.required("territoryUuid", JsonDecode.option(Uuid.decode)),
              availableTerritories: field.required("availableTerritories", JsonDecode.array(Types_Territory.decode)),
              createdAt: field.required("createdAt", JsonDecode.option(Types_Date.decode)),
              address: field.required("address", JsonDecode.option(Types_Address.decode))
            };
    });

var decode$4 = JsonDecode.object(function (field) {
      return {
              limits: field.required("limits", decode$2),
              canManage: field.required("canManage", JsonDecode.bool),
              locked: false,
              client: field.required("client", decode$3),
              addressConstraints: field.required("addressConstraints", AddressInput_Types.AddressConstraint.decode),
              errors: []
            };
    });

var Limits = {};

var $$Error = {
  fromString: fromString,
  message: message
};

var Client = {};

export {
  fieldToString ,
  Lookup ,
  Limits ,
  $$Error ,
  Client ,
  decode$4 as decode,
}
/* decode Not a pure module */
