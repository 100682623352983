

import * as Wire from "../../../../../../types/Wire.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as MomentRe from "../../../../../../bindings/moment/MomentRe.mjs";
import * as Belt_Lib_SortArray from "../../../../../../libraries/Belt_Lib_SortArray.mjs";
import * as WeekTimeline_PositionsHelper from "./WeekTimeline_PositionsHelper.mjs";

function get(wire, sizes, period, startAt) {
  var startAt$1 = WeekTimeline_PositionsHelper.updateStartAt(wire, period, startAt);
  var match = Wire.businessHours(wire);
  var dayWidth;
  if (match !== undefined) {
    var startOfDay = WeekTimeline_PositionsHelper.startOfDay(wire, period, startAt$1);
    var endOfDay = WeekTimeline_PositionsHelper.endOfDay(wire, period, startAt$1);
    var left = Locale.T.lessThan(startAt$1, startOfDay) ? Units.Px.zero : (
        Locale.T.moreThan(startAt$1, endOfDay) ? sizes.day : Units.Px.multiplyWithInt(sizes.minute, Locale.T.dateToMinutes(wire, startAt$1) - (MomentRe.Duration.asMinutes(match.start) | 0) | 0)
      );
    dayWidth = Units.Px.plus(WeekTimeline_PositionsHelper.nonBusinessHoursBlockWidth, left);
  } else {
    dayWidth = Units.Px.multiplyWithInt(sizes.minute, Locale.T.dateToMinutes(wire, startAt$1));
  }
  return Units.Px.plus(Units.Px.multiplyWithInt(sizes.day, Locale.T.isoWeekday(startAt$1)), dayWidth);
}

var JobLeft = {
  get: get
};

function firstDayJobWidth(wire, sizes, period, startAt, finishAt, isOneDayJob) {
  var startOfDay = WeekTimeline_PositionsHelper.startOfDay(wire, period, startAt);
  var endOfDay = WeekTimeline_PositionsHelper.endOfDay(wire, period, startAt);
  var isFullDayJob = Locale.T.equalOrLessThan(startAt, startOfDay) && Locale.T.equalOrMoreThan(finishAt, endOfDay);
  if (Locale.T.equalOrMoreThan(startAt, endOfDay)) {
    return Units.Px.zero;
  }
  if (isFullDayJob) {
    var match = Wire.businessHours(wire);
    if (match !== undefined) {
      return Units.Px.minus(sizes.day, isOneDayJob ? WeekTimeline_PositionsHelper.nonBusinessHoursBlockWidth : Units.Px.fromInt(5));
    } else {
      return sizes.day;
    }
  }
  var finishAt$1 = Locale.T.equalOrMoreThan(finishAt, endOfDay) ? endOfDay : finishAt;
  var width = Units.Px.multiplyWithInt(sizes.minute, Locale.T.minutesBetween(finishAt$1, startAt));
  var match$1 = Wire.businessHours(wire);
  var shiftWidth = match$1 !== undefined && Locale.T.equalOrMoreThan(finishAt$1, endOfDay) ? WeekTimeline_PositionsHelper.nonBusinessHoursBlockWidth : Units.Px.zero;
  return Units.Px.plus(width, shiftWidth);
}

function lastDayJobWidth(wire, period, sizes, finishAt) {
  var startOfDay = WeekTimeline_PositionsHelper.startOfDay(wire, period, finishAt);
  var endOfDay = WeekTimeline_PositionsHelper.endOfDay(wire, period, finishAt);
  if (Locale.T.equalOrLessThan(finishAt, startOfDay)) {
    return WeekTimeline_PositionsHelper.nonBusinessHoursBlockWidth;
  }
  if (Locale.T.equalOrMoreThan(finishAt, endOfDay)) {
    return Units.Px.minus(sizes.day, WeekTimeline_PositionsHelper.nonBusinessHoursBlockWidth);
  }
  var width = Units.Px.multiplyWithInt(sizes.minute, Locale.T.minutesBetween(finishAt, startOfDay));
  var shiftWidth = Wire.businessHours(wire) !== undefined && Units.Px.notEqual(width, Units.Px.zero) ? Units.Px.fromInt(7) : Units.Px.zero;
  return Units.Px.plus(width, shiftWidth);
}

function otherDaysJobWidth(sizes, startAt, finishAt) {
  return Units.Px.multiplyWithInt(sizes.day, (Locale.T.isoWeekday(finishAt) - Locale.T.isoWeekday(startAt) | 0) - 1 | 0);
}

function get$1(wire, sizes, period, startAt, finishAt) {
  var startAt$1 = WeekTimeline_PositionsHelper.updateStartAt(wire, period, startAt);
  var finishAt$1 = WeekTimeline_PositionsHelper.updateFinishAt(wire, period, finishAt);
  var isOneDayJob = Locale.T.equalDay(startAt$1, finishAt$1);
  var firstDayJobWidth$1 = firstDayJobWidth(wire, sizes, period, startAt$1, finishAt$1, isOneDayJob);
  if (isOneDayJob) {
    return firstDayJobWidth$1;
  } else {
    return Units.Px.plus(Units.Px.plus(firstDayJobWidth$1, lastDayJobWidth(wire, period, sizes, finishAt$1)), otherDaysJobWidth(sizes, startAt$1, finishAt$1));
  }
}

function byWidth(__x) {
  return Belt_Lib_SortArray.stableSortBy(__x, (function (a, b) {
                return Units.Px.cmp(a.width, b.width);
              }));
}

function byPosition(__x) {
  return Belt_Lib_SortArray.stableSortBy(__x, (function (a, b) {
                return Units.Px.toInt(Units.Px.minus(a.top.contents, b.top.contents));
              }));
}

var SortJobs = {
  byWidth: byWidth,
  byPosition: byPosition
};

var isVisible = WeekTimeline_PositionsHelper.isVisible;

var startedOutside = WeekTimeline_PositionsHelper.startedOutside;

var finishedOutside = WeekTimeline_PositionsHelper.finishedOutside;

var jobLeft = get;

var JobWidth = {
  get: get$1
};

var jobWidth = get$1;

export {
  isVisible ,
  startedOutside ,
  finishedOutside ,
  JobLeft ,
  jobLeft ,
  JobWidth ,
  jobWidth ,
  SortJobs ,
}
/* Wire Not a pure module */
