import { connect } from 'react-redux'
import { toggleFocusedJob, toggleWorkerJobs } from 'planado/actions/map'
import WorkerJobList from 'planado/components/map/worker_job_list'

const mapStateToProps = ({
  historyWorker,
  jobs,
  engineChannel,
  focusedJobUuid,
  showWorkerJobs
}) => {
  return {
    jobs,
    engineChannel,
    focusedJobUuid,
    worker: historyWorker,
    open: showWorkerJobs
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onJobClick: job => dispatch(toggleFocusedJob(job)),
    onJobListToggle: () => dispatch(toggleWorkerJobs())
  }
}

const WorkerJobListLink = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkerJobList)

export default WorkerJobListLink
