

import * as React from "react";
import * as TeamEdit from "./TeamEdit.mjs";
import * as TeamIndex from "./TeamIndex.mjs";

function Teams(Props) {
  var wire = Props.wire;
  var route = Props.route;
  if (typeof route !== "object" && route !== "New") {
    return React.createElement(TeamIndex.make, {
                wire: wire
              });
  }
  return React.createElement(TeamEdit.make, {
              wire: wire,
              mode: route,
              isModal: false,
              onDelete: (function (param) {
                  wire.navigate(undefined, {
                        NAME: "Team",
                        VAL: "Index"
                      });
                }),
              onClose: (function () {
                  wire.navigate(undefined, {
                        NAME: "Team",
                        VAL: "Index"
                      });
                })
            });
}

var make = Teams;

export {
  make ,
}
/* react Not a pure module */
