

import * as Js_math from "rescript/lib/es6/js_math.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as WeekCalendar_Constants from "./WeekCalendar_Constants.mjs";

function toString(num) {
  return num.toString() + "px";
}

function heightPx(start, finish) {
  return (finish - start) * WeekCalendar_Constants.sizeOfMinutePx + (
          start === 0.0 || Caml_int32.mod_(start | 0, WeekCalendar_Constants.minutesInHour) === 0 || Caml_int32.mod_(start | 0, WeekCalendar_Constants.minutesInHour / 2 | 0) === 0 ? 1.0 : 0.0
        );
}

function topPx(start) {
  return start * WeekCalendar_Constants.sizeOfMinutePx - (
          start === 0.0 || Caml_int32.mod_(start | 0, WeekCalendar_Constants.minutesInHour) === 0 || Caml_int32.mod_(start | 0, WeekCalendar_Constants.minutesInHour / 2 | 0) === 0 ? 1.0 : 0.0
        );
}

function make(param) {
  var start = param.start;
  var widthPx = param.width.contents;
  var heightPx$1 = heightPx(start, param.finish);
  var topPx$1 = topPx(start);
  var offset = {
    widthPx: widthPx,
    heightPx: heightPx$1,
    topPx: topPx$1
  };
  var style = {
    height: toString(heightPx$1),
    top: toString(topPx$1),
    width: toString(widthPx)
  };
  return [
          style,
          offset
        ];
}

function get(unscheduledJobs) {
  return Js_math.floor((window.document.body.offsetWidth - 80.0 - (
                unscheduledJobs ? 250.0 : 20.0
              )) / WeekCalendar_Constants.daysInWeek / WeekCalendar_Constants.numberOfJobsInColumn) * WeekCalendar_Constants.numberOfJobsInColumn;
}

var JobStyle = {
  sizeOfBorderPx: 3.0,
  topPx: topPx,
  make: make
};

var ColumnWidth = {
  get: get
};

export {
  JobStyle ,
  ColumnWidth ,
}
/* WeekCalendar_Constants Not a pure module */
