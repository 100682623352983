

import * as El from "../../libraries/El.mjs";
import * as Icon from "../../Icon.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Optional from "../common/Optional.mjs";
import * as FormInput from "./FormInput.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as FieldErrors from "../common/FieldError/FieldErrors.mjs";
import * as UrlInputModuleCss from "/home/runner/work/planado/planado/client/rescript/components/inputs/UrlInput.module.css";

var styles = UrlInputModuleCss;

var placeholder$p = Utils.Translations.t("js.jobs.custom_fields.link.placeholder");

function hasProtocol(str) {
  if (str.startsWith("https://")) {
    return true;
  } else {
    return str.startsWith("http://");
  }
}

function removeSpaces(str) {
  var regex = /\s/g;
  return Js_string.replaceByRe(regex, "", str);
}

function isEmpty(str) {
  return str.length === 0;
}

function UrlInput(Props) {
  var id = Props.id;
  var name = Props.name;
  var value = Props.value;
  var onValueChange = Props.onValueChange;
  var disabled = Props.disabled;
  var labelText = Props.labelText;
  var wire = Props.wire;
  var className = Props.className;
  var errors = Props.errors;
  var className$1 = RCore.$$Option.getOr(className, "pd-form-group");
  var match = React.useState(function () {
        return "Show";
      });
  var setMode = match[1];
  var onBlur = function (_event) {
    if (value.length === 0 || value === "https://" || value === "http://") {
      return onValueChange("");
    } else if (hasProtocol(value)) {
      return onValueChange(removeSpaces(value));
    } else {
      return onValueChange("https://" + removeSpaces(value));
    }
  };
  var inputClasses = disabled ? El.Cn.concat(styles.urlContainer, styles.disabled) : styles.urlContainer;
  var match$1 = React.useState(function () {
        return false;
      });
  var setUrlHovered = match$1[1];
  var match$2 = React.useState(function () {
        
      });
  var setBtnAnimStyle = match$2[1];
  var onHoverUrl = function (_event) {
    setBtnAnimStyle(function (param) {
          return styles.btnShowAnim;
        });
    setUrlHovered(function (param) {
          return true;
        });
  };
  var onLeaveUrl = function (_event) {
    setBtnAnimStyle(function (param) {
          return styles.btnHideAnim;
        });
    setTimeout((function () {
            setUrlHovered(function (param) {
                  return false;
                });
          }), 250);
  };
  var canEditUrl = !disabled && match$1[0];
  var tmp;
  if (match[0] === "Show") {
    tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: inputClasses,
              onMouseEnter: onHoverUrl,
              onMouseLeave: onLeaveUrl
            }, value.length === 0 ? React.createElement("p", {
                    className: styles.placeholder
                  }, placeholder$p(wire.ctx)) : React.createElement("a", {
                    href: value,
                    target: "_blank"
                  }, React.createElement("p", {
                        className: styles.urlText
                      }, value)), React.createElement(Optional.make, {
                  show: canEditUrl,
                  children: React.createElement("button", {
                        className: El.Cn.concatOpt(styles.btnEdit, match$2[0]),
                        onClick: (function ($$event) {
                            $$event.preventDefault();
                            $$event.stopPropagation();
                            setMode(function (param) {
                                  return "Edit";
                                });
                          })
                      }, React.createElement("i", {
                            className: Icon.style(undefined, undefined, "pencil-alt")
                          }))
                })), React.createElement(FieldErrors.make, {
              errors: errors
            }));
  } else {
    var tmp$1 = {
      _type: "text",
      value: value,
      onValueChange: onValueChange,
      onBlur: onBlur,
      id: id,
      disabled: disabled,
      maxLength: 1000,
      errors: errors,
      wrapperClass: Js_dict.fromList({
            hd: [
              "pd-form-group",
              false
            ],
            tl: /* [] */0
          }),
      inputClass: Js_dict.fromList({
            hd: [
              "pd-form-control",
              false
            ],
            tl: {
              hd: [
                styles.input,
                true
              ],
              tl: /* [] */0
            }
          }),
      autoFocus: true,
      placeholder: placeholder$p(wire.ctx)
    };
    if (name !== undefined) {
      tmp$1.name = name;
    }
    tmp = React.createElement("div", {
          className: styles.container
        }, React.createElement(FormInput.make, tmp$1));
  }
  return React.createElement("div", {
              className: className$1
            }, React.createElement("label", {
                  className: "pd-label",
                  htmlFor: id
                }, labelText), tmp);
}

var make = UrlInput;

var urlInput = UrlInput;

export {
  hasProtocol ,
  removeSpaces ,
  isEmpty ,
  make ,
  urlInput ,
}
/* styles Not a pure module */
