

import * as React from "react";
import * as JobEdit_State from "./lib/state/JobEdit_State.mjs";
import * as JobEdit_StateHooks from "./lib/state/JobEdit_StateHooks.mjs";
import * as JobEdit_FormComponent from "./JobEdit_FormComponent.mjs";

function JobEdit(Props) {
  var subroute = Props.subroute;
  var job = Props.job;
  var stateContext = JobEdit_StateHooks.useContext(subroute, job);
  return React.createElement(JobEdit_State.Context.Provider.make, JobEdit_State.Context.Provider.makeProps(stateContext, React.createElement(JobEdit_FormComponent.make, {
                      subroute: subroute
                    }), undefined));
}

var make = JobEdit;

export {
  make ,
}
/* react Not a pure module */
