

import * as El from "../../../../../El.mjs";
import * as React from "react";
import * as Bindings_Slate from "../../../../../../bindings/Bindings_Slate.mjs";
import * as Liquid_EditorTypes from "../../../lib/Liquid_EditorTypes.mjs";
import * as Liquid_EditorSuggestionsHooks from "./Liquid_EditorSuggestionsHooks.mjs";
import * as Liquid_EditorSuggestionsModuleCss from "/home/runner/work/planado/planado/client/rescript/libraries/liquid/editor/components/element/suggestions/Liquid_EditorSuggestions.module.css";

var styles = Liquid_EditorSuggestionsModuleCss;

function Liquid_EditorSuggestions(Props) {
  var editor = Props.editor;
  var substitutions = Props.substitutions;
  var variable = Props.variable;
  var suggestions = Props.suggestions;
  var keydownSubscription = Props.keydownSubscription;
  var match = React.useState(function () {
        return 0;
      });
  var setIndex = match[1];
  var index = match[0];
  var insertVariable = Liquid_EditorSuggestionsHooks.useInsertVariable(editor, substitutions, variable);
  var onKeyDown = function ($$event) {
    var match = $$event.key;
    switch (match) {
      case "ArrowDown" :
          $$event.preventDefault();
          var nextIndex = index === (suggestions.length - 1 | 0) ? 0 : index + 1 | 0;
          return setIndex(function (param) {
                      return nextIndex;
                    });
      case "ArrowUp" :
          $$event.preventDefault();
          var nextIndex$1 = index === 0 ? suggestions.length - 1 | 0 : index - 1 | 0;
          return setIndex(function (param) {
                      return nextIndex$1;
                    });
      case "Escape" :
          $$event.preventDefault();
          return Bindings_Slate.SlateTransforms.move(undefined, editor);
      case "Enter" :
      case "Tab" :
          break;
      default:
        return ;
    }
    $$event.preventDefault();
    var suggestion = suggestions[index];
    if (suggestion !== undefined) {
      return insertVariable(suggestion);
    }
    
  };
  React.useEffect((function () {
          return keydownSubscription.subscribe(onKeyDown);
        }), [
        index,
        variable
      ]);
  var onItemClick = function (suggestion) {
    return function (_event) {
      insertVariable(suggestion);
      Bindings_Slate.React.ReactEditor.focus(editor);
    };
  };
  return React.createElement("div", {
              className: styles.items
            }, suggestions.map(function (suggestion, idx) {
                  return React.createElement("div", {
                              key: El.Cn.concat(Liquid_EditorTypes.Suggestion.key(suggestion), index.toString()),
                              className: El.Cn.concat(styles.item, idx === index ? styles.active : ""),
                              onClick: onItemClick(suggestion),
                              onMouseEnter: (function (_event) {
                                  setIndex(function (param) {
                                        return idx;
                                      });
                                })
                            }, Liquid_EditorTypes.Suggestion.name(suggestion));
                }));
}

var make = Liquid_EditorSuggestions;

export {
  styles ,
  make ,
}
/* styles Not a pure module */
