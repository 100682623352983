

import * as RCore from "../../libraries/RCore.mjs";
import * as Runtime from "../../Runtime.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function debounce(delayMS, leadingOpt, f) {
  var leading = leadingOpt !== undefined ? leadingOpt : false;
  var timerId = {
    contents: undefined
  };
  var cancel = function () {
    RCore.$$Option.mapWithDefault(timerId.contents, undefined, (function (timerId) {
            Runtime.Timeout.cancel(timerId);
          }));
    timerId.contents = undefined;
  };
  var schedule = function () {
    cancel();
    timerId.contents = Caml_option.some(Runtime.Timeout.defer(delayMS, (function () {
                f();
                timerId.contents = undefined;
              })));
  };
  var isScheduled = function () {
    return RCore.$$Option.isSome(timerId.contents);
  };
  var flush = function () {
    cancel();
    f();
  };
  var doLeading = {
    contents: leading
  };
  var debounced = function () {
    if (doLeading.contents) {
      doLeading.contents = false;
      return f();
    } else {
      return schedule();
    }
  };
  return {
          cancel: cancel,
          flush: flush,
          isScheduled: isScheduled,
          f: debounced
        };
}

export {
  debounce ,
}
/* No side effect */
