export const credentials = {
  credentials: 'same-origin'
}

export const defaultHeaders = new Headers({
  Accept: 'application/json'
})

export const defaultHeadersWithContentType = new Headers({
  'Content-Type': 'application/json',
  Accept: 'application/json'
})
