

import * as RCore from "../../RCore.mjs";
import * as Liquid_Lexer from "./Liquid_Lexer.mjs";

function make(input) {
  return {
          tokens: Liquid_Lexer.tokenize(input),
          pointer: {
            contents: 0
          }
        };
}

function jump(parser, point) {
  parser.pointer.contents = point;
}

function consume(type_, parser) {
  var token = parser.tokens[parser.pointer.contents];
  if (token !== undefined && !(type_ !== undefined && Liquid_Lexer.Type.fromToken(token) !== type_)) {
    parser.pointer.contents = parser.pointer.contents + 1 | 0;
    return Liquid_Lexer.toString(token);
  }
  
}

function id(parser, str) {
  var match = parser.tokens[parser.pointer.contents];
  if (match === undefined) {
    return ;
  }
  if (typeof match !== "object") {
    return ;
  }
  if (match.TAG !== "Id") {
    return ;
  }
  var s = match._0;
  if (s === str) {
    parser.pointer.contents = parser.pointer.contents + 1 | 0;
    return Liquid_Lexer.toString({
                TAG: "Id",
                _0: s
              });
  }
  
}

function look(aheadOpt, parser, type_) {
  var ahead = aheadOpt !== undefined ? aheadOpt : 0;
  var token = parser.tokens[parser.pointer.contents + ahead | 0];
  if (token !== undefined) {
    return Liquid_Lexer.Type.fromToken(token) === type_;
  } else {
    return false;
  }
}

function expression(parser) {
  var token = parser.tokens[parser.pointer.contents];
  var variableLookups = function () {
    var str = "";
    var $$break = false;
    while(!$$break) {
      if (look(undefined, parser, "OpenSquare")) {
        str = str + RCore.$$Option.getOr(consume("OpenSquare", parser), "") + expression(parser) + RCore.$$Option.getOr(consume("CloseSquare", parser), "");
      } else if (look(undefined, parser, "Dot")) {
        str = str + RCore.$$Option.getOr(consume(undefined, parser), "") + RCore.$$Option.getOr(consume("Id", parser), "");
      } else {
        $$break = true;
      }
    };
    return str;
  };
  if (token === undefined) {
    return "";
  }
  if (typeof token !== "object") {
    switch (token) {
      case "OpenSquare" :
          return RCore.$$Option.getOr(consume(undefined, parser), "") + expression(parser) + RCore.$$Option.getOr(consume("CloseSquare", parser), "") + variableLookups();
      case "OpenRound" :
          consume("OpenRound", parser);
          var a = expression(parser);
          consume("DotDot", parser);
          var b = expression(parser);
          consume("CloseRound", parser);
          return "(" + a + ".." + b + ")";
      default:
        return "";
    }
  } else {
    switch (token.TAG) {
      case "String" :
      case "Number" :
          break;
      case "Id" :
          return RCore.$$Option.getOr(consume(undefined, parser), "") + variableLookups();
      default:
        return "";
    }
  }
  return RCore.$$Option.getOr(consume(undefined, parser), "");
}

function argument(parser) {
  var arg = look(undefined, parser, "Id") && look(1, parser, "Colon") ? RCore.$$Option.getOr(consume(undefined, parser), "") + RCore.$$Option.getOr(consume(undefined, parser), "") + " " : "";
  return arg + expression(parser);
}

export {
  make ,
  jump ,
  consume ,
  id ,
  look ,
  expression ,
  argument ,
}
/* Liquid_Lexer Not a pure module */
