

import * as Utils from "../../../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../../../../../../../common/Optional.mjs";
import * as AppContext from "../../../../../../../../../context/AppContext.mjs";
import * as Schedule_State from "../../../../../../../lib/state/Schedule_State.mjs";
import * as Schedule_Types_Job from "../../../../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as WeekTimeline_AssignedJobPositions from "../../../../../lib/positions/WeekTimeline_AssignedJobPositions.mjs";
import * as WeekTimeline_UnassignedJobAssignButtonHook from "./WeekTimeline_UnassignedJobAssignButtonHook.mjs";
import * as WeekTimeline_UnassignedJobAssignButtonModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/unassigned/components/job/assign_button/WeekTimeline_UnassignedJobAssignButton.module.css";

function WeekTimeline_UnassignedJobAssignButton$AssignButtonIcon(Props) {
  return React.createElement("svg", {
              height: "10",
              width: "13",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M12.675 4.063h-1.3v-1.25a.32.32 0 00-.325-.313h-.65a.32.32 0 00-.325.313v1.25h-1.3a.32.32 0 00-.325.312V5a.32.32 0 00.325.313h1.3v1.25a.32.32 0 00.325.312h.65a.32.32 0 00.325-.313v-1.25h1.3A.32.32 0 0013 5v-.625a.32.32 0 00-.325-.313zM4.55 5c1.436 0 2.6-1.12 2.6-2.5S5.986 0 4.55 0c-1.436 0-2.6 1.12-2.6 2.5S3.114 5 4.55 5zm1.82.625h-.34a3.66 3.66 0 01-2.96 0h-.34C1.223 5.625 0 6.801 0 8.25v.813C0 9.58.437 10 .975 10h7.15c.538 0 .975-.42.975-.938V8.25c0-1.45-1.223-2.625-2.73-2.625z",
                  fill: "#4a90e2"
                }));
}

var styles = WeekTimeline_UnassignedJobAssignButtonModuleCss;

var assign$p = Utils.Translations.tr("js.schedule.v2.job.assign");

var cancel$p = Utils.Translations.tr("js.schedule.v2.job.cancel");

function WeekTimeline_UnassignedJobAssignButton(Props) {
  var job = Props.job;
  var toggleIsActive = Props.toggleIsActive;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = WeekTimeline_UnassignedJobAssignButtonHook.use(job, toggleIsActive);
  var period = Schedule_State.usePeriod();
  var show = WeekTimeline_AssignedJobPositions.isVisible(wire, period, Schedule_Types_Job.UnassignedJob.startAt(job), Schedule_Types_Job.UnassignedJob.finishAt(job));
  return React.createElement(Optional.make, {
              show: show,
              children: React.createElement("button", {
                    ref: match[0],
                    className: styles.assignButton,
                    onClick: match[1]
                  }, React.createElement(WeekTimeline_UnassignedJobAssignButton$AssignButtonIcon, {}), React.createElement("span", {
                        className: styles.assignButtonText
                      }, match[2] ? cancel$p(ctx) : assign$p(ctx)))
            });
}

var make = WeekTimeline_UnassignedJobAssignButton;

export {
  make ,
}
/* styles Not a pure module */
