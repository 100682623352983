import handleDataUpdate from './map/data_update'

const toggleJobStatusFilter = (f, s) => ({ ...f, [s]: !f[s] })

const jobFilterWithOnStatus = (f, s) =>
  Object.assign(...Object.keys(f).map(k => ({ [k]: k == s })))

const toggle = (current, next, key = 'id') => {
  if (current !== null && current[key] === next[key]) {
    return null
  } else {
    return next
  }
}

const setCurrentBlock = (state, block) => {
  if (block === null) {
    return {
      currentBlockId: null,
      focusedJobUuid: null,
      currentLocationId: null
    }
  } else {
    return {
      currentBlockId: block.id,
      currentLocationId: block.locations[0].compositeId,
      focusedJobUuid: block.jobUuid
    }
  }
}

const handleLocationSwitch = (state, { type }) => {
  if (state.currentLocationId === null) {
    return state
  } else {
    const block = state.currentBlock
    const locations = block.locations
    const locationIdx = locations.findIndex(
      l => l.compositeId === state.currentLocationId
    )
    const blocks = state.locationBlocks
    const blockIdx = blocks.indexOf(block)

    switch (type) {
      case 'MAP_NEXT_LOCATION':
        if (locationIdx === locations.length - 1) {
          const nextBlock =
            blocks.length - 1 === blockIdx ? null : blocks[blockIdx + 1]

          if (nextBlock === null) {
            return state
          } else {
            return { ...state, ...setCurrentBlock(state, nextBlock) }
          }
        } else {
          return {
            ...state,
            currentLocationId: locations[locationIdx + 1].compositeId
          }
        }
      case 'MAP_PREV_LOCATION':
        if (locationIdx === 0) {
          const prevBlock = blockIdx === 0 ? null : blocks[blockIdx - 1]

          if (prevBlock === null) {
            return state
          } else {
            return {
              ...state,
              ...setCurrentBlock(state, prevBlock),
              currentLocationId:
                prevBlock.locations[prevBlock.locations.length - 1].compositeId
            }
          }
        } else {
          return {
            ...state,
            currentLocationId: locations[locationIdx - 1].compositeId
          }
        }
    }
  }
}

const handlePanelJobFilter = (state, action) => {
  switch (action.type) {
    case 'MAP_TOGGLE_JOB_STATUS_FILTER': {
      let filter

      if (state.showPanelJobs) {
        filter = toggleJobStatusFilter(state.jobStatusFilter, action.status)
      } else {
        filter = jobFilterWithOnStatus(state.jobStatusFilter, action.status)
      }

      return {
        ...state,
        showPanelJobs: true,
        jobStatusFilter: filter
      }
    }
    case 'MAP_TOGGLE_PANEL_JOBS':
      return { ...state, showPanelJobs: !state.showPanelJobs }
    case 'MAP_TOGGLE_PANEL_STATUS_FILTER':
      return { ...state, showPanelStatusFilter: !state.showPanelStatusFilter }
  }
}

const handleHistoryModeToggle = (
  state,
  { type, date = null, worker = null }
) => {
  switch (type) {
    case 'MAP_SET_WORKER_HISTORY': {
      return {
        ...state,
        loadingLocations: true,
        loadingJobs: true,
        historyWorkerUuid: worker.uuid,
        historyDate: date
      }
    }
    case 'MAP_RESET_WORKER_HISTORY':
      return {
        ...state,
        historyWorkerUuid: null,
        historyDate: null,
        viewingHistory: false,
        currentBlockId: null,
        locationBlocks: [],
        focusedJobUuid: null,
        focusedWorkerUuid: null,
        showWorkerJobs: false,
        locations: [],
        jobs: {}
      }
  }
}

export const reducer = (state, action, _nextVersion) => {
  switch (action.type) {
    case 'MAP_SET_WORKER_HISTORY':
    case 'MAP_RESET_WORKER_HISTORY':
      return handleHistoryModeToggle(state, action)
    case 'MAP_TOGGLE_LOCATION_BLOCK': {
      const block = toggle(state.currentBlock, action.block)

      return {
        ...state,
        ...setCurrentBlock(state, block)
      }
    }
    case 'MAP_SET_LOCATION':
      return {
        ...state,
        currentLocationId:
          state.currentLocationId === action.location.compositeId
            ? null
            : action.location.compositeId
      }
    case 'MAP_START_ENGINE':
      return { ...state, engine: action.engine }
    case 'MAP_SHOW_WORKERS_WO_LOCATION':
      return { ...state, showWorkersWithStaleLocation: true }
    case 'MAP_HIDE_WORKERS_WO_LOCATION':
      return { ...state, showWorkersWithStaleLocation: false }
    case 'MAP_TOGGLE_JOB_STATUS_FILTER':
    case 'MAP_TOGGLE_PANEL_JOBS':
    case 'MAP_TOGGLE_PANEL_STATUS_FILTER':
      return handlePanelJobFilter(state, action)
    case 'MAP_HIDE_FOCUSED_JOB':
      return { ...state, focusedJobUuid: null }
    case 'MAP_TOGGLE_FOCUSED_JOB':
      return {
        ...state,
        focusedJobUuid:
          state.focusedJobUuid === action.job.uuid ? null : action.job.uuid
      }
    case 'MAP_TOGGLE_FOCUSED_WORKER':
      return {
        ...state,
        focusedWorkerUuid:
          state.focusedWorkerUuid === action.worker.uuid
            ? null
            : action.worker.uuid
      }
    case 'MAP_HIDE_FOCUSED_WORKER':
      return { ...state, focusedWorkerUuid: null }
    case 'MAP_NEXT_LOCATION':
    case 'MAP_PREV_LOCATION':
      return handleLocationSwitch(state, action)
    case 'MAP_TOGGLE_WORKER_JOBS':
      return {
        ...state,
        showWorkerJobs: !state.showWorkerJobs
      }
    case 'MAP_ADD_LOCATION':
    case 'MAP_UPDATE_LOCATION':
    case 'MAP_ADD_JOB':
    case 'MAP_UPDATE_JOB':
    case 'MAP_REMOVE_JOB':
    case 'MAP_SET_LOCATIONS':
    case 'MAP_SET_HISTORY_DATE':
    case 'MAP_SET_JOBS':
      return handleDataUpdate(state, action)
    default:
      console.error('Unhandled action', action)
      return state
  }
}
