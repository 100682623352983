

import * as WeekTimeline_DragLayerModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/drag_layer/WeekTimeline_DragLayer.module.css";

var styles = WeekTimeline_DragLayerModuleCss;

function make() {
  var jobEl = document.createElement("div");
  jobEl.setAttribute("class", styles.job);
  var containerEl = document.createElement("div");
  containerEl.setAttribute("class", styles.container);
  containerEl.appendChild(jobEl);
  return {
          containerEl: containerEl,
          jobEl: jobEl
        };
}

export {
  make ,
}
/* styles Not a pure module */
