

import * as El from "../../../../libraries/El.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Billing_Math from "../Billing_Math.mjs";
import * as Billing_Types from "../Billing_Types.mjs";
import * as Billing_Utils from "../Billing_Utils.mjs";
import * as BillingModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/billing/Billing.module.css";

function planName$p(param) {
  var suffix = param.suffix;
  var code = param.code;
  return function (ctx) {
    var name = Utils.Translations.t("js.billing.plan_name." + code)(ctx);
    if (suffix !== undefined) {
      return name + " (" + suffix + ")";
    } else {
      return name;
    }
  };
}

var seats$p = Utils.Translations.txr("js.billing.licenses_no_count");

var free$p = Utils.Translations.tr("js.billing.wizard.free");

var onFreePlan$p = Utils.Translations.tr("js.billing.wizard.on_free_plan");

var perMonth$p = Utils.Translations.tr("js.billing.show.per_month");

var total$p = Utils.Translations.tr("js.billing.wizard.total");

var onPlan$p = Utils.Translations.txr("js.billing.wizard.on_plan");

var pay$p = Utils.Translations.tr("js.billing.wizard.pay");

var everyNMonth$p = Utils.Translations.txh("js.billing.order.every_n_month");

var everyYear$p = Utils.Translations.th("js.billing.order.every_year");

var youSave$p = Utils.Translations.tr("js.billing.wizard.you_save");

var styles = BillingModuleCss;

function Billing_Wizard_Footer(Props) {
  var ctx = Props.ctx;
  var order = Props.order;
  var plan = Props.plan;
  var plans = Props.plans;
  var planType = Props.planType;
  var isFree = planType.name.code === "Free";
  var tmp;
  if (isFree) {
    tmp = React.createElement("div", {
          className: styles.amount
        }, free$p(ctx));
  } else {
    var perMonth = Billing_Math.monthlyAmount(Billing_Math.countSeats(order), plan.priceScheme);
    tmp = perMonth !== undefined ? React.createElement("div", {
            className: styles.amount
          }, total$p(ctx), El.space, Billing_Utils.Format.money$p(perMonth), El.space, perMonth$p(ctx)) : null;
  }
  var maxPrice = Billing_Math.selectMaxPrice(plans);
  var monthlyProfit = RCore.$$Option.map(Billing_Math.profit(maxPrice, plan.priceScheme), Billing_Math.billAmount(order));
  var tmp$1;
  if (monthlyProfit !== undefined) {
    var amount = Caml_option.valFromOption(monthlyProfit);
    tmp$1 = amount !== undefined ? React.createElement("div", {
            className: styles.profit
          }, youSave$p(ctx), El.space, Billing_Utils.Format.money$p(amount), El.space, perMonth$p(ctx)) : null;
  } else {
    tmp$1 = null;
  }
  var tmp$2;
  if (isFree) {
    var limit = planType.features.seatsLimit;
    tmp$2 = limit !== undefined ? React.createElement("div", {
            className: styles.summary
          }, limit.toString(), El.space, seats$p({
                count: limit
              }, ctx), El.space, onFreePlan$p(ctx)) : null;
  } else {
    var ba = Billing_Math.billAmount(order)(plan.priceScheme);
    var seats = Billing_Math.countSeats(order);
    if (ba !== undefined) {
      var match = order.monthsCycle;
      tmp$2 = React.createElement("div", {
            className: styles.summary
          }, seats.toString(), El.space, seats$p({
                count: seats
              }, ctx), El.space, onPlan$p({
                planName: planName$p(planType.name)(ctx)
              }, ctx), El.space, pay$p(ctx), El.space, Billing_Utils.Format.money$p(ba), El.space, match === "year" ? everyYear$p(ctx) : everyNMonth$p({
                  count: Billing_Types.Order.Cycle.toInt(order.monthsCycle)
                }, ctx));
    } else {
      tmp$2 = null;
    }
  }
  return React.createElement("footer", undefined, React.createElement("div", {
                  className: styles.totals
                }, tmp, tmp$1), tmp$2);
}

var Types;

var $$Math;

var make = Billing_Wizard_Footer;

export {
  Types ,
  $$Math ,
  make ,
}
/* seats' Not a pure module */
