

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Client from "react-dom/client";
import * as Server from "react-dom/server";

function renderToElementWithClassName(reactElement, className) {
  var elements = Array.prototype.slice.call(document.getElementsByClassName(className));
  var element = elements[0];
  if (element !== undefined) {
    Client.createRoot(Caml_option.valFromOption(element)).render(reactElement);
  } else {
    console.error("ReactDOM.renderToElementWithClassName: no element of class " + className + " found in the HTML.");
  }
}

function renderToStaticMarkup(prim) {
  return Server.renderToStaticMarkup(prim);
}

var Root = {
  make: (function (prim) {
      return Client.createRoot(prim);
    })
};

function render(prim0, prim1) {
  prim0.render(prim1);
}

export {
  renderToStaticMarkup ,
  Root ,
  render ,
  renderToElementWithClassName ,
}
/* react-dom/client Not a pure module */
