

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Types from "../../../types/Types.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as Context_Types from "../../../context/Context_Types.mjs";
import * as DictionaryTypes from "../dictionary/DictionaryTypes.mjs";
import * as Types_Territory from "../../../types/Types_Territory.mjs";
import * as Pages_Custom_Fields_Types from "../../../pages/custom_fields/Pages_Custom_Fields_Types.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              firstName: field.required("firstName", JsonDecode.string),
              lastName: field.required("lastName", JsonDecode.string),
              email: RCore.$$Option.getOr(field.required("email", JsonDecode.option(JsonDecode.string)), ""),
              mobilePhone: RCore.$$Option.getOr(field.required("mobilePhone", JsonDecode.option(JsonDecode.string)), ""),
              canCompleteJobs: field.required("canCompleteJobs", JsonDecode.bool),
              createdAt: field.required("createdAt", JsonDecode.string)
            };
    });

var decode$1 = JsonDecode.object(function (field) {
      return {
              userId: field.required("userId", Types.Id.decodeOpaque),
              uuid: field.required("uuid", Uuid.decode),
              firstName: field.required("firstName", JsonDecode.string),
              lastName: field.required("lastName", JsonDecode.string),
              email: field.required("email", JsonDecode.string),
              mobilePhone: field.required("mobilePhone", JsonDecode.option(JsonDecode.string)),
              isOwner: field.required("isOwner", JsonDecode.bool),
              isPaid: field.required("isPaid", JsonDecode.bool),
              url: field.required("url", JsonDecode.string)
            };
    });

var decode$2 = JsonDecode.object(function (field) {
      return {
              users: field.required("users", JsonDecode.array(decode$1)),
              availableLicenses: field.required("availableLicenses", JsonDecode.option(JsonDecode.$$int)),
              usedLicenses: field.required("usedLicenses", JsonDecode.$$int),
              userInvitations: field.required("userInvitations", JsonDecode.array(decode))
            };
    });

var Errors = {};

var UserName = {};

var FieldUserForm = {
  Errors: Errors,
  UserName: UserName
};

function fromString(permission) {
  switch (permission) {
    case "delete" :
        return "Delete";
    case "denied" :
        return "Denied";
    case "read" :
        return "Read";
    case "write" :
        return "Write";
    default:
      return ;
  }
}

var decode$3 = JsonDecode.fromVariant(JsonDecode.string, fromString);

var Permission = {
  fromString: fromString,
  decode: decode$3
};

var decode$4 = JsonDecode.object(function (field) {
      return {
              jobs: field.required("readWriteJobs", decode$3),
              schedule: field.required("readWriteSchedule", decode$3),
              clients: field.required("readWriteClients", decode$3),
              teams: field.required("readWriteTeams", decode$3),
              loginWeb: field.required("loginWeb", JsonDecode.bool),
              map: field.required("map", JsonDecode.bool),
              shifts: field.required("shifts", JsonDecode.bool),
              admin: field.required("admin", JsonDecode.bool),
              loginMobile: field.required("loginMobile", JsonDecode.bool),
              createJobsMobile: field.required("createJobsMobile", JsonDecode.bool),
              completeJobs: field.required("completeJobs", JsonDecode.bool),
              jobsExport: field.required("jobsExport", JsonDecode.bool)
            };
    });

var $$Permissions = {
  Permission: Permission,
  decode: decode$4
};

var Skill = {};

var decode$5 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var decode$6 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              firstName: field.required("firstName", JsonDecode.string),
              lastName: field.required("lastName", JsonDecode.string),
              mobilePhone: field.required("mobilePhone", JsonDecode.option(JsonDecode.string)),
              timezoneVariants: field.required("timezoneVariants", JsonDecode.array(Context_Types.TimeZone.decode)),
              timezone: field.required("timezone", JsonDecode.option(JsonDecode.string)),
              email: field.required("email", JsonDecode.string),
              permissions: field.required("permissions", decode$4),
              skillUuids: field.required("skillUuids", JsonDecode.array(Uuid.decode)),
              availableTerritories: field.required("availableTerritories", JsonDecode.array(Types_Territory.decode)),
              territoryUuid: RCore.$$Option.flatMap(field.optional("territoryUuid", JsonDecode.option(Uuid.decode)), (function (x) {
                      return x;
                    })),
              paid: field.required("paid", JsonDecode.bool),
              owner: field.required("owner", JsonDecode.bool),
              tenantHasSeats: field.required("tenantHasSeats", JsonDecode.bool),
              teams: field.required("teams", JsonDecode.array(decode$5)),
              customFieldValues: field.required("customFieldValues", JsonDecode.array(Pages_Custom_Fields_Types.FieldValue.decode)),
              customFields: field.required("customFields", JsonDecode.array(Pages_Custom_Fields_Types.Field.decode)),
              dictionaries: field.required("dictionaries", JsonDecode.array(DictionaryTypes.Index.Dictionary.decode))
            };
    });

var Show = {
  decode: decode$6
};

var decode$7 = JsonDecode.object(function (field) {
      return {
              paid: field.required("paid", JsonDecode.bool),
              permissions: field.required("permissions", decode$4),
              availableTerritories: field.required("availableTerritories", JsonDecode.array(Types_Territory.decode)),
              timezoneVariants: field.required("timezoneVariants", JsonDecode.array(Context_Types.TimeZone.decode)),
              tenantHasSeats: field.required("tenantHasSeats", JsonDecode.bool),
              customFields: field.required("customFields", JsonDecode.array(Pages_Custom_Fields_Types.Field.decode)),
              dictionaries: field.required("dictionaries", JsonDecode.array(DictionaryTypes.Index.Dictionary.decode))
            };
    });

var New = {
  decode: decode$7
};

function fromPair(param) {
  var text = param[1];
  switch (param[0]) {
    case "email" :
        return {
                TAG: "Email",
                _0: text
              };
    case "firstName" :
        return {
                TAG: "FirstName",
                _0: text
              };
    case "lastName" :
        return {
                TAG: "LastName",
                _0: text
              };
    case "password" :
        return {
                TAG: "Password",
                _0: text
              };
    default:
      return ;
  }
}

function errorText(error) {
  return error._0;
}

function firstName(errors) {
  return RCore.$$Array.getBy(errors, (function (e) {
                if (e.TAG === "FirstName") {
                  return true;
                } else {
                  return false;
                }
              }));
}

function lastName(errors) {
  return RCore.$$Array.getBy(errors, (function (e) {
                if (e.TAG === "LastName") {
                  return true;
                } else {
                  return false;
                }
              }));
}

function email(errors) {
  return RCore.$$Array.getBy(errors, (function (e) {
                if (e.TAG === "Email") {
                  return true;
                } else {
                  return false;
                }
              }));
}

function password(errors) {
  return RCore.$$Array.getBy(errors, (function (e) {
                if (e.TAG === "Password") {
                  return true;
                } else {
                  return false;
                }
              }));
}

var $$Error = {
  fromPair: fromPair,
  errorText: errorText,
  firstName: firstName,
  lastName: lastName,
  email: email,
  password: password
};

var Index_UserInvitation = {};

var Index_User = {};

var Index = {
  UserInvitation: Index_UserInvitation,
  User: Index_User,
  decode: decode$2
};

var Team = {};

export {
  Index ,
  FieldUserForm ,
  $$Permissions ,
  Skill ,
  Team ,
  Show ,
  New ,
  $$Error ,
}
/* decode Not a pure module */
