

import Time_picker from "planado/components/input/datetime/time_picker";

var make = Time_picker;

export {
  make ,
}
/* make Not a pure module */
