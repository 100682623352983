

import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function optString(str) {
  if (str === "") {
    return ;
  } else {
    return str;
  }
}

function omit(f) {
  return function (param) {
    return f();
  };
}

function choose(v1, v2, c) {
  if (c) {
    return v1;
  } else {
    return v2;
  }
}

function apply(f) {
  return f();
}

function id(x) {
  return x;
}

function pos(x) {
  return x > 0;
}

function repeat(times, x) {
  if (times !== 0) {
    return Core__List.concat({
                hd: x,
                tl: /* [] */0
              }, repeat(times - 1 | 0, x));
  } else {
    return /* [] */0;
  }
}

function defer(f) {
  setTimeout(f, 0);
}

function both(mx, my) {
  if (mx !== undefined && my !== undefined) {
    return [
            Caml_option.valFromOption(mx),
            Caml_option.valFromOption(my)
          ];
  }
  
}

function stringContains(query, string) {
  return Js_string.indexOf(query.toLowerCase(), string.trim().toLowerCase()) !== -1;
}

export {
  optString ,
  omit ,
  choose ,
  apply ,
  id ,
  pos ,
  repeat ,
  defer ,
  both ,
  stringContains ,
}
/* No side effect */
