

import * as Fun from "../../../../../../../../../libraries/Fun.mjs";
import * as Hooks from "../../../../../../../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../../../../types/Types.mjs";
import * as Utils from "../../../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../../../../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppContext from "../../../../../../../../../context/AppContext.mjs";
import * as Schedule_DND from "../../../../../../../lib/Schedule_DND.mjs";
import * as Assignee_Types from "../../../../../../../../../types/Assignee_Types.mjs";
import * as Schedule_State from "../../../../../../../lib/state/Schedule_State.mjs";
import * as Types_Territory from "../../../../../../../../../types/Types_Territory.mjs";
import * as MultipleAssignee from "../../../../../../../../../types/MultipleAssignee.mjs";
import * as Schedule_Types_Job from "../../../../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as WeekTimeline_State from "../../../../../lib/state/WeekTimeline_State.mjs";
import * as Schedule_Types_Assignees from "../../../../../../../lib/types/Schedule_Types_Assignees.mjs";
import * as WeekTimeline_AssignJobBlockType from "../../../../../lib/types/WeekTimeline_AssignJobBlockType.mjs";

var noTerritory$p = Utils.Translations.tx("js.schedule.v2.job.territory_warning");

function makeBlocks(wire, sizes, period, jobs, assigneesFilter, jobUuid, scheduledAt, startAt, finishAt, territory, hideAssignJobBlocks, dispatch) {
  return RCore.$$Array.filterMap(Schedule_Types_Assignees.filter(wire, assigneesFilter), (function (assignee) {
                var hasScheduledJob = RCore.$$Array.keep(jobs, (function (j) {
                          if (Caml_obj.notequal(Schedule_Types_Job.AssignedJob.uuid(j), jobUuid)) {
                            return Schedule_Types_Job.AssignedJob.scheduledAt(j) !== undefined;
                          } else {
                            return false;
                          }
                        })).some(function (job) {
                      if (Locale.T.intersectIntervalStrict([
                              startAt,
                              finishAt
                            ], [
                              Schedule_Types_Job.AssignedJob.startAt(job),
                              Schedule_Types_Job.AssignedJob.finishAt(job)
                            ])) {
                        return Schedule_Types_Job.AssignedJob.assignees(job).some(function (a) {
                                    return Caml_obj.equal(MultipleAssignee.uuid(a), Assignee_Types.Assignee.uuid(assignee));
                                  });
                      } else {
                        return false;
                      }
                    });
                if (hasScheduledJob) {
                  return ;
                }
                var onClick = function () {
                  hideAssignJobBlocks();
                  var match = MultipleAssignee.PossibleAssignee.findByUuid(Assignee_Types.Assignee.uuid(assignee), wire.ctx.allAssignees);
                  var transferredAssignees = match !== undefined ? (
                      match.TAG === "Team" ? [{
                            TAG: "Team",
                            _0: match._0
                          }] : [{
                            TAG: "Worker",
                            _0: {
                              TAG: "Edit",
                              _0: match._0
                            }
                          }]
                    ) : [];
                  dispatch({
                        TAG: "Jobs",
                        _0: {
                          TAG: "JobSubmitted",
                          _0: jobUuid,
                          _1: scheduledAt,
                          _2: transferredAssignees
                        }
                      });
                };
                var match = Fun.both(territory, Assignee_Types.Assignee.territory(assignee));
                var tooltip;
                if (match !== undefined) {
                  var aTerritory = match[1];
                  tooltip = Caml_obj.notequal(Types_Territory.uuid(match[0]), Types_Territory.uuid(aTerritory)) ? noTerritory$p({
                          territory: Types_Territory.name(aTerritory)
                        }, wire.ctx) : undefined;
                } else {
                  tooltip = undefined;
                }
                return WeekTimeline_AssignJobBlockType.make(assignee, onClick, wire, sizes, period, startAt, finishAt, tooltip);
              }));
}

function use(job, toggleIsActive) {
  var wire = AppContext.useWire();
  var sizes = WeekTimeline_State.useSizes();
  var timelineDispatch = WeekTimeline_State.useDispatch();
  var scheduleDispatch = Schedule_State.useDispatch();
  var period = Schedule_State.usePeriod();
  var jobs = Schedule_State.useAssignedJobs();
  var assigneesFilter = Schedule_State.useAssigneesFilter();
  var match = React.useState(function () {
        return false;
      });
  var setShowCancelButton = match[1];
  var showCancelButton = match[0];
  var elementRef = Types.ReactRef.use();
  var hideAssignJobBlocks = function () {
    setShowCancelButton(function (param) {
          return false;
        });
    timelineDispatch({
          TAG: "SetAssignJobBlocks",
          _0: []
        });
  };
  Hooks.useOnEscPress(!showCancelButton, hideAssignJobBlocks);
  Hooks.useOnClickOutside(!showCancelButton, elementRef, hideAssignJobBlocks);
  var match$1 = Schedule_DND.DND.Context.use();
  var dndSubscriptions = match$1.subscriptions;
  React.useEffect((function () {
          if (showCancelButton) {
            return dndSubscriptions.onDragStart.subscribe(hideAssignJobBlocks);
          }
          
        }), [showCancelButton]);
  var onClick = function ($$event) {
    $$event.stopPropagation();
    toggleIsActive();
    if (showCancelButton) {
      return hideAssignJobBlocks();
    }
    setShowCancelButton(function (param) {
          return true;
        });
    var blocks = makeBlocks(wire, sizes, period, jobs, assigneesFilter, Schedule_Types_Job.UnassignedJob.uuid(job), Schedule_Types_Job.UnassignedJob.scheduledAt(job), Schedule_Types_Job.UnassignedJob.startAt(job), Schedule_Types_Job.UnassignedJob.finishAt(job), Schedule_Types_Job.UnassignedJob.territory(job), hideAssignJobBlocks, scheduleDispatch);
    timelineDispatch({
          TAG: "SetAssignJobBlocks",
          _0: blocks
        });
  };
  return [
          elementRef,
          onClick,
          showCancelButton
        ];
}

export {
  use ,
}
/* noTerritory' Not a pure module */
