

import * as Wire from "../../../../../../types/Wire.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as Portal from "../../../../../../Portal.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../../../../../common/Optional.mjs";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as ModalQuery from "../../../../../../types/ModalQuery.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_Types_Job from "../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as Schedule_AddJobPanel from "../../../add_job_panel/Schedule_AddJobPanel.mjs";
import * as Schedule_JobGroupHeaderModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/popovers/job_group/header/Schedule_JobGroupHeader.module.css";

function jobs$p(c, ctx) {
  return Utils.Translations.txr("js.schedule.v2.jobs_count")({
              count: c
            }, ctx);
}

function unassigned$p(c, ctx) {
  return Utils.Translations.txr("js.schedule.v2.unassigned_count")({
              count: c
            }, ctx);
}

function finished$p(c, ctx) {
  return Utils.Translations.txr("js.schedule.v2.finished_count")({
              count: c
            }, ctx);
}

function unassignedCount(jobs) {
  return RCore.$$Array.keep(jobs, Schedule_Types_Job.ScheduledJob.isUnassigned).length;
}

function finishedCount(jobs) {
  return RCore.$$Array.keep(jobs, Schedule_Types_Job.ScheduledJob.isFinished).length;
}

function groupHour(currentDay, jobs) {
  return RCore.$$Option.getOr(RCore.$$Array.reduce(jobs, undefined, (function (minJobDate, job) {
                    if (minJobDate !== undefined) {
                      return Caml_option.some(Locale.T.min(Schedule_Types_Job.ScheduledJob.startAt(job), Caml_option.valFromOption(minJobDate)));
                    } else {
                      return Caml_option.some(Schedule_Types_Job.ScheduledJob.startAt(job));
                    }
                  })), currentDay);
}

var styles = Schedule_JobGroupHeaderModuleCss;

function Schedule_JobGroupHeader(Props) {
  var assigneeUuid = Props.assigneeUuid;
  var currentDay = Props.currentDay;
  var jobs = Props.jobs;
  var close = Props.close;
  var wire = AppContext.useWire();
  var openModal = wire.openModal;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        
      });
  var setAddJobPopoverPosition = match[1];
  var addJobPopoverPosition = match[0];
  var closeAddJobPopover = function () {
    setAddJobPopoverPosition(function (param) {
          
        });
  };
  var toggleAddJobPopover = function ($$event) {
    var elementRect = $$event.target.getBoundingClientRect();
    var pageX = Units.Px.fromFloat(elementRect.x);
    var pageY = Units.Px.plus(Units.Px.fromFloat(elementRect.y), Units.Px.fromFloat(elementRect.height));
    setAddJobPopoverPosition(function (param) {
          return [
                  pageX,
                  pageY
                ];
        });
  };
  var addJobByTemplate = function (templateUuid) {
    var hour = groupHour(currentDay, jobs);
    var options = {
      TAG: "Schedule",
      _0: ModalQuery.Job.NewOptions.Schedule.make(Locale.T.formatM("DateTimeISO")(hour), RCore.$$Option.map(assigneeUuid, (function (uuid) {
                  return {
                          NAME: "Uuid",
                          VAL: uuid
                        };
                })), templateUuid)
    };
    openModal({
          NAME: "Job",
          VAL: {
            NAME: "New",
            VAL: options
          }
        });
  };
  var addJob = function (templateUuid) {
    return function ($$event) {
      $$event.preventDefault();
      addJobByTemplate(templateUuid);
      setAddJobPopoverPosition(function (param) {
            
          });
    };
  };
  var jobsCount = jobs.length;
  var unassignedCount$1 = unassignedCount(jobs);
  var finishedCount$1 = finishedCount(jobs);
  var currentDay$1 = Locale.T.fmtM("DateWithMonthAbbrAndDayOfWeek", wire)(currentDay);
  var hasRightsToEdit = Wire.hasRightsToEditJobs(wire);
  var tmp;
  if (addJobPopoverPosition !== undefined) {
    var style = {
      left: Units.Px.toString(addJobPopoverPosition[0]),
      top: Units.Px.toString(addJobPopoverPosition[1])
    };
    tmp = React.createElement(Portal.make, {
          root: wire.footer,
          children: React.createElement("div", {
                className: styles.popover,
                style: style
              }, React.createElement(Schedule_AddJobPanel.make, {
                    addJob: addJob,
                    close: closeAddJobPopover
                  }))
        });
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: styles.header
                }, React.createElement("div", {
                      className: styles.group
                    }, React.createElement("div", {
                          className: styles.date
                        }, currentDay$1), React.createElement(Optional.make, {
                          show: hasRightsToEdit,
                          children: React.createElement("div", {
                                className: styles.addJob,
                                onClick: toggleAddJobPopover
                              })
                        }), React.createElement("div", {
                          className: styles.close,
                          onClick: (function (param) {
                              close();
                            })
                        })), React.createElement("div", {
                      className: styles.items
                    }, React.createElement(Optional.make, {
                          show: Caml_obj.notequal(jobs, []),
                          children: React.createElement("div", {
                                className: styles.item
                              }, jobs$p(jobsCount, ctx))
                        }), React.createElement(Optional.make, {
                          show: unassignedCount$1 > 0,
                          children: React.createElement("div", {
                                className: styles.item
                              }, unassigned$p(unassignedCount$1, ctx))
                        }), React.createElement(Optional.make, {
                          show: finishedCount$1 > 0,
                          children: React.createElement("div", {
                                className: styles.item
                              }, finished$p(finishedCount$1, ctx))
                        }))), tmp);
}

var make = React.memo(Schedule_JobGroupHeader);

export {
  make ,
}
/* styles Not a pure module */
