

import * as React from "react";
import * as CamlinternalLazy from "rescript/lib/es6/camlinternalLazy.js";
import * as MapReMarkerComponent from "../../../../../../../../common/mapRe/MapReMarkerComponent.mjs";

var locationSvg = {
  LAZY_DONE: false,
  VAL: (function () {
      return React.createElement("div", {
                  style: {
                    height: "28px",
                    width: "17px"
                  }
                }, React.createElement("svg", {
                      fill: "none",
                      viewBox: "0 0 14 23",
                      xmlns: "http://www.w3.org/2000/svg"
                    }, React.createElement("path", {
                          clipRule: "evenodd",
                          d: "M7 22C8.65685 22 10 20.6569 10 19C10 17.3431 8.65685 16 7 16C5.34315 16 4 17.3431 4 19C4 20.6569 5.34315 22 7 22Z",
                          fill: "#29AAE2",
                          fillRule: "evenodd",
                          stroke: "white"
                        }), React.createElement("path", {
                          d: "M5.9718 16.9611L5.97226 16.9618C6.46902 17.6794 7.53093 17.6794 8.02773 16.9618L8.0282 16.9611C8.8258 15.8048 9.51257 14.8264 10.102 13.9866C11.1781 12.4535 11.9299 11.3824 12.4395 10.535C12.8402 9.86876 13.1102 9.31134 13.2768 8.74896C13.4445 8.18274 13.5 7.63603 13.5 7C13.5 3.41014 10.5899 0.5 7 0.5C3.41014 0.5 0.5 3.41014 0.5 7C0.5 7.63603 0.555536 8.18274 0.723217 8.74896C0.88976 9.31134 1.15979 9.86876 1.5605 10.535C2.07012 11.3824 2.82189 12.4535 3.89798 13.9866C4.48743 14.8264 5.1742 15.8048 5.9718 16.9611ZM9 7C9 8.10457 8.10458 9 7 9C5.89542 9 5 8.10457 5 7C5 5.89542 5.89542 5 7 5C8.10458 5 9 5.89542 9 7Z",
                          fill: "#29AAE2",
                          stroke: "white"
                        })));
    })
};

var textStyle = {
  LAZY_DONE: false,
  VAL: (function () {
      return {
              backgroundColor: "#f6f6f6c4",
              color: "#9013FE",
              fontSize: "12px",
              fontWeight: "bold",
              left: "22px",
              margin: "0",
              padding: "1px",
              position: "absolute",
              top: "2px",
              whiteSpace: "nowrap",
              borderRadius: "2px"
            };
    })
};

function JobForm_HistoryMapMarker$EventMarker$Marker(Props) {
  var text = Props.text;
  return React.createElement("div", {
              style: {
                position: "relative"
              }
            }, CamlinternalLazy.force(locationSvg), text !== undefined ? React.createElement("p", {
                    style: CamlinternalLazy.force(textStyle)
                  }, text) : null);
}

function JobForm_HistoryMapMarker$EventMarker(Props) {
  var text = Props.text;
  var wire = Props.wire;
  var coordinates = Props.coordinates;
  var properties_element = React.createElement(JobForm_HistoryMapMarker$EventMarker$Marker, {
        text: text
      });
  var properties = {
    element: properties_element,
    width: 17.0,
    height: 26.0,
    anchorPosition: "BottomMiddle"
  };
  return React.createElement(MapReMarkerComponent.make, {
              mapType: wire.ctx.mapType,
              coordinates: coordinates,
              customMarkerProperties: properties
            });
}

var markerSvg = {
  LAZY_DONE: false,
  VAL: (function () {
      return React.createElement("div", {
                  style: {
                    height: "47px",
                    width: "129px"
                  }
                }, React.createElement("svg", {
                      fill: "none",
                      viewBox: "0 0 129 47",
                      xmlns: "http://www.w3.org/2000/svg"
                    }, React.createElement("path", {
                          clipRule: "evenodd",
                          d: "M128 36L113 46.5V36H128Z",
                          fill: "#1D89CF",
                          fillRule: "evenodd"
                        }), React.createElement("path", {
                          clipRule: "evenodd",
                          d: "M128 36L113 46.5V36H128Z",
                          fillRule: "evenodd",
                          stroke: "#979797",
                          strokeLinejoin: "round"
                        }), React.createElement("path", {
                          clipRule: "evenodd",
                          d: "M128 36L113 46.5V36H128Z",
                          fillRule: "evenodd",
                          stroke: "#1D89CF",
                          strokeLinejoin: "round"
                        }), React.createElement("rect", {
                          height: "35",
                          width: "127",
                          fill: "white",
                          stroke: "#1D89CF",
                          strokeWidth: "2",
                          x: "1",
                          y: "1"
                        })));
    })
};

var textStyle$1 = {
  LAZY_DONE: false,
  VAL: (function () {
      return {
              color: "black",
              fontSize: "11px",
              height: "35px",
              left: "0",
              lineHeight: "16px",
              margin: "0",
              overflow: "hidden",
              padding: "4px",
              position: "absolute",
              textAlign: "left",
              top: "0",
              width: "129px"
            };
    })
};

function JobForm_HistoryMapMarker$AddressMarker$Marker(Props) {
  var text = Props.text;
  return React.createElement("div", {
              style: {
                position: "relative"
              }
            }, CamlinternalLazy.force(markerSvg), text !== undefined ? React.createElement("p", {
                    style: CamlinternalLazy.force(textStyle$1)
                  }, text) : null);
}

function JobForm_HistoryMapMarker$AddressMarker(Props) {
  var text = Props.text;
  var wire = Props.wire;
  var coordinates = Props.coordinates;
  var properties_element = React.createElement(JobForm_HistoryMapMarker$AddressMarker$Marker, {
        text: text
      });
  var properties_width = 129.0 - 15.0;
  var properties = {
    element: properties_element,
    width: properties_width,
    height: 47.0,
    anchorPosition: "BottomRight"
  };
  return React.createElement(MapReMarkerComponent.make, {
              mapType: wire.ctx.mapType,
              coordinates: coordinates,
              customMarkerProperties: properties
            });
}

var EventMarker = {
  make: JobForm_HistoryMapMarker$EventMarker
};

var AddressMarker = {
  make: JobForm_HistoryMapMarker$AddressMarker
};

export {
  EventMarker ,
  AddressMarker ,
}
/* react Not a pure module */
