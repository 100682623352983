import { createStore } from 'planado/stores/utils'
import reducer from 'planado/reducers/admin/dictionaries_show'
import { v6 as uuidv6 } from 'uuid-with-v6'

const initialDictionary = {
  uuid: null,
  name: '',
  values: [
    { uuid: uuidv6(), name: '', visible: true, isNew: true },
    { uuid: uuidv6(), name: '', visible: true, isNew: true },
    { uuid: uuidv6(), name: '', visible: true, isNew: true }
  ]
}

export default props => {
  const initialState = {
    templates: [],
    validate: false,
    dictionary: initialDictionary,
    popovers: [],
    formSubmitted: false,
    ...props
  }

  return createStore(reducer, initialState, { logger: { diff: true } })
}
