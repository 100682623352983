

import * as JobEdit_FormStateReducerHelper from "./JobEdit_FormStateReducerHelper.mjs";

function reducer(wire, subroute, state, action) {
  if (typeof action !== "object") {
    switch (action) {
      case "Unlock" :
          return JobEdit_FormStateReducerHelper.unlock(state);
      case "ConfirmRemoval" :
          return JobEdit_FormStateReducerHelper.updateConfirmation(state, "Removal");
      case "ConfirmFinish" :
          return JobEdit_FormStateReducerHelper.updateConfirmation(state, "Finish");
      case "ConfirmCancel" :
          return JobEdit_FormStateReducerHelper.updateConfirmation(state, "Cancel");
      case "HideConfirmation" :
          return JobEdit_FormStateReducerHelper.updateConfirmation(state, "None");
      case "ToggleHistory" :
          return JobEdit_FormStateReducerHelper.toggleHistory(state);
      case "NoOp" :
          return "NoUpdate";
      
    }
  } else {
    switch (action.TAG) {
      case "Lock" :
          return JobEdit_FormStateReducerHelper.lock(state, action.saving);
      case "RemoveJob" :
          return JobEdit_FormStateReducerHelper.removeJob(state, action._0, wire);
      case "Destroyed" :
          var response = action._0;
          if (response.TAG !== "Ok") {
            return "NoUpdate";
          }
          var response$1 = response._0;
          return {
                  TAG: "SideEffect",
                  _0: (function (param) {
                      wire.subscriptions.jobs.trigger("CloseModal");
                      wire.subscriptions.jobs.trigger({
                            TAG: "JobSubmitted",
                            _0: response$1.uuid
                          });
                      JobEdit_FormStateReducerHelper.notifyJobSubmit(wire, response$1);
                    })
                };
      case "CancelJob" :
          return JobEdit_FormStateReducerHelper.cancelJob(state, wire, action._0, subroute);
      case "SubmitJob" :
          return JobEdit_FormStateReducerHelper.submitJob(state, subroute, wire, action._0);
      case "SubmitJobResult" :
          return JobEdit_FormStateReducerHelper.submitJobResult(state, wire, action._0);
      case "SubmitWorkflowError" :
          return JobEdit_FormStateReducerHelper.submitWorkflowError(state, wire, action._0);
      
    }
  }
}

var API;

export {
  API ,
  reducer ,
}
/* JobEdit_FormStateReducerHelper Not a pure module */
