

import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as DayTimeline_PositionsHelper from "./DayTimeline_PositionsHelper.mjs";

function make(wire, businessHours, currentDay, sizeOfMinute, startAt, finishAt) {
  var left = DayTimeline_PositionsHelper.left(wire, businessHours, currentDay, sizeOfMinute, startAt);
  return {
          left: left,
          width: DayTimeline_PositionsHelper.width(wire, businessHours, currentDay, sizeOfMinute, left, finishAt)
        };
}

function keepInterval(interval, businessHours, currentDay) {
  if (interval.working) {
    return false;
  } else {
    return DayTimeline_PositionsHelper.inBusinessHours(businessHours, currentDay, interval.from, interval.to_);
  }
}

function make$1(wire, businessHours, currentDay, sizeOfMinute, intervals) {
  return RCore.$$Array.keepMap(intervals, (function (interval) {
                if (keepInterval(interval, businessHours, currentDay)) {
                  return make(wire, businessHours, currentDay, sizeOfMinute, interval.from, interval.to_);
                }
                
              }));
}

export {
  make$1 as make,
}
/* DayTimeline_PositionsHelper Not a pure module */
