const toOnEventName = name =>
  `on${name.substr(0, 1).toUpperCase()}${name.substr(1)}`

function registerEvents(engine, props, eventsList) {
  eventsList.forEach(eventName => {
    const onEventName = toOnEventName(eventName)
    if (Object.prototype.hasOwnProperty.call(props, onEventName)) {
      engine.setEvent(eventName, props[onEventName])
    }
  })
}

function eventsDecorator(component, supportEvents) {
  Object.defineProperty(component.prototype, 'setupEvents', {
    enumerable: false,
    configurable: true,
    writable: true,
    value() {
      registerEvents(this.getEngine(), this.props, supportEvents)
    }
  })

  return component
}

const supportEvents = {
  google: {
    map: [
      'center_changed',
      'bounds_changed',
      'click',
      'dblclick',
      'drag',
      'dragend',
      'dragstart',
      'heading_changed',
      'idle',
      'maptypeid_changed',
      'mousemove',
      'mouseout',
      'mouseover',
      'projection_changed',
      'resize',
      'rightclick',
      'tilesloaded',
      'tilt_changed',
      'zoom_changed'
    ],
    marker: [
      'animation_changed',
      'click',
      'clickable_changed',
      'cursor_changed',
      'dblclick',
      'drag',
      'dragend',
      'draggable_changed',
      'dragstart',
      'flat_changed',
      'icon_changed',
      'mousedown',
      'mouseout',
      'mouseover',
      'mouseup',
      'position_changed',
      'rightclick',
      'shape_changed',
      'title_changed',
      'visible_changed',
      'zindex_changed'
    ]
  },
  yandex: {
    map: [
      'actionbegin',
      'actionbreak',
      'actionend',
      'actiontick',
      'actiontickcomplete',
      'balloonclose',
      'balloonopen',
      'boundschange',
      'click',
      'contextmenu',
      'dblclick',
      'destroy',
      'hintclose',
      'hintopen',
      'marginchange',
      'mousedown',
      'mouseenter',
      'mouseleave',
      'mousemove',
      'mouseup',
      'multitouchend',
      'multitouchmove',
      'multitouchstart',
      'optionschange',
      'sizechange',
      'typechange',
      'wheel'
    ],
    marker: [
      'balloonclose',
      'balloonopen',
      'beforedrag',
      'beforedragstart',
      'click',
      'contextmenu',
      'dblclick',
      'geometrychange',
      'hintclose',
      'hintopen',
      'mapchange',
      'mousedown',
      'mouseenter',
      'mouseleave',
      'mousemove',
      'mouseup',
      'multitouchend',
      'multitouchmove',
      'multitouchstart',
      'optionschange',
      'overlaychange',
      'parentchange',
      'propertieschange',
      'wheel',
      'drag',
      'dragend',
      'dragstart',
      'editorstatechange'
    ]
  }
}

export { eventsDecorator, supportEvents }
