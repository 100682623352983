

import * as El from "../../../../../../libraries/El.mjs";
import * as Uuid from "../../../../../../bindings/uuid/Uuid.mjs";
import * as Modal from "../../../../../modal/Modal.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../../../inputs/Checkbox.mjs";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JobFieldType from "../../../JobFieldType.mjs";
import * as Context_Types from "../../../../../../context/Context_Types.mjs";
import * as JobFieldsList from "../../../JobFieldsList.mjs";
import * as JobIndexFilter_Types from "../../common/JobIndexFilter_Types.mjs";
import * as JobIndexFilter_FieldsTranslations from "../../common/JobIndexFilter_FieldsTranslations.mjs";
import * as ModalModuleCss from "/home/runner/work/planado/planado/client/rescript/components/modal/Modal.module.css";
import * as JobIndexFilter_SettingsPopoverModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/index/job_index_filter/components/settings/JobIndexFilter_SettingsPopover.module.css";

var styles = JobIndexFilter_SettingsPopoverModuleCss;

var modalStyles = ModalModuleCss;

var modalName$p = Utils.Translations.tr("js.jobs.index.filters.settings.modal_name");

var pickFilters$p = Utils.Translations.tr("js.jobs.index.filters.settings.pick_filters");

var save$p = Utils.Translations.tr("js.jobs.index.filters.settings.save");

function checkbox(key, onToggle, checked, label, disabled) {
  return React.createElement("div", {
              key: key,
              className: styles.checkbox
            }, React.createElement(Checkbox.make, {
                  onToggle: onToggle,
                  checked: checked,
                  label: label,
                  disabled: disabled
                }));
}

var allPredefinedFields = [
  "MobileNumber",
  "Territory",
  "TypeCode",
  "CompletionJobs",
  "CreatedAt",
  "WorkDuration",
  "EnRouteDuration",
  "SuspensionDuration",
  "ActualDuration",
  "Geolocation"
];

function JobIndexFilter_SettingsPopover(Props) {
  var data = Props.data;
  var initialPredefinedFields = Props.initialPredefinedFields;
  var initialCustomFields = Props.initialCustomFields;
  var initialReportFields = Props.initialReportFields;
  var hidePopover = Props.hidePopover;
  var createFilters = Props.createFilters;
  var updateFilters = Props.updateFilters;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var permittedPredefinedFields = React.useMemo((function () {
          if (Context_Types.Features.hasFlag("territories", ctx.features)) {
            return allPredefinedFields;
          } else {
            return allPredefinedFields.slice(1);
          }
        }), [Context_Types.Features.hasFlag("territories", ctx.features)]);
  var keepEnabled = function (fields) {
    return RCore.$$Array.filterMap(fields, (function (f) {
                  if (f.enabled) {
                    return Caml_option.some(f.typeUuid);
                  }
                  
                }));
  };
  var match = React.useState(function () {
        return RCore.$$Array.filterMap(initialPredefinedFields, (function (f) {
                      if (f.enabled) {
                        return f.key;
                      }
                      
                    }));
      });
  var setJobFields = match[1];
  var predefinedFields = match[0];
  var match$1 = React.useState(function () {
        return keepEnabled(initialCustomFields);
      });
  var setCustomFields = match$1[1];
  var customFields = match$1[0];
  var match$2 = React.useState(function () {
        return keepEnabled(initialReportFields);
      });
  var setReportFields = match$2[1];
  var reportFields = match$2[0];
  var toggleField = function (checked, set, field) {
    return set(function (prev) {
                if (checked) {
                  return Belt_Array.concatMany([
                              prev,
                              [field]
                            ]);
                } else {
                  return RCore.$$Array.keep(prev, (function (j) {
                                return Caml_obj.notequal(j, field);
                              }));
                }
              });
  };
  var systemCheckboxes = JobIndexFilter_Types.allSystem.map(function (c) {
        return checkbox(JobFieldType.toString(c), (function (prim) {
                      
                    }), true, JobIndexFilter_FieldsTranslations.jobFieldsHeader$p(c)(ctx), true);
      });
  var jobFieldsCheckboxes = permittedPredefinedFields.map(function (c) {
        return checkbox(JobFieldType.toString(c), (function (bool) {
                      toggleField(bool, setJobFields, c);
                    }), predefinedFields.some(function (f) {
                        return f === c;
                      }), JobIndexFilter_FieldsTranslations.jobFieldsHeader$p(c)(ctx), false);
      });
  var disabledField = function (field) {
    if (typeof field === "object") {
      return false;
    }
    switch (field) {
      case "TimePicker" :
      case "Image" :
      case "Location" :
      case "Duration" :
      case "File" :
      case "Action" :
      case "Link" :
      case "Button" :
          return true;
      default:
        return false;
    }
  };
  var customFieldsCheckboxes = data.customFields.length === 0 ? El.ndash : data.customFields.map(function (field) {
          return checkbox(Uuid.toString(field.uuid), (function (checked) {
                        toggleField(checked, setCustomFields, field.uuid);
                      }), customFields.some(function (f) {
                          return Caml_obj.equal(f, field.uuid);
                        }), field.name, disabledField(field.type_));
        });
  var reportFieldsCheckboxes = data.reportFields.length === 0 ? El.ndash : data.reportFields.map(function (field) {
          return checkbox(Uuid.toString(field.uuid), (function (bool) {
                        toggleField(bool, setReportFields, field.uuid);
                      }), reportFields.some(function (f) {
                          return Caml_obj.equal(f, field.uuid);
                        }), field.name, disabledField(field.type_));
        });
  var makeEnabledCustomFields = function (allFields, pickedFields) {
    return allFields.map(function (f) {
                return {
                        typeUuid: f,
                        enabled: pickedFields.some(function (x) {
                              return Uuid.equal(f, x);
                            })
                      };
              });
  };
  var makeEnabledPredefinedFields = function (allFields, pickedFields) {
    return allFields.map(function (f) {
                return {
                        key: f,
                        enabled: pickedFields.some(function (j) {
                              return j === f;
                            })
                      };
              });
  };
  var onSave = function (param) {
    var filters = data.filters;
    var partial = filters !== undefined ? updateFilters(filters.uuid) : createFilters;
    partial(makeEnabledPredefinedFields(permittedPredefinedFields, predefinedFields), makeEnabledCustomFields(data.customFields.map(function (f) {
                  return f.uuid;
                }), customFields), makeEnabledCustomFields(data.reportFields.map(function (f) {
                  return f.uuid;
                }), reportFields));
  };
  return React.createElement(Modal.make, {
              wire: wire,
              onClose: hidePopover,
              children: React.createElement("div", {
                    className: El.Cn.concat(modalStyles.dialog, styles.dialog)
                  }, React.createElement(Modal.Header.make, {
                        children: React.createElement("div", {
                              className: styles.headerHeadline
                            }, modalName$p(ctx))
                      }), React.createElement(Modal.Body.make, {
                        className: styles.container,
                        children: null
                      }, React.createElement("div", {
                            className: styles.columnsHeader
                          }, pickFilters$p(ctx)), React.createElement(JobFieldsList.make, {
                            jobFields: React.createElement(React.Fragment, undefined, systemCheckboxes, jobFieldsCheckboxes),
                            customFields: customFieldsCheckboxes,
                            reportFields: reportFieldsCheckboxes
                          })), React.createElement(Modal.Footer.make, {
                        className: styles.footer,
                        children: null
                      }, React.createElement(Modal.CloseButton.make, {
                            ctx: ctx
                          }), React.createElement(Modal.SubmitButton.make, {
                            onClick: onSave,
                            children: save$p(ctx)
                          })))
            });
}

var make = JobIndexFilter_SettingsPopover;

export {
  make ,
}
/* styles Not a pure module */
