

import * as RCore from "../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../utils/Utils.mjs";
import * as Errors from "../../../../../Errors.mjs";
import * as Locale from "../../../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_API from "../api/Schedule_API.mjs";
import * as Shared_Lib_IO from "../../../../../shared/lib/Shared_Lib_IO.mjs";
import * as MessengerTypes from "../../../../messenger/MessengerTypes.mjs";
import * as Schedule_Types_Job from "../../types/job/Schedule_Types_Job.mjs";
import * as Schedule_StateTypes from "../Schedule_StateTypes.mjs";
import * as MultipleAssignees_Utils from "../../../../common/MultipleAssignees/MultipleAssignees_Utils.mjs";

var jobNotFound$p = Utils.Translations.t("js.jobs.index.job_not_found");

function displayError(messenger, text) {
  messenger.trigger({
        TAG: "Show",
        _0: MessengerTypes.Message.make(undefined, "Danger", {
              TAG: "Text",
              _0: text
            })
      });
}

function handleResponse(resultIO, wire) {
  var messenger = wire.subscriptions.messenger;
  var ctx = wire.ctx;
  Shared_Lib_IO.unsafeRunAsync(Shared_Lib_IO.bimap(resultIO, (function (result) {
              if (result.TAG === "Ok") {
                return ;
              }
              var match = result._0;
              if (typeof match !== "object") {
                return ;
              }
              if (match.TAG !== "HTTPError") {
                return ;
              }
              var match$1 = match._0;
              var tmp = match$1[0];
              if (typeof tmp === "object") {
                return ;
              }
              switch (tmp) {
                case "NotFound" :
                    return displayError(messenger, jobNotFound$p(ctx));
                case "UnprocessableEntity" :
                    var text = Errors.getString("assignees", Errors.parse(match$1[1]));
                    if (text !== undefined) {
                      return displayError(messenger, text);
                    } else {
                      return ;
                    }
                default:
                  return ;
              }
            }), (function (error) {
              console.log(error);
            })), (function (prim) {
          
        }));
}

function syncScheduledJob(wire, job) {
  var jobUuid = Schedule_Types_Job.ScheduledJob.uuid(job);
  var scheduledAt = RCore.$$Option.map(Schedule_Types_Job.ScheduledJob.scheduledAt(job), (function (d) {
          return Locale.T.presentDatetime(wire, d);
        }));
  handleResponse(Schedule_API.Job.Patch.$$Request.update(jobUuid, MultipleAssignees_Utils.withActualTempWorkers(Schedule_Types_Job.ScheduledJob.assignees(job), Schedule_Types_Job.ScheduledJob.scheduledAt(job), wire.ctx.allAssignees), scheduledAt, wire), wire);
}

function scheduleJob(wire, state, jobUuid, scheduledAt) {
  var match = Schedule_StateTypes.State.scheduleJob(state, jobUuid, scheduledAt);
  if (match === undefined) {
    return "NoUpdate";
  }
  var job = match[1];
  return {
          TAG: "UpdateWithSideEffect",
          _0: Schedule_StateTypes.State.addScheduledJob(Schedule_StateTypes.State.removeJobByUuid(state, jobUuid), job),
          _1: (function (param) {
              syncScheduledJob(wire, job);
            })
        };
}

function syncUnscheduledJob(wire, job) {
  var jobUuid = Schedule_Types_Job.UnscheduledJob.uuid(job);
  var scheduledAt = RCore.$$Option.map(Schedule_Types_Job.UnscheduledJob.scheduledAt(job), (function (x) {
          return Locale.T.presentDatetime(wire, x);
        }));
  handleResponse(Schedule_API.Job.Patch.$$Request.update(jobUuid, MultipleAssignees_Utils.withActualTempWorkers(Schedule_Types_Job.UnscheduledJob.assignees(job), Schedule_Types_Job.UnscheduledJob.scheduledAt(job), wire.ctx.allAssignees), scheduledAt, wire), wire);
}

function unscheduleJob(wire, state, jobUuid) {
  var job = Schedule_StateTypes.State.findScheduledJob(state, jobUuid);
  if (job === undefined) {
    return "NoUpdate";
  }
  var init = Schedule_Types_Job.ScheduledJob.core(job);
  var job$1 = Schedule_Types_Job.UnscheduledJob.make({
        uuid: init.uuid,
        status: init.status,
        duration: init.duration,
        template: init.template,
        client: init.client,
        territory: init.territory,
        skillUuids: init.skillUuids,
        address: init.address,
        scheduledAt: undefined,
        enRouteAt: init.enRouteAt,
        startedAt: init.startedAt,
        suspendedAt: init.suspendedAt,
        finishedAt: init.finishedAt,
        resolution: init.resolution,
        customUi: init.customUi
      }, Schedule_Types_Job.ScheduledJob.assignees(job));
  var state$1 = Schedule_StateTypes.State.addUnscheduledJob(Schedule_StateTypes.State.removeJobByUuid(state, jobUuid), job$1);
  return {
          TAG: "UpdateWithSideEffect",
          _0: state$1,
          _1: (function (param) {
              syncUnscheduledJob(wire, job$1);
            })
        };
}

function unassignJob(wire, state, jobUuid) {
  var job = Schedule_StateTypes.State.findScheduledJob(state, jobUuid);
  if (job === undefined) {
    return "NoUpdate";
  }
  if (!Schedule_Types_Job.ScheduledJob.isAssigned(job)) {
    return "NoUpdate";
  }
  var job$1 = Schedule_Types_Job.ScheduledJob.make([], Schedule_Types_Job.ScheduledJob.core(job), Schedule_Types_Job.ScheduledJob.interval(job));
  var state$1 = Schedule_StateTypes.State.addScheduledJob(Schedule_StateTypes.State.removeJobByUuid(state, Schedule_Types_Job.ScheduledJob.uuid(job$1)), job$1);
  return {
          TAG: "UpdateWithSideEffect",
          _0: state$1,
          _1: (function (param) {
              syncScheduledJob(wire, job$1);
            })
        };
}

function resetAssignee(job) {
  return {
          core: job.core,
          interval: job.interval,
          bar: job.bar,
          assignees: []
        };
}

function setAssignee(job, assignees) {
  return {
          core: job.core,
          interval: job.interval,
          bar: job.bar,
          assignees: assignees
        };
}

function submitJob(wire, state, jobUuid, scheduledAt, assignees) {
  var job = Schedule_StateTypes.State.findScheduledJob(state, jobUuid);
  if (job !== undefined) {
    if (scheduledAt !== undefined) {
      var scheduledAt$1 = Caml_option.valFromOption(scheduledAt);
      var init = Schedule_Types_Job.ScheduledJob.core(job);
      var job$1 = Schedule_Types_Job.ScheduledJob.make(assignees, {
            uuid: init.uuid,
            status: init.status,
            duration: init.duration,
            template: init.template,
            client: init.client,
            territory: init.territory,
            skillUuids: init.skillUuids,
            address: init.address,
            scheduledAt: Caml_option.some(scheduledAt$1),
            enRouteAt: init.enRouteAt,
            startedAt: init.startedAt,
            suspendedAt: init.suspendedAt,
            finishedAt: init.finishedAt,
            resolution: init.resolution,
            customUi: init.customUi
          }, {
            startAt: scheduledAt$1,
            finishAt: Locale.T.nextMinutes(Schedule_Types_Job.ScheduledJob.duration(job), scheduledAt$1)
          });
      return {
              TAG: "UpdateWithSideEffect",
              _0: Schedule_StateTypes.State.addScheduledJob(Schedule_StateTypes.State.removeJobByUuid(state, jobUuid), job$1),
              _1: (function (param) {
                  syncScheduledJob(wire, job$1);
                })
            };
    }
    var init$1 = Schedule_Types_Job.ScheduledJob.core(job);
    var job$2 = Schedule_Types_Job.UnscheduledJob.make({
          uuid: init$1.uuid,
          status: init$1.status,
          duration: init$1.duration,
          template: init$1.template,
          client: init$1.client,
          territory: init$1.territory,
          skillUuids: init$1.skillUuids,
          address: init$1.address,
          scheduledAt: undefined,
          enRouteAt: init$1.enRouteAt,
          startedAt: init$1.startedAt,
          suspendedAt: init$1.suspendedAt,
          finishedAt: init$1.finishedAt,
          resolution: init$1.resolution,
          customUi: init$1.customUi
        }, assignees);
    return {
            TAG: "UpdateWithSideEffect",
            _0: Schedule_StateTypes.State.addUnscheduledJob(Schedule_StateTypes.State.removeJobByUuid(state, jobUuid), job$2),
            _1: (function (param) {
                syncUnscheduledJob(wire, job$2);
              })
          };
  }
  var job$3 = Schedule_StateTypes.State.findUnscheduledJob(state, jobUuid);
  if (job$3 === undefined) {
    return "NoUpdate";
  }
  if (scheduledAt !== undefined) {
    var scheduledAt$2 = Caml_option.valFromOption(scheduledAt);
    var init$2 = Schedule_Types_Job.UnscheduledJob.core(job$3);
    var job$4 = Schedule_Types_Job.ScheduledJob.make(assignees, {
          uuid: init$2.uuid,
          status: init$2.status,
          duration: init$2.duration,
          template: init$2.template,
          client: init$2.client,
          territory: init$2.territory,
          skillUuids: init$2.skillUuids,
          address: init$2.address,
          scheduledAt: Caml_option.some(scheduledAt$2),
          enRouteAt: init$2.enRouteAt,
          startedAt: init$2.startedAt,
          suspendedAt: init$2.suspendedAt,
          finishedAt: init$2.finishedAt,
          resolution: init$2.resolution,
          customUi: init$2.customUi
        }, {
          startAt: scheduledAt$2,
          finishAt: Locale.T.nextMinutes(Schedule_Types_Job.UnscheduledJob.duration(job$3), scheduledAt$2)
        });
    return {
            TAG: "UpdateWithSideEffect",
            _0: Schedule_StateTypes.State.addScheduledJob(Schedule_StateTypes.State.removeJobByUuid(state, jobUuid), job$4),
            _1: (function (param) {
                syncScheduledJob(wire, job$4);
              })
          };
  }
  var init$3 = Schedule_Types_Job.UnscheduledJob.core(job$3);
  var job$5 = Schedule_Types_Job.UnscheduledJob.make({
        uuid: init$3.uuid,
        status: init$3.status,
        duration: init$3.duration,
        template: init$3.template,
        client: init$3.client,
        territory: init$3.territory,
        skillUuids: init$3.skillUuids,
        address: init$3.address,
        scheduledAt: undefined,
        enRouteAt: init$3.enRouteAt,
        startedAt: init$3.startedAt,
        suspendedAt: init$3.suspendedAt,
        finishedAt: init$3.finishedAt,
        resolution: init$3.resolution,
        customUi: init$3.customUi
      }, assignees);
  return {
          TAG: "UpdateWithSideEffect",
          _0: Schedule_StateTypes.State.addUnscheduledJob(Schedule_StateTypes.State.removeJobByUuid(state, jobUuid), job$5),
          _1: (function (param) {
              syncUnscheduledJob(wire, job$5);
            })
        };
}

function removeJob(state, jobUuid) {
  return {
          TAG: "Update",
          _0: Schedule_StateTypes.State.removeJobByUuid(state, jobUuid)
        };
}

function inPeriod(period, param) {
  return Locale.T.intersectInterval(period, [
              param.startAt,
              param.finishAt
            ]);
}

function refineJobInterval(now, state) {
  return function (job) {
    var match = Schedule_Types_Job.ScheduledJob.status(job);
    var interval;
    var exit = 0;
    switch (match) {
      case "EnRoute" :
      case "Started" :
          exit = 1;
          break;
      case "Suspended" :
          if (Locale.T.moreThan(Schedule_Types_Job.ScheduledJob.finishAt(job), now)) {
            var init = Schedule_Types_Job.ScheduledJob.interval(job);
            interval = {
              startAt: init.startAt,
              finishAt: RCore.$$Option.getOr(Schedule_Types_Job.ScheduledJob.suspendedAt(job), now)
            };
          } else {
            interval = Schedule_Types_Job.ScheduledJob.interval(job);
          }
          break;
      default:
        interval = Schedule_Types_Job.ScheduledJob.interval(job);
    }
    if (exit === 1) {
      var scheduledFinishAt = Schedule_Types_Job.ScheduledJob.scheduledFinishAt(job);
      var at;
      if (scheduledFinishAt !== undefined) {
        var scheduledFinishAt$1 = Caml_option.valFromOption(scheduledFinishAt);
        at = Locale.T.moreThan(scheduledFinishAt$1, now) ? scheduledFinishAt$1 : now;
      } else {
        at = now;
      }
      var duration = Locale.T.minutesBetween(at, Schedule_Types_Job.ScheduledJob.startAt(job));
      var finishAt;
      if (duration >= Schedule_Types_Job.ScheduledJob.duration(job)) {
        finishAt = at;
      } else {
        var diffMin = Schedule_Types_Job.ScheduledJob.duration(job) - duration | 0;
        finishAt = Locale.T.nextMinutes(diffMin, at);
      }
      var init$1 = Schedule_Types_Job.ScheduledJob.interval(job);
      interval = {
        startAt: init$1.startAt,
        finishAt: finishAt
      };
    }
    var finishAt$1 = interval.finishAt;
    var startAt = interval.startAt;
    var durationMin = Locale.T.minutesBetween(finishAt$1, startAt);
    var interval$1;
    if (durationMin >= 5) {
      interval$1 = interval;
    } else {
      var diffMin$1 = 5 - durationMin | 0;
      interval$1 = {
        startAt: startAt,
        finishAt: Locale.T.nextMinutes(diffMin$1, finishAt$1)
      };
    }
    if (inPeriod(state.settings.filter.period, interval$1)) {
      return Schedule_Types_Job.ScheduledJob.$$setInterval(job, interval$1);
    }
    
  };
}

function refineJobIntervals(now, state) {
  var init = state.scheduledJobs;
  return {
          scheduledJobs: {
            loading: init.loading,
            warning: init.warning,
            jobs: RCore.$$Array.filterMap(state.scheduledJobs.jobs, refineJobInterval(now, state))
          },
          unscheduledJobs: state.unscheduledJobs,
          settings: state.settings,
          scheduleTimelines: state.scheduleTimelines
        };
}

function setJob(wire, state, job) {
  var hasJob = Schedule_StateTypes.State.findScheduledJob(state, Schedule_Types_Job.DecodedJob.uuid(job)) !== undefined || Schedule_StateTypes.State.findUnscheduledJob(state, Schedule_Types_Job.DecodedJob.uuid(job)) !== undefined;
  var inPeriod$1 = RCore.$$Option.mapWithDefault(Schedule_Types_Job.DecodedJob.interval(job), true, (function (interval) {
          return inPeriod(Schedule_StateTypes.State.period(state), interval);
        }));
  var canceled = Schedule_Types_Job.DecodedJob.core(job).status === "Canceled";
  var territories = state.settings.filter.territories;
  var match = Schedule_Types_Job.DecodedJob.territory(job);
  var filtered;
  if (Caml_obj.equal(territories, [])) {
    filtered = false;
  } else if (match !== undefined) {
    var uuid = match.uuid;
    filtered = territories.some(function (tUuid) {
          return Caml_obj.equal(tUuid, uuid);
        }) ? false : true;
  } else {
    filtered = true;
  }
  if (!hasJob && (filtered || !inPeriod$1)) {
    return "NoUpdate";
  }
  if (hasJob && (filtered || !inPeriod$1 || canceled)) {
    return removeJob(state, Schedule_Types_Job.DecodedJob.uuid(job));
  }
  var interval = Schedule_Types_Job.DecodedJob.interval(job);
  if (interval !== undefined) {
    var state$1 = Schedule_StateTypes.State.removeJobByUuid(state, Schedule_Types_Job.DecodedJob.uuid(job));
    var j = Schedule_Types_Job.ScheduledJob.make(Schedule_Types_Job.DecodedJob.assignees(job), Schedule_Types_Job.DecodedJob.core(job), interval);
    var job$1 = refineJobInterval(Locale.T.now(wire), state$1)(j);
    if (job$1 !== undefined) {
      return {
              TAG: "Update",
              _0: Schedule_StateTypes.State.addScheduledJob(state$1, job$1)
            };
    } else {
      return {
              TAG: "Update",
              _0: state$1
            };
    }
  }
  var job$2 = Schedule_Types_Job.UnscheduledJob.make(Schedule_Types_Job.DecodedJob.core(job), Schedule_Types_Job.DecodedJob.assignees(job));
  var hasJob$1 = Schedule_StateTypes.State.findUnscheduledJob(state, Schedule_Types_Job.UnscheduledJob.uuid(job$2)) !== undefined;
  if (hasJob$1) {
    return {
            TAG: "Update",
            _0: Schedule_StateTypes.State.replaceUnscheduledJob(state, job$2)
          };
  } else {
    return {
            TAG: "Update",
            _0: Schedule_StateTypes.State.addUnscheduledJob(state, job$2)
          };
  }
}

function fetchScheduledJobs(wire, state, view) {
  var state_scheduledJobs = {
    loading: true,
    warning: "Hidden",
    jobs: []
  };
  var state_unscheduledJobs = state.unscheduledJobs;
  var state_settings = state.settings;
  var state_scheduleTimelines = state.scheduleTimelines;
  var state$1 = {
    scheduledJobs: state_scheduledJobs,
    unscheduledJobs: state_unscheduledJobs,
    settings: state_settings,
    scheduleTimelines: state_scheduleTimelines
  };
  var filterAssigned = view.NAME === "Calendar";
  return {
          TAG: "UpdateWithIO",
          _0: state$1,
          _1: Shared_Lib_IO.bimap(Schedule_API.ScheduledJobs.$$fetch([
                    wire,
                    state_settings.filter,
                    filterAssigned,
                    state_settings.jobDuration
                  ]), (function (r) {
                  if (r === undefined) {
                    return {
                            TAG: "Jobs",
                            _0: "NoOp"
                          };
                  }
                  if (r.TAG !== "Ok") {
                    return {
                            TAG: "Jobs",
                            _0: "NoOp"
                          };
                  }
                  var match = r._0;
                  return {
                          TAG: "Jobs",
                          _0: {
                            TAG: "ScheduledJobsFetched",
                            _0: Locale.T.now(wire),
                            _1: match.jobs,
                            _2: match.jobsLimit
                          }
                        };
                }), (function (_error) {
                  return {
                          TAG: "Jobs",
                          _0: "NoOp"
                        };
                }))
        };
}

function setScheduledJobs(now, state, jobs, jobsLimit) {
  var state_scheduledJobs = {
    loading: false,
    warning: Schedule_Types_Job.Warning.fromJobs(jobs, jobsLimit),
    jobs: jobs
  };
  var state_unscheduledJobs = state.unscheduledJobs;
  var state_settings = state.settings;
  var state_scheduleTimelines = state.scheduleTimelines;
  var state$1 = {
    scheduledJobs: state_scheduledJobs,
    unscheduledJobs: state_unscheduledJobs,
    settings: state_settings,
    scheduleTimelines: state_scheduleTimelines
  };
  var match = state_settings.jobDuration;
  if (match === "Scheduled") {
    return {
            TAG: "Update",
            _0: state$1
          };
  } else {
    return {
            TAG: "Update",
            _0: refineJobIntervals(now, state$1)
          };
  }
}

function fetchUnscheduledJobs(wire, state) {
  var init = Schedule_StateTypes.State.UnscheduledJobs.initial;
  return {
          TAG: "UpdateWithIO",
          _0: {
            scheduledJobs: state.scheduledJobs,
            unscheduledJobs: {
              loading: true,
              jobs: init.jobs,
              jobsLimit: init.jobsLimit,
              jobsAfter: init.jobsAfter
            },
            settings: state.settings,
            scheduleTimelines: state.scheduleTimelines
          },
          _1: Shared_Lib_IO.bimap(Schedule_API.UnscheduledJobs.$$fetch([
                    wire,
                    state.settings.filter,
                    undefined,
                    state.settings.jobDuration
                  ]), (function (r) {
                  if (r === undefined) {
                    return {
                            TAG: "Jobs",
                            _0: "NoOp"
                          };
                  }
                  if (r.TAG !== "Ok") {
                    return {
                            TAG: "Jobs",
                            _0: "NoOp"
                          };
                  }
                  var match = r._0;
                  return {
                          TAG: "Jobs",
                          _0: {
                            TAG: "UnscheduledJobsFetched",
                            _0: match.jobs,
                            _1: match.jobsLimit,
                            _2: match.jobsAfter
                          }
                        };
                }), (function (param) {
                  return {
                          TAG: "Jobs",
                          _0: "NoOp"
                        };
                }))
        };
}

function fetchNextUnscheduledJobs(wire, state) {
  var init = state.unscheduledJobs;
  return {
          TAG: "UpdateWithIO",
          _0: {
            scheduledJobs: state.scheduledJobs,
            unscheduledJobs: {
              loading: true,
              jobs: init.jobs,
              jobsLimit: init.jobsLimit,
              jobsAfter: init.jobsAfter
            },
            settings: state.settings,
            scheduleTimelines: state.scheduleTimelines
          },
          _1: Shared_Lib_IO.bimap(Schedule_API.UnscheduledJobs.$$fetch([
                    wire,
                    state.settings.filter,
                    state.unscheduledJobs.jobsAfter,
                    state.settings.jobDuration
                  ]), (function (r) {
                  if (r === undefined) {
                    return {
                            TAG: "Jobs",
                            _0: "NoOp"
                          };
                  }
                  if (r.TAG !== "Ok") {
                    return {
                            TAG: "Jobs",
                            _0: "NoOp"
                          };
                  }
                  var match = r._0;
                  return {
                          TAG: "Jobs",
                          _0: {
                            TAG: "NextUnscheduledJobsFetched",
                            _0: match.jobs,
                            _1: match.jobsLimit,
                            _2: match.jobsAfter
                          }
                        };
                }), (function (param) {
                  return {
                          TAG: "Jobs",
                          _0: "NoOp"
                        };
                }))
        };
}

function uniqueUnscheduledJobs(jobs) {
  return RCore.$$Array.reduce(jobs, [], (function (memo, job) {
                var inArray = memo.some(function (j) {
                      return Caml_obj.equal(Schedule_Types_Job.UnscheduledJob.uuid(j), Schedule_Types_Job.UnscheduledJob.uuid(job));
                    });
                if (!inArray) {
                  Js_array.push(job, memo);
                }
                return memo;
              }));
}

function setUnscheduledJobs(state, jobs, jobsLimit, jobsAfter) {
  return {
          TAG: "Update",
          _0: {
            scheduledJobs: state.scheduledJobs,
            unscheduledJobs: {
              loading: false,
              jobs: jobs,
              jobsLimit: Caml_option.some(jobsLimit),
              jobsAfter: jobsAfter
            },
            settings: state.settings,
            scheduleTimelines: state.scheduleTimelines
          }
        };
}

function setNextUnscheduledJobs(state, jobs, jobsLimit, jobsAfter) {
  return {
          TAG: "Update",
          _0: {
            scheduledJobs: state.scheduledJobs,
            unscheduledJobs: {
              loading: false,
              jobs: uniqueUnscheduledJobs(Belt_Array.concatMany([
                        state.unscheduledJobs.jobs,
                        jobs
                      ])),
              jobsLimit: Caml_option.some(jobsLimit),
              jobsAfter: jobsAfter
            },
            settings: state.settings,
            scheduleTimelines: state.scheduleTimelines
          }
        };
}

function setScheduleTimelines(state, workersTimelines, teamTimelines) {
  return {
          TAG: "Update",
          _0: {
            scheduledJobs: state.scheduledJobs,
            unscheduledJobs: state.unscheduledJobs,
            settings: state.settings,
            scheduleTimelines: {
              loading: false,
              workersTimelines: workersTimelines,
              teamTimelines: teamTimelines
            }
          }
        };
}

export {
  scheduleJob ,
  unscheduleJob ,
  unassignJob ,
  resetAssignee ,
  setAssignee ,
  submitJob ,
  removeJob ,
  refineJobIntervals ,
  setJob ,
  fetchScheduledJobs ,
  setScheduledJobs ,
  fetchUnscheduledJobs ,
  fetchNextUnscheduledJobs ,
  uniqueUnscheduledJobs ,
  setUnscheduledJobs ,
  setNextUnscheduledJobs ,
  setScheduleTimelines ,
}
/* jobNotFound' Not a pure module */
