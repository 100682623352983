

import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.mjs";

function apply(x, f) {
  if (x.TAG === "Ok") {
    if (f.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: f._0(x._0)
            };
    } else {
      return {
              TAG: "Error",
              _0: f._0
            };
    }
  } else if (f.TAG === "Ok") {
    return {
            TAG: "Error",
            _0: x._0
          };
  } else {
    return {
            TAG: "Error",
            _0: f._0
          };
  }
}

function mapError(x, f) {
  if (x.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: x._0
          };
  } else {
    return {
            TAG: "Error",
            _0: f(x._0)
          };
  }
}

function align(fa, fb) {
  if (fa.TAG !== "Ok") {
    if (fb.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                TAG: "Right",
                _0: fb._0
              }
            };
    } else {
      return {
              TAG: "Error",
              _0: fa._0
            };
    }
  }
  var a = fa._0;
  if (fb.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              TAG: "Both",
              _0: a,
              _1: fb._0
            }
          };
  } else {
    return {
            TAG: "Ok",
            _0: {
              TAG: "Left",
              _0: a
            }
          };
  }
}

var map = Core__Result.map;

export {
  apply ,
  mapError ,
  map ,
  align ,
}
/* No side effect */
