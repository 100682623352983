

import * as Fun from "./Fun.mjs";
import * as Uuid from "../bindings/uuid/Uuid.mjs";
import * as RCore from "./RCore.mjs";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Encode.mjs";

var optionString = Json_Encode$JsonCombinators.option(function (prim) {
      return prim;
    });

function nullableString(value) {
  return Json_Encode$JsonCombinators.option(function (prim) {
                return prim;
              })(Fun.optString(value));
}

var optionBool = Json_Encode$JsonCombinators.option(function (prim) {
      return prim;
    });

function optionUuid(value) {
  return Json_Encode$JsonCombinators.option(function (prim) {
                return prim;
              })(RCore.$$Option.map(value, Uuid.toString));
}

function uuid(value) {
  return Uuid.toString(value);
}

function $$int(prim) {
  return prim;
}

function $$float(prim) {
  return prim;
}

function bool(prim) {
  return prim;
}

function string(prim) {
  return prim;
}

var array = Json_Encode$JsonCombinators.array;

var list = Json_Encode$JsonCombinators.list;

var object = Json_Encode$JsonCombinators.object;

var option = Json_Encode$JsonCombinators.option;

var withDefault = Json_Encode$JsonCombinators.withDefault;

var date = Json_Encode$JsonCombinators.date;

var pair = Json_Encode$JsonCombinators.pair;

var dict = Json_Encode$JsonCombinators.dict;

function jsonDict(prim) {
  return prim;
}

function jsonArray(prim) {
  return prim;
}

function stringArray(prim) {
  return prim;
}

function intArray(prim) {
  return prim;
}

function floatArray(prim) {
  return prim;
}

function boolArray(prim) {
  return prim;
}

var $$null = null;

export {
  $$int ,
  $$float ,
  bool ,
  string ,
  array ,
  list ,
  object ,
  option ,
  withDefault ,
  date ,
  pair ,
  dict ,
  jsonDict ,
  jsonArray ,
  stringArray ,
  intArray ,
  floatArray ,
  boolArray ,
  optionString ,
  nullableString ,
  optionBool ,
  optionUuid ,
  uuid ,
  $$null ,
}
/* optionString Not a pure module */
