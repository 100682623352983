

import * as Schedule_API from "../api/Schedule_API.mjs";
import * as Shared_Lib_IO from "../../../../../shared/lib/Shared_Lib_IO.mjs";

function fetchShifts(wire, state) {
  var init = state.scheduleTimelines;
  var state_scheduledJobs = state.scheduledJobs;
  var state_unscheduledJobs = state.unscheduledJobs;
  var state_settings = state.settings;
  var state_scheduleTimelines = {
    loading: true,
    workersTimelines: init.workersTimelines,
    teamTimelines: init.teamTimelines
  };
  var state$1 = {
    scheduledJobs: state_scheduledJobs,
    unscheduledJobs: state_unscheduledJobs,
    settings: state_settings,
    scheduleTimelines: state_scheduleTimelines
  };
  return {
          TAG: "UpdateWithIO",
          _0: state$1,
          _1: Shared_Lib_IO.bimap(Schedule_API.ScheduleTimeline.$$fetch([
                    wire,
                    state_settings.filter
                  ]), (function (r) {
                  if (r === undefined) {
                    return {
                            TAG: "Jobs",
                            _0: "NoOp"
                          };
                  }
                  if (r.TAG !== "Ok") {
                    return {
                            TAG: "Jobs",
                            _0: "NoOp"
                          };
                  }
                  var match = r._0;
                  return {
                          TAG: "Jobs",
                          _0: {
                            TAG: "ScheduleTimelinesFetched",
                            _0: match.workersTimelines,
                            _1: match.teamTimelines
                          }
                        };
                }), (function (param) {
                  return {
                          TAG: "Jobs",
                          _0: "NoOp"
                        };
                }))
        };
}

export {
  fetchShifts ,
}
/* Schedule_API Not a pure module */
