

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as Modal from "../modal/Modal.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Errors from "../../Errors.mjs";
import * as Select from "../inputs/Select.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as UI_Form from "../../ui/UI_Form.mjs";
import * as UI_Icon from "../../ui/UI_Icon.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as UI_Label from "../../ui/UI_Label.mjs";
import * as FakeInput from "../inputs/FakeInput.mjs";
import * as FormInput from "../inputs/FormInput.mjs";
import * as UI_Button from "../../ui/UI_Button.mjs";
import * as AppContext from "../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Settings_API from "./Settings_API.mjs";
import * as MessengerTypes from "../messenger/MessengerTypes.mjs";
import * as Settings_OwnerModuleCss from "/home/runner/work/planado/planado/client/rescript/components/settings/Settings_Owner.module.css";

var noUsers$p = Utils.Translations.t("js.settings.placeholders.create_more_users");

var ownerPlaceholder$p = Utils.Translations.t("js.settings.placeholders.owner");

var sectionTitle$p = Utils.Translations.tr("js.settings.owner_settings");

var label$p = Utils.Translations.tr("js.settings.labels.owner");

var transfer$p = Utils.Translations.tr("js.settings.buttons.transfer");

var popoverTitle$p = Utils.Translations.tr("js.settings.owner_modal.title");

var transferInfo$p = Utils.Translations.txr("js.settings.owner_modal.info");

var password$p = Utils.Translations.t("js.settings.labels.password");

var cancel$p = Utils.Translations.tr("js.settings.buttons.cancel");

var transferred$p = Utils.Translations.t("js.settings.messages.owner_transferred");

var styles = Settings_OwnerModuleCss;

function Settings_Owner$Popover(Props) {
  var users = Props.users;
  var userUuid = Props.userUuid;
  var onClose = Props.onClose;
  var errors = Props.errors;
  var setErrors = Props.setErrors;
  var wire = Props.wire;
  var match = React.useState(function () {
        return "";
      });
  var setPassword = match[1];
  var password = match[0];
  var transferOwner = function () {
    Backend.$$finally(Settings_API.TransferOwner.patch(wire, userUuid, password), (function (r) {
            if (r.TAG === "Ok") {
              wire.subscriptions.messenger.trigger({
                    TAG: "Show",
                    _0: MessengerTypes.Message.make(undefined, undefined, {
                          TAG: "Text",
                          _0: transferred$p(wire.ctx)
                        })
                  });
              wire.reloadContext();
              return wire.navigate(undefined, {
                          NAME: "Job",
                          VAL: "Index"
                        });
            }
            var e = r._0;
            if (typeof e !== "object") {
              console.log(e);
              return ;
            }
            if (e.TAG === "HTTPError") {
              var match = e._0;
              var tmp = match[0];
              if (typeof tmp !== "object") {
                if (tmp === "UnprocessableEntity") {
                  var text = match[1];
                  return setErrors(function (current) {
                              return {
                                      owner: Errors.getArray("owner", Errors.parse(text)),
                                      businessHours: current.businessHours,
                                      language: current.language,
                                      timezone: current.timezone,
                                      country: current.country,
                                      dateFormat: current.dateFormat,
                                      measurementSystem: current.measurementSystem,
                                      thousandsSeparator: current.thousandsSeparator,
                                      decimalMark: current.decimalMark,
                                      currency: current.currency,
                                      currencyFullFormat: current.currencyFullFormat,
                                      smsNearest: current.smsNearest,
                                      smsEnRoute: current.smsEnRoute,
                                      smsFinished: current.smsFinished
                                    };
                            });
                }
                console.log(e);
                return ;
              } else {
                console.log(e);
                return ;
              }
            } else {
              console.log(e);
              return ;
            }
          }));
  };
  var match$1 = RCore.$$Array.getBy(users, (function (u) {
          return Uuid.equal(u.uuid, userUuid);
        }));
  if (match$1 !== undefined) {
    return React.createElement("div", {
                className: styles.popoverWrapper
              }, React.createElement("div", {
                    className: styles.popoverHeader
                  }, React.createElement("div", {
                        className: styles.highlighted
                      }, popoverTitle$p(wire.ctx)), React.createElement(UI_Button.make, {
                        flavor: "close",
                        className: styles.popoverCloseIcon,
                        onClick: onClose,
                        children: React.createElement(UI_Icon.make, {
                              icon: "times"
                            })
                      })), React.createElement("div", {
                    className: styles.popoverBody
                  }, React.createElement(UI_Form.Group.make, {
                        className: styles.highlighted,
                        children: transferInfo$p({
                              name: match$1.name
                            }, wire.ctx)
                      }), React.createElement(FormInput.make, {
                        _type: "password",
                        value: password,
                        onValueChange: (function (value) {
                            setPassword(function (param) {
                                  return value;
                                });
                          }),
                        labelText: password$p(wire.ctx),
                        errors: errors.owner
                      })), React.createElement("div", {
                    className: styles.modalFooter
                  }, React.createElement(UI_Button.make, {
                        flavor: "outline",
                        className: styles.popoverCancelButton,
                        onClick: onClose,
                        children: cancel$p(wire.ctx)
                      }), React.createElement(UI_Button.make, {
                        flavor: "danger",
                        className: styles.confirmTransfer,
                        disabled: password === "",
                        onClick: (function (param) {
                            transferOwner();
                          }),
                        children: transfer$p(wire.ctx)
                      })));
  } else {
    return null;
  }
}

function Settings_Owner(Props) {
  var currentUserUuid = Props.currentUserUuid;
  var users = Props.users;
  var errors = Props.errors;
  var setErrors = Props.setErrors;
  var wire = AppContext.useWire();
  var match = React.useState(function () {
        return false;
      });
  var setShowPopover = match[1];
  var match$1 = React.useState(function () {
        
      });
  var setUserUuid = match$1[1];
  var userUuid = match$1[0];
  var onClose = function (param) {
    setShowPopover(function (param) {
          return false;
        });
    setErrors(function (current) {
          return {
                  owner: [],
                  businessHours: current.businessHours,
                  language: current.language,
                  timezone: current.timezone,
                  country: current.country,
                  dateFormat: current.dateFormat,
                  measurementSystem: current.measurementSystem,
                  thousandsSeparator: current.thousandsSeparator,
                  decimalMark: current.decimalMark,
                  currency: current.currency,
                  currencyFullFormat: current.currencyFullFormat,
                  smsNearest: current.smsNearest,
                  smsEnRoute: current.smsEnRoute,
                  smsFinished: current.smsFinished
                };
        });
  };
  return React.createElement(UI_Form.Section.make, {
              children: null
            }, React.createElement(UI_Form.SectionTitle.make, {
                  children: sectionTitle$p(wire.ctx)
                }), React.createElement(UI_Form.Container.make, {
                  children: React.createElement(UI_Form.Group.make, {
                        className: styles.transfer,
                        children: null
                      }, React.createElement(UI_Label.make, {
                            children: label$p(wire.ctx)
                          }), React.createElement("div", undefined, Caml_obj.equal(users, []) ? React.createElement(FakeInput.make, {
                                  dangerouslySetInnerHTML: {
                                    __html: noUsers$p(wire.ctx)
                                  }
                                }) : React.createElement(Select.make, {
                                  placeholder: ownerPlaceholder$p(wire.ctx),
                                  onChange: (function (value) {
                                      setUserUuid(function (param) {
                                            return RCore.$$Option.map(value === null ? undefined : Caml_option.some(value), (function (prim) {
                                                          return prim;
                                                        }));
                                          });
                                    }),
                                  value: Js_null.bind(Js_null.fromOption(userUuid), (function (prim) {
                                          return prim;
                                        })),
                                  clearable: false,
                                  options: users.map(function (u) {
                                        return {
                                                value: u.uuid,
                                                label: u.name
                                              };
                                      })
                                })), React.createElement(UI_Button.make, {
                            flavor: "danger",
                            disabled: Caml_option.some(currentUserUuid) === userUuid || userUuid === undefined || Caml_obj.equal(users, []),
                            onClick: (function (e) {
                                e.preventDefault();
                                setShowPopover(function (param) {
                                      return true;
                                    });
                              }),
                            children: transfer$p(wire.ctx)
                          }))
                }), userUuid !== undefined ? React.createElement(Modal.make, {
                    wire: wire,
                    onClose: onClose,
                    show: match[0],
                    children: React.createElement(Settings_Owner$Popover, {
                          users: users,
                          userUuid: Caml_option.valFromOption(userUuid),
                          onClose: onClose,
                          errors: errors,
                          setErrors: setErrors,
                          wire: wire
                        })
                  }) : null);
}

var make = Settings_Owner;

export {
  make ,
}
/* noUsers' Not a pure module */
