

import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TerritoriesSelect from "../../../inputs/TerritoriesSelect.mjs";
import * as MapJobsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/map/jobs/MapJobs.module.css";

var styles = MapJobsModuleCss;

var territory$p = Utils.Translations.t("js.map.job_filter.territories");

function MapJobsFilterByTerritory(Props) {
  var wire = Props.wire;
  var territory = Props.territory;
  var territories = Props.territories;
  var onChange = Props.onChange;
  return React.createElement("div", {
              className: styles.territoriesSelect
            }, React.createElement(TerritoriesSelect.make, {
                  id: "map-filter-territories",
                  inputName: "input-map-territory",
                  selectedTerritoryUuid: Js_null.fromOption(territory),
                  onChange: (function (x) {
                      onChange(x === null ? undefined : Caml_option.some(x));
                    }),
                  availableTerritories: territories.map(function (t) {
                        return {
                                uuid: t.uuid,
                                name: t.name
                              };
                      }),
                  placeholder: territory$p(wire.ctx),
                  disabled: false,
                  compactPlaceholder: true
                }));
}

var make = MapJobsFilterByTerritory;

export {
  make ,
}
/* styles Not a pure module */
