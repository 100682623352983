

import * as Schedule_Types_Timeline from "../../../../lib/types/Schedule_Types_Timeline.mjs";

function initial(sizes) {
  return {
          sizes: sizes,
          timelineSubscriptions: Schedule_Types_Timeline.TimelineSubscriptions.make(),
          assignJobBlocks: []
        };
}

var State = {
  initial: initial
};

function sizes(state) {
  return state.sizes;
}

function timelineSubscriptions(state) {
  return state.timelineSubscriptions;
}

function assignJobBlocks(state) {
  return state.assignJobBlocks;
}

var StateSelector = {
  sizes: sizes,
  timelineSubscriptions: timelineSubscriptions,
  assignJobBlocks: assignJobBlocks
};

var Action = {};

var Reducer = {};

export {
  State ,
  StateSelector ,
  Action ,
  Reducer ,
}
/* Schedule_Types_Timeline Not a pure module */
