

import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Assignee_Types from "../../../../../../types/Assignee_Types.mjs";
import * as WeekTimeline_Constants from "../../lib/WeekTimeline_Constants.mjs";
import * as Schedule_Types_Timeline from "../../../../lib/types/Schedule_Types_Timeline.mjs";

function snapToGrid(wire, filter, activeDay, param) {
  var y = param[1];
  var x = param[0];
  if (activeDay !== undefined) {
    var activeDayAssigneeUuid = Assignee_Types.Assignee.uuid(activeDay.bar);
    var param$1 = [
      x,
      y
    ];
    var y$1 = param$1[1];
    var x$1 = param$1[0];
    var assignees = Schedule_Types_Timeline.Bars.Assignees.filter(wire, filter);
    var coordinate = {
      contents: undefined
    };
    var prevBar = {
      contents: Units.Px.zero
    };
    assignees.forEach(function (assignee) {
          if (coordinate.contents !== undefined) {
            return ;
          }
          var isActiveDay = Caml_obj.equal(activeDayAssigneeUuid, Assignee_Types.Assignee.uuid(assignee));
          if (isActiveDay) {
            var y1 = Units.Px.minus(Units.Px.plus(Units.Px.plus(prevBar.contents, WeekTimeline_Constants.rowHeight), WeekTimeline_Constants.activeDayOffset), WeekTimeline_Constants.halfOfBarGapHeight);
            var y2 = Units.Px.plus(Units.Px.plus(Units.Px.plus(prevBar.contents, WeekTimeline_Constants.rowHeight), WeekTimeline_Constants.activeDayOffset), WeekTimeline_Constants.halfOfBarGapHeight);
            if (Units.Px.equalOrMoreThan(y$1, y1) && Units.Px.equalOrLessThan(y$1, y2)) {
              coordinate.contents = [
                x$1,
                Units.Px.plus(Units.Px.plus(prevBar.contents, WeekTimeline_Constants.rowHeight), WeekTimeline_Constants.activeDayOffset)
              ];
            }
            
          }
          var rowHeight = Units.Px.plus(WeekTimeline_Constants.rowHeight, WeekTimeline_Constants.barGapHeight);
          if (coordinate.contents === undefined) {
            var y1$1 = Units.Px.minus(prevBar.contents, WeekTimeline_Constants.halfOfBarGapHeight);
            var y2$1 = Units.Px.plus(prevBar.contents, WeekTimeline_Constants.halfOfBarGapHeight);
            if (Units.Px.equalOrMoreThan(y$1, y1$1) && Units.Px.equalOrLessThan(y$1, y2$1)) {
              coordinate.contents = [
                x$1,
                prevBar.contents
              ];
            }
            
          }
          if (isActiveDay) {
            prevBar.contents = Units.Px.plus(Units.Px.plus(prevBar.contents, rowHeight), WeekTimeline_Constants.activeDayHeight);
          } else {
            prevBar.contents = Units.Px.plus(prevBar.contents, rowHeight);
          }
        });
    return RCore.$$Option.getOr(coordinate.contents, [
                x$1,
                y$1
              ]);
  }
  var rowHeight = Units.Px.plus(WeekTimeline_Constants.rowHeight, WeekTimeline_Constants.barGapHeight);
  var bar = Units.Px.multiply(Units.Px.round(Units.Px.divide(y, rowHeight)), rowHeight);
  var y1 = Units.Px.minus(bar, Units.Px.divideByInt(WeekTimeline_Constants.barGapHeight, 2));
  var y2 = Units.Px.plus(bar, Units.Px.divideByInt(WeekTimeline_Constants.barGapHeight, 2));
  var maxY = Units.Px.multiplyWithInt(Units.Px.plus(rowHeight, WeekTimeline_Constants.barGapHeight), Schedule_Types_Timeline.Bars.Assignees.length(wire, filter));
  if (Units.Px.equalOrMoreThan(y, y1) && Units.Px.equalOrLessThan(y, y2) && Units.Px.lessThan(y, maxY)) {
    return [
            x,
            bar
          ];
  } else {
    return [
            x,
            y
          ];
  }
}

export {
  snapToGrid ,
}
/* Assignee_Types Not a pure module */
