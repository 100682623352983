

import * as Fun from "../../Fun.mjs";
import * as RCore from "../../RCore.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Liquid_Variable from "./Liquid_Variable.mjs";
import * as Liquid_Tokenizer from "./Liquid_Tokenizer.mjs";
import * as Shared_Lib_String from "../../../shared/lib/Shared_Lib_String.mjs";

var whitespaceControl = "-";

var newLine = new RegExp("\r\n|\r|\n");

var contentOfVariable = new RegExp("^{{" + whitespaceControl + "?(.*?)" + whitespaceControl + "?}}$");

function parseVariable(token) {
  var matches = Shared_Lib_String.matchh(token, contentOfVariable);
  if (matches === undefined) {
    return ;
  }
  var match = matches[1];
  if (match !== undefined) {
    return Liquid_Variable.make(match);
  }
  
}

function parseTokens(tokenizer) {
  var tokens = Liquid_Tokenizer.tokens(tokenizer);
  var nodes = {
    contents: []
  };
  tokens.forEach(function (originToken) {
        var token = originToken.trim();
        var parsedToken;
        if (token.startsWith("{{")) {
          var variable = parseVariable(token);
          parsedToken = variable !== undefined ? ({
                TAG: "Variable",
                _0: variable
              }) : ({
                TAG: "String",
                _0: originToken
              });
        } else {
          parsedToken = {
            TAG: "String",
            _0: originToken
          };
        }
        nodes.contents = Belt_Array.concatMany([
              nodes.contents,
              [parsedToken]
            ]);
      });
  return nodes.contents;
}

function compactTokens(tokens) {
  return RCore.$$Array.reduceWithIndex(tokens, [], (function (compactTokens, token, index) {
                var match = tokens[index - 1 | 0];
                if (match !== undefined && typeof match === "object" && match.TAG === "String" && typeof token === "object" && token.TAG === "String") {
                  compactTokens[index - 1 | 0] = {
                    TAG: "String",
                    _0: match._0 + token._0
                  };
                  return compactTokens;
                }
                return Belt_Array.concatMany([
                            compactTokens,
                            [token]
                          ]);
              }));
}

function parse(source) {
  var lines = RCore.$$Array.filterMap(source.split(newLine), Fun.id);
  var tokens = lines.map(function (l, i) {
          return [
                  i,
                  l
                ];
        }).flatMap(function (param) {
        var tokens = compactTokens(parseTokens(Liquid_Tokenizer.make(param[1])));
        if (param[0] === 0) {
          return tokens;
        } else {
          return Belt_Array.concatMany([
                      ["NewLine"],
                      tokens
                    ]);
        }
      });
  return {
          TAG: "Ok",
          _0: tokens
        };
}

export {
  parse ,
}
/* newLine Not a pure module */
