

import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as ForbiddenModuleCss from "/home/runner/work/planado/planado/client/rescript/components/errors/Forbidden.module.css";

var styles = ForbiddenModuleCss;

var title$p = Utils.Translations.tr("js.errors.oops");

var text$p = Utils.Translations.tr("js.errors.forbidden");

function Forbidden(Props) {
  var wire = Props.wire;
  var ctx = wire.ctx;
  return React.createElement("div", {
              className: styles.wrapper
            }, React.createElement("div", {
                  className: styles.code
                }, "403"), React.createElement("div", {
                  className: styles.oops
                }, title$p(ctx)), React.createElement("div", {
                  className: styles.separator
                }), React.createElement("div", {
                  className: styles.text
                }, text$p(ctx)));
}

var make = Forbidden;

export {
  make ,
}
/* styles Not a pure module */
