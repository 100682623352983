

import * as Schedule_JobsStateReducerHelper from "./Schedule_JobsStateReducerHelper.mjs";

function reducer(wire, state, action) {
  if (typeof action !== "object") {
    switch (action) {
      case "FetchUnscheduledJobs" :
          return Schedule_JobsStateReducerHelper.fetchUnscheduledJobs(wire, state);
      case "FetchNextUnscheduledJobs" :
          return Schedule_JobsStateReducerHelper.fetchNextUnscheduledJobs(wire, state);
      case "NoOp" :
          return "NoUpdate";
      
    }
  } else {
    switch (action.TAG) {
      case "FetchScheduledJobs" :
          return Schedule_JobsStateReducerHelper.fetchScheduledJobs(wire, state, action._0);
      case "ScheduledJobsFetched" :
          return Schedule_JobsStateReducerHelper.setScheduledJobs(action._0, state, action._1, action._2);
      case "RefineScheduledJobs" :
          var match = state.settings.jobDuration;
          if (match === "Scheduled" || state.scheduledJobs.loading) {
            return "NoUpdate";
          } else {
            return {
                    TAG: "Update",
                    _0: Schedule_JobsStateReducerHelper.refineJobIntervals(action._0, state)
                  };
          }
      case "UnscheduledJobsFetched" :
          return Schedule_JobsStateReducerHelper.setUnscheduledJobs(state, action._0, action._1, action._2);
      case "NextUnscheduledJobsFetched" :
          return Schedule_JobsStateReducerHelper.setNextUnscheduledJobs(state, action._0, action._1, action._2);
      case "ScheduleTimelinesFetched" :
          return Schedule_JobsStateReducerHelper.setScheduleTimelines(state, action._0, action._1);
      case "JobScheduled" :
          return Schedule_JobsStateReducerHelper.scheduleJob(wire, state, action._0, action._1);
      case "JobUnscheduled" :
          return Schedule_JobsStateReducerHelper.unscheduleJob(wire, state, action._0);
      case "JobUnassigned" :
          return Schedule_JobsStateReducerHelper.unassignJob(wire, state, action._0);
      case "JobSubmitted" :
          return Schedule_JobsStateReducerHelper.submitJob(wire, state, action._0, action._1, action._2);
      case "JobRemoved" :
          return Schedule_JobsStateReducerHelper.removeJob(state, action._0);
      case "JobFetched" :
          return Schedule_JobsStateReducerHelper.setJob(wire, state, action._0);
      
    }
  }
}

export {
  reducer ,
}
/* Schedule_JobsStateReducerHelper Not a pure module */
