


function every(msec, f) {
  return setInterval(f, msec);
}

function cancel(prim) {
  clearInterval(prim);
}

var Interval = {
  every: every,
  cancel: cancel
};

function defer(msec, f) {
  return setTimeout(f, msec);
}

function nextTick(f) {
  return setTimeout(f, 0);
}

function cancel$1(prim) {
  clearTimeout(prim);
}

var Timeout = {
  defer: defer,
  nextTick: nextTick,
  cancel: cancel$1
};

var start = (() => { debugger });

var Debugger = {
  start: start
};

export {
  Interval ,
  Timeout ,
  Debugger ,
}
/* No side effect */
