import React from 'react'
import Engine from '../engines/yandex'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import * as styles from '../styles.module.css'

class YandexViewport extends React.Component {
  constructor(props) {
    super(props)

    const { ctx, onJobClick, onWorkersJobClick, onBoundsChange } = props

    this.engine = new Engine({
      ctx,
      onJobClick,
      onWorkersJobClick,
      onBoundsChange
    })
  }

  componentDidMount() {
    this.engine.start(this.viewport, this.props.channel)
    this.props.onEngineStart(this.engine)
  }

  render() {
    return (
      <div
        className={`${styles.viewport} yandex-viewport`}
        ref={v => {
          this.viewport = v
        }}
      >
        <div className={styles.mapIcon}>
          <i className="fa fa-map-o" />
        </div>
      </div>
    )
  }
}

export default withContext(YandexViewport)
