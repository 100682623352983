

import * as RCore from "../../../libraries/RCore.mjs";
import * as Route from "../../../types/Route.mjs";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function toString(target) {
  if (target === "parent") {
    return "_parent";
  } else if (target === "top") {
    return "_top";
  } else if (target === "blank") {
    return "_blank";
  } else {
    return "_self";
  }
}

function Link(Props) {
  var param = Props.wire;
  var $staropt$star = Props.route;
  var $staropt$star$1 = Props.disabled;
  var modals = Props.modals;
  var $staropt$star$2 = Props.className;
  var target = Props.target;
  var children = Props.children;
  var navigate = param.navigate;
  var route = $staropt$star !== undefined ? $staropt$star : param.route.path;
  var disabled = $staropt$star$1 !== undefined ? $staropt$star$1 : false;
  var className = $staropt$star$2 !== undefined ? $staropt$star$2 : "";
  var onClick = function ($$event) {
    var meta = $$event.metaKey;
    $$event.stopPropagation();
    if (!meta && RCore.$$Option.isNone(target)) {
      $$event.preventDefault();
      if (!disabled) {
        return navigate(modals, route);
      } else {
        return ;
      }
    }
    
  };
  var target$1 = target !== undefined ? toString(target) : "";
  var href = modals !== undefined ? Route.pagePath(modals, route) : Route.pagePath(undefined, route);
  return React.createElement("a", {
              className: className,
              href: href,
              target: target$1,
              onClick: onClick
            }, children);
}

function $$default(param) {
  var search = RCore.$$Option.getOr(Caml_option.undefined_to_opt(param.search), "");
  var match = Route.parseUrl(param.pathname, search);
  var className = RCore.$$Option.getOr(Caml_option.undefined_to_opt(param.className), "");
  var target = Caml_option.undefined_to_opt(param.target);
  return Link({
              wire: param.wire,
              route: match.path,
              disabled: param.disabled,
              modals: match.modals,
              className: className,
              target: target,
              children: param.children
            });
}

var Target = {};

var make = Link;

export {
  Target ,
  make ,
  $$default as default,
}
/* Route Not a pure module */
