

import * as Caml_option from "rescript/lib/es6/caml_option.js";

function length(prim) {
  return prim.length;
}

function Touch_identifier(prim) {
  return prim["identifier "];
}

function Touch_screenX(prim) {
  return prim.screenX;
}

function Touch_screenY(prim) {
  return prim.screenY;
}

function Touch_clientX(prim) {
  return prim.clientX;
}

function Touch_clientY(prim) {
  return prim.clientY;
}

function Touch_pageX(prim) {
  return prim.pageX;
}

function Touch_pageY(prim) {
  return prim.pageY;
}

function Touch_target(prim) {
  return prim.target;
}

var $$Touch = {
  identifier: Touch_identifier,
  screenX: Touch_screenX,
  screenY: Touch_screenY,
  clientX: Touch_clientX,
  clientY: Touch_clientY,
  pageX: Touch_pageX,
  pageY: Touch_pageY,
  target: Touch_target
};

function item(prim0, prim1) {
  return Caml_option.nullable_to_opt(prim0.item(prim1));
}

export {
  length ,
  $$Touch ,
  item ,
}
/* No side effect */
