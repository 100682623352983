

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Hooks from "../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Select from "../../../components/inputs/Select.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as UI_Form from "../../../ui/UI_Form.mjs";
import * as UI_Icon from "../../../ui/UI_Icon.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../components/inputs/Checkbox.mjs";
import * as Optional from "../../../components/common/Optional.mjs";
import * as UI_Label from "../../../ui/UI_Label.mjs";
import * as UI_Button from "../../../ui/UI_Button.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FieldErrors from "../../../components/common/FieldError/FieldErrors.mjs";
import * as DropDownMenu from "../../../components/common/DropDownMenu/DropDownMenu.mjs";
import * as Backend_Errors from "../../../libraries/backend/Backend_Errors.mjs";
import * as MessengerTypes from "../../../components/messenger/MessengerTypes.mjs";
import * as Pages_User_Custom_Fields_API from "./Pages_User_Custom_Fields_API.mjs";
import * as Pages_User_Custom_FieldsModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/custom_fields/users/Pages_User_Custom_Fields.module.css";

var create$p = Utils.Translations.tr("js.custom_fields.index.create_field");

var noFields$p = Utils.Translations.tr("js.custom_fields.index.no_fields");

var checkbox$p = Utils.Translations.t("js.custom_fields.index.options.checkbox");

var currency$p = Utils.Translations.t("js.custom_fields.index.options.currency");

var decimal$p = Utils.Translations.t("js.custom_fields.index.options.decimal");

var dictionary$p = Utils.Translations.t("js.custom_fields.index.options.dictionary");

var file$p = Utils.Translations.t("js.custom_fields.index.options.file");

var image$p = Utils.Translations.t("js.custom_fields.index.options.image");

var input$p = Utils.Translations.t("js.custom_fields.index.options.input");

var integer$p = Utils.Translations.t("js.custom_fields.index.options.integer");

var label$p = Utils.Translations.tr("js.custom_fields.index.label");

var dictionaryInputLabel$p = Utils.Translations.tr("js.custom_fields.index.dictionary");

var useCurrencyFractionalUnit$p = Utils.Translations.t("js.custom_fields.index.use_currency_fractional_unit");

var save$p = Utils.Translations.tr("common.save");

var updated$p = Utils.Translations.t("js.custom_fields.index.updated");

var styles = Pages_User_Custom_FieldsModuleCss;

function dropdownOptions(ctx) {
  return [
          {
            name: checkbox$p(ctx),
            dataType: "Boolean",
            fieldType: "Checkbox"
          },
          {
            name: decimal$p(ctx),
            dataType: "Decimal",
            fieldType: "Input"
          },
          {
            name: integer$p(ctx),
            dataType: "Integer",
            fieldType: "Input"
          },
          {
            name: currency$p(ctx),
            dataType: "Currency",
            fieldType: "Currency"
          },
          {
            name: input$p(ctx),
            dataType: "String",
            fieldType: "Input"
          },
          {
            name: file$p(ctx),
            dataType: "Attachment",
            fieldType: "File"
          },
          {
            name: image$p(ctx),
            dataType: "Attachment",
            fieldType: "Image"
          },
          {
            name: dictionary$p(ctx),
            dataType: "Dictionary",
            fieldType: "Dictionary"
          }
        ];
}

function fetchData(wire, setInitialFields, setFields, setDictionaries) {
  Backend.$$finally(Backend.decode(Pages_User_Custom_Fields_API.Index.get(wire), Pages_User_Custom_Fields_API.Index.$$Response.decode), (function (result) {
          if (result.TAG === "Ok") {
            var match = result._0;
            var dictionaries = match.dictionaries;
            var customFields = match.customFields;
            setInitialFields(function (param) {
                  return customFields;
                });
            setFields(function (param) {
                  return customFields;
                });
            return setDictionaries(function (param) {
                        return dictionaries;
                      });
          }
          console.log(result._0);
        }));
}

function Pages_User_Custom_Fields$TypeIcon(Props) {
  var dataType = Props.dataType;
  var fieldType = Props.fieldType;
  switch (dataType) {
    case "Integer" :
        if (fieldType === "Input") {
          return React.createElement("div", {
                      className: styles.numberIcon
                    }, "123");
        } else {
          return null;
        }
    case "Decimal" :
        if (fieldType === "Input") {
          return React.createElement("div", {
                      className: styles.numberIcon
                    }, "3.14");
        } else {
          return null;
        }
    case "Attachment" :
        switch (fieldType) {
          case "File" :
              return React.createElement(UI_Icon.make, {
                          icon: "paperclip"
                        });
          case "Image" :
              return React.createElement(UI_Icon.make, {
                          icon: "camera"
                        });
          default:
            return null;
        }
    case "Boolean" :
        if (fieldType === "Checkbox") {
          return React.createElement("div", {
                      className: styles.checkboxIconWrapper
                    }, React.createElement(UI_Icon.make, {
                          className: styles.checkboxIconYes,
                          icon: "square-check"
                        }), React.createElement(UI_Icon.make, {
                          className: styles.checkboxIconNo,
                          icon: "square-xmark"
                        }));
        } else {
          return null;
        }
    case "Currency" :
        if (fieldType === "Currency") {
          return React.createElement(UI_Icon.make, {
                      icon: "money-bill"
                    });
        } else {
          return null;
        }
    case "String" :
        if (fieldType === "Input") {
          return React.createElement(UI_Icon.make, {
                      icon: "font"
                    });
        } else {
          return null;
        }
    case "Dictionary" :
        if (fieldType === "Dictionary") {
          return React.createElement(UI_Icon.make, {
                      icon: "square-caret-down"
                    });
        } else {
          return null;
        }
    
  }
}

var TypeIcon = {
  make: Pages_User_Custom_Fields$TypeIcon
};

function Pages_User_Custom_Fields$Field(Props) {
  var field = Props.field;
  var index = Props.index;
  var disabled = Props.disabled;
  var setFields = Props.setFields;
  var dictionaries = Props.dictionaries;
  var errors = Props.errors;
  var ctx = Props.ctx;
  var nameInputId = Hooks.useId(undefined);
  var dictionaryPickerId = Hooks.useId(undefined);
  var onChange = function ($$event) {
    $$event.preventDefault();
    var newValue = $$event.target.value;
    setFields(function (current) {
          return current.map(function (f) {
                      if (Uuid.equal(f.uuid, field.uuid)) {
                        return {
                                uuid: f.uuid,
                                name: newValue,
                                dataType: f.dataType,
                                fieldType: f.fieldType,
                                dictionaryUuid: f.dictionaryUuid,
                                currency: f.currency,
                                useCurrencyFractionalUnit: f.useCurrencyFractionalUnit
                              };
                      } else {
                        return f;
                      }
                    });
        });
  };
  var onDelete = function ($$event) {
    $$event.preventDefault();
    setFields(function (current) {
          return RCore.$$Array.keep(current, (function (f) {
                        return !Uuid.equal(f.uuid, field.uuid);
                      }));
        });
  };
  var toggleFractionalCurrencyUnit = function (value) {
    setFields(function (current) {
          return current.map(function (f) {
                      if (Uuid.equal(f.uuid, field.uuid)) {
                        return {
                                uuid: f.uuid,
                                name: f.name,
                                dataType: f.dataType,
                                fieldType: f.fieldType,
                                dictionaryUuid: f.dictionaryUuid,
                                currency: f.currency,
                                useCurrencyFractionalUnit: value
                              };
                      } else {
                        return f;
                      }
                    });
        });
  };
  var isDictionary = field.dataType === "Dictionary";
  return React.createElement("div", {
              className: styles.fieldWrapper
            }, React.createElement("div", {
                  className: styles.fieldRow
                }, React.createElement("div", {
                      className: styles.fieldPrefixTypeIcon
                    }, React.createElement(Pages_User_Custom_Fields$TypeIcon, {
                          dataType: field.dataType,
                          fieldType: field.fieldType
                        })), React.createElement(UI_Form.GroupContainer.make, {
                      className: styles.widthFull,
                      children: null
                    }, React.createElement(UI_Form.Group.make, {
                          className: isDictionary ? "" : styles.widthFull,
                          width: isDictionary ? "half" : "full",
                          children: null
                        }, React.createElement(UI_Label.make, {
                              htmlFor: nameInputId,
                              className: styles.label,
                              children: label$p(ctx)
                            }), React.createElement(UI_Form.Control.make, {
                              children: React.createElement("input", {
                                    id: nameInputId,
                                    disabled: disabled,
                                    value: field.name,
                                    onBlur: (function (evt) {
                                        evt.preventDefault();
                                      }),
                                    onChange: onChange
                                  })
                            }), React.createElement(FieldErrors.make, {
                              errors: RCore.$$Option.mapOr(Backend_Errors.getMany(errors, ["customFields." + index.toString() + ".name"]), [], (function (s) {
                                      return s.map(Utils.capitalize);
                                    }))
                            })), React.createElement(Optional.make, {
                          show: isDictionary,
                          children: React.createElement(UI_Form.Group.make, {
                                width: "half",
                                children: null
                              }, React.createElement(UI_Label.make, {
                                    htmlFor: dictionaryPickerId,
                                    className: styles.label,
                                    children: dictionaryInputLabel$p(ctx)
                                  }), React.createElement(Select.make, {
                                    placeholder: "",
                                    id: dictionaryPickerId,
                                    onChange: (function (value) {
                                        setFields(function (current) {
                                              return current.map(function (f) {
                                                          if (Uuid.equal(f.uuid, field.uuid)) {
                                                            return {
                                                                    uuid: f.uuid,
                                                                    name: f.name,
                                                                    dataType: f.dataType,
                                                                    fieldType: f.fieldType,
                                                                    dictionaryUuid: RCore.$$Option.map(value === null ? undefined : Caml_option.some(value), (function (prim) {
                                                                            return prim;
                                                                          })),
                                                                    currency: f.currency,
                                                                    useCurrencyFractionalUnit: f.useCurrencyFractionalUnit
                                                                  };
                                                          } else {
                                                            return f;
                                                          }
                                                        });
                                            });
                                      }),
                                    value: Js_null.fromOption(RCore.$$Option.map(field.dictionaryUuid, (function (prim) {
                                                return prim;
                                              }))),
                                    disabled: disabled,
                                    options: dictionaries.map(function (d) {
                                          return {
                                                  value: d.uuid,
                                                  label: d.name
                                                };
                                        })
                                  }), React.createElement(FieldErrors.make, {
                                    errors: RCore.$$Option.mapOr(Backend_Errors.getMany(errors, ["customFields." + index.toString() + ".dictionaryUuid"]), [], (function (s) {
                                            return s.map(Utils.capitalize);
                                          }))
                                  }))
                        })), React.createElement(UI_Button.make, {
                      flavor: "outline",
                      className: styles.deleteButton,
                      disabled: disabled,
                      onClick: onDelete,
                      children: React.createElement(UI_Icon.make, {
                            className: styles.deleteButtonIcon,
                            icon: "trash-alt"
                          })
                    })), React.createElement(Optional.make, {
                  show: field.dataType === "Currency",
                  children: React.createElement("div", {
                        className: styles.fieldAdditionalSettingsRow
                      }, React.createElement("div", {
                            className: styles.useFractionalCheckbox
                          }, React.createElement(Checkbox.make, {
                                onToggle: toggleFractionalCurrencyUnit,
                                checked: RCore.$$Option.getOr(field.useCurrencyFractionalUnit, false),
                                label: useCurrencyFractionalUnit$p(ctx),
                                disabled: disabled
                              })))
                }));
}

function Pages_User_Custom_Fields$AddFieldDropdown(Props) {
  var pushField = Props.pushField;
  var disabled = Props.disabled;
  var ctx = Props.ctx;
  var dropDownClass = Props.dropDownClass;
  return React.createElement(DropDownMenu.make, {
              position: "left",
              button: (function (ref) {
                  return React.createElement(UI_Button.make, {
                              flavor: "outline",
                              className: styles.dropdownMenuButton,
                              forwardRef: ref,
                              disabled: disabled,
                              children: null
                            }, React.createElement("span", undefined, React.createElement(UI_Icon.make, {
                                      className: styles.dropdownPlusIcon,
                                      icon: "plus"
                                    }), create$p(ctx)), React.createElement(UI_Icon.make, {
                                  className: styles.dropdownCaretIcon,
                                  icon: "caret-down"
                                }));
                }),
              className: dropDownClass,
              listClassName: styles.dropdownList,
              disabled: disabled,
              children: dropdownOptions(ctx).map(function (o) {
                    return React.createElement("li", {
                                key: o.name,
                                className: styles.dropdownOption,
                                onClick: (function (_e) {
                                    pushField(o);
                                  })
                              }, React.createElement("div", {
                                    className: styles.dropdownOptionTypeIcon
                                  }, React.createElement(Pages_User_Custom_Fields$TypeIcon, {
                                        dataType: o.dataType,
                                        fieldType: o.fieldType
                                      })), React.createElement("div", {
                                    className: styles.optionText
                                  }, o.name));
                  })
            });
}

function Pages_User_Custom_Fields(Props) {
  var wire = AppContext.useWire();
  var messenger = wire.subscriptions.messenger;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return [];
      });
  var setInitialFields = match[1];
  var initialFields = match[0];
  var match$1 = React.useState(function () {
        return initialFields;
      });
  var setFields = match$1[1];
  var fields = match$1[0];
  var match$2 = React.useState(function () {
        return [];
      });
  var setDicts = match$2[1];
  var dicts = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setDisabled = match$3[1];
  var disabled = match$3[0];
  var match$4 = React.useState(function () {
        return Backend_Errors.empty;
      });
  var setErrors = match$4[1];
  var errors = match$4[0];
  React.useEffect((function () {
          fetchData(wire, setInitialFields, setFields, setDicts);
        }), []);
  var pushField = function (option) {
    var match = option.dataType === "Currency" ? [
        ctx.currency,
        false
      ] : [
        undefined,
        undefined
      ];
    var useCurrencyFractionalUnit = match[1];
    var currency = match[0];
    setFields(function (current) {
          return Belt_Array.concatMany([
                      current,
                      [{
                          uuid: Uuid.createV6(),
                          name: "",
                          dataType: option.dataType,
                          fieldType: option.fieldType,
                          dictionaryUuid: undefined,
                          currency: currency,
                          useCurrencyFractionalUnit: useCurrencyFractionalUnit
                        }]
                    ]);
        });
  };
  var onSubmit = function ($$event) {
    $$event.preventDefault();
    setDisabled(function (param) {
          return true;
        });
    setErrors(function (param) {
          return Backend_Errors.empty;
        });
    Backend.$$finally(Pages_User_Custom_Fields_API.Post.post(wire, fields), (function (r) {
            if (r.TAG === "Ok") {
              setInitialFields(function (param) {
                    return fields;
                  });
              return messenger.trigger({
                          TAG: "Show",
                          _0: MessengerTypes.Message.make(undefined, "Success", {
                                TAG: "Text",
                                _0: updated$p(ctx)
                              })
                        });
            }
            var e = r._0;
            if (typeof e !== "object") {
              console.log(e);
              return ;
            }
            if (e.TAG === "HTTPError") {
              var match = e._0;
              var tmp = match[0];
              if (typeof tmp !== "object") {
                if (tmp === "UnprocessableEntity") {
                  var text = match[1];
                  return setErrors(function (param) {
                              return Backend_Errors.make(JSON.parse(text));
                            });
                }
                console.log(e);
                return ;
              } else {
                console.log(e);
                return ;
              }
            } else {
              console.log(e);
              return ;
            }
          }));
    setDisabled(function (param) {
          return false;
        });
  };
  var hasFields = Caml_obj.notequal(fields, []);
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: styles.wrapper
                }, hasFields ? React.createElement("div", {
                        className: styles.fieldsWrapper
                      }, fields.map(function (field, index) {
                            return React.createElement(Pages_User_Custom_Fields$Field, {
                                        field: field,
                                        index: index,
                                        disabled: disabled,
                                        setFields: setFields,
                                        dictionaries: dicts,
                                        errors: errors,
                                        ctx: ctx,
                                        key: Uuid.toString(field.uuid)
                                      });
                          })) : React.createElement("div", {
                        className: styles.noFields
                      }, noFields$p(ctx)), React.createElement(Optional.make, {
                      show: fields.length < 100,
                      children: React.createElement(Pages_User_Custom_Fields$AddFieldDropdown, {
                            pushField: pushField,
                            disabled: disabled,
                            ctx: ctx,
                            dropDownClass: hasFields ? styles.dropdown : styles.dropdownNoFields
                          })
                    })), React.createElement(Optional.make, {
                  show: Caml_obj.notequal(fields, initialFields),
                  children: React.createElement("div", {
                        className: styles.footer
                      }, React.createElement(UI_Button.make, {
                            purpose: "primary",
                            className: styles.saveButton,
                            onClick: onSubmit,
                            children: save$p(ctx)
                          }))
                }));
}

var make = Pages_User_Custom_Fields;

export {
  TypeIcon ,
  make ,
}
/* create' Not a pure module */
