

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as TeamAPI from "./TeamAPI.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.mjs";
import * as TeamIndexItem from "./TeamIndexItem.mjs";
import * as TeamIndexHeader from "./TeamIndexHeader.mjs";

var name$p = Utils.Translations.tr("js.teams.index.table.name");

var foreman$p = Utils.Translations.tr("js.teams.index.table.foreman");

var workers$p = Utils.Translations.tr("js.teams.index.table.workers");

var description$p = Utils.Translations.tr("js.teams.index.table.description");

var noTeams$p = Utils.Translations.tr("js.teams.index.no_teams");

function TeamIndex(Props) {
  var wire = Props.wire;
  var match = React.useState(function () {
        
      });
  var navigate = wire.navigate;
  var ctx = wire.ctx;
  var setTeams = match[1];
  var teams = match[0];
  var openTeam = function (uuid) {
    return function ($$event) {
      $$event.preventDefault();
      navigate(undefined, {
            NAME: "Team",
            VAL: {
              NAME: "Edit",
              VAL: uuid
            }
          });
    };
  };
  var openNewTeam = function ($$event) {
    $$event.preventDefault();
    navigate(undefined, {
          NAME: "Team",
          VAL: "New"
        });
  };
  var listResponse = function (v) {
    var list = Core__Result.map(v, (function (v) {
            return {
                    TAG: "List",
                    _0: v
                  };
          }));
    if (list.TAG !== "Ok") {
      return ;
    }
    var teams = list._0._0.teams;
    setTeams(function (param) {
          return teams;
        });
  };
  React.useEffect((function () {
          Backend.$$finally(TeamAPI.Index.index(wire), listResponse);
        }), []);
  if (teams !== undefined) {
    return React.createElement("div", {
                id: "content-wrapper"
              }, React.createElement("div", {
                    className: "page-container narrow"
                  }, React.createElement(TeamIndexHeader.make, {
                        ctx: ctx,
                        openNewTeam: openNewTeam
                      }), React.createElement("div", {
                        className: "panel"
                      }, React.createElement("div", {
                            className: "panel-body"
                          }, teams.length > 0 ? React.createElement("table", {
                                  className: "table table-hover"
                                }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, name$p(ctx)), React.createElement("th", undefined, foreman$p(ctx)), React.createElement("th", undefined, workers$p(ctx)), React.createElement("th", undefined, description$p(ctx)))), React.createElement("tbody", undefined, teams.map(function (t) {
                                          return React.createElement(TeamIndexItem.make, {
                                                      team: t,
                                                      openTeam: openTeam,
                                                      ctx: ctx,
                                                      key: Uuid.toString(t.uuid)
                                                    });
                                        }))) : React.createElement("div", {
                                  className: "panel-title teams__no-teams"
                                }, noTeams$p(ctx))))));
  } else {
    return null;
  }
}

var make = TeamIndex;

export {
  make ,
}
/* name' Not a pure module */
