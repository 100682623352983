

import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Portal from "../../../Portal.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JobSettingsTabs from "../JobSettingsTabs.mjs";
import * as RecurringJobAPI from "./RecurringJobAPI.mjs";
import * as JobSettingsHeader from "../JobSettingsHeader.mjs";
import * as RecurringJobIndexJS from "./RecurringJobIndexJS.mjs";
import * as Utils$1 from "planado/stores/utils";
import * as RecurringJobIndexFooterJS from "./RecurringJobIndexFooterJS.mjs";
import Recurring_jobs_index from "planado/stores/admin/recurring_jobs_index";

function RecurringJobIndex(Props) {
  var wire = Props.wire;
  var footer = wire.footer;
  var match = React.useState(function () {
        
      });
  var setState = match[1];
  var state = match[0];
  var loaded = function (data) {
    if (data.TAG !== "Ok") {
      return ;
    }
    var data$1 = data._0;
    if (data$1 === undefined) {
      return ;
    }
    var store = Recurring_jobs_index(data$1);
    setState(function (param) {
          return Caml_option.some(store);
        });
    Utils$1.connectDocumentToStore(Utils.$$document, footer, store);
  };
  React.useEffect((function () {
          Backend.$$finally(RecurringJobAPI.Index.index(wire), loaded);
        }), []);
  if (state === undefined) {
    return null;
  }
  var store = Caml_option.valFromOption(state);
  return React.createElement("div", {
              id: "content-wrapper"
            }, React.createElement("div", {
                  className: "page-container narrow"
                }, React.createElement(JobSettingsHeader.make, {
                      ctx: wire.ctx
                    }), React.createElement("div", {
                      className: "settings-page settings-page_recurring-jobs row"
                    }, React.createElement(JobSettingsTabs.make, {
                          wire: wire,
                          tab: "RecurringJobs"
                        }), React.createElement("div", {
                          className: "tab-content"
                        }, React.createElement("div", {
                              className: "tab-pane active"
                            }, React.createElement("div", {
                                  className: "recurring-jobs-index"
                                }, React.createElement(RecurringJobIndexJS.make, {
                                      store: store
                                    })))), React.createElement(Portal.make, {
                          root: footer,
                          children: React.createElement(RecurringJobIndexFooterJS.make, {
                                store: store
                              })
                        }))));
}

var make = RecurringJobIndex;

export {
  make ,
}
/* Utils Not a pure module */
