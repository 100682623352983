import React, { Component } from 'react'
import { DraggableSideBarJobsManager } from 'planado/schedule/utils/managers'
import jQuery from 'jquery'
import { Job } from './job'
import { SessionContext } from 'planado/utils/context.js'
import { filterJobs } from 'planado/schedule/utils2/filter'

export class Jobs extends Component {
  componentWillMount() {
    this.dragManager = new DraggableSideBarJobsManager({
      ctx: this.props.ctx,
      chart: this.props.sideBar.chart()
    })
  }

  componentDidMount() {
    const { collection } = this.props
    collection.on('add remove reset sort', () => this.forceUpdate(), this)

    this.dragManager.manage(this.$jobs)
  }

  componentWillUnmount() {
    const { collection } = this.props
    collection.off(null, null, this)
  }

  componentWillReceiveProps(nextProps) {
    const { collection } = this.props
    if (collection === nextProps.collection) {
      return
    }
    collection.off(null, null, this)
    nextProps.collection.on(
      'add remove reset sort',
      () => this.forceUpdate(),
      this
    )
  }

  componentDidUpdate() {
    this.dragManager.manage(this.$jobs)
  }

  get $jobs() {
    return jQuery(this.list).children()
  }

  render() {
    const { sideBar, collection, open, filter } = this.props
    return (
      <ul className="navigation" ref={ul => (this.list = ul)}>
        <SessionContext.Consumer>
          {ctx =>
            filterJobs(collection, filter).map(job => (
              <Job
                model={job}
                sideBar={sideBar}
                chart={sideBar.chart()}
                open={open}
                ctx={ctx}
                key={job.id}
              />
            ))
          }
        </SessionContext.Consumer>
      </ul>
    )
  }
}
