

import * as RCore from "../../../../../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Switch from "../../../../../../../../inputs/Switch.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as BaseFormInput from "../../../../../../../../inputs/BaseFormInput.mjs";

var yes$p = Utils.Translations.t("js.jobs.values.checkbox.yes");

var no$p = Utils.Translations.t("js.jobs.values.checkbox.no");

function JobForm_ReportFieldCheckbox(Props) {
  var label = Props.label;
  var value = Props.value;
  var id = Props.id;
  var onValueChange = Props.onValueChange;
  var ctx = Props.ctx;
  var readOnly = Props.readOnly;
  var wrapperClass = Props.wrapperClass;
  var states = RCore.$$Array.keep([
          [
            yes$p(ctx),
            true
          ],
          [
            no$p(ctx),
            false
          ]
        ], (function (param) {
            if (readOnly) {
              return Caml_obj.equal(param[1], value);
            } else {
              return true;
            }
          })).map(function (param) {
        return {
                name: param[0],
                value: param[1]
              };
      });
  var classes = Belt_Array.concatMany([
        [[
            "job-report-field-container",
            true
          ]],
        wrapperClass !== undefined ? [[
              wrapperClass,
              true
            ]] : []
      ]);
  var tmp = {
    id: id,
    wrapperClass: Js_dict.fromArray(classes),
    children: React.createElement(Switch.OptionalBool.make, {
          emptyDefaultValue: true,
          onValueChange: onValueChange,
          value: value,
          states: states
        })
  };
  if (label !== undefined) {
    tmp.labelText = label;
  }
  return React.createElement(BaseFormInput.make, tmp);
}

var make = JobForm_ReportFieldCheckbox;

var $$default = JobForm_ReportFieldCheckbox;

export {
  make ,
  $$default as default,
}
/* yes' Not a pure module */
