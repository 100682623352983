

import * as JsonDecode from "../libraries/JsonDecode.mjs";

function decode(field) {
  return {
          value: field.required("value", JsonDecode.option(JsonDecode.string)),
          name: field.required("name", JsonDecode.option(JsonDecode.string))
        };
}

var decode$1 = JsonDecode.object(function (field) {
      var unknown = field.required("type", JsonDecode.string);
      switch (unknown) {
        case "email" :
            return {
                    TAG: "Email",
                    _0: decode(field)
                  };
        case "phone" :
            return {
                    TAG: "Phone",
                    _0: decode(field)
                  };
        default:
          return JsonDecode.cannotDecode("unknown contact type: " + unknown);
      }
    });

function extract(c) {
  if (c.TAG === "Phone") {
    return [
            c._0,
            "phone"
          ];
  } else {
    return [
            c._0,
            "email"
          ];
  }
}

function wrap(values, contactType) {
  if (contactType === "email") {
    return {
            TAG: "Email",
            _0: values
          };
  } else {
    return {
            TAG: "Phone",
            _0: values
          };
  }
}

var Values = {};

var empty = {
  TAG: "Phone",
  _0: {
    value: undefined,
    name: undefined
  }
};

export {
  Values ,
  decode$1 as decode,
  extract ,
  wrap ,
  empty ,
}
/* decode Not a pure module */
