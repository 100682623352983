

import * as El from "../../libraries/El.mjs";
import * as Icon from "../../Icon.mjs";
import * as Hooks from "../../libraries/hooks/Hooks.mjs";
import * as React from "react";

function Action(Props) {
  var id = Props.id;
  var disabled = Props.disabled;
  var checked = Props.value;
  var label = Props.label;
  var onChange = Props.onChange;
  var id$1 = Hooks.useId(id);
  var checkboxStyles = El.Cn.concatOpt(El.Cn.concat(Icon.style(undefined, undefined, "check-square"), "pd-action-checkbox"), checked ? "pd-action-checkbox_checked" : undefined);
  var onClick = disabled ? (function (prim) {
        
      }) : (function (_e) {
        onChange(!checked);
      });
  return React.createElement("div", {
              className: "pd-action-container",
              id: id$1,
              disabled: disabled,
              onClick: onClick
            }, React.createElement("span", {
                  "aria-hidden": true,
                  className: checkboxStyles
                }), React.createElement("span", {
                  className: "pd-action-label"
                }, label));
}

var make = Action;

export {
  make ,
}
/* El Not a pure module */
