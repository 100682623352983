

import * as El from "../../../../../../../libraries/El.mjs";
import * as Uuid from "../../../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../../../utils/Utils.mjs";
import * as React from "react";

var templateLabel$p = Utils.Translations.tr("js.jobs.labels.template");

function JobForm_BodyTemplateBlock(Props) {
  var template = Props.template;
  var typeUuid = Props.typeUuid;
  var isNewRecord = Props.isNewRecord;
  var id = Props.id;
  var ctx = Props.ctx;
  var jobTypes = Props.jobTypes;
  if (template === undefined) {
    return null;
  }
  var hasType = RCore.$$Option.mapWithDefault(typeUuid, false, (function (uuid) {
          return jobTypes.some(function (jt) {
                      return Uuid.equal(jt.uuid, uuid);
                    });
        }));
  var groupSize = isNewRecord || hasType ? "pd-form-group-half" : "pd-form-group-full";
  var className = El.Cn.concat(El.Cn.concat("pd-form-group", "job-template"), groupSize);
  return React.createElement("div", {
              className: className
            }, React.createElement("label", {
                  className: "pd-label",
                  htmlFor: id
                }, templateLabel$p(ctx)), React.createElement("input", {
                  className: "pd-form-control",
                  id: id,
                  name: "template",
                  readOnly: true,
                  type: "text",
                  value: template
                }));
}

var make = JobForm_BodyTemplateBlock;

var $$default = JobForm_BodyTemplateBlock;

export {
  make ,
  $$default as default,
}
/* templateLabel' Not a pure module */
