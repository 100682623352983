

import * as El from "../../libraries/El.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Router from "../../Router.mjs";
import * as HeaderModuleCss from "/home/runner/work/planado/planado/client/rescript/components/header/Header.module.css";

var help$p = Utils.Translations.tr("js.menu.help");

var contactUs$p = Utils.Translations.tr("js.menu.contact_us");

var email$p = Utils.Translations.tr("js.menu.support_email");

var ask$p = Utils.Translations.tr("js.menu.ask");

var styles = HeaderModuleCss;

var utmSource = Router.host();

var utmContent = Router.href();

function HeaderSupport(Props) {
  var param = Props.wire;
  var ctx = param.ctx;
  var match = ctx.branding;
  var tmp;
  var exit = 0;
  if (match !== undefined) {
    var contacts = match.contacts;
    if (contacts !== undefined) {
      tmp = React.createElement("div", {
            className: El.Cn.concat(styles.dropItem, styles.dropItemSupport)
          }, React.createElement("div", {
                className: styles.menuSupportHeader
              }, contactUs$p(ctx)), React.createElement("div", {
                className: styles.menuSupportEmail
              }, contacts.support), React.createElement("div", {
                className: styles.menuSupportHelp
              }, ask$p(ctx)));
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: styles.dropItem
            }, React.createElement("a", {
                  href: "https://planado.freshdesk.com/support/solutions?utm_source=" + utmSource + "&utm_content=" + utmContent + "&utm_medium=none&utm_campaign=planado-header-link",
                  target: "_blank"
                }, help$p(ctx))), React.createElement("div", {
              className: El.Cn.concat(styles.dropItem, styles.dropItemDivider)
            }), React.createElement("div", {
              className: El.Cn.concat(styles.dropItem, styles.dropItemSupport)
            }, React.createElement("div", {
                  className: styles.menuSupportHeader
                }, contactUs$p(ctx)), React.createElement("div", {
                  className: styles.menuSupportEmail
                }, email$p(ctx)), React.createElement("div", {
                  className: styles.menuSupportHelp
                }, ask$p(ctx))));
  }
  return React.createElement("div", {
              className: El.Cn.concat(styles.drop, styles.dropRtl)
            }, tmp);
}

var make = HeaderSupport;

export {
  make ,
}
/* help' Not a pure module */
