

import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_Lib_SortArray from "../../../../../../libraries/Belt_Lib_SortArray.mjs";
import * as Schedule_Types_Job from "../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as WeekTimeline_Types from "../types/WeekTimeline_Types.mjs";
import * as Schedule_IntervalTree from "../../../../lib/Schedule_IntervalTree.mjs";
import * as WeekTimeline_Constants from "../WeekTimeline_Constants.mjs";

function index(job) {
  return job.index;
}

var start = WeekTimeline_Types.UnassignedPosition.start;

var finish = WeekTimeline_Types.UnassignedPosition.finish;

var IntervalTree = Schedule_IntervalTree.Make({
      index: index,
      start: start,
      finish: finish
    });

function startedOutside(period, startAt) {
  return Locale.T.lessThan(startAt, period[0]);
}

function finishedOutside(period, finishAt) {
  return Locale.T.moreThan(finishAt, period[1]);
}

function jobLeft(sizes, period, startAt) {
  return Units.Px.multiplyWithInt(sizes.day, Locale.T.isoWeekday(Locale.T.max(period[0], startAt)));
}

function jobWidth(sizes, period, startAt, finishAt) {
  return Units.Px.multiplyWithInt(sizes.day, (Locale.T.isoWeekday(Locale.T.min(finishAt, period[1])) - Locale.T.isoWeekday(Locale.T.max(period[0], startAt)) | 0) + 1 | 0);
}

function sortByWidth(__x) {
  return Belt_Lib_SortArray.stableSortBy(__x, (function (a, b) {
                return Units.Px.cmp(a.width, b.width);
              }));
}

function findGap(_positionOpt, _indexOpt, jobPosition) {
  while(true) {
    var positionOpt = _positionOpt;
    var indexOpt = _indexOpt;
    var position = positionOpt !== undefined ? Caml_option.valFromOption(positionOpt) : Units.Px.zero;
    var index = indexOpt !== undefined ? indexOpt : 0;
    var rendered = RCore.$$Array.keep(jobPosition.children.contents, (function (j) {
            return j.rendered.contents;
          }));
    var found = rendered.every((function(position){
        return function (p) {
          return Caml_obj.notequal(position, p.top.contents);
        }
        }(position)));
    if (found) {
      return position;
    }
    var j = rendered[index];
    var position$1 = Units.Px.plus(j.top.contents, WeekTimeline_Constants.unassignedRowHeight);
    var index$1 = index + 1 | 0;
    _indexOpt = index$1;
    _positionOpt = Caml_option.some(position$1);
    continue ;
  };
}

function setPosition(job) {
  if (!job.rendered.contents) {
    job.rendered.contents = true;
    job.top.contents = Caml_obj.equal(job.children.contents, []) ? Units.Px.zero : findGap(undefined, undefined, job);
    return ;
  }
  
}

function sortByPosition(positions) {
  return Belt_Lib_SortArray.stableSortBy(positions, (function (a, b) {
                  return Units.Px.toInt(Units.Px.minus(b.top.contents, a.top.contents));
                })).toReversed();
}

function make(period, sizes, jobs) {
  var filtered = RCore.$$Array.keep(jobs, (function (job) {
          return Locale.T.intersectInterval(period, [
                      Schedule_Types_Job.UnassignedJob.startAt(job),
                      Schedule_Types_Job.UnassignedJob.finishAt(job)
                    ]);
        }));
  var positions = sortByWidth(filtered.map(function (job, index) {
            var startAt = Schedule_Types_Job.UnassignedJob.startAt(job);
            var finishAt = Schedule_Types_Job.UnassignedJob.finishAt(job);
            return {
                    index: index,
                    startedOutside: startedOutside(period, startAt),
                    finishedOutside: finishedOutside(period, finishAt),
                    top: {
                      contents: Units.Px.zero
                    },
                    left: jobLeft(sizes, period, startAt),
                    width: jobWidth(sizes, period, startAt, finishAt),
                    rendered: {
                      contents: false
                    },
                    children: {
                      contents: []
                    }
                  };
          }));
  var tree = IntervalTree.make(positions);
  positions.forEach(function (position) {
        position.children.contents = RCore.$$Array.keep(IntervalTree.rangeSearch(undefined, tree, WeekTimeline_Types.UnassignedPosition.start(position) + 1 | 0, WeekTimeline_Types.UnassignedPosition.finish(position) - 1 | 0), (function (p) {
                return p.index !== position.index;
              }));
      });
  positions.forEach(setPosition);
  return sortByPosition(positions).map(function (p) {
              return [
                      p,
                      filtered[p.index]
                    ];
            });
}

export {
  make ,
}
/* IntervalTree Not a pure module */
