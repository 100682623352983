

import Table from "planado/components/jobs/index/table";

var make = Table;

export {
  make ,
}
/* make Not a pure module */
