

import * as React from "react";
import * as Optional from "../../../../common/Optional.mjs";
import * as Schedule_TimelineWidgetFull from "./Schedule_TimelineWidgetFull.mjs";
import * as Schedule_TimelineWidgetStats from "./Schedule_TimelineWidgetStats.mjs";
import * as Schedule_TimelineWidgetCompact from "./Schedule_TimelineWidgetCompact.mjs";

function Schedule_TimelineWidget(Props) {
  var view = Props.view;
  var withUnassignedJobs = Props.withUnassignedJobs;
  var match = React.useState(function () {
        return true;
      });
  var setIsCompact = match[1];
  var isCompact = match[0];
  var show = function (_event) {
    setIsCompact(function (param) {
          return false;
        });
  };
  var close = function (_event) {
    setIsCompact(function (param) {
          return true;
        });
  };
  var stats = Schedule_TimelineWidgetStats.use();
  React.useEffect((function () {
          if (withUnassignedJobs && !isCompact) {
            setIsCompact(function (param) {
                  return true;
                });
          }
          
        }), [withUnassignedJobs]);
  return React.createElement(Optional.make, {
              show: stats.all !== 0,
              children: isCompact ? React.createElement(Schedule_TimelineWidgetCompact.make, {
                      stats: stats,
                      onClick: show,
                      view: view,
                      withUnassignedJobs: withUnassignedJobs
                    }) : React.createElement(Schedule_TimelineWidgetFull.make, {
                      stats: stats,
                      close: close,
                      view: view,
                      withUnassignedJobs: withUnassignedJobs
                    })
            });
}

var make = Schedule_TimelineWidget;

export {
  make ,
}
/* react Not a pure module */
