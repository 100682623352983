

import * as Fun from "../../../../libraries/Fun.mjs";
import * as Wire from "../../../../types/Wire.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Types from "../../../../types/Types.mjs";
import * as Units from "../../../../types/Units.mjs";
import * as React from "react";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_State from "../state/Schedule_State.mjs";
import * as Schedule_Scroll from "../../components/scroll/Schedule_Scroll.mjs";
import * as Shared_Lib_Timer from "../../../../shared/lib/Shared_Lib_Timer.mjs";
import * as Schedule_Types_Job from "../types/job/Schedule_Types_Job.mjs";
import * as Schedule_UtilsTimeline from "../utils/Schedule_UtilsTimeline.mjs";

function use() {
  var match = React.useState(function () {
        return false;
      });
  var setIsDrag = match[1];
  var isDrag = match[0];
  var onMouseDown = function ($$event) {
    var isScrollPrevented = Schedule_Scroll.hasPreventScrollAttr($$event.target);
    if (!isScrollPrevented) {
      return setIsDrag(function (param) {
                  return true;
                });
    }
    
  };
  var onMouseUp = function (_event) {
    if (isDrag) {
      return setIsDrag(function (param) {
                  return false;
                });
    }
    
  };
  return [
          onMouseDown,
          onMouseUp,
          isDrag
        ];
}

var DragCursor = {
  use: use
};

function use$1(element, subscriptions) {
  React.useEffect((function () {
          if (element === undefined) {
            return ;
          }
          var element$1 = Caml_option.valFromOption(element);
          var onMouseEnter = Fun.omit(subscriptions.onMouseEnter.trigger);
          var onMouseLeave = Fun.omit(subscriptions.onMouseLeave.trigger);
          var onMouseMove = function ($$event) {
            var point_0 = Units.Px.fromInt($$event.pageX);
            var point_1 = Units.Px.fromInt($$event.pageY);
            var point = [
              point_0,
              point_1
            ];
            var converted = Schedule_UtilsTimeline.ConvertPoint.fromPageToNode(element$1, point);
            subscriptions.onMouseMove.trigger(converted);
          };
          element$1.addEventListener("mouseenter", onMouseEnter);
          element$1.addEventListener("mousemove", onMouseMove);
          element$1.addEventListener("mouseout", onMouseLeave);
          return (function () {
                    element$1.removeEventListener("mouseenter", onMouseEnter);
                    element$1.removeEventListener("mousemove", onMouseMove);
                    element$1.removeEventListener("mouseout", onMouseLeave);
                  });
        }), [element]);
}

var TimelineSubscriptions = {
  use: use$1
};

function use$2(elementRef, assigneeUuid, onActiveAssigneeChanged) {
  var locked = Schedule_State.useLocked();
  React.useEffect((function () {
          var element = Types.ReactRef.toOption(elementRef);
          if (element === undefined) {
            return ;
          }
          if (locked) {
            return ;
          }
          var element$1 = Caml_option.valFromOption(element);
          var onMouseEnter = function (_event) {
            onActiveAssigneeChanged.trigger(Caml_option.some(assigneeUuid));
          };
          var onMouseLeave = function (_event) {
            onActiveAssigneeChanged.trigger(undefined);
          };
          element$1.addEventListener("mouseenter", onMouseEnter);
          element$1.addEventListener("mouseout", onMouseLeave);
          return (function () {
                    onActiveAssigneeChanged.trigger(undefined);
                    element$1.removeEventListener("mouseenter", onMouseEnter);
                    element$1.removeEventListener("mouseout", onMouseLeave);
                  });
        }), [locked]);
}

var BarHighlightAssignee = {
  use: use$2
};

function use$3(onScrollStart, elementRef, job) {
  var wire = AppContext.useWire();
  var match = React.useState(function () {
        return function (prim) {
          
        };
      });
  var setDelayHandler = match[1];
  var resetDelayHandler = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setClickCoordinate = match$1[1];
  var clickCoordinate = match$1[0];
  var resetClickCoordinate = React.useCallback((function () {
          setClickCoordinate(function (param) {
                
              });
        }), []);
  React.useEffect((function () {
          var onClick = function ($$event) {
            resetDelayHandler();
            if (RCore.$$Option.isSome(clickCoordinate)) {
              return resetClickCoordinate();
            }
            var coordinate_0 = Units.Px.fromInt($$event.pageX);
            var coordinate_1 = Units.Px.fromInt($$event.pageY);
            var coordinate = [
              coordinate_0,
              coordinate_1
            ];
            setDelayHandler(function (param) {
                  return Shared_Lib_Timer.delay(300, (function () {
                                setClickCoordinate(function (param) {
                                      return coordinate;
                                    });
                                resetDelayHandler();
                              }));
                });
          };
          var onDoubleClick = function ($$event) {
            $$event.stopPropagation();
            resetDelayHandler();
            Wire.openJob(undefined, undefined, wire, Schedule_Types_Job.ScheduledJob.uuid(job));
          };
          var targetOpt = RCore.$$Option.map(Types.ReactRef.toOption(elementRef), (function (prim) {
                  return prim;
                }));
          var unsubscribeFromClickListeners;
          if (targetOpt !== undefined) {
            var target = Caml_option.valFromOption(targetOpt);
            if (RCore.$$Option.isSome(clickCoordinate)) {
              target.addEventListener("click", onClick);
              unsubscribeFromClickListeners = (function () {
                  target.removeEventListener("click", onClick);
                });
            } else {
              target.addEventListener("click", onClick);
              target.addEventListener("dblclick", onDoubleClick);
              unsubscribeFromClickListeners = (function () {
                  target.removeEventListener("click", onClick);
                  target.removeEventListener("dblclick", onDoubleClick);
                });
            }
          } else {
            unsubscribeFromClickListeners = (function (prim) {
                
              });
          }
          var unsubscribeFromScrollStart = onScrollStart.subscribe(function (param) {
                resetClickCoordinate();
              });
          return (function () {
                    unsubscribeFromClickListeners();
                    unsubscribeFromScrollStart();
                  });
        }), [
        resetDelayHandler,
        clickCoordinate
      ]);
  return [
          clickCoordinate,
          resetClickCoordinate
        ];
}

var JobComponentListeners = {
  use: use$3
};

export {
  DragCursor ,
  TimelineSubscriptions ,
  BarHighlightAssignee ,
  JobComponentListeners ,
}
/* Wire Not a pure module */
