

import * as Note from "../../../components/common/Note/Note.mjs";
import * as Hooks from "../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Route from "../../../types/Route.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as Runtime from "../../../Runtime.mjs";
import * as UI_Icon from "../../../ui/UI_Icon.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../components/inputs/Checkbox.mjs";
import * as Collapse from "../../../components/common/Collapse/Collapse.mjs";
import * as Optional from "../../../components/common/Optional.mjs";
import * as UI_Button from "../../../ui/UI_Button.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Backend_Helper from "../../../libraries/backend/Backend_Helper.mjs";
import * as MessengerTypes from "../../../components/messenger/MessengerTypes.mjs";
import * as Pages_Clients_Upload_API from "./Pages_Clients_Upload_API.mjs";
import * as Pages_Clients_Upload_Results from "./Pages_Clients_Upload_Results.mjs";
import * as File_input from "planado/components/input/file_input";
import * as Pages_Clients_Upload_FormModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/clients/upload/Pages_Clients_Upload_Form.module.css";

var bold$p = Utils.Translations.tr("js.clients.upload.bold");

var description$p = Utils.Translations.tr("js.clients.upload.messages.you_can_upload_clients_here");

var example$p = Utils.Translations.tr("js.clients.upload.messages.example");

var fieldDescription$p = Utils.Translations.t("js.clients.upload.buttons.fields_description");

var forRequired$p = Utils.Translations.t("js.clients.upload.for_required");

var quotesUsage$p = Utils.Translations.tr("js.clients.upload.messages.quotes_usage");

var title$p = Utils.Translations.tr("js.clients.upload.title");

var useSingleQuotes$p = Utils.Translations.tr("js.clients.upload.messages.use_single_quotes");

function organization$p(ctx) {
  return " - " + Utils.Translations.t("js.clients.upload.fields.organization")(ctx);
}

function externalId$p(ctx) {
  return "external_id - " + Utils.Translations.t("js.clients.upload.fields.external_id")(ctx);
}

function firstName$p(ctx) {
  return "first_name - " + Utils.Translations.t("js.clients.upload.fields.first_name")(ctx);
}

function lastName$p(ctx) {
  return "last_name - " + Utils.Translations.t("js.clients.upload.fields.last_name")(ctx);
}

function middleName$p(ctx) {
  return "middle_name - " + Utils.Translations.t("js.clients.upload.fields.middle_name")(ctx);
}

function organizationName$p(ctx) {
  return "organization_name - " + Utils.Translations.t("js.clients.upload.fields.organization_name")(ctx);
}

function contactName$p(ctx) {
  return "contact_name - " + Utils.Translations.t("js.clients.upload.fields.contact_name")(ctx);
}

function cellPhone$p(ctx) {
  return "cell_phone - " + Utils.Translations.t("js.clients.upload.fields.cell_phone")(ctx);
}

function workPhone$p(ctx) {
  return "work_phone - " + Utils.Translations.t("js.clients.upload.fields.work_phone")(ctx);
}

function email$p(ctx) {
  return "email - " + Utils.Translations.t("js.clients.upload.fields.email")(ctx);
}

function siteAddress$p(ctx) {
  return "site_address - " + Utils.Translations.t("js.clients.upload.fields.site_address")(ctx);
}

function siteAddressCountry$p(ctx) {
  return "site_address_country - " + Utils.Translations.t("js.clients.upload.fields.site_address_country")(ctx);
}

function siteAddressApartment$p(ctx) {
  return "site_address_apartment - " + Utils.Translations.t("js.clients.upload.fields.site_address_apartment")(ctx);
}

function siteAddressFloor$p(ctx) {
  return "site_address_floor - " + Utils.Translations.t("js.clients.upload.fields.site_address_floor")(ctx);
}

function siteAddressEntranceNo$p(ctx) {
  return "site_address_entrance_no - " + Utils.Translations.t("js.clients.upload.fields.site_address_entrance_no")(ctx);
}

function siteAddressDescription$p(ctx) {
  return "site_address_description - " + Utils.Translations.t("js.clients.upload.fields.site_address_description")(ctx);
}

function territory$p(ctx) {
  return "territory - " + Utils.Translations.t("js.clients.upload.fields.territory")(ctx);
}

var skipHeader$p = Utils.Translations.t("js.clients.upload.labels.skip_header");

var file$p = Utils.Translations.tr("js.clients.upload.labels.file");

var limitMsg$p = Utils.Translations.t("js.clients.upload.limit_msg");

var limitInfo$p = Utils.Translations.tr("js.clients.upload.limit");

var submit$p = Utils.Translations.tr("js.clients.upload.buttons.upload");

var styles = Pages_Clients_Upload_FormModuleCss;

function Pages_Clients_Upload_Form$PossibleFields(Props) {
  var ctx = Props.ctx;
  return React.createElement(Note.make, {
              children: null,
              flavor: "info"
            }, React.createElement("p", undefined, description$p(ctx), React.createElement("a", {
                      href: Route.pagePath(undefined, {
                            NAME: "Client",
                            VAL: "CsvSample"
                          })
                    }, example$p(ctx)), useSingleQuotes$p(ctx)), React.createElement(Collapse.make, {
                  label: fieldDescription$p(ctx),
                  children: null
                }, React.createElement("br", undefined), React.createElement("p", undefined, React.createElement("strong", undefined, bold$p(ctx)), " " + forRequired$p(ctx)), React.createElement("ol", undefined, React.createElement("li", undefined, externalId$p(ctx)), React.createElement("li", undefined, React.createElement("strong", undefined, "organization"), organization$p(ctx)), React.createElement("li", undefined, firstName$p(ctx)), React.createElement("li", undefined, lastName$p(ctx)), React.createElement("li", undefined, middleName$p(ctx)), React.createElement("li", undefined, organizationName$p(ctx)), React.createElement("li", undefined, contactName$p(ctx)), React.createElement("li", undefined, cellPhone$p(ctx)), React.createElement("li", undefined, workPhone$p(ctx)), React.createElement("li", undefined, email$p(ctx)), React.createElement("li", undefined, siteAddress$p(ctx)), React.createElement("li", undefined, siteAddressCountry$p(ctx)), React.createElement("li", undefined, siteAddressApartment$p(ctx)), React.createElement("li", undefined, siteAddressFloor$p(ctx)), React.createElement("li", undefined, siteAddressEntranceNo$p(ctx)), React.createElement("li", undefined, siteAddressDescription$p(ctx)), React.createElement("li", undefined, territory$p(ctx)))));
}

function fetchTaskResult(wire, setState, uuid) {
  Backend.$$finally(Pages_Clients_Upload_API.Get.send(wire, uuid), (function (statusResponse) {
          if (statusResponse.TAG === "Ok") {
            var match = statusResponse._0;
            var summary = match.summary;
            if (match.status === "finished") {
              var success = match.success;
              return setState(function (current) {
                          return {
                                  uuid: current.uuid,
                                  skipHeader: current.skipHeader,
                                  file: undefined,
                                  taskErrors: current.taskErrors,
                                  hasQuotesError: current.hasQuotesError,
                                  sendingForm: current.sendingForm,
                                  sendingFile: false,
                                  sendingSuccess: success,
                                  sendingSummary: summary,
                                  formSent: current.formSent
                                };
                        });
            }
            
          }
          Runtime.Timeout.defer(1000, (function () {
                  fetchTaskResult(wire, setState, uuid);
                }));
        }));
}

function Pages_Clients_Upload_Form(Props) {
  var state = Props.state;
  var setState = Props.setState;
  var hideModal = Props.hideModal;
  var wire = AppContext.useWire();
  var messenger = wire.subscriptions.messenger;
  var ctx = wire.ctx;
  var submit = async function ($$event) {
    $$event.preventDefault();
    var match = state.file;
    if (match === undefined) {
      return ;
    }
    var target = $$event.target;
    var formData = Backend_Helper.$$FormData.make(target);
    setState(function (current) {
          return {
                  uuid: current.uuid,
                  skipHeader: current.skipHeader,
                  file: current.file,
                  taskErrors: [],
                  hasQuotesError: false,
                  sendingForm: true,
                  sendingFile: true,
                  sendingSuccess: false,
                  sendingSummary: undefined,
                  formSent: false
                };
        });
    return Backend.$$finally(Pages_Clients_Upload_API.Post.send(wire, formData), (function (r) {
                  if (r.TAG === "Ok") {
                    var match = r._0;
                    var uuid = match.uuid;
                    if (uuid !== undefined && Caml_obj.equal(match.taskErrors, [])) {
                      var uuid$1 = Caml_option.valFromOption(uuid);
                      setState(function (current) {
                            return {
                                    uuid: current.uuid,
                                    skipHeader: current.skipHeader,
                                    file: current.file,
                                    taskErrors: current.taskErrors,
                                    hasQuotesError: current.hasQuotesError,
                                    sendingForm: false,
                                    sendingFile: current.sendingFile,
                                    sendingSuccess: current.sendingSuccess,
                                    sendingSummary: current.sendingSummary,
                                    formSent: true
                                  };
                          });
                      Runtime.Timeout.defer(1000, (function () {
                              fetchTaskResult(wire, setState, uuid$1);
                            }));
                      return ;
                    }
                    var summary = match.summary;
                    var quotesError = summary.quotesError;
                    var taskErrors = match.taskErrors;
                    return setState(function (current) {
                                return {
                                        uuid: current.uuid,
                                        skipHeader: current.skipHeader,
                                        file: current.file,
                                        taskErrors: taskErrors,
                                        hasQuotesError: RCore.$$Option.getOr(quotesError, false),
                                        sendingForm: false,
                                        sendingFile: false,
                                        sendingSuccess: current.sendingSuccess,
                                        sendingSummary: summary,
                                        formSent: current.formSent
                                      };
                              });
                  }
                  setState(function (current) {
                        return {
                                uuid: current.uuid,
                                skipHeader: current.skipHeader,
                                file: current.file,
                                taskErrors: current.taskErrors,
                                hasQuotesError: current.hasQuotesError,
                                sendingForm: false,
                                sendingFile: false,
                                sendingSuccess: false,
                                sendingSummary: current.sendingSummary,
                                formSent: current.formSent
                              };
                      });
                  messenger.trigger({
                        TAG: "Show",
                        _0: MessengerTypes.Message.make(undefined, "Danger", {
                              TAG: "Text",
                              _0: Utils.Translations.t("js.errors.internal_error")(ctx)
                            })
                      });
                }));
  };
  var checkboxId = Hooks.useId(undefined);
  var fileId = Hooks.useId(undefined);
  return React.createElement("div", {
              className: styles.modalContainer
            }, React.createElement("div", {
                  className: styles.form
                }, React.createElement("form", {
                      className: state.sendingForm ? "form_sending" : "",
                      onSubmit: (function (e) {
                          submit(e);
                        })
                    }, React.createElement("header", {
                          className: styles.formHeader
                        }, React.createElement("h1", {
                              className: "form-header__title"
                            }, title$p(ctx)), React.createElement(UI_Button.make, {
                              flavor: "close",
                              className: styles.closeButton,
                              onClick: (function (evt) {
                                  evt.preventDefault();
                                  hideModal();
                                }),
                              children: React.createElement(UI_Icon.make, {
                                    icon: "times"
                                  })
                            })), React.createElement("section", {
                          className: styles.formBody
                        }, React.createElement("div", undefined, React.createElement(Optional.make, {
                                  show: state.formSent,
                                  children: React.createElement(Pages_Clients_Upload_Results.make, {
                                        state: state
                                      })
                                }), React.createElement(Pages_Clients_Upload_Form$PossibleFields, {
                                  ctx: ctx
                                }), React.createElement(Optional.make, {
                                  show: state.hasQuotesError,
                                  children: React.createElement(Note.make, {
                                        className: styles.noteBottomMargin,
                                        children: quotesUsage$p(ctx),
                                        flavor: "warning"
                                      })
                                }), React.createElement(Optional.make, {
                                  show: Caml_obj.notequal(state.taskErrors, []),
                                  children: React.createElement(Note.make, {
                                        className: styles.noteBottomMargin,
                                        children: state.taskErrors.map(function (error, idx) {
                                              return React.createElement("div", {
                                                          key: "error-" + idx.toString()
                                                        }, error);
                                            }),
                                        flavor: "danger"
                                      })
                                }), React.createElement("div", {
                                  className: styles.formGroup
                                }, React.createElement("label", {
                                      className: styles.label,
                                      htmlFor: checkboxId
                                    }), React.createElement(Checkbox.make, {
                                      onToggle: (function (param) {
                                          setState(function (current) {
                                                return {
                                                        uuid: current.uuid,
                                                        skipHeader: !current.skipHeader,
                                                        file: current.file,
                                                        taskErrors: current.taskErrors,
                                                        hasQuotesError: current.hasQuotesError,
                                                        sendingForm: current.sendingForm,
                                                        sendingFile: current.sendingFile,
                                                        sendingSuccess: current.sendingSuccess,
                                                        sendingSummary: current.sendingSummary,
                                                        formSent: current.formSent
                                                      };
                                              });
                                        }),
                                      checked: state.skipHeader,
                                      label: skipHeader$p(ctx),
                                      name: "skip_header",
                                      id: checkboxId
                                    })), React.createElement("div", {
                                  className: styles.formGroupFileInput
                                }, React.createElement("label", {
                                      className: styles.label,
                                      htmlFor: fileId
                                    }, file$p(ctx)), React.createElement(File_input.NewFileInput, {
                                      disabled: false,
                                      id: fileId,
                                      name: "file",
                                      value: Js_null.fromOption(state.file),
                                      onFileSelected: (function (file) {
                                          setState(function (current) {
                                                return {
                                                        uuid: current.uuid,
                                                        skipHeader: current.skipHeader,
                                                        file: Caml_option.some(file),
                                                        taskErrors: current.taskErrors,
                                                        hasQuotesError: current.hasQuotesError,
                                                        sendingForm: current.sendingForm,
                                                        sendingFile: current.sendingFile,
                                                        sendingSuccess: current.sendingSuccess,
                                                        sendingSummary: current.sendingSummary,
                                                        formSent: current.formSent
                                                      };
                                              });
                                        }),
                                      onFileCleared: (function (_file) {
                                          setState(function (current) {
                                                return {
                                                        uuid: current.uuid,
                                                        skipHeader: current.skipHeader,
                                                        file: undefined,
                                                        taskErrors: current.taskErrors,
                                                        hasQuotesError: current.hasQuotesError,
                                                        sendingForm: current.sendingForm,
                                                        sendingFile: current.sendingFile,
                                                        sendingSuccess: current.sendingSuccess,
                                                        sendingSummary: current.sendingSummary,
                                                        formSent: current.formSent
                                                      };
                                              });
                                        }),
                                      limit: 3145728,
                                      limitMsg: limitMsg$p(ctx)
                                    }), React.createElement("span", {
                                      className: styles.sizeLimit
                                    }, limitInfo$p(ctx))))), React.createElement("footer", {
                          className: styles.formFooter
                        }, React.createElement(UI_Button.make, {
                              purpose: "primary",
                              type_: "submit",
                              disabled: state.file === undefined || state.sendingFile || state.sendingForm,
                              children: submit$p(ctx)
                            })))));
}

var make = Pages_Clients_Upload_Form;

export {
  make ,
}
/* bold' Not a pure module */
