import React from 'react'
import ReportFieldsLink from 'planado/containers/admin/templates/report_fields_link'
import Hint from 'planado/components/hint'
import { withContext } from 'planado/utils/contextConsumer.jsx'

export default withContext(({ ctx }) => (
  <section className="form-section">
    <h2 className="form-section__title">
      {ctx.t('js.templates.report.title')}
      <Hint
        content={ctx.t('js.templates.report.hint')}
        modifiers={{ hint_title: true }}
      />
    </h2>

    <div className="form-container">
      <ReportFieldsLink />
    </div>
  </section>
))
