

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as React from "react";
import * as Template_Edit from "./Template_Edit.mjs";
import * as Template_Index from "./Template_Index.mjs";

function Template_Component(Props) {
  var wire = Props.wire;
  var route = Props.route;
  if (typeof route === "object") {
    if (route.NAME === "Edit") {
      return React.createElement(Template_Edit.make, {
                  wire: wire,
                  mode: route,
                  key: Uuid.toString(route.VAL)
                });
    } else {
      return React.createElement(Template_Edit.make, {
                  wire: wire,
                  mode: route,
                  key: "new"
                });
    }
  } else {
    return React.createElement(Template_Index.make, {
                wire: wire
              });
  }
}

var make = Template_Component;

export {
  make ,
}
/* Uuid Not a pure module */
