

import * as Icon from "../../../Icon.mjs";
import * as Hooks from "../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../libraries/Locale.mjs";
import * as Select from "../../inputs/Select.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as UI_Form from "../../../ui/UI_Form.mjs";
import * as UI_Page from "../../../ui/UI_Page.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as UI_Label from "../../../ui/UI_Label.mjs";
import * as UI_Button from "../../../ui/UI_Button.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TemporaryWorkerAPI from "./TemporaryWorkerAPI.mjs";
import * as JobForm_ReportFieldDatePicker from "../../job/edit/form/components/reports/report_field/components/date_picker/JobForm_ReportFieldDatePicker.mjs";
import * as TemporaryWorkerModuleCss from "/home/runner/work/planado/planado/client/rescript/components/team/temporary_worker/TemporaryWorker.module.css";

var styles = TemporaryWorkerModuleCss;

var teamName$p = Utils.Translations.tr("js.teams.show.name");

var heading$p = Utils.Translations.tr("js.teams.temp_worker.title");

var worker$p = Utils.Translations.tr("js.teams.temp_worker.worker");

var from$p = Utils.Translations.tr("js.teams.temp_worker.date_from");

var to$p = Utils.Translations.tr("js.teams.temp_worker.date_to");

var add$p = Utils.Translations.tr("common.add");

var cancel$p = Utils.Translations.tr("common.cancel");

var empty$p = Utils.Translations.t("common.messages.must_be_filled");

var toEarlierThanFrom$p = Utils.Translations.t("js.teams.temp_worker.errors.to_earlier_than_from");

function TemporaryWorkerForm$Error(Props) {
  var errors = Props.errors;
  var text = errors[0];
  if (text !== undefined) {
    return React.createElement("div", {
                className: "pd-control-error " + styles.errorMessage
              }, React.createElement("div", undefined, text));
  } else {
    return null;
  }
}

function TemporaryWorkerForm(Props) {
  var wire = Props.wire;
  var teamUuid = Props.teamUuid;
  var teamName = Props.teamName;
  var exceptUuids = Props.exceptUuids;
  var onClose = Props.onClose;
  var onSubmit = Props.onSubmit;
  var errors = Props.errors;
  var setErrors = Props.setErrors;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return false;
      });
  var setDisabled = match[1];
  var disabled = match[0];
  var match$1 = React.useState(function () {
        return {
                teamUuid: teamUuid,
                userUuid: undefined,
                dateFrom: undefined,
                dateTo: undefined
              };
      });
  var setState = match$1[1];
  var state = match$1[0];
  var teamInputId = Hooks.useId(undefined);
  var workerInputId = Hooks.useId(undefined);
  var dateFromInputId = Hooks.useId(undefined);
  var dateToInputId = Hooks.useId(undefined);
  var limits = {
    pastDaysCount: 0,
    futureDaysCount: 60
  };
  var options = RCore.$$Array.keep(RCore.$$Array.keepMap(ctx.allAssignees, (function (a) {
              if (a.TAG === "Team") {
                return ;
              } else {
                return a._0;
              }
            })), (function (a) {
          return !exceptUuids.some(function (e) {
                      return Caml_obj.equal(e, a.uuid);
                    });
        }));
  var close = function ($$event) {
    $$event.preventDefault();
    onClose();
  };
  var validateForm = function () {
    var match = state.userUuid;
    var withUserValidated = match !== undefined ? errors : ({
          userUuid: [empty$p(ctx)],
          dateFrom: errors.dateFrom,
          dateTo: errors.dateTo
        });
    var match$1 = state.dateFrom;
    var withDateFromValidated = match$1 !== undefined ? withUserValidated : ({
          userUuid: withUserValidated.userUuid,
          dateFrom: [empty$p(ctx)],
          dateTo: withUserValidated.dateTo
        });
    var match$2 = state.dateTo;
    var withDateToValidated = match$2 !== undefined ? withDateFromValidated : ({
          userUuid: withDateFromValidated.userUuid,
          dateFrom: withDateFromValidated.dateFrom,
          dateTo: [empty$p(ctx)]
        });
    var match$3 = state.dateFrom;
    var match$4 = state.dateTo;
    if (match$3 !== undefined && match$4 !== undefined && Locale.T.lessThan(Caml_option.valFromOption(match$4), Caml_option.valFromOption(match$3))) {
      return {
              userUuid: withDateToValidated.userUuid,
              dateFrom: withDateToValidated.dateFrom,
              dateTo: [toEarlierThanFrom$p(ctx)]
            };
    } else {
      return withDateToValidated;
    }
  };
  var submit = function ($$event) {
    $$event.preventDefault();
    var newErrors = validateForm();
    setErrors(function (param) {
          return newErrors;
        });
    if (Caml_obj.equal(TemporaryWorkerAPI.Post.$$Response.Errors.emptyErrors, newErrors)) {
      setDisabled(function (param) {
            return true;
          });
      onSubmit(state);
      return setDisabled(function (param) {
                  return false;
                });
    }
    
  };
  var tmp = {
    id: dateFromInputId,
    wrapperClass: styles.dateInput,
    limits: limits,
    onChange: (function (value) {
        setErrors(function (current) {
              return {
                      userUuid: current.userUuid,
                      dateFrom: [],
                      dateTo: current.dateTo
                    };
            });
        setState(function (current) {
              return {
                      teamUuid: current.teamUuid,
                      userUuid: current.userUuid,
                      dateFrom: value,
                      dateTo: current.dateTo
                    };
            });
      }),
    calendarPlacement: "bottom",
    disabled: disabled,
    wire: wire
  };
  if (state.dateFrom !== undefined) {
    tmp.date = Caml_option.valFromOption(state.dateFrom);
  }
  var tmp$1 = {
    id: dateToInputId,
    wrapperClass: styles.dateInput,
    limits: limits,
    onChange: (function (value) {
        setErrors(function (current) {
              return {
                      userUuid: current.userUuid,
                      dateFrom: current.dateFrom,
                      dateTo: []
                    };
            });
        setState(function (current) {
              return {
                      teamUuid: current.teamUuid,
                      userUuid: current.userUuid,
                      dateFrom: current.dateFrom,
                      dateTo: value
                    };
            });
      }),
    calendarPlacement: "bottom",
    disabled: disabled,
    wire: wire
  };
  if (state.dateTo !== undefined) {
    tmp$1.date = Caml_option.valFromOption(state.dateTo);
  }
  return React.createElement(UI_Page.make, {
              children: React.createElement("div", {
                    className: styles.wrapper
                  }, React.createElement("div", {
                        className: styles.formHeading
                      }, React.createElement("div", {
                            className: styles.formHeadingTitle
                          }, heading$p(ctx)), React.createElement(UI_Button.make, {
                            flavor: "close",
                            disabled: disabled,
                            onClick: close,
                            children: React.createElement("span", {
                                  className: Icon.style(undefined, undefined, "times")
                                })
                          })), React.createElement("div", {
                        className: styles.formBody
                      }, React.createElement(UI_Form.Group.make, {
                            children: null
                          }, React.createElement(UI_Label.make, {
                                htmlFor: teamInputId,
                                children: teamName$p(ctx)
                              }), React.createElement(UI_Form.Control.make, {
                                children: React.createElement("input", {
                                      id: teamInputId,
                                      disabled: true,
                                      value: teamName
                                    })
                              })), React.createElement(UI_Form.Group.make, {
                            children: null
                          }, React.createElement(UI_Label.make, {
                                htmlFor: workerInputId,
                                children: worker$p(ctx)
                              }), React.createElement(Select.make, {
                                placeholder: "",
                                id: workerInputId,
                                onChange: (function (value) {
                                    setErrors(function (current) {
                                          return {
                                                  userUuid: [],
                                                  dateFrom: current.dateFrom,
                                                  dateTo: current.dateTo
                                                };
                                        });
                                    setState(function (current) {
                                          return {
                                                  teamUuid: current.teamUuid,
                                                  userUuid: RCore.$$Option.map(value === null ? undefined : Caml_option.some(value), (function (prim) {
                                                          return prim;
                                                        })),
                                                  dateFrom: current.dateFrom,
                                                  dateTo: current.dateTo
                                                };
                                        });
                                  }),
                                value: Js_null.fromOption(RCore.$$Option.map(state.userUuid, (function (prim) {
                                            return prim;
                                          }))),
                                disabled: disabled,
                                options: options.map(function (w) {
                                      return {
                                              value: w.uuid,
                                              label: w.name
                                            };
                                    })
                              }), React.createElement(TemporaryWorkerForm$Error, {
                                errors: errors.userUuid
                              })), React.createElement(UI_Form.GroupContainer.make, {
                            children: null
                          }, React.createElement(UI_Form.Group.make, {
                                width: "half",
                                children: null
                              }, React.createElement(UI_Label.make, {
                                    htmlFor: dateFromInputId,
                                    children: from$p(ctx)
                                  }), React.createElement(JobForm_ReportFieldDatePicker.make, tmp), React.createElement(TemporaryWorkerForm$Error, {
                                    errors: errors.dateFrom
                                  })), React.createElement(UI_Form.Group.make, {
                                width: "half",
                                children: null
                              }, React.createElement(UI_Label.make, {
                                    htmlFor: dateToInputId,
                                    children: to$p(ctx)
                                  }), React.createElement(JobForm_ReportFieldDatePicker.make, tmp$1), React.createElement(TemporaryWorkerForm$Error, {
                                    errors: errors.dateTo
                                  })))), React.createElement("div", {
                        className: styles.footer
                      }, React.createElement(UI_Button.make, {
                            flavor: "outline",
                            disabled: disabled,
                            onClick: close,
                            children: cancel$p(ctx)
                          }), React.createElement(UI_Button.make, {
                            purpose: "primary",
                            disabled: disabled,
                            onClick: submit,
                            children: add$p(ctx)
                          })))
            });
}

var API;

var make = TemporaryWorkerForm;

export {
  API ,
  make ,
}
/* styles Not a pure module */
