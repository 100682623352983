

import * as El from "../../../libraries/El.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as React from "react";
import * as Optional from "../../common/Optional.mjs";
import * as FieldErrors from "../../common/FieldError/FieldErrors.mjs";
import * as FileInput_Preview from "./components/preview/FileInput_Preview.mjs";
import * as FileInput_AddButton from "./components/add_button/FileInput_AddButton.mjs";
import * as FileInputModuleCss from "/home/runner/work/planado/planado/client/rescript/components/file/file_input/FileInput.module.css";

var styles = FileInputModuleCss;

function FileInput(Props) {
  var fieldName = Props.fieldName;
  var files = Props.files;
  var onStartUpload = Props.onStartUpload;
  var onFinishUpload = Props.onFinishUpload;
  var removeFile = Props.removeFile;
  var readOnlyOpt = Props.readOnly;
  var maxFilesInFieldOpt = Props.maxFilesInField;
  var showCarouselImage = Props.showCarouselImage;
  var sizeLimitMbOpt = Props.sizeLimitMb;
  var backendErrorsOpt = Props.backendErrors;
  var onlyImages = Props.onlyImages;
  var wrapperClass = Props.wrapperClass;
  var readOnly = readOnlyOpt !== undefined ? readOnlyOpt : false;
  var maxFilesInField = maxFilesInFieldOpt !== undefined ? maxFilesInFieldOpt : 1;
  var sizeLimitMb = sizeLimitMbOpt !== undefined ? sizeLimitMbOpt : 25;
  var backendErrors = backendErrorsOpt !== undefined ? backendErrorsOpt : [];
  var canHaveMoreFiles = !readOnly && files.length < maxFilesInField;
  var tmp = {
    onStartUpload: onStartUpload,
    onFinishUpload: onFinishUpload,
    sizeLimitMb: sizeLimitMb
  };
  if (onlyImages !== undefined) {
    tmp.onlyImages = onlyImages;
  }
  return React.createElement("div", {
              className: El.Cn.concatOpt(styles.field, wrapperClass)
            }, fieldName !== undefined ? React.createElement("div", {
                    className: styles.fieldName
                  }, fieldName) : null, React.createElement("div", {
                  className: styles.files
                }, files.map(function (file) {
                      return React.createElement(FileInput_Preview.make, {
                                  file: file,
                                  removeFile: removeFile,
                                  showImage: showCarouselImage,
                                  readOnly: readOnly,
                                  key: Uuid.toString(file.uuid)
                                });
                    }), React.createElement(Optional.make, {
                      show: canHaveMoreFiles,
                      children: React.createElement(FileInput_AddButton.make, tmp)
                    })), React.createElement(FieldErrors.make, {
                  errors: backendErrors
                }));
}

var make = React.memo(FileInput);

export {
  make ,
}
/* styles Not a pure module */
