

import * as DND_Context from "./DND_Context.mjs";
import * as DND_Draggable from "./DND_Draggable.mjs";
import * as DND_Droppable from "./DND_Droppable.mjs";

function Make($star) {
  var Context = DND_Context.Make({});
  var makeProps = function (value, children, param) {
    return {
            value: value,
            children: children
          };
  };
  var make = Context.context.Provider;
  var ContextProvider = {
    makeProps: makeProps,
    make: make
  };
  var Draggable = DND_Draggable.Make(Context);
  var Droppable = DND_Droppable.Make(Context);
  return {
          Context: Context,
          ContextProvider: ContextProvider,
          Draggable: Draggable,
          Droppable: Droppable
        };
}

export {
  Make ,
}
/* DND_Context Not a pure module */
