

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as RCore from "../RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as Locale from "../Locale.mjs";
import * as MomentRe from "../../bindings/moment/MomentRe.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Context_Types from "../../context/Context_Types.mjs";

var acceptJson = [[
    "Accept",
    "application/json"
  ]];

var contentJson = Belt_Array.concatMany([
      acceptJson,
      [[
          "Content-Type",
          "application/json"
        ]]
    ]);

var contentJsonOverridden = Belt_Array.concatMany([
      contentJson,
      [[
          "X-HTTP-Method-Override",
          "GET"
        ]]
    ]);

var withUserTimezone = Belt_Array.concatMany([
      contentJson,
      [[
          "X-User-Timezone",
          RCore.$$Option.getOr(MomentRe.guess(), "")
        ]]
    ]);

function withFrontendVersion(headers) {
  var version = Utils.currentFrontendVersion();
  if (version !== undefined) {
    return Belt_Array.concatMany([
                headers,
                [[
                    "X-Planado-Frontend-Version",
                    version
                  ]]
              ]);
  } else {
    return headers;
  }
}

function roundTimestampTo5Minutes(unixTimestamp) {
  return unixTimestamp - unixTimestamp % 300 | 0;
}

function withSignature(headers, wire) {
  if (Context_Types.Features.hasFlag("webApiSignature", wire.ctx.features)) {
    return Belt_Array.concatMany([
                headers,
                [[
                    "X-Request-Signature",
                    Utils.digest(Uuid.toString(wire.ctx.tenantUuid) + "-" + roundTimestampTo5Minutes(MomentRe.Moment.unix(Locale.T.now(wire))).toString())
                  ]]
              ]);
  } else {
    return headers;
  }
}

function withOptionalHeaders(headers, wire) {
  return withSignature(withFrontendVersion(headers), wire);
}

export {
  acceptJson ,
  contentJson ,
  contentJsonOverridden ,
  withUserTimezone ,
  withFrontendVersion ,
  roundTimestampTo5Minutes ,
  withSignature ,
  withOptionalHeaders ,
}
/* contentJson Not a pure module */
