

import * as Catalog_Types from "../Catalog_Types.mjs";

function fromProps(unit, type_) {
  if (unit !== undefined) {
    return {
            TAG: "Edit",
            _0: unit.uuid,
            _1: {
              name: unit.name,
              code: unit.code,
              type_: unit.type_,
              externalId: unit.externalId,
              archived: unit.archived
            }
          };
  } else {
    return {
            TAG: "New",
            _0: Catalog_Types.Unit.Form.empty(type_)
          };
  }
}

function form(s) {
  if (s.TAG === "New") {
    return s._0;
  } else {
    return s._1;
  }
}

function updateForm(state, newForm) {
  if (state.TAG === "New") {
    return {
            TAG: "New",
            _0: newForm
          };
  } else {
    return {
            TAG: "Edit",
            _0: state._0,
            _1: newForm
          };
  }
}

function reducer(state, action) {
  var form$1 = form(state);
  switch (action.TAG) {
    case "SetName" :
        return updateForm(state, {
                    name: action._0,
                    code: form$1.code,
                    type_: form$1.type_,
                    externalId: form$1.externalId,
                    archived: form$1.archived
                  });
    case "SetCode" :
        return updateForm(state, {
                    name: form$1.name,
                    code: action._0,
                    type_: form$1.type_,
                    externalId: form$1.externalId,
                    archived: form$1.archived
                  });
    case "SetExternalId" :
        return updateForm(state, {
                    name: form$1.name,
                    code: form$1.code,
                    type_: form$1.type_,
                    externalId: action._0,
                    archived: form$1.archived
                  });
    case "SetArchived" :
        return updateForm(state, {
                    name: form$1.name,
                    code: form$1.code,
                    type_: form$1.type_,
                    externalId: form$1.externalId,
                    archived: action._0
                  });
    
  }
}

export {
  fromProps ,
  form ,
  reducer ,
}
/* Catalog_Types Not a pure module */
