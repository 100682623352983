

import * as RCore from "../../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../../types/Types.mjs";
import * as Utils from "../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../../../../libraries/Locale.mjs";
import * as Tooltip from "../../../../../../common/Tooltip/Tooltip.mjs";
import * as Optional from "../../../../../../common/Optional.mjs";
import * as JobForm_BodyBusinessHoursWarningModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/edit/form/components/body/business_hours_warning/JobForm_BodyBusinessHoursWarning.module.css";

var businessHoursHint$p = Utils.Translations.tx("js.settings.business_hours.hint");

var businessHoursWarning$p = Utils.Translations.tr("js.settings.business_hours.warning");

var styles = JobForm_BodyBusinessHoursWarningModuleCss;

function JobForm_BodyBusinessHoursWarning(Props) {
  var scheduledAt = Props.scheduledAt;
  var status = Props.status;
  var wire = Props.wire;
  var ctx = wire.ctx;
  var warningRef = Types.ReactRef.use();
  var businessHours = wire.ctx.businessHours;
  if (businessHours === undefined) {
    return null;
  }
  var scheduledAt$1 = Locale.T.toDateTime(scheduledAt, wire);
  var start = Types.Time.Duration.asSeconds(businessHours.start);
  var finish = Types.Time.Duration.asSeconds(businessHours.finish);
  var scheduledAtTime = scheduledAt$1.time;
  var scheduledAtSeconds = scheduledAtTime !== undefined ? Types.Time.Duration.asSeconds(Locale.T.durationFormatWithLocalizator("Time", wire.ctx.localizator, scheduledAtTime)) : 0.0;
  var jobFinished = status === "Finished";
  var show = !jobFinished && RCore.$$Option.isSome(scheduledAt$1.date) && RCore.$$Option.isSome(scheduledAt$1.time) && (scheduledAtSeconds < start || scheduledAtSeconds > finish);
  return React.createElement(Optional.make, {
              show: show,
              children: React.createElement(Tooltip.make, {
                    elementRef: warningRef,
                    position: "right",
                    content: businessHoursHint$p({
                          from: (function (__x) {
                                return Locale.T.secondsToTime(wire, __x);
                              })(start),
                          to: (function (__x) {
                                return Locale.T.secondsToTime(wire, __x);
                              })(finish)
                        }, ctx),
                    children: React.createElement("span", {
                          ref: warningRef,
                          className: styles.message
                        }, businessHoursWarning$p(ctx))
                  })
            });
}

var make = JobForm_BodyBusinessHoursWarning;

var $$default = JobForm_BodyBusinessHoursWarning;

export {
  make ,
  $$default as default,
}
/* businessHoursHint' Not a pure module */
