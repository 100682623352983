

import * as JobEdit_State from "./JobEdit_State.mjs";
import * as JobEdit_StateTypes from "./JobEdit_StateTypes.mjs";
import * as JobEdit_StateReducer from "./reducer/JobEdit_StateReducer.mjs";

function useState() {
  return JobEdit_State.Context.use()[0];
}

function useDispatch() {
  return JobEdit_State.Context.use()[1];
}

function useJob() {
  var state = useState();
  return JobEdit_StateTypes.State.job(state);
}

function useCustomFieldsErrors() {
  var state = useState();
  return JobEdit_StateTypes.State.customFieldsErrors(state);
}

function useJobErrors() {
  var state = useState();
  return JobEdit_StateTypes.State.jobErrors(state);
}

function useSetExternalId() {
  var dispatch = useDispatch();
  return function (externalId) {
    dispatch({
          TAG: "Job",
          _0: {
            TAG: "SetExternalId",
            _0: externalId
          }
        });
  };
}

var GlobalContext;

var useContext = JobEdit_StateReducer.use;

export {
  GlobalContext ,
  useContext ,
  useState ,
  useDispatch ,
  useJob ,
  useCustomFieldsErrors ,
  useJobErrors ,
  useSetExternalId ,
}
/* JobEdit_State Not a pure module */
