

import * as MomentRe from "../bindings/moment/MomentRe.mjs";

var iso8601 = MomentRe.Moment.defaultFormat;

var $$Date = {
  iso8601: iso8601
};

export {
  $$Date ,
}
/* MomentRe Not a pure module */
