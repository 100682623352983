import * as React from 'react'
import { encode } from 'rscrpt/libraries/ContextAPI.mjs'
import I18n from 'planado/vendor/i18n.js'
import underscore from 'underscore'
import moment from 'moment-timezone'

export const initMomentJS = (ctx) => {
  let lang
  if (ctx.localizator.lang === '') {
    lang = 'en-gb'
  } else {
    lang = ctx.localizator.lang
  }

  moment.locale(lang)
}

const initNumberFormats = (ctx) => {
  const { numbers, lang } = ctx.localizator

  if (!numbers) {
    return
  }

  if (I18n.translations[lang]) {
    I18n.translations[lang].number = underscore.extend(
      I18n.translations[lang].number,
      {
        format: {
          separator: numbers.separator,
          delimiter: numbers.delimiter,
        },
      }
    )
  }
}

export const setLang = (ctx) => {
  I18n.locale = ctx.localizator.lang
}

export const translateC = (key, value, context) =>
  I18n.t(key, { ...value, locale: context.localizator.lang })


export const createContext = ({ wire, ctx, dom }) => {
  const ctx_ = encode(ctx)

  const context = {
    wire,
    dom,
    tenantUuid: ctx_.tenantUuid,
    dadataToken: ctx_.dadataToken,
    yandexGeocoderApiKey: ctx_.yandexGeocoderApiKey,
    currency: ctx_.currency,
    authorized: ctx_.authorized,
    owner: ctx_.owner,
    enabled: ctx_.enabled,
    billing: ctx_.billing,
    tags: ctx_.tags,
    storageType: 'localStorage',

    trial: ctx_.trial,
    features: ctx_.features,
    localizator: ctx_.localizator,
    user: ctx_.user,
    branding: ctx_.branding,
    permissions: ctx_.permissions,
    assignees: ctx_.assignees,
    availableSkills: ctx_.availableSkills,
    licenseSource: ctx._licenseSource,
    disposable: ctx_.disposable,
    addressSourceOptions: ctx_.addressSourceOptions,

    t(key, params) {
      return translateC(key, params, this)
    },

    toHumanSize(size) {
      if (size) {
        return I18n.toHumanSize(size, { format: '%n %u' })
      } else {
        return translateC('js.components.file_input.unknown_size', null, this)
      }
    },

    toNumber(number, options = { strip_insignificant_zeros: true }) {
      return I18n.toNumber(number, options)
    },
  }

  setLang(context)
  initMomentJS(context)
  initNumberFormats(context)

  return context
}

export const SessionContext = React.createContext(null)
