

import * as React from "react";

function JobIndexFilter_Wrapper(Props) {
  var toggleFilter = Props.toggleFilter;
  var show = Props.show;
  var label = Props.label;
  var children = Props.children;
  var classes = show ? "j-filter__item" : "j-filter__item is-hidden";
  return React.createElement("div", {
              className: classes
            }, React.createElement("h3", {
                  className: "j-filter__name",
                  onClick: (function (param) {
                      toggleFilter(!show);
                    })
                }, label), children);
}

var make = JobIndexFilter_Wrapper;

export {
  make ,
}
/* react Not a pure module */
