

import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Pages_Addresses_Search from "./search/Pages_Addresses_Search.mjs";
import * as Pages_Addresses_Buttons from "./buttons/Pages_Addresses_Buttons.mjs";
import * as Pages_Addresses_HeaderModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/addresses/header/Pages_Addresses_Header.module.css";

var styles = Pages_Addresses_HeaderModuleCss;

var addressesTitle$p = Utils.Translations.tr("js.addresses.index.title");

function Pages_Addresses_Header(Props) {
  var addressType = Props.addressType;
  var match = AppContext.useWire();
  return React.createElement("div", {
              className: styles.header
            }, React.createElement("h1", undefined, addressesTitle$p(match.ctx)), React.createElement("div", {
                  className: styles.divider
                }), React.createElement(Pages_Addresses_Search.make, {}), React.createElement(Pages_Addresses_Buttons.make, {
                  addressType: addressType
                }));
}

var make = Pages_Addresses_Header;

export {
  make ,
}
/* styles Not a pure module */
