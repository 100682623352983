import jQuery from 'jquery'
import classNames from 'classnames'
import { showTime } from 'planado/utils/time/index.js'
import { async, escape } from 'planado/utils/index.js'

export class DraggableSpansManager {
  dragFilter = ':not(.ui-draggable,.multiple,.finished,.current,.overtime)'
  changeOpacity = true

  constructor(options) {
    this.options = options
    this.callbacks = this.options.callbacks || {}
    this.chart = this.options.chart
    this.ctx = options.ctx
  }

  manage($spans) {
    $spans.filter(this.dragFilter).draggable({
      containment: '.chart-bars',
      snap: '.bar.snapping',
      snapTolerance: 20,
      snapMode: 'inner',
      revert: 'invalid',
      scope: 'chart',
      appendTo: '#schedule',
      helper: this.buildHelper,
      start: this.startDragging,
      drag: this.drag,
      stop: this.stopDragging
    })

    $spans
      .filter('.ui-draggable')
      .filter('.finished,.current,.overtime')
      .draggable('destroy')
  }

  startDragging = (evt, ui) => {
    const target = evt.currentTarget
    const $target = jQuery(target).data({ dragging: true })
    if (this.changeOpacity) {
      $target.css({ opacity: 0.2 })
    }
    const $span = ui.helper
    $span.data({ originalHTML: $span.html() }).addClass('dragging')

    if ($span.hasClass('nested')) {
      this._resizeSpan($span)
    }
    if (this.callbacks.dragStart) {
      this.callbacks.dragStart(evt, ui, $span)
    }
  }

  drag = (evt, ui) => {
    let $span = ui.helper
    let job = this.chart.jobs().get($span.data('job-id'))
    let $activeBar = $span.data('$activeBar')
    let outOfChart = $span.data('outOfChart')

    if ($activeBar && !outOfChart) {
      let offset = this.getOffset(ui)

      let timeStart = this.chart.viewOffsetToTime(offset)
      let timeFinish = timeStart.clone().add(job.scheduledDuration())
      let originalHTML = $span
        .data('originalHTML')
        .replace(/reactid/g, 'reactid-copy')
      return $span.html(
        `<div class="time-target time-start">${showTime(
          timeStart,
          'time',
          this.options.ctx
        )}</div>` +
          '<div class="time-target time-finish">' +
          showTime(timeFinish, 'time', this.options.ctx) +
          '</div>' +
          originalHTML
      )
    } else {
      return $span.html($span.data('originalHTML'))
    }
  }

  getOffset(ui) {
    let $viewPort = jQuery('.view-port')
    return ui.position.left - $viewPort.offset().left + $viewPort[0].scrollLeft
  }

  stopDragging = (evt, ui) => {
    let { target } = evt
    let $target = jQuery(target).data({ dragging: false })
    let $span = ui.helper
    $span.html($span.data('originalHTML'))
    async(() => $target.css({ opacity: '' }))
    // no idea why I need do that
    async(() => jQuery('.bar-span.helper').remove())
    if (this.callbacks.dragStop) {
      this.callbacks.dragStop(evt, ui, $span)
    }
  }

  buildHelper = evt => {
    let target = evt.currentTarget
    let $target = jQuery(target)
    let html = evt.currentTarget.innerHTML.replace(/reactid/g, 'reactid-copy')

    let classes = classNames({
      'bar-span helper': true,
      nested: this.options.nested
    })

    let el = jQuery('<div></div>')
      .addClass(classes)
      .html(html)
      .data({ 'job-id': $target.data('job-id') })
      .css({
        width: $target.css('width')
      })[0]
    let r = Math.floor(Math.random() * 1e6)
    jQuery(el).data({ rand_id: r })
    return el
  }

  _resizeSpan($span) {
    let job = this.options.chart.jobs().get($span.data('job-id'))
    let width = this._getSpanWidth(job)
    if (width) {
      $span.css({ width })
    }
  }

  _getSpanWidth(job) {
    return this.options.chart.durationAsDiff(job.scheduledDuration())
  }
}

export class DraggableSideBarJobsManager extends DraggableSpansManager {
  changeOpacity = false

  manage($spans) {
    $spans.filter(this.dragFilter).draggable({
      containment: '.chart-bars',
      snap: '.bar.snapping',
      snapTolerance: 20,
      snapMode: 'inner',
      revert: 'invalid',
      scope: 'chart',
      appendTo: '#schedule',
      helper: this.buildHelper,
      start: this.startDragging,
      drag: this.drag,
      stop: this.stopDragging,
      cursorAt: {
        top: 15,
        left: 30
      }
    })

    $spans
      .filter('.ui-draggable')
      .filter('.finished,.current,.overtime')
      .draggable('destroy')
  }

  buildHelper = evt => {
    let target = evt.currentTarget
    let $target = jQuery(target)
    let job = this.chart.jobs().get($target.data('job-id'))

    return jQuery(
      `<div class='bar-span helper'><span class='text'>${escape(
        job.getLabel(this.ctx)
      )}</span></div>`
    )
      .data($target.data())
      .data({ outOfChart: true })
      .css({ width: this._getSpanWidth(job) })
  }
}
