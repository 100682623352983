

import * as React from "react";
import * as Locale from "../../../../../../../../../libraries/Locale.mjs";
import * as AppContext from "../../../../../../../../../context/AppContext.mjs";
import * as ModalQuery from "../../../../../../../../../types/ModalQuery.mjs";
import * as Assignee_Types from "../../../../../../../../../types/Assignee_Types.mjs";
import * as Schedule_Popover from "../../../../../../../components/popovers/popover/Schedule_Popover.mjs";
import * as Schedule_AddJobPanel from "../../../../../../../components/add_job_panel/Schedule_AddJobPanel.mjs";
import * as Schedule_UtilsTimeline from "../../../../../../../lib/utils/Schedule_UtilsTimeline.mjs";
import * as WeekTimeline_ActiveDayUtils from "../../../lib/WeekTimeline_ActiveDayUtils.mjs";

function WeekTimeline_ActiveDayBarAddJobPopover(Props) {
  var param = Props.activeDay;
  var contentEl = Props.contentEl;
  var coordinate = Props.coordinate;
  var close = Props.close;
  var bar = param.bar;
  var match = AppContext.useWire();
  var openModal = match.openModal;
  var scheduledAt = WeekTimeline_ActiveDayUtils.FindByCoordinateInTimeline.scheduledAt(param.period, param.sizes, Schedule_UtilsTimeline.ConvertPoint.fromPageToNode(contentEl, coordinate));
  var addJob = function (templateUuid) {
    return function ($$event) {
      $$event.preventDefault();
      var options = {
        TAG: "Schedule",
        _0: ModalQuery.Job.NewOptions.Schedule.make(Locale.T.formatM("DateTimeISO")(scheduledAt), {
              NAME: "Uuid",
              VAL: Assignee_Types.Assignee.uuid(bar)
            }, templateUuid)
      };
      openModal({
            NAME: "Job",
            VAL: {
              NAME: "New",
              VAL: options
            }
          });
      close();
    };
  };
  var clickTargetPosition = {
    NAME: "Coordinate",
    VAL: coordinate
  };
  return React.createElement(Schedule_Popover.make, {
              clickTargetPosition: clickTargetPosition,
              children: React.createElement(Schedule_AddJobPanel.make, {
                    scheduledAt: scheduledAt,
                    addJob: addJob,
                    close: close
                  })
            });
}

var make = WeekTimeline_ActiveDayBarAddJobPopover;

export {
  make ,
}
/* react Not a pure module */
