

import * as RCore from "../../libraries/RCore.mjs";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RadioModuleCss from "/home/runner/work/planado/planado/client/rescript/components/inputs/Radio.module.css";

var styles = RadioModuleCss;

function Radio(Props) {
  var onChange = Props.onChange;
  var checked = Props.checked;
  var name = Props.name;
  var labelText = Props.labelText;
  var wrapperClass = Props.wrapperClass;
  var labelClass = Props.labelClass;
  var inputClass = Props.inputClass;
  var disabled = Props.disabled;
  var children = Props.children;
  var tmp = {};
  if (wrapperClass !== undefined) {
    tmp.className = Caml_option.valFromOption(wrapperClass);
  }
  var tmp$1 = {
    checked: checked,
    type: "radio",
    onChange: onChange
  };
  if (inputClass !== undefined) {
    tmp$1.className = Caml_option.valFromOption(inputClass);
  }
  if (disabled !== undefined) {
    tmp$1.disabled = Caml_option.valFromOption(disabled);
  }
  if (name !== undefined) {
    tmp$1.name = Caml_option.valFromOption(name);
  }
  return React.createElement("div", tmp, React.createElement("label", {
                  className: RCore.$$Option.getOr(labelClass, styles.radio)
                }, React.createElement("input", tmp$1), RCore.$$Option.getOr(labelText, ""), RCore.$$Option.getOr(children, null)));
}

var make = Radio;

export {
  make ,
}
/* styles Not a pure module */
