

import * as React from "react";
import * as Hooks_TrackVisibility from "./Hooks_TrackVisibility.mjs";

function use(onLoadMore, hasNextPage, loading) {
  var match = Hooks_TrackVisibility.use();
  var shouldLoadMore = match.isVisible && hasNextPage && !loading;
  React.useEffect((function () {
          if (!shouldLoadMore) {
            return ;
          }
          var timeoutId = setTimeout(onLoadMore, 100);
          return (function () {
                    clearTimeout(timeoutId);
                  });
        }), [
        onLoadMore,
        shouldLoadMore
      ]);
  return [
          match.rootRef,
          match.elementRef
        ];
}

export {
  use ,
}
/* react Not a pure module */
