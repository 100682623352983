

import * as React from "react";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as JobEdit_StateTypes from "../JobEdit_StateTypes.mjs";
import * as Shared_Lib_Reducer from "../../../../../../shared/lib/Shared_Lib_Reducer.mjs";
import * as JobEdit_JobStateReducer from "./JobEdit_JobStateReducer.mjs";
import * as JobEdit_FormStateReducer from "./JobEdit_FormStateReducer.mjs";

function use(subroute, job) {
  var wire = AppContext.useWire();
  var initial = JobEdit_StateTypes.State.initial(subroute, job);
  var reducer = React.useCallback((function (param) {
          var action = param[1];
          var state = param[0];
          if (action.TAG === "Form") {
            return JobEdit_FormStateReducer.reducer(wire, subroute, state, action._0);
          } else {
            return JobEdit_JobStateReducer.reducer(state, action._0);
          }
        }), []);
  var match = Shared_Lib_Reducer.use(reducer, initial);
  return [
          match[0],
          match[1]
        ];
}

export {
  use ,
}
/* react Not a pure module */
