import React, { Component } from 'react'
import classNames from 'classnames'

export default class Spinner extends Component {
  spinTimer = null
  holdTimer = null

  handleAction = action => {
    const { step, value } = this.props
    switch (action) {
      case 'pgup':
      case 'inc':
        this.onChange(value + step)
        break
      case 'pgdn':
      case 'dec':
        this.onChange(value - step)
        break
    }
  }

  onKeyDown = evt => {
    const actions = {
      33: 'pgup',
      34: 'pgdn',
      38: 'inc',
      40: 'dec'
    }

    const action = actions[evt.keyCode]

    if (action) {
      evt.preventDefault()
      this.handleAction(action)
    }
  }

  onWheel = evt => {
    evt.preventDefault()
    this.handleAction(evt.deltaY < 0 ? 'inc' : 'dec')
  }

  onChange = value => {
    const { min, max, onValueChange } = this.props
    value = parseInt(value, 10)

    if (value >= min && value <= max) {
      onValueChange(value)
    }
  }

  stopSpinTimer = () => {
    if (this.spinTimer !== null) {
      clearInterval(this.spinTimer)
      this.spinTimer = null
    }
  }

  stopHoldTimer = () => {
    if (this.holdTimer !== null) {
      clearTimeout(this.holdTimer)
      this.holdTimer = null
    }
  }

  startSpinTimer = action => {
    this.stopSpinTimer()
    this.stopHoldTimer()

    this.spinTimer = setInterval(() => {
      this.handleAction(action)
    }, 75)
  }

  startHoldTimer = action => {
    this.stopHoldTimer()

    this.holdTimer = setTimeout(() => {
      this.startSpinTimer(action)
    }, 600)
  }

  onMouseUp = action => {
    if (!this.props.disabled) {
      if (this.holdTimer !== null) {
        this.handleAction(action)
      }

      this.stopHoldTimer()
      this.stopSpinTimer()
    }
  }

  onMouseOut = () => {
    this.stopHoldTimer()
    this.stopSpinTimer()
  }

  render() {
    const { classes, id, name, value } = this.props

    const wrapperClasses = classNames({
      spinner: true,
      ...classes
    })

    return (
      <div className={wrapperClasses}>
        <input
          id={id}
          name={name}
          value={value}
          autoComplete="off"
          type="number"
          className="spinner__input"
          onWheel={this.onWheel}
          onKeyDown={this.onKeyDown}
          disabled={this.props.disabled}
          onChange={evt => this.onChange(evt.target.value)}
        />

        <span
          className="spinner__arrow spinner__arrow_up"
          onMouseDown={() => this.startHoldTimer('inc')}
          onMouseUp={() => this.onMouseUp('inc')}
          onMouseOut={() => this.onMouseOut()}
        />

        <span
          className="spinner__arrow spinner__arrow_down"
          onMouseDown={() => this.startHoldTimer('dec')}
          onMouseUp={() => this.onMouseUp('dec')}
          onMouseOut={() => this.onMouseOut()}
        />
      </div>
    )
  }
}

Spinner.defaultProps = {
  id: '',
  name: '',
  value: 1,
  step: 1,
  min: 0,
  max: 1000,
  disabled: false,
  onValueChange: () => {}
}
