export const addLocation = location => {
  return {
    type: 'ADD_LOCATION',
    location
  }
}

export const removeLocation = location => {
  return {
    type: 'REMOVE_LOCATION',
    location
  }
}

export const highlightLocation = (location, measurementSystem, _item) => {
  return {
    type: 'HIGHLIGHT_LOCATION',
    location,
    measurementSystem
  }
}

export const removeLocationHighlight = location => {
  return {
    type: 'REMOVE_LOCATION_HIGHLIGHT',
    location
  }
}

export const addWorkerJob = job => {
  return {
    type: 'ADD_WORKER_JOB',
    job
  }
}

export const removeWorkerJob = job => {
  return {
    type: 'REMOVE_WORKER_JOB',
    job
  }
}

export const addJob = (job, options = {}) => {
  return {
    type: 'ADD_JOB',
    job,
    options
  }
}

export const removeJob = job => {
  return {
    type: 'REMOVE_JOB',
    job
  }
}

export const addWorker = (worker, location) => {
  return {
    type: 'ADD_WORKER',
    worker,
    location
  }
}

export const removeWorker = (worker, location) => {
  return {
    type: 'REMOVE_WORKER',
    worker,
    location
  }
}

export const locateWorker = (worker, location) => {
  return {
    type: 'LOCATE_WORKER',
    worker,
    location
  }
}

export const locateJob = job => {
  return {
    type: 'LOCATE_JOB',
    job
  }
}

export const locateWorkerJob = job => {
  return {
    type: 'LOCATE_WORKER_JOB',
    job
  }
}

export const showLocations = (block, job, initial) => {
  return {
    type: 'SHOW_LOCATIONS',
    block,
    job,
    initial
  }
}

export const highlightWorker = (worker, location) => {
  return {
    type: 'HIGHLIGHT_WORKER',
    worker,
    location
  }
}

export const removeWorkerHighlight = (worker, location) => {
  return {
    type: 'REMOVE_WORKER_HIGHLIGHT',
    worker,
    location
  }
}

export const updateContext = ctx => {
  return {
    type: 'UPDATE_CONTEXT',
    ctx
  }
}
