

import * as Fun from "../../../libraries/Fun.mjs";
import * as Url from "../../../bindings/url/Url.mjs";
import * as Hooks from "../../../libraries/hooks/Hooks.mjs";
import * as Modal from "../../../components/modal/Modal.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Title from "../../../components/common/Title.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Button from "../../../components/common/Button.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as DOMFile from "../../../libraries/DOMFile.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Labeled from "../../../components/inputs/Labeled.mjs";
import * as Spinner from "../../../components/common/Spinner/Spinner.mjs";
import * as PromiseF from "../../../libraries/PromiseF.mjs";
import * as FormInput from "../../../components/inputs/FormInput.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as OldFileInput from "../../../components/inputs/OldFileInput/OldFileInput.mjs";
import * as Backend_Errors from "../../../libraries/backend/Backend_Errors.mjs";
import * as MessengerTypes from "../../../components/messenger/MessengerTypes.mjs";
import * as Pages_Territories_API from "../Pages_Territories_API.mjs";
import * as Pages_Territories_RemoveWarning from "../remove_warning/Pages_Territories_RemoveWarning.mjs";
import * as Pages_Territories_TerritoryModalModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/territories/territory_modal/Pages_Territories_TerritoryModal.module.css";

var replace$p = Utils.Translations.tr("js.admin.territories.show.replace");

var name$p = Utils.Translations.t("js.admin.territories.show.name");

var layout$p = Utils.Translations.t("js.admin.territories.show.layout");

var limitMsg$p = Utils.Translations.t("js.admin.territories.show.limit_msg");

var delete$p = Utils.Translations.tr("common.remove");

var save$p = Utils.Translations.tr("common.save");

var add$p = Utils.Translations.tr("common.add");

var territoriesUpdated$p = Utils.Translations.t("js.admin.territories.messages.territories_updated");

var layoutInUse$p = Utils.Translations.txr("js.admin.territories.modal.layout_in_use");

var styles = Pages_Territories_TerritoryModalModuleCss;

function Pages_Territories_TerritoryModal$DownloadLink(Props) {
  var url = Props.url;
  var replaceFile = Props.replaceFile;
  var name = Props.name;
  var match = AppContext.useWire();
  return React.createElement("div", {
              className: styles.downloadLink
            }, React.createElement("a", {
                  download: "download",
                  href: Url.toString(url),
                  target: "_blank"
                }, name), replaceFile !== undefined ? React.createElement("div", undefined, React.createElement(Button.make, {
                        onClick: replaceFile,
                        children: replace$p(match.ctx)
                      })) : null);
}

function Pages_Territories_TerritoryModal$LayoutError(Props) {
  var wire = Props.wire;
  var territories = Props.territories;
  return React.createElement("div", {
              className: styles.error
            }, layoutInUse$p({
                  names: Utils.Translations.joinWithComma(territories.map(function (t) {
                            return t.name;
                          }))
                }, wire.ctx));
}

function Pages_Territories_TerritoryModal(Props) {
  var mode = Props.mode;
  var onAction = Props.onAction;
  var wire = AppContext.useWire();
  var navigate = wire.navigate;
  var route = wire.route;
  var subscriptions = wire.subscriptions;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        
      });
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setUsedBy = match$1[1];
  var usedBy = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setUsedLayouts = match$2[1];
  var usedLayouts = match$2[0];
  var match$3 = React.useState(function () {
        return Backend_Errors.empty;
      });
  var setErrors = match$3[1];
  var errors = match$3[0];
  var updateState = function (f) {
    setState(function (s) {
          if (s !== undefined) {
            return f(s);
          } else {
            return s;
          }
        });
  };
  var new_ = typeof mode === "object" ? false : true;
  var match$4 = React.useMemo((function () {
          if (state === undefined) {
            return [
                    undefined,
                    null
                  ];
          }
          if (new_) {
            return [
                    undefined,
                    null
                  ];
          }
          var values = {
            name: state.name
          };
          return [
                  Caml_option.some(values),
                  React.createElement(Title.make, {
                        route: route,
                        ctx: ctx,
                        values: values,
                        wire: wire
                      })
                ];
        }), [RCore.$$Option.isSome(state)]);
  var match$5 = Hooks.useLocked(false);
  var unlock = match$5[2];
  var lock = match$5[1];
  var locked = match$5[0];
  var territoryInUse = function (usedBy) {
    return Core__List.some(Core__List.map({
                    hd: usedBy.users,
                    tl: {
                      hd: usedBy.teams,
                      tl: {
                        hd: usedBy.clients,
                        tl: {
                          hd: usedBy.sites,
                          tl: {
                            hd: usedBy.jobs,
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }, (function (prim) {
                      return prim.length;
                    })), (function (x) {
                  return x > 0;
                }));
  };
  var showMessage = function (messageType, text) {
    subscriptions.messenger.trigger({
          TAG: "Show",
          _0: MessengerTypes.Message.make(undefined, messageType, {
                TAG: "Text",
                _0: text
              })
        });
  };
  var deleteTerritory = function (uuid) {
    var deleted = function (result) {
      unlock();
      if (result.TAG === "Ok") {
        onAction();
        showMessage("Success", territoriesUpdated$p(ctx));
        return navigate(undefined, {
                    NAME: "Territories",
                    VAL: "Index"
                  });
      }
      var errors = result._0;
      if (typeof errors !== "object") {
        return unlock();
      }
      if (errors.TAG !== "ValidationError") {
        return unlock();
      }
      var errors$1 = errors._0;
      unlock();
      setErrors(function (param) {
            return errors$1;
          });
    };
    lock();
    Backend.$$finally(Pages_Territories_API.Delete.$$delete(wire, uuid), deleted);
  };
  React.useEffect((function () {
          if (typeof mode === "object") {
            var loaded = function (r) {
              if (r.TAG !== "Ok") {
                return ;
              }
              var s = r._0;
              setState(function (param) {
                    return s;
                  });
            };
            Backend.$$finally(Pages_Territories_API.Show.show(wire, mode.VAL), (function (l) {
                    Utils.debounce1(loaded, 300, false)(l);
                  }));
          } else {
            setState(function (param) {
                  return {
                          name: undefined,
                          domFile: undefined,
                          file: undefined
                        };
                });
          }
        }), []);
  var fileId = Hooks.useId(undefined);
  var uploaded = function (result) {
    if (result.TAG === "Ok") {
      onAction();
      showMessage("Success", territoriesUpdated$p(ctx));
      return navigate(undefined, {
                  NAME: "Territories",
                  VAL: "Index"
                });
    }
    var errors = result._0;
    if (typeof errors !== "object") {
      return unlock();
    }
    if (errors.TAG !== "ValidationError") {
      return unlock();
    }
    var errors$1 = errors._0;
    unlock();
    setErrors(function (param) {
          return errors$1;
        });
  };
  var validateResponse = function (result) {
    if (result.TAG === "Ok") {
      var used = result._0;
      if (used.length !== 0) {
        unlock();
        return setUsedLayouts(function (param) {
                    return used;
                  });
      } else {
        lock();
        if (typeof mode === "object") {
          if (state !== undefined) {
            return Backend.$$finally(Pages_Territories_API.Update.update(wire, mode.VAL, state), uploaded);
          } else {
            return ;
          }
        } else if (state !== undefined) {
          return Backend.$$finally(Pages_Territories_API.Create.create(wire, state), uploaded);
        } else {
          return ;
        }
      }
    }
    var errors = result._0;
    if (typeof errors !== "object") {
      return unlock();
    }
    if (errors.TAG !== "ValidationError") {
      return unlock();
    }
    var errors$1 = errors._0;
    unlock();
    setErrors(function (param) {
          return errors$1;
        });
  };
  var onValidate = function (param) {
    lock();
    if (typeof mode === "object") {
      if (state !== undefined) {
        return Backend.$$finally(Pages_Territories_API.Validate.validate(wire, state, Caml_option.some(mode.VAL)), validateResponse);
      } else {
        return ;
      }
    } else if (state !== undefined) {
      return Backend.$$finally(Pages_Territories_API.Validate.validate(wire, state, undefined), validateResponse);
    } else {
      return ;
    }
  };
  var setFile = function (file) {
    updateState(function (s) {
          return {
                  name: s.name,
                  domFile: Caml_option.some(file),
                  file: s.file
                };
        });
    var __x = DOMFile.base64Content(file);
    PromiseF.$$finally((function (body) {
            updateState(function (s) {
                  return {
                          name: s.name,
                          domFile: s.domFile,
                          file: {
                            TAG: "New",
                            name: file.name,
                            body: body
                          }
                        };
                });
          }), (function (prim) {
            
          }), __x);
  };
  var clearFile = function () {
    updateState(function (s) {
          return {
                  name: s.name,
                  domFile: undefined,
                  file: undefined
                };
        });
  };
  var tmp = {};
  var tmp$1 = match$4[0];
  if (tmp$1 !== undefined) {
    tmp.values = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2;
  if (state !== undefined) {
    var tmp$3 = {
      _type: "input",
      value: RCore.$$Option.getOr(state.name, ""),
      onValueChange: (function (name) {
          updateState(function (s) {
                return {
                        name: Fun.optString(name),
                        domFile: s.domFile,
                        file: s.file
                      };
              });
        }),
      labelText: name$p(ctx),
      maxLength: 200,
      wrapperClass: Js_dict.fromList({
            hd: [
              styles.nameInput,
              true
            ],
            tl: /* [] */0
          }),
      autoFocus: true
    };
    var tmp$4 = Backend_Errors.get(errors, "name");
    if (tmp$4 !== undefined) {
      tmp$3.errors = Caml_option.valFromOption(tmp$4);
    }
    var match$6 = state.domFile;
    var match$7 = state.file;
    var tmp$5;
    var exit = 0;
    if (match$6 !== undefined || !(match$7 !== undefined && match$7.TAG !== "New")) {
      exit = 1;
    } else {
      tmp$5 = React.createElement(Pages_Territories_TerritoryModal$DownloadLink, {
            url: match$7.url,
            replaceFile: (function (param) {
                clearFile();
              }),
            name: match$7.name
          });
    }
    if (exit === 1) {
      var tmp$6 = {
        ctx: ctx,
        onFileChooseAction: setFile,
        onFileClearAction: clearFile,
        file: state.domFile,
        limit: [
          1048576,
          limitMsg$p(ctx)
        ]
      };
      var tmp$7 = RCore.$$Option.flatMap(Backend_Errors.getMany(errors, [
                "geojsonFile.name",
                "geojsonFile.body",
                "layout"
              ]), (function (a) {
              return a[0];
            }));
      if (tmp$7 !== undefined) {
        tmp$6.error = tmp$7;
      }
      tmp$5 = React.createElement(OldFileInput.make, tmp$6);
    }
    tmp$2 = React.createElement("form", undefined, React.createElement(FormInput.make, tmp$3), React.createElement(Labeled.make, {
              label: layout$p(ctx),
              id: fileId,
              children: tmp$5
            }), usedLayouts !== undefined ? React.createElement(Pages_Territories_TerritoryModal$LayoutError, {
                wire: wire,
                territories: usedLayouts
              }) : null);
  } else {
    tmp$2 = React.createElement(Spinner.make, {});
  }
  var tmp$8;
  if (typeof mode === "object") {
    var uuid = mode.VAL;
    tmp$8 = React.createElement(React.Fragment, undefined, React.createElement(Modal.RemoveButton.make, {
              onClick: (function (param) {
                  var useByResponse = function (data) {
                    unlock();
                    if (data.TAG !== "Ok") {
                      return ;
                    }
                    var entities = data._0;
                    if (territoryInUse(entities)) {
                      return setUsedBy(function (param) {
                                  return [
                                          uuid,
                                          entities
                                        ];
                                });
                    } else {
                      return deleteTerritory(uuid);
                    }
                  };
                  lock();
                  Backend.$$finally(Pages_Territories_API.UsedBy.get(wire, uuid), useByResponse);
                }),
              disabled: locked,
              children: delete$p(ctx)
            }));
  } else {
    tmp$8 = null;
  }
  return React.createElement(React.Fragment, undefined, match$4[1], React.createElement(Modal.make, {
                  wire: wire,
                  onClose: (function () {
                      navigate(undefined, {
                            NAME: "Territories",
                            VAL: "Index"
                          });
                    }),
                  children: React.createElement(Modal.Dialog.make, {
                        className: styles.dialog,
                        children: null
                      }, React.createElement(Modal.HeaderTitle.make, tmp), React.createElement(Modal.Body.make, {
                            children: React.createElement("div", {
                                  className: styles.editForm
                                }, tmp$2)
                          }), React.createElement(Modal.Footer.make, {
                            children: null
                          }, tmp$8, React.createElement(Modal.CloseButton.make, {
                                ctx: ctx
                              }), React.createElement(Modal.SubmitButton.make, {
                                onClick: onValidate,
                                disabled: locked,
                                children: new_ ? add$p(ctx) : save$p(ctx)
                              })))
                }), typeof mode === "object" ? React.createElement(React.Fragment, undefined, usedBy !== undefined ? React.createElement(Pages_Territories_RemoveWarning.make, {
                          wire: wire,
                          usedBy: usedBy[1],
                          onClose: (function () {
                              setUsedBy(function (param) {
                                    
                                  });
                            }),
                          onAccept: deleteTerritory,
                          uuid: usedBy[0]
                        }) : null) : null);
}

var make = Pages_Territories_TerritoryModal;

export {
  make ,
}
/* replace' Not a pure module */
