import {
  RESOLUTION_SET_FIELD,
  RESOLUTION_ADD,
  RESOLUTION_ADD_CANCEL,
  RESOLUTION_DELETE,
  RESOLUTION_EDIT,
  RESOLUTION_RESTORE,
  SEND_FORM_REQUEST,
  SEND_FORM_SUCCESS,
  SEND_FORM_FAIL
} from 'planado/actions/admin/resolutions'

const setResolutionField = (resolutions, resolutionId, name, value) => {
  const idx = resolutions.findIndex(x => x.resolutionId === resolutionId)

  if (idx === -1) {
    return resolutions
  } else {
    const oldValue = resolutions[idx]
    const initialValue =
      'initialValue' in oldValue ? oldValue.initialValue : oldValue

    let isCommentRequired
    if (name === 'hasComment' && value === false) {
      isCommentRequired = { isCommentRequired: false }
    } else {
      isCommentRequired = {}
    }

    return [
      ...resolutions.slice(0, idx),
      { ...oldValue, ...isCommentRequired, [name]: value, initialValue },
      ...resolutions.slice(idx + 1)
    ]
  }
}

const removeResolutionField = (resolutions, resolutionId) => {
  const idx = resolutions.findIndex(x => x.resolutionId === resolutionId)

  if (idx === -1) {
    return resolutions
  } else {
    return [...resolutions.slice(0, idx), ...resolutions.slice(idx + 1)]
  }
}

const restoreResolution = (resolutions, resolutionId) => {
  const idx = resolutions.findIndex(x => x.resolutionId === resolutionId)

  if (idx === -1) {
    return resolutions
  } else {
    const oldValue = resolutions[idx]
    const initialValue =
      'initialValue' in oldValue ? oldValue.initialValue : oldValue

    return [
      ...resolutions.slice(0, idx),
      { ...initialValue },
      ...resolutions.slice(idx + 1)
    ]
  }
}

export const reducer = (state = {}, action, _) => {
  switch (action.type) {
    case RESOLUTION_SET_FIELD: {
      return {
        ...state,
        resolutions: setResolutionField(
          state.resolutions,
          action.resolutionId,
          action.name,
          action.value
        )
      }
    }
    case RESOLUTION_DELETE: {
      return {
        ...state,
        resolutions: setResolutionField(
          state.resolutions,
          action.resolutionId,
          'destroy',
          true
        )
      }
    }
    case RESOLUTION_RESTORE: {
      return {
        ...state,
        resolutions: restoreResolution(state.resolutions, action.resolutionId)
      }
    }
    case RESOLUTION_ADD: {
      const resolutionId = state.resolutions.reduce(
        (id, curr) => (curr.resolutionId <= id ? curr.resolutionId - 1 : id),
        -1
      )

      return {
        ...state,
        resolutions: [
          ...state.resolutions,
          {
            resolutionId,
            name: null,
            isSuccessful: false,
            hasComment: false,
            isCommentRequired: false,
            isSystem: false,
            destroy: false
          }
        ]
      }
    }
    case RESOLUTION_ADD_CANCEL: {
      return {
        ...state,
        resolutions: removeResolutionField(
          state.resolutions,
          action.resolutionId
        )
      }
    }
    case RESOLUTION_EDIT: {
      return {
        ...state,
        resolutions: setResolutionField(
          state.resolutions,
          action.resolutionId,
          'isEditing',
          true
        )
      }
    }
    case SEND_FORM_REQUEST:
      return {
        ...state,
        formDisabled: true
      }
    case SEND_FORM_SUCCESS:
      return {
        ...state,
        formDisabled: false,
        resolutions: action.resolutions.map(r => ({ ...r, destroy: false }))
      }
    case SEND_FORM_FAIL:
      return {
        ...state,
        formDisabled: false,
        errors: action.errors
      }
    default: {
      return state
    }
  }
}

export default reducer
