

import * as Uuid from "../../../../../../bindings/uuid/Uuid.mjs";
import * as Types from "../../../../../../types/Types.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Optional from "../../../../../common/Optional.mjs";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as JobIndexFilter_Reset from "../../common/JobIndexFilter_Reset.mjs";
import * as JobIndexFilter_Wrapper from "../../common/JobIndexFilter_Wrapper.mjs";
import * as JobIndexFilter_Checkbox from "../../common/JobIndexFilter_Checkbox.mjs";

var label$p = Utils.Translations.t("js.jobs.index.filters.templates");

var selected$p = Utils.Translations.txr("js.jobs.index.selected");

var withoutTemplate$p = Utils.Translations.t("js.jobs.index.without_template");

function JobIndexFilter_Templates(Props) {
  var currentFilter = Props.currentFilter;
  var templates = Props.templates;
  var resetFilter = Props.resetFilter;
  var filterByTemplate = Props.filterByTemplate;
  var toggleFilter = Props.toggleFilter;
  var filterDisabled = Props.filterDisabled;
  var show = Props.show;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var showPreview = currentFilter.length > 0;
  var onResetClick = function (param) {
    resetFilter();
    toggleFilter({
            TAG: "System",
            _0: "Template"
          })(false);
  };
  var renderTemplates = templates.map(function (t) {
        var checked = currentFilter.some(function (f) {
              if (typeof f !== "object") {
                return false;
              } else {
                return Uuid.equal(f._0, t.uuid);
              }
            });
        return React.createElement(JobIndexFilter_Checkbox.make, {
                    name: t.name,
                    onToggle: (function (param) {
                        filterByTemplate({
                              TAG: "Uuid",
                              _0: t.uuid
                            }, !checked);
                      }),
                    checked: checked,
                    disabled: filterDisabled,
                    key: Types.Id.toString(t.id)
                  });
      });
  var renderWithoutTerritory = React.createElement(JobIndexFilter_Checkbox.make, {
        name: withoutTemplate$p(ctx),
        onToggle: (function (param) {
            filterByTemplate("WithoutTemplate", !Js_array.includes("WithoutTemplate", currentFilter));
          }),
        checked: Js_array.includes("WithoutTemplate", currentFilter),
        disabled: filterDisabled
      });
  return React.createElement(JobIndexFilter_Wrapper.make, {
              toggleFilter: toggleFilter({
                    TAG: "System",
                    _0: "Template"
                  }),
              show: show,
              label: label$p(ctx),
              children: null
            }, React.createElement(Optional.make, {
                  show: showPreview,
                  children: React.createElement(JobIndexFilter_Reset.make, {
                        onResetClick: onResetClick
                      })
                }), React.createElement("div", {
                  className: "j-filter__list"
                }, renderTemplates, renderWithoutTerritory), React.createElement(Optional.make, {
                  show: showPreview,
                  children: React.createElement("div", {
                        className: "j-filter__selected"
                      }, selected$p({
                            count: currentFilter.length
                          }, ctx))
                }));
}

var make = JobIndexFilter_Templates;

export {
  make ,
}
/* label' Not a pure module */
