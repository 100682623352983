

import * as El from "../../libraries/El.mjs";
import * as Icon from "../../Icon.mjs";
import * as Route from "../../types/Route.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";

var title$p = Utils.Translations.tr("js.teams.title.index");

var add$p = Utils.Translations.tr("js.teams.index.add");

function TeamIndexHeader(Props) {
  var ctx = Props.ctx;
  var openNewTeam = Props.openNewTeam;
  var match = ctx.permissions;
  return React.createElement("div", {
              className: "page-header"
            }, React.createElement("div", {
                  className: "row item-header"
                }, React.createElement("div", {
                      className: "col-lg-12"
                    }, React.createElement("h1", {
                          className: "text-center text-left-sm"
                        }, title$p(ctx)), match !== undefined && match.teams === "ViewEdit" ? React.createElement("a", {
                            className: "add-link item-add-button btn btn-primary btn-outline",
                            href: Route.pagePath(undefined, {
                                  NAME: "Team",
                                  VAL: "New"
                                }),
                            onClick: openNewTeam
                          }, React.createElement("i", {
                                className: Icon.style(undefined, undefined, "plus")
                              }), El.space, add$p(ctx)) : null)));
}

var make = TeamIndexHeader;

export {
  make ,
}
/* title' Not a pure module */
