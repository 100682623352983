import React, { Fragment, Component } from 'react'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import LocationPopup from './popup'
import Modal from 'rscrpt/components/modal/Modal.mjs'

class Location extends Component {
  state = {
    showPopup: false,
  }

  togglePopup = (_) =>
    this.setState(({ showPopup }, _props) => ({ showPopup: !showPopup }))

  get popup() {
    const {
      id,
      isResolved,
      formatted,
      geolocation,
      tempGeolocation,
      saveLocation,
      xhr,
      readOnly,
      wire,
    } = this.props

    const { mapType, mapApiKey } = wire.ctx

    const content = (
      <LocationPopup
        id={id}
        readOnly={readOnly}
        saveLocation={saveLocation}
        isResolved={isResolved}
        geolocation={geolocation}
        tempGeolocation={tempGeolocation}
        formatted={formatted}
        xhr={xhr}
        mapType={mapType.toLowerCase()}
        mapApiKey={mapApiKey}
        onClose={this.togglePopup}
      />
    )

    return (
      <Modal wire={wire} show={this.state.showPopup} onClose={this.togglePopup}>
        {content}
      </Modal>
    )
  }

  get link() {
    const { isResolved, formatted, geolocation, ctx, readOnly } = this.props
    if (isResolved) {
      return (
        <button
          type="button"
          className="btn-link pd-form-location pd-form-location_coords"
          onClick={this.togglePopup}
        >
          {
            [
              parseFloat(geolocation.latitude).toFixed(6),
              parseFloat(geolocation.longitude).toFixed(6)
            ].join(', ')
          }
        </button>
      )
    } else if (!readOnly && formatted && formatted.length > 2) {
      return (
        <button
          type="button"
          className="btn-link pd-form-location"
          onClick={this.togglePopup}
        >
          {ctx.t('js.address_input.show_on_map')}
        </button>
      )
    } else {
      return null
    }
  }

  render = () => (
    <Fragment>
      {this.link}
      {this.popup}
    </Fragment>
  )
}

export default withContext(Location)
