

import * as React from "react";

var context = React.createContext();

function useAuth() {
  return React.useContext(context);
}

function useSocket() {
  return useAuth().socket;
}

var provider = context.Provider;

var Provider = {
  provider: provider
};

function Context_Auth(Props) {
  var value = Props.value;
  var children = Props.children;
  return React.createElement(provider, {
              value: value,
              children: children
            });
}

var make = Context_Auth;

export {
  useAuth ,
  useSocket ,
  Provider ,
  make ,
}
/* context Not a pure module */
