export const BLANK_ADDRESS = {
  streetNumber: null,
  route: null,
  sublocality: null,
  locality: null,
  subadministrativeArea: null,
  administrativeArea: null,
  postalCode: null,
  extId: null,
  geolocation: null,
  geolocationResolved: false,
  formatted: null,
  formattedFull: null,
  country: null,
  source: null
}
