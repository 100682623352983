import React, { Component } from 'react'
import { stringLengthWithNewLines } from 'planado/utils/index.js'
import {
  hasAssigneeWithRequiredSkills,
  hasAssigneeWithRequiredTerritory,
} from 'planado/utils/possible_assignees'
import FormInput from 'planado/components/input/form_input'
import { Select } from 'planado/components/input'
import DurationSpinner from 'planado/components/input/duration_spinner'
import CustomFieldsLink from 'planado/containers/admin/templates/custom_fields_link'
import Hint from 'planado/components/hint'
import { INPUT_MAX_LENGTH } from 'planado/constants/admin/templates'
import { SkillsSelect } from 'planado/components/input/skills_select'
import { TerritoriesSelect } from 'planado/components/input/territories_select'
import MultipleAssignees from 'rscrpt/components/common/MultipleAssignees/MultipleAssignees.mjs'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import * as styles from './styles.module.css'

class JobInfo extends Component {
  constructor(props) {
    super(props)

    this.state = {
      canValidate: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors.template.name.length > 0) {
      this.setState({
        canValidate: true,
      })
    }
  }

  blurHandler() {
    const {
      template: { name, uuid, isNewRecord },
      checkTemplateName,
      ctx,
    } = this.props

    if (name && name.trim().length > 0) {
      checkTemplateName(ctx, name.trim(), uuid, isNewRecord)
    }
  }

  changeHandler(value) {
    const {
      template: { uuid, isNewRecord },
      setField,
      setError,
      checkTemplateName,
      ctx
    } = this.props

    setField('name', value)

    value = value.trim()
    if (this.state.canValidate && value && value.length > 0) {
      checkTemplateName(ctx, value, uuid, isNewRecord)
    } else if (this.state.canValidate) {
      setError('template', {
        name: Array.of(ctx.t('js.templates.info.errors.name_empty')),
      })
    }
  }

  descriptionChangeHandler(value) {
    const { limits, setField } = this.props
    if (stringLengthWithNewLines(value) <= limits.description) {
      setField('description', value)
    }
  }

  warnNoAssigneeWithRequiredSkills() {
    const { possibleAssignees, skillUuids, ctx } = this.props
    const show = !hasAssigneeWithRequiredSkills(possibleAssignees, skillUuids)

    return (
      show && (
        <span className={styles.msgWarn}>
          {ctx.t('js.templates.messages.no_assignees_with_skills')}
        </span>
      )
    )
  }

  warnNoAssigneesOnTerritory() {
    const { possibleAssignees, availableTerritories, territoryUuid, ctx } =
      this.props

    const hasAssigneeOnTerritory = hasAssigneeWithRequiredTerritory(
      possibleAssignees,
      territoryUuid,
      availableTerritories
    )

    if (hasAssigneeOnTerritory || territoryUuid === null) {
      return null
    } else {
      return (
        <span className={styles.msgWarn}>
          {ctx.t('js.templates.messages.no_assignees_on_territory')}
        </span>
      )
    }
  }

  render() {
    const {
      inputId,
      template,
      types,
      setField,
      changeSkills,
      possibleAssignees,
      errors,
      customFields,
      limits,
      ctx,
      skillUuids,
      territoryUuid,
      changeTerritory,
      availableTerritories,
      setAssignees,
    } = this.props

    const numberOfFields = customFields.filter(
      (field) => !field.isDestroyed
    ).length

    const typeOptions = types.map(({ code, refId }) => ({
      label: code,
      value: refId,
    }))

    return (
      <section className="form-section">
        <div className="form-container">
          <FormInput
            id={inputId('name')}
            labelText={ctx.t('js.templates.info.labels.name')}
            name="template[name]"
            type="text"
            autoComplete="off"
            value={template.name || ''}
            onValueChange={this.changeHandler.bind(this)}
            onBlur={this.blurHandler.bind(this)}
            errors={errors.template.name}
            maxLength={INPUT_MAX_LENGTH}
          >
            <Hint
              content={ctx.t('js.templates.info.hints.name')}
              modifiers={{ hint_label: true }}
            />
          </FormInput>

          <div className="pd-form-group">
            <label className="pd-label">
              {ctx.t('js.templates.info.labels.type')}
            </label>

            <Select
              autosize={false}
              name="template[type_id]"
              placeholder={ctx.t('js.templates.info.placeholders.type')}
              onChange={(refId) => setField('typeId', refId)}
              value={template.typeId}
              options={typeOptions}
            />
          </div>

          <FormInput
            id={inputId('description')}
            name="template[description]"
            value={template.description || ''}
            onValueChange={(value) => this.descriptionChangeHandler(value)}
            type="textarea"
            maxLength={limits.description}
            labelText={ctx.t('js.templates.info.labels.description')}
          />

          <div className="pd-form-group">
            <label htmlFor={inputId('duration')} className="pd-label">
              {ctx.t('js.templates.info.labels.duration')}
            </label>

            <DurationSpinner
              id={inputId('duration')}
              min={5}
              max={template.maxScheduledDuration}
              step={5}
              name="template[scheduled_duration][]"
              defaultValue={template.scheduledDuration / 60 || 60}
              onValueChange={(value) => setField('scheduledDuration', value)}
            />
          </div>

          <div className="pd-form-group">
            <label className="pd-label">
              {ctx.t('js.templates.info.labels.assignee_skills')}
            </label>
            <Hint content={ctx.t('js.jobs.hints.skills')} />
            <SkillsSelect
              inputName="template[skills]"
              selectedSkillUuids={skillUuids}
              availableSkills={ctx.availableSkills}
              onChange={(uuids) => changeSkills(uuids, possibleAssignees)}
              placeholder={ctx.t('js.components.skills_select.placeholder')}
            />
            {this.warnNoAssigneeWithRequiredSkills()}
          </div>

          {ctx.features.flags.includes('territories') && (
            <div className="pd-form-group">
              <label className="pd-label">
                {ctx.t('js.templates.info.labels.territory')}
              </label>
              <Hint content={ctx.t('js.jobs.hints.territories')} />
              <TerritoriesSelect
                id="territory-select"
                inputName="template[territory]"
                selectedTerritoryUuid={territoryUuid}
                availableTerritories={availableTerritories}
                onChange={(uuid) => changeTerritory(uuid)}
                placeholder={ctx.t(
                  'js.components.territories_select.placeholder'
                )}
              />
              {this.warnNoAssigneesOnTerritory()}
            </div>
          )}

          <div className="pd-form-group">
            <label className="pd-label">
              {ctx.t('js.templates.info.labels.assignee_subject')}
            </label>

            <MultipleAssignees
              selectedAssignees={template.assignees}
              territoryUuid={territoryUuid}
              skillUuids={skillUuids}
              availableSkills={ctx.availableSkills}
              disabled={false}
              onChange={setAssignees}
            />
          </div>

          <h2 className="form-section__title">
            {ctx.t('js.templates.info.titles.custom_fields')}
            {numberOfFields === 0 ? null : (
              <Hint
                content={ctx.t('js.templates.info.hints.custom_fields')}
                modifiers={{ hint_title: true }}
              />
            )}
          </h2>

          <CustomFieldsLink />
        </div>
      </section>
    )
  }
}

export default withContext(({ ctx, ...props }) => (
  <JobInfo {...props} ctx={ctx} />
))
