

import * as Link from "../../../../common/Link/Link.mjs";
import * as Units from "../../../../../types/Units.mjs";
import * as Utils from "../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as Schedule_State from "../../../lib/state/Schedule_State.mjs";
import * as Schedule_TimelineEmptyModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/timeline/empty/Schedule_TimelineEmpty.module.css";

function Schedule_TimelineEmpty$Icon(Props) {
  return React.createElement("svg", {
              height: "28",
              width: "40",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M38.156 19.325a7.332 7.332 0 000-2.662l1.613-.932a.453.453 0 00.206-.531 9.38 9.38 0 00-2.075-3.587.456.456 0 00-.563-.088l-1.612.931a7.338 7.338 0 00-2.306-1.331V9.262c0-.212-.15-.4-.356-.443a9.43 9.43 0 00-4.138 0 .455.455 0 00-.356.443v1.863c-.844.3-1.625.75-2.306 1.331l-1.613-.931a.457.457 0 00-.563.088 9.38 9.38 0 00-2.075 3.587.46.46 0 00.207.531l1.612.932a7.332 7.332 0 000 2.662l-1.612.931a.452.452 0 00-.207.532 9.426 9.426 0 002.075 3.587.456.456 0 00.563.087l1.613-.93a7.339 7.339 0 002.306 1.33v1.863c0 .212.15.4.356.444a9.43 9.43 0 004.137 0 .455.455 0 00.357-.444v-1.863a7.34 7.34 0 002.306-1.33l1.612.93a.457.457 0 00.563-.087 9.38 9.38 0 002.075-3.587.46.46 0 00-.206-.532l-1.613-.931zM31 21.025a3.034 3.034 0 01-3.031-3.031A3.038 3.038 0 0131 14.963a3.038 3.038 0 013.031 3.03A3.034 3.034 0 0131 21.026zM6 11.994c2.206 0 4-1.794 4-4 0-2.207-1.794-4-4-4s-4 1.793-4 4c0 2.206 1.794 4 4 4zm14 2c.119 0 .231-.031.35-.038a11.521 11.521 0 012.269-3.7 2.463 2.463 0 011.806-.787c.431 0 .856.112 1.225.331l.494.287c.05-.03.1-.056.15-.087.437-.912.7-1.925.7-3 0-3.869-3.131-7-7-7A6.986 6.986 0 0013 6.994c0 3.869 3.131 7 7 7zm6.575 12.156c-.144-.075-.287-.162-.425-.244-.512.3-.956.613-1.719.613a2.484 2.484 0 01-1.806-.788 11.397 11.397 0 01-2.512-4.35c-.67-2.156 1.556-3.106 1.612-3.143a6.39 6.39 0 010-.488l-.494-.288a2.547 2.547 0 01-.612-.506c-.206.013-.407.038-.613.038-1.537 0-2.975-.375-4.281-1h-.519A7.203 7.203 0 008 23.194v1.8a3 3 0 003 3h15.962c-.23-.375-.387-.8-.387-1.269v-.575zM10.819 15.156A3.988 3.988 0 008 13.994H4c-2.206 0-4 1.793-4 4v2c0 1.106.894 2 2 2h4.119a9.165 9.165 0 014.7-6.838z",
                  fill: "#dbe0e9"
                }));
}

var noAssignees$p = Utils.Translations.tr("js.schedule.v2.jobs.no_assignees");

var noAssigneesLink$p = Utils.Translations.tr("js.schedule.v2.jobs.no_assignees_link");

var noAssigneesByTerritories$p = Utils.Translations.tr("js.schedule.v2.jobs.no_assignees_by_territories");

var noAssigneesBySkills$p = Utils.Translations.tr("js.schedule.v2.jobs.no_assignees_by_skills");

var noAssigneesBySkillsAndTerritories$p = Utils.Translations.tr("js.schedule.v2.jobs.no_assignees_by_multiple_filters");

var styles = Schedule_TimelineEmptyModuleCss;

function Schedule_TimelineEmpty(Props) {
  var width = Props.width;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var filter = Schedule_State.useFilter();
  var title = Caml_obj.notequal(filter.territories, []) && Caml_obj.notequal(filter.skills.uuids, []) ? noAssigneesBySkillsAndTerritories$p(ctx) : (
      Caml_obj.notequal(filter.skills.uuids, []) ? noAssigneesBySkills$p(ctx) : (
          Caml_obj.notequal(filter.territories, []) ? noAssigneesByTerritories$p(ctx) : noAssignees$p(ctx)
        )
    );
  var style = {
    width: Units.Px.toString(width)
  };
  return React.createElement("div", {
              className: styles.container,
              style: style
            }, React.createElement(Schedule_TimelineEmpty$Icon, {}), React.createElement("div", {
                  className: styles.title
                }, title), React.createElement(Link.make, {
                  wire: wire,
                  route: {
                    NAME: "User",
                    VAL: "Index"
                  },
                  className: styles.link,
                  children: noAssigneesLink$p(ctx)
                }));
}

var make = React.memo(Schedule_TimelineEmpty);

export {
  make ,
}
/* noAssignees' Not a pure module */
