

import * as Radio from "../../../../../../inputs/Radio.mjs";
import * as Types from "../../../../../../../types/Types.mjs";
import * as Utils from "../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Tooltip from "../../../../../../common/Tooltip/Tooltip.mjs";
import * as AppContext from "../../../../../../../context/AppContext.mjs";
import * as Schedule_State from "../../../../../lib/state/Schedule_State.mjs";
import * as Schedule_Header_AssigneesFilterModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/header/right/settings/assignees_filter/Schedule_Header_AssigneesFilter.module.css";

var styles = Schedule_Header_AssigneesFilterModuleCss;

var label$p = Utils.Translations.tr("js.schedule.v2.settings.assignees_filter.label");

var all$p = Utils.Translations.t("js.schedule.v2.settings.assignees_filter.all");

var teams$p = Utils.Translations.t("js.schedule.v2.settings.assignees_filter.teams");

var hint$p = Utils.Translations.t("js.schedule.v2.settings.assignees_filter.hint");

function Schedule_Header_AssigneesFilter(Props) {
  var wire = AppContext.useWire();
  var assigneesFilter = Schedule_State.useAssigneesFilter();
  var setAssigneesFilter = Schedule_State.useSetAssigneesFilter();
  var hintRef = Types.ReactRef.use();
  return React.createElement(React.Fragment, undefined, React.createElement("label", {
                  className: styles.label
                }, label$p(wire.ctx), React.createElement(Tooltip.make, {
                      elementRef: hintRef,
                      position: "top",
                      content: hint$p(wire.ctx),
                      children: React.createElement("span", {
                            ref: hintRef,
                            className: styles.hint
                          })
                    })), React.createElement(Radio.make, {
                  onChange: (function (_e) {
                      setAssigneesFilter("All");
                    }),
                  checked: assigneesFilter === "All",
                  labelText: all$p(wire.ctx)
                }), React.createElement(Radio.make, {
                  onChange: (function (_e) {
                      setAssigneesFilter("Teams");
                    }),
                  checked: assigneesFilter === "Teams",
                  labelText: teams$p(wire.ctx)
                }));
}

var make = Schedule_Header_AssigneesFilter;

export {
  make ,
}
/* styles Not a pure module */
