// extracted by mini-css-extract-plugin
export var address = "address-EtVSMrA6AXI0gSBtAN6r";
export var assignee = "assignee-IN8DWXdUujwykZBR4_vN";
export var dragJob = "drag-job-mHsEps0S9nJNjzqdqitl";
export var duration = "duration-iDf2UlKOZhGuZvgRxj5A";
export var finished = "finished-wDNu8J49u8R4Guj9PIWx";
export var info = "info-hLJ0LDtDt4EmMJTKgMFt";
export var job = "job-QomQsdsnzPsEWiSmayZg";
export var noAssignee = "no-assignee-BqjaKF4s75pkAlqQSqk5";
export var successful = "successful-G9CThxsxioWtwNnS3YeJ";
export var template = "template-bOvaS5SIe3ci6EBShEHD";
export var unsuccessful = "unsuccessful-FiRq7mLxD8MJNw7IVp5q";