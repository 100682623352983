const getElementCoordinates = elem => {
  const box = elem.getBoundingClientRect()

  return {
    top: box.top + pageYOffset,
    left: box.left + pageXOffset
  }
}

export const scrollToElement = (element, offsetTop) => {
  const { top, left } = getElementCoordinates(element)

  window.scrollTo({
    top: top + offsetTop,
    left: left,
    behavior: 'smooth'
  })
}
