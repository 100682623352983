

import * as Resolutions from "planado/containers/admin/resolutions";

var make = Resolutions.ResolutionList;

export {
  make ,
}
/* make Not a pure module */
