

import * as Note from "../../../components/common/Note/Note.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../../components/common/Optional.mjs";
import * as AppContext from "../../../context/AppContext.mjs";

function clientsCreated$p(count, ctx) {
  return Utils.Translations.tx("js.clients.upload.clients_created")({
              count: count
            }, ctx);
}

function clientsUpdated$p(count, ctx) {
  return Utils.Translations.tx("js.clients.upload.clients_updated")({
              count: count
            }, ctx);
}

function jobsCreated$p(count, ctx) {
  return Utils.Translations.tx("js.clients.upload.jobs_created")({
              count: count
            }, ctx);
}

var uploadResults$p = Utils.Translations.tr("js.clients.upload.upload_results");

var internalError$p = Utils.Translations.tr("js.clients.upload.internal_error");

var inProgress$p = Utils.Translations.tr("js.clients.upload.upload_in_progress");

function Pages_Clients_Upload_Results$Result(Props) {
  var state = Props.state;
  var ctx = Props.ctx;
  var match = state.sendingSummary;
  if (match !== undefined) {
    var clientsCreated = match.clientsCreated;
    if (clientsCreated !== undefined) {
      var clientsUpdated = match.clientsUpdated;
      if (clientsUpdated !== undefined) {
        var jobsCreated = match.jobsCreated;
        if (jobsCreated !== undefined && state.sendingSuccess) {
          return React.createElement(Note.make, {
                      children: null,
                      flavor: "success"
                    }, uploadResults$p(ctx), React.createElement("br", undefined), clientsCreated$p(clientsCreated, ctx), React.createElement("br", undefined), clientsUpdated$p(clientsUpdated, ctx), React.createElement("br", undefined), React.createElement(Optional.make, {
                          show: jobsCreated > 0,
                          children: jobsCreated$p(jobsCreated, ctx)
                        }));
        }
        
      }
      
    }
    
  }
  return React.createElement(Note.make, {
              children: internalError$p(ctx),
              flavor: "danger"
            });
}

function Pages_Clients_Upload_Results(Props) {
  var state = Props.state;
  var ctx = AppContext.useCtx();
  if (state.sendingFile) {
    return React.createElement("div", undefined, React.createElement(Note.make, {
                    children: inProgress$p(ctx),
                    flavor: "info"
                  }));
  } else {
    return React.createElement(Pages_Clients_Upload_Results$Result, {
                state: state,
                ctx: ctx
              });
  }
}

var make = Pages_Clients_Upload_Results;

export {
  make ,
}
/* uploadResults' Not a pure module */
