

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppContext from "../../../../../../../context/AppContext.mjs";
import * as Schedule_DND from "../../../../../lib/Schedule_DND.mjs";
import * as Schedule_Types_Job from "../../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as WeekCalendar_Constants from "../../../lib/WeekCalendar_Constants.mjs";

function use(job) {
  var wire = AppContext.useWire();
  var draggableJob_uuid = Schedule_Types_Job.ScheduledJob.uuid(job);
  var draggableJob_template = Schedule_Types_Job.ScheduledJob.template(job);
  var draggableJob_assignees = Schedule_Types_Job.ScheduledJob.assignees(job);
  var draggableJob_address = Schedule_Types_Job.ScheduledJob.address(job);
  var draggableJob_scheduledAt = Schedule_Types_Job.ScheduledJob.scheduledAt(job);
  var draggableJob_duration = Schedule_Types_Job.ScheduledJob.duration(job);
  var draggableJob = {
    uuid: draggableJob_uuid,
    template: draggableJob_template,
    bar: undefined,
    assignees: draggableJob_assignees,
    address: draggableJob_address,
    scheduledAt: draggableJob_scheduledAt,
    duration: draggableJob_duration
  };
  return Schedule_DND.DND.Draggable.use(undefined, Schedule_Types_Job.ScheduledJob.hasRightsToEdit(job, wire), Schedule_DND.DND.Context.makePayload(draggableJob), Caml_obj.notequal(Schedule_Types_Job.ScheduledJob.assignees(job), []) ? WeekCalendar_Constants.dndScheduledJob : WeekCalendar_Constants.dndUnassignedJob, undefined);
}

export {
  use ,
}
/* AppContext Not a pure module */
