

import * as RCore from "../../../libraries/RCore.mjs";
import * as React from "react";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pages_Clients_API from "./Pages_Clients_API.mjs";
import * as Pages_Clients_Table from "./Pages_Clients_Table.mjs";
import * as Pages_Clients_Types from "./Pages_Clients_Types.mjs";

function Pages_Clients$StatefulComponent(Props) {
  var state = Props.state;
  var setState = Props.setState;
  var setCounts = Props.setCounts;
  var wire = AppContext.useWire();
  var refreshClients = function (concatClientsOpt, filter) {
    var concatClients = concatClientsOpt !== undefined ? concatClientsOpt : true;
    setState(function (state) {
          return {
                  fetching: true,
                  clients: state.clients,
                  totalClients: state.totalClients,
                  totalSites: state.totalSites,
                  filter: state.filter,
                  isAdmin: state.isAdmin
                };
        });
    Backend.$$finally(Pages_Clients_API.get(wire, filter), (function (response) {
            if (response.TAG !== "Ok") {
              return ;
            }
            var match = response._0;
            var isAdmin = match.isAdmin;
            var totalSites = match.totalSites;
            var totalClients = match.totalClients;
            var clients = match.clients;
            setCounts(totalClients, totalSites);
            setState(function (state) {
                  return {
                          fetching: false,
                          clients: concatClients ? Belt_Array.concatMany([
                                  state.clients,
                                  clients
                                ]) : clients,
                          totalClients: totalClients,
                          totalSites: totalSites,
                          filter: RCore.$$Option.getOr(filter, Pages_Clients_Types.Filter.initial),
                          isAdmin: isAdmin
                        };
                });
          }));
  };
  React.useEffect((function () {
          return wire.subscriptions.clients.subscribe(function ($$event) {
                      refreshClients(false, undefined);
                    });
        }), [
        state,
        wire
      ]);
  return React.createElement(Pages_Clients_Table.make, {
              state: state,
              refreshClients: refreshClients
            });
}

function Pages_Clients(Props) {
  var setCounts = Props.setCounts;
  var wire = AppContext.useWire();
  var match = React.useState(function () {
        
      });
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          Backend.$$finally(Pages_Clients_API.get(wire, undefined), (function (response) {
                  if (response.TAG !== "Ok") {
                    return ;
                  }
                  var match = response._0;
                  var isAdmin = match.isAdmin;
                  var totalSites = match.totalSites;
                  var totalClients = match.totalClients;
                  var clients = match.clients;
                  setCounts(totalClients, totalSites);
                  setState(function (param) {
                        return {
                                fetching: false,
                                clients: clients,
                                totalClients: totalClients,
                                totalSites: totalSites,
                                filter: Pages_Clients_Types.Filter.initial,
                                isAdmin: isAdmin
                              };
                      });
                }));
        }), []);
  if (state === undefined) {
    return null;
  }
  var setState$1 = function (cb) {
    setState(function (param) {
          return cb(state);
        });
  };
  return React.createElement(Pages_Clients$StatefulComponent, {
              state: state,
              setState: setState$1,
              setCounts: setCounts
            });
}

var make = Pages_Clients;

export {
  make ,
}
/* react Not a pure module */
