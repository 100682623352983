import React from 'react'
import { validateForm, scrollToError, formHasError } from './validators'
import JobInfo from './job_info'
import JobReport from './job_report'
import JobCatalog from './job_catalog'
import RelatedEntities from 'rscrpt/components/common/RelatedEntities/RelatedEntities.mjs'
import {
  ListType,
  PageType,
} from 'rscrpt/components/common/RelatedEntities/RelatedEntitiesTypes.mjs'
import Settings from 'rscrpt/components/admin/template/Template_Settings.mjs'
import TemplateFooter from 'rscrpt/components/admin/template/Template_Footer.mjs'
import TemplateHeading from 'rscrpt/components/admin/template/Template_Heading.mjs'
import TemplateResolutions from 'rscrpt/components/admin/template/Template_Resolutions.mjs'
import { withContext } from 'planado/utils/contextConsumer.jsx'

export default withContext(
  ({
    template,
    types,
    possibleAssignees,
    actions,
    resolutions,
    possibleResolutionIds,
    customFields,
    errors,
    reportFields,
    wire,
    limits = {},
    ctx,
    deleteTemplateRe,
    onSubmitTemplateRe,
    showServerErrorMsgRe,
    showFormErrorMsgRe,
    redirectToIndexRe,
    availableTerritories,
    territoryUuid,
    skillUuids,
    formDisabled,
    foremanError
  }) => {
    const inputId = (name) => `template-form-input-${name}`

    const sumbitHandler = (e) => {
      const updatedErrors = validateForm(
        errors,
        template,
        customFields,
        reportFields,
        resolutions,
        actions,
        ctx
      )

      e.preventDefault()

      if (formHasError(updatedErrors)) {
        scrollToError(updatedErrors)
        showFormErrorMsgRe()
      } else if (!formDisabled) {
        actions.submitTemplate({
          onSuccess: onSubmitTemplateRe,
          onFailure: showServerErrorMsgRe,
          onNotFound: redirectToIndexRe,
          ctx,
        })
      }
    }

    return (
      <div>
        <form
          className="form form_template"
          method="post"
          onSubmit={sumbitHandler}
          id={
            template.isNewRecord
              ? 'new_template'
              : `edit_template_${template.uuid}`
          }
        >
          <TemplateHeading
            name={template.name || ''}
            isNewRecord={template.isNewRecord}
          />

          <JobInfo
            customFields={customFields}
            inputId={inputId}
            template={template}
            types={types}
            possibleAssignees={possibleAssignees}
            setField={actions.setField}
            setError={actions.setError}
            changeSkills={actions.changeSkills}
            checkTemplateName={actions.checkTemplateName}
            errors={errors}
            limits={limits}
            skillUuids={skillUuids}
            territoryUuid={territoryUuid}
            availableTerritories={availableTerritories}
            changeTerritory={actions.changeTerritory}
            setAssignees={actions.setAssignees}
          />

          <JobReport />

          {ctx.features.flags.includes('catalog') &&
            <JobCatalog
              setServices={actions.setServices}
              setCategories={actions.setCategories}
            />
          }

          <Settings
            wire={wire}
            trackMovements={template.trackMovements}
            setTrackMovements={actions.setTrackMovements}
            canBeUsedOnMobile={template.canBeUsedOnMobile}
            setCanBeUsedOnMobile={actions.setCanBeUsedOnMobile}
          />

          <TemplateResolutions
            wire={wire}
            resolutions={resolutions}
            errors={errors.resolutions}
            possibleResolutionIds={possibleResolutionIds}
            moveResolution={actions.moveResolution}
            deleteResolution={actions.deleteResolution}
            setError={actions.setError}
            addResolutions={actions.addResolutions}
          />

          <TemplateFooter
            isNewRecord={template.isNewRecord}
            uuid={template.uuid}
            recurringJobs={template.recurringJobs}
            formDisabled={formDisabled}
            foremanError={foremanError}
            deleteTemplate={deleteTemplateRe}
          />
        </form>

        {template.isNewRecord ? null : (
          <RelatedEntities
            uuid={template.uuid}
            listType={ListType.fromString('jobs_and_rec')}
            pageType={PageType.fromString('template')}
            wire={wire}
          />
        )}
      </div>
    )
  }
)
