export const jobLimits = {
  name: 50,
  description: 15000,
  mobileNumber: 50,
  addressDescription: 500,
  apartment: 50,
  floor: 50,
  customField: 200,
  externalIdInput: 500,
  entranceNo: 50
}
