

import * as RCore from "../../../../../../../../libraries/RCore.mjs";
import * as React from "react";
import * as Schedule_State from "../../../../../../lib/state/Schedule_State.mjs";
import * as Schedule_UtilsBusinessHours from "../../../../../../lib/utils/Schedule_UtilsBusinessHours.mjs";
import * as DayTimeline_BarModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/day_timeline/components/timeline/bar/DayTimeline_Bar.module.css";

var styles = DayTimeline_BarModuleCss;

function DayTimeline_BarCells(Props) {
  var businessHours = Schedule_State.useBusinessHours();
  return RCore.$$Array.make(Schedule_UtilsBusinessHours.hoursInDay(businessHours), undefined).map(function (param, idx) {
              return React.createElement("div", {
                          key: idx.toString(),
                          className: styles.cell
                        });
            });
}

var make = React.memo(DayTimeline_BarCells);

export {
  make ,
}
/* styles Not a pure module */
