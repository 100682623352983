

import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../libraries/Locale.mjs";
import * as AppContext from "../../context/AppContext.mjs";
import * as BackgroundTaskIcon from "./BackgroundTaskIcon.mjs";
import * as BackgroundRowModuleCss from "/home/runner/work/planado/planado/client/rescript/components/background_task/BackgroundRow.module.css";

var styles = BackgroundRowModuleCss;

var cancel$p = Utils.Translations.tr("js.background_tasks.export.cancel");

var exportCanceled$p = Utils.Translations.tr("js.background_tasks.export.export_canceled");

var downloadXlsx$p = Utils.Translations.tr("js.background_tasks.export.download_xlsx");

var jobsExport$p = Utils.Translations.tr("js.background_tasks.export.title");

function tooManyJobs$p(params, ctx) {
  return Utils.Translations.concat([
              Utils.Translations.t("js.background_tasks.export.errors.export")(ctx),
              Utils.Translations.tx("js.background_tasks.export.errors.too_many_jobs")(params, ctx)
            ], ctx);
}

function timedOut$p(ctx) {
  return Utils.Translations.concat([
              Utils.Translations.t("js.background_tasks.export.errors.export")(ctx),
              Utils.Translations.t("js.background_tasks.export.errors.timeout")(ctx)
            ], ctx);
}

var exportError$p = Utils.Translations.tr("js.background_tasks.export.errors.export");

function BackgroundExportRow(Props) {
  var task = Props.task;
  var cancel = Props.cancel;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = task.status;
  var renderName;
  if (typeof match !== "object") {
    switch (match) {
      case "Waiting" :
      case "InProgress" :
          renderName = jobsExport$p(ctx);
          break;
      case "Canceled" :
          renderName = React.createElement("span", undefined, exportCanceled$p(ctx));
          break;
      
    }
  } else if (match._0) {
    renderName = React.createElement("div", {
          className: styles.name
        }, jobsExport$p(ctx), React.createElement("div", {
              className: styles.time
            }, RCore.$$Option.getOr(RCore.$$Option.map(task.finishedAt, Locale.T.fmtM("DateTime", wire)), "")));
  } else {
    var match$1 = task.errors;
    if (match$1 !== undefined) {
      if (match$1.length !== 1) {
        renderName = exportError$p(ctx);
      } else {
        var match$2 = match$1[0];
        if (match$2.TAG === "General") {
          switch (match$2._0) {
            case "TooManyJobs" :
                renderName = tooManyJobs$p({
                      count: 100000
                    }, ctx);
                break;
            case "TimedOut" :
                renderName = timedOut$p(ctx);
                break;
            default:
              renderName = exportError$p(ctx);
          }
        } else {
          renderName = exportError$p(ctx);
        }
      }
    } else {
      renderName = null;
    }
  }
  var renderFileUrl = function () {
    var url = task.fileUrl;
    if (url !== undefined) {
      return React.createElement("a", {
                  href: url
                }, downloadXlsx$p(ctx));
    } else {
      return null;
    }
  };
  var onCancelClick = function (evt) {
    evt.preventDefault();
    cancel(task.uuid);
  };
  var match$3 = task.status;
  var tmp;
  var exit = 0;
  if (typeof match$3 !== "object") {
    switch (match$3) {
      case "Waiting" :
      case "InProgress" :
          exit = 1;
          break;
      case "Canceled" :
          tmp = null;
          break;
      
    }
  } else {
    tmp = match$3._0 ? renderFileUrl() : null;
  }
  if (exit === 1) {
    tmp = React.createElement("a", {
          href: "javscript:;",
          onClick: onCancelClick
        }, cancel$p(ctx));
  }
  var renderAction = React.createElement("div", {
        className: styles.action
      }, tmp);
  return React.createElement("div", {
              className: styles.item
            }, React.createElement(BackgroundTaskIcon.make, {
                  task: task
                }), renderName, renderAction);
}

var make = BackgroundExportRow;

export {
  make ,
}
/* styles Not a pure module */
