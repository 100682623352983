

import * as El from "../../../../libraries/El.mjs";
import * as Icon from "../../../../Icon.mjs";
import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as Modal from "../../../modal/Modal.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../libraries/Locale.mjs";
import * as Backend from "../../../../libraries/backend/Backend.mjs";
import * as Spinner from "../../../common/Spinner/Spinner.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../../../common/Optional.mjs";
import * as TeamEdit from "../../../team/TeamEdit.mjs";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Context_Types from "../../../../context/Context_Types.mjs";
import * as Backend_Errors from "../../../../libraries/backend/Backend_Errors.mjs";
import * as MessengerTypes from "../../../messenger/MessengerTypes.mjs";
import * as TemporaryWorkerAPI from "../../../team/temporary_worker/TemporaryWorkerAPI.mjs";
import * as ShiftsIndexTeamCell from "./ShiftsIndexTeamCell.mjs";
import * as TemporaryWorkerForm from "../../../team/temporary_worker/TemporaryWorkerForm.mjs";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";
import * as ShiftsIndexTeamsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/shifts/index/teams/ShiftsIndexTeams.module.css";

var styles = ShiftsIndexTeamsModuleCss;

var noTeams$p = Utils.Translations.th("js.shifts.index.no_teams");

var teamActions$p = Utils.Translations.tr("js.shifts.index.team_actions");

var teamSettings$p = Utils.Translations.tr("js.shifts.index.team_settings");

var addTempWorker$p = Utils.Translations.tr("js.shifts.index.add_temp_worker");

var tempWorkerCreated$p = Utils.Translations.t("js.teams.temp_worker.created");

function ShiftsIndexTeams$TeamMembers(Props) {
  var workers = Props.workers;
  var workerUuids = Props.workerUuids;
  var foremanUuid = Props.foremanUuid;
  var temporaryWorkers = Props.temporaryWorkers;
  var dateRange = Props.dateRange;
  var renderEdit;
  if (foremanUuid !== undefined) {
    var uuid = Caml_option.valFromOption(foremanUuid);
    renderEdit = RCore.$$Option.mapWithDefault(RCore.$$Array.getBy(workers, (function (w) {
                return Uuid.equal(w.uuid, uuid);
              })), null, (function (w) {
            return React.createElement("div", {
                        key: Uuid.toString(w.uuid),
                        className: styles.memberName
                      }, w.name);
          }));
  } else {
    renderEdit = null;
  }
  var actualTempWorkerUuids = RCore.$$Array.reduce(temporaryWorkers, [], (function (acc, w) {
          if (!acc.some(function (persistedUuid) {
                  return Uuid.equal(persistedUuid, w.userUuid);
                }) && dateRange.some(function (date) {
                  return Locale.T.equalDay(date, w.dateFrom) ? true : Locale.T.equalDay(date, w.dateTo);
                })) {
            return Belt_Array.concatMany([
                        acc,
                        [w.userUuid]
                      ]);
          } else {
            return acc;
          }
        }));
  var renderView = RCore.$$Array.filterMap(Belt_Array.concatMany([
              workerUuids,
              actualTempWorkerUuids
            ]), (function (u) {
            return RCore.$$Array.getBy(workers, (function (w) {
                          return Uuid.equal(u, w.uuid);
                        }));
          })).map(function (w) {
        return React.createElement("div", {
                    key: Uuid.toString(w.uuid),
                    className: styles.memberName
                  }, w.name);
      });
  return React.createElement("div", {
              className: styles.teamMembers
            }, renderEdit, renderView);
}

function ShiftsIndexTeams$Name(Props) {
  var dateRange = Props.dateRange;
  var workers = Props.workers;
  var team = Props.team;
  var toggleTeam = Props.toggleTeam;
  var constructorWorkers = Props.constructorWorkers;
  var deleteConstructorWorker = Props.deleteConstructorWorker;
  var setEditedTeamMode = Props.setEditedTeamMode;
  var openedTeams = Props.openedTeams;
  var teamActionsOpened = Props.teamActionsOpened;
  var setTeamActionsOpened = Props.setTeamActionsOpened;
  var setAddingTempWorkerTeamUuid = Props.setAddingTempWorkerTeamUuid;
  var ctx = AppContext.useCtx();
  var isOpened = React.useMemo((function () {
          return openedTeams.some(function (u) {
                      return Uuid.equal(team.uuid, u);
                    });
        }), [openedTeams]);
  var hasWorkers = React.useMemo((function () {
          if (team.workerUuids.length > 0) {
            return true;
          } else {
            return RCore.$$Option.isSome(team.foremanUuid);
          }
        }), [team]);
  var rowCN = React.useMemo((function () {
          var match = RCore.$$Array.getBy(constructorWorkers, (function (cu) {
                  return Uuid.equal(cu.uuid, team.uuid);
                }));
          var base;
          if (match !== undefined) {
            var match$1 = match.dates;
            base = match$1 !== undefined && match$1[1] !== undefined ? styles.name + " " + styles.nameConstructor : styles.name;
          } else {
            base = styles.name;
          }
          if (hasWorkers) {
            return base + " " + styles.withMembers;
          } else {
            return base;
          }
        }), [
        hasWorkers,
        constructorWorkers
      ]);
  var onConstructorClear = function (uuid) {
    return function (evt) {
      evt.preventDefault();
      evt.stopPropagation();
      deleteConstructorWorker(uuid);
    };
  };
  var actionIconContainer = function (uuid) {
    var match = RCore.$$Array.getBy(constructorWorkers, (function (cu) {
            return Uuid.equal(cu.uuid, uuid);
          }));
    if (match !== undefined) {
      var match$1 = match.dates;
      if (match$1 !== undefined && match$1[1] !== undefined) {
        return React.createElement("div", {
                    className: El.Cn.concat(styles.nameCancelConstr, styles.icon),
                    onClick: onConstructorClear(match.uuid)
                  }, React.createElement("i", {
                        className: Icon.style(undefined, undefined, "times-circle")
                      }));
      }
      
    }
    return React.createElement(React.Fragment, undefined, React.createElement("div", {
                    className: El.Cn.concat(styles.editIcon, styles.icon),
                    onClick: (function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        setTeamActionsOpened(function (current) {
                              if (current !== undefined && Caml_obj.equal(Caml_option.valFromOption(current), uuid)) {
                                return ;
                              } else {
                                return Caml_option.some(uuid);
                              }
                            });
                      })
                  }, React.createElement("i", {
                        className: Icon.style(undefined, undefined, "cog")
                      })), React.createElement(Optional.make, {
                    show: Caml_obj.equal(teamActionsOpened, Caml_option.some(uuid)),
                    children: React.createElement("div", {
                          className: styles.dropdown,
                          onClick: (function (e) {
                              e.stopPropagation();
                            })
                        }, React.createElement("div", {
                              className: styles.teamActionsHeader
                            }, teamActions$p(ctx)), React.createElement("div", {
                              className: styles.teamActionsOption,
                              onClick: (function (e) {
                                  setTeamActionsOpened(function (param) {
                                        
                                      });
                                  var uuid = team.uuid;
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setEditedTeamMode(function (param) {
                                        return {
                                                NAME: "Edit",
                                                VAL: uuid
                                              };
                                      });
                                })
                            }, teamSettings$p(ctx)), React.createElement(Optional.make, {
                              show: Context_Types.Features.hasFlag("temporaryWorkers", ctx.features),
                              children: React.createElement("div", {
                                    className: styles.teamActionsOption,
                                    onClick: (function (param) {
                                        setAddingTempWorkerTeamUuid(function (param) {
                                              return teamActionsOpened;
                                            });
                                        setTeamActionsOpened(function (param) {
                                              
                                            });
                                      })
                                  }, addTempWorker$p(ctx))
                            }))
                  }));
  };
  var onClick = function (evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (hasWorkers) {
      return toggleTeam(team.uuid);
    }
    
  };
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  key: Uuid.toString(team.uuid),
                  className: rowCN,
                  onClick: onClick
                }, React.createElement("span", undefined, team.name), React.createElement("div", {
                      className: styles.nameButtons
                    }, actionIconContainer(team.uuid), hasWorkers ? React.createElement("div", {
                            className: El.Cn.concat(styles.openMembers, styles.icon)
                          }, isOpened ? React.createElement("i", {
                                  className: Icon.style("lg", undefined, "angle-up")
                                }) : React.createElement("i", {
                                  className: Icon.style("lg", undefined, "angle-down")
                                })) : React.createElement("div", {
                            className: styles.icon
                          }))), React.createElement(Optional.make, {
                  show: isOpened,
                  children: React.createElement(ShiftsIndexTeams$TeamMembers, {
                        workers: workers,
                        workerUuids: team.workerUuids,
                        foremanUuid: team.foremanUuid,
                        temporaryWorkers: team.temporaryWorkers,
                        dateRange: dateRange
                      })
                }));
}

function ShiftsIndexTeams$Names(Props) {
  var dateRange = Props.dateRange;
  var teams = Props.teams;
  var workers = Props.workers;
  var constructorWorkers = Props.constructorWorkers;
  var deleteConstructorWorker = Props.deleteConstructorWorker;
  var setEditedTeamMode = Props.setEditedTeamMode;
  var openedTeams = Props.openedTeams;
  var toggleTeam = Props.toggleTeam;
  var setAddingTempWorkerTeamUuid = Props.setAddingTempWorkerTeamUuid;
  var match = React.useState(function () {
        
      });
  var setTeamActionsOpened = match[1];
  var teamActionsOpened = match[0];
  return React.createElement("div", {
              className: styles.sidebar
            }, teams.map(function (team) {
                  return React.createElement(ShiftsIndexTeams$Name, {
                              dateRange: dateRange,
                              workers: workers,
                              team: team,
                              toggleTeam: toggleTeam,
                              constructorWorkers: constructorWorkers,
                              deleteConstructorWorker: deleteConstructorWorker,
                              setEditedTeamMode: setEditedTeamMode,
                              openedTeams: openedTeams,
                              teamActionsOpened: teamActionsOpened,
                              setTeamActionsOpened: setTeamActionsOpened,
                              setAddingTempWorkerTeamUuid: setAddingTempWorkerTeamUuid,
                              key: Uuid.toString(team.uuid)
                            });
                }));
}

function ShiftsIndexTeams$Row(Props) {
  var workers = Props.workers;
  var team = Props.team;
  var updateTeam = Props.updateTeam;
  var updateWorker = Props.updateWorker;
  var interval = Props.interval;
  var constructorWorker = Props.constructorWorker;
  var onConstructorWorkerUpdate = Props.onConstructorWorkerUpdate;
  var dateRange = Props.dateRange;
  var jobTypes = Props.jobTypes;
  var territories = Props.territories;
  var isOpened = Props.isOpened;
  var mode = Props.mode;
  var teamWorkers = React.useMemo((function () {
          var actualTempWorkerUuids = RCore.$$Array.keepMap(team.temporaryWorkers, (function (w) {
                  if (dateRange.some(function (date) {
                          return Locale.T.equalDay(date, w.dateFrom) ? true : Locale.T.equalDay(date, w.dateTo);
                        })) {
                    return Caml_option.some(w.userUuid);
                  }
                  
                }));
          var workerUuids = Belt_Array.concatMany([
                team.workerUuids,
                actualTempWorkerUuids,
                RCore.$$Option.mapWithDefault(team.foremanUuid, [], (function (a) {
                        return [a];
                      }))
              ]);
          return RCore.$$Array.keep(workers, (function (w) {
                        return workerUuids.some(function (u) {
                                    return Uuid.equal(u, w.uuid);
                                  });
                      }));
        }), [
        workers,
        team
      ]);
  return React.createElement("div", {
              className: styles.row
            }, dateRange.map(function (day, idx) {
                  return React.createElement(ShiftsIndexTeamCell.make, {
                              day: day,
                              team: team,
                              workers: teamWorkers,
                              jobTypes: jobTypes,
                              territories: territories,
                              updateTeam: updateTeam,
                              updateWorker: updateWorker,
                              constructorWorker: constructorWorker,
                              onConstructorWorkerUpdate: onConstructorWorkerUpdate,
                              interval: interval,
                              isOpened: isOpened,
                              mode: mode,
                              key: idx.toString()
                            });
                }));
}

function ShiftsIndexTeams$TempWorker(Props) {
  var team = Props.team;
  var updateTeam = Props.updateTeam;
  var wire = Props.wire;
  var errors = Props.errors;
  var setAddingTempWorkerTeamUuid = Props.setAddingTempWorkerTeamUuid;
  var setErrors = Props.setErrors;
  var ctx = wire.ctx;
  var onTempWorkerClose = function () {
    setAddingTempWorkerTeamUuid(function (param) {
          
        });
  };
  return React.createElement(Modal.make, {
              wire: wire,
              onClose: onTempWorkerClose,
              children: React.createElement(TemporaryWorkerForm.make, {
                    wire: wire,
                    teamUuid: team.uuid,
                    teamName: team.name,
                    exceptUuids: Belt_Array.concatMany([
                          team.workerUuids,
                          RCore.$$Option.mapWithDefault(team.foremanUuid, [], (function (u) {
                                  return [u];
                                }))
                        ]),
                    onClose: onTempWorkerClose,
                    onSubmit: (function (worker) {
                        var userUuid = worker.userUuid;
                        if (userUuid === undefined) {
                          return ;
                        }
                        var from = worker.dateFrom;
                        if (from === undefined) {
                          return ;
                        }
                        var to = worker.dateTo;
                        if (to === undefined) {
                          return ;
                        }
                        var to$1 = Caml_option.valFromOption(to);
                        var from$1 = Caml_option.valFromOption(from);
                        var userUuid$1 = Caml_option.valFromOption(userUuid);
                        Backend.$$finally(TemporaryWorkerAPI.Post.create(wire, team.uuid, userUuid$1, from$1, to$1), (function (res) {
                                if (res.TAG === "Ok") {
                                  wire.subscriptions.messenger.trigger({
                                        TAG: "Show",
                                        _0: MessengerTypes.Message.make(undefined, "Success", {
                                              TAG: "Text",
                                              _0: tempWorkerCreated$p(ctx)
                                            })
                                      });
                                  updateTeam({
                                        uuid: team.uuid,
                                        name: team.name,
                                        shifts: team.shifts,
                                        territoryUuid: team.territoryUuid,
                                        workerUuids: team.workerUuids,
                                        foremanUuid: team.foremanUuid,
                                        temporaryWorkers: Belt_Array.concatMany([
                                              team.temporaryWorkers,
                                              [{
                                                  userUuid: userUuid$1,
                                                  dateFrom: from$1,
                                                  dateTo: to$1
                                                }]
                                            ])
                                      });
                                  return setAddingTempWorkerTeamUuid(function (param) {
                                              
                                            });
                                }
                                var errors = res._0;
                                if (typeof errors !== "object") {
                                  console.log(errors);
                                  return ;
                                }
                                if (errors.TAG === "ValidationError") {
                                  var e = Json_Decode$JsonCombinators.decode(Backend_Errors.json(errors._0), TemporaryWorkerAPI.Post.$$Response.ErrorResponse.decode);
                                  if (e.TAG === "Ok") {
                                    var errors$1 = e._0.errors;
                                    return setErrors(function (param) {
                                                return errors$1;
                                              });
                                  }
                                  console.log(e._0);
                                  return ;
                                }
                                console.log(errors);
                              }));
                      }),
                    errors: errors,
                    setErrors: setErrors
                  })
            });
}

function ShiftsIndexTeams(Props) {
  var dateRange = Props.dateRange;
  var workers = Props.workers;
  var teams = Props.teams;
  var jobTypes = Props.jobTypes;
  var territories = Props.territories;
  var constructorWorkers = Props.constructorWorkers;
  var deleteConstructorWorker = Props.deleteConstructorWorker;
  var deleteTeam = Props.deleteTeam;
  var updateTeam = Props.updateTeam;
  var updateWorker = Props.updateWorker;
  var interval = Props.interval;
  var updateConstructorWorker = Props.updateConstructorWorker;
  var mode = Props.mode;
  var editedTeamMode = Props.editedTeamMode;
  var setEditedTeamMode = Props.setEditedTeamMode;
  var loading = Props.loading;
  var wire = AppContext.useWire();
  var match = React.useState(function () {
        return [];
      });
  var updateOpenedTeams = match[1];
  var openedTeams = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setAddingTempWorkerTeamUuid = match$1[1];
  var addingTempWorkerTeamUuid = match$1[0];
  var match$2 = React.useState(function () {
        return TemporaryWorkerAPI.Post.$$Response.Errors.emptyErrors;
      });
  var toggleTeam = function (uuid) {
    updateOpenedTeams(function (prev) {
          if (prev.some(function (u) {
                  return Uuid.equal(uuid, u);
                })) {
            return RCore.$$Array.keep(prev, (function (u) {
                          return !Uuid.equal(uuid, u);
                        }));
          } else {
            return Belt_Array.concatMany([
                        prev,
                        [uuid]
                      ]);
          }
        });
  };
  var onConstructorWorkerUpdate = function (teamUuid, day) {
    var existing;
    var len = constructorWorkers.length;
    if (len !== 1) {
      if (len !== 0) {
        return ;
      } else {
        return updateConstructorWorker(teamUuid, day);
      }
    }
    var match = constructorWorkers[0];
    var match$1 = match.dates;
    var existing$1 = match.uuid;
    if (Uuid.equal(existing$1, teamUuid)) {
      return updateConstructorWorker(teamUuid, day);
    }
    if (match$1 !== undefined) {
      if (match$1[1] !== undefined) {
        return ;
      }
      existing = existing$1;
    } else {
      existing = existing$1;
    }
    updateConstructorWorker(teamUuid, day);
    deleteConstructorWorker(existing);
  };
  var tmp;
  if (teams.length === 0) {
    tmp = React.createElement("div", {
          className: styles.noTeams
        }, loading ? React.createElement(Spinner.make, {}) : noTeams$p(wire.ctx));
  } else {
    var tmp$1;
    if (addingTempWorkerTeamUuid !== undefined) {
      var uuid = Caml_option.valFromOption(addingTempWorkerTeamUuid);
      var team = RCore.$$Array.getBy(teams, (function (t) {
              return Uuid.equal(t.uuid, uuid);
            }));
      tmp$1 = team !== undefined ? React.createElement(ShiftsIndexTeams$TempWorker, {
              team: team,
              updateTeam: updateTeam,
              wire: wire,
              errors: match$2[0],
              setAddingTempWorkerTeamUuid: setAddingTempWorkerTeamUuid,
              setErrors: match$2[1]
            }) : null;
    } else {
      tmp$1 = null;
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement(ShiftsIndexTeams$Names, {
              dateRange: dateRange,
              teams: teams,
              workers: workers,
              constructorWorkers: constructorWorkers,
              deleteConstructorWorker: deleteConstructorWorker,
              setEditedTeamMode: setEditedTeamMode,
              openedTeams: openedTeams,
              toggleTeam: toggleTeam,
              setAddingTempWorkerTeamUuid: setAddingTempWorkerTeamUuid
            }), React.createElement("div", {
              className: styles.table
            }, teams.map(function (team) {
                  var isOpened = openedTeams.some(function (u) {
                        return Uuid.equal(team.uuid, u);
                      });
                  return React.createElement("div", {
                              key: Uuid.toString(team.uuid)
                            }, React.createElement(Optional.make, {
                                  show: isOpened,
                                  children: React.createElement("div", {
                                        className: styles.openedSeperator
                                      })
                                }), React.createElement(ShiftsIndexTeams$Row, {
                                  workers: workers,
                                  team: team,
                                  updateTeam: updateTeam,
                                  updateWorker: updateWorker,
                                  interval: interval,
                                  constructorWorker: RCore.$$Array.getBy(constructorWorkers, (function (cu) {
                                          return Uuid.equal(cu.uuid, team.uuid);
                                        })),
                                  onConstructorWorkerUpdate: onConstructorWorkerUpdate,
                                  dateRange: dateRange,
                                  jobTypes: jobTypes,
                                  territories: territories,
                                  isOpened: isOpened,
                                  mode: mode,
                                  key: Uuid.toString(team.uuid)
                                }), React.createElement(Optional.make, {
                                  show: isOpened,
                                  children: React.createElement("div", {
                                        className: styles.openedSeperator
                                      })
                                }));
                })), tmp$1);
  }
  var tmp$2;
  if (editedTeamMode !== undefined && Context_Types.Features.hasFlag("teams", wire.ctx.features)) {
    var onClose = function () {
      setEditedTeamMode(function (param) {
            
          });
    };
    tmp$2 = React.createElement(Modal.make, {
          wire: wire,
          onClose: onClose,
          children: React.createElement(TeamEdit.make, {
                wire: wire,
                mode: editedTeamMode,
                isModal: true,
                onDelete: deleteTeam,
                onClose: onClose
              })
        });
  } else {
    tmp$2 = null;
  }
  return React.createElement(React.Fragment, undefined, tmp, tmp$2);
}

var make = ShiftsIndexTeams;

export {
  make ,
}
/* styles Not a pure module */
