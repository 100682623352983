import { connect } from 'react-redux'
import CurrentJobs from 'planado/components/map/current_jobs'

const mapStateToProps = ({ currentJobs, engineChannel, jobStatusFilter }) => {
  return {
    jobs: currentJobs,
    statusFilter: jobStatusFilter,
    engineChannel
  }
}

const mapDispatchToProps = _dispatch => ({})

const CurrentJobsLink = connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentJobs)

export default CurrentJobsLink
