

import * as WeekTimeline_State from "../../../../../lib/state/WeekTimeline_State.mjs";
import * as Schedule_TimelineHooks from "../../../../../../../lib/hooks/Schedule_TimelineHooks.mjs";

function use(elementRef, job) {
  var match = WeekTimeline_State.useTimelineSubscriptions();
  return Schedule_TimelineHooks.JobComponentListeners.use(match.onScrollStart, elementRef, job);
}

export {
  use ,
}
/* WeekTimeline_State Not a pure module */
