

import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as AppContext from "../../../context/AppContext.mjs";
import * as JobFieldsListModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/index/JobFieldsList.module.css";

var styles = JobFieldsListModuleCss;

var jobFields$p = Utils.Translations.tr("js.jobs.index.views.columns.job_fields");

var customFields$p = Utils.Translations.tr("js.jobs.index.views.columns.custom_fields");

var reportFields$p = Utils.Translations.tr("js.jobs.index.views.columns.report_fields");

function JobFieldsList(Props) {
  var jobFields = Props.jobFields;
  var customFields = Props.customFields;
  var reportFields = Props.reportFields;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  return React.createElement(React.Fragment, undefined, React.createElement("section", {
                  className: styles.content
                }, React.createElement("div", {
                      className: styles.column
                    }, React.createElement("div", {
                          className: styles.headline
                        }, jobFields$p(ctx))), React.createElement("div", {
                      className: styles.column
                    }, React.createElement("div", {
                          className: styles.headline
                        }, customFields$p(ctx))), React.createElement("div", {
                      className: styles.column
                    }, React.createElement("div", {
                          className: styles.headline
                        }, reportFields$p(ctx)))), React.createElement("section", {
                  className: styles.content
                }, React.createElement("div", {
                      className: styles.contentColumn
                    }, jobFields), React.createElement("div", {
                      className: styles.contentColumn
                    }, customFields), React.createElement("div", {
                      className: styles.contentColumn
                    }, reportFields)));
}

var make = JobFieldsList;

export {
  make ,
}
/* styles Not a pure module */
