

import * as ModalQuery from "../../../../../types/ModalQuery.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Subscription from "../../../../../libraries/Subscription.mjs";
import * as JobEdit_Types from "../types/JobEdit_Types.mjs";

var Form = {};

var Job = {};

var Action = {
  Form: Form,
  Job: Job
};

var FormEvent = {};

var Confirmation = {};

function initial(subroute) {
  return {
          id: subroute.NAME === "Edit" ? Caml_option.some(ModalQuery.Job.EditOptions.uuid(subroute.VAL)) : undefined,
          locked: false,
          saving: false,
          events: Subscription.make(),
          confirmation: "None",
          confirmationLocked: false,
          showHistory: false,
          errors: JobEdit_Types.Form.Errors.empty,
          autoTerritory: false
        };
}

var Form$1 = {
  FormEvent: FormEvent,
  Confirmation: Confirmation,
  initial: initial
};

function initial$1(subroute, job) {
  return {
          form: initial(subroute),
          job: job,
          changedData: JobEdit_Types.ChangedData.empty
        };
}

function job(state) {
  return state.job;
}

function foremanError(state) {
  return state.job.foremanError;
}

function assignees(state) {
  return state.job.assignees;
}

function template(state) {
  return state.job.template;
}

function typeUuid(state) {
  return state.job.typeUuid;
}

function jobTypes(state) {
  return state.job.jobTypes;
}

function possibleAssignees(state) {
  return state.job.possibleAssignees;
}

function skillUuids(state) {
  return state.job.skillUuids;
}

function status(state) {
  return state.job.status;
}

function territoryUuid(state) {
  return state.job.territoryUuid;
}

function scheduledDuration(state) {
  return state.job.scheduledDuration;
}

function maxScheduledDuration(state) {
  return state.job.maxScheduledDuration;
}

function scheduledAt(state) {
  return state.job.scheduledAt;
}

function reportFields(state) {
  return state.job.reportFields;
}

function customFields(state) {
  return state.job.customFields;
}

function customFieldsErrors(state) {
  return state.form.errors.customFields;
}

function jobErrors(state) {
  return state.form.errors.job;
}

var State = {
  Form: Form$1,
  initial: initial$1,
  job: job,
  foremanError: foremanError,
  assignees: assignees,
  template: template,
  typeUuid: typeUuid,
  jobTypes: jobTypes,
  possibleAssignees: possibleAssignees,
  skillUuids: skillUuids,
  status: status,
  territoryUuid: territoryUuid,
  scheduledDuration: scheduledDuration,
  maxScheduledDuration: maxScheduledDuration,
  scheduledAt: scheduledAt,
  reportFields: reportFields,
  customFields: customFields,
  customFieldsErrors: customFieldsErrors,
  jobErrors: jobErrors
};

var Reducer = {};

export {
  Action ,
  State ,
  Reducer ,
}
/* ModalQuery Not a pure module */
