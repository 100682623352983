

import * as React from "react";
import * as WeekTimeline_CurrentTimeHook from "./WeekTimeline_CurrentTimeHook.mjs";
import * as WeekTimeline_CurrentTimeModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/timeline/current_time/WeekTimeline_CurrentTime.module.css";

var styles = WeekTimeline_CurrentTimeModuleCss;

function WeekTimeline_CurrentTime(Props) {
  var part = Props.part;
  var ref = WeekTimeline_CurrentTimeHook.use();
  var className;
  className = part === "Line" ? styles.line : styles.triangle;
  return React.createElement("div", {
              ref: ref,
              className: className
            });
}

var make = React.memo(WeekTimeline_CurrentTime);

export {
  make ,
}
/* styles Not a pure module */
