import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import Location from './location'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import { BLANK_ADDRESS } from 'planado/types/address'
import {
  str2geolocation,
  isGeolocation,
  latlongRegex
} from 'planado/utils/geolocation'
import LimitedInput from 'planado/components/input/limited_input'
import * as styles from './styles.module.css'

/********* Convenient utils for attributes *************/

// This list does not contain `formatted` because it's only used
// to generate hidden fields
const ATTRIBUTES = [
  'streetNumber',
  'route',
  'sublocality',
  'locality',
  'subadministrativeArea',
  'administrativeArea',
  'postalCode',
  'extId',
  'geolocationResolved',
  'formattedFull',
  'country',
  'source'
]

const ATTRIBUTE_FIELD_NAME_MAP = {
  streetNumber: 'street_number',
  route: 'route',
  sublocality: 'sublocality',
  locality: 'locality',
  subadministrativeArea: 'subadministrative_area',
  administrativeArea: 'administrative_area',
  postalCode: 'postal_code',
  extId: 'ext_id',
  geolocationResolved: 'geolocation_resolved',
  formattedFull: 'formatted_full',
  country: 'country',
  source: 'source'
}

// decamelize adds unnecessary overhead, hence the hardcoded mapping
const decamelizeAttribute = attribute => ATTRIBUTE_FIELD_NAME_MAP[attribute]

const getAttributeValue = (attributes, key) => {
  if (attributes) {
    switch (key) {
      case 'geolocationResolved':
        return attributes.geolocationResolved.toString()
      default:
        return attributes[key] || ''
    }
  } else {
    return key === 'geolocationResolved' ? 'false' : ''
  }
}

/********** *************/
/******** Lookup components **********/

class LookupDropdownContainer extends Component {
  shouldComponentUpdate(_newProps, _newState) {
    return false
  }

  render() {
    return <div className="lookup-dropdown-container" />
  }
}

const MarkerIcon = ({ isResolved, isFailedToResolve }) => {
  if (isResolved || isFailedToResolve) {
    const classes = classNames({
      [styles.geolocationFailedIcon]: isFailedToResolve,
      [styles.geolocationResolvedIcon]: isResolved
    })

    return (
      <span
        className={`${classes} fa fa-map-marker-alt pd-form-control-icon`}
        aria-hidden="true"
      />
    )
  } else {
    return null
  }
}

const WarningText = withContext(({ isFailedToResolve, ctx }) => {
  if (isFailedToResolve) {
    return (
      <span
        className={`${styles.geolocationFailedText} pd-form-control-comment pd-form-control-comment-right pd-margin-top-1px`}
      >
        {ctx.t('js.jobs.comments.location_not_resolved')}
      </span>
    )
  } else {
    return null
  }
})

const ResetButton = ({ isEmpty, readOnly, onReset }) => {
  if (!isEmpty && !readOnly) {
    return (
      <span
        className={`${styles.pdFormControlReset} fa fa-times-circle pd-form-control-reset`}
        onClick={onReset}
      />
    )
  } else {
    return null
  }
}

const LookupRestrictionsText = ({
  formatted,
  formattedFull,
  lookupAddress,
  isResolved
}) => {
  const isRelativeToLookup =
    formatted &&
    formattedFull &&
    lookupAddress &&
    formattedFull.indexOf(lookupAddress) != -1 &&
    formatted.indexOf(lookupAddress) == -1

  if (isResolved && lookupAddress && isRelativeToLookup) {
    return <span className="pd-form-control-comment">{lookupAddress}</span>
  } else {
    return null
  }
}

const MAX_LENGTH = 200

class AddressLookupField extends Component {
  state = {
    locationId: `location-popup-id${Math.floor(Math.random() * 100000 + 1)}`,
    inputfocused: false
  }
  input = React.createRef()

  componentDidMount = () => {
    document.addEventListener('keypress', this.onKeyPress, false)
    this.input.current.placeholder = ''
    this.props.onLookupMount(this.input.current)
  }

  componentWillUnmount = () => {
    document.removeEventListener('keypress', this.onKeyPress, false)
  }

  onKeyPress = evt => {
    if (evt.keyCode === 13 && this.state.inputfocused) {
      evt.preventDefault()
    }
  }

  inputName = attribute => this.props.namePrefix + '[' + attribute + ']'

  geolocationInputName = attribute => this.props.namePrefix + attribute

  onReset = _ => {
    this.props.onChange(null)
  }

  onChange = _ => {
    const value = this.input.current.value

    this.props.wire.usedResources.track("googleAutocompleteRequest")

    if (latlongRegex(value.trim())) {
      const geolocation = str2geolocation(value)

      if (geolocation) {
        this.props.onChange({
          ...BLANK_ADDRESS,
          formatted: `${geolocation.latitude}, ${geolocation.longitude}`,
          geolocation: geolocation,
          geolocationResolved: true
        })
      }
    } else {
      this.props.onChange({
        ...BLANK_ADDRESS,
        formatted: value,
        geolocationResolved: false
      })
    }
  }

  onFocus = () => {
    this.setState(() => ({ inputfocused: true }))
  }

  onBlur = () => {
    this.props.onBlur()
    this.setState(() => ({ inputfocused: false }))
  }

  render() {
    const {
      id,
      readOnly,
      attributes,
      onChange,
      xhr,
      lookupAddress,
      wrapperClass,
      wire,
      ctx
    } = this.props

    const formatted = attributes ? attributes.formatted : null
    const formattedFull = attributes ? attributes.formattedFull : null
    const geolocation = attributes ? attributes.geolocation : null
    const tempGeolocation = attributes ? attributes.tempGeolocation : null
    const geolocationResolved = attributes
      ? attributes.geolocationResolved
      : null

    const isEmpty = !formatted || formatted.length < 3

    const isResolved =
      geolocationResolved && isGeolocation(geolocation) && !isEmpty

    const isFailedToResolve = !isResolved && !isEmpty

    /* eslint-disable */
    let attributesWithoutGeolocation
    if (attributes) {
      const { geolocation: _, ...attrs } = attributes
      attributesWithoutGeolocation = attrs
    } else {
      attributesWithoutGeolocation = null
    }
    /* eslint-enable */

    const hiddenFields = ATTRIBUTES.map(key => (
      <input
        key={key}
        type="hidden"
        name={this.inputName(decamelizeAttribute(key))}
        value={getAttributeValue(attributesWithoutGeolocation, key)}
      />
    ))

    const hiddenGeolocationFields = geolocation ? (
      <Fragment>
        <input
          type="hidden"
          name={this.geolocationInputName('[geolocation][latitude]')}
          value={geolocation.latitude}
        />
        <input
          type="hidden"
          name={this.geolocationInputName('[geolocation][longitude]')}
          value={geolocation.longitude}
        />
      </Fragment>
    ) : (
      <input
        type="hidden"
        name={this.geolocationInputName('[geolocation]')}
        value=""
      />
    )

    const groupClasses = classNames({
      'pd-form-group': true,
      'address-formatted': true,
      'geolocation-resolved': isResolved,
      'geolocation-failed': isFailedToResolve,
      ...wrapperClass
    })

    const groupStyles = classNames({
      'geolocation-failed': isFailedToResolve
    })

    return (
      <div className={groupClasses} styles={groupStyles}>
        <label htmlFor={id} className="pd-label">
          {ctx.t('js.jobs.labels.address')}
        </label>

        {hiddenFields}
        {hiddenGeolocationFields}

        <Location
          id={this.state.locationId}
          isResolved={isResolved}
          readOnly={readOnly}
          formatted={formatted}
          geolocation={geolocation}
          tempGeolocation={tempGeolocation}
          saveLocation={onChange}
          ctx={ctx}
          xhr={xhr}
          wire={wire}
        />

        <div className="pd-complex-form-control-container">
          <LimitedInput
            id={id}
            ref={this.input}
            name={this.inputName('formatted')}
            type="text"
            onValueChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            readOnly={readOnly}
            value={formatted || ''}
            maxLength={MAX_LENGTH}
            className="pd-form-control pd-form-control-with-icon"
          />

          <MarkerIcon
            isResolved={isResolved}
            isFailedToResolve={isFailedToResolve}
          />

          <ResetButton
            readOnly={readOnly}
            isEmpty={isEmpty}
            onReset={this.onReset}
          />

          <LookupRestrictionsText
            formatted={formatted}
            formattedFull={formattedFull}
            lookupAddress={lookupAddress}
            isResolved={isResolved}
          />

          <WarningText isFailedToResolve={isFailedToResolve} />

          <LookupDropdownContainer />
        </div>
      </div>
    )
  }
}

export default withContext(props => {
  return <AddressLookupField {...props} />
})
