import React from 'react'
import jQuery from 'jquery'

export default class Title extends React.Component {
  constructor(props) {
    super(props)

    this.componentDidMount = this.updateTitle
    this.componentDidUpdate = this.updateTitle
  }

  updateTitle() {
    const { placement = 'top' } = this.props
    jQuery('[data-component-tooltip="true"]').tooltip({ html: true, placement })
  }

  render() {
    const { children, content } = this.props
    const child = React.Children.only(children)
    let childProps = {
      'data-original-title': content,
      'data-component-tooltip': true
    }

    return React.cloneElement(child, childProps)
  }
}
