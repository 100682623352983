

import * as El from "../../../libraries/El.mjs";
import * as Link from "../Link/Link.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Types from "../../../types/Types.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../libraries/Locale.mjs";
import * as Tooltip from "../Tooltip/Tooltip.mjs";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as ModalQuery from "../../../types/ModalQuery.mjs";
import * as Shared_Lib_Option from "../../../shared/lib/Shared_Lib_Option.mjs";
import * as RelatedEntitiesTypes from "./RelatedEntitiesTypes.mjs";
import * as RelatedEntitiesTableModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/RelatedEntities/RelatedEntitiesTable.module.css";

var styles = RelatedEntitiesTableModuleCss;

var address$p = Utils.Translations.tr("js.components.job_list.address");

var datetime$p = Utils.Translations.datetimeFromStr;

var finish$p = Utils.Translations.t("js.components.job_list.finish");

var name$p = Utils.Translations.tr("js.components.job_list.name");

var nextScheduledAt$p = Utils.Translations.tr("js.recurring_jobs.table.next_scheduled_at");

var notAssigned$p = Utils.Translations.tr("js.components.job_list.values.not_assigned");

var noTemplate$p = Utils.Translations.tr("js.components.job_list.values.no_template");

function notFound$p(pageType, c) {
  var _type = RelatedEntitiesTypes.PageType.toString(pageType);
  return Utils.Translations.tr("js.components.job_list.jobs_not_found_" + _type)(c);
}

var notScheduled$p = Utils.Translations.tr("js.components.job_list.values.not_scheduled");

var number$p = Utils.Translations.tr("js.components.job_list.number");

var phone$p = Utils.Translations.tr("js.components.job_list.phone");

var scheduledAt$p = Utils.Translations.tr("js.components.job_list.scheduled_at");

var start$p = Utils.Translations.t("js.components.job_list.start");

function status$p(status, c) {
  return Utils.Translations.tr("js.components.job_list.statuses." + status)(c);
}

var tableStatus$p = Utils.Translations.tr("js.components.job_list.status");

var template$p = Utils.Translations.tr("js.components.job_list.template");

function tooltip$p(started, finished, c) {
  return Utils.Translations.tx("js.components.job_list.start_and_finish_html")({
              started: started,
              finished: finished
            }, c);
}

var worker$p = Utils.Translations.tr("js.components.job_list.worker");

function jobLink(route, uuid, wire, serialNo) {
  var match = route.path;
  var match$1 = Core__List.head(route.modals);
  var jobRoute;
  var exit = 0;
  var exit$1 = 0;
  if (typeof match === "object") {
    var variant = match.NAME;
    if (variant === "RecurringJob" || variant === "Template") {
      var match$2 = match.VAL;
      if (typeof match$2 === "object" && match$2.NAME === "Edit") {
        exit = 1;
      } else {
        exit$1 = 2;
      }
    } else {
      exit$1 = 2;
    }
  } else {
    exit$1 = 2;
  }
  if (exit$1 === 2) {
    if (match$1 !== undefined && typeof match$1 === "object") {
      var variant$1 = match$1.NAME;
      if (variant$1 === "Client" || variant$1 === "Site") {
        var match$3 = match$1.VAL;
        if (typeof match$3 === "object" && match$3.NAME === "Edit") {
          exit = 1;
        } else {
          jobRoute = undefined;
        }
      } else {
        jobRoute = undefined;
      }
    } else {
      jobRoute = undefined;
    }
  }
  if (exit === 1) {
    jobRoute = [
      route.path,
      {
        hd: {
          NAME: "Job",
          VAL: {
            NAME: "Edit",
            VAL: ModalQuery.Job.EditOptions.make(undefined, undefined, uuid)
          }
        },
        tl: route.modals
      }
    ];
  }
  if (jobRoute !== undefined) {
    return React.createElement(Link.make, {
                wire: wire,
                route: jobRoute[0],
                modals: jobRoute[1],
                children: Types.Id.toString(serialNo)
              });
  } else {
    return React.createElement("a", {
                onClick: (function (param) {
                    wire.subscriptions.jobs.trigger({
                          TAG: "OpenJob",
                          _0: uuid
                        });
                  })
              }, Types.Id.toString(serialNo));
  }
}

function title(wire, startedAt, finishedAt) {
  var ctx = wire.ctx;
  var match = Shared_Lib_Option.align(startedAt, finishedAt);
  if (match === undefined) {
    return ;
  }
  switch (match.TAG) {
    case "Left" :
        return El.Cn.concat(start$p(ctx), datetime$p(match._0, wire));
    case "Right" :
        return El.Cn.concat(finish$p(ctx), datetime$p(match._0, wire));
    case "Both" :
        return tooltip$p(datetime$p(match._0, wire), datetime$p(match._1, wire), ctx);
    
  }
}

function titleBlock(ref, scheduledAt, wire) {
  return React.createElement("span", {
              ref: ref
            }, scheduledAt !== undefined ? datetime$p(scheduledAt, wire) : notScheduled$p(wire.ctx));
}

function date(wire, ref, scheduledAt, startedAt, finishedAt) {
  var title$1 = title(wire, startedAt, finishedAt);
  if (title$1 !== undefined) {
    return React.createElement(Tooltip.make, {
                elementRef: ref,
                position: "bottom",
                wrapperClassName: styles.tooltip,
                content: title$1,
                children: titleBlock(ref, scheduledAt, wire)
              });
  } else {
    return titleBlock(ref, scheduledAt, wire);
  }
}

function templateOrAddress(ctx, pageType, address, template) {
  if (pageType === "Template") {
    if (address !== undefined) {
      return address;
    } else if (template !== undefined) {
      return template;
    } else {
      return noTemplate$p(ctx);
    }
  } else if (template !== undefined) {
    return template;
  } else {
    return noTemplate$p(ctx);
  }
}

function assigneeText(assignee, ctx) {
  if (assignee !== undefined) {
    return assignee;
  } else {
    return notAssigned$p(ctx);
  }
}

function statusText(status, ctx, resolution) {
  return React.createElement(React.Fragment, undefined, status$p(status, ctx), resolution !== undefined ? React.createElement("div", {
                    className: "resolution-name"
                  }, resolution) : null);
}

function RelatedEntitiesTable$RelatedEntitiesItemJob(Props) {
  var wire = Props.wire;
  var param = Props.job;
  var pageType = Props.pageType;
  var ctx = wire.ctx;
  var ref = React.useRef(null);
  return React.createElement("tr", undefined, React.createElement("td", undefined, jobLink(wire.route, param.uuid, wire, param.serialNo)), React.createElement("td", undefined, date(wire, ref, param.scheduledAt, param.startedAt, param.finishedAt)), React.createElement("td", undefined, templateOrAddress(ctx, pageType, param.address, param.template)), React.createElement("td", undefined, assigneeText(param.assignee, ctx)), React.createElement("td", undefined, statusText(param.status, ctx, param.resolution)));
}

function siteRoutes(wire, uuid, name) {
  return React.createElement(Link.make, {
              wire: wire,
              route: {
                NAME: "Site",
                VAL: "Index"
              },
              modals: {
                hd: {
                  NAME: "Site",
                  VAL: {
                    NAME: "Edit",
                    VAL: uuid
                  }
                },
                tl: /* [] */0
              },
              target: "blank",
              children: name
            });
}

function cell(value) {
  if (value !== undefined) {
    return React.createElement("td", undefined, value);
  } else {
    return null;
  }
}

function RelatedEntitiesTable$RelatedEntitiesItemSite(Props) {
  var wire = Props.wire;
  var param = Props.site;
  return React.createElement("tr", undefined, React.createElement("td", undefined, siteRoutes(wire, param.uuid, param.name)), cell(param.address), cell(param.phone));
}

function recurringJobRoute(wire, job) {
  return React.createElement(Link.make, {
              wire: wire,
              route: {
                NAME: "RecurringJob",
                VAL: {
                  NAME: "Edit",
                  VAL: job.uuid
                }
              },
              target: "blank",
              children: job.name
            });
}

function templateColumn(pageType, recurringJob) {
  if (pageType === "Template") {
    return null;
  } else {
    return React.createElement("td", undefined, RCore.$$Option.getOr(recurringJob.template, ""));
  }
}

function timeToString(wire, time) {
  return Locale.T.fmtM("DateTime", wire)(time);
}

function RelatedEntitiesTable$RelatedEntitiesItemRecurringJob(Props) {
  var wire = Props.wire;
  var pageType = Props.pageType;
  var recurringJob = Props.recurringJob;
  return React.createElement("tr", undefined, React.createElement("td", undefined, recurringJobRoute(wire, recurringJob)), templateColumn(pageType, recurringJob), React.createElement("td", undefined, timeToString(wire, recurringJob.nextScheduledAt)));
}

function RelatedEntitiesTable$TableHeader(Props) {
  var param = Props.wire;
  var state = Props.state;
  var jobsCount = Props.jobsCount;
  var sitesCount = Props.sitesCount;
  var recurringJobsCount = Props.recurringJobsCount;
  var pageType = Props.pageType;
  var ctx = param.ctx;
  var match = state.tab;
  var exit = 0;
  if (match.NAME === "ClientSite") {
    exit = match.VAL === "Jobs" ? 1 : 2;
  } else {
    var match$1 = match.VAL;
    if (match$1 === "Sites") {
      if (sitesCount > 0) {
        return React.createElement("tr", undefined, React.createElement("th", undefined, name$p(ctx)), React.createElement("th", undefined, address$p(ctx)), React.createElement("th", undefined, phone$p(ctx)));
      } else {
        return null;
      }
    }
    exit = match$1 === "Jobs" ? 1 : 2;
  }
  switch (exit) {
    case 1 :
        if (jobsCount > 0) {
          return React.createElement("tr", undefined, React.createElement("th", {
                          className: styles.tableNumber
                        }, number$p(ctx)), React.createElement("th", {
                          className: styles.tableScheduledAt
                        }, scheduledAt$p(ctx)), pageType === "Template" ? React.createElement("th", {
                            className: styles.tableAddress
                          }, address$p(ctx)) : React.createElement("th", {
                            className: styles.tableTemplate
                          }, template$p(ctx)), React.createElement("th", {
                          className: styles.tableWorker
                        }, worker$p(ctx)), React.createElement("th", {
                          className: styles.tableStatus
                        }, tableStatus$p(ctx)));
        } else {
          return null;
        }
    case 2 :
        if (recurringJobsCount > 0) {
          return React.createElement("tr", undefined, React.createElement("th", {
                          className: styles.tableName
                        }, name$p(ctx)), pageType === "Template" ? null : React.createElement("th", {
                            className: styles.tableTemplateOptional
                          }, template$p(ctx)), React.createElement("th", {
                          className: styles.tableNextScheduledAt
                        }, nextScheduledAt$p(ctx)));
        } else {
          return null;
        }
    
  }
}

function RelatedEntitiesTable$TableBody(Props) {
  var wire = Props.wire;
  var state = Props.state;
  var pageType = Props.pageType;
  var listType = Props.listType;
  var jobsCount = Props.jobsCount;
  var sitesCount = Props.sitesCount;
  var recurringJobsCount = Props.recurringJobsCount;
  var ctx = wire.ctx;
  var match = state.tab;
  var exit = 0;
  if (match.NAME === "ClientSite") {
    exit = match.VAL === "Jobs" ? 2 : 3;
  } else {
    var match$1 = match.VAL;
    if (match$1 === "Sites") {
      if (sitesCount > 0) {
        return state.sites.map(function (site) {
                    return React.createElement(RelatedEntitiesTable$RelatedEntitiesItemSite, {
                                wire: wire,
                                site: site,
                                key: Uuid.toString(site.uuid)
                              });
                  });
      }
      
    } else {
      exit = match$1 === "Jobs" ? 2 : 3;
    }
  }
  switch (exit) {
    case 2 :
        if (jobsCount > 0) {
          return state.jobs.map(function (job) {
                      return React.createElement(RelatedEntitiesTable$RelatedEntitiesItemJob, {
                                  wire: wire,
                                  job: job,
                                  pageType: pageType,
                                  key: Uuid.toString(job.uuid)
                                });
                    });
        }
        break;
    case 3 :
        if (recurringJobsCount > 0) {
          return state.recurringJobs.map(function (recurringJob) {
                      return React.createElement(RelatedEntitiesTable$RelatedEntitiesItemRecurringJob, {
                                  wire: wire,
                                  pageType: pageType,
                                  recurringJob: recurringJob,
                                  key: Uuid.toString(recurringJob.uuid)
                                });
                    });
        }
        break;
    
  }
  if (typeof match === "object" && match.NAME === "ClientSite" && listType === "JobsAndRec" && (pageType === "RecurringJob" || pageType === "Template")) {
    return React.createElement("tr", undefined, React.createElement("td", {
                    className: styles.tableNotFoundWithMargin
                  }, notFound$p(pageType, ctx)));
  } else {
    return React.createElement("tr", undefined, React.createElement("td", {
                    className: styles.tableNotFound
                  }, notFound$p(pageType, ctx)));
  }
}

function RelatedEntitiesTable(Props) {
  var wire = Props.wire;
  var state = Props.state;
  var pageType = Props.pageType;
  var listType = Props.listType;
  var jobsCount = Props.jobsCount;
  var sitesCount = Props.sitesCount;
  var recurringJobsCount = Props.recurringJobsCount;
  return React.createElement("table", {
              className: "table"
            }, React.createElement("thead", undefined, React.createElement(RelatedEntitiesTable$TableHeader, {
                      wire: wire,
                      state: state,
                      jobsCount: jobsCount,
                      sitesCount: sitesCount,
                      recurringJobsCount: recurringJobsCount,
                      pageType: pageType
                    })), React.createElement("tbody", undefined, React.createElement(RelatedEntitiesTable$TableBody, {
                      wire: wire,
                      state: state,
                      pageType: pageType,
                      listType: listType,
                      jobsCount: jobsCount,
                      sitesCount: sitesCount,
                      recurringJobsCount: recurringJobsCount
                    })));
}

var make = RelatedEntitiesTable;

export {
  make ,
}
/* styles Not a pure module */
