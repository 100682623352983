

import * as El from "../../../../libraries/El.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Billing_Types from "../Billing_Types.mjs";
import * as BillingModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/billing/Billing.module.css";

var months$p = Utils.Translations.txr("js.billing.wizard.months");

var discount$p = Utils.Translations.txr("js.billing.wizard.discount");

function discount(period) {
  if (period === "year") {
    return 15;
  } else if (period === "six") {
    return 10;
  } else if (period === "three") {
    return 5;
  } else {
    return ;
  }
}

var styles = BillingModuleCss;

function Billing_Components_Periods(Props) {
  var ctx = Props.ctx;
  var onClick = Props.onClick;
  var periods = Props.periods;
  var order = Props.order;
  var custom = Props.custom;
  var planAlternatives = Props.planAlternatives;
  var p = function (period, index) {
    var planExists = planAlternatives.some(function (param) {
          return param.monthsCycle === period;
        });
    var className = period === order.monthsCycle ? styles.current : "";
    var className$1 = planExists ? className : El.Cn.concat(className, styles.unavailablePeriod);
    var d = discount(period);
    var tmp;
    if (d !== undefined) {
      var className$2 = custom ? El.Cn.concat(styles.discount, styles.invisibleDiscount) : styles.discount;
      tmp = React.createElement("div", {
            className: className$2
          }, discount$p({
                discount: d
              }, ctx));
    } else {
      tmp = null;
    }
    return React.createElement("td", {
                key: index.toString(),
                className: className$1,
                onClick: onClick(period)
              }, React.createElement("div", undefined, months$p({
                        count: Billing_Types.Order.Cycle.toInt(period)
                      }, ctx)), tmp);
  };
  return React.createElement("table", {
              className: styles.periods
            }, React.createElement("tbody", undefined, React.createElement("tr", undefined, periods.map(p))));
}

var Types;

var make = Billing_Components_Periods;

export {
  Types ,
  make ,
}
/* months' Not a pure module */
