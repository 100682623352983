

import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as React from "react";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JobEdit_API from "../../../lib/state/api/JobEdit_API.mjs";
import * as Subscription from "../../../../../../libraries/Subscription.mjs";
import * as Shared_Lib_IO from "../../../../../../shared/lib/Shared_Lib_IO.mjs";
import * as JobForm_HistoryMap from "./components/map/JobForm_HistoryMap.mjs";
import * as Shared_Lib_Reducer from "../../../../../../shared/lib/Shared_Lib_Reducer.mjs";
import * as JobForm_HistoryEvents from "./components/events/JobForm_HistoryEvents.mjs";
import * as JobForm_HistoryHeader from "./components/header/JobForm_HistoryHeader.mjs";
import * as JobForm_HistoryLoader from "./components/loader/JobForm_HistoryLoader.mjs";
import * as JobForm_HistoryFakeData from "../../../lib/utils/JobForm_HistoryFakeData.mjs";
import * as JobForm_HistoryModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/edit/form/components/history/JobForm_History.module.css";

var styles = JobForm_HistoryModuleCss;

function reducer(param) {
  var action = param[1];
  var state = param[0];
  if (typeof action !== "object") {
    return "NoUpdate";
  }
  switch (action.TAG) {
    case "FetchLocations" :
        return {
                TAG: "IO",
                _0: Shared_Lib_IO.bimap(JobEdit_API.$$History.DayLocations.Get.$$fetch(action._0, action._1, Locale.T.formatM("DateISO")(action._2)), (function (a) {
                        return {
                                TAG: "FetchLocationsResult",
                                _0: a
                              };
                      }), (function (param) {
                        return "NoOp";
                      }))
              };
    case "FetchLocationsResult" :
        var res = action._0;
        if (res.TAG !== "Ok") {
          return "NoUpdate";
        }
        var res$1 = res._0;
        var newLocations = state.history.locations.map(function (group) {
              if (Locale.T.equal(res$1.date, group.date)) {
                return {
                        date: group.date,
                        locations: res$1.locations
                      };
              } else {
                return group;
              }
            });
        var init = state.history;
        return {
                TAG: "Update",
                _0: {
                  history: {
                    addressFormatted: init.addressFormatted,
                    addressGeolocation: init.addressGeolocation,
                    addressMarkerLabel: init.addressMarkerLabel,
                    eventsGrouped: init.eventsGrouped,
                    locations: newLocations,
                    dates: init.dates
                  },
                  mapDate: state.mapDate
                }
              };
    case "SetDate" :
        return {
                TAG: "Update",
                _0: {
                  history: state.history,
                  mapDate: Caml_option.some(action._0)
                }
              };
    
  }
}

function JobForm_History$HistoryComponent(Props) {
  var wire = Props.wire;
  var toggleHistory = Props.toggleHistory;
  var history = Props.history;
  var uuid = Props.uuid;
  var mapDate = history.dates[0];
  var match = Shared_Lib_Reducer.use(reducer, {
        history: history,
        mapDate: mapDate
      });
  var send = match[1];
  var state = match[0];
  var match$1 = state.history;
  var locations = match$1.locations;
  var eventsGrouped = match$1.eventsGrouped;
  var match$2 = React.useState(function () {
        return Subscription.make();
      });
  var mapEventsSubscription = match$2[0];
  var onSelectDate = function (selectedDate) {
    var date = state.mapDate;
    if (date === undefined) {
      return ;
    }
    if (Locale.T.equal(Caml_option.valFromOption(date), selectedDate)) {
      return ;
    }
    send({
          TAG: "SetDate",
          _0: selectedDate
        });
    var dateWithLocations = RCore.$$Array.getBy(locations, (function (group) {
            return Locale.T.equal(group.date, selectedDate);
          }));
    if (dateWithLocations === undefined) {
      return ;
    }
    var match = dateWithLocations.locations;
    if (match !== undefined) {
      return ;
    } else {
      return send({
                  TAG: "FetchLocations",
                  _0: wire,
                  _1: uuid,
                  _2: selectedDate
                });
    }
  };
  return React.createElement("div", {
              className: styles.wrapper
            }, React.createElement("div", {
                  className: styles.eventsMapWrapper
                }, React.createElement("div", {
                      className: styles.container
                    }, React.createElement(JobForm_HistoryHeader.Title.make, {
                          ctx: wire.ctx
                        }), React.createElement(JobForm_HistoryEvents.make, {
                          wire: wire,
                          eventsGrouped: eventsGrouped,
                          triggerMapEvent: mapEventsSubscription.trigger,
                          onSelectDate: onSelectDate
                        })), React.createElement(JobForm_HistoryMap.make, {
                      wire: wire,
                      eventsGrouped: eventsGrouped,
                      addressMarkerLabel: match$1.addressMarkerLabel,
                      addressGeolocation: match$1.addressGeolocation,
                      locations: locations,
                      mapEventsSubscription: mapEventsSubscription,
                      toggleHistory: toggleHistory,
                      selectedDate: state.mapDate,
                      onSelectDate: onSelectDate,
                      dates: match$1.dates,
                      triggerMapEvent: mapEventsSubscription.trigger
                    })));
}

function JobForm_History(Props) {
  var wire = Props.wire;
  var uuid = Props.uuid;
  var toggleHistory = Props.toggleHistory;
  var match = React.useState(function () {
        return {
                state: undefined,
                requestStartedAtMs: Date.now()
              };
      });
  var setState = match[1];
  var match$1 = match[0];
  var requestStartedAtMs = match$1.requestStartedAtMs;
  var state = match$1.state;
  React.useEffect((function () {
          Shared_Lib_IO.unsafeRunAsync(JobEdit_API.$$History.Get.$$fetch(wire, uuid), (function (result) {
                  if (result.TAG === "Ok") {
                    var response = result._0;
                    if (response.TAG === "Ok") {
                      var history = response._0;
                      var nowMs = Date.now();
                      if (requestStartedAtMs !== undefined && nowMs - requestStartedAtMs > 1000) {
                        setTimeout((function () {
                                setState(function (param) {
                                      return {
                                              state: history,
                                              requestStartedAtMs: undefined
                                            };
                                    });
                              }), 1000 - (nowMs - requestStartedAtMs) | 0);
                        return ;
                      }
                      return setState(function (param) {
                                  return {
                                          state: history,
                                          requestStartedAtMs: undefined
                                        };
                                });
                    }
                    console.log(response._0);
                    return ;
                  }
                  
                }));
        }), []);
  var tmp;
  if (state !== undefined) {
    var match$2 = wire.ctx.env;
    var history = match$2 === "development" ? ({
          addressFormatted: state.addressFormatted,
          addressGeolocation: state.addressGeolocation,
          addressMarkerLabel: state.addressMarkerLabel,
          eventsGrouped: JobForm_HistoryFakeData.grouped(wire.ctx),
          locations: JobForm_HistoryFakeData.Locations.byDate(wire.ctx),
          dates: JobForm_HistoryFakeData.dates
        }) : state;
    tmp = React.createElement(JobForm_History$HistoryComponent, {
          wire: wire,
          toggleHistory: toggleHistory,
          history: history,
          uuid: uuid
        });
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: styles.wrapper
            }, React.createElement(JobForm_HistoryLoader.make, {
                  show: RCore.$$Option.isNone(state),
                  children: tmp
                }));
}

var make = JobForm_History;

export {
  make ,
}
/* styles Not a pure module */
