

import * as El from "../../libraries/El.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../libraries/Locale.mjs";
import * as Optional from "../common/Optional.mjs";
import * as AppContext from "../../context/AppContext.mjs";
import * as BackgroundTaskIcon from "./BackgroundTaskIcon.mjs";
import * as BackgroundBulkModal from "./BackgroundBulkModal.mjs";
import * as BackgroundRowModuleCss from "/home/runner/work/planado/planado/client/rescript/components/background_task/BackgroundRow.module.css";

var styles = BackgroundRowModuleCss;

var cancel$p = Utils.Translations.tr("js.background_tasks.bulk.cancel");

var openModal$p = Utils.Translations.tr("js.background_tasks.bulk.open_modal");

var bulkCanceled$p = Utils.Translations.tr("js.background_tasks.bulk.user_canceled");

var bulkError$p = Utils.Translations.tr("js.background_tasks.bulk.errors.interrupted");

function withJobsCount(text, count, ctx) {
  return React.createElement("span", undefined, text, El.space, El.ndash, El.space, Utils.Translations.txr("js.background_tasks.bulk.jobs")({
                  count: count
                }, ctx));
}

function delete$p(count, ctx) {
  return withJobsCount(Utils.Translations.tr("js.background_tasks.bulk.delete")(ctx), count, ctx);
}

function schedule$p(count, ctx) {
  return withJobsCount(Utils.Translations.tr("js.background_tasks.bulk.schedule")(ctx), count, ctx);
}

function assign$p(count, ctx) {
  return withJobsCount(Utils.Translations.tr("js.background_tasks.bulk.assign")(ctx), count, ctx);
}

function BackgroundBulkRow(Props) {
  var task = Props.task;
  var cancel = Props.cancel;
  var retry = Props.retry;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return false;
      });
  var setShowModal = match[1];
  var renderFinishTime = React.createElement("div", {
        className: styles.time
      }, RCore.$$Option.getOr(RCore.$$Option.map(task.finishedAt, Locale.T.fmtM("DateTime", wire)), ""));
  var operation = task.type_;
  var firstRow;
  if (typeof operation !== "object") {
    firstRow = null;
  } else {
    var operation$1 = operation._0;
    switch (operation$1.TAG) {
      case "Delete" :
          firstRow = delete$p(operation$1._0, ctx);
          break;
      case "Schedule" :
          firstRow = schedule$p(operation$1._0, ctx);
          break;
      case "AssignMultiple" :
          firstRow = assign$p(operation$1._0, ctx);
          break;
      
    }
  }
  var match$1 = task.status;
  var secondRow;
  if (typeof match$1 !== "object") {
    switch (match$1) {
      case "Waiting" :
      case "InProgress" :
          secondRow = null;
          break;
      case "Canceled" :
          secondRow = React.createElement("div", {
                className: styles.errorContainer
              }, renderFinishTime, React.createElement("div", {
                    className: styles.error
                  }, bulkCanceled$p(ctx)));
          break;
      
    }
  } else {
    secondRow = match$1._0 ? renderFinishTime : React.createElement("div", {
            className: styles.errorContainer
          }, renderFinishTime, React.createElement("div", {
                className: styles.error
              }, bulkError$p(ctx)));
  }
  var renderName = React.createElement("div", {
        className: styles.name
      }, React.createElement("div", undefined, firstRow), React.createElement("div", undefined, secondRow));
  var onCancelClick = function (evt) {
    evt.preventDefault();
    cancel(task.uuid);
  };
  var match$2 = task.status;
  var tmp;
  var exit = 0;
  if (typeof match$2 !== "object") {
    switch (match$2) {
      case "Waiting" :
      case "InProgress" :
          exit = 1;
          break;
      case "Canceled" :
          exit = 2;
          break;
      
    }
  } else if (match$2._0) {
    var match$3 = task.type_;
    var exit$1 = 0;
    if (typeof match$3 !== "object") {
      exit$1 = 3;
    } else {
      switch (match$3._0.TAG) {
        case "Delete" :
            tmp = RCore.$$Option.getOr(task.errors, []).length === 0 ? null : React.createElement("a", {
                    onClick: (function (param) {
                        setShowModal(function (param) {
                              return true;
                            });
                      })
                  }, openModal$p(ctx));
            break;
        case "Schedule" :
        case "AssignMultiple" :
            exit$1 = 3;
            break;
        
      }
    }
    if (exit$1 === 3) {
      tmp = React.createElement("a", {
            onClick: (function (param) {
                setShowModal(function (param) {
                      return true;
                    });
              })
          }, openModal$p(ctx));
    }
    
  } else {
    exit = 2;
  }
  switch (exit) {
    case 1 :
        tmp = React.createElement("a", {
              onClick: onCancelClick
            }, cancel$p(ctx));
        break;
    case 2 :
        tmp = React.createElement("a", {
              onClick: (function (param) {
                  setShowModal(function (param) {
                        return true;
                      });
                })
            }, openModal$p(ctx));
        break;
    
  }
  var renderAction = React.createElement("div", {
        className: styles.action
      }, tmp);
  var onRetry = function (uuid, removeUuids) {
    setShowModal(function (param) {
          return false;
        });
    retry(uuid, removeUuids);
  };
  var operation$2 = task.type_;
  var tmp$1;
  tmp$1 = typeof operation$2 !== "object" ? null : React.createElement(BackgroundBulkModal.make, {
          taskUuid: task.uuid,
          operation: operation$2._0,
          onClose: (function () {
              setShowModal(function (param) {
                    return false;
                  });
            }),
          retry: onRetry
        });
  return React.createElement("div", {
              className: styles.item
            }, React.createElement(BackgroundTaskIcon.make, {
                  task: task
                }), renderName, renderAction, React.createElement(Optional.make, {
                  show: match[0],
                  children: tmp$1
                }));
}

var make = BackgroundBulkRow;

export {
  make ,
}
/* styles Not a pure module */
