

import * as Fun from "../../../../libraries/Fun.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as MapDateScroll from "../../MapDateScroll.mjs";
import * as DateRangePicker from "../../../inputs/DateRangePicker.mjs";
import * as MapJobsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/map/jobs/MapJobs.module.css";

var styles = MapJobsModuleCss;

var period$p = Utils.Translations.tr("js.map.scheduling.period");

function toPeriod(today, date) {
  if (date.TAG === "Date") {
    var date$1 = date._0;
    if (Locale.T.equal(date$1, today)) {
      return "Today";
    } else {
      return {
              NAME: "Date",
              VAL: date$1
            };
    }
  }
  var match = date._0;
  var a = match[0];
  if (a === undefined) {
    return ;
  }
  var b = match[1];
  if (b !== undefined) {
    return {
            NAME: "Period",
            VAL: [
              Caml_option.valFromOption(a),
              Caml_option.valFromOption(b)
            ]
          };
  }
  
}

function MapJobsFilterByDate(Props) {
  var wire = Props.wire;
  var period = Props.period;
  var onChange = Props.onChange;
  var today = React.useMemo(function () {
        return Locale.T.startOf("day", Locale.T.now(wire));
      });
  var match = React.useState(function () {
        var max = function (x) {
          return Locale.T.max(today, x);
        };
        if (typeof period !== "object") {
          return {
                  TAG: "Date",
                  _0: today
                };
        }
        if (period.NAME === "Date") {
          return {
                  TAG: "Date",
                  _0: max(period.VAL)
                };
        }
        var match = period.VAL;
        return {
                TAG: "Period",
                _0: [
                  Caml_option.some(max(match[0])),
                  Caml_option.some(max(match[1]))
                ]
              };
      });
  var setFilter = match[1];
  var filter = match[0];
  React.useEffect((function () {
          var filter$1 = toPeriod(today, filter);
          if (filter$1 !== undefined && Caml_obj.notequal(filter$1, period)) {
            onChange(filter$1);
          }
          
        }), [filter]);
  var setFirstDate = function () {
    setFilter(function (param) {
          return {
                  TAG: "Period",
                  _0: [
                    Caml_option.some(today),
                    undefined
                  ]
                };
        });
  };
  var setPeriodDate = function (period) {
    setFilter(function (param) {
          return {
                  TAG: "Period",
                  _0: period
                };
        });
  };
  var setDefaultDate = function () {
    setFilter(function (param) {
          return {
                  TAG: "Date",
                  _0: today
                };
        });
  };
  var setDate = function (date) {
    setFilter(function (param) {
          return {
                  TAG: "Date",
                  _0: date
                };
        });
  };
  var filterBlock;
  if (filter.TAG === "Date") {
    filterBlock = React.createElement(React.Fragment, undefined, React.createElement(MapDateScroll.make, {
              wire: wire,
              size: 3,
              minimalDate: today,
              current: filter._0,
              onChange: setDate
            }), React.createElement("button", {
              className: "btn btn-transparent",
              onClick: Fun.omit(setFirstDate)
            }, period$p(wire.ctx)));
  } else {
    var match$1 = filter._0;
    filterBlock = React.createElement(DateRangePicker.make, {
          wire: wire,
          startDate: match$1[0],
          endDate: match$1[1],
          onChange: setPeriodDate,
          onClear: setDefaultDate,
          minimalDate: today
        });
  }
  return React.createElement("div", {
              className: styles.dateFilter
            }, filterBlock);
}

var make = MapJobsFilterByDate;

export {
  make ,
}
/* styles Not a pure module */
