import React from 'react'
import { recurringJobPath } from 'planado/routes.js'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import { showTime } from 'planado/utils/time/index.js'
import Link from 'rscrpt/components/common/Link/Link.mjs'

const Header = withContext(({ ctx }) => (
  <thead>
    <tr>
      <th>{ctx.t('js.recurring_jobs.table.name')}</th>
      <th>{ctx.t('js.recurring_jobs.table.site_client')}</th>
      <th>{ctx.t('js.recurring_jobs.table.created_at')}</th>
      <th>{ctx.t('js.recurring_jobs.table.interval')}</th>
      <th>{ctx.t('js.recurring_jobs.table.next_scheduled_at')}</th>
    </tr>
  </thead>
))

const Row = withContext(
  ({
    uuid,
    name,
    clientName,
    siteName,
    createdAt,
    period,
    periodCount,
    nextScheduledAt,
    ctx,
  }) => {
    let siteOrClientName
    if (siteName && clientName) {
      siteOrClientName = `${siteName} (${clientName})`
    } else {
      siteOrClientName = siteName || clientName
    }

    return (
      <tr>
        <td>
          <Link wire={ctx.wire} pathname={recurringJobPath(uuid)}>
            {name}
          </Link>
        </td>
        <td>{siteOrClientName}</td>
        <td>{showTime(createdAt, 'datetime', ctx)}</td>
        <td>{`${periodCount} ${ctx.t(
          `js.recurring_jobs.intervals.${period}`
        )}`}</td>
        <td>{showTime(nextScheduledAt, 'datetime', ctx)}</td>
      </tr>
    )
  }
)

export default withContext(({ ctx, recurringJobs }) => {
  if (recurringJobs.length === 0) {
    return <div>{ctx.t('js.recurring_jobs.you_have_no_recurring_jobs')}</div>
  } else {
    return (
      <div>
        <table className="table table-hover">
          <Header />

          <tbody>
            {recurringJobs.map((j) => (
              <Row key={j.uuid} {...j} />
            ))}
          </tbody>
        </table>
      </div>
    )
  }
})
