

import * as El from "../../../../../../libraries/El.mjs";
import * as React from "react";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Schedule_DND from "../../../../lib/Schedule_DND.mjs";
import * as Schedule_Constants from "../../../../lib/Schedule_Constants.mjs";
import * as Schedule_Types_Job from "../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as Schedule_JobPreviewForm from "../../job_preview/form/Schedule_JobPreviewForm.mjs";
import * as Schedule_JobPreviewInfo from "../../job_preview/info/Schedule_JobPreviewInfo.mjs";
import * as Schedule_JobPreviewFooter from "../../job_preview/footer/Schedule_JobPreviewFooter.mjs";
import * as Schedule_JobPreviewHeader from "../../job_preview/header/Schedule_JobPreviewHeader.mjs";
import * as Schedule_JobGroupCardModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/popovers/job_group/job_card/Schedule_JobGroupCard.module.css";

var styles = Schedule_JobGroupCardModuleCss;

function Schedule_JobGroupCard(Props) {
  var job = Props.job;
  var wire = AppContext.useWire();
  var match = React.useState(function () {
        return false;
      });
  var setHovered = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowForm = match$1[1];
  var showForm = match$1[0];
  var hasRightsToEdit = Schedule_Types_Job.ScheduledJob.hasRightsToEdit(job, wire);
  var toggleJobForm = function () {
    if (hasRightsToEdit) {
      return setShowForm(function (prev) {
                  return !prev;
                });
    }
    
  };
  var draggableJob_uuid = Schedule_Types_Job.ScheduledJob.uuid(job);
  var draggableJob_template = Schedule_Types_Job.ScheduledJob.template(job);
  var draggableJob_assignees = Schedule_Types_Job.ScheduledJob.assignees(job);
  var draggableJob_address = Schedule_Types_Job.ScheduledJob.address(job);
  var draggableJob_scheduledAt = Schedule_Types_Job.ScheduledJob.scheduledAt(job);
  var draggableJob_duration = Schedule_Types_Job.ScheduledJob.duration(job);
  var draggableJob = {
    uuid: draggableJob_uuid,
    template: draggableJob_template,
    bar: undefined,
    assignees: draggableJob_assignees,
    address: draggableJob_address,
    scheduledAt: draggableJob_scheduledAt,
    duration: draggableJob_duration
  };
  var match$2 = Schedule_DND.DND.Draggable.use(undefined, !showForm && hasRightsToEdit, Schedule_DND.DND.Context.makePayload(draggableJob), Schedule_Types_Job.ScheduledJob.assignees(job).length > 0 ? Schedule_Constants.dndScheduledJob : Schedule_Constants.dndUnassignedJob, undefined);
  var className = El.Cn.concat(El.Cn.concat(styles.container, Schedule_Types_Job.ScheduledJob.isStarted(job) ? styles.started : ""), Schedule_Types_Job.ScheduledJob.isEnRoute(job) ? styles.enRoute : "");
  return React.createElement("div", {
              ref: match$2[0],
              className: className,
              onMouseEnter: (function (param) {
                  setHovered(function (param) {
                        return true;
                      });
                }),
              onMouseLeave: (function (param) {
                  setHovered(function (param) {
                        return false;
                      });
                })
            }, React.createElement(Schedule_JobPreviewHeader.make, {
                  hovered: match[0],
                  job: job
                }), showForm ? React.createElement(Schedule_JobPreviewForm.make, {
                    job: job,
                    closePreview: (function (prim) {
                        
                      }),
                    closeForm: toggleJobForm
                  }) : React.createElement(Schedule_JobPreviewInfo.make, {
                    job: job,
                    toggleJobForm: toggleJobForm
                  }), React.createElement(Schedule_JobPreviewFooter.make, {
                  job: job
                }));
}

var make = React.memo(Schedule_JobGroupCard);

export {
  make ,
}
/* styles Not a pure module */
