import { async } from 'planado/utils/index.js'
import jQuery from 'jquery'

export default class NavigationController {
  locked = false

  constructor() {
    this.init = this.init.bind(this)
    this.scroll = this.scroll.bind(this)
    this.scrollTo = this.scrollTo.bind(this)
    this.scrollToDate = this.scrollToDate.bind(this)
    this.changeScroll = this.changeScroll.bind(this)
    this.load = this.load.bind(this)
    this.loadingComplete = this.loadingComplete.bind(this)
    this.loading = false
  }

  init($viewPort, chart) {
    this.$viewPort = $viewPort
    this.chart = chart
    this.viewPort = this.$viewPort[0]
    this.scrollPos = this.viewPort.scrollLeft
    this.$content = this.$viewPort.find('.chart-bars')
    this.updateWidth()
    this.navigator = this.chart.nav
    this.chart.on('change:scale', () => async(this.updateWidth))
    this.chart.on('goto', payload =>
      async(() => this.scrollToDate(payload.target))
    )

    this.scrollTo(this.chart.viewOffset(), true)
  }

  scroll() {
    const newScrollPos = this.viewPort.scrollLeft
    if (!this.$viewPort || this.locked || newScrollPos === this.scrollPos) {
      return
    }

    const direction = newScrollPos > this.scrollPos ? 'right' : 'left'

    if (!this.loading) {
      if (direction === 'left' && newScrollPos <= this.loadThreshold.left) {
        this.load('past')
      } else if (newScrollPos >= this.loadThreshold.right) {
        this.load('future')
      }
    }

    this.scrollPos = newScrollPos
  }

  scrollTo(value, force = false) {
    if (force) {
      this.$viewPort[0].scrollLeft = value
      return jQuery.Deferred().resolve()
    } else {
      return this.$viewPort.animate({ scrollLeft: value }, 200)
    }
  }

  scrollToDate(date) {
    this.scrollTo(this.chart.viewOffset(date))
  }

  changeScroll(value) {
    this.locked = true
    this.viewPort.scrollLeft = value
    setTimeout(() => (this.locked = false), 3)
  }

  load(direction) {
    this.$viewPort.addClass('loading')
    this.loading = true
    this.chart.load(direction).done(this.loadingComplete)
  }

  updateWidth = () => {
    this.width = this.$content.width()
    this.windowWidth = this.$viewPort.width()

    const marginPx = 100

    this.loadThreshold = {
      left: marginPx,
      right: this.width - this.windowWidth - marginPx
    }

    let startOffset = this.chart.viewOffset(this.chart.get('start'))
    let scrollThreshold = Math.floor(startOffset / 2)

    this.scrollThreshold = {
      left: scrollThreshold,
      right: this.width - this.windowWidth - scrollThreshold
    }
  }

  loadingComplete({ direction }) {
    setTimeout(() => {
      const sizeOfDay = this.navigator.hoursToOffset(24)
      const sizeOfWeek = this.navigator.hoursToOffset(168)
      const sizeOfTwoHour = this.navigator.hoursToOffset(2)

      const { scale } = this.chart.options.store.getState()

      let scrollLeft
      switch (scale) {
        case 'day': {
          if (direction === 'future') {
            scrollLeft = this.scrollPos - sizeOfDay + sizeOfTwoHour
          } else {
            scrollLeft = sizeOfDay - sizeOfTwoHour
          }
          break
        }
        case 'week': {
          if (direction === 'future') {
            scrollLeft = this.scrollPos - sizeOfWeek + sizeOfDay
          } else {
            scrollLeft = sizeOfWeek - sizeOfDay
          }
          break
        }
      }

      this.viewPort.scrollLeft = scrollLeft

      this.loading = false
      this.$viewPort.removeClass('loading')
    }, 100)
  }
}
