

import * as RCore from "../../libraries/RCore.mjs";
import * as Locale from "../../libraries/Locale.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as MapTypes from "./MapTypes.mjs";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as MultipleAssignee from "../../types/MultipleAssignee.mjs";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";

function mapSite(s) {
  return {
          uuid: s.uuid,
          name: s.name
        };
}

function mapClient(s) {
  return {
          uuid: s.uuid,
          name: s.name
        };
}

function mapLocation(l) {
  return {
          longitude: l.longitude,
          latitude: l.latitude
        };
}

function mapJob(j) {
  var assignees = Json_Decode$JsonCombinators.decode(j.assignees, MultipleAssignee.decode);
  var tmp;
  tmp = assignees.TAG === "Ok" ? assignees._0 : [];
  return {
          uuid: j.uuid,
          status: RCore.$$Option.getOr(MapTypes.Job.Status.fromString(j.status), "Posted"),
          serialNo: j.serial_no,
          clientUuid: Caml_option.null_to_opt(j.client_uuid),
          clientName: Caml_option.null_to_opt(j.client_name),
          site: RCore.$$Option.map(Caml_option.null_to_opt(j.site), mapSite),
          client: RCore.$$Option.map(Caml_option.null_to_opt(j.client), mapClient),
          formattedAddress: Caml_option.null_to_opt(j.formatted_address),
          location: RCore.$$Option.map(Caml_option.null_to_opt(j.location), mapLocation),
          apartment: Caml_option.null_to_opt(j.apartment),
          floor: Caml_option.null_to_opt(j.floor),
          entranceNo: Caml_option.null_to_opt(j.entrance_no),
          addressDescription: Caml_option.null_to_opt(j.address_description),
          scheduledAt: RCore.$$Option.map(Caml_option.null_to_opt(j.scheduled_at), Locale.T.make),
          scheduledFinishAt: RCore.$$Option.map(Caml_option.null_to_opt(j.scheduled_finish_at), Locale.T.make),
          enRouteAt: RCore.$$Option.map(Caml_option.null_to_opt(j.en_route_at), Locale.T.make),
          startedAt: RCore.$$Option.map(Caml_option.null_to_opt(j.started_at), Locale.T.make),
          finishedAt: RCore.$$Option.map(Caml_option.null_to_opt(j.finished_at), Locale.T.make),
          lastActivityAt: RCore.$$Option.map(Caml_option.null_to_opt(j.last_activity_at), Locale.T.make),
          templateName: Caml_option.null_to_opt(j.template_name),
          templateUuid: Caml_option.null_to_opt(j.template_uuid),
          assignees: tmp,
          resolution: Caml_option.null_to_opt(j.resolution),
          resolutionUuid: Caml_option.null_to_opt(j.resolution_uuid),
          successful: j.successful,
          territoryUuid: Caml_option.null_to_opt(j.territory_uuid),
          skillUuids: j.skill_uuids,
          typeUuid: Caml_option.null_to_opt(j.type_uuid)
        };
}

var load = JsonDecode.custom(function (json) {
      return mapJob(json);
    });

function show(wire, id) {
  return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Map",
                    VAL: {
                      NAME: "Job",
                      VAL: id
                    }
                  }
                }), load);
}

function patch(wire, job) {
  return Backend.patch(undefined, true, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "Map",
                VAL: {
                  NAME: "Job",
                  VAL: job.uuid
                }
              }
            }, {
              NAME: "Obj",
              VAL: {
                scheduled_at: Js_null.fromOption(RCore.$$Option.map(job.scheduledAt, Locale.T.toIsoM)),
                assignees: MultipleAssignee.serialize(MultipleAssignee.encode(job.assignees))
              }
            });
}

var Patch = {
  patch: patch
};

function decode(js) {
  return js.current_jobs.map(mapJob);
}

function index(wire) {
  return Backend.get(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "Map",
                VAL: {
                  NAME: "Show",
                  VAL: "Workers"
                }
              }
            });
}

var Show = {
  decode: decode,
  index: index
};

var Jobs_Show = {
  show: show
};

var Jobs = {
  Show: Jobs_Show,
  Patch: Patch
};

export {
  Jobs ,
  Show ,
}
/* load Not a pure module */
