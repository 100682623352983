

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as UI_Form from "../../ui/UI_Form.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../components/inputs/Checkbox.mjs";
import * as Optional from "../../components/common/Optional.mjs";
import * as UI_Button from "../../ui/UI_Button.mjs";
import * as AppContext from "../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as MessengerTypes from "../../components/messenger/MessengerTypes.mjs";
import * as JobForm_Carousel from "../../components/job/edit/form/hooks/JobForm_Carousel.mjs";
import * as JobAttachmentInput from "../../components/file/job_attachment_input/JobAttachmentInput.mjs";
import * as JobForm_Carousel_Utils from "../../components/job/edit/form/hooks/JobForm_Carousel_Utils.mjs";
import * as Pages_Client_Portal_API from "./Pages_Client_Portal_API.mjs";
import * as Pages_Client_Portal_Types from "./Pages_Client_Portal_Types.mjs";
import * as Pages_Client_Portal_SettingsModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/client_portal/Pages_Client_Portal_Settings.module.css";

var logo$p = Utils.Translations.tr("js.client_portal.settings.logo");

var fields$p = Utils.Translations.tr("js.client_portal.settings.fields");

var templates$p = Utils.Translations.tr("js.client_portal.settings.templates");

var selected$p = Utils.Translations.txr("js.client_portal.settings.selected");

var templateInfo$p = Utils.Translations.tr("js.client_portal.settings.template_info");

var jobFields$p = Utils.Translations.tr("js.client_portal.settings.job_fields");

var customFields$p = Utils.Translations.tr("js.client_portal.settings.custom_fields");

var reportFields$p = Utils.Translations.tr("js.client_portal.settings.report_fields");

var save$p = Utils.Translations.tr("common.save");

var updated$p = Utils.Translations.t("js.client_portal.settings.updated");

var internalError$p = Utils.Translations.t("js.client_portal.errors.internal_error");

function jobField$p(field) {
  switch (field) {
    case "Address" :
        return Utils.Translations.t("js.jobs.index.heading.address");
    case "Assignee" :
        return Utils.Translations.t("js.jobs.index.heading.assignee");
    case "CreatedAt" :
        return Utils.Translations.t("js.jobs.index.heading.created_at");
    case "Description" :
        return Utils.Translations.t("js.jobs.index.heading.description");
    case "EnRouteAt" :
        return Utils.Translations.t("js.jobs.index.heading.en_route_at");
    case "FinishedAt" :
        return Utils.Translations.t("js.jobs.index.heading.finished_at");
    case "Resolution" :
        return Utils.Translations.t("js.jobs.index.heading.resolution_name");
    case "ScheduledAt" :
        return Utils.Translations.t("js.jobs.index.heading.scheduled_at");
    case "ScheduledDuration" :
        return Utils.Translations.t("js.jobs.index.heading.scheduled_duration");
    case "StartedAt" :
        return Utils.Translations.t("js.jobs.index.heading.started_at");
    case "Status" :
        return Utils.Translations.t("js.jobs.index.heading.status");
    case "Template" :
        return Utils.Translations.t("js.jobs.index.heading.template");
    case "WorkDuration" :
        return Utils.Translations.t("js.jobs.index.heading.work_duration");
    
  }
}

var styles = Pages_Client_Portal_SettingsModuleCss;

function Pages_Client_Portal_Settings$TemplateCheckbox(Props) {
  var uuid = Props.uuid;
  var name = Props.name;
  var checked = Props.checked;
  var disabled = Props.disabled;
  var setPortal = Props.setPortal;
  var onToggle = function (_e) {
    setPortal(function (current) {
          if (checked) {
            var init = current.settings;
            return {
                    uuid: current.uuid,
                    logo: current.logo,
                    users: current.users,
                    settings: {
                      templateUuids: RCore.$$Array.keep(current.settings.templateUuids, (function (u) {
                              return !Uuid.equal(u, uuid);
                            })),
                      columns: init.columns
                    }
                  };
          }
          var init$1 = current.settings;
          return {
                  uuid: current.uuid,
                  logo: current.logo,
                  users: current.users,
                  settings: {
                    templateUuids: Belt_Array.concatMany([
                          current.settings.templateUuids,
                          [uuid]
                        ]),
                    columns: init$1.columns
                  }
                };
        });
  };
  return React.createElement(Checkbox.make, {
              onToggle: onToggle,
              checked: checked,
              label: name,
              disabled: disabled,
              wrapperClasses: Js_dict.fromList({
                    hd: [
                      styles.checkbox,
                      true
                    ],
                    tl: /* [] */0
                  })
            });
}

function Pages_Client_Portal_Settings$JobFieldCheckbox(Props) {
  var field = Props.field;
  var checked = Props.checked;
  var disabled = Props.disabled;
  var setPortal = Props.setPortal;
  var ctx = Props.ctx;
  var onToggle = function (_e) {
    setPortal(function (current) {
          if (checked) {
            var init = current.settings;
            var init$1 = current.settings.columns;
            return {
                    uuid: current.uuid,
                    logo: current.logo,
                    users: current.users,
                    settings: {
                      templateUuids: init.templateUuids,
                      columns: {
                        jobFields: RCore.$$Array.keep(current.settings.columns.jobFields, (function (f) {
                                return f !== field;
                              })),
                        customFields: init$1.customFields,
                        reportFields: init$1.reportFields
                      }
                    }
                  };
          }
          var init$2 = current.settings;
          var init$3 = current.settings.columns;
          return {
                  uuid: current.uuid,
                  logo: current.logo,
                  users: current.users,
                  settings: {
                    templateUuids: init$2.templateUuids,
                    columns: {
                      jobFields: Belt_Array.concatMany([
                            current.settings.columns.jobFields,
                            [field]
                          ]),
                      customFields: init$3.customFields,
                      reportFields: init$3.reportFields
                    }
                  }
                };
        });
  };
  return React.createElement(Checkbox.make, {
              onToggle: onToggle,
              checked: checked,
              label: jobField$p(field)(ctx),
              disabled: disabled,
              wrapperClasses: Js_dict.fromList({
                    hd: [
                      styles.checkbox,
                      true
                    ],
                    tl: /* [] */0
                  }),
              checkboxClass: Js_dict.fromList({
                    hd: [
                      styles.wordBreak,
                      true
                    ],
                    tl: /* [] */0
                  })
            });
}

function Pages_Client_Portal_Settings$CustomReportFieldCheckbox(Props) {
  var uuid = Props.uuid;
  var name = Props.name;
  var checked = Props.checked;
  var disabled = Props.disabled;
  var onSelect = Props.onSelect;
  var onUnselect = Props.onUnselect;
  var onToggle = function (_e) {
    if (checked) {
      return onUnselect(uuid);
    } else {
      return onSelect(uuid);
    }
  };
  return React.createElement(Checkbox.make, {
              onToggle: onToggle,
              checked: checked,
              label: name,
              disabled: disabled,
              wrapperClasses: Js_dict.fromList({
                    hd: [
                      styles.checkbox,
                      true
                    ],
                    tl: /* [] */0
                  }),
              checkboxClass: Js_dict.fromList({
                    hd: [
                      styles.wordBreak,
                      true
                    ],
                    tl: /* [] */0
                  })
            });
}

function Pages_Client_Portal_Settings(Props) {
  var portal = Props.portal;
  var setPortal = Props.setPortal;
  var templates = Props.templates;
  var customFields = Props.customFields;
  var reportFields = Props.reportFields;
  var initialPortal = React.useMemo((function () {
          return portal;
        }), []);
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return false;
      });
  var setDisabled = match[1];
  var disabled = match[0];
  var attachments = RCore.$$Option.mapOr(portal.logo, [], (function (l) {
          return [l];
        }));
  var match$1 = JobForm_Carousel.use(JobForm_Carousel_Utils.extractCarouselImages("", attachments));
  var selectedTemplates = RCore.$$Array.keep(templates, (function (t) {
          return portal.settings.templateUuids.some(function (uuid) {
                      return Uuid.equal(uuid, t.uuid);
                    });
        }));
  var selectedJobFields = RCore.$$Array.keep(Pages_Client_Portal_Types.State.Portal.Settings.Columns.JobField.allFields, (function (f) {
          return portal.settings.columns.jobFields.some(function (field) {
                      return field === f;
                    });
        }));
  var selectedCustomFields = RCore.$$Array.keep(customFields, (function (f) {
          return portal.settings.columns.customFields.some(function (uuid) {
                      return Uuid.equal(uuid, f.uuid);
                    });
        }));
  var selectedReportFields = RCore.$$Array.keep(reportFields, (function (f) {
          return portal.settings.columns.reportFields.some(function (uuid) {
                      return Uuid.equal(uuid, f.uuid);
                    });
        }));
  var onSubmit = function ($$event) {
    $$event.preventDefault();
    setDisabled(function (param) {
          return true;
        });
    Backend.$$finally(Pages_Client_Portal_API.Settings.Update.patch(wire, portal), (function (r) {
            if (r.TAG === "Ok") {
              wire.subscriptions.messenger.trigger({
                    TAG: "Show",
                    _0: MessengerTypes.Message.make(undefined, "Success", {
                          TAG: "Text",
                          _0: updated$p(ctx)
                        })
                  });
            } else {
              console.log(r._0);
              wire.subscriptions.messenger.trigger({
                    TAG: "Show",
                    _0: MessengerTypes.Message.make(undefined, "Danger", {
                          TAG: "Text",
                          _0: internalError$p(ctx)
                        })
                  });
            }
            setDisabled(function (param) {
                  return false;
                });
          }));
  };
  return React.createElement("div", {
              className: styles.wrapper
            }, React.createElement(UI_Form.Group.make, {
                  className: styles.noExtraBottomPadding,
                  width: "half",
                  children: null
                }, React.createElement("div", {
                      className: styles.sectionTitle
                    }, logo$p(ctx)), React.createElement(JobAttachmentInput.make, {
                      files: attachments,
                      addFile: (function (file) {
                          setPortal(function (current) {
                                return {
                                        uuid: current.uuid,
                                        logo: file,
                                        users: current.users,
                                        settings: current.settings
                                      };
                              });
                        }),
                      removeFile: (function (param) {
                          setPortal(function (current) {
                                return {
                                        uuid: current.uuid,
                                        logo: undefined,
                                        users: current.users,
                                        settings: current.settings
                                      };
                              });
                        }),
                      setLock: (function (locked) {
                          setDisabled(function (param) {
                                return locked;
                              });
                        }),
                      readOnly: false,
                      maxFilesInField: 1,
                      onlyImages: true,
                      showCarouselImage: match$1.showCarouselImage
                    })), React.createElement(UI_Form.Group.make, {
                  width: "half",
                  children: null
                }, React.createElement("div", {
                      className: styles.templatesTitleWrapper
                    }, React.createElement("div", {
                          className: styles.sectionTitle
                        }, templates$p(ctx)), React.createElement("div", {
                          className: styles.selectedTemplates
                        }, selected$p({
                              count: selectedTemplates.length
                            }, ctx))), React.createElement("div", {
                      className: styles.templatesBlock
                    }, templateInfo$p(ctx), React.createElement("div", {
                          className: styles.dropdownList
                        }, templates.map(function (template) {
                              return React.createElement(Pages_Client_Portal_Settings$TemplateCheckbox, {
                                          uuid: template.uuid,
                                          name: template.name,
                                          checked: selectedTemplates.some(function (s) {
                                                return Uuid.equal(s.uuid, template.uuid);
                                              }),
                                          disabled: disabled,
                                          setPortal: setPortal,
                                          key: Uuid.toString(template.uuid)
                                        });
                            })))), React.createElement(UI_Form.Group.make, {
                  width: "full",
                  children: null
                }, React.createElement("div", {
                      className: styles.sectionTitle
                    }, fields$p(ctx)), React.createElement("div", {
                      className: styles.fieldsBlock
                    }, React.createElement("div", {
                          className: styles.fieldsHeader
                        }, React.createElement("div", {
                              className: styles.column
                            }, jobFields$p(ctx)), React.createElement("div", {
                              className: styles.column
                            }, customFields$p(ctx)), React.createElement("div", {
                              className: styles.column
                            }, reportFields$p(ctx))), React.createElement("div", {
                          className: styles.dropdownList
                        }, React.createElement("div", {
                              className: styles.fieldsWrapper
                            }, React.createElement("div", {
                                  className: styles.column
                                }, Pages_Client_Portal_Types.State.Portal.Settings.Columns.JobField.alwaysOnFields.map(function (field) {
                                      return React.createElement(Pages_Client_Portal_Settings$JobFieldCheckbox, {
                                                  field: field,
                                                  checked: true,
                                                  disabled: true,
                                                  setPortal: setPortal,
                                                  ctx: ctx,
                                                  key: Pages_Client_Portal_Types.State.Portal.Settings.Columns.JobField.toString(field)
                                                });
                                    }), Pages_Client_Portal_Types.State.Portal.Settings.Columns.JobField.restFields.map(function (field) {
                                      return React.createElement(Pages_Client_Portal_Settings$JobFieldCheckbox, {
                                                  field: field,
                                                  checked: selectedJobFields.some(function (s) {
                                                        return s === field;
                                                      }),
                                                  disabled: false,
                                                  setPortal: setPortal,
                                                  ctx: ctx,
                                                  key: Pages_Client_Portal_Types.State.Portal.Settings.Columns.JobField.toString(field)
                                                });
                                    })), React.createElement("div", {
                                  className: styles.column
                                }, customFields.map(function (field) {
                                      return React.createElement(Pages_Client_Portal_Settings$CustomReportFieldCheckbox, {
                                                  uuid: field.uuid,
                                                  name: field.name,
                                                  checked: selectedCustomFields.some(function (s) {
                                                        return Uuid.equal(s.uuid, field.uuid);
                                                      }),
                                                  disabled: disabled,
                                                  onSelect: (function (uuid) {
                                                      setPortal(function (current) {
                                                            var init = current.settings;
                                                            var init$1 = current.settings.columns;
                                                            return {
                                                                    uuid: current.uuid,
                                                                    logo: current.logo,
                                                                    users: current.users,
                                                                    settings: {
                                                                      templateUuids: init.templateUuids,
                                                                      columns: {
                                                                        jobFields: init$1.jobFields,
                                                                        customFields: Belt_Array.concatMany([
                                                                              current.settings.columns.customFields,
                                                                              [uuid]
                                                                            ]),
                                                                        reportFields: init$1.reportFields
                                                                      }
                                                                    }
                                                                  };
                                                          });
                                                    }),
                                                  onUnselect: (function (uuid) {
                                                      setPortal(function (current) {
                                                            var init = current.settings;
                                                            var init$1 = current.settings.columns;
                                                            return {
                                                                    uuid: current.uuid,
                                                                    logo: current.logo,
                                                                    users: current.users,
                                                                    settings: {
                                                                      templateUuids: init.templateUuids,
                                                                      columns: {
                                                                        jobFields: init$1.jobFields,
                                                                        customFields: RCore.$$Array.keep(current.settings.columns.customFields, (function (u) {
                                                                                return !Uuid.equal(u, uuid);
                                                                              })),
                                                                        reportFields: init$1.reportFields
                                                                      }
                                                                    }
                                                                  };
                                                          });
                                                    }),
                                                  key: Uuid.toString(field.uuid)
                                                });
                                    })), React.createElement("div", {
                                  className: styles.column
                                }, reportFields.map(function (field) {
                                      return React.createElement(Pages_Client_Portal_Settings$CustomReportFieldCheckbox, {
                                                  uuid: field.uuid,
                                                  name: field.name,
                                                  checked: selectedReportFields.some(function (s) {
                                                        return Uuid.equal(s.uuid, field.uuid);
                                                      }),
                                                  disabled: disabled,
                                                  onSelect: (function (uuid) {
                                                      setPortal(function (current) {
                                                            var init = current.settings;
                                                            var init$1 = current.settings.columns;
                                                            return {
                                                                    uuid: current.uuid,
                                                                    logo: current.logo,
                                                                    users: current.users,
                                                                    settings: {
                                                                      templateUuids: init.templateUuids,
                                                                      columns: {
                                                                        jobFields: init$1.jobFields,
                                                                        customFields: init$1.customFields,
                                                                        reportFields: Belt_Array.concatMany([
                                                                              current.settings.columns.reportFields,
                                                                              [uuid]
                                                                            ])
                                                                      }
                                                                    }
                                                                  };
                                                          });
                                                    }),
                                                  onUnselect: (function (uuid) {
                                                      setPortal(function (current) {
                                                            var init = current.settings;
                                                            var init$1 = current.settings.columns;
                                                            return {
                                                                    uuid: current.uuid,
                                                                    logo: current.logo,
                                                                    users: current.users,
                                                                    settings: {
                                                                      templateUuids: init.templateUuids,
                                                                      columns: {
                                                                        jobFields: init$1.jobFields,
                                                                        customFields: init$1.customFields,
                                                                        reportFields: RCore.$$Array.keep(current.settings.columns.reportFields, (function (u) {
                                                                                return !Uuid.equal(u, uuid);
                                                                              }))
                                                                      }
                                                                    }
                                                                  };
                                                          });
                                                    }),
                                                  key: Uuid.toString(field.uuid)
                                                });
                                    })))))), React.createElement(Optional.make, {
                  show: Caml_obj.notequal(portal, initialPortal),
                  children: React.createElement("div", {
                        className: styles.footer
                      }, React.createElement(UI_Button.make, {
                            purpose: "primary",
                            className: styles.saveButton,
                            disabled: disabled,
                            onClick: onSubmit,
                            children: save$p(ctx)
                          }))
                }), match$1.carousel);
}

var make = Pages_Client_Portal_Settings;

export {
  make ,
}
/* logo' Not a pure module */
