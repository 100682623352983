import React, { PureComponent } from 'react'
import styleNames from 'classnames'
import { DayPickerSingleDateController } from 'react-dates'
import { capitalize } from 'planado/utils/index.js'
import {
  now,
  getMoment,
  showTime,
  isValidTime,
  isBeforeDay,
  isAfterDay,
} from 'planado/utils/time/index.js'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import { v6 as uuidv6 } from 'uuid-with-v6'
import { make as Panel } from 'rscrpt/components/common/Panel/Panel.mjs'
import { footer } from 'rscrpt/types/Wire.mjs'
import * as styles from './styles.module.css'

const Calendar = React.forwardRef(
  (
    {
      date,
      ctx,
      focused,
      inputId,
      renderMonthInTitle,
      onDateChange,
      limitDateRange,
      placement,
      firstDayOfWeek,
    },
    ref
  ) => {
    const [rendered, setRendered] = React.useState(false)

    React.useEffect(() => setRendered(true))

    const momentDate = getMoment({
      dateTime: date,
      format: 'date',
      ctx,
    })

    let calendar
    if (rendered) {
      calendar = (
        <DayPickerSingleDateController
          hideKeyboardShortcutsPanel
          initialVisibleMonth={() =>
            momentDate || getMoment({ dateTime: now(ctx), ctx })
          }
          renderMonth={renderMonthInTitle}
          onDateChange={onDateChange}
          date={momentDate}
          numberOfMonths={1}
          navPrev={<span />}
          navNext={<span />}
          daySize={31}
          isOutsideRange={limitDateRange}
          focused={focused}
          firstDayOfWeek={firstDayOfWeek}
        />
      )
    } else {
      calendar = null
    }

    return (
      <Panel footer={footer(ctx.wire)} position={placement} domId={inputId}>
        <div className={styles.popup} ref={ref}>
          {calendar}
        </div>
      </Panel>
    )
  }
)

class DatePicker extends PureComponent {
  popupRef = React.createRef()
  inputId = uuidv6()

  static defaultProps = {
    id: '',
    date: null,
    calendarPlacement: 'bottom',
    disabled: false,
    errors: [],
    limits: null,
    dateToDisplay: null,
    focused: true,
    disableTypingOnFocus: false,
    showClearButton: true,
    outlineIcons: false,
    clickHandlerInstalled: false,
    scrollHandlerInstalled: false,
    firstDayOfWeek: 1,
    wrapperClass: '',
  }

  state = {
    focused: false,
    date: this.props.date,
    prevPropsDate: this.props.date,
  }

  static getDerivedStateFromProps(props, state) {
    if (props.date !== state.prevPropsDate) {
      return {
        ...state,
        prevPropsDate: props.date,
        date: props.date,
      }
    } else {
      return null
    }
  }

  onClick = (_event) => {
    if (this.props.disabled) {
      return null
    }

    const clickHandlerInstalled = this.state.clickHandlerInstalled
    const scrollHandlerInstalled = this.state.scrollHandlerInstalled

    this.setState(
      () => ({
        focused: true,
        clickHandlerInstalled: true,
        scrollHandlerInstalled: true,
      }),
      () => {
        const {
          ctx: { dom },
        } = this.props

        if (!scrollHandlerInstalled) {
          dom.onScroll(this.scrollHandler)
        }

        if (!clickHandlerInstalled) {
          dom.addEventListener('click', this.clickHandler, false)
        }
      }
    )
  }

  onBlur = (_event) => {
    const { onChange, date: oldDate, ctx } = this.props
    const { date: newDate } = this.state

    if (oldDate !== newDate && typeof newDate === 'string') {
      const validDate = isValidTime({ date: newDate, ctx, format: 'date' })

      const date = validDate ? newDate : null

      this.setState(
        () => ({ date, focused: false }),
        () => onChange(date)
      )
    }
  }

  scrollHandler = (_event) => {
    const clickHandlerInstalled = this.state.clickHandlerInstalled
    const scrollHandlerInstalled = this.state.scrollHandlerInstalled

    this.setState(
      () => ({
        focused: false,
        clickHandlerInstalled: false,
        scrollHandlerInstalled: false,
      }),
      () => {
        const {
          ctx: { dom },
        } = this.props

        if (clickHandlerInstalled) {
          dom.removeEventListener('click', this.clickHandler, false)
        }

        if (scrollHandlerInstalled) {
          dom.removeScrollHandler(this.scrollHandler)
        }
      }
    )
  }

  clickHandler = (event) => {
    const target = event.target

    if (
      target instanceof Node &&
      this.picker &&
      !this.picker.contains(target) &&
      this.popupRef &&
      this.popupRef.current &&
      !this.popupRef.current.contains(target)
    ) {
      this.setState(
        () => ({ focused: false, clickHandlerInstalled: false }),
        () => {
          const {
            ctx: { dom },
          } = this.props
          dom.removeEventListener('click', this.clickHandler, false)
        }
      )
    }
  }

  onInputChange = (event) => {
    if (this.props.disableTypingOnFocus) {
      return
    }

    const target = event.target
    if (target instanceof HTMLInputElement) {
      this.setState(() => ({ date: target.value }))
    }
  }

  onDateChange = (date) => {
    const { onChange, ctx } = this.props

    date = showTime(date, 'date', ctx)

    const cont = onChange(date)

    if (cont !== false) {
      this.setState(
        () => ({ date, focused: false })
      )
    }
  }

  renderMonthInTitle = (date) => {
    const { ctx } = this.props
    return `${capitalize(ctx.t('js.month')[date.get('month')])} ${date.get(
      'year'
    )}`
  }

  limitDateRange = (day) => {
    const { ctx, limits } = this.props

    if (limits === null) {
      return false
    }

    const { pastDaysCount, futureDaysCount } = limits

    const beginDate = getMoment({ dateTime: now(ctx), ctx }).subtract(
      pastDaysCount,
      'days'
    )

    const endDate = getMoment({ dateTime: now(ctx), ctx }).add(
      futureDaysCount,
      'days'
    )

    return isBeforeDay(day, beginDate) || isAfterDay(day, endDate)
  }

  onClearDate = (evt) => {
    evt.preventDefault()
    this.setState(
      () => ({ date: null }),
      () => this.props.onChange(null)
    )
  }

  get popup() {
    if (this.state.focused) {
      const { date, ctx, focused, calendarPlacement, firstDayOfWeek } =
        this.props

      return (
        <Calendar
          ref={this.popupRef}
          date={date}
          ctx={ctx}
          focused={focused}
          inputId={this.inputId}
          renderMonthInTitle={this.renderMonthInTitle}
          onDateChange={this.onDateChange}
          limitDateRange={this.limitDateRange}
          placement={calendarPlacement}
          firstDayOfWeek={firstDayOfWeek}
        />
      )
    } else {
      return null
    }
  }

  get calendarIcon() {
    const { disabled, dateToDisplay } = this.props

    if (this.props.outlineIcons && (dateToDisplay || this.state.date)) {
      return null
    } else {
      const style = styleNames({
        [styles.icon]: true,
        [styles.iconDate]: true,
        [styles.iconDateDisabled]: disabled,
      })

      return <div className={style} onClick={this.onClick} />
    }
  }

  get clearButton() {
    const { disabled, showClearButton } = this.props

    if (!disabled && showClearButton) {
      const { date } = this.state

      if (date === null || date === '') {
        return null
      } else {
        return (
          <div
            className={`${styles.iconClear} fa fa-times`}
            onClick={this.onClearDate}
          />
        )
      }
    } else {
      return null
    }
  }

  render() {
    const {
      id,
      errors,
      disabled,
      disableTypingOnFocus,
      dateToDisplay,
      wrapperClass,
    } = this.props
    const { date } = this.state

    const inputStyle = styleNames({
      [styles.input]: true,
      [styles.inputDisabled]: disabled,
      [styles.inputHiddenCaret]: disableTypingOnFocus,
    })

    const wrapperClasses = styleNames({
      [styles.picker]: true,
      [styles.pickerDate]: true,
      [styles.outlineIcons]: this.props.outlineIcons,
      [wrapperClass]: true,
      'test-picker-date': true,
    })

    return (
      <>
        <div
          id={this.inputId}
          className={wrapperClasses}
          ref={(el) => (this.picker = el)}
        >
          <input
            id={id}
            type="text"
            className={inputStyle}
            value={dateToDisplay || date || ''}
            onChange={this.onInputChange}
            onClick={this.onClick}
            onBlur={this.onBlur}
            readOnly={disabled}
            autoComplete="off"
          />

          {this.clearButton}
          {this.calendarIcon}

          {errors.length === 0 ? null : (
            <div className={styles.error}>
              {errors.map((x, idx) => (
                <div key={idx}>{x}</div>
              ))}
            </div>
          )}
        </div>
        {this.popup}
      </>
    )
  }
}

export default withContext(DatePicker)
