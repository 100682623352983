

import * as El from "../../../libraries/El.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../Optional.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AddJobButton from "../AddJobButton/AddJobButton.mjs";
import * as Context_Types from "../../../context/Context_Types.mjs";
import * as Shared_Lib_IO from "../../../shared/lib/Shared_Lib_IO.mjs";
import * as AddJobButtonTypes from "../AddJobButton/AddJobButtonTypes.mjs";
import * as RelatedEntitiesAPI from "./RelatedEntitiesAPI.mjs";
import * as RelatedEntitiesTable from "./RelatedEntitiesTable.mjs";
import * as RelatedEntitiesTypes from "./RelatedEntitiesTypes.mjs";
import * as RelatedEntitiesModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/RelatedEntities/RelatedEntities.module.css";

var styles = RelatedEntitiesModuleCss;

var jobs$p = Utils.Translations.tr("js.components.job_list.title");

var showMore$p = Utils.Translations.tr("js.components.job_list.show_more");

var tabJobs$p = Utils.Translations.tr("js.clients.show.tabs.jobs");

var tabRecurringJobs$p = Utils.Translations.tr("js.clients.show.tabs.recurring_jobs");

var tabSites$p = Utils.Translations.tr("js.clients.show.tabs.sites");

function incrementActiveTabPage(state) {
  return {
          jobs: state.jobs,
          jobsCount: state.jobsCount,
          sites: state.sites,
          sitesCount: state.sitesCount,
          recurringJobs: state.recurringJobs,
          recurringJobsCount: state.recurringJobsCount,
          templates: state.templates,
          page: state.page + 1 | 0,
          locked: state.locked,
          tab: state.tab
        };
}

function reducer(state, action) {
  if (typeof action !== "object") {
    if (action === "LockButton") {
      return {
              jobs: state.jobs,
              jobsCount: state.jobsCount,
              sites: state.sites,
              sitesCount: state.sitesCount,
              recurringJobs: state.recurringJobs,
              recurringJobsCount: state.recurringJobsCount,
              templates: state.templates,
              page: state.page,
              locked: true,
              tab: state.tab
            };
    } else {
      return {
              jobs: state.jobs,
              jobsCount: state.jobsCount,
              sites: state.sites,
              sitesCount: state.sitesCount,
              recurringJobs: state.recurringJobs,
              recurringJobsCount: state.recurringJobsCount,
              templates: state.templates,
              page: 1,
              locked: state.locked,
              tab: state.tab
            };
    }
  }
  switch (action.TAG) {
    case "SetInitialData" :
        var data = action._0;
        return {
                jobs: data.jobs,
                jobsCount: data.jobsCount,
                sites: data.sites,
                sitesCount: data.sitesCount,
                recurringJobs: data.recurringJobs,
                recurringJobsCount: data.recurringJobsCount,
                templates: data.templates,
                page: 1,
                locked: false,
                tab: state.tab
              };
    case "SetLoadedData" :
        var data$1 = action._0;
        var init = incrementActiveTabPage(state);
        return {
                jobs: Belt_Array.concatMany([
                      state.jobs,
                      data$1.jobs
                    ]),
                jobsCount: init.jobsCount,
                sites: Belt_Array.concatMany([
                      state.sites,
                      data$1.sites
                    ]),
                sitesCount: init.sitesCount,
                recurringJobs: Belt_Array.concatMany([
                      state.recurringJobs,
                      data$1.recurringJobs
                    ]),
                recurringJobsCount: init.recurringJobsCount,
                templates: init.templates,
                page: init.page,
                locked: false,
                tab: init.tab
              };
    case "SwitchTab" :
        var tab = action._0;
        var roundPage = function (entries) {
          return Js_math.ceil(entries / 10);
        };
        var page;
        if (tab.NAME === "ClientSite") {
          page = tab.VAL === "Jobs" ? roundPage(state.jobs.length) : roundPage(state.recurringJobs.length);
        } else {
          var match = tab.VAL;
          page = match === "Sites" ? roundPage(state.sites.length) : (
              match === "Jobs" ? roundPage(state.jobs.length) : roundPage(state.recurringJobs.length)
            );
        }
        return {
                jobs: state.jobs,
                jobsCount: state.jobsCount,
                sites: state.sites,
                sitesCount: state.sitesCount,
                recurringJobs: state.recurringJobs,
                recurringJobsCount: state.recurringJobsCount,
                templates: state.templates,
                page: page,
                locked: state.locked,
                tab: tab
              };
    
  }
}

function tabText(ctx, tab) {
  if (tab.NAME === "ClientSite") {
    if (tab.VAL === "Jobs") {
      return tabJobs$p(ctx);
    } else {
      return tabRecurringJobs$p(ctx);
    }
  }
  var match = tab.VAL;
  if (match === "Sites") {
    return tabSites$p(ctx);
  } else if (match === "Jobs") {
    return tabJobs$p(ctx);
  } else {
    return tabRecurringJobs$p(ctx);
  }
}

function item(ctx, state, tab, onClick, countValue) {
  return React.createElement("li", {
              className: Caml_obj.equal(state.tab, tab) ? "active" : ""
            }, React.createElement("button", {
                  onClick: (function (_evt) {
                      _evt.preventDefault();
                      onClick();
                    })
                }, tabText(ctx, tab), React.createElement("span", {
                      className: styles.tabEntityCount
                    }, countValue.toString())));
}

function RelatedEntities$Tabs(Props) {
  var ctx = Props.ctx;
  var state = Props.state;
  var pageType = Props.pageType;
  var listType = Props.listType;
  var jobsCount = Props.jobsCount;
  var sitesCount = Props.sitesCount;
  var recurringJobsCount = Props.recurringJobsCount;
  var compact = Props.compact;
  var send = Props.send;
  var recurringJobsTab = function (tab) {
    return React.createElement(Optional.make, {
                show: Context_Types.Features.hasFlag("recurringJobs", ctx.features),
                children: item(ctx, state, tab, (function (_event) {
                        return send({
                                    TAG: "SwitchTab",
                                    _0: tab
                                  });
                      }), recurringJobsCount)
              });
  };
  if (listType === "JobsSitesAndRec") {
    var fstTab = {
      NAME: "Organization",
      VAL: "Jobs"
    };
    var sndTab = {
      NAME: "Organization",
      VAL: "Sites"
    };
    return React.createElement("ul", {
                className: El.Cn.concat("nav nav-tabs", compact ? styles.compactNav : "")
              }, item(ctx, state, fstTab, (function (_event) {
                      return send({
                                  TAG: "SwitchTab",
                                  _0: fstTab
                                });
                    }), jobsCount), item(ctx, state, sndTab, (function (_event) {
                      return send({
                                  TAG: "SwitchTab",
                                  _0: sndTab
                                });
                    }), sitesCount), recurringJobsTab({
                    NAME: "Organization",
                    VAL: "RecurringJobs"
                  }));
  }
  if (pageType === "RecurringJob") {
    return null;
  }
  var fstTab$1 = {
    NAME: "ClientSite",
    VAL: "Jobs"
  };
  return React.createElement("ul", {
              className: El.Cn.concat("nav nav-tabs", compact ? styles.compactNav : "")
            }, item(ctx, state, fstTab$1, (function (_event) {
                    return send({
                                TAG: "SwitchTab",
                                _0: fstTab$1
                              });
                  }), jobsCount), recurringJobsTab({
                  NAME: "ClientSite",
                  VAL: "RecurringJobs"
                }));
}

function jobButtonClassName(compact) {
  if (compact) {
    return "";
  } else {
    return styles.jobButton;
  }
}

function jobType(state) {
  var match = state.tab;
  if (match.VAL === "RecurringJobs") {
    return "RecurringJob";
  } else {
    return "Job";
  }
}

function RelatedEntities$NewJobButton(Props) {
  var wire = Props.wire;
  var state = Props.state;
  var options = Props.options;
  var pageType = Props.pageType;
  var compact = Props.compact;
  var match = state.tab;
  if (match.NAME === "ClientSite") {
    if (match.VAL === "Jobs" && pageType === "RecurringJob") {
      return null;
    }
    
  } else if (match.VAL === "Sites") {
    return null;
  }
  return React.createElement("div", {
              className: jobButtonClassName(compact)
            }, React.createElement(AddJobButton.make, {
                  wire: wire,
                  templates: state.templates,
                  compact: compact,
                  jobType: jobType(state),
                  options: options
                }));
}

function options(uuid, pageType) {
  if (pageType === "Site") {
    return {
            clientUuid: AddJobButtonTypes.emptyButtonOptions.clientUuid,
            siteUuid: uuid,
            templateUuid: AddJobButtonTypes.emptyButtonOptions.templateUuid,
            recurringJobUuid: AddJobButtonTypes.emptyButtonOptions.recurringJobUuid
          };
  } else if (pageType === "Client") {
    return {
            clientUuid: uuid,
            siteUuid: AddJobButtonTypes.emptyButtonOptions.siteUuid,
            templateUuid: AddJobButtonTypes.emptyButtonOptions.templateUuid,
            recurringJobUuid: AddJobButtonTypes.emptyButtonOptions.recurringJobUuid
          };
  } else if (pageType === "RecurringJob") {
    return {
            clientUuid: AddJobButtonTypes.emptyButtonOptions.clientUuid,
            siteUuid: AddJobButtonTypes.emptyButtonOptions.siteUuid,
            templateUuid: AddJobButtonTypes.emptyButtonOptions.templateUuid,
            recurringJobUuid: uuid
          };
  } else {
    return {
            clientUuid: AddJobButtonTypes.emptyButtonOptions.clientUuid,
            siteUuid: AddJobButtonTypes.emptyButtonOptions.siteUuid,
            templateUuid: uuid,
            recurringJobUuid: AddJobButtonTypes.emptyButtonOptions.recurringJobUuid
          };
  }
}

function RelatedEntities$HeaderAndAddButton(Props) {
  var wire = Props.wire;
  var state = Props.state;
  var uuid = Props.uuid;
  var jobsCount = Props.jobsCount;
  var pageType = Props.pageType;
  var compact = Props.compact;
  var options$1 = options(uuid, pageType);
  return React.createElement("header", {
              className: styles.header
            }, pageType === "RecurringJob" ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: styles.headline
                      }, jobs$p(wire.ctx), React.createElement(Optional.make, {
                            show: jobsCount > 0,
                            children: React.createElement("span", {
                                  className: styles.count
                                }, El.nbsp, jobsCount.toString())
                          })), React.createElement(RelatedEntities$NewJobButton, {
                        wire: wire,
                        state: state,
                        options: options$1,
                        pageType: pageType,
                        compact: compact
                      })) : React.createElement(RelatedEntities$NewJobButton, {
                    wire: wire,
                    state: state,
                    options: options$1,
                    pageType: pageType,
                    compact: compact
                  }));
}

function button(ctx, state, onClick) {
  return React.createElement("button", {
              className: El.Cn.concat(styles.button, "tests-client-jobs-btn"),
              disabled: state.locked,
              type: "button",
              onClick: onClick
            }, React.createElement(Optional.make, {
                  show: !state.locked,
                  children: showMore$p(ctx)
                }));
}

function RelatedEntities$LoadNextPageButton(Props) {
  var ctx = Props.ctx;
  var state = Props.state;
  var onClick = Props.onClick;
  var jobsCount = Props.jobsCount;
  var sitesCount = Props.sitesCount;
  var recurringJobsCount = Props.recurringJobsCount;
  var match = state.tab;
  var exit = 0;
  if (match.NAME === "ClientSite") {
    exit = match.VAL === "Jobs" ? 1 : 2;
  } else {
    var match$1 = match.VAL;
    if (match$1 === "Sites") {
      if (sitesCount > Math.imul(state.page, 10)) {
        return button(ctx, state, onClick);
      } else {
        return null;
      }
    }
    exit = match$1 === "Jobs" ? 1 : 2;
  }
  switch (exit) {
    case 1 :
        if (jobsCount > Math.imul(state.page, 10)) {
          return button(ctx, state, onClick);
        } else {
          return null;
        }
    case 2 :
        if (recurringJobsCount > Math.imul(state.page, 10)) {
          return button(ctx, state, onClick);
        } else {
          return null;
        }
    
  }
}

function RelatedEntities(Props) {
  var uuid = Props.uuid;
  var pageType = Props.pageType;
  var listType = Props.listType;
  var compactOpt = Props.compact;
  var wire = Props.wire;
  var compact = compactOpt !== undefined ? compactOpt : false;
  var match = React.useReducer(reducer, RelatedEntitiesTypes.emptyState(listType));
  var send = match[1];
  var state = match[0];
  var request = function (page, tab, withDelay) {
    send("LockButton");
    var pagesToLoad = tab !== undefined ? ({
          NAME: "SingleTab",
          VAL: tab
        }) : ({
          NAME: "InitialFetch",
          VAL: listType
        });
    var makeRequest = function () {
      return RelatedEntitiesAPI.Show.request(wire, RelatedEntitiesTypes.Options.make(uuid, page, pageType, pagesToLoad));
    };
    if (withDelay) {
      return Shared_Lib_IO.withDelayBefore(makeRequest(), 1000);
    } else {
      return makeRequest();
    }
  };
  var initialFetch = function () {
    Shared_Lib_IO.unsafeRunAsync(Shared_Lib_IO.tap(request(1, undefined, true), (function (result) {
                if (result.TAG === "Ok") {
                  return send({
                              TAG: "SetInitialData",
                              _0: result._0
                            });
                }
                
              })), (function (prim) {
            
          }));
  };
  var trackerEvent = function ($$event) {
    if (typeof $$event !== "object" || $$event.TAG !== "JobSubmitted") {
      return ;
    } else {
      return Shared_Lib_IO.unsafeRunAsync(Shared_Lib_IO.tap(request(1, undefined, true), (function (result) {
                        if (result.TAG !== "Ok") {
                          return ;
                        }
                        send("SetDefaultPage");
                        initialFetch();
                      })), (function (prim) {
                    
                  }));
    }
  };
  var loadNextPage = function () {
    var page = incrementActiveTabPage(state).page;
    Shared_Lib_IO.unsafeRunAsync(Shared_Lib_IO.tap(request(page, state.tab, false), (function (result) {
                if (result.TAG !== "Ok") {
                  return ;
                }
                var match = result._0;
                send({
                      TAG: "SetLoadedData",
                      _0: RelatedEntitiesTypes.Entries.make(match.jobs, match.sites, match.recurringJobs)
                    });
              })), (function (prim) {
            
          }));
  };
  var jobsCount = RCore.$$Option.getOr(state.jobsCount, 0);
  var sitesCount = RCore.$$Option.getOr(state.sitesCount, 0);
  var recurringJobsCount = RCore.$$Option.getOr(state.recurringJobsCount, 0);
  var subscriptions = wire.subscriptions;
  var ctx = wire.ctx;
  React.useEffect((function () {
          initialFetch();
          return subscriptions.jobs.subscribe(trackerEvent);
        }), []);
  return React.createElement("div", {
              className: El.Cn.concat(styles.wrapper, "tests-client-jobs")
            }, React.createElement(RelatedEntities$Tabs, {
                  ctx: ctx,
                  state: state,
                  pageType: pageType,
                  listType: listType,
                  jobsCount: jobsCount,
                  sitesCount: sitesCount,
                  recurringJobsCount: recurringJobsCount,
                  compact: compact,
                  send: send
                }), React.createElement("div", {
                  className: El.Cn.concat(styles.content, compact ? styles.contentCompact : "")
                }, React.createElement(RelatedEntities$HeaderAndAddButton, {
                      wire: wire,
                      state: state,
                      uuid: uuid,
                      jobsCount: jobsCount,
                      pageType: pageType,
                      compact: compact
                    }), React.createElement(RelatedEntitiesTable.make, {
                      wire: wire,
                      state: state,
                      pageType: pageType,
                      listType: listType,
                      jobsCount: jobsCount,
                      sitesCount: sitesCount,
                      recurringJobsCount: recurringJobsCount
                    }), React.createElement(RelatedEntities$LoadNextPageButton, {
                      ctx: ctx,
                      state: state,
                      onClick: (function (param) {
                          loadNextPage();
                        }),
                      jobsCount: jobsCount,
                      sitesCount: sitesCount,
                      recurringJobsCount: recurringJobsCount
                    })));
}

var make = RelatedEntities;

var $$default = RelatedEntities;

export {
  make ,
  $$default as default,
}
/* styles Not a pure module */
