

import * as Types from "../../../../../../../../../../types/Types.mjs";
import * as React from "react";
import * as AppContext from "../../../../../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_DND from "../../../../../../../../lib/Schedule_DND.mjs";
import * as Schedule_Types_Job from "../../../../../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as WeekTimeline_Constants from "../../../../../../lib/WeekTimeline_Constants.mjs";

function use(job, draggableForAssignee) {
  var wire = AppContext.useWire();
  var canDrag = draggableForAssignee && Schedule_Types_Job.AssignedJob.hasRightsToEdit(job, wire) && Schedule_Types_Job.AssignedJob.duration(job) <= WeekTimeline_Constants.minutesInWeek;
  var draggableJob_uuid = Schedule_Types_Job.AssignedJob.uuid(job);
  var draggableJob_template = Schedule_Types_Job.AssignedJob.template(job);
  var draggableJob_assignees = Schedule_Types_Job.AssignedJob.assignees(job);
  var draggableJob_address = Schedule_Types_Job.AssignedJob.address(job);
  var draggableJob_scheduledAt = Schedule_Types_Job.AssignedJob.scheduledAt(job);
  var draggableJob_duration = Schedule_Types_Job.AssignedJob.duration(job);
  var draggableJob = {
    uuid: draggableJob_uuid,
    template: draggableJob_template,
    bar: undefined,
    assignees: draggableJob_assignees,
    address: draggableJob_address,
    scheduledAt: draggableJob_scheduledAt,
    duration: draggableJob_duration
  };
  var match = Schedule_DND.DND.Draggable.use(undefined, canDrag, Schedule_DND.DND.Context.makePayload(draggableJob), WeekTimeline_Constants.dndScheduledJob, undefined);
  var dragRef = match[0];
  React.useEffect((function () {
          var dragElement = Types.ReactRef.toOption(dragRef);
          if (dragElement !== undefined) {
            Caml_option.valFromOption(dragElement).setAttribute("data-prevent-scroll", "true");
          }
          
        }), []);
  return [
          dragRef,
          match[1]
        ];
}

export {
  use ,
}
/* Types Not a pure module */
