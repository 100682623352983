const DEFAULT_FILTER = {
  filter: [],
  savedAt: null
}

export const filterReducer = (
  state = DEFAULT_FILTER,
  { type, filter, savedAt }
) => {
  switch (type) {
    case 'SET_FILTER':
      return { filter, savedAt }
    default:
      return state
  }
}

export const scaleReducer = (scale = 'day', action) => {
  switch (action.type) {
    case 'SET_SCALE':
      return action.scale
    default:
      return scale
  }
}

export const backboneReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_BACKBONE':
      return action.backbone
    default:
      return state
  }
}

export const handlersReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_HANDLERS':
      return action.handlers
    default:
      return state
  }
}
