

import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../types/Types.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as Schedule_Scroll from "../../../../components/scroll/Schedule_Scroll.mjs";
import * as Shared_Lib_Reducer from "../../../../../../shared/lib/Shared_Lib_Reducer.mjs";
import * as WeekTimeline_Types from "../types/WeekTimeline_Types.mjs";
import * as Shared_Lib_Debounce from "../../../../../../shared/lib/Shared_Lib_Debounce.mjs";
import * as Schedule_TimelineHooks from "../../../../lib/hooks/Schedule_TimelineHooks.mjs";
import * as Schedule_UtilsTimeline from "../../../../lib/utils/Schedule_UtilsTimeline.mjs";
import * as WeekTimeline_StateTypes from "./WeekTimeline_StateTypes.mjs";
import * as WeekTimeline_UtilsSizes from "../utils/WeekTimeline_UtilsSizes.mjs";
import * as WeekTimeline_StateContext from "./WeekTimeline_StateContext.mjs";
import * as WeekTimeline_StateReducer from "./WeekTimeline_StateReducer.mjs";
import * as Schedule_Scroll_InitialPosition from "../../../../components/scroll/Schedule_Scroll_InitialPosition.mjs";

function useScroll(timelineSubscriptions) {
  var elements = WeekTimeline_Types.DomElements.use();
  var initialPosition = Schedule_Scroll_InitialPosition.useInitialPosition();
  React.useEffect((function () {
          var elements$1 = RCore.$$Array.filterMap([
                elements.scrollAreaEl,
                elements.viewportEl,
                elements.sidebarEl
              ], (function (el) {
                  return Types.ReactRef.toOption(el);
                }));
          if (elements$1.length !== 3) {
            return ;
          }
          var scrollArea = elements$1[0];
          var viewport = elements$1[1];
          var sidebar = elements$1[2];
          var scrollElements = [{
              element: sidebar,
              direction: "Vertical"
            }];
          var onScrollStart = function (param) {
            timelineSubscriptions.onScrollStart.trigger([
                  param.x,
                  param.y
                ]);
          };
          var onScrollEnd = function (param) {
            timelineSubscriptions.onScrollEnd.trigger([
                  param.x,
                  param.y
                ]);
          };
          var scroll = Schedule_Scroll.init(initialPosition, onScrollStart, onScrollEnd, scrollElements, viewport, Caml_option.some(scrollArea), undefined, undefined);
          if (scroll !== undefined) {
            return scroll.onDestroy;
          }
          
        }), []);
  return elements;
}

function useSizes(state, dispatch) {
  var wire = AppContext.useWire();
  var showUnscheduledJobs = Schedule_State.useShowUnscheduledJobs();
  var match = Shared_Lib_Debounce.debounce(300, undefined, (function () {
          var sizes = WeekTimeline_UtilsSizes.get(wire, showUnscheduledJobs);
          if (Caml_obj.notequal(sizes, state.sizes)) {
            return dispatch({
                        TAG: "SizesChanged",
                        _0: sizes
                      });
          }
          
        }));
  var onResize = match.f;
  React.useEffect((function () {
          var sizes = WeekTimeline_UtilsSizes.get(wire, showUnscheduledJobs);
          if (Caml_obj.notequal(sizes, state.sizes)) {
            dispatch({
                  TAG: "SizesChanged",
                  _0: sizes
                });
          }
          return wire.subscriptions.resize.subscribe(function (param) {
                      onResize();
                    });
        }), [
        showUnscheduledJobs,
        state.sizes
      ]);
}

function useContext() {
  var wire = AppContext.useWire();
  var showUnscheduledJobs = Schedule_State.useShowUnscheduledJobs();
  var initialSizes = React.useMemo((function () {
          return WeekTimeline_UtilsSizes.get(wire, showUnscheduledJobs);
        }), []);
  var context = Shared_Lib_Reducer.use(WeekTimeline_StateReducer.reducer, WeekTimeline_StateTypes.State.initial(initialSizes));
  var state = context[0];
  useSizes(state, context[1]);
  var elements = useScroll(WeekTimeline_StateTypes.StateSelector.timelineSubscriptions(state));
  Schedule_TimelineHooks.TimelineSubscriptions.use(Schedule_UtilsTimeline.contentEl(elements.viewportEl), state.timelineSubscriptions);
  var setInitialPosition = Schedule_Scroll_InitialPosition.useSetInitialPosition();
  React.useEffect((function () {
          return WeekTimeline_StateTypes.StateSelector.timelineSubscriptions(state).onScrollEnd.subscribe(function (param) {
                      setInitialPosition({
                            x: Units.Px.zero,
                            y: param[1]
                          });
                    });
        }), []);
  return [
          elements,
          context
        ];
}

function useState() {
  return WeekTimeline_StateContext.use()[0];
}

function useDispatch() {
  return WeekTimeline_StateContext.use()[1];
}

function useSizes$1() {
  var state = useState();
  return WeekTimeline_StateTypes.StateSelector.sizes(state);
}

function useTimelineSubscriptions() {
  var state = useState();
  return WeekTimeline_StateTypes.StateSelector.timelineSubscriptions(state);
}

function useAssignJobBlocks() {
  var state = useState();
  return WeekTimeline_StateTypes.StateSelector.assignJobBlocks(state);
}

export {
  useContext ,
  useDispatch ,
  useSizes$1 as useSizes,
  useTimelineSubscriptions ,
  useAssignJobBlocks ,
}
/* Types Not a pure module */
