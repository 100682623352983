import classNames from 'classnames'
import React from 'react'
import FormInput from 'planado/components/input/form_input'
import Hint from 'rscrpt/components/common/Hint/Hint.mjs'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import * as styles from './styles.module.css'

const AddButton = withContext(({ readOnly, ctx, addContact }) => {
  if (readOnly) {
    return null
  } else {
    return (
      <a href="#" onClick={addContact} className={styles.addButton}>
        <span className="fa fa-plus icon-add-model" />
        &nbsp;
        {ctx.t('js.contacts.list.add_contact')}
      </a>
    )
  }
})

const ContactInput = withContext(
  ({
    ctx,
    value,
    onDeletePress,
    editContact,
    index,
    inputId,
    inputName,
    readOnly,
    isPrimary,
    isDeletable,
    isNameReadOnly = false,
    highlightPrimary = true,
  }) => {
    let deleteButton
    if (isPrimary) {
      deleteButton = null
    } else {
      const buttonClass = classNames({
        [styles.button]: true,
        [styles.buttonInvisible]: !isDeletable || readOnly,
      })

      deleteButton = (
        <button className={`${buttonClass} btn`} onClick={onDeletePress}>
          <span className={`${styles.trashIcon} fa fa-trash-alt`} />
        </button>
      )
    }

    let helpPopover
    if (isPrimary) {
      helpPopover = (
        <div className={styles.hint}>
          <Hint content={ctx.t('js.contacts.list.hint_text')} thin={true} />
        </div>
      )
    } else {
      helpPopover = null
    }

    let primaryLabel
    if (isPrimary) {
      primaryLabel = (
        <span className={styles.primaryLabel}>
          {ctx.t('js.contacts.list.labels.primary')}
        </span>
      )
    } else {
      primaryLabel = null
    }

    const wrapperClassNames = classNames({
      [styles.wrapper]: true,
      [styles.wrapperPrimary]: isPrimary && highlightPrimary,
    })

    return (
      <div className={wrapperClassNames}>
        {primaryLabel}

        <FormInput
          id={inputId(`contact-name-${index}`)}
          name={inputName('name')}
          type="text"
          value={value.name || ''}
          readOnly={readOnly || isNameReadOnly}
          onValueChange={(name) => editContact({ ...value, name })}
          maxLength={50}
          className="pd-form-control"
          labelText={ctx.t('js.contacts.list.labels.name')}
          wrapperClass={{ [styles.contactName]: true }}
        />

        <FormInput
          id={inputId(`contact-value-${index}`)}
          name={inputName('value')}
          type="text"
          readOnly={readOnly}
          value={value.value || ''}
          onValueChange={(newValue) =>
            editContact({ ...value, value: newValue })
          }
          maxLength={50}
          className="pd-form-control"
          labelText={ctx.t('js.contacts.list.labels.value')}
          wrapperClass={{ 'pd-form-group-equal-size': true }}
        />

        {deleteButton}
        {helpPopover}
      </div>
    )
  }
)

const ContactList = withContext(
  ({
    contacts,
    addContact,
    removeContact,
    editContact,
    inputId,
    inputName,
    errors = [],
    readOnly = false,
    isFirstContactHidden = false,
    isFirstContactNameReadOnly = false,
    highlightPrimary = true,
    ctx,
  }) => {
    const idxOffset = isFirstContactHidden ? 1 : 0

    const contactList = contacts.slice(idxOffset).map((contact, idx) => {
      const onDeletePress = (evt) => {
        evt.preventDefault()

        removeContact(idx + idxOffset)
      }

      const contactErrors = errors[idx + idxOffset] || null

      return (
        <ContactInput
          index={idx + idxOffset}
          inputId={inputId}
          inputName={inputName}
          key={idx + idxOffset}
          readOnly={readOnly}
          value={contact}
          errors={contactErrors}
          editContact={(value) => editContact(idx + idxOffset, value)}
          highlightPrimary={highlightPrimary}
          onDeletePress={onDeletePress}
          isDeletable={idx > 0}
          isPrimary={idx === 0 && !isFirstContactHidden}
          isNameReadOnly={idx === 0 && isFirstContactNameReadOnly}
        />
      )
    })

    return (
      <>
        <h2 className={styles.title}>{ctx.t('js.contacts.list.title')}</h2>

        {contactList}

        <AddButton addContact={addContact} readOnly={readOnly} />
      </>
    )
  }
)

export default ContactList
