

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Types from "../../types/Types.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as EncodeJson from "../../libraries/EncodeJson.mjs";
import * as Context_Lang from "../../context/Context_Lang.mjs";
import * as Context_Types from "../../context/Context_Types.mjs";
import * as Context_Localizator from "../../context/Context_Localizator.mjs";

function index(wire) {
  return Backend.get(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "GeneralSettings",
                VAL: "Index"
              }
            });
}

var Index = {
  index: index
};

function encode(settings) {
  var encodeLocale = function (locale) {
    return EncodeJson.object([
                [
                  "language",
                  EncodeJson.string(Context_Lang.toString(locale.language))
                ],
                [
                  "timezone",
                  EncodeJson.string(locale.timezone)
                ],
                [
                  "country",
                  EncodeJson.string(locale.country)
                ],
                [
                  "dateFormat",
                  EncodeJson.string(locale.dateFormat)
                ],
                [
                  "measurementSystem",
                  EncodeJson.string(Context_Localizator.MeasurementSystem.toString(locale.measurementSystem))
                ],
                [
                  "thousandsSeparator",
                  EncodeJson.string(locale.thousandsSeparator)
                ],
                [
                  "decimalMark",
                  EncodeJson.string(locale.decimalMark)
                ],
                [
                  "currencyFullFormat",
                  EncodeJson.string(locale.currencyFullFormat)
                ]
              ]);
  };
  var encodeLookupRestrictions = function (restrictions) {
    return EncodeJson.object([
                [
                  "country",
                  EncodeJson.optionString(restrictions.country)
                ],
                [
                  "region",
                  EncodeJson.optionString(restrictions.region)
                ],
                [
                  "area",
                  EncodeJson.optionString(restrictions.area)
                ],
                [
                  "city",
                  EncodeJson.optionString(restrictions.city)
                ],
                [
                  "settlement",
                  EncodeJson.optionString(restrictions.settlement)
                ],
                [
                  "formatted",
                  EncodeJson.optionString(restrictions.formatted)
                ]
              ]);
  };
  var encodeSmsSettings = function (sms) {
    return EncodeJson.object([
                [
                  "sendHour",
                  EncodeJson.$$int(sms.sendHour)
                ],
                [
                  "workerEnRouteTemplate",
                  EncodeJson.optionString(sms.workerEnRouteTemplate)
                ],
                [
                  "nearestJobsTemplate",
                  EncodeJson.optionString(sms.nearestJobsTemplate)
                ],
                [
                  "jobFinishedTemplate",
                  EncodeJson.optionString(sms.jobFinishedTemplate)
                ]
              ]);
  };
  var encodeBusinessHours = function (businessHours) {
    var match = RCore.$$Option.getOr(RCore.$$Option.map(businessHours.interval, (function (i) {
                return [
                        Types.Time.HoursMinutes.toString(i[0]),
                        Types.Time.HoursMinutes.toString(i[1])
                      ];
              })), [
          undefined,
          undefined
        ]);
    return EncodeJson.object([
                [
                  "hasBusinessHours",
                  EncodeJson.bool(businessHours.configured)
                ],
                [
                  "businessHoursStart",
                  EncodeJson.optionString(match[0])
                ],
                [
                  "businessHoursFinish",
                  EncodeJson.optionString(match[1])
                ]
              ]);
  };
  return EncodeJson.object([
              [
                "customization",
                EncodeJson.object([
                      [
                        "companyName",
                        EncodeJson.optionString(settings.companyName)
                      ],
                      [
                        "officePhone",
                        EncodeJson.optionString(settings.officePhone)
                      ],
                      [
                        "lookupRestrictions",
                        encodeLookupRestrictions(settings.lookupRestrictions)
                      ]
                    ])
              ],
              [
                "locale",
                encodeLocale(settings.locale)
              ],
              [
                "mapSettings",
                EncodeJson.object([[
                        "mapType",
                        EncodeJson.string(Context_Types.MapType.toString(settings.mapType))
                      ]])
              ],
              [
                "smsSettings",
                encodeSmsSettings(settings.smsSettings)
              ],
              [
                "businessHours",
                encodeBusinessHours(settings.businessHours)
              ],
              [
                "currency",
                EncodeJson.string(settings.locale.currency)
              ]
            ]);
}

function patch(wire, settings) {
  return Backend.patch(undefined, undefined, undefined, true, wire, {
              NAME: "API",
              VAL: {
                NAME: "GeneralSettings",
                VAL: "Index"
              }
            }, {
              NAME: "Json",
              VAL: encode(settings)
            });
}

function patch$1(wire, userUuid, password) {
  return Backend.patch(undefined, undefined, undefined, true, wire, {
              NAME: "API",
              VAL: {
                NAME: "GeneralSettings",
                VAL: "TransferOwner"
              }
            }, {
              NAME: "Obj",
              VAL: {
                newOwnerUuid: Uuid.toString(userUuid),
                password: password
              }
            });
}

var TransferOwner = {
  patch: patch$1
};

var Update = {
  patch: patch
};

export {
  Index ,
  Update ,
  TransferOwner ,
}
/* Uuid Not a pure module */
