


function toPrecision(decimals, num) {
  var pow = Math.pow(10.0, decimals);
  var multiplied = num >= 0.0 ? Math.floor(pow * num) : Math.ceil(pow * num);
  return multiplied / pow;
}

export {
  toPrecision ,
}
/* No side effect */
