

import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Provider from "../../../Provider.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ResolutionAPI from "./ResolutionAPI.mjs";
import * as MessengerTypes from "../../messenger/MessengerTypes.mjs";
import * as JobSettingsTabs from "../JobSettingsTabs.mjs";
import * as JobSettingsHeader from "../JobSettingsHeader.mjs";
import * as ResolutionIndexJS from "./ResolutionIndexJS.mjs";
import * as ResolutionSaveButtonJS from "./ResolutionSaveButtonJS.mjs";
import Resolutions from "planado/stores/admin/resolutions";

var resolutions$p = Utils.Translations.tr("js.resolutions.resolutions");

var failureMsg$p = Utils.Translations.t("js.resolutions.messages.unprocessable_entity");

var successMsg$p = Utils.Translations.t("js.resolutions.messages.updated");

function ResolutionIndex(Props) {
  var wire = Props.wire;
  var messenger = wire.subscriptions.messenger;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        
      });
  var setState = match[1];
  var state = match[0];
  var loaded = function (data) {
    if (data.TAG !== "Ok") {
      return ;
    }
    var data$1 = data._0;
    if (data$1 !== undefined) {
      return setState(function (param) {
                  return Caml_option.some(Resolutions(data$1));
                });
    }
    
  };
  var onFailure = function () {
    messenger.trigger({
          TAG: "Show",
          _0: MessengerTypes.Message.make(undefined, "Danger", {
                TAG: "Text",
                _0: failureMsg$p(ctx)
              })
        });
  };
  var onSuccess = function () {
    messenger.trigger({
          TAG: "Show",
          _0: MessengerTypes.Message.make(undefined, undefined, {
                TAG: "Text",
                _0: successMsg$p(ctx)
              })
        });
  };
  React.useEffect((function () {
          Backend.$$finally(ResolutionAPI.Index.index(wire), loaded);
        }), []);
  if (state !== undefined) {
    return React.createElement(Provider.make, {
                store: Caml_option.valFromOption(state),
                children: React.createElement("div", undefined, React.createElement("div", {
                          className: "page-container narrow"
                        }, React.createElement("form", {
                              className: "form-horizontal"
                            }, React.createElement(JobSettingsHeader.make, {
                                  ctx: ctx
                                }), React.createElement("div", {
                                  className: "settings-page row"
                                }, React.createElement(JobSettingsTabs.make, {
                                      wire: wire,
                                      tab: "Resolutions"
                                    }), React.createElement("div", {
                                      className: "tab-content"
                                    }, React.createElement("div", {
                                          className: "tab-pane active"
                                        }, React.createElement("div", {
                                              className: "panel"
                                            }, React.createElement("div", {
                                                  className: "panel-body panel-body_resolutions"
                                                }, React.createElement("div", {
                                                      className: "panel-body__hl panel-body__hl_resolutions"
                                                    }, resolutions$p(ctx)), React.createElement("div", {
                                                      id: "resolutions"
                                                    }, React.createElement("div", {
                                                          className: "resolution-list-container"
                                                        }, React.createElement(ResolutionIndexJS.make, {})))), React.createElement("div", {
                                                  className: "panel-footer"
                                                }, React.createElement(ResolutionSaveButtonJS.make, {
                                                      onSuccess: onSuccess,
                                                      onFailure: onFailure
                                                    })))))))))
              });
  } else {
    return null;
  }
}

var make = ResolutionIndex;

export {
  make ,
}
/* resolutions' Not a pure module */
