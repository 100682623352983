import React from 'react'
import classNames from 'classnames'
import Title from 'planado/components/title'

const Hint = ({ content, modifiers = {} }) => {
  const classes = classNames({
    hint: true,
    ...modifiers
  })

  return (
    <div className={classes}>
      <Title content={content}>
        <div className="hint__icon" />
      </Title>
    </div>
  )
}

export default Hint
