

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as React from "react";
import * as MapFocusedJob from "../MapFocusedJob.mjs";

function MapJobsFocusedJob(Props) {
  var wire = Props.wire;
  var channel = Props.channel;
  var focusedJob = Props.focusedJob;
  var onClose = Props.onClose;
  var onClick = Props.onClick;
  if (focusedJob !== undefined) {
    return React.createElement(MapFocusedJob.make, {
                wire: wire,
                job: focusedJob,
                onClick: (function () {
                    onClick(focusedJob.uuid);
                  }),
                onClose: onClose,
                channel: channel,
                key: Uuid.toString(focusedJob.uuid)
              });
  } else {
    return null;
  }
}

var make = MapJobsFocusedJob;

export {
  make ,
}
/* Uuid Not a pure module */
