


var emptyButtonOptions = {
  clientUuid: undefined,
  siteUuid: undefined,
  templateUuid: undefined,
  recurringJobUuid: undefined
};

export {
  emptyButtonOptions ,
}
/* No side effect */
