

import * as React from "react";
import * as Locale from "../../../../../../../../../libraries/Locale.mjs";
import * as AppContext from "../../../../../../../../../context/AppContext.mjs";
import * as ModalQuery from "../../../../../../../../../types/ModalQuery.mjs";
import * as Assignee_Types from "../../../../../../../../../types/Assignee_Types.mjs";
import * as Schedule_State from "../../../../../../../lib/state/Schedule_State.mjs";
import * as Schedule_Popover from "../../../../../../../components/popovers/popover/Schedule_Popover.mjs";
import * as WeekTimeline_State from "../../../../../lib/state/WeekTimeline_State.mjs";
import * as WeekTimeline_Utils from "../../../../../lib/utils/WeekTimeline_Utils.mjs";
import * as Schedule_AddJobPanel from "../../../../../../../components/add_job_panel/Schedule_AddJobPanel.mjs";
import * as Schedule_UtilsTimeline from "../../../../../../../lib/utils/Schedule_UtilsTimeline.mjs";

function getScheduledAt(wire, period, sizes, viewportEl, coordinate) {
  return WeekTimeline_Utils.FindByCoordinateInTimeline.scheduledAt(wire, period, sizes, Schedule_UtilsTimeline.ConvertPoint.fromPageToNode(viewportEl, coordinate));
}

function WeekTimeline_AddJobPopover(Props) {
  var assignee = Props.assignee;
  var coordinate = Props.coordinate;
  var viewportEl = Props.viewportEl;
  var close = Props.close;
  var wire = AppContext.useWire();
  var openModal = wire.openModal;
  var sizes = WeekTimeline_State.useSizes();
  var period = Schedule_State.usePeriod();
  var scheduledAt = getScheduledAt(wire, period, sizes, viewportEl, coordinate);
  var addJob = function (templateUuid) {
    return function ($$event) {
      $$event.preventDefault();
      var options = {
        TAG: "Schedule",
        _0: ModalQuery.Job.NewOptions.Schedule.make(Locale.T.formatM("DateTimeISO")(scheduledAt), {
              NAME: "Uuid",
              VAL: Assignee_Types.Assignee.uuid(assignee)
            }, templateUuid)
      };
      openModal({
            NAME: "Job",
            VAL: {
              NAME: "New",
              VAL: options
            }
          });
      close();
    };
  };
  var clickTargetPosition = {
    NAME: "Coordinate",
    VAL: coordinate
  };
  return React.createElement(Schedule_Popover.make, {
              clickTargetPosition: clickTargetPosition,
              children: React.createElement(Schedule_AddJobPanel.make, {
                    scheduledAt: scheduledAt,
                    addJob: addJob,
                    close: close
                  })
            });
}

var make = WeekTimeline_AddJobPopover;

export {
  make ,
}
/* react Not a pure module */
