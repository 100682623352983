

import * as Hooks from "../../libraries/hooks/Hooks.mjs";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import Form_inputJsx from "planado/components/input/form_input.jsx";

function FormInput(Props) {
  var _type = Props._type;
  var value = Props.value;
  var onValueChange = Props.onValueChange;
  var onBlur = Props.onBlur;
  var onFocus = Props.onFocus;
  var id = Props.id;
  var readOnly = Props.readOnly;
  var name = Props.name;
  var disabled = Props.disabled;
  var labelText = Props.labelText;
  var maxLength = Props.maxLength;
  var errors = Props.errors;
  var wrapperClass = Props.wrapperClass;
  var inputClass = Props.inputClass;
  var autoFocus = Props.autoFocus;
  var placeholder = Props.placeholder;
  var id$1 = Hooks.useId(id);
  var tmp = {
    type: _type,
    value: value,
    onValueChange: onValueChange,
    id: id$1
  };
  if (onBlur !== undefined) {
    tmp.onBlur = Caml_option.valFromOption(onBlur);
  }
  if (onFocus !== undefined) {
    tmp.onFocus = Caml_option.valFromOption(onFocus);
  }
  if (readOnly !== undefined) {
    tmp.readOnly = readOnly;
  }
  if (name !== undefined) {
    tmp.name = name;
  }
  if (disabled !== undefined) {
    tmp.disabled = disabled;
  }
  if (labelText !== undefined) {
    tmp.labelText = labelText;
  }
  if (maxLength !== undefined) {
    tmp.maxLength = maxLength;
  }
  if (errors !== undefined) {
    tmp.errors = Caml_option.valFromOption(errors);
  }
  if (wrapperClass !== undefined) {
    tmp.wrapperClass = Caml_option.valFromOption(wrapperClass);
  }
  if (inputClass !== undefined) {
    tmp.inputClass = Caml_option.valFromOption(inputClass);
  }
  if (autoFocus !== undefined) {
    tmp.autoFocus = autoFocus;
  }
  if (placeholder !== undefined) {
    tmp.placeholder = placeholder;
  }
  return React.createElement(Form_inputJsx, tmp);
}

var Raw = {
  make: Form_inputJsx
};

var make = FormInput;

export {
  Raw ,
  make ,
}
/* Raw Not a pure module */
