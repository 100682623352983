

import * as Case from "./utils/Case.mjs";
import * as RCore from "./libraries/RCore.mjs";
import * as Utils from "./utils/Utils.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Js_list from "rescript/lib/es6/js_list.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";

function fieldReducer(es, param) {
  var k = param[0];
  var keyReducer = function (es, e) {
    var mbString = Js_json.decodeString(e);
    if (mbString !== undefined) {
      return Js_list.cons([
                  k,
                  mbString
                ], es);
    } else {
      return es;
    }
  };
  var mbArray = Js_json.decodeArray(param[1]);
  if (mbArray !== undefined) {
    return Js_array.reduceRight(keyReducer, es, mbArray);
  } else {
    return es;
  }
}

function parse(json) {
  return RCore.$$Option.mapWithDefault(RCore.$$Option.flatMap(RCore.$$Option.flatMap(Js_json.decodeObject(Case.camelize(JSON.parse(json))), (function (__x) {
                        return Js_dict.get(__x, "errors");
                      })), Js_json.decodeObject), /* [] */0, (function (v) {
                return Js_array.reduceRight(fieldReducer, /* [] */0, Js_dict.entries(v));
              }));
}

function _collapse(es) {
  if (es) {
    return Core__List.map(es, (function (param) {
                  return param[1];
                }));
  }
  
}

function get(key, errors) {
  return _collapse(Core__List.filter(errors, (function (param) {
                    return param[0] === key;
                  })));
}

function getArray(key, errors) {
  var xs = _collapse(Core__List.filter(errors, (function (param) {
              return param[0] === key;
            })));
  if (xs !== undefined) {
    return Core__List.toArray(xs);
  } else {
    return [];
  }
}

function getString(key, errors) {
  return RCore.$$Option.map(get(key, errors), (function (list) {
                return Utils.Translations.joinWithComma(Core__List.toArray(list));
              }));
}

function getMany(keys, errors) {
  var es = Js_list.flatten(Js_list.map((function (k) {
              return RCore.$$Option.getOr(get(k, errors), /* [] */0);
            }), keys));
  if (es) {
    return es;
  }
  
}

export {
  parse ,
  get ,
  getArray ,
  getString ,
  getMany ,
}
/* Case Not a pure module */
