

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Locale from "../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as MapTypes from "../MapTypes.mjs";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as MapJobsAPI from "./MapJobsAPI.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as MapJobsTypes from "./MapJobsTypes.mjs";
import * as MapJobsUtils from "./MapJobsUtils.mjs";
import * as Shared_Lib_IO from "../../../shared/lib/Shared_Lib_IO.mjs";
import * as Types_Spatial from "../../../types/Types_Spatial.mjs";
import * as MapJobsStorage from "./MapJobsStorage.mjs";

function withinBoundingBox(param, boundingBox, useBoundingBox) {
  var $$location = param.location;
  if ($$location === undefined) {
    return true;
  }
  if (boundingBox === undefined) {
    return true;
  }
  if (!useBoundingBox) {
    return true;
  }
  var mappedLocation_longitude = Types_Spatial.Longitude.fromNumber($$location.longitude);
  var mappedLocation_latitude = Types_Spatial.Latitude.fromNumber($$location.latitude);
  var mappedLocation = {
    longitude: mappedLocation_longitude,
    latitude: mappedLocation_latitude
  };
  return Types_Spatial.BoundingBox.withinBoundingBox(mappedLocation, boundingBox);
}

function compDate(a, b) {
  return Locale.T.equal(Locale.T.startOf("day", a), Locale.T.startOf("day", b));
}

function filterByPeriod(dates, job) {
  var at = job.scheduledAt;
  if (at !== undefined) {
    return Core__List.has(dates, Caml_option.valFromOption(at), compDate);
  } else {
    return true;
  }
}

function filterByTerritory(territory, job) {
  var match = job.territoryUuid;
  if (territory !== undefined) {
    if (match !== undefined) {
      return Caml_obj.equal(Caml_option.valFromOption(territory), Caml_option.valFromOption(match));
    } else {
      return false;
    }
  } else {
    return true;
  }
}

function showJob(dates, useBoundingBox, filter, job) {
  if (withinBoundingBox(job, filter.boundingBox, useBoundingBox) && filterByPeriod(dates, job) && filterByTerritory(filter.territory, job)) {
    var jobType = filter.jobType;
    var match = job.typeUuid;
    if (jobType !== undefined) {
      if (match !== undefined) {
        return Caml_obj.equal(Caml_option.valFromOption(jobType), Caml_option.valFromOption(match));
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function updateJobs(dates, updatedJobs) {
  var scheduledJobs = RCore.$$Array.keep(updatedJobs, (function (param) {
          var scheduledAt = param.scheduledAt;
          var status = param.status;
          var tmp;
          switch (status) {
            case "Posted" :
            case "Scheduled" :
                tmp = false;
                break;
            default:
              tmp = true;
          }
          if (tmp && RCore.$$Option.isNone(scheduledAt)) {
            return true;
          } else if (status !== "Scheduled") {
            return RCore.$$Option.isSome(scheduledAt);
          } else {
            return false;
          }
        }));
  var unscheduledJobs = RCore.$$Array.keep(updatedJobs, (function (param) {
          var status = param.status;
          if (status === "Posted") {
            return true;
          } else {
            return status === "Scheduled";
          }
        }));
  return {
          TAG: "Updated",
          _0: dates,
          _1: scheduledJobs,
          _2: unscheduledJobs
        };
}

function initial(boundingBox) {
  return {
          jobs: MapJobsTypes.Jobs.initial,
          fetching: true,
          filter: MapJobsTypes.Filter.initial(boundingBox),
          showUnscheduled: true,
          focusedJob: undefined,
          filterValues: undefined,
          workers: [],
          locations: [],
          useBoundingBox: false,
          rendered: false
        };
}

function reducer(wire, channel, today, state, action) {
  if (typeof action !== "object") {
    switch (action) {
      case "FocusJobOnMap" :
          var job = state.focusedJob;
          if (job !== undefined) {
            return {
                    TAG: "SideEffect",
                    _0: (function (param) {
                        MapJobsUtils.$$Map.focusJob(channel, state.filter, job);
                      })
                  };
          } else {
            return "NoUpdate";
          }
      case "BlurJob" :
          var job$1 = state.focusedJob;
          if (job$1 !== undefined) {
            return {
                    TAG: "UpdateWithSideEffect",
                    _0: {
                      jobs: state.jobs,
                      fetching: state.fetching,
                      filter: state.filter,
                      showUnscheduled: state.showUnscheduled,
                      focusedJob: undefined,
                      filterValues: state.filterValues,
                      workers: state.workers,
                      locations: state.locations,
                      useBoundingBox: state.useBoundingBox,
                      rendered: state.rendered
                    },
                    _1: (function (param) {
                        MapJobsUtils.$$Map.blurJob(channel, param.state.filter, job$1);
                      })
                  };
          } else {
            return "NoUpdate";
          }
      case "ToggleUnscheduled" :
          var showUnscheduled = !state.showUnscheduled;
          var job$2 = state.focusedJob;
          var focusedJob;
          if (job$2 !== undefined) {
            var status = job$2.status;
            focusedJob = !showUnscheduled && (status === "Posted" || status === "Scheduled") ? undefined : job$2;
          } else {
            focusedJob = job$2;
          }
          return {
                  TAG: "UpdateWithSideEffect",
                  _0: {
                    jobs: state.jobs,
                    fetching: state.fetching,
                    filter: state.filter,
                    showUnscheduled: showUnscheduled,
                    focusedJob: focusedJob,
                    filterValues: state.filterValues,
                    workers: state.workers,
                    locations: state.locations,
                    useBoundingBox: state.useBoundingBox,
                    rendered: state.rendered
                  },
                  _1: (function (param) {
                      var state$1 = param.state;
                      MapJobsUtils.$$Map.replaceJobs(state$1.showUnscheduled, channel, state$1.filter, state.jobs.jobs, state$1.jobs.jobs);
                      param.send("FocusJobOnMap");
                    })
                };
      case "ToggleUseBoundingBox" :
          return {
                  TAG: "UpdateWithSideEffect",
                  _0: {
                    jobs: state.jobs,
                    fetching: state.fetching,
                    filter: state.filter,
                    showUnscheduled: state.showUnscheduled,
                    focusedJob: state.focusedJob,
                    filterValues: state.filterValues,
                    workers: state.workers,
                    locations: state.locations,
                    useBoundingBox: !state.useBoundingBox,
                    rendered: state.rendered
                  },
                  _1: (function (param) {
                      param.send("FetchJobs");
                      var match = wire.ctx.user;
                      if (match !== undefined) {
                        return Shared_Lib_IO.unsafeRunAsync(MapJobsStorage.set(match.uuid, param.state.useBoundingBox), (function (prim) {
                                      
                                    }));
                      }
                      
                    })
                };
      case "FetchJobs" :
          var filter;
          if (state.useBoundingBox) {
            filter = state.filter;
          } else {
            var init = state.filter;
            filter = {
              period: init.period,
              template: init.template,
              territory: init.territory,
              jobType: init.jobType,
              boundingBox: undefined
            };
          }
          return {
                  TAG: "UpdateWithIO",
                  _0: {
                    jobs: state.jobs,
                    fetching: true,
                    filter: state.filter,
                    showUnscheduled: state.showUnscheduled,
                    focusedJob: state.focusedJob,
                    filterValues: state.filterValues,
                    workers: state.workers,
                    locations: state.locations,
                    useBoundingBox: state.useBoundingBox,
                    rendered: state.rendered
                  },
                  _1: Shared_Lib_IO.bimap(MapJobsAPI.Index.$$fetch(wire, filter), (function (result) {
                          if (result !== undefined && result.TAG === "Ok") {
                            return {
                                    TAG: "FetchJobsSuccess",
                                    _0: result._0
                                  };
                          } else {
                            return "FetchJobsError";
                          }
                        }), (function (_error) {
                          return "FetchJobsError";
                        }))
                };
      case "FetchJobsError" :
          return {
                  TAG: "Update",
                  _0: {
                    jobs: state.jobs,
                    fetching: false,
                    filter: state.filter,
                    showUnscheduled: state.showUnscheduled,
                    focusedJob: state.focusedJob,
                    filterValues: state.filterValues,
                    workers: state.workers,
                    locations: state.locations,
                    useBoundingBox: state.useBoundingBox,
                    rendered: state.rendered
                  }
                };
      case "FetchJobError" :
          return "NoUpdate";
      
    }
  } else {
    switch (action.TAG) {
      case "JobClicked" :
          var jobUuid = action._0;
          var job$3 = RCore.$$Array.getBy(state.jobs.jobs, (function (param) {
                  return Caml_obj.equal(param.uuid, jobUuid);
                }));
          var match = state.focusedJob;
          if (job$3 === undefined) {
            return "NoUpdate";
          }
          if (match !== undefined && Caml_obj.equal(job$3.uuid, match.uuid)) {
            return {
                    TAG: "SideEffect",
                    _0: (function (param) {
                        param.send("BlurJob");
                      })
                  };
          }
          return {
                  TAG: "SideEffect",
                  _0: (function (param) {
                      param.send({
                            TAG: "FocusJob",
                            _0: job$3
                          });
                    })
                };
          break;
      case "FocusJob" :
          var nextFocusedJob = action._0;
          var prevFocusedJob = state.focusedJob;
          if (prevFocusedJob !== undefined) {
            if (Caml_obj.equal(prevFocusedJob.uuid, nextFocusedJob.uuid)) {
              return {
                      TAG: "SideEffect",
                      _0: (function (param) {
                          param.send("BlurJob");
                        })
                    };
            } else {
              return {
                      TAG: "UpdateWithSideEffect",
                      _0: {
                        jobs: state.jobs,
                        fetching: state.fetching,
                        filter: state.filter,
                        showUnscheduled: state.showUnscheduled,
                        focusedJob: nextFocusedJob,
                        filterValues: state.filterValues,
                        workers: state.workers,
                        locations: state.locations,
                        useBoundingBox: state.useBoundingBox,
                        rendered: state.rendered
                      },
                      _1: (function (param) {
                          var state = param.state;
                          MapJobsUtils.$$Map.blurJob(channel, state.filter, prevFocusedJob);
                          MapJobsUtils.$$Map.focusJob(channel, state.filter, nextFocusedJob);
                        })
                    };
            }
          } else {
            return {
                    TAG: "UpdateWithSideEffect",
                    _0: {
                      jobs: state.jobs,
                      fetching: state.fetching,
                      filter: state.filter,
                      showUnscheduled: state.showUnscheduled,
                      focusedJob: nextFocusedJob,
                      filterValues: state.filterValues,
                      workers: state.workers,
                      locations: state.locations,
                      useBoundingBox: state.useBoundingBox,
                      rendered: state.rendered
                    },
                    _1: (function (param) {
                        MapJobsUtils.$$Map.focusJob(channel, param.state.filter, nextFocusedJob);
                      })
                  };
          }
      case "SetFilter" :
          var filterAction = action._0;
          var filter$1 = MapJobsTypes.Filter.reducer(state.filter, filterAction);
          return {
                  TAG: "UpdateWithSideEffect",
                  _0: {
                    jobs: state.jobs,
                    fetching: state.fetching,
                    filter: filter$1,
                    showUnscheduled: state.showUnscheduled,
                    focusedJob: state.focusedJob,
                    filterValues: state.filterValues,
                    workers: state.workers,
                    locations: state.locations,
                    useBoundingBox: state.useBoundingBox,
                    rendered: state.rendered
                  },
                  _1: (function (param) {
                      var send = param.send;
                      if (filterAction.TAG === "SetBoundingBox" && !param.state.useBoundingBox) {
                        return ;
                      } else {
                        return send("FetchJobs");
                      }
                    })
                };
      case "SetJobs" :
          var jobs = MapJobsTypes.Jobs.reducer(state.jobs, action._0);
          return {
                  TAG: "Update",
                  _0: {
                    jobs: jobs,
                    fetching: state.fetching,
                    filter: state.filter,
                    showUnscheduled: state.showUnscheduled,
                    focusedJob: state.focusedJob,
                    filterValues: state.filterValues,
                    workers: state.workers,
                    locations: state.locations,
                    useBoundingBox: state.useBoundingBox,
                    rendered: state.rendered
                  }
                };
      case "EnableUseBoundingBox" :
          var filter$2 = MapJobsTypes.Filter.reducer(state.filter, {
                TAG: "SetBoundingBox",
                _0: action._0
              });
          return {
                  TAG: "UpdateWithSideEffect",
                  _0: {
                    jobs: state.jobs,
                    fetching: state.fetching,
                    filter: filter$2,
                    showUnscheduled: state.showUnscheduled,
                    focusedJob: state.focusedJob,
                    filterValues: state.filterValues,
                    workers: state.workers,
                    locations: state.locations,
                    useBoundingBox: true,
                    rendered: true
                  },
                  _1: (function (param) {
                      param.send("FetchJobs");
                    })
                };
      case "DisableUseBoundingBox" :
          var filter$3 = MapJobsTypes.Filter.reducer(state.filter, {
                TAG: "SetBoundingBox",
                _0: action._0
              });
          return {
                  TAG: "UpdateWithSideEffect",
                  _0: {
                    jobs: state.jobs,
                    fetching: state.fetching,
                    filter: filter$3,
                    showUnscheduled: state.showUnscheduled,
                    focusedJob: state.focusedJob,
                    filterValues: state.filterValues,
                    workers: state.workers,
                    locations: state.locations,
                    useBoundingBox: false,
                    rendered: true
                  },
                  _1: (function (param) {
                      param.send("FetchJobs");
                    })
                };
      case "FetchJobsSuccess" :
          var match$1 = action._0;
          var start = match$1.start;
          var dates = Locale.T.dateRange(Locale.T.daysBetween(match$1.finish, start) + 1 | 0, start);
          var jobs$1 = MapJobsTypes.Jobs.reducer(state.jobs, {
                TAG: "Updated",
                _0: dates,
                _1: match$1.scheduledJobs,
                _2: match$1.unscheduledJobs
              });
          var job$4 = state.focusedJob;
          var focusedJob$1;
          if (job$4 !== undefined) {
            var uuid = job$4.uuid;
            focusedJob$1 = RCore.$$Option.isSome(RCore.$$Array.getBy(jobs$1.jobs, (function (job) {
                        return Caml_obj.equal(job.uuid, uuid);
                      }))) ? job$4 : undefined;
          } else {
            focusedJob$1 = undefined;
          }
          return {
                  TAG: "UpdateWithSideEffect",
                  _0: {
                    jobs: jobs$1,
                    fetching: false,
                    filter: state.filter,
                    showUnscheduled: state.showUnscheduled,
                    focusedJob: focusedJob$1,
                    filterValues: {
                      territories: match$1.territories,
                      templates: match$1.templates,
                      types: match$1.types
                    },
                    workers: match$1.workers,
                    locations: match$1.locations,
                    useBoundingBox: state.useBoundingBox,
                    rendered: state.rendered
                  },
                  _1: (function (param) {
                      var state$1 = param.state;
                      MapJobsUtils.$$Map.replaceJobs(state$1.showUnscheduled, channel, state$1.filter, state.jobs.jobs, state$1.jobs.jobs);
                      MapJobsUtils.$$Map.replaceLocations(channel, state.workers, state.locations, state$1.workers, state$1.locations, today, dates);
                      param.send("FocusJobOnMap");
                    })
                };
      case "RemoveJob" :
          var uuid$1 = action._0;
          var match$2 = state.jobs;
          var jobs$2 = match$2.jobs;
          if (!MapJobsUtils.Jobs.includes(jobs$2, uuid$1)) {
            return "NoUpdate";
          }
          var jobsAction = updateJobs(match$2.dates, MapJobsUtils.Jobs.remove(jobs$2, uuid$1));
          var updatedJobs = MapJobsTypes.Jobs.reducer(state.jobs, jobsAction);
          return {
                  TAG: "UpdateWithSideEffect",
                  _0: {
                    jobs: updatedJobs,
                    fetching: state.fetching,
                    filter: state.filter,
                    showUnscheduled: state.showUnscheduled,
                    focusedJob: state.focusedJob,
                    filterValues: state.filterValues,
                    workers: state.workers,
                    locations: state.locations,
                    useBoundingBox: state.useBoundingBox,
                    rendered: state.rendered
                  },
                  _1: (function (param) {
                      var send = param.send;
                      var state$1 = param.state;
                      MapJobsUtils.$$Map.replaceJobs(state$1.showUnscheduled, channel, state$1.filter, state.jobs.jobs, state$1.jobs.jobs);
                      var match = state$1.focusedJob;
                      if (match !== undefined) {
                        if (Caml_obj.equal(match.uuid, uuid$1)) {
                          return send("BlurJob");
                        } else {
                          return send("FocusJobOnMap");
                        }
                      }
                      
                    })
                };
      case "FetchJobSuccess" :
          var job$5 = action._0;
          if (action._1 === "JobUpdated") {
            var match$3 = state.jobs;
            var dates$1 = match$3.dates;
            var jobs$3 = match$3.jobs;
            if (!MapJobsUtils.Jobs.includes(jobs$3, job$5.uuid)) {
              return "NoUpdate";
            }
            if (!showJob(dates$1, state.useBoundingBox, state.filter, job$5)) {
              return {
                      TAG: "SideEffect",
                      _0: (function (param) {
                          param.send({
                                TAG: "RemoveJob",
                                _0: job$5.uuid
                              });
                        })
                    };
            }
            var jobsAction$1 = updateJobs(dates$1, MapJobsUtils.Jobs.replace(jobs$3, job$5));
            var updatedJobs$1 = MapJobsTypes.Jobs.reducer(state.jobs, jobsAction$1);
            return {
                    TAG: "UpdateWithSideEffect",
                    _0: {
                      jobs: updatedJobs$1,
                      fetching: state.fetching,
                      filter: state.filter,
                      showUnscheduled: state.showUnscheduled,
                      focusedJob: state.focusedJob,
                      filterValues: state.filterValues,
                      workers: state.workers,
                      locations: state.locations,
                      useBoundingBox: state.useBoundingBox,
                      rendered: state.rendered
                    },
                    _1: (function (param) {
                        var state$1 = param.state;
                        MapJobsUtils.$$Map.replaceJobs(state$1.showUnscheduled, channel, state$1.filter, state.jobs.jobs, state$1.jobs.jobs);
                        param.send("FocusJobOnMap");
                      })
                  };
          }
          var match$4 = state.jobs;
          var dates$2 = match$4.dates;
          if (!showJob(dates$2, state.useBoundingBox, state.filter, job$5)) {
            return "NoUpdate";
          }
          var jobsAction$2 = updateJobs(dates$2, MapJobsUtils.Jobs.add(match$4.jobs, job$5));
          var updatedJobs$2 = MapJobsTypes.Jobs.reducer(state.jobs, jobsAction$2);
          return {
                  TAG: "UpdateWithSideEffect",
                  _0: {
                    jobs: updatedJobs$2,
                    fetching: state.fetching,
                    filter: state.filter,
                    showUnscheduled: state.showUnscheduled,
                    focusedJob: state.focusedJob,
                    filterValues: state.filterValues,
                    workers: state.workers,
                    locations: state.locations,
                    useBoundingBox: state.useBoundingBox,
                    rendered: state.rendered
                  },
                  _1: (function (param) {
                      var state$1 = param.state;
                      MapJobsUtils.$$Map.replaceJobs(state$1.showUnscheduled, channel, state$1.filter, state.jobs.jobs, state$1.jobs.jobs);
                      param.send("FocusJobOnMap");
                    })
                };
      case "AddLocations" :
          var match$5 = state.jobs;
          var dates$3 = match$5.dates;
          var newLocations = action._0.locations.map(MapTypes.$$Location.fromMessage).toReversed();
          var updatedLocations = state.locations.map(function (l) {
                return RCore.$$Option.getOr(RCore.$$Array.getBy(newLocations, (function (param) {
                                  return Uuid.equal(param.workerUuid, l.workerUuid);
                                })), l);
              });
          return {
                  TAG: "UpdateWithSideEffect",
                  _0: {
                    jobs: state.jobs,
                    fetching: state.fetching,
                    filter: state.filter,
                    showUnscheduled: state.showUnscheduled,
                    focusedJob: state.focusedJob,
                    filterValues: state.filterValues,
                    workers: state.workers,
                    locations: updatedLocations,
                    useBoundingBox: state.useBoundingBox,
                    rendered: state.rendered
                  },
                  _1: (function (param) {
                      MapJobsUtils.$$Map.replaceLocations(channel, state.workers, state.locations, param.state.workers, updatedLocations, today, dates$3);
                    })
                };
      
    }
  }
}

export {
  initial ,
  reducer ,
}
/* Uuid Not a pure module */
