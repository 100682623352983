

import * as ReactRedux from "react-redux";

var make = ReactRedux.Provider;

export {
  make ,
}
/* make Not a pure module */
