

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";

function fromString(s) {
  switch (s) {
    case "products" :
        return "Products";
    case "services" :
        return "Services";
    default:
      return ;
  }
}

function toString(t) {
  if (t === "Products") {
    return "products";
  } else {
    return "services";
  }
}

var decode = JsonDecode.fromVariant(JsonDecode.string, fromString);

function empty(type_) {
  return {
          name: undefined,
          type_: type_,
          description: undefined,
          parentUuid: undefined,
          externalId: undefined,
          archived: false
        };
}

var Form = {
  empty: empty
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              type_: field.required("type", decode),
              description: field.required("description", JsonDecode.option(JsonDecode.string)),
              parentUuid: field.required("parentUuid", JsonDecode.option(Uuid.decode)),
              externalId: field.required("externalId", JsonDecode.option(JsonDecode.string)),
              archived: field.required("archived", JsonDecode.bool)
            };
    });

var Category = {
  Form: Form,
  decode: decode$1
};

function empty$1(ctx) {
  return {
          name: undefined,
          description: undefined,
          priceDefault: undefined,
          currency: ctx.currency,
          categoryUuid: undefined,
          unitUuid: undefined,
          externalId: undefined,
          gtin: undefined,
          archived: false
        };
}

var Form$1 = {
  empty: empty$1
};

var decode$2 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              description: field.required("description", JsonDecode.option(JsonDecode.string)),
              priceDefault: field.required("priceDefault", JsonDecode.option(JsonDecode.string)),
              currency: field.required("currency", JsonDecode.string),
              externalId: field.required("externalId", JsonDecode.option(JsonDecode.string)),
              categoryUuid: field.required("categoryUuid", JsonDecode.option(Uuid.decode)),
              unitUuid: field.required("unitUuid", JsonDecode.option(Uuid.decode)),
              gtin: field.required("gtin", JsonDecode.option(JsonDecode.string)),
              archived: field.required("archived", JsonDecode.bool)
            };
    });

var Product = {
  Form: Form$1,
  decode: decode$2
};

function empty$2(ctx) {
  return {
          name: undefined,
          description: undefined,
          priceDefault: undefined,
          currency: ctx.currency,
          categoryUuid: undefined,
          externalId: undefined,
          archived: false
        };
}

var Form$2 = {
  empty: empty$2
};

var decode$3 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              description: field.required("description", JsonDecode.option(JsonDecode.string)),
              priceDefault: field.required("priceDefault", JsonDecode.option(JsonDecode.string)),
              currency: field.required("currency", JsonDecode.string),
              externalId: field.required("externalId", JsonDecode.option(JsonDecode.string)),
              categoryUuid: field.required("categoryUuid", JsonDecode.option(Uuid.decode)),
              archived: field.required("archived", JsonDecode.bool)
            };
    });

var Service = {
  Form: Form$2,
  decode: decode$3
};

function empty$3(type_) {
  return {
          name: undefined,
          code: undefined,
          type_: type_,
          externalId: undefined,
          archived: false
        };
}

var Form$3 = {
  empty: empty$3
};

var decode$4 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              code: field.required("code", JsonDecode.string),
              type_: field.required("type", decode),
              externalId: field.required("externalId", JsonDecode.option(JsonDecode.string)),
              archived: field.required("archived", JsonDecode.bool)
            };
    });

var Unit = {
  Form: Form$3,
  decode: decode$4
};

function uuidToId(prim) {
  return prim;
}

function idToUuid(prim) {
  return prim;
}

var Type = {
  toString: toString
};

export {
  uuidToId ,
  idToUuid ,
  Type ,
  Category ,
  Product ,
  Service ,
  Unit ,
}
/* decode Not a pure module */
