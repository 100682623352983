

import * as RCore from "../../../../../../../../libraries/RCore.mjs";
import * as React from "react";
import * as WeekTimeline_BarCell from "./bar_cell/WeekTimeline_BarCell.mjs";
import * as WeekTimeline_Constants from "../../../../lib/WeekTimeline_Constants.mjs";

function WeekTimeline_BarCells(Props) {
  var viewportEl = Props.viewportEl;
  var assignee = Props.assignee;
  return RCore.$$Array.make(WeekTimeline_Constants.daysInWeek, undefined).map(function (param, weekDay) {
              return React.createElement(WeekTimeline_BarCell.make, {
                          viewportEl: viewportEl,
                          assignee: assignee,
                          weekDay: weekDay,
                          key: weekDay.toString()
                        });
            });
}

var make = WeekTimeline_BarCells;

export {
  make ,
}
/* react Not a pure module */
