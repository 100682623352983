

import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";

var settings$p = Utils.Translations.tr("js.admin.settings.title.settings");

var jobs$p = Utils.Translations.tr("js.admin.settings.title.jobs");

function JobSettingsHeader(Props) {
  var ctx = Props.ctx;
  return React.createElement("div", {
              className: "page-header"
            }, React.createElement("div", {
                  className: "row"
                }, React.createElement("h1", {
                      className: "settings-page__hl"
                    }, settings$p(ctx), React.createElement("span", undefined, jobs$p(ctx)))));
}

var make = JobSettingsHeader;

export {
  make ,
}
/* settings' Not a pure module */
