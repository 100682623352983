

import * as Units from "../../../../../types/Units.mjs";
import * as Schedule_Constants from "../../../lib/Schedule_Constants.mjs";

var rowHeight = Units.Px.fromInt(50);

var barGapHeight = Units.Px.fromInt(14);

var halfOfBarGapHeight = Units.Px.divideByInt(barGapHeight, 2);

var activeDayHeight = Units.Px.fromInt(92);

var activeDayOffset = Units.Px.fromInt(34);

var unassignedRowHeight = Units.Px.fromInt(127);

var hoursInDay = Schedule_Constants.hoursInDay;

var daysInWeek = Schedule_Constants.daysInWeek;

var minutesInHour = Schedule_Constants.minutesInHour;

var minutesInDay = Schedule_Constants.minutesInDay;

var minutesInWeek = Schedule_Constants.minutesInWeek;

var stepsInHour = Schedule_Constants.stepsInHour;

var minutesInStep = Schedule_Constants.minutesInStep;

var dndScheduledJob = Schedule_Constants.dndScheduledJob;

var dndUnscheduledJob = Schedule_Constants.dndUnscheduledJob;

var dndUnassignedJob = Schedule_Constants.dndUnassignedJob;

var sidebarWidth = Schedule_Constants.sidebarWidth;

var unscheduledJobsWidth = Schedule_Constants.unscheduledJobsWidth;

var hiddenUnscheduledJobsWidth = Schedule_Constants.hiddenUnscheduledJobsWidth;

var numberOfJobsInRow = 4;

var numberOfVisibleJobs = 3;

export {
  hoursInDay ,
  daysInWeek ,
  minutesInHour ,
  minutesInDay ,
  minutesInWeek ,
  stepsInHour ,
  minutesInStep ,
  dndScheduledJob ,
  dndUnscheduledJob ,
  dndUnassignedJob ,
  sidebarWidth ,
  unscheduledJobsWidth ,
  hiddenUnscheduledJobsWidth ,
  rowHeight ,
  barGapHeight ,
  halfOfBarGapHeight ,
  activeDayHeight ,
  activeDayOffset ,
  unassignedRowHeight ,
  numberOfJobsInRow ,
  numberOfVisibleJobs ,
}
/* rowHeight Not a pure module */
