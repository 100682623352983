

import * as El from "../../../libraries/El.mjs";
import * as Hint from "../../common/Hint/Hint.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Types from "../../../types/Types.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as UI_Icon from "../../../ui/UI_Icon.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../../common/Optional.mjs";
import * as UI_Button from "../../../ui/UI_Button.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as UI_SortableList from "../../../ui/UI_SortableList.mjs";
import * as Template_ResolutionsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/template/Template_Resolutions.module.css";

function getId(resolution) {
  return resolution.id;
}

var SortableList = UI_SortableList.Make({
      getId: getId
    });

var empty_selectedResolutions = [];

var empty_possibleResolutionIds = [];

var empty = {
  dropdownActive: false,
  selectedResolutions: empty_selectedResolutions,
  possibleResolutionIds: empty_possibleResolutionIds
};

var heading$p = Utils.Translations.tr("js.templates.resolutions.titles.section");

var hint$p = Utils.Translations.t("js.templates.resolutions.hint");

var successful$p = Utils.Translations.tr("js.templates.resolutions.titles.success");

var unsuccessful$p = Utils.Translations.tr("js.templates.resolutions.titles.danger");

var missing$p = Utils.Translations.tr("js.templates.resolutions.missing_resolutions");

var showDropdown$p = Utils.Translations.tr("js.templates.resolutions.buttons.show_dropdown");

var addResolutions$p = Utils.Translations.tr("js.templates.resolutions.buttons.add_resolutions");

var error$p = Utils.Translations.t("js.templates.resolutions.error");

var selectEmpty$p = Utils.Translations.tr("js.templates.resolutions.select_empty");

var styles = Template_ResolutionsModuleCss;

function Template_Resolutions$Item(Props) {
  var id = Props.id;
  var name = Props.name;
  var successful = Props.successful;
  var deleteResolution = Props.deleteResolution;
  var deleteHandler = function (param) {
    deleteResolution(id);
  };
  var className = El.Cn.concatIf(styles.resolutionItem, [
        successful,
        styles.resolutionItemSuccessful
      ]);
  return React.createElement(SortableList.Item.make, {
              hasActivator: true,
              id: id,
              children: React.createElement("div", {
                    className: className
                  }, React.createElement(SortableList.Activator.make, {
                        id: id,
                        children: React.createElement("div", {
                              className: styles.resolutionDrag
                            })
                      }), name, React.createElement("button", {
                        className: styles.resolutionDelete,
                        onClick: deleteHandler
                      }))
            });
}

function Template_Resolutions$List(Props) {
  var resolutions = Props.resolutions;
  var deleteResolution = Props.deleteResolution;
  var ctx = Props.ctx;
  if (Caml_obj.equal(resolutions, [])) {
    return React.createElement("div", {
                className: styles.noResolutions
              }, missing$p(ctx));
  } else {
    return React.createElement("div", {
                className: styles.resolutionListWrapper
              }, resolutions.map(function (resolution) {
                    return React.createElement(Template_Resolutions$Item, {
                                id: resolution.id,
                                name: resolution.name,
                                successful: resolution.successful,
                                deleteResolution: deleteResolution,
                                key: Types.Id.toString(resolution.id)
                              });
                  }));
  }
}

function Template_Resolutions$Errors(Props) {
  var errors = Props.errors;
  return React.createElement(Optional.make, {
              show: Caml_obj.notequal(errors, []),
              children: React.createElement("div", {
                    className: styles.dropdownResolutionsError
                  }, errors.map(function (error, index) {
                        return React.createElement("div", {
                                    key: index.toString()
                                  }, error);
                      }))
            });
}

function Template_Resolutions$AddButton(Props) {
  var possibleResolutionIds = Props.possibleResolutionIds;
  var resolutions = Props.resolutions;
  var errors = Props.errors;
  var state = Props.state;
  var setState = Props.setState;
  var setError = Props.setError;
  var addResolutions = Props.addResolutions;
  var ctx = Props.ctx;
  var onClick = function (e) {
    e.preventDefault();
    var possible = state.possibleResolutionIds;
    if (Caml_obj.notequal(errors, [])) {
      var selectedResolutions = RCore.$$Array.keep(resolutions, (function (resolution) {
              if (resolution.selected) {
                return true;
              } else {
                return possible.some(function (p) {
                            return Caml_obj.equal(p, resolution.id);
                          });
              }
            }));
      var successfulPresence = selectedResolutions.some(function (r) {
            return r.successful;
          });
      var unsuccessfulPresence = selectedResolutions.some(function (r) {
            return !r.successful;
          });
      if (!successfulPresence || !unsuccessfulPresence) {
        setError("resolutions", [error$p(ctx)]);
      } else {
        setError("resolutions", []);
      }
    }
    addResolutions(possible);
    setState(function (current) {
          return {
                  dropdownActive: false,
                  selectedResolutions: current.selectedResolutions,
                  possibleResolutionIds: []
                };
        });
  };
  return React.createElement(Optional.make, {
              show: possibleResolutionIds.length !== resolutions.length,
              children: React.createElement("div", {
                    className: styles.addResolutionButton
                  }, React.createElement(UI_Button.make, {
                        purpose: "primary",
                        onClick: onClick,
                        children: addResolutions$p(ctx)
                      }))
            });
}

function Template_Resolutions$DropdownList(Props) {
  var resolutions = Props.resolutions;
  var possibleResolutionIds = Props.possibleResolutionIds;
  var state = Props.state;
  var setState = Props.setState;
  var wire = Props.wire;
  if (possibleResolutionIds.length === resolutions.length) {
    return React.createElement("div", {
                className: El.Cn.concat(styles.dropdownListItem, styles.dropdownListItemEmpty)
              }, selectEmpty$p(wire.ctx));
  }
  var onSelect = function (id) {
    var possible = state.possibleResolutionIds;
    if (possible.some(function (p) {
            return Caml_obj.equal(p, id);
          })) {
      return setState(function (current) {
                  return {
                          dropdownActive: current.dropdownActive,
                          selectedResolutions: current.selectedResolutions,
                          possibleResolutionIds: RCore.$$Array.keep(possible, (function (p) {
                                  return Caml_obj.notequal(p, id);
                                }))
                        };
                });
    } else {
      return setState(function (current) {
                  return {
                          dropdownActive: current.dropdownActive,
                          selectedResolutions: current.selectedResolutions,
                          possibleResolutionIds: Belt_Array.concatMany([
                                possible,
                                [id]
                              ])
                        };
                });
    }
  };
  var successfulResolutions = Utils.CompareLocale.sortAlphabetical(resolutions.filter(function (r) {
            if (r.successful) {
              return !r.selected;
            } else {
              return false;
            }
          }), (function (r) {
          return r.name.toLowerCase();
        }), wire);
  var unsuccessfulResolutions = Utils.CompareLocale.sortAlphabetical(resolutions.filter(function (r) {
            if (r.successful) {
              return false;
            } else {
              return !r.selected;
            }
          }), (function (r) {
          return r.name.toLowerCase();
        }), wire);
  return React.createElement("div", undefined, React.createElement(Optional.make, {
                  show: Caml_obj.notequal(successfulResolutions, []),
                  children: React.createElement("div", undefined, React.createElement("div", {
                            className: styles.dropdownResolutionBlock
                          }, successful$p(wire.ctx)), successfulResolutions.map(function (param) {
                            var id = param.id;
                            var itemClasses = El.Cn.concatIf(styles.dropdownListItem, [
                                  state.possibleResolutionIds.some(function (p) {
                                        return Caml_obj.equal(p, id);
                                      }),
                                  styles.isActive
                                ]);
                            return React.createElement("div", {
                                        key: Types.Id.toString(id),
                                        className: itemClasses,
                                        onClick: (function (param) {
                                            onSelect(id);
                                          })
                                      }, param.name);
                          }))
                }), React.createElement(Optional.make, {
                  show: Caml_obj.notequal(unsuccessfulResolutions, []),
                  children: React.createElement("div", undefined, React.createElement("div", {
                            className: styles.dropdownResolutionBlock
                          }, unsuccessful$p(wire.ctx)), unsuccessfulResolutions.map(function (param) {
                            var id = param.id;
                            var itemClasses = El.Cn.concatIf(styles.dropdownListItem, [
                                  state.possibleResolutionIds.some(function (p) {
                                        return Caml_obj.equal(p, id);
                                      }),
                                  styles.isActive
                                ]);
                            return React.createElement("div", {
                                        key: Types.Id.toString(id),
                                        className: itemClasses,
                                        onClick: (function (param) {
                                            onSelect(id);
                                          })
                                      }, param.name);
                          }))
                }));
}

function Template_Resolutions$Dropdown(Props) {
  var resolutions = Props.resolutions;
  var possibleResolutionIds = Props.possibleResolutionIds;
  var errors = Props.errors;
  var setError = Props.setError;
  var addResolutions = Props.addResolutions;
  var wire = Props.wire;
  var match = React.useState(function () {
        return empty;
      });
  var setState = match[1];
  var state = match[0];
  var dropdownClasses = El.Cn.concatIf(styles.dropdown, [
        state.dropdownActive,
        styles.isActive
      ]);
  return React.createElement("div", {
              className: styles.dropdownWrapper
            }, React.createElement(Template_Resolutions$Errors, {
                  errors: errors
                }), React.createElement(UI_Button.make, {
                  flavor: "outline",
                  className: styles.selectResolutionsButton,
                  onClick: (function (e) {
                      e.preventDefault();
                      setState(function (current) {
                            return {
                                    dropdownActive: !current.dropdownActive,
                                    selectedResolutions: current.selectedResolutions,
                                    possibleResolutionIds: current.possibleResolutionIds
                                  };
                          });
                    }),
                  children: null
                }, React.createElement(UI_Icon.make, {
                      className: styles.selectResolutionsButtonIcon,
                      icon: "plus"
                    }), showDropdown$p(wire.ctx)), React.createElement("div", {
                  className: dropdownClasses
                }, React.createElement("div", {
                      className: styles.dropdownResolutionList
                    }, React.createElement(Template_Resolutions$DropdownList, {
                          resolutions: resolutions,
                          possibleResolutionIds: possibleResolutionIds,
                          state: state,
                          setState: setState,
                          wire: wire
                        })), React.createElement(Template_Resolutions$AddButton, {
                      possibleResolutionIds: possibleResolutionIds,
                      resolutions: resolutions,
                      errors: errors,
                      state: state,
                      setState: setState,
                      setError: setError,
                      addResolutions: addResolutions,
                      ctx: wire.ctx
                    })));
}

function Template_Resolutions(Props) {
  var wire = Props.wire;
  var resolutions = Props.resolutions;
  var errors = Props.errors;
  var possibleResolutionIds = Props.possibleResolutionIds;
  var moveResolution = Props.moveResolution;
  var deleteResolution = Props.deleteResolution;
  var setError = Props.setError;
  var addResolutions = Props.addResolutions;
  var ctx = wire.ctx;
  var match = RCore.$$Array.partition(RCore.$$Array.keepMap(possibleResolutionIds, (function (id) {
              return RCore.$$Array.getBy(resolutions, (function (r) {
                            return Caml_obj.equal(r.id, id);
                          }));
            })), (function (r) {
          return r.successful;
        }));
  var unsuccRes = match[1];
  var succRes = match[0];
  return React.createElement("div", {
              className: "form-section"
            }, React.createElement("div", {
                  className: styles.headingWrapper
                }, React.createElement("div", {
                      className: styles.heading
                    }, heading$p(ctx)), React.createElement("div", {
                      className: styles.hint
                    }, React.createElement(Hint.make, {
                          content: hint$p(ctx)
                        }))), React.createElement("div", {
                  className: "form-container"
                }, React.createElement("div", {
                      className: styles.resolutionsSuccess
                    }, React.createElement("div", {
                          className: styles.resolutionsHeading
                        }, successful$p(ctx)), React.createElement(SortableList.make, {
                          items: succRes,
                          onDragEnd: (function (resolutions) {
                              moveResolution(resolutions, true);
                            }),
                          children: React.createElement(Template_Resolutions$List, {
                                resolutions: succRes,
                                deleteResolution: deleteResolution,
                                ctx: ctx
                              })
                        })), React.createElement("div", {
                      className: styles.resolutionsFailure
                    }, React.createElement("div", {
                          className: styles.resolutionsHeading
                        }, unsuccessful$p(ctx)), React.createElement(SortableList.make, {
                          items: unsuccRes,
                          onDragEnd: (function (resolutions) {
                              moveResolution(resolutions, false);
                            }),
                          children: React.createElement(Template_Resolutions$List, {
                                resolutions: unsuccRes,
                                deleteResolution: deleteResolution,
                                ctx: ctx
                              })
                        })), React.createElement(Template_Resolutions$Dropdown, {
                      resolutions: resolutions,
                      possibleResolutionIds: possibleResolutionIds,
                      errors: errors,
                      setError: setError,
                      addResolutions: addResolutions,
                      wire: wire
                    })));
}

var make = Template_Resolutions;

var $$default = Template_Resolutions;

export {
  make ,
  $$default as default,
}
/* SortableList Not a pure module */
