

import * as El from "../../../../libraries/El.mjs";
import * as React from "react";
import * as Schedule_ButtonGroupModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/button_group/Schedule_ButtonGroup.module.css";

var styles = Schedule_ButtonGroupModuleCss;

function Schedule_ButtonGroup(Props) {
  var idOpt = Props.id;
  var classNameOpt = Props.className;
  var children = Props.children;
  var id = idOpt !== undefined ? idOpt : "";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("div", {
              className: El.Cn.concat(styles.group, className),
              id: id
            }, children);
}

var make = Schedule_ButtonGroup;

export {
  make ,
}
/* styles Not a pure module */
