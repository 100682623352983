

import * as RCore from "../../../../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../../../../types/Types.mjs";
import * as Utils from "../../../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Select from "../../../../../../../../inputs/Select.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as BaseFormInput from "../../../../../../../../inputs/BaseFormInput.mjs";

var selectPlaceholder$p = Utils.Translations.t("js.components.select.placeholder");

function JobForm_ReportFieldDictionary(Props) {
  var id = Props.id;
  var label = Props.label;
  var value = Props.value;
  var dictionaryValues = Props.dictionaryValues;
  var onValueChange = Props.onValueChange;
  var disabled = Props.disabled;
  var errorsOpt = Props.errors;
  var wrapperClass = Props.wrapperClass;
  var ctx = Props.ctx;
  var errors = errorsOpt !== undefined ? errorsOpt : [];
  var options = dictionaryValues.map(function (v) {
        return Select.$$Option.fromUuid(v.name, v.uuid);
      });
  var classes = Belt_Array.concatMany([
        [[
            "job-report-field-container",
            true
          ]],
        wrapperClass !== undefined ? [[
              wrapperClass,
              true
            ]] : []
      ]);
  var tmp = {
    id: id,
    wrapperClass: Js_dict.fromArray(classes),
    errors: errors,
    children: React.createElement(Select.make, {
          placeholder: selectPlaceholder$p(ctx),
          onChange: (function (v) {
              onValueChange(RCore.$$Option.map(v === null ? undefined : Caml_option.some(v), Types.Id.toUuid));
            }),
          value: Js_null.fromOption(RCore.$$Option.map(value, Types.Id.fromUuid)),
          autosize: false,
          disabled: disabled,
          options: options
        })
  };
  if (label !== undefined) {
    tmp.labelText = label;
  }
  return React.createElement(BaseFormInput.make, tmp);
}

var make = JobForm_ReportFieldDictionary;

var $$default = JobForm_ReportFieldDictionary;

export {
  make ,
  $$default as default,
}
/* selectPlaceholder' Not a pure module */
