import { connect } from 'react-redux'
import HistoryDatePicker from 'planado/components/map/history_date_picker'
import { changeHistoryDate } from 'planado/actions/map'
import momentTimezone from 'moment-timezone'

const mapStateToProps = ({ historyDate, currentTime, loadingLocations }) => {
  return {
    selectedDateISO: historyDate,
    todaySelected:
      historyDate === momentTimezone(currentTime).startOf('day').format(),
    readOnly: loadingLocations
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onDateChange: date => dispatch(changeHistoryDate(date))
  }
}

const HistoryDatePickerLink = connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoryDatePicker)

export default HistoryDatePickerLink
