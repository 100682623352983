

import * as React from "react";
import * as GoogleMaps from "../../../bindings/googleMaps/GoogleMaps.mjs";
import * as YandexMaps from "../../../bindings/yandexMaps/YandexMaps.mjs";

function MapContext($$Map) {
  var initContext = React.createContext(undefined);
  var useMap = function () {
    return React.useContext(initContext);
  };
  var make = initContext.Provider;
  var makeProps = function (value, children, param) {
    return {
            value: value,
            children: children
          };
  };
  var Provider = {
    make: make,
    makeProps: makeProps
  };
  return {
          initContext: initContext,
          useMap: useMap,
          Provider: Provider
        };
}

var initContext = React.createContext(undefined);

function useMap() {
  return React.useContext(initContext);
}

var make = initContext.Provider;

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var Provider = {
  make: make,
  makeProps: makeProps
};

var GoogleMapContext = {
  initContext: initContext,
  useMap: useMap,
  Provider: Provider
};

var initContext$1 = React.createContext(undefined);

function useMap$1() {
  return React.useContext(initContext$1);
}

var make$1 = initContext$1.Provider;

function makeProps$1(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var Provider$1 = {
  make: make$1,
  makeProps: makeProps$1
};

var YandexMapContext = {
  initContext: initContext$1,
  useMap: useMap$1,
  Provider: Provider$1
};

export {
  MapContext ,
  GoogleMapContext ,
  YandexMapContext ,
}
/* initContext Not a pure module */
