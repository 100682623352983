// extracted by mini-css-extract-plugin
export var alignEnd = "align-end-XdaHMsc6lZ7HF5ek1OTy";
export var cell = "cell-nIjckclY8sXy5FWBvAA4";
export var completion = "completion-D8g_Od_d3cqUdU7IZIJq";
export var completionConnector = "completion-connector-cRQf0STTAg3RLWK51a41";
export var completionEnd = "completion-end-g1WDHooFkyZxpFMvAEnk";
export var container = "container-kOIZw_h0yp9ujiAwpCqq";
export var current = "current-oUdVxTszo8x41H_KJnwb";
export var deletedRow = "deleted-row-aEwNDj03YSuUFuY1579J";
export var disabledIcon = "disabled-icon-bldL3Qqtt02wjSoDZ5NC";
export var firstCell = "first-cell-V9UuGTcY3GfgJdtnP_xm";
export var header = "header-jB1C8k5XBfy7ll8lI6xQ";
export var icon = "icon-UwTPmQesl4NChDd1M92w";
export var iconContainer = "icon-container-sHZg8jk_C_Bx89nc5atH";
export var jobsContainer = "jobs-container-y2h_gvBn3zZELB3Spkwo";
export var jobsNavigation = "jobs-navigation-_cKrY0cBwQsQYQhWCEiJ";
export var navigateButton = "navigate-button-L8BY2bsbWG6mdNWko6ps";
export var resolution = "resolution-YYbF7O_c5R7Fdsb0eLNV";
export var row = "row-r2XQkkgnJCWs3hdkwNc1";
export var successful = "successful-O9wgUHq5UwRwJHDuSBe3";
export var unsuccessful = "unsuccessful-tH14jlYHNjHqk3jRpzrn";