

import * as Units from "../../../../../../../../types/Units.mjs";
import * as React from "react";
import * as WeekTimeline_ActiveDayIntervalModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/active_day/components/interval/WeekTimeline_ActiveDayInterval.module.css";

var styles = WeekTimeline_ActiveDayIntervalModuleCss;

function make(param) {
  return {
          left: Units.Px.toString(param.left),
          top: Units.Px.toString(Units.Px.zero),
          width: Units.Px.toString(Units.Px.max(param.width, Units.Px.fromInt(2)))
        };
}

function WeekTimeline_ActiveDayInterval(Props) {
  var position = Props.position;
  var style = make(position);
  return React.createElement("div", {
              className: styles.interval,
              style: style
            });
}

var make$1 = React.memo(WeekTimeline_ActiveDayInterval);

export {
  make$1 as make,
}
/* styles Not a pure module */
