// extracted by mini-css-extract-plugin
export var table = "table-K0Q7TYyQPO3XXhEbKtZw";
export var tableAddress = "table-address-fauI4aHsjl8QaP2MsGNF";
export var tableName = "table-name-uF_iyR0OzbrPilkjfwyi";
export var tableNextScheduledAt = "table-next-scheduled-at-SdCQ3ehT2JAuP_VCy_J7";
export var tableNotFound = "table-not-found-UVrpPCAWJ5WAkuKbg9nT";
export var tableNotFoundWithMargin = "table-not-found-with-margin-wWbHg7VTq4zPKua_ylIH";
export var tableNumber = "table-number-RAlglKLYX90PBNszFrdc";
export var tableScheduledAt = "table-scheduled-at-Wc6sckBlBN0Dtt9VVupV";
export var tableStatus = "table-status-N6VjaBPAkPi7fBFFz3Q4";
export var tableTemplate = "table-template-vesnBSP4NFsWyNZo5tf5";
export var tableTemplateOptional = "table-template-optional-X2TDvnfwZcWIO7kjTPKe";
export var tableWorker = "table-worker-TASGZjZHZ6lAvZ7aRjqb";
export var tooltip = "tooltip-pSMkpIdux7ywC4_At_SP";