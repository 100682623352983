

import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Switch from "../../../../../inputs/Switch.mjs";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as Schedule_Header_FilterComponent from "./Schedule_Header_FilterComponent.mjs";

var skills$p = Utils.Translations.tr("js.schedule.v2.filter.skills");

var selectedSkills$p = Utils.Translations.tr("js.schedule.v2.filter.selected_skills");

var emptySkills$p = Utils.Translations.tr("js.schedule.v2.filter.empty.skills");

var skillsModeLabel$p = Utils.Translations.tr("js.schedule.v2.filter.skills_mode_label");

var any$p = Utils.Translations.t("js.schedule.v2.filter.skills_mode.any");

var all$p = Utils.Translations.t("js.schedule.v2.filter.skills_mode.all");

var ModeSwitch = Switch.Switch({});

function Schedule_Header_FilterBySkills$ModeSwitchComponent(Props) {
  var onModeChange = Props.onModeChange;
  var mode = Props.mode;
  var ctx = Props.ctx;
  var states = [
    {
      name: any$p(ctx),
      value: "Any"
    },
    {
      name: all$p(ctx),
      value: "All"
    }
  ];
  return React.createElement(React.Fragment, undefined, React.createElement("label", undefined, skillsModeLabel$p(ctx)), React.createElement(ModeSwitch.make, {
                  onValueChange: onModeChange,
                  value: mode,
                  states: states
                }));
}

function Schedule_Header_FilterBySkills(Props) {
  var filterVariants = Props.filterVariants;
  var param = Props.wire;
  var ctx = param.ctx;
  var filter = Schedule_State.useFilter();
  var setFilterSkillsUuids = Schedule_State.useSetFilterSkillsUuids();
  var setFilterSkillsMode = Schedule_State.useSetFilterSkillsMode();
  return React.createElement(Schedule_Header_FilterComponent.make, {
              filterVariants: filterVariants,
              selectedFilterVariants: filter.skills.uuids,
              setFilter: setFilterSkillsUuids,
              label: skills$p(ctx),
              labelSelected: selectedSkills$p(ctx),
              labelEmpty: emptySkills$p(ctx),
              children: React.createElement(Schedule_Header_FilterBySkills$ModeSwitchComponent, {
                    onModeChange: setFilterSkillsMode,
                    mode: filter.skills.mode,
                    ctx: ctx
                  })
            });
}

var make = Schedule_Header_FilterBySkills;

export {
  make ,
}
/* skills' Not a pure module */
