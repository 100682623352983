import React from 'react'
import { withContext } from 'planado/utils/contextConsumer.jsx'

export const SaveButton = withContext(
  ({ ctx, onSuccess, onFailure, disabled, actions }) => (
    <button
      type="button"
      className="btn btn-primary"
      onClick={_ => actions.sendForm(ctx, onSuccess, onFailure)}
      disabled={disabled}
    >
      {ctx.t('common.save')}
    </button>
  )
)
