

import * as Title from "../common/Title.mjs";
import * as React from "react";
import * as AppContext from "../../context/AppContext.mjs";
import * as DayTimeline from "./views/day_timeline/DayTimeline.mjs";
import * as ScheduleOld from "./old/ScheduleOld.mjs";
import * as Schedule_DND from "./lib/Schedule_DND.mjs";
import * as WeekCalendar from "./views/week_calendar/WeekCalendar.mjs";
import * as WeekTimeline from "./views/week_timeline/WeekTimeline.mjs";
import * as Context_Types from "../../context/Context_Types.mjs";
import * as MonthCalendar from "./views/month_calendar/MonthCalendar.mjs";
import * as Schedule_State from "./lib/state/Schedule_State.mjs";
import * as Schedule_Header from "./components/header/Schedule_Header.mjs";
import * as Schedule_StateContext from "./lib/state/Schedule_StateContext.mjs";
import * as Schedule_Scroll_InitialPosition from "./components/scroll/Schedule_Scroll_InitialPosition.mjs";
import * as ScheduleModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/Schedule.module.css";

var styles = ScheduleModuleCss;

function Schedule$Component(Props) {
  var view = Props.view;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var mode = Schedule_State.useMode();
  var tmp;
  if (view.NAME === "Calendar") {
    tmp = view.VAL === "Week" ? React.createElement(WeekCalendar.make, {}) : React.createElement(MonthCalendar.make, {});
  } else if (view.VAL === "Week") {
    var match = mode.week;
    tmp = match === "Old" ? (
        Context_Types.Features.hasFlag("showOldTimeline", ctx.features) && Context_Types.Features.hasFlag("scheduleZoom", ctx.features) ? React.createElement(ScheduleOld.make, {
                view: view
              }) : React.createElement(WeekTimeline.make, {})
      ) : React.createElement(WeekTimeline.make, {});
  } else {
    var match$1 = mode.day;
    tmp = match$1 === "Old" ? (
        Context_Types.Features.hasFlag("showOldTimeline", ctx.features) ? React.createElement(ScheduleOld.make, {
                view: view
              }) : React.createElement(DayTimeline.make, {})
      ) : React.createElement(DayTimeline.make, {});
  }
  return React.createElement("div", {
              className: styles.container
            }, React.createElement(Title.make, {
                  route: wire.route,
                  ctx: ctx,
                  wire: wire
                }), React.createElement(Schedule_Header.make, {
                  view: view
                }), React.createElement("div", {
                  className: styles.view
                }, tmp));
}

function Schedule$WithContext(Props) {
  var view = Props.view;
  var children = Props.children;
  var stateContext = Schedule_State.useContext(view);
  var initialPositionContext = Schedule_Scroll_InitialPosition.useContext();
  return React.createElement(Schedule_StateContext.Provider.make, Schedule_StateContext.Provider.makeProps(stateContext, React.createElement(Schedule_Scroll_InitialPosition.Provider.make, Schedule_Scroll_InitialPosition.Provider.makeProps(initialPositionContext, children, undefined)), undefined));
}

function Schedule(Props) {
  var view = Props.view;
  var dndContext = React.useMemo((function () {
          return Schedule_DND.DND.Context.make();
        }), []);
  return React.createElement(Schedule_DND.DND.ContextProvider.make, Schedule_DND.DND.ContextProvider.makeProps(dndContext, React.createElement(Schedule$WithContext, {
                      view: view,
                      children: React.createElement(Schedule$Component, {
                            view: view
                          })
                    }), undefined));
}

var make = Schedule;

export {
  make ,
}
/* styles Not a pure module */
