

import Wrapper from "planado/components/wrapper";

var make = Wrapper;

export {
  make ,
}
/* make Not a pure module */
