// extracted by mini-css-extract-plugin
export var confirmTransfer = "confirm-transfer-TnDFX8zfhYNY12yDcFHw";
export var highlighted = "highlighted-YjSt7yjhuxpW8AbSuW2w";
export var modalFooter = "modal-footer-NX9L3ppBcbgJ36NwAjTA";
export var popoverBody = "popover-body-U_Bg0KUtiEICFMkQbP1L";
export var popoverCancelButton = "popover-cancel-button-qHW9ODpyKtILxGRLgPs6";
export var popoverCloseIcon = "popover-close-icon-NehzMpdUZhlgZHmvdfoQ";
export var popoverHeader = "popover-header-VaBz4Pns9MdCVn7zj1mQ";
export var popoverWrapper = "popover-wrapper-NWJEK7Zoowp5EjtCKjm7";
export var tranfer = "tranfer-j9h5MSFk8FevHwqbf0Z0";
export var transfer = "transfer-E37ZDq9YYZxnmElxAQle";