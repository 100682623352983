

import * as Uuid from "../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../libraries/RCore.mjs";
import * as Radio from "../../../../inputs/Radio.mjs";
import * as Utils from "../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../../../../common/Optional.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as JobViewTypes from "../JobViewTypes.mjs";

var modalName$p = Utils.Translations.tr("js.jobs.index.views.save.modal_name");

var remaining$p = Utils.Translations.txr("js.components.limited_input.remaining");

var maxLength$p = Utils.Translations.txr("js.components.limited_input.maxlength");

var error$p = Utils.Translations.tr("js.jobs.index.views.save.error");

var newView$p = Utils.Translations.t("js.jobs.index.views.save.new");

var system$p = Utils.Translations.tr("js.jobs.index.views.save.system");

var save$p = Utils.Translations.tr("js.jobs.index.views.save.save");

function SavePopover(Props) {
  var views = Props.views;
  var createView = Props.createView;
  var saveView = Props.saveView;
  var match = React.useState(function () {
        return "NewView";
      });
  var setSelected = match[1];
  var selected = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setValidate = match$1[1];
  var validate = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setError = match$2[1];
  var hasError = match$2[0];
  var match$3 = React.useState(function () {
        return "";
      });
  var setName = match$3[1];
  var name = match$3[0];
  var match$4 = AppContext.useWire();
  var ctx = match$4.ctx;
  var match$5 = RCore.$$Array.partition(views, (function (v) {
          return v.system;
        }));
  var otherViews = match$5[1];
  var selectedNewView = selected === "NewView";
  var showError = hasError && selectedNewView;
  var onNameChange = function ($$event) {
    var inputName = $$event.target.value;
    if (inputName.length > 0) {
      setName(function (param) {
            return Js_string.slice(0, JobViewTypes.maxViewNameLength, inputName);
          });
      return setError(function (param) {
                  return false;
                });
    } else if (validate) {
      setName(function (param) {
            return inputName;
          });
      return setError(function (param) {
                  return true;
                });
    } else {
      return setName(function (param) {
                  return inputName;
                });
    }
  };
  var onSaveClick = function (param) {
    if (typeof selected !== "object") {
      if (name.length > 0) {
        return createView(name);
      } else {
        setValidate(function (param) {
              return true;
            });
        return setError(function (param) {
                    return true;
                  });
      }
    } else {
      return saveView(selected._0);
    }
  };
  var maxLengthBlock = function () {
    var lengthRemaining = JobViewTypes.maxViewNameLength - name.length | 0;
    if (lengthRemaining <= (JobViewTypes.maxViewNameLength / 10 | 0) && lengthRemaining > 0) {
      return React.createElement("span", {
                  className: "pd-form-control-comment"
                }, remaining$p({
                      count: JobViewTypes.maxViewNameLength,
                      current: name.length
                    }, ctx));
    } else if (lengthRemaining === 0) {
      return React.createElement("span", {
                  className: "pd-form-control-comment"
                }, maxLength$p({
                      count: JobViewTypes.maxViewNameLength
                    }, ctx));
    } else {
      return null;
    }
  };
  var newViewInput = function () {
    var inputClasses = showError ? "JobsViews-save__new-field has-error" : "JobsViews-save__new-field";
    return React.createElement(React.Fragment, undefined, React.createElement("input", {
                    className: inputClasses,
                    autoFocus: true,
                    type: "text",
                    value: name,
                    onChange: onNameChange
                  }), React.createElement(Optional.make, {
                    show: showError,
                    children: React.createElement("div", {
                          className: "pd-control-error"
                        }, error$p(ctx))
                  }), React.createElement(Optional.make, {
                    show: !hasError,
                    children: maxLengthBlock()
                  }));
  };
  return React.createElement("div", {
              className: "JobsViews-save"
            }, React.createElement("header", {
                  className: "JobsViews-save__header"
                }, modalName$p(ctx)), React.createElement("section", {
                  className: "JobsViews-save__new"
                }, React.createElement(Radio.make, {
                      onChange: (function (param) {
                          setSelected(function (param) {
                                return "NewView";
                              });
                        }),
                      checked: selectedNewView,
                      labelText: newView$p(ctx),
                      wrapperClass: "JobsViews-save__radio"
                    }), React.createElement(Optional.make, {
                      show: selectedNewView,
                      children: newViewInput()
                    })), React.createElement(Optional.make, {
                  show: otherViews.length > 0,
                  children: React.createElement("section", {
                        className: "JobsViews-save__content"
                      }, otherViews.map(function (v) {
                            return React.createElement(Radio.make, {
                                        onChange: (function (param) {
                                            setSelected(function (param) {
                                                  return {
                                                          TAG: "Exisintg",
                                                          _0: v.uuid
                                                        };
                                                });
                                          }),
                                        checked: Caml_obj.equal(selected, {
                                              TAG: "Exisintg",
                                              _0: v.uuid
                                            }),
                                        labelText: v.name,
                                        wrapperClass: "JobsViews-save__radio",
                                        key: Uuid.toString(v.uuid)
                                      });
                          }))
                }), React.createElement("section", {
                  className: "JobsViews-save__system"
                }, match$5[0].map(function (v) {
                      return React.createElement("div", {
                                  key: Uuid.toString(v.uuid),
                                  className: "JobsViews-save__system-item"
                                }, React.createElement(Radio.make, {
                                      onChange: (function (param) {
                                          setSelected(function (param) {
                                                return {
                                                        TAG: "Exisintg",
                                                        _0: v.uuid
                                                      };
                                              });
                                        }),
                                      checked: Caml_obj.equal(selected, {
                                            TAG: "Exisintg",
                                            _0: v.uuid
                                          }),
                                      labelText: v.name,
                                      wrapperClass: "JobsViews-save__radio"
                                    }));
                    }), React.createElement("div", {
                      className: "JobsViews-save__system-text"
                    }, system$p(ctx))), React.createElement("footer", {
                  className: "JobsViews-save__footer"
                }, React.createElement("button", {
                      className: "btn btn-32px btn-primary",
                      onClick: onSaveClick
                    }, save$p(ctx))));
}

var make = SavePopover;

export {
  make ,
}
/* modalName' Not a pure module */
