// extracted by mini-css-extract-plugin
export var active = "active-_iqePnmmbGtJQHnGCWI4";
export var compact = "compact-_Y9iL3SRMrkMfeoNq_pv";
export var enRoute = "en-route-oCRJjSho_VxgLtzfg42l";
export var finished = "finished-VDMt0gPgnBF8dPS4Yxgo";
export var finishedOutside = "finished-outside-v5McfZ0Bq5eL8JqzM4oB";
export var icon = "icon-NeLiWIHSDTGopQEyCTHS";
export var job = "job-_SuzS4qqAYLJnTO8E4Le";
export var notDraggable = "not-draggable-KDGU7CIYHwJYgOTS86yS";
export var posted = "posted-kUEcK2vb2npzdXhj3EMI";
export var started = "started-GgxpNN6f0U9sQlYPnipn";
export var startedOutside = "started-outside-G0q7_5xIaiz8DyQzljNO";
export var successful = "successful-jBr3RcpxfBF9zvjJwLzg";
export var suspended = "suspended-W1PIvZtosIpQMlDq8gpN";
export var unsuccessful = "unsuccessful-JwXyNXv1Q5cVS_nB9xrb";