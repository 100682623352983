

import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as AppQrLink from "../../components/common/AppQrLink.mjs";
import * as Tour_Types from "./Tour_Types.mjs";
import * as ToursModuleCss from "/home/runner/work/planado/planado/client/rescript/libraries/tours/Tours.module.css";

var styles = ToursModuleCss;

var greetings$p = Utils.Translations.txh("js.tour.intro.greetings");

var openTemplateList$p = Utils.Translations.th("js.tour.intro.open_template_list");

var selectTemplate$p = Utils.Translations.txh("js.tour.intro.select_template");

var addClient$p = Utils.Translations.th("js.tour.intro.add_client");

var enterClientName$p = Utils.Translations.th("js.tour.intro.enter_client_name");

var addPhoneNumber$p = Utils.Translations.th("js.tour.intro.add_phone_number");

var enterJobDescription$p = Utils.Translations.th("js.tour.intro.enter_job_description");

var pickCurrentDate$p = Utils.Translations.th("js.tour.intro.pick_current_date");

var chooseAssignee$p = Utils.Translations.th("js.tour.intro.choose_the_assignee");

var assignToYourself$p = Utils.Translations.th("js.tour.intro.assign_to_yourself");

var clickAdd$p = Utils.Translations.th("js.tour.intro.click_add");

var seeJobReport$p = Utils.Translations.th("js.tour.intro.see_job_report");

var seeSchedule$p = Utils.Translations.th("js.tour.intro.see_schedule");

var wrapUp$p = Utils.Translations.th("js.tour.intro.wrap_up");

function intro(w) {
  var step = Tour_Types.Step.withSeed(w.seed);
  var ctx = w.ctx;
  var match = w.ctx;
  var match$1 = match.user;
  if (match$1 === undefined) {
    return [];
  }
  var match$2 = match.tourValues;
  if (match$2 === undefined) {
    return [];
  }
  var intro$1 = match$2.intro;
  return [
          step(undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, "null", "next", undefined, undefined, React.createElement("div", {
                    className: styles.regularHint
                  }, greetings$p({
                        name: match$1.name
                      }, ctx)), "greetings", undefined),
          step(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                NAME: "anchor",
                VAL: "job-add-button"
              }, undefined, undefined, undefined, React.createElement("div", {
                    className: styles.regularHint
                  }, openTemplateList$p(ctx)), "openTemplateSelect", undefined),
          step(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                NAME: "anchor",
                VAL: {
                  NAME: "template-variant",
                  VAL: intro$1.templateUuid
                }
              }, undefined, undefined, undefined, React.createElement("div", {
                    className: styles.regularHint
                  }, selectTemplate$p({
                        template: intro$1.templateName
                      }, ctx)), {
                NAME: "selectTemplate",
                VAL: intro$1.templateUuid
              }, undefined),
          step({
                NAME: "jobFormOpenedByTemplate",
                VAL: intro$1.templateUuid
              }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                NAME: "anchor",
                VAL: "job-form-add-client"
              }, undefined, undefined, undefined, React.createElement("div", {
                    className: styles.regularHint
                  }, addClient$p(ctx)), "chooseNewClient", undefined),
          step(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                NAME: "anchor",
                VAL: "job-new-client-name"
              }, "next", undefined, undefined, React.createElement("div", {
                    className: styles.regularHint
                  }, enterClientName$p(ctx)), "enterClientFirstName", undefined),
          step(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                NAME: "anchor",
                VAL: "job-new-client-phone"
              }, "next", undefined, undefined, React.createElement("div", {
                    className: styles.regularHint
                  }, addPhoneNumber$p(ctx)), "enterClientPhoneNumber", undefined),
          step(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                NAME: "anchor",
                VAL: "job-form-description"
              }, "next", undefined, undefined, React.createElement("div", {
                    className: styles.regularHint
                  }, enterJobDescription$p(ctx)), "enterJobDescription", undefined),
          step(undefined, undefined, true, undefined, [".DayPicker"], [{
                  NAME: "css",
                  VAL: ".DayPicker"
                }], undefined, undefined, {
                NAME: "anchor",
                VAL: "job-form-scheduled_at-date"
              }, undefined, undefined, undefined, React.createElement("div", {
                    className: styles.regularHint
                  }, pickCurrentDate$p(ctx)), "pickCurrentDate", undefined),
          step(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                NAME: "anchor",
                VAL: "job-form-assignee-select"
              }, undefined, undefined, undefined, React.createElement("div", {
                    className: styles.regularHint
                  }, chooseAssignee$p(ctx)), "chooseAssignee", undefined),
          step(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                NAME: "anchor",
                VAL: "job-form-assignee-options"
              }, undefined, undefined, undefined, React.createElement("div", {
                    className: styles.regularHint
                  }, assignToYourself$p(ctx)), "assignToYourself", undefined),
          step(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                NAME: "anchor",
                VAL: "job-form-report"
              }, "next", undefined, undefined, React.createElement("div", {
                    className: styles.regularHint
                  }, seeJobReport$p(ctx)), "seeTheFields", undefined),
          step(undefined, undefined, undefined, undefined, undefined, undefined, [{
                  NAME: "anchor",
                  VAL: "job-submit-button"
                }], undefined, {
                NAME: "anchor",
                VAL: "job-submit-button"
              }, undefined, undefined, undefined, React.createElement("div", {
                    className: styles.regularHint
                  }, clickAdd$p(ctx)), "addJob", undefined),
          step(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                NAME: "anchor",
                VAL: "schedule-viewport"
              }, "next", undefined, undefined, React.createElement("div", {
                    className: styles.regularHint
                  }, seeSchedule$p(ctx)), "seeSchedule", undefined),
          step(undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, "null", "finish", undefined, undefined, React.createElement("div", {
                    className: styles.downloadApp
                  }, wrapUp$p(ctx), React.createElement(AppQrLink.make, {
                        className: styles.appDownloadQr
                      })), "downloadApp", undefined)
        ];
}

export {
  intro ,
}
/* styles Not a pure module */
