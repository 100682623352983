

import * as Units from "../../../types/Units.mjs";

var minutesInDay = 1440;

var minutesInWeek = Math.imul(7, minutesInDay);

var stepsInDay = 288;

var sidebarWidth = Units.Px.fromInt(145);

var unscheduledJobsWidth = Units.Px.fromInt(265);

var hiddenUnscheduledJobsWidth = Units.Px.fromInt(35);

var hoursInDay = 24;

var daysInWeek = 7;

var minutesInHour = 60;

var stepsInHour = 12;

var minutesInStep = 5.0;

var dndScheduledJob = "dnd-scheduled-job";

var dndUnscheduledJob = "dnd-unscheduled-job";

var dndUnassignedJob = "dnd-unassigned-job";

export {
  hoursInDay ,
  daysInWeek ,
  minutesInHour ,
  minutesInDay ,
  minutesInWeek ,
  stepsInHour ,
  stepsInDay ,
  minutesInStep ,
  dndScheduledJob ,
  dndUnscheduledJob ,
  dndUnassignedJob ,
  sidebarWidth ,
  unscheduledJobsWidth ,
  hiddenUnscheduledJobsWidth ,
}
/* sidebarWidth Not a pure module */
