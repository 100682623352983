

import * as Fun from "../../../libraries/Fun.mjs";
import * as Hint from "../../common/Hint/Hint.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Panel from "../../common/Panel/Panel.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../inputs/Checkbox.mjs";
import * as Optional from "../../common/Optional.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Template_Field_Settings_ButtonModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/template/Template_Field_Settings_Button.module.css";

var styles = Template_Field_Settings_ButtonModuleCss;

var hidden$p = Utils.Translations.t("js.templates.custom_fields.settings.hidden");

var required$p = Utils.Translations.t("js.templates.custom_fields.settings.required");

var requiredHint$p = Utils.Translations.t("js.templates.custom_fields.settings.required_hint");

var readOnly$p = Utils.Translations.t("js.templates.custom_fields.settings.read_only");

var readOnlyHint$p = Utils.Translations.t("js.templates.custom_fields.settings.read_only_hint");

function Template_Field_Settings_Button$Button(Props) {
  var wire = Props.wire;
  var canBeRequiredOpt = Props.canBeRequired;
  var required = Props.required;
  var toggleRequired = Props.toggleRequired;
  var hidden = Props.hidden;
  var toggleHidden = Props.toggleHidden;
  var readOnly = Props.readOnly;
  var toggleReadOnly = Props.toggleReadOnly;
  var canBeRequired = canBeRequiredOpt !== undefined ? canBeRequiredOpt : true;
  var subscriptions = wire.subscriptions;
  var ctx = wire.ctx;
  var buttonRef = React.useRef(null);
  var buttonContentRef = React.useRef(null);
  var match = React.useState(function () {
        return false;
      });
  var setShowSettings = match[1];
  var showSettings = match[0];
  var showPopover = function ($$event) {
    $$event.preventDefault();
    setShowSettings(function (param) {
          return !showSettings;
        });
  };
  var refToJsObj = function (ref) {
    return RCore.$$Option.getOr(RCore.$$Option.map(Caml_option.nullable_to_opt(ref.current), (function (prim) {
                      return prim;
                    })), {});
  };
  React.useEffect((function () {
          return subscriptions.click.subscribe(function ($$event) {
                      $$event.stopPropagation();
                      var buttonEl = refToJsObj(buttonRef);
                      var buttonContentEl = refToJsObj(buttonContentRef);
                      var eventTarget = $$event.target;
                      if (Caml_obj.equal(eventTarget, buttonEl) || Caml_obj.equal(eventTarget, buttonContentEl)) {
                        return ;
                      } else {
                        return setShowSettings(function (param) {
                                    return false;
                                  });
                      }
                    });
        }), []);
  var buttonUuid = React.useMemo((function () {
          return Uuid.createV4();
        }), []);
  return React.createElement(React.Fragment, undefined, React.createElement("button", {
                  ref: buttonRef,
                  className: "custom-field__button settings " + styles.btnSettings,
                  id: Uuid.toString(buttonUuid),
                  onClick: showPopover
                }, React.createElement("i", {
                      ref: buttonContentRef,
                      className: Icon.style(undefined, undefined, "cog")
                    })), React.createElement(Panel.make, {
                  footer: wire.footer,
                  show: showSettings,
                  domId: Uuid.toString(buttonUuid),
                  position: "bottom",
                  className: styles.dropdown,
                  children: React.createElement(React.Fragment, undefined, React.createElement(Checkbox.make, {
                            onToggle: Fun.omit(toggleHidden),
                            checked: !hidden,
                            label: hidden$p(ctx),
                            wrapperClasses: Js_dict.fromList({
                                  hd: [
                                    styles.checkbox,
                                    true
                                  ],
                                  tl: /* [] */0
                                })
                          }), React.createElement(Optional.make, {
                            show: canBeRequired,
                            children: React.createElement(Checkbox.make, {
                                  onToggle: Fun.omit(toggleRequired),
                                  checked: required,
                                  label: required$p(ctx),
                                  wrapperClasses: Js_dict.fromList({
                                        hd: [
                                          styles.checkbox,
                                          true
                                        ],
                                        tl: /* [] */0
                                      }),
                                  children: React.createElement(Hint.make, {
                                        content: requiredHint$p(ctx),
                                        classes: styles.hint
                                      })
                                })
                          }), React.createElement(Checkbox.make, {
                            onToggle: Fun.omit(toggleReadOnly),
                            checked: readOnly,
                            label: readOnly$p(ctx),
                            wrapperClasses: Js_dict.fromList({
                                  hd: [
                                    styles.checkbox,
                                    true
                                  ],
                                  tl: /* [] */0
                                }),
                            children: React.createElement(Hint.make, {
                                  content: readOnlyHint$p(ctx),
                                  classes: styles.hint
                                })
                          }))
                }));
}

var Button = {
  make: Template_Field_Settings_Button$Button
};

var button = Template_Field_Settings_Button$Button;

export {
  Button ,
  button ,
}
/* styles Not a pure module */
