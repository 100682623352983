

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as Hooks from "../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as UI_Form from "../../ui/UI_Form.mjs";
import * as UI_Icon from "../../ui/UI_Icon.mjs";
import * as UI_Page from "../../ui/UI_Page.mjs";
import * as UserAPI from "../../components/admin/user/UserAPI.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../components/inputs/Checkbox.mjs";
import * as Optional from "../../components/common/Optional.mjs";
import * as UI_Button from "../../ui/UI_Button.mjs";
import * as AppContext from "../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as UI_PageHeader from "../../ui/UI_PageHeader.mjs";
import * as Pages_Audit_API from "./Pages_Audit_API.mjs";
import * as Pages_Audit_Table from "./Pages_Audit_Table.mjs";
import * as Pages_Audit_Types from "./Pages_Audit_Types.mjs";
import * as Pages_AuditModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/audit/Pages_Audit.module.css";

var audit$p = Utils.Translations.tr("js.titles.audit_log.index");

var filter$p = Utils.Translations.t("js.admin.audit_log.filter");

var loadMore$p = Utils.Translations.tr("js.admin.audit_log.load_more");

var loading$p = Utils.Translations.tr("js.admin.audit_log.loading");

var noData$p = Utils.Translations.tr("js.admin.audit_log.no_data");

var actions$p = Utils.Translations.tr("js.admin.audit_log.heading.actions");

var users$p = Utils.Translations.tr("js.admin.audit_log.heading.users");

var reset$p = Utils.Translations.tr("js.admin.audit_log.reset");

function actionValue$p(action) {
  return Utils.Translations.t("js.admin.audit_log.actions." + Pages_Audit_Types.Log.Action.toString(action));
}

var submit$p = Utils.Translations.tr("js.admin.audit_log.submit");

var styles = Pages_AuditModuleCss;

function Pages_Audit$UserCheckbox(Props) {
  var uuid = Props.uuid;
  var name = Props.name;
  var checked = Props.checked;
  var setFilter = Props.setFilter;
  var onToggle = function (_e) {
    setFilter(function (current) {
          if (checked) {
            return {
                    users: RCore.$$Array.keep(current.users, (function (u) {
                            return !Uuid.equal(u, uuid);
                          })),
                    actions: current.actions
                  };
          } else {
            return {
                    users: Belt_Array.concatMany([
                          current.users,
                          [uuid]
                        ]),
                    actions: current.actions
                  };
          }
        });
  };
  return React.createElement(Checkbox.make, {
              onToggle: onToggle,
              checked: checked,
              label: name,
              disabled: false,
              wrapperClasses: Js_dict.fromList({
                    hd: [
                      styles.checkbox,
                      true
                    ],
                    tl: /* [] */0
                  }),
              checkboxClass: Js_dict.fromList({
                    hd: [
                      styles.wordBreak,
                      true
                    ],
                    tl: /* [] */0
                  })
            });
}

function Pages_Audit$ActionCheckbox(Props) {
  var action = Props.action;
  var name = Props.name;
  var checked = Props.checked;
  var setFilter = Props.setFilter;
  var onToggle = function (_e) {
    setFilter(function (current) {
          if (checked) {
            return {
                    users: current.users,
                    actions: RCore.$$Array.keep(current.actions, (function (a) {
                            return a !== action;
                          }))
                  };
          } else {
            return {
                    users: current.users,
                    actions: Belt_Array.concatMany([
                          current.actions,
                          [action]
                        ])
                  };
          }
        });
  };
  return React.createElement(Checkbox.make, {
              onToggle: onToggle,
              checked: checked,
              label: name,
              disabled: false,
              wrapperClasses: Js_dict.fromList({
                    hd: [
                      styles.checkbox,
                      true
                    ],
                    tl: /* [] */0
                  }),
              checkboxClass: Js_dict.fromList({
                    hd: [
                      styles.wordBreak,
                      true
                    ],
                    tl: /* [] */0
                  })
            });
}

function Pages_Audit$Filters(Props) {
  var users = Props.users;
  var searchParams = Props.searchParams;
  var setSearchParams = Props.setSearchParams;
  var setShowFilter = Props.setShowFilter;
  var setLogs = Props.setLogs;
  var ctx = Props.ctx;
  var match = React.useState(function () {
        return searchParams.filter;
      });
  var setFilter = match[1];
  var filter = match[0];
  var filterChanged = Caml_obj.notequal(filter.users, searchParams.filter.users) || Caml_obj.notequal(filter.actions, searchParams.filter.actions);
  var onSubmit = function ($$event) {
    $$event.preventDefault();
    if (filterChanged) {
      setLogs(function (param) {
            return [];
          });
      setSearchParams(function (param) {
            return {
                    after: undefined,
                    filter: filter
                  };
          });
    } else {
      setSearchParams(function (current) {
            return {
                    after: current.after,
                    filter: filter
                  };
          });
    }
    setShowFilter(function (param) {
          return false;
        });
  };
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: styles.filtersWrapper
                }, React.createElement(UI_Form.Group.make, {
                      width: "half",
                      children: null
                    }, React.createElement("div", {
                          className: styles.sectionWrapper
                        }, React.createElement("div", {
                              className: styles.sectionTitle
                            }, users$p(ctx)), React.createElement(Optional.make, {
                              show: Caml_obj.notequal(filter.users, []),
                              children: React.createElement("div", {
                                    className: styles.reset
                                  }, " (" + filter.users.length.toString() + ",", React.createElement(UI_Button.make, {
                                        flavor: "link",
                                        className: styles.resetLink,
                                        onClick: (function (param) {
                                            setFilter(function (param) {
                                                  return {
                                                          users: [],
                                                          actions: filter.actions
                                                        };
                                                });
                                          }),
                                        children: reset$p(ctx)
                                      }), ")")
                            })), React.createElement("div", {
                          className: styles.dropdownList
                        }, users.map(function (user) {
                              return React.createElement(Pages_Audit$UserCheckbox, {
                                          uuid: user.uuid,
                                          name: user.firstName + " " + user.lastName,
                                          checked: filter.users.some(function (s) {
                                                return Uuid.equal(s, user.uuid);
                                              }),
                                          setFilter: setFilter,
                                          key: Uuid.toString(user.uuid)
                                        });
                            }))), React.createElement(UI_Form.Group.make, {
                      width: "half",
                      children: null
                    }, React.createElement("div", {
                          className: styles.sectionWrapper
                        }, React.createElement("div", {
                              className: styles.sectionTitle
                            }, actions$p(ctx)), React.createElement(Optional.make, {
                              show: Caml_obj.notequal(filter.actions, []),
                              children: React.createElement("div", {
                                    className: styles.reset
                                  }, " (" + filter.actions.length.toString() + ",", React.createElement(UI_Button.make, {
                                        flavor: "link",
                                        className: styles.resetLink,
                                        onClick: (function (param) {
                                            setFilter(function (param) {
                                                  return {
                                                          users: filter.users,
                                                          actions: []
                                                        };
                                                });
                                          }),
                                        children: reset$p(ctx)
                                      }), ")")
                            })), React.createElement("div", {
                          className: styles.dropdownList
                        }, Pages_Audit_Types.Log.Action.all.map(function (action) {
                              return React.createElement(Pages_Audit$ActionCheckbox, {
                                          action: action,
                                          name: actionValue$p(action)(ctx),
                                          checked: filter.actions.some(function (a) {
                                                return action === a;
                                              }),
                                          setFilter: setFilter,
                                          key: Pages_Audit_Types.Log.Action.toString(action)
                                        });
                            })))), React.createElement(Optional.make, {
                  show: filterChanged,
                  children: React.createElement(UI_Button.make, {
                        purpose: "primary",
                        className: styles.submitFiltersButton,
                        onClick: onSubmit,
                        children: submit$p(ctx)
                      })
                }));
}

function Pages_Audit(Props) {
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return [];
      });
  var setLogs = match[1];
  var logs = match[0];
  var match$1 = React.useState(function () {
        return 0;
      });
  var setLastLoaded = match$1[1];
  var match$2 = React.useState(function () {
        return [];
      });
  var setUsers = match$2[1];
  var match$3 = React.useState(function () {
        return false;
      });
  var setShowFilter = match$3[1];
  var showFilter = match$3[0];
  var match$4 = React.useState(function () {
        return true;
      });
  var setLoading = match$4[1];
  var match$5 = React.useState(function () {
        return {
                after: undefined,
                filter: {
                  users: [],
                  actions: []
                }
              };
      });
  var setSearchParams = match$5[1];
  var searchParams = match$5[0];
  var filterButtonId = Hooks.useId(undefined);
  React.useEffect((function () {
          setLoading(function (param) {
                return true;
              });
          Backend.$$finally(Pages_Audit_API.Index.getViaPost(wire, searchParams), (function (result) {
                  if (result.TAG === "Ok") {
                    var logs = result._0.logs;
                    setLastLoaded(function (param) {
                          return logs.length;
                        });
                    setLogs(function (current) {
                          return Belt_Array.concatMany([
                                      current,
                                      logs
                                    ]);
                        });
                  } else {
                    console.log(result._0);
                  }
                  setLoading(function (param) {
                        return false;
                      });
                }));
        }), [searchParams]);
  React.useEffect((function () {
          Backend.$$finally(UserAPI.Index.index(wire), (function (result) {
                  if (result.TAG === "Ok") {
                    var users = result._0.users;
                    return setUsers(function (param) {
                                return users;
                              });
                  }
                  console.log(result._0);
                }));
        }), []);
  var popover = React.useRef(null);
  var closeFilters = function () {
    if (showFilter) {
      return setShowFilter(function (param) {
                  return false;
                });
    }
    
  };
  Hooks.useOnClickOutside(undefined, popover, closeFilters);
  return React.createElement(UI_Page.make, {
              children: null
            }, React.createElement(UI_PageHeader.make, {
                  wrapperClassName: styles.headerWrapper,
                  headerClassName: styles.header,
                  children: null
                }, React.createElement("div", undefined, audit$p(ctx)), React.createElement("div", {
                      className: styles.filterButtonContainer
                    }, React.createElement(UI_Button.make, {
                          flavor: "outline",
                          id: filterButtonId,
                          className: styles.filterButton,
                          onClick: (function (param) {
                              setShowFilter(function (current) {
                                    return !current;
                                  });
                            }),
                          children: null
                        }, React.createElement(UI_Icon.make, {
                              className: styles.filterIcon,
                              icon: "filter"
                            }), filter$p(ctx) + (
                          Caml_obj.equal(searchParams.filter.users, []) && Caml_obj.equal(searchParams.filter.actions, []) ? "" : " (" + (searchParams.filter.users.length + searchParams.filter.actions.length | 0).toString() + ")"
                        ), React.createElement(UI_Icon.make, {
                              className: styles.caretIcon,
                              icon: showFilter ? "caret-up" : "caret-down"
                            })), React.createElement(Optional.make, {
                          show: showFilter,
                          children: React.createElement("div", {
                                ref: popover,
                                className: styles.filterPopover
                              }, React.createElement(Pages_Audit$Filters, {
                                    users: match$2[0],
                                    searchParams: searchParams,
                                    setSearchParams: setSearchParams,
                                    setShowFilter: setShowFilter,
                                    setLogs: setLogs,
                                    ctx: ctx
                                  }))
                        }))), React.createElement("div", {
                  className: styles.wrapper
                }, Caml_obj.equal(logs, []) && match$4[0] ? React.createElement("div", {
                        className: styles.loadingText
                      }, loading$p(ctx)) : (
                    Caml_obj.equal(logs, []) ? React.createElement("div", {
                            className: styles.noDataText
                          }, noData$p(ctx)) : React.createElement(React.Fragment, undefined, React.createElement(Pages_Audit_Table.make, {
                                logs: logs
                              }), React.createElement(Optional.make, {
                                show: match$1[0] === 50,
                                children: React.createElement(UI_Button.make, {
                                      flavor: "outline",
                                      className: styles.loadMoreButton,
                                      onClick: (function (param) {
                                          setSearchParams(function (current) {
                                                return {
                                                        after: RCore.$$Option.map(logs[logs.length - 1 | 0], (function (l) {
                                                                return l.uuid;
                                                              })),
                                                        filter: current.filter
                                                      };
                                              });
                                        }),
                                      children: loadMore$p(ctx)
                                    })
                              }))
                  )));
}

var make = Pages_Audit;

export {
  make ,
}
/* audit' Not a pure module */
