

import * as Hooks from "../../libraries/hooks/Hooks.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as AppContext from "../../context/AppContext.mjs";
import * as MessengerTypes from "../../components/messenger/MessengerTypes.mjs";
import * as Pages_Skills_API from "./Pages_Skills_API.mjs";
import * as Pages_Skills_Form from "./form/Pages_Skills_Form.mjs";

var skillsUpdated$p = Utils.Translations.t("js.admin.skills.messages.skills_updated");

var unprocessableEntity$p = Utils.Translations.t("js.admin.skills.messages.unprocessable_entity");

var serverError$p = Utils.Translations.t("js.admin.skills.messages.server_error");

function Pages_Skills(Props) {
  var wire = AppContext.useWire();
  var messenger = wire.subscriptions.messenger;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        
      });
  var setSkills = match[1];
  var skills = match[0];
  var match$1 = Hooks.useLocked(false);
  var unlock = match$1[2];
  var lock = match$1[1];
  var showMessage = function (messageType, text) {
    messenger.trigger({
          TAG: "Show",
          _0: MessengerTypes.Message.make(undefined, messageType, {
                TAG: "Text",
                _0: text
              })
        });
  };
  var showError = function (error) {
    var text;
    if (typeof error !== "object" || error.TAG !== "HTTPError") {
      text = serverError$p(ctx);
    } else {
      var tmp = error._0[0];
      text = typeof tmp !== "object" && tmp === "UnprocessableEntity" ? unprocessableEntity$p(ctx) : serverError$p(ctx);
    }
    showMessage("Danger", text);
  };
  React.useEffect((function () {
          Backend.$$finally(Pages_Skills_API.Get.get(wire), (function (skills) {
                  if (skills.TAG === "Ok") {
                    var skills$1 = skills._0;
                    setSkills(function (param) {
                          return skills$1;
                        });
                  } else {
                    showError(skills._0);
                  }
                  unlock();
                }));
        }), []);
  var submit = function (skills) {
    lock();
    Backend.$$finally(Pages_Skills_API.Patch.patch(wire, skills), (function (skills) {
            if (skills.TAG === "Ok") {
              var skills$1 = skills._0;
              setSkills(function (param) {
                    return skills$1;
                  });
              showMessage("Success", skillsUpdated$p(ctx));
              wire.reloadContext();
            } else {
              showError(skills._0);
            }
            unlock();
          }));
  };
  if (skills !== undefined) {
    return React.createElement(Pages_Skills_Form.make, {
                initialSkills: skills,
                onSubmit: submit,
                locked: match$1[0],
                lock: lock,
                unlock: unlock
              });
  } else {
    return null;
  }
}

var make = Pages_Skills;

export {
  make ,
}
/* skillsUpdated' Not a pure module */
