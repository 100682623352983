import humps from 'humps'
import { sendRequest } from 'planado/utils/network.js'
import { analyticsPath } from 'planado/routes.js'
import underscore from 'underscore'

export const isUuid = uuid =>
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(uuid)

export const camelize = object => humps.camelizeKeys(object)

export const decamelize = (object, options = {}) =>
  humps.decamelizeKeys(object, options)

export const camelizeString = humps.camelize

export const decamelizeString = humps.decamelize

let isId = key => isUuid(key) || /^[0-9-]+$/.test(key)

export const camelizeKeysWithId = object =>
  humps.camelizeKeys(object, (key, convert) => (isId(key) ? key : convert(key)))

export function loadScript(url) {
  return new Promise((resolve, _) => {
    const script = document.createElement('script')
    script.src = url
    script.onload = resolve
    document.getElementsByTagName('head')[0].appendChild(script)
  })
}

export const limit = (s, l) => (s.length > l ? s.substring(0, l) + '...' : s)

export const listToTree = (list, id = el => el.uuid) =>
  list.reduce((acc, el) => Object.assign(acc, { [id(el)]: el }), {})

export const jQuery = window.jQuery

export const ajax = window.jQuery.ajax

export const Deferred = window.jQuery.Deferred

export const capitalize = str => `${str.charAt(0).toUpperCase()}${str.slice(1)}`

export const escape = underscore.escape

export const replaceSpaces = str => str.replace(/ /g, '\u00a0')

export const stringLengthWithNewLines = str => {
  let newLines = str.match(/(\r\n|\n|\r)/g)

  if (newLines) {
    return str.length + newLines.length
  } else {
    return str.length
  }
}

export const areEqual = underscore.isEqual

export const isEmpty = underscore.isEmpty

export const debounce = (func, wait, immediate) => {
  let timeout
  return function (...args) {
    const context = this

    const later = () => {
      timeout = null

      if (!immediate) {
        func.apply(context, args)
      }
    }

    const callNow = immediate && !timeout

    if (timeout) {
      clearTimeout(timeout)
    }

    timeout = setTimeout(later, wait)

    if (callNow) {
      func.apply(context, args)
    }
  }
}

/**
 * Courtesy of https://github.com/bjoerge/debounce-promise
 */

const defer = () => {
  const deferred = {}
  deferred.promise = new Promise((resolve, reject) => {
    deferred.resolve = resolve
    deferred.reject = reject
  })
  return deferred
}

export const debouncePromise = (fn, wait = 0) => {
  let deferred
  let timer
  let pendingArgs = []
  return function debounced(...args) {
    if (deferred) {
      clearTimeout(timer)
    } else {
      deferred = defer()
    }
    pendingArgs.push(args)
    timer = setTimeout(flush.bind(this), wait)
    return deferred.promise
  }
  function flush() {
    const thisDeferred = deferred
    clearTimeout(timer)
    fn.apply(this, pendingArgs[pendingArgs.length - 1]).then(
      res => thisDeferred.resolve(res),
      err => thisDeferred.reject(err)
    )
    pendingArgs = []
    deferred = null
  }
}

export const async = (clbk, delay = null) => setTimeout(clbk, delay ? delay : 0)

export const logEvent = (name, ctx) => {
  const wireCtx = ctx.wire.ctx
  sendRequest(analyticsPath(name), wireCtx)
}

export const onResize = cb => this.window.addEventListener('resize', cb, false)

export const splitViews = views => {
  const viewsButtons = views.map(
    v => `<button class="JobsViews-list__item">${v.name}</button>`
  )

  const div = document.createElement('div')
  div.innerHTML = `<div class='JobsViews js-views'><div class='JobsViews-list js-views-list'>${viewsButtons.join(
    ''
  )}</div></div>`
  document.querySelector('body').appendChild(div)

  const menuItemsList = document.querySelector('.js-views-list').childNodes
  const menuItems = Array.prototype.slice.call(menuItemsList)
  let menuItemsWidths = []
  menuItems.forEach(item => {
    menuItemsWidths = [...menuItemsWidths, item.clientWidth]
  })

  div.remove()

  const widthConstraint = Math.max(650, window.innerWidth - 350)

  const separator = menuItemsWidths.reduce(
    (acc, cur, i) =>
      acc.w + cur < widthConstraint && i == acc.c
        ? { w: (acc.w += cur), c: (acc.c += 1) }
        : acc,
    { w: 0, c: 0 }
  ).c

  return {
    shownViews: views.slice(0, separator),
    hiddenViews: views.slice(separator, views.length)
  }
}
