


function bind(param) {
  var onScroll = param.scroll.trigger;
  var onResize = param.resize.trigger;
  var onKeyDown = param.keydown.trigger;
  var onMouseMove = param.mouseMove.trigger;
  var documentTarget = document.documentElement;
  documentTarget.addEventListener("keydown", onKeyDown);
  documentTarget.addEventListener("mousemove", onMouseMove);
  window.document.addEventListener("scroll", onScroll, false);
  window.addEventListener("resize", onResize, false);
  return function () {
    documentTarget.removeEventListener("keydown", onKeyDown);
    documentTarget.removeEventListener("mousemove", onMouseMove);
    window.document.removeEventListener("scroll", onScroll, false);
    window.removeEventListener("resize", onResize, false);
  };
}

export {
  bind ,
}
/* No side effect */
