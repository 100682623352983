

import * as El from "../../../libraries/El.mjs";
import * as Hooks from "../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as AppContext from "../../../context/AppContext.mjs";
import * as MultipleAssigneesModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/MultipleAssignees/MultipleAssignees.module.css";

var inputPlaceholder$p = Utils.Translations.t("js.components.assignee_select.placeholder");

var styles = MultipleAssigneesModuleCss;

function fromString(key) {
  switch (key) {
    case "ArrowDown" :
        return "Down";
    case "ArrowUp" :
        return "Up";
    case "Enter" :
        return "Submit";
    default:
      return ;
  }
}

function MultipleAssignees_Input(Props) {
  var inputId = Props.inputId;
  var value = Props.value;
  var setValue = Props.setValue;
  var setFocused = Props.setFocused;
  var autoFocus = Props.autoFocus;
  var disabled = Props.disabled;
  var onNavigation = Props.onNavigation;
  var inputId$1 = Hooks.useId(inputId);
  var match = AppContext.useWire();
  var onChange = function (evt) {
    evt.preventDefault();
    var value = evt.target.value;
    setValue(function (param) {
          return value;
        });
  };
  var onKeyDown = function (evt) {
    var action = fromString(evt.key);
    if (action !== undefined) {
      return onNavigation(action);
    }
    
  };
  return React.createElement("input", {
              className: El.Cn.concat(styles.input, "form-control"),
              id: inputId$1,
              autoFocus: autoFocus,
              disabled: disabled,
              placeholder: inputPlaceholder$p(match.ctx),
              value: RCore.$$Option.getOr(value, ""),
              onKeyDown: onKeyDown,
              onFocus: (function (param) {
                  setFocused(function (param) {
                        return true;
                      });
                }),
              onChange: onChange
            });
}

var KeyboardAction = {};

var make = MultipleAssignees_Input;

export {
  KeyboardAction ,
  make ,
}
/* inputPlaceholder' Not a pure module */
