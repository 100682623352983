

import * as React from "react";
import * as Utils_IsImage from "../../../../../utils/Utils_IsImage.mjs";
import * as FileInput_Preview_File from "./FileInput_Preview_File.mjs";
import * as FileInput_Preview_Image from "./FileInput_Preview_Image.mjs";
import * as FileInput_Preview_NotUploaded from "./FileInput_Preview_NotUploaded.mjs";

function FileInput_Preview(Props) {
  var file = Props.file;
  var removeFile = Props.removeFile;
  var showImage = Props.showImage;
  var readOnly = Props.readOnly;
  var removeFile$1 = function ($$event) {
    $$event.preventDefault();
    $$event.stopPropagation();
    removeFile(file.uuid);
  };
  var url = file.url;
  if (url !== undefined) {
    if (Utils_IsImage.isImage(url)) {
      return React.createElement(FileInput_Preview_Image.make, {
                  image: file,
                  url: url,
                  removeFile: removeFile$1,
                  showImage: showImage,
                  readOnly: readOnly
                });
    } else {
      return React.createElement(FileInput_Preview_File.make, {
                  url: url,
                  file: file,
                  removeFile: removeFile$1,
                  readOnly: readOnly
                });
    }
  } else {
    return React.createElement(FileInput_Preview_NotUploaded.make, {
                name: file.name,
                readOnly: readOnly,
                removeFile: removeFile$1
              });
  }
}

var make = FileInput_Preview;

export {
  make ,
}
/* react Not a pure module */
