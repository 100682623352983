

import * as React from "react";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as Runtime from "../../../Runtime.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LimitedInput from "../../inputs/LimitedInput.mjs";
import * as Context_Types from "../../../context/Context_Types.mjs";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as AddressInput_Types from "../AddressInput_Types.mjs";
import * as AddressInput_Common from "../AddressInput_Common.mjs";
import * as StringGeolocationParser from "../../../types/StringGeolocationParser.mjs";
import * as AddressInput_SuggestionsBlock from "../suggestions_block/AddressInput_SuggestionsBlock.mjs";

function createWrapperClassName(status) {
  return "pd-form-group " + AddressInput_Common.styles.addressFormatted + (
          status !== undefined ? " geolocation-" + AddressInput_Types.ResolveStatus.toString(status) : ""
        );
}

function getStatus(address, queryAddress, queryChangedManually) {
  if (queryAddress !== undefined && AddressInput_SuggestionsBlock.inputLongerThanMinimum(queryAddress.formatted)) {
    if (address.geolocationResolved && !queryChangedManually) {
      return "Resolved";
    } else {
      return "Failed";
    }
  }
  
}

function keyDown(update, setQueryManualUpdateStatus, setFocused, focused, requestSuggestions, inputRef) {
  return function (suggestions, resolvedQueryAddress) {
    return function (param) {
      var $$event = param[0];
      var lastSuggestion = suggestions.length - 1 | 0;
      var updateInputFromSuggestion = function (n) {
        var suggestion = suggestions[n];
        var match = suggestion !== undefined ? [
            AddressInput_Types.RegularAddress.makeFromResponseItem(suggestion),
            n
          ] : [
            AddressInput_Types.RegularAddress.makeFromString(resolvedQueryAddress.formatted),
            0
          ];
        update(match[0]);
        return {
                TAG: "Suggestion",
                _0: match[1]
              };
      };
      if (suggestions.length <= 0) {
        return ;
      }
      var match = $$event.code;
      switch (match) {
        case "ArrowDown" :
            $$event.preventDefault();
            setQueryManualUpdateStatus(true);
            return setFocused(function (current) {
                        if (current === undefined) {
                          return ;
                        }
                        if (typeof current !== "object") {
                          return updateInputFromSuggestion(0);
                        }
                        var n = current._0;
                        if (n === lastSuggestion) {
                          update(resolvedQueryAddress);
                          return "Input";
                        } else {
                          return updateInputFromSuggestion(n + 1 | 0);
                        }
                      });
        case "ArrowUp" :
            $$event.preventDefault();
            setQueryManualUpdateStatus(true);
            return setFocused(function (current) {
                        if (current === undefined) {
                          return ;
                        }
                        if (typeof current !== "object") {
                          return updateInputFromSuggestion(lastSuggestion);
                        }
                        var n = current._0;
                        if (n !== 0) {
                          return updateInputFromSuggestion(n - 1 | 0);
                        } else {
                          update(resolvedQueryAddress);
                          return "Input";
                        }
                      });
        case "Enter" :
            $$event.preventDefault();
            if (focused === undefined) {
              return ;
            }
            if (typeof focused !== "object") {
              return ;
            }
            var chosenAddress = AddressInput_Types.RegularAddress.makeFromResponseItem(suggestions[focused._0]);
            update(chosenAddress);
            requestSuggestions(chosenAddress.formatted);
            setQueryManualUpdateStatus(false);
            setFocused(function (param) {
                  
                });
            var ref = inputRef.current;
            if (!(ref == null)) {
              return ref.blur();
            } else {
              return ;
            }
        default:
          return param[1]($$event);
      }
    };
  };
}

function AddressInput_RegularAddress(Props) {
  var wire = Props.wire;
  var readOnly = Props.readOnly;
  var constraints = Props.constraints;
  var initialAddress = Props.initialAddress;
  var onChange = Props.onChange;
  var suggestionsAmount = Props.suggestionsAmount;
  var match = React.useState(function () {
        return AddressInput_Types.RegularAddress.makeFromJs(initialAddress);
      });
  var setAddress = match[1];
  var address = match[0];
  var match$1 = React.useState(function () {
        return address;
      });
  var setQueryAddress = match$1[1];
  var queryAddress = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setFocused = match$2[1];
  var focused = match$2[0];
  var match$3 = React.useState(function () {
        
      });
  var setResponse = match$3[1];
  var response = match$3[0];
  var match$4 = React.useState(function () {
        
      });
  var setClosestPoint = match$4[1];
  var match$5 = React.useState(function () {
        return false;
      });
  var setQueryManualUpdateStatus = match$5[1];
  var queryChangedManually = match$5[0];
  var setQueryManualUpdateStatus$1 = function (value) {
    setQueryManualUpdateStatus(function (param) {
          return value;
        });
  };
  var inputId = React.useMemo((function () {
          return "form-input-formatted-" + Js_math.random_int(0, 100000).toString();
        }), []);
  var inputRef = React.useRef(null);
  var status = getStatus(address, queryAddress, queryChangedManually);
  var match$6 = constraints !== undefined ? [
      constraints.locations,
      constraints.formatted
    ] : [
      AddressInput_Types.AddressConstraintParts.empty,
      ""
    ];
  var baseAddressFormatted = match$6[1];
  var baseAddressParts = match$6[0];
  var updateAddress = function (unconstrainedAddress) {
    var address = baseAddressFormatted === "" ? unconstrainedAddress : AddressInput_Types.RegularAddress.applyConstraints(unconstrainedAddress, baseAddressFormatted);
    setAddress(function (param) {
          return address;
        });
    onChange(AddressInput_Types.RegularAddress.makeJs(address));
  };
  var updateAddressFromPopup = function (jsAddress) {
    setAddress(function (param) {
          return AddressInput_Types.RegularAddress.makeFromJs(jsAddress);
        });
    onChange(jsAddress);
    setQueryManualUpdateStatus(function (param) {
          return false;
        });
  };
  var requestSuggestions = function (query) {
    AddressInput_Common.requestSuggestions(undefined, undefined, baseAddressParts, suggestionsAmount, wire.ctx.dadataToken, query, (function (r) {
            wire.usedResources.track("dadataRequest");
            setResponse(function (param) {
                  return r.suggestions;
                });
          }), undefined);
  };
  var onInputChange = function (value) {
    var address = AddressInput_Types.RegularAddress.makeFromString(value);
    if (AddressInput_Common.needToDisplayOrRequest(value)) {
      requestSuggestions(value);
    }
    setQueryManualUpdateStatus(function (param) {
          return true;
        });
    setQueryAddress(function (param) {
          return address;
        });
    updateAddress(address);
  };
  var onFocus = function (_evt) {
    setFocused(function (param) {
          return "Input";
        });
  };
  var onSelect = function (item) {
    return function (_evt) {
      var address = AddressInput_Types.RegularAddress.makeFromResponseItem(item);
      requestSuggestions(address.formatted);
      setQueryManualUpdateStatus(function (param) {
            return false;
          });
      setFocused(function (param) {
            
          });
      updateAddress(address);
    };
  };
  var onBlur = function (_evt) {
    Runtime.Timeout.defer(200, (function () {
            setFocused(function (param) {
                  
                });
          }));
  };
  var onReset = function (_evt) {
    var emptyAddress = AddressInput_Types.RegularAddress.makeFromString("");
    setQueryAddress(function (param) {
          return emptyAddress;
        });
    setQueryManualUpdateStatus(function (param) {
          return false;
        });
    setFocused(function (param) {
          
        });
    updateAddress(emptyAddress);
  };
  var isUndefined = function (input) {
    return Js_null_undefined.fromOption(Caml_option.some(input)) === undefined;
  };
  var keyDown$1 = keyDown(updateAddress, setQueryManualUpdateStatus$1, setFocused, focused, requestSuggestions, inputRef);
  React.useEffect((function () {
          var loadedAddress = AddressInput_Types.RegularAddress.makeFromJs(initialAddress);
          var updatedAddress = StringGeolocationParser.isGeolocationString(loadedAddress.formatted) ? AddressInput_Types.RegularAddress.makeFromString(loadedAddress.formatted) : loadedAddress;
          setAddress(function (param) {
                return updatedAddress;
              });
          setQueryAddress(function (param) {
                return updatedAddress;
              });
        }), [initialAddress]);
  React.useEffect((function () {
          var geolocation = address.geolocation;
          if (geolocation === undefined) {
            return ;
          }
          var tzGeolocation = wire.ctx.localizator.approximateGeolocation;
          if (Caml_obj.equal(geolocation.latitude, tzGeolocation.latitude) && Caml_obj.equal(geolocation.longitude, tzGeolocation.longitude) || isUndefined(geolocation.latitude) || isUndefined(geolocation.longitude)) {
            return ;
          } else {
            setClosestPoint(function (param) {
                  return geolocation;
                });
            return ;
          }
        }), [address]);
  React.useEffect((function () {
          if (focused !== undefined && response !== undefined && queryAddress !== undefined) {
            return wire.subscriptions.keydown.subscribe(keyDown$1(response, queryAddress));
          }
          
        }), [
        focused,
        response,
        queryAddress
      ]);
  return React.createElement("div", {
              className: createWrapperClassName(status)
            }, React.createElement("label", {
                  className: "pd-label",
                  htmlFor: inputId
                }, AddressInput_Common.Translations.addressLabel$p(wire.ctx)), React.createElement(AddressInput_Common.LocationCoordinates.make, {
                  wire: wire,
                  mapType: Context_Types.MapType.toString(wire.ctx.mapType),
                  mapApiKey: wire.ctx.mapApiKey,
                  readOnly: readOnly,
                  closestPoint: match$4[0],
                  queryChangedManually: queryChangedManually,
                  address: address,
                  queryAddress: queryAddress,
                  saveLocation: updateAddressFromPopup,
                  response: response
                }), React.createElement("div", {
                  className: AddressInput_Common.styles.pdComplexFormControlContainer
                }, React.createElement(LimitedInput.make, {
                      maxLength: 200,
                      onValueChange: onInputChange,
                      value: address.formatted,
                      id: inputId,
                      onFocus: onFocus,
                      onBlur: onBlur,
                      readOnly: readOnly,
                      type_: "text",
                      className: "pd-form-control pd-form-control-with-icon",
                      ref: inputRef
                    }), React.createElement(AddressInput_SuggestionsBlock.SuggestionsBlock.make, {
                      wire: wire,
                      address: {
                        TAG: "Regular",
                        _0: address
                      },
                      response: response,
                      focused: focused,
                      constraints: constraints,
                      onSelect: onSelect
                    }), React.createElement(AddressInput_Common.ResetAndMarkerIcons.make, {
                      queryAddress: queryAddress,
                      onReset: onReset,
                      status: status,
                      wire: wire,
                      constraints: constraints,
                      readOnly: readOnly
                    })));
}

var make = AddressInput_RegularAddress;

var $$default = AddressInput_RegularAddress;

export {
  make ,
  $$default as default,
}
/* react Not a pure module */
