

import * as RCore from "./RCore.mjs";
import * as $$Number from "./Number.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Shared_Lib_String from "../shared/lib/Shared_Lib_String.mjs";

var toString = $$Number.Decimal.toString;

var toFixed = $$Number.Decimal.toFixed;

var make = $$Number.Decimal.make;

var CurrencyValue = {
  Decimal: undefined,
  toString: toString,
  toFixed: toFixed,
  make: make
};

function isCurrencyString(str) {
  var regex = /^-?(0|[1-9][0-9]{0,13})(\.\d{1,2})?$/;
  return regex.test(str);
}

var ParsedInput = {};

var ParsingError = {};

function parse(value, useCurrencyFractionalUnit, ctx, removeFormatting) {
  var trimmedValue = $$Number.Utils.removeSpacesAndLetters(value);
  if (trimmedValue.length === 0) {
    return {
            TAG: "Error",
            _0: "EmptyString"
          };
  }
  var refinedValue;
  if (removeFormatting) {
    var delimiter = ctx.localizator.numbers.delimiter;
    var separator = ctx.localizator.numbers.separator;
    refinedValue = (function (__x) {
          return $$Number.Utils.replace(separator, ".", __x);
        })($$Number.Utils.removeDelimiters(delimiter, trimmedValue));
  } else {
    refinedValue = trimmedValue;
  }
  var mbDecimal = make(refinedValue);
  if (mbDecimal === undefined) {
    return {
            TAG: "Error",
            _0: "ParseNumberStringError"
          };
  }
  var decimalValue = Caml_option.valFromOption(mbDecimal);
  var hasCents = $$Number.Utils.hasFractionalPart(refinedValue, ".");
  var decimalString = toString(decimalValue);
  var hasValidFormat = isCurrencyString(decimalString);
  if (!hasValidFormat) {
    return {
            TAG: "Error",
            _0: "InvalidFormat"
          };
  }
  var report_decimalStringFixed = toFixed(decimalValue, 2);
  var report_integerPart = toFixed(decimalValue, 0);
  var report = {
    refinedValue: refinedValue,
    decimalValue: decimalValue,
    decimalString: decimalString,
    decimalStringFixed: report_decimalStringFixed,
    integerPart: report_integerPart
  };
  if (hasCents && !useCurrencyFractionalUnit) {
    return {
            TAG: "Error",
            _0: {
              TAG: "MustBeWithoutCents",
              _0: report
            }
          };
  } else {
    return {
            TAG: "Ok",
            _0: report
          };
  }
}

function format(delimiter, separator, $staropt$star, formatOnType, useCurrencyFractionalUnit, param) {
  var refinedValue = param.refinedValue;
  var startGroupingFrom = $staropt$star !== undefined ? $staropt$star : 5;
  var decimalString = formatOnType ? param.decimalString : param.decimalStringFixed;
  var minusSign = Js_string.charAt(0, decimalString) === "-" ? "-" : "";
  var withoutMinus = Shared_Lib_String.replaceFirst(decimalString, "-", "");
  var numberParts = Js_string.split(".", withoutMinus);
  var formattedInt = $$Number.Utils.addThousandsSeparator(numberParts[0], startGroupingFrom, delimiter);
  var formattedInt$1 = minusSign + formattedInt;
  var formattedFull;
  if (useCurrencyFractionalUnit) {
    var fractionalPart = numberParts[1];
    formattedFull = fractionalPart !== undefined ? formattedInt$1 + separator + fractionalPart : formattedInt$1;
  } else {
    formattedFull = formattedInt$1;
  }
  if (!(formatOnType && useCurrencyFractionalUnit)) {
    return formattedFull;
  }
  var refinedValueHasSeparator = refinedValue.split("").some(function (v) {
        return v === ".";
      });
  if (!refinedValueHasSeparator) {
    return formattedInt$1;
  }
  var fractionalPart$1 = RCore.$$Option.getOr(refinedValue.split(".")[1], "");
  return formattedInt$1 + separator + Js_string.slice(0, 2, fractionalPart$1);
}

export {
  CurrencyValue ,
  ParsedInput ,
  ParsingError ,
  parse ,
  format ,
}
/* Number Not a pure module */
