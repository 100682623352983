import React, { Component } from 'react'
import Throttler from 'planado/schedule/utils/throttler'
import { DraggableSpansManager } from 'planado/schedule/utils/managers'
import classNames from 'classnames'
import jQuery from 'jquery'
import { ChartBusinessHoursLink } from 'planado/schedule/containers'
import { escape, async, areEqual, logEvent } from 'planado/utils/index.js'
import { BarLink } from 'planado/schedule/containers'
import { showTime } from 'planado/utils/time/index.js'
import { filterBars } from 'planado/schedule/utils2/filter'
import { SessionContext } from 'planado/utils/context.js'

class BarSingleSpan extends Component {
  state = {
    job: this.job,
    style: this.style,
    className: this.className,
    isMounted: false
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.model === nextProps.model) {
      return
    }

    this.props.model.off('change', this.onChange, this)
    nextProps.model.on('change', this.onChange, this)
  }

  componentDidMount() {
    const { job, chart } = this.props

    job.on('change', this.onChange, this)
    chart.on('change', this.onChange, this)

    this.setState(() => ({ isMounted: true }))
    jQuery(this.span).tooltip()
  }

  componentWillUnmount() {
    const { job, chart } = this.props

    job.off('change', this.onChange, this)
    chart.off('change:currentTime change:scale', this.onChange, this)

    this.setState(() => ({ isMounted: false }))
    jQuery(this.span).tooltip('destroy')
  }

  componentWillUpdate() {
    this.onChange()
  }

  componentDidUpdate() {
    const $el = jQuery(this.span)

    if ($el.hasClass('ui-draggable') && !this.props.job.isSchedulable()) {
      $el.draggable('destroy')
    }
  }

  onChange = () => {
    const prevState = this.state
    const nextState = {
      job: this.job,
      style: this.style,
      className: this.className,
      isMounted: prevState.isMounted
    }

    if (!areEqual(prevState, nextState)) {
      this.setState(() => nextState)
    }
  }

  get job() {
    const { job, chart, ctx } = this.props

    return {
      id: job.id,
      label: job.getLabel(ctx),
      description: job.getDescription(ctx),
      status: job.status(chart.time(), ctx)
    }
  }

  get style() {
    const { chart, job, ctx } = this.props

    const position = {
      start: chart.viewOffset(job.startAt(ctx)),
      finish: chart.viewOffset(job.finishAt(ctx))
    }

    return {
      left: position.start,
      width: position.finish - position.start,
      top: 0,
      zIndex: position.start
    }
  }

  get className() {
    const { job, chart, ctx } = this.props

    let status
    let isMounted
    if (this.state) {
      isMounted = this.state.isMounted
      status = this.state.job.status
    } else {
      isMounted = false
      status = job.status(chart.time(), ctx)
    }

    const draggable =
      isMounted &&
      chart.isEditable() &&
      jQuery(this.span).hasClass('ui-draggable')

    const classes = classNames({
      'bar-span': true,
      'ui-draggable': draggable,
      active: job.get('active')
    })

    return classes + ' ' + status + ' ' + job.get('state')
  }

  setActiveJob = () => {
    const { job, chart } = this.props

    if (!job.get('active')) {
      chart.setActiveJob(job)
    }
  }

  openJob = () => this.props.openJob(this.props.job.attributes.uuid)

  render() {
    const { job, className, style } = this.state
    return (
      <div
        ref={div => (this.span = div)}
        className={className}
        style={style}
        data-toggle="tooltip"
        data-placement="bottom"
        data-animation="false"
        data-delay="300"
        title={job.description}
        data-job-id={job.id}
        onClick={this.setActiveJob}
        onDoubleClick={this.openJob}
      >
        <span className="text">{job.label}</span>
      </div>
    )
  }
}

class BarSingleSpanOfMulti extends Component {
  state = {
    status: this.props.model.status(this.props.chart.time(), this.props.ctx),
    editable: this.props.chart.isEditable(),
    isMounted: false
  }

  componentDidMount() {
    const { model, chart } = this.props
    model.on('change', () => this.forceUpdate(), this)
    chart.on('change', this.updateStateClass, this)
  }

  componentWillReceiveProps(nextProps) {
    const { model } = this.props
    if (model === nextProps.model) {
      return
    }

    model.off(null, null, this)
    nextProps.model.on('change', () => this.forceUpdate(), this)
  }

  componentWillUpdate() {
    this.updateStateClass()
  }

  componentWillUnmount() {
    const { model, chart } = this.props

    model.off(null, null, this)
    chart.off(null, this.updateStateClass, this)
    this.setState(() => ({
      isMounted: false
    }))
  }

  get spanClasses() {
    const draggable =
      this.state.isMounted &&
      jQuery(this.bar).hasClass('ui-draggable') &&
      this.state.editable
    const classes = classNames({
      'bar-span': true,
      active: this.props.model.get('active'),
      'ui-draggable': draggable
    })

    return (
      classes + ' ' + this.state.status + ' ' + this.props.model.get('state')
    )
  }

  setActiveJob = evt => {
    evt.preventDefault()
    evt.stopPropagation()

    if (!this.props.model.get('active')) {
      this.props.chart.setActiveJob(this.props.model)
    }
  }

  updateStateClass() {
    const { model, chart, ctx } = this.props
    const newStateClass = model.status(chart.time(), ctx)

    if (newStateClass !== this.state.status) {
      this.setState(() => {
        newStateClass
      })
    }
  }

  openJob = () => this.props.openJob(this.props.model.attributes.uuid)

  render() {
    const { handleMouseEnter, handleMouseLeave, model, ctx } = this.props

    return (
      <div
        className={this.spanClasses + ' nested'}
        onMouseMove={handleMouseEnter}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data-job-id={model.id}
        onClick={this.setActiveJob}
        ref={div => {
          this.bar = div
        }}
        onDoubleClick={this.openJob}
      >
        <span className="text">{model.getLabel(ctx)}</span>
      </div>
    )
  }
}

class BarMultiSpanContent extends Component {
  componentWillMount() {
    this.dragManager = new DraggableSpansManager({
      ctx: this.props.ctx,
      chart: this.props.chart,
      nested: true,
      callbacks: {
        dragStart: this.props.handleSpanDragStart,
        dragStop: this.props.handleSpanDragStop
      }
    })
  }

  componentDidMount() {
    this.dragManager.manage(this.$spans)
  }

  componentDidUpdate() {
    this.dragManager.manage(this.$spans)
  }

  get $spans() {
    return jQuery(this.bar).find('.bar-span')
  }

  componentWillUnmount() {
    this.props.group.finalize()
  }

  render() {
    const classes = classNames({
      'multi-span-content popover bottom': true,
      hide: !this.props.visible
    })

    const { chart, group } = this.props
    const props = this.props

    const style = { display: 'block' }
    if (this.props.transparent) {
      style.visibility = 'hidden'
    }

    const spans = group.map((job, i) => (
      <BarSingleSpanOfMulti
        key={i}
        ctx={props.ctx}
        model={job}
        chart={chart}
        handleMouseEnter={props.handleMouseEnter}
        handleMouseEnter={props.handleMouseEnter}
        handleMouseLeave={props.handleMouseLeave}
        openJob={props.openJob}
      />
    ))

    return (
      <div
        className={classes}
        style={style}
        ref={div => {
          this.bar = div
        }}
        key={group.getKey()}
      >
        <div className="arrow" />
        <div className="popover-content">{spans}</div>
      </div>
    )
  }
}

class BarMultiSpan extends Component {
  get hideContentDelay() {
    return 500
  }

  constructor(props) {
    super(props)

    this.state = {
      jobMouseMoveThrottler: new Throttler(250, this.handleJobMouseEnter, this),
      hovered: false,
      hoveredJob: null,
      activeJob: this.props.group.activeJob(),
      dragging: false
    }
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.handleJobMouseLeave = this.handleJobMouseLeave.bind(this)
    this.handleSpanDragStart = this.handleSpanDragStart.bind(this)
    this.handleSpanDragStop = this.handleSpanDragStop.bind(this)
    this.setActiveJob = this.setActiveJob.bind(this)
    this.forceUpdate2 = this.forceUpdate2.bind(this)
  }

  render() {
    const { group, chart, ctx } = this.props
    let position = {
      start: chart.viewOffset(group.minStartAt()),
      finish: chart.viewOffset(group.maxFinishAt(ctx))
    }

    let { activeJob } = this.state
    let focusedJob = this.state.hoveredJob || activeJob

    let classes = classNames({
      'bar-span multiple': true,
      blur: focusedJob,
      hovered: this.state.hovered
    })

    let hoveredJobClasses = classNames({
      'hovered-job': true,
      hide: !focusedJob,
      active: focusedJob === activeJob
    })

    let hoveredJobStyle = {}

    if (focusedJob) {
      const absoluteOffset = chart.viewOffset(focusedJob.startAt(ctx))
      hoveredJobStyle.left = absoluteOffset - position.start
      hoveredJobStyle.width =
        chart.viewOffset(focusedJob.finishAt(ctx)) - absoluteOffset
    }

    const spanWidth = position.finish - position.start

    return (
      <div
        className={classes}
        key={group.getKey()}
        style={{ left: position.start, width: spanWidth }}
        ref={div => {
          this.bar = div
        }}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <div
          className={hoveredJobClasses}
          style={hoveredJobStyle}
          dangerouslySetInnerHTML={{ __html: '&nbsp;' }}
        />
        <span className="spans-count">{group.length}</span>
        <span className="text">{group.getName(ctx)}</span>
        <BarMultiSpanContent
          ctx={ctx}
          chart={chart}
          group={group}
          visible={this.state.hovered}
          transparent={this.state.dragging}
          hoveredJob={this.state.hoveredJob}
          handleMouseEnter={this.state.jobMouseMoveThrottler.call}
          handleMouseLeave={this.handleJobMouseLeave}
          handleSpanDragStart={this.handleSpanDragStart}
          handleSpanDragStop={this.handleSpanDragStop}
          openJob={this.props.openJob}
        />
      </div>
    )
  }

  shouldComponentUpdate(newProps, newState) {
    return (
      this.state.hovered !== newState.hovered ||
      (this.state.hoveredJob && this.state.hoveredJob.id) !==
        (newState.hoveredJob && newState.hoveredJob.id) ||
      this.state.dragging !== newState.dragging ||
      this.state.activeJob !== newState.activeJob
    )
  }

  handleMouseEnter() {
    if (this._hoveredTimeout) {
      clearTimeout(this._hoveredTimeout)
      this._hoveredTimeout = null
      setTimeout(() => this._checkHover(), this.hideContentDelay)
    }

    this.setState(() => ({ hovered: true }))
  }

  handleMouseLeave() {
    if (this._hoveredTimeout || this.state.dragging) {
      return
    }
    this._hoveredTimeout = setTimeout(
      () => this._hideContent(false),
      this.hideContentDelay
    )
  }

  handleJobMouseEnter(evt) {
    if (this.state.hoveredJob) {
      return
    }
    const $span = jQuery(evt.currentTarget)
    const job = this.props.group.get($span.data('job-id'))
    this.setState(() => ({ hoveredJob: job }))
  }

  handleJobMouseLeave() {
    if (this.state.dragging) {
      return
    }
    this.state.jobMouseMoveThrottler.reset()
    this.setState(() => ({ hoveredJob: null }))
  }

  handleSpanDragStart() {
    this.props.chart.lock()
    if (this._hoveredTimeout) {
      clearTimeout(this._hoveredTimeout)
    }
    this._hoveredTimeout = null
    this.setState(() => ({ dragging: true }))
  }

  handleSpanDragStop() {
    // Let drop event to be processed first
    this._handleSpanDragStopDesc = async(() => {
      this.props.chart.unlock()
      this._hideContent()
      this.setState(() => ({ dragging: false, hoveredJob: null }))
    })
  }

  componentDidMount() {
    this.props.group.on('change:active_job', this.setActiveJob, this)
    this.props.chart.on('change', this.forceUpdate2, this)
  }

  componentWillUnmount() {
    if (this._hoveredTimeout) {
      clearTimeout(this._hoveredTimeout)
    }
    if (this._handleSpanDragStopDesc) {
      clearTimeout(this._handleSpanDragStopDesc)
    }

    const { chart, group } = this.props

    chart.unlock()
    group.off('change:active_job', this.setActiveJob, this)
    chart.off('change', this.forceUpdate2, this)
  }

  setActiveJob() {
    this.setState(() => ({ activeJob: this.props.group.activeJob() }))
  }

  forceUpdate2() {
    this.forceUpdate()
  }

  _hideContent(clear) {
    if (clear == null) {
      clear = true
    }
    if (clear) {
      clearTimeout(this._hoveredTimeout)
    }
    this._hoveredTimeout = null
    this.setState(() => ({ hovered: false, hoveredJob: null }))
  }

  _checkHover() {
    if (this.state.hoveredJob || this.state.dragging) {
      return
    }
    if (!jQuery(this.bar).is(':hover')) {
      this._hideContent(false)
    }
  }
}

class BarSpans extends Component {
  componentDidMount() {
    const { collection } = this.props
    collection.on('add remove reset sort', () => this.forceUpdate(), this)
    this.dragManager.manage(this.$spans)

    this.props.chart.on(
      'change:start change:scale',
      () => this.forceUpdate(),
      this
    )
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.collection === nextProps.collection) {
      return
    }
    nextProps.collection.on(
      'add remove reset sort',
      () => this.forceUpdate(),
      this
    )

    this.props.chart.off(null, null, this)
    this.props.chart.on(
      'change:start change:scale',
      () => this.forceUpdate(),
      this
    )
  }

  componentWillUnmount() {
    this.props.collection.off(null, null, this)
    this.props.chart.off(null, null, this)
  }

  componentWillMount() {
    this.dragManager = new DraggableSpansManager({
      ctx: this.props.ctx,
      chart: this.props.chart
    })
  }

  componentDidUpdate() {
    this.dragManager.manage(this.$spans)
  }

  get $spans() {
    return jQuery(this.container).find('.bar-span')
  }

  render() {
    const { chart, collection, openJob, ctx } = this.props
    const chartStartAt = chart.get('start').unix()
    const chartFinishAt = chart.get('finish').unix()

    const spans = []
    collection.groups(ctx).forEach(group => {
      if (group.length === 1) {
        const job = group.at(0)
        const jobStartAt = job.startAt(ctx).unix()
        const jobFinishAt = job.finishAt(ctx).unix()

        if (chartStartAt <= jobFinishAt && chartFinishAt >= jobStartAt) {
          spans.push(
            <BarSingleSpan
              key={job.id}
              job={job}
              openJob={openJob}
              chart={chart}
              ctx={ctx}
            />
          )
        }
      } else {
        const groupStartAt = group.minStartAt().unix()
        const groupFinishAt = group.maxFinishAt(ctx).unix()

        if (chartStartAt <= groupFinishAt && chartFinishAt >= groupStartAt) {
          spans.push(
            <BarMultiSpan
              group={group}
              chart={chart}
              key={group.getKey()}
              openJob={this.props.openJob}
              ctx={ctx}
            />
          )
        }
      }
    })

    return (
      <div
        className="spans-tree-container"
        ref={div => {
          this.container = div
        }}
      >
        {spans}
      </div>
    )
  }
}

class HighlightingTimeUnderCursor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      highlightTimeUnderCursor: false,
      highlightPosition: 0
    }

    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseOut = this.handleMouseOut.bind(this)
  }

  handleMouseMove(evt) {
    if (
      jQuery(evt.target).hasClass('ctx-menu') ||
      jQuery(evt.target).parents('.ctx-menu').length > 0
    ) {
      this.setState(() => ({
        highlightTimeUnderCursor: false,
        highlightPosition: null
      }))
      return
    }

    this.setState(() => ({
      highlightTimeUnderCursor: true,
      highlightPosition: evt.clientX - jQuery(this.cursor).offset().left
    }))
  }

  handleMouseOut() {
    this.setState(() => ({
      highlightTimeUnderCursor: false,
      highlightPosition: null
    }))
  }

  componentDidMount() {
    let $el = document.getElementsByClassName('bars-tree-container')[0]

    $el.addEventListener('mousemove', this.handleMouseMove)
    $el.addEventListener('mouseleave', this.handleMouseOut)
  }

  componentWillUnmount() {
    let $el = document.getElementsByClassName('bars-tree-container')[0]

    $el.removeEventListener('mousemove', this.handleMouseMove)
    $el.removeEventListener('mouseleave', this.handleMouseOut)
  }

  render = () => (
    <div className="highlighting-time" ref={c => (this.cursor = c)}>
      <div style={{ left: this.state.highlightPosition }} />
    </div>
  )
}

export class Bar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      menuPosition: {
        x: 0,
        y: 0
      },
      isHover: null
    }

    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)

    this.$window = jQuery(window)
  }

  componentDidMount() {
    if (!this.props.model) {
      return
    }

    this.props.model.on(
      'change',
      () => this.setState(() => ({ update: null })),
      this
    )
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.model === nextProps.model) {
      return
    }

    this.props.model.off(null, null, this)
    nextProps.model.on(
      'change',
      () => this.setState(() => ({ update: null })),
      this
    )
  }

  shouldComponentUpdate(nextProps) {
    return this.state.isHover === nextProps.model.get('hoverStatus')
  }

  componentWillUnmount() {
    if (!this.props.model) {
      return
    }

    this.props.model.off(null, null, this)
  }

  render() {
    const { chart, openJob, model: bar } = this.props

    let menu
    if (bar.get('menuId')) {
      menu = this.menu
    } else {
      menu = ''
    }

    const classes = classNames({
      'bar snapping': true,
      unassigned: bar.unassigned()
    })

    return (
      <div
        className={classes}
        key={bar.id}
        data-bar-id={bar.id}
        ref={div => {
          this.bar = div
        }}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onContextMenu={this.showJobVariants}
      >
        {menu}
        <SessionContext.Consumer>
          {ctx => (
            <BarSpans
              collection={bar.jobs()}
              chart={chart}
              openJob={openJob}
              ctx={ctx}
            />
          )}
        </SessionContext.Consumer>
        &nbsp;
      </div>
    )
  }

  handleMouseEnter() {
    this.props.model.setHoverStatus()
    this.setState(() => ({ isHover: true }))
  }

  handleMouseLeave() {
    this.props.model.resetHoverStatus()
    this.setState(() => ({ isHover: null }))
  }

  findTemplatesHeight() {
    const templates = this.props.templates.map(
      tpl => `<div class='ctx-list__item'>${escape(tpl.name)}</div>`
    )

    const div = document.createElement('div')
    div.innerHTML = `<div class='ctx-menu_shift'>${templates.join('')}</div>`
    document.body.appendChild(div)

    const menu = document.getElementsByClassName('ctx-menu_shift')[0]
    const menuHeight = menu.offsetHeight
    menu.remove()

    if (menuHeight >= 351) {
      return 430
    } else {
      return menuHeight + 79
    }
  }

  showJobVariants = evt => {
    evt.preventDefault()

    const { chart } = this.props

    if (!chart.isEditable() || evt.shiftKey || evt.altKey || evt.ctrlKey) {
      return
    }

    const menuHeight = this.findTemplatesHeight() + 4
    const documentHeight = this.$window.height()
    const barPosition = jQuery(this.bar).offset()
    const cursorYPos = this.$window.scrollTop() + evt.clientY

    const menuTooLow = documentHeight - cursorYPos < menuHeight
    const menuCanBeDisplayedOnTop =
      cursorYPos - jQuery('.view-port').offset().top > menuHeight

    let menuXPos
    if (document.body.clientWidth - evt.clientX < 290) {
      menuXPos = evt.clientX - 290
    } else {
      menuXPos = evt.clientX
    }

    let menuYPos
    if (menuTooLow && menuCanBeDisplayedOnTop) {
      menuYPos = cursorYPos - menuHeight
    } else if (!menuTooLow) {
      menuYPos = cursorYPos
    } else {
      menuYPos = documentHeight - menuHeight
    }

    this.props.model.setMenuId(new Date().getTime())

    const clientX = evt.clientX

    this.setState(() => ({
      menuPosition: {
        clientX: clientX - barPosition.left,
        x: menuXPos - barPosition.left + 1,
        y: menuYPos - barPosition.top
      },
      menuHeight
    }))
  }

  get menu() {
    const { model, chart, ctx } = this.props
    let position = this.state.menuPosition
    let menuId = model.get('menuId')
    const time = showTime(
      chart.viewOffsetToTime(this.state.menuPosition.clientX),
      'time',
      ctx
    )

    let classes = classNames({
      'ctx-list': true,
      'ctx-list_without-scroll': this.state.menuHeight < 430
    })
    return (
      <div
        className="ctx-menu"
        id={`menu_${menuId}`}
        style={{ left: position.x, top: position.y }}
      >
        <div className="ctx-menu__hl">
          {ctx.t('js.schedule.create_job_at') + ' ' + time}
        </div>

        <div className={classes}>{this.templatesList}</div>
      </div>
    )
  }

  get templatesList() {
    const { ctx } = this.props
    return (
      <>
        {this.props.templates.map(({ name, uuid }) => (
          <a
            href="#"
            className="ctx-list__item"
            key={uuid}
            onClick={e => this.createJobFromTemplate(e, uuid)}
          >
            {name}
          </a>
        ))}

        <div className="ctx-list__item ctx-list__item_divider">&nbsp;</div>

        <a
          href="#"
          className="ctx-list__item"
          onClick={e => this.createJobFromTemplate(e, null)}
        >
          {ctx.t('js.schedule.navigation.no_template')}
        </a>
      </>
    )
  }

  createJobFromTemplate = (event, template) => {
    event.preventDefault()
    const { chart, ctx, model, addJob } = this.props

    const scheduledAt = showTime(
      chart.viewOffsetToTime(this.state.menuPosition.clientX),
      'datetime',
      ctx
    )

    let assigneePk
    if (model.id === -1) {
      assigneePk = {}
      logEvent('old-schedule__add-unassigned-job', ctx)
    } else {
      assigneePk = { assigneePk: model.id }
      logEvent('old-schedule__add-job', ctx)
    }

    let templateUuid
    if (template === null) {
      templateUuid = {}
    } else {
      templateUuid = {
        templateUuid: template
      }
    }

    addJob({
      ...templateUuid,
      ...assigneePk,
      scheduledAt: scheduledAt
    })

    model.resetMenuId()
  }
}

export class BarsView extends Component {
  state = {
    highlightTimeUnderCursor: false,
    highlightPosition: 0
  }

  componentDidMount() {
    this.props.collection.on('add remove', () => this.forceUpdate(), this)
    this.initDroppable()
  }

  componentDidUpdate() {
    this.initDroppable()
  }

  componentWillUnmount = () => this.props.collection.off(null, null, this)

  handleMouseMove = evt => {
    const $target = jQuery(evt.target)
    if (
      $target.parents('.ctx-menu').length > 0 ||
      $target.hasClass('ctx-menu')
    ) {
      return
    }

    const clientX = evt.clientX - jQuery(this.bars).offset().left
    this.setState(() => ({
      highlightTimeUnderCursor: true,
      highlightPosition: clientX
    }))
  }

  handleMouseOut = () => {
    this.setState(() => ({
      highlightTimeUnderCursor: false,
      highlightPosition: null
    }))
  }

  get $bars() {
    return jQuery(this.bars).find('.bar')
  }

  initDroppable() {
    const { chart, ctx } = this.props
    this.$bars.filter(':not(.ui-droppable)').droppable({
      accept: '.bar-span,.side-bar-job',
      scope: 'chart',
      drop(evt, ui) {
        const $span = ui.helper
        if ($span.data('outOfChart')) {
          return
        }

        const $bar = jQuery(this)
        const job = chart.jobs().get($span.data('job-id'))
        const bar = chart.bars().get($bar.data('bar-id'))

        const offset =
          ui.position.left -
          jQuery('.view-port').offset().left +
          jQuery('.view-port')[0].scrollLeft
        const time = chart.viewOffsetToTime(offset)
        if (chart.isEditable()) {
          if (bar.unassigned()) {
            if (job.get('bar_id') === -1) {
              logEvent('old-schedule__reschedule-unassigned-job', ctx)
            } else {
              logEvent('old-schedule__unassign-job', ctx)
            }

            if (job.published()) {
              job.unassign(time, ctx)
            } else {
              job.rescheduleAt({ time, ctx })
            }
          } else {
            job.assignTo(bar, time, ctx)
          }
        }
      },

      over(evt, ui) {
        let $span = ui.helper
        $span.data({ $activeBar: this })
      },
      out(evt, ui) {
        let $span = ui.helper
        $span.data({ $activeBar: null })
      }
    })
  }

  render = () => (
    <SessionContext.Consumer>
      {ctx => (
        <div className="bars-tree-container" ref={div => (this.bars = div)}>
          <>
            {filterBars(this.props.collection, this.props.filter).map(bar => (
              <BarLink ctx={ctx} model={bar} key={bar.id} />
            ))}

            <HighlightingTimeUnderCursor />

            <ChartBusinessHoursLink ctx={ctx} />
          </>
        </div>
      )}
    </SessionContext.Consumer>
  )
}
