

import * as Fun from "../../../../../../libraries/Fun.mjs";
import * as Uuid from "../../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JobEdit_JobType from "../../../lib/types/JobEdit_JobType.mjs";
import * as JobForm_ResolutionInput from "../resolution_input/JobForm_ResolutionInput.mjs";
import * as JobForm_ResolutionPopup from "../resolution_popup/JobForm_ResolutionPopup.mjs";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Encode.mjs";

var selectResolution$p = Utils.Translations.tr("js.jobs.modal.select_resolution");

var successful$p = Utils.Translations.tr("js.jobs.modal.successful");

var unsuccessful$p = Utils.Translations.tr("js.jobs.modal.unsuccessful");

var finishJob$p = Utils.Translations.tr("js.jobs.modal.finish_job");

var finish$p = Utils.Translations.tr("js.jobs.modal.buttons.finish");

var close$p = Utils.Translations.tr("js.jobs.modal.buttons.close");

var save$p = Utils.Translations.tr("js.jobs.modal.buttons.save");

function JobForm_FinishPopup$ResolutionList(Props) {
  var possibleResolutions = Props.possibleResolutions;
  var onCommentChange = Props.onCommentChange;
  var onResolutionChange = Props.onResolutionChange;
  var resolutionCommentOpt = Props.resolutionComment;
  var resolution = Props.resolution;
  var ctx = Props.ctx;
  var resolutionComment = resolutionCommentOpt !== undefined ? resolutionCommentOpt : "";
  var renderResolution = function (r) {
    var isSelected = resolution !== undefined ? Uuid.equal(r.resolutionUuid, resolution.resolutionUuid) : false;
    return React.createElement(JobForm_ResolutionInput.make, {
                name: r.name,
                isCommentRequired: r.isCommentRequired,
                onSelect: (function (param) {
                    onResolutionChange(r);
                  }),
                onCommentChange: onCommentChange,
                hasComment: r.hasComment,
                isSelected: isSelected,
                resolutionComment: resolutionComment,
                ctx: ctx,
                key: Uuid.toString(r.resolutionUuid)
              });
  };
  var successfulResolutions = RCore.$$Array.filterMap(possibleResolutions, (function (r) {
          if (r.isSuccessful) {
            return Caml_option.some(renderResolution(r));
          }
          
        }));
  var unsuccessfulResolutions = RCore.$$Array.filterMap(possibleResolutions, (function (r) {
          if (r.isSuccessful) {
            return ;
          } else {
            return Caml_option.some(renderResolution(r));
          }
        }));
  return React.createElement("div", {
              className: "pd-table-layout"
            }, React.createElement("div", {
                  className: "pd-tl-row"
                }, React.createElement("div", {
                      className: "pd-tl-cell pd-text-right"
                    }), React.createElement("div", {
                      className: "pd-tl-cell pd-tl-cell-heading"
                    }, React.createElement("strong", undefined, selectResolution$p(ctx)))), React.createElement("div", {
                  className: "pd-tl-row resolutions-successful"
                }, React.createElement("div", {
                      className: "pd-tl-cell pd-text-right"
                    }, successful$p(ctx)), React.createElement("div", {
                      className: "pd-tl-cell"
                    }, successfulResolutions)), React.createElement("div", {
                  className: "pd-tl-row resolutions-unsuccessful"
                }, React.createElement("div", {
                      className: "pd-tl-cell pd-text-right"
                    }, unsuccessful$p(ctx)), React.createElement("div", {
                      className: "pd-tl-cell"
                    }, unsuccessfulResolutions)));
}

function JobForm_FinishPopup(Props) {
  var possibleResolutions = Props.possibleResolutions;
  var onClose = Props.onClose;
  var submitForm = Props.submitForm;
  var locked = Props.locked;
  var param = Props.wire;
  var initResolution = Props.initResolution;
  var initResolutionComment = Props.initResolutionComment;
  var status = Props.status;
  var ctx = param.ctx;
  var match = React.useState(function () {
        return initResolution;
      });
  var setResolution = match[1];
  var resolution = match[0];
  var match$1 = React.useState(function () {
        return initResolutionComment;
      });
  var setComment = match$1[1];
  var resolutionComment = match$1[0];
  var onCommentChange = function (resolutionComment) {
    setComment(function (param) {
          return Fun.optString(resolutionComment);
        });
  };
  var onResolutionChange = function (resolution) {
    setResolution(function (param) {
          return resolution;
        });
  };
  var onSubmitForm = function (r) {
    submitForm([
          [
            "status",
            JobEdit_JobType.Status.toString("Finished")
          ],
          [
            "resolutionUuid",
            Uuid.toString(r.resolutionUuid)
          ],
          [
            "resolutionComment",
            r.hasComment ? Json_Encode$JsonCombinators.option(function (prim) {
                      return prim;
                    })(resolutionComment) : null
          ]
        ]);
  };
  var buttonText = status === "Finished" ? save$p(ctx) : finish$p(ctx);
  return React.createElement(JobForm_ResolutionPopup.make, {
              possibleResolutions: possibleResolutions,
              initResolution: initResolution,
              initResolutionComment: initResolutionComment,
              resolutionComment: resolutionComment,
              locked: locked,
              onClose: onClose,
              submitForm: onSubmitForm,
              resolution: resolution,
              title: finishJob$p(ctx),
              closeText: close$p(ctx),
              buttonText: buttonText,
              children: React.createElement(JobForm_FinishPopup$ResolutionList, {
                    possibleResolutions: possibleResolutions,
                    onCommentChange: onCommentChange,
                    onResolutionChange: onResolutionChange,
                    resolutionComment: RCore.$$Option.getOr(resolutionComment, ""),
                    resolution: resolution,
                    ctx: ctx
                  })
            });
}

var make = JobForm_FinishPopup;

export {
  make ,
}
/* selectResolution' Not a pure module */
