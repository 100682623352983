// extracted by mini-css-extract-plugin
export var btnEdit = "btn-edit-RnVF8rJwQpZkXyA7lpsQ";
export var btnHideAnim = "btn-hide-anim-vP307sSXMSx13akJc65L";
export var btnShowAnim = "btn-show-anim-BGS30raaE6DztbMzTQe2";
export var container = "container-HFL3pBgwZeAI0du2aHlg";
export var disabled = "disabled-LIUnuwMvwtvbHR4VhB1k";
export var hideAnimation = "hide-animation-waANlrA8lIGm6oBsFryF";
export var input = "input-djNhavf0pZxWy5bV2fKc";
export var placeholder = "placeholder-R0r2jdUcvbIesygvyIQF";
export var showAnimation = "show-animation-BmV6NBRYnEMYz7QktUCK";
export var urlContainer = "url-container-LmIVrSVsuKlhf3rS39e9";
export var urlText = "url-text-neuG58HVzWcQrmstduei";