

import * as El from "../../../../../../libraries/El.mjs";
import * as Uuid from "../../../../../../bindings/uuid/Uuid.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Assignee_Types from "../../../../../../types/Assignee_Types.mjs";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as WeekTimeline_Bar from "./bar/WeekTimeline_Bar.mjs";
import * as WeekTimeline_Scale from "./scale/WeekTimeline_Scale.mjs";
import * as WeekTimeline_State from "../../lib/state/WeekTimeline_State.mjs";
import * as Schedule_TimelineEmpty from "../../../../components/timeline/empty/Schedule_TimelineEmpty.mjs";
import * as Schedule_TimelineHooks from "../../../../lib/hooks/Schedule_TimelineHooks.mjs";
import * as WeekTimeline_CurrentTime from "./current_time/WeekTimeline_CurrentTime.mjs";
import * as Schedule_Scroll_InitialPosition from "../../../../components/scroll/Schedule_Scroll_InitialPosition.mjs";
import * as WeekTimeline_TimelineModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/timeline/WeekTimeline_Timeline.module.css";

var styles = WeekTimeline_TimelineModuleCss;

function barKey(v) {
  return Uuid.toString(Assignee_Types.Assignee.uuid(v));
}

function makeStyle(width) {
  return {
          width: Units.Px.toString(width)
        };
}

function makeScrollableStyle(width, initialPositionY) {
  var initialPositionY$1 = Units.Px.toString(Units.Px.abs(initialPositionY));
  return {
          width: Units.Px.toString(width),
          transform: "translate(0px, -" + initialPositionY$1 + ")"
        };
}

function WeekTimeline_Timeline(Props) {
  var viewportEl = Props.viewportEl;
  var bars = Props.bars;
  var wire = AppContext.useWire();
  var sizes = WeekTimeline_State.useSizes();
  var loading = Schedule_State.useScheduledJobsLoading();
  var match = Schedule_TimelineHooks.DragCursor.use();
  var initialPositionY = Schedule_Scroll_InitialPosition.useInitialPositionY();
  if (Caml_obj.equal(bars, []) && !loading) {
    return React.createElement(Schedule_TimelineEmpty.make, {
                width: sizes.emptyViewport
              });
  }
  var className = El.Cn.concat(styles.container, match[2] ? styles.isDrag : "");
  return React.createElement("div", {
              className: className,
              onMouseDown: match[0],
              onMouseUp: match[1]
            }, React.createElement(WeekTimeline_Scale.make, {}), React.createElement("div", {
                  ref: viewportEl,
                  className: styles.viewport + " " + wire.tour.anchor("schedule-viewport"),
                  style: makeStyle(sizes.viewport)
                }, React.createElement("div", {
                      className: styles.scrollable,
                      style: makeScrollableStyle(sizes.viewport, initialPositionY)
                    }, bars.map(function (param) {
                          var assignee = param.assignee;
                          return React.createElement(WeekTimeline_Bar.make, {
                                      viewportEl: viewportEl,
                                      assignee: assignee,
                                      jobs: param.jobs,
                                      workingIntervals: param.workingIntervals,
                                      key: barKey(assignee)
                                    });
                        }), React.createElement(WeekTimeline_CurrentTime.make, {
                          part: "Line"
                        }))));
}

var make = WeekTimeline_Timeline;

export {
  make ,
}
/* styles Not a pure module */
