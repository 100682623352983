import React from 'react'
import classNames from 'classnames'
import LimitedInput from 'planado/components/input/limited_input'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import Tooltip, {
  positionFromString,
} from 'rscrpt/components/common/Tooltip/Tooltip.mjs'
import * as styles from './styles.module.css'

const MAX_TYPE_LENGTH = 40

const JobType = withContext(
  ({
    idx,
    formDisabled,
    id,
    name,
    canBeRemoved,
    removed,
    setType,
    removeType,
    ctx,
  }) => {
    const onRemoveClick = (_evt) => {
      if (canBeRemoved) {
        removeType(idx)
      }
    }

    const ref = React.createRef()

    const nameKey = `settings[job_types][${idx}]`

    const classes = classNames({
      row: true,
      'job-type': true,
      'job-type_hidden': removed,
    })

    return (
      <div className={classes}>
        <div className="input-group col-xs-12">
          <input type="hidden" name={`${nameKey}[removed]`} value={removed} />

          <input
            type="hidden"
            name={`${nameKey}[id]`}
            value={id === null ? '' : id}
          />

          <LimitedInput
            className="form-control pd-form-control"
            type="text"
            name={nameKey + '[name]'}
            maxLength={MAX_TYPE_LENGTH}
            onValueChange={(value) => setType(idx, value)}
            value={name}
            disabled={formDisabled}
          />

          <span className={'input-group-btn remove-type ' + styles.btnDelete}>
            <Tooltip
              wrapperClassName={styles.tooltip}
              content={ctx.t('js.admin.settings.cannot_be_removed')}
              elementRef={ref}
              position={positionFromString('right')}
              manual
              enabled={!canBeRemoved}
            >
              <button
                ref={ref}
                className="btn"
                type="button"
                disabled={formDisabled}
                onClick={onRemoveClick}
              >
                <span className="fa fa-times" />
              </button>
            </Tooltip>
          </span>
        </div>
      </div>
    )
  }
)

export default withContext(({ formDisabled, types, actions, ctx }) => (
  <div>
    {types.map((type, idx) => (
      <JobType key={idx} {...{ idx, formDisabled, ...type, ...actions }} />
    ))}

    <div className="row add-link-row">
      <button
        type="button"
        className="btn-link add-link"
        onClick={actions.addType}
        disabled={formDisabled}
      >
        <span className="fa fa-plus" />
        {' ' + ctx.t('js.admin.settings.add_job_type')}
      </button>
    </div>
  </div>
))
