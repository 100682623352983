

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Shared_Lib_IO from "../shared/lib/Shared_Lib_IO.mjs";

function map(p, f) {
  return p.then(f);
}

var Functor = {
  map: map
};

function pure(x) {
  return Promise.resolve(x);
}

function bind(p, f) {
  return p.then(f);
}

var Monad = {
  pure: pure,
  bind: bind
};

function bimap(success, fail, p) {
  return p.then(success, (function (err) {
                return Promise.reject(fail(err));
              }));
}

function $$catch(f, p) {
  return p.catch(function (x) {
              return Promise.resolve(f(x));
            });
}

function mapError(f, p) {
  return p.then((function (x) {
                return x;
              }), (function (err) {
                return Promise.reject(f(err));
              }));
}

function $$finally(success, fail, p) {
  p.then(success, fail);
}

function fold(success, fail, p) {
  return p.then(success, fail);
}

function fromOption(err, opt) {
  if (opt !== undefined) {
    return Promise.resolve(Caml_option.valFromOption(opt));
  } else {
    return Promise.reject(err);
  }
}

function fromResult(opt) {
  if (opt.TAG === "Ok") {
    return Promise.resolve(opt._0);
  } else {
    return Promise.reject(opt._0);
  }
}

function fromJs(p) {
  return p.then(function (x) {
              return x;
            });
}

function toIOLazy(runPromise) {
  return Shared_Lib_IO.async(function (onDone) {
              var promise = runPromise();
              bimap((function (v) {
                      return Promise.resolve(onDone({
                                      TAG: "Ok",
                                      _0: v
                                    }));
                    }), (function (e) {
                      return Promise.resolve(onDone({
                                      TAG: "Error",
                                      _0: e
                                    }));
                    }), promise);
            });
}

function make(f) {
  return new Promise(f);
}

var JS = {};

export {
  Functor ,
  map ,
  Monad ,
  pure ,
  bind ,
  bimap ,
  $$catch ,
  mapError ,
  $$finally ,
  fold ,
  fromOption ,
  fromResult ,
  fromJs ,
  toIOLazy ,
  make ,
  JS ,
}
/* No side effect */
