

import * as El from "../El.mjs";
import * as RCore from "../RCore.mjs";
import * as Units from "../../types/Units.mjs";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var defaultThreshold = [Units.Px.zero];

var defaultRootMargin = El.Cn.concat(El.Cn.concat(El.Cn.concat(Units.Px.toString(Units.Px.zero), Units.Px.toString(Units.Px.zero)), Units.Px.toString(Units.Px.zero)), Units.Px.toString(Units.Px.zero));

function use(thresholdOpt, rootMarginOpt, param) {
  var threshold = thresholdOpt !== undefined ? thresholdOpt : defaultThreshold;
  var rootMargin = rootMarginOpt !== undefined ? rootMarginOpt : defaultRootMargin;
  var rootRef = React.useRef(null);
  var elementRef = React.useRef(null);
  var match = React.useState(function () {
        
      });
  var setEntry = match[1];
  React.useEffect((function () {
          var element = elementRef.current;
          if (element == null) {
            return ;
          }
          var root = RCore.$$Option.getOr(Caml_option.nullable_to_opt(rootRef.current), document.documentElement);
          var options = {
            root: root,
            rootMargin: rootMargin,
            threshold: threshold.map(Units.Px.toFloat)
          };
          var observer = new IntersectionObserver((function (entries, _observer) {
                  if (entries.length !== 1) {
                    return ;
                  }
                  var newEntry = entries[0];
                  setEntry(function (param) {
                        return Caml_option.some(newEntry);
                      });
                }), options);
          observer.observe(element);
          return (function () {
                    observer.disconnect();
                  });
        }), [
        elementRef.current,
        rootRef.current
      ]);
  return {
          entry: match[0],
          elementRef: elementRef,
          rootRef: rootRef
        };
}

export {
  use ,
}
/* defaultRootMargin Not a pure module */
