

import * as Fun from "../../../../../../../libraries/Fun.mjs";
import * as Uuid from "../../../../../../../bindings/uuid/Uuid.mjs";
import * as Wire from "../../../../../../../types/Wire.mjs";
import * as Hooks from "../../../../../../../libraries/hooks/Hooks.mjs";
import * as React from "react";
import * as Optional from "../../../../../../common/Optional.mjs";
import * as UrlInput from "../../../../../../inputs/UrlInput.mjs";
import * as FormInput from "../../../../../../inputs/FormInput.mjs";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DecimalInput from "../../../../../../inputs/DecimalInput.mjs";
import * as IntegerInput from "../../../../../../inputs/IntegerInput.mjs";
import * as CurrencyInput from "../../../../../../inputs/CurrencyInput.mjs";
import * as DateTimePicker from "../../../../../../inputs/DateTimePicker.mjs";
import * as JobAttachmentInput from "../../../../../../file/job_attachment_input/JobAttachmentInput.mjs";
import * as JobForm_ReportFieldButton from "./components/button/JobForm_ReportFieldButton.mjs";
import * as JobForm_ReportFieldCheckbox from "./components/checkbox/JobForm_ReportFieldCheckbox.mjs";
import * as JobForm_ReportFieldSignature from "./components/signature/JobForm_ReportFieldSignature.mjs";
import * as JobForm_ReportFieldDatePicker from "./components/date_picker/JobForm_ReportFieldDatePicker.mjs";
import * as JobForm_ReportFieldDictionary from "./components/dictionary/JobForm_ReportFieldDictionary.mjs";
import * as JobForm_ReportFieldTimePicker from "./components/time_picker/JobForm_ReportFieldTimePicker.mjs";
import * as JobForm_ReportFieldActionInput from "./components/action_input/JobForm_ReportFieldActionInput.mjs";
import * as JobForm_ReportFieldModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/edit/form/components/reports/report_field/JobForm_ReportField.module.css";

var styles = JobForm_ReportFieldModuleCss;

function JobForm_ReportField(Props) {
  var wire = Props.wire;
  var readOnly = Props.readOnly;
  var field = Props.field;
  var addFile = Props.addFile;
  var removeFile = Props.removeFile;
  var updateReportField = Props.updateReportField;
  var jobUuid = Props.jobUuid;
  var setLock = Props.setLock;
  var showCarouselImage = Props.showCarouselImage;
  var id = Hooks.useId(undefined);
  var key = Uuid.toString(field.typeUuid);
  var button = field.value;
  switch (button.TAG) {
    case "Action" :
        return React.createElement(JobForm_ReportFieldActionInput.make, {
                    id: id,
                    label: field.name,
                    value: button._0,
                    onValueChange: (function (value) {
                        updateReportField({
                              typeUuid: field.typeUuid,
                              name: field.name,
                              value: {
                                TAG: "Action",
                                _0: value
                              }
                            });
                      }),
                    readOnly: readOnly,
                    key: key
                  });
    case "Barcode" :
        return React.createElement(FormInput.make, {
                    _type: "textarea",
                    value: button._0,
                    onValueChange: (function (value) {
                        updateReportField({
                              typeUuid: field.typeUuid,
                              name: field.name,
                              value: {
                                TAG: "Barcode",
                                _0: value
                              }
                            });
                      }),
                    id: id,
                    readOnly: readOnly,
                    labelText: field.name,
                    maxLength: 5000,
                    errors: [],
                    autoFocus: true,
                    key: key
                  });
    case "Button" :
        return React.createElement(Optional.make, {
                    show: Wire.hasTag(wire, "planadoteam"),
                    children: React.createElement("div", {
                          className: styles.field
                        }, React.createElement("div", {
                              className: styles.fieldName
                            }, field.name), React.createElement(JobForm_ReportFieldButton.make, {
                              wire: wire,
                              jobUuid: jobUuid,
                              typeUuid: field.typeUuid,
                              button: button._0
                            })),
                    key: key
                  });
    case "Checkbox" :
        return React.createElement(JobForm_ReportFieldCheckbox.make, {
                    label: field.name,
                    value: button._0,
                    id: id,
                    onValueChange: (function (value) {
                        updateReportField({
                              typeUuid: field.typeUuid,
                              name: field.name,
                              value: {
                                TAG: "Checkbox",
                                _0: value
                              }
                            });
                      }),
                    ctx: wire.ctx,
                    readOnly: readOnly,
                    key: key
                  });
    case "Currency" :
        var match = button._0;
        var useCurrencyFractionalUnit = match.useCurrencyFractionalUnit;
        var currency = match.currency;
        return React.createElement("div", {
                    key: key,
                    className: styles.field
                  }, React.createElement("div", {
                        className: styles.fieldName
                      }, field.name), React.createElement(CurrencyInput.make, {
                        wire: wire,
                        currency: currency,
                        useCurrencyFractionalUnit: useCurrencyFractionalUnit,
                        value: match.value,
                        onValueChange: (function (value) {
                            updateReportField({
                                  typeUuid: field.typeUuid,
                                  name: field.name,
                                  value: {
                                    TAG: "Currency",
                                    _0: {
                                      currency: currency,
                                      useCurrencyFractionalUnit: useCurrencyFractionalUnit,
                                      value: Fun.optString(value)
                                    }
                                  }
                                });
                          }),
                        disabled: readOnly,
                        backendErrors: Core__List.fromArray([])
                      }));
    case "Date" :
        var tmp = {
          id: id,
          onChange: (function (value) {
              updateReportField({
                    typeUuid: field.typeUuid,
                    name: field.name,
                    value: {
                      TAG: "Date",
                      _0: value
                    }
                  });
            }),
          disabled: readOnly,
          wire: wire
        };
        if (button._0 !== undefined) {
          tmp.date = Caml_option.valFromOption(button._0);
        }
        return React.createElement("div", {
                    key: key,
                    className: styles.field
                  }, React.createElement("div", {
                        className: styles.fieldName
                      }, field.name), React.createElement(JobForm_ReportFieldDatePicker.make, tmp));
    case "DateTime" :
        return React.createElement("div", {
                    key: key,
                    className: styles.field
                  }, React.createElement(DateTimePicker.make, {
                        id: id,
                        label: field.name,
                        dateTime: button._0,
                        onChange: (function (value) {
                            updateReportField({
                                  typeUuid: field.typeUuid,
                                  name: field.name,
                                  value: {
                                    TAG: "DateTime",
                                    _0: value
                                  }
                                });
                            return true;
                          }),
                        disabled: readOnly
                      }));
    case "Dictionary" :
        var match$1 = button._0;
        var dictionaryValues = match$1.dictionaryValues;
        return React.createElement(JobForm_ReportFieldDictionary.make, {
                    id: id,
                    label: field.name,
                    value: match$1.value,
                    dictionaryValues: dictionaryValues,
                    onValueChange: (function (value) {
                        updateReportField({
                              typeUuid: field.typeUuid,
                              name: field.name,
                              value: {
                                TAG: "Dictionary",
                                _0: {
                                  dictionaryValues: dictionaryValues,
                                  value: value
                                }
                              }
                            });
                      }),
                    disabled: readOnly,
                    ctx: wire.ctx,
                    key: key
                  });
    case "File" :
    case "Image" :
        break;
    case "Input" :
        var inputType = button._0;
        var variant = inputType.NAME;
        if (variant === "Integer") {
          return React.createElement(IntegerInput.make, {
                      id: id,
                      value: inputType.VAL,
                      onValueChange: (function (value) {
                          updateReportField({
                                typeUuid: field.typeUuid,
                                name: field.name,
                                value: {
                                  TAG: "Input",
                                  _0: {
                                    NAME: "Integer",
                                    VAL: value
                                  }
                                }
                              });
                        }),
                      disabled: readOnly,
                      labelText: field.name,
                      errors: [],
                      key: key
                    });
        } else if (variant === "Decimal") {
          return React.createElement(DecimalInput.make, {
                      id: id,
                      value: inputType.VAL,
                      onValueChange: (function (value) {
                          updateReportField({
                                typeUuid: field.typeUuid,
                                name: field.name,
                                value: {
                                  TAG: "Input",
                                  _0: {
                                    NAME: "Decimal",
                                    VAL: value
                                  }
                                }
                              });
                        }),
                      disabled: readOnly,
                      labelText: field.name,
                      wire: wire,
                      errors: [],
                      key: key
                    });
        } else {
          return React.createElement(FormInput.make, {
                      _type: "textarea",
                      value: inputType.VAL,
                      onValueChange: (function (value) {
                          updateReportField({
                                typeUuid: field.typeUuid,
                                name: field.name,
                                value: {
                                  TAG: "Input",
                                  _0: {
                                    NAME: "String",
                                    VAL: value
                                  }
                                }
                              });
                        }),
                      readOnly: readOnly,
                      labelText: field.name,
                      maxLength: 5000,
                      errors: [],
                      autoFocus: true,
                      key: key
                    });
        }
    case "Link" :
        return React.createElement("div", {
                    key: key
                  }, React.createElement(UrlInput.make, {
                        id: id,
                        value: button._0,
                        onValueChange: (function (value) {
                            updateReportField({
                                  typeUuid: field.typeUuid,
                                  name: field.name,
                                  value: {
                                    TAG: "Link",
                                    _0: value
                                  }
                                });
                          }),
                        disabled: readOnly,
                        labelText: field.name,
                        wire: wire,
                        className: undefined,
                        errors: []
                      }));
    case "Signature" :
        return React.createElement("div", {
                    key: key,
                    className: styles.field
                  }, React.createElement("div", {
                        className: styles.fieldName
                      }, field.name), React.createElement(JobForm_ReportFieldSignature.make, {
                        wire: wire,
                        images: button._0.attachments,
                        removeFile: (function (uuid) {
                            removeFile(field.typeUuid, uuid);
                          }),
                        readOnly: readOnly
                      }));
    case "Time" :
        var tmp$1 = {
          onChange: (function (value) {
              updateReportField({
                    typeUuid: field.typeUuid,
                    name: field.name,
                    value: {
                      TAG: "Time",
                      _0: value
                    }
                  });
            }),
          disabled: readOnly,
          wire: wire,
          key: key
        };
        if (button._0 !== undefined) {
          tmp$1.time = Caml_option.valFromOption(button._0);
        }
        return React.createElement("div", {
                    key: key,
                    className: styles.field
                  }, React.createElement("div", {
                        className: styles.fieldName
                      }, field.name), React.createElement(JobForm_ReportFieldTimePicker.make, tmp$1));
    
  }
  return React.createElement(JobAttachmentInput.make, {
              fieldName: field.name,
              files: button._0,
              addFile: (function (file) {
                  addFile(field.typeUuid, file);
                }),
              removeFile: (function (uuid) {
                  removeFile(field.typeUuid, uuid);
                }),
              setLock: setLock,
              readOnly: readOnly,
              maxFilesInField: 10,
              showCarouselImage: showCarouselImage,
              key: key
            });
}

var make = JobForm_ReportField;

var $$default = JobForm_ReportField;

export {
  make ,
  $$default as default,
}
/* styles Not a pure module */
