

import * as RCore from "../../libraries/RCore.mjs";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Shared_Lib_IO from "./Shared_Lib_IO.mjs";

function lift(uncancelable) {
  return function (context) {
    uncancelable(context);
  };
}

var IO = {};

var Uncancelable = {
  lift: lift,
  IO: IO
};

function lift$1(cancelable) {
  return function (context) {
    return cancelable(context);
  };
}

var Cancelable = {
  lift: lift$1
};

var SideEffect = {
  Uncancelable: Uncancelable,
  Cancelable: Cancelable
};

var Update = {};

function update(state) {
  return {
          TAG: "Update",
          _0: state
        };
}

function updateWithSideEffect(state, effect) {
  return {
          TAG: "UpdateWithSideEffect",
          _0: state,
          _1: effect
        };
}

function sideEffect(effect) {
  return {
          TAG: "SideEffect",
          _0: effect
        };
}

function updateWithCancelableSideEffect(state, effect) {
  return {
          TAG: "UpdateWithCancelableSideEffect",
          _0: state,
          _1: effect
        };
}

function cancelableSideEffect(effect) {
  return {
          TAG: "CancelableSideEffect",
          _0: effect
        };
}

function updateWithIO(state, io) {
  return {
          TAG: "UpdateWithIO",
          _0: state,
          _1: io
        };
}

function io(io$1) {
  return {
          TAG: "IO",
          _0: io$1
        };
}

var Reducer = {};

function useReducer(reducer, initialState) {
  var reducerWithSideEffects = function (stateAndSideEffects, action) {
    var sideEffects = stateAndSideEffects.sideEffects;
    var update = reducer([
          stateAndSideEffects.state,
          action
        ]);
    if (typeof update !== "object") {
      return stateAndSideEffects;
    }
    switch (update.TAG) {
      case "Update" :
          return {
                  state: update._0,
                  sideEffects: stateAndSideEffects.sideEffects
                };
      case "UpdateWithSideEffect" :
          var sideEffect = update._1;
          return {
                  state: update._0,
                  sideEffects: {
                    contents: Belt_Array.concatMany([
                          sideEffects.contents,
                          [(function (context) {
                                sideEffect(context);
                              })]
                        ])
                  }
                };
      case "SideEffect" :
          var uncancelableSideEffect = update._0;
          return {
                  state: stateAndSideEffects.state,
                  sideEffects: {
                    contents: Belt_Array.concatMany([
                          stateAndSideEffects.sideEffects.contents,
                          [(function (context) {
                                uncancelableSideEffect(context);
                              })]
                        ])
                  }
                };
      case "UpdateWithCancelableSideEffect" :
          var cancelableSideEffect = update._1;
          return {
                  state: update._0,
                  sideEffects: {
                    contents: Belt_Array.concatMany([
                          sideEffects.contents,
                          [(function (context) {
                                return cancelableSideEffect(context);
                              })]
                        ])
                  }
                };
      case "CancelableSideEffect" :
          var cancelableSideEffect$1 = update._0;
          return {
                  state: stateAndSideEffects.state,
                  sideEffects: {
                    contents: Belt_Array.concatMany([
                          stateAndSideEffects.sideEffects.contents,
                          [(function (context) {
                                return cancelableSideEffect$1(context);
                              })]
                        ])
                  }
                };
      case "UpdateWithIO" :
          var ioAction = update._1;
          var sideEffect$1 = function (context) {
            Shared_Lib_IO.unsafeRunAsync(ioAction, (function (result) {
                    context.send(result._0);
                  }));
          };
          return {
                  state: update._0,
                  sideEffects: {
                    contents: Belt_Array.concatMany([
                          stateAndSideEffects.sideEffects.contents,
                          [sideEffect$1]
                        ])
                  }
                };
      case "IO" :
          var ioAction$1 = update._0;
          var sideEffect$2 = function (context) {
            Shared_Lib_IO.unsafeRunAsync(ioAction$1, (function (result) {
                    context.send(result._0);
                  }));
          };
          return {
                  state: stateAndSideEffects.state,
                  sideEffects: {
                    contents: Belt_Array.concatMany([
                          stateAndSideEffects.sideEffects.contents,
                          [sideEffect$2]
                        ])
                  }
                };
      
    }
  };
  var initialStateWithSideEffects_sideEffects = {
    contents: []
  };
  var initialStateWithSideEffects = {
    state: initialState,
    sideEffects: initialStateWithSideEffects_sideEffects
  };
  var match = React.useReducer(reducerWithSideEffects, initialStateWithSideEffects);
  var send = match[1];
  var match$1 = match[0];
  var sideEffects = match$1.sideEffects;
  var state = match$1.state;
  React.useEffect((function () {
          if (sideEffects.contents.length <= 0) {
            return ;
          }
          var cancelers = RCore.$$Array.filterMap(sideEffects.contents, (function (sideEffect) {
                  return sideEffect({
                              state: state,
                              send: (function (x) {
                                  send(x);
                                })
                            });
                }));
          sideEffects.contents = [];
          if (cancelers.length > 0) {
            return (function () {
                      cancelers.forEach(function (func) {
                            func();
                          });
                    });
          }
          
        }), [sideEffects]);
  return [
          state,
          send
        ];
}

var noUpdate = "NoUpdate";

var use = useReducer;

export {
  SideEffect ,
  Update ,
  noUpdate ,
  update ,
  updateWithSideEffect ,
  sideEffect ,
  updateWithCancelableSideEffect ,
  cancelableSideEffect ,
  updateWithIO ,
  io ,
  Reducer ,
  use ,
}
/* react Not a pure module */
