

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Locale from "../../../libraries/Locale.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";

var emptyErrors_userUuid = [];

var emptyErrors_dateFrom = [];

var emptyErrors_dateTo = [];

var emptyErrors = {
  userUuid: emptyErrors_userUuid,
  dateFrom: emptyErrors_dateFrom,
  dateTo: emptyErrors_dateTo
};

var decode = JsonDecode.object(function (field) {
      return {
              userUuid: RCore.$$Option.getOr(field.optional("userUuid", JsonDecode.array(JsonDecode.string)), []),
              dateFrom: RCore.$$Option.getOr(field.optional("dateFrom", JsonDecode.array(JsonDecode.string)), []),
              dateTo: RCore.$$Option.getOr(field.optional("dateTo", JsonDecode.array(JsonDecode.string)), [])
            };
    });

var Errors = {
  emptyErrors: emptyErrors,
  decode: decode
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              errors: field.required("errors", decode)
            };
    });

var ErrorResponse = {
  decode: decode$1
};

var decode$2 = JsonDecode.object(function (field) {
      return {
              teamUuid: field.required("teamUuid", Uuid.decode)
            };
    });

var $$Response = {
  Errors: Errors,
  ErrorResponse: ErrorResponse,
  decode: decode$2
};

function create(wire, teamUuid, userUuid, dateFrom, dateTo) {
  return Backend.decode(Backend.post(undefined, undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Team",
                    VAL: {
                      NAME: "TemporaryWorkers",
                      VAL: {
                        NAME: "Create",
                        VAL: teamUuid
                      }
                    }
                  }
                }, {
                  NAME: "Obj",
                  VAL: {
                    user_uuid: Uuid.toString(userUuid),
                    date_from: Locale.T.isoDate(dateFrom),
                    date_to: Locale.T.isoDate(dateTo)
                  }
                }), decode$2);
}

var Post = {
  $$Response: $$Response,
  create: create
};

export {
  Post ,
}
/* decode Not a pure module */
