

import * as React from "react";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as Shared_Lib_Timer from "../../../../../../shared/lib/Shared_Lib_Timer.mjs";
import * as WeekCalendar_Constants from "../../lib/WeekCalendar_Constants.mjs";
import * as WeekCalendar_CurrentTimeModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_calendar/components/current_time/WeekCalendar_CurrentTime.module.css";

var styles = WeekCalendar_CurrentTimeModuleCss;

function toPx(num) {
  return num.toString() + "px";
}

function calculatePosition(wire, businessHours, columnWidth, period) {
  var now = Locale.T.now(wire);
  if (!Locale.T.withinInterval(now, period)) {
    return ;
  }
  var weekday = Locale.T.isoWeekday(now);
  var minutes = (function (__x) {
        return Locale.T.dateToMinutes(wire, __x);
      })(now);
  var left = columnWidth * weekday - 2.0;
  if (businessHours !== undefined) {
    var start = businessHours.start;
    var minPx = Math.imul(Locale.T.Schedule.startDurationToHours(start), WeekCalendar_Constants.sizeOfHourPx);
    var maxPx = Math.imul(Locale.T.Schedule.finishDurationToHours(businessHours.finish), WeekCalendar_Constants.sizeOfHourPx);
    var startRounded = Math.imul(Locale.T.Schedule.startDurationToHours(start), WeekCalendar_Constants.minutesInHour);
    var top = (minutes - startRounded | 0) * WeekCalendar_Constants.sizeOfMinutePx;
    if (top >= 0.0 && top <= (maxPx - minPx | 0)) {
      return {
              left: toPx(left),
              top: toPx(top),
              width: toPx(columnWidth + 2.0)
            };
    } else {
      return ;
    }
  }
  var top$1 = minutes * WeekCalendar_Constants.sizeOfMinutePx;
  return {
          left: toPx(left),
          top: toPx(top$1),
          width: toPx(columnWidth + 2.0)
        };
}

function WeekCalendar_CurrentTime(Props) {
  var columnWidth = Props.columnWidth;
  var wire = AppContext.useWire();
  var businessHours = Schedule_State.useBusinessHours();
  var period = Schedule_State.usePeriod();
  var match = React.useState(function () {
        return calculatePosition(wire, businessHours, columnWidth, period);
      });
  var setStyle = match[1];
  var style = match[0];
  React.useEffect((function () {
          setStyle(function (param) {
                return calculatePosition(wire, businessHours, columnWidth, period);
              });
          return Shared_Lib_Timer.repeat(60000, (function () {
                        setStyle(function (param) {
                              return calculatePosition(wire, businessHours, columnWidth, period);
                            });
                      }));
        }), [
        businessHours,
        columnWidth,
        period
      ]);
  if (style !== undefined) {
    return React.createElement("div", {
                className: styles.line,
                style: style
              });
  } else {
    return null;
  }
}

var make = WeekCalendar_CurrentTime;

export {
  make ,
}
/* styles Not a pure module */
