


function fromCode(code) {
  if (code >= 300) {
    if (code >= 400) {
      if (code >= 511) {
        return {
                TAG: "Else",
                _0: code
              };
      }
      switch (code) {
        case 400 :
            return "BadRequest";
        case 401 :
            return "Unauthorized";
        case 402 :
            return "PaymentRequired";
        case 403 :
            return "Forbidden";
        case 404 :
            return "NotFound";
        case 405 :
            return "MethodNotAllowed";
        case 406 :
            return "NotAcceptable";
        case 407 :
            return "ProxyAuthenticationRequired";
        case 408 :
            return "RequestTimeout";
        case 409 :
            return "Conflict";
        case 410 :
            return "Gone";
        case 411 :
            return "LengthRequired";
        case 412 :
            return "PreconditionFailed";
        case 413 :
            return "PayloadTooLarge";
        case 414 :
            return "URITooLong";
        case 415 :
            return "UnsupportedMediaType";
        case 416 :
            return "RangeNotSatisfiable";
        case 417 :
            return "ExpectationFailed";
        case 418 :
            return "Teapot";
        case 421 :
            return "MisredirectedRequest";
        case 422 :
            return "UnprocessableEntity";
        case 423 :
            return "Locked";
        case 426 :
            return "UpgradeRequired";
        case 428 :
            return "PreconditionRequired";
        case 429 :
            return "TooManyRequests";
        case 431 :
            return "RequestsHeaderFieldsTooLarge";
        case 451 :
            return "UnavailableForLegalReasons";
        case 419 :
        case 420 :
        case 424 :
        case 425 :
        case 427 :
        case 430 :
        case 432 :
        case 433 :
        case 434 :
        case 435 :
        case 436 :
        case 437 :
        case 438 :
        case 439 :
        case 440 :
        case 441 :
        case 442 :
        case 443 :
        case 444 :
        case 445 :
        case 446 :
        case 447 :
        case 448 :
        case 449 :
        case 450 :
        case 452 :
        case 453 :
        case 454 :
        case 455 :
        case 456 :
        case 457 :
        case 458 :
        case 459 :
        case 460 :
        case 461 :
        case 462 :
        case 463 :
        case 464 :
        case 465 :
        case 466 :
        case 467 :
        case 468 :
        case 469 :
        case 470 :
        case 471 :
        case 472 :
        case 473 :
        case 474 :
        case 475 :
        case 476 :
        case 477 :
        case 478 :
        case 479 :
        case 480 :
        case 481 :
        case 482 :
        case 483 :
        case 484 :
        case 485 :
        case 486 :
        case 487 :
        case 488 :
        case 489 :
        case 490 :
        case 491 :
        case 492 :
        case 493 :
        case 494 :
        case 495 :
        case 496 :
        case 497 :
        case 498 :
        case 499 :
            return {
                    TAG: "Else",
                    _0: code
                  };
        case 500 :
            return "InternalServerError";
        case 501 :
            return "NotImplemented";
        case 502 :
            return "BadGateway";
        case 503 :
            return "ServiceUnavailble";
        case 504 :
            return "GatewayTimeout";
        case 505 :
            return "HTTPVersionNotSupported";
        case 506 :
            return "VariantAlsoNegotiates";
        case 507 :
            return "InsufficientStorage";
        case 508 :
            return "LoopDetected";
        case 509 :
            return "NotExtended";
        case 510 :
            return "NetworkAuthenticationRequired";
        
      }
    } else {
      if (code >= 309) {
        return {
                TAG: "Else",
                _0: code
              };
      }
      switch (code) {
        case 300 :
            return "MultipleChoices";
        case 301 :
            return "MovedPermanently";
        case 302 :
            return "Found";
        case 303 :
            return "SeeOther";
        case 304 :
            return "NotModified";
        case 305 :
            return "UseProxy";
        case 306 :
            return "SwitchProxy";
        case 307 :
            return "TemporaryRedirect";
        case 308 :
            return "PermanentRedirect";
        
      }
    }
  } else {
    switch (code) {
      case 200 :
          return "Ok'";
      case 201 :
          return "Created";
      case 202 :
          return "Accepted";
      case 203 :
          return "NotAuthoritativeInformation";
      case 204 :
          return "NoContent";
      case 205 :
          return "ResetContent";
      case 206 :
          return "PartialContent";
      case 207 :
          return "MultiStatus";
      case 208 :
          return "AlreadyReported";
      case 226 :
          return "IMUsed";
      default:
        return {
                TAG: "Else",
                _0: code
              };
    }
  }
}

function toCode(status) {
  if (typeof status === "object") {
    return status._0;
  }
  switch (status) {
    case "Ok'" :
        return 200;
    case "Created" :
        return 201;
    case "Accepted" :
        return 202;
    case "NotAuthoritativeInformation" :
        return 203;
    case "NoContent" :
        return 204;
    case "ResetContent" :
        return 205;
    case "PartialContent" :
        return 206;
    case "MultiStatus" :
        return 207;
    case "AlreadyReported" :
        return 208;
    case "IMUsed" :
        return 226;
    case "MultipleChoices" :
        return 300;
    case "MovedPermanently" :
        return 301;
    case "Found" :
        return 302;
    case "SeeOther" :
        return 303;
    case "NotModified" :
        return 304;
    case "UseProxy" :
        return 305;
    case "SwitchProxy" :
        return 306;
    case "TemporaryRedirect" :
        return 307;
    case "PermanentRedirect" :
        return 308;
    case "BadRequest" :
        return 400;
    case "Unauthorized" :
        return 401;
    case "PaymentRequired" :
        return 402;
    case "Forbidden" :
        return 403;
    case "NotFound" :
        return 404;
    case "MethodNotAllowed" :
        return 405;
    case "NotAcceptable" :
        return 406;
    case "ProxyAuthenticationRequired" :
        return 407;
    case "RequestTimeout" :
        return 408;
    case "Conflict" :
        return 409;
    case "Gone" :
        return 410;
    case "LengthRequired" :
        return 411;
    case "PreconditionFailed" :
        return 412;
    case "PayloadTooLarge" :
        return 413;
    case "URITooLong" :
        return 414;
    case "UnsupportedMediaType" :
        return 415;
    case "RangeNotSatisfiable" :
        return 416;
    case "ExpectationFailed" :
        return 417;
    case "Teapot" :
        return 418;
    case "MisredirectedRequest" :
        return 421;
    case "UnprocessableEntity" :
        return 422;
    case "Locked" :
        return 423;
    case "UpgradeRequired" :
        return 426;
    case "PreconditionRequired" :
        return 428;
    case "TooManyRequests" :
        return 429;
    case "RequestsHeaderFieldsTooLarge" :
        return 431;
    case "UnavailableForLegalReasons" :
        return 451;
    case "InternalServerError" :
        return 500;
    case "NotImplemented" :
        return 501;
    case "BadGateway" :
        return 502;
    case "ServiceUnavailble" :
        return 503;
    case "GatewayTimeout" :
        return 504;
    case "HTTPVersionNotSupported" :
        return 505;
    case "VariantAlsoNegotiates" :
        return 506;
    case "InsufficientStorage" :
        return 507;
    case "LoopDetected" :
        return 508;
    case "NotExtended" :
        return 509;
    case "NetworkAuthenticationRequired" :
        return 510;
    
  }
}

function isSuccess(status) {
  if (typeof status === "object") {
    return false;
  }
  switch (status) {
    case "Ok'" :
    case "Created" :
    case "Accepted" :
    case "NotAuthoritativeInformation" :
    case "NoContent" :
    case "ResetContent" :
    case "PartialContent" :
    case "MultiStatus" :
    case "AlreadyReported" :
    case "IMUsed" :
        return true;
    default:
      return false;
  }
}

function isRedirect(status) {
  if (typeof status === "object") {
    return false;
  }
  switch (status) {
    case "MultipleChoices" :
    case "MovedPermanently" :
    case "Found" :
    case "SeeOther" :
    case "NotModified" :
    case "UseProxy" :
    case "SwitchProxy" :
    case "TemporaryRedirect" :
    case "PermanentRedirect" :
        return true;
    default:
      return false;
  }
}

function isClientError(status) {
  if (typeof status === "object") {
    return false;
  }
  switch (status) {
    case "BadRequest" :
    case "Unauthorized" :
    case "PaymentRequired" :
    case "Forbidden" :
    case "NotFound" :
    case "MethodNotAllowed" :
    case "NotAcceptable" :
    case "ProxyAuthenticationRequired" :
    case "RequestTimeout" :
    case "Conflict" :
    case "Gone" :
    case "LengthRequired" :
    case "PreconditionFailed" :
    case "PayloadTooLarge" :
    case "URITooLong" :
    case "UnsupportedMediaType" :
    case "RangeNotSatisfiable" :
    case "ExpectationFailed" :
    case "Teapot" :
    case "MisredirectedRequest" :
    case "UnprocessableEntity" :
    case "Locked" :
    case "UpgradeRequired" :
    case "PreconditionRequired" :
    case "TooManyRequests" :
    case "RequestsHeaderFieldsTooLarge" :
    case "UnavailableForLegalReasons" :
        return true;
    default:
      return false;
  }
}

function isServerError(status) {
  if (typeof status === "object") {
    return false;
  }
  switch (status) {
    case "InternalServerError" :
    case "NotImplemented" :
    case "BadGateway" :
    case "ServiceUnavailble" :
    case "GatewayTimeout" :
    case "HTTPVersionNotSupported" :
    case "VariantAlsoNegotiates" :
    case "InsufficientStorage" :
    case "LoopDetected" :
    case "NotExtended" :
    case "NetworkAuthenticationRequired" :
        return true;
    default:
      return false;
  }
}

export {
  fromCode ,
  toCode ,
  isSuccess ,
  isRedirect ,
  isClientError ,
  isServerError ,
}
/* No side effect */
