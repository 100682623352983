

import * as El from "../../../libraries/El.mjs";
import * as Icon from "../../../Icon.mjs";
import * as React from "react";
import * as Optional from "../Optional.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CurrencyText from "../../inputs/CurrencyText.mjs";
import * as CatalogModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/Catalog/Catalog.module.css";

var styles = CatalogModuleCss;

function ServiceItem(Props) {
  var ctx = Props.ctx;
  var name = Props.name;
  var description = Props.description;
  var price = Props.price;
  var currency = Props.currency;
  var archived = Props.archived;
  var onRemove = Props.onRemove;
  var tags = Props.tags;
  var disabledOpt = Props.disabled;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var onRemoveClick = function (e) {
    e.preventDefault();
    onRemove();
  };
  var itemCN = archived ? El.Cn.concat(styles.selectedItem, styles.archived) : styles.selectedItem;
  return React.createElement("div", {
              className: itemCN
            }, React.createElement("div", {
                  className: styles.row
                }, React.createElement("div", {
                      className: styles.itemLabel
                    }, React.createElement("i", {
                          className: Icon.style("lg", undefined, "wrench")
                        })), React.createElement("div", {
                      className: styles.itemInfo
                    }, React.createElement("div", {
                          className: styles.header
                        }, name), description !== undefined ? React.createElement("div", {
                            className: styles.description
                          }, description) : null), React.createElement("div", {
                      className: styles.itemInfo
                    }, price !== undefined ? React.createElement(CurrencyText.make, {
                            ctx: ctx,
                            currency: currency,
                            value: price.toString()
                          }) : null), React.createElement(Optional.make, {
                      show: !disabled,
                      children: React.createElement("div", {
                            className: styles.itemDelete,
                            onClick: onRemoveClick
                          }, React.createElement("i", {
                                className: Icon.style(undefined, undefined, "times")
                              }))
                    })), tags !== undefined ? Caml_option.valFromOption(tags) : null);
}

var make = ServiceItem;

export {
  make ,
}
/* styles Not a pure module */
