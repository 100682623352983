import { connect } from 'react-redux'
import { startEngine, toggleFocusedJob } from 'planado/actions/map'

import Viewport from 'planado/components/map/viewport'

const mapStateToProps = (
  { engineChannel, mapType, context, mapApiKey },
  { onJobClick }
) => ({
  type: mapType,
  channel: engineChannel,
  apiKey: mapApiKey,
  context,
  onJobClick
})

const mapDispatchToProps = dispatch => ({
  onEngineStart: engine => dispatch(startEngine(engine)),
  onWorkersJobClick: job => dispatch(toggleFocusedJob(job))
})

const ViewportLink = connect(mapStateToProps, mapDispatchToProps)(Viewport)

export default ViewportLink
