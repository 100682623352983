

import * as El from "../../../libraries/El.mjs";
import * as Link from "../../../components/common/Link/Link.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as UI_Icon from "../../../ui/UI_Icon.mjs";
import * as Optional from "../../../components/common/Optional.mjs";
import * as FormInput from "../../../components/inputs/FormInput.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ClientSelect from "../../../components/inputs/ClientSelect.mjs";
import * as Types_Contact from "../../../types/Types_Contact.mjs";
import * as UI_ContactList from "../../../ui/UI_ContactList.mjs";
import * as Pages_Site_Types from "./Pages_Site_Types.mjs";
import * as Pages_Site_AddressBlock from "./Pages_Site_AddressBlock.mjs";

var externalId$p = Utils.Translations.t("js.sites.show.labels.external_id");

var name$p = Utils.Translations.t("js.sites.show.labels.name");

var clientId$p = Utils.Translations.tr("js.sites.show.labels.client_id");

var goToClient$p = Utils.Translations.tr("js.sites.show.labels.go_to_client");

var description$p = Utils.Translations.t("js.sites.show.labels.description");

function Pages_Site_Body$NameBlock(Props) {
  var state = Props.state;
  var setState = Props.setState;
  var ctx = Props.ctx;
  var site = state.site;
  return React.createElement(React.Fragment, undefined, React.createElement(FormInput.make, {
                  _type: "text",
                  value: Pages_Site_Types.fieldToString(site.name),
                  onValueChange: (function (value) {
                      setState(function (current) {
                            var init = current.site;
                            return {
                                    canManage: current.canManage,
                                    locked: current.locked,
                                    site: {
                                      uuid: init.uuid,
                                      isNewRecord: init.isNewRecord,
                                      name: value,
                                      description: init.description,
                                      externalId: init.externalId,
                                      showingExternalId: init.showingExternalId,
                                      contacts: init.contacts,
                                      floor: init.floor,
                                      apartment: init.apartment,
                                      addressDescription: init.addressDescription,
                                      entranceNo: init.entranceNo,
                                      clientUuid: init.clientUuid,
                                      clientName: init.clientName,
                                      territoryUuid: init.territoryUuid,
                                      availableTerritories: init.availableTerritories,
                                      createdAt: init.createdAt,
                                      address: init.address,
                                      creator: init.creator
                                    },
                                    addressConstraints: current.addressConstraints,
                                    errors: current.errors
                                  };
                          });
                    }),
                  id: "site_name",
                  readOnly: !state.canManage,
                  name: "site[name]",
                  labelText: name$p(ctx),
                  maxLength: 300,
                  errors: Pages_Site_Types.$$Error.message(state.errors, "name"),
                  wrapperClass: Js_dict.fromList({
                        hd: [
                          "pd-form-group",
                          true
                        ],
                        tl: /* [] */0
                      }),
                  autoFocus: site.isNewRecord
                }), React.createElement(FormInput.make, {
                  _type: "textarea",
                  value: Pages_Site_Types.fieldToString(site.description),
                  onValueChange: (function (value) {
                      setState(function (current) {
                            var init = current.site;
                            return {
                                    canManage: current.canManage,
                                    locked: current.locked,
                                    site: {
                                      uuid: init.uuid,
                                      isNewRecord: init.isNewRecord,
                                      name: init.name,
                                      description: value,
                                      externalId: init.externalId,
                                      showingExternalId: init.showingExternalId,
                                      contacts: init.contacts,
                                      floor: init.floor,
                                      apartment: init.apartment,
                                      addressDescription: init.addressDescription,
                                      entranceNo: init.entranceNo,
                                      clientUuid: init.clientUuid,
                                      clientName: init.clientName,
                                      territoryUuid: init.territoryUuid,
                                      availableTerritories: init.availableTerritories,
                                      createdAt: init.createdAt,
                                      address: init.address,
                                      creator: init.creator
                                    },
                                    addressConstraints: current.addressConstraints,
                                    errors: current.errors
                                  };
                          });
                    }),
                  id: "site_description",
                  readOnly: !state.canManage,
                  name: "site[description]",
                  labelText: description$p(ctx),
                  maxLength: 1000,
                  errors: Pages_Site_Types.$$Error.message(state.errors, "description"),
                  wrapperClass: Js_dict.fromList({
                        hd: [
                          "pd-form-group",
                          true
                        ],
                        tl: /* [] */0
                      })
                }));
}

function Pages_Site_Body$ClientBlock(Props) {
  var state = Props.state;
  var setState = Props.setState;
  var wire = Props.wire;
  var site = state.site;
  var ctx = wire.ctx;
  var uuid = site.clientUuid;
  var uuid$1 = site.clientUuid;
  return React.createElement("div", {
              className: "pd-form-group pd-form-group"
            }, React.createElement("label", {
                  className: "pd-label",
                  htmlFor: "site_client_id"
                }, clientId$p(ctx)), uuid !== undefined ? React.createElement("span", {
                    className: "pd-form-label-action-link-container"
                  }, React.createElement(Link.make, {
                        wire: wire,
                        route: {
                          NAME: "Client",
                          VAL: "Index"
                        },
                        modals: {
                          hd: {
                            NAME: "Client",
                            VAL: {
                              NAME: "Edit",
                              VAL: Caml_option.valFromOption(uuid)
                            }
                          },
                          tl: /* [] */0
                        },
                        className: "pd-form-label-action-link",
                        children: null
                      }, goToClient$p(ctx), El.space, React.createElement(UI_Icon.make, {
                            icon: "external-link-alt"
                          }))) : null, React.createElement(ClientSelect.make, {
                  id: "site-client_uuid",
                  value: uuid$1 !== undefined ? ({
                        TAG: "Client",
                        _0: {
                          TAG: "Organization",
                          _0: {
                            TAG: "Edit",
                            _0: {
                              uuid: Caml_option.valFromOption(uuid$1),
                              name: site.clientName
                            }
                          }
                        }
                      }) : undefined,
                  _type: "organization",
                  onValueChange: (function (v) {
                      var exit = 0;
                      var uuid;
                      var name;
                      var client;
                      if (v !== undefined) {
                        var match = v.value;
                        switch (match.TAG) {
                          case "Organization" :
                              var match$1 = match._0;
                              uuid = match$1.uuid;
                              name = match$1.name;
                              client = v;
                              exit = 2;
                              break;
                          case "Individual" :
                              var match$2 = match._0;
                              uuid = match$2.uuid;
                              name = match$2.name;
                              client = v;
                              exit = 2;
                              break;
                          case "Site" :
                              exit = 1;
                              break;
                          
                        }
                      } else {
                        exit = 1;
                      }
                      switch (exit) {
                        case 1 :
                            return setState(function (current) {
                                        var init = current.site;
                                        return {
                                                canManage: current.canManage,
                                                locked: current.locked,
                                                site: {
                                                  uuid: init.uuid,
                                                  isNewRecord: init.isNewRecord,
                                                  name: init.name,
                                                  description: init.description,
                                                  externalId: init.externalId,
                                                  showingExternalId: init.showingExternalId,
                                                  contacts: [],
                                                  floor: undefined,
                                                  apartment: undefined,
                                                  addressDescription: undefined,
                                                  entranceNo: undefined,
                                                  clientUuid: undefined,
                                                  clientName: undefined,
                                                  territoryUuid: undefined,
                                                  availableTerritories: init.availableTerritories,
                                                  createdAt: init.createdAt,
                                                  address: undefined,
                                                  creator: init.creator
                                                },
                                                addressConstraints: current.addressConstraints,
                                                errors: current.errors
                                              };
                                      });
                        case 2 :
                            return setState(function (current) {
                                        var init = current.site;
                                        return {
                                                canManage: current.canManage,
                                                locked: current.locked,
                                                site: {
                                                  uuid: init.uuid,
                                                  isNewRecord: init.isNewRecord,
                                                  name: init.name,
                                                  description: init.description,
                                                  externalId: init.externalId,
                                                  showingExternalId: init.showingExternalId,
                                                  contacts: client.contacts,
                                                  floor: client.floor,
                                                  apartment: client.apartment,
                                                  addressDescription: client.addressDescription,
                                                  entranceNo: client.entranceNo,
                                                  clientUuid: Caml_option.some(uuid),
                                                  clientName: name,
                                                  territoryUuid: client.territoryUuid,
                                                  availableTerritories: init.availableTerritories,
                                                  createdAt: init.createdAt,
                                                  address: client.address,
                                                  creator: init.creator
                                                },
                                                addressConstraints: current.addressConstraints,
                                                errors: current.errors
                                              };
                                      });
                        
                      }
                    }),
                  readOnly: !state.canManage
                }));
}

function Pages_Site_Body$ExternalIdBlock(Props) {
  var state = Props.state;
  var setState = Props.setState;
  var ctx = Props.ctx;
  var site = state.site;
  return React.createElement(Optional.make, {
              show: site.isNewRecord && site.showingExternalId,
              children: React.createElement(FormInput.make, {
                    _type: "text",
                    value: Pages_Site_Types.fieldToString(site.externalId),
                    onValueChange: (function (value) {
                        setState(function (current) {
                              var init = current.site;
                              return {
                                      canManage: current.canManage,
                                      locked: current.locked,
                                      site: {
                                        uuid: init.uuid,
                                        isNewRecord: init.isNewRecord,
                                        name: init.name,
                                        description: init.description,
                                        externalId: value,
                                        showingExternalId: init.showingExternalId,
                                        contacts: init.contacts,
                                        floor: init.floor,
                                        apartment: init.apartment,
                                        addressDescription: init.addressDescription,
                                        entranceNo: init.entranceNo,
                                        clientUuid: init.clientUuid,
                                        clientName: init.clientName,
                                        territoryUuid: init.territoryUuid,
                                        availableTerritories: init.availableTerritories,
                                        createdAt: init.createdAt,
                                        address: init.address,
                                        creator: init.creator
                                      },
                                      addressConstraints: current.addressConstraints,
                                      errors: current.errors
                                    };
                            });
                      }),
                    id: "site-external_id",
                    readOnly: !state.canManage,
                    name: "site[external_id]",
                    labelText: externalId$p(ctx),
                    errors: Pages_Site_Types.$$Error.message(state.errors, "externalId"),
                    wrapperClass: Js_dict.fromList({
                          hd: [
                            "pd-form-group",
                            true
                          ],
                          tl: /* [] */0
                        })
                  })
            });
}

function Pages_Site_Body$ContactBlock(Props) {
  var state = Props.state;
  var setState = Props.setState;
  return React.createElement(UI_ContactList.make, {
              contacts: state.site.contacts,
              addContact: (function (param) {
                  setState(function (current) {
                        var init = current.site;
                        return {
                                canManage: current.canManage,
                                locked: current.locked,
                                site: {
                                  uuid: init.uuid,
                                  isNewRecord: init.isNewRecord,
                                  name: init.name,
                                  description: init.description,
                                  externalId: init.externalId,
                                  showingExternalId: init.showingExternalId,
                                  contacts: Belt_Array.concatMany([
                                        current.site.contacts,
                                        [Types_Contact.empty]
                                      ]),
                                  floor: init.floor,
                                  apartment: init.apartment,
                                  addressDescription: init.addressDescription,
                                  entranceNo: init.entranceNo,
                                  clientUuid: init.clientUuid,
                                  clientName: init.clientName,
                                  territoryUuid: init.territoryUuid,
                                  availableTerritories: init.availableTerritories,
                                  createdAt: init.createdAt,
                                  address: init.address,
                                  creator: init.creator
                                },
                                addressConstraints: current.addressConstraints,
                                errors: current.errors
                              };
                      });
                }),
              removeContact: (function (idx) {
                  setState(function (current) {
                        var init = current.site;
                        return {
                                canManage: current.canManage,
                                locked: current.locked,
                                site: {
                                  uuid: init.uuid,
                                  isNewRecord: init.isNewRecord,
                                  name: init.name,
                                  description: init.description,
                                  externalId: init.externalId,
                                  showingExternalId: init.showingExternalId,
                                  contacts: current.site.contacts.filter(function (param, i) {
                                        return i !== idx;
                                      }),
                                  floor: init.floor,
                                  apartment: init.apartment,
                                  addressDescription: init.addressDescription,
                                  entranceNo: init.entranceNo,
                                  clientUuid: init.clientUuid,
                                  clientName: init.clientName,
                                  territoryUuid: init.territoryUuid,
                                  availableTerritories: init.availableTerritories,
                                  createdAt: init.createdAt,
                                  address: init.address,
                                  creator: init.creator
                                },
                                addressConstraints: current.addressConstraints,
                                errors: current.errors
                              };
                      });
                }),
              editContact: (function (idx, value) {
                  setState(function (current) {
                        var init = current.site;
                        return {
                                canManage: current.canManage,
                                locked: current.locked,
                                site: {
                                  uuid: init.uuid,
                                  isNewRecord: init.isNewRecord,
                                  name: init.name,
                                  description: init.description,
                                  externalId: init.externalId,
                                  showingExternalId: init.showingExternalId,
                                  contacts: current.site.contacts.map(function (contact, i) {
                                        if (i === idx) {
                                          return value;
                                        } else {
                                          return contact;
                                        }
                                      }),
                                  floor: init.floor,
                                  apartment: init.apartment,
                                  addressDescription: init.addressDescription,
                                  entranceNo: init.entranceNo,
                                  clientUuid: init.clientUuid,
                                  clientName: init.clientName,
                                  territoryUuid: init.territoryUuid,
                                  availableTerritories: init.availableTerritories,
                                  createdAt: init.createdAt,
                                  address: init.address,
                                  creator: init.creator
                                },
                                addressConstraints: current.addressConstraints,
                                errors: current.errors
                              };
                      });
                }),
              readOnly: !state.canManage,
              isFirstContactHidden: false
            });
}

function Pages_Site_Body(Props) {
  var state = Props.state;
  var setState = Props.setState;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  return React.createElement(React.Fragment, undefined, React.createElement("div", undefined, React.createElement(Pages_Site_Body$NameBlock, {
                      state: state,
                      setState: setState,
                      ctx: ctx
                    }), React.createElement(Pages_Site_Body$ClientBlock, {
                      state: state,
                      setState: setState,
                      wire: wire
                    }), React.createElement(Pages_Site_AddressBlock.make, {
                      state: state,
                      setState: setState,
                      wire: wire
                    }), React.createElement(Pages_Site_Body$ExternalIdBlock, {
                      state: state,
                      setState: setState,
                      ctx: ctx
                    })), React.createElement(Pages_Site_Body$ContactBlock, {
                  state: state,
                  setState: setState
                }));
}

var make = Pages_Site_Body;

export {
  make ,
}
/* externalId' Not a pure module */
