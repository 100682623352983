const defaultStatusFilter = {
  scheduled: true,
  published: true,
  en_route: true,
  started: true,
  suspended: true,
  finished: false
}

const defaultEventState = {
  nextLocation: null,
  prevLocation: null
}

const defaultState = {
  historyWorkerUuid: null,
  currentBlockId: null,
  currentLocationId: null,
  focusedJobUuid: null,
  focusedWorkerUuid: null,
  showWorkersWithStaleLocation: false,
  jobStatusFilter: defaultStatusFilter,
  showPanelJobs: true,
  showPanelStatusFilter: false,
  showWorkerJobs: false,
  version: 1,
  events: defaultEventState,
  loadingLocations: false,
  loadingJobs: false,
  locations: [],
  wrappedLocations: [],
  jobs: {},
  viewingHistory: false,
  historyDate: null
}

export default defaultState
