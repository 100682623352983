

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              visible: field.required("visible", JsonDecode.bool),
              orderNo: field.required("orderNo", JsonDecode.$$int),
              errors: [],
              isNew: false
            };
    });

var Skill = {
  decode: decode
};

var decodeEntity = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var decode$1 = JsonDecode.object(function (field) {
      return {
              users: field.required("users", JsonDecode.array(decodeEntity)),
              teams: field.required("teams", JsonDecode.array(decodeEntity)),
              templates: field.required("templates", JsonDecode.array(decodeEntity))
            };
    });

var UsedBy = {
  decode: decode$1
};

export {
  Skill ,
  UsedBy ,
}
/* decode Not a pure module */
