// extracted by mini-css-extract-plugin
export var add = "add-PbGjqgIRwyODkr0tz5_R";
export var container = "container-CPDl5JilhDwq1_ZgVfg0";
export var deleteButton = "delete-button-rikBh9hP3BGVhQkC5SXT";
export var deleteTeam = "delete-team-nSmCCn7LS9dNHZexa0A8";
export var error = "error-Kydxe6jMmt0FeGIbnnxp";
export var errorText = "error-text-spoCfRrAmKBvIE_06RkR";
export var errors = "errors-GdNUvbFTbgv44_JniPI_";
export var fallbackContainer = "fallback-container-TBEn7PbWYIFa_40umAQz";
export var fallbackName = "fallback-name-sNPvkxPiKCZ27H6neoNp";
export var foreman = "foreman-VA97XgHuZhzw0jnLbu9e";
export var foremanOption = "foreman-option-GTk8zK4UGadjGM3AJhSg";
export var input = "input-IdqBNv0BBEVi3ZfhiX0t";
export var selected = "selected-QQSWwfbLrOvKh8aH81u4";
export var teamContainer = "team-container-KctAyotNXLS_5RTaK4Iv";
export var teamHeader = "team-header-sEiBONVggaz_Eh9sQwbi";
export var teamMembers = "team-members-EXu_l04VONqMvH4aibMb";
export var teamName = "team-name-ruB_QGyb5Z3TOLqXjhLT";
export var teamWorker = "team-worker-_QvbmbHPj5rT80VdJe2k";
export var warning = "warning-_sZvk3r6ifvCyUX0yHkZ";
export var warningText = "warning-text-fIaJINbSuqveXlNzpZlA";
export var worker = "worker-m1yEu7bnLYlmc0Oluhdo";
export var workerContainer = "worker-container-VZwemWn6hvaJq3PMPsqz";
export var workerName = "worker-name-NWzG4jxkrktI_J1vMTTE";