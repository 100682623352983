// extracted by mini-css-extract-plugin
export var addButton = "add-button-Rjp9Go5DsGD49ExOSg_e";
export var button = "button-ax_vtSm0fELwcKotNvaW";
export var buttonInvisible = "button-invisible-WLRifbvpbnmS4Y2fv14D";
export var contactName = "contact-name-PvGLVyFoZDQqP9xHDQst";
export var fa = "fa-ZTc2x90wCymCV4ajEhZf";
export var hint = "hint-rKtXVKZK00vyvBGyzJv5";
export var iconAddModel = "icon-add-model-MZff5_8IM5jkcD5VgP1Q";
export var pdFormLabelActionLink = "pd-form-label-action-link-hFsGC_np9mP2nXnYJzua";
export var primary = "primary-joPD2m06jXFUaLAC2zhw";
export var primaryLabel = "primary-label-gQ9d9urNAe1YcX_Papvx";
export var title = "title-JP9eyxT7E9xZQqarDrVw";
export var trashIcon = "trash-icon-Cm8UVD3zmnw4dM8liWmG";
export var wrapper = "wrapper-Ix_TbI5IcgCrjwaPNqho";
export var wrapperPrimary = "wrapper-primary-i2pcV_5QT1b18177EOQ8";