

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Types from "../../../types/Types.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as Types_Date from "../../../types/Types_Date.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Context_Types from "../../../context/Context_Types.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              id: field.required("id", Types.Id.decodeOpaque),
              uuid: field.required("uuid", Uuid.decode),
              serialNo: field.required("serialNo", Types.Id.decodeOpaque),
              scheduledAt: field.required("scheduledAt", JsonDecode.option(JsonDecode.string)),
              startedAt: field.required("startedAt", JsonDecode.option(JsonDecode.string)),
              finishedAt: field.required("finishedAt", JsonDecode.option(JsonDecode.string)),
              resolution: field.required("resolution", JsonDecode.option(JsonDecode.string)),
              assignee: field.required("assignee", JsonDecode.option(JsonDecode.string)),
              template: field.required("template", JsonDecode.option(JsonDecode.string)),
              status: field.required("status", JsonDecode.string),
              address: field.optional("address", JsonDecode.string)
            };
    });

var decode$1 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              address: field.required("address", JsonDecode.option(JsonDecode.string)),
              phone: field.required("phone", JsonDecode.option(JsonDecode.string))
            };
    });

var decode$2 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              template: field.required("template", JsonDecode.option(JsonDecode.string)),
              nextScheduledAt: field.required("nextScheduledAt", Types_Date.decode)
            };
    });

function fromString(listType) {
  if (listType === "jobs_and_rec") {
    return "JobsAndRec";
  } else {
    return "JobsSitesAndRec";
  }
}

function toString(listType) {
  if (listType === "JobsAndRec") {
    return "jobs_and_rec";
  } else {
    return "jobs_sites_and_rec";
  }
}

var ListType = {
  fromString: fromString,
  toString: toString
};

function toString$1(pageType) {
  if (pageType === "Site") {
    return "site";
  } else if (pageType === "Client") {
    return "client";
  } else if (pageType === "RecurringJob") {
    return "recurring_job";
  } else {
    return "template";
  }
}

function fromString$1(pageType) {
  switch (pageType) {
    case "client" :
        return "Client";
    case "recurring_job" :
        return "RecurringJob";
    case "site" :
        return "Site";
    default:
      return "Template";
  }
}

var PageType = {
  toString: toString$1,
  fromString: fromString$1
};

var Organization = {};

var ClientSite = {};

function toString$2(tab) {
  if (tab.NAME === "ClientSite") {
    if (tab.VAL === "Jobs") {
      return "jobs";
    } else {
      return "recurring_jobs";
    }
  }
  var match = tab.VAL;
  if (match === "Sites") {
    return "sites";
  } else if (match === "Jobs") {
    return "jobs";
  } else {
    return "recurring_jobs";
  }
}

var Tab = {
  Organization: Organization,
  ClientSite: ClientSite,
  toString: toString$2
};

function toString$3(fetchType) {
  if (fetchType.NAME === "InitialFetch") {
    return toString(fetchType.VAL);
  } else {
    return toString$2(fetchType.VAL);
  }
}

function emptyState(listType) {
  return {
          jobs: [],
          jobsCount: undefined,
          sites: [],
          sitesCount: undefined,
          recurringJobs: [],
          recurringJobsCount: undefined,
          templates: [],
          page: 1,
          locked: false,
          tab: listType === "JobsAndRec" ? ({
                NAME: "ClientSite",
                VAL: "Jobs"
              }) : ({
                NAME: "Organization",
                VAL: "Jobs"
              })
        };
}

var decode$3 = JsonDecode.object(function (field) {
      return {
              jobs: field.required("jobs", JsonDecode.array(decode)),
              jobsCount: field.required("jobsCount", JsonDecode.$$int),
              sites: field.required("sites", JsonDecode.array(decode$1)),
              sitesCount: field.required("sitesCount", JsonDecode.$$int),
              recurringJobs: field.required("recurringJobs", JsonDecode.array(decode$2)),
              recurringJobsCount: field.required("recurringJobsCount", JsonDecode.$$int),
              templates: field.required("templates", JsonDecode.array(Context_Types.Template.decode))
            };
    });

var Index = {
  decode: decode$3
};

function make(jobs, sites, recurringJobs) {
  return {
          jobs: jobs,
          sites: sites,
          recurringJobs: recurringJobs
        };
}

var Entries = {
  make: make
};

function make$1(uuid, page, pageType, listType) {
  return {
          uuid: uuid,
          page: page,
          pageType: pageType,
          listType: listType
        };
}

function toQuery(options) {
  var uuid = options.uuid;
  var id = uuid !== undefined ? "?uuid=" + Uuid.toString(Caml_option.valFromOption(uuid)) : "?";
  return id + "&page=" + options.page.toString() + "&page_type=" + toString$1(options.pageType) + "&list_type=" + toString$3(options.listType);
}

var Options = {
  make: make$1,
  toQuery: toQuery
};

var Job = {};

var Site = {};

var RecurringJob = {};

var FetchType = {};

export {
  Job ,
  Site ,
  RecurringJob ,
  ListType ,
  PageType ,
  Tab ,
  FetchType ,
  emptyState ,
  Index ,
  Entries ,
  Options ,
}
/* decode Not a pure module */
