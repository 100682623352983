

import * as Backend from "../../libraries/backend/Backend.mjs";
import * as PromiseF from "../../libraries/PromiseF.mjs";

function destroySession(wire) {
  return PromiseF.toIOLazy(function () {
              return Backend.$$delete(undefined, undefined, undefined, wire, {
                          NAME: "API",
                          VAL: "Session"
                        });
            });
}

export {
  destroySession ,
}
/* Backend Not a pure module */
