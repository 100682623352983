

import * as Schedule_Constants from "../../../lib/Schedule_Constants.mjs";

var sizeOfStepPx = 144 / Schedule_Constants.stepsInHour;

var sizeOfMinutePx = sizeOfStepPx / Schedule_Constants.minutesInStep;

var headerHeightPx = 144;

var hoursInDay = Schedule_Constants.hoursInDay;

var daysInWeek = Schedule_Constants.daysInWeek;

var minutesInDay = Schedule_Constants.minutesInDay;

var minutesInStep = Schedule_Constants.minutesInStep;

var dndScheduledJob = Schedule_Constants.dndScheduledJob;

var dndUnscheduledJob = Schedule_Constants.dndUnscheduledJob;

var dndUnassignedJob = Schedule_Constants.dndUnassignedJob;

var sidebarWidth = Schedule_Constants.sidebarWidth;

var numberOfJobsInColumn = 5.0;

var numberOfVisibleJobs = 4.0;

var sizeOfHourPx = 144;

var horizontalCalendarOffsetPx = 80.0;

var jobPreviewWidthPx = 292.0;

var jobPreviewMaxWidthPx = 335.0;

var jobGroupWidthPx = 321.0;

var minutesInHour = 60;

export {
  hoursInDay ,
  daysInWeek ,
  minutesInDay ,
  minutesInStep ,
  dndScheduledJob ,
  dndUnscheduledJob ,
  dndUnassignedJob ,
  sidebarWidth ,
  numberOfJobsInColumn ,
  numberOfVisibleJobs ,
  sizeOfHourPx ,
  sizeOfMinutePx ,
  headerHeightPx ,
  horizontalCalendarOffsetPx ,
  jobPreviewWidthPx ,
  jobPreviewMaxWidthPx ,
  jobGroupWidthPx ,
  minutesInHour ,
}
/* Schedule_Constants Not a pure module */
