import React from 'react'
import { newDictionaryPath } from 'planado/routes.js'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import Link from 'rscrpt/components/common/Link/Link.mjs'

export default withContext(({ ctx }) => (
  <div className="dict-list__header">
    <div className="dict-list__header-content">
      <h1 className="dict-list__header-headline">
        {ctx.t('js.admin.dictionaries.dictionaries')}
      </h1>

      <Link
        wire={ctx.wire}
        pathname={newDictionaryPath}
        className="dict-list__header-btn btn btn-primary btn-outline"
      >
        <span className="fa fa-plus" />
        {ctx.t('js.admin.dictionaries.add_dictionary')}
      </Link>
    </div>
  </div>
))
