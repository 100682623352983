

import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../types/Types.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_DND from "../../../../lib/Schedule_DND.mjs";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as Shared_Lib_Timer from "../../../../../../shared/lib/Shared_Lib_Timer.mjs";
import * as DayTimeline_State from "../../lib/state/DayTimeline_State.mjs";
import * as DayTimeline_Utils from "../../lib/utils/DayTimeline_Utils.mjs";
import * as DayTimeline_Constants from "../../lib/DayTimeline_Constants.mjs";
import * as Schedule_UtilsTimeline from "../../../../lib/utils/Schedule_UtilsTimeline.mjs";
import * as DayTimeline_UnassignedJobPositions from "../../lib/positions/DayTimeline_UnassignedJobPositions.mjs";

var Bar = {};

function make(positions) {
  var bars = {};
  positions.forEach(function (param) {
        var position = param[0];
        var top = position.top.contents;
        position.top.contents = Units.Px.zero;
        var key = Units.Px.toString(top);
        var newJob = [[
            position,
            param[1]
          ]];
        var jobs = Js_dict.get(bars, key);
        if (jobs !== undefined) {
          bars[key] = Belt_Array.concatMany([
                jobs,
                newJob
              ]);
        } else {
          bars[key] = newJob;
        }
      });
  return RCore.$$Array.filterMap(Js_dict.entries(bars), (function (param) {
                var key = Units.Px.fromString(param[0]);
                if (key !== undefined) {
                  return [
                          Caml_option.valFromOption(key),
                          param[1]
                        ];
                }
                
              }));
}

function use() {
  var wire = AppContext.useWire();
  var sizes = DayTimeline_State.useSizes();
  var jobs = Schedule_State.useUnassignedJobs();
  var currentDay = Schedule_State.useCurrentDay();
  var businessHours = Schedule_State.useBusinessHours();
  return React.useMemo((function () {
                var positions = DayTimeline_UnassignedJobPositions.make(wire, businessHours, currentDay, sizes, jobs);
                return make(positions);
              }), [
              jobs,
              businessHours,
              currentDay,
              sizes
            ]);
}

var Bars = {
  make: make,
  use: use
};

function useVertical(unassignedEl) {
  React.useEffect((function () {
          var unassignedEl$1 = Types.ReactRef.toOption(unassignedEl);
          if (unassignedEl$1 === undefined) {
            return ;
          }
          var unassignedEl$2 = Caml_option.valFromOption(unassignedEl$1);
          var onWheel = function ($$event) {
            if (Caml_obj.greaterthan(Units.Px.abs(Units.Px.fromFloat($$event.deltaY)), Units.Px.abs(Units.Px.fromFloat($$event.deltaX)))) {
              $$event.stopPropagation();
              return ;
            }
            
          };
          unassignedEl$2.addEventListener("wheel", onWheel);
          return (function () {
                    unassignedEl$2.removeEventListener("wheel", onWheel);
                  });
        }), []);
}

var Scroll = {
  useVertical: useVertical
};

function use$1(viewportEl, compactMode, toggleMode) {
  var sizes = DayTimeline_State.useSizes();
  var dispatch = Schedule_State.useDispatch();
  var currentDay = Schedule_State.useCurrentDay();
  var businessHours = Schedule_State.useBusinessHours();
  var match = React.useState(function () {
        return false;
      });
  var setIsOver = match[1];
  var isOver = match[0];
  var match$1 = React.useState(function () {
        return function (prim) {
          
        };
      });
  var setHoverDelayHandler = match$1[1];
  var hoverDelayHandler = match$1[0];
  React.useEffect((function () {
          if (isOver && compactMode) {
            setHoverDelayHandler(function (param) {
                  return Shared_Lib_Timer.delay(2000, toggleMode);
                });
          } else {
            hoverDelayHandler();
          }
        }), [
        compactMode,
        isOver
      ]);
  var onDrop = function (param) {
    var contentEl = Schedule_UtilsTimeline.contentEl(viewportEl);
    if (contentEl !== undefined) {
      var match = Schedule_DND.DND.Context.payload(param.payload);
      var uuid = match.uuid;
      if (param.payloadType === DayTimeline_Constants.dndScheduledJob) {
        dispatch({
              TAG: "Jobs",
              _0: {
                TAG: "JobUnassigned",
                _0: uuid
              }
            });
      } else {
        var coordinate = Schedule_UtilsTimeline.ConvertPoint.fromPageToNode(Caml_option.valFromOption(contentEl), param.dragPreviewOffset);
        var scheduledAt = DayTimeline_Utils.FindByCoordinateInTimeline.scheduledAt(businessHours, currentDay, sizes, coordinate);
        dispatch({
              TAG: "Jobs",
              _0: {
                TAG: "JobScheduled",
                _0: uuid,
                _1: scheduledAt
              }
            });
      }
    }
    setIsOver(function (param) {
          return false;
        });
  };
  var dropRef = Schedule_DND.DND.Droppable.use(undefined, (function () {
          setIsOver(function (param) {
                return true;
              });
        }), (function () {
          setIsOver(function (param) {
                return false;
              });
        }), [
        DayTimeline_Constants.dndScheduledJob,
        DayTimeline_Constants.dndUnassignedJob
      ], onDrop, undefined);
  return [
          dropRef,
          isOver
        ];
}

var Drop = {
  use: use$1
};

export {
  Bar ,
  Bars ,
  Scroll ,
  Drop ,
}
/* Types Not a pure module */
