

import * as El from "../../../../../libraries/El.mjs";
import * as Hooks from "../../../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../types/Types.mjs";
import * as Units from "../../../../../types/Units.mjs";
import * as React from "react";
import * as Portal from "../../../../../Portal.mjs";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_PopoverModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/popovers/popover/Schedule_Popover.module.css";

function x(clickTargetPosition) {
  if (clickTargetPosition.NAME === "DomRect") {
    return Units.Px.fromFloat(clickTargetPosition.VAL.x);
  } else {
    return clickTargetPosition.VAL[0];
  }
}

function y(clickTargetPosition) {
  if (clickTargetPosition.NAME === "DomRect") {
    return Units.Px.fromFloat(clickTargetPosition.VAL.y);
  } else {
    return clickTargetPosition.VAL[1];
  }
}

function width(clickTargetPosition) {
  if (clickTargetPosition.NAME === "DomRect") {
    return Units.Px.fromFloat(clickTargetPosition.VAL.width);
  } else {
    return Units.Px.zero;
  }
}

function height(clickTargetPosition) {
  if (clickTargetPosition.NAME === "DomRect") {
    return Units.Px.fromFloat(clickTargetPosition.VAL.height);
  } else {
    return Units.Px.zero;
  }
}

function mapHorizontalPosition(hasSpaceInLeft, hasSpaceInRight, popoverPosition) {
  switch (popoverPosition) {
    case "LeftTop" :
        if (hasSpaceInLeft) {
          return "LeftTop";
        } else if (hasSpaceInRight) {
          return "RightTop";
        } else {
          return "Unknown";
        }
    case "Left" :
        if (hasSpaceInLeft) {
          return "Left";
        } else if (hasSpaceInRight) {
          return "Right";
        } else {
          return "Unknown";
        }
    case "LeftBottom" :
        if (hasSpaceInLeft) {
          return "LeftBottom";
        } else if (hasSpaceInRight) {
          return "RightBottom";
        } else {
          return "Unknown";
        }
    case "RightTop" :
        if (hasSpaceInRight) {
          return "RightTop";
        } else if (hasSpaceInLeft) {
          return "LeftTop";
        } else {
          return "Unknown";
        }
    case "Right" :
        if (hasSpaceInRight) {
          return "Right";
        } else if (hasSpaceInLeft) {
          return "Left";
        } else {
          return "Unknown";
        }
    case "RightBottom" :
        if (hasSpaceInRight) {
          return "RightBottom";
        } else if (hasSpaceInLeft) {
          return "LeftBottom";
        } else {
          return "Unknown";
        }
    case "Unknown" :
        return "Unknown";
    
  }
}

function calc(popoverPosition, clickTargetPosition, contentPosition) {
  var elementX = x(clickTargetPosition);
  var elementY = y(clickTargetPosition);
  var elementWidth = width(clickTargetPosition);
  var elementHeight = height(clickTargetPosition);
  var contentWidth = Units.Px.fromFloat(contentPosition.width);
  var contentHeight = Units.Px.fromFloat(contentPosition.height);
  var documentPosition = document.documentElement.getBoundingClientRect();
  var documentWidth = Units.Px.fromFloat(documentPosition.width);
  var documentHeight = Units.Px.fromFloat(documentPosition.height);
  var hasSpaceInTop = Units.Px.equalOrMoreThan(Units.Px.minus(elementY, contentHeight), Units.Px.zero);
  var hasSpaceInRight = Units.Px.equalOrLessThan(Units.Px.plus(Units.Px.plus(elementX, elementWidth), contentWidth), documentWidth);
  var hasSpaceInBottom = Units.Px.equalOrLessThan(Units.Px.plus(Units.Px.plus(elementY, elementHeight), contentHeight), documentHeight);
  var hasSpaceInLeft = Units.Px.equalOrMoreThan(Units.Px.minus(elementX, contentWidth), Units.Px.zero);
  var popoverPosition$1 = mapHorizontalPosition(hasSpaceInLeft, hasSpaceInRight, popoverPosition);
  switch (popoverPosition$1) {
    case "LeftTop" :
        if (hasSpaceInBottom) {
          return "LeftTop";
        } else if (hasSpaceInTop) {
          return "LeftBottom";
        } else {
          return "Unknown";
        }
    case "Left" :
        if (hasSpaceInTop && hasSpaceInBottom) {
          return "Left";
        } else {
          return "Unknown";
        }
    case "LeftBottom" :
        if (hasSpaceInTop) {
          return "LeftBottom";
        } else if (hasSpaceInBottom) {
          return "LeftTop";
        } else {
          return "Unknown";
        }
    case "RightTop" :
        if (hasSpaceInBottom) {
          return "RightTop";
        } else if (hasSpaceInTop) {
          return "RightBottom";
        } else {
          return "Unknown";
        }
    case "Right" :
        if (hasSpaceInTop && hasSpaceInBottom) {
          return "Right";
        } else {
          return "Unknown";
        }
    case "RightBottom" :
        if (hasSpaceInTop) {
          return "RightBottom";
        } else if (hasSpaceInBottom) {
          return "RightTop";
        } else {
          return "Unknown";
        }
    case "Unknown" :
        return "Unknown";
    
  }
}

var initial = {
  left: Units.Px.toString(Units.Px.zero),
  top: Units.Px.toString(Units.Px.zero)
};

var horizontalOffset = Units.Px.fromInt(3);

var verticalOffset = Units.Px.fromInt(1);

var verticalWindowOffset = Units.Px.fromInt(5);

function leftPosition(clickCoordinate, clickTargetPosition, contentPosition, position) {
  var elementX = x(clickTargetPosition);
  var elementWidth = width(clickTargetPosition);
  var contentWidth = Units.Px.fromFloat(contentPosition.width);
  switch (position) {
    case "LeftTop" :
    case "Left" :
    case "LeftBottom" :
        break;
    case "Unknown" :
        if (clickCoordinate !== undefined) {
          return clickCoordinate[0];
        } else {
          return elementX;
        }
    default:
      return Units.Px.plus(Units.Px.plus(elementX, elementWidth), horizontalOffset);
  }
  return Units.Px.minus(Units.Px.minus(elementX, contentWidth), horizontalOffset);
}

function verticalPosition(clickTargetPosition, contentPosition, position) {
  var documentPosition = document.documentElement.getBoundingClientRect();
  var documentHeight = Units.Px.fromFloat(documentPosition.height);
  var elementY = y(clickTargetPosition);
  var elementHeight = height(clickTargetPosition);
  var halfElementY = Units.Px.plus(elementY, Units.Px.divideByInt(height(clickTargetPosition), 2));
  var contentY = Units.Px.fromFloat(contentPosition.height);
  var halfContentY = Units.Px.divideByInt(contentY, 2);
  var contentHeight = Units.Px.fromFloat(contentPosition.height);
  var position$1;
  var exit = 0;
  switch (position) {
    case "LeftTop" :
    case "RightTop" :
        exit = 1;
        break;
    case "Left" :
    case "Right" :
        exit = 3;
        break;
    case "LeftBottom" :
    case "RightBottom" :
        exit = 2;
        break;
    case "Unknown" :
        position$1 = Units.Px.lessThan(Units.Px.plus(Units.Px.plus(elementY, elementHeight), contentHeight), documentHeight) ? ({
              TAG: "BottomPosition",
              _0: Units.Px.minus(Units.Px.minus(Units.Px.minus(Units.Px.minus(documentHeight, elementY), elementHeight), contentY), verticalOffset)
            }) : ({
              TAG: "TopPosition",
              _0: Units.Px.minus(Units.Px.minus(elementY, contentY), verticalOffset)
            });
        break;
    
  }
  switch (exit) {
    case 1 :
        position$1 = Units.Px.moreThan(elementY, Units.Px.minus(documentHeight, elementY)) ? ({
              TAG: "BottomPosition",
              _0: Units.Px.minus(Units.Px.minus(documentHeight, elementY), contentY)
            }) : ({
              TAG: "TopPosition",
              _0: elementY
            });
        break;
    case 2 :
        position$1 = {
          TAG: "BottomPosition",
          _0: Units.Px.minus(Units.Px.minus(documentHeight, elementY), elementHeight)
        };
        break;
    case 3 :
        position$1 = Units.Px.moreThan(Units.Px.minus(Units.Px.minus(halfElementY, halfContentY), contentHeight), documentHeight) ? ({
              TAG: "TopPosition",
              _0: Units.Px.minus(documentHeight, contentHeight)
            }) : ({
              TAG: "TopPosition",
              _0: Units.Px.minus(halfElementY, halfContentY)
            });
        break;
    
  }
  if (position$1.TAG === "TopPosition") {
    if (Units.Px.lessThan(position$1._0, Units.Px.zero)) {
      return {
              TAG: "TopPosition",
              _0: verticalWindowOffset
            };
    } else {
      return position$1;
    }
  } else if (Units.Px.lessThan(position$1._0, Units.Px.zero)) {
    return {
            TAG: "BottomPosition",
            _0: verticalWindowOffset
          };
  } else {
    return position$1;
  }
}

function make(clickCoordinate, clickTargetPosition, contentPosition, position) {
  var verticalPosition$1 = verticalPosition(clickTargetPosition, contentPosition, position);
  var left = leftPosition(clickCoordinate, clickTargetPosition, contentPosition, position);
  var tmp;
  tmp = verticalPosition$1.TAG === "TopPosition" ? "auto" : Units.Px.toString(verticalPosition$1._0);
  var tmp$1;
  tmp$1 = verticalPosition$1.TAG === "TopPosition" ? Units.Px.toString(verticalPosition$1._0) : "auto";
  return {
          bottom: tmp,
          left: Units.Px.toString(left),
          top: tmp$1
        };
}

var styles = Schedule_PopoverModuleCss;

function Schedule_Popover(Props) {
  var transparentOpt = Props.transparent;
  var clickCoordinate = Props.clickCoordinate;
  var clickTargetPosition = Props.clickTargetPosition;
  var popoverPositionOpt = Props.popoverPosition;
  var children = Props.children;
  var transparent = transparentOpt !== undefined ? transparentOpt : false;
  var popoverPosition = popoverPositionOpt !== undefined ? popoverPositionOpt : "RightTop";
  var match = AppContext.useWire();
  var match$1 = React.useState(function () {
        return [
                initial,
                undefined
              ];
      });
  var setState = match$1[1];
  var match$2 = match$1[0];
  var containerRef = Types.ReactRef.use();
  Hooks.useOnMount(function () {
        var content = RCore.$$Option.flatMap(Types.ReactRef.toOption(containerRef), (function (prim) {
                return Caml_option.nullable_to_opt(prim.firstElementChild);
              }));
        if (content === undefined) {
          return ;
        }
        var contentPosition = Caml_option.valFromOption(content).getBoundingClientRect();
        var position = calc(popoverPosition, clickTargetPosition, contentPosition);
        var style = make(clickCoordinate, clickTargetPosition, contentPosition, position);
        setState(function (param) {
              return [
                      style,
                      position
                    ];
            });
      });
  var className = El.Cn.concat(styles.container, transparent || RCore.$$Option.isNone(match$2[1]) ? styles.transparent : "");
  return React.createElement(Portal.make, {
              root: match.footer,
              children: React.createElement("div", {
                    ref: containerRef,
                    className: className,
                    style: match$2[0]
                  }, children)
            });
}

var make$1 = React.memo(Schedule_Popover);

var ClickTargetPosition = {};

var PopoverPosition = {};

export {
  ClickTargetPosition ,
  PopoverPosition ,
  make$1 as make,
}
/* initial Not a pure module */
