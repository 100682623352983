

import * as Radio from "../../../inputs/Radio.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Checkbox from "../../../inputs/Checkbox.mjs";
import * as Optional from "../../../common/Optional.mjs";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as UserShowModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/user/UserShow.module.css";

var delete$p = Utils.Translations.tr("js.admin.users.form.options.delete");

var readWrite$p = Utils.Translations.tr("js.admin.users.form.options.read_write");

var readOnly$p = Utils.Translations.tr("js.admin.users.form.options.read_only");

var styles = UserShowModuleCss;

function UserPermissionInput(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var deleteModeOpt = Props.deleteMode;
  var disabled = Props.disabled;
  var paid = Props.paid;
  var label = Props.label;
  var deleteMode = deleteModeOpt !== undefined ? deleteModeOpt : false;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var match$1 = React.useState(function () {
        return value;
      });
  var setPrevValue = match$1[1];
  var prevValue = match$1[0];
  var denied = value === "Denied";
  var readOnlyDisabled = disabled || denied;
  var readWriteDisabled = !paid || disabled || denied;
  var onChange$1 = function (newValue) {
    setPrevValue(function (param) {
          return value;
        });
    onChange(newValue);
  };
  var onCheckboxToggle = function (checked) {
    var value = checked ? (
        prevValue === "Denied" ? "Read" : prevValue
      ) : "Denied";
    onChange$1(value);
  };
  return React.createElement("div", {
              className: styles.inputRow
            }, React.createElement("div", {
                  className: styles.inputLabel
                }), React.createElement("div", {
                  className: "form-input-item form-input-flex"
                }, React.createElement("div", {
                      className: "form-input-checkbox"
                    }, React.createElement(Checkbox.make, {
                          onToggle: onCheckboxToggle,
                          checked: !denied,
                          label: label,
                          disabled: disabled,
                          wrapperClasses: Js_dict.fromList({
                                hd: [
                                  "react-checkbox_users",
                                  true
                                ],
                                tl: /* [] */0
                              })
                        })), React.createElement("div", {
                      className: "form-input-radio"
                    }, React.createElement(Optional.make, {
                          show: deleteMode,
                          children: React.createElement(Radio.make, {
                                onChange: (function (param) {
                                    onChange$1("Delete");
                                  }),
                                checked: value === "Delete",
                                wrapperClass: "radio",
                                labelClass: readWriteDisabled ? "disabled" : "",
                                disabled: readWriteDisabled,
                                children: delete$p(ctx)
                              })
                        }), React.createElement(Radio.make, {
                          onChange: (function (param) {
                              onChange$1("Write");
                            }),
                          checked: value === "Write",
                          wrapperClass: "radio",
                          labelClass: readWriteDisabled ? "disabled" : "",
                          disabled: readWriteDisabled,
                          children: readWrite$p(ctx)
                        }), React.createElement(Radio.make, {
                          onChange: (function (param) {
                              onChange$1("Read");
                            }),
                          checked: value === "Read",
                          wrapperClass: "radio",
                          labelClass: readOnlyDisabled ? "disabled" : "",
                          disabled: readOnlyDisabled,
                          children: readOnly$p(ctx)
                        }))));
}

var make = UserPermissionInput;

export {
  make ,
}
/* delete' Not a pure module */
