

import * as Fun from "../../../libraries/Fun.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as React from "react";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as GoogleMaps from "../../../bindings/googleMaps/GoogleMaps.mjs";
import * as YandexMaps from "../../../bindings/yandexMaps/YandexMaps.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as MapReContext from "./MapReContext.mjs";
import * as Types_Spatial from "../../../types/Types_Spatial.mjs";
import * as MapReComponent from "./MapReComponent.mjs";

function toString(strokeStyle) {
  return "dash";
}

var StrokeStyle = {
  toString: toString
};

function MapRePolylineComponent$YandexPolyline(Props) {
  var coordinates = Props.coordinates;
  var strokeWidth = Props.strokeWidth;
  var strokeColor = Props.strokeColor;
  var strokeOpacity = Props.strokeOpacity;
  var strokeStyle = Props.strokeStyle;
  var hintContent = Props.hintContent;
  var onClick = Props.onClick;
  var onMouseEnter = Props.onMouseEnter;
  var onMouseLeave = Props.onMouseLeave;
  var onMouseMove = Props.onMouseMove;
  var ymapsApi = YandexMaps.getApi();
  var map = MapReContext.YandexMapContext.useMap();
  var match = React.useState(function () {
        
      });
  var setPolyline = match[1];
  var polyline = match[0];
  var locationsToYandex = function (coords) {
    return coords.map(function (c) {
                return YandexMaps.Coordinate.fromGeolocation(c);
              });
  };
  var createPolyline = function (ymapsApi) {
    var coordinates$1 = locationsToYandex(coordinates);
    var options_strokeStyle = RCore.$$Option.map(strokeStyle, toString);
    var options = {
      strokeColor: strokeColor,
      strokeWidth: strokeWidth,
      strokeOpacity: strokeOpacity,
      strokeStyle: options_strokeStyle
    };
    var properties = {
      hintContent: hintContent,
      balloonContent: undefined
    };
    var polyline = YandexMaps.GeoObject.Polyline.make(ymapsApi, coordinates$1, properties, options);
    var addListener = function (eventType, handler) {
      YandexMaps.Events.add(YandexMaps.GeoObject.Polyline.events(polyline), eventType, handler);
    };
    if (onClick !== undefined) {
      addListener("Click", (function (v) {
              onClick(YandexMaps.Events.$$Event.$$Geolocation.get(v));
            }));
    }
    if (onMouseEnter !== undefined) {
      addListener("MouseEnter", (function (v) {
              onMouseEnter(YandexMaps.Events.$$Event.$$Geolocation.get(v));
            }));
    }
    if (onMouseLeave !== undefined) {
      addListener("MouseLeave", (function (v) {
              onMouseLeave(YandexMaps.Events.$$Event.$$Geolocation.get(v));
            }));
    }
    if (onMouseMove !== undefined) {
      addListener("MouseMove", (function (v) {
              onMouseMove(YandexMaps.Events.$$Event.$$Geolocation.get(v));
            }));
    }
    return polyline;
  };
  React.useEffect((function () {
          var match = Fun.both(ymapsApi, map);
          if (match === undefined) {
            return ;
          }
          var map$1 = match[1];
          var ymapsApi$1 = match[0];
          YandexMaps.defineArrow(ymapsApi$1);
          var polyline = createPolyline(ymapsApi$1);
          setPolyline(function (param) {
                return Caml_option.some(polyline);
              });
          YandexMaps.$$Map.addGeoObject(map$1, polyline);
          return (function () {
                    YandexMaps.$$Map.removeGeoObject(map$1, polyline);
                  });
        }), []);
  React.useEffect((function () {
          if (ymapsApi !== undefined && map !== undefined && polyline !== undefined) {
            var map$1 = Caml_option.valFromOption(map);
            YandexMaps.$$Map.removeGeoObject(map$1, Caml_option.valFromOption(polyline));
            var newPolyline = createPolyline(Caml_option.valFromOption(ymapsApi));
            setPolyline(function (param) {
                  return Caml_option.some(newPolyline);
                });
            YandexMaps.$$Map.addGeoObject(map$1, newPolyline);
          }
          
        }), coordinates);
  return null;
}

function MapRePolylineComponent$GooglePolyline(Props) {
  var coordinates = Props.coordinates;
  var strokeWeight = Props.strokeWeight;
  var strokeColor = Props.strokeColor;
  var strokeOpacity = Props.strokeOpacity;
  var strokeStyle = Props.strokeStyle;
  var onClick = Props.onClick;
  var displayIcon = Props.displayIcon;
  var onMouseEnter = Props.onMouseEnter;
  var onMouseLeave = Props.onMouseLeave;
  var onMouseMove = Props.onMouseMove;
  var gmapsApi = GoogleMaps.getApi();
  var map = MapReContext.GoogleMapContext.useMap();
  var match = React.useState(function () {
        
      });
  var setZoom = match[1];
  var zoom = match[0];
  var createPolyline = function (gmapsApi) {
    var path = coordinates.map(function (param) {
          return GoogleMaps.LatLngLiteral.fromGeolocation({
                      longitude: param.longitude,
                      latitude: param.latitude
                    });
        });
    var icons;
    if (displayIcon) {
      var lengthLimit = RCore.$$Option.getOr(RCore.$$Option.map(zoom, (function (zoom) {
                  return Math.pow(2.0, MapReComponent.maxZoom - zoom | 0) * 0.0004;
                })), 0.0004);
      var offset = function (coordinates) {
        var arrowPosition = function (_coordinates, _acc) {
          while(true) {
            var acc = _acc;
            var coordinates = _coordinates;
            if (!coordinates) {
              return acc;
            }
            var match = coordinates.tl;
            var first = coordinates.hd;
            if (!match) {
              return 0.0;
            }
            var second = match.hd;
            if (Types_Spatial.$$Geolocation.vectorLength(first, second) >= lengthLimit) {
              return acc + Types_Spatial.$$Geolocation.vectorLength(first, second) / 8.0;
            }
            _acc = acc + Types_Spatial.$$Geolocation.vectorLength(first, second);
            _coordinates = {
              hd: second,
              tl: match.tl
            };
            continue ;
          };
        };
        var totalLength = function (_previous, _coordinates, _acc) {
          while(true) {
            var acc = _acc;
            var coordinates = _coordinates;
            var previous = _previous;
            if (!coordinates) {
              return acc;
            }
            var first = coordinates.hd;
            _acc = acc + Types_Spatial.$$Geolocation.vectorLength(first, previous);
            _coordinates = coordinates.tl;
            _previous = first;
            continue ;
          };
        };
        var coordinates$1 = Core__List.fromArray(coordinates);
        if (coordinates$1) {
          return arrowPosition(coordinates$1, 0.0) / totalLength(coordinates$1.hd, coordinates$1.tl, 0.0) * 100.0;
        } else {
          return 0.0;
        }
      };
      var arrowOffset = offset(coordinates);
      var match = Core__List.fromArray(coordinates);
      var exit = 0;
      if (match) {
        var match$1 = match.tl;
        if (match$1 && Types_Spatial.$$Geolocation.vectorLength(match.hd, match$1.hd) < lengthLimit && arrowOffset === 0.0) {
          icons = undefined;
        } else {
          exit = 1;
        }
      } else {
        exit = 1;
      }
      if (exit === 1) {
        icons = [{
            icon: {
              path: "M -0.7 0.1 l 0 -9 l -0.7 0 l 1.4 -2.3 l 1.4 2.3 l -0.7 0 l 0 9",
              strokeOpacity: 1.0,
              scale: 4.0,
              fillColor: "#E75F85",
              fillOpacity: 0.8,
              strokeColor: "white",
              strokeWeight: 1.0
            },
            offset: arrowOffset.toString() + "%",
            repeat: "0"
          }];
      }
      
    } else {
      icons = undefined;
    }
    var strokeOpacity$1 = strokeStyle !== undefined ? 0.0 : strokeOpacity;
    var options = {
      path: path,
      geodesic: false,
      strokeColor: strokeColor,
      strokeOpacity: strokeOpacity$1,
      strokeWeight: strokeWeight,
      icons: icons
    };
    var polyline = GoogleMaps.Polyline.make(gmapsApi, options);
    var addListener = function (eventType, handler) {
      GoogleMaps.Polyline.Events.addListener(polyline, eventType, handler);
    };
    if (onClick !== undefined) {
      addListener("Click", (function (v) {
              onClick(GoogleMaps.Polyline.Events.$$Event.$$Geolocation.get(v));
            }));
    }
    if (onMouseEnter !== undefined) {
      addListener("MouseOver", (function (v) {
              onMouseEnter(GoogleMaps.Polyline.Events.$$Event.$$Geolocation.get(v));
            }));
    }
    if (onMouseLeave !== undefined) {
      addListener("MouseOut", (function (v) {
              onMouseLeave(GoogleMaps.Polyline.Events.$$Event.$$Geolocation.get(v));
            }));
    }
    if (onMouseMove !== undefined) {
      addListener("MouseMove", (function (v) {
              onMouseMove(GoogleMaps.Polyline.Events.$$Event.$$Geolocation.get(v));
            }));
    }
    return polyline;
  };
  React.useEffect((function () {
          if (map === undefined) {
            return ;
          }
          var map$1 = Caml_option.valFromOption(map);
          GoogleMaps.$$Map.Events.addListener(map$1, "ZoomChanged", (function (param) {
                  setZoom(function (param) {
                        return GoogleMaps.$$Map.getZoom(map$1);
                      });
                }));
        }), []);
  React.useEffect((function () {
          if (gmapsApi === undefined) {
            return ;
          }
          if (map === undefined) {
            return ;
          }
          var polyline = createPolyline(Caml_option.valFromOption(gmapsApi));
          GoogleMaps.Polyline.setMap(polyline, Caml_option.valFromOption(map));
          return (function () {
                    GoogleMaps.Polyline.removeFromMap(polyline);
                  });
        }), [
        coordinates,
        zoom
      ]);
  return null;
}

function MapRePolylineComponent(Props) {
  var mapType = Props.mapType;
  var coordinates = Props.coordinates;
  var displayIcon = Props.displayIcon;
  var strokeWidth = Props.strokeWidth;
  var strokeColor = Props.strokeColor;
  var strokeOpacity = Props.strokeOpacity;
  var strokeStyle = Props.strokeStyle;
  var hintContent = Props.hintContent;
  var onMouseEnter = Props.onMouseEnter;
  var onMouseLeave = Props.onMouseLeave;
  var onMouseMove = Props.onMouseMove;
  var onClick = Props.onClick;
  if (mapType === "Google") {
    return React.createElement(MapRePolylineComponent$GooglePolyline, {
                coordinates: coordinates,
                strokeWeight: strokeWidth,
                strokeColor: strokeColor,
                strokeOpacity: strokeOpacity,
                strokeStyle: strokeStyle,
                onClick: onClick,
                displayIcon: displayIcon,
                onMouseEnter: onMouseEnter,
                onMouseLeave: onMouseLeave,
                onMouseMove: onMouseMove
              });
  } else if (mapType === "Yandex") {
    return React.createElement(MapRePolylineComponent$YandexPolyline, {
                coordinates: coordinates,
                strokeWidth: strokeWidth,
                strokeColor: strokeColor,
                strokeOpacity: strokeOpacity,
                strokeStyle: strokeStyle,
                hintContent: hintContent,
                onClick: onClick,
                onMouseEnter: onMouseEnter,
                onMouseLeave: onMouseLeave,
                onMouseMove: onMouseMove
              });
  } else {
    return null;
  }
}

var make = MapRePolylineComponent;

export {
  StrokeStyle ,
  make ,
}
/* react Not a pure module */
