

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Types from "../../types/Types.mjs";
import * as Locale from "../../libraries/Locale.mjs";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Context_Types from "../../context/Context_Types.mjs";

var jobUuid = Uuid.fromString("bbbbbbb1-bbb1-bbb1-bbb1-bbbbbbbbbbb1");

var firstWorker_id = Types.Id.fromInt(27182818);

var firstWorker_uuid = Uuid.fromString("fffffff1-fff1-fff1-fff1-fffffffffff1");

var firstWorker_team_names = ["Agents"];

var firstWorker = {
  name: "Caius Cosades",
  id: firstWorker_id,
  uuid: firstWorker_uuid,
  team_names: firstWorker_team_names
};

var secondWorker_id = Types.Id.fromInt(314159265);

var secondWorker_uuid = Uuid.fromString("fffffff2-fff2-fff2-fff2-fffffffffff2");

var secondWorker_team_names = ["Venturers"];

var secondWorker = {
  name: "Rhett Butler",
  id: secondWorker_id,
  uuid: secondWorker_uuid,
  team_names: secondWorker_team_names
};

var workers = [
  firstWorker,
  secondWorker
];

function uuidToId(uuid) {
  var worker = RCore.$$Array.getBy(workers, (function (w) {
          return Caml_obj.equal(w.uuid, uuid);
        }));
  if (worker !== undefined) {
    return worker.id;
  } else {
    return Types.Id.fromString("Unknown worker");
  }
}

var location1_uuid = Uuid.fromString("ccccccc1-ccc1-ccc1-ccc1-ccccccccccc1");

var location1_workerUuid = firstWorker_uuid;

var location1_jobUuids = [jobUuid];

var location1_distancesToDestination = [200];

var location1_events = [];

var location1 = {
  uuid: location1_uuid,
  workerUuid: location1_workerUuid,
  effectiveAt: "2023-10-30T09:05:01+00:00",
  accuracy: 60,
  longitude: -82.4526,
  latitude: 27.9641,
  jobUuids: location1_jobUuids,
  distancesToDestination: location1_distancesToDestination,
  events: location1_events,
  batteryLevel: 0.95
};

var location2_uuid = Uuid.fromString("ccccccc2-ccc2-ccc2-ccc2-ccccccccccc2");

var location2_workerUuid = firstWorker_uuid;

var location2_jobUuids = [jobUuid];

var location2_distancesToDestination = [6491];

var location2_events = [];

var location2 = {
  uuid: location2_uuid,
  workerUuid: location2_workerUuid,
  effectiveAt: "2023-10-30T12:16:17+00:00",
  accuracy: 1001,
  longitude: -82.1572,
  latitude: 29.1788,
  jobUuids: location2_jobUuids,
  distancesToDestination: location2_distancesToDestination,
  events: location2_events,
  batteryLevel: 0.52
};

var location3_uuid = Uuid.fromString("ccccccc3-ccc3-ccc3-ccc3-ccccccccccc3");

var location3_workerUuid = firstWorker_uuid;

var location3_jobUuids = [];

var location3_distancesToDestination = [4451];

var location3_events = [];

var location3 = {
  uuid: location3_uuid,
  workerUuid: location3_workerUuid,
  effectiveAt: "2023-10-30T18:09:12+00:00",
  accuracy: 1050,
  longitude: -81.6557,
  latitude: 30.3322,
  jobUuids: location3_jobUuids,
  distancesToDestination: location3_distancesToDestination,
  events: location3_events,
  batteryLevel: 0.25
};

var location4_uuid = Uuid.fromString("ccccccc4-ccc4-ccc4-ccc4-ccccccccccc4");

var location4_workerUuid = secondWorker_uuid;

var location4_jobUuids = [];

var location4_distancesToDestination = [333];

var location4_events = [];

var location4 = {
  uuid: location4_uuid,
  workerUuid: location4_workerUuid,
  effectiveAt: "2023-10-30T15:15:15+00:00",
  accuracy: 50,
  longitude: -81.0982,
  latitude: 29.2112,
  jobUuids: location4_jobUuids,
  distancesToDestination: location4_distancesToDestination,
  events: location4_events,
  batteryLevel: 0.9
};

var location5_uuid = Uuid.fromString("ccccccc5-ccc5-ccc5-ccc5-ccccccccccc5");

var location5_workerUuid = secondWorker_uuid;

var location5_jobUuids = [];

var location5_distancesToDestination = [222];

var location5_events = [];

var location5 = {
  uuid: location5_uuid,
  workerUuid: location5_workerUuid,
  effectiveAt: "2023-10-30T17:17:17+00:00",
  accuracy: 150,
  longitude: -80.6399,
  latitude: 28.0923,
  jobUuids: location5_jobUuids,
  distancesToDestination: location5_distancesToDestination,
  events: location5_events,
  batteryLevel: 0.75
};

function makeJs(fake) {
  return {
          id: Js_math.random_int(0, 10000),
          uuid: Uuid.toString(fake.uuid),
          worker_id: Types.Id.toInt(uuidToId(fake.workerUuid)),
          worker_uuid: Uuid.toString(fake.workerUuid),
          effective_at: fake.effectiveAt,
          precision: fake.accuracy,
          accuracy: fake.accuracy,
          longitude: fake.longitude,
          latitude: fake.latitude,
          job_uuids: fake.jobUuids.map(function (item) {
                return Uuid.toString(item);
              }),
          distances_to_destination: RCore.$$Array.filterMap(fake.distancesToDestination, (function (item) {
                    return item;
                  })).map(function (item) {
                return item.toString();
              }),
          events: [],
          battery_level: fake.batteryLevel
        };
}

var locations = [
    location1,
    location2,
    location3,
    location4,
    location5
  ].map(function (x) {
      return makeJs(x);
    });

var job_clientUuid = Caml_option.some(Uuid.fromString("abcffff1-abc1-abc1-abc1-abcffffffff1"));

var job_clientName = "Space Z";

var job_site = {
  uuid: Uuid.fromString("bbbffff1-bbb1-bbb1-bbb1-bbbffffffff1"),
  name: "Bungalow #25"
};

var job_client = {
  uuid: Uuid.fromString("abcffff1-abc1-abc1-abc1-abcffffffff1"),
  name: "Space Z"
};

var job_formattedAddress = "Ocala, SW 40th Ln, 25";

var job_location = {
  longitude: -82.1572,
  latitude: 29.1788
};

var job_apartment = "25";

var job_addressDescription = "Cottage district in outskirts";

var job_scheduledAt = Caml_option.some(Locale.T.make("2023-10-30T08:35:00+00:00"));

var job_scheduledFinishAt = Caml_option.some(Locale.T.make("2023-10-30T20:59:59+00:00"));

var job_enRouteAt = Caml_option.some(Locale.T.make("2023-10-30T09:15:00+00:00"));

var job_startedAt = Caml_option.some(Locale.T.make("2023-10-30T10:35:00+00:00"));

var job_lastActivityAt = Caml_option.some(Locale.T.make("2023-10-30T10:40:00+00:00"));

var job_templateName = "Adding fake data to the map page";

var job_templateUuid = Caml_option.some(Uuid.fromString("affffff9-aff9-aff9-aff9-affffffffff9"));

var job_assignees = [{
    TAG: "Worker",
    _0: {
      TAG: "Edit",
      _0: {
        uuid: firstWorker_uuid,
        name: "Caius Cosades",
        permission: "Edit",
        skills: [],
        territory: undefined,
        permanent: true
      }
    }
  }];

var job_resolution = "Completed exceptionally well";

var job_resolutionUuid = Caml_option.some(Uuid.fromString("bbbaaaf9-bbb9-bbb9-bbb9-bbbaaafffff9"));

var job_territoryUuid = Caml_option.some(Uuid.fromString("fffffee1-fff1-fff1-fff1-fffffffffee1"));

var job_skillUuids = [];

var job_typeUuid = Caml_option.some(Uuid.fromString("abfffff1-abf1-abf1-abf1-abfffffffff1"));

var job = {
  uuid: jobUuid,
  status: "Finished",
  serialNo: 98765,
  clientUuid: job_clientUuid,
  clientName: job_clientName,
  site: job_site,
  client: job_client,
  formattedAddress: job_formattedAddress,
  location: job_location,
  apartment: job_apartment,
  floor: undefined,
  entranceNo: undefined,
  addressDescription: job_addressDescription,
  scheduledAt: job_scheduledAt,
  scheduledFinishAt: job_scheduledFinishAt,
  enRouteAt: job_enRouteAt,
  startedAt: job_startedAt,
  finishedAt: undefined,
  lastActivityAt: job_lastActivityAt,
  templateName: job_templateName,
  templateUuid: job_templateUuid,
  assignees: job_assignees,
  resolution: job_resolution,
  resolutionUuid: job_resolutionUuid,
  successful: true,
  territoryUuid: job_territoryUuid,
  skillUuids: job_skillUuids,
  typeUuid: job_typeUuid
};

function makeJs$1(job) {
  return {
          id: 1848,
          uuid: Uuid.toString(job.uuid),
          longitude: -82.1572,
          latitude: 29.1788,
          status: "finished",
          serial_no: job.serialNo,
          territory_uuid: job.territoryUuid,
          type_id: 1945,
          type_uuid: job.typeUuid,
          client_uuid: job.clientUuid,
          client_name: job.clientName,
          client: {
            name: "Space Z",
            uuid: "abcffff1-abc1-abc1-abc1-abcffffffff1"
          },
          site: job.site,
          address_id: 1583,
          address_uuid: "aaaffff1-aaa1-aaa1-aaa1-aaaffffffff1",
          formatted_address: job.formattedAddress,
          apartment: job.apartment,
          floor: job.floor,
          entrance_no: job.entranceNo,
          address_description: job.addressDescription,
          scheduled_at: "2023-10-30T08:00:00+00:00",
          scheduled_finish_at: "2023-10-30T09:00:00+00:00",
          en_route_at: "2023-10-30T08:00:00+00:00",
          started_at: "2023-10-30T08:35:00+00:00",
          suspended_at: undefined,
          finished_at: "2023-10-30T10:55:00+00:00",
          last_activity_at: "2023-10-30T13:55:00+00:00",
          worker_ids: [Types.Id.toInt(firstWorker_id)],
          template_name: job.templateName,
          template_id: 1825,
          template_uuid: job.templateUuid,
          assignees: {
            teams: [],
            workers: [{
                uuid: firstWorker_uuid,
                name: "Caius Cosades",
                permission: "edit",
                access: "edit",
                territory: null,
                skills: []
              }]
          },
          resolution_id: 909,
          resolution_uuid: job.resolutionUuid,
          resolution: job.resolution,
          successful: job.successful,
          location: makeJs(location2)
        };
}

var jobs = [makeJs$1(job)];

function make(wire) {
  return {
          tenant_uuid: wire.ctx.tenantUuid,
          current_jobs: jobs,
          map_api_key: wire.ctx.mapApiKey,
          map_type: Context_Types.MapType.toString(wire.ctx.mapType),
          workers: workers,
          latest_locations: locations
        };
}

function mergeWithReal(fake, realJson) {
  return {
          tenant_uuid: realJson.tenant_uuid,
          current_jobs: Belt_Array.concatMany([
                realJson.current_jobs,
                fake.current_jobs
              ]),
          map_api_key: realJson.map_api_key,
          map_type: realJson.map_type,
          workers: Belt_Array.concatMany([
                realJson.workers,
                fake.workers
              ]),
          latest_locations: Belt_Array.concatMany([
                realJson.latest_locations,
                fake.latest_locations
              ])
        };
}

var State = {
  make: make,
  mergeWithReal: mergeWithReal
};

var JsWorker = {};

var JsLocation = {};

var JsJob = {};

export {
  JsWorker ,
  JsLocation ,
  JsJob ,
  jobs ,
  locations ,
  workers ,
  State ,
}
/* jobUuid Not a pure module */
