

import * as Wire from "../../../../types/Wire.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Locale from "../../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_Types_Job from "../types/job/Schedule_Types_Job.mjs";
import * as Schedule_Types_Mode from "../types/Schedule_Types_Mode.mjs";
import * as Schedule_Types_Settings from "../types/Schedule_Types_Settings.mjs";

var Jobs = {};

var Settings = {};

function jobSubmitted(jobUuid, scheduledAt, assignees) {
  return {
          TAG: "Jobs",
          _0: {
            TAG: "JobSubmitted",
            _0: jobUuid,
            _1: scheduledAt,
            _2: assignees
          }
        };
}

var Action = {
  Jobs: Jobs,
  Settings: Settings,
  jobSubmitted: jobSubmitted
};

var initial_jobs = [];

var initial = {
  loading: false,
  warning: "Hidden",
  jobs: initial_jobs
};

var ScheduledJobs = {
  initial: initial
};

var initial_workersTimelines = [];

var initial_teamTimelines = [];

var initial$1 = {
  loading: false,
  workersTimelines: initial_workersTimelines,
  teamTimelines: initial_teamTimelines
};

var ScheduleTimelines = {
  initial: initial$1
};

var initial_jobs$1 = [];

var initial$2 = {
  loading: true,
  jobs: initial_jobs$1,
  jobsLimit: undefined,
  jobsAfter: undefined
};

var UnscheduledJobs = {
  initial: initial$2
};

function initial$3(wire, view) {
  return {
          filter: Schedule_Types_Settings.Filter.initial(wire, view),
          businessHours: Wire.businessHours(wire),
          showUnscheduledJobs: true,
          locked: false,
          mode: Schedule_Types_Mode.initial(wire),
          jobDuration: "Scheduled"
        };
}

var Settings$1 = {
  initial: initial$3
};

function initial$4(wire, view) {
  return {
          scheduledJobs: initial,
          unscheduledJobs: initial$2,
          settings: initial$3(wire, view),
          scheduleTimelines: initial$1
        };
}

function findUnscheduledJob(state, jobUuid) {
  return RCore.$$Array.getBy(state.unscheduledJobs.jobs, (function (job) {
                return Caml_obj.equal(Schedule_Types_Job.UnscheduledJob.uuid(job), jobUuid);
              }));
}

function findScheduledJob(state, jobUuid) {
  return RCore.$$Array.getBy(state.scheduledJobs.jobs, (function (job) {
                return Caml_obj.equal(Schedule_Types_Job.ScheduledJob.uuid(job), jobUuid);
              }));
}

function setScheduledJobs(state, jobs) {
  var init = state.scheduledJobs;
  return {
          scheduledJobs: {
            loading: init.loading,
            warning: init.warning,
            jobs: jobs
          },
          unscheduledJobs: state.unscheduledJobs,
          settings: state.settings,
          scheduleTimelines: state.scheduleTimelines
        };
}

function addScheduledJob(state, job) {
  var init = state.scheduledJobs;
  return {
          scheduledJobs: {
            loading: init.loading,
            warning: init.warning,
            jobs: Belt_Array.concatMany([
                  state.scheduledJobs.jobs,
                  [job]
                ])
          },
          unscheduledJobs: state.unscheduledJobs,
          settings: state.settings,
          scheduleTimelines: state.scheduleTimelines
        };
}

function addUnscheduledJob(state, job) {
  var init = state.unscheduledJobs;
  return {
          scheduledJobs: state.scheduledJobs,
          unscheduledJobs: {
            loading: init.loading,
            jobs: Belt_Array.concatMany([
                  [job],
                  state.unscheduledJobs.jobs
                ]),
            jobsLimit: init.jobsLimit,
            jobsAfter: init.jobsAfter
          },
          settings: state.settings,
          scheduleTimelines: state.scheduleTimelines
        };
}

function replaceUnscheduledJob(state, job) {
  var init = state.unscheduledJobs;
  return {
          scheduledJobs: state.scheduledJobs,
          unscheduledJobs: {
            loading: init.loading,
            jobs: state.unscheduledJobs.jobs.map(function (j) {
                  if (Caml_obj.equal(Schedule_Types_Job.UnscheduledJob.uuid(j), Schedule_Types_Job.UnscheduledJob.uuid(job))) {
                    return job;
                  } else {
                    return j;
                  }
                }),
            jobsLimit: init.jobsLimit,
            jobsAfter: init.jobsAfter
          },
          settings: state.settings,
          scheduleTimelines: state.scheduleTimelines
        };
}

function removeJobByUuid(state, jobUuid) {
  var init = state.scheduledJobs;
  var init$1 = state.unscheduledJobs;
  return {
          scheduledJobs: {
            loading: init.loading,
            warning: init.warning,
            jobs: RCore.$$Array.keep(state.scheduledJobs.jobs, (function (job) {
                    return Caml_obj.notequal(Schedule_Types_Job.ScheduledJob.uuid(job), jobUuid);
                  }))
          },
          unscheduledJobs: {
            loading: init$1.loading,
            jobs: RCore.$$Array.keep(state.unscheduledJobs.jobs, (function (job) {
                    return Caml_obj.notequal(Schedule_Types_Job.UnscheduledJob.uuid(job), jobUuid);
                  })),
            jobsLimit: init$1.jobsLimit,
            jobsAfter: init$1.jobsAfter
          },
          settings: state.settings,
          scheduleTimelines: state.scheduleTimelines
        };
}

function rescheduleScheduledJob(state, job, scheduledAt) {
  var job$1 = Schedule_Types_Job.ScheduledJob.setScheduledAt(Schedule_Types_Job.ScheduledJob.$$setInterval(job, {
            startAt: scheduledAt,
            finishAt: Locale.T.nextMinutes(Schedule_Types_Job.ScheduledJob.duration(job), scheduledAt)
          }), Caml_option.some(scheduledAt));
  var state$1 = setScheduledJobs(state, state.scheduledJobs.jobs.map(function (j) {
            if (Caml_obj.equal(Schedule_Types_Job.ScheduledJob.uuid(job$1), Schedule_Types_Job.ScheduledJob.uuid(j))) {
              return job$1;
            } else {
              return j;
            }
          }));
  return [
          state$1,
          job$1
        ];
}

function scheduleUnscheduledJob(state, job, scheduledAt) {
  var init = Schedule_Types_Job.UnscheduledJob.core(job);
  var scheduledJob = Schedule_Types_Job.ScheduledJob.make(Schedule_Types_Job.UnscheduledJob.assignees(job), {
        uuid: init.uuid,
        status: init.status,
        duration: init.duration,
        template: init.template,
        client: init.client,
        territory: init.territory,
        skillUuids: init.skillUuids,
        address: init.address,
        scheduledAt: Caml_option.some(scheduledAt),
        enRouteAt: init.enRouteAt,
        startedAt: init.startedAt,
        suspendedAt: init.suspendedAt,
        finishedAt: init.finishedAt,
        resolution: init.resolution,
        customUi: init.customUi
      }, {
        startAt: scheduledAt,
        finishAt: Locale.T.nextMinutes(Schedule_Types_Job.UnscheduledJob.duration(job), scheduledAt)
      });
  var state$1 = addScheduledJob(removeJobByUuid(state, Schedule_Types_Job.UnscheduledJob.uuid(job)), scheduledJob);
  return [
          state$1,
          scheduledJob
        ];
}

function scheduleJob(state, jobUuid, scheduledAt) {
  var job = findUnscheduledJob(state, jobUuid);
  if (job !== undefined) {
    return scheduleUnscheduledJob(state, job, scheduledAt);
  }
  var job$1 = findScheduledJob(state, jobUuid);
  if (job$1 !== undefined) {
    return rescheduleScheduledJob(state, job$1, scheduledAt);
  }
  
}

function scheduledJobsLoading(state) {
  return state.scheduledJobs.loading;
}

function workersTimelines(state) {
  return state.scheduleTimelines.workersTimelines;
}

function scheduleTimelines(state) {
  return state.scheduleTimelines;
}

function scheduledJobsWarning(state) {
  return state.scheduledJobs.warning;
}

function scheduledJobs(state) {
  return state.scheduledJobs.jobs;
}

function assignedJobs(state) {
  return RCore.$$Array.filterMap(state.scheduledJobs.jobs, Schedule_Types_Job.ScheduledJob.assigned);
}

function unassignedJobs(state) {
  return RCore.$$Array.filterMap(state.scheduledJobs.jobs, Schedule_Types_Job.ScheduledJob.unassigned);
}

function unscheduledJobsLoading(state) {
  return state.unscheduledJobs.loading;
}

function unscheduledJobsLimit(state) {
  return state.unscheduledJobs.jobsLimit;
}

function hasNextUnscheduledJobs(state) {
  return state.unscheduledJobs.jobsAfter !== undefined;
}

function unscheduledJobs(state) {
  return state.unscheduledJobs.jobs;
}

function filter(state) {
  return state.settings.filter;
}

function period(state) {
  return state.settings.filter.period;
}

function startOfPeriod(state) {
  return state.settings.filter.period[0];
}

function finishOfPeriod(state) {
  return state.settings.filter.period[1];
}

function businessHours(state) {
  return state.settings.businessHours;
}

function showUnscheduledJobs(state) {
  return state.settings.showUnscheduledJobs;
}

function locked(state) {
  return state.settings.locked;
}

function mode(state) {
  return state.settings.mode;
}

function jobDuration(state) {
  return state.settings.jobDuration;
}

function assigneesFilter(state) {
  return state.settings.filter.assignees;
}

var State = {
  ScheduledJobs: ScheduledJobs,
  ScheduleTimelines: ScheduleTimelines,
  UnscheduledJobs: UnscheduledJobs,
  Settings: Settings$1,
  initial: initial$4,
  findUnscheduledJob: findUnscheduledJob,
  findScheduledJob: findScheduledJob,
  setScheduledJobs: setScheduledJobs,
  addScheduledJob: addScheduledJob,
  addUnscheduledJob: addUnscheduledJob,
  replaceUnscheduledJob: replaceUnscheduledJob,
  removeJobByUuid: removeJobByUuid,
  rescheduleScheduledJob: rescheduleScheduledJob,
  scheduleUnscheduledJob: scheduleUnscheduledJob,
  scheduleJob: scheduleJob,
  scheduledJobsLoading: scheduledJobsLoading,
  workersTimelines: workersTimelines,
  scheduleTimelines: scheduleTimelines,
  scheduledJobsWarning: scheduledJobsWarning,
  scheduledJobs: scheduledJobs,
  assignedJobs: assignedJobs,
  unassignedJobs: unassignedJobs,
  unscheduledJobsLoading: unscheduledJobsLoading,
  unscheduledJobsLimit: unscheduledJobsLimit,
  hasNextUnscheduledJobs: hasNextUnscheduledJobs,
  unscheduledJobs: unscheduledJobs,
  filter: filter,
  period: period,
  startOfPeriod: startOfPeriod,
  finishOfPeriod: finishOfPeriod,
  businessHours: businessHours,
  showUnscheduledJobs: showUnscheduledJobs,
  locked: locked,
  mode: mode,
  jobDuration: jobDuration,
  assigneesFilter: assigneesFilter
};

var Reducer = {};

export {
  Action ,
  State ,
  Reducer ,
}
/* Wire Not a pure module */
