

import * as El from "../../../libraries/El.mjs";
import * as Fun from "../../../libraries/Fun.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Checkbox from "../../inputs/Checkbox.mjs";
import * as Optional from "../../common/Optional.mjs";
import * as MapJobsItem from "./MapJobsItem.mjs";
import * as MapJobsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/map/jobs/MapJobs.module.css";

var styles = MapJobsModuleCss;

var showUnscheduled$p = Utils.Translations.t("js.map.scheduling.show_unscheduled");

function MapJobsUnscheduled(Props) {
  var wire = Props.wire;
  var onToggle = Props.onToggle;
  var checked = Props.checked;
  var focusedUuid = Props.focusedUuid;
  var focus = Props.focus;
  var jobs = Props.jobs;
  return React.createElement("section", {
              className: El.Cn.concat(styles.unscheduledJobs, styles.jobsList)
            }, React.createElement("header", undefined, React.createElement(Checkbox.make, {
                      onToggle: Fun.omit(onToggle),
                      checked: checked,
                      label: showUnscheduled$p(wire.ctx),
                      name: "Foobars",
                      id: "showUnscheduled",
                      wrapperClasses: Js_dict.fromList({
                            hd: [
                              styles.unscheduledHeader,
                              true
                            ],
                            tl: /* [] */0
                          })
                    })), React.createElement(Optional.make, {
                  show: checked,
                  children: React.createElement("ul", undefined, jobs.map(function (job) {
                            return React.createElement(MapJobsItem.make, {
                                        wire: wire,
                                        onClick: focus,
                                        focusedUuid: focusedUuid,
                                        job: job,
                                        key: job.serialNo.toString()
                                      });
                          }))
                }));
}

var make = MapJobsUnscheduled;

export {
  make ,
}
/* styles Not a pure module */
