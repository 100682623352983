import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Wrapper from 'planado/schedule/components/wrapper'
import SideBar from 'planado/schedule/components/sidebar'
import Chart from 'planado/schedule/components/chart'
import { BusinessHours } from 'planado/schedule/components/chart/business_hours'
import { Bar } from 'planado/schedule/components/chart/bars'
import { TimeLine } from 'planado/schedule/components/chart/timeline'
import { CurrentTime } from 'planado/schedule/components/chart/current_time'
import { Background } from 'planado/schedule/components/chart/background'
import { BarLabels } from 'planado/schedule/components/chart/bar_labels'
import { BarsView } from 'planado/schedule/components/chart/bars'
import { Stats } from 'planado/schedule/components/sidebar/stats'
import { Jobs } from 'planado/schedule/components/sidebar/jobs'
import { AssigneesFilter } from 'planado/schedule/components/chart/assignees_filter'
import * as actions from 'planado/schedule/actions'

const WrapperC = {
  mapStateToProps: state => ({
    editable: state.editable
  }),
  mapDispatchToProps: () => ({})
}

export const WrapperLink = connect(
  WrapperC.mapStateToProps,
  WrapperC.mapDispatchToProps
)(Wrapper)

const ChartC = {
  mapStateToProps: state => ({
    model: state.backbone.chart,
    viewController: state.backbone.viewController,
    sideBar: state.backbone.sideBar,
    scale: state.scale
  }),
  mapDispatchToProps: dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  })
}

export const ChartLink = connect(
  ChartC.mapStateToProps,
  ChartC.mapDispatchToProps
)(Chart)

const SideBarC = {
  mapStateToProps: state => ({
    ctx: state.backbone.chart.ctx,
    model: state.backbone.sideBar,
    editable: state.editable
  }),
  mapDispatchToProps: () => ({})
}

export const SideBarLink = connect(
  SideBarC.mapStateToProps,
  SideBarC.mapDispatchToProps
)(SideBar)

const BusinessHoursC = {
  mapStateToProps: state => ({
    businessHours: state.businessHours,
    scale: state.scale,
    marks: state.backbone.chart.getMarks(),
    marksWidth: state.backbone.chart.width()
  }),
  mapDispatchToProps: () => ({})
}

export const ChartBusinessHoursLink = connect(
  BusinessHoursC.mapStateToProps,
  BusinessHoursC.mapDispatchToProps
)(BusinessHours)

const TimeLineC = {
  mapStateToProps: state => ({
    scale: state.scale,
    model: state.backbone.chart
  }),
  mapDispatchToProps: dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  })
}

export const TimeLineLink = connect(
  TimeLineC.mapStateToProps,
  TimeLineC.mapDispatchToProps
)(TimeLine)

const CurrentTimeC = {
  mapStateToProps: state => ({
    chart: state.backbone.chart
  }),
  mapDispatchToProps: dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  })
}

export const CurrentTimeLink = connect(
  CurrentTimeC.mapStateToProps,
  CurrentTimeC.mapDispatchToProps
)(CurrentTime)

const BackgroundC = {
  mapStateToProps: state => ({
    bars: state.backbone.chart.bars(),
    filter: state.filter
  }),
  mapDispatchToProps: dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  })
}

export const BackgroundLink = connect(
  BackgroundC.mapStateToProps,
  BackgroundC.mapDispatchToProps
)(Background)

const BarLabelsC = {
  mapStateToProps: state => ({
    collection: state.backbone.chart.bars(),
    filter: state.filter
  }),
  mapDispatchToProps: dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  })
}

export const BarLabelsLink = connect(
  BarLabelsC.mapStateToProps,
  BarLabelsC.mapDispatchToProps
)(BarLabels)

const BarsViewC = {
  mapStateToProps: state => ({
    scale: state.scale,
    chart: state.backbone.chart,
    collection: state.backbone.chart.bars(),
    filter: state.filter
  }),
  mapDispatchToProps: dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  })
}

export const BarsViewLink = connect(
  BarsViewC.mapStateToProps,
  BarsViewC.mapDispatchToProps
)(BarsView)

const StatsC = {
  mapStateToProps: state => ({
    collection: state.backbone.sideBar.dayJobs(),
    chart: state.backbone.chart
  }),
  mapDispatchToProps: dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  })
}

export const StatsLink = connect(
  StatsC.mapStateToProps,
  StatsC.mapDispatchToProps
)(Stats)

const JobsC = {
  mapStateToProps: state => ({
    collection: state.backbone.sideBar.dayJobs(),
    sideBar: state.backbone.sideBar,
    open: state.handlers.openJob,
    filter: state.filter
  }),
  mapDispatchToProps: dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  })
}

export const JobsLink = connect(
  JobsC.mapStateToProps,
  JobsC.mapDispatchToProps
)(Jobs)

const BarC = {
  mapStateToProps: state => ({
    templates: state.templates,
    chart: state.backbone.chart,
    openJob: state.handlers.openJob,
    addJob: state.handlers.addJob
  }),
  mapDispatchToProps: () => ({})
}

export const BarLink = connect(
  BarC.mapStateToProps,
  BarC.mapDispatchToProps
)(Bar)

const AssigneesFilterC = {
  mapStateToProps: ({ filter, wire, teams, workers, backbone }) => ({
    filter: filter.filter,
    teams,
    workers,
    wire,
    controller: backbone.viewController,
    options: {}
  }),
  mapDispatchToProps: dispatch => ({
    onChange: (ctx, controller, filter) =>
      dispatch(actions.setFilter(ctx, controller, filter))
  })
}

export const AssigneesFilterLink = connect(
  AssigneesFilterC.mapStateToProps,
  AssigneesFilterC.mapDispatchToProps
)(AssigneesFilter)
