

import * as Backend from "../../../libraries/backend/Backend.mjs";

function index(wire) {
  return Backend.get(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: "ResolutionIndex"
            });
}

var Index = {
  index: index
};

export {
  Index ,
}
/* Backend Not a pure module */
