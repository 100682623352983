

import * as React from "react";
import * as UserEdit from "./UserEdit.mjs";
import * as UserIndex from "./UserIndex.mjs";

function User(Props) {
  var wire = Props.wire;
  var route = Props.route;
  if (typeof route === "object") {
    if (route.NAME === "Field") {
      return React.createElement(UserIndex.make, {
                  wire: wire
                });
    } else {
      return React.createElement(UserEdit.make, {
                  wire: wire,
                  mode: route.VAL
                });
    }
  } else {
    return React.createElement(UserIndex.make, {
                wire: wire
              });
  }
}

var make = User;

export {
  make ,
}
/* react Not a pure module */
