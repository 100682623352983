// extracted by mini-css-extract-plugin
export var addJob = "addJob-A4HbOw7kYx2AvFyr1sbd";
export var close = "close-ioD6ir1efWiUOjbhFN_T";
export var count = "count-GA6PVSVT1bnmJfuXRClu";
export var date = "date-QdySz7HkKYqNpOpvZhMs";
export var group = "group-JC2Pqs5z29is6d1PAyyl";
export var header = "header-NTJqSiiJSjcenkk0hO7q";
export var item = "item-AcoCccsbOJn1BgF6lnj7";
export var items = "items-pwFRKcRgz0k_ipu2sxs5";
export var jobBack = "job-back-vJ29dLbqRtw_bksHIxw3";
export var jobContainer = "job-container-wGvnM7IrvHMd2ZGk5HCI";
export var jobHeader = "job-header-N0fA1XsukCsiddval_rG";
export var jobHeadline = "job-headline-ZEKtRjahCXTrol6637YW";
export var jobs = "jobs-q_XgvpWhmCqXvf4jpF_X";
export var popover = "popover-D65XYRN5k_PnsfDdF7dS";
export var subheader = "subheader-IGH71iFTl5LBuW2n4oFZ";