// extracted by mini-css-extract-plugin
export var active = "active-BkLxvEREJmJq4Z9Rgy4k";
export var address = "address-p6jzSntdRg0qlQScLY8w";
export var compact = "compact-CspJFKw_QKmOcK6Jz_lJ";
export var enRoute = "en-route-t6WDZ7h2BlnFxhj0N_2u";
export var finished = "finished-frSGPFGdaVSbEhRMJm2y";
export var finishedOutside = "finished-outside-FSHjE4eqVJe1WV2N0QkR";
export var full = "full-eFm5r8jYXFDbJSD8_KiB";
export var half = "half-yfVdk41KJ2pSmWuJvuBS";
export var icon = "icon-zxSmNBGzxRAlJAkEhssD";
export var iconBackground = "icon-background-sc9netzGNkSyzdCHgikT";
export var inner = "inner-a_FojQUE6QfGaWtjcyle";
export var job = "job-HNbyCYb2zL1VSYFgVmbs";
export var notDraggable = "not-draggable-ykGxQqOic6hlF2Z95qc5";
export var oneThird = "one-third-haIJe0q5p41ecRdJ3c9n";
export var posted = "posted-KF92GSbRMvWQUYkrHeGx";
export var started = "started-Cjgkc1q9S0ps2It3H8MY";
export var startedOutside = "started-outside-nm6YvYzdXQSGlICmjWxt";
export var successful = "successful-DT286gIbj38kJVcUpfVF";
export var suspended = "suspended-xLVYMGzUSac9cDWD04AS";
export var template = "template-q34p6CVQpA0gb7jVaQpK";
export var unsuccessful = "unsuccessful-JCxhC9PX_P2eXSvfurPw";