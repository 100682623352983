

import * as El from "../../../../../../../../libraries/El.mjs";
import * as React from "react";
import * as JobForm_HistoryLoaderModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/edit/form/components/history/components/loader/JobForm_HistoryLoader.module.css";

var styles = JobForm_HistoryLoaderModuleCss;

function JobForm_HistoryLoader(Props) {
  var show = Props.show;
  var children = Props.children;
  var className = El.Cn.concat(styles.overlay, show ? "" : styles.hideOverlay);
  return React.createElement("div", {
              className: El.Cn.concat("wrapper", styles.wrapper)
            }, React.createElement("div", {
                  className: className
                }), children);
}

var make = JobForm_HistoryLoader;

export {
  make ,
}
/* styles Not a pure module */
