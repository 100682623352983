

import * as React from "react";
import * as Schedule_HeaderIntervalCalendar from "./Schedule_HeaderIntervalCalendar.mjs";
import * as Schedule_HeaderIntervalDatePeriod from "./Schedule_HeaderIntervalDatePeriod.mjs";
import * as Schedule_HeaderIntervalPrevNextButtons from "./Schedule_HeaderIntervalPrevNextButtons.mjs";

function Schedule_HeaderInterval(Props) {
  var view = Props.view;
  return React.createElement(React.Fragment, undefined, React.createElement(Schedule_HeaderIntervalCalendar.make, {
                  view: view
                }), React.createElement(Schedule_HeaderIntervalPrevNextButtons.make, {
                  view: view
                }), React.createElement(Schedule_HeaderIntervalDatePeriod.make, {
                  view: view
                }));
}

var make = Schedule_HeaderInterval;

export {
  make ,
}
/* react Not a pure module */
