

import * as Case from "../../utils/Case.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.mjs";
import * as Context_Types from "../../context/Context_Types.mjs";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";

var ProfileSettings = {};

var decode = JsonDecode.object(function (field) {
      return {
              timezoneVariants: field.required("timezoneVariants", JsonDecode.array(Context_Types.TimeZone.decode)),
              timezone: RCore.$$Option.getOr(field.required("timezone", JsonDecode.option(JsonDecode.string)), ""),
              firstName: field.required("firstName", JsonDecode.string),
              lastName: field.required("lastName", JsonDecode.string)
            };
    });

var $$Response = {
  ProfileSettings: ProfileSettings,
  decode: decode
};

function get(wire) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: "Profile"
                }), decode);
}

var Show = {
  $$Response: $$Response,
  get: get
};

var emptyErrors_firstName = [];

var emptyErrors_lastName = [];

var emptyErrors_timezone = [];

var emptyErrors = {
  firstName: emptyErrors_firstName,
  lastName: emptyErrors_lastName,
  timezone: emptyErrors_timezone
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              firstName: RCore.$$Option.getOr(field.optional("firstName", JsonDecode.array(JsonDecode.string)), []),
              lastName: RCore.$$Option.getOr(field.optional("lastName", JsonDecode.array(JsonDecode.string)), []),
              timezone: RCore.$$Option.getOr(field.optional("timezone", JsonDecode.array(JsonDecode.string)), [])
            };
    });

var $$Error = {
  emptyErrors: emptyErrors,
  decode: decode$1
};

var emptyError = {
  errors: emptyErrors
};

function makeResponse(errors) {
  return {
          errors: RCore.$$Option.getOr(errors, emptyErrors)
        };
}

var decode$2 = JsonDecode.object(function (field) {
      return {
              errors: RCore.$$Option.getOr(field.optional("errors", decode$1), emptyErrors)
            };
    });

function fromString(str) {
  return Core__Result.getOr(Json_Decode$JsonCombinators.decode(Case.camelize(JSON.parse(str)), decode$2), emptyError);
}

function request(params) {
  return {
          timezone: params.timezone,
          firstName: params.firstName,
          lastName: params.lastName
        };
}

function update(wire, params) {
  return Backend.decode(Backend.patch(undefined, undefined, undefined, true, wire, {
                  NAME: "API",
                  VAL: "Profile"
                }, {
                  NAME: "Obj",
                  VAL: request(params)
                }), decode$2);
}

var $$Request = {
  request: request,
  update: update
};

var Patch_Response = {
  $$Error: $$Error,
  makeResponse: makeResponse,
  fromString: fromString
};

var Patch = {
  $$Response: Patch_Response,
  $$Request: $$Request
};

export {
  Show ,
  Patch ,
}
/* decode Not a pure module */
