

import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as WebhookTypes from "./WebhookTypes.mjs";

function $$fetch(wire, uuid) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Integrations",
                    VAL: {
                      NAME: "Webhooks",
                      VAL: {
                        NAME: "Edit",
                        VAL: uuid
                      }
                    }
                  }
                }), WebhookTypes.State.decode);
}

var Show = {
  $$fetch: $$fetch
};

function post(wire, state) {
  return Backend.post(undefined, undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "Integrations",
                VAL: {
                  NAME: "Webhooks",
                  VAL: "New"
                }
              }
            }, {
              NAME: "Obj",
              VAL: {
                url: state.url,
                secret: Js_null.fromOption(state.secret),
                name: Js_null.fromOption(state.name),
                event_types: state.eventTypes,
                status: WebhookTypes.Status.toString(state.status),
                version: state.version,
                alternative_versions: state.alternativeVersions
              }
            });
}

var Create = {
  post: post
};

function patch(wire, state, uuid) {
  return Backend.patch(undefined, undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "Integrations",
                VAL: {
                  NAME: "Webhooks",
                  VAL: {
                    NAME: "Edit",
                    VAL: uuid
                  }
                }
              }
            }, {
              NAME: "Obj",
              VAL: {
                uuid: state.uuid,
                url: state.url,
                secret: Js_null.fromOption(state.secret),
                name: Js_null.fromOption(state.name),
                event_types: state.eventTypes,
                status: WebhookTypes.Status.toString(state.status),
                version: state.version
              }
            });
}

var Update = {
  patch: patch
};

function destroy(uuid, wire) {
  return Backend.discard(Backend.$$delete(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Integrations",
                    VAL: {
                      NAME: "Webhooks",
                      VAL: {
                        NAME: "Edit",
                        VAL: uuid
                      }
                    }
                  }
                }));
}

var Delete = {
  destroy: destroy
};

export {
  Show ,
  Create ,
  Update ,
  Delete ,
}
/* Backend Not a pure module */
