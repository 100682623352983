import {
  scaleMarkIntervals,
  durationInHours
} from 'planado/schedule/utils2/scales'

class MarksBuilder {
  constructor(options) {
    this.chart = options.chart
    this.start = options.start
    this.finish = options.finish
  }

  buildDay(d) {
    return {
      start: d.clone(),
      offset: this.chart.viewOffset(d),
      finish: d.clone().endOf('day'),
      marks: []
    }
  }

  build() {
    const { scale, ctx } = this.chart.get('store').getState()

    let days = []
    let intervals = scaleMarkIntervals(scale, ctx)

    let start = this.start.clone().startOf('day')

    let day = this.buildDay(start)

    let current = start
    let nextMajor = current.clone()
    let nextLabel = current.clone()

    let addMark = () => {
      if (current.isAfter(day.finish)) {
        if (day.marks.length) {
          days.push(day)
        }
        day = this.buildDay(current)
      }

      return day.marks.push({
        label: nextLabel.isSame(current),
        major: nextMajor.isSame(current),
        mark: current.clone(),
        offset: this.chart.viewOffset(current)
      })
    }

    let i = 0
    while (current.isBefore(this.finish)) {
      if (!current.isBefore(start)) {
        addMark()
      }

      if (i++ > 10000) {
        throw 'Unexpected loop'
      }
      if (nextLabel.isSame(current)) {
        nextLabel = nextLabel.add(intervals.label)
      }
      if (nextMajor.isSame(current)) {
        nextMajor = nextMajor.add(intervals.major)
      }
      current.add(intervals.minor || intervals.major)
    }

    if (day.marks.length) {
      days.push(day)
    }

    return days
  }
}

class Navigator {
  constructor(chart) {
    const { scale, ctx } = chart.get('store').getState()
    this.scale = scale
    this.ratio =
      Math.floor(
        (chart.get('viewPortWidth') / durationInHours(scale, ctx)) * 100
      ) / 100

    this.chart = chart
    this.chart.on('change:scale', this.updateScale.bind(this))
  }

  hoursToOffset(hours) {
    return Math.floor(this.ratio * hours)
  }

  updateScale() {
    const { scale, ctx } = this.chart.get('store').getState()

    this.scale = scale
    this.ratio =
      Math.floor(
        (this.chart.get('viewPortWidth') / durationInHours(scale, ctx)) * 100
      ) / 100
  }

  getMarks() {
    return new MarksBuilder({
      scale: this.scale,
      start: this.chart.get('start').clone(),
      finish: this.chart.get('finish').clone(),
      chart: this.chart
    }).build()
  }

  getDays() {
    const start = this.chart.get('start').clone()
    const finish = this.chart.get('finish').clone()

    const days = []
    const current = start.clone()

    while (!current.isAfter(finish)) {
      days.push({
        day: current.clone(),
        offset: this.chart.viewOffset(current)
      })
      current.add({ days: 1 })
    }

    return days
  }
}

export default Navigator
