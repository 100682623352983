

import * as El from "../../../libraries/El.mjs";
import * as Fun from "../../../libraries/Fun.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TemplatesPopupModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/TemplatesPopup/TemplatesPopup.module.css";

var withoutTemplate$p = Utils.Translations.tr("common.without_template");

var emptyTemplateList$p = Utils.Translations.tr("common.empty_template_list");

var styles = TemplatesPopupModuleCss;

function TemplatesPopup(Props) {
  var options = Props.options;
  var minForFilteringOpt = Props.minForFiltering;
  var onClick = Props.onClick;
  var minForFiltering = minForFilteringOpt !== undefined ? minForFilteringOpt : 5;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var match$1 = React.useState(function () {
        
      });
  var setQuery = match$1[1];
  var query = match$1[0];
  var filterEnabled = React.useMemo((function () {
          return options.length >= minForFiltering;
        }), [
        options,
        minForFiltering
      ]);
  var filter = function (query) {
    return RCore.$$Array.keep(options, (function (o) {
                  return Fun.stringContains(query, o.name);
                }));
  };
  var filteredOptions = query !== undefined ? filter(query) : options;
  var onSearchInputChange = function (evt) {
    var q = evt.target.value;
    setQuery(function (param) {
          return Fun.optString(q);
        });
  };
  var filterBlock = filterEnabled ? React.createElement("div", {
          className: styles.filterWrapper
        }, React.createElement("input", {
              className: styles.filter,
              autoFocus: true,
              value: RCore.$$Option.getOr(query, ""),
              onChange: onSearchInputChange
            }), RCore.$$Option.isNone(query) ? React.createElement("div", {
                className: styles.filterIcon
              }) : React.createElement("button", {
                className: El.Cn.concat(styles.reset, Icon.style(undefined, undefined, "times")),
                type: "button",
                onClick: (function (param) {
                    setQuery(function (param) {
                          
                        });
                  })
              })) : null;
  return React.createElement("div", undefined, filterBlock, React.createElement("div", {
                  className: styles.container
                }, filteredOptions.map(function (r) {
                      return React.createElement("div", {
                                  key: Uuid.toString(r.uuid),
                                  className: styles.item,
                                  onClick: (function (e) {
                                      onClick(e, Caml_option.some(r.uuid));
                                    })
                                }, r.name);
                    }), filterEnabled && filteredOptions.length === 0 ? React.createElement("div", {
                        className: El.Cn.concat(styles.item, styles.emptyList)
                      }, emptyTemplateList$p(ctx)) : null, React.createElement("div", {
                      className: styles.item,
                      onClick: (function (e) {
                          onClick(e, undefined);
                        })
                    }, withoutTemplate$p(ctx))));
}

var make = TemplatesPopup;

export {
  make ,
}
/* withoutTemplate' Not a pure module */
