

import * as Fun from "../../libraries/Fun.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as $$String from "rescript/lib/es6/string.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as Core__Int from "@rescript/core/lib/es6/src/Core__Int.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Types_Spatial from "../../types/Types_Spatial.mjs";
import * as Shared_Lib_Array from "../../shared/lib/Shared_Lib_Array.mjs";
import * as Shared_Lib_Option from "../../shared/lib/Shared_Lib_Option.mjs";
import * as Shared_Lib_String from "../../shared/lib/Shared_Lib_String.mjs";

var FocusedElement = {};

function toString(resolveStatus) {
  if (resolveStatus === "Resolved") {
    return "resolved";
  } else {
    return "failed";
  }
}

var ResolveStatus = {
  toString: toString
};

var extIdAttributes = [
  "area",
  "areaType",
  "areaTypeFull",
  "block",
  "blockType",
  "city",
  "cityType",
  "cityTypeFull",
  "country",
  "flat",
  "flatArea",
  "flatType",
  "house",
  "houseType",
  "houseTypeFull",
  "kladrId",
  "okato",
  "oktmo",
  "postalBox",
  "postalCode",
  "qc",
  "qcComplete",
  "qcGeo",
  "qcHouse",
  "region",
  "regionType",
  "regionTypeFull",
  "settlement",
  "settlementType",
  "settlementTypeFull",
  "street",
  "streetType",
  "streetTypeFull",
  "taxOffice"
];

function makeExtId(dict) {
  var match = Js_dict.entries(dict);
  if (match.length !== 0) {
    return Utils.md5((function (__x) {
                    return Shared_Lib_Array.$$String.joinWith("|", __x);
                  })(extIdAttributes.map(function (attr) {
                        return RCore.$$Option.getOr(RCore.$$Option.flatMap(Js_dict.get(dict, attr), (function (x) {
                                          if (x === null) {
                                            return ;
                                          } else {
                                            return Caml_option.some(x);
                                          }
                                        })), "");
                      })));
  }
  
}

var decode = JsonDecode.object(function (field) {
      return {
              postalCode: field.required("postal_code", JsonDecode.option(JsonDecode.string)),
              countryIsoCode: field.required("country_iso_code", JsonDecode.option(JsonDecode.string)),
              regionWithType: field.required("region_with_type", JsonDecode.option(JsonDecode.string)),
              areaWithType: field.required("area_with_type", JsonDecode.option(JsonDecode.string)),
              cityWithType: field.required("city_with_type", JsonDecode.option(JsonDecode.string)),
              settlementWithType: field.required("settlement_with_type", JsonDecode.option(JsonDecode.string)),
              streetWithType: field.required("street_with_type", JsonDecode.option(JsonDecode.string)),
              geoLat: field.required("geo_lat", JsonDecode.option(JsonDecode.string)),
              geoLon: field.required("geo_lon", JsonDecode.option(JsonDecode.string)),
              source: field.required("source", JsonDecode.option(JsonDecode.string)),
              house: field.required("house", JsonDecode.option(JsonDecode.string)),
              street: field.required("street", JsonDecode.option(JsonDecode.string)),
              area: field.required("area", JsonDecode.option(JsonDecode.string)),
              areaType: field.required("area_type", JsonDecode.option(JsonDecode.string)),
              areaTypeFull: field.required("area_type_full", JsonDecode.option(JsonDecode.string)),
              block: field.required("block", JsonDecode.option(JsonDecode.string)),
              blockType: field.required("block_type", JsonDecode.option(JsonDecode.string)),
              city: field.required("city", JsonDecode.option(JsonDecode.string)),
              cityType: field.required("city_type", JsonDecode.option(JsonDecode.string)),
              cityTypeFull: field.required("city_type_full", JsonDecode.option(JsonDecode.string)),
              country: field.required("country", JsonDecode.option(JsonDecode.string)),
              flat: field.required("flat", JsonDecode.option(JsonDecode.string)),
              flatArea: field.required("flat_area", JsonDecode.option(JsonDecode.string)),
              flatType: field.required("flat_type", JsonDecode.option(JsonDecode.string)),
              houseType: field.required("house_type", JsonDecode.option(JsonDecode.string)),
              houseTypeFull: field.required("house_type_full", JsonDecode.option(JsonDecode.string)),
              kladrId: field.required("kladr_id", JsonDecode.option(JsonDecode.string)),
              okato: field.required("okato", JsonDecode.option(JsonDecode.string)),
              oktmo: field.required("oktmo", JsonDecode.option(JsonDecode.string)),
              postalBox: field.required("postal_box", JsonDecode.option(JsonDecode.string)),
              qc: field.required("qc", JsonDecode.option(JsonDecode.string)),
              qcComplete: field.required("qc_complete", JsonDecode.option(JsonDecode.string)),
              qcGeo: field.required("qc_geo", JsonDecode.option(JsonDecode.string)),
              qcHouse: field.required("qc_house", JsonDecode.option(JsonDecode.string)),
              region: field.required("region", JsonDecode.option(JsonDecode.string)),
              regionType: field.required("region_type", JsonDecode.option(JsonDecode.string)),
              regionTypeFull: field.required("region_type_full", JsonDecode.option(JsonDecode.string)),
              settlement: field.required("settlement", JsonDecode.option(JsonDecode.string)),
              settlementType: field.required("settlement_type", JsonDecode.option(JsonDecode.string)),
              settlementTypeFull: field.required("settlement_type_full", JsonDecode.option(JsonDecode.string)),
              streetType: field.required("street_type", JsonDecode.option(JsonDecode.string)),
              streetTypeFull: field.required("street_type_full", JsonDecode.option(JsonDecode.string)),
              taxOffice: field.required("tax_office", JsonDecode.option(JsonDecode.string))
            };
    });

var decode$1 = JsonDecode.object(function (field) {
      return {
              value: field.required("value", JsonDecode.string),
              unrestrictedValue: field.required("unrestricted_value", JsonDecode.string),
              data: field.required("data", decode)
            };
    });

var decode$2 = JsonDecode.object(function (field) {
      return {
              suggestions: field.required("suggestions", JsonDecode.array(decode$1))
            };
    });

var decode$3 = JsonDecode.object(function (field) {
      return {
              country: field.required("country", JsonDecode.option(JsonDecode.string)),
              region: field.required("region", JsonDecode.option(JsonDecode.string)),
              area: field.required("area", JsonDecode.option(JsonDecode.string)),
              city: field.required("city", JsonDecode.option(JsonDecode.string)),
              settlement: field.required("settlement", JsonDecode.option(JsonDecode.string)),
              formatted: field.required("formatted", JsonDecode.option(JsonDecode.string))
            };
    });

var SettingsAddressConstraints_empty = {
  country: undefined,
  region: undefined,
  area: undefined,
  city: undefined,
  settlement: undefined,
  formatted: undefined
};

var SettingsAddressConstraints = {
  empty: SettingsAddressConstraints_empty,
  decode: decode$3
};

function makeFromSuggestionData(suggestionData) {
  return {
          country: suggestionData.country,
          area: suggestionData.area,
          region: suggestionData.region,
          city: suggestionData.city,
          settlement: suggestionData.settlement
        };
}

var empty = {
  country: undefined,
  area: undefined,
  region: undefined,
  city: undefined,
  settlement: undefined
};

var decode$4 = JsonDecode.object(function (field) {
      return {
              country: field.required("country", JsonDecode.option(JsonDecode.string)),
              area: field.required("area", JsonDecode.option(JsonDecode.string)),
              region: field.required("region", JsonDecode.option(JsonDecode.string)),
              city: field.required("city", JsonDecode.option(JsonDecode.string)),
              settlement: field.required("settlement", JsonDecode.option(JsonDecode.string))
            };
    });

function makeSettings(lookupRestrictions) {
  return {
          country: lookupRestrictions.locations.country,
          region: lookupRestrictions.locations.region,
          area: lookupRestrictions.locations.area,
          city: lookupRestrictions.locations.city,
          settlement: lookupRestrictions.locations.settlement,
          formatted: lookupRestrictions.formatted === "" ? undefined : lookupRestrictions.formatted
        };
}

function makeFromSettings(lookupRestrictions) {
  return {
          locations: {
            country: lookupRestrictions.country,
            area: lookupRestrictions.area,
            region: lookupRestrictions.region,
            city: lookupRestrictions.city,
            settlement: lookupRestrictions.settlement
          },
          formatted: RCore.$$Option.getOr(lookupRestrictions.formatted, "")
        };
}

function makeFromResponseItem(responseItem) {
  var data = responseItem.data;
  return {
          locations: makeFromSuggestionData(data),
          formatted: responseItem.value
        };
}

function makeFromString(formatted) {
  return {
          locations: empty,
          formatted: formatted
        };
}

var decode$5 = JsonDecode.oneOf([
      JsonDecode.object(function (field) {
            return {
                    locations: field.required("locations", decode$4),
                    formatted: field.required("formatted", JsonDecode.string)
                  };
          }),
      JsonDecode.custom(function (param) {
            
          })
    ]);

var AddressConstraint = {
  makeSettings: makeSettings,
  makeFromSettings: makeFromSettings,
  makeFromResponseItem: makeFromResponseItem,
  makeFromString: makeFromString,
  decode: decode$5
};

var JsRegularAddress = {};

function applyConstraints(address, constraints) {
  var parts = Js_string.split(",", constraints).map($$String.trim);
  var regex = /^\s/;
  var formatted = RCore.$$Array.reduce(parts, address.formatted, (function (longerPart, shorterPart) {
          return Js_string.replaceByRe(regex, "", Shared_Lib_String.replaceFirst(longerPart, shorterPart + ",", ""));
        }));
  return {
          streetNumber: address.streetNumber,
          route: address.route,
          sublocality: address.sublocality,
          locality: address.locality,
          subadministrativeArea: address.subadministrativeArea,
          administrativeArea: address.administrativeArea,
          postalCode: address.postalCode,
          extId: address.extId,
          formattedFull: address.formattedFull,
          country: address.country,
          source: address.source,
          geolocationResolved: address.geolocationResolved,
          formatted: formatted,
          geolocation: address.geolocation,
          qcGeo: address.qcGeo
        };
}

function geolocationFromString(string) {
  var geolocation = Types_Spatial.$$Geolocation.fromStringStrict(string);
  return [
          geolocation,
          RCore.$$Option.isSome(geolocation)
        ];
}

function makeFromString$1(formatted) {
  var match = geolocationFromString(formatted);
  return {
          streetNumber: undefined,
          route: undefined,
          sublocality: undefined,
          locality: undefined,
          subadministrativeArea: undefined,
          administrativeArea: undefined,
          postalCode: undefined,
          extId: undefined,
          formattedFull: formatted,
          country: undefined,
          source: undefined,
          geolocationResolved: match[1],
          formatted: formatted,
          geolocation: match[0],
          qcGeo: undefined
        };
}

function precision(data) {
  var stringPrecision = data.qcGeo;
  if (stringPrecision === undefined) {
    return 4;
  }
  var p = Core__Int.fromString(stringPrecision, undefined);
  if (p !== undefined) {
    return p;
  } else {
    return 0;
  }
}

function makeJs(address) {
  return {
          streetNumber: Js_null.fromOption(address.streetNumber),
          route: Js_null.fromOption(address.route),
          sublocality: Js_null.fromOption(address.sublocality),
          locality: Js_null.fromOption(address.locality),
          subadministrativeArea: Js_null.fromOption(address.subadministrativeArea),
          administrativeArea: Js_null.fromOption(address.administrativeArea),
          postalCode: Js_null.fromOption(address.postalCode),
          extId: Js_null.fromOption(address.extId),
          formattedFull: Js_null.fromOption(address.formattedFull),
          country: Js_null.fromOption(address.country),
          source: Js_null.fromOption(address.source),
          geolocationResolved: address.geolocationResolved,
          formatted: address.formatted === "" ? null : Js_null.fromOption(address.formatted),
          geolocation: Js_null.fromOption(address.geolocation)
        };
}

function makeFromJs(address) {
  return {
          streetNumber: Caml_option.null_to_opt(address.streetNumber),
          route: Caml_option.null_to_opt(address.route),
          sublocality: Caml_option.null_to_opt(address.sublocality),
          locality: Caml_option.null_to_opt(address.locality),
          subadministrativeArea: Caml_option.null_to_opt(address.subadministrativeArea),
          administrativeArea: Caml_option.null_to_opt(address.administrativeArea),
          postalCode: Caml_option.null_to_opt(address.postalCode),
          extId: Caml_option.null_to_opt(address.extId),
          formattedFull: Caml_option.null_to_opt(address.formattedFull),
          country: Caml_option.null_to_opt(address.country),
          source: Caml_option.null_to_opt(address.source),
          geolocationResolved: address.geolocationResolved,
          formatted: RCore.$$Option.getOr(Caml_option.null_to_opt(address.formatted), ""),
          geolocation: Caml_option.null_to_opt(address.geolocation),
          qcGeo: undefined
        };
}

function makeFromResponseItem$1(responseItem) {
  var data = responseItem.data;
  var precision$1 = precision(data);
  var match = Fun.both(data.geoLat, data.geoLon);
  var tmp;
  if (match !== undefined && precision$1 !== 4) {
    var match$1 = Fun.both(Types_Spatial.Latitude.fromString(match[0]), Types_Spatial.Longitude.fromString(match[1]));
    tmp = match$1 !== undefined ? Types_Spatial.$$Geolocation.make(match$1[0], match$1[1]) : undefined;
  } else {
    tmp = undefined;
  }
  return {
          streetNumber: data.house,
          route: data.street,
          sublocality: data.streetWithType,
          locality: Shared_Lib_Option.alt(data.cityWithType, data.settlementWithType),
          subadministrativeArea: data.areaWithType,
          administrativeArea: data.regionWithType,
          postalCode: data.postalCode,
          extId: makeExtId(data),
          formattedFull: responseItem.unrestrictedValue,
          country: data.countryIsoCode,
          source: Shared_Lib_Option.alt(data.source, "dadata"),
          geolocationResolved: precision$1 !== 4 && data.geoLat !== undefined && data.geoLon !== undefined,
          formatted: responseItem.value,
          geolocation: tmp,
          qcGeo: data.qcGeo
        };
}

function make(streetNumber, route, sublocality, locality, subadministrativeArea, administrativeArea, postalCode, extId, formattedFull, country, source, geolocationResolved, formatted, geolocation, qcGeo) {
  return {
          streetNumber: streetNumber,
          route: route,
          sublocality: sublocality,
          locality: locality,
          subadministrativeArea: subadministrativeArea,
          administrativeArea: administrativeArea,
          postalCode: postalCode,
          extId: extId,
          formattedFull: formattedFull,
          country: country,
          source: source,
          geolocationResolved: geolocationResolved,
          formatted: RCore.$$Option.getOr(formatted, ""),
          geolocation: geolocation,
          qcGeo: qcGeo
        };
}

var Address = {};

var Response_Suggestion = {
  Data: {}
};

var $$Response = {
  extIdAttributes: extIdAttributes,
  makeExtId: makeExtId,
  Suggestion: Response_Suggestion,
  decode: decode$2
};

var AddressConstraintParts = {
  empty: empty
};

var RegularAddress_empty = {
  streetNumber: undefined,
  route: undefined,
  sublocality: undefined,
  locality: undefined,
  subadministrativeArea: undefined,
  administrativeArea: undefined,
  postalCode: undefined,
  extId: undefined,
  formattedFull: undefined,
  country: undefined,
  source: undefined,
  geolocationResolved: false,
  formatted: "",
  geolocation: undefined,
  qcGeo: undefined
};

var RegularAddress = {
  applyConstraints: applyConstraints,
  makeFromString: makeFromString$1,
  makeJs: makeJs,
  makeFromJs: makeFromJs,
  makeFromResponseItem: makeFromResponseItem$1,
  make: make,
  empty: RegularAddress_empty
};

export {
  FocusedElement ,
  ResolveStatus ,
  $$Response ,
  SettingsAddressConstraints ,
  AddressConstraintParts ,
  AddressConstraint ,
  JsRegularAddress ,
  RegularAddress ,
  Address ,
}
/* decode Not a pure module */
