

import * as El from "../../libraries/El.mjs";
import * as Fun from "../../libraries/Fun.mjs";
import * as Icon from "../../Icon.mjs";
import * as Link from "../common/Link/Link.mjs";
import * as Hooks from "../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Route from "../../types/Route.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Errors from "../../Errors.mjs";
import * as Locale from "../../libraries/Locale.mjs";
import * as MapAPI from "./MapAPI.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as MapTypes from "./MapTypes.mjs";
import * as Optional from "../common/Optional.mjs";
import * as ModalQuery from "../../types/ModalQuery.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DateTimePicker from "../inputs/DateTimePicker.mjs";
import * as MessengerTypes from "../messenger/MessengerTypes.mjs";
import * as MultipleAssignee from "../../types/MultipleAssignee.mjs";
import * as MultipleAssignees from "../common/MultipleAssignees/MultipleAssignees.mjs";
import * as MultipleAssignees_Utils from "../common/MultipleAssignees/MultipleAssignees_Utils.mjs";
import * as MapFocusedJobModuleCss from "/home/runner/work/planado/planado/client/rescript/components/map/MapFocusedJob.module.css";

var jobNo$p = Utils.Translations.tx("js.map.serial_no");

var edit$p = Utils.Translations.tr("js.map.scheduling.edit");

var editing$p = Utils.Translations.tr("js.map.scheduling.editing");

var assignee$p = Utils.Translations.tr("js.map.scheduling.assignee");

var scheduledAt$p = Utils.Translations.t("js.map.scheduling.scheduled_at");

var jobNotFound$p = Utils.Translations.t("js.jobs.index.job_not_found");

var save$p = Utils.Translations.tr("common.save");

var cancel$p = Utils.Translations.tr("common.cancel");

var styles = MapFocusedJobModuleCss;

function MapFocusedJob$Summary(Props) {
  var job = Props.job;
  var wire = Props.wire;
  var fmtTime = function (v) {
    return Locale.T.fmtM("Time", wire)(v);
  };
  var scheduledAt = RCore.$$Option.map(job.scheduledAt, fmtTime);
  var scheduledFinishAt = RCore.$$Option.map(job.scheduledFinishAt, fmtTime);
  var time;
  if (scheduledAt !== undefined) {
    var at = Caml_option.valFromOption(scheduledAt);
    time = scheduledFinishAt !== undefined ? Caml_option.some(React.createElement(React.Fragment, undefined, at, El.ndash, Caml_option.valFromOption(scheduledFinishAt))) : Caml_option.some(at);
  } else {
    time = undefined;
  }
  var match = MultipleAssignee.mainAssigneeName(job.assignees);
  return React.createElement("div", {
              className: styles.summary
            }, match !== undefined ? (
                time !== undefined ? React.createElement(React.Fragment, undefined, Caml_option.valFromOption(time), El.nbsp, El.dot, El.nbsp, match) : match
              ) : (
                time !== undefined ? Caml_option.valFromOption(time) : null
              ));
}

function MapFocusedJob$Address(Props) {
  var job = Props.job;
  var onClick = Props.onClick;
  var addr = job.formattedAddress;
  if (addr === undefined) {
    return null;
  }
  if (addr === "") {
    return null;
  }
  var className = El.Cn.fromList(El.Cn.add(styles.hasLocation, RCore.$$Option.isSome(job.location), {
            hd: [
              styles.jobAddress,
              true
            ],
            tl: /* [] */0
          }));
  var match = job.location;
  return React.createElement("div", {
              className: className,
              onClick: onClick
            }, match !== undefined ? React.createElement("div", {
                    className: styles.locateJob
                  }, React.createElement("i", {
                        className: Icon.style(undefined, undefined, "crosshairs")
                      })) : null, addr);
}

function site(job, wire) {
  var match = job.site;
  if (match !== undefined) {
    return React.createElement("div", {
                className: styles.site
              }, React.createElement(Link.make, {
                    wire: wire,
                    route: {
                      NAME: "Site",
                      VAL: "Index"
                    },
                    modals: {
                      hd: {
                        NAME: "Site",
                        VAL: {
                          NAME: "Edit",
                          VAL: match.uuid
                        }
                      },
                      tl: /* [] */0
                    },
                    target: "blank",
                    children: match.name
                  }));
  } else {
    return null;
  }
}

function client(job, wire) {
  var match = job.client;
  if (match !== undefined) {
    return React.createElement("div", {
                className: styles.client
              }, React.createElement(Link.make, {
                    wire: wire,
                    route: {
                      NAME: "Client",
                      VAL: "Index"
                    },
                    modals: {
                      hd: {
                        NAME: "Client",
                        VAL: {
                          NAME: "Edit",
                          VAL: match.uuid
                        }
                      },
                      tl: /* [] */0
                    },
                    target: "blank",
                    children: match.name
                  }));
  } else {
    return null;
  }
}

function MapFocusedJob$EditForm(Props) {
  var wire = Props.wire;
  var job = Props.job;
  var onUpdate = Props.onUpdate;
  var messenger = wire.subscriptions.messenger;
  var ctx = wire.ctx;
  var match = Hooks.useLocked(false);
  var unlock = match[2];
  var lock = match[1];
  var locked = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setOpen = match$1[1];
  var match$2 = React.useState(function () {
        return {
                scheduledAt: {
                  date: undefined,
                  time: Locale.T.nowRoundTime(5, undefined, wire)
                },
                assignees: []
              };
      });
  var setState = match$2[1];
  var state = match$2[0];
  var handleOpen = function () {
    setOpen(function (param) {
          return true;
        });
    setState(function (param) {
          var scheduledAt = job.scheduledAt;
          var tmp;
          if (scheduledAt !== undefined) {
            var scheduledAt$1 = Caml_option.valFromOption(scheduledAt);
            tmp = {
              date: Locale.T.fmtM("Date", wire)(scheduledAt$1),
              time: Locale.T.fmtM("Time", wire)(scheduledAt$1)
            };
          } else {
            tmp = {
              date: undefined,
              time: Locale.T.nowRoundTime(5, undefined, wire)
            };
          }
          return {
                  scheduledAt: tmp,
                  assignees: job.assignees
                };
        });
  };
  var displayError = function (text) {
    messenger.trigger({
          TAG: "Show",
          _0: MessengerTypes.Message.make(undefined, "Danger", {
                TAG: "Text",
                _0: text
              })
        });
  };
  var handleResponse = function (res) {
    if (res.TAG === "Ok") {
      onUpdate();
    } else {
      var match = res._0;
      if (typeof match === "object" && match.TAG === "HTTPError") {
        var match$1 = match._0;
        var tmp = match$1[0];
        if (typeof tmp !== "object") {
          switch (tmp) {
            case "NotFound" :
                displayError(jobNotFound$p(ctx));
                break;
            case "UnprocessableEntity" :
                var text = Errors.getString("assignees", Errors.parse(match$1[1]));
                if (text !== undefined) {
                  displayError(text);
                }
                break;
            default:
              
          }
        }
        
      }
      
    }
    unlock();
  };
  var submit = function () {
    lock();
    var updateScheduledAt = RCore.$$Option.map(Locale.T.concatDateWithTime(state.scheduledAt, wire), Locale.T.make);
    var newrecord = Caml_obj.obj_dup(job);
    Backend.$$finally(MapAPI.Jobs.Patch.patch(wire, (newrecord.assignees = state.assignees, newrecord.scheduledAt = updateScheduledAt, newrecord)), handleResponse);
  };
  var handleSubmit = function ($$event) {
    El.cancelSubmit($$event);
    submit();
  };
  var hasRightsToEditJobs = function (ctx) {
    var perms = ctx.permissions;
    if (perms === undefined) {
      return false;
    }
    var match = perms.jobs;
    if (typeof match === "object") {
      return true;
    } else {
      return !(match === "View" || match === "Restricted");
    }
  };
  var match$3 = job.status;
  switch (match$3) {
    case "EnRoute" :
    case "Started" :
    case "Suspended" :
    case "Finished" :
        return null;
    default:
      if (!match$1[0]) {
        return React.createElement(Optional.make, {
                    show: hasRightsToEditJobs(ctx),
                    children: React.createElement("div", {
                          className: styles.editButton
                        }, React.createElement("button", {
                              className: "btn",
                              onClick: (function (param) {
                                  handleOpen();
                                })
                            }, edit$p(ctx)))
                  });
      }
      var tmp = {
        selectedAssignees: state.assignees,
        territoryUuid: job.territoryUuid,
        skillUuids: job.skillUuids,
        availableSkills: ctx.availableSkills,
        disabled: locked,
        onChange: (function (a) {
            setState(function (s) {
                  return {
                          scheduledAt: s.scheduledAt,
                          assignees: a
                        };
                });
          }),
        openUp: true
      };
      var tmp$1 = Locale.T.fromDateTime(state.scheduledAt, wire);
      if (tmp$1 !== undefined) {
        tmp.availableAt = Caml_option.valFromOption(tmp$1);
      }
      return React.createElement("section", {
                  className: styles.editForm
                }, React.createElement("form", {
                      onSubmit: handleSubmit
                    }, React.createElement("header", undefined, editing$p(ctx)), React.createElement("div", {
                          className: El.Cn.concat(styles.group, styles.scheduledAt)
                        }, React.createElement(DateTimePicker.make, {
                              id: "map-job-scheduled-at",
                              label: scheduledAt$p(ctx),
                              dateTime: state.scheduledAt,
                              onChange: (function (scheduledAt) {
                                  var withActualTempWorkers = MultipleAssignees_Utils.withActualTempWorkers(state.assignees, Locale.T.fromDateTime(scheduledAt, wire), ctx.allAssignees);
                                  if (Caml_obj.equal(state.assignees, withActualTempWorkers)) {
                                    setState(function (s) {
                                          return {
                                                  scheduledAt: scheduledAt,
                                                  assignees: s.assignees
                                                };
                                        });
                                  } else {
                                    setState(function (param) {
                                          return {
                                                  scheduledAt: scheduledAt,
                                                  assignees: withActualTempWorkers
                                                };
                                        });
                                  }
                                  return true;
                                }),
                              calendarPlacement: "top",
                              disabled: locked
                            })), React.createElement("div", {
                          className: styles.group
                        }, React.createElement("label", undefined, assignee$p(ctx)), React.createElement(MultipleAssignees.make, tmp)), React.createElement("footer", undefined, React.createElement("button", {
                              className: "btn",
                              type: "button",
                              onClick: (function (param) {
                                  setOpen(function (param) {
                                        return false;
                                      });
                                })
                            }, cancel$p(ctx)), React.createElement("button", {
                              className: "btn btn-primary",
                              disabled: locked
                            }, save$p(ctx)))));
  }
}

function MapFocusedJob(Props) {
  var wire = Props.wire;
  var job = Props.job;
  var onClick = Props.onClick;
  var onClose = Props.onClose;
  var channel = Props.channel;
  return React.createElement("div", {
              className: El.Cn.concat(styles.focusedJob, styles.focusedEntity)
            }, React.createElement("section", undefined, React.createElement("div", {
                      className: styles.dismiss,
                      onClick: Fun.omit(onClose)
                    }, El.times), React.createElement("h3", undefined, React.createElement("a", {
                          href: Route.pagePath({
                                hd: {
                                  NAME: "Job",
                                  VAL: {
                                    NAME: "Edit",
                                    VAL: ModalQuery.Job.EditOptions.make(undefined, undefined, job.uuid)
                                  }
                                },
                                tl: /* [] */0
                              }, {
                                NAME: "Job",
                                VAL: "Index"
                              }),
                          onClick: El.replaceClick(onClick)
                        }, El.Cn.concatOpt(jobNo$p({
                                  serialNo: job.serialNo
                                }, wire.ctx), job.templateName))), React.createElement(MapFocusedJob$Summary, {
                      job: job,
                      wire: wire
                    }), React.createElement(MapFocusedJob$Address, {
                      job: job,
                      onClick: (function (_event) {
                          MapTypes.EngineChannel.locateJob(channel)(job);
                        })
                    }), site(job, wire), client(job, wire)), React.createElement(MapFocusedJob$EditForm, {
                  wire: wire,
                  job: job,
                  onUpdate: onClose
                }));
}

var make = MapFocusedJob;

export {
  make ,
}
/* jobNo' Not a pure module */
