

import * as Uuid from "../../../../../../../../bindings/uuid/Uuid.mjs";
import * as Route from "../../../../../../../../types/Route.mjs";
import * as Types from "../../../../../../../../types/Types.mjs";
import * as Utils from "../../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as ModalQuery from "../../../../../../../../types/ModalQuery.mjs";
import * as JobEdit_Hooks from "../../../../../lib/hooks/JobEdit_Hooks.mjs";
import * as Shared_Lib_Array from "../../../../../../../../shared/lib/Shared_Lib_Array.mjs";
import * as JobForm_ScheduleOverlapsWarningModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/edit/form/components/body/schedule_warnings/overlaps_warning/JobForm_ScheduleOverlapsWarning.module.css";

function headline$p(count, c) {
  return Utils.Translations.txr("js.jobs.messages.overlapping")({
              count: count
            }, c);
}

function serialNo$p(serialNo, c) {
  return Utils.Translations.txr("js.jobs.messages.serial_no")({
              serial_no: Types.Id.toString(serialNo)
            }, c);
}

function andNJobsMore$p(count, c) {
  return Utils.Translations.txr("js.jobs.messages.and_n_jobs_more")({
              count: count
            }, c);
}

var styles = JobForm_ScheduleOverlapsWarningModuleCss;

function JobForm_ScheduleOverlapsWarning(Props) {
  var wire = Props.wire;
  var except = Props.except;
  var assignees = Props.assignees;
  var scheduledAt = Props.scheduledAt;
  var scheduledDuration = Props.scheduledDuration;
  var shownJobsCountOpt = Props.shownJobsCount;
  var shownJobsCount = shownJobsCountOpt !== undefined ? shownJobsCountOpt : 5;
  var overlappingJobs = JobEdit_Hooks.useOverlappingJobs(wire, assignees, scheduledAt, scheduledDuration, except);
  var sizeOfJobs = overlappingJobs.length;
  var makeJobsBlock = function (jobs) {
    var __x = jobs.map(function (job) {
          return React.createElement("a", {
                      key: Uuid.toString(job.uuid),
                      className: styles.warning,
                      href: Route.pagePath({
                            hd: {
                              NAME: "Job",
                              VAL: {
                                NAME: "Edit",
                                VAL: ModalQuery.Job.EditOptions.make(undefined, undefined, job.uuid)
                              }
                            },
                            tl: /* [] */0
                          }, {
                            NAME: "Job",
                            VAL: "Index"
                          }),
                      target: "_blank"
                    }, serialNo$p(job.serialNo, wire.ctx));
        });
    return Shared_Lib_Array.intersperse(", ", __x);
  };
  if (sizeOfJobs === 0) {
    return null;
  }
  if (sizeOfJobs <= shownJobsCount) {
    return React.createElement("div", {
                className: styles.warnings
              }, headline$p(sizeOfJobs, wire.ctx), makeJobsBlock(overlappingJobs));
  }
  var remainingCount = sizeOfJobs - shownJobsCount | 0;
  return React.createElement("div", {
              className: styles.warnings
            }, headline$p(sizeOfJobs, wire.ctx), makeJobsBlock((function (__x) {
                      return Shared_Lib_Array.take(shownJobsCount, __x);
                    })(overlappingJobs)), andNJobsMore$p(remainingCount, wire.ctx));
}

var make = JobForm_ScheduleOverlapsWarning;

export {
  make ,
}
/* styles Not a pure module */
