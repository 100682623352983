

import * as El from "../../../../../../libraries/El.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as DayTimeline_State from "../../lib/state/DayTimeline_State.mjs";
import * as Schedule_TimelineHooks from "../../../../lib/hooks/Schedule_TimelineHooks.mjs";
import * as Schedule_TimelineWidget from "../../../../components/timeline/widget/Schedule_TimelineWidget.mjs";
import * as DayTimeline_UnassignedBars from "./DayTimeline_UnassignedBars.mjs";
import * as DayTimeline_UnassignedHooks from "./DayTimeline_UnassignedHooks.mjs";
import * as DayTimeline_UnassignedStyle from "./DayTimeline_UnassignedStyle.mjs";
import * as Schedule_TimelineUnassignedHeader from "../../../../components/timeline/unassigned_header/Schedule_TimelineUnassignedHeader.mjs";
import * as DayTimeline_UnassignedModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/day_timeline/components/unassigned/DayTimeline_Unassigned.module.css";

var empty$p = Utils.Translations.tr("js.schedule.v2.jobs.no_unassigned_added");

var drop$p = Utils.Translations.tr("js.schedule.v2.jobs.drop");

var styles = DayTimeline_UnassignedModuleCss;

function DayTimeline_Unassigned(Props) {
  var viewportEl = Props.viewportEl;
  var unassignedEl = Props.unassignedEl;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var sizes = DayTimeline_State.useSizes();
  var businessHours = Schedule_State.useBusinessHours();
  var bars = DayTimeline_UnassignedHooks.Bars.use();
  var match$1 = React.useState(function () {
        return true;
      });
  var setCompactMode = match$1[1];
  var compactMode = match$1[0];
  var toggleMode = function () {
    setCompactMode(function (prev) {
          return !prev;
        });
  };
  DayTimeline_UnassignedHooks.Scroll.useVertical(unassignedEl);
  var barCount = bars.length;
  var jobsCount = RCore.$$Array.reduce(bars, 0, (function (count, param) {
          return count + param[1].length | 0;
        }));
  var match$2 = DayTimeline_UnassignedHooks.Drop.use(viewportEl, compactMode, toggleMode);
  var isOver = match$2[1];
  var messageBlock = isOver ? drop$p(ctx) : (
      barCount === 0 ? empty$p(ctx) : null
    );
  var style = DayTimeline_UnassignedStyle.use(compactMode, barCount);
  var className = El.Cn.concat(El.Cn.concat(styles.viewport, isOver ? styles.drop : ""), barCount === 0 ? styles.empty : "");
  var contentClassName = El.Cn.concat(El.Cn.concat(compactMode ? styles.hidden : styles.content, barCount === 1 ? styles.compact : ""), RCore.$$Option.isSome(businessHours) ? styles.withBusinessHours : "");
  var match$3 = Schedule_TimelineHooks.DragCursor.use();
  var timelineClassName = barCount === 0 || isOver ? styles.hidden : El.Cn.concat(styles.timeline, match$3[2] ? styles.isDrag : "");
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  ref: match$2[0],
                  className: styles.container,
                  style: style
                }, React.createElement(Schedule_TimelineUnassignedHeader.make, {
                      compactMode: compactMode,
                      toggleMode: toggleMode,
                      jobsCount: jobsCount
                    }), React.createElement("div", {
                      className: contentClassName
                    }, React.createElement("div", {
                          className: className,
                          style: DayTimeline_UnassignedStyle.viewport(barCount, sizes.viewport)
                        }, React.createElement("div", {
                              ref: unassignedEl,
                              className: timelineClassName,
                              style: DayTimeline_UnassignedStyle.timeline(sizes.timeline),
                              onMouseDown: match$3[0],
                              onMouseUp: match$3[1]
                            }, React.createElement(DayTimeline_UnassignedBars.make, {
                                  bars: bars
                                })), messageBlock))), React.createElement(Schedule_TimelineWidget.make, {
                  view: "day",
                  withUnassignedJobs: !compactMode
                }));
}

var make = DayTimeline_Unassigned;

export {
  make ,
}
/* empty' Not a pure module */
