import { applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { createStore } from 'planado/stores/utils'
import { camelize } from 'planado/utils/index.js'
import { useSubscription } from 'rscrpt/libraries/Websocket.mjs'

import {
  addLocation,
  updateLocation,
  addJob,
  updateJob,
  removeJob,
} from 'planado/actions/map'
import { groupLatestLocations } from 'planado/utils/map'
import { listToTree } from 'planado/utils/index.js'
import reducer from 'planado/reducer'
import defaultState from './initial_state'

class EngineChannel {
  constructor() {
    this._queue = []
    this._onPush = null
  }

  push = (action) => {
    if (this._onPush === null) {
      this._queue.push(action)
    } else {
      this._onPush(action)
    }
  }

  subscribe = (callback) => {
    this._onPush = callback

    this._queue.forEach((e) => callback(e))
    this._queue = []
  }
}

export function createChannel() {
  return new EngineChannel()
}

export const createMapStore = ({ data, engineChannel }, _socket) => {
  const {
    currentJobs,
    latestLocations,
    workers,
    time = new Date(),
    ...rest
  } = camelize(data)

  const initialState = {
    ...defaultState,
    ...rest,
    workers: listToTree(workers),
    currentJobs: listToTree(currentJobs),
    latestLocations: groupLatestLocations(latestLocations),
    engineChannel,
    currentTime: time,
    time: new Date(),
  }

  return createStore(reducer, initialState, applyMiddleware(thunk))
}

export const Subscriptions = ({ store, channel, children }) => {
  useSubscription(channel, 'location_update', ({ locations }) => {
    locations.forEach((l) => {
      store.dispatch(addLocation(l))
    })
  })

  useSubscription(channel, 'job_created', (job) => {
    store.dispatch(addJob(job))
  })

  useSubscription(channel, 'job_updated', ({ job, location }) => {
    store.dispatch(updateJob(job))

    if (location !== null) {
      store.dispatch(updateLocation(location))
    }
  })

  useSubscription(channel, 'job_removed', ({ uuid }) => {
    store.dispatch(removeJob({ uuid }))
  })

  return children
}
