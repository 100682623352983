

import * as El from "../../../../../libraries/El.mjs";
import * as Hooks from "../../../../../libraries/hooks/Hooks.mjs";
import * as Types from "../../../../../types/Types.mjs";
import * as React from "react";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as Schedule_Types_Job from "../../../lib/types/job/Schedule_Types_Job.mjs";
import * as Schedule_JobPreviewForm from "./form/Schedule_JobPreviewForm.mjs";
import * as Schedule_JobPreviewInfo from "./info/Schedule_JobPreviewInfo.mjs";
import * as Schedule_JobPreviewFooter from "./footer/Schedule_JobPreviewFooter.mjs";
import * as Schedule_JobPreviewHeader from "./header/Schedule_JobPreviewHeader.mjs";
import * as Schedule_JobPreviewModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/popovers/job_preview/Schedule_JobPreview.module.css";

var styles = Schedule_JobPreviewModuleCss;

function Schedule_JobPreview(Props) {
  var job = Props.job;
  var close = Props.close;
  var wire = AppContext.useWire();
  var match = React.useState(function () {
        return false;
      });
  var setShowForm = match[1];
  var showForm = match[0];
  var elementRef = Types.ReactRef.use();
  Hooks.useOnClickOutside(undefined, elementRef, close);
  Hooks.useOnEscPress(undefined, close);
  var toggleJobForm = function () {
    if (Schedule_Types_Job.ScheduledJob.hasRightsToEdit(job, wire)) {
      return setShowForm(function (prev) {
                  return !prev;
                });
    }
    
  };
  var className = El.Cn.concat(styles.container, showForm ? styles.withForm : "");
  return React.createElement("div", {
              ref: elementRef,
              className: className
            }, React.createElement(Schedule_JobPreviewHeader.make, {
                  close: close,
                  job: job
                }), showForm ? React.createElement(Schedule_JobPreviewForm.make, {
                    job: job,
                    closePreview: close,
                    closeForm: toggleJobForm
                  }) : React.createElement(Schedule_JobPreviewInfo.make, {
                    job: job,
                    toggleJobForm: toggleJobForm
                  }), React.createElement(Schedule_JobPreviewFooter.make, {
                  job: job
                }));
}

var make = React.memo(Schedule_JobPreview);

export {
  make ,
}
/* styles Not a pure module */
