import { connect } from 'react-redux'

import { hideFocusedJob } from 'planado/actions/map'
import FocusedJob from 'planado/components/map/focused_job'

const mapStateToProps = ({ jobs, focusedJobUuid, engineChannel }) => {
  return {
    job: jobs[focusedJobUuid] || null,
    engineChannel: engineChannel
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClose: () => dispatch(hideFocusedJob())
  }
}

const FocusedJobLink = connect(mapStateToProps, mapDispatchToProps)(FocusedJob)

export default FocusedJobLink
