import React, { Fragment } from 'react'
import WorkerItemLink from 'planado/containers/map/worker_item_link'

import { showTime } from 'planado/utils/time/index.js'
import JobFilterLink from 'planado/containers/map/job_filter_link'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import * as styles from './styles.module.css'

const WorkersWithLocation = withContext(({ children, currentTime, ctx }) => {
  const now = `${ctx.t('js.localizator.time.today')}, ${showTime(
    currentTime,
    'date',
    ctx
  )}`
  let content

  if (children.length > 0) {
    content = (
      <ul className={`${styles.workerNames} ${styles.withLocation}`}>
        {children}
      </ul>
    )
  } else {
    const text = ctx.t('js.map.no_data_for', {
      date: ctx.t('js.localizator.time.today').toLowerCase()
    })

    content = <div className={styles.empty}>{text}</div>
  }

  return (
    <Fragment>
      <div className={styles.title}>{now}</div>
      {content}
    </Fragment>
  )
})

const WorkersWithStaleLocation = withContext(
  ({ open, children, onToggle, ctx }) => {
    const toggle = () => onToggle(open)
    const title = ctx.t('js.map.no_locations_for_today')

    if (children.length === 0) {
      return <div />
    } else if (open) {
      return (
        <div>
          <hr className={styles.bar} />
          <div
            className={`${styles.title} ${styles.collapsable}`}
            onClick={toggle}
          >
            <span className={styles.collapseList}>
              <i className="fa fa-chevron-down" />
            </span>
            {title}
          </div>
          <ul className={styles.workerNames}>{children}</ul>
        </div>
      )
    } else {
      return (
        <div>
          <hr className={styles.bar} />
          <div
            className={`${styles.title} ${styles.collapsable}`}
            onClick={toggle}
          >
            <span className={styles.collapseList}>
              <i className="fa fa-chevron-right" />
            </span>
            {title}
          </div>
        </div>
      )
    }
  }
)

const Container = ({ children }) => (
  <div className={styles.workerList}>
    <div className={styles.allWorkers}>
      <div className={styles.listWrapper}>{children}</div>
      <JobFilterLink />
    </div>
  </div>
)

const NoLocations = withContext(({ ctx }) => {
  let locationGuide
  if (ctx.branding && ctx.branding.brandName) {
    locationGuide = ctx.t('js.map.location_guide_white_label', {
      name: ctx.branding.brandName
    })
  } else {
    locationGuide = ctx.t('js.map.location_guide')
  }

  return (
    <div className={styles.noLocationsNotice}>
      <p>{ctx.t('js.map.no_locations')}</p>
      <p>{locationGuide}</p>
    </div>
  )
})

const WorkerList = ({ locationsExist, ...props }) => {
  if (locationsExist) {
    const wLocation = props.workersWithLocation
    const woLocation = props.workersWithStaleLocation

    return (
      <Container>
        <WorkersWithLocation currentTime={props.currentTime}>
          {wLocation.map(w => (
            <WorkerItemLink key={w.id} worker={w} hasDayLocation />
          ))}
        </WorkersWithLocation>
        <WorkersWithStaleLocation
          open={props.showWorkersWithStaleLocation}
          onToggle={props.onWorkersWOLocationToggle}
        >
          {woLocation.map(w => (
            <WorkerItemLink key={w.id} worker={w} hasDayLocation={false} />
          ))}
        </WorkersWithStaleLocation>
      </Container>
    )
  } else {
    return (
      <Container>
        <NoLocations />
      </Container>
    )
  }
}

export default WorkerList
