class Throttler {
  constructor(timeout, callback, context, runCallbackOnThrottlingFinish) {
    this.call = this.call.bind(this)
    this.stopThrottling = this.stopThrottling.bind(this)
    this.runCallback = this.runCallback.bind(this)
    this.reset = this.reset.bind(this)
    this.timeout = timeout
    this.callback = callback
    this.context = context
    if (runCallbackOnThrottlingFinish == null) {
      runCallbackOnThrottlingFinish = false
    }
    this.runCallbackOnThrottlingFinish = runCallbackOnThrottlingFinish
    this._throttling = false
    this._descriptor = null
  }

  call(...args) {
    this._lastArgs = args
    if (this._throttling) {
      return
    }
    this._throttling = true
    this.runCallback()

    this._descriptor = setTimeout(this.stopThrottling, this.timeout)
  }

  stopThrottling() {
    this._descriptor = null
    this._throttling = false
    if (this.runCallbackOnThrottlingFinish) {
      this.runCallback()
    }
  }

  runCallback() {
    return this.callback.apply(this.context, this._lastArgs)
  }

  reset() {
    if (this._descriptor) {
      clearTimeout(this._descriptor)
    }
    this._descriptor = null
    this._throttling = false
  }
}

export default Throttler
