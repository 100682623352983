import { partition } from 'planado/reducers/utils'
import {
  TEMPLATE_SET_FIELD,
  TEMPLATE_ADD_CUSTOM_FIELD,
  TEMPLATE_MOVE_CUSTOM_FIELD,
  TEMPLATE_SET_CUSTOM_FIELD,
  TEMPLATE_DELETE_CUSTOM_FIELD,
  TEMPLATE_SET_CUSTOM_FIELD_FILE,
  TEMPLATE_ADD_RESOLUTION,
  TEMPLATE_MOVE_RESOLUTION,
  TEMPLATE_DELETE_RESOLUTION,
  TEMPLATE_SET_POSSIBLE_ASSIGNEES,
  GET_POSSIBLE_FIELD_NAMES_SUCCESS,
  GET_POSSIBLE_FIELD_NAMES_FAIL,
  TEMPLATE_ADD_ERROR,
  CHECK_NAME_REQUEST,
  CHECK_NAME_SUCCESS,
  CHECK_NAME_FAIL,
  SHOW_POPOVER,
  SHOW_MODAL,
  SET_TEMPLATE,
  CHANGE_SKILLS,
  CHANGE_TERRITORY,
  SEND_FORM_REQUEST,
  SEND_FORM_SUCCESS,
  SEND_FORM_FAIL,
  TEMPLATE_SET_TRACK_MOVEMENTS,
  TEMPLATE_SET_ASSIGNEES,
  TEMPLATE_SET_CAN_BE_USED_ON_MOBILE,
  SET_SERVICES,
  SET_CATEGORIES,
} from 'planado/constants/admin/templates'

const updateCustomField = (reportFields, index, name, value) => [
  ...reportFields.slice(0, index),
  {
    ...reportFields[index],
    [name]: value,
  },
  ...reportFields.slice(index + 1),
]

const updateCustomFieldFile = (reportFields, index, value) => {
  if (value !== undefined) {
    return [
      ...reportFields.slice(0, index),
      {
        ...reportFields[index],
        value: value,
        removeFile: value !== null ? reportFields[index].removeFile : true,
      },
      ...reportFields.slice(index + 1),
    ]
  } else {
    return [...reportFields]
  }
}

const deleteCustomField = (reportFields, fieldUuid, isNew) => {
  if (isNew) {
    return reportFields.filter((f, _) => f.fieldUuid !== fieldUuid)
  } else {
    return reportFields.map((field, _) => {
      if (field.fieldUuid === fieldUuid) {
        return {
          ...field,
          isDestroyed: true,
          name: 'destroyed',
        }
      } else {
        return field
      }
    })
  }
}

const reducer = (state = {}, action) => {
  switch (action.type) {
    case TEMPLATE_SET_TRACK_MOVEMENTS:
      return {
        ...state,
        template: {
          ...state.template,
          trackMovements: action.trackMovements,
        },
      }
    case TEMPLATE_SET_CAN_BE_USED_ON_MOBILE:
      return {
        ...state,
        template: {
          ...state.template,
          canBeUsedOnMobile: action.canBeUsedOnMobile,
        },
      }
    case TEMPLATE_SET_FIELD:
      return {
        ...state,
        template: {
          ...state.template,
          [action.name]: action.value,
          update: false,
        },
      }
    case TEMPLATE_SET_ASSIGNEES:
      return {
        ...state,
        template: {
          ...state.template,
          assignees: action.value,
        },
      }
    case TEMPLATE_ADD_CUSTOM_FIELD:
      if (action.customField.isCustomField) {
        return {
          ...state,
          customFields: [...state.customFields, action.customField],
        }
      } else {
        return {
          ...state,
          reportFields: [...state.reportFields, action.customField],
        }
      }
    case TEMPLATE_MOVE_CUSTOM_FIELD:
      const { fields, isCustomField } = action.value

      if (isCustomField) {
        return {
          ...state,
          customFields: fields,
        }
      } else {
        return {
          ...state,
          reportFields: fields,
        }
      }
    case TEMPLATE_SET_CUSTOM_FIELD:
      if (action.isCustomField) {
        return {
          ...state,
          customFields: updateCustomField(
            state.customFields,
            action.index,
            action.name,
            action.value
          ),
        }
      } else {
        return {
          ...state,
          reportFields: updateCustomField(
            state.reportFields,
            action.index,
            action.name,
            action.value
          ),
        }
      }
    case TEMPLATE_DELETE_CUSTOM_FIELD:
      if (action.isCustomField) {
        return {
          ...state,
          customFields: deleteCustomField(
            state.customFields,
            action.fieldUuid,
            action.isNew
          ),
        }
      } else {
        return {
          ...state,
          reportFields: deleteCustomField(
            state.reportFields,
            action.fieldUuid,
            action.isNew
          ),
        }
      }
    case TEMPLATE_SET_CUSTOM_FIELD_FILE:
      if (action.isCustomField) {
        return {
          ...state,
          customFields: updateCustomFieldFile(
            state.customFields,
            action.index,
            action.value
          ),
        }
      } else {
        return {
          ...state,
          reportFields: updateCustomFieldFile(
            state.reportFields,
            action.index,
            action.value
          ),
        }
      }
    case TEMPLATE_ADD_RESOLUTION: {
      let [currentSuccessful, currentUnsuccessful] = partition(
        state.possibleResolutionIds,
        (id) => state.resolutions.some((r) => r.id === id && r.successful)
      )
      let [insertedSuccessful, insertedUnsuccessful] = partition(
        action.possibleResolutionIds,
        (id) => state.resolutions.some((r) => r.id === id && r.successful)
      )

      const resolutions = state.resolutions.map((resolution) => {
        if (action.possibleResolutionIds.includes(resolution.id)) {
          resolution.selected = true
        }

        return resolution
      })

      return {
        ...state,
        possibleResolutionIds: [
          ...currentSuccessful,
          ...insertedSuccessful,
          ...currentUnsuccessful,
          ...insertedUnsuccessful,
        ],
        resolutions: resolutions,
      }
    }
    case TEMPLATE_MOVE_RESOLUTION: {
      const { resolutions, successful } = action.value

      const separatelySwapped = () => {
        const res = resolutions.map((r) => r.id)

        const [currentSucc, currentUnsucc] = partition(
          state.possibleResolutionIds,
          (id) => state.resolutions.some((r) => r.id === id && r.successful)
        )

        if (successful) {
          return [...res, ...currentUnsucc]
        } else {
          return [...currentSucc, ...res]
        }
      }

      return {
        ...state,
        possibleResolutionIds: separatelySwapped(),
      }
    }
    case TEMPLATE_DELETE_RESOLUTION:
      return {
        ...state,
        resolutions: state.resolutions.map((resolution) => {
          if (resolution.id === action.id) {
            resolution.selected = false
          }
          return resolution
        }),
        possibleResolutionIds: state.possibleResolutionIds.filter(
          (id) => id !== action.id
        ),
      }
    case TEMPLATE_ADD_ERROR:
      return {
        ...state,
        errors: { ...state.errors, [action.name]: action.error },
      }
    case TEMPLATE_SET_POSSIBLE_ASSIGNEES:
      return {
        ...state,
        possibleAssignees: action.possibleAssignees,
      }
    case CHECK_NAME_REQUEST: {
      return state
    }
    case CHECK_NAME_FAIL: {
      return {
        ...state,
        errors: {
          ...state.errors,
          template: {
            name: Array.of(action.error),
          },
        },
      }
    }
    case CHECK_NAME_SUCCESS:
      return {
        ...state,
        errors: {
          ...state.errors,
          template: {
            name: [],
          },
        },
      }
    case GET_POSSIBLE_FIELD_NAMES_SUCCESS:
      return {
        ...state,
        possibleFieldNames: {
          ...state.possibleFieldNames,
          [action.fieldType]: action.possibleFieldNames,
        },
      }
    case GET_POSSIBLE_FIELD_NAMES_FAIL:
      return {
        ...state,
        possibleFieldNames: {
          ...state.possibleFieldNames,
          [action.fieldType]: [],
        },
      }
    case SHOW_POPOVER:
      return {
        ...state,
        popover: {
          node: action.node,
          targetId: action.buttonId,
          isModal: false,
          dom: action.dom,
        },
      }
    case SHOW_MODAL:
      return {
        ...state,
        popover: {
          node: action.node,
          isModal: true,
        },
      }
    case SET_TEMPLATE:
      return {
        ...state,
        ...action.newState,
        template: {
          ...action.newState.template,
          update: true,
        },
      }
    case CHANGE_SKILLS:
      return {
        ...state,
        skillUuids: action.skillUuids,
      }
    case CHANGE_TERRITORY:
      return {
        ...state,
        territoryUuid: action.territoryUuid,
      }
    case 'WINDOW_CLICKED':
      return {
        ...state,
        popover: null,
      }
    case SEND_FORM_REQUEST:
      return {
        ...state,
        formDisabled: true,
      }
    case SEND_FORM_SUCCESS:
    case SEND_FORM_FAIL:
      return {
        ...state,
        formDisabled: false,
      }
    case SET_CATEGORIES: {
      return {
        ...state,
        catalog: {
          ...state.catalog,
          categories: action.categories,
        },
      }
    }
    case SET_SERVICES: {
      return {
        ...state,
        catalog: {
          ...state.catalog,
          services: action.services,
        },
      }
    }

    default:
      return state
  }
}

export default reducer
