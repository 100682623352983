import React from 'react'
import Field from './field'
import Hint from 'planado/components/hint'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import * as styles from './styles.module.css'
import { v6 as uuidv6 } from 'uuid-with-v6'
import { make as DropDownMenu } from 'rscrpt/components/common/DropDownMenu/DropDownMenu.mjs'

import { SortableList } from './sortable_list'

const MAX_FIELDS_COUNT = 100

export default
  withContext(
    ({
      isCustomField,
      types,
      fields,
      customFields,
      dictionaries,
      possibleFieldNames,
      actions,
      onDragEnd,
      ctx,
    }) => {
      const numberOfFields = fields.filter((field) => !field.isDestroyed).length

      const inputName = (idx, name) =>
        isCustomField
          ? `template[custom_fields][${idx}][${name}]`
          : `template[report_fields][${idx}][${name}]`

      const addField = (field) => (e) => {
        e.preventDefault()
        actions.addCustomField({
          fieldUuid: uuidv6(),
          isNew: true,
          typeId: null,
          fieldType: field.fieldType,
          dataType: field.dataType,
          name: null,
          value: null,
          required: field.fieldType === 'action',
          hidden: false,
          readOnly: false,
          isDestroyed: false,
          dictionaryUuid: null,
          signedFieldUuid: null,
          urlValue: null,
          reusableButton: false,
          useGallery: false,
          isCustomField: isCustomField,
          errors: [],
        })
      }

      let requiredBlock
      if (!isCustomField && numberOfFields > 0) {
        requiredBlock = (
          <div className="custom-fields__required">
            {ctx.t('js.templates.custom_fields.labels.required')}
            <Hint
              content={ctx.t('js.templates.custom_fields.hints.required')}
              modifiers={{ hint_required: true }}
            />
          </div>
        )
      } else {
        requiredBlock = null
      }

      const fieldsLimitExceeded = numberOfFields >= MAX_FIELDS_COUNT

      return (
        <div className="custom-fields">
          {requiredBlock}

          <div>
            {numberOfFields === 0 ? (
              <div className="custom-fields__empty">
                {isCustomField
                  ? ctx.t('js.templates.info.hints.custom_fields')
                  : ctx.t('js.templates.custom_fields.empty')}
              </div>
            ) : null}

            <SortableList items={fields} onDragEnd={onDragEnd}>
              {fields.map((field, i) =>
                <Field
                  {...field}
                  {...actions}
                  idx={i}
                  isCustomField={isCustomField}
                  dictionaries={dictionaries}
                  possibleFieldNames={possibleFieldNames}
                  inputName={inputName}
                  fields={fields}
                  customFields={
                    field.fieldType === 'signature' ? customFields : []
                  }
                  key={field.fieldUuid}
                />)
              }
            </SortableList>
          </div>

          <div className="pd-form-group pd-form-group-half">
            <DropDownMenu
              button={(ref) => (
                <button
                  ref={ref}
                  className="custom-fields__btn btn"
                  type="button"
                  disabled={fieldsLimitExceeded}
                >
                  <i className="fa fa-plus" />
                  {isCustomField
                    ? ctx.t('js.templates.custom_fields.buttons.add_read_only')
                    : ctx.t('js.templates.custom_fields.buttons.add')}
                </button>
              )}
            >
              <>
                {types.map((type, idx) => (
                  <li key={idx}>
                    <a
                      href="#"
                      className={
                        styles[type.fieldType] ||
                        styles[type.dataType] ||
                        styles[type.fieldType]
                      }
                      data-name={type.name}
                      onClick={addField(type)}
                    >
                      {type.text}
                    </a>
                  </li>
                ))}
              </>
            </DropDownMenu>

            {fieldsLimitExceeded && (
              <p styleName="fieldsLimit">
                {ctx.t('js.templates.custom_fields.errors.fields_limit')}
              </p>
            )}
          </div>
        </div>
      )
    }
  )
