

import * as Hooks from "../../../libraries/hooks/Hooks.mjs";
import * as Modal from "../../modal/Modal.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Title from "../../common/Title.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Button from "../../common/Button.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as DOMFile from "../../../libraries/DOMFile.mjs";
import * as Labeled from "../../inputs/Labeled.mjs";
import * as Popover from "../../common/Popover/Popover.mjs";
import * as Spinner from "../../common/Spinner/Spinner.mjs";
import * as ApiRoute from "../../../types/ApiRoute.mjs";
import * as PromiseF from "../../../libraries/PromiseF.mjs";
import * as FormInput from "../../inputs/FormInput.mjs";
import * as IntSelect from "../../inputs/IntSelect.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as OldFileInput from "../../inputs/OldFileInput/OldFileInput.mjs";
import * as Backend_Errors from "../../../libraries/backend/Backend_Errors.mjs";
import * as ClientForms_API from "./ClientForms_API.mjs";
import * as ClientForms_Types from "./ClientForms_Types.mjs";
import * as ClientFormsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/client_forms/ClientForms.module.css";

var save$p = Utils.Translations.tr("common.save");

var add$p = Utils.Translations.tr("common.add");

var cancel$p = Utils.Translations.tr("common.cancel");

var delete$p = Utils.Translations.tr("common.remove");

var confirm$p = Utils.Translations.tr("common.yes_remove");

var name$p = Utils.Translations.t("js.admin.client_forms.name");

var form$p = Utils.Translations.t("js.admin.client_forms.form");

var replace$p = Utils.Translations.tr("js.admin.client_forms.replace");

var deleteForm$p = Utils.Translations.tr("js.admin.client_forms.delete_form");

var default$p = Utils.Translations.t("js.titles.client_forms.default_form");

var download$p = Utils.Translations.t("js.admin.client_forms.download");

var limitMsg$p = Utils.Translations.t("js.admin.client_forms.limit_msg");

var availabilityPeriod$p = Utils.Translations.t("js.admin.client_forms.availability_period");

function hours$p(hours, c) {
  return Utils.Translations.tx("js.admin.client_forms.hours")({
              count: hours
            }, c);
}

function days$p(days, c) {
  return Utils.Translations.tx("js.admin.client_forms.days")({
              count: days
            }, c);
}

var styles = ClientFormsModuleCss;

function ClientForms_Show$DownloadLink(Props) {
  var uuid = Props.uuid;
  var replaceFile = Props.replaceFile;
  var name = Props.name;
  var match = AppContext.useWire();
  return React.createElement("div", {
              className: styles.downloadLink
            }, React.createElement("a", {
                  download: "download",
                  href: ApiRoute.path({
                        NAME: "API",
                        VAL: {
                          NAME: "ClientForm",
                          VAL: {
                            NAME: "Download",
                            VAL: uuid
                          }
                        }
                      }),
                  target: "_blank"
                }, name), replaceFile !== undefined ? React.createElement("div", undefined, React.createElement(Button.make, {
                        onClick: replaceFile,
                        children: replace$p(match.ctx)
                      })) : null);
}

function ClientForms_Show(Props) {
  var mode = Props.mode;
  var onAction = Props.onAction;
  var wire = AppContext.useWire();
  var navigate = wire.navigate;
  var route = wire.route;
  var ctx = wire.ctx;
  var optionsAvailability = React.useMemo((function () {
          return [
                  {
                    value: 1,
                    label: hours$p(1, ctx)
                  },
                  {
                    value: 3,
                    label: hours$p(3, ctx)
                  },
                  {
                    value: 12,
                    label: hours$p(12, ctx)
                  },
                  {
                    value: 24,
                    label: days$p(1, ctx)
                  },
                  {
                    value: 72,
                    label: days$p(3, ctx)
                  },
                  {
                    value: 168,
                    label: days$p(7, ctx)
                  },
                  {
                    value: 720,
                    label: days$p(30, ctx)
                  }
                ];
        }), []);
  var match = React.useState(function () {
        
      });
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        return Backend_Errors.empty;
      });
  var setErrors = match$1[1];
  var errors = match$1[0];
  var updateCustomState = function (f) {
    setState(function (s) {
          if (s !== undefined && s.TAG !== "Default") {
            return {
                    TAG: "Custom",
                    _0: f(s._0)
                  };
          } else {
            return s;
          }
        });
  };
  var new_ = typeof mode === "object" ? false : true;
  var match$2 = React.useMemo((function () {
          if (state === undefined) {
            return [
                    undefined,
                    null
                  ];
          }
          if (new_) {
            return [
                    undefined,
                    null
                  ];
          }
          var values;
          values = state.TAG === "Default" ? ({
                name: default$p(ctx)
              }) : ({
                name: state._0.name
              });
          return [
                  Caml_option.some(values),
                  React.createElement(Title.make, {
                        route: route,
                        ctx: ctx,
                        values: values,
                        wire: wire
                      })
                ];
        }), [RCore.$$Option.isSome(state)]);
  var match$3 = Hooks.useLocked(false);
  var unlock = match$3[2];
  var lock = match$3[1];
  var match$4 = match$3[0] || state === undefined ? [
      true,
      true
    ] : (
      state.TAG === "Default" ? [
          false,
          true
        ] : [
          false,
          false
        ]
    );
  var match$5 = React.useState(function () {
        return false;
      });
  var setConfirmation = match$5[1];
  var showConfirmation = match$5[0];
  var removeButtonId = Hooks.useId(undefined);
  var removeButton = React.useRef(null);
  Hooks.useToggleOnClick1(removeButton, showConfirmation, (function (show) {
          setConfirmation(function (param) {
                return show;
              });
        }));
  React.useEffect((function () {
          if (typeof mode === "object") {
            var loaded = function (r) {
              if (r.TAG === "Ok") {
                var s = r._0;
                return setState(function (param) {
                            return ClientForms_Types.Show.State.make(s.form);
                          });
              }
              console.log(r._0);
            };
            Backend.$$finally(ClientForms_API.Show.show(wire, mode.VAL), (function (l) {
                    Utils.debounce1(loaded, 300, false)(l);
                  }));
          } else {
            setState(function (param) {
                  return ClientForms_Types.Show.State.make(undefined);
                });
          }
        }), []);
  var fileId = Hooks.useId(undefined);
  var onSubmit = function (param) {
    var uuid;
    var form;
    if (state === undefined) {
      return ;
    }
    if (state.TAG === "Default") {
      uuid = state._0.uuid;
      form = state;
    } else {
      var s = state._0;
      var uuid$1 = s.uuid;
      if (uuid$1 !== undefined) {
        uuid = Caml_option.valFromOption(uuid$1);
        form = state;
      } else {
        var request = ClientForms_API.Create.$$Request.fromState(s);
        if (request === undefined) {
          return ;
        }
        var uploaded = function (result) {
          if (result.TAG === "Ok") {
            onAction();
            return navigate(undefined, {
                        NAME: "ClientForm",
                        VAL: "Index"
                      });
          }
          var errors = result._0;
          if (typeof errors !== "object") {
            return unlock();
          }
          if (errors.TAG !== "ValidationError") {
            return unlock();
          }
          var errors$1 = errors._0;
          unlock();
          setErrors(function (param) {
                return errors$1;
              });
        };
        lock();
        return Backend.$$finally(ClientForms_API.Create.create(wire, request), uploaded);
      }
    }
    var uploaded$1 = function (result) {
      if (result.TAG === "Ok") {
        onAction();
        return navigate(undefined, {
                    NAME: "ClientForm",
                    VAL: "Index"
                  });
      }
      var errors = result._0;
      if (typeof errors !== "object") {
        return unlock();
      }
      if (errors.TAG !== "ValidationError") {
        return unlock();
      }
      var errors$1 = errors._0;
      unlock();
      setErrors(function (param) {
            return errors$1;
          });
    };
    lock();
    Backend.$$finally(ClientForms_API.Update.update(wire, uuid, form), uploaded$1);
  };
  var setFile = function (file) {
    updateCustomState(function (s) {
          return {
                  uuid: s.uuid,
                  name: s.name,
                  file: s.file,
                  domFile: Caml_option.some(file),
                  availabilityPeriodMin: s.availabilityPeriodMin
                };
        });
    var __x = DOMFile.base64Content(file);
    PromiseF.$$finally((function (body) {
            updateCustomState(function (s) {
                  return {
                          uuid: s.uuid,
                          name: s.name,
                          file: {
                            TAG: "New",
                            name: file.name,
                            body: body
                          },
                          domFile: s.domFile,
                          availabilityPeriodMin: s.availabilityPeriodMin
                        };
                });
          }), (function (prim) {
            
          }), __x);
  };
  var clearFile = function () {
    updateCustomState(function (s) {
          return {
                  uuid: s.uuid,
                  name: s.name,
                  file: undefined,
                  domFile: undefined,
                  availabilityPeriodMin: s.availabilityPeriodMin
                };
        });
  };
  var replaceFile = function (param) {
    clearFile();
  };
  var tmp = {};
  var tmp$1 = match$2[0];
  if (tmp$1 !== undefined) {
    tmp.values = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2;
  if (state !== undefined) {
    if (state.TAG === "Default") {
      var d = state._0;
      tmp$2 = React.createElement(React.Fragment, undefined, React.createElement(ClientForms_Show$DownloadLink, {
                uuid: d.uuid,
                name: download$p(ctx)
              }), React.createElement(Labeled.make, {
                label: availabilityPeriod$p(ctx),
                children: React.createElement(IntSelect.make, {
                      onChange: (function (a) {
                          var f = function (s) {
                            return {
                                    uuid: s.uuid,
                                    availabilityPeriodMin: Math.imul(RCore.$$Option.getOr(a === null ? undefined : Caml_option.some(a), ClientForms_Types.Show.State.defaultavailabilityPeriodMin), 60)
                                  };
                          };
                          setState(function (s) {
                                if (s !== undefined && s.TAG === "Default") {
                                  return {
                                          TAG: "Default",
                                          _0: f(s._0)
                                        };
                                } else {
                                  return s;
                                }
                              });
                        }),
                      value: d.availabilityPeriodMin / 60 | 0,
                      options: optionsAvailability,
                      searchable: false,
                      clearable: false
                    })
              }));
    } else {
      var s = state._0;
      var tmp$3 = {
        _type: "input",
        value: s.name,
        onValueChange: (function (name) {
            updateCustomState(function (s) {
                  return {
                          uuid: s.uuid,
                          name: name,
                          file: s.file,
                          domFile: s.domFile,
                          availabilityPeriodMin: s.availabilityPeriodMin
                        };
                });
          }),
        labelText: name$p(ctx),
        autoFocus: true
      };
      var tmp$4 = Backend_Errors.get(errors, "name");
      if (tmp$4 !== undefined) {
        tmp$3.errors = Caml_option.valFromOption(tmp$4);
      }
      var match$6 = s.domFile;
      var match$7 = s.file;
      var match$8 = s.uuid;
      var tmp$5;
      var exit = 0;
      if (match$6 !== undefined || !(match$7 !== undefined && !(match$7.TAG === "New" || match$8 === undefined))) {
        exit = 1;
      } else {
        tmp$5 = React.createElement(ClientForms_Show$DownloadLink, {
              uuid: Caml_option.valFromOption(match$8),
              replaceFile: replaceFile,
              name: match$7.name
            });
      }
      if (exit === 1) {
        var tmp$6 = {
          ctx: ctx,
          onFileChooseAction: setFile,
          onFileClearAction: clearFile,
          file: s.domFile,
          limit: [
            1048576,
            limitMsg$p(ctx)
          ]
        };
        var tmp$7 = Backend_Errors.getFirst(errors, "file.name");
        if (tmp$7 !== undefined) {
          tmp$6.error = tmp$7;
        }
        tmp$5 = React.createElement(OldFileInput.make, tmp$6);
      }
      tmp$2 = React.createElement("form", undefined, React.createElement(FormInput.make, tmp$3), React.createElement(Labeled.make, {
                label: availabilityPeriod$p(ctx),
                children: React.createElement(IntSelect.make, {
                      onChange: (function (a) {
                          updateCustomState(function (s) {
                                return {
                                        uuid: s.uuid,
                                        name: s.name,
                                        file: s.file,
                                        domFile: s.domFile,
                                        availabilityPeriodMin: Math.imul(RCore.$$Option.getOr(a === null ? undefined : Caml_option.some(a), ClientForms_Types.Show.State.defaultavailabilityPeriodMin), 60)
                                      };
                              });
                        }),
                      value: s.availabilityPeriodMin / 60 | 0,
                      options: optionsAvailability,
                      searchable: false,
                      clearable: false
                    })
              }), React.createElement(Labeled.make, {
                label: form$p(ctx),
                id: fileId,
                children: tmp$5
              }));
    }
  } else {
    tmp$2 = React.createElement(Spinner.make, {});
  }
  var tmp$8;
  if (typeof mode === "object") {
    var uuid = mode.VAL;
    tmp$8 = React.createElement(Popover.make, {
          footer: wire.footer,
          show: showConfirmation,
          buttonId: removeButtonId,
          position: "right",
          children: React.createElement("div", {
                className: styles.removalConfirmation
              }, React.createElement("h5", undefined, deleteForm$p(ctx)), React.createElement("footer", undefined, React.createElement(Button.make, {
                        onClick: (function (param) {
                            lock();
                            setConfirmation(function (param) {
                                  return false;
                                });
                            Backend.$$finally(ClientForms_API.Delete.$$delete(wire, uuid), (function (param) {
                                    onAction();
                                    navigate(undefined, {
                                          NAME: "ClientForm",
                                          VAL: "Index"
                                        });
                                  }));
                          }),
                        flavor: "danger",
                        children: confirm$p(ctx)
                      }), React.createElement(Button.make, {
                        onClick: (function (param) {
                            setConfirmation(function (param) {
                                  return false;
                                });
                          }),
                        children: cancel$p(ctx)
                      })))
        });
  } else {
    tmp$8 = null;
  }
  return React.createElement(React.Fragment, undefined, match$2[1], React.createElement(Modal.make, {
                  wire: wire,
                  onClose: (function () {
                      navigate(undefined, {
                            NAME: "ClientForm",
                            VAL: "Index"
                          });
                    }),
                  children: React.createElement(Modal.Dialog.make, {
                        children: null
                      }, React.createElement(Modal.HeaderTitle.make, tmp), React.createElement(Modal.Body.make, {
                            className: styles.editForm,
                            children: tmp$2
                          }), React.createElement(Modal.Footer.make, {
                            children: null
                          }, typeof mode === "object" ? React.createElement(React.Fragment, undefined, React.createElement(Modal.RemoveButton.make, {
                                      id: removeButtonId,
                                      forwardRef: removeButton,
                                      disabled: match$4[1],
                                      children: delete$p(ctx)
                                    })) : null, React.createElement(Modal.CloseButton.make, {
                                ctx: ctx
                              }), React.createElement(Modal.SubmitButton.make, {
                                onClick: onSubmit,
                                disabled: match$4[0],
                                children: new_ ? add$p(ctx) : save$p(ctx)
                              })))
                }), tmp$8);
}

var make = ClientForms_Show;

export {
  make ,
}
/* save' Not a pure module */
