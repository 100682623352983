

import * as Fun from "../../../../libraries/Fun.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Types from "../../../../types/Types.mjs";
import * as Units from "../../../../types/Units.mjs";
import * as React from "react";
import * as DomUtils from "../../../../DomUtils.mjs";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as Schedule_DND from "../../lib/Schedule_DND.mjs";
import * as Schedule_State from "../../lib/state/Schedule_State.mjs";
import * as Schedule_Constants from "../../lib/Schedule_Constants.mjs";
import * as Schedule_Types_Job from "../../lib/types/job/Schedule_Types_Job.mjs";
import * as Webapi__Dom__HtmlElement from "rescript-webapi/lib/es6/src/Webapi/Dom/Webapi__Dom__HtmlElement.mjs";

function use() {
  var dispatch = Schedule_State.useDispatch();
  var match = React.useState(function () {
        return false;
      });
  var setIsOver = match[1];
  var onDrop = function (param) {
    setIsOver(function (param) {
          return false;
        });
    var match = Schedule_DND.DND.Context.payload(param.payload);
    dispatch({
          TAG: "Jobs",
          _0: {
            TAG: "JobUnscheduled",
            _0: match.uuid
          }
        });
  };
  var dropRef = Schedule_DND.DND.Droppable.use(undefined, (function () {
          setIsOver(function (param) {
                return true;
              });
        }), (function () {
          setIsOver(function (param) {
                return false;
              });
        }), [
        Schedule_Constants.dndScheduledJob,
        Schedule_Constants.dndUnassignedJob
      ], onDrop, undefined);
  return [
          dropRef,
          match[0]
        ];
}

var Droppable = {
  use: use
};

var basePadding = Units.Px.fromInt(7);

function use$1(elementRef) {
  var jobs = Schedule_State.useUnscheduledJobs();
  React.useEffect((function () {
          var elementOpt = Types.ReactRef.toOption(elementRef);
          var htmlElementOpt = RCore.$$Option.flatMap(elementOpt, Webapi__Dom__HtmlElement.ofElement);
          var match = Fun.both(elementOpt, htmlElementOpt);
          if (match !== undefined) {
            var htmlElement = match[1];
            var offsetHeight = htmlElement.offsetHeight;
            var scrollHeight = match[0].scrollHeight;
            var style = htmlElement.style;
            var padding = scrollHeight > offsetHeight ? basePadding : Units.Px.plus(DomUtils.scrollbarWidth(), basePadding);
            style.setProperty("padding-right", Units.Px.toString(padding));
          }
          
        }), [jobs]);
}

function use$2(job) {
  var wire = AppContext.useWire();
  var draggableJob_uuid = Schedule_Types_Job.UnscheduledJob.uuid(job);
  var draggableJob_template = Schedule_Types_Job.UnscheduledJob.template(job);
  var draggableJob_assignees = Schedule_Types_Job.UnscheduledJob.assignees(job);
  var draggableJob_address = Schedule_Types_Job.UnscheduledJob.address(job);
  var draggableJob_duration = Schedule_Types_Job.UnscheduledJob.duration(job);
  var draggableJob = {
    uuid: draggableJob_uuid,
    template: draggableJob_template,
    bar: undefined,
    assignees: draggableJob_assignees,
    address: draggableJob_address,
    scheduledAt: undefined,
    duration: draggableJob_duration
  };
  return Schedule_DND.DND.Draggable.use(undefined, Schedule_Types_Job.UnscheduledJob.hasRightsToEdit(job, wire), Schedule_DND.DND.Context.makePayload(draggableJob), Schedule_Constants.dndUnscheduledJob, undefined)[0];
}

var Draggable = {
  use: use$2
};

var UpdateRightPadding = {
  use: use$1
};

export {
  Droppable ,
  UpdateRightPadding ,
  Draggable ,
}
/* basePadding Not a pure module */
