

import * as RCore from "../../libraries/RCore.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as EncodeJson from "../../libraries/EncodeJson.mjs";
import * as Pages_Client_Portal_Types from "./Pages_Client_Portal_Types.mjs";

function get(wire) {
  return Backend.get(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "ClientPortal",
                VAL: "Settings"
              }
            });
}

var Show = {
  get: get
};

function encode(settings) {
  return EncodeJson.object([
              [
                "templateUuids",
                EncodeJson.jsonArray(settings.templateUuids.map(EncodeJson.uuid))
              ],
              [
                "columns",
                EncodeJson.object([
                      [
                        "jobFields",
                        EncodeJson.jsonArray(settings.columns.jobFields.map(function (f) {
                                  return EncodeJson.string(Pages_Client_Portal_Types.State.Portal.Settings.Columns.JobField.toString(f));
                                }))
                      ],
                      [
                        "customFields",
                        EncodeJson.jsonArray(settings.columns.customFields.map(EncodeJson.uuid))
                      ],
                      [
                        "reportFields",
                        EncodeJson.jsonArray(settings.columns.reportFields.map(EncodeJson.uuid))
                      ]
                    ])
              ]
            ]);
}

function patch(wire, portal) {
  return Backend.patch(undefined, undefined, undefined, true, wire, {
              NAME: "API",
              VAL: {
                NAME: "ClientPortal",
                VAL: "Settings"
              }
            }, {
              NAME: "Obj",
              VAL: {
                uuid: EncodeJson.uuid(portal.uuid),
                logoFileUuid: Js_null.fromOption(RCore.$$Option.map(portal.logo, (function (l) {
                            return EncodeJson.uuid(l.uuid);
                          }))),
                settings: encode(portal.settings)
              }
            });
}

function post(wire, portalUuid, clientUuid) {
  return Backend.post(undefined, undefined, undefined, true, wire, {
              NAME: "API",
              VAL: {
                NAME: "ClientPortal",
                VAL: {
                  NAME: "Users",
                  VAL: "Create"
                }
              }
            }, {
              NAME: "Obj",
              VAL: {
                portalUuid: EncodeJson.uuid(portalUuid),
                clientUuid: EncodeJson.uuid(clientUuid)
              }
            });
}

var Create = {
  post: post
};

function $$delete(wire, uuid) {
  return Backend.$$delete(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "ClientPortal",
                VAL: {
                  NAME: "Users",
                  VAL: {
                    NAME: "Delete",
                    VAL: uuid
                  }
                }
              }
            });
}

var Delete = {
  $$delete: $$delete
};

var Users = {
  Create: Create,
  Delete: Delete
};

var Settings_Update = {
  patch: patch
};

var Settings = {
  Show: Show,
  Update: Settings_Update
};

export {
  Settings ,
  Users ,
}
/* Backend Not a pure module */
