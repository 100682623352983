import React from 'react'
import ymaps from './ymaps'
import { eventsDecorator, supportEvents } from '../events'
import { areEqual } from 'planado/utils/index.js'
import { GEOLOCATION_OPTIONS } from '../constants'
import { withContext } from 'planado/utils/contextConsumer.jsx'

const setControl = (map, name, props) => {
  map.controls.add(name, props)
}

const setControls = (map, controls) => {
  controls.forEach((control) => setControl(map, control.name, control.props))
}

const setCorrection = (map) => {
  map.action.setCorrection((tick) => {
    const mapSize = map.container.getSize()

    const top = [
      tick.globalPixelCenter[0],
      tick.globalPixelCenter[1] - mapSize[1] / 2,
    ]
    const bottom = [
      tick.globalPixelCenter[0],
      tick.globalPixelCenter[1] + mapSize[1] / 2,
    ]

    const projection = map.options.get('projection')

    const tickTop = projection.fromGlobalPixels(top, tick.zoom)
    const tickBottom = projection.fromGlobalPixels(bottom, tick.zoom)

    const tickCenter = projection.fromGlobalPixels(
      tick.globalPixelCenter,
      tick.zoom
    )

    if (tickTop[0] > 85) {
      tick.globalPixelCenter = projection.toGlobalPixels(
        [85, tickCenter[1]],
        tick.zoom
      )

      tick.globalPixelCenter = [
        tick.globalPixelCenter[0],
        tick.globalPixelCenter[1] + mapSize[1] / 2,
      ]
      tick.duration = 0
    }

    if (tickBottom[0] < -85) {
      tick.globalPixelCenter = projection.toGlobalPixels(
        [-85, tickCenter[1]],
        tick.zoom
      )

      tick.globalPixelCenter = [
        tick.globalPixelCenter[0],
        tick.globalPixelCenter[1] - mapSize[1] / 2,
      ]
      tick.duration = 0
    }

    return tick
  })
}

class MapEngine {
  constructor() {
    this.map = new Promise((resolve, _reject) => {
      this.mapLoaded = resolve
    })
  }

  createMap(element, state, options, controls) {
    return new Promise((resolve) => {
      ymaps
        .getApi()
        .geolocation.get(GEOLOCATION_OPTIONS)
        .done((location) => {
          if (!Array.isArray(state['center'])) {
            state['center'] = location.geoObjects.position
          }

          const Map = ymaps.getApi().Map

          const map = new Map(element, state, options)

          this.events = map.events.group()
          this.setupCollection(map)
          setControls(map, controls)

          setCorrection(map)

          resolve(map)
          this.mapLoaded(map)
        })
    })
  }

  addMarker(marker) {
    this.map.then((_) => this.geoCollection.add(marker.marker))
  }

  setCenter(center) {
    this.map.then((m) => m.setCenter(center))
  }

  setCenterSmoothly(center) {
    this.map.then((m) =>
      m.panTo(center.map(Number), {
        checkZoomRange: true,
        flying: false,
      })
    )
  }

  setZoom(zoom) {
    this.map.then((m) => m.setZoom(zoom))
  }

  setState(name, value) {
    this.map.then((m) => m.state.set(name, value))
  }

  setOptions(name, value) {
    this.map.then((m) => m.options.set(name, value))
  }

  setEvent(name, cb) {
    this.map.then((_) => this.events.events.add(name, cb))
  }

  setupCollection(map) {
    this.geoCollection = new (ymaps.getApi().GeoObjectCollection)()
    map.geoObjects.add(this.geoCollection)
  }

  destroy() {
    this.map.then((m) => {
      this.events.removeAll()
      m.destroy()
    })
  }
}

export const MapEngineContext = React.createContext(null)

class Map extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isApiLoaded: false,
    }

    this.engine = null

    this.onAPILoad = this.onAPILoad.bind(this)
  }

  componentDidMount() {
    if (ymaps.isAvailible()) {
      this.onAPILoad()
    } else {
      const { ctx } = this.props
      ymaps
        .load({ ctx, coordorder: 'latlong' })
        .then(this.onAPILoad)
        .catch((err) => console.error('Map load error: %s', err))
    }
    if (this.engineAvailible) {
      this.engine.map.then((m) => m.container.fitToViewport())
    }
  }

  componentWillReceiveProps(nextProps) {
    const { center: currentCenter = null } = this.props
    const { center: nextCenter = null } = nextProps

    if (this.engineAvailible && Array.isArray(nextCenter)) {
      if (!areEqual(currentCenter, nextCenter)) {
        this.engine.setCenterSmoothly(nextCenter)
      }
    } else if (this.engineAvailible && Array.isArray(nextCenter)) {
      this.engine.setCenterSmoothly(nextCenter)
    }
  }

  onAPILoad() {
    const { state, center, zoom, options, controls } = this.props
    this.engine = new MapEngine()

    this.engine
      .createMap(this.element, { ...state, center, zoom }, options, controls)
      .then(() => {
        this.setupEvents()
        this.setState({ isApiLoaded: true })
      })
  }

  getEngine() {
    return this.engine ? this.engine : null
  }

  get engineAvailible() {
    return this.engine !== null
  }

  get style() {
    const { width, height } = this.props

    return {
      width: typeof width === 'string' ? width : `${width}px`,
      height: typeof height === 'string' ? height : `${height}px`,
    }
  }

  render() {
    return (
      <MapEngineContext.Provider value={{ mapEngine: this.engine }}>
        <div className="map-component" style={this.style}>
          <div
            className="map-component__map"
            ref={(el) => {
              this.element = el
            }}
          />
          {this.state.isApiLoaded ? this.props.children : null}
        </div>
      </MapEngineContext.Provider>
    )
  }
}

Map.defaultProps = {
  zoom: 13,
  width: '100%',
  height: '100%',
  state: {
    controls: [],
  },
  options: {
    minZoom: 2,
    maxZoom: 23,
  },
  controls: [
    {
      name: 'typeSelector',
      props: {
        float: 'none',
        size: 'small',
        position: { top: 235, right: 13 },
      },
    },
    {
      name: 'zoomControl',
      props: { float: 'right', position: { top: 13, right: 13 } },
    },
  ],
  style: {
    position: 'relative',
  },
}

export default withContext(eventsDecorator(Map, supportEvents['yandex']['map']))
