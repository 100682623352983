// extracted by mini-css-extract-plugin
export var addClientButtonIcon = "add-client-button-icon-V0wh6GxTOrlW7HSGL97B";
export var cancelButton = "cancel-button-DJATm57D8iaURs6WxgEr";
export var clientSearchInput = "client-search-input-bf7lwtjoHT63uz5UIxlm";
export var clientSearchModal = "client-search-modal-gXzvIxRe2X2JHzFPmOkg";
export var clientSearchModalBody = "client-search-modal-body-xPcSpI3anxD8HVCU4ARw";
export var clientSearchModalHeader = "client-search-modal-header-z6p3gJ_iIw6_EKA5DFnx";
export var clientSearchOption = "client-search-option-KvuKxdwd7vej4E5OTeBC";
export var clientSearchOptions = "client-search-options-BcVQisjZPsSn6rGubUnx";
export var clientsTable = "clients-table-bU37Ji3J73u6Em8ZWqpZ";
export var clientsTableDelete = "clients-table-delete-YfQkRxoO5TY3TRgGg9co";
export var clientsTableEmail = "clients-table-email-wjFv4JZLNhJrAJaZjUNF";
export var clientsTableName = "clients-table-name-_HSj_ylRcUdP3gwNISlp";
export var clientsTablePhone = "clients-table-phone-LTNshcdRiQCaJzSG48Hg";
export var noClients = "no-clients-bnWU9DT9V1flMbSXjlaV";
export var wrapper = "wrapper-MYvhrGdC3kfr2gv2X1Kr";