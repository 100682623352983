

import * as JsonDecode from "../../../../../../libraries/JsonDecode.mjs";
import * as JobEdit_Types from "../../types/JobEdit_Types.mjs";

var decode = JsonDecode.array(JobEdit_Types.OverlappingJobs.Job.decode);

var OverlappingJobs = {
  decode: decode
};

var decode$1 = JsonDecode.array(JobEdit_Types.OutOfShiftWorkers.$$Worker.decode);

var OutOfShiftWorkers = {
  decode: decode$1
};

export {
  OverlappingJobs ,
  OutOfShiftWorkers ,
}
/* decode Not a pure module */
