

import * as El from "../../../../../../libraries/El.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../../../../common/Optional.mjs";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Schedule_Types_Job from "../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as Schedule_JobPreviewHeaderModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/popovers/job_preview/header/Schedule_JobPreviewHeader.module.css";

var headline$p = Utils.Translations.tr("js.schedule.v2.job.headline");

var styles = Schedule_JobPreviewHeaderModuleCss;

function Schedule_JobPreviewHeader(Props) {
  var hoveredOpt = Props.hovered;
  var close = Props.close;
  var job = Props.job;
  var hovered = hoveredOpt !== undefined ? hoveredOpt : true;
  var match = AppContext.useWire();
  var subscriptions = match.subscriptions;
  var uuid = Schedule_Types_Job.ScheduledJob.uuid(job);
  var template = Schedule_Types_Job.ScheduledJob.template(job);
  var openJob = function (_event) {
    subscriptions.jobs.trigger({
          TAG: "OpenJob",
          _0: uuid
        });
  };
  var closeBlock;
  if (close !== undefined) {
    var closePreview = function (param) {
      close();
    };
    closeBlock = React.createElement("div", {
          className: styles.close,
          onClick: closePreview
        });
  } else {
    closeBlock = null;
  }
  return React.createElement("div", {
              className: styles.header
            }, React.createElement("div", {
                  className: styles.headline
                }, template !== undefined ? template.name : headline$p(match.ctx)), React.createElement(Optional.make, {
                  show: hovered,
                  children: React.createElement("div", {
                        className: El.Cn.concat(styles.openJob, RCore.$$Option.isNone(close) ? styles.right : ""),
                        onClick: openJob
                      })
                }), closeBlock);
}

var make = React.memo(Schedule_JobPreviewHeader);

export {
  make ,
}
/* headline' Not a pure module */
