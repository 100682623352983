// extracted by mini-css-extract-plugin
export var address = "address-Vjqfxs5eNUvDyRVbcLmt";
export var client = "client-y_qbihvQqb_STy1lwQfe";
export var date = "date-eJvvNsYbewUi1LtGIEld";
export var dates = "dates-jWtjDECtqMQeqarlYSil";
export var enRoute = "en-route-vpZG8fzO35F5nw8fzMKn";
export var footer = "footer-IuLdEthsJQTwc1cKfu46";
export var resolution = "resolution-FTsLLrZVnCulNlmzwz6v";
export var resolutionText = "resolution-text-rzgiORm3Tb2Gt3J7ZT4G";
export var started = "started-M37MGxr8yvXq3ruy6JfH";
export var suspended = "suspended-j308fppEcln211IpSpIg";
export var unsuccessful = "unsuccessful-LQW4PpgOvG6ns_FLMkoQ";
export var warning = "warning-GJrse6t7YuPS9Mz3jPf1";