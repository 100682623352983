

import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_Types_Job from "../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as WeekTimeline_Types from "../types/WeekTimeline_Types.mjs";
import * as Schedule_IntervalTree from "../../../../lib/Schedule_IntervalTree.mjs";
import * as WeekTimeline_Constants from "../WeekTimeline_Constants.mjs";
import * as WeekTimeline_AssignedJobPositionsHelper from "./WeekTimeline_AssignedJobPositionsHelper.mjs";

function index(job) {
  return job.index;
}

var start = WeekTimeline_Types.AssignedPosition.start;

var finish = WeekTimeline_Types.AssignedPosition.finish;

var IntervalTree = Schedule_IntervalTree.Make({
      index: index,
      start: start,
      finish: finish
    });

function make(wire, period, sizes, startAt, finishAt, index) {
  return {
          index: index,
          startedOutside: WeekTimeline_AssignedJobPositionsHelper.startedOutside(wire, period, startAt),
          finishedOutside: WeekTimeline_AssignedJobPositionsHelper.finishedOutside(wire, period, finishAt),
          top: {
            contents: Units.Px.zero
          },
          left: WeekTimeline_AssignedJobPositionsHelper.jobLeft(wire, sizes, period, startAt),
          width: WeekTimeline_AssignedJobPositionsHelper.jobWidth(wire, sizes, period, startAt, finishAt),
          height: {
            contents: Units.Px.zero
          },
          size: {
            contents: "Full"
          },
          rendered: {
            contents: false
          },
          hidden: {
            contents: false
          },
          children: {
            contents: []
          }
        };
}

var Position = {
  make: make
};

var minJobHeight = Units.Px.divideByInt(WeekTimeline_Constants.rowHeight, WeekTimeline_Constants.numberOfJobsInRow);

var maxTopPosition = Units.Px.multiplyWithInt(minJobHeight, WeekTimeline_Constants.numberOfVisibleJobs - 1 | 0);

function findGap(_positionOpt, _indexOpt, jobPosition) {
  while(true) {
    var positionOpt = _positionOpt;
    var indexOpt = _indexOpt;
    var position = positionOpt !== undefined ? Caml_option.valFromOption(positionOpt) : Units.Px.zero;
    var index = indexOpt !== undefined ? indexOpt : 0;
    var rendered = RCore.$$Array.keep(jobPosition.children.contents, (function (j) {
            if (j.rendered.contents) {
              return !j.hidden.contents;
            } else {
              return false;
            }
          }));
    var found = rendered.every((function(position){
        return function (p) {
          return Caml_obj.notequal(position, p.top.contents);
        }
        }(position)));
    if (Caml_obj.greaterthan(position, maxTopPosition)) {
      return ;
    }
    if (found) {
      return Caml_option.some(position);
    }
    var j = rendered[index];
    if (j === undefined) {
      return ;
    }
    var position$1 = Units.Px.plus(j.top.contents, minJobHeight);
    var index$1 = index + 1 | 0;
    _indexOpt = index$1;
    _positionOpt = Caml_option.some(position$1);
    continue ;
  };
}

function setPosition(job) {
  if (job.rendered.contents) {
    return ;
  }
  if (Caml_obj.equal(job.children.contents, [])) {
    job.rendered.contents = true;
    job.height.contents = WeekTimeline_Constants.rowHeight;
    job.top.contents = Units.Px.zero;
    return ;
  }
  job.height.contents = minJobHeight;
  job.rendered.contents = true;
  var gap = findGap(undefined, undefined, job);
  if (gap !== undefined) {
    job.top.contents = Caml_option.valFromOption(gap);
  } else {
    job.top.contents = Units.Px.multiplyWithInt(minJobHeight, WeekTimeline_Constants.numberOfVisibleJobs);
    job.hidden.contents = true;
  }
}

function updatePosition(job) {
  var sizeOfJobs = job.children.contents.length;
  var hasOneChild = job.children.contents.every(function (j) {
        return j.children.contents.length <= 1;
      });
  var hasLessThanThreeChilds = job.children.contents.every(function (j) {
        var childrens = RCore.$$Array.keep(j.children.contents, (function (job) {
                return job.index !== j.index;
              }));
        if (childrens.length <= 2) {
          return true;
        } else {
          return childrens.every(function (j) {
                      return j.children.contents.length <= 2;
                    });
        }
      });
  if (job.hidden.contents) {
    return ;
  }
  if (sizeOfJobs === 0) {
    job.height.contents = WeekTimeline_Constants.rowHeight;
    job.size.contents = "Full";
    return ;
  }
  if (sizeOfJobs === 1 || hasOneChild) {
    var halfOfRowHeight = Units.Px.divideByInt(WeekTimeline_Constants.rowHeight, 2);
    if (Caml_obj.equal(job.top.contents, Units.Px.zero)) {
      job.height.contents = Units.Px.minus(halfOfRowHeight, Units.Px.fromInt(1));
    } else {
      job.height.contents = halfOfRowHeight;
      job.top.contents = halfOfRowHeight;
    }
    job.size.contents = "Half";
    return ;
  }
  if (sizeOfJobs === 2 || hasLessThanThreeChilds) {
    var thirdOfRowHeight = Units.Px.divideByInt(WeekTimeline_Constants.rowHeight, 3);
    var top = Caml_obj.equal(job.top.contents, Units.Px.zero) ? Units.Px.zero : (
        Caml_obj.equal(job.top.contents, minJobHeight) ? thirdOfRowHeight : Units.Px.multiplyWithInt(thirdOfRowHeight, 2)
      );
    job.top.contents = top;
    job.height.contents = Units.Px.fromInt(16);
    job.size.contents = "OneThird";
    return ;
  }
  job.height.contents = Units.Px.fromInt(12);
  job.size.contents = "OneQuarter";
}

function make$1(wire, period, sizes, jobs) {
  var filtered = RCore.$$Array.keep(jobs, (function (job) {
          return WeekTimeline_AssignedJobPositionsHelper.isVisible(wire, period, Schedule_Types_Job.AssignedJob.startAt(job), Schedule_Types_Job.AssignedJob.finishAt(job));
        }));
  var positions = WeekTimeline_AssignedJobPositionsHelper.SortJobs.byWidth(filtered.map(function (job, index) {
            return make(wire, period, sizes, Schedule_Types_Job.AssignedJob.startAt(job), Schedule_Types_Job.AssignedJob.finishAt(job), index);
          }));
  var tree = IntervalTree.make(positions);
  positions.forEach(function (position) {
        position.children.contents = RCore.$$Array.keep(IntervalTree.rangeSearch(undefined, tree, WeekTimeline_Types.AssignedPosition.start(position) + 1 | 0, WeekTimeline_Types.AssignedPosition.finish(position) - 1 | 0), (function (p) {
                return p.index !== position.index;
              }));
      });
  positions.forEach(setPosition);
  positions.forEach(updatePosition);
  return WeekTimeline_AssignedJobPositionsHelper.SortJobs.byPosition(positions).map(function (p) {
              return [
                      p,
                      filtered[p.index]
                    ];
            });
}

var isVisible = WeekTimeline_AssignedJobPositionsHelper.isVisible;

var JobLeft = WeekTimeline_AssignedJobPositionsHelper.JobLeft;

var jobLeft = WeekTimeline_AssignedJobPositionsHelper.jobLeft;

var JobWidth = WeekTimeline_AssignedJobPositionsHelper.JobWidth;

var jobWidth = WeekTimeline_AssignedJobPositionsHelper.jobWidth;

var SortJobs = WeekTimeline_AssignedJobPositionsHelper.SortJobs;

export {
  isVisible ,
  JobLeft ,
  jobLeft ,
  JobWidth ,
  jobWidth ,
  SortJobs ,
  IntervalTree ,
  Position ,
  minJobHeight ,
  maxTopPosition ,
  findGap ,
  setPosition ,
  updatePosition ,
  make$1 as make,
}
/* IntervalTree Not a pure module */
