

import * as El from "../../../libraries/El.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../libraries/Locale.mjs";
import * as Popover from "../../../components/common/Popover/Popover.mjs";
import * as Optional from "../../../components/common/Optional.mjs";
import * as UI_Button from "../../../ui/UI_Button.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Pages_Client_FooterModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/clients/edit/Pages_Client_Footer.module.css";

var prompt$p = Utils.Translations.tr("js.clients.popovers.confirm_delete.prompt");

var confirmMessage$p = Utils.Translations.tr("js.clients.popovers.confirm_delete.jobs");

var popoverCancel$p = Utils.Translations.tr("js.clients.popovers.confirm_delete.cancel");

var popoverConfirm$p = Utils.Translations.tr("js.clients.popovers.confirm_delete.delete");

var submitCreate$p = Utils.Translations.tr("js.clients.buttons.submit_create");

var submitEdit$p = Utils.Translations.tr("js.clients.buttons.submit_edit");

var delete$p = Utils.Translations.tr("js.clients.buttons.delete");

var cancel$p = Utils.Translations.tr("js.clients.buttons.cancel");

function externalId$p(id, ctx) {
  return Utils.Translations.tx("js.clients.show.info.external_id")({
              id: id
            }, ctx);
}

function createdAt$p(date, time, c) {
  return Utils.Translations.tx("js.clients.show.info.created_at")({
              date: date,
              time: time
            }, c);
}

var styles = Pages_Client_FooterModuleCss;

function Pages_Client_Footer$DeletePopover(Props) {
  var onClose = Props.onClose;
  var onDelete = Props.onDelete;
  var ctx = Props.ctx;
  var locked = Props.locked;
  return React.createElement("div", {
              className: styles.popup
            }, React.createElement("h5", undefined, prompt$p(ctx)), React.createElement("p", undefined, confirmMessage$p(ctx)), React.createElement("div", {
                  className: styles.deleteButtons
                }, React.createElement(UI_Button.make, {
                      purpose: "default",
                      onClick: (function (param) {
                          onClose();
                        }),
                      children: popoverCancel$p(ctx)
                    }), React.createElement(UI_Button.make, {
                      flavor: "danger",
                      disabled: locked,
                      onClick: onDelete,
                      children: popoverConfirm$p(ctx)
                    })));
}

function Pages_Client_Footer$DeleteButton(Props) {
  var state = Props.state;
  var onDestroy = Props.onDestroy;
  var wire = Props.wire;
  var match = React.useState(function () {
        return false;
      });
  var setShow = match[1];
  var onDelete = function (evt) {
    evt.preventDefault();
    onDestroy();
  };
  var onClick = function (evt) {
    evt.preventDefault();
    setShow(function (param) {
          return true;
        });
  };
  var buttonId = "client-show-remove-button";
  return React.createElement(Optional.make, {
              show: !state.client.isNewRecord,
              children: React.createElement("div", undefined, React.createElement(UI_Button.make, {
                        purpose: "default",
                        flavor: "danger",
                        id: buttonId,
                        onClick: onClick,
                        children: delete$p(wire.ctx)
                      }), React.createElement(Popover.make, {
                        footer: wire.footer,
                        show: match[0],
                        buttonId: buttonId,
                        position: "top",
                        className: styles.deletePopover,
                        children: React.createElement(Pages_Client_Footer$DeletePopover, {
                              onClose: (function () {
                                  setShow(function (param) {
                                        return false;
                                      });
                                }),
                              onDelete: onDelete,
                              ctx: wire.ctx,
                              locked: state.locked
                            })
                      }))
            });
}

function Pages_Client_Footer$CancelButton(Props) {
  var wire = Props.wire;
  return React.createElement(UI_Button.make, {
              purpose: "default",
              className: styles.cancelButton,
              onClick: (function (param) {
                  wire.navigate(undefined, {
                        NAME: "Client",
                        VAL: "Index"
                      });
                }),
              children: cancel$p(wire.ctx)
            });
}

function Pages_Client_Footer$SubmitButton(Props) {
  var state = Props.state;
  var ctx = Props.ctx;
  return React.createElement(UI_Button.make, {
              purpose: "primary",
              className: styles.submitButton,
              type_: "submit",
              disabled: state.locked,
              children: state.client.isNewRecord ? submitCreate$p(ctx) : submitEdit$p(ctx)
            });
}

function Pages_Client_Footer$ClientInfo(Props) {
  var client = Props.client;
  var wire = Props.wire;
  var timestamp = client.createdAt;
  var tmp;
  if (timestamp !== undefined) {
    var dateTime = Locale.T.toDateTime(Caml_option.valFromOption(timestamp), wire);
    tmp = React.createElement("span", undefined, createdAt$p(dateTime.date, dateTime.time, wire.ctx));
  } else {
    tmp = null;
  }
  var id = client.externalId;
  return React.createElement(Optional.make, {
              show: !client.isNewRecord,
              children: React.createElement("div", {
                    className: styles.infoText
                  }, tmp, id !== undefined ? React.createElement("span", {
                          title: id
                        }, ", " + externalId$p(id, wire.ctx)) : null)
            });
}

function Pages_Client_Footer(Props) {
  var state = Props.state;
  var onDestroy = Props.onDestroy;
  var wire = AppContext.useWire();
  var client = state.client;
  return React.createElement(Optional.make, {
              show: state.canManage,
              children: React.createElement("div", {
                    className: El.Cn.concatIf(styles.footer, [
                          client.isNewRecord,
                          styles.footerCreate
                        ])
                  }, React.createElement("div", {
                        className: styles.info
                      }, React.createElement(Pages_Client_Footer$DeleteButton, {
                            state: state,
                            onDestroy: onDestroy,
                            wire: wire
                          }), React.createElement(Pages_Client_Footer$ClientInfo, {
                            client: client,
                            wire: wire
                          })), React.createElement("div", {
                        className: styles.buttons
                      }, React.createElement(Pages_Client_Footer$CancelButton, {
                            wire: wire
                          }), React.createElement(Pages_Client_Footer$SubmitButton, {
                            state: state,
                            ctx: wire.ctx
                          })))
            });
}

var make = Pages_Client_Footer;

export {
  make ,
}
/* prompt' Not a pure module */
