

import * as Tour from "./Tour.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Button from "../../components/common/Button.mjs";
import * as Tour$1 from "@reactour/tour";

var next$p = Utils.Translations.tr("js.tour.controls.next");

var finish$p = Utils.Translations.tr("js.tour.controls.finish");

function Tour_Provider$ExternalProvider$Components$CloseButton(Props) {
  var onClick = Props.onClick;
  return React.createElement("button", {
              className: "reactour__close-button",
              style: {
                background: "none",
                border: "0",
                cursor: "pointer",
                display: "block",
                height: "12px",
                padding: "0",
                position: "absolute",
                right: "22px",
                top: "22px",
                width: "12px"
              },
              onClick: onClick
            }, React.createElement("svg", {
                  role: "presentation",
                  style: {
                    display: "block"
                  },
                  viewBox: "0 0 9.1 9.1"
                }, React.createElement("path", {
                      d: "M5.9 4.5l2.8-2.8c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L4.5 3.1 1.7.3C1.3-.1.7-.1.3.3c-.4.4-.4 1 0 1.4l2.8 2.8L.3 7.4c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3L4.5 6l2.8 2.8c.3.2.5.3.8.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L5.9 4.5z",
                      fill: "#999"
                    })));
}

function Tour_Provider(Props) {
  var wire = Props.wire;
  var steps = Props.steps;
  var children = Props.children;
  var ctx = wire.ctx;
  React.useEffect((function () {
          if (steps.length > 0) {
            return ;
          } else {
            return wire.subscriptions.keydown.subscribe(function (param) {
                        var e = param[0];
                        if (e.key === "0" && e.metaKey && e.shiftKey) {
                          Tour.Progress.update(wire, "intro", "reset");
                        }
                        param[1](e);
                      });
          }
        }), [steps]);
  return React.createElement(Tour$1.TourProvider, {
              steps: steps,
              showBadge: false,
              onClickMask: (function () {
                  
                }),
              beforeClose: (function () {
                  Tour.Progress.update(wire, "intro", "closed");
                }),
              afterOpen: (function () {
                  if (steps.length > 0) {
                    return Tour.Progress.update(wire, "intro", "started");
                  }
                  
                }),
              showDots: false,
              prevButton: (function () {
                  return null;
                }),
              nextButton: (function (tour) {
                  var match = React.useState(function () {
                        return true;
                      });
                  var setEnabled = match[1];
                  var enabled = match[0];
                  React.useEffect((function () {
                          return wire.tour.nextButtonSubscription.subscribe(function (e) {
                                      setEnabled(function (param) {
                                            return e;
                                          });
                                    });
                        }), []);
                  var match$1 = tour.steps[tour.currentStep];
                  if (match$1 === undefined) {
                    return null;
                  }
                  var text = match$1.button;
                  if (text === undefined) {
                    return null;
                  }
                  var translation = text === "finish" ? finish$p(ctx) : next$p(ctx);
                  return React.createElement(Button.make, {
                              disabled: !enabled,
                              onClick: (function (param) {
                                  if (!enabled) {
                                    return ;
                                  }
                                  Tour.Progress.track(wire, tour);
                                  var step = tour.steps[tour.currentStep + 1 | 0];
                                  tour.setCurrentStep(tour.currentStep + 1 | 0);
                                  if (step !== undefined) {
                                    return setEnabled(function (param) {
                                                return Tour.NextButton.enabledOnStepEntrance("intro", step);
                                              });
                                  }
                                  
                                }),
                              flavor: "success",
                              children: translation
                            });
                }),
              children: children,
              components: {
                Close: Tour_Provider$ExternalProvider$Components$CloseButton
              },
              styles: {
                popover: (function () {
                    return {
                            backgroundColor: "#fff",
                            color: "inherit",
                            left: "0",
                            padding: "48px 30px 24px",
                            position: "fixed",
                            top: "0",
                            zIndex: "100000",
                            borderRadius: "4px",
                            boxShadow: "0 0.5em 3em rgba(0, 0, 0, 0.3)",
                            transition: "transform 0.3s"
                          };
                  }),
                highlightedArea: undefined,
                maskArea: (function (x) {
                    return {
                            height: x.height,
                            width: x.width,
                            x: x.x,
                            y: x.y,
                            rx: 3
                          };
                  })
              }
            });
}

var make = Tour_Provider;

export {
  make ,
}
/* next' Not a pure module */
