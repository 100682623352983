

import MomentTimezone from "moment-timezone";
import * as MomentTimezone$1 from "moment-timezone";

function floorToHourStart(input) {
  return MomentTimezone$1.duration(input.hours(), "hours");
}

function add(duration, moment) {
  var clone = moment.clone();
  clone.add(duration);
  return clone;
}

function subtract(duration, moment) {
  var clone = moment.clone();
  clone.subtract(duration);
  return clone;
}

function startOf(timeUnit, moment) {
  var clone = moment.clone();
  clone.startOf(timeUnit);
  return clone;
}

function endOf(timeUnit, moment) {
  var clone = moment.clone();
  clone.endOf(timeUnit);
  return clone;
}

function moment(format, value) {
  if (format !== undefined) {
    return MomentTimezone(value, format);
  } else {
    return MomentTimezone(value);
  }
}

function Duration_minutes(prim) {
  return prim.minutes();
}

function Duration_hours(prim) {
  return prim.hours();
}

function Duration_asSeconds(prim) {
  return prim.asSeconds();
}

function Duration_asMinutes(prim) {
  return prim.asMinutes();
}

function Duration_asHours(prim) {
  return prim.asHours();
}

var Duration = {
  minutes: Duration_minutes,
  hours: Duration_hours,
  asSeconds: Duration_asSeconds,
  asMinutes: Duration_asMinutes,
  asHours: Duration_asHours
};

function duration(prim0, prim1) {
  return MomentTimezone$1.duration(prim0, prim1);
}

function guess(prim) {
  return MomentTimezone$1.default.tz.guess();
}

function durationFormat(prim0, prim1) {
  return MomentTimezone$1.duration(prim0, prim1);
}

function Moment_clone(prim) {
  return prim.clone();
}

function Moment_mutableAdd(prim0, prim1) {
  prim0.add(prim1);
}

function Moment_mutableSubtract(prim0, prim1) {
  prim0.subtract(prim1);
}

function Moment_mutableStartOf(prim0, prim1) {
  prim0.startOf(prim1);
}

function Moment_mutableEndOf(prim0, prim1) {
  prim0.endOf(prim1);
}

function Moment_minute(prim) {
  return prim.minute();
}

function Moment_hour(prim) {
  return prim.hour();
}

function Moment_day(prim) {
  return prim.day();
}

function Moment_date(prim) {
  return prim.date();
}

function Moment_week(prim) {
  return prim.week();
}

function Moment_month(prim) {
  return prim.month();
}

function Moment_year(prim) {
  return prim.year();
}

function Moment_weekday(prim) {
  return prim.weekday();
}

function Moment_isValid(prim) {
  return prim.isValid();
}

function Moment_isBefore(prim0, prim1) {
  return prim0.isBefore(prim1);
}

function Moment_isAfter(prim0, prim1) {
  return prim0.isAfter(prim1);
}

function Moment_isSame(prim0, prim1) {
  return prim0.isSame(prim1);
}

function Moment_isSameWithGranularity(prim0, prim1, prim2) {
  return prim0.isSame(prim1, prim2);
}

function Moment_isSameOrBefore(prim0, prim1) {
  return prim0.isSameOrBefore(prim1);
}

function Moment_isSameOrAfter(prim0, prim1) {
  return prim0.isSameOrAfter(prim1);
}

function Moment_format(prim0, prim1) {
  return prim0.format(prim1);
}

function Moment_defaultFormat(prim) {
  return prim.format();
}

function Moment_unix(prim) {
  return prim.unix();
}

var Moment = {
  clone: Moment_clone,
  mutableAdd: Moment_mutableAdd,
  add: add,
  mutableSubtract: Moment_mutableSubtract,
  subtract: subtract,
  mutableStartOf: Moment_mutableStartOf,
  startOf: startOf,
  mutableEndOf: Moment_mutableEndOf,
  endOf: endOf,
  minute: Moment_minute,
  hour: Moment_hour,
  day: Moment_day,
  date: Moment_date,
  week: Moment_week,
  month: Moment_month,
  year: Moment_year,
  weekday: Moment_weekday,
  isValid: Moment_isValid,
  isBefore: Moment_isBefore,
  isAfter: Moment_isAfter,
  isSame: Moment_isSame,
  isSameWithGranularity: Moment_isSameWithGranularity,
  isSameOrBefore: Moment_isSameOrBefore,
  isSameOrAfter: Moment_isSameOrAfter,
  format: Moment_format,
  defaultFormat: Moment_defaultFormat,
  unix: Moment_unix
};

function momentDefaultFormat(prim) {
  return MomentTimezone(prim);
}

function momentWithFormat(prim0, prim1) {
  return MomentTimezone(prim0, prim1);
}

function momentWithFormats(prim0, prim1) {
  return MomentTimezone(prim0, prim1);
}

function makeFromJsDate(prim) {
  return MomentTimezone(prim);
}

function diff(prim0, prim1, prim2) {
  return prim0.diff(prim1, prim2);
}

export {
  Duration ,
  duration ,
  floorToHourStart ,
  guess ,
  durationFormat ,
  Moment ,
  momentDefaultFormat ,
  momentWithFormat ,
  momentWithFormats ,
  makeFromJsDate ,
  diff ,
  moment ,
}
/* moment-timezone Not a pure module */
