

import * as Uuid from "../../../../../bindings/uuid/Uuid.mjs";
import * as Locale from "../../../../../libraries/Locale.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Types_Spatial from "../../../../../types/Types_Spatial.mjs";

function makeFromFloats(latitude, longitude) {
  return {
          longitude: Types_Spatial.Longitude.fromNumber(longitude),
          latitude: Types_Spatial.Latitude.fromNumber(latitude)
        };
}

var admin = {
  name: "Admin",
  web: true
};

var fieldWorker = {
  name: "Field Worker",
  web: false
};

function datetime(datetime$1) {
  return Locale.T.make(datetime$1);
}

var jobCreated_uuid = Uuid.createV4();

var jobCreated_worker = admin;

var jobCreated_happenedAt = Locale.T.make("2020-04-04T15:30:00+00:00");

var jobCreated = {
  uuid: jobCreated_uuid,
  application: "Web",
  worker: jobCreated_worker,
  deviceState: undefined,
  happenedAt: jobCreated_happenedAt,
  value: "JobCreated",
  viewedAt: undefined
};

var jobPublished_uuid = Uuid.createV4();

var jobPublished_worker = admin;

var jobPublished_happenedAt = Locale.T.make("2020-04-04T15:35:00+00:00");

var jobPublished_value = {
  NAME: "StatusUpdated",
  VAL: "Published"
};

var jobPublished_viewedAt = "2020-04-04T15:36:15+00:00";

var jobPublished = {
  uuid: jobPublished_uuid,
  application: "Web",
  worker: jobPublished_worker,
  deviceState: undefined,
  happenedAt: jobPublished_happenedAt,
  value: jobPublished_value,
  viewedAt: jobPublished_viewedAt
};

var jobEnRoute_uuid = Uuid.createV4();

var jobEnRoute_worker = fieldWorker;

var jobEnRoute_deviceState = {
  geolocation: makeFromFloats(55.0, 37.0),
  accuracy: "Moderate",
  distanceToDestinationM: 1000.0
};

var jobEnRoute_happenedAt = Locale.T.make("2020-04-04T15:40:00+00:00");

var jobEnRoute_value = {
  NAME: "StatusUpdated",
  VAL: "EnRoute"
};

var jobEnRoute = {
  uuid: jobEnRoute_uuid,
  application: "Mobile",
  worker: jobEnRoute_worker,
  deviceState: jobEnRoute_deviceState,
  happenedAt: jobEnRoute_happenedAt,
  value: jobEnRoute_value,
  viewedAt: undefined
};

var messageSent_uuid = Uuid.createV4();

var messageSent_application = {
  TAG: "Other",
  _0: "background"
};

var messageSent_happenedAt = Locale.T.make("2020-04-04T15:40:05+00:00");

var messageSent = {
  uuid: messageSent_uuid,
  application: messageSent_application,
  worker: undefined,
  deviceState: undefined,
  happenedAt: messageSent_happenedAt,
  value: "MessageSent",
  viewedAt: undefined
};

function movementFinished(ctx) {
  var match = ctx.localizator.measurementSystem;
  var tmp;
  tmp = typeof match !== "object" && match === "Imperial" ? ({
        TAG: "Feet",
        _0: 1234
      }) : ({
        TAG: "Meters",
        _0: 987
      });
  return {
          uuid: Uuid.createV4(),
          application: "Mobile",
          worker: fieldWorker,
          deviceState: {
            geolocation: makeFromFloats(55.01, 37.05),
            accuracy: "Low",
            distanceToDestinationM: 100.0
          },
          happenedAt: Locale.T.make("2020-04-04T15:45:00+00:00"),
          value: {
            NAME: "MovementFinished",
            VAL: tmp
          },
          viewedAt: undefined
        };
}

var jobStarted_uuid = Uuid.createV4();

var jobStarted_worker = fieldWorker;

var jobStarted_deviceState = {
  geolocation: makeFromFloats(55.01, 37.05),
  accuracy: "Low",
  distanceToDestinationM: 100.0
};

var jobStarted_happenedAt = Locale.T.make("2020-04-04T15:45:00+00:00");

var jobStarted_value = {
  NAME: "StatusUpdated",
  VAL: "Started"
};

var jobStarted = {
  uuid: jobStarted_uuid,
  application: "Mobile",
  worker: jobStarted_worker,
  deviceState: jobStarted_deviceState,
  happenedAt: jobStarted_happenedAt,
  value: jobStarted_value,
  viewedAt: undefined
};

var jobSuspended_uuid = Uuid.createV4();

var jobSuspended_worker = fieldWorker;

var jobSuspended_deviceState = {
  geolocation: makeFromFloats(55.02, 36.95),
  accuracy: "High",
  distanceToDestinationM: 75.0
};

var jobSuspended_happenedAt = Locale.T.make("2020-04-04T15:51:00+00:00");

var jobSuspended_value = {
  NAME: "StatusUpdated",
  VAL: "Suspended"
};

var jobSuspended = {
  uuid: jobSuspended_uuid,
  application: "Mobile",
  worker: jobSuspended_worker,
  deviceState: jobSuspended_deviceState,
  happenedAt: jobSuspended_happenedAt,
  value: jobSuspended_value,
  viewedAt: undefined
};

var jobResumed_uuid = Uuid.createV4();

var jobResumed_worker = fieldWorker;

var jobResumed_deviceState = {
  geolocation: makeFromFloats(55.05, 37.10),
  accuracy: "High",
  distanceToDestinationM: 100.0
};

var jobResumed_happenedAt = Locale.T.make("2020-04-04T15:55:00+00:00");

var jobResumed_value = {
  NAME: "StatusUpdated",
  VAL: "Started"
};

var jobResumed = {
  uuid: jobResumed_uuid,
  application: "Mobile",
  worker: jobResumed_worker,
  deviceState: jobResumed_deviceState,
  happenedAt: jobResumed_happenedAt,
  value: jobResumed_value,
  viewedAt: undefined
};

var value_value = {
  NAME: "Images",
  VAL: [
    {
      url: "https://storage.planadoapp.com/1000/35878623001/9291a631bbc9f2b933aa6cff31929ba390ed6df7/58406746657b0e0e08612e45.png",
      previewUrl: "https://storage.planadoapp.com/1000/35878623001/9291a631bbc9f2b933aa6cff31929ba390ed6df7/58406746657b0e0e08612e45.png",
      name: "photo1"
    },
    {
      url: "https://storage.planadoapp.com/1000/35878623001/9291a631bbc9f2b933aa6cff31929ba390ed6df7/58406746657b0e0e08612e45.png",
      previewUrl: "https://storage.planadoapp.com/1000/35878623001/9291a631bbc9f2b933aa6cff31929ba390ed6df7/58406746657b0e0e08612e45.png",
      name: "photo1"
    },
    {
      url: "https://storage.planadoapp.com/1000/40274851001/816c1c9ae064f78eadd6baa193f961559b0bbc5a/Screenshot 2020-03-03 at 17.40.48.png",
      previewUrl: "https://storage.planadoapp.com/1000/40274851001/816c1c9ae064f78eadd6baa193f961559b0bbc5a/Screenshot 2020-03-03 at 17.40.48.png",
      name: "photo2"
    },
    {
      url: undefined,
      previewUrl: undefined,
      name: "Not uploaded"
    },
    {
      url: "https://planado.s3.amazonaws.com/1000/52372608001/ecd27ee847f39dda62affb498005afbd0da84c6f/cam_20211020_135545.jpeg",
      previewUrl: "https://planado.s3.amazonaws.com/1000/52372608001/ecd27ee847f39dda62affb498005afbd0da84c6f/cam_20211020_135545.jpeg",
      name: "high resolution photo"
    },
    {
      url: "https://storage.planadoapp.com/1000/51672620001/710f5489ef554e03f6c5c1927d72eee7c7ee0977/%D0%A0%D0%B0%D1%81%D0%BF%D0%B8%D1%81%D0%B0%D0%BD%D0%B8%D0%B5_%D0%BD%D0%B0_%D0%BD%D0%B5%D0%B4%D0%B5%D0%BB%D1%8E.jpg",
      previewUrl: "https://storage.planadoapp.com/1000/51672620001/710f5489ef554e03f6c5c1927d72eee7c7ee0977/%D0%A0%D0%B0%D1%81%D0%BF%D0%B8%D1%81%D0%B0%D0%BD%D0%B8%D0%B5_%D0%BD%D0%B0_%D0%BD%D0%B5%D0%B4%D0%B5%D0%BB%D1%8E.jpg",
      name: "thumbnails overflow check 1"
    },
    {
      url: "https://storage.planadoapp.com/1000/36539160001/54727999313a0e71cfac87c6027411a3ce9f50ff/82ab58ed628eefee20191202-6-1kzrfi4",
      previewUrl: "https://storage.planadoapp.com/1000/36539160001/54727999313a0e71cfac87c6027411a3ce9f50ff/82ab58ed628eefee20191202-6-1kzrfi4",
      name: "thumbnails overflow check 2"
    }
  ]
};

var value = {
  name: "photos",
  value: value_value
};

var imagesFieldUpdated_uuid = Uuid.createV4();

var imagesFieldUpdated_worker = fieldWorker;

var imagesFieldUpdated_deviceState = {
  geolocation: makeFromFloats(55.05, 37.09),
  accuracy: "High",
  distanceToDestinationM: 105.0
};

var imagesFieldUpdated_happenedAt = Locale.T.make("2020-04-04T15:55:30+00:00");

var imagesFieldUpdated_value = {
  NAME: "FieldUpdated",
  VAL: value
};

var imagesFieldUpdated = {
  uuid: imagesFieldUpdated_uuid,
  application: "Mobile",
  worker: imagesFieldUpdated_worker,
  deviceState: imagesFieldUpdated_deviceState,
  happenedAt: imagesFieldUpdated_happenedAt,
  value: imagesFieldUpdated_value,
  viewedAt: undefined
};

var value_value$1 = {
  NAME: "Files",
  VAL: [{
      url: "https://google.com",
      previewUrl: undefined,
      name: "file.pdf"
    }]
};

var value$1 = {
  name: "document",
  value: value_value$1
};

var fileFieldUpdated_uuid = Uuid.createV4();

var fileFieldUpdated_worker = fieldWorker;

var fileFieldUpdated_deviceState = {
  geolocation: makeFromFloats(55.05, 37.11),
  accuracy: "High",
  distanceToDestinationM: 100.0
};

var fileFieldUpdated_happenedAt = Locale.T.make("2020-04-04T15:56:00+00:00");

var fileFieldUpdated_value = {
  NAME: "FieldUpdated",
  VAL: value$1
};

var fileFieldUpdated = {
  uuid: fileFieldUpdated_uuid,
  application: "Mobile",
  worker: fileFieldUpdated_worker,
  deviceState: fileFieldUpdated_deviceState,
  happenedAt: fileFieldUpdated_happenedAt,
  value: fileFieldUpdated_value,
  viewedAt: undefined
};

var value_value$2 = {
  NAME: "Files",
  VAL: []
};

var value$2 = {
  name: "document",
  value: value_value$2
};

var fileFieldEmptied_uuid = Uuid.createV4();

var fileFieldEmptied_worker = fieldWorker;

var fileFieldEmptied_deviceState = {
  geolocation: makeFromFloats(55.05, 37.12),
  accuracy: "High",
  distanceToDestinationM: 100.0
};

var fileFieldEmptied_happenedAt = Locale.T.make("2020-04-04T15:57:00+00:00");

var fileFieldEmptied_value = {
  NAME: "FieldUpdated",
  VAL: value$2
};

var fileFieldEmptied = {
  uuid: fileFieldEmptied_uuid,
  application: "Mobile",
  worker: fileFieldEmptied_worker,
  deviceState: fileFieldEmptied_deviceState,
  happenedAt: fileFieldEmptied_happenedAt,
  value: fileFieldEmptied_value,
  viewedAt: undefined
};

var value_value$3 = {
  NAME: "Currency",
  VAL: {
    currency: "rub",
    useCurrencyFractionalUnit: true,
    value: "400.50"
  }
};

var value$3 = {
  name: "currency",
  value: value_value$3
};

var currencyFieldUpdated_uuid = Uuid.createV4();

var currencyFieldUpdated_worker = fieldWorker;

var currencyFieldUpdated_deviceState = {
  geolocation: makeFromFloats(55.02, 37.09),
  accuracy: "High",
  distanceToDestinationM: 90.0
};

var currencyFieldUpdated_happenedAt = Locale.T.make("2020-04-04T16:10:00+00:00");

var currencyFieldUpdated_value = {
  NAME: "FieldUpdated",
  VAL: value$3
};

var currencyFieldUpdated_viewedAt = "2020-04-05T16:11:15+00:00";

var currencyFieldUpdated = {
  uuid: currencyFieldUpdated_uuid,
  application: "Mobile",
  worker: currencyFieldUpdated_worker,
  deviceState: currencyFieldUpdated_deviceState,
  happenedAt: currencyFieldUpdated_happenedAt,
  value: currencyFieldUpdated_value,
  viewedAt: currencyFieldUpdated_viewedAt
};

var currencyFieldEmptied_uuid = Uuid.createV4();

var currencyFieldEmptied_worker = fieldWorker;

var currencyFieldEmptied_deviceState = {
  geolocation: makeFromFloats(55.02, 37.09),
  accuracy: "High",
  distanceToDestinationM: 90.0
};

var currencyFieldEmptied_happenedAt = Locale.T.make("2020-04-04T16:12:00+00:00");

var currencyFieldEmptied_value = {
  NAME: "FieldUpdated",
  VAL: {
    name: "currency",
    value: {
      NAME: "Currency",
      VAL: undefined
    }
  }
};

var currencyFieldEmptied = {
  uuid: currencyFieldEmptied_uuid,
  application: "Mobile",
  worker: currencyFieldEmptied_worker,
  deviceState: currencyFieldEmptied_deviceState,
  happenedAt: currencyFieldEmptied_happenedAt,
  value: currencyFieldEmptied_value,
  viewedAt: undefined
};

var value_value$4 = {
  NAME: "Date",
  VAL: Caml_option.some(Locale.T.make("2021-01-01"))
};

var value$4 = {
  name: "date",
  value: value_value$4
};

var dateFieldUpdated_uuid = Uuid.createV4();

var dateFieldUpdated_worker = fieldWorker;

var dateFieldUpdated_deviceState = {
  geolocation: makeFromFloats(55.0, 37.05),
  accuracy: "High",
  distanceToDestinationM: 80.0
};

var dateFieldUpdated_happenedAt = Locale.T.make("2020-04-04T16:15:00+00:00");

var dateFieldUpdated_value = {
  NAME: "FieldUpdated",
  VAL: value$4
};

var dateFieldUpdated = {
  uuid: dateFieldUpdated_uuid,
  application: "Mobile",
  worker: dateFieldUpdated_worker,
  deviceState: dateFieldUpdated_deviceState,
  happenedAt: dateFieldUpdated_happenedAt,
  value: dateFieldUpdated_value,
  viewedAt: undefined
};

var dateFieldEmptied_uuid = Uuid.createV4();

var dateFieldEmptied_worker = fieldWorker;

var dateFieldEmptied_deviceState = {
  geolocation: makeFromFloats(55.0, 37.05),
  accuracy: "High",
  distanceToDestinationM: 80.0
};

var dateFieldEmptied_happenedAt = Locale.T.make("2020-04-04T16:16:00+00:00");

var dateFieldEmptied_value = {
  NAME: "FieldUpdated",
  VAL: {
    name: "date",
    value: {
      NAME: "Date",
      VAL: undefined
    }
  }
};

var dateFieldEmptied = {
  uuid: dateFieldEmptied_uuid,
  application: "Mobile",
  worker: dateFieldEmptied_worker,
  deviceState: dateFieldEmptied_deviceState,
  happenedAt: dateFieldEmptied_happenedAt,
  value: dateFieldEmptied_value,
  viewedAt: undefined
};

var value_value$5 = {
  NAME: "Datetime",
  VAL: Caml_option.some(Locale.T.make("2021-01-01T10:56:00Z"))
};

var value$5 = {
  name: "datetime",
  value: value_value$5
};

var datetimeFieldUpdated_uuid = Uuid.createV4();

var datetimeFieldUpdated_worker = fieldWorker;

var datetimeFieldUpdated_deviceState = {
  geolocation: makeFromFloats(55.0, 37.02),
  accuracy: "High",
  distanceToDestinationM: 70.0
};

var datetimeFieldUpdated_happenedAt = Locale.T.make("2020-04-04T16:25:00+00:00");

var datetimeFieldUpdated_value = {
  NAME: "FieldUpdated",
  VAL: value$5
};

var datetimeFieldUpdated = {
  uuid: datetimeFieldUpdated_uuid,
  application: "Mobile",
  worker: datetimeFieldUpdated_worker,
  deviceState: datetimeFieldUpdated_deviceState,
  happenedAt: datetimeFieldUpdated_happenedAt,
  value: datetimeFieldUpdated_value,
  viewedAt: undefined
};

var datetimeFieldEmptied_uuid = Uuid.createV4();

var datetimeFieldEmptied_worker = fieldWorker;

var datetimeFieldEmptied_deviceState = {
  geolocation: makeFromFloats(55.0, 37.02),
  accuracy: "High",
  distanceToDestinationM: 70.0
};

var datetimeFieldEmptied_happenedAt = Locale.T.make("2020-04-04T16:26:00+00:00");

var datetimeFieldEmptied_value = {
  NAME: "FieldUpdated",
  VAL: {
    name: "datetime",
    value: {
      NAME: "Datetime",
      VAL: undefined
    }
  }
};

var datetimeFieldEmptied = {
  uuid: datetimeFieldEmptied_uuid,
  application: "Mobile",
  worker: datetimeFieldEmptied_worker,
  deviceState: datetimeFieldEmptied_deviceState,
  happenedAt: datetimeFieldEmptied_happenedAt,
  value: datetimeFieldEmptied_value,
  viewedAt: undefined
};

var value_value$6 = {
  NAME: "Time",
  VAL: Caml_option.some(Locale.T.make("2021-01-01T10:15:45"))
};

var value$6 = {
  name: "time",
  value: value_value$6
};

var timeFieldUpdated_uuid = Uuid.createV4();

var timeFieldUpdated_worker = fieldWorker;

var timeFieldUpdated_deviceState = {
  geolocation: makeFromFloats(55.0, 37.05),
  accuracy: "High",
  distanceToDestinationM: 80.0
};

var timeFieldUpdated_happenedAt = Locale.T.make("2020-04-04T16:15:00+00:00");

var timeFieldUpdated_value = {
  NAME: "FieldUpdated",
  VAL: value$6
};

var timeFieldUpdated = {
  uuid: timeFieldUpdated_uuid,
  application: "Mobile",
  worker: timeFieldUpdated_worker,
  deviceState: timeFieldUpdated_deviceState,
  happenedAt: timeFieldUpdated_happenedAt,
  value: timeFieldUpdated_value,
  viewedAt: undefined
};

var timeFieldEmptied_uuid = Uuid.createV4();

var timeFieldEmptied_worker = fieldWorker;

var timeFieldEmptied_deviceState = {
  geolocation: makeFromFloats(55.0, 37.05),
  accuracy: "High",
  distanceToDestinationM: 80.0
};

var timeFieldEmptied_happenedAt = Locale.T.make("2020-04-04T16:16:00+00:00");

var timeFieldEmptied_value = {
  NAME: "FieldUpdated",
  VAL: {
    name: "time",
    value: {
      NAME: "Time",
      VAL: undefined
    }
  }
};

var timeFieldEmptied = {
  uuid: timeFieldEmptied_uuid,
  application: "Mobile",
  worker: timeFieldEmptied_worker,
  deviceState: timeFieldEmptied_deviceState,
  happenedAt: timeFieldEmptied_happenedAt,
  value: timeFieldEmptied_value,
  viewedAt: undefined
};

var jobFinished_uuid = Uuid.createV4();

var jobFinished_worker = fieldWorker;

var jobFinished_deviceState = {
  geolocation: makeFromFloats(55.10, 36.96),
  accuracy: "High",
  distanceToDestinationM: 50.0
};

var jobFinished_happenedAt = Locale.T.make("2020-04-04T16:05:00+00:00");

var jobFinished_value = {
  NAME: "StatusUpdated",
  VAL: "Finished"
};

var jobFinished = {
  uuid: jobFinished_uuid,
  application: "Mobile",
  worker: jobFinished_worker,
  deviceState: jobFinished_deviceState,
  happenedAt: jobFinished_happenedAt,
  value: jobFinished_value,
  viewedAt: undefined
};

var value_value$7 = {
  NAME: "Images",
  VAL: [{
      url: "https://storage.planadoapp.com/1000/35578652001/5b38812722de90d6d0a697d301eac4bd7de15276/Screenshot%202020-01-16%20at%2011.56.18.png",
      previewUrl: "https://storage.planadoapp.com/1000/35578652001/5b38812722de90d6d0a697d301eac4bd7de15276/Screenshot%202020-01-16%20at%2011.56.18.png",
      name: "photo1"
    }]
};

var value$7 = {
  name: "single image",
  value: value_value$7
};

var imageUploaded_uuid = Uuid.createV4();

var imageUploaded_worker = fieldWorker;

var imageUploaded_happenedAt = Locale.T.make("2020-04-05T10:00:00+00:00");

var imageUploaded_value = {
  NAME: "FieldUpdated",
  VAL: value$7
};

var imageUploaded = {
  uuid: imageUploaded_uuid,
  application: "Mobile",
  worker: imageUploaded_worker,
  deviceState: undefined,
  happenedAt: imageUploaded_happenedAt,
  value: imageUploaded_value,
  viewedAt: undefined
};

var jobPrinted_uuid = Uuid.createV4();

var jobPrinted_application = {
  TAG: "Other",
  _0: "background"
};

var jobPrinted_happenedAt = Locale.T.make("2020-04-05T10:05:00+00:00");

var jobPrinted_value = {
  NAME: "Printed",
  VAL: "https://storage.planadoapp.com/1000/35578652001/5b38812722de90d6d0a697d301eac4bd7de15276/Screenshot%202020-01-16%20at%2011.56.18.png"
};

var jobPrinted = {
  uuid: jobPrinted_uuid,
  application: jobPrinted_application,
  worker: undefined,
  deviceState: undefined,
  happenedAt: jobPrinted_happenedAt,
  value: jobPrinted_value,
  viewedAt: undefined
};

var jobUpdated1_uuid = Uuid.createV4();

var jobUpdated1_worker = admin;

var jobUpdated1_happenedAt = Locale.T.make("2020-04-06T12:25:00+00:00");

var jobUpdated1 = {
  uuid: jobUpdated1_uuid,
  application: "Web",
  worker: jobUpdated1_worker,
  deviceState: undefined,
  happenedAt: jobUpdated1_happenedAt,
  value: "JobUpdated",
  viewedAt: undefined
};

var jobUpdated2_uuid = Uuid.createV4();

var jobUpdated2_worker = admin;

var jobUpdated2_happenedAt = Locale.T.make("2020-04-06T14:35:00+00:00");

var jobUpdated2 = {
  uuid: jobUpdated2_uuid,
  application: "Web",
  worker: jobUpdated2_worker,
  deviceState: undefined,
  happenedAt: jobUpdated2_happenedAt,
  value: "JobUpdated",
  viewedAt: undefined
};

var jobUpdated3_uuid = Uuid.createV4();

var jobUpdated3_worker = admin;

var jobUpdated3_happenedAt = Locale.T.make("2020-04-07T14:35:00+00:00");

var jobUpdated3 = {
  uuid: jobUpdated3_uuid,
  application: "Web",
  worker: jobUpdated3_worker,
  deviceState: undefined,
  happenedAt: jobUpdated3_happenedAt,
  value: "JobUpdated",
  viewedAt: undefined
};

var jobUpdated4_uuid = Uuid.createV4();

var jobUpdated4_happenedAt = Locale.T.make("2020-04-07T14:36:00+00:00");

var jobUpdated4 = {
  uuid: jobUpdated4_uuid,
  application: "API",
  worker: undefined,
  deviceState: undefined,
  happenedAt: jobUpdated4_happenedAt,
  value: "JobUpdated",
  viewedAt: undefined
};

var jobUpdated5_uuid = Uuid.createV4();

var jobUpdated5_application = {
  TAG: "Other",
  _0: "Telepathy"
};

var jobUpdated5_happenedAt = Locale.T.make("2020-04-07T14:39:00+00:00");

var jobUpdated5 = {
  uuid: jobUpdated5_uuid,
  application: jobUpdated5_application,
  worker: undefined,
  deviceState: undefined,
  happenedAt: jobUpdated5_happenedAt,
  value: "JobUpdated",
  viewedAt: undefined
};

var events = [
  jobCreated,
  jobPublished,
  jobEnRoute,
  messageSent,
  jobStarted,
  jobSuspended,
  jobResumed,
  jobFinished
];

function grouped(ctx) {
  var eventsDay1 = [
    jobCreated,
    jobPublished,
    jobEnRoute,
    messageSent,
    movementFinished(ctx),
    jobStarted,
    jobSuspended
  ];
  var group1_date = Locale.T.make("2020-04-04");
  var group1 = {
    date: group1_date,
    events: eventsDay1
  };
  var eventsDay2 = [
    jobResumed,
    imagesFieldUpdated,
    fileFieldUpdated,
    fileFieldEmptied,
    currencyFieldUpdated,
    currencyFieldEmptied,
    dateFieldUpdated,
    dateFieldEmptied,
    datetimeFieldUpdated,
    datetimeFieldEmptied,
    timeFieldUpdated,
    timeFieldEmptied,
    jobFinished,
    imageUploaded,
    jobPrinted
  ];
  var group2_date = Locale.T.make("2020-04-05");
  var group2 = {
    date: group2_date,
    events: eventsDay2
  };
  var eventsDay3 = [jobUpdated1];
  var group3_date = Locale.T.make("2020-04-06");
  var group3 = {
    date: group3_date,
    events: eventsDay3
  };
  var eventsDay4 = [jobUpdated2];
  var group4_date = Locale.T.make("2020-04-07");
  var group4 = {
    date: group4_date,
    events: eventsDay4
  };
  var eventsDay5 = [
    jobUpdated3,
    jobUpdated4,
    jobUpdated5
  ];
  var group5_date = Locale.T.make("2020-04-08");
  var group5 = {
    date: group5_date,
    events: eventsDay5
  };
  return [
          group1,
          group2,
          group3,
          group4,
          group5
        ];
}

var dates = [
    "2020-04-04",
    "2020-04-05",
    "2020-04-06"
  ].map(Locale.T.make);

var emptyLocations_movements = [];

var emptyLocations_other = [];

var emptyLocations = {
  movements: emptyLocations_movements,
  other: emptyLocations_other
};

function day1(ctx) {
  var coordinates = [
    makeFromFloats(55.02, 37.06),
    makeFromFloats(55.03, 37.03),
    makeFromFloats(55.0, 36.95),
    makeFromFloats(55.01, 37.05)
  ];
  var locations = coordinates.map(function (c) {
        return {
                geolocation: c,
                accuracy: 10.0,
                actualAt: jobEnRoute_happenedAt
              };
      });
  var movement_movementFinishedUuid = movementFinished(ctx).uuid;
  var movement = {
    movementFinishedUuid: movement_movementFinishedUuid,
    locations: locations
  };
  var coordinates$1 = [
    makeFromFloats(55.0501, 37.0501),
    makeFromFloats(55.05, 37.05),
    makeFromFloats(55.05, 37.0),
    makeFromFloats(55.0499, 37.0),
    makeFromFloats(55.035, 37.035)
  ];
  var locations$1 = coordinates$1.map(function (c) {
        return {
                geolocation: c,
                accuracy: 10.0,
                actualAt: jobSuspended_happenedAt
              };
      });
  var shortAndLongMovement_movementFinishedUuid = jobEnRoute_uuid;
  var shortAndLongMovement = {
    movementFinishedUuid: shortAndLongMovement_movementFinishedUuid,
    locations: locations$1
  };
  var coordinates$2 = [
    makeFromFloats(55.0329, 37.0399),
    makeFromFloats(55.033, 37.04)
  ];
  var locations$2 = coordinates$2.map(function (c) {
        return {
                geolocation: c,
                accuracy: 10.0,
                actualAt: jobSuspended_happenedAt
              };
      });
  var shortMovement_movementFinishedUuid = jobEnRoute_uuid;
  var shortMovement = {
    movementFinishedUuid: shortMovement_movementFinishedUuid,
    locations: locations$2
  };
  var coordinates$3 = [
    makeFromFloats(53.5232, 43.8329),
    makeFromFloats(55.9988, 37.2237)
  ];
  var locations$3 = coordinates$3.map(function (c) {
        return {
                geolocation: c,
                accuracy: 10.0,
                actualAt: jobSuspended_happenedAt
              };
      });
  var veryLongMovement_movementFinishedUuid = jobEnRoute_uuid;
  var veryLongMovement = {
    movementFinishedUuid: veryLongMovement_movementFinishedUuid,
    locations: locations$3
  };
  var coordinates$4 = [
    makeFromFloats(56.0015756, 37.209013),
    makeFromFloats(55.995063, 37.212805),
    makeFromFloats(55.991423, 37.213502),
    makeFromFloats(55.991763, 37.215955),
    makeFromFloats(55.991449, 37.219145),
    makeFromFloats(55.991135, 37.222341),
    makeFromFloats(55.989768, 37.224159),
    makeFromFloats(55.988178, 37.226102),
    makeFromFloats(55.980605, 37.182521)
  ];
  var locations$4 = coordinates$4.map(function (c) {
        return {
                geolocation: c,
                accuracy: 10.0,
                actualAt: jobSuspended_happenedAt
              };
      });
  var problematicMovement_movementFinishedUuid = jobEnRoute_uuid;
  var problematicMovement = {
    movementFinishedUuid: problematicMovement_movementFinishedUuid,
    locations: locations$4
  };
  var coordinates$5 = [
    makeFromFloats(55.01, 37.05),
    makeFromFloats(55.05, 37.11)
  ];
  var locations$5 = coordinates$5.map(function (c) {
        return {
                geolocation: c,
                accuracy: 10.0,
                actualAt: jobFinished_happenedAt
              };
      });
  var other_movementFinishedUuid = jobFinished_uuid;
  var other = {
    movementFinishedUuid: other_movementFinishedUuid,
    locations: locations$5
  };
  var locations_movements = [
    movement,
    shortAndLongMovement,
    shortMovement,
    veryLongMovement,
    problematicMovement
  ];
  var locations_other = [other];
  var locations$6 = {
    movements: locations_movements,
    other: locations_other
  };
  return {
          date: dates[0],
          locations: locations$6
        };
}

var day2_date = dates[1];

var day2 = {
  date: day2_date,
  locations: undefined
};

var day3_date = dates[2];

var day3 = {
  date: day3_date,
  locations: undefined
};

function byDate(ctx) {
  return [
          day1(ctx),
          day2
        ];
}

var Locations = {
  emptyLocations: emptyLocations,
  day1: day1,
  day2: day2,
  day3: day3,
  byDate: byDate
};

export {
  makeFromFloats ,
  admin ,
  fieldWorker ,
  datetime ,
  jobCreated ,
  jobPublished ,
  jobEnRoute ,
  movementFinished ,
  jobStarted ,
  jobSuspended ,
  jobResumed ,
  imagesFieldUpdated ,
  fileFieldUpdated ,
  fileFieldEmptied ,
  currencyFieldUpdated ,
  currencyFieldEmptied ,
  dateFieldUpdated ,
  dateFieldEmptied ,
  datetimeFieldUpdated ,
  datetimeFieldEmptied ,
  timeFieldUpdated ,
  timeFieldEmptied ,
  jobFinished ,
  imageUploaded ,
  jobPrinted ,
  jobUpdated1 ,
  jobUpdated2 ,
  jobUpdated3 ,
  jobUpdated4 ,
  jobUpdated5 ,
  events ,
  grouped ,
  dates ,
  Locations ,
}
/* jobCreated Not a pure module */
