import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import TemplateContainer from 'planado/components/admin/templates/show/container'
import * as actions from 'planado/actions/admin/templates'

const mapStateToProps = state => ({
  ...state,
  jobsPage: state.jobsPage || 2,
  jobsFetching: state.jobsFetching || false
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
})

const TemplateContainerLink = connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateContainer)

export default TemplateContainerLink
