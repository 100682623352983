

import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as JsonDecode from "../../../../libraries/JsonDecode.mjs";
import * as Types_Date from "../../../../types/Types_Date.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              from: field.required("from", Types_Date.decode),
              to_: field.required("to", Types_Date.decode),
              working: field.required("working", JsonDecode.bool)
            };
    });

var Interval = {
  decode: decode
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              entityUuid: field.required("entityUuid", Uuid.decode),
              timeline: field.required("timeline", JsonDecode.array(decode))
            };
    });

var ScheduleTimeline = {
  decode: decode$1
};

export {
  Interval ,
  ScheduleTimeline ,
}
/* decode Not a pure module */
