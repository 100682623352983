

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              formatted: field.required("formatted", JsonDecode.option(JsonDecode.string)),
              floor: field.required("floor", JsonDecode.option(JsonDecode.string)),
              apartment: field.required("apartment", JsonDecode.option(JsonDecode.string)),
              entranceNo: field.required("entranceNo", JsonDecode.option(JsonDecode.string))
            };
    });

var Address = {
  decode: decode
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              organization: field.required("organization", JsonDecode.bool),
              address: field.required("address", decode),
              phone: field.required("phone", JsonDecode.option(JsonDecode.string))
            };
    });

var Client = {
  decode: decode$1
};

var Bounds = {};

var initial = {
  bounds: {
    offset: 0,
    limit: 50
  }
};

var initial_clients = [];

var initial$1 = {
  fetching: false,
  clients: initial_clients,
  totalClients: 0,
  totalSites: 0,
  filter: initial,
  isAdmin: false
};

function Filter_toJson(prim) {
  return prim;
}

var Filter = {
  toJson: Filter_toJson,
  initial: initial
};

export {
  Address ,
  Client ,
  Bounds ,
  Filter ,
  initial$1 as initial,
}
/* decode Not a pure module */
