

import * as RCore from "../../libraries/RCore.mjs";
import * as React from "react";
import * as $$Number from "../../libraries/Number.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as FormInput from "./FormInput.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function removeSpacesAndLetters(str) {
  var regex = /\s/g;
  return Js_string.replaceByRe(regex, "", str);
}

function isUserFillInt(str) {
  var intTypingRegex = /^-?\d{0,30}$/;
  return intTypingRegex.test(str);
}

function IntegerInput(Props) {
  var id = Props.id;
  var name = Props.name;
  var value = Props.value;
  var onValueChange = Props.onValueChange;
  var disabled = Props.disabled;
  var labelText = Props.labelText;
  var errors = Props.errors;
  var wrapperClass = Props.wrapperClass;
  var onChange = function (str) {
    var value = removeSpacesAndLetters(str);
    if (value.length === 0 || isUserFillInt(value)) {
      return onValueChange(value);
    }
    
  };
  var onBlur = function (_event) {
    onValueChange(RCore.$$Option.getOr(RCore.$$Option.map($$Number.$$BigInt.make(value), $$Number.$$BigInt.toString), ""));
  };
  var wrapperClass$1 = RCore.$$Option.map(wrapperClass, (function (cn) {
          return Js_dict.fromList({
                      hd: [
                        cn,
                        true
                      ],
                      tl: /* [] */0
                    });
        }));
  var tmp = {
    _type: "text",
    value: value,
    onValueChange: onChange,
    onBlur: onBlur,
    maxLength: 31
  };
  if (id !== undefined) {
    tmp.id = id;
  }
  if (name !== undefined) {
    tmp.name = name;
  }
  if (disabled !== undefined) {
    tmp.disabled = disabled;
  }
  if (labelText !== undefined) {
    tmp.labelText = labelText;
  }
  if (errors !== undefined) {
    tmp.errors = Caml_option.valFromOption(errors);
  }
  if (wrapperClass$1 !== undefined) {
    tmp.wrapperClass = Caml_option.valFromOption(wrapperClass$1);
  }
  return React.createElement(FormInput.make, tmp);
}

var make = IntegerInput;

var integerInput = IntegerInput;

export {
  make ,
  integerInput ,
}
/* react Not a pure module */
