

import * as Fun from "../../../../../../libraries/Fun.mjs";
import * as Types from "../../../../../../types/Types.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_DND from "../../../../lib/Schedule_DND.mjs";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as Shared_Lib_Timer from "../../../../../../shared/lib/Shared_Lib_Timer.mjs";
import * as DayTimeline_State from "../../lib/state/DayTimeline_State.mjs";
import * as Schedule_UtilsTimeline from "../../../../lib/utils/Schedule_UtilsTimeline.mjs";
import * as DayTimeline_DragLayerActions from "./DayTimeline_DragLayerActions.mjs";
import * as DayTimeline_DragLayerElements from "./DayTimeline_DragLayerElements.mjs";

var offset = Units.Px.fromInt(150);

function inRect(topLeft, bottomRight, point) {
  if (Caml_obj.lessthan(topLeft[0], point[0]) && Caml_obj.lessthan(point[0], bottomRight[0]) && Caml_obj.lessthan(topLeft[1], point[1])) {
    return Caml_obj.lessthan(point[1], bottomRight[1]);
  } else {
    return false;
  }
}

function top(topLeft, bottomRight, point) {
  return inRect([
              Units.Px.plus(topLeft[0], offset),
              topLeft[1]
            ], [
              Units.Px.minus(bottomRight[0], offset),
              Units.Px.plus(topLeft[1], offset)
            ], point);
}

function right(topLeft, bottomRight, point) {
  return inRect([
              Units.Px.minus(bottomRight[0], offset),
              topLeft[1]
            ], bottomRight, point);
}

function bottom(topLeft, bottomRight, point) {
  return inRect([
              Units.Px.plus(topLeft[0], offset),
              Units.Px.minus(bottomRight[1], offset)
            ], [
              Units.Px.minus(bottomRight[0], offset),
              bottomRight[1]
            ], point);
}

function left(topLeft, bottomRight, point) {
  return inRect(topLeft, [
              Units.Px.plus(topLeft[0], offset),
              bottomRight[1]
            ], point);
}

function make(rect, point) {
  var topLeft_0 = Units.Px.fromFloat(rect.x);
  var topLeft_1 = Units.Px.fromFloat(rect.y);
  var topLeft = [
    topLeft_0,
    topLeft_1
  ];
  var bottomRight_0 = Units.Px.plus(topLeft_0, Units.Px.fromFloat(rect.width));
  var bottomRight_1 = Units.Px.plus(topLeft_1, Units.Px.fromFloat(rect.height));
  var bottomRight = [
    bottomRight_0,
    bottomRight_1
  ];
  if (top(topLeft, bottomRight, point)) {
    return "Top";
  } else if (right(topLeft, bottomRight, point)) {
    return "Right";
  } else if (bottom(topLeft, bottomRight, point)) {
    return "Bottom";
  } else if (left(topLeft, bottomRight, point)) {
    return "Left";
  } else {
    return "Center";
  }
}

function use(viewportEl) {
  var match = Schedule_DND.DND.Context.use();
  var item = match.item;
  var subscriptions = match.subscriptions;
  var handler = React.useRef(function (prim) {
        
      });
  var rafId = React.useRef(undefined);
  var isScrolling = React.useRef(false);
  var scrollSubscriptions = DayTimeline_State.useScrollSubscriptions();
  var cancelHandler = function () {
    handler.current();
    if (isScrolling.current) {
      isScrolling.current = false;
      return ;
    }
    
  };
  var scroll = function (direction, scrollSubscriptions) {
    if (isScrolling.current) {
      scrollSubscriptions.onTriggerScroll.trigger([
            direction,
            undefined
          ]);
      rafId.current = Caml_option.some(requestAnimationFrame(function (param) {
                scroll(direction, scrollSubscriptions);
              }));
      return ;
    }
    
  };
  var scroll$1 = function () {
    var match = Fun.both(Types.ReactRef.toOption(viewportEl), item.contents);
    if (match === undefined) {
      return ;
    }
    var rect = match[0].getBoundingClientRect();
    var direction = make(rect, match[1].dragPreviewOffset);
    if (direction !== "Center") {
      if (scrollSubscriptions !== undefined) {
        isScrolling.current = true;
        return scroll(direction, scrollSubscriptions);
      } else {
        return ;
      }
    }
    
  };
  React.useEffect((function () {
          var unsubscribeFromDrag = subscriptions.onDrag.subscribe(function () {
                cancelHandler();
                handler.current = Shared_Lib_Timer.delay(2000, scroll$1);
              });
          var unsubscribeFromDragEnd = subscriptions.onDragEnd.subscribe(cancelHandler);
          return (function () {
                    unsubscribeFromDrag();
                    unsubscribeFromDragEnd();
                  });
        }), [scrollSubscriptions]);
}

function use$1(viewportEl) {
  var wire = AppContext.useWire();
  var match = Schedule_DND.DND.Context.use();
  var item = match.item;
  var subscriptions = match.subscriptions;
  var sizes = DayTimeline_State.useSizes();
  var filter = Schedule_State.useFilter();
  var currentDay = Schedule_State.useCurrentDay();
  var businessHours = Schedule_State.useBusinessHours();
  var elements = React.useMemo(DayTimeline_DragLayerElements.make, []);
  var rafId = React.useRef(undefined);
  var onDragEnd = function () {
    DayTimeline_DragLayerActions.hideDragLayer(elements);
  };
  React.useEffect((function () {
          var match = Fun.both(Types.ReactRef.toOption(viewportEl), Schedule_UtilsTimeline.contentEl(viewportEl));
          if (match === undefined) {
            return ;
          }
          var contentEl = match[1];
          var viewportEl$1 = match[0];
          wire.footer.appendChild(elements.containerEl);
          var unsubscribeFromDragStart = subscriptions.onDragStart.subscribe(function () {
                var match = item.contents;
                if (match === undefined) {
                  return ;
                }
                var payload = Schedule_DND.DND.Context.payload(match.payload);
                DayTimeline_DragLayerActions.showDragLayer(wire, sizes, filter, businessHours, currentDay, viewportEl$1, contentEl, elements, payload, match.dragPreviewOffset, match.mouseOffset);
              });
          var unsubscribeFromDrag = subscriptions.onDrag.subscribe(function () {
                var id = rafId.current;
                if (id !== undefined) {
                  cancelAnimationFrame(Caml_option.valFromOption(id));
                }
                rafId.current = Caml_option.some(requestAnimationFrame(function (_time) {
                          var match = item.contents;
                          if (match !== undefined) {
                            var payload = Schedule_DND.DND.Context.payload(match.payload);
                            DayTimeline_DragLayerActions.updateDragLayer(wire, sizes, filter, businessHours, currentDay, viewportEl$1, contentEl, elements, payload, match.dragPreviewOffset, match.mouseOffset);
                          }
                          rafId.current = undefined;
                        }));
              });
          var unsubscribeFromDragEnd = subscriptions.onDragEnd.subscribe(onDragEnd);
          return (function () {
                    wire.footer.removeChild(elements.containerEl);
                    unsubscribeFromDragStart();
                    unsubscribeFromDrag();
                    unsubscribeFromDragEnd();
                  });
        }), [
        sizes,
        filter,
        businessHours,
        currentDay
      ]);
}

function DayTimeline_DragLayer(Props) {
  var viewportEl = Props.viewportEl;
  use$1(viewportEl);
  use(viewportEl);
  return null;
}

var make$1 = React.memo(DayTimeline_DragLayer);

export {
  make$1 as make,
}
/* offset Not a pure module */
