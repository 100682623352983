

import * as Fun from "../../../../../../../libraries/Fun.mjs";
import * as React from "react";
import * as Assignee_Types from "../../../../../../../types/Assignee_Types.mjs";
import * as DayTimeline_State from "../../../lib/state/DayTimeline_State.mjs";
import * as DayTimeline_BarDrop from "./hooks/DayTimeline_BarDrop.mjs";
import * as DayTimeline_BarJobs from "./components/DayTimeline_BarJobs.mjs";
import * as DayTimeline_BarCells from "./components/DayTimeline_BarCells.mjs";
import * as Schedule_TimelineHooks from "../../../../../lib/hooks/Schedule_TimelineHooks.mjs";
import * as Schedule_UtilsTimeline from "../../../../../lib/utils/Schedule_UtilsTimeline.mjs";
import * as DayTimeline_BarListeners from "./hooks/DayTimeline_BarListeners.mjs";
import * as DayTimeline_AddJobPopover from "./components/add_job_popover/DayTimeline_AddJobPopover.mjs";
import * as DayTimeline_BarModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/day_timeline/components/timeline/bar/DayTimeline_Bar.module.css";

var styles = DayTimeline_BarModuleCss;

function DayTimeline_Bar(Props) {
  var viewportEl = Props.viewportEl;
  var assignee = Props.assignee;
  var jobs = Props.jobs;
  var workingIntervals = Props.workingIntervals;
  var elementRef = DayTimeline_BarDrop.use(assignee, viewportEl);
  var match = DayTimeline_BarListeners.use(elementRef);
  var match$1 = DayTimeline_State.useTimelineSubscriptions();
  Schedule_TimelineHooks.BarHighlightAssignee.use(elementRef, Assignee_Types.Assignee.uuid(assignee), match$1.onActiveAssigneeChanged);
  var match$2 = Fun.both(match[0], Schedule_UtilsTimeline.contentEl(viewportEl));
  return React.createElement("div", {
              ref: elementRef,
              className: styles.bar
            }, React.createElement(DayTimeline_BarCells.make, {}), React.createElement(DayTimeline_BarJobs.make, {
                  assignee: assignee,
                  jobs: jobs,
                  workingIntervals: workingIntervals
                }), match$2 !== undefined ? React.createElement(DayTimeline_AddJobPopover.make, {
                    assignee: assignee,
                    coordinate: match$2[0],
                    viewportEl: match$2[1],
                    close: match[1]
                  }) : null);
}

var make = React.memo(DayTimeline_Bar);

export {
  make ,
}
/* styles Not a pure module */
