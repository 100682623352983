

import * as Units from "../../../../../../types/Units.mjs";
import * as DayTimeline_Constants from "../../lib/DayTimeline_Constants.mjs";
import * as Schedule_Types_Timeline from "../../../../lib/types/Schedule_Types_Timeline.mjs";

function maxHeight(wire, filter) {
  return Units.Px.multiplyWithInt(Units.Px.plus(DayTimeline_Constants.rowHeight, DayTimeline_Constants.barGapHeight), Schedule_Types_Timeline.Bars.Assignees.length(wire, filter));
}

function snapToGrid(wire, filter, param) {
  var y = param[1];
  var x = param[0];
  var rowHeight = Units.Px.plus(DayTimeline_Constants.rowHeight, DayTimeline_Constants.barGapHeight);
  var bar = Units.Px.multiply(Units.Px.round(Units.Px.divide(y, rowHeight)), rowHeight);
  var y1 = Units.Px.minus(bar, Units.Px.divideByInt(DayTimeline_Constants.barGapHeight, 2));
  var y2 = Units.Px.plus(bar, Units.Px.divideByInt(DayTimeline_Constants.barGapHeight, 2));
  if (Units.Px.equalOrMoreThan(y, y1) && Units.Px.equalOrLessThan(y, y2) && Units.Px.lessThan(y, maxHeight(wire, filter))) {
    return [
            x,
            bar
          ];
  } else {
    return [
            x,
            y
          ];
  }
}

export {
  snapToGrid ,
}
/* DayTimeline_Constants Not a pure module */
