

import * as El from "../../../../../libraries/El.mjs";
import * as Types from "../../../../../types/Types.mjs";
import * as Utils from "../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Tooltip from "../../../../common/Tooltip/Tooltip.mjs";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as Schedule_TimelineWidgetModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/timeline/widget/Schedule_TimelineWidget.module.css";

var dayJobs$p = Utils.Translations.t("js.schedule.v2.widget_tooltips.day_jobs");

var weekJobs$p = Utils.Translations.t("js.schedule.v2.widget_tooltips.week_jobs");

var styles = Schedule_TimelineWidgetModuleCss;

function Schedule_TimelineWidgetCompact(Props) {
  var stats = Props.stats;
  var onClick = Props.onClick;
  var view = Props.view;
  var withUnassignedJobs = Props.withUnassignedJobs;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var elementRef = Types.ReactRef.use();
  var all = stats.all;
  var publishedDeg = stats.published / all * 360.0;
  var enRouteDeg = publishedDeg + stats.enRoute / all * 360.0;
  var startedDeg = enRouteDeg + stats.started / all * 360.0;
  var suspendedDeg = startedDeg + stats.suspended / all * 360.0;
  var successfullyFinishedDeg = suspendedDeg + stats.successfullyFinished / all * 360.0;
  var unsuccessfullyFinishedDeg = successfullyFinishedDeg + stats.unsuccessfullyFinished / all * 360.0;
  var style = {
    background: "conic-gradient(#6bc4f4 0deg " + publishedDeg.toString() + "deg, #7f7be9 " + publishedDeg.toString() + "deg " + enRouteDeg.toString() + "deg, #4cbc86 " + enRouteDeg.toString() + "deg " + startedDeg.toString() + "deg, #98a1af " + startedDeg.toString() + "deg " + suspendedDeg.toString() + "deg, #aedd74 " + suspendedDeg.toString() + "deg " + successfullyFinishedDeg.toString() + "deg, #ff8989 " + successfullyFinishedDeg.toString() + "deg " + unsuccessfullyFinishedDeg.toString() + "deg)"
  };
  var tooltip = view === "day" ? dayJobs$p(ctx) : weekJobs$p(ctx);
  var className = El.Cn.concat(El.Cn.concat(styles.compact, withUnassignedJobs ? styles.withUnassignedJobs : ""), view === "day" ? styles.day : styles.week);
  return React.createElement(Tooltip.make, {
              elementRef: elementRef,
              position: "top",
              content: tooltip,
              children: React.createElement("button", {
                    ref: elementRef,
                    className: className,
                    style: style,
                    type: "button",
                    onClick: onClick
                  }, React.createElement("div", {
                        className: styles.compactInner
                      }, all.toString()))
            });
}

var make = Schedule_TimelineWidgetCompact;

export {
  make ,
}
/* dayJobs' Not a pure module */
