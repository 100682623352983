

import * as React from "react";
import * as DayTimeline_HighlightTimeHook from "./DayTimeline_HighlightTimeHook.mjs";
import * as DayTimeline_HighlightTimeModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/day_timeline/components/highlight_time/DayTimeline_HighlightTime.module.css";

var styles = DayTimeline_HighlightTimeModuleCss;

function DayTimeline_HighlightTime(Props) {
  var ref = DayTimeline_HighlightTimeHook.use();
  return React.createElement("div", {
              ref: ref,
              className: styles.line
            });
}

var make = React.memo(DayTimeline_HighlightTime);

export {
  make ,
}
/* styles Not a pure module */
