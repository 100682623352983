

import * as Uuid from "../../../../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../../../../libraries/RCore.mjs";
import * as React from "react";
import * as AppContext from "../../../../../../../../context/AppContext.mjs";
import * as Assignee_Types from "../../../../../../../../types/Assignee_Types.mjs";
import * as Schedule_State from "../../../../../../lib/state/Schedule_State.mjs";
import * as DayTimeline_State from "../../../../lib/state/DayTimeline_State.mjs";
import * as Schedule_Types_Job from "../../../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as DayTimeline_Interval from "./interval/DayTimeline_Interval.mjs";
import * as Schedule_TimelineJob from "../../../../../../components/timeline/job/Schedule_TimelineJob.mjs";
import * as Schedule_TimelineJobGroups from "../../../../../../components/timeline/job_groups/Schedule_TimelineJobGroups.mjs";
import * as DayTimeline_AssignJobBlocks from "./assign_job_blocks/DayTimeline_AssignJobBlocks.mjs";
import * as DayTimeline_IntervalPositions from "../../../../lib/positions/DayTimeline_IntervalPositions.mjs";
import * as DayTimeline_AssignedJobPositions from "../../../../lib/positions/DayTimeline_AssignedJobPositions.mjs";
import * as DayTimeline_BarModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/day_timeline/components/timeline/bar/DayTimeline_Bar.module.css";

var styles = DayTimeline_BarModuleCss;

function DayTimeline_BarJobs$Intervals(Props) {
  var intervals = Props.intervals;
  return intervals.map(function (position, idx) {
              return React.createElement(DayTimeline_Interval.make, {
                          position: position,
                          key: idx.toString()
                        });
            });
}

function DayTimeline_BarJobs(Props) {
  var assignee = Props.assignee;
  var jobs = Props.jobs;
  var workingIntervals = Props.workingIntervals;
  var wire = AppContext.useWire();
  var currentDay = Schedule_State.useCurrentDay();
  var businessHours = Schedule_State.useBusinessHours();
  var sizes = DayTimeline_State.useSizes();
  var match = DayTimeline_State.useTimelineSubscriptions();
  var onScrollStart = match.onScrollStart;
  var jobs$1 = DayTimeline_AssignedJobPositions.make(wire, businessHours, currentDay, sizes.minute, jobs);
  var dayoffIntervals = DayTimeline_IntervalPositions.make(wire, businessHours, currentDay, sizes.minute, workingIntervals);
  var jobBlocks = jobs$1.map(function (param) {
        var position = param[0];
        var job = param[1];
        var match = position.size.contents;
        var size;
        switch (match) {
          case "Full" :
              size = "Full";
              break;
          case "Half" :
              size = "Half";
              break;
          case "OneThird" :
              size = "OneThird";
              break;
          
        }
        return React.createElement(Schedule_TimelineJob.make, {
                    job: {
                      TAG: "Assigned",
                      _0: job
                    },
                    size: size,
                    top: position.top,
                    left: position.left,
                    width: position.width,
                    startedOutside: position.startedOutside,
                    finishedOutside: position.finishedOutside,
                    onScrollStart: onScrollStart,
                    bar: assignee,
                    key: Uuid.toString(Schedule_Types_Job.AssignedJob.uuid(job))
                  });
      });
  var hiddenJobs = React.useMemo((function () {
          return RCore.$$Array.keep(jobs$1, (function (param) {
                        return param[0].hidden.contents;
                      }));
        }), [jobs$1]);
  return React.createElement("div", {
              className: styles.jobs
            }, React.createElement(DayTimeline_BarJobs$Intervals, {
                  intervals: dayoffIntervals
                }), jobBlocks, React.createElement(Schedule_TimelineJobGroups.make, {
                  assigneeUuid: Assignee_Types.Assignee.uuid(assignee),
                  onScrollStart: onScrollStart,
                  jobs: hiddenJobs
                }), React.createElement(DayTimeline_AssignJobBlocks.make, {
                  assignee: assignee
                }));
}

var make = DayTimeline_BarJobs;

export {
  make ,
}
/* styles Not a pure module */
