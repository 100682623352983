/* eslint-disable no-undef */
import { createStore, applyMiddleware, compose } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'

import { camelizeKeysWithId } from 'planado/utils/index.js'

const middlewares = [thunk]
const environment = process.env.NODE_ENV || 'development'

export const middlewaresWithLogger = (loggerOptions = {}) => {
  if (environment === 'development') {
    const logger = createLogger(loggerOptions)
    const composeEnhancers =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

    return composeEnhancers(applyMiddleware(...middlewares.concat([logger])))
  } else {
    return compose(applyMiddleware(...middlewares))
  }
}

const createStoreWithLogger = (reducer, state, options = {}) => {
  let camelizedState
  if (state.hasOwnProperty('wire')) {
    camelizedState = {
      ...camelizeKeysWithId({ ...state, wire: null }),
      wire: state.wire,
    }
  } else {
    camelizedState = camelizeKeysWithId(state)
  }

  return createStore(
    reducer,
    camelizedState,
    middlewaresWithLogger(options.logger || {})
  )
}

export { createStoreWithLogger as createStore }

export function connectDocumentToStore(document, except, store) {
  let exceptClicked = false

  except.addEventListener(
    'click',
    () => {
      exceptClicked = true
    },
    false
  )

  document.addEventListener(
    'click',
    () => {
      if (!exceptClicked) {
        store.dispatch({ type: 'WINDOW_CLICKED' })
      }

      exceptClicked = false
    },
    false
  )
}
