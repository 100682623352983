

import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as WeekCalendar_Constants from "../../lib/WeekCalendar_Constants.mjs";
import * as WeekCalendar_JobGroupsTypes from "./WeekCalendar_JobGroupsTypes.mjs";

function intervals(businessHours) {
  if (businessHours === undefined) {
    return RCore.$$Array.range(0, WeekCalendar_Constants.hoursInDay - 1 | 0);
  }
  var start = Locale.T.Schedule.startDurationToHours(businessHours.start);
  var finish = Locale.T.Schedule.finishDurationToHours(businessHours.finish);
  return RCore.$$Array.range(0, (finish - start | 0) - 1 | 0);
}

function use(jobs) {
  var businessHours = Schedule_State.useBusinessHours();
  var hiddenJobs = RCore.$$Array.keep(jobs, (function (param) {
          return param[0].hidden.contents;
        }));
  return WeekCalendar_JobGroupsTypes.Groups.update(WeekCalendar_JobGroupsTypes.Groups.make(intervals(businessHours), hiddenJobs));
}

export {
  use ,
}
/* Locale Not a pure module */
