

import * as Fun from "../../../../../../libraries/Fun.mjs";
import * as Tour from "../../../../../../libraries/tours/Tour.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as FormInput from "../../../../../inputs/FormInput.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JobForm_Site from "./client/JobForm_Site.mjs";
import * as Context_Types from "../../../../../../context/Context_Types.mjs";
import * as JobForm_Client from "./client/JobForm_Client.mjs";
import * as JobEdit_JobType from "../../../lib/types/JobEdit_JobType.mjs";
import * as MultipleAssignees from "../../../../../common/MultipleAssignees/MultipleAssignees.mjs";
import * as JobForm_CustomFields from "../custom_fields/JobForm_CustomFields.mjs";
import * as JobForm_BodyTypeBlock from "./type_block/JobForm_BodyTypeBlock.mjs";
import * as JobForm_BodySkillsBlock from "./skills_block/JobForm_BodySkillsBlock.mjs";
import * as MultipleAssignees_Utils from "../../../../../common/MultipleAssignees/MultipleAssignees_Utils.mjs";
import * as JobForm_ScheduleWarnings from "./schedule_warnings/JobForm_ScheduleWarnings.mjs";
import * as JobForm_BodyScheduleBlock from "./schedule_block/JobForm_BodyScheduleBlock.mjs";
import * as JobForm_BodyTemplateBlock from "./template_block/JobForm_BodyTemplateBlock.mjs";
import * as JobForm_BodyExternalIdBlock from "./external_id_block/JobForm_BodyExternalIdBlock.mjs";
import * as JobForm_BodyBusinessHoursWarning from "./business_hours_warning/JobForm_BodyBusinessHoursWarning.mjs";
import * as JobFormBodyModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/edit/form/components/body/JobFormBody.module.css";

var description$p = Utils.Translations.t("js.jobs.labels.description");

var assignee$p = Utils.Translations.tr("js.jobs.labels.assignee_subject_id");

var styles = JobFormBodyModuleCss;

function inputId(name) {
  return "job-form-input-" + name;
}

function JobFormBody(Props) {
  var wire = Props.wire;
  var job = Props.job;
  var dispatch = Props.dispatch;
  var setLock = Props.setLock;
  var customFieldsErrors = Props.customFieldsErrors;
  var jobErrors = Props.jobErrors;
  var clientSite = job.clientSite;
  var scheduledDuration = job.scheduledDuration;
  var scheduledAt = job.scheduledAt;
  var skillUuids = job.skillUuids;
  var jobTypes = job.jobTypes;
  var typeUuid = job.typeUuid;
  var template = job.template;
  var status = job.status;
  var isNewRecord = job.isNewRecord;
  var externalId = job.externalId;
  var uuid = job.uuid;
  var ctx = wire.ctx;
  var hasRightsToEdit = React.useMemo(function () {
        var permissions = ctx.permissions;
        if (permissions !== undefined) {
          return Context_Types.$$Permissions.hasRightsToEdit(permissions);
        } else {
          return false;
        }
      });
  var statusAllowEdit = React.useMemo(function () {
        return [
                  "Posted",
                  "Scheduled",
                  "Published"
                ].some(function (s) {
                    return s === status;
                  });
      });
  var match = React.useState(function () {
        return [
                JobEdit_JobType.Site.New.empty(),
                JobEdit_JobType.Client.Individual.New.empty(),
                JobEdit_JobType.Client.Organization.New.empty()
              ];
      });
  var updateCache = match[1];
  var match$1 = match[0];
  var org = match$1[2];
  var ind = match$1[1];
  var siteCache = match$1[0];
  var disabledBySignatureFieldTypes = RCore.$$Array.filterMap(job.reportFields, (function (f) {
          var match = f.value;
          if (match.TAG !== "Signature") {
            return ;
          }
          var match$1 = match._0;
          if (match$1.attachments.length > 0) {
            return Caml_option.some(match$1.signedFieldTypeUuid);
          }
          
        }));
  React.useEffect((function () {
          if (clientSite !== undefined) {
            if (clientSite.TAG === "Client") {
              var match = clientSite._0;
              if (match.TAG === "Organization") {
                var v = match._0;
                if (v.TAG !== "Edit") {
                  var v$1 = v._0;
                  updateCache(function (param) {
                        return [
                                param[0],
                                param[1],
                                v$1
                              ];
                      });
                }
                
              } else {
                var v$2 = match._0;
                if (v$2.TAG !== "Edit") {
                  var v$3 = v$2._0;
                  updateCache(function (param) {
                        return [
                                param[0],
                                v$3,
                                param[2]
                              ];
                      });
                }
                
              }
            } else {
              var v$4 = clientSite._0;
              if (v$4.TAG !== "Edit") {
                var v$5 = v$4._0;
                updateCache(function (param) {
                      return [
                              v$5,
                              param[1],
                              param[2]
                            ];
                    });
              }
              
            }
          }
          
        }), [clientSite]);
  var onSwitch = function (cType) {
    switch (cType) {
      case "individual" :
          return dispatch({
                      TAG: "Job",
                      _0: {
                        TAG: "SetClient",
                        _0: {
                          TAG: "Individual",
                          _0: {
                            TAG: "New",
                            _0: ind
                          }
                        }
                      }
                    });
      case "organization" :
          return dispatch({
                      TAG: "Job",
                      _0: {
                        TAG: "SetClient",
                        _0: {
                          TAG: "Organization",
                          _0: {
                            TAG: "New",
                            _0: org
                          }
                        }
                      }
                    });
      case "site" :
          return dispatch({
                      TAG: "Job",
                      _0: {
                        TAG: "SetSite",
                        _0: {
                          TAG: "New",
                          _0: siteCache
                        }
                      }
                    });
      default:
        return dispatch({
                    TAG: "Job",
                    _0: "RemoveClientSite"
                  });
    }
  };
  var tour = Tour.use(wire);
  var tmp;
  if (scheduledAt !== undefined) {
    var scheduledAt$1 = Caml_option.valFromOption(scheduledAt);
    tmp = React.createElement(React.Fragment, undefined, React.createElement(JobForm_BodyBusinessHoursWarning.make, {
              scheduledAt: scheduledAt$1,
              status: status,
              wire: wire
            }), React.createElement(JobForm_ScheduleWarnings.make, {
              wire: wire,
              jobUuid: uuid,
              assignees: job.assignees,
              scheduledAt: scheduledAt$1,
              scheduledDuration: scheduledDuration,
              status: status
            }));
  } else {
    tmp = null;
  }
  var tmp$1 = {
    inputId: "job-form-input-assignee_subject_id",
    selectedAssignees: job.assignees,
    territoryUuid: job.territoryUuid,
    skillUuids: skillUuids,
    availableSkills: ctx.availableSkills,
    disabled: !statusAllowEdit || !hasRightsToEdit,
    onChange: (function (a) {
        dispatch({
              TAG: "Job",
              _0: {
                TAG: "SetAssignees",
                _0: a
              }
            });
      }),
    openUp: false
  };
  if (job.fallbackTeamName !== undefined) {
    tmp$1.fallbackTeamName = job.fallbackTeamName;
  }
  if (scheduledAt !== undefined) {
    tmp$1.availableAt = Caml_option.valFromOption(scheduledAt);
  }
  var tmp$2;
  var exit = 0;
  if (clientSite !== undefined) {
    if (clientSite.TAG === "Client") {
      var match$2 = clientSite._0;
      if (match$2.TAG === "Organization") {
        var org$1 = match$2._0;
        if (org$1.TAG === "Edit") {
          exit = 1;
        } else {
          tmp$2 = React.createElement(JobForm_Client.Organization.make, {
                onSwitch: onSwitch,
                organization: org$1._0,
                job: job,
                dispatch: dispatch,
                errors: jobErrors,
                inputId: inputId
              });
        }
      } else {
        var client = match$2._0;
        if (client.TAG === "Edit") {
          exit = 1;
        } else {
          tmp$2 = React.createElement(JobForm_Client.Individual.make, {
                onSwitch: onSwitch,
                client: client._0,
                dispatch: dispatch,
                job: job,
                errors: jobErrors,
                inputId: inputId
              });
        }
      }
    } else {
      var site = clientSite._0;
      if (site.TAG === "Edit") {
        exit = 1;
      } else {
        tmp$2 = React.createElement(JobForm_Site.make, {
              onSwitch: onSwitch,
              site: site._0,
              job: job,
              dispatch: dispatch,
              errors: jobErrors,
              inputId: inputId
            });
      }
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp$2 = React.createElement(JobForm_Client.Edit.make, {
          readOnly: !hasRightsToEdit,
          clientSite: clientSite,
          onAddClicked: (function (e) {
              e.preventDefault();
              tour.trigger("newClientChosen");
              dispatch({
                    TAG: "Job",
                    _0: {
                      TAG: "SetClient",
                      _0: {
                        TAG: "Individual",
                        _0: {
                          TAG: "New",
                          _0: ind
                        }
                      }
                    }
                  });
            }),
          job: job,
          dispatch: dispatch,
          inputId: inputId
        });
  }
  return React.createElement("div", {
              className: "content"
            }, React.createElement("div", {
                  className: "job-data"
                }, React.createElement("div", {
                      className: "pd-form-group-container transparent-border-1px"
                    }, React.createElement(JobForm_BodyTemplateBlock.make, {
                          template: template,
                          typeUuid: typeUuid,
                          isNewRecord: isNewRecord,
                          id: "job-form-input-template",
                          ctx: ctx,
                          jobTypes: jobTypes
                        }), React.createElement(JobForm_BodyTypeBlock.make, {
                          id: "job-form-input-type",
                          template: template,
                          typeUuid: typeUuid,
                          jobTypes: jobTypes,
                          onValueChange: (function (typeUuid) {
                              dispatch({
                                    TAG: "Job",
                                    _0: {
                                      TAG: "SetTypeUuid",
                                      _0: typeUuid
                                    }
                                  });
                            }),
                          isNewRecord: isNewRecord,
                          ctx: ctx
                        })), React.createElement(FormInput.make, {
                      _type: "textarea",
                      value: RCore.$$Option.getOr(job.description, ""),
                      onValueChange: (function (text) {
                          dispatch({
                                TAG: "Job",
                                _0: {
                                  TAG: "SetDescription",
                                  _0: Fun.optString(text)
                                }
                              });
                          tour.trigger({
                                NAME: "jobDescriptionUpdated",
                                VAL: Fun.optString(text)
                              });
                        }),
                      id: "job-form-input-description",
                      readOnly: !hasRightsToEdit,
                      name: "job[description]",
                      labelText: description$p(ctx),
                      maxLength: 15000,
                      wrapperClass: Js_dict.fromList({
                            hd: [
                              "intercom-job-add-description",
                              true
                            ],
                            tl: {
                              hd: [
                                wire.tour.anchor("job-form-description"),
                                true
                              ],
                              tl: /* [] */0
                            }
                          }),
                      inputClass: Js_dict.fromList({
                            hd: [
                              styles.descriptionInput,
                              true
                            ],
                            tl: /* [] */0
                          })
                    }), React.createElement(JobForm_BodySkillsBlock.make, {
                      ctx: ctx,
                      template: template,
                      possibleAssignees: job.possibleAssignees,
                      skillUuids: skillUuids,
                      setSkills: (function (uuids) {
                          dispatch({
                                TAG: "Job",
                                _0: {
                                  TAG: "SetSkills",
                                  _0: uuids
                                }
                              });
                        }),
                      status: status
                    }), React.createElement("div", undefined, React.createElement(JobForm_BodyScheduleBlock.make, {
                          wire: wire,
                          inputId: inputId,
                          disabled: !(hasRightsToEdit && statusAllowEdit),
                          setScheduledAt: (function (scheduledAt) {
                              dispatch({
                                    TAG: "Job",
                                    _0: {
                                      TAG: "SetScheduledAt",
                                      _0: scheduledAt
                                    }
                                  });
                              var oldAssignees = job.assignees;
                              var withNewTemp = MultipleAssignees_Utils.withActualTempWorkers(job.assignees, scheduledAt, ctx.allAssignees);
                              if (Caml_obj.equal(oldAssignees, withNewTemp)) {
                                return ;
                              } else {
                                return dispatch({
                                            TAG: "Job",
                                            _0: {
                                              TAG: "SetAssignees",
                                              _0: withNewTemp
                                            }
                                          });
                              }
                            }),
                          setScheduledDuration: (function (scheduledDuration) {
                              dispatch({
                                    TAG: "Job",
                                    _0: {
                                      TAG: "SetScheduledDuration",
                                      _0: scheduledDuration
                                    }
                                  });
                            }),
                          scheduledAt: scheduledAt,
                          maxScheduledDuration: job.maxScheduledDuration,
                          scheduledDuration: scheduledDuration
                        }), tmp), React.createElement("div", {
                      className: "pd-form-group job-assignee_subject_id transparent-border-1px intercom-job-add-assignee " + wire.tour.anchor("job-form-assignee-select")
                    }, React.createElement("label", {
                          className: "pd-label",
                          htmlFor: "job-form-input-assignee_subject_id"
                        }, assignee$p(ctx)), React.createElement(MultipleAssignees.make, tmp$1)), React.createElement(JobForm_CustomFields.make, {
                      wire: wire,
                      customFields: job.customFields,
                      disabled: (function (f) {
                          if (!hasRightsToEdit || f.readOnly) {
                            return true;
                          } else {
                            return disabledBySignatureFieldTypes.some(function (uuid) {
                                        return Caml_obj.equal(uuid, f.typeUuid);
                                      });
                          }
                        }),
                      addFile: (function (fieldUuid, file) {
                          dispatch({
                                TAG: "Job",
                                _0: {
                                  TAG: "SetCustomFieldFile",
                                  _0: fieldUuid,
                                  _1: file
                                }
                              });
                        }),
                      removeFile: (function (fieldUuid, uuid) {
                          dispatch({
                                TAG: "Job",
                                _0: {
                                  TAG: "RemoveCustomFieldFile",
                                  _0: fieldUuid,
                                  _1: uuid
                                }
                              });
                        }),
                      setLock: setLock,
                      jobUuid: uuid,
                      updateCustomField: (function (field) {
                          dispatch({
                                TAG: "Job",
                                _0: {
                                  TAG: "UpdateCustomField",
                                  _0: field
                                }
                              });
                        }),
                      customFieldsErrors: customFieldsErrors
                    }), React.createElement(JobForm_BodyExternalIdBlock.make, {
                      wire: wire,
                      id: "job-form-input-external_id",
                      name: "job[external_id]",
                      value: externalId,
                      show: isNewRecord && RCore.$$Option.isSome(externalId) || RCore.$$Option.isSome(externalId),
                      onValueChange: (function (externalId) {
                          dispatch({
                                TAG: "Job",
                                _0: {
                                  TAG: "SetExternalId",
                                  _0: externalId
                                }
                              });
                        }),
                      readOnly: !isNewRecord,
                      errors: []
                    })), tmp$2);
}

var make = JobFormBody;

export {
  make ,
}
/* description' Not a pure module */
