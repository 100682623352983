

import * as Uuid from "../../../../../../bindings/uuid/Uuid.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as Backend from "../../../../../../libraries/backend/Backend.mjs";
import * as PromiseF from "../../../../../../libraries/PromiseF.mjs";
import * as JsonDecode from "../../../../../../libraries/JsonDecode.mjs";
import * as JobEdit_Types from "../../types/JobEdit_Types.mjs";
import * as Shared_Lib_IO from "../../../../../../shared/lib/Shared_Lib_IO.mjs";
import * as Backend_Headers from "../../../../../../libraries/backend/Backend_Headers.mjs";
import * as JobEdit_Decoder from "./JobEdit_Decoder.mjs";
import * as JobEdit_Encoder from "./JobEdit_Encoder.mjs";
import * as JobEdit_JobType from "../../types/JobEdit_JobType.mjs";
import * as JobForm_HistoryTypes from "../../types/JobForm_HistoryTypes.mjs";

function fetchByUuid(wire, uuid) {
  return PromiseF.toIOLazy(function () {
              return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                              NAME: "API",
                              VAL: {
                                NAME: "Job",
                                VAL: {
                                  NAME: "Edit",
                                  VAL: uuid
                                }
                              }
                            }), JobEdit_JobType.decode(wire));
            });
}

function fetchNewWithOptions(wire, options) {
  return PromiseF.toIOLazy(function () {
              return Backend.decode(Backend.post(Backend_Headers.contentJsonOverridden, undefined, undefined, undefined, wire, {
                              NAME: "API",
                              VAL: {
                                NAME: "Job",
                                VAL: "New"
                              }
                            }, {
                              NAME: "Obj",
                              VAL: JobEdit_Encoder.FetchOptions.encode(options)
                            }), JobEdit_JobType.decode(wire));
            });
}

function $$fetch(wire, subroute) {
  if (subroute.NAME !== "Edit") {
    return fetchNewWithOptions(wire, subroute.VAL);
  }
  var match = subroute.VAL;
  var json = match.jobResponse;
  if (json !== undefined) {
    return PromiseF.toIOLazy(function () {
                return Backend.decode(PromiseF.pure({
                                TAG: "Ok",
                                _0: json
                              }), JobEdit_JobType.decode(wire));
              });
  } else {
    return fetchByUuid(wire, match.uuid);
  }
}

var Get = {
  fetchByUuid: fetchByUuid,
  fetchNewWithOptions: fetchNewWithOptions,
  $$fetch: $$fetch
};

function post(wire, job, payloadObj) {
  var payload = JobEdit_Encoder.encodeNewJob(job, wire, payloadObj);
  return PromiseF.toIOLazy(function () {
              return Backend.decode(Backend.post(undefined, undefined, undefined, true, wire, {
                              NAME: "API",
                              VAL: {
                                NAME: "Job",
                                VAL: "Index"
                              }
                            }, {
                              NAME: "Obj",
                              VAL: Utils.jsonToObj(payload)
                            }), JobEdit_Types.Job.PostResponse.decode);
            });
}

var Post = {
  post: post
};

function cancel(wire, uuid, payload) {
  return PromiseF.toIOLazy(function () {
              return Backend.decode(Backend.patch(undefined, undefined, undefined, true, wire, {
                              NAME: "API",
                              VAL: {
                                NAME: "Job",
                                VAL: {
                                  NAME: "Cancel",
                                  VAL: uuid
                                }
                              }
                            }, {
                              NAME: "Obj",
                              VAL: payload
                            }), JobEdit_Types.Job.PostResponse.decode);
            });
}

function patch(wire, uuid, changedData, payload) {
  var payload$1 = JobEdit_Encoder.encodeChangedData(changedData, wire, payload);
  return PromiseF.toIOLazy(function () {
              return Backend.decode(Backend.patch(undefined, undefined, undefined, true, wire, {
                              NAME: "API",
                              VAL: {
                                NAME: "Job",
                                VAL: {
                                  NAME: "Edit",
                                  VAL: uuid
                                }
                              }
                            }, {
                              NAME: "Obj",
                              VAL: Utils.jsonToObj(payload$1)
                            }), JobEdit_Types.Job.PostResponse.decode);
            });
}

var Patch = {
  cancel: cancel,
  patch: patch
};

function $$delete(wire, uuid) {
  return PromiseF.toIOLazy(function () {
              return Backend.decode(Backend.$$delete(undefined, undefined, undefined, wire, {
                              NAME: "API",
                              VAL: {
                                NAME: "Job",
                                VAL: {
                                  NAME: "Edit",
                                  VAL: uuid
                                }
                              }
                            }), JobEdit_Types.Job.PostResponse.decode);
            });
}

var Delete = {
  $$delete: $$delete
};

var Job = {
  Get: Get,
  Post: Post,
  Patch: Patch,
  Delete: Delete
};

function $$fetch$1(wire, uuid) {
  return PromiseF.toIOLazy(function () {
              return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                              NAME: "API",
                              VAL: {
                                NAME: "Job",
                                VAL: {
                                  NAME: "History",
                                  VAL: {
                                    NAME: "Uuid",
                                    VAL: uuid
                                  }
                                }
                              }
                            }), JobForm_HistoryTypes.$$History.decode(wire));
            });
}

var Get$1 = {
  $$fetch: $$fetch$1
};

function $$fetch$2(wire, id, date) {
  return PromiseF.toIOLazy(function () {
              return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                              NAME: "API",
                              VAL: {
                                NAME: "Job",
                                VAL: {
                                  NAME: "History",
                                  VAL: {
                                    NAME: "DayLocations",
                                    VAL: [
                                      id,
                                      date
                                    ]
                                  }
                                }
                              }
                            }), JobForm_HistoryTypes.LocationsByDate.decode);
            });
}

var Get$2 = {
  $$fetch: $$fetch$2
};

var DayLocations = {
  Get: Get$2
};

var $$History = {
  Get: Get$1,
  DayLocations: DayLocations
};

function post$1(wire, jobUuid, stencilUuid) {
  return Backend.decode(Backend.post(undefined, undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "RenderRequest",
                    VAL: "Index"
                  }
                }, {
                  NAME: "Obj",
                  VAL: {
                    job_uuid: jobUuid,
                    stencil_uuid: stencilUuid
                  }
                }), JobEdit_Types.PrintRequest.PostResponse.decode);
}

var Post$1 = {
  post: post$1
};

function $$fetch$3(wire, uuid) {
  return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "RenderRequest",
                    VAL: {
                      NAME: "Show",
                      VAL: uuid
                    }
                  }
                }), JobEdit_Types.PrintRequest.GetResponse.decode);
}

var Get$3 = {
  $$fetch: $$fetch$3
};

var Print = {
  Post: Post$1,
  Get: Get$3
};

function patch$1(wire, jobUuid, typeUuid) {
  return Backend.patch(undefined, true, undefined, true, wire, {
              NAME: "API",
              VAL: {
                NAME: "Job",
                VAL: {
                  NAME: "ReportButton",
                  VAL: [
                    Uuid.toString(jobUuid),
                    Uuid.toString(typeUuid)
                  ]
                }
              }
            }, {
              NAME: "Obj",
              VAL: {}
            });
}

var Patch$1 = {
  patch: patch$1
};

var ReportFieldButton = {
  Patch: Patch$1
};

function $$fetch$4(wire, workerUuids, from, to_, except) {
  var period = [
    from,
    to_
  ];
  var params = JobEdit_Types.OverlapRequestParams.make(workerUuids, period, except);
  return Shared_Lib_IO.debounce(undefined, 300, (function () {
                return PromiseF.toIOLazy(function () {
                            return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                                            NAME: "API",
                                            VAL: {
                                              NAME: "Job",
                                              VAL: {
                                                NAME: "Overlapping",
                                                VAL: params
                                              }
                                            }
                                          }), JobEdit_Decoder.OverlappingJobs.decode);
                          });
              }));
}

var Get$4 = {
  $$fetch: $$fetch$4
};

var OverlappingJobs = {
  Get: Get$4
};

function $$fetch$5(wire, workerUuids, from, to_) {
  var period = [
    from,
    to_
  ];
  var params = JobEdit_Types.ShiftsRequestParams.make(workerUuids, period);
  return Shared_Lib_IO.debounce(undefined, 300, (function () {
                return PromiseF.toIOLazy(function () {
                            return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                                            NAME: "API",
                                            VAL: {
                                              NAME: "Workers",
                                              VAL: {
                                                NAME: "NotWorking",
                                                VAL: params
                                              }
                                            }
                                          }), JobEdit_Decoder.OutOfShiftWorkers.decode);
                          });
              }));
}

var Get$5 = {
  $$fetch: $$fetch$5
};

var OutOfShiftsWorkers = {
  Get: Get$5
};

var decode = JsonDecode.object(function (field) {
      return field.required("territoryUuid", JsonDecode.option(Uuid.decode));
    });

function $$fetch$6(geolocation, wire) {
  return Shared_Lib_IO.debounce(undefined, 300, (function () {
                  return PromiseF.toIOLazy(function () {
                              return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                                              NAME: "API",
                                              VAL: {
                                                NAME: "Job",
                                                VAL: {
                                                  NAME: "Territories",
                                                  VAL: {
                                                    NAME: "Find",
                                                    VAL: geolocation
                                                  }
                                                }
                                              }
                                            }), decode);
                            });
                }))();
}

var JobTerritory = {
  decode: decode,
  $$fetch: $$fetch$6
};

export {
  Job ,
  $$History ,
  Print ,
  ReportFieldButton ,
  OverlappingJobs ,
  OutOfShiftsWorkers ,
  JobTerritory ,
}
/* decode Not a pure module */
