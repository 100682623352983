

import * as El from "../../../../libraries/El.mjs";
import * as Fun from "../../../../libraries/Fun.mjs";
import * as Url from "../../../../bindings/url/Url.mjs";
import * as Icon from "../../../../Icon.mjs";
import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as Hooks from "../../../../libraries/hooks/Hooks.mjs";
import * as Modal from "../../../modal/Modal.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Route from "../../../../types/Route.mjs";
import * as Types from "../../../../types/Types.mjs";
import * as Units from "../../../../types/Units.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Router from "../../../../Router.mjs";
import * as Script from "../../../../Script.mjs";
import * as Backend from "../../../../libraries/backend/Backend.mjs";
import * as Context from "../../../../context/Context.mjs";
import * as Spinner from "../../../common/Spinner/Spinner.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as Billing_API from "../Billing_API.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DropDownMenu from "../../../common/DropDownMenu/DropDownMenu.mjs";
import * as Subscription from "../../../../libraries/Subscription.mjs";
import * as Billing_Utils from "../Billing_Utils.mjs";
import * as Context_Types from "../../../../context/Context_Types.mjs";
import * as MessengerTypes from "../../../messenger/MessengerTypes.mjs";
import * as Billing_Components_Blocks from "../components/Billing_Components_Blocks.mjs";
import * as Billing_Components_Crypto from "../components/Billing_Components_Crypto.mjs";
import * as Billing_Components_Paddle from "../components/Billing_Components_Paddle.mjs";
import * as Billing_Wizard_ChoosePlan from "./Billing_Wizard_ChoosePlan.mjs";
import * as Billing_Wizard_Components from "./Billing_Wizard_Components.mjs";
import * as Billing_Wizard_PaymentMethods from "./Billing_Wizard_PaymentMethods.mjs";
import * as BillingModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/billing/Billing.module.css";

var changePlan$p = Utils.Translations.tr("js.billing.wizard.change_plan");

var continue$p = Utils.Translations.tr("common.continue");

var requestPrice$p = Utils.Translations.tr("js.billing.wizard.request_pricing");

var save$p = Utils.Translations.tr("common.save");

var saved$p = Utils.Translations.tr("common.saved");

var choosePlanBtn$p = Utils.Translations.tr("js.billing.wizard.choose_plan_btn");

var editRequisites$p = Utils.Translations.tr("js.billing.wizard.confirm.edit_requisites");

var createInvoice$p = Utils.Translations.tr("js.billing.wizard.confirm.create_invoice");

var connect$p = Utils.Translations.tr("js.billing.wizard.confirm.connect");

var applyNewPlan$p = Utils.Translations.tr("js.billing.wizard.confirm.apply_new_plan");

var surcharge$p = Utils.Translations.txr("js.billing.wizard.confirm.surcharge");

var surchargeInvoice$p = Utils.Translations.tr("js.billing.wizard.confirm.surcharge_invoice");

var available$p = Utils.Translations.txr("js.billing.wizard.available");

var payPaddle$p = Utils.Translations.tr("js.billing.wizard.confirm.paddle");

var conctactSupport$p = Utils.Translations.tr("js.billing.wizard.contact_support");

function date$p(v, w) {
  return Utils.Translations.dateFromDate(Utils.Translations.dateFromIsoStr(v), w);
}

var styles = BillingModuleCss;

function Billing_Wizard_Page(Props) {
  var data = Props.data;
  var updateData = Props.updateData;
  var reload = Props.reload;
  var onComplete = Props.onComplete;
  var windowedOpt = Props.windowed;
  var windowed = windowedOpt !== undefined ? windowedOpt : false;
  var updatePaymentMethod = function (paymentMethod) {
    updateData(function (data) {
          var init = data.order;
          return {
                  availablePlans: data.availablePlans,
                  planTypes: data.planTypes,
                  subscriptionOrder: data.subscriptionOrder,
                  subscriptionPlan: data.subscriptionPlan,
                  subscription: data.subscription,
                  order: {
                    planTypeId: init.planTypeId,
                    paidSeats: init.paidSeats,
                    freeSeats: init.freeSeats,
                    monthsCycle: init.monthsCycle,
                    organizationName: init.organizationName,
                    billingAddress: init.billingAddress,
                    paymentMethod: paymentMethod,
                    currency: init.currency
                  },
                  plan: data.plan,
                  currentSeats: data.currentSeats,
                  enterprise: data.enterprise,
                  free: data.free,
                  currentPlanType: data.currentPlanType,
                  unpaidBill: data.unpaidBill,
                  latestPayments: data.latestPayments,
                  canChangePlan: data.canChangePlan,
                  card: data.card,
                  availablePaymentMethods: data.availablePaymentMethods,
                  cryptoChillSettings: data.cryptoChillSettings,
                  paddleSettings: data.paddleSettings,
                  paddleSubscription: data.paddleSubscription
                };
        });
  };
  var wire = AppContext.useWire();
  var footer = wire.footer;
  var ctx = wire.ctx;
  var selectPlan = function (available, types, order) {
    var plan = RCore.$$Array.getBy(available, (function (p) {
            if (Types.Id.equal(p.typeId, order.planTypeId)) {
              return p.monthsCycle === order.monthsCycle;
            } else {
              return false;
            }
          }));
    var planType = RCore.$$Array.getBy(types, (function (t) {
            return Types.Id.equal(t.id, order.planTypeId);
          }));
    return [
            plan,
            planType
          ];
  };
  var isValid = function (currentSeats, order, subscriptionOrder, plan, param) {
    var seatsLimit = param.features.seatsLimit;
    var match = RCore.$$Option.map(plan, (function (param) {
            return param.priceScheme;
          }));
    var match$1 = ctx.trial;
    var exit = 0;
    var exit$1 = 0;
    if (match !== undefined) {
      if (typeof match !== "object") {
        return true;
      }
      switch (match.TAG) {
        case "Flat" :
            if (seatsLimit !== undefined) {
              if (match$1 !== undefined) {
                if (match._0.amount === 0.0) {
                  if (match$1.expired) {
                    return currentSeats <= seatsLimit;
                  } else {
                    return false;
                  }
                }
                exit$1 = 3;
              } else {
                exit$1 = 3;
              }
            } else {
              exit = 2;
            }
            break;
        case "Quoted" :
        case "QuotedWithThreshold" :
            exit$1 = 3;
            break;
        
      }
    } else {
      exit$1 = 3;
    }
    if (exit$1 === 3) {
      if (seatsLimit !== undefined) {
        if (seatsLimit < currentSeats) {
          return false;
        }
        exit = 2;
      } else {
        exit = 2;
      }
    }
    if (exit === 2 && subscriptionOrder !== undefined && Caml_obj.equal(subscriptionOrder, order)) {
      return false;
    }
    return currentSeats <= (order.paidSeats + order.freeSeats | 0);
  };
  var match = Hooks.useLocked(RCore.$$Option.isSome(data.subscriptionOrder) || data.currentSeats > (data.order.paidSeats + data.order.freeSeats | 0));
  var unlock = match[2];
  var lock = match[1];
  var locked = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setCryptoLoaded = match$1[1];
  var cryptoLoaded = match$1[0];
  var updatePaddleSubscription = function (planUuid, subscriptionId, seats) {
    Backend.finallyOnSuccess(Billing_API.Subscription.updatePaddleSubscription(wire, planUuid, subscriptionId, seats), (function () {
            Router.redirect(Route.pagePath(undefined, "PaddlePaymentSuccess"));
          }));
  };
  var updateOrder = function (f) {
    var currentSeats = data.currentSeats;
    var availablePlans = data.availablePlans;
    var newOrder = f(data.order);
    var planAndType = selectPlan(availablePlans, data.planTypes, newOrder);
    var plan = planAndType[0];
    if (plan !== undefined) {
      var planType = planAndType[1];
      if (planType === undefined) {
        return ;
      }
      updateData(function (param) {
            return {
                    availablePlans: data.availablePlans,
                    planTypes: data.planTypes,
                    subscriptionOrder: data.subscriptionOrder,
                    subscriptionPlan: data.subscriptionPlan,
                    subscription: data.subscription,
                    order: newOrder,
                    plan: plan,
                    currentSeats: data.currentSeats,
                    enterprise: data.enterprise,
                    free: data.free,
                    currentPlanType: planType,
                    unpaidBill: data.unpaidBill,
                    latestPayments: data.latestPayments,
                    canChangePlan: data.canChangePlan,
                    card: data.card,
                    availablePaymentMethods: data.availablePaymentMethods,
                    cryptoChillSettings: data.cryptoChillSettings,
                    paddleSettings: data.paddleSettings,
                    paddleSubscription: data.paddleSubscription
                  };
          });
      var valid = isValid(currentSeats, newOrder, data.subscriptionOrder, plan, planType);
      if (valid) {
        return unlock();
      } else {
        return lock();
      }
    }
    var planType$1 = planAndType[1];
    if (planType$1 === undefined) {
      return ;
    }
    var plan$1 = Js_array.find((function (p) {
            return Types.Id.equal(p.typeId, planType$1.id);
          }), availablePlans);
    var monthsCycle = RCore.$$Option.getOr(RCore.$$Option.map(plan$1, (function (p) {
                return p.monthsCycle;
              })), newOrder.monthsCycle);
    var correctedOrder_planTypeId = newOrder.planTypeId;
    var correctedOrder_paidSeats = newOrder.paidSeats;
    var correctedOrder_freeSeats = newOrder.freeSeats;
    var correctedOrder_organizationName = newOrder.organizationName;
    var correctedOrder_billingAddress = newOrder.billingAddress;
    var correctedOrder_paymentMethod = newOrder.paymentMethod;
    var correctedOrder_currency = newOrder.currency;
    var correctedOrder = {
      planTypeId: correctedOrder_planTypeId,
      paidSeats: correctedOrder_paidSeats,
      freeSeats: correctedOrder_freeSeats,
      monthsCycle: monthsCycle,
      organizationName: correctedOrder_organizationName,
      billingAddress: correctedOrder_billingAddress,
      paymentMethod: correctedOrder_paymentMethod,
      currency: correctedOrder_currency
    };
    updateData(function (param) {
          return {
                  availablePlans: data.availablePlans,
                  planTypes: data.planTypes,
                  subscriptionOrder: data.subscriptionOrder,
                  subscriptionPlan: data.subscriptionPlan,
                  subscription: data.subscription,
                  order: correctedOrder,
                  plan: plan$1,
                  currentSeats: data.currentSeats,
                  enterprise: data.enterprise,
                  free: data.free,
                  currentPlanType: planType$1,
                  unpaidBill: data.unpaidBill,
                  latestPayments: data.latestPayments,
                  canChangePlan: data.canChangePlan,
                  card: data.card,
                  availablePaymentMethods: data.availablePaymentMethods,
                  cryptoChillSettings: data.cryptoChillSettings,
                  paddleSettings: data.paddleSettings,
                  paddleSubscription: data.paddleSubscription
                };
        });
    var valid$1 = isValid(currentSeats, correctedOrder, data.subscriptionOrder, plan$1, planType$1);
    if (valid$1) {
      return unlock();
    } else {
      return lock();
    }
  };
  var match$2 = data.subscriptionOrder;
  var previewOrder = match$2 !== undefined ? Billing_API.Order.preview(wire) : Billing_API.Order.create(wire);
  var subscriptionUuid = RCore.$$Option.map(data.subscription, (function (param) {
          return param.uuid;
        }));
  var match$3 = React.useState(function () {
        return subscriptionUuid;
      });
  var currentUuid = match$3[0];
  var match$4 = React.useState(function () {
        return false;
      });
  var setPriceRequested = match$4[1];
  var priceRequested = match$4[0];
  var match$5 = React.useState(function () {
        return "ChoosePlan";
      });
  var setStep = match$5[1];
  var step = match$5[0];
  var changeStep = function (step) {
    if (!windowed) {
      window.scrollTo(Units.Px.toFloat(Units.Px.zero), Units.Px.toFloat(Units.Px.zero));
    }
    setStep(function (param) {
          return step;
        });
  };
  var match$6 = React.useState(function () {
        
      });
  var setBill = match$6[1];
  var bill = match$6[0];
  var match$7 = React.useState(function () {
        
      });
  var setCheckoutUrl = match$7[1];
  var checkoutUrl = match$7[0];
  var match$8 = React.useState(function () {
        
      });
  var setChargeError = match$8[1];
  var chargeError = match$8[0];
  var match$9 = React.useState(function () {
        return false;
      });
  var setShowPayOptions = match$9[1];
  var priceRequestSubscription = React.useMemo((function () {
          return Subscription.make();
        }), []);
  var changed = currentUuid !== undefined && subscriptionUuid !== undefined ? !Uuid.equal(Caml_option.valFromOption(currentUuid), Caml_option.valFromOption(subscriptionUuid)) : false;
  if (changed) {
    match$3[1](function (param) {
          return subscriptionUuid;
        });
  }
  var allowedToChangePlan = !Context_Types.Features.hasFlag("disablePlanChange", wire.ctx.features);
  var subscribed = function (response) {
    if (response.TAG === "Ok") {
      return onComplete();
    }
    var message = response._0;
    if (typeof message !== "object") {
      return unlock();
    }
    if (message.TAG !== "PaymentError") {
      return unlock();
    }
    var message$1 = message._0;
    setChargeError(function (param) {
          return message$1;
        });
    unlock();
  };
  var orderResponse = function (response) {
    unlock();
    if (response.TAG !== "Ok") {
      return ;
    }
    var match = response._0;
    var checkoutUrl = match.checkoutUrl;
    var bill = match.bill;
    setBill(function (param) {
          return bill;
        });
    setCheckoutUrl(function (param) {
          return checkoutUrl;
        });
    changeStep("Confirm");
  };
  var confirm = function (fakeResult, param) {
    lock();
    Backend.$$finally(Billing_API.Subscription.create(fakeResult, wire, data.order), subscribed);
  };
  var $$continue = function () {
    switch (step) {
      case "ChoosePlan" :
          if (!windowed) {
            return changeStep("ChoosePaymentMethod");
          }
          break;
      case "ChoosePaymentMethod" :
          break;
      case "Confirm" :
          return confirm(undefined, undefined);
      
    }
    lock();
    setChargeError(function (param) {
          
        });
    Backend.$$finally(previewOrder(data.order), orderResponse);
  };
  var back = function (evt) {
    evt.preventDefault();
    switch (step) {
      case "ChoosePlan" :
          return ;
      case "ChoosePaymentMethod" :
          return changeStep("ChoosePlan");
      case "Confirm" :
          if (windowed) {
            return changeStep("ChoosePlan");
          } else {
            return changeStep("ChoosePaymentMethod");
          }
      
    }
  };
  var onSubmit = function (evt) {
    evt.preventDefault();
    if (Context.isDev(ctx)) {
      return confirm("Success", undefined);
    } else {
      return $$continue();
    }
  };
  var btnGroupClass = El.Cn.concat(El.Cn.concat("btn-group", styles.continueButton), match$9[0] ? "open" : "");
  var payButtonOptions = function () {
    if (Context.isDev(ctx)) {
      return React.createElement(DropDownMenu.make, {
                  button: (function (_ref) {
                      return React.createElement("button", {
                                  ref: _ref,
                                  className: "btn btn-primary dropdown-toggle",
                                  type: "button",
                                  onClick: (function (param) {
                                      setShowPayOptions(function (prev) {
                                            return !prev;
                                          });
                                    })
                                }, React.createElement("span", {
                                      className: "caret"
                                    }));
                    }),
                  children: React.createElement("li", undefined, React.createElement("a", {
                            href: "",
                            onClick: (function (evt) {
                                evt.preventDefault();
                                setShowPayOptions(function (prev) {
                                      return !prev;
                                    });
                                confirm("InsufficientFunds", undefined);
                              })
                          }, "Insufficient funds"))
                });
    } else {
      return null;
    }
  };
  switch (step) {
    case "ChoosePlan" :
        var order = data.order;
        var isChosen = function (plan) {
          return RCore.$$Option.getOr(RCore.$$Option.map(plan, (function (e) {
                            return Types.Id.equal(e.id, order.planTypeId);
                          })), false);
        };
        var enterpriseChosen = isChosen(data.enterprise);
        var freeChosen = isChosen(data.free);
        var containerClass = El.Cn.concat(El.Cn.concat(El.Cn.concat(El.Cn.concat(El.Cn.fromRecord({
                              "p-modal-body": windowed,
                              "form-row row": true
                            }), freeChosen ? styles.free : ""), windowed ? styles.windowed : ""), styles.billing), styles.billingChoosePlan);
        var content = ctx.licenseSource === "external" ? "Subscription is not yet activated" : React.createElement(Billing_Wizard_ChoosePlan.make, {
                order: order,
                windowed: windowed,
                plan: data.plan,
                currentPlanType: data.currentPlanType,
                onOrderUpdate: (function (order) {
                    updateOrder(function (param) {
                          return order;
                        });
                  }),
                planTypes: data.planTypes,
                availablePlans: data.availablePlans,
                priceRequest: priceRequestSubscription,
                onPriceRequested: (function () {
                    setPriceRequested(function (param) {
                          return true;
                        });
                    unlock();
                  }),
                currentSeats: data.currentSeats,
                subscriptionPlan: data.subscriptionPlan,
                wire: wire
              });
        var next;
        if (!enterpriseChosen || !priceRequested) {
          var submitClass = El.Cn.concat(El.Cn.fromRecord({
                    "p-modal-btn-submit": windowed,
                    "btn btn-primary paid": true
                  }), windowed ? styles.continueButton : "");
          var match$10;
          if (enterpriseChosen) {
            var action = function () {
              lock();
              priceRequestSubscription.trigger();
            };
            match$10 = [
              action,
              requestPrice$p
            ];
          } else if (freeChosen) {
            var match$11 = ctx.trial;
            var text;
            var exit = 0;
            if (match$11 !== undefined) {
              var expiresAt = match$11.expiresAt;
              if (expiresAt !== undefined && !match$11.expired) {
                text = (function (__x) {
                    return available$p({
                                at: date$p(expiresAt, wire)
                              }, __x);
                  });
              } else {
                exit = 1;
              }
            } else {
              exit = 1;
            }
            if (exit === 1) {
              text = RCore.$$Option.isSome(subscriptionUuid) ? save$p : RCore.$$Option.mapWithDefault(data.subscriptionOrder, save$p, (function (param) {
                        return saved$p;
                      }));
            }
            var action$1 = function () {
              lock();
              Backend.$$finally(Billing_API.Subscription.create(undefined, wire, order), subscribed);
            };
            match$10 = [
              action$1,
              text
            ];
          } else {
            match$10 = [
              (function () {
                  $$continue();
                }),
              continue$p
            ];
          }
          var action$2 = match$10[0];
          next = React.createElement("button", {
                className: submitClass,
                disabled: locked,
                onClick: (function (evt) {
                    evt.preventDefault();
                    if (allowedToChangePlan) {
                      return action$2();
                    } else {
                      return wire.subscriptions.messenger.trigger({
                                  TAG: "Show",
                                  _0: MessengerTypes.Message.make(undefined, "Warning", {
                                        TAG: "Text",
                                        _0: "Please contact support to change subscription"
                                      })
                                });
                    }
                  })
              }, match$10[1](ctx));
        } else {
          next = null;
        }
        if (windowed) {
          return React.createElement(Modal.Dialog.make, {
                      className: styles.billingModalDialog,
                      children: null
                    }, React.createElement(Modal.Header.make, {
                          children: React.createElement("h1", undefined, changePlan$p(ctx))
                        }), React.createElement("div", {
                          className: containerClass
                        }, content), !enterpriseChosen || !priceRequested ? React.createElement(Modal.Footer.make, {
                            children: null
                          }, React.createElement(Modal.CloseButton.make, {
                                ctx: ctx
                              }), next) : null);
        } else {
          return React.createElement("div", undefined, React.createElement(Billing_Wizard_Components.PageHeader.make, {
                          ctx: ctx
                        }), React.createElement("div", {
                          className: containerClass
                        }, React.createElement("div", {
                              className: "col-md-12 form-container"
                            }, React.createElement(Billing_Components_Blocks.TrialEnded.make, {
                                  ctx: ctx
                                }), React.createElement(Billing_Components_Blocks.TrialEnds.make, {
                                  wire: wire
                                }), React.createElement(Billing_Components_Blocks.OnFreePlan.make, {
                                  order: data.subscriptionOrder,
                                  ctx: ctx
                                }), React.createElement("div", {
                                  className: styles.wizard
                                }, React.createElement(Billing_Wizard_Components.Steps.make, {
                                      current: "ChoosePlan",
                                      ctx: ctx
                                    }), React.createElement("div", {
                                      className: El.Cn.concat(styles.wizardContent, styles.panelBody)
                                    }, React.createElement("div", undefined, content)), !enterpriseChosen || !priceRequested ? React.createElement("div", {
                                        className: styles.wizardButtons
                                      }, next) : null))));
        }
    case "ChoosePaymentMethod" :
        var plan = data.plan;
        if (plan !== undefined) {
          return React.createElement(Billing_Wizard_PaymentMethods.make, {
                      wire: wire,
                      order: data.order,
                      availablePaymentMethods: data.availablePaymentMethods,
                      plan: plan,
                      goBack: back,
                      onContinue: $$continue,
                      updatePaymentMethod: updatePaymentMethod,
                      onOrderUpdate: updateOrder,
                      locked: locked
                    });
        } else {
          return null;
        }
    case "Confirm" :
        var currentOrder = data.subscriptionOrder;
        if (currentOrder !== undefined) {
          var currentPlan = data.subscriptionPlan;
          if (currentPlan !== undefined) {
            var plan$1 = data.plan;
            if (plan$1 === undefined) {
              return null;
            }
            if (windowed) {
              var order$1 = data.order;
              var match$12 = order$1.paymentMethod;
              var tmp;
              switch (match$12) {
                case "Invoice" :
                case "Card" :
                    tmp = null;
                    break;
                case "Crypto" :
                    tmp = React.createElement(Script.make, {
                          src: "https://static.cryptochill.com/static/js/sdk.js",
                          root: footer,
                          onload: (function (param) {
                              setCryptoLoaded(function (param) {
                                    return true;
                                  });
                            })
                        });
                    break;
                
              }
              var tmp$1;
              if (bill !== undefined) {
                var match$13 = order$1.paymentMethod;
                switch (match$13) {
                  case "Invoice" :
                      tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("button", {
                                className: "p-modal-btn-submit p-modal-btn btn btn-primary",
                                onClick: onSubmit
                              }, React.createElement("span", undefined, createInvoice$p(ctx), El.space, Billing_Utils.Format.money$p(bill.totalAmount))), payButtonOptions());
                      break;
                  case "Card" :
                      var match$14 = Fun.both(RCore.$$Option.flatMap(data.plan, (function (p) {
                                  return p.paddlePlanId;
                                })), data.paddleSubscription);
                      if (match$14 !== undefined) {
                        var subscriptionId = match$14[1].subscriptionId;
                        tmp$1 = React.createElement(Billing_Components_Paddle.UpdatePlan.make, {
                              onClick: (function (param) {
                                  updatePaddleSubscription(plan$1.uuid, subscriptionId, order$1.paidSeats);
                                })
                            });
                      } else {
                        tmp$1 = conctactSupport$p(ctx);
                      }
                      break;
                  case "Crypto" :
                      if (cryptoLoaded) {
                        var settings = data.cryptoChillSettings;
                        tmp$1 = settings !== undefined ? React.createElement(Billing_Components_Crypto.make, {
                                bill: bill,
                                order: order$1,
                                settings: settings,
                                reload: reload
                              }) : conctactSupport$p(ctx);
                      } else {
                        tmp$1 = React.createElement(Spinner.make, {});
                      }
                      break;
                  
                }
              } else {
                var match$15 = order$1.paymentMethod;
                var exit$1 = 0;
                switch (match$15) {
                  case "Card" :
                      var match$16 = Fun.both(RCore.$$Option.flatMap(data.plan, (function (p) {
                                  return p.paddlePlanId;
                                })), data.paddleSubscription);
                      if (match$16 !== undefined) {
                        var subscriptionId$1 = match$16[1].subscriptionId;
                        tmp$1 = React.createElement(Billing_Components_Paddle.UpdatePlan.make, {
                              onClick: (function (param) {
                                  updatePaddleSubscription(plan$1.uuid, subscriptionId$1, order$1.paidSeats);
                                })
                            });
                      } else {
                        tmp$1 = conctactSupport$p(ctx);
                      }
                      break;
                  case "Invoice" :
                  case "Crypto" :
                      exit$1 = 2;
                      break;
                  
                }
                if (exit$1 === 2) {
                  tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("button", {
                            className: "p-modal-btn-submit p-modal-btn btn btn-primary",
                            onClick: onSubmit
                          }, applyNewPlan$p(ctx)), payButtonOptions());
                }
                
              }
              return React.createElement(Modal.Dialog.make, {
                          className: styles.billingModalDialog,
                          children: null
                        }, tmp, React.createElement(Modal.Header.make, {
                              children: React.createElement("h1", undefined, changePlan$p(ctx))
                            }), React.createElement("div", {
                              className: El.Cn.concat(El.Cn.concat(El.Cn.concat(styles.billingContainer, styles.billing), "form-row row"), styles.windowed)
                            }, chargeError !== undefined ? React.createElement("div", {
                                    className: "alert alert-danger"
                                  }, chargeError) : null, React.createElement(Billing_Components_Blocks.Order.make, {
                                  current: true,
                                  order: currentOrder,
                                  wire: wire,
                                  plan: currentPlan
                                }), React.createElement(Billing_Components_Blocks.Order.make, {
                                  startsAt: new Date(),
                                  order: order$1,
                                  wire: wire,
                                  plan: plan$1
                                }), bill !== undefined ? React.createElement("div", {
                                    className: "row"
                                  }, React.createElement("div", {
                                        className: "col-md-12"
                                      }, surcharge$p({
                                            start: date$p(bill.periodStart, wire),
                                            end: date$p(bill.periodEnd, wire)
                                          }, ctx), El.space, React.createElement("strong", undefined, Billing_Utils.Format.money$p(bill.totalAmount)), El.space, surchargeInvoice$p(ctx))) : null), React.createElement(Modal.Footer.make, {
                              children: null
                            }, React.createElement("button", {
                                  className: "p-modal-btn-back p-modal-btn btn back",
                                  onClick: back
                                }, React.createElement("i", {
                                      className: Icon.style(undefined, undefined, "chevron-left")
                                    }), El.space, choosePlanBtn$p(ctx)), React.createElement(Modal.CloseButton.make, {
                                  ctx: ctx
                                }), React.createElement("div", {
                                  className: btnGroupClass
                                }, tmp$1)));
            }
            
          }
          
        }
        var plan$2 = data.plan;
        if (plan$2 === undefined) {
          return null;
        }
        var order$2 = data.order;
        var match$17 = order$2.paymentMethod;
        var tmp$2;
        switch (match$17) {
          case "Invoice" :
          case "Card" :
              tmp$2 = null;
              break;
          case "Crypto" :
              tmp$2 = React.createElement(Script.make, {
                    src: "https://static.cryptochill.com/static/js/sdk.js",
                    root: footer,
                    onload: (function (param) {
                        setCryptoLoaded(function (param) {
                              return true;
                            });
                      })
                  });
              break;
          
        }
        var match$18 = order$2.paymentMethod;
        var tmp$3;
        switch (match$18) {
          case "Invoice" :
              tmp$3 = React.createElement(React.Fragment, undefined, React.createElement("button", {
                        className: "btn btn-primary",
                        onClick: onSubmit
                      }, bill !== undefined ? React.createElement(React.Fragment, undefined, createInvoice$p(ctx), El.space, Billing_Utils.Format.money$p(bill.totalAmount)) : connect$p(ctx)), payButtonOptions());
              break;
          case "Card" :
              var match$19 = RCore.$$Option.flatMap(data.plan, (function (p) {
                      return p.paddlePlanId;
                    }));
              if (match$19 !== undefined && checkoutUrl !== undefined) {
                var checkoutUrl$1 = Caml_option.valFromOption(checkoutUrl);
                tmp$3 = React.createElement("button", {
                      className: "btn btn-primary",
                      onClick: (function (param) {
                          Router.redirect(Url.href(checkoutUrl$1));
                        })
                    }, payPaddle$p(ctx));
              } else {
                tmp$3 = conctactSupport$p(ctx);
              }
              break;
          case "Crypto" :
              if (cryptoLoaded) {
                if (bill !== undefined) {
                  var settings$1 = data.cryptoChillSettings;
                  tmp$3 = settings$1 !== undefined ? React.createElement(Billing_Components_Crypto.make, {
                          bill: bill,
                          order: order$2,
                          settings: settings$1,
                          reload: reload
                        }) : conctactSupport$p(ctx);
                } else {
                  tmp$3 = React.createElement("button", {
                        className: "btn btn-primary",
                        onClick: onSubmit
                      }, connect$p(ctx));
                }
              } else {
                tmp$3 = React.createElement(Spinner.make, {});
              }
              break;
          
        }
        return React.createElement("div", undefined, tmp$2, React.createElement(Billing_Wizard_Components.PageHeader.make, {
                        ctx: ctx
                      }), React.createElement("div", {
                        className: "form-row row " + styles.billing
                      }, React.createElement("div", {
                            className: "col-md-12 form-container"
                          }, React.createElement(Billing_Components_Blocks.TrialEnded.make, {
                                ctx: ctx
                              }), React.createElement(Billing_Components_Blocks.TrialEnds.make, {
                                wire: wire
                              }), React.createElement("div", {
                                className: styles.wizard
                              }, React.createElement(Billing_Wizard_Components.Steps.make, {
                                    current: "Confirm",
                                    ctx: ctx
                                  }), React.createElement("div", {
                                    className: El.Cn.concat(El.Cn.concat(styles.billingContainer, styles.wizardContent), styles.panelBody)
                                  }, React.createElement("div", undefined, React.createElement(Billing_Components_Blocks.Order.make, {
                                            order: order$2,
                                            wire: wire,
                                            plan: plan$2
                                          }), React.createElement(Billing_Components_Blocks.PaymentMethod.make, {
                                            order: order$2,
                                            wire: wire,
                                            paddleSubscription: data.paddleSubscription,
                                            paddleSettings: data.paddleSettings
                                          }), bill !== undefined ? React.createElement(Billing_Wizard_Components.Confirmation.make, {
                                              bill: bill,
                                              paymentMethod: order$2.paymentMethod,
                                              wire: wire
                                            }) : null))), React.createElement("div", {
                                className: styles.wizardButtons
                              }, React.createElement("button", {
                                    className: "btn",
                                    onClick: back
                                  }, React.createElement("i", {
                                        className: Icon.style(undefined, undefined, "chevron-left")
                                      }), El.space, editRequisites$p(ctx)), React.createElement("div", {
                                    className: btnGroupClass
                                  }, tmp$3)))));
        break;
    
  }
}

var Types$1;

var make = Billing_Wizard_Page;

export {
  Types$1 as Types,
  make ,
}
/* changePlan' Not a pure module */
