

import * as El from "../../../../libraries/El.mjs";
import * as Fun from "../../../../libraries/Fun.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as React from "react";
import * as UserTypes from "../UserTypes.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LimitedInput from "../../../inputs/LimitedInput.mjs";
import * as UserShowModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/user/UserShow.module.css";

var styles = UserShowModuleCss;

function UserFormInput(Props) {
  var id = Props.id;
  var value = Props.value;
  var onChange = Props.onChange;
  var type_ = Props.type_;
  var error = Props.error;
  var label = Props.label;
  var disabledOpt = Props.disabled;
  var maxLength = Props.maxLength;
  var limitedOpt = Props.limited;
  var containerClass = Props.containerClass;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var limited = limitedOpt !== undefined ? limitedOpt : false;
  var containerCN = El.Cn.concatOpt("form-group form-input", RCore.$$Option.map(error, (function (param) {
              return "has-error";
            })));
  var tmp;
  if (limited && RCore.$$Option.isSome(maxLength)) {
    tmp = React.createElement(LimitedInput.make, {
          maxLength: RCore.$$Option.getExn(maxLength, undefined),
          onValueChange: (function (v) {
              onChange(Fun.optString(v));
            }),
          value: value,
          id: id,
          type_: type_,
          className: "form-control",
          disabled: disabled
        });
  } else {
    var tmp$1 = {
      className: "form-control",
      id: id,
      disabled: disabled,
      type: type_,
      value: value,
      onChange: (function (evt) {
          var value = evt.target.value;
          onChange(Fun.optString(value));
        })
    };
    if (maxLength !== undefined) {
      tmp$1.maxLength = Caml_option.valFromOption(maxLength);
    }
    tmp = React.createElement("input", tmp$1);
  }
  return React.createElement("div", {
              className: El.Cn.concatOpt(containerCN, containerClass)
            }, React.createElement("div", {
                  className: styles.inputRow
                }, React.createElement("div", {
                      className: styles.inputLabel + " control-label"
                    }, React.createElement("label", {
                          htmlFor: id
                        }, label)), React.createElement("div", {
                      className: styles.inputText
                    }, tmp, error !== undefined ? React.createElement("p", {
                            className: "help-block",
                            dangerouslySetInnerHTML: {
                              __html: UserTypes.$$Error.errorText(error)
                            }
                          }) : null)));
}

var make = UserFormInput;

export {
  make ,
}
/* styles Not a pure module */
