

import * as Fun from "../../../libraries/Fun.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../common/Optional.mjs";
import * as StringGeolocationParser from "../../../types/StringGeolocationParser.mjs";
import * as AddressInput_SuggestionsBlockModuleCss from "/home/runner/work/planado/planado/client/rescript/components/address_input/suggestions_block/AddressInput_SuggestionsBlock.module.css";

var chooseOptionOrContinueTyping$p = Utils.Translations.t("js.address_input.input_hint");

var unknownAddress$p = Utils.Translations.t("js.address_input.unknown_address");

var styles = AddressInput_SuggestionsBlockModuleCss;

function inputLongerThanMinimum(input) {
  return input.length >= 3;
}

function renderSuggestions(wire, response, focusedEl, onSelect) {
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: styles.suggestionsHint
                }, chooseOptionOrContinueTyping$p(wire.ctx)), response.map(function (item, index) {
                  var identificator = index.toString();
                  var match;
                  match = typeof focusedEl !== "object" || focusedEl._0 !== index ? [
                      styles.suggestionsSuggestion,
                      styles.suggestionsText
                    ] : [
                      styles.suggestionsSuggestion + " " + styles.highlightedSuggestion,
                      styles.suggestionsText + " " + styles.highlightedText
                    ];
                  return React.createElement("div", {
                              key: identificator,
                              className: match[0],
                              role: "option"
                            }, React.createElement("span", {
                                  key: identificator,
                                  className: match[1],
                                  onClick: onSelect(item)
                                }, item.value));
                }));
}

function needToDisplayError(address, response, constraints) {
  if (address.TAG === "Regular") {
    if (constraints !== undefined && response.length === 0) {
      var query = address._0;
      if (StringGeolocationParser.isGeolocationString(query.formatted)) {
        return inputLongerThanMinimum(query.formatted);
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return response.length === 0;
  }
}

function needToDisplaySuggestions(address, response) {
  if (address.TAG !== "Regular") {
    if (response.length > 0) {
      return inputLongerThanMinimum(address._0.formatted);
    } else {
      return false;
    }
  }
  var formatted = address._0.formatted;
  if (response.length > 0 && !StringGeolocationParser.isGeolocationString(formatted)) {
    return inputLongerThanMinimum(formatted);
  } else {
    return false;
  }
}

function AddressInput_SuggestionsBlock$Suggestions(Props) {
  var wire = Props.wire;
  var address = Props.address;
  var response = Props.response;
  var focused = Props.focused;
  var constraints = Props.constraints;
  var onSelect = Props.onSelect;
  var match = Fun.both(focused, response);
  if (match === undefined) {
    return null;
  }
  var response$1 = match[1];
  return React.createElement(React.Fragment, undefined, React.createElement(Optional.make, {
                  show: needToDisplayError(address, response$1, constraints),
                  children: React.createElement("div", {
                        className: styles.suggestionsHint
                      }, unknownAddress$p(wire.ctx))
                }), React.createElement(Optional.make, {
                  show: needToDisplaySuggestions(address, response$1),
                  children: renderSuggestions(wire, response$1, match[0], onSelect)
                }));
}

function AddressInput_SuggestionsBlock$SuggestionsBlock(Props) {
  var wire = Props.wire;
  var address = Props.address;
  var response = Props.response;
  var focused = Props.focused;
  var constraints = Props.constraints;
  var onSelect = Props.onSelect;
  return React.createElement("div", {
              className: styles.suggestionsWrapper
            }, React.createElement("span", {
                  className: styles.suggestionsAddon
                }), React.createElement("div", {
                  className: styles.suggestionsSuggestions
                }, React.createElement(AddressInput_SuggestionsBlock$Suggestions, {
                      wire: wire,
                      address: address,
                      response: response,
                      focused: focused,
                      constraints: constraints,
                      onSelect: onSelect
                    })));
}

var SuggestionsBlock = {
  make: AddressInput_SuggestionsBlock$SuggestionsBlock
};

export {
  inputLongerThanMinimum ,
  SuggestionsBlock ,
}
/* chooseOptionOrContinueTyping' Not a pure module */
