

import * as Note from "../../common/Note/Note.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as AppContext from "../../../context/AppContext.mjs";

var pending$p = Utils.Translations.th("js.billing.pending");

function Billing_Pending(Props) {
  var $$fetch = Props.fetch;
  var match = AppContext.useWire();
  React.useEffect((function () {
          var intervalId = setInterval((function () {
                  $$fetch(true);
                }), 2000);
          return (function () {
                    clearInterval(intervalId);
                  });
        }), []);
  return React.createElement(Note.make, {
              children: pending$p(match.ctx),
              flavor: "warning"
            });
}

var make = Billing_Pending;

export {
  make ,
}
/* pending' Not a pure module */
