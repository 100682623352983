

import * as RCore from "../../../libraries/RCore.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as AmoCrmTypes from "./AmoCrmTypes.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var decode = JsonDecode.object(function (field) {
      var error = field.required("error", JsonDecode.option(JsonDecode.string));
      var templates = field.required("templates", JsonDecode.array(AmoCrmTypes.Template.decode));
      var credentials = field.required("credentials", JsonDecode.option(AmoCrmTypes.Credentials.decode));
      var account = field.required("account", JsonDecode.option(AmoCrmTypes.Account.decode));
      if (account === undefined) {
        return {
                credentials: credentials,
                configuration: undefined,
                templates: templates,
                error: error
              };
      }
      var settings = field.required("settings", AmoCrmTypes.Settings.decode);
      var configuration = AmoCrmTypes.Configuration.make(account, settings);
      return {
              credentials: credentials,
              configuration: configuration,
              templates: templates,
              error: error
            };
    });

function make(address, userEmail, userHash) {
  return {
          address: address,
          userEmail: userEmail,
          userHash: userHash
        };
}

function mapJobField(jobField) {
  switch (jobField.TAG) {
    case "Predefined" :
        return {
                code: AmoCrmTypes.JobField.Predefined.toString(jobField._0),
                customFieldUuid: undefined,
                reportFieldUuid: undefined
              };
    case "Custom" :
        return {
                code: undefined,
                customFieldUuid: jobField._0,
                reportFieldUuid: undefined
              };
    case "Report" :
        return {
                code: undefined,
                customFieldUuid: undefined,
                reportFieldUuid: jobField._0
              };
    
  }
}

function mapLeadField(leadField) {
  if (leadField.TAG === "PredefinedField") {
    return {
            leadCustomFieldId: undefined,
            contactCustomFieldId: undefined,
            fieldType: undefined,
            predefinedLeadField: AmoCrmTypes.Predefined.Lead.toString(leadField._0),
            predefinedContactField: undefined,
            multiple: undefined
          };
  } else {
    return {
            leadCustomFieldId: leadField._0,
            contactCustomFieldId: undefined,
            fieldType: AmoCrmTypes.Field.DataType.toString(leadField._1),
            predefinedLeadField: undefined,
            predefinedContactField: undefined,
            multiple: leadField._2
          };
  }
}

function mapContactField(contactField) {
  return {
          leadCustomFieldId: undefined,
          contactCustomFieldId: contactField._0,
          fieldType: AmoCrmTypes.Field.DataType.toString(contactField._1),
          predefinedLeadField: undefined,
          predefinedContactField: undefined,
          multiple: contactField._2
        };
}

function mapPredefinedLeadField(f) {
  return {
          leadCustomFieldId: undefined,
          contactCustomFieldId: undefined,
          fieldType: undefined,
          predefinedLeadField: AmoCrmTypes.Predefined.Lead.toString(f),
          predefinedContactField: undefined,
          multiple: undefined
        };
}

function mapPredefinedContactField(f) {
  return {
          leadCustomFieldId: undefined,
          contactCustomFieldId: undefined,
          fieldType: undefined,
          predefinedLeadField: undefined,
          predefinedContactField: AmoCrmTypes.Predefined.Contact.toString(f),
          multiple: undefined
        };
}

function make$1(param) {
  return {
          accountId: param.account.id,
          pipelines: param.settings.pipelines.map(function (param) {
                return {
                        pipelineId: param.id,
                        statuses: RCore.$$Array.filterMap(param.statuses, (function (param) {
                                var statusIdOnFailure = param.statusIdOnFailure;
                                var statusIdOnSuccess = param.statusIdOnSuccess;
                                var templateUuid = param.templateUuid;
                                if (templateUuid !== undefined && statusIdOnSuccess !== undefined && statusIdOnFailure !== undefined) {
                                  return {
                                          uuid: param.uuid,
                                          configured: param.configured,
                                          statusId: param.statusId,
                                          statusIdOnSuccess: Caml_option.valFromOption(statusIdOnSuccess),
                                          statusIdOnFailure: Caml_option.valFromOption(statusIdOnFailure),
                                          templateUuid: Caml_option.valFromOption(templateUuid),
                                          jobFields: RCore.$$Array.filterMap(param.jobFields, (function (mapping) {
                                                    var jobField = mapping.jobField;
                                                    if (jobField === undefined) {
                                                      return ;
                                                    }
                                                    var amocrmField = mapping.amocrmField;
                                                    if (amocrmField !== undefined) {
                                                      return [
                                                              jobField,
                                                              amocrmField
                                                            ];
                                                    }
                                                    
                                                  })).map(function (param) {
                                                var amocrmField = param[1];
                                                var tmp;
                                                switch (amocrmField.TAG) {
                                                  case "PredefinedLead" :
                                                      tmp = mapPredefinedLeadField(amocrmField._0);
                                                      break;
                                                  case "PredefinedContact" :
                                                      tmp = mapPredefinedContactField(amocrmField._0);
                                                      break;
                                                  case "LeadField" :
                                                      tmp = mapLeadField(amocrmField._0);
                                                      break;
                                                  case "ContactField" :
                                                      tmp = mapContactField(amocrmField._0);
                                                      break;
                                                  
                                                }
                                                return {
                                                        jobField: mapJobField(param[0]),
                                                        amocrmField: tmp
                                                      };
                                              }),
                                          leadFields: RCore.$$Array.filterMap(param.leadFields, (function (mapping) {
                                                    var leadField = mapping.leadField;
                                                    if (leadField === undefined) {
                                                      return ;
                                                    }
                                                    var planadoField = mapping.planadoField;
                                                    if (planadoField !== undefined) {
                                                      return [
                                                              leadField,
                                                              planadoField
                                                            ];
                                                    }
                                                    
                                                  })).map(function (param) {
                                                return {
                                                        leadField: mapLeadField(param[0]),
                                                        planadoField: mapJobField(param[1])
                                                      };
                                              })
                                        };
                                }
                                
                              }))
                      };
              })
        };
}

var decode$1 = JsonDecode.object(function (field) {
      return {
              message: field.required("message", JsonDecode.string),
              redirect: field.required("redirect", JsonDecode.option(JsonDecode.string))
            };
    });

function show(wire) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: "amoCRM"
                }), decode);
}

function create(wire, address, userEmail, userHash) {
  return Backend.decode(Backend.post(undefined, undefined, undefined, true, wire, {
                  NAME: "API",
                  VAL: "amoCRM"
                }, {
                  NAME: "Obj",
                  VAL: make(address, userEmail, userHash)
                }), decode);
}

function update(wire, configuration) {
  return Backend.decode(Backend.patch(undefined, undefined, undefined, true, wire, {
                  NAME: "API",
                  VAL: "amoCRM"
                }, {
                  NAME: "Obj",
                  VAL: make$1(configuration)
                }), decode$1);
}

function destroy(wire) {
  return Backend.decode(Backend.$$delete(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: "amoCRM"
                }), JsonDecode.unit);
}

var decode$2 = JsonDecode.object(function (field) {
      var match = field.required("status", JsonDecode.string);
      switch (match) {
        case "complete" :
            return {
                    TAG: "Complete",
                    _0: field.required("redirect", JsonDecode.string)
                  };
        case "pending" :
            return "Pending";
        default:
          return JsonDecode.cannotDecode("Expected 'pending' or 'complete'");
      }
    });

function check(wire, host) {
  return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Integrations",
                    VAL: {
                      NAME: "amoCRM",
                      VAL: {
                        NAME: "Connect",
                        VAL: host
                      }
                    }
                  }
                }), decode$2);
}

var Show_Get = {};

var Show_Post = {};

var Show_Patch = {
  $$Response: {}
};

var Show = {
  Get: Show_Get,
  Post: Show_Post,
  Patch: Show_Patch,
  show: show,
  create: create,
  update: update,
  destroy: destroy
};

var Connect = {
  check: check
};

export {
  Show ,
  Connect ,
}
/* decode Not a pure module */
