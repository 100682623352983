

import * as Js_math from "rescript/lib/es6/js_math.js";

function make() {
  return Js_math.floor(Math.random() * 100000000.0);
}

var Seed = {
  make: make
};

function id($$int, css) {
  return css + "-" + $$int.toString();
}

var $$CSS = {
  id: id
};

export {
  Seed ,
  $$CSS ,
}
/* No side effect */
