

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import CheckboxJsx from "planado/components/input/checkbox.jsx";

function Checkbox(Props) {
  var onToggle = Props.onToggle;
  var checked = Props.checked;
  var label = Props.label;
  var disabled = Props.disabled;
  var name = Props.name;
  var id = Props.id;
  var wrapperClasses = Props.wrapperClasses;
  var inputClasses = Props.inputClasses;
  var checkboxClass = Props.checkboxClass;
  var children = Props.children;
  var tmp = {
    onToggle: (function (param, b) {
        onToggle(b);
      }),
    checked: checked
  };
  if (label !== undefined) {
    tmp.label = label;
  }
  if (disabled !== undefined) {
    tmp.disabled = disabled;
  }
  if (name !== undefined) {
    tmp.name = name;
  }
  if (id !== undefined) {
    tmp.id = id;
  }
  if (children !== undefined) {
    tmp.children = Caml_option.valFromOption(children);
  }
  if (wrapperClasses !== undefined) {
    tmp.wrapperClasses = Caml_option.valFromOption(wrapperClasses);
  }
  if (inputClasses !== undefined) {
    tmp.inputClasses = Caml_option.valFromOption(inputClasses);
  }
  if (checkboxClass !== undefined) {
    tmp.checkboxClass = Caml_option.valFromOption(checkboxClass);
  }
  return React.createElement(CheckboxJsx, tmp);
}

var make = Checkbox;

export {
  make ,
}
/* react Not a pure module */
