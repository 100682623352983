

import IndexJs from "planado/dom/index.js";
import * as ContextJs from "planado/utils/context.js";

function get(wire) {
  return ContextJs.createContext({
              dom: new IndexJs(window),
              wire: wire,
              ctx: wire.ctx
            });
}

function jsContext(prim) {
  return ContextJs.createContext(prim);
}

export {
  jsContext ,
  get ,
}
/* planado/dom/index.js Not a pure module */
