

import * as Url from "../../../bindings/url/Url.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Types from "../../../types/Types.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as Belt_Lib_Id from "../../../libraries/Belt_Lib_Id.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Types_FeatureFlag from "../../../types/Types_FeatureFlag.mjs";

var ISODate = {
  decode: JsonDecode.string
};

function fromString(currency) {
  switch (currency) {
    case "rub" :
        return "rub";
    case "usd" :
        return "usd";
    default:
      return ;
  }
}

var decode = JsonDecode.fromVariant(JsonDecode.string, fromString);

var decode$1 = JsonDecode.object(function (field) {
      return {
              amount: field.required("amount", JsonDecode.$$float),
              currency: field.required("currency", decode)
            };
    });

function decodeFromFloat(currency) {
  return JsonDecode.map(JsonDecode.$$float, (function (amount) {
                return {
                        amount: amount,
                        currency: currency
                      };
              }));
}

function decodeWithCurrency(currency) {
  return JsonDecode.array(JsonDecode.object(function (field) {
                  return {
                          price: field.required("price", decodeFromFloat(currency)),
                          quota: field.required("quota", JsonDecode.option(JsonDecode.$$int))
                        };
                }));
}

var decode$2 = JsonDecode.object(function (field) {
      return {
              threshold: field.required("threshold", decode$1),
              charge: field.required("charge", decode$1)
            };
    });

var decode$3 = JsonDecode.object(function (field) {
      var currency = field.required("currency", decode);
      var match = field.required("schemeType", JsonDecode.string);
      switch (match) {
        case "flat" :
            return {
                    TAG: "Flat",
                    _0: field.required("price", decodeFromFloat(currency))
                  };
        case "quoted" :
            return {
                    TAG: "Quoted",
                    _0: field.required("prices", decodeWithCurrency(currency))
                  };
        case "quotedWithThreshold" :
            return {
                    TAG: "QuotedWithThreshold",
                    _0: field.required("prices", decodeWithCurrency(currency)),
                    _1: field.required("threshold", decode$2)
                  };
        default:
          return "NotDefined";
      }
    });

var decode$4 = JsonDecode.object(function (field) {
      return {
              due: field.required("due", JsonDecode.string),
              periodStart: field.required("periodStart", JsonDecode.string),
              periodEnd: field.required("periodEnd", JsonDecode.string),
              totalAmount: field.required("totalAmount", decode$1)
            };
    });

var Bill = {
  decode: decode$4
};

function fromString$1(s) {
  switch (s) {
    case "due" :
        return "Due";
    case "prepared" :
        return "Prepared";
    default:
      return ;
  }
}

var decode$5 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              no: field.required("no", JsonDecode.string),
              totalAmount: field.required("totalAmount", decode$1),
              periodStart: field.required("periodStart", JsonDecode.string),
              periodEnd: field.required("periodEnd", JsonDecode.string),
              due: field.required("due", JsonDecode.string),
              status: field.required("status", JsonDecode.fromVariant(JsonDecode.string, fromString$1))
            };
    });

function hasFlag(flag, param) {
  return param.flags.some(function (f) {
              return f === flag;
            });
}

function hasAnyFlag(fs, param) {
  var flags = param.flags;
  return fs.some(function (flag) {
              return flags.some(function (f) {
                          return f === flag;
                        });
            });
}

var decode$6 = JsonDecode.object(function (field) {
      return {
              flags: RCore.$$Array.filterMap(field.required("flags", JsonDecode.array(Types_FeatureFlag.decode)), (function (x) {
                      return x;
                    })),
              smsPerSeat: field.required("smsPerSeat", JsonDecode.option(JsonDecode.$$int)),
              seatsLimit: field.required("seatsLimit", JsonDecode.option(JsonDecode.$$int))
            };
    });

var Features = {
  hasFlag: hasFlag,
  hasAnyFlag: hasAnyFlag,
  decode: decode$6
};

function toString(paymentMethod) {
  switch (paymentMethod) {
    case "Invoice" :
        return "invoice";
    case "Card" :
        return "card";
    case "Crypto" :
        return "crypto";
    
  }
}

function fromString$2(paymentMethod) {
  switch (paymentMethod) {
    case "card" :
        return "Card";
    case "crypto" :
        return "Crypto";
    case "invoice" :
        return "Invoice";
    default:
      return ;
  }
}

var decode$7 = JsonDecode.fromVariant(JsonDecode.string, fromString$2);

function fromString$3(paymentStatus) {
  switch (paymentStatus) {
    case "checked" :
        return "Checked";
    case "failed" :
        return "Failed";
    case "prepared" :
        return "Prepared";
    case "reversed" :
        return "Reversed";
    case "succeeded" :
        return "Succeeded";
    default:
      return ;
  }
}

var decode$8 = JsonDecode.fromVariant(JsonDecode.string, fromString$3);

function make(s, account) {
  switch (s) {
    case "bank" :
        if (account.startsWith("407") || account.startsWith("408")) {
          return "BankRub";
        } else {
          return "BankUsd";
        }
    case "paddle" :
        return "Paddle";
    case "paypro" :
        return "Paypro";
    default:
      return "Other";
  }
}

function makeFromString(s) {
  var match = Js_string.splitByReAtMost(/\//, 2, s);
  if (match.length !== 2) {
    return ;
  }
  var prefix = match[0];
  if (prefix === undefined) {
    return ;
  }
  var account = match[1];
  if (account === undefined) {
    return ;
  }
  var accountType = make(prefix, account);
  if (accountType !== undefined) {
    return {
            accountType: accountType
          };
  }
  
}

var decode$9 = JsonDecode.map(JsonDecode.string, makeFromString);

var decode$10 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              status: field.required("status", decode$8),
              paymentScreenFileUuid: field.required("paymentScreenFileUuid", JsonDecode.option(Uuid.decode)),
              billUuid: field.required("billUuid", JsonDecode.option(Uuid.decode)),
              billNo: field.required("billNo", JsonDecode.option(JsonDecode.string)),
              billScreenFileUuid: field.required("billScreenFileUuid", JsonDecode.option(Uuid.decode)),
              billPaymentAccount: RCore.$$Option.flatMap(field.required("billPaymentAccount", JsonDecode.option(decode$9)), (function (x) {
                      return x;
                    })),
              invoiceUuid: field.required("invoiceUuid", JsonDecode.option(Uuid.decode)),
              invoiceNo: field.required("invoiceNo", JsonDecode.option(JsonDecode.string)),
              invoiceScreenFileUuid: field.required("invoiceScreenFileUuid", JsonDecode.option(Uuid.decode)),
              invoicePaymentAccount: RCore.$$Option.flatMap(field.required("invoicePaymentAccount", JsonDecode.option(decode$9)), (function (x) {
                      return x;
                    })),
              currency: field.required("currency", decode),
              amount: field.required("amount", JsonDecode.$$float),
              createdAt: field.required("createdAt", JsonDecode.string)
            };
    });

var InvalidCycle = /* @__PURE__ */Caml_exceptions.create("Billing_Types.Order.Cycle.InvalidCycle");

function fromInt(i) {
  switch (i) {
    case 1 :
        return "one";
    case 2 :
        return "two";
    case 3 :
        return "three";
    case 4 :
        return "four";
    case 6 :
        return "six";
    case 12 :
        return "year";
    default:
      return ;
  }
}

function toInt(c) {
  if (c === "year") {
    return 12;
  } else if (c === "one") {
    return 1;
  } else if (c === "six") {
    return 6;
  } else if (c === "two") {
    return 2;
  } else if (c === "three") {
    return 3;
  } else {
    return 4;
  }
}

function cmp(x, y) {
  return Caml.int_compare(toInt(x), toInt(y));
}

var Cmp = Belt_Lib_Id.MakeComparableU({
      cmp: cmp
    });

var decode$11 = JsonDecode.fromVariant(JsonDecode.$$int, fromInt);

function allSeats(param) {
  return param.paidSeats + param.freeSeats | 0;
}

var decode$12 = JsonDecode.object(function (field) {
      return {
              planTypeId: field.required("planTypeId", Types.Id.decode),
              paidSeats: field.required("paidSeats", JsonDecode.$$int),
              freeSeats: field.required("freeSeats", JsonDecode.$$int),
              monthsCycle: field.required("monthsCycle", decode$11),
              organizationName: field.required("organizationName", JsonDecode.option(JsonDecode.string)),
              billingAddress: field.required("billingAddress", JsonDecode.option(JsonDecode.string)),
              paymentMethod: field.required("paymentMethod", decode$7),
              currency: field.required("currency", decode)
            };
    });

var decode$13 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              nextBilling: field.required("nextBilling", JsonDecode.string),
              billingDay: field.required("billingDay", JsonDecode.$$int),
              lastDayOfMonth: field.required("lastDayOfMonth", JsonDecode.bool),
              paidTill: field.required("paidTill", JsonDecode.string),
              nextBill: field.required("nextBill", JsonDecode.string),
              nextBillDue: field.required("nextBillDue", JsonDecode.string),
              paidSeats: field.required("paidSeats", JsonDecode.$$int),
              freeSeats: field.required("freeSeats", JsonDecode.$$int)
            };
    });

var Subscription = {
  decode: decode$13
};

var format = /^(.+)(?:-(\d+))$/;

function fromString$4(input) {
  var test = format.exec(input);
  var match = RCore.$$Option.map(test === null ? undefined : Caml_option.some(test), (function (r) {
          return r;
        }));
  if (match === undefined) {
    return {
            code: input,
            suffix: undefined
          };
  }
  if (match.length !== 3) {
    return {
            code: input,
            suffix: undefined
          };
  }
  var code = match[1];
  var suffix = match[2];
  if (!(code == null) && !(suffix == null)) {
    return {
            code: code,
            suffix: suffix
          };
  } else {
    return {
            code: input,
            suffix: undefined
          };
  }
}

var decode$14 = JsonDecode.map(JsonDecode.string, fromString$4);

var decode$15 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", decode$14),
              typeId: field.required("planTypeId", Types.Id.decode),
              monthsCycle: field.required("monthsCycle", decode$11),
              changeable: field.required("changeable", JsonDecode.bool),
              priceScheme: field.required("priceScheme", decode$3),
              features: field.required("features", decode$6),
              paddlePlanId: field.required("paddlePlanId", JsonDecode.option(Types.Id.decode))
            };
    });

var Plan = {
  decode: decode$15
};

var decode$16 = JsonDecode.object(function (field) {
      return {
              id: field.required("planTypeId", Types.Id.decode),
              name: field.required("name", decode$14),
              features: field.required("features", decode$6)
            };
    });

var PlanType = {
  decode: decode$16
};

var decode$17 = JsonDecode.object(function (field) {
      return {
              pan: field.required("pan", JsonDecode.string),
              expiresAt: field.required("expiresAt", JsonDecode.string),
              cardType: field.required("cardType", JsonDecode.string)
            };
    });

function fromString$5(status) {
  switch (status) {
    case "active" :
        return "Active";
    case "initial" :
        return "Initial";
    case "removed" :
        return "Removed";
    default:
      return ;
  }
}

var decode$18 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              status: field.required("status", JsonDecode.fromVariant(JsonDecode.string, fromString$5)),
              details: field.optional("details", decode$17)
            };
    });

function decode$19(f) {
  return {
          pan: f.required("pan", JsonDecode.string),
          expiresAt: f.required("expiresAt", JsonDecode.string),
          cardType: f.required("cardType", JsonDecode.string)
        };
}

var empty = null;

var Paypal = {
  empty: empty
};

var decode$20 = JsonDecode.object(function (field) {
      var match = field.required("type", JsonDecode.string);
      switch (match) {
        case "card" :
            return {
                    TAG: "Card",
                    _0: decode$19(field)
                  };
        case "paypal" :
            return {
                    TAG: "Paypal",
                    _0: empty
                  };
        default:
          return JsonDecode.cannotDecode("Expected valid option: card or paypal");
      }
    });

var decode$21 = JsonDecode.object(function (field) {
      return {
              subscriptionId: field.required("subscriptionId", Types.Id.decode),
              updateUrl: field.required("updateUrl", Url.decode),
              cancelUrl: field.required("cancelUrl", Url.decode),
              paymentInformation: field.required("paymentInformation", decode$20)
            };
    });

var decode$22 = JsonDecode.object(function (field) {
      return {
              account: field.required("account", JsonDecode.string),
              profile: field.required("profile", JsonDecode.string),
              currency: field.required("currency", JsonDecode.string)
            };
    });

var CryptoChillSettings = {
  decode: decode$22
};

var decode$23 = JsonDecode.object(function (field) {
      return {
              vendor: field.required("vendor", JsonDecode.$$int)
            };
    });

var PaddleSettings = {
  decode: decode$23
};

var Billing = {};

var Show = {};

function intFromStep(step) {
  switch (step) {
    case "ChoosePlan" :
        return 1;
    case "ChoosePaymentMethod" :
        return 2;
    case "Confirm" :
        return 3;
    
  }
}

var Wizard = {
  intFromStep: intFromStep
};

var Id = Types.Id;

var PK = Types.PK;

var Currency = {};

var Money = {};

var PriceScheme_QuotedPrice = {};

var PriceScheme_PriceThreshold = {};

var PriceScheme = {
  QuotedPrice: PriceScheme_QuotedPrice,
  PriceThreshold: PriceScheme_PriceThreshold
};

var UnpaidBill_Status = {};

var UnpaidBill = {
  Status: UnpaidBill_Status,
  decode: decode$5
};

var PaymentMethod = {
  toString: toString,
  decode: decode$7
};

var Payment_Status = {};

var Payment_PaymentAccount = {
  Type: {}
};

var Payment = {
  Status: Payment_Status,
  PaymentAccount: Payment_PaymentAccount,
  decode: decode$10
};

var Order_Cycle = {
  InvalidCycle: InvalidCycle,
  toInt: toInt,
  cmp: cmp,
  Cmp: Cmp
};

var Order = {
  Cycle: Order_Cycle,
  allSeats: allSeats,
  decode: decode$12
};

var PlanName = {
  fromString: fromString$4
};

var Card_Details = {};

var Card_Status = {};

var Card = {
  Details: Card_Details,
  Status: Card_Status,
  decode: decode$18
};

var PaddleSubscription_Card = {};

var PaddleSubscription_PaymentInformation = {};

var PaddleSubscription = {
  Card: PaddleSubscription_Card,
  Paypal: Paypal,
  PaymentInformation: PaddleSubscription_PaymentInformation,
  decode: decode$21
};

export {
  Id ,
  PK ,
  ISODate ,
  Currency ,
  Money ,
  PriceScheme ,
  Bill ,
  UnpaidBill ,
  Features ,
  PaymentMethod ,
  Payment ,
  Order ,
  Subscription ,
  PlanName ,
  Plan ,
  PlanType ,
  Card ,
  PaddleSubscription ,
  CryptoChillSettings ,
  PaddleSettings ,
  Billing ,
  Show ,
  Wizard ,
}
/* decode Not a pure module */
