

import * as Fun from "../../../../../../libraries/Fun.mjs";
import * as Uuid from "../../../../../../bindings/uuid/Uuid.mjs";
import * as Wire from "../../../../../../types/Wire.mjs";
import * as Hooks from "../../../../../../libraries/hooks/Hooks.mjs";
import * as React from "react";
import * as Optional from "../../../../../common/Optional.mjs";
import * as UrlInput from "../../../../../inputs/UrlInput.mjs";
import * as FormInput from "../../../../../inputs/FormInput.mjs";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FieldErrors from "../../../../../common/FieldError/FieldErrors.mjs";
import * as DecimalInput from "../../../../../inputs/DecimalInput.mjs";
import * as IntegerInput from "../../../../../inputs/IntegerInput.mjs";
import * as CurrencyInput from "../../../../../inputs/CurrencyInput.mjs";
import * as DateTimePicker from "../../../../../inputs/DateTimePicker.mjs";
import * as JobAttachmentInput from "../../../../../file/job_attachment_input/JobAttachmentInput.mjs";
import * as JobForm_ReportFieldButton from "../reports/report_field/components/button/JobForm_ReportFieldButton.mjs";
import * as JobForm_ReportFieldDatePicker from "../reports/report_field/components/date_picker/JobForm_ReportFieldDatePicker.mjs";
import * as JobForm_ReportFieldDictionary from "../reports/report_field/components/dictionary/JobForm_ReportFieldDictionary.mjs";
import * as JobForm_ReportFieldTimePicker from "../reports/report_field/components/time_picker/JobForm_ReportFieldTimePicker.mjs";
import * as JobForm_CustomFieldModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/edit/form/components/custom_fields/JobForm_CustomField.module.css";

var styles = JobForm_CustomFieldModuleCss;

function JobForm_CustomField(Props) {
  var wire = Props.wire;
  var disabled = Props.disabled;
  var field = Props.field;
  var addFile = Props.addFile;
  var removeFile = Props.removeFile;
  var updateCustomField = Props.updateCustomField;
  var jobUuid = Props.jobUuid;
  var setLock = Props.setLock;
  var errors = Props.errors;
  var showCarouselImage = Props.showCarouselImage;
  var id = Hooks.useId(undefined);
  var key = Uuid.toString(field.typeUuid);
  var files = field.value;
  switch (files.TAG) {
    case "Button" :
        return React.createElement(Optional.make, {
                    show: Wire.hasTag(wire, "planadoteam"),
                    children: React.createElement("div", {
                          className: styles.field
                        }, React.createElement("div", {
                              className: styles.fieldName
                            }, field.name), React.createElement(JobForm_ReportFieldButton.make, {
                              wire: wire,
                              jobUuid: jobUuid,
                              typeUuid: field.typeUuid,
                              button: files._0
                            }))
                  });
    case "Currency" :
        var match = files._0;
        var useCurrencyFractionalUnit = match.useCurrencyFractionalUnit;
        var currency = match.currency;
        return React.createElement("div", {
                    className: styles.field
                  }, React.createElement("div", {
                        className: styles.fieldName
                      }, field.name), React.createElement(CurrencyInput.make, {
                        wire: wire,
                        currency: currency,
                        useCurrencyFractionalUnit: useCurrencyFractionalUnit,
                        value: match.value,
                        onValueChange: (function (value) {
                            updateCustomField({
                                  typeUuid: field.typeUuid,
                                  name: field.name,
                                  value: {
                                    TAG: "Currency",
                                    _0: {
                                      currency: currency,
                                      useCurrencyFractionalUnit: useCurrencyFractionalUnit,
                                      value: Fun.optString(value)
                                    }
                                  },
                                  readOnly: field.readOnly,
                                  required: field.required
                                });
                          }),
                        disabled: disabled,
                        backendErrors: Core__List.fromArray(errors)
                      }));
    case "Date" :
        var tmp = {
          id: id,
          onChange: (function (value) {
              updateCustomField({
                    typeUuid: field.typeUuid,
                    name: field.name,
                    value: {
                      TAG: "Date",
                      _0: value
                    },
                    readOnly: field.readOnly,
                    required: field.required
                  });
            }),
          disabled: disabled,
          wire: wire,
          errors: errors
        };
        if (files._0 !== undefined) {
          tmp.date = Caml_option.valFromOption(files._0);
        }
        return React.createElement("div", {
                    className: styles.field
                  }, React.createElement("div", {
                        className: styles.fieldName
                      }, field.name), React.createElement(JobForm_ReportFieldDatePicker.make, tmp));
    case "DateTime" :
        return React.createElement("div", {
                    className: styles.field
                  }, React.createElement(DateTimePicker.make, {
                        id: id,
                        label: field.name,
                        dateTime: files._0,
                        onChange: (function (value) {
                            updateCustomField({
                                  typeUuid: field.typeUuid,
                                  name: field.name,
                                  value: {
                                    TAG: "DateTime",
                                    _0: value
                                  },
                                  readOnly: field.readOnly,
                                  required: field.required
                                });
                            return true;
                          }),
                        disabled: disabled,
                        wrapperClass: styles.datetimePickerWrapper
                      }), React.createElement(FieldErrors.make, {
                        errors: errors
                      }));
    case "Dictionary" :
        var match$1 = files._0;
        var dictionaryValues = match$1.dictionaryValues;
        return React.createElement(JobForm_ReportFieldDictionary.make, {
                    id: id,
                    label: field.name,
                    value: match$1.value,
                    dictionaryValues: dictionaryValues,
                    onValueChange: (function (value) {
                        updateCustomField({
                              typeUuid: field.typeUuid,
                              name: field.name,
                              value: {
                                TAG: "Dictionary",
                                _0: {
                                  dictionaryValues: dictionaryValues,
                                  value: value
                                }
                              },
                              readOnly: field.readOnly,
                              required: field.required
                            });
                      }),
                    disabled: disabled,
                    errors: errors,
                    ctx: wire.ctx
                  });
    case "File" :
        return React.createElement("div", {
                    className: styles.field
                  }, React.createElement(JobAttachmentInput.make, {
                        fieldName: field.name,
                        files: files._0,
                        addFile: (function (file) {
                            addFile(field.typeUuid, file);
                          }),
                        removeFile: (function (uuid) {
                            removeFile(field.typeUuid, uuid);
                          }),
                        setLock: setLock,
                        readOnly: disabled,
                        maxFilesInField: 1,
                        showCarouselImage: showCarouselImage,
                        key: key
                      }), React.createElement(FieldErrors.make, {
                        errors: errors
                      }));
    case "Input" :
        var inputType = files._0;
        var variant = inputType.NAME;
        if (variant === "Integer") {
          return React.createElement(IntegerInput.make, {
                      id: id,
                      value: inputType.VAL,
                      onValueChange: (function (value) {
                          updateCustomField({
                                typeUuid: field.typeUuid,
                                name: field.name,
                                value: {
                                  TAG: "Input",
                                  _0: {
                                    NAME: "Integer",
                                    VAL: value
                                  }
                                },
                                readOnly: field.readOnly,
                                required: field.required
                              });
                        }),
                      disabled: disabled,
                      labelText: field.name,
                      errors: errors
                    });
        } else if (variant === "Decimal") {
          return React.createElement(DecimalInput.make, {
                      id: id,
                      value: inputType.VAL,
                      onValueChange: (function (value) {
                          updateCustomField({
                                typeUuid: field.typeUuid,
                                name: field.name,
                                value: {
                                  TAG: "Input",
                                  _0: {
                                    NAME: "Decimal",
                                    VAL: value
                                  }
                                },
                                readOnly: field.readOnly,
                                required: field.required
                              });
                        }),
                      disabled: disabled,
                      labelText: field.name,
                      wire: wire,
                      errors: errors
                    });
        } else {
          return React.createElement(FormInput.make, {
                      _type: "textarea",
                      value: inputType.VAL,
                      onValueChange: (function (value) {
                          updateCustomField({
                                typeUuid: field.typeUuid,
                                name: field.name,
                                value: {
                                  TAG: "Input",
                                  _0: {
                                    NAME: "String",
                                    VAL: value
                                  }
                                },
                                readOnly: field.readOnly,
                                required: field.required
                              });
                        }),
                      disabled: disabled,
                      labelText: field.name,
                      maxLength: 200,
                      errors: errors,
                      autoFocus: true
                    });
        }
    case "Link" :
        return React.createElement("div", undefined, React.createElement(UrlInput.make, {
                        id: id,
                        value: files._0,
                        onValueChange: (function (value) {
                            updateCustomField({
                                  typeUuid: field.typeUuid,
                                  name: field.name,
                                  value: {
                                    TAG: "Link",
                                    _0: value
                                  },
                                  readOnly: field.readOnly,
                                  required: field.required
                                });
                          }),
                        disabled: disabled,
                        labelText: field.name,
                        wire: wire,
                        className: undefined,
                        errors: errors
                      }));
    case "Time" :
        var tmp$1 = {
          onChange: (function (value) {
              updateCustomField({
                    typeUuid: field.typeUuid,
                    name: field.name,
                    value: {
                      TAG: "Time",
                      _0: value
                    },
                    readOnly: field.readOnly,
                    required: field.required
                  });
            }),
          disabled: disabled,
          wire: wire,
          errors: errors,
          key: key
        };
        if (files._0 !== undefined) {
          tmp$1.time = Caml_option.valFromOption(files._0);
        }
        return React.createElement("div", {
                    className: styles.field
                  }, React.createElement("div", {
                        className: styles.fieldName
                      }, field.name), React.createElement(JobForm_ReportFieldTimePicker.make, tmp$1));
    
  }
}

var make = JobForm_CustomField;

var $$default = JobForm_CustomField;

export {
  make ,
  $$default as default,
}
/* styles Not a pure module */
