

import * as RCore from "../../../../../libraries/RCore.mjs";
import * as Locale from "../../../../../libraries/Locale.mjs";
import * as Backend from "../../../../../libraries/backend/Backend.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as PromiseF from "../../../../../libraries/PromiseF.mjs";
import * as Shared_Lib_IO from "../../../../../shared/lib/Shared_Lib_IO.mjs";
import * as MultipleAssignee from "../../../../../types/MultipleAssignee.mjs";
import * as Schedule_Decoder from "./Schedule_Decoder.mjs";
import * as Schedule_Types_Settings from "../../types/Schedule_Types_Settings.mjs";

var $$fetch = Shared_Lib_IO.debounce(undefined, 1000, (function (param) {
        var jobDuration = param[3];
        var filterAssigned = param[2];
        var filter = param[1];
        var wire = param[0];
        return PromiseF.toIOLazy(function () {
                    return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                                    NAME: "API",
                                    VAL: {
                                      NAME: "Schedule",
                                      VAL: {
                                        NAME: "ScheduledJobs",
                                        VAL: [
                                          filter,
                                          filterAssigned,
                                          jobDuration
                                        ]
                                      }
                                    }
                                  }), Schedule_Decoder.ScheduledJobs.decode(jobDuration));
                  });
      }));

var ScheduledJobs = {
  $$fetch: $$fetch
};

var $$fetch$1 = Shared_Lib_IO.debounce(undefined, 1000, (function (param) {
        var jobDuration = param[3];
        var jobsAfter = param[2];
        var filter = param[1];
        var wire = param[0];
        return PromiseF.toIOLazy(function () {
                    return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                                    NAME: "API",
                                    VAL: {
                                      NAME: "Schedule",
                                      VAL: {
                                        NAME: "UnscheduledJobs",
                                        VAL: [
                                          filter,
                                          jobsAfter,
                                          jobDuration
                                        ]
                                      }
                                    }
                                  }), Schedule_Decoder.UnscheduledJobs.decode(jobDuration));
                  });
      }));

var UnscheduledJobs = {
  $$fetch: $$fetch$1
};

var $$fetch$2 = Shared_Lib_IO.debounce(undefined, 1000, (function (param) {
        var filter = param[1];
        var wire = param[0];
        return PromiseF.toIOLazy(function () {
                    return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                                    NAME: "API",
                                    VAL: {
                                      NAME: "Schedule",
                                      VAL: {
                                        NAME: "Shifts",
                                        VAL: filter
                                      }
                                    }
                                  }), Schedule_Decoder.ScheduleTimeline.decode);
                  });
      }));

var ScheduleTimeline = {
  $$fetch: $$fetch$2
};

function encodeScheduledAt(wire, scheduledAt) {
  if (scheduledAt !== undefined) {
    return Js_null.fromOption(RCore.$$Option.map(Locale.T.concatDateWithTime(scheduledAt, wire), Locale.T.make));
  } else {
    return null;
  }
}

function update(jobUuid, assignees, scheduledAt, wire) {
  return PromiseF.toIOLazy(function () {
              return Backend.patch(undefined, true, undefined, undefined, wire, {
                          NAME: "API",
                          VAL: {
                            NAME: "Schedule",
                            VAL: {
                              NAME: "Job",
                              VAL: jobUuid
                            }
                          }
                        }, {
                          NAME: "Obj",
                          VAL: {
                            scheduled_at: encodeScheduledAt(wire, scheduledAt),
                            assignees: MultipleAssignee.serialize(MultipleAssignee.encode(assignees))
                          }
                        });
            });
}

var $$Request = {
  update: update
};

var Patch = {
  $$Request: $$Request
};

var Job = {
  encodeScheduledAt: encodeScheduledAt,
  Patch: Patch
};

function $$fetch$3(wire) {
  return PromiseF.toIOLazy(function () {
              return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                              NAME: "API",
                              VAL: {
                                NAME: "Schedule",
                                VAL: "Index"
                              }
                            }), Schedule_Types_Settings.FilterVariants.decode);
            });
}

var FilterVariants = {
  $$fetch: $$fetch$3
};

export {
  ScheduledJobs ,
  UnscheduledJobs ,
  ScheduleTimeline ,
  Job ,
  FilterVariants ,
}
/* fetch Not a pure module */
