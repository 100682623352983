

import * as Utils from "../../utils/Utils.mjs";
import * as Currency from "../../libraries/Currency.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";

function currencySymbol$p(code, ctx) {
  return Utils.Translations.t("common.currency_signs." + code)(ctx);
}

function CurrencyText(Props) {
  var ctx = Props.ctx;
  var currency = Props.currency;
  var value = Props.value;
  var currencyFormat = ctx.localizator.currencyFormat;
  var match = ctx.localizator.numbers;
  var delimiter = match.delimiter;
  var separator = match.separator;
  var parsed = Currency.parse(value, true, ctx, false);
  var format = function (parsedInput) {
    return Currency.format(delimiter, separator, undefined, false, true, parsedInput);
  };
  var displayedValue;
  if (parsed.TAG === "Ok") {
    displayedValue = format(parsed._0);
  } else {
    var parsedInput = parsed._0;
    displayedValue = typeof parsedInput !== "object" ? "" : format(parsedInput._0);
  }
  var currencySymbol = currencySymbol$p(currency, ctx);
  var __x = (function (__x) {
        return Js_string.replace("%v", displayedValue, __x);
      })(currencyFormat);
  return Js_string.replace("%$", currencySymbol, __x);
}

var make = CurrencyText;

export {
  make ,
}
/* Utils Not a pure module */
