export default [
  'az',
  'am',
  'by',
  'ee',
  'es',
  'pa',
  'kz',
  'kg',
  'lv',
  'lt',
  'md',
  'ru',
  'tj',
  'tm',
  'ua',
  'uk',
  'us',
  'uz',
  'th'
]
