

import * as Template_pageJsx from "planado/components/admin/templates/show/template_page.jsx";

var make = Template_pageJsx.Form;

export {
  make ,
}
/* make Not a pure module */
