

import * as Fun from "../../../libraries/Fun.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Locale from "../../../libraries/Locale.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function initial(boundingBox) {
  return {
          period: "Today",
          template: undefined,
          territory: undefined,
          jobType: undefined,
          boundingBox: boundingBox
        };
}

function reducer(state, action) {
  switch (action.TAG) {
    case "SetPeriod" :
        return {
                period: action._0,
                template: state.template,
                territory: state.territory,
                jobType: state.jobType,
                boundingBox: state.boundingBox
              };
    case "SetTerritory" :
        return {
                period: state.period,
                template: state.template,
                territory: action._0,
                jobType: state.jobType,
                boundingBox: state.boundingBox
              };
    case "SetJobType" :
        return {
                period: state.period,
                template: state.template,
                territory: state.territory,
                jobType: action._0,
                boundingBox: state.boundingBox
              };
    case "SetTemplate" :
        return {
                period: state.period,
                template: action._0,
                territory: state.territory,
                jobType: state.jobType,
                boundingBox: state.boundingBox
              };
    case "SetBoundingBox" :
        return {
                period: state.period,
                template: state.template,
                territory: state.territory,
                jobType: state.jobType,
                boundingBox: action._0
              };
    
  }
}

var Filter = {
  initial: initial,
  reducer: reducer
};

var initial_territories = [];

var initial_templates = [];

var initial_types = [];

var initial$1 = {
  territories: initial_territories,
  templates: initial_templates,
  types: initial_types
};

var FilterValues = {
  initial: initial$1
};

function mapStatus(s) {
  if (s === "Changed") {
    return "Assigned";
  } else {
    return s;
  }
}

function filterJob(date, s) {
  return function (param) {
    var scheduledAt = param.scheduledAt;
    if (scheduledAt !== undefined && Locale.T.equal(date, Locale.T.startOf("day", Caml_option.valFromOption(scheduledAt)))) {
      return mapStatus(param.status) === s;
    } else {
      return false;
    }
  };
}

function groupByStatus(jobs) {
  return function (date) {
    var filtered = Core__List.filter(Core__List.map({
              hd: "Scheduled",
              tl: {
                hd: "Assigned",
                tl: {
                  hd: "Published",
                  tl: {
                    hd: "EnRoute",
                    tl: {
                      hd: "Started",
                      tl: {
                        hd: "Suspended",
                        tl: {
                          hd: "Finished",
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }, (function (s) {
                return [
                        s,
                        RCore.$$Array.keep(jobs, filterJob(date, s))
                      ];
              })), (function (v) {
            return Fun.pos(v[1].length);
          }));
    return [
            date,
            filtered
          ];
  };
}

function makeJobsByDate(dates, jobs) {
  return Core__List.filter(Core__List.map(dates, groupByStatus(jobs)), (function (v) {
                return Fun.pos(Core__List.length(v[1]));
              }));
}

var initial_jobs = [];

var initial_unscheduledJobs = [];

var initial$2 = {
  jobs: initial_jobs,
  dates: /* [] */0,
  jobsByDate: undefined,
  unscheduledJobs: initial_unscheduledJobs,
  focusedJob: undefined
};

function reducer$1(state, action) {
  var unscheduledJobs = action._2;
  var scheduledJobs = action._1;
  var dates = action._0;
  var grouped = makeJobsByDate(dates, scheduledJobs);
  return {
          jobs: Belt_Array.concatMany([
                scheduledJobs,
                unscheduledJobs
              ]),
          dates: dates,
          jobsByDate: grouped,
          unscheduledJobs: unscheduledJobs,
          focusedJob: state.focusedJob
        };
}

var Jobs = {
  initial: initial$2,
  reducer: reducer$1
};

export {
  Filter ,
  FilterValues ,
  Jobs ,
}
/* Locale Not a pure module */
