import {
  fetchJSON,
  fetchJSONC,
  sendJSONRequest,
} from 'planado/utils/network.js'
import { serializeAssignees } from 'planado/utils/serialize_assignees.js'
import { readFileInBase64 } from 'planado/utils/file_reader'
import { templatesPath, templatePath } from 'planado/routes.js'
import {
  templatePossibleFieldNames,
  templateCheckName,
} from 'planado/routes.js'

import {
  TEMPLATE_SET_FIELD,
  TEMPLATE_ADD_CUSTOM_FIELD,
  TEMPLATE_MOVE_CUSTOM_FIELD,
  TEMPLATE_SET_CUSTOM_FIELD,
  TEMPLATE_DELETE_CUSTOM_FIELD,
  TEMPLATE_SET_CUSTOM_FIELD_FILE,
  TEMPLATE_SET_POSSIBLE_ASSIGNEES,
  TEMPLATE_ADD_RESOLUTION,
  TEMPLATE_MOVE_RESOLUTION,
  TEMPLATE_DELETE_RESOLUTION,
  GET_POSSIBLE_FIELD_NAMES_REQUEST,
  GET_POSSIBLE_FIELD_NAMES_SUCCESS,
  GET_POSSIBLE_FIELD_NAMES_FAIL,
  TEMPLATE_ADD_ERROR,
  CHECK_NAME_REQUEST,
  CHECK_NAME_SUCCESS,
  CHECK_NAME_FAIL,
  SHOW_POPOVER,
  SHOW_MODAL,
  SET_TEMPLATE,
  CHANGE_SKILLS,
  CHANGE_TERRITORY,
  SEND_FORM_REQUEST,
  SEND_FORM_SUCCESS,
  SEND_FORM_FAIL,
  TEMPLATE_SET_TRACK_MOVEMENTS,
  TEMPLATE_SET_ASSIGNEES,
  TEMPLATE_SET_CAN_BE_USED_ON_MOBILE,
  SET_SERVICES,
  SET_CATEGORIES,
} from 'planado/constants/admin/templates'

import { sortAssigneesBySkillsAndTerritories } from 'planado/utils/possible_assignees'
import { addUuidsToFileCustomFields } from 'planado/stores/admin/templates'

export const setTrackMovements = (trackMovements) => ({
  type: TEMPLATE_SET_TRACK_MOVEMENTS,
  trackMovements,
})

export const setCanBeUsedOnMobile = (canBeUsedOnMobile) => ({
  type: TEMPLATE_SET_CAN_BE_USED_ON_MOBILE,
  canBeUsedOnMobile,
})

export const setField = (name, value) => ({
  type: TEMPLATE_SET_FIELD,
  name,
  value,
})

export const setAssignees = (value) => ({
  type: TEMPLATE_SET_ASSIGNEES,
  value,
})

export const setServices = (value) => ({
  type: SET_SERVICES,
  services: value,
})

export const setCategories = (value) => ({
  type: SET_CATEGORIES,
  categories: value,
})


export const setCustomField = (index, name, value, isCustomField) => ({
  type: TEMPLATE_SET_CUSTOM_FIELD,
  index,
  name,
  value,
  isCustomField,
})

export const addCustomField = (customField) => ({
  type: TEMPLATE_ADD_CUSTOM_FIELD,
  customField,
})

export const moveCustomField = (fields, isCustomField) => ({
  type: TEMPLATE_MOVE_CUSTOM_FIELD,
  value: {
    fields: fields,
    isCustomField: isCustomField,
  },
})

export const deleteCustomField =
  (fieldUuid, isNew, isCustomField) => (dispatch) =>
    dispatch({
      type: TEMPLATE_DELETE_CUSTOM_FIELD,
      fieldUuid,
      isNew,
      isCustomField,
    })

export const setCustomFieldFile = (index, file, isCustomField) => (dispatch) =>
  dispatch({
    type: TEMPLATE_SET_CUSTOM_FIELD_FILE,
    index,
    value: file,
    isCustomField,
  })

export const addResolutions = (possibleResolutionIds) => ({
  type: TEMPLATE_ADD_RESOLUTION,
  possibleResolutionIds,
})

export const moveResolution = (resolutions, successful) => ({
  type: TEMPLATE_MOVE_RESOLUTION,
  value: {
    resolutions: resolutions,
    successful: successful,
  },
})

export const deleteResolution = (id) => ({
  type: TEMPLATE_DELETE_RESOLUTION,
  id,
})

export const checkTemplateName = (ctx, name, id, isNewRecord) => {
  return (dispatch) => {
    dispatch({
      type: CHECK_NAME_REQUEST,
    })

    const except = isNewRecord ? null : id
    fetchJSON(templateCheckName(name, except), ctx).then((templateNameValid) => {
      if (templateNameValid) {
        dispatch({
          type: CHECK_NAME_SUCCESS,
        })
      } else {
        dispatch({
          type: CHECK_NAME_FAIL,
          error: ctx.t('js.templates.info.errors.name_already_exists'),
        })
      }
    })
  }
}

export const getPossibleFieldNames = (
  ctx,
  isCustomField,
  type,
  name = '',
  dataType = ''
) => {
  return (dispatch) => {
    dispatch({
      type: GET_POSSIBLE_FIELD_NAMES_REQUEST,
    })

    fetchJSONC(
      templatePossibleFieldNames(isCustomField, type, name, dataType),
      ctx
    ).then(({ possibleFieldNames }) => {
      if (possibleFieldNames.length > 0) {
        dispatch({
          type: GET_POSSIBLE_FIELD_NAMES_SUCCESS,
          possibleFieldNames: possibleFieldNames,
          fieldType: type,
        })
      } else {
        dispatch({
          type: GET_POSSIBLE_FIELD_NAMES_FAIL,
          possibleFieldNames: [],
          fieldType: type,
        })
      }
    })
  }
}

export const setError = (name, error) => ({
  type: TEMPLATE_ADD_ERROR,
  name,
  error,
})

export const showPopover = (node, dom, buttonId) => (dispatch) => {
  dispatch({
    type: SHOW_POPOVER,
    dom,
    buttonId,
    node,
  })
}

export const showModal = (node) => (dispatch) => {
  dispatch({
    type: SHOW_MODAL,
    node,
  })
}

export const setTemplate = (dispatch, newData) => {
  const {
    possibleAssignees,
    skillUuids,
    territoryUuid,
    customFields,
    reportFields,
  } = newData

  const possibleAssigneesBySkillsAndTerritories =
    sortAssigneesBySkillsAndTerritories(
      possibleAssignees,
      skillUuids,
      territoryUuid
    )

  const newState = {
    ...newData,
    possibleAssignees: possibleAssigneesBySkillsAndTerritories,
    customFields: addUuidsToFileCustomFields(customFields, reportFields),
  }

  dispatch({
    type: SET_TEMPLATE,
    newState,
  })
}

export const changeSkills = (skillUuids) => (dispatch, getState) => {
  dispatch({
    type: CHANGE_SKILLS,
    skillUuids,
  })

  const { possibleAssignees, territoryUuid } = getState()

  const possibleAssigneesBySkillsAndTerritories =
    sortAssigneesBySkillsAndTerritories(
      possibleAssignees,
      skillUuids,
      territoryUuid
    )

  dispatch({
    type: TEMPLATE_SET_POSSIBLE_ASSIGNEES,
    possibleAssignees: possibleAssigneesBySkillsAndTerritories,
  })
}

export const changeTerritory = (territoryUuid) => (dispatch, getState) => {
  dispatch({
    type: CHANGE_TERRITORY,
    territoryUuid,
  })

  const { possibleAssignees, skillUuids } = getState()

  const possibleAssigneesBySkillsAndTerritories =
    sortAssigneesBySkillsAndTerritories(
      possibleAssignees,
      skillUuids,
      territoryUuid
    )

  dispatch({
    type: TEMPLATE_SET_POSSIBLE_ASSIGNEES,
    possibleAssignees: possibleAssigneesBySkillsAndTerritories,
  })
}

const mapField = (f, newValue) => {
  let signedFieldUuid
  if (f.fieldType === 'signature') {
    signedFieldUuid = { signedFieldUuid: f.signedFieldUuid }
  } else {
    signedFieldUuid = {}
  }

  let currencyFieldValues
  if (f.fieldType === 'currency') {
    currencyFieldValues = {
      currency: f.currency,
      useCurrencyFractionalUnit: f.useCurrencyFractionalUnit,
      currencyValue: f.currencyValue,
    }
  } else {
    currencyFieldValues = {}
  }

  let useGallery
  if (f.fieldType === 'image') {
    useGallery = { useGallery: f.useGallery }
  } else {
    useGallery = {}
  }

  let reusableButton
  if (f.fieldType === 'button') {
    reusableButton = {
      urlValue: f.isDestroyed ? null : f.urlValue,
      reusableButton: f.reusableButton,
    }
  } else {
    reusableButton = {}
  }

  return {
    uuid: f.fieldUuid,
    value: newValue || f.value,
    name: f.name,
    required: f.required,
    hidden: f.hidden,
    readOnly: f.readOnly,
    dictionaryUuid: f.dictionaryUuid,
    fieldType: f.fieldType,
    dataType: f.dataType,
    deleted: f.isDestroyed === true,
    removeFile: f.removeFile === true,
    urlValue: f.urlValue,
    ...useGallery,
    ...currencyFieldValues,
    ...signedFieldUuid,
    ...reusableButton,
  }
}

const mapFields = (fields) =>
  fields.map((f) => {
    if (f.fieldType === 'file' && f.value instanceof File) {
      return readFileInBase64(f.value).then((base64Content) =>
        mapField(f, { base64Content, name: f.value.name })
      )
    } else {
      return Promise.resolve(mapField(f))
    }
  })

const serializeCatalog = (catalog) => {
  return [
    ...catalog.services.map((service) => ({
      ...service,
      type: 'service'
    })),
    ...catalog.categories.map((category) => ({
      ...category,
      type: 'category'
    }))
  ]
}

export const submitTemplate =
  ({ onSuccess, onFailure, onNotFound, ctx }) =>
    async (dispatch, getState) => {
      const {
        template,
        customFields,
        reportFields,
        skillUuids,
        territoryUuid,
        possibleResolutionIds,
        catalog
      } = getState()

      const { isNewRecord, uuid, name: templateName } = template

      dispatch({ type: SEND_FORM_REQUEST })

      const except = isNewRecord ? null : uuid
      const validName = await fetchJSON(
        templateCheckName(templateName, except),
        ctx
      )

      if (validName) {
        dispatch({ type: CHECK_NAME_SUCCESS })

        const url = isNewRecord ? templatesPath : templatePath(uuid)

        try {
          const cfs = await Promise.all(mapFields(customFields))
          const rfs = await Promise.all(mapFields(reportFields))

          const body = {
            ...template,
            customFields: cfs,
            reportFields: rfs,
            skillUuids,
            territoryUuid,
            possibleResolutionIds,
            assignees: serializeAssignees(template.assignees),
            catalog: serializeCatalog(catalog),
          }

          const updatedTemplate = await sendJSONRequest(url, ctx, {
            method: isNewRecord ? 'POST' : 'PATCH',
            body,
          })

          dispatch({
            type: SET_TEMPLATE,
            newState: updatedTemplate,
          })

          onSuccess({
            uuid: updatedTemplate.template.uuid,
            name: updatedTemplate.template.name,
          })

          dispatch({ type: SEND_FORM_SUCCESS })
        } catch (e) {
          dispatch({ type: SEND_FORM_FAIL })

          if (e.status === 404) {
            onNotFound()
          } else {
            onFailure()
          }
        }
      } else {
        dispatch({ type: SEND_FORM_FAIL })

        dispatch({
          type: CHECK_NAME_FAIL,
          error: ctx.t('js.templates.info.errors.name_already_exists'),
        })
      }
    }
