

import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";

function make() {
  var subscriptions = {
    contents: /* [] */0
  };
  var subscribe = function (callback) {
    subscriptions.contents = {
      hd: callback,
      tl: subscriptions.contents
    };
    return function () {
      subscriptions.contents = Core__List.filter(subscriptions.contents, (function (s) {
              return s !== callback;
            }));
    };
  };
  var trigger = function (evt) {
    Core__List.map(subscriptions.contents, (function (c) {
            c(evt);
          }));
  };
  return {
          subscribe: subscribe,
          trigger: trigger
        };
}

function empty_subscribe(param) {
  return function (prim) {
    
  };
}

function empty_trigger(prim) {
  
}

var empty = {
  subscribe: empty_subscribe,
  trigger: empty_trigger
};

function buildStack(callbacks) {
  if (!callbacks) {
    return function (prim) {
      
    };
  }
  var t = callbacks.tl;
  var h = callbacks.hd;
  return function (e) {
    h([
          e,
          buildStack(t)
        ]);
  };
}

function create() {
  var subscriptions = {
    contents: /* [] */0
  };
  var stack = {
    contents: (function (prim) {
        
      })
  };
  var rebuild = function () {
    stack.contents = buildStack(subscriptions.contents);
  };
  var subscribe = function (callback) {
    subscriptions.contents = {
      hd: callback,
      tl: subscriptions.contents
    };
    rebuild();
    return function () {
      subscriptions.contents = Core__List.filter(subscriptions.contents, (function (s) {
              return s !== callback;
            }));
      rebuild();
    };
  };
  var trigger = function (evt) {
    stack.contents(evt);
  };
  return {
          subscribe: subscribe,
          trigger: trigger
        };
}

var Stacked = {
  create: create
};

export {
  make ,
  empty ,
  Stacked ,
}
/* No side effect */
