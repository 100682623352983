

import * as Fun from "../../../../../../../../libraries/Fun.mjs";
import * as Types from "../../../../../../../../types/Types.mjs";
import * as Units from "../../../../../../../../types/Units.mjs";
import * as React from "react";
import * as Optional from "../../../../../../../common/Optional.mjs";
import * as AppContext from "../../../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_Scroll from "../../../../../../components/scroll/Schedule_Scroll.mjs";
import * as WeekTimeline_ActiveDayState from "../../lib/state/WeekTimeline_ActiveDayState.mjs";
import * as WeekTimeline_ActiveDayUtils from "../../lib/WeekTimeline_ActiveDayUtils.mjs";
import * as WeekTimeline_ActiveDayButtons from "../buttons/WeekTimeline_ActiveDayButtons.mjs";
import * as WeekTimeline_ActiveDaySkeleton from "../skeleton/WeekTimeline_ActiveDaySkeleton.mjs";
import * as WeekTimeline_ActiveDayStateTypes from "../../lib/state/WeekTimeline_ActiveDayStateTypes.mjs";
import * as WeekTimeline_ActiveDayScrollModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/active_day/components/scroll/WeekTimeline_ActiveDayScroll.module.css";

var styles = WeekTimeline_ActiveDayScrollModuleCss;

function makeStyle(width) {
  return {
          width: Units.Px.toString(width)
        };
}

function WeekTimeline_ActiveDayScroll(Props) {
  var param = Props.activeDay;
  var children = Props.children;
  var scrollSubscriptions = param.scrollSubscriptions;
  var sizes = param.sizes;
  var match = param.elements;
  var viewportEl = match.viewportEl;
  var scrollAreaEl = match.scrollAreaEl;
  var period = param.period;
  var wire = AppContext.useWire();
  var setState = WeekTimeline_ActiveDayState.useSetState();
  var renderedAt = React.useRef(0.0);
  var match$1 = React.useState(function () {
        return true;
      });
  var setShowSkeleton = match$1[1];
  var showSkeleton = match$1[0];
  React.useLayoutEffect((function () {
          renderedAt.current = Date.now();
          var match = Fun.both(Types.ReactRef.toOption(viewportEl), Types.ReactRef.toOption(scrollAreaEl));
          if (match === undefined) {
            return ;
          }
          var initialPosition = WeekTimeline_ActiveDayUtils.InitialPosition.make(wire, period, sizes);
          var scroll = Schedule_Scroll.init(initialPosition, undefined, undefined, undefined, match[0], Caml_option.some(match[1]), undefined, undefined);
          if (scroll === undefined) {
            return ;
          }
          var subscriptions = scroll.subscriptions;
          setState(function (state) {
                var activeDay = state.activeDay;
                if (activeDay !== undefined) {
                  return {
                          activeDay: {
                            bar: activeDay.bar,
                            weekDay: activeDay.weekDay,
                            period: activeDay.period,
                            elements: activeDay.elements,
                            sizes: activeDay.sizes,
                            jobs: activeDay.jobs,
                            scrollSubscriptions: subscriptions,
                            intervals: activeDay.intervals
                          },
                          hiddenActiveDay: state.hiddenActiveDay
                        };
                } else {
                  return WeekTimeline_ActiveDayStateTypes.State.initial;
                }
              });
          return scroll.onDestroy;
        }), []);
  React.useLayoutEffect((function () {
          if (scrollSubscriptions !== undefined) {
            var delay = 1000.0 - (Date.now() - renderedAt.current);
            setInterval((function () {
                    setShowSkeleton(function (param) {
                          return false;
                        });
                  }), delay);
          }
          
        }), [
        scrollSubscriptions,
        showSkeleton
      ]);
  return React.createElement("div", {
              className: styles.container
            }, React.createElement("div", {
                  ref: scrollAreaEl,
                  className: styles.content
                }, React.createElement("div", {
                      ref: viewportEl,
                      className: styles.viewport + " " + wire.tour.anchor("schedule-viewport"),
                      style: makeStyle(sizes.viewport)
                    }, React.createElement("div", {
                          className: styles.timeline,
                          style: makeStyle(sizes.timeline)
                        }, children), React.createElement("div", {
                          className: styles.gradient
                        })), React.createElement(WeekTimeline_ActiveDaySkeleton.make, {
                      show: showSkeleton
                    }), React.createElement(Optional.make, {
                      show: !showSkeleton,
                      children: React.createElement(WeekTimeline_ActiveDayButtons.make, {
                            sizes: sizes,
                            scrollSubscriptions: scrollSubscriptions
                          })
                    })));
}

var make = WeekTimeline_ActiveDayScroll;

export {
  make ,
}
/* styles Not a pure module */
