

import * as React from "react";
import * as DictionaryEdit from "./DictionaryEdit.mjs";
import * as DictionaryIndex from "./DictionaryIndex.mjs";

function Dictionary(Props) {
  var wire = Props.wire;
  var route = Props.route;
  if (typeof route !== "object" && route !== "New") {
    return React.createElement(DictionaryIndex.make, {
                wire: wire
              });
  }
  return React.createElement(DictionaryEdit.make, {
              wire: wire,
              mode: route
            });
}

var make = Dictionary;

export {
  make ,
}
/* react Not a pure module */
