

import * as El from "../../../../../../../libraries/El.mjs";
import * as Fun from "../../../../../../../libraries/Fun.mjs";
import * as Tour from "../../../../../../../libraries/tours/Tour.mjs";
import * as Uuid from "../../../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Errors from "../../../../../../../Errors.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as FormInput from "../../../../../../inputs/FormInput.mjs";
import * as AppContext from "../../../../../../../context/AppContext.mjs";
import * as ClientSelect from "../../../../../../inputs/ClientSelect.mjs";
import * as UI_ContactList from "../../../../../../../ui/UI_ContactList.mjs";
import * as JobForm_AddressBlock from "./JobForm_AddressBlock.mjs";
import * as JobForm_ClientSiteSwitch from "./JobForm_ClientSiteSwitch.mjs";

var heading$p = Utils.Translations.tr("js.jobs.labels.add_client_heading");

var organizationName$p = Utils.Translations.t("js.jobs.labels.organization_name");

var firstName$p = Utils.Translations.t("js.jobs.labels.first_name");

var middleName$p = Utils.Translations.t("js.jobs.labels.middle_name");

var lastName$p = Utils.Translations.t("js.jobs.labels.last_name");

var mobileNumber$p = Utils.Translations.t("js.jobs.labels.mobile_number");

var email$p = Utils.Translations.t("js.jobs.labels.email");

var goToSite$p = Utils.Translations.tr("js.jobs.labels.go_to_site");

var goToClient$p = Utils.Translations.tr("js.jobs.labels.go_to_client");

var siteLabel$p = Utils.Translations.tr("js.jobs.labels.addresses.site");

var clientLabel$p = Utils.Translations.tr("js.jobs.labels.addresses.client");

var neitherLabel$p = Utils.Translations.tr("js.jobs.labels.addresses.neither");

var addLink$p = Utils.Translations.tr("js.jobs.labels.add_link");

function JobForm_Client$Organization(Props) {
  var onSwitch = Props.onSwitch;
  var organization = Props.organization;
  var job = Props.job;
  var dispatch = Props.dispatch;
  var errors = Props.errors;
  var inputId = Props.inputId;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  return React.createElement("div", {
              className: "client-data client-data-add"
            }, React.createElement("span", {
                  className: "client-data-add-close-icon close",
                  onClick: (function (param) {
                      dispatch({
                            TAG: "Job",
                            _0: "RemoveClientSite"
                          });
                    })
                }, React.createElement("span", {
                      className: "fa fa-times"
                    })), React.createElement("div", {
                  className: "pd-form-group pd-form-group__huge_padding"
                }, React.createElement("span", {
                      className: "text-semibold"
                    }, heading$p(ctx))), React.createElement(JobForm_ClientSiteSwitch.make, {
                  ctx: ctx,
                  onValueChange: onSwitch,
                  value: "organization"
                }), React.createElement(FormInput.make, {
                  _type: "text",
                  value: RCore.$$Option.getOr(organization.name, ""),
                  onValueChange: (function (v) {
                      var org = {
                        name: Fun.optString(v)
                      };
                      dispatch({
                            TAG: "Job",
                            _0: {
                              TAG: "SetClientSite",
                              _0: {
                                TAG: "Client",
                                _0: {
                                  TAG: "Organization",
                                  _0: {
                                    TAG: "New",
                                    _0: org
                                  }
                                }
                              }
                            }
                          });
                    }),
                  id: "client-organization_name",
                  name: "client[organization_name]",
                  labelText: organizationName$p(ctx),
                  maxLength: 5000,
                  errors: Errors.getArray("organization_name", errors),
                  wrapperClass: Js_dict.fromList({
                        hd: [
                          "client-organization_name",
                          true
                        ],
                        tl: {
                          hd: [
                            "pd-form-group__huge_padding",
                            true
                          ],
                          tl: /* [] */0
                        }
                      })
                }), React.createElement(JobForm_AddressBlock.make, {
                  setApartment: (function (a) {
                      dispatch({
                            TAG: "Job",
                            _0: {
                              TAG: "SetApartment",
                              _0: a
                            }
                          });
                    }),
                  setFloor: (function (f) {
                      dispatch({
                            TAG: "Job",
                            _0: {
                              TAG: "SetFloor",
                              _0: f
                            }
                          });
                    }),
                  setEntranceNo: (function (e) {
                      dispatch({
                            TAG: "Job",
                            _0: {
                              TAG: "SetEntranceNo",
                              _0: e
                            }
                          });
                    }),
                  setTerritory: (function (t) {
                      dispatch({
                            TAG: "Job",
                            _0: {
                              TAG: "SetTerritoryUuid",
                              _0: t
                            }
                          });
                    }),
                  setAddressDescription: (function (a) {
                      dispatch({
                            TAG: "Job",
                            _0: {
                              TAG: "SetAddressDescription",
                              _0: a
                            }
                          });
                    }),
                  setAddress: (function (a) {
                      dispatch({
                            TAG: "Job",
                            _0: {
                              TAG: "SetAddress",
                              _0: a,
                              _1: wire
                            }
                          });
                    }),
                  inputId: inputId,
                  job: job,
                  wire: wire,
                  readOnly: false
                }), React.createElement("div", {
                  className: "pd-form-group-container__huge_padding"
                }, React.createElement(UI_ContactList.make, {
                      contacts: job.contacts,
                      addContact: (function (param) {
                          dispatch({
                                TAG: "Job",
                                _0: "AddContact"
                              });
                        }),
                      removeContact: (function (i) {
                          dispatch({
                                TAG: "Job",
                                _0: {
                                  TAG: "DeleteContact",
                                  _0: i
                                }
                              });
                        }),
                      editContact: (function (i, c) {
                          dispatch({
                                TAG: "Job",
                                _0: {
                                  TAG: "EditContact",
                                  _0: i,
                                  _1: c
                                }
                              });
                        }),
                      readOnly: false,
                      highlightPrimary: true
                    })));
}

var Organization = {
  make: JobForm_Client$Organization
};

function JobForm_Client$Individual(Props) {
  var onSwitch = Props.onSwitch;
  var client = Props.client;
  var dispatch = Props.dispatch;
  var job = Props.job;
  var errors = Props.errors;
  var inputId = Props.inputId;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = React.useMemo((function () {
          var match = job.contacts[0];
          if (match !== undefined) {
            if (match.TAG === "Phone") {
              return [
                      match._0.value,
                      mobileNumber$p(ctx)
                    ];
            } else {
              return [
                      match._0.value,
                      email$p(ctx)
                    ];
            }
          } else {
            return [
                    undefined,
                    mobileNumber$p(ctx)
                  ];
          }
        }), [job.contacts]);
  var contactValue = match[0];
  var updateContact = function (contact) {
    dispatch({
          TAG: "Job",
          _0: {
            TAG: "EditContact",
            _0: 0,
            _1: contact
          }
        });
  };
  var updateClient = function (client) {
    dispatch({
          TAG: "Job",
          _0: {
            TAG: "SetClientSite",
            _0: {
              TAG: "Client",
              _0: {
                TAG: "Individual",
                _0: {
                  TAG: "New",
                  _0: client
                }
              }
            }
          }
        });
    var name = Fun.optString(RCore.$$Array.keepSome([
                client.firstName,
                client.middleName,
                client.lastName
              ]).join(" "));
    updateContact({
          TAG: "Phone",
          _0: {
            value: contactValue,
            name: name
          }
        });
  };
  var updateMobile = function (number) {
    var name = Fun.optString(RCore.$$Array.filterMap([
                client.firstName,
                client.middleName,
                client.lastName
              ], Fun.id).join(" "));
    updateContact({
          TAG: "Phone",
          _0: {
            value: number,
            name: name
          }
        });
  };
  var tour = Tour.use(wire);
  return React.createElement("div", {
              className: "client-data client-data-add"
            }, React.createElement("span", {
                  className: "client-data-add-close-icon close",
                  onClick: (function (param) {
                      dispatch({
                            TAG: "Job",
                            _0: "RemoveClientSite"
                          });
                    })
                }, React.createElement("span", {
                      className: "fa fa-times"
                    })), React.createElement("div", {
                  className: "pd-form-group pd-form-group__huge_padding"
                }, React.createElement("span", {
                      className: "text-semibold"
                    }, heading$p(ctx))), React.createElement(JobForm_ClientSiteSwitch.make, {
                  ctx: ctx,
                  onValueChange: onSwitch,
                  value: "individual"
                }), React.createElement("div", {
                  className: "pd-form-group-container pd-form-group-container__huge_padding job-client-name"
                }, React.createElement(FormInput.make, {
                      _type: "text",
                      value: RCore.$$Option.getOr(client.firstName, ""),
                      onValueChange: (function (v) {
                          updateClient({
                                firstName: Fun.optString(v),
                                middleName: client.middleName,
                                lastName: client.lastName
                              });
                          tour.trigger({
                                NAME: "clientFirstNameUpdated",
                                VAL: Fun.optString(v)
                              });
                        }),
                      id: "client-first_name",
                      name: "client[first_name]",
                      labelText: firstName$p(ctx),
                      maxLength: 50,
                      errors: Errors.getArray("individual_name", errors),
                      wrapperClass: Js_dict.fromList({
                            hd: [
                              "client-first_name",
                              true
                            ],
                            tl: {
                              hd: [
                                "pd-form-group-half",
                                true
                              ],
                              tl: {
                                hd: [
                                  "intercom-job-add-client-name",
                                  true
                                ],
                                tl: {
                                  hd: [
                                    wire.tour.anchor("job-new-client-name"),
                                    true
                                  ],
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    }), React.createElement(FormInput.make, {
                      _type: "text",
                      value: RCore.$$Option.getOr(client.middleName, ""),
                      onValueChange: (function (v) {
                          updateClient({
                                firstName: client.firstName,
                                middleName: Fun.optString(v),
                                lastName: client.lastName
                              });
                        }),
                      id: "client-middle_name",
                      name: "client[middle_name]",
                      labelText: middleName$p(ctx),
                      maxLength: 50,
                      wrapperClass: Js_dict.fromList({
                            hd: [
                              "client-middle_name",
                              true
                            ],
                            tl: {
                              hd: [
                                "pd-form-group-half",
                                true
                              ],
                              tl: /* [] */0
                            }
                          })
                    }), React.createElement(FormInput.make, {
                      _type: "text",
                      value: RCore.$$Option.getOr(client.lastName, ""),
                      onValueChange: (function (v) {
                          updateClient({
                                firstName: client.firstName,
                                middleName: client.middleName,
                                lastName: Fun.optString(v)
                              });
                        }),
                      id: "client-last_name",
                      name: "client[last_name]",
                      labelText: lastName$p(ctx),
                      maxLength: 50,
                      wrapperClass: Js_dict.fromList({
                            hd: [
                              "client-last_name",
                              true
                            ],
                            tl: {
                              hd: [
                                "pd-form-group-half",
                                true
                              ],
                              tl: /* [] */0
                            }
                          })
                    })), React.createElement("div", {
                  className: "pd-form-group-container pd-form-group-container__huge_padding job-client-mobile-phone"
                }, React.createElement(FormInput.make, {
                      _type: "text",
                      value: RCore.$$Option.getOr(contactValue, ""),
                      onValueChange: (function (v) {
                          updateMobile(Fun.optString(v));
                          tour.trigger({
                                NAME: "clientPhoneNumberUpdated",
                                VAL: Fun.optString(v)
                              });
                        }),
                      id: "mobile_phone",
                      name: "job[contacts][][value]",
                      labelText: match[1],
                      maxLength: 50,
                      wrapperClass: Js_dict.fromList({
                            hd: [
                              "intercom-job-add-client-phone",
                              true
                            ],
                            tl: {
                              hd: [
                                wire.tour.anchor("job-new-client-phone"),
                                true
                              ],
                              tl: {
                                hd: [
                                  "pd-form-group-half",
                                  true
                                ],
                                tl: /* [] */0
                              }
                            }
                          })
                    })), React.createElement(JobForm_AddressBlock.make, {
                  setApartment: (function (a) {
                      dispatch({
                            TAG: "Job",
                            _0: {
                              TAG: "SetApartment",
                              _0: a
                            }
                          });
                    }),
                  setFloor: (function (f) {
                      dispatch({
                            TAG: "Job",
                            _0: {
                              TAG: "SetFloor",
                              _0: f
                            }
                          });
                    }),
                  setEntranceNo: (function (e) {
                      dispatch({
                            TAG: "Job",
                            _0: {
                              TAG: "SetEntranceNo",
                              _0: e
                            }
                          });
                    }),
                  setTerritory: (function (t) {
                      dispatch({
                            TAG: "Job",
                            _0: {
                              TAG: "SetTerritoryUuid",
                              _0: t
                            }
                          });
                    }),
                  setAddressDescription: (function (a) {
                      dispatch({
                            TAG: "Job",
                            _0: {
                              TAG: "SetAddressDescription",
                              _0: a
                            }
                          });
                    }),
                  setAddress: (function (a) {
                      dispatch({
                            TAG: "Job",
                            _0: {
                              TAG: "SetAddress",
                              _0: a,
                              _1: wire
                            }
                          });
                    }),
                  inputId: inputId,
                  job: job,
                  wire: wire,
                  readOnly: false
                }), React.createElement("div", {
                  className: "pd-form-group-container__huge_padding"
                }, React.createElement(UI_ContactList.make, {
                      contacts: job.contacts,
                      addContact: (function (param) {
                          dispatch({
                                TAG: "Job",
                                _0: "AddContact"
                              });
                        }),
                      removeContact: (function (i) {
                          dispatch({
                                TAG: "Job",
                                _0: {
                                  TAG: "DeleteContact",
                                  _0: i
                                }
                              });
                        }),
                      editContact: (function (i, c) {
                          dispatch({
                                TAG: "Job",
                                _0: {
                                  TAG: "EditContact",
                                  _0: i,
                                  _1: c
                                }
                              });
                        }),
                      readOnly: false,
                      isFirstContactHidden: true
                    })));
}

var Individual = {
  make: JobForm_Client$Individual
};

function JobForm_Client$Edit(Props) {
  var readOnly = Props.readOnly;
  var clientSite = Props.clientSite;
  var onAddClicked = Props.onAddClicked;
  var job = Props.job;
  var dispatch = Props.dispatch;
  var inputId = Props.inputId;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var tmp;
  var exit = 0;
  var uuid;
  if (clientSite !== undefined) {
    if (clientSite.TAG === "Client") {
      var match = clientSite._0;
      if (match.TAG === "Organization") {
        var match$1 = match._0;
        if (match$1.TAG === "Edit") {
          uuid = match$1._0.uuid;
          exit = 1;
        } else {
          tmp = null;
        }
      } else {
        var match$2 = match._0;
        if (match$2.TAG === "Edit") {
          uuid = match$2._0.uuid;
          exit = 1;
        } else {
          tmp = null;
        }
      }
    } else {
      var match$3 = clientSite._0;
      tmp = match$3.TAG === "Edit" ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "pd-form-label-action-link-container"
                }, React.createElement("a", {
                      className: "pd-form-label-action-link",
                      href: "/sites/" + Uuid.toString(match$3._0.uuid),
                      target: "blank"
                    }, goToSite$p(wire.ctx), El.nbsp, React.createElement("span", {
                          className: "fa fa-external-link-alt"
                        }))), React.createElement("label", {
                  className: "pd-label",
                  htmlFor: "client-client_uuid"
                }, siteLabel$p(wire.ctx))) : null;
    }
  } else {
    tmp = readOnly ? null : React.createElement(React.Fragment, undefined, React.createElement("div", {
                className: "pd-form-label-action-link-container"
              }, React.createElement("a", {
                    className: "pd-form-label-action-link add-client intercom-job-add-client " + wire.tour.anchor("job-form-add-client"),
                    href: "#",
                    onClick: onAddClicked
                  }, React.createElement("span", {
                        className: "fa fa-plus icon-add-model"
                      }), El.nbsp, addLink$p(ctx))), React.createElement("label", {
                className: "pd-label",
                htmlFor: "client-client_uuid"
              }, neitherLabel$p(wire.ctx)));
  }
  if (exit === 1) {
    tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: "pd-form-label-action-link-container"
            }, React.createElement("a", {
                  className: "pd-form-label-action-link",
                  href: "/clients/" + Uuid.toString(uuid),
                  target: "blank"
                }, goToClient$p(ctx), El.nbsp, React.createElement("span", {
                      className: "fa fa-external-link-alt"
                    }))), React.createElement("label", {
              className: "pd-label",
              htmlFor: "client-client_uuid"
            }, clientLabel$p(wire.ctx)));
  }
  return React.createElement("div", {
              className: "client-data"
            }, React.createElement("div", {
                  className: "pd-form-group pd-form-group__huge_padding job-client_uuid"
                }, tmp, React.createElement(ClientSelect.make, {
                      id: "client-client_uuid",
                      value: clientSite,
                      _type: "site_and_client",
                      onValueChange: (function (v) {
                          dispatch({
                                TAG: "Job",
                                _0: {
                                  TAG: "SetFoundClient",
                                  _0: v,
                                  _1: wire
                                }
                              });
                        }),
                      readOnly: readOnly
                    })), React.createElement(JobForm_AddressBlock.make, {
                  setApartment: (function (a) {
                      dispatch({
                            TAG: "Job",
                            _0: {
                              TAG: "SetApartment",
                              _0: a
                            }
                          });
                    }),
                  setFloor: (function (f) {
                      dispatch({
                            TAG: "Job",
                            _0: {
                              TAG: "SetFloor",
                              _0: f
                            }
                          });
                    }),
                  setEntranceNo: (function (e) {
                      dispatch({
                            TAG: "Job",
                            _0: {
                              TAG: "SetEntranceNo",
                              _0: e
                            }
                          });
                    }),
                  setTerritory: (function (t) {
                      dispatch({
                            TAG: "Job",
                            _0: {
                              TAG: "SetTerritoryUuid",
                              _0: t
                            }
                          });
                    }),
                  setAddressDescription: (function (a) {
                      dispatch({
                            TAG: "Job",
                            _0: {
                              TAG: "SetAddressDescription",
                              _0: a
                            }
                          });
                    }),
                  setAddress: (function (a) {
                      dispatch({
                            TAG: "Job",
                            _0: {
                              TAG: "SetAddress",
                              _0: a,
                              _1: wire
                            }
                          });
                    }),
                  inputId: inputId,
                  job: job,
                  wire: wire,
                  readOnly: readOnly
                }), React.createElement("div", {
                  className: "pd-form-group-container__huge_padding"
                }, React.createElement(UI_ContactList.make, {
                      contacts: job.contacts,
                      addContact: (function (param) {
                          dispatch({
                                TAG: "Job",
                                _0: "AddContact"
                              });
                        }),
                      removeContact: (function (i) {
                          dispatch({
                                TAG: "Job",
                                _0: {
                                  TAG: "DeleteContact",
                                  _0: i
                                }
                              });
                        }),
                      editContact: (function (i, c) {
                          dispatch({
                                TAG: "Job",
                                _0: {
                                  TAG: "EditContact",
                                  _0: i,
                                  _1: c
                                }
                              });
                        }),
                      readOnly: readOnly
                    })));
}

var Edit = {
  make: JobForm_Client$Edit
};

export {
  Organization ,
  Individual ,
  Edit ,
}
/* heading' Not a pure module */
