

import * as Wire from "../../../types/Wire.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Types from "../../../types/Types.mjs";
import * as React from "react";
import * as Locale from "../../../libraries/Locale.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Websocket from "../../../libraries/Websocket.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as ModalQuery from "../../../types/ModalQuery.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Context_Auth from "../../../context/Context_Auth.mjs";
import * as Store from "planado/schedule/store";
import * as IndexJsx from "planado/schedule/index.jsx";
import * as ScheduleOldModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/old/ScheduleOld.module.css";

function map(param) {
  return {
          TAG: "Schedule",
          _0: ModalQuery.Job.NewOptions.Schedule.make(Caml_option.nullable_to_opt(param.scheduledAt), RCore.$$Option.flatMap(Caml_option.nullable_to_opt(param.assigneePk), Types.PK.decode), Caml_option.nullable_to_opt(param.templateUuid))
        };
}

var JobOptions = {
  map: map
};

var dayDuration = Locale.T.duration(1.0, "days");

var weekDuration = Locale.T.duration(1.0, "weeks");

function usePeriod(scale) {
  var wire = AppContext.useWire();
  var match = scale === "day" ? [
      "day",
      dayDuration
    ] : (
      wire.ctx.localizator.mondayFirst ? [
          "isoWeek",
          weekDuration
        ] : [
          "week",
          weekDuration
        ]
    );
  var duration = match[1];
  var timeUnit = match[0];
  return [
          (function (__x) {
                return Locale.T.fmtM(undefined, wire)(__x);
              })((function (__x) {
                    return Locale.T.startOf(timeUnit, __x);
                  })(Locale.T.subtract(duration, Locale.T.now(wire)))),
          (function (__x) {
                return Locale.T.fmtM(undefined, wire)(__x);
              })((function (__x) {
                    return Locale.T.endOf(timeUnit, __x);
                  })(Locale.T.add(duration, Locale.T.now(wire))))
        ];
}

function use(scale) {
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = React.useState(function () {
        
      });
  var setStore = match[1];
  var period = usePeriod(scale);
  var mounted = React.useRef(true);
  React.useEffect((function () {
          var requestStartedAtMs = Date.now();
          Backend.$$finally(Backend.get(undefined, true, undefined, wire, {
                    NAME: "API",
                    VAL: {
                      NAME: "ScheduleOld",
                      VAL: period
                    }
                  }), (function (result) {
                  if (result.TAG !== "Ok") {
                    return ;
                  }
                  var data = result._0;
                  if (data === undefined) {
                    return ;
                  }
                  var diffMs = Date.now() - requestStartedAtMs;
                  if (diffMs >= 600.0 && mounted.current) {
                    return setStore(function (param) {
                                return Caml_option.some(Store.createScheduleStore({
                                                scale: scale,
                                                wire: wire,
                                                ctx: ctx,
                                                data: data
                                              }));
                              });
                  } else if (mounted.current) {
                    setTimeout((function () {
                            if (mounted.current) {
                              return setStore(function (param) {
                                          return Caml_option.some(Store.createScheduleStore({
                                                          scale: scale,
                                                          wire: wire,
                                                          ctx: ctx,
                                                          data: data
                                                        }));
                                        });
                            }
                            
                          }), 600.0 - diffMs);
                    return ;
                  } else {
                    return ;
                  }
                }));
          return (function () {
                    mounted.current = false;
                  });
        }), []);
  return match[0];
}

var styles = ScheduleOldModuleCss;

function ScheduleOld(Props) {
  var view = Props.view;
  var scale = typeof view === "object" && view.NAME === "Timeline" && view.VAL === "Week" ? "week" : "day";
  var wire = AppContext.useWire();
  var openModal = wire.openModal;
  var ctx = wire.ctx;
  var store = use(scale);
  var openJob = function (uuid) {
    Wire.openJob(undefined, undefined, wire, uuid);
  };
  var socket = Context_Auth.useSocket();
  var channel = Websocket.useChannel(socket, {
        NAME: "scheduleV1",
        VAL: ctx.tenantUuid
      });
  if (store !== undefined) {
    return React.createElement(IndexJsx.Schedule, {
                ctx: ctx,
                store: Caml_option.valFromOption(store),
                openJob: openJob,
                addJob: (function (v) {
                    var options = map(v);
                    openModal({
                          NAME: "Job",
                          VAL: {
                            NAME: "New",
                            VAL: options
                          }
                        });
                  }),
                scale: scale,
                channel: channel
              });
  } else {
    return React.createElement("div", {
                className: styles.loader
              });
  }
}

function Store_create(prim) {
  return Store.createScheduleStore(prim);
}

var Store$1 = {
  create: Store_create,
  dayDuration: dayDuration,
  weekDuration: weekDuration,
  usePeriod: usePeriod,
  requestDelayMs: 600.0,
  use: use
};

var JsComponent = {
  make: IndexJsx.Schedule
};

var make = ScheduleOld;

export {
  JobOptions ,
  Store$1 as Store,
  JsComponent ,
  make ,
}
/* dayDuration Not a pure module */
