

import * as RCore from "../../libraries/RCore.mjs";
import * as $$String from "rescript/lib/es6/string.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";

function replaceFirst(input, search, replaceWith) {
  return input.replace(search, replaceWith);
}

function replaceEach(input, search, replaceWith) {
  var __x = Core__List.fromArray(input.split(search));
  return $$String.concat(replaceWith, __x);
}

function repeat(i, str) {
  var _acc = "";
  var _i = i;
  while(true) {
    var i$1 = _i;
    var acc = _acc;
    if (i$1 <= 0) {
      return acc;
    }
    _i = i$1 - 1 | 0;
    _acc = acc + str;
    continue ;
  };
}

function matchh(str, regexp) {
  return RCore.$$Option.map((function (__x) {
                  return Js_string.match_(regexp, __x);
                })(str), RCore.$$Array.keepSome);
}

export {
  replaceFirst ,
  replaceEach ,
  repeat ,
  matchh ,
}
/* No side effect */
