

import * as Backend from "../../libraries/backend/Backend.mjs";

function sendActivationMail(wire) {
  return Backend.post(undefined, undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: "SendActivationMail"
            }, {
              NAME: "Obj",
              VAL: {}
            });
}

export {
  sendActivationMail ,
}
/* Backend Not a pure module */
