

import * as El from "../../../../../../libraries/El.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as React from "react";
import * as Optional from "../../../../../common/Optional.mjs";
import * as JobIndexFilter_Reset from "../../common/JobIndexFilter_Reset.mjs";
import * as JobIndexFilter_Wrapper from "../../common/JobIndexFilter_Wrapper.mjs";
import * as JobIndexFilter_CustomModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/index/job_index_filter/components/custom/JobIndexFilter_Custom.module.css";

var styles = JobIndexFilter_CustomModuleCss;

function buttonCN(active) {
  if (active) {
    return El.Cn.concat(styles.button, styles.active);
  } else {
    return styles.button;
  }
}

function JobIndexFilter_CustomWrapper(Props) {
  var toggleFilter = Props.toggleFilter;
  var onResetClick = Props.onResetClick;
  var renderSelected = Props.renderSelected;
  var label = Props.label;
  var show = Props.show;
  var hasValue = Props.hasValue;
  var leftButton = Props.leftButton;
  var rightButton = Props.rightButton;
  var disabled = Props.disabled;
  var children = Props.children;
  return React.createElement(JobIndexFilter_Wrapper.make, {
              toggleFilter: toggleFilter,
              show: show,
              label: label,
              children: null
            }, React.createElement(Optional.make, {
                  show: hasValue,
                  children: React.createElement(JobIndexFilter_Reset.make, {
                        onResetClick: onResetClick
                      })
                }), renderSelected, React.createElement("div", {
                  className: "j-filter__list"
                }, React.createElement("div", {
                      className: styles.container
                    }, React.createElement("div", {
                          className: styles.group
                        }, React.createElement("button", {
                              className: buttonCN(leftButton.checked),
                              disabled: disabled,
                              onClick: leftButton.onClick
                            }, leftButton.text), React.createElement("button", {
                              className: buttonCN(rightButton.checked),
                              disabled: disabled,
                              onClick: rightButton.onClick
                            }, rightButton.text)), RCore.$$Option.getOr(children, null))));
}

var make = JobIndexFilter_CustomWrapper;

export {
  make ,
}
/* styles Not a pure module */
