

import * as Link from "../../../components/common/Link/Link.mjs";
import * as Note from "../../../components/common/Note/Note.mjs";
import * as Wire from "../../../types/Wire.mjs";
import * as Hooks from "../../../libraries/hooks/Hooks.mjs";
import * as Modal from "../../../components/modal/Modal.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Runtime from "../../../Runtime.mjs";
import * as UI_Icon from "../../../ui/UI_Icon.mjs";
import * as Collapse from "../../../components/common/Collapse/Collapse.mjs";
import * as UI_Button from "../../../ui/UI_Button.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as OldFileInput from "../../../components/inputs/OldFileInput/OldFileInput.mjs";
import * as Backend_Helper from "../../../libraries/backend/Backend_Helper.mjs";
import * as MessengerTypes from "../../../components/messenger/MessengerTypes.mjs";
import * as Pages_Sites_Upload_API from "./Pages_Sites_Upload_API.mjs";
import * as Pages_Sites_Upload_FieldsDescription from "./Pages_Sites_Upload_FieldsDescription.mjs";
import * as Pages_Sites_UploadModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/sites/upload/Pages_Sites_Upload.module.css";

var pageNotFound$p = Utils.Translations.t("js.sites.site_upload.page_error");

var title$p = Utils.Translations.tr("js.sites.site_upload.title");

var description$p = Utils.Translations.tr("js.sites.site_upload.description");

var downloadExample$p = Utils.Translations.tr("js.sites.site_upload.download_example");

var fieldsDescription$p = Utils.Translations.t("js.sites.site_upload.buttons.fields_description");

var upload$p = Utils.Translations.tr("js.sites.site_upload.buttons.upload");

var ruleSingleQuotes$p = Utils.Translations.tr("js.sites.site_upload.csv_rules.use_single_quotes");

var ruleHeadersRequired$p = Utils.Translations.tr("js.sites.site_upload.csv_rules.headers_required");

var uploadInProgress$p = Utils.Translations.tr("js.sites.site_upload.upload_in_progress");

var uploadResults$p = Utils.Translations.tr("js.sites.site_upload.upload_results");

var uploadErrors$p = Utils.Translations.tr("js.sites.site_upload.upload_errors");

var internalError$p = Utils.Translations.tr("js.sites.site_upload.internal_error");

var sitesCreated$p = Utils.Translations.t("js.sites.site_upload.sites_created");

var sitesUpdated$p = Utils.Translations.t("js.sites.site_upload.sites_updated");

var labelFile$p = Utils.Translations.tr("js.sites.site_upload.labels.file");

var fileLimitMsg$p = Utils.Translations.t("js.sites.site_upload.limit_msg");

var styles = Pages_Sites_UploadModuleCss;

function Pages_Sites_Upload$Description(Props) {
  var wire = Props.wire;
  var ctx = wire.ctx;
  return React.createElement(Note.make, {
              className: styles.description,
              children: null,
              flavor: "info"
            }, React.createElement("p", undefined, description$p(ctx)), React.createElement("ul", undefined, React.createElement("li", undefined, ruleHeadersRequired$p(ctx)), React.createElement("li", undefined, ruleSingleQuotes$p(ctx)), React.createElement("li", undefined, React.createElement(Link.make, {
                          wire: wire,
                          route: {
                            NAME: "Site",
                            VAL: "CsvSample"
                          },
                          target: "self",
                          children: downloadExample$p(ctx)
                        }))), React.createElement(Collapse.make, {
                  label: fieldsDescription$p(ctx),
                  children: React.createElement(Pages_Sites_Upload_FieldsDescription.make, {
                        ctx: ctx
                      })
                }));
}

function Pages_Sites_Upload$TaskResult(Props) {
  var ctx = Props.ctx;
  var task = Props.task;
  var summary = task.summary;
  var status = task.status;
  if (typeof status !== "object") {
    return null;
  }
  if (!status._0) {
    return React.createElement(Note.make, {
                className: styles.status,
                children: null,
                flavor: "danger"
              }, React.createElement("p", undefined, uploadErrors$p(ctx)), React.createElement("ul", undefined, task.errors.map(function (e, i) {
                        return React.createElement("li", {
                                    key: i.toString()
                                  }, e);
                      })));
  }
  var created = summary.sitesCreated;
  var tmp;
  if (created !== undefined) {
    var updated = summary.sitesUpdated;
    tmp = updated !== undefined ? React.createElement("ul", undefined, React.createElement("li", undefined, sitesCreated$p(ctx) + created.toString()), React.createElement("li", undefined, sitesUpdated$p(ctx) + updated.toString())) : null;
  } else {
    tmp = null;
  }
  return React.createElement(Note.make, {
              className: styles.status,
              children: null,
              flavor: "success"
            }, React.createElement("p", undefined, uploadResults$p(ctx)), tmp);
}

function timeoutUuid(state) {
  if (state.TAG === "InProgress") {
    return Caml_option.some(state.timeout);
  }
  
}

function file(state) {
  switch (state.TAG) {
    case "FileSelected" :
    case "Posted" :
        return Caml_option.some(state._0);
    case "InProgress" :
        return Caml_option.some(state.file);
    case "Initial" :
    case "Finished" :
        return ;
    
  }
}

function locked(state) {
  switch (state.TAG) {
    case "Posted" :
    case "InProgress" :
        return true;
    default:
      return false;
  }
}

function submitLocked(state) {
  switch (state.TAG) {
    case "FileSelected" :
        return false;
    case "Finished" :
        if (state._0.TAG === "Ok") {
          return true;
        } else {
          return false;
        }
    default:
      return true;
  }
}

function requestCount(state) {
  if (state.TAG === "InProgress") {
    return state.requestCount;
  } else {
    return 0;
  }
}

function Pages_Sites_Upload$Status(Props) {
  var ctx = Props.ctx;
  var state = Props.state;
  switch (state.TAG) {
    case "Initial" :
    case "FileSelected" :
        return null;
    case "Posted" :
    case "InProgress" :
        break;
    case "Finished" :
        var task = state._0;
        if (task.TAG === "Ok") {
          return React.createElement(Pages_Sites_Upload$TaskResult, {
                      ctx: ctx,
                      task: task._0
                    });
        } else {
          return React.createElement(Note.make, {
                      className: styles.status,
                      children: React.createElement("p", undefined, internalError$p(ctx)),
                      flavor: "danger"
                    });
        }
    
  }
  return React.createElement(Note.make, {
              className: styles.status,
              children: React.createElement("p", undefined, uploadInProgress$p(ctx)),
              flavor: "info"
            });
}

function nextRequestInMs(requestCount) {
  var nextIterationTimeoutMs = 2000 + Math.imul(requestCount, 1000) | 0;
  return Math.min(nextIterationTimeoutMs, 60000);
}

function Pages_Sites_Upload(Props) {
  var wire = Props.wire;
  var match = React.useState(function () {
        return {
                TAG: "Initial",
                _0: undefined
              };
      });
  var messenger = wire.subscriptions.messenger;
  var ctx = wire.ctx;
  var setState = match[1];
  var state = match[0];
  var match$1 = ctx.permissions;
  var show = match$1 !== undefined ? match$1.clients === "ViewEdit" : false;
  var handleResponse = function (task) {
    if (task.TAG === "Ok") {
      var task$1 = task._0;
      return setState(function (current) {
                  var requestCount$1 = requestCount(current);
                  var match = task$1.status;
                  var file;
                  switch (current.TAG) {
                    case "Posted" :
                        file = current._0;
                        break;
                    case "InProgress" :
                        file = current.file;
                        break;
                    default:
                      return current;
                  }
                  if (typeof match === "object") {
                    return {
                            TAG: "Finished",
                            _0: {
                              TAG: "Ok",
                              _0: task$1
                            }
                          };
                  }
                  var timeoutUuid = Runtime.Timeout.defer(nextRequestInMs(requestCount$1), (function () {
                          var uuid = task$1.uuid;
                          Backend.$$finally(Pages_Sites_Upload_API.getTask(wire, uuid), handleResponse);
                        }));
                  return {
                          TAG: "InProgress",
                          file: file,
                          requestCount: requestCount$1 + 1 | 0,
                          timeout: timeoutUuid,
                          task: task$1
                        };
                });
    }
    setState(function (current) {
          if (current.TAG === "InProgress") {
            return {
                    TAG: "Finished",
                    _0: {
                      TAG: "Error",
                      _0: current.file
                    }
                  };
          } else {
            return current;
          }
        });
    messenger.trigger({
          TAG: "Show",
          _0: MessengerTypes.Message.make(undefined, "Danger", {
                TAG: "Text",
                _0: Utils.Translations.t("js.errors.internal_error")(ctx)
              })
        });
  };
  React.useEffect((function () {
          var match = ctx.permissions;
          if (match !== undefined && match.clients === "View") {
            Wire.closeModal(wire);
            messenger.trigger({
                  TAG: "Show",
                  _0: MessengerTypes.Message.make(undefined, "Warning", {
                        TAG: "Text",
                        _0: pageNotFound$p(ctx)
                      })
                });
          }
          
        }), []);
  React.useEffect((function () {
          var timeoutUuid$1 = timeoutUuid(state);
          if (timeoutUuid$1 === undefined) {
            return ;
          }
          var timeoutUuid$2 = Caml_option.valFromOption(timeoutUuid$1);
          return (function () {
                    Runtime.Timeout.cancel(timeoutUuid$2);
                  });
        }), [timeoutUuid(state)]);
  var submitAction = function (file, data) {
    setState(function (param) {
          return {
                  TAG: "Posted",
                  _0: file
                };
        });
    Backend.$$finally(Pages_Sites_Upload_API.postFile(wire, data), handleResponse);
  };
  var submit = function ($$event) {
    $$event.preventDefault();
    var target = $$event.target;
    var formData = Backend_Helper.$$FormData.make(target);
    switch (state.TAG) {
      case "FileSelected" :
          return submitAction(state._0, formData);
      case "Finished" :
          var file = state._0;
          if (file.TAG === "Ok") {
            return ;
          } else {
            return submitAction(file._0, formData);
          }
      default:
        return ;
    }
  };
  var onClose = function () {
    Wire.closeModal(wire);
  };
  var fileId = Hooks.useId(undefined);
  return React.createElement(Modal.make, {
              wire: wire,
              onClose: onClose,
              show: show,
              children: React.createElement("div", {
                    className: styles.modalContainer
                  }, React.createElement("div", {
                        className: styles.form
                      }, React.createElement("form", {
                            onSubmit: submit
                          }, React.createElement("div", {
                                className: styles.formHeader
                              }, React.createElement("div", undefined, title$p(ctx)), React.createElement("div", undefined, React.createElement(UI_Button.make, {
                                        flavor: "close",
                                        onClick: (function ($$event) {
                                            $$event.preventDefault();
                                            Wire.closeModal(wire);
                                          }),
                                        children: React.createElement(UI_Icon.make, {
                                              icon: "times"
                                            })
                                      }))), React.createElement("div", {
                                className: styles.formBody
                              }, React.createElement(Pages_Sites_Upload$Description, {
                                    wire: wire
                                  }), React.createElement(Pages_Sites_Upload$Status, {
                                    ctx: ctx,
                                    state: state
                                  }), React.createElement("div", {
                                    className: styles.formInput
                                  }, React.createElement("label", {
                                        className: styles.label,
                                        htmlFor: fileId
                                      }, labelFile$p(ctx)), React.createElement(OldFileInput.make, {
                                        ctx: ctx,
                                        onFileChooseAction: (function (file) {
                                            setState(function (param) {
                                                  return {
                                                          TAG: "FileSelected",
                                                          _0: file
                                                        };
                                                });
                                          }),
                                        onFileClearAction: (function () {
                                            setState(function (param) {
                                                  return {
                                                          TAG: "Initial",
                                                          _0: undefined
                                                        };
                                                });
                                          }),
                                        file: file(state),
                                        id: fileId,
                                        limit: [
                                          3145728,
                                          fileLimitMsg$p(ctx)
                                        ],
                                        disabled: locked(state)
                                      }))), React.createElement("div", {
                                className: styles.formFooter
                              }, React.createElement(UI_Button.make, {
                                    purpose: "primary",
                                    type_: "submit",
                                    disabled: submitLocked(state),
                                    children: upload$p(ctx)
                                  })))))
            });
}

var make = Pages_Sites_Upload;

export {
  make ,
}
/* pageNotFound' Not a pure module */
