

import * as El from "../../../../libraries/El.mjs";
import * as Link from "../../../../components/common/Link/Link.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as UI_Icon from "../../../../ui/UI_Icon.mjs";
import * as UI_Button from "../../../../ui/UI_Button.mjs";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as DropDownMenu from "../../../../components/common/DropDownMenu/DropDownMenu.mjs";
import * as Pages_Addresses_ButtonsModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/addresses/header/buttons/Pages_Addresses_Buttons.module.css";

var styles = Pages_Addresses_ButtonsModuleCss;

var uploadClients$p = Utils.Translations.tr("js.clients.upload.buttons.upload_clients");

var uploadSites$p = Utils.Translations.tr("js.sites.site_upload.buttons.upload_sites");

var addSite$p = Utils.Translations.tr("js.addresses.index.add_site");

var addClient$p = Utils.Translations.tr("js.addresses.index.add_client");

function Pages_Addresses_Buttons(Props) {
  var addressType = Props.addressType;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = ctx.permissions;
  if (match === undefined) {
    return null;
  }
  if (match.clients !== "ViewEdit") {
    return null;
  }
  var uploadClientsModal = addressType === "client" ? ({
        NAME: "Client",
        VAL: {
          NAME: "Upload",
          VAL: "Clients"
        }
      }) : ({
        NAME: "Site",
        VAL: {
          NAME: "Upload",
          VAL: "Clients"
        }
      });
  var uploadSitesModal = addressType === "client" ? ({
        NAME: "Client",
        VAL: {
          NAME: "Upload",
          VAL: "Sites"
        }
      }) : ({
        NAME: "Site",
        VAL: {
          NAME: "Upload",
          VAL: "Sites"
        }
      });
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "btn-group"
                }, React.createElement(DropDownMenu.make, {
                      position: "left",
                      button: (function (ref) {
                          return React.createElement(UI_Button.make, {
                                      flavor: "outline",
                                      className: styles.btnUpload,
                                      forwardRef: ref,
                                      children: React.createElement(UI_Icon.make, {
                                            className: styles.btnUploadIcon,
                                            icon: "upload"
                                          })
                                    });
                        }),
                      children: React.createElement(React.Fragment, undefined, React.createElement("li", undefined, React.createElement(Link.make, {
                                    wire: wire,
                                    modals: {
                                      hd: uploadClientsModal,
                                      tl: /* [] */0
                                    },
                                    className: styles.uploadLink,
                                    children: uploadClients$p(ctx)
                                  })), React.createElement("li", undefined, React.createElement(Link.make, {
                                    wire: wire,
                                    modals: {
                                      hd: uploadSitesModal,
                                      tl: /* [] */0
                                    },
                                    className: styles.uploadLink,
                                    children: uploadSites$p(ctx)
                                  })))
                    })), React.createElement(Link.make, {
                  wire: wire,
                  modals: {
                    hd: {
                      NAME: "Site",
                      VAL: "New"
                    },
                    tl: /* [] */0
                  },
                  className: El.Cn.concat(styles.btn, "btn btn-outline"),
                  children: React.createElement(React.Fragment, undefined, React.createElement(UI_Icon.make, {
                            className: styles.btnIcon,
                            icon: "plus"
                          }), addSite$p(ctx))
                }), React.createElement(Link.make, {
                  wire: wire,
                  modals: {
                    hd: {
                      NAME: "Client",
                      VAL: "New"
                    },
                    tl: /* [] */0
                  },
                  className: El.Cn.concat(styles.btn, "btn btn-outline"),
                  children: React.createElement(React.Fragment, undefined, React.createElement(UI_Icon.make, {
                            className: styles.btnIcon,
                            icon: "plus"
                          }), addClient$p(ctx))
                }));
}

var make = Pages_Addresses_Buttons;

export {
  make ,
}
/* styles Not a pure module */
