import { connect } from 'react-redux'
import { select } from 'planado/events'

import LocationsView from 'planado/components/map/locations_view'

const mapStateToProps = state => {
  if (state.loadingLocations || state.loadingJobs) {
    return {
      worker: state.historyWorker,
      events: select(state, ['nextLocation', 'prevLocation']),
      pending: true
    }
  } else {
    return {
      worker: state.historyWorker,
      locations: state.locations,
      locationBlocks: state.locationBlocks,
      events: select(state, ['nextLocation', 'prevLocation']),
      pending: false,
      viewingHistory: state.viewingHistory
    }
  }
}

const mapDispatchToProps = _dispatch => ({})

const LocationsLink = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationsView)

export default LocationsLink
