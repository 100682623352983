

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Types from "../../../types/Types.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import Dictionaries_show from "planado/stores/admin/dictionaries_show";
import Dictionaries_index from "planado/stores/admin/dictionaries_index";

var decode = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var ResponseData = {
  decode: decode
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              id: field.required("id", Types.Id.decode),
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              orderNo: field.required("orderNo", JsonDecode.$$int)
            };
    });

var Value = {
  decode: decode$1
};

var decode$2 = JsonDecode.object(function (field) {
      return {
              id: field.required("id", Types.Id.decode),
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              values: field.required("values", JsonDecode.array(decode$1))
            };
    });

var Dictionary = {
  Value: Value,
  decode: decode$2
};

var Index = {
  Dictionary: Dictionary
};

var StoreEdit = {
  make: (function (prim) {
      return Dictionaries_show(prim);
    })
};

var StoreIndex = {
  make: (function (prim) {
      return Dictionaries_index(prim);
    })
};

export {
  StoreEdit ,
  StoreIndex ,
  ResponseData ,
  Index ,
}
/* decode Not a pure module */
