

import * as React from "react";
import * as Locale from "../../../../../libraries/Locale.mjs";
import * as JobEdit_API from "../state/api/JobEdit_API.mjs";
import * as Shared_Lib_IO from "../../../../../shared/lib/Shared_Lib_IO.mjs";
import * as MultipleAssignee from "../../../../../types/MultipleAssignee.mjs";

function useOverlappingJobs(wire, assignees, scheduledAt, scheduledDuration, except) {
  var match = React.useState(function () {
        return [];
      });
  var setJobs = match[1];
  var workerUuids = assignees.flatMap(MultipleAssignee.allUuids);
  var duration = Locale.T.duration(scheduledDuration, "seconds");
  var to_ = Locale.T.add(duration, scheduledAt);
  var hasAssignee = workerUuids.length > 0;
  React.useEffect((function () {
          if (hasAssignee) {
            Shared_Lib_IO.unsafeRunAsync(JobEdit_API.OverlappingJobs.Get.$$fetch(wire, workerUuids, scheduledAt, to_, except)(), (function (result) {
                    if (result.TAG !== "Ok") {
                      return ;
                    }
                    var match = result._0;
                    if (match === undefined) {
                      return ;
                    }
                    if (match.TAG !== "Ok") {
                      return ;
                    }
                    var jobs = match._0;
                    setJobs(function (param) {
                          return jobs;
                        });
                  }));
          } else {
            setJobs(function (param) {
                  return [];
                });
          }
        }), [
        assignees.length,
        scheduledAt,
        scheduledDuration
      ]);
  return match[0];
}

function useOutOfShiftsWorkers(wire, assignees, scheduledAt, scheduledDuration) {
  var match = React.useState(function () {
        return [];
      });
  var setWorkers = match[1];
  var workerUuids = assignees.flatMap(MultipleAssignee.allUuids);
  var duration = Locale.T.duration(scheduledDuration, "seconds");
  var to_ = Locale.T.add(duration, scheduledAt);
  React.useEffect((function () {
          if (workerUuids.length > 0) {
            Shared_Lib_IO.unsafeRunAsync(JobEdit_API.OutOfShiftsWorkers.Get.$$fetch(wire, workerUuids, scheduledAt, to_)(), (function (result) {
                    if (result.TAG !== "Ok") {
                      return ;
                    }
                    var match = result._0;
                    if (match === undefined) {
                      return ;
                    }
                    if (match.TAG !== "Ok") {
                      return ;
                    }
                    var workers = match._0;
                    setWorkers(function (param) {
                          return workers;
                        });
                  }));
          } else {
            setWorkers(function (param) {
                  return [];
                });
          }
        }), [
        assignees.length,
        scheduledAt,
        scheduledDuration
      ]);
  return match[0];
}

export {
  useOverlappingJobs ,
  useOutOfShiftsWorkers ,
}
/* react Not a pure module */
