

import * as React from "react";
import * as Schedule_UnscheduledSkeletonModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/uscheduled_jobs/components/skeleton/Schedule_UnscheduledSkeleton.module.css";

var styles = Schedule_UnscheduledSkeletonModuleCss;

function Schedule_UnscheduledSkeleton(Props) {
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: styles.card
                }, React.createElement("div", {
                      className: styles.cardTemplate
                    }), React.createElement("div", {
                      className: styles.cardAssignee
                    }), React.createElement("div", {
                      className: styles.cardAddress
                    })), React.createElement("div", {
                  className: styles.card
                }, React.createElement("div", {
                      className: styles.cardTemplate
                    }), React.createElement("div", {
                      className: styles.cardAssignee
                    }), React.createElement("div", {
                      className: styles.cardAddress
                    })), React.createElement("div", {
                  className: styles.card
                }, React.createElement("div", {
                      className: styles.cardTemplate
                    }), React.createElement("div", {
                      className: styles.cardAssignee
                    }), React.createElement("div", {
                      className: styles.cardAddress
                    })));
}

var make = Schedule_UnscheduledSkeleton;

export {
  make ,
}
/* styles Not a pure module */
