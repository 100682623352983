

import * as React from "react";
import * as ShiftsShowForm from "./ShiftsShowForm.mjs";

function isTeam(owner) {
  if (owner.TAG === "Team") {
    return true;
  } else {
    return false;
  }
}

function ShiftsShow(Props) {
  var owner = Props.owner;
  var shift = Props.shift;
  var selectedDay = Props.selectedDay;
  var jobTypes = Props.jobTypes;
  var territories = Props.territories;
  var popoverRef = Props.popoverRef;
  var submitShift = Props.submitShift;
  var locked = Props.locked;
  var close = Props.close;
  return React.createElement(ShiftsShowForm.make, {
              selectedDay: selectedDay,
              shift: shift,
              workerTerritoryUuid: owner._0.territoryUuid,
              jobTypes: jobTypes,
              territories: territories,
              submitShift: submitShift,
              locked: locked,
              popoverRef: popoverRef,
              close: close,
              isTeam: isTeam(owner)
            });
}

var Owner = {};

var make = ShiftsShow;

export {
  Owner ,
  make ,
}
/* react Not a pure module */
