import React from 'react'

const SortOptions = {
  NONE: 0,
  DESC: -1,
  ASC: 1,

  _transitions: {
    '-1': 1, // DESC => ASC
    '1': -1, // ASC => DESC
    '0': -1 // NONE => DESC
  },

  getNext: function(from) {
    return this._transitions[from]
  }
}

class SortableHeading extends React.Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(e) {
    e.preventDefault()
    this.props.callback(SortOptions.getNext(this.props.order))
  }

  get arrowClass() {
    switch (this.props.order) {
      case SortOptions.DESC:
        return 'fa fa-caret-down'
      case SortOptions.ASC:
        return 'fa fa-caret-up'
      default:
        return 'fa fa-caret-down unsorted'
    }
  }

  render() {
    return (
      <th className="sortable-heading" onClick={this.handleClick}>
        {this.props.text} <span className={this.arrowClass} />
      </th>
    )
  }
}

SortableHeading.defaultProps = {
  order: SortOptions.NONE
}

export { SortableHeading, SortOptions }
