

import * as Uuid from "../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../types/Types.mjs";
import * as Locale from "../../../../../libraries/Locale.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as Distance from "../../../../../libraries/Distance.mjs";
import * as JsContext from "../../../../../libraries/JsContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsonDecode from "../../../../../libraries/JsonDecode.mjs";
import * as Types_Date from "../../../../../types/Types_Date.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Float from "@rescript/core/lib/es6/src/Core__Float.mjs";
import * as Types_Address from "../../../../../types/Types_Address.mjs";
import * as Types_Catalog from "../../../../../types/Types_Catalog.mjs";
import * as Types_Contact from "../../../../../types/Types_Contact.mjs";
import * as Types_Territory from "../../../../../types/Types_Territory.mjs";
import * as MultipleAssignee from "../../../../../types/MultipleAssignee.mjs";
import * as AddressInput_Types from "../../../../address_input/AddressInput_Types.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              text: field.required("text", JsonDecode.string),
              url: RCore.$$Option.flatten(field.optional("url", JsonDecode.option(JsonDecode.string)))
            };
    });

function fromString(status) {
  switch (status) {
    case "canceled" :
        return "Canceled";
    case "en_route" :
        return "EnRoute";
    case "finished" :
        return "Finished";
    case "posted" :
        return "Posted";
    case "published" :
        return "Published";
    case "scheduled" :
        return "Scheduled";
    case "started" :
        return "Started";
    case "suspended" :
        return "Suspended";
    default:
      return ;
  }
}

function toString(status) {
  switch (status) {
    case "Posted" :
        return "posted";
    case "Scheduled" :
        return "scheduled";
    case "Published" :
        return "published";
    case "EnRoute" :
        return "en_route";
    case "Started" :
        return "started";
    case "Suspended" :
        return "suspended";
    case "Finished" :
        return "finished";
    case "Canceled" :
        return "canceled";
    
  }
}

var decode$1 = JsonDecode.fromVariant(JsonDecode.string, fromString);

var decode$2 = JsonDecode.object(function (field) {
      return {
              resolutionUuid: field.required("resolutionUuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              isSuccessful: field.required("isSuccessful", JsonDecode.bool),
              hasComment: field.required("hasComment", JsonDecode.bool),
              isCommentRequired: field.required("isCommentRequired", JsonDecode.bool),
              isSystem: field.required("isSystem", JsonDecode.bool)
            };
    });

function uuid(l) {
  return l.uuid;
}

var decode$3 = JsonDecode.object(function (field) {
      var uuid = field.required("uuid", Uuid.decode);
      if (field.required("default", JsonDecode.bool)) {
        return {
                TAG: "Default",
                uuid: uuid
              };
      } else {
        return {
                TAG: "Custom",
                uuid: uuid,
                name: field.required("name", JsonDecode.string)
              };
      }
    });

var PrintLayout = {
  uuid: uuid,
  decode: decode$3
};

var decode$4 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              serialNo: field.required("serialNo", JsonDecode.$$int),
              template: RCore.$$Option.flatten(field.optional("template", JsonDecode.option(JsonDecode.string))),
              assignee: RCore.$$Option.flatten(field.optional("assignee", JsonDecode.option(JsonDecode.string))),
              scheduledAt: RCore.$$Option.flatten(field.optional("scheduledAt", JsonDecode.option(Types_Date.decode))),
              status: field.required("status", decode$1),
              resolutionName: RCore.$$Option.flatten(field.optional("resolutionName", JsonDecode.option(JsonDecode.string))),
              resolutionSuccessful: RCore.$$Option.flatten(field.optional("resolutionSuccessful", JsonDecode.option(JsonDecode.bool)))
            };
    });

var decode$5 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              serialNo: field.required("serialNo", JsonDecode.$$int),
              template: RCore.$$Option.flatten(field.optional("template", JsonDecode.option(JsonDecode.string))),
              assignee: RCore.$$Option.flatten(field.optional("assignee", JsonDecode.option(JsonDecode.string))),
              scheduledAt: RCore.$$Option.flatten(field.optional("scheduledAt", JsonDecode.option(Types_Date.decode))),
              status: field.required("status", decode$1),
              resolutionName: RCore.$$Option.flatten(field.optional("resolutionName", JsonDecode.option(JsonDecode.string))),
              resolutionSuccessful: RCore.$$Option.flatten(field.optional("resolutionSuccessful", JsonDecode.option(JsonDecode.bool))),
              completionOfUuid: field.required("completionOfUuid", Uuid.decode)
            };
    });

var Assignee = {};

var Team = {};

var AssigneesData = {};

function uuids(assignees) {
  return Belt_Array.concatMany([
              assignees.workers.map(function (w) {
                    return w.uuid;
                  }),
              assignees.teams.flatMap(function (t) {
                    return Belt_Array.concatMany([
                                RCore.$$Option.getOr(RCore.$$Option.map(t.foreman, (function (f) {
                                            return [f.uuid];
                                          })), []),
                                t.workers.map(function (teamWorker) {
                                      return teamWorker.uuid;
                                    })
                              ]);
                  })
            ]);
}

var Assignees = {
  Assignee: Assignee,
  Team: Team,
  AssigneesData: AssigneesData,
  uuids: uuids
};

function url(attachment) {
  return RCore.$$Option.getOr(attachment.url, "");
}

var decode$6 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              url: field.required("url", JsonDecode.option(JsonDecode.string)),
              previewUrl: field.required("previewUrl", JsonDecode.option(JsonDecode.string)),
              size: field.required("size", JsonDecode.option(JsonDecode.$$int)),
              width: field.required("width", JsonDecode.option(JsonDecode.$$int)),
              height: field.required("height", JsonDecode.option(JsonDecode.$$int))
            };
    });

var Attachment = {
  url: url,
  decode: decode$6
};

function make(uuid, name) {
  return {
          uuid: uuid,
          name: name
        };
}

var decode$7 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var DictionaryValue = {
  make: make,
  decode: decode$7
};

function decode$8(field) {
  return {
          dictionaryValues: field.required("dictionaryValues", JsonDecode.array(decode$7)),
          value: field.required("value", JsonDecode.option(Uuid.decode))
        };
}

var Dictionary = {
  decode: decode$8
};

function fromString$1(status) {
  switch (status) {
    case "active" :
        return "Active";
    case "error" :
        return "Error";
    case "released" :
        return "Released";
    case "triggered" :
        return "Triggered";
    default:
      return ;
  }
}

var decode$9 = JsonDecode.fromVariant(JsonDecode.string, fromString$1);

var Status = {
  fromString: fromString$1,
  decode: decode$9
};

function decode$10(field) {
  return {
          status: field.required("buttonState", JsonDecode.option(JsonDecode.object(function (field) {
                        return field.required("status", decode$9);
                      }))),
          reusableButton: field.required("reusableButton", JsonDecode.bool),
          urlValue: field.required("urlValue", JsonDecode.string)
        };
}

var Button = {
  Status: Status,
  decode: decode$10
};

function decode$11(field) {
  return {
          currency: field.required("currency", JsonDecode.string),
          useCurrencyFractionalUnit: field.required("useCurrencyFractionalUnit", JsonDecode.bool),
          value: field.required("value", JsonDecode.option(JsonDecode.string))
        };
}

var Currency = {
  decode: decode$11
};

var Input = {};

function decode$12(field) {
  return {
          attachments: field.required("value", JsonDecode.array(decode$6)),
          signedFieldTypeUuid: field.required("signedFieldTypeUuid", Uuid.decode)
        };
}

var Signature = {
  decode: decode$12
};

function decode$13(wire, field) {
  var unknown = field.required("fieldType", JsonDecode.string);
  switch (unknown) {
    case "action" :
        return {
                TAG: "Action",
                _0: field.required("value", JsonDecode.bool)
              };
    case "barcode" :
        return {
                TAG: "Barcode",
                _0: RCore.$$Option.getOr(field.required("value", JsonDecode.option(JsonDecode.string)), "")
              };
    case "button" :
        return {
                TAG: "Button",
                _0: decode$10(field)
              };
    case "checkbox" :
        return {
                TAG: "Checkbox",
                _0: field.required("value", JsonDecode.option(JsonDecode.bool))
              };
    case "currency" :
        return {
                TAG: "Currency",
                _0: decode$11(field)
              };
    case "date_picker" :
        return {
                TAG: "Date",
                _0: field.required("value", JsonDecode.option(Types_Date.decode))
              };
    case "datetime_picker" :
        var value = field.required("value", JsonDecode.option(JsonDecode.map(JsonDecode.string, (function (v) {
                        return Locale.T.toDateTime(Locale.T.make(v), wire);
                      }))));
        return {
                TAG: "DateTime",
                _0: RCore.$$Option.getOr(value, Types.Time.DateTime.empty)
              };
    case "dictionary" :
        return {
                TAG: "Dictionary",
                _0: decode$8(field)
              };
    case "file" :
        return {
                TAG: "File",
                _0: field.required("value", JsonDecode.array(decode$6))
              };
    case "image" :
        return {
                TAG: "Image",
                _0: field.required("value", JsonDecode.array(decode$6))
              };
    case "input" :
        var dataType = field.required("dataType", JsonDecode.string);
        var value$1 = RCore.$$Option.getOr(field.required("value", JsonDecode.option(JsonDecode.string)), "");
        switch (dataType) {
          case "decimal" :
              return {
                      TAG: "Input",
                      _0: {
                        NAME: "Decimal",
                        VAL: value$1
                      }
                    };
          case "integer" :
              return {
                      TAG: "Input",
                      _0: {
                        NAME: "Integer",
                        VAL: value$1
                      }
                    };
          case "string" :
              return {
                      TAG: "Input",
                      _0: {
                        NAME: "String",
                        VAL: value$1
                      }
                    };
          default:
            return JsonDecode.cannotDecode("Unknown input data type: " + dataType);
        }
    case "link" :
        return {
                TAG: "Link",
                _0: RCore.$$Option.getOr(field.required("value", JsonDecode.option(JsonDecode.string)), "")
              };
    case "signature" :
        return {
                TAG: "Signature",
                _0: decode$12(field)
              };
    case "time_picker" :
        return {
                TAG: "Time",
                _0: field.required("value", JsonDecode.option(JsonDecode.map(JsonDecode.string, Locale.T.parse("TimeSeconds", JsContext.get(wire)))))
              };
    default:
      return JsonDecode.cannotDecode("Unknown report field type: " + unknown);
  }
}

var ReportFieldValue = {
  decode: decode$13
};

function decode$14(wire, field) {
  var button = decode$13(wire, field);
  switch (button.TAG) {
    case "Action" :
        return JsonDecode.cannotDecode("Action is not supported for custom fields");
    case "Barcode" :
        return JsonDecode.cannotDecode("Barcode is not supported for custom fields");
    case "Button" :
        return {
                TAG: "Button",
                _0: button._0
              };
    case "Checkbox" :
        return JsonDecode.cannotDecode("Checkbox is not supported for custom fields");
    case "Currency" :
        return {
                TAG: "Currency",
                _0: button._0
              };
    case "Date" :
        return {
                TAG: "Date",
                _0: button._0
              };
    case "DateTime" :
        return {
                TAG: "DateTime",
                _0: button._0
              };
    case "Dictionary" :
        return {
                TAG: "Dictionary",
                _0: button._0
              };
    case "File" :
        return {
                TAG: "File",
                _0: button._0
              };
    case "Image" :
        return JsonDecode.cannotDecode("Image is not supported for custom fields");
    case "Input" :
        return {
                TAG: "Input",
                _0: button._0
              };
    case "Link" :
        return {
                TAG: "Link",
                _0: button._0
              };
    case "Signature" :
        return JsonDecode.cannotDecode("Signature is not supported for custom fields");
    case "Time" :
        return {
                TAG: "Time",
                _0: button._0
              };
    
  }
}

var CustomFieldValue = {
  decode: decode$14
};

function decode$15(wire) {
  return JsonDecode.object(function (field) {
              return {
                      typeUuid: field.required("typeUuid", Uuid.decode),
                      name: field.required("name", JsonDecode.string),
                      value: decode$13(wire, field)
                    };
            });
}

var ReportField = {
  Attachment: Attachment,
  DictionaryValue: DictionaryValue,
  Dictionary: Dictionary,
  Button: Button,
  Currency: Currency,
  Input: Input,
  Signature: Signature,
  ReportFieldValue: ReportFieldValue,
  CustomFieldValue: CustomFieldValue,
  decode: decode$15
};

function decode$16(wire) {
  return JsonDecode.object(function (field) {
              return {
                      typeUuid: field.required("typeUuid", Uuid.decode),
                      name: field.required("name", JsonDecode.string),
                      value: decode$14(wire, field),
                      readOnly: field.required("readOnly", JsonDecode.bool),
                      required: field.required("required", JsonDecode.bool)
                    };
            });
}

var CustomField = {
  Attachment: Attachment,
  DictionaryValue: DictionaryValue,
  Dictionary: Dictionary,
  Button: Button,
  Currency: Currency,
  Input: Input,
  Signature: Signature,
  ReportFieldValue: ReportFieldValue,
  CustomFieldValue: CustomFieldValue,
  decode: decode$16
};

var decode$17 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var decode$18 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

function decode$19(field) {
  return {
          uuid: field.required("uuid", Uuid.decode),
          name: field.required("name", JsonDecode.option(JsonDecode.string))
        };
}

var Edit = {
  decode: decode$19
};

function make$1(name) {
  return name;
}

function empty() {
  return {
          name: undefined
        };
}

var New = {
  make: make$1,
  empty: empty
};

function decode$20(field) {
  return {
          TAG: "Edit",
          _0: decode$19(field)
        };
}

var Organization = {
  Edit: Edit,
  New: New,
  decode: decode$20
};

function make$2(uuid, name, firstName, middleName, lastName) {
  return {
          uuid: uuid,
          name: name,
          firstName: firstName,
          middleName: middleName,
          lastName: lastName
        };
}

function decode$21(field) {
  return {
          uuid: field.required("uuid", Uuid.decode),
          name: field.required("name", JsonDecode.option(JsonDecode.string)),
          firstName: field.required("firstName", JsonDecode.option(JsonDecode.string)),
          middleName: field.required("middleName", JsonDecode.option(JsonDecode.string)),
          lastName: field.required("lastName", JsonDecode.option(JsonDecode.string))
        };
}

var Edit$1 = {
  make: make$2,
  decode: decode$21
};

function make$3(firstName, middleName, lastName) {
  return {
          firstName: firstName,
          middleName: middleName,
          lastName: lastName
        };
}

function empty$1() {
  return {
          firstName: undefined,
          middleName: undefined,
          lastName: undefined
        };
}

var New$1 = {
  make: make$3,
  empty: empty$1
};

function decode$22(field) {
  return {
          TAG: "Edit",
          _0: decode$21(field)
        };
}

var Individual = {
  Edit: Edit$1,
  New: New$1,
  decode: decode$22
};

function decode$23(field) {
  if (field.required("organization", JsonDecode.bool)) {
    return {
            TAG: "Organization",
            _0: {
              TAG: "Edit",
              _0: decode$19(field)
            }
          };
  } else {
    return {
            TAG: "Individual",
            _0: {
              TAG: "Edit",
              _0: decode$21(field)
            }
          };
  }
}

var Client = {
  Organization: Organization,
  Individual: Individual,
  decode: decode$23
};

function make$4(uuid, name, description, client) {
  return {
          uuid: uuid,
          name: name,
          description: description,
          client: client
        };
}

function decode$24(field) {
  return {
          uuid: field.required("uuid", Uuid.decode),
          name: field.required("name", JsonDecode.option(JsonDecode.string)),
          description: field.required("description", JsonDecode.option(JsonDecode.string)),
          client: field.required("client", JsonDecode.option(JsonDecode.object(decode$19)))
        };
}

var Edit$2 = {
  make: make$4,
  decode: decode$24
};

function make$5(name, description, client) {
  return {
          name: name,
          description: description,
          client: client
        };
}

function empty$2() {
  return {
          name: undefined,
          description: undefined,
          client: undefined
        };
}

var New$2 = {
  make: make$5,
  empty: empty$2
};

function decode$25(field) {
  return {
          TAG: "Edit",
          _0: {
            uuid: field.required("uuid", Uuid.decode),
            name: field.required("name", JsonDecode.option(JsonDecode.string)),
            description: field.required("description", JsonDecode.option(JsonDecode.string)),
            client: field.required("client", JsonDecode.option(JsonDecode.object(decode$19)))
          }
        };
}

var Site = {
  Edit: Edit$2,
  New: New$2,
  decode: decode$25
};

var decode$26 = JsonDecode.object(function (field) {
      var unknown = field.required("type", JsonDecode.string);
      switch (unknown) {
        case "client" :
            return {
                    TAG: "Client",
                    _0: decode$23(field)
                  };
        case "site" :
            return {
                    TAG: "Site",
                    _0: decode$25(field)
                  };
        default:
          return JsonDecode.cannotDecode("Unknown client site type: " + unknown);
      }
    });

var decode$27 = JsonDecode.object(function (field) {
      return {
              foremanUuid: RCore.$$Option.flatten(field.optional("foremanUuid", JsonDecode.option(Uuid.decode))),
              id: field.required("id", Types.Id.decodeOpaque),
              name: field.required("name", JsonDecode.string),
              skills: field.required("skills", JsonDecode.array(decode$18)),
              territory: field.required("territory", JsonDecode.option(Types_Territory.decode)),
              type_: field.required("type", JsonDecode.string),
              uuid: field.required("uuid", Uuid.decode)
            };
    });

function makeJs(assignee) {
  return {
          foremanUuid: Js_null.fromOption(assignee.foremanUuid),
          id: assignee.id,
          name: assignee.name,
          skills: assignee.skills,
          territory: Js_null.fromOption(assignee.territory),
          type_: assignee.type_,
          uuid: assignee.uuid
        };
}

function makeFromJs(assignee) {
  return {
          foremanUuid: Caml_option.null_to_opt(assignee.foremanUuid),
          id: assignee.id,
          name: assignee.name,
          skills: assignee.skills,
          territory: Caml_option.null_to_opt(assignee.territory),
          type_: assignee.type_,
          uuid: assignee.uuid
        };
}

var decode$28 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var decode$29 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              description: field.required("description", JsonDecode.option(JsonDecode.string)),
              priceDefault: RCore.$$Option.flatMap(field.required("priceDefault", JsonDecode.option(JsonDecode.map(JsonDecode.string, (function (s) {
                                  return Core__Float.fromString(s);
                                })))), (function (x) {
                      return x;
                    })),
              currency: field.required("currency", JsonDecode.string),
              category: field.required("category", JsonDecode.option(decode$28))
            };
    });

function toServiceItem(availableService) {
  var category = RCore.$$Option.map(availableService.category, (function (c) {
          return Types_Catalog.Service.Category.make(c.uuid, c.name);
        }));
  return Types_Catalog.Service.make(availableService.uuid, availableService.name, availableService.description, availableService.priceDefault, availableService.currency, category, false);
}

function make$6(uuid, name, description, price, quantity, amount, currency, category, archived) {
  return {
          uuid: uuid,
          name: name,
          description: description,
          price: price,
          quantity: quantity,
          amount: amount,
          currency: currency,
          category: category,
          archived: archived
        };
}

function fromAvailableService(availableService) {
  return make$6(availableService.uuid, availableService.name, availableService.description, availableService.priceDefault, 1, availableService.priceDefault, availableService.currency, RCore.$$Option.map(availableService.category, (function (c) {
                    return Types_Catalog.Service.Category.make(c.uuid, c.name);
                  })), false);
}

function fromService(service) {
  return make$6(service.uuid, service.name, service.description, service.priceDefault, 1, service.priceDefault, service.currency, service.category, false);
}

var decode$30 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              description: field.required("description", JsonDecode.option(JsonDecode.string)),
              price: RCore.$$Option.flatMap(field.required("price", JsonDecode.option(JsonDecode.map(JsonDecode.string, (function (s) {
                                  return Core__Float.fromString(s);
                                })))), (function (x) {
                      return x;
                    })),
              quantity: field.required("quantity", JsonDecode.$$int),
              amount: RCore.$$Option.flatMap(field.required("amount", JsonDecode.option(JsonDecode.map(JsonDecode.string, (function (s) {
                                  return Core__Float.fromString(s);
                                })))), (function (x) {
                      return x;
                    })),
              currency: field.required("currency", JsonDecode.string),
              category: field.required("category", JsonDecode.option(Types_Catalog.Service.Category.decode)),
              archived: field.required("archived", JsonDecode.bool)
            };
    });

var ProvidedService = {
  Category: undefined,
  make: make$6,
  fromAvailableService: fromAvailableService,
  fromService: fromService,
  decode: decode$30
};

var make$7 = Types_Catalog.OrderedService.make;

function fromAvailableService$1(availableService) {
  return make$7(availableService.uuid, false);
}

var decode$31 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              required: field.required("required", JsonDecode.bool)
            };
    });

var OrderedService = {
  make: make$7,
  fromAvailableService: fromAvailableService$1,
  decode: decode$31
};

function decode$32(wire) {
  return JsonDecode.flatMap(JsonDecode.custom(function (json) {
                  return json;
                }), (function (json) {
                return JsonDecode.object(function (field) {
                            var job_uuid = RCore.$$Option.flatten(field.optional("jobUuid", JsonDecode.option(Uuid.decode)));
                            var job_serialNo = RCore.$$Option.flatten(field.optional("serialNo", JsonDecode.option(JsonDecode.$$int)));
                            var job_externalId = field.required("externalId", JsonDecode.option(JsonDecode.string));
                            var job_templateUuid = RCore.$$Option.flatten(field.optional("templateUuid", JsonDecode.option(Uuid.decode)));
                            var job_isNewRecord = field.required("isNewRecord", JsonDecode.bool);
                            var job_status = field.required("status", decode$1);
                            var job_copiedFromUuid = RCore.$$Option.flatten(field.optional("copiedFromUuid", JsonDecode.option(Uuid.decode)));
                            var job_copiedFromSerialNo = RCore.$$Option.flatten(field.optional("copiedFromSerialNo", JsonDecode.option(JsonDecode.$$int)));
                            var job_externalLink = RCore.$$Option.flatten(field.optional("externalLink", JsonDecode.option(decode)));
                            var job_viewedAt = RCore.$$Option.flatten(field.optional("viewedAt", JsonDecode.option(JsonDecode.string)));
                            var job_enRouteAt = RCore.$$Option.flatten(field.optional("enRouteAt", JsonDecode.option(JsonDecode.string)));
                            var job_startedAt = RCore.$$Option.flatten(field.optional("startedAt", JsonDecode.option(JsonDecode.string)));
                            var job_suspendedAt = RCore.$$Option.flatten(field.optional("suspendedAt", JsonDecode.option(JsonDecode.string)));
                            var job_finishedAt = RCore.$$Option.flatten(field.optional("finishedAt", JsonDecode.option(JsonDecode.string)));
                            var job_possibleResolutions = field.required("possibleResolutions", JsonDecode.array(decode$2));
                            var job_resolution = RCore.$$Option.flatten(field.optional("resolution", JsonDecode.option(decode$2)));
                            var job_resolutionComment = RCore.$$Option.flatten(field.optional("resolutionComment", JsonDecode.option(JsonDecode.string)));
                            var job_createdBy = RCore.$$Option.flatten(field.optional("createdBy", JsonDecode.option(JsonDecode.string)));
                            var job_createdAt = RCore.$$Option.flatten(field.optional("createdAt", JsonDecode.option(Types_Date.decode)));
                            var job_enRouteDurationMin = RCore.$$Option.flatten(field.optional("enRouteDurationMin", JsonDecode.option(JsonDecode.$$int)));
                            var job_workDurationMin = RCore.$$Option.flatten(field.optional("workDurationMin", JsonDecode.option(JsonDecode.$$int)));
                            var job_suspensionDurationMin = RCore.$$Option.flatten(field.optional("suspensionDurationMin", JsonDecode.option(JsonDecode.$$int)));
                            var job_actualDurationMin = RCore.$$Option.flatten(field.optional("actualDurationMin", JsonDecode.option(JsonDecode.$$int)));
                            var job_totalTraveledDistance = RCore.$$Option.flatten(field.optional("totalTraveledDistanceM", JsonDecode.option(Distance.Distance.decode(wire.ctx))));
                            var job_initialJobUuid = RCore.$$Option.flatten(field.optional("initialJobUuid", JsonDecode.option(Uuid.decode)));
                            var job_completionOfUuid = RCore.$$Option.flatten(field.optional("completionOfUuid", JsonDecode.option(Uuid.decode)));
                            var job_nextJobs = RCore.$$Option.flatten(field.optional("nextJobs", JsonDecode.option(JsonDecode.array(decode$4))));
                            var job_completionJobs = RCore.$$Option.flatten(field.optional("completionJobs", JsonDecode.option(JsonDecode.array(decode$5))));
                            var job_printLayouts = field.required("printLayouts", JsonDecode.array(decode$3));
                            var job_assignees = field.required("assignees", MultipleAssignee.decode);
                            var job_fallbackTeamName = field.required("fallbackTeamName", JsonDecode.option(JsonDecode.string));
                            var job_reportFields = field.required("reportFields", JsonDecode.array(decode$15(wire)));
                            var job_trackMovements = RCore.$$Option.getOr(field.required("trackMovements", JsonDecode.option(JsonDecode.bool)), false);
                            var job_odometerM = field.required("odometerM", JsonDecode.option(JsonDecode.$$float));
                            var job_template = field.required("template", JsonDecode.option(JsonDecode.string));
                            var job_typeUuid = field.required("typeUuid", JsonDecode.option(Uuid.decode));
                            var job_jobTypes = field.required("jobTypes", JsonDecode.array(decode$17));
                            var job_possibleAssignees = field.required("possibleAssignees", JsonDecode.array(decode$27));
                            var job_skillUuids = field.required("skillUuids", JsonDecode.array(Uuid.decode));
                            var job_territoryUuid = field.required("territoryUuid", JsonDecode.option(Uuid.decode));
                            var job_scheduledAt = field.required("scheduledAt", JsonDecode.option(Types_Date.decode));
                            var job_scheduledDuration = field.required("scheduledDuration", JsonDecode.$$int);
                            var job_maxScheduledDuration = field.required("maxScheduledDuration", JsonDecode.$$int);
                            var job_customFields = field.required("customFields", JsonDecode.array(decode$16(wire)));
                            var job_clientSite = field.required("clientSite", JsonDecode.option(decode$26));
                            var job_description = field.required("description", JsonDecode.option(JsonDecode.string));
                            var job_contacts = field.required("contacts", JsonDecode.array(Types_Contact.decode));
                            var job_availableTerritories = field.required("availableTerritories", JsonDecode.array(Types_Territory.decode));
                            var job_address = field.required("address", JsonDecode.option(Types_Address.decode));
                            var job_apartment = field.required("apartment", JsonDecode.option(JsonDecode.string));
                            var job_floor = field.required("floor", JsonDecode.option(JsonDecode.string));
                            var job_entranceNo = field.required("entranceNo", JsonDecode.option(JsonDecode.string));
                            var job_addressDescription = field.required("addressDescription", JsonDecode.option(JsonDecode.string));
                            var job_addressConstraints = field.required("addressConstraints", AddressInput_Types.AddressConstraint.decode);
                            var job_availableServices = field.required("availableServices", JsonDecode.array(decode$29));
                            var job_orderedServices = field.required("orderedServices", JsonDecode.array(decode$31));
                            var job_providedServices = field.required("providedServices", JsonDecode.array(decode$30));
                            var job = {
                              uuid: job_uuid,
                              serialNo: job_serialNo,
                              externalId: job_externalId,
                              templateUuid: job_templateUuid,
                              isNewRecord: job_isNewRecord,
                              status: job_status,
                              copiedFromUuid: job_copiedFromUuid,
                              copiedFromSerialNo: job_copiedFromSerialNo,
                              externalLink: job_externalLink,
                              viewedAt: job_viewedAt,
                              enRouteAt: job_enRouteAt,
                              startedAt: job_startedAt,
                              suspendedAt: job_suspendedAt,
                              finishedAt: job_finishedAt,
                              possibleResolutions: job_possibleResolutions,
                              resolution: job_resolution,
                              resolutionComment: job_resolutionComment,
                              createdBy: job_createdBy,
                              createdAt: job_createdAt,
                              enRouteDurationMin: job_enRouteDurationMin,
                              workDurationMin: job_workDurationMin,
                              suspensionDurationMin: job_suspensionDurationMin,
                              actualDurationMin: job_actualDurationMin,
                              totalTraveledDistance: job_totalTraveledDistance,
                              initialJobUuid: job_initialJobUuid,
                              completionOfUuid: job_completionOfUuid,
                              nextJobs: job_nextJobs,
                              completionJobs: job_completionJobs,
                              printLayouts: job_printLayouts,
                              foremanError: false,
                              assignees: job_assignees,
                              fallbackTeamName: job_fallbackTeamName,
                              reportFields: job_reportFields,
                              trackMovements: job_trackMovements,
                              odometerM: job_odometerM,
                              template: job_template,
                              typeUuid: job_typeUuid,
                              jobTypes: job_jobTypes,
                              possibleAssignees: job_possibleAssignees,
                              skillUuids: job_skillUuids,
                              territoryUuid: job_territoryUuid,
                              scheduledAt: job_scheduledAt,
                              scheduledDuration: job_scheduledDuration,
                              maxScheduledDuration: job_maxScheduledDuration,
                              customFields: job_customFields,
                              clientSite: job_clientSite,
                              description: job_description,
                              contacts: job_contacts,
                              availableTerritories: job_availableTerritories,
                              address: job_address,
                              apartment: job_apartment,
                              floor: job_floor,
                              entranceNo: job_entranceNo,
                              addressDescription: job_addressDescription,
                              addressConstraints: job_addressConstraints,
                              availableServices: job_availableServices,
                              orderedServices: job_orderedServices,
                              providedServices: job_providedServices
                            };
                            return [
                                    job,
                                    json
                                  ];
                          });
              }));
}

var ExternalLink = {};

var Status$1 = {
  toString: toString
};

var Resolution = {};

var NextJob = {};

var CompletionJob = {};

var Field_Dictionary = {};

var Field_Currency = {};

var Field_Signature = {};

var Field_ReportFieldValue = {};

var Field_CustomFieldValue = {};

var Field = {
  Attachment: Attachment,
  DictionaryValue: DictionaryValue,
  Dictionary: Field_Dictionary,
  Button: Button,
  Currency: Field_Currency,
  Input: Input,
  Signature: Field_Signature,
  ReportFieldValue: Field_ReportFieldValue,
  CustomFieldValue: Field_CustomFieldValue
};

var JobType = {};

var Skill = {
  fromMultipleAssigneeSkills: (function (prim) {
      return prim;
    })
};

var ClientSite = {};

var PossibleAssignee = {
  makeJs: makeJs,
  makeFromJs: makeFromJs
};

var AvailableService_Category = {};

var AvailableService = {
  Category: AvailableService_Category,
  toServiceItem: toServiceItem
};

export {
  ExternalLink ,
  Status$1 as Status,
  Resolution ,
  PrintLayout ,
  NextJob ,
  CompletionJob ,
  Assignees ,
  Field ,
  ReportField ,
  CustomField ,
  JobType ,
  Skill ,
  Client ,
  Site ,
  ClientSite ,
  PossibleAssignee ,
  AvailableService ,
  ProvidedService ,
  OrderedService ,
  decode$32 as decode,
}
/* decode Not a pure module */
