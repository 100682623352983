

import * as Types from "../../../../../../types/Types.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as Schedule_Types from "../../../../lib/types/Schedule_Types.mjs";
import * as Schedule_Types_Job from "../../../../lib/types/job/Schedule_Types_Job.mjs";

function use() {
  var scrollAreaEl = Types.ReactRef.use();
  var viewportEl = Types.ReactRef.use();
  var sidebarEl = Types.ReactRef.use();
  return {
          scrollAreaEl: scrollAreaEl,
          viewportEl: viewportEl,
          sidebarEl: sidebarEl
        };
}

var DomElements = {
  use: use
};

var Sizes = {};

function start(position) {
  return Units.Px.toInt(position.left);
}

function finish(position) {
  return Units.Px.toInt(Units.Px.plus(position.left, position.width));
}

var AssignedPosition = {
  start: start,
  finish: finish
};

var IntervalPosition = {};

function start$1(position) {
  return Units.Px.toInt(position.left);
}

function finish$1(position) {
  return Units.Px.toInt(Units.Px.plus(position.left, position.width));
}

var UnassignedPosition = {
  start: start$1,
  finish: finish$1
};

function mapJobStatus(status, job) {
  var match = Schedule_Types_Job.AssignedJob.status(job);
  var exit = 0;
  var exit$1 = 0;
  var exit$2 = 0;
  if (status !== undefined) {
    switch (status) {
      case "Posted" :
          return "Posted";
      case "FinishedSuccessful" :
          switch (match) {
            case "Started" :
            case "Suspended" :
                exit = 2;
                break;
            case "Canceled" :
                return "FinishedUnsuccessful";
            case "Finished" :
                var match$1 = Schedule_Types_Job.AssignedJob.resolution(job);
                if (match$1 !== undefined && match$1.successful) {
                  return "FinishedSuccessful";
                } else {
                  return "FinishedUnsuccessful";
                }
            default:
              exit$2 = 4;
          }
          break;
      default:
        exit$2 = 4;
    }
  } else {
    switch (match) {
      case "EnRoute" :
          return "EnRoute";
      case "Started" :
          return "Started";
      case "Suspended" :
          return "Suspended";
      case "Canceled" :
          return "FinishedUnsuccessful";
      case "Finished" :
          var match$2 = Schedule_Types_Job.AssignedJob.resolution(job);
          if (match$2 !== undefined && match$2.successful) {
            return "FinishedSuccessful";
          } else {
            return "FinishedUnsuccessful";
          }
      default:
        return "Posted";
    }
  }
  if (exit$2 === 4) {
    switch (match) {
      case "EnRoute" :
          return "EnRoute";
      case "Started" :
      case "Suspended" :
      case "Canceled" :
      case "Finished" :
          exit$1 = 3;
          break;
      default:
        return "Posted";
    }
  }
  if (exit$1 === 3) {
    switch (status) {
      case "EnRoute" :
          return "EnRoute";
      case "Started" :
          return "Started";
      case "Suspended" :
      case "FinishedUnsuccessful" :
          exit = 2;
          break;
      
    }
  }
  if (exit === 2) {
    switch (match) {
      case "Started" :
          return "Started";
      case "Suspended" :
          return "Suspended";
      case "Canceled" :
      case "Finished" :
          break;
      
    }
  }
  if (status === "Suspended") {
    return "Suspended";
  } else {
    return "FinishedUnsuccessful";
  }
}

var BlockStatus = {
  mapJobStatus: mapJobStatus
};

var ActiveDay = {};

var Time = Schedule_Types.Time;

var ReactRef = Schedule_Types.ReactRef;

var JobCore = Schedule_Types.JobCore;

var AssignedJob = Schedule_Types.AssignedJob;

var UnassignedJob = Schedule_Types.UnassignedJob;

var ScheduledJob = Schedule_Types.ScheduledJob;

var BarAssignee = Schedule_Types.BarAssignee;

var DraggableJob = Schedule_Types.DraggableJob;

var JobsAfter = Schedule_Types.JobsAfter;

var Filter = Schedule_Types.Filter;

var Interval = Schedule_Types.Interval;

var Bar = Schedule_Types.Bar;

var Bars = Schedule_Types.Bars;

var TimelineSubscriptions = Schedule_Types.TimelineSubscriptions;

var JobSize = Schedule_Types.JobSize;

var Assignee = Schedule_Types.Assignee;

var Assignees = Schedule_Types.Assignees;

export {
  Time ,
  ReactRef ,
  JobCore ,
  AssignedJob ,
  UnassignedJob ,
  ScheduledJob ,
  BarAssignee ,
  DraggableJob ,
  JobsAfter ,
  Filter ,
  Interval ,
  Bar ,
  Bars ,
  TimelineSubscriptions ,
  JobSize ,
  Assignee ,
  Assignees ,
  DomElements ,
  Sizes ,
  AssignedPosition ,
  IntervalPosition ,
  UnassignedPosition ,
  BlockStatus ,
  ActiveDay ,
}
/* Types Not a pure module */
