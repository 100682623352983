import React from 'react'
import classNames from 'classnames'
import {
  JobInfoLink,
  RecurringInfoLink,
} from 'planado/containers/admin/recurring_jobs_show'
import { recurringJobsPath } from 'planado/routes.js'
import { validateForm, formHasError } from './validators'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import Link from 'rscrpt/components/common/Link/Link.mjs'
import RelatedEntities from 'rscrpt/components/common/RelatedEntities/RelatedEntities.mjs'
import {
  ListType,
  PageType,
} from 'rscrpt/components/common/RelatedEntities/RelatedEntitiesTypes.mjs'
import * as styles from './styles.module.css'

const DeletePopover = withContext(({ ctx, onClose, onDelete }) => (
  <div className="form-footer__delete-popup">
    <h5>{ctx.t('js.recurring_jobs.popovers.confirm_delete.prompt')}</h5>

    <div>
      <button className="btn btn-default" type="button" onClick={onClose}>
        {ctx.t('js.recurring_jobs.popovers.confirm_delete.cancel')}
      </button>

      <button
        className="btn btn-danger button-remove_client"
        type="button"
        onClick={(evt) => {
          evt.preventDefault()
          onDelete()
        }}
      >
        {ctx.t('js.recurring_jobs.popovers.confirm_delete.delete')}
      </button>
    </div>
  </div>
))

const DeleteButton = withContext(
  ({ ctx, actions: { showPopover, closePopover }, deleteRecurringJob }) => {
    const buttonId = 'recurring-job-show-remove-button'

    const onClick = (evt) => {
      evt.preventDefault()
      showPopover(
        <DeletePopover onClose={closePopover} onDelete={deleteRecurringJob} />,
        ctx.dom,
        evt,
        buttonId
      )
    }

    return (
      <div className="form-footer__delete">
        <button
          className="btn btn-default btn-danger"
          onClick={onClick}
          id={buttonId}
          type="button"
        >
          {ctx.t('js.recurring_jobs.buttons.delete')}
        </button>
      </div>
    )
  }
)

const Header = withContext(({ ctx, template, isNew }) => (
  <header className="form-header">
    <h1 className="form-header__title">
      {isNew
        ? ctx.t('js.recurring_jobs.heading.add')
        : ctx.t('js.recurring_jobs.heading.edit')}

      <span className="form-header__template label label-info">
        <span className="fa fa-copy" />
        {` ${template}`}
      </span>
    </h1>

    <Link
      wire={ctx.wire}
      pathname={recurringJobsPath}
      className="form-header__close close"
    >
      <span className="fa fa-times" />
    </Link>
  </header>
))

const Footer = withContext(
  ({
    ctx,
    isNew,
    errors,
    job,
    actions,
    onSubmitRecurringJob,
    deleteRecurringJob,
    locked,
    foremanError,
  }) => {
    const sendForm = (evt) => {
      evt.preventDefault()

      const updatedErrors = validateForm({ ctx, errors, job, actions })

      if (!formHasError(updatedErrors)) {
        actions.sendForm(ctx, onSubmitRecurringJob)
      }
    }

    const classes = classNames({
      'form-footer': true,
      'form-footer_create': isNew,
    })

    return (
      <div className={classes}>
        {isNew ? null : (
          <DeleteButton
            actions={actions}
            deleteRecurringJob={() => deleteRecurringJob(job.uuid)}
          />
        )}

        <div>
          <Link
            wire={ctx.wire}
            className="btn btn-default"
            pathname={recurringJobsPath}
          >
            {ctx.t('js.recurring_jobs.buttons.cancel')}
          </Link>

          <button
            className="btn btn-primary btn-submit"
            onClick={sendForm}
            type="submit"
            disabled={locked || foremanError}
          >
            {isNew
              ? ctx.t('js.recurring_jobs.buttons.submit_create')
              : ctx.t('js.recurring_jobs.buttons.submit_edit')}
          </button>
        </div>
      </div>
    )
  }
)

export default withContext(
  ({
    isNew,
    job,
    errors,
    template,
    actions,
    wire,
    ctx,
    onSubmitRecurringJob,
    deleteRecurringJob,
    locked,
    foremanError,
  }) => {
    return (
      <>
        <form className="form form_recurring-job">
          <Header isNew={isNew} template={template} />
          <JobInfoLink wire={wire} ctx={ctx} />
          <RecurringInfoLink ctx={ctx} wire={wire} />
          <Footer
            isNew={isNew}
            job={job}
            actions={actions}
            errors={errors}
            onSubmitRecurringJob={onSubmitRecurringJob}
            deleteRecurringJob={deleteRecurringJob}
            locked={locked}
            foremanError={foremanError}
          />
        </form>
        {isNew ? null : (
          <div className={styles.relatedEntities}>
            <RelatedEntities
              uuid={job.uuid}
              listType={ListType.fromString('jobs_and_rec')}
              pageType={PageType.fromString('recurring_job')}
              wire={wire}
            />
          </div>
        )}
      </>
    )
  }
)
