

import * as Wire from "../../../types/Wire.mjs";
import * as Modal from "../../../components/modal/Modal.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Title from "../../../components/common/Title.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Errors from "../../../Errors.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Optional from "../../../components/common/Optional.mjs";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as MessengerTypes from "../../../components/messenger/MessengerTypes.mjs";
import * as RelatedEntities from "../../../components/common/RelatedEntities/RelatedEntities.mjs";
import * as Pages_Client_API from "./Pages_Client_API.mjs";
import * as Pages_Client_Body from "./Pages_Client_Body.mjs";
import * as Pages_Client_Types from "./Pages_Client_Types.mjs";
import * as Pages_Client_Footer from "./Pages_Client_Footer.mjs";
import * as Pages_Client_Heading from "./Pages_Client_Heading.mjs";
import * as Pages_ClientModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/clients/edit/Pages_Client.module.css";

var styles = Pages_ClientModuleCss;

var created$p = Utils.Translations.t("js.clients.messages.created");

var destroyed$p = Utils.Translations.t("js.clients.messages.destroyed");

var updated$p = Utils.Translations.t("js.clients.messages.updated");

function showErrorNotification(messenger, ctx) {
  messenger.trigger({
        TAG: "Show",
        _0: MessengerTypes.Message.make(undefined, "Danger", {
              TAG: "Text",
              _0: Utils.Translations.t("js.errors.internal_error")(ctx)
            })
      });
}

function Pages_Client(Props) {
  var wire = Props.wire;
  var subRoute = Props.subRoute;
  var messenger = wire.subscriptions.messenger;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        
      });
  var setStateOptional = match[1];
  var state = match[0];
  var setState = function (fun) {
    setStateOptional(function (current) {
          return RCore.$$Option.map(current, fun);
        });
  };
  var handleResponse = function (response) {
    if (response.TAG !== "Ok") {
      return showErrorNotification(messenger, ctx);
    }
    var data = response._0;
    setStateOptional(function (param) {
          return data;
        });
  };
  React.useEffect((function () {
          Backend.$$finally(Pages_Client_API.Get.send(wire, subRoute), handleResponse);
        }), []);
  var onClose = function () {
    Wire.closeModal(wire);
  };
  var unlock = function () {
    setState(function (current) {
          return {
                  limits: current.limits,
                  canManage: current.canManage,
                  locked: false,
                  client: current.client,
                  addressConstraints: current.addressConstraints,
                  errors: current.errors
                };
        });
  };
  var onSubmitted = function () {
    Wire.closeModal(wire);
    wire.subscriptions.clients.trigger("ClientSubmitted");
  };
  var onDestroy = function () {
    if (state === undefined) {
      return ;
    }
    var uuid = state.client.uuid;
    if (uuid !== undefined) {
      setState(function (current) {
            return {
                    limits: current.limits,
                    canManage: current.canManage,
                    locked: true,
                    client: current.client,
                    addressConstraints: current.addressConstraints,
                    errors: []
                  };
          });
      Backend.$$finally(Pages_Client_API.Delete.send(wire, Caml_option.valFromOption(uuid)), (function (r) {
              if (r.TAG === "Ok") {
                onSubmitted();
                return messenger.trigger({
                            TAG: "Show",
                            _0: MessengerTypes.Message.make(undefined, undefined, {
                                  TAG: "Text",
                                  _0: destroyed$p(ctx)
                                })
                          });
              }
              var match = r._0;
              if (typeof match === "object" && match.TAG === "HTTPError") {
                var match$1 = match._0;
                var tmp = match$1[0];
                if (typeof tmp !== "object" && tmp === "UnprocessableEntity") {
                  var text = match$1[1];
                  return setState(function (current) {
                              return {
                                      limits: current.limits,
                                      canManage: current.canManage,
                                      locked: false,
                                      client: current.client,
                                      addressConstraints: current.addressConstraints,
                                      errors: RCore.$$Array.filterMap(Core__List.toArray(Errors.parse(text)), Pages_Client_Types.$$Error.fromString)
                                    };
                            });
                }
                
              }
              showErrorNotification(messenger, ctx);
              unlock();
            }));
      return ;
    }
    
  };
  var onUpdate = function (evt) {
    evt.preventDefault();
    if (state === undefined) {
      return ;
    }
    var client = state.client;
    var uuid = client.uuid;
    if (uuid !== undefined) {
      setState(function (current) {
            return {
                    limits: current.limits,
                    canManage: current.canManage,
                    locked: true,
                    client: current.client,
                    addressConstraints: current.addressConstraints,
                    errors: []
                  };
          });
      Backend.$$finally(Pages_Client_API.Patch.send(wire, Caml_option.valFromOption(uuid), client), (function (r) {
              if (r.TAG === "Ok") {
                onSubmitted();
                return messenger.trigger({
                            TAG: "Show",
                            _0: MessengerTypes.Message.make(undefined, undefined, {
                                  TAG: "Text",
                                  _0: updated$p(ctx)
                                })
                          });
              }
              var match = r._0;
              if (typeof match === "object" && match.TAG === "HTTPError") {
                var match$1 = match._0;
                var tmp = match$1[0];
                if (typeof tmp !== "object" && tmp === "UnprocessableEntity") {
                  var text = match$1[1];
                  return setState(function (current) {
                              return {
                                      limits: current.limits,
                                      canManage: current.canManage,
                                      locked: false,
                                      client: current.client,
                                      addressConstraints: current.addressConstraints,
                                      errors: RCore.$$Array.filterMap(Core__List.toArray(Errors.parse(text)), Pages_Client_Types.$$Error.fromString)
                                    };
                            });
                }
                
              }
              showErrorNotification(messenger, ctx);
              unlock();
            }));
      return ;
    }
    
  };
  var onCreate = function (evt) {
    evt.preventDefault();
    if (state !== undefined) {
      setState(function (current) {
            return {
                    limits: current.limits,
                    canManage: current.canManage,
                    locked: true,
                    client: current.client,
                    addressConstraints: current.addressConstraints,
                    errors: []
                  };
          });
      Backend.$$finally(Pages_Client_API.Post.send(wire, state.client), (function (r) {
              if (r.TAG === "Ok") {
                onSubmitted();
                return messenger.trigger({
                            TAG: "Show",
                            _0: MessengerTypes.Message.make(undefined, undefined, {
                                  TAG: "Text",
                                  _0: created$p(ctx)
                                })
                          });
              }
              var match = r._0;
              if (typeof match === "object" && match.TAG === "HTTPError") {
                var match$1 = match._0;
                var tmp = match$1[0];
                if (typeof tmp !== "object" && tmp === "UnprocessableEntity") {
                  var text = match$1[1];
                  return setState(function (current) {
                              return {
                                      limits: current.limits,
                                      canManage: current.canManage,
                                      locked: false,
                                      client: current.client,
                                      addressConstraints: current.addressConstraints,
                                      errors: RCore.$$Array.filterMap(Core__List.toArray(Errors.parse(text)), Pages_Client_Types.$$Error.fromString)
                                    };
                            });
                }
                
              }
              showErrorNotification(messenger, ctx);
              unlock();
            }));
      return ;
    }
    
  };
  if (state === undefined) {
    return null;
  }
  var tmp = {
    pageType: "Client",
    listType: state.client.organization ? "JobsSitesAndRec" : "JobsAndRec",
    compact: true,
    wire: wire
  };
  if (state.client.uuid !== undefined) {
    tmp.uuid = Caml_option.valFromOption(state.client.uuid);
  }
  return React.createElement(Modal.make, {
              wire: wire,
              onClose: onClose,
              show: true,
              children: null
            }, React.createElement(Title.make, {
                  route: wire.route,
                  ctx: ctx,
                  values: {
                    name: state.client.name
                  },
                  wire: wire
                }), React.createElement("form", {
                  className: styles.wrapper,
                  onSubmit: state.client.isNewRecord ? onCreate : onUpdate
                }, React.createElement("div", {
                      className: styles.header
                    }, React.createElement(Pages_Client_Heading.make, {
                          state: state,
                          setState: setState
                        })), React.createElement("div", {
                      className: styles.body
                    }, React.createElement("div", {
                          className: styles.container
                        }, React.createElement(Pages_Client_Body.make, {
                              state: state,
                              setState: setState
                            }), React.createElement(Optional.make, {
                              show: !state.client.isNewRecord,
                              children: React.createElement("div", {
                                    className: styles.relatedEntities
                                  }, React.createElement(RelatedEntities.make, tmp))
                            }))), React.createElement("div", {
                      className: styles.footer
                    }, React.createElement(Pages_Client_Footer.make, {
                          state: state,
                          onDestroy: onDestroy
                        }))));
}

var make = Pages_Client;

export {
  make ,
}
/* styles Not a pure module */
