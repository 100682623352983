

import * as Wire from "../../../types/Wire.mjs";
import * as Modal from "../../modal/Modal.mjs";
import * as React from "react";
import * as JobEdit from "./JobEdit.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as JobEdit_API from "./lib/state/api/JobEdit_API.mjs";
import * as Shared_Lib_IO from "../../../shared/lib/Shared_Lib_IO.mjs";

function JobModal(Props) {
  var subroute = Props.subroute;
  var wire = AppContext.useWire();
  var match = React.useState(function () {
        
      });
  var setJob = match[1];
  var job = match[0];
  React.useEffect((function () {
          if (job === undefined) {
            Shared_Lib_IO.unsafeRunAsync(Shared_Lib_IO.bimap(JobEdit_API.Job.Get.$$fetch(wire, subroute), (function (result) {
                        if (result.TAG !== "Ok") {
                          return ;
                        }
                        var job = result._0;
                        setJob(function (param) {
                              return job;
                            });
                      }), (function (prim) {
                        
                      })), (function (prim) {
                    
                  }));
          }
          
        }), []);
  if (job !== undefined) {
    return React.createElement(Modal.make, {
                wire: wire,
                onClose: (function () {
                    Wire.closeModal(wire);
                  }),
                children: React.createElement(JobEdit.make, {
                      subroute: subroute,
                      job: job[0]
                    })
              });
  } else {
    return null;
  }
}

var make = JobModal;

export {
  make ,
}
/* Wire Not a pure module */
