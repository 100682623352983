

import * as RCore from "../../../../../../../../../libraries/RCore.mjs";
import * as React from "react";
import * as Locale from "../../../../../../../../../libraries/Locale.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as JsContext from "../../../../../../../../../libraries/JsContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import Date_picker from "planado/components/input/datetime/date_picker";

var Limits = {};

function JobForm_ReportFieldDatePicker$DatePickerJS(Props) {
  var id = Props.id;
  var date = Props.date;
  var wrapperClass = Props.wrapperClass;
  var limits = Props.limits;
  var calendarPlacement = Props.calendarPlacement;
  var disabledOpt = Props.disabled;
  var onChange = Props.onChange;
  var wire = Props.wire;
  var errors = Props.errors;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var onValueChange = function (newDate) {
    var value = RCore.$$Option.flatMap(RCore.$$Option.map(newDate === null ? undefined : Caml_option.some(newDate), Locale.T.parse("Date", JsContext.get(wire))), (function (moment) {
            if (Locale.T.isValid(moment)) {
              return Caml_option.some(moment);
            }
            
          }));
    onChange(value);
  };
  return React.createElement(Date_picker, {
              id: id,
              date: Js_null.fromOption(date),
              dateToDisplay: Js_null.fromOption(RCore.$$Option.map(date, Locale.T.fmtM("Date", wire))),
              calendarPlacement: calendarPlacement,
              disabled: disabled,
              onChange: onValueChange,
              wrapperClass: Js_null.fromOption(wrapperClass),
              limits: Js_null.fromOption(limits),
              errors: errors
            });
}

function JobForm_ReportFieldDatePicker(Props) {
  var id = Props.id;
  var date = Props.date;
  var wrapperClass = Props.wrapperClass;
  var limits = Props.limits;
  var onChange = Props.onChange;
  var calendarPlacementOpt = Props.calendarPlacement;
  var disabledOpt = Props.disabled;
  var wire = Props.wire;
  var errorsOpt = Props.errors;
  var calendarPlacement = calendarPlacementOpt !== undefined ? calendarPlacementOpt : "top";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var errors = errorsOpt !== undefined ? errorsOpt : [];
  return React.createElement(JobForm_ReportFieldDatePicker$DatePickerJS, {
              id: id,
              date: date,
              wrapperClass: wrapperClass,
              limits: limits,
              calendarPlacement: calendarPlacement,
              disabled: disabled,
              onChange: onChange,
              wire: wire,
              errors: errors
            });
}

var make = JobForm_ReportFieldDatePicker;

export {
  Limits ,
  make ,
}
/* react Not a pure module */
