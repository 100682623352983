import React from 'react'
import { Provider } from 'react-redux'
import { FormLink } from 'planado/containers/admin/recurring_jobs_show'
import FooterLink from 'planado/containers/footer_link'

export const Form = ({
  store,
  wire,
  onSubmitRecurringJob,
  deleteRecurringJob
}) => (
  <Provider store={store}>
    <FormLink
      wire={wire}
      onSubmitRecurringJob={onSubmitRecurringJob}
      deleteRecurringJob={deleteRecurringJob}
    />
  </Provider>
)

export const Footer = props => (
  <Provider {...props}>
    <FooterLink />
  </Provider>
)
