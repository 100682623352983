

import * as Fun from "../../libraries/Fun.mjs";
import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as Modal from "../modal/Modal.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../libraries/Locale.mjs";
import * as Select from "../inputs/Select.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as UI_Icon from "../../ui/UI_Icon.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as MomentRe from "../../bindings/moment/MomentRe.mjs";
import * as Optional from "../common/Optional.mjs";
import * as FormInput from "../inputs/FormInput.mjs";
import * as UI_Button from "../../ui/UI_Button.mjs";
import * as AppContext from "../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as MultiSelect from "../inputs/MultiSelect.mjs";
import * as SkillsSelect from "../inputs/SkillsSelect.mjs";
import * as Context_Types from "../../context/Context_Types.mjs";
import * as Backend_Errors from "../../libraries/backend/Backend_Errors.mjs";
import * as TerritoriesSelect from "../inputs/TerritoriesSelect.mjs";
import * as TemporaryWorkerAPI from "./temporary_worker/TemporaryWorkerAPI.mjs";
import * as TemporaryWorkerForm from "./temporary_worker/TemporaryWorkerForm.mjs";
import * as MultipleAssignees_Team from "../common/MultipleAssignees/MultipleAssignees_Team.mjs";
import * as TeamModuleCss from "/home/runner/work/planado/planado/client/rescript/components/team/Team.module.css";

var styles = TeamModuleCss;

var name$p = Utils.Translations.t("js.teams.show.name");

var foreman$p = Utils.Translations.tr("js.teams.show.foreman");

var workers$p = Utils.Translations.tr("js.teams.show.workers");

var temporaryWorkers$p = Utils.Translations.tr("js.teams.show.temporary_workers");

var skills$p = Utils.Translations.tr("js.teams.show.skills");

var territory$p = Utils.Translations.tr("js.teams.show.territory");

var description$p = Utils.Translations.t("js.teams.show.description");

var useWorkersSkills$p = Utils.Translations.tr("js.teams.show.use_workers_skills");

function tooManyWorkers$p(ctx) {
  return Utils.Translations.tx("js.teams.errors.too_many_workers")({
              max: 10
            }, ctx);
}

var placeholder$p = Utils.Translations.t("js.components.select.placeholder");

var noResults$p = Utils.Translations.t("js.components.select.no_results");

var chooseSkillsForTeam$p = Utils.Translations.t("js.components.skills_select.placeholder");

var chooseTerritoryForTeam$p = Utils.Translations.t("js.components.territories_select.placeholder");

var addTempWorker$p = Utils.Translations.tr("js.shifts.index.add_temp_worker");

var until$p = Utils.Translations.t("js.teams.show.until");

function TeamForm(Props) {
  var team = Props.team;
  var mode = Props.mode;
  var errors = Props.errors;
  var foremen = Props.foremen;
  var workers = Props.workers;
  var availableTerritories = Props.availableTerritories;
  var onUpdate = Props.onUpdate;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return team.foremanUuid;
      });
  var setForemanUuid = match[1];
  var currentForemanUuid = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setAddingTempWorkerTeamUuid = match$1[1];
  var match$2 = React.useState(function () {
        return TemporaryWorkerAPI.Post.$$Response.Errors.emptyErrors;
      });
  var onTempWorkerModalClose = function () {
    setAddingTempWorkerTeamUuid(function (param) {
          return false;
        });
  };
  var isExist = function (uuid, uuids) {
    return uuids.some(function (uuid$p) {
                return Caml_obj.equal(uuid$p, uuid);
              });
  };
  var match$3 = ctx.permissions;
  var hasPermissions = match$3 !== undefined ? match$3.teams === "ViewEdit" : false;
  var makeUniqueUuidsInArray = function (uuids) {
    return RCore.$$Array.reduce(uuids, [], (function (acc, uuid) {
                  if (isExist(uuid, acc)) {
                    return acc;
                  } else {
                    return Belt_Array.concatMany([
                                acc,
                                [uuid]
                              ]);
                  }
                }));
  };
  var mergeSkillUuids = function (currentUuids, newUuids) {
    var filteredUuids = RCore.$$Array.keep(newUuids, (function (uuid) {
            return !isExist(uuid, currentUuids);
          }));
    return Belt_Array.concatMany([
                currentUuids,
                filteredUuids
              ]);
  };
  var takeForemanSkillUuids = function (foremanUuid) {
    if (foremanUuid === undefined) {
      return [];
    }
    var uuid = Caml_option.valFromOption(foremanUuid);
    return foremen.flatMap(function (f) {
                if (Caml_obj.equal(f.uuid, uuid)) {
                  return f.skillUuids;
                } else {
                  return [];
                }
              });
  };
  var takeWorkersSkillUuids = function (workerUuids) {
    var isSelected = function (uuid) {
      return workerUuids.some(function (workerUuid) {
                  return Caml_obj.equal(uuid, workerUuid);
                });
    };
    return makeUniqueUuidsInArray(workers.flatMap(function (w) {
                    if (isSelected(w.uuid)) {
                      return w.skillUuids;
                    } else {
                      return [];
                    }
                  }));
  };
  var takeSkillsFromMembers = function () {
    var foremanSkillUuids = takeForemanSkillUuids(team.foremanUuid);
    var workersSkillUuids = takeWorkersSkillUuids(team.workerUuids);
    var tempWorkersSkills = takeWorkersSkillUuids(team.temporaryWorkers.map(function (w) {
              return w.userUuid;
            }));
    return makeUniqueUuidsInArray(Belt_Array.concatMany([
                    foremanSkillUuids,
                    workersSkillUuids,
                    tempWorkersSkills
                  ]));
  };
  var onChangeForeman = function (id) {
    var uuid = Js_null.bind(id, (function (prim) {
            return prim;
          }));
    var foremanUuid = uuid === null ? undefined : Caml_option.some(uuid);
    var foremanSkillUuids = takeForemanSkillUuids(foremanUuid);
    setForemanUuid(function (param) {
          return foremanUuid;
        });
    onUpdate({
          uuid: team.uuid,
          name: team.name,
          foremanUuid: foremanUuid,
          workerUuids: RCore.$$Array.filterMap(team.workerUuids, (function (workerUuid) {
                  if (uuid !== null && Caml_obj.equal(uuid, workerUuid)) {
                    return ;
                  } else {
                    return Caml_option.some(workerUuid);
                  }
                })),
          description: team.description,
          skillUuids: mergeSkillUuids(team.skillUuids, foremanSkillUuids),
          territoryUuid: team.territoryUuid,
          temporaryWorkers: team.temporaryWorkers
        });
  };
  var onTempWorkerAdd = function (tempWorkerState) {
    var userUuid = tempWorkerState.userUuid;
    if (userUuid === undefined) {
      return ;
    }
    var from = tempWorkerState.dateFrom;
    if (from === undefined) {
      return ;
    }
    var to = tempWorkerState.dateTo;
    if (to === undefined) {
      return ;
    }
    var userUuid$1 = Caml_option.valFromOption(userUuid);
    var skillUuids = mergeSkillUuids(team.skillUuids, takeWorkersSkillUuids([userUuid$1]));
    onUpdate({
          uuid: team.uuid,
          name: team.name,
          foremanUuid: team.foremanUuid,
          workerUuids: team.workerUuids,
          description: team.description,
          skillUuids: skillUuids,
          territoryUuid: team.territoryUuid,
          temporaryWorkers: team.temporaryWorkers.concat([{
                  uuid: undefined,
                  userUuid: userUuid$1,
                  dateFrom: Caml_option.valFromOption(from),
                  dateTo: Caml_option.valFromOption(to)
                }])
        });
    setAddingTempWorkerTeamUuid(function (param) {
          return false;
        });
  };
  var onChangeWorkers = function (newWorkerId) {
    var newWorkerUuids = newWorkerId.map(function (prim) {
          return prim;
        });
    if (newWorkerUuids.length <= team.workerUuids.length) {
      return onUpdate({
                  uuid: team.uuid,
                  name: team.name,
                  foremanUuid: team.foremanUuid,
                  workerUuids: newWorkerUuids,
                  description: team.description,
                  skillUuids: team.skillUuids,
                  territoryUuid: team.territoryUuid,
                  temporaryWorkers: team.temporaryWorkers
                });
    }
    var workersSkillUuids = takeWorkersSkillUuids(newWorkerUuids);
    var newTeamSkillUuids = mergeSkillUuids(team.skillUuids, workersSkillUuids);
    onUpdate({
          uuid: team.uuid,
          name: team.name,
          foremanUuid: team.foremanUuid,
          workerUuids: newWorkerUuids,
          description: team.description,
          skillUuids: newTeamSkillUuids,
          territoryUuid: team.territoryUuid,
          temporaryWorkers: team.temporaryWorkers
        });
  };
  var today = Locale.T.startOf("day", Locale.T.now(wire));
  var onTempWorkerDelete = function (worker) {
    var skillUuids = mergeSkillUuids(team.skillUuids, takeWorkersSkillUuids([worker.uuid]));
    onUpdate({
          uuid: team.uuid,
          name: team.name,
          foremanUuid: team.foremanUuid,
          workerUuids: team.workerUuids,
          description: team.description,
          skillUuids: skillUuids,
          territoryUuid: team.territoryUuid,
          temporaryWorkers: RCore.$$Array.keepMap(team.temporaryWorkers, (function (w) {
                  if (Caml_obj.notequal(w.userUuid, worker.uuid) || !Locale.T.equalDay(w.dateFrom, MomentRe.makeFromJsDate(worker.dateFrom)) || !Locale.T.equalDay(w.dateTo, MomentRe.makeFromJsDate(worker.dateTo))) {
                    return w;
                  } else if (Locale.T.equalOrLessThan(today, w.dateTo) && Locale.T.equalOrLessThan(w.dateFrom, today)) {
                    return {
                            uuid: w.uuid,
                            userUuid: w.userUuid,
                            dateFrom: w.dateFrom,
                            dateTo: today
                          };
                  } else {
                    return ;
                  }
                }))
        });
  };
  var onClickTakeSkills = function (evt) {
    evt.preventDefault();
    onUpdate({
          uuid: team.uuid,
          name: team.name,
          foremanUuid: team.foremanUuid,
          workerUuids: team.workerUuids,
          description: team.description,
          skillUuids: takeSkillsFromMembers(),
          territoryUuid: team.territoryUuid,
          temporaryWorkers: team.temporaryWorkers
        });
  };
  var membersSkillsCount = takeSkillsFromMembers().length;
  var showTakeSkillsButton = membersSkillsCount > 0;
  var selectedWorkers = RCore.$$Array.filterMap(team.workerUuids, (function (workerUuid) {
          var worker = RCore.$$Array.getBy(workers, (function (w) {
                  return Caml_obj.equal(w.uuid, workerUuid);
                }));
          if (worker !== undefined) {
            return {
                    value: worker.uuid,
                    label: worker.name,
                    disabled: false
                  };
          }
          
        }));
  var workersWarning = [{
      value: Uuid.fromString("0"),
      label: tooManyWorkers$p(ctx),
      disabled: true
    }];
  var workersWithoutForemanAndTempWorkers = RCore.$$Array.filterMap(workers, (function (w) {
          var tempWorkerUuids = RCore.$$Array.reduce(team.temporaryWorkers, [], (function (acc, t) {
                  if (acc.some(function (uuid) {
                          return Uuid.equal(uuid, t.userUuid);
                        })) {
                    return acc;
                  } else {
                    return Belt_Array.concatMany([
                                acc,
                                [t.userUuid]
                              ]);
                  }
                }));
          if (tempWorkerUuids.some(function (uuid) {
                  return Uuid.equal(uuid, w.uuid);
                })) {
            return ;
          }
          if (currentForemanUuid !== undefined && Uuid.equal(w.uuid, Caml_option.valFromOption(currentForemanUuid))) {
            return ;
          }
          return {
                  value: w.uuid,
                  label: w.name,
                  disabled: false
                };
        }));
  var tempWorkersWithNames = team.temporaryWorkers.map(function (tempWorker) {
        var from = Locale.T.fmtM("Date", wire)(tempWorker.dateFrom);
        var to = Locale.T.fmtM("Date", wire)(tempWorker.dateTo);
        var dateSuffix = " (" + (
          Locale.T.equalOrLessThan(tempWorker.dateFrom, today) ? until$p(ctx) + " " + to : (
              from === to ? from : from + " - " + to
            )
        ) + ")";
        var name = RCore.$$Option.mapWithDefault(RCore.$$Array.getBy(workers, (function (worker) {
                    return Caml_obj.equal(worker.uuid, tempWorker.userUuid);
                  })), "", (function (w) {
                return w.name;
              }));
        return [
                {
                  uuid: tempWorker.userUuid,
                  name: name,
                  permission: "View",
                  dateFrom: new Date(Locale.T.isoDate(tempWorker.dateFrom)),
                  dateTo: new Date(Locale.T.isoDate(tempWorker.dateTo))
                },
                name + dateSuffix
              ];
      });
  var workerOptions = Belt_Array.concatMany([
        team.workerUuids,
        team.temporaryWorkers.map(function (w) {
              return w.userUuid;
            })
      ]).length >= 10 ? Belt_Array.concatMany([
          selectedWorkers,
          workersWarning
        ]) : workersWithoutForemanAndTempWorkers;
  var tmp = {
    _type: "text",
    value: team.name,
    onValueChange: (function (value) {
        onUpdate({
              uuid: team.uuid,
              name: value,
              foremanUuid: team.foremanUuid,
              workerUuids: team.workerUuids,
              description: team.description,
              skillUuids: team.skillUuids,
              territoryUuid: team.territoryUuid,
              temporaryWorkers: team.temporaryWorkers
            });
      }),
    readOnly: !hasPermissions,
    labelText: name$p(ctx),
    maxLength: 100,
    wrapperClass: Js_dict.fromArray([[
            "pd-form-group-full",
            true
          ]])
  };
  var tmp$1 = Backend_Errors.getMany(errors, [
        "name",
        "teamNameMustBeUnique"
      ]);
  if (tmp$1 !== undefined) {
    tmp.errors = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = {
    _type: "textarea",
    value: RCore.$$Option.getOr(team.description, ""),
    onValueChange: (function (value) {
        onUpdate({
              uuid: team.uuid,
              name: team.name,
              foremanUuid: team.foremanUuid,
              workerUuids: team.workerUuids,
              description: Fun.optString(value),
              skillUuids: team.skillUuids,
              territoryUuid: team.territoryUuid,
              temporaryWorkers: team.temporaryWorkers
            });
      }),
    readOnly: !hasPermissions,
    labelText: description$p(ctx),
    maxLength: 500
  };
  var tmp$3 = Backend_Errors.get(errors, "description");
  if (tmp$3 !== undefined) {
    tmp$2.errors = Caml_option.valFromOption(tmp$3);
  }
  var match$4 = Context_Types.Features.hasFlag("territories", ctx.features) ? [
      "pd-form-group pd-form-group-half",
      true
    ] : [
      "pd-form-group pd-form-group-full",
      false
    ];
  var teamUuid = team.uuid;
  return React.createElement("div", {
              className: "team-form-body"
            }, React.createElement("div", undefined, React.createElement("div", {
                      className: "pd-form-group-container"
                    }, React.createElement(FormInput.make, tmp)), React.createElement(FormInput.make, tmp$2), React.createElement("div", {
                      className: "pd-form-group-container transparent-border-1px"
                    }, React.createElement("div", {
                          className: match$4[0]
                        }, React.createElement("label", {
                              className: "pd-label",
                              htmlFor: "team-foreman"
                            }, foreman$p(ctx)), React.createElement(Select.make, {
                              placeholder: placeholder$p(ctx),
                              id: "team-foreman",
                              name: "team[foreman_uuid]",
                              onChange: onChangeForeman,
                              value: Js_null.bind(Js_null.fromOption(team.foremanUuid), (function (prim) {
                                      return prim;
                                    })),
                              disabled: !hasPermissions,
                              options: foremen.map(function (w) {
                                    return {
                                            value: w.uuid,
                                            label: w.name
                                          };
                                  }),
                              noResultsText: noResults$p(ctx)
                            })), React.createElement(Optional.make, {
                          show: match$4[1],
                          children: React.createElement("div", {
                                className: "pd-form-group pd-form-group-half"
                              }, React.createElement("label", {
                                    className: "pd-label",
                                    htmlFor: "team-territories"
                                  }, territory$p(ctx)), React.createElement(TerritoriesSelect.make, {
                                    id: "team-territories",
                                    inputName: "input-team-territory",
                                    selectedTerritoryUuid: Js_null.fromOption(team.territoryUuid),
                                    onChange: (function (uuid) {
                                        onUpdate({
                                              uuid: team.uuid,
                                              name: team.name,
                                              foremanUuid: team.foremanUuid,
                                              workerUuids: team.workerUuids,
                                              description: team.description,
                                              skillUuids: team.skillUuids,
                                              territoryUuid: uuid === null ? undefined : Caml_option.some(uuid),
                                              temporaryWorkers: team.temporaryWorkers
                                            });
                                      }),
                                    availableTerritories: availableTerritories.map(function (t) {
                                          return {
                                                  uuid: t.uuid,
                                                  name: t.name
                                                };
                                        }),
                                    placeholder: chooseTerritoryForTeam$p(ctx),
                                    disabled: !hasPermissions
                                  }))
                        })), React.createElement("div", {
                      className: "pd-form-group"
                    }, React.createElement("label", {
                          className: "pd-label",
                          htmlFor: "team-workers"
                        }, workers$p(ctx)), React.createElement(MultiSelect.make, {
                          placeholder: placeholder$p(ctx),
                          id: "team-workers",
                          onChange: onChangeWorkers,
                          value: team.workerUuids.map(function (prim) {
                                return prim;
                              }),
                          disabled: !hasPermissions,
                          options: workerOptions,
                          noResultsText: noResults$p(ctx),
                          multi: true
                        })), React.createElement(Optional.make, {
                      show: Context_Types.Features.hasFlag("temporaryWorkers", ctx.features) && Caml_obj.notequal(team.temporaryWorkers, []),
                      children: React.createElement("div", {
                            className: "pd-form-group"
                          }, React.createElement("label", {
                                className: "pd-label",
                                htmlFor: "team-temp-workers"
                              }, temporaryWorkers$p(ctx)), React.createElement(MultipleAssignees_Team.make, {
                                team: {
                                  uuid: RCore.$$Option.getOr(team.uuid, Uuid.fromString("0")),
                                  name: team.name,
                                  foreman: undefined,
                                  workers: [],
                                  temporaryWorkers: tempWorkersWithNames.map(function (w) {
                                        return w[0];
                                      }),
                                  skills: [],
                                  territory: undefined
                                },
                                availableAt: "All",
                                onWorkerDelete: (function (param) {
                                    
                                  }),
                                onDelete: (function () {
                                    
                                  }),
                                onTempWorkerDelete: onTempWorkerDelete,
                                names: tempWorkersWithNames.map(function (w) {
                                      return w[1];
                                    }),
                                showTeamHeader: false,
                                disabled: false,
                                decoration: undefined
                              }))
                    }), React.createElement(Optional.make, {
                      show: mode !== "New" && Context_Types.Features.hasFlag("shifts", ctx.features) && Context_Types.Features.hasFlag("temporaryWorkers", ctx.features),
                      children: React.createElement("div", {
                            className: "pd-form-group"
                          }, React.createElement(UI_Button.make, {
                                flavor: "outline",
                                className: styles.addTempWorkerButton,
                                onClick: (function (e) {
                                    e.preventDefault();
                                    setAddingTempWorkerTeamUuid(function (param) {
                                          return true;
                                        });
                                  }),
                                children: React.createElement("span", undefined, React.createElement(UI_Icon.make, {
                                          className: styles.addTempWorkerIcon,
                                          icon: "plus"
                                        }), addTempWorker$p(ctx))
                              }))
                    }), React.createElement("div", {
                      className: "pd-form-group"
                    }, React.createElement("label", {
                          className: "pd-label",
                          htmlFor: "team-workers"
                        }, skills$p(ctx)), React.createElement(SkillsSelect.make, {
                          id: "team-skills",
                          inputName: "input-team-skills",
                          selectedSkillUuids: team.skillUuids,
                          onChange: (function (uuids) {
                              onUpdate({
                                    uuid: team.uuid,
                                    name: team.name,
                                    foremanUuid: team.foremanUuid,
                                    workerUuids: team.workerUuids,
                                    description: team.description,
                                    skillUuids: uuids,
                                    territoryUuid: team.territoryUuid,
                                    temporaryWorkers: team.temporaryWorkers
                                  });
                            }),
                          availableSkills: ctx.availableSkills.map(function (s) {
                                return {
                                        uuid: s.uuid,
                                        name: s.name
                                      };
                              }),
                          placeholder: chooseSkillsForTeam$p(ctx),
                          disabled: !hasPermissions
                        }), React.createElement(Optional.make, {
                          show: hasPermissions && showTakeSkillsButton,
                          children: React.createElement("button", {
                                className: "btn btn-default " + styles.takeBtn,
                                type: "button",
                                onClick: onClickTakeSkills
                              }, useWorkersSkills$p(ctx))
                        }), teamUuid !== undefined && match$1[0] ? React.createElement(Modal.make, {
                            wire: wire,
                            onClose: onTempWorkerModalClose,
                            children: React.createElement(TemporaryWorkerForm.make, {
                                  wire: wire,
                                  teamUuid: Caml_option.valFromOption(teamUuid),
                                  teamName: team.name,
                                  exceptUuids: RCore.$$Option.mapWithDefault(team.foremanUuid, [], (function (u) {
                                            return [u];
                                          })).concat(team.workerUuids),
                                  onClose: onTempWorkerModalClose,
                                  onSubmit: onTempWorkerAdd,
                                  errors: match$2[0],
                                  setErrors: match$2[1]
                                })
                          }) : null)));
}

var make = TeamForm;

export {
  make ,
}
/* styles Not a pure module */
