

import * as React from "react";

var circle = React.createElement("svg", {
      height: "20",
      width: "20"
    }, React.createElement("circle", {
          cx: "10",
          cy: "10",
          fill: "#7777774d",
          r: "10"
        }));

var properties = {
  element: circle,
  width: 20.0,
  height: 20.0,
  anchorPosition: "Center"
};

var HighlightCircle = {
  circle: circle,
  properties: properties
};

function element(text) {
  return React.createElement("div", {
              style: {
                backgroundColor: "white",
                border: "3px solid #ec829f",
                display: "inline-block",
                fontSize: "11px",
                height: "19px",
                padding: "0 3px",
                whiteSpace: "nowrap",
                width: "auto",
                borderRadius: "9px",
                justifyContent: "center"
              }
            }, text);
}

function properties$1(text) {
  return {
          element: element(text),
          width: 48.0,
          height: 23.0,
          anchorPosition: "BottomMiddle"
        };
}

var MovementDistance = {
  element: element,
  properties: properties$1
};

export {
  HighlightCircle ,
  MovementDistance ,
}
/* circle Not a pure module */
