

import * as Case from "../../utils/Case.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.mjs";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";

function make(email, token, mobilePhone, password, passwordConfirmation, notificationMethod) {
  var email$1 = notificationMethod === "Sms" ? email : undefined;
  var mobilePhone$1 = notificationMethod === "Email" && mobilePhone !== "" ? mobilePhone : undefined;
  var notificationMethod$1 = notificationMethod === "Sms" ? "sms" : "email";
  return {
          email: email$1,
          mobilePhone: mobilePhone$1,
          token: token,
          password: password,
          passwordConfirmation: passwordConfirmation,
          notificationMethod: notificationMethod$1
        };
}

var $$Request = {
  make: make
};

var decode = JsonDecode.object(function (field) {
      return {
              message: field.required("message", JsonDecode.string)
            };
    });

var emptyErrors_email = [];

var emptyErrors_password = [];

var emptyErrors_passwordConfirmation = [];

var emptyErrors_mobilePhone = [];

var emptyErrors = {
  email: emptyErrors_email,
  password: emptyErrors_password,
  passwordConfirmation: emptyErrors_passwordConfirmation,
  mobilePhone: emptyErrors_mobilePhone
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              email: RCore.$$Option.getOr(field.optional("email", JsonDecode.array(JsonDecode.string)), []),
              password: RCore.$$Option.getOr(field.optional("password", JsonDecode.array(JsonDecode.string)), []),
              passwordConfirmation: RCore.$$Option.getOr(field.optional("passwordConfirmation", JsonDecode.array(JsonDecode.string)), []),
              mobilePhone: RCore.$$Option.getOr(field.optional("mobilePhone", JsonDecode.array(JsonDecode.string)), [])
            };
    });

function errorsFromString(str) {
  return Core__Result.getOr(Json_Decode$JsonCombinators.decode(Case.camelize(JSON.parse(str)), decode$1), emptyErrors);
}

function create(wire, token, email, mobilePhone, password, passwordConfirmation, notificationMethod) {
  return Backend.decode(Backend.post(undefined, undefined, undefined, true, wire, {
                  NAME: "API",
                  VAL: "ActivateFieldUser"
                }, {
                  NAME: "Obj",
                  VAL: make(email, token, mobilePhone, password, passwordConfirmation, notificationMethod)
                }), decode);
}

var Post_Response = {
  UnprocessableEntity: {
    errorsFromString: errorsFromString
  }
};

var Post = {
  $$Request: $$Request,
  $$Response: Post_Response
};

export {
  Post ,
  create ,
}
/* decode Not a pure module */
