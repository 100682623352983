import { listToTree } from 'planado/utils/index.js'
import { unwrapLocations, locationsToBlocks } from 'planado/utils/map'
import { isSameJsDay } from 'planado/utils/time/index.js'

const lastLocation = b =>
  b === null ? null : b.locations[b.locations.length - 1]

const refreshBlocks = ({
  historyWorkerUuid,
  loadingJobs,
  loadingLocations,
  locations
}) => {
  if (historyWorkerUuid === null) {
    return {}
  } else if (loadingJobs || loadingLocations) {
    return {}
  } else {
    return {
      locationBlocks: locationsToBlocks(locations)
    }
  }
}

const setBlocks = ({
  loadingJobs,
  loadingLocations,
  locations,
  viewingHistory
}) => {
  if (loadingJobs || loadingLocations) {
    return {}
  } else {
    const blocks = locationsToBlocks(locations)

    if (viewingHistory) {
      return {
        locationBlocks: blocks,
        currentBlockId: null,
        currentLocationId: null,
        focusedJobUuid: null
      }
    } else {
      const currentBlock = blocks.length > 0 ? blocks[blocks.length - 1] : null
      const currentLocation = lastLocation(currentBlock)
      const currentLocationId =
        currentLocation === null ? null : currentLocation.compositeId

      return {
        locationBlocks: locationsToBlocks(locations),
        currentBlockId: currentBlock === null ? null : currentBlock.id,
        currentLocationId: currentLocationId,
        focusedJobUuid: currentBlock === null ? null : currentBlock.jobUuid
      }
    }
  }
}

const addLocation = (
  { historyWorkerUuid, wrappedLocations, viewingHistory },
  location
) => {
  if (historyWorkerUuid !== location.workerUuid || viewingHistory) {
    return {}
  } else {
    const wrapped = []

    for (const l of wrappedLocations) {
      if (l.uuid === location.uuid) {
        wrapped.push(location)
      } else {
        wrapped.push(l)
      }
    }

    if (wrapped.length === wrappedLocations.length) {
      wrapped.push(location)
    }

    return {
      wrappedLocations: wrapped,
      locations: unwrapLocations(wrapped)
    }
  }
}

const addJob = ({ jobs, historyWorkerUuid, viewingHistory }, job) => {
  if (viewingHistory && !job.workerUuids.includes(historyWorkerUuid)) {
    return {}
  } else {
    return {
      jobs: { ...jobs, [job.uuid]: job }
    }
  }
}

const addCurrentJob = ({ currentJobs, currentTime }, job) => {
  if (isSameJsDay(new Date(job.lastActivityAt), currentTime)) {
    return {
      currentJobs: {
        ...currentJobs,
        [job.uuid]: job
      }
    }
  } else {
    return { currentJobs }
  }
}

const removeJob = (
  {
    jobs: { uuid: _a, ...jobs },
    currentJobs: { uuid: _b, ...currentJobs },
    focusedJobUuid
  },
  { uuid }
) => ({
  focusedJobUuid: focusedJobUuid === uuid ? null : focusedJobUuid,
  jobs,
  currentJobs
})

const handle = (state, action) => {
  switch (action.type) {
    case 'MAP_ADD_LOCATION':
    case 'MAP_UPDATE_LOCATION': {
      const nextState = {
        ...state,
        ...addLocation(state, action.location),
        latestLocations: {
          ...state.latestLocations,
          [action.location.workerUuid]: action.location
        }
      }

      return { ...nextState, ...refreshBlocks(nextState) }
    }
    case 'MAP_ADD_JOB':
    case 'MAP_UPDATE_JOB':
      return {
        ...state,
        ...addJob(state, action.job),
        ...addCurrentJob(state, action.job)
      }
    case 'MAP_REMOVE_JOB':
      return { ...state, ...removeJob(state, action.job) }
    case 'MAP_SET_HISTORY_DATE':
      return {
        ...state,
        historyDate: action.date,
        viewingHistory: !isSameJsDay(state.currentTime, new Date(action.date)),
        loadingLocations: true,
        locations: []
      }
    case 'MAP_SET_LOCATIONS': {
      const nextState = {
        ...state,
        wrappedLocations: action.locations,
        locations: unwrapLocations(action.locations),
        loadingLocations: false
      }

      return { ...nextState, ...setBlocks(nextState) }
    }
    case 'MAP_SET_JOBS': {
      const nextState = {
        ...state,
        jobs: listToTree(action.jobs),
        loadingJobs: false
      }

      return { ...nextState, ...setBlocks(nextState) }
    }
  }
}

export default handle
