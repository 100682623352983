

import * as Hooks from "../../../../libraries/hooks/Hooks.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../../common/Optional.mjs";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as JobIndexExportButtonModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/index/header/JobIndexExportButton.module.css";

var label$p = Utils.Translations.tr("js.jobs.index.export.label");

var defaultColumns$p = Utils.Translations.tr("js.jobs.index.export.default_columns");

var tableColumns$p = Utils.Translations.tr("js.jobs.index.export.table_columns");

var styles = JobIndexExportButtonModuleCss;

function JobIndexExportButton$ExportPopover(Props) {
  var exportJobs = Props.exportJobs;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  return React.createElement("div", {
              className: styles.exportPopover
            }, React.createElement("a", {
                  className: styles.link,
                  target: "_blank",
                  onClick: (function (param) {
                      exportJobs("Default");
                    })
                }, defaultColumns$p(ctx)), React.createElement("a", {
                  className: styles.link,
                  target: "_blank",
                  onClick: (function (param) {
                      exportJobs("All");
                    })
                }, tableColumns$p(ctx)));
}

function JobIndexExportButton(Props) {
  var exportJobs = Props.exportJobs;
  var match = AppContext.useWire();
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowPopover = match$1[1];
  var showPopover = match$1[0];
  var popoverRef = React.useRef(null);
  Hooks.useToggleOnClick1(popoverRef, showPopover, (function (show) {
          setShowPopover(function (param) {
                return show;
              });
        }));
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "export"
                }, React.createElement("button", {
                      ref: popoverRef,
                      className: styles.button
                    }, label$p(match.ctx))), React.createElement(Optional.make, {
                  show: showPopover,
                  children: React.createElement(JobIndexExportButton$ExportPopover, {
                        exportJobs: exportJobs
                      })
                }));
}

var make = JobIndexExportButton;

export {
  make ,
}
/* label' Not a pure module */
