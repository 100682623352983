

import * as Wire from "../../../../../../types/Wire.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as MomentRe from "../../../../../../bindings/moment/MomentRe.mjs";
import * as WeekTimeline_Constants from "../WeekTimeline_Constants.mjs";

function viewport(showUnscheduledJobs) {
  var documentWidth = Units.Px.fromInt(document.documentElement.clientWidth);
  return Units.Px.minus(Units.Px.minus(documentWidth, WeekTimeline_Constants.sidebarWidth), showUnscheduledJobs ? WeekTimeline_Constants.unscheduledJobsWidth : WeekTimeline_Constants.hiddenUnscheduledJobsWidth);
}

function emptyViewport(viewport) {
  return Units.Px.minus(viewport, Units.Px.fromInt(25));
}

function get(wire, showUnscheduledJobs) {
  var viewport$1 = viewport(showUnscheduledJobs);
  var day = Units.Px.divideByInt(viewport$1, WeekTimeline_Constants.daysInWeek);
  var match = Wire.businessHours(wire);
  var match$1;
  if (match !== undefined) {
    var day$1 = Units.Px.minus(day, Units.Px.fromInt(11));
    var minutesInDay = MomentRe.Duration.asMinutes(match.finish) - MomentRe.Duration.asMinutes(match.start);
    var sizeOfMinute = Units.Px.divideByFloat(day$1, minutesInDay);
    var sizeOfStep = Units.Px.multiplyWithFloat(sizeOfMinute, WeekTimeline_Constants.minutesInStep);
    match$1 = [
      sizeOfMinute,
      sizeOfStep
    ];
  } else {
    var sizeOfMinute$1 = Units.Px.divideByInt(day, WeekTimeline_Constants.minutesInDay);
    var sizeOfStep$1 = Units.Px.multiplyWithFloat(sizeOfMinute$1, WeekTimeline_Constants.minutesInStep);
    match$1 = [
      sizeOfMinute$1,
      sizeOfStep$1
    ];
  }
  return {
          minute: match$1[0],
          step: match$1[1],
          day: day,
          viewport: viewport$1,
          emptyViewport: emptyViewport(viewport$1)
        };
}

export {
  get ,
}
/* Wire Not a pure module */
