import React from 'react'
import { filterBars } from 'planado/schedule/utils2/filter'

export const Background = ({ bars, filter }) => (
  <div className="chart-background">
    {filterBars(bars, filter).map(bar => {
      if (bar.unassigned()) {
        return (
          <div
            className="bar-background unassigned"
            key={bar.id}
            dangerouslySetInnerHTML={{ __html: '&nbsp;' }}
          />
        )
      } else {
        return (
          <div
            className="bar-background"
            key={bar.id}
            dangerouslySetInnerHTML={{ __html: '&nbsp;' }}
          />
        )
      }
    })}
  </div>
)
