

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";
import * as SelectJsx from "planado/components/input/select.jsx";

var decode = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var JobType = {
  decode: decode
};

var make = SelectJsx.ContextlessSelect;

export {
  JobType ,
  make ,
}
/* decode Not a pure module */
