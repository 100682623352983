import {
  CONTACT_ADD,
  CONTACT_REMOVE,
  CONTACT_EDIT
} from 'planado/actions/contacts'

export const initialState = [
  { type: 'phone', name: null, value: null },
  { type: 'phone', name: null, value: null }
]

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTACT_ADD: {
      return [
        ...state,
        {
          type: 'phone',
          value: null,
          name: null
        }
      ]
    }
    case CONTACT_REMOVE: {
      if (action.index === 0) {
        console.error(
          'Invalid index passed to CONTACT_REMOVE: can not delete the only contact!'
        )
        return state
      } else {
        return [
          ...state.slice(0, action.index),
          ...state.slice(action.index + 1)
        ]
      }
    }
    case CONTACT_EDIT: {
      return [
        ...state.slice(0, action.index),
        action.value,
        ...state.slice(action.index + 1)
      ]
    }
    default:
      return state
  }
}

export default reducer
