

import * as Uuid from "../../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../../../../../common/Optional.mjs";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_Types_Job from "../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as Schedule_JobGroupCard from "../job_card/Schedule_JobGroupCard.mjs";
import * as Schedule_JobGroupUnfinishedModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/popovers/job_group/unfinished/Schedule_JobGroupUnfinished.module.css";

var styles = Schedule_JobGroupUnfinishedModuleCss;

var unfinished$p = Utils.Translations.tr("js.schedule.v2.jobs.unfinished");

function Schedule_JobGroupUnfinished(Props) {
  var jobs = Props.jobs;
  var match = AppContext.useWire();
  var jobBlocks = RCore.$$Array.filterMap(jobs, (function (job) {
          if (Schedule_Types_Job.ScheduledJob.isFinished(job)) {
            return ;
          } else {
            return Caml_option.some(React.createElement(Schedule_JobGroupCard.make, {
                            job: job,
                            key: Uuid.toString(Schedule_Types_Job.ScheduledJob.uuid(job))
                          }));
          }
        }));
  return React.createElement(Optional.make, {
              show: Caml_obj.notequal(jobBlocks, []),
              children: null
            }, React.createElement("div", {
                  className: styles.subheader
                }, unfinished$p(match.ctx), React.createElement("span", {
                      className: styles.count
                    }, jobBlocks.length.toString())), jobBlocks);
}

var make = Schedule_JobGroupUnfinished;

export {
  make ,
}
/* styles Not a pure module */
