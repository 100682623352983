

import * as React from "react";
import * as Locale from "../../../../../libraries/Locale.mjs";
import * as Schedule_State from "../../../lib/state/Schedule_State.mjs";
import * as Schedule_Button from "../../button/Schedule_Button.mjs";
import * as Schedule_ButtonGroup from "../../button_group/Schedule_ButtonGroup.mjs";
import * as Schedule_HeaderIntervalModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/header/interval/Schedule_HeaderInterval.module.css";

var styles = Schedule_HeaderIntervalModuleCss;

function Schedule_HeaderIntervalPrevNextButtons(Props) {
  var view = Props.view;
  var match = Schedule_State.usePeriod();
  var endOfPeriod = match[1];
  var startOfPeriod = match[0];
  var setPeriod = Schedule_State.useSetPeriod();
  var setPrevPeriod = function (_event) {
    var tmp;
    var exit = 0;
    if (view.NAME === "Calendar") {
      if (view.VAL === "Week") {
        exit = 1;
      } else {
        tmp = [
          Locale.T.prevMonth(startOfPeriod),
          Locale.T.prevMonth(endOfPeriod)
        ];
      }
    } else if (view.VAL === "Week") {
      exit = 1;
    } else {
      tmp = [
        Locale.T.prevDay(startOfPeriod),
        Locale.T.prevDay(endOfPeriod)
      ];
    }
    if (exit === 1) {
      tmp = [
        Locale.T.prevWeek(startOfPeriod),
        Locale.T.prevWeek(endOfPeriod)
      ];
    }
    setPeriod(tmp);
  };
  var setNextPeriod = function (_event) {
    var tmp;
    var exit = 0;
    if (view.NAME === "Calendar") {
      if (view.VAL === "Week") {
        exit = 1;
      } else {
        tmp = [
          Locale.T.nextMonth(startOfPeriod),
          Locale.T.nextMonth(endOfPeriod)
        ];
      }
    } else if (view.VAL === "Week") {
      exit = 1;
    } else {
      tmp = [
        Locale.T.nextDay(startOfPeriod),
        Locale.T.nextDay(endOfPeriod)
      ];
    }
    if (exit === 1) {
      tmp = [
        Locale.T.nextWeek(startOfPeriod),
        Locale.T.nextWeek(endOfPeriod)
      ];
    }
    setPeriod(tmp);
  };
  return React.createElement("div", {
              className: styles.changeDay
            }, React.createElement(Schedule_ButtonGroup.make, {
                  children: null
                }, React.createElement(Schedule_Button.make, {
                      onClick: setPrevPeriod,
                      className: styles.prev,
                      children: React.createElement("span", undefined)
                    }), React.createElement(Schedule_Button.make, {
                      onClick: setNextPeriod,
                      className: styles.next,
                      children: React.createElement("span", undefined)
                    })));
}

var make = React.memo(Schedule_HeaderIntervalPrevNextButtons);

export {
  make ,
}
/* styles Not a pure module */
