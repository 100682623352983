

import * as Fun from "../../../../../../libraries/Fun.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../types/Types.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as React from "react";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as DayTimeline_State from "../../lib/state/DayTimeline_State.mjs";
import * as Schedule_Constants from "../../../../lib/Schedule_Constants.mjs";
import * as Webapi__Dom__HtmlElement from "rescript-webapi/lib/es6/src/Webapi/Dom/Webapi__Dom__HtmlElement.mjs";
import * as DayTimeline_CurrentTimeModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/day_timeline/components/current_time/DayTimeline_CurrentTime.module.css";

var styles = DayTimeline_CurrentTimeModuleCss;

function positionLeft(wire, businessHours, currentDay, sizes) {
  var now = Locale.T.now(wire);
  if (!Locale.T.equalDay(now, currentDay)) {
    return ;
  }
  var now$1 = Locale.T.dateToMinutes(wire, now);
  if (businessHours === undefined) {
    return Caml_option.some(Units.Px.multiplyWithFloat(sizes.minute, now$1));
  }
  var start = Math.imul(Locale.T.Schedule.startDurationToHours(businessHours.start), Schedule_Constants.minutesInHour);
  var finish = Math.imul(Locale.T.Schedule.startDurationToHours(businessHours.finish), Schedule_Constants.minutesInHour);
  if (now$1 >= start && now$1 <= finish) {
    return Caml_option.some(Units.Px.multiplyWithFloat(sizes.minute, now$1 - start));
  }
  
}

function use() {
  var wire = AppContext.useWire();
  var sizes = DayTimeline_State.useSizes();
  var locked = Schedule_State.useLocked();
  var currentDay = Schedule_State.useCurrentDay();
  var businessHours = Schedule_State.useBusinessHours();
  var elementRef = Types.ReactRef.use();
  var setStyle = function () {
    requestAnimationFrame(function (_time) {
          var element = Types.ReactRef.toOption(elementRef);
          var htmlElement = RCore.$$Option.flatMap(element, Webapi__Dom__HtmlElement.ofElement);
          var match = Fun.both(element, htmlElement);
          if (match === undefined) {
            return ;
          }
          var element$1 = match[0];
          var left = positionLeft(wire, businessHours, currentDay, sizes);
          if (left !== undefined) {
            match[1].style.setProperty("left", Units.Px.toString(Caml_option.valFromOption(left)));
            element$1.classList.add(styles.visible);
          } else {
            element$1.classList.remove(styles.visible);
          }
        });
  };
  React.useEffect((function () {
          setStyle();
        }), [
        businessHours,
        sizes,
        currentDay
      ]);
  React.useEffect((function () {
          if (locked) {
            return ;
          } else {
            return wire.subscriptions.timer.subscribe(function (_now) {
                        setStyle();
                      });
          }
        }), [
        locked,
        businessHours,
        sizes,
        currentDay
      ]);
  return elementRef;
}

export {
  use ,
}
/* styles Not a pure module */
