

import * as Types from "../../../../../../types/Types.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as Schedule_Types from "../../../../lib/types/Schedule_Types.mjs";

function use() {
  var scrollAreaEl = Types.ReactRef.use();
  var viewportEl = Types.ReactRef.use();
  var sidebarEl = Types.ReactRef.use();
  var scaleEl = Types.ReactRef.use();
  var unassignedEl = Types.ReactRef.use();
  return {
          scrollAreaEl: scrollAreaEl,
          viewportEl: viewportEl,
          sidebarEl: sidebarEl,
          scaleEl: scaleEl,
          unassignedEl: unassignedEl
        };
}

var DomElements = {
  use: use
};

var Sizes = {};

function start(position) {
  return Units.Px.toInt(position.left);
}

function finish(position) {
  return Units.Px.toInt(Units.Px.plus(position.left, position.width));
}

var Position = {
  start: start,
  finish: finish
};

var IntervalPosition = {};

var Id = Schedule_Types.Id;

var PK = Schedule_Types.PK;

var Time = Schedule_Types.Time;

var ReactRef = Schedule_Types.ReactRef;

var JobCore = Schedule_Types.JobCore;

var AssignedJob = Schedule_Types.AssignedJob;

var UnassignedJob = Schedule_Types.UnassignedJob;

var ScheduledJob = Schedule_Types.ScheduledJob;

var UnscheduledJob = Schedule_Types.UnscheduledJob;

var DraggableJob = Schedule_Types.DraggableJob;

var Warning = Schedule_Types.Warning;

var Period = Schedule_Types.Period;

var Filter = Schedule_Types.Filter;

var Interval = Schedule_Types.Interval;

var Bar = Schedule_Types.Bar;

var Bars = Schedule_Types.Bars;

var TimelineSubscriptions = Schedule_Types.TimelineSubscriptions;

var JobSize = Schedule_Types.JobSize;

var Assignee = Schedule_Types.Assignee;

var BusinessHours = Schedule_Types.BusinessHours;

var Assignees = Schedule_Types.Assignees;

export {
  Id ,
  PK ,
  Time ,
  ReactRef ,
  JobCore ,
  AssignedJob ,
  UnassignedJob ,
  ScheduledJob ,
  UnscheduledJob ,
  DraggableJob ,
  Warning ,
  Period ,
  Filter ,
  Interval ,
  Bar ,
  Bars ,
  TimelineSubscriptions ,
  JobSize ,
  Assignee ,
  BusinessHours ,
  Assignees ,
  DomElements ,
  Sizes ,
  Position ,
  IntervalPosition ,
}
/* Types Not a pure module */
