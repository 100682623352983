

import * as RCore from "../../libraries/RCore.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function take(i, xs) {
  var l = xs.length;
  var len = i < 0 ? 0 : (
      l < i ? l : i
    );
  return xs.slice(0, len);
}

function last(xs) {
  var l = xs.length;
  if (l === 0) {
    return [
            [],
            undefined
          ];
  } else {
    return [
            xs.slice(0, l - 1 | 0),
            xs[l - 1 | 0]
          ];
  }
}

function flatMap(f, xs) {
  return xs.flatMap(f);
}

function intersperse(delim, xs) {
  if (Caml_obj.equal(xs, [])) {
    return [];
  }
  var prepended = xs.flatMap(function (x) {
        return [
                delim,
                x
              ];
      });
  return prepended.slice(1, prepended.length);
}

function mapOption(f, xs) {
  return RCore.$$Array.reduce(xs, [], (function (acc, curr) {
                var v = f(curr);
                if (v !== undefined) {
                  return Belt_Array.concatMany([
                              acc,
                              [Caml_option.valFromOption(v)]
                            ]);
                } else {
                  return acc;
                }
              }));
}

function distinctBy(eq, xs) {
  return RCore.$$Array.reduce(xs, [], (function (acc, curr) {
                if (acc.some(function (e) {
                        return eq(curr, e);
                      })) {
                  return acc;
                } else {
                  return Belt_Array.concatMany([
                              acc,
                              [curr]
                            ]);
                }
              }));
}

function joinWith(delim, xs) {
  return xs.join(delim);
}

var $$String = {
  joinWith: joinWith
};

export {
  take ,
  last ,
  flatMap ,
  intersperse ,
  mapOption ,
  distinctBy ,
  $$String ,
}
/* No side effect */
