// extracted by mini-css-extract-plugin
export var androidBadge = "android-badge-mYn6zizqdmcmdjmJeokc";
export var button = "button-rsiMvay9tdqQwEr8PRJ0";
export var buttonLoading = "button-loading-blOBlcNdVU8u9D60XwkK";
export var container = "container-SyhG3rkC_Peth6v8Mlvv";
export var download = "download-aEKGBqP5YS8bboFka4oW";
export var error = "error-uX0f3m808VzQQXTUhpXA";
export var form = "form-zwfOzU_7adEGh6ouoXgF";
export var icon = "icon-vcC2TgRnPH_RZYpWNamB";
export var iconEye = "icon-eye-ZeS_fohIhyQ7QFFH_uRE";
export var input = "input-Pfan7xS4pLB3rBrWWkzH";
export var inputContainer = "input-container-C2AmDfqyFGOH3pxFvJ_D";
export var iosBadge = "ios-badge-EJ2M7SorBImDpCzIT1yJ";
export var limit = "limit-ERpH6LuJsQ7dblhRUfLC";
export var loader = "loader-lRLvLMbA8utBVOXFJmDf";
export var logo = "logo-roQP6mHrzEyadMrel3dL";
export var registrationFinished = "registration-finished-NkLX_cHd0MDCB3xPMQAd";