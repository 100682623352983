

import * as DayTimeline_PositionsHelper from "../positions/DayTimeline_PositionsHelper.mjs";

function make(assignee, onClick, wire, businessHours, currentDay, sizeOfMinute, startAt, finishAt, tooltip) {
  var left = DayTimeline_PositionsHelper.left(wire, businessHours, currentDay, sizeOfMinute, startAt);
  return {
          assignee: assignee,
          onClick: onClick,
          left: left,
          width: DayTimeline_PositionsHelper.width(wire, businessHours, currentDay, sizeOfMinute, left, finishAt),
          tooltip: tooltip
        };
}

function assignee(block) {
  return block.assignee;
}

function onClick(block) {
  return block.onClick;
}

function left(block) {
  return block.left;
}

function width(block) {
  return block.width;
}

function tooltip(block) {
  return block.tooltip;
}

export {
  make ,
  assignee ,
  onClick ,
  left ,
  width ,
  tooltip ,
}
/* DayTimeline_PositionsHelper Not a pure module */
