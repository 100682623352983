

import * as Slate from "slate";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SlateReact from "slate-react";
import * as SlateHistory from "slate-history";

var Generator = {};

var Point = {};

function isCollapsed(selection) {
  return Slate.Range.isCollapsed(selection);
}

function edges(selection) {
  return Slate.Range.edges(selection);
}

var ElementCallback = {};

var InsertCallback = {};

var $$Node = {};

var Path = {};

var NodeMatch = {};

var NodeEntry = {};

function isElement(value) {
  return Slate.Element.isElement(value);
}

function fromAny(value) {
  if (Slate.Element.isElement(value)) {
    return Caml_option.some(value);
  }
  
}

function above(editor, options) {
  return Caml_option.nullable_to_opt(Slate.Editor.above(editor, options));
}

function before(options, editor, point) {
  return Caml_option.nullable_to_opt(Slate.Editor.before(editor, point, options));
}

function after(options, editor, point) {
  return Caml_option.nullable_to_opt(Slate.Editor.after(editor, point, options));
}

function range(editor, point1, point2) {
  return Caml_option.nullable_to_opt(Slate.Editor.range(editor, point1, point2));
}

function stringAtPath(editor, at) {
  return Slate.Editor.string(editor, at);
}

function stringAtRange(editor, at) {
  return Slate.Editor.string(editor, at);
}

function isEditor(value) {
  return Slate.Editor.isEditor(value);
}

function isBlock(editor, node) {
  return Slate.Editor.isBlock(editor, node);
}

function start(editor, path) {
  return Slate.Editor.start(editor, path);
}

function nodes(options, editor) {
  return Slate.Editor.nodes(editor, options);
}

function select(editor, range) {
  Slate.Transforms.select(editor, range);
}

function $$delete(editor) {
  Slate.Transforms.delete(editor);
}

function removeNodes(editor, options) {
  Slate.Transforms.removeNodes(editor, options);
}

function insertNodes(editor, elements) {
  Slate.Transforms.insertNodes(editor, elements);
}

function setNodes(editor, props, options) {
  Slate.Transforms.setNodes(editor, props, options);
}

function move(options, editor) {
  Slate.Transforms.move(editor, options);
}

function toDOMRange(editor, range) {
  return Caml_option.nullable_to_opt(SlateReact.ReactEditor.toDOMRange(editor, range));
}

function focus(editor) {
  SlateReact.ReactEditor.focus(editor);
}

function isFocused(editor) {
  return SlateReact.ReactEditor.isFocused(editor);
}

function findPath(editor, element) {
  return SlateReact.ReactEditor.findPath(editor, element);
}

var Props = {};

function Range_make(prim0, prim1, prim2) {
  return {
          anchor: prim0,
          focus: prim1
        };
}

function Range_anchor(prim) {
  return prim.anchor;
}

function Range_focus(prim) {
  return prim.focus;
}

var $$Range = {
  make: Range_make,
  anchor: Range_anchor,
  focus: Range_focus
};

var SlateRange = {
  range: Slate.Range,
  isCollapsed: isCollapsed,
  edges: edges
};

function Element_make(prim0, prim1, prim2, prim3) {
  var tmp = {};
  if (prim0 !== undefined) {
    tmp.text = prim0;
  }
  if (prim1 !== undefined) {
    tmp.children = Caml_option.valFromOption(prim1);
  }
  if (prim2 !== undefined) {
    tmp.payload = Caml_option.valFromOption(prim2);
  }
  return tmp;
}

function Element_text(prim) {
  return prim.text;
}

function Element_payload(prim) {
  return prim.payload;
}

function Element_children(prim) {
  return prim.children;
}

function Element_toString(prim) {
  return Slate.Node.string(prim);
}

var $$Element = {
  make: Element_make,
  text: Element_text,
  payload: Element_payload,
  children: Element_children,
  toString: Element_toString
};

function Editor_make(prim) {
  return Slate.createEditor();
}

function Editor_isInline(prim0, prim1) {
  return prim0.isInline(prim1);
}

function Editor_setIsInline(prim0, prim1) {
  prim0.isInline = prim1;
}

function Editor_getIsInline(prim) {
  return prim.isInline;
}

function Editor_isVoid(prim0, prim1) {
  return prim0.isVoid(prim1);
}

function Editor_setIsVoid(prim0, prim1) {
  prim0.isVoid = prim1;
}

function Editor_getIsVoid(prim) {
  return prim.isVoid;
}

function Editor_isReadOnly(prim0, prim1) {
  return prim0.isReadOnly(prim1);
}

function Editor_setIsReadOnly(prim0, prim1) {
  prim0.isReadOnly = prim1;
}

function Editor_getIsReadOnly(prim) {
  return prim.isReadOnly;
}

function Editor_insertText(prim0, prim1) {
  prim0.insertText(prim1);
}

function Editor_getInsertText(prim) {
  return prim.insertText;
}

function Editor_setInsertText(prim0, prim1) {
  prim0.insertText = prim1;
}

function Editor_getSelection(prim) {
  return Caml_option.nullable_to_opt(prim.selection);
}

function Editor_undo(prim0, prim1) {
  prim0.undo();
}

var Editor = {
  ElementCallback: ElementCallback,
  InsertCallback: InsertCallback,
  make: Editor_make,
  isInline: Editor_isInline,
  setIsInline: Editor_setIsInline,
  getIsInline: Editor_getIsInline,
  isVoid: Editor_isVoid,
  setIsVoid: Editor_setIsVoid,
  getIsVoid: Editor_getIsVoid,
  isReadOnly: Editor_isReadOnly,
  setIsReadOnly: Editor_setIsReadOnly,
  getIsReadOnly: Editor_getIsReadOnly,
  insertText: Editor_insertText,
  getInsertText: Editor_getInsertText,
  setInsertText: Editor_setInsertText,
  getSelection: Editor_getSelection,
  undo: Editor_undo
};

var SlateElement = {
  slateElement: Slate.Element,
  isElement: isElement,
  fromAny: fromAny
};

var SlateEditor_AboveOptions = {
  make: (function (prim0, prim1, prim2, prim3) {
      var tmp = {};
      if (prim0 !== undefined) {
        tmp.match = Caml_option.valFromOption(prim0);
      }
      if (prim1 !== undefined) {
        tmp.mode = Caml_option.valFromOption(prim1);
      }
      if (prim2 !== undefined) {
        tmp.voids = prim2;
      }
      return tmp;
    })
};

var SlateEditor_BeforeOptions = {
  make: (function (prim0, prim1, prim2, prim3) {
      var tmp = {};
      if (prim0 !== undefined) {
        tmp.distance = prim0;
      }
      if (prim1 !== undefined) {
        tmp.unit = Caml_option.valFromOption(prim1);
      }
      if (prim2 !== undefined) {
        tmp.voids = prim2;
      }
      return tmp;
    })
};

var SlateEditor_NodesOptions = {
  make: (function (prim0, prim1) {
      var tmp = {};
      if (prim0 !== undefined) {
        tmp.match = Caml_option.valFromOption(prim0);
      }
      return tmp;
    })
};

var SlateEditor = {
  slateEditor: Slate.Editor,
  AboveOptions: SlateEditor_AboveOptions,
  above: above,
  BeforeOptions: SlateEditor_BeforeOptions,
  before: before,
  after: after,
  range: range,
  stringAtPath: stringAtPath,
  stringAtRange: stringAtRange,
  isEditor: isEditor,
  isBlock: isBlock,
  start: start,
  NodesOptions: SlateEditor_NodesOptions,
  nodes: nodes
};

var SlateTransforms_RemoveNodeOptions = {
  make: (function (prim0, prim1) {
      var tmp = {};
      if (prim0 !== undefined) {
        tmp.at = Caml_option.valFromOption(prim0);
      }
      return tmp;
    })
};

var SlateTransforms_SetNodesOptions = {
  make: (function (prim0, prim1, prim2, prim3, prim4, prim5, prim6) {
      var tmp = {};
      if (prim0 !== undefined) {
        tmp.at = Caml_option.valFromOption(prim0);
      }
      if (prim1 !== undefined) {
        tmp.match = Caml_option.valFromOption(prim1);
      }
      if (prim2 !== undefined) {
        tmp.mode = Caml_option.valFromOption(prim2);
      }
      if (prim3 !== undefined) {
        tmp.hanging = prim3;
      }
      if (prim4 !== undefined) {
        tmp.split = prim4;
      }
      if (prim5 !== undefined) {
        tmp.voids = prim5;
      }
      return tmp;
    })
};

var SlateTransforms_MoveOptions = {
  make: (function (prim0, prim1, prim2) {
      var tmp = {};
      if (prim0 !== undefined) {
        tmp.unit = Caml_option.valFromOption(prim0);
      }
      if (prim1 !== undefined) {
        tmp.reverse = prim1;
      }
      return tmp;
    })
};

var SlateTransforms = {
  slateTransforms: Slate.Transforms,
  select: select,
  $$delete: $$delete,
  RemoveNodeOptions: SlateTransforms_RemoveNodeOptions,
  removeNodes: removeNodes,
  insertNodes: insertNodes,
  SetNodesOptions: SlateTransforms_SetNodesOptions,
  setNodes: setNodes,
  MoveOptions: SlateTransforms_MoveOptions,
  move: move
};

var React_Slate = {
  make: SlateReact.Slate
};

var React_ReactEditor = {
  reactEditor: SlateReact.ReactEditor,
  toDOMRange: toDOMRange,
  focus: focus,
  isFocused: isFocused,
  findPath: findPath
};

var React_Editable = {
  Props: Props,
  make: SlateReact.Editable
};

function React_withReact(prim) {
  return SlateReact.withReact(prim);
}

function React_useSelected(prim) {
  return SlateReact.useSelected();
}

function React_useFocused(prim) {
  return SlateReact.useFocused();
}

function React_useSlateStatic(prim) {
  return SlateReact.useSlateStatic();
}

var React = {
  Slate: React_Slate,
  ReactEditor: React_ReactEditor,
  Editable: React_Editable,
  withReact: React_withReact,
  useSelected: React_useSelected,
  useFocused: React_useFocused,
  useSlateStatic: React_useSlateStatic
};

var SlateHistory$1 = {
  withHistory: (function (prim) {
      return SlateHistory.withHistory(prim);
    })
};

export {
  Generator ,
  Point ,
  $$Range ,
  SlateRange ,
  $$Element ,
  Editor ,
  $$Node ,
  Path ,
  NodeMatch ,
  NodeEntry ,
  SlateElement ,
  SlateEditor ,
  SlateTransforms ,
  React ,
  SlateHistory$1 as SlateHistory,
}
/* SlateRange Not a pure module */
