

import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as APITypes from "./APITypes.mjs";

function $$fetch(wire) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Integrations",
                    VAL: "API"
                  }
                }), APITypes.Show.State.decode);
}

var Get = {
  $$fetch: $$fetch
};

function createKey(wire) {
  return Backend.decode(Backend.post(undefined, undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Integrations",
                    VAL: "GenerateKey"
                  }
                }, {
                  NAME: "Obj",
                  VAL: {}
                }), APITypes.KeyResponse.decode);
}

var GenerateKey = {
  createKey: createKey
};

var Show = APITypes.Show;

var KeyResponse = APITypes.KeyResponse;

export {
  Show ,
  KeyResponse ,
  Get ,
  GenerateKey ,
}
/* Backend Not a pure module */
