import { scrollToElement } from 'planado/utils/window'

const isEmpty = obj => {
  if (typeof obj === 'string') {
    obj = obj.trim()
  }

  return !obj || obj.length === 0
}

const validateFields = (fields, actions, isCustomField, ctx, customFields) =>
  fields.map((field, idx) => {
    if (field.isDestroyed) {
      return false
    } else {
      let error = []
      let hasError = false

      if (isEmpty(field.name)) {
        error[0] = Array.of(
          ctx.t('js.templates.custom_fields.errors.name_empty')
        )
        hasError = true
      } else if (
        field.errors !== undefined &&
        (!isEmpty(field.errors[0]) || !isEmpty(field.errors[1]))
      ) {
        error = field.errors
        hasError = true
      }

      if (field.fieldType === 'dictionary' && field.dictionaryUuid === null) {
        error[1] = Array.of(
          ctx.t('js.templates.custom_fields.errors.value_empty')
        )
        hasError = true
      } else if (field.fieldType === 'signature') {
        if (field.signedFieldUuid === null) {
          error[1] = Array.of(
            ctx.t('js.templates.custom_fields.errors.sign_field_empty')
          )
          hasError = true
        } else {
          const fileField = customFields.find(
            cf => cf.fieldUuid === field.signedFieldUuid && !cf.isDestroyed
          )

          if (!fileField) {
            error[1] = Array.of(
              ctx.t('js.templates.custom_fields.errors.sign_field_empty')
            )
            hasError = true
          }
        }
      } else if (
        field.fieldType === 'button' &&
        (field.urlValue === null ||
          field.urlValue === '' ||
          field.urlValue.match(/^http(s)?:\/\/$/))
      ) {
        error[1] = Array.of(
          ctx.t('js.templates.custom_fields.errors.url_value_empty')
        )
        hasError = true
      }

      if (hasError) {
        actions.setCustomField(idx, 'errors', error, isCustomField)
      }

      return hasError
    }
  })

export const validateResolutions = (resolutions, actions, ctx) => {
  const successfulPresence = resolutions.some(r => r.selected && r.successful)
  const badPresence = resolutions.some(r => r.selected && !r.successful)

  if (!successfulPresence || !badPresence) {
    actions.setError(
      'resolutions',
      Array.of(ctx.t('js.templates.resolutions.error'))
    )

    return true
  } else {
    return false
  }
}

const validateName = (name, errors, actions, ctx) => {
  if (isEmpty(name)) {
    actions.setError('template', {
      name: Array.of(ctx.t('js.templates.info.errors.name_empty'))
    })
    return true
  } else {
    return errors.template.name.length > 0
  }
}

const validateForm = (
  errors,
  template,
  customFields,
  reportFields,
  resolutions,
  actions,
  ctx
) => ({
  template: {
    name: validateName(template.name, errors, actions, ctx)
  },
  customFields: validateFields(customFields, actions, true, ctx),
  reportFields: validateFields(reportFields, actions, false, ctx, customFields),
  resolutions: validateResolutions(resolutions, actions, ctx)
})

const formHasError = errors => {
  switch (true) {
    case errors.template.name:
    case errors.resolutions:
    case errors.customFields.some(hasError => hasError):
    case errors.reportFields.some(hasError => hasError):
      return true
    default:
      return false
  }
}

const updateFieldsErrors = (
  type,
  { fields, idx, isCustomField, setCustomField }
) =>
  fields.forEach((checkedField, checkedIdx) => {
    if (
      checkedIdx !== idx &&
      checkedField.type === type &&
      !isEmpty(checkedField.errors) &&
      !isEmpty(checkedField.name)
    ) {
      const hasError = fields.some(
        (field, fieldIdx) =>
          fieldIdx !== checkedIdx &&
          field.type === type &&
          field.name === checkedField.name
      )

      if (!hasError) {
        const errors =
          type === 'dictionary' ? [[], checkedField.errors[1] || []] : []

        setCustomField(checkedIdx, 'errors', errors, isCustomField)
      }
    }
  })

const validateField = (
  name,
  type,
  { fields, setCustomField, idx, errors = [], isCustomField },
  ctx
) => {
  let error
  if (!isEmpty(name)) {
    const matchesFound = fields.some(
      (field, i) =>
        i !== idx &&
        field.fieldType === type &&
        !isEmpty(field.name) &&
        field.name.trim() === name.trim() &&
        !field.isDestroyed
    )

    if (matchesFound) {
      const errorText = Array.of(
        ctx.t('js.templates.custom_fields.errors.sameFieldNames')
      )

      error = type === 'dictionary' ? [errorText, errors[1] || []] : [errorText]
    } else {
      error = type === 'dictionary' ? [[], errors[1] || []] : []
    }
  } else {
    const errorText = Array.of(
      ctx.t('js.templates.custom_fields.errors.name_empty')
    )
    error = type === 'dictionary' ? [errorText, errors[1] || []] : [errorText]
  }

  setCustomField(idx, 'errors', error, isCustomField)
}

const scrollToError = errors => {
  let idx
  let el
  switch (true) {
    case errors.template.name: {
      el = document.getElementById('template-form-input-name')
      scrollToElement(el, -75)
      break
    }
    case errors.customFields.some(hasError => hasError): {
      idx = errors.customFields.indexOf(true)
      el = document.getElementById(`template[custom_fields][${idx}][name]`)
      scrollToElement(el, -75)
      break
    }
    case errors.reportFields.some(hasError => hasError): {
      idx = errors.reportFields.indexOf(true)
      el = document.getElementById(`template[report_fields][${idx}][name]`)
      scrollToElement(el, -75)
      break
    }
    case errors.resolutions:
    default:
      break
  }
}

export {
  validateForm,
  formHasError,
  updateFieldsErrors,
  validateField,
  isEmpty,
  scrollToError
}
