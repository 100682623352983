

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";
import * as JobEdit_JobType from "../../components/job/edit/lib/types/JobEdit_JobType.mjs";

var PageType = {};

function decodeString(dataType) {
  switch (dataType) {
    case "attachment" :
        return "Attachment";
    case "boolean" :
        return "Boolean";
    case "currency" :
        return "Currency";
    case "decimal" :
        return "Decimal";
    case "dictionary" :
        return "Dictionary";
    case "integer" :
        return "Integer";
    case "string" :
        return "String";
    default:
      return ;
  }
}

function toString(dataType) {
  switch (dataType) {
    case "Integer" :
        return "integer";
    case "Decimal" :
        return "decimal";
    case "Attachment" :
        return "attachment";
    case "Boolean" :
        return "boolean";
    case "Currency" :
        return "currency";
    case "String" :
        return "string";
    case "Dictionary" :
        return "dictionary";
    
  }
}

var decode = JsonDecode.fromVariant(JsonDecode.string, decodeString);

var DataType = {
  decodeString: decodeString,
  toString: toString,
  decode: decode
};

function decodeString$1(dataType) {
  switch (dataType) {
    case "checkbox" :
        return "Checkbox";
    case "currency" :
        return "Currency";
    case "dictionary" :
        return "Dictionary";
    case "file" :
        return "File";
    case "image" :
        return "Image";
    case "input" :
        return "Input";
    default:
      return ;
  }
}

function toString$1(dataType) {
  switch (dataType) {
    case "File" :
        return "file";
    case "Image" :
        return "image";
    case "Input" :
        return "input";
    case "Checkbox" :
        return "checkbox";
    case "Currency" :
        return "currency";
    case "Dictionary" :
        return "dictionary";
    
  }
}

var decode$1 = JsonDecode.fromVariant(JsonDecode.string, decodeString$1);

var FieldType = {
  decodeString: decodeString$1,
  toString: toString$1,
  decode: decode$1
};

var FieldOption = {};

var decode$2 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              dataType: field.required("dataType", decode),
              fieldType: field.required("fieldType", decode$1),
              dictionaryUuid: field.required("dictionaryUuid", JsonDecode.option(Uuid.decode)),
              currency: field.required("currency", JsonDecode.option(JsonDecode.string)),
              useCurrencyFractionalUnit: field.required("useCurrencyFractionalUnit", JsonDecode.option(JsonDecode.bool))
            };
    });

var Field = {
  decode: decode$2
};

var decode$3 = JsonDecode.object(function (field) {
      return {
              typeUuid: field.required("typeUuid", Uuid.decode),
              dataType: field.required("dataType", decode),
              fieldType: field.required("fieldType", decode$1),
              booleanValue: RCore.$$Option.flatten(field.optional("booleanValue", JsonDecode.option(JsonDecode.bool))),
              stringValue: RCore.$$Option.flatten(field.optional("stringValue", JsonDecode.option(JsonDecode.string))),
              decimalValue: RCore.$$Option.flatten(field.optional("decimalValue", JsonDecode.option(JsonDecode.string))),
              integerValue: RCore.$$Option.flatten(field.optional("integerValue", JsonDecode.option(JsonDecode.string))),
              dictionaryUuid: field.optional("dictionaryUuid", Uuid.decode),
              dictionaryValueUuid: RCore.$$Option.flatten(field.optional("dictionaryValueUuid", JsonDecode.option(Uuid.decode))),
              currency: field.optional("currency", JsonDecode.string),
              currencyValue: RCore.$$Option.flatten(field.optional("currencyValue", JsonDecode.option(JsonDecode.string))),
              useCurrencyFractionalUnit: field.optional("useCurrencyFractionalUnit", JsonDecode.bool),
              attachmentValues: field.optional("attachmentValues", JsonDecode.array(JobEdit_JobType.Field.Attachment.decode))
            };
    });

var FieldValue = {
  decode: decode$3
};

var FieldWithValue = {};

export {
  PageType ,
  DataType ,
  FieldType ,
  FieldOption ,
  Field ,
  FieldValue ,
  FieldWithValue ,
}
/* decode Not a pure module */
