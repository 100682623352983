

import * as El from "../../../../libraries/El.mjs";
import * as Icon from "../../../../Icon.mjs";
import * as Link from "../../../common/Link/Link.mjs";
import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as Modal from "../../../modal/Modal.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../../common/Optional.mjs";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as Context_Types from "../../../../context/Context_Types.mjs";
import * as ModalModuleCss from "/home/runner/work/planado/planado/client/rescript/components/modal/Modal.module.css";
import * as UserTeamsWarningModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/user/show/UserTeamsWarning.module.css";

var completeJobsWarning$p = Utils.Translations.tr("js.admin.users.form.permissions.complete_jobs_warning");

var loseForemanRights$p = Utils.Translations.t("js.admin.users.form.permissions.lose_foreman_rights");

var teamsCount$p = Utils.Translations.tx("js.admin.users.form.permissions.teams_count");

var accept$p = Utils.Translations.tr("js.admin.users.form.buttons.accept");

var styles = UserTeamsWarningModuleCss;

var modalStyles = ModalModuleCss;

function UserTeamsWarning(Props) {
  var onClose = Props.onClose;
  var onSubmit = Props.onSubmit;
  var teams = Props.teams;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var features = ctx.features;
  return React.createElement(Modal.make, {
              wire: wire,
              onClose: onClose,
              children: React.createElement("div", {
                    className: El.Cn.concat(El.Cn.concat(modalStyles.dialog, styles.dialog), "test-teams-modal")
                  }, React.createElement(Modal.Header.make, {
                        children: React.createElement("h1", {
                              className: styles.title
                            }, completeJobsWarning$p(ctx))
                      }), React.createElement(Modal.Body.make, {
                        className: styles.body,
                        children: null
                      }, React.createElement("div", {
                            className: styles.textSection
                          }, Utils.Translations.concat([
                                loseForemanRights$p(ctx),
                                teamsCount$p({
                                      count: teams.length
                                    }, ctx)
                              ], ctx)), React.createElement(Optional.make, {
                            show: Context_Types.Features.hasFlag("teams", features) && !Context_Types.Features.hasFlag("shifts", features),
                            children: teams.map(function (t) {
                                  return React.createElement(Link.make, {
                                              wire: wire,
                                              route: {
                                                NAME: "Team",
                                                VAL: {
                                                  NAME: "Edit",
                                                  VAL: t.uuid
                                                }
                                              },
                                              className: styles.link,
                                              children: null,
                                              key: Uuid.toString(t.uuid)
                                            }, t.name, React.createElement("span", {
                                                  className: Icon.style(undefined, undefined, "external-link-alt")
                                                }));
                                })
                          })), React.createElement(Modal.Footer.make, {
                        className: El.Cn.concat(styles.footer, modalStyles.footer),
                        children: null
                      }, React.createElement(Modal.CloseButton.make, {
                            ctx: ctx
                          }), React.createElement("div", {
                            id: "test-btn-modal-footer-accept"
                          }, React.createElement(Modal.RemoveButton.make, {
                                onClick: (function (param) {
                                    onSubmit();
                                  }),
                                children: accept$p(ctx)
                              }))))
            });
}

var make = UserTeamsWarning;

export {
  make ,
}
/* completeJobsWarning' Not a pure module */
