

import * as App from "./App.mjs";
import * as Case from "./utils/Case.mjs";
import * as React from "react";
import * as JsonDecode from "./libraries/JsonDecode.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.mjs";
import * as Context_Types from "./context/Context_Types.mjs";
import * as BackgroundTask from "./types/BackgroundTask.mjs";
import * as Bindings_ReactDomServer from "./bindings/reactDom/Bindings_ReactDomServer.mjs";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";

function initApp(ctxJson, footer, websocketParams, backgroundTasks) {
  var json = Case.camelize(ctxJson);
  var ctx = Json_Decode$JsonCombinators.decode(json, Context_Types.Context.decode);
  if (ctx.TAG === "Ok") {
    return Bindings_ReactDomServer.renderToElementWithClassName(React.createElement(App.make, {
                    ctx: ctx._0,
                    footer: footer,
                    websocketParams: websocketParams,
                    backgroundTasks: Core__Result.getOr(JsonDecode.decode(Case.camelize(backgroundTasks), JsonDecode.array(BackgroundTask.decode)), [])
                  }), "app");
  }
  console.log(ctx._0);
}

export {
  initApp ,
}
/* App Not a pure module */
