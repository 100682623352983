

import * as React from "react";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import Base_form_inputJsx from "planado/components/input/base_form_input.jsx";

function BaseFormInput(Props) {
  var id = Props.id;
  var labelText = Props.labelText;
  var wrapperClass = Props.wrapperClass;
  var labelClass = Props.labelClass;
  var errors = Props.errors;
  var horizontal = Props.horizontal;
  var children = Props.children;
  var errors$1 = Js_null.fromOption(errors);
  var labelText$1 = Js_null.fromOption(labelText);
  var tmp = {
    id: id,
    labelText: labelText$1,
    errors: errors$1,
    children: children
  };
  if (wrapperClass !== undefined) {
    tmp.wrapperClass = Caml_option.valFromOption(wrapperClass);
  }
  if (labelClass !== undefined) {
    tmp.labelClass = Caml_option.valFromOption(labelClass);
  }
  if (horizontal !== undefined) {
    tmp.horizontal = horizontal;
  }
  return React.createElement(Base_form_inputJsx, tmp);
}

var make = BaseFormInput;

export {
  make ,
}
/* react Not a pure module */
