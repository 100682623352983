

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";
import * as BackgroundTask from "../../types/BackgroundTask.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              backgroundTasks: field.required("backgroundTasks", JsonDecode.array(BackgroundTask.decode))
            };
    });

var $$Response = {
  decode: decode
};

function readAll(wire, tasks) {
  return Backend.decode(Backend.patch(undefined, true, undefined, true, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "BackgroundTasks",
                    VAL: "Read"
                  }
                }, {
                  NAME: "Obj",
                  VAL: {
                    backgroundTasks: tasks.map(function (t) {
                          return Uuid.toString(t.uuid);
                        })
                  }
                }), decode);
}

function index(wire) {
  return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "BackgroundTasks",
                    VAL: "Index"
                  }
                }), decode);
}

function cancel(wire, uuid) {
  return Backend.decode(Backend.patch(undefined, true, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "BackgroundTasks",
                    VAL: {
                      NAME: "Cancel",
                      VAL: uuid
                    }
                  }
                }, {
                  NAME: "Obj",
                  VAL: {}
                }), decode);
}

var deafultParams = [
  0,
  10
];

var $$default = {
  TAG: "All",
  _0: deafultParams
};

function increaseOffset(filter) {
  switch (filter.TAG) {
    case "All" :
        var match = filter._0;
        var limit = match[1];
        return {
                TAG: "All",
                _0: [
                  match[0] + limit | 0,
                  limit
                ]
              };
    case "Successful" :
        var match$1 = filter._0;
        var limit$1 = match$1[1];
        return {
                TAG: "Successful",
                _0: [
                  match$1[0] + limit$1 | 0,
                  limit$1
                ]
              };
    case "Unsuccessful" :
        var match$2 = filter._0;
        var limit$2 = match$2[1];
        return {
                TAG: "Unsuccessful",
                _0: [
                  match$2[0] + limit$2 | 0,
                  limit$2
                ]
              };
    
  }
}

function toJson(filter) {
  var jsFilter;
  switch (filter.TAG) {
    case "All" :
        var match = filter._0;
        jsFilter = {
          section: "all",
          offset: match[0],
          limit: match[1]
        };
        break;
    case "Successful" :
        var match$1 = filter._0;
        jsFilter = {
          section: "successful",
          offset: match$1[0],
          limit: match$1[1]
        };
        break;
    case "Unsuccessful" :
        var match$2 = filter._0;
        jsFilter = {
          section: "unsuccessful",
          offset: match$2[0],
          limit: match$2[1]
        };
        break;
    
  }
  return Utils.objToJson(jsFilter);
}

var Filter = {
  deafultParams: deafultParams,
  $$default: $$default,
  increaseOffset: increaseOffset,
  toJson: toJson
};

function get(wire, backgroundTasksUuid, filter) {
  return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "BulkOperations",
                    VAL: {
                      NAME: "Show",
                      VAL: [
                        backgroundTasksUuid,
                        Utils.objToJson({
                              filter: filter
                            })
                      ]
                    }
                  }
                }), BackgroundTask.BulkOperation.decode);
}

function retry(wire, backgroundTasksUuid, excludeUuids) {
  return Backend.decode(Backend.post(undefined, undefined, undefined, true, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "BulkOperations",
                    VAL: {
                      NAME: "Retry",
                      VAL: backgroundTasksUuid
                    }
                  }
                }, {
                  NAME: "Obj",
                  VAL: {
                    excludeUuids: excludeUuids
                  }
                }), decode);
}

var BulkOperation = {
  Filter: Filter,
  get: get,
  retry: retry
};

export {
  $$Response ,
  readAll ,
  index ,
  cancel ,
  BulkOperation ,
}
/* decode Not a pure module */
