

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";

var TaskStatus = {};

var decode = JsonDecode.object(function (field) {
      return {
              sitesCreated: field.optional("sitesCreated", JsonDecode.$$int),
              sitesUpdated: field.optional("sitesUpdated", JsonDecode.$$int)
            };
    });

var decode$1 = JsonDecode.object(function (field) {
      var match = field.required("status", JsonDecode.string);
      var status;
      switch (match) {
        case "finished" :
            status = {
              TAG: "Finished",
              _0: field.required("success", JsonDecode.bool)
            };
            break;
        case "in_progress" :
            status = "InProgress";
            break;
        default:
          status = JsonDecode.cannotDecode("unknown task status");
      }
      return {
              uuid: field.required("uuid", Uuid.decode),
              status: status,
              errors: field.required("errors", JsonDecode.array(JsonDecode.string)),
              summary: field.required("summary", decode)
            };
    });

function getTask(wire, uuid) {
  return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Tasks",
                    VAL: {
                      NAME: "Site",
                      VAL: uuid
                    }
                  }
                }), decode$1);
}

function postFile(wire, data) {
  return Backend.decode(Backend.post(undefined, undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Site",
                    VAL: "Upload"
                  }
                }, {
                  NAME: "FormData",
                  VAL: data
                }), decode$1);
}

var Task = {
  Summary: {}
};

export {
  TaskStatus ,
  Task ,
  getTask ,
  postFile ,
}
/* decode Not a pure module */
