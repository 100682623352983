

import * as El from "../../../../../libraries/El.mjs";
import * as Hooks from "../../../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../types/Types.mjs";
import * as Utils from "../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../../libraries/Locale.mjs";
import * as Optional from "../../../../common/Optional.mjs";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as DatePicker from "../../../../inputs/DatePicker.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_State from "../../../lib/state/Schedule_State.mjs";
import * as Schedule_Button from "../../button/Schedule_Button.mjs";
import * as Schedule_ButtonGroup from "../../button_group/Schedule_ButtonGroup.mjs";
import * as DayPickerRangeController from "../../../../inputs/DayPickerRangeController.mjs";
import * as Schedule_HeaderIntervalModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/header/interval/Schedule_HeaderInterval.module.css";

var today$p = Utils.Translations.tr("js.schedule.v2.buttons.today");

var styles = Schedule_HeaderIntervalModuleCss;

function renderDay(date) {
  return React.createElement("span", {
              className: styles.calendarDate
            }, Locale.T.monthDay(date).toString());
}

function Schedule_HeaderIntervalCalendar$Popover$SingleDate(Props) {
  var currentDay = Props.currentDay;
  var period = Props.period;
  var setPeriod = Props.setPeriod;
  var onDateChange = function (date) {
    setPeriod(period(date));
  };
  return React.createElement(DatePicker.make, {
              date: currentDay,
              onDateChange: onDateChange,
              renderDay: renderDay,
              numberOfMonths: 1,
              firstDayOfWeek: "Monday",
              navPrev: React.createElement("span", undefined),
              navNext: React.createElement("span", undefined),
              daySize: 31,
              hideKeyboardShortcutsPanel: true
            });
}

function Schedule_HeaderIntervalCalendar$Popover$Range(Props) {
  var currentDay = Props.currentDay;
  var period = Props.period;
  var setPeriod = Props.setPeriod;
  var match = period(currentDay);
  var endDate = match[1];
  var startDate = match[0];
  var match$1 = React.useState(function () {
        return [
                startDate,
                endDate
              ];
      });
  var setHovered = match$1[1];
  var hovered = match$1[0];
  var onDatesChange = function (dates) {
    setPeriod(period(RCore.$$Option.getOr(Caml_option.nullable_to_opt(dates.startDate), currentDay)));
  };
  var className = function (date) {
    if (Locale.T.withinInterval(date, hovered)) {
      return El.Cn.concat(styles.calendarDate, styles.calendarCustomHovered);
    } else {
      return styles.calendarDate;
    }
  };
  var renderDay = function (date) {
    return React.createElement("span", {
                className: className(date),
                onMouseEnter: (function (_e) {
                    setHovered(function (param) {
                          return period(date);
                        });
                  }),
                onMouseLeave: (function (_e) {
                    setHovered(function (param) {
                          return [
                                  startDate,
                                  endDate
                                ];
                        });
                  })
              }, Locale.T.monthDay(date).toString());
  };
  return React.createElement(DayPickerRangeController.make, {
              startDate: Caml_option.some(startDate),
              endDate: Caml_option.some(endDate),
              onDatesChange: onDatesChange,
              minimumNights: 0,
              numberOfMonths: 1,
              focusedInput: DayPickerRangeController.FocusedInput.toString("Start"),
              firstDayOfWeek: "Monday",
              hideKeyboardShortcutsPanel: true,
              navPrev: React.createElement("span", undefined),
              navNext: React.createElement("span", undefined),
              daySize: 31,
              renderDay: renderDay
            });
}

function Schedule_HeaderIntervalCalendar$Popover(Props) {
  var view = Props.view;
  var closeCalendar = Props.closeCalendar;
  var setPeriod = Schedule_State.useSetPeriod();
  var currentDay = Schedule_State.useCurrentDay();
  var calendarPopoverRef = Types.ReactRef.use();
  Hooks.useOnClickOutside(undefined, calendarPopoverRef, closeCalendar);
  Hooks.useOnEscPress(undefined, closeCalendar);
  var period = function (date) {
    var startOfDay = Locale.T.startOf("day", date);
    var endOfDay = Locale.T.endOf("day", date);
    if (view.NAME === "Calendar") {
      if (view.VAL !== "Week") {
        return [
                Locale.T.startOf("month", startOfDay),
                Locale.T.endOf("month", endOfDay)
              ];
      }
      
    } else if (view.VAL !== "Week") {
      return [
              startOfDay,
              endOfDay
            ];
    }
    return [
            Locale.T.startOf("isoWeek", startOfDay),
            Locale.T.endOf("isoWeek", endOfDay)
          ];
  };
  var tmp;
  var exit = 0;
  if (typeof view === "object" && view.NAME === "Timeline" && view.VAL === "Day") {
    tmp = React.createElement(Schedule_HeaderIntervalCalendar$Popover$SingleDate, {
          currentDay: currentDay,
          period: period,
          setPeriod: setPeriod
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(Schedule_HeaderIntervalCalendar$Popover$Range, {
          currentDay: currentDay,
          period: period,
          setPeriod: setPeriod
        });
  }
  return React.createElement("div", {
              ref: calendarPopoverRef,
              className: styles.calendarPopover
            }, tmp);
}

function Schedule_HeaderIntervalCalendar(Props) {
  var view = Props.view;
  var wire = AppContext.useWire();
  var match = React.useState(function () {
        return false;
      });
  var setShowCalendar = match[1];
  var showCalendar = match[0];
  var toggleCalendar = React.useCallback((function (_event) {
          setShowCalendar(function (prev) {
                return !prev;
              });
        }), []);
  var closeCalendar = React.useCallback((function () {
          setShowCalendar(function (param) {
                return false;
              });
        }), []);
  var setPeriod = Schedule_State.useSetPeriod();
  var setTodayPeriod = React.useCallback((function (_event) {
          var tmp;
          var exit = 0;
          if (view.NAME === "Calendar") {
            if (view.VAL === "Week") {
              exit = 1;
            } else {
              tmp = [
                Locale.T.Schedule.startOfCurrentMonth(wire),
                Locale.T.Schedule.endOfCurrentMonth(wire)
              ];
            }
          } else if (view.VAL === "Week") {
            exit = 1;
          } else {
            tmp = [
              Locale.T.Schedule.startOfCurrentDay(wire),
              Locale.T.Schedule.endOfCurrentDay(wire)
            ];
          }
          if (exit === 1) {
            tmp = [
              Locale.T.Schedule.startOfCurrentWeek(wire),
              Locale.T.Schedule.endOfCurrentWeek(wire)
            ];
          }
          setPeriod(tmp);
        }), [view]);
  return React.createElement("div", {
              className: styles.calendar
            }, React.createElement(Schedule_ButtonGroup.make, {
                  children: null
                }, React.createElement(Schedule_Button.make, {
                      onClick: setTodayPeriod,
                      children: today$p(wire.ctx)
                    }), React.createElement(Schedule_Button.make, {
                      onClick: toggleCalendar,
                      active: showCalendar,
                      children: React.createElement("span", {
                            className: styles.iconCalendar
                          })
                    })), React.createElement(Optional.make, {
                  show: showCalendar,
                  children: React.createElement(Schedule_HeaderIntervalCalendar$Popover, {
                        view: view,
                        closeCalendar: closeCalendar
                      })
                }));
}

var make = React.memo(Schedule_HeaderIntervalCalendar);

export {
  make ,
}
/* today' Not a pure module */
