

import * as El from "../../../../../../libraries/El.mjs";
import * as Fun from "../../../../../../libraries/Fun.mjs";
import * as Link from "../../../../../common/Link/Link.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as ModalQuery from "../../../../../../types/ModalQuery.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JobForm_FooterModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/edit/form/components/footer/JobForm_Footer.module.css";

var ExternalLinkJs = {};

var noCreator$p = Utils.Translations.txr("js.jobs.footer.created_at.no_creator");

var hasCreator$p = Utils.Translations.txr("js.jobs.footer.created_at.has_creator");

var copiedFromJob$p = Utils.Translations.t("js.jobs.footer.copied_from_job");

var styles = JobForm_FooterModuleCss;

function JobForm_Footer(Props) {
  var wire = Props.wire;
  var job = Props.job;
  if (job.isNewRecord) {
    return null;
  }
  var createdAt = job.createdAt;
  var createdBy = job.createdBy;
  var externalLink = job.externalLink;
  var ctx = wire.ctx;
  var createdAtBlock;
  if (createdAt !== undefined) {
    var dateTime = Locale.T.toDateTime(Caml_option.valFromOption(createdAt), wire);
    var createdAtString = createdBy !== undefined ? hasCreator$p({
            date: dateTime.date,
            time: dateTime.time,
            createdBy: createdBy
          }, ctx) : noCreator$p({
            date: dateTime.date,
            time: dateTime.time
          }, ctx);
    var match = Fun.both(job.copiedFromUuid, job.copiedFromSerialNo);
    var copiedFromString;
    if (match !== undefined) {
      var text = copiedFromJob$p(ctx);
      copiedFromString = React.createElement("span", {
            className: El.Cn.concat("job-copied-from", styles.externalLinkAlt)
          }, ", " + text, React.createElement(Link.make, {
                wire: wire,
                route: {
                  NAME: "Job",
                  VAL: "Index"
                },
                modals: {
                  hd: {
                    NAME: "Job",
                    VAL: {
                      NAME: "Edit",
                      VAL: ModalQuery.Job.EditOptions.make(undefined, undefined, match[0])
                    }
                  },
                  tl: /* [] */0
                },
                target: "blank",
                children: match[1].toString()
              }));
    } else {
      copiedFromString = null;
    }
    createdAtBlock = React.createElement("div", undefined, createdAtString, copiedFromString);
  } else {
    createdAtBlock = null;
  }
  var externalLinkBlock;
  if (externalLink !== undefined) {
    var url = externalLink.url;
    var text$1 = externalLink.text;
    externalLinkBlock = url !== undefined ? React.createElement("div", {
            className: styles.externalLinkAlt
          }, React.createElement("a", {
                href: url,
                target: "_blank"
              }, text$1)) : React.createElement("div", undefined, text$1);
  } else {
    externalLinkBlock = null;
  }
  return React.createElement("div", {
              className: styles.container
            }, createdAtBlock, externalLinkBlock);
}

var make = JobForm_Footer;

export {
  ExternalLinkJs ,
  make ,
}
/* noCreator' Not a pure module */
