

import * as Wire from "../../../../../../../../../types/Wire.mjs";
import * as RCore from "../../../../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../../../../types/Types.mjs";
import * as Units from "../../../../../../../../../types/Units.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppContext from "../../../../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_DND from "../../../../../../../lib/Schedule_DND.mjs";
import * as Assignee_Types from "../../../../../../../../../types/Assignee_Types.mjs";
import * as Schedule_State from "../../../../../../../lib/state/Schedule_State.mjs";
import * as Shared_Lib_Timer from "../../../../../../../../../shared/lib/Shared_Lib_Timer.mjs";
import * as WeekTimeline_State from "../../../../../lib/state/WeekTimeline_State.mjs";
import * as Schedule_UtilsTimeline from "../../../../../../../lib/utils/Schedule_UtilsTimeline.mjs";
import * as WeekTimeline_ActiveDayState from "../../../../active_day/lib/state/WeekTimeline_ActiveDayState.mjs";
import * as WeekTimeline_BarCellModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/timeline/bar/components/bar_cell/WeekTimeline_BarCell.module.css";

function make(element) {
  var rect = element.getBoundingClientRect();
  return {
          x: [
            Units.Px.fromFloat(rect.x),
            Units.Px.plus(Units.Px.fromFloat(rect.x), Units.Px.fromFloat(rect.width))
          ],
          y: [
            Units.Px.fromFloat(rect.y),
            Units.Px.plus(Units.Px.fromFloat(rect.y), Units.Px.fromFloat(rect.height))
          ]
        };
}

function inBounds(contentEl, coordinate, param) {
  var match = param.y;
  var match$1 = param.x;
  var match$2 = Schedule_UtilsTimeline.ConvertPoint.fromNodeToPage(contentEl, coordinate);
  var y = match$2[1];
  var x = match$2[0];
  if (Caml_obj.greaterequal(x, match$1[0]) && Caml_obj.lessequal(x, match$1[1]) && Caml_obj.greaterequal(y, match[0])) {
    return Caml_obj.lessequal(y, match[1]);
  } else {
    return false;
  }
}

var styles = WeekTimeline_BarCellModuleCss;

function use(viewportEl, active, assignee, weekDay) {
  var wire = AppContext.useWire();
  var elementRef = Types.ReactRef.use();
  var showUnscheduledJobs = Schedule_State.useShowUnscheduledJobs();
  var sizes = WeekTimeline_State.useSizes();
  var setActiveDay = WeekTimeline_ActiveDayState.useSetActiveDay();
  var resetActiveDay = WeekTimeline_ActiveDayState.useResetActiveDay();
  var activeWeekDay = WeekTimeline_ActiveDayState.useWeekDay();
  var activeAssigneeUuid = WeekTimeline_ActiveDayState.useAssigneeUuid();
  var match = Schedule_DND.DND.Context.use();
  var match$1 = match.subscriptions;
  var onDragEnd = match$1.onDragEnd;
  var onDragStart = match$1.onDragStart;
  var isDragging = React.useRef(false);
  React.useEffect((function () {
          var unsubscribeFromDragStart = onDragStart.subscribe(function () {
                isDragging.current = true;
              });
          var unsubscribeFromDragEnd = onDragEnd.subscribe(function () {
                isDragging.current = false;
              });
          return (function () {
                    unsubscribeFromDragStart();
                    unsubscribeFromDragEnd();
                  });
        }), []);
  var match$2 = WeekTimeline_State.useTimelineSubscriptions();
  var onMouseLeave = match$2.onMouseLeave;
  var onMouseMove = match$2.onMouseMove;
  var hovered = React.useRef(false);
  var resetHovered = function (element) {
    requestAnimationFrame(function (_time) {
          element.classList.remove(styles.hovered);
          hovered.current = false;
        });
  };
  var hoverTimeoutId = React.useRef(undefined);
  var handleMouseMove = function (contentEl, element) {
    return function (coordinate) {
      var hoverTimeoutId$1 = hoverTimeoutId.current;
      if (hoverTimeoutId$1 !== undefined) {
        clearTimeout(Caml_option.valFromOption(hoverTimeoutId$1));
      }
      var bounds = make(element);
      var inBounds$1 = inBounds(contentEl, coordinate, bounds);
      if (isDragging.current && inBounds$1) {
        hoverTimeoutId.current = Caml_option.some(setTimeout((function () {
                    setActiveDay(assignee, weekDay);
                  }), 2000));
        return ;
      } else if (isDragging.current) {
        return ;
      } else if (!hovered.current && inBounds$1) {
        requestAnimationFrame(function (_time) {
              element.classList.add(styles.hovered);
              hovered.current = true;
            });
        return ;
      } else if (hovered.current && !inBounds$1) {
        return resetHovered(element);
      } else {
        return ;
      }
    };
  };
  var handleMouseLeave = function (element) {
    return function () {
      var hoverTimeoutId$1 = hoverTimeoutId.current;
      if (hoverTimeoutId$1 !== undefined) {
        clearTimeout(Caml_option.valFromOption(hoverTimeoutId$1));
      }
      resetHovered(element);
    };
  };
  React.useEffect((function () {
          var contentEl = Schedule_UtilsTimeline.contentEl(viewportEl);
          var element = Types.ReactRef.toOption(elementRef);
          if (contentEl === undefined) {
            return ;
          }
          if (element === undefined) {
            return ;
          }
          if (active) {
            return ;
          }
          var element$1 = Caml_option.valFromOption(element);
          var unsubscribeFromMouseMove = onMouseMove.subscribe(handleMouseMove(Caml_option.valFromOption(contentEl), element$1));
          var unsubscribeFromMouseLeave = onMouseLeave.subscribe(handleMouseLeave(element$1));
          return (function () {
                    handleMouseLeave(element$1)();
                    unsubscribeFromMouseMove();
                    unsubscribeFromMouseLeave();
                  });
        }), [
        active,
        activeWeekDay,
        showUnscheduledJobs
      ]);
  var match$3 = React.useState(function () {
        return function (prim) {
          
        };
      });
  var setClickDelayHandler = match$3[1];
  var resetClickDelayHandler = match$3[0];
  var onDoubleClick = function (_event) {
    resetClickDelayHandler();
  };
  var mouseDownAt = React.useRef(undefined);
  var onMouseDown = function (_event) {
    mouseDownAt.current = Date.now();
  };
  var onMouseUp = function (_event) {
    var mouseUpAt = Date.now();
    var mouseDownAt$1 = mouseDownAt.current;
    if (mouseDownAt$1 !== undefined && mouseUpAt - mouseDownAt$1 <= 300.0) {
      resetClickDelayHandler();
      return setClickDelayHandler(function (param) {
                  return Shared_Lib_Timer.delay(300, (function () {
                                resetClickDelayHandler();
                                if (Caml_obj.equal(activeWeekDay, weekDay) && Caml_obj.equal(activeAssigneeUuid, Caml_option.some(Assignee_Types.Assignee.uuid(assignee)))) {
                                  return resetActiveDay();
                                } else {
                                  return setActiveDay(assignee, weekDay);
                                }
                              }));
                });
    }
    
  };
  React.useEffect((function () {
          var targetOpt = RCore.$$Option.map(Types.ReactRef.toOption(elementRef), (function (prim) {
                  return prim;
                }));
          if (targetOpt === undefined) {
            return ;
          }
          var target = Caml_option.valFromOption(targetOpt);
          target.addEventListener("mousedown", onMouseDown);
          target.addEventListener("mouseup", onMouseUp);
          if (Wire.hasRightsToEditJobs(wire)) {
            target.addEventListener("dblclick", onDoubleClick);
          }
          return (function () {
                    target.removeEventListener("mousedown", onMouseDown);
                    target.removeEventListener("mouseup", onMouseUp);
                    if (Wire.hasRightsToEditJobs(wire)) {
                      target.removeEventListener("dblclick", onDoubleClick);
                      return ;
                    }
                    
                  });
        }), [
        sizes,
        resetClickDelayHandler,
        activeWeekDay,
        activeAssigneeUuid,
        setActiveDay,
        resetActiveDay
      ]);
  return elementRef;
}

export {
  use ,
}
/* styles Not a pure module */
