

import * as Units from "../../../../../types/Units.mjs";
import * as Schedule_Constants from "../../../lib/Schedule_Constants.mjs";

var rowHeight = Units.Px.fromInt(50);

var optimalViewPortWidth = Units.Px.fromInt(1600);

var optimalHourWidth = Units.Px.divideByInt(optimalViewPortWidth, 9);

var businessHoursWidth = Units.Px.fromInt(40);

var controlsHeight = Units.Px.fromInt(64);

var scaleHeight = Units.Px.fromInt(23);

var barGapHeight = Units.Px.fromInt(14);

var minutesInHour = Schedule_Constants.minutesInHour;

var dndScheduledJob = Schedule_Constants.dndScheduledJob;

var dndUnscheduledJob = Schedule_Constants.dndUnscheduledJob;

var dndUnassignedJob = Schedule_Constants.dndUnassignedJob;

var sidebarWidth = Schedule_Constants.sidebarWidth;

var numberOfJobsInRow = 3;

var numberOfVisibleJobs = 2;

var minimumNumberOfHours = 9;

export {
  minutesInHour ,
  dndScheduledJob ,
  dndUnscheduledJob ,
  dndUnassignedJob ,
  sidebarWidth ,
  rowHeight ,
  numberOfJobsInRow ,
  numberOfVisibleJobs ,
  minimumNumberOfHours ,
  optimalViewPortWidth ,
  optimalHourWidth ,
  businessHoursWidth ,
  controlsHeight ,
  scaleHeight ,
  barGapHeight ,
}
/* rowHeight Not a pure module */
