import { connect } from 'react-redux'
import { toggleLocationBlock, setLocation } from 'planado/actions/map'

import LocationBlock from 'planado/components/map/location_block'

const mapStateToProps = (state, { block }) => {
  const { currentBlockId, jobs, currentLocationId, engineChannel } = state

  return {
    block,
    currentLocationId,
    engineChannel,
    job: jobs[block.jobUuid] || null,
    open: currentBlockId === block.id
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onBlockToggle: block => dispatch(toggleLocationBlock(block)),
    onLocationToggle: location => dispatch(setLocation(location))
  }
}

const LocationBlockLink = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationBlock)

export default LocationBlockLink
