


function reducer(state, action) {
  if (typeof action !== "object") {
    switch (action) {
      case "ToggleUnscheduledJobs" :
          var init = state.settings;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: init.filter,
                      businessHours: init.businessHours,
                      showUnscheduledJobs: !state.settings.showUnscheduledJobs,
                      locked: init.locked,
                      mode: init.mode,
                      jobDuration: init.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "LockSchedule" :
          var init$1 = state.settings;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: init$1.filter,
                      businessHours: init$1.businessHours,
                      showUnscheduledJobs: init$1.showUnscheduledJobs,
                      locked: true,
                      mode: init$1.mode,
                      jobDuration: init$1.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "UnlockSchedule" :
          var init$2 = state.settings;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: init$2.filter,
                      businessHours: init$2.businessHours,
                      showUnscheduledJobs: init$2.showUnscheduledJobs,
                      locked: false,
                      mode: init$2.mode,
                      jobDuration: init$2.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "ShowJobsWithScheduledDuration" :
          var init$3 = state.settings;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: init$3.filter,
                      businessHours: init$3.businessHours,
                      showUnscheduledJobs: init$3.showUnscheduledJobs,
                      locked: init$3.locked,
                      mode: init$3.mode,
                      jobDuration: "Scheduled"
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "ShowJobsWithActualDuration" :
          var init$4 = state.settings;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: init$4.filter,
                      businessHours: init$4.businessHours,
                      showUnscheduledJobs: init$4.showUnscheduledJobs,
                      locked: init$4.locked,
                      mode: init$4.mode,
                      jobDuration: "Actual"
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "ToggleDayTimelineMode" :
          var init$5 = state.settings;
          var init$6 = state.settings.mode;
          var match = state.settings.mode.day;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: init$5.filter,
                      businessHours: init$5.businessHours,
                      showUnscheduledJobs: init$5.showUnscheduledJobs,
                      locked: init$5.locked,
                      mode: {
                        day: match === "Old" ? "New" : "Old",
                        week: init$6.week
                      },
                      jobDuration: init$5.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "ToggleWeekTimelineMode" :
          var init$7 = state.settings;
          var init$8 = state.settings.mode;
          var match$1 = state.settings.mode.week;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: init$7.filter,
                      businessHours: init$7.businessHours,
                      showUnscheduledJobs: init$7.showUnscheduledJobs,
                      locked: init$7.locked,
                      mode: {
                        day: init$8.day,
                        week: match$1 === "Old" ? "New" : "Old"
                      },
                      jobDuration: init$7.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      
    }
  } else {
    switch (action.TAG) {
      case "SetBusinessHours" :
          var init$9 = state.settings;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: init$9.filter,
                      businessHours: action._0,
                      showUnscheduledJobs: init$9.showUnscheduledJobs,
                      locked: init$9.locked,
                      mode: init$9.mode,
                      jobDuration: init$9.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "SetFilterTerritories" :
          var init$10 = state.settings;
          var init$11 = state.settings.filter;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: {
                        territories: action._0,
                        skills: init$11.skills,
                        period: init$11.period,
                        assignees: init$11.assignees
                      },
                      businessHours: init$10.businessHours,
                      showUnscheduledJobs: init$10.showUnscheduledJobs,
                      locked: init$10.locked,
                      mode: init$10.mode,
                      jobDuration: init$10.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "SetFilterSkills" :
          var init$12 = state.settings;
          var init$13 = state.settings.filter;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: {
                        territories: init$13.territories,
                        skills: action._0,
                        period: init$13.period,
                        assignees: init$13.assignees
                      },
                      businessHours: init$12.businessHours,
                      showUnscheduledJobs: init$12.showUnscheduledJobs,
                      locked: init$12.locked,
                      mode: init$12.mode,
                      jobDuration: init$12.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "SetFilterSkillsUuids" :
          var init$14 = state.settings;
          var init$15 = state.settings.filter;
          var init$16 = state.settings.filter.skills;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: {
                        territories: init$15.territories,
                        skills: {
                          uuids: action._0,
                          mode: init$16.mode
                        },
                        period: init$15.period,
                        assignees: init$15.assignees
                      },
                      businessHours: init$14.businessHours,
                      showUnscheduledJobs: init$14.showUnscheduledJobs,
                      locked: init$14.locked,
                      mode: init$14.mode,
                      jobDuration: init$14.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "SetFilterSkillsMode" :
          var init$17 = state.settings;
          var init$18 = state.settings.filter;
          var init$19 = state.settings.filter.skills;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: {
                        territories: init$18.territories,
                        skills: {
                          uuids: init$19.uuids,
                          mode: action._0
                        },
                        period: init$18.period,
                        assignees: init$18.assignees
                      },
                      businessHours: init$17.businessHours,
                      showUnscheduledJobs: init$17.showUnscheduledJobs,
                      locked: init$17.locked,
                      mode: init$17.mode,
                      jobDuration: init$17.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "SetFilterPeriod" :
          var init$20 = state.settings;
          var init$21 = state.settings.filter;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: {
                        territories: init$21.territories,
                        skills: init$21.skills,
                        period: action._0,
                        assignees: init$21.assignees
                      },
                      businessHours: init$20.businessHours,
                      showUnscheduledJobs: init$20.showUnscheduledJobs,
                      locked: init$20.locked,
                      mode: init$20.mode,
                      jobDuration: init$20.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "SetMode" :
          var init$22 = state.settings;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: init$22.filter,
                      businessHours: init$22.businessHours,
                      showUnscheduledJobs: init$22.showUnscheduledJobs,
                      locked: init$22.locked,
                      mode: action._0,
                      jobDuration: init$22.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "SetAssigneesFilter" :
          var init$23 = state.settings;
          var init$24 = state.settings.filter;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: {
                        territories: init$24.territories,
                        skills: init$24.skills,
                        period: init$24.period,
                        assignees: action._0
                      },
                      businessHours: init$23.businessHours,
                      showUnscheduledJobs: init$23.showUnscheduledJobs,
                      locked: init$23.locked,
                      mode: init$23.mode,
                      jobDuration: init$23.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      
    }
  }
}

export {
  reducer ,
}
/* No side effect */
