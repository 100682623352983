

import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TemplateSelect from "../../../inputs/TemplateSelect.mjs";
import * as MapJobsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/map/jobs/MapJobs.module.css";

var styles = MapJobsModuleCss;

var template$p = Utils.Translations.t("js.template_list.template");

function MapJobsFilterByTemplate(Props) {
  var wire = Props.wire;
  var template = Props.template;
  var templates = Props.templates;
  var onChange = Props.onChange;
  return React.createElement("div", {
              className: styles.templateSelect
            }, React.createElement(TemplateSelect.make, {
                  placeholder: template$p(wire.ctx),
                  onChange: (function (x) {
                      onChange(x === null ? undefined : Caml_option.some(x));
                    }),
                  value: Js_null.fromOption(template),
                  disabled: false,
                  options: templates.map(function (param) {
                        return {
                                value: param.uuid,
                                label: param.name
                              };
                      })
                }));
}

var make = MapJobsFilterByTemplate;

export {
  make ,
}
/* styles Not a pure module */
