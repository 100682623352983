

import * as IndexJsx from "planado/components/admin/recurring_jobs/index/index.jsx";

var make = IndexJsx.Footer;

export {
  make ,
}
/* make Not a pure module */
