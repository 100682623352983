import { connect } from 'react-redux'

import {
  toggleJobStatusFilter,
  togglePanelJobs,
  togglePanelStatusFilter
} from 'planado/actions/map'
import JobFilter from 'planado/components/map/job_filter'

const mapStateToProps = ({
  showPanelStatusFilter,
  jobStatusFilter,
  showPanelJobs
}) => {
  return {
    open: showPanelStatusFilter,
    checkedStatuses: jobStatusFilter,
    showJobs: showPanelJobs
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onStatusToggle: status => dispatch(toggleJobStatusFilter(status)),
    onToggle: () => dispatch(togglePanelJobs()),
    onFilterToggle: () => dispatch(togglePanelStatusFilter())
  }
}

const JobFilterLink = connect(mapStateToProps, mapDispatchToProps)(JobFilter)

export default JobFilterLink
