

import * as El from "../../../../../../libraries/El.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Schedule_Types_Job from "../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as Schedule_JobPreviewFooterModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/popovers/job_preview/footer/Schedule_JobPreviewFooter.module.css";

var enRoute$p = Utils.Translations.tr("common.job_states.en_route");

var started$p = Utils.Translations.tr("common.job_states.started");

var suspended$p = Utils.Translations.tr("common.job_states.suspended");

var styles = Schedule_JobPreviewFooterModuleCss;

function Schedule_JobPreviewFooterStatus(Props) {
  var job = Props.job;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var match$1 = Schedule_Types_Job.ScheduledJob.status(job);
  var match$2 = Schedule_Types_Job.ScheduledJob.resolution(job);
  switch (match$1) {
    case "EnRoute" :
        return React.createElement("div", {
                    className: styles.enRoute
                  }, enRoute$p(ctx));
    case "Started" :
        return React.createElement("div", {
                    className: styles.started
                  }, started$p(ctx));
    case "Suspended" :
        return React.createElement("div", {
                    className: styles.suspended
                  }, suspended$p(ctx));
    case "Finished" :
        if (match$2 !== undefined) {
          return React.createElement("div", {
                      className: El.Cn.concat(styles.resolution, match$2.successful ? "" : styles.unsuccessful)
                    }, React.createElement("div", {
                          className: styles.resolutionText
                        }, match$2.name));
        } else {
          return null;
        }
    default:
      return null;
  }
}

var make = React.memo(Schedule_JobPreviewFooterStatus);

export {
  make ,
}
/* enRoute' Not a pure module */
