

import * as Wire from "../../../../../../types/Wire.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Schedule_Constants from "../../../../lib/Schedule_Constants.mjs";
import * as DayTimeline_Constants from "../DayTimeline_Constants.mjs";

function unscheduledJobsWidth(showUnscheduledJobs) {
  if (showUnscheduledJobs) {
    return Schedule_Constants.unscheduledJobsWidth;
  } else {
    return Schedule_Constants.hiddenUnscheduledJobsWidth;
  }
}

function businessHoursWidth(wire) {
  var match = Wire.businessHours(wire);
  if (match !== undefined) {
    return DayTimeline_Constants.businessHoursWidth;
  } else {
    return Units.Px.zero;
  }
}

function viewport(wire, showUnscheduledJobs) {
  var documentWidth = Units.Px.fromInt(document.documentElement.clientWidth);
  return Units.Px.minus(Units.Px.minus(Units.Px.minus(documentWidth, Schedule_Constants.sidebarWidth), businessHoursWidth(wire)), showUnscheduledJobs ? Schedule_Constants.unscheduledJobsWidth : Schedule_Constants.hiddenUnscheduledJobsWidth);
}

function emptyViewport(wire, viewport) {
  return Units.Px.minus(Units.Px.plus(viewport, Units.Px.multiplyWithInt(businessHoursWidth(wire), 2)), Units.Px.fromInt(25));
}

function hours(businessHours) {
  if (businessHours === undefined) {
    return Schedule_Constants.hoursInDay;
  }
  var startRounded = Locale.T.Schedule.startDurationToHours(businessHours.start);
  var finishRounded = Locale.T.Schedule.finishDurationToHours(businessHours.finish);
  return finishRounded - startRounded | 0;
}

function hour(viewport, hours) {
  if (hours <= DayTimeline_Constants.minimumNumberOfHours) {
    return Units.Px.divideByInt(viewport, hours);
  }
  if (Caml_obj.lessequal(viewport, DayTimeline_Constants.optimalViewPortWidth)) {
    return Units.Px.divideByInt(viewport, DayTimeline_Constants.minimumNumberOfHours);
  }
  var width = Units.Px.multiplyWithInt(DayTimeline_Constants.optimalHourWidth, hours);
  if (Caml_obj.lessequal(width, viewport)) {
    return Units.Px.divideByInt(viewport, hours);
  } else {
    return DayTimeline_Constants.optimalHourWidth;
  }
}

function get(wire, showUnscheduledJobs, businessHours) {
  var viewport$1 = viewport(wire, showUnscheduledJobs);
  var emptyViewport$1 = emptyViewport(wire, viewport$1);
  var hours$1 = hours(businessHours);
  var hour$1 = hour(viewport$1, hours$1);
  var step = Units.Px.divideByInt(hour$1, Schedule_Constants.stepsInHour);
  return {
          minute: Units.Px.divideByFloat(step, Schedule_Constants.minutesInStep),
          step: step,
          hour: hour$1,
          viewport: viewport$1,
          emptyViewport: emptyViewport$1,
          timeline: Units.Px.multiplyWithInt(hour$1, hours$1)
        };
}

export {
  unscheduledJobsWidth ,
  get ,
}
/* Wire Not a pure module */
