

import * as React from "react";
import * as WeekTimeline_State from "../../../../lib/state/WeekTimeline_State.mjs";
import * as Schedule_TimelineJob from "../../../../../../components/timeline/job/Schedule_TimelineJob.mjs";

function WeekTimeline_ActiveDayJob(Props) {
  var job = Props.job;
  var position = Props.position;
  var bar = Props.bar;
  var match = WeekTimeline_State.useTimelineSubscriptions();
  var match$1 = position.size.contents;
  var size;
  switch (match$1) {
    case "Full" :
        size = "Full";
        break;
    case "Half" :
        size = "Half";
        break;
    case "OneThird" :
        size = "OneThird";
        break;
    
  }
  return React.createElement(Schedule_TimelineJob.make, {
              job: {
                TAG: "Assigned",
                _0: job
              },
              size: size,
              top: position.top,
              left: position.left,
              width: position.width,
              startedOutside: position.startedOutside,
              finishedOutside: position.finishedOutside,
              onScrollStart: match.onScrollStart,
              bar: bar
            });
}

var make = WeekTimeline_ActiveDayJob;

export {
  make ,
}
/* react Not a pure module */
