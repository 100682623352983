export const GEOLOCATION_OPTIONS = {
  provider: 'yandex',
  mapStateAutoApply: true,
  autoReverseGeocode: false
}

export const LOCALES = {
  ru: 'ru_RU',
  en: 'en_US',
  es: 'en_US'
}
