

import * as El from "../../libraries/El.mjs";
import * as Icon from "../../Icon.mjs";
import * as React from "react";
import * as BackgroundTasksIndexModuleCss from "/home/runner/work/planado/planado/client/rescript/components/background_task/BackgroundTasksIndex.module.css";

var styles = BackgroundTasksIndexModuleCss;

function iconCN(success) {
  if (success) {
    return El.Cn.concat(styles.successful, Icon.style(undefined, undefined, "check-circle"));
  } else {
    return El.Cn.concat(styles.unsuccessful, Icon.style(undefined, undefined, "times-circle"));
  }
}

function BackgroundTaskIcon(Props) {
  var task = Props.task;
  var match = task.type_;
  var match$1 = task.status;
  var match$2 = task.errors;
  var tmp;
  var exit = 0;
  if (typeof match !== "object") {
    if (typeof match$1 !== "object") {
      switch (match$1) {
        case "Waiting" :
        case "InProgress" :
            exit = 1;
            break;
        case "Canceled" :
            tmp = React.createElement("span", {
                  className: El.Cn.concat(styles.icon, Icon.style(undefined, undefined, "file-excel"))
                });
            break;
        
      }
    } else {
      tmp = React.createElement(React.Fragment, undefined, React.createElement("span", {
                className: El.Cn.concat(styles.icon, Icon.style(undefined, undefined, "file-excel"))
              }), React.createElement("span", {
                className: iconCN(match$1._0)
              }));
    }
  } else if (typeof match$1 !== "object") {
    switch (match$1) {
      case "Waiting" :
      case "InProgress" :
          exit = 1;
          break;
      case "Canceled" :
          tmp = React.createElement("span", {
                className: El.Cn.concat(styles.icon, Icon.style(undefined, undefined, "pen-square"))
              });
          break;
      
    }
  } else {
    var success = match$1._0;
    var exit$1 = 0;
    if (success && match$2 !== undefined && match$2.length > 0) {
      tmp = React.createElement(React.Fragment, undefined, React.createElement("span", {
                className: El.Cn.concat(styles.icon, Icon.style(undefined, undefined, "pen-square"))
              }), React.createElement("span", {
                className: iconCN(false)
              }));
    } else {
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      tmp = React.createElement(React.Fragment, undefined, React.createElement("span", {
                className: El.Cn.concat(styles.icon, Icon.style(undefined, undefined, "pen-square"))
              }), React.createElement("span", {
                className: iconCN(success)
              }));
    }
    
  }
  if (exit === 1) {
    tmp = React.createElement("span", {
          className: El.Cn.concat(styles.icon, Icon.style(undefined, true, "spinner"))
        });
  }
  return React.createElement("div", {
              className: styles.iconContainer
            }, tmp);
}

var make = BackgroundTaskIcon;

export {
  make ,
}
/* styles Not a pure module */
