

import * as JsonDecode from "../../../../../libraries/JsonDecode.mjs";
import * as Schedule_Types_Job from "../../types/job/Schedule_Types_Job.mjs";
import * as Schedule_Types_Shift from "../../types/Schedule_Types_Shift.mjs";

function decode(jobDuration) {
  return JsonDecode.map(Schedule_Types_Job.DecodedJob.decode(jobDuration), (function (r) {
                var interval = r.interval;
                if (interval !== undefined) {
                  return Schedule_Types_Job.ScheduledJob.make(r.assignees, r.core, interval);
                } else {
                  return JsonDecode.cannotDecode("scheduled job");
                }
              }));
}

var DecodeScheduledJob = {
  decode: decode
};

function decode$1(jobDuration) {
  return JsonDecode.object(function (field) {
              return {
                      jobsLimit: field.required("jobsLimit", Schedule_Types_Job.Warning.Limit.decode),
                      jobs: field.required("jobs", JsonDecode.array(decode(jobDuration)))
                    };
            });
}

var ScheduledJobs = {
  decode: decode$1
};

function decode$2(jobDuration) {
  return JsonDecode.object(function (field) {
              return {
                      jobs: field.required("jobs", JsonDecode.array(Schedule_Types_Job.UnscheduledJob.decode(jobDuration))),
                      jobsLimit: field.required("jobsLimit", Schedule_Types_Job.Warning.Limit.decode),
                      jobsAfter: field.required("after", JsonDecode.option(Schedule_Types_Job.JobsAfter.decode))
                    };
            });
}

var UnscheduledJobs = {
  decode: decode$2
};

var decode$3 = JsonDecode.object(function (field) {
      return {
              workersTimelines: field.required("workersTimelines", JsonDecode.array(Schedule_Types_Shift.ScheduleTimeline.decode)),
              teamTimelines: field.required("teamTimelines", JsonDecode.array(Schedule_Types_Shift.ScheduleTimeline.decode))
            };
    });

var ScheduleTimeline = {
  decode: decode$3
};

export {
  DecodeScheduledJob ,
  ScheduledJobs ,
  UnscheduledJobs ,
  ScheduleTimeline ,
}
/* decode Not a pure module */
