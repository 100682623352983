import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from 'planado/actions/admin/recurring_jobs_show'
import Form from 'planado/components/admin/recurring_jobs/show/form'
import JobInfo from 'planado/components/admin/recurring_jobs/show/job_info'
import RecurringInfo from 'planado/components/admin/recurring_jobs/show/recurring_info'
import { GoogleAddressField } from 'planado/components/address_input'
import ClientSelect from 'planado/components/input/client_select'
import Contacts from 'planado/components/contacts/list'
import {
  addContact,
  removeContact,
  editContact,
} from 'planado/actions/contacts'
import AddressInput_RegularAddress from 'rscrpt/components/address_input/regular_address/AddressInput_RegularAddress.mjs'
import * as AddressInput_Types from 'rscrpt/components/address_input/AddressInput_Types.mjs'

const FormC = {
  mapStateToProps: ({ recurringJob, isNew, errors, locked }, _ownProps) => ({
    job: recurringJob,
    template: recurringJob.template,
    isNew,
    locked,
    errors
  }),
  mapDispatchToProps: (dispatch, _ownProps) => ({
    actions: bindActionCreators(actions, dispatch),
  }),
}

export const FormLink = connect(
  FormC.mapStateToProps,
  FormC.mapDispatchToProps
)(Form)

const JobInfoC = {
  mapStateToProps: (
    { isNew, recurringJob, errors, types, mapOptions },
    _ownProps
  ) => ({
    job: recurringJob,
    errors,
    types,
    mapOptions,
    isNew,
  }),
  mapDispatchToProps: (dispatch, _ownProps) => ({
    actions: bindActionCreators(actions, dispatch),
  }),
}

export const JobInfoLink = connect(
  JobInfoC.mapStateToProps,
  JobInfoC.mapDispatchToProps
)(JobInfo)

const RecurringInfoC = {
  mapStateToProps: ({ recurringJob, errors }, _ownProps) => ({
    job: recurringJob,
    errors: errors,
  }),
  mapDispatchToProps: (dispatch, _ownProps) => ({
    actions: bindActionCreators(actions, dispatch),
  }),
}

export const RecurringInfoLink = connect(
  RecurringInfoC.mapStateToProps,
  RecurringInfoC.mapDispatchToProps
)(RecurringInfo)

const AddressInputC = {
  mapStateToProps: (state, { readOnly, wire }) => ({
    initialAddress:
      state.recurringJob.address === null
        ? AddressInput_Types.RegularAddress.makeFromString('')
        : state.recurringJob.address,
    readOnly,
    wire,
    constraints:
      Object.keys(state.mapOptions.addressConstraints).length === 0
        ? undefined
        : state.mapOptions.addressConstraints,
  }),
  mapDispatchToProps: (dispatch, _ownProps) => ({
    onChange: (address) => {
      dispatch(actions.setField('address', address))
    },
  }),
}

export const DadataAddressLink = connect(
  AddressInputC.mapStateToProps,
  AddressInputC.mapDispatchToProps
)(AddressInput_RegularAddress)

const GoogleAddressC = {
  mapStateToProps: ({ recurringJob, mapOptions, context }, _ownProps) => ({
    constraints: mapOptions.addressConstraints,
    mapType: mapOptions.type,
    mapApiKey: mapOptions.key,
    attributes: recurringJob.address,
    xhr: false,
    context,
  }),
  mapDispatchToProps: (dispatch, _ownProps) => ({
    onChange: (address) => dispatch(actions.setField('address', address)),
  }),
}

export const GoogleAddressLink = connect(
  GoogleAddressC.mapStateToProps,
  GoogleAddressC.mapDispatchToProps
)(GoogleAddressField)

const ClientSelectC = {
  mapStateToProps: ({ recurringJob: { client, site } }, _ownProps) => {
    let value
    if (site) {
      const label = client ? `${site.name} — ${client.name}` : site.name

      value = {
        label: label,
        value: site.uuid,
        type: 'site',
        data: null,
        address: null,
      }
    } else if (client) {
      value = {
        label: client.name,
        value: client.uuid,
        type: 'client',
        data: null,
        address: null,
      }
    } else {
      value = null
    }

    return {
      minimumResultsForSearch: 0,
      value,
      type: 'site_and_client',
    }
  },
  mapDispatchToProps: (dispatch, _ownProps) => ({
    onValueChange: (clientOrSite) => {
      if (clientOrSite && clientOrSite.type === 'site') {
        dispatch(actions.setSite(clientOrSite))
      } else if (clientOrSite && clientOrSite.type === 'client') {
        dispatch(actions.setClient(clientOrSite))
      } else {
        dispatch(actions.cancelSetClientOrSite())
      }
    },
  }),
}

export const ClientSelectLink = connect(
  ClientSelectC.mapStateToProps,
  ClientSelectC.mapDispatchToProps
)(ClientSelect)

const inputId = (name) => name
const inputName = (name) => name

const ContactsC = {
  mapStateToProps: ({ recurringJob: { contacts } }, _ownProps) => ({
    contacts,
    inputId,
    inputName,
    errors: {},
  }),
  mapDispatchToProps: (dispatch, _ownProps) => ({
    addContact: (e) => {
      e.preventDefault()
      dispatch(addContact())
    },
    removeContact: (index) => dispatch(removeContact(index)),
    editContact: (index, value) => dispatch(editContact(index, value)),
  }),
}

export const ContactsLink = connect(
  ContactsC.mapStateToProps,
  ContactsC.mapDispatchToProps
)(Contacts)
