

import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Checkbox from "../../inputs/Checkbox.mjs";
import * as Optional from "../../common/Optional.mjs";
import * as Context_Types from "../../../context/Context_Types.mjs";
import * as Template_SettingsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/template/Template_Settings.module.css";

var title$p = Utils.Translations.tr("js.templates.settings.title");

var trackLabel$p = Utils.Translations.t("js.templates.settings.track_movements.label");

var trackHint$p = Utils.Translations.tr("js.templates.settings.track_movements.hint");

var creationLabel$p = Utils.Translations.t("js.templates.settings.creation_from_mobile.label");

var creationHint$p = Utils.Translations.tr("js.templates.settings.creation_from_mobile.hint");

var styles = Template_SettingsModuleCss;

function Template_Settings(Props) {
  var wire = Props.wire;
  var trackMovements = Props.trackMovements;
  var setTrackMovements = Props.setTrackMovements;
  var canBeUsedOnMobile = Props.canBeUsedOnMobile;
  var setCanBeUsedOnMobile = Props.setCanBeUsedOnMobile;
  var trackingEnabled = Context_Types.Features.hasFlag("tracking", wire.ctx.features);
  var createJobsEnabled = Context_Types.Features.hasFlag("createJobsInApp", wire.ctx.features);
  if (trackingEnabled || createJobsEnabled) {
    return React.createElement("section", {
                className: styles.section
              }, React.createElement("h2", {
                    className: styles.title
                  }, title$p(wire.ctx)), React.createElement(Optional.make, {
                    show: trackingEnabled,
                    children: React.createElement("div", {
                          className: styles.form
                        }, React.createElement(Checkbox.make, {
                              onToggle: (function (param) {
                                  setTrackMovements(!trackMovements);
                                }),
                              checked: trackMovements,
                              label: trackLabel$p(wire.ctx)
                            }), React.createElement("div", {
                              className: styles.hint
                            }, trackHint$p(wire.ctx)))
                  }), React.createElement(Optional.make, {
                    show: createJobsEnabled,
                    children: React.createElement("div", {
                          className: styles.form
                        }, React.createElement(Checkbox.make, {
                              onToggle: (function (param) {
                                  setCanBeUsedOnMobile(!canBeUsedOnMobile);
                                }),
                              checked: canBeUsedOnMobile,
                              label: creationLabel$p(wire.ctx)
                            }), React.createElement("div", {
                              className: styles.hint
                            }, creationHint$p(wire.ctx)))
                  }));
  } else {
    return null;
  }
}

var make = Template_Settings;

var $$default = Template_Settings;

export {
  make ,
  $$default as default,
}
/* title' Not a pure module */
