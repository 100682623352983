

import * as El from "../../../../El.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as Bindings_Slate from "../../../../../bindings/Bindings_Slate.mjs";
import * as Liquid_EditorTypes from "../../lib/Liquid_EditorTypes.mjs";
import * as Liquid_EditorSuggestions from "./suggestions/Liquid_EditorSuggestions.mjs";
import * as Liquid_EditorElementModuleCss from "/home/runner/work/planado/planado/client/rescript/libraries/liquid/editor/components/element/Liquid_EditorElement.module.css";

var styles = Liquid_EditorElementModuleCss;

function useIsActive(variable, substitutions) {
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var selected = Bindings_Slate.React.useSelected();
  var focused = Bindings_Slate.React.useFocused();
  var isActive = selected && focused;
  var match$1 = React.useState(function () {
        
      });
  var setSuggestions = match$1[1];
  React.useEffect((function () {
          if (isActive) {
            var nextSuggestions = Liquid_EditorTypes.Suggestion.make(ctx, variable, substitutions);
            if (Caml_obj.notequal(nextSuggestions, [])) {
              setSuggestions(function (param) {
                    return nextSuggestions;
                  });
            }
            
          } else {
            setSuggestions(function (param) {
                  
                });
          }
        }), [isActive]);
  return [
          isActive,
          match$1[0]
        ];
}

function Liquid_EditorElement$Variable(Props) {
  var substitutions = Props.substitutions;
  var isValidate = Props.isValidate;
  var variable = Props.variable;
  var element = Props.element;
  var attributes = Props.attributes;
  var keydownSubscription = Props.keydownSubscription;
  var children = Props.children;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var editor = Bindings_Slate.React.useSlateStatic();
  var match$1 = useIsActive(variable, substitutions);
  var suggestions = match$1[1];
  var isActive = match$1[0];
  var remove = function (_event) {
    var path = Bindings_Slate.React.ReactEditor.findPath(editor, element);
    Bindings_Slate.SlateTransforms.removeNodes(editor, Bindings_Slate.SlateTransforms.RemoveNodeOptions.make(path, undefined));
  };
  var hasError = isValidate && !isActive && Liquid_EditorTypes.Suggestion.hasSuggestions(ctx, variable, substitutions);
  var className = El.Cn.concat(El.Cn.concat(styles.variable, isActive ? styles.active : ""), hasError ? styles.error : "");
  var element$1 = React.createElement("span", {
        className: className
      }, Liquid_EditorTypes.formatVariable(ctx, substitutions, variable), React.createElement("span", {
            className: styles.variableIcon,
            onClick: remove
          }), suggestions !== undefined ? React.createElement(Liquid_EditorSuggestions.make, {
              editor: editor,
              substitutions: substitutions,
              variable: variable,
              suggestions: suggestions,
              keydownSubscription: keydownSubscription
            }) : null, children);
  return React.cloneElement(element$1, attributes);
}

function Liquid_EditorElement$Paragraph(Props) {
  var attributes = Props.attributes;
  var children = Props.children;
  return React.cloneElement(React.createElement("div", {
                  className: styles.paragraph
                }, children), attributes);
}

function Liquid_EditorElement(Props) {
  var substitutions = Props.substitutions;
  var keydownSubscription = Props.keydownSubscription;
  var isValidate = Props.isValidate;
  var param = Props.props;
  var element = param.element;
  var children = param.children;
  var attributes = param.attributes;
  var match = Bindings_Slate.$$Element.payload(element);
  if (match !== undefined) {
    if (typeof match !== "object") {
      return React.createElement(Liquid_EditorElement$Paragraph, {
                  attributes: attributes,
                  children: children
                });
    } else {
      return React.createElement(Liquid_EditorElement$Variable, {
                  substitutions: substitutions,
                  isValidate: isValidate,
                  variable: match._0,
                  element: element,
                  attributes: attributes,
                  keydownSubscription: keydownSubscription,
                  children: children
                });
    }
  } else {
    return children;
  }
}

var make = Liquid_EditorElement;

export {
  make ,
}
/* styles Not a pure module */
