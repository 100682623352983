// extracted by mini-css-extract-plugin
export var active = "active-vD1QpSezR0NCeCkUmzAu";
export var chipWarning = "chip-warning-VF38xYoRRFNgAlCb21BQ";
export var controls = "controls-IStEqtvo2AHJpyOkPjyh";
export var controlsSection = "controls-section-rD0ODaH9ZZvd3o6J_UbL";
export var foreman = "foreman-Ke_AOziKPcOuvLBtzr7j";
export var noAssignees = "no-assignees-tEYbt1sBj1CFLYk2W9YA";
export var openUp = "open-up-IvFT0DU5gcLb8YMTRMIg";
export var option = "option-SGnXLLZICcv4ukvs68q7";
export var options = "options-vALVIZC3JdpUjWArRHt3";
export var optionsContainer = "options-container-mPB0WrqA51oxj7aQdNSM";
export var team = "team-Fr2BDbkPWAk3zernXMgB";
export var teamIcon = "team-icon-emTlst3la6olUdRQx7Yn";
export var teamMember = "team-member-ba5bF2qPMpNeoq9sYKfg";
export var teamMembers = "team-members-dlHpQnDCrNW3_a_t_8Li";
export var teamName = "team-name-kFkJSF6dHmME7KY_TVk8";
export var wrapper = "wrapper-SXpOFbkN7J8m0sxXjTAq";