

import * as El from "../../../../../../../../libraries/El.mjs";
import * as Utils from "../../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as AppContext from "../../../../../../../../context/AppContext.mjs";
import * as Schedule_State from "../../../../../../lib/state/Schedule_State.mjs";
import * as WeekTimeline_UnassignedEmptyBlockModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/unassigned/components/empty_block/WeekTimeline_UnassignedEmptyBlock.module.css";

var empty$p = Utils.Translations.tr("js.schedule.v2.jobs.no_unassigned_added");

var styles = WeekTimeline_UnassignedEmptyBlockModuleCss;

function WeekTimeline_UnassignedEmptyBlock(Props) {
  var compactMode = Props.compactMode;
  var match = AppContext.useWire();
  var loading = Schedule_State.useScheduledJobsLoading();
  var className = compactMode ? styles.hiddenContainer : El.Cn.concat(styles.container, loading ? styles.loading : "");
  if (loading) {
    return React.createElement("div", {
                className: className
              });
  } else {
    return React.createElement("div", {
                className: className
              }, empty$p(match.ctx));
  }
}

var make = WeekTimeline_UnassignedEmptyBlock;

export {
  make ,
}
/* empty' Not a pure module */
