

import * as RCore from "../libraries/RCore.mjs";
import * as Core__Float from "@rescript/core/lib/es6/src/Core__Float.mjs";

var onlyNumbersRe = /[^0-9.,-\s]+/;

var numericWithDelimiters = /^[-\d\s.,]+$/;

function removeCharacters(v) {
  return v.trim().replace(onlyNumbersRe, "");
}

function hasDot(__x) {
  return __x.includes(".");
}

function hasComma(__x) {
  return __x.includes(",");
}

function splitBySpace(__x) {
  return __x.split(" ");
}

function splitByComma(__x) {
  return __x.split(",");
}

function replaceCommaWithDot(__x) {
  return __x.replace(",", ".");
}

function $$parseFloat(v) {
  return Core__Float.fromString(v.trim());
}

var Helpers = {
  latLimit: 90.0,
  lonLimit: 180.0,
  onlyNumbersRe: onlyNumbersRe,
  numericWithDelimiters: numericWithDelimiters,
  removeCharacters: removeCharacters,
  hasDot: hasDot,
  hasComma: hasComma,
  splitBySpace: splitBySpace,
  splitByComma: splitByComma,
  replaceCommaWithDot: replaceCommaWithDot,
  $$parseFloat: $$parseFloat
};

function isGeolocationString(x) {
  return numericWithDelimiters.test(x);
}

function validateLatLon(latlon) {
  if (latlon.length !== 2) {
    return ;
  }
  var lat = latlon[0];
  var lon = latlon[1];
  if (Math.abs(lat) <= 90.0 && Math.abs(lon) <= 180.0) {
    return [
            lat,
            lon
          ];
  } else if (Math.abs(lat) <= 180.0 && Math.abs(lon) <= 90.0) {
    return [
            lon,
            lat
          ];
  } else {
    return ;
  }
}

function parseString(input) {
  var string = removeCharacters(input);
  if (string.includes(".") && string.includes(",")) {
    return RCore.$$Array.filterMap(string.split(","), $$parseFloat);
  } else if (string.includes(".")) {
    return RCore.$$Array.filterMap(string.split(" "), $$parseFloat);
  } else {
    return RCore.$$Array.filterMap(string.split(" "), (function (v) {
                  var v$1 = v.replace(",", ".");
                  return Core__Float.fromString(v$1.trim());
                }));
  }
}

function parse(v) {
  return validateLatLon(parseString(v));
}

export {
  Helpers ,
  isGeolocationString ,
  validateLatLon ,
  parseString ,
  parse ,
}
/* No side effect */
