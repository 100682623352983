

import * as Hint from "../../common/Hint/Hint.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as $$String from "rescript/lib/es6/string.js";
import * as Distance from "../../../libraries/Distance.mjs";
import * as DistanceTrackedModuleCss from "/home/runner/work/planado/planado/client/rescript/components/inputs/distance_tracked/DistanceTracked.module.css";

function distanceTraveled$p(ctx) {
  var match = ctx.localizator.measurementSystem;
  if (typeof match !== "object" && match === "Imperial") {
    return Utils.Translations.tr("js.jobs.labels.distance_traveled_imperial")(ctx);
  } else {
    return Utils.Translations.tr("js.jobs.labels.distance_traveled")(ctx);
  }
}

var distanceTraveledHint$p = Utils.Translations.t("js.jobs.labels.distance_traveled_hint");

var styles = DistanceTrackedModuleCss;

function DistanceTracked(Props) {
  var param = Props.wire;
  var odometerM = Props.odometerM;
  var setOdometerM = Props.setOdometerM;
  var ctx = param.ctx;
  var inputId = React.useMemo((function () {
          return Uuid.toString(Uuid.createV4());
        }), []);
  var $$default = React.useMemo((function () {
          return Distance.format(undefined, 0.0, ctx);
        }), []);
  var match = React.useState(function () {
        return Distance.format(undefined, Distance.mToKm(odometerM), ctx);
      });
  var setValue = match[1];
  var onChange = function ($$event) {
    var value = $$event.target.value;
    var match = Distance.parse(value, ctx);
    if (match !== undefined) {
      return setValue(function (param) {
                  return value;
                });
    }
    
  };
  var onBlur = function ($$event) {
    var value = $$event.target.value;
    var valueKm = Distance.parse($$String.trim(value), ctx);
    if (valueKm !== undefined) {
      var formatted = Distance.format(undefined, valueKm, ctx);
      setValue(function (param) {
            return formatted;
          });
      return setOdometerM(Distance.kmToM(valueKm));
    }
    setOdometerM(0.0);
    setValue(function (param) {
          return $$default;
        });
  };
  return React.createElement("div", {
              className: "pd-form-group"
            }, React.createElement("label", {
                  className: "pd-label",
                  htmlFor: inputId
                }, distanceTraveled$p(ctx)), React.createElement(Hint.make, {
                  content: distanceTraveledHint$p(ctx),
                  classes: styles.hint,
                  position: "Top"
                }), React.createElement("input", {
                  className: "pd-form-control",
                  id: inputId,
                  value: match[0],
                  onBlur: onBlur,
                  onChange: onChange
                }));
}

var make = DistanceTracked;

var $$default = DistanceTracked;

export {
  make ,
  $$default as default,
}
/* distanceTraveledHint' Not a pure module */
