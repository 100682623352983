import React from 'react'
import { dictionaryPath } from 'planado/routes.js'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import Link from 'rscrpt/components/common/Link/Link.mjs'

const Row = withContext(({ ctx, uuid, name, values }) => (
  <tr>
    <td>
      <Link wire={ctx.wire} pathname={dictionaryPath(uuid)}>
        {name}
      </Link>
    </td>

    <td>
      {values
        .slice(0, 6)
        .map((v, i) => (i < 5 ? v.name : '...'))
        .join(', ')}
    </td>
  </tr>
))

const TableHeading = withContext(({ ctx }) => (
  <thead>
    <tr>
      <th className="dict-list__table-name">
        {ctx.t('js.admin.dictionaries.name')}
      </th>
      <th className="dict-list__table-values">
        {ctx.t('js.admin.dictionaries.values')}
      </th>
    </tr>
  </thead>
))

const Table = withContext(({ dictionaries, ctx }) => (
  <div className="dict-list__content">
    {dictionaries.length === 0 ? (
      <div className="dict-list__content-empty">
        <h1>{ctx.t('js.admin.dictionaries.empty.headline')}</h1>
        <p>{ctx.t('js.admin.dictionaries.empty.content')}</p>
      </div>
    ) : (
      <table className="dict-list__table">
        <TableHeading />

        <tbody>
          {dictionaries.map((d, i) => (
            <Row key={i} {...d} />
          ))}
        </tbody>
      </table>
    )}
  </div>
))

export default Table
