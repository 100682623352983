

import * as El from "../../../../libraries/El.mjs";
import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../../../libraries/backend/Backend.mjs";
import * as DomUtils from "../../../../DomUtils.mjs";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as JsonDecode from "../../../../libraries/JsonDecode.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import Search_input from "planado/components/input/search_input";
import * as Pages_Addresses_SearchModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/addresses/header/search/Pages_Addresses_Search.module.css";

var decode = JsonDecode.map(JsonDecode.array(JsonDecode.object(function (field) {
              return {
                      type_: field.required("type", JsonDecode.string),
                      siteUuid: RCore.$$Option.flatten(field.optional("siteUuid", JsonDecode.option(Uuid.decode))),
                      clientUuid: RCore.$$Option.flatten(field.optional("clientUuid", JsonDecode.option(Uuid.decode))),
                      clientName: RCore.$$Option.flatten(field.optional("clientName", JsonDecode.option(JsonDecode.string))),
                      name: field.required("name", JsonDecode.string),
                      address: field.required("address", JsonDecode.string)
                    };
            })), (function (addresses) {
        return RCore.$$Array.filterMap(addresses, (function (address) {
                      switch (address.type_) {
                        case "client" :
                            var uuid = address.clientUuid;
                            if (uuid !== undefined) {
                              return {
                                      NAME: "client",
                                      VAL: {
                                        uuid: Caml_option.valFromOption(uuid),
                                        name: address.name,
                                        address: address.address
                                      }
                                    };
                            } else {
                              return ;
                            }
                        case "site" :
                            var uuid$1 = address.siteUuid;
                            if (uuid$1 !== undefined) {
                              return {
                                      NAME: "site",
                                      VAL: {
                                        uuid: Caml_option.valFromOption(uuid$1),
                                        name: address.name + RCore.$$Option.mapWithDefault(address.clientName, "", (function (name) {
                                                return " — " + name;
                                              })),
                                        address: address.address
                                      }
                                    };
                            } else {
                              return ;
                            }
                        default:
                          return ;
                      }
                    }));
      }));

function get(wire, query) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Address",
                    VAL: {
                      NAME: "Search",
                      VAL: query
                    }
                  }
                }), decode);
}

var styles = Pages_Addresses_SearchModuleCss;

var placeholder$p = Utils.Translations.t("js.addresses.index.search");

function Pages_Addresses_Search(Props) {
  var wire = AppContext.useWire();
  var openModal = wire.openModal;
  var match = React.useState(function () {
        return {
                fetching: false,
                possibleValue: []
              };
      });
  var setState = match[1];
  var match$1 = match[0];
  var getPossibleValue = function (query) {
    setState(function (state) {
          return {
                  fetching: true,
                  possibleValue: state.possibleValue
                };
        });
    Backend.$$finally(get(wire, query), (function (response) {
            if (response.TAG !== "Ok") {
              return setState(function (state) {
                          return {
                                  fetching: false,
                                  possibleValue: state.possibleValue
                                };
                        });
            }
            var possibleValue = response._0;
            setState(function (param) {
                  return {
                          fetching: false,
                          possibleValue: possibleValue
                        };
                });
          }));
  };
  var selectValue = function (_index, address) {
    if (address.NAME === "client") {
      return openModal({
                  NAME: "Client",
                  VAL: {
                    NAME: "Edit",
                    VAL: address.VAL.uuid
                  }
                });
    } else {
      return openModal({
                  NAME: "Site",
                  VAL: {
                    NAME: "Edit",
                    VAL: address.VAL.uuid
                  }
                });
    }
  };
  var itemMarkup = function (address, isHighlighted, props) {
    return React.createElement(DomUtils.Spread.make, {
                props: props,
                children: React.createElement("div", {
                      className: El.Cn.concatIf(styles.item, [
                            isHighlighted,
                            styles.active
                          ])
                    }, address.VAL.name, React.createElement("div", {
                          className: El.Cn.concatIf(styles.address, [
                                isHighlighted,
                                styles.active
                              ])
                        }, address.VAL.address)),
                key: Uuid.toString(address.VAL.uuid)
              });
  };
  return React.createElement(Search_input, {
              fetching: match$1.fetching,
              possibleValue: match$1.possibleValue,
              getPossibleValue: getPossibleValue,
              selectValue: selectValue,
              itemMarkup: itemMarkup,
              placeholder: placeholder$p(wire.ctx),
              delay: 300,
              minLength: 3
            });
}

var make = Pages_Addresses_Search;

export {
  make ,
}
/* decode Not a pure module */
