

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import Templates from "planado/stores/admin/templates";

var Resolution = {};

var decode = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var Template = {
  decode: decode
};

function make(uuid, name) {
  return {
          uuid: uuid,
          name: name
        };
}

var TemplateRecurringJob = {
  make: make
};

var State = {};

var Index = {
  State: State
};

var Store = {
  make: (function (prim0, prim1) {
      return Templates(prim0, prim1);
    })
};

export {
  Resolution ,
  Template ,
  TemplateRecurringJob ,
  Index ,
  Store ,
}
/* decode Not a pure module */
