import React from 'react'
import { YandexMarker } from './yandex'
import { GoogleMarker } from './google'

export default ({ mapType, ...rest }) => {
  switch (mapType) {
    case 'yandex':
      return <YandexMarker {...rest} />
    case 'google':
      return <GoogleMarker {...rest} />
    default:
      throw `Unknown placemark type ${mapType}`
  }
}
