

import * as React from "react";
import * as FileInput from "../file_input/FileInput.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function calcLockDelayMs(startUploadAt) {
  var diffMs = Date.now() - startUploadAt.current;
  if (500.0 > diffMs) {
    return 500.0 - diffMs;
  } else {
    return 0.0;
  }
}

function JobAttachmentInput(Props) {
  var fieldName = Props.fieldName;
  var files = Props.files;
  var addFile = Props.addFile;
  var removeFile = Props.removeFile;
  var setLock = Props.setLock;
  var readOnly = Props.readOnly;
  var maxFilesInField = Props.maxFilesInField;
  var sizeLimitMb = Props.sizeLimitMb;
  var backendErrors = Props.errors;
  var onlyImages = Props.onlyImages;
  var wrapperClass = Props.wrapperClass;
  var showCarouselImage = Props.showCarouselImage;
  var startUploadAt = React.useRef(0.0);
  var onStartUpload = React.useCallback((function () {
          startUploadAt.current = Date.now();
          setLock(true);
        }), [setLock]);
  var onFinishUpload = React.useCallback((function (file) {
          if (file.TAG === "Ok") {
            var file$1 = file._0;
            addFile({
                  uuid: file$1.uuid,
                  name: file$1.name,
                  url: file$1.url,
                  previewUrl: file$1.previewUrl,
                  size: file$1.size,
                  width: file$1.width,
                  height: file$1.height
                });
          }
          setTimeout((function () {
                  setLock(false);
                }), calcLockDelayMs(startUploadAt));
        }), [
        addFile,
        setLock
      ]);
  var files$1 = React.useMemo((function () {
          return files.map(function (file) {
                      return {
                              uuid: file.uuid,
                              name: file.name,
                              url: file.url,
                              previewUrl: file.previewUrl,
                              size: file.size,
                              width: file.width,
                              height: file.height
                            };
                    });
        }), [files]);
  var tmp = {
    files: files$1,
    onStartUpload: onStartUpload,
    onFinishUpload: onFinishUpload,
    removeFile: removeFile,
    readOnly: readOnly,
    maxFilesInField: maxFilesInField,
    showCarouselImage: showCarouselImage
  };
  if (fieldName !== undefined) {
    tmp.fieldName = fieldName;
  }
  if (sizeLimitMb !== undefined) {
    tmp.sizeLimitMb = sizeLimitMb;
  }
  if (backendErrors !== undefined) {
    tmp.backendErrors = Caml_option.valFromOption(backendErrors);
  }
  if (onlyImages !== undefined) {
    tmp.onlyImages = onlyImages;
  }
  if (wrapperClass !== undefined) {
    tmp.wrapperClass = wrapperClass;
  }
  return React.createElement(FileInput.make, tmp);
}

var make = React.memo(JobAttachmentInput);

export {
  make ,
}
/* make Not a pure module */
