

import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Subscription from "../../../../libraries/Subscription.mjs";
import * as Context_Types from "../../../../context/Context_Types.mjs";
import * as Assignee_Types from "../../../../types/Assignee_Types.mjs";
import * as MultipleAssignee from "../../../../types/MultipleAssignee.mjs";
import * as Schedule_Types_Job from "./job/Schedule_Types_Job.mjs";
import * as Schedule_Types_Settings from "./Schedule_Types_Settings.mjs";
import * as Schedule_Types_Assignees from "./Schedule_Types_Assignees.mjs";

function hasTerritory(assignee, filter) {
  return Schedule_Types_Settings.Filter.territories(filter).some(function (uuid) {
              return Caml_obj.equal(Assignee_Types.Assignee.territoryUuid(assignee), Caml_option.some(uuid));
            });
}

function hasSkills(assignee, filter) {
  var match = filter.skills.mode;
  if (match === "Any") {
    return Schedule_Types_Settings.Filter.skillUuids(filter).some(function (uuid) {
                return Assignee_Types.Assignee.skillUuids(assignee).some(function (skillUuid) {
                            return Uuid.equal(skillUuid, uuid);
                          });
              });
  } else {
    return Schedule_Types_Settings.Filter.skillUuids(filter).every(function (uuid) {
                return Assignee_Types.Assignee.skillUuids(assignee).some(function (skillUuid) {
                            return Uuid.equal(skillUuid, uuid);
                          });
              });
  }
}

function filter(wire, filter$1) {
  var assignees = Schedule_Types_Assignees.filter(wire, filter$1.assignees);
  if (Caml_obj.notequal(filter$1.skills.uuids, []) && Caml_obj.notequal(filter$1.territories, [])) {
    return RCore.$$Array.keep(assignees, (function (assignee) {
                  if (hasTerritory(assignee, filter$1)) {
                    return hasSkills(assignee, filter$1);
                  } else {
                    return false;
                  }
                }));
  } else if (Caml_obj.notequal(filter$1.skills.uuids, [])) {
    return RCore.$$Array.keep(assignees, (function (assignee) {
                  return hasSkills(assignee, filter$1);
                }));
  } else if (Caml_obj.notequal(filter$1.territories, [])) {
    return RCore.$$Array.keep(assignees, (function (assignee) {
                  return hasTerritory(assignee, filter$1);
                }));
  } else {
    return assignees;
  }
}

function length(wire, filterValue) {
  return filter(wire, filterValue).length;
}

var Assignees = {
  filter: filter,
  length: length
};

function key(assignee) {
  return Uuid.toString(Assignee_Types.Assignee.uuid(assignee));
}

function make(wire, filter$1, jobs, scheduleTimelines) {
  var jobBars = {};
  jobs.forEach(function (job) {
        var assignees = Schedule_Types_Job.AssignedJob.assignees(job);
        var mainAssigneeKey = RCore.$$Option.map(MultipleAssignee.mainAssigneeUuid(assignees), Uuid.toString);
        var additionalKeys = Context_Types.Features.hasFlag("showOldTimeline", wire.ctx.features) ? [] : MultipleAssignee.workerUuids(assignees).map(Uuid.toString);
        var keys = RCore.$$Option.getOr(RCore.$$Option.map(mainAssigneeKey, (function (main) {
                    return Belt_Array.concatMany([
                                [main],
                                additionalKeys
                              ]);
                  })), additionalKeys);
        keys.forEach(function (key) {
              var jobs = Js_dict.get(jobBars, key);
              if (jobs !== undefined) {
                jobBars[key] = Belt_Array.concatMany([
                      jobs,
                      [job]
                    ]);
              } else {
                jobBars[key] = [job];
              }
            });
      });
  var intervalsBars = {};
  scheduleTimelines.forEach(function (workerTimeline) {
        var key = Uuid.toString(workerTimeline.entityUuid);
        var intervals = Js_dict.get(intervalsBars, key);
        if (intervals !== undefined) {
          intervalsBars[key] = Belt_Array.concatMany([
                intervals,
                workerTimeline.timeline
              ]);
        } else {
          intervalsBars[key] = workerTimeline.timeline;
        }
      });
  return filter(wire, filter$1).map(function (assignee) {
              var jobs = RCore.$$Option.getOr(Js_dict.get(jobBars, key(assignee)), []);
              var workingIntervals = RCore.$$Option.getOr(Js_dict.get(intervalsBars, key(assignee)), []);
              return {
                      assignee: assignee,
                      jobs: jobs,
                      workingIntervals: workingIntervals
                    };
            });
}

function make$1() {
  return {
          onMouseEnter: Subscription.make(),
          onMouseMove: Subscription.make(),
          onMouseLeave: Subscription.make(),
          onScrollStart: Subscription.make(),
          onScrollEnd: Subscription.make(),
          onActiveAssigneeChanged: Subscription.make()
        };
}

var TimelineSubscriptions = {
  make: make$1
};

var JobSize = {};

var Bar = {};

var Bars = {
  Assignees: Assignees,
  make: make
};

export {
  Bar ,
  Bars ,
  TimelineSubscriptions ,
  JobSize ,
}
/* Uuid Not a pure module */
