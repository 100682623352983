

import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as $$String from "rescript/lib/es6/string.js";
import * as Backend from "../../../../libraries/backend/Backend.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as PromiseF from "../../../../libraries/PromiseF.mjs";
import * as JsonDecode from "../../../../libraries/JsonDecode.mjs";
import * as Catalog_Types from "../Catalog_Types.mjs";
import * as Shared_Lib_IO from "../../../../shared/lib/Shared_Lib_IO.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              categories: field.required("categories", JsonDecode.array(Catalog_Types.Category.decode)),
              services: field.required("services", JsonDecode.array(Catalog_Types.Service.decode))
            };
    });

function index(wire) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Services",
                    VAL: "Index"
                  }
                }), decode);
}

function encodeForm(form) {
  return {
          name: RCore.$$Option.map(form.name, $$String.trim),
          description: Js_null.fromOption(RCore.$$Option.map(form.description, $$String.trim)),
          categoryUuid: Js_null.fromOption(RCore.$$Option.map(form.categoryUuid, Uuid.toString)),
          priceDefault: Js_null.fromOption(form.priceDefault),
          currency: form.currency,
          externalId: Js_null.fromOption(RCore.$$Option.map(form.externalId, $$String.trim)),
          archived: form.archived
        };
}

function create(wire, service) {
  return Backend.decode(Backend.post(undefined, undefined, undefined, true, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Services",
                    VAL: "Index"
                  }
                }, {
                  NAME: "Obj",
                  VAL: encodeForm(service)
                }), Catalog_Types.Service.decode);
}

function update(wire, uuid, service) {
  return Backend.decode(Backend.patch(undefined, undefined, undefined, true, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Services",
                    VAL: {
                      NAME: "Update",
                      VAL: uuid
                    }
                  }
                }, {
                  NAME: "Obj",
                  VAL: encodeForm(service)
                }), Catalog_Types.Service.decode);
}

var decode$1 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var decode$2 = JsonDecode.object(function (field) {
      return field.required("errors", JsonDecode.object(function (field) {
                      return {
                              templates: field.required("templates", JsonDecode.array(decode$1))
                            };
                    }));
    });

function $$delete(wire, uuid) {
  return Backend.$$delete(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "Services",
                VAL: {
                  NAME: "Update",
                  VAL: uuid
                }
              }
            });
}

var decode$3 = JsonDecode.object(function (field) {
      return {
              categories: field.required("categories", JsonDecode.array(Catalog_Types.Category.decode)),
              services: field.required("services", JsonDecode.array(Catalog_Types.Service.decode))
            };
    });

var FilterResponse = {
  decode: decode$3
};

function buildFilterQuery(bounds, name, categoryUuid) {
  return Utils.objToJson({
              bounds: bounds,
              name: name,
              category_uuid: categoryUuid
            });
}

var filter = Shared_Lib_IO.debounce(undefined, 300, (function (param) {
        var categoryUuid = param[3];
        var name = param[2];
        var bounds = param[1];
        var wire = param[0];
        return PromiseF.toIOLazy(function () {
                    return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                                    NAME: "API",
                                    VAL: {
                                      NAME: "Products",
                                      VAL: {
                                        NAME: "Filter",
                                        VAL: buildFilterQuery(bounds, name, categoryUuid)
                                      }
                                    }
                                  }), decode$3);
                  });
      }));

var IndexResponse = {};

var Delete = {
  $$Response: {
    Entity: {},
    decode: decode$2
  }
};

export {
  IndexResponse ,
  index ,
  create ,
  update ,
  Delete ,
  $$delete ,
  FilterResponse ,
  buildFilterQuery ,
  filter ,
}
/* decode Not a pure module */
