

import * as Fun from "../libraries/Fun.mjs";
import * as Uuid from "../bindings/uuid/Uuid.mjs";
import * as RCore from "../libraries/RCore.mjs";
import * as Types from "./Types.mjs";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as QueryUtils from "../utils/QueryUtils.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function make(scheduledAt, assigneePk, templateUuid) {
  return {
          scheduledAt: scheduledAt,
          assigneePk: assigneePk,
          templateUuid: templateUuid
        };
}

var Schedule = {
  make: make
};

function make$1(clientUuid, siteUuid, templateUuid) {
  return {
          clientUuid: clientUuid,
          siteUuid: siteUuid,
          templateUuid: templateUuid
        };
}

var RelatedEntity = {
  make: make$1
};

function copy(id) {
  return {
          TAG: "Copy",
          _0: id
        };
}

function nextJob(uuid, templateUuid) {
  return {
          TAG: "Next",
          _0: [
            uuid,
            templateUuid
          ]
        };
}

function completionJob(uuid) {
  return {
          TAG: "Completion",
          _0: uuid
        };
}

function decodeCopyJob(search) {
  return RCore.$$Option.map(QueryUtils.queryUuid(search, "copy"), (function (r) {
                return {
                        TAG: "Copy",
                        _0: r
                      };
              }));
}

function decodeNextJob(search) {
  return RCore.$$Option.map(QueryUtils.queryUuid(search, "nextJob"), (function (str) {
                return {
                        TAG: "Next",
                        _0: [
                          str,
                          QueryUtils.queryUuid(search, "nextTemplate")
                        ]
                      };
              }));
}

function decodeCompletionJob(search) {
  return RCore.$$Option.map(QueryUtils.queryUuid(search, "completionJob"), (function (r) {
                return {
                        TAG: "Completion",
                        _0: r
                      };
              }));
}

function decodeScheduleJob(search) {
  return {
          TAG: "Schedule",
          _0: {
            scheduledAt: QueryUtils.query(search, "scheduledAt"),
            assigneePk: QueryUtils.queryPK(search, "assignee"),
            templateUuid: QueryUtils.queryUuid(search, "template")
          }
        };
}

function decodeRelatedEntityJob(search) {
  return {
          TAG: "RelatedEntity",
          _0: {
            clientUuid: QueryUtils.queryUuid(search, "client"),
            siteUuid: QueryUtils.queryUuid(search, "site"),
            templateUuid: QueryUtils.queryUuid(search, "template")
          }
        };
}

function decode(search) {
  var match = decodeCopyJob(search);
  var match$1 = decodeNextJob(search);
  var match$2 = decodeCompletionJob(search);
  if (match !== undefined) {
    return match;
  }
  if (match$1 !== undefined) {
    return match$1;
  }
  if (match$2 !== undefined) {
    return match$2;
  }
  var match$3 = QueryUtils.queryId(search, "scheduledAt");
  if (match$3 !== undefined) {
    return decodeScheduleJob(search);
  } else {
    return decodeRelatedEntityJob(search);
  }
}

function prepareQuery(newOptions) {
  switch (newOptions.TAG) {
    case "Copy" :
        return [[
                  "copy",
                  Uuid.toString(newOptions._0)
                ]];
    case "Next" :
        var match = newOptions._0;
        var template = match[1];
        var uuid = match[0];
        if (template !== undefined) {
          return [
                  [
                    "nextJob",
                    Uuid.toString(uuid)
                  ],
                  [
                    "nextTemplate",
                    Uuid.toString(Caml_option.valFromOption(template))
                  ]
                ];
        } else {
          return [[
                    "nextJob",
                    Uuid.toString(uuid)
                  ]];
        }
    case "Completion" :
        return [[
                  "completionJob",
                  Uuid.toString(newOptions._0)
                ]];
    case "Schedule" :
        var match$1 = newOptions._0;
        return RCore.$$Array.keepMap([
                    RCore.$$Option.map(match$1.templateUuid, (function (uuid) {
                            return [
                                    "template",
                                    Uuid.toString(uuid)
                                  ];
                          })),
                    RCore.$$Option.map(match$1.scheduledAt, (function (scheduledAt) {
                            return [
                                    "scheduledAt",
                                    scheduledAt
                                  ];
                          })),
                    RCore.$$Option.map(match$1.assigneePk, (function (pk) {
                            return [
                                    "assignee",
                                    Types.PK.encode(pk)
                                  ];
                          }))
                  ], Fun.id);
    case "RelatedEntity" :
        var match$2 = newOptions._0;
        return RCore.$$Array.keepMap([
                    RCore.$$Option.map(match$2.templateUuid, (function (uuid) {
                            return [
                                    "template",
                                    Uuid.toString(uuid)
                                  ];
                          })),
                    RCore.$$Option.map(match$2.clientUuid, (function (uuid) {
                            return [
                                    "client",
                                    Uuid.toString(uuid)
                                  ];
                          })),
                    RCore.$$Option.map(match$2.siteUuid, (function (uuid) {
                            return [
                                    "site",
                                    Uuid.toString(uuid)
                                  ];
                          }))
                  ], Fun.id);
    
  }
}

function encode(job) {
  return QueryUtils.joinQuery(prepareQuery(job));
}

function key(options) {
  switch (options.TAG) {
    case "Copy" :
        return "copy-" + Uuid.toString(options._0);
    case "Next" :
        var match = options._0;
        var templateUuid = match[1];
        var uuid = match[0];
        if (templateUuid !== undefined) {
          return "next-" + Uuid.toString(uuid) + "template-" + Uuid.toString(Caml_option.valFromOption(templateUuid));
        } else {
          return "next-" + Uuid.toString(uuid);
        }
    case "Completion" :
        return "completion-" + Uuid.toString(options._0);
    case "Schedule" :
        return "schedule";
    case "RelatedEntity" :
        return "related-entity";
    
  }
}

var JobResponse = {};

function uuid(param) {
  return param.uuid;
}

function showLinkedJobs(param) {
  return param.showLinkedJobs;
}

function make$2(jobResponseOpt, showLinkedJobsOpt, uuid) {
  var jobResponse = jobResponseOpt !== undefined ? Caml_option.valFromOption(jobResponseOpt) : undefined;
  var showLinkedJobs = showLinkedJobsOpt !== undefined ? showLinkedJobsOpt : false;
  return {
          uuid: uuid,
          jobResponse: jobResponse,
          showLinkedJobs: showLinkedJobs
        };
}

function key$1(param) {
  return Uuid.toString(param.uuid) + "-" + (
          param.jobResponse !== undefined ? "withJobResponse" : "withoutJobResponse"
        ) + (
          param.showLinkedJobs ? "shownLinkedJobs" : "hiddenLinkedJobs"
        );
}

var EditOptions = {
  JobResponse: JobResponse,
  uuid: uuid,
  showLinkedJobs: showLinkedJobs,
  make: make$2,
  key: key$1
};

function key$2(query) {
  if (query.NAME === "Edit") {
    return "edit-" + key$1(query.VAL);
  } else {
    return "new-" + key(query.VAL);
  }
}

var $$Event = {};

var Upload = {};

var $$Event$1 = {};

var Client = {
  $$Event: $$Event$1
};

var $$Event$2 = {};

var Site = {
  $$Event: $$Event$2
};

function parseJobQuery(search) {
  var uuid = QueryUtils.query(search, "job");
  if (uuid !== undefined) {
    if (uuid === "new") {
      return {
              hd: {
                NAME: "Job",
                VAL: {
                  NAME: "New",
                  VAL: decode(search)
                }
              },
              tl: /* [] */0
            };
    } else if (Uuid.test(uuid)) {
      return {
              hd: {
                NAME: "Job",
                VAL: {
                  NAME: "Edit",
                  VAL: make$2(undefined, undefined, Uuid.fromString(uuid))
                }
              },
              tl: /* [] */0
            };
    } else {
      return ;
    }
  }
  
}

function parseUploadQuery(search) {
  var match = QueryUtils.query(search, "upload");
  if (match === undefined) {
    return ;
  }
  switch (match) {
    case "clients" :
        return "Clients";
    case "sites" :
        return "Sites";
    default:
      return ;
  }
}

function parseQuery(search) {
  return RCore.$$Option.getOr(parseJobQuery(search), /* [] */0);
}

function makeQuery(modals) {
  return RCore.$$Option.map(Core__List.head(modals), (function (modal) {
                var variant = modal.NAME;
                if (variant === "Site") {
                  var match = modal.VAL;
                  if (typeof match === "object") {
                    if (match.NAME === "Edit") {
                      return "site=" + Uuid.toString(match.VAL);
                    } else if (match.VAL === "Sites") {
                      return "upload=sites";
                    } else {
                      return "upload=clients";
                    }
                  } else {
                    return "site=new";
                  }
                }
                if (variant === "Client") {
                  var match$1 = modal.VAL;
                  if (typeof match$1 === "object") {
                    if (match$1.NAME === "Edit") {
                      return "client=" + Uuid.toString(match$1.VAL);
                    } else if (match$1.VAL === "Sites") {
                      return "upload=sites";
                    } else {
                      return "upload=clients";
                    }
                  } else {
                    return "client=new";
                  }
                }
                var match$2 = modal.VAL;
                if (match$2.NAME === "Edit") {
                  return "job=" + Uuid.toString(match$2.VAL.uuid);
                }
                var options = encode(match$2.VAL);
                return "job=new" + (
                        options === "" ? "" : "&" + options
                      );
              }));
}

var Job_NewOptions = {
  Schedule: Schedule,
  RelatedEntity: RelatedEntity,
  copy: copy,
  nextJob: nextJob,
  completionJob: completionJob,
  decodeCopyJob: decodeCopyJob,
  decodeNextJob: decodeNextJob,
  decodeCompletionJob: decodeCompletionJob,
  decodeScheduleJob: decodeScheduleJob,
  decodeRelatedEntityJob: decodeRelatedEntityJob,
  decode: decode,
  encode: encode,
  key: key
};

var Job = {
  NewOptions: Job_NewOptions,
  EditOptions: EditOptions,
  key: key$2,
  $$Event: $$Event
};

export {
  Job ,
  Upload ,
  Client ,
  Site ,
  parseJobQuery ,
  parseUploadQuery ,
  parseQuery ,
  makeQuery ,
}
/* Uuid Not a pure module */
