

import * as React from "react";
import * as WeekTimeline_ActiveDayStateTypes from "./WeekTimeline_ActiveDayStateTypes.mjs";

var context = React.createContext(WeekTimeline_ActiveDayStateTypes.State.initial);

function use() {
  return React.useContext(context);
}

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var Provider = {
  makeProps: makeProps,
  make: make
};

var initial = undefined;

var context$1 = React.createContext(initial);

function use$1() {
  return React.useContext(context$1);
}

function makeProps$1(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make$1 = context$1.Provider;

var Provider$1 = {
  makeProps: makeProps$1,
  make: make$1
};

var StateContext = {
  use: use,
  Provider: Provider
};

var SetStateContext = {
  use: use$1,
  Provider: Provider$1
};

export {
  StateContext ,
  SetStateContext ,
}
/* context Not a pure module */
