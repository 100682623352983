

import * as Units from "../../../../../../types/Units.mjs";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as DayTimeline_Constants from "../DayTimeline_Constants.mjs";
import * as Schedule_Types_Timeline from "../../../../lib/types/Schedule_Types_Timeline.mjs";

function assigneeCoordinates(idx) {
  var y1 = Units.Px.plus(Units.Px.multiplyWithInt(DayTimeline_Constants.rowHeight, idx), Units.Px.multiplyWithInt(DayTimeline_Constants.barGapHeight, idx));
  var barMarginHeightHalf = Units.Px.divideByInt(DayTimeline_Constants.barGapHeight, 2);
  return [
          Units.Px.minus(y1, barMarginHeightHalf),
          Units.Px.plus(Units.Px.plus(y1, DayTimeline_Constants.rowHeight), barMarginHeightHalf)
        ];
}

function assignee(wire, filter, param) {
  var y = param[1];
  var assignees = Schedule_Types_Timeline.Bars.Assignees.filter(wire, filter);
  return assignees.filter(function (param, idx) {
                var match = assigneeCoordinates(idx);
                if (Units.Px.equalOrLessThan(match[0], y)) {
                  return Units.Px.equalOrLessThan(y, match[1]);
                } else {
                  return false;
                }
              })[0];
}

function scheduledAt(businessHours, currentDay, sizes, param) {
  var offsetInMinutes = Units.Px.toInt(Units.Px.multiplyWithInt(Units.Px.round(Units.Px.divideByInt(Units.Px.divide(param[0], sizes.minute), 5)), 5));
  var startOfDay = businessHours !== undefined ? Locale.T.add(Locale.T.floorToHourStart(businessHours.start), currentDay) : currentDay;
  return Locale.T.nextMinutes(offsetInMinutes, startOfDay);
}

var Sizes;

var FindByCoordinateInTimeline = {
  assignee: assignee,
  scheduledAt: scheduledAt
};

export {
  Sizes ,
  FindByCoordinateInTimeline ,
}
/* Locale Not a pure module */
