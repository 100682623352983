

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Types from "../../types/Types.mjs";
import * as Locale from "../../libraries/Locale.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";
import * as Types_Date from "../../types/Types_Date.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Context_Types from "../../context/Context_Types.mjs";
import * as MultipleAssignee from "../../types/MultipleAssignee.mjs";

var Mode = {
  $$default: "View"
};

function $$default(ctx) {
  if (Context_Types.Features.hasFlag("teams", ctx.features) && MultipleAssignee.PossibleAssignee.selectTeams(ctx.allAssignees).length > 0) {
    return "Teams";
  } else {
    return "Workers";
  }
}

var WorkersType = {
  $$default: $$default
};

var Day = {};

var WorkCellState = {};

var ConstructorCellState = {};

var decode = JsonDecode.object(function (field) {
      return {
              timeFrom: field.required("timeFrom", Types.Time.HoursMinutes.decode),
              timeTo: field.required("timeTo", Types.Time.HoursMinutes.decode),
              jobTypeUuids: field.required("jobTypeUuids", JsonDecode.array(Uuid.decode)),
              territoryUuids: field.required("territoryUuids", JsonDecode.array(Uuid.decode)),
              errors: [],
              teamUuid: field.required("teamUuid", JsonDecode.option(Uuid.decode))
            };
    });

var Interval = {
  decode: decode
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              date: field.required("date", Types_Date.decode),
              useBusinessHours: field.required("useBusinessHours", JsonDecode.bool),
              intervals: field.required("intervals", JsonDecode.array(decode))
            };
    });

var Shift = {
  decode: decode$1
};

var decode$2 = JsonDecode.object(function (field) {
      return {
              value: field.required("uuid", Uuid.decode),
              label: field.required("name", JsonDecode.string)
            };
    });

var SelectOption = {
  decode: decode$2
};

var DateRange = {};

function make(uuid, name, shifts, territoryUuid) {
  return {
          uuid: uuid,
          name: name,
          shifts: shifts,
          territoryUuid: territoryUuid
        };
}

var decode$3 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              shifts: field.required("shifts", JsonDecode.array(decode$1)),
              territoryUuid: RCore.$$Option.flatMap(field.optional("territoryUuid", JsonDecode.option(Uuid.decode)), (function (x) {
                      return x;
                    }))
            };
    });

var $$Worker = {
  make: make,
  decode: decode$3
};

var decode$4 = JsonDecode.object(function (field) {
      return {
              userUuid: field.required("userUuid", Uuid.decode),
              dateFrom: field.required("dateFrom", Types_Date.decode),
              dateTo: field.required("dateTo", Types_Date.decode)
            };
    });

var decode$5 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              shifts: field.required("shifts", JsonDecode.array(decode$1)),
              territoryUuid: RCore.$$Option.flatMap(field.optional("territoryUuid", JsonDecode.option(Uuid.decode)), (function (x) {
                      return x;
                    })),
              workerUuids: field.required("workerUuids", JsonDecode.array(Uuid.decode)),
              foremanUuid: field.required("foremanUuid", JsonDecode.option(Uuid.decode)),
              temporaryWorkers: field.required("temporaryWorkers", JsonDecode.array(decode$4))
            };
    });

function asWorker(team) {
  return make(team.uuid, team.name, team.shifts, team.territoryUuid);
}

var Team = {
  decode: decode$5,
  asWorker: asWorker
};

function update(allUsers, userUuid, date) {
  var user = RCore.$$Array.getBy(allUsers, (function (u) {
          return Uuid.equal(u.uuid, userUuid);
        }));
  if (user === undefined) {
    return Belt_Array.concatMany([
                allUsers,
                [{
                    uuid: userUuid,
                    dates: [
                      date,
                      undefined
                    ]
                  }]
              ]);
  }
  var match = user.dates;
  var resDates;
  if (match !== undefined) {
    var max = match[1];
    var existing = match[0];
    if (max !== undefined) {
      var max$1 = Caml_option.valFromOption(max);
      if (Locale.T.equalDay(date, existing)) {
        var next = Locale.T.nextDay(existing);
        resDates = Locale.T.equalDay(next, max$1) ? [
            max$1,
            undefined
          ] : [
            next,
            Caml_option.some(max$1)
          ];
      } else if (Locale.T.equalDay(date, max$1)) {
        var prev = Locale.T.prevDay(max$1);
        resDates = Locale.T.equalDay(existing, prev) ? [
            existing,
            undefined
          ] : [
            existing,
            Caml_option.some(prev)
          ];
      } else if (Locale.T.withinInterval(date, [
              existing,
              max$1
            ])) {
        var fromStart = Locale.T.daysBetween(date, existing);
        var toEnd = Locale.T.daysBetween(max$1, date);
        resDates = fromStart > toEnd ? [
            existing,
            Caml_option.some(date)
          ] : [
            date,
            Caml_option.some(max$1)
          ];
      } else {
        resDates = [
          Locale.T.min(existing, date),
          Caml_option.some(Locale.T.max(max$1, date))
        ];
      }
    } else if (Locale.T.equalDay(date, existing)) {
      resDates = undefined;
    } else {
      var min = Locale.T.min(existing, date);
      var max$2 = Locale.T.max(existing, date);
      resDates = [
        min,
        Caml_option.some(max$2)
      ];
    }
  } else {
    resDates = [
      date,
      undefined
    ];
  }
  if (resDates !== undefined) {
    return allUsers.map(function (u) {
                if (Uuid.equal(u.uuid, userUuid)) {
                  return {
                          uuid: userUuid,
                          dates: resDates
                        };
                } else {
                  return u;
                }
              });
  } else {
    return RCore.$$Array.keep(allUsers, (function (u) {
                  return !Uuid.equal(u.uuid, userUuid);
                }));
  }
}

var ConstructorWorker = {
  update: update
};

var TemporaryWorker = {};

export {
  Mode ,
  WorkersType ,
  Day ,
  WorkCellState ,
  ConstructorCellState ,
  Interval ,
  Shift ,
  SelectOption ,
  DateRange ,
  $$Worker ,
  TemporaryWorker ,
  Team ,
  ConstructorWorker ,
}
/* decode Not a pure module */
