

import * as JsonDecode from "../../../libraries/JsonDecode.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              quotesError: field.optional("quotesError", JsonDecode.bool),
              hasHeader: field.optional("hasHeader", JsonDecode.bool),
              clientsCreated: field.optional("clientsCreated", JsonDecode.$$int),
              clientsUpdated: field.optional("clientsUpdated", JsonDecode.$$int),
              jobsCreated: field.optional("jobsCreated", JsonDecode.$$int),
              jobsUpdated: field.optional("jobsUpdated", JsonDecode.$$int),
              internalError: field.optional("internalError", JsonDecode.string)
            };
    });

var Summary = {
  decode: decode
};

function make(uuid, skipHeader, file, taskErrors, hasQuotesError, sendingForm, sendingFile, sendingSuccess, sendingSummary, formSent) {
  return {
          uuid: uuid,
          skipHeader: skipHeader,
          file: file,
          taskErrors: taskErrors,
          hasQuotesError: hasQuotesError,
          sendingForm: sendingForm,
          sendingFile: sendingFile,
          sendingSuccess: sendingSuccess,
          sendingSummary: sendingSummary,
          formSent: formSent
        };
}

export {
  Summary ,
  make ,
}
/* decode Not a pure module */
