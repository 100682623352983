

import * as Uuid from "../../../../../bindings/uuid/Uuid.mjs";
import * as EncodeJson from "../../../../../libraries/EncodeJson.mjs";
import * as Schedule_Types_Mode from "../../types/Schedule_Types_Mode.mjs";
import * as Schedule_StorageType from "./Schedule_StorageType.mjs";
import * as Schedule_Types_Settings from "../../types/Schedule_Types_Settings.mjs";
import * as Schedule_Types_JobDuration from "../../types/Schedule_Types_JobDuration.mjs";

function encodeUuids(uuids) {
  return EncodeJson.jsonArray(uuids.map(function (v) {
                  return EncodeJson.string(Uuid.toString(v));
                }));
}

function encodeFilter(territories, skills, assigneesFilter) {
  return EncodeJson.object([
              [
                "territories",
                encodeUuids(territories)
              ],
              [
                "skills",
                EncodeJson.object([
                      [
                        "uuids",
                        encodeUuids(skills.uuids)
                      ],
                      [
                        "mode",
                        EncodeJson.string(Schedule_Types_Settings.Filter.Skills.Mode.toString(skills.mode))
                      ]
                    ])
              ],
              [
                "assignees",
                EncodeJson.string(Schedule_Types_Settings.Filter.Assignees.toString(assigneesFilter))
              ]
            ]);
}

function encode(view, territories, assigneesFilter, skills, mode, jobDuration) {
  return EncodeJson.object([
              [
                "version",
                EncodeJson.string(Schedule_StorageType.Version.toString("V2"))
              ],
              [
                "data",
                EncodeJson.object([
                      [
                        "filter",
                        encodeFilter(territories, skills, assigneesFilter)
                      ],
                      [
                        "view",
                        EncodeJson.string(Schedule_StorageType.View.toString(view))
                      ],
                      [
                        "mode",
                        Schedule_Types_Mode.encode(mode)
                      ],
                      [
                        "jobDuration",
                        EncodeJson.string(Schedule_Types_JobDuration.toString(jobDuration))
                      ]
                    ])
              ]
            ]);
}

export {
  encode ,
}
/* Uuid Not a pure module */
