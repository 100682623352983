

import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as $$String from "rescript/lib/es6/string.js";
import * as Backend from "../../../../libraries/backend/Backend.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as JsonDecode from "../../../../libraries/JsonDecode.mjs";
import * as Catalog_Types from "../Catalog_Types.mjs";

function encodeForm(form) {
  return {
          name: Js_null.fromOption(RCore.$$Option.map(form.name, $$String.trim)),
          code: Js_null.fromOption(form.code),
          type: Catalog_Types.Type.toString(form.type_),
          externalId: Js_null.fromOption(RCore.$$Option.map(form.externalId, $$String.trim)),
          archived: form.archived
        };
}

function create(wire, unit) {
  return Backend.decode(Backend.post(undefined, undefined, undefined, true, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Units",
                    VAL: "Index"
                  }
                }, {
                  NAME: "Obj",
                  VAL: encodeForm(unit)
                }), Catalog_Types.Unit.decode);
}

function update(wire, uuid, unit) {
  return Backend.decode(Backend.patch(undefined, undefined, undefined, true, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Units",
                    VAL: {
                      NAME: "Update",
                      VAL: uuid
                    }
                  }
                }, {
                  NAME: "Obj",
                  VAL: encodeForm(unit)
                }), Catalog_Types.Unit.decode);
}

var decode = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var decode$1 = JsonDecode.object(function (field) {
      return field.required("errors", JsonDecode.object(function (field) {
                      return {
                              products: field.required("products", JsonDecode.array(decode))
                            };
                    }));
    });

function $$delete(wire, uuid) {
  return Backend.$$delete(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "Units",
                VAL: {
                  NAME: "Update",
                  VAL: uuid
                }
              }
            });
}

var decode$2 = JsonDecode.object(function (field) {
      return {
              units: field.required("units", JsonDecode.array(Catalog_Types.Unit.decode))
            };
    });

function buildFilterQuery(bounds, type_) {
  return Utils.objToJson({
              bounds: bounds,
              type: Catalog_Types.Type.toString(type_)
            });
}

function filter(wire, bounds, type_) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Units",
                    VAL: {
                      NAME: "Filter",
                      VAL: buildFilterQuery(bounds, type_)
                    }
                  }
                }), decode$2);
}

var Delete = {
  $$Response: {
    Entity: {},
    decode: decode$1
  }
};

var FilterResponse = {};

export {
  create ,
  update ,
  Delete ,
  $$delete ,
  FilterResponse ,
  buildFilterQuery ,
  filter ,
}
/* decode Not a pure module */
