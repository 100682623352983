

import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as PromiseF from "../../libraries/PromiseF.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Context_Lang from "../../context/Context_Lang.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.mjs";
import * as Types_Spatial from "../../types/Types_Spatial.mjs";
import Richmarker from "planado/components/map/engines/google/richmarker";

function getApi() {
  return RCore.$$Option.flatMap(window.google, (function (prim) {
                return prim.maps;
              }));
}

function getApiResult() {
  var gmapsApi = RCore.$$Option.flatMap(window.google, (function (prim) {
          return prim.maps;
        }));
  if (gmapsApi !== undefined) {
    return {
            TAG: "Ok",
            _0: Caml_option.valFromOption(gmapsApi)
          };
  } else {
    return {
            TAG: "Error",
            _0: "ApiNotAvailable"
          };
  }
}

function fromGeolocation(param) {
  return {
          lat: param.latitude,
          lng: param.longitude
        };
}

var LatLngLiteral = {
  fromGeolocation: fromGeolocation
};

function url(ctx) {
  var lang = Context_Lang.toString(ctx.localizator.lang);
  var apiKey = ctx.mapApiKey;
  return "//maps.googleapis.com/maps/api/js?key=" + apiKey + "&libraries=places&language=" + lang;
}

function load(ctx) {
  return PromiseF.map(Utils.loadScript(url(ctx)), (function () {
                return getApiResult();
              }));
}

var Loader = {
  url: url,
  load: load
};

var _make = ((Constructor, latitude, longitude) => new Constructor(latitude, longitude));

function make(gmapsApi, coordinates) {
  return _make(gmapsApi.LatLng, coordinates.latitude, coordinates.longitude);
}

var _make$1 = ((Constructor, swCorner, neCorner) => new Constructor(swCorner, neCorner));

function make$1(gmapsApi, bounds) {
  return _make$1(gmapsApi.LatLngBounds, make(gmapsApi, bounds.southWest), make(gmapsApi, bounds.northEast));
}

var Options = {};

function make$2(top, right, bottom, left, param) {
  return {
          top: top,
          right: right,
          bottom: bottom,
          left: left
        };
}

var Padding = {
  make: make$2
};

var $$Event = {};

function addListener(obj, $$event, handler) {
  obj.addListener("zoom_changed", handler);
}

var _make$2 = ((Constructor, element, options) => new Constructor(element, options));

function make$3(gmapsApi, element, options) {
  return _make$2(gmapsApi.Map, element, options);
}

function makeResult(element, options) {
  return Core__Result.map(getApiResult(), (function (api) {
                return make$3(api, element, options);
              }));
}

function panTo(map, $$location) {
  map.panTo(fromGeolocation($$location));
}

var Icon = {};

var Options$1 = {};

var _make$3 = ((Constructor, options) => new Constructor(options));

function make$4(gmapsApi, options) {
  return _make$3(gmapsApi.Polyline, options);
}

function removeFromMap(t) {
  t.setMap(null);
}

function toString(eventType) {
  switch (eventType) {
    case "Click" :
        return "click";
    case "MouseOver" :
        return "mouseover";
    case "MouseOut" :
        return "mouseout";
    case "MouseMove" :
        return "mousemove";
    
  }
}

var LatLng = {};

function get(t) {
  var latLng = t.latLng;
  return Types_Spatial.$$Geolocation.make(latLng.lat(), latLng.lng());
}

var $$Geolocation = {
  LatLng: LatLng,
  get: get
};

var $$Event$1 = {
  $$Geolocation: $$Geolocation
};

function addListener$1(obj, $$event, handler) {
  obj.addListener(toString($$event), handler);
}

var Options$2 = {};

var _make$4 = ((Constructor, options) => new Constructor(options));

function make$5(gmapsApi, options) {
  return _make$4(gmapsApi.Marker, options);
}

function removeFromMap$1(t) {
  t.setMap(null);
}

var Options$3 = {};

function make$6(gmapsApi, _constructor, coordinates, content, anchor) {
  ((_constructor.prototype.__proto__ = google.maps.OverlayView.prototype));
  return new Richmarker({
              position: make(gmapsApi, coordinates),
              flat: true,
              anchor: anchor,
              content: content
            });
}

var LatLng$1 = {
  make: make
};

var LatLngBounds = {
  make: make$1
};

var Map_Events = {
  EventType: {},
  $$Event: $$Event,
  addListener: addListener
};

function Map_fitBounds(prim0, prim1, prim2) {
  prim0.fitBounds(prim1, prim2);
}

function Map_getZoom(prim) {
  return prim.getZoom();
}

var $$Map = {
  Options: Options,
  Padding: Padding,
  Events: Map_Events,
  make: make$3,
  makeResult: makeResult,
  fitBounds: Map_fitBounds,
  panTo: panTo,
  getZoom: Map_getZoom
};

function Polyline_setMap(prim0, prim1) {
  prim0.setMap(prim1);
}

var Polyline_Events = {
  EventType: {},
  $$Event: $$Event$1,
  addListener: addListener$1
};

var Polyline = {
  Icon: Icon,
  Options: Options$1,
  make: make$4,
  setMap: Polyline_setMap,
  removeFromMap: removeFromMap,
  Events: Polyline_Events
};

function Marker_setMap(prim0, prim1) {
  prim0.setMap(prim1);
}

var Marker = {
  Options: Options$2,
  make: make$5,
  setMap: Marker_setMap,
  removeFromMap: removeFromMap$1
};

var RichMarker_Constructor = {
  make: Richmarker
};

var RichMarker = {
  Constructor: RichMarker_Constructor,
  Options: Options$3,
  make: make$6
};

export {
  getApi ,
  getApiResult ,
  LatLngLiteral ,
  Loader ,
  LatLng$1 as LatLng,
  LatLngBounds ,
  $$Map ,
  Polyline ,
  Marker ,
  RichMarker ,
}
/* RichMarker Not a pure module */
