

import * as Uuid from "../../../../../bindings/uuid/Uuid.mjs";
import * as Utils from "../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../../../../common/Optional.mjs";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as Schedule_State from "../../../lib/state/Schedule_State.mjs";
import * as Schedule_Types_Job from "../../../lib/types/job/Schedule_Types_Job.mjs";
import * as Hooks_InfiniteScroll from "../../../../../libraries/hooks/Hooks_InfiniteScroll.mjs";
import * as Schedule_Types_Settings from "../../../lib/types/Schedule_Types_Settings.mjs";
import * as Schedule_UnscheduledJob from "./job/Schedule_UnscheduledJob.mjs";
import * as Schedule_UnscheduledSkeleton from "./skeleton/Schedule_UnscheduledSkeleton.mjs";
import * as Schedule_UnscheduledModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/uscheduled_jobs/Schedule_Unscheduled.module.css";

var styles = Schedule_UnscheduledModuleCss;

var filter$p = Utils.Translations.tr("js.schedule.v2.jobs.filter");

var noJobsAdded$p = Utils.Translations.tr("js.schedule.v2.jobs.no_jobs_added");

function Schedule_UnscheduledJobs(Props) {
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var jobs = Schedule_State.useUnscheduledJobs();
  var loading = Schedule_State.useUnscheduledJobsLoading();
  var hasNextPage = Schedule_State.useHasNextUnscheduledJobs();
  var filter = Schedule_State.useFilter();
  var dispatch = Schedule_State.useDispatch();
  var onLoadMore = React.useCallback((function () {
          dispatch({
                TAG: "Jobs",
                _0: "FetchNextUnscheduledJobs"
              });
        }), []);
  var match$1 = Hooks_InfiniteScroll.use(onLoadMore, hasNextPage, loading);
  var content = !loading && Caml_obj.equal(jobs, []) && Schedule_Types_Settings.Filter.isEmpty(filter) ? React.createElement("div", {
          className: styles.emptyJobs
        }, noJobsAdded$p(ctx)) : (
      !loading && Caml_obj.equal(jobs, []) ? React.createElement("div", {
              className: styles.emptyJobs
            }, filter$p(ctx)) : React.createElement(React.Fragment, undefined, jobs.map(function (job) {
                  return React.createElement(Schedule_UnscheduledJob.make, {
                              job: job,
                              key: Uuid.toString(Schedule_Types_Job.UnscheduledJob.uuid(job))
                            });
                }), React.createElement(Optional.make, {
                  show: loading || hasNextPage,
                  children: React.createElement("div", {
                        ref: match$1[1]
                      }, React.createElement(Schedule_UnscheduledSkeleton.make, {}))
                }))
    );
  return React.createElement("div", {
              className: styles.jobs
            }, content);
}

var make = React.memo(Schedule_UnscheduledJobs);

export {
  make ,
}
/* styles Not a pure module */
