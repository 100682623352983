

import * as Fun from "../../../../../libraries/Fun.mjs";
import * as RCore from "../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../../libraries/Locale.mjs";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as Schedule_State from "../../../lib/state/Schedule_State.mjs";
import * as Schedule_HeaderIntervalModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/header/interval/Schedule_HeaderInterval.module.css";

var styles = Schedule_HeaderIntervalModuleCss;

function day$p(num) {
  return Utils.Translations.t("js.schedule.v2.abbr_day_names." + num.toString());
}

function useFormattedPeriod(view) {
  var wire = AppContext.useWire();
  var period = Schedule_State.usePeriod();
  var startOfPeriod = period[0];
  var match;
  var exit = 0;
  if (view.NAME === "Calendar") {
    if (view.VAL === "Week") {
      exit = 1;
    } else {
      match = [
        Locale.T.fmtM("DateFullNoDay", wire)(startOfPeriod),
        undefined
      ];
    }
  } else if (view.VAL === "Week") {
    exit = 1;
  } else {
    match = [
      Locale.T.fmtM("DateFull", wire)(startOfPeriod),
      day$p(Locale.T.isoWeekday(startOfPeriod))(wire.ctx)
    ];
  }
  if (exit === 1) {
    match = [
      Locale.T.Schedule.range(wire, period),
      undefined
    ];
  }
  return [
          match[0].toUpperCase(),
          match[1]
        ];
}

function Schedule_HeaderIntervalDatePeriod(Props) {
  var view = Props.view;
  return React.createElement("div", {
              className: styles.datePeriod
            }, Utils.Translations.joinWithComma(RCore.$$Array.filterMap(useFormattedPeriod(view), Fun.id)));
}

var make = React.memo(Schedule_HeaderIntervalDatePeriod);

export {
  make ,
}
/* styles Not a pure module */
