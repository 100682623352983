

import Limited_inputJsx from "planado/components/input/limited_input.jsx";

var make = Limited_inputJsx;

export {
  make ,
}
/* make Not a pure module */
