

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";

function fromString(s) {
  if (s === "tracking") {
    return "Tracking";
  }
  
}

var decode = JsonDecode.fromVariant(JsonDecode.string, fromString);

var decode$1 = JsonDecode.object(function (field) {
      return {
              TAG: "Saved",
              name: field.required("name", JsonDecode.string)
            };
    });

var Custom = {};

var decode$2 = JsonDecode.object(function (field) {
      var uuid = field.required("uuid", Uuid.decode);
      var match = field.required("name", JsonDecode.option(JsonDecode.string));
      var match$1 = field.required("default", JsonDecode.bool);
      if (match !== undefined) {
        if (match$1) {
          return JsonDecode.cannotDecode("name and default are mutually exclusive");
        } else {
          return {
                  TAG: "Custom",
                  _0: {
                    uuid: uuid,
                    name: match,
                    availabilityPeriodMin: field.required("availabilityPeriodMin", JsonDecode.$$int),
                    file: field.required("file", decode$1)
                  }
                };
        }
      } else if (match$1) {
        return {
                TAG: "Default",
                uuid: uuid,
                availabilityPeriodMin: field.required("availabilityPeriodMin", JsonDecode.$$int)
              };
      } else {
        return JsonDecode.cannotDecode("name and default are mutually exclusive");
      }
    });

function file(s) {
  if (s.TAG === "Default") {
    return ;
  } else {
    return s._0.file;
  }
}

function name(s) {
  if (s.TAG === "Default") {
    return ;
  } else {
    return s._0.name;
  }
}

function uuid(s) {
  if (s.TAG === "Default") {
    return s.uuid;
  } else {
    return s._0.uuid;
  }
}

function availabilityPeriodMin(s) {
  if (s.TAG === "Default") {
    return s.availabilityPeriodMin;
  } else {
    return s._0.availabilityPeriodMin;
  }
}

var Form = {
  Custom: Custom,
  decode: decode$2,
  file: file,
  name: name,
  uuid: uuid,
  availabilityPeriodMin: availabilityPeriodMin
};

var Custom$1 = {};

var Default = {};

function make(form) {
  if (form !== undefined && form.TAG === "Default") {
    return {
            TAG: "Default",
            _0: {
              uuid: form.uuid,
              availabilityPeriodMin: form.availabilityPeriodMin
            }
          };
  }
  return {
          TAG: "Custom",
          _0: {
            uuid: RCore.$$Option.map(form, uuid),
            name: RCore.$$Option.getOr(RCore.$$Option.flatMap(form, name), ""),
            file: RCore.$$Option.flatMap(form, file),
            domFile: undefined,
            availabilityPeriodMin: RCore.$$Option.getOr(RCore.$$Option.map(form, availabilityPeriodMin), 1440)
          }
        };
}

var State = {
  defaultavailabilityPeriodMin: 1440,
  Custom: Custom$1,
  Default: Default,
  make: make
};

var Show = {
  State: State
};

var decode$3 = JsonDecode.object(function (field) {
      var uuid = field.required("uuid", Uuid.decode);
      var type_ = field.required("type", decode);
      var filename = field.required("filename", JsonDecode.string);
      var match = field.required("name", JsonDecode.option(JsonDecode.string));
      var match$1 = field.required("default", JsonDecode.bool);
      if (match !== undefined) {
        if (match$1) {
          return JsonDecode.cannotDecode("name and default are mutually exclusive");
        } else {
          return {
                  TAG: "Custom",
                  uuid: uuid,
                  name: match,
                  type_: type_,
                  filename: filename,
                  availabilityPeriodMin: field.required("availabilityPeriodMin", JsonDecode.$$int)
                };
        }
      } else if (match$1) {
        return {
                TAG: "Default",
                uuid: uuid,
                type_: type_,
                filename: filename,
                availabilityPeriodMin: field.required("availabilityPeriodMin", JsonDecode.$$int)
              };
      } else {
        return JsonDecode.cannotDecode("name and default are mutually exclusive");
      }
    });

function uuid$1(s) {
  return s.uuid;
}

var Form$1 = {
  decode: decode$3,
  uuid: uuid$1
};

function make$1(forms) {
  return {
          forms: forms
        };
}

var State$1 = {
  make: make$1
};

var Index = {
  Form: Form$1,
  State: State$1
};

var FormType = {};

var $$File = {};

export {
  FormType ,
  $$File ,
  Form ,
  Show ,
  Index ,
}
/* decode Not a pure module */
