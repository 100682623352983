

import * as WeekCalendar_DragLayerModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_calendar/components/drag_layer/WeekCalendar_DragLayer.module.css";

var styles = WeekCalendar_DragLayerModuleCss;

function make() {
  var jobEl = document.createElement("div");
  jobEl.setAttribute("class", styles.job);
  var templateEl = document.createElement("div");
  templateEl.setAttribute("class", styles.template);
  var assigneeEl = document.createElement("div");
  var addressEl = document.createElement("div");
  jobEl.appendChild(templateEl);
  jobEl.appendChild(assigneeEl);
  jobEl.appendChild(addressEl);
  var containerEl = document.createElement("div");
  containerEl.setAttribute("class", styles.container);
  containerEl.appendChild(jobEl);
  return {
          containerEl: containerEl,
          jobEl: jobEl,
          templateEl: templateEl,
          assigneeEl: assigneeEl,
          addressEl: addressEl
        };
}

export {
  make ,
}
/* styles Not a pure module */
