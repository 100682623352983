

import * as El from "../../libraries/El.mjs";
import * as Modal from "../modal/Modal.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Router from "../../Router.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as FormInput from "../inputs/FormInput.mjs";
import * as PasswordAPI from "./PasswordAPI.mjs";
import * as Backend_Errors from "../../libraries/backend/Backend_Errors.mjs";
import * as PasswordModuleCss from "/home/runner/work/planado/planado/client/rescript/components/password/Password.module.css";

var styles = PasswordModuleCss;

var title$p = Utils.Translations.txr("js.password.title");

var currentPassword$p = Utils.Translations.t("js.password.current_password");

var password$p = Utils.Translations.t("js.password.password");

var passwordConfirmation$p = Utils.Translations.t("js.password.password_confirmation");

var passwordMustBeFilled$p = Utils.Translations.t("js.password.errors.password_must_be_filled");

var passwordCannotContainOnlySpaces$p = Utils.Translations.t("js.password.errors.password_cannot_contain_only_spaces");

var mustBeFilled$p = Utils.Translations.t("js.password.errors.must_be_filled");

var passwordsNotEqual$p = Utils.Translations.t("js.password.errors.passwords_not_equal");

var submit$p = Utils.Translations.tr("js.password.submit");

function extractPasswordErrors(password, ctx) {
  if (password.length < 8) {
    return [passwordMustBeFilled$p(ctx)];
  } else if (password.trim() === "") {
    return [passwordCannotContainOnlySpaces$p(ctx)];
  } else {
    return [];
  }
}

function extractPasswordConfirmationErrors(passwordConfirmation, password, ctx) {
  if (passwordConfirmation === "") {
    return [mustBeFilled$p(ctx)];
  } else if (passwordConfirmation !== password) {
    return [passwordsNotEqual$p(ctx)];
  } else {
    return [];
  }
}

function Password(Props) {
  var wire = Props.wire;
  var onClose = Props.onClose;
  var show = Props.show;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return PasswordAPI.Patch.$$Response.$$Error.emptyErrors;
      });
  var setErrors = match[1];
  var errors = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setCatchErrorsOnChange = match$1[1];
  var catchErrorsOnChange = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setCurrentPassword = match$2[1];
  var currentPassword = match$2[0];
  var match$3 = React.useState(function () {
        return "";
      });
  var setPassword = match$3[1];
  var password = match$3[0];
  var match$4 = React.useState(function () {
        return "";
      });
  var setPasswordConfirmation = match$4[1];
  var passwordConfirmation = match$4[0];
  var onPasswordChange = function (password) {
    if (catchErrorsOnChange) {
      var passwordErrors = extractPasswordErrors(password, ctx);
      setErrors(function (errors) {
            return {
                    currentPassword: errors.currentPassword,
                    password: passwordErrors,
                    passwordConfirmation: errors.passwordConfirmation
                  };
          });
      if (passwordConfirmation !== "") {
        var passwordConfirmationErrors = extractPasswordConfirmationErrors(passwordConfirmation, password, ctx);
        setErrors(function (errors) {
              return {
                      currentPassword: errors.currentPassword,
                      password: errors.password,
                      passwordConfirmation: passwordConfirmationErrors
                    };
            });
      }
      
    }
    setPassword(function (param) {
          return password;
        });
  };
  var onPasswordBlur = function () {
    var passwordErrors = extractPasswordErrors(password, ctx);
    if (!catchErrorsOnChange && Caml_obj.notequal(passwordErrors, [])) {
      setCatchErrorsOnChange(function (param) {
            return true;
          });
    }
    setErrors(function (errors) {
          return {
                  currentPassword: errors.currentPassword,
                  password: passwordErrors,
                  passwordConfirmation: errors.passwordConfirmation
                };
        });
  };
  var onPasswordConfirmationChange = function (passwordConfirmation) {
    if (catchErrorsOnChange) {
      var passwordConfirmationErrors = extractPasswordConfirmationErrors(passwordConfirmation, password, ctx);
      setErrors(function (errors) {
            return {
                    currentPassword: errors.currentPassword,
                    password: errors.password,
                    passwordConfirmation: passwordConfirmationErrors
                  };
          });
    }
    setPasswordConfirmation(function (param) {
          return passwordConfirmation;
        });
  };
  var onPasswordConfirmationBlur = function () {
    var passwordConfirmationErrors = extractPasswordConfirmationErrors(passwordConfirmation, password, ctx);
    if (!catchErrorsOnChange && Caml_obj.notequal(passwordConfirmationErrors, [])) {
      setCatchErrorsOnChange(function (param) {
            return true;
          });
    }
    setErrors(function (errors) {
          return {
                  currentPassword: errors.currentPassword,
                  password: errors.password,
                  passwordConfirmation: passwordConfirmationErrors
                };
        });
  };
  var match$5 = React.useState(function () {
        return false;
      });
  var setRequestInProgress = match$5[1];
  var requestInProgress = match$5[0];
  var handlePatchResponse = function (response) {
    if (response.TAG === "Ok") {
      return Router.redirectToLogin();
    }
    var errors = response._0;
    if (typeof errors === "object" && errors.TAG === "ValidationError") {
      var errors$1 = errors._0;
      setRequestInProgress(function (param) {
            return false;
          });
      setErrors(function (param) {
            return {
                    currentPassword: Backend_Errors.getArray(errors$1, "currentPassword"),
                    password: Backend_Errors.getArray(errors$1, "password"),
                    passwordConfirmation: Backend_Errors.getArray(errors$1, "passwordConfirmation")
                  };
          });
      return setCatchErrorsOnChange(function (param) {
                  return true;
                });
    }
    setRequestInProgress(function (param) {
          return false;
        });
    setCatchErrorsOnChange(function (param) {
          return true;
        });
  };
  var submitDisabled = ctx.disposable || requestInProgress || Caml_obj.notequal(errors, PasswordAPI.Patch.$$Response.$$Error.emptyErrors) || currentPassword === "" || password === "" || passwordConfirmation === "";
  var user = ctx.user;
  if (user !== undefined) {
    return React.createElement(Modal.make, {
                wire: wire,
                onClose: onClose,
                show: show,
                children: React.createElement(Modal.Dialog.make, {
                      className: styles.form,
                      children: null
                    }, React.createElement(Modal.Header.make, {
                          children: title$p({
                                name: user.name
                              }, ctx)
                        }), React.createElement("form", {
                          onSubmit: (function ($$event) {
                              $$event.preventDefault();
                              setRequestInProgress(function (param) {
                                    return true;
                                  });
                              Backend.$$finally(Backend.discard(PasswordAPI.Patch.$$Request.make(wire, {
                                            currentPassword: currentPassword,
                                            password: password,
                                            passwordConfirmation: passwordConfirmation
                                          })), handlePatchResponse);
                            })
                        }, React.createElement("div", {
                              className: "panel-body"
                            }, React.createElement(FormInput.make, {
                                  _type: "password",
                                  value: currentPassword,
                                  onValueChange: (function (v) {
                                      if (Caml_obj.notequal(errors.currentPassword, [])) {
                                        setErrors(function (errors) {
                                              return {
                                                      currentPassword: [],
                                                      password: errors.password,
                                                      passwordConfirmation: errors.passwordConfirmation
                                                    };
                                            });
                                      }
                                      setCurrentPassword(function (param) {
                                            return v;
                                          });
                                    }),
                                  disabled: ctx.disposable || requestInProgress,
                                  labelText: currentPassword$p(ctx),
                                  errors: errors.currentPassword
                                }), React.createElement(FormInput.make, {
                                  _type: "password",
                                  value: password,
                                  onValueChange: onPasswordChange,
                                  onBlur: (function (param) {
                                      onPasswordBlur();
                                    }),
                                  disabled: ctx.disposable || requestInProgress,
                                  labelText: password$p(ctx),
                                  errors: errors.password
                                }), React.createElement(FormInput.make, {
                                  _type: "password",
                                  value: passwordConfirmation,
                                  onValueChange: onPasswordConfirmationChange,
                                  onBlur: (function (param) {
                                      onPasswordConfirmationBlur();
                                    }),
                                  disabled: ctx.disposable || requestInProgress,
                                  labelText: passwordConfirmation$p(ctx),
                                  errors: errors.passwordConfirmation
                                })), React.createElement("div", {
                              className: "panel-footer footer"
                            }, React.createElement("button", {
                                  className: El.Cn.concat("btn btn-primary", requestInProgress ? styles.loader : ""),
                                  disabled: submitDisabled
                                }, React.createElement("span", undefined, submit$p(ctx))))))
              });
  } else {
    return null;
  }
}

var API;

var make = Password;

export {
  API ,
  make ,
}
/* styles Not a pure module */
