

import * as El from "../../../../../../../libraries/El.mjs";
import * as Fun from "../../../../../../../libraries/Fun.mjs";
import * as Uuid from "../../../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../../types/Types.mjs";
import * as Utils from "../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Select from "../../../../../../inputs/Select.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var typeLabel$p = Utils.Translations.tr("js.jobs.labels.type");

var noJobType$p = Utils.Translations.t("js.jobs.placeholders.no_job_type");

function JobForm_BodyTypeBlock$FormGroup(Props) {
  var className = Props.className;
  var id = Props.id;
  var ctx = Props.ctx;
  var children = Props.children;
  return React.createElement("div", {
              className: className
            }, React.createElement("label", {
                  className: "pd-label",
                  htmlFor: id
                }, typeLabel$p(ctx)), children);
}

function JobForm_BodyTypeBlock(Props) {
  var id = Props.id;
  var template = Props.template;
  var typeUuid = Props.typeUuid;
  var jobTypes = Props.jobTypes;
  var onValueChange = Props.onValueChange;
  var isNewRecord = Props.isNewRecord;
  var ctx = Props.ctx;
  var type_ = RCore.$$Option.flatMap(typeUuid, (function (uuid) {
          return RCore.$$Array.getBy(jobTypes, (function (jt) {
                        return Uuid.equal(jt.uuid, uuid);
                      }));
        }));
  var isFormHalfSized = Fun.choose(isNewRecord || RCore.$$Option.isSome(type_), false, RCore.$$Option.isSome(template));
  var groupSize = isFormHalfSized ? "pd-form-group-half" : "pd-form-group-full";
  var className = El.Cn.concat("pd-form-group", groupSize);
  if (!isNewRecord) {
    if (type_ !== undefined) {
      return React.createElement(JobForm_BodyTypeBlock$FormGroup, {
                  className: className,
                  id: id,
                  ctx: ctx,
                  children: React.createElement("input", {
                        className: "pd-form-control",
                        id: id,
                        readOnly: true,
                        type: "text",
                        value: type_.name
                      })
                });
    } else {
      return null;
    }
  }
  var options = jobTypes.map(function (x) {
        return {
                value: x.uuid,
                label: x.name
              };
      });
  var value = RCore.$$Option.map(typeUuid, Types.Id.fromUuid);
  return React.createElement(JobForm_BodyTypeBlock$FormGroup, {
              className: className,
              id: id,
              ctx: ctx,
              children: React.createElement(Select.make, {
                    placeholder: noJobType$p(ctx),
                    name: "job[type_uuid]",
                    onChange: (function (id) {
                        onValueChange(RCore.$$Option.map(id === null ? undefined : Caml_option.some(id), Types.Id.toUuid));
                      }),
                    value: Js_null.fromOption(value),
                    autosize: false,
                    options: options,
                    className: "pd-form-control-select"
                  })
            });
}

var make = JobForm_BodyTypeBlock;

var $$default = JobForm_BodyTypeBlock;

export {
  make ,
  $$default as default,
}
/* typeLabel' Not a pure module */
