import React from 'react'
import { Provider } from 'react-redux'
import {
  HeaderLink,
  TableLink
} from 'planado/containers/admin/recurring_jobs_index'
import FooterLink from 'planado/containers/footer_link'

export const RecurringJobsIndex = props => (
  <Provider {...props}>
    <div className="panel-body">
      <HeaderLink />
      <TableLink />
    </div>
  </Provider>
)

export const Footer = props => (
  <Provider {...props}>
    <FooterLink />
  </Provider>
)
