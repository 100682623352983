

import * as React from "react";
import * as Schedule_TimelineWidget from "../../../../components/timeline/widget/Schedule_TimelineWidget.mjs";
import * as WeekTimeline_UnassignedHooks from "./WeekTimeline_UnassignedHooks.mjs";
import * as WeekTimeline_UnassignedContent from "./components/content/WeekTimeline_UnassignedContent.mjs";
import * as Schedule_TimelineUnassignedHeader from "../../../../components/timeline/unassigned_header/Schedule_TimelineUnassignedHeader.mjs";
import * as WeekTimeline_UnassignedModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/unassigned/WeekTimeline_Unassigned.module.css";

var styles = WeekTimeline_UnassignedModuleCss;

function WeekTimeline_Unassigned(Props) {
  var viewportEl = Props.viewportEl;
  var match = React.useState(function () {
        return true;
      });
  var setCompactMode = match[1];
  var compactMode = match[0];
  var toggleMode = React.useCallback((function () {
          setCompactMode(function (prev) {
                return !prev;
              });
        }), []);
  var jobs = WeekTimeline_UnassignedHooks.Jobs.use();
  var jobsCount = jobs.length;
  var style = WeekTimeline_UnassignedHooks.Style.use(compactMode);
  var match$1 = WeekTimeline_UnassignedHooks.Drop.use(viewportEl, compactMode, toggleMode);
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  ref: match$1[0],
                  className: styles.container,
                  style: style
                }, React.createElement(Schedule_TimelineUnassignedHeader.make, {
                      compactMode: compactMode,
                      toggleMode: toggleMode,
                      jobsCount: jobsCount
                    }), React.createElement(WeekTimeline_UnassignedContent.make, {
                      compactMode: compactMode,
                      isOver: match$1[1],
                      jobs: jobs
                    })), React.createElement(Schedule_TimelineWidget.make, {
                  view: "week",
                  withUnassignedJobs: !compactMode
                }));
}

var make = React.memo(WeekTimeline_Unassigned);

export {
  make ,
}
/* styles Not a pure module */
