

import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DayTimeline_Types from "../types/DayTimeline_Types.mjs";
import * as Schedule_Types_Job from "../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as DayTimeline_Constants from "../DayTimeline_Constants.mjs";
import * as DayTimeline_JobPositionsHelper from "./DayTimeline_JobPositionsHelper.mjs";

function findGap(_positionOpt, _indexOpt, jobPosition) {
  while(true) {
    var positionOpt = _positionOpt;
    var indexOpt = _indexOpt;
    var position = positionOpt !== undefined ? Caml_option.valFromOption(positionOpt) : Units.Px.zero;
    var index = indexOpt !== undefined ? indexOpt : 0;
    var rendered = RCore.$$Array.keep(jobPosition.children.contents, (function (j) {
            return j.rendered.contents;
          }));
    var found = rendered.every((function(position){
        return function (p) {
          return Caml_obj.notequal(position, p.top.contents);
        }
        }(position)));
    if (found) {
      return position;
    }
    var j = rendered[index];
    var position$1 = Units.Px.plus(j.top.contents, DayTimeline_Constants.rowHeight);
    var index$1 = index + 1 | 0;
    _indexOpt = index$1;
    _positionOpt = Caml_option.some(position$1);
    continue ;
  };
}

function setPosition(job) {
  if (!job.rendered.contents) {
    job.rendered.contents = true;
    job.height.contents = DayTimeline_Constants.rowHeight;
    job.top.contents = Caml_obj.equal(job.children.contents, []) ? Units.Px.zero : findGap(undefined, undefined, job);
    return ;
  }
  
}

function make(wire, businessHours, currentDay, sizes, jobs) {
  var filtered = RCore.$$Array.keep(jobs, (function (job) {
          return DayTimeline_JobPositionsHelper.inBusinessHours(businessHours, currentDay, Schedule_Types_Job.UnassignedJob.startAt(job), Schedule_Types_Job.UnassignedJob.finishAt(job));
        }));
  var positions = DayTimeline_JobPositionsHelper.sortByWidth(filtered.map(function (job, index) {
            return DayTimeline_JobPositionsHelper.makePosition(wire, businessHours, currentDay, sizes.minute, Schedule_Types_Job.UnassignedJob.startAt(job), Schedule_Types_Job.UnassignedJob.finishAt(job), index);
          }));
  var tree = DayTimeline_JobPositionsHelper.IntervalTree.make(positions);
  positions.forEach(function (position) {
        position.children.contents = RCore.$$Array.keep(DayTimeline_JobPositionsHelper.IntervalTree.rangeSearch(undefined, tree, DayTimeline_Types.Position.start(position) + 1 | 0, DayTimeline_Types.Position.finish(position) - 1 | 0), (function (p) {
                return p.index !== position.index;
              }));
      });
  positions.forEach(setPosition);
  return DayTimeline_JobPositionsHelper.sortByPosition(positions).map(function (p) {
              return [
                      p,
                      filtered[p.index]
                    ];
            });
}

export {
  make ,
}
/* DayTimeline_Types Not a pure module */
