import React from 'react'
import DropDownButton from 'planado/components/common/dropdown_button'
import { newRecurringJobPath } from 'planado/routes.js'
import { withContext } from 'planado/utils/contextConsumer.jsx'

export default withContext(({ ctx, templates, actions: { showPopover } }) => (
  <div>
    <div className="panel-body__hl">
      {ctx.t('js.recurring_jobs.heading.index')}
    </div>

    <div className="add-recurring-job">
      <DropDownButton
        text={ctx.t('js.recurring_jobs.buttons.add')}
        dom={ctx.dom}
        items={templates}
        showPopover={showPopover}
        itemPath={newRecurringJobPath}
      />
    </div>
  </div>
))
