

import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as TitleRoute from "../../types/TitleRoute.mjs";

var _setTitle = (function(title) { window.document.querySelector("title").innerHTML = title });

var suffix$p = Utils.Translations.t("js.title_suffix");

function suffixWhiteLabel$p(ctx) {
  return function (name) {
    return Utils.Translations.tx("js.title_suffix_white_label")({
                name: name
              }, ctx);
  };
}

function title(values, permission, route, ctx) {
  var title$1 = TitleRoute.make(RCore.$$Option.isSome(values), route, permission);
  var values$1 = RCore.$$Option.getOr(values, {});
  return RCore.$$Option.flatMap(title$1, (function (key) {
                var string = Utils.Translations.tx(key)(values$1, ctx);
                if (Js_string.search(/%\{.+\}/, string) === -1) {
                  return string;
                }
                
              }));
}

function brandedTitle(values, permission, route, ctx) {
  var t = title(values, permission, route, ctx);
  if (t === undefined) {
    return ;
  }
  var match = ctx.branding;
  if (match === undefined) {
    return t + suffix$p(ctx);
  }
  var brandName = match.brandName;
  if (brandName !== undefined) {
    return t + suffixWhiteLabel$p(ctx)(brandName);
  } else {
    return t + suffix$p(ctx);
  }
}

function updateTitle(permission, route, ctx, values) {
  var title = brandedTitle(values, permission, route, ctx);
  if (title !== undefined) {
    return _setTitle(title);
  }
  
}

function Title(Props) {
  var permissionOpt = Props.permission;
  var route = Props.route;
  var ctx = Props.ctx;
  var values = Props.values;
  var wire = Props.wire;
  var permission = permissionOpt !== undefined ? permissionOpt : "ViewEdit";
  React.useEffect((function () {
          updateTitle(permission, route, ctx, values);
        }), [
        wire.route,
        values
      ]);
  return null;
}

var make = Title;

export {
  title ,
  make ,
}
/* suffix' Not a pure module */
