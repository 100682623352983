/* eslint-disable no-undef */
import React, { Component } from 'react'

export default class Autocomplete extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      highlightedIndex: null
    }

    this.cursor = null

    this._ignoreBlur = false
    this.keyDownHandlers = {
      ArrowDown(event) {
        event.preventDefault()
        const itemsLength = this.getFilteredItems().length
        if (!itemsLength) return
        const { highlightedIndex } = this.state
        const index =
          highlightedIndex === null || highlightedIndex === itemsLength - 1
            ? 0
            : highlightedIndex + 1
        this._performAutoCompleteOnKeyUp = true
        this.setState(() => ({
          highlightedIndex: index,
          isOpen: true
        }))
      },

      ArrowUp(event) {
        event.preventDefault()
        const itemsLength = this.getFilteredItems().length
        if (!itemsLength) return
        const { highlightedIndex } = this.state
        const index =
          highlightedIndex === 0 || highlightedIndex === null
            ? itemsLength - 1
            : highlightedIndex - 1
        this._performAutoCompleteOnKeyUp = true
        this.setState(() => ({
          highlightedIndex: index,
          isOpen: true
        }))
      },

      Enter(event) {
        if (!this.isOpen()) {
          return
        }

        if (this.state.highlightedIndex === null) {
          this.setState(
            () => ({ isOpen: false }),
            () => {
              this.el.select()
            }
          )
        } else {
          event.preventDefault()

          const item = this.getFilteredItems()[this.state.highlightedIndex]
          const value = this.props.getItemValue(item)

          this.setState(
            () => ({
              isOpen: false,
              highlightedIndex: null
            }),
            () => {
              this.el.setSelectionRange(value.length, value.length)
              this.props.onSelect(value, item)
            }
          )
        }
      },

      Escape() {
        this.setState(() => ({
          highlightedIndex: null,
          isOpen: false
        }))
      }
    }

    this.getFilteredItems = this.getFilteredItems.bind(this)
    this.maybeAutoCompleteText = this.maybeAutoCompleteText.bind(this)
    this.setMenuPositions = this.setMenuPositions.bind(this)
    this.handleInputBlur = this.handleInputBlur.bind(this)
    this.handleInputClick = this.handleInputClick.bind(this)
  }

  componentWillMount() {
    this._ignoreBlur = false
    this._performAutoCompleteOnUpdate = false
    this._performAutoCompleteOnKeyUp = false
  }

  componentWillReceiveProps(nextProps) {
    this._performAutoCompleteOnUpdate = true
    // If `items` has changed we want to reset `highlightedIndex`
    // since it probably no longer refers to a relevant item
    if (
      this.props.items !== nextProps.items ||
      // The entries in `items` may have been changed even though the
      // object reference remains the same, double check by seeing
      // if `highlightedIndex` points to an existing item
      this.state.highlightedIndex >= nextProps.items.length
    ) {
      this.setState(() => ({ highlightedIndex: null }))
    }
  }

  componentDidMount() {
    if (this.isOpen()) {
      this.setMenuPositions()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.state.isOpen && !prevState.isOpen) ||
      ('open' in this.props && this.props.open && !prevProps.open)
    )
      this.setMenuPositions()

    if (this.isOpen() && this._performAutoCompleteOnUpdate) {
      this._performAutoCompleteOnUpdate = false
      this.maybeAutoCompleteText()
    }

    if (prevState.isOpen !== this.state.isOpen) {
      this.props.onMenuVisibilityChange(this.state.isOpen)
    }

    if (this.cursor !== null) {
      this.el.selectionStart = this.cursor
      this.el.selectionEnd = this.cursor
    }
  }

  handleKeyDown = event => {
    if (this.keyDownHandlers[event.key])
      this.keyDownHandlers[event.key].call(this, event)
    else if (!this.isOpen()) {
      this.setState(() => ({ isOpen: true }))
    }
  }

  handleChange = event => {
    this.cursor = event.target.selectionStart
    this._performAutoCompleteOnKeyUp = true
    this.setState(() => ({ highlightedIndex: null }))
    this.props.onChange(event, event.target.value)
  }

  handleKeyUp = () => {
    if (this._performAutoCompleteOnKeyUp) {
      this._performAutoCompleteOnKeyUp = false
      this.maybeAutoCompleteText()
    }
  }

  getFilteredItems() {
    let items = this.props.items

    if (this.props.shouldItemRender) {
      items = items.filter(item =>
        this.props.shouldItemRender(item, this.props.value)
      )
    }

    if (this.props.sortItems) {
      items.sort((a, b) => this.props.sortItems(a, b, this.props.value))
    }

    return items
  }

  maybeAutoCompleteText() {
    const { highlightedIndex } = this.state
    const { getItemValue, autoHighlight, value } = this.props

    if (!autoHighlight || value === '') {
      return
    }

    const items = this.getFilteredItems()
    if (items.length === 0) {
      return
    }

    const matchedItem =
      highlightedIndex !== null ? items[highlightedIndex] : items[0]
    const itemValue = getItemValue(matchedItem)
    const itemValueDoesMatch =
      itemValue.toLowerCase().indexOf(value.toLowerCase()) !== -1
    if (itemValueDoesMatch && highlightedIndex === null) {
      this.setState(() => ({ highlightedIndex: 0 }))
    }
  }

  setMenuPositions() {
    const node = this.el
    const rect = node.getBoundingClientRect()
    const computedStyle = global.window.getComputedStyle(node)
    const marginBottom = parseInt(computedStyle.marginBottom, 10) || 0
    const marginLeft = parseInt(computedStyle.marginLeft, 10) || 0
    const marginRight = parseInt(computedStyle.marginRight, 10) || 0
    this.setState(() => ({
      menuTop: rect.bottom + marginBottom,
      menuLeft: rect.left + marginLeft,
      menuWidth: rect.width + marginLeft + marginRight
    }))
  }

  highlightItemFromMouse = index => {
    this.setState(() => ({ highlightedIndex: index }))
  }

  selectItemFromMouse = item => {
    const value = this.props.getItemValue(item)
    this.setState(
      () => ({
        isOpen: false,
        highlightedIndex: null
      }),
      () => {
        this.props.onSelect(value, item)
        this.el.focus()
      }
    )
  }

  setIgnoreBlur = ignore => {
    this._ignoreBlur = ignore
  }

  renderMenu = () => {
    const items = this.getFilteredItems().map((item, index) => {
      return this.props.renderItem(
        item,
        this.state.highlightedIndex === index,
        {
          cursor: 'default',
          // Ignore blur to prevent menu from de-rendering before we can process click
          onMouseDown: () => this.setIgnoreBlur(true),
          onMouseEnter: () => this.highlightItemFromMouse(index),
          onMouseLeave: () => this.highlightItemFromMouse(null),
          onClick: () => this.selectItemFromMouse(item),
          key: `item-${index}`
        }
      )
    })
    const style = {
      left: this.state.menuLeft,
      top: this.state.menuTop,
      minWidth: this.state.menuWidth
    }
    return this.props.renderMenu(items, this.props.value, {
      style: style,
      ref: el => {
        this.menu = el
      }
    })
  }

  handleInputBlur() {
    if (this._ignoreBlur) return
    this.setState(() => ({
      isOpen: false,
      highlightedIndex: null
    }))
  }

  handleInputFocus = () => {
    if (this._ignoreBlur) {
      this.setIgnoreBlur(false)
      return
    }
    this.setState(() => ({ isOpen: true }))
  }

  isInputFocused = () => {
    const el = this.el
    return el.ownerDocument && el === el.ownerDocument.activeElement
  }

  handleInputClick() {
    // Input will not be focused if it's disabled
    if (this.isInputFocused() && !this.isOpen()) {
      this.setState(() => ({ isOpen: true }))
    }
  }

  composeEventHandlers(internal, external) {
    return external ? e => (internal(e), external(e)) : internal
  }

  isOpen = () => ('open' in this.props ? this.props.open : this.state.isOpen)

  render() {
    const { inputProps } = this.props
    const open = this.isOpen()

    return (
      <div style={this.props.wrapperStyle} {...this.props.wrapperProps}>
        <input
          {...inputProps}
          autoComplete="off"
          ref={node => (this.el = node)}
          onFocus={this.composeEventHandlers(
            this.handleInputFocus,
            inputProps.onFocus
          )}
          onBlur={this.composeEventHandlers(
            this.handleInputBlur,
            inputProps.onBlur
          )}
          onChange={this.handleChange}
          onKeyDown={this.composeEventHandlers(
            this.handleKeyDown,
            inputProps.onKeyDown
          )}
          onKeyUp={this.composeEventHandlers(
            this.handleKeyUp,
            inputProps.onKeyUp
          )}
          onClick={this.composeEventHandlers(
            this.handleInputClick,
            inputProps.onClick
          )}
          value={this.props.value}
        />
        {open && this.renderMenu()}
      </div>
    )
  }
}

Autocomplete.defaultProps = {
  value: '',
  wrapperProps: {},
  wrapperStyle: {
    display: 'inline-block'
  },
  inputProps: {},
  onChange() {},
  onSelect() {},
  menuStyle: {
    borderRadius: '3px',
    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
    background: 'rgba(255, 255, 255, 0.9)',
    padding: '2px 0',
    fontSize: '90%',
    position: 'fixed',
    overflow: 'auto',
    maxHeight: '50%'
  },
  renderMenu(items, _, { style, ...props }) {
    return (
      <div
        {...props}
        style={{ ...style, ...this.menuStyle }}
        children={items}
      />
    )
  },
  autoHighlight: true,
  onMenuVisibilityChange() {}
}
