

import * as Utils from "../../utils/Utils.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as EncodeJson from "../../libraries/EncodeJson.mjs";
import * as Backend_Headers from "../../libraries/backend/Backend_Headers.mjs";
import * as Pages_Activation_Types from "./Pages_Activation_Types.mjs";

function show(wire, token) {
  return Backend.decode(Backend.get(Backend_Headers.withUserTimezone, undefined, false, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Activation",
                    VAL: token
                  }
                }), Pages_Activation_Types.State.decode(wire.ctx.localizator.international));
}

var Get = {
  show: show
};

function user(state) {
  return EncodeJson.object([
              [
                "password",
                EncodeJson.string(state.password)
              ],
              [
                "password_confirmation",
                EncodeJson.string(state.passwordConfirmation)
              ],
              [
                "terms_accepted",
                EncodeJson.bool(state.isChecked)
              ]
            ]);
}

function tenant(state) {
  return EncodeJson.object([
              [
                "country",
                EncodeJson.string(state.country)
              ],
              [
                "timezone",
                EncodeJson.string(state.timezone)
              ],
              [
                "language",
                EncodeJson.string(state.language)
              ]
            ]);
}

function encode(state) {
  return Utils.jsonToObj(EncodeJson.object([
                  [
                    "user",
                    user(state)
                  ],
                  [
                    "tenant",
                    tenant(state)
                  ]
                ]));
}

function update(wire, token, state) {
  return Backend.post(undefined, undefined, false, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "Activation",
                VAL: token
              }
            }, {
              NAME: "Obj",
              VAL: encode(state)
            });
}

var Patch = {
  update: update
};

export {
  Get ,
  Patch ,
}
/* Utils Not a pure module */
