

import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as JobIndexFilter_CustomWrapper from "./JobIndexFilter_CustomWrapper.mjs";

var yes$p = Utils.Translations.tr("js.jobs.index.filters.boolean.true_value");

var no$p = Utils.Translations.tr("js.jobs.index.filters.boolean.false_value");

var any$p = Utils.Translations.tr("js.jobs.index.filters.polytype.any");

var notFilled$p = Utils.Translations.tr("js.jobs.index.filters.polytype.not_filled");

var FilterType = {};

function JobIndexFilter_CustomBoolean(Props) {
  var label = Props.label;
  var resetFilter = Props.resetFilter;
  var toggleFilter = Props.toggleFilter;
  var filterByField = Props.filterByField;
  var value = Props.value;
  var show = Props.show;
  var disabled = Props.disabled;
  var type_ = Props.type_;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var match$1 = value !== undefined ? [
      true,
      value
    ] : [
      false,
      false
    ];
  var boolValue = match$1[1];
  var hasValue = match$1[0];
  var match$2;
  match$2 = type_ === "Boolean" ? [
      yes$p(ctx),
      no$p(ctx)
    ] : [
      any$p(ctx),
      notFilled$p(ctx)
    ];
  var noLabel = match$2[1];
  var yesLabel = match$2[0];
  var onResetClick = function (param) {
    resetFilter();
    toggleFilter(false);
  };
  var renderSelected = React.createElement("div", {
        className: "j-filter__selected"
      }, value !== undefined ? (
          value ? yesLabel : noLabel
        ) : null);
  return React.createElement(JobIndexFilter_CustomWrapper.make, {
              toggleFilter: toggleFilter,
              onResetClick: onResetClick,
              renderSelected: renderSelected,
              label: label,
              show: show,
              hasValue: hasValue,
              leftButton: {
                onClick: (function (param) {
                    filterByField(true);
                  }),
                checked: boolValue,
                text: yesLabel
              },
              rightButton: {
                onClick: (function (param) {
                    filterByField(false);
                  }),
                checked: hasValue && !boolValue,
                text: noLabel
              },
              disabled: disabled
            });
}

var make = JobIndexFilter_CustomBoolean;

export {
  FilterType ,
  make ,
}
/* yes' Not a pure module */
