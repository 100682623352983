// extracted by mini-css-extract-plugin
export var closeButton = "close-button-Ohzr5owrhrJ7Z7kDT7hD";
export var form = "form-tpS_BOFC6hD26CeO8rEy";
export var formBody = "form-body-oYisqgZXs94hyB4FXhxh";
export var formFooter = "form-footer-z0UfDP9ouQ9gUKFKeP4v";
export var formGroup = "form-group-aXpmBYw0AhnzkbJMWd1I";
export var formGroupFileInput = "form-group-file-input-voRNdf8cea6VWkohZ2Lp";
export var formHeader = "form-header-UPgKxzhRJ_DsBPiG5do2";
export var label = "label-flxnhFbaz3eWQnLzkBB6";
export var modalContainer = "modal-container-v_NoPQAlC6HP2pvBRMnt";
export var noteBottomMargin = "note-bottom-margin-Yh0oRdRmKRxAhqx0Pz9L";
export var sizeLimit = "size-limit-cICuihJ_1BDH1DLDFreI";