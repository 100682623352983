import React from 'react'
import DatePicker from './date_picker'
import TimePicker from './time_picker'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import {
  concatDateWithTime,
  updateDay,
  showTime,
  ceilToHour,
  now,
  formatDurationAsTime,
} from 'planado/utils/time/index.js'
import * as styles from './styles.module.css'

const emptyErrors = {
  date: [],
  time: [],
}

export default withContext(
  ({
    id = '',
    label,
    dateTime,
    limits,
    timeStep = 300,
    onChange,
    errors = emptyErrors,
    calendarPlacement = 'bottom',
    disabled = false,
    businessHours = null,
    ctx,
    outlineIcons = false,
    wrapperClass,
    dateClass,
    timeClass,
  }) => {
    const onDateChange = (updatedDate) => {
      let time
      if (updatedDate === null) {
        time = null
      } else if (dateTime.time === null) {
        const todayDate = showTime(now(ctx), 'date', ctx)

        if (updatedDate === todayDate) {
          time = ceilToHour(ctx)
        } else if (businessHours) {
          time = formatDurationAsTime(businessHours.start)
        } else {
          time = '10:00'
        }
      } else {
        time = dateTime.time
      }

      return onChange({
        date: updatedDate,
        time,
      })
    }

    const onTimeChange = (updatedTime, bias) => {
      if (bias) {
        return onChange({
          date: bias > 0 ? changeToNextDay() : changeToPreviousDay(),
          time: updatedTime || '00:00',
        })
      } else {
        return onChange({
          date: actualDateTime().date,
          time: updatedTime || '00:00',
        })
      }
    }

    const actualDateTime = () => {
      if (dateTime.date && dateTime.date !== 'Invalid date') {
        return dateTime
      } else {
        return new Object({
          date: showTime(now(ctx), 'date', ctx),
          time: dateTime.time,
        })
      }
    }

    const changeToPreviousDay = () => {
      const updatedDateTime = updateDay({
        dateTime: concatDateWithTime({ ...actualDateTime(), ctx }),
        bias: -1,
        ctx,
      })
      return showTime(updatedDateTime, 'date', ctx)
    }

    const changeToNextDay = () => {
      const updatedDateTime = updateDay({
        dateTime: concatDateWithTime({ ...actualDateTime(), ctx }),
        bias: 1,
        ctx,
      })
      return showTime(updatedDateTime, 'date', ctx)
    }

    return (
      <div className={`${wrapperClass} ${styles.pickerWrapper}`}>
        <label htmlFor={id} className={styles.headline}>
          {label}
        </label>

        <div className={styles.inputs}>
          <DatePicker
            id={id}
            date={dateTime.date}
            onChange={onDateChange}
            calendarPlacement={calendarPlacement}
            disabled={disabled}
            errors={errors.date}
            limits={limits}
            outlineIcons={outlineIcons}
            wrapperClass={dateClass}
          />

          <TimePicker
            time={dateTime.time}
            onChange={onTimeChange}
            disabled={disabled}
            secondsStep={timeStep}
            dateChangeable
            errors={errors.time}
            popupPlacement={calendarPlacement}
            outlineIcons={outlineIcons}
            wrapperClass={timeClass}
          />
        </div>
      </div>
    )
  }
)

export { DatePicker, TimePicker }
