import {
  REMOVE_VALUE,
  CHANGE_VALUE_NAME,
  CHANGE_DICTIONARY_NAME,
  ADD_VALUE,
  REORDER_VALUES,
  SHOW_POPOVER,
  HIDE_POPOVER,
  ADD_NAME_ERROR,
  ADD_VALUE_ERROR,
  SET_TEMPLATES,
  DISABLE_SUBMIT_BUTTON,
  SET_DICTIONARY,
} from 'planado/actions/admin/dictionaries_show'
import { getPosition } from 'planado/components/popover'
import { v6 as uuidv6 } from 'uuid-with-v6'

const INITIAL_STATE = {
  isNew: true,
  dictionary: {
    name: '',
    values: [],
  },
  popovers: [],
  formSubmitted: false,
}

const changeValue = (values, idx, field) =>
  values.map((v, i) => (i === idx ? { ...v, ...field } : v))

const removeValue = (values, idx) => values.filter((v, i) => i !== idx)

const showPopover = ({ popovers }, { dom, node, buttonId, place, modal }) => [
  ...popovers,
  {
    node,
    modal,
    place,
    targetId: buttonId,
    position: getPosition(dom, buttonId, place),
  },
]

const hidePopover = (popovers) => popovers.slice(0, popovers.length - 1)

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DICTIONARY:
      return {
        ...state,
        dictionary: {
          ...action.dictionary,
        },
        formSubmitted: false,
        isNew: false,
      }

    case CHANGE_DICTIONARY_NAME:
      return {
        ...state,
        formSubmitted: false,
        dictionary: {
          ...state.dictionary,
          name: action.name,
        },
      }

    case REMOVE_VALUE: {
      const values = action.isNew
        ? removeValue(state.dictionary.values, action.idx)
        : changeValue(state.dictionary.values, action.idx, { visible: false })

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          values,
        },
      }
    }

    case CHANGE_VALUE_NAME:
      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          values: changeValue(state.dictionary.values, action.idx, {
            name: action.name,
          }),
        },
      }

    case ADD_VALUE:
      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          values: [
            ...state.dictionary.values,
            { uuid: uuidv6(), isNew: true, name: '', visible: true },
          ],
        },
      }

    case REORDER_VALUES:
      const { values } = action.value

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          values: values,
        },
      }

    case ADD_NAME_ERROR:
      return {
        ...state,
        validate: true,
        dictionary: {
          ...state.dictionary,
          errors: action.errors,
        },
      }

    case ADD_VALUE_ERROR:
      return {
        ...state,
        validate: true,
        dictionary: {
          ...state.dictionary,
          values: changeValue(state.dictionary.values, action.idx, {
            errors: action.errors,
          }),
        },
      }

    case SET_TEMPLATES:
      return {
        ...state,
        templates: action.templates,
      }

    case SHOW_POPOVER:
      return {
        ...state,
        popovers: showPopover(state, action),
      }

    case DISABLE_SUBMIT_BUTTON:
      return {
        ...state,
        formSubmitted: true,
      }

    case HIDE_POPOVER:
    case 'WINDOW_CLICKED':
      return {
        ...state,
        popovers: hidePopover(state.popovers),
      }

    default:
      return state
  }
}

export default reducer
