

import * as Backend from "./backend/Backend.mjs";
import * as PromiseF from "./PromiseF.mjs";
import * as Shared_Lib_IO from "../shared/lib/Shared_Lib_IO.mjs";

function logEvent(wire, name) {
  Shared_Lib_IO.unsafeRunAsync(PromiseF.toIOLazy(function () {
            return Backend.get(undefined, true, undefined, wire, {
                        NAME: "API",
                        VAL: {
                          NAME: "Analytics",
                          VAL: name
                        }
                      });
          }), (function (prim) {
          
        }));
}

function count(name) {
  Shared_Lib_IO.unsafeRunAsync(Shared_Lib_IO.suspend(function () {
            console.count(name);
          }), (function (prim) {
          
        }));
}

export {
  logEvent ,
  count ,
}
/* Backend Not a pure module */
