import React, { Component } from 'react'
import classNames from 'classnames'
import jQuery from 'jquery'
import { clientPath, sitePath } from 'planado/routes.js'

export class Toolbar extends Component {
  state = {
    editable: this.props.sideBar.chart().isEditable()
  }

  get effect() {
    return {
      effect: 'blind',
      duration: 100,
      direction: 'vertical'
    }
  }

  stopPropagation(evt) {
    evt.stopPropagation()
  }

  startJob = evt => {
    evt.preventDefault()
    if (this.props.job.published() && this.state.editable) {
      this.props.job.start(this.props.ctx)
    }
  }

  resumeJob = evt => {
    evt.preventDefault()
    if (this.props.job.suspended() && this.state.editable) {
      this.props.job.resume(this.props.ctx)
    }
  }

  showJobOnChart = evt => {
    evt.preventDefault()
    this.props.sideBar.goToJob(this.props.job, this.props.ctx)
  }

  showJobOnMap = evt => {
    if (!this.props.job.posted()) {
      document.location.pathname = evt.currentTarget.href
    }
  }

  editJob = evt => {
    evt.preventDefault()
    if (this.state.editable) {
      this.props.open()
    }
  }

  componentDidMount() {
    jQuery(this.toolbar).show(this.effect)
  }

  componentDidUpdate() {
    if (this.props.hiding) {
      let $el = jQuery(this.toolbar)
      $el.hide({
        ...this.effect,
        complete: () => $el.trigger('toolbar:hidden')
      })
    }
  }

  render() {
    let { job, ctx } = this.props

    let showOnChartStyles = classNames({
      'fa fa-calendar-o': true,
      disabled: !job.hasStart(ctx)
    })

    let startStyles = classNames({
      'fa fa-play': true,
      disabled: !job.published()
    })

    let finishStyles = classNames({
      'fa fa-check': true,
      disabled: job.finished()
    })

    let showOnMapStyles = classNames({
      'fa fa-map-marker-alt': true,
      disabled: job.posted()
    })

    let mapRef = '/map'

    let changeStateButton

    if (job.finished() || job.started()) {
      changeStateButton = (
        <li>
          <a
            href="#"
            className={finishStyles}
            onClick={this.finishJob}
            title={ctx.t('js.schedule.job_actions.finish')}
          />
        </li>
      )
    } else if (job.suspended()) {
      changeStateButton = (
        <li>
          <a
            href="#"
            className="fa fa-play"
            onClick={this.resumeJob}
            title={ctx.t('js.schedule.job_actions.resume')}
          />
        </li>
      )
    } else {
      changeStateButton = (
        <li>
          <a
            href="#"
            className={startStyles}
            onClick={this.startJob}
            title={ctx.t('js.schedule.job_actions.start')}
          />
        </li>
      )
    }

    const site = job.get('site')

    const siteBlock =
      site === null ? null : (
        <a
          target="_blank"
          href={sitePath(site.uuid)}
          className="client-info site-info"
        >
          {site.name}
        </a>
      )

    let client

    if (job.get('client_uuid')) {
      client = (
        <a
          href={clientPath(job.get('client_uuid'))}
          className="client-info"
          target="_blank"
        >
          {job.get('client_name')}
        </a>
      )
    } else if (job.get('description')) {
      client = <div style={{ height: 40 }} />
    } else {
      client = ''
    }

    let description

    if (job.get('description')) {
      description = (
        <div className="job-description" title={job.get('description')}>
          {job.get('description')}
        </div>
      )
    } else {
      description = ''
    }

    let jobType

    if (job.get('job_type')) {
      jobType = (
        <div className="type-wrapper">
          <span className="job-type label label-tag label-success">
            {job.get('job_type')}
          </span>
        </div>
      )
    } else {
      jobType = ''
    }

    return (
      <div
        className="job-toolbar"
        style={{ display: 'none' }}
        ref={div => {
          this.toolbar = div
        }}
      >
        <div className="job-details">
          {jobType}
          {siteBlock}
          {client}
        </div>
        <ul onClick={this.stopPropagation}>
          {changeStateButton}
          <li>
            <a
              href="#"
              className={showOnChartStyles}
              onClick={this.showJobOnChart}
              title={ctx.t('js.schedule.job_actions.show_on_chart')}
            />
          </li>
          <li>
            <a
              href={mapRef}
              className={showOnMapStyles}
              onClick={this.showJobOnMap}
              title={ctx.t('js.schedule.job_actions.show_on_map')}
            />
          </li>
          <li>
            <a
              href="#"
              className="fa fa-pencil-alt"
              onClick={this.editJob}
              title={ctx.t('js.schedule.job_actions.edit_job')}
            />
          </li>
        </ul>
        {description}
      </div>
    )
  }
}
