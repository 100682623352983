

import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Portal from "../../../Portal.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Provider from "../../../Provider.mjs";
import * as JobTypeAPI from "./JobTypeAPI.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JobTypeIndexJS from "./JobTypeIndexJS.mjs";
import * as MessengerTypes from "../../messenger/MessengerTypes.mjs";
import * as JobSettingsTabs from "../JobSettingsTabs.mjs";
import * as JobSettingsHeader from "../JobSettingsHeader.mjs";
import * as JobTypeSaveButtonJS from "./JobTypeSaveButtonJS.mjs";
import * as JobTypeIndexFooterJS from "./JobTypeIndexFooterJS.mjs";
import Job_types from "planado/stores/admin/job_types";

var jobTypes$p = Utils.Translations.tr("js.job_types.title");

var failureMsg$p = Utils.Translations.t("js.job_types.messages.unprocessable_entity");

var successMsg$p = Utils.Translations.t("js.job_types.messages.updated");

function JobTypeIndex(Props) {
  var wire = Props.wire;
  var messenger = wire.subscriptions.messenger;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        
      });
  var setState = match[1];
  var state = match[0];
  var loaded = function (data) {
    if (data.TAG !== "Ok") {
      return ;
    }
    var data$1 = data._0;
    if (data$1 !== undefined) {
      return setState(function (param) {
                  return Caml_option.some(Job_types(data$1));
                });
    }
    
  };
  var onFailure = function () {
    messenger.trigger({
          TAG: "Show",
          _0: MessengerTypes.Message.make(undefined, "Danger", {
                TAG: "Text",
                _0: failureMsg$p(ctx)
              })
        });
  };
  var onSuccess = function () {
    messenger.trigger({
          TAG: "Show",
          _0: MessengerTypes.Message.make(undefined, undefined, {
                TAG: "Text",
                _0: successMsg$p(ctx)
              })
        });
  };
  React.useEffect((function () {
          Backend.$$finally(JobTypeAPI.Index.index(wire), loaded);
        }), []);
  if (state !== undefined) {
    return React.createElement(Provider.make, {
                store: Caml_option.valFromOption(state),
                children: React.createElement("div", undefined, React.createElement("div", {
                          className: "page-container narrow"
                        }, React.createElement("form", {
                              className: "form-horizontal"
                            }, React.createElement(JobSettingsHeader.make, {
                                  ctx: ctx
                                }), React.createElement("div", {
                                  className: "settings-page row"
                                }, React.createElement(JobSettingsTabs.make, {
                                      wire: wire,
                                      tab: "JobTypes"
                                    }), React.createElement("div", {
                                      className: "tab-content"
                                    }, React.createElement("div", {
                                          className: "tab-pane active"
                                        }, React.createElement("div", {
                                              className: "panel"
                                            }, React.createElement("div", {
                                                  className: "panel-body"
                                                }, React.createElement("div", {
                                                      className: "panel-body__hl"
                                                    }, jobTypes$p(ctx)), React.createElement("div", {
                                                      id: "job-types"
                                                    }, React.createElement(JobTypeIndexJS.make, {}))), React.createElement("div", {
                                                  className: "panel-footer"
                                                }, React.createElement(JobTypeSaveButtonJS.make, {
                                                      onSuccess: onSuccess,
                                                      onFailure: onFailure
                                                    })))))))), React.createElement(Portal.make, {
                          root: wire.footer,
                          children: React.createElement(JobTypeIndexFooterJS.make, {})
                        }))
              });
  } else {
    return null;
  }
}

var make = JobTypeIndex;

export {
  make ,
}
/* jobTypes' Not a pure module */
