import React from 'react'
import classNames from 'classnames'

import { showTime } from 'planado/utils/time/index.js'
import { jobPath, clientPath, sitePath } from 'planado/routes.js'
import { hasLocation, jobName } from 'planado/utils/map'
import { locateJob } from 'planado/map_commands'
import JobAddress from './job_address'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import * as styles from './styles.module.css'

const Header = withContext(({ ctx, job, openJob }) => (
  <h3>
    <a
      href={jobPath(job.uuid)}
      onClick={evt => {
        evt.preventDefault()
        openJob(job.uuid)
      }}
    >
      {jobName(ctx, job)}
    </a>
  </h3>
))

const ScheduledSummary = withContext(({ job, ctx }) => {
  const scheduledAt = showTime(new Date(job.scheduledAt), 'time', ctx)
  let text

  if (job.scheduledFinishAt === null) {
    text = scheduledAt
  } else {
    const finishAt = showTime(new Date(job.scheduledFinishAt), 'time', ctx)
    text = `${scheduledAt}–${finishAt}`
  }

  if (job.assignees.teams.length > 0) {
    return (
      <div className={styles.summary}>{`${text} • ${job.assignees.teams[0].name}`}</div>
    )
  } else {
    const editWorker = job.assignees.workers.find(({ access }) => access === 'edit')

    if (editWorker) {
      return (
        <div className={styles.summary}>{`${text} • ${editWorker.name}`}</div>
      )
    } else {
      return <div className={styles.summary}>{text}</div>
    }
  }
})

const Timestamps = withContext(
  ({ status, enRouteAt, startedAt, suspendedAt, finishedAt, ctx }) => {
    switch (status) {
      case 'published':
        return (
          <div className={`${styles.summary} ${styles.timestamps}`}>
            <span>{ctx.t('js.map.worker_jobs.published_status')}</span>
          </div>
        )
      case 'en_route': {
        const enRoute = ctx.t('js.map.worker_jobs.en_route', {
          time: showTime(new Date(enRouteAt), 'time', ctx)
        })

        return (
          <div className={`${styles.summary} ${styles.timestamps}`}>
            <span>{enRoute}</span>
          </div>
        )
      }
      case 'started': {
        const started = ctx.t('js.map.worker_jobs.started', {
          time: showTime(new Date(startedAt), 'time', ctx)
        })

        if (enRouteAt === null) {
          return (
            <div className={`${styles.summary} ${styles.timestamps}`}>
              <span>{started}</span>
            </div>
          )
        } else {
          const enRoute = ctx.t('js.map.worker_jobs.en_route', {
            time: showTime(new Date(enRouteAt), 'time', ctx)
          })

          return (
            <div className={`${styles.summary} ${styles.timestamps}`}>
              <span>{enRoute}</span>
              <span>{started}</span>
            </div>
          )
        }
      }
      case 'suspended': {
        const started = ctx.t('js.map.worker_jobs.started', {
          time: showTime(new Date(startedAt), 'time', ctx)
        })
        const suspended = ctx.t('js.map.worker_jobs.suspended', {
          time: showTime(new Date(suspendedAt), 'time', ctx)
        })

        if (enRouteAt === null) {
          return (
            <div className={`${styles.summary} ${styles.timestamps}`}>
              <span>{started}</span>
              <span>{suspended}</span>
            </div>
          )
        } else {
          const enRoute = ctx.t('js.map.worker_jobs.en_route', {
            time: showTime(new Date(enRouteAt), 'time', ctx)
          })

          return (
            <div className={`${styles.summary} ${styles.timestamps}`}>
              <span>{enRoute}</span>
              <span>{started}</span>
              <br />
              <span>{suspended}</span>
            </div>
          )
        }
      }
      case 'finished': {
        const started = ctx.t('js.map.worker_jobs.started', {
          time: showTime(new Date(startedAt), 'time', ctx)
        })
        const finished = ctx.t('js.map.worker_jobs.finished', {
          time: showTime(new Date(finishedAt), 'time', ctx)
        })

        if (enRouteAt === null) {
          return (
            <div className={`${styles.summary} ${styles.timestamps}`}>
              <span>{started}</span>
              <span>{finished}</span>
            </div>
          )
        } else {
          const enRoute = ctx.t('js.map.worker_jobs.en_route', {
            time: showTime(new Date(enRouteAt), 'time', ctx)
          })

          return (
            <div className={`${styles.summary} ${styles.timestamps}`}>
              <span>{enRoute}</span>
              <span>{started}</span>
              <span>{finished}</span>
            </div>
          )
        }
      }
      default:
        return <div />
    }
  }
)

const Client = props => {
  if (props.job.clientName === null) {
    return <span />
  } else {
    return (
      <div className={styles.client}>
        <a href={clientPath(props.job.clientUuid)} target="_blank">
          {props.job.clientName}
        </a>
      </div>
    )
  }
}

const Site = ({ site }) => {
  if (site === null) {
    return null
  } else {
    return (
      <div className={styles.site}>
        <a href={sitePath(site.uuid)} target="_blank">
          {site.name}
        </a>
      </div>
    )
  }
}

const Resolution = props => {
  if (props.job.status === 'finished') {
    const classes = classNames({
      [styles.resolution]: true,
      [styles.success]: props.job.successful,
      [styles.failure]: !props.job.successful
    })

    return (
      <div className={classes}>
        <span>{props.job.resolution}</span>
      </div>
    )
  } else {
    return <span />
  }
}

class FocusedJob extends React.Component {
  constructor(props) {
    super(props)

    this.locateJob = this.locateJob.bind(this)
  }

  render() {
    const { job, onClose, openJob } = this.props

    if (job === null) {
      return <div />
    } else {
      return (
        <div className={`${styles.focusedJob} ${styles.focusedEntity}`}>
          <div className={styles.dismiss} onClick={onClose}>
            &times;
          </div>
          <Header job={job} openJob={openJob} />
          <ScheduledSummary job={job} />
          <JobAddress job={job} onClick={this.locateJob} />
          <Site site={job.site} />
          <Client job={job} />
          <Timestamps {...job} />
          <Resolution job={job} />
        </div>
      )
    }
  }

  locateJob(evt) {
    evt.stopPropagation()

    if (hasLocation(this.props.job)) {
      this.props.engineChannel.push(locateJob(this.props.job))
    }
  }
}

export default FocusedJob
