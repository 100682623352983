

import * as El from "../../../../libraries/El.mjs";
import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as Hooks from "../../../../libraries/hooks/Hooks.mjs";
import * as Panel from "../../../common/Panel/Panel.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as SaveView from "./save/SaveView.mjs";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ManageColumns from "./columns/ManageColumns.mjs";
import * as SettingsPopover from "./settings/SettingsPopover.mjs";
import * as SettingsPreviewContent from "./settings/SettingsPreviewContent.mjs";
import * as PopoverModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/Popover/Popover.module.css";
import * as JobViewsIndexModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/index/views/JobViewsIndex.module.css";

var count$p = Utils.Translations.txr("js.jobs.index.total_jobs");

var countMax$p = Utils.Translations.txr("js.jobs.index.total_jobs_max");

var styles = JobViewsIndexModuleCss;

var popoverStyles = PopoverModuleCss;

function JobViewsIndex(Props) {
  var count = Props.count;
  var currentColumns = Props.currentColumns;
  var customFields = Props.customFields;
  var reportFields = Props.reportFields;
  var views = Props.views;
  var activeView = Props.activeView;
  var viewsSaving = Props.viewsSaving;
  var selectView = Props.selectView;
  var saveViews = Props.saveViews;
  var returnDefaultView = Props.returnDefaultView;
  var createView = Props.createView;
  var saveView = Props.saveView;
  var toggleVirtualColumn = Props.toggleVirtualColumn;
  var toggleCustomColumn = Props.toggleCustomColumn;
  var toggleReportColumn = Props.toggleReportColumn;
  var toggleJobColumn = Props.toggleJobColumn;
  var match = React.useState(function () {
        return [];
      });
  var setShownViews = match[1];
  var match$1 = React.useState(function () {
        return [];
      });
  var setHiddenViews = match$1[1];
  var buttonUuid = React.useMemo((function () {
          return Uuid.createV4();
        }), []);
  var match$2 = React.useState(function () {
        return false;
      });
  var setShowModal = match$2[1];
  var showModal = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setShowSettings = match$3[1];
  var match$4 = AppContext.useWire();
  var subscriptions = match$4.subscriptions;
  var ctx = match$4.ctx;
  var popoverToggler = React.useRef(null);
  Hooks.useToggleOnClick1(popoverToggler, showModal, (function (show) {
          setShowModal(function (param) {
                return show;
              });
        }));
  var setViews = function () {
    var match = Utils.splitViews(views);
    var hiddenViews = match.hiddenViews;
    var shownViews = match.shownViews;
    setShownViews(function (param) {
          return shownViews;
        });
    setHiddenViews(function (param) {
          return hiddenViews;
        });
  };
  React.useEffect((function () {
          return subscriptions.resize.subscribe(function (param) {
                      setViews();
                    });
        }), []);
  React.useEffect((function () {
          setViews();
        }), [views]);
  var itemClasses = function (uuid) {
    if (activeView !== undefined && Caml_obj.equal(Caml_option.valFromOption(activeView), uuid)) {
      return "JobsViews-list__item active";
    } else {
      return "JobsViews-list__item";
    }
  };
  var items = match[0].map(function (v) {
        return React.createElement("button", {
                    key: Uuid.toString(v.uuid),
                    className: itemClasses(v.uuid),
                    type: "button",
                    onClick: (function (param) {
                        selectView(v.uuid);
                      })
                  }, v.name);
      });
  var openModal = function (param) {
    setShowModal(function (param) {
          return true;
        });
  };
  var renderCount;
  renderCount = typeof count !== "object" ? El.nbsp : (
      count.TAG === "Exact" ? count$p({
              count: count._0
            }, ctx) : countMax$p({
              count: count._0
            }, ctx)
    );
  return React.createElement("div", {
              className: "JobsViews"
            }, React.createElement("div", {
                  className: "JobsViews-list"
                }, items, React.createElement("button", {
                      ref: popoverToggler,
                      className: "JobsViews-list__item JobsViews-list__item_other js-view-elements",
                      id: Uuid.toString(buttonUuid),
                      type: "button",
                      onClick: openModal
                    })), showModal ? React.createElement(Panel.make, {
                    footer: match$4.footer,
                    domId: Uuid.toString(buttonUuid),
                    position: "bottomRight",
                    className: El.Cn.concat(styles.popover, popoverStyles.popover),
                    offsetX: 30.0,
                    children: React.createElement(SettingsPreviewContent.make, {
                          hiddenViews: match$1[0],
                          activeView: activeView,
                          selectView: (function (v) {
                              setShowModal(function (param) {
                                    return false;
                                  });
                              selectView(v);
                            }),
                          openSettings: (function () {
                              setShowModal(function (param) {
                                    return false;
                                  });
                              setShowSettings(function (param) {
                                    return true;
                                  });
                            }),
                          returnDefaultView: (function () {
                              setShowModal(function (param) {
                                    return false;
                                  });
                              returnDefaultView();
                            })
                        })
                  }) : (
                match$3[0] ? React.createElement(SettingsPopover.make, {
                        views: views,
                        viewsSaving: viewsSaving,
                        saveViews: (function (views) {
                            saveViews(views, (function () {
                                    setShowSettings(function (param) {
                                          return false;
                                        });
                                  }));
                          }),
                        hidePopover: (function () {
                            setShowSettings(function (param) {
                                  return false;
                                });
                          })
                      }) : null
              ), React.createElement("div", {
                  className: "JobsViews__jobs js-view-elements"
                }, renderCount), React.createElement("div", {
                  className: "JobsViews__divider"
                }), React.createElement(SaveView.make, {
                  views: views,
                  activeView: activeView,
                  createView: createView,
                  saveView: saveView
                }), React.createElement(ManageColumns.make, {
                  currentColumns: currentColumns,
                  customFields: customFields,
                  reportFields: reportFields,
                  toggleVirtualColumn: toggleVirtualColumn,
                  toggleCustomColumn: toggleCustomColumn,
                  toggleReportColumn: toggleReportColumn,
                  toggleJobColumn: toggleJobColumn
                }));
}

var make = JobViewsIndex;

export {
  make ,
}
/* count' Not a pure module */
