

import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as UI_Page from "../../ui/UI_Page.mjs";
import * as AppContext from "../../context/AppContext.mjs";
import * as Pages_Sites from "../sites/index/Pages_Sites.mjs";
import * as UI_PageTabs from "../../ui/UI_PageTabs.mjs";
import * as Pages_Clients from "../clients/index/Pages_Clients.mjs";
import * as Pages_Addresses_Header from "./header/Pages_Addresses_Header.mjs";
import * as Pages_AddressesModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/addresses/Pages_Addresses.module.css";

var styles = Pages_AddressesModuleCss;

var clients$p = Utils.Translations.tr("js.sites.index.tabs.clients");

var sites$p = Utils.Translations.tr("js.sites.index.tabs.sites");

function Pages_Addresses(Props) {
  var addressType = Props.addressType;
  var wire = AppContext.useWire();
  var match = React.useState(function () {
        return [
                undefined,
                undefined
              ];
      });
  var setCounts = match[1];
  var match$1 = match[0];
  var sitesCount = match$1[1];
  var clientsCount = match$1[0];
  var setCounts$1 = function (clientsCount, sitesCount) {
    setCounts(function (param) {
          return [
                  clientsCount,
                  sitesCount
                ];
        });
  };
  return React.createElement(UI_Page.make, {
              children: null
            }, React.createElement(Pages_Addresses_Header.make, {
                  addressType: addressType
                }), React.createElement(UI_PageTabs.make, {
                  children: null
                }, React.createElement(UI_PageTabs.Tab.make, {
                      route: {
                        NAME: "Client",
                        VAL: "Index"
                      },
                      renderLabel: (function () {
                          return React.createElement(React.Fragment, undefined, clients$p(wire.ctx), clientsCount !== undefined ? React.createElement("span", {
                                            className: styles.count
                                          }, clientsCount.toString()) : null);
                        }),
                      children: React.createElement(Pages_Clients.make, {
                            setCounts: setCounts$1
                          })
                    }), React.createElement(UI_PageTabs.Tab.make, {
                      route: {
                        NAME: "Site",
                        VAL: "Index"
                      },
                      renderLabel: (function () {
                          return React.createElement(React.Fragment, undefined, sites$p(wire.ctx), sitesCount !== undefined ? React.createElement("span", {
                                            className: styles.count
                                          }, sitesCount.toString()) : null);
                        }),
                      children: React.createElement(Pages_Sites.make, {
                            setCounts: setCounts$1
                          })
                    })));
}

var make = Pages_Addresses;

export {
  make ,
}
/* styles Not a pure module */
