import React, { Component } from 'react'
import moment from 'moment-timezone'
import { showTime } from 'planado/utils/time/index.js'
import classNames from 'classnames'

class HourMarks extends Component {
  shouldComponentUpdate({ scale }) {
    return this.props.scale !== scale
  }

  render() {
    const { model, ctx } = this.props

    return (
      <div className="hour-marks">
        {model.getMarks().reduce(
          (acc, day) =>
            acc.concat(
              day.marks.map((mark) => {
                let label
                let date = showTime(day.start, 'date', ctx)
                let time = showTime(mark.mark, 'time', ctx)
                if (mark.label) {
                  label = <div className="time-label">{time}</div>
                } else {
                  label = ''
                }

                let classes = classNames({
                  mark: true,
                  unlabeled: !label,
                  minor: !mark.major && !label,
                })

                return (
                  <div
                    className={classes}
                    key={date + ' ' + time}
                    style={{ left: mark.offset }}
                  >
                    <div className="terminator" />
                    {label}
                  </div>
                )
              })
            ),
          []
        )}
      </div>
    )
  }
}

const OddDays = ({ model, days }) => {
  const today = model.time().clone().startOf('day')
  const twoDays = moment.duration({ days: 2 }).as('seconds')

  const divs = days.map((day) => {
    const date = day.day.clone()

    if (date.diff(today, 'seconds') % twoDays !== 0) {
      return null
    } else {
      const dateFinish = date.clone().endOf('day')
      const dateOffset = model.viewOffset(date)
      const dateWidth = model.viewOffset(dateFinish) - dateOffset

      return (
        <div
          className="odd-day"
          key={date.unix()}
          style={{ left: dateOffset, width: dateWidth }}
        />
      )
    }
  })

  return <div className="odd-days">{divs}</div>
}

export class TimeLine extends Component {
  shouldComponentUpdate = (nextProps) => this.props.scale !== nextProps.scale

  componentDidMount = () =>
    this.props.model.on('change:start change:scale', this.forceUpdate2, this)

  componentWillUnmount = () =>
    this.props.model.off('change:start change:scale', this.forceUpdate2, this)

  forceUpdate2 = () => this.forceUpdate()

  dateMarks = (days) =>
    days.map((day) => {
      const date = showTime(day.day, 'date', this.props.ctx)
      return (
        <div className="mark" key={date} style={{ left: day.offset }}>
          {date}
        </div>
      )
    })

  render = () => {
    const { model, scale, ctx } = this.props
    const days = model.getDays()

    return (
      <div className="time-line">
        <div className="day-marks">{this.dateMarks(days)}</div>
        <HourMarks model={model} scale={scale} ctx={ctx} />
        <OddDays model={model} days={days} />
      </div>
    )
  }
}
