

import * as Utils from "../../../utils/Utils.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Context_Types from "../../../context/Context_Types.mjs";

function useSubstitutions(trackingForms) {
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var jobSubstitutions = [
    {
      TAG: "String",
      _0: {
        name: Utils.Translations.t("js.settings.sms.substitutions.job_serial_no")(ctx),
        key: "serial_no"
      }
    },
    {
      TAG: "Datetime",
      _0: {
        name: Utils.Translations.t("js.settings.sms.substitutions.job_scheduled_at")(ctx),
        key: "scheduled_at"
      }
    }
  ];
  var jobFilters = Context_Types.Features.hasFlag("clientTracking", ctx.features) ? [{
        name: Utils.Translations.t("js.settings.sms.filters.tracking")(ctx),
        key: "client_form_link",
        args: trackingForms.map(function (f) {
              if (typeof f !== "object") {
                return {
                        name: Utils.Translations.tx("js.settings.sms.filters.tracking_form_name")({
                              name: Utils.Translations.t("js.settings.sms.filters.default_form")(ctx)
                            }, ctx),
                        value: {
                          TAG: "String",
                          _0: "default"
                        }
                      };
              }
              var name = f.name;
              return {
                      name: Utils.Translations.tx("js.settings.sms.filters.tracking_form_name")({
                            name: name
                          }, ctx),
                      value: {
                        TAG: "String",
                        _0: name
                      }
                    };
            })
      }] : [];
  var workerSubstitutions = [
    {
      TAG: "String",
      _0: {
        name: Utils.Translations.t("js.settings.sms.substitutions.worker_name")(ctx),
        key: "name"
      }
    },
    {
      TAG: "String",
      _0: {
        name: Utils.Translations.t("js.settings.sms.substitutions.worker_phone")(ctx),
        key: "phone"
      }
    }
  ];
  var companySubstitutions = [{
      TAG: "String",
      _0: {
        name: Utils.Translations.t("js.settings.sms.substitutions.company_name")(ctx),
        key: "name"
      }
    }];
  return [
          {
            TAG: "Object",
            _0: {
              children: jobSubstitutions,
              name: Utils.Translations.t("js.settings.sms.substitutions.job")(ctx),
              key: "job",
              filters: jobFilters
            }
          },
          {
            TAG: "Object",
            _0: {
              children: workerSubstitutions,
              name: Utils.Translations.t("js.settings.sms.substitutions.worker")(ctx),
              key: "worker",
              filters: []
            }
          },
          {
            TAG: "Object",
            _0: {
              children: companySubstitutions,
              name: Utils.Translations.t("js.settings.sms.substitutions.company")(ctx),
              key: "company",
              filters: []
            }
          }
        ];
}

export {
  useSubstitutions ,
}
/* Utils Not a pure module */
