

import * as React from "react";
import * as Schedule_State from "../../lib/state/Schedule_State.mjs";
import * as Schedule_Unscheduled from "../../components/uscheduled_jobs/Schedule_Unscheduled.mjs";
import * as WeekCalendar_Content from "./components/content/WeekCalendar_Content.mjs";
import * as WeekCalendarModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_calendar/WeekCalendar.module.css";

var styles = WeekCalendarModuleCss;

function WeekCalendar$Component(Props) {
  return React.createElement("div", {
              className: styles.content
            }, React.createElement(WeekCalendar_Content.make, {}), React.createElement(Schedule_Unscheduled.make, {}));
}

var make = React.memo(WeekCalendar$Component, (function (_prevProps, nextProps) {
        return nextProps.locked;
      }));

function WeekCalendar(Props) {
  var locked = Schedule_State.useLocked();
  return React.createElement(make, {
              locked: locked
            });
}

var make$1 = WeekCalendar;

export {
  make$1 as make,
}
/* styles Not a pure module */
