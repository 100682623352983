import React, { Component } from 'react'
import FormInput from 'planado/components/input/form_input'
import DurationSpinner from 'planado/components/input/duration_spinner'
import { stringLengthWithNewLines } from 'planado/utils/index.js'
import {
  DadataAddressLink,
  GoogleAddressLink,
  ClientSelectLink,
  ContactsLink,
} from 'planado/containers/admin/recurring_jobs_show'
import { Select } from 'planado/components/input'
import { jobLimits } from 'planado/constants/limits'
import { clientPath, sitePath } from 'planado/routes.js'
import MultipleAssignees from 'rscrpt/components/common/MultipleAssignees/MultipleAssignees.mjs'

export default class JobInfo extends Component {
  state = {
    canValidate: false,
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.errors.name.length > 0) {
      this.setState(() => ({ canValidate: true }))
    }
  }

  changeHandler = (name, value) => {
    const {
      ctx,
      actions: { setField, setError },
    } = this.props
    const { canValidate } = this.state

    setField(name, value)

    if (name === 'name' && canValidate && value.length > 0) {
      setError('name', [])
    } else if (name === 'name' && canValidate) {
      setError('name', Array.of(ctx.t('js.recurring_jobs.errors.name_empty')))
    }
  }

  descriptionChangeHandler = (value) => {
    const {
      actions: { setField },
    } = this.props

    if (stringLengthWithNewLines(value) <= jobLimits.description) {
      setField('description', value)
    }
  }

  addressDescriptionChangeHandler = (value) => {
    const {
      actions: { setField },
    } = this.props

    if (stringLengthWithNewLines(value) <= jobLimits.addressDescription) {
      setField('addressDescription', value)
    }
  }

  inputId = (name) => `recurring_job-form-input-${name}`

  get addressInput() {
    const {
      mapOptions: { addressSourceOptions },
      wire,
      readOnly,
    } = this.props

    switch (addressSourceOptions.type) {
      case 'dadata':
        return <DadataAddressLink readOnly={readOnly} wire={wire} />
      case 'google':
      default:
        return (
          <GoogleAddressLink
            wire={wire}
            id={this.inputId('format')}
            inputName="recurring_job[address]"
          />
        )
    }
  }

  get clientSiteBlock() {
    const {
      ctx,
      job: { client, site },
    } = this.props

    let clientOrSiteLabel
    if (site) {
      clientOrSiteLabel = ctx.t('js.recurring_jobs.labels.site')
    } else if (client) {
      clientOrSiteLabel = ctx.t('js.recurring_jobs.labels.client')
    } else {
      clientOrSiteLabel = ctx.t('js.recurring_jobs.labels.client_or_site')
    }

    return (
      <div className="pd-form-group">
        <label className="pd-label">{clientOrSiteLabel}</label>

        {!client && !site ? null : (
          <span className="pd-form-label-action-link-container">
            <a
              href={site ? sitePath(site.uuid) : clientPath(client.uuid)}
              target="_blank"
              className="pd-form-label-action-link"
            >
              {ctx.t('js.jobs.labels.go_to_client')}
              &nbsp;
              <span className="fa fa-external-link-alt" />
            </a>
          </span>
        )}

        <ClientSelectLink
          id={this.inputId('client_uuid')}
          placeholder={ctx.t('js.recurring_jobs.placeholders.client_or_site')}
        />
      </div>
    )
  }

  get addressBlock() {
    const {
      ctx,
      job,
      actions: { setField },
    } = this.props

    return (
      <div className="client-form-address">
        {this.addressInput}

        <div className="pd-form-group-container pd-form-group-three-quarters">
          <FormInput
            id={this.inputId('apartment')}
            type="text"
            value={job.apartment || ''}
            wrapperClass={{
              'client-first_name': true,
              'pd-form-group-sm': true,
            }}
            labelText={ctx.t('js.recurring_jobs.labels.apartment')}
            onValueChange={(value) => setField('apartment', value)}
            maxLength={jobLimits.apartment}
          />

          <FormInput
            id={this.inputId('floor')}
            type="text"
            value={job.floor || ''}
            wrapperClass={{
              'client-first_name': true,
              'pd-form-group-sm': true,
            }}
            labelText={ctx.t('js.recurring_jobs.labels.floor')}
            onValueChange={(value) => setField('floor', value)}
            maxLength={jobLimits.floor}
          />

          <FormInput
            id={this.inputId('entranceNo')}
            type="text"
            value={job.entranceNo || ''}
            wrapperClass={{
              'client-first_name': true,
              'pd-form-group-sm': true,
            }}
            labelText={ctx.t('js.recurring_jobs.labels.entrance_no')}
            onValueChange={(value) => setField('entranceNo', value)}
            maxLength={jobLimits.entranceNo}
          />
        </div>

        <FormInput
          id={this.inputId('address-description')}
          type="textarea"
          value={job.addressDescription || ''}
          labelText={ctx.t('js.recurring_jobs.labels.address_description')}
          onValueChange={this.addressDescriptionChangeHandler}
          maxLength={jobLimits.addressDescription}
        />
      </div>
    )
  }

  render() {
    const {
      isNew,
      job,
      types,
      errors,
      ctx,
      actions: { setField, setAssignees },
    } = this.props
    return (
      <section className="form-section">
        <div className="form-container">
          <FormInput
            id={this.inputId('name')}
            labelText={ctx.t('js.recurring_jobs.labels.name')}
            type="text"
            wrapperClass={{ 'js-recurring-job-name': true }}
            value={job.name || ''}
            onValueChange={(v) => this.changeHandler('name', v)}
            errors={errors.name}
            maxLength={jobLimits.name}
          />

          <FormInput
            id={this.inputId('address-description')}
            type="textarea"
            value={job.description || ''}
            labelText={ctx.t('js.recurring_jobs.labels.description')}
            onValueChange={this.descriptionChangeHandler}
            maxLength={jobLimits.description}
          />

          <div className="pd-form-group">
            <label className="pd-label">
              {ctx.t('js.recurring_jobs.labels.type')}
            </label>

            <Select
              autosize={false}
              name="recurring_job[type_id]"
              placeholder={ctx.t('js.recurring_jobs.placeholders.type')}
              onChange={typeUuid => setField('typeUuid', typeUuid)}
              value={job.typeUuid}
              disabled={!isNew}
              options={types.map(({ uuid, name }) => ({
                label: name,
                value: uuid
              }))}
            />
          </div>

          <div className="pd-form-group">
            <label className="pd-label">
              {ctx.t('js.recurring_jobs.labels.assignee_subject')}
            </label>

            <MultipleAssignees
              selectedAssignees={job.assignees}
              territoryUuid={null}
              skillUuids={[]}
              availableSkills={[]}
              disabled={false}
              onChange={setAssignees}
            />
          </div>

          <div className="pd-form-group">
            <label className="pd-label" htmlFor={this.inputId('duration')}>
              {ctx.t('js.recurring_jobs.labels.duration')}
            </label>

            <DurationSpinner
              id={this.inputId('duration')}
              min={5}
              max={job.maxScheduledDuration}
              step={5}
              onValueChange={(seconds) =>
                setField('scheduledDuration', seconds)
              }
              defaultValue={job.scheduledDuration / 60}
            />
          </div>

          {this.clientSiteBlock}

          {this.addressBlock}

          <ContactsLink />
        </div>
      </section>
    )
  }
}
