

import * as Uuid from "../../../../../bindings/uuid/Uuid.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as JobFieldType from "../../JobFieldType.mjs";

function toString(fieldType) {
  switch (fieldType.TAG) {
    case "System" :
    case "PredefinedField" :
        return JobFieldType.toString(fieldType._0);
    case "CustomField" :
    case "ReportField" :
        return Uuid.toString(fieldType._0);
    
  }
}

var allSystem = [
  "Assignee",
  "Status",
  "Template",
  "ScheduledAt",
  "FinishedAt",
  "Client"
];

function systemEql(field) {
  return function (fieldType) {
    if (fieldType.TAG === "System") {
      return field === fieldType._0;
    } else {
      return false;
    }
  };
}

function predefinedEql(field) {
  return function (fieldType) {
    if (fieldType.TAG === "PredefinedField") {
      return field === fieldType._0;
    } else {
      return false;
    }
  };
}

function customEql(field) {
  return function (fieldType) {
    switch (fieldType.TAG) {
      case "CustomField" :
          return Caml_obj.equal(field, fieldType._0);
      case "System" :
      case "PredefinedField" :
      case "ReportField" :
          return false;
      
    }
  };
}

function reportEql(field) {
  return function (fieldType) {
    switch (fieldType.TAG) {
      case "System" :
      case "PredefinedField" :
      case "CustomField" :
          return false;
      case "ReportField" :
          return Caml_obj.equal(field, fieldType._0);
      
    }
  };
}

export {
  toString ,
  allSystem ,
  systemEql ,
  predefinedEql ,
  customEql ,
  reportEql ,
}
/* Uuid Not a pure module */
