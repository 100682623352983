

import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Schedule_Types_Job from "../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as WeekCalendar_Constants from "../../lib/WeekCalendar_Constants.mjs";

function make(intervals, jobs) {
  return intervals.map(function (index) {
              var startPx = Math.imul(index, WeekCalendar_Constants.sizeOfHourPx) - 1 | 0;
              var finishPx = (startPx + WeekCalendar_Constants.sizeOfHourPx | 0) + (
                index === 0 ? 2 : 1
              ) | 0;
              var startM = Math.imul(index, WeekCalendar_Constants.minutesInHour);
              var finishM = startM + WeekCalendar_Constants.minutesInHour | 0;
              var jobs$1 = RCore.$$Array.keep(jobs, (function (param) {
                      var position = param[0];
                      if (startM <= position.finish) {
                        return finishM > position.start;
                      } else {
                        return false;
                      }
                    }));
              return {
                      index: index,
                      startPx: startPx,
                      finishPx: finishPx,
                      jobs: jobs$1
                    };
            });
}

function update(groups) {
  return RCore.$$Array.reduce(groups, [], (function (groups, group) {
                var sizeOfGroups = groups.length;
                if (sizeOfGroups <= 0) {
                  return [group];
                }
                var prev = groups[sizeOfGroups - 1 | 0];
                var groupsAreEqual = RCore.$$Array.equal(group.jobs, prev.jobs, (function (param, param$1) {
                        return Caml_obj.equal(Schedule_Types_Job.ScheduledJob.uuid(param[1]), Schedule_Types_Job.ScheduledJob.uuid(param$1[1]));
                      }));
                if (groupsAreEqual) {
                  return groups.map(function (g) {
                              if (g.index === prev.index) {
                                return {
                                        index: g.index,
                                        startPx: g.startPx,
                                        finishPx: group.finishPx,
                                        jobs: g.jobs
                                      };
                              } else {
                                return g;
                              }
                            });
                } else {
                  Js_array.push(group, groups);
                  return groups;
                }
              }));
}

var Groups = {
  make: make,
  update: update
};

export {
  Groups ,
}
/* Schedule_Types_Job Not a pure module */
