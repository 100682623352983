

import * as El from "../../../libraries/El.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Radio from "../../inputs/Radio.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../libraries/Locale.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as UserAPI from "./UserAPI.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../inputs/Checkbox.mjs";
import * as Optional from "../../common/Optional.mjs";
import * as FormInput from "../../inputs/FormInput.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SkillsSelect from "../../inputs/SkillsSelect.mjs";
import * as Context_Types from "../../../context/Context_Types.mjs";
import * as Backend_Errors from "../../../libraries/backend/Backend_Errors.mjs";
import * as MessengerTypes from "../../messenger/MessengerTypes.mjs";
import * as TerritoriesSelect from "../../inputs/TerritoriesSelect.mjs";
import * as UserFieldUserFormModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/user/UserFieldUserForm.module.css";

var title$p = Utils.Translations.tr("js.admin.users.field_user_form.title");

var titleEdit$p = Utils.Translations.tr("js.admin.users.field_user_form.title_edit");

var add$p = Utils.Translations.tr("js.admin.users.field_user_form.add_user");

var save$p = Utils.Translations.tr("js.admin.users.field_user_form.save");

var delete$p = Utils.Translations.tr("js.admin.users.field_user_form.delete");

var cancel$p = Utils.Translations.tr("js.admin.users.field_user_form.cancel");

var email$p = Utils.Translations.t("js.admin.users.field_user_form.fields.email");

var firstName$p = Utils.Translations.t("js.admin.users.field_user_form.fields.first_name");

var lastName$p = Utils.Translations.t("js.admin.users.field_user_form.fields.last_name");

var howToNotify$p = Utils.Translations.tr("js.admin.users.field_user_form.fields.how_to_notify");

var bySms$p = Utils.Translations.t("js.admin.users.field_user_form.fields.by_sms");

var byEmail$p = Utils.Translations.t("js.admin.users.field_user_form.fields.by_email");

var mobilePhone$p = Utils.Translations.t("js.admin.users.field_user_form.fields.mobile_phone");

var skills$p = Utils.Translations.t("js.admin.users.field_user_form.fields.skills");

var territory$p = Utils.Translations.t("js.admin.users.field_user_form.fields.territory");

var canCompleteJobs$p = Utils.Translations.t("js.admin.users.field_user_form.fields.can_complete_jobs");

var canCreateJobs$p = Utils.Translations.t("js.admin.users.field_user_form.fields.can_create_jobs");

var noFreeLicense$p = Utils.Translations.t("js.admin.users.field_user_form.messages.no_free_license");

var mustBeFilled$p = Utils.Translations.t("js.admin.users.field_user_form.messages.must_be_filled");

var incorrectPhone$p = Utils.Translations.t("js.admin.users.field_user_form.messages.incorrect_phone");

var unprocessableEntity$p = Utils.Translations.t("js.admin.users.field_user_form.messages.unprocessable_entity");

var userCreated$p = Utils.Translations.t("js.admin.users.field_user_form.messages.user_created");

var userUpdated$p = Utils.Translations.t("js.admin.users.field_user_form.messages.user_updated");

var userDeleted$p = Utils.Translations.t("js.admin.users.field_user_form.messages.user_deleted");

var instructionsWereSent$p = Utils.Translations.t("js.admin.users.field_user_form.instructions_were_sent");

var sentBySms$p = Utils.Translations.t("js.admin.users.field_user_form.by_sms");

var toNumber$p = Utils.Translations.t("js.admin.users.field_user_form.to_number");

var toEmail$p = Utils.Translations.t("js.admin.users.field_user_form.to_email");

var sendAgain$p = Utils.Translations.t("js.admin.users.field_user_form.send_again");

var at$p = Utils.Translations.t("js.admin.users.field_user_form.at");

var styles = UserFieldUserFormModuleCss;

var emptyErrors_email = [];

var emptyErrors_firstName = [];

var emptyErrors_lastName = [];

var emptyErrors_mobilePhone = [];

var emptyErrors = {
  email: emptyErrors_email,
  firstName: emptyErrors_firstName,
  lastName: emptyErrors_lastName,
  mobilePhone: emptyErrors_mobilePhone
};

var textInputStyle = Js_dict.fromList({
      hd: [
        styles.textInput,
        true
      ],
      tl: /* [] */0
    });

var contactInputStyle = Js_dict.fromList({
      hd: [
        styles.textInput,
        true
      ],
      tl: {
        hd: [
          styles.contactInput,
          true
        ],
        tl: /* [] */0
      }
    });

function UserFieldUserForm$Form(Props) {
  var wire = Props.wire;
  var onSubmitNew = Props.onSubmitNew;
  var onSubmitUpdate = Props.onSubmitUpdate;
  var onDelete = Props.onDelete;
  var licenseAvailable = Props.licenseAvailable;
  var availableTerritories = Props.availableTerritories;
  var backendErrors = Props.backendErrors;
  var userInvitation = Props.userInvitation;
  var navigate = wire.navigate;
  var messenger = wire.subscriptions.messenger;
  var ctx = wire.ctx;
  var getInvitationValue = function (cb, defaultValue) {
    return function () {
      return RCore.$$Option.getOr(RCore.$$Option.map(userInvitation, cb), defaultValue);
    };
  };
  var partial_arg = getInvitationValue((function (i) {
          return i.firstName;
        }), "");
  var match = React.useState(Curry.__1(partial_arg));
  var setFirstName = match[1];
  var firstName = match[0];
  var partial_arg$1 = getInvitationValue((function (i) {
          return i.lastName;
        }), "");
  var match$1 = React.useState(Curry.__1(partial_arg$1));
  var setLastName = match$1[1];
  var lastName = match$1[0];
  var partial_arg$2 = getInvitationValue((function (i) {
          return i.latestNotificationMethod;
        }), "Sms");
  var match$2 = React.useState(Curry.__1(partial_arg$2));
  var setNotificationMethod = match$2[1];
  var notificationMethod = match$2[0];
  var partial_arg$3 = getInvitationValue((function (i) {
          return i.email;
        }), "");
  var match$3 = React.useState(Curry.__1(partial_arg$3));
  var setEmail = match$3[1];
  var email = match$3[0];
  var partial_arg$4 = getInvitationValue((function (i) {
          return i.mobilePhone;
        }), "");
  var match$4 = React.useState(Curry.__1(partial_arg$4));
  var setMobilePhone = match$4[1];
  var mobilePhone = match$4[0];
  var partial_arg$5 = getInvitationValue((function (i) {
          return i.skillUuids;
        }), []);
  var match$5 = React.useState(Curry.__1(partial_arg$5));
  var setSkillUuids = match$5[1];
  var skillUuids = match$5[0];
  var partial_arg$6 = getInvitationValue((function (i) {
          return i.territoryUuid;
        }), undefined);
  var match$6 = React.useState(Curry.__1(partial_arg$6));
  var setTerritoryUuid = match$6[1];
  var territoryUuid = match$6[0];
  var match$7 = React.useState(function () {
        var skillUuids = getInvitationValue((function (i) {
                  return i.skillUuids;
                }), [])();
        return skillUuids.length > 0;
      });
  var toggleSkillsCheckbox = match$7[1];
  var skillsCheckbox = match$7[0];
  var match$8 = React.useState(function () {
        var territoryUuid = getInvitationValue((function (i) {
                  return i.territoryUuid;
                }), undefined)();
        return RCore.$$Option.isSome(territoryUuid);
      });
  var toggleTerritoryCheckbox = match$8[1];
  var territoryCheckbox = match$8[0];
  var partial_arg$7 = getInvitationValue((function (i) {
          return i.canCompleteJobs;
        }), licenseAvailable);
  var match$9 = React.useState(Curry.__1(partial_arg$7));
  var toggleCompleteJobs = match$9[1];
  var canCompleteJobs = match$9[0];
  var partial_arg$8 = getInvitationValue((function (i) {
          return i.canCreateJobs;
        }), false);
  var match$10 = React.useState(Curry.__1(partial_arg$8));
  var toggleCreateJobs = match$10[1];
  var canCreateJobs = match$10[0];
  var match$11 = React.useState(function () {
        return false;
      });
  var toggleSendAgain = match$11[1];
  var sendAgain = match$11[0];
  var match$12 = React.useState(function () {
        return emptyErrors;
      });
  var setErrors = match$12[1];
  var errors = match$12[0];
  React.useEffect((function () {
          setErrors(function (param) {
                return backendErrors;
              });
        }), [backendErrors]);
  var toggleCompleteJobs$1 = function () {
    toggleCompleteJobs(function (v) {
          if (licenseAvailable) {
            return !v;
          } else {
            return false;
          }
        });
  };
  var toggleCreateJobs$1 = function () {
    toggleCreateJobs(function (v) {
          if (licenseAvailable) {
            return !v;
          } else {
            return false;
          }
        });
  };
  var noFreeLicense = licenseAvailable === false;
  var errorsFirstName = firstName === "" ? [mustBeFilled$p(ctx)] : [];
  var errorsLastName = lastName === "" ? [mustBeFilled$p(ctx)] : [];
  var mustNotify = RCore.$$Option.isNone(userInvitation) || sendAgain;
  var errorsMobilePhone;
  if (mustNotify && notificationMethod === "Sms") {
    var digitsCount = Utils.extractDigits(mobilePhone).length;
    errorsMobilePhone = digitsCount < 7 || digitsCount > 25 ? [incorrectPhone$p(ctx)] : [];
  } else {
    errorsMobilePhone = [];
  }
  var errorsEmail = mustNotify && notificationMethod === "Email" && email.length === 0 ? [mustBeFilled$p(ctx)] : [];
  var errors$1 = {
    email: errorsEmail,
    firstName: errorsFirstName,
    lastName: errorsLastName,
    mobilePhone: errorsMobilePhone
  };
  var formHasErrors = Core__List.some({
        hd: errorsFirstName,
        tl: {
          hd: errorsLastName,
          tl: {
            hd: errorsMobilePhone,
            tl: {
              hd: errorsEmail,
              tl: /* [] */0
            }
          }
        }
      }, (function (es) {
          return Caml_obj.notequal(es, []);
        }));
  var handleClickSubmit = function (evt) {
    evt.preventDefault();
    if (formHasErrors) {
      messenger.trigger({
            TAG: "Show",
            _0: MessengerTypes.Message.make(undefined, "Danger", {
                  TAG: "Text",
                  _0: unprocessableEntity$p(ctx)
                })
          });
      return setErrors(function (param) {
                  return errors$1;
                });
    }
    var invitation = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      notificationMethod: notificationMethod,
      mobilePhone: mobilePhone,
      canCompleteJobs: canCompleteJobs,
      canCreateJobs: canCreateJobs,
      skillsCheckbox: skillsCheckbox,
      territoryCheckbox: territoryCheckbox,
      skillUuids: skillUuids,
      territoryUuid: territoryUuid,
      sendAgain: sendAgain
    };
    setErrors(function (param) {
          return emptyErrors;
        });
    if (userInvitation !== undefined) {
      return onSubmitUpdate(userInvitation.uuid, invitation);
    } else {
      return onSubmitNew(invitation);
    }
  };
  var stripMailTo = function (str) {
    return function (param) {
      var regex = /^\s*mailto:/g;
      return Js_string.replaceByRe(regex, "", str);
    };
  };
  var availableSkills = ctx.availableSkills.map(function (s) {
        return {
                uuid: s.uuid,
                name: s.name
              };
      });
  var tmp;
  tmp = notificationMethod === "Sms" ? React.createElement(FormInput.make, {
          _type: "text",
          value: mobilePhone,
          onValueChange: (function (v) {
              setMobilePhone(function (param) {
                    return v;
                  });
            }),
          labelText: mobilePhone$p(ctx),
          maxLength: 50,
          errors: errors.mobilePhone,
          wrapperClass: contactInputStyle
        }) : React.createElement(FormInput.make, {
          _type: "text",
          value: email,
          onValueChange: (function (v) {
              setEmail(stripMailTo(v));
            }),
          labelText: email$p(ctx),
          maxLength: 100,
          errors: errors.email,
          wrapperClass: contactInputStyle
        });
  var chooseNotificationMethod = React.createElement("div", {
        className: styles.chooseNotificationMethod
      }, React.createElement(Radio.make, {
            onChange: (function (param) {
                setNotificationMethod(function (param) {
                      return "Sms";
                    });
              }),
            checked: notificationMethod === "Sms",
            labelText: bySms$p(ctx)
          }), React.createElement(Radio.make, {
            onChange: (function (param) {
                setNotificationMethod(function (param) {
                      return "Email";
                    });
              }),
            checked: notificationMethod === "Email",
            labelText: byEmail$p(ctx)
          }), tmp);
  var tmp$1;
  if (userInvitation !== undefined) {
    var match$13 = userInvitation.latestNotificationMethod;
    var message;
    message = match$13 === "Sms" ? El.Cn.concat(El.Cn.concat(El.Cn.concat(instructionsWereSent$p(ctx), sentBySms$p(ctx)), toNumber$p(ctx)), userInvitation.mobilePhone) : El.Cn.concat(El.Cn.concat(instructionsWereSent$p(ctx), toEmail$p(ctx)), userInvitation.email);
    var dateTimeIso = userInvitation.latestNotificationSendAt;
    var date;
    if (dateTimeIso !== undefined) {
      var date$1 = Locale.T.fmtS("DateNoYear", wire)(dateTimeIso);
      var time = Utils.Translations.timeFromStr(dateTimeIso, wire);
      date = El.Cn.concat(El.Cn.concat(El.Cn.concat(" ", date$1), at$p(ctx)), time);
    } else {
      date = "";
    }
    tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("p", {
              className: styles.howToNotify
            }, El.Cn.concat(message + ",", date)), React.createElement(Checkbox.make, {
              onToggle: (function (param) {
                  toggleSendAgain(function (cond) {
                        return !cond;
                      });
                }),
              checked: sendAgain,
              label: sendAgain$p(ctx),
              wrapperClasses: Js_dict.fromList({
                    hd: [
                      styles.sendAgainCheckbox,
                      true
                    ],
                    tl: /* [] */0
                  })
            }), React.createElement(Optional.make, {
              show: sendAgain,
              children: chooseNotificationMethod
            }));
  } else {
    tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("p", {
              className: styles.howToNotify
            }, howToNotify$p(ctx)), chooseNotificationMethod);
  }
  return React.createElement("div", {
              className: styles.modalContainer
            }, React.createElement("div", {
                  className: styles.form
                }, React.createElement("form", undefined, React.createElement("div", {
                          className: styles.formHeader
                        }, React.createElement("div", undefined, userInvitation !== undefined ? titleEdit$p(ctx) : title$p(ctx)), React.createElement("div", undefined, React.createElement("a", {
                                  className: "close",
                                  onClick: (function (evt) {
                                      evt.preventDefault();
                                      navigate(undefined, {
                                            NAME: "User",
                                            VAL: "Index"
                                          });
                                    })
                                }, React.createElement("span", {
                                      className: Icon.style(undefined, undefined, "times")
                                    })))), React.createElement("div", {
                          className: styles.formBody
                        }, React.createElement(FormInput.make, {
                              _type: "text",
                              value: firstName,
                              onValueChange: (function (v) {
                                  setFirstName(function (param) {
                                        return v;
                                      });
                                }),
                              labelText: firstName$p(ctx),
                              maxLength: 50,
                              errors: errors.firstName,
                              wrapperClass: textInputStyle,
                              autoFocus: true
                            }), React.createElement(FormInput.make, {
                              _type: "text",
                              value: lastName,
                              onValueChange: (function (v) {
                                  setLastName(function (param) {
                                        return v;
                                      });
                                }),
                              labelText: lastName$p(ctx),
                              maxLength: 50,
                              errors: errors.lastName,
                              wrapperClass: textInputStyle
                            }), tmp$1, React.createElement(Optional.make, {
                              show: Context_Types.Features.hasFlag("teams", ctx.features),
                              children: React.createElement(React.Fragment, undefined, React.createElement(Checkbox.make, {
                                        onToggle: (function (param) {
                                            toggleCompleteJobs$1();
                                          }),
                                        checked: canCompleteJobs,
                                        label: canCompleteJobs$p(ctx),
                                        disabled: noFreeLicense,
                                        wrapperClasses: Js_dict.fromList({
                                              hd: [
                                                styles.completeJobsCheckbox,
                                                true
                                              ],
                                              tl: /* [] */0
                                            })
                                      }), React.createElement(Optional.make, {
                                        show: Context_Types.Features.hasFlag("createJobsInApp", wire.ctx.features),
                                        children: React.createElement(Checkbox.make, {
                                              onToggle: (function (param) {
                                                  toggleCreateJobs$1();
                                                }),
                                              checked: canCreateJobs,
                                              label: canCreateJobs$p(ctx),
                                              disabled: noFreeLicense,
                                              wrapperClasses: Js_dict.fromList({
                                                    hd: [
                                                      styles.createJobsCheckbox,
                                                      true
                                                    ],
                                                    tl: /* [] */0
                                                  })
                                            })
                                      }), React.createElement(Optional.make, {
                                        show: noFreeLicense,
                                        children: React.createElement("p", {
                                              className: styles.noFreeLicense
                                            }, noFreeLicense$p(ctx))
                                      }))
                            }), React.createElement("div", {
                              className: styles.formGroup
                            }, React.createElement(Checkbox.make, {
                                  onToggle: (function (param) {
                                      toggleSkillsCheckbox(function (v) {
                                            return !v;
                                          });
                                    }),
                                  checked: skillsCheckbox,
                                  label: skills$p(ctx)
                                }), React.createElement(Optional.make, {
                                  show: skillsCheckbox,
                                  children: React.createElement(SkillsSelect.make, {
                                        id: "user-skills",
                                        inputName: "input-user-skills",
                                        selectedSkillUuids: skillUuids,
                                        onChange: (function (v) {
                                            setSkillUuids(function (param) {
                                                  return v;
                                                });
                                          }),
                                        availableSkills: availableSkills,
                                        placeholder: "",
                                        disabled: false
                                      })
                                })), React.createElement(Optional.make, {
                              show: Context_Types.Features.hasFlag("territories", ctx.features),
                              children: React.createElement("div", {
                                    className: styles.formGroup
                                  }, React.createElement(Checkbox.make, {
                                        onToggle: (function (param) {
                                            toggleTerritoryCheckbox(function (v) {
                                                  return !v;
                                                });
                                          }),
                                        checked: territoryCheckbox,
                                        label: territory$p(ctx)
                                      }), React.createElement(Optional.make, {
                                        show: territoryCheckbox,
                                        children: React.createElement(TerritoriesSelect.make, {
                                              id: "user-territories",
                                              inputName: "input-user-territory",
                                              selectedTerritoryUuid: Js_null.fromOption(territoryUuid),
                                              onChange: (function (v) {
                                                  setTerritoryUuid(function (param) {
                                                        if (v === null) {
                                                          return ;
                                                        } else {
                                                          return Caml_option.some(v);
                                                        }
                                                      });
                                                }),
                                              availableTerritories: availableTerritories,
                                              placeholder: "",
                                              disabled: false
                                            })
                                      }))
                            })), React.createElement("div", {
                          className: styles.formFooter
                        }, userInvitation !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("button", {
                                    className: "btn btn-danger",
                                    onClick: (function (evt) {
                                        evt.preventDefault();
                                        onDelete(userInvitation.uuid);
                                      })
                                  }, delete$p(ctx)), React.createElement("div", {
                                    className: styles.btnGroup
                                  }, React.createElement("button", {
                                        className: "btn btn-default",
                                        onClick: (function (evt) {
                                            evt.preventDefault();
                                            navigate(undefined, {
                                                  NAME: "User",
                                                  VAL: "Index"
                                                });
                                          })
                                      }, cancel$p(ctx)), React.createElement("button", {
                                        className: "btn btn-primary",
                                        onClick: handleClickSubmit
                                      }, save$p(ctx)))) : React.createElement("button", {
                                className: "btn btn-primary",
                                onClick: handleClickSubmit
                              }, add$p(ctx))))));
}

function UserFieldUserForm(Props) {
  var wire = Props.wire;
  var onClose = Props.onClose;
  var navigate = wire.navigate;
  var route = wire.route;
  var messenger = wire.subscriptions.messenger;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        
      });
  var setBackendValues = match[1];
  var backendValues = match[0];
  var match$1 = React.useState(function () {
        return emptyErrors;
      });
  var setBackendErrors = match$1[1];
  var handleResponse = function (r) {
    switch (r.TAG) {
      case "Created" :
          var match = r._0;
          if (match.TAG === "Ok") {
            messenger.trigger({
                  TAG: "Show",
                  _0: MessengerTypes.Message.make(undefined, "Success", {
                        TAG: "Text",
                        _0: userCreated$p(ctx)
                      })
                });
            return onClose();
          }
          var errors = match._0;
          if (typeof errors !== "object") {
            return ;
          }
          if (errors.TAG !== "ValidationError") {
            return ;
          }
          messenger.trigger({
                TAG: "Show",
                _0: MessengerTypes.Message.make(undefined, "Danger", {
                      TAG: "Text",
                      _0: unprocessableEntity$p(ctx)
                    })
              });
          var errors$1 = UserAPI.FieldUserForm.UnprocessableEntity.errorsFromString(Backend_Errors.json(errors._0));
          return setBackendErrors(function (param) {
                      return errors$1;
                    });
      case "Updated" :
          var match$1 = r._0;
          if (match$1.TAG === "Ok") {
            messenger.trigger({
                  TAG: "Show",
                  _0: MessengerTypes.Message.make(undefined, "Success", {
                        TAG: "Text",
                        _0: userUpdated$p(ctx)
                      })
                });
            return onClose();
          }
          var errors$2 = match$1._0;
          if (typeof errors$2 !== "object") {
            return ;
          }
          if (errors$2.TAG !== "ValidationError") {
            return ;
          }
          messenger.trigger({
                TAG: "Show",
                _0: MessengerTypes.Message.make(undefined, "Danger", {
                      TAG: "Text",
                      _0: unprocessableEntity$p(ctx)
                    })
              });
          var errors$3 = UserAPI.FieldUserForm.UnprocessableEntity.errorsFromString(Backend_Errors.json(errors$2._0));
          return setBackendErrors(function (param) {
                      return errors$3;
                    });
      case "Deleted" :
          if (r._0.TAG !== "Ok") {
            return onClose();
          }
          onClose();
          return messenger.trigger({
                      TAG: "Show",
                      _0: MessengerTypes.Message.make(undefined, "Success", {
                            TAG: "Text",
                            _0: userDeleted$p(ctx)
                          })
                    });
      case "NewUser" :
          var res = r._0;
          if (res.TAG !== "Ok") {
            return ;
          }
          var res$1 = res._0;
          var match$2 = res$1.licenseAvailable;
          var match$3 = Context_Types.Features.hasFlag("teams", ctx.features);
          if (!match$2 && !match$3) {
            onClose();
            return messenger.trigger({
                        TAG: "Show",
                        _0: MessengerTypes.Message.make(undefined, "Danger", {
                              TAG: "Text",
                              _0: noFreeLicense$p(ctx)
                            })
                      });
          }
          return setBackendValues(function (param) {
                      return res$1;
                    });
          break;
      case "Show" :
          var res$2 = r._0;
          if (res$2.TAG === "Ok") {
            var res$3 = res$2._0;
            return setBackendValues(function (param) {
                        return res$3;
                      });
          }
          var match$4 = res$2._0;
          if (typeof match$4 !== "object") {
            return ;
          }
          if (match$4.TAG !== "HTTPError") {
            return ;
          }
          var tmp = match$4._0[0];
          if (typeof tmp !== "object" && tmp === "NotFound") {
            return navigate(undefined, "Unknown");
          } else {
            return ;
          }
      
    }
  };
  var onSubmitNew = function (user) {
    Backend.$$finally(UserAPI.FieldUserForm.create(wire, user), (function (v) {
            handleResponse({
                  TAG: "Created",
                  _0: v
                });
          }));
  };
  var onSubmitUpdate = function (uuid, user) {
    Backend.$$finally(UserAPI.FieldUserForm.update(wire, uuid, user), (function (v) {
            handleResponse({
                  TAG: "Updated",
                  _0: v
                });
          }));
  };
  var onDelete = function (uuid) {
    Backend.$$finally(UserAPI.FieldUserForm.destroy(wire, uuid), (function (v) {
            handleResponse({
                  TAG: "Deleted",
                  _0: v
                });
          }));
  };
  React.useEffect((function () {
          var match = route.path;
          if (typeof match === "object" && match.NAME === "User") {
            var match$1 = match.VAL;
            if (typeof match$1 === "object" && match$1.NAME === "Field") {
              var match$2 = match$1.VAL;
              if (typeof match$2 === "object") {
                Backend.$$finally(UserAPI.FieldUserForm.edit(wire, match$2.VAL), (function (v) {
                        handleResponse({
                              TAG: "Show",
                              _0: v
                            });
                      }));
              } else {
                Backend.$$finally(UserAPI.FieldUserForm.new_(wire), (function (v) {
                        handleResponse({
                              TAG: "NewUser",
                              _0: v
                            });
                      }));
              }
            }
            
          }
          
        }), []);
  if (backendValues !== undefined) {
    return React.createElement(UserFieldUserForm$Form, {
                wire: wire,
                onSubmitNew: onSubmitNew,
                onSubmitUpdate: onSubmitUpdate,
                onDelete: onDelete,
                licenseAvailable: backendValues.licenseAvailable,
                availableTerritories: backendValues.availableTerritories,
                backendErrors: match$1[0],
                userInvitation: backendValues.userInvitation
              });
  } else {
    return null;
  }
}

var make = UserFieldUserForm;

export {
  make ,
}
/* title' Not a pure module */
