

import * as Wire from "../../../types/Wire.mjs";
import * as Modal from "../../../components/modal/Modal.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as MessengerTypes from "../../../components/messenger/MessengerTypes.mjs";
import * as Pages_Clients_Upload_Form from "./Pages_Clients_Upload_Form.mjs";
import * as Pages_Clients_Upload_Types from "./Pages_Clients_Upload_Types.mjs";

var pageNotFound$p = Utils.Translations.t("js.clients.upload.page_error");

function Pages_Clients_Upload(Props) {
  var wire = Props.wire;
  var messenger = wire.subscriptions.messenger;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return Pages_Clients_Upload_Types.make(undefined, true, undefined, [], false, false, false, false, undefined, false);
      });
  React.useEffect((function () {
          var match = ctx.permissions;
          if (match !== undefined && match.clients === "View") {
            Wire.closeModal(wire);
            messenger.trigger({
                  TAG: "Show",
                  _0: MessengerTypes.Message.make(undefined, "Warning", {
                        TAG: "Text",
                        _0: pageNotFound$p(ctx)
                      })
                });
          }
          
        }), []);
  var match$1 = ctx.permissions;
  var show = match$1 !== undefined ? match$1.clients === "ViewEdit" : false;
  var onClose = function () {
    Wire.closeModal(wire);
  };
  return React.createElement(Modal.make, {
              wire: wire,
              onClose: onClose,
              show: show,
              children: React.createElement("div", {
                    id: "content-wrapper"
                  }, React.createElement("div", {
                        className: "page-container narrow"
                      }, React.createElement(Pages_Clients_Upload_Form.make, {
                            state: match[0],
                            setState: match[1],
                            hideModal: onClose
                          })))
            });
}

var make = Pages_Clients_Upload;

export {
  make ,
}
/* pageNotFound' Not a pure module */
