

import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Checkbox from "./Checkbox.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IndeterminateCheckboxModuleCss from "/home/runner/work/planado/planado/client/rescript/components/inputs/IndeterminateCheckbox.module.css";

var styles = IndeterminateCheckboxModuleCss;

function IndeterminateCheckbox(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var wrapperClasses = Props.wrapperClasses;
  var disabled = Props.disabled;
  var children = Props.children;
  var checked = value !== "unchecked";
  var inputCN = value === "checked" || value === "unchecked" ? Js_dict.fromList(/* [] */0) : Js_dict.fromList({
          hd: [
            styles.indeterminate,
            true
          ],
          tl: /* [] */0
        });
  var onToggle = function (_newValue) {
    if (disabled) {
      return ;
    } else if (value === "unchecked") {
      return onChange("checked");
    } else {
      return onChange("unchecked");
    }
  };
  var tmp = {
    onToggle: onToggle,
    checked: checked,
    disabled: disabled,
    wrapperClasses: wrapperClasses,
    checkboxClass: inputCN
  };
  if (children !== undefined) {
    tmp.children = Caml_option.valFromOption(children);
  }
  return React.createElement(Checkbox.make, tmp);
}

var make = IndeterminateCheckbox;

var $$default = IndeterminateCheckbox;

export {
  make ,
  $$default as default,
}
/* styles Not a pure module */
