

import * as SelectJsx from "planado/components/input/select.jsx";

var make = SelectJsx.ContextlessSelect;

var Uuid = {
  make: SelectJsx.ContextlessSelect
};

export {
  make ,
  Uuid ,
}
/* make Not a pure module */
