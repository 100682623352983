

import * as Wire from "../../../../../../../types/Wire.mjs";
import * as RCore from "../../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../../types/Types.mjs";
import * as Units from "../../../../../../../types/Units.mjs";
import * as React from "react";
import * as AppContext from "../../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Shared_Lib_Timer from "../../../../../../../shared/lib/Shared_Lib_Timer.mjs";
import * as Schedule_Types_Job from "../../../../../lib/types/job/Schedule_Types_Job.mjs";

function use(elementRef, job) {
  var wire = AppContext.useWire();
  var subscriptions = wire.subscriptions;
  var match = React.useState(function () {
        return function (prim) {
          
        };
      });
  var setHoverDelayHandler = match[1];
  var resetHoverDelayHandler = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setHovered = match$1[1];
  var hovered = match$1[0];
  var onMouseEnter = function (_event) {
    resetHoverDelayHandler();
    setHoverDelayHandler(function (param) {
          return Shared_Lib_Timer.delay(300, (function () {
                        setHovered(function (param) {
                              return true;
                            });
                      }));
        });
  };
  var onMouseLeave = function (_event) {
    resetHoverDelayHandler();
    setHovered(function (param) {
          return false;
        });
  };
  var match$2 = React.useState(function () {
        return function (prim) {
          
        };
      });
  var setClickDelayHandler = match$2[1];
  var resetClickDelayHandler = match$2[0];
  var match$3 = React.useState(function () {
        
      });
  var setClickCoordinate = match$3[1];
  var clickCoordinate = match$3[0];
  var resetClickCoordinate = function () {
    setClickCoordinate(function (param) {
          
        });
  };
  var onClick = function ($$event) {
    resetClickDelayHandler();
    if (RCore.$$Option.isSome(clickCoordinate)) {
      return setClickCoordinate(function (param) {
                  
                });
    }
    var coordinate_0 = Units.Px.fromInt($$event.pageX);
    var coordinate_1 = Units.Px.fromInt($$event.pageY);
    var coordinate = [
      coordinate_0,
      coordinate_1
    ];
    setClickDelayHandler(function (param) {
          return Shared_Lib_Timer.delay(300, (function () {
                        setClickCoordinate(function (param) {
                              return coordinate;
                            });
                        resetClickDelayHandler();
                      }));
        });
  };
  var onDoubleClick = function ($$event) {
    $$event.stopPropagation();
    resetClickDelayHandler();
    Wire.openJob(undefined, undefined, wire, Schedule_Types_Job.ScheduledJob.uuid(job));
  };
  React.useEffect((function () {
          var targetOpt = RCore.$$Option.map(Types.ReactRef.toOption(elementRef), (function (prim) {
                  return prim;
                }));
          var unsubscribeFromHoverListeners;
          if (targetOpt !== undefined) {
            var target = Caml_option.valFromOption(targetOpt);
            target.addEventListener("mouseenter", onMouseEnter);
            target.addEventListener("mouseout", onMouseLeave);
            unsubscribeFromHoverListeners = (function () {
                target.removeEventListener("mouseenter", onMouseEnter);
                target.removeEventListener("mouseout", onMouseLeave);
              });
          } else {
            unsubscribeFromHoverListeners = (function (prim) {
                
              });
          }
          var unsubscribeFromClickListeners;
          if (targetOpt !== undefined) {
            var target$1 = Caml_option.valFromOption(targetOpt);
            if (RCore.$$Option.isSome(clickCoordinate)) {
              target$1.addEventListener("click", onClick);
              unsubscribeFromClickListeners = (function () {
                  target$1.removeEventListener("click", onClick);
                });
            } else {
              target$1.addEventListener("click", onClick);
              target$1.addEventListener("dblclick", onDoubleClick);
              unsubscribeFromClickListeners = (function () {
                  target$1.removeEventListener("click", onClick);
                  target$1.removeEventListener("dblclick", onDoubleClick);
                });
            }
          } else {
            unsubscribeFromClickListeners = (function (prim) {
                
              });
          }
          var unsubscribeFromScrollStart = subscriptions.scroll.subscribe(function (param) {
                setClickCoordinate(function (param) {
                      
                    });
              });
          return (function () {
                    unsubscribeFromHoverListeners();
                    unsubscribeFromClickListeners();
                    unsubscribeFromScrollStart();
                  });
        }), [
        resetHoverDelayHandler,
        hovered,
        resetClickDelayHandler,
        clickCoordinate
      ]);
  return [
          hovered,
          [
            clickCoordinate,
            resetClickCoordinate
          ]
        ];
}

export {
  use ,
}
/* Wire Not a pure module */
