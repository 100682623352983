

import * as El from "../../../libraries/El.mjs";
import * as Link from "../../../components/common/Link/Link.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../../../components/common/Optional.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import Infinite_scroll from "planado/components/infinite_scroll";
import * as Pages_Clients_TableModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/clients/index/Pages_Clients_Table.module.css";

var clientName$p = Utils.Translations.tr("js.clients.index.table.name");

var clientAddress$p = Utils.Translations.tr("js.clients.index.table.address");

var clientPhone$p = Utils.Translations.tr("js.clients.index.table.phone");

var clientApartment$p = Utils.Translations.t("js.clients.index.table.apartment");

var emptyHeadline$p = Utils.Translations.tr("js.clients.index.empty_block.headline");

var emptyLoad$p = Utils.Translations.tr("js.clients.index.empty_block.load_clients");

var emptyLoadPermissions$p = Utils.Translations.tr("js.clients.index.empty_block.load_clients_permission");

var showMore$p = Utils.Translations.tr("js.addresses.index.show_more");

var styles = Pages_Clients_TableModuleCss;

function Pages_Clients_Table$TableHeading(Props) {
  var ctx = Props.ctx;
  return React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", {
                      className: styles.clientsTableName
                    }, clientName$p(ctx)), React.createElement("th", {
                      className: styles.clientsTableAddress
                    }, clientAddress$p(ctx)), React.createElement("th", {
                      className: styles.clientsTablePhone
                    }, clientPhone$p(ctx))));
}

function Pages_Clients_Table$Row(Props) {
  var wire = Props.wire;
  var uuid = Props.uuid;
  var name = Props.name;
  var address = Props.address;
  var phone = Props.phone;
  var formatted = address.formatted;
  var match = address.apartment;
  var addressText = match !== undefined && match !== "" ? (
      formatted !== undefined ? formatted + ", " + clientApartment$p(wire.ctx) + address.apartment : ""
    ) : RCore.$$Option.getOr(formatted, "");
  return React.createElement("tr", undefined, React.createElement("td", undefined, React.createElement(Link.make, {
                      wire: wire,
                      route: {
                        NAME: "Client",
                        VAL: "Index"
                      },
                      modals: {
                        hd: {
                          NAME: "Client",
                          VAL: {
                            NAME: "Edit",
                            VAL: uuid
                          }
                        },
                        tl: /* [] */0
                      },
                      children: name
                    })), React.createElement("td", undefined, addressText), React.createElement("td", undefined, RCore.$$Option.getOr(phone, "")));
}

function Pages_Clients_Table(Props) {
  var state = Props.state;
  var refreshClients = Props.refreshClients;
  var wire = AppContext.useWire();
  var filter = state.filter;
  var totalClients = state.totalClients;
  var clients = state.clients;
  var fetching = state.fetching;
  var ctx = wire.ctx;
  var loadMore = function () {
    var match = filter.bounds;
    var offset = match.offset;
    var newOffset = offset === 0 ? 50 : offset + match.limit | 0;
    var newLimit = offset > 200 ? 50 : 30;
    var newBounds = {
      offset: newOffset,
      limit: newLimit
    };
    refreshClients(undefined, {
          bounds: newBounds
        });
  };
  return React.createElement("div", {
              className: styles.clientsList
            }, Caml_obj.notequal(clients, []) ? React.createElement(React.Fragment, undefined, React.createElement(Infinite_scroll, {
                        loadMore: loadMore,
                        hasMore: !fetching && clients.length !== totalClients && filter.bounds.offset <= 200,
                        children: React.createElement("table", {
                              className: styles.clientsTable
                            }, React.createElement(Pages_Clients_Table$TableHeading, {
                                  ctx: ctx
                                }), React.createElement("tbody", undefined, clients.map(function (param) {
                                      var uuid = param.uuid;
                                      return React.createElement(Pages_Clients_Table$Row, {
                                                  wire: wire,
                                                  uuid: uuid,
                                                  name: param.name,
                                                  address: param.address,
                                                  phone: param.phone,
                                                  key: Uuid.toString(uuid)
                                                });
                                    })))
                      }), React.createElement(Optional.make, {
                        show: clients.length < totalClients,
                        children: React.createElement("button", {
                              className: El.Cn.concatIf(styles.clientsListBtn, [
                                    fetching,
                                    "is-loading"
                                  ]),
                              type: "button",
                              onClick: (function (param) {
                                  loadMore();
                                })
                            }, showMore$p(ctx))
                      })) : React.createElement("div", undefined, React.createElement("div", {
                        className: styles.clientsListHeadline
                      }, emptyHeadline$p(ctx)), React.createElement("div", {
                        className: styles.clientsListDesc
                      }, state.isAdmin ? React.createElement(Link.make, {
                              wire: wire,
                              modals: {
                                hd: {
                                  NAME: "Client",
                                  VAL: {
                                    NAME: "Upload",
                                    VAL: "Clients"
                                  }
                                },
                                tl: /* [] */0
                              },
                              children: emptyLoad$p(ctx)
                            }) : React.createElement("p", undefined, emptyLoad$p(ctx), React.createElement("br", undefined), emptyLoadPermissions$p(ctx)))));
}

var make = Pages_Clients_Table;

export {
  make ,
}
/* clientName' Not a pure module */
