const INITIAL_STATE = {
  dictionaries: []
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default reducer
