

import * as React from "react";
import * as Locale from "../../../../../../../../../libraries/Locale.mjs";
import * as JsContext from "../../../../../../../../../libraries/JsContext.mjs";
import * as TimePicker from "../../../../../../../../inputs/TimePicker.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function JobForm_ReportFieldTimePicker$TimePickerJS(Props) {
  var time = Props.time;
  var disabledOpt = Props.disabled;
  var onChange = Props.onChange;
  var wire = Props.wire;
  var errors = Props.errors;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var onValueChange = function (newTime) {
    if (newTime === "") {
      return onChange(undefined);
    } else {
      return onChange(Caml_option.some(Locale.T.parse("Time", JsContext.get(wire))(newTime)));
    }
  };
  var value = time !== undefined ? Locale.T.fmtM("Time", wire)(Caml_option.valFromOption(time)) : "";
  return React.createElement(TimePicker.make, {
              time: value,
              onChange: onValueChange,
              disabled: disabled,
              popupPlacement: "top",
              nullable: true,
              errors: errors
            });
}

function JobForm_ReportFieldTimePicker(Props) {
  var time = Props.time;
  var onChange = Props.onChange;
  var disabledOpt = Props.disabled;
  var wire = Props.wire;
  var errorsOpt = Props.errors;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var errors = errorsOpt !== undefined ? errorsOpt : [];
  return React.createElement(JobForm_ReportFieldTimePicker$TimePickerJS, {
              time: time,
              disabled: disabled,
              onChange: onChange,
              wire: wire,
              errors: errors
            });
}

var make = JobForm_ReportFieldTimePicker;

export {
  make ,
}
/* react Not a pure module */
