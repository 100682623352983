

import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as React from "react";
import * as Backend from "../../../../libraries/backend/Backend.mjs";
import * as PromiseF from "../../../../libraries/PromiseF.mjs";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as Utils_File from "../../../../utils/Utils_File.mjs";
import * as FileInput_API from "../lib/FileInput_API.mjs";
import * as FileInput_Types from "../lib/FileInput_Types.mjs";

function use() {
  var wire = AppContext.useWire();
  return React.useCallback((function (jsFile) {
                return PromiseF.bind((function (__x) {
                                return PromiseF.mapError((function (error) {
                                              return {
                                                      TAG: "ProcessingFailed",
                                                      _0: FileInput_Types.ProcessingError.fromString(error)
                                                    };
                                            }), __x);
                              })(Utils_File.$$process(jsFile)), (function (param) {
                              var width = param.width;
                              var height = param.height;
                              var name = param.name;
                              var checksum = param.checksum;
                              var file_uuid = Uuid.createV6();
                              var file_size = param.size;
                              var file = {
                                uuid: file_uuid,
                                name: name,
                                url: undefined,
                                previewUrl: undefined,
                                size: file_size,
                                width: width,
                                height: height
                              };
                              return PromiseF.make(function (resolve, reject) {
                                          Backend.$$finally(FileInput_API.Presign.presign(wire, file, checksum, jsFile.type), (function (response) {
                                                  if (response.TAG !== "Ok") {
                                                    return reject({
                                                                TAG: "PresignFailed",
                                                                _0: response._0
                                                              });
                                                  }
                                                  var match = response._0;
                                                  var uploadUrl = match.uploadUrl;
                                                  var uuid = match.uuid;
                                                  var file_url = match.remoteUrl;
                                                  var file_previewUrl = match.previewUrl;
                                                  var file_size$1 = file_size;
                                                  var file = {
                                                    uuid: uuid,
                                                    name: name,
                                                    url: file_url,
                                                    previewUrl: file_previewUrl,
                                                    size: file_size$1,
                                                    width: width,
                                                    height: height
                                                  };
                                                  if (uploadUrl !== undefined) {
                                                    var __x = FileInput_API.Upload.upload(uploadUrl, jsFile);
                                                    return PromiseF.$$finally((function (response) {
                                                                  var code = response.status;
                                                                  if (code !== 200) {
                                                                    return reject({
                                                                                TAG: "UploadFailed",
                                                                                _0: "s3 responded with unexpected status " + code.toString()
                                                                              });
                                                                  } else {
                                                                    var __x = FileInput_API.SetUploaded.setUploaded(wire, uuid);
                                                                    return PromiseF.$$finally((function (response) {
                                                                                  if (response.TAG === "Ok") {
                                                                                    return resolve(file);
                                                                                  } else {
                                                                                    return reject({
                                                                                                TAG: "ServerError",
                                                                                                _0: response._0
                                                                                              });
                                                                                  }
                                                                                }), (function (error) {
                                                                                  reject({
                                                                                        TAG: "ServerError",
                                                                                        _0: {
                                                                                          TAG: "NetworkError",
                                                                                          _0: error
                                                                                        }
                                                                                      });
                                                                                }), __x);
                                                                  }
                                                                }), (function (error) {
                                                                  reject({
                                                                        TAG: "UploadFailed",
                                                                        _0: String(error)
                                                                      });
                                                                }), __x);
                                                  } else {
                                                    return resolve(file);
                                                  }
                                                }));
                                        });
                            }));
              }), []);
}

export {
  use ,
}
/* Uuid Not a pure module */
