

import * as Fun from "../../../../../../libraries/Fun.mjs";
import * as Uuid from "../../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JobForm_ResolutionInput from "../resolution_input/JobForm_ResolutionInput.mjs";
import * as JobForm_ResolutionPopup from "../resolution_popup/JobForm_ResolutionPopup.mjs";

var cancelHeader$p = Utils.Translations.tr("js.jobs.modal.cancel_header");

var cancelReason$p = Utils.Translations.tr("js.jobs.modal.cancel_reason");

var notCancel$p = Utils.Translations.tr("js.jobs.modal.buttons.not_cancel");

var cancel$p = Utils.Translations.tr("js.jobs.modal.buttons.cancel");

var close$p = Utils.Translations.tr("js.jobs.modal.buttons.close");

var save$p = Utils.Translations.tr("js.jobs.modal.buttons.save");

function JobForm_CancelPopup$ResolutionList(Props) {
  var possibleResolutions = Props.possibleResolutions;
  var onCommentChange = Props.onCommentChange;
  var onResolutionChange = Props.onResolutionChange;
  var resolutionCommentOpt = Props.resolutionComment;
  var resolution = Props.resolution;
  var ctx = Props.ctx;
  var resolutionComment = resolutionCommentOpt !== undefined ? resolutionCommentOpt : "";
  return React.createElement("div", {
              className: "pd-table-layout"
            }, React.createElement("div", {
                  className: "pd-tl-row"
                }, React.createElement("div", {
                      className: "pd-tl-cell pd-tl-cell-heading"
                    }, React.createElement("strong", undefined, cancelReason$p(ctx)))), React.createElement("div", {
                  className: "pd-tl-row resolutions-unsuccessful"
                }, React.createElement("div", {
                      className: "pd-tl-cell"
                    }, possibleResolutions.map(function (r) {
                          var isSelected = resolution !== undefined ? Uuid.equal(r.resolutionUuid, resolution.resolutionUuid) : false;
                          return React.createElement(JobForm_ResolutionInput.make, {
                                      name: r.name,
                                      isCommentRequired: r.isCommentRequired,
                                      onSelect: (function (param) {
                                          onResolutionChange(r);
                                        }),
                                      onCommentChange: onCommentChange,
                                      hasComment: r.hasComment,
                                      isSelected: isSelected,
                                      resolutionComment: resolutionComment,
                                      ctx: ctx,
                                      key: Uuid.toString(r.resolutionUuid)
                                    });
                        }))));
}

function JobForm_CancelPopup(Props) {
  var possibleResolutions = Props.possibleResolutions;
  var initResolution = Props.initResolution;
  var initResolutionComment = Props.initResolutionComment;
  var status = Props.status;
  var onClose = Props.onClose;
  var cancelJob = Props.cancelJob;
  var locked = Props.locked;
  var param = Props.wire;
  var ctx = param.ctx;
  var match = React.useState(function () {
        if (initResolution !== undefined) {
          return Caml_option.some(Caml_option.valFromOption(initResolution));
        } else if (possibleResolutions.length !== 1) {
          return ;
        } else {
          return Caml_option.some(possibleResolutions[0]);
        }
      });
  var setResolution = match[1];
  var resolution = match[0];
  var match$1 = React.useState(function () {
        return initResolutionComment;
      });
  var setComment = match$1[1];
  var resolutionComment = match$1[0];
  var onCommentChange = function (resolutionComment) {
    setComment(function (param) {
          return Fun.optString(resolutionComment);
        });
  };
  var onResolutionChange = function (resolution) {
    setResolution(function (param) {
          return resolution;
        });
  };
  var onCancel = function (r) {
    cancelJob([
          r.resolutionUuid,
          r.hasComment ? resolutionComment : undefined
        ]);
  };
  var match$2 = status === "Canceled" ? [
      close$p(ctx),
      save$p(ctx)
    ] : [
      notCancel$p(ctx),
      cancel$p(ctx)
    ];
  return React.createElement(JobForm_ResolutionPopup.make, {
              possibleResolutions: possibleResolutions,
              initResolution: initResolution,
              initResolutionComment: initResolutionComment,
              resolutionComment: resolutionComment,
              locked: locked,
              onClose: onClose,
              submitForm: onCancel,
              resolution: resolution,
              title: cancelHeader$p(ctx),
              closeText: match$2[0],
              buttonText: match$2[1],
              children: React.createElement(JobForm_CancelPopup$ResolutionList, {
                    possibleResolutions: possibleResolutions,
                    onCommentChange: onCommentChange,
                    onResolutionChange: onResolutionChange,
                    resolutionComment: RCore.$$Option.getOr(resolutionComment, ""),
                    resolution: resolution,
                    ctx: ctx
                  })
            });
}

var make = JobForm_CancelPopup;

export {
  make ,
}
/* cancelHeader' Not a pure module */
