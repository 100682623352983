import React, { Component } from 'react'
import Spinner from 'planado/components/input/spinner'
import DateTimePicker from 'planado/components/input/datetime'
import Interval from 'planado/components/input/interval'

export default class RecurringInfo extends Component {
  state = {
    canValidate: false
  }

  limits = {
    pastDaysCount: 0,
    futureDaysCount: 365
  }
  inputId = name => `recurring_job-form-input-${name}`

  get nextScheduledDateInput() {
    const {
      ctx,
      job,
      actions: { setField },
      errors
    } = this.props
    const label =
      job.jobsCount == 0
        ? ctx.t('js.recurring_jobs.labels.first_scheduled_at')
        : ctx.t('js.recurring_jobs.table.next_scheduled_at')
    return (
      <DateTimePicker
        id={this.inputId('first-scheduled-at-date')}
        label={label}
        dateTime={job.nextScheduledAt}
        onChange={v => {
          setField('nextScheduledAt', v)
        }}
        limits={this.limits}
        errors={{
          date: errors.nextScheduledAtDate,
          time: errors.nextScheduledAtTime
        }}
      />
    )
  }

  get stopRepeatingDateInput() {
    const {
      job: { stopRepeatingAt },
      actions: { setField },
      errors,
      ctx
    } = this.props

    return (
      <DateTimePicker
        id={this.inputId('stop-repeating-at-date')}
        label={ctx.t('js.recurring_jobs.labels.stop_repeating_at_date')}
        dateTime={stopRepeatingAt}
        errors={{
          date: errors.stopRepeatingAtDate,
          time: errors.stopRepeatingAtTime
        }}
        onChange={v => setField('stopRepeatingAt', v)}
        limits={this.limits}
      />
    )
  }

  render() {
    const {
      ctx,
      job,
      actions: { setField }
    } = this.props
    const intervalName = {
      periodCount: 'recurring_job[period_count]',
      period: 'recurring_job[period]'
    }

    return (
      <section className="form-section">
        <div className="form-container">
          <Interval
            id={this.inputId('interval')}
            name={intervalName}
            onPeriodChange={v => setField('period', v)}
            onPeriodCountChange={v => setField('periodCount', v)}
            periodCount={job.periodCount}
            period={job.period}
          />

          <div className="pd-form-group">
            <label
              className="pd-label"
              htmlFor={this.inputId('create-before-scheduled-at-days')}
            >
              {ctx.t('js.recurring_jobs.labels.before_scheduled_at_days')}
            </label>

            <Spinner
              classes={{ spinner_restrict: true }}
              name="recurring_job[create_before_scheduled_at_days]"
              onValueChange={v => setField('createBeforeScheduledAtDays', v)}
              id={this.inputId('create-before-scheduled-at-days')}
              dom={ctx.dom}
              min={1}
              value={job.createBeforeScheduledAtDays}
            />
          </div>

          {this.nextScheduledDateInput}

          {this.stopRepeatingDateInput}
        </div>
      </section>
    )
  }
}
