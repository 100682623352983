import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from 'planado/actions/admin/dictionaries_show'
import Form from 'planado/components/admin/dictionaries/show/form'
import Footer from 'planado/components/admin/dictionaries/show/footer'
import {
  ConfirmRemovalDialog,
  ErrorRemovalModal
} from 'planado/components/admin/dictionaries/show/dialogs'

const ConfirmRemovalDialogC = {
  mapStateToProps: (state, _ownProps) => ({
    uuid: state.dictionary.uuid,
    token: state.token
  }),
  mapDispatchToProps: (dispatch, _ownProps) => ({
    actions: bindActionCreators(actions, dispatch)
  })
}

export const ConfirmRemovalDialogLink = connect(
  ConfirmRemovalDialogC.mapStateToProps,
  ConfirmRemovalDialogC.mapDispatchToProps
)(ConfirmRemovalDialog)

const ErrorRemovalModalC = {
  mapStateToProps: (state, _ownProps) => ({
    templates: state.templates
  }),
  mapDispatchToProps: (dispatch, _ownProps) => ({
    actions: bindActionCreators(actions, dispatch)
  })
}

export const ErrorRemovalModalLink = connect(
  ErrorRemovalModalC.mapStateToProps,
  ErrorRemovalModalC.mapDispatchToProps
)(ErrorRemovalModal)

const FormC = {
  mapStateToProps: (state, _ownProps) => state,
  mapDispatchToProps: (dispatch, _ownProps) => ({
    actions: bindActionCreators(actions, dispatch)
  })
}

export const FormLink = connect(
  FormC.mapStateToProps,
  FormC.mapDispatchToProps
)(Form)

const FooterC = {
  mapStateToProps: ({ popovers }, _) => ({
    popovers
  }),
  mapDispatchToProps: (dispatch, _ownProps) => ({
    actions: bindActionCreators(actions, dispatch)
  })
}

export const FooterLink = connect(
  FooterC.mapStateToProps,
  FooterC.mapDispatchToProps
)(Footer)
