import { sendJSONRequest } from 'planado/utils/network.js'
import { jobTypesPath } from 'planado/routes.js'
import { decamelize } from 'planado/utils/index.js'

export const SET_TYPE = 'SET_TYPE'
export const REMOVE_TYPE = 'REMOVE_TYPE'
export const ADD_TYPE = 'ADD_TYPE'

export const SEND_FORM_REQUEST = 'SEND_FORM_REQUEST'
export const SEND_FORM_SUCCESS = 'SEND_FORM_SUCCESS'
export const SEND_FORM_FAIL = 'SEND_FORM_FAIL'

export const setType = (idx, value) => ({ type: SET_TYPE, idx, value })

export const removeType = idx => ({ type: REMOVE_TYPE, idx })

export const addType = () => ({ type: ADD_TYPE })

export const sendForm = (ctx, onSuccess, onFailure) =>
  async function (dispatch, getState) {
    dispatch({ type: SEND_FORM_REQUEST })

    const { types } = getState()

    const body = decamelize({
      jobTypes: types.map(({ id, name, removed }) => ({
        id,
        name,
        removed
      }))
    })

    try {
      const { types: updatedTypes } = await sendJSONRequest(jobTypesPath, ctx, {
        method: 'PATCH',
        body
      })

      dispatch({ type: SEND_FORM_SUCCESS, types: updatedTypes })

      onSuccess()
    } catch (response) {
      const { errors } = await response.json()

      dispatch({ type: SEND_FORM_FAIL, errors })

      onFailure()
    }
  }
