

import * as El from "../../../libraries/El.mjs";
import * as Chip from "../Chip/Chip.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as DomUtils from "../../../DomUtils.mjs";
import * as Optional from "../Optional.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Context_Types from "../../../context/Context_Types.mjs";
import * as Filters_Button from "../Filters/Filters_Button.mjs";
import * as MultipleAssignee from "../../../types/MultipleAssignee.mjs";
import * as Filters_ButtonGroup from "../Filters/Filters_ButtonGroup.mjs";
import * as MultipleAssignees_OptionsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/MultipleAssignees/MultipleAssignees_Options.module.css";

var noAssignees$p = Utils.Translations.tr("js.components.assignee_select.no_assignees");

var filterTerritory$p = Utils.Translations.tr("js.components.assignee_select.filter_territory");

var filterSkills$p = Utils.Translations.tr("js.components.assignee_select.filter_skills");

var filterAll$p = Utils.Translations.tr("js.components.assignee_select.filter.all");

var filterTeams$p = Utils.Translations.tr("js.components.assignee_select.filter.teams");

var filterWorkers$p = Utils.Translations.tr("js.components.assignee_select.filter.workers");

var territoryWarning$p = Utils.Translations.tr("js.components.assignee_select.territory_warning");

var skillsWarning$p = Utils.Translations.tr("js.components.assignee_select.skills_warning");

var styles = MultipleAssignees_OptionsModuleCss;

var ChipState = {
  $$default: {
    territory: false,
    skills: false
  }
};

function MultipleAssignees_Options$Team(Props) {
  var team = Props.team;
  var match = AppContext.useWire();
  var match$1 = team.foreman;
  var tmp;
  if (match$1 !== undefined) {
    var name = match$1.name;
    tmp = match$1.permission === "Edit" ? React.createElement("span", {
            className: styles.teamMember
          }, React.createElement("span", {
                className: styles.foreman
              }, name)) : React.createElement("span", {
            className: styles.teamMember
          }, name);
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: styles.team
            }, React.createElement("div", {
                  className: styles.teamMembers
                }, React.createElement("i", {
                      className: El.Cn.concat(Icon.style(undefined, undefined, "users"), styles.teamIcon)
                    }), React.createElement("div", undefined, tmp, Utils.Translations.concatEl(Belt_Array.concatMany([team.workers.map(function (w) {
                                    return React.createElement("span", {
                                                key: Uuid.toString(w.uuid),
                                                className: styles.teamMember
                                              }, w.name);
                                  })]), match.ctx))), React.createElement("div", {
                  className: styles.teamName
                }, team.name));
}

function MultipleAssignees_Options$AssigneeOption(Props) {
  var assignee = Props.assignee;
  var idx = Props.idx;
  var cursor = Props.cursor;
  var onClick = Props.onClick;
  var el = React.useRef(null);
  var e = DomUtils.extractDomElementFromRef(el);
  if (e !== undefined && Caml_obj.equal(idx, cursor)) {
    DomUtils.scrollIntoView(Caml_option.valFromOption(e), false);
  }
  var optionCN = function (idx) {
    var active = Caml_obj.equal(idx, cursor) ? styles.active : "";
    return El.Cn.concat(El.Cn.concat(styles.option, "option"), active);
  };
  var tmp;
  tmp = assignee.TAG === "Team" ? React.createElement(MultipleAssignees_Options$Team, {
          team: assignee._0
        }) : assignee._0.name;
  return React.createElement("div", {
              key: idx.toString(),
              ref: el,
              className: optionCN(idx),
              onClick: onClick(assignee)
            }, tmp);
}

function MultipleAssignees_Options(Props) {
  var possibleAssignees = Props.possibleAssignees;
  var cursor = Props.cursor;
  var activeChips = Props.activeChips;
  var updateChips = Props.updateChips;
  var activeFilters = Props.activeFilters;
  var onFilterClick = Props.onFilterClick;
  var onPick = Props.onPick;
  var openUp = Props.openUp;
  var territoryUuid = Props.territoryUuid;
  var skillUuids = Props.skillUuids;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return false;
      });
  var setShowTerritoryWarning = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowSkillsWarning = match$1[1];
  var onClick = function (possibleAssignee) {
    return function (evt) {
      evt.preventDefault();
      onPick(possibleAssignee);
    };
  };
  return React.createElement("div", {
              className: styles.wrapper
            }, React.createElement("div", {
                  className: El.Cn.concat(styles.options, openUp ? styles.openUp : "")
                }, React.createElement("div", {
                      className: styles.optionsContainer + " " + wire.tour.anchor("job-form-assignee-options")
                    }, React.createElement("div", {
                          className: styles.controls
                        }, React.createElement(Optional.make, {
                              show: Context_Types.Features.hasFlag("teams", ctx.features),
                              children: React.createElement(Filters_ButtonGroup.make, {
                                    className: styles.controlsSection,
                                    children: null
                                  }, React.createElement(Filters_Button.make, {
                                        onClick: (function (param) {
                                            onFilterClick("All");
                                          }),
                                        active: activeFilters === "All",
                                        children: filterAll$p(ctx)
                                      }), React.createElement(Filters_Button.make, {
                                        onClick: (function (param) {
                                            onFilterClick("Teams");
                                          }),
                                        active: activeFilters === "Teams",
                                        children: filterTeams$p(ctx)
                                      }), React.createElement(Filters_Button.make, {
                                        onClick: (function (param) {
                                            onFilterClick("Workers");
                                          }),
                                        active: activeFilters === "Workers",
                                        children: filterWorkers$p(ctx)
                                      }))
                            }), React.createElement("div", {
                              className: styles.controlsSection
                            }, React.createElement(Optional.make, {
                                  show: Context_Types.Features.hasFlag("territories", ctx.features),
                                  children: React.createElement(Chip.make, {
                                        name: filterTerritory$p(ctx),
                                        onClick: (function () {
                                            updateChips({
                                                  territory: !activeChips.territory,
                                                  skills: activeChips.skills
                                                });
                                          }),
                                        active: activeChips.territory,
                                        disabled: territoryUuid === undefined,
                                        onHover: (function (param) {
                                            setShowTerritoryWarning(function (param) {
                                                  return territoryUuid === undefined;
                                                });
                                          }),
                                        onLeave: (function (param) {
                                            setShowTerritoryWarning(function (param) {
                                                  return false;
                                                });
                                          })
                                      })
                                }), React.createElement(Chip.make, {
                                  name: filterSkills$p(ctx),
                                  onClick: (function () {
                                      updateChips({
                                            territory: activeChips.territory,
                                            skills: !activeChips.skills
                                          });
                                    }),
                                  active: activeChips.skills,
                                  disabled: skillUuids.length === 0,
                                  onHover: (function (param) {
                                      setShowSkillsWarning(function (param) {
                                            return skillUuids.length === 0;
                                          });
                                    }),
                                  onLeave: (function (param) {
                                      setShowSkillsWarning(function (param) {
                                            return false;
                                          });
                                    })
                                }), React.createElement(Optional.make, {
                                  show: match[0] && Context_Types.Features.hasFlag("territories", ctx.features),
                                  children: React.createElement("span", {
                                        className: styles.chipWarning
                                      }, territoryWarning$p(ctx))
                                }), React.createElement(Optional.make, {
                                  show: match$1[0],
                                  children: React.createElement("span", {
                                        className: styles.chipWarning
                                      }, skillsWarning$p(ctx))
                                }))), possibleAssignees.length === 0 ? React.createElement("div", {
                            className: styles.noAssignees
                          }, noAssignees$p(ctx)) : possibleAssignees.map(function (assignee, idx) {
                            return React.createElement(MultipleAssignees_Options$AssigneeOption, {
                                        assignee: assignee,
                                        idx: idx,
                                        cursor: cursor,
                                        onClick: onClick,
                                        key: Uuid.toString(MultipleAssignee.PossibleAssignee.uuid(assignee))
                                      });
                          }))));
}

var make = MultipleAssignees_Options;

export {
  ChipState ,
  make ,
}
/* noAssignees' Not a pure module */
