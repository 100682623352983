

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var setHeaderHeight = (function(height) {document.documentElement.style.setProperty('--header-height', height + 'px');});

var _lockWindow = (function(_) { document.body.classList.add("p-locked") });

var _unlockWindow = (function(_) { document.body.classList.remove("p-locked") });

var windowScrollY = (function(_) { return window.scrollY });

function contains(parent, child) {
  return parent.contains(child);
}

var lockCounter = {
  contents: 0
};

function lockWindow() {
  lockCounter.contents = lockCounter.contents + 1 | 0;
  _lockWindow();
}

function unlockWindow() {
  lockCounter.contents = lockCounter.contents - 1 | 0;
  if (lockCounter.contents === 0) {
    return _unlockWindow();
  }
  
}

function toggleWindowLock(tf) {
  if (tf) {
    return lockWindow();
  } else {
    return unlockWindow();
  }
}

var _setScrollLeft = (function(el) { el.scrollLeft = (el.scrollWidth - el.clientWidth) });

var scrollbarWidth = (function(_) {
    const div = window.document.createElement('div')

    div.style.overflowY = 'scroll'
    div.style.width = '50px'
    div.style.height = '50px'
    div.style.visibility = 'hidden'

    window.document.querySelector('body').appendChild(div)
    const scrollWidth = div.offsetWidth - div.clientWidth
    window.document.querySelector('body').removeChild(div)

    return scrollWidth
  });

var windowInnerHeight = (function(_) { return window.innerHeight });

var windowInnerWidth = (function(_) { return window.innerWidth });

function get(element) {
  var box = element.getBoundingClientRect();
  return {
          top: box.top,
          right: box.right,
          bottom: box.bottom,
          left: box.left,
          height: box.height,
          width: box.width,
          x: box.x,
          y: box.y
        };
}

var BoundingClientRect = {
  get: get
};

function DomUtils$Spread(Props) {
  return React.cloneElement(Props.children, Props.props);
}

var Spread = {
  make: DomUtils$Spread
};

function extractDomElementFromRef(reactRef) {
  return Caml_option.nullable_to_opt(reactRef.current);
}

function onClick(useCaptureOpt, node, cb) {
  var useCapture = useCaptureOpt !== undefined ? useCaptureOpt : false;
  node.addEventListener("click", cb, useCapture);
}

function onMouseOver(useCaptureOpt, node, cb) {
  var useCapture = useCaptureOpt !== undefined ? useCaptureOpt : false;
  node.addEventListener("mouseover", cb, useCapture);
}

function onMouseLeave(useCaptureOpt, node, cb) {
  var useCapture = useCaptureOpt !== undefined ? useCaptureOpt : false;
  node.addEventListener("mouseleave", cb, useCapture);
}

function removeOnClick(useCaptureOpt, node, cb) {
  var useCapture = useCaptureOpt !== undefined ? useCaptureOpt : false;
  node.removeEventListener("click", cb, useCapture);
}

function removeOnMouseOver(useCaptureOpt, node, cb) {
  var useCapture = useCaptureOpt !== undefined ? useCaptureOpt : false;
  node.removeEventListener("mouseover", cb, useCapture);
}

function removeOnMouseLeave(useCaptureOpt, node, cb) {
  var useCapture = useCaptureOpt !== undefined ? useCaptureOpt : false;
  node.removeEventListener("mouseleave", cb, useCapture);
}

var Listeners = {
  onClick: onClick,
  onMouseOver: onMouseOver,
  onMouseLeave: onMouseLeave,
  removeOnClick: removeOnClick,
  removeOnMouseOver: removeOnMouseOver,
  removeOnMouseLeave: removeOnMouseLeave
};

var setScrollLeft = _setScrollLeft;

function Input_setSelectionRange(prim0, prim1, prim2) {
  prim0.setSelectionRange(prim1, prim2);
}

function Input_selectionStart(prim) {
  return prim.selectionStart;
}

var Input = {
  setSelectionRange: Input_setSelectionRange,
  selectionStart: Input_selectionStart
};

function focus(prim0, prim1) {
  prim0.focus();
}

function blur(prim0, prim1) {
  prim0.blur();
}

function scrollIntoView(prim0, prim1) {
  prim0.scrollIntoView(prim1);
}

export {
  setHeaderHeight ,
  windowScrollY ,
  contains ,
  lockCounter ,
  lockWindow ,
  unlockWindow ,
  toggleWindowLock ,
  setScrollLeft ,
  scrollbarWidth ,
  windowInnerHeight ,
  windowInnerWidth ,
  BoundingClientRect ,
  Spread ,
  extractDomElementFromRef ,
  Input ,
  Listeners ,
  focus ,
  blur ,
  scrollIntoView ,
}
/* react Not a pure module */
