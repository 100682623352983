import React from 'react'
import { searchOrganizationPath, searchAddressPath } from 'planado/routes.js'
import { fetchJSONC } from 'planado/utils/network.js'
import { debouncePromise } from 'planado/utils/index.js'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import { Async } from 'react-select'

const itemName = item => {
  if (item.type === 'site') {
    if (item.clientName === null) {
      return item.name
    } else {
      return [item.name, item.clientName].join(' — ')
    }
  } else {
    return item.name
  }
}

const searchUrl = (type, input) => {
  switch (type) {
    case 'site_and_client':
      return searchAddressPath(input)
    case 'organization':
      return searchOrganizationPath(input)
    default:
      throw new Error(`Invalid searchUrl type: ${type}`)
  }
}

const ClientOption = ({ option, className, isFocused, onFocus, onSelect }) => {
  const { label, address } = option

  const onClick = evt => {
    evt.preventDefault()
    evt.stopPropagation()

    onSelect(option, evt)
  }

  const onMouseEnter = evt => {
    onFocus(option, evt)
  }

  const onMouseMove = evt => {
    if (isFocused) return

    onFocus(option, evt)
  }

  const addressBlock = address ? (
    <div className="client-address">{address}</div>
  ) : null

  return (
    <div
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseMove={onMouseMove}
    >
      {label}

      {addressBlock}
    </div>
  )
}

const filterOptions = (options, _filterValue, _excludeOptions, _props) =>
  options

const ClientSelect = withContext(
  ({
    id,
    name,
    value,
    type = 'site_and_client',
    onValueChange,
    placeholder,
    readOnly = false,
    ctx
  }) => {
    const text = value ? value.label : null

    if (readOnly) {
      return (
        <input
          type="text"
          className="pd-form-control"
          readOnly
          placeholder={placeholder || ctx.t('js.components.select.no_client')}
          value={text || ''}
        />
      )
    } else {
      const loadOptions = input => {
        if (input.trim().length === 0) {
          return Promise.resolve({ options: [] })
        } else {
          const url = searchUrl(type, input)
          return fetchJSONC(url, ctx).then(json => ({
            options: json.map(item => ({
              label: itemName(item),
              value: item.type === 'site' ? item.siteUuid : item.clientUuid,
              type: item.type,
              address: item.address,
              data: { ...item.value, type: item.type }
            }))
          }))
        }
      }

      return (
        <span>
          <input name={name} type="hidden" value={text || ''} />

          <Async
            autoload={false}
            autosize={false}
            cache={false}
            value={value}
            placeholder={placeholder || ctx.t('js.components.select.no_client')}
            loadingPlaceholder={ctx.t(
              'js.components.select.loading_placeholder'
            )}
            noResultsText={ctx.t('js.components.select.no_results')}
            searchPromptText={ctx.t('js.components.select.search_prompt')}
            id={id}
            name={name}
            filterOptions={filterOptions}
            onChange={value => onValueChange(value, ctx)}
            loadOptions={debouncePromise(loadOptions, 300)}
            optionComponent={ClientOption}
          />
        </span>
      )
    }
  }
)

export default ClientSelect
