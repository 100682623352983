

import * as El from "../../../libraries/El.mjs";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NoteModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/Note/Note.module.css";

var styles = NoteModuleCss;

function toClassName(f) {
  if (f === "warning") {
    return styles.noteWarning;
  } else if (f === "danger") {
    return styles.noteDanger;
  } else if (f === "success") {
    return styles.noteSuccess;
  } else {
    return styles.noteInfo;
  }
}

function Note(Props) {
  var title = Props.title;
  var className = Props.className;
  var children = Props.children;
  var flavor = Props.flavor;
  return React.createElement("div", {
              className: El.Cn.concatOpt(El.Cn.concat(styles.note, toClassName(flavor)), className)
            }, title !== undefined ? React.createElement("h4", undefined, Caml_option.valFromOption(title)) : null, children);
}

var Flavor = {};

var make = Note;

export {
  Flavor ,
  make ,
}
/* styles Not a pure module */
