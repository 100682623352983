

import * as Wire from "../../../../../../types/Wire.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as MomentRe from "../../../../../../bindings/moment/MomentRe.mjs";
import * as WeekTimeline_Constants from "../WeekTimeline_Constants.mjs";
import * as Schedule_Types_Timeline from "../../../../lib/types/Schedule_Types_Timeline.mjs";

function assigneeCoordinates(idx) {
  var y1 = Units.Px.plus(Units.Px.multiplyWithInt(WeekTimeline_Constants.rowHeight, idx), Units.Px.multiplyWithInt(WeekTimeline_Constants.barGapHeight, idx));
  var barMarginHeightHalf = Units.Px.divideByInt(WeekTimeline_Constants.barGapHeight, 2);
  return [
          Units.Px.minus(y1, barMarginHeightHalf),
          Units.Px.plus(Units.Px.plus(y1, WeekTimeline_Constants.rowHeight), barMarginHeightHalf)
        ];
}

function assignee(wire, filter, param) {
  var y = param[1];
  var assignees = Schedule_Types_Timeline.Bars.Assignees.filter(wire, filter);
  return assignees.filter(function (param, idx) {
                var match = assigneeCoordinates(idx);
                if (Caml_obj.lessequal(match[0], y)) {
                  return Caml_obj.lessequal(y, match[1]);
                } else {
                  return false;
                }
              })[0];
}

function scheduledAt(wire, period, sizes, param) {
  var x = param[0];
  var match = Wire.businessHours(wire);
  if (match !== undefined) {
    var start = match.start;
    var days = Units.Px.toInt(Units.Px.divide(x, sizes.day));
    var minutesX = Units.Px.fromFloat(Units.Px.toFloat(x) % Units.Px.toFloat(sizes.day));
    var dayMinutes;
    if (Caml_obj.lessequal(minutesX, Units.Px.fromInt(5))) {
      dayMinutes = MomentRe.Duration.asMinutes(start) | 0;
    } else if (Units.Px.equalOrMoreThan(minutesX, Units.Px.minus(sizes.day, Units.Px.fromInt(5)))) {
      dayMinutes = MomentRe.Duration.asMinutes(match.finish) | 0;
    } else {
      var offsetInMinutes = Units.Px.toInt(Units.Px.multiplyWithInt(Units.Px.round(Units.Px.divideByInt(Units.Px.divide(Units.Px.minus(minutesX, Units.Px.fromInt(5)), sizes.minute), 30)), 30));
      dayMinutes = offsetInMinutes + (MomentRe.Duration.asMinutes(start) | 0) | 0;
    }
    var minutes = Math.imul(days, WeekTimeline_Constants.minutesInDay) + dayMinutes | 0;
    return Locale.T.add(Locale.T.duration(minutes, "minutes"), period[0]);
  }
  var offsetInMinutes$1 = Units.Px.toInt(Units.Px.multiplyWithInt(Units.Px.round(Units.Px.divideByInt(Units.Px.divide(x, sizes.minute), 30)), 30));
  var startOfDay = period[0];
  return Locale.T.nextMinutes(offsetInMinutes$1, startOfDay);
}

var Sizes;

var FindByCoordinateInTimeline = {
  assignee: assignee,
  scheduledAt: scheduledAt
};

export {
  Sizes ,
  FindByCoordinateInTimeline ,
}
/* Wire Not a pure module */
