import React from 'react'

import { watch } from 'planado/events'
import LocationBlockLink from 'planado/containers/map/location_block_link'
import WorkerJobListLink from 'planado/containers/map/worker_job_list_link'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import * as styles from './styles.module.css'

const getOffset = (item, parent) => {
  let offset = 0,
    current = item

  while (current != parent) {
    offset += current.offsetTop

    current = current.offsetParent
  }

  return offset
}

const List = withContext(({ blocks, ctx }) => {
  if (blocks.length > 0) {
    return (
      <ul className={styles.blockList}>
        {blocks.map(b => (
          <LocationBlockLink key={b.id} block={b} />
        ))}
      </ul>
    )
  } else {
    return (
      <div className={styles.noLocationsForDate}>
        {ctx.t('js.map.no_locations_for_date')}
      </div>
    )
  }
})

class LocationsView extends React.Component {
  constructor(props) {
    super(props)

    this.handlers = {
      nextLocation: this.scrollToLocation,
      prevLocation: this.scrollToLocation
    }
  }

  render() {
    const { pending, locationBlocks, ctx } = this.props

    if (pending) {
      return (
        <div className={styles.locationsView} ref={v => (this.view = v)}>
          <div className={styles.loader}>
            <i className="fa fa-spinner fa-spin" />
          </div>
        </div>
      )
    } else {
      return (
        <div className={styles.locationsView} ref={v => (this.view = v)}>
          <h4>{ctx.t('js.map.locations.title')}</h4>
          <List blocks={locationBlocks} />
          <WorkerJobListLink />
        </div>
      )
    }
  }

  componentDidUpdate(prevProps) {
    watch(this.props.events, this.handlers)

    if (
      !this.props.pending &&
      prevProps.pending &&
      !this.props.viewingHistory
    ) {
      this.view.scrollTop = 10000
    }
  }

  scrollToLocation = () => {
    if (this.props.pending) {
      return
    }

    const item = this.view.querySelector('.location-point.active') || null

    if (item === null) {
      return
    }

    const offset = getOffset(item, this.view.offsetParent) - this.view.offsetTop
    const upperBound = 0
    const lowerBound = this.view.offsetHeight - this.view.offsetTop
    const scroll = this.view.scrollTop
    let nextScroll

    if (offset > this.view.scrollHeight - lowerBound + 100) {
      nextScroll = 10000
    } else if (offset < lowerBound - 50) {
      nextScroll = 0
    } else if (offset > scroll + lowerBound - 100) {
      nextScroll = offset - lowerBound + 100
    } else if (offset < scroll + 50) {
      nextScroll = offset - upperBound - 50
    }

    if (nextScroll !== undefined) {
      this.view.scrollTop = nextScroll
    }
  }
}

export default withContext(LocationsView)
