

import * as El from "../../libraries/El.mjs";
import * as Fun from "../../libraries/Fun.mjs";
import * as React from "react";
import * as Runtime from "../../Runtime.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as MessengerItemModuleCss from "/home/runner/work/planado/planado/client/rescript/components/messenger/MessengerItem.module.css";

var styles = MessengerItemModuleCss;

function MessengerItem(Props) {
  var message = Props.message;
  var onClose = Props.onClose;
  var hoveredOpt = Props.hovered;
  var onHover = Props.onHover;
  var onLeave = Props.onLeave;
  var hovered = hoveredOpt !== undefined ? hoveredOpt : false;
  var match = React.useState(function () {
        return Caml_option.some(Runtime.Timeout.defer(5000, onClose));
      });
  var setTimeoutId = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setWasHovered = match$1[1];
  var hideIfNotHovered = function () {
    if (!hovered) {
      return onClose();
    }
    
  };
  React.useEffect((function () {
          if (hovered) {
            setWasHovered(function (param) {
                  return true;
                });
          }
          setTimeoutId(function (timeoutId) {
                if (timeoutId !== undefined) {
                  if (hovered) {
                    Runtime.Timeout.cancel(Caml_option.valFromOption(timeoutId));
                    return ;
                  } else {
                    return timeoutId;
                  }
                } else if (hovered) {
                  return timeoutId;
                } else {
                  return Caml_option.some(Runtime.Timeout.defer(2000, hideIfNotHovered));
                }
              });
        }), [hovered]);
  var animationStyles = hovered ? undefined : (
      match$1[0] ? styles.itemCloseAfterHover : styles.itemAnimated
    );
  var match$2 = message.messageType;
  var tmp;
  switch (match$2) {
    case "Success" :
        tmp = styles.success;
        break;
    case "Danger" :
        tmp = styles.danger;
        break;
    case "Warning" :
        tmp = styles.warning;
        break;
    
  }
  var className = El.Cn.concatOpt(El.Cn.concat(styles.item, tmp), animationStyles);
  var string = message.messageBody;
  var tmp$1;
  tmp$1 = string._0;
  return React.createElement("div", {
              className: className,
              onMouseEnter: onHover,
              onMouseLeave: onLeave
            }, React.createElement("button", {
                  className: styles.close,
                  type: "button",
                  onClick: Fun.omit(onClose)
                }), tmp$1);
}

var make = MessengerItem;

export {
  make ,
}
/* styles Not a pure module */
