

import * as Units from "../../../../../../types/Units.mjs";
import * as React from "react";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_DND from "../../../../lib/Schedule_DND.mjs";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as Shared_Lib_Timer from "../../../../../../shared/lib/Shared_Lib_Timer.mjs";
import * as WeekTimeline_State from "../../lib/state/WeekTimeline_State.mjs";
import * as WeekTimeline_Utils from "../../lib/utils/WeekTimeline_Utils.mjs";
import * as DayTimeline_UtilsSizes from "../../../day_timeline/lib/utils/DayTimeline_UtilsSizes.mjs";
import * as Schedule_UtilsTimeline from "../../../../lib/utils/Schedule_UtilsTimeline.mjs";
import * as WeekTimeline_Constants from "../../lib/WeekTimeline_Constants.mjs";
import * as WeekTimeline_UnassignedJobPositions from "../../lib/positions/WeekTimeline_UnassignedJobPositions.mjs";

function use() {
  var period = Schedule_State.usePeriod();
  var jobs = Schedule_State.useUnassignedJobs();
  var sizes = WeekTimeline_State.useSizes();
  return React.useMemo((function () {
                return WeekTimeline_UnassignedJobPositions.make(period, sizes, jobs);
              }), [
              period,
              sizes,
              jobs
            ]);
}

var Jobs = {
  use: use
};

var compactHeight = Units.Px.toString(Units.Px.fromInt(37));

var contentHeight = Units.Px.toString(Units.Px.fromInt(190));

var marginWidth = Units.Px.toString(Units.Px.fromInt(16));

function use$1(compactMode) {
  var showUnscheduledJobs = Schedule_State.useShowUnscheduledJobs();
  var unscheduledJobsWidth = Units.Px.toString(DayTimeline_UtilsSizes.unscheduledJobsWidth(showUnscheduledJobs));
  return {
          height: compactMode ? compactHeight : contentHeight,
          width: "calc(100% - " + unscheduledJobsWidth + " + " + marginWidth + ")"
        };
}

function use$2(viewportEl, compactMode, toggleMode) {
  var wire = AppContext.useWire();
  var dispatch = Schedule_State.useDispatch();
  var period = Schedule_State.usePeriod();
  var sizes = WeekTimeline_State.useSizes();
  var match = React.useState(function () {
        return false;
      });
  var setIsOver = match[1];
  var isOver = match[0];
  var match$1 = React.useState(function () {
        return function (prim) {
          
        };
      });
  var setHoverDelayHandler = match$1[1];
  var hoverDelayHandler = match$1[0];
  React.useEffect((function () {
          if (isOver && compactMode) {
            setHoverDelayHandler(function (param) {
                  return Shared_Lib_Timer.delay(2000, toggleMode);
                });
          } else {
            hoverDelayHandler();
          }
        }), [
        compactMode,
        isOver
      ]);
  var onDrop = function (param) {
    var contentEl = Schedule_UtilsTimeline.contentEl(viewportEl);
    if (contentEl !== undefined) {
      var match = Schedule_DND.DND.Context.payload(param.payload);
      var uuid = match.uuid;
      if (param.payloadType === WeekTimeline_Constants.dndScheduledJob) {
        dispatch({
              TAG: "Jobs",
              _0: {
                TAG: "JobUnassigned",
                _0: uuid
              }
            });
      } else {
        var coordinate = Schedule_UtilsTimeline.ConvertPoint.fromPageToNode(Caml_option.valFromOption(contentEl), param.dragPreviewOffset);
        var scheduledAt = WeekTimeline_Utils.FindByCoordinateInTimeline.scheduledAt(wire, period, sizes, coordinate);
        dispatch({
              TAG: "Jobs",
              _0: {
                TAG: "JobScheduled",
                _0: uuid,
                _1: scheduledAt
              }
            });
      }
    }
    setIsOver(function (param) {
          return false;
        });
  };
  var dropRef = Schedule_DND.DND.Droppable.use(undefined, (function () {
          setIsOver(function (param) {
                return true;
              });
        }), (function () {
          setIsOver(function (param) {
                return false;
              });
        }), [WeekTimeline_Constants.dndScheduledJob], onDrop, undefined);
  return [
          dropRef,
          isOver
        ];
}

var Drop = {
  use: use$2
};

var Style = {
  use: use$1
};

export {
  Jobs ,
  Style ,
  Drop ,
}
/* compactHeight Not a pure module */
