

import * as El from "../../../libraries/El.mjs";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as HintModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/Hint/Hint.module.css";

var styles = HintModuleCss;

function Hint(Props) {
  var content = Props.content;
  var classesOpt = Props.classes;
  var positionOpt = Props.position;
  var thinOpt = Props.thin;
  var children = Props.children;
  var classes = classesOpt !== undefined ? classesOpt : "";
  var position = positionOpt !== undefined ? positionOpt : "Top";
  var thin = thinOpt !== undefined ? thinOpt : false;
  var match = React.useState(function () {
        return false;
      });
  var setVisible = match[1];
  var positionClass = position === "Top" ? styles.top : (
      position === "Left" ? styles.left : styles.right
    );
  var className = El.Cn.concat(El.Cn.concat(positionClass, styles.hint), classes);
  var onMouseEnter = function ($$event) {
    $$event.stopPropagation();
    setVisible(function (param) {
          return true;
        });
  };
  var onMouseLeave = function ($$event) {
    $$event.stopPropagation();
    setVisible(function (param) {
          return false;
        });
  };
  return React.createElement("div", {
              className: className,
              onMouseEnter: onMouseEnter,
              onMouseLeave: onMouseLeave
            }, children !== undefined ? Caml_option.valFromOption(children) : React.createElement("div", {
                    className: styles.hintIcon
                  }), match[0] ? React.createElement("div", {
                    className: thin ? styles.thinnerTooltip : styles.tooltip
                  }, React.createElement("div", {
                        className: styles.tooltipArrow
                      }), content) : null);
}

var make = Hint;

var $$default = Hint;

export {
  make ,
  $$default as default,
}
/* styles Not a pure module */
