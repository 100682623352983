

import ReactImages from "react-images";

var FrameProps = {};

var ModalProps = {};

var TrackProps = {};

function make(rotationDegOpt) {
  var rotationDeg = rotationDegOpt !== undefined ? rotationDegOpt : 0;
  return {
          container: (function () {
              return {
                      margin: "auto"
                    };
            }),
          view: (function () {
              return {
                      display: "flex !important",
                      margin: "auto",
                      maxHeight: "78vh",
                      maxWidth: "80vw",
                      alignContent: "center",
                      transform: "rotate(" + rotationDeg.toString() + "deg)"
                    };
            })
        };
}

var Styles = {
  make: make
};

function make$1(caption, source) {
  return {
          caption: caption,
          source: source
        };
}

var View = {
  make: make$1
};

var InnerProps = {};

var CustomComponents = {};

var make$2 = ReactImages;

export {
  FrameProps ,
  ModalProps ,
  TrackProps ,
  Styles ,
  View ,
  InnerProps ,
  CustomComponents ,
  make$2 as make,
}
/* make Not a pure module */
