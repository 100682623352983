

import * as SelectJsx from "planado/components/input/select.jsx";

var make = SelectJsx.ContextlessSelect;

export {
  make ,
}
/* make Not a pure module */
