

import * as Chip from "../../common/Chip/Chip.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as ServiceItem from "../../common/Catalog/ServiceItem.mjs";
import * as Template_ServicesModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/template_catalog/Template_Services.module.css";

var preOrder$p = Utils.Translations.tr("js.templates.services.pre_ordered");

var required$p = Utils.Translations.tr("js.templates.services.required");

var styles = Template_ServicesModuleCss;

function Template_Service$Buttons(Props) {
  var ctx = Props.ctx;
  var ordered = Props.ordered;
  var onOrder = Props.onOrder;
  var required = Props.required;
  var onRequired = Props.onRequired;
  return React.createElement("div", {
              className: styles.chips
            }, React.createElement(Chip.make, {
                  name: preOrder$p(ctx),
                  onClick: onOrder,
                  active: ordered
                }), React.createElement(Chip.make, {
                  name: required$p(ctx),
                  onClick: onRequired,
                  active: required,
                  disabled: !ordered
                }));
}

function Template_Service(Props) {
  var ctx = Props.ctx;
  var service = Props.service;
  var onRemove = Props.onRemove;
  var onUpdate = Props.onUpdate;
  var onOrder = function () {
    onUpdate({
          uuid: service.uuid,
          name: service.name,
          description: service.description,
          priceDefault: service.priceDefault,
          currency: service.currency,
          category: service.category,
          preOrdered: !service.preOrdered,
          required: false,
          archived: service.archived
        });
  };
  var onRequired = function () {
    if (service.preOrdered) {
      return onUpdate({
                  uuid: service.uuid,
                  name: service.name,
                  description: service.description,
                  priceDefault: service.priceDefault,
                  currency: service.currency,
                  category: service.category,
                  preOrdered: service.preOrdered,
                  required: !service.required,
                  archived: service.archived
                });
    }
    
  };
  return React.createElement(ServiceItem.make, {
              ctx: ctx,
              name: service.name,
              description: service.description,
              price: service.priceDefault,
              currency: service.currency,
              archived: service.archived,
              onRemove: onRemove,
              tags: React.createElement(Template_Service$Buttons, {
                    ctx: ctx,
                    ordered: service.preOrdered,
                    onOrder: onOrder,
                    required: service.required,
                    onRequired: onRequired
                  }),
              key: Uuid.toString(service.uuid)
            });
}

var make = Template_Service;

export {
  make ,
}
/* preOrder' Not a pure module */
