

import * as Fun from "../../../libraries/Fun.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as React from "react";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as YandexMaps from "../../../bindings/yandexMaps/YandexMaps.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as MapReContext from "./MapReContext.mjs";
import * as Types_Spatial from "../../../types/Types_Spatial.mjs";
import * as MapReComponent from "./MapReComponent.mjs";
import * as MapRePolylineComponent from "./MapRePolylineComponent.mjs";

function MapReArrowComponent(Props) {
  var coordinates = Props.coordinates;
  var strokeColor = Props.strokeColor;
  var strokeStyle = Props.strokeStyle;
  var ymapsApi = YandexMaps.getApi();
  var match = React.useState(function () {
        
      });
  var setZoom = match[1];
  var zoom = match[0];
  var map = MapReContext.YandexMapContext.useMap();
  var arrowRef = React.useRef(undefined);
  var backgroundArrowRef = React.useRef(undefined);
  var updateArrow = function (arrow) {
    arrowRef.current = Caml_option.some(arrow);
  };
  var updateBackgroundArrow = function (arrow) {
    backgroundArrowRef.current = Caml_option.some(arrow);
  };
  var createArrow = function (ymapsApi, _coordinates) {
    while(true) {
      var coordinates = _coordinates;
      if (map === undefined) {
        return ;
      }
      var lengthLimit = RCore.$$Option.getOr(RCore.$$Option.map(zoom, (function (zoom) {
                  return Math.pow(2.0, MapReComponent.maxZoom - zoom | 0) * 0.00125;
                })), 0.00125);
      var match = Core__List.fromArray(coordinates);
      if (!match) {
        return ;
      }
      var match$1 = match.tl;
      if (!match$1) {
        return ;
      }
      var snd = match$1.hd;
      var fst = match.hd;
      if (Types_Spatial.$$Geolocation.vectorLength(fst, snd) < lengthLimit) {
        _coordinates = coordinates.slice(1);
        continue ;
      }
      var arrowheadCoords = Types_Spatial.$$Geolocation.add(fst, Types_Spatial.$$Geolocation.scaleToLength(fst, snd, lengthLimit));
      var options_strokeWidth = 4.0;
      var options_strokeOpacity = 0.8;
      var options_strokeStyle = RCore.$$Option.map(strokeStyle, MapRePolylineComponent.StrokeStyle.toString);
      var options = {
        strokeColor: strokeColor,
        strokeWidth: options_strokeWidth,
        strokeOpacity: options_strokeOpacity,
        strokeStyle: options_strokeStyle
      };
      var addArrow = (function(fst,arrowheadCoords){
      return function addArrow(callback, options) {
        YandexMaps.addArrow(ymapsApi, map, callback, [
              [
                Types_Spatial.Latitude.toNumber(fst.latitude),
                Types_Spatial.Longitude.toNumber(fst.longitude)
              ],
              [
                Types_Spatial.Latitude.toNumber(arrowheadCoords.latitude),
                Types_Spatial.Longitude.toNumber(arrowheadCoords.longitude)
              ]
            ], undefined, options);
      }
      }(fst,arrowheadCoords));
      var backgroundOptions_strokeColor = "FFF";
      var backgroundOptions_strokeWidth = 7.0;
      var backgroundOptions_strokeOpacity = 0.8;
      var backgroundOptions_strokeStyle = options_strokeStyle;
      var backgroundOptions = {
        strokeColor: backgroundOptions_strokeColor,
        strokeWidth: backgroundOptions_strokeWidth,
        strokeOpacity: backgroundOptions_strokeOpacity,
        strokeStyle: backgroundOptions_strokeStyle
      };
      addArrow(updateBackgroundArrow, backgroundOptions);
      return addArrow(updateArrow, options);
    };
  };
  React.useEffect((function () {
          var match = Fun.both(ymapsApi, map);
          if (match === undefined) {
            return ;
          }
          var map$1 = match[1];
          YandexMaps.$$Map.Events.add(YandexMaps.$$Map.events(map$1), "BoundsChange", (function (e) {
                  setZoom(function (param) {
                        return YandexMaps.$$Map.Events.$$Event.Zoom.get(e);
                      });
                }));
          YandexMaps.defineArrow(match[0]);
          setZoom(function (param) {
                return YandexMaps.$$Map.getZoom(map$1);
              });
        }), []);
  React.useEffect((function () {
          var match = Fun.both(ymapsApi, map);
          if (match === undefined) {
            return ;
          }
          var map$1 = match[1];
          createArrow(match[0], coordinates);
          return (function () {
                    [
                        arrowRef.current,
                        backgroundArrowRef.current
                      ].map(function (arrow) {
                          RCore.$$Option.forEach(arrow, (function (a) {
                                  YandexMaps.removeObject(Caml_option.some(map$1), a);
                                }));
                        });
                  });
        }), [zoom]);
  return null;
}

var make = MapReArrowComponent;

export {
  make ,
}
/* react Not a pure module */
