import momentTimezone from 'moment-timezone'
import { presentFormat } from './helpers.js'
import { capitalize } from 'planado/utils/index.js'

const RAW_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ'

export const getTimeZone = ({
  localizator: {
    datetime: { timezone },
  },
}) => timezone

export const setDefaultTimeZone = (ctx) => {
  momentTimezone.tz.setDefault(getTimeZone(ctx))
}

const _moment = (datetime) => momentTimezone.utc(datetime, RAW_FORMAT, true)

export const showTime = (datetime, format, ctx) => {
  if (datetime) {
    const {
      localizator: {
        lang,
        datetime: { timezone },
      },
    } = ctx

    let momentDatetime
    if (typeof datetime === 'string') {
      momentDatetime = _moment(datetime).tz(timezone)
    } else {
      momentDatetime = momentTimezone(datetime).tz(timezone)
    }

    switch (format) {
      case 'iso8601':
        return momentDatetime.format(RAW_FORMAT)
      case 'day_with_month': {
        const month = ctx.t('js.month_genetive')[momentDatetime.month()]
        const date = momentDatetime.date()

        switch (lang) {
          case 'en':
          case 'es':
            return `${date} ${capitalize(month)}`
          case 'ru':
          default:
            return `${date} ${month}`
        }
      }
      case 'date/calendar':
        return momentDatetime.utc().format(presentFormat('date', ctx))
      default:
        return momentDatetime.format(presentFormat(format, ctx))
    }
  } else {
    return null
  }
}

export const extractDate = (datetime, ctx) => {
  if (datetime) {
    return momentTimezone
      .utc(datetime, RAW_FORMAT, true)
      .tz(getTimeZone(ctx))
      .startOf('day')
      .format(RAW_FORMAT)
  } else {
    return null
  }
}

export const isValidTime = ({ date, format, ctx }) => {
  const presentedFormat = presentFormat(format, ctx)
  return momentTimezone(date, presentedFormat, true).isValid()
}

export const isSame = (a, b) => _moment(a).isSame(_moment(b))

export const isSameYear = (a, b) => _moment(a).isSame(_moment(b), 'year')

export const isBeforeTime = (a, b) => _moment(a).isBefore(_moment(b))

export const isAfterTime = (a, b) => _moment(a).isAfter(_moment(b))

export const isSameJsDay = (a, b) =>
  momentTimezone(a).isSame(momentTimezone(b), 'day')

export const isSameISODay = (a, b) =>
  momentTimezone(a).isSame(momentTimezone(b), 'day')

export const isBeforeDay = (a, b) => _moment(a).isBefore(_moment(b), 'day')

export const isAfterDay = (a, b) => _moment(a).isAfter(_moment(b), 'day')

export const getMoment = ({ dateTime, format, ctx }) => {
  if (dateTime) {
    if (format) {
      return momentTimezone
        .utc(dateTime, presentFormat(format, ctx), true)
        .tz(getTimeZone(ctx))
    } else {
      return _moment(dateTime).tz(getTimeZone(ctx))
    }
  } else {
    return null
  }
}

export const now = (ctx) =>
  momentTimezone.tz(getTimeZone(ctx)).utc().format(RAW_FORMAT)

export const nowM = (_ctx) => momentTimezone()

export const nowRound = ({ ctx, step, bias }) => {
  let dateTime = momentTimezone.tz(getTimeZone(ctx)).utc()

  if (bias) {
    dateTime = dateTime.add(bias, 'm')
  }

  if (step) {
    const round = step - (dateTime.minute() % step)
    if (round !== step) {
      dateTime = dateTime.add(round, 'm')
    }
  }

  return dateTime.format(RAW_FORMAT)
}

export const ceilToHour = (ctx) => {
  const datetime = momentTimezone
    .tz(getTimeZone(ctx))
    .utc()
    .startOf('hour')
    .add(1, 'h')

  return showTime(datetime, 'time', ctx)
}

export const timeToSeconds = (time, ctx) => {
  const timeFormat = presentFormat('time', ctx)
  return momentTimezone.duration(time, timeFormat).asSeconds()
}

export const secondsToTime = (seconds, ctx) => {
  const duration = momentTimezone.duration(seconds, 'seconds').asMilliseconds()
  const timeFormat = presentFormat('time', ctx)
  return momentTimezone.utc(duration).format(timeFormat)
}

export const concatDateWithTime = ({ date, time, ctx }) => {
  if (date && time) {
    const timeFormat = presentFormat('time', ctx)
    const dateFormat = presentFormat('date', ctx)

    const dateTime = `${date} ${time}`
    const dateTimeFormat = `${dateFormat} ${timeFormat}`

    return momentTimezone
      .tz(dateTime, dateTimeFormat, true, getTimeZone(ctx))
      .format(RAW_FORMAT)
  } else {
    return null
  }
}

export const updateDay = ({ dateTime, bias, ctx: _ }) => {
  if (dateTime) {
    return _moment(dateTime).add(bias, 'd').format(RAW_FORMAT)
  } else {
    return null
  }
}

export const updateSeconds = ({ dateTime, seconds, ctx: _ }) => {
  if (dateTime) {
    return _moment(dateTime).add(seconds, 's').format(RAW_FORMAT)
  } else {
    return null
  }
}

export const splitDate = (dateTime, ctx) => {
  const formattedDateTime = showTime(dateTime, 'datetime', ctx)

  if (formattedDateTime) {
    return formattedDateTime.split(' ')
  } else {
    return null
  }
}

const assertTime = (value) => {
  if (!(value instanceof Date)) {
    throw `Unexpected argument: ${value}`
  }
}

export const isDatesEqual = (a, b) => {
  assertTime(a)
  assertTime(b)

  return a.getTime() === b.getTime()
}

export const isoStringToDateString = (isoString, ctx) =>
  showTime(momentTimezone(isoString), 'date', ctx)

export const timePeriod = (timeParams) => {
  let { scheduledAt, scheduledFinishAt, ctx, today } = timeParams
  if (!isSameISODay(scheduledAt, scheduledFinishAt)) {
    return (
      showTime(scheduledAt, 'date_week', ctx) +
      ' - ' +
      showTime(scheduledFinishAt, 'date_week', ctx)
    )
  } else if (today) {
    return (
      showTime(scheduledAt, 'time', ctx) +
      ' - ' +
      showTime(scheduledFinishAt, 'time', ctx)
    )
  } else {
    return (
      showTime(scheduledAt, 'date_time_week', ctx) +
      ' - ' +
      showTime(scheduledFinishAt, 'time', ctx)
    )
  }
}

export const scheduledTime = (timeParams) => {
  let { scheduledAt, scheduledFinishAt, ctx } = timeParams
  if (scheduledAt === null) {
    return null
  } else if (scheduledFinishAt === null) {
    return showTime(scheduledAt, 'date_time_week', ctx)
  } else {
    return timePeriod(timeParams)
  }
}

export const presentDatetime = (dateTime, ctx) => {
  if (dateTime) {
    return {
      date: showTime(dateTime, 'date', ctx),
      time: showTime(dateTime, 'time', ctx),
    }
  } else {
    return {
      date: null,
      time: null,
    }
  }
}

export const isoDateToLocalDate = (isoDate, ctx) => {
  if (isoDate) {
    return showTime(momentTimezone(isoDate), 'date', ctx)
  } else {
    return null
  }
}

export const formatDurationAsTime = (duration) => {
  return momentTimezone.utc(duration.asMilliseconds()).format('HH:mm')
}
