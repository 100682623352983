// extracted by mini-css-extract-plugin
export var active = "active-Ruzr5bkgMqK0Cnm1Uudu";
export var address = "address-nffgdfSN7aUSxS3XKNQ8";
export var assignee = "assignee-SAlXq154K7jAYbnyUGM1";
export var compact = "compact-BqHTZef2XYczZ09_zC3m";
export var container = "container-vvxtWWsxqBDk_Bn0UCMg";
export var enRoute = "en-route-lpFwOer8SYZd2seBAeOW";
export var finished = "finished-VdZJVbZUIas3Q3OseKpH";
export var hovered = "hovered-wHugZXiP55SEWQO92AWv";
export var job = "job-adDxau9wjcdtXIMKAvLV";
export var noAssignee = "no-assignee-fBhp7ewxjXhYidhCbQiO";
export var noTemplate = "no-template-UCIq3N214S0izGW4cr0_";
export var notPublished = "not-published-mgbSDoNZ_6go9Y7ql1dn";
export var started = "started-wX39wLmvzgd6g9PmpiQt";
export var successful = "successful-lZd619jLGFyBlltVQvPS";
export var suspended = "suspended-tvf4T4LTZwUlHUXXVtPv";
export var template = "template-FogULS3z8YBCzAob2OaS";
export var unsuccessful = "unsuccessful-vC6vugpUVPFjG94atTCd";