

import * as RCore from "./RCore.mjs";
import * as $$Number from "./Number.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as JsonDecode from "./JsonDecode.mjs";
import * as Core__Float from "@rescript/core/lib/es6/src/Core__Float.mjs";
import * as Shared_Lib_Float from "../shared/lib/Shared_Lib_Float.mjs";

function format(startGroupingFromOpt, value, ctx) {
  var startGroupingFrom = startGroupingFromOpt !== undefined ? startGroupingFromOpt : 5;
  var match = ctx.localizator.numbers;
  var delimiter = match.delimiter;
  var separator = match.separator;
  var match$1 = (function (__x) {
        return Js_string.split(".", __x);
      })(value.toString());
  var len = match$1.length;
  if (len >= 3) {
    return "";
  }
  switch (len) {
    case 0 :
        return "";
    case 1 :
        var integer = match$1[0];
        var integer$1 = $$Number.Utils.addThousandsSeparator(integer, startGroupingFrom, delimiter);
        return integer$1 + separator + "0";
    case 2 :
        var integer$2 = match$1[0];
        var fractional = match$1[1];
        var integer$3 = $$Number.Utils.addThousandsSeparator(integer$2, startGroupingFrom, delimiter);
        return integer$3 + separator + fractional;
    
  }
}

var FormatKilometers = {
  format: format
};

function removeCharacters(str) {
  var regex = /[^\d]/g;
  return Js_string.replaceByRe(regex, "", str);
}

function canBeDistance(str) {
  var regex = /^([\d\s.,]+)?$/;
  return regex.test(str);
}

function check(str) {
  var refined = removeCharacters(str);
  if (canBeDistance(str)) {
    return refined.length <= 10;
  } else {
    return false;
  }
}

function parse(value, param) {
  var match = param.localizator.numbers;
  var separator = match.separator;
  var refinedValue = $$Number.Utils.removeSpacesAndLetters(value);
  if (!check(value)) {
    return ;
  }
  if (refinedValue.length === 0) {
    return 0.0;
  }
  var dotsCount = $$Number.Utils.countDots(refinedValue);
  var commasCount = $$Number.Utils.countCommas(refinedValue);
  var refinedValue$1;
  var exit = 0;
  if (dotsCount !== 0) {
    if (dotsCount !== 1) {
      exit = 1;
    } else if (commasCount !== 0) {
      if (commasCount !== 1) {
        exit = 1;
      } else {
        refinedValue$1 = (function (__x) {
              return $$Number.Utils.replace(separator, ".", __x);
            })($$Number.Utils.removeDelimiters(match.delimiter, refinedValue));
      }
    } else {
      refinedValue$1 = refinedValue;
    }
  } else if (commasCount !== 0) {
    if (commasCount !== 1) {
      exit = 1;
    } else {
      refinedValue$1 = $$Number.Utils.replaceCommasToDots(refinedValue);
    }
  } else {
    refinedValue$1 = refinedValue;
  }
  if (exit === 1) {
    refinedValue$1 = dotsCount > commasCount ? $$Number.Utils.replaceCommasToDots($$Number.Utils.removeDelimiters(".", refinedValue)) : (
        commasCount > dotsCount ? $$Number.Utils.removeDelimiters(",", refinedValue) : ""
      );
  }
  var match$1 = Js_string.split(".", refinedValue$1);
  var len = match$1.length;
  var refinedValue$2;
  if (len >= 3) {
    refinedValue$2 = undefined;
  } else {
    switch (len) {
      case 0 :
          refinedValue$2 = undefined;
          break;
      case 1 :
          var integer = match$1[0];
          refinedValue$2 = integer + ".0";
          break;
      case 2 :
          var integer$1 = match$1[0];
          var fractional = match$1[1];
          refinedValue$2 = integer$1 + "." + Js_string.substring(0, 3, fractional);
          break;
      
    }
  }
  return RCore.$$Option.flatMap(refinedValue$2, Core__Float.fromString);
}

var ParseKilometers = {
  parse: parse
};

function fromM(num) {
  return Math.round(num) / 1000.0;
}

function toM(num) {
  return Math.round(num * 1000.0);
}

var Convert = {
  fromM: fromM,
  toM: toM
};

function decode(ctx) {
  return JsonDecode.map(JsonDecode.$$float, (function (v) {
                if (ctx.localizator.measurementSystem !== "Imperial") {
                  if (v < 1000.0) {
                    return {
                            TAG: "Meters",
                            _0: v | 0
                          };
                  } else {
                    return {
                            TAG: "Kilometers",
                            _0: Shared_Lib_Float.toPrecision(2, v / 1000.0)
                          };
                  }
                }
                var miles = v * 0.00062137;
                if (miles < 1.0) {
                  return {
                          TAG: "Feet",
                          _0: miles / 0.000189394 | 0
                        };
                } else {
                  return {
                          TAG: "Miles",
                          _0: Shared_Lib_Float.toPrecision(2, miles)
                        };
                }
              }));
}

var CheckKilometers = {
  canBeDistance: canBeDistance
};

var Distance = {
  decode: decode
};

var mToKm = fromM;

var kmToM = toM;

export {
  FormatKilometers ,
  CheckKilometers ,
  ParseKilometers ,
  Convert ,
  Distance ,
  format ,
  parse ,
  mToKm ,
  kmToM ,
}
/* Number Not a pure module */
