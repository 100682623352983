

import * as El from "../../../libraries/El.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Portal from "../../../Portal.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as DomUtils from "../../../DomUtils.mjs";
import * as Optional from "../Optional.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TooltipModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/Tooltip/Tooltip.module.css";

function fromString(value) {
  switch (value) {
    case "right" :
        return "right";
    case "top" :
        return "top";
    default:
      return "bottom";
  }
}

function toPx(x) {
  return x.toString() + "px";
}

function elementPosition(position, element) {
  var match = DomUtils.BoundingClientRect.get(element);
  var y = match.y;
  var x = match.x;
  var width = match.width;
  var height = match.height;
  if (position === "right") {
    return {
            top: y + height / 2.0,
            left: x + width
          };
  } else if (position === "top") {
    return {
            top: y,
            left: x + width / 2.0
          };
  } else {
    return {
            top: y + height,
            left: x + width / 2.0
          };
  }
}

function tooltipPosition(position, tooltip, offset) {
  var left = offset.left;
  var top = offset.top;
  var tooltip$1 = tooltip.current;
  if (tooltip$1 == null) {
    return offset;
  }
  var match = DomUtils.BoundingClientRect.get(tooltip$1);
  var height = match.height;
  if (position === "right") {
    return {
            top: top - height / 2.0,
            left: left
          };
  }
  var width = match.width;
  if (position === "top") {
    return {
            top: top - height - 8.0,
            left: left - width / 2.0
          };
  } else {
    return {
            top: top,
            left: left - width / 2.0
          };
  }
}

function make(position, element, tooltip) {
  var offset = elementPosition(position, element);
  var match = tooltipPosition(position, tooltip, offset);
  return {
          left: toPx(match.left),
          top: toPx(match.top)
        };
}

var styles = TooltipModuleCss;

function Tooltip$Inner(Props) {
  var position = Props.position;
  var manual = Props.manual;
  var element = Props.element;
  var content = Props.content;
  var wrapperClassName = Props.wrapperClassName;
  var tooltip = React.useRef(null);
  var match = React.useState(function () {
        return false;
      });
  var setRendered = match[1];
  var rendered = match[0];
  var match$1 = React.useState(function () {
        return true;
      });
  var setShow = match$1[1];
  var show = match$1[0];
  var match$2 = React.useState(function () {
        return make(position, element, tooltip);
      });
  var setStyle = match$2[1];
  var style = match$2[0];
  var onDocumentClick = function (show) {
    return function ($$event) {
      var target = $$event.target;
      if (!show) {
        return ;
      }
      var tooltip$1 = tooltip.current;
      if (!(tooltip$1 == null) && DomUtils.contains(tooltip$1, target)) {
        return ;
      }
      setShow(function (param) {
            return false;
          });
    };
  };
  var onClick = function ($$event) {
    var target = $$event.target;
    if (DomUtils.contains(element, target)) {
      $$event.stopPropagation();
      return setShow(function (prev) {
                  return !prev;
                });
    }
    
  };
  var onHover = function ($$event) {
    $$event.stopPropagation();
    setShow(function (param) {
          return true;
        });
  };
  var onBlur = function ($$event) {
    $$event.stopPropagation();
    setShow(function (param) {
          return false;
        });
  };
  var match$3 = AppContext.useWire();
  var subscriptions = match$3.subscriptions;
  React.useEffect((function () {
          if (!manual) {
            return ;
          }
          var unsubscribeOnClick = subscriptions.click.subscribe(onClick);
          var unsubscribeOnDocClick = subscriptions.click.subscribe(onDocumentClick(show));
          return (function () {
                    unsubscribeOnClick();
                    unsubscribeOnDocClick();
                  });
        }), [
        show,
        manual
      ]);
  React.useEffect((function () {
          setStyle(function (param) {
                return make(position, element, tooltip);
              });
          setShow(function (param) {
                return false;
              });
          setRendered(function (param) {
                return true;
              });
          if (manual) {
            return ;
          } else {
            DomUtils.Listeners.onMouseOver(undefined, element, onHover);
            DomUtils.Listeners.onMouseLeave(undefined, element, onBlur);
            return (function () {
                      DomUtils.Listeners.removeOnMouseOver(undefined, element, onHover);
                      DomUtils.Listeners.removeOnMouseLeave(undefined, element, onBlur);
                    });
          }
        }), []);
  React.useEffect(function () {
        var updatedStyle = make(position, element, tooltip);
        if (rendered && Caml_obj.notequal(style, updatedStyle)) {
          setStyle(function (param) {
                return updatedStyle;
              });
        }
        
      });
  var positionClassName = position === "right" ? styles.right : (
      position === "top" ? styles.top : styles.bottom
    );
  var className = El.Cn.concat(El.Cn.concat(El.Cn.concat(styles.tooltip, positionClassName), rendered ? styles.rendered : ""), wrapperClassName !== undefined ? wrapperClassName : "");
  return React.createElement(Optional.make, {
              show: show,
              children: React.createElement(Portal.make, {
                    root: match$3.footer,
                    children: React.createElement("div", {
                          ref: tooltip,
                          className: className,
                          style: style
                        }, Utils.Translations.toHtml(content))
                  })
            });
}

function Tooltip(Props) {
  var elementRef = Props.elementRef;
  var position = Props.position;
  var manualOpt = Props.manual;
  var enabledOpt = Props.enabled;
  var wrapperClassName = Props.wrapperClassName;
  var content = Props.content;
  var children = Props.children;
  var manual = manualOpt !== undefined ? manualOpt : false;
  var enabled = enabledOpt !== undefined ? enabledOpt : true;
  var match = React.useState(function () {
        
      });
  var setElement = match[1];
  var element = match[0];
  React.useEffect((function () {
          setElement(function (param) {
                return Caml_option.nullable_to_opt(elementRef.current);
              });
        }), []);
  return React.createElement(React.Fragment, undefined, children, element !== undefined && enabled ? React.createElement(Tooltip$Inner, {
                    position: position,
                    manual: manual,
                    element: Caml_option.valFromOption(element),
                    content: content,
                    wrapperClassName: wrapperClassName
                  }) : null);
}

var Position = {};

var positionFromString = fromString;

var make$1 = Tooltip;

var $$default = Tooltip;

export {
  Position ,
  positionFromString ,
  make$1 as make,
  $$default as default,
}
/* styles Not a pure module */
