

import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as FormInput from "../../../../../inputs/FormInput.mjs";

var commentRequired$p = Utils.Translations.t("js.jobs.modal.comment_required");

var resolutionComment$p = Utils.Translations.t("js.jobs.modal.resolution_comment");

function JobForm_ResolutionInput(Props) {
  var name = Props.name;
  var isCommentRequired = Props.isCommentRequired;
  var onSelect = Props.onSelect;
  var onCommentChange = Props.onCommentChange;
  var hasComment = Props.hasComment;
  var isSelectedOpt = Props.isSelected;
  var resolutionCommentOpt = Props.resolutionComment;
  var ctx = Props.ctx;
  var isSelected = isSelectedOpt !== undefined ? isSelectedOpt : false;
  var resolutionComment = resolutionCommentOpt !== undefined ? resolutionCommentOpt : "";
  var commentLabelText = isCommentRequired ? commentRequired$p(ctx) : resolutionComment$p(ctx);
  var commentInput = isSelected && hasComment ? React.createElement(FormInput.make, {
          _type: "textarea",
          value: resolutionComment,
          onValueChange: onCommentChange,
          name: "job-note",
          labelText: commentLabelText,
          maxLength: 5000,
          wrapperClass: Js_dict.fromArray([[
                  "pd-form-group-after-radio",
                  true
                ]]),
          inputClass: Js_dict.fromArray([[
                  "pd-form-control-300px",
                  true
                ]]),
          autoFocus: true
        }) : null;
  return React.createElement("div", undefined, React.createElement("label", {
                  className: "pd-form-radio pd-label-pointer pd-label-normal-weight"
                }, React.createElement("input", {
                      className: "pd-form-control",
                      checked: isSelected,
                      type: "radio",
                      onChange: onSelect
                    }), name), commentInput);
}

var make = JobForm_ResolutionInput;

export {
  make ,
}
/* commentRequired' Not a pure module */
