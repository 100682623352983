

import * as Units from "../../../../../../types/Units.mjs";
import * as Locale from "../../../../../../libraries/Locale.mjs";

function inBusinessHours(businessHours, currentDay, startAt, finishAt) {
  if (businessHours === undefined) {
    return true;
  }
  var start = Locale.T.add(Locale.T.floorToHourStart(businessHours.start), currentDay);
  var finish = Locale.T.add(businessHours.finish, currentDay);
  return Locale.T.intersectInterval([
              start,
              finish
            ], [
              startAt,
              finishAt
            ]);
}

function left(wire, businessHours, currentDay, sizeOfMinute, startAt) {
  var startAt$1;
  if (businessHours !== undefined) {
    var startDuration = Locale.T.floorToHourStart(businessHours.start);
    var startOfDay = Locale.T.add(startDuration, currentDay);
    var startAt$2 = Locale.T.max(startOfDay, startAt);
    startAt$1 = Locale.T.prevMinutesD(startDuration, startAt$2);
  } else {
    startAt$1 = Locale.T.max(currentDay, startAt);
  }
  var minutes = Locale.T.dateToMinutes(wire, startAt$1);
  return Units.Px.multiplyWithFloat(sizeOfMinute, minutes);
}

function width(wire, businessHours, currentDay, sizeOfMinute, left, finishAt) {
  var finishAt$1;
  if (businessHours !== undefined) {
    var endOfDay = Locale.T.add(businessHours.finish, currentDay);
    var finishAt$2 = Locale.T.min(endOfDay, finishAt);
    finishAt$1 = Locale.T.prevMinutesD(Locale.T.floorToHourStart(businessHours.start), finishAt$2);
  } else {
    finishAt$1 = Locale.T.min(finishAt, Locale.T.endOf("day", currentDay));
  }
  var minutes = Locale.T.dateToMinutes(wire, finishAt$1);
  return Units.Px.minus(Units.Px.multiplyWithFloat(sizeOfMinute, minutes), left);
}

export {
  inBusinessHours ,
  left ,
  width ,
}
/* Locale Not a pure module */
