

import * as Tour from "../../libraries/tours/Tour.mjs";
import * as Wire from "../../types/Wire.mjs";
import * as React from "react";
import * as JobModal from "../job/edit/JobModal.mjs";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as ModalQuery from "../../types/ModalQuery.mjs";
import * as Pages_Site from "../../pages/sites/edit/Pages_Site.mjs";
import * as Pages_Client from "../../pages/clients/edit/Pages_Client.mjs";
import * as Pages_Sites_Upload from "../../pages/sites/upload/Pages_Sites_Upload.mjs";
import * as Pages_Clients_Upload from "../../pages/clients/upload/Pages_Clients_Upload.mjs";

function Modals(Props) {
  var wire = Props.wire;
  var openModal = wire.openModal;
  var tour = Tour.use(wire);
  var onTrackerEvent = function (evt) {
    if (typeof evt !== "object") {
      return Wire.closeModal(wire);
    }
    switch (evt.TAG) {
      case "OpenJob" :
          return Wire.openJob(undefined, undefined, wire, evt._0);
      case "AddJob" :
          return openModal({
                      NAME: "Job",
                      VAL: {
                        NAME: "New",
                        VAL: evt._0
                      }
                    });
      case "NewJob" :
          var params = evt._0;
          var path;
          switch (params.TAG) {
            case "Copy" :
                path = ModalQuery.Job.NewOptions.copy(params._0);
                break;
            case "Next" :
                path = ModalQuery.Job.NewOptions.nextJob(params._0, params._1);
                break;
            case "Completion" :
                path = ModalQuery.Job.NewOptions.completionJob(params._0);
                break;
            
          }
          return Wire.replaceModal(wire, {
                      NAME: "Job",
                      VAL: {
                        NAME: "New",
                        VAL: path
                      }
                    });
      case "JobSubmitted" :
          tour.trigger("jobCreated");
          return ;
      
    }
  };
  React.useEffect((function () {
          return wire.subscriptions.jobs.subscribe(onTrackerEvent);
        }), [
        wire,
        tour
      ]);
  var match = Core__List.head(wire.route.modals);
  if (match === undefined) {
    return null;
  }
  var variant = match.NAME;
  if (variant === "Site") {
    var subRoute = match.VAL;
    if (typeof subRoute === "object" && subRoute.NAME !== "Edit") {
      if (subRoute.VAL === "Sites") {
        return React.createElement(Pages_Sites_Upload.make, {
                    wire: wire
                  });
      } else {
        return React.createElement(Pages_Clients_Upload.make, {
                    wire: wire
                  });
      }
    }
    return React.createElement(Pages_Site.make, {
                wire: wire,
                subRoute: subRoute
              });
  }
  if (variant === "Client") {
    var subRoute$1 = match.VAL;
    if (typeof subRoute$1 === "object" && subRoute$1.NAME !== "Edit") {
      if (subRoute$1.VAL === "Sites") {
        return React.createElement(Pages_Sites_Upload.make, {
                    wire: wire
                  });
      } else {
        return React.createElement(Pages_Clients_Upload.make, {
                    wire: wire
                  });
      }
    }
    return React.createElement(Pages_Client.make, {
                wire: wire,
                subRoute: subRoute$1
              });
  }
  var subroute = match.VAL;
  return React.createElement(JobModal.make, {
              subroute: subroute,
              key: ModalQuery.Job.key(subroute)
            });
}

var make = Modals;

export {
  make ,
}
/* Tour Not a pure module */
