

import * as El from "../../../../../../../../../libraries/El.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Assignee_Types from "../../../../../../../../../types/Assignee_Types.mjs";
import * as WeekTimeline_BarCellHook from "./WeekTimeline_BarCellHook.mjs";
import * as WeekTimeline_ActiveDayState from "../../../../active_day/lib/state/WeekTimeline_ActiveDayState.mjs";
import * as WeekTimeline_BarCellModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/timeline/bar/components/bar_cell/WeekTimeline_BarCell.module.css";

var styles = WeekTimeline_BarCellModuleCss;

function WeekTimeline_BarCell(Props) {
  var viewportEl = Props.viewportEl;
  var jobAssignee = Props.assignee;
  var weekDay = Props.weekDay;
  var activeDay = WeekTimeline_ActiveDayState.useActiveDay();
  var active = activeDay !== undefined ? Caml_obj.equal(Assignee_Types.Assignee.uuid(activeDay.bar), Assignee_Types.Assignee.uuid(jobAssignee)) && activeDay.weekDay === weekDay : false;
  var elementRef = WeekTimeline_BarCellHook.use(viewportEl, active, jobAssignee, weekDay);
  var blockClassName = El.Cn.concat(El.Cn.concat("cell", styles.cell), active ? styles.active : "");
  return React.createElement("div", {
              ref: elementRef,
              className: blockClassName
            }, React.createElement("button", {
                  className: styles.button,
                  type: "button"
                }));
}

var make = React.memo(WeekTimeline_BarCell);

export {
  make ,
}
/* styles Not a pure module */
