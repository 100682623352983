

import * as Liquid_Parser from "./Liquid_Parser.mjs";
import * as Liquid_Expression from "./Liquid_Expression.mjs";

function make(name, args) {
  return {
          name: name,
          args: args
        };
}

var Filter = {
  make: make
};

var empty_lookups = [];

var empty_filters = [];

var empty = {
  markup: "  ",
  lookups: empty_lookups,
  filters: empty_filters
};

function make$1(markup) {
  var parser = Liquid_Parser.make(markup);
  if (Liquid_Parser.look(undefined, parser, "EndOfString")) {
    return ;
  }
  var match = Liquid_Expression.parse(Liquid_Parser.expression(parser));
  if (match === undefined) {
    return ;
  }
  if (typeof match !== "object") {
    return ;
  }
  if (match.TAG !== "Variable") {
    return ;
  }
  var filters = [];
  while(Liquid_Parser.consume("Pipe", parser) !== undefined) {
    var filterName = Liquid_Parser.consume("Id", parser);
    var filterArgs;
    if (Liquid_Parser.consume("Colon", parser) !== undefined) {
      var args = [Liquid_Parser.argument(parser)];
      while(Liquid_Parser.consume("Comma", parser) !== undefined) {
        args.push(Liquid_Parser.argument(parser));
      };
      filterArgs = args;
    } else {
      filterArgs = [];
    }
    if (filterName !== undefined) {
      filters.push({
            name: filterName,
            args: filterArgs
          });
    }
    
  };
  Liquid_Parser.consume("EndOfString", parser);
  return {
          markup: markup,
          lookups: match._0,
          filters: filters
        };
}

export {
  Filter ,
  empty ,
  make$1 as make,
}
/* Liquid_Parser Not a pure module */
