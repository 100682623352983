

import * as Hooks from "../../../../../libraries/hooks/Hooks.mjs";
import * as Types from "../../../../../types/Types.mjs";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_JobGroupHeader from "./header/Schedule_JobGroupHeader.mjs";
import * as Schedule_JobGroupFinished from "./finished/Schedule_JobGroupFinished.mjs";
import * as Schedule_JobGroupUnfinished from "./unfinished/Schedule_JobGroupUnfinished.mjs";
import * as Schedule_JobGroupModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/popovers/job_group/Schedule_JobGroup.module.css";

var styles = Schedule_JobGroupModuleCss;

function Schedule_JobGroup(Props) {
  var assigneeUuid = Props.assigneeUuid;
  var currentDay = Props.currentDay;
  var jobs = Props.jobs;
  var close = Props.close;
  var listRef = Types.ReactRef.use();
  Hooks.useOnClickOutside(undefined, listRef, close);
  Hooks.useOnEscPress(undefined, close);
  var tmp = {
    currentDay: currentDay,
    jobs: jobs,
    close: close
  };
  if (assigneeUuid !== undefined) {
    tmp.assigneeUuid = Caml_option.valFromOption(assigneeUuid);
  }
  return React.createElement("div", {
              ref: listRef,
              className: styles.container
            }, React.createElement(Schedule_JobGroupHeader.make, tmp), React.createElement("div", {
                  className: styles.jobs
                }, React.createElement(Schedule_JobGroupUnfinished.make, {
                      jobs: jobs
                    }), React.createElement(Schedule_JobGroupFinished.make, {
                      jobs: jobs
                    })));
}

var make = React.memo(Schedule_JobGroup);

export {
  make ,
}
/* styles Not a pure module */
