

import * as DND from "../../../libraries/drag_and_drop/DND.mjs";

var DND$1 = DND.Make({});

var DND_Context = DND$1.Context;

var DND_ContextProvider = DND$1.ContextProvider;

var DND_Draggable = {
  use: DND$1.Draggable.use
};

var DND_Droppable = DND$1.Droppable;

var DND$2 = {
  Context: DND_Context,
  ContextProvider: DND_ContextProvider,
  Draggable: DND_Draggable,
  Droppable: DND_Droppable
};

var Context;

var ContextProvider;

var Draggable;

var Droppable;

export {
  DND$2 as DND,
  Context ,
  ContextProvider ,
  Draggable ,
  Droppable ,
}
/* DND Not a pure module */
