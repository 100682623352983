

import * as El from "../../../../../../../../libraries/El.mjs";
import * as Icon from "../../../../../../../../Icon.mjs";
import * as Uuid from "../../../../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../../../../../libraries/Locale.mjs";
import * as Tooltip from "../../../../../../../common/Tooltip/Tooltip.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Shared_Lib_Array from "../../../../../../../../shared/lib/Shared_Lib_Array.mjs";
import * as JobForm_HistoryTypes from "../../../../../lib/types/JobForm_HistoryTypes.mjs";
import * as JobForm_HistoryEventValue from "./event_value/JobForm_HistoryEventValue.mjs";
import * as JobForm_HistoryEventsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/edit/form/components/history/components/events/JobForm_HistoryEvents.module.css";

var styles = JobForm_HistoryEventsModuleCss;

function jobViewedAt$p(datetime, c) {
  return Utils.Translations.tx("js.jobs.history.events.job_viewed_at")(datetime, c);
}

function JobForm_HistoryEvents$LocationMarker(Props) {
  var state = Props.state;
  if (state !== undefined) {
    return React.createElement(React.Fragment, undefined, React.createElement("div", {
                    className: styles.location
                  }, React.createElement("div", {
                        className: styles.marker
                      })));
  } else {
    return React.createElement("div", {
                className: styles.withoutLocation
              });
  }
}

var icon = React.createElement("svg", {
      className: styles.viewedIcon,
      height: "9",
      width: "16",
      fill: "none",
      viewBox: "0 0 16 9",
      xmlns: "http://www.w3.org/2000/svg"
    }, React.createElement("path", {
          clipRule: "evenodd",
          d: "M11.0567 1.07216C11.1708 0.940929 11.1569 0.742061 11.0256 0.627979L10.4128 0.0772371C10.2816 -0.0368447 10.0827 -0.0229412 9.96861 0.108292L3.79963 7.09568L1.44897 4.87596C1.32598 4.75301 1.12666 4.75301 1.00367 4.87596L0.41399 5.46566C0.29104 5.58861 0.29104 5.78797 0.41399 5.91092L3.48078 8.89263C3.60867 9.02052 3.94279 9.05028 4.10044 8.89263C4.04803 8.93453 11.0567 1.07216 11.0567 1.07216Z",
          fill: "#00A3FF",
          fillRule: "evenodd"
        }), React.createElement("path", {
          clipRule: "evenodd",
          d: "M15.079 1.07216C15.1931 0.940929 15.1792 0.742061 15.0479 0.627979L14.4351 0.0772371C14.3039 -0.0368447 14.105 -0.0229412 13.9909 0.108292L7.82192 7.09568L7.40354 6.80826L6.43019 7.90478L7.50307 8.89263C7.63096 9.02052 7.96508 9.05028 8.12273 8.89263C8.07032 8.93453 15.079 1.07216 15.079 1.07216Z",
          fill: "#00A3FF",
          fillRule: "evenodd"
        }));

function isVisible(value) {
  return RCore.$$Option.isSome(value);
}

function viewedMark(wire, viewedAt, ref) {
  return React.createElement(Tooltip.make, {
              elementRef: ref,
              position: "bottom",
              content: jobViewedAt$p({
                    date: Locale.T.fmtS("Date", wire)(viewedAt),
                    time: Locale.T.fmtS("Time", wire)(viewedAt)
                  }, wire.ctx),
              children: React.createElement("span", {
                    ref: ref
                  }, icon)
            });
}

function JobForm_HistoryEvents$JobViewedIcon(Props) {
  var wire = Props.wire;
  var viewedAt = Props.viewedAt;
  if (viewedAt === undefined) {
    return null;
  }
  var ref = React.useRef(null);
  return React.createElement(React.Fragment, undefined, React.createElement("div", undefined, viewedMark(wire, viewedAt, ref)));
}

function JobForm_HistoryEvents$DayUserEvents$Header(Props) {
  var wire = Props.wire;
  var date = Props.date;
  var application = Props.application;
  var worker = Props.worker;
  var workerName;
  var exit = 0;
  if (typeof application !== "object") {
    switch (application) {
      case "Web" :
      case "Mobile" :
          exit = 1;
          break;
      case "API" :
          workerName = null;
          break;
      
    }
  } else {
    workerName = null;
  }
  if (exit === 1) {
    workerName = worker !== undefined ? React.createElement("p", {
            className: styles.workerName
          }, worker.name) : null;
  }
  var sourceIcon;
  if (typeof application !== "object") {
    switch (application) {
      case "Web" :
          sourceIcon = React.createElement("span", {
                className: El.Cn.concat(Icon.style(undefined, undefined, "desktop"), styles.iconDesktop)
              });
          break;
      case "Mobile" :
          sourceIcon = React.createElement("span", {
                className: El.Cn.concat(Icon.style(undefined, undefined, "mobile-alt"), styles.iconMobile)
              });
          break;
      case "API" :
          sourceIcon = React.createElement("span", {
                className: El.Cn.concat(Icon.style(undefined, undefined, "code"), styles.iconApi)
              });
          break;
      
    }
  } else {
    sourceIcon = React.createElement("span", {
          className: El.Cn.concat(Icon.style(undefined, undefined, "cogs"), styles.iconOther)
        });
  }
  var date$1 = React.createElement("div", {
        className: styles.date
      }, Locale.T.fmtM("DateFull", wire)(date));
  return React.createElement("div", {
              className: styles.eventGroupHeader
            }, workerName, date$1, sourceIcon);
}

function JobForm_HistoryEvents$DayUserEvents$Event(Props) {
  var wire = Props.wire;
  var param = Props.event;
  var triggerMapEvent = Props.triggerMapEvent;
  var onSelectDate = Props.onSelectDate;
  var allImages = Props.allImages;
  var value = param.value;
  var deviceState = param.deviceState;
  var separator = React.createElement("div", {
        className: styles.separator
      });
  if (typeof value === "object" && value.NAME === "MovementFinished") {
    return React.createElement("div", {
                className: styles.event
              }, React.createElement(JobForm_HistoryEventValue.make, {
                    wire: wire,
                    value: value,
                    allImages: allImages
                  }), separator);
  }
  var className = deviceState !== undefined ? styles.eventWithLocation : styles.event;
  var happenedAtBlock = React.createElement("div", {
        className: styles.time
      }, Locale.T.fmtM("Time", wire)(param.happenedAt));
  var onMouseEnter = function (_event) {
    if (deviceState === undefined) {
      return ;
    }
    var geolocation = deviceState.geolocation;
    var coordinate_longitude = geolocation.longitude;
    var coordinate_latitude = geolocation.latitude;
    var coordinate = {
      longitude: coordinate_longitude,
      latitude: coordinate_latitude
    };
    triggerMapEvent({
          TAG: "AddHighlight",
          _0: coordinate
        });
  };
  var onMouseLeave = function (_event) {
    if (deviceState !== undefined) {
      return triggerMapEvent("RemoveHighlight");
    }
    
  };
  var onClick = function ($$event) {
    $$event.preventDefault();
    $$event.stopPropagation();
    if (deviceState === undefined) {
      return ;
    }
    var geolocation = deviceState.geolocation;
    var coordinate_longitude = geolocation.longitude;
    var coordinate_latitude = geolocation.latitude;
    var coordinate = {
      longitude: coordinate_longitude,
      latitude: coordinate_latitude
    };
    onSelectDate();
    triggerMapEvent({
          TAG: "Pan",
          _0: coordinate
        });
  };
  return React.createElement("div", {
              className: className,
              onClick: onClick,
              onMouseEnter: onMouseEnter,
              onMouseLeave: onMouseLeave
            }, happenedAtBlock, React.createElement(JobForm_HistoryEventValue.make, {
                  wire: wire,
                  value: value,
                  allImages: allImages
                }), React.createElement(JobForm_HistoryEvents$JobViewedIcon, {
                  wire: wire,
                  viewedAt: param.viewedAt
                }), separator, React.createElement(JobForm_HistoryEvents$LocationMarker, {
                  state: deviceState
                }));
}

function JobForm_HistoryEvents$DayUserEvents(Props) {
  var wire = Props.wire;
  var param = Props.dateWorkerEvents;
  var triggerMapEvent = Props.triggerMapEvent;
  var onSelectDate = Props.onSelectDate;
  var allImages = Props.allImages;
  var date = param.date;
  return React.createElement("div", {
              className: styles.group
            }, React.createElement(JobForm_HistoryEvents$DayUserEvents$Header, {
                  wire: wire,
                  date: date,
                  application: param.application,
                  worker: param.worker
                }), param.events.map(function ($$event) {
                  return React.createElement(JobForm_HistoryEvents$DayUserEvents$Event, {
                              wire: wire,
                              event: $$event,
                              triggerMapEvent: triggerMapEvent,
                              onSelectDate: (function () {
                                  onSelectDate(date);
                                }),
                              allImages: allImages,
                              key: Uuid.toString($$event.uuid)
                            });
                }));
}

function JobForm_HistoryEvents(Props) {
  var wire = Props.wire;
  var eventsGrouped = Props.eventsGrouped;
  var triggerMapEvent = Props.triggerMapEvent;
  var onSelectDate = Props.onSelectDate;
  var allImages = JobForm_HistoryTypes.FieldUpdated.FieldValue.removeDuplicates(eventsGrouped.flatMap(function (g) {
            return g.events.flatMap(function (e) {
                        var match = e.value;
                        if (typeof match !== "object") {
                          return [];
                        }
                        if (match.NAME !== "FieldUpdated") {
                          return [];
                        }
                        var match$1 = match.VAL.value;
                        if (typeof match$1 !== "object") {
                          return [];
                        }
                        var variant = match$1.NAME;
                        if (variant === "Signature" || variant === "Images") {
                          return match$1.VAL;
                        } else {
                          return [];
                        }
                      });
          }));
  var eventsByDateAndWorker = RCore.$$Array.reduce(eventsGrouped, [], (function (acc, param) {
          var date = param.date;
          var eventsByWorker = RCore.$$Array.reduce(param.events, [], (function (groups, $$event) {
                  var match = Shared_Lib_Array.last(groups);
                  var lastGroup = match[1];
                  if (lastGroup === undefined) {
                    return Belt_Array.concatMany([
                                groups,
                                [[$$event]]
                              ]);
                  }
                  var match$1 = Shared_Lib_Array.last(lastGroup)[1];
                  if (match$1 !== undefined) {
                    if (Caml_obj.equal($$event.worker, match$1.worker) && Caml_obj.equal(match$1.application, $$event.application)) {
                      Js_array.push($$event, lastGroup);
                      return groups;
                    } else {
                      return Belt_Array.concatMany([
                                  groups,
                                  [[$$event]]
                                ]);
                    }
                  } else {
                    return Belt_Array.concatMany([
                                groups,
                                [[$$event]]
                              ]);
                  }
                }));
          var eventsByDateAndWorker = eventsByWorker.map(function (events) {
                return {
                        application: RCore.$$Option.getExn(RCore.$$Option.map(events[0], (function (e) {
                                    return e.application;
                                  })), undefined),
                        date: date,
                        worker: RCore.$$Option.flatMap(events[0], (function (e) {
                                return e.worker;
                              })),
                        events: events
                      };
              });
          return Belt_Array.concatMany([
                      acc,
                      eventsByDateAndWorker
                    ]);
        }));
  return eventsByDateAndWorker.map(function (dateWorkerEvents, index) {
              return React.createElement(JobForm_HistoryEvents$DayUserEvents, {
                          wire: wire,
                          dateWorkerEvents: dateWorkerEvents,
                          triggerMapEvent: triggerMapEvent,
                          onSelectDate: onSelectDate,
                          allImages: allImages,
                          key: index.toString()
                        });
            });
}

var JobViewedIcon = {
  isVisible: isVisible
};

var make = JobForm_HistoryEvents;

export {
  JobViewedIcon ,
  make ,
}
/* styles Not a pure module */
