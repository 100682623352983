

import * as El from "../../../../../libraries/El.mjs";
import * as Hooks from "../../../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../../../common/Optional.mjs";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as SavePopover from "./SavePopover.mjs";
import * as PopoverModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/Popover/Popover.module.css";
import * as SaveViewModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/index/views/save/SaveView.module.css";

var modalButton$p = Utils.Translations.tr("js.jobs.index.views.save.modal_button");

var styles = SaveViewModuleCss;

var popStyles = PopoverModuleCss;

function SaveView(Props) {
  var views = Props.views;
  var activeView = Props.activeView;
  var createView = Props.createView;
  var saveView = Props.saveView;
  var match = React.useState(function () {
        return false;
      });
  var setShowPopover = match[1];
  var match$1 = AppContext.useWire();
  var popover = React.useRef(null);
  Hooks.useOnClickOutside(undefined, popover, (function () {
          setShowPopover(function (param) {
                return false;
              });
        }));
  var disabled = RCore.$$Option.isSome(activeView);
  var openPopver = function (evt) {
    evt.stopPropagation();
    setShowPopover(function (param) {
          return true;
        });
  };
  return React.createElement("div", {
              className: "JobsViews-save js-view-elements"
            }, React.createElement("button", {
                  className: "JobsViews-save__btn",
                  disabled: disabled,
                  onClick: openPopver
                }, modalButton$p(match$1.ctx)), React.createElement(Optional.make, {
                  show: match[0],
                  children: React.createElement("div", {
                        ref: popover,
                        className: El.Cn.concat(styles.popover, popStyles.popover)
                      }, React.createElement(SavePopover.make, {
                            views: views,
                            createView: (function (name) {
                                createView(name, (function () {
                                        setShowPopover(function (param) {
                                              return false;
                                            });
                                      }));
                              }),
                            saveView: (function (uuid) {
                                saveView(uuid, (function () {
                                        setShowPopover(function (param) {
                                              return false;
                                            });
                                      }));
                              })
                          }))
                }));
}

var make = SaveView;

export {
  make ,
}
/* modalButton' Not a pure module */
