

import * as React from "react";
import * as Optional from "../../../../../../../../common/Optional.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as MapReArrowComponent from "../../../../../../../../common/mapRe/MapReArrowComponent.mjs";
import * as MapRePolylineComponent from "../../../../../../../../common/mapRe/MapRePolylineComponent.mjs";

function JobForm_HistoryMovement(Props) {
  var movement = Props.movement;
  var strokeOpacity = Props.strokeOpacity;
  var mapType = Props.mapType;
  var type_ = Props.type_;
  var onMouseEnter = Props.onMouseEnter;
  var onMouseLeave = Props.onMouseLeave;
  var coordinates = React.useMemo((function () {
          return movement.locations.map(function (l) {
                      return l.geolocation;
                    });
        }), movement.locations);
  var strokeColor;
  strokeColor = type_ === "Movement" ? "#e75f85" : "#4a4a4a";
  var tmp = {
    mapType: mapType,
    coordinates: coordinates,
    displayIcon: type_ === "Movement",
    strokeWidth: 5.0,
    strokeColor: strokeColor,
    strokeOpacity: strokeOpacity
  };
  if (onMouseEnter !== undefined) {
    tmp.onMouseEnter = Caml_option.valFromOption(onMouseEnter);
  }
  if (onMouseLeave !== undefined) {
    tmp.onMouseLeave = Caml_option.valFromOption(onMouseLeave);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Optional.make, {
                  show: mapType === "Yandex" && type_ === "Movement",
                  children: React.createElement(MapReArrowComponent.make, {
                        coordinates: coordinates,
                        strokeColor: strokeColor
                      })
                }), React.createElement(MapRePolylineComponent.make, tmp));
}

var make = JobForm_HistoryMovement;

export {
  make ,
}
/* react Not a pure module */
