

import * as Uuid from "../bindings/uuid/Uuid.mjs";
import * as RCore from "../libraries/RCore.mjs";
import * as Locale from "../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as MomentRe from "../bindings/moment/MomentRe.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Context_Types from "../context/Context_Types.mjs";
import * as Assignee_Types from "./Assignee_Types.mjs";
import * as MultipleAssignee from "./MultipleAssignee.mjs";

function withActualTempWorkers(assignees, availableAt, allAssignees) {
  return assignees.map(function (a) {
              if (a.TAG !== "Team") {
                return {
                        TAG: "Worker",
                        _0: a._0
                      };
              }
              var t = a._0;
              var match = RCore.$$Array.getBy(allAssignees, (function (a) {
                      if (a.TAG === "Team") {
                        return Uuid.equal(a._0.uuid, t.uuid);
                      } else {
                        return false;
                      }
                    }));
              if (availableAt !== undefined && match !== undefined) {
                var at = Caml_option.valFromOption(availableAt);
                if (match.TAG === "Team") {
                  return {
                          TAG: "Team",
                          _0: {
                            uuid: t.uuid,
                            name: t.name,
                            foreman: t.foreman,
                            workers: RCore.$$Array.keep(t.workers, (function (w) {
                                    return w.permanent;
                                  })),
                            temporaryWorkers: RCore.$$Array.keepMap(match._0.temporaryWorkers, (function (w) {
                                    if (Locale.T.equalOrLessThan(Locale.T.startOf("day", MomentRe.makeFromJsDate(w.dateFrom)), at) && Locale.T.moreThan(Locale.T.endOf("day", MomentRe.makeFromJsDate(w.dateTo)), at)) {
                                      return w;
                                    }
                                    
                                  })),
                            skills: t.skills,
                            territory: t.territory
                          }
                        };
                }
                
              }
              return {
                      TAG: "Team",
                      _0: {
                        uuid: t.uuid,
                        name: t.name,
                        foreman: t.foreman,
                        workers: t.workers,
                        temporaryWorkers: [],
                        skills: t.skills,
                        territory: t.territory
                      }
                    };
            });
}

function draggableForAssignee(assignees, bar, features) {
  if (Caml_obj.equal(assignees, []) || Context_Types.Features.hasFlag("showOldTimeline", features)) {
    return true;
  }
  var match = RCore.$$Array.getBy(assignees, (function (a) {
          if (MultipleAssignee.isTeam(a)) {
            return MultipleAssignee.canEdit(a);
          } else {
            return false;
          }
        }));
  if (match !== undefined) {
    if (bar !== undefined && Caml_obj.equal(MultipleAssignee.uuid(match), Assignee_Types.Assignee.uuid(bar))) {
      return true;
    } else {
      return false;
    }
  }
  if (bar === undefined) {
    return false;
  }
  var editAssignee = RCore.$$Array.getBy(assignees, (function (a) {
          return MultipleAssignee.canEdit(a);
        }));
  if (editAssignee !== undefined && Caml_obj.equal(MultipleAssignee.uuid(editAssignee), Assignee_Types.Assignee.uuid(bar))) {
    return true;
  } else {
    return false;
  }
}

export {
  withActualTempWorkers ,
  draggableForAssignee ,
}
/* Uuid Not a pure module */
