

import * as Uuid from "../../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Radio from "../../../../../inputs/Radio.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../../../../../common/Optional.mjs";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as JobViewTypes from "../../../views/JobViewTypes.mjs";
import * as JobIndexFilter_Checkbox from "../../common/JobIndexFilter_Checkbox.mjs";

var allResolutions$p = Utils.Translations.t("js.jobs.index.resolutions.select.all");

var deselectAll$p = Utils.Translations.tr("js.jobs.index.filter.deselect_all");

var selectAll$p = Utils.Translations.tr("js.jobs.index.filter.select_all");

var successfulResolution$p = Utils.Translations.t("js.jobs.index.resolutions.select.successful");

var unsuccessfulResolution$p = Utils.Translations.t("js.jobs.index.resolutions.select.unsuccessful");

var youselfResolution$p = Utils.Translations.t("js.jobs.index.resolutions.select.youself");

var filterSuccessful$p = Utils.Translations.tr("js.jobs.index.filters.successful");

var filterUnsuccessful$p = Utils.Translations.tr("js.jobs.index.filters.unsuccessful");

function JobIndexFilter_Resolutions(Props) {
  var activeView = Props.activeView;
  var status = Props.status;
  var resolutions = Props.resolutions;
  var successfulResolutions = Props.successfulResolutions;
  var unsuccessfulResolutions = Props.unsuccessfulResolutions;
  var currentResolutions = Props.currentResolutions;
  var resetResolutions = Props.resetResolutions;
  var appendResolutions = Props.appendResolutions;
  var filterByResolution = Props.filterByResolution;
  var filterDisabled = Props.filterDisabled;
  var selectSuccessfulResolutions = Props.selectSuccessfulResolutions;
  var selectUnsuccessfulResolutions = Props.selectUnsuccessfulResolutions;
  var selectAllResolutions = Props.selectAllResolutions;
  var match = React.useState(function () {
        return "SelectAll";
      });
  var setSelectedOption = match[1];
  var selectedOption = match[0];
  var match$1 = AppContext.useWire();
  var ctx = match$1.ctx;
  var renderSelectButton = function (resolutions, handleSelect, handleDeselect) {
    if (resolutions.length <= 3) {
      return null;
    }
    var numberOfSelected = RCore.$$Array.filterMap(resolutions, (function (s) {
            return RCore.$$Array.getBy(currentResolutions, (function (f) {
                          return Uuid.equal(f, s.uuid);
                        }));
          })).length;
    if (resolutions.length === numberOfSelected) {
      return React.createElement("button", {
                  className: "j-filter__reset",
                  onClick: handleDeselect
                }, deselectAll$p(ctx));
    } else {
      return React.createElement("button", {
                  className: "j-filter__reset",
                  onClick: handleSelect
                }, selectAll$p(ctx));
    }
  };
  var renderResolutions = function (resolutions) {
    return resolutions.map(function (r) {
                var checked = currentResolutions.some(function (f) {
                      return Uuid.equal(f, r.uuid);
                    });
                return React.createElement(JobIndexFilter_Checkbox.make, {
                            name: r.name,
                            onToggle: (function (param) {
                                filterByResolution(r.uuid, status, !checked);
                              }),
                            checked: checked,
                            disabled: filterDisabled,
                            key: Uuid.toString(r.uuid)
                          });
              });
  };
  var resolutionListClasses = selectedOption === "SelectYourself" ? "j-filter__item" : "j-filter__item is-hidden";
  var radioButton = function (text, option) {
    return React.createElement(Radio.make, {
                onChange: (function (param) {
                    switch (option) {
                      case "SelectSuccessful" :
                          selectSuccessfulResolutions();
                          break;
                      case "SelectUnsuccessful" :
                          selectUnsuccessfulResolutions(status);
                          break;
                      case "SelectAll" :
                      case "SelectYourself" :
                          selectAllResolutions(status);
                          break;
                      
                    }
                    setSelectedOption(function (param) {
                          return option;
                        });
                  }),
                checked: selectedOption === option,
                name: "filter_resolutions-" + JobViewTypes.Status.toString(status),
                labelText: text,
                wrapperClass: "j-filter__option",
                labelClass: "j-radio",
                disabled: filterDisabled
              });
  };
  var radioButtonOptions = React.createElement(React.Fragment, undefined, radioButton(allResolutions$p(ctx), "SelectAll"), React.createElement(Optional.make, {
            show: status === "Finished",
            children: null
          }, radioButton(successfulResolution$p(ctx), "SelectSuccessful"), radioButton(unsuccessfulResolution$p(ctx), "SelectUnsuccessful")), radioButton(youselfResolution$p(ctx), "SelectYourself"));
  var resolutionOptions = React.createElement(React.Fragment, undefined, React.createElement(Optional.make, {
            show: status === "Finished",
            children: null
          }, React.createElement("div", {
                className: "j-filter__name_section"
              }, filterSuccessful$p(ctx), renderSelectButton(successfulResolutions, (function (param) {
                      appendResolutions("Finished", "Successful");
                    }), (function (param) {
                      resetResolutions("Finished", "Successful");
                    }))), renderResolutions(successfulResolutions)), React.createElement("div", {
            className: "j-filter__name_section"
          }, filterUnsuccessful$p(ctx), renderSelectButton(unsuccessfulResolutions, (function (param) {
                  appendResolutions(status, "Unsuccessful");
                }), (function (param) {
                  resetResolutions(status, "Unsuccessful");
                }))), renderResolutions(unsuccessfulResolutions));
  React.useEffect((function () {
          if (currentResolutions.length === resolutions.length || currentResolutions.length === 0) {
            setSelectedOption(function (param) {
                  return "SelectAll";
                });
          } else {
            var sortedSuccessfulResolutions = successfulResolutions.map(function (r) {
                  return r.uuid;
                });
            var sortedUnsuccessfulResolutions = unsuccessfulResolutions.map(function (r) {
                  return r.uuid;
                });
            if (RCore.$$Array.equal(currentResolutions, sortedSuccessfulResolutions, Caml_obj.equal)) {
              setSelectedOption(function (param) {
                    return "SelectSuccessful";
                  });
            } else if (RCore.$$Array.equal(currentResolutions, sortedUnsuccessfulResolutions, Caml_obj.equal)) {
              setSelectedOption(function (param) {
                    return "SelectUnsuccessful";
                  });
            } else {
              setSelectedOption(function (param) {
                    return "SelectYourself";
                  });
            }
          }
        }), [activeView]);
  React.useEffect((function () {
          if (currentResolutions.length === 0) {
            setSelectedOption(function (param) {
                  return "SelectAll";
                });
          }
          
        }), [currentResolutions]);
  return React.createElement("div", {
              className: "j-filter__item"
            }, React.createElement("div", {
                  className: "j-filter__list"
                }, radioButtonOptions, React.createElement("div", {
                      className: resolutionListClasses
                    }, React.createElement("div", {
                          className: "j-filter__list"
                        }, resolutionOptions))));
}

var make = JobIndexFilter_Resolutions;

export {
  make ,
}
/* allResolutions' Not a pure module */
