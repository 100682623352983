

import * as Wire from "../../../../../../types/Wire.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Assignee_Types from "../../../../../../types/Assignee_Types.mjs";
import * as Schedule_Types_JobCore from "../../../../lib/types/job/Schedule_Types_JobCore.mjs";
import * as WeekCalendar_Constants from "../../lib/WeekCalendar_Constants.mjs";
import * as Webapi__Dom__HtmlElement from "rescript-webapi/lib/es6/src/Webapi/Dom/Webapi__Dom__HtmlElement.mjs";
import * as WeekCalendar_DragLayerModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_calendar/components/drag_layer/WeekCalendar_DragLayer.module.css";

var styles = WeekCalendar_DragLayerModuleCss;

function setJobDimensions(columnWidth, param, param$1) {
  var style = RCore.$$Option.map(Webapi__Dom__HtmlElement.ofElement(param.jobEl), (function (prim) {
          return prim.style;
        }));
  if (style === undefined) {
    return ;
  }
  var style$1 = Caml_option.valFromOption(style);
  var height = WeekCalendar_Constants.sizeOfMinutePx * param$1.duration;
  style$1.setProperty("height", height.toString() + "px");
  style$1.setProperty("width", columnWidth.toString() + "px");
}

function setJobPosition(param, param$1) {
  var style = RCore.$$Option.map(Webapi__Dom__HtmlElement.ofElement(param.jobEl), (function (prim) {
          return prim.style;
        }));
  if (style === undefined) {
    return ;
  }
  var x = Units.Px.toString(param$1[0]);
  var y = Units.Px.toString(param$1[1]);
  Caml_option.valFromOption(style).setProperty("transform", "translate(" + x + ", " + y + ")");
}

var noAssignee$p = Utils.Translations.t("js.schedule.v2.job.without_assignee");

function setJobDescription(wire, param, param$1) {
  var bar = param$1.bar;
  var assigneeEl = param.assigneeEl;
  var jobEl = param.jobEl;
  var address = RCore.$$Option.mapWithDefault(param$1.address, "", Schedule_Types_JobCore.Address.toString);
  var template = RCore.$$Option.mapWithDefault(param$1.template, "", (function (param) {
          return param.name;
        }));
  param.addressEl.textContent = address;
  param.templateEl.textContent = template;
  if (bar !== undefined) {
    jobEl.classList.remove(styles.notPublished);
    assigneeEl.classList.remove(styles.noAssignee);
    assigneeEl.textContent = Assignee_Types.Assignee.name(bar.assignee);
  } else {
    jobEl.classList.add(styles.notPublished);
    assigneeEl.classList.add(styles.noAssignee);
    assigneeEl.textContent = noAssignee$p(Wire.ctx(wire));
  }
}

function showDragLayer(wire, columnWidth, elements, payload, dragPreviewOffset) {
  var containerEl = elements.containerEl;
  setJobDimensions(columnWidth, elements, payload);
  setJobPosition(elements, dragPreviewOffset);
  setJobDescription(wire, elements, payload);
  if (!containerEl.classList.contains(styles.visible)) {
    containerEl.classList.add(styles.visible);
    return ;
  }
  
}

function hideDragLayer(param) {
  var containerEl = param.containerEl;
  if (containerEl.classList.contains(styles.visible)) {
    containerEl.classList.remove(styles.visible);
    return ;
  }
  
}

export {
  setJobPosition ,
  showDragLayer ,
  hideDragLayer ,
}
/* styles Not a pure module */
