

import * as Utils from "../../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as AppContext from "../../../../../../../../context/AppContext.mjs";
import * as WeekTimeline_UnassignedUnassignBlockModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/unassigned/components/unassign_block/WeekTimeline_UnassignedUnassignBlock.module.css";

var drop$p = Utils.Translations.tr("js.schedule.v2.jobs.drop");

var styles = WeekTimeline_UnassignedUnassignBlockModuleCss;

function WeekTimeline_UnassignedUnassignBlock(Props) {
  var match = AppContext.useWire();
  return React.createElement("div", {
              className: styles.container
            }, React.createElement("span", {
                  className: styles.icon
                }), drop$p(match.ctx));
}

var make = WeekTimeline_UnassignedUnassignBlock;

export {
  make ,
}
/* drop' Not a pure module */
