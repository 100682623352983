

import * as Wire from "../../../../../../../../../types/Wire.mjs";
import * as RCore from "../../../../../../../../../libraries/RCore.mjs";
import * as Units from "../../../../../../../../../types/Units.mjs";
import * as React from "react";
import * as Locale from "../../../../../../../../../libraries/Locale.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppContext from "../../../../../../../../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Assignee_Types from "../../../../../../../../../types/Assignee_Types.mjs";
import * as MultipleAssignee from "../../../../../../../../../types/MultipleAssignee.mjs";
import * as Schedule_Types_Job from "../../../../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as WeekTimeline_Types from "../../../../../lib/types/WeekTimeline_Types.mjs";
import * as WeekTimeline_Constants from "../../../../../lib/WeekTimeline_Constants.mjs";

function use(assignee, period, sizes, jobs) {
  var wire = AppContext.useWire();
  var match = React.useState(function () {
        
      });
  var setBlocks = match[1];
  React.useEffect((function () {
          var match = Wire.businessHours(wire);
          if (match === undefined) {
            return ;
          }
          if (!Caml_obj.notequal(jobs, [])) {
            return ;
          }
          var finish = match.finish;
          var start = match.start;
          var startOfDayBlocks = {};
          var finishOfDayBlocks = {};
          var jobs$1 = RCore.$$Array.keep(jobs, (function (job) {
                  return Schedule_Types_Job.AssignedJob.assignees(job).some(function (a) {
                              return Caml_obj.equal(MultipleAssignee.uuid(a), Assignee_Types.Assignee.uuid(assignee));
                            });
                }));
          RCore.$$Array.make(WeekTimeline_Constants.daysInWeek, period[0]).forEach(function (date, index) {
                var startOfDay = Locale.T.nextDays(index)(date);
                var endOfDay = Locale.T.endOf("day", startOfDay);
                var startOfBusinessHours = Locale.T.add(start, startOfDay);
                var endOfBusinessHours = Locale.T.add(finish, startOfDay);
                var morning = [
                  startOfDay,
                  startOfBusinessHours
                ];
                var evening = [
                  endOfBusinessHours,
                  endOfDay
                ];
                var key = Locale.T.fmtM("Date", wire)(startOfDay);
                jobs$1.forEach(function (job) {
                      var jobInterval_0 = Schedule_Types_Job.AssignedJob.startAt(job);
                      var jobInterval_1 = Schedule_Types_Job.AssignedJob.finishAt(job);
                      var jobInterval = [
                        jobInterval_0,
                        jobInterval_1
                      ];
                      if (Locale.T.intersectIntervalStrict(morning, jobInterval)) {
                        var match = Js_dict.get(startOfDayBlocks, key);
                        if (match !== undefined) {
                          startOfDayBlocks[key] = [
                            match[0],
                            WeekTimeline_Types.BlockStatus.mapJobStatus(match[1], job)
                          ];
                        } else {
                          var left = (function (__x) {
                                return Units.Px.multiplyWithInt(sizes.day, __x);
                              })(Locale.T.isoWeekday(startOfDay));
                          startOfDayBlocks[key] = [
                            left,
                            WeekTimeline_Types.BlockStatus.mapJobStatus(undefined, job)
                          ];
                        }
                      }
                      if (!Locale.T.intersectIntervalStrict(evening, jobInterval)) {
                        return ;
                      }
                      var match$1 = Js_dict.get(finishOfDayBlocks, key);
                      if (match$1 !== undefined) {
                        finishOfDayBlocks[key] = [
                          match$1[0],
                          WeekTimeline_Types.BlockStatus.mapJobStatus(match$1[1], job)
                        ];
                        return ;
                      }
                      var left$1 = Units.Px.minus(Units.Px.plus((function (__x) {
                                    return Units.Px.multiplyWithInt(sizes.day, __x);
                                  })(Locale.T.isoWeekday(startOfDay)), sizes.day), Units.Px.fromInt(5));
                      finishOfDayBlocks[key] = [
                        left$1,
                        WeekTimeline_Types.BlockStatus.mapJobStatus(undefined, job)
                      ];
                    });
              });
          var blocks = Belt_Array.concatMany([
                  Js_dict.entries(startOfDayBlocks),
                  Js_dict.entries(finishOfDayBlocks)
                ]).map(function (param) {
                return param[1];
              });
          if (Caml_obj.notequal(blocks, [])) {
            setBlocks(function (param) {
                  return blocks;
                });
          } else {
            setBlocks(function (param) {
                  
                });
          }
        }), [
        period,
        sizes,
        jobs
      ]);
  return match[0];
}

export {
  use ,
}
/* Wire Not a pure module */
