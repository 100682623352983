import { loadScript } from 'planado/utils/index.js'
import { stringify } from 'qs'
import { gmapsPromise } from 'planado/globals'

const successApiLoaded = '_$_api_success'
const url = ({ ctx, apiKey = '', ...rest }) => {
  const params = {
    callback: successApiLoaded,
    key: apiKey,
    language: ctx.localizator.lang,
    libraries: 'places',
    ...rest
  }

  return `//maps.googleapis.com/maps/api/js?${stringify(params)}`
}

let loadApiPromise = null
const loadApi = options => {
  if (loadApiPromise !== null) {
    return loadApiPromise
  } else {
    loadApiPromise = new Promise(resolve => {
      window[successApiLoaded] = gmaps => {
        resolve(gmaps)
        window[successApiLoaded] = null
      }

      loadScript(url(options))
    })

    return loadApiPromise
  }
}

class Gmaps {
  constructor() {
    this.api =
      typeof window !== undefined && window.google && window.google.maps
        ? window.google.maps
        : null
  }

  getApi() {
    return this.api
  }

  getCoords([lat, lng]) {
    return new this.api.LatLng(lat, lng)
  }

  getSize([width, height]) {
    return new this.api.Size(width, height)
  }

  isAvailible() {
    return this.api !== null
  }

  load(options = {}) {
    if (window.gmapsRequested) {
      return gmapsPromise.then(() => (this.api = window.google.maps))
    } else {
      return loadApi(options).then(() => (this.api = window.google.maps))
    }
  }
}

export default new Gmaps()
