export const CONTACT_ADD = 'planado/contacts/CONTACT_ADD'
export const CONTACT_REMOVE = 'planado/contacts/CONTACT_REMOVE'
export const CONTACT_EDIT = 'planado/contacts/CONTACT_EDIT'

export const addContact = () => ({
  type: CONTACT_ADD
})

export const removeContact = index => ({
  type: CONTACT_REMOVE,
  index
})

export const editContact = (index, value) => ({
  type: CONTACT_EDIT,
  index,
  value
})
