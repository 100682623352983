

import * as Case from "../../../utils/Case.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Errors from "../../../Errors.mjs";
import * as $$String from "rescript/lib/es6/string.js";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as UserTypes from "./UserTypes.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as EncodeJson from "../../../libraries/EncodeJson.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.mjs";
import * as Js_undefined from "rescript/lib/es6/js_undefined.js";
import * as Context_Types from "../../../context/Context_Types.mjs";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as Pages_Custom_Fields_Types from "../../../pages/custom_fields/Pages_Custom_Fields_Types.mjs";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";

function index(wire) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "User",
                    VAL: "Index"
                  }
                }), UserTypes.Index.decode);
}

var Index = {
  index: index
};

var decode = JsonDecode.object(function (field) {
      var perm = JsonDecode.fromVariant(JsonDecode.string, UserTypes.$$Permissions.Permission.fromString);
      return {
              jobs: field.required("readWriteJobs", perm),
              schedule: field.required("readWriteSchedule", perm),
              clients: field.required("readWriteClients", perm),
              teams: field.required("readWriteTeams", perm),
              loginWeb: field.required("loginWeb", JsonDecode.bool),
              map: field.required("map", JsonDecode.bool),
              shifts: field.required("shifts", JsonDecode.bool),
              admin: field.required("admin", JsonDecode.bool),
              loginMobile: field.required("loginMobile", JsonDecode.bool),
              createJobsMobile: field.required("createJobsMobile", JsonDecode.bool),
              completeJobs: field.required("completeJobs", JsonDecode.bool),
              jobsExport: field.required("jobsExport", JsonDecode.bool)
            };
    });

var $$Permissions = {
  decode: decode
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var Skill = {
  decode: decode$1
};

var decode$2 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var Team = {
  decode: decode$2
};

function new_(wire) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "User",
                    VAL: {
                      NAME: "Custom",
                      VAL: "New"
                    }
                  }
                }), UserTypes.New.decode);
}

function show(wire, uuid) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "User",
                    VAL: {
                      NAME: "Custom",
                      VAL: {
                        NAME: "Edit",
                        VAL: uuid
                      }
                    }
                  }
                }), UserTypes.Show.decode);
}

function destroy(wire, uuid) {
  return Backend.$$delete(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "User",
                VAL: {
                  NAME: "Custom",
                  VAL: {
                    NAME: "Edit",
                    VAL: uuid
                  }
                }
              }
            });
}

function rejectEmpty(field) {
  switch (field.dataType) {
    case "Integer" :
        if (field.integerValue !== undefined) {
          return field;
        } else {
          return ;
        }
    case "Decimal" :
        if (field.decimalValue !== undefined) {
          return field;
        } else {
          return ;
        }
    case "Attachment" :
        if (field.attachmentValues !== undefined) {
          return field;
        } else {
          return ;
        }
    case "Boolean" :
        if (field.booleanValue !== undefined) {
          return field;
        } else {
          return ;
        }
    case "Currency" :
        if (field.currencyValue !== undefined) {
          return field;
        } else {
          return ;
        }
    case "String" :
        if (field.stringValue !== undefined) {
          return field;
        } else {
          return ;
        }
    case "Dictionary" :
        if (field.dictionaryValueUuid !== undefined) {
          return field;
        } else {
          return ;
        }
    
  }
}

function encodeAttachmentValue(value) {
  return EncodeJson.object([[
                "uuid",
                EncodeJson.uuid(value.uuid)
              ]]);
}

function encode(field) {
  var match = field.dataType;
  var tmp;
  switch (match) {
    case "Integer" :
        tmp = [[
            "integerValue",
            EncodeJson.optionString(field.integerValue)
          ]];
        break;
    case "Decimal" :
        tmp = [[
            "decimalValue",
            EncodeJson.optionString(field.decimalValue)
          ]];
        break;
    case "Attachment" :
        tmp = [[
            "attachmentValues",
            EncodeJson.jsonArray(RCore.$$Option.getOr(RCore.$$Option.map(field.attachmentValues, (function (a) {
                            return a.map(encodeAttachmentValue);
                          })), []))
          ]];
        break;
    case "Boolean" :
        tmp = [[
            "booleanValue",
            EncodeJson.optionBool(field.booleanValue)
          ]];
        break;
    case "Currency" :
        tmp = [
          [
            "currencyValue",
            EncodeJson.optionString(field.currencyValue)
          ],
          [
            "currency",
            EncodeJson.optionString(field.currency)
          ],
          [
            "useCurrencyFractionalUnit",
            EncodeJson.optionBool(field.useCurrencyFractionalUnit)
          ]
        ];
        break;
    case "String" :
        tmp = [[
            "stringValue",
            EncodeJson.optionString(field.stringValue)
          ]];
        break;
    case "Dictionary" :
        tmp = [
          [
            "dictionaryUuid",
            EncodeJson.optionUuid(field.dictionaryUuid)
          ],
          [
            "dictionaryValueUuid",
            EncodeJson.optionUuid(field.dictionaryValueUuid)
          ]
        ];
        break;
    
  }
  return EncodeJson.object(Belt_Array.concatMany([
                  [
                    [
                      "typeUuid",
                      EncodeJson.uuid(field.typeUuid)
                    ],
                    [
                      "dataType",
                      EncodeJson.string(Pages_Custom_Fields_Types.DataType.toString(field.dataType))
                    ],
                    [
                      "fieldType",
                      EncodeJson.string(Pages_Custom_Fields_Types.FieldType.toString(field.fieldType))
                    ]
                  ],
                  tmp
                ]));
}

function encode$1(data, ctx) {
  return {
          firstName: Js_null.fromOption(RCore.$$Option.map(data.firstName, $$String.trim)),
          lastName: Js_null.fromOption(RCore.$$Option.map(data.lastName, $$String.trim)),
          email: Js_null.fromOption(data.email),
          mobilePhone: Js_null.fromOption(data.mobilePhone),
          timezone: Js_null.fromOption(data.timezone),
          password: data.password,
          territoryUuid: Context_Types.Features.hasFlag("territories", ctx.features) ? Caml_option.some(Js_null.fromOption(RCore.$$Option.map(data.territoryUuid, Uuid.toString))) : undefined,
          skillUuids: data.skillUuids.map(Uuid.toString),
          permissions: data.permissions,
          customFields: EncodeJson.jsonArray(RCore.$$Array.keepMap(data.customFieldValues, rejectEmpty).map(encode))
        };
}

function encodeReadWritePermission(permission) {
  switch (permission) {
    case "Read" :
        return "read";
    case "Write" :
        return "write";
    case "Delete" :
        return "delete";
    case "Denied" :
        return "denied";
    
  }
}

function encode$2(data, ctx) {
  return {
          readWriteClients: encodeReadWritePermission(data.permissions.clients),
          readWriteJobs: encodeReadWritePermission(data.permissions.jobs),
          readWriteSchedule: encodeReadWritePermission(data.permissions.schedule),
          readWriteTeams: encodeReadWritePermission(data.permissions.teams),
          user: encode$1(data, ctx)
        };
}

function submitShow(wire, uuid, data) {
  return Backend.patch(undefined, undefined, undefined, true, wire, {
              NAME: "API",
              VAL: {
                NAME: "User",
                VAL: {
                  NAME: "Custom",
                  VAL: {
                    NAME: "Edit",
                    VAL: uuid
                  }
                }
              }
            }, {
              NAME: "Obj",
              VAL: encode$2(data, wire.ctx)
            });
}

function submitNew(wire, data) {
  return Backend.post(undefined, undefined, undefined, true, wire, {
              NAME: "API",
              VAL: {
                NAME: "User",
                VAL: {
                  NAME: "Custom",
                  VAL: "Create"
                }
              }
            }, {
              NAME: "Obj",
              VAL: encode$2(data, wire.ctx)
            });
}

var decodeUserField = JsonDecode.object(function (field) {
      return {
              firstName: field.required("firstName", JsonDecode.string),
              lastName: field.required("lastName", JsonDecode.string)
            };
    });

var decode$3 = JsonDecode.object(function (field) {
      return field.required("data", JsonDecode.object(function (field) {
                      return field.required("form", decodeUserField);
                    }));
    });

var emptyErrors_email = [];

var emptyErrors_firstName = [];

var emptyErrors_lastName = [];

var emptyErrors_mobilePhone = [];

var emptyErrors = {
  email: emptyErrors_email,
  firstName: emptyErrors_firstName,
  lastName: emptyErrors_lastName,
  mobilePhone: emptyErrors_mobilePhone
};

var decode$4 = JsonDecode.object(function (field) {
      return field.required("errors", JsonDecode.object(function (field) {
                      return {
                              email: RCore.$$Option.getOr(field.optional("email", JsonDecode.array(JsonDecode.string)), []),
                              firstName: [],
                              lastName: [],
                              mobilePhone: RCore.$$Option.getOr(field.optional("mobilePhone", JsonDecode.array(JsonDecode.string)), [])
                            };
                    }));
    });

function errorsFromString(json) {
  return Core__Result.getOr(Json_Decode$JsonCombinators.decode(Case.camelize(json), decode$4), emptyErrors);
}

var UnprocessableEntity = {
  emptyErrors: emptyErrors,
  decode: decode$4,
  errorsFromString: errorsFromString
};

function make(user) {
  var match = user.notificationMethod;
  var notificationMethod;
  notificationMethod = match === "Sms" ? "sms" : "email";
  var email = Js_undefined.fromOption(user.notificationMethod === "Email" ? user.email : undefined);
  var mobilePhone = Js_undefined.fromOption(user.notificationMethod === "Sms" ? user.mobilePhone : undefined);
  var territoryUuid = user.territoryCheckbox ? Js_null_undefined.fromOption(user.territoryUuid) : undefined;
  return {
          email: email,
          firstName: user.firstName,
          lastName: user.lastName,
          notificationMethod: notificationMethod,
          mobilePhone: mobilePhone,
          canCompleteJobs: user.canCompleteJobs,
          canCreateJobs: user.canCreateJobs,
          skillUuids: user.skillsCheckbox ? user.skillUuids : [],
          territoryUuid: territoryUuid
        };
}

function make$1(user) {
  var match = user.notificationMethod;
  var notificationMethod;
  notificationMethod = match === "Sms" ? "sms" : "email";
  var sendAgain = user.sendAgain;
  var notificationMethod$1 = Js_undefined.fromOption(sendAgain ? notificationMethod : undefined);
  var email = Js_undefined.fromOption(sendAgain && user.notificationMethod === "Email" ? user.email : undefined);
  var mobilePhone = Js_undefined.fromOption(sendAgain && user.notificationMethod === "Sms" ? user.mobilePhone : undefined);
  var territoryUuid = user.territoryCheckbox ? Js_null_undefined.fromOption(user.territoryUuid) : undefined;
  return {
          email: email,
          firstName: user.firstName,
          lastName: user.lastName,
          notificationMethod: notificationMethod$1,
          mobilePhone: mobilePhone,
          canCompleteJobs: user.canCompleteJobs,
          canCreateJobs: user.canCreateJobs,
          skillUuids: user.skillsCheckbox ? user.skillUuids : [],
          territoryUuid: territoryUuid,
          sendAgain: user.sendAgain
        };
}

var decodeEntity = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

function notificationFromString(notificationMethod) {
  switch (notificationMethod) {
    case "email" :
        return "Email";
    case "sms" :
        return "Sms";
    default:
      return ;
  }
}

var decode$5 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              firstName: field.required("firstName", JsonDecode.string),
              lastName: field.required("lastName", JsonDecode.string),
              email: RCore.$$Option.getOr(field.required("email", JsonDecode.option(JsonDecode.string)), ""),
              mobilePhone: RCore.$$Option.getOr(field.required("mobilePhone", JsonDecode.option(JsonDecode.string)), ""),
              canCompleteJobs: field.required("canCompleteJobs", JsonDecode.bool),
              canCreateJobs: field.required("canCreateJobs", JsonDecode.bool),
              latestNotificationMethod: field.required("latestNotificationMethod", JsonDecode.fromVariant(JsonDecode.string, notificationFromString)),
              latestNotificationSendAt: field.required("latestNotificationSendAt", JsonDecode.option(JsonDecode.string)),
              skillUuids: field.required("skillUuids", JsonDecode.array(Uuid.decode)),
              territoryUuid: field.required("territoryUuid", JsonDecode.option(Uuid.decode)),
              createdAt: field.required("createdAt", JsonDecode.string)
            };
    });

var UserInvitation = {
  notificationFromString: notificationFromString,
  decode: decode$5
};

var decode$6 = JsonDecode.object(function (field) {
      return {
              userInvitation: field.optional("userInvitation", decode$5),
              licenseAvailable: field.required("licenseAvailable", JsonDecode.bool),
              availableTerritories: field.required("availableTerritories", JsonDecode.array(decodeEntity))
            };
    });

function new_$1(wire) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "User",
                    VAL: {
                      NAME: "Field",
                      VAL: "New"
                    }
                  }
                }), decode$6);
}

function edit(wire, uuid) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "User",
                    VAL: {
                      NAME: "Field",
                      VAL: {
                        NAME: "Edit",
                        VAL: uuid
                      }
                    }
                  }
                }), decode$6);
}

function create(wire, user) {
  return Backend.decode(Backend.post(undefined, undefined, undefined, true, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "User",
                    VAL: {
                      NAME: "Field",
                      VAL: "Create"
                    }
                  }
                }, {
                  NAME: "Obj",
                  VAL: make(user)
                }), Json_Decode$JsonCombinators.custom(function (param) {
                  
                }));
}

function update(wire, uuid, user) {
  return Backend.decode(Backend.patch(undefined, undefined, undefined, true, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "User",
                    VAL: {
                      NAME: "Field",
                      VAL: {
                        NAME: "Edit",
                        VAL: uuid
                      }
                    }
                  }
                }, {
                  NAME: "Obj",
                  VAL: make$1(user)
                }), Json_Decode$JsonCombinators.custom(function (param) {
                  
                }));
}

function destroy$1(wire, uuid) {
  return Backend.decode(Backend.$$delete(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "User",
                    VAL: {
                      NAME: "Field",
                      VAL: {
                        NAME: "Edit",
                        VAL: uuid
                      }
                    }
                  }
                }), Json_Decode$JsonCombinators.custom(function (param) {
                  
                }));
}

function decode$7(text) {
  return RCore.$$Array.filterMap(Core__List.toArray(Errors.parse(text)), UserTypes.$$Error.fromPair);
}

var Errors$1 = {
  decode: decode$7
};

var Edit = {
  $$Permissions: $$Permissions,
  Skill: Skill,
  Team: Team,
  new_: new_,
  show: show,
  destroy: destroy,
  submitShow: submitShow,
  submitNew: submitNew
};

var FieldUserForm_UserName = {
  decode: decode$3
};

var FieldUserForm_Get = {
  $$Response: {
    UserInvitation: UserInvitation
  }
};

var FieldUserForm = {
  UserName: FieldUserForm_UserName,
  UnprocessableEntity: UnprocessableEntity,
  Get: FieldUserForm_Get,
  new_: new_$1,
  edit: edit,
  create: create,
  update: update,
  destroy: destroy$1
};

export {
  Index ,
  Edit ,
  FieldUserForm ,
  Errors$1 as Errors,
}
/* decode Not a pure module */
