

import * as Backend from "../libraries/backend/Backend.mjs";

function store(handleResponse, items, wire) {
  (
      handleResponse !== undefined ? handleResponse : (function (prim) {
            
          })
    )(Backend.post(undefined, true, undefined, undefined, wire, {
            NAME: "API",
            VAL: "UsedResources"
          }, {
            NAME: "Obj",
            VAL: {
              resources: items.map(function (r) {
                    return {
                            resource_type: r.resourceType,
                            unit: r.unit,
                            diff: r.diff
                          };
                  })
            }
          }));
}

export {
  store ,
}
/* Backend Not a pure module */
