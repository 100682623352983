// extracted by mini-css-extract-plugin
export var actionColumn = "action-column-xIC0UAAALxcCfdMV1DDb";
export var createdAtColumn = "created-at-column-ELU8wXpGOYYXskeVaFMO";
export var detailsColumn = "details-column-Z8W6WDYCMTUoD82klYyz";
export var idButton = "id-button-k4YvFRzYiN3Nk0c6BU0D";
export var idColumn = "id-column-VI3lDkJ0Ki8VG075wZwK";
export var idTextArea = "id-text-area-dDxX76isaSdpJksRZZxU";
export var ipColumn = "ip-column-GacIQbFwaEnk5PtEcQcL";
export var tableHeader = "table-header-FgiOxYsUtdgXZgxU_7nl";
export var tableRow = "table-row-Ee0ETTpiKQ9phDmEdkEQ";
export var userAgentColumn = "user-agent-column-vcvY9CyhQ9jlo4D7Z290";
export var userColumn = "user-column-QNtEpMTFqyD1psq3zSOq";