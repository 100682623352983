

import * as Uuid from "../../../../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../../../types/Types.mjs";
import * as Units from "../../../../../../../../types/Units.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Schedule_Types_Job from "../../../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as WeekTimeline_Constants from "../../../../lib/WeekTimeline_Constants.mjs";
import * as WeekTimeline_UnassignedJob from "../job/WeekTimeline_UnassignedJob.mjs";
import * as WeekTimeline_UnassignedEmptyBlock from "../empty_block/WeekTimeline_UnassignedEmptyBlock.mjs";
import * as WeekTimeline_UnassignedContentHooks from "./WeekTimeline_UnassignedContentHooks.mjs";
import * as WeekTimeline_UnassignedUnassignBlock from "../unassign_block/WeekTimeline_UnassignedUnassignBlock.mjs";
import * as WeekTimeline_UnassignedContentModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/unassigned/components/content/WeekTimeline_UnassignedContent.module.css";

var bottomPadding = Units.Px.fromInt(60);

function make(jobs) {
  var blockHeight = RCore.$$Array.reduce(jobs, Units.Px.zero, (function (blockHeight, param) {
          return Units.Px.max(blockHeight, Units.Px.plus(param[0].top.contents, WeekTimeline_Constants.unassignedRowHeight));
        }));
  return {
          height: Units.Px.toString(Units.Px.plus(blockHeight, bottomPadding))
        };
}

var styles = WeekTimeline_UnassignedContentModuleCss;

function WeekTimeline_UnassignedContent(Props) {
  var compactMode = Props.compactMode;
  var isOver = Props.isOver;
  var jobs = Props.jobs;
  var containerRef = Types.ReactRef.use();
  WeekTimeline_UnassignedContentHooks.UpdateRightPadding.use(containerRef, jobs);
  WeekTimeline_UnassignedContentHooks.StopPropagateWheelEvent.use(containerRef, jobs);
  var messageBlock = isOver ? React.createElement(WeekTimeline_UnassignedUnassignBlock.make, {}) : (
      Caml_obj.equal(jobs, []) ? React.createElement(WeekTimeline_UnassignedEmptyBlock.make, {
              compactMode: compactMode
            }) : null
    );
  var jobsBlock = jobs.map(function (param) {
        var job = param[1];
        return React.createElement(WeekTimeline_UnassignedJob.make, {
                    position: param[0],
                    job: job,
                    key: Uuid.toString(Schedule_Types_Job.UnassignedJob.uuid(job))
                  });
      });
  var className = compactMode || isOver || Caml_obj.equal(jobs, []) ? styles.hiddenContainer : styles.container;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  ref: containerRef,
                  className: className
                }, React.createElement("div", {
                      className: styles.scrollable,
                      style: make(jobs)
                    }, jobsBlock)), messageBlock);
}

var make$1 = WeekTimeline_UnassignedContent;

export {
  make$1 as make,
}
/* bottomPadding Not a pure module */
