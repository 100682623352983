

import * as Uuid from "../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../libraries/RCore.mjs";
import * as Locale from "../../../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Distance from "../../../../../libraries/Distance.mjs";
import * as JsContext from "../../../../../libraries/JsContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsonDecode from "../../../../../libraries/JsonDecode.mjs";
import * as Types_Date from "../../../../../types/Types_Date.mjs";
import * as Types_Spatial from "../../../../../types/Types_Spatial.mjs";

function fromFloat(accuracy) {
  if (accuracy < 100.0) {
    return "High";
  } else if (accuracy < 1000.0) {
    return "Moderate";
  } else {
    return "Low";
  }
}

var decode = JsonDecode.map(JsonDecode.$$float, fromFloat);

function fromString(status) {
  switch (status) {
    case "assigned" :
        return "Assigned";
    case "canceled" :
        return "Canceled";
    case "changed" :
        return "Changed";
    case "en_route" :
        return "EnRoute";
    case "finished" :
        return "Finished";
    case "posted" :
        return "Posted";
    case "published" :
        return "Published";
    case "scheduled" :
        return "Scheduled";
    case "started" :
        return "Started";
    case "suspended" :
        return "Suspended";
    default:
      return {
              NAME: "Unknown",
              VAL: status
            };
  }
}

function toString(status) {
  if (typeof status === "object") {
    return status.VAL;
  } else if (status === "Published") {
    return "published";
  } else if (status === "Finished") {
    return "finished";
  } else if (status === "Suspended") {
    return "suspended";
  } else if (status === "Canceled") {
    return "canceled";
  } else if (status === "Started") {
    return "started";
  } else if (status === "Changed") {
    return "changed";
  } else if (status === "Scheduled") {
    return "scheduled";
  } else if (status === "EnRoute") {
    return "en_route";
  } else if (status === "Posted") {
    return "posted";
  } else {
    return "assigned";
  }
}

var decode$1 = JsonDecode.map(JsonDecode.string, fromString);

var decode$2 = JsonDecode.object(function (field) {
      return {
              name: field.required("name", JsonDecode.string),
              successful: field.required("successful", JsonDecode.bool)
            };
    });

var decode$3 = JsonDecode.object(function (field) {
      return {
              comment: RCore.$$Option.flatten(field.optional("comment", JsonDecode.option(JsonDecode.string))),
              resolution: field.required("resolution", decode$2)
            };
    });

var decode$4 = JsonDecode.object(function (field) {
      return {
              name: field.required("name", JsonDecode.string),
              successful: field.required("successful", JsonDecode.bool)
            };
    });

var decode$5 = JsonDecode.object(function (field) {
      return {
              comment: RCore.$$Option.flatten(field.optional("comment", JsonDecode.option(JsonDecode.string))),
              resolution: field.required("resolution", decode$4)
            };
    });

var decode$6 = JsonDecode.object(function (field) {
      return {
              url: RCore.$$Option.flatten(field.optional("url", JsonDecode.option(JsonDecode.string))),
              previewUrl: RCore.$$Option.flatten(field.optional("previewUrl", JsonDecode.option(JsonDecode.string))),
              name: field.required("name", JsonDecode.string)
            };
    });

function removeDuplicates(images) {
  return RCore.$$Array.reduce(images, [], (function (a, el) {
                if (a.some(function (i) {
                        return Caml_obj.equal(i.url, el.url) ? i.name === el.name : false;
                      })) {
                  return a;
                } else {
                  return Belt_Array.concatMany([
                              a,
                              [el]
                            ]);
                }
              }));
}

var decode$7 = JsonDecode.object(function (field) {
      return {
              currency: field.required("currency", JsonDecode.string),
              useCurrencyFractionalUnit: field.required("useCurrencyFractionalUnit", JsonDecode.bool),
              value: RCore.$$Option.flatten(field.optional("value", JsonDecode.option(JsonDecode.string)))
            };
    });

function toString$1(field) {
  var variant = field.NAME;
  if (variant === "Action") {
    return "action";
  } else if (variant === "IntegerInput" || variant === "DecimalInput" || variant === "Input") {
    return "input";
  } else if (variant === "Barcode") {
    return "barcode";
  } else if (variant === "Currency") {
    return "currency";
  } else if (variant === "Checkbox") {
    return "checkbox";
  } else if (variant === "Files") {
    return "file";
  } else if (variant === "Date") {
    return "date";
  } else if (variant === "Unknown") {
    return "unknown";
  } else if (variant === "Textarea") {
    return "textarea";
  } else if (variant === "Images") {
    return "image";
  } else if (variant === "Signature") {
    return "signature";
  } else if (variant === "Time") {
    return "time";
  } else if (variant === "Dictionary") {
    return "dictionary";
  } else {
    return "datetime";
  }
}

function decode$8(wire, field) {
  var fieldType = field.required("fieldType", JsonDecode.string);
  var dataType = field.required("dataType", JsonDecode.string);
  switch (fieldType) {
    case "action" :
        return {
                NAME: "Action",
                VAL: RCore.$$Option.flatten(field.optional("value", JsonDecode.option(JsonDecode.bool)))
              };
    case "barcode" :
        return {
                NAME: "Barcode",
                VAL: RCore.$$Option.flatten(field.optional("value", JsonDecode.option(JsonDecode.string)))
              };
    case "checkbox" :
        return {
                NAME: "Checkbox",
                VAL: RCore.$$Option.flatten(field.optional("value", JsonDecode.option(JsonDecode.bool)))
              };
    case "currency" :
        return {
                NAME: "Currency",
                VAL: RCore.$$Option.flatten(field.optional("value", JsonDecode.option(decode$7)))
              };
    case "date_picker" :
        return {
                NAME: "Date",
                VAL: RCore.$$Option.flatten(field.optional("value", JsonDecode.option(Types_Date.decode)))
              };
    case "datetime_picker" :
        return {
                NAME: "Datetime",
                VAL: RCore.$$Option.flatten(field.optional("value", JsonDecode.option(Types_Date.decode)))
              };
    case "dictionary" :
        return {
                NAME: "Dictionary",
                VAL: RCore.$$Option.flatten(field.optional("value", JsonDecode.option(JsonDecode.string)))
              };
    case "file" :
        return {
                NAME: "Files",
                VAL: RCore.$$Option.getOr(field.required("value", JsonDecode.option(JsonDecode.array(decode$6))), [])
              };
    case "image" :
        return {
                NAME: "Images",
                VAL: RCore.$$Option.getOr(field.required("value", JsonDecode.option(JsonDecode.array(decode$6))), [])
              };
    case "input" :
        switch (dataType) {
          case "decimal" :
              return {
                      NAME: "DecimalInput",
                      VAL: RCore.$$Option.flatten(field.optional("value", JsonDecode.option(JsonDecode.string)))
                    };
          case "integer" :
              return {
                      NAME: "IntegerInput",
                      VAL: RCore.$$Option.flatten(field.optional("value", JsonDecode.option(JsonDecode.string)))
                    };
          default:
            return {
                    NAME: "Input",
                    VAL: RCore.$$Option.flatten(field.optional("value", JsonDecode.option(JsonDecode.string)))
                  };
        }
    case "signature" :
        return {
                NAME: "Signature",
                VAL: RCore.$$Option.getOr(field.required("value", JsonDecode.option(JsonDecode.array(decode$6))), [])
              };
    case "textarea" :
        return {
                NAME: "Textarea",
                VAL: RCore.$$Option.flatten(field.optional("value", JsonDecode.option(JsonDecode.string)))
              };
    case "time_picker" :
        var ctx = JsContext.get(wire);
        return {
                NAME: "Time",
                VAL: RCore.$$Option.flatten(field.optional("value", JsonDecode.option(JsonDecode.map(JsonDecode.string, Locale.T.parse("TimeSeconds", ctx)))))
              };
    default:
      return {
              NAME: "Unknown",
              VAL: fieldType
            };
  }
}

function decode$9(wire) {
  return JsonDecode.object(function (field) {
              return {
                      name: field.required("name", JsonDecode.string),
                      value: decode$8(wire, field)
                    };
            });
}

function decode$10(wire, field) {
  var match = field.required("type", JsonDecode.string);
  switch (match) {
    case "button_triggered" :
        return "ButtonTriggered";
    case "canceled" :
        return {
                NAME: "Canceled",
                VAL: field.required("value", decode$5)
              };
    case "field_updated" :
        return {
                NAME: "FieldUpdated",
                VAL: field.required("value", decode$9(wire))
              };
    case "finished" :
        return {
                NAME: "Finished",
                VAL: field.required("value", decode$3)
              };
    case "job_created" :
        return "JobCreated";
    case "job_updated" :
        return "JobUpdated";
    case "job_viewed" :
        return "JobViewed";
    case "message_sent" :
        return "MessageSent";
    case "movement_finished" :
        return {
                NAME: "MovementFinished",
                VAL: field.required("value", Distance.Distance.decode(wire.ctx))
              };
    case "printed" :
        return {
                NAME: "Printed",
                VAL: field.required("value", JsonDecode.string)
              };
    case "status_updated" :
        return {
                NAME: "StatusUpdated",
                VAL: field.required("value", decode$1)
              };
    default:
      return "Unknown";
  }
}

var decode$11 = JsonDecode.object(function (field) {
      return {
              name: field.required("name", JsonDecode.string),
              web: field.required("web", JsonDecode.bool)
            };
    });

var decode$12 = JsonDecode.object(function (field) {
      return {
              geolocation: field.required("geolocation", Types_Spatial.$$Geolocation.decode),
              accuracy: field.required("accuracy", decode),
              distanceToDestinationM: RCore.$$Option.flatten(field.optional("distanceToDestinationM", JsonDecode.option(JsonDecode.$$float)))
            };
    });

function fromString$1(application) {
  switch (application) {
    case "api" :
        return "API";
    case "mobile" :
        return "Mobile";
    case "web" :
        return "Web";
    default:
      return {
              TAG: "Other",
              _0: application
            };
  }
}

var decode$13 = JsonDecode.map(JsonDecode.string, fromString$1);

function decode$14(wire) {
  return JsonDecode.object(function (field) {
              return {
                      uuid: field.required("uuid", Uuid.decode),
                      application: field.required("application", decode$13),
                      worker: RCore.$$Option.flatten(field.optional("worker", JsonDecode.option(decode$11))),
                      deviceState: RCore.$$Option.flatten(field.optional("deviceState", JsonDecode.option(decode$12))),
                      happenedAt: field.required("happenedAt", Types_Date.decode),
                      value: decode$10(wire, field),
                      viewedAt: field.required("viewedAt", JsonDecode.option(JsonDecode.string))
                    };
            });
}

function decode$15(wire) {
  return JsonDecode.object(function (field) {
              return {
                      date: field.required("date", Types_Date.decode),
                      events: field.required("events", JsonDecode.array(decode$14(wire)))
                    };
            });
}

var decode$16 = JsonDecode.object(function (field) {
      return {
              geolocation: field.required("geolocation", Types_Spatial.$$Geolocation.decode),
              accuracy: field.required("accuracy", JsonDecode.$$float),
              actualAt: field.required("actualAt", Types_Date.decode)
            };
    });

var decode$17 = JsonDecode.object(function (field) {
      return {
              movementFinishedUuid: field.required("movementFinishedUuid", Uuid.decode),
              locations: field.required("locations", JsonDecode.array(decode$16))
            };
    });

var decode$18 = JsonDecode.object(function (field) {
      return {
              movements: field.required("movements", JsonDecode.array(decode$17)),
              other: field.required("other", JsonDecode.array(decode$17))
            };
    });

var decode$19 = JsonDecode.object(function (field) {
      return {
              date: field.required("date", Types_Date.decode),
              locations: RCore.$$Option.flatten(field.optional("locations", JsonDecode.option(decode$18)))
            };
    });

var LocationsByDate = {
  decode: decode$19
};

function decode$20(wire) {
  return JsonDecode.object(function (field) {
              return {
                      addressFormatted: RCore.$$Option.flatten(field.optional("addressFormatted", JsonDecode.option(JsonDecode.string))),
                      addressGeolocation: RCore.$$Option.flatten(field.optional("addressGeolocation", JsonDecode.option(Types_Spatial.$$Geolocation.decode))),
                      addressMarkerLabel: RCore.$$Option.flatten(field.optional("addressMarkerLabel", JsonDecode.option(JsonDecode.string))),
                      eventsGrouped: field.required("events", JsonDecode.array(decode$15(wire))),
                      locations: field.required("locations", JsonDecode.array(decode$19)),
                      dates: field.required("dates", JsonDecode.array(Types_Date.decode))
                    };
            });
}

var $$History = {
  decode: decode$20
};

var green = "#51a52e";

var orange = "#ff9833";

var red = "#f64841";

function fromAccuracy(accuracy) {
  switch (accuracy) {
    case "High" :
        return green;
    case "Moderate" :
        return orange;
    case "Low" :
        return red;
    
  }
}

var Color = {
  green: green,
  orange: orange,
  red: red,
  fromAccuracy: fromAccuracy
};

var EventsByDateAndWorker = {};

var Accuracy = {};

var StatusUpdated = {
  toString: toString
};

var Finished = {
  Resolution: {}
};

var Canceled = {
  Resolution: {}
};

var FieldUpdated = {
  FieldValue: {
    Attachment: {},
    removeDuplicates: removeDuplicates,
    Currency: {},
    toString: toString$1
  }
};

var EventValue = {};

var $$Worker = {};

var DeviceState = {};

var Application = {};

var $$Event = {
  Grouped: {}
};

var $$Location = {};

var Movement = {};

var Locations = {};

export {
  Accuracy ,
  StatusUpdated ,
  Finished ,
  Canceled ,
  FieldUpdated ,
  EventValue ,
  $$Worker ,
  DeviceState ,
  Application ,
  $$Event ,
  $$Location ,
  Movement ,
  Locations ,
  LocationsByDate ,
  $$History ,
  Color ,
  EventsByDateAndWorker ,
}
/* decode Not a pure module */
