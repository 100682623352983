

import * as RCore from "../../../../libraries/RCore.mjs";
import * as Types from "../../../../types/Types.mjs";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as JsonDecode from "../../../../libraries/JsonDecode.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Shared_Lib_Option from "../../../../shared/lib/Shared_Lib_Option.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              id: field.required("id", Types.Id.decodeOpaque),
              name: field.required("name", JsonDecode.string)
            };
    });

function fromString(jobDataType) {
  switch (jobDataType) {
    case "attachment" :
        return "Attachment";
    case "boolean" :
        return "Boolean";
    case "currency" :
        return "Currency";
    case "date" :
        return "Date";
    case "datetime" :
        return "Datetime";
    case "decimal" :
        return "Decimal";
    case "dictionary" :
        return "Dictionary";
    case "integer" :
        return "Integer";
    case "job_type" :
        return "Type";
    case "skills" :
        return "Skills";
    case "string" :
        return "String";
    case "territory" :
        return "Territory";
    case "url" :
        return "URL";
    default:
      return "Unknown";
  }
}

function fromStringLifter(v) {
  return Shared_Lib_Option.some(fromString(v));
}

function fromString$1(dealDataType) {
  switch (dealDataType) {
    case "boolean" :
        return "Boolean";
    case "contacts" :
        return "Contacts";
    case "crm_status" :
        return "CrmStatus";
    case "date" :
        return "Date";
    case "datetime" :
        return "Datetime";
    case "double" :
        return "Double";
    case "employee" :
        return "Employee";
    case "enumeration" :
        return "Enumeration";
    case "file" :
        return "File";
    case "integer" :
        return "Integer";
    case "money" :
        return "Money";
    case "address" :
    case "rest_3_its_yandex_map_uf" :
    case "rest_4_its_yandex_map_uf" :
        return "Address";
    case "string" :
        return "String";
    case "url" :
        return "Url";
    case "user" :
        return "User";
    default:
      return "Unknown";
  }
}

function toString(dealDataType) {
  switch (dealDataType) {
    case "Address" :
        return "address";
    case "Boolean" :
        return "boolean";
    case "Contacts" :
        return "contacts";
    case "CrmStatus" :
        return "crm_status";
    case "Date" :
        return "date";
    case "Datetime" :
        return "datetime";
    case "Double" :
        return "double";
    case "Employee" :
        return "employee";
    case "Enumeration" :
        return "enumeration";
    case "File" :
        return "file";
    case "Integer" :
        return "integer";
    case "Money" :
        return "money";
    case "String" :
        return "string";
    case "Url" :
        return "url";
    case "User" :
        return "user";
    case "Unknown" :
        return "unknown";
    
  }
}

var Deal = {
  fromString: fromString$1,
  toString: toString
};

function listDealDataTypesForJobType(jobFieldDataType) {
  switch (jobFieldDataType) {
    case "Address" :
        return {
                hd: "String",
                tl: {
                  hd: "Address",
                  tl: /* [] */0
                }
              };
    case "Assignee" :
        return {
                hd: "Enumeration",
                tl: {
                  hd: "User",
                  tl: {
                    hd: "Employee",
                    tl: /* [] */0
                  }
                }
              };
    case "Attachment" :
        return {
                hd: "File",
                tl: /* [] */0
              };
    case "Contacts" :
        return {
                hd: "Contacts",
                tl: /* [] */0
              };
    case "Dictionary" :
        return {
                hd: "String",
                tl: {
                  hd: "Enumeration",
                  tl: {
                    hd: "Boolean",
                    tl: {
                      hd: "CrmStatus",
                      tl: /* [] */0
                    }
                  }
                }
              };
    case "Duration" :
        return {
                hd: "Integer",
                tl: {
                  hd: "String",
                  tl: {
                    hd: "Double",
                    tl: /* [] */0
                  }
                }
              };
    case "Currency" :
    case "Decimal" :
    case "Integer" :
        return {
                hd: "Integer",
                tl: {
                  hd: "String",
                  tl: {
                    hd: "Double",
                    tl: {
                      hd: "Money",
                      tl: /* [] */0
                    }
                  }
                }
              };
    case "Skills" :
        return {
                hd: "Enumeration",
                tl: {
                  hd: "String",
                  tl: /* [] */0
                }
              };
    case "String" :
        return {
                hd: "Date",
                tl: {
                  hd: "Datetime",
                  tl: {
                    hd: "String",
                    tl: {
                      hd: "Integer",
                      tl: {
                        hd: "Double",
                        tl: {
                          hd: "Enumeration",
                          tl: {
                            hd: "Employee",
                            tl: {
                              hd: "User",
                              tl: {
                                hd: "CrmStatus",
                                tl: {
                                  hd: "Address",
                                  tl: {
                                    hd: "Money",
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              };
    case "Date" :
    case "Datetime" :
    case "Time" :
        return {
                hd: "Date",
                tl: {
                  hd: "Datetime",
                  tl: /* [] */0
                }
              };
    case "TimeString" :
        return {
                hd: "String",
                tl: /* [] */0
              };
    case "Territory" :
    case "Type" :
        return {
                hd: "Enumeration",
                tl: /* [] */0
              };
    case "URL" :
        return {
                hd: "Url",
                tl: /* [] */0
              };
    default:
      return /* [] */0;
  }
}

function canMapToJobField(dealFieldDataType, jobFieldDataType) {
  var supportedTypes = listDealDataTypesForJobType(jobFieldDataType);
  return Core__List.some(supportedTypes, (function (dealType) {
                return dealType === dealFieldDataType;
              }));
}

var DealToJob = {
  listDealDataTypesForJobType: listDealDataTypesForJobType,
  canMapToJobField: canMapToJobField
};

function listDealDataTypesForJobType$1(jobFieldDataType) {
  switch (jobFieldDataType) {
    case "Assignee" :
        return {
                hd: "String",
                tl: {
                  hd: "Enumeration",
                  tl: {
                    hd: "User",
                    tl: {
                      hd: "Employee",
                      tl: /* [] */0
                    }
                  }
                }
              };
    case "Boolean" :
        return {
                hd: "Boolean",
                tl: /* [] */0
              };
    case "Currency" :
        return {
                hd: "Money",
                tl: {
                  hd: "String",
                  tl: {
                    hd: "Double",
                    tl: {
                      hd: "Integer",
                      tl: /* [] */0
                    }
                  }
                }
              };
    case "Date" :
    case "Datetime" :
        return {
                hd: "Datetime",
                tl: {
                  hd: "Date",
                  tl: {
                    hd: "String",
                    tl: /* [] */0
                  }
                }
              };
    case "Dictionary" :
        return {
                hd: "String",
                tl: {
                  hd: "Enumeration",
                  tl: /* [] */0
                }
              };
    case "Decimal" :
    case "Integer" :
        return {
                hd: "String",
                tl: {
                  hd: "Integer",
                  tl: {
                    hd: "Double",
                    tl: /* [] */0
                  }
                }
              };
    case "Resolution" :
        return {
                hd: "String",
                tl: /* [] */0
              };
    case "String" :
        return {
                hd: "String",
                tl: {
                  hd: "Integer",
                  tl: {
                    hd: "Double",
                    tl: {
                      hd: "Enumeration",
                      tl: /* [] */0
                    }
                  }
                }
              };
    case "Attachment" :
    case "URL" :
        return {
                hd: "String",
                tl: {
                  hd: "Url",
                  tl: /* [] */0
                }
              };
    default:
      return /* [] */0;
  }
}

function canMapToDealField(dealFieldDataType, jobFieldDataType) {
  var supportedTypes = listDealDataTypesForJobType$1(jobFieldDataType);
  return Core__List.some(supportedTypes, (function (dealType) {
                return dealType === dealFieldDataType;
              }));
}

var JobToDeal = {
  listDealDataTypesForJobType: listDealDataTypesForJobType$1,
  canMapToDealField: canMapToDealField
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Types.Id.decodeOpaque),
              name: field.required("name", JsonDecode.string),
              dataType: field.required("dataType", JsonDecode.map(JsonDecode.string, fromString))
            };
    });

var decode$2 = JsonDecode.object(function (field) {
      return {
              id: field.required("id", Types.Id.decodeOpaque),
              name: field.required("name", JsonDecode.string),
              fields: field.required("fields", JsonDecode.array(decode$1)),
              reportFields: field.required("reportFields", JsonDecode.array(decode$1))
            };
    });

var decode$3 = JsonDecode.object(function (field) {
      return {
              id: field.required("id", Types.Id.decodeOpaque),
              name: field.required("name", JsonDecode.string),
              dataType: field.required("type", JsonDecode.string),
              multiple: field.required("multiple", JsonDecode.bool),
              readOnly: field.required("readOnly", JsonDecode.bool),
              statusType: field.optional("statusType", JsonDecode.string)
            };
    });

function _removePrefix(prefix, str) {
  if (Js_string.startsWith(prefix, str)) {
    return Js_string.replace(prefix, "", str);
  }
  
}

var all = [
  "Address",
  "AddressDescription",
  "Apartment",
  "Assignee",
  "Contacts",
  "ContactName",
  "ContactValue",
  "Description",
  "EnRouteAt",
  "EntranceNo",
  "FinishedAt",
  "Floor",
  "PhotoArchiveUrlShared",
  "Resolution",
  "ResolutionComment",
  "ScheduledAt",
  "ScheduledAtDate",
  "ScheduledAtTime",
  "ScheduledDuration",
  "SerialNo",
  "Skills",
  "StartedAt",
  "Territory",
  "Type"
];

function toString$1(predefinedField) {
  switch (predefinedField) {
    case "Address" :
        return "address";
    case "AddressDescription" :
        return "address_details.description";
    case "Apartment" :
        return "address_details.apartment";
    case "Assignee" :
        return "assignee";
    case "Contacts" :
        return "contacts";
    case "ContactName" :
        return "contacts.0.name";
    case "ContactValue" :
        return "contacts.0.value";
    case "Description" :
        return "description";
    case "EnRouteAt" :
        return "en_route_at";
    case "EntranceNo" :
        return "address_details.entrance_no";
    case "FinishedAt" :
        return "finished_at";
    case "Floor" :
        return "address_details.floor";
    case "PhotoArchiveUrlShared" :
        return "photo_archive_url_shared";
    case "Resolution" :
        return "resolution";
    case "ResolutionComment" :
        return "resolution_comment";
    case "ScheduledAt" :
        return "scheduled_at";
    case "ScheduledAtDate" :
        return "scheduled_at.date";
    case "ScheduledAtTime" :
        return "scheduled_at.time";
    case "ScheduledDuration" :
        return "scheduled_duration";
    case "SerialNo" :
        return "serial_no";
    case "Skills" :
        return "skill_uuids";
    case "StartedAt" :
        return "started_at";
    case "Territory" :
        return "territory_uuid";
    case "Type" :
        return "type_uuid";
    
  }
}

function fromString$2(str) {
  return Js_array.find((function (f) {
                return toString$1(f) === str;
              }), all);
}

function toDataType(predefinedField) {
  switch (predefinedField) {
    case "Address" :
        return "Address";
    case "Assignee" :
        return "Assignee";
    case "Contacts" :
        return "Contacts";
    case "PhotoArchiveUrlShared" :
        return "URL";
    case "Resolution" :
        return "Resolution";
    case "ScheduledAt" :
        return "Time";
    case "ScheduledAtDate" :
        return "Date";
    case "ScheduledAtTime" :
        return "TimeString";
    case "ScheduledDuration" :
        return "Duration";
    case "SerialNo" :
        return "Integer";
    case "Skills" :
        return "Skills";
    case "EnRouteAt" :
    case "FinishedAt" :
    case "StartedAt" :
        return "Datetime";
    case "Territory" :
        return "Territory";
    case "Type" :
        return "Type";
    default:
      return "String";
  }
}

var PredefinedField = {
  all: all,
  toString: toString$1,
  fromString: fromString$2,
  toDataType: toDataType
};

function makePredefined(f) {
  return {
          TAG: "Predefined",
          _0: f
        };
}

function makeCustom(param) {
  return {
          TAG: "Custom",
          _0: param.uuid
        };
}

function makeReport(param) {
  return {
          TAG: "Report",
          _0: param.uuid
        };
}

function makeId(jobField) {
  switch (jobField.TAG) {
    case "Custom" :
        return Types.Id.map((function (str) {
                      return "custom-field-" + str;
                    }), jobField._0);
    case "Report" :
        return Types.Id.map((function (str) {
                      return "report-field-" + str;
                    }), jobField._0);
    case "Predefined" :
        return Types.Id.fromString(toString$1(jobField._0));
    
  }
}

function fromId(id, templateFields) {
  var strId = Types.Id.toString(id);
  var s = _removePrefix("custom-field-", strId);
  if (s !== undefined) {
    var chosenUuid = Types.Id.fromString(s);
    return RCore.$$Option.map((function (__x) {
                    return Js_array.find((function (param) {
                                  return Types.Id.equal(param.uuid, chosenUuid);
                                }), __x);
                  })(templateFields), (function (param) {
                  return {
                          TAG: "Custom",
                          _0: param.uuid
                        };
                }));
  }
  var s$1 = _removePrefix("report-field-", strId);
  if (s$1 === undefined) {
    return RCore.$$Option.map(fromString$2(strId), (function (f) {
                  return {
                          TAG: "Predefined",
                          _0: f
                        };
                }));
  }
  var chosenUuid$1 = Types.Id.fromString(s$1);
  return RCore.$$Option.map((function (__x) {
                  return Js_array.find((function (param) {
                                return Types.Id.equal(param.uuid, chosenUuid$1);
                              }), __x);
                })(templateFields), (function (param) {
                return {
                        TAG: "Report",
                        _0: param.uuid
                      };
              }));
}

var decode$4 = JsonDecode.object(function (field) {
      var code = field.optional("code", JsonDecode.fromVariant(JsonDecode.string, fromString$2));
      if (code !== undefined) {
        return {
                TAG: "Predefined",
                _0: code
              };
      }
      var uuid = field.optional("customFieldUuid", Types.Id.decodeOpaque);
      if (uuid !== undefined) {
        return {
                TAG: "Custom",
                _0: Caml_option.valFromOption(uuid)
              };
      } else {
        return {
                TAG: "Report",
                _0: field.required("reportFieldUuid", Types.Id.decodeOpaque)
              };
      }
    });

var JobField = {
  PredefinedField: PredefinedField,
  makePredefined: makePredefined,
  makeCustom: makeCustom,
  makeReport: makeReport,
  makeId: makeId,
  fromId: fromId,
  decode: decode$4
};

var Deal$1 = {};

function makeDealField(param) {
  return {
          TAG: "DealField",
          _0: {
            id: param.id,
            dataType: param.dataType,
            multiple: param.multiple,
            statusType: param.statusType
          }
        };
}

function makeId$1(dealField) {
  return Types.Id.map((function (str) {
                return "deal-field-" + str;
              }), dealField._0.id);
}

function fromId$1(id, dealFields) {
  var strId = Types.Id.toString(id);
  var s = _removePrefix("deal-field-", strId);
  if (s === undefined) {
    return ;
  }
  var chosenFieldId = Types.Id.fromString(s);
  return RCore.$$Option.map(RCore.$$Array.getBy(dealFields, (function (param) {
                    return Types.Id.equal(param.id, chosenFieldId);
                  })), makeDealField);
}

var decode$5 = JsonDecode.object(function (field) {
      return {
              TAG: "DealField",
              _0: {
                id: field.required("dealFieldId", Types.Id.decodeOpaque),
                dataType: field.required("dataType", JsonDecode.string),
                multiple: field.required("multiple", JsonDecode.bool),
                statusType: field.optional("statusType", JsonDecode.string)
              }
            };
    });

var DealField = {
  Deal: Deal$1,
  makeDealField: makeDealField,
  makeId: makeId$1,
  fromId: fromId$1,
  decode: decode$5
};

var decode$6 = JsonDecode.object(function (field) {
      return {
              id: field.optional("id", Types.Id.decodeOpaque),
              templateId: field.required("templateId", JsonDecode.option(Types.Id.decodeOpaque)),
              jobFields: field.required("jobFields", JsonDecode.array(JsonDecode.object(function (field) {
                            var jobField = field.required("jobField", decode$4);
                            var dealField = field.required("dealField", decode$5);
                            return [
                                    jobField,
                                    dealField
                                  ];
                          }))),
              dealFields: field.required("dealFields", JsonDecode.array(JsonDecode.object(function (field) {
                            var dealField = field.required("dealField", decode$5);
                            var jobField = field.required("jobField", decode$4);
                            return [
                                    dealField,
                                    jobField
                                  ];
                          }))),
              onSuccess: field.required("onSuccess", JsonDecode.option(Types.Id.decodeOpaque)),
              onFailure: field.required("onFailure", JsonDecode.option(Types.Id.decodeOpaque)),
              createClient: field.required("createClient", JsonDecode.bool)
            };
    });

var decode$7 = JsonDecode.object(function (field) {
      return {
              id: field.required("id", Types.Id.decodeOpaque),
              name: field.required("name", JsonDecode.string),
              configured: field.required("configured", JsonDecode.bool),
              stages: field.required("stages", JsonDecode.array(decode$6))
            };
    });

var decode$8 = JsonDecode.object(function (field) {
      return {
              id: field.required("id", Types.Id.decodeOpaque),
              name: field.required("name", JsonDecode.string),
              stages: field.required("stages", JsonDecode.array(decode)),
              types: field.required("types", JsonDecode.array(decode$7)),
              configuredTypes: field.required("configuredTypes", JsonDecode.$$int)
            };
    });

var decode$9 = JsonDecode.object(function (field) {
      return {
              domain: field.required("domain", JsonDecode.string),
              templates: field.required("templates", JsonDecode.array(decode$2)),
              categories: field.required("categories", JsonDecode.array(decode$8)),
              dealFields: field.required("dealFields", JsonDecode.array(decode$3))
            };
    });

var Settings = {
  decode: decode$9
};

var Stage = {};

var DataType_Job = {
  fromStringLifter: fromStringLifter
};

var DataType = {
  Job: DataType_Job,
  Deal: Deal,
  DealToJob: DealToJob,
  JobToDeal: JobToDeal
};

var TemplateField = {};

var Template = {};

var DealField$1 = {};

var ConfiguredStage = {
  JobField: JobField,
  DealField: DealField
};

var DealType = {};

var Category = {};

export {
  Stage ,
  DataType ,
  TemplateField ,
  Template ,
  DealField$1 as DealField,
  ConfiguredStage ,
  DealType ,
  Category ,
  Settings ,
}
/* decode Not a pure module */
