

import * as Radio from "../../../../../../inputs/Radio.mjs";
import * as Utils from "../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../../../../../common/Optional.mjs";
import * as AppContext from "../../../../../../../context/AppContext.mjs";
import * as Context_Types from "../../../../../../../context/Context_Types.mjs";
import * as Schedule_State from "../../../../../lib/state/Schedule_State.mjs";
import * as Schedule_Header_ModeModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/header/right/settings/mode/Schedule_Header_Mode.module.css";

var styles = Schedule_Header_ModeModuleCss;

var day$p = Utils.Translations.tr("js.schedule.v2.settings.mode.day");

var week$p = Utils.Translations.tr("js.schedule.v2.settings.mode.week");

var new$p = Utils.Translations.t("js.schedule.v2.settings.mode.new");

var old$p = Utils.Translations.t("js.schedule.v2.settings.mode.old");

function Schedule_Header_Mode(Props) {
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var toggleDayMode = Schedule_State.useToggleDayTimelineMode();
  var toggleDayMode$1 = function (_event) {
    toggleDayMode();
  };
  var toggleWeekMode = Schedule_State.useToggleWeekTimelineMode();
  var toggleWeekMode$1 = function (_event) {
    toggleWeekMode();
  };
  var mode = Schedule_State.useMode();
  return React.createElement(Optional.make, {
              show: Context_Types.Features.hasFlag("showOldTimeline", ctx.features),
              children: React.createElement("div", {
                    className: styles.container
                  }, React.createElement("div", {
                        className: styles.field
                      }, React.createElement("label", {
                            className: styles.label
                          }, day$p(ctx)), React.createElement(Radio.make, {
                            onChange: toggleDayMode$1,
                            checked: mode.day === "New",
                            labelText: new$p(ctx)
                          }), React.createElement(Radio.make, {
                            onChange: toggleDayMode$1,
                            checked: mode.day === "Old",
                            labelText: old$p(ctx)
                          })), React.createElement(Optional.make, {
                        show: Context_Types.Features.hasFlag("scheduleZoom", ctx.features),
                        children: React.createElement("div", {
                              className: styles.field
                            }, React.createElement("label", {
                                  className: styles.label
                                }, week$p(ctx)), React.createElement(Radio.make, {
                                  onChange: toggleWeekMode$1,
                                  checked: mode.week === "New",
                                  labelText: new$p(ctx)
                                }), React.createElement(Radio.make, {
                                  onChange: toggleWeekMode$1,
                                  checked: mode.week === "Old",
                                  labelText: old$p(ctx)
                                }))
                      }))
            });
}

var make = Schedule_Header_Mode;

export {
  make ,
}
/* styles Not a pure module */
