

import * as React from "react";
import * as Action from "../../../../../../../../inputs/Action.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as BaseFormInput from "../../../../../../../../inputs/BaseFormInput.mjs";

function JobForm_ReportFieldActionInput(Props) {
  var id = Props.id;
  var label = Props.label;
  var value = Props.value;
  var onValueChange = Props.onValueChange;
  var readOnly = Props.readOnly;
  return React.createElement(BaseFormInput.make, {
              id: id,
              wrapperClass: Js_dict.fromList({
                    hd: [
                      "job-report-field-container",
                      true
                    ],
                    tl: /* [] */0
                  }),
              children: React.createElement(Action.make, {
                    id: id,
                    disabled: readOnly,
                    value: value,
                    label: label,
                    onChange: onValueChange
                  })
            });
}

var make = JobForm_ReportFieldActionInput;

var $$default = JobForm_ReportFieldActionInput;

export {
  make ,
  $$default as default,
}
/* react Not a pure module */
