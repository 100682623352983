

import * as Backend from "../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              mailSent: field.required("mailSent", JsonDecode.bool)
            };
    });

function recover(wire, email) {
  return Backend.decode(Backend.post(undefined, undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: "Recover"
                }, {
                  NAME: "Obj",
                  VAL: {
                    email: email
                  }
                }), decode);
}

export {
  recover ,
}
/* decode Not a pure module */
