

import * as RCore from "../../../../../libraries/RCore.mjs";
import * as Locale from "../../../../../libraries/Locale.mjs";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Schedule_Types_Job from "../../../lib/types/job/Schedule_Types_Job.mjs";

function minDate(wire, jobs) {
  return RCore.$$Array.reduce(jobs, Locale.T.now(wire), (function (minDate, job) {
                var startAt = Schedule_Types_Job.ScheduledJob.startAt(job);
                if (Locale.T.lessThan(startAt, minDate)) {
                  return startAt;
                } else {
                  return minDate;
                }
              }));
}

function maxDate(wire, jobs) {
  return RCore.$$Array.reduce(jobs, Locale.T.now(wire), (function (maxDate, job) {
                var finishAt = Schedule_Types_Job.ScheduledJob.finishAt(job);
                if (Locale.T.moreThan(finishAt, maxDate)) {
                  return finishAt;
                } else {
                  return maxDate;
                }
              }));
}

function groups(wire, jobs) {
  var minDate$1 = minDate(wire, jobs);
  var maxDate$1 = maxDate(wire, jobs);
  var dateInterval = 2 + Locale.T.daysBetween(maxDate$1, minDate$1) | 0;
  return RCore.$$Array.make(dateInterval, minDate$1).map(function (date, index) {
              return {
                      date: Locale.T.nextDays(index)(date),
                      jobs: []
                    };
            });
}

function scheduledOnDate(date, job) {
  var startAt = Schedule_Types_Job.ScheduledJob.startAt(job);
  var finishAt = Schedule_Types_Job.ScheduledJob.finishAt(job);
  var startOfDate = Locale.T.startOf("day", date);
  var endOfDate = Locale.T.endOf("day", date);
  if (Locale.T.lessThan(startOfDate, finishAt)) {
    return Locale.T.moreThan(endOfDate, startAt);
  } else {
    return false;
  }
}

function transform(wire, jobs) {
  var groupedJobs = groups(wire, jobs);
  groupedJobs.forEach(function (group) {
        jobs.forEach(function (job) {
              if (scheduledOnDate(group.date, job)) {
                Js_array.push(job, group.jobs);
                return ;
              }
              
            });
      });
  return groupedJobs;
}

export {
  transform ,
}
/* Locale Not a pure module */
