

import * as RCore from "../../../RCore.mjs";
import * as Types from "../../../../types/Types.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as Shared_Lib_Array from "../../../../shared/lib/Shared_Lib_Array.mjs";

var SlateProps = {};

var SlateElement = {};

var lookupDelimeter = ".";

var filterDelimeter = " | ";

var filterArgsDelimeter = ": ";

var argsDelimeter = ", ";

function key(a) {
  if (a.TAG === "String") {
    return "'" + a._0 + "'";
  } else {
    return a._0;
  }
}

function name(a) {
  return a._0;
}

var Value = {
  key: key,
  name: name
};

var Arg = {
  Value: Value
};

function name$1(param) {
  return param.name;
}

var Filter = {
  Arg: Arg,
  name: name$1
};

function name$2(param) {
  return param.name;
}

function key$1(param) {
  return param.key;
}

var Payload = {
  name: name$2,
  key: key$1
};

function name$3(substitution) {
  switch (substitution.TAG) {
    case "Object" :
        return substitution._0.name;
    case "String" :
    case "Datetime" :
        return substitution._0.name;
    
  }
}

function key$2(substitution) {
  switch (substitution.TAG) {
    case "Object" :
        return substitution._0.key;
    case "String" :
    case "Datetime" :
        return substitution._0.key;
    
  }
}

function children(substitution) {
  switch (substitution.TAG) {
    case "Object" :
        return substitution._0.children;
    case "String" :
    case "Datetime" :
        return ;
    
  }
}

var Substitution = {
  Payload: Payload,
  name: name$3,
  key: key$2,
  children: children
};

var Subtitution = {};

function name$4(s) {
  return s._0.name;
}

function key$3(s) {
  switch (s.TAG) {
    case "FilterArg" :
        return key(s._0.value);
    case "Filter" :
    case "Subtitution" :
        return s._0.key;
    
  }
}

function getSubstitutions(_lookups, _substitutions) {
  while(true) {
    var substitutions = _substitutions;
    var lookups = _lookups;
    if (!lookups) {
      return substitutions;
    }
    var lookups$1 = lookups.tl;
    var lookup = lookups.hd;
    if (lookups$1) {
      var substitution = RCore.$$Array.getBy(substitutions, (function(lookup){
          return function (substitution) {
            return key$2(substitution) === lookup;
          }
          }(lookup)));
      if (substitution === undefined) {
        return [];
      }
      switch (substitution.TAG) {
        case "Object" :
            _substitutions = substitution._0.children;
            _lookups = lookups$1;
            continue ;
        case "String" :
        case "Datetime" :
            return [];
        
      }
    } else {
      var substitution$1 = RCore.$$Array.getBy(substitutions, (function(lookup){
          return function (substitution) {
            return key$2(substitution) === lookup;
          }
          }(lookup)));
      if (substitution$1 === undefined) {
        return [];
      }
      switch (substitution$1.TAG) {
        case "Object" :
            return substitution$1._0.children;
        case "String" :
        case "Datetime" :
            return [];
        
      }
    }
  };
}

function getLastSubstitution(_lookups, _substitutions) {
  while(true) {
    var substitutions = _substitutions;
    var lookups = _lookups;
    if (!lookups) {
      return ;
    }
    var lookups$1 = lookups.tl;
    var lookup = lookups.hd;
    if (lookups$1) {
      var substitution = RCore.$$Array.getBy(substitutions, (function(lookup){
          return function (substitution) {
            return key$2(substitution) === lookup;
          }
          }(lookup)));
      if (substitution === undefined) {
        return ;
      }
      switch (substitution.TAG) {
        case "Object" :
            _substitutions = substitution._0.children;
            _lookups = lookups$1;
            continue ;
        case "String" :
        case "Datetime" :
            return ;
        
      }
    } else {
      var substitution$1 = RCore.$$Array.getBy(substitutions, (function(lookup){
          return function (substitution) {
            return key$2(substitution) === lookup;
          }
          }(lookup)));
      if (substitution$1 === undefined) {
        return ;
      }
      switch (substitution$1.TAG) {
        case "Object" :
            if (substitution$1._0.filters.length !== 0) {
              return substitution$1;
            } else {
              return ;
            }
        case "String" :
        case "Datetime" :
            return substitution$1;
        
      }
    }
  };
}

function datetimeFilters(ctx) {
  return [
          {
            TAG: "Filter",
            _0: {
              name: Utils.Translations.t("js.settings.sms.filters.date")(ctx),
              key: "date",
              args: []
            }
          },
          {
            TAG: "Filter",
            _0: {
              name: Utils.Translations.t("js.settings.sms.filters.time")(ctx),
              key: "time",
              args: []
            }
          },
          {
            TAG: "Filter",
            _0: {
              name: Utils.Translations.t("js.settings.sms.filters.datetime")(ctx),
              key: "datetime",
              args: []
            }
          }
        ];
}

function objectFilters(lookups, varFilters, substitutions) {
  var match = getLastSubstitution(lookups, substitutions);
  if (match === undefined) {
    return [];
  }
  switch (match.TAG) {
    case "Object" :
        var filters = match._0.filters;
        var match$1 = Shared_Lib_Array.last(varFilters);
        var last = match$1[1];
        if (last === undefined) {
          return filters.map(function (f) {
                      return {
                              TAG: "Filter",
                              _0: f
                            };
                    });
        }
        if (last.args.length !== 0) {
          return [];
        }
        var name = last.name;
        return RCore.$$Option.getOr(RCore.$$Option.map(RCore.$$Array.getBy(filters, (function (f) {
                              return f.key === name;
                            })), (function (f) {
                          return f.args.map(function (a) {
                                      return {
                                              TAG: "FilterArg",
                                              _0: a
                                            };
                                    });
                        })), []);
    case "String" :
    case "Datetime" :
        return [];
    
  }
}

function make(ctx, param, substitutions) {
  var filters = param.filters;
  var lookups = Core__List.fromArray(param.lookups);
  if (!Caml_obj.equal(filters, [])) {
    return objectFilters(lookups, filters, substitutions);
  }
  var suggestions = getSubstitutions(lookups, substitutions).map(function (substitution) {
        switch (substitution.TAG) {
          case "Object" :
              var match = substitution._0;
              return {
                      TAG: "Subtitution",
                      _0: {
                        name: match.name,
                        key: match.key
                      }
                    };
          case "String" :
          case "Datetime" :
              break;
          
        }
        var match$1 = substitution._0;
        return {
                TAG: "Subtitution",
                _0: {
                  name: match$1.name,
                  key: match$1.key
                }
              };
      });
  if (Caml_obj.equal(suggestions, [])) {
    var match = getLastSubstitution(lookups, substitutions);
    if (match === undefined) {
      return [];
    }
    switch (match.TAG) {
      case "Object" :
          return match._0.filters.map(function (f) {
                      return {
                              TAG: "Filter",
                              _0: f
                            };
                    });
      case "String" :
          return [];
      case "Datetime" :
          return datetimeFilters(ctx);
      
    }
  } else {
    return Belt_Array.concatMany([
                suggestions,
                objectFilters(lookups, filters, substitutions)
              ]);
  }
}

function hasSuggestions(ctx, variable, substitutions) {
  return Caml_obj.notequal(make(ctx, variable, substitutions), []);
}

function argsFilter(selectedArgs, allArgs) {
  if (Caml_obj.equal(selectedArgs, [])) {
    return "";
  } else {
    return filterArgsDelimeter + RCore.$$Array.filterMap(selectedArgs, (function (s) {
                    return RCore.$$Option.map(RCore.$$Array.getBy(allArgs, (function (a) {
                                      return s === key(a.value);
                                    })), (function (a) {
                                  return a.name;
                                }));
                  })).join(argsDelimeter);
  }
}

function objectFilters$1(varFilters, substitution) {
  if (varFilters.length === 0) {
    return "";
  }
  switch (substitution.TAG) {
    case "Object" :
        var filters = substitution._0.filters;
        if (filters.length === 0) {
          return "";
        }
        var filterStr = RCore.$$Array.filterMap(varFilters, (function (a) {
                  return RCore.$$Option.map(RCore.$$Array.getBy(filters, (function (f) {
                                    return f.key === a.name;
                                  })), (function (s) {
                                return s.name + argsFilter(a.args, s.args);
                              }));
                })).join(filterDelimeter);
        if (filterStr === "") {
          return "";
        } else {
          return filterDelimeter + filterStr;
        }
    case "String" :
    case "Datetime" :
        return "";
    
  }
}

function formatVariable(ctx, substitutions, variable) {
  var substitutions$1 = {
    contents: substitutions
  };
  var hasError = {
    contents: false
  };
  var formatted = RCore.$$Array.reduce(variable.lookups, "", (function (formatted, lookup) {
          var substitution = RCore.$$Array.getBy(substitutions$1.contents, (function (substitution) {
                  return key$2(substitution) === lookup;
                }));
          if (substitution !== undefined) {
            if (formatted === "") {
              substitutions$1.contents = RCore.$$Option.getOr(children(substitution), []);
              return name$3(substitution) + objectFilters$1(variable.filters, substitution);
            } else {
              substitutions$1.contents = RCore.$$Option.getOr(children(substitution), []);
              return formatted + lookupDelimeter + name$3(substitution) + objectFilters$1(variable.filters, substitution);
            }
          } else {
            hasError.contents = true;
            return formatted;
          }
        }));
  var formattedLookups = hasError.contents ? variable.lookups.join(filterDelimeter) : formatted;
  var datetimeSuggestions = datetimeFilters(ctx);
  var formattedFilters = RCore.$$Array.filterMap(variable.filters, (function (filter) {
            return RCore.$$Option.map(RCore.$$Array.getBy(datetimeSuggestions, (function (f) {
                              return key$3(f) === filter.name;
                            })), (function (s) {
                          return s._0.name;
                        }));
          })).join(filterDelimeter);
  return formattedLookups + (
          formattedFilters === "" ? "" : filterDelimeter
        ) + formattedFilters;
}

var Suggestion = {
  Subtitution: Subtitution,
  name: name$4,
  key: key$3,
  make: make,
  hasSuggestions: hasSuggestions
};

export {
  SlateProps ,
  SlateElement ,
  lookupDelimeter ,
  filterDelimeter ,
  filterArgsDelimeter ,
  argsDelimeter ,
  Filter ,
  Substitution ,
  Suggestion ,
  formatVariable ,
}
/* Types Not a pure module */
