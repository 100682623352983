

import * as Liquid_Body from "./Liquid_Body.mjs";

function parse(source) {
  var body = Liquid_Body.parse(source);
  if (body.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              source: source,
              body: body._0
            }
          };
  } else {
    return {
            TAG: "Error",
            _0: body._0
          };
  }
}

export {
  parse ,
}
/* Liquid_Body Not a pure module */
