

import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as Pages_Clients_Types from "./Pages_Clients_Types.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              clients: field.required("clients", JsonDecode.array(Pages_Clients_Types.Client.decode)),
              totalClients: field.required("totalClients", JsonDecode.$$int),
              totalSites: field.required("totalSites", JsonDecode.$$int),
              isAdmin: field.required("isAdmin", JsonDecode.bool)
            };
    });

function get(wire, filter) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Client",
                    VAL: {
                      NAME: "Index",
                      VAL: filter
                    }
                  }
                }), decode);
}

var $$Response = {};

export {
  $$Response ,
  get ,
}
/* decode Not a pure module */
