

import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Router from "../../Router.mjs";
import * as Select from "../../components/inputs/Select.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as Checkbox from "../../components/inputs/Checkbox.mjs";
import * as FormInput from "../../components/inputs/FormInput.mjs";
import * as AppContext from "../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as BaseFormInput from "../../components/inputs/BaseFormInput.mjs";
import * as Pages_Activation_API from "./Pages_Activation_API.mjs";
import * as Pages_Activation_Types from "./Pages_Activation_Types.mjs";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";
import * as Pages_Activation_FormModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/activation/Pages_Activation_Form.module.css";

var t = Utils.Translations.t;

var tr = Utils.Translations.tr;

var tx = Utils.Translations.tx;

var country$p = t("js.activation.country");

var language$p = t("js.activation.language");

var email$p = t("js.activation.email");

var password$p = t("js.activation.password");

var passwordConfirmation$p = t("js.activation.password_confirmation");

var submit$p = tr("js.activation.submit");

var terms$p = t("js.activation.accept_terms_html");

function termsWhitelabel$p(license, ctx) {
  return tx("js.activation.accept_terms_html")({
              license: license
            }, ctx);
}

var timezone$p = t("js.activation.timezone");

var title$p = tr("js.activation.title");

var styles = Pages_Activation_FormModuleCss;

function map(variants) {
  return variants.map(function (v) {
              return {
                      label: v.name,
                      value: v.code
                    };
            });
}

function Pages_Activation_Form$Wrapper(Props) {
  var children = Props.children;
  var match = AppContext.useWire();
  var match$1 = match.ctx.branding;
  var logo;
  var exit = 0;
  if (match$1 !== undefined) {
    var logo$1 = match$1.logo;
    if (logo$1 !== undefined) {
      logo = React.createElement("a", {
            className: styles.logoWhiteLabel,
            href: "/"
          }, React.createElement("img", {
                src: logo$1
              }));
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    logo = React.createElement("a", {
          className: styles.logo,
          href: "/"
        });
  }
  return React.createElement("div", {
              className: styles.wrapper
            }, React.createElement("div", {
                  className: styles.header
                }, logo), React.createElement("div", {
                  className: styles.form
                }, children));
}

function Pages_Activation_Form(Props) {
  var initialState = Props.initialState;
  var token = Props.token;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return initialState;
      });
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setLocked = match$1[1];
  var match$2 = React.useState(function () {
        return {
                user: undefined,
                tenant: undefined
              };
      });
  var setErrors = match$2[1];
  var errors = match$2[0];
  var onSubmit = function ($$event) {
    $$event.preventDefault();
    setLocked(function (param) {
          return true;
        });
    Backend.$$finally(Pages_Activation_API.Patch.update(wire, token, state), (function (r) {
            if (r.TAG === "Ok") {
              Router.redirectToRoot(wire);
            } else {
              var match = r._0;
              if (typeof match === "object" && match.TAG === "HTTPError") {
                var match$1 = match._0;
                var tmp = match$1[0];
                if (typeof tmp !== "object" && tmp === "UnprocessableEntity") {
                  var e = Json_Decode$JsonCombinators.decode(JSON.parse(match$1[1]), Pages_Activation_Types.Patch.$$Response.decode);
                  if (e.TAG === "Ok") {
                    var e$1 = e._0.errors;
                    if (e$1 !== undefined) {
                      setErrors(function (param) {
                            return e$1;
                          });
                    } else {
                      console.log("Failed to parse errors");
                      console.log(e);
                    }
                  } else {
                    console.log("Failed to parse errors");
                    console.log(e);
                  }
                }
                
              }
              
            }
            setLocked(function (param) {
                  return false;
                });
          }));
  };
  var isValidForm = state.isChecked && state.password !== "" && state.password === state.passwordConfirmation;
  var match$3 = errors.user;
  var tmp;
  if (match$3 !== undefined) {
    var e = match$3.password;
    tmp = e !== undefined ? e : [];
  } else {
    tmp = [];
  }
  var match$4 = errors.user;
  var tmp$1;
  if (match$4 !== undefined) {
    var e$1 = match$4.passwordConfirmation;
    tmp$1 = e$1 !== undefined ? e$1 : [];
  } else {
    tmp$1 = [];
  }
  var match$5 = errors.tenant;
  var tmp$2;
  if (match$5 !== undefined) {
    var e$2 = match$5.country;
    tmp$2 = e$2 !== undefined ? e$2 : [];
  } else {
    tmp$2 = [];
  }
  var match$6 = errors.tenant;
  var tmp$3;
  if (match$6 !== undefined) {
    var e$3 = match$6.language;
    tmp$3 = e$3 !== undefined ? e$3 : [];
  } else {
    tmp$3 = [];
  }
  var match$7 = errors.tenant;
  var tmp$4;
  if (match$7 !== undefined) {
    var e$4 = match$7.timezone;
    tmp$4 = e$4 !== undefined ? e$4 : [];
  } else {
    tmp$4 = [];
  }
  var match$8 = ctx.branding;
  var tmp$5;
  if (match$8 !== undefined) {
    var match$9 = match$8.urls;
    tmp$5 = match$9 !== undefined ? termsWhitelabel$p(match$9.license, ctx) : terms$p(ctx);
  } else {
    tmp$5 = terms$p(ctx);
  }
  return React.createElement(Pages_Activation_Form$Wrapper, {
              children: React.createElement("form", {
                    onSubmit: onSubmit
                  }, React.createElement("div", {
                        className: "panel-heading"
                      }, React.createElement("div", {
                            className: "panel-title"
                          }, title$p(ctx))), React.createElement("div", {
                        className: "panel-body"
                      }, React.createElement(FormInput.make, {
                            _type: "email",
                            value: state.email,
                            onValueChange: (function (email) {
                                setState(function (current) {
                                      return {
                                              email: email,
                                              password: current.password,
                                              passwordConfirmation: current.passwordConfirmation,
                                              isChecked: current.isChecked,
                                              activationCode: current.activationCode,
                                              timezone: current.timezone,
                                              country: current.country,
                                              language: current.language,
                                              timezoneVariants: current.timezoneVariants,
                                              countryVariants: current.countryVariants,
                                              languageVariants: current.languageVariants,
                                              branding: current.branding
                                            };
                                    });
                              }),
                            readOnly: true,
                            labelText: email$p(ctx)
                          }), React.createElement(FormInput.make, {
                            _type: "password",
                            value: state.password,
                            onValueChange: (function (password) {
                                setState(function (current) {
                                      return {
                                              email: current.email,
                                              password: password,
                                              passwordConfirmation: current.passwordConfirmation,
                                              isChecked: current.isChecked,
                                              activationCode: current.activationCode,
                                              timezone: current.timezone,
                                              country: current.country,
                                              language: current.language,
                                              timezoneVariants: current.timezoneVariants,
                                              countryVariants: current.countryVariants,
                                              languageVariants: current.languageVariants,
                                              branding: current.branding
                                            };
                                    });
                              }),
                            labelText: password$p(ctx),
                            errors: tmp,
                            autoFocus: true
                          }), React.createElement(FormInput.make, {
                            _type: "password",
                            value: state.passwordConfirmation,
                            onValueChange: (function (passwordConfirmation) {
                                setState(function (current) {
                                      return {
                                              email: current.email,
                                              password: current.password,
                                              passwordConfirmation: passwordConfirmation,
                                              isChecked: current.isChecked,
                                              activationCode: current.activationCode,
                                              timezone: current.timezone,
                                              country: current.country,
                                              language: current.language,
                                              timezoneVariants: current.timezoneVariants,
                                              countryVariants: current.countryVariants,
                                              languageVariants: current.languageVariants,
                                              branding: current.branding
                                            };
                                    });
                              }),
                            labelText: passwordConfirmation$p(ctx),
                            errors: tmp$1
                          }), React.createElement(BaseFormInput.make, {
                            id: "country",
                            labelText: country$p(ctx),
                            errors: tmp$2,
                            children: React.createElement(Select.make, {
                                  onChange: (function (v) {
                                      if (v !== null) {
                                        return setState(function (current) {
                                                    return {
                                                            email: current.email,
                                                            password: current.password,
                                                            passwordConfirmation: current.passwordConfirmation,
                                                            isChecked: current.isChecked,
                                                            activationCode: current.activationCode,
                                                            timezone: current.timezone,
                                                            country: Pages_Activation_Types.Variant.idToString(v),
                                                            language: current.language,
                                                            timezoneVariants: current.timezoneVariants,
                                                            countryVariants: current.countryVariants,
                                                            languageVariants: current.languageVariants,
                                                            branding: current.branding
                                                          };
                                                  });
                                      }
                                      
                                    }),
                                  value: Js_null.fromOption(Caml_option.some(Pages_Activation_Types.Variant.stringToId(state.country))),
                                  clearable: false,
                                  options: map(state.countryVariants)
                                })
                          }), React.createElement(BaseFormInput.make, {
                            id: "language",
                            labelText: language$p(ctx),
                            errors: tmp$3,
                            children: React.createElement(Select.make, {
                                  onChange: (function (v) {
                                      if (v !== null) {
                                        return setState(function (current) {
                                                    return {
                                                            email: current.email,
                                                            password: current.password,
                                                            passwordConfirmation: current.passwordConfirmation,
                                                            isChecked: current.isChecked,
                                                            activationCode: current.activationCode,
                                                            timezone: current.timezone,
                                                            country: current.country,
                                                            language: Pages_Activation_Types.Variant.idToString(v),
                                                            timezoneVariants: current.timezoneVariants,
                                                            countryVariants: current.countryVariants,
                                                            languageVariants: current.languageVariants,
                                                            branding: current.branding
                                                          };
                                                  });
                                      }
                                      
                                    }),
                                  value: Js_null.fromOption(Caml_option.some(Pages_Activation_Types.Variant.stringToId(state.language))),
                                  clearable: false,
                                  options: map(state.languageVariants)
                                })
                          }), React.createElement(BaseFormInput.make, {
                            id: "timezone",
                            labelText: timezone$p(ctx),
                            errors: tmp$4,
                            children: React.createElement(Select.make, {
                                  onChange: (function (v) {
                                      if (v !== null) {
                                        return setState(function (current) {
                                                    return {
                                                            email: current.email,
                                                            password: current.password,
                                                            passwordConfirmation: current.passwordConfirmation,
                                                            isChecked: current.isChecked,
                                                            activationCode: current.activationCode,
                                                            timezone: Pages_Activation_Types.Variant.idToString(v),
                                                            country: current.country,
                                                            language: current.language,
                                                            timezoneVariants: current.timezoneVariants,
                                                            countryVariants: current.countryVariants,
                                                            languageVariants: current.languageVariants,
                                                            branding: current.branding
                                                          };
                                                  });
                                      }
                                      
                                    }),
                                  value: Js_null.fromOption(Caml_option.some(Pages_Activation_Types.Variant.stringToId(state.timezone))),
                                  clearable: false,
                                  options: map(state.timezoneVariants)
                                })
                          }), React.createElement("div", {
                            className: "pd-form-group terms-accepted"
                          }, React.createElement(Checkbox.make, {
                                onToggle: (function (value) {
                                    setState(function (current) {
                                          return {
                                                  email: current.email,
                                                  password: current.password,
                                                  passwordConfirmation: current.passwordConfirmation,
                                                  isChecked: value,
                                                  activationCode: current.activationCode,
                                                  timezone: current.timezone,
                                                  country: current.country,
                                                  language: current.language,
                                                  timezoneVariants: current.timezoneVariants,
                                                  countryVariants: current.countryVariants,
                                                  languageVariants: current.languageVariants,
                                                  branding: current.branding
                                                };
                                        });
                                  }),
                                checked: state.isChecked,
                                children: React.createElement("span", {
                                      dangerouslySetInnerHTML: {
                                        __html: tmp$5
                                      }
                                    })
                              }))), React.createElement("div", {
                        className: "panel-footer footer"
                      }, React.createElement("button", {
                            className: "btn btn-primary btn",
                            disabled: !isValidForm || match$1[0],
                            type: "submit"
                          }, submit$p(ctx))))
            });
}

var make = Pages_Activation_Form;

export {
  make ,
}
/* country' Not a pure module */
