

import * as React from "react";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_DND from "../../../../lib/Schedule_DND.mjs";
import * as WeekCalendar_DragLayerActions from "./WeekCalendar_DragLayerActions.mjs";
import * as WeekCalendar_DragLayerElements from "./WeekCalendar_DragLayerElements.mjs";

function use(columnWidth) {
  var wire = AppContext.useWire();
  var match = Schedule_DND.DND.Context.use();
  var item = match.item;
  var subscriptions = match.subscriptions;
  var elements = React.useMemo(WeekCalendar_DragLayerElements.make, []);
  var onDragStart = function () {
    var match = item.contents;
    if (match === undefined) {
      return ;
    }
    var payload = Schedule_DND.DND.Context.payload(match.payload);
    WeekCalendar_DragLayerActions.showDragLayer(wire, columnWidth, elements, payload, match.dragPreviewOffset);
  };
  var rafId = React.useRef(undefined);
  var onDrag = function () {
    var id = rafId.current;
    if (id !== undefined) {
      cancelAnimationFrame(Caml_option.valFromOption(id));
    }
    rafId.current = Caml_option.some(requestAnimationFrame(function (_time) {
              var match = item.contents;
              if (match !== undefined) {
                WeekCalendar_DragLayerActions.setJobPosition(elements, match.dragPreviewOffset);
              }
              rafId.current = undefined;
            }));
  };
  var onDragEnd = function () {
    WeekCalendar_DragLayerActions.hideDragLayer(elements);
  };
  React.useEffect((function () {
          wire.footer.appendChild(elements.containerEl);
          var unsubscribeFromDragStart = subscriptions.onDragStart.subscribe(onDragStart);
          var unsubscribeFromDrag = subscriptions.onDrag.subscribe(onDrag);
          var unsubscribeFromDragEnd = subscriptions.onDragEnd.subscribe(onDragEnd);
          return (function () {
                    wire.footer.removeChild(elements.containerEl);
                    unsubscribeFromDragStart();
                    unsubscribeFromDrag();
                    unsubscribeFromDragEnd();
                  });
        }), []);
}

function WeekCalendar_DragLayer(Props) {
  var columnWidth = Props.columnWidth;
  use(columnWidth);
  return null;
}

var make = React.memo(WeekCalendar_DragLayer);

export {
  make ,
}
/* make Not a pure module */
