

import * as El from "../../../../../../libraries/El.mjs";
import * as Uuid from "../../../../../../bindings/uuid/Uuid.mjs";
import * as Wire from "../../../../../../types/Wire.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as React from "react";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as Optional from "../../../../../common/Optional.mjs";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_DND from "../../../../lib/Schedule_DND.mjs";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as WeekCalendar_Job from "../job/WeekCalendar_Job.mjs";
import * as WeekCalendar_Cell from "../cell/WeekCalendar_Cell.mjs";
import * as Schedule_Types_Job from "../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as WeekCalendar_JobGroups from "../job_groups/WeekCalendar_JobGroups.mjs";
import * as WeekCalendar_VirtualCell from "../virtual_cell/WeekCalendar_VirtualCell.mjs";
import * as WeekCalendar_JobPositions from "../../lib/WeekCalendar_JobPositions.mjs";
import * as Schedule_UtilsBusinessHours from "../../../../lib/utils/Schedule_UtilsBusinessHours.mjs";
import * as WeekCalendar_ColumnModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_calendar/components/column/WeekCalendar_Column.module.css";

var styles = WeekCalendar_ColumnModuleCss;

function WeekCalendar_Column$Cells(Props) {
  var weekDay = Props.weekDay;
  var wire = AppContext.useWire();
  var businessHours = Schedule_State.useBusinessHours();
  var startOfDay = Schedule_UtilsBusinessHours.startOfDay(Caml_option.some(weekDay), wire, businessHours);
  var hours = RCore.$$Array.make(Schedule_UtilsBusinessHours.hoursInDay(businessHours), startOfDay).map(function (hour, idx) {
        return Locale.T.nextMinutes(Math.imul(idx, 60), hour);
      });
  return React.createElement("div", {
              className: styles.cells
            }, hours.map(function (hour, idx) {
                  return React.createElement(WeekCalendar_Cell.make, {
                              hour: hour,
                              key: idx.toString()
                            });
                }));
}

function WeekCalendar_Column$VirtualCells(Props) {
  var weekDay = Props.weekDay;
  var wire = AppContext.useWire();
  var businessHours = Schedule_State.useBusinessHours();
  var match = Schedule_DND.DND.Context.use();
  var subscriptions = match.subscriptions;
  var match$1 = React.useState(function () {
        return false;
      });
  var setShow = match$1[1];
  React.useEffect((function () {
          var unsubscribeFromDragStart = subscriptions.onDragStart.subscribe(function () {
                setShow(function (param) {
                      return true;
                    });
              });
          var unsubscribeFromDragEnd = subscriptions.onDragEnd.subscribe(function () {
                setShow(function (param) {
                      return false;
                    });
              });
          return (function () {
                    unsubscribeFromDragStart();
                    unsubscribeFromDragEnd();
                  });
        }), []);
  var startOfDay = Schedule_UtilsBusinessHours.startOfDay(Caml_option.some(weekDay), wire, businessHours);
  var hours = RCore.$$Array.make((Schedule_UtilsBusinessHours.hoursInDay(businessHours) << 1), startOfDay).map(function (hour, idx) {
        return Locale.T.nextMinutes(Math.imul(idx, 30), hour);
      });
  return React.createElement(Optional.make, {
              show: match$1[0],
              children: React.createElement("div", {
                    className: styles.cells
                  }, hours.map(function (hour, idx) {
                        return React.createElement(WeekCalendar_VirtualCell.make, {
                                    hour: hour,
                                    key: idx.toString()
                                  });
                      }))
            });
}

var make = React.memo(WeekCalendar_Column$VirtualCells);

function WeekCalendar_Column$Jobs(Props) {
  var jobs = Props.jobs;
  return jobs.map(function (param) {
              var job = param[1];
              return React.createElement(WeekCalendar_Job.make, {
                          position: param[0],
                          job: job,
                          key: Uuid.toString(Schedule_Types_Job.ScheduledJob.uuid(job))
                        });
            });
}

function WeekCalendar_Column(Props) {
  var calendarRef = Props.calendarRef;
  var jobs = Props.jobs;
  var columnWidth = Props.columnWidth;
  var weekDay = Props.weekDay;
  var wire = AppContext.useWire();
  var businessHours = Schedule_State.useBusinessHours();
  var match = React.useState(function () {
        return WeekCalendar_JobPositions.positions(wire, businessHours, weekDay, columnWidth, jobs);
      });
  var setState = match[1];
  var jobPositions = match[0];
  React.useEffect((function () {
          setState(function (param) {
                return WeekCalendar_JobPositions.positions(wire, businessHours, weekDay, columnWidth, jobs);
              });
        }), [
        jobs,
        columnWidth,
        businessHours
      ]);
  var className = El.Cn.concat(styles.column, RCore.$$Option.isNone(Wire.businessHours(wire)) ? styles.withoutBorder : "");
  return React.createElement("div", {
              className: className,
              style: {
                width: columnWidth.toString() + "px"
              }
            }, React.createElement(WeekCalendar_Column$Cells, {
                  weekDay: weekDay
                }), React.createElement(WeekCalendar_Column$Jobs, {
                  jobs: jobPositions
                }), React.createElement(WeekCalendar_JobGroups.make, {
                  calendarRef: calendarRef,
                  columnWidth: columnWidth,
                  weekDay: weekDay,
                  jobs: jobPositions
                }), React.createElement(make, {
                  weekDay: weekDay
                }));
}

var make$1 = WeekCalendar_Column;

export {
  make$1 as make,
}
/* styles Not a pure module */
