

import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as AppContext from "../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Types_Address from "../types/Types_Address.mjs";
import * as AddressInput_Types from "../components/address_input/AddressInput_Types.mjs";
import * as AddressInput_RegularAddress from "../components/address_input/regular_address/AddressInput_RegularAddress.mjs";
import * as IndexJsx from "planado/components/address_input/index.jsx";

function UI_AddressField(Props) {
  var suggestionsAmount = Props.suggestionsAmount;
  var constraints = Props.constraints;
  var inputNameOpt = Props.inputName;
  var readOnly = Props.readOnly;
  var address = Props.address;
  var onChangeDadata = Props.onChangeDadata;
  var onChangeGoogle = Props.onChangeGoogle;
  var inputName = inputNameOpt !== undefined ? inputNameOpt : "address";
  var wire = AppContext.useWire();
  var match = wire.ctx.addressSourceOptions;
  var tmp;
  if (match === "Dadata") {
    tmp = React.createElement(AddressInput_RegularAddress.make, {
          wire: wire,
          readOnly: readOnly,
          constraints: constraints,
          initialAddress: AddressInput_Types.RegularAddress.makeJs(address !== undefined ? Types_Address.toInputType(address) : AddressInput_Types.RegularAddress.makeFromString("")),
          onChange: onChangeDadata,
          suggestionsAmount: suggestionsAmount
        });
  } else {
    var tmp$1 = {
      inputName: inputName,
      wire: wire,
      readOnly: readOnly,
      onChange: onChangeGoogle,
      wrapperClass: Js_dict.fromList({
            hd: [
              "pd-form-group",
              true
            ],
            tl: /* [] */0
          }),
      attributes: Types_Address.encode(address)
    };
    if (constraints !== undefined) {
      tmp$1.constraints = Caml_option.valFromOption(constraints);
    }
    tmp = React.createElement(IndexJsx.GoogleAddressField, tmp$1);
  }
  return React.createElement("div", {
              className: "pd-form-group-container"
            }, tmp);
}

var make = UI_AddressField;

export {
  make ,
}
/* react Not a pure module */
