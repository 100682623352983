

import * as Wire from "../../../../../types/Wire.mjs";
import * as JsonDecode from "../../../../../libraries/JsonDecode.mjs";
import * as Types_Date from "../../../../../types/Types_Date.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Assignee_Types from "../../../../../types/Assignee_Types.mjs";
import * as MultipleAssignee from "../../../../../types/MultipleAssignee.mjs";
import * as Schedule_Types_JobCore from "./Schedule_Types_JobCore.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              startAt: field.required("lower", Types_Date.decode),
              finishAt: field.required("upper", Types_Date.decode)
            };
    });

function startAt(param) {
  return param.startAt;
}

function finishAt(param) {
  return param.finishAt;
}

var JobInterval = {
  decode: decode,
  startAt: startAt,
  finishAt: finishAt
};

function decode$1(jobDuration) {
  var intervalKey = jobDuration === "Scheduled" ? "scheduledInterval" : "actualInterval";
  return JsonDecode.object(function (field) {
              return {
                      core: Schedule_Types_JobCore.decode(field),
                      interval: field.required(intervalKey, JsonDecode.option(decode)),
                      bar: field.required("assignee", JsonDecode.option(Assignee_Types.Assignee.decode)),
                      assignees: field.required("assignees", MultipleAssignee.decode)
                    };
            });
}

function core(param) {
  return param.core;
}

function territory(v) {
  return Schedule_Types_JobCore.territory(core(v));
}

function uuid(v) {
  return Schedule_Types_JobCore.uuid(core(v));
}

function interval(param) {
  return param.interval;
}

function scheduledAt(param) {
  return param.core.scheduledAt;
}

function bar(param) {
  return param.bar;
}

function assignees(param) {
  return param.assignees;
}

var DecodedJob = {
  decode: decode$1,
  core: core,
  territory: territory,
  uuid: uuid,
  interval: interval,
  scheduledAt: scheduledAt,
  bar: bar,
  assignees: assignees
};

function make(core, interval, assignees) {
  return {
          core: core,
          interval: interval,
          assignees: assignees
        };
}

function core$1(param) {
  return param.core;
}

function uuid$1(v) {
  return Schedule_Types_JobCore.uuid(core$1(v));
}

function status(v) {
  return Schedule_Types_JobCore.status(core$1(v));
}

function duration(v) {
  return Schedule_Types_JobCore.duration(core$1(v));
}

function template(v) {
  return Schedule_Types_JobCore.template(core$1(v));
}

function client(v) {
  return Schedule_Types_JobCore.client(core$1(v));
}

function territory$1(v) {
  return Schedule_Types_JobCore.territory(core$1(v));
}

function address(v) {
  return Schedule_Types_JobCore.address(core$1(v));
}

function scheduledAt$1(v) {
  return Schedule_Types_JobCore.scheduledAt(core$1(v));
}

function resolution(v) {
  return Schedule_Types_JobCore.resolution(core$1(v));
}

function hasRightsToEdit(job, wire) {
  var status$1 = status(job);
  if (Wire.hasRightsToEditJobs(wire) && status$1 !== "EnRoute" && status$1 !== "Started" && status$1 !== "Suspended") {
    return status$1 !== "Finished";
  } else {
    return false;
  }
}

function interval$1(param) {
  return param.interval;
}

function startAt$1(v) {
  return startAt(interval$1(v));
}

function finishAt$1(v) {
  return finishAt(interval$1(v));
}

function assignees$1(param) {
  return param.assignees;
}

function make$1(core, interval) {
  return {
          core: core,
          interval: interval
        };
}

function core$2(param) {
  return param.core;
}

function uuid$2(v) {
  return Schedule_Types_JobCore.uuid(core$2(v));
}

function status$1(v) {
  return Schedule_Types_JobCore.status(core$2(v));
}

function duration$1(v) {
  return Schedule_Types_JobCore.duration(core$2(v));
}

function template$1(v) {
  return Schedule_Types_JobCore.template(core$2(v));
}

function client$1(v) {
  return Schedule_Types_JobCore.client(core$2(v));
}

function territory$2(v) {
  return Schedule_Types_JobCore.territory(core$2(v));
}

function address$1(v) {
  return Schedule_Types_JobCore.address(core$2(v));
}

function scheduledAt$2(v) {
  return Schedule_Types_JobCore.scheduledAt(core$2(v));
}

function scheduledFinishAt(v) {
  return Schedule_Types_JobCore.scheduledFinishAt(core$2(v));
}

function enRouteAt(v) {
  return Schedule_Types_JobCore.enRouteAt(core$2(v));
}

function suspendedAt(v) {
  return Schedule_Types_JobCore.suspendedAt(core$2(v));
}

function finishedAt(v) {
  return Schedule_Types_JobCore.finishedAt(core$2(v));
}

function resolution$1(v) {
  return Schedule_Types_JobCore.resolution(core$2(v));
}

function hasRightsToEdit$1(job, wire) {
  var status$2 = status$1(job);
  if (Wire.hasRightsToEditJobs(wire) && status$2 !== "EnRoute" && status$2 !== "Started" && status$2 !== "Suspended") {
    return status$2 !== "Finished";
  } else {
    return false;
  }
}

function title(v) {
  return Schedule_Types_JobCore.title(core$2(v));
}

function interval$2(param) {
  return param.interval;
}

function startAt$2(v) {
  return startAt(interval$2(v));
}

function finishAt$2(v) {
  return finishAt(interval$2(v));
}

var UnassignedJob = {
  make: make$1,
  core: core$2,
  uuid: uuid$2,
  status: status$1,
  duration: duration$1,
  template: template$1,
  client: client$1,
  territory: territory$2,
  address: address$1,
  scheduledAt: scheduledAt$2,
  scheduledFinishAt: scheduledFinishAt,
  enRouteAt: enRouteAt,
  suspendedAt: suspendedAt,
  finishedAt: finishedAt,
  resolution: resolution$1,
  hasRightsToEdit: hasRightsToEdit$1,
  title: title,
  interval: interval$2,
  startAt: startAt$2,
  finishAt: finishAt$2
};

function assigned(scheduledJob) {
  if (scheduledJob.TAG === "Assigned") {
    return scheduledJob._0;
  }
  
}

function isAssigned(scheduledJob) {
  if (scheduledJob.TAG === "Assigned") {
    return true;
  } else {
    return false;
  }
}

function unassigned(scheduledJob) {
  if (scheduledJob.TAG === "Assigned") {
    return ;
  } else {
    return scheduledJob._0;
  }
}

function isUnassigned(scheduledJob) {
  if (scheduledJob.TAG === "Assigned") {
    return false;
  } else {
    return true;
  }
}

function make$2(assignees, core, interval) {
  if (assignees.length > 0) {
    return {
            TAG: "Assigned",
            _0: {
              core: core,
              interval: interval,
              assignees: assignees
            }
          };
  } else {
    return {
            TAG: "Unassigned",
            _0: {
              core: core,
              interval: interval
            }
          };
  }
}

function core$3(scheduledJob) {
  return scheduledJob._0.core;
}

function uuid$3(v) {
  return Schedule_Types_JobCore.uuid(v._0.core);
}

function status$2(v) {
  return Schedule_Types_JobCore.status(v._0.core);
}

function duration$2(v) {
  return Schedule_Types_JobCore.duration(v._0.core);
}

function template$2(v) {
  return Schedule_Types_JobCore.template(v._0.core);
}

function client$2(v) {
  return Schedule_Types_JobCore.client(v._0.core);
}

function territory$3(v) {
  return Schedule_Types_JobCore.territory(v._0.core);
}

function address$2(v) {
  return Schedule_Types_JobCore.address(v._0.core);
}

function scheduledAt$3(v) {
  return Schedule_Types_JobCore.scheduledAt(v._0.core);
}

function scheduledFinishAt$1(v) {
  return Schedule_Types_JobCore.scheduledFinishAt(v._0.core);
}

function enRouteAt$1(v) {
  return Schedule_Types_JobCore.enRouteAt(v._0.core);
}

function suspendedAt$1(v) {
  return Schedule_Types_JobCore.suspendedAt(v._0.core);
}

function finishedAt$1(v) {
  return Schedule_Types_JobCore.finishedAt(v._0.core);
}

function resolution$2(v) {
  return Schedule_Types_JobCore.resolution(v._0.core);
}

function hasRightsToEdit$2(job, wire) {
  var status$3 = status$2(job);
  if (Wire.hasRightsToEditJobs(wire) && status$3 !== "EnRoute" && status$3 !== "Started" && status$3 !== "Suspended") {
    return status$3 !== "Finished";
  } else {
    return false;
  }
}

function title$1(v) {
  return Schedule_Types_JobCore.title(v._0.core);
}

function interval$3(scheduledJob) {
  return scheduledJob._0.interval;
}

function $$setInterval(job, interval) {
  if (job.TAG !== "Assigned") {
    return {
            TAG: "Unassigned",
            _0: {
              core: job._0.core,
              interval: interval
            }
          };
  }
  var match = job._0;
  return {
          TAG: "Assigned",
          _0: {
            core: match.core,
            interval: interval,
            assignees: match.assignees
          }
        };
}

function setScheduledAt(job, scheduledAt) {
  if (job.TAG === "Assigned") {
    var match = job._0;
    var core = match.core;
    return {
            TAG: "Assigned",
            _0: {
              core: {
                uuid: core.uuid,
                status: core.status,
                duration: core.duration,
                template: core.template,
                client: core.client,
                territory: core.territory,
                skillUuids: core.skillUuids,
                address: core.address,
                scheduledAt: scheduledAt,
                enRouteAt: core.enRouteAt,
                startedAt: core.startedAt,
                suspendedAt: core.suspendedAt,
                finishedAt: core.finishedAt,
                resolution: core.resolution,
                customUi: core.customUi
              },
              interval: match.interval,
              assignees: match.assignees
            }
          };
  }
  var match$1 = job._0;
  var core$1 = match$1.core;
  return {
          TAG: "Unassigned",
          _0: {
            core: {
              uuid: core$1.uuid,
              status: core$1.status,
              duration: core$1.duration,
              template: core$1.template,
              client: core$1.client,
              territory: core$1.territory,
              skillUuids: core$1.skillUuids,
              address: core$1.address,
              scheduledAt: scheduledAt,
              enRouteAt: core$1.enRouteAt,
              startedAt: core$1.startedAt,
              suspendedAt: core$1.suspendedAt,
              finishedAt: core$1.finishedAt,
              resolution: core$1.resolution,
              customUi: core$1.customUi
            },
            interval: match$1.interval
          }
        };
}

function startAt$3(v) {
  return startAt(v._0.interval);
}

function finishAt$3(v) {
  return finishAt(v._0.interval);
}

function assignees$2(scheduledJob) {
  if (scheduledJob.TAG === "Assigned") {
    return scheduledJob._0.assignees;
  } else {
    return [];
  }
}

function isEnRoute(job) {
  return status$2(job) === "EnRoute";
}

function isStarted(job) {
  return status$2(job) === "Started";
}

function isSuspended(job) {
  return status$2(job) === "Suspended";
}

function isFinished(job) {
  return status$2(job) === "Finished";
}

function territoryUuid(job) {
  if (job.TAG === "Assigned") {
    var t = job._0.core.territory;
    if (t !== undefined) {
      return Caml_option.some(t.uuid);
    } else {
      return ;
    }
  }
  var t$1 = job._0.core.territory;
  if (t$1 !== undefined) {
    return Caml_option.some(t$1.uuid);
  }
  
}

function skillUuids(job) {
  return job._0.core.skillUuids;
}

var ScheduledJob = {
  assigned: assigned,
  isAssigned: isAssigned,
  unassigned: unassigned,
  isUnassigned: isUnassigned,
  make: make$2,
  core: core$3,
  uuid: uuid$3,
  status: status$2,
  duration: duration$2,
  template: template$2,
  client: client$2,
  territory: territory$3,
  address: address$2,
  scheduledAt: scheduledAt$3,
  scheduledFinishAt: scheduledFinishAt$1,
  enRouteAt: enRouteAt$1,
  suspendedAt: suspendedAt$1,
  finishedAt: finishedAt$1,
  resolution: resolution$2,
  hasRightsToEdit: hasRightsToEdit$2,
  title: title$1,
  interval: interval$3,
  $$setInterval: $$setInterval,
  setScheduledAt: setScheduledAt,
  startAt: startAt$3,
  finishAt: finishAt$3,
  assignees: assignees$2,
  isEnRoute: isEnRoute,
  isStarted: isStarted,
  isSuspended: isSuspended,
  isFinished: isFinished,
  territoryUuid: territoryUuid,
  skillUuids: skillUuids
};

function make$3(core, assignees) {
  return {
          core: core,
          assignees: assignees
        };
}

function core$4(param) {
  return param.core;
}

function uuid$4(v) {
  return Schedule_Types_JobCore.uuid(core$4(v));
}

function status$3(v) {
  return Schedule_Types_JobCore.status(core$4(v));
}

function duration$3(v) {
  return Schedule_Types_JobCore.duration(core$4(v));
}

function template$3(v) {
  return Schedule_Types_JobCore.template(core$4(v));
}

function client$3(v) {
  return Schedule_Types_JobCore.client(core$4(v));
}

function territory$4(v) {
  return Schedule_Types_JobCore.territory(core$4(v));
}

function address$3(v) {
  return Schedule_Types_JobCore.address(core$4(v));
}

function scheduledAt$4(v) {
  return Schedule_Types_JobCore.scheduledAt(core$4(v));
}

function scheduledFinishAt$2(v) {
  return Schedule_Types_JobCore.scheduledFinishAt(core$4(v));
}

function enRouteAt$2(v) {
  return Schedule_Types_JobCore.enRouteAt(core$4(v));
}

function suspendedAt$2(v) {
  return Schedule_Types_JobCore.suspendedAt(core$4(v));
}

function finishedAt$2(v) {
  return Schedule_Types_JobCore.finishedAt(core$4(v));
}

function resolution$3(v) {
  return Schedule_Types_JobCore.resolution(core$4(v));
}

function hasRightsToEdit$3(job, wire) {
  var status$4 = status$3(job);
  if (Wire.hasRightsToEditJobs(wire) && status$4 !== "EnRoute" && status$4 !== "Started" && status$4 !== "Suspended") {
    return status$4 !== "Finished";
  } else {
    return false;
  }
}

function assignees$3(param) {
  return param.assignees;
}

function title$2(v) {
  return Schedule_Types_JobCore.title(core$4(v));
}

function decode$2(jobDuration) {
  return JsonDecode.map(decode$1(jobDuration), (function (r) {
                return {
                        core: r.core,
                        assignees: r.assignees
                      };
              }));
}

var UnscheduledJob = {
  make: make$3,
  core: core$4,
  uuid: uuid$4,
  status: status$3,
  duration: duration$3,
  template: template$3,
  client: client$3,
  territory: territory$4,
  address: address$3,
  scheduledAt: scheduledAt$4,
  scheduledFinishAt: scheduledFinishAt$2,
  enRouteAt: enRouteAt$2,
  suspendedAt: suspendedAt$2,
  finishedAt: finishedAt$2,
  resolution: resolution$3,
  hasRightsToEdit: hasRightsToEdit$3,
  assignees: assignees$3,
  title: title$2,
  decode: decode$2
};

var BarAssignee = {};

var DraggableJob = {};

function fromInt(n) {
  return n;
}

function toInt(n) {
  return n;
}

var decode$3 = JsonDecode.map(JsonDecode.$$int, fromInt);

function fromJobs(jobs, limit) {
  if (jobs.length >= limit) {
    return {
            TAG: "Shown",
            _0: limit
          };
  } else {
    return "Hidden";
  }
}

var decode$4 = JsonDecode.map(JsonDecode.$$int, (function (prim) {
        return prim;
      }));

var JobCore;

var AssignedJob = {
  make: make,
  core: core$1,
  uuid: uuid$1,
  status: status,
  duration: duration,
  template: template,
  client: client,
  territory: territory$1,
  address: address,
  scheduledAt: scheduledAt$1,
  resolution: resolution,
  hasRightsToEdit: hasRightsToEdit,
  startAt: startAt$1,
  finishAt: finishAt$1,
  assignees: assignees$1
};

var Warning_Limit = {
  toInt: toInt,
  decode: decode$3
};

var Warning = {
  Limit: Warning_Limit,
  fromJobs: fromJobs
};

function JobsAfter_toInt(prim) {
  return prim;
}

var JobsAfter = {
  toInt: JobsAfter_toInt,
  decode: decode$4
};

export {
  JobCore ,
  JobInterval ,
  DecodedJob ,
  AssignedJob ,
  UnassignedJob ,
  ScheduledJob ,
  UnscheduledJob ,
  BarAssignee ,
  DraggableJob ,
  Warning ,
  JobsAfter ,
}
/* decode Not a pure module */
