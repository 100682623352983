import React from 'react'
import classNames from 'classnames'
import Link from 'rscrpt/components/common/Link/Link.mjs'
import { withContext } from 'planado/utils/contextConsumer.jsx'

const DropDown = withContext(({ ctx, items, itemPath }) => (
  <ul className="dropdown-button__menu">
    {items.map(({ id, name }) => (
      <li key={id}>
        <Link
          wire={ctx.wire}
          {...itemPath(id)}
          className="dropdown-button__item"
        >
          {name}
        </Link>
      </li>
    ))}
  </ul>
))

const DropDownButton = ({
  classes = {},
  items = [],
  dom = {},
  text = '',
  itemPath = () => {},
  showPopover = () => {},
}) => {
  const buttonId = 'dropdown-button'

  const onClick = (evt) => {
    showPopover(
      <DropDown items={items} itemPath={itemPath} />,
      dom,
      evt,
      buttonId
    )
  }

  const buttonClasses = classNames({
    'dropdown-button__button': true,
    btn: true,
    'btn-32px': true,
    ...classes,
  })

  return (
    <div className="dropdown-button">
      <button
        className={buttonClasses}
        onClick={onClick}
        id={buttonId}
        type="button"
      >
        {text}
      </button>
    </div>
  )
}

export default DropDownButton
