

import * as React from "react";
import * as Schedule_Popover from "../../../../../components/popovers/popover/Schedule_Popover.mjs";
import * as Schedule_JobPreview from "../../../../../components/popovers/job_preview/Schedule_JobPreview.mjs";

function WeekCalendar_JobPreview(Props) {
  var close = Props.close;
  var element = Props.element;
  var clickCoordinate = Props.clickCoordinate;
  var job = Props.job;
  var clickTargetPosition_1 = element.getBoundingClientRect();
  var clickTargetPosition = {
    NAME: "DomRect",
    VAL: clickTargetPosition_1
  };
  return React.createElement(Schedule_Popover.make, {
              clickCoordinate: clickCoordinate,
              clickTargetPosition: clickTargetPosition,
              children: React.createElement(Schedule_JobPreview.make, {
                    job: job,
                    close: close
                  })
            });
}

var make = WeekCalendar_JobPreview;

export {
  make ,
}
/* react Not a pure module */
