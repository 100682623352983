

import * as El from "../../../../../../libraries/El.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as React from "react";
import * as Optional from "../../../../../common/Optional.mjs";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as WeekCalendar_Constants from "../../lib/WeekCalendar_Constants.mjs";
import * as WeekCalendar_BusinessHoursModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_calendar/components/business_hours/WeekCalendar_BusinessHours.module.css";

var styles = WeekCalendar_BusinessHoursModuleCss;

function WeekCalendar_BusinessHours(Props) {
  var columnWidth = Props.columnWidth;
  var showColumnsOpt = Props.showColumns;
  var showColumns = showColumnsOpt !== undefined ? showColumnsOpt : false;
  var match = AppContext.useWire();
  var businessHours = Schedule_State.useBusinessHours();
  var setBusinessHours = Schedule_State.useSetBusinessHours();
  var style = {
    width: (columnWidth * WeekCalendar_Constants.daysInWeek).toString() + "px"
  };
  var match$1 = match.ctx.businessHours;
  if (match$1 === undefined) {
    return null;
  }
  if (businessHours === undefined) {
    return React.createElement("div", {
                className: El.Cn.concat(styles.container, styles.hidden),
                style: style,
                onClick: (function (param) {
                    setBusinessHours(match$1);
                  })
              }, React.createElement("div", {
                    className: styles.icon
                  }));
  }
  var style$1 = {
    width: columnWidth.toString() + "px"
  };
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: El.Cn.concat(styles.container, styles.shown),
                  style: style,
                  onClick: (function (param) {
                      setBusinessHours(undefined);
                    })
                }, React.createElement("div", {
                      className: styles.icon
                    })), React.createElement(Optional.make, {
                  show: showColumns,
                  children: React.createElement("div", {
                        className: styles.columns,
                        style: style
                      }, RCore.$$Array.make(WeekCalendar_Constants.daysInWeek, React.createElement("div", undefined)).map(function (param, index) {
                            return React.createElement("div", {
                                        key: index.toString(),
                                        className: styles.cell,
                                        style: style$1
                                      });
                          }))
                }));
}

var make = WeekCalendar_BusinessHours;

export {
  make ,
}
/* styles Not a pure module */
