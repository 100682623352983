import React from 'react'
import { templatePath } from 'planado/routes.js'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import Link from 'rscrpt/components/common/Link/Link.mjs'

export const ConfirmRemovalDialog = withContext(
  ({ uuid, actions: { hidePopover }, ctx, deleteDictionary }) => (
    <div className="dict-form__popover">
      <div className="dict-form__popover-header">
        {ctx.t('js.admin.dictionaries.remove_text')}
      </div>

      <div className="dict-form__popover-buttons">
        <button
          className="btn btn-32px"
          onClick={() => hidePopover()}
          type="button"
        >
          {ctx.t('js.admin.dictionaries.cancel')}
        </button>

        <button
          className="btn btn-32px btn-danger button-remove"
          onClick={() => deleteDictionary(uuid)}
          type="button"
        >
          {ctx.t('js.admin.dictionaries.remove_ok')}
        </button>
      </div>
    </div>
  )
)

export const ErrorRemovalModal = withContext(
  ({ templates, actions: { hidePopover }, ctx }) => (
    <div className="dict-form__modal">
      <header className="dict-form__header">
        <div className="dict-form__header-text">
          <b>{ctx.t('js.admin.dictionaries.used')}</b>
        </div>

        <a className="dict-form__header-close" onClick={() => hidePopover()}>
          <span className="fa fa-times" />
        </a>
      </header>

      <div className="dict-form__section dict-form__section_modal">
        <div className="dict-form__section-text">
          {ctx.t('js.admin.dictionaries.error.modal')}
        </div>

        {templates.map((t) => (
          <Link
            key={t.uuid}
            wire={ctx.wire}
            pathname={templatePath(t.uuid)}
            target="_blank"
          >
            {t.name}
          </Link>
        ))}
      </div>

      <div className="dict-form__footer">
        <div className="dict-form__footer-divider" />

        <button
          className="btn btn-32px btn-primary"
          type="button"
          onClick={() => hidePopover()}
        >
          {ctx.t('js.admin.dictionaries.close')}
        </button>
      </div>
    </div>
  )
)
