

import * as El from "../../../libraries/El.mjs";
import * as Url from "../../../bindings/url/Url.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Link from "../../common/Link/Link.mjs";
import * as Hooks from "../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Route from "../../../types/Route.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as APIAPI from "./APIAPI.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Runtime from "../../../Runtime.mjs";
import * as APITypes from "./APITypes.mjs";
import * as DomUtils from "../../../DomUtils.mjs";
import * as Optional from "../../common/Optional.mjs";
import * as AddButton from "../../common/AddButton/AddButton.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as WebhookTypes from "../webhook/WebhookTypes.mjs";
import * as Context_Types from "../../../context/Context_Types.mjs";
import * as Shared_Lib_Array from "../../../shared/lib/Shared_Lib_Array.mjs";
import * as APIShowModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/api/APIShow.module.css";

var styles = APIShowModuleCss;

var t = Utils.Translations.t;

var tr = Utils.Translations.tr;

var tx = Utils.Translations.tx;

var joinWithComma = Utils.Translations.joinWithComma;

var addWebhook$p = tr("js.admin.api.show.add_webhook");

var apiKey$p = tr("js.admin.api.show.api_key");

var apiDocs$p = tr("js.admin.api.show.docs");

var enableFeaturesNote$p = tr("js.admin.api.show.enable_features_note");

var featuresDisabled$p = tr("js.admin.api.show.features_disabled");

var noEvents$p = t("js.admin.api.show.no_events");

var webhooks$p = tr("js.admin.api.show.webhooks");

var apiDescription$p = tr("js.admin.api.show.api_description");

var webhooksDescription$p = tr("js.admin.api.show.webhooks_description");

var webhooksWarning$p = tr("js.admin.api.show.webhooks_warning");

var webhooksLocked$p = tr("js.admin.api.show.webhook_locked");

var disabled$p = t("js.admin.api.show.disabled");

var locked$p = tr("js.admin.api.show.locked");

var needKey$p = tr("js.admin.api.show.need_key");

var generateNewKey$p = tr("js.admin.api.show.generate_new_key");

var keyWarning$p = tr("js.admin.api.show.key_warning");

var configuredWebhooks$p = tr("js.admin.api.show.configured_webhooks");

var sendsTo$p = tx("js.admin.api.show.sends_to");

var onEvents$p = tx("js.admin.api.show.on_events");

var sendRequest$p = tx("js.admin.api.show.send_to");

function APIShow$DocsLink(Props) {
  var ctx = Props.ctx;
  var link = function (href) {
    return React.createElement("a", {
                href: href,
                target: "blank"
              }, apiDocs$p(ctx), El.space, React.createElement("i", {
                    className: Icon.style(undefined, undefined, "external-link-alt")
                  }));
  };
  return React.createElement("div", undefined, apiDescription$p(ctx), React.createElement("br", undefined), React.createElement("br", undefined), needKey$p(ctx), El.space, ctx.localizator.lang === "ru" ? link("https://api-docs.planadoapp.com/ru") : link("https://api-docs.planadoapp.com/"));
}

function APIShow$WebhooksDocsLink(Props) {
  var ctx = Props.ctx;
  var haveLocked = Props.haveLocked;
  var link = function (href) {
    return React.createElement("a", {
                href: href,
                target: "blank"
              }, apiDocs$p(ctx), El.space, React.createElement("i", {
                    className: Icon.style(undefined, undefined, "external-link-alt")
                  }));
  };
  var warningCN = El.Cn.concat(styles.webhooksWarning, haveLocked ? styles.locked : styles.warning);
  return React.createElement(React.Fragment, undefined, React.createElement("div", undefined, webhooksDescription$p(ctx), El.space, ctx.localizator.lang === "ru" ? link("https://api-docs.planadoapp.com/ru/docs/v2/webhooks.html") : link("https://api-docs.planadoapp.com/docs/v2/webhooks.html")), React.createElement("div", {
                  className: warningCN
                }, React.createElement("i", {
                      className: Icon.style(undefined, undefined, "exclamation-triangle")
                    }), El.space, haveLocked ? webhooksLocked$p(ctx) : webhooksWarning$p(ctx)));
}

function APIShow$GenerateAPIKey(Props) {
  var ctx = Props.ctx;
  var onClick = Props.onClick;
  var new_Opt = Props.new_;
  var new_ = new_Opt !== undefined ? new_Opt : false;
  return React.createElement(React.Fragment, undefined, React.createElement("button", {
                  className: El.Cn.concat("btn", styles.generateKey),
                  disabled: ctx.disposable,
                  type: "button",
                  onClick: onClick
                }, generateNewKey$p(ctx)), new_ ? null : React.createElement("span", {
                    className: styles.keyWarning
                  }, keyWarning$p(ctx)));
}

function APIShow$ApiKey(Props) {
  var state = Props.state;
  var ctx = Props.ctx;
  var onClick = Props.onClick;
  var key = RCore.$$Option.getOr(state.apiKey, "");
  var match = React.useState(function () {
        return false;
      });
  var setCopied = match[1];
  var keyRef = React.useRef(null);
  var id = Hooks.useId(undefined);
  var match$1 = React.useState(function () {
        return false;
      });
  var setFocused = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setIgnoreBlur = match$2[1];
  var ignoreBlur = match$2[0];
  var copy = function (param) {
    setCopied(function (param) {
          return true;
        });
    Runtime.Timeout.nextTick(function () {
          Utils.copyToClipboard(Caml_option.nullable_to_opt(keyRef.current));
        });
  };
  var inputType = match$1[0] ? "text" : "password";
  var onFocus = function (param) {
    setFocused(function (param) {
          return true;
        });
  };
  var onBlur = function (param) {
    if (!ignoreBlur) {
      setCopied(function (param) {
            return false;
          });
      return setFocused(function (param) {
                  return false;
                });
    }
    
  };
  var match$3 = React.useState(function () {
        return false;
      });
  var setRendered = match$3[1];
  var rendered = match$3[0];
  React.useLayoutEffect((function () {
          setRendered(function (param) {
                return true;
              });
        }), []);
  React.useEffect((function () {
          if (rendered) {
            var e = keyRef.current;
            if (!(e == null)) {
              DomUtils.focus(e, undefined);
            }
            
          }
          
        }), [key]);
  return React.createElement("div", {
              className: styles.apiKey
            }, key === "" ? React.createElement(APIShow$GenerateAPIKey, {
                    ctx: ctx,
                    onClick: onClick,
                    new_: true
                  }) : React.createElement("div", {
                    className: styles.generatedKey
                  }, React.createElement("label", {
                        className: "pd-label",
                        htmlFor: id
                      }, apiKey$p(ctx)), React.createElement("div", {
                        className: styles.keyContainer
                      }, React.createElement("input", {
                            ref: keyRef,
                            className: "string required readonly form-control",
                            id: id,
                            readOnly: true,
                            type: inputType,
                            value: RCore.$$Option.getOr(state.apiKey, ""),
                            onFocus: onFocus,
                            onBlur: onBlur
                          }), React.createElement("button", {
                            className: styles.copyKey,
                            type: "button",
                            onClick: copy,
                            onMouseEnter: (function (param) {
                                setIgnoreBlur(function (param) {
                                      return true;
                                    });
                              }),
                            onMouseLeave: (function (param) {
                                setIgnoreBlur(function (param) {
                                      return false;
                                    });
                              })
                          }, match[0] ? React.createElement("i", {
                                  className: Icon.style(undefined, undefined, "check")
                                }) : React.createElement("i", {
                                  className: Icon.style(undefined, undefined, "clipboard")
                                }))), React.createElement(APIShow$GenerateAPIKey, {
                        ctx: ctx,
                        onClick: onClick
                      })));
}

function APIShow$AddWebhookButton(Props) {
  var state = Props.state;
  var wire = Props.wire;
  return React.createElement(Optional.make, {
              show: state.webhooks.length < WebhookTypes.maxWebhooks,
              children: React.createElement(AddButton.make, {
                    wire: wire,
                    route: {
                      NAME: "Integrations",
                      VAL: {
                        NAME: "Webhooks",
                        VAL: "New"
                      }
                    },
                    disabled: wire.ctx.disposable,
                    children: addWebhook$p(wire.ctx)
                  })
            });
}

function name(w, ctx) {
  var name$1 = w.name;
  if (name$1 !== undefined) {
    return name$1;
  } else {
    return sendRequest$p({
                url: Url.origin(w.url) + "/..."
              }, ctx);
  }
}

function firstEvents(ctx, events) {
  var leadingElements = Shared_Lib_Array.take(5, events).map(function (e) {
        return t("js.admin.api.show.event_types." + e)(ctx);
      });
  return (joinWithComma(leadingElements) + (
              leadingElements.length !== events.length ? ", ..." : ""
            )).toLowerCase();
}

function sendsTo(w, ctx) {
  var match = w.name;
  if (match !== undefined) {
    return sendsTo$p({
                url: Url.origin(w.url)
              }, ctx) + " ";
  } else {
    return "";
  }
}

function events(w, ctx) {
  var types = w.eventTypes;
  if (types.length !== 0) {
    return onEvents$p({
                events: firstEvents(ctx, types)
              }, ctx);
  } else {
    return noEvents$p(ctx);
  }
}

function APIShow$Webhook(Props) {
  var w = Props.webhook;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = w.status;
  var helpText;
  helpText = match === "Disabled" ? disabled$p(ctx) : sendsTo(w, ctx) + events(w, ctx);
  var match$1 = w.status;
  var tmp;
  var exit = 0;
  switch (match$1) {
    case "Enabled" :
    case "Disabled" :
        tmp = null;
        break;
    case "Suspended" :
    case "Locked" :
        exit = 1;
        break;
    
  }
  if (exit === 1) {
    tmp = React.createElement("div", {
          className: styles.locked
        }, locked$p(ctx));
  }
  return React.createElement("li", undefined, React.createElement(Link.make, {
                  wire: wire,
                  route: {
                    NAME: "Integrations",
                    VAL: {
                      NAME: "Webhooks",
                      VAL: {
                        NAME: "Edit",
                        VAL: w.uuid
                      }
                    }
                  },
                  children: name(w, ctx)
                }), React.createElement("em", undefined, " (" + helpText + ")"), tmp);
}

function APIShow$WebhooksTable(Props) {
  var state = Props.state;
  var wire = Props.wire;
  var match = state.webhooks;
  if (match.length !== 0) {
    return React.createElement("div", {
                className: styles.webhooks
              }, React.createElement("div", undefined, configuredWebhooks$p(wire.ctx)), React.createElement("ul", undefined, state.webhooks.map(function (webhook, idx) {
                        return React.createElement(APIShow$Webhook, {
                                    webhook: webhook,
                                    key: idx.toString()
                                  });
                      })));
  } else {
    return null;
  }
}

function APIShow(Props) {
  var wire = Props.wire;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return false;
      });
  var setLoaded = match[1];
  var match$1 = React.useState(function () {
        return APITypes.Show.State.make(undefined, undefined);
      });
  var setState = match$1[1];
  var state = match$1[0];
  var showResponse = function (data) {
    if (data.TAG !== "Ok") {
      return ;
    }
    var response = data._0;
    setState(function (param) {
          return response;
        });
    setLoaded(function (param) {
          return true;
        });
  };
  var generateKeyResponse = function (data) {
    if (data.TAG !== "Ok") {
      return ;
    }
    var apiKey = data._0.apiKey;
    setState(function (s) {
          return {
                  apiKey: apiKey,
                  webhooks: s.webhooks
                };
        });
  };
  React.useEffect((function () {
          Backend.$$finally(APIAPI.Get.$$fetch(wire), showResponse);
        }), [wire]);
  if (!Context_Types.Features.hasFlag("api", ctx.features)) {
    return React.createElement("div", undefined, React.createElement("section", undefined, React.createElement("div", undefined, React.createElement("div", {
                            className: styles.apiFormHeadline
                          }, featuresDisabled$p(ctx)), React.createElement("div", {
                            className: styles.apiFormDesc
                          }, enableFeaturesNote$p(ctx)))));
  }
  var generate = function (param) {
    Backend.$$finally(APIAPI.GenerateKey.createKey(wire), generateKeyResponse);
  };
  return React.createElement(Optional.make, {
              show: match[0],
              children: React.createElement("form", {
                    className: styles.apiForm,
                    action: Route.pagePath(undefined, {
                          NAME: "Integrations",
                          VAL: "API"
                        }),
                    method: "post"
                  }, React.createElement("div", undefined, React.createElement("section", undefined, React.createElement("h2", undefined, "API"), React.createElement("div", undefined, React.createElement(APIShow$DocsLink, {
                                    ctx: ctx
                                  }), React.createElement(APIShow$ApiKey, {
                                    state: state,
                                    ctx: ctx,
                                    onClick: generate
                                  }))), React.createElement("section", undefined, React.createElement("h2", undefined, webhooks$p(ctx)), React.createElement(APIShow$WebhooksDocsLink, {
                                ctx: ctx,
                                haveLocked: state.webhooks.some(function (w) {
                                      if (w.status === "Suspended") {
                                        return true;
                                      } else {
                                        return w.status === "Locked";
                                      }
                                    })
                              }), React.createElement(APIShow$WebhooksTable, {
                                state: state,
                                wire: wire
                              }), React.createElement(APIShow$AddWebhookButton, {
                                state: state,
                                wire: wire
                              }))))
            });
}

var make = APIShow;

export {
  make ,
}
/* styles Not a pure module */
