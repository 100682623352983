

import * as Fun from "../../libraries/Fun.mjs";
import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as Types from "../../types/Types.mjs";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";
import * as Context_Lang from "../../context/Context_Lang.mjs";
import * as Context_Types from "../../context/Context_Types.mjs";
import * as AddressInput_Types from "../address_input/AddressInput_Types.mjs";
import * as Types_TrackingForm from "../../types/Types_TrackingForm.mjs";
import * as Context_Localizator from "../../context/Context_Localizator.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              id: field.required("id", Types.Id.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var decode$1 = JsonDecode.object(function (field) {
      var match = Fun.both(field.required("start", JsonDecode.option(Types.Time.HoursMinutes.decode)), field.required("finish", JsonDecode.option(Types.Time.HoursMinutes.decode)));
      if (match !== undefined) {
        return {
                configured: true,
                interval: [
                  match[0],
                  match[1]
                ]
              };
      } else {
        return {
                configured: false,
                interval: undefined
              };
      }
    });

var decode$2 = JsonDecode.object(function (field) {
      return {
              used: field.required("used", JsonDecode.$$int),
              limit: field.required("limit", JsonDecode.option(JsonDecode.$$int))
            };
    });

var decode$3 = JsonDecode.object(function (field) {
      return {
              quotas: field.required("quotas", decode$2),
              workerEnRouteTemplate: field.required("workerEnRouteTemplate", JsonDecode.option(JsonDecode.string)),
              nearestJobsTemplate: field.required("nearestJobsTemplate", JsonDecode.option(JsonDecode.string)),
              jobFinishedTemplate: field.required("jobFinishedTemplate", JsonDecode.option(JsonDecode.string)),
              sendHour: field.required("sendHour", JsonDecode.$$int)
            };
    });

var decode$4 = JsonDecode.object(function (field) {
      return {
              code: field.required("code", JsonDecode.string),
              name: field.required("name", JsonDecode.string)
            };
    });

var decode$5 = JsonDecode.object(function (field) {
      return {
              value: field.required("value", JsonDecode.string),
              label: field.required("label", JsonDecode.string)
            };
    });

var decode$6 = JsonDecode.object(function (field) {
      return {
              value: field.required("value", JsonDecode.string),
              label: field.required("label", JsonDecode.string),
              thousandsSeparator: field.required("thousandsSeparator", JsonDecode.string),
              decimalMark: field.required("decimalMark", JsonDecode.string)
            };
    });

var decode$7 = JsonDecode.object(function (field) {
      return {
              language: field.required("language", Context_Lang.decode),
              languageVariants: field.required("languageVariants", JsonDecode.array(decode$4)),
              timezone: field.required("timezone", JsonDecode.string),
              timezoneVariants: field.required("timezoneVariants", JsonDecode.array(decode$4)),
              country: field.required("country", JsonDecode.string),
              countryVariants: field.required("countryVariants", JsonDecode.array(decode$4)),
              dateFormat: field.required("dateFormat", JsonDecode.string),
              dateFormatVariants: field.required("dateFormatVariants", JsonDecode.array(decode$5)),
              measurementSystem: field.required("measurementSystem", Context_Localizator.MeasurementSystem.decode),
              measurementSystemVariants: field.required("measurementSystemVariants", JsonDecode.array(decode$5)),
              currency: field.required("currency", JsonDecode.string),
              currencyVariants: field.required("currencyVariants", JsonDecode.array(decode$5)),
              thousandsSeparator: field.required("thousandsSeparator", JsonDecode.string),
              decimalMark: field.required("decimalMark", JsonDecode.string),
              numberFormatVariants: field.required("numberFormatVariants", JsonDecode.array(decode$6)),
              currencyFullFormat: field.required("currencyFullFormat", JsonDecode.string)
            };
    });

var decode$8 = JsonDecode.object(function (field) {
      return {
              loaded: true,
              subdomain: field.required("subdomain", JsonDecode.string),
              companyName: field.required("companyName", JsonDecode.option(JsonDecode.string)),
              officePhone: field.required("officePhone", JsonDecode.option(JsonDecode.string)),
              dadataToken: field.required("dadataToken", JsonDecode.string),
              mapType: field.required("mapType", Context_Types.MapType.decode),
              international: field.required("international", JsonDecode.bool),
              region: field.required("region", Context_Localizator.Region.decode),
              hasSms: field.required("hasSms", JsonDecode.bool),
              hasSmsProvider: field.required("hasSmsProvider", JsonDecode.bool),
              userUuid: field.required("userUuid", Uuid.decode),
              owner: field.required("owner", JsonDecode.bool),
              users: field.required("users", JsonDecode.array(decode)),
              trackingForms: field.required("trackingForms", JsonDecode.array(Types_TrackingForm.decode)),
              businessHours: field.required("businessHours", decode$1),
              smsSettings: field.required("smsSettings", decode$3),
              locale: field.required("locale", decode$7),
              lookupRestrictions: field.required("lookupRestrictions", AddressInput_Types.SettingsAddressConstraints.decode)
            };
    });

var State = {
  decode: decode$8
};

var empty_owner = [];

var empty_businessHours = [];

var empty_language = [];

var empty_timezone = [];

var empty_country = [];

var empty_dateFormat = [];

var empty_measurementSystem = [];

var empty_thousandsSeparator = [];

var empty_decimalMark = [];

var empty_currency = [];

var empty_currencyFullFormat = [];

var empty_smsNearest = [];

var empty_smsEnRoute = [];

var empty_smsFinished = [];

var empty = {
  owner: empty_owner,
  businessHours: empty_businessHours,
  language: empty_language,
  timezone: empty_timezone,
  country: empty_country,
  dateFormat: empty_dateFormat,
  measurementSystem: empty_measurementSystem,
  thousandsSeparator: empty_thousandsSeparator,
  decimalMark: empty_decimalMark,
  currency: empty_currency,
  currencyFullFormat: empty_currencyFullFormat,
  smsNearest: empty_smsNearest,
  smsEnRoute: empty_smsEnRoute,
  smsFinished: empty_smsFinished
};

var Errors = {
  empty: empty
};

var SmsLimits = {};

var Limits_limits = {
  companyName: 500,
  officePhone: 50,
  address: 200,
  smsSettings: {
    workerEnRouteTemplate: 250,
    nearestJobsTemplate: 250,
    jobFinishedTemplate: 250
  }
};

var Limits = {
  SmsLimits: SmsLimits,
  limits: Limits_limits
};

var User = {};

var BusinessHours = {};

var SmsSettings = {
  Quotas: {}
};

var Locale_VariantWithCode = {};

var Locale_Variant = {};

var Locale_NumberFormatVariant = {};

var Locale = {
  VariantWithCode: Locale_VariantWithCode,
  Variant: Locale_Variant,
  NumberFormatVariant: Locale_NumberFormatVariant
};

export {
  User ,
  BusinessHours ,
  SmsSettings ,
  Locale ,
  State ,
  Errors ,
  Limits ,
}
/* decode Not a pure module */
