

import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as $$Number from "../../libraries/Number.mjs";
import * as Select from "../inputs/Select.mjs";
import * as UI_Form from "../../ui/UI_Form.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../common/Optional.mjs";
import * as UI_Label from "../../ui/UI_Label.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as AppContext from "../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FieldErrors from "../common/FieldError/FieldErrors.mjs";
import * as Context_Lang from "../../context/Context_Lang.mjs";
import * as Context_Localizator from "../../context/Context_Localizator.mjs";
import * as AddressInput_AddressConstraint from "../address_input/address_constraint/AddressInput_AddressConstraint.mjs";

var title$p = Utils.Translations.tr("js.settings.localization_settings");

var language$p = Utils.Translations.tr("js.settings.labels.language");

var languagePlaceholder$p = Utils.Translations.t("js.settings.placeholders.language");

var country$p = Utils.Translations.tr("js.settings.labels.country");

var countryPlaceholder$p = Utils.Translations.t("js.settings.placeholders.country");

var timezone$p = Utils.Translations.tr("js.settings.labels.timezone");

var timezonePlaceholder$p = Utils.Translations.t("js.settings.placeholders.timezone");

var dateFormat$p = Utils.Translations.tr("js.settings.labels.date_format");

var dateFormatPlaceholder$p = Utils.Translations.t("js.settings.placeholders.date_format");

var measurementSystem$p = Utils.Translations.tr("js.settings.labels.measurement_system");

var measurementSystemPlaceholder$p = Utils.Translations.t("js.settings.placeholders.measurement_system");

var currency$p = Utils.Translations.tr("js.settings.labels.currency");

var currencyPlaceholder$p = Utils.Translations.t("js.settings.placeholders.currency");

var currencyFormat$p = Utils.Translations.tr("js.settings.labels.currency_format");

var currencyFormatPlaceholder$p = Utils.Translations.t("js.settings.placeholders.currency_format");

var numberFormat$p = Utils.Translations.tr("js.settings.labels.number_format");

var numberFormatPlaceholder$p = Utils.Translations.t("js.settings.placeholders.number_format");

function currencySymbol$p(code, ctx) {
  return Utils.Translations.t("common.currency_signs." + code)(ctx);
}

var shouldBeFilled$p = Utils.Translations.t("js.settings.errors.empty");

function toOptions(vs) {
  return vs.map(function (param) {
              return Select.$$Option.fromString(param.name, param.code);
            });
}

function toNumberOptions(vs) {
  return vs.map(function (param) {
              return Select.$$Option.fromString(param.label, param.value);
            });
}

function currencyFormatOptions(ctx, currency, thousandsSeparator, decimalMark) {
  var currencyFormats = [
    "%v %$",
    "%$ %v",
    "%$%v",
    "%v"
  ];
  var currencyValue = $$Number.format(thousandsSeparator, decimalMark, 5, "1000000.23");
  var currencySymbol = currencySymbol$p(currency, ctx);
  return currencyFormats.map(function (format) {
              return {
                      label: (function (__x) {
                            return Js_string.replace("%$", currencySymbol, __x);
                          })((function (__x) {
                                return Js_string.replace("%v", currencyValue, __x);
                              })(format)),
                      value: format
                    };
            });
}

function Settings_Locale(Props) {
  var locale = Props.locale;
  var setLocale = Props.setLocale;
  var lookupRestrictions = Props.lookupRestrictions;
  var setLookupRestrictions = Props.setLookupRestrictions;
  var errors = Props.errors;
  var setErrors = Props.setErrors;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var currencyFullFormat = locale.currencyFullFormat;
  var numberFormatVariants = locale.numberFormatVariants;
  var decimalMark = locale.decimalMark;
  var thousandsSeparator = locale.thousandsSeparator;
  var currency = locale.currency;
  var measurementSystem = locale.measurementSystem;
  var dateFormat = locale.dateFormat;
  var country = locale.country;
  var timezone = locale.timezone;
  var language = locale.language;
  var error = function (e) {
    if (Caml_obj.equal(e, [])) {
      return [];
    } else {
      return [shouldBeFilled$p(ctx)];
    }
  };
  return React.createElement(UI_Form.Section.make, {
              children: null
            }, React.createElement(UI_Form.SectionTitle.make, {
                  children: title$p(ctx)
                }), React.createElement(UI_Form.Container.make, {
                  children: null
                }, React.createElement(UI_Form.Group.make, {
                      children: null
                    }, React.createElement(UI_Label.make, {
                          children: language$p(ctx)
                        }), React.createElement(Select.make, {
                          placeholder: languagePlaceholder$p(ctx),
                          onChange: (function (value) {
                              setErrors(function (current) {
                                    return {
                                            owner: current.owner,
                                            businessHours: current.businessHours,
                                            language: [],
                                            timezone: current.timezone,
                                            country: current.country,
                                            dateFormat: current.dateFormat,
                                            measurementSystem: current.measurementSystem,
                                            thousandsSeparator: current.thousandsSeparator,
                                            decimalMark: current.decimalMark,
                                            currency: current.currency,
                                            currencyFullFormat: current.currencyFullFormat,
                                            smsNearest: current.smsNearest,
                                            smsEnRoute: current.smsEnRoute,
                                            smsFinished: current.smsFinished
                                          };
                                  });
                              setLocale({
                                    language: RCore.$$Option.getOr(RCore.$$Option.map(value === null ? undefined : Caml_option.some(value), Context_Lang.fromString), language),
                                    languageVariants: locale.languageVariants,
                                    timezone: locale.timezone,
                                    timezoneVariants: locale.timezoneVariants,
                                    country: locale.country,
                                    countryVariants: locale.countryVariants,
                                    dateFormat: locale.dateFormat,
                                    dateFormatVariants: locale.dateFormatVariants,
                                    measurementSystem: locale.measurementSystem,
                                    measurementSystemVariants: locale.measurementSystemVariants,
                                    currency: locale.currency,
                                    currencyVariants: locale.currencyVariants,
                                    thousandsSeparator: locale.thousandsSeparator,
                                    decimalMark: locale.decimalMark,
                                    numberFormatVariants: locale.numberFormatVariants,
                                    currencyFullFormat: locale.currencyFullFormat
                                  });
                            }),
                          value: Context_Lang.toString(language),
                          disabled: ctx.disposable,
                          clearable: false,
                          searchable: false,
                          options: toOptions(locale.languageVariants)
                        }), React.createElement(FieldErrors.make, {
                          errors: error(errors.language)
                        })), React.createElement(UI_Form.Group.make, {
                      children: null
                    }, React.createElement(UI_Label.make, {
                          children: country$p(ctx)
                        }), React.createElement(Select.make, {
                          placeholder: countryPlaceholder$p(ctx),
                          onChange: (function (value) {
                              setErrors(function (current) {
                                    return {
                                            owner: current.owner,
                                            businessHours: current.businessHours,
                                            language: current.language,
                                            timezone: current.timezone,
                                            country: [],
                                            dateFormat: current.dateFormat,
                                            measurementSystem: current.measurementSystem,
                                            thousandsSeparator: current.thousandsSeparator,
                                            decimalMark: current.decimalMark,
                                            currency: current.currency,
                                            currencyFullFormat: current.currencyFullFormat,
                                            smsNearest: current.smsNearest,
                                            smsEnRoute: current.smsEnRoute,
                                            smsFinished: current.smsFinished
                                          };
                                  });
                              setLocale({
                                    language: locale.language,
                                    languageVariants: locale.languageVariants,
                                    timezone: locale.timezone,
                                    timezoneVariants: locale.timezoneVariants,
                                    country: RCore.$$Option.getOr(value === null ? undefined : Caml_option.some(value), country),
                                    countryVariants: locale.countryVariants,
                                    dateFormat: locale.dateFormat,
                                    dateFormatVariants: locale.dateFormatVariants,
                                    measurementSystem: locale.measurementSystem,
                                    measurementSystemVariants: locale.measurementSystemVariants,
                                    currency: locale.currency,
                                    currencyVariants: locale.currencyVariants,
                                    thousandsSeparator: locale.thousandsSeparator,
                                    decimalMark: locale.decimalMark,
                                    numberFormatVariants: locale.numberFormatVariants,
                                    currencyFullFormat: locale.currencyFullFormat
                                  });
                            }),
                          value: country,
                          autosize: false,
                          disabled: ctx.disposable,
                          clearable: false,
                          options: toOptions(locale.countryVariants)
                        }), React.createElement(FieldErrors.make, {
                          errors: error(errors.country)
                        })), React.createElement(UI_Form.Group.make, {
                      children: null
                    }, React.createElement(UI_Label.make, {
                          children: timezone$p(ctx)
                        }), React.createElement(Select.make, {
                          placeholder: timezonePlaceholder$p(ctx),
                          onChange: (function (value) {
                              setErrors(function (current) {
                                    return {
                                            owner: current.owner,
                                            businessHours: current.businessHours,
                                            language: current.language,
                                            timezone: [],
                                            country: current.country,
                                            dateFormat: current.dateFormat,
                                            measurementSystem: current.measurementSystem,
                                            thousandsSeparator: current.thousandsSeparator,
                                            decimalMark: current.decimalMark,
                                            currency: current.currency,
                                            currencyFullFormat: current.currencyFullFormat,
                                            smsNearest: current.smsNearest,
                                            smsEnRoute: current.smsEnRoute,
                                            smsFinished: current.smsFinished
                                          };
                                  });
                              setLocale({
                                    language: locale.language,
                                    languageVariants: locale.languageVariants,
                                    timezone: RCore.$$Option.getOr(value === null ? undefined : Caml_option.some(value), timezone),
                                    timezoneVariants: locale.timezoneVariants,
                                    country: locale.country,
                                    countryVariants: locale.countryVariants,
                                    dateFormat: locale.dateFormat,
                                    dateFormatVariants: locale.dateFormatVariants,
                                    measurementSystem: locale.measurementSystem,
                                    measurementSystemVariants: locale.measurementSystemVariants,
                                    currency: locale.currency,
                                    currencyVariants: locale.currencyVariants,
                                    thousandsSeparator: locale.thousandsSeparator,
                                    decimalMark: locale.decimalMark,
                                    numberFormatVariants: locale.numberFormatVariants,
                                    currencyFullFormat: locale.currencyFullFormat
                                  });
                            }),
                          value: timezone,
                          autosize: false,
                          disabled: ctx.disposable,
                          clearable: false,
                          options: toOptions(locale.timezoneVariants)
                        }), React.createElement(FieldErrors.make, {
                          errors: error(errors.timezone)
                        })), React.createElement(UI_Form.Group.make, {
                      children: null
                    }, React.createElement(UI_Label.make, {
                          children: dateFormat$p(ctx)
                        }), React.createElement(Select.make, {
                          placeholder: dateFormatPlaceholder$p(ctx),
                          onChange: (function (value) {
                              setErrors(function (current) {
                                    return {
                                            owner: current.owner,
                                            businessHours: current.businessHours,
                                            language: current.language,
                                            timezone: current.timezone,
                                            country: current.country,
                                            dateFormat: [],
                                            measurementSystem: current.measurementSystem,
                                            thousandsSeparator: current.thousandsSeparator,
                                            decimalMark: current.decimalMark,
                                            currency: current.currency,
                                            currencyFullFormat: current.currencyFullFormat,
                                            smsNearest: current.smsNearest,
                                            smsEnRoute: current.smsEnRoute,
                                            smsFinished: current.smsFinished
                                          };
                                  });
                              setLocale({
                                    language: locale.language,
                                    languageVariants: locale.languageVariants,
                                    timezone: locale.timezone,
                                    timezoneVariants: locale.timezoneVariants,
                                    country: locale.country,
                                    countryVariants: locale.countryVariants,
                                    dateFormat: RCore.$$Option.getOr(value === null ? undefined : Caml_option.some(value), dateFormat),
                                    dateFormatVariants: locale.dateFormatVariants,
                                    measurementSystem: locale.measurementSystem,
                                    measurementSystemVariants: locale.measurementSystemVariants,
                                    currency: locale.currency,
                                    currencyVariants: locale.currencyVariants,
                                    thousandsSeparator: locale.thousandsSeparator,
                                    decimalMark: locale.decimalMark,
                                    numberFormatVariants: locale.numberFormatVariants,
                                    currencyFullFormat: locale.currencyFullFormat
                                  });
                            }),
                          value: dateFormat,
                          disabled: ctx.disposable,
                          clearable: false,
                          searchable: false,
                          options: locale.dateFormatVariants
                        }), React.createElement(FieldErrors.make, {
                          errors: error(errors.dateFormat)
                        })), React.createElement(UI_Form.Group.make, {
                      children: null
                    }, React.createElement(UI_Label.make, {
                          children: measurementSystem$p(ctx)
                        }), React.createElement(Select.make, {
                          placeholder: measurementSystemPlaceholder$p(ctx),
                          onChange: (function (value) {
                              setErrors(function (current) {
                                    return {
                                            owner: current.owner,
                                            businessHours: current.businessHours,
                                            language: current.language,
                                            timezone: current.timezone,
                                            country: current.country,
                                            dateFormat: current.dateFormat,
                                            measurementSystem: [],
                                            thousandsSeparator: current.thousandsSeparator,
                                            decimalMark: current.decimalMark,
                                            currency: current.currency,
                                            currencyFullFormat: current.currencyFullFormat,
                                            smsNearest: current.smsNearest,
                                            smsEnRoute: current.smsEnRoute,
                                            smsFinished: current.smsFinished
                                          };
                                  });
                              setLocale({
                                    language: locale.language,
                                    languageVariants: locale.languageVariants,
                                    timezone: locale.timezone,
                                    timezoneVariants: locale.timezoneVariants,
                                    country: locale.country,
                                    countryVariants: locale.countryVariants,
                                    dateFormat: locale.dateFormat,
                                    dateFormatVariants: locale.dateFormatVariants,
                                    measurementSystem: RCore.$$Option.getOr(RCore.$$Option.map(value === null ? undefined : Caml_option.some(value), Context_Localizator.MeasurementSystem.fromString), measurementSystem),
                                    measurementSystemVariants: locale.measurementSystemVariants,
                                    currency: locale.currency,
                                    currencyVariants: locale.currencyVariants,
                                    thousandsSeparator: locale.thousandsSeparator,
                                    decimalMark: locale.decimalMark,
                                    numberFormatVariants: locale.numberFormatVariants,
                                    currencyFullFormat: locale.currencyFullFormat
                                  });
                            }),
                          value: Context_Localizator.MeasurementSystem.toString(measurementSystem),
                          disabled: ctx.disposable,
                          clearable: false,
                          searchable: false,
                          options: locale.measurementSystemVariants
                        }), React.createElement(FieldErrors.make, {
                          errors: error(errors.measurementSystem)
                        })), React.createElement(UI_Form.Group.make, {
                      children: null
                    }, React.createElement(UI_Label.make, {
                          children: currency$p(ctx)
                        }), React.createElement(Select.make, {
                          placeholder: currencyPlaceholder$p(ctx),
                          onChange: (function (value) {
                              setErrors(function (current) {
                                    return {
                                            owner: current.owner,
                                            businessHours: current.businessHours,
                                            language: current.language,
                                            timezone: current.timezone,
                                            country: current.country,
                                            dateFormat: current.dateFormat,
                                            measurementSystem: current.measurementSystem,
                                            thousandsSeparator: current.thousandsSeparator,
                                            decimalMark: current.decimalMark,
                                            currency: [],
                                            currencyFullFormat: current.currencyFullFormat,
                                            smsNearest: current.smsNearest,
                                            smsEnRoute: current.smsEnRoute,
                                            smsFinished: current.smsFinished
                                          };
                                  });
                              setLocale({
                                    language: locale.language,
                                    languageVariants: locale.languageVariants,
                                    timezone: locale.timezone,
                                    timezoneVariants: locale.timezoneVariants,
                                    country: locale.country,
                                    countryVariants: locale.countryVariants,
                                    dateFormat: locale.dateFormat,
                                    dateFormatVariants: locale.dateFormatVariants,
                                    measurementSystem: locale.measurementSystem,
                                    measurementSystemVariants: locale.measurementSystemVariants,
                                    currency: RCore.$$Option.getOr(value === null ? undefined : Caml_option.some(value), currency),
                                    currencyVariants: locale.currencyVariants,
                                    thousandsSeparator: locale.thousandsSeparator,
                                    decimalMark: locale.decimalMark,
                                    numberFormatVariants: locale.numberFormatVariants,
                                    currencyFullFormat: locale.currencyFullFormat
                                  });
                            }),
                          value: currency,
                          autosize: false,
                          disabled: ctx.disposable,
                          clearable: false,
                          options: locale.currencyVariants
                        }), React.createElement(FieldErrors.make, {
                          errors: error(errors.currency)
                        })), React.createElement(UI_Form.Group.make, {
                      children: null
                    }, React.createElement(UI_Label.make, {
                          children: currencyFormat$p(ctx)
                        }), React.createElement(Select.make, {
                          placeholder: currencyFormatPlaceholder$p(ctx),
                          onChange: (function (value) {
                              setErrors(function (current) {
                                    return {
                                            owner: current.owner,
                                            businessHours: current.businessHours,
                                            language: current.language,
                                            timezone: current.timezone,
                                            country: current.country,
                                            dateFormat: current.dateFormat,
                                            measurementSystem: current.measurementSystem,
                                            thousandsSeparator: current.thousandsSeparator,
                                            decimalMark: current.decimalMark,
                                            currency: current.currency,
                                            currencyFullFormat: [],
                                            smsNearest: current.smsNearest,
                                            smsEnRoute: current.smsEnRoute,
                                            smsFinished: current.smsFinished
                                          };
                                  });
                              setLocale({
                                    language: locale.language,
                                    languageVariants: locale.languageVariants,
                                    timezone: locale.timezone,
                                    timezoneVariants: locale.timezoneVariants,
                                    country: locale.country,
                                    countryVariants: locale.countryVariants,
                                    dateFormat: locale.dateFormat,
                                    dateFormatVariants: locale.dateFormatVariants,
                                    measurementSystem: locale.measurementSystem,
                                    measurementSystemVariants: locale.measurementSystemVariants,
                                    currency: locale.currency,
                                    currencyVariants: locale.currencyVariants,
                                    thousandsSeparator: locale.thousandsSeparator,
                                    decimalMark: locale.decimalMark,
                                    numberFormatVariants: locale.numberFormatVariants,
                                    currencyFullFormat: RCore.$$Option.getOr(value === null ? undefined : Caml_option.some(value), currencyFullFormat)
                                  });
                            }),
                          value: currencyFullFormat,
                          disabled: ctx.disposable,
                          clearable: false,
                          searchable: false,
                          options: currencyFormatOptions(ctx, currency, thousandsSeparator, decimalMark)
                        }), React.createElement(FieldErrors.make, {
                          errors: error(errors.currencyFullFormat)
                        })), React.createElement(UI_Form.Group.make, {
                      children: null
                    }, React.createElement(UI_Label.make, {
                          children: numberFormat$p(ctx)
                        }), React.createElement(Select.make, {
                          placeholder: numberFormatPlaceholder$p(ctx),
                          onChange: (function (v) {
                              setErrors(function (current) {
                                    return {
                                            owner: current.owner,
                                            businessHours: current.businessHours,
                                            language: current.language,
                                            timezone: current.timezone,
                                            country: current.country,
                                            dateFormat: current.dateFormat,
                                            measurementSystem: current.measurementSystem,
                                            thousandsSeparator: [],
                                            decimalMark: [],
                                            currency: current.currency,
                                            currencyFullFormat: current.currencyFullFormat,
                                            smsNearest: current.smsNearest,
                                            smsEnRoute: current.smsEnRoute,
                                            smsFinished: current.smsFinished
                                          };
                                  });
                              var format = RCore.$$Array.getBy(numberFormatVariants, (function (param) {
                                      if (v !== null) {
                                        return v === param.value;
                                      } else {
                                        return false;
                                      }
                                    }));
                              if (format !== undefined) {
                                return setLocale({
                                            language: locale.language,
                                            languageVariants: locale.languageVariants,
                                            timezone: locale.timezone,
                                            timezoneVariants: locale.timezoneVariants,
                                            country: locale.country,
                                            countryVariants: locale.countryVariants,
                                            dateFormat: locale.dateFormat,
                                            dateFormatVariants: locale.dateFormatVariants,
                                            measurementSystem: locale.measurementSystem,
                                            measurementSystemVariants: locale.measurementSystemVariants,
                                            currency: locale.currency,
                                            currencyVariants: locale.currencyVariants,
                                            thousandsSeparator: format.thousandsSeparator,
                                            decimalMark: format.decimalMark,
                                            numberFormatVariants: locale.numberFormatVariants,
                                            currencyFullFormat: locale.currencyFullFormat
                                          });
                              } else {
                                return setLocale({
                                            language: locale.language,
                                            languageVariants: locale.languageVariants,
                                            timezone: locale.timezone,
                                            timezoneVariants: locale.timezoneVariants,
                                            country: locale.country,
                                            countryVariants: locale.countryVariants,
                                            dateFormat: locale.dateFormat,
                                            dateFormatVariants: locale.dateFormatVariants,
                                            measurementSystem: locale.measurementSystem,
                                            measurementSystemVariants: locale.measurementSystemVariants,
                                            currency: locale.currency,
                                            currencyVariants: locale.currencyVariants,
                                            thousandsSeparator: "",
                                            decimalMark: "",
                                            numberFormatVariants: locale.numberFormatVariants,
                                            currencyFullFormat: locale.currencyFullFormat
                                          });
                              }
                            }),
                          value: thousandsSeparator + decimalMark,
                          disabled: ctx.disposable,
                          clearable: false,
                          searchable: false,
                          options: toNumberOptions(numberFormatVariants)
                        }), React.createElement(FieldErrors.make, {
                          errors: Caml_obj.equal(errors.decimalMark, []) && Caml_obj.equal(errors.thousandsSeparator, []) ? [] : [shouldBeFilled$p(ctx)]
                        })), React.createElement(Optional.make, {
                      show: !ctx.disposable && country === "ru",
                      children: React.createElement(AddressInput_AddressConstraint.make, {
                            wire: wire,
                            initialConstraint: lookupRestrictions,
                            onChange: (function (constraint_) {
                                setLookupRestrictions(constraint_);
                              }),
                            suggestionsAmount: 5,
                            disabled: false
                          })
                    })));
}

var make = Settings_Locale;

export {
  make ,
}
/* title' Not a pure module */
