

import * as React from "react";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as WeekTimeline_State from "../../lib/state/WeekTimeline_State.mjs";
import * as Schedule_Unscheduled from "../../../../components/uscheduled_jobs/Schedule_Unscheduled.mjs";
import * as WeekTimeline_Timeline from "../timeline/WeekTimeline_Timeline.mjs";
import * as Schedule_Types_Timeline from "../../../../lib/types/Schedule_Types_Timeline.mjs";
import * as WeekTimeline_Unassigned from "../unassigned/WeekTimeline_Unassigned.mjs";
import * as Schedule_TimelineSidebar from "../../../../components/timeline/sidebar/Schedule_TimelineSidebar.mjs";
import * as WeekTimeline_ActiveDayState from "../active_day/lib/state/WeekTimeline_ActiveDayState.mjs";
import * as WeekTimeline_ContentModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/content/WeekTimeline_Content.module.css";

var styles = WeekTimeline_ContentModuleCss;

function WeekTimeline_Content$Component(Props) {
  var param = Props.elements;
  var viewportEl = param.viewportEl;
  var wire = AppContext.useWire();
  var filter = Schedule_State.useFilter();
  var jobs = Schedule_State.useAssignedJobs();
  var scheduleTimelines = Schedule_State.useScheduleTimelines();
  var bars = React.useMemo((function () {
          return Schedule_Types_Timeline.Bars.make(wire, filter, jobs, Belt_Array.concatMany([
                          scheduleTimelines.teamTimelines,
                          scheduleTimelines.workersTimelines
                        ]));
        }), [jobs]);
  var match = WeekTimeline_State.useTimelineSubscriptions();
  var activeAssigneeUuid = WeekTimeline_ActiveDayState.useAssigneeUuid();
  var tmp = {
    elementRef: param.sidebarEl,
    assignees: bars.map(function (param) {
          return param.assignee;
        }),
    onActiveAssigneeChanged: match.onActiveAssigneeChanged
  };
  if (activeAssigneeUuid !== undefined) {
    tmp.activeAssigneeUuid = Caml_option.valFromOption(activeAssigneeUuid);
  }
  return React.createElement("div", {
              className: styles.container
            }, React.createElement("div", {
                  ref: param.scrollAreaEl,
                  className: styles.container
                }, React.createElement(Schedule_TimelineSidebar.make, tmp), React.createElement(WeekTimeline_Timeline.make, {
                      viewportEl: viewportEl,
                      bars: bars
                    }), React.createElement(WeekTimeline_Unassigned.make, {
                      viewportEl: viewportEl
                    })), React.createElement("div", {
                  className: styles.separator
                }), React.createElement(Schedule_Unscheduled.make, {}));
}

var make = React.memo(WeekTimeline_Content$Component, (function (_prevProps, nextProps) {
        return nextProps.locked;
      }));

function WeekTimeline_Content(Props) {
  var elements = Props.elements;
  var locked = Schedule_State.useLocked();
  return React.createElement(make, {
              elements: elements,
              locked: locked
            });
}

var make$1 = WeekTimeline_Content;

export {
  make$1 as make,
}
/* styles Not a pure module */
