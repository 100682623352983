import React from 'react'
import { Provider } from 'react-redux'
import {
  FormLink,
  FooterLink
} from 'planado/containers/admin/dictionaries_show'

export const Form = props => (
  <Provider {...props}>
    <FormLink
      deleteDictionary={props.deleteDictionary}
      onSubmitDictionary={props.onSubmitDictionary}
    />
  </Provider>
)

export const Footer = props => (
  <Provider {...props}>
    <FooterLink />
  </Provider>
)
