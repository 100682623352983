import { combineReducers, createStore } from 'redux'
import { middlewaresWithLogger } from 'planado/stores/utils'
import {
  blankObjectReducer,
  blankArrayReducer,
  blankFalseReducer
} from 'planado/reducers/utils'
import {
  scaleReducer,
  backboneReducer,
  handlersReducer,
  filterReducer
} from './reducer'
import { createContext } from 'planado/utils/context.js'
import Dom from 'planado/dom'
import { getScheduleFilter } from 'planado/storage'
import { isAfterTime } from 'planado/utils/time/index.js'

const DEFAULT_FILTER = {
  filter: [],
  savedAt: null
}

const INITIAL_STATE = {
  jobs: [],
  bars: [],
  templates: [],
  businessHours: {
    show: false
  },
  filter: DEFAULT_FILTER
}

const businessHours = ({ show, start, finish }) => {
  if (show && start && finish) {
    return { show, start, finish }
  } else {
    return { show: false }
  }
}

const filter = (workers, teams) => {
  const filterJSON = getScheduleFilter()
  const { filter, savedAt } =
    filterJSON === null ? DEFAULT_FILTER : JSON.parse(filterJSON)

  const assignees = [...teams, ...workers]

  let updatedFilter
  if (savedAt === null || (savedAt !== null && filter.length === 0)) {
    updatedFilter = []
  } else {
    updatedFilter = assignees
      .filter(({ uuid, created_at }) => {
        if (filter.includes(uuid) || isAfterTime(created_at, savedAt)) {
          return true
        } else {
          return false
        }
      })
      .map(({ uuid }) => uuid)
  }

  return {
    teams,
    workers,
    filter: {
      savedAt,
      filter: updatedFilter
    }
  }
}

export const createScheduleStore = ({
  scale,
  wire,
  ctx,
  data: { business_hours, workers, teams, ...props }
}) => {
  const reducer = combineReducers({
    context: blankObjectReducer,
    dom: blankObjectReducer,
    jobs: blankArrayReducer,
    bars: blankArrayReducer,
    templates: blankArrayReducer,
    businessHours: blankObjectReducer,
    ctx: blankObjectReducer,
    editable: blankFalseReducer,
    scale: scaleReducer,
    backbone: backboneReducer,
    handlers: handlersReducer,
    websockets: blankObjectReducer,
    wire: blankObjectReducer,
    filter: filterReducer,
    teams: blankArrayReducer,
    workers: blankArrayReducer
  })

  const ctx_ = createContext({ wire, ctx, dom: new Dom(window) })

  const state = {
    ...INITIAL_STATE,
    ...props,
    ...filter(workers, teams),
    businessHours: businessHours(business_hours),
    ctx: ctx_,
    backbone: {},
    handlers: { openJob: () => ({}) },
    scale,
    wire
  }

  return createStore(reducer, state, middlewaresWithLogger({}))
}
