

import Client_select from "planado/components/input/client_select";

var Value = {};

var Address = {};

var make = Client_select;

export {
  Value ,
  Address ,
  make ,
}
/* make Not a pure module */
