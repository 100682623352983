

import * as Fun from "../../../../../../libraries/Fun.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../types/Types.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as DayTimeline_State from "../../lib/state/DayTimeline_State.mjs";
import * as Webapi__Dom__HtmlElement from "rescript-webapi/lib/es6/src/Webapi/Dom/Webapi__Dom__HtmlElement.mjs";
import * as DayTimeline_HighlightTimeModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/day_timeline/components/highlight_time/DayTimeline_HighlightTime.module.css";

var styles = DayTimeline_HighlightTimeModuleCss;

function use() {
  var locked = Schedule_State.useLocked();
  var match = DayTimeline_State.useTimelineSubscriptions();
  var onMouseLeave = match.onMouseLeave;
  var onMouseMove = match.onMouseMove;
  var onMouseEnter = match.onMouseEnter;
  var rafId = React.useRef(undefined);
  var setPosition = function (element) {
    return function (coordinate) {
      var rafID = rafId.current;
      if (rafID !== undefined) {
        cancelAnimationFrame(Caml_option.valFromOption(rafID));
      }
      rafId.current = Caml_option.some(requestAnimationFrame(function (_time) {
                element.style.setProperty("left", Units.Px.toString(coordinate[0]));
                rafId.current = undefined;
              }));
    };
  };
  var hide = function (element) {
    requestAnimationFrame(function (_time) {
          element.classList.remove(styles.visible);
        });
  };
  var elementRef = Types.ReactRef.use();
  React.useEffect((function () {
          var element = Types.ReactRef.toOption(elementRef);
          var htmlElement = RCore.$$Option.flatMap(element, Webapi__Dom__HtmlElement.ofElement);
          var match = Fun.both(element, htmlElement);
          if (match === undefined) {
            return ;
          }
          if (locked) {
            return ;
          }
          var element$1 = match[0];
          var unsubscribeFromMouseMove = onMouseMove.subscribe(setPosition(match[1]));
          var unsubscribeFromMouseEnter = onMouseEnter.subscribe(function () {
                requestAnimationFrame(function (_time) {
                      element$1.classList.add(styles.visible);
                    });
              });
          var unsubscribeMouseLeave = onMouseLeave.subscribe(function () {
                hide(element$1);
              });
          return (function () {
                    unsubscribeFromMouseMove();
                    unsubscribeFromMouseEnter();
                    unsubscribeMouseLeave();
                    hide(element$1);
                  });
        }), [locked]);
  return elementRef;
}

export {
  use ,
}
/* styles Not a pure module */
