import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from 'planado/actions/admin/recurring_jobs_index'
import Header from 'planado/components/admin/recurring_jobs/index/header'
import Table from 'planado/components/admin/recurring_jobs/index/table'

const HeaderC = {
  mapStateToProps: (state, _ownProps) => ({
    templates: state.templates
  }),
  mapDispatchToProps: (dispatch, _ownProps) => ({
    actions: bindActionCreators(actions, dispatch)
  })
}

export const HeaderLink = connect(
  HeaderC.mapStateToProps,
  HeaderC.mapDispatchToProps
)(Header)

const TableC = {
  mapStateToProps: (state, _ownProps) => ({
    recurringJobs: state.recurringJobs
  }),
  mapDispatchToProps: (_dispatch, _ownProps) => ({})
}

export const TableLink = connect(
  TableC.mapStateToProps,
  TableC.mapDispatchToProps
)(Table)
