import { SHOW_POPOVER } from 'planado/actions/admin/recurring_jobs_index'
import { getPosition } from 'planado/components/popover'

const reducer = (state = {}, action) => {
  switch (action.type) {
    case SHOW_POPOVER:
      return {
        ...state,
        popover: {
          node: action.node,
          targetId: action.buttonId,
          ...getPosition(action.dom, action.buttonId)
        }
      }
    case 'WINDOW_CLICKED':
      return {
        ...state,
        popover: { targetId: null }
      }
    default:
      return state
  }
}

export default reducer
