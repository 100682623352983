

import * as Fun from "../../../../../../../../libraries/Fun.mjs";
import * as Uuid from "../../../../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../../../../libraries/RCore.mjs";
import * as React from "react";
import * as Schedule_Types_Job from "../../../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as WeekTimeline_State from "../../../../lib/state/WeekTimeline_State.mjs";
import * as Schedule_UtilsTimeline from "../../../../../../lib/utils/Schedule_UtilsTimeline.mjs";
import * as WeekTimeline_Constants from "../../../../lib/WeekTimeline_Constants.mjs";
import * as WeekTimeline_ActiveDayJob from "../job/WeekTimeline_ActiveDayJob.mjs";
import * as Schedule_TimelineJobGroups from "../../../../../../components/timeline/job_groups/Schedule_TimelineJobGroups.mjs";
import * as WeekTimeline_ActiveDayBarDrop from "./hooks/WeekTimeline_ActiveDayBarDrop.mjs";
import * as WeekTimeline_ActiveDayInterval from "../interval/WeekTimeline_ActiveDayInterval.mjs";
import * as WeekTimeline_ActiveDayStateTypes from "../../lib/state/WeekTimeline_ActiveDayStateTypes.mjs";
import * as WeekTimeline_ActiveDayBarListeners from "./hooks/WeekTimeline_ActiveDayBarListeners.mjs";
import * as WeekTimeline_ActiveDayBarAddJobPopover from "./components/WeekTimeline_ActiveDayBarAddJobPopover.mjs";
import * as WeekTimeline_ActiveDayBarModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/active_day/components/bar/WeekTimeline_ActiveDayBar.module.css";

var styles = WeekTimeline_ActiveDayBarModuleCss;

function WeekTimeline_ActiveDayBar$Cells(Props) {
  return RCore.$$Array.make(WeekTimeline_Constants.hoursInDay, undefined).map(function (param, idx) {
              return React.createElement("div", {
                          key: idx.toString(),
                          className: styles.cell
                        });
            });
}

function WeekTimeline_ActiveDayBar$Jobs(Props) {
  var param = Props.activeDay;
  var bar = param.bar;
  return param.jobs.map(function (param) {
              var job = param[1];
              return React.createElement(WeekTimeline_ActiveDayJob.make, {
                          job: job,
                          position: param[0],
                          bar: bar,
                          key: Uuid.toString(Schedule_Types_Job.AssignedJob.uuid(job))
                        });
            });
}

function WeekTimeline_ActiveDayBar$Intervals(Props) {
  var intervals = Props.intervals;
  return intervals.map(function (position) {
              return React.createElement(WeekTimeline_ActiveDayInterval.make, {
                          position: position
                        });
            });
}

function WeekTimeline_ActiveDayBar(Props) {
  var activeDay = Props.activeDay;
  var elementRef = WeekTimeline_ActiveDayBarDrop.use(activeDay);
  var match = WeekTimeline_ActiveDayBarListeners.use(elementRef);
  var contentEl = Schedule_UtilsTimeline.contentEl(activeDay.elements.viewportEl);
  var match$1 = Fun.both(match[0], contentEl);
  var addJobPopoverBlock = match$1 !== undefined ? React.createElement(WeekTimeline_ActiveDayBarAddJobPopover.make, {
          activeDay: activeDay,
          contentEl: match$1[1],
          coordinate: match$1[0],
          close: match[1]
        }) : null;
  var match$2 = WeekTimeline_State.useTimelineSubscriptions();
  var hiddenJobs = React.useMemo((function () {
          return RCore.$$Array.keep(activeDay.jobs, (function (param) {
                        return param[0].hidden.contents;
                      }));
        }), [activeDay.jobs]);
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  ref: elementRef,
                  className: styles.bar
                }, React.createElement(WeekTimeline_ActiveDayBar$Cells, {}), React.createElement(WeekTimeline_ActiveDayBar$Intervals, {
                      intervals: activeDay.intervals
                    }), React.createElement(WeekTimeline_ActiveDayBar$Jobs, {
                      activeDay: activeDay
                    }), React.createElement(Schedule_TimelineJobGroups.make, {
                      isActiveDay: true,
                      assigneeUuid: WeekTimeline_ActiveDayStateTypes.ActiveDaySelector.assigneeUuid(activeDay),
                      onScrollStart: match$2.onScrollStart,
                      jobs: hiddenJobs
                    })), addJobPopoverBlock);
}

var make = WeekTimeline_ActiveDayBar;

export {
  make ,
}
/* styles Not a pure module */
