

import * as El from "../../../libraries/El.mjs";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Filters_ButtonGroupModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/Filters/Filters_ButtonGroup.module.css";

var styles = Filters_ButtonGroupModuleCss;

function Filters_ButtonGroup(Props) {
  var id = Props.id;
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var tmp = {
    className: El.Cn.concat(styles.group, className)
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  return React.createElement("div", tmp, children);
}

var make = Filters_ButtonGroup;

export {
  make ,
}
/* styles Not a pure module */
