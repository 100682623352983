import React, { Component } from 'react'

export default class InfiniteScroll extends Component {
  constructor(props) {
    super(props)

    this.componentDidMount = this.attachScrollListener
    this.componentDidUpdate = this.attachScrollListener
    this.componentWillUnmount = this.detachScrollListener
  }

  attachScrollListener = () => {
    let { scrollEl } = this.props
    if (scrollEl === null) {
      scrollEl = window
    }

    if (this.props.hasMore) {
      scrollEl.addEventListener('scroll', this.scrollListener)
      scrollEl.addEventListener('resize', this.scrollListener)
    }
  }

  detachScrollListener = () => {
    let { scrollEl } = this.props
    if (scrollEl === null) {
      scrollEl = window
    }

    scrollEl.removeEventListener('scroll', this.scrollListener)
    scrollEl.removeEventListener('resize', this.scrollListener)
  }

  scrollListener = () => {
    const el = this.scrollComponent
    let { scrollEl } = this.props

    let offset
    if (scrollEl === null) {
      let scrollTop
      if (window.pageYOffset) {
        scrollTop = window.pageYOffset
      } else {
        scrollTop = (
          document.documentElement ||
          document.body.parentNode ||
          document.body
        ).scrollTop
      }
      offset =
        this.calculateTopPosition(el) +
        el.offsetHeight -
        scrollTop -
        window.innerHeight
    } else {
      offset = el.scrollHeight - scrollEl.scrollTop - scrollEl.clientHeight
    }

    if (offset < 0 && this.props.hasMore) {
      this.detachScrollListener()
      this.props.loadMore()
    }
  }

  calculateTopPosition(el) {
    if (!el) {
      return 0
    } else {
      return el.offsetTop + this.calculateTopPosition(el.offsetParent)
    }
  }

  render() {
    const { children } = this.props

    return <div ref={node => (this.scrollComponent = node)}>{children}</div>
  }
}

InfiniteScroll.defaultProps = {
  scrollEl: null
}
