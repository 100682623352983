

import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as React from "react";
import * as JobFieldType from "../../../JobFieldType.mjs";
import * as JobViewTypes from "../../../views/JobViewTypes.mjs";
import * as JobIndexFilter_Types from "../../common/JobIndexFilter_Types.mjs";
import * as JobIndexFilter_CustomBoolean from "../custom/JobIndexFilter_CustomBoolean.mjs";
import * as JobIndexFilter_FieldsTranslations from "../../common/JobIndexFilter_FieldsTranslations.mjs";

function JobIndexFilter_PredefinedAnyOrEmpty(Props) {
  var currentFilter = Props.currentFilter;
  var activeFilters = Props.activeFilters;
  var predefinedFields = Props.predefinedFields;
  var ctx = Props.ctx;
  var send = Props.send;
  var toggleFilter = Props.toggleFilter;
  var filterDisabled = Props.filterDisabled;
  var enabledFilters = [
    "CompletionJobs",
    "WorkDuration",
    "EnRouteDuration",
    "SuspensionDuration",
    "ActualDuration",
    "Geolocation"
  ];
  var label = function (field) {
    if (enabledFilters.some(function (f) {
            return f === field;
          })) {
      return JobIndexFilter_FieldsTranslations.jobFieldsHeader$p(field)(ctx);
    } else {
      return "";
    }
  };
  var wrapValue = function (key, value) {
    return RCore.$$Option.map(RCore.$$Array.getBy(enabledFilters, (function (f) {
                      return f === key;
                    })), (function (filted) {
                  switch (filted) {
                    case "Geolocation" :
                        return {
                                TAG: "Geolocation",
                                _0: value
                              };
                    case "CompletionJobs" :
                        return {
                                TAG: "Uuids",
                                _0: value
                              };
                    default:
                      return {
                              TAG: "Duration",
                              _0: value
                            };
                  }
                }));
  };
  var valueToBoolean = function (value) {
    if (value === "NotFilled") {
      return false;
    } else {
      return true;
    }
  };
  return enabledFilters.map(function (key) {
              var field = RCore.$$Array.getBy(predefinedFields, (function (j) {
                      if (j.enabled) {
                        return key === j.key;
                      } else {
                        return false;
                      }
                    }));
              if (field === undefined) {
                return null;
              }
              var match = RCore.$$Option.map(RCore.$$Array.getBy(currentFilter.predefinedFields, (function (param) {
                          return field.key === param.key;
                        })), (function (f) {
                      return f.value;
                    }));
              var tmp;
              if (match !== undefined) {
                var exit = 0;
                switch (match.TAG) {
                  case "Duration" :
                  case "Geolocation" :
                  case "Uuids" :
                      exit = 1;
                      break;
                  default:
                    tmp = undefined;
                }
                if (exit === 1) {
                  tmp = valueToBoolean(match._0);
                }
                
              } else {
                tmp = undefined;
              }
              return React.createElement(JobIndexFilter_CustomBoolean.make, {
                          label: label(key),
                          resetFilter: (function () {
                              send({
                                    TAG: "ResetPredefinedField",
                                    _0: key
                                  });
                            }),
                          toggleFilter: toggleFilter({
                                TAG: "PredefinedField",
                                _0: key
                              }),
                          filterByField: (function (value) {
                              var v = wrapValue(key, JobViewTypes.Settings.Filter.Value.PolytypeAnyOrEmpty.fromBoolean(value));
                              if (v !== undefined) {
                                return send({
                                            TAG: "FilterByJobField",
                                            _0: key,
                                            _1: v
                                          });
                              }
                              
                            }),
                          value: tmp,
                          show: activeFilters.some(function (f) {
                                return JobIndexFilter_Types.predefinedEql(key)(f);
                              }),
                          disabled: filterDisabled,
                          type_: "PolytypeAnyOrEmpty",
                          key: JobFieldType.toString(field.key)
                        });
            });
}

var make = JobIndexFilter_PredefinedAnyOrEmpty;

export {
  make ,
}
/* react Not a pure module */
