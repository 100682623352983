

import * as Wire from "../../../../types/Wire.mjs";
import * as EncodeJson from "../../../../libraries/EncodeJson.mjs";
import * as JsonDecode from "../../../../libraries/JsonDecode.mjs";
import * as Context_Types from "../../../../context/Context_Types.mjs";

function fromString(str) {
  switch (str) {
    case "new" :
        return "New";
    case "old" :
        return "Old";
    default:
      return ;
  }
}

function toString(mode) {
  if (mode === "Old") {
    return "old";
  } else {
    return "new";
  }
}

var decode = JsonDecode.fromVariant(JsonDecode.string, fromString);

var Mode = {
  fromString: fromString,
  toString: toString,
  decode: decode
};

function initial(wire) {
  var showOldTimeline = Context_Types.Features.hasFlag("showOldTimeline", Wire.ctx(wire).features);
  var scheduleZoom = Context_Types.Features.hasFlag("scheduleZoom", Wire.ctx(wire).features);
  var day = showOldTimeline ? "Old" : "New";
  var week = showOldTimeline && scheduleZoom ? "Old" : "New";
  return {
          day: day,
          week: week
        };
}

var decode$1 = JsonDecode.object(function (field) {
      return {
              day: field.required("day", decode),
              week: field.required("week", decode)
            };
    });

function encode(mode) {
  return EncodeJson.object([
              [
                "day",
                EncodeJson.string(toString(mode.day))
              ],
              [
                "week",
                EncodeJson.string(toString(mode.week))
              ]
            ]);
}

export {
  Mode ,
  initial ,
  decode$1 as decode,
  encode ,
}
/* decode Not a pure module */
