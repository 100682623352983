import { connect } from 'react-redux'

import Panel from 'planado/components/map/panel'

const mapStateToProps = ({ historyWorkerUuid, engineChannel }) => {
  return {
    historyMode: historyWorkerUuid !== null,
    engineChannel
  }
}

const mapDispatchToProps = _dispatch => ({})

const PanelLink = connect(mapStateToProps, mapDispatchToProps)(Panel)

export default PanelLink
