

import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as React from "react";
import * as Optional from "../../../../../common/Optional.mjs";
import * as WeekCalendar_Utils from "../../lib/WeekCalendar_Utils.mjs";
import * as WeekCalendar_JobGroup from "./group/WeekCalendar_JobGroup.mjs";
import * as WeekCalendar_JobGroupsHook from "./WeekCalendar_JobGroupsHook.mjs";

function WeekCalendar_JobGroups(Props) {
  var calendarRef = Props.calendarRef;
  var columnWidth = Props.columnWidth;
  var weekDay = Props.weekDay;
  var jobs = Props.jobs;
  var groups = WeekCalendar_JobGroupsHook.use(jobs);
  return groups.map(function (group, index) {
              var startOfFirstJob = RCore.$$Option.mapWithDefault(group.jobs[0], 0.0, (function (job) {
                      return WeekCalendar_Utils.JobStyle.topPx(job[0].start);
                    }));
              var sizeOfJobs = group.jobs.length;
              var show = sizeOfJobs > 1 || sizeOfJobs === 1 && !(group.startPx <= startOfFirstJob && startOfFirstJob <= group.finishPx);
              return React.createElement(Optional.make, {
                          show: show,
                          children: React.createElement(WeekCalendar_JobGroup.make, {
                                calendarRef: calendarRef,
                                weekDay: weekDay,
                                columnWidth: columnWidth,
                                group: group
                              }),
                          key: index.toString()
                        });
            });
}

var make = WeekCalendar_JobGroups;

export {
  make ,
}
/* react Not a pure module */
