

import * as El from "../../../libraries/El.mjs";
import * as Fun from "../../../libraries/Fun.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as MultipleAssignee from "../../../types/MultipleAssignee.mjs";
import * as MapJobsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/map/jobs/MapJobs.module.css";

var styles = MapJobsModuleCss;

var jobNo$p = Utils.Translations.txr("js.map.serial_no");

function MapJobsItem(Props) {
  var wire = Props.wire;
  var onClick = Props.onClick;
  var focusedUuid = Props.focusedUuid;
  var job = Props.job;
  var fmtTime = function (v) {
    return Locale.T.fmtM("Time", wire)(v);
  };
  var focused = RCore.$$Option.getOr(RCore.$$Option.map(focusedUuid, (function (uuid) {
              return Caml_obj.equal(uuid, job.uuid);
            })), false);
  var className = focused ? styles.focused : "";
  var match = MultipleAssignee.mainAssigneeName(job.assignees);
  var match$1 = job.scheduledAt;
  return React.createElement("li", {
              key: Uuid.toString(job.uuid),
              className: className,
              onClick: Fun.omit(onClick(job))
            }, React.createElement("div", {
                  className: styles.jobName
                }, jobNo$p({
                      serialNo: job.serialNo
                    }, wire.ctx), El.space, RCore.$$Option.getOr(job.templateName, "")), React.createElement("div", {
                  className: styles.jobDetails
                }, match !== undefined ? (
                    match$1 !== undefined ? React.createElement(React.Fragment, undefined, fmtTime(Caml_option.valFromOption(match$1)), El.space, El.dot, match, El.space) : match
                  ) : (
                    match$1 !== undefined ? fmtTime(Caml_option.valFromOption(match$1)) : null
                  )));
}

var make = MapJobsItem;

export {
  make ,
}
/* styles Not a pure module */
