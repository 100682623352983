

import IndexJsx from "planado/components/address_input/location/index.jsx";

var make = IndexJsx;

export {
  make ,
}
/* make Not a pure module */
