

import * as Js_array from "rescript/lib/es6/js_array.js";
import * as StringScanner from "./string_scanner/StringScanner.mjs";

var whiteSpaceOrNothing = /\s*/;

var comprassionOperator = /==|!=|<>|<=?|>=?|contains(?=\s)/;

var singleStringLiteral = /'[^\']*'/;

var doubleStringLiteral = /"[^\"]*"/;

var numberLiteral = /-?\d+(\.\d+)?/;

var identifier = /[a-zA-Z_][\w-]*\??/;

var dotdot = /\.\./;

function fromToken(token) {
  if (typeof token !== "object") {
    switch (token) {
      case "Pipe" :
          return "Pipe";
      case "Dot" :
          return "Dot";
      case "Colon" :
          return "Colon";
      case "Comma" :
          return "Comma";
      case "OpenSquare" :
          return "OpenSquare";
      case "CloseSquare" :
          return "CloseSquare";
      case "OpenRound" :
          return "OpenRound";
      case "CloseRound" :
          return "CloseRound";
      case "Question" :
          return "Question";
      case "Dash" :
          return "Dash";
      case "EndOfString" :
          return "EndOfString";
      
    }
  } else {
    switch (token.TAG) {
      case "Comparison" :
          return "Comparison";
      case "String" :
          return "String";
      case "Number" :
          return "Number";
      case "Id" :
          return "Id";
      case "DotDot" :
          return "DotDot";
      
    }
  }
}

var Type = {
  fromToken: fromToken
};

function scanComprasionOperator(ss) {
  return function () {
    var token = StringScanner.scan(ss, comprassionOperator);
    if (token !== undefined) {
      return {
              TAG: "Comparison",
              _0: token
            };
    }
    
  };
}

function scanSingleStringLiteral(ss) {
  return function () {
    var token = StringScanner.scan(ss, singleStringLiteral);
    if (token !== undefined) {
      return {
              TAG: "String",
              _0: token
            };
    }
    
  };
}

function scanDoubleStringLiteral(ss) {
  return function () {
    var token = StringScanner.scan(ss, doubleStringLiteral);
    if (token !== undefined) {
      return {
              TAG: "String",
              _0: token
            };
    }
    
  };
}

function scanNumberLiteral(ss) {
  return function () {
    var token = StringScanner.scan(ss, numberLiteral);
    if (token !== undefined) {
      return {
              TAG: "Number",
              _0: token
            };
    }
    
  };
}

function scanIdentifier(ss) {
  return function () {
    var token = StringScanner.scan(ss, identifier);
    if (token !== undefined) {
      return {
              TAG: "Id",
              _0: token
            };
    }
    
  };
}

function scanDotDot(ss) {
  return function () {
    var token = StringScanner.scan(ss, dotdot);
    if (token !== undefined) {
      return {
              TAG: "DotDot",
              _0: token
            };
    }
    
  };
}

function scanSpecial(ss) {
  return function () {
    var match = StringScanner.scanChar(ss);
    if (match === undefined) {
      return ;
    }
    switch (match) {
      case "(" :
          return "OpenRound";
      case ")" :
          return "CloseRound";
      case "," :
          return "Comma";
      case "-" :
          return "Dash";
      case "." :
          return "Dot";
      case ":" :
          return "Colon";
      case "?" :
          return "Question";
      case "[" :
          return "OpenSquare";
      case "]" :
          return "CloseSquare";
      case "|" :
          return "Pipe";
      default:
        return ;
    }
  };
}

function toString(lexeme) {
  if (typeof lexeme === "object") {
    return lexeme._0;
  }
  switch (lexeme) {
    case "Pipe" :
        return "|";
    case "Dot" :
        return ".";
    case "Colon" :
        return ":";
    case "Comma" :
        return ",";
    case "OpenSquare" :
        return "[";
    case "CloseSquare" :
        return "]";
    case "OpenRound" :
        return "(";
    case "CloseRound" :
        return ")";
    case "Question" :
    case "Dash" :
        return "?";
    case "EndOfString" :
        return "eos";
    
  }
}

function applyOpt(x, y) {
  if (x !== undefined) {
    return x;
  } else {
    return y();
  }
}

function tokenize(source) {
  var tokens = [];
  var $$break = false;
  var ss = StringScanner.make(source);
  while(!$$break && !StringScanner.hasTerminated(ss)) {
    StringScanner.skip(ss, whiteSpaceOrNothing);
    if (StringScanner.hasTerminated(ss)) {
      $$break = true;
    } else {
      var token = applyOpt(applyOpt(applyOpt(applyOpt(applyOpt(applyOpt(scanComprasionOperator(ss)(), scanSingleStringLiteral(ss)), scanDoubleStringLiteral(ss)), scanNumberLiteral(ss)), scanIdentifier(ss)), scanDotDot(ss)), scanSpecial(ss));
      if (token !== undefined) {
        Js_array.push(token, tokens);
      }
      
    }
  };
  Js_array.push("EndOfString", tokens);
  return tokens;
}

export {
  Type ,
  toString ,
  tokenize ,
}
/* StringScanner Not a pure module */
