

import * as JsonDecode from "../../../../../libraries/JsonDecode.mjs";

function toString(version) {
  if (version === "V1") {
    return "v1";
  } else {
    return "v2";
  }
}

function fromString(version) {
  switch (version) {
    case "v1" :
        return "V1";
    case "v2" :
        return "V2";
    default:
      return ;
  }
}

var decode = JsonDecode.fromVariant(JsonDecode.string, fromString);

function fromString$1(view) {
  switch (view) {
    case "day" :
    case "dayTimeline" :
        return {
                NAME: "Timeline",
                VAL: "Day"
              };
    case "month" :
    case "monthCalendar" :
        return {
                NAME: "Calendar",
                VAL: "Month"
              };
    case "week" :
    case "weekCalendar" :
        return {
                NAME: "Calendar",
                VAL: "Week"
              };
    case "weekTimeline" :
        return {
                NAME: "Timeline",
                VAL: "Week"
              };
    default:
      return ;
  }
}

function toString$1(view) {
  if (view.NAME === "Calendar") {
    if (view.VAL === "Week") {
      return "weekCalendar";
    } else {
      return "monthCalendar";
    }
  } else if (view.VAL === "Week") {
    return "weekTimeline";
  } else {
    return "dayTimeline";
  }
}

var decode$1 = JsonDecode.fromVariant(JsonDecode.string, fromString$1);

var Version = {
  toString: toString,
  decode: decode
};

var View = {
  toString: toString$1,
  decode: decode$1
};

export {
  Version ,
  View ,
}
/* decode Not a pure module */
