

import * as El from "../../../../libraries/El.mjs";
import * as Icon from "../../../../Icon.mjs";
import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as Hooks from "../../../../libraries/hooks/Hooks.mjs";
import * as Panel from "../../../common/Panel/Panel.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Types from "../../../../types/Types.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../libraries/Locale.mjs";
import * as Backend from "../../../../libraries/backend/Backend.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as DomUtils from "../../../../DomUtils.mjs";
import * as Optional from "../../../common/Optional.mjs";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as ShiftsShow from "../../show/ShiftsShow.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ShiftsShowAPI from "../../show/ShiftsShowAPI.mjs";
import * as Backend_Errors from "../../../../libraries/backend/Backend_Errors.mjs";
import * as MessengerTypes from "../../../messenger/MessengerTypes.mjs";
import * as ShiftsIndexAPI from "../ShiftsIndexAPI.mjs";
import * as ShiftsIndexTeamMembersCell from "./ShiftsIndexTeamMembersCell.mjs";
import * as ShiftsIndexCellModuleCss from "/home/runner/work/planado/planado/client/rescript/components/shifts/index/ShiftsIndexCell.module.css";

var styles = ShiftsIndexCellModuleCss;

var shiftUpdated$p = Utils.Translations.t("js.shifts.show.shift_updated");

var unprocessableEntity$p = Utils.Translations.t("js.shifts.show.unprocessable_entity");

var serverError$p = Utils.Translations.t("js.shifts.show.server_error");

function notifyOk(wire, text) {
  wire.subscriptions.messenger.trigger({
        TAG: "Show",
        _0: MessengerTypes.Message.make(undefined, "Success", {
              TAG: "Text",
              _0: text
            })
      });
}

function notifyError(wire, text) {
  wire.subscriptions.messenger.trigger({
        TAG: "Show",
        _0: MessengerTypes.Message.make(undefined, "Danger", {
              TAG: "Text",
              _0: text
            })
      });
}

function ShiftsIndexTeamCell(Props) {
  var day = Props.day;
  var team = Props.team;
  var workers = Props.workers;
  var jobTypes = Props.jobTypes;
  var territories = Props.territories;
  var updateTeam = Props.updateTeam;
  var updateWorker = Props.updateWorker;
  var constructorWorker = Props.constructorWorker;
  var onConstructorWorkerUpdate = Props.onConstructorWorkerUpdate;
  var interval = Props.interval;
  var isOpened = Props.isOpened;
  var mode = Props.mode;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var buttonId = Hooks.useId(undefined);
  var match = Hooks.useLocked(false);
  var unlock = match[2];
  var lock = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowForm = match$1[1];
  var showForm = match$1[0];
  var teamShift = React.useMemo((function () {
          return RCore.$$Array.getBy(team.shifts, (function (s) {
                        return Locale.T.equal(s.date, day);
                      }));
        }), [team.shifts]);
  var match$2 = React.useMemo((function () {
          if (constructorWorker === undefined) {
            return [
                    false,
                    false
                  ];
          }
          var match = constructorWorker.dates;
          if (match === undefined) {
            return [
                    false,
                    false
                  ];
          }
          var max = match[1];
          var min = match[0];
          if (max === undefined) {
            return [
                    Locale.T.equalDay(min, day),
                    false
                  ];
          }
          var within = Locale.T.withinInterval(day, [
                min,
                Caml_option.valFromOption(max)
              ]);
          return [
                  within,
                  within
                ];
        }), [constructorWorker]);
  var isConstrHighlighted = match$2[1];
  var cellCN = React.useMemo((function () {
          var shapeCN = teamShift !== undefined ? styles.workday : styles.emptyDay;
          var constructorCN;
          constructorCN = mode === "View" ? El.Cn.concat(shapeCN, styles.view) : El.Cn.concat(shapeCN, styles.constructor);
          var base = isConstrHighlighted || showForm ? El.Cn.concat(constructorCN, styles.picked) : constructorCN;
          if (isOpened) {
            return El.Cn.concat(base, styles.openedCell);
          } else {
            return base;
          }
        }), [
        teamShift,
        mode,
        constructorWorker,
        isOpened
      ]);
  var popoverRef = Types.ReactRef.use();
  var cellRef = Types.ReactRef.use();
  var position = function (popoverRef) {
    var container = popoverRef.current;
    if (container == null) {
      return "bottom";
    }
    var position$1 = DomUtils.BoundingClientRect.get(container);
    if (((position$1.right | 0) + 600 | 0) >= DomUtils.windowInnerWidth()) {
      return "bottomRight";
    } else {
      return "bottom";
    }
  };
  var refresh = function (workerUuids, teamUuids, onSuccess, onError) {
    Backend.$$finally(ShiftsIndexAPI.list(wire, interval, workerUuids, teamUuids), (function (result) {
            if (result.TAG !== "Ok") {
              return onError();
            }
            var match = result._0;
            match.users.forEach(function (u) {
                  updateWorker(u);
                });
            match.teams.forEach(function (t) {
                  updateTeam(t);
                });
            onSuccess();
          }));
  };
  var deleteShift = function () {
    if (teamShift !== undefined) {
      return updateTeam({
                  uuid: team.uuid,
                  name: team.name,
                  shifts: RCore.$$Array.keep(team.shifts, (function (s) {
                          return !Uuid.equal(s.uuid, teamShift.uuid);
                        })),
                  territoryUuid: team.territoryUuid,
                  workerUuids: team.workerUuids,
                  foremanUuid: team.foremanUuid,
                  temporaryWorkers: team.temporaryWorkers
                });
    }
    
  };
  var onClick = function (param) {
    if (mode === "View") {
      return setShowForm(function (param) {
                  return true;
                });
    } else {
      return onConstructorWorkerUpdate(team.uuid, day);
    }
  };
  var handleResponse = function (response) {
    if (response.TAG === "Ok") {
      var match = response._0;
      refresh(match.workerUuids, match.teamUuids, (function () {
              notifyOk(wire, shiftUpdated$p(ctx));
            }), (function () {
              notifyError(wire, serverError$p(ctx));
            }));
      return unlock();
    }
    var errors = response._0;
    if (typeof errors === "object" && errors.TAG === "ValidationError") {
      unlock();
      return notifyError(wire, RCore.$$Option.getOr(Backend_Errors.getFirst(errors._0, "intervals"), unprocessableEntity$p(ctx)));
    }
    unlock();
    notifyError(wire, serverError$p(ctx));
  };
  var handleDeleteResponse = function (response) {
    if (response.TAG === "Ok") {
      deleteShift();
      refresh([], [], (function (prim) {
              
            }), (function (prim) {
              
            }));
      notifyOk(wire, shiftUpdated$p(ctx));
    } else {
      console.log(response._0);
      notifyError(wire, unprocessableEntity$p(ctx));
    }
    unlock();
  };
  var submitShift = function (newShift) {
    if (newShift === undefined) {
      if (teamShift !== undefined) {
        lock();
        return Backend.$$finally(ShiftsShowAPI.Delete.$$delete(wire, teamShift), handleDeleteResponse);
      } else {
        return ;
      }
    }
    if (teamShift !== undefined && Caml_obj.equal(newShift, teamShift)) {
      return ;
    }
    lock();
    Backend.$$finally(ShiftsShowAPI.PatchTeam.update(wire, team.uuid, newShift), handleResponse);
  };
  return React.createElement("div", {
              className: styles.teamCellContainer
            }, React.createElement("div", {
                  ref: cellRef,
                  className: El.Cn.concat(styles.teamCell, cellCN),
                  id: buttonId,
                  onClick: onClick
                }, React.createElement(Optional.make, {
                      show: match$2[0],
                      children: React.createElement("i", {
                            className: Icon.style(undefined, undefined, "check")
                          })
                    }), React.createElement(Panel.make, {
                      footer: wire.footer,
                      show: showForm,
                      domId: buttonId,
                      position: position(cellRef),
                      children: React.createElement(ShiftsShow.make, {
                            owner: {
                              TAG: "Team",
                              _0: team
                            },
                            shift: teamShift,
                            selectedDay: day,
                            jobTypes: jobTypes,
                            territories: territories,
                            popoverRef: popoverRef,
                            submitShift: submitShift,
                            locked: match[0],
                            close: (function () {
                                setShowForm(function (param) {
                                      return false;
                                    });
                              })
                          })
                    })), React.createElement(Optional.make, {
                  show: isOpened,
                  children: React.createElement(ShiftsIndexTeamMembersCell.make, {
                        mode: mode,
                        day: day,
                        workers: workers,
                        team: team,
                        isPicked: isConstrHighlighted || showForm
                      })
                }));
}

var make = ShiftsIndexTeamCell;

export {
  make ,
}
/* styles Not a pure module */
