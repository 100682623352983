

import * as RCore from "../libraries/RCore.mjs";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";

function titleKey(route) {
  if (typeof route !== "object") {
    if (route === "AuditLog") {
      return {
              TAG: "Any",
              _0: "js.titles.audit_log.index"
            };
    } else if (route === "Shifts") {
      return {
              TAG: "Any",
              _0: "js.titles.shifts.index"
            };
    } else if (route === "ResolutionIndex") {
      return {
              TAG: "Any",
              _0: "js.titles.resolutions"
            };
    } else if (route === "Login") {
      return {
              TAG: "Any",
              _0: "js.titles.login"
            };
    } else if (route === "Skills") {
      return {
              TAG: "Any",
              _0: "js.titles.skills"
            };
    } else if (route === "JobTypeIndex") {
      return {
              TAG: "Any",
              _0: "js.titles.job_types"
            };
    } else if (route === "Billing") {
      return {
              TAG: "Any",
              _0: "js.titles.billing"
            };
    } else if (route === "GeneralSettings") {
      return {
              TAG: "Any",
              _0: "js.titles.settings"
            };
    } else if (route === "Unknown") {
      return {
              TAG: "Any",
              _0: "js.titles.unknown"
            };
    } else if (route === "PaddlePaymentSuccess") {
      return {
              TAG: "Any",
              _0: "js.titles.paddle_success"
            };
    } else {
      return "Empty";
    }
  }
  var variant = route.NAME;
  if (variant === "ClientPortal") {
    return {
            TAG: "Any",
            _0: "js.titles.client_portal.index"
          };
  }
  if (variant === "CustomFields") {
    return {
            TAG: "Any",
            _0: "js.titles.custom_fields.index"
          };
  }
  if (variant === "Integrations") {
    var match = route.VAL;
    if (typeof match !== "object") {
      if (match === "Index") {
        return {
                TAG: "Any",
                _0: "js.titles.integrations.index"
              };
      } else if (match === "API") {
        return {
                TAG: "Any",
                _0: "js.titles.integrations.api"
              };
      } else {
        return "Empty";
      }
    }
    var variant$1 = match.NAME;
    if (variant$1 === "amoCRM") {
      return {
              TAG: "Any",
              _0: "js.titles.integrations.amocrm"
            };
    }
    if (variant$1 === "Bitrix24") {
      return {
              TAG: "Any",
              _0: "js.titles.integrations.bitrix24"
            };
    }
    if (variant$1 !== "Webhooks") {
      return "Empty";
    }
    var match$1 = match.VAL;
    if (typeof match$1 === "object") {
      if (match$1.NAME === "Edit") {
        return {
                TAG: "Any",
                _0: "js.titles.integrations.webhooks.edit"
              };
      } else {
        return "Empty";
      }
    } else if (match$1 === "New") {
      return {
              TAG: "Any",
              _0: "js.titles.integrations.webhooks.new"
            };
    } else {
      return "Empty";
    }
  }
  if (variant === "PrintLayout") {
    var match$2 = route.VAL;
    if (typeof match$2 === "object") {
      if (match$2.NAME === "Edit") {
        return {
                TAG: "Any",
                _0: "js.titles.print_layouts.edit"
              };
      } else {
        return "Empty";
      }
    } else if (match$2 === "Index") {
      return {
              TAG: "Any",
              _0: "js.titles.print_layouts.index"
            };
    } else if (match$2 === "New") {
      return {
              TAG: "Any",
              _0: "js.titles.print_layouts.new"
            };
    } else {
      return "Empty";
    }
  }
  if (variant === "MapShow") {
    return {
            TAG: "Any",
            _0: "js.titles.map"
          };
  }
  if (variant === "Job") {
    var match$3 = route.VAL;
    if (match$3 === "Upload") {
      return {
              TAG: "Any",
              _0: "js.jobs.upload.title"
            };
    } else if (match$3 === "Index") {
      return {
              TAG: "Any",
              _0: "js.titles.jobs.index"
            };
    } else {
      return "Empty";
    }
  }
  if (variant === "Template") {
    var match$4 = route.VAL;
    if (typeof match$4 !== "object") {
      if (match$4 === "Index") {
        return {
                TAG: "Any",
                _0: "js.titles.templates.index"
              };
      } else {
        return "Empty";
      }
    }
    var variant$2 = match$4.NAME;
    if (variant$2 === "New") {
      return {
              TAG: "Any",
              _0: "js.titles.templates.new"
            };
    } else if (variant$2 === "Edit") {
      return {
              TAG: "Any",
              _0: "js.titles.templates.edit"
            };
    } else {
      return "Empty";
    }
  }
  if (variant === "Schedule") {
    return {
            TAG: "Any",
            _0: "js.titles.schedule"
          };
  }
  if (variant === "ClientForm") {
    var match$5 = route.VAL;
    if (typeof match$5 === "object") {
      if (match$5.NAME === "Edit") {
        return {
                TAG: "Any",
                _0: "js.titles.client_forms.edit"
              };
      } else {
        return "Empty";
      }
    } else if (match$5 === "Index") {
      return {
              TAG: "Any",
              _0: "js.titles.client_forms.index"
            };
    } else if (match$5 === "New") {
      return {
              TAG: "Any",
              _0: "js.titles.client_forms.new"
            };
    } else {
      return "Empty";
    }
  }
  if (variant === "Products") {
    if (route.VAL === "Index") {
      return {
              TAG: "Any",
              _0: "js.titles.products.index"
            };
    } else {
      return "Empty";
    }
  }
  if (variant === "Territories") {
    var match$6 = route.VAL;
    if (typeof match$6 === "object") {
      if (match$6.NAME === "Edit") {
        return {
                TAG: "Any",
                _0: "js.titles.territories.edit"
              };
      } else {
        return "Empty";
      }
    } else if (match$6 === "Index") {
      return {
              TAG: "Any",
              _0: "js.titles.territories.index"
            };
    } else if (match$6 === "New") {
      return {
              TAG: "Any",
              _0: "js.titles.territories.new"
            };
    } else {
      return "Empty";
    }
  }
  if (variant === "Site") {
    if (route.VAL === "Index") {
      return {
              TAG: "Any",
              _0: "js.titles.sites.index"
            };
    } else {
      return "Empty";
    }
  }
  if (variant === "Team") {
    var match$7 = route.VAL;
    if (typeof match$7 === "object") {
      if (match$7.NAME === "Edit") {
        return {
                TAG: "ViewEdit",
                _0: "js.titles.teams.view",
                _1: "js.titles.teams.edit"
              };
      } else {
        return "Empty";
      }
    } else if (match$7 === "Index") {
      return {
              TAG: "Any",
              _0: "js.titles.teams.index"
            };
    } else if (match$7 === "New") {
      return {
              TAG: "Any",
              _0: "js.titles.teams.new"
            };
    } else {
      return "Empty";
    }
  }
  if (variant === "User") {
    var match$8 = route.VAL;
    if (typeof match$8 !== "object") {
      if (match$8 === "Index") {
        return {
                TAG: "Any",
                _0: "js.titles.users.index"
              };
      } else {
        return "Empty";
      }
    }
    var variant$3 = match$8.NAME;
    if (variant$3 === "Custom") {
      var match$9 = match$8.VAL;
      if (typeof match$9 === "object") {
        if (match$9.NAME === "Edit") {
          return {
                  TAG: "Any",
                  _0: "js.titles.users.edit"
                };
        } else {
          return "Empty";
        }
      } else if (match$9 === "New") {
        return {
                TAG: "Any",
                _0: "js.titles.users.new"
              };
      } else {
        return "Empty";
      }
    }
    if (variant$3 !== "Field") {
      return "Empty";
    }
    var match$10 = match$8.VAL;
    if (typeof match$10 === "object") {
      if (match$10.NAME === "Edit") {
        return {
                TAG: "Any",
                _0: "js.titles.users.edit_field_user"
              };
      } else {
        return "Empty";
      }
    } else if (match$10 === "New") {
      return {
              TAG: "Any",
              _0: "js.titles.users.new_field_user"
            };
    } else {
      return "Empty";
    }
  }
  if (variant === "Dictionary") {
    var match$11 = route.VAL;
    if (typeof match$11 === "object") {
      if (match$11.NAME === "Edit") {
        return {
                TAG: "Any",
                _0: "js.titles.dictionaries.edit"
              };
      } else {
        return "Empty";
      }
    } else if (match$11 === "Index") {
      return {
              TAG: "Any",
              _0: "js.titles.dictionaries.index"
            };
    } else if (match$11 === "New") {
      return {
              TAG: "Any",
              _0: "js.titles.dictionaries.new"
            };
    } else {
      return "Empty";
    }
  }
  if (variant === "Client") {
    if (route.VAL === "Index") {
      return {
              TAG: "Any",
              _0: "js.titles.clients.index"
            };
    } else {
      return "Empty";
    }
  }
  if (variant === "Services") {
    if (route.VAL === "Index") {
      return {
              TAG: "Any",
              _0: "js.titles.services.index"
            };
    } else {
      return "Empty";
    }
  }
  if (variant !== "RecurringJob") {
    return "Empty";
  }
  var match$12 = route.VAL;
  if (typeof match$12 !== "object") {
    if (match$12 === "Index") {
      return {
              TAG: "Any",
              _0: "js.titles.recurring_jobs.index"
            };
    } else {
      return "Empty";
    }
  }
  var variant$4 = match$12.NAME;
  if (variant$4 === "New") {
    return {
            TAG: "Any",
            _0: "js.titles.recurring_jobs.new"
          };
  } else if (variant$4 === "Edit") {
    return {
            TAG: "Any",
            _0: "js.titles.recurring_jobs.edit"
          };
  } else {
    return "Empty";
  }
}

function make(hasValues, param, permission) {
  var modals = param.modals;
  var match = Core__List.head(modals);
  var exit = 0;
  if (match !== undefined) {
    var match$1 = match.VAL;
    if (typeof match$1 === "object" && match$1.NAME === "Edit") {
      if (!hasValues) {
        return ;
      }
      exit = 1;
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var key = RCore.$$Option.map(Core__List.head(modals), (function (modal) {
            var variant = modal.NAME;
            if (variant === "Site") {
              var match = modal.VAL;
              if (typeof match === "object") {
                if (match.NAME === "Edit") {
                  return {
                          TAG: "ViewEdit",
                          _0: "js.titles.sites.view",
                          _1: "js.titles.sites.edit"
                        };
                } else if (match.VAL === "Sites") {
                  return {
                          TAG: "Any",
                          _0: "js.titles.sites.upload"
                        };
                } else {
                  return {
                          TAG: "Any",
                          _0: "js.titles.clients.upload"
                        };
                }
              } else {
                return {
                        TAG: "Any",
                        _0: "js.titles.sites.new"
                      };
              }
            }
            if (variant !== "Client") {
              if (modal.VAL.NAME === "Edit") {
                return {
                        TAG: "ViewEdit",
                        _0: "js.titles.jobs.view",
                        _1: "js.titles.jobs.edit"
                      };
              } else {
                return {
                        TAG: "Any",
                        _0: "js.titles.jobs.new"
                      };
              }
            }
            var match$1 = modal.VAL;
            if (typeof match$1 === "object") {
              if (match$1.NAME === "Edit") {
                return {
                        TAG: "ViewEdit",
                        _0: "js.titles.clients.view",
                        _1: "js.titles.clients.edit"
                      };
              } else if (match$1.VAL === "Sites") {
                return {
                        TAG: "Any",
                        _0: "js.titles.sites.upload"
                      };
              } else {
                return {
                        TAG: "Any",
                        _0: "js.titles.clients.upload"
                      };
              }
            } else {
              return {
                      TAG: "Any",
                      _0: "js.titles.clients.new"
                    };
            }
          }));
    var key$1 = RCore.$$Option.getOr(key, titleKey(param.path));
    var exit$1 = 0;
    if (typeof permission === "object") {
      return ;
    }
    if (permission === "ViewEditRemove") {
      if (typeof key$1 !== "object") {
        exit$1 = 2;
      } else {
        if (key$1.TAG !== "Any") {
          return key$1._1;
        }
        exit$1 = 2;
      }
    } else {
      if (permission === "Restricted") {
        return ;
      }
      if (permission === "View") {
        if (typeof key$1 !== "object") {
          exit$1 = 2;
        } else {
          if (key$1.TAG !== "Any") {
            return key$1._0;
          }
          exit$1 = 2;
        }
      } else if (typeof key$1 !== "object") {
        exit$1 = 2;
      } else {
        if (key$1.TAG !== "Any") {
          return key$1._1;
        }
        exit$1 = 2;
      }
    }
    if (exit$1 === 2) {
      if (typeof key$1 !== "object") {
        return ;
      } else {
        return key$1._0;
      }
    }
    
  }
  
}

export {
  titleKey ,
  make ,
}
/* No side effect */
