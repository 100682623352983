import React, { Component } from 'react'
import jQuery from 'jquery'
import moment from 'moment-timezone'
import { Provider } from 'react-redux'
import { SessionContext } from 'planado/utils/context.js'
import { getMoment, now } from 'planado/utils/time/index.js'
import { WrapperLink } from 'planado/schedule/containers'
import { subscribe } from 'planado/schedule/utils/schedule'

import Repositories from 'planado/schedule/utils/repositories'
import ViewController from 'planado/schedule/utils/view_controller'
import { Chart, SideBar } from 'planado/schedule/utils/models'
import { BarJobs } from 'planado/schedule/utils/models'

const setScale = (scale, store) => {
  const {
    backbone: { chart, navController },
    ctx,
  } = store.getState()

  if (navController !== null) {
    const now_ = getMoment({ dateTime: now(ctx), ctx })

    let period
    switch (scale) {
      case 'day': {
        const day = moment.duration({ days: 1 })

        period = [
          now_.clone().subtract(day).startOf('day'),
          now_.clone().add(day).endOf('day'),
        ]
        break
      }
      case 'week': {
        const weekType = ctx.localizator.mondayFirst ? 'isoWeek' : 'week'
        const week = moment.duration({ days: 7 })

        period = [
          now_.clone().startOf(weekType).subtract(week),
          now_.clone().endOf(weekType).add(week),
        ]
        break
      }
    }

    navController.$viewPort.addClass('loading')
    navController.loading = true

    chart._fetchPeriod(period).done((jobs, _textStatus, jqXHR) => {
      chart.jobs().reset(Array.from(jobs))

      const [start, finish] = period
      chart.set({ start, finish })

      store.dispatch({ type: 'SET_SCALE', scale })

      setTimeout(() => {
        const offset = chart.viewOffset(
          getMoment({ dateTime: chart.get('currentTime'), ctx })
        )
        const sizeOfTwoHour = navController.navigator.hoursToOffset(2)
        const sizeOfDay = navController.navigator.hoursToOffset(24)

        let left
        switch (scale) {
          case 'day': {
            left = offset - sizeOfTwoHour
            break
          }
          case 'week': {
            left = offset - sizeOfDay
            break
          }
        }

        navController.viewPort.scrollLeft = left
        navController.loading = false
        navController.$viewPort.removeClass('loading')
      }, 100)
    })
  }
}

function Subscriptions({ channel, children }) {
  subscribe(channel)

  return children
}

export class Schedule extends Component {
  constructor(props) {
    super(props)

    const { store, openJob, addJob } = props
    const { jobs, bars, editable } = store.getState()

    const { ctx } = store.getState()
    this.ctx = ctx

    Repositories.jobs.init(jobs, ctx)
    Repositories.bars.init(bars, ctx)

    Repositories.jobs.belongsTo('bar', Repositories.bars)
    Repositories.bars.hasMany('jobs', Repositories.jobs, {
      collection: BarJobs,
    })

    const viewController = new ViewController(
      jQuery(window),
      jQuery(document),
      jQuery('body')
    )

    const chart = new Chart({
      viewPortWidth: viewController.viewPortWidth,
      editable,
      store,
    })

    const sideBar = new SideBar({ chart: chart })

    store.dispatch({
      type: 'SET_BACKBONE',
      backbone: {
        chart: chart,
        sideBar: sideBar,
        viewController: viewController,
        navController: null,
      },
    })
    store.dispatch({ type: 'SET_HANDLERS', handlers: { openJob, addJob } })

    jQuery(document.body).on('click', (evt) => {
      var $target = jQuery(evt.target)

      if ($target.parents('.ctx-menu').length > 0) {
        return
      }

      chart.resetMenuId()
    })

    jQuery(window).on('resize', () => {
      chart.set('viewPortWidth', viewController.sizes.viewPortWidth)
      viewController.updateSizes(jQuery('#schedule'))
    })
  }

  render = () => (
    <Subscriptions channel={this.props.channel}>
      <Provider store={this.props.store}>
        <SessionContext.Provider value={this.ctx}>
          <WrapperLink />
        </SessionContext.Provider>
      </Provider>
    </Subscriptions>
  )

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    if (prevProps.scale != this.props.scale) {
      setScale(this.props.scale, this.props.store)
    }
  }
}
