

import Jobs_search from "planado/components/jobs/index/jobs_search";

var make = Jobs_search;

export {
  make ,
}
/* make Not a pure module */
