

import * as Fun from "../../libraries/Fun.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../common/Optional.mjs";
import * as Js_promise from "rescript/lib/es6/js_promise.js";
import * as Types_Spatial from "../../types/Types_Spatial.mjs";
import * as AddressInput_Types from "./AddressInput_Types.mjs";
import * as AddressInput_Request from "./AddressInput_Request.mjs";
import * as StringGeolocationParser from "../../types/StringGeolocationParser.mjs";
import * as AddressInput_ResetButton from "./reset_button/AddressInput_ResetButton.mjs";
import * as AddressInput_LocationIndex from "./AddressInput_LocationIndex.mjs";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";
import * as AddressInputModuleCss from "/home/runner/work/planado/planado/client/rescript/components/address_input/AddressInput.module.css";

var locationNotResolved$p = Utils.Translations.t("js.jobs.comments.location_not_resolved");

var addressLabel$p = Utils.Translations.t("js.address_input.address");

var constraintLabel$p = Utils.Translations.t("js.settings.labels.lookup_restrictions");

var constraintHint$p = Utils.Translations.t("js.settings.hints.lookup_restrictions");

var styles = AddressInputModuleCss;

function inputLongerThanMinimum(input) {
  return input.length >= 3;
}

function needToDisplayOrRequest(query) {
  if (StringGeolocationParser.isGeolocationString(query)) {
    return false;
  } else {
    return inputLongerThanMinimum(query);
  }
}

function coordsOrInvalidAddressGiven(queryAddress) {
  if (StringGeolocationParser.isGeolocationString(queryAddress.formatted)) {
    return inputLongerThanMinimum(queryAddress.formatted);
  } else {
    return false;
  }
}

function composeIconClassName(status) {
  return "fa fa-map-marker-alt pd-form-control-icon geolocation-" + status + "-icon";
}

function renderResetAndMarker(status, wire, constraints, onReset, readOnly) {
  var match;
  if (status !== undefined) {
    var tmp;
    tmp = status === "Failed" ? React.createElement("span", {
            className: styles.pdMarginTop + "pd-form-control-comment pd-form-control-comment-right geolocation-failed-text"
          }, locationNotResolved$p(wire.ctx)) : (
        status === "Resolved" && constraints !== undefined ? React.createElement("span", {
                className: "pd-form-control-comment"
              }, constraints.formatted) : null
      );
    match = [
      composeIconClassName(AddressInput_Types.ResolveStatus.toString(status)),
      tmp
    ];
  } else {
    match = [
      "",
      null
    ];
  }
  return React.createElement(React.Fragment, undefined, React.createElement(AddressInput_ResetButton.ResetButton.make, {
                  onReset: onReset,
                  inputType: "Regular",
                  disabled: readOnly
                }), React.createElement("span", {
                  "aria-hidden": true,
                  className: match[0]
                }), match[1]);
}

function AddressInput_Common$ResetAndMarkerIcons(Props) {
  var queryAddress = Props.queryAddress;
  var onReset = Props.onReset;
  var status = Props.status;
  var wire = Props.wire;
  var constraints = Props.constraints;
  var readOnly = Props.readOnly;
  if (queryAddress !== undefined) {
    return React.createElement(React.Fragment, undefined, React.createElement(Optional.make, {
                    show: coordsOrInvalidAddressGiven(queryAddress),
                    children: null
                  }, React.createElement(AddressInput_ResetButton.ResetButton.make, {
                        onReset: onReset,
                        inputType: "Regular",
                        disabled: readOnly
                      }), React.createElement("span", {
                        "aria-hidden": true,
                        className: composeIconClassName(RCore.$$Option.isSome(Types_Spatial.$$Geolocation.fromString(queryAddress.formatted)) ? "resolved" : "failed")
                      })), React.createElement(Optional.make, {
                    show: needToDisplayOrRequest(queryAddress.formatted),
                    children: renderResetAndMarker(status, wire, constraints, onReset, readOnly)
                  }));
  } else {
    return null;
  }
}

function requestSuggestions(fromBound, toBound, baseAddressOpt, suggestionsAmount, token, query, onComplete, param) {
  var baseAddress = baseAddressOpt !== undefined ? baseAddressOpt : AddressInput_Types.AddressConstraintParts.empty;
  Js_promise.then_((function (json) {
          var response = Json_Decode$JsonCombinators.decode(json, AddressInput_Types.$$Response.decode);
          if (response.TAG === "Ok") {
            onComplete(response._0);
          }
          return Promise.resolve();
        }), AddressInput_Request.postAddressRequest(query, suggestionsAmount, token, baseAddress, fromBound, toBound, undefined));
}

function findFirstNonEmptyLocation(suggestions) {
  var responseItem = RCore.$$Array.getBy(suggestions, (function (item) {
          if (item.data.geoLat !== undefined) {
            return item.data.geoLon !== undefined;
          } else {
            return false;
          }
        }));
  if (responseItem === undefined) {
    return ;
  }
  var match = Fun.both(responseItem.data.geoLat, responseItem.data.geoLon);
  if (match === undefined) {
    return ;
  }
  var match$1 = Fun.both(Types_Spatial.Latitude.fromString(match[0]), Types_Spatial.Longitude.fromString(match[1]));
  if (match$1 !== undefined) {
    return Types_Spatial.$$Geolocation.make(match$1[0], match$1[1]);
  }
  
}

function AddressInput_Common$LocationCoordinates(Props) {
  var wire = Props.wire;
  var mapType = Props.mapType;
  var mapApiKey = Props.mapApiKey;
  var readOnly = Props.readOnly;
  var closestPoint = Props.closestPoint;
  var queryChangedManually = Props.queryChangedManually;
  var address = Props.address;
  var queryAddress = Props.queryAddress;
  var saveLocation = Props.saveLocation;
  var response = Props.response;
  var resolveClosestPoint = function (closestPoint) {
    return RCore.$$Option.getOr(closestPoint, wire.ctx.localizator.approximateGeolocation);
  };
  var geolocation = address.geolocation;
  var match;
  if (geolocation !== undefined) {
    match = StringGeolocationParser.isGeolocationString(address.formatted) ? [
        true,
        geolocation
      ] : [
        !queryChangedManually,
        geolocation
      ];
  } else {
    var tmp;
    if (response !== undefined && response.length > 0 && inputLongerThanMinimum(address.formatted)) {
      var coordinates = findFirstNonEmptyLocation(response);
      tmp = coordinates !== undefined ? coordinates : resolveClosestPoint(closestPoint);
    } else {
      tmp = resolveClosestPoint(closestPoint);
    }
    match = [
      false,
      tmp
    ];
  }
  if (queryAddress !== undefined) {
    return React.createElement(Optional.make, {
                show: inputLongerThanMinimum(queryAddress.formatted),
                children: React.createElement(AddressInput_LocationIndex.make, {
                      isResolved: match[0],
                      formatted: address.formatted,
                      geolocation: match[1],
                      saveLocation: saveLocation,
                      readOnly: readOnly,
                      mapType: mapType,
                      mapApiKey: mapApiKey,
                      wire: wire
                    })
              });
  } else {
    return null;
  }
}

var Translations = {
  addressLabel$p: addressLabel$p,
  constraintLabel$p: constraintLabel$p,
  constraintHint$p: constraintHint$p
};

var ResetAndMarkerIcons = {
  make: AddressInput_Common$ResetAndMarkerIcons
};

var LocationCoordinates = {
  make: AddressInput_Common$LocationCoordinates
};

export {
  Translations ,
  styles ,
  needToDisplayOrRequest ,
  ResetAndMarkerIcons ,
  requestSuggestions ,
  LocationCoordinates ,
}
/* locationNotResolved' Not a pure module */
