const REPLACEMENTS = {
  a: 'ddd',
  A: 'dddd',
  b: 'MMM',
  B: 'MMMM',
  d: 'DD',
  e: 'D',
  F: 'YYYY-MM-DD',
  H: 'HH',
  I: 'hh',
  j: 'DDDD',
  m: 'MM',
  M: 'mm',
  p: 'A',
  S: 'ss',
  Z: 'z',
  ':z': 'Z',
  w: 'd',
  y: 'YY',
  Y: 'YYYY',
  '%': '%'
}

export const toMomentJSFormat = unixFormat => {
  let momentFormat = unixFormat
  for (let key in REPLACEMENTS) {
    momentFormat = momentFormat.replace(`%${key}`, REPLACEMENTS[key])
  }

  return momentFormat
}

const removeYearFromDateFormat = format =>
  format.replace(/.%Y$/i, '').replace(/^%Y./i, '')

const transformFormat = (source, formats) => {
  let format
  switch (source) {
    case 'date':
    case null:
      format = formats.date
      break
    case 'day':
      format = '%e'
      break
    case 'date_full':
      format = '%e %B %Y'
      break
    case 'date_full_month_abbr':
      format = '%e %b %Y'
      break
    case 'day_and_month_abbr':
      format = '%e %b'
      break
    case 'month_abbr_and_day':
      format = '%b %e'
      break
    case 'date_full_no_day':
      format = '%B, %Y'
      break
    case 'date_full_no_year':
      format = '%e %b'
      break
    case 'date_with_day_of_week':
      format = `%a, ${formats.date}`
      break
    case 'date_with_month_abbr_and_day_of_week':
      format = '%e %B, %a'
      break
    case 'week_day_with_day_with_month':
      format = '%a, %d %B'
      break
    case 'week_day_with_day_with_month_with_year':
      format = '%a, %d %B %Y'
      break
    case 'date_time_week':
      format = `%a, ${removeYearFromDateFormat(formats.date)} ${formats.time}`
      break
    case 'date_week':
      format = '%a, %d.%m'
      break
    case 'datetime_no_year':
      format = `%e %b ${formats.time}`
      break
    case 'time':
      format = formats.time
      break
    case 'time_seconds':
      format = '%H:mm:ss'
      break
    case 'datetime':
      format = formats.datetime
      break
    case 'date_short':
      format = formats.dateShort
      break
    case 'date_no_year':
      format = removeYearFromDateFormat(formats.date)
      break
    case 'month_with_full_year':
      format = 'MMMM %Y'
      break
    case 'month':
      format = 'MMMM'
      break
    default:
      format = source
  }

  return toMomentJSFormat(format)
}

export const presentFormatWithLocalizator = (sourceFormat, localizator) => {
  const {
    datetime: { formats }
  } = localizator

  return transformFormat(sourceFormat, formats)
}

export const presentFormat = (sourceFormat, ctx) => {
  const {
    localizator: {
      datetime: { formats }
    }
  } = ctx

  return transformFormat(sourceFormat, formats)
}
