

import Duration_spinner from "planado/components/input/duration_spinner";

var make = Duration_spinner;

export {
  make ,
}
/* make Not a pure module */
