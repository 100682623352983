

import * as El from "../../../libraries/El.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../../../components/common/Optional.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pages_Skills_API from "../Pages_Skills_API.mjs";
import * as Pages_Skills_Form_Item from "./Pages_Skills_Form_Item.mjs";
import * as Pages_Skills_SortableList from "../Pages_Skills_SortableList.mjs";
import * as Pages_Skills_RemoveWarning from "../remove_warning/Pages_Skills_RemoveWarning.mjs";
import * as Pages_Skills_FormModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/skills/form/Pages_Skills_Form.module.css";

var styles = Pages_Skills_FormModuleCss;

var title$p = Utils.Translations.tr("js.admin.skills.title");

var add$p = Utils.Translations.tr("js.admin.skills.add");

var noSkills$p = Utils.Translations.tr("js.admin.skills.no_skills");

var save$p = Utils.Translations.tr("common.save");

var duplicateName$p = Utils.Translations.t("js.admin.skills.errors.duplicate_name");

var blank$p = Utils.Translations.t("js.admin.skills.errors.blank");

var whenSkillsExist$p = Utils.Translations.tr("js.admin.skills.description.when_skills_exist");

var whenSkillsNotExist$p = Utils.Translations.tr("js.admin.skills.description.when_skills_not_exist");

function Pages_Skills_Form(Props) {
  var initialSkills = Props.initialSkills;
  var onSubmit = Props.onSubmit;
  var locked = Props.locked;
  var lock = Props.lock;
  var unlock = Props.unlock;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return initialSkills;
      });
  var setSkills = match[1];
  var skills = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setUsedBy = match$1[1];
  var usedBy = match$1[0];
  var noVisibleSkills = skills.every(function (skill) {
        return !skill.visible;
      });
  var updateOrderNo = function (skills) {
    return skills.map(function (skill, i) {
                return {
                        uuid: skill.uuid,
                        name: skill.name,
                        visible: skill.visible,
                        orderNo: i,
                        errors: skill.errors,
                        isNew: skill.isNew
                      };
              });
  };
  var validateSkill = function (newName, checkEmptyOpt, skill, skills) {
    var checkEmpty = checkEmptyOpt !== undefined ? checkEmptyOpt : false;
    var name = newName !== undefined ? newName : skill.name;
    var isNameEmpty = name === "";
    var isNameUsed = skills.some(function (s) {
          if (s.visible && Caml_obj.notequal(s.uuid, skill.uuid)) {
            return s.name.trim() === name.trim();
          } else {
            return false;
          }
        });
    var errors = isNameEmpty && checkEmpty ? [blank$p(ctx)] : (
        isNameUsed && !isNameEmpty ? [duplicateName$p(ctx)] : []
      );
    return {
            uuid: skill.uuid,
            name: name,
            visible: skill.visible,
            orderNo: skill.orderNo,
            errors: errors,
            isNew: skill.isNew
          };
  };
  var validateSkills = function (skills) {
    return skills.map(function (skill) {
                return validateSkill(undefined, true, skill, skills);
              });
  };
  var hasErrors = function (skills) {
    return skills.some(function (skill) {
                if (skill.visible) {
                  return Caml_obj.notequal(skill.errors, []);
                } else {
                  return false;
                }
              });
  };
  var updateSkills = function (skills) {
    setSkills(function (param) {
          return updateOrderNo(skills);
        });
  };
  var addNewSkill = function ($$event) {
    $$event.preventDefault();
    var newSkill_uuid = Uuid.createV6();
    var newSkill_orderNo = skills.length;
    var newSkill_errors = [];
    var newSkill = {
      uuid: newSkill_uuid,
      name: "",
      visible: true,
      orderNo: newSkill_orderNo,
      errors: newSkill_errors,
      isNew: true
    };
    setSkills(function (skills) {
          return Belt_Array.concatMany([
                      skills,
                      [newSkill]
                    ]);
        });
  };
  var updateSkill = function (skill) {
    setSkills(function (skills) {
          return skills.map(function (s) {
                      if (Caml_obj.equal(s.uuid, skill.uuid)) {
                        return validateSkill(skill.name, undefined, skill, skills);
                      } else {
                        return s;
                      }
                    });
        });
  };
  var skillInUse = function (param) {
    return [
              param.users,
              param.teams,
              param.templates
            ].some(function (entity) {
                return Caml_obj.notequal(entity, []);
              });
  };
  var deleteSkillForce = function (skill) {
    var updatedSkills = RCore.$$Array.filterMap(skills, (function (s) {
            if (Caml_obj.equal(s.uuid, skill.uuid)) {
              if (skill.isNew) {
                return ;
              } else {
                return {
                        uuid: skill.uuid,
                        name: skill.name,
                        visible: false,
                        orderNo: skill.orderNo,
                        errors: skill.errors,
                        isNew: skill.isNew
                      };
              }
            } else {
              return s;
            }
          }));
    setSkills(function (param) {
          return updatedSkills;
        });
    setUsedBy(function (param) {
          
        });
  };
  var deleteSkill = function (skill) {
    lock();
    Backend.$$finally(Pages_Skills_API.UsedBy.get(wire, skill.uuid), (function (entities) {
            unlock();
            if (entities.TAG !== "Ok") {
              return ;
            }
            var entities$1 = entities._0;
            if (skillInUse(entities$1)) {
              return setUsedBy(function (param) {
                          return [
                                  skill,
                                  entities$1
                                ];
                        });
            } else {
              return deleteSkillForce(skill);
            }
          }));
  };
  var submit = function () {
    var skills$1 = updateOrderNo(validateSkills(skills));
    setSkills(function (param) {
          return skills$1;
        });
    if (!hasErrors(skills$1)) {
      return onSubmit(skills$1);
    }
    
  };
  React.useEffect((function () {
          setSkills(function (param) {
                return initialSkills;
              });
        }), [initialSkills]);
  var tmp;
  if (usedBy !== undefined) {
    var onClose = function () {
      setUsedBy(function (param) {
            
          });
    };
    tmp = React.createElement(Pages_Skills_RemoveWarning.make, {
          skill: usedBy[0],
          usedBy: usedBy[1],
          onClose: onClose,
          onAccept: deleteSkillForce
        });
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              id: "content-wrapper"
            }, React.createElement("div", {
                  className: "page-container narrow"
                }, React.createElement("form", {
                      className: styles.form
                    }, React.createElement("header", {
                          className: styles.formHeader
                        }, React.createElement("div", undefined, title$p(ctx))), React.createElement("div", {
                          className: styles.formBody
                        }, noVisibleSkills ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                                    className: "note note-info " + styles.description
                                  }, React.createElement("p", undefined, whenSkillsNotExist$p(ctx))), React.createElement("div", {
                                    className: styles.noSkillsContainer
                                  }, React.createElement("span", {
                                        className: El.Cn.concat(Icon.style(undefined, undefined, "tags"), styles.tags)
                                      }), React.createElement("p", {
                                        className: styles.noSkills
                                      }, noSkills$p(ctx)))) : React.createElement(React.Fragment, undefined, React.createElement("div", {
                                    className: "note note-info " + styles.description
                                  }, React.createElement("p", undefined, whenSkillsExist$p(ctx))), React.createElement("div", {
                                    className: styles.skillsList
                                  }, React.createElement(Pages_Skills_SortableList.make, {
                                        items: skills,
                                        onDragEnd: updateSkills,
                                        children: skills.map(function (skill) {
                                              return React.createElement(Pages_Skills_Form_Item.make, {
                                                          skill: skill,
                                                          updateSkill: updateSkill,
                                                          deleteSkill: deleteSkill,
                                                          locked: locked,
                                                          key: Uuid.toString(skill.uuid)
                                                        });
                                            })
                                      }))), React.createElement("button", {
                              className: noVisibleSkills ? styles.addButton : styles.addButtonLeft,
                              onClick: addNewSkill
                            }, React.createElement("span", {
                                  className: Icon.style(undefined, undefined, "plus")
                                }), add$p(ctx))), React.createElement(Optional.make, {
                          show: !(Caml_obj.equal(initialSkills, []) && noVisibleSkills),
                          children: React.createElement("footer", {
                                className: styles.formFooter
                              }, React.createElement("button", {
                                    className: "btn btn-primary",
                                    disabled: locked || hasErrors(skills),
                                    onClick: (function ($$event) {
                                        $$event.preventDefault();
                                        submit();
                                      })
                                  }, save$p(ctx)))
                        }), tmp)));
}

var make = Pages_Skills_Form;

export {
  make ,
}
/* styles Not a pure module */
