

import * as Uuid from "../bindings/uuid/Uuid.mjs";
import * as JsonDecode from "./JsonDecode.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              jobUuid: field.required("jobUuid", Uuid.decode),
              type_: field.required("type", JsonDecode.string)
            };
    });

var JobEvent = {
  decode: decode
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              workerUuid: field.required("workerUuid", Uuid.decode),
              effectiveAt: field.required("effectiveAt", JsonDecode.string),
              accuracy: field.required("accuracy", JsonDecode.$$float),
              longitude: field.required("longitude", JsonDecode.$$float),
              latitude: field.required("latitude", JsonDecode.$$float),
              jobUuids: field.required("jobUuids", JsonDecode.array(Uuid.decode)),
              distancesToDestination: field.required("distancesToDestination", JsonDecode.array(JsonDecode.option(JsonDecode.$$float))),
              events: field.required("events", JsonDecode.array(decode)),
              batteryLevel: field.required("batteryLevel", JsonDecode.$$float)
            };
    });

var $$Location = {
  JobEvent: JobEvent,
  decode: decode$1
};

var decode$2 = JsonDecode.object(function (field) {
      return {
              locations: field.required("locations", JsonDecode.array(decode$1))
            };
    });

var LocationUpdate = {
  decode: decode$2
};

var decode$3 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode)
            };
    });

var JobRemoved = {
  decode: decode$3
};

var Message = {
  $$Location: $$Location,
  LocationUpdate: LocationUpdate,
  JobRemoved: JobRemoved
};

var Channel = {
  Message: Message
};

var decode$4 = JsonDecode.object(function (field) {
      return {
              location: field.required("location", JsonDecode.option(JsonDecode.custom(function (json) {
                            return json;
                          }))),
              job: field.required("job", JsonDecode.custom(function (json) {
                        return json;
                      }))
            };
    });

var JobUpdate = {
  decode: decode$4
};

var MapEvent = {
  JobUpdate: JobUpdate
};

var ScheduleEvent = {};

export {
  Channel ,
  MapEvent ,
  ScheduleEvent ,
}
/* decode Not a pure module */
