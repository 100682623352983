import React from 'react'
import classNames from 'classnames'

import {
  getAccuracy,
  footToMileRatio,
  meterToMileRatio
} from 'planado/utils/map'
import {
  addLocation,
  removeLocation,
  highlightLocation,
  removeLocationHighlight
} from 'planado/map_commands'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import * as styles from './styles.module.css'
import { showTime } from 'planado/utils/time/index.js'

const formatAccuracy = ({ precision }, ctx) => {
  if (ctx.localizator.measurementSystem === 'imperial') {
    const precisionMiles = precision * meterToMileRatio

    if (precisionMiles < 1) {
      return ctx.t(
        'js.map.locations.feet',
        { feet: Math.round(precisionMiles / footToMileRatio) }
      )
    } else {
      return ctx.t('js.map.locations.miles', { miles: Math.round(precisionMiles * 100) / 100 })
    }
  } else {
    if (precision < 5000) {
      return ctx.t('js.map.locations.meters', { meters: Math.round(precision) })
    } else {
      return ctx.t('js.map.locations.meters', { meters: '> 5000' })
    }
  }
}

const formatDistance = (distance, ctx) => {
  if (distance === null) {
    return '—'
  } else if (ctx.localizator.measurementSystem === 'imperial') {
    const miles = distance * meterToMileRatio

    if (miles < 1) {
      const rounded = Math.ceil(miles / footToMileRatio / 10) * 10

      return ctx.t('js.map.locations.feet', { feet: rounded })
    } else {
      const rounded = Math.ceil(miles * 10) / 10

      return ctx.t('js.map.locations.miles', { miles: rounded })
    }
  } else {
    const meters = Math.round(distance)

    if (meters < 100) {
      return ctx.t('js.map.locations.meters', { meters: meters })
    } else if (meters < 1000) {
      const rounded = Math.ceil(meters / 50) * 50

      return ctx.t('js.map.locations.meters', { meters: rounded })
    } else if (meters < 10000) {
      const kms = Math.ceil(meters / 100) / 10

      return ctx.t('js.map.locations.kilometers', {
        kms: ctx.toNumber(kms)
      })
    } else {
      const kms = Math.ceil(meters / 1000)

      return ctx.t('js.map.locations.kilometers', { kms: kms })
    }
  }
}

const JobStatusChange = withContext(({ jobUuid, events, ctx }) => {
  const event = events.filter(e => e.jobUuid === jobUuid)[0] || null

  if (event === null) {
    return <span />
  } else {
    return (
      <span className={styles.statusChange}>
        {ctx.t('js.map.locations.status_changes.' + event.type)}
      </span>
    )
  }
})

class LocationItem extends React.Component {
  constructor(props) {
    super(props)

    this.onClick = evt => {
      evt.stopPropagation()
      props.onClick(this.props.location)
      this.engineChannel.push(
        highlightLocation(
          this.props.location,
          this.props.ctx.localizator.measurementSystem,
          this.item
        )
      )
    }
  }

  get distance() {
    const { location, showDistance, ctx } = this.props

    if (showDistance) {
      return (
        <span className={styles.distance}>
          {formatDistance(location.distance, ctx)}
        </span>
      )
    } else {
      return ''
    }
  }

  get engineChannel() {
    return this.props.engineChannel
  }

  render() {
    const { ctx, active, location } = this.props

    const itemClass = classNames({
      [styles.locationPoint]: true,
      [styles.active]: active
    })

    const precisionClasses = classNames({
      [styles.precisionIndicator]: true,
      [styles[getAccuracy(location, ctx.localizator.measurementSystem)]]: true
    })

    return (
      <li
        className={itemClass}
        onClick={this.onClick}
        ref={r => (this.item = r)}
      >
        <span className={styles.time}>
          {showTime(location.effectiveAt, 'time', ctx)}
        </span>
        &nbsp;
        <JobStatusChange {...location} />
        <span className={styles.locationTail}>
          {this.distance}
          &nbsp;
          <span className={styles.accuracy}>{formatAccuracy(location, ctx)}</span>
          <span className={precisionClasses}>
            <i className="fa fa-circle" />
          </span>
        </span>
      </li>
    )
  }

  // Engine interaction

  componentDidUpdate(prevProps) {
    if (!this.props.active && prevProps.active) {
      this.engineChannel.push(removeLocationHighlight(this.props.location))
    } else if (this.props.active && !prevProps.active) {
      this.engineChannel.push(
        highlightLocation(
          this.props.location,
          this.props.ctx.localizator.measurementSystem,
          this.item
        )
      )
    }
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.location.events.length !== nextProps.location.events.length ||
      this.props.active !== nextProps.active ||
      this.props.ctx != nextProps.ctx
    )
  }

  componentDidMount() {
    if (this.props.active) {
      this.engineChannel.push(
        highlightLocation(
          this.props.location,
          this.props.ctx.localizator.measurementSystem,
          this.item
        )
      )
    }
    this.engineChannel.push(addLocation(this.props.location))
  }

  componentWillUnmount() {
    if (this.props.active) {
      this.engineChannel.push(removeLocationHighlight(this.props.location))
    }
    this.engineChannel.push(removeLocation(this.props.location))
  }
}

export default withContext(LocationItem)
