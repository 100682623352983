

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as EncodeJson from "../../../libraries/EncodeJson.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as LocalStorage from "../../../bindings/localStorage/LocalStorage.mjs";
import * as Shared_Lib_IO from "../../../shared/lib/Shared_Lib_IO.mjs";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";

function fromString(version) {
  if (version === "v1") {
    return "v1";
  }
  
}

var decode = JsonDecode.fromVariant(JsonDecode.string, fromString);

var decode$1 = JsonDecode.object(function (field) {
      return {
              useBoundingBox: field.required("useBoundingBox", JsonDecode.bool)
            };
    });

var decode$2 = JsonDecode.object(function (field) {
      field.required("version", decode);
      return field.required("data", decode$1);
    });

function encodeData(useBoundingBox) {
  return EncodeJson.object([[
                "useBoundingBox",
                EncodeJson.bool(useBoundingBox)
              ]]);
}

function encode(useBoundingBox) {
  return EncodeJson.object([
              [
                "version",
                EncodeJson.string("v1")
              ],
              [
                "data",
                encodeData(useBoundingBox)
              ]
            ]);
}

function storageKey(uuid) {
  return Uuid.toString(uuid) + ".map";
}

function get(userUuid) {
  return Shared_Lib_IO.map(LocalStorage.getItem(storageKey(userUuid)), (function (json) {
                if (json === undefined) {
                  return ;
                }
                var e = Json_Decode$JsonCombinators.decode(json, decode$2);
                if (e.TAG === "Ok") {
                  return e._0;
                }
                console.log("Error decoding map storage data: " + e._0);
              }));
}

function set(userUuid, useBoundingBox) {
  return LocalStorage.setItem(storageKey(userUuid), encode(useBoundingBox));
}

var Types = {};

export {
  Types ,
  get ,
  set ,
}
/* decode Not a pure module */
