

import * as Uuid from "../bindings/uuid/Uuid.mjs";
import * as RCore from "../libraries/RCore.mjs";
import * as Types from "../types/Types.mjs";
import * as JsonDecode from "../libraries/JsonDecode.mjs";
import * as LocaleBase from "../libraries/LocaleBase.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Assignee_Types from "../types/Assignee_Types.mjs";
import * as MultipleAssignee from "../types/MultipleAssignee.mjs";
import * as Types_FeatureFlag from "../types/Types_FeatureFlag.mjs";
import * as Context_Localizator from "./Context_Localizator.mjs";

function codeFromVariantsById(id, variants) {
  if (id === undefined) {
    return ;
  }
  var tzId = Caml_option.valFromOption(id);
  var variant = RCore.$$Array.getBy(variants, (function (tz) {
          return Types.Id.equal(tz.id, tzId);
        }));
  if (variant !== undefined) {
    return variant.code;
  }
  
}

function idFromVariantsByCode(code, variants) {
  if (code === undefined) {
    return ;
  }
  var variant = RCore.$$Array.getBy(variants, (function (tz) {
          return tz.code === code;
        }));
  if (variant !== undefined) {
    return Caml_option.some(variant.id);
  }
  
}

var decode = JsonDecode.object(function (field) {
      return {
              id: field.required("id", Types.Id.decode),
              code: field.required("code", JsonDecode.string),
              name: field.required("name", JsonDecode.string)
            };
    });

var TimeZone = {
  codeFromVariantsById: codeFromVariantsById,
  idFromVariantsByCode: idFromVariantsByCode,
  decode: decode
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              support: field.required("support", JsonDecode.string),
              sales: field.required("sales", JsonDecode.string),
              phone: field.required("phone", JsonDecode.string)
            };
    });

var decode$2 = JsonDecode.object(function (field) {
      return {
              license: field.required("license", JsonDecode.string),
              privacyPolicy: field.required("privacyPolicy", JsonDecode.string)
            };
    });

var decode$3 = JsonDecode.object(function (field) {
      return {
              version: field.required("version", JsonDecode.$$int),
              domain: field.required("domain", JsonDecode.string),
              brandName: field.required("brandName", JsonDecode.option(JsonDecode.string)),
              contacts: field.required("contacts", JsonDecode.option(decode$1)),
              logo: field.required("logo", JsonDecode.option(JsonDecode.string)),
              urls: field.required("urls", JsonDecode.option(decode$2))
            };
    });

var Branding = {
  decode: decode$3
};

function uuid(param) {
  return param.uuid;
}

function name(param) {
  return param.name;
}

var decode$4 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var Template = {
  uuid: uuid,
  name: name,
  decode: decode$4
};

function decodeDuration(localizator, bound) {
  return function (s) {
    if (bound === "Finish" && s === "00:00") {
      return LocaleBase.T.durationFormatWithLocalizator("Time", localizator, "24:00");
    } else {
      return LocaleBase.T.durationFormatWithLocalizator("Time", localizator, s);
    }
  };
}

function decode$5(localizator) {
  return JsonDecode.object(function (field) {
              return {
                      start: field.required("start", JsonDecode.map(JsonDecode.string, decodeDuration(localizator, "Start"))),
                      finish: field.required("finish", JsonDecode.map(JsonDecode.string, decodeDuration(localizator, "Finish")))
                    };
            });
}

function fromStringOpt(permission) {
  switch (permission) {
    case "delete" :
        return "ViewEditDelete";
    case "edit" :
        return "ViewEdit";
    case "restricted" :
        return "Restricted";
    case "view" :
        return "View";
    default:
      return ;
  }
}

function toString(permission) {
  if (typeof permission === "object") {
    return permission.VAL;
  } else if (permission === "ViewEditDelete") {
    return "delete";
  } else if (permission === "Restricted") {
    return "restricted";
  } else if (permission === "View") {
    return "view";
  } else {
    return "edit";
  }
}

var decode$6 = JsonDecode.fromVariant(JsonDecode.string, fromStringOpt);

function fromStringOpt$1(viewEditPermission) {
  switch (viewEditPermission) {
    case "edit" :
        return "ViewEdit";
    case "restricted" :
        return "Restricted";
    case "view" :
        return "View";
    default:
      return ;
  }
}

function toString$1(viewEditPermission) {
  if (typeof viewEditPermission === "object") {
    return viewEditPermission.VAL;
  } else if (viewEditPermission === "Restricted") {
    return "restricted";
  } else if (viewEditPermission === "View") {
    return "view";
  } else {
    return "edit";
  }
}

var decode$7 = JsonDecode.fromVariant(JsonDecode.string, fromStringOpt$1);

function fromStringOpt$2(boolPermission) {
  switch (boolPermission) {
    case "false" :
        return "Restricted";
    case "true" :
        return "View";
    default:
      return ;
  }
}

function toString$2(boolPermission) {
  if (typeof boolPermission === "object") {
    return boolPermission.VAL;
  } else if (boolPermission === "View") {
    return "true";
  } else {
    return "false";
  }
}

var decode$8 = JsonDecode.fromVariant(JsonDecode.string, fromStringOpt$2);

function hasRightsToEdit(param) {
  var jobs = param.jobs;
  if (jobs === "ViewEdit") {
    return true;
  } else {
    return jobs === "ViewEditDelete";
  }
}

var decode$9 = JsonDecode.object(function (field) {
      return {
              schedule: field.required("schedule", decode$7),
              jobs: field.required("jobs", decode$6),
              clients: field.required("clients", decode$7),
              map: field.required("map", decode$8),
              teams: field.required("teams", decode$7),
              recurringJobs: field.required("recurringJobs", decode$8),
              admin: field.required("admin", decode$8),
              shifts: field.required("shifts", decode$8),
              jobsExport: field.required("jobsExport", decode$8)
            };
    });

var $$Permissions = {
  hasRightsToEdit: hasRightsToEdit,
  decode: decode$9
};

var decode$10 = JsonDecode.object(function (field) {
      return {
              id: field.required("id", Types.Id.decodeOpaque),
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              email: field.required("email", JsonDecode.string)
            };
    });

function hasFlag(flag, param) {
  return param.flags.some(function (f) {
              return f === flag;
            });
}

function hasAnyFlag(fs, param) {
  var flags = param.flags;
  return fs.some(function (flag) {
              return flags.some(function (f) {
                          return f === flag;
                        });
            });
}

var decode$11 = JsonDecode.object(function (field) {
      return {
              flags: RCore.$$Array.filterMap(field.required("flags", JsonDecode.array(JsonDecode.string)), Types_FeatureFlag.fromString)
            };
    });

var Features = {
  hasFlag: hasFlag,
  hasAnyFlag: hasAnyFlag,
  decode: decode$11
};

function hasTag(tags, tag) {
  return tags.some(function (t) {
              return t === tag;
            });
}

var decode$12 = JsonDecode.array(JsonDecode.string);

var Tags = {
  hasTag: hasTag,
  decode: decode$12
};

var decode$13 = JsonDecode.object(function (field) {
      return {
              expired: field.required("expired", JsonDecode.bool),
              expiresIn: field.required("expiresIn", JsonDecode.option(JsonDecode.$$int)),
              expiresAt: field.required("expiresAt", JsonDecode.option(JsonDecode.string))
            };
    });

var decode$14 = JsonDecode.object(function (field) {
      return {
              target: field.required("target", JsonDecode.$$int),
              current: field.required("current", JsonDecode.$$int)
            };
    });

function code(step) {
  switch (step.TAG) {
    case "AddClient" :
        return "add_client";
    case "AddCustomResolution" :
        return "add_custom_resolution";
    case "AddFieldWOrkers" :
        return "add_field_workers";
    case "AddGeojsonToTerritory" :
        return "add_geojson_to_territory";
    case "AddJob" :
        return "add_job";
    case "AddOrEditTemplate" :
        return "add_or_edit_template";
    case "AddRecurringJob" :
        return "add_recurring_job";
    case "AddSkill" :
        return "add_skill";
    case "AddTeam" :
        return "add_team";
    case "AddTerritory" :
        return "add_territory";
    case "AddTrackingPageToSMSTemplate" :
        return "add_tracking_page_to_sms_template";
    case "ChooseAssignee" :
        return "choose_assignee";
    case "ChooseTeamAsAssignee" :
        return "choose_team_as_assignee";
    case "ConfigureCustomJobView" :
        return "configure_custom_job_view";
    case "ConnectCRM" :
        return "connect_crm";
    case "FinishJob" :
        return "finish_job";
    case "SetSkillsInTemplates" :
        return "set_skills_in_templates";
    case "SetTerritoriesForWorkers" :
        return "set_territories_for_workers";
    case "SetUpShiftsForWorkers" :
        return "set_up_shifts_for_workers";
    case "SetUpShiftsForTeams" :
        return "set_up_shifts_for_teams";
    case "SetUpSMSTemplate" :
        return "set_up_sms_template";
    case "UploadClients" :
        return "upload_clients";
    
  }
}

function complete(step) {
  return step._0 === "complete";
}

var decode$15 = JsonDecode.object(function (field) {
      var complete = field.required("complete", JsonDecode.bool);
      var intProgress = function () {
        if (complete) {
          return "complete";
        } else {
          return {
                  NAME: "incomplete",
                  VAL: field.required("progress", decode$14)
                };
        }
      };
      var unknown = field.required("code", JsonDecode.string);
      switch (unknown) {
        case "add_client" :
            return {
                    TAG: "AddClient",
                    _0: intProgress()
                  };
        case "add_custom_resolution" :
            return {
                    TAG: "AddCustomResolution",
                    _0: intProgress()
                  };
        case "add_field_workers" :
            return {
                    TAG: "AddFieldWOrkers",
                    _0: intProgress()
                  };
        case "add_geojson_to_territory" :
            return {
                    TAG: "AddGeojsonToTerritory",
                    _0: intProgress()
                  };
        case "add_job" :
            return {
                    TAG: "AddJob",
                    _0: intProgress()
                  };
        case "add_or_edit_template" :
            return {
                    TAG: "AddOrEditTemplate",
                    _0: intProgress()
                  };
        case "add_recurring_job" :
            return {
                    TAG: "AddRecurringJob",
                    _0: intProgress()
                  };
        case "add_skill" :
            return {
                    TAG: "AddSkill",
                    _0: intProgress()
                  };
        case "add_team" :
            return {
                    TAG: "AddTeam",
                    _0: intProgress()
                  };
        case "add_territory" :
            return {
                    TAG: "AddTerritory",
                    _0: intProgress()
                  };
        case "add_tracking_page_to_sms_template" :
            return {
                    TAG: "AddTrackingPageToSMSTemplate",
                    _0: intProgress()
                  };
        case "choose_assignee" :
            return {
                    TAG: "ChooseAssignee",
                    _0: intProgress()
                  };
        case "choose_team_as_assignee" :
            return {
                    TAG: "ChooseTeamAsAssignee",
                    _0: intProgress()
                  };
        case "configure_custom_job_view" :
            return {
                    TAG: "ConfigureCustomJobView",
                    _0: intProgress()
                  };
        case "connect_crm" :
            if (complete) {
              return {
                      TAG: "ConnectCRM",
                      _0: "complete"
                    };
            } else {
              return {
                      TAG: "ConnectCRM",
                      _0: {
                        NAME: "incomplete",
                        VAL: false
                      }
                    };
            }
        case "finish_job" :
            return {
                    TAG: "FinishJob",
                    _0: intProgress()
                  };
        case "set_skills_in_templates" :
            return {
                    TAG: "SetSkillsInTemplates",
                    _0: intProgress()
                  };
        case "set_territories_for_workers" :
            return {
                    TAG: "SetTerritoriesForWorkers",
                    _0: intProgress()
                  };
        case "set_up_shifts_for_teams" :
            return {
                    TAG: "SetUpShiftsForTeams",
                    _0: intProgress()
                  };
        case "set_up_shifts_for_workers" :
            return {
                    TAG: "SetUpShiftsForWorkers",
                    _0: intProgress()
                  };
        case "set_up_sms_template" :
            return {
                    TAG: "SetUpSMSTemplate",
                    _0: intProgress()
                  };
        case "upload_clients" :
            return {
                    TAG: "UploadClients",
                    _0: intProgress()
                  };
        default:
          return JsonDecode.cannotDecode("Unknown step code: " + unknown);
      }
    });

var Step = {
  code: code,
  complete: complete,
  decode: decode$15
};

var decode$16 = JsonDecode.object(function (field) {
      return {
              steps: field.required("steps", JsonDecode.array(decode$15))
            };
    });

function fromString(env) {
  switch (env) {
    case "development" :
        return "development";
    case "production" :
        return "production";
    case "test" :
        return "test";
    default:
      return ;
  }
}

var decode$17 = JsonDecode.fromVariant(JsonDecode.string, fromString);

function fromString$1(mapType) {
  switch (mapType) {
    case "google" :
        return "Google";
    case "yandex" :
        return "Yandex";
    default:
      return {
              NAME: "Unknown",
              VAL: mapType
            };
  }
}

function toString$3(mapType) {
  if (typeof mapType === "object") {
    return mapType.VAL;
  } else if (mapType === "Yandex") {
    return "yandex";
  } else {
    return "google";
  }
}

var decode$18 = JsonDecode.map(JsonDecode.string, fromString$1);

function fromString$2(source) {
  switch (source) {
    case "dadata" :
        return "Dadata";
    case "google" :
        return "Google";
    default:
      return {
              NAME: "Unknown",
              VAL: "Unknown address source"
            };
  }
}

function toString$4(source) {
  if (typeof source === "object") {
    return source.VAL;
  } else if (source === "Google") {
    return "google";
  } else {
    return "dadata";
  }
}

var decode$19 = JsonDecode.map(JsonDecode.string, fromString$2);

function fromString$3(s) {
  switch (s) {
    case "external" :
        return "external";
    case "internal" :
        return "internal";
    default:
      return ;
  }
}

var decode$20 = JsonDecode.fromVariant(JsonDecode.string, fromString$3);

var decode$21 = JsonDecode.object(function (field) {
      return {
              templateUuid: field.required("templateUuid", Uuid.decode),
              templateName: field.required("templateName", JsonDecode.string)
            };
    });

var decode$22 = JsonDecode.object(function (field) {
      return {
              intro: field.required("intro", decode$21)
            };
    });

var decode$23 = JsonDecode.object(function (field) {
      var localizator = field.required("localizator", Context_Localizator.decode);
      return {
              tenantUuid: field.required("tenantUuid", Uuid.decode),
              domain: field.required("domain", JsonDecode.string),
              subdomain: field.required("subdomain", JsonDecode.string),
              dadataToken: field.required("dadataToken", JsonDecode.string),
              yandexGeocoderApiKey: field.required("yandexGeocoderApiKey", JsonDecode.string),
              googleCaptchaSiteKey: field.required("googleCaptchaSiteKey", JsonDecode.string),
              currency: field.required("currency", JsonDecode.string),
              owner: field.required("owner", JsonDecode.bool),
              enabled: field.required("enabled", JsonDecode.bool),
              billing: field.required("billing", JsonDecode.bool),
              dueBillUuid: field.required("dueBillUuid", JsonDecode.option(Uuid.decode)),
              tags: field.required("tags", decode$12),
              trial: field.required("trial", JsonDecode.option(decode$13)),
              features: field.required("features", decode$11),
              localizator: localizator,
              user: field.required("user", JsonDecode.option(decode$10)),
              branding: field.required("branding", JsonDecode.option(decode$3)),
              isMaster: field.required("master", JsonDecode.bool),
              permissions: field.required("permissions", JsonDecode.option(decode$9)),
              env: field.required("env", decode$17),
              mapType: field.required("mapType", decode$18),
              mapApiKey: field.required("mapApiKey", JsonDecode.string),
              templates: field.required("templates", JsonDecode.array(decode$4)),
              businessHours: field.required("businessHours", JsonDecode.option(decode$5(localizator))),
              assignees: field.required("assignees", JsonDecode.array(Assignee_Types.Assignee.decode)),
              allAssignees: field.required("allAssignees", MultipleAssignee.PossibleAssignee.decode),
              availableSkills: field.required("availableSkills", JsonDecode.array(MultipleAssignee.Skill.decode)),
              licenseSource: field.required("licenseSource", decode$20),
              disposable: field.required("disposable", JsonDecode.bool),
              timezone: field.required("timezone", JsonDecode.string),
              addressSourceOptions: field.required("addressSourceOptions", decode$19),
              tourValues: field.required("tourValues", JsonDecode.option(decode$22)),
              successPath: field.required("successPath", JsonDecode.option(decode$16))
            };
    });

var Context = {
  decode: decode$23
};

var Contacts = {};

var Urls = {};

var BusinessHours = {};

var Permission = {
  toString: toString
};

var ViewEditPermission = {
  toString: toString$1
};

var BoolPermission = {
  toString: toString$2
};

var User = {};

var Trial = {};

var Environment = {};

var MapType = {
  toString: toString$3,
  decode: decode$18
};

var AddressSourceOptions = {
  toString: toString$4
};

var LicenseSource = {};

var TourValues = {
  Intro: {}
};

var SuccessPath_IntProgress = {};

var SuccessPath = {
  IntProgress: SuccessPath_IntProgress,
  Step: Step
};

export {
  TimeZone ,
  Contacts ,
  Urls ,
  Branding ,
  Template ,
  BusinessHours ,
  Permission ,
  ViewEditPermission ,
  BoolPermission ,
  $$Permissions ,
  User ,
  Features ,
  Tags ,
  Trial ,
  Environment ,
  MapType ,
  AddressSourceOptions ,
  LicenseSource ,
  TourValues ,
  SuccessPath ,
  Context ,
}
/* decode Not a pure module */
