


var Size = {};

var Icon = {};

function style(size, spinOpt, icon) {
  var spin = spinOpt !== undefined ? spinOpt : false;
  var fasize = size !== undefined ? "fa-" + size : "";
  var prefix = icon === "cc-jcb" || icon === "cc-mastercard" || icon === "cc-paypal" || icon === "cc-visa" ? "fab" : (
      icon === "map" ? "far" : (
          icon === "ruble-sign" ? "fas" : "fa"
        )
    );
  if (spin) {
    return prefix + " " + fasize + " fa-" + icon + " fa-spin";
  } else {
    return prefix + " " + fasize + " fa-" + icon;
  }
}

export {
  Size ,
  Icon ,
  style ,
}
/* No side effect */
