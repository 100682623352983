

import * as Js_string from "rescript/lib/es6/js_string.js";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";

var make = (function(str) {
    try {
      return new URL(str);
    } catch {
      return;
    }
  });

var _trailingSlash = /\/$/;

function href(url) {
  return Js_string.replaceByRe(_trailingSlash, "", url.href);
}

function normalizeStringToURL(string) {
  var trimmed = string.trim();
  var looksLikeURL = Js_string.startsWith("https://", trimmed) || Js_string.startsWith("http://", trimmed);
  if (trimmed === "") {
    return "";
  } else if (looksLikeURL) {
    return trimmed;
  } else {
    return "https://" + trimmed;
  }
}

var decode = JsonDecode.fromVariant(JsonDecode.string, make);

function toString(prim) {
  return prim.toString();
}

function origin(prim) {
  return prim.origin;
}

export {
  make ,
  toString ,
  origin ,
  href ,
  normalizeStringToURL ,
  decode ,
}
/* decode Not a pure module */
