import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from 'planado/actions/admin/job_types'
import JobTypesList from 'planado/components/admin/job_types/list'
import { SaveButton } from 'planado/components/admin/job_types/save_button'

const JobTypesListC = {
  mapStateToProps: (state, _ownProps) => ({
    types: state.types,
    formDisabled: state.formDisabled
  }),
  mapDispatchToProps: (dispatch, _ownProps) => ({
    actions: bindActionCreators(actions, dispatch)
  })
}

export const JobTypesListLink = connect(
  JobTypesListC.mapStateToProps,
  JobTypesListC.mapDispatchToProps
)(JobTypesList)

const SaveButtonC = {
  mapStateToProps: (state, _ownProps) => ({
    disabled: state.formDisabled
  }),
  mapDispatchToProps: (dispatch, _ownProps) => ({
    actions: bindActionCreators(actions, dispatch)
  })
}

export const SaveButtonLink = connect(
  SaveButtonC.mapStateToProps,
  SaveButtonC.mapDispatchToProps
)(SaveButton)
