

import * as Wire from "../../../types/Wire.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as UI_Icon from "../../../ui/UI_Icon.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../../../components/common/Optional.mjs";
import * as UI_Button from "../../../ui/UI_Button.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as DropDownMenu from "../../../components/common/DropDownMenu/DropDownMenu.mjs";
import * as Pages_Client_Types from "./Pages_Client_Types.mjs";
import * as Pages_Client_HeadingModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/clients/edit/Pages_Client_Heading.module.css";

var add$p = Utils.Translations.tr("js.clients.heading.add");

var edit$p = Utils.Translations.tr("js.clients.heading.edit");

var addExternalId$p = Utils.Translations.tr("js.clients.dropdown.add_external_id");

var styles = Pages_Client_HeadingModuleCss;

function Pages_Client_Heading$ClientTitle(Props) {
  var client = Props.client;
  var ctx = Props.ctx;
  var initial = React.useMemo((function () {
          return client;
        }), []);
  if (initial.isNewRecord) {
    return React.createElement("div", {
                className: styles.clientFormHeadingTitle
              }, add$p(ctx));
  } else {
    return React.createElement("div", {
                className: styles.clientFormHeadingTitle
              }, initial.organization ? Pages_Client_Types.fieldToString(initial.organizationName) : RCore.$$Array.filterMap([
                        initial.firstName,
                        initial.lastName
                      ], (function (n) {
                          return n;
                        })).join(" "), React.createElement("span", undefined, edit$p(ctx)));
  }
}

function showExternalId(setState) {
  return setState(function (current) {
              var init = current.client;
              return {
                      limits: current.limits,
                      canManage: current.canManage,
                      locked: current.locked,
                      client: {
                        uuid: init.uuid,
                        isNewRecord: init.isNewRecord,
                        organization: init.organization,
                        showingExternalId: true,
                        externalId: init.externalId,
                        name: init.name,
                        firstName: init.firstName,
                        lastName: init.lastName,
                        middleName: init.middleName,
                        organizationName: init.organizationName,
                        contacts: init.contacts,
                        floor: init.floor,
                        apartment: init.apartment,
                        addressDescription: init.addressDescription,
                        entranceNo: init.entranceNo,
                        territoryUuid: init.territoryUuid,
                        availableTerritories: init.availableTerritories,
                        createdAt: init.createdAt,
                        address: init.address
                      },
                      addressConstraints: current.addressConstraints,
                      errors: current.errors
                    };
            });
}

function Pages_Client_Heading$ClientExternalId(Props) {
  var state = Props.state;
  var setState = Props.setState;
  var ctx = Props.ctx;
  var client = state.client;
  var onClick = function (evt) {
    evt.preventDefault();
    if (!client.showingExternalId) {
      return showExternalId(setState);
    }
    
  };
  if (Caml_obj.notequal(Pages_Client_Types.$$Error.message(state.errors, "externalId"), []) && !client.showingExternalId) {
    showExternalId(setState);
  }
  return React.createElement(Optional.make, {
              show: !client.showingExternalId && state.canManage && client.isNewRecord,
              children: React.createElement(DropDownMenu.make, {
                    position: "right",
                    className: styles.dropdownOptions,
                    children: React.createElement("li", undefined, React.createElement("a", {
                              className: "add_external_id",
                              href: "",
                              onClick: onClick
                            }, addExternalId$p(ctx)))
                  })
            });
}

function Pages_Client_Heading(Props) {
  var state = Props.state;
  var setState = Props.setState;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  return React.createElement("div", {
              className: styles.clientFormHeading
            }, React.createElement(Pages_Client_Heading$ClientTitle, {
                  client: state.client,
                  ctx: ctx
                }), React.createElement("div", {
                  className: styles.clientFormHeadingRight
                }, React.createElement(Pages_Client_Heading$ClientExternalId, {
                      state: state,
                      setState: setState,
                      ctx: ctx
                    }), React.createElement(UI_Button.make, {
                      flavor: "close",
                      className: styles.closeButton,
                      onClick: (function (_event) {
                          Wire.closeModal(wire);
                        }),
                      children: React.createElement(UI_Icon.make, {
                            icon: "times"
                          })
                    })));
}

var make = Pages_Client_Heading;

export {
  make ,
}
/* add' Not a pure module */
