import React, { Component } from 'react'
import { ymaps } from '../yandex'
import gmaps from './gmaps'
import { eventsDecorator, supportEvents } from '../events'
import { areEqual } from 'planado/utils/index.js'
import { GEOLOCATION_OPTIONS } from '../constants'
import { withContext } from 'planado/utils/contextConsumer.jsx'

class MapEngine {
  createMap(element, options = {}, controls) {
    controls = controls || {
      disableDefaultUI: true,
      zoomControl: true,
      zoomControlOptions: {
        position: gmaps.getApi().ControlPosition.RIGHT_TOP,
      },
      mapTypeControl: true,
      mapTypeControlOptions: {
        position: gmaps.getApi().ControlPosition.TOP_RIGHT,
      },
    }

    this.map = new (gmaps.getApi().Map)(element, {
      ...options,
      ...controls,
    })

    this.setBoundsLimit()
  }

  setBoundsLimit() {
    let ignoreMapMove = false
    let center = this.map.getCenter()

    this.map.addListener('center_changed', () => {
      if (ignoreMapMove) {
        ignoreMapMove = false
        return
      }

      const bounds = this.map.getBounds().toJSON()
      if (bounds.north < 85 && bounds.south > -85) {
        center = this.map.getCenter()
        return
      }

      ignoreMapMove = true
      this.map.setCenter(center)
    })

    this.map.addListener('zoom_changed', () => { })

    this.map.addListener('resize', () => { })
  }

  getMap() {
    return this.map || null
  }

  addMarker(marker) {
    marker.setMap(this.getMap())
  }

  setCenter(center) {
    this.map.setCenter(center)
  }

  setCenterSmoothly(center) {
    this.map.panTo(center)
  }

  setZoom(zoom) {
    this.map.setZoom(zoom)
  }

  setOption(name, value) {
    this.map.set(name, value)
  }

  setOptions(controls) {
    for (const key in controls) {
      this.setOption(key, controls[key])
    }
  }

  setEvent(name, cb) {
    this.map.addListener(name, cb)
  }
}

export const MapEngineContext = React.createContext(null)

class Map extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isApiLoaded: false,
    }

    this.engine = null

    this.onAPILoad = this.onAPILoad.bind(this)
    this.getCenter = this.getCenter.bind(this)
  }

  componentDidMount() {
    const { ctx, apiKey } = this.props

    if (gmaps.isAvailible() && ymaps.isAvailible()) {
      this.onAPILoad()
    } else if (!ymaps.isAvailible()) {
      ymaps.load({ ctx, coordorder: 'latlong' }).then(() => {
        if (gmaps.isAvailible()) {
          this.onAPILoad()
        } else {
          gmaps.load({ ctx, apiKey }).then(this.onAPILoad)
        }
      })
    }

    if (this.engineAvailible) {
      gmaps.getApi().event.trigger(this.engine.getMap(), 'resize')
      this.getCenter().then((center) => this.engine.setCenter(center))
    }
  }

  getCenter() {
    return new Promise((resolve) => {
      const { center } = this.props

      if (center !== null) {
        resolve(gmaps.getCoords(center))
      } else {
        ymaps
          .getApi()
          .geolocation.get(GEOLOCATION_OPTIONS)
          .done((location) => {
            resolve(gmaps.getCoords(location.geoObjects.position))
          })
      }
    })
  }

  onAPILoad() {
    this.getCenter().then((center) => {
      const { options, zoom, controls } = this.props

      this.engine = new MapEngine()
      this.engine.createMap(
        this.element,
        { ...options, center, zoom, styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'off' }] }] },
        controls
      )
      this.setupEvents()

      this.setState({
        isApiLoaded: true,
      })
    })
  }

  componentWillReceiveProps(nextProps) {
    const { center: currentCenter = null } = this.props
    const { center: nextCenter = null } = nextProps

    if (
      this.engineAvailible &&
      Array.isArray(currentCenter) &&
      Array.isArray(nextCenter)
    ) {
      if (!areEqual(currentCenter, nextCenter)) {
        this.engine.setCenterSmoothly(gmaps.getCoords(nextCenter))
      }
    } else if (this.engineAvailible && Array.isArray(nextCenter)) {
      this.engine.setCenterSmoothly(gmaps.getCoords(nextCenter))
    }
  }

  get engineAvailible() {
    return this.engine !== null
  }

  getEngine() {
    return this.engine || null
  }

  get style() {
    const { width, height } = this.props

    return {
      width: typeof width === 'string' ? width : `${width}px`,
      height: typeof height === 'string' ? height : `${height}px`,
    }
  }

  render() {
    return (
      <MapEngineContext.Provider value={{ mapEngine: this.engine }}>
        <div className="map-component" style={this.style}>
          <div
            className="map-component__map"
            ref={(el) => {
              this.element = el
            }}
          />
          {this.state.isApiLoaded ? this.props.children : null}
        </div>
      </MapEngineContext.Provider>
    )
  }
}

Map.defaultProps = {
  center: null,
  zoom: 13,
  width: '100%',
  height: '100%',
  options: {
    minZoom: 2,
    maxZoom: 18,
  },
  style: {
    position: 'relative',
  },
}

export default withContext(eventsDecorator(Map, supportEvents['google']['map']))
