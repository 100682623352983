import React, { Component } from 'react'
import classNames from 'classnames'
import jQuery from 'jquery'
import { showTime, getMoment, now } from 'planado/utils/time/index.js'
import { Toolbar } from './toolbar'
import { SessionContext } from 'planado/utils/context.js'

export class Job extends Component {
  state = {
    hidingToolbar: false,
    status: this.currentStatus()
  }

  componentDidMount() {
    const { sideBar, chart, model } = this.props

    jQuery(this.sbj).on('toolbar:hidden', () => {
      sideBar.setActiveJob(null)
      this.setState(() => ({ hidingToolbar: false }))
    })

    chart.on('change:currentTime', this.updateStateClass, this)
    model.on('change', () => this.forceUpdate(), this)

    jQuery(this.sbj)
      .find('[title]')
      .tooltip({
        placement: 'bottom',
        animation: false,
        container: 'body',
        delay: {
          show: 500,
          hide: 100
        }
      })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.model === nextProps.model) {
      return
    }
    nextProps.model.on('change', () => this.forceUpdate(), this)
  }

  componentWillUpdate() {
    this.updateStateClass()
  }

  componentDidUpdate() {
    const { model } = this.props
    const $el = jQuery(this.sbj)

    if ($el.data('uiDraggable') && !model.isSchedulable()) {
      $el.draggable('destroy')
    }

    $el.find('[data-original-title]').tooltip('destroy')
    $el.find('[title]').tooltip({
      placement: 'bottom',
      animation: false,
      container: 'body',
      delay: {
        show: 500,
        hide: 100
      }
    })
  }

  componentWillUnmount() {
    const { model, chart } = this.props

    chart.off(null, null, this)
    model.off(null, null, this)

    jQuery(this.sbj).off('toolbar:hidden')
  }

  currentStatus() {
    const { model, chart, ctx } = this.props
    return model.status(chart.time(), ctx)
  }

  toggleActiveJob = evt => {
    const { model, sideBar } = this.props

    if (
      evt.target.nodeName === 'A' &&
      jQuery(evt.target).attr('href') !== '#'
    ) {
      return
    } else {
      evt.preventDefault()
    }

    if (model.get('active')) {
      this.setState(() => ({ hidingToolbar: true }))
    } else {
      sideBar.setActiveJob(model)
    }
  }

  getIconClasses(state) {
    return classNames({
      'fa menu-icon': true,
      'fa-question': state === 'posted',
      'fa-calendar-alt': state === 'scheduled',
      'fa-hand-point-right': state === 'assigned',
      'fa-play': state === 'started',
      'fa-truck fa-flip-horizontal': state === 'en_route',
      'fa-pause': state === 'suspended',
      'fa-check': state === 'finished'
    })
  }

  getJobTime = job => {
    const { ctx } = this.props
    const scheduledDate = job.scheduledAt(ctx)
    const scheduled = scheduledDate === null
    let jobTime, timeIconClass, timeTitle

    if (job.finished()) {
      const finishedDate = job.finishedAt(ctx)

      if (scheduled) {
        jobTime = showTime(finishedDate, 'date_no_year', ctx)
        timeTitle = showTime(finishedDate, 'datetime', ctx)
      } else {
        jobTime = showTime(scheduledDate, 'date_no_year', ctx)
        timeTitle = showTime(scheduledDate, 'datetime', ctx)
      }

      timeIconClass = 'fa fa-calendar-alt'
    } else {
      if (scheduled) {
        const d = new Date(1970, 1, 1)

        jobTime = showTime(d, 'time', ctx).replace(/0/g, '?')
        timeIconClass = 'fa fa-clock'
        timeTitle = ctx.t('common.messages.job_not_scheduled')
      } else {
        if (
          scheduledDate.isSame(getMoment({ dateTime: now(ctx), ctx }), 'day')
        ) {
          jobTime = showTime(scheduledDate, 'time', ctx)
          timeIconClass = 'fa fa-clock'
        } else {
          jobTime = showTime(scheduledDate, 'date_no_year', ctx)
          timeIconClass = 'fa fa-calendar-alt'
        }

        timeTitle = showTime(scheduledDate, 'datetime', ctx)
      }
    }

    return [jobTime, timeIconClass, timeTitle]
  }

  updateStateClass = () => {
    let newStateClass = this.currentStatus()

    if (newStateClass !== this.state.status) {
      this.setState(() => ({ status: newStateClass }))
    }
  }

  render() {
    const { model, open, ctx } = this.props
    let address
    let job = model
    let { sideBar } = this.props
    let toolbar = ''

    if (job.isActive()) {
      toolbar = (
        <SessionContext.Consumer>
          {ctx => (
            <Toolbar
              ctx={ctx}
              job={job}
              sideBar={sideBar}
              open={() => open(job.getUuid())}
              hiding={this.state.hidingToolbar}
            />
          )}
        </SessionContext.Consumer>
      )
    }

    let classes = classNames({
      'side-bar-job': true,
      active: job.isActive(),
      'in-progress': job.inProgress()
    })

    classes += ` ${this.state.status}`

    let visibleState = job.visibleState()
    let iconClasses = this.getIconClasses(visibleState)

    let time = this.getJobTime(job)
    let jobTime = time[0]
    let timeIconClass = time[1]
    let timeTitle = time[2]

    if (job.get('address')) {
      address = (
        <span className="mm-text address" title={job.get('address')}>
          {job.get('address')}
        </span>
      )
    } else {
      address = ''
    }

    let stateTitle = ctx.t(`common.job_states.${visibleState}`)

    return (
      <li
        className={classes}
        onClick={this.toggleActiveJob}
        data-job-id={job.id}
        ref={div => {
          this.sbj = div
        }}
        data-group-name={ctx.t(`js.schedule.job_states.${this.state.status}`)}
      >
        <a href="#">
          <span className="label label-small job-no" title={job.get('name')}>
            {job.get('serial_no')}
          </span>
          <i
            className={iconClasses}
            title={stateTitle}
            data-original-title={stateTitle}
          />
          <span className="mm-text" title={timeTitle}>
            <i className={timeIconClass} />
            {` ${jobTime}`}
          </span>
          <span className="label label-small">{job.assigneeName()}</span>
          {address}
        </a>
        {toolbar}
      </li>
    )
  }
}
