

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as Pages_Clients_Upload_Types from "./Pages_Clients_Upload_Types.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              uuid: field.optional("uuid", Uuid.decode),
              taskErrors: field.required("errors", JsonDecode.array(JsonDecode.string)),
              summary: field.required("summary", Pages_Clients_Upload_Types.Summary.decode)
            };
    });

function send(wire, data) {
  return Backend.decode(Backend.post(undefined, undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Client",
                    VAL: "Upload"
                  }
                }, {
                  NAME: "FormData",
                  VAL: data
                }), decode);
}

var decode$1 = JsonDecode.object(function (field) {
      return {
              errors: field.required("errors", JsonDecode.array(JsonDecode.string)),
              summary: field.required("summary", Pages_Clients_Upload_Types.Summary.decode),
              status: field.required("status", JsonDecode.string),
              success: field.required("success", JsonDecode.bool)
            };
    });

function send$1(wire, uuid) {
  return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Tasks",
                    VAL: {
                      NAME: "Client",
                      VAL: uuid
                    }
                  }
                }), decode$1);
}

var Post_Response = {};

var Post = {
  $$Response: Post_Response,
  send: send
};

var Get_Response = {};

var Get = {
  $$Response: Get_Response,
  send: send$1
};

export {
  Post ,
  Get ,
}
/* decode Not a pure module */
