export const TEMPLATE_SET_FIELD = 'TEMPLATE_SET_FIELD'

export const TEMPLATE_ADD_CUSTOM_FIELD = 'TEMPLATE_ADD_CUSTOM_FIELD'
export const TEMPLATE_MOVE_CUSTOM_FIELD = 'TEMPLATE_MOVE_CUSTOM_FIELD'
export const TEMPLATE_SET_CUSTOM_FIELD = 'TEMPLATE_SET_CUSTOM_FIELD'
export const TEMPLATE_DELETE_CUSTOM_FIELD = 'TEMPLATE_DELETE_CUSTOM_FIELD'
export const TEMPLATE_SET_CUSTOM_FIELD_FILE = 'TEMPLATE_SET_CUSTOM_FIELD_FILE'

export const TEMPLATE_ADD_RESOLUTION = 'TEMPLATE_ADD_RESOLUTION'
export const TEMPLATE_MOVE_RESOLUTION = 'TEMPLATE_MOVE_RESOLUTION'
export const TEMPLATE_DELETE_RESOLUTION = 'TEMPLATE_DELETE_RESOLUTION'

export const TEMPLATE_SET_POSSIBLE_ASSIGNEES = 'TEMPLATE_SET_POSSIBLE_ASSIGNEES'

export const GET_POSSIBLE_FIELD_NAMES_REQUEST =
  'GET_POSSIBLE_FIELD_NAMES_REQUEST'
export const GET_POSSIBLE_FIELD_NAMES_SUCCESS =
  'GET_POSSIBLE_FIELD_NAMES_SUCCESS'
export const GET_POSSIBLE_FIELD_NAMES_FAIL = 'GET_POSSIBLE_FIELD_NAMES_FAIL'

export const TEMPLATE_ADD_ERROR = 'TEMPLATE_ADD_ERROR'

export const CHECK_NAME_REQUEST = 'CHECK_NAME_REQUEST'
export const CHECK_NAME_SUCCESS = 'CHECK_NAME_SUCCESS'
export const CHECK_NAME_FAIL = 'CHECK_NAME_FAIL'

export const INPUT_MAX_LENGTH = 80

export const SHOW_POPOVER = 'SHOW_POPOVER'
export const SHOW_MODAL = 'SHOW_MODAL'

export const SET_TEMPLATE = 'SET_TEMPLATE'
export const CHANGE_SKILLS = 'CHANGE_SKILLS'
export const CHANGE_TERRITORY = 'CHANGE_TERRITORY'

export const SEND_FORM_REQUEST = 'SEND_FORM_REQUEST'
export const SEND_FORM_SUCCESS = 'SEND_FORM_SUCCESS'
export const SEND_FORM_FAIL = 'SEND_FORM_FAIL'

export const TEMPLATE_SET_TRACK_MOVEMENTS = 'TEMPLATE_SET_TRACK_MOVEMENTS'

export const TEMPLATE_SET_ASSIGNEES = 'TEMPLATE_SET_ASSIGNEES'

export const TEMPLATE_SET_CAN_BE_USED_ON_MOBILE =
  'TEMPLATE_SET_CAN_BE_USED_ON_MOBILE'

export const SET_SERVICES = 'SET_SERVICES'
export const SET_CATEGORIES = 'SET_CATEGORIES'
