

import * as Fun from "../../../../../../../libraries/Fun.mjs";
import * as Wire from "../../../../../../../types/Wire.mjs";
import * as RCore from "../../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../../types/Types.mjs";
import * as Units from "../../../../../../../types/Units.mjs";
import * as React from "react";
import * as Locale from "../../../../../../../libraries/Locale.mjs";
import * as MomentRe from "../../../../../../../bindings/moment/MomentRe.mjs";
import * as AppContext from "../../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_State from "../../../../../lib/state/Schedule_State.mjs";
import * as WeekTimeline_State from "../../../lib/state/WeekTimeline_State.mjs";
import * as WeekTimeline_Constants from "../../../lib/WeekTimeline_Constants.mjs";
import * as Webapi__Dom__HtmlElement from "rescript-webapi/lib/es6/src/Webapi/Dom/Webapi__Dom__HtmlElement.mjs";
import * as WeekTimeline_CurrentTimeModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/timeline/current_time/WeekTimeline_CurrentTime.module.css";

var styles = WeekTimeline_CurrentTimeModuleCss;

function positionLeft(wire, period, sizes) {
  var now = Locale.T.now(wire);
  if (!Locale.T.withinInterval(now, period)) {
    return ;
  }
  var match = Wire.businessHours(wire);
  if (match !== undefined) {
    var start = match.start;
    var startOfDay = Locale.T.startOf("day", now);
    var startOfBusinessHours = Locale.T.add(start, startOfDay);
    var endOfDay = Locale.T.endOf("day", now);
    var endOfBsinessHours = Locale.T.add(match.finish, startOfDay);
    var daysShift = Units.Px.multiplyWithInt(sizes.day, Locale.T.isoWeekday(now));
    if (Locale.T.withinInterval(now, [
            startOfDay,
            startOfBusinessHours
          ])) {
      return Caml_option.some(daysShift);
    }
    if (Locale.T.withinInterval(now, [
            endOfBsinessHours,
            endOfDay
          ])) {
      return Caml_option.some(Units.Px.minus(Units.Px.plus(daysShift, sizes.day), Units.Px.fromInt(1)));
    }
    var minutes = Locale.T.dateToMinutes(wire, now) - (MomentRe.Duration.asMinutes(start) | 0) | 0;
    return Caml_option.some(Units.Px.plus(Units.Px.plus(daysShift, Units.Px.fromInt(5)), Units.Px.multiplyWithInt(sizes.minute, minutes)));
  }
  var minutes$1 = Math.imul(Locale.T.isoWeekday(now), WeekTimeline_Constants.minutesInDay) + Locale.T.dateToMinutes(wire, now) | 0;
  return Caml_option.some(Units.Px.multiplyWithInt(sizes.minute, minutes$1));
}

function use() {
  var wire = AppContext.useWire();
  var sizes = WeekTimeline_State.useSizes();
  var locked = Schedule_State.useLocked();
  var period = Schedule_State.usePeriod();
  var elementRef = Types.ReactRef.use();
  var setStyle = function () {
    requestAnimationFrame(function (_time) {
          var element = Types.ReactRef.toOption(elementRef);
          var htmlElement = RCore.$$Option.flatMap(element, Webapi__Dom__HtmlElement.ofElement);
          var match = Fun.both(element, htmlElement);
          if (match === undefined) {
            return ;
          }
          var element$1 = match[0];
          var left = positionLeft(wire, period, sizes);
          if (left !== undefined) {
            match[1].style.setProperty("left", Units.Px.toString(Caml_option.valFromOption(left)));
            element$1.classList.add(styles.visible);
          } else {
            element$1.classList.remove(styles.visible);
          }
        });
  };
  React.useEffect((function () {
          setStyle();
        }), [
        sizes,
        period
      ]);
  React.useEffect((function () {
          if (locked) {
            return ;
          } else {
            return wire.subscriptions.timer.subscribe(function (_now) {
                        setStyle();
                      });
          }
        }), [
        locked,
        sizes,
        period
      ]);
  return elementRef;
}

export {
  use ,
}
/* styles Not a pure module */
