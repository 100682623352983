

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as RCore from "../RCore.mjs";
import * as Random from "../Random.mjs";

var TranstionEvent = {};

var Status = {};

var $$Event = {};

function toString(css) {
  if (typeof css === "object") {
    return "template-variant-" + Uuid.toString(css.VAL);
  } else if (css === "job-form-scheduled_at-date") {
    return "job-form-scheduled_at-date";
  } else if (css === "schedule-viewport") {
    return "schedule-viewport";
  } else if (css === "job-form-assignee-select") {
    return "job-form-assignee-select";
  } else if (css === "job-form-description") {
    return "job-form-description";
  } else if (css === "job-add-button") {
    return "job-add-button";
  } else if (css === "job-new-client-name") {
    return "job-new-client-name";
  } else if (css === "job-submit-button") {
    return "job-submit-button";
  } else if (css === "job-form-assignee-options") {
    return "job-form-assignee-options";
  } else if (css === "job-form-add-client") {
    return "job-form-add-client";
  } else if (css === "job-form-report") {
    return "job-form-report";
  } else {
    return "job-new-client-phone";
  }
}

function id(seed, css) {
  return Random.$$CSS.id(seed, "tour-" + toString(css));
}

function withSeed(seed) {
  return function (from) {
    if (typeof from !== "object") {
      return document.createElement("div");
    }
    var variant = from.NAME;
    if (variant === "element" || variant === "css") {
      return from.VAL;
    } else {
      return "." + id(seed, from.VAL);
    }
  };
}

function make(from) {
  var variant = from.NAME;
  if (variant === 2) {
    var match = from.VAL;
    return [
            match[0],
            match[1]
          ];
  }
  if (variant !== 4) {
    return from.VAL;
  }
  var match$1 = from.VAL;
  return [
          match$1[0],
          match$1[1],
          match$1[2],
          match$1[3]
        ];
}

var $$default = make({
      NAME: 1,
      VAL: 10
    });

var ComponentPadding = {
  make: make,
  $$default: $$default
};

var $$default$1 = {
  mask: $$default,
  popover: $$default,
  wrapper: $$default
};

function make$1(popover, mask, wrapper) {
  return {
          mask: make(mask),
          popover: make(popover),
          wrapper: make(wrapper)
        };
}

var Padding = {
  $$default: $$default$1,
  make: make$1
};

function withSeed$1(seed) {
  var makeSelector = withSeed(seed);
  return function (delayUntil, position, bypassElem, stepInteraction, highlightedSelectors, mutationObservables, resizeObservables, popoverPaddingOpt, selector, button, maskPaddingOpt, wrapperPaddingOpt, content, label, param) {
    var popoverPadding = popoverPaddingOpt !== undefined ? popoverPaddingOpt : ({
          NAME: 1,
          VAL: 10
        });
    var maskPadding = maskPaddingOpt !== undefined ? maskPaddingOpt : (
        typeof selector === "object" ? ({
              NAME: 1,
              VAL: 10
            }) : ({
              NAME: 1,
              VAL: 0
            })
      );
    var wrapperPadding = wrapperPaddingOpt !== undefined ? wrapperPaddingOpt : ({
          NAME: 1,
          VAL: 0
        });
    return {
            position: position,
            selector: makeSelector(selector),
            content: content,
            delayUntil: delayUntil,
            stepInteraction: stepInteraction,
            disableActions: false,
            bypassElem: bypassElem,
            highlightedSelectors: highlightedSelectors,
            mutationObservables: RCore.$$Option.map(mutationObservables, (function (xs) {
                    return xs.map(makeSelector);
                  })),
            resizeObservables: RCore.$$Option.map(resizeObservables, (function (xs) {
                    return xs.map(makeSelector);
                  })),
            button: button,
            padding: make$1(popoverPadding, maskPadding, wrapperPadding),
            label: label
          };
  };
}

var Step_defaultPadding = {
  NAME: 1,
  VAL: 10
};

var Step = {
  defaultPadding: Step_defaultPadding,
  withSeed: withSeed$1
};

var Intro = {
  empty: {
    autoStart: undefined,
    started: undefined,
    closed: undefined,
    progress: undefined
  }
};

var TourProgress = {
  Intro: Intro
};

var $$CSS = {
  id: id
};

var Selector = {};

export {
  TranstionEvent ,
  Status ,
  $$Event ,
  $$CSS ,
  Selector ,
  ComponentPadding ,
  Padding ,
  Step ,
  TourProgress ,
}
/* default Not a pure module */
