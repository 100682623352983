

import * as El from "../../../libraries/El.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../libraries/Locale.mjs";
import * as MapTypes from "../MapTypes.mjs";
import * as MapJobsItem from "./MapJobsItem.mjs";
import * as MapJobsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/map/jobs/MapJobs.module.css";

var styles = MapJobsModuleCss;

function status$p(status, c) {
  return Utils.Translations.tr("js.map.scheduling.statuses." + status)(c);
}

function MapJobsScheduled(Props) {
  var wire = Props.wire;
  var focus = Props.focus;
  var focusedUuid = Props.focusedUuid;
  var date = Props.date;
  var jobs = Props.jobs;
  var ctx = wire.ctx;
  var dateStr = Locale.T.fmtM("DateWithDayOfWeek", wire)(date);
  var dateUniq = Locale.T.isoDate(date);
  var jobsByStatus = function (param) {
    var status = param[0];
    return React.createElement("section", {
                key: MapTypes.Job.Status.toString(status) + "." + dateUniq,
                className: El.Cn.concat(styles.jobsByStatus, styles.jobsList)
              }, React.createElement("header", undefined, status$p(MapTypes.Job.Status.toString(status), ctx)), React.createElement("ul", undefined, param[1].map(function (job) {
                        return React.createElement(MapJobsItem.make, {
                                    wire: wire,
                                    onClick: focus,
                                    focusedUuid: focusedUuid,
                                    job: job,
                                    key: job.serialNo.toString()
                                  });
                      })));
  };
  return React.createElement("section", undefined, React.createElement("header", {
                  className: styles.scheduledDate
                }, dateStr), El.list(jobsByStatus, jobs));
}

var make = MapJobsScheduled;

export {
  make ,
}
/* styles Not a pure module */
