

import * as El from "../../../../../../libraries/El.mjs";
import * as Uuid from "../../../../../../bindings/uuid/Uuid.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Assignee_Types from "../../../../../../types/Assignee_Types.mjs";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as DayTimeline_Bar from "./bar/DayTimeline_Bar.mjs";
import * as DayTimeline_Scale from "./scale/DayTimeline_Scale.mjs";
import * as DayTimeline_State from "../../lib/state/DayTimeline_State.mjs";
import * as Schedule_TimelineEmpty from "../../../../components/timeline/empty/Schedule_TimelineEmpty.mjs";
import * as Schedule_TimelineHooks from "../../../../lib/hooks/Schedule_TimelineHooks.mjs";
import * as DayTimeline_CurrentTime from "../current_time/DayTimeline_CurrentTime.mjs";
import * as DayTimeline_BusinessHours from "../business_hours/DayTimeline_BusinessHours.mjs";
import * as Schedule_Scroll_InitialPosition from "../../../../components/scroll/Schedule_Scroll_InitialPosition.mjs";
import * as DayTimeline_TimelineModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/day_timeline/components/timeline/DayTimeline_Timeline.module.css";

var styles = DayTimeline_TimelineModuleCss;

function barKey(v) {
  return Uuid.toString(Assignee_Types.Assignee.uuid(v));
}

function makeStyle(width) {
  return {
          width: Units.Px.toString(width)
        };
}

function makeScrollableStyle(width, initialPositionY) {
  var initialPositionY$1 = Units.Px.toString(Units.Px.abs(initialPositionY));
  return {
          width: Units.Px.toString(width),
          transform: "translate(0px, -" + initialPositionY$1 + ")"
        };
}

function DayTimeline_Timeline(Props) {
  var viewportEl = Props.viewportEl;
  var scaleEl = Props.scaleEl;
  var bars = Props.bars;
  var wire = AppContext.useWire();
  var sizes = DayTimeline_State.useSizes();
  var loading = Schedule_State.useScheduledJobsLoading();
  var match = Schedule_TimelineHooks.DragCursor.use();
  var initialPositionY = Schedule_Scroll_InitialPosition.useInitialPositionY();
  if (Caml_obj.equal(bars, []) && !loading) {
    return React.createElement(Schedule_TimelineEmpty.make, {
                width: sizes.emptyViewport
              });
  }
  var className = El.Cn.concat(styles.container, match[2] ? styles.isDrag : "");
  return React.createElement(DayTimeline_BusinessHours.make, {
              children: React.createElement("div", {
                    className: className,
                    onMouseDown: match[0],
                    onMouseUp: match[1]
                  }, React.createElement(DayTimeline_Scale.make, {
                        scaleEl: scaleEl,
                        viewportEl: viewportEl
                      }), React.createElement("div", {
                        ref: viewportEl,
                        className: styles.viewport + " " + wire.tour.anchor("schedule-viewport"),
                        style: makeStyle(sizes.viewport)
                      }, React.createElement("div", {
                            className: styles.scrollable,
                            style: makeScrollableStyle(sizes.timeline, initialPositionY)
                          }, bars.map(function (param) {
                                var assignee = param.assignee;
                                return React.createElement(DayTimeline_Bar.make, {
                                            viewportEl: viewportEl,
                                            assignee: assignee,
                                            jobs: param.jobs,
                                            workingIntervals: param.workingIntervals,
                                            key: barKey(assignee)
                                          });
                              }), React.createElement(DayTimeline_CurrentTime.make, {
                                part: "Line"
                              }))))
            });
}

var make = React.memo(DayTimeline_Timeline);

export {
  make ,
}
/* styles Not a pure module */
