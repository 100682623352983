

import * as El from "../../../../../../libraries/El.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as MonthDay from "../../../../../common/MonthDay/MonthDay.mjs";
import * as Optional from "../../../../../common/Optional.mjs";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as WeekCalendar_ColumnHeaderModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_calendar/components/column/WeekCalendar_ColumnHeader.module.css";

function day$p(num, ctx) {
  return Utils.Translations.tr("js.schedule.v2.abbr_day_names." + num.toString())(ctx);
}

function jobs$p(count, ctx) {
  return Utils.Translations.txr("js.schedule.v2.jobs_count")({
              count: count
            }, ctx);
}

var styles = WeekCalendar_ColumnHeaderModuleCss;

function WeekCalendar_ColumnHeader(Props) {
  var prevDay = Props.prevDay;
  var day = Props.day;
  var columnWidth = Props.columnWidth;
  var sizeOfJobs = Props.sizeOfJobs;
  var wire = AppContext.useWire();
  var dayActive = Locale.T.equalDay(day, Locale.T.now(wire));
  var style = {
    width: columnWidth.toString() + "px"
  };
  var className = El.Cn.concat(styles.headerButton, dayActive ? styles.active : "");
  return React.createElement("div", {
              className: className,
              style: style
            }, React.createElement("div", {
                  className: styles.weekDay
                }, day$p(Locale.T.isoWeekday(day), wire.ctx)), React.createElement("div", {
                  className: styles.monthDay
                }, React.createElement(MonthDay.make, {
                      prevDay: prevDay,
                      day: day
                    })), React.createElement("div", {
                  className: styles.jobs,
                  style: style
                }, React.createElement(Optional.make, {
                      show: dayActive && sizeOfJobs !== 0,
                      children: React.createElement("span", {
                            className: styles.circle
                          })
                    }), React.createElement(Optional.make, {
                      show: sizeOfJobs !== 0,
                      children: jobs$p(sizeOfJobs, wire.ctx)
                    })));
}

var make = WeekCalendar_ColumnHeader;

export {
  make ,
}
/* styles Not a pure module */
