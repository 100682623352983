

import * as Wire from "../../../../types/Wire.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";

function filter(wire, assigneeFilter) {
  return RCore.$$Array.filterMap(Wire.assignees(wire), (function (assignee) {
                if (assignee.TAG === "Worker") {
                  if (assigneeFilter === "Teams") {
                    return ;
                  } else {
                    return assignee;
                  }
                } else if (assignee._0.foremanUuid !== undefined) {
                  return assignee;
                } else {
                  return ;
                }
              }));
}

export {
  filter ,
}
/* Wire Not a pure module */
