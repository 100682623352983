

import * as Url from "../bindings/url/Url.mjs";
import * as Case from "../utils/Case.mjs";
import * as Uuid from "../bindings/uuid/Uuid.mjs";
import * as RCore from "./RCore.mjs";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PhoenixElixir from "phoenix-elixir";

function add(ss, eventName, callback) {
  var existing = Js_dict.get(ss, eventName);
  if (existing !== undefined) {
    return Js_array.push(callback, existing);
  } else {
    ss[eventName] = [callback];
    return 1;
  }
}

function remove(ss, eventName, callback) {
  var cs = Js_dict.get(ss, eventName);
  if (cs === undefined) {
    return 0;
  }
  var filtered = RCore.$$Array.keep(cs, (function (c) {
          return c !== callback;
        }));
  ss[eventName] = filtered;
  return filtered.length;
}

function isEmpty(ss) {
  return Object.keys(ss).every(function (k) {
              var list = Js_dict.get(ss, k);
              if (list !== undefined) {
                return list.length === 0;
              } else {
                return true;
              }
            });
}

function fromString(s) {
  if (s === "") {
    return ;
  } else {
    return s;
  }
}

function toString(name) {
  var variant = name.NAME;
  if (variant === "scheduleV1") {
    return "schedule/v1:" + Uuid.toString(name.VAL);
  } else if (variant === "scheduleV2") {
    return "schedule/v2:" + Uuid.toString(name.VAL);
  } else {
    return "map/v1:" + Uuid.toString(name.VAL);
  }
}

function shouldCamelize(name) {
  if (typeof name === "object") {
    return name.NAME !== "scheduleV1";
  } else {
    return true;
  }
}

var Channel = {};

function on(param, param$1, param$2) {
  
}

function off(param, param$1, param$2) {
  
}

function wrap(c) {
  return {
          TAG: "Null",
          _0: c
        };
}

var Channel$1 = {
  on: on,
  off: off,
  wrap: wrap
};

function make(param, param$1) {
  
}

function channel(param, param$1) {
  
}

var Socket = {
  Channel: Channel$1,
  make: make,
  channel: channel
};

function make$1() {
  
}

function _join(raw, name) {
  console.log("Joining " + toString(name));
  raw.join();
}

function on$1(c, $$event, callback) {
  var size = add(c.subscriptions, $$event, callback);
  if (size !== 1) {
    return ;
  }
  var transform = shouldCamelize(c.name) ? Case.camelize : (function (x) {
        return x;
      });
  var raw = c.current.contents;
  var raw$1;
  if (raw !== undefined) {
    raw$1 = Caml_option.valFromOption(raw);
  } else {
    var raw$2 = c.create();
    c.current.contents = Caml_option.some(raw$2);
    _join(raw$2, c.name);
    raw$1 = raw$2;
  }
  raw$1.on($$event, (function (json) {
          var ss = c.subscriptions;
          var data = transform(json);
          var cs = Js_dict.get(ss, $$event);
          if (cs !== undefined) {
            cs.forEach(function (c) {
                  c(data);
                });
            return ;
          }
          
        }));
}

function off$1(c, name, callback) {
  var size = remove(c.subscriptions, name, callback);
  var raw = c.current.contents;
  if (raw === undefined) {
    return ;
  }
  if (size !== 0) {
    return ;
  }
  var raw$1 = Caml_option.valFromOption(raw);
  raw$1.off(name);
  if (isEmpty(c.subscriptions)) {
    var name$1 = c.name;
    console.log("Leaving " + toString(name$1));
    raw$1.leave();
    return ;
  }
  
}

function wrap$1(c) {
  return {
          TAG: "Phoenix",
          _0: c
        };
}

var Channel$2 = {
  on: on$1,
  off: off$1,
  wrap: wrap$1
};

function make$2(url, options) {
  var s = new PhoenixElixir.Socket(Url.href(url), options);
  console.log("Connecting to " + Url.href(url));
  s.connect();
  return s;
}

function channel$1(s, name) {
  return {
          current: {
            contents: undefined
          },
          create: (function () {
              return s.channel(toString(name));
            }),
          subscriptions: {},
          name: name
        };
}

var Socket$1 = {
  Channel: Channel$2,
  make: make$2,
  channel: channel$1
};

function _useChannel(socketMod, socket, c) {
  return React.useMemo((function () {
                var c$1 = socketMod.channel(socket, c);
                return socketMod.Channel.wrap(c$1);
              }), [socket]);
}

function _subscribe(channelMod, channel, eventName, callback) {
  channelMod.on(channel, eventName, callback);
  return (function () {
            channelMod.off(channel, eventName, callback);
          });
}

function make$3(heartbeatIntervalMs, enabledOpt, token, url) {
  var enabled = enabledOpt !== undefined ? enabledOpt : true;
  var token$1 = fromString(token);
  var url$1 = Url.make(url);
  if (token$1 === undefined) {
    return {
            TAG: "Null",
            _0: undefined
          };
  }
  if (url$1 === undefined) {
    return {
            TAG: "Null",
            _0: undefined
          };
  }
  var options_params = {
    token: token$1
  };
  var options = {
    params: options_params,
    heartbeatIntervalMs: heartbeatIntervalMs
  };
  if (enabled) {
    return {
            TAG: "Phoenix",
            _0: make$2(Caml_option.valFromOption(url$1), options)
          };
  } else {
    return {
            TAG: "Null",
            _0: undefined
          };
  }
}

function useChannel(socket, c) {
  if (socket.TAG === "Null") {
    return _useChannel(Socket, socket._0, c);
  } else {
    return _useChannel(Socket$1, socket._0, c);
  }
}

function useSubscription(channel, eventName, callback) {
  React.useEffect((function () {
          if (channel.TAG === "Null") {
            return _subscribe(Channel$1, channel._0, eventName, callback);
          } else {
            return _subscribe(Channel$2, channel._0, eventName, callback);
          }
        }), [channel]);
}

function useSubscription1(channel, eventName, callback, props) {
  React.useEffect((function () {
          if (channel.TAG === "Null") {
            return _subscribe(Channel$1, channel._0, eventName, callback);
          } else {
            return _subscribe(Channel$2, channel._0, eventName, callback);
          }
        }), [
        channel,
        props
      ]);
}

var nullSocket = {
  TAG: "Null",
  _0: undefined
};

var ChannelName = {};

var Null_Socket = {};

var Null = {
  Socket: Null_Socket,
  make: make$1
};

var Phoenix = {
  Socket: {}
};

export {
  ChannelName ,
  Channel ,
  Null ,
  Phoenix ,
  make$3 as make,
  useChannel ,
  useSubscription ,
  useSubscription1 ,
  nullSocket ,
}
/* Url Not a pure module */
