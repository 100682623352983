

import * as Backend from "../../../libraries/backend/Backend.mjs";

function index(wire) {
  return Backend.get(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "RecurringJob",
                VAL: "Index"
              }
            });
}

var Index = {
  index: index
};

function new_(wire, template, client, site) {
  return Backend.get(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "RecurringJob",
                VAL: {
                  NAME: "New",
                  VAL: {
                    client: client,
                    site: site,
                    template: template
                  }
                }
              }
            });
}

function show(wire, uuid) {
  return Backend.get(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "RecurringJob",
                VAL: {
                  NAME: "Edit",
                  VAL: uuid
                }
              }
            });
}

function destroy(wire, uuid) {
  return Backend.$$delete(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "RecurringJob",
                VAL: {
                  NAME: "Edit",
                  VAL: uuid
                }
              }
            });
}

var Show = {
  new_: new_,
  show: show,
  destroy: destroy
};

export {
  Index ,
  Show ,
}
/* Backend Not a pure module */
