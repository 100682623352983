

import * as El from "../../../../../../../../../libraries/El.mjs";
import * as Fun from "../../../../../../../../../libraries/Fun.mjs";
import * as RCore from "../../../../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../../../../types/Types.mjs";
import * as Units from "../../../../../../../../../types/Units.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../../../../../../../../common/Optional.mjs";
import * as AppContext from "../../../../../../../../../context/AppContext.mjs";
import * as Schedule_Types_Job from "../../../../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as Schedule_TimelineJob from "../../../../../../../components/timeline/job/Schedule_TimelineJob.mjs";
import * as WeekTimeline_JobDrag from "./hooks/WeekTimeline_JobDrag.mjs";
import * as WeekTimeline_JobUtils from "./WeekTimeline_JobUtils.mjs";
import * as MultipleAssigneesUtils from "../../../../../../../../../types/MultipleAssigneesUtils.mjs";
import * as WeekTimeline_JobListeners from "./hooks/WeekTimeline_JobListeners.mjs";
import * as WeekTimeline_JobModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/timeline/bar/components/job/WeekTimeline_Job.module.css";

var styles = WeekTimeline_JobModuleCss;

var minJobWidthWithIcon = Units.Px.fromInt(25);

function WeekTimeline_Job$StatusIcon(Props) {
  var job = Props.job;
  var position = Props.position;
  var match = Schedule_Types_Job.AssignedJob.status(job);
  var tmp;
  switch (match) {
    case "EnRoute" :
        tmp = React.createElement("div", {
              className: El.Cn.concat(styles.icon, styles.enRoute)
            });
        break;
    case "Started" :
        tmp = React.createElement("div", {
              className: El.Cn.concat(styles.icon, styles.started)
            });
        break;
    default:
      tmp = null;
  }
  return React.createElement(Optional.make, {
              show: Caml_obj.greaterthan(position.width, minJobWidthWithIcon),
              children: tmp
            });
}

function WeekTimeline_Job(Props) {
  var job = Props.job;
  var position = Props.position;
  var bar = Props.bar;
  var wire = AppContext.useWire();
  var assignees = Schedule_Types_Job.AssignedJob.assignees(job);
  var draggableForAssignee = MultipleAssigneesUtils.draggableForAssignee(assignees, bar, wire.ctx.features);
  var match = WeekTimeline_JobDrag.use(job, draggableForAssignee);
  var elementRef = match[0];
  var match$1 = WeekTimeline_JobListeners.use(elementRef, {
        TAG: "Assigned",
        _0: job
      });
  var clickCoordinate = match$1[0];
  var match$2 = Fun.both(clickCoordinate, Types.ReactRef.toOption(elementRef));
  var previewBlock = match$2 !== undefined ? React.createElement(Schedule_TimelineJob.JobPreview.make, {
          close: match$1[1],
          element: match$2[1],
          clickCoordinate: match$2[0],
          job: {
            TAG: "Assigned",
            _0: job
          }
        }) : null;
  var style = WeekTimeline_JobUtils.Style.make(position);
  var className = WeekTimeline_JobUtils.Cn.get(job, position, RCore.$$Option.isSome(clickCoordinate) || match[1], draggableForAssignee);
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  ref: elementRef,
                  className: className,
                  style: style
                }, React.createElement(WeekTimeline_Job$StatusIcon, {
                      job: job,
                      position: position
                    })), previewBlock);
}

var make = React.memo(WeekTimeline_Job);

export {
  make ,
}
/* styles Not a pure module */
