import React from 'react'
import YandexViewport from './viewports/yandex'
import GoogleViewport from './viewports/google'
import { YANDEX, GOOGLE } from 'planado/consts'

const Viewport = ({ type, ...rest }) => {
  switch (type) {
    case YANDEX:
      return <YandexViewport {...rest} />
    case GOOGLE:
      return <GoogleViewport {...rest} />
    default:
      throw `Unknown map type ${type}`
  }
}

export default Viewport
