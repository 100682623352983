import React from 'react'
import classNames from 'classnames'

import WorkerJobItem from './worker_job_item'
import { sortJobs } from 'planado/utils/map'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import * as styles from './styles.module.css'

const JobGroup = props => {
  if (props.jobs.length > 0) {
    const jobs = sortJobs(props.jobs)

    return (
      <div className={styles.jobBlock}>
        <h4>{props.title}</h4>
        <ul>
          {jobs.map(j => (
            <WorkerJobItem
              key={j.id}
              job={j}
              onClick={props.onClick}
              focused={props.focusedJobUuid === j.uuid}
              engineChannel={props.engineChannel}
            />
          ))}
        </ul>
      </div>
    )
  } else {
    return <div />
  }
}

const statuses = ['published', 'en_route', 'started', 'suspended', 'finished']

const Groups = withContext(
  ({ jobs, onJobClick, focusedJobUuid, engineChannel, open, ctx }) => {
    const style = open ? {} : { display: 'none' }

    return (
      <div style={style}>
        {statuses.map(s => (
          <JobGroup
            jobs={Object.values(jobs).filter(j => j.status === s)}
            onClick={onJobClick}
            focusedJobUuid={focusedJobUuid}
            title={ctx.t(`js.map.worker_jobs.${s}_jobs`)}
            key={s}
            engineChannel={engineChannel}
          />
        ))}
      </div>
    )
  }
)

const NoJobs = withContext(({ show, ctx }) => {
  if (show) {
    return (
      <div className={styles.noJobs}>{ctx.t('js.map.no_jobs_for_date')}</div>
    )
  } else {
    return <div />
  }
})

const WorkerJobList = withContext(({ onJobListToggle, ctx, ...props }) => {
  const togglerClass = classNames({
    fa: true,
    'fa-chevron-up': props.open,
    'fa-chevron-down': !props.open
  })

  const length = Object.keys(props.jobs).length

  return (
    <div className={styles.workerJobList}>
      <h3 onClick={onJobListToggle}>
        {ctx.t('js.map.worker_jobs.jobs')}
        <span className={styles.jobsCount}>{length}</span>
        <span className={styles.toggler}>
          <i className={togglerClass} />
        </span>
      </h3>
      <Groups {...props} />
      <NoJobs show={length === 0 && props.open} />
    </div>
  )
})

export default WorkerJobList
