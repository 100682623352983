

import Spinner from "planado/components/input/spinner";
import * as Spinner$1 from "planado/components/input/spinner";

var Stateful = {
  make: Spinner$1.StatefulSpinner
};

var Stateless = {
  make: Spinner
};

export {
  Stateful ,
  Stateless ,
}
/* Stateful Not a pure module */
