

import * as El from "../../../../libraries/El.mjs";
import * as Fun from "../../../../libraries/Fun.mjs";
import * as Icon from "../../../../Icon.mjs";
import * as Link from "../../../common/Link/Link.mjs";
import * as Hooks from "../../../../libraries/hooks/Hooks.mjs";
import * as Modal from "../../../modal/Modal.mjs";
import * as Panel from "../../../common/Panel/Panel.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Types from "../../../../types/Types.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Errors from "../../../../Errors.mjs";
import * as Router from "../../../../Router.mjs";
import * as Select from "../../../inputs/Select.mjs";
import * as Backend from "../../../../libraries/backend/Backend.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as Popover from "../../../common/Popover/Popover.mjs";
import * as ApiRoute from "../../../../types/ApiRoute.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../inputs/Checkbox.mjs";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Optional from "../../../common/Optional.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Bitrix_API from "./Bitrix_API.mjs";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Bitrix_Types from "./Bitrix_Types.mjs";
import * as Context_Types from "../../../../context/Context_Types.mjs";
import * as MessengerTypes from "../../../messenger/MessengerTypes.mjs";
import * as Bitrix_ShowModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/integrations/bitrix24/Bitrix_Show.module.css";

var styles = Bitrix_ShowModuleCss;

var configuringCategory$p = Utils.Translations.tr("js.admin.bitrix24.steps.configure_category");

var connecting$p = Utils.Translations.tr("js.admin.bitrix24.steps.connect");

var docs$p = Utils.Translations.tr("js.admin.bitrix24.docs");

var integrationDisabled$p = Utils.Translations.tr("js.admin.bitrix24.integration_disabled");

var enableNote$p = Utils.Translations.tr("js.admin.bitrix24.enable_integration_note");

var connectWhiteLabel$p = Utils.Translations.tx("js.admin.bitrix24.connect_white_label");

var connect$p = Utils.Translations.t("js.admin.bitrix24.connect");

var supportWhiteLabel$p = Utils.Translations.tx("js.admin.bitrix24.support_html_white_label");

var support$p = Utils.Translations.t("js.admin.bitrix24.support_html");

var applicationWhiteLabel$p = Utils.Translations.tx("admin.bitrix24.planado_application_white_label");

var application$p = Utils.Translations.t("js.admin.bitrix24.planado_application");

var bitrixErrorOccurred$p = Utils.Translations.tr("js.admin.bitrix24.bitrix_error_occurred");

var internalError$p = Utils.Translations.t("js.admin.bitrix24.internal_error");

var installApplication$p = Utils.Translations.tr("js.admin.bitrix24.install_application");

var enterBitrixAddress$p = Utils.Translations.tr("js.admin.bitrix24.enter_bitrix_address");

var checkAdminPrivileges$p = Utils.Translations.tr("js.admin.bitrix24.permission_error");

var unavailableForDomain$p = Utils.Translations.tr("js.admin.bitrix24.unavailable_for_domain");

var unavailableOnFreePlans$p = Utils.Translations.tr("js.admin.bitrix24.unavailable_on_free_plans");

var domainAlreadyInUse$p = Utils.Translations.tr("js.admin.bitrix24.domain_already_in_use");

var installIntegration$p = Utils.Translations.tr("js.admin.bitrix24.install_integration");

var syncDeals$p = Utils.Translations.tr("js.admin.bitrix24.sync_deals");

var removeIntegration$p = Utils.Translations.tr("js.admin.bitrix24.remove_integration");

var save$p = Utils.Translations.tr("common.save");

var add$p = Utils.Translations.tr("common.add");

var remove$p = Utils.Translations.tr("common.remove");

var removeIntegrationQuestion$p = Utils.Translations.tr("js.admin.bitrix24.remove_integration_question");

var consequences$p = Utils.Translations.t("js.admin.bitrix24.consequences");

var cancel$p = Utils.Translations.tr("common.cancel");

var yesRemove$p = Utils.Translations.tr("js.admin.bitrix24.yes_remove");

var selectStage$p = Utils.Translations.t("js.admin.bitrix24.select_stage");

var selectTemplate$p = Utils.Translations.t("js.admin.bitrix24.select_template");

var createClient$p = Utils.Translations.t("js.admin.bitrix24.create_client");

var selectJobField$p = Utils.Translations.t("js.admin.bitrix24.select_job_field");

var selectDealField$p = Utils.Translations.t("js.admin.bitrix24.select_deal_field");

var selectTemplateFirst$p = Utils.Translations.t("js.admin.bitrix24.select_template_first");

var jobCreation$p = Utils.Translations.tr("js.admin.bitrix24.job_creation");

var forBitrixDeals$p = Utils.Translations.tr("js.admin.bitrix24.for_bitrix_deals");

var addStage$p = Utils.Translations.t("js.admin.bitrix24.add_stage");

var addField$p = Utils.Translations.t("js.admin.bitrix24.add_field");

var onDealStage$p = Utils.Translations.tr("js.admin.bitrix24.on_deal_stage");

var createJobByTemplate$p = Utils.Translations.tr("js.admin.bitrix24.create_job_by_template");

var from$p = Utils.Translations.tr("js.admin.bitrix24.from");

var fillJobFields$p = Utils.Translations.tr("js.admin.bitrix24.fill_job_fields");

var onJobCompletionFillDealFields$p = Utils.Translations.tr("js.admin.bitrix24.on_job_completion_fill_deal_fields");

var onJobSuccessfulCompletion$p = Utils.Translations.tr("js.admin.bitrix24.on_job_successful_completion");

var onJobUnsuccessfulCompletion$p = Utils.Translations.tr("js.admin.bitrix24.on_job_unsuccessful_completion");

var chooseDealCategory$p = Utils.Translations.tr("js.admin.bitrix24.choose_deal_category");

var choosingCategory$p = Utils.Translations.tr("js.admin.bitrix24.choosing_category");

var defaultCategory$p = Utils.Translations.t("js.admin.bitrix24.default_category");

var toCategoriesList$p = Utils.Translations.tr("js.admin.bitrix24.to_categories_list");

var toAccountsList$p = Utils.Translations.tr("js.admin.bitrix24.to_accounts_list");

var integrations$p = Utils.Translations.tr("js.titles.integrations.index");

var configuredTypesHint$p = Utils.Translations.tx("js.admin.bitrix24.configured_types_hint");

var address$p = Utils.Translations.t("js.admin.predefined_job_fields.address");

var addressDescription$p = Utils.Translations.t("js.admin.predefined_job_fields.address_description");

var apartment$p = Utils.Translations.t("js.admin.predefined_job_fields.apartment");

var assignee$p = Utils.Translations.t("js.admin.predefined_job_fields.assignee");

var contacts$p = Utils.Translations.t("js.admin.predefined_job_fields.contacts");

var contactName$p = Utils.Translations.t("js.admin.predefined_job_fields.contact_name");

var contactValue$p = Utils.Translations.t("js.admin.predefined_job_fields.contact_value");

var description$p = Utils.Translations.t("js.admin.predefined_job_fields.description");

var entranceNo$p = Utils.Translations.t("js.admin.predefined_job_fields.entrance_no");

var floor$p = Utils.Translations.t("js.admin.predefined_job_fields.floor");

var jobType$p = Utils.Translations.t("js.admin.predefined_job_fields.job_type");

var photoArchiveUrl$p = Utils.Translations.t("js.admin.predefined_job_fields.photo_archive_url");

var scheduledAt$p = Utils.Translations.t("js.admin.predefined_job_fields.scheduled_at");

var scheduledAtDate$p = Utils.Translations.t("js.admin.predefined_job_fields.scheduled_at_date");

var scheduledAtTime$p = Utils.Translations.t("js.admin.predefined_job_fields.scheduled_at_time");

var scheduledDuration$p = Utils.Translations.t("js.admin.predefined_job_fields.scheduled_duration");

var skills$p = Utils.Translations.t("js.admin.predefined_job_fields.skills");

var territory$p = Utils.Translations.t("js.admin.predefined_job_fields.territory");

var enRouteAt$p = Utils.Translations.t("js.admin.predefined_job_fields.en_route_at");

var finishedAt$p = Utils.Translations.t("js.admin.predefined_job_fields.finished_at");

var resolution$p = Utils.Translations.t("js.admin.predefined_job_fields.resolution");

var resolutionComment$p = Utils.Translations.t("js.admin.predefined_job_fields.resolution_comment");

var serialNo$p = Utils.Translations.t("js.admin.predefined_job_fields.serial_no");

var startedAt$p = Utils.Translations.t("js.admin.predefined_job_fields.started_at");

var formContainsErrors$p = Utils.Translations.t("js.errors.form_contains_errors");

var configuringStage$p = Utils.Translations.tr("js.admin.bitrix24.configuring_stage");

var unsupportedFieldMapping$p = Utils.Translations.tr("js.admin.bitrix24.unsupported_field_mapping");

var writeToSupport$p = Utils.Translations.tr("js.admin.bitrix24.write_to_support");

var writeToAddAnotherCrm$p = Utils.Translations.t("js.admin.bitrix24.write_to_add_another_crm");

var writeToAddAnotherCrmWhiteLabel$p = Utils.Translations.tx("js.admin.bitrix24.write_to_add_another_crm_white_label");

var alreadyAddedAbove$p = Utils.Translations.tr("js.admin.bitrix24.already_added_above");

var alreadyMappedJobField$p = Utils.Translations.txr("js.admin.bitrix24.already_mapped_job_field");

var alreadyMappedDealField$p = Utils.Translations.txr("js.admin.bitrix24.already_mapped_deal_field");

var dealToJobError$p = Utils.Translations.tx("js.admin.bitrix24.mapping_errors.to_job_field");

var jobToDealError$p = Utils.Translations.tx("js.admin.bitrix24.mapping_errors.from_job_field");

function dealDataTypes$p(dataTypes, ctx) {
  var dealFieldDataType$p = function (dealFieldDataType) {
    var dataType = Bitrix_Types.DataType.Deal.toString(dealFieldDataType);
    return Utils.Translations.t("js.admin.bitrix24.deal_data_types." + dataType);
  };
  return Utils.Translations.joinWithComma(Core__List.toArray(Core__List.map(dataTypes, (function (t) {
                        return dealFieldDataType$p(t)(ctx);
                      })))) + ".";
}

var buttonId = "bitrix24-options-button";

var removeLinkId = "bitrix24-remove-integration-button";

function Bitrix_Show$Domain(Props) {
  var param = Props.wire;
  var domain = Props.domain;
  var menuOpen = Props.menuOpen;
  var toggleMenu = Props.toggleMenu;
  var sync = Props.sync;
  var remove = Props.remove;
  var bitrixTenantUid = Props.bitrixTenantUid;
  var footer = param.footer;
  var subscriptions = param.subscriptions;
  var ctx = param.ctx;
  var match = React.useState(function () {
        return false;
      });
  var setShowConfirmation = match[1];
  var handleSync = function ($$event) {
    $$event.preventDefault();
    sync();
  };
  var handleToggleMenu = function ($$event) {
    $$event.stopPropagation();
    toggleMenu();
  };
  var confirm = function ($$event) {
    $$event.stopPropagation();
    $$event.preventDefault();
    setShowConfirmation(function (param) {
          return true;
        });
  };
  var hideConfirmation = function ($$event) {
    handleToggleMenu($$event);
    setShowConfirmation(function (param) {
          return false;
        });
  };
  var onRemove = function ($$event) {
    $$event.stopPropagation();
    remove();
  };
  var domainUrl = "https://" + domain;
  React.useEffect((function () {
          return subscriptions.click.subscribe(hideConfirmation);
        }), []);
  return React.createElement("div", {
              className: styles.domain
            }, React.createElement("a", {
                  href: domainUrl,
                  target: "_blank"
                }, React.createElement("i", {
                      className: El.Cn.concat(Icon.style(undefined, undefined, "check"), styles.check)
                    }), domain + " "), React.createElement("button", {
                  className: "btn btn-default btn-more-options dropdown-toggle",
                  id: buttonId,
                  onClick: handleToggleMenu
                }, React.createElement("span", {
                      key: "1",
                      className: Icon.style(undefined, undefined, "circle")
                    }), React.createElement("span", {
                      key: "2",
                      className: Icon.style(undefined, undefined, "circle")
                    }), React.createElement("span", {
                      key: "3",
                      className: Icon.style(undefined, undefined, "circle")
                    })), React.createElement(Panel.make, {
                  footer: footer,
                  show: menuOpen,
                  domId: buttonId,
                  position: "bottomRight",
                  className: styles.dropdown,
                  children: React.createElement("ul", {
                        className: styles.integrationMenu
                      }, React.createElement("li", {
                            id: removeLinkId
                          }, React.createElement("a", {
                                href: ApiRoute.path({
                                      NAME: "API",
                                      VAL: {
                                        NAME: "Bitrix24",
                                        VAL: {
                                          NAME: "SyncDeals",
                                          VAL: bitrixTenantUid
                                        }
                                      }
                                    }),
                                onClick: handleSync
                              }, syncDeals$p(ctx))), React.createElement("li", undefined, React.createElement("a", {
                                href: ApiRoute.path({
                                      NAME: "API",
                                      VAL: {
                                        NAME: "Bitrix24",
                                        VAL: {
                                          NAME: "Show",
                                          VAL: bitrixTenantUid
                                        }
                                      }
                                    }),
                                onClick: confirm
                              }, removeIntegration$p(ctx))))
                }), React.createElement(Popover.make, {
                  footer: footer,
                  show: menuOpen && match[0],
                  buttonId: removeLinkId,
                  position: "left",
                  className: styles.popover,
                  children: React.createElement("div", undefined, React.createElement("h5", undefined, removeIntegrationQuestion$p(ctx)), React.createElement("div", undefined, React.createElement("ul", undefined, consequences$p(ctx).split("\n").map(function (line, idx) {
                                    return React.createElement("li", {
                                                key: idx.toString()
                                              }, line);
                                  }))), React.createElement("footer", undefined, React.createElement("button", {
                                className: "btn btn-default",
                                onClick: hideConfirmation
                              }, cancel$p(ctx)), React.createElement("button", {
                                className: "btn btn-danger",
                                onClick: onRemove
                              }, yesRemove$p(ctx))))
                }));
}

function Bitrix_Show$DealTypeConfiguration$DealStage(Props) {
  var stage = Props.stage;
  var stages = Props.stages;
  var templates = Props.templates;
  var edit = Props.edit;
  var stageName = RCore.$$Option.getOr(RCore.$$Option.flatMap(stage.id, (function (stageId) {
              return RCore.$$Option.map((function (__x) {
                              return Js_array.find((function (param) {
                                            return Types.Id.equal(param.id, stageId);
                                          }), __x);
                            })(stages), (function (param) {
                            return param.name;
                          }));
            })), "");
  var templateName = RCore.$$Option.getOr(RCore.$$Option.flatMap(stage.templateId, (function (templateId) {
              return RCore.$$Option.map((function (__x) {
                              return Js_array.find((function (param) {
                                            return Types.Id.equal(param.id, templateId);
                                          }), __x);
                            })(templates), (function (param) {
                            return param.name;
                          }));
            })), "");
  return React.createElement("div", {
              className: styles.stageConfig
            }, React.createElement("div", undefined, stageName), React.createElement("div", undefined, templateName), React.createElement("button", {
                  className: "btn",
                  onClick: (function (param) {
                      edit();
                    })
                }, React.createElement("i", {
                      className: Icon.style(undefined, undefined, "cog")
                    })));
}

function Bitrix_Show$DealTypeConfiguration$StagesHeader(Props) {
  var ctx = Props.ctx;
  var show = Props.show;
  return React.createElement(Optional.make, {
              show: show,
              children: React.createElement("div", {
                    className: styles.stagesHead
                  }, React.createElement("div", undefined, onDealStage$p(ctx)), React.createElement("div", undefined, createJobByTemplate$p(ctx)))
            });
}

var noStyle = {};

var hidden = {
  maxHeight: "0",
  overflow: "hidden"
};

function Bitrix_Show$DealTypeConfiguration(Props) {
  var param = Props.wire;
  var dealType = Props.dealType;
  var templates = Props.templates;
  var addStage = Props.addStage;
  var stages = Props.stages;
  var configure = Props.configure;
  var editStage = Props.editStage;
  var ctx = param.ctx;
  return React.createElement("div", {
              className: styles.dealType
            }, React.createElement("div", {
                  className: styles.dealName
                }, React.createElement(Checkbox.make, {
                      onToggle: (function (param) {
                          configure(dealType.id);
                        }),
                      checked: dealType.configured,
                      label: dealType.name
                    })), React.createElement("div", {
                  className: styles.dealStages,
                  style: dealType.configured ? noStyle : hidden
                }, React.createElement("div", undefined, React.createElement(Bitrix_Show$DealTypeConfiguration$StagesHeader, {
                          ctx: ctx,
                          show: dealType.stages.length > 0
                        }), dealType.stages.map(function (s, idx) {
                          return React.createElement(Bitrix_Show$DealTypeConfiguration$DealStage, {
                                      stage: s,
                                      stages: stages,
                                      templates: templates,
                                      edit: (function () {
                                          editStage(idx);
                                        }),
                                      key: idx.toString()
                                    });
                        }), React.createElement("div", {
                          className: styles.addStage
                        }, React.createElement("button", {
                              className: El.Cn.concat("btn-link add-link", styles.addStage),
                              onClick: (function (evt) {
                                  evt.target.blur();
                                  addStage();
                                })
                            }, React.createElement("i", {
                                  className: Icon.style(undefined, undefined, "plus")
                                }), " " + addStage$p(ctx))))));
}

function stageVariants(stages) {
  return stages.map(function (param) {
              return {
                      value: param.id,
                      label: param.name
                    };
            });
}

function templateVariants(templates) {
  return templates.map(function (param) {
              return {
                      value: param.id,
                      label: param.name
                    };
            });
}

function dealFieldVariants(dealFields) {
  return dealFields.map(function (field) {
              return {
                      value: Bitrix_Types.ConfiguredStage.DealField.makeId(Bitrix_Types.ConfiguredStage.DealField.makeDealField(field)),
                      label: field.name
                    };
            });
}

var predefinedFieldsDestination = [
  "Description",
  "Type",
  "Assignee",
  "ScheduledAt",
  "ScheduledAtDate",
  "ScheduledAtTime",
  "ScheduledDuration",
  "Address",
  "AddressDescription",
  "Apartment",
  "Floor",
  "EntranceNo",
  "Contacts",
  "ContactName",
  "ContactValue",
  "Skills",
  "Territory"
];

var predefinedFieldsSource = [
  "Resolution",
  "ResolutionComment",
  "SerialNo",
  "Assignee",
  "StartedAt",
  "EnRouteAt",
  "FinishedAt",
  "PhotoArchiveUrlShared"
];

function predefinedField$p(field, ctx) {
  switch (field) {
    case "Address" :
        return address$p(ctx);
    case "AddressDescription" :
        return addressDescription$p(ctx);
    case "Apartment" :
        return apartment$p(ctx);
    case "Assignee" :
        return assignee$p(ctx);
    case "Contacts" :
        return contacts$p(ctx);
    case "ContactName" :
        return contactName$p(ctx);
    case "ContactValue" :
        return contactValue$p(ctx);
    case "Description" :
        return description$p(ctx);
    case "EnRouteAt" :
        return enRouteAt$p(ctx);
    case "EntranceNo" :
        return entranceNo$p(ctx);
    case "FinishedAt" :
        return finishedAt$p(ctx);
    case "Floor" :
        return floor$p(ctx);
    case "PhotoArchiveUrlShared" :
        return photoArchiveUrl$p(ctx);
    case "Resolution" :
        return resolution$p(ctx);
    case "ResolutionComment" :
        return resolutionComment$p(ctx);
    case "ScheduledAt" :
        return scheduledAt$p(ctx);
    case "ScheduledAtDate" :
        return scheduledAtDate$p(ctx);
    case "ScheduledAtTime" :
        return scheduledAtTime$p(ctx);
    case "ScheduledDuration" :
        return scheduledDuration$p(ctx);
    case "SerialNo" :
        return serialNo$p(ctx);
    case "Skills" :
        return skills$p(ctx);
    case "StartedAt" :
        return startedAt$p(ctx);
    case "Territory" :
        return territory$p(ctx);
    case "Type" :
        return jobType$p(ctx);
    
  }
}

function toJobFieldVariants(fields, configuredFields, ctx) {
  var fullScheduledAtConfigured = configuredFields.some(function (f) {
        var match = f[0];
        if (match === undefined) {
          return false;
        }
        switch (match.TAG) {
          case "Custom" :
          case "Report" :
              return false;
          case "Predefined" :
              if (match._0 === "ScheduledAt") {
                return true;
              } else {
                return false;
              }
          
        }
      });
  var combinedScheduledAtConfigured = configuredFields.some(function (f) {
        var match = f[0];
        if (match === undefined) {
          return false;
        }
        switch (match.TAG) {
          case "Custom" :
          case "Report" :
              return false;
          case "Predefined" :
              switch (match._0) {
                case "ScheduledAtDate" :
                case "ScheduledAtTime" :
                    return true;
                default:
                  return false;
              }
          
        }
      });
  return Belt_Array.concatMany([
              RCore.$$Array.keepMap(predefinedFieldsDestination, (function (f) {
                      switch (f) {
                        case "ScheduledAt" :
                            if (combinedScheduledAtConfigured) {
                              return ;
                            }
                            break;
                        case "ScheduledAtDate" :
                        case "ScheduledAtTime" :
                            if (fullScheduledAtConfigured) {
                              return ;
                            }
                            break;
                        default:
                          
                      }
                      return {
                              value: Bitrix_Types.ConfiguredStage.JobField.makeId(Bitrix_Types.ConfiguredStage.JobField.makePredefined(f)),
                              label: predefinedField$p(f, ctx)
                            };
                    })),
              fields.map(function (field) {
                    return {
                            value: Bitrix_Types.ConfiguredStage.JobField.makeId(Bitrix_Types.ConfiguredStage.JobField.makeCustom(field)),
                            label: field.name
                          };
                  })
            ]);
}

function fromJobFieldVariants(fields, ctx) {
  return Belt_Array.concatMany([
              predefinedFieldsSource.map(function (f) {
                    return {
                            value: Bitrix_Types.ConfiguredStage.JobField.makeId(Bitrix_Types.ConfiguredStage.JobField.makePredefined(f)),
                            label: predefinedField$p(f, ctx)
                          };
                  }),
              fields.map(function (field) {
                    return {
                            value: Bitrix_Types.ConfiguredStage.JobField.makeId(Bitrix_Types.ConfiguredStage.JobField.makeReport(field)),
                            label: field.name
                          };
                  })
            ]);
}

var newFieldMapping = [
  undefined,
  undefined
];

function Bitrix_Show$DealStageWindow$StageForm(Props) {
  var ctx = Props.ctx;
  var stage = Props.stage;
  var stages = Props.stages;
  var templates = Props.templates;
  var dealFields = Props.dealFields;
  var update = Props.update;
  var containerClassName = styles.stageWithFields;
  var fieldTypeMappingWarn = function (jobField, dealField, templateFields, fieldMapping) {
    if (jobField === undefined) {
      return null;
    }
    if (dealField === undefined) {
      return null;
    }
    var jobFieldDataType;
    var exit = 0;
    switch (jobField.TAG) {
      case "Custom" :
      case "Report" :
          exit = 1;
          break;
      case "Predefined" :
          jobFieldDataType = Bitrix_Types.ConfiguredStage.JobField.PredefinedField.toDataType(jobField._0);
          break;
      
    }
    if (exit === 1) {
      var id = jobField._0;
      var field = RCore.$$Array.getBy(templateFields, (function (f) {
              return Caml_obj.equal(f.uuid, id);
            }));
      jobFieldDataType = RCore.$$Option.getOr(RCore.$$Option.map(field, (function (f) {
                  return f.dataType;
                })), "Unknown");
    }
    var dealFieldDataType = Bitrix_Types.DataType.Deal.fromString(dealField._0.dataType);
    var canMap;
    canMap = fieldMapping === "DealToJob" ? Bitrix_Types.DataType.DealToJob.canMapToJobField(dealFieldDataType, jobFieldDataType) : Bitrix_Types.DataType.JobToDeal.canMapToDealField(dealFieldDataType, jobFieldDataType);
    var buildMessage = function () {
      if (fieldMapping === "DealToJob") {
        var supportedDataTypes = Bitrix_Types.DataType.DealToJob.listDealDataTypesForJobType(jobFieldDataType);
        if (!supportedDataTypes) {
          return unsupportedFieldMapping$p(ctx);
        }
        var typesStr = dealDataTypes$p(supportedDataTypes, ctx);
        var baseStr = dealToJobError$p({
              count: Core__List.length(supportedDataTypes)
            }, ctx);
        return baseStr + typesStr;
      }
      var supportedDataTypes$1 = Bitrix_Types.DataType.JobToDeal.listDealDataTypesForJobType(jobFieldDataType);
      if (!supportedDataTypes$1) {
        return unsupportedFieldMapping$p(ctx);
      }
      var typesStr$1 = dealDataTypes$p(supportedDataTypes$1, ctx);
      var baseStr$1 = jobToDealError$p({
            count: Core__List.length(supportedDataTypes$1)
          }, ctx);
      return baseStr$1 + typesStr$1;
    };
    return React.createElement(Optional.make, {
                show: !canMap,
                children: React.createElement(React.Fragment, undefined, React.createElement("p", {
                          className: styles.warnFieldType
                        }, buildMessage()), React.createElement("p", {
                          className: styles.warnFieldType
                        }, writeToSupport$p(ctx)))
              });
  };
  var alreadyMappedJobFieldWarn = function (jobField, index) {
    if (jobField === undefined) {
      return null;
    }
    var firstMatchIndex = RCore.$$Array.reduceWithIndex(stage.jobFields.map(function (prim) {
              return prim[0];
            }), undefined, (function (acc, field, idx) {
            if (acc !== undefined) {
              return acc;
            } else if (Caml_obj.equal(field, jobField)) {
              return idx;
            } else {
              return ;
            }
          }));
    if (firstMatchIndex === undefined) {
      return null;
    }
    if (index <= firstMatchIndex) {
      return null;
    }
    var dealField = RCore.$$Option.getOr(stage.jobFields.map(function (prim) {
                return prim[1];
              })[firstMatchIndex], undefined);
    if (dealField === undefined) {
      return React.createElement("p", {
                  className: styles.warnSameFields
                }, alreadyAddedAbove$p(ctx));
    }
    var id = dealField._0.id;
    var bitrixFieldName = RCore.$$Option.getOr(RCore.$$Option.map(RCore.$$Array.getBy(dealFields, (function (a) {
                    return Caml_obj.equal(a.id, id);
                  })), (function (f) {
                return f.name;
              })), "");
    return React.createElement("p", {
                className: styles.warnSameFields
              }, alreadyMappedJobField$p({
                    name: bitrixFieldName
                  }, ctx));
  };
  var alreadyMappedDealFieldWarn = function (dealField, index, templateFields) {
    if (dealField === undefined) {
      return null;
    }
    var firstMatchIndex = RCore.$$Array.reduceWithIndex(stage.dealFields.map(function (prim) {
              return prim[0];
            }), undefined, (function (acc, field, idx) {
            if (acc !== undefined) {
              return acc;
            } else if (Caml_obj.equal(field, dealField)) {
              return idx;
            } else {
              return ;
            }
          }));
    if (firstMatchIndex === undefined) {
      return null;
    }
    if (index <= firstMatchIndex) {
      return null;
    }
    var jobField = RCore.$$Option.getOr(stage.dealFields.map(function (prim) {
                return prim[1];
              })[firstMatchIndex], undefined);
    if (jobField === undefined) {
      return React.createElement("p", {
                  className: styles.warnSameFields
                }, alreadyAddedAbove$p(ctx));
    }
    var jobFieldName;
    var exit = 0;
    switch (jobField.TAG) {
      case "Custom" :
      case "Report" :
          exit = 1;
          break;
      case "Predefined" :
          jobFieldName = predefinedField$p(jobField._0, ctx);
          break;
      
    }
    if (exit === 1) {
      var id = jobField._0;
      jobFieldName = RCore.$$Option.getOr(RCore.$$Option.map(RCore.$$Array.getBy(templateFields, (function (f) {
                      return Caml_obj.equal(f.uuid, id);
                    })), (function (f) {
                  return f.name;
                })), "");
    }
    return React.createElement("p", {
                className: styles.warnSameFields
              }, alreadyMappedDealField$p({
                    name: jobFieldName
                  }, ctx));
  };
  var toggleCreateClient = function () {
    update({
          id: stage.id,
          templateId: stage.templateId,
          jobFields: stage.jobFields,
          dealFields: stage.dealFields,
          onSuccess: stage.onSuccess,
          onFailure: stage.onFailure,
          createClient: !stage.createClient
        });
  };
  var onChangeTemplate = function (id) {
    var templateId = id === null ? undefined : Caml_option.some(id);
    if (Caml_obj.equal(templateId, stage.templateId)) {
      return ;
    }
    var jobFieldMappingWithoutTemplateFields = stage.jobFields.map(function (param) {
          var dealField = param[1];
          var jobField = param[0];
          if (jobField === undefined) {
            return [
                    undefined,
                    dealField
                  ];
          }
          switch (jobField.TAG) {
            case "Custom" :
            case "Report" :
                return [
                        undefined,
                        dealField
                      ];
            case "Predefined" :
                return [
                        jobField,
                        dealField
                      ];
            
          }
        });
    var dealFieldMappingWithoutTemplateFields = stage.dealFields.map(function (param) {
          var jobField = param[1];
          var dealField = param[0];
          if (jobField === undefined) {
            return [
                    dealField,
                    undefined
                  ];
          }
          switch (jobField.TAG) {
            case "Custom" :
            case "Report" :
                return [
                        dealField,
                        undefined
                      ];
            case "Predefined" :
                return [
                        dealField,
                        jobField
                      ];
            
          }
        });
    update({
          id: stage.id,
          templateId: templateId,
          jobFields: jobFieldMappingWithoutTemplateFields,
          dealFields: dealFieldMappingWithoutTemplateFields,
          onSuccess: stage.onSuccess,
          onFailure: stage.onFailure,
          createClient: stage.createClient
        });
  };
  var templateFields = RCore.$$Option.getOr(RCore.$$Option.map(RCore.$$Option.flatMap(stage.templateId, (function (templateId) {
                  return Js_array.find((function (param) {
                                return Types.Id.equal(templateId, param.id);
                              }), templates);
                })), (function (param) {
              return param.fields;
            })), []);
  var templateFields$1 = RCore.$$Option.getOr(RCore.$$Option.map(RCore.$$Option.flatMap(stage.templateId, (function (templateId) {
                  return Js_array.find((function (param) {
                                return Types.Id.equal(templateId, param.id);
                              }), templates);
                })), (function (param) {
              return param.reportFields;
            })), []);
  return React.createElement("div", {
              className: containerClassName
            }, React.createElement("div", {
                  className: "pd-form-group pd-form-group-full"
                }, React.createElement("label", {
                      className: "pd-label"
                    }, onDealStage$p(ctx)), React.createElement(Select.make, {
                      placeholder: selectStage$p(ctx),
                      onChange: (function (id) {
                          update({
                                id: id === null ? undefined : Caml_option.some(id),
                                templateId: stage.templateId,
                                jobFields: stage.jobFields,
                                dealFields: stage.dealFields,
                                onSuccess: stage.onSuccess,
                                onFailure: stage.onFailure,
                                createClient: stage.createClient
                              });
                        }),
                      value: Js_null.fromOption(stage.id),
                      clearable: false,
                      options: stageVariants(stages)
                    })), React.createElement("div", {
                  className: "pd-form-group pd-form-group-full"
                }, React.createElement("label", {
                      className: "pd-label"
                    }, createJobByTemplate$p(ctx)), React.createElement(Select.make, {
                      placeholder: selectTemplate$p(ctx),
                      onChange: onChangeTemplate,
                      value: Js_null.fromOption(stage.templateId),
                      clearable: false,
                      options: templateVariants(templates)
                    })), React.createElement("div", {
                  className: "pd-form-group pd-form-group-full"
                }, React.createElement(Checkbox.make, {
                      onToggle: (function (param) {
                          toggleCreateClient();
                        }),
                      checked: stage.createClient,
                      label: createClient$p(ctx)
                    })), React.createElement("div", {
                  className: "pd-form-group pd-form-group-full"
                }, React.createElement("label", {
                      className: "pd-label"
                    }, fillJobFields$p(ctx)), stage.jobFields.map(function (param, idx) {
                      var dealFieldId = param[1];
                      var jobFieldId = param[0];
                      return React.createElement("div", {
                                  key: "job-fields-" + idx.toString()
                                }, React.createElement("div", {
                                      key: idx.toString(),
                                      className: styles.fieldConfiguration
                                    }, React.createElement(Select.make, {
                                          placeholder: RCore.$$Option.isNone(stage.templateId) ? selectTemplateFirst$p(ctx) : selectJobField$p(ctx),
                                          onChange: (function (id) {
                                              var field = id !== null ? Bitrix_Types.ConfiguredStage.JobField.fromId(id, templateFields) : undefined;
                                              var jobFields = Belt_Array.concatMany([stage.jobFields]);
                                              jobFields[idx] = [
                                                field,
                                                dealFieldId
                                              ];
                                              update({
                                                    id: stage.id,
                                                    templateId: stage.templateId,
                                                    jobFields: jobFields,
                                                    dealFields: stage.dealFields,
                                                    onSuccess: stage.onSuccess,
                                                    onFailure: stage.onFailure,
                                                    createClient: stage.createClient
                                                  });
                                            }),
                                          value: Js_null.fromOption(RCore.$$Option.map(jobFieldId, Bitrix_Types.ConfiguredStage.JobField.makeId)),
                                          disabled: RCore.$$Option.isNone(stage.templateId),
                                          clearable: false,
                                          options: toJobFieldVariants(templateFields, stage.jobFields, ctx),
                                          className: Js_null.fromOption(styles.fieldSelect)
                                        }), React.createElement("div", {
                                          className: styles.from
                                        }, from$p(ctx)), React.createElement(Select.make, {
                                          placeholder: selectDealField$p(ctx),
                                          onChange: (function (id) {
                                              var field = id !== null ? Bitrix_Types.ConfiguredStage.DealField.fromId(id, dealFields) : undefined;
                                              var jobFields = Belt_Array.concatMany([stage.jobFields]);
                                              jobFields[idx] = [
                                                jobFieldId,
                                                field
                                              ];
                                              update({
                                                    id: stage.id,
                                                    templateId: stage.templateId,
                                                    jobFields: jobFields,
                                                    dealFields: stage.dealFields,
                                                    onSuccess: stage.onSuccess,
                                                    onFailure: stage.onFailure,
                                                    createClient: stage.createClient
                                                  });
                                            }),
                                          value: Js_null.fromOption(RCore.$$Option.map(dealFieldId, Bitrix_Types.ConfiguredStage.DealField.makeId)),
                                          clearable: false,
                                          options: dealFieldVariants(dealFields),
                                          className: Js_null.fromOption(styles.fieldSelect)
                                        }), React.createElement("button", {
                                          className: "btn " + styles.removeFieldBtn,
                                          onClick: (function (param) {
                                              var jobFields = RCore.$$Array.removeOne(stage.jobFields, idx);
                                              update({
                                                    id: stage.id,
                                                    templateId: stage.templateId,
                                                    jobFields: jobFields,
                                                    dealFields: stage.dealFields,
                                                    onSuccess: stage.onSuccess,
                                                    onFailure: stage.onFailure,
                                                    createClient: stage.createClient
                                                  });
                                            })
                                        }, React.createElement("i", {
                                              className: Icon.style(undefined, undefined, "trash")
                                            }))), React.createElement("div", {
                                      className: styles.mappingWarnings
                                    }, alreadyMappedJobFieldWarn(jobFieldId, idx), fieldTypeMappingWarn(jobFieldId, dealFieldId, templateFields, "DealToJob")));
                    }), React.createElement("div", {
                      className: styles.addField
                    }, React.createElement("button", {
                          className: "btn-link add-link",
                          onClick: (function (evt) {
                              evt.target.blur();
                              update({
                                    id: stage.id,
                                    templateId: stage.templateId,
                                    jobFields: Belt_Array.concatMany([
                                          stage.jobFields,
                                          [newFieldMapping]
                                        ]),
                                    dealFields: stage.dealFields,
                                    onSuccess: stage.onSuccess,
                                    onFailure: stage.onFailure,
                                    createClient: stage.createClient
                                  });
                            })
                        }, React.createElement("i", {
                              className: Icon.style(undefined, undefined, "plus")
                            }), " " + addField$p(ctx)))), React.createElement("div", {
                  className: "pd-form-group pd-form-group-full"
                }, React.createElement("label", {
                      className: "pd-label"
                    }, onJobCompletionFillDealFields$p(ctx)), stage.dealFields.map(function (param, idx) {
                      var jobFieldId = param[1];
                      var dealFieldId = param[0];
                      return React.createElement("div", {
                                  key: "deal-fields-" + idx.toString()
                                }, React.createElement("div", {
                                      key: idx.toString(),
                                      className: styles.fieldConfiguration
                                    }, React.createElement(Select.make, {
                                          placeholder: selectDealField$p(ctx),
                                          onChange: (function (id) {
                                              var field = id !== null ? Bitrix_Types.ConfiguredStage.DealField.fromId(id, dealFields) : undefined;
                                              var dealFields$1 = Belt_Array.concatMany([stage.dealFields]);
                                              dealFields$1[idx] = [
                                                field,
                                                jobFieldId
                                              ];
                                              update({
                                                    id: stage.id,
                                                    templateId: stage.templateId,
                                                    jobFields: stage.jobFields,
                                                    dealFields: dealFields$1,
                                                    onSuccess: stage.onSuccess,
                                                    onFailure: stage.onFailure,
                                                    createClient: stage.createClient
                                                  });
                                            }),
                                          value: Js_null.fromOption(RCore.$$Option.map(dealFieldId, Bitrix_Types.ConfiguredStage.DealField.makeId)),
                                          clearable: false,
                                          options: dealFieldVariants(RCore.$$Array.keep(dealFields, (function (f) {
                                                      return !f.readOnly;
                                                    }))),
                                          className: Js_null.fromOption(styles.fieldSelect)
                                        }), React.createElement("div", {
                                          className: styles.from
                                        }, from$p(ctx)), React.createElement(Select.make, {
                                          placeholder: RCore.$$Option.isNone(stage.templateId) ? selectTemplateFirst$p(ctx) : selectJobField$p(ctx),
                                          onChange: (function (id) {
                                              var field = id !== null ? Bitrix_Types.ConfiguredStage.JobField.fromId(id, templateFields$1) : undefined;
                                              var dealFields = Belt_Array.concatMany([stage.dealFields]);
                                              dealFields[idx] = [
                                                dealFieldId,
                                                field
                                              ];
                                              update({
                                                    id: stage.id,
                                                    templateId: stage.templateId,
                                                    jobFields: stage.jobFields,
                                                    dealFields: dealFields,
                                                    onSuccess: stage.onSuccess,
                                                    onFailure: stage.onFailure,
                                                    createClient: stage.createClient
                                                  });
                                            }),
                                          value: Js_null.fromOption(RCore.$$Option.map(jobFieldId, Bitrix_Types.ConfiguredStage.JobField.makeId)),
                                          disabled: RCore.$$Option.isNone(stage.templateId),
                                          clearable: false,
                                          options: fromJobFieldVariants(templateFields$1, ctx),
                                          className: Js_null.fromOption(styles.fieldSelect)
                                        }), React.createElement("button", {
                                          className: "btn " + styles.removeFieldBtn,
                                          onClick: (function (param) {
                                              var dealFields = RCore.$$Array.removeOne(stage.dealFields, idx);
                                              update({
                                                    id: stage.id,
                                                    templateId: stage.templateId,
                                                    jobFields: stage.jobFields,
                                                    dealFields: dealFields,
                                                    onSuccess: stage.onSuccess,
                                                    onFailure: stage.onFailure,
                                                    createClient: stage.createClient
                                                  });
                                            })
                                        }, React.createElement("i", {
                                              className: Icon.style(undefined, undefined, "trash")
                                            }))), React.createElement("div", {
                                      className: styles.mappingWarnings
                                    }, alreadyMappedDealFieldWarn(dealFieldId, idx, templateFields$1), fieldTypeMappingWarn(jobFieldId, dealFieldId, templateFields$1, "JobToDeal")));
                    }), React.createElement("div", {
                      className: styles.addField
                    }, React.createElement("button", {
                          className: "btn-link add-link",
                          onClick: (function (evt) {
                              evt.target.blur();
                              update({
                                    id: stage.id,
                                    templateId: stage.templateId,
                                    jobFields: stage.jobFields,
                                    dealFields: Belt_Array.concatMany([
                                          stage.dealFields,
                                          [newFieldMapping]
                                        ]),
                                    onSuccess: stage.onSuccess,
                                    onFailure: stage.onFailure,
                                    createClient: stage.createClient
                                  });
                            })
                        }, React.createElement("i", {
                              className: Icon.style(undefined, undefined, "plus")
                            }), " " + addField$p(ctx)))), React.createElement("div", {
                  className: "pd-form-group pd-form-group-full"
                }, React.createElement("label", {
                      className: "pd-label"
                    }, onJobSuccessfulCompletion$p(ctx)), React.createElement(Select.make, {
                      placeholder: selectStage$p(ctx),
                      onChange: (function (value) {
                          update({
                                id: stage.id,
                                templateId: stage.templateId,
                                jobFields: stage.jobFields,
                                dealFields: stage.dealFields,
                                onSuccess: value === null ? undefined : Caml_option.some(value),
                                onFailure: stage.onFailure,
                                createClient: stage.createClient
                              });
                        }),
                      value: Js_null.fromOption(stage.onSuccess),
                      clearable: true,
                      options: stageVariants(stages)
                    })), React.createElement("div", {
                  className: "pd-form-group pd-form-group-full"
                }, React.createElement("label", {
                      className: "pd-label"
                    }, onJobUnsuccessfulCompletion$p(ctx)), React.createElement(Select.make, {
                      placeholder: selectStage$p(ctx),
                      onChange: (function (value) {
                          update({
                                id: stage.id,
                                templateId: stage.templateId,
                                jobFields: stage.jobFields,
                                dealFields: stage.dealFields,
                                onSuccess: stage.onSuccess,
                                onFailure: value === null ? undefined : Caml_option.some(value),
                                createClient: stage.createClient
                              });
                        }),
                      value: Js_null.fromOption(stage.onFailure),
                      clearable: true,
                      options: stageVariants(stages)
                    })));
}

var newStage_jobFields = [];

var newStage_dealFields = [];

var newStage = {
  id: undefined,
  templateId: undefined,
  jobFields: newStage_jobFields,
  dealFields: newStage_dealFields,
  onSuccess: undefined,
  onFailure: undefined,
  createClient: true
};

function Bitrix_Show$DealStageWindow(Props) {
  var ctx = Props.ctx;
  var updateStage = Props.updateStage;
  var removeStage = Props.removeStage;
  var templates = Props.templates;
  var dealFields = Props.dealFields;
  var stages = Props.stages;
  var stage = Props.stage;
  var new_ = RCore.$$Option.isNone(stage);
  var match = React.useState(function () {
        return RCore.$$Option.getOr(stage, newStage);
      });
  var setStage = match[1];
  var stage$1 = match[0];
  return React.createElement(Modal.Dialog.make, {
              children: null
            }, React.createElement(Modal.Header.make, {
                  children: React.createElement("h1", undefined, configuringStage$p(ctx))
                }), React.createElement(Modal.Body.make, {
                  style: {
                    maxWidth: "680px",
                    overflow: "auto"
                  },
                  children: React.createElement(Bitrix_Show$DealStageWindow$StageForm, {
                        ctx: ctx,
                        stage: stage$1,
                        stages: stages,
                        templates: templates,
                        dealFields: dealFields,
                        update: (function (s) {
                            setStage(function (param) {
                                  return s;
                                });
                          })
                      })
                }), React.createElement(Modal.Footer.make, {
                  children: null
                }, new_ ? null : React.createElement(Modal.RemoveButton.make, {
                        onClick: (function (param) {
                            removeStage();
                          }),
                        children: remove$p(ctx)
                      }), React.createElement(Modal.CloseButton.make, {
                      ctx: ctx
                    }), React.createElement(Modal.SubmitButton.make, {
                      onClick: (function (param) {
                          updateStage(stage$1);
                        }),
                      children: new_ ? add$p(ctx) : save$p(ctx)
                    })));
}

function Bitrix_Show$DocsLink(Props) {
  var ctx = Props.ctx;
  var link = function (href) {
    return React.createElement("a", {
                href: href,
                target: "blank"
              }, docs$p(ctx), El.space, React.createElement("i", {
                    className: Icon.style(undefined, undefined, "external-link-alt")
                  }));
  };
  return React.createElement("div", undefined, link(ctx.localizator.lang === "ru" ? "https://planado.freshdesk.com/ru-RU/support/solutions/folders/13000010704" : "https://planado.freshdesk.com/en/support/solutions/folders/13000010704"));
}

var defaultCategoryId = Types.Id.fromString("0");

function Bitrix_Show(Props) {
  var wire = Props.wire;
  var initialTenantUid = Props.bitrixTenantUid;
  var pathCategory = Props.pathCategory;
  var messenger = wire.subscriptions.messenger;
  var ctx = wire.ctx;
  var match = Hooks.useLocked(false);
  var unlock = match[2];
  var lock = match[1];
  var locked = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setLoaded = match$1[1];
  var match$2 = React.useState(function () {
        
      });
  var setOpenStage = match$2[1];
  var openStage = match$2[0];
  var match$3 = React.useState(function () {
        
      });
  var setTenantUid = match$3[1];
  var tenantUid = match$3[0];
  var match$4 = React.useState(function () {
        return false;
      });
  var setIntegrationMenuOpen = match$4[1];
  var integrationMenuOpen = match$4[0];
  var match$5 = React.useState(function () {
        var parseParams = function (query) {
          var q = Core__List.map(Core__List.fromArray(Js_string.substr(1, query).split("&")), (function (q) {
                  return Core__List.fromArray(Js_string.split("=", q));
                }));
          if (!q) {
            return ;
          }
          var match = q.hd;
          if (!match) {
            return ;
          }
          if (match.hd !== "error") {
            return ;
          }
          var match$1 = match.tl;
          if (!match$1) {
            return ;
          }
          if (match$1.tl) {
            return ;
          }
          var match$2 = q.tl;
          if (!match$2) {
            return ;
          }
          var match$3 = match$2.hd;
          if (!match$3) {
            return ;
          }
          var error = match$1.hd;
          if (match$3.hd !== "domain") {
            return ;
          }
          var match$4 = match$3.tl;
          if (!match$4) {
            return ;
          }
          if (match$4.tl) {
            return ;
          }
          var match$5 = match$2.tl;
          var domain = match$4.hd;
          if (!match$5) {
            return [
                    error,
                    domain,
                    undefined
                  ];
          }
          var match$6 = match$5.hd;
          if (!match$6) {
            return ;
          }
          if (match$6.hd !== "message") {
            return ;
          }
          var match$7 = match$6.tl;
          if (match$7 && !(match$7.tl || match$5.tl)) {
            return [
                    error,
                    domain,
                    Js_string.replaceByRe(/[+]/g, " ", match$7.hd)
                  ];
          }
          
        };
        var params = parseParams(Router.search());
        var bitrixAddress = params !== undefined ? params[1] : undefined;
        var errors;
        if (params !== undefined) {
          var message = params[2];
          var error = params[0];
          errors = message !== undefined ? ({
                hd: [
                  error,
                  message
                ],
                tl: /* [] */0
              }) : (
              Js_string.includes("unavailable_on_free_plans", error) ? ({
                    hd: [
                      "permission_error",
                      "unavailable_on_free_plans"
                    ],
                    tl: /* [] */0
                  }) : (
                  Js_string.includes("domain_already_in_use", error) ? ({
                        hd: [
                          "permission_error",
                          "domain_already_in_use"
                        ],
                        tl: /* [] */0
                      }) : (
                      Js_string.includes("unavailable_for_domain", error) ? ({
                            hd: [
                              "permission_error",
                              "unavailable_for_domain"
                            ],
                            tl: /* [] */0
                          }) : ({
                            hd: [
                              "permission_error",
                              "permission_error"
                            ],
                            tl: /* [] */0
                          })
                    )
                )
            );
        } else {
          errors = /* [] */0;
        }
        return {
                bitrix24Settings: undefined,
                enabled: false,
                applicationUrl: undefined,
                suggest: "",
                errors: errors,
                bitrixAddress: bitrixAddress,
                currentCategory: undefined
              };
      });
  var setState = match$5[1];
  var state = match$5[0];
  var responseToCreate = function (data) {
    if (data.TAG === "Ok") {
      return Router.redirect(data._0.redirectUrl);
    }
    var match = data._0;
    if (typeof match !== "object") {
      return unlock();
    }
    if (match.TAG !== "HTTPError") {
      return unlock();
    }
    var match$1 = match._0;
    var tmp = match$1[0];
    if (typeof tmp === "object") {
      return unlock();
    }
    if (tmp !== "UnprocessableEntity") {
      return unlock();
    }
    var text = match$1[1];
    unlock();
    setState(function (s) {
          return {
                  bitrix24Settings: s.bitrix24Settings,
                  enabled: s.enabled,
                  applicationUrl: s.applicationUrl,
                  suggest: s.suggest,
                  errors: Errors.parse(text),
                  bitrixAddress: s.bitrixAddress,
                  currentCategory: s.currentCategory
                };
        });
  };
  var createIntegration = function () {
    if (locked) {
      return ;
    }
    var address = state.bitrixAddress;
    if (address !== undefined) {
      lock();
      return Backend.$$finally(Bitrix_API.Show.create(wire, address), responseToCreate);
    }
    
  };
  var responseToLoad = function (data) {
    if (data.TAG !== "Ok") {
      return unlock();
    }
    var match = data._0;
    var bitrixTenantUid = match.bitrixTenantUid;
    var error = match.error;
    var suggest = match.suggest;
    var applicationUrl = match.applicationUrl;
    var enabled = match.enabled;
    var settings = match.settings;
    if (bitrixTenantUid !== undefined) {
      if (initialTenantUid === "primary") {
        wire.navigate(undefined, {
              NAME: "Integrations",
              VAL: {
                NAME: "Bitrix24",
                VAL: {
                  NAME: "Edit",
                  VAL: {
                    category: undefined,
                    bitrixTenantUid: bitrixTenantUid
                  }
                }
              }
            });
      }
      
    } else if (initialTenantUid === "primary") {
      wire.navigate(undefined, {
            NAME: "Integrations",
            VAL: {
              NAME: "Bitrix24",
              VAL: "New"
            }
          });
    }
    unlock();
    setLoaded(function (param) {
          return true;
        });
    setTenantUid(function (param) {
          return bitrixTenantUid;
        });
    setState(function (s) {
          var tmp;
          if (error !== undefined) {
            switch (error) {
              case "internal_server_error" :
                  tmp = {
                    hd: [
                      "Error",
                      internalError$p(ctx)
                    ],
                    tl: /* [] */0
                  };
                  break;
              case "unavailable_on_free_plans" :
                  tmp = {
                    hd: [
                      "permission_error",
                      error
                    ],
                    tl: /* [] */0
                  };
                  break;
              default:
                var match = Js_string.split(": ", error);
                var len = match.length;
                if (len >= 3) {
                  tmp = /* [] */0;
                } else {
                  switch (len) {
                    case 0 :
                        tmp = /* [] */0;
                        break;
                    case 1 :
                        var message = match[0];
                        tmp = {
                          hd: [
                            "Bitrix error",
                            message
                          ],
                          tl: /* [] */0
                        };
                        break;
                    case 2 :
                        var errorType = match[0];
                        var message$1 = match[1];
                        tmp = {
                          hd: [
                            errorType,
                            message$1
                          ],
                          tl: /* [] */0
                        };
                        break;
                    
                  }
                }
            }
          } else {
            tmp = state.errors;
          }
          return {
                  bitrix24Settings: settings,
                  enabled: enabled,
                  applicationUrl: applicationUrl,
                  suggest: suggest,
                  errors: tmp,
                  bitrixAddress: s.bitrixAddress,
                  currentCategory: settings !== undefined && settings.categories.length === 1 ? Caml_option.some(defaultCategoryId) : undefined
                };
        });
  };
  var responseToSave = function (data) {
    if (data.TAG === "Ok") {
      return unlock();
    }
    var match = data._0;
    if (typeof match !== "object") {
      return unlock();
    }
    if (match.TAG !== "HTTPError") {
      return unlock();
    }
    var tmp = match._0[0];
    if (typeof tmp === "object") {
      return unlock();
    }
    if (tmp !== "UnprocessableEntity") {
      return unlock();
    }
    unlock();
    messenger.trigger({
          TAG: "Show",
          _0: MessengerTypes.Message.make(undefined, "Danger", {
                TAG: "Text",
                _0: formContainsErrors$p(ctx)
              })
        });
  };
  var responseToSync = function (data) {
    unlock();
  };
  var syncDeals = function () {
    if (tenantUid !== undefined) {
      lock();
      setIntegrationMenuOpen(function (param) {
            return false;
          });
      return Backend.$$finally(Bitrix_API.Show.sync(wire, tenantUid), responseToSync);
    }
    
  };
  var responseToDestroy = function (data) {
    if (data.TAG !== "Ok") {
      return unlock();
    }
    unlock();
    wire.navigate(undefined, {
          NAME: "Integrations",
          VAL: "Index"
        });
  };
  var destroy = function () {
    if (tenantUid !== undefined) {
      lock();
      setIntegrationMenuOpen(function (param) {
            return false;
          });
      return Backend.$$finally(Bitrix_API.Show.destroy(wire, tenantUid), responseToDestroy);
    }
    
  };
  var updateCategory = function (settings, updated) {
    return RCore.$$Option.map(settings, (function (s) {
                  return {
                          domain: s.domain,
                          templates: s.templates,
                          categories: s.categories.map(function (cat) {
                                if (Types.Id.equal(cat.id, updated.id)) {
                                  return updated;
                                } else {
                                  return cat;
                                }
                              }),
                          dealFields: s.dealFields
                        };
                }));
  };
  var handleUpdateAddress = function ($$event) {
    var address = $$event.target.value;
    var regex = /^https?:\/\/|\/$/g;
    var bitrixAddress = RCore.$$Option.map(Fun.optString(address), (function (__x) {
            return Js_string.replaceByRe(regex, "", __x);
          }));
    setState(function (s) {
          return {
                  bitrix24Settings: s.bitrix24Settings,
                  enabled: s.enabled,
                  applicationUrl: s.applicationUrl,
                  suggest: s.suggest,
                  errors: s.errors,
                  bitrixAddress: bitrixAddress,
                  currentCategory: s.currentCategory
                };
        });
  };
  var updateType = function (category, updatedType) {
    var updatedTypes = category.types.map(function (dealType) {
          if (Types.Id.equal(dealType.id, updatedType.id)) {
            return updatedType;
          } else {
            return dealType;
          }
        });
    setOpenStage(function (param) {
          
        });
    setState(function (s) {
          return {
                  bitrix24Settings: updateCategory(state.bitrix24Settings, {
                        id: category.id,
                        name: category.name,
                        stages: category.stages,
                        types: updatedTypes,
                        configuredTypes: category.configuredTypes
                      }),
                  enabled: s.enabled,
                  applicationUrl: s.applicationUrl,
                  suggest: s.suggest,
                  errors: s.errors,
                  bitrixAddress: s.bitrixAddress,
                  currentCategory: s.currentCategory
                };
        });
  };
  var toggleConfigured = function (category) {
    var types = category.types;
    return function (typeId) {
      var updatesTypes = types.map(function (dealType) {
            if (Types.Id.equal(dealType.id, typeId)) {
              return {
                      id: dealType.id,
                      name: dealType.name,
                      configured: !dealType.configured,
                      stages: dealType.stages
                    };
            } else {
              return dealType;
            }
          });
      setState(function (s) {
            return {
                    bitrix24Settings: updateCategory(state.bitrix24Settings, {
                          id: category.id,
                          name: category.name,
                          stages: category.stages,
                          types: updatesTypes,
                          configuredTypes: category.configuredTypes
                        }),
                    enabled: s.enabled,
                    applicationUrl: s.applicationUrl,
                    suggest: s.suggest,
                    errors: s.errors,
                    bitrixAddress: s.bitrixAddress,
                    currentCategory: s.currentCategory
                  };
          });
    };
  };
  var editStage = function (category, dealType, idx) {
    setOpenStage(function (param) {
          return [
                  category,
                  dealType,
                  idx
                ];
        });
  };
  var categoryName = function (name, ctx) {
    if (name === "default") {
      return defaultCategory$p(ctx);
    } else {
      return name;
    }
  };
  var backButtonParams = function (ctx, categories, bitrixTenantUid) {
    if (categories.length >= 2) {
      return [
              {
                NAME: "Integrations",
                VAL: {
                  NAME: "Bitrix24",
                  VAL: {
                    NAME: "Edit",
                    VAL: {
                      category: undefined,
                      bitrixTenantUid: bitrixTenantUid
                    }
                  }
                }
              },
              toCategoriesList$p(ctx)
            ];
    } else if (Context_Types.Features.hasFlag("multipleBitrixSettings", ctx.features)) {
      return [
              {
                NAME: "Integrations",
                VAL: {
                  NAME: "Bitrix24",
                  VAL: "Index"
                }
              },
              toAccountsList$p(ctx)
            ];
    } else {
      return [
              {
                NAME: "Integrations",
                VAL: "Index"
              },
              integrations$p(ctx)
            ];
    }
  };
  React.useEffect((function () {
          Backend.$$finally(Bitrix_API.Show.show(wire, initialTenantUid), responseToLoad);
          return wire.subscriptions.click.subscribe(function (param) {
                      setIntegrationMenuOpen(function (param) {
                            return false;
                          });
                    });
        }), []);
  var tmp;
  if (state.enabled) {
    var match$6 = Fun.both(state.bitrix24Settings, initialTenantUid);
    if (match$6 !== undefined) {
      var bitrixTenantUid = match$6[1];
      var settings = match$6[0];
      var categories = settings.categories;
      var templates = settings.templates;
      var match$7 = state.currentCategory;
      var chosenCategory;
      var exit = 0;
      var currentId;
      if (match$7 !== undefined) {
        if (pathCategory !== undefined) {
          currentId = Caml_option.valFromOption(pathCategory);
          exit = 1;
        } else {
          currentId = Caml_option.valFromOption(match$7);
          exit = 1;
        }
      } else if (pathCategory !== undefined) {
        currentId = Caml_option.valFromOption(pathCategory);
        exit = 1;
      } else {
        chosenCategory = undefined;
      }
      if (exit === 1) {
        chosenCategory = (function (__x) {
              return Js_array.find((function (param) {
                            return Types.Id.equal(param.id, currentId);
                          }), __x);
            })(categories);
      }
      var tmp$1;
      if (chosenCategory !== undefined) {
        var stages = chosenCategory.stages;
        var tmp$2;
        if (openStage !== undefined) {
          var idx = openStage[2];
          var dealType = openStage[1];
          var stage = RCore.$$Option.flatMap(idx, (function (i) {
                  return dealType.stages[i];
                }));
          tmp$2 = React.createElement(Bitrix_Show$DealStageWindow, {
                ctx: ctx,
                updateStage: (function (stage) {
                    if (idx === undefined) {
                      return updateType(chosenCategory, {
                                  id: dealType.id,
                                  name: dealType.name,
                                  configured: dealType.configured,
                                  stages: Belt_Array.concatMany([
                                        dealType.stages,
                                        [stage]
                                      ])
                                });
                    }
                    var stages = Belt_Array.concatMany([dealType.stages]);
                    stages[idx] = stage;
                    updateType(chosenCategory, {
                          id: dealType.id,
                          name: dealType.name,
                          configured: dealType.configured,
                          stages: stages
                        });
                  }),
                removeStage: (function () {
                    if (idx === undefined) {
                      return ;
                    }
                    var stages = RCore.$$Array.removeOne(dealType.stages, idx);
                    updateType(chosenCategory, {
                          id: dealType.id,
                          name: dealType.name,
                          configured: dealType.configured,
                          stages: stages
                        });
                  }),
                templates: templates,
                dealFields: settings.dealFields,
                stages: stages,
                stage: stage
              });
        } else {
          tmp$2 = null;
        }
        tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: styles.subheader
                }, jobCreation$p(ctx)), React.createElement("div", {
                  className: styles.typesCaption
                }, forBitrixDeals$p(ctx)), chosenCategory.types.map(function (dealType) {
                  return React.createElement(Bitrix_Show$DealTypeConfiguration, {
                              wire: wire,
                              dealType: dealType,
                              templates: templates,
                              addStage: (function () {
                                  editStage(chosenCategory, dealType, undefined);
                                }),
                              stages: stages,
                              configure: toggleConfigured(chosenCategory),
                              editStage: (function (idx) {
                                  editStage(chosenCategory, dealType, idx);
                                }),
                              key: Types.Id.toString(dealType.id)
                            });
                }), React.createElement(Modal.make, {
                  wire: wire,
                  onClose: (function () {
                      setOpenStage(function (param) {
                            
                          });
                    }),
                  show: RCore.$$Option.isSome(openStage),
                  children: tmp$2
                }));
      } else {
        tmp$1 = React.createElement("div", {
              className: styles.categories
            }, React.createElement("h3", undefined, chooseDealCategory$p(ctx)), React.createElement("ul", {
                  className: styles.categoriesList
                }, categories.map(function (category, i) {
                      return React.createElement("li", {
                                  key: i.toString()
                                }, React.createElement(Link.make, {
                                      wire: wire,
                                      route: {
                                        NAME: "Integrations",
                                        VAL: {
                                          NAME: "Bitrix24",
                                          VAL: {
                                            NAME: "Edit",
                                            VAL: {
                                              category: Caml_option.some(category.id),
                                              bitrixTenantUid: bitrixTenantUid
                                            }
                                          }
                                        }
                                      },
                                      children: categoryName(category.name, ctx)
                                    }), React.createElement(Optional.make, {
                                      show: category.configuredTypes > 0,
                                      children: React.createElement("em", undefined, " (" + configuredTypesHint$p({
                                                count: category.configuredTypes
                                              }, ctx) + ")")
                                    }));
                    })));
      }
      var tmp$3;
      if (chosenCategory !== undefined) {
        var match$8 = backButtonParams(ctx, categories, bitrixTenantUid);
        tmp$3 = React.createElement("div", {
              className: styles.footer
            }, React.createElement(Link.make, {
                  wire: wire,
                  route: match$8[0],
                  className: "btn " + styles.back,
                  children: React.createElement(React.Fragment, undefined, React.createElement("i", {
                            className: Icon.style(undefined, undefined, "chevron-left")
                          }), El.space, match$8[1])
                }), React.createElement("button", {
                  className: "btn btn-primary",
                  disabled: locked,
                  onClick: (function (param) {
                      if (tenantUid !== undefined) {
                        lock();
                        return Backend.$$finally(Bitrix_API.Show.update(wire, settings, tenantUid), responseToSave);
                      }
                      
                    })
                }, save$p(ctx)));
      } else {
        tmp$3 = null;
      }
      tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
                className: styles.formContainer
              }, React.createElement("h2", {
                    className: styles.caption
                  }, React.createElement("span", undefined, React.createElement(React.Fragment, undefined, chosenCategory !== undefined ? React.createElement(React.Fragment, undefined, categoryName(chosenCategory.name, ctx), El.space, El.mdash, El.space, configuringCategory$p(ctx)) : choosingCategory$p(ctx))), React.createElement(Bitrix_Show$Domain, {
                        wire: wire,
                        domain: settings.domain,
                        menuOpen: integrationMenuOpen,
                        toggleMenu: (function () {
                            setIntegrationMenuOpen(function (param) {
                                  return !integrationMenuOpen;
                                });
                          }),
                        sync: syncDeals,
                        remove: destroy,
                        bitrixTenantUid: bitrixTenantUid
                      })), tmp$1), tmp$3);
    } else {
      var match$9 = ctx.branding;
      var match$10;
      var exit$1 = 0;
      if (match$9 !== undefined) {
        var name = match$9.brandName;
        if (name !== undefined) {
          match$10 = [
            connectWhiteLabel$p({
                  name: name
                }, ctx),
            writeToAddAnotherCrmWhiteLabel$p({
                  name: name
                }, ctx)
          ];
        } else {
          exit$1 = 1;
        }
      } else {
        exit$1 = 1;
      }
      if (exit$1 === 1) {
        match$10 = [
          connect$p(ctx),
          writeToAddAnotherCrm$p(ctx)
        ];
      }
      var match$11 = ctx.branding;
      var supportHtml;
      if (match$11 !== undefined) {
        var match$12 = match$11.contacts;
        supportHtml = match$12 !== undefined ? supportWhiteLabel$p({
                mail: match$12.support
              }, ctx) : support$p(ctx);
      } else {
        supportHtml = support$p(ctx);
      }
      var match$13 = ctx.branding;
      var appName;
      if (match$13 !== undefined) {
        var name$1 = match$13.brandName;
        appName = name$1 !== undefined ? applicationWhiteLabel$p({
                name: name$1
              }, ctx) : application$p(ctx);
      } else {
        appName = application$p(ctx);
      }
      var appUrl = state.applicationUrl;
      var permissionError = Errors.getString("permission_error", state.errors);
      var bitrix24AddressError = Errors.getString("bitrix24Address", state.errors);
      var tmp$4;
      var exit$2 = 0;
      if (permissionError !== undefined) {
        switch (permissionError) {
          case "domain_already_in_use" :
              if (bitrix24AddressError !== undefined) {
                exit$2 = 1;
              } else {
                tmp$4 = React.createElement("div", {
                      className: "pd-control-error"
                    }, domainAlreadyInUse$p(ctx));
              }
              break;
          case "unavailable_for_domain" :
              if (bitrix24AddressError !== undefined) {
                exit$2 = 1;
              } else {
                tmp$4 = React.createElement("div", {
                      className: "pd-control-error"
                    }, unavailableForDomain$p(ctx));
              }
              break;
          case "unavailable_on_free_plans" :
              if (bitrix24AddressError !== undefined) {
                exit$2 = 1;
              } else {
                tmp$4 = React.createElement("div", {
                      className: "pd-control-error"
                    }, unavailableOnFreePlans$p(ctx));
              }
              break;
          default:
            if (bitrix24AddressError !== undefined) {
              exit$2 = 1;
            } else {
              tmp$4 = React.createElement("div", {
                    className: "pd-control-error"
                  }, checkAdminPrivileges$p(ctx));
            }
        }
      } else if (bitrix24AddressError !== undefined) {
        tmp$4 = React.createElement("div", {
              className: "pd-control-error"
            }, bitrix24AddressError);
      } else {
        exit$2 = 1;
      }
      if (exit$2 === 1) {
        var errors = state.errors;
        tmp$4 = errors ? React.createElement("div", {
                className: "pd-control-error"
              }, React.createElement("span", undefined, bitrixErrorOccurred$p(ctx)), React.createElement("div", undefined, Core__List.toArray(Core__List.map(errors, (function (param) {
                              return param[0] + ": " + param[1];
                            }))))) : null;
      }
      tmp = React.createElement("div", {
            className: styles.newContainer
          }, React.createElement("div", {
                className: styles.newHeader
              }, match$10[0], match$10[1], El.space, React.createElement("span", {
                    dangerouslySetInnerHTML: {
                      __html: supportHtml
                    }
                  }), ".", React.createElement("br", undefined), React.createElement("br", undefined), React.createElement(Bitrix_Show$DocsLink, {
                    ctx: ctx
                  })), React.createElement("hr", undefined), React.createElement("div", {
                className: styles.newFormContainer
              }, React.createElement("h2", {
                    className: styles.caption
                  }, React.createElement("span", undefined, connecting$p(ctx))), React.createElement("ul", {
                    className: styles.stepsList
                  }, React.createElement("li", undefined, React.createElement("span", {
                            className: styles.stepNo
                          }, "1"), installApplication$p(ctx), El.space, appUrl !== undefined ? React.createElement("a", {
                              href: appUrl,
                              target: "_blank"
                            }, appName) : null, "."), React.createElement("li", undefined, React.createElement("span", {
                            className: styles.stepNo
                          }, "2"), enterBitrixAddress$p(ctx), React.createElement("div", {
                            className: styles.newAddress
                          }, React.createElement("input", {
                                className: "form-control",
                                placeholder: state.suggest,
                                type: "text",
                                value: RCore.$$Option.getOr(state.bitrixAddress, ""),
                                onChange: handleUpdateAddress
                              }), tmp$4))), React.createElement("div", {
                    className: styles.newFooter
                  }, React.createElement("button", {
                        className: "btn btn-primary btn-submit",
                        disabled: locked,
                        onClick: (function (param) {
                            createIntegration();
                          })
                      }, installIntegration$p(ctx)))));
    }
  } else {
    tmp = React.createElement("div", {
          className: styles.settings
        }, React.createElement("div", {
              className: styles.headline
            }, integrationDisabled$p(ctx)), React.createElement("div", {
              className: styles.desc
            }, enableNote$p(ctx)));
  }
  return React.createElement(Optional.make, {
              show: match$1[0],
              children: React.createElement("div", {
                    className: "form"
                  }, React.createElement("section", {
                        className: styles.form
                      }, tmp))
            });
}

var make = Bitrix_Show;

export {
  make ,
}
/* styles Not a pure module */
