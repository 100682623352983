

import * as Uuid from "../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../libraries/RCore.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as LocalStorage from "../../../../../bindings/localStorage/LocalStorage.mjs";
import * as Shared_Lib_IO from "../../../../../shared/lib/Shared_Lib_IO.mjs";
import * as Schedule_StorageDecoder from "./Schedule_StorageDecoder.mjs";
import * as Schedule_StorageEncoder from "./Schedule_StorageEncoder.mjs";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";

function key(uuid) {
  return Uuid.toString(uuid) + ".schedule";
}

function use(view, state, dispatch) {
  var wire = AppContext.useWire();
  var navigate = wire.navigate;
  var ctx = wire.ctx;
  React.useEffect((function () {
          var match = ctx.user;
          if (match !== undefined) {
            Shared_Lib_IO.unsafeRunAsync(Shared_Lib_IO.tap(Shared_Lib_IO.map(LocalStorage.getItem(key(match.uuid)), (function (__x) {
                            return RCore.$$Option.flatMap(__x, (function (x) {
                                          var value = Json_Decode$JsonCombinators.decode(x, Schedule_StorageDecoder.decode(wire));
                                          if (value.TAG === "Ok") {
                                            return value._0;
                                          }
                                          
                                        }));
                          })), (function (result) {
                        if (result === undefined) {
                          return ;
                        }
                        var jobDuration = result.jobDuration;
                        var mode = result.mode;
                        var savedView = result.view;
                        var assigneesFilter = result.assigneesFilter;
                        var skills = result.skills;
                        var territories = result.territories;
                        if (Caml_obj.notequal(savedView, view)) {
                          navigate(undefined, {
                                NAME: "Schedule",
                                VAL: savedView
                              });
                        }
                        if (Caml_obj.notequal(territories, state.settings.filter.territories)) {
                          dispatch({
                                TAG: "Settings",
                                _0: {
                                  TAG: "SetFilterTerritories",
                                  _0: territories
                                }
                              });
                        }
                        if (Caml_obj.notequal(skills, state.settings.filter.skills)) {
                          dispatch({
                                TAG: "Settings",
                                _0: {
                                  TAG: "SetFilterSkills",
                                  _0: skills
                                }
                              });
                        }
                        if (assigneesFilter !== state.settings.filter.assignees) {
                          dispatch({
                                TAG: "Settings",
                                _0: {
                                  TAG: "SetAssigneesFilter",
                                  _0: assigneesFilter
                                }
                              });
                        }
                        if (Caml_obj.notequal(mode, state.settings.mode)) {
                          dispatch({
                                TAG: "Settings",
                                _0: {
                                  TAG: "SetMode",
                                  _0: mode
                                }
                              });
                        }
                        if (jobDuration !== state.settings.jobDuration) {
                          if (jobDuration === "Scheduled") {
                            return dispatch({
                                        TAG: "Settings",
                                        _0: "ShowJobsWithScheduledDuration"
                                      });
                          } else {
                            return dispatch({
                                        TAG: "Settings",
                                        _0: "ShowJobsWithActualDuration"
                                      });
                          }
                        }
                        
                      })), (function (prim) {
                    
                  }));
          }
          
        }), []);
  React.useEffect((function () {
          var match = ctx.user;
          if (match !== undefined) {
            Shared_Lib_IO.unsafeRunAsync(LocalStorage.setItem(key(match.uuid), Schedule_StorageEncoder.encode(view, state.settings.filter.territories, state.settings.filter.assignees, state.settings.filter.skills, state.settings.mode, state.settings.jobDuration)), (function (prim) {
                    
                  }));
          }
          
        }), [
        state.settings.filter.territories,
        state.settings.filter.skills,
        state.settings.filter.assignees,
        state.settings.mode,
        state.settings.jobDuration,
        view
      ]);
}

export {
  use ,
}
/* Uuid Not a pure module */
