

import * as El from "../../libraries/El.mjs";
import * as Fun from "../../libraries/Fun.mjs";
import * as Icon from "../../Icon.mjs";
import * as Route from "../../types/Route.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Router from "../../Router.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../common/Optional.mjs";
import * as HeaderAPI from "./HeaderAPI.mjs";
import * as HeaderLink from "./HeaderLink.mjs";
import * as HeaderTypes from "./HeaderTypes.mjs";
import * as AddJobButton from "../common/AddJobButton/AddJobButton.mjs";
import * as Context_Types from "../../context/Context_Types.mjs";
import * as HeaderSupport from "./HeaderSupport.mjs";
import * as Shared_Lib_IO from "../../shared/lib/Shared_Lib_IO.mjs";
import * as HeaderSuccessPath from "./HeaderSuccessPath.mjs";
import * as HeaderModuleCss from "/home/runner/work/planado/planado/client/rescript/components/header/Header.module.css";

var settings$p = Utils.Translations.tr("js.menu.sections.settings");

var general$p = Utils.Translations.tr("js.menu.settings.general");

var jobs$p = Utils.Translations.tr("js.menu.sections.jobs");

var catalog$p = Utils.Translations.tr("js.menu.sections.catalog");

var profile$p = Utils.Translations.tr("js.menu.sections.update_profile");

var changePassword$p = Utils.Translations.tr("js.menu.sections.change_password");

var signOut$p = Utils.Translations.tr("js.menu.sections.sign_out");

var styles = HeaderModuleCss;

function HeaderMenu(Props) {
  var wire = Props.wire;
  var openedSection = Props.openedSection;
  var setOpenedSection = Props.setOpenedSection;
  var togglePasswordModal = Props.togglePasswordModal;
  var toggleProfileModal = Props.toggleProfileModal;
  var navigate = wire.navigate;
  var route = wire.route;
  var ctx = wire.ctx;
  var openSection = function (section) {
    return function ($$event) {
      $$event.stopPropagation();
      if (Caml_obj.equal(openedSection, section)) {
        return setOpenedSection(function (param) {
                    
                  });
      } else {
        return setOpenedSection(function (param) {
                    return section;
                  });
      }
    };
  };
  var onClick = function (route) {
    return function ($$event) {
      var meta = $$event.metaKey;
      if (!meta) {
        $$event.preventDefault();
        $$event.stopPropagation();
        navigate(undefined, route);
        return setOpenedSection(function (param) {
                    
                  });
      }
      
    };
  };
  var section = function (section$1, route) {
    return React.createElement(HeaderLink.make, {
                route: route,
                wire: wire,
                onClick: onClick,
                section: section$1,
                openedSection: openedSection
              });
  };
  var signOut = function () {
    Shared_Lib_IO.unsafeRunAsync(Shared_Lib_IO.tap(HeaderAPI.destroySession(wire), (function (result) {
                if (result.TAG === "Ok") {
                  return Router.redirectToLogin();
                }
                
              })), (function (prim) {
            
          }));
  };
  var menu = HeaderTypes.Menu.make(ctx);
  var match = menu.settings;
  var match$1 = ctx.permissions;
  var tmp;
  if (match$1 !== undefined) {
    var match$2 = match$1.jobs;
    tmp = (match$2 === "ViewEditDelete" || match$2 === "ViewEdit") && ctx.enabled ? React.createElement("div", {
            className: styles.addJob
          }, React.createElement(AddJobButton.make, {
                wire: wire,
                templates: ctx.templates,
                dark: true,
                filterEnabled: true,
                jobType: "Job",
                buttonClassName: wire.tour.anchor("job-add-button") + " intercom-add-job-button",
                templateClassName: (function (param) {
                    return wire.tour.anchor({
                                NAME: "template-variant",
                                VAL: param.uuid
                              }) + " intercom-add-job-button-template";
                  })
              })) : null;
  } else {
    tmp = null;
  }
  var successPath = ctx.successPath;
  return React.createElement(React.Fragment, undefined, React.createElement(Optional.make, {
                  show: !HeaderTypes.Menu.empty(menu),
                  children: React.createElement("nav", {
                        className: styles.menu,
                        role: "navigation"
                      }, React.createElement(Optional.make, {
                            show: menu.schedule,
                            children: section("Schedule", {
                                  NAME: "Schedule",
                                  VAL: {
                                    NAME: "Timeline",
                                    VAL: "Day"
                                  }
                                })
                          }), React.createElement(Optional.make, {
                            show: menu.jobs,
                            children: section("Jobs", {
                                  NAME: "Job",
                                  VAL: "Index"
                                })
                          }), React.createElement(Optional.make, {
                            show: menu.map,
                            children: section("Map", {
                                  NAME: "MapShow",
                                  VAL: "Workers"
                                })
                          }), React.createElement(Optional.make, {
                            show: menu.clients,
                            children: section("Addresses", {
                                  NAME: "Client",
                                  VAL: "Index"
                                })
                          }), React.createElement(Optional.make, {
                            show: menu.teams,
                            children: section("Teams", {
                                  NAME: "Team",
                                  VAL: "Index"
                                })
                          }), React.createElement(Optional.make, {
                            show: menu.shifts,
                            children: section("Shifts", "Shifts")
                          }), match !== undefined ? React.createElement("div", {
                              className: HeaderLink.matchItemClass(route.path, openedSection, {
                                    TAG: "SettingsSection",
                                    _0: "Top"
                                  })
                            }, React.createElement("button", {
                                  type: "button",
                                  onClick: openSection("Settings")
                                }, settings$p(ctx)), React.createElement("div", {
                                  className: styles.drop
                                }, React.createElement("div", {
                                      className: HeaderLink.matchItemClass(route.path, openedSection, {
                                            TAG: "SettingsSection",
                                            _0: "General"
                                          })
                                    }, React.createElement("a", {
                                          href: Route.pagePath(undefined, "GeneralSettings"),
                                          onClick: onClick("GeneralSettings")
                                        }, general$p(ctx))), React.createElement("div", {
                                      className: styles.dropGroup
                                    }, React.createElement("div", {
                                          className: styles.groupHl
                                        }, jobs$p(ctx)), React.createElement("div", {
                                          className: styles.group
                                        }, section({
                                              TAG: "SettingsSection",
                                              _0: "Templates"
                                            }, {
                                              NAME: "Template",
                                              VAL: "Index"
                                            }), section({
                                              TAG: "SettingsSection",
                                              _0: "Resolutions"
                                            }, "ResolutionIndex"), section({
                                              TAG: "SettingsSection",
                                              _0: "JobTypes"
                                            }, "JobTypeIndex"), React.createElement(Optional.make, {
                                              show: match.recurringJobs,
                                              children: section({
                                                    TAG: "SettingsSection",
                                                    _0: "RecurringJobs"
                                                  }, {
                                                    NAME: "RecurringJob",
                                                    VAL: "Index"
                                                  })
                                            }), React.createElement(Optional.make, {
                                              show: Context_Types.Features.hasFlag("printLayouts", ctx.features),
                                              children: section({
                                                    TAG: "SettingsSection",
                                                    _0: "PrintLayouts"
                                                  }, {
                                                    NAME: "PrintLayout",
                                                    VAL: "Index"
                                                  })
                                            }), React.createElement(Optional.make, {
                                              show: Context_Types.Features.hasFlag("clientTracking", ctx.features),
                                              children: section({
                                                    TAG: "SettingsSection",
                                                    _0: "ClientForms"
                                                  }, {
                                                    NAME: "ClientForm",
                                                    VAL: "Index"
                                                  })
                                            }))), React.createElement(Optional.make, {
                                      show: match.catalog,
                                      children: React.createElement("div", {
                                            className: styles.dropGroup
                                          }, React.createElement("div", {
                                                className: styles.groupHl
                                              }, catalog$p(ctx)), React.createElement("div", {
                                                className: styles.group
                                              }, section({
                                                    TAG: "SettingsSection",
                                                    _0: "Products"
                                                  }, {
                                                    NAME: "Products",
                                                    VAL: "Index"
                                                  }), section({
                                                    TAG: "SettingsSection",
                                                    _0: "Services"
                                                  }, {
                                                    NAME: "Services",
                                                    VAL: "Index"
                                                  })))
                                    }), section({
                                      TAG: "SettingsSection",
                                      _0: "Users"
                                    }, {
                                      NAME: "User",
                                      VAL: "Index"
                                    }), React.createElement(Optional.make, {
                                      show: match.territories,
                                      children: section({
                                            TAG: "SettingsSection",
                                            _0: "Territories"
                                          }, {
                                            NAME: "Territories",
                                            VAL: "Index"
                                          })
                                    }), section({
                                      TAG: "SettingsSection",
                                      _0: "Skills"
                                    }, "Skills"), section({
                                      TAG: "SettingsSection",
                                      _0: "Dictionaries"
                                    }, {
                                      NAME: "Dictionary",
                                      VAL: "Index"
                                    }), React.createElement(Optional.make, {
                                      show: Context_Types.Features.hasFlag("customFields", ctx.features),
                                      children: section({
                                            TAG: "SettingsSection",
                                            _0: "CustomFields"
                                          }, {
                                            NAME: "CustomFields",
                                            VAL: "Users"
                                          })
                                    }), React.createElement(Optional.make, {
                                      show: Context_Types.Features.hasFlag("clientPortal", ctx.features),
                                      children: section({
                                            TAG: "SettingsSection",
                                            _0: "ClientPortal"
                                          }, {
                                            NAME: "ClientPortal",
                                            VAL: "Settings"
                                          })
                                    }), React.createElement(Optional.make, {
                                      show: Context_Types.Features.hasFlag("audit", ctx.features),
                                      children: section({
                                            TAG: "SettingsSection",
                                            _0: "AuditLog"
                                          }, "AuditLog")
                                    }), React.createElement(Optional.make, {
                                      show: match.integrations,
                                      children: section({
                                            TAG: "SettingsSection",
                                            _0: "Integrations"
                                          }, {
                                            NAME: "Integrations",
                                            VAL: "Index"
                                          })
                                    }), React.createElement(Optional.make, {
                                      show: match.billing,
                                      children: section({
                                            TAG: "SettingsSection",
                                            _0: "Billing"
                                          }, "Billing")
                                    }))) : null)
                }), React.createElement("nav", {
                  className: styles.menu,
                  role: "navigation"
                }, tmp, React.createElement("div", {
                      className: HeaderLink.matchItemClass(route.path, openedSection, "Support")
                    }, React.createElement("button", {
                          type: "button",
                          onClick: openSection("Support")
                        }, React.createElement("i", {
                              className: Icon.style("lg", undefined, "question-circle")
                            })), React.createElement(HeaderSupport.make, {
                          wire: wire
                        })), successPath !== undefined && Context_Types.Features.hasFlag("showSuccessPath", wire.ctx.features) ? React.createElement("div", {
                        className: HeaderLink.matchItemClass(route.path, openedSection, "SuccessPath")
                      }, React.createElement("button", {
                            type: "button",
                            onClick: openSection("SuccessPath")
                          }, React.createElement("i", {
                                className: Icon.style("lg", undefined, "bolt")
                              })), React.createElement(HeaderSuccessPath.make, {
                            wire: wire,
                            successPath: successPath
                          })) : null, React.createElement("div", {
                      className: HeaderLink.matchItemClass(route.path, openedSection, "Profile")
                    }, React.createElement("button", {
                          type: "button",
                          onClick: openSection("Profile")
                        }, HeaderLink.linkText("Profile")(ctx)), React.createElement("div", {
                          className: El.Cn.concat(styles.drop, styles.dropRtl)
                        }, React.createElement(Optional.make, {
                              show: ctx.enabled,
                              children: null
                            }, React.createElement("div", {
                                  className: styles.dropItem
                                }, React.createElement("button", {
                                      type: "button",
                                      onClick: Fun.omit(toggleProfileModal)
                                    }, profile$p(ctx))), React.createElement("div", {
                                  className: styles.dropItem
                                }, React.createElement("button", {
                                      type: "button",
                                      onClick: Fun.omit(togglePasswordModal)
                                    }, changePassword$p(ctx))), React.createElement("div", {
                                  className: El.Cn.concat(styles.dropItem, styles.dropItemDivider)
                                })), React.createElement("div", {
                              className: styles.dropItem
                            }, React.createElement("button", {
                                  type: "button",
                                  onClick: Fun.omit(signOut)
                                }, signOut$p(ctx)))))));
}

var make = HeaderMenu;

export {
  make ,
}
/* settings' Not a pure module */
