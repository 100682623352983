

import * as El from "../../../../../libraries/El.mjs";
import * as RCore from "../../../../../libraries/RCore.mjs";
import * as React from "react";
import * as Locale from "../../../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as DomUtils from "../../../../../DomUtils.mjs";
import * as Optional from "../../../../common/Optional.mjs";
import * as Core__Int from "@rescript/core/lib/es6/src/Core__Int.mjs";
import * as JsContext from "../../../../../libraries/JsContext.mjs";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as DatePicker from "../../../../inputs/DatePicker.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Shared_Lib_Option from "../../../../../shared/lib/Shared_Lib_Option.mjs";
import * as JobIndexFilter_CalendarPopup from "./JobIndexFilter_CalendarPopup.mjs";
import * as JobIndexFilter_DatesInputsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/index/job_index_filter/common/JobIndexFilter_DatesInputs.module.css";

var styles = JobIndexFilter_DatesInputsModuleCss;

function outsideClickHandler(calRef, $$event, onBlur) {
  var node = calRef.current;
  if (node == null) {
    return ;
  }
  var target = $$event.target;
  if (DomUtils.contains(node, target)) {
    return ;
  } else {
    return onBlur();
  }
}

var CalendarButton = {};

function JobIndexFilter_DatesInputs$Input(Props) {
  var value = Props.value;
  var onFocus = Props.onFocus;
  var onDateChange = Props.onDateChange;
  var disabled = Props.disabled;
  var match = React.useState(function () {
        return value;
      });
  var setInputValue = match[1];
  var wire = AppContext.useWire();
  var onChange = function (e) {
    e.preventDefault();
    var value = e.target.value;
    setInputValue(function (param) {
          return value;
        });
  };
  var onBlur = function (e) {
    var newValue = e.target.value;
    if (!Caml_obj.notequal(value, newValue)) {
      return ;
    }
    var parsedDate = Locale.T.parse("Date", JsContext.get(wire))(newValue);
    if (Locale.T.isValid(parsedDate)) {
      return onDateChange(Caml_option.some(parsedDate));
    } else if (value !== undefined) {
      return onDateChange(undefined);
    } else {
      return ;
    }
  };
  var displayValue = RCore.$$Option.getOr(Shared_Lib_Option.alt(match[0], value), "");
  React.useEffect((function () {
          setInputValue(function (param) {
                return value;
              });
        }), [value]);
  return React.createElement("input", {
              className: "j-scheduled__input",
              disabled: disabled,
              type: "text",
              value: displayValue,
              onFocus: onFocus,
              onBlur: onBlur,
              onChange: onChange
            });
}

var Input = {
  make: JobIndexFilter_DatesInputs$Input
};

function openUp(containerRef, offset) {
  var container = containerRef.current;
  if (container == null) {
    return false;
  }
  var position = DomUtils.BoundingClientRect.get(container);
  return ((position.bottom | 0) + (offset | 0) | 0) >= DomUtils.windowInnerHeight();
}

function JobIndexFilter_DatesInputs$Date(Props) {
  var date = Props.date;
  var onDateChange = Props.onDateChange;
  var disabled = Props.disabled;
  var wire = AppContext.useWire();
  var containerRef = React.useRef(null);
  var match = React.useState(function () {
        return false;
      });
  var setFocused = match[1];
  var calenfarStyle = match[0] ? El.Cn.concat(styles.calendarPopover, styles.isActive) : styles.calendarPopover;
  var calendarCN = openUp(containerRef, 350) ? El.Cn.concat(calenfarStyle, styles.calendarDateUp) : El.Cn.concat(calenfarStyle, styles.calendarDown);
  var tmp = {
    onDateChange: (function (v) {
        onDateChange(Caml_option.some(v));
      }),
    numberOfMonths: 1,
    navPrev: React.createElement("span", undefined),
    navNext: React.createElement("span", undefined),
    daySize: 31,
    hideKeyboardShortcutsPanel: true,
    onOutsideClick: (function (evt) {
        outsideClickHandler(containerRef, evt, (function () {
                setFocused(function (param) {
                      return false;
                    });
              }));
      })
  };
  if (date !== undefined) {
    tmp.date = Caml_option.valFromOption(date);
  }
  return React.createElement("div", {
              ref: containerRef,
              className: styles.dateContainer
            }, React.createElement(JobIndexFilter_DatesInputs$Input, {
                  value: RCore.$$Option.map(date, Locale.T.fmtM("Date", wire)),
                  onFocus: (function (param) {
                      setFocused(function (param) {
                            return true;
                          });
                    }),
                  onDateChange: onDateChange,
                  disabled: disabled
                }), React.createElement("div", {
                  className: calendarCN
                }, React.createElement(DatePicker.make, tmp)));
}

var $$Date = {
  make: JobIndexFilter_DatesInputs$Date
};

function JobIndexFilter_DatesInputs$Range(Props) {
  var filter = Props.onDatesChange;
  var value = Props.value;
  var buttons = Props.buttons;
  var disabled = Props.disabled;
  var match = React.useState(function () {
        
      });
  var finish = value[1];
  var start = value[0];
  var setFocusedInput = match[1];
  var onDatesChange = function (start, finish) {
    if (!disabled) {
      return filter(start, finish);
    }
    
  };
  var wire = AppContext.useWire();
  var containerRef = React.useRef(null);
  var format = Locale.T.fmtM("Date", wire);
  var focusHandler = function (input) {
    setFocusedInput(function (param) {
          return input;
        });
  };
  var changeStartDate = function (startDate) {
    onDatesChange(startDate, finish);
  };
  var changeFinishDate = function (finishDate) {
    var realFinishDate = Locale.T.isRangeValid(start, finishDate) ? finishDate : undefined;
    onDatesChange(start, realFinishDate);
  };
  var renderButtons = function () {
    return React.createElement(Optional.make, {
                show: Caml_obj.notequal(buttons, []),
                children: React.createElement("div", {
                      className: "j-calendar__buttons"
                    }, buttons.map(function (param) {
                          var getRange = param[2];
                          return React.createElement("button", {
                                      key: param[0],
                                      className: "j-calendar__button",
                                      type: "button",
                                      onClick: (function (param) {
                                          var match = getRange();
                                          onDatesChange(Caml_option.some(match[0]), Caml_option.some(match[1]));
                                        })
                                    }, param[1]);
                        }))
              });
  };
  var calendarCN = openUp(containerRef, 350) ? styles.calendarRangeUp : styles.calendarDown;
  return React.createElement("div", {
              ref: containerRef
            }, React.createElement("div", {
                  className: "j-scheduled__inputs"
                }, React.createElement(JobIndexFilter_DatesInputs$Input, {
                      value: RCore.$$Option.map(start, format),
                      onFocus: (function (param) {
                          setFocusedInput(function (param) {
                                return "Start";
                              });
                        }),
                      onDateChange: changeStartDate,
                      disabled: disabled
                    }), React.createElement("div", {
                      className: "j-scheduled__inputs-separator"
                    }, El.ndash), React.createElement(JobIndexFilter_DatesInputs$Input, {
                      value: RCore.$$Option.map(finish, format),
                      onFocus: (function (param) {
                          setFocusedInput(function (param) {
                                return "End";
                              });
                        }),
                      onDateChange: changeFinishDate,
                      disabled: disabled
                    })), React.createElement("div", {
                  className: "j_scheduled_calendar"
                }, React.createElement("div", {
                      className: calendarCN
                    }, React.createElement(JobIndexFilter_CalendarPopup.make, {
                          focusedInput: match[0],
                          value: value,
                          buttons: renderButtons(),
                          onFocusChange: focusHandler,
                          onDatesChange: onDatesChange,
                          onOutsideClick: (function (evt) {
                              outsideClickHandler(containerRef, evt, (function () {
                                      setFocusedInput(function (param) {
                                            
                                          });
                                    }));
                            })
                        }))));
}

var $$Range = {
  make: JobIndexFilter_DatesInputs$Range
};

function JobIndexFilter_DatesInputs$Days(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var onBlur = Props.onBlur;
  var onKeyPress = Props.onKeyPress;
  var containerClass = Props.containerClass;
  var disabled = Props.disabled;
  var label = Props.label;
  var stringValue = RCore.$$Option.mapWithDefault(value, "", (function (i) {
          return i.toString();
        }));
  return React.createElement("div", {
              className: El.Cn.concatOpt(styles.labelContainer, containerClass)
            }, React.createElement("input", {
                  className: styles.daysInput,
                  disabled: disabled,
                  min: "1",
                  type: "number",
                  value: stringValue,
                  onKeyPress: onKeyPress,
                  onBlur: onBlur,
                  onChange: (function (evt) {
                      var value = evt.target.value;
                      onChange(RCore.$$Option.flatMap(value, (function (x) {
                                  return Core__Int.fromString(x, undefined);
                                })));
                    })
                }), label);
}

var Days = {
  make: JobIndexFilter_DatesInputs$Days
};

export {
  CalendarButton ,
  Input ,
  $$Date ,
  $$Range ,
  Days ,
}
/* styles Not a pure module */
