

import * as El from "../../../../../../../../../libraries/El.mjs";
import * as Fun from "../../../../../../../../../libraries/Fun.mjs";
import * as Uuid from "../../../../../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../../../../types/Types.mjs";
import * as Units from "../../../../../../../../../types/Units.mjs";
import * as React from "react";
import * as Tooltip from "../../../../../../../../common/Tooltip/Tooltip.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Assignee_Types from "../../../../../../../../../types/Assignee_Types.mjs";
import * as DayTimeline_State from "../../../../../lib/state/DayTimeline_State.mjs";
import * as DayTimeline_AssignJobBlockType from "../../../../../lib/types/DayTimeline_AssignJobBlockType.mjs";
import * as DayTimeline_AssignJobBlocksModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/day_timeline/components/timeline/bar/components/assign_job_blocks/DayTimeline_AssignJobBlocks.module.css";

var styles = DayTimeline_AssignJobBlocksModuleCss;

function DayTimeline_AssignJobBlocks$Block(Props) {
  var block = Props.block;
  var blockRef = Types.ReactRef.use();
  var className = El.Cn.fromList({
        hd: [
          styles.block,
          true
        ],
        tl: {
          hd: [
            styles.warning,
            DayTimeline_AssignJobBlockType.tooltip(block) !== undefined
          ],
          tl: /* [] */0
        }
      });
  var element = React.createElement("div", {
        ref: blockRef,
        className: className,
        style: {
          left: Units.Px.toString(DayTimeline_AssignJobBlockType.left(block)),
          width: Units.Px.toString(DayTimeline_AssignJobBlockType.width(block))
        },
        onClick: Fun.omit(DayTimeline_AssignJobBlockType.onClick(block))
      });
  var tooltip = DayTimeline_AssignJobBlockType.tooltip(block);
  if (tooltip !== undefined) {
    return React.createElement(Tooltip.make, {
                elementRef: blockRef,
                position: "top",
                content: tooltip,
                children: element
              });
  } else {
    return element;
  }
}

function key(block) {
  return Uuid.toString(Assignee_Types.Assignee.uuid(DayTimeline_AssignJobBlockType.assignee(block)));
}

function DayTimeline_AssignJobBlocks(Props) {
  var assignee = Props.assignee;
  var blocks = DayTimeline_State.useAssignJobBlocks();
  return RCore.$$Array.filterMap(blocks, (function (block) {
                if (Caml_obj.equal(DayTimeline_AssignJobBlockType.assignee(block), assignee)) {
                  return Caml_option.some(React.createElement(DayTimeline_AssignJobBlocks$Block, {
                                  block: block,
                                  key: key(block)
                                }));
                }
                
              }));
}

var make = DayTimeline_AssignJobBlocks;

export {
  make ,
}
/* styles Not a pure module */
