

import * as El from "../../../libraries/El.mjs";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ChipModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/Chip/Chip.module.css";

var styles = ChipModuleCss;

function Chip(Props) {
  var name = Props.name;
  var onClick = Props.onClick;
  var active = Props.active;
  var disabledOpt = Props.disabled;
  var onHover = Props.onHover;
  var onLeave = Props.onLeave;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var stateCN = active ? styles.active : styles.normal;
  var className = El.Cn.concat(stateCN, styles.button);
  var onClickInner = function (evt) {
    evt.preventDefault();
    onClick();
  };
  var tmp = {
    className: styles.container
  };
  if (onHover !== undefined) {
    tmp.onMouseEnter = Caml_option.valFromOption(onHover);
  }
  if (onLeave !== undefined) {
    tmp.onMouseLeave = Caml_option.valFromOption(onLeave);
  }
  return React.createElement("div", tmp, React.createElement("button", {
                  className: className,
                  disabled: disabled,
                  onClick: onClickInner
                }, React.createElement("span", undefined, name)));
}

var make = Chip;

export {
  make ,
}
/* styles Not a pure module */
