

import * as El from "../../libraries/El.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as React from "react";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ButtonModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/Button.module.css";

var styles = ButtonModuleCss;

function toClassName(f) {
  if (f === "success") {
    return styles.primary;
  } else {
    return styles.danger;
  }
}

function makeClassName(flavor, disabled, className) {
  var flavor$1 = RCore.$$Option.map(flavor, toClassName);
  var disabled$1 = disabled ? styles.disabled : undefined;
  return Core__List.reduce({
              hd: flavor$1,
              tl: {
                hd: disabled$1,
                tl: {
                  hd: className,
                  tl: /* [] */0
                }
              }
            }, styles.btn, El.Cn.concatOpt);
}

function Button(Props) {
  var type_Opt = Props.type_;
  var disabledOpt = Props.disabled;
  var onClick = Props.onClick;
  var flavor = Props.flavor;
  var id = Props.id;
  var className = Props.className;
  var forwardRef = Props.forwardRef;
  var children = Props.children;
  var type_ = type_Opt !== undefined ? type_Opt : "button";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var className$1 = makeClassName(flavor, disabled, className);
  var tmp = {
    className: className$1,
    disabled: disabled,
    type: type_
  };
  if (forwardRef !== undefined) {
    tmp.ref = Caml_option.valFromOption(forwardRef);
  }
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement("button", tmp, children);
}

var Flavor = {};

var make = Button;

export {
  Flavor ,
  make ,
}
/* styles Not a pure module */
