

import * as El from "../../../libraries/El.mjs";
import * as Link from "../../common/Link/Link.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Hooks from "../../../libraries/hooks/Hooks.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Popover from "../../common/Popover/Popover.mjs";
import * as UI_Icon from "../../../ui/UI_Icon.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../../common/Optional.mjs";
import * as UI_Button from "../../../ui/UI_Button.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DropDownMenu from "../../common/DropDownMenu/DropDownMenu.mjs";
import * as Template_FooterModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/template/Template_Footer.module.css";

var cancel$p = Utils.Translations.tr("js.forms.buttons.cancel");

var copy$p = Utils.Translations.tr("js.templates.copy");

var create$p = Utils.Translations.tr("js.forms.buttons.submit_create");

var delete$p = Utils.Translations.tr("js.forms.buttons.delete");

var update$p = Utils.Translations.tr("js.forms.buttons.submit_edit");

var deletePrompt$p = Utils.Translations.tr("js.templates.popovers.confirm_delete.prompt");

var deleteJobsNotice$p = Utils.Translations.tr("js.templates.popovers.confirm_delete.jobs");

var deleteRecurringJobsNotice$p = Utils.Translations.tr("js.templates.popovers.confirm_delete.delete_with_recurring_jobs");

var deleteCancel$p = Utils.Translations.tr("js.templates.popovers.confirm_delete.cancel");

var deleteConfirm$p = Utils.Translations.tr("js.templates.popovers.confirm_delete.delete");

var styles = Template_FooterModuleCss;

function notice(wire, recurringJobs) {
  var ctx = wire.ctx;
  if (Caml_obj.equal(recurringJobs, [])) {
    return React.createElement("p", undefined, deleteJobsNotice$p(ctx));
  } else {
    return React.createElement("div", {
                className: styles.largeNotice
              }, React.createElement("ul", {
                    className: styles.noticeList
                  }, React.createElement("li", undefined, deleteJobsNotice$p(ctx)), React.createElement("li", undefined, deleteRecurringJobsNotice$p(ctx))), recurringJobs.map(function (job) {
                    return React.createElement(Link.make, {
                                wire: wire,
                                route: {
                                  NAME: "RecurringJob",
                                  VAL: {
                                    NAME: "Edit",
                                    VAL: job.uuid
                                  }
                                },
                                target: "blank",
                                children: null,
                                key: Uuid.toString(job.uuid)
                              }, job.name, React.createElement(UI_Icon.make, {
                                    icon: "external-link-alt"
                                  }));
                  }));
  }
}

function Template_Footer$DeletePopover(Props) {
  var wire = Props.wire;
  var recurringJobs = Props.recurringJobs;
  var onClose = Props.onClose;
  var onDelete = Props.onDelete;
  var ctx = wire.ctx;
  return React.createElement("div", {
              className: styles.popoverForm
            }, React.createElement("div", {
                  className: styles.headline
                }, deletePrompt$p(ctx)), notice(wire, recurringJobs), React.createElement(UI_Button.make, {
                  purpose: "default",
                  onClick: onClose,
                  children: deleteCancel$p(ctx)
                }), React.createElement(UI_Button.make, {
                  flavor: "danger",
                  className: styles.confirmDeleteButton,
                  onClick: onDelete,
                  children: deleteConfirm$p(ctx)
                }));
}

function Template_Footer$DeleteButton(Props) {
  var wire = Props.wire;
  var recurringJobs = Props.recurringJobs;
  var formDisabled = Props.formDisabled;
  var deleteTemplate = Props.deleteTemplate;
  var match = React.useState(function () {
        return false;
      });
  var setShowPopover = match[1];
  var buttonId = Hooks.useId(undefined);
  var onClick = function (e) {
    e.preventDefault();
    setShowPopover(function (param) {
          return true;
        });
  };
  var onClose = function (_e) {
    setShowPopover(function (param) {
          return false;
        });
  };
  return React.createElement("div", undefined, React.createElement(UI_Button.make, {
                  flavor: "danger",
                  id: buttonId,
                  className: styles.deleteButton,
                  disabled: formDisabled,
                  onClick: onClick,
                  children: delete$p(wire.ctx)
                }), React.createElement(Popover.make, {
                  footer: wire.footer,
                  show: match[0],
                  buttonId: buttonId,
                  position: "top",
                  className: styles.popover,
                  children: React.createElement(Template_Footer$DeletePopover, {
                        wire: wire,
                        recurringJobs: recurringJobs,
                        onClose: onClose,
                        onDelete: deleteTemplate
                      })
                }));
}

function Template_Footer$CopyButton(Props) {
  var wire = Props.wire;
  var uuid = Props.uuid;
  return React.createElement("div", {
              className: styles.additionalActions
            }, React.createElement(DropDownMenu.make, {
                  position: "right",
                  children: React.createElement("li", undefined, React.createElement(Link.make, {
                            wire: wire,
                            route: {
                              NAME: "Template",
                              VAL: {
                                NAME: "New",
                                VAL: Caml_option.some(uuid)
                              }
                            },
                            children: copy$p(wire.ctx)
                          }))
                }));
}

function Template_Footer$CancelButton(Props) {
  var wire = Props.wire;
  return React.createElement(UI_Button.make, {
              purpose: "default",
              className: styles.cancelButton,
              onClick: (function (param) {
                  wire.navigate(undefined, {
                        NAME: "Template",
                        VAL: "Index"
                      });
                }),
              children: cancel$p(wire.ctx)
            });
}

var spinner = React.createElement(UI_Icon.make, {
      size: "lg",
      spin: true,
      className: styles.spinner,
      icon: "spinner"
    });

function Template_Footer$SubmitButton(Props) {
  var ctx = Props.ctx;
  var isNewRecord = Props.isNewRecord;
  var foremanError = Props.foremanError;
  var formDisabled = Props.formDisabled;
  var text = formDisabled ? spinner : (
      isNewRecord ? create$p(ctx) : update$p(ctx)
    );
  var className = El.Cn.concatIf(styles.submitButton, [
        isNewRecord,
        "test_submit-client-form"
      ]);
  return React.createElement(UI_Button.make, {
              purpose: "primary",
              className: className,
              disabled: formDisabled || foremanError,
              children: text
            });
}

function Template_Footer(Props) {
  var isNewRecord = Props.isNewRecord;
  var uuid = Props.uuid;
  var recurringJobs = Props.recurringJobs;
  var formDisabled = Props.formDisabled;
  var foremanError = Props.foremanError;
  var deleteTemplate = Props.deleteTemplate;
  var wire = AppContext.useWire();
  var className = El.Cn.concatIf("form-footer", [
        isNewRecord,
        "form-footer_create"
      ]);
  return React.createElement("div", {
              className: className
            }, React.createElement(Optional.make, {
                  show: !isNewRecord,
                  children: null
                }, React.createElement(Template_Footer$DeleteButton, {
                      wire: wire,
                      recurringJobs: recurringJobs,
                      formDisabled: formDisabled,
                      deleteTemplate: deleteTemplate
                    }), React.createElement(Template_Footer$CopyButton, {
                      wire: wire,
                      uuid: uuid
                    })), React.createElement("div", undefined, React.createElement(Template_Footer$CancelButton, {
                      wire: wire
                    }), React.createElement(Template_Footer$SubmitButton, {
                      ctx: wire.ctx,
                      isNewRecord: isNewRecord,
                      foremanError: foremanError,
                      formDisabled: formDisabled
                    })));
}

var make = Template_Footer;

var $$default = Template_Footer;

export {
  make ,
  $$default as default,
}
/* cancel' Not a pure module */
