

import * as Uuid from "../../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Optional from "../../../../../common/Optional.mjs";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as JobViewTypes from "../../../views/JobViewTypes.mjs";
import * as JobIndexFilter_Reset from "../../common/JobIndexFilter_Reset.mjs";
import * as JobIndexFilter_Wrapper from "../../common/JobIndexFilter_Wrapper.mjs";
import * as JobIndexFilter_Checkbox from "../../common/JobIndexFilter_Checkbox.mjs";
import * as JobIndexFilter_Resolutions from "./JobIndexFilter_Resolutions.mjs";

var label$p = Utils.Translations.t("js.jobs.index.filters.statuses");

function status$p(status) {
  switch (status) {
    case "Posted" :
        return Utils.Translations.t("js.jobs.statuses.posted");
    case "Scheduled" :
        return Utils.Translations.t("js.jobs.statuses.scheduled");
    case "Published" :
        return Utils.Translations.t("js.jobs.statuses.published");
    case "EnRoute" :
        return Utils.Translations.t("js.jobs.statuses.en_route");
    case "Started" :
        return Utils.Translations.t("js.jobs.statuses.started");
    case "Suspended" :
        return Utils.Translations.t("js.jobs.statuses.suspended");
    case "Canceled" :
        return Utils.Translations.t("js.jobs.statuses.canceled");
    case "Finished" :
        return Utils.Translations.t("js.jobs.statuses.finished");
    
  }
}

var statuses$p = Utils.Translations.tx("js.jobs.index.selected.statuses");

var resolutions$p = Utils.Translations.tx("js.jobs.index.selected.resolutions");

var statusesList = [
  "Posted",
  "Scheduled",
  "Published",
  "EnRoute",
  "Started",
  "Suspended",
  "Canceled",
  "Finished"
];

function JobIndexFilter_Statuses(Props) {
  var currentFilter = Props.currentFilter;
  var resolutions = Props.resolutions;
  var resetFilter = Props.resetFilter;
  var toggleFilter = Props.toggleFilter;
  var resetResolutions = Props.resetResolutions;
  var appendResolutions = Props.appendResolutions;
  var filterByStatus = Props.filterByStatus;
  var filterByResolution = Props.filterByResolution;
  var selectAllResolutions = Props.selectAllResolutions;
  var selectSuccessfulResolutions = Props.selectSuccessfulResolutions;
  var selectUnsuccessfulResolutions = Props.selectUnsuccessfulResolutions;
  var filterDisabled = Props.filterDisabled;
  var activeView = Props.activeView;
  var show = Props.show;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var match$1 = RCore.$$Array.partition(resolutions, (function (r) {
          return r.type_ === "Successful";
        }));
  var unsuccessfulResolutions = match$1[1];
  var successfulResolutions = match$1[0];
  var numberOfStatuses = currentFilter.statuses.length;
  var showPreview = numberOfStatuses > 0;
  var onResetClick = function (param) {
    resetFilter();
    toggleFilter({
            TAG: "System",
            _0: "Status"
          })(false);
  };
  var selectedStatuses = function () {
    if (numberOfStatuses === 0) {
      return "";
    } else if (numberOfStatuses === 1) {
      return status$p(currentFilter.statuses[0])(ctx);
    } else {
      return statuses$p({
                  count: numberOfStatuses
                }, ctx);
    }
  };
  var selectedResolutions = function (status) {
    var tmp;
    tmp = status === "Canceled" ? currentFilter.canceledResolutions : currentFilter.resolutions;
    var len = tmp.length;
    if (len !== 1) {
      if (len !== 0) {
        return ", " + resolutions$p({
                    count: tmp.length
                  }, ctx);
      } else {
        return "";
      }
    }
    var selected = tmp[0];
    var res = RCore.$$Array.getBy(resolutions, (function (param) {
            return Uuid.equal(selected, param.uuid);
          }));
    if (res !== undefined) {
      return ", " + res.name;
    } else {
      return "";
    }
  };
  var statusWithResolutionsEnabled = function (status) {
    if (status === "Finished" || status === "Canceled") {
      return Js_array.includes(status, currentFilter.statuses);
    } else {
      return false;
    }
  };
  var renderStatuses = statusesList.map(function (s, idx) {
        return React.createElement(React.Fragment, {
                    children: null,
                    key: idx
                  }, React.createElement(JobIndexFilter_Checkbox.make, {
                        name: status$p(s)(ctx),
                        onToggle: filterByStatus(s),
                        checked: currentFilter.statuses.some(function (filter) {
                              return filter === s;
                            }),
                        disabled: filterDisabled,
                        key: JobViewTypes.Status.toString(s)
                      }), React.createElement(Optional.make, {
                        show: statusWithResolutionsEnabled(s),
                        children: React.createElement(JobIndexFilter_Resolutions.make, {
                              activeView: activeView,
                              status: s,
                              resolutions: resolutions,
                              successfulResolutions: successfulResolutions,
                              unsuccessfulResolutions: unsuccessfulResolutions,
                              currentResolutions: s === "Canceled" ? currentFilter.canceledResolutions : currentFilter.resolutions,
                              resetResolutions: resetResolutions,
                              appendResolutions: appendResolutions,
                              filterByResolution: filterByResolution,
                              filterDisabled: filterDisabled,
                              selectSuccessfulResolutions: selectSuccessfulResolutions,
                              selectUnsuccessfulResolutions: selectUnsuccessfulResolutions,
                              selectAllResolutions: selectAllResolutions,
                              key: JobViewTypes.Status.toString(s)
                            })
                      }));
      });
  return React.createElement(JobIndexFilter_Wrapper.make, {
              toggleFilter: toggleFilter({
                    TAG: "System",
                    _0: "Status"
                  }),
              show: show,
              label: label$p(ctx),
              children: null
            }, React.createElement(Optional.make, {
                  show: showPreview,
                  children: React.createElement(JobIndexFilter_Reset.make, {
                        onResetClick: onResetClick
                      })
                }), React.createElement("div", {
                  className: "j-filter__list"
                }, renderStatuses), React.createElement(Optional.make, {
                  show: showPreview,
                  children: React.createElement("div", {
                        className: "j-filter__selected"
                      }, selectedStatuses(), React.createElement(Optional.make, {
                            show: Js_array.includes("Finished", currentFilter.statuses) || Js_array.includes("Canceled", currentFilter.statuses),
                            children: selectedResolutions("Finished") + selectedResolutions("Canceled")
                          }))
                }));
}

var make = JobIndexFilter_Statuses;

export {
  make ,
}
/* label' Not a pure module */
