

import * as ResolutionsJs from "planado/containers/admin/resolutions.js";

var make = ResolutionsJs.SaveButtonLink;

export {
  make ,
}
/* make Not a pure module */
