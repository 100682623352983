import React, { useState } from 'react'
import classNames from 'classnames'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import {
  Input,
  File,
  Dictionary,
  Image,
  Checkbox,
  Action,
  Barcode,
  Link,
  Currency,
  Signature,
  Button,
  Generic,
} from './fields'
import {
  button as SettingsButton
} from 'rscrpt/components/admin/template/Template_Field_Settings_Button.mjs'

import { SortableListItem, SortableListActivator } from './sortable_list'

const Field = (props) => {
  const {
    ctx,
    deleteCustomField,
    idx,
    isCustomField,
    fieldType,
    fieldUuid,
    setCustomField,
    isNew = false,
    required = false,
    isDestroyed = false,
    hidden = false,
    readOnly = false,
  } = props
  const deleteHandler = () => deleteCustomField(fieldUuid, isNew, isCustomField)

  const requiredChangeHandler = () => {
    if (
      fieldType !== 'checkbox' &&
      fieldType !== 'action' &&
      fieldType !== 'link'
    ) {
      setCustomField(idx, 'required', !required, isCustomField)
    }
  }

  const [focused, setFocused] = useState(false)

  const focusHandler = (focus) => setFocused(focus)

  const inputBlock = (() => {
    switch (fieldType) {
      case 'file':
        if (isCustomField) {
          return <File {...props} focusHandler={focusHandler} />
        } else {
          return <Generic {...props} focusHandler={focusHandler} />
        }
      case 'dictionary':
        return <Dictionary {...props} focusHandler={focusHandler} />
      case 'image':
        return <Image {...props} focusHandler={focusHandler} />
      case 'checkbox':
        return <Checkbox {...props} focusHandler={focusHandler} />
      case 'action':
        return <Action {...props} focusHandler={focusHandler} />
      case 'barcode':
        return <Barcode {...props} focusHandler={focusHandler} />
      case 'input':
        return <Input {...props} focusHandler={focusHandler} />
      case 'link':
        return <Link {...props} focusHandler={focusHandler} />
      case 'currency':
        return <Currency {...props} focusHandler={focusHandler} />
      case 'signature':
        return <Signature {...props} focusHandler={focusHandler} />
      case 'button':
        return <Button {...props} focusHandler={focusHandler} />
      case 'datetime_picker':
      case 'date_picker':
      case 'time_picker':
      case 'location':
      case 'duration':
      default:
        return <Generic {...props} focusHandler={focusHandler} />
    }
  })()

  const requiredBlock = (() => {
    if (!isCustomField) {
      const checkboxClasses = classNames({
        'custom-field__required': true,
        'is-active': required && fieldType !== 'checkbox',
        'is-disable':
          fieldType === 'checkbox' ||
          fieldType === 'action' ||
          fieldType === 'link',
      })

      return <div className={checkboxClasses} onClick={requiredChangeHandler} />
    } else {
      return null
    }
  })()

  const settingsButton = (() => {
    const toggleRequired = () =>
      setCustomField(idx, 'required', !required, isCustomField)

    const toggleHidden = () =>
      setCustomField(idx, 'hidden', !hidden, isCustomField)

    const toggleReadOnly = () =>
      setCustomField(idx, 'readOnly', !readOnly, isCustomField)

    if (isCustomField) {
      return (
        <SettingsButton
          wire={ctx.wire}
          canBeRequired={fieldType !== 'button'}
          required={required}
          toggleRequired={toggleRequired}
          hidden={hidden}
          toggleHidden={toggleHidden}
          readOnly={readOnly}
          toggleReadOnly={toggleReadOnly}
        />
      )
    } else {
      return null
    }
  })()

  const fieldClasses = classNames({
    'custom-field': true,
    'is-destroyed': isDestroyed,
    'is-focus': focused,
  })

  const deleteButtonClasses = classNames({
    'custom-field__button': true,
    'cf-delete': isCustomField,
    'rf-delete': !isCustomField,
  })

  return (
    <SortableListItem id={fieldUuid} hasActivator={true}>
      <div className={fieldClasses}>
        <SortableListActivator id={fieldUuid}>
          <div className="custom-field__drag" />
        </SortableListActivator>

        {requiredBlock}

        {inputBlock}

        {settingsButton}

        <button
          className={deleteButtonClasses}
          type="button"
          onClick={deleteHandler}
        >
          <i className="fa fa-trash-alt" />
        </button>
      </div>
    </SortableListItem>
  )
}

export default withContext(Field)
