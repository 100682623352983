import { connect } from 'react-redux'
import { resetWorkerHistory } from 'planado/actions/map'

import WorkerLocations from 'planado/components/map/worker_locations.jsx'

const mapStateToProps = ({ historyWorker, historyDate }) => {
  return {
    worker: historyWorker,
    selectedDate: historyDate
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onReturn: () => dispatch(resetWorkerHistory())
  }
}

const WorkerLocationsLink = connect(mapStateToProps, mapDispatchToProps)(
  WorkerLocations
)

export default WorkerLocationsLink
