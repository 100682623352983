

import * as El from "../../../../../libraries/El.mjs";
import * as Modal from "../../../../modal/Modal.mjs";
import * as Utils from "../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as SettingsList from "./SettingsList.mjs";
import * as ModalModuleCss from "/home/runner/work/planado/planado/client/rescript/components/modal/Modal.module.css";
import * as SettingsPopoverModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/index/views/settings/SettingsPopover.module.css";

var modalName$p = Utils.Translations.tr("js.jobs.index.views.settings.modal_name");

var save$p = Utils.Translations.tr("js.jobs.index.views.settings.save");

var modalStyles = ModalModuleCss;

var styles = SettingsPopoverModuleCss;

function SettingsPopover(Props) {
  var oldViews = Props.views;
  var viewsSaving = Props.viewsSaving;
  var saveViews = Props.saveViews;
  var hidePopover = Props.hidePopover;
  var match = React.useState(function () {
        return oldViews;
      });
  var setViews = match[1];
  var views = match[0];
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var changeNameHandler = function (uuid, name) {
    setViews(function (param) {
          return views.map(function (v) {
                      if (Caml_obj.equal(uuid, v.uuid)) {
                        return {
                                uuid: v.uuid,
                                name: name,
                                settings: v.settings,
                                orderNo: v.orderNo,
                                system: v.system,
                                destroy: v.destroy
                              };
                      } else {
                        return v;
                      }
                    });
        });
  };
  var deleteHandler = function (uuid) {
    setViews(function (param) {
          return views.map(function (v) {
                      if (Caml_obj.equal(uuid, v.uuid)) {
                        return {
                                uuid: v.uuid,
                                name: v.name,
                                settings: v.settings,
                                orderNo: v.orderNo,
                                system: v.system,
                                destroy: true
                              };
                      } else {
                        return v;
                      }
                    });
        });
  };
  var onDragEnd = function (views) {
    setViews(function (param) {
          return views.map(function (view, orderNo) {
                      return {
                              uuid: view.uuid,
                              name: view.name,
                              settings: view.settings,
                              orderNo: orderNo,
                              system: view.system,
                              destroy: view.destroy
                            };
                    });
        });
  };
  return React.createElement(Modal.make, {
              wire: wire,
              onClose: hidePopover,
              children: React.createElement("div", {
                    className: El.Cn.concat(modalStyles.dialog, styles.popover)
                  }, React.createElement(Modal.Header.make, {
                        children: React.createElement("div", {
                              className: "JobsViews-settings__header-headline"
                            }, modalName$p(ctx))
                      }), React.createElement(Modal.Body.make, {
                        children: React.createElement(SettingsList.make, {
                              views: views,
                              changeNameHandler: changeNameHandler,
                              deleteHandler: deleteHandler,
                              onDragEnd: onDragEnd
                            })
                      }), React.createElement(Modal.Footer.make, {
                        children: null
                      }, React.createElement(Modal.CloseButton.make, {
                            ctx: ctx
                          }), React.createElement(Modal.SubmitButton.make, {
                            onClick: (function (param) {
                                saveViews(views);
                              }),
                            disabled: viewsSaving,
                            children: save$p(ctx)
                          })))
            });
}

var make = SettingsPopover;

export {
  make ,
}
/* modalName' Not a pure module */
