

import * as El from "../../../../libraries/El.mjs";
import * as Icon from "../../../../Icon.mjs";
import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as Hooks from "../../../../libraries/hooks/Hooks.mjs";
import * as Panel from "../../../common/Panel/Panel.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Types from "../../../../types/Types.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../libraries/Locale.mjs";
import * as Backend from "../../../../libraries/backend/Backend.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as DomUtils from "../../../../DomUtils.mjs";
import * as Optional from "../../../common/Optional.mjs";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ShiftsShow from "../../show/ShiftsShow.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ShiftsShowAPI from "../../show/ShiftsShowAPI.mjs";
import * as MessengerTypes from "../../../messenger/MessengerTypes.mjs";
import * as ShiftsIndexCellModuleCss from "/home/runner/work/planado/planado/client/rescript/components/shifts/index/ShiftsIndexCell.module.css";

var styles = ShiftsIndexCellModuleCss;

var shiftUpdated$p = Utils.Translations.t("js.shifts.show.shift_updated");

var unprocessableEntity$p = Utils.Translations.t("js.shifts.show.unprocessable_entity");

var serverError$p = Utils.Translations.t("js.shifts.show.server_error");

function notifyOk(wire, text) {
  wire.subscriptions.messenger.trigger({
        TAG: "Show",
        _0: MessengerTypes.Message.make(undefined, "Success", {
              TAG: "Text",
              _0: text
            })
      });
}

function notifyError(wire, text) {
  wire.subscriptions.messenger.trigger({
        TAG: "Show",
        _0: MessengerTypes.Message.make(undefined, "Danger", {
              TAG: "Text",
              _0: text
            })
      });
}

function ShiftsIndexWorkerCell(Props) {
  var day = Props.day;
  var worker = Props.worker;
  var jobTypes = Props.jobTypes;
  var territories = Props.territories;
  var updateWorker = Props.updateWorker;
  var constructorWorker = Props.constructorWorker;
  var updateConstructorWorker = Props.updateConstructorWorker;
  var mode = Props.mode;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var buttonId = Hooks.useId(undefined);
  var match = Hooks.useLocked(false);
  var unlock = match[2];
  var lock = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowForm = match$1[1];
  var showForm = match$1[0];
  var shift = React.useMemo((function () {
          return RCore.$$Array.getBy(worker.shifts, (function (s) {
                        return Locale.T.equal(s.date, day);
                      }));
        }), [worker.shifts]);
  var match$2 = React.useMemo((function () {
          if (constructorWorker === undefined) {
            return [
                    false,
                    false
                  ];
          }
          var match = constructorWorker.dates;
          if (match === undefined) {
            return [
                    false,
                    false
                  ];
          }
          var max = match[1];
          var min = match[0];
          if (max === undefined) {
            return [
                    Locale.T.equalDay(min, day),
                    false
                  ];
          }
          var within = Locale.T.withinInterval(day, [
                min,
                Caml_option.valFromOption(max)
              ]);
          return [
                  within,
                  within
                ];
        }), [constructorWorker]);
  var isConstrHighlighted = match$2[1];
  var isPicked = match$2[0];
  var cellShapeCN = React.useMemo((function () {
          if (shift === undefined) {
            return [styles.emptyDay];
          }
          var workerUuids = RCore.$$Array.filterMap(shift.intervals, (function (i) {
                  return i.teamUuid;
                }));
          if (workerUuids.length === 0) {
            return [styles.workday];
          } else if (workerUuids.length === shift.intervals.length) {
            if (isPicked) {
              return [styles.emptyDay];
            } else {
              return [styles.memberTeamDay];
            }
          } else if (isPicked) {
            return [styles.workday];
          } else {
            return [
                    styles.workday,
                    styles.memberTeamDay
                  ];
          }
        }), [
        shift,
        isPicked
      ]);
  var constructorCN;
  constructorCN = mode === "View" ? styles.view : styles.constructor;
  var cellComponents = cellShapeCN.map(function (cn) {
          if (isConstrHighlighted || showForm) {
            return El.Cn.concat(constructorCN, El.Cn.concat(cn, styles.picked));
          } else {
            return El.Cn.concat(constructorCN, cn);
          }
        }).map(function (cn, i) {
        return React.createElement("div", {
                    key: i.toString(),
                    className: cn
                  });
      });
  var popoverRef = Types.ReactRef.use();
  var cellRef = Types.ReactRef.use();
  var position = function (popoverRef) {
    var container = popoverRef.current;
    if (container == null) {
      return "bottom";
    }
    var position$1 = DomUtils.BoundingClientRect.get(container);
    if (((position$1.right | 0) + 600 | 0) >= DomUtils.windowInnerWidth()) {
      return "bottomRight";
    } else {
      return "bottom";
    }
  };
  var deleteShift = function () {
    if (shift !== undefined) {
      return updateWorker({
                  uuid: worker.uuid,
                  name: worker.name,
                  shifts: RCore.$$Array.keep(worker.shifts, (function (s) {
                          return !Uuid.equal(s.uuid, shift.uuid);
                        })),
                  territoryUuid: worker.territoryUuid
                });
    }
    
  };
  var onClick = function (param) {
    if (mode === "View") {
      return setShowForm(function (param) {
                  return true;
                });
    } else {
      return updateConstructorWorker(worker.uuid, day);
    }
  };
  var handleResponse = function (response) {
    unlock();
    if (response.TAG === "Ok") {
      notifyOk(wire, shiftUpdated$p(ctx));
      var shift = response._0.shift;
      var prevShift = RCore.$$Array.getBy(worker.shifts, (function (s) {
              return Uuid.equal(s.uuid, shift.uuid);
            }));
      if (prevShift !== undefined) {
        return updateWorker({
                    uuid: worker.uuid,
                    name: worker.name,
                    shifts: Belt_Array.concatMany([
                          RCore.$$Array.keep(worker.shifts, (function (s) {
                                  return !Uuid.equal(s.uuid, prevShift.uuid);
                                })),
                          [shift]
                        ]),
                    territoryUuid: worker.territoryUuid
                  });
      } else {
        return updateWorker({
                    uuid: worker.uuid,
                    name: worker.name,
                    shifts: Belt_Array.concatMany([
                          worker.shifts,
                          [shift]
                        ]),
                    territoryUuid: worker.territoryUuid
                  });
      }
    }
    var match = response._0;
    if (typeof match === "object" && match.TAG === "HTTPError") {
      var tmp = match._0[0];
      if (typeof tmp !== "object" && tmp === "UnprocessableEntity") {
        return notifyError(wire, unprocessableEntity$p(ctx));
      }
      
    }
    notifyError(wire, serverError$p(ctx));
  };
  var handleDeleteResponse = function (response) {
    unlock();
    if (response.TAG === "Ok") {
      notifyOk(wire, shiftUpdated$p(ctx));
      return deleteShift();
    }
    console.log(response._0);
    notifyError(wire, unprocessableEntity$p(ctx));
  };
  var submitShift = function (newShift) {
    if (newShift === undefined) {
      if (shift !== undefined) {
        lock();
        return Backend.$$finally(ShiftsShowAPI.Delete.$$delete(wire, shift), handleDeleteResponse);
      } else {
        return ;
      }
    }
    if (shift !== undefined && Caml_obj.equal(newShift, shift)) {
      return ;
    }
    lock();
    Backend.$$finally(ShiftsShowAPI.PatchWorker.update(wire, worker.uuid, newShift), handleResponse);
  };
  return React.createElement("div", {
              ref: cellRef,
              className: styles.cellContainer,
              id: buttonId,
              onClick: onClick
            }, cellComponents, React.createElement(Optional.make, {
                  show: isPicked,
                  children: React.createElement("i", {
                        className: Icon.style(undefined, undefined, "check")
                      })
                }), React.createElement(Panel.make, {
                  footer: wire.footer,
                  show: showForm,
                  domId: buttonId,
                  position: position(cellRef),
                  children: React.createElement(ShiftsShow.make, {
                        owner: {
                          TAG: "Worker",
                          _0: worker
                        },
                        shift: shift,
                        selectedDay: day,
                        jobTypes: jobTypes,
                        territories: territories,
                        popoverRef: popoverRef,
                        submitShift: submitShift,
                        locked: match[0],
                        close: (function () {
                            setShowForm(function (param) {
                                  return false;
                                });
                          })
                      })
                }));
}

var make = ShiftsIndexWorkerCell;

export {
  make ,
}
/* styles Not a pure module */
