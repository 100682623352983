import React, { Component } from 'react'
import {
  SortableHeading,
  SortOptions,
} from 'planado/components/users/sortable_heading.jsx'
import { firstBy } from 'planado/utils/users'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import Link from 'rscrpt/components/common/Link/Link.mjs'

class UserTable extends Component {
  state = {
    sortName: SortOptions.DESC,
    sortPaid: SortOptions.NONE,
  }

  renderItem = (item) => {
    const { ctx } = this.props

    let ownerModifier
    if (item.isOwner) {
      ownerModifier = (
        <span className="owner">({ctx.t('js.admin.users.table.owner')})</span>
      )
    } else {
      ownerModifier = ''
    }

    let paidModifier
    if (item.isPaid) {
      paidModifier = <span className="fa fa-check text-success" />
    } else {
      paidModifier = ''
    }

    return (
      <tr key={item.userId}>
        <td>
          <Link wire={ctx.wire} pathname={item.url}>
            {item.firstName} <strong>{item.lastName}</strong>
          </Link>{' '}
          {ownerModifier}
        </td>
        <td>{item.email}</td>
        <td>{item.mobilePhone}</td>
        <td>{paidModifier}</td>
      </tr>
    )
  }

  onNameSortClicked = (newOrder) => {
    this.setState({
      sortName: newOrder,
      sortPaid: SortOptions.NONE,
    })
  }

  onPaidSortClicked = (newOrder) => {
    this.setState({
      sortName: SortOptions.NONE,
      sortPaid: newOrder,
    })
  }

  getData = () => {
    if (this.state.sortPaid != SortOptions.NONE) {
      return this.props.data
        .slice()
        .sort(
          firstBy('isPaid', this.state.sortPaid)
            .thenBy('lastName')
            .thenBy('firstName')
            .thenBy('email')
        )
    } else {
      return this.props.data
        .slice()
        .sort(
          firstBy('lastName', -this.state.sortName)
            .thenBy('firstName', -this.state.sortName)
            .thenBy('email', -this.state.sortName)
        )
    }
  }

  render = () => {
    const { ctx } = this.props
    let data = this.getData()

    return (
      <table className="table table-responsive table-hover table-user">
        <thead>
          <tr>
            <SortableHeading
              text={ctx.t('js.admin.users.table.name')}
              callback={this.onNameSortClicked}
              order={this.state.sortName}
            />
            <th>{ctx.t('js.admin.users.table.email')}</th>
            <th>{ctx.t('js.admin.users.table.mobile_phone')}</th>
            <SortableHeading
              text={ctx.t('js.admin.users.table.is_paid')}
              callback={this.onPaidSortClicked}
              order={this.state.sortPaid}
            />
          </tr>
        </thead>
        <tbody>{data.map(this.renderItem)}</tbody>
      </table>
    )
  }
}

export default withContext(UserTable)
