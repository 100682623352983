

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as DictionaryTypes from "../../../components/admin/dictionary/DictionaryTypes.mjs";
import * as Pages_Custom_Fields_Types from "../Pages_Custom_Fields_Types.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              customFields: field.required("customFields", JsonDecode.array(Pages_Custom_Fields_Types.Field.decode)),
              dictionaries: field.required("dictionaries", JsonDecode.array(DictionaryTypes.Index.Dictionary.decode))
            };
    });

var $$Response = {
  decode: decode
};

function get(wire) {
  return Backend.get(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "CustomFields",
                VAL: "Users"
              }
            });
}

var Index = {
  $$Response: $$Response,
  get: get
};

function make(fields) {
  return {
          customFields: fields.map(function (f) {
                return {
                        uuid: Uuid.toString(f.uuid),
                        name: f.name,
                        dataType: Pages_Custom_Fields_Types.DataType.toString(f.dataType),
                        fieldType: Pages_Custom_Fields_Types.FieldType.toString(f.fieldType),
                        dictionaryUuid: Js_null.fromOption(RCore.$$Option.map(f.dictionaryUuid, Uuid.toString)),
                        currency: Js_null.fromOption(f.currency),
                        useCurrencyFractionalUnit: Js_null.fromOption(f.useCurrencyFractionalUnit)
                      };
              })
        };
}

function post(wire, fields) {
  return Backend.post(undefined, undefined, undefined, true, wire, {
              NAME: "API",
              VAL: {
                NAME: "CustomFields",
                VAL: "Users"
              }
            }, {
              NAME: "Obj",
              VAL: make(fields)
            });
}

var Errors;

var Post = {
  post: post
};

export {
  Errors ,
  Index ,
  Post ,
}
/* decode Not a pure module */
