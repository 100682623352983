

import * as El from "../../libraries/El.mjs";
import * as Fun from "../../libraries/Fun.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as React from "react";
import * as Locale from "../../libraries/Locale.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DayPickerRangeController from "./DayPickerRangeController.mjs";
import * as DateRangePickerModuleCss from "/home/runner/work/planado/planado/client/rescript/components/inputs/DateRangePicker.module.css";

var styles = DateRangePickerModuleCss;

function reducer(state, action) {
  if (typeof action === "object") {
    return {
            focusedInput: action._0,
            showCalendar: state.showCalendar
          };
  }
  switch (action) {
    case "ShowCalendar" :
        return {
                focusedInput: state.focusedInput,
                showCalendar: true
              };
    case "HideCalendar" :
        return {
                focusedInput: state.focusedInput,
                showCalendar: false
              };
    case "Chosen" :
        return {
                focusedInput: "Start",
                showCalendar: false
              };
    
  }
}

function DateRangePicker(Props) {
  var wire = Props.wire;
  var startDate = Props.startDate;
  var endDate = Props.endDate;
  var onChange = Props.onChange;
  var onClear = Props.onClear;
  var minimalDate = Props.minimalDate;
  var subscriptions = wire.subscriptions;
  var showCalendar = RCore.$$Option.isNone(Fun.both(startDate, endDate));
  var focusedInput = showCalendar ? RCore.$$Option.getOr(RCore.$$Option.map(startDate, (function (param) {
                return "End";
              })), "Start") : "Start";
  var empty = {
    focusedInput: focusedInput,
    showCalendar: showCalendar
  };
  var match = React.useReducer(reducer, empty);
  var send = match[1];
  var state = match[0];
  var dateFmt = Locale.T.fmtM("Date", wire);
  var period;
  if (startDate !== undefined) {
    var start = Caml_option.valFromOption(startDate);
    if (endDate !== undefined) {
      var start$1 = dateFmt(start);
      var finish = dateFmt(Caml_option.valFromOption(endDate));
      period = start$1 + "—" + finish;
    } else {
      var start$2 = dateFmt(start);
      period = start$2 + "—";
    }
  } else {
    period = "";
  }
  var select = function (param) {
    var start = param.startDate;
    var start$1 = (start == null) ? undefined : Caml_option.some(start);
    var finish = Caml_option.nullable_to_opt(param.endDate);
    if (!(start == null) && startDate !== undefined && Locale.T.equal(start, Caml_option.valFromOption(startDate))) {
      send("Chosen");
      return onChange([
                  start$1,
                  finish
                ]);
    }
    send({
          TAG: "ChangeInput",
          _0: "End"
        });
    onChange([
          start$1,
          undefined
        ]);
  };
  var isOutsideRange;
  if (minimalDate !== undefined) {
    var date = Caml_option.valFromOption(minimalDate);
    isOutsideRange = (function (d) {
        return Locale.T.lessThan(d, date);
      });
  } else {
    isOutsideRange = (function (param) {
        return false;
      });
  }
  React.useEffect((function () {
          return subscriptions.click.subscribe(function (_event) {
                      send("HideCalendar");
                    });
        }), []);
  return React.createElement("div", {
              className: styles.root
            }, React.createElement("div", {
                  className: styles.inputBlock,
                  onClick: El.stopClick
                }, React.createElement("input", {
                      className: styles.input,
                      readOnly: true,
                      type: "text",
                      value: period,
                      onFocus: (function (_event) {
                          send("ShowCalendar");
                        })
                    }), React.createElement("div", {
                      className: styles.clear,
                      onClick: Fun.omit(onClear)
                    })), state.showCalendar ? React.createElement("div", {
                    className: styles.container
                  }, React.createElement(DayPickerRangeController.make, {
                        startDate: startDate,
                        endDate: endDate,
                        onDatesChange: select,
                        minimumNights: 0,
                        numberOfMonths: 1,
                        focusedInput: DayPickerRangeController.FocusedInput.toString(state.focusedInput),
                        hideKeyboardShortcutsPanel: true,
                        navPrev: React.createElement("span", undefined),
                        navNext: React.createElement("span", undefined),
                        daySize: 31,
                        isOutsideRange: isOutsideRange
                      })) : null);
}

var make = DateRangePicker;

export {
  make ,
}
/* styles Not a pure module */
