

import * as RCore from "../../libraries/RCore.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";

var emptyErrors_currentPassword = [];

var emptyErrors_password = [];

var emptyErrors_passwordConfirmation = [];

var emptyErrors = {
  currentPassword: emptyErrors_currentPassword,
  password: emptyErrors_password,
  passwordConfirmation: emptyErrors_passwordConfirmation
};

var decode = JsonDecode.object(function (field) {
      return {
              currentPassword: RCore.$$Option.getOr(field.optional("currentPassword", JsonDecode.array(JsonDecode.string)), []),
              password: RCore.$$Option.getOr(field.optional("password", JsonDecode.array(JsonDecode.string)), []),
              passwordConfirmation: RCore.$$Option.getOr(field.optional("passwordConfirmation", JsonDecode.array(JsonDecode.string)), [])
            };
    });

var $$Error = {
  emptyErrors: emptyErrors,
  decode: decode
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              errors: RCore.$$Option.getOr(field.optional("errors", decode), emptyErrors)
            };
    });

var $$Response = {
  $$Error: $$Error,
  decode: decode$1
};

function request(params) {
  return {
          currentPassword: params.currentPassword,
          password: params.password,
          passwordConfirmation: params.passwordConfirmation
        };
}

function make(wire, params) {
  return Backend.decode(Backend.patch(undefined, true, undefined, true, wire, {
                  NAME: "API",
                  VAL: "Password"
                }, {
                  NAME: "Obj",
                  VAL: request(params)
                }), decode$1);
}

var $$Request = {
  request: request,
  make: make
};

var Patch = {
  $$Response: $$Response,
  $$Request: $$Request
};

export {
  Patch ,
}
/* decode Not a pure module */
