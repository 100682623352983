import { jobs } from 'planado/schedule/utils/repositories'
import 'jquery-ui/ui/widgets/draggable'
import 'jquery-ui/ui/widgets/droppable'
import 'jquery-ui/ui/effects/effect-blind'
import { useSubscription } from 'rscrpt/libraries/Websocket.mjs'

function updateJob(job) {
  const existing = jobs.get(job.uuid)
  existing.set(job, { fromConsumer: true })
}

export function subscribe(channel) {
  useSubscription(channel, 'job_created', (j) => jobs.add(j))
  useSubscription(channel, 'job_updated', updateJob)
  useSubscription(channel, 'job_removed', ({ uuid }) => jobs.remove(uuid))
}
