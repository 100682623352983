

import * as Units from "../../../../../../../../../types/Units.mjs";
import * as React from "react";
import * as WeekTimeline_IntervalModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/timeline/bar/components/interval/WeekTimeline_Interval.module.css";

var styles = WeekTimeline_IntervalModuleCss;

function make(param) {
  return {
          left: Units.Px.toString(param.left),
          width: Units.Px.toString(Units.Px.max(param.width, Units.Px.fromInt(2)))
        };
}

function WeekTimeline_Interval(Props) {
  var position = Props.position;
  return React.createElement("div", {
              className: styles.interval,
              style: make(position)
            });
}

var make$1 = React.memo(WeekTimeline_Interval);

export {
  make$1 as make,
}
/* styles Not a pure module */
