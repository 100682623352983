

import * as Modal from "../../../../../../../../modal/Modal.mjs";
import * as Utils from "../../../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Carousel from "../../../../../../../../common/Carousel.mjs";
import * as Optional from "../../../../../../../../common/Optional.mjs";
import * as FileInput_Preview from "../../../../../../../../file/file_input/components/preview/FileInput_Preview.mjs";
import * as JobForm_ReportFieldSignatureModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/edit/form/components/reports/report_field/components/signature/JobForm_ReportFieldSignature.module.css";

var styles = JobForm_ReportFieldSignatureModuleCss;

var signatureNotFilled$p = Utils.Translations.tr("js.jobs.report_fields.signature_not_filled");

function JobForm_ReportFieldSignature(Props) {
  var wire = Props.wire;
  var images = Props.images;
  var removeFile = Props.removeFile;
  var readOnly = Props.readOnly;
  var match = React.useState(function () {
        return false;
      });
  var toggleShowCarousel = match[1];
  var toggleShowCarousel$1 = function (param) {
    toggleShowCarousel(function (show) {
          return !show;
        });
  };
  if (images.length !== 1) {
    return React.createElement("i", {
                className: styles.noSignature
              }, signatureNotFilled$p(wire.ctx));
  }
  var match$1 = images[0];
  var url = match$1.url;
  var name = match$1.name;
  var file_uuid = match$1.uuid;
  var file_previewUrl = match$1.previewUrl;
  var file_size = match$1.size;
  var file_width = match$1.width;
  var file_height = match$1.height;
  var file = {
    uuid: file_uuid,
    name: name,
    url: url,
    previewUrl: file_previewUrl,
    size: file_size,
    width: file_width,
    height: file_height
  };
  return React.createElement("div", {
              className: styles.images
            }, React.createElement(FileInput_Preview.make, {
                  file: file,
                  removeFile: removeFile,
                  showImage: toggleShowCarousel$1,
                  readOnly: readOnly
                }), React.createElement(Optional.make, {
                  show: match[0],
                  children: React.createElement(Modal.make, {
                        wire: wire,
                        onClose: toggleShowCarousel$1,
                        children: React.createElement(Carousel.make, {
                              views: url !== undefined ? [Carousel.View.make(name, url)] : [],
                              currentIndex: 0,
                              isModal: true,
                              components: {
                                Footer: (function (param) {
                                    return null;
                                  })
                              },
                              frameProps: {
                                accessibility: false
                              },
                              modalProps: {
                                onClose: toggleShowCarousel$1
                              },
                              trackProps: {
                                onViewChange: (function (param) {
                                    
                                  })
                              },
                              styles: Carousel.Styles.make(undefined)
                            })
                      })
                }));
}

var make = JobForm_ReportFieldSignature;

export {
  make ,
}
/* styles Not a pure module */
