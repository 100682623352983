

import * as Fun from "../../../../../../libraries/Fun.mjs";
import * as React from "react";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as Shared_Lib_Option from "../../../../../../shared/lib/Shared_Lib_Option.mjs";
import * as DayTimeline_BusinessHoursModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/day_timeline/components/business_hours/DayTimeline_BusinessHours.module.css";

function DayTimeline_BusinessHours$ShowIcon(Props) {
  return React.createElement("svg", {
              height: "6",
              width: "7",
              fill: "none",
              viewBox: "0 0 7 6",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M1.43867 3.00359L0.152883 4.77444C-0.0509612 5.05518 -0.0509613 5.50869 0.152883 5.78943C0.356727 6.07017 0.686013 6.07017 0.889858 5.78943L2.55197 3.50749C2.75581 3.22675 2.75581 2.77324 2.55197 2.4925L0.889858 0.210557C0.686014 -0.0701861 0.356727 -0.0701861 0.152883 0.210557C-0.050961 0.491301 -0.0509611 0.944809 0.152883 1.22555L1.43867 3.00359ZM5.56133 2.99641L6.84712 1.22556C7.05096 0.944821 7.05096 0.491312 6.84712 0.210568C6.64327 -0.0701752 6.31399 -0.0701752 6.11014 0.210568L4.44803 2.49251C4.24418 2.77325 4.24418 3.22676 4.44803 3.5075L6.10492 5.78944C6.30876 6.07019 6.63805 6.07019 6.84189 5.78944C7.04573 5.5087 7.04573 5.05519 6.84189 4.77445L5.56133 2.99641Z",
                  fill: "#23272d"
                }));
}

function DayTimeline_BusinessHours$HideIcon(Props) {
  return React.createElement("svg", {
              height: "6",
              width: "7",
              fill: "none",
              viewBox: "0 0 7 6",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M5.88989 3L4.7626 4.76872C4.58389 5.04913 4.58389 5.5021 4.7626 5.7825C4.94132 6.06291 5.23002 6.06291 5.40873 5.7825L6.86596 3.5033C7.04468 3.22289 7.04468 2.76992 6.86596 2.48951L5.40873 0.210306C5.23002 -0.0701019 4.94132 -0.0701019 4.7626 0.210306C4.58389 0.490713 4.58389 0.943679 4.7626 1.22409L5.88989 3ZM1.11011 3L2.2374 1.23128C2.41612 0.950869 2.41612 0.497903 2.2374 0.217496C2.05868 -0.062912 1.76999 -0.062912 1.59127 0.217495L0.134038 2.4967C-0.0446788 2.77711 -0.0446788 3.23008 0.134038 3.51049L1.58669 5.78969C1.7654 6.0701 2.0541 6.0701 2.23282 5.78969C2.41153 5.50929 2.41153 5.05632 2.23282 4.77591L1.11011 3Z",
                  fill: "#23272d"
                }));
}

var styles = DayTimeline_BusinessHoursModuleCss;

function iconBlock(icon) {
  if (icon === "show") {
    return React.createElement(DayTimeline_BusinessHours$ShowIcon, {});
  } else {
    return React.createElement(DayTimeline_BusinessHours$HideIcon, {});
  }
}

function DayTimeline_BusinessHours$LeftColumn(Props) {
  var icon = Props.icon;
  var onClick = Props.onClick;
  return React.createElement("div", {
              className: styles.container
            }, React.createElement("button", {
                  className: styles.button,
                  type: "button",
                  onClick: Fun.omit(onClick)
                }, iconBlock(icon)));
}

function DayTimeline_BusinessHours$RightColumn(Props) {
  var icon = Props.icon;
  var onClick = Props.onClick;
  return React.createElement("div", {
              className: styles.container
            }, React.createElement("button", {
                  className: styles.button,
                  type: "button",
                  onClick: Fun.omit(onClick)
                }, iconBlock(icon)));
}

function DayTimeline_BusinessHours(Props) {
  var children = Props.children;
  var match = AppContext.useWire();
  var businessHours = Schedule_State.useBusinessHours();
  var setBusinessHours = Schedule_State.useSetBusinessHours();
  var match$1 = Shared_Lib_Option.align(match.ctx.businessHours, businessHours);
  var match$2;
  if (match$1 !== undefined) {
    switch (match$1.TAG) {
      case "Left" :
          var hours = match$1._0;
          var onClick = function () {
            setBusinessHours(hours);
          };
          match$2 = [
            React.createElement(DayTimeline_BusinessHours$LeftColumn, {
                  icon: "show",
                  onClick: onClick
                }),
            React.createElement(DayTimeline_BusinessHours$RightColumn, {
                  icon: "show",
                  onClick: onClick
                })
          ];
          break;
      case "Right" :
          match$2 = [
            null,
            null
          ];
          break;
      case "Both" :
          var onClick$1 = function () {
            setBusinessHours(undefined);
          };
          match$2 = [
            React.createElement(DayTimeline_BusinessHours$LeftColumn, {
                  icon: "hide",
                  onClick: onClick$1
                }),
            React.createElement(DayTimeline_BusinessHours$RightColumn, {
                  icon: "hide",
                  onClick: onClick$1
                })
          ];
          break;
      
    }
  } else {
    match$2 = [
      null,
      null
    ];
  }
  return React.createElement(React.Fragment, undefined, match$2[0], children, match$2[1]);
}

var make = React.memo(DayTimeline_BusinessHours);

export {
  make ,
}
/* styles Not a pure module */
