

import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as Schedule_Header_FilterComponent from "./Schedule_Header_FilterComponent.mjs";

var territories$p = Utils.Translations.tr("js.schedule.v2.filter.territories");

var selectedTerritories$p = Utils.Translations.tr("js.schedule.v2.filter.selected_territories");

var emptyTerritories$p = Utils.Translations.tr("js.schedule.v2.filter.empty.territories");

function Schedule_Header_FilterByTerritory(Props) {
  var filterVariants = Props.filterVariants;
  var param = Props.wire;
  var ctx = param.ctx;
  var filter = Schedule_State.useFilter();
  var setFilterTerritories = Schedule_State.useSetFilterTerritories();
  return React.createElement(Schedule_Header_FilterComponent.make, {
              filterVariants: filterVariants,
              selectedFilterVariants: filter.territories,
              setFilter: setFilterTerritories,
              label: territories$p(ctx),
              labelSelected: selectedTerritories$p(ctx),
              labelEmpty: emptyTerritories$p(ctx)
            });
}

var make = Schedule_Header_FilterByTerritory;

export {
  make ,
}
/* territories' Not a pure module */
