

import * as El from "../../libraries/El.mjs";
import * as Link from "../../components/common/Link/Link.mjs";
import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Runtime from "../../Runtime.mjs";
import * as DomUtils from "../../DomUtils.mjs";
import * as MomentRe from "../../bindings/moment/MomentRe.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as UI_Button from "../../ui/UI_Button.mjs";
import * as AppContext from "../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Pages_Audit_Types from "./Pages_Audit_Types.mjs";
import * as Pages_Audit_TableModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/audit/Pages_Audit_Table.module.css";

var id$p = Utils.Translations.tr("js.admin.audit_log.heading.id");

var action$p = Utils.Translations.tr("js.admin.audit_log.heading.action");

var user$p = Utils.Translations.tr("js.admin.audit_log.heading.user");

var details$p = Utils.Translations.tr("js.admin.audit_log.heading.details");

var ip$p = Utils.Translations.tr("js.admin.audit_log.heading.ip");

var userAgent$p = Utils.Translations.tr("js.admin.audit_log.heading.user_agent");

var createdAt$p = Utils.Translations.tr("js.admin.audit_log.heading.created_at");

function actionValue$p(action) {
  return Utils.Translations.tr("js.admin.audit_log.actions." + Pages_Audit_Types.Log.Action.toString(action));
}

var clientAction$p = Utils.Translations.txr("js.admin.audit_log.details.client");

var jobAction$p = Utils.Translations.txr("js.admin.audit_log.details.job");

var jobsExportedFinished$p = Utils.Translations.tr("js.admin.audit_log.details.jobs_exported_finished");

var jobsExportedNotFinished$p = Utils.Translations.tr("js.admin.audit_log.details.jobs_exported_not_finished");

var siteAction$p = Utils.Translations.txr("js.admin.audit_log.details.site");

var userAction$p = Utils.Translations.txr("js.admin.audit_log.details.user");

var styles = Pages_Audit_TableModuleCss;

function Pages_Audit_Table$Identifier(Props) {
  var uuid = Props.uuid;
  var keyRef = React.useRef(null);
  var strUuid = Uuid.toString(uuid);
  var copy = function (param) {
    Runtime.Timeout.nextTick(function () {
          var el = keyRef.current;
          var el$1 = (el == null) ? undefined : Caml_option.some(el);
          Utils.copyToClipboard(el$1);
          RCore.$$Option.map(el$1, (function (el) {
                  DomUtils.blur(el, undefined);
                }));
        });
  };
  return React.createElement(UI_Button.make, {
              flavor: "link",
              className: styles.idButton,
              onClick: copy,
              children: null
            }, React.createElement("input", {
                  ref: keyRef,
                  "aria-hidden": true,
                  className: styles.idTextArea,
                  readOnly: true,
                  type: "text",
                  value: strUuid,
                  onChange: (function (param) {
                      
                    })
                }), Js_string.slice(0, 8, strUuid) + "...");
}

function Pages_Audit_Table$Details(Props) {
  var log = Props.log;
  var wire = Props.wire;
  var match = log.action;
  var match$1 = Pages_Audit_Types.Log.target(log);
  if (match === "inviteSent" || match === "userRemovedWeb") {
    var n = match$1.name;
    if (n !== undefined) {
      return userAction$p({
                  name: n
                }, wire.ctx);
    }
    
  } else if (match === "siteRemovedWeb") {
    var n$1 = match$1.name;
    if (n$1 !== undefined) {
      return siteAction$p({
                  name: n$1
                }, wire.ctx);
    }
    
  } else if (match === "userUpdatedWeb" || match === "userCreatedWeb") {
    var u = match$1.uuid;
    if (u !== undefined) {
      var n$2 = match$1.name;
      if (n$2 !== undefined) {
        return React.createElement(Link.make, {
                    wire: wire,
                    route: {
                      NAME: "User",
                      VAL: {
                        NAME: "Custom",
                        VAL: {
                          NAME: "Edit",
                          VAL: Caml_option.valFromOption(u)
                        }
                      }
                    },
                    target: "blank",
                    children: userAction$p({
                          name: n$2
                        }, wire.ctx)
                  });
      }
      
    }
    
  } else if (match === "jobRemovedWeb") {
    var s = match$1.serialNo;
    if (s !== undefined) {
      return jobAction$p({
                  serial_no: s
                }, wire.ctx);
    }
    
  } else if (match === "clientRemovedWeb") {
    var n$3 = match$1.name;
    if (n$3 !== undefined) {
      return clientAction$p({
                  name: n$3
                }, wire.ctx);
    }
    
  }
  var match$2 = log.action;
  var match$3 = Pages_Audit_Types.Log.result(log);
  if (match$2 !== "jobsExported") {
    return null;
  }
  var uuid = match$3.fileUuid;
  if (uuid !== undefined) {
    return React.createElement(Link.make, {
                wire: wire,
                route: {
                  NAME: "File",
                  VAL: Caml_option.valFromOption(uuid)
                },
                target: "blank",
                children: jobsExportedFinished$p(wire.ctx)
              });
  } else {
    return jobsExportedNotFinished$p(wire.ctx);
  }
}

function Pages_Audit_Table(Props) {
  var logs = Props.logs;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  return React.createElement("table", undefined, React.createElement("thead", undefined, React.createElement("tr", {
                      className: styles.tableRow
                    }, React.createElement("th", {
                          className: El.Cn.concat(styles.idColumn, styles.tableHeader)
                        }, id$p(ctx)), React.createElement("th", {
                          className: El.Cn.concat(styles.actionColumn, styles.tableHeader)
                        }, action$p(ctx)), React.createElement("th", {
                          className: El.Cn.concat(styles.userColumn, styles.tableHeader)
                        }, user$p(ctx)), React.createElement("th", {
                          className: El.Cn.concat(styles.detailsColumn, styles.tableHeader)
                        }, details$p(ctx)), React.createElement("th", {
                          className: El.Cn.concat(styles.ipColumn, styles.tableHeader)
                        }, ip$p(ctx)), React.createElement("th", {
                          className: El.Cn.concat(styles.userAgentColumn, styles.tableHeader)
                        }, userAgent$p(ctx)), React.createElement("th", {
                          className: El.Cn.concat(styles.createdAtColumn, styles.tableHeader)
                        }, createdAt$p(ctx)))), React.createElement("tbody", undefined, logs.map(function (l) {
                      return React.createElement("tr", {
                                  key: Uuid.toString(l.uuid),
                                  className: styles.tableRow
                                }, React.createElement("td", {
                                      className: styles.idColumn
                                    }, React.createElement(Pages_Audit_Table$Identifier, {
                                          uuid: l.uuid
                                        })), React.createElement("td", {
                                      className: styles.actionColumn
                                    }, actionValue$p(l.action)(ctx)), React.createElement("td", {
                                      className: styles.userColumn
                                    }, React.createElement(Link.make, {
                                          wire: wire,
                                          route: {
                                            NAME: "User",
                                            VAL: {
                                              NAME: "Custom",
                                              VAL: {
                                                NAME: "Edit",
                                                VAL: l.user.uuid
                                              }
                                            }
                                          },
                                          target: "blank",
                                          children: l.user.name
                                        })), React.createElement("td", {
                                      className: styles.detailsColumn
                                    }, React.createElement(Pages_Audit_Table$Details, {
                                          log: l,
                                          wire: wire
                                        })), React.createElement("td", {
                                      className: styles.ipColumn
                                    }, l.ipAddress), React.createElement("td", {
                                      className: styles.userAgentColumn
                                    }, l.userAgent), React.createElement("td", {
                                      className: styles.createdAtColumn
                                    }, MomentRe.Moment.format(l.createdAt, "D MMMM HH:mm:ss")));
                    })));
}

var make = Pages_Audit_Table;

export {
  make ,
}
/* id' Not a pure module */
