

import * as Icon from "../../Icon.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Context_Types from "../../context/Context_Types.mjs";
import * as HeaderModuleCss from "/home/runner/work/planado/planado/client/rescript/components/header/Header.module.css";

function stepName$p(step, ctx) {
  return Utils.Translations.tr("js.success_path.steps." + Context_Types.SuccessPath.Step.code(step))(ctx);
}

var styles = HeaderModuleCss;

function HeaderSuccessPath(Props) {
  var param = Props.wire;
  var successPath = Props.successPath;
  var ctx = param.ctx;
  return React.createElement("ul", {
              className: styles.successPath + " " + styles.drop
            }, successPath.steps.map(function (step, idx) {
                  return React.createElement("li", {
                              key: idx.toString()
                            }, Context_Types.SuccessPath.Step.complete(step) ? React.createElement("span", {
                                    className: styles.successPathIcon + " " + styles.checked
                                  }, React.createElement("i", {
                                        className: Icon.style("lg", undefined, "check")
                                      })) : React.createElement("span", {
                                    className: styles.successPathIcon
                                  }, React.createElement("i", {
                                        className: Icon.style("lg", undefined, "check")
                                      })), stepName$p(step, ctx));
                }));
}

var SuccessPath;

var make = HeaderSuccessPath;

export {
  SuccessPath ,
  make ,
}
/* styles Not a pure module */
