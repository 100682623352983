

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Locale from "../../../libraries/Locale.mjs";
import * as MomentRe from "../../../bindings/moment/MomentRe.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function withActualTempWorkers(assignees, availableAt, allAssignees) {
  return assignees.map(function (a) {
              if (a.TAG !== "Team") {
                return {
                        TAG: "Worker",
                        _0: a._0
                      };
              }
              var t = a._0;
              var match = RCore.$$Array.getBy(allAssignees, (function (a) {
                      if (a.TAG === "Team") {
                        return Uuid.equal(a._0.uuid, t.uuid);
                      } else {
                        return false;
                      }
                    }));
              if (availableAt !== undefined && match !== undefined) {
                var at = Caml_option.valFromOption(availableAt);
                if (match.TAG === "Team") {
                  var temporaryWorkers = match._0.temporaryWorkers;
                  return {
                          TAG: "Team",
                          _0: {
                            uuid: t.uuid,
                            name: t.name,
                            foreman: t.foreman,
                            workers: RCore.$$Array.keep(t.workers, (function (w) {
                                    return !temporaryWorkers.some(function (temp) {
                                                return Uuid.equal(temp.uuid, w.uuid);
                                              });
                                  })),
                            temporaryWorkers: RCore.$$Array.keepMap(temporaryWorkers, (function (w) {
                                    if (Locale.T.equalOrLessThan(Locale.T.startOf("day", MomentRe.makeFromJsDate(w.dateFrom)), at) && Locale.T.moreThan(Locale.T.endOf("day", MomentRe.makeFromJsDate(w.dateTo)), at)) {
                                      return w;
                                    }
                                    
                                  })),
                            skills: t.skills,
                            territory: t.territory
                          }
                        };
                }
                
              }
              return {
                      TAG: "Team",
                      _0: {
                        uuid: t.uuid,
                        name: t.name,
                        foreman: t.foreman,
                        workers: t.workers,
                        temporaryWorkers: [],
                        skills: t.skills,
                        territory: t.territory
                      }
                    };
            });
}

export {
  withActualTempWorkers ,
}
/* Uuid Not a pure module */
