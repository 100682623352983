

import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Optional from "../../../components/common/Optional.mjs";
import * as FormInput from "../../../components/inputs/FormInput.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Context_Types from "../../../context/Context_Types.mjs";
import * as Types_Address from "../../../types/Types_Address.mjs";
import * as UI_AddressField from "../../../ui/UI_AddressField.mjs";
import * as AddressInput_Types from "../../../components/address_input/AddressInput_Types.mjs";
import * as Pages_Client_Types from "./Pages_Client_Types.mjs";
import * as IndexJsx from "planado/components/input/territories_select/index.jsx";

var apartmentOrganization$p = Utils.Translations.t("js.clients.labels.apartment_organization");

var apartmentIndividual$p = Utils.Translations.t("js.clients.labels.apartment_individual");

var floor$p = Utils.Translations.t("js.clients.labels.floor");

var entranceNo$p = Utils.Translations.t("js.clients.labels.entrance_no");

var territory$p = Utils.Translations.tr("js.clients.labels.territory");

var territoryPlaceholder$p = Utils.Translations.t("js.clients.show.info.territory_placeholder");

var addressDescription$p = Utils.Translations.t("js.sites.show.labels.address_description");

function Pages_Client_AddressBlock(Props) {
  var state = Props.state;
  var setState = Props.setState;
  var wire = Props.wire;
  var client = state.client;
  var ctx = wire.ctx;
  return React.createElement(React.Fragment, undefined, React.createElement(UI_AddressField.make, {
                  suggestionsAmount: 5,
                  constraints: state.addressConstraints,
                  inputName: "client[address]",
                  readOnly: !state.canManage,
                  address: state.client.address,
                  onChangeDadata: (function (address) {
                      setState(function (current) {
                            var init = current.client;
                            return {
                                    limits: current.limits,
                                    canManage: current.canManage,
                                    locked: current.locked,
                                    client: {
                                      uuid: init.uuid,
                                      isNewRecord: init.isNewRecord,
                                      organization: init.organization,
                                      showingExternalId: init.showingExternalId,
                                      externalId: init.externalId,
                                      name: init.name,
                                      firstName: init.firstName,
                                      lastName: init.lastName,
                                      middleName: init.middleName,
                                      organizationName: init.organizationName,
                                      contacts: init.contacts,
                                      floor: init.floor,
                                      apartment: init.apartment,
                                      addressDescription: init.addressDescription,
                                      entranceNo: init.entranceNo,
                                      territoryUuid: init.territoryUuid,
                                      availableTerritories: init.availableTerritories,
                                      createdAt: init.createdAt,
                                      address: Types_Address.fromInputType(AddressInput_Types.RegularAddress.makeFromJs(address))
                                    },
                                    addressConstraints: current.addressConstraints,
                                    errors: current.errors
                                  };
                          });
                    }),
                  onChangeGoogle: (function (address) {
                      setState(function (current) {
                            var init = current.client;
                            return {
                                    limits: current.limits,
                                    canManage: current.canManage,
                                    locked: current.locked,
                                    client: {
                                      uuid: init.uuid,
                                      isNewRecord: init.isNewRecord,
                                      organization: init.organization,
                                      showingExternalId: init.showingExternalId,
                                      externalId: init.externalId,
                                      name: init.name,
                                      firstName: init.firstName,
                                      lastName: init.lastName,
                                      middleName: init.middleName,
                                      organizationName: init.organizationName,
                                      contacts: init.contacts,
                                      floor: init.floor,
                                      apartment: init.apartment,
                                      addressDescription: init.addressDescription,
                                      entranceNo: init.entranceNo,
                                      territoryUuid: init.territoryUuid,
                                      availableTerritories: init.availableTerritories,
                                      createdAt: init.createdAt,
                                      address: RCore.$$Option.map(address === null ? undefined : Caml_option.some(address), (function (js) {
                                              return Types_Address.fromInputType(AddressInput_Types.RegularAddress.makeFromJs(js));
                                            }))
                                    },
                                    addressConstraints: current.addressConstraints,
                                    errors: current.errors
                                  };
                          });
                    })
                }), React.createElement("div", {
                  className: "pd-form-group-container"
                }, React.createElement(FormInput.make, {
                      _type: "text",
                      value: Pages_Client_Types.fieldToString(client.apartment),
                      onValueChange: (function (value) {
                          setState(function (current) {
                                var init = current.client;
                                return {
                                        limits: current.limits,
                                        canManage: current.canManage,
                                        locked: current.locked,
                                        client: {
                                          uuid: init.uuid,
                                          isNewRecord: init.isNewRecord,
                                          organization: init.organization,
                                          showingExternalId: init.showingExternalId,
                                          externalId: init.externalId,
                                          name: init.name,
                                          firstName: init.firstName,
                                          lastName: init.lastName,
                                          middleName: init.middleName,
                                          organizationName: init.organizationName,
                                          contacts: init.contacts,
                                          floor: init.floor,
                                          apartment: value,
                                          addressDescription: init.addressDescription,
                                          entranceNo: init.entranceNo,
                                          territoryUuid: init.territoryUuid,
                                          availableTerritories: init.availableTerritories,
                                          createdAt: init.createdAt,
                                          address: init.address
                                        },
                                        addressConstraints: current.addressConstraints,
                                        errors: current.errors
                                      };
                              });
                        }),
                      id: "client-apartment",
                      readOnly: !state.canManage,
                      name: "client[apartment]",
                      labelText: client.organization ? apartmentOrganization$p(ctx) : apartmentIndividual$p(ctx),
                      maxLength: state.limits.apartment,
                      wrapperClass: Js_dict.fromList({
                            hd: [
                              "pd-form-group-sm",
                              true
                            ],
                            tl: /* [] */0
                          })
                    }), React.createElement(FormInput.make, {
                      _type: "text",
                      value: Pages_Client_Types.fieldToString(client.floor),
                      onValueChange: (function (value) {
                          setState(function (current) {
                                var init = current.client;
                                return {
                                        limits: current.limits,
                                        canManage: current.canManage,
                                        locked: current.locked,
                                        client: {
                                          uuid: init.uuid,
                                          isNewRecord: init.isNewRecord,
                                          organization: init.organization,
                                          showingExternalId: init.showingExternalId,
                                          externalId: init.externalId,
                                          name: init.name,
                                          firstName: init.firstName,
                                          lastName: init.lastName,
                                          middleName: init.middleName,
                                          organizationName: init.organizationName,
                                          contacts: init.contacts,
                                          floor: value,
                                          apartment: init.apartment,
                                          addressDescription: init.addressDescription,
                                          entranceNo: init.entranceNo,
                                          territoryUuid: init.territoryUuid,
                                          availableTerritories: init.availableTerritories,
                                          createdAt: init.createdAt,
                                          address: init.address
                                        },
                                        addressConstraints: current.addressConstraints,
                                        errors: current.errors
                                      };
                              });
                        }),
                      id: "client-floor",
                      readOnly: !state.canManage,
                      name: "client[site_address_floor]",
                      labelText: floor$p(ctx),
                      maxLength: state.limits.floor,
                      wrapperClass: Js_dict.fromList({
                            hd: [
                              "pd-form-group-sm",
                              true
                            ],
                            tl: /* [] */0
                          })
                    }), React.createElement(FormInput.make, {
                      _type: "text",
                      value: Pages_Client_Types.fieldToString(client.entranceNo),
                      onValueChange: (function (value) {
                          setState(function (current) {
                                var init = current.client;
                                return {
                                        limits: current.limits,
                                        canManage: current.canManage,
                                        locked: current.locked,
                                        client: {
                                          uuid: init.uuid,
                                          isNewRecord: init.isNewRecord,
                                          organization: init.organization,
                                          showingExternalId: init.showingExternalId,
                                          externalId: init.externalId,
                                          name: init.name,
                                          firstName: init.firstName,
                                          lastName: init.lastName,
                                          middleName: init.middleName,
                                          organizationName: init.organizationName,
                                          contacts: init.contacts,
                                          floor: init.floor,
                                          apartment: init.apartment,
                                          addressDescription: init.addressDescription,
                                          entranceNo: value,
                                          territoryUuid: init.territoryUuid,
                                          availableTerritories: init.availableTerritories,
                                          createdAt: init.createdAt,
                                          address: init.address
                                        },
                                        addressConstraints: current.addressConstraints,
                                        errors: current.errors
                                      };
                              });
                        }),
                      id: "client-entrance_no",
                      readOnly: !state.canManage,
                      name: "client[entrance_no]",
                      labelText: entranceNo$p(ctx),
                      maxLength: state.limits.entranceNo,
                      wrapperClass: Js_dict.fromList({
                            hd: [
                              "pd-form-group-sm",
                              true
                            ],
                            tl: /* [] */0
                          })
                    }), React.createElement(Optional.make, {
                      show: Context_Types.Features.hasFlag("territories", ctx.features),
                      children: React.createElement("div", {
                            className: "pd-form-group pd-form-group-half"
                          }, React.createElement("label", {
                                className: "pd-label",
                                htmlFor: "client-territory"
                              }, territory$p(ctx)), React.createElement(IndexJsx.TerritoriesSelect, {
                                inputName: "client[territories]",
                                selectedTerritoryUuid: client.territoryUuid,
                                availableTerritories: client.availableTerritories,
                                disabled: !state.canManage,
                                onChange: (function (value) {
                                    setState(function (current) {
                                          var init = current.client;
                                          return {
                                                  limits: current.limits,
                                                  canManage: current.canManage,
                                                  locked: current.locked,
                                                  client: {
                                                    uuid: init.uuid,
                                                    isNewRecord: init.isNewRecord,
                                                    organization: init.organization,
                                                    showingExternalId: init.showingExternalId,
                                                    externalId: init.externalId,
                                                    name: init.name,
                                                    firstName: init.firstName,
                                                    lastName: init.lastName,
                                                    middleName: init.middleName,
                                                    organizationName: init.organizationName,
                                                    contacts: init.contacts,
                                                    floor: init.floor,
                                                    apartment: init.apartment,
                                                    addressDescription: init.addressDescription,
                                                    entranceNo: init.entranceNo,
                                                    territoryUuid: value,
                                                    availableTerritories: init.availableTerritories,
                                                    createdAt: init.createdAt,
                                                    address: init.address
                                                  },
                                                  addressConstraints: current.addressConstraints,
                                                  errors: current.errors
                                                };
                                        });
                                  }),
                                placeholder: territoryPlaceholder$p(ctx)
                              }))
                    })), React.createElement(FormInput.make, {
                  _type: "textarea",
                  value: Pages_Client_Types.fieldToString(client.addressDescription),
                  onValueChange: (function (v) {
                      if (Utils.stringLengthWithNewLines(v) <= state.limits.addressDescription) {
                        return setState(function (current) {
                                    var init = current.client;
                                    return {
                                            limits: current.limits,
                                            canManage: current.canManage,
                                            locked: current.locked,
                                            client: {
                                              uuid: init.uuid,
                                              isNewRecord: init.isNewRecord,
                                              organization: init.organization,
                                              showingExternalId: init.showingExternalId,
                                              externalId: init.externalId,
                                              name: init.name,
                                              firstName: init.firstName,
                                              lastName: init.lastName,
                                              middleName: init.middleName,
                                              organizationName: init.organizationName,
                                              contacts: init.contacts,
                                              floor: init.floor,
                                              apartment: init.apartment,
                                              addressDescription: v,
                                              entranceNo: init.entranceNo,
                                              territoryUuid: init.territoryUuid,
                                              availableTerritories: init.availableTerritories,
                                              createdAt: init.createdAt,
                                              address: init.address
                                            },
                                            addressConstraints: current.addressConstraints,
                                            errors: current.errors
                                          };
                                  });
                      }
                      
                    }),
                  id: "client-address_description",
                  readOnly: !state.canManage,
                  name: "client[address_description]",
                  labelText: addressDescription$p(ctx),
                  maxLength: state.limits.addressDescription,
                  inputClass: Js_dict.fromList({
                        hd: [
                          "pd-form-control",
                          true
                        ],
                        tl: /* [] */0
                      })
                }));
}

var make = Pages_Client_AddressBlock;

export {
  make ,
}
/* apartmentOrganization' Not a pure module */
