

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Locale from "../../libraries/Locale.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as JsContext from "../../libraries/JsContext.mjs";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";
import * as Types_Date from "../../types/Types_Date.mjs";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as MultipleAssignee from "../../types/MultipleAssignee.mjs";
import * as $$Map from "planado/stores/map";

var decode = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var Territory = {
  decode: decode
};

var UnknownStatus = /* @__PURE__ */Caml_exceptions.create("MapTypes.Job.Status.UnknownStatus");

function fromString(status) {
  switch (status) {
    case "assigned" :
        return "Assigned";
    case "changed" :
        return "Changed";
    case "en_route" :
        return "EnRoute";
    case "finished" :
        return "Finished";
    case "posted" :
        return "Posted";
    case "published" :
        return "Published";
    case "scheduled" :
        return "Scheduled";
    case "started" :
        return "Started";
    case "suspended" :
        return "Suspended";
    default:
      return ;
  }
}

function toString(status) {
  switch (status) {
    case "Posted" :
        return "posted";
    case "Scheduled" :
        return "scheduled";
    case "Assigned" :
        return "assigned";
    case "Changed" :
        return "changed";
    case "Published" :
        return "published";
    case "EnRoute" :
        return "en_route";
    case "Started" :
        return "started";
    case "Suspended" :
        return "suspended";
    case "Finished" :
        return "finished";
    
  }
}

var decode$1 = JsonDecode.fromVariant(JsonDecode.string, fromString);

var Status = {
  UnknownStatus: UnknownStatus,
  fromString: fromString,
  toString: toString,
  decode: decode$1
};

var decode$2 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var Site = {
  decode: decode$2
};

var decode$3 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var Client = {
  decode: decode$3
};

var decode$4 = JsonDecode.object(function (field) {
      return {
              longitude: field.required("longitude", JsonDecode.$$float),
              latitude: field.required("latitude", JsonDecode.$$float)
            };
    });

var $$Geolocation = {
  decode: decode$4
};

var decode$5 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              status: field.required("status", decode$1),
              serialNo: field.required("serialNo", JsonDecode.$$int),
              clientUuid: field.required("clientUuid", JsonDecode.option(Uuid.decode)),
              clientName: field.required("clientName", JsonDecode.option(JsonDecode.string)),
              site: field.required("site", JsonDecode.option(decode$2)),
              client: field.required("client", JsonDecode.option(decode$3)),
              formattedAddress: field.required("formattedAddress", JsonDecode.option(JsonDecode.string)),
              location: field.required("location", JsonDecode.option(decode$4)),
              apartment: field.required("apartment", JsonDecode.option(JsonDecode.string)),
              floor: field.required("floor", JsonDecode.option(JsonDecode.string)),
              entranceNo: field.required("entranceNo", JsonDecode.option(JsonDecode.string)),
              addressDescription: field.required("addressDescription", JsonDecode.option(JsonDecode.string)),
              scheduledAt: field.required("scheduledAt", JsonDecode.option(Types_Date.decode)),
              scheduledFinishAt: field.required("scheduledFinishAt", JsonDecode.option(Types_Date.decode)),
              enRouteAt: field.required("enRouteAt", JsonDecode.option(Types_Date.decode)),
              startedAt: field.required("startedAt", JsonDecode.option(Types_Date.decode)),
              finishedAt: field.required("finishedAt", JsonDecode.option(Types_Date.decode)),
              lastActivityAt: field.required("lastActivityAt", JsonDecode.option(Types_Date.decode)),
              templateName: field.required("templateName", JsonDecode.option(JsonDecode.string)),
              templateUuid: field.required("templateUuid", JsonDecode.option(Uuid.decode)),
              assignees: field.required("assignees", MultipleAssignee.decode),
              resolution: field.required("resolution", JsonDecode.option(JsonDecode.string)),
              resolutionUuid: field.required("resolutionUuid", JsonDecode.option(Uuid.decode)),
              successful: field.required("successful", JsonDecode.bool),
              territoryUuid: field.required("territoryUuid", JsonDecode.option(Uuid.decode)),
              skillUuids: field.required("skillUuids", JsonDecode.array(Uuid.decode)),
              typeUuid: field.required("typeUuid", JsonDecode.option(Uuid.decode))
            };
    });

var Job = {
  Status: Status,
  Site: Site,
  Client: Client,
  $$Geolocation: $$Geolocation,
  decode: decode$5
};

var decode$6 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var $$Worker = {
  decode: decode$6
};

var decode$7 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              workerUuid: field.required("workerUuid", Uuid.decode),
              effectiveAt: field.required("effectiveAt", JsonDecode.string),
              longitude: field.required("longitude", JsonDecode.$$float),
              latitude: field.required("latitude", JsonDecode.$$float)
            };
    });

function fromMessage(msg) {
  return {
          uuid: msg.uuid,
          workerUuid: msg.workerUuid,
          effectiveAt: msg.effectiveAt,
          longitude: msg.longitude,
          latitude: msg.latitude
        };
}

var $$Location = {
  decode: decode$7,
  fromMessage: fromMessage
};

function make(options, name, job) {
  var match = job.location;
  if (match !== undefined) {
    return {
            type: name,
            job: {
              uuid: job.uuid,
              serialNo: job.serialNo,
              assigneeName: Js_null.fromOption(MultipleAssignee.mainAssigneeName(job.assignees)),
              scheduledAt: Js_null.fromOption(RCore.$$Option.map(job.scheduledAt, Locale.T.toIsoM)),
              scheduledFinishAt: Js_null.fromOption(RCore.$$Option.map(job.scheduledFinishAt, Locale.T.toIsoM)),
              location: {
                longitude: match.longitude,
                latitude: match.latitude
              },
              status: toString(job.status),
              colorEnabled: true
            },
            options: options
          };
  }
  
}

function make$1(wire) {
  return {
          type: "UPDATE_CONTEXT",
          ctx: JsContext.get(wire)
        };
}

function pushJobCommand(channel, comm) {
  RCore.$$Option.map(comm, (function (extra) {
          channel.push(extra);
        }));
}

function pushWorkerCommand(channel, comm) {
  channel.push(comm);
}

function addJobCommand(options, job) {
  return make(options, "ADD_JOB", job);
}

function addJob(channel) {
  return function (job, options) {
    pushJobCommand(channel, addJobCommand(options, job));
  };
}

function addWorkerCommand(extra, extra$1) {
  return {
          type: "ADD_WORKER",
          worker: extra,
          location: extra$1
        };
}

function removeWorkerCommand(extra, extra$1) {
  return {
          type: "REMOVE_WORKER",
          worker: extra,
          location: extra$1
        };
}

function removeJob(channel) {
  return function (job) {
    pushJobCommand(channel, make(undefined, "REMOVE_JOB", job));
  };
}

function locateJob(channel) {
  return function (job) {
    pushJobCommand(channel, make(undefined, "LOCATE_JOB", job));
  };
}

function addWorker(channel, worker, $$location) {
  pushWorkerCommand(channel, {
        type: "ADD_WORKER",
        worker: worker,
        location: $$location
      });
}

function removeWorker(channel, worker, $$location) {
  pushWorkerCommand(channel, {
        type: "REMOVE_WORKER",
        worker: worker,
        location: $$location
      });
}

function updateContext(wire, channel) {
  var comm = make$1(wire);
  channel.push(comm);
}

var EngineEvents = {};

var Template;

var JobType;

var EngineChannel_JobCommand = {};

var EngineChannel_WorkerCommand = {};

function EngineChannel_make(prim) {
  return $$Map.createChannel();
}

var EngineChannel = {
  JobCommand: EngineChannel_JobCommand,
  WorkerCommand: EngineChannel_WorkerCommand,
  make: EngineChannel_make,
  addJob: addJob,
  addWorkerCommand: addWorkerCommand,
  removeWorkerCommand: removeWorkerCommand,
  removeJob: removeJob,
  locateJob: locateJob,
  addWorker: addWorker,
  removeWorker: removeWorker,
  updateContext: updateContext
};

export {
  Template ,
  JobType ,
  Territory ,
  Job ,
  $$Worker ,
  $$Location ,
  EngineChannel ,
  EngineEvents ,
}
/* decode Not a pure module */
