

import * as Fun from "../../../../../../../libraries/Fun.mjs";
import * as React from "react";
import * as Assignee_Types from "../../../../../../../types/Assignee_Types.mjs";
import * as WeekTimeline_State from "../../../lib/state/WeekTimeline_State.mjs";
import * as WeekTimeline_BarDrop from "./hooks/WeekTimeline_BarDrop.mjs";
import * as WeekTimeline_BarJobs from "./components/WeekTimeline_BarJobs.mjs";
import * as WeekTimeline_BarCells from "./components/WeekTimeline_BarCells.mjs";
import * as Schedule_TimelineHooks from "../../../../../lib/hooks/Schedule_TimelineHooks.mjs";
import * as Schedule_UtilsTimeline from "../../../../../lib/utils/Schedule_UtilsTimeline.mjs";
import * as WeekTimeline_BarActiveDay from "./components/WeekTimeline_BarActiveDay.mjs";
import * as WeekTimeline_BarListeners from "./hooks/WeekTimeline_BarListeners.mjs";
import * as WeekTimeline_AddJobPopover from "./components/add_job_popover/WeekTimeline_AddJobPopover.mjs";
import * as WeekTimeline_BarModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/timeline/bar/WeekTimeline_Bar.module.css";

var styles = WeekTimeline_BarModuleCss;

function WeekTimeline_Bar(Props) {
  var viewportEl = Props.viewportEl;
  var assignee = Props.assignee;
  var jobs = Props.jobs;
  var workingIntervals = Props.workingIntervals;
  var elementRef = WeekTimeline_BarDrop.use(assignee, viewportEl);
  var match = WeekTimeline_BarListeners.use(elementRef);
  var match$1 = WeekTimeline_State.useTimelineSubscriptions();
  Schedule_TimelineHooks.BarHighlightAssignee.use(elementRef, Assignee_Types.Assignee.uuid(assignee), match$1.onActiveAssigneeChanged);
  var match$2 = Fun.both(match[0], Schedule_UtilsTimeline.contentEl(viewportEl));
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  ref: elementRef,
                  className: styles.bar
                }, React.createElement(WeekTimeline_BarCells.make, {
                      viewportEl: viewportEl,
                      assignee: assignee
                    }), React.createElement(WeekTimeline_BarJobs.make, {
                      assignee: assignee,
                      jobs: jobs,
                      workingIntervals: workingIntervals
                    })), React.createElement(WeekTimeline_BarActiveDay.make, {
                  assignee: assignee
                }), match$2 !== undefined ? React.createElement(WeekTimeline_AddJobPopover.make, {
                    assignee: assignee,
                    coordinate: match$2[0],
                    viewportEl: match$2[1],
                    close: match[1]
                  }) : null);
}

var make = React.memo(WeekTimeline_Bar);

export {
  styles ,
  make ,
}
/* styles Not a pure module */
