

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Types from "../../../types/Types.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";

function make(resolutions, workers, teams, templates, customFields, reportFields) {
  return {
          resolutions: resolutions,
          workers: workers,
          teams: teams,
          templates: templates,
          customFields: customFields,
          reportFields: reportFields
        };
}

var empty_resolutions = [];

var empty_workers = [];

var empty_teams = [];

var empty_templates = [];

var empty_customFields = [];

var empty_reportFields = [];

var empty = {
  resolutions: empty_resolutions,
  workers: empty_workers,
  teams: empty_teams,
  templates: empty_templates,
  customFields: empty_customFields,
  reportFields: empty_reportFields
};

function get(mappings) {
  return function (originalId) {
    return RCore.$$Option.map(RCore.$$Array.getBy(mappings, (function (param) {
                      return Types.Id.equal(param[0], originalId);
                    })), (function (prim) {
                  return prim[1];
                }));
  };
}

var pair = JsonDecode.object(function (field) {
      return [
              field.required("id", Types.Id.decodeOpaque),
              field.required("uuid", Uuid.decode)
            ];
    });

var decode = JsonDecode.object(function (field) {
      return {
              resolutions: field.required("resolutions", JsonDecode.array(pair)),
              workers: field.required("workers", JsonDecode.array(pair)),
              teams: field.required("teams", JsonDecode.array(pair)),
              templates: field.required("templates", JsonDecode.array(pair)),
              customFields: field.required("customFields", JsonDecode.array(pair)),
              reportFields: field.required("reportFields", JsonDecode.array(pair))
            };
    });

export {
  make ,
  empty ,
  get ,
  pair ,
  decode ,
}
/* pair Not a pure module */
