

import * as Fun from "../libraries/Fun.mjs";
import * as RCore from "../libraries/RCore.mjs";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as DomUtils from "../DomUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@dnd-kit/core";
import * as Bindings_DndKit from "../bindings/Bindings_DndKit.mjs";
import * as Sortable from "@dnd-kit/sortable";
import * as Modifiers from "@dnd-kit/modifiers";

function Make(Value) {
  var include = Bindings_DndKit.Make(Value);
  var Utilities = include.Utilities;
  var Sortable$1 = include.Sortable;
  var makeStyle = function (isDragging, transform, transition) {
    var transform$1 = Utilities.Css.transformToString(transform);
    if (transform$1 === undefined) {
      return {};
    }
    var tmp = {
      transform: transform$1
    };
    var tmp$1 = isDragging ? "150" : undefined;
    if (tmp$1 !== undefined) {
      tmp.zIndex = tmp$1;
    }
    if (transition !== undefined) {
      tmp.transition = transition;
    }
    return tmp;
  };
  var makeAttributes = function (style, attributes, listeners, ref) {
    return Js_dict.fromArray(Belt_Array.concatMany([
                    Js_dict.entries(listeners),
                    Js_dict.entries(attributes),
                    [[
                        "ref",
                        ref
                      ]],
                    style !== undefined ? [[
                          "style",
                          Caml_option.valFromOption(style)
                        ]] : []
                  ]));
  };
  var UI_SortableList$Make$Item = function (Props) {
    var hasActivatorOpt = Props.hasActivator;
    var id = Props.id;
    var children = Props.children;
    var hasActivator = hasActivatorOpt !== undefined ? hasActivatorOpt : false;
    var match = Sortable.useSortable({
          id: id
        });
    var setNodeRef = match.setNodeRef;
    var active = match.active;
    var isDragging = (active == null) ? false : Caml_obj.equal(active.id, id);
    var style = makeStyle(isDragging, match.transform, match.transition);
    var props = hasActivator ? ({
          ref: setNodeRef,
          style: style
        }) : makeAttributes(style, match.attributes, match.listeners, setNodeRef);
    return React.createElement(DomUtils.Spread.make, {
                props: props,
                children: children
              });
  };
  var Item = {
    makeStyle: makeStyle,
    makeAttributes: makeAttributes,
    make: UI_SortableList$Make$Item,
    $$default: UI_SortableList$Make$Item
  };
  var UI_SortableList$Make$Activator = function (Props) {
    var id = Props.id;
    var children = Props.children;
    var match = Sortable.useSortable({
          id: id
        });
    return React.createElement(DomUtils.Spread.make, {
                props: makeAttributes(undefined, match.attributes, match.listeners, match.setActivatorNodeRef),
                children: children
              });
  };
  var Activator = {
    make: UI_SortableList$Make$Activator,
    $$default: UI_SortableList$Make$Activator
  };
  var UI_SortableList$Make = function (Props) {
    var items = Props.items;
    var onDragEnd = Props.onDragEnd;
    var children = Props.children;
    var sensors = Core.useSensors(Core.useSensor(Core.PointerSensor), Core.useSensor(Core.KeyboardSensor));
    var onDragEnd$1 = function ($$event) {
      var over = $$event.over;
      var active = $$event.active;
      if (over === null) {
        return ;
      }
      var oldIndex = RCore.$$Array.findIndexOpt(items, (function (item) {
              return Caml_obj.equal(Value.getId(item), active.id);
            }));
      var newIndex = RCore.$$Array.findIndexOpt(items, (function (item) {
              return Caml_obj.equal(Value.getId(item), over.id);
            }));
      var match = Fun.both(oldIndex, newIndex);
      if (match !== undefined) {
        return onDragEnd(Sortable$1.arrayMove(match[0], match[1], items));
      }
      
    };
    var items$1 = items.map(function (item) {
          return Value.getId(item);
        });
    return React.createElement(Core.DndContext, {
                collisionDetection: Core.rectIntersection,
                modifiers: [
                  Modifiers.restrictToVerticalAxis,
                  Modifiers.restrictToWindowEdges
                ],
                sensors: sensors,
                onDragEnd: onDragEnd$1,
                children: React.createElement(Sortable.SortableContext, {
                      items: items$1,
                      strategy: Sortable.verticalListSortingStrategy,
                      children: children
                    })
              });
  };
  return {
          Types: include.Types,
          SortingStrategy: include.SortingStrategy,
          CollisionDetection: include.CollisionDetection,
          Active: include.Active,
          Over: include.Over,
          DragEndEvent: include.DragEndEvent,
          Sensor: include.Sensor,
          SensorDescriptor: include.SensorDescriptor,
          Modifier: include.Modifier,
          Utilities: Utilities,
          Sensors: include.Sensors,
          Modifiers: include.Modifiers,
          DndContext: include.DndContext,
          Sortable: Sortable$1,
          Item: Item,
          Activator: Activator,
          make: UI_SortableList$Make,
          $$default: UI_SortableList$Make
        };
}

export {
  Make ,
}
/* react Not a pure module */
