

import * as Runtime from "../../Runtime.mjs";

function delay(delayMS, f) {
  var timerId = Runtime.Timeout.defer(delayMS, f);
  return function () {
    Runtime.Timeout.cancel(timerId);
  };
}

function repeat(delayMS, f) {
  var timerId = Runtime.Interval.every(delayMS, f);
  return function () {
    Runtime.Interval.cancel(timerId);
  };
}

export {
  delay ,
  repeat ,
}
/* No side effect */
