

import * as Wire from "../../../types/Wire.mjs";
import * as React from "react";
import * as JobForm from "./form/JobForm.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as ModalQuery from "../../../types/ModalQuery.mjs";
import * as JobEdit_StateHooks from "./lib/state/JobEdit_StateHooks.mjs";

var jobUuid = ModalQuery.Job.EditOptions.uuid;

function JobEdit_FormComponent(Props) {
  var subroute = Props.subroute;
  var wire = AppContext.useWire();
  var state = JobEdit_StateHooks.useState();
  var dispatch = JobEdit_StateHooks.useDispatch();
  var onCopy = function () {
    if (typeof subroute === "object" && subroute.NAME === "Edit") {
      return wire.subscriptions.jobs.trigger({
                  TAG: "NewJob",
                  _0: {
                    TAG: "Copy",
                    _0: jobUuid(subroute.VAL)
                  }
                });
    }
    
  };
  var onNextJob = function (templateUuid) {
    if (typeof subroute === "object" && subroute.NAME === "Edit") {
      return wire.subscriptions.jobs.trigger({
                  TAG: "NewJob",
                  _0: {
                    TAG: "Next",
                    _0: jobUuid(subroute.VAL),
                    _1: templateUuid
                  }
                });
    }
    
  };
  var onCompletionJob = function () {
    if (typeof subroute === "object" && subroute.NAME === "Edit") {
      return wire.subscriptions.jobs.trigger({
                  TAG: "NewJob",
                  _0: {
                    TAG: "Completion",
                    _0: jobUuid(subroute.VAL)
                  }
                });
    }
    
  };
  var onClose = function () {
    Wire.closeModal(wire);
  };
  React.useEffect((function () {
          return state.form.events.subscribe(function ($$event) {
                      if (typeof $$event !== "object") {
                        return dispatch({
                                    TAG: "Form",
                                    _0: "Unlock"
                                  });
                      } else if ($$event.TAG === "Lock") {
                        return dispatch({
                                    TAG: "Form",
                                    _0: {
                                      TAG: "Lock",
                                      saving: $$event.saving
                                    }
                                  });
                      } else {
                        var jobUuid = $$event._0;
                        wire.subscriptions.jobs.trigger("CloseModal");
                        return wire.subscriptions.jobs.trigger({
                                    TAG: "JobSubmitted",
                                    _0: jobUuid
                                  });
                      }
                    });
        }), []);
  return React.createElement(JobForm.make, {
              subroute: subroute,
              onClose: onClose,
              onCopy: onCopy,
              onNextJob: onNextJob,
              onCompletionJob: onCompletionJob,
              onRemove: (function (_event) {
                  dispatch({
                        TAG: "Form",
                        _0: "ConfirmRemoval"
                      });
                }),
              onFinish: (function (_event) {
                  dispatch({
                        TAG: "Form",
                        _0: "ConfirmFinish"
                      });
                }),
              onCancel: (function (_event) {
                  dispatch({
                        TAG: "Form",
                        _0: "ConfirmCancel"
                      });
                }),
              removeJob: (function (uuid) {
                  return function (_event) {
                    dispatch({
                          TAG: "Form",
                          _0: {
                            TAG: "RemoveJob",
                            _0: uuid
                          }
                        });
                  };
                }),
              toggleHistory: (function (_event) {
                  dispatch({
                        TAG: "Form",
                        _0: "ToggleHistory"
                      });
                }),
              hideConfirmation: (function (_event) {
                  dispatch({
                        TAG: "Form",
                        _0: "HideConfirmation"
                      });
                }),
              showHistory: state.form.showHistory,
              locked: state.form.locked,
              saving: state.form.saving,
              setLock: (function (lock) {
                  dispatch(lock ? ({
                            TAG: "Form",
                            _0: {
                              TAG: "Lock",
                              saving: false
                            }
                          }) : ({
                            TAG: "Form",
                            _0: "Unlock"
                          }));
                }),
              confirmation: state.form.confirmation,
              confirmationLocked: state.form.confirmationLocked,
              submitWorkflowError: (function (error) {
                  dispatch({
                        TAG: "Form",
                        _0: {
                          TAG: "SubmitWorkflowError",
                          _0: error
                        }
                      });
                }),
              submitJob: (function (payload) {
                  dispatch({
                        TAG: "Form",
                        _0: {
                          TAG: "SubmitJob",
                          _0: payload
                        }
                      });
                }),
              cancelJob: (function (resolution) {
                  dispatch({
                        TAG: "Form",
                        _0: {
                          TAG: "CancelJob",
                          _0: resolution
                        }
                      });
                })
            });
}

var make = JobEdit_FormComponent;

export {
  make ,
}
/* Wire Not a pure module */
