// extracted by mini-css-extract-plugin
export var address = "address-Ea4kJckya99ST0EtsCTf";
export var client = "client-gb4gckQZMulUM254XLSK";
export var dismiss = "dismiss-aij2dlRhe3fnoJy9aSaQ";
export var editButton = "edit-button-O3nvZ1WwzuiFlIKkfKWT";
export var editForm = "edit-form-E7FmFLbMcKA9gj_Z0Kp0";
export var focusedEntity = "focused-entity-FXEd49pjN7S46AC1uC3b";
export var focusedJob = "focused-job-qFhXpwoUUJFAXMYZ1X9r";
export var group = "group-kkuMofWHSjz7SLhAuKal";
export var hasLocation = "has-location-MVGKRv6hsqE3ZnKGuQPb";
export var jobAddress = "job-address-o5GxdJF0mQZ7JbGdp3jz";
export var locateJob = "locate-job-mOVXhYsjQCiWQmS5svX6";
export var scheduledAt = "scheduled-at-i9qo6IcJLrJ2Fo7gNNuA";
export var site = "site-QqppqJFIkhWE3wGbRPz4";
export var summary = "summary-wymAE_5eLl7yCWHv6xV1";
export var timestamps = "timestamps-prSI_N3KknX0DtF8BxUw";