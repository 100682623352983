// extracted by mini-css-extract-plugin
export var checkbox = "checkbox-GlMZkrBzc6tXu4sIpwjs";
export var column = "column-wgmhE_nQk2M9AajENn3M";
export var dropdownList = "dropdown-list-v2MA2uz0j03piaZDoprf";
export var fieldsBlock = "fields-block-rUbGIMbdDIaNorgF9ZoZ";
export var fieldsHeader = "fields-header-_jS_V4ib9pCK2NfP1OXv";
export var fieldsWrapper = "fields-wrapper-kzAYivHT1vWeDQD1E2D_";
export var footer = "footer-KGCFI76FKJwXKHIlUbpQ";
export var noExtraBottomPadding = "no-extra-bottom-padding-G1Xts81UHziAdjy8XX6G";
export var saveButton = "save-button-wJPo5I02mHwGADLarmg8";
export var sectionTitle = "section-title-_GthppdpqCrI86mpbjlD";
export var selectedTemplates = "selected-templates-I5MemI6NQtDsu5W0XF30";
export var templatesBlock = "templates-block-_b3CrAGZoLrM4D2rjUuk";
export var templatesTitleWrapper = "templates-title-wrapper-F__mjMkflei7jQRAnidB";
export var wordBreak = "word-break-Iqo9eeF1XOtau3Txoicm";
export var wrapper = "wrapper-j7L4aDyH3oMmd7auaGJV";