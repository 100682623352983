import React from 'react'

import LocationsViewLink from 'planado/containers/map/locations_view_link'
import HistoryDatePickerLink from 'planado/containers/map/history_date_picker_link'
import * as styles from './styles.module.css'

const WorkerLocations = ({ onReturn, worker }) => {
  return (
    <div className={styles.locations}>
      <header className={styles.worker}>
        <h3 className={styles.workerName}>{worker.name}</h3>
        <div className={styles.closeWorker} onClick={onReturn}>
          &times;
        </div>
      </header>
      <HistoryDatePickerLink />
      <LocationsViewLink />
    </div>
  )
}

export default WorkerLocations
