import { Collection, Job, Bar } from 'planado/schedule/utils/models'
import { async } from 'planado/utils/index.js'
import { getMoment, now } from 'planado/utils/time/index.js'

let repos = {}

class Repository extends Collection {
  initialize = name => {
    super.initialize([])
    return (this.name = name)
  }

  init(data, ctx = null) {
    this.ctx = ctx
    this.reset(data)
    this.load()
  }

  load() {}

  belongsTo(name, repo) {
    return (this.model.prototype[name] = function() {
      return repo.get(this.get(repo.model.prototype.idAttribute))
    })
  }

  hasMany(name, repo, options) {
    let models
    if (options == null) {
      options = {}
    }

    const ctx = this.ctx

    return (models = this.model.prototype[name] = function() {
      if (this[`_${name}`]) {
        return this[`_${name}`]
      }

      let condition = {}
      condition[this.idAttribute] = this.id
      models = repo.where(condition)

      if (options.collection) {
        return (this[`_${name}`] = new options.collection(models, ctx))
      } else {
        return models
      }
    })
  }
}

class Jobs extends Repository {
  static initClass() {
    this.prototype.model = Job
  }

  comparator = job => {
    let currentTime
    if (job.collection) {
      currentTime = job.collection.currentTime
    } else {
      currentTime = getMoment({ dateTime: now(this.ctx), ctx: this.ctx })
    }

    const klass = job.status(currentTime, this.ctx)

    switch (klass) {
      case 'overdue':
        return [0, job.get('scheduled_at')]
      case 'overtime':
        return [1, job.overtime(currentTime, this.ctx)]
      case 'current':
        return [2, job.get('first_activity_at')]
      case 'scheduled':
        return [3, job.get('scheduled_at')]
      case 'posted':
        return [4, job.get('serial_no')]
      case 'failed':
        return [5, job.get('first_activity_at')]
      case 'finished':
        return [6, 3000000000000 - job.finishedAt(this.ctx).toDate()]
    }
  }

  init(data, ctx) {
    this.currentTime = getMoment({ dateTime: now(ctx), ctx })
    this.ctx = ctx
    super.init(data, ctx)
  }

  forDate = date => {
    const ctx = this.ctx
    const prevDate = date.clone().subtract(1, 'days')
    const nextDate = date
      .clone()
      .add(1, 'days')
      .subtract(1, 'seconds')

    return this.filter(job => {
      const at = job.scheduledAt(ctx)
      if (at && job.finished()) {
        return at.isSame(date, 'day') || at.isSame(prevDate, 'day')
      } else if (at && at.isAfter(nextDate)) {
        return false
      } else {
        return true
      }
    })
  }
}

class DayJobs extends Jobs {
  init = (data, ctx) => {
    super.init(data, ctx)
    this.ctx = ctx
    return async(() => this.listenToModels('change', () => this.sort()))
  }

  forSingleDay(day) {
    const ctx = this.ctx
    return this.filter(function(job) {
      let at = job.scheduledAt(ctx)
      return at && at.isSame(day, 'day')
    })
  }
}

class Bars extends Repository {
  static initClass() {
    this.prototype.model = Bar
  }

  load() {
    this.linkToJobs()
  }

  linkToJobs() {
    this.listenTo(repos.jobs, 'add', this._addJob.bind(this))
    this.listenTo(repos.jobs, 'remove', this._removeJob.bind(this))
    this.listenTo(repos.jobs, 'reset', this._resetJobs.bind(this))
    this.listenTo(repos.jobs, 'change:bar_id', this._changeJobBar.bind(this))
  }

  resetMenuId() {
    this.forEach(bar => bar.resetMenuId())
  }

  _addJob(job) {
    let bar = this.get(job.get('bar_id'))
    if (bar) {
      bar.addJob(job)
    }
  }

  _removeJob(job) {
    let bar = this.get(job.get('bar_id'))
    if (bar) {
      bar.removeJob(job)
    }
  }

  _resetJobs(jobs = []) {
    this.forEach(bar => {
      const barJobs = jobs.filter(
        job => job.get('bar_id') === bar.get('bar_id')
      )
      bar.resetJobs(barJobs)
    })
  }

  _changeJobBar(job, bar_id) {
    let prevBar = this.get(job.previous('bar_id'))
    if (prevBar) {
      prevBar.removeJob(job)
    }
    let nextBar = this.get(bar_id)
    if (nextBar) {
      nextBar.addJob(job)
    }
  }
}

Jobs.initClass()
Bars.initClass()

let jobs = new Jobs('jobs')
let dayJobs = new DayJobs('dayJobs')
let bars = new Bars('bars')

repos.jobs = jobs
repos.bars = bars

export default repos

export { jobs, dayJobs, bars }
