

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Types from "../../../types/Types.mjs";
import * as Locale from "../../../libraries/Locale.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as ShiftsTypes from "../ShiftsTypes.mjs";

function makePayload(shift) {
  return {
          uuid: shift.uuid,
          date: Locale.T.formatM("DateISO")(shift.date),
          useBusinessHours: shift.useBusinessHours,
          intervals: shift.intervals.map(function (a) {
                return {
                        timeFrom: Types.Time.HoursMinutes.encode(a.timeFrom),
                        timeTo: Types.Time.HoursMinutes.encode(a.timeTo),
                        jobTypeUuids: a.jobTypeUuids,
                        territoryUuids: a.territoryUuids,
                        teamUuid: a.teamUuid
                      };
              })
        };
}

var decode = JsonDecode.object(function (field) {
      return {
              shift: field.required("shift", ShiftsTypes.Shift.decode)
            };
    });

function update(wire, userUuid, shift) {
  return Backend.decode(Backend.patch(undefined, true, undefined, true, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Shifts",
                    VAL: {
                      NAME: "User",
                      VAL: {
                        NAME: "Update",
                        VAL: userUuid
                      }
                    }
                  }
                }, {
                  NAME: "Obj",
                  VAL: makePayload(shift)
                }), decode);
}

var decode$1 = JsonDecode.object(function (field) {
      return {
              workerUuids: field.required("workerUuids", JsonDecode.array(Uuid.decode)),
              teamUuids: field.required("teamUuids", JsonDecode.array(Uuid.decode))
            };
    });

function update$1(wire, teamUuid, shift) {
  return Backend.decode(Backend.patch(undefined, true, undefined, true, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Shifts",
                    VAL: {
                      NAME: "Team",
                      VAL: {
                        NAME: "Update",
                        VAL: teamUuid
                      }
                    }
                  }
                }, {
                  NAME: "Obj",
                  VAL: makePayload(shift)
                }), decode$1);
}

function $$delete(wire, shift) {
  return Backend.$$delete(undefined, true, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "Shifts",
                VAL: {
                  NAME: "Delete",
                  VAL: shift.uuid
                }
              }
            });
}

var Delete = {
  $$delete: $$delete
};

var PatchWorker_Response = {};

var PatchWorker = {
  $$Response: PatchWorker_Response,
  update: update
};

var PatchTeam_Response = {};

var PatchTeam = {
  $$Response: PatchTeam_Response,
  update: update$1
};

export {
  PatchWorker ,
  PatchTeam ,
  Delete ,
}
/* decode Not a pure module */
