

import * as El from "../../../../../../../libraries/El.mjs";
import * as Fun from "../../../../../../../libraries/Fun.mjs";
import * as RCore from "../../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../../types/Types.mjs";
import * as Units from "../../../../../../../types/Units.mjs";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_DND from "../../../../../lib/Schedule_DND.mjs";
import * as Schedule_Popover from "../../../../../components/popovers/popover/Schedule_Popover.mjs";
import * as Schedule_JobGroup from "../../../../../components/popovers/job_group/Schedule_JobGroup.mjs";
import * as Schedule_Types_Job from "../../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as WeekCalendar_Constants from "../../../lib/WeekCalendar_Constants.mjs";
import * as WeekCalendar_JobGroupModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_calendar/components/job_groups/group/WeekCalendar_JobGroup.module.css";

function toPx(num) {
  return num.toString() + "px";
}

function make(columnWidth, param) {
  var startPx = param.startPx;
  var widthPx = columnWidth / WeekCalendar_Constants.numberOfJobsInColumn + 5.0;
  var heightPx = param.finishPx - startPx;
  return {
          height: toPx(heightPx),
          top: toPx(startPx),
          width: toPx(widthPx)
        };
}

function hasUnassigned(__x) {
  return __x.some(function (param) {
              return Schedule_Types_Job.ScheduledJob.isUnassigned(param[1]);
            });
}

var styles = WeekCalendar_JobGroupModuleCss;

function WeekCalendar_JobGroup(Props) {
  var calendarRef = Props.calendarRef;
  var weekDay = Props.weekDay;
  var columnWidth = Props.columnWidth;
  var group = Props.group;
  var elementRef = Types.ReactRef.use();
  var match = React.useState(function () {
        
      });
  var setClickCoordinate = match[1];
  var clickCoordinate = match[0];
  var closePreview = function () {
    setClickCoordinate(function (param) {
          
        });
  };
  var togglePreview = function ($$event) {
    if (!RCore.$$Option.isNone(clickCoordinate)) {
      return setClickCoordinate(function (param) {
                  
                });
    }
    var coordinate_0 = Units.Px.fromInt($$event.pageX);
    var coordinate_1 = Units.Px.fromInt($$event.pageY);
    var coordinate = [
      coordinate_0,
      coordinate_1
    ];
    setClickCoordinate(function (param) {
          return coordinate;
        });
  };
  var match$1 = React.useState(function () {
        return false;
      });
  var setTransparent = match$1[1];
  var setTransapent = function () {
    setTransparent(function (param) {
          return true;
        });
  };
  var match$2 = Schedule_DND.DND.Context.use();
  var dndSubscriptions = match$2.subscriptions;
  React.useEffect((function () {
          var calendarElement = Types.ReactRef.toOption(calendarRef);
          if (calendarElement === undefined) {
            return ;
          }
          if (!RCore.$$Option.isSome(clickCoordinate)) {
            return ;
          }
          var closePreview = function (_event) {
            setClickCoordinate(function (param) {
                  
                });
          };
          Caml_option.valFromOption(calendarElement).addEventListener("scroll", closePreview);
          var unsubscribeFromDragStart = dndSubscriptions.onDragStart.subscribe(setTransapent);
          var unsubscribeFromDragEnd = dndSubscriptions.onDragEnd.subscribe(function () {
                setClickCoordinate(function (param) {
                      
                    });
                setTransparent(function (param) {
                      return false;
                    });
              });
          return (function () {
                    unsubscribeFromDragStart();
                    unsubscribeFromDragEnd();
                  });
        }), [
        calendarRef,
        clickCoordinate
      ]);
  var match$3 = Fun.both(clickCoordinate, Types.ReactRef.toOption(elementRef));
  var popoverBlock;
  if (match$3 !== undefined) {
    var jobs = group.jobs.map(function (param) {
          return param[1];
        });
    var clickTargetPosition_1 = match$3[1].getBoundingClientRect();
    var clickTargetPosition = {
      NAME: "DomRect",
      VAL: clickTargetPosition_1
    };
    popoverBlock = React.createElement(Schedule_Popover.make, {
          transparent: match$1[0],
          clickCoordinate: match$3[0],
          clickTargetPosition: clickTargetPosition,
          children: React.createElement(Schedule_JobGroup.make, {
                currentDay: weekDay,
                jobs: jobs,
                close: closePreview
              })
        });
  } else {
    popoverBlock = null;
  }
  var style = make(columnWidth, group);
  var className = El.Cn.concat(styles.container, RCore.$$Option.isSome(clickCoordinate) ? styles.active : "");
  var innerClassName = El.Cn.concat(styles.number, hasUnassigned(group.jobs) ? styles.unassigned : "");
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  ref: elementRef,
                  className: className,
                  style: style,
                  onClick: togglePreview
                }, React.createElement("div", {
                      className: innerClassName
                    }, group.jobs.length.toString())), popoverBlock);
}

var make$1 = React.memo(WeekCalendar_JobGroup);

export {
  make$1 as make,
}
/* styles Not a pure module */
