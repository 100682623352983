

import * as JobEdit_StateTypes from "./JobEdit_StateTypes.mjs";
import * as Types_ReactContext from "../../../../../types/Types_ReactContext.mjs";

var Context = Types_ReactContext.Make({
      initial: undefined
    });

var Action = JobEdit_StateTypes.Action;

var State = JobEdit_StateTypes.State;

export {
  Action ,
  State ,
  Context ,
}
/* Context Not a pure module */
