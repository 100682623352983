

import * as Wire from "../../../../../../types/Wire.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DayTimeline_Utils from "../../lib/utils/DayTimeline_Utils.mjs";
import * as Schedule_Types_JobCore from "../../../../lib/types/job/Schedule_Types_JobCore.mjs";
import * as Schedule_UtilsTimeline from "../../../../lib/utils/Schedule_UtilsTimeline.mjs";
import * as Webapi__Dom__HtmlElement from "rescript-webapi/lib/es6/src/Webapi/Dom/Webapi__Dom__HtmlElement.mjs";
import * as DayTimeline_DragLayerGrid from "./DayTimeline_DragLayerGrid.mjs";
import * as DayTimeline_DragLayerElements from "./DayTimeline_DragLayerElements.mjs";

function getJobWidth(sizes, param) {
  return Units.Px.multiplyWithInt(sizes.minute, param.duration);
}

function setJobWidth(sizes, param, payload) {
  var style = RCore.$$Option.map(Webapi__Dom__HtmlElement.ofElement(param.jobEl), (function (prim) {
          return prim.style;
        }));
  if (style !== undefined) {
    Caml_option.valFromOption(style).setProperty("width", Units.Px.toString(getJobWidth(sizes, payload)));
    return ;
  }
  
}

function getJobPosition(wire, filter, contentEl, dragPreviewOffset) {
  var match = (function (__x) {
        return Schedule_UtilsTimeline.ConvertPoint.fromNodeToPage(contentEl, __x);
      })((function (__x) {
            return DayTimeline_DragLayerGrid.snapToGrid(wire, filter, __x);
          })((function (__x) {
                return Schedule_UtilsTimeline.ConvertPoint.fromPageToNode(contentEl, __x);
              })(dragPreviewOffset)));
  var x = Units.Px.toString(match[0]);
  var y = Units.Px.toString(match[1]);
  return "translate(" + x + ", " + y + ")";
}

function setJobPosition(wire, filter, contentEl, param, dragPreviewOffset) {
  var style = RCore.$$Option.map(Webapi__Dom__HtmlElement.ofElement(param.jobEl), (function (prim) {
          return prim.style;
        }));
  if (style !== undefined) {
    Caml_option.valFromOption(style).setProperty("transform", getJobPosition(wire, filter, contentEl, dragPreviewOffset));
    return ;
  }
  
}

var template$p = Utils.Translations.t("js.schedule.v2.job.headline");

function setJobDescription(wire, param, param$1) {
  var address = RCore.$$Option.mapWithDefault(param$1.address, "", Schedule_Types_JobCore.Address.toString);
  var template = RCore.$$Option.mapWithDefault(param$1.template, template$p(Wire.ctx(wire)), (function (param) {
          return param.name;
        }));
  param.addressEl.textContent = address;
  param.templateEl.textContent = template;
}

function hasRescheduledStyle(jobEl) {
  return jobEl.classList.contains(DayTimeline_DragLayerElements.styles.rescheduled);
}

function inViewport(viewportEl, dragPreviewOffset) {
  var rect = viewportEl.getBoundingClientRect();
  var x = Units.Px.minus(dragPreviewOffset[0], Units.Px.fromFloat(rect.x));
  if (Units.Px.equalOrMoreThan(x, Units.Px.zero)) {
    return Units.Px.equalOrMoreThan(Units.Px.minus(Units.Px.fromFloat(rect.width), x), Units.Px.zero);
  } else {
    return false;
  }
}

function setDate(wire, viewportEl, param, scheduledAt, param$1, dragPreviewOffset) {
  var attribute;
  if (inViewport(viewportEl, dragPreviewOffset)) {
    var scheduledFinishAt = Locale.T.nextMinutes(param$1.duration, scheduledAt);
    var scheduledAtStr = Locale.T.fmtM("Time", wire)(scheduledAt);
    var scheduledFinishAtStr = Locale.T.fmtM("Time", wire)(scheduledFinishAt);
    attribute = Locale.T.equalDay(scheduledAt, scheduledFinishAt) ? scheduledAtStr + "–" + scheduledFinishAtStr : scheduledAtStr;
  } else {
    attribute = "none";
  }
  param.jobEl.setAttribute("data-scheduled-at", attribute);
}

function setJobDate(wire, filter, businessHours, currentDay, sizes, contentEl, viewportEl, elements, payload, dragPreviewOffset) {
  var coordinate = DayTimeline_DragLayerGrid.snapToGrid(wire, filter, Schedule_UtilsTimeline.ConvertPoint.fromPageToNode(contentEl, dragPreviewOffset));
  var scheduledAt = DayTimeline_Utils.FindByCoordinateInTimeline.scheduledAt(businessHours, currentDay, sizes, coordinate);
  setDate(wire, viewportEl, elements, scheduledAt, payload, dragPreviewOffset);
  var prevScheduledAt = payload.scheduledAt;
  if (prevScheduledAt === undefined) {
    return ;
  }
  var jobEl = elements.jobEl;
  var isResheduled = Locale.T.compare(Caml_option.valFromOption(prevScheduledAt), scheduledAt) !== 0;
  var hasRescheduledStyle$1 = hasRescheduledStyle(jobEl);
  if (isResheduled && !hasRescheduledStyle$1) {
    jobEl.classList.add(DayTimeline_DragLayerElements.styles.rescheduled);
    return ;
  } else if (!isResheduled && hasRescheduledStyle$1) {
    jobEl.classList.remove(DayTimeline_DragLayerElements.styles.rescheduled);
    return ;
  } else {
    return ;
  }
}

function fixDragPreviewOffset(dragPreviewOffset, mouseOffset, sizes, payload) {
  var jobWidth = getJobWidth(sizes, payload);
  var jobHeight = Units.Px.fromInt(50);
  var x = Units.Px.lessThan(Units.Px.plus(dragPreviewOffset[0], jobWidth), mouseOffset[0]) ? mouseOffset[0] : dragPreviewOffset[0];
  var y = Units.Px.lessThan(Units.Px.plus(dragPreviewOffset[1], jobHeight), mouseOffset[1]) ? mouseOffset[1] : dragPreviewOffset[1];
  return [
          x,
          y
        ];
}

function showDragLayer(wire, sizes, filter, businessHours, currentDay, viewportEl, contentEl, elements, payload, dragPreviewOffset, mouseOffset) {
  var containerEl = elements.containerEl;
  var dragPreviewOffset$1 = fixDragPreviewOffset(dragPreviewOffset, mouseOffset, sizes, payload);
  setJobWidth(sizes, elements, payload);
  setJobPosition(wire, filter, contentEl, elements, dragPreviewOffset$1);
  setJobDescription(wire, elements, payload);
  setJobDate(wire, filter, businessHours, currentDay, sizes, contentEl, viewportEl, elements, payload, dragPreviewOffset$1);
  if (!containerEl.classList.contains(DayTimeline_DragLayerElements.styles.visible)) {
    containerEl.classList.add(DayTimeline_DragLayerElements.styles.visible);
    return ;
  }
  
}

function updateDragLayer(wire, sizes, filter, businessHours, currentDay, viewportEl, contentEl, elements, payload, dragPreviewOffset, mouseOffset) {
  var dragPreviewOffset$1 = fixDragPreviewOffset(dragPreviewOffset, mouseOffset, sizes, payload);
  setJobPosition(wire, filter, contentEl, elements, dragPreviewOffset$1);
  setJobDate(wire, filter, businessHours, currentDay, sizes, contentEl, viewportEl, elements, payload, dragPreviewOffset$1);
}

function hideDragLayer(param) {
  var containerEl = param.containerEl;
  if (containerEl.classList.contains(DayTimeline_DragLayerElements.styles.visible)) {
    containerEl.classList.remove(DayTimeline_DragLayerElements.styles.visible);
    return ;
  }
  
}

export {
  fixDragPreviewOffset ,
  showDragLayer ,
  updateDragLayer ,
  hideDragLayer ,
}
/* template' Not a pure module */
