import React from 'react'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import classNames from 'classnames'

export const NewFileInput = withContext(
  ({
    id,
    name,
    value,
    btnText,
    onFileSelected,
    onFileCleared,
    ctx,
    disabled,
    limit = null,
    limitMsg = '',
    ...rest
  }) => {
    const greaterThanLimit = file => {
      return limit !== null && file && file.size >= limit
    }

    const DisabledFileInputWithoutFile = (
      <div className="pd-form-control-impersonator disabled">
        <span>{ctx.t('js.components.file_input.file_not_uploaded')}</span>
      </div>
    )

    let fileInput, fileName, clearButton

    const onChange = evt => {
      const file = evt.target.files[0]

      if (greaterThanLimit(file)) {
        alert(limitMsg)
      } else {
        onFileSelected(file)
      }
    }

    if (value !== null && !greaterThanLimit(value)) {
      fileName = (
        <span>
          {value.name}

          <span className="pd-form-control-filesize">
            &nbsp;(
            {ctx.toHumanSize(value.size)})
          </span>
        </span>
      )

      clearButton = (
        <a
          className="btn btn-xs"
          onClick={evt => {
            fileInput.value = ''
            onFileCleared()
            evt.stopPropagation()
          }}
        >
          <span className="fa fa-times" />
          &nbsp;
          {ctx.t('js.components.file_input.clear')}
        </a>
      )
    } else {
      fileName = null
      clearButton = null
    }

    return disabled ? (
      DisabledFileInputWithoutFile
    ) : (
      <div
        className="pd-form-control-impersonator pd-form-control-clickable"
        onClick={() => fileInput.click()}
      >
        <input
          {...rest}
          id={id}
          name={name}
          className="pd-form-control"
          type="file"
          ref={x => (fileInput = x)}
          onChange={onChange}
        />

        {fileName}

        <div className="pfi-actions">
          {clearButton}

          <a
            className="btn btn-xs btn-primary pfi-choose"
            onClick={evt => {
              fileInput.value = ''
              fileInput.click()
              evt.stopPropagation()
            }}
          >
            {btnText || ctx.t('js.components.file_input.select')}
          </a>
        </div>
      </div>
    )
  }
)

export const ExistingFileInput = withContext(
  ({ value, onFileDelete, isRemote, ctx, disabled }) => {
    const classes = classNames({
      'pd-form-control-impersonator': true,
      disabled: disabled
    })

    return (
      <div className={classes}>
        <span>
          <a
            href={value.url}
            data-remote={isRemote}
            download={value.name}
            target="_blank"
          >
            {value.name}
          </a>

          <span className="pd-form-control-filesize">
            &nbsp;(
            {ctx.toHumanSize(value.size)})
          </span>
        </span>

        {!disabled && (
          <span
            className="icon-clear pd-form-control-icon pd-form-control-icon-clickable"
            aria-hidden="true"
            onClick={onFileDelete}
          />
        )}
      </div>
    )
  }
)

const FileInput = ({
  id,
  name,
  value,
  btnText,
  onFileSelected,
  onFileCleared,
  onFileDelete,
  isRemote,
  disabled,
  limit,
  limitMsg
}) => {
  const exists = value !== null && 'uuid' in value

  if (exists) {
    return (
      <ExistingFileInput
        value={value}
        onFileDelete={onFileDelete}
        isRemote={isRemote}
        disabled={disabled}
      />
    )
  } else {
    return (
      <NewFileInput
        id={id}
        name={name}
        value={value}
        btnText={btnText}
        onFileSelected={onFileSelected}
        onFileCleared={onFileCleared}
        disabled={disabled}
        limit={limit}
        limitMsg={limitMsg}
      />
    )
  }
}

export default FileInput
