import { createStore } from 'planado/stores/utils'
import reducer from 'planado/reducers/admin/templates'
import { sortByMatchingSkills } from 'planado/utils/possible_assignees'
import { v4 as getUuid } from 'uuid'

const presentPossibleAssignees = (initAssignees, requiredSkillUuids) => {
  if (requiredSkillUuids.length === 0) {
    return initAssignees
  } else {
    return sortByMatchingSkills(initAssignees, requiredSkillUuids)
  }
}

const presentTerritoryUuid = (uuid, availableTerritories) => {
  const territoryAvailable = availableTerritories.some(t => t.uuid === uuid)
  return territoryAvailable ? uuid : null
}

export const addUuidsToFileCustomFields = (customFields, reportFields) => {
  if (reportFields.some(field => field.fieldType === 'signature')) {
    return customFields.map(field => {
      if (field.fieldType === 'file' && field.fieldUuid === null) {
        return { ...field, fieldUuid: getUuid() }
      } else {
        return field
      }
    })
  } else {
    return customFields
  }
}

const INITIAL_STATE = {
  errors: {
    template: {
      name: []
    },
    resolutions: []
  },
  formDisabled: false,
  foremanError: false
}

export default (props = {}, ctx) => {
  const initialState = {
    popover: null,
    ...INITIAL_STATE,
    ...props,
    template: {
      ...props.template,
      trackMovements: props.template.trackMovements === true,
      canBeUsedOnMobile: props.template.canBeUsedOnMobile === true
    },
    customFields: addUuidsToFileCustomFields(
      props.customFields,
      props.reportFields
    ),
    possibleAssignees: presentPossibleAssignees(
      ctx.assignees,
      props.skillUuids
    ),
    territoryUuid: presentTerritoryUuid(
      props.territoryUuid,
      props.availableTerritories
    )
  }

  return createStore(reducer, initialState, { logger: { diff: true } })
}
