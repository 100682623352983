

import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Types from "../../../../types/Types.mjs";
import * as Locale from "../../../../libraries/Locale.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as JsonDecode from "../../../../libraries/JsonDecode.mjs";
import * as Types_Date from "../../../../types/Types_Date.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Float from "@rescript/core/lib/es6/src/Core__Float.mjs";
import * as JobFieldType from "../JobFieldType.mjs";
import * as JobViewTypes from "./JobViewTypes.mjs";
import * as JobIndexIdToUuid from "../JobIndexIdToUuid.mjs";

function d2(mappings) {
  var f = JobIndexIdToUuid.get(mappings);
  return JsonDecode.flatMap(Types.Id.decodeOpaque, (function (id) {
                return JsonDecode.custom(function (param) {
                            var uuid = f(id);
                            if (uuid !== undefined) {
                              return Caml_option.valFromOption(uuid);
                            } else {
                              return JsonDecode.cannotDecode("InvalidId");
                            }
                          });
              }));
}

function decodeV1(mappings) {
  return JsonDecode.object(function (field) {
              return {
                      jobFields: field.required("jobFields", JsonDecode.array(JobFieldType.decode)),
                      customFields: field.required("customFields", JsonDecode.array(d2(mappings.customFields))),
                      reportFields: field.required("reportFields", JsonDecode.array(d2(mappings.reportFields))),
                      virtual: RCore.$$Option.getOr(field.optional("virtual", JsonDecode.array(JobViewTypes.Virtual.decode)), [])
                    };
            });
}

var decodeV5 = JsonDecode.object(function (field) {
      return {
              jobFields: field.required("jobFields", JsonDecode.array(JobFieldType.decode)),
              customFields: field.required("customFields", JsonDecode.array(Uuid.decode)),
              reportFields: field.required("reportFields", JsonDecode.array(Uuid.decode)),
              virtual: field.required("virtual", JsonDecode.array(JobViewTypes.Virtual.decode))
            };
    });

function decode(version, mappings) {
  if (version === undefined) {
    return decodeV1(mappings);
  }
  switch (version) {
    case "V5" :
    case "V6" :
    case "V7" :
        return decodeV5;
    default:
      return decodeV1(mappings);
  }
}

function decodeStartFinish2(date) {
  return JsonDecode.object(function (field) {
              try {
                var start = field.required("start", JsonDecode.option(date));
                var finish = field.required("finish", JsonDecode.option(date));
                if (start !== undefined && finish !== undefined) {
                  return {
                          TAG: "Interval",
                          _0: {
                            TAG: "Specific",
                            _0: [
                              Caml_option.valFromOption(start),
                              Caml_option.valFromOption(finish)
                            ]
                          }
                        };
                } else {
                  return ;
                }
              }
              catch (exn){
                return ;
              }
            });
}

function decodeV1$1(ctx) {
  var decodeDateV1 = JsonDecode.map(JsonDecode.string, Locale.T.parse("Date", ctx));
  return decodeStartFinish2(decodeDateV1);
}

var decodeV4 = decodeStartFinish2(Types_Date.decode);

var decodePredefined = JsonDecode.object(function (field) {
      var match = field.required("type", JsonDecode.string);
      switch (match) {
        case "earlier" :
            return {
                    TAG: "Earlier",
                    _0: field.required("values", JobViewTypes.Settings.Filter.DateFilter.$$Range.decode)
                  };
        case "eql" :
            return {
                    TAG: "Eql",
                    _0: field.required("values", JsonDecode.object(function (field) {
                              return field.required("date", Types_Date.decode);
                            }))
                  };
        case "interval" :
            return {
                    TAG: "Interval",
                    _0: field.required("values", JobViewTypes.Settings.Filter.DateFilter.Interval.decode)
                  };
        case "later" :
            return {
                    TAG: "Later",
                    _0: field.required("values", JobViewTypes.Settings.Filter.DateFilter.$$Range.decode)
                  };
        default:
          return JsonDecode.cannotDecode("ExpectedValidOption");
      }
    });

function decodeWithoutTemplate(value) {
  if (value === "withoutTemplate") {
    return "WithoutTemplate";
  }
  
}

function decodeId(mappings) {
  var f = JobIndexIdToUuid.get(mappings);
  return JsonDecode.oneOf([
              JsonDecode.map(JsonDecode.fromVariant(JsonDecode.string, decodeWithoutTemplate), (function (param) {
                      return "WithoutTemplate";
                    })),
              JsonDecode.map(Types.Id.decodeOpaque, (function (s) {
                      var uuid = f(s);
                      if (uuid !== undefined) {
                        return {
                                TAG: "Uuid",
                                _0: Caml_option.valFromOption(uuid)
                              };
                      }
                      
                    }))
            ]);
}

function decodeDict(mappings) {
  return JsonDecode.map(JsonDecode.oneOf([
                  JsonDecode.array(Uuid.decode),
                  JsonDecode.map(JsonDecode.dict(JsonDecode.bool), (function (v) {
                          return RCore.$$Array.filterMap(RCore.$$Array.filterMap(Object.keys(v), Core__Float.fromString).map(Types.Id.fromNumber), JobIndexIdToUuid.get(mappings));
                        }))
                ]), (function (a) {
                return a.map(function (v) {
                            return {
                                    TAG: "Uuid",
                                    _0: v
                                  };
                          });
              }));
}

var decodeUuid = JsonDecode.oneOf([
      JsonDecode.map(JsonDecode.fromVariant(JsonDecode.string, decodeWithoutTemplate), (function (param) {
              return "WithoutTemplate";
            })),
      JsonDecode.map(Uuid.decode, (function (x) {
              return {
                      TAG: "Uuid",
                      _0: x
                    };
            }))
    ]);

var decodeV1$2 = JsonDecode.oneOf([
      JsonDecode.map(JsonDecode.dict(JsonDecode.bool), (function (d) {
              return RCore.$$Array.filterMap(Object.keys(d), JobViewTypes.Settings.Filter.Territory.fromString);
            })),
      JsonDecode.custom(function (param) {
            return [];
          })
    ]);

var decodeV3 = JobViewTypes.Settings.Filter.Territory.decode;

var decode$1 = JsonDecode.oneOf([
      JsonDecode.array(decodeV3),
      decodeV1$2
    ]);

var decodeStatusesV1 = JsonDecode.map(JsonDecode.dict(JsonDecode.bool), (function (d) {
        return RCore.$$Array.filterMap(Js_dict.entries(d), (function (param) {
                      if (param[1]) {
                        return JobViewTypes.Status.fromString(param[0]);
                      }
                      
                    }));
      }));

var decodeStatuses = JsonDecode.oneOf([
      JsonDecode.map(JsonDecode.array(JobViewTypes.Status.decode), RCore.$$Array.keepSome),
      decodeStatusesV1
    ]);

function decodeIds(mappings) {
  var f = JobIndexIdToUuid.get(mappings);
  return JsonDecode.map(JsonDecode.dict(JsonDecode.bool), (function (v) {
                return RCore.$$Array.filterMap(RCore.$$Array.filterMap(Object.keys(v), Core__Float.fromString).map(Types.Id.fromNumber), f);
              }));
}

function decodeArrayIds(mappings) {
  var f = JobIndexIdToUuid.get(mappings);
  return JsonDecode.map(JsonDecode.array(Types.Id.decodeOpaque), (function (v) {
                return RCore.$$Array.filterMap(v, f);
              }));
}

function decodeV1$3(ctx, mappings) {
  return JsonDecode.object(function (field) {
              return {
                      teams: RCore.$$Option.getOr(field.optional("teams", decodeIds(mappings.teams)), []),
                      client: RCore.$$Option.flatMap(field.optional("client", JsonDecode.option(JobViewTypes.Settings.Filter.Client.decode)), (function (x) {
                              return x;
                            })),
                      workers: RCore.$$Option.getOr(field.optional("workers", decodeIds(mappings.workers)), []),
                      statuses: RCore.$$Option.getOr(field.optional("statuses", decodeStatusesV1), []),
                      templates: RCore.$$Option.getOr(field.optional("templates", JsonDecode.oneOf([
                                    decodeDict(mappings.templates),
                                    JsonDecode.map(JsonDecode.array(decodeId(mappings.templates)), RCore.$$Array.keepSome)
                                  ])), []),
                      resolutions: RCore.$$Option.getOr(field.optional("resolutions", decodeIds(mappings.resolutions)), []),
                      canceledResolutions: [],
                      territories: RCore.$$Option.getOr(field.optional("territories", decodeV1$2), []),
                      finishedAt: RCore.$$Option.flatten(field.optional("finishedAt", decodeV1$1(ctx))),
                      scheduledAt: RCore.$$Option.flatten(field.optional("scheduledAt", decodeV1$1(ctx))),
                      createdAt: undefined,
                      predefinedFields: [],
                      customFields: [],
                      reportFields: []
                    };
            });
}

var decodeV5$1 = JsonDecode.object(function (field) {
      return {
              teams: RCore.$$Option.getOr(field.optional("teams", JsonDecode.array(Uuid.decode)), []),
              client: RCore.$$Option.flatten(field.optional("client", JsonDecode.option(JobViewTypes.Settings.Filter.Client.decode))),
              workers: RCore.$$Option.getOr(field.optional("workers", JsonDecode.array(Uuid.decode)), []),
              statuses: RCore.$$Option.getOr(field.optional("statuses", decodeStatuses), []),
              templates: RCore.$$Option.getOr(field.optional("templates", JsonDecode.array(decodeUuid)), []),
              resolutions: RCore.$$Option.getOr(field.optional("resolutions", JsonDecode.array(Uuid.decode)), []),
              canceledResolutions: [],
              territories: RCore.$$Option.getOr(field.optional("territories", decode$1), []),
              finishedAt: RCore.$$Option.flatten(RCore.$$Option.flatten(field.optional("finishedAt", JsonDecode.option(decodeV4)))),
              scheduledAt: RCore.$$Option.flatten(RCore.$$Option.flatten(field.optional("scheduledAt", JsonDecode.option(decodeV4)))),
              createdAt: undefined,
              predefinedFields: RCore.$$Option.getOr(field.optional("predefinedFields", JsonDecode.array(JobViewTypes.Settings.Filter.PredefinedField.decode)), []),
              customFields: RCore.$$Option.getOr(field.optional("customFields", JsonDecode.array(JobViewTypes.Settings.Filter.CustomField.decode)), []),
              reportFields: RCore.$$Option.getOr(field.optional("reportFields", JsonDecode.array(JobViewTypes.Settings.Filter.CustomField.decode)), [])
            };
    });

var decodeV6 = JsonDecode.object(function (field) {
      return {
              teams: RCore.$$Option.getOr(field.optional("teams", JsonDecode.array(Uuid.decode)), []),
              client: RCore.$$Option.flatMap(field.optional("client", JsonDecode.option(JobViewTypes.Settings.Filter.Client.decode)), (function (x) {
                      return x;
                    })),
              workers: RCore.$$Option.getOr(field.optional("workers", JsonDecode.array(Uuid.decode)), []),
              statuses: RCore.$$Option.getOr(field.optional("statuses", decodeStatuses), []),
              templates: RCore.$$Option.getOr(field.optional("templates", JsonDecode.array(decodeUuid)), []),
              resolutions: RCore.$$Option.getOr(field.optional("resolutions", JsonDecode.array(Uuid.decode)), []),
              canceledResolutions: RCore.$$Option.getOr(field.optional("canceledResolutions", JsonDecode.array(Uuid.decode)), []),
              territories: RCore.$$Option.getOr(field.optional("territories", decode$1), []),
              finishedAt: RCore.$$Option.flatten(field.optional("finishedAt", JsonDecode.option(decodePredefined))),
              scheduledAt: RCore.$$Option.flatten(field.optional("scheduledAt", JsonDecode.option(decodePredefined))),
              createdAt: RCore.$$Option.flatten(field.optional("createdAt", JsonDecode.option(decodePredefined))),
              predefinedFields: RCore.$$Option.getOr(field.optional("predefinedFields", JsonDecode.array(JobViewTypes.Settings.Filter.PredefinedField.decode)), []),
              customFields: RCore.$$Option.getOr(field.optional("customFields", JsonDecode.array(JobViewTypes.Settings.Filter.CustomField.decode)), []),
              reportFields: RCore.$$Option.getOr(field.optional("reportFields", JsonDecode.array(JobViewTypes.Settings.Filter.CustomField.decode)), [])
            };
    });

function decode$2(version, ctx, mappings) {
  if (version === undefined) {
    return decodeV1$3(ctx, mappings);
  }
  switch (version) {
    case "V1" :
        return decodeV1$3(ctx, mappings);
    case "V2" :
    case "V3" :
        return JsonDecode.object(function (field) {
                    return {
                            teams: RCore.$$Option.getOr(field.optional("teams", decodeArrayIds(mappings.teams)), []),
                            client: RCore.$$Option.flatMap(field.optional("client", JsonDecode.option(JobViewTypes.Settings.Filter.Client.decode)), (function (x) {
                                    return x;
                                  })),
                            workers: RCore.$$Option.getOr(field.optional("workers", decodeArrayIds(mappings.workers)), []),
                            statuses: RCore.$$Option.getOr(field.optional("statuses", decodeStatuses), []),
                            templates: RCore.$$Array.keepSome(RCore.$$Option.getOr(field.optional("templates", JsonDecode.array(decodeId(mappings.templates))), [])),
                            resolutions: RCore.$$Option.getOr(field.optional("resolutions", JsonDecode.oneOf([
                                          decodeArrayIds(mappings.resolutions),
                                          decodeIds(mappings.resolutions)
                                        ])), []),
                            canceledResolutions: [],
                            territories: RCore.$$Option.getOr(field.optional("territories", decode$1), []),
                            finishedAt: RCore.$$Option.flatten(field.optional("finishedAt", decodeV1$1(ctx))),
                            scheduledAt: RCore.$$Option.flatten(field.optional("scheduledAt", decodeV1$1(ctx))),
                            createdAt: undefined,
                            predefinedFields: [],
                            customFields: [],
                            reportFields: []
                          };
                  });
    case "V4" :
        return JsonDecode.object(function (field) {
                    return {
                            teams: RCore.$$Option.getOr(field.optional("teams", decodeArrayIds(mappings.teams)), []),
                            client: RCore.$$Option.flatten(field.optional("client", JsonDecode.option(JobViewTypes.Settings.Filter.Client.decode))),
                            workers: RCore.$$Option.getOr(field.optional("workers", decodeArrayIds(mappings.workers)), []),
                            statuses: RCore.$$Option.getOr(field.optional("statuses", decodeStatuses), []),
                            templates: RCore.$$Array.keepSome(RCore.$$Option.getOr(field.optional("templates", JsonDecode.array(decodeId(mappings.templates))), [])),
                            resolutions: RCore.$$Option.getOr(field.optional("resolutions", decodeArrayIds(mappings.resolutions)), []),
                            canceledResolutions: [],
                            territories: RCore.$$Option.getOr(field.optional("territories", decode$1), []),
                            finishedAt: RCore.$$Option.flatten(RCore.$$Option.flatten(field.optional("finishedAt", JsonDecode.option(decodeV4)))),
                            scheduledAt: RCore.$$Option.flatten(RCore.$$Option.flatten(field.optional("scheduledAt", JsonDecode.option(decodeV4)))),
                            createdAt: undefined,
                            predefinedFields: RCore.$$Option.getOr(field.optional("predefinedFields", JsonDecode.array(JobViewTypes.Settings.Filter.PredefinedField.decode)), []),
                            customFields: RCore.$$Option.getOr(field.optional("customFields", JsonDecode.array(JobViewTypes.Settings.Filter.CustomField.decode)), []),
                            reportFields: RCore.$$Option.getOr(field.optional("reportFields", JsonDecode.array(JobViewTypes.Settings.Filter.CustomField.decode)), [])
                          };
                  });
    case "V5" :
        return decodeV5$1;
    case "V6" :
    case "V7" :
        return decodeV6;
    
  }
}

function decode$3(ctx, mappings) {
  return JsonDecode.flatMap(JsonDecode.object(function (field) {
                  return field.optional("version", JobViewTypes.Version.decode);
                }), (function (version) {
                return JsonDecode.object(function (field) {
                            return {
                                    columns: field.required("columns", decode(version, mappings)),
                                    filter: field.required("filter", decode$2(version, ctx, mappings)),
                                    scheduled: RCore.$$Option.flatMap(field.optional("scheduled", JsonDecode.option(JobViewTypes.Scheduled.decode)), (function (x) {
                                            return x;
                                          })),
                                    resolutions: RCore.$$Option.flatMap(field.optional("resolutions", JsonDecode.option(JobViewTypes.Resolutions.decode)), (function (x) {
                                            return x;
                                          })),
                                    upToDate: Caml_obj.equal(version, "V7")
                                  };
                          });
              }));
}

function decode$4(ctx, mappings) {
  return JsonDecode.object(function (field) {
              return {
                      uuid: field.required("uuid", Uuid.decode),
                      name: field.required("name", JsonDecode.string),
                      settings: field.required("settings", decode$3(ctx, mappings)),
                      orderNo: field.required("orderNo", JsonDecode.$$int),
                      system: field.required("system", JsonDecode.bool),
                      destroy: RCore.$$Option.getOr(RCore.$$Option.flatMap(field.optional("destroy", JsonDecode.option(JsonDecode.bool)), (function (x) {
                                  return x;
                                })), false)
                    };
            });
}

export {
  decode$4 as decode,
}
/* decodeV5 Not a pure module */
