

import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JobTypeSelect from "../../../inputs/JobTypeSelect.mjs";
import * as MapJobsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/map/jobs/MapJobs.module.css";

var styles = MapJobsModuleCss;

var jobType$p = Utils.Translations.t("js.job_type_list.type");

function MapJobsFilterByType(Props) {
  var wire = Props.wire;
  var jobType = Props.jobType;
  var types = Props.types;
  var onChange = Props.onChange;
  return React.createElement("div", {
              className: styles.jobTypeSelect
            }, React.createElement(JobTypeSelect.make, {
                  placeholder: jobType$p(wire.ctx),
                  onChange: (function (x) {
                      onChange(x === null ? undefined : Caml_option.some(x));
                    }),
                  value: Js_null.fromOption(jobType),
                  options: types.map(function (param) {
                        return {
                                value: param.uuid,
                                label: param.name
                              };
                      })
                }));
}

var make = MapJobsFilterByType;

export {
  make ,
}
/* styles Not a pure module */
