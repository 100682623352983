// extracted by mini-css-extract-plugin
export var bodyOverlay = "body-overlay-Yh8b3BAbCn8Yme_mqez6";
export var close = "close-Vh3Ev3nHNUALat0DhoRw";
export var closeBtn = "close-btn-vyZnLow_nq0_SaG6KYbR";
export var container = "container-Q5D5HS7nEZ0lR3SUsuJD";
export var dialog = "dialog-oGDwMPWHQYrQXmF6fiyT";
export var dialogContainer = "dialog-container-GAq3CDD08YCSqaOpKxSm";
export var dialogNested = "dialog-nested-RDkqH8S4LcIZkEH7XST4";
export var footer = "footer-rEaJZnjaDRnY6ln2fogM";
export var overlay = "overlay-PEnIiOmTPjcsDXQbXuTr";
export var removeBtn = "remove-btn-ZpOEFo58UuIkVhE78Mw5";