

import * as El from "../../../../../../libraries/El.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../types/Types.mjs";
import * as React from "react";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as WeekCalendar_Scale from "../scale/WeekCalendar_Scale.mjs";
import * as WeekCalendar_Utils from "../../lib/WeekCalendar_Utils.mjs";
import * as Shared_Lib_Debounce from "../../../../../../shared/lib/Shared_Lib_Debounce.mjs";
import * as WeekCalendar_Column from "../column/WeekCalendar_Column.mjs";
import * as WeekCalendar_Constants from "../../lib/WeekCalendar_Constants.mjs";
import * as WeekCalendar_DragLayer from "../drag_layer/WeekCalendar_DragLayer.mjs";
import * as WeekCalendar_CurrentTime from "../current_time/WeekCalendar_CurrentTime.mjs";
import * as WeekCalendar_ColumnHeader from "../column/WeekCalendar_ColumnHeader.mjs";
import * as WeekCalendar_BusinessHours from "../business_hours/WeekCalendar_BusinessHours.mjs";
import * as WeekCalendar_TransformJobs from "../../lib/WeekCalendar_TransformJobs.mjs";
import * as WeekCalendar_ContentModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_calendar/components/content/WeekCalendar_Content.module.css";

var styles = WeekCalendar_ContentModuleCss;

function getPx(columnWidth) {
  return (columnWidth * WeekCalendar_Constants.daysInWeek + 60.0).toString() + "px";
}

function WeekCalendar_Content(Props) {
  var wire = AppContext.useWire();
  var subscriptions = wire.subscriptions;
  var startOfWeek = Schedule_State.useStartOfPeriod();
  var jobs = Schedule_State.useScheduledJobs();
  var showUnscheduledJobs = Schedule_State.useShowUnscheduledJobs();
  var jobs$1 = WeekCalendar_TransformJobs.transform(wire, jobs);
  var match = React.useState(function () {
        return WeekCalendar_Utils.ColumnWidth.get(showUnscheduledJobs);
      });
  var setColumnWidth = match[1];
  var columnWidth = match[0];
  var calendarRef = Types.ReactRef.use();
  var match$1 = Shared_Lib_Debounce.debounce(300, undefined, (function () {
          setColumnWidth(function (param) {
                return WeekCalendar_Utils.ColumnWidth.get(showUnscheduledJobs);
              });
        }));
  var onResize = match$1.f;
  React.useEffect((function () {
          return subscriptions.resize.subscribe(function (param) {
                      onResize();
                    });
        }), [showUnscheduledJobs]);
  React.useEffect((function () {
          setColumnWidth(function (param) {
                return WeekCalendar_Utils.ColumnWidth.get(showUnscheduledJobs);
              });
        }), [showUnscheduledJobs]);
  var days = RCore.$$Array.make(WeekCalendar_Constants.daysInWeek, startOfWeek).map(function (d, i) {
        return Locale.T.nextDays(i)(d);
      });
  var jobColumns = new Array(0);
  var headerColumns = new Array(0);
  days.forEach(function (day, index) {
        var jobs$2 = RCore.$$Option.mapWithDefault(RCore.$$Array.getBy(jobs$1, (function (param) {
                    return Locale.T.equalDay(param.date, day);
                  })), [], (function (param) {
                return param.jobs;
              }));
        var jobColumn = React.createElement(WeekCalendar_Column.make, {
              calendarRef: calendarRef,
              jobs: jobs$2,
              columnWidth: columnWidth,
              weekDay: day,
              key: index.toString()
            });
        var header = React.createElement(WeekCalendar_ColumnHeader.make, {
              prevDay: days[index - 1 | 0],
              day: day,
              columnWidth: columnWidth,
              sizeOfJobs: jobs$2.length,
              key: index.toString()
            });
        Js_array.push(jobColumn, jobColumns);
        Js_array.push(header, headerColumns);
      });
  var headerStyle = {
    width: getPx(columnWidth)
  };
  var contentStyle = {
    width: (columnWidth * WeekCalendar_Constants.daysInWeek).toString() + "px"
  };
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  ref: calendarRef,
                  className: El.Cn.concat(styles.calendar, showUnscheduledJobs ? styles.compact : ""),
                  onScroll: subscriptions.scroll.trigger
                }, React.createElement("div", {
                      className: styles.calendarContainer
                    }, React.createElement("div", {
                          className: styles.header,
                          style: headerStyle
                        }, headerColumns), React.createElement(WeekCalendar_BusinessHours.make, {
                          columnWidth: columnWidth
                        }), React.createElement("div", {
                          className: styles.week
                        }, React.createElement(WeekCalendar_Scale.make, {}), React.createElement("div", {
                              className: styles.columns,
                              style: contentStyle
                            }, React.createElement(WeekCalendar_CurrentTime.make, {
                                  columnWidth: columnWidth
                                }), jobColumns)), React.createElement(WeekCalendar_BusinessHours.make, {
                          columnWidth: columnWidth,
                          showColumns: true
                        }))), React.createElement(WeekCalendar_DragLayer.make, {
                  columnWidth: columnWidth
                }));
}

var make = WeekCalendar_Content;

export {
  make ,
}
/* styles Not a pure module */
