import React from 'react'
import { YandexMap } from './yandex'
import { GoogleMap } from './google'

export default ({ mapType, children, ...rest }) => {
  switch (mapType) {
    case 'yandex':
      return <YandexMap {...rest}>{children}</YandexMap>
    case 'google':
      return <GoogleMap {...rest}>{children}</GoogleMap>
    default:
      throw `Unknown map type ${mapType}`
  }
}
