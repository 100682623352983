

import * as RCore from "../../../../../../../libraries/RCore.mjs";
import * as Units from "../../../../../../../types/Units.mjs";
import * as React from "react";
import * as Locale from "../../../../../../../libraries/Locale.mjs";
import * as Schedule_State from "../../../../../lib/state/Schedule_State.mjs";
import * as WeekTimeline_State from "../../../lib/state/WeekTimeline_State.mjs";
import * as WeekTimeline_ScaleDay from "./WeekTimeline_ScaleDay.mjs";
import * as WeekTimeline_Constants from "../../../lib/WeekTimeline_Constants.mjs";
import * as WeekTimeline_CurrentTime from "../current_time/WeekTimeline_CurrentTime.mjs";
import * as WeekTimeline_ScaleModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/timeline/scale/WeekTimeline_Scale.module.css";

var styles = WeekTimeline_ScaleModuleCss;

function WeekTimeline_Scale(Props) {
  var startOfWeek = Schedule_State.useStartOfPeriod();
  var sizes = WeekTimeline_State.useSizes();
  var daysBlock = RCore.$$Array.make(WeekTimeline_Constants.daysInWeek, startOfWeek).map(function (date, index) {
        var day = Locale.T.nextDays(index)(date);
        return React.createElement(WeekTimeline_ScaleDay.make, {
                    day: day,
                    key: index.toString()
                  });
      });
  var style = {
    width: Units.Px.toString(sizes.viewport)
  };
  return React.createElement("div", {
              className: styles.days,
              style: style
            }, daysBlock, React.createElement(WeekTimeline_CurrentTime.make, {
                  part: "Triangle"
                }));
}

var make = WeekTimeline_Scale;

export {
  make ,
}
/* styles Not a pure module */
