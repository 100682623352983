import { connect } from 'react-redux'
import {
  setResolution,
  deleteResolution,
  restoreResolution,
  addResolution,
  cancelAddResolution,
  editResolution,
  sendForm
} from 'planado/actions/admin/resolutions'
import { SaveButton } from 'planado/components/admin/resolutions/save_button'

import List from 'planado/components/admin/resolutions/list'

const inputId = name => `settings-${name}`

const mapStateToProps = ({ resolutions, formDisabled }, _ownProps) => ({
  resolutions,
  formDisabled,
  inputId
})

const mapDispatchToProps = (dispatch, _ownProps) => ({
  deleteResolution: resolutionId => dispatch(deleteResolution(resolutionId)),
  restoreResolution: resolutionId => dispatch(restoreResolution(resolutionId)),
  addResolution: () => dispatch(addResolution()),
  setResolution: (resolutionId, key, value) =>
    dispatch(setResolution(resolutionId, key, value)),
  cancelAddResolution: resolutionId =>
    dispatch(cancelAddResolution(resolutionId)),
  editResolution: resolutionId => dispatch(editResolution(resolutionId))
})

export const ResolutionList = connect(mapStateToProps, mapDispatchToProps)(List)

const SaveButtonC = {
  mapStateToProps: (state, _ownProps) => ({
    disabled: state.formDisabled
  }),
  mapDispatchToProps: (dispatch, _ownProps) => ({
    sendForm: (ctx, onSuccess, onFailure) =>
      dispatch(sendForm(ctx, onSuccess, onFailure))
  })
}

export const SaveButtonLink = connect(
  SaveButtonC.mapStateToProps,
  SaveButtonC.mapDispatchToProps
)(SaveButton)
