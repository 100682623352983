

import * as El from "../../../../../libraries/El.mjs";
import * as Utils from "../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../../../common/Optional.mjs";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as FileInput_Preview_RemoveButton from "./FileInput_Preview_RemoveButton.mjs";
import * as FileInput_PreviewModuleCss from "/home/runner/work/planado/planado/client/rescript/components/file/file_input/components/preview/FileInput_Preview.module.css";

var styles = FileInput_PreviewModuleCss;

var fileNotUploaded$p = Utils.Translations.tr("js.components.file_input.file_not_uploaded");

function FileInput_Preview_NotUploaded(Props) {
  var name = Props.name;
  var readOnly = Props.readOnly;
  var removeFile = Props.removeFile;
  var ctx = AppContext.useCtx();
  return React.createElement("div", {
              className: El.Cn.concat(styles.imageWrapper, styles.notUploaded)
            }, React.createElement("div", {
                  className: styles.notUploadedFile
                }, fileNotUploaded$p(ctx)), React.createElement("div", {
                  className: styles.imageHover
                }, React.createElement("div", {
                      className: El.Cn.concat(styles.imageContent, styles.notUploaded)
                    }, React.createElement("div", {
                          className: styles.imageContentText
                        }, name)), React.createElement(Optional.make, {
                      show: !readOnly,
                      children: React.createElement("div", {
                            className: El.Cn.concat(styles.imageButtons, styles.notUploaded)
                          }, React.createElement(FileInput_Preview_RemoveButton.make, {
                                removeFile: removeFile,
                                decoration: "Light"
                              }))
                    })));
}

var make = FileInput_Preview_NotUploaded;

export {
  make ,
}
/* styles Not a pure module */
