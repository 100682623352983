import React from 'react'
import { withContext } from 'planado/utils/contextConsumer.jsx'

const DisabledSpinner = ({ id, name, value }) => {
  return (
    <div className="pd-spinner">
      <input
        id={id}
        className="pd-form-control"
        name={name}
        size="2"
        value={value}
        type="text"
        readOnly
      />
    </div>
  )
}

const DisabledSpinners = ({ id, name, hours, minutes, ctx }) => {
  return (
    <div className="pd-flex">
      <DisabledSpinner id={id} name={name} value={hours} />
      <span className="pd-spinner-text">
        {ctx.t('js.jobs.labels.duration_h')}
      </span>

      <DisabledSpinner id={id} name={name} value={minutes} />
      <span className="pd-spinner-text">
        {ctx.t('js.jobs.labels.duration_m')}
      </span>
    </div>
  )
}

class DurationSpinner extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: this.props.defaultValue || this.props.min
    }

    this.spinTimer = null
    this.holdTimer = null

    this.onHourChange = this.onHourChange.bind(this)
    this.onMinuteChange = this.onMinuteChange.bind(this)

    this.updateValue = this.updateValue.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)

    this.stopSpinTimer = this.stopSpinTimer.bind(this)
    this.startSpinTimer = this.startSpinTimer.bind(this)

    this.stopHoldTimer = this.stopHoldTimer.bind(this)
    this.startHoldTimer = this.startHoldTimer.bind(this)

    this.onMouseUp = this.onMouseUp.bind(this)
    this.onMouseOut = this.onMouseOut.bind(this)

    this.handleAction = this.handleAction.bind(this)
  }

  get minutes() {
    return this.state.value % 60
  }

  get hours() {
    return Math.floor(this.state.value / 60)
  }

  updateValue(newValue) {
    const { max, min, onValueChange } = this.props

    const value = Math.max(Math.min(newValue, max), min)

    this.setState(() => ({ value }))
    onValueChange(value * 60)
  }

  startSpinTimer(context, name) {
    this.stopSpinTimer()
    this.stopHoldTimer()

    this.spinTimer = window.setInterval(
      () => this.handleAction(context, name),
      75
    )
  }

  stopSpinTimer() {
    if (this.spinTimer) {
      window.clearInterval(this.spinTimer)
      this.spinTimer = null
    }
  }

  startHoldTimer(context, name) {
    this.stopHoldTimer()

    this.holdTimer = window.setTimeout(
      () => this.startSpinTimer(context, name),
      600
    )
  }

  stopHoldTimer() {
    if (this.holdTimer) {
      window.clearTimeout(this.holdTimer)
      this.holdTimer = null
    }
  }

  handleAction(context, name) {
    if (context !== 'hour' && context !== 'minute') {
      console.error(
        `DurationSpinner.handleAction expects context to be either "hour" or "minute". Given value: ${context}`
      )
      return
    }

    let step = context == 'hour' ? 60 : this.props.step
    let step_page = context == 'hour' ? 300 : 30

    switch (name) {
      case 'pgup':
        this.updateValue(this.state.value + step_page)
        break
      case 'pgdn':
        this.updateValue(this.state.value - step_page)
        break
      case 'inc':
        this.updateValue(this.state.value + step)
        break
      case 'dec':
        this.updateValue(this.state.value - step)
        break
      default:
        console.error(
          `DurationSpinner.handleAction expects name to be either "pgup", "pgdn", "inc" or "dec". Given value: ${name}`
        )
    }
  }

  onKeyDown(e, context) {
    let actions = {
      33: 'pgup',
      34: 'pgdn',
      38: 'inc',
      40: 'dec'
    }

    if (actions[e.keyCode]) {
      e.preventDefault()
      this.handleAction(context, actions[e.keyCode])
    }
  }

  onMouseUp(e, context, name) {
    if (this.holdTimer) {
      this.handleAction(context, name)
    }

    this.stopHoldTimer()
    this.stopSpinTimer()
  }

  onMouseOut(_e) {
    this.stopHoldTimer()
    this.stopSpinTimer()
  }

  onHourChange(e) {
    let targetValue = e.target.value.replace(/\D/g, '')
    let parsedValue = parseInt(targetValue)

    if (targetValue.length == 0) {
      this.updateValue(this.minutes)
    } else if (
      parsedValue * 60 + this.minutes < this.props.max &&
      parsedValue >= 0
    ) {
      this.updateValue(parsedValue * 60 + this.minutes)
    }
  }

  onMinuteChange(e) {
    let targetValue = e.target.value.replace(/\D/g, '')
    let parsedValue = parseInt(targetValue)

    if (parsedValue < 0 || parsedValue > 59) {
      return
    } else if (targetValue.length == 0) {
      this.updateValue(this.hours * 60)
    } else {
      this.updateValue(this.hours * 60 + parsedValue)
    }
  }

  render() {
    const { ctx, disabled } = this.props

    return disabled ? (
      <DisabledSpinners
        id={this.props.id}
        name={this.props.name}
        hours={this.hours}
        minutes={this.minutes}
        ctx={ctx}
      />
    ) : (
      <div className="pd-flex">
        <div className="pd-spinner">
          <input
            id={this.props.id}
            role="spinbutton"
            autoComplete="off"
            className="pd-form-control"
            name={this.props.name}
            size="2"
            value={this.hours}
            onChange={this.onHourChange}
            onKeyDown={e => this.onKeyDown(e, 'hour')}
            type="text"
          />
          <span
            tabIndex="-1"
            className="pd-spinner-arrow pd-spinner-arrow-up"
            onMouseDown={_e => this.startHoldTimer('hour', 'inc')}
            onMouseUp={e => this.onMouseUp(e, 'hour', 'inc')}
            onMouseOut={this.onMouseOut}
          />

          <span
            tabIndex="-1"
            className="pd-spinner-arrow pd-spinner-arrow-down"
            onMouseDown={_e => this.startHoldTimer('hour', 'dec')}
            onMouseUp={e => this.onMouseUp(e, 'hour', 'dec')}
            onMouseOut={this.onMouseOut}
          />
        </div>

        <span className="pd-spinner-text">
          {ctx.t('js.jobs.labels.duration_h')}
        </span>

        <div className="pd-spinner">
          <input
            id={this.props.id + '_minute'}
            role="spinbutton"
            autoComplete="off"
            className="pd-form-control"
            name={this.props.name}
            size="2"
            value={this.minutes}
            onChange={this.onMinuteChange}
            onKeyDown={e => this.onKeyDown(e, 'minute')}
            type="text"
          />
          <span
            tabIndex="-1"
            className="pd-spinner-arrow pd-spinner-arrow-up"
            onMouseDown={_e => this.startHoldTimer('minute', 'inc')}
            onMouseUp={e => this.onMouseUp(e, 'minute', 'inc')}
            onMouseOut={this.onMouseOut}
          />

          <span
            tabIndex="-1"
            className="pd-spinner-arrow pd-spinner-arrow-down"
            onMouseDown={_e => this.startHoldTimer('minute', 'dec')}
            onMouseUp={e => this.onMouseUp(e, 'minute', 'dec')}
            onMouseOut={this.onMouseOut}
          />
        </div>

        <span className="pd-spinner-text">
          {ctx.t('js.jobs.labels.duration_m')}
        </span>
      </div>
    )
  }
}

DurationSpinner.defaultProps = {
  step: 1,
  name: '',
  onValueChange: () => { }
}

export default withContext(DurationSpinner)
