

import * as Radio from "../inputs/Radio.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as UI_Form from "../../ui/UI_Form.mjs";
import * as AppContext from "../../context/AppContext.mjs";
import * as Settings_MapModuleCss from "/home/runner/work/planado/planado/client/rescript/components/settings/Settings_Map.module.css";

var title$p = Utils.Translations.tr("js.settings.map_settings.title");

var google$p = Utils.Translations.t("js.settings.map_settings.google");

var yandex$p = Utils.Translations.t("js.settings.map_settings.yandex");

var styles = Settings_MapModuleCss;

function Settings_Map(Props) {
  var setMapType = Props.setMapType;
  var mapType = Props.mapType;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  return React.createElement(UI_Form.Section.make, {
              children: null
            }, React.createElement(UI_Form.SectionTitle.make, {
                  children: title$p(ctx)
                }), React.createElement(UI_Form.Container.make, {
                  children: null
                }, React.createElement(UI_Form.Group.make, {
                      children: React.createElement(Radio.make, {
                            onChange: (function (param) {
                                setMapType("Google");
                              }),
                            checked: mapType === "Google",
                            labelText: google$p(ctx),
                            labelClass: styles.radioLabel,
                            inputClass: styles.radioInput,
                            disabled: ctx.disposable
                          })
                    }), React.createElement(UI_Form.Group.make, {
                      children: React.createElement(Radio.make, {
                            onChange: (function (param) {
                                setMapType("Yandex");
                              }),
                            checked: mapType === "Yandex",
                            labelText: yandex$p(ctx),
                            labelClass: styles.radioLabel,
                            inputClass: styles.radioInput,
                            disabled: ctx.disposable
                          })
                    })));
}

var make = Settings_Map;

export {
  make ,
}
/* title' Not a pure module */
