

import * as RCore from "./RCore.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";

function assertEqual(value, expected) {
  if (Caml_obj.equal(value, expected)) {
    return ;
  }
  throw {
        RE_EXN_ID: Json_Decode$JsonCombinators.DecodeError,
        _1: "Expected " + RCore.$$Option.getOr(JSON.stringify(expected), "") + ", got " + RCore.$$Option.getOr(JSON.stringify(value), ""),
        Error: new Error()
      };
}

function fromVariant(decoder, f) {
  return Json_Decode$JsonCombinators.custom(function (json) {
              var value = f(decoder(json));
              if (value !== undefined) {
                return Caml_option.valFromOption(value);
              }
              throw {
                    RE_EXN_ID: Json_Decode$JsonCombinators.DecodeError,
                    _1: "Invalid variant: " + JSON.stringify(json),
                    Error: new Error()
                  };
            });
}

function cannotDecode(msg) {
  throw {
        RE_EXN_ID: Json_Decode$JsonCombinators.DecodeError,
        _1: msg,
        Error: new Error()
      };
}

var unit = Json_Decode$JsonCombinators.custom(function (param) {
      
    });

var custom = Json_Decode$JsonCombinators.custom;

var id = Json_Decode$JsonCombinators.id;

var $$float = Json_Decode$JsonCombinators.$$float;

var $$int = Json_Decode$JsonCombinators.$$int;

var bool = Json_Decode$JsonCombinators.bool;

var string = Json_Decode$JsonCombinators.string;

var array = Json_Decode$JsonCombinators.array;

var list = Json_Decode$JsonCombinators.list;

var object = Json_Decode$JsonCombinators.object;

var option = Json_Decode$JsonCombinators.option;

var date = Json_Decode$JsonCombinators.date;

var pair = Json_Decode$JsonCombinators.pair;

var tuple2 = Json_Decode$JsonCombinators.tuple2;

var tuple3 = Json_Decode$JsonCombinators.tuple3;

var tuple4 = Json_Decode$JsonCombinators.tuple4;

var dict = Json_Decode$JsonCombinators.dict;

var field = Json_Decode$JsonCombinators.field;

var oneOf = Json_Decode$JsonCombinators.oneOf;

var map = Json_Decode$JsonCombinators.map;

var flatMap = Json_Decode$JsonCombinators.flatMap;

var indirect = Json_Decode$JsonCombinators.indirect;

var decode = Json_Decode$JsonCombinators.decode;

export {
  custom ,
  id ,
  $$float ,
  $$int ,
  bool ,
  string ,
  array ,
  list ,
  object ,
  option ,
  date ,
  pair ,
  tuple2 ,
  tuple3 ,
  tuple4 ,
  dict ,
  field ,
  oneOf ,
  map ,
  flatMap ,
  indirect ,
  decode ,
  assertEqual ,
  fromVariant ,
  cannotDecode ,
  unit ,
}
/* unit Not a pure module */
