

import * as RCore from "./RCore.mjs";

function fromTuples(alwaysOpt, pairs) {
  var always = alwaysOpt !== undefined ? alwaysOpt : [];
  return RCore.$$Array.reduce(pairs, always.join(" "), (function (acc, param) {
                if (!param[1]) {
                  return acc;
                }
                var s = param[0];
                if (acc === "") {
                  return s;
                } else {
                  return acc + " " + s;
                }
              }));
}

export {
  fromTuples ,
}
/* No side effect */
