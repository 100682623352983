import {
  SET_TYPE,
  REMOVE_TYPE,
  ADD_TYPE,
  SEND_FORM_REQUEST,
  SEND_FORM_SUCCESS,
  SEND_FORM_FAIL
} from 'planado/actions/admin/job_types'

const initialType = { id: null, name: '', canBeRemoved: true, removed: false }

const removeType = (types, idx) => {
  if (types[idx].id === null) {
    return [...types.slice(0, idx), ...types.slice(idx + 1, types.length)]
  } else {
    return types.map((type, index) =>
      idx === index ? { ...type, removed: true } : type
    )
  }
}

const setType = (types, idx, value) =>
  types.map((type, index) => (idx === index ? { ...type, name: value } : type))

export default (state, action) => {
  switch (action.type) {
    case SET_TYPE:
      return {
        ...state,
        types: setType(state.types, action.idx, action.value)
      }
    case SEND_FORM_REQUEST:
      return {
        ...state,
        formDisabled: true,
        errors: []
      }
    case SEND_FORM_SUCCESS:
      return {
        ...state,
        types: action.types,
        formDisabled: false,
        errors: []
      }
    case SEND_FORM_FAIL:
      return {
        ...state,
        formDisabled: false,
        errors: action.errors
      }
    case REMOVE_TYPE:
      return {
        ...state,
        types: removeType(state.types, action.idx)
      }
    case ADD_TYPE:
      return {
        ...state,
        types: [...state.types, initialType]
      }
    default:
      return state
  }
}
