

import * as JsonDecode from "../../../../../libraries/JsonDecode.mjs";

function fromString(status) {
  switch (status) {
    case "assigned" :
        return "Assigned";
    case "canceled" :
        return "Canceled";
    case "changed" :
        return "Changed";
    case "en_route" :
        return "EnRoute";
    case "finished" :
        return "Finished";
    case "posted" :
        return "Posted";
    case "published" :
        return "Published";
    case "scheduled" :
        return "Scheduled";
    case "started" :
        return "Started";
    case "suspended" :
        return "Suspended";
    default:
      return ;
  }
}

function toString(status) {
  switch (status) {
    case "Posted" :
        return "posted";
    case "Scheduled" :
        return "scheduled";
    case "Assigned" :
        return "assigned";
    case "Changed" :
        return "changed";
    case "Published" :
        return "published";
    case "EnRoute" :
        return "en_route";
    case "Started" :
        return "started";
    case "Suspended" :
        return "suspended";
    case "Canceled" :
        return "canceled";
    case "Finished" :
        return "finished";
    
  }
}

var decode = JsonDecode.fromVariant(JsonDecode.string, fromString);

export {
  toString ,
  decode ,
}
/* decode Not a pure module */
