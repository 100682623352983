

import * as Uuid from "../../../../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../../../../libraries/RCore.mjs";
import * as React from "react";
import * as Assignee_Types from "../../../../../../../../types/Assignee_Types.mjs";
import * as WeekTimeline_ActiveDay from "../../../active_day/WeekTimeline_ActiveDay.mjs";
import * as WeekTimeline_Constants from "../../../../lib/WeekTimeline_Constants.mjs";

function activeDayKey(assignee, weekDay) {
  return Uuid.toString(Assignee_Types.Assignee.uuid(assignee)) + "-" + weekDay.toString();
}

function WeekTimeline_BarActiveDay(Props) {
  var assignee = Props.assignee;
  return RCore.$$Array.make(WeekTimeline_Constants.daysInWeek, undefined).map(function (param, weekDay) {
              return React.createElement(WeekTimeline_ActiveDay.make, {
                          assignee: assignee,
                          weekDay: weekDay,
                          key: activeDayKey(assignee, weekDay)
                        });
            });
}

var make = WeekTimeline_BarActiveDay;

export {
  make ,
}
/* Uuid Not a pure module */
