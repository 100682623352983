


var containers = [
  "TopLeft",
  "TopRight",
  "BottomLeft",
  "BottomRight"
];

var Position = {
  containers: containers
};

var MessageType = {};

var MessageBody = {};

var _nextId = (function (_) { return new Date().toISOString() });

function make(positionOpt, messageTypeOpt, messageBody) {
  var position = positionOpt !== undefined ? positionOpt : "TopRight";
  var messageType = messageTypeOpt !== undefined ? messageTypeOpt : "Success";
  return {
          id: _nextId(),
          messageBody: messageBody,
          position: position,
          messageType: messageType
        };
}

var $$Event = {};

var Message = {
  make: make
};

export {
  Position ,
  MessageType ,
  MessageBody ,
  Message ,
  $$Event ,
}
/* No side effect */
