

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as SelectJsx from "planado/components/input/select.jsx";

function fromString(label, value) {
  return {
          label: label,
          value: value
        };
}

function fromUuid(label, value) {
  return {
          label: label,
          value: Uuid.toString(value)
        };
}

function make(label, value) {
  return {
          label: label,
          value: value
        };
}

var $$Option = {
  fromString: fromString,
  fromUuid: fromUuid,
  make: make
};

var make$1 = SelectJsx.ContextlessSelect;

export {
  $$Option ,
  make$1 as make,
}
/* make Not a pure module */
