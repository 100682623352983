

import * as RCore from "../../../../../../../libraries/RCore.mjs";
import * as Units from "../../../../../../../types/Units.mjs";
import * as React from "react";
import * as Locale from "../../../../../../../libraries/Locale.mjs";
import * as AppContext from "../../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_State from "../../../../../lib/state/Schedule_State.mjs";
import * as DayTimeline_State from "../../../lib/state/DayTimeline_State.mjs";
import * as DayTimeline_ScaleHour from "./DayTimeline_ScaleHour.mjs";
import * as DayTimeline_CurrentTime from "../../current_time/DayTimeline_CurrentTime.mjs";
import * as DayTimeline_HighlightTime from "../../highlight_time/DayTimeline_HighlightTime.mjs";
import * as Schedule_UtilsBusinessHours from "../../../../../lib/utils/Schedule_UtilsBusinessHours.mjs";
import * as DayTimeline_ScaleModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/day_timeline/components/timeline/scale/DayTimeline_Scale.module.css";

var styles = DayTimeline_ScaleModuleCss;

function makeStyle(width) {
  return {
          width: Units.Px.toString(width)
        };
}

function DayTimeline_Scale(Props) {
  var scaleEl = Props.scaleEl;
  var viewportEl = Props.viewportEl;
  var wire = AppContext.useWire();
  var sizes = DayTimeline_State.useSizes();
  var currentDay = Schedule_State.useCurrentDay();
  var businessHours = Schedule_State.useBusinessHours();
  var startOfDay = Schedule_UtilsBusinessHours.startOfDay(Caml_option.some(currentDay), wire, businessHours);
  var hours = RCore.$$Array.make(Schedule_UtilsBusinessHours.hoursInDay(businessHours), startOfDay).map(function (hour, index) {
        return Locale.T.fmtM("Time", wire)(Locale.T.nextHours(index)(hour));
      });
  return React.createElement("div", {
              className: styles.container,
              style: makeStyle(sizes.viewport)
            }, React.createElement("div", {
                  ref: scaleEl,
                  className: styles.hours,
                  style: makeStyle(sizes.timeline)
                }, hours.map(function (hour) {
                      return React.createElement(DayTimeline_ScaleHour.make, {
                                  hour: hour,
                                  viewportEl: viewportEl,
                                  key: hour
                                });
                    }), React.createElement(DayTimeline_CurrentTime.make, {
                      part: "Triangle"
                    }), React.createElement(DayTimeline_HighlightTime.make, {})));
}

var make = React.memo(DayTimeline_Scale);

export {
  make ,
}
/* styles Not a pure module */
