

import * as React from "react";
import * as AppContext from "../../context/AppContext.mjs";
import * as AppQrLinkModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/AppQrLink.module.css";

var styles = AppQrLinkModuleCss;

function AppQrLink(Props) {
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var lang = AppContext.useLang();
  var qr = styles.qr + " " + (
    lang === "ru" ? styles.ru : styles.en
  );
  var className$1 = className === "" ? qr : className + " " + qr;
  return React.createElement("div", {
              className: className$1
            });
}

var make = AppQrLink;

export {
  make ,
}
/* styles Not a pure module */
