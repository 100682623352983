// extracted by mini-css-extract-plugin
export var additionalActions = "additional-actions-cTKtrAZZrPQuvw4cOeUd";
export var cancelButton = "cancel-button-MIsZ0WxOt1z0e2HUkazu";
export var confirmDeleteButton = "confirm-delete-button-HV_g_gFWiNZ23ZK1cAEF";
export var deleteButton = "delete-button-UvxFOucxtXhJ7YTAeH1S";
export var headline = "headline-AB7vi9ZlhJDOirJDaurb";
export var largeNotice = "large-notice-cs0b583O4NICFxjLKluj";
export var noticeList = "notice-list-K8q_ci1vh6LmWLL_4BwF";
export var popover = "popover-HQcn_aHimPxtY0pqmiM1";
export var popoverForm = "popover-form-DTStgxg0u42mNkjRO1PT";
export var spinner = "spinner-iajDPbHBKpLyzSoYnwP2";
export var submitButton = "submit-button-YEbG70H3sNjdbIUxXarY";