

import * as RCore from "../../libraries/RCore.mjs";
import * as Shared_Lib_Option from "./Shared_Lib_Option.mjs";
import * as Shared_Lib_Result from "./Shared_Lib_Result.mjs";

function async(onDone) {
  return {
          TAG: "Async",
          _0: onDone
        };
}

function map(io, f) {
  return {
          TAG: "Map",
          _0: f,
          _1: io
        };
}

function tap(io, f) {
  return {
          TAG: "Map",
          _0: (function (a) {
              f(a);
              return a;
            }),
          _1: io
        };
}

function delay(millis) {
  return {
          TAG: "Async",
          _0: (function (onDone) {
              setTimeout((function () {
                      onDone({
                            TAG: "Ok",
                            _0: undefined
                          });
                    }), millis);
            })
        };
}

function mapError(ioA, e1ToE2) {
  switch (ioA.TAG) {
    case "Pure" :
        return {
                TAG: "Pure",
                _0: ioA._0
              };
    case "Throw" :
        return {
                TAG: "Throw",
                _0: e1ToE2(ioA._0)
              };
    case "Suspend" :
        return {
                TAG: "Suspend",
                _0: ioA._0
              };
    case "SuspendIO" :
        var getIOA = ioA._0;
        return {
                TAG: "SuspendIO",
                _0: (function () {
                    return mapError(getIOA(), e1ToE2);
                  })
              };
    case "Async" :
        var onDoneA = ioA._0;
        return {
                TAG: "Async",
                _0: (function (onDone) {
                    onDoneA(function (resultA) {
                          onDone(Shared_Lib_Result.mapError(resultA, e1ToE2));
                        });
                  })
              };
    case "Map" :
        return {
                TAG: "Map",
                _0: ioA._0,
                _1: mapError(ioA._1, e1ToE2)
              };
    case "Apply" :
        return {
                TAG: "Apply",
                _0: mapError(ioA._0, e1ToE2),
                _1: mapError(ioA._1, e1ToE2)
              };
    case "FlatMap" :
        var rToIOA = ioA._0;
        return {
                TAG: "FlatMap",
                _0: (function (r) {
                    return mapError(rToIOA(r), e1ToE2);
                  }),
                _1: mapError(ioA._1, e1ToE2)
              };
    
  }
}

function withDelayBefore(io, millis) {
  return {
          TAG: "FlatMap",
          _0: (function () {
              return io;
            }),
          _1: delay(millis)
        };
}

function bimap(io, aToB, e1ToE2) {
  return mapError({
              TAG: "Map",
              _0: aToB,
              _1: io
            }, e1ToE2);
}

function suspend(getA) {
  return {
          TAG: "Suspend",
          _0: getA
        };
}

function debounce(immediateOpt, intervalMsOpt, io) {
  var immediate = immediateOpt !== undefined ? immediateOpt : false;
  var intervalMs = intervalMsOpt !== undefined ? intervalMsOpt : 150;
  var currentlyDebouncedIO = {
    contents: undefined
  };
  var startDebouncedIO = function () {
    var debouncedIO = delay(intervalMs);
    currentlyDebouncedIO.contents = Shared_Lib_Option.some(debouncedIO);
    return {
            TAG: "Map",
            _0: (function () {
                var shouldRunIO = RCore.$$Option.mapWithDefault(currentlyDebouncedIO.contents, false, (function (io) {
                        return io === debouncedIO;
                      }));
                if (shouldRunIO) {
                  currentlyDebouncedIO.contents = undefined;
                }
                return shouldRunIO;
              }),
            _1: debouncedIO
          };
  };
  return function (a) {
    var match = currentlyDebouncedIO.contents;
    var immediatelyRanIO = immediate && match === undefined ? Shared_Lib_Option.some({
            TAG: "SuspendIO",
            _0: (function () {
                return {
                        TAG: "Map",
                        _0: Shared_Lib_Option.some,
                        _1: io(a)
                      };
              })
          }) : undefined;
    var debouncedIO_0 = function (shouldRunIO) {
      if (shouldRunIO && RCore.$$Option.isNone(immediatelyRanIO)) {
        return {
                TAG: "Map",
                _0: Shared_Lib_Option.some,
                _1: io(a)
              };
      } else {
        return {
                TAG: "Pure",
                _0: undefined
              };
      }
    };
    var debouncedIO_1 = startDebouncedIO();
    var debouncedIO = {
      TAG: "FlatMap",
      _0: debouncedIO_0,
      _1: debouncedIO_1
    };
    return RCore.$$Option.getOr(immediatelyRanIO, debouncedIO);
  };
}

function unsafeRunAsync(_ioA, _onDone) {
  while(true) {
    var onDone = _onDone;
    var ioA = _ioA;
    switch (ioA.TAG) {
      case "Pure" :
          return onDone({
                      TAG: "Ok",
                      _0: ioA._0
                    });
      case "Throw" :
          return onDone({
                      TAG: "Error",
                      _0: ioA._0
                    });
      case "Suspend" :
          return onDone({
                      TAG: "Ok",
                      _0: ioA._0()
                    });
      case "SuspendIO" :
          _ioA = ioA._0();
          continue ;
      case "Async" :
          return ioA._0(onDone);
      case "Map" :
          var r0ToA = ioA._0;
          _onDone = (function(onDone,r0ToA){
          return function (result) {
            if (result.TAG === "Ok") {
              return onDone({
                          TAG: "Ok",
                          _0: r0ToA(result._0)
                        });
            } else {
              return onDone(result);
            }
          }
          }(onDone,r0ToA));
          _ioA = ioA._1;
          continue ;
      case "Apply" :
          return unsafeRunAsyncPar2(ioA._0, ioA._1, (function(onDone){
                    return function (resultR0ToA, resultR0) {
                      onDone(Shared_Lib_Result.apply(resultR0, resultR0ToA));
                    }
                    }(onDone)));
      case "FlatMap" :
          var r0ToIOA = ioA._0;
          _onDone = (function(onDone,r0ToIOA){
          return function (result) {
            if (result.TAG === "Ok") {
              return unsafeRunAsync(r0ToIOA(result._0), (function (result) {
                            if (result.TAG === "Ok") {
                              return onDone({
                                          TAG: "Ok",
                                          _0: result._0
                                        });
                            } else {
                              return onDone(result);
                            }
                          }));
            } else {
              return onDone(result);
            }
          }
          }(onDone,r0ToIOA));
          _ioA = ioA._1;
          continue ;
      
    }
  };
}

function unsafeRunAsyncPar2(ioA, ioB, onDone) {
  var refA = {
    contents: undefined
  };
  var refB = {
    contents: undefined
  };
  unsafeRunAsync(ioA, (function (resultA) {
          var resultB = refB.contents;
          if (resultB !== undefined) {
            return onDone(resultA, resultB);
          } else {
            refA.contents = resultA;
            return ;
          }
        }));
  unsafeRunAsync(ioB, (function (resultB) {
          var resultA = refA.contents;
          if (resultA !== undefined) {
            return onDone(resultA, resultB);
          } else {
            refB.contents = resultB;
            return ;
          }
        }));
}

export {
  async ,
  map ,
  tap ,
  withDelayBefore ,
  bimap ,
  suspend ,
  debounce ,
  unsafeRunAsync ,
  unsafeRunAsyncPar2 ,
}
/* No side effect */
