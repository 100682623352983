import React from 'react'
import classNames from 'classnames'

import { hasAddress, hasLocation } from 'planado/utils/map'
import * as styles from './styles.module.css'

const LocateButton = ({ job }) => {
  if (hasLocation(job)) {
    return (
      <div className={styles.locateJob}>
        <i className="fa fa-crosshairs" />
      </div>
    )
  } else {
    return <span />
  }
}

const JobAddress = ({ job, onClick, show = true }) => {
  if (hasAddress(job) && show) {
    const classes = classNames({
      'job-address': true,
      'has-location': hasLocation(job)
    })

    return (
      <div className={classes} onClick={onClick}>
        <LocateButton job={job} />
        {job.formattedAddress}
      </div>
    )
  } else {
    return <div />
  }
}

export default JobAddress
