

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Shared_Lib_IO from "../../shared/lib/Shared_Lib_IO.mjs";

function setItem(key, value) {
  return Shared_Lib_IO.suspend(function () {
              localStorage.setItem(key, JSON.stringify(value));
            });
}

function getItem(key) {
  return Shared_Lib_IO.map(Shared_Lib_IO.suspend(function () {
                  return Caml_option.nullable_to_opt(localStorage.getItem(key));
                }), (function (result) {
                if (result !== undefined) {
                  return JSON.parse(result);
                }
                
              }));
}

function removeItem(key) {
  return Shared_Lib_IO.suspend(function () {
              localStorage.removeItem(key);
            });
}

function clear() {
  return Shared_Lib_IO.suspend(function () {
              localStorage.removeItem();
            });
}

function key(k) {
  return Shared_Lib_IO.suspend(function () {
              return localStorage.removeItem(k);
            });
}

function length() {
  return Shared_Lib_IO.suspend(function () {
              return localStorage.length();
            });
}

export {
  setItem ,
  getItem ,
  removeItem ,
  clear ,
  key ,
  length ,
}
/* No side effect */
