import React from 'react'
import { fetchJSONC, sendJSONRequest } from 'planado/utils/network.js'
import { dictionaryPath, dictionariesPath } from 'planado/routes.js'
import {
  ConfirmRemovalDialogLink,
  ErrorRemovalModalLink,
} from 'planado/containers/admin/dictionaries_show'

export const CHANGE_DICTIONARY_NAME = 'CHANGE_DICTIONARY_NAME'

export const REMOVE_VALUE = 'REMOVE_DICTIONARY'
export const CHANGE_VALUE_NAME = 'CHANGE_VALUE_NAME'

export const ADD_VALUE = 'ADD_VALUE'

export const REORDER_VALUES = 'REORDER_VALUES'

export const SHOW_POPOVER = 'SHOW_POPOVER'
export const HIDE_POPOVER = 'HIDE_POPOVER'

export const ADD_NAME_ERROR = 'ADD_NAME_ERROR'
export const ADD_VALUE_ERROR = 'ADD_VALUE_ERROR'

export const REMOVABLE_REQUEST = 'REMOVABLE_REQUEST'
export const REMOVABLE_FAIL = 'REMOVABLE_FAIL'

export const SET_TEMPLATES = 'SET_TEMPLATES'

export const DISABLE_SUBMIT_BUTTON = 'DISABLE_SUBMIT_BUTTON'

export const SEND_FORM_REQUEST = 'SEND_FORM_REQUEST'
export const SEND_FORM_SUCCESS = 'SEND_FORM_SUCCESS'
export const SEND_FORM_FAIL = 'SEND_FORM_FAIL'

export const SET_DICTIONARY = 'SET_DICTIONARY'

export const disableSubmitButton = () => ({
  type: DISABLE_SUBMIT_BUTTON,
})

export const changeDictionaryName = (name) => ({
  type: CHANGE_DICTIONARY_NAME,
  name,
})

export const removeValue = (idx, isNew) => ({
  type: REMOVE_VALUE,
  idx,
  isNew
})

export const changeValueName = (idx, name) => ({
  type: CHANGE_VALUE_NAME,
  idx,
  name,
})

export const addValue = () => ({
  type: ADD_VALUE,
})

export const reorderValues = (values) => ({
  type: REORDER_VALUES,
  value: {
    values: values
  }
})

export const confirmRemoval =
  (node, dom, buttonId, place, modal) => (dispatch, getState) => {
    const { popovers } = getState()

    if (
      popovers.length === 0 ||
      popovers[popovers.length - 1].targetId !== buttonId
    ) {
      setTimeout(
        () =>
          dispatch({ type: SHOW_POPOVER, dom, node, buttonId, place, modal }),
        0
      )
    }
  }

export const hidePopover = () => ({
  type: HIDE_POPOVER,
})

export const addNameError = (errors) => ({
  type: ADD_NAME_ERROR,
  errors,
})

export const addValueError = (idx, errors) => ({
  type: ADD_VALUE_ERROR,
  idx,
  errors,
})

export const removable =
  (ctx, uuid, buttonId, deleteDictionary) => (dispatch, getState) =>
    fetchJSONC(dictionaryPath(uuid), ctx).then(({ templates }) => {
      if (templates.length === 0) {
        confirmRemoval(
          <ConfirmRemovalDialogLink deleteDictionary={deleteDictionary} />,
          ctx.dom,
          buttonId,
          'top',
          false
        )(dispatch, getState)
      } else {
        dispatch({
          type: SET_TEMPLATES,
          templates,
        })

        confirmRemoval(
          <ErrorRemovalModalLink />,
          ctx.dom,
          buttonId,
          'top',
          true
        )(dispatch, getState)
      }
    })

export const sendForm =
  (payload, isNew, ctx, uuid = null, onSubmitDictionary) =>
    async (dispatch, _getState) => {
      const url = isNew ? dictionariesPath : dictionaryPath(uuid)

      const method = isNew ? 'POST' : 'PATCH'

      try {
        const { dictionary } = await sendJSONRequest(url, ctx, {
          body: payload,
          method,
        })

        dispatch({
          type: SET_DICTIONARY,
          dictionary,
        })

        onSubmitDictionary()
      } catch (res) {
        if (res.status === 422) {
          const { errors } = await res.json()

          if (errors.dictionary && errors.dictionary.name) {
            dispatch(
              addNameError([
                ctx.t('js.admin.dictionaries.error.name_already_exists'),
              ])
            )
          }

          if (errors.dictionary && errors.dictionary.values) {
            Object.entries(errors.dictionary.values).forEach(([i, _]) =>
              dispatch(
                addValueError(
                  parseInt(i),
                  [ctx.t('js.admin.dictionaries.error.value_already_exists')]
                )
              )
            )
          }
        }
      }
    }
