// extracted by mini-css-extract-plugin
export var btn = "btn-IkKSLydO4ilJnSAYpCMj";
export var btnPrimary = "btn-primary-jqOmuC4wXR1Ox4Kpf5G1";
export var buttons = "buttons-EFj8F9DjgWpcTDHHMyRX";
export var cancelButton = "cancel-button-qewMbquEnr90Btf0h7yA";
export var deleteButtons = "delete-buttons-fIfcusIXZejCB7RJEZG4";
export var deletePopover = "delete-popover-hZzXztaS7FuFxhs2ReMw";
export var footer = "footer-mccLg5PuZPW8RXMmfKsu";
export var footerCreate = "footer-create-JYOfqVfv_XJDk96jWTUK";
export var info = "info-_m1ZB9DFinoScFgMCl_7";
export var infoText = "info-text-jOWoR88vlSV3_sXt1U4q";
export var popover = "popover-_NHKG2HyXzeOZbrH_W4S";
export var popoverContent = "popover-content-qTZ3MhwBX0QmtVii6Wym";
export var popup = "popup-WUsfjZ_SVWvCcHcasFWp";
export var submitButton = "submit-button-Pf9nqy4GcnOtUxlZ_zm6";