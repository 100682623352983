

import * as El from "../../../../../../libraries/El.mjs";
import * as Uuid from "../../../../../../bindings/uuid/Uuid.mjs";
import * as Hooks from "../../../../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../types/Types.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as UI_Icon from "../../../../../../ui/UI_Icon.mjs";
import * as Optional from "../../../../../common/Optional.mjs";
import * as UI_Button from "../../../../../../ui/UI_Button.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ServiceItem from "../../../../../common/Catalog/ServiceItem.mjs";
import * as JobEdit_JobType from "../../../lib/types/JobEdit_JobType.mjs";
import * as JobForm_ServicesSelect from "./JobForm_ServicesSelect.mjs";
import * as JobForm_ServicesModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/edit/form/components/services/JobForm_Services.module.css";

var label$p = Utils.Translations.tr("js.jobs.labels.services");

var addService$p = Utils.Translations.tr("js.jobs.services.add_service");

var orderService$p = Utils.Translations.tr("js.jobs.services.order_service");

var ordered$p = Utils.Translations.tr("js.jobs.services.ordered");

var required$p = Utils.Translations.tr("js.jobs.services.required");

var provided$p = Utils.Translations.tr("js.jobs.services.provided");

var styles = JobForm_ServicesModuleCss;

function make(status) {
  switch (status) {
    case "Finished" :
        return "Provide";
    case "Started" :
    case "Suspended" :
    case "Canceled" :
        return "None";
    default:
      return "Order";
  }
}

function JobForm_Services$OrderedServiceItem(Props) {
  var onRemove = Props.onRemove;
  var service = Props.service;
  var required = Props.required;
  var ctx = Props.ctx;
  var disabled = Props.disabled;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: styles.statusContainer
                }, React.createElement("div", {
                      className: styles.status
                    }, ordered$p(ctx)), React.createElement(Optional.make, {
                      show: required,
                      children: React.createElement("div", {
                            className: styles.status
                          }, required$p(ctx))
                    })), React.createElement(ServiceItem.make, {
                  ctx: ctx,
                  name: service.name,
                  description: service.description,
                  price: service.priceDefault,
                  currency: service.currency,
                  archived: service.archived,
                  onRemove: onRemove,
                  disabled: disabled,
                  key: Uuid.toString(service.uuid)
                }));
}

function JobForm_Services$ProvidedServiceItem(Props) {
  var onRemove = Props.onRemove;
  var service = Props.service;
  var ordered = Props.ordered;
  var required = Props.required;
  var ctx = Props.ctx;
  var disabled = Props.disabled;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: styles.statusContainer
                }, React.createElement(Optional.make, {
                      show: ordered,
                      children: React.createElement("div", {
                            className: styles.status
                          }, ordered$p(ctx))
                    }), React.createElement(Optional.make, {
                      show: required,
                      children: React.createElement("div", {
                            className: styles.status
                          }, required$p(ctx))
                    }), React.createElement("div", {
                      className: styles.status
                    }, provided$p(ctx))), React.createElement(ServiceItem.make, {
                  ctx: ctx,
                  name: service.name,
                  description: service.description,
                  price: service.price,
                  currency: service.currency,
                  archived: service.archived,
                  onRemove: onRemove,
                  disabled: disabled,
                  key: Uuid.toString(service.uuid)
                }));
}

function JobForm_Services$AddService(Props) {
  var ctx = Props.ctx;
  var availableServices = Props.availableServices;
  var addService = Props.addService;
  var mode = Props.mode;
  var match = React.useState(function () {
        return false;
      });
  var setOpened = match[1];
  var elementRef = Types.ReactRef.use();
  Hooks.useOnClickOutside(undefined, elementRef, (function () {
          setOpened(function (param) {
                return false;
              });
        }));
  var openSelect = function (evt) {
    evt.stopPropagation();
    setOpened(function (param) {
          return true;
        });
  };
  if (match[0]) {
    return React.createElement("div", {
                ref: elementRef
              }, React.createElement(JobForm_ServicesSelect.make, {
                    onSelect: addService,
                    availableServices: availableServices
                  }));
  }
  var tmp;
  switch (mode) {
    case "Order" :
        tmp = orderService$p(ctx);
        break;
    case "Provide" :
        tmp = addService$p(ctx);
        break;
    case "None" :
        tmp = null;
        break;
    
  }
  return React.createElement(UI_Button.make, {
              flavor: "link",
              className: styles.add,
              onClick: openSelect,
              children: null
            }, React.createElement(UI_Icon.make, {
                  icon: "plus"
                }), El.space, tmp);
}

function JobForm_Services(Props) {
  var ctx = Props.ctx;
  var availableServices = Props.availableServices;
  var orderedServices = Props.orderedServices;
  var providedServices = Props.providedServices;
  var setProvidedServices = Props.setProvidedServices;
  var setOrderedServices = Props.setOrderedServices;
  var status = Props.status;
  var mode = make(status);
  var toProvide = React.useMemo((function () {
          return RCore.$$Array.keep(availableServices, (function (s) {
                        if (providedServices.some(function (p) {
                                return Uuid.equal(p.uuid, s.uuid);
                              })) {
                          return false;
                        } else if (orderedServices.some(function (o) {
                                return Uuid.equal(o.uuid, s.uuid);
                              })) {
                          return mode === "Provide";
                        } else {
                          return true;
                        }
                      }));
        }), [
        availableServices,
        providedServices,
        orderedServices
      ]);
  React.useEffect((function () {
          switch (mode) {
            case "Order" :
            case "Provide" :
                break;
            case "None" :
                var notProvided = RCore.$$Array.keep(orderedServices, (function (o) {
                        return !providedServices.some(function (a) {
                                    return Uuid.equal(a.uuid, o.uuid);
                                  });
                      }));
                var picked = RCore.$$Array.keep(availableServices, (function (s) {
                          return notProvided.some(function (o) {
                                      return Uuid.equal(o.uuid, s.uuid);
                                    });
                        })).map(function (a) {
                      return JobEdit_JobType.ProvidedService.fromAvailableService(a);
                    });
                setProvidedServices(Belt_Array.concatMany([
                          providedServices,
                          picked
                        ]));
                break;
            
          }
        }), []);
  var ordered = React.useMemo((function () {
          switch (mode) {
            case "Order" :
                return RCore.$$Array.keep(availableServices, (function (s) {
                                return orderedServices.some(function (o) {
                                            return Uuid.equal(o.uuid, s.uuid);
                                          });
                              })).map(function (a) {
                            return JobEdit_JobType.AvailableService.toServiceItem(a);
                          });
            case "Provide" :
            case "None" :
                return [];
            
          }
        }), [
        availableServices,
        orderedServices
      ]);
  var addService = function (service) {
    switch (mode) {
      case "Order" :
          var ordered = JobEdit_JobType.OrderedService.fromAvailableService(service);
          return setOrderedServices(Belt_Array.concatMany([
                          orderedServices,
                          [ordered]
                        ]));
      case "Provide" :
          var provided = JobEdit_JobType.ProvidedService.fromService(service);
          return setProvidedServices(Belt_Array.concatMany([
                          providedServices,
                          [provided]
                        ]));
      case "None" :
          return ;
      
    }
  };
  var isRequired = function (uuid) {
    return RCore.$$Option.getOr(RCore.$$Option.map(RCore.$$Array.getBy(orderedServices, (function (s) {
                          return Uuid.equal(s.uuid, uuid);
                        })), (function (s) {
                      return s.required;
                    })), false);
  };
  var isOrdered = function (uuid) {
    return orderedServices.some(function (s) {
                return Uuid.equal(s.uuid, uuid);
              });
  };
  return React.createElement("div", {
              className: styles.container
            }, React.createElement("div", {
                  className: styles.header
                }, label$p(ctx)), React.createElement(Optional.make, {
                  show: toProvide.length > 0 && mode !== "None",
                  children: React.createElement(JobForm_Services$AddService, {
                        ctx: ctx,
                        availableServices: toProvide,
                        addService: addService,
                        mode: mode
                      })
                }), React.createElement("div", {
                  className: styles.providedServices
                }, ordered.map(function (service) {
                      var required = isRequired(service.uuid);
                      return React.createElement(JobForm_Services$OrderedServiceItem, {
                                  onRemove: (function () {
                                      var newOrderedServices = RCore.$$Array.keep(orderedServices, (function (s) {
                                              return !Uuid.equal(s.uuid, service.uuid);
                                            }));
                                      setOrderedServices(newOrderedServices);
                                    }),
                                  service: service,
                                  required: required,
                                  ctx: ctx,
                                  disabled: isRequired(service.uuid),
                                  key: Uuid.toString(service.uuid)
                                });
                    }), providedServices.map(function (service) {
                      return React.createElement(JobForm_Services$ProvidedServiceItem, {
                                  onRemove: (function () {
                                      var newProvidedServices = RCore.$$Array.keep(providedServices, (function (s) {
                                              return !Uuid.equal(s.uuid, service.uuid);
                                            }));
                                      setProvidedServices(newProvidedServices);
                                    }),
                                  service: service,
                                  ordered: isOrdered(service.uuid),
                                  required: isRequired(service.uuid),
                                  ctx: ctx,
                                  disabled: mode === "None",
                                  key: Uuid.toString(service.uuid)
                                });
                    })));
}

var make$1 = JobForm_Services;

export {
  make$1 as make,
}
/* label' Not a pure module */
