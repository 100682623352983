import { trigger } from 'planado/events'
import { reducer as mapReducer } from 'planado/reducers/map'

const initialAction = '@@'

class MapStore {
  get historyWorker() {
    return this.workers[this.historyWorkerUuid]
  }

  get currentBlock() {
    if (this.currentBlockId === null) {
      return null
    } else {
      return (
        this.locationBlocks.find(({ id }) => id === this.currentBlockId) || null
      )
    }
  }

  get focusedJob() {
    if (this.focusedJobUuid === null) {
      return null
    } else {
      return this.jobs[this.focusedJobUuid] || null
    }
  }

  get focusedWorker() {
    if (this.focusedWorkerUuid === null) {
      return null
    } else {
      return this.workers[this.focusedWorkerUuid]
    }
  }
}

const reducerFor = ({ type }) => {
  if (type.substring(0, 4) === 'MAP_') {
    return mapReducer
  } else if (type.substring(0, 2) === initialAction) {
    return s => s
  } else {
    throw `Can't find a reducer for ${type}`
  }
}

const reducer = (state, action) => {
  const nextVersion = state.version + 1
  const nextState = reducerFor(action)(state, action, nextVersion)
  const events = trigger(state.events, action.events, nextVersion)

  return Object.assign(new MapStore(), {
    ...nextState,
    version: nextVersion,
    events
  })
}

export default reducer
