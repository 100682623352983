// extracted by mini-css-extract-plugin
export var dark = "dark-KfZT2jugZXKCNTW0Ypde";
export var fileName = "file-name-vla4LYiXjlqifLH8FV9P";
export var fileNameWrapper = "file-name-wrapper-R81Q0ECGPCAeiKNxRi9b";
export var fileWrapper = "file-wrapper-EeVMag0dFwZtG1GN_gcd";
export var iconTrash = "icon-trash-TF6P9i4s81s0Vny8fiq_";
export var image = "image-vfgS1ONUP_8rk_HPtJ8Y";
export var imageButtons = "image-buttons-rOfOqOS3w_Sa3SrkIXOD";
export var imageContent = "image-content-QAceDawW2v9XQSpDgaSV";
export var imageContentText = "image-content-text-PWHvT9msxmjIzHPpugY_";
export var imageHover = "image-hover-wxtW8rbYswHY0LVZ8NHU";
export var imageLight = "image-light-HyaA0Bb8Fmna6r_CX2Uf";
export var imageWrapper = "image-wrapper-TbxJh0EwCAkJNQTaShDe";
export var notUploaded = "not-uploaded-ihGveqyUdEGYPSPpdrK3";
export var notUploadedFile = "not-uploaded-file-zABh8YTkjajODj6e11Tu";