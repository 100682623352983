import React from 'react'
import { addJob, removeJob } from 'planado/map_commands'
import { filterJobs, hasLocation } from 'planado/utils/map'

const Job = ({ engineChannel, job }) => {
  React.useEffect(() => {
    if (hasLocation(job)) {
      engineChannel.push(addJob(job))

      return () => {
        engineChannel.push(removeJob(job))
      }
    }
  }, [job]);

  return null
}

const CurrentJobs = ({ jobs, statusFilter, engineChannel }) =>
  filterJobs(jobs, j => statusFilter[j.status]).map(j => (
    <Job key={j.uuid} job={j} engineChannel={engineChannel} />
  ))

export default CurrentJobs
