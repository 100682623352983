

import * as Types from "../../types/Types.mjs";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import Datetime from "planado/components/input/datetime";

function DateTimePicker(Props) {
  var id = Props.id;
  var label = Props.label;
  var dateTime = Props.dateTime;
  var onChange = Props.onChange;
  var calendarPlacementOpt = Props.calendarPlacement;
  var disabledOpt = Props.disabled;
  var outlineIconsOpt = Props.outlineIcons;
  var wrapperClass = Props.wrapperClass;
  var dateClass = Props.dateClass;
  var timeClass = Props.timeClass;
  var businessHours = Props.businessHours;
  var calendarPlacement = calendarPlacementOpt !== undefined ? calendarPlacementOpt : "bottom";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var outlineIcons = outlineIconsOpt !== undefined ? outlineIconsOpt : false;
  var onChange$1 = function (v) {
    var dateTime = Types.Time.DateTime.fromJs(v);
    return onChange(dateTime);
  };
  var tmp = {
    id: id,
    label: label,
    dateTime: Types.Time.DateTime.toJs(dateTime),
    onChange: onChange$1,
    calendarPlacement: calendarPlacement,
    disabled: disabled,
    outlineIcons: outlineIcons
  };
  if (wrapperClass !== undefined) {
    tmp.wrapperClass = wrapperClass;
  }
  if (dateClass !== undefined) {
    tmp.dateClass = dateClass;
  }
  if (timeClass !== undefined) {
    tmp.timeClass = timeClass;
  }
  if (businessHours !== undefined) {
    tmp.businessHours = Caml_option.valFromOption(businessHours);
  }
  return React.createElement(Datetime, tmp);
}

var make = DateTimePicker;

export {
  make ,
}
/* Types Not a pure module */
