

import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Popover from "../../../common/Popover/Popover.mjs";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as UserDeletePopoverModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/user/show/UserDeletePopover.module.css";

var deletePrompt$p = Utils.Translations.tr("js.admin.users.form.popovers.confirm_delete.prompt");

var cancel$p = Utils.Translations.tr("js.admin.users.form.popovers.confirm_delete.cancel");

var confirm$p = Utils.Translations.tr("js.admin.users.form.popovers.confirm_delete.delete");

var styles = UserDeletePopoverModuleCss;

function UserDeletePopover(Props) {
  var buttonId = Props.buttonId;
  var onClose = Props.onClose;
  var onDelete = Props.onDelete;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  return React.createElement(Popover.make, {
              footer: match.footer,
              buttonId: buttonId,
              position: "top",
              className: styles.popover,
              children: React.createElement("div", {
                    className: "form-footer__delete-popup"
                  }, React.createElement("h5", undefined, deletePrompt$p(ctx)), React.createElement("div", undefined, React.createElement("button", {
                            className: "btn btn-default",
                            onClick: onClose
                          }, cancel$p(ctx)), React.createElement("button", {
                            className: "btn btn-danger",
                            onClick: onDelete
                          }, confirm$p(ctx))))
            });
}

var make = UserDeletePopover;

export {
  make ,
}
/* deletePrompt' Not a pure module */
