

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Hooks from "../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../../common/Optional.mjs";
import * as FormInput from "../../inputs/FormInput.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DecimalInput from "../../inputs/DecimalInput.mjs";
import * as IntegerInput from "../../inputs/IntegerInput.mjs";
import * as CurrencyInput from "../../inputs/CurrencyInput.mjs";
import * as JobEdit_JobType from "../../job/edit/lib/types/JobEdit_JobType.mjs";
import * as JobForm_Carousel from "../../job/edit/form/hooks/JobForm_Carousel.mjs";
import * as JobAttachmentInput from "../../file/job_attachment_input/JobAttachmentInput.mjs";
import * as JobForm_Carousel_Utils from "../../job/edit/form/hooks/JobForm_Carousel_Utils.mjs";
import * as JobForm_ReportFieldCheckbox from "../../job/edit/form/components/reports/report_field/components/checkbox/JobForm_ReportFieldCheckbox.mjs";
import * as JobForm_ReportFieldDictionary from "../../job/edit/form/components/reports/report_field/components/dictionary/JobForm_ReportFieldDictionary.mjs";
import * as UserCustomFieldsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/user/UserCustomFields.module.css";

var styles = UserCustomFieldsModuleCss;

function UserCustomFields$FieldWrapper(Props) {
  var fieldType = Props.fieldType;
  var children = Props.children;
  return React.createElement(Optional.make, {
              show: Caml_obj.notequal(children, null),
              children: React.createElement("div", {
                    className: styles.inputRow
                  }, React.createElement("div", {
                        className: styles.inputLabel
                      }, fieldType.name), children)
            });
}

var FieldWrapper = {
  make: UserCustomFields$FieldWrapper
};

function UserCustomFields$FieldBlock(Props) {
  var fieldValue = Props.fieldValue;
  var dictionaryValues = Props.dictionaryValues;
  var showCarouselImage = Props.showCarouselImage;
  var updateFieldValue = Props.updateFieldValue;
  var wire = Props.wire;
  var id = Hooks.useId(undefined);
  switch (fieldValue.dataType) {
    case "Integer" :
        if (fieldValue.fieldType === "Input") {
          return React.createElement(IntegerInput.make, {
                      id: id,
                      value: RCore.$$Option.getOr(fieldValue.integerValue, ""),
                      onValueChange: (function (newValue) {
                          updateFieldValue({
                                typeUuid: fieldValue.typeUuid,
                                dataType: fieldValue.dataType,
                                fieldType: fieldValue.fieldType,
                                booleanValue: fieldValue.booleanValue,
                                stringValue: fieldValue.stringValue,
                                decimalValue: fieldValue.decimalValue,
                                integerValue: newValue === "" ? undefined : newValue,
                                dictionaryUuid: fieldValue.dictionaryUuid,
                                dictionaryValueUuid: fieldValue.dictionaryValueUuid,
                                currency: fieldValue.currency,
                                currencyValue: fieldValue.currencyValue,
                                useCurrencyFractionalUnit: fieldValue.useCurrencyFractionalUnit,
                                attachmentValues: fieldValue.attachmentValues
                              });
                        }),
                      wrapperClass: styles.input
                    });
        }
        console.log(fieldValue);
        return null;
    case "Decimal" :
        if (fieldValue.fieldType === "Input") {
          return React.createElement(DecimalInput.make, {
                      id: id,
                      value: RCore.$$Option.getOr(fieldValue.decimalValue, ""),
                      onValueChange: (function (newValue) {
                          updateFieldValue({
                                typeUuid: fieldValue.typeUuid,
                                dataType: fieldValue.dataType,
                                fieldType: fieldValue.fieldType,
                                booleanValue: fieldValue.booleanValue,
                                stringValue: fieldValue.stringValue,
                                decimalValue: newValue === "" ? undefined : newValue,
                                integerValue: fieldValue.integerValue,
                                dictionaryUuid: fieldValue.dictionaryUuid,
                                dictionaryValueUuid: fieldValue.dictionaryValueUuid,
                                currency: fieldValue.currency,
                                currencyValue: fieldValue.currencyValue,
                                useCurrencyFractionalUnit: fieldValue.useCurrencyFractionalUnit,
                                attachmentValues: fieldValue.attachmentValues
                              });
                        }),
                      disabled: false,
                      wire: wire,
                      wrapperClass: styles.input
                    });
        }
        console.log(fieldValue);
        return null;
    case "Attachment" :
        return React.createElement(JobAttachmentInput.make, {
                    files: RCore.$$Option.getOr(fieldValue.attachmentValues, []),
                    addFile: (function (file) {
                        updateFieldValue({
                              typeUuid: fieldValue.typeUuid,
                              dataType: fieldValue.dataType,
                              fieldType: fieldValue.fieldType,
                              booleanValue: fieldValue.booleanValue,
                              stringValue: fieldValue.stringValue,
                              decimalValue: fieldValue.decimalValue,
                              integerValue: fieldValue.integerValue,
                              dictionaryUuid: fieldValue.dictionaryUuid,
                              dictionaryValueUuid: fieldValue.dictionaryValueUuid,
                              currency: fieldValue.currency,
                              currencyValue: fieldValue.currencyValue,
                              useCurrencyFractionalUnit: fieldValue.useCurrencyFractionalUnit,
                              attachmentValues: Belt_Array.concatMany([
                                    RCore.$$Option.getOr(fieldValue.attachmentValues, []),
                                    [file]
                                  ])
                            });
                      }),
                    removeFile: (function (uuid) {
                        var newAttachments = RCore.$$Array.keep(RCore.$$Option.getOr(fieldValue.attachmentValues, []), (function (a) {
                                return !Uuid.equal(a.uuid, uuid);
                              }));
                        updateFieldValue({
                              typeUuid: fieldValue.typeUuid,
                              dataType: fieldValue.dataType,
                              fieldType: fieldValue.fieldType,
                              booleanValue: fieldValue.booleanValue,
                              stringValue: fieldValue.stringValue,
                              decimalValue: fieldValue.decimalValue,
                              integerValue: fieldValue.integerValue,
                              dictionaryUuid: fieldValue.dictionaryUuid,
                              dictionaryValueUuid: fieldValue.dictionaryValueUuid,
                              currency: fieldValue.currency,
                              currencyValue: fieldValue.currencyValue,
                              useCurrencyFractionalUnit: fieldValue.useCurrencyFractionalUnit,
                              attachmentValues: Caml_obj.equal(newAttachments, []) ? undefined : newAttachments
                            });
                      }),
                    setLock: (function (param) {
                        
                      }),
                    readOnly: false,
                    maxFilesInField: 10,
                    wrapperClass: styles.input,
                    showCarouselImage: showCarouselImage
                  });
    case "Boolean" :
        if (fieldValue.fieldType === "Checkbox") {
          return React.createElement(JobForm_ReportFieldCheckbox.make, {
                      value: fieldValue.booleanValue,
                      id: id,
                      onValueChange: (function (newValue) {
                          updateFieldValue({
                                typeUuid: fieldValue.typeUuid,
                                dataType: fieldValue.dataType,
                                fieldType: fieldValue.fieldType,
                                booleanValue: newValue,
                                stringValue: fieldValue.stringValue,
                                decimalValue: fieldValue.decimalValue,
                                integerValue: fieldValue.integerValue,
                                dictionaryUuid: fieldValue.dictionaryUuid,
                                dictionaryValueUuid: fieldValue.dictionaryValueUuid,
                                currency: fieldValue.currency,
                                currencyValue: fieldValue.currencyValue,
                                useCurrencyFractionalUnit: fieldValue.useCurrencyFractionalUnit,
                                attachmentValues: fieldValue.attachmentValues
                              });
                        }),
                      ctx: wire.ctx,
                      readOnly: false,
                      wrapperClass: styles.input
                    });
        }
        console.log(fieldValue);
        return null;
    case "Currency" :
        if (fieldValue.fieldType === "Currency") {
          var currency = fieldValue.currency;
          if (currency !== undefined) {
            var useCurrencyFractionalUnit = fieldValue.useCurrencyFractionalUnit;
            if (useCurrencyFractionalUnit !== undefined) {
              return React.createElement(CurrencyInput.make, {
                          wire: wire,
                          currency: currency,
                          useCurrencyFractionalUnit: useCurrencyFractionalUnit,
                          value: fieldValue.currencyValue,
                          onValueChange: (function (newValue) {
                              updateFieldValue({
                                    typeUuid: fieldValue.typeUuid,
                                    dataType: fieldValue.dataType,
                                    fieldType: fieldValue.fieldType,
                                    booleanValue: fieldValue.booleanValue,
                                    stringValue: fieldValue.stringValue,
                                    decimalValue: fieldValue.decimalValue,
                                    integerValue: fieldValue.integerValue,
                                    dictionaryUuid: fieldValue.dictionaryUuid,
                                    dictionaryValueUuid: fieldValue.dictionaryValueUuid,
                                    currency: fieldValue.currency,
                                    currencyValue: newValue === "" ? undefined : newValue,
                                    useCurrencyFractionalUnit: fieldValue.useCurrencyFractionalUnit,
                                    attachmentValues: fieldValue.attachmentValues
                                  });
                            }),
                          disabled: false,
                          classNameWrapper: styles.input,
                          backendErrors: Core__List.fromArray([])
                        });
            } else {
              console.log(fieldValue);
              return null;
            }
          }
          console.log(fieldValue);
          return null;
        }
        console.log(fieldValue);
        return null;
    case "String" :
        if (fieldValue.fieldType === "Input") {
          return React.createElement(FormInput.make, {
                      _type: "textarea",
                      value: RCore.$$Option.getOr(fieldValue.stringValue, ""),
                      onValueChange: (function (newValue) {
                          updateFieldValue({
                                typeUuid: fieldValue.typeUuid,
                                dataType: fieldValue.dataType,
                                fieldType: fieldValue.fieldType,
                                booleanValue: fieldValue.booleanValue,
                                stringValue: newValue === "" ? undefined : newValue,
                                decimalValue: fieldValue.decimalValue,
                                integerValue: fieldValue.integerValue,
                                dictionaryUuid: fieldValue.dictionaryUuid,
                                dictionaryValueUuid: fieldValue.dictionaryValueUuid,
                                currency: fieldValue.currency,
                                currencyValue: fieldValue.currencyValue,
                                useCurrencyFractionalUnit: fieldValue.useCurrencyFractionalUnit,
                                attachmentValues: fieldValue.attachmentValues
                              });
                        }),
                      id: id,
                      maxLength: 5000,
                      wrapperClass: Js_dict.fromArray([[
                              styles.input,
                              true
                            ]]),
                      autoFocus: true
                    });
        }
        console.log(fieldValue);
        return null;
    case "Dictionary" :
        if (fieldValue.fieldType === "Dictionary") {
          return React.createElement(JobForm_ReportFieldDictionary.make, {
                      id: id,
                      value: fieldValue.dictionaryValueUuid,
                      dictionaryValues: dictionaryValues,
                      onValueChange: (function (newValue) {
                          updateFieldValue({
                                typeUuid: fieldValue.typeUuid,
                                dataType: fieldValue.dataType,
                                fieldType: fieldValue.fieldType,
                                booleanValue: fieldValue.booleanValue,
                                stringValue: fieldValue.stringValue,
                                decimalValue: fieldValue.decimalValue,
                                integerValue: fieldValue.integerValue,
                                dictionaryUuid: fieldValue.dictionaryUuid,
                                dictionaryValueUuid: newValue,
                                currency: fieldValue.currency,
                                currencyValue: fieldValue.currencyValue,
                                useCurrencyFractionalUnit: fieldValue.useCurrencyFractionalUnit,
                                attachmentValues: fieldValue.attachmentValues
                              });
                        }),
                      disabled: false,
                      wrapperClass: styles.input,
                      ctx: wire.ctx
                    });
        }
        console.log(fieldValue);
        return null;
    
  }
}

var FieldBlock = {
  make: UserCustomFields$FieldBlock
};

function UserCustomFields(Props) {
  var dictionaries = Props.dictionaries;
  var fieldTypes = Props.fieldTypes;
  var initialValues = Props.fieldValues;
  var onChange = Props.onChange;
  var wire = AppContext.useWire();
  var emptyField = function (fieldType) {
    return {
            typeUuid: fieldType.uuid,
            dataType: fieldType.dataType,
            fieldType: fieldType.fieldType,
            booleanValue: undefined,
            stringValue: undefined,
            decimalValue: undefined,
            integerValue: undefined,
            dictionaryUuid: fieldType.dictionaryUuid,
            dictionaryValueUuid: undefined,
            currency: fieldType.currency,
            currencyValue: undefined,
            useCurrencyFractionalUnit: fieldType.useCurrencyFractionalUnit,
            attachmentValues: undefined
          };
  };
  var match = React.useState(function () {
        return fieldTypes.map(function (fieldType) {
                    var fieldValue = RCore.$$Array.getBy(initialValues, (function (v) {
                            return Uuid.equal(fieldType.uuid, v.typeUuid);
                          }));
                    if (fieldValue !== undefined) {
                      return {
                              fieldValue: fieldValue,
                              fieldType: fieldType
                            };
                    } else {
                      return {
                              fieldValue: emptyField(fieldType),
                              fieldType: fieldType
                            };
                    }
                  });
      });
  var setFieldsWithValues = match[1];
  var fieldsWithValues = match[0];
  var carouselImages = JobForm_Carousel_Utils.removeDuplicates(fieldsWithValues.flatMap(function (f) {
            var match = f.fieldValue;
            if (match.dataType === "Attachment") {
              return JobForm_Carousel_Utils.extractCarouselImages(f.fieldType.name, RCore.$$Option.getOr(match.attachmentValues, []));
            } else {
              return [];
            }
          }));
  var match$1 = JobForm_Carousel.use(carouselImages);
  var showCarouselImage = match$1.showCarouselImage;
  React.useEffect((function () {
          onChange(fieldsWithValues.map(function (f) {
                    return f.fieldValue;
                  }));
        }), [fieldsWithValues]);
  return React.createElement(React.Fragment, undefined, fieldsWithValues.map(function (param, index) {
                  var fieldType = param.fieldType;
                  var fieldValue = param.fieldValue;
                  var match = fieldValue.dataType;
                  var match$1 = fieldValue.dictionaryUuid;
                  var dictionaryValues;
                  if (match === "Dictionary") {
                    if (match$1 !== undefined) {
                      var uuid = Caml_option.valFromOption(match$1);
                      dictionaryValues = RCore.$$Option.mapOr(RCore.$$Array.getBy(dictionaries, (function (d) {
                                  return Uuid.equal(d.uuid, uuid);
                                })), [], (function (d) {
                              return d.values.map(function (v) {
                                          return JobEdit_JobType.Field.DictionaryValue.make(v.uuid, v.name);
                                        });
                            }));
                    } else {
                      dictionaryValues = [];
                    }
                  } else {
                    dictionaryValues = [];
                  }
                  return React.createElement(UserCustomFields$FieldWrapper, {
                              fieldType: fieldType,
                              children: React.createElement(UserCustomFields$FieldBlock, {
                                    fieldValue: fieldValue,
                                    dictionaryValues: dictionaryValues,
                                    showCarouselImage: showCarouselImage,
                                    updateFieldValue: (function (fieldValue) {
                                        var newFieldWithValue = {
                                          fieldValue: fieldValue,
                                          fieldType: fieldType
                                        };
                                        setFieldsWithValues(function (current) {
                                              return current.with(index, newFieldWithValue);
                                            });
                                      }),
                                    wire: wire,
                                    key: Uuid.toString(fieldValue.typeUuid)
                                  })
                            });
                }), match$1.carousel);
}

var maxCustomFields = 100;

var make = UserCustomFields;

export {
  styles ,
  maxCustomFields ,
  FieldWrapper ,
  FieldBlock ,
  make ,
}
/* styles Not a pure module */
