// extracted by mini-css-extract-plugin
export var addField = "add-field-NhBadcgozs0aTGkWCIyW";
export var addStage = "add-stage-hkfnHkJLX29LBnHnsxRU";
export var back = "back-IHRy8glC7LXKVXvYeHfF";
export var caption = "caption-wGMHR7k4Z5bqwtTllto9";
export var categories = "categories-mEfpxXi9rLDgL1sdGaTU";
export var categoriesList = "categories-list-nryI_qqe859hiNWgHyTh";
export var check = "check-cI6MNg_yZBTvUbwTCxWI";
export var dealName = "deal-name-egdyNWfYcn3U57Kf_zjG";
export var dealStages = "deal-stages-SUQTXNgxJTMRWsZG9WlC";
export var dealType = "deal-type-c75diB4rJvSu3HdTEV8H";
export var desc = "desc-CCOS8xThpWAD86o5Ijyk";
export var domain = "domain-NaUpeH6xgGNY_UsftDsS";
export var dropdown = "dropdown-trwGUnBLLUoydLf1BFf4";
export var fa = "fa-RgGC3tkC2iakfoZ6WrWv";
export var failureStages = "failure-stages-tDzuzx2xx9ih6sC9CQJ1";
export var fieldConfiguration = "field-configuration-vPo2r8enMu9qHU9dMw5L";
export var fieldSelect = "field-select-rPztAz9Kn8rvzyisD6n3";
export var footer = "footer-LSov81nEJLGnIa9xHEi_";
export var form = "form-JO4bUaYMzvcMyY2yDHUm";
export var formContainer = "form-container-tx0jGM9XrJhKwb6C2dCF";
export var from = "from-teqDkZmIq_Uw7UGr6FwC";
export var headline = "headline-W9whDsLjg7Kd5lBZF87q";
export var integrationMenu = "integration-menu-S9RP1Y2at4jEBTbxQ9hc";
export var mappingWarnings = "mapping-warnings-XqrR1XvyK71cTaQH4azs";
export var newAddress = "new-address-a7N_TRJ6gjM0tWt9uKJV";
export var newContainer = "new-container-OlmGzWEGsh8b5Lm4yx2h";
export var newFooter = "new-footer-jsz7q6Jz80rh9H3k02CS";
export var newFormContainer = "new-form-container-Zb1VbOED29sJb25Xs8ZR";
export var newHeader = "new-header-k1ySAl5KlDNUjLU1HKRY";
export var popover = "popover-hLFlkuDsr_KMagq4NqEu";
export var removeFieldBtn = "remove-field-btn-vzhw7buObthemmqqDZCs";
export var settings = "settings-t4RbjCVJcaVjcL5oznuc";
export var stageConfig = "stage-config-BUrBrvcurQrgOQlBrGv0";
export var stageWithFields = "stage-with-fields-T2PEG6mtEiVipS9lDEEr";
export var stagesHead = "stages-head-Svne0xuTmVq0RG0BGVf7";
export var stepNo = "step-no-lQmfDGFcHyamXAiJQzo4";
export var stepsList = "steps-list-aBbZxLRRaFSPgauE_D_k";
export var subheader = "subheader-IqSsuuJvWMeB4PD10Chn";
export var typesCaption = "types-caption-do0XYbpLTJ714qjcEuRD";
export var warnFieldType = "warn-field-type-Pyejdcd7NDOjq9Ivsaet";
export var warnSameFields = "warn-same-fields-b22QGQO6IkdkpD6ECmur";