

import * as El from "../../../../libraries/El.mjs";
import * as Fun from "../../../../libraries/Fun.mjs";
import * as Hooks from "../../../../libraries/hooks/Hooks.mjs";
import * as Modal from "../../../modal/Modal.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Button from "../../../common/Button.mjs";
import * as Select from "../../../inputs/Select.mjs";
import * as Popover from "../../../common/Popover/Popover.mjs";
import * as Checkbox from "../../../inputs/Checkbox.mjs";
import * as Optional from "../../../common/Optional.mjs";
import * as FormInput from "../../../inputs/FormInput.mjs";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as Caml_format from "rescript/lib/es6/caml_format.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Catalog_Lib from "../Catalog_Lib.mjs";
import * as Catalog_Form from "../Catalog_Form.mjs";
import * as Catalog_Types from "../Catalog_Types.mjs";
import * as CurrencyInput from "../../../inputs/CurrencyInput.mjs";
import * as Backend_Errors from "../../../../libraries/backend/Backend_Errors.mjs";
import * as Services_State from "./Services_State.mjs";
import * as Catalog_IndexModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/catalog/Catalog_Index.module.css";

var newService$p = Utils.Translations.tr("js.admin.catalog.new_service");

var name$p = Utils.Translations.t("js.admin.catalog.form.name");

var description$p = Utils.Translations.t("js.admin.catalog.form.description");

var category$p = Utils.Translations.t("js.admin.catalog.form.category");

var externalId$p = Utils.Translations.t("js.admin.catalog.form.external_id");

var priceDefault$p = Utils.Translations.tr("js.admin.catalog.form.price_default");

var archived$p = Utils.Translations.t("js.admin.catalog.form.archived_service");

var noResults$p = Utils.Translations.t("js.components.select.no_results");

var deleteServiceConfirm$p = Utils.Translations.tr("js.admin.catalog.form.delete_service_confirm");

var deleteWithTemplates$p = Utils.Translations.tx("js.admin.catalog.form.delete_with_templates");

var close$p = Utils.Translations.tr("common.close");

var styles = Catalog_IndexModuleCss;

var empty = {
  templates: []
};

function Service_Form(Props) {
  var mode = Props.mode;
  var service = Props.service;
  var onClose = Props.onClose;
  var categories = Props.categories;
  var onCreate = Props.onCreate;
  var onUpdate = Props.onUpdate;
  var onDelete = Props.onDelete;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = React.useReducer(Services_State.reducer, Services_State.fromProps(ctx, service));
  var send = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        return empty;
      });
  var setRelatedEntities = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setShowConfirmation = match$2[1];
  var match$3 = React.useState(function () {
        return Backend_Errors.empty;
      });
  var setErrors = match$3[1];
  var errors = match$3[0];
  var form = Services_State.form(state);
  var removeButtonId = Hooks.useId(undefined);
  var removeButton = React.useRef(null);
  var title = React.useMemo((function () {
          var name = service !== undefined ? service.name : newService$p(ctx);
          return React.createElement(Modal.Header.make, {
                      children: name
                    });
        }), [RCore.$$Option.isSome(service)]);
  var parentSelectValue = Catalog_Lib.parentSelectValue(form.categoryUuid, categories);
  var parentOptions = categories.map(function (c) {
        return Select.$$Option.make(c.name, Catalog_Types.uuidToId(c.uuid));
      });
  var onErrors = function (errors) {
    setErrors(function (param) {
          return errors;
        });
  };
  var onSubmit = function (e) {
    e.preventDefault();
    if (service !== undefined) {
      return onUpdate(service.uuid, Services_State.form(state), onClose, onErrors);
    } else {
      return onCreate(Services_State.form(state), onClose, onErrors);
    }
  };
  var onRemoveError = function (templates) {
    setRelatedEntities(function (param) {
          return {
                  templates: templates
                };
        });
    setShowConfirmation(function (param) {
          return true;
        });
  };
  var onRemove = function () {
    if (service !== undefined) {
      return onDelete(service.uuid, onClose, onRemoveError);
    }
    
  };
  var items = match$1[0].templates;
  var templateError = items.length !== 0 ? deleteWithTemplates$p({
          names: items.map(function (i) {
                return i.name;
              })
        }, ctx) : undefined;
  var renderErrors = Utils.Translations.joinWithComma(RCore.$$Array.filterMap([templateError], (function (x) {
              return x;
            })));
  var tmp = {
    _type: "input",
    value: RCore.$$Option.getOr(form.name, ""),
    onValueChange: (function (name) {
        send({
              TAG: "SetName",
              _0: Fun.optString(name)
            });
      }),
    labelText: name$p(ctx),
    autoFocus: true
  };
  var tmp$1 = Backend_Errors.get(errors, "name");
  if (tmp$1 !== undefined) {
    tmp.errors = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = {
    _type: "input",
    value: RCore.$$Option.getOr(form.description, ""),
    onValueChange: (function (description) {
        send({
              TAG: "SetDescription",
              _0: Fun.optString(description)
            });
      }),
    labelText: description$p(ctx)
  };
  var tmp$3 = Backend_Errors.get(errors, "description");
  if (tmp$3 !== undefined) {
    tmp$2.errors = Caml_option.valFromOption(tmp$3);
  }
  var tmp$4 = {
    _type: "input",
    value: RCore.$$Option.getOr(form.externalId, ""),
    onValueChange: (function (externalId) {
        send({
              TAG: "SetExternalId",
              _0: Fun.optString(externalId)
            });
      }),
    labelText: externalId$p(ctx)
  };
  var tmp$5 = Backend_Errors.get(errors, "externalId");
  if (tmp$5 !== undefined) {
    tmp$4.errors = Caml_option.valFromOption(tmp$5);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Catalog_Form.make, {
                  mode: mode,
                  onClose: onClose,
                  title: title,
                  onSubmit: onSubmit,
                  locked: false,
                  onRemove: onRemove,
                  removeButtonId: removeButtonId,
                  removeButton: removeButton,
                  children: null
                }, React.createElement(FormInput.make, tmp), React.createElement(FormInput.make, tmp$2), React.createElement("div", {
                      className: styles.inputWrapper
                    }, React.createElement("p", {
                          className: styles.inputLabel
                        }, priceDefault$p(ctx)), React.createElement(CurrencyInput.make, {
                          wire: wire,
                          currency: form.currency,
                          useCurrencyFractionalUnit: true,
                          value: form.priceDefault,
                          onValueChange: (function (input) {
                              send({
                                    TAG: "SetPriceDefault",
                                    _0: RCore.$$Option.map(Fun.optString(input), Caml_format.float_of_string)
                                  });
                            }),
                          disabled: false,
                          classNameWrapper: styles.currencyWrapper,
                          backendErrors: Core__List.fromArray(Backend_Errors.getArray(errors, "priceDefault"))
                        })), React.createElement("div", {
                      className: styles.inputWrapper
                    }, React.createElement("p", {
                          className: styles.inputLabel
                        }, category$p(ctx)), React.createElement(Select.make, {
                          placeholder: category$p(ctx),
                          onChange: (function (value) {
                              send({
                                    TAG: "SetCategory",
                                    _0: RCore.$$Option.map(value === null ? undefined : Caml_option.some(value), Catalog_Types.idToUuid)
                                  });
                            }),
                          value: parentSelectValue,
                          disabled: false,
                          options: parentOptions,
                          noResultsText: noResults$p(ctx),
                          up: true
                        })), React.createElement(FormInput.make, tmp$4), React.createElement(Optional.make, {
                      show: mode !== "New",
                      children: React.createElement("div", {
                            className: styles.inputWrapper
                          }, React.createElement(Checkbox.make, {
                                onToggle: (function (value) {
                                    send({
                                          TAG: "SetArchived",
                                          _0: value
                                        });
                                  }),
                                checked: form.archived,
                                label: archived$p(ctx)
                              }))
                    })), typeof mode === "object" ? React.createElement(Popover.make, {
                    footer: wire.footer,
                    show: match$2[0],
                    buttonId: removeButtonId,
                    position: "right",
                    children: React.createElement("div", undefined, React.createElement("h5", undefined, Utils.Translations.concatEl([
                                  deleteServiceConfirm$p(ctx),
                                  El.space,
                                  renderErrors
                                ], ctx)), React.createElement("footer", undefined, React.createElement(Button.make, {
                                  onClick: (function (param) {
                                      setShowConfirmation(function (param) {
                                            return false;
                                          });
                                    }),
                                  children: close$p(ctx)
                                })))
                  }) : null);
}

var make = Service_Form;

export {
  make ,
}
/* newService' Not a pure module */
