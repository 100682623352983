

import * as Types from "../../types/Types.mjs";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactDates from "react-dates";

var Dates = {};

function toString(focusedInput) {
  if (focusedInput === "Start") {
    return "startDate";
  } else {
    return "endDate";
  }
}

function fromString(focusedInput) {
  switch (focusedInput) {
    case "endDate" :
        return "End";
    case "startDate" :
        return "Start";
    default:
      return ;
  }
}

var FocusedInput = {
  toString: toString,
  fromString: fromString
};

function DayPickerRangeController(Props) {
  var startDate = Props.startDate;
  var endDate = Props.endDate;
  var onDatesChange = Props.onDatesChange;
  var onFocusChange = Props.onFocusChange;
  var onOutsideClick = Props.onOutsideClick;
  var minimumNights = Props.minimumNights;
  var numberOfMonths = Props.numberOfMonths;
  var focusedInput = Props.focusedInput;
  var firstDayOfWeekOpt = Props.firstDayOfWeek;
  var hideKeyboardShortcutsPanel = Props.hideKeyboardShortcutsPanel;
  var navPrev = Props.navPrev;
  var navNext = Props.navNext;
  var initialVisibleMonth = Props.initialVisibleMonth;
  var daySize = Props.daySize;
  var renderDay = Props.renderDay;
  var isOutsideRange = Props.isOutsideRange;
  var renderMonth = Props.renderMonth;
  var firstDayOfWeek = firstDayOfWeekOpt !== undefined ? firstDayOfWeekOpt : "Monday";
  var tmp = {
    startDate: startDate,
    endDate: endDate,
    onDatesChange: onDatesChange,
    firstDayOfWeek: Types.Time.WeekDay.toInt(firstDayOfWeek)
  };
  if (onFocusChange !== undefined) {
    tmp.onFocusChange = Caml_option.valFromOption(onFocusChange);
  }
  if (onOutsideClick !== undefined) {
    tmp.onOutsideClick = Caml_option.valFromOption(onOutsideClick);
  }
  if (minimumNights !== undefined) {
    tmp.minimumNights = minimumNights;
  }
  if (numberOfMonths !== undefined) {
    tmp.numberOfMonths = numberOfMonths;
  }
  if (focusedInput !== undefined) {
    tmp.focusedInput = focusedInput;
  }
  if (hideKeyboardShortcutsPanel !== undefined) {
    tmp.hideKeyboardShortcutsPanel = hideKeyboardShortcutsPanel;
  }
  if (navPrev !== undefined) {
    tmp.navPrev = Caml_option.valFromOption(navPrev);
  }
  if (navNext !== undefined) {
    tmp.navNext = Caml_option.valFromOption(navNext);
  }
  if (initialVisibleMonth !== undefined) {
    tmp.initialVisibleMonth = Caml_option.valFromOption(initialVisibleMonth);
  }
  if (daySize !== undefined) {
    tmp.daySize = daySize;
  }
  if (renderDay !== undefined) {
    tmp.renderDay = Caml_option.valFromOption(renderDay);
  }
  if (isOutsideRange !== undefined) {
    tmp.isOutsideRange = Caml_option.valFromOption(isOutsideRange);
  }
  if (renderMonth !== undefined) {
    tmp.renderMonth = Caml_option.valFromOption(renderMonth);
  }
  return React.createElement(ReactDates.DayPickerRangeController, tmp);
}

var make = DayPickerRangeController;

export {
  Dates ,
  FocusedInput ,
  make ,
}
/* Types Not a pure module */
