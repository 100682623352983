

import * as React from "react";

var context = React.createContext();

function use() {
  return React.useContext(context);
}

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var Provider = {
  makeProps: makeProps,
  make: make
};

export {
  use ,
  Provider ,
}
/* context Not a pure module */
