

import * as Modal from "../../modal/Modal.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Catalog_IndexModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/catalog/Catalog_Index.module.css";

var save$p = Utils.Translations.tr("common.save");

var add$p = Utils.Translations.tr("common.add");

var delete$p = Utils.Translations.tr("common.remove");

var styles = Catalog_IndexModuleCss;

function Catalog_Form(Props) {
  var mode = Props.mode;
  var onClose = Props.onClose;
  var title = Props.title;
  var onSubmit = Props.onSubmit;
  var locked = Props.locked;
  var onRemove = Props.onRemove;
  var removeButtonId = Props.removeButtonId;
  var removeButton = Props.removeButton;
  var children = Props.children;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var new_ = typeof mode === "object" ? false : true;
  var onClick = function (e) {
    e.preventDefault();
    onRemove();
  };
  var tmp;
  if (new_) {
    tmp = null;
  } else {
    var tmp$1 = {
      onClick: onClick,
      disabled: locked,
      children: delete$p(ctx)
    };
    if (removeButtonId !== undefined) {
      tmp$1.id = removeButtonId;
    }
    var tmp$2 = RCore.$$Option.map(removeButton, (function (prim) {
            return prim;
          }));
    if (tmp$2 !== undefined) {
      tmp$1.forwardRef = Caml_option.valFromOption(tmp$2);
    }
    tmp = React.createElement(Modal.RemoveButton.make, tmp$1);
  }
  return React.createElement(Modal.make, {
              wire: wire,
              onClose: onClose,
              children: React.createElement(Modal.Dialog.make, {
                    children: null
                  }, title, React.createElement(Modal.Body.make, {
                        children: React.createElement("div", {
                              className: styles.editForm
                            }, React.createElement("form", undefined, children))
                      }), React.createElement(Modal.Footer.make, {
                        children: null
                      }, tmp, React.createElement(Modal.CloseButton.make, {
                            ctx: ctx
                          }), React.createElement(Modal.SubmitButton.make, {
                            onClick: onSubmit,
                            disabled: locked,
                            children: new_ ? add$p(ctx) : save$p(ctx)
                          })))
            });
}

var make = Catalog_Form;

export {
  make ,
}
/* save' Not a pure module */
