

import * as El from "../libraries/El.mjs";
import * as RCore from "../libraries/RCore.mjs";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UI_ButtonModuleCss from "/home/runner/work/planado/planado/client/rescript/ui/UI_Button.module.css";

var styles = UI_ButtonModuleCss;

function toStyle(f) {
  if (f === "default") {
    return styles.btnDefault;
  } else {
    return styles.btnPrimary;
  }
}

function toStyle$1(f) {
  if (f === "link") {
    return styles.btnLink;
  } else if (f === "close") {
    return styles.close;
  } else if (f === "outline") {
    return styles.btnOutline;
  } else if (f === "warning") {
    return styles.btnWarning;
  } else if (f === "danger") {
    return styles.btnDanger;
  } else if (f === "success") {
    return styles.btnSuccess;
  } else {
    return styles.btnInfo;
  }
}

function UI_Button(Props) {
  var purpose = Props.purpose;
  var flavor = Props.flavor;
  var id = Props.id;
  var className = Props.className;
  var type_ = Props.type_;
  var forwardRef = Props.forwardRef;
  var disabledOpt = Props.disabled;
  var onClick = Props.onClick;
  var children = Props.children;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var className$1 = El.Cn.concatIf(El.Cn.concatManyOpt(styles.btn, [
            RCore.$$Option.map(purpose, toStyle),
            RCore.$$Option.map(flavor, toStyle$1),
            className
          ]), [
        disabled,
        styles.disabled
      ]);
  var tmp = {
    className: className$1,
    disabled: disabled
  };
  if (forwardRef !== undefined) {
    tmp.ref = Caml_option.valFromOption(forwardRef);
  }
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  if (type_ !== undefined) {
    tmp.type = Caml_option.valFromOption(type_);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement("button", tmp, children);
}

var make = UI_Button;

export {
  make ,
}
/* styles Not a pure module */
