

import * as Uuid from "../bindings/uuid/Uuid.mjs";
import * as RCore from "./RCore.mjs";
import * as Backend from "./backend/Backend.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as Context_Types from "../context/Context_Types.mjs";
import * as Types_Territory from "../types/Types_Territory.mjs";
import * as Context_Localizator from "../context/Context_Localizator.mjs";

function encode(t) {
  return {
          expired: t.expired,
          expiresIn: Js_null.fromOption(t.expiresIn),
          expiresAt: Js_null.fromOption(t.expiresAt)
        };
}

function encode$1(p) {
  return {
          schedule: Context_Types.ViewEditPermission.toString(p.schedule),
          jobs: Context_Types.Permission.toString(p.jobs),
          clients: Context_Types.ViewEditPermission.toString(p.clients),
          map: Context_Types.BoolPermission.toString(p.map),
          teams: Context_Types.ViewEditPermission.toString(p.teams),
          recurringJobs: Context_Types.BoolPermission.toString(p.recurringJobs),
          admin: Context_Types.BoolPermission.toString(p.admin)
        };
}

function encode$2(param) {
  return {
          version: param.version,
          domain: param.domain,
          brandName: Js_null.fromOption(param.brandName),
          contacts: Js_null.fromOption(param.contacts),
          logo: Js_null.fromOption(param.logo),
          urls: Js_null.fromOption(param.urls)
        };
}

var Branding = {
  encode: encode$2
};

function encode$3(t) {
  return {
          uuid: Uuid.toString(t.uuid),
          name: t.name
        };
}

var BusinessHours = {};

function encode$4(s) {
  return {
          uuid: Uuid.toString(s.uuid),
          name: s.name
        };
}

function encode$5(a) {
  if (a.TAG === "Worker") {
    var match = a._0;
    return {
            uuid: Uuid.toString(match.uuid),
            name: match.name,
            foremanUuid: null,
            territory: Js_null.fromOption(RCore.$$Option.map(match.territory, Types_Territory.encode)),
            skills: match.skills.map(encode$4),
            type: "user"
          };
  }
  var match$1 = a._0;
  return {
          uuid: Uuid.toString(match$1.uuid),
          name: match$1.name,
          foremanUuid: Js_null.fromOption(RCore.$$Option.map(match$1.foremanUuid, Uuid.toString)),
          territory: Js_null.fromOption(RCore.$$Option.map(match$1.territory, Types_Territory.encode)),
          skills: match$1.skills.map(encode$4),
          type: "team"
        };
}

function encode$6(c) {
  return {
          tenantUuid: c.tenantUuid,
          subdomain: c.subdomain,
          domain: c.domain,
          dadataToken: c.dadataToken,
          yandexGeocoderApiKey: c.yandexGeocoderApiKey,
          currency: c.currency,
          owner: c.owner,
          enabled: c.enabled,
          billing: c.billing,
          tags: c.tags,
          trial: Js_null.fromOption(RCore.$$Option.map(c.trial, encode)),
          features: c.features,
          localizator: Context_Localizator.toJs(c.localizator),
          user: Js_null.fromOption(c.user),
          branding: Js_null.fromOption(RCore.$$Option.map(c.branding, encode$2)),
          master: c.isMaster,
          permissions: Js_null.fromOption(RCore.$$Option.map(c.permissions, encode$1)),
          env: c.env,
          mapType: Context_Types.MapType.toString(c.mapType),
          mapApiKey: c.mapApiKey,
          templates: c.templates.map(encode$3),
          businessHours: null,
          assignees: c.assignees.map(encode$5),
          availableSkills: c.availableSkills,
          licenseSource: c.licenseSource,
          disposable: c.disposable,
          timezone: c.timezone,
          addressSourceOptions: Context_Types.AddressSourceOptions.toString(c.addressSourceOptions)
        };
}

function $$fetch(wire) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: "Session"
                }), Context_Types.Context.decode);
}

var ContextJs_Trial = {};

var ContextJs_Permissions = {};

var ContextJs_Template = {};

var ContextJs_Skill = {};

var ContextJs_Assignee = {};

var ContextJs = {
  Trial: ContextJs_Trial,
  $$Permissions: ContextJs_Permissions,
  Branding: Branding,
  Template: ContextJs_Template,
  BusinessHours: BusinessHours,
  Skill: ContextJs_Skill,
  Assignee: ContextJs_Assignee
};

export {
  ContextJs ,
  $$fetch ,
  encode$6 as encode,
}
/* Uuid Not a pure module */
