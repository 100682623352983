


function reducer(param) {
  var action = param[1];
  var state = param[0];
  switch (action.TAG) {
    case "SizesChanged" :
        return {
                TAG: "Update",
                _0: {
                  sizes: action._0,
                  timelineSubscriptions: state.timelineSubscriptions,
                  scrollSubscriptions: state.scrollSubscriptions,
                  assignJobBlocks: state.assignJobBlocks
                }
              };
    case "ScrollInitialized" :
        return {
                TAG: "Update",
                _0: {
                  sizes: state.sizes,
                  timelineSubscriptions: state.timelineSubscriptions,
                  scrollSubscriptions: action._0,
                  assignJobBlocks: state.assignJobBlocks
                }
              };
    case "SetAssignJobBlocks" :
        return {
                TAG: "Update",
                _0: {
                  sizes: state.sizes,
                  timelineSubscriptions: state.timelineSubscriptions,
                  scrollSubscriptions: state.scrollSubscriptions,
                  assignJobBlocks: action._0
                }
              };
    
  }
}

export {
  reducer ,
}
/* No side effect */
