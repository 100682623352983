

import * as El from "../../../../../../../../libraries/El.mjs";
import * as Fun from "../../../../../../../../libraries/Fun.mjs";
import * as Hooks from "../../../../../../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../../../types/Types.mjs";
import * as Units from "../../../../../../../../types/Units.mjs";
import * as Utils from "../../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../../../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../../../../../../../common/Optional.mjs";
import * as AppContext from "../../../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_DND from "../../../../../../lib/Schedule_DND.mjs";
import * as Assignee_Types from "../../../../../../../../types/Assignee_Types.mjs";
import * as Schedule_State from "../../../../../../lib/state/Schedule_State.mjs";
import * as Types_Territory from "../../../../../../../../types/Types_Territory.mjs";
import * as MultipleAssignee from "../../../../../../../../types/MultipleAssignee.mjs";
import * as DayTimeline_State from "../../../../lib/state/DayTimeline_State.mjs";
import * as Schedule_Types_Job from "../../../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as Schedule_Types_Assignees from "../../../../../../lib/types/Schedule_Types_Assignees.mjs";
import * as DayTimeline_AssignJobBlockType from "../../../../lib/types/DayTimeline_AssignJobBlockType.mjs";
import * as DayTimeline_UnassignedJobModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/day_timeline/components/unassigned/job/DayTimeline_UnassignedJob.module.css";

function DayTimeline_UnassignedJobAssignJob$Icon(Props) {
  return React.createElement("svg", {
              height: "9",
              width: "12",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M11.408 3.656h-1.17V2.531a.288.288 0 0 0-.293-.281H9.36a.288.288 0 0 0-.292.281v1.125h-1.17a.288.288 0 0 0-.293.281V4.5c0 .155.132.281.292.281h1.17v1.125c0 .155.132.282.293.282h.585c.16 0 .293-.127.293-.282V4.781h1.17c.16 0 .292-.126.292-.281v-.563a.288.288 0 0 0-.292-.28ZM4.095 4.5c1.292 0 2.34-1.007 2.34-2.25S5.387 0 4.095 0c-1.292 0-2.34 1.007-2.34 2.25S2.803 4.5 4.095 4.5Zm1.638.563h-.305a3.293 3.293 0 0 1-2.666 0h-.305C1.101 5.063 0 6.12 0 7.425v.731C0 8.622.393 9 .877 9h6.436c.484 0 .877-.378.877-.844v-.731c0-1.304-1.1-2.362-2.457-2.362Z",
                  fill: "#308EC3"
                }));
}

var styles = DayTimeline_UnassignedJobModuleCss;

var assign$p = Utils.Translations.tr("js.schedule.v2.job.assign");

var cancel$p = Utils.Translations.tr("js.schedule.v2.job.cancel");

var noTerritory$p = Utils.Translations.tx("js.schedule.v2.job.territory_warning");

function makeStyle(param) {
  return {
          left: Units.Px.toString(param.left),
          top: Units.Px.toString(param.top.contents),
          width: Units.Px.toString(param.width)
        };
}

function makeBlocks(wire, sizeOfMinute, currentDay, jobs, jobUuid, businessHours, scheduledAt, startAt, finishAt, territory, hideAssignJobBlocks, dispatch) {
  return RCore.$$Array.filterMap(Schedule_Types_Assignees.filter(wire, Schedule_State.useAssigneesFilter()), (function (assignee) {
                var hasScheduledJob = RCore.$$Array.keep(jobs, (function (j) {
                          if (Caml_obj.notequal(Schedule_Types_Job.AssignedJob.uuid(j), jobUuid)) {
                            return Schedule_Types_Job.AssignedJob.scheduledAt(j) !== undefined;
                          } else {
                            return false;
                          }
                        })).some(function (job) {
                      if (Locale.T.intersectIntervalStrict([
                              startAt,
                              finishAt
                            ], [
                              Schedule_Types_Job.AssignedJob.startAt(job),
                              Schedule_Types_Job.AssignedJob.finishAt(job)
                            ])) {
                        return Schedule_Types_Job.AssignedJob.assignees(job).some(function (a) {
                                    return Caml_obj.equal(MultipleAssignee.uuid(a), Assignee_Types.Assignee.uuid(assignee));
                                  });
                      } else {
                        return false;
                      }
                    });
                if (hasScheduledJob) {
                  return ;
                }
                var onClick = function () {
                  hideAssignJobBlocks();
                  var match = MultipleAssignee.PossibleAssignee.findByUuid(Assignee_Types.Assignee.uuid(assignee), wire.ctx.allAssignees);
                  var transferredAssignees = match !== undefined ? (
                      match.TAG === "Team" ? [{
                            TAG: "Team",
                            _0: match._0
                          }] : [{
                            TAG: "Worker",
                            _0: {
                              TAG: "Edit",
                              _0: match._0
                            }
                          }]
                    ) : [];
                  dispatch({
                        TAG: "Jobs",
                        _0: {
                          TAG: "JobSubmitted",
                          _0: jobUuid,
                          _1: scheduledAt,
                          _2: transferredAssignees
                        }
                      });
                };
                var match = Fun.both(territory, Assignee_Types.Assignee.territory(assignee));
                var tooltip;
                if (match !== undefined) {
                  var aTerritory = match[1];
                  tooltip = Caml_obj.notequal(Types_Territory.uuid(match[0]), Types_Territory.uuid(aTerritory)) ? noTerritory$p({
                          territory: Types_Territory.name(aTerritory)
                        }, wire.ctx) : undefined;
                } else {
                  tooltip = undefined;
                }
                return DayTimeline_AssignJobBlockType.make(assignee, onClick, wire, businessHours, currentDay, sizeOfMinute, startAt, finishAt, tooltip);
              }));
}

function inBounds(el, x, y) {
  var r = el.getBoundingClientRect();
  var x1 = r.x;
  var x2 = x1 + r.width;
  var y1 = r.y;
  var y2 = y1 + r.height;
  if (x > x1 && x < x2 && y > y1) {
    return y < y2;
  } else {
    return false;
  }
}

var jobWidthForFullButton = Units.Px.fromInt(100);

function DayTimeline_UnassignedJobAssignJob(Props) {
  var position = Props.position;
  var job = Props.job;
  var children = Props.children;
  var wire = AppContext.useWire();
  var subscriptions = wire.subscriptions;
  var ctx = wire.ctx;
  var jobRef = Types.ReactRef.use();
  var buttonRef = Types.ReactRef.use();
  var match = React.useState(function () {
        return false;
      });
  var setShowAssignButton = match[1];
  var showAssignButton = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowCancelButton = match$1[1];
  var showCancelButton = match$1[0];
  var timelineDispatch = DayTimeline_State.useDispatch();
  var hideAssignJobBlocks = function () {
    setShowCancelButton(function (param) {
          return false;
        });
    timelineDispatch({
          TAG: "SetAssignJobBlocks",
          _0: []
        });
  };
  Hooks.useOnEscPress(!showCancelButton, hideAssignJobBlocks);
  Hooks.useOnClickOutside(!showCancelButton, jobRef, hideAssignJobBlocks);
  var match$2 = Schedule_DND.DND.Context.use();
  var dndSubscriptions = match$2.subscriptions;
  React.useEffect((function () {
          if (showCancelButton) {
            return dndSubscriptions.onDragStart.subscribe(hideAssignJobBlocks);
          }
          
        }), [showCancelButton]);
  React.useEffect((function () {
          var jobEl = Types.ReactRef.toOption(jobRef);
          if (jobEl === undefined) {
            return ;
          }
          if (showAssignButton) {
            return ;
          }
          var jobEl$1 = Caml_option.valFromOption(jobEl);
          var onMouseEnter = function (_event) {
            setShowAssignButton(function (param) {
                  return true;
                });
          };
          jobEl$1.addEventListener("mouseenter", onMouseEnter);
          return (function () {
                    jobEl$1.removeEventListener("mouseenter", onMouseEnter);
                  });
        }), [showAssignButton]);
  React.useEffect((function () {
          var match = Fun.both(Types.ReactRef.toOption(jobRef), Types.ReactRef.toOption(buttonRef));
          if (match === undefined) {
            return ;
          }
          if (!(showAssignButton && !showCancelButton)) {
            return ;
          }
          var buttonEl = match[1];
          var jobEl = match[0];
          var onMouseMove = function ($$event) {
            var x = $$event.clientX;
            var y = $$event.clientY;
            var inJobBounds = inBounds(jobEl, x, y);
            var inButtonBounds = inBounds(buttonEl, x, y);
            if (!inJobBounds && !inButtonBounds) {
              return setShowAssignButton(function (param) {
                          return false;
                        });
            }
            
          };
          return subscriptions.mouseMove.subscribe(onMouseMove);
        }), [
        showAssignButton,
        showCancelButton
      ]);
  var sizes = DayTimeline_State.useSizes();
  var scheduleDispatch = Schedule_State.useDispatch();
  var jobs = Schedule_State.useAssignedJobs();
  var currentDay = Schedule_State.useCurrentDay();
  var businessHours = Schedule_State.useBusinessHours();
  var onClick = function ($$event) {
    $$event.stopPropagation();
    if (showCancelButton) {
      return hideAssignJobBlocks();
    }
    setShowCancelButton(function (show) {
          return !show;
        });
    var blocks = makeBlocks(wire, sizes.minute, currentDay, jobs, Schedule_Types_Job.UnassignedJob.uuid(job), businessHours, Schedule_Types_Job.UnassignedJob.scheduledAt(job), Schedule_Types_Job.UnassignedJob.startAt(job), Schedule_Types_Job.UnassignedJob.finishAt(job), Schedule_Types_Job.UnassignedJob.territory(job), hideAssignJobBlocks, scheduleDispatch);
    timelineDispatch({
          TAG: "SetAssignJobBlocks",
          _0: blocks
        });
  };
  var isCompact = React.useMemo((function () {
          return Caml_obj.lessthan(position.width, jobWidthForFullButton);
        }), []);
  return React.createElement(React.Fragment, undefined, children(jobRef), React.createElement(Optional.make, {
                  show: showAssignButton,
                  children: React.createElement("div", {
                        className: styles.buttonContainer,
                        style: makeStyle(position)
                      }, React.createElement("button", {
                            ref: buttonRef,
                            className: El.Cn.concat(El.Cn.concat(styles.button, showCancelButton ? styles.active : ""), isCompact ? styles.compact : ""),
                            type: "button",
                            onClick: onClick
                          }, React.createElement("span", {
                                className: styles.icon
                              }, React.createElement(DayTimeline_UnassignedJobAssignJob$Icon, {})), React.createElement(Optional.make, {
                                show: !isCompact,
                                children: showCancelButton ? cancel$p(ctx) : assign$p(ctx)
                              })))
                }));
}

var make = DayTimeline_UnassignedJobAssignJob;

export {
  make ,
}
/* styles Not a pure module */
