

import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as ClientForms_Types from "./ClientForms_Types.mjs";

var NewFile = {};

function fromState(state) {
  var match = state.file;
  if (match !== undefined && match.TAG === "New") {
    return {
            name: state.name,
            file: {
              name: match.name,
              body: match.body
            },
            availabilityPeriodMin: state.availabilityPeriodMin
          };
  }
  
}

var $$Request = {
  fromState: fromState
};

function create(wire, request) {
  return Backend.discard(Backend.post(undefined, undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "ClientForm",
                    VAL: "Index"
                  }
                }, {
                  NAME: "Obj",
                  VAL: {
                    name: request.name,
                    availability_period_min: request.availabilityPeriodMin,
                    file: {
                      name: request.file.name,
                      body: request.file.body
                    }
                  }
                }));
}

var Create = {
  $$Request: $$Request,
  create: create
};

function make(state) {
  if (state.TAG === "Default") {
    return {
            name: undefined,
            availability_period_min: state._0.availabilityPeriodMin,
            file: undefined
          };
  }
  var custom = state._0;
  var match = custom.file;
  if (match !== undefined && match.TAG === "New") {
    return {
            name: custom.name,
            availability_period_min: custom.availabilityPeriodMin,
            file: {
              name: match.name,
              body: match.body
            }
          };
  }
  return {
          name: custom.name,
          availability_period_min: custom.availabilityPeriodMin,
          file: undefined
        };
}

function update(wire, uuid, state) {
  return Backend.discard(Backend.patch(undefined, true, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "ClientForm",
                    VAL: {
                      NAME: "Edit",
                      VAL: uuid
                    }
                  }
                }, {
                  NAME: "Obj",
                  VAL: make(state)
                }));
}

var decode = JsonDecode.object(function (field) {
      return {
              form: field.required("form", ClientForms_Types.Form.decode)
            };
    });

function show(wire, uuid) {
  return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "ClientForm",
                    VAL: {
                      NAME: "Edit",
                      VAL: uuid
                    }
                  }
                }), decode);
}

var decode$1 = JsonDecode.object(function (field) {
      return {
              forms: field.required("forms", JsonDecode.array(ClientForms_Types.Index.Form.decode))
            };
    });

function index(wire) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "ClientForm",
                    VAL: "Index"
                  }
                }), decode$1);
}

function $$delete(wire, uuid) {
  return Backend.$$delete(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "ClientForm",
                VAL: {
                  NAME: "Edit",
                  VAL: uuid
                }
              }
            });
}

var Delete = {
  $$delete: $$delete
};

var Update = {
  update: update
};

var Show_Response = {};

var Show = {
  $$Response: Show_Response,
  show: show
};

var Index_Response = {};

var Index = {
  $$Response: Index_Response,
  index: index
};

var Errors;

export {
  NewFile ,
  Create ,
  Update ,
  Show ,
  Index ,
  Delete ,
  Errors ,
}
/* decode Not a pure module */
