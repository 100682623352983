const getStorage = storageType => {
  let storage
  switch (storageType) {
    case 'localStorage':
    default:
      storage = window.localStorage

      return {
        set(key, value) {
          storage.setItem(key, window.JSON.stringify(value))
        },
        get(key) {
          return window.JSON.parse(storage.getItem(key))
        },
        remove(key) {
          storage.removeItem(key)
        },
        clear() {
          storage.clear()
        },
        get length() {
          return storage.length
        }
      }
  }
}

export const disablePlacemarkHint = (storageType = 'localStorage') => {
  const storage = getStorage(storageType)
  storage.set('isPlacemarkHintDisable', true)
}

export const getStatusPlacemarkHint = (storageType = 'localStorage') => {
  const storage = getStorage(storageType)
  return storage.get('isPlacemarkHintDisable')
}

export const setScheduleFilter = (filter, storageType = 'localStorage') => {
  const storage = getStorage(storageType)
  return storage.set('scheduleFilter', filter)
}

export const getScheduleFilter = (storageType = 'localStorage') => {
  const storage = getStorage(storageType)
  return storage.get('scheduleFilter')
}
