import React from 'react'
import classNames from 'classnames'
import Autocomplete from 'planado/components/input/autocomplete'
import BaseFormInput from 'planado/components/input/base_form_input'
import { withContext } from 'planado/utils/contextConsumer.jsx'

export default withContext(
  ({
    name,
    placeholder,
    labelText,
    wrapperClass,
    errors = [],
    possibleValue = [],
    value,
    blurHandler,
    setValue,
    selectHandler,
    menuVisibilityChangeHandler,
    maxLength,
    ctx,
    children
  }) => {
    const inputProps = {
      type: 'text',
      id: name,
      name,
      placeholder: placeholder,
      className: 'pd-form-control',
      onBlur: e => blurHandler(e.target.value)
    }

    const menuStyle = {
      display: possibleValue.length > 0 ? 'block' : 'none',
      position: 'absolute',
      top: 'calc(100% + 2px)',
      left: 0,
      width: '100%',
      padding: '7px 0',
      maxHeight: '360px',
      borderRadius: '2px',
      backgroundColor: '#fff',
      border: 'solid 2px #d5d5d5',
      overflow: 'scroll',
      zIndex: 11,
      overflowX: 'hidden',
      wordWrap: 'break-word'
    }

    const autocompleteItemMarkup = (item, isHighlighted, props) => {
      const classes = classNames({
        suggest__item: true,
        suggest__item_active: isHighlighted
      })

      return (
        <div className={classes} key={item.uuid} {...props}>
          {item.name}
        </div>
      )
    }

    const matchStateToTerm = (state, value) =>
      state.name.toLowerCase().indexOf(value.toLowerCase().trim()) !== -1

    const sort = (a, b, value) => {
      const aLower = a.name.toLowerCase()
      const bLower = b.name.toLowerCase()
      const valueLower = value.toLowerCase()
      const queryPosA = aLower.indexOf(valueLower)
      const queryPosB = bLower.indexOf(valueLower)

      if (queryPosA !== queryPosB) {
        return queryPosA - queryPosB
      } else {
        return aLower < bLower ? -1 : 1
      }
    }

    const changeHandler = (_, newValue) => {
      if (maxLength) {
        newValue = newValue.slice(0, maxLength)

        if (newValue !== value) {
          setValue(newValue)
        }
      } else {
        setValue(value)
      }
    }

    let maxLengthText
    if (maxLength == undefined || maxLength == null) {
      maxLengthText = null
    } else {
      const lengthRemaining = maxLength - value.length
      if (lengthRemaining <= maxLength / 10 && lengthRemaining > 0) {
        maxLengthText = (
          <span className="pd-form-control-comment">
            {ctx.t('js.components.limited_input.remaining', {
              count: maxLength,
              current: value.length
            })}
          </span>
        )
      } else if (lengthRemaining === 0) {
        maxLengthText = (
          <span className="pd-form-control-comment">
            {ctx.t('js.components.limited_input.maxlength', {
              count: maxLength
            })}
          </span>
        )
      } else {
        maxLengthText = null
      }
    }

    return (
      <BaseFormInput
        id={name}
        labelText={labelText}
        wrapperClass={wrapperClass}
        errors={errors}
      >
        {children}

        <Autocomplete
          wrapperProps={{ className: 'suggest' }}
          wrapperStyle={{}}
          inputProps={inputProps}
          menuStyle={menuStyle}
          value={value}
          items={possibleValue}
          getItemValue={item => item.name}
          renderItem={autocompleteItemMarkup}
          shouldItemRender={matchStateToTerm}
          sortItems={sort}
          onMenuVisibilityChange={menuVisibilityChangeHandler}
          onChange={changeHandler}
          onSelect={selectHandler}
        />

        {errors.length === 0 ? maxLengthText : null}
      </BaseFormInput>
    )
  }
)
