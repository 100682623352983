

import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Router from "../../../Router.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Runtime from "../../../Runtime.mjs";
import * as AmoCrmApi from "./AmoCrmApi.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as AmoCrmShowModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/amocrm/AmoCrmShow.module.css";

var styles = AmoCrmShowModuleCss;

var connecting$p = Utils.Translations.tr("js.admin.integrations.amocrm.connecting");

function AmoCrmConnect(Props) {
  var wire = Props.wire;
  var host = Props.host;
  var match = React.useState(function () {
        
      });
  var setTimeoutId = match[1];
  var timeoutId = match[0];
  var refreshStatus = function () {
    var timeoutId = Runtime.Timeout.defer(1000, (function () {
            Backend.$$finally(AmoCrmApi.Connect.check(wire, host), (function (r) {
                    if (r.TAG !== "Ok") {
                      return refreshStatus();
                    }
                    var url = r._0;
                    if (typeof url !== "object") {
                      return refreshStatus();
                    } else {
                      return Router.redirect(url._0);
                    }
                  }));
          }));
    setTimeoutId(function (param) {
          return Caml_option.some(timeoutId);
        });
  };
  React.useEffect((function () {
          return RCore.$$Option.flatMap(timeoutId, (function (id) {
                        return (function () {
                                  Runtime.Timeout.cancel(id);
                                });
                      }));
        }), [timeoutId]);
  React.useEffect((function () {
          refreshStatus();
        }), []);
  return React.createElement("div", {
              className: "form"
            }, React.createElement("section", {
                  className: styles.form
                }, React.createElement("div", {
                      className: styles.newContainer
                    }, React.createElement("h4", {
                          className: styles.newHeader
                        }, connecting$p(wire.ctx)))));
}

var make = AmoCrmConnect;

export {
  make ,
}
/* styles Not a pure module */
