

import * as Hooks from "../../../../../../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../../../types/Types.mjs";
import * as React from "react";
import * as Locale from "../../../../../../../../libraries/Locale.mjs";
import * as Logger from "../../../../../../../../libraries/Logger.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppContext from "../../../../../../../../context/AppContext.mjs";
import * as Schedule_DND from "../../../../../../lib/Schedule_DND.mjs";
import * as Assignee_Types from "../../../../../../../../types/Assignee_Types.mjs";
import * as Schedule_State from "../../../../../../lib/state/Schedule_State.mjs";
import * as Shared_Lib_Debounce from "../../../../../../../../shared/lib/Shared_Lib_Debounce.mjs";
import * as WeekTimeline_ActiveDayStateTypes from "./WeekTimeline_ActiveDayStateTypes.mjs";
import * as WeekTimeline_ActiveDayStateContext from "./WeekTimeline_ActiveDayStateContext.mjs";

function useContext() {
  var wire = AppContext.useWire();
  var period = Schedule_State.usePeriod();
  var jobs = Schedule_State.useAssignedJobs();
  var showUnscheduledJobs = Schedule_State.useShowUnscheduledJobs();
  var context = React.useState(function () {
        return WeekTimeline_ActiveDayStateTypes.State.initial;
      });
  var setState = context[1];
  var state = context[0];
  var hiddenActiveDay = state.hiddenActiveDay;
  var match = Schedule_DND.DND.Context.use();
  var onDragEnd = match.subscriptions.onDragEnd;
  React.useEffect((function () {
          return onDragEnd.subscribe(function () {
                      if (hiddenActiveDay !== undefined) {
                        return setState(function (state) {
                                    return {
                                            activeDay: state.activeDay,
                                            hiddenActiveDay: undefined
                                          };
                                  });
                      }
                      
                    });
        }), [hiddenActiveDay]);
  Hooks.useCustomCompareEffect1((function () {
          setState(function (state) {
                var activeDay = state.activeDay;
                if (activeDay !== undefined) {
                  return {
                          activeDay: {
                            bar: activeDay.bar,
                            weekDay: activeDay.weekDay,
                            period: activeDay.period,
                            elements: activeDay.elements,
                            sizes: activeDay.sizes,
                            jobs: WeekTimeline_ActiveDayStateTypes.Jobs.make(wire, jobs, WeekTimeline_ActiveDayStateTypes.ActiveDaySelector.assigneeUuid(activeDay), activeDay.period, activeDay.sizes),
                            scrollSubscriptions: activeDay.scrollSubscriptions,
                            intervals: activeDay.intervals
                          },
                          hiddenActiveDay: state.hiddenActiveDay
                        };
                } else {
                  return WeekTimeline_ActiveDayStateTypes.State.initial;
                }
              });
        }), jobs, Caml_obj.equal);
  React.useEffect((function () {
          setState(function (param) {
                return WeekTimeline_ActiveDayStateTypes.State.initial;
              });
        }), [period]);
  var match$1 = Shared_Lib_Debounce.debounce(300, undefined, (function () {
          var activeDay = state.activeDay;
          if (activeDay === undefined) {
            return ;
          }
          var sizes = WeekTimeline_ActiveDayStateTypes.Sizes.make(showUnscheduledJobs);
          if (Caml_obj.notequal(sizes, activeDay.sizes)) {
            return setState(function (state) {
                        return {
                                activeDay: {
                                  bar: activeDay.bar,
                                  weekDay: activeDay.weekDay,
                                  period: activeDay.period,
                                  elements: activeDay.elements,
                                  sizes: sizes,
                                  jobs: activeDay.jobs,
                                  scrollSubscriptions: activeDay.scrollSubscriptions,
                                  intervals: activeDay.intervals
                                },
                                hiddenActiveDay: state.hiddenActiveDay
                              };
                      });
          }
          
        }));
  var onResize = match$1.f;
  React.useEffect((function () {
          return wire.subscriptions.resize.subscribe(function (param) {
                      onResize();
                    });
        }), [state]);
  return context;
}

function useState() {
  return WeekTimeline_ActiveDayStateContext.StateContext.use();
}

function useSetState() {
  return WeekTimeline_ActiveDayStateContext.SetStateContext.use();
}

function useSetActiveDay() {
  var setState = WeekTimeline_ActiveDayStateContext.SetStateContext.use();
  var wire = AppContext.useWire();
  var jobs = Schedule_State.useAssignedJobs();
  var workersTimelines = Schedule_State.useWorkersTimelines();
  var startOfPeriod = Schedule_State.useStartOfPeriod();
  var showUnscheduledJobs = Schedule_State.useShowUnscheduledJobs();
  var scrollAreaEl = Types.ReactRef.use();
  var viewportEl = Types.ReactRef.use();
  var inDragAndDrop = React.useRef(false);
  var match = Schedule_DND.DND.Context.use();
  var match$1 = match.subscriptions;
  var onDragEnd = match$1.onDragEnd;
  var onDragStart = match$1.onDragStart;
  React.useEffect((function () {
          var unsubscribeFromDragStart = onDragStart.subscribe(function () {
                inDragAndDrop.current = true;
              });
          var unsubscribeFromDragEnd = onDragEnd.subscribe(function () {
                inDragAndDrop.current = false;
              });
          return (function () {
                    unsubscribeFromDragStart();
                    unsubscribeFromDragEnd();
                  });
        }), []);
  return function (bar, weekDay) {
    var sizes = WeekTimeline_ActiveDayStateTypes.Sizes.make(showUnscheduledJobs);
    var startOfDay = Locale.T.nextDays(weekDay)(startOfPeriod);
    var endOfDay = Locale.T.endOf("day", startOfDay);
    var period = [
      startOfDay,
      endOfDay
    ];
    var assigneeUuid = Assignee_Types.Assignee.uuid(bar);
    var jobs$1 = WeekTimeline_ActiveDayStateTypes.Jobs.make(wire, jobs, assigneeUuid, period, sizes);
    var intervals = WeekTimeline_ActiveDayStateTypes.Interval.makeArray(wire, workersTimelines, assigneeUuid, period, sizes);
    var activeDay_elements = {
      scrollAreaEl: scrollAreaEl,
      viewportEl: viewportEl
    };
    var activeDay = {
      bar: bar,
      weekDay: weekDay,
      period: period,
      elements: activeDay_elements,
      sizes: sizes,
      jobs: jobs$1,
      scrollSubscriptions: undefined,
      intervals: intervals
    };
    setState(function (state) {
          var currentActiveDay = state.activeDay;
          var exit = 0;
          if (currentActiveDay !== undefined && state.hiddenActiveDay === undefined) {
            if (inDragAndDrop.current) {
              return {
                      activeDay: activeDay,
                      hiddenActiveDay: currentActiveDay
                    };
            }
            
          } else {
            exit = 2;
          }
          if (exit === 2) {
            var hiddenActiveDay = state.hiddenActiveDay;
            if (hiddenActiveDay !== undefined && WeekTimeline_ActiveDayStateTypes.ActiveDay.key(activeDay) === WeekTimeline_ActiveDayStateTypes.ActiveDay.key(hiddenActiveDay)) {
              return {
                      activeDay: hiddenActiveDay,
                      hiddenActiveDay: undefined
                    };
            }
            
          }
          return {
                  activeDay: activeDay,
                  hiddenActiveDay: state.hiddenActiveDay
                };
        });
    Logger.logEvent(wire, "week-timeline-show-day");
  };
}

function useResetActiveDay() {
  var state = WeekTimeline_ActiveDayStateContext.StateContext.use();
  var setState = WeekTimeline_ActiveDayStateContext.SetStateContext.use();
  return React.useCallback((function () {
                var match = state.activeDay;
                if (match !== undefined) {
                  return setState(function (param) {
                              return WeekTimeline_ActiveDayStateTypes.State.initial;
                            });
                }
                
              }), [state]);
}

function useActiveDay() {
  return WeekTimeline_ActiveDayStateContext.StateContext.use().activeDay;
}

function useHiddenActiveDay() {
  return WeekTimeline_ActiveDayStateContext.StateContext.use().hiddenActiveDay;
}

function useAssigneeUuid() {
  var match = WeekTimeline_ActiveDayStateContext.StateContext.use();
  return RCore.$$Option.map(match.activeDay, WeekTimeline_ActiveDayStateTypes.ActiveDaySelector.assigneeUuid);
}

function useWeekDay() {
  var match = WeekTimeline_ActiveDayStateContext.StateContext.use();
  return RCore.$$Option.map(match.activeDay, WeekTimeline_ActiveDayStateTypes.ActiveDaySelector.weekDay);
}

function usePeriod() {
  var match = WeekTimeline_ActiveDayStateContext.StateContext.use();
  return RCore.$$Option.map(match.activeDay, WeekTimeline_ActiveDayStateTypes.ActiveDaySelector.period);
}

function useJobs() {
  var match = WeekTimeline_ActiveDayStateContext.StateContext.use();
  return RCore.$$Option.map(match.activeDay, WeekTimeline_ActiveDayStateTypes.ActiveDaySelector.jobs);
}

function useSizes() {
  var match = WeekTimeline_ActiveDayStateContext.StateContext.use();
  return RCore.$$Option.map(match.activeDay, WeekTimeline_ActiveDayStateTypes.ActiveDaySelector.sizes);
}

function useElements() {
  var match = WeekTimeline_ActiveDayStateContext.StateContext.use();
  return RCore.$$Option.map(match.activeDay, WeekTimeline_ActiveDayStateTypes.ActiveDaySelector.elements);
}

function useScrollSubscriptions() {
  var match = WeekTimeline_ActiveDayStateContext.StateContext.use();
  return RCore.$$Option.flatMap(match.activeDay, WeekTimeline_ActiveDayStateTypes.ActiveDaySelector.scrollSubscriptions);
}

export {
  useContext ,
  useState ,
  useSetState ,
  useSetActiveDay ,
  useResetActiveDay ,
  useActiveDay ,
  useHiddenActiveDay ,
  useAssigneeUuid ,
  useWeekDay ,
  usePeriod ,
  useJobs ,
  useSizes ,
  useElements ,
  useScrollSubscriptions ,
}
/* Hooks Not a pure module */
