import React from 'react'
import Select from 'react-select'
import { withContext } from 'planado/utils/contextConsumer.jsx'

// Reason cannot into context
// Hence the extra component
export const ContextlessSelect = ({
  placeholder,
  value,
  name,
  options,
  onChange,
  multi = false,
  noResultsText,
  up = false,
  disabled = false,
  clearable = true,
  searchable = true,
  autoFocus = false,
  autoSize = false,
  errors = null,
  ...rest
}) => {
  const hasErrors = errors !== null && errors.length > 0

  let errorBlock

  if (hasErrors) {
    errorBlock = (
      <div className="pd-control-error">
        {errors.map((x, idx) => (
          <div key={idx}>{x}</div>
        ))}
      </div>
    )
  } else {
    errorBlock = null
  }

  let valueInput

  if (name) {
    valueInput = <input type="hidden" name={name} value={value || ''} />
  } else {
    valueInput = null
  }

  const onChangeCallback = multi
    ? values => onChange(values.map(x => x.value))
    : value => onChange(value ? value.value : null)

  return (
    <span className={up ? 'select-up' : ''}>
      {valueInput}

      <Select
        autosize={autoSize}
        placeholder={placeholder}
        value={value}
        options={options}
        multi={multi}
        noResultsText={noResultsText}
        onChange={onChangeCallback}
        disabled={disabled}
        clearable={clearable}
        searchable={searchable}
        autoFocus={autoFocus}
        {...rest}
      />
      {errorBlock}
    </span>
  )
}

const Wrapper = withContext(({ placeholder, noResultsText, ctx, ...props }) => (
  <ContextlessSelect
    {...props}
    ctx={ctx}
    placeholder={placeholder || ctx.t('js.components.select.placeholder')}
    noResultsText={noResultsText || ctx.t('js.components.select.no_results')}
    clearValueText={ctx.t('js.components.select.clear_value_text')}
    clearAllText={ctx.t('js.components.select.clear_all_text')}
  />
))

export default Wrapper
