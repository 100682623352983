

import * as Utils from "./utils/Utils.mjs";
import * as React from "react";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";

var loaded = {
  contents: /* [] */0
};

function Script(Props) {
  var src = Props.src;
  var root = Props.root;
  var onloadOpt = Props.onload;
  var onload = onloadOpt !== undefined ? onloadOpt : (function (prim) {
        
      });
  React.useEffect((function () {
          if (Core__List.some(loaded.contents, (function (x) {
                    return x === src;
                  }))) {
            onload(false);
          } else {
            var script = Utils.createElement("script");
            Utils.setAttribute(script, "async", "true");
            Utils.setAttribute(script, "src", src);
            Utils.setOnload(script, (function () {
                    onload(true);
                  }));
            Utils.appendChild(root, script);
            loaded.contents = Core__List.concat({
                  hd: src,
                  tl: /* [] */0
                }, loaded.contents);
          }
        }), []);
  return null;
}

var make = Script;

export {
  make ,
}
/* Utils Not a pure module */
