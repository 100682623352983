

import * as Wire from "../../../../../../types/Wire.mjs";
import * as Types from "../../../../../../types/Types.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as React from "react";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as Optional from "../../../../../common/Optional.mjs";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as ModalQuery from "../../../../../../types/ModalQuery.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_Popover from "../../../../components/popovers/popover/Schedule_Popover.mjs";
import * as Schedule_AddJobPanel from "../../../../components/add_job_panel/Schedule_AddJobPanel.mjs";
import * as WeekCalendar_CellModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_calendar/components/cell/WeekCalendar_Cell.module.css";

var styles = WeekCalendar_CellModuleCss;

function WeekCalendar_Cell$Component(Props) {
  var hour = Props.hour;
  var match = AppContext.useWire();
  var openModal = match.openModal;
  var subscriptions = match.subscriptions;
  var match$1 = React.useState(function () {
        
      });
  var setPopoverPosition = match$1[1];
  var popoverPosition = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setShowPopover = match$2[1];
  var showPopover = match$2[0];
  var closeAddJobPopover = function () {
    if (showPopover) {
      setShowPopover(function (param) {
            return false;
          });
    }
    if (popoverPosition !== undefined) {
      return setPopoverPosition(function (param) {
                  
                });
    }
    
  };
  var addJobByTemplate = function (templateUuid) {
    openModal({
          NAME: "Job",
          VAL: {
            NAME: "New",
            VAL: {
              TAG: "Schedule",
              _0: ModalQuery.Job.NewOptions.Schedule.make(Locale.T.formatM("DateTimeISO")(hour), undefined, templateUuid)
            }
          }
        });
  };
  var addJob = function (templateId) {
    return function ($$event) {
      $$event.preventDefault();
      addJobByTemplate(templateId);
      closeAddJobPopover();
    };
  };
  var buttonRef = Types.ReactRef.use();
  var cellRef = Types.ReactRef.use();
  var match$3 = React.useState(function () {
        return false;
      });
  var setShowButton = match$3[1];
  var showButton = match$3[0];
  React.useEffect((function () {
          var cell = Types.ReactRef.toOption(cellRef);
          if (cell === undefined) {
            return ;
          }
          var cell$1 = Caml_option.valFromOption(cell);
          return subscriptions.mouseMove.subscribe(function ($$event) {
                      var cellRect = cell$1.getBoundingClientRect();
                      var clientX = $$event.clientX;
                      var clientY = $$event.clientY;
                      var nextShowButton = cellRect.top <= clientY && clientY <= cellRect.bottom && cellRect.left < clientX && clientX < cellRect.right;
                      if (nextShowButton !== showButton) {
                        return setShowButton(function (param) {
                                    return nextShowButton;
                                  });
                      }
                      
                    });
        }), [showButton]);
  var showAddJobPopover = function (_event) {
    if (popoverPosition !== undefined) {
      setPopoverPosition(function (param) {
            
          });
    }
    setShowPopover(function (showPopover) {
          return !showPopover;
        });
  };
  var addJobButtonBlock = React.createElement(Optional.make, {
        show: showButton || showPopover,
        children: React.createElement("button", {
              ref: buttonRef,
              className: styles.button,
              onClick: showAddJobPopover
            })
      });
  var match$4 = Types.ReactRef.toOption(buttonRef);
  var addJobPopoverBlock;
  if (popoverPosition !== undefined) {
    addJobPopoverBlock = React.createElement(Schedule_Popover.make, {
          clickTargetPosition: {
            NAME: "Coordinate",
            VAL: [
              popoverPosition[0],
              popoverPosition[1]
            ]
          },
          popoverPosition: "RightTop",
          children: React.createElement(Schedule_AddJobPanel.make, {
                addJob: addJob,
                close: closeAddJobPopover
              })
        });
  } else if (match$4 !== undefined && showPopover) {
    var clickTargetPosition_1 = Caml_option.valFromOption(match$4).getBoundingClientRect();
    var clickTargetPosition = {
      NAME: "DomRect",
      VAL: clickTargetPosition_1
    };
    addJobPopoverBlock = React.createElement(Schedule_Popover.make, {
          clickTargetPosition: clickTargetPosition,
          popoverPosition: "RightTop",
          children: React.createElement(Schedule_AddJobPanel.make, {
                addJob: addJob,
                close: closeAddJobPopover
              })
        });
  } else {
    addJobPopoverBlock = null;
  }
  var showFloatAddJobPopover = function ($$event) {
    var clientX = $$event.clientX;
    var clientY = $$event.clientY;
    if (showPopover) {
      setShowPopover(function (param) {
            return false;
          });
    }
    setPopoverPosition(function (param) {
          return [
                  Units.Px.fromInt(clientX),
                  Units.Px.fromInt(clientY)
                ];
        });
  };
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  ref: cellRef,
                  className: styles.cell,
                  onDoubleClick: showFloatAddJobPopover
                }, addJobButtonBlock), addJobPopoverBlock);
}

function WeekCalendar_Cell(Props) {
  var hour = Props.hour;
  var wire = AppContext.useWire();
  if (Wire.hasRightsToEditJobs(wire)) {
    return React.createElement(WeekCalendar_Cell$Component, {
                hour: hour
              });
  } else {
    return React.createElement("div", {
                className: styles.cell
              });
  }
}

var make = WeekCalendar_Cell;

export {
  make ,
}
/* styles Not a pure module */
