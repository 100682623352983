

import * as Backend from "../../libraries/backend/Backend.mjs";

function request(params) {
  return {
          resetToken: params.resetToken,
          password: params.password,
          passwordConfirmation: params.passwordConfirmation
        };
}

function reset(wire, params) {
  return Backend.post(undefined, undefined, undefined, true, wire, {
              NAME: "API",
              VAL: {
                NAME: "ResetPassword",
                VAL: params.resetToken
              }
            }, {
              NAME: "Obj",
              VAL: request(params)
            });
}

export {
  request ,
  reset ,
}
/* Backend Not a pure module */
