

import * as El from "../../../libraries/El.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../Optional.mjs";
import * as FieldErrorsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/FieldError/FieldErrors.module.css";

var styles = FieldErrorsModuleCss;

function FieldErrors(Props) {
  var customClass = Props.customClass;
  var errors = Props.errors;
  return React.createElement(Optional.make, {
              show: Caml_obj.notequal(errors, []),
              children: React.createElement("div", {
                    className: El.Cn.concatOpt(styles.error, customClass)
                  }, Utils.Translations.joinWithComma(errors))
            });
}

var make = FieldErrors;

export {
  make ,
}
/* styles Not a pure module */
