

import * as React from "react";

function MonthCalendar(Props) {
  return React.createElement("div", undefined, "Month");
}

var make = MonthCalendar;

export {
  make ,
}
/* react Not a pure module */
