import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Catalog from 'rscrpt/components/admin/template_catalog/Template_Catalog.mjs'
import * as actions from 'planado/actions/admin/templates'

const mapStateToProps = (state, { setServices, setCategories }) => {
  return {
    ...state,
    setServices: setServices,
    setCategories: setCategories,
    selectedServices: state.catalog.services,
    selectedServiceCategories: state.catalog.categories,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
})

const CatalogLink = connect(
  mapStateToProps,
  mapDispatchToProps
)(Catalog)

export default CatalogLink
