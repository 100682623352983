export const filterBars = (bars, { filter }) => {
  if (filter.length > 0) {
    return bars.filter(bar => {
      if (bar.unassigned()) {
        return true
      } else {
        return filter.includes(bar.get('uuid'))
      }
    })
  } else {
    return bars
  }
}

export const filterJobs = (jobs, { filter }) => {
  if (filter.length > 0) {
    return jobs.filter(
      ({
        attributes: { assigned_to_team, assigned_to_worker, assignee_uuid }
      }) => {
        if (assigned_to_team || assigned_to_worker) {
          return filter.includes(assignee_uuid)
        } else {
          return true
        }
      }
    )
  } else {
    return jobs
  }
}
