

import * as El from "../../../../libraries/El.mjs";
import * as Fun from "../../../../libraries/Fun.mjs";
import * as Icon from "../../../../Icon.mjs";
import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as Modal from "../../../modal/Modal.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Checkbox from "../../../inputs/Checkbox.mjs";
import * as Optional from "../../../common/Optional.mjs";
import * as FormInput from "../../../inputs/FormInput.mjs";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Catalog_Form from "../Catalog_Form.mjs";
import * as Backend_Errors from "../../../../libraries/backend/Backend_Errors.mjs";
import * as CatalogUnits_State from "./CatalogUnits_State.mjs";
import * as TableModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/Table.module.css";
import * as Catalog_IndexModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/catalog/Catalog_Index.module.css";

var addUnit$p = Utils.Translations.tr("js.admin.catalog.add_unit");

var noUnits$p = Utils.Translations.tr("js.admin.catalog.no_units");

var newUnit$p = Utils.Translations.tr("js.admin.catalog.new_unit");

var name$p = Utils.Translations.t("js.admin.catalog.form.name");

var code$p = Utils.Translations.t("js.admin.catalog.form.code");

var externalId$p = Utils.Translations.t("js.admin.catalog.form.external_id");

var archived$p = Utils.Translations.t("js.admin.catalog.form.archived_unit");

var relatedProductsError$p = Utils.Translations.tx("js.admin.catalog.form.related_products_error");

var styles = Catalog_IndexModuleCss;

var tableStyles = TableModuleCss;

var empty = {
  products: []
};

function CatalogUnits_List$Form(Props) {
  var mode = Props.mode;
  var unit = Props.unit;
  var type_ = Props.type_;
  var onClose = Props.onClose;
  var onCreate = Props.onCreate;
  var onUpdate = Props.onUpdate;
  var onDelete = Props.onDelete;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var match$1 = React.useState(function () {
        return Backend_Errors.empty;
      });
  var setErrors = match$1[1];
  var errors = match$1[0];
  var match$2 = React.useState(function () {
        return empty;
      });
  var setRelatedEntities = match$2[1];
  var match$3 = React.useReducer(CatalogUnits_State.reducer, CatalogUnits_State.fromProps(unit, type_));
  var send = match$3[1];
  var state = match$3[0];
  var match$4 = CatalogUnits_State.form(state);
  var title = React.useMemo((function () {
          var name = unit !== undefined ? unit.name : newUnit$p(ctx);
          return React.createElement(Modal.Header.make, {
                      children: name
                    });
        }), [RCore.$$Option.isSome(unit)]);
  var onErrors = function (errors) {
    setErrors(function (param) {
          return errors;
        });
  };
  var onSubmit = function (e) {
    e.preventDefault();
    if (unit !== undefined) {
      return onUpdate(unit.uuid, CatalogUnits_State.form(state), onClose, onErrors);
    } else {
      return onCreate(CatalogUnits_State.form(state), onClose, onErrors);
    }
  };
  var onRemoveError = function (products) {
    setRelatedEntities(function (param) {
          return {
                  products: products
                };
        });
  };
  var onRemoveClick = function () {
    if (unit !== undefined) {
      return onDelete(unit.uuid, onClose, onRemoveError);
    }
    
  };
  var items = match$2[0].products;
  var renderErrors = items.length !== 0 ? React.createElement("div", {
          className: styles.error
        }, relatedProductsError$p({
              names: items.map(function (i) {
                    return i.name;
                  })
            }, ctx)) : null;
  var tmp = {
    _type: "input",
    value: RCore.$$Option.getOr(match$4.name, ""),
    onValueChange: (function (name) {
        send({
              TAG: "SetName",
              _0: Fun.optString(name)
            });
      }),
    labelText: name$p(ctx),
    autoFocus: true
  };
  var tmp$1 = Backend_Errors.get(errors, "name");
  if (tmp$1 !== undefined) {
    tmp.errors = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = {
    _type: "input",
    value: RCore.$$Option.getOr(match$4.code, ""),
    onValueChange: (function (code) {
        send({
              TAG: "SetCode",
              _0: Fun.optString(code)
            });
      }),
    labelText: code$p(ctx)
  };
  var tmp$3 = Backend_Errors.get(errors, "code");
  if (tmp$3 !== undefined) {
    tmp$2.errors = Caml_option.valFromOption(tmp$3);
  }
  var tmp$4 = {
    _type: "input",
    value: RCore.$$Option.getOr(match$4.externalId, ""),
    onValueChange: (function (updateExternalId) {
        send({
              TAG: "SetExternalId",
              _0: Fun.optString(updateExternalId)
            });
      }),
    labelText: externalId$p(ctx)
  };
  var tmp$5 = Backend_Errors.get(errors, "externalId");
  if (tmp$5 !== undefined) {
    tmp$4.errors = Caml_option.valFromOption(tmp$5);
  }
  return React.createElement(Catalog_Form.make, {
              mode: mode,
              onClose: onClose,
              title: title,
              onSubmit: onSubmit,
              locked: false,
              onRemove: onRemoveClick,
              children: null
            }, React.createElement(FormInput.make, tmp), React.createElement(FormInput.make, tmp$2), React.createElement(FormInput.make, tmp$4), React.createElement(Optional.make, {
                  show: mode !== "New",
                  children: React.createElement("div", {
                        className: styles.inputWrapper
                      }, React.createElement(Checkbox.make, {
                            onToggle: (function (value) {
                                send({
                                      TAG: "SetArchived",
                                      _0: value
                                    });
                              }),
                            checked: match$4.archived,
                            label: archived$p(ctx)
                          }))
                }), renderErrors);
}

function CatalogUnits_List$Header(Props) {
  var ctx = Props.ctx;
  return React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, name$p(ctx)), React.createElement("th", undefined, code$p(ctx)), React.createElement("th", undefined, externalId$p(ctx))));
}

function CatalogUnits_List$UnitRow(Props) {
  var unit = Props.unit;
  var onUnitOpen = Props.onUnitOpen;
  var onClick = function (e) {
    e.preventDefault();
    onUnitOpen(unit.uuid);
  };
  return React.createElement("tr", {
              className: styles.tableRow,
              onClick: onClick
            }, React.createElement("td", undefined, React.createElement("div", {
                      className: El.Cn.concat(styles.categoryName, styles.link)
                    }, unit.name)), React.createElement("td", undefined, unit.code), React.createElement("td", undefined, RCore.$$Option.getOr(unit.externalId, "")));
}

function CatalogUnits_List$Table(Props) {
  var ctx = Props.ctx;
  var units = Props.units;
  var onUnitOpen = Props.onUnitOpen;
  return React.createElement("table", {
              className: El.Cn.concat(tableStyles.table, styles.dataTable)
            }, React.createElement(CatalogUnits_List$Header, {
                  ctx: ctx
                }), React.createElement("tbody", undefined, units.map(function (unit) {
                      return React.createElement(CatalogUnits_List$UnitRow, {
                                  unit: unit,
                                  onUnitOpen: onUnitOpen,
                                  key: Uuid.toString(unit.uuid)
                                });
                    })));
}

function CatalogUnits_List(Props) {
  var param = Props.wire;
  var units = Props.units;
  var type_ = Props.type_;
  var onCreate = Props.onCreate;
  var onUpdate = Props.onUpdate;
  var onDelete = Props.onDelete;
  var ctx = param.ctx;
  var match = React.useState(function () {
        return false;
      });
  var setAddNew = match[1];
  var match$1 = React.useState(function () {
        
      });
  var setOpenedUnit = match$1[1];
  var openedUnit = match$1[0];
  var onAddNew = function (e) {
    e.preventDefault();
    setAddNew(function (param) {
          return true;
        });
  };
  var onClose = function () {
    setAddNew(function (param) {
          return false;
        });
  };
  var onUnitOpen = function (uuid) {
    setOpenedUnit(function (param) {
          return Caml_option.some(uuid);
        });
  };
  var onUnitClose = function () {
    setOpenedUnit(function (param) {
          
        });
  };
  var tmp;
  if (openedUnit !== undefined) {
    var uuid = Caml_option.valFromOption(openedUnit);
    tmp = React.createElement(CatalogUnits_List$Form, {
          mode: {
            NAME: "Edit",
            VAL: uuid
          },
          unit: RCore.$$Array.getBy(units, (function (c) {
                  return Uuid.equal(c.uuid, uuid);
                })),
          type_: type_,
          onClose: onUnitClose,
          onCreate: onCreate,
          onUpdate: onUpdate,
          onDelete: onDelete
        });
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: "panel-body"
            }, React.createElement("a", {
                  className: "add-link btn btn-outline btn-32px",
                  onClick: onAddNew
                }, React.createElement("i", {
                      className: Icon.style(undefined, undefined, "plus")
                    }), El.space, addUnit$p(ctx)), units.length === 0 ? React.createElement("div", {
                    className: styles.noEntriesContainer
                  }, React.createElement("span", {
                        className: El.Cn.concat(Icon.style(undefined, undefined, "tags"), styles.tags)
                      }), React.createElement("p", {
                        className: styles.noEntries
                      }, noUnits$p(ctx))) : React.createElement(CatalogUnits_List$Table, {
                    ctx: ctx,
                    units: units,
                    onUnitOpen: onUnitOpen
                  }), React.createElement(Optional.make, {
                  show: match[0],
                  children: React.createElement(CatalogUnits_List$Form, {
                        mode: "New",
                        unit: undefined,
                        type_: type_,
                        onClose: onClose,
                        onCreate: onCreate,
                        onUpdate: onUpdate,
                        onDelete: onDelete
                      })
                }), tmp);
}

var make = CatalogUnits_List;

export {
  make ,
}
/* addUnit' Not a pure module */
