import React from 'react'

import { showTime, isSameJsDay } from 'planado/utils/time/index.js'
import { highlightWorker, removeWorkerHighlight } from 'planado/map_commands'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import * as styles from './styles.module.css'

const Teams = ({ names }) => {
  if (names.length > 0) {
    return <div className="teams">{names.join(', ')}</div>
  } else {
    return <div />
  }
}

class FocusedWorker extends React.Component {
  render() {
    const { worker, location, onClose, ctx } = this.props

    if (worker === null) {
      return <div />
    } else {
      const { effectiveAt, batteryLevel } = location

      return (
        <div className={`${styles.focusedWorker} ${styles.focusedEntity}`}>
          <div className={styles.dismiss} onClick={onClose}>
            &times;
          </div>
          <h3>{worker.name}</h3>
          <Teams names={worker.teamNames} />
          <div className={styles.lastLocation}>
            {ctx.t('js.map.last_location', {
              at: showTime(new Date(effectiveAt), 'datetime', ctx)
            })}
          </div>
          <div className={styles.batteryLevel}>
            {ctx.t('js.map.charge_level', {
              level: Math.round(batteryLevel * 100)
            })}
          </div>
        </div>
      )
    }
  }

  // Engine interaction

  highlight() {
    let { worker, location, currentTime } = this.props

    if (worker !== null && isSameJsDay(location.effectiveAt, currentTime)) {
      this.props.engineChannel.push(highlightWorker(worker, location))
    }
  }

  removeHighlight(prevProps) {
    let { worker, location } = prevProps

    if (worker !== null) {
      this.props.engineChannel.push(removeWorkerHighlight(worker, location))
    }
  }

  componentDidMount() {
    this.highlight()
  }

  componentWillUnmount() {
    this.removeHighlight(this.props)
  }

  componentDidUpdate(prevProps) {
    this.removeHighlight(prevProps)
    this.highlight()
  }
}

export default withContext(FocusedWorker)
