

import * as Fun from "../../../../../libraries/Fun.mjs";
import * as Utils from "../../../../../utils/Utils.mjs";

var mustBeFilled$p = Utils.Translations.t("common.messages.must_be_filled");

var maxFileSizeMb = 1024.0 * 1024.0 * 7.0;

var maxBase64FileSize = maxFileSizeMb * (4.0 / 3.0) | 0;

function validate(ctx, job) {
  var match = job.clientSite;
  var exit = 0;
  if (match === undefined) {
    return ;
  }
  if (match.TAG === "Client") {
    var match$1 = match._0;
    if (match$1.TAG === "Organization") {
      var match$2 = match$1._0;
      if (match$2.TAG === "Edit") {
        if (match$2._0.name !== undefined) {
          return ;
        }
        exit = 1;
      } else {
        if (match$2._0.name !== undefined) {
          return ;
        }
        exit = 1;
      }
    } else {
      var match$3 = match$1._0;
      if (match$3.TAG === "Edit") {
        var match$4 = match$3._0;
        if (match$4.firstName !== undefined) {
          return ;
        }
        if (match$4.lastName !== undefined) {
          return ;
        }
        exit = 2;
      } else {
        var match$5 = match$3._0;
        if (match$5.firstName !== undefined) {
          return ;
        }
        if (match$5.lastName !== undefined) {
          return ;
        }
        exit = 2;
      }
    }
  } else {
    var match$6 = match._0;
    if (match$6.TAG === "Edit") {
      if (match$6._0.name !== undefined) {
        return ;
      }
      exit = 3;
    } else {
      if (match$6._0.name !== undefined) {
        return ;
      }
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        return {
                hd: [
                  "organization_name",
                  mustBeFilled$p(ctx)
                ],
                tl: /* [] */0
              };
    case 2 :
        return {
                hd: [
                  "individual_name",
                  mustBeFilled$p(ctx)
                ],
                tl: /* [] */0
              };
    case 3 :
        return {
                hd: [
                  "site_name",
                  mustBeFilled$p(ctx)
                ],
                tl: /* [] */0
              };
    
  }
}

var ClientSite = {
  validate: validate
};

function validate$1(customFields, wire) {
  var errors = customFields.map(function (__x) {
        var ctx = wire.ctx;
        var value = __x.value;
        var mustBeFilled = [mustBeFilled$p(ctx)];
        var isFilled = function (value) {
          if (value !== undefined) {
            return [];
          } else {
            return mustBeFilled;
          }
        };
        if (!(__x.required && !__x.readOnly)) {
          return [];
        }
        switch (value.TAG) {
          case "Button" :
              return [];
          case "Currency" :
              return isFilled(value._0.value);
          case "DateTime" :
              var dateTime = value._0;
              var match = Fun.both(dateTime.date, dateTime.time);
              if (match !== undefined) {
                return [];
              } else {
                return mustBeFilled;
              }
          case "Dictionary" :
              return isFilled(value._0.value);
          case "File" :
              if (value._0.length === 0) {
                return mustBeFilled;
              } else {
                return [];
              }
          case "Input" :
              if (value._0.VAL === "") {
                return mustBeFilled;
              } else {
                return [];
              }
          case "Link" :
              if (value._0 === "") {
                return mustBeFilled;
              } else {
                return [];
              }
          case "Date" :
          case "Time" :
              return isFilled(value._0);
          
        }
      });
  var hasErrors = errors.some(function (e) {
        return e.length > 0;
      });
  return [
          hasErrors,
          errors
        ];
}

var CustomFields = {
  validate: validate$1
};

export {
  maxBase64FileSize ,
  ClientSite ,
  CustomFields ,
}
/* mustBeFilled' Not a pure module */
