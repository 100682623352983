

import * as Fun from "../../Fun.mjs";
import * as RCore from "../../RCore.mjs";
import * as Core__Int from "@rescript/core/lib/es6/src/Core__Int.mjs";
import * as Core__Float from "@rescript/core/lib/es6/src/Core__Float.mjs";
import * as Shared_Lib_String from "../../../shared/lib/Shared_Lib_String.mjs";
import * as Liquid_VariableLookup from "./Liquid_VariableLookup.mjs";

var singleQuotedString = new RegExp("^\\s*'((?:[\\uD800-\\uDBFF][\\uDC00-\\uDFFF]|[^\\uD800-\\uDFFF])*)'\\s*$");

var doubleQuotedString = new RegExp("^\\s*\"((?:[\\uD800-\\uDBFF][\\uDC00-\\uDFFF]|[^\\uD800-\\uDFFF])*)\"\\s*$");

var integers = new RegExp("^\\s*(-?\\d+)\\s*$");

var floats = new RegExp("^\\s*(-?\\d[.0-9]+)\\s*$");

var ranges = new RegExp("^\\s*\\(\\s*(\\S+)\\s*\\.\\.\\s*(\\S+)\\s*\\)\\s*$");

function parse(markup) {
  if (markup === "") {
    return ;
  }
  var matches = Shared_Lib_String.matchh(markup, singleQuotedString);
  if (matches !== undefined) {
    return RCore.$$Option.map(matches[1], (function (string) {
                  return {
                          TAG: "String",
                          _0: string
                        };
                }));
  }
  var matches$1 = Shared_Lib_String.matchh(markup, doubleQuotedString);
  if (matches$1 !== undefined) {
    return RCore.$$Option.map(matches$1[1], (function (string) {
                  return {
                          TAG: "String",
                          _0: string
                        };
                }));
  }
  var matches$2 = Shared_Lib_String.matchh(markup, integers);
  if (matches$2 !== undefined) {
    return RCore.$$Option.map(RCore.$$Option.flatMap(matches$2[1], (function (x) {
                      return Core__Int.fromString(x, undefined);
                    })), (function (integer) {
                  return {
                          TAG: "Integer",
                          _0: integer
                        };
                }));
  }
  var matches$3 = Shared_Lib_String.matchh(markup, ranges);
  if (matches$3 !== undefined) {
    var match = Fun.both(matches$3[1], matches$3[2]);
    if (match === undefined) {
      return ;
    }
    var startExpression = parse(match[0]);
    var endExpression = parse(match[1]);
    var match$1 = Fun.both(startExpression, endExpression);
    if (match$1 !== undefined) {
      return {
              TAG: "Range",
              _0: match$1[0],
              _1: match$1[1]
            };
    } else {
      return ;
    }
  }
  var matches$4 = Shared_Lib_String.matchh(markup, floats);
  if (matches$4 !== undefined) {
    return RCore.$$Option.map(RCore.$$Option.flatMap(matches$4[1], Core__Float.fromString), (function ($$float) {
                  return {
                          TAG: "Float",
                          _0: $$float
                        };
                }));
  }
  switch (markup) {
    case "blank" :
    case "empty" :
        return {
                TAG: "String",
                _0: ""
              };
    case "false" :
        return "False";
    case "" :
    case "nil" :
    case "null" :
        return "Null";
    case "true" :
        return "True";
    default:
      var variable = Liquid_VariableLookup.make(markup);
      if (variable !== undefined) {
        return {
                TAG: "Variable",
                _0: variable
              };
      } else {
        return ;
      }
  }
}

export {
  parse ,
}
/* singleQuotedString Not a pure module */
