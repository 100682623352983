

import * as Uuid from "../bindings/uuid/Uuid.mjs";
import * as RCore from "../libraries/RCore.mjs";
import * as EncodeJson from "../libraries/EncodeJson.mjs";
import * as JsonDecode from "../libraries/JsonDecode.mjs";
import * as Types_Spatial from "./Types_Spatial.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              addressUuid: field.optional("addressUuid", Uuid.decode),
              formatted: field.required("formatted", JsonDecode.option(JsonDecode.string)),
              streetNumber: field.required("streetNumber", JsonDecode.option(JsonDecode.string)),
              route: field.required("route", JsonDecode.option(JsonDecode.string)),
              sublocality: field.required("sublocality", JsonDecode.option(JsonDecode.string)),
              locality: field.required("locality", JsonDecode.option(JsonDecode.string)),
              subadministrativeArea: field.required("subadministrativeArea", JsonDecode.option(JsonDecode.string)),
              administrativeArea: field.required("administrativeArea", JsonDecode.option(JsonDecode.string)),
              country: field.required("country", JsonDecode.option(JsonDecode.string)),
              postalCode: field.required("postalCode", JsonDecode.option(JsonDecode.string)),
              source: field.required("source", JsonDecode.option(JsonDecode.string)),
              extId: field.required("extId", JsonDecode.option(JsonDecode.string)),
              geolocation: field.required("geolocation", JsonDecode.option(Types_Spatial.$$Geolocation.decode)),
              geolocationResolved: field.required("geolocationResolved", JsonDecode.bool),
              formattedFull: field.required("formattedFull", JsonDecode.option(JsonDecode.string))
            };
    });

function encodeGeolocation(geolocation) {
  return EncodeJson.object([
              [
                "latitude",
                EncodeJson.string(Types_Spatial.Latitude.toString(geolocation.latitude))
              ],
              [
                "longitude",
                EncodeJson.string(Types_Spatial.Longitude.toString(geolocation.longitude))
              ]
            ]);
}

var empty = EncodeJson.object([
      [
        "streetNumber",
        null
      ],
      [
        "route",
        null
      ],
      [
        "sublocality",
        null
      ],
      [
        "locality",
        null
      ],
      [
        "subadministrativeArea",
        null
      ],
      [
        "administrativeArea",
        null
      ],
      [
        "postalCode",
        null
      ],
      [
        "extId",
        null
      ],
      [
        "geolocation",
        null
      ],
      [
        "geolocationResolved",
        EncodeJson.bool(false)
      ],
      [
        "formatted",
        null
      ],
      [
        "formattedFull",
        null
      ],
      [
        "country",
        null
      ],
      [
        "source",
        null
      ]
    ]);

function encode(address) {
  if (address !== undefined) {
    return EncodeJson.object([
                [
                  "streetNumber",
                  EncodeJson.optionString(address.streetNumber)
                ],
                [
                  "route",
                  EncodeJson.optionString(address.route)
                ],
                [
                  "sublocality",
                  EncodeJson.optionString(address.sublocality)
                ],
                [
                  "locality",
                  EncodeJson.optionString(address.locality)
                ],
                [
                  "subadministrativeArea",
                  EncodeJson.optionString(address.subadministrativeArea)
                ],
                [
                  "administrativeArea",
                  EncodeJson.optionString(address.administrativeArea)
                ],
                [
                  "postalCode",
                  EncodeJson.optionString(address.postalCode)
                ],
                [
                  "extId",
                  EncodeJson.optionString(address.extId)
                ],
                [
                  "geolocation",
                  EncodeJson.option(encodeGeolocation)(address.geolocation)
                ],
                [
                  "geolocationResolved",
                  EncodeJson.bool(address.geolocationResolved)
                ],
                [
                  "formatted",
                  EncodeJson.optionString(address.formatted)
                ],
                [
                  "formattedFull",
                  EncodeJson.optionString(address.formattedFull)
                ],
                [
                  "country",
                  EncodeJson.optionString(address.country)
                ],
                [
                  "source",
                  EncodeJson.optionString(address.source)
                ]
              ]);
  } else {
    return empty;
  }
}

function toInputType(a) {
  return {
          streetNumber: a.streetNumber,
          route: a.route,
          sublocality: a.sublocality,
          locality: a.locality,
          subadministrativeArea: a.subadministrativeArea,
          administrativeArea: a.administrativeArea,
          postalCode: a.postalCode,
          extId: a.extId,
          formattedFull: a.formattedFull,
          country: a.country,
          source: a.source,
          geolocationResolved: a.geolocationResolved,
          formatted: RCore.$$Option.getOr(a.formatted, ""),
          geolocation: a.geolocation,
          qcGeo: undefined
        };
}

function fromInputType(a) {
  return {
          addressUuid: undefined,
          formatted: a.formatted,
          streetNumber: a.streetNumber,
          route: a.route,
          sublocality: a.sublocality,
          locality: a.locality,
          subadministrativeArea: a.subadministrativeArea,
          administrativeArea: a.administrativeArea,
          country: a.country,
          postalCode: a.postalCode,
          source: a.source,
          extId: a.extId,
          geolocation: a.geolocation,
          geolocationResolved: a.geolocationResolved,
          formattedFull: a.formattedFull
        };
}

export {
  decode ,
  encode ,
  toInputType ,
  fromInputType ,
}
/* decode Not a pure module */
