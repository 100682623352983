import React from 'react'
import { isoStringToDateString } from 'planado/utils/time/index.js'
import { toMomentJSFormat } from 'planado/utils/time/helpers'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import { DatePicker } from 'planado/components/input/datetime'
import momentTimezone from 'moment-timezone'
import * as styles from './styles.module.css'

const limits = {
  pastDaysCount: 365,
  futureDaysCount: 0
}

const HistoryDatePicker = withContext(
  ({ selectedDateISO, onDateChange, todaySelected, readOnly, ctx }) => {
    let [date, setDate] = React.useState(
      isoStringToDateString(selectedDateISO, ctx)
    )

    const changeDate = (date) => {
      setDate(date)

      const isoDate = momentTimezone(date, toMomentJSFormat(ctx.localizator.datetime.formats.date)).format()

      onDateChange(isoDate)
    }

    return (
      <div className={styles.mapDateSelector}>
        <DatePicker
          id={'LocationsDatePicker'}
          date={momentTimezone(selectedDateISO)}
          dateToDisplay={todaySelected ? `${ctx.t('js.localizator.time.today')}, ${date}` : date}
          onChange={changeDate}
          calendarPlacement={'bottom'}
          disabled={readOnly}
          disableTypingOnFocus={true}
          errors={[]}
          limits={limits}
          focused={true}
          showClearButton={false}
        />
      </div>
    )
  }
)

export default HistoryDatePicker
