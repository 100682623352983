import moment from 'moment-timezone'
import { featureFlag } from 'rscrpt/context/Context.mjs'

const scales = (scale, ctx) => {
  if (scale === 'week') {
    return {
      duration: moment.duration({ days: 5 }),
      markIntervals: {
        minor: moment.duration({ hours: 1 }),
        major: moment.duration({ hours: 2 }),
        label: moment.duration({ hours: 4 }),
      },
    }
  } else if (featureFlag('schedule-zoom-day/2', ctx.features)) {
    return {
      duration: moment.duration({ hours: 12 }),
      markIntervals: {
        major: moment.duration({ minutes: 30 }),
        label: moment.duration({ hours: 1 }),
      },
    }
  } else {
    return {
      duration: moment.duration({ days: 1 }),
      markIntervals: {
        major: moment.duration({ hours: 1 }),
        label: moment.duration({ hours: 2 }),
      },
    }
  }
}

export const chartDuration = moment.duration({ days: 3 })

export const durationInHours = (scale, ctx) =>
  scales(scale, ctx).duration.asHours()

export const scaleHuge = (scale) => scale === 'week'

export const scaleDuration = (scale, ctx) => scales(scale, ctx).duration

export const scaleMarkIntervals = (scale, ctx) =>
  scales(scale, ctx).markIntervals
