

import * as El from "../../../libraries/El.mjs";
import * as Types from "../../../types/Types.mjs";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Filters_ButtonModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/Filters/Filters_Button.module.css";

var styles = Filters_ButtonModuleCss;

function Filters_Button(Props) {
  var onClickOpt = Props.onClick;
  var activeOpt = Props.active;
  var classNameOpt = Props.className;
  var id = Props.id;
  var disabledOpt = Props.disabled;
  var children = Props.children;
  var onClick = onClickOpt !== undefined ? onClickOpt : (function (prim) {
        
      });
  var active = activeOpt !== undefined ? activeOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var buttonRef = Types.ReactRef.use();
  var className$1 = El.Cn.concat(El.Cn.concat(styles.button, active ? styles.active : ""), className);
  var tmp = {
    ref: buttonRef,
    className: className$1,
    disabled: disabled,
    type: "button",
    onClick: onClick
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  return React.createElement("button", tmp, children);
}

var make = Filters_Button;

export {
  make ,
}
/* styles Not a pure module */
