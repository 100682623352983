

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Types from "../../../types/Types.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Billing_Types from "./Billing_Types.mjs";

var standardType_id = Types.Id.fromInt(4);

var standardType_name = Billing_Types.PlanName.fromString("Standard");

var standardType_features = {
  flags: [
    "teams",
    "territories"
  ],
  smsPerSeat: 100,
  seatsLimit: undefined
};

var standardType = {
  id: standardType_id,
  name: standardType_name,
  features: standardType_features
};

var standardPlan_uuid = Uuid.fromString("381a024e-1c1c-4523-8484-270d994f6839");

var standardPlan_name = Billing_Types.PlanName.fromString("Standard-3000");

var standardPlan_typeId = Types.Id.fromInt(4);

var standardPlan_priceScheme = {
  TAG: "Flat",
  _0: {
    amount: 12.0,
    currency: "usd"
  }
};

var standardPlan_features = standardType_features;

var standardPlan = {
  uuid: standardPlan_uuid,
  name: standardPlan_name,
  typeId: standardPlan_typeId,
  monthsCycle: "one",
  changeable: true,
  priceScheme: standardPlan_priceScheme,
  features: standardPlan_features,
  paddlePlanId: undefined
};

var proType_id = Types.Id.fromInt(14);

var proType_name = Billing_Types.PlanName.fromString("Pro+");

var proType_features = {
  flags: [
    "api",
    "amocrm",
    "bitrix24",
    "shifts",
    "teams",
    "territories"
  ],
  smsPerSeat: 500,
  seatsLimit: undefined
};

var proType = {
  id: proType_id,
  name: proType_name,
  features: proType_features
};

var proPlan_uuid = Uuid.fromString("3ec3b3d3-624e-48ed-b882-01643d3a0b8c");

var proPlan_name = Billing_Types.PlanName.fromString("Pro+");

var proPlan_typeId = Types.Id.fromInt(14);

var proPlan_priceScheme = {
  TAG: "Flat",
  _0: {
    amount: 1800.0,
    currency: "rub"
  }
};

var proPlan_features = proType_features;

var proPlan = {
  uuid: proPlan_uuid,
  name: proPlan_name,
  typeId: proPlan_typeId,
  monthsCycle: "one",
  changeable: true,
  priceScheme: proPlan_priceScheme,
  features: proPlan_features,
  paddlePlanId: undefined
};

var planTypes = [
  standardType,
  proType
];

var plans = [
  standardPlan,
  proPlan
];

var order_planTypeId = standardType_id;

var order_organizationName = "Cool job, awesome results LTD";

var order_billingAddress = "Illinois, Chicago, Lake Shore Drive 175";

var order = {
  planTypeId: order_planTypeId,
  paidSeats: 42,
  freeSeats: 0,
  monthsCycle: "one",
  organizationName: order_organizationName,
  billingAddress: order_billingAddress,
  paymentMethod: "Invoice",
  currency: "usd"
};

var state_subscriptionOrder = order;

var state_subscriptionPlan = standardPlan;

var state_subscription = {
  uuid: Uuid.fromString("13c0a6ea-6491-4f62-8230-9fab4d25c179"),
  nextBilling: "2000-01-01T00:00:00+00:00",
  billingDay: 1,
  lastDayOfMonth: false,
  paidTill: "2000-01-01T00:00:00+00:00",
  nextBill: "2000-01-01T00:00:00+00:00",
  nextBillDue: "2200-01-01T00:00:00+00:00",
  paidSeats: 42,
  freeSeats: 0
};

var state_plan = standardPlan;

var state_unpaidBill = {
  uuid: Uuid.fromString("4536c676-6059-4ab0-9f5c-019139095873"),
  no: "789123456789",
  totalAmount: {
    amount: 12.0,
    currency: "usd"
  },
  periodStart: "2000-01-01T00:00:00+00:00",
  periodEnd: "2200-01-01T00:00:00+00:00",
  due: "2200-01-01T00:00:00+00:00",
  status: "Due"
};

var state_latestPayments = [
  {
    uuid: Uuid.fromString("a05b67eb-4491-44ab-a416-23d8dacc001c"),
    status: "Succeeded",
    paymentScreenFileUuid: Caml_option.some(Uuid.fromString("f67ba4b7-2996-43cb-b1aa-e2e93c5cb933")),
    billUuid: Caml_option.some(Uuid.fromString("007a11c2-aa17-4254-9428-ebec4968c5b0")),
    billNo: "27322633",
    billScreenFileUuid: Caml_option.some(Uuid.fromString("f67ba4b7-2996-43cb-b1aa-e2e93c5cb933")),
    billPaymentAccount: {
      accountType: "Paddle"
    },
    invoiceUuid: Caml_option.some(Uuid.fromString("f67ba4b7-2996-43cb-b1aa-e2e93c5cb933")),
    invoiceNo: "789123456789",
    invoiceScreenFileUuid: Caml_option.some(Uuid.fromString("f67ba4b7-2996-43cb-b1aa-e2e93c5cb933")),
    invoicePaymentAccount: {
      accountType: "Paddle"
    },
    currency: "usd",
    amount: 12.0,
    createdAt: "1999-12-01T00:00:00+00:00"
  },
  {
    uuid: Uuid.fromString("55d44238-f936-40c6-9f54-49efa0894a79"),
    status: "Prepared",
    paymentScreenFileUuid: Caml_option.some(Uuid.fromString("f67ba4b7-2996-43cb-b1aa-e2e93c5cb933")),
    billUuid: Caml_option.some(Uuid.fromString("f67ba4b7-2996-43cb-b1aa-e2e93c5cb933")),
    billNo: "27322618",
    billScreenFileUuid: Caml_option.some(Uuid.fromString("f67ba4b7-2996-43cb-b1aa-e2e93c5cb933")),
    billPaymentAccount: {
      accountType: "BankRub"
    },
    invoiceUuid: Caml_option.some(Uuid.fromString("f67ba4b7-2996-43cb-b1aa-e2e93c5cb933")),
    invoiceNo: "789123456789",
    invoiceScreenFileUuid: Caml_option.some(Uuid.fromString("f67ba4b7-2996-43cb-b1aa-e2e93c5cb933")),
    invoicePaymentAccount: {
      accountType: "BankRub"
    },
    currency: "rub",
    amount: 1800.0,
    createdAt: "1999-11-01T00:00:00+00:00"
  }
];

var state_availablePaymentMethods = [
  "Invoice",
  "Card"
];

var state = {
  availablePlans: plans,
  planTypes: planTypes,
  subscriptionOrder: state_subscriptionOrder,
  subscriptionPlan: state_subscriptionPlan,
  subscription: state_subscription,
  order: order,
  plan: state_plan,
  currentSeats: 42,
  enterprise: undefined,
  free: undefined,
  currentPlanType: standardType,
  unpaidBill: state_unpaidBill,
  latestPayments: state_latestPayments,
  canChangePlan: true,
  card: undefined,
  availablePaymentMethods: state_availablePaymentMethods,
  cryptoChillSettings: undefined,
  paddleSettings: undefined,
  paddleSubscription: undefined
};

export {
  standardType ,
  standardPlan ,
  proType ,
  proPlan ,
  planTypes ,
  plans ,
  order ,
  state ,
}
/* standardType Not a pure module */
