

import * as React from "react";
import * as Optional from "../../../../common/Optional.mjs";
import * as Schedule_StateHooks from "../../../lib/state/Schedule_StateHooks.mjs";
import * as Schedule_Header_Filter from "./filter/Schedule_Header_Filter.mjs";
import * as Schedule_Header_Settings from "./settings/Schedule_Header_Settings.mjs";
import * as Schedule_Header_RightModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/header/right/Schedule_Header_Right.module.css";

var styles = Schedule_Header_RightModuleCss;

function Schedule_Header_Right(Props) {
  var isNewSchedule = Schedule_StateHooks.IsNew.use();
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: styles.separator
                }), React.createElement(Optional.make, {
                  show: isNewSchedule,
                  children: React.createElement(Schedule_Header_Filter.make, {})
                }), React.createElement(Schedule_Header_Settings.make, {}));
}

var make = Schedule_Header_Right;

export {
  make ,
}
/* styles Not a pure module */
