import React, { Component } from 'react'
import classNames from 'classnames'
import { debounce } from 'planado/utils/index.js'
import Autocomplete from 'planado/components/input/autocomplete'
import { withContext } from 'planado/utils/contextConsumer.jsx'

class SearchInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      query: '',
      fetching: true
    }

    this.getPossibleValueWithDelay = debounce(
      props.getPossibleValue,
      props.delay
    )
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      fetching: nextProps.fetching
    })
  }

  changeHandler(_, value) {
    this.setState({
      query: value,
      fetching: true
    })

    this.getPossibleValueWithDelay(value)
  }

  menuMarkup = (items, value, props) => {
    const { ctx, minLength } = this.props

    let menuBlock
    switch (true) {
      case minLength !== -1 && value.length < minLength: {
        const lengthRemaining = minLength - value.length

        const lengthRemainingEl = ctx.t('js.search_input.format_too_short', {
          count: lengthRemaining
        })

        menuBlock = (
          <div className="search-input__item search-input__item_info">
            {lengthRemainingEl}
          </div>
        )

        break
      }
      case this.state.fetching: {
        menuBlock = (
          <div className="search-input__item search-input__item_message">
            {ctx.t('js.search_input.search')}
          </div>
        )

        break
      }
      case items.length === 0: {
        menuBlock = (
          <div className="search-input__item search-input__item_message">
            {ctx.t('js.search_input.format_no_matches')}
          </div>
        )

        break
      }
      default: {
        menuBlock = items
      }
    }

    return (
      <div className="search-input__menu" {...props}>
        {menuBlock}
      </div>
    )
  }

  itemMarkup = (item, isHighlighted, props) => {
    const { itemMarkup } = this.props

    if (typeof itemMarkup === 'function') {
      return itemMarkup(item, isHighlighted, props)
    } else {
      const classes = classNames({
        'search-input__item': true,
        'search-input__item_active': isHighlighted
      })

      return (
        <div className={classes} {...props}>
          {item.name}
        </div>
      )
    }
  }

  render() {
    const {
      theme,
      possibleValue,
      selectValue,
      placeholder,
      minLength
    } = this.props
    const { query, fetching } = this.state
    const inputProps = {
      type: 'text',
      placeholder: placeholder,
      className: 'pd-form-control'
    }

    const classes = classNames({
      'search-input': true,
      'search-input_hidden': query.length < 1,
      [`search-input_${theme}`]: true
    })

    return (
      <Autocomplete
        wrapperProps={{ className: classes }}
        wrapperStyle={{}}
        inputProps={inputProps}
        renderMenu={this.menuMarkup}
        renderItem={this.itemMarkup}
        value={query}
        items={fetching || query.length < minLength ? [] : possibleValue}
        getItemValue={item => `${item.name} ${item.address}`}
        onChange={this.changeHandler.bind(this)}
        onSelect={selectValue}
      />
    )
  }
}

SearchInput.defaultProps = {
  placeholder: '',
  minLength: -1,
  delay: 0,
  fetching: false,
  theme: 'light'
}

export default withContext(SearchInput)
