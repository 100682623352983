import { connect } from 'react-redux'

import { isSameJsDay } from 'planado/utils/time/index.js'
import {
  showWorkersWithStaleLocation,
  hideWorkersWithStaleLocation
} from 'planado/actions/map'
import WorkerList from 'planado/components/map/worker_list'

const compareLastName = (w1, w2) => {
  if (w1.lastName > w2.lastName) {
    return 1
  } else if (w1.lastName < w2.lastName) {
    return -1
  } else if (w1.firstName > w2.firstName) {
    return 1
  } else if (w1.firstName < w2.firstName) {
    return -1
  } else if (w1.id > w2.id) {
    return 1
  } else {
    return -1
  }
}

const mapStateToProps = ({
  workers,
  latestLocations,
  currentTime,
  ...state
}) => {
  const ws = Object.values(workers)
  const workersWithLocation = ws
    .filter(
      w =>
        w.uuid in latestLocations &&
        isSameJsDay(latestLocations[w.uuid].effectiveAt, currentTime)
    )
    .sort(compareLastName)

  const workersWithStaleLocation = ws
    .filter(
      w =>
        w.uuid in latestLocations &&
        !isSameJsDay(latestLocations[w.uuid].effectiveAt, currentTime)
    )
    .sort(compareLastName)

  return {
    currentTime: currentTime,
    workersWithLocation: workersWithLocation,
    workersWithStaleLocation: workersWithStaleLocation,
    showWorkersWithStaleLocation: state.showWorkersWithStaleLocation,
    locationsExist: Object.keys(latestLocations).length > 0
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onWorkersWOLocationToggle: open => {
      if (open) {
        return dispatch(hideWorkersWithStaleLocation())
      } else {
        return dispatch(showWorkersWithStaleLocation())
      }
    }
  }
}

const WorkerListLink = connect(mapStateToProps, mapDispatchToProps)(WorkerList)

export default WorkerListLink
