

import * as RCore from "../libraries/RCore.mjs";
import * as Context_Lang from "./Context_Lang.mjs";
import * as Context_Types from "./Context_Types.mjs";

var enableFallbacks$p = (function(locale) {
    I18n.defaultLocale = locale;
    I18n.fallbacks = true;
  });

function enableFallbacks(lang) {
  enableFallbacks$p(Context_Lang.toString(lang));
}

function translate(x) {
  return I18n.t(x);
}

var featureFlag = Context_Types.Features.hasFlag;

function headerHeight(ctx) {
  var match = ctx.trial;
  var bannerHeight = match !== undefined ? (
      match.expiresIn !== undefined || ctx.dueBillUuid !== undefined ? 25 : 0
    ) : (
      ctx.dueBillUuid !== undefined ? 25 : 0
    );
  return 46 + bannerHeight | 0;
}

function toHumanSize(size) {
  return I18n.toHumanSize(size, {
              format: "%n %u"
            });
}

function productName(ctx) {
  var match = ctx.branding;
  if (match === undefined) {
    return I18n.t("common.product_name");
  }
  var name = match.brandName;
  if (name !== undefined) {
    return name;
  } else {
    return I18n.t("common.product_name");
  }
}

function isDev(ctx) {
  return ctx.env === "development";
}

function canManageSubscription(ctx) {
  if (ctx.owner && ctx.billing && !ctx.disposable) {
    return ctx.licenseSource === "internal";
  } else {
    return false;
  }
}

function authorized(ctx) {
  return RCore.$$Option.isSome(ctx.user);
}

function address(ctx) {
  return ctx.subdomain + "." + ctx.domain;
}

function translateWithOptions(prim0, prim1) {
  return I18n.t(prim0, prim1);
}

function toNumber(prim) {
  return I18n.toNumber(prim);
}

var Auth;

export {
  enableFallbacks ,
  translate ,
  translateWithOptions ,
  toNumber ,
  featureFlag ,
  headerHeight ,
  toHumanSize ,
  productName ,
  isDev ,
  canManageSubscription ,
  authorized ,
  address ,
  Auth ,
}
/* Context_Lang Not a pure module */
