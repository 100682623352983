

import * as El from "../../../../libraries/El.mjs";
import * as Icon from "../../../../Icon.mjs";
import * as Hooks from "../../../../libraries/hooks/Hooks.mjs";
import * as Panel from "../../../common/Panel/Panel.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../libraries/Locale.mjs";
import * as Tooltip from "../../../common/Tooltip/Tooltip.mjs";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DateTimePicker from "../../../inputs/DateTimePicker.mjs";
import * as MultipleAssignees from "../../../common/MultipleAssignees/MultipleAssignees.mjs";
import * as JobIndexBulkButtonsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/index/header/JobIndexBulkButtons.module.css";

var pick$p = Utils.Translations.tr("js.jobs.index.bulk.label");

var back$p = Utils.Translations.tr("js.jobs.index.bulk.back");

var cancel$p = Utils.Translations.tr("js.jobs.index.bulk.cancel");

var delete$p = Utils.Translations.tr("js.jobs.index.bulk.delete");

var deleteCount$p = Utils.Translations.txr("js.jobs.index.bulk.delete_count");

var assign$p = Utils.Translations.tr("js.jobs.index.bulk.assign");

var assignCount$p = Utils.Translations.txr("js.jobs.index.bulk.assign_count");

var schedule$p = Utils.Translations.tr("js.jobs.index.bulk.schedule");

var scheduleCount$p = Utils.Translations.txr("js.jobs.index.bulk.schedule_count");

var scheduleLabel$p = Utils.Translations.t("js.jobs.index.bulk.schedule_label");

var tooltip$p = Utils.Translations.t("js.jobs.index.bulk.tooltip");

var submit$p = Utils.Translations.tr("js.jobs.index.bulk.submit");

var styles = JobIndexBulkButtonsModuleCss;

function JobIndexBulkButtons$Pick(Props) {
  var onClick = Props.onClick;
  var match = AppContext.useWire();
  return React.createElement("button", {
              className: El.Cn.concat(styles.button, styles.lastButton),
              onClick: (function (param) {
                  onClick();
                })
            }, pick$p(match.ctx));
}

var Pick = {
  make: JobIndexBulkButtons$Pick
};

function JobIndexBulkButtons$SubmitButtons(Props) {
  var onSubmit = Props.onSubmit;
  var onClose = Props.onClose;
  var deleteOpt = Props.delete;
  var $$delete = deleteOpt !== undefined ? deleteOpt : false;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var match$1 = $$delete ? [
      "btn btn-danger btn-32px",
      delete$p(ctx)
    ] : [
      "btn btn-primary btn-32px",
      submit$p(ctx)
    ];
  return React.createElement("div", {
              className: styles.submitButtons
            }, React.createElement("button", {
                  className: El.Cn.concat(match$1[0], styles.submitButton),
                  type: "button",
                  onClick: (function (param) {
                      onSubmit();
                    })
                }, match$1[1]), React.createElement("button", {
                  className: El.Cn.concat("btn btn-32px", styles.submitButton),
                  type: "button",
                  onClick: (function (param) {
                      onClose();
                    })
                }, cancel$p(ctx)));
}

function JobIndexBulkButtons$ButtonWithPopover(Props) {
  var buttonLabel = Props.buttonLabel;
  var buttonIcon = Props.buttonIcon;
  var showPopover = Props.showPopover;
  var setShowPopover = Props.setShowPopover;
  var disabled = Props.disabled;
  var popoverClassesOpt = Props.popoverClasses;
  var children = Props.children;
  var popoverClasses = popoverClassesOpt !== undefined ? popoverClassesOpt : "";
  var match = AppContext.useWire();
  var popover = React.useRef(null);
  var buttonId = Hooks.useId(undefined);
  var buttonCN = disabled ? El.Cn.concat(styles.button, styles.disabled) : styles.button;
  Hooks.useOnClickOutside(undefined, popover, (function () {
          setShowPopover(function (param) {
                return false;
              });
        }));
  return React.createElement(Tooltip.make, {
              elementRef: popover,
              position: "bottom",
              enabled: disabled,
              content: tooltip$p(match.ctx),
              children: React.createElement("div", {
                    ref: popover
                  }, React.createElement("button", {
                        className: buttonCN,
                        id: buttonId,
                        disabled: disabled,
                        onClick: (function (param) {
                            setShowPopover(function (prevState) {
                                  return !prevState;
                                });
                          })
                      }, React.createElement("i", {
                            className: Icon.style(undefined, undefined, buttonIcon)
                          }), buttonLabel), React.createElement(Panel.make, {
                        footer: match.footer,
                        show: showPopover,
                        domId: buttonId,
                        position: "bottomRight",
                        children: React.createElement("div", {
                              className: El.Cn.concat(styles.popover, popoverClasses)
                            }, children)
                      }))
            });
}

function JobIndexBulkButtons$Cancel(Props) {
  var onClick = Props.onClick;
  var match = AppContext.useWire();
  return React.createElement("button", {
              className: styles.button,
              onClick: (function (param) {
                  onClick();
                })
            }, back$p(match.ctx));
}

var Cancel = {
  make: JobIndexBulkButtons$Cancel
};

function JobIndexBulkButtons$Delete(Props) {
  var onSubmit = Props.onSubmit;
  var disabled = Props.disabled;
  var bulkCount = Props.bulkCount;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowPopover = match$1[1];
  return React.createElement(JobIndexBulkButtons$ButtonWithPopover, {
              buttonLabel: delete$p(ctx),
              buttonIcon: "trash",
              showPopover: match$1[0],
              setShowPopover: setShowPopover,
              disabled: disabled,
              popoverClasses: styles.deletePopover,
              children: null
            }, React.createElement("span", {
                  className: styles.headline
                }, deleteCount$p({
                      count: bulkCount
                    }, ctx)), React.createElement(JobIndexBulkButtons$SubmitButtons, {
                  onSubmit: onSubmit,
                  onClose: (function () {
                      setShowPopover(function (param) {
                            return false;
                          });
                    }),
                  delete: true
                }));
}

var Delete = {
  make: JobIndexBulkButtons$Delete
};

function JobIndexBulkButtons$Assign(Props) {
  var onSubmit = Props.onSubmit;
  var disabled = Props.disabled;
  var bulkCount = Props.bulkCount;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowPopover = match$1[1];
  var match$2 = React.useState(function () {
        return [];
      });
  var setAssignees = match$2[1];
  var assignees = match$2[0];
  return React.createElement(JobIndexBulkButtons$ButtonWithPopover, {
              buttonLabel: assign$p(ctx),
              buttonIcon: "user-edit",
              showPopover: match$1[0],
              setShowPopover: setShowPopover,
              disabled: disabled,
              popoverClasses: styles.assignPopover,
              children: null
            }, React.createElement("span", {
                  className: styles.headline
                }, assignCount$p({
                      count: bulkCount
                    }, ctx)), React.createElement(MultipleAssignees.make, {
                  selectedAssignees: assignees,
                  territoryUuid: undefined,
                  skillUuids: [],
                  availableSkills: [],
                  disabled: disabled,
                  onChange: (function (v) {
                      setAssignees(function (param) {
                            return v;
                          });
                    }),
                  openUp: false
                }), React.createElement(JobIndexBulkButtons$SubmitButtons, {
                  onSubmit: (function () {
                      onSubmit(assignees);
                    }),
                  onClose: (function () {
                      setShowPopover(function (param) {
                            return false;
                          });
                    })
                }));
}

var Assign = {
  make: JobIndexBulkButtons$Assign
};

function JobIndexBulkButtons$Schedule(Props) {
  var onSubmit = Props.onSubmit;
  var disabled = Props.disabled;
  var bulkCount = Props.bulkCount;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return false;
      });
  var setShowPopover = match[1];
  var match$1 = React.useState(function () {
        
      });
  var setScheduledAt = match$1[1];
  var scheduledAt = match$1[0];
  var pickerId = Hooks.useId(undefined);
  var onScheduledAtChanged = function (datetime) {
    var scheduledAt = RCore.$$Option.map(Locale.T.concatDateWithTime(datetime, wire), Locale.T.make);
    setScheduledAt(function (param) {
          return scheduledAt;
        });
    return true;
  };
  var toDateTime = function (v) {
    if (v !== undefined) {
      return Locale.T.toDateTime(Caml_option.valFromOption(v), wire);
    } else {
      return {
              date: undefined,
              time: undefined
            };
    }
  };
  var tmp = {
    id: pickerId,
    label: scheduleLabel$p(ctx),
    dateTime: toDateTime(scheduledAt),
    onChange: onScheduledAtChanged,
    calendarPlacement: "bottomRight",
    outlineIcons: true,
    wrapperClass: styles.datepicker
  };
  if (ctx.businessHours !== undefined) {
    tmp.businessHours = Caml_option.valFromOption(ctx.businessHours);
  }
  return React.createElement(JobIndexBulkButtons$ButtonWithPopover, {
              buttonLabel: schedule$p(ctx),
              buttonIcon: "calendar-alt",
              showPopover: match[0],
              setShowPopover: setShowPopover,
              disabled: disabled,
              popoverClasses: styles.schedulePopover,
              children: null
            }, React.createElement("span", {
                  className: styles.headline
                }, scheduleCount$p({
                      count: bulkCount
                    }, ctx)), React.createElement(DateTimePicker.make, tmp), React.createElement(JobIndexBulkButtons$SubmitButtons, {
                  onSubmit: (function () {
                      onSubmit(scheduledAt);
                    }),
                  onClose: (function () {
                      setShowPopover(function (param) {
                            return false;
                          });
                    })
                }));
}

var Schedule = {
  make: JobIndexBulkButtons$Schedule
};

export {
  Pick ,
  Cancel ,
  Delete ,
  Assign ,
  Schedule ,
}
/* pick' Not a pure module */
