

import * as El from "../libraries/El.mjs";
import * as Link from "../components/common/Link/Link.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../components/common/Optional.mjs";
import * as AppContext from "../context/AppContext.mjs";
import * as UI_PageTabsModuleCss from "/home/runner/work/planado/planado/client/rescript/ui/UI_PageTabs.module.css";

var styles = UI_PageTabsModuleCss;

function UI_PageTabs$Tab(Props) {
  var route = Props.route;
  var renderLabel = Props.renderLabel;
  var children = Props.children;
  var wire = AppContext.useWire();
  var isActive = Caml_obj.equal(wire.route.path, route);
  var labelContent = React.useMemo(Curry.__1(renderLabel));
  var label = isActive ? React.createElement("div", {
          className: El.Cn.concat(styles.label, styles.active)
        }, labelContent) : React.createElement(Link.make, {
          wire: wire,
          route: route,
          className: styles.label,
          children: labelContent
        });
  return React.createElement(React.Fragment, undefined, label, React.createElement(Optional.make, {
                  show: isActive,
                  children: React.createElement("div", {
                        className: styles.tab
                      }, children)
                }));
}

var Tab = {
  make: UI_PageTabs$Tab
};

function UI_PageTabs(Props) {
  var className = Props.className;
  var children = Props.children;
  return React.createElement("div", {
              className: El.Cn.concatOpt(styles.tabs, className)
            }, children);
}

var make = UI_PageTabs;

export {
  Tab ,
  make ,
}
/* styles Not a pure module */
