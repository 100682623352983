

import * as Types from "../../types/Types.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as Router from "../../Router.mjs";
import * as ApiRoute from "../../types/ApiRoute.mjs";
import * as PromiseF from "../PromiseF.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Webapi__Fetch from "rescript-webapi/lib/es6/src/Webapi/Webapi__Fetch.mjs";
import * as MessengerTypes from "../../components/messenger/MessengerTypes.mjs";
import * as Backend_Headers from "./Backend_Headers.mjs";
import * as Backend_HttpStatus from "./Backend_HttpStatus.mjs";

function showVersionMismatchMessage(wire) {
  var message = MessengerTypes.Message.make(undefined, "Danger", {
        TAG: "Text",
        _0: Utils.Translations.t("js.errors.api_version_mismatch")(wire.ctx)
      });
  wire.subscriptions.messenger.trigger({
        TAG: "Show",
        _0: message
      });
}

function read(redirectOnUnauthorizedStatusOpt, promise, wire) {
  var redirectOnUnauthorizedStatus = redirectOnUnauthorizedStatusOpt !== undefined ? redirectOnUnauthorizedStatusOpt : true;
  return PromiseF.fromJs(promise).then((function (response) {
                var status = Backend_HttpStatus.fromCode(response.status);
                if (!response.ok) {
                  if (status === "Gone") {
                    showVersionMismatchMessage(wire);
                    return Promise.resolve({
                                TAG: "Ok",
                                _0: undefined
                              });
                  } else {
                    if (status === "Unauthorized" && redirectOnUnauthorizedStatus) {
                      Router.redirectToLogin();
                    }
                    return PromiseF.map(PromiseF.fromJs(response.text()), (function (text) {
                                  return {
                                          TAG: "Error",
                                          _0: {
                                            TAG: "HTTPError",
                                            _0: [
                                              status,
                                              text
                                            ]
                                          }
                                        };
                                }));
                  }
                }
                if (typeof status !== "object") {
                  switch (status) {
                    case "Ok'" :
                        if (response.redirected) {
                          return Promise.resolve({
                                      TAG: "Ok",
                                      _0: undefined
                                    });
                        }
                        break;
                    case "NoContent" :
                    case "MovedPermanently" :
                    case "Found" :
                        return Promise.resolve({
                                    TAG: "Ok",
                                    _0: undefined
                                  });
                    default:
                      
                  }
                }
                return PromiseF.map(PromiseF.fromJs(response.json()), (function (json) {
                              return {
                                      TAG: "Ok",
                                      _0: json
                                    };
                            }));
              }), (function (error) {
                return Promise.resolve({
                            TAG: "Error",
                            _0: {
                              TAG: "NetworkError",
                              _0: error
                            }
                          });
              }));
}

function $$fetch$1(method_Opt, headers, body, silentOpt, isFormDataOpt, redirectOnUnauthorizedStatus, wire, path) {
  var method_ = method_Opt !== undefined ? method_Opt : "Get";
  var silent = silentOpt !== undefined ? silentOpt : false;
  var isFormData = isFormDataOpt !== undefined ? isFormDataOpt : false;
  var path$1 = ApiRoute.path(path);
  var request = Types.$$Request.make(path$1);
  wire.subscriptions.tracker.trigger({
        TAG: "Request",
        _0: {
          TAG: "Started",
          _0: request,
          _1: silent
        }
      });
  var h = headers !== undefined ? headers : (
      isFormData ? Backend_Headers.acceptJson : Backend_Headers.contentJson
    );
  var headers$1 = Backend_Headers.withOptionalHeaders(h, wire);
  var requestInit = Webapi__Fetch.RequestInit.make(method_, Caml_option.some(headers$1), body, undefined, undefined, undefined, "SameOrigin", undefined, undefined, undefined, undefined, undefined, undefined);
  var responsePromise = fetch(path$1, requestInit);
  return PromiseF.map(read(redirectOnUnauthorizedStatus, responsePromise, wire), (function (response) {
                wire.subscriptions.tracker.trigger({
                      TAG: "Request",
                      _0: {
                        TAG: "Finished",
                        _0: Types.$$Request.update("Received", request)
                      }
                    });
                return response;
              }));
}

export {
  showVersionMismatchMessage ,
  read ,
  $$fetch$1 as $$fetch,
}
/* Types Not a pure module */
