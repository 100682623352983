import { Make as makeSortableList } from 'rscrpt/ui/UI_SortableList.mjs'

const {
  make: SortableList,
  Item: { make: SortableListItem },
  Activator: { make: SortableListActivator },
} = makeSortableList({
  getId: (field) => field.fieldUuid,
})

export { SortableList, SortableListItem, SortableListActivator }
