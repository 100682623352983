

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as Hooks from "../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as BackgroundTask from "../../types/BackgroundTask.mjs";
import * as BackgroundBulkRow from "./BackgroundBulkRow.mjs";
import * as BackgroundTasksApi from "./BackgroundTasksApi.mjs";
import * as BackgroundExportRow from "./BackgroundExportRow.mjs";
import * as BackgroundTasksIndexModuleCss from "/home/runner/work/planado/planado/client/rescript/components/background_task/BackgroundTasksIndex.module.css";

var operations$p = Utils.Translations.tr("js.background_tasks.operations");

var finished$p = Utils.Translations.tr("js.background_tasks.finished");

var styles = BackgroundTasksIndexModuleCss;

function unfinished(task) {
  var match = task.status;
  if (typeof match === "object") {
    return false;
  }
  switch (match) {
    case "Waiting" :
    case "InProgress" :
        return true;
    case "Canceled" :
        return false;
    
  }
}

function successful(task) {
  var match = task.status;
  if (typeof match !== "object" || !match._0) {
    return false;
  } else {
    return true;
  }
}

function bulkDeleteInProgressTasks(tasks) {
  return tasks.some(function (t) {
              var match = t.type_;
              var match$1 = t.status;
              if (typeof match !== "object") {
                return false;
              }
              switch (match._0.TAG) {
                case "Delete" :
                    if (typeof match$1 === "object") {
                      return false;
                    }
                    switch (match$1) {
                      case "Waiting" :
                      case "InProgress" :
                          return true;
                      case "Canceled" :
                          return false;
                      
                    }
                case "Schedule" :
                case "AssignMultiple" :
                    return false;
                
              }
            });
}

function bulkUpdateTasks(tasks) {
  return RCore.$$Array.keep(tasks, (function (t) {
                var match = t.type_;
                if (typeof match !== "object") {
                  return false;
                }
                switch (match._0.TAG) {
                  case "Delete" :
                      return false;
                  case "Schedule" :
                  case "AssignMultiple" :
                      return true;
                  
                }
              }));
}

function BackgroundTasksIndex(Props) {
  var tasks = Props.tasks;
  var reloadBackgroundTasks = Props.reloadBackgroundTasks;
  var closeAllTasks = Props.closeAllTasks;
  var wire = Props.wire;
  var ctx = wire.ctx;
  var prevTasks = Hooks.usePrevious(tasks);
  React.useEffect((function () {
          if (tasks.length === 0) {
            return ;
          }
          if (BackgroundTask.noActiveTasks(tasks)) {
            var timeoutId = setTimeout((function () {
                    Backend.$$finally(BackgroundTasksApi.readAll(wire, tasks), reloadBackgroundTasks);
                  }), 4000);
            return (function () {
                      clearTimeout(timeoutId);
                    });
          }
          if (!BackgroundTask.haveUnfinishedTasks(tasks)) {
            return ;
          }
          var intervalId = setInterval((function () {
                  Backend.$$finally(BackgroundTasksApi.index(wire), reloadBackgroundTasks);
                }), 2000);
          return (function () {
                    clearInterval(intervalId);
                  });
        }), [tasks]);
  React.useEffect((function () {
          var match = wire.route.path;
          if (typeof match === "object" && match.NAME === "Job" && match.VAL === "Index") {
            var previousArray = RCore.$$Option.getOr(prevTasks, []);
            if (bulkDeleteInProgressTasks(previousArray) && !bulkDeleteInProgressTasks(tasks)) {
              wire.subscriptions.backgroundTasks.trigger("FinishedDeleteOperation");
            } else if (bulkUpdateTasks(tasks).some(function (t) {
                    return successful(t) ? RCore.$$Array.getBy(bulkUpdateTasks(previousArray), (function (p) {
                                    return unfinished(p) ? Caml_obj.equal(p.uuid, t.uuid) : false;
                                  })) !== undefined : false;
                  })) {
              wire.subscriptions.backgroundTasks.trigger("FinishedUpdateOperation");
            }
            
          }
          
        }), [
        wire.route,
        tasks
      ]);
  var renderHeader = !tasks.some(unfinished) && tasks.some(successful) ? finished$p(ctx) : operations$p(ctx);
  var markTasksRead = function () {
    closeAllTasks();
    Backend.$$finally(BackgroundTasksApi.readAll(wire, tasks), reloadBackgroundTasks);
  };
  var cancel = function (uuid) {
    Backend.$$finally(BackgroundTasksApi.cancel(wire, uuid), reloadBackgroundTasks);
  };
  var retry = function (uuid, removeUuids) {
    Backend.$$finally(BackgroundTasksApi.BulkOperation.retry(wire, uuid, removeUuids), reloadBackgroundTasks);
  };
  return React.createElement("div", {
              className: styles.container
            }, React.createElement("div", {
                  className: styles.header
                }, renderHeader, React.createElement("div", {
                      className: styles.closeContainer
                    }, React.createElement("span", {
                          className: styles.close,
                          onClick: (function (param) {
                              markTasksRead();
                            })
                        }))), React.createElement("div", {
                  className: styles.items
                }, tasks.map(function (t) {
                      var match = t.type_;
                      if (typeof match !== "object") {
                        return React.createElement(BackgroundExportRow.make, {
                                    task: t,
                                    cancel: cancel,
                                    key: Uuid.toString(t.uuid)
                                  });
                      } else {
                        return React.createElement(BackgroundBulkRow.make, {
                                    task: t,
                                    cancel: cancel,
                                    retry: retry,
                                    key: Uuid.toString(t.uuid)
                                  });
                      }
                    })));
}

var make = BackgroundTasksIndex;

export {
  make ,
}
/* operations' Not a pure module */
