

import * as Types from "../../../../../../../types/Types.mjs";
import * as React from "react";
import * as Tooltip from "../../../../../../common/Tooltip/Tooltip.mjs";
import * as Schedule_Types_Job from "../../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as WeekCalendar_JobModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_calendar/components/job/WeekCalendar_Job.module.css";

var styles = WeekCalendar_JobModuleCss;

function WeekCalendar_JobStatus(Props) {
  var job = Props.job;
  var statusRef = Types.ReactRef.use();
  var match = Schedule_Types_Job.ScheduledJob.status(job);
  var match$1 = Schedule_Types_Job.ScheduledJob.resolution(job);
  switch (match) {
    case "EnRoute" :
        return React.createElement("div", {
                    className: styles.enRoute
                  });
    case "Started" :
        return React.createElement("div", {
                    className: styles.started
                  });
    case "Suspended" :
        return React.createElement("div", {
                    className: styles.suspended
                  });
    case "Finished" :
        if (match$1 !== undefined) {
          return React.createElement(Tooltip.make, {
                      elementRef: statusRef,
                      position: "bottom",
                      content: match$1.name,
                      children: React.createElement("div", {
                            ref: statusRef,
                            className: match$1.successful ? styles.successful : styles.unsuccessful
                          })
                    });
        } else {
          return null;
        }
    default:
      return null;
  }
}

var make = WeekCalendar_JobStatus;

export {
  make ,
}
/* styles Not a pure module */
