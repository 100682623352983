

import * as Sortable from "@dnd-kit/sortable";
import * as Utilities from "@dnd-kit/utilities";

function Make(Value) {
  var SortingStrategy = {};
  var CollisionDetection = {};
  var Active = {};
  var Over = {};
  var DragEndEvent = {};
  var Sensor = {};
  var SensorDescriptor = {};
  var Modifier = {};
  var Types = {
    SortingStrategy: SortingStrategy,
    CollisionDetection: CollisionDetection,
    Active: Active,
    Over: Over,
    DragEndEvent: DragEndEvent,
    Sensor: Sensor,
    SensorDescriptor: SensorDescriptor,
    Modifier: Modifier
  };
  var Transform = {};
  var Transition = {};
  var transformToString = function (transform) {
    var transform$1 = Utilities.CSS.Transform.toString(transform);
    if (transform$1 !== undefined) {
      return transform$1.split("scale")[0];
    }
    
  };
  var Css = {
    Transform: Transform,
    Transition: Transition,
    transformToString: transformToString
  };
  var Algorithms = {};
  var Utilities$1 = {
    Css: Css,
    Algorithms: Algorithms
  };
  var Sensors = {};
  var Modifiers = {};
  var DndContext = {};
  var SortableContext = {};
  var UseSortableArgs = {};
  var UseSortableResult = {};
  var Hooks = {
    UseSortableArgs: UseSortableArgs,
    UseSortableResult: UseSortableResult
  };
  var Strategies = {};
  var arrayMove = function (oldIndex, newIndex, items) {
    return Sortable.arrayMove(items, oldIndex, newIndex);
  };
  var Sortable$1 = {
    SortableContext: SortableContext,
    Hooks: Hooks,
    Strategies: Strategies,
    arrayMove: arrayMove
  };
  return {
          Types: Types,
          SortingStrategy: SortingStrategy,
          CollisionDetection: CollisionDetection,
          Active: Active,
          Over: Over,
          DragEndEvent: DragEndEvent,
          Sensor: Sensor,
          SensorDescriptor: SensorDescriptor,
          Modifier: Modifier,
          Utilities: Utilities$1,
          Sensors: Sensors,
          Modifiers: Modifiers,
          DndContext: DndContext,
          Sortable: Sortable$1
        };
}

export {
  Make ,
}
/* @dnd-kit/sortable Not a pure module */
