

import * as RCore from "../libraries/RCore.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as Core__Float from "@rescript/core/lib/es6/src/Core__Float.mjs";

var Ms = {};

function fromInt(value) {
  return value;
}

function fromString(value) {
  return RCore.$$Option.map(Core__Float.fromString((function (__x) {
                      return Js_string.replace("px", "", __x);
                    })(value)), (function (prim) {
                return prim;
              }));
}

function toInt(value) {
  return value | 0;
}

function toString(value) {
  return value.toString() + "px";
}

function eq(a, b) {
  return a === b;
}

function notEqual(a, b) {
  return a !== b;
}

function equalOrLessThan(a, b) {
  return a <= b;
}

function equalOrMoreThan(a, b) {
  return a >= b;
}

function moreThan(a, b) {
  return a > b;
}

function lessThan(a, b) {
  return a < b;
}

function minus(a, b) {
  return a - b;
}

function plus(a, b) {
  return a + b;
}

function multiply(a, b) {
  return a * b;
}

function divide(a, b) {
  return a / b;
}

function cmp(a, b) {
  if (Caml_obj.lessthan(a, b)) {
    return -1;
  } else if (Caml_obj.greaterthan(a, b)) {
    return 1;
  } else {
    return 0;
  }
}

function inverse(x) {
  return -1.0 * x;
}

function multiplyWithInt(px, n) {
  return px * n;
}

function multiplyWithFloat(px, n) {
  return px * n;
}

function divideByInt(px, n) {
  return px / n;
}

function divideByFloat(px, n) {
  return px / n;
}

function max(a, b) {
  return Math.max(a, b);
}

function min(a, b) {
  return Math.min(a, b);
}

function min3(a, b, c) {
  return Math.min(a, b, c);
}

function floor(a) {
  return Math.floor(a);
}

function ceil(a) {
  return Math.ceil(a);
}

function round(a) {
  return Math.round(a);
}

function abs(px) {
  return Math.abs(px);
}

function getMod(a, b) {
  return Caml_int32.mod_(a | 0, b | 0);
}

var Minute = {
  zero: 0
};

function Px_fromFloat(prim) {
  return prim;
}

function Px_toFloat(prim) {
  return prim;
}

var Px = {
  fromFloat: Px_fromFloat,
  toFloat: Px_toFloat,
  fromInt: fromInt,
  fromString: fromString,
  toInt: toInt,
  toString: toString,
  zero: 0.0,
  one: 1.0,
  eq: eq,
  notEqual: notEqual,
  equalOrLessThan: equalOrLessThan,
  equalOrMoreThan: equalOrMoreThan,
  moreThan: moreThan,
  lessThan: lessThan,
  minus: minus,
  plus: plus,
  multiply: multiply,
  divide: divide,
  cmp: cmp,
  inverse: inverse,
  multiplyWithInt: multiplyWithInt,
  multiplyWithFloat: multiplyWithFloat,
  divideByInt: divideByInt,
  divideByFloat: divideByFloat,
  max: max,
  min: min,
  min3: min3,
  floor: floor,
  ceil: ceil,
  round: round,
  abs: abs,
  getMod: getMod
};

export {
  Ms ,
  Px ,
  Minute ,
}
/* No side effect */
