// extracted by mini-css-extract-plugin
export var closeButton = "close-button-ckIuUYBEauCxFrkQt8dZ";
export var compact = "compact-KUP7Ps7Q9TuO195vflpV";
export var compactInner = "compact-inner-hjKBF4ThkRGo3ZX77KVi";
export var container = "container-GBggw9ISH_BmIilfCUGw";
export var day = "day-JjS331rkgoZAHzojLw02";
export var enRoute = "en-route-hOfWbLQLNZzrg14m4UHd";
export var finished = "finished-qKHREcA2hUDCJgm3q8gf";
export var inWork = "in-work-JgrT8xcmbtoNV3lPTf1F";
export var jobsCount = "jobs-count-YroQU7qV6vXrtYYabHyj";
export var published = "published-S98JcdTa5SVDIw2o_iyw";
export var started = "started-Tay2Fv4kcMq2C3ihctfA";
export var stats = "stats-p5Kp72U0N1uKVSBY4bQg";
export var status = "status-JkEL6b4vlwUr4MO48VXq";
export var statusContent = "status-content-zZcn8uruazX1HLijOlSL";
export var successfullyFinished = "successfully-finished-b8bc3lfdyxIVSLovSqED";
export var suspended = "suspended-dMdG5XNkmqpi9S_wwkQy";
export var unsuccessfullyFinished = "unsuccessfully-finished-WZ7gNXDAf7V66gjwXET9";
export var week = "week-lm3Mf0hE2L1h1KQSW_7I";
export var withUnassignedJobs = "with-unassigned-jobs-dtflfMlgxtIZh9IkkqyF";