

import * as React from "react";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DictionaryAPI from "./DictionaryAPI.mjs";
import * as DictionaryTypes from "./DictionaryTypes.mjs";
import * as DictionaryIndexJS from "./DictionaryIndexJS.mjs";

function DictionaryIndex(Props) {
  var wire = Props.wire;
  var match = React.useState(function () {
        
      });
  var setState = match[1];
  var state = match[0];
  var loaded = function (data) {
    if (data.TAG !== "Ok") {
      return ;
    }
    var data$1 = data._0;
    if (data$1 !== undefined) {
      return setState(function (param) {
                  return Caml_option.some(DictionaryTypes.StoreIndex.make(data$1));
                });
    }
    
  };
  React.useEffect((function () {
          Backend.$$finally(DictionaryAPI.Index.index(wire), loaded);
        }), []);
  if (state !== undefined) {
    return React.createElement("div", undefined, React.createElement("div", {
                    className: "page-container narrow"
                  }, React.createElement(DictionaryIndexJS.make, {
                        store: Caml_option.valFromOption(state)
                      })));
  } else {
    return null;
  }
}

var make = DictionaryIndex;

export {
  make ,
}
/* react Not a pure module */
