

import * as React from "react";
import * as RecurringJobEdit from "./RecurringJobEdit.mjs";
import * as RecurringJobIndex from "./RecurringJobIndex.mjs";

function RecurringJob(Props) {
  var wire = Props.wire;
  var mode = Props.mode;
  if (typeof mode === "object") {
    return React.createElement(RecurringJobEdit.make, {
                wire: wire,
                mode: mode
              });
  } else {
    return React.createElement(RecurringJobIndex.make, {
                wire: wire
              });
  }
}

var make = RecurringJob;

export {
  make ,
}
/* react Not a pure module */
