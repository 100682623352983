

import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";

var boldFields$p = Utils.Translations.tr("js.sites.site_upload.bold");

var areRequired$p = Utils.Translations.tr("js.sites.site_upload.for_required");

var externalId$p = Utils.Translations.t("js.sites.site_upload.fields.external_id");

var clientExternalId$p = Utils.Translations.t("js.sites.site_upload.fields.client_external_id");

var name$p = Utils.Translations.t("js.sites.site_upload.fields.name");

var description$p = Utils.Translations.t("js.sites.site_upload.fields.description");

var address$p = Utils.Translations.t("js.sites.site_upload.fields.address");

var addressDescription$p = Utils.Translations.t("js.sites.site_upload.fields.address_description");

var apartment$p = Utils.Translations.t("js.sites.site_upload.fields.apartment");

var floor$p = Utils.Translations.t("js.sites.site_upload.fields.floor");

var entranceNo$p = Utils.Translations.t("js.sites.site_upload.fields.entrance_no");

var country$p = Utils.Translations.t("js.sites.site_upload.fields.country");

var contact1Name$p = Utils.Translations.t("js.sites.site_upload.fields.contact_1_name");

var contact1PhoneNum$p = Utils.Translations.t("js.sites.site_upload.fields.contact_1_phone_num");

var contact2Name$p = Utils.Translations.t("js.sites.site_upload.fields.contact_2_name");

var contact2PhoneNum$p = Utils.Translations.t("js.sites.site_upload.fields.contact_2_phone_num");

var email$p = Utils.Translations.t("js.sites.site_upload.fields.email");

var territory$p = Utils.Translations.t("js.sites.site_upload.fields.territory");

var latitude$p = Utils.Translations.t("js.sites.site_upload.fields.latitude");

var longitude$p = Utils.Translations.t("js.sites.site_upload.fields.longitude");

function buildStr(field, description) {
  return field + " — " + description;
}

function buildStrBold(field, description) {
  return React.createElement(React.Fragment, undefined, React.createElement("strong", undefined, field), " — " + description);
}

function Pages_Sites_Upload_FieldsDescription(Props) {
  var ctx = Props.ctx;
  return React.createElement("div", undefined, React.createElement("p", undefined, React.createElement("strong", undefined, boldFields$p(ctx)), areRequired$p(ctx)), React.createElement("ol", undefined, React.createElement("li", undefined, buildStr("external_id", externalId$p(ctx))), React.createElement("li", undefined, buildStr("client_external_id", clientExternalId$p(ctx))), React.createElement("li", undefined, buildStrBold("name", name$p(ctx))), React.createElement("li", undefined, buildStr("description", description$p(ctx))), React.createElement("li", undefined, buildStr("address", address$p(ctx))), React.createElement("li", undefined, buildStr("address_description", addressDescription$p(ctx))), React.createElement("li", undefined, buildStr("apartment", apartment$p(ctx))), React.createElement("li", undefined, buildStr("floor", floor$p(ctx))), React.createElement("li", undefined, buildStr("entrance_no", entranceNo$p(ctx))), React.createElement("li", undefined, buildStr("country", country$p(ctx))), React.createElement("li", undefined, buildStr("contact_1_name", contact1Name$p(ctx))), React.createElement("li", undefined, buildStr("contact_1_phone_num", contact1PhoneNum$p(ctx))), React.createElement("li", undefined, buildStr("contact_2_name", contact2Name$p(ctx))), React.createElement("li", undefined, buildStr("contact_2_phone_num", contact2PhoneNum$p(ctx))), React.createElement("li", undefined, buildStr("email", email$p(ctx))), React.createElement("li", undefined, buildStr("territory", territory$p(ctx))), React.createElement("li", undefined, buildStr("latitude", latitude$p(ctx))), React.createElement("li", undefined, buildStr("longitude", longitude$p(ctx)))));
}

var make = Pages_Sites_Upload_FieldsDescription;

export {
  make ,
}
/* boldFields' Not a pure module */
