

import * as RCore from "../../../RCore.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Bindings_Slate from "../../../../bindings/Bindings_Slate.mjs";

var Payload = {};

var initialValue = [Bindings_Slate.$$Element.make(undefined, [Bindings_Slate.$$Element.make("", undefined, undefined, undefined)], "Paragraph", undefined)];

function makeEmptyParagraph() {
  return Bindings_Slate.$$Element.make(undefined, [Bindings_Slate.$$Element.make("", undefined, undefined, undefined)], "Paragraph", undefined);
}

function makeVariableElement(variable) {
  var text = "{{" + variable.markup + "}}";
  return Bindings_Slate.$$Element.make(undefined, [Bindings_Slate.$$Element.make(text, undefined, undefined, undefined)], {
              TAG: "Variable",
              _0: variable
            }, undefined);
}

function fromLiquidTemplate(param) {
  var elements = [makeEmptyParagraph()];
  param.body.forEach(function (token) {
        if (typeof token !== "object") {
          elements.push(makeEmptyParagraph());
          return ;
        }
        switch (token.TAG) {
          case "String" :
              var paragraphChildren = RCore.$$Option.flatMap(elements[elements.length - 1 | 0], Bindings_Slate.$$Element.children);
              if (paragraphChildren !== undefined) {
                paragraphChildren.push(Bindings_Slate.$$Element.make(token._0, undefined, undefined, undefined));
                return ;
              } else {
                return ;
              }
          case "Variable" :
              var paragraphChildren$1 = RCore.$$Option.flatMap(elements[elements.length - 1 | 0], Bindings_Slate.$$Element.children);
              if (paragraphChildren$1 !== undefined) {
                paragraphChildren$1.push(makeVariableElement(token._0));
                paragraphChildren$1.push(Bindings_Slate.$$Element.make("", undefined, undefined, undefined));
                return ;
              } else {
                return ;
              }
          case "Tag" :
              return ;
          
        }
      });
  if (Caml_obj.equal(elements, [])) {
    return initialValue;
  } else {
    return elements;
  }
}

function fromLiquidTemplate2(param) {
  var elements = RCore.$$Array.reduce(RCore.$$Array.reduce(param.body, [], (function (elements, token) {
              if (typeof token !== "object") {
                return Belt_Array.concatMany([
                            elements,
                            [[]]
                          ]);
              }
              switch (token.TAG) {
                case "String" :
                    var element = Bindings_Slate.$$Element.make(token._0, undefined, undefined, undefined);
                    var els = elements[elements.length - 1 | 0];
                    if (els !== undefined) {
                      els.push(element);
                      return elements;
                    } else {
                      return Belt_Array.concatMany([
                                  elements,
                                  [[element]]
                                ]);
                    }
                case "Variable" :
                    var element$1 = makeVariableElement(token._0);
                    var els$1 = elements[elements.length - 1 | 0];
                    if (els$1 !== undefined) {
                      els$1.push(element$1);
                      return elements;
                    } else {
                      return Belt_Array.concatMany([
                                  elements,
                                  [[element$1]]
                                ]);
                    }
                case "Tag" :
                    return elements;
                
              }
            })), [], (function (elements, children) {
          var children$1;
          if (Caml_obj.equal(children, [])) {
            children$1 = [Bindings_Slate.$$Element.make("", undefined, undefined, undefined)];
          } else {
            var payload = RCore.$$Option.flatMap(children[children.length - 1 | 0], Bindings_Slate.$$Element.payload);
            children$1 = payload !== undefined && typeof payload === "object" ? Belt_Array.concatMany([
                    children,
                    [Bindings_Slate.$$Element.make("", undefined, undefined, undefined)]
                  ]) : children;
          }
          return Belt_Array.concatMany([
                      elements,
                      [Bindings_Slate.$$Element.make(undefined, children$1, "Paragraph", undefined)]
                    ]);
        }));
  if (Caml_obj.equal(elements, [])) {
    return initialValue;
  } else {
    return elements;
  }
}

function toString(elements) {
  return elements.map(function (element, index) {
                var match = Bindings_Slate.$$Element.payload(element);
                var match$1 = Bindings_Slate.$$Element.children(element);
                if (match !== undefined) {
                  if (typeof match !== "object") {
                    if (match$1 !== undefined) {
                      return (
                              index === 0 ? "" : "\n"
                            ) + toString(match$1);
                    } else {
                      return Bindings_Slate.$$Element.toString(element);
                    }
                  } else {
                    return "{{" + match._0.markup + "}}";
                  }
                } else {
                  return Bindings_Slate.$$Element.toString(element);
                }
              }).join("");
}

export {
  Payload ,
  initialValue ,
  makeVariableElement ,
  fromLiquidTemplate ,
  fromLiquidTemplate2 ,
  toString ,
}
/* initialValue Not a pure module */
