

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";
import * as Types_Date from "../../types/Types_Date.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var decode$1 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              foreman: field.required("foreman", JsonDecode.option(JsonDecode.string)),
              workers: field.required("workers", JsonDecode.array(decode)),
              description: field.required("description", JsonDecode.option(JsonDecode.string))
            };
    });

var Team = {
  decode: decode$1
};

var decode$2 = JsonDecode.object(function (field) {
      return {
              uuid: field.optional("uuid", Uuid.decode),
              userUuid: field.required("userUuid", Uuid.decode),
              dateFrom: field.required("dateFrom", Types_Date.decode),
              dateTo: field.required("dateTo", Types_Date.decode)
            };
    });

var decode$3 = JsonDecode.object(function (field) {
      return {
              uuid: field.optional("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              foremanUuid: field.required("foremanUuid", JsonDecode.option(Uuid.decode)),
              workerUuids: field.required("workerUuids", JsonDecode.array(Uuid.decode)),
              description: field.required("description", JsonDecode.option(JsonDecode.string)),
              skillUuids: field.required("skillUuids", JsonDecode.array(Uuid.decode)),
              territoryUuid: field.required("territoryUuid", JsonDecode.option(Uuid.decode)),
              temporaryWorkers: field.required("temporaryWorkers", JsonDecode.array(decode$2))
            };
    });

var Team$1 = {
  decode: decode$3
};

var decode$4 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              skillUuids: field.required("skillUuids", JsonDecode.array(Uuid.decode))
            };
    });

var $$Worker = {
  decode: decode$4
};

var decode$5 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var Territory = {
  decode: decode$5
};

var decode$6 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var Skill = {
  decode: decode$6
};

var State = {};

var Index_Worker = {};

var Index = {
  $$Worker: Index_Worker,
  Team: Team
};

var Edit_TemporaryWorker = {};

var Edit = {
  TemporaryWorker: Edit_TemporaryWorker,
  Team: Team$1,
  $$Worker: $$Worker,
  Territory: Territory,
  Skill: Skill,
  State: State
};

export {
  Index ,
  Edit ,
}
/* decode Not a pure module */
