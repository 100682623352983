import React, { Component } from 'react'
import { getMoment } from 'planado/utils/time/index.js'

export class CurrentTime extends Component {
  state = {
    left: this.left
  }

  get left() {
    const { chart, ctx } = this.props
    return chart.viewOffset(
      getMoment({ dateTime: chart.get('currentTime'), ctx })
    )
  }

  shouldComponentUpdate = (_, nextState) => this.state.left !== nextState.left

  componentDidMount = () => {
    this.props.chart.on(
      'change:start change:scale change:currentTime',
      this.updatePosition,
      this
    )
  }

  componentWillUnmount = () => {
    this.props.chart.off(
      'change:start change:scale change:currentTime',
      this.updatePosition,
      this
    )
  }

  updatePosition() {
    const updatedLeft = this.left

    if (updatedLeft !== this.state.left) {
      this.setState(() => ({
        left: updatedLeft
      }))
    }
  }

  render = () => (
    <div className="current-time" style={{ left: this.state.left }}>
      <i className="fa fa-map-marker-alt fa-2x" />
    </div>
  )
}
