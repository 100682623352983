

import * as El from "../libraries/El.mjs";
import * as React from "react";
import * as DomUtils from "../DomUtils.mjs";
import * as UI_FormModuleCss from "/home/runner/work/planado/planado/client/rescript/ui/UI_Form.module.css";

var styles = UI_FormModuleCss;

var Width = {};

function UI_Form$Group(Props) {
  var className = Props.className;
  var widthOpt = Props.width;
  var children = Props.children;
  var width = widthOpt !== undefined ? widthOpt : "full";
  var baseClassName = width === "half" ? El.Cn.concat(styles.formGroup, styles.formGroupHalf) : styles.formGroup;
  return React.createElement("div", {
              className: El.Cn.concatOpt(baseClassName, className)
            }, children);
}

var Group = {
  Width: Width,
  make: UI_Form$Group
};

function UI_Form$GroupContainer(Props) {
  var className = Props.className;
  var children = Props.children;
  return React.createElement("div", {
              className: El.Cn.concatOpt(styles.formGroupContainer, className)
            }, children);
}

var GroupContainer = {
  make: UI_Form$GroupContainer
};

function UI_Form$Control(Props) {
  var className = Props.className;
  var children = Props.children;
  return React.createElement(DomUtils.Spread.make, {
              props: {
                className: El.Cn.concatOpt(styles.formControl, className)
              },
              children: children
            });
}

var Control = {
  make: UI_Form$Control
};

function UI_Form$SectionTitle(Props) {
  var className = Props.className;
  var children = Props.children;
  return React.createElement("div", {
              className: El.Cn.concatOpt(styles.formSectionTitle, className)
            }, children);
}

var SectionTitle = {
  make: UI_Form$SectionTitle
};

function UI_Form$Container(Props) {
  var className = Props.className;
  var children = Props.children;
  return React.createElement("div", {
              className: El.Cn.concatOpt(styles.formContainer, className)
            }, children);
}

var Container = {
  make: UI_Form$Container
};

function UI_Form$Section(Props) {
  var className = Props.className;
  var children = Props.children;
  return React.createElement("div", {
              className: El.Cn.concatOpt(styles.formSection, className)
            }, children);
}

var Section = {
  make: UI_Form$Section
};

function UI_Form$Header(Props) {
  var className = Props.className;
  var children = Props.children;
  return React.createElement("div", {
              className: El.Cn.concatOpt(styles.formHeader, className)
            }, children);
}

var Header = {
  make: UI_Form$Header
};

function UI_Form$HeaderTitle(Props) {
  var className = Props.className;
  var children = Props.children;
  return React.createElement("div", {
              className: El.Cn.concatOpt(styles.formHeaderTitle, className)
            }, children);
}

var HeaderTitle = {
  make: UI_Form$HeaderTitle
};

export {
  Group ,
  GroupContainer ,
  Control ,
  SectionTitle ,
  Container ,
  Section ,
  Header ,
  HeaderTitle ,
}
/* styles Not a pure module */
