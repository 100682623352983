

import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as WebhookTypes from "../webhook/WebhookTypes.mjs";

function make(apiKey, webhooksOpt) {
  var webhooks = webhooksOpt !== undefined ? webhooksOpt : [];
  return {
          apiKey: apiKey,
          webhooks: webhooks
        };
}

var decode = JsonDecode.object(function (field) {
      return {
              apiKey: field.required("apiKey", JsonDecode.option(JsonDecode.string)),
              webhooks: field.required("webhooks", JsonDecode.array(WebhookTypes.Webhook.decode))
            };
    });

var State = {
  make: make,
  decode: decode
};

var Show = {
  State: State
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              apiKey: field.required("apiKey", JsonDecode.string)
            };
    });

var KeyResponse = {
  decode: decode$1
};

export {
  Show ,
  KeyResponse ,
}
/* decode Not a pure module */
