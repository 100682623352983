

import * as Case from "../../../utils/Case.mjs";
import * as Title from "../../common/Title.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Portal from "../../../Portal.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DictionaryAPI from "./DictionaryAPI.mjs";
import * as MessengerTypes from "../../messenger/MessengerTypes.mjs";
import * as DictionaryTypes from "./DictionaryTypes.mjs";
import * as DictionaryFormJS from "./DictionaryFormJS.mjs";
import * as DictionaryFooterJS from "./DictionaryFooterJS.mjs";
import * as Utils$1 from "planado/stores/utils";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";

var dictionaryDeleted$p = Utils.Translations.t("js.admin.dictionaries.messages.dictionary_deleted");

var dictionaryCreated$p = Utils.Translations.t("js.admin.dictionaries.messages.dictionary_created");

var dictionaryUpdated$p = Utils.Translations.t("js.admin.dictionaries.messages.dictionary_updated");

function DictionaryEdit(Props) {
  var wire = Props.wire;
  var mode = Props.mode;
  var footer = wire.footer;
  var navigate = wire.navigate;
  var messenger = wire.subscriptions.messenger;
  var ctx = wire.ctx;
  var showMessage = function (messageType, text) {
    messenger.trigger({
          TAG: "Show",
          _0: MessengerTypes.Message.make(undefined, messageType, text)
        });
  };
  var onSubmitDictionary = function () {
    var msg = typeof mode === "object" ? dictionaryUpdated$p(ctx) : dictionaryCreated$p(ctx);
    showMessage("Success", {
          TAG: "Text",
          _0: msg
        });
    navigate(undefined, {
          NAME: "Dictionary",
          VAL: "Index"
        });
  };
  var uuid = typeof mode === "object" ? Caml_option.some(mode.VAL) : undefined;
  var match = React.useState(function () {
        
      });
  var setStore = match[1];
  var store = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setResponseData = match$1[1];
  var responseData = match$1[0];
  var loaded = function (data) {
    if (data.TAG === "Ok") {
      var json = data._0;
      if (json === undefined) {
        return ;
      }
      var store = DictionaryTypes.StoreEdit.make(json);
      var data$1 = Case.camelize(json);
      Utils$1.connectDocumentToStore(Utils.$$document, footer, store);
      setStore(function (param) {
            return Caml_option.some(store);
          });
      var decodeError = Json_Decode$JsonCombinators.decode(data$1, DictionaryAPI.Get.decode);
      if (decodeError.TAG === "Ok") {
        var dictionaryField = decodeError._0;
        if (dictionaryField !== undefined) {
          return setResponseData(function (param) {
                      return dictionaryField;
                    });
        } else {
          return ;
        }
      }
      console.log(decodeError._0);
      return ;
    }
    var match = data._0;
    if (typeof match !== "object") {
      return ;
    }
    if (match.TAG !== "HTTPError") {
      return ;
    }
    var tmp = match._0[0];
    if (typeof tmp !== "object" && tmp === "NotFound") {
      return navigate(undefined, "Unknown");
    }
    
  };
  var destroyed = function (data) {
    if (data.TAG !== "Ok") {
      return ;
    }
    showMessage("Success", {
          TAG: "Text",
          _0: dictionaryDeleted$p(ctx)
        });
    navigate(undefined, {
          NAME: "Dictionary",
          VAL: "Index"
        });
  };
  var deleteDictionary = function (uuid) {
    Backend.$$finally(DictionaryAPI.Show.destroy(wire, uuid), destroyed);
  };
  React.useEffect((function () {
          if (uuid !== undefined) {
            Backend.$$finally(DictionaryAPI.Show.show(wire, Caml_option.valFromOption(uuid)), loaded);
          } else {
            Backend.$$finally(DictionaryAPI.Show.new_(wire), loaded);
          }
        }), []);
  var title = responseData !== undefined ? React.createElement(Title.make, {
          route: wire.route,
          ctx: ctx,
          values: {
            name: responseData.name
          },
          wire: wire
        }) : null;
  if (store === undefined) {
    return null;
  }
  var store$1 = Caml_option.valFromOption(store);
  return React.createElement(React.Fragment, undefined, title, React.createElement("div", {
                  id: "content-wrapper"
                }, React.createElement("div", {
                      className: "page-container narrow"
                    }, React.createElement(DictionaryFormJS.make, {
                          store: store$1,
                          deleteDictionary: deleteDictionary,
                          onSubmitDictionary: onSubmitDictionary
                        }), React.createElement(Portal.make, {
                          root: footer,
                          children: React.createElement(DictionaryFooterJS.make, {
                                store: store$1
                              })
                        }))));
}

var make = DictionaryEdit;

export {
  make ,
}
/* dictionaryDeleted' Not a pure module */
