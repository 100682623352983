

import * as RCore from "../../../../../../../../libraries/RCore.mjs";
import * as Units from "../../../../../../../../types/Units.mjs";
import * as React from "react";
import * as Locale from "../../../../../../../../libraries/Locale.mjs";
import * as AppContext from "../../../../../../../../context/AppContext.mjs";
import * as WeekTimeline_Constants from "../../../../lib/WeekTimeline_Constants.mjs";
import * as WeekTimeline_ActiveDayScaleModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/active_day/components/scale/WeekTimeline_ActiveDayScale.module.css";

var styles = WeekTimeline_ActiveDayScaleModuleCss;

function makeStyle(width) {
  return {
          width: Units.Px.toString(width)
        };
}

function WeekTimeline_ActiveDayScale(Props) {
  var param = Props.activeDay;
  var wire = AppContext.useWire();
  var hours = RCore.$$Array.make(WeekTimeline_Constants.hoursInDay, Locale.T.startOf("day", Locale.T.now(wire))).map(function (hour, index) {
        return Locale.T.fmtM("Time", wire)(Locale.T.nextHours(index)(hour));
      });
  return React.createElement("div", {
              className: styles.timeline,
              style: makeStyle(param.sizes.timeline)
            }, hours.map(function (hour) {
                  return React.createElement("div", {
                              key: hour,
                              className: styles.hour
                            }, hour);
                }));
}

var make = React.memo(WeekTimeline_ActiveDayScale);

export {
  make ,
}
/* styles Not a pure module */
