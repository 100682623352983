

import * as React from "react";
import * as FakeInputModuleCss from "/home/runner/work/planado/planado/client/rescript/components/inputs/FakeInput.module.css";

var styles = FakeInputModuleCss;

function FakeInput(Props) {
  var dangerouslySetInnerHTML = Props.dangerouslySetInnerHTML;
  return React.createElement("div", {
              className: styles.fakeStyleContainer,
              dangerouslySetInnerHTML: dangerouslySetInnerHTML
            });
}

var make = FakeInput;

export {
  styles ,
  make ,
}
/* styles Not a pure module */
