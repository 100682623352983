

import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Bindings_Slate from "../../../bindings/Bindings_Slate.mjs";
import * as Liquid_Template from "../parser/Liquid_Template.mjs";
import * as Liquid_EditorInlines from "./lib/Liquid_EditorInlines.mjs";
import * as Liquid_EditorTemplate from "./lib/Liquid_EditorTemplate.mjs";

function useValue(initialValue) {
  var match = React.useState(function () {
        var template = Liquid_Template.parse(initialValue);
        if (template.TAG === "Ok") {
          return Liquid_EditorTemplate.fromLiquidTemplate(template._0);
        } else {
          return Liquid_EditorTemplate.initialValue;
        }
      });
  var setValue = match[1];
  var value = match[0];
  React.useEffect((function () {
          var template = Liquid_Template.parse(initialValue);
          if (template.TAG === "Ok") {
            var template$1 = template._0;
            setValue(function (param) {
                  return Liquid_EditorTemplate.fromLiquidTemplate(template$1);
                });
          }
          
        }), [initialValue]);
  var onChange = function (nextValue) {
    if (Caml_obj.notequal(value, nextValue)) {
      return setValue(function (param) {
                  return nextValue;
                });
    }
    
  };
  return [
          value,
          onChange
        ];
}

function useEditor() {
  return React.useMemo((function () {
                return Liquid_EditorInlines.withInlines(Bindings_Slate.React.withReact(Bindings_Slate.SlateHistory.withHistory(Bindings_Slate.Editor.make())));
              }), []);
}

export {
  useValue ,
  useEditor ,
}
/* react Not a pure module */
