import React from 'react'
import { Provider } from 'react-redux'
import ContainerLink from 'planado/containers/admin/templates/template_container_link'

export const Form = ({
  store,
  wire,
  deleteTemplate,
  onSubmitTemplate,
  showServerErrorMsg,
  showFormErrorMsg,
  redirectToIndex
}) => {
  return (
    <Provider store={store}>
      <ContainerLink
        wire={wire}
        deleteTemplateRe={deleteTemplate}
        onSubmitTemplateRe={onSubmitTemplate}
        showServerErrorMsgRe={showServerErrorMsg}
        showFormErrorMsgRe={showFormErrorMsg}
        redirectToIndexRe={redirectToIndex}
      />
    </Provider>
  )
}
