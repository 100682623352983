import Backbone from 'backbone'
import moment from 'moment-timezone'
import jQuery from 'jquery'
import Navigator from 'planado/schedule/utils/navigator'
import { jobs, dayJobs, bars } from 'planado/schedule/utils/repositories'
import {
  jobSchedulePath,
  jobAssignPath,
  jobUnassignPath,
  jobUnschedulePath,
  jobStartPath,
  jobResumePath,
  fetchSchedulePath,
} from 'planado/routes.js'
import { scaleDuration } from 'planado/schedule/utils2/scales'
import { getMoment, showTime, now } from 'planado/utils/time/index.js'
import { credentials } from 'planado/utils/network_config.js'
import { makeSignatureHeader } from 'planado/utils/network.js'

const compact = (arr) => arr.filter((value) => value)

const headers = (ctx) => {
  const defaultHeaders = {
    Accept: 'application/json',
  }

  if (ctx && ctx.features.flags.includes('webApiSignature')) {
    const wireCtx = ctx.wire.ctx
    const signatureHeaders = makeSignatureHeader(wireCtx)
    return { ...defaultHeaders, ...signatureHeaders }
  } else {
    return defaultHeaders
  }
}

class Collection extends Backbone.Collection {
  listenToModels(events, callback, models = null) {
    let subscribe = (model) => model.on(events, callback)
    let unsubscribe = (model) => {
      if (model) {
        model.off(events, callback)
      }
    }

    if (!this._modelsSubscriptions) {
      this._modelsSubscriptions = []
    }
    this._modelsSubscriptions.push(unsubscribe)

    this.on('add', subscribe)
    this.on('remove', unsubscribe)
    this.forEach(subscribe)
    if (models) {
      models.forEach(subscribe)
    }

    return this
  }

  unlisten() {
    if (!this._modelsSubscriptions) {
      return
    }

    Array.from(this._modelsSubscriptions).map((unsubscribe) =>
      this.forEach(unsubscribe)
    )
  }
}

class Job extends Backbone.Model {
  static initClass() {
    this.prototype.intersectionTolerance = moment.duration({ minutes: 40 })
    this.prototype.idAttribute = 'uuid'
  }

  initialize() {
    this.set('active', false)
  }

  getUuid() {
    return this.get('uuid')
  }

  scheduledAt(ctx) {
    if (this.hasScheduledStart() && ctx) {
      return getMoment({ dateTime: this.get('scheduled_at'), ctx })
    } else {
      return null
    }
  }

  finishedAt(ctx) {
    return getMoment({ dateTime: this.get('finished_at'), ctx })
  }

  scheduledDuration() {
    return moment.duration({ minutes: this.get('scheduled_duration_min') || 0 })
  }

  hasScheduledStart() {
    return this.get('scheduled_at') !== null
  }

  startAt(ctx) {
    let start = this.get('first_activity_at') || this.get('scheduled_at')

    if (start) {
      return getMoment({ dateTime: start, ctx })
    } else {
      return null
    }
  }

  finishAt(ctx) {
    if (this.finished()) {
      return getMoment({ dateTime: this.get('finished_at'), ctx })
    } else {
      const start = this.startAt(ctx)
      if (start) {
        return start.add(this.scheduledDuration())
      } else {
        return null
      }
    }
  }

  duration() {
    let minutes = this.finished()
      ? this.get('actual_duration_min')
      : this.get('scheduled_duration_min')
    return moment.duration({ minutes: minutes || 0 })
  }

  hasStart(ctx) {
    return this.startAt(ctx) !== null
  }

  posted() {
    return this.get('state') === 'posted'
  }
  scheduled() {
    return this.get('state') === 'scheduled'
  }
  published() {
    return this.get('state') === 'published'
  }
  enRoute() {
    return this.get('state') === 'en_route'
  }
  started() {
    return this.get('state') === 'started'
  }
  suspended() {
    return this.get('state') === 'suspended'
  }
  finished() {
    return this.get('state') === 'finished'
  }
  inProgress() {
    return this.enRoute() || this.started() || this.suspended()
  }

  isSchedulable() {
    return !(this.finished() || this.started() || this.enRoute())
  }

  isOverdue(date, ctx) {
    return (
      (this.scheduled() || this.published()) &&
      this.scheduledAt(ctx).toDate() < date.toDate()
    )
  }

  isOvertime(date, ctx) {
    return (this.started() || this.enRoute()) && this.overtime(date, ctx) > 0
  }

  overtime(date, ctx) {
    return (
      date.toDate() - this.startAt(ctx).add(this.scheduledDuration()).toDate()
    )
  }

  failed() {
    return !this.get('successful')
  }

  visibleState() {
    if (this.posted()) {
      return 'posted'
    } else if (this.scheduled()) {
      return 'scheduled'
    } else if (this.published()) {
      return 'assigned'
    } else if (this.started()) {
      return 'started'
    } else if (this.enRoute()) {
      return 'en_route'
    } else if (this.suspended()) {
      return 'suspended'
    } else if (this.finished()) {
      return 'finished'
    }
  }

  status(date, ctx) {
    if (this.isOverdue(date, ctx)) {
      return 'overdue'
    } else if (this.isOvertime(date, ctx)) {
      return 'overtime'
    } else if (this.started() || this.enRoute() || this.suspended()) {
      return 'current'
    } else if (this.posted()) {
      return 'posted'
    } else if (!this.finished()) {
      return 'scheduled'
    } else if (this.failed()) {
      return 'failed'
    } else {
      return 'finished'
    }
  }

  assigneeName() {
    return this.get('assignee_name') || ''
  }

  getDescription(ctx) {
    let descr = this.get('description')

    if (descr && descr.length > 50) {
      descr = descr.substring(0, 50) + '…'
    }

    return compact([
      showTime(this.startAt(ctx), 'time', ctx),
      this.getLabel(ctx, { withNoSign: true }),
      this.get('job_type'),
      descr,
    ]).join(' • ')
  }

  getLabel(ctx, options) {
    if (options == null) {
      options = {}
    }
    const site = this.get('site') === null ? undefined : this.get('site').name
    let client = this.get('client_id') ? this.get('client_name') : undefined //else I18n.t('js.schedule.jobs.no_client')
    let template = this.get('template_id')
      ? this.get('template_name')
      : undefined // else I18n.t('js.schedule.jobs.no_template')

    let jobNo = this.get('serial_no')

    if (options.withNoSign) {
      jobNo = ctx.t('common.number_sign') + jobNo
    }

    return compact([jobNo, this.get('address'), site, client, template]).join(
      ' • '
    )
  }

  get offset() {
    return this.get('offset')
  }

  get serialNo() {
    return this.get('serial_no')
  }

  rescheduleAt({ time, post, ctx }) {
    let before
    if (post == null) {
      post = true
    }
    if (this.scheduled() || this.published()) {
      before = this.scheduledAt(ctx).toDate()
    }
    if (!before) {
      before = null
    }
    let after = time.toDate()
    this.set('scheduled_at', after)

    if (post) {
      return jQuery.ajax({
        ...credentials,
        headers: headers(ctx),
        url: jobSchedulePath(this.id),
        method: 'patch',
        data: {
          scheduled_at: this._localizedScheduledStartAt(ctx),
        },
      })
    }
  }

  assignTo(bar, time = null, ctx) {
    this.set({ bar_id: bar.id })
    if (time) {
      this.rescheduleAt({ time, post: false, ctx })
    }

    let data = {
      scheduled_at: this._localizedScheduledStartAt(ctx),
    }

    if (bar.get('type') === 'team') {
      data.team_id = bar.id
    } else if (bar.get('type') === 'worker') {
      data.worker_id = bar.id
    }

    return jQuery.ajax({
      ...credentials,
      headers: headers(ctx),
      url: jobAssignPath(this.id),
      method: 'patch',
      data,
    })
  }

  unassign(time, ctx) {
    this.rescheduleAt({ time, post: false, ctx })
    this.set('bar_id', -1)

    return jQuery.ajax({
      ...credentials,
      headers: headers(ctx),
      url: jobUnassignPath(this.id),
      method: 'patch',
      data: {
        scheduled_at: this._localizedScheduledStartAt(ctx),
      },
    })
  }

  unschedule(ctx) {
    return jQuery.ajax({
      ...credentials,
      headers: headers(ctx),
      url: jobUnschedulePath(this.id),
      method: 'patch',
    })
  }

  intersects = (job, ctx) => {
    let startAt = this.startAt(ctx)
    let finishAt = this.finishAt(ctx).subtract({ minutes: 5 })

    return (
      (job.startAt(ctx).isAfter(startAt) &&
        job.startAt(ctx).isBefore(finishAt)) ||
      (job.finishAt(ctx).isAfter(startAt) &&
        job.finishAt(ctx).isBefore(finishAt))
    )
  }

  isActive() {
    return this.get('active')
  }

  start(ctx) {
    return jQuery.ajax({
      ...credentials,
      headers: headers(ctx),
      url: jobStartPath(this.id),
      method: 'patch',
    })
  }

  resume(ctx) {
    return jQuery.ajax({
      ...credentials,
      headers: headers(ctx),
      url: jobResumePath(this.id),
      method: 'patch',
    })
  }

  _localizedScheduledStartAt(ctx) {
    return showTime(this.get('scheduled_at'), 'iso8601', ctx)
  }
}
Job.initClass()

class JobGroup extends Collection {
  static initClass() {
    this.prototype.model = Job
  }

  init = (data, ctx) => {
    this.ctx = ctx
    this.reset(data)
  }

  comparator(job) {
    return job.startAt(this.ctx).unix()
  }

  minStartAt() {
    return this.min((job) => job.startAt(this.ctx).unix()).startAt(this.ctx)
  }

  maxFinishAt(ctx) {
    return this.max((job) => job.finishAt(ctx).unix()).finishAt(ctx)
  }

  getName(ctx) {
    return ctx.t('js.schedule.bars.multiple_jobs')
  }

  getKey() {
    return this.key || (this.key = Math.floor(Math.random() * 1e6))
  }

  activate() {
    return this.listenToModels(
      'change:active',
      this.triggerActiveJob.bind(this)
    )
  }

  finalize() {
    return this.unlisten()
  }

  triggerActiveJob() {
    return this.trigger('change:active_job')
  }

  activeJob() {
    return this.find((job) => job.isActive())
  }

  intersects(job, ctx) {
    let minStartAt = this.minStartAt()
    let maxFinishAt = this.maxFinishAt(ctx)
    let startAt = job.startAt(ctx).add({ minutes: 5 })
    let finishAt = job.finishAt(ctx)

    return (
      (startAt.isSameOrAfter(minStartAt) &&
        startAt.isSameOrBefore(maxFinishAt)) ||
      (finishAt.isSameOrAfter(minStartAt) &&
        finishAt.isSameOrBefore(maxFinishAt))
    )
  }
}

JobGroup.initClass()

class BarJobs extends Collection {
  static initClass() {
    this.prototype.model = Job
  }

  comparator(job) {
    if (job.hasScheduledStart()) {
      return job.startAt(this.ctx).unix()
    } else {
      return 0
    }
  }

  initialize(models, ctx) {
    this.ctx = ctx

    this.listenToModels('change:scheduled_at', this.reorder.bind(this), models)
    this.listenToModels(
      'change:scheduled_duration_min',
      this.reorder.bind(this),
      models
    )

    this.on('add remove', this.reorder.bind(this))
  }

  reorder() {
    this.sort(false)
    this.trigger('reset')
  }

  scheduled() {
    return this.filter((job) => job.hasScheduledStart())
  }

  groups = (ctx) => {
    const groups = []
    const lastGroup = this.scheduled().reduce((currentGroup, job) => {
      if (currentGroup && currentGroup.intersects(job, ctx)) {
        currentGroup.add(job)
        return currentGroup
      } else {
        if (currentGroup) {
          groups.push(currentGroup.activate())
        }

        const jobGroup = new JobGroup([])
        jobGroup.init([job], ctx)
        return jobGroup
      }
    }, null)

    if (lastGroup) {
      groups.push(lastGroup.activate())
    }

    return this._orderGroups(groups)
  }

  _orderGroups(groups) {
    return groups.sort(function (g1, g2) {
      if (g1.length < g2.length) {
        return -1
      } else if (g1.length > g2.length) {
        return 1
      } else if (g1.length === g2.length && g1.length > 1) {
        return 0
      } else {
        if (g1.at(0).id > g2.at(0).id) {
          return 1
        } else {
          return -1
        }
      }
    })
  }
}
BarJobs.initClass()

class Bar extends Backbone.Model {
  static initClass() {
    this.prototype.idAttribute = 'bar_id'
  }

  unassigned() {
    return this.get('type') === 'unassigned'
  }

  addJob(job) {
    this.jobs().add(job)
  }

  removeJob(job) {
    this.jobs().remove(job)
  }

  resetJobs(jobs) {
    this.jobs().reset(jobs)
  }

  isTeam() {
    return this.get('is_team')
  }

  setMenuId(menuId) {
    this.collection.resetMenuId()
    this.set('menuId', menuId)
  }

  setHoverStatus() {
    this.set('hoverStatus', true)
  }

  resetMenuId() {
    let currentMenuId = this.get('menuId')
    if (!currentMenuId) {
      return
    }

    if (new Date().getTime() - currentMenuId > 100) {
      this.set('menuId', null)
    }
  }

  resetHoverStatus() {
    let currentHoverStatus = this.get('hoverStatus')
    if (!currentHoverStatus) {
      return
    }

    this.set('hoverStatus', null)
  }
}

Bar.initClass()

class SideBar extends Backbone.Model {
  initialize() {
    return this.chart().on('change:active_job', (_, job) =>
      this.setActiveJob(job)
    )
  }

  setActiveJob(job) {
    if (this.get('active_job') !== job) {
      this.set({ active_job: job })
    }
    return this.chart().setActiveJob(job)
  }

  chart() {
    return this.get('chart')
  }

  jobs() {
    return this.chart().jobs()
  }

  dayJobs() {
    return this.chart().dayJobs()
  }

  bars() {
    return this.chart().bars()
  }

  goToJob(job, ctx) {
    return this.chart().goTo(job.startAt(ctx).subtract({ hours: 1 }))
  }
}

class Chart extends Backbone.Model {
  static initClass() {
    this.prototype._centerDateFactor = 2
  }

  initialize(options) {
    this.options = options
    this.nav = new Navigator(this)

    const { store } = options
    const { ctx, scale } = store.getState()

    this.ctx = ctx

    this.listenStore()

    const now_ = getMoment({ dateTime: now(ctx), ctx })

    const viewPortStart = now_.clone().subtract({ hours: 2 })

    let period
    switch (scale) {
      case 'day': {
        const day = moment.duration({ days: 1 })

        period = [
          now_.clone().subtract(day).startOf('day'),
          now_.clone().add(day).endOf('day'),
        ]
        break
      }
      case 'week': {
        const weekType = ctx.localizator.mondayFirst ? 'isoWeek' : 'week'
        const week = moment.duration({ days: 7 })

        period = [
          now_.clone().startOf(weekType).subtract(week),
          now_.clone().endOf(weekType).add(week),
        ]
        break
      }
    }

    const [start, finish] = period

    this.set({
      scale,
      start,
      finish,
      viewPortStart,
      currentTime: now_.clone(),
      activeDate: now_.clone().startOf('day'),
    })

    setTimeout(
      this.refreshTime.bind(this),
      now_.clone().startOf('minute').add({ minutes: 1 }).diff(now_)
    )

    this.jobs().currentTime = this.get('currentTime')
    this.jobs().listenToModels('change', this._resetActiveDayJobs.bind(this))
    this.jobs().on('add', this._resetActiveDayJobs.bind(this))
    this.jobs().on('remove', this._resetActiveDayJobs.bind(this))
    this.dayJobs().currentTime = this.get('currentTime')
    this.dayJobs().init(this.jobs().forDate(this.get('activeDate')), ctx)
  }

  listenStore() {
    const { store } = this.options
    store.subscribe(() => {
      const { scale: nextScale } = store.getState()
      if (this.get('scale') !== nextScale) {
        this.set('scale', nextScale)
      }
    })
  }

  viewPortStart() {
    return this.get('viewPortStart')
  }

  getMarks() {
    return this.nav.getMarks()
  }

  getDays() {
    return this.nav.getDays()
  }

  minutesOffset(date) {
    if (date == null) {
      date = this.viewPortStart()
    }
    return this._minutesDiff(date, this.get('start').clone())
  }

  minutes() {
    return this.minutesOffset(this.get('finish').clone())
  }

  viewOffset(date) {
    if (date === null) {
      date = this.viewPortStart()
    }

    return Math.floor(
      (this.minutesOffset(date) / this.minutes()) * this.width()
    )
  }

  viewOffsetToMinutes(offset) {
    return Math.floor((offset / this.width()) * this.minutes())
  }

  durationAsDiff(duration) {
    return this.nav.hoursToOffset(duration.asHours())
  }

  viewOffsetToTime(offset, roundMinutes) {
    if (roundMinutes == null) {
      roundMinutes = 5
    }
    let res = this.get('start')
      .clone()
      .add({ minutes: this.viewOffsetToMinutes(offset) })
    if (roundMinutes) {
      let minutes = res.minutes()
      let diff = minutes % roundMinutes
      if (diff < roundMinutes / 2.0 - 1) {
        res.minutes(minutes - diff)
      } else {
        res.minutes(minutes + roundMinutes - diff)
      }
    }
    return res
  }

  viewOffsetToCenteredTime(offset) {
    return this.viewOffsetToTime(offset, null).add(this._centeredDateDiff())
  }

  width() {
    return this.nav.hoursToOffset(this.hours())
  }

  seconds() {
    return this.get('finish').clone().diff(this.get('start').clone(), 'seconds')
  }

  hours() {
    return this.seconds() / 60 / 60
  }

  viewPortWidth() {
    return this.get('viewPortWidth')
  }

  isEditable() {
    return this.get('editable')
  }

  refreshTime() {
    this.set({
      currentTime: getMoment({ dateTime: now(this.ctx), ctx: this.ctx }),
    })
    this.jobs().currentTime = this.get('currentTime')
    this.dayJobs().currentTime = this.get('currentTime')

    this.changeActiveDate(this.get('currentTime'))

    this.dayJobs().sort()
    return setTimeout(this.refreshTime.bind(this), 60 * 1000)
  }

  time() {
    return this.get('currentTime').clone()
  }

  activeDate() {
    return this.get('activeDate').clone()
  }

  bars() {
    return bars
  }

  jobs() {
    return jobs
  }

  dayJobs() {
    return dayJobs
  }

  changeActiveDate(time) {
    if (!time.isSame(this.get('activeDate'), 'day')) {
      this.set({ activeDate: time.clone().startOf('day') })
      this._resetActiveDayJobs()
    }
  }

  load(direction) {
    let interval
    switch (this.get('scale')) {
      case 'day':
        interval = moment.duration({ days: 1 })
        break
      case 'week':
        interval = moment.duration({ days: 7 })
        break
    }

    let period
    switch (direction) {
      case 'future': {
        period = [
          this.get('start').clone().add(interval),
          this.get('finish').clone().add(interval),
        ]
        break
      }
      case 'past': {
        period = [
          this.get('start').clone().subtract(interval),
          this.get('finish').clone().subtract(interval),
        ]
        break
      }
    }

    const defer = jQuery.Deferred()
    this._fetchPeriod(period).done((jobs, _textStatus, _jqXHR) => {
      this.jobs().reset(Array.from(jobs))

      const [start, finish] = period
      this.set({ start, finish })

      defer.resolve({ duration: interval, direction })
    })

    return defer
  }

  lock() { }
  unlock() { }

  goTo(date, options) {
    if (options == null) {
      options = {}
    }

    if (options.centered) {
      date = this._fromCentered(date)
    }

    return this.trigger('goto', { target: date })
  }

  goToOffset(offset, options) {
    if (options == null) {
      options = {}
    }
    this.goTo(this.viewOffsetToTime(offset, false), options)
  }

  setActiveJob(job) {
    if (this.get('active_job') === job) {
      return
    }
    let prev = this.get('active_job')
    if (prev) {
      prev.set({ active: false })
    }
    if (job) {
      job.set({ active: true })
    }
    this.set({ active_job: job })
  }

  resetMenuId() {
    this.bars().resetMenuId()
  }
  resetHoverStatus() {
    this.bars().resetHoverStatus()
  }

  _resetActiveDayJobs() {
    this.dayJobs().reset(this.jobs().forDate(this.get('activeDate')))
  }

  _minutesDiff(a, b) {
    return Math.ceil((a.unix() - b.unix()) / 60)
  }

  _fromCentered(date) {
    return date.clone().subtract(this._centeredDateDiff())
  }

  _centeredDateDiff() {
    return moment.duration({
      seconds: Math.floor(
        scaleDuration(this.get('scale'), this.ctx).asSeconds() /
        this._centerDateFactor
      ),
    })
  }

  _fetchPeriod(period) {
    return jQuery.ajax({
      ...credentials,
      headers: headers(this.ctx),
      url: fetchSchedulePath,
      data: jQuery.param({
        period: period.map((date) => showTime(date, 'date', this.ctx)),
      }),
    })
  }
}

Chart.initClass()

export { Collection, BarJobs, Bar, Job, Chart, SideBar }
