import {
  SET_FIELD,
  SET_ASSIGNEES,
  SET_ERROR,
  SET_CLIENT,
  SET_SITE,
  CANCEL_SET_CLIENT_OR_SITE,
  UPDATE_FIELD,
  SHOW_POPOVER,
  HIDE_POPOVER,
  SET_RECURRING_JOB,
  SEND_REQUEST_START,
  SEND_REQUEST_FINISH
} from 'planado/actions/admin/recurring_jobs_show'
import { getPosition } from 'planado/components/popover'

import {
  CONTACT_ADD,
  CONTACT_EDIT,
  CONTACT_REMOVE
} from 'planado/actions/contacts'
import ReduceContacts, {
  initialState as DEFAULT_CONTACTS
} from 'planado/reducers/contacts/list'

const reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_FIELD:
      return {
        ...state,
        recurringJob: { ...state.recurringJob, [action.name]: action.value }
      }
    case SET_ASSIGNEES:
      return {
        ...state,
        recurringJob: { ...state.recurringJob, assignees: action.value }
      }
    case SET_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.name]: action.value
        }
      }
    case SET_CLIENT: {
      const { type: _type, ...clientInfo } = action // eslint-disable-line
      return {
        ...state,
        recurringJob: { ...state.recurringJob, ...clientInfo }
      }
    }
    case SET_SITE: {
      const { type: _type, ...siteInfo } = action // eslint-disable-line
      return {
        ...state,
        recurringJob: { ...state.recurringJob, ...siteInfo }
      }
    }
    case UPDATE_FIELD:
      return {
        ...state,
        [action.name]: action.value
      }
    case SET_RECURRING_JOB:
      return {
        ...action.newState
      }
    case SEND_REQUEST_START:
      return {
        ...state,
        locked: true
      }
    case SEND_REQUEST_FINISH:
      return {
        ...state,
        locked: false
      }
    case SHOW_POPOVER:
      return {
        ...state,
        popover: {
          node: action.node,
          targetId: action.buttonId,
          ...getPosition(action.dom, action.buttonId, 'top')
        }
      }
    case HIDE_POPOVER:
    case 'WINDOW_CLICKED':
      return {
        ...state,
        popover: {
          targetId: null
        }
      }
    case CONTACT_ADD:
    case CONTACT_REMOVE:
    case CONTACT_EDIT: {
      const contacts = ReduceContacts(state.recurringJob.contacts, action)
      return {
        ...state,
        recurringJob: { ...state.recurringJob, contacts }
      }
    }
    case CANCEL_SET_CLIENT_OR_SITE:
      return {
        ...state,
        recurringJob: {
          ...state.recurringJob,
          entranceNo: null,
          apartment: null,
          address: null,
          floor: null,
          addressDescription: null,
          contacts: DEFAULT_CONTACTS,
          client: null,
          site: null
        }
      }
    default:
      return state
  }
}

export default reducer
