

import * as UI_SortableList from "../../ui/UI_SortableList.mjs";

function getId(territory) {
  return territory.uuid;
}

var include = UI_SortableList.Make({
      getId: getId
    });

var Item = include.Item;

var Item_make = Item.make;

var Item_default = Item.$$default;

var Item$1 = {
  make: Item_make,
  $$default: Item_default
};

var Activator = include.Activator;

var make = include.make;

var $$default = include.$$default;

export {
  Item$1 as Item,
  Activator ,
  make ,
  $$default as default,
}
/* include Not a pure module */
