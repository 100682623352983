

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Types_Catalog from "../../../types/Types_Catalog.mjs";

var Parent = {};

function fromJs(js) {
  return {
          uuid: js.uuid,
          name: js.name,
          description: Caml_option.null_to_opt(js.description),
          parent: Caml_option.null_to_opt(js.parent),
          childCategories: js.childCategories,
          services: js.services,
          archived: js.archived
        };
}

function toJs(category) {
  return {
          uuid: category.uuid,
          name: category.name,
          description: Js_null.fromOption(category.description),
          parent: Js_null.fromOption(category.parent),
          childCategories: category.childCategories,
          services: category.services,
          archived: category.archived
        };
}

var Category = {};

function fromJs$1(js) {
  return {
          uuid: js.uuid,
          name: js.name,
          description: Caml_option.null_to_opt(js.description),
          priceDefault: Caml_option.null_to_opt(js.priceDefault),
          currency: js.currency,
          category: Caml_option.null_to_opt(js.category),
          preOrdered: js.preOrdered,
          required: js.required,
          archived: js.archived
        };
}

function toJs$1(service) {
  return {
          uuid: service.uuid,
          name: service.name,
          description: Js_null.fromOption(service.description),
          priceDefault: Js_null.fromOption(service.priceDefault),
          currency: service.currency,
          category: Js_null.fromOption(service.category),
          preOrdered: service.preOrdered,
          required: service.required,
          archived: service.archived
        };
}

function makeServiceItem(service) {
  return Types_Catalog.Service.make(service.uuid, service.name, service.description, service.priceDefault, service.currency, RCore.$$Option.map(service.category, (function (c) {
                    return Types_Catalog.Service.Category.make(c.uuid, c.name);
                  })), service.archived);
}

function fromService(service) {
  return {
          TAG: "Service",
          _0: service
        };
}

function fromCategory(category) {
  return {
          TAG: "Category",
          _0: category
        };
}

function fromJsService(service) {
  return {
          TAG: "Service",
          _0: fromJs$1(service)
        };
}

function fromJsCategory(category) {
  return {
          TAG: "Category",
          _0: fromJs(category)
        };
}

function isService(item) {
  if (item.TAG === "Category") {
    return false;
  } else {
    return true;
  }
}

function isCategory(i) {
  return !isService(i);
}

function name(item) {
  return item._0.name;
}

function equal(i1, i2) {
  if (i1.TAG === "Category") {
    if (i2.TAG === "Category") {
      return Uuid.equal(i1._0.uuid, i2._0.uuid);
    } else {
      return false;
    }
  } else if (i2.TAG === "Category") {
    return false;
  } else {
    return Uuid.equal(i1._0.uuid, i2._0.uuid);
  }
}

function uuid(item) {
  return item._0.uuid;
}

var ListItem = {
  fromService: fromService,
  fromCategory: fromCategory,
  fromJsService: fromJsService,
  fromJsCategory: fromJsCategory,
  isService: isService,
  isCategory: isCategory,
  name: name,
  equal: equal,
  uuid: uuid
};

var SelectedService = {};

var SelectedCategory = {};

var Category$1 = {
  Parent: Parent,
  Children: undefined,
  Service: undefined,
  toJs: toJs
};

var Service = {
  Category: Category,
  toJs: toJs$1,
  makeServiceItem: makeServiceItem
};

var OrderedService;

export {
  Category$1 as Category,
  Service ,
  ListItem ,
  SelectedService ,
  SelectedCategory ,
  OrderedService ,
}
/* Uuid Not a pure module */
