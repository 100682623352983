

import * as RCore from "../../libraries/RCore.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";

var decode = JsonDecode.object(function (field) {
      return RCore.$$Option.flatten(field.optional("redirectUrl", JsonDecode.option(JsonDecode.string)));
    });

function create(wire, token, redirect) {
  return Backend.decode(Backend.post(undefined, undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "SignInByToken",
                    VAL: [
                      token,
                      redirect
                    ]
                  }
                }, {
                  NAME: "Obj",
                  VAL: {}
                }), decode);
}

export {
  create ,
}
/* decode Not a pure module */
