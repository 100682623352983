

import * as Radio from "../../../../../../inputs/Radio.mjs";
import * as Types from "../../../../../../../types/Types.mjs";
import * as Utils from "../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Tooltip from "../../../../../../common/Tooltip/Tooltip.mjs";
import * as Optional from "../../../../../../common/Optional.mjs";
import * as AppContext from "../../../../../../../context/AppContext.mjs";
import * as Context_Types from "../../../../../../../context/Context_Types.mjs";
import * as Schedule_State from "../../../../../lib/state/Schedule_State.mjs";
import * as Schedule_Header_JobDurationModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/header/right/settings/job_duration/Schedule_Header_JobDuration.module.css";

var styles = Schedule_Header_JobDurationModuleCss;

var label$p = Utils.Translations.tr("js.schedule.v2.settings.job_duration.label");

var hint$p = Utils.Translations.t("js.schedule.v2.settings.job_duration.hint");

var scheduled$p = Utils.Translations.t("js.schedule.v2.settings.job_duration.scheduled");

var actual$p = Utils.Translations.t("js.schedule.v2.settings.job_duration.actual");

function Schedule_Header_JobDuration(Props) {
  var wire = AppContext.useWire();
  var jobDuration = Schedule_State.useJobDuration();
  var showJobsWithScheduledDuration = Schedule_State.useShowJobsWithScheduledDuration();
  var showJobsWithScheduledDuration$1 = function (_event) {
    showJobsWithScheduledDuration();
  };
  var showJobsWithActualDuration = Schedule_State.useShowJobsWithActualDuration();
  var showJobsWithActualDuration$1 = function (_event) {
    showJobsWithActualDuration();
  };
  var hintRef = Types.ReactRef.use();
  return React.createElement("div", {
              className: styles.container
            }, React.createElement("div", {
                  className: styles.field
                }, React.createElement("label", {
                      className: styles.label
                    }, label$p(wire.ctx), React.createElement(Optional.make, {
                          show: Context_Types.Features.hasFlag("showOldTimeline", wire.ctx.features),
                          children: React.createElement(Tooltip.make, {
                                elementRef: hintRef,
                                position: "top",
                                content: hint$p(wire.ctx),
                                children: React.createElement("span", {
                                      ref: hintRef,
                                      className: styles.hint
                                    })
                              })
                        })), React.createElement(Radio.make, {
                      onChange: showJobsWithScheduledDuration$1,
                      checked: jobDuration === "Scheduled",
                      labelText: scheduled$p(wire.ctx)
                    }), React.createElement(Radio.make, {
                      onChange: showJobsWithActualDuration$1,
                      checked: jobDuration === "Actual",
                      labelText: actual$p(wire.ctx)
                    })));
}

var make = Schedule_Header_JobDuration;

export {
  make ,
}
/* styles Not a pure module */
