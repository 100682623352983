

import * as RCore from "../../../libraries/RCore.mjs";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_SortArrayInt from "rescript/lib/es6/belt_SortArrayInt.js";

function Make(funarg) {
  var median = function (xs) {
    var xsSize = xs.length;
    if (xsSize === 0) {
      return 0;
    }
    Belt_SortArrayInt.stableSortInPlace(xs);
    var half = Js_math.floor(xsSize / 2.0);
    if (xsSize % 2 === 0) {
      return (xs[half - 1 | 0] + xs[half] | 0) / 2.0 | 0;
    } else {
      return xs[half];
    }
  };
  var makeExn = function (nodes) {
    if (nodes.length === 0) {
      return "Empty";
    }
    var mid = median(nodes.map(funarg.finish));
    var centerNodes = RCore.$$Array.keep(nodes, (function (node) {
            if (funarg.start(node) <= mid) {
              return funarg.finish(node) >= mid;
            } else {
              return false;
            }
          }));
    var leftNodes = RCore.$$Array.keep(nodes, (function (node) {
            return funarg.finish(node) < mid;
          }));
    var rightNodes = RCore.$$Array.keep(nodes, (function (node) {
            return funarg.start(node) > mid;
          }));
    var leftNodes$1 = leftNodes.length > 0 ? makeExn(leftNodes) : "Empty";
    var rightNodes$1 = rightNodes.length > 0 ? makeExn(rightNodes) : "Empty";
    return {
            TAG: "Node",
            _0: {
              nodes: centerNodes,
              mid: mid,
              left: leftNodes$1,
              right: rightNodes$1
            }
          };
  };
  var make = function (nodes) {
    try {
      return makeExn(nodes);
    }
    catch (exn){
      return "Empty";
    }
  };
  var unique = function (jobs) {
    return RCore.$$Array.reduce(jobs, [], (function (memo, node) {
                  var inArray = memo.some(function (n) {
                        return funarg.index(n) === funarg.index(node);
                      });
                  if (!inArray) {
                    Js_array.push(node, memo);
                  }
                  return memo;
                }));
  };
  var intersect = function (lo, hi) {
    return function (node) {
      var start = funarg.start(node);
      var finish = funarg.finish(node);
      if (start <= hi) {
        return finish >= lo;
      } else {
        return false;
      }
    };
  };
  var rangeSearch = function (_intervalsOpt, _tree, lo, hi) {
    while(true) {
      var tree = _tree;
      var intervalsOpt = _intervalsOpt;
      var intervals = intervalsOpt !== undefined ? intervalsOpt : [];
      if (typeof tree !== "object") {
        return intervals;
      }
      var match = tree._0;
      var right = match.right;
      var left = match.left;
      var mid = match.mid;
      var nodes = match.nodes;
      var intersect$1 = intersect(lo, hi);
      if (lo <= mid && mid <= hi) {
        var intervals$1 = unique(Belt_Array.concatMany([
                  intervals,
                  RCore.$$Array.keep(nodes, intersect$1)
                ]));
        var intervals$2 = rangeSearch(intervals$1, right, lo, hi);
        _tree = left;
        _intervalsOpt = intervals$2;
        continue ;
      }
      if (hi === mid) {
        return unique(Belt_Array.concatMany([
                        intervals,
                        RCore.$$Array.keep(nodes, intersect$1)
                      ]));
      }
      if (hi > mid) {
        var intervals$3 = unique(Belt_Array.concatMany([
                  intervals,
                  RCore.$$Array.keep(nodes, intersect$1)
                ]));
        _tree = right;
        _intervalsOpt = intervals$3;
        continue ;
      }
      if (hi >= mid) {
        return intervals;
      }
      var intervals$4 = unique(Belt_Array.concatMany([
                intervals,
                RCore.$$Array.keep(nodes, intersect$1)
              ]));
      _tree = left;
      _intervalsOpt = intervals$4;
      continue ;
    };
  };
  return {
          make: make,
          rangeSearch: rangeSearch
        };
}

export {
  Make ,
}
/* No side effect */
