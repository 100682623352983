

import * as Hooks from "../../../../../../../../../libraries/hooks/Hooks.mjs";
import * as React from "react";
import * as Optional from "../../../../../../../../common/Optional.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JobEdit_API from "../../../../../../lib/state/api/JobEdit_API.mjs";

function JobForm_ReportFieldButton(Props) {
  var wire = Props.wire;
  var jobUuid = Props.jobUuid;
  var typeUuid = Props.typeUuid;
  var button = Props.button;
  var id = Props.id;
  var id$1 = Hooks.useId(id);
  var match = button.status;
  var canPress;
  if (match !== undefined) {
    switch (match) {
      case "Triggered" :
      case "Released" :
          canPress = button.reusableButton;
          break;
      case "Active" :
      case "Error" :
          canPress = true;
          break;
      
    }
  } else {
    canPress = false;
  }
  var onClickButton = function ($$event) {
    $$event.preventDefault();
    $$event.stopPropagation();
    if (jobUuid !== undefined) {
      JobEdit_API.ReportFieldButton.Patch.patch(wire, Caml_option.valFromOption(jobUuid), typeUuid);
      return ;
    }
    
  };
  return React.createElement(Optional.make, {
              show: jobUuid !== undefined,
              children: React.createElement("div", undefined, React.createElement("p", undefined, "Request url: " + button.urlValue), React.createElement("button", {
                        id: id$1,
                        disabled: !canPress,
                        onClick: onClickButton
                      }, "Send request"))
            });
}

var make = JobForm_ReportFieldButton;

var $$default = JobForm_ReportFieldButton;

export {
  make ,
  $$default as default,
}
/* Hooks Not a pure module */
