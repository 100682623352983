export const trigger = (current, events, version) => {
  if (events === undefined || events === null) {
    return current
  } else {
    return {
      ...current,
      ...Object.assign(
        ...Object.keys(events).map(e => ({
          [e]: { version, payload: events[e] }
        }))
      )
    }
  }
}

export function watch(events, handlers) {
  for (let e in events) {
    if (e in handlers) {
      handlers[e](events[e])
    }
  }
}

export const select = ({ version, events }, list) => {
  const triggered = list.filter(
    e => events[e] !== null && version === events[e].version
  )

  if (triggered.length > 0) {
    return Object.assign(...triggered.map(e => ({ [e]: events[e].payload })))
  } else {
    return {}
  }
}
