

import * as Fun from "../../../../libraries/Fun.mjs";
import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Select from "../../../inputs/Select.mjs";
import * as Backend from "../../../../libraries/backend/Backend.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Spinner from "../../../common/Spinner/Spinner.mjs";
import * as Optional from "../../../common/Optional.mjs";
import * as FormInput from "../../../inputs/FormInput.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Catalog_Lib from "../Catalog_Lib.mjs";
import * as CurrencyText from "../../../inputs/CurrencyText.mjs";
import * as Service_Form from "./Service_Form.mjs";
import * as Services_API from "./Services_API.mjs";
import * as Catalog_Types from "../Catalog_Types.mjs";
import * as Shared_Lib_IO from "../../../../shared/lib/Shared_Lib_IO.mjs";
import * as Backend_Errors from "../../../../libraries/backend/Backend_Errors.mjs";
import * as Categories_API from "../categories/Categories_API.mjs";
import * as Categories_List from "../categories/Categories_List.mjs";
import * as Catalog_ListEntites from "../Catalog_ListEntites.mjs";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";
import * as Catalog_IndexModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/catalog/Catalog_Index.module.css";

var catalog$p = Utils.Translations.tr("js.admin.settings.title.catalog");

var categories$p = Utils.Translations.tr("js.admin.catalog.categories");

var services$p = Utils.Translations.tr("js.admin.catalog.services");

var addService$p = Utils.Translations.tr("js.admin.catalog.add_service");

var noServices$p = Utils.Translations.tr("js.admin.catalog.no_services");

var filter$p = Utils.Translations.tr("js.admin.catalog.filter");

var name$p = Utils.Translations.tr("js.admin.catalog.form.name");

var category$p = Utils.Translations.tr("js.admin.catalog.form.category");

var priceDefault$p = Utils.Translations.tr("js.admin.catalog.form.price_default");

var description$p = Utils.Translations.tr("js.admin.catalog.form.description");

var externalId$p = Utils.Translations.tr("js.admin.catalog.form.external_id");

var filterName$p = Utils.Translations.t("js.admin.catalog.form.name");

var filterCategory$p = Utils.Translations.t("js.admin.catalog.form.category");

var showMore$p = Utils.Translations.tr("js.admin.catalog.show_more");

var styles = Catalog_IndexModuleCss;

var default_categories = [];

var default_services = [];

var $$default = {
  loaded: false,
  page: "Services",
  categories: default_categories,
  services: default_services,
  fetching: false,
  hasMore: false,
  offset: 0,
  filterName: undefined,
  filterCategory: undefined
};

function Services_Index$ServicesList$Header(Props) {
  var ctx = Props.ctx;
  return React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, name$p(ctx)), React.createElement("th", undefined, category$p(ctx)), React.createElement("th", undefined, priceDefault$p(ctx)), React.createElement("th", undefined, description$p(ctx)), React.createElement("th", undefined, externalId$p(ctx))));
}

function Services_Index$ServicesList$ServiceRow(Props) {
  var ctx = Props.ctx;
  var service = Props.service;
  var categories = Props.categories;
  var onOpen = Props.onOpen;
  var category = React.useMemo((function () {
          return Catalog_Lib.rednerParent(service.categoryUuid, categories);
        }), [
        service,
        categories
      ]);
  var onClick = function (e) {
    e.preventDefault();
    onOpen(service.uuid);
  };
  var value = service.priceDefault;
  return React.createElement("tr", {
              className: styles.tableRow,
              onClick: onClick
            }, React.createElement("td", {
                  className: styles.link
                }, service.name), React.createElement("td", undefined, category), React.createElement("td", undefined, value !== undefined ? React.createElement(CurrencyText.make, {
                        ctx: ctx,
                        currency: service.currency,
                        value: value
                      }) : null), React.createElement("td", undefined, RCore.$$Option.getOr(service.description, "")), React.createElement("td", undefined, RCore.$$Option.getOr(service.externalId, "")));
}

function Services_Index$ServicesList$Filter(Props) {
  var ctx = Props.ctx;
  var name = Props.name;
  var onNameChange = Props.onNameChange;
  var pickedCategory = Props.pickedCategory;
  var categories = Props.categories;
  var onCategoryPick = Props.onCategoryPick;
  var options = React.useMemo((function () {
          return categories.map(function (c) {
                      return Select.$$Option.make(c.name, Catalog_Types.uuidToId(c.uuid));
                    });
        }), [categories]);
  var selectValue = Catalog_Lib.parentSelectValue(pickedCategory, categories);
  return React.createElement("div", {
              className: styles.filter
            }, filter$p(ctx), React.createElement("div", undefined, React.createElement(FormInput.make, {
                      _type: "input",
                      value: RCore.$$Option.getOr(name, ""),
                      onValueChange: onNameChange,
                      wrapperClass: Js_dict.fromList({
                            hd: [
                              styles.filterName,
                              true
                            ],
                            tl: /* [] */0
                          }),
                      placeholder: filterName$p(ctx)
                    })), React.createElement("div", undefined, React.createElement(Select.make, {
                      placeholder: filterCategory$p(ctx),
                      onChange: onCategoryPick,
                      value: selectValue,
                      options: options
                    })));
}

function Services_Index$ServicesList(Props) {
  var param = Props.wire;
  var services = Props.services;
  var filterName = Props.filterName;
  var onFilterNameChange = Props.onFilterNameChange;
  var onFilterCategoryPick = Props.onFilterCategoryPick;
  var filterCategory = Props.filterCategory;
  var hasMore = Props.hasMore;
  var fetching = Props.fetching;
  var onLoadMore = Props.onLoadMore;
  var categories = Props.categories;
  var onCreate = Props.onCreate;
  var onUpdate = Props.onUpdate;
  var onDelete = Props.onDelete;
  var ctx = param.ctx;
  var match = React.useState(function () {
        
      });
  var setOpenedService = match[1];
  var onOpenEdit = function (uuid) {
    setOpenedService(function (param) {
          return Caml_option.some(uuid);
        });
  };
  var onCloseEdit = function () {
    setOpenedService(function (param) {
          
        });
  };
  var renderRow = function (service) {
    return React.createElement(Services_Index$ServicesList$ServiceRow, {
                ctx: ctx,
                service: service,
                categories: categories,
                onOpen: onOpenEdit,
                key: Uuid.toString(service.uuid)
              });
  };
  var renderNewForm = function (onCloseNew) {
    return React.createElement(Service_Form.make, {
                mode: "New",
                service: undefined,
                onClose: onCloseNew,
                categories: categories,
                onCreate: onCreate,
                onUpdate: onUpdate,
                onDelete: onDelete
              });
  };
  var renderEditForm = function (uuid) {
    return React.createElement(Service_Form.make, {
                mode: {
                  NAME: "Edit",
                  VAL: uuid
                },
                service: RCore.$$Array.getBy(services, (function (s) {
                        return Uuid.equal(s.uuid, uuid);
                      })),
                onClose: onCloseEdit,
                categories: categories,
                onCreate: onCreate,
                onUpdate: onUpdate,
                onDelete: onDelete
              });
  };
  var filterRequest = filterName !== undefined || filterCategory !== undefined || fetching ? true : false;
  return React.createElement(React.Fragment, undefined, React.createElement(Catalog_ListEntites.make, {
                  ctx: ctx,
                  entities: services,
                  title: addService$p(ctx),
                  noEntries: noServices$p(ctx),
                  opened: match[0],
                  renderRow: renderRow,
                  renderNewForm: renderNewForm,
                  renderEditForm: renderEditForm,
                  header: React.createElement(Services_Index$ServicesList$Header, {
                        ctx: ctx
                      }),
                  filter: React.createElement(Services_Index$ServicesList$Filter, {
                        ctx: ctx,
                        name: filterName,
                        onNameChange: onFilterNameChange,
                        pickedCategory: filterCategory,
                        categories: categories,
                        onCategoryPick: onFilterCategoryPick
                      }),
                  filterRequest: filterRequest
                }), React.createElement(Optional.make, {
                  show: hasMore,
                  children: React.createElement("div", {
                        className: styles.loadMore
                      }, fetching ? React.createElement(Spinner.make, {}) : React.createElement("div", {
                              className: styles.showMore,
                              onClick: onLoadMore
                            }, showMore$p(ctx)))
                }));
}

function Services_Index(Props) {
  var wire = Props.wire;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return $$default;
      });
  var setState = match[1];
  var state = match[0];
  var $$fetch = function () {
    var loaded = function (response) {
      if (response.TAG === "Ok") {
        var match = response._0;
        var services = match.services;
        var categories = match.categories;
        return setState(function (prev) {
                    return {
                            loaded: true,
                            page: prev.page,
                            categories: categories,
                            services: services,
                            fetching: prev.fetching,
                            hasMore: services.length === 50,
                            offset: prev.offset,
                            filterName: prev.filterName,
                            filterCategory: prev.filterCategory
                          };
                  });
      }
      console.log(response._0);
    };
    Backend.$$finally(Services_API.index(wire), loaded);
  };
  React.useEffect((function () {
          $$fetch();
        }), []);
  var setPage = function (page) {
    setState(function (prev) {
          return {
                  loaded: prev.loaded,
                  page: page,
                  categories: prev.categories,
                  services: prev.services,
                  fetching: prev.fetching,
                  hasMore: prev.hasMore,
                  offset: prev.offset,
                  filterName: prev.filterName,
                  filterCategory: prev.filterCategory
                };
        });
  };
  var handleServiceResponse = function (succCb, errorCb) {
    return function (response) {
      var services = Catalog_Lib.handleServiceResponse(state.services, response)(succCb, errorCb);
      setState(function (prev) {
            return {
                    loaded: prev.loaded,
                    page: prev.page,
                    categories: prev.categories,
                    services: services,
                    fetching: prev.fetching,
                    hasMore: prev.hasMore,
                    offset: prev.offset,
                    filterName: prev.filterName,
                    filterCategory: prev.filterCategory
                  };
          });
    };
  };
  var handleCategoriesResponse = function (succCb, errorCb) {
    return function (response) {
      var categories = Catalog_Lib.handleCategoriesResponse(state.categories, response)(succCb, errorCb);
      setState(function (prev) {
            return {
                    loaded: prev.loaded,
                    page: prev.page,
                    categories: categories,
                    services: prev.services,
                    fetching: prev.fetching,
                    hasMore: prev.hasMore,
                    offset: prev.offset,
                    filterName: prev.filterName,
                    filterCategory: prev.filterCategory
                  };
          });
    };
  };
  var onServiceCreate = function (data, succCb, errorCb) {
    Backend.$$finally(Services_API.create(wire, data), handleServiceResponse(succCb, errorCb));
  };
  var onServiceUpdate = function (uuid, data, succCb, errorCb) {
    Backend.$$finally(Services_API.update(wire, uuid, data), handleServiceResponse(succCb, errorCb));
  };
  var onServiceDelete = function (uuid, succCb, errorCb) {
    Backend.$$finally(Services_API.$$delete(wire, uuid), (function (response) {
            var succResp = function () {
              succCb();
              setState(function (prev) {
                    return {
                            loaded: prev.loaded,
                            page: prev.page,
                            categories: prev.categories,
                            services: RCore.$$Array.keep(prev.services, (function (s) {
                                    return !Uuid.equal(s.uuid, uuid);
                                  })),
                            fetching: prev.fetching,
                            hasMore: prev.hasMore,
                            offset: prev.offset,
                            filterName: prev.filterName,
                            filterCategory: prev.filterCategory
                          };
                  });
            };
            if (response.TAG === "Ok") {
              return succResp();
            }
            var errors = Backend.parseError(response._0);
            if (typeof errors !== "object") {
              console.log(errors);
              return ;
            }
            switch (errors.TAG) {
              case "HTTPError" :
                  var tmp = errors._0[0];
                  if (typeof tmp !== "object") {
                    if (tmp === "NotFound") {
                      return succResp();
                    }
                    console.log(errors);
                    return ;
                  } else {
                    console.log(errors);
                    return ;
                  }
              case "ValidationError" :
                  var e = Json_Decode$JsonCombinators.decode(Backend_Errors.json(errors._0), Services_API.Delete.$$Response.decode);
                  if (e.TAG === "Ok") {
                    return errorCb(e._0.templates);
                  }
                  console.log(e._0);
                  return ;
              default:
                console.log(errors);
                return ;
            }
          }));
  };
  var onCategoryCreate = function (data, succCb, errorCb) {
    Backend.$$finally(Categories_API.create(wire, data), handleCategoriesResponse(succCb, errorCb));
  };
  var onCategoryUpdate = function (uuid, data, succCb, errorCb) {
    Backend.$$finally(Categories_API.update(wire, uuid, data), handleCategoriesResponse(succCb, errorCb));
  };
  var onCategoryDelete = function (uuid, succCb, errorCb) {
    Backend.$$finally(Categories_API.Delete.$$delete(wire, uuid), (function (response) {
            if (response.TAG === "Ok") {
              $$fetch();
              return succCb();
            }
            var errors = Backend.parseError(response._0);
            if (typeof errors !== "object") {
              console.log(errors);
              return ;
            }
            switch (errors.TAG) {
              case "HTTPError" :
                  var tmp = errors._0[0];
                  if (typeof tmp !== "object") {
                    if (tmp === "NotFound") {
                      $$fetch();
                      return succCb();
                    }
                    console.log(errors);
                    return ;
                  } else {
                    console.log(errors);
                    return ;
                  }
              case "ValidationError" :
                  var e = Json_Decode$JsonCombinators.decode(Backend_Errors.json(errors._0), Categories_API.Delete.$$Response.decode);
                  if (e.TAG === "Ok") {
                    var match = e._0;
                    return errorCb(match.descendants, match.products, match.services, match.templates);
                  }
                  console.log(e._0);
                  return ;
              default:
                console.log(errors);
                return ;
            }
          }));
  };
  var newCats = function (prevCats, fetched) {
    return RCore.$$Array.keep(fetched, (function (c) {
                  return !prevCats.some(function (cc) {
                              return Uuid.equal(c.uuid, cc.uuid);
                            });
                }));
  };
  var handleFilterResponse = function (newState) {
    return function (response) {
      if (response !== undefined) {
        if (response.TAG === "Ok") {
          var match = response._0;
          var services = match.services;
          var categories = match.categories;
          return setState(function (prev) {
                      return newState(services, categories, prev);
                    });
        }
        console.log(response._0);
        return ;
      }
      console.log("Error filtering services");
    };
  };
  var filter = function (filterName, filterCategory) {
    setState(function (prev) {
          return {
                  loaded: prev.loaded,
                  page: prev.page,
                  categories: prev.categories,
                  services: prev.services,
                  fetching: true,
                  hasMore: prev.hasMore,
                  offset: prev.offset,
                  filterName: filterName,
                  filterCategory: filterCategory
                };
        });
    var bounds = Utils.objToJson({
          offset: state.offset,
          limit: 50
        });
    var newState = function (services, categories, prev) {
      return {
              loaded: prev.loaded,
              page: prev.page,
              categories: Belt_Array.concatMany([
                    prev.categories,
                    newCats(prev.categories, categories)
                  ]),
              services: services,
              fetching: false,
              hasMore: services.length === 50,
              offset: prev.offset,
              filterName: prev.filterName,
              filterCategory: prev.filterCategory
            };
    };
    Shared_Lib_IO.unsafeRunAsync(Shared_Lib_IO.bimap(Services_API.filter([
                  wire,
                  bounds,
                  filterName,
                  filterCategory
                ]), handleFilterResponse(newState), (function (prim) {
                
              })), (function (prim) {
            
          }));
  };
  var onFilterNameChange = function (input) {
    filter(Fun.optString(input), state.filterCategory);
  };
  var onFilterCategoryPick = function (input) {
    filter(state.filterName, RCore.$$Option.map(input === null ? undefined : Caml_option.some(input), Catalog_Types.idToUuid));
  };
  var onLoadMore = function (e) {
    e.preventDefault();
    setState(function (prev) {
          return {
                  loaded: prev.loaded,
                  page: prev.page,
                  categories: prev.categories,
                  services: prev.services,
                  fetching: true,
                  hasMore: prev.hasMore,
                  offset: prev.offset,
                  filterName: prev.filterName,
                  filterCategory: prev.filterCategory
                };
        });
    var bounds = Utils.objToJson({
          offset: state.offset + 50 | 0,
          limit: 50
        });
    var newState = function (services, categories, prev) {
      return {
              loaded: prev.loaded,
              page: prev.page,
              categories: Belt_Array.concatMany([
                    prev.categories,
                    newCats(prev.categories, categories)
                  ]),
              services: Belt_Array.concatMany([
                    prev.services,
                    services
                  ]),
              fetching: false,
              hasMore: services.length === 50,
              offset: state.offset + 50 | 0,
              filterName: prev.filterName,
              filterCategory: prev.filterCategory
            };
    };
    Shared_Lib_IO.unsafeRunAsync(Shared_Lib_IO.bimap(Services_API.filter([
                  wire,
                  bounds,
                  state.filterName,
                  state.filterCategory
                ]), handleFilterResponse(newState), (function (prim) {
                
              })), (function (prim) {
            
          }));
  };
  var tmp;
  if (state.loaded) {
    var match$1 = state.page;
    tmp = match$1 === "Categories" ? React.createElement(Categories_List.make, {
            wire: wire,
            categories: state.categories,
            onCreate: onCategoryCreate,
            onUpdate: onCategoryUpdate,
            onDelete: onCategoryDelete,
            type_: "Services"
          }) : React.createElement(Services_Index$ServicesList, {
            wire: wire,
            services: state.services,
            filterName: state.filterName,
            onFilterNameChange: onFilterNameChange,
            onFilterCategoryPick: onFilterCategoryPick,
            filterCategory: state.filterCategory,
            hasMore: state.hasMore,
            fetching: state.fetching,
            onLoadMore: onLoadMore,
            categories: state.categories,
            onCreate: onServiceCreate,
            onUpdate: onServiceUpdate,
            onDelete: onServiceDelete
          });
  } else {
    tmp = React.createElement("div", {
          className: "panel-body"
        }, React.createElement(Spinner.make, {}));
  }
  return React.createElement("div", {
              id: "content-wrapper"
            }, React.createElement("div", {
                  className: "page-container narrow"
                }, React.createElement("div", {
                      className: "page-header row"
                    }, React.createElement("h1", {
                          className: "settings-page__hl"
                        }, catalog$p(ctx), React.createElement("span", undefined, services$p(ctx)))), React.createElement("div", {
                      className: "settings-page settings-page_template row"
                    }, React.createElement("ul", {
                          className: "nav nav-tabs"
                        }, React.createElement("li", {
                              className: state.page === "Services" ? "active" : "",
                              onClick: (function (param) {
                                  setPage("Services");
                                })
                            }, React.createElement("a", undefined, services$p(ctx))), React.createElement("li", {
                              className: state.page === "Categories" ? "active" : "",
                              onClick: (function (param) {
                                  setPage("Categories");
                                })
                            }, React.createElement("a", undefined, categories$p(ctx)))), React.createElement("div", {
                          className: "tab-content tab-pane active panel"
                        }, tmp))));
}

var make = Services_Index;

export {
  make ,
}
/* catalog' Not a pure module */
