

import * as El from "../../libraries/El.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as MapShowModuleCss from "/home/runner/work/planado/planado/client/rescript/components/map/MapShow.module.css";

var label$p = Utils.Translations.tr("js.map.legend.colors.label");

var unpublished$p = Utils.Translations.tr("js.map.legend.colors.unpublished");

var published$p = Utils.Translations.tr("js.map.legend.colors.published");

var finished$p = Utils.Translations.tr("js.map.legend.colors.finished");

var styles = MapShowModuleCss;

function iconStyle(s) {
  return El.Cn.concat(styles.legendIcon, s);
}

function MapColorsLegend(Props) {
  var param = Props.wire;
  var ctx = param.ctx;
  return React.createElement("div", {
              className: styles.legend
            }, React.createElement("span", {
                  className: styles.description
                }, label$p(ctx)), React.createElement("div", {
                  className: iconStyle(styles.unpublished)
                }), React.createElement("span", {
                  className: styles.description
                }, unpublished$p(ctx)), React.createElement("div", {
                  className: iconStyle(styles.published)
                }), React.createElement("span", {
                  className: styles.description
                }, published$p(ctx)), React.createElement("div", {
                  className: iconStyle(styles.finished)
                }), React.createElement("span", {
                  className: styles.description
                }, finished$p(ctx)));
}

var make = MapColorsLegend;

export {
  make ,
}
/* label' Not a pure module */
