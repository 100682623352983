

import * as Route from "./types/Route.mjs";
import * as Context from "./context/Context.mjs";
import * as HeaderTypes from "./components/header/HeaderTypes.mjs";

function href() {
  return window.location.origin;
}

function host() {
  return window.location.host;
}

function pathname() {
  return window.location.pathname;
}

function search() {
  return window.location.search;
}

function reload() {
  window.location.reload();
}

function push(param) {
  window.history.pushState(window.history.state, "", Route.pagePath(param.modals, param.path));
}

function back() {
  window.history.back();
}

function onPopState(cb) {
  window.addEventListener("popstate", cb);
}

function offPopState(cb) {
  window.removeEventListener("popstate", cb);
}

var redirect = (function(url) { document.location = url });

var redirectPath = (function(path) { document.location.pathname = path });

function redirectToLogin() {
  redirectPath("/login");
}

function redirectToRoot(wire) {
  if (!Context.authorized(wire.ctx)) {
    return redirectToLogin();
  }
  var menu = HeaderTypes.Menu.make(wire.ctx);
  var route = HeaderTypes.Menu.defaultRoute(menu);
  if (route !== undefined) {
    return redirectPath(Route.pagePath(undefined, route));
  }
  
}

export {
  href ,
  host ,
  pathname ,
  search ,
  reload ,
  push ,
  back ,
  onPopState ,
  offPopState ,
  redirect ,
  redirectToLogin ,
  redirectToRoot ,
}
/* Route Not a pure module */
