

import Panel_link from "planado/containers/map/panel_link";

var make = Panel_link;

export {
  make ,
}
/* make Not a pure module */
