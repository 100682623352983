import React, { Component } from 'react'
import jQuery from 'jquery'
import { showTime } from 'planado/utils/time/index.js'

export class Stats extends Component {
  componentDidMount() {
    const { chart, collection } = this.props

    chart.on('change:activeDate', () => this.forceUpdate(), this)
    collection.on('add remove reset sort', () => this.forceUpdate(), this)

    jQuery(this.stats)
      .find('[title]')
      .tooltip({ animation: false })
  }

  componentWillUnmount() {
    const { chart, collection } = this.props

    chart.off(null, null, this)
    collection.off(null, null, this)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.collection === nextProps.collection) {
      return
    }

    nextProps.collection.on(
      'add remove reset sort',
      () => this.forceUpdate(),
      this
    )
  }

  render() {
    const { collection, ctx } = this.props
    let todayJobs = collection.forSingleDay(this.props.chart.activeDate())

    let successful = todayJobs.filter(
      job => job.finished() && job.get('successful')
    ).length
    let failed = todayJobs.filter(
      job => job.finished() && !job.get('successful')
    ).length
    let suspended = todayJobs.filter(job => job.suspended()).length
    let inProgress = todayJobs.filter(job => job.started() || job.enRoute())
      .length
    let rest = todayJobs.length - successful - failed - suspended - inProgress

    let successfulProgress = 0
    let failedProgress = 0
    let suspendedProgress = 0
    let inProgressProgress = 0
    let restProgress = 0

    if (todayJobs.length > 0) {
      successfulProgress = Math.round((successful / todayJobs.length) * 100)
      failedProgress = Math.round((failed / todayJobs.length) * 100)
      suspendedProgress = Math.round((suspended / todayJobs.length) * 100)
      inProgressProgress = Math.round((inProgress / todayJobs.length) * 100)
      restProgress =
        100 -
        successfulProgress -
        failedProgress -
        suspendedProgress -
        inProgressProgress
    }

    let todayHuman = showTime(this.props.chart.activeDate(), 'date_short', ctx)

    return (
      <div
        className="job-stats"
        ref={div => {
          this.stats = div
        }}
      >
        <h4 className="today">
          {todayHuman} —{' '}
          {ctx.t('js.schedule.jobs_count', { count: todayJobs.length })}
        </h4>
        <div className="progress">
          <div
            className="progress-bar progress-finish"
            title={ctx.t('common.job_groups.finished')}
            data-placement="right"
            data-container="body"
            style={{
              width: successfulProgress + '%',
              display: successfulProgress ? 'block' : 'none'
            }}
          >
            {successful}
          </div>
          <div
            className="progress-bar progress-bar-danger"
            title={ctx.t('common.job_groups.failed')}
            data-placement="right"
            data-container="body"
            style={{
              width: failedProgress + '%',
              display: failedProgress ? 'block' : 'none'
            }}
          >
            {failed}
          </div>
          <div
            className="progress-bar progress-bar-success"
            title={ctx.t('common.job_groups.in_progress')}
            data-placement="right"
            data-container="body"
            style={{
              width: inProgressProgress + '%',
              display: inProgressProgress ? 'block' : 'none'
            }}
          >
            {inProgress}
          </div>
          <div
            title={ctx.t('common.job_groups.suspended')}
            className="progress-bar progress-bar-warning"
            style={{
              width: suspendedProgress + '%',
              display: suspendedProgress ? 'block' : 'none'
            }}
          >
            {suspended}
          </div>
          <div
            title={ctx.t('common.job_groups.unfinished')}
            data-placement="right"
            data-container="body"
            className="progress-bar progress-bar-info"
            style={{
              width: restProgress + '%',
              display: restProgress ? 'block' : 'none'
            }}
          >
            {rest}
          </div>
        </div>
      </div>
    )
  }
}
