

import * as El from "../libraries/El.mjs";
import * as React from "react";
import * as Runtime from "../Runtime.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Pages_WrapperModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/Pages_Wrapper.module.css";

var styles = Pages_WrapperModuleCss;

function Pages_Wrapper(Props) {
  var subscriptions = Props.subscriptions;
  var children = Props.children;
  var match = React.useState(function () {
        return false;
      });
  var setLoader = match[1];
  var current = React.useRef(undefined);
  var reset = function (request) {
    var match = current.current;
    if (match !== undefined && request !== undefined && Caml_obj.equal(match.uuid, request.uuid)) {
      current.current = undefined;
      return setLoader(function (param) {
                  return false;
                });
    }
    
  };
  React.useEffect((function () {
          var unsub = subscriptions.tracker.subscribe(function (trackerEvent) {
                var match = current.current;
                var r = trackerEvent._0;
                if (r.TAG === "Started") {
                  var silent = r._1;
                  current.current = r._0;
                  return setLoader(function (param) {
                              return !silent;
                            });
                }
                if (match === undefined) {
                  return ;
                }
                var r$1 = r._0;
                if (!Caml_obj.equal(r$1.uuid, match.uuid)) {
                  return ;
                }
                var diff = r$1.updatedAt.valueOf() - match.createdAt.valueOf();
                if (diff > 500.0) {
                  return reset(current.current);
                }
                var request = current.current;
                Runtime.Timeout.defer(500.0 - diff | 0, (function () {
                        reset(request);
                      }));
              });
          return (function () {
                    unsub();
                  });
        }), []);
  var className = El.Cn.concat(styles.overlay, match[0] ? "" : styles.hideOverlay);
  return React.createElement("div", {
              className: El.Cn.concat("wrapper", styles.wrapper)
            }, React.createElement("div", {
                  className: className
                }), children);
}

var make = Pages_Wrapper;

export {
  make ,
}
/* styles Not a pure module */
