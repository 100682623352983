

import * as RCore from "./RCore.mjs";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import BigJs from "big.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Shared_Lib_String from "../shared/lib/Shared_Lib_String.mjs";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

function isIntString(str) {
  var intRegex = /^(0|-?[1-9][0-9]{0,29})$/;
  return intRegex.test(str);
}

function isDecimalString(str) {
  var decimalRegex = /^-?(0|[1-9][0-9]{0,15})(\.\d{1,14})?$/;
  return decimalRegex.test(str);
}

function removeSpacesAndLetters(str) {
  var regex = /[^\d.,-]/g;
  return Js_string.replaceByRe(regex, "", str);
}

function replace(oldValue, newValue, string) {
  return Shared_Lib_String.replaceEach(string, oldValue, newValue);
}

function removeDelimiters(delimiter, string) {
  return replace(delimiter, "", string);
}

function replaceCommasToDots(x) {
  return replace(",", ".", x);
}

function countDots(value) {
  return RCore.$$Array.keep((function (__x) {
                  return Js_string.split("", __x);
                })(value), (function (v) {
                return v === ".";
              })).length;
}

function countCommas(value) {
  return RCore.$$Array.keep((function (__x) {
                  return Js_string.split("", __x);
                })(value), (function (v) {
                return v === ",";
              })).length;
}

function addThousandsSeparator(number, startGroupingFrom, delimiter) {
  if (number.length < startGroupingFrom) {
    return number;
  }
  var regex = /(\d)(?=(\d\d\d)+(?!\d))/g;
  var matchFn = function (group, _offset, _wholeString) {
    return group + delimiter;
  };
  return Js_string.unsafeReplaceBy0(regex, matchFn, number);
}

function hasFractionalPart(number, separator) {
  return RCore.$$Option.isSome(Js_string.split(separator, number)[1]);
}

var Utils = {
  removeSpacesAndLetters: removeSpacesAndLetters,
  replace: replace,
  removeDelimiters: removeDelimiters,
  replaceCommasToDots: replaceCommasToDots,
  countDots: countDots,
  countCommas: countCommas,
  addThousandsSeparator: addThousandsSeparator,
  hasFractionalPart: hasFractionalPart
};

function toString(prim) {
  return prim.toString();
}

var constructor = BigJs();

constructor.DP = 0;

constructor.PE = 31;

function make(value) {
  try {
    return Caml_option.some(constructor(value));
  }
  catch (raw__e){
    var _e = Caml_js_exceptions.internalToOCamlException(raw__e);
    if (_e.RE_EXN_ID === Js_exn.$$Error) {
      return ;
    }
    throw _e;
  }
}

function toString$1(prim) {
  return prim.toString();
}

function toFixed(prim0, prim1) {
  return prim0.toFixed(prim1);
}

var constructor$1 = BigJs();

constructor$1.NE = -15;

function make$1(value) {
  try {
    return Caml_option.some(constructor$1(value));
  }
  catch (raw__e){
    var _e = Caml_js_exceptions.internalToOCamlException(raw__e);
    if (_e.RE_EXN_ID === Js_exn.$$Error) {
      return ;
    }
    throw _e;
  }
}

function parse(value, ctx) {
  var value$1 = removeSpacesAndLetters(value);
  if (value$1.length === 0) {
    return {
            TAG: "Error",
            _0: "EmptyString"
          };
  }
  var dotsCount = countDots(value$1);
  var commasCount = countCommas(value$1);
  var refinedValue;
  var exit = 0;
  if (dotsCount !== 0) {
    if (dotsCount !== 1) {
      exit = 1;
    } else if (commasCount !== 0) {
      if (commasCount !== 1) {
        exit = 1;
      } else {
        var delimiter = ctx.localizator.numbers.delimiter;
        var separator = ctx.localizator.numbers.separator;
        refinedValue = (function (__x) {
              return replace(separator, ".", __x);
            })(removeDelimiters(delimiter, value$1));
      }
    } else {
      refinedValue = value$1;
    }
  } else if (commasCount !== 0) {
    if (commasCount !== 1) {
      exit = 1;
    } else {
      refinedValue = replaceCommasToDots(value$1);
    }
  } else {
    refinedValue = value$1;
  }
  if (exit === 1) {
    refinedValue = dotsCount > commasCount ? replaceCommasToDots(removeDelimiters(".", value$1)) : (
        commasCount > dotsCount ? removeDelimiters(",", value$1) : ""
      );
  }
  var decimal = RCore.$$Option.map(make$1(refinedValue), toString$1);
  if (decimal !== undefined) {
    if (isDecimalString(decimal)) {
      return {
              TAG: "Ok",
              _0: decimal
            };
    } else {
      return {
              TAG: "Error",
              _0: "DoesNotMatchDecimalRegex"
            };
    }
  } else {
    return {
            TAG: "Error",
            _0: "ParseNumberStringError"
          };
  }
}

var FromString = {
  parse: parse
};

var Decimal = {
  toString: toString$1,
  toFixed: toFixed,
  constructor: constructor$1,
  make: make$1,
  FromString: FromString,
  fromString: parse
};

function format(delimiter, separator, startGroupingFromOpt, number) {
  var startGroupingFrom = startGroupingFromOpt !== undefined ? startGroupingFromOpt : 5;
  var negativeMark = Js_string.charAt(0, number) === "-" ? "-" : "";
  var nonNegativeNumber = Shared_Lib_String.replaceFirst(number, "-", "");
  var match = Js_string.split(".", nonNegativeNumber);
  var len = match.length;
  if (len >= 3) {
    return "";
  }
  switch (len) {
    case 0 :
        return "";
    case 1 :
        var integer = match[0];
        var integer$1 = addThousandsSeparator(integer, startGroupingFrom, delimiter);
        return negativeMark + integer$1;
    case 2 :
        var integer$2 = match[0];
        var fractional = match[1];
        var integer$3 = addThousandsSeparator(integer$2, startGroupingFrom, delimiter);
        return negativeMark + integer$3 + separator + fractional;
    
  }
}

function formatFloat(ctx, $$float) {
  var match = ctx.localizator.numbers;
  var numberString = $$float.toString();
  return format(match.delimiter, match.separator, undefined, numberString);
}

var BigJs$1 = {
  makeConstructor: (function (prim) {
      return BigJs();
    })
};

var $$BigInt = {
  toString: toString,
  make: make
};

export {
  isIntString ,
  Utils ,
  BigJs$1 as BigJs,
  $$BigInt ,
  Decimal ,
  format ,
  formatFloat ,
}
/* constructor Not a pure module */
