

import * as Link from "../../../../../common/Link/Link.mjs";
import * as React from "react";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_Types_Job from "../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as Schedule_Types_JobCore from "../../../../lib/types/job/Schedule_Types_JobCore.mjs";
import * as Schedule_JobPreviewFooterDates from "./Schedule_JobPreviewFooterDates.mjs";
import * as Schedule_JobPreviewFooterStatus from "./Schedule_JobPreviewFooterStatus.mjs";
import * as Schedule_JobPreviewFooterModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/popovers/job_preview/footer/Schedule_JobPreviewFooter.module.css";

var styles = Schedule_JobPreviewFooterModuleCss;

function Schedule_JobPreviewFooter$Address(Props) {
  var job = Props.job;
  var address = Schedule_Types_Job.ScheduledJob.address(job);
  if (address !== undefined) {
    return React.createElement("div", {
                className: styles.address
              }, Schedule_Types_JobCore.Address.toString(Caml_option.valFromOption(address)));
  } else {
    return null;
  }
}

var make = React.memo(Schedule_JobPreviewFooter$Address);

function Schedule_JobPreviewFooter$Client(Props) {
  var job = Props.job;
  var wire = AppContext.useWire();
  var match = Schedule_Types_Job.ScheduledJob.client(job);
  if (match === undefined) {
    return null;
  }
  if (match.NAME === "Client") {
    var match$1 = match.VAL;
    return React.createElement("div", {
                className: styles.client
              }, React.createElement(Link.make, {
                    wire: wire,
                    route: {
                      NAME: "Client",
                      VAL: "Index"
                    },
                    modals: {
                      hd: {
                        NAME: "Client",
                        VAL: {
                          NAME: "Edit",
                          VAL: match$1.uuid
                        }
                      },
                      tl: /* [] */0
                    },
                    target: "blank",
                    children: match$1.name
                  }));
  }
  var match$2 = match.VAL;
  return React.createElement("div", {
              className: styles.client
            }, React.createElement(Link.make, {
                  wire: wire,
                  route: {
                    NAME: "Site",
                    VAL: "Index"
                  },
                  modals: {
                    hd: {
                      NAME: "Site",
                      VAL: {
                        NAME: "Edit",
                        VAL: match$2.uuid
                      }
                    },
                    tl: /* [] */0
                  },
                  target: "blank",
                  children: match$2.name
                }));
}

var make$1 = React.memo(Schedule_JobPreviewFooter$Client);

function Schedule_JobPreviewFooter(Props) {
  var job = Props.job;
  return React.createElement("div", {
              className: styles.footer
            }, React.createElement(make, {
                  job: job
                }), React.createElement(make$1, {
                  job: job
                }), React.createElement(Schedule_JobPreviewFooterDates.make, {
                  job: job
                }), React.createElement(Schedule_JobPreviewFooterStatus.make, {
                  job: job
                }));
}

var make$2 = React.memo(Schedule_JobPreviewFooter);

export {
  make$2 as make,
}
/* styles Not a pure module */
