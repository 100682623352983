


function Optional(Props) {
  var children = Props.children;
  var show = Props.show;
  if (show) {
    return children;
  } else {
    return null;
  }
}

var make = Optional;

export {
  make ,
}
/* No side effect */
