

import * as Uuid from "../../../../../../../../bindings/uuid/Uuid.mjs";
import * as Route from "../../../../../../../../types/Route.mjs";
import * as Utils from "../../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as JobEdit_Hooks from "../../../../../lib/hooks/JobEdit_Hooks.mjs";
import * as Shared_Lib_Array from "../../../../../../../../shared/lib/Shared_Lib_Array.mjs";
import * as JobForm_ScheduleShiftsWarningModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/edit/form/components/body/schedule_warnings/shifts_warning/JobForm_ScheduleShiftsWarning.module.css";

function headline$p(count, c) {
  return Utils.Translations.txr("js.jobs.messages.shifts_not_match")({
              count: count
            }, c);
}

function andNWorkersMore$p(count, c) {
  return Utils.Translations.txr("js.jobs.messages.and_n_workers_more")({
              count: count
            }, c);
}

var styles = JobForm_ScheduleShiftsWarningModuleCss;

function JobForm_ScheduleShiftsWarning(Props) {
  var wire = Props.wire;
  var assignees = Props.assignees;
  var scheduledAt = Props.scheduledAt;
  var scheduledDuration = Props.scheduledDuration;
  var shownWorkersCountOpt = Props.shownWorkersCount;
  var shownWorkersCount = shownWorkersCountOpt !== undefined ? shownWorkersCountOpt : 3;
  var outOfShiftsWorkers = JobEdit_Hooks.useOutOfShiftsWorkers(wire, assignees, scheduledAt, scheduledDuration);
  var sizeOfWorkers = outOfShiftsWorkers.length;
  var makeWorkersBlock = function (workers) {
    var __x = workers.map(function (worker) {
          return React.createElement("a", {
                      key: Uuid.toString(worker.uuid),
                      className: styles.warning,
                      href: Route.pagePath(undefined, {
                            NAME: "User",
                            VAL: {
                              NAME: "Custom",
                              VAL: {
                                NAME: "Edit",
                                VAL: worker.uuid
                              }
                            }
                          }),
                      target: "_blank"
                    }, worker.name);
        });
    return Shared_Lib_Array.intersperse(", ", __x);
  };
  if (sizeOfWorkers === 0) {
    return null;
  }
  if (sizeOfWorkers <= shownWorkersCount) {
    return React.createElement("div", {
                className: styles.warnings
              }, headline$p(sizeOfWorkers, wire.ctx), makeWorkersBlock(outOfShiftsWorkers));
  }
  var remainingCount = sizeOfWorkers - shownWorkersCount | 0;
  return React.createElement("div", {
              className: styles.warnings
            }, headline$p(sizeOfWorkers, wire.ctx), makeWorkersBlock((function (__x) {
                      return Shared_Lib_Array.take(shownWorkersCount, __x);
                    })(outOfShiftsWorkers)), andNWorkersMore$p(remainingCount, wire.ctx));
}

var make = JobForm_ScheduleShiftsWarning;

export {
  make ,
}
/* styles Not a pure module */
