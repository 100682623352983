import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { CustomFields } from 'planado/components/admin/templates/show/fields'
import * as actions from 'planado/actions/admin/templates'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
})

const CustomFieldsLink = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomFields)

export default CustomFieldsLink
