

import * as Link from "../../../../../common/Link/Link.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as ModalQuery from "../../../../../../types/ModalQuery.mjs";
import * as JobEdit_API from "../api/JobEdit_API.mjs";
import * as JobEdit_Types from "../../types/JobEdit_Types.mjs";
import * as Shared_Lib_IO from "../../../../../../shared/lib/Shared_Lib_IO.mjs";
import * as Backend_Errors from "../../../../../../libraries/backend/Backend_Errors.mjs";
import * as MessengerTypes from "../../../../../messenger/MessengerTypes.mjs";
import * as JobEdit_FormStateReducerHelperModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/edit/lib/state/reducer/JobEdit_FormStateReducerHelper.module.css";

var payloadTooLarge$p = Utils.Translations.t("js.jobs.messages.payload_too_large");

var serverError$p = Utils.Translations.t("js.jobs.messages.server_error");

var unprocessableEntity$p = Utils.Translations.t("js.jobs.messages.unprocessable_entity");

var job$p = Utils.Translations.tr("js.jobs.messages.job");

var serialNo$p = Utils.Translations.txr("js.jobs.messages.serial_no");

var created$p = Utils.Translations.tr("js.jobs.messages.created");

var updated$p = Utils.Translations.tr("js.jobs.messages.updated");

var deleted$p = Utils.Translations.tr("js.jobs.messages.deleted");

var canceled$p = Utils.Translations.tr("js.jobs.messages.canceled");

var styles = JobEdit_FormStateReducerHelperModuleCss;

function notify(param, text) {
  param.subscriptions.messenger.trigger({
        TAG: "Show",
        _0: MessengerTypes.Message.make(undefined, "Danger", {
              TAG: "Text",
              _0: text
            })
      });
}

function notifyJobSubmit(wire, param) {
  var status = param.status;
  var ctx = wire.ctx;
  var statusText;
  switch (status) {
    case "Created" :
        statusText = created$p(ctx);
        break;
    case "Updated" :
        statusText = updated$p(ctx);
        break;
    case "Canceled" :
        statusText = canceled$p(ctx);
        break;
    case "Deleted" :
        statusText = deleted$p(ctx);
        break;
    
  }
  var serialNumber = serialNo$p({
        serial_no: param.serialNo
      }, ctx);
  var msg;
  msg = status === "Deleted" ? React.createElement("div", {
          className: styles.deleteMessage
        }, serialNumber) : React.createElement(Link.make, {
          wire: wire,
          route: wire.route.path,
          modals: {
            hd: {
              NAME: "Job",
              VAL: {
                NAME: "Edit",
                VAL: ModalQuery.Job.EditOptions.make(undefined, undefined, param.uuid)
              }
            },
            tl: /* [] */0
          },
          className: styles.submitMessage,
          children: serialNumber
        });
  var msgBody = React.createElement("div", {
        className: styles.messageBody
      }, job$p(ctx), msg, statusText);
  wire.subscriptions.messenger.trigger({
        TAG: "Show",
        _0: MessengerTypes.Message.make(undefined, "Success", {
              TAG: "ReactElement",
              _0: msgBody
            })
      });
}

function lock(state, saving) {
  var init = state.form;
  return {
          TAG: "Update",
          _0: {
            form: {
              id: init.id,
              locked: true,
              saving: saving,
              events: init.events,
              confirmation: init.confirmation,
              confirmationLocked: init.confirmationLocked,
              showHistory: init.showHistory,
              errors: init.errors,
              autoTerritory: init.autoTerritory
            },
            job: state.job,
            changedData: state.changedData
          }
        };
}

function unlock(state) {
  var init = state.form;
  return {
          TAG: "Update",
          _0: {
            form: {
              id: init.id,
              locked: false,
              saving: false,
              events: init.events,
              confirmation: init.confirmation,
              confirmationLocked: init.confirmationLocked,
              showHistory: init.showHistory,
              errors: init.errors,
              autoTerritory: init.autoTerritory
            },
            job: state.job,
            changedData: state.changedData
          }
        };
}

function updateConfirmation(state, confirmation) {
  var init = state.form;
  return {
          TAG: "Update",
          _0: {
            form: {
              id: init.id,
              locked: init.locked,
              saving: init.saving,
              events: init.events,
              confirmation: confirmation,
              confirmationLocked: init.confirmationLocked,
              showHistory: init.showHistory,
              errors: init.errors,
              autoTerritory: init.autoTerritory
            },
            job: state.job,
            changedData: state.changedData
          }
        };
}

function removeJob(state, uuid, wire) {
  var init = state.form;
  return {
          TAG: "UpdateWithIO",
          _0: {
            form: {
              id: init.id,
              locked: init.locked,
              saving: init.saving,
              events: init.events,
              confirmation: init.confirmation,
              confirmationLocked: true,
              showHistory: init.showHistory,
              errors: init.errors,
              autoTerritory: init.autoTerritory
            },
            job: state.job,
            changedData: state.changedData
          },
          _1: Shared_Lib_IO.bimap(JobEdit_API.Job.Delete.$$delete(wire, uuid), (function (response) {
                  return {
                          TAG: "Form",
                          _0: {
                            TAG: "Destroyed",
                            _0: response
                          }
                        };
                }), (function (param) {
                  return {
                          TAG: "Form",
                          _0: "NoOp"
                        };
                }))
        };
}

function toggleHistory(state) {
  var init = state.form;
  return {
          TAG: "Update",
          _0: {
            form: {
              id: init.id,
              locked: init.locked,
              saving: init.saving,
              events: init.events,
              confirmation: init.confirmation,
              confirmationLocked: init.confirmationLocked,
              showHistory: !state.form.showHistory,
              errors: init.errors,
              autoTerritory: init.autoTerritory
            },
            job: state.job,
            changedData: state.changedData
          }
        };
}

function submitJob(state, subroute, wire, payload) {
  var init = state.form;
  var io = subroute.NAME === "Edit" ? JobEdit_API.Job.Patch.patch(wire, subroute.VAL.uuid, state.changedData, payload) : JobEdit_API.Job.Post.post(wire, state.job, payload);
  return {
          TAG: "UpdateWithIO",
          _0: {
            form: {
              id: init.id,
              locked: true,
              saving: true,
              events: init.events,
              confirmation: init.confirmation,
              confirmationLocked: init.confirmationLocked,
              showHistory: init.showHistory,
              errors: init.errors,
              autoTerritory: init.autoTerritory
            },
            job: state.job,
            changedData: state.changedData
          },
          _1: Shared_Lib_IO.bimap(io, (function (r) {
                  return {
                          TAG: "Form",
                          _0: {
                            TAG: "SubmitJobResult",
                            _0: r
                          }
                        };
                }), (function (param) {
                  return {
                          TAG: "Form",
                          _0: "NoOp"
                        };
                }))
        };
}

function submitJobResult(state, wire, result) {
  var ctx = wire.ctx;
  if (result.TAG === "Ok") {
    var response = result._0;
    return {
            TAG: "SideEffect",
            _0: (function (param) {
                var state = param.state;
                notifyJobSubmit(wire, response);
                state.form.events.trigger("Unlock");
                state.form.events.trigger({
                      TAG: "Submit",
                      _0: response.uuid
                    });
              })
          };
  }
  var error = result._0;
  var handleErrors = function (notificationText, errors) {
    var init = state.form;
    var init$1 = state.form.errors;
    return {
            TAG: "UpdateWithSideEffect",
            _0: {
              form: {
                id: init.id,
                locked: false,
                saving: false,
                events: init.events,
                confirmation: init.confirmation,
                confirmationLocked: init.confirmationLocked,
                showHistory: init.showHistory,
                errors: {
                  job: init$1.job,
                  response: errors,
                  customFields: init$1.customFields
                },
                autoTerritory: init.autoTerritory
              },
              job: state.job,
              changedData: state.changedData
            },
            _1: (function (param) {
                notify(wire, notificationText);
              })
          };
  };
  if (typeof error === "object") {
    switch (error.TAG) {
      case "HTTPError" :
          var tmp = error._0[0];
          if (typeof tmp !== "object") {
            switch (tmp) {
              case "PayloadTooLarge" :
                  return handleErrors(payloadTooLarge$p(ctx), Backend_Errors.empty);
              case "UnprocessableEntity" :
                  return handleErrors(unprocessableEntity$p(ctx), Backend_Errors.empty);
              default:
                
            }
          }
          break;
      case "ValidationError" :
          var errors = error._0;
          var allKeys = Backend_Errors.allErrorKeys(errors);
          var text = RCore.$$Option.getOr(RCore.$$Option.map(RCore.$$Option.flatMap((function (__x) {
                            return Backend_Errors.getMany(errors, __x);
                          })(allKeys), (function (__x) {
                          return __x[0];
                        })), Utils.capitalize), serverError$p(ctx));
          return handleErrors(text, errors);
      default:
        
    }
  }
  return {
          TAG: "SideEffect",
          _0: (function (param) {
              param.state.form.events.trigger("Unlock");
              notify(wire, serverError$p(ctx));
            })
        };
}

function cancelJob(state, wire, data, subroute) {
  if (subroute.NAME !== "Edit") {
    return "NoUpdate";
  }
  var init = state.form;
  return {
          TAG: "UpdateWithIO",
          _0: {
            form: {
              id: init.id,
              locked: true,
              saving: true,
              events: init.events,
              confirmation: init.confirmation,
              confirmationLocked: init.confirmationLocked,
              showHistory: init.showHistory,
              errors: init.errors,
              autoTerritory: init.autoTerritory
            },
            job: state.job,
            changedData: state.changedData
          },
          _1: Shared_Lib_IO.bimap(JobEdit_API.Job.Patch.cancel(wire, subroute.VAL.uuid, JobEdit_Types.Form.CancelJob.toJs(data)), (function (r) {
                  return {
                          TAG: "Form",
                          _0: {
                            TAG: "SubmitJobResult",
                            _0: r
                          }
                        };
                }), (function (param) {
                  return {
                          TAG: "Form",
                          _0: "NoOp"
                        };
                }))
        };
}

function submitWorkflowError(state, wire, errors) {
  var ctx = wire.ctx;
  switch (errors.TAG) {
    case "ClientSiteErrors" :
    case "CustomFieldErrors" :
        break;
    case "BuildPayloadError" :
        return "NoUpdate";
    
  }
  var init = state.form;
  return {
          TAG: "UpdateWithSideEffect",
          _0: {
            form: {
              id: init.id,
              locked: init.locked,
              saving: init.saving,
              events: init.events,
              confirmation: init.confirmation,
              confirmationLocked: init.confirmationLocked,
              showHistory: init.showHistory,
              errors: errors._0,
              autoTerritory: init.autoTerritory
            },
            job: state.job,
            changedData: state.changedData
          },
          _1: (function (param) {
              notify(wire, unprocessableEntity$p(ctx));
            })
        };
}

var API;

function payloadToObj(prim) {
  return prim;
}

export {
  API ,
  payloadToObj ,
  notify ,
  notifyJobSubmit ,
  lock ,
  unlock ,
  updateConfirmation ,
  removeJob ,
  toggleHistory ,
  submitJob ,
  submitJobResult ,
  cancelJob ,
  submitWorkflowError ,
}
/* payloadTooLarge' Not a pure module */
