

import * as RCore from "../../libraries/RCore.mjs";
import * as Types from "../../types/Types.mjs";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactDates from "react-dates";

function DatePicker(Props) {
  var date = Props.date;
  var onDateChange = Props.onDateChange;
  var isDayHighlighted = Props.isDayHighlighted;
  var renderDay = Props.renderDay;
  var numberOfMonths = Props.numberOfMonths;
  var firstDayOfWeekOpt = Props.firstDayOfWeek;
  var navPrev = Props.navPrev;
  var navNext = Props.navNext;
  var daySize = Props.daySize;
  var hideKeyboardShortcutsPanel = Props.hideKeyboardShortcutsPanel;
  var enableOutsideDays = Props.enableOutsideDays;
  var onPrevMonthClick = Props.onPrevMonthClick;
  var onNextMonthClick = Props.onNextMonthClick;
  var onOutsideClick = Props.onOutsideClick;
  var firstDayOfWeek = firstDayOfWeekOpt !== undefined ? firstDayOfWeekOpt : "Monday";
  var tmp = {
    firstDayOfWeek: Types.Time.WeekDay.toInt(firstDayOfWeek),
    focused: RCore.$$Option.isSome(date)
  };
  if (date !== undefined) {
    tmp.date = Caml_option.valFromOption(date);
  }
  if (onDateChange !== undefined) {
    tmp.onDateChange = Caml_option.valFromOption(onDateChange);
  }
  if (isDayHighlighted !== undefined) {
    tmp.isDayHighlighted = Caml_option.valFromOption(isDayHighlighted);
  }
  if (renderDay !== undefined) {
    tmp.renderDay = Caml_option.valFromOption(renderDay);
  }
  if (numberOfMonths !== undefined) {
    tmp.numberOfMonths = numberOfMonths;
  }
  if (navPrev !== undefined) {
    tmp.navPrev = Caml_option.valFromOption(navPrev);
  }
  if (navNext !== undefined) {
    tmp.navNext = Caml_option.valFromOption(navNext);
  }
  if (daySize !== undefined) {
    tmp.daySize = daySize;
  }
  if (hideKeyboardShortcutsPanel !== undefined) {
    tmp.hideKeyboardShortcutsPanel = hideKeyboardShortcutsPanel;
  }
  if (enableOutsideDays !== undefined) {
    tmp.enableOutsideDays = enableOutsideDays;
  }
  if (onPrevMonthClick !== undefined) {
    tmp.onPrevMonthClick = Caml_option.valFromOption(onPrevMonthClick);
  }
  if (onNextMonthClick !== undefined) {
    tmp.onNextMonthClick = Caml_option.valFromOption(onNextMonthClick);
  }
  if (onOutsideClick !== undefined) {
    tmp.onOutsideClick = Caml_option.valFromOption(onOutsideClick);
  }
  return React.createElement(ReactDates.DayPickerSingleDateController, tmp);
}

var make = DatePicker;

export {
  make ,
}
/* Types Not a pure module */
