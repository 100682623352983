

import * as El from "../../../libraries/El.mjs";
import * as Fun from "../../../libraries/Fun.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as MapTypes from "../MapTypes.mjs";
import * as Optional from "../../common/Optional.mjs";
import * as MapJobsItem from "./MapJobsItem.mjs";
import * as MapJobsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/map/jobs/MapJobs.module.css";

var styles = MapJobsModuleCss;

function status$p(status) {
  return Utils.Translations.tr("js.map.scheduling.statuses." + status);
}

var statuses = [
  "Scheduled",
  "Assigned",
  "Published",
  "EnRoute",
  "Started",
  "Suspended",
  "Finished"
];

function mapStatus(s) {
  if (s === "Changed") {
    return "Assigned";
  } else {
    return s;
  }
}

function filterJob(s) {
  return function (param) {
    return mapStatus(param.status) === s;
  };
}

function groupByStatus(jobs) {
  return RCore.$$Array.keep(statuses.map(function (s) {
                  return [
                          s,
                          RCore.$$Array.keep(jobs, filterJob(s))
                        ];
                }), (function (v) {
                return Fun.pos(v[1].length);
              }));
}

function MapJobsInProgress(Props) {
  var wire = Props.wire;
  var focus = Props.focus;
  var focusedUuid = Props.focusedUuid;
  var jobs = Props.jobs;
  var ctx = wire.ctx;
  return React.createElement(Optional.make, {
              show: Caml_obj.notequal(jobs, []),
              children: groupByStatus(jobs).map(function (param) {
                    var status = param[0];
                    return React.createElement("section", {
                                key: MapTypes.Job.Status.toString(status),
                                className: El.Cn.concat(styles.jobsByStatus, styles.jobsList)
                              }, React.createElement("header", undefined, status$p(MapTypes.Job.Status.toString(status))(ctx)), React.createElement("ul", undefined, param[1].map(function (job) {
                                        return React.createElement(MapJobsItem.make, {
                                                    wire: wire,
                                                    onClick: focus,
                                                    focusedUuid: focusedUuid,
                                                    job: job,
                                                    key: job.serialNo.toString()
                                                  });
                                      })));
                  })
            });
}

var make = MapJobsInProgress;

export {
  make ,
}
/* styles Not a pure module */
