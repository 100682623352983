

import * as Hooks from "../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Errors from "../../Errors.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as FormInput from "../inputs/FormInput.mjs";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as RecoverAPI from "./RecoverAPI.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Backend_Errors from "../../libraries/backend/Backend_Errors.mjs";
import * as MessengerTypes from "../messenger/MessengerTypes.mjs";
import * as RecoverModuleCss from "/home/runner/work/planado/planado/client/rescript/components/recover/Recover.module.css";

var styles = RecoverModuleCss;

var enterYourEmailAddress$p = Utils.Translations.tr("js.recover.enter_your_email_address");

var email$p = Utils.Translations.t("js.recover.email");

var recoverAccess$p = Utils.Translations.tr("js.recover.recover_access");

var instructionsSent$p = Utils.Translations.tr("js.recover.instructions_sent");

function Recover(Props) {
  var wire = Props.wire;
  var navigate = wire.navigate;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return "";
      });
  var setEmail = match[1];
  var email = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setMailSent = match$1[1];
  var match$2 = React.useState(function () {
        return /* [] */0;
      });
  var setErrors = match$2[1];
  var match$3 = Hooks.useLocked(false);
  var unlock = match$3[2];
  var lock = match$3[1];
  var locked = match$3[0];
  var goToLogin = function ($$event) {
    $$event.preventDefault();
    navigate(undefined, "Login");
  };
  var onChangeEmail = function (email) {
    setEmail(function (param) {
          return email;
        });
  };
  var response = function (data) {
    unlock();
    if (data.TAG === "Ok") {
      var mailSent = data._0.mailSent;
      return setMailSent(function (param) {
                  return mailSent;
                });
    }
    var e = data._0;
    if (typeof e !== "object") {
      console.log(e);
      return ;
    }
    switch (e.TAG) {
      case "HTTPError" :
          var match = e._0;
          var tmp = match[0];
          if (typeof tmp !== "object") {
            if (tmp === "UnprocessableEntity") {
              var text = match[1];
              return setErrors(function (param) {
                          return Errors.parse(text);
                        });
            }
            console.log(e);
            return ;
          } else {
            console.log(e);
            return ;
          }
      case "ValidationError" :
          var e$1 = e._0;
          var error = Backend_Errors.getFirst(e$1, "email");
          if (error !== undefined) {
            return wire.subscriptions.messenger.trigger({
                        TAG: "Show",
                        _0: MessengerTypes.Message.make(undefined, "Danger", {
                              TAG: "Text",
                              _0: error
                            })
                      });
          } else {
            console.log(e$1);
            return ;
          }
      default:
        console.log(e);
        return ;
    }
  };
  var submit = function () {
    lock();
    Backend.$$finally(RecoverAPI.recover(wire, email), response);
  };
  var handleSubmit = function ($$event) {
    $$event.preventDefault();
    submit();
  };
  var match$4 = ctx.branding;
  var tmp;
  var exit = 0;
  if (match$4 !== undefined) {
    var logo = match$4.logo;
    if (logo !== undefined) {
      tmp = React.createElement("span", {
            className: styles.whiteLabelLogo,
            onClick: goToLogin
          }, React.createElement("img", {
                src: logo
              }));
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement("span", {
          className: styles.logo,
          onClick: goToLogin
        });
  }
  var tmp$1;
  if (match$1[0]) {
    tmp$1 = React.createElement("div", {
          className: "row"
        }, React.createElement("div", {
              className: "note note-info"
            }, instructionsSent$p(ctx)));
  } else {
    var tmp$2 = {
      _type: "text",
      value: email,
      onValueChange: onChangeEmail,
      disabled: locked,
      labelText: email$p(ctx)
    };
    var tmp$3 = RCore.$$Option.map(Errors.getMany({
              hd: "email",
              tl: /* [] */0
            }, match$2[0]), Core__List.toArray);
    if (tmp$3 !== undefined) {
      tmp$2.errors = Caml_option.valFromOption(tmp$3);
    }
    tmp$1 = React.createElement("form", {
          className: "panel",
          onSubmit: handleSubmit
        }, React.createElement("div", {
              className: "panel-body"
            }, React.createElement("div", {
                  className: "note note-info"
                }, enterYourEmailAddress$p(ctx)), React.createElement(FormInput.make, tmp$2)), React.createElement("div", {
              className: "panel-footer footer"
            }, React.createElement("button", {
                  className: "btn btn-primary btn",
                  disabled: locked || email === "",
                  type: "submit"
                }, recoverAccess$p(ctx))));
  }
  return React.createElement("div", {
              className: styles.wrapper
            }, React.createElement("div", {
                  className: styles.header
                }, tmp), React.createElement("div", {
                  className: styles.form
                }, tmp$1));
}

var make = Recover;

export {
  make ,
}
/* styles Not a pure module */
