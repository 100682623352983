import React, { Component } from 'react'
import jQuery from 'jquery'
import { StatsLink, JobsLink } from 'planado/schedule/containers'
import { SessionContext } from 'planado/utils/context.js'

export default class SideBar extends Component {
  shouldComponentUpdate = () => false

  componentDidMount() {
    const { editable, model, ctx } = this.props

    if (editable) {
      jQuery(this.bar)
        .filter(':not(.ui-droppable)')
        .droppable({
          accept: '.bar-span',
          greedy: true,
          scope: 'chart',
          drop: (_evt, ui) => {
            const $span = ui.helper
            const job = model.jobs().get($span.data('job-id'))

            if (job.scheduled() || job.started() || job.published()) {
              job.unschedule(ctx)
            }
          }
        })
    }
  }

  render = () => (
    <SessionContext.Consumer>
      {ctx => (
        <div className="schedule-sidebar" ref={div => (this.bar = div)}>
          <div className="menu-content">
            <h2>{ctx.t('js.schedule.sidebar.jobs')}</h2>
          </div>

          <StatsLink ctx={ctx} />
          <JobsLink ctx={ctx} />
        </div>
      )}
    </SessionContext.Consumer>
  )
}
