

import * as Fun from "../../../libraries/Fun.mjs";
import * as React from "react";
import * as GoogleMaps from "../../../bindings/googleMaps/GoogleMaps.mjs";
import * as YandexMaps from "../../../bindings/yandexMaps/YandexMaps.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as MapReContext from "./MapReContext.mjs";
import * as Bindings_ReactDomServer from "../../../bindings/reactDom/Bindings_ReactDomServer.mjs";

function MapReMarkerComponent$YandexMarker(Props) {
  var coordinates = Props.coordinates;
  var customMarkerProperties = Props.customMarkerProperties;
  var color = Props.color;
  var ymapsApi = YandexMaps.getApi();
  var map = MapReContext.YandexMapContext.useMap();
  var match = React.useState(function () {
        
      });
  var setMarker = match[1];
  var marker = match[0];
  var createMarker = function (ymapsApi) {
    if (customMarkerProperties !== undefined) {
      var height = customMarkerProperties.height;
      var width = customMarkerProperties.width;
      var htmlMarkup = Bindings_ReactDomServer.renderToStaticMarkup(customMarkerProperties.element);
      var customClass = YandexMaps.TemplateLayoutFactory.createClass(ymapsApi, htmlMarkup);
      var iconImageOffset;
      switch (customMarkerProperties.anchorPosition) {
        case "Center" :
            iconImageOffset = [
              - width / 2.0,
              - height / 2.0
            ];
            break;
        case "BottomMiddle" :
            iconImageOffset = [
              - width / 2.0,
              - height
            ];
            break;
        case "BottomRight" :
            iconImageOffset = [
              - width,
              - height
            ];
            break;
        
      }
      var properties_iconLayout = "default#imageWithContent";
      var properties_iconImageHref = "";
      var properties_iconContentLayout = Caml_option.some(customClass);
      var properties_iconImageSize = [
        width,
        height
      ];
      var properties_iconImageOffset = iconImageOffset;
      var properties = {
        iconLayout: properties_iconLayout,
        iconColor: undefined,
        iconImageHref: properties_iconImageHref,
        iconContentLayout: properties_iconContentLayout,
        iconImageSize: properties_iconImageSize,
        iconImageOffset: properties_iconImageOffset,
        iconContentOffset: undefined
      };
      var coordinates$1 = YandexMaps.Coordinate.fromGeolocation(coordinates);
      return YandexMaps.GeoObject.Placemark.make(ymapsApi, coordinates$1, {
                  balloonContent: undefined
                }, properties);
    }
    var coordinates$2 = YandexMaps.Coordinate.fromGeolocation(coordinates);
    var properties_iconImageHref$1 = "";
    var properties$1 = {
      iconLayout: undefined,
      iconColor: color,
      iconImageHref: properties_iconImageHref$1,
      iconContentLayout: undefined,
      iconImageSize: undefined,
      iconImageOffset: undefined,
      iconContentOffset: undefined
    };
    return YandexMaps.GeoObject.Placemark.make(ymapsApi, coordinates$2, {
                balloonContent: undefined
              }, properties$1);
  };
  React.useEffect((function () {
          var match = Fun.both(ymapsApi, map);
          if (match === undefined) {
            return ;
          }
          var map$1 = match[1];
          var marker = createMarker(match[0]);
          setMarker(function (param) {
                return Caml_option.some(marker);
              });
          YandexMaps.$$Map.addGeoObject(map$1, marker);
          return (function () {
                    YandexMaps.$$Map.removeGeoObject(map$1, marker);
                  });
        }), []);
  React.useEffect((function () {
          if (ymapsApi !== undefined && map !== undefined && marker !== undefined) {
            var map$1 = Caml_option.valFromOption(map);
            YandexMaps.$$Map.removeGeoObject(map$1, Caml_option.valFromOption(marker));
            var newMarker = createMarker(Caml_option.valFromOption(ymapsApi));
            setMarker(function (param) {
                  return Caml_option.some(newMarker);
                });
            YandexMaps.$$Map.addGeoObject(map$1, newMarker);
          }
          
        }), [coordinates]);
  return null;
}

function MapReMarkerComponent$GoogleMarker(Props) {
  var coordinates = Props.coordinates;
  var customMarkerProperties = Props.customMarkerProperties;
  var gmapsApi = GoogleMaps.getApi();
  var map = MapReContext.GoogleMapContext.useMap();
  var match = React.useState(function () {
        
      });
  var setMarker = match[1];
  var marker = match[0];
  var createMarker = function (gmapsApi) {
    if (customMarkerProperties !== undefined) {
      var htmlMarkup = Bindings_ReactDomServer.renderToStaticMarkup(customMarkerProperties.element);
      var anchor;
      switch (customMarkerProperties.anchorPosition) {
        case "Center" :
            anchor = 5;
            break;
        case "BottomMiddle" :
            anchor = 8;
            break;
        case "BottomRight" :
            anchor = 9;
            break;
        
      }
      return GoogleMaps.RichMarker.make(gmapsApi, GoogleMaps.RichMarker.Constructor.make, coordinates, htmlMarkup, anchor);
    }
    var options_position = GoogleMaps.LatLngLiteral.fromGeolocation(coordinates);
    var options = {
      position: options_position,
      title: undefined
    };
    return GoogleMaps.Marker.make(gmapsApi, options);
  };
  React.useEffect((function () {
          var match = Fun.both(gmapsApi, map);
          if (match === undefined) {
            return ;
          }
          var marker = createMarker(match[0]);
          setMarker(function (param) {
                return Caml_option.some(marker);
              });
          GoogleMaps.Marker.setMap(marker, match[1]);
          return (function () {
                    GoogleMaps.Marker.removeFromMap(marker);
                  });
        }), []);
  React.useEffect((function () {
          if (gmapsApi !== undefined && map !== undefined && marker !== undefined) {
            GoogleMaps.Marker.removeFromMap(Caml_option.valFromOption(marker));
            var newMarker = createMarker(Caml_option.valFromOption(gmapsApi));
            setMarker(function (param) {
                  return Caml_option.some(newMarker);
                });
            GoogleMaps.Marker.setMap(newMarker, Caml_option.valFromOption(map));
          }
          
        }), [coordinates]);
  return null;
}

function MapReMarkerComponent(Props) {
  var mapType = Props.mapType;
  var coordinates = Props.coordinates;
  var customMarkerProperties = Props.customMarkerProperties;
  var color = Props.color;
  var text = Props.text;
  if (typeof mapType === "object") {
    return null;
  } else if (mapType === "Yandex") {
    return React.createElement(MapReMarkerComponent$YandexMarker, {
                coordinates: coordinates,
                customMarkerProperties: customMarkerProperties,
                color: color,
                text: text
              });
  } else {
    return React.createElement(MapReMarkerComponent$GoogleMarker, {
                coordinates: coordinates,
                customMarkerProperties: customMarkerProperties,
                color: color,
                text: text
              });
  }
}

var make = MapReMarkerComponent;

export {
  make ,
}
/* react Not a pure module */
