import React, { Fragment, useEffect } from 'react'

import WorkerListLink from 'planado/containers/map/worker_list_link'
import WorkerLocationsLink from 'planado/containers/map/worker_locations_link'
import FocusedJobLink from 'planado/containers/map/focused_job_link'
import FocusedWorkerLink from 'planado/containers/map/focused_worker_link'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import { updateContext } from 'planado/map_commands'
import './styles.module.css'

const Panel = ({ historyMode, openJob, engineChannel, ctx }) => {
  useEffect(() => {
    engineChannel.push(updateContext(ctx))
  }, [ctx])

  if (historyMode) {
    return (
      <Fragment>
        <WorkerLocationsLink />
        <FocusedJobLink openJob={openJob} />
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        <WorkerListLink />
        <FocusedWorkerLink />
      </Fragment>
    )
  }
}

export default withContext(Panel)
