

import * as Uuid from "../../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as React from "react";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as Schedule_Types_Job from "../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as DayTimeline_UnassignedJob from "./job/DayTimeline_UnassignedJob.mjs";
import * as Schedule_UtilsBusinessHours from "../../../../lib/utils/Schedule_UtilsBusinessHours.mjs";
import * as DayTimeline_UnassignedModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/day_timeline/components/unassigned/DayTimeline_Unassigned.module.css";

var styles = DayTimeline_UnassignedModuleCss;

function DayTimeline_UnassignedBars$Cells(Props) {
  var businessHours = Schedule_State.useBusinessHours();
  return RCore.$$Array.make(Schedule_UtilsBusinessHours.hoursInDay(businessHours), undefined).map(function (param, idx) {
              return React.createElement("div", {
                          key: idx.toString(),
                          className: styles.cell
                        });
            });
}

var make = React.memo(DayTimeline_UnassignedBars$Cells);

function DayTimeline_UnassignedBars$Bar(Props) {
  var jobs = Props.jobs;
  return React.createElement("div", {
              className: styles.bar
            }, React.createElement(make, {}), jobs.map(function (param) {
                  var job = param[1];
                  return React.createElement(DayTimeline_UnassignedJob.make, {
                              position: param[0],
                              job: job,
                              key: Uuid.toString(Schedule_Types_Job.UnassignedJob.uuid(job))
                            });
                }));
}

var make$1 = React.memo(DayTimeline_UnassignedBars$Bar);

function DayTimeline_UnassignedBars(Props) {
  var bars = Props.bars;
  return bars.map(function (param) {
              return React.createElement(make$1, {
                          jobs: param[1],
                          key: Units.Px.toString(param[0])
                        });
            });
}

var make$2 = DayTimeline_UnassignedBars;

export {
  make$2 as make,
}
/* styles Not a pure module */
