

import * as El from "../../../../libraries/El.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Types from "../../../../types/Types.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Billing_Math from "../Billing_Math.mjs";
import * as Billing_Utils from "../Billing_Utils.mjs";
import * as BillingModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/billing/Billing.module.css";

var monthly$p = Utils.Translations.tr("js.billing.wizard.monthly");

var perSeat$p = Utils.Translations.tr("js.billing.show.per_license");

var priceOnRequest$p = Utils.Translations.tr("js.billing.wizard.price_on_request");

var styles = BillingModuleCss;

function planClass(hoveredPlan, order, planType) {
  var hovered = RCore.$$Option.getOr(RCore.$$Option.map(hoveredPlan, (function (p) {
              return Types.Id.equal(p.id, planType.id);
            })), false);
  var cl = El.Cn.fromRecord({
        hover: hovered
      });
  return El.Cn.concat(Types.Id.equal(order.planTypeId, planType.id) ? El.Cn.concat(cl, styles.current) : cl, hovered ? styles.hover : "");
}

var Utils$1 = {
  planClass: planClass
};

function Billing_Components_PlanPrice(Props) {
  var ctx = Props.ctx;
  var onMouseEnter = Props.onMouseEnter;
  var onMouseLeave = Props.onMouseLeave;
  var onClick = Props.onClick;
  var hoveredPlan = Props.hoveredPlan;
  var order = Props.order;
  var plans = Props.plans;
  var plan = Props.planType;
  var className = planClass(hoveredPlan, order, plan);
  var maxPrice = Billing_Math.currentOrderPrice(order, plans);
  return React.createElement("td", {
              className: className,
              onClick: onClick,
              onMouseEnter: onMouseEnter,
              onMouseLeave: onMouseLeave
            }, maxPrice !== undefined ? React.createElement("div", undefined, Billing_Utils.Format.money$p(maxPrice), monthly$p(ctx), maxPrice.amount > 0.0 ? React.createElement("span", undefined, El.space, perSeat$p(ctx)) : null) : priceOnRequest$p(ctx));
}

var Types$1;

var make = Billing_Components_PlanPrice;

export {
  Types$1 as Types,
  Utils$1 as Utils,
  make ,
}
/* monthly' Not a pure module */
