import * as React from 'react'
import classNames from 'classnames'

import { limit } from 'planado/utils/index.js'
import { showTime } from 'planado/utils/time/index.js'
import { addWorker, removeWorker, locateWorker } from 'planado/map_commands'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import * as styles from './styles.module.css'

const LocateIcon = ({ show }) => {
  if (show) {
    return (
      <div className={styles.locate}>
        <i className="fa fa-crosshairs" />
      </div>
    )
  } else {
    return <div />
  }
}

class WorkerItem extends React.Component {
  onHistoryClick = evt => {
    evt.stopPropagation()
    this.props.onHistoryClick()
  }

  lastActivityAt() {
    const { location, showDate, ctx } = this.props
    const dateFormat = showDate ? 'datetime' : 'time'

    return location === null
      ? null
      : showTime(new Date(location.effectiveAt), dateFormat, ctx)
  }

  get currentStatus() {
    const { currentJob, ctx } = this.props
    let statusData = []

    const lastActivityAt = this.lastActivityAt()
    if (lastActivityAt !== null) {
      statusData = [lastActivityAt]
    }

    if (currentJob !== null) {
      const serialNo = ctx.t('js.map.serial_no', currentJob)
      statusData.push(ctx.t(`js.map.status.${currentJob.status}`, { serialNo }))
    }

    if (statusData.length > 0) {
      return <div className={styles.workerStatus}>{statusData.join(' • ')}</div>
    } else {
      return null
    }
  }

  render() {
    const { focused, hasLocation, worker, ctx } = this.props

    const classes = classNames(styles.worker, {
      [styles.hasLocation]: hasLocation,
      [styles.focused]: focused
    })

    return (
      <li onClick={this.locateWorker} className={classes}>
        <div className={styles.workerName}>
          <LocateIcon show={hasLocation} />
          {limit(worker.name, 28)}
        </div>
        <div
          className={styles.workerHistory}
          onClick={this.onHistoryClick}
          title={ctx.t('js.map.location_history')}
        >
          <i className={`${styles.button} fa fa-clock`} />
          <i className="fa fa-chevron-right" />
        </div>
        {this.currentStatus}
      </li>
    )
  }

  // Engine interaction

  locateWorker = evt => {
    const {
      hasLocation,
      engineChannel,
      worker,
      location,
      onWorkerClick
    } = this.props

    if (hasLocation) {
      evt.stopPropagation()
      engineChannel.push(locateWorker(worker, location))
    }

    onWorkerClick()
  }

  componentDidMount() {
    this.pushWorkerLocation(this.props)
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.popWorkerLocation(prevProps)
      this.pushWorkerLocation(this.props)
    }
  }

  componentWillUnmount() {
    this.popWorkerLocation(this.props)
  }

  pushWorkerLocation = ({ worker, hasLocation, location, engineChannel }) => {
    if (hasLocation) {
      engineChannel.push(addWorker(worker, location))
    }
  }

  popWorkerLocation = ({ worker, hasLocation, location, engineChannel }) => {
    if (hasLocation) {
      engineChannel.push(removeWorker(worker, location))
    }
  }
}

export default withContext(WorkerItem)
