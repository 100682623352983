

import * as Utils from "../../../../utils/Utils.mjs";

var $$File = {};

function fromString(string) {
  switch (string) {
    case "gettingBase64ValueError" :
        return "GettingBase64ValueError";
    case "gettingDataUrlError" :
        return "GettingDataUrlError";
    case "gettingImageDimensionsError" :
        return "GettingImageDimensionsError";
    case "readingFileError" :
        return "ReadingFileError";
    default:
      return "UnknownError";
  }
}

var ProcessingError = {
  fromString: fromString
};

function asText(error, ctx) {
  if (typeof error !== "object") {
    return Utils.Translations.t("js.components.file_input.errors.not_an_image")(ctx);
  }
  switch (error.TAG) {
    case "ProcessingFailed" :
        return Utils.Translations.t("js.components.file_input.errors.processing_failed")(ctx);
    case "UploadFailed" :
        return Utils.Translations.t("js.components.file_input.errors.upload_failed")(ctx);
    case "PresignFailed" :
    case "ServerError" :
        return Utils.Translations.t("js.components.file_input.errors.server_error")(ctx);
    case "SizeLimitExceeded" :
        return Utils.Translations.tx("js.components.file_input.errors.cannot_be_bigger_than_n_mb")({
                    megabytes: error._0.toString()
                  }, ctx);
    
  }
}

var $$Error = {
  asText: asText
};

export {
  $$File ,
  ProcessingError ,
  $$Error ,
}
/* Utils Not a pure module */
