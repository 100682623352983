

import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as Shared_Lib_Reducer from "../../../../../shared/lib/Shared_Lib_Reducer.mjs";
import * as Schedule_StateTypes from "../Schedule_StateTypes.mjs";
import * as Schedule_Types_Settings from "../../types/Schedule_Types_Settings.mjs";
import * as Schedule_JobsStateReducer from "./Schedule_JobsStateReducer.mjs";
import * as Schedule_SettingsStateReducer from "./Schedule_SettingsStateReducer.mjs";
import * as Schedule_ShiftsStateReducerHelper from "./Schedule_ShiftsStateReducerHelper.mjs";

function use(view) {
  var wire = AppContext.useWire();
  var initial = Schedule_StateTypes.State.initial(wire, view);
  var reducer = React.useCallback((function (param) {
          var action = param[1];
          var state = param[0];
          if (typeof action !== "object") {
            return Schedule_ShiftsStateReducerHelper.fetchShifts(wire, state);
          } else if (action.TAG === "Jobs") {
            return Schedule_JobsStateReducer.reducer(wire, state, action._0);
          } else {
            return Schedule_SettingsStateReducer.reducer(state, action._0);
          }
        }), []);
  var match = Shared_Lib_Reducer.use(reducer, initial);
  var dispatch = match[1];
  var state = match[0];
  var prevView = React.useRef(view);
  React.useEffect((function () {
          if (Caml_obj.notequal(prevView.current, view)) {
            var period = Schedule_Types_Settings.Period.initial(wire, view);
            if (Caml_obj.notequal(state.settings.filter.period, period)) {
              dispatch({
                    TAG: "Settings",
                    _0: {
                      TAG: "SetFilterPeriod",
                      _0: period
                    }
                  });
            }
            prevView.current = view;
          }
          
        }), [
        state,
        view
      ]);
  return [
          state,
          dispatch
        ];
}

export {
  use ,
}
/* react Not a pure module */
