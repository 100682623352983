

import * as Utils from "../../../../../../../../utils/Utils.mjs";
import * as AppContext from "../../../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_DND from "../../../../../../lib/Schedule_DND.mjs";
import * as Assignee_Types from "../../../../../../../../types/Assignee_Types.mjs";
import * as MessengerTypes from "../../../../../../../messenger/MessengerTypes.mjs";
import * as Schedule_State from "../../../../../../lib/state/Schedule_State.mjs";
import * as MultipleAssignee from "../../../../../../../../types/MultipleAssignee.mjs";
import * as DayTimeline_State from "../../../../lib/state/DayTimeline_State.mjs";
import * as DayTimeline_Utils from "../../../../lib/utils/DayTimeline_Utils.mjs";
import * as Schedule_StateTypes from "../../../../../../lib/state/Schedule_StateTypes.mjs";
import * as DayTimeline_Constants from "../../../../lib/DayTimeline_Constants.mjs";
import * as Schedule_UtilsTimeline from "../../../../../../lib/utils/Schedule_UtilsTimeline.mjs";

function use(bar, viewportEl) {
  var sizes = DayTimeline_State.useSizes();
  var currentDay = Schedule_State.useCurrentDay();
  var businessHours = Schedule_State.useBusinessHours();
  var dispatch = Schedule_State.useDispatch();
  var match = AppContext.useWire();
  var messenger = match.subscriptions.messenger;
  var ctx = match.ctx;
  var allAssignees = ctx.allAssignees;
  var onDrop = function (param) {
    var contentEl = Schedule_UtilsTimeline.contentEl(viewportEl);
    if (contentEl === undefined) {
      return ;
    }
    var match = Schedule_DND.DND.Context.payload(param.payload);
    var assignees = match.assignees;
    var coordinate = Schedule_UtilsTimeline.ConvertPoint.fromPageToNode(Caml_option.valFromOption(contentEl), param.dragPreviewOffset);
    var scheduledAt = DayTimeline_Utils.FindByCoordinateInTimeline.scheduledAt(businessHours, currentDay, sizes, coordinate);
    var a = MultipleAssignee.PossibleAssignee.findByUuid(Assignee_Types.Assignee.uuid(bar), allAssignees);
    var transferredAssignees;
    if (a !== undefined) {
      var tmp;
      tmp = a.TAG === "Team" ? ({
            TAG: "Team",
            _0: a._0
          }) : ({
            TAG: "Worker",
            _0: {
              TAG: "Edit",
              _0: a._0
            }
          });
      transferredAssignees = MultipleAssignee.setMainAssignee(assignees, tmp);
    } else {
      transferredAssignees = assignees;
    }
    if (MultipleAssignee.workerNumberValid(transferredAssignees)) {
      return dispatch(Schedule_StateTypes.Action.jobSubmitted(match.uuid, Caml_option.some(scheduledAt), transferredAssignees));
    } else {
      return messenger.trigger({
                  TAG: "Show",
                  _0: MessengerTypes.Message.make(undefined, "Danger", {
                        TAG: "Text",
                        _0: Utils.Translations.t("js.schedule.errors.too_many_assignees")(ctx)
                      })
                });
    }
  };
  return Schedule_DND.DND.Droppable.use(undefined, undefined, undefined, [
              DayTimeline_Constants.dndScheduledJob,
              DayTimeline_Constants.dndUnscheduledJob,
              DayTimeline_Constants.dndUnassignedJob
            ], onDrop, undefined);
}

export {
  use ,
}
/* Utils Not a pure module */
