

import * as Uuid from "../bindings/uuid/Uuid.mjs";
import * as RCore from "../libraries/RCore.mjs";
import * as JsonDecode from "../libraries/JsonDecode.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Types_Territory from "./Types_Territory.mjs";

function fromString(s) {
  switch (s) {
    case "team" :
        return "Team";
    case "user" :
        return "Worker";
    default:
      return ;
  }
}

var decode = JsonDecode.fromVariant(JsonDecode.string, fromString);

var Type = {
  fromString: fromString,
  decode: decode
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

function decode$2(field) {
  return {
          uuid: field.required("uuid", Uuid.decode),
          name: field.required("name", JsonDecode.string),
          territory: RCore.$$Option.flatten(field.optional("territory", JsonDecode.option(Types_Territory.decode))),
          skills: RCore.$$Option.getOr(RCore.$$Option.flatten(field.optional("skills", JsonDecode.option(JsonDecode.array(decode$1)))), [])
        };
}

function decode$3(field) {
  return {
          uuid: field.required("uuid", Uuid.decode),
          name: field.required("name", JsonDecode.string),
          territory: RCore.$$Option.flatten(field.optional("territory", JsonDecode.option(Types_Territory.decode))),
          skills: RCore.$$Option.getOr(RCore.$$Option.flatten(field.optional("skills", JsonDecode.option(JsonDecode.array(decode$1)))), []),
          foremanUuid: RCore.$$Option.flatten(field.optional("foremanUuid", JsonDecode.option(Uuid.decode)))
        };
}

var decode$4 = JsonDecode.object(function (field) {
      var match = field.required("type", decode);
      if (match === "Worker") {
        return {
                TAG: "Worker",
                _0: decode$2(field)
              };
      } else {
        return {
                TAG: "Team",
                _0: decode$3(field)
              };
      }
    });

function typeToString(assignee) {
  if (assignee.TAG === "Worker") {
    return "user";
  } else {
    return "team";
  }
}

function eq(a, b) {
  if (a.TAG === "Worker") {
    if (b.TAG === "Worker") {
      return Uuid.equal(a._0.uuid, b._0.uuid);
    } else {
      return false;
    }
  } else if (b.TAG === "Worker") {
    return false;
  } else {
    return Uuid.equal(a._0.uuid, b._0.uuid);
  }
}

function uuid(assignee) {
  return assignee._0.uuid;
}

function name(assignee) {
  return assignee._0.name;
}

function territory(assignee) {
  var territory$1 = assignee._0.territory;
  if (territory$1 !== undefined) {
    return territory$1;
  }
  
}

function territoryUuid(assignee) {
  var match = assignee._0.territory;
  if (match !== undefined) {
    return Caml_option.some(match.uuid);
  }
  
}

function skillUuids(assignee) {
  return assignee._0.skills.map(function (skill) {
              return skill.uuid;
            });
}

var Assignee_Skill = {};

var Assignee_Worker = {};

var Assignee_Team = {};

var Assignee = {
  Type: Type,
  Skill: Assignee_Skill,
  $$Worker: Assignee_Worker,
  Team: Assignee_Team,
  decode: decode$4,
  typeToString: typeToString,
  eq: eq,
  uuid: uuid,
  name: name,
  territory: territory,
  territoryUuid: territoryUuid,
  skillUuids: skillUuids
};

export {
  Assignee ,
}
/* decode Not a pure module */
