

import * as Hint from "../../../../../common/Hint/Hint.mjs";
import * as Wire from "../../../../../../types/Wire.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as ApiRoute from "../../../../../../types/ApiRoute.mjs";
import * as Optional from "../../../../../common/Optional.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JobEdit_Types from "../../../lib/types/JobEdit_Types.mjs";
import * as DistanceTracked from "../../../../../inputs/distance_tracked/DistanceTracked.mjs";
import * as JobForm_Carousel from "../../hooks/JobForm_Carousel.mjs";
import * as JobForm_ReportField from "./report_field/JobForm_ReportField.mjs";
import * as JobForm_Carousel_Utils from "../../hooks/JobForm_Carousel_Utils.mjs";

var formLabel$p = Utils.Translations.tr("js.jobs.labels.report");

var downloadImagesLabel$p = Utils.Translations.tr("js.jobs.labels.download_images");

var downloadImagesHint$p = Utils.Translations.t("js.jobs.labels.download_images_hint");

function JobForm_Reports$Fields(Props) {
  var wire = Props.wire;
  var reportFields = Props.reportFields;
  var addFile = Props.addFile;
  var removeFile = Props.removeFile;
  var updateReportField = Props.updateReportField;
  var jobUuid = Props.jobUuid;
  var setLock = Props.setLock;
  var showCarouselImage = Props.showCarouselImage;
  var readOnly = !Wire.hasRightsToEditJobs(wire);
  return reportFields.map(function (field, index) {
              return React.createElement(JobForm_ReportField.make, {
                          wire: wire,
                          readOnly: readOnly,
                          field: field,
                          addFile: addFile,
                          removeFile: removeFile,
                          updateReportField: updateReportField,
                          jobUuid: jobUuid,
                          setLock: setLock,
                          showCarouselImage: showCarouselImage,
                          key: "report-field-" + index.toString()
                        });
            });
}

var make = React.memo(JobForm_Reports$Fields);

function JobForm_Reports$DownloadImagesButton(Props) {
  var ctx = Props.ctx;
  var jobUuid = Props.jobUuid;
  var imagesCount = Props.imagesCount;
  var downloadImagesLimitOpt = Props.downloadImagesLimit;
  var downloadImagesLimit = downloadImagesLimitOpt !== undefined ? downloadImagesLimitOpt : 50;
  return React.createElement("div", {
              className: "job-report-download-pics pd-form-group"
            }, React.createElement("a", {
                  download: "download",
                  href: ApiRoute.path({
                        NAME: "API",
                        VAL: {
                          NAME: "Job",
                          VAL: {
                            NAME: "Images",
                            VAL: jobUuid
                          }
                        }
                      }),
                  target: "_blank"
                }, React.createElement("span", {
                      className: "fa fa-download"
                    }), downloadImagesLabel$p(ctx)), React.createElement(Optional.make, {
                  show: imagesCount > downloadImagesLimit,
                  children: React.createElement(Hint.make, {
                        content: downloadImagesHint$p(ctx)
                      })
                }));
}

function JobForm_Reports(Props) {
  var wire = Props.wire;
  var reportFields = Props.reportFields;
  var addFile = Props.addFile;
  var removeFile = Props.removeFile;
  var jobUuid = Props.jobUuid;
  var trackMovements = Props.trackMovements;
  var status = Props.status;
  var odometerM = Props.odometerM;
  var setOdometerM = Props.setOdometerM;
  var updateReportField = Props.updateReportField;
  var setLock = Props.setLock;
  var ctx = wire.ctx;
  var carouselImages = JobForm_Carousel_Utils.removeDuplicates(reportFields.flatMap(function (field) {
            var attachments = field.value;
            switch (attachments.TAG) {
              case "File" :
              case "Image" :
                  return JobForm_Carousel_Utils.extractCarouselImages(field.name, attachments._0);
              default:
                return [];
            }
          }));
  var match = JobForm_Carousel.use(carouselImages);
  var imagesCount = JobEdit_Types.ReportFieldsImages.count(reportFields);
  var hasImages = imagesCount > 0;
  return React.createElement("div", {
              className: "job-report " + wire.tour.anchor("job-form-report")
            }, React.createElement("div", {
                  className: "job-report-header"
                }, React.createElement("span", {
                      className: "text-semibold"
                    }, formLabel$p(ctx))), React.createElement("div", {
                  className: "job-report-items"
                }, React.createElement(make, {
                      wire: wire,
                      reportFields: reportFields,
                      addFile: addFile,
                      removeFile: removeFile,
                      updateReportField: updateReportField,
                      jobUuid: jobUuid,
                      setLock: setLock,
                      showCarouselImage: match.showCarouselImage
                    }), jobUuid !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Optional.make, {
                            show: status === "Finished" && trackMovements,
                            children: React.createElement(DistanceTracked.make, {
                                  wire: wire,
                                  odometerM: RCore.$$Option.getOr(odometerM, 0.0),
                                  setOdometerM: (function (odometerM) {
                                      setOdometerM(odometerM);
                                    })
                                })
                          }), React.createElement(Optional.make, {
                            show: hasImages,
                            children: React.createElement(JobForm_Reports$DownloadImagesButton, {
                                  ctx: ctx,
                                  jobUuid: Caml_option.valFromOption(jobUuid),
                                  imagesCount: imagesCount
                                })
                          })) : null), match.carousel);
}

var make$1 = React.memo(JobForm_Reports);

export {
  make$1 as make,
}
/* formLabel' Not a pure module */
