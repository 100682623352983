// extracted by mini-css-extract-plugin
export var formContainer = "form-container-OglGJ_PmyN5X22nEbsNy";
export var formControl = "form-control-d7p46DM9kabV_KNXNV6D";
export var formControl300Px = "form-control-300px-fnUj18WAOBZMWjgSYT3E";
export var formControlWithIcon = "form-control-with-icon-pcMGh_PGUAtadanSZ2oK";
export var formGroup = "form-group-MGgkxrwprG7DqYVmX941";
export var formGroupContainer = "form-group-container-lH2dGSv4ZUwTR917z6X1";
export var formGroupHalf = "form-group-half-rAae81_EkRPb_pbMZxGs";
export var formHeader = "form-header-C_uctdxGDl72tXC3Vede";
export var formHeaderTitle = "form-header-title-myPRva5AOnQRgQh5P5k3";
export var formMsg = "form-msg-glPd4__fODZSInys6p6y";
export var formSection = "form-section-MhvH4x2hoKHY2NYKERSz";
export var formSectionTitle = "form-section-title-TWDOs0A54_NGRADbGyIg";
export var searchInput = "search-input-JjeCuBKgwMxTBF7GB7v7";