import React, { Fragment, Component } from 'react'
import classNames from 'classnames'
import jQuery from 'jquery'
import NavigationController from 'planado/schedule/utils/navigation_controller'
import {
  TimeLineLink,
  CurrentTimeLink,
  BackgroundLink,
  BarLabelsLink,
  BarsViewLink,
  AssigneesFilterLink
} from 'planado/schedule/containers'
import { scaleHuge } from 'planado/schedule/utils2/scales'
import { SessionContext } from 'planado/utils/context.js'

export default class Chart extends Component {
  navController = new NavigationController()

  state = {
    scrolling: false
  }

  shouldComponentUpdate = () => false

  componentWillUnmount() {
    this.props.model.off(null, null, this)
  }

  componentDidMount() {
    const { model, viewController, sideBar } = this.props

    this.navController.init(this.getViewPort(), model)
    viewController.updateSizes(jQuery('#schedule'))

    model.on('change:scale', () => this.forceUpdate(), this)

    model.options.store.dispatch({
      type: 'SET_BACKBONE',
      backbone: {
        chart: model,
        sideBar: sideBar,
        viewController: viewController,
        navController: this.navController
      }
    })

    if (model.isEditable()) {
      jQuery(this.getViewPort())
        .filter(':not(.ui-droppable)')
        .droppable({
          accept: '.bar-span,.side-bar-job',
          scope: 'chart',
          over(evt, ui) {
            const draggable = jQuery(ui.draggable.clone(true)).data(
              'ui-draggable'
            )

            jQuery(draggable.options.snap).each(function() {
              const $this = jQuery(this)
              const offset = $this.offset()

              draggable.snapElements.push({
                item: this,
                width: $this.outerWidth(),
                height: $this.outerHeight(),
                top: offset.top,
                left: offset.left
              })
            })

            const $span = ui.helper
            $span.data({ outOfChart: false })
          },
          out(evt, ui) {
            jQuery(ui.draggable.clone(true)).data(
              'ui-draggable'
            ).snapElements = []

            const $span = ui.helper
            $span.data({ outOfChart: true })
          }
        })
    }
  }

  getViewPort = () =>
    this.$viewPort ||
    (this.$viewPort = jQuery(this.chartDiv).find('.view-port'))

  startScrolling = evt => {
    const { pageX, currentTarget, target } = evt

    const $node = jQuery(currentTarget)
    const $target = jQuery(target)
    if ($target.is('.bar-span') || $target.parents('.bar-span').length > 0) {
      return
    }

    $node.css({ cursor: 'move' })

    this.setState(() => ({ scrolling: true, prevXPosition: pageX }))
  }

  scroll = evt => {
    if (this.state.scrolling) {
      const { pageX, currentTarget } = evt
      const node = jQuery(currentTarget)[0]

      node.scrollLeft = node.scrollLeft + this.state.prevXPosition - pageX
      this.setState(() => ({ prevXPosition: pageX }))
    }
  }

  stopScrollingByOut = evt => {
    if (this.state.scrolling) {
      if (!jQuery(evt.relatedTarget).parents('.view-port').length) {
        this.stopScrolling(evt)
      }
    }
  }

  stopScrolling = evt => {
    if (this.state.scrolling) {
      let $node = jQuery(evt.currentTarget)
      $node.css({ cursor: 'auto' })
      this.setState(() => ({ scrolling: false, prevXPosition: null }))
    }
  }

  render() {
    const { model, scale } = this.props

    const viewPortClasses = classNames({
      'view-port': true,
      'huge-scale': scaleHuge(scale)
    })

    return (
      <SessionContext.Consumer>
        {ctx => (
          <>
            <div
              className="schedule-chart"
              ref={div => {
                this.chartDiv = div
              }}
            >
              <div className="chart-navigation__filter">
                <AssigneesFilterLink />
              </div>
              <BackgroundLink />
              <div
                className={viewPortClasses}
                data-scale={scale}
                style={{ width: model.viewPortWidth() }}
                onScroll={this.navController.scroll}
                onMouseDown={this.startScrolling}
                onMouseMove={this.scroll}
                onMouseUp={this.stopScrolling}
                onMouseOut={this.stopScrollingByOut}
              >
                <span className="view-port-loader-background" />
                <div className="chart-bars" style={{ width: model.width() }}>
                  <Fragment>
                    <TimeLineLink ctx={ctx} />
                    <BarsViewLink ctx={ctx} />
                    <CurrentTimeLink ctx={ctx} />
                  </Fragment>
                </div>
              </div>
              <BarLabelsLink />
            </div>
          </>
        )}
      </SessionContext.Consumer>
    )
  }
}
