

import * as Types from "../../../../../../../../types/Types.mjs";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as WeekTimeline_ActiveDaySkeletonModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/active_day/components/skeleton/WeekTimeline_ActiveDaySkeleton.module.css";

var styles = WeekTimeline_ActiveDaySkeletonModuleCss;

function WeekTimeline_ActiveDaySkeleton(Props) {
  var show = Props.show;
  var elementRef = Types.ReactRef.use();
  React.useEffect((function () {
          var element = Types.ReactRef.toOption(elementRef);
          if (element !== undefined && !show) {
            var classList = Caml_option.valFromOption(element).classList;
            var isHidden = classList.contains(styles.hidden);
            if (!isHidden) {
              classList.add(styles.hidden);
            }
            
          }
          
        }), [
        elementRef,
        show
      ]);
  return React.createElement("div", {
              ref: elementRef,
              className: styles.skeleton
            }, React.createElement("div", {
                  className: styles.skale
                }), React.createElement("div", {
                  className: styles.bar
                }));
}

var make = WeekTimeline_ActiveDaySkeleton;

export {
  make ,
}
/* styles Not a pure module */
