

import * as Hooks from "../../libraries/hooks/Hooks.mjs";
import * as Modal from "../modal/Modal.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Select from "../inputs/Select.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as FormInput from "../inputs/FormInput.mjs";
import * as ProfileAPI from "./ProfileAPI.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Context_Types from "../../context/Context_Types.mjs";
import * as Backend_Errors from "../../libraries/backend/Backend_Errors.mjs";
import * as ProfileModuleCss from "/home/runner/work/planado/planado/client/rescript/components/profile/Profile.module.css";

var styles = ProfileModuleCss;

var title$p = Utils.Translations.txr("js.profile.title");

var email$p = Utils.Translations.t("js.profile.email");

var timezone$p = Utils.Translations.tr("js.profile.timezone");

var timezonePlaceholder$p = Utils.Translations.t("js.profile.timezone");

var firstName$p = Utils.Translations.t("js.profile.first_name");

var lastName$p = Utils.Translations.t("js.profile.last_name");

var submit$p = Utils.Translations.tr("js.profile.submit");

var mustBeFilled$p = Utils.Translations.t("js.profile.errors.must_be_filled");

var cannotContainOnlySpaces$p = Utils.Translations.t("js.profile.errors.cannot_contain_only_spaces");

function readErrors(errors) {
  return {
          firstName: Backend_Errors.getArray(errors, "firstName"),
          lastName: Backend_Errors.getArray(errors, "lastName"),
          timezone: Backend_Errors.getArray(errors, "timezone")
        };
}

function Profile(Props) {
  var wire = Props.wire;
  var onClose = Props.onClose;
  var show = Props.show;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return [];
      });
  var setTimezoneVariants = match[1];
  var timezoneVariants = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setTimezone = match$1[1];
  var timezone = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setFirstName = match$2[1];
  var firstName = match$2[0];
  var match$3 = React.useState(function () {
        return "";
      });
  var setLastName = match$3[1];
  var lastName = match$3[0];
  var match$4 = Hooks.useLocked(false);
  var unlock = match$4[2];
  var lock = match$4[1];
  var match$5 = React.useState(function () {
        return ProfileAPI.Patch.$$Response.$$Error.emptyErrors;
      });
  var setErrors = match$5[1];
  var errors = match$5[0];
  var inputFieldErrors = function (value) {
    if (value === "") {
      return [mustBeFilled$p(ctx)];
    } else if (value.trim() === "") {
      return [cannotContainOnlySpaces$p(ctx)];
    } else {
      return [];
    }
  };
  var validateFirstName = function () {
    setErrors(function (errors) {
          return {
                  firstName: inputFieldErrors(firstName),
                  lastName: errors.lastName,
                  timezone: errors.timezone
                };
        });
  };
  var validateLastName = function () {
    setErrors(function (errors) {
          return {
                  firstName: errors.firstName,
                  lastName: inputFieldErrors(lastName),
                  timezone: errors.timezone
                };
        });
  };
  var handleShowResponse = function (response) {
    if (response.TAG === "Ok") {
      var match = response._0;
      var lastName = match.lastName;
      var firstName = match.firstName;
      var timezone = match.timezone;
      var timezoneVariants = match.timezoneVariants;
      setTimezoneVariants(function (param) {
            return timezoneVariants;
          });
      setTimezone(function (param) {
            return Context_Types.TimeZone.idFromVariantsByCode(timezone, timezoneVariants);
          });
      setFirstName(function (param) {
            return firstName;
          });
      setLastName(function (param) {
            return lastName;
          });
    } else {
      var errors = response._0;
      if (typeof errors === "object" && errors.TAG === "ValidationError") {
        var errors$1 = errors._0;
        setErrors(function (param) {
              return readErrors(errors$1);
            });
      }
      
    }
    unlock();
  };
  React.useEffect((function () {
          if (show) {
            Backend.$$finally(ProfileAPI.Show.get(wire), handleShowResponse);
          }
          
        }), [show]);
  var handlePatchResponse = function (response) {
    if (response.TAG === "Ok") {
      wire.reloadContext();
      onClose();
    } else {
      var errors = response._0;
      if (typeof errors === "object" && errors.TAG === "ValidationError") {
        var errors$1 = errors._0;
        setErrors(function (param) {
              return readErrors(errors$1);
            });
      }
      
    }
    unlock();
  };
  var handleSubmit = function ($$event) {
    $$event.preventDefault();
    lock();
    Backend.$$finally(Backend.discard(ProfileAPI.Patch.$$Request.update(wire, {
                  timezone: Context_Types.TimeZone.codeFromVariantsById(timezone, timezoneVariants),
                  firstName: firstName,
                  lastName: lastName
                })), handlePatchResponse);
  };
  var user = ctx.user;
  if (user !== undefined) {
    return React.createElement(Modal.make, {
                wire: wire,
                onClose: onClose,
                show: show,
                children: React.createElement(Modal.Dialog.make, {
                      className: styles.form,
                      children: null
                    }, React.createElement(Modal.Header.make, {
                          children: title$p({
                                name: user.name
                              }, ctx)
                        }), React.createElement("form", {
                          onSubmit: handleSubmit
                        }, React.createElement("div", {
                              className: "panel-body"
                            }, React.createElement(FormInput.make, {
                                  _type: "text",
                                  value: user.email,
                                  onValueChange: (function (param) {
                                      
                                    }),
                                  disabled: true,
                                  labelText: email$p(ctx)
                                }), React.createElement(FormInput.make, {
                                  _type: "text",
                                  value: firstName,
                                  onValueChange: (function (v) {
                                      setFirstName(function (param) {
                                            return v;
                                          });
                                    }),
                                  onBlur: (function (param) {
                                      validateFirstName();
                                    }),
                                  disabled: ctx.disposable,
                                  labelText: firstName$p(ctx),
                                  maxLength: 50,
                                  errors: errors.firstName
                                }), React.createElement(FormInput.make, {
                                  _type: "text",
                                  value: lastName,
                                  onValueChange: (function (v) {
                                      setLastName(function (param) {
                                            return v;
                                          });
                                    }),
                                  onBlur: (function (param) {
                                      validateLastName();
                                    }),
                                  disabled: ctx.disposable,
                                  labelText: lastName$p(ctx),
                                  maxLength: 50,
                                  errors: errors.lastName
                                }), React.createElement("div", {
                                  className: styles.timezoneSelectGroup
                                }, React.createElement("label", {
                                      className: "pd-label"
                                    }, timezone$p(ctx)), React.createElement(Select.make, {
                                      placeholder: timezonePlaceholder$p(ctx),
                                      onChange: (function (v) {
                                          setTimezone(function (param) {
                                                if (v === null) {
                                                  return ;
                                                } else {
                                                  return Caml_option.some(v);
                                                }
                                              });
                                        }),
                                      value: Js_null.fromOption(timezone),
                                      options: timezoneVariants.map(function (t) {
                                            return {
                                                    value: t.id,
                                                    label: t.name
                                                  };
                                          })
                                    }))), React.createElement("div", {
                              className: "panel-footer footer"
                            }, React.createElement("button", {
                                  className: "btn btn-primary",
                                  disabled: match$4[0]
                                }, submit$p(ctx)))))
              });
  } else {
    return null;
  }
}

var make = Profile;

export {
  make ,
}
/* styles Not a pure module */
