

import * as El from "../../libraries/El.mjs";
import * as Hooks from "../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Types from "../../types/Types.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as UI_Icon from "../../ui/UI_Icon.mjs";
import * as UI_Label from "../../ui/UI_Label.mjs";
import * as UI_Button from "../../ui/UI_Button.mjs";
import * as AppContext from "../../context/AppContext.mjs";
import * as TimePicker from "../inputs/TimePicker.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as BaseFormInput from "../inputs/BaseFormInput.mjs";
import * as Settings_BusinessHoursModuleCss from "/home/runner/work/planado/planado/client/rescript/components/settings/Settings_BusinessHours.module.css";

var sectionTitle$p = Utils.Translations.t("js.settings.business_hours.label");

var from$p = Utils.Translations.tr("js.settings.business_hours.from");

var to$p = Utils.Translations.tr("js.settings.business_hours.to");

var turnOff$p = Utils.Translations.t("js.settings.business_hours.turn_off");

var enable$p = Utils.Translations.t("js.settings.business_hours.add");

var styles = Settings_BusinessHoursModuleCss;

function Settings_BusinessHours(Props) {
  var businessHours = Props.businessHours;
  var setBusinessHours = Props.setBusinessHours;
  var errors = Props.errors;
  var setErrors = Props.setErrors;
  var wire = AppContext.useWire();
  var match = React.useState(function () {
        return businessHours.configured;
      });
  var setShow = match[1];
  var show = match[0];
  var match$1 = React.useState(function () {
        var match = businessHours.interval;
        if (match !== undefined) {
          return {
                  start: Caml_option.some(match[0]),
                  finish: Caml_option.some(match[1])
                };
        } else {
          return {
                  start: undefined,
                  finish: undefined
                };
        }
      });
  var $$setInterval = match$1[1];
  var interval = match$1[0];
  var inputId = Hooks.useId(undefined);
  var setTime = function (field, strTime) {
    var t = Types.Time.HoursMinutes.fromString(strTime);
    var timeRounded = t !== undefined ? Types.Time.HoursMinutes.roundMinutes(Caml_option.valFromOption(t), 30) : Types.Time.HoursMinutes.unsafeFromPair(0, 0);
    if (field === "finish") {
      $$setInterval(function (current) {
            return {
                    start: current.start,
                    finish: Caml_option.some(timeRounded)
                  };
          });
    } else {
      $$setInterval(function (current) {
            return {
                    start: Caml_option.some(timeRounded),
                    finish: current.finish
                  };
          });
    }
    setErrors(function (current) {
          return {
                  owner: current.owner,
                  businessHours: [],
                  language: current.language,
                  timezone: current.timezone,
                  country: current.country,
                  dateFormat: current.dateFormat,
                  measurementSystem: current.measurementSystem,
                  thousandsSeparator: current.thousandsSeparator,
                  decimalMark: current.decimalMark,
                  currency: current.currency,
                  currencyFullFormat: current.currencyFullFormat,
                  smsNearest: current.smsNearest,
                  smsEnRoute: current.smsEnRoute,
                  smsFinished: current.smsFinished
                };
        });
  };
  React.useEffect((function () {
          if (show) {
            var s = interval.start;
            if (s !== undefined) {
              var f = interval.finish;
              if (f !== undefined) {
                setBusinessHours({
                      configured: true,
                      interval: [
                        Caml_option.valFromOption(s),
                        Caml_option.valFromOption(f)
                      ]
                    });
              }
              
            }
            
          } else {
            setBusinessHours({
                  configured: false,
                  interval: undefined
                });
          }
        }), [
        interval,
        show
      ]);
  if (show) {
    return React.createElement(BaseFormInput.make, {
                id: inputId,
                labelText: sectionTitle$p(wire.ctx),
                errors: errors.businessHours,
                horizontal: false,
                children: React.createElement("div", {
                      className: styles.inputs
                    }, React.createElement(UI_Label.make, {
                          className: styles.label,
                          children: from$p(wire.ctx)
                        }), React.createElement(TimePicker.make, {
                          time: RCore.$$Option.getOr(RCore.$$Option.map(interval.start, (function (s) {
                                      return Types.Time.HoursMinutes.toString(s);
                                    })), ""),
                          onChange: (function (value) {
                              setTime("start", value);
                            }),
                          secondsStep: 1800,
                          errors: []
                        }), React.createElement(UI_Label.make, {
                          className: styles.label,
                          children: to$p(wire.ctx)
                        }), React.createElement(TimePicker.make, {
                          time: RCore.$$Option.getOr(RCore.$$Option.map(interval.finish, (function (f) {
                                      return Types.Time.HoursMinutes.toString(f);
                                    })), ""),
                          onChange: (function (value) {
                              setTime("finish", value);
                            }),
                          secondsStep: 1800,
                          errors: []
                        }), React.createElement(UI_Button.make, {
                          flavor: "outline",
                          className: styles.grayButton,
                          onClick: (function (param) {
                              setErrors(function (current) {
                                    return {
                                            owner: current.owner,
                                            businessHours: [],
                                            language: current.language,
                                            timezone: current.timezone,
                                            country: current.country,
                                            dateFormat: current.dateFormat,
                                            measurementSystem: current.measurementSystem,
                                            thousandsSeparator: current.thousandsSeparator,
                                            decimalMark: current.decimalMark,
                                            currency: current.currency,
                                            currencyFullFormat: current.currencyFullFormat,
                                            smsNearest: current.smsNearest,
                                            smsEnRoute: current.smsEnRoute,
                                            smsFinished: current.smsFinished
                                          };
                                  });
                              setShow(function (param) {
                                    return false;
                                  });
                            }),
                          children: null
                        }, React.createElement(UI_Icon.make, {
                              className: styles.minusIcon,
                              icon: "minus"
                            }), React.createElement("strong", undefined, " " + turnOff$p(wire.ctx))))
              });
  } else {
    return React.createElement(UI_Button.make, {
                flavor: "outline",
                className: El.Cn.concat(styles.addButton, styles.grayButton),
                onClick: (function (param) {
                    setShow(function (param) {
                          return true;
                        });
                  }),
                children: null
              }, React.createElement(UI_Icon.make, {
                    className: styles.plusIcon,
                    icon: "plus"
                  }), React.createElement("strong", undefined, " " + enable$p(wire.ctx)), React.createElement(UI_Icon.make, {
                    className: styles.caretIcon,
                    icon: "caret-down"
                  }));
  }
}

var make = Settings_BusinessHours;

export {
  make ,
}
/* sectionTitle' Not a pure module */
