

import * as React from "react";
import * as Schedule_Scroll from "./Schedule_Scroll.mjs";
import * as Types_ReactContext from "../../../../types/Types_ReactContext.mjs";

var include = Types_ReactContext.Make({
      initial: undefined
    });

var use = include.use;

function useContext() {
  return React.useState(function () {
              return Schedule_Scroll.Coordinate.empty;
            });
}

function useInitialPosition() {
  var match = use();
  var initialPosition = match[0];
  return React.useMemo((function () {
                return initialPosition;
              }), []);
}

function useInitialPositionY() {
  var match = use();
  var y = match[0].y;
  return React.useMemo((function () {
                return y;
              }), []);
}

function useSetInitialPosition() {
  var match = use();
  var setInitialPosition = match[1];
  return function (initialPosition) {
    setInitialPosition(function (param) {
          return initialPosition;
        });
  };
}

var context = include.context;

var Provider = include.Provider;

export {
  context ,
  use ,
  Provider ,
  useContext ,
  useInitialPosition ,
  useInitialPositionY ,
  useSetInitialPosition ,
}
/* include Not a pure module */
