

import * as Fun from "../../../libraries/Fun.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as React from "react";
import * as DomUtils from "../../../DomUtils.mjs";
import * as PromiseF from "../../../libraries/PromiseF.mjs";
import * as GoogleMaps from "../../../bindings/googleMaps/GoogleMaps.mjs";
import * as YandexMaps from "../../../bindings/yandexMaps/YandexMaps.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as MapReContext from "./MapReContext.mjs";
import * as MapReCustomMarker from "./MapReCustomMarker.mjs";
import * as MapReMarkerComponent from "./MapReMarkerComponent.mjs";

function MapReComponent$YandexMap(Props) {
  var wire = Props.wire;
  var containerRef = Props.containerRef;
  var region = Props.region;
  var fallbackRegion = Props.fallbackRegion;
  var mapEventsSubscription = Props.mapEventsSubscription;
  var controls = Props.controls;
  var children = Props.children;
  var match = React.useState(function () {
        
      });
  var setMap = match[1];
  var map = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setHighlightCoordinates = match$1[1];
  var highlightCoordinates = match$1[0];
  var highlightCircle = highlightCoordinates !== undefined ? React.createElement(MapReMarkerComponent.make, {
          mapType: "Yandex",
          coordinates: highlightCoordinates,
          customMarkerProperties: MapReCustomMarker.HighlightCircle.properties
        }) : null;
  var match$2 = React.useState(function () {
        
      });
  var setDistanceMarkerCoordinates = match$2[1];
  var distanceMarkerData = match$2[0];
  var distanceMarker = distanceMarkerData !== undefined ? React.createElement(MapReMarkerComponent.make, {
          mapType: "Yandex",
          coordinates: distanceMarkerData[0],
          customMarkerProperties: MapReCustomMarker.MovementDistance.properties(distanceMarkerData[1])
        }) : null;
  var createMap = function (ymapsApi, domElement) {
    return PromiseF.map(YandexMaps.ready(ymapsApi), (function (ymapsApi) {
                  var createBoundingBoxOptions = function (boundingBox) {
                    return {
                            center: undefined,
                            zoom: undefined,
                            bounds: Caml_option.some(YandexMaps.BoundingBox.fromBoundingBox(boundingBox)),
                            controls: []
                          };
                  };
                  var createCenterOptions = function (center) {
                    return {
                            center: YandexMaps.Coordinate.fromGeolocation(center),
                            zoom: 14,
                            bounds: undefined,
                            controls: []
                          };
                  };
                  var mapOptions = region !== undefined ? (
                      region.NAME === "Center" ? createCenterOptions(region.VAL) : createBoundingBoxOptions(region.VAL)
                    ) : (
                      fallbackRegion.NAME === "Center" ? createCenterOptions(fallbackRegion.VAL) : createBoundingBoxOptions(fallbackRegion.VAL)
                    );
                  var mapFitOptions_autoFitToViewport = "always";
                  var mapFitOptions = {
                    autoFitToViewport: mapFitOptions_autoFitToViewport,
                    maxZoom: 17,
                    minZoom: 3
                  };
                  var map = YandexMaps.$$Map.make(ymapsApi, domElement, mapOptions)(mapFitOptions);
                  if (controls !== undefined) {
                    controls.forEach(function (control) {
                          YandexMaps.Controls.add(map, control, wire.ctx.features);
                        });
                  }
                  setMap(function (param) {
                        return Caml_option.some(map);
                      });
                  return map;
                }));
  };
  React.useEffect((function () {
          var domElement = DomUtils.extractDomElementFromRef(containerRef);
          if (domElement !== undefined) {
            var domElement$1 = Caml_option.valFromOption(domElement);
            var ymapsApi = YandexMaps.getApi();
            if (ymapsApi !== undefined) {
              createMap(Caml_option.valFromOption(ymapsApi), domElement$1);
            } else {
              PromiseF.map(YandexMaps.Loader.load(wire.ctx), (function (result) {
                      if (result.TAG === "Ok") {
                        createMap(result._0, domElement$1);
                        return ;
                      }
                      console.log("Yandex maps API failed to load");
                    }));
            }
          }
          
        }), []);
  React.useEffect((function () {
          if (map === undefined) {
            return ;
          }
          var map$1 = Caml_option.valFromOption(map);
          return mapEventsSubscription.subscribe(function ($$event) {
                      var longitude;
                      var latitude;
                      if (typeof $$event !== "object") {
                        if ($$event === "RemoveHighlight") {
                          return Fun.defer(function () {
                                      setHighlightCoordinates(function (param) {
                                            
                                          });
                                    });
                        } else {
                          return Fun.defer(function () {
                                      setDistanceMarkerCoordinates(function (param) {
                                            
                                          });
                                    });
                        }
                      }
                      switch ($$event.TAG) {
                        case "Pan" :
                            var match = $$event._0;
                            longitude = match.longitude;
                            latitude = match.latitude;
                            break;
                        case "PanToRegion" :
                            var match$1 = $$event._0;
                            if (match$1.NAME !== "Center") {
                              return YandexMaps.$$Map.setBounds(map$1, YandexMaps.BoundingBox.fromBoundingBox(match$1.VAL));
                            }
                            var match$2 = match$1.VAL;
                            longitude = match$2.longitude;
                            latitude = match$2.latitude;
                            break;
                        case "AddHighlight" :
                            var coordinates = $$event._0;
                            return Fun.defer(function () {
                                        setHighlightCoordinates(function (param) {
                                              return coordinates;
                                            });
                                      });
                        case "ShowDistance" :
                            var data = $$event._0;
                            return Fun.defer(function () {
                                        setDistanceMarkerCoordinates(function (param) {
                                              
                                            });
                                        setDistanceMarkerCoordinates(function (param) {
                                              return data;
                                            });
                                      });
                        
                      }
                      YandexMaps.$$Map.panTo(map$1, [
                            latitude,
                            longitude
                          ], {
                            checkZoomRange: true,
                            flying: true
                          });
                    });
        }), [map]);
  React.useEffect((function () {
          if (map === undefined) {
            return ;
          }
          var map$1 = Caml_option.valFromOption(map);
          if (region !== undefined) {
            if (region.NAME === "Center") {
              var match = region.VAL;
              YandexMaps.$$Map.panTo(map$1, [
                    match.latitude,
                    match.longitude
                  ], {
                    checkZoomRange: true,
                    flying: true
                  });
            } else {
              YandexMaps.$$Map.setBounds(map$1, YandexMaps.BoundingBox.fromBoundingBox(region.VAL));
            }
          }
          
        }), [region]);
  if (map !== undefined) {
    return React.createElement(MapReContext.YandexMapContext.Provider.make, MapReContext.YandexMapContext.Provider.makeProps(map, null, undefined), highlightCircle, distanceMarker, RCore.$$Option.getOr(children, null));
  } else {
    return null;
  }
}

var defaultMapOptions = {
  center: undefined,
  zoom: undefined,
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  maxZoom: 17,
  minZoom: 3
};

function MapReComponent$GoogleMap(Props) {
  var wire = Props.wire;
  var containerRef = Props.containerRef;
  var region = Props.region;
  var fallbackRegion = Props.fallbackRegion;
  var mapEventsSubscription = Props.mapEventsSubscription;
  var children = Props.children;
  var match = React.useState(function () {
        
      });
  var setMap = match[1];
  var map = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setHighlightCoordinates = match$1[1];
  var highlightCoordinates = match$1[0];
  var highlightCircle = highlightCoordinates !== undefined ? React.createElement(MapReMarkerComponent.make, {
          mapType: "Google",
          coordinates: highlightCoordinates,
          customMarkerProperties: MapReCustomMarker.HighlightCircle.properties
        }) : null;
  var match$2 = React.useState(function () {
        
      });
  var setDistanceMarkerCoordinates = match$2[1];
  var distanceMarkerData = match$2[0];
  var distanceMarker = distanceMarkerData !== undefined ? React.createElement(MapReMarkerComponent.make, {
          mapType: "Google",
          coordinates: distanceMarkerData[0],
          customMarkerProperties: MapReCustomMarker.MovementDistance.properties(distanceMarkerData[1])
        }) : null;
  var createMap = function (gmapsApi, domElement) {
    var createCenterOptions = function (center) {
      return {
              center: GoogleMaps.LatLngLiteral.fromGeolocation(center),
              zoom: 14,
              fullscreenControl: false,
              mapTypeControl: false,
              streetViewControl: false,
              maxZoom: 17,
              minZoom: 3
            };
    };
    var fitMap = function (map, boundingBox) {
      GoogleMaps.$$Map.fitBounds(map, GoogleMaps.LatLngBounds.make(gmapsApi, boundingBox), GoogleMaps.$$Map.Padding.make(20, undefined, undefined, undefined, undefined));
    };
    var mapOptions;
    var exit = 0;
    if (region !== undefined && region.NAME === "Center") {
      mapOptions = createCenterOptions(region.VAL);
    } else {
      exit = 1;
    }
    if (exit === 1) {
      mapOptions = fallbackRegion.NAME === "Center" ? createCenterOptions(fallbackRegion.VAL) : defaultMapOptions;
    }
    var map = GoogleMaps.$$Map.make(gmapsApi, domElement, mapOptions);
    var exit$1 = 0;
    if (region !== undefined && region.NAME !== "Center") {
      fitMap(map, region.VAL);
    } else {
      exit$1 = 1;
    }
    if (exit$1 === 1) {
      if (fallbackRegion.NAME === "Center") {
        
      } else {
        fitMap(map, fallbackRegion.VAL);
      }
    }
    setMap(function (param) {
          return Caml_option.some(map);
        });
  };
  React.useEffect((function () {
          var domElement = DomUtils.extractDomElementFromRef(containerRef);
          if (domElement !== undefined) {
            var domElement$1 = Caml_option.valFromOption(domElement);
            var gmapsApi = GoogleMaps.getApi();
            if (gmapsApi !== undefined) {
              createMap(Caml_option.valFromOption(gmapsApi), domElement$1);
            } else {
              PromiseF.map(GoogleMaps.Loader.load(wire.ctx), (function (result) {
                      if (result.TAG === "Ok") {
                        return createMap(result._0, domElement$1);
                      }
                      
                    }));
            }
          }
          
        }), []);
  React.useEffect((function () {
          if (map === undefined) {
            return ;
          }
          var map$1 = Caml_option.valFromOption(map);
          return mapEventsSubscription.subscribe(function ($$event) {
                      var longitude;
                      var latitude;
                      if (typeof $$event !== "object") {
                        if ($$event === "RemoveHighlight") {
                          return Fun.defer(function () {
                                      setHighlightCoordinates(function (param) {
                                            
                                          });
                                    });
                        } else {
                          return Fun.defer(function () {
                                      setDistanceMarkerCoordinates(function (param) {
                                            
                                          });
                                    });
                        }
                      }
                      switch ($$event.TAG) {
                        case "Pan" :
                            var match = $$event._0;
                            longitude = match.longitude;
                            latitude = match.latitude;
                            break;
                        case "PanToRegion" :
                            var match$1 = $$event._0;
                            if (match$1.NAME === "Center") {
                              var match$2 = match$1.VAL;
                              longitude = match$2.longitude;
                              latitude = match$2.latitude;
                            } else {
                              var boundingBox = match$1.VAL;
                              return RCore.$$Option.forEach(GoogleMaps.getApi(), (function (api) {
                                            GoogleMaps.$$Map.fitBounds(map$1, GoogleMaps.LatLngBounds.make(api, boundingBox), GoogleMaps.$$Map.Padding.make(20, undefined, undefined, undefined, undefined));
                                          }));
                            }
                            break;
                        case "AddHighlight" :
                            var coordinates = $$event._0;
                            return Fun.defer(function () {
                                        setHighlightCoordinates(function (param) {
                                              return coordinates;
                                            });
                                      });
                        case "ShowDistance" :
                            var data = $$event._0;
                            return Fun.defer(function () {
                                        setDistanceMarkerCoordinates(function (param) {
                                              
                                            });
                                        setDistanceMarkerCoordinates(function (param) {
                                              return data;
                                            });
                                      });
                        
                      }
                      GoogleMaps.$$Map.panTo(map$1, {
                            longitude: longitude,
                            latitude: latitude
                          });
                    });
        }), [map]);
  React.useEffect((function () {
          var gmapsApi = GoogleMaps.getApi();
          if (gmapsApi === undefined) {
            return ;
          }
          if (map !== undefined && region !== undefined) {
            var map$1 = Caml_option.valFromOption(map);
            if (region.NAME === "Center") {
              var match = region.VAL;
              GoogleMaps.$$Map.panTo(map$1, {
                    longitude: match.longitude,
                    latitude: match.latitude
                  });
            } else {
              GoogleMaps.$$Map.fitBounds(map$1, GoogleMaps.LatLngBounds.make(Caml_option.valFromOption(gmapsApi), region.VAL), GoogleMaps.$$Map.Padding.make(20, undefined, undefined, undefined, undefined));
            }
          }
          
        }), [region]);
  if (map !== undefined) {
    return React.createElement(MapReContext.GoogleMapContext.Provider.make, MapReContext.GoogleMapContext.Provider.makeProps(map, null, undefined), highlightCircle, distanceMarker, RCore.$$Option.getOr(children, null));
  } else {
    return null;
  }
}

function MapReComponent(Props) {
  var wire = Props.wire;
  var containerRef = Props.containerRef;
  var region = Props.region;
  var fallbackRegion = Props.fallbackRegion;
  var mapEventsSubscription = Props.mapEventsSubscription;
  var controls = Props.controls;
  var children = Props.children;
  var children$1 = RCore.$$Option.getOr(children, null);
  var match = wire.ctx.mapType;
  if (typeof match === "object") {
    return null;
  } else if (match === "Yandex") {
    return React.createElement(MapReComponent$YandexMap, {
                wire: wire,
                containerRef: containerRef,
                region: region,
                fallbackRegion: fallbackRegion,
                mapEventsSubscription: mapEventsSubscription,
                controls: controls,
                children: children$1
              });
  } else {
    return React.createElement(MapReComponent$GoogleMap, {
                wire: wire,
                containerRef: containerRef,
                region: region,
                fallbackRegion: fallbackRegion,
                mapEventsSubscription: mapEventsSubscription,
                children: children$1
              });
  }
}

var maxZoom = 17;

var make = MapReComponent;

export {
  maxZoom ,
  make ,
}
/* react Not a pure module */
