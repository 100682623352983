

import * as Caml from "rescript/lib/es6/caml.js";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Locale from "../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as MapTypes from "../MapTypes.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function sortJobs(x) {
  return Js_array.sortInPlaceWith((function (x, y) {
                var match = x.scheduledAt;
                var match$1 = y.scheduledAt;
                if (match === undefined) {
                  if (match$1 !== undefined) {
                    return 1;
                  } else {
                    return Caml.int_compare(x.serialNo, y.serialNo);
                  }
                }
                if (match$1 === undefined) {
                  return -1;
                }
                var cmp = Locale.T.compare(Caml_option.valFromOption(match), Caml_option.valFromOption(match$1));
                if (cmp === 0) {
                  return Caml.int_compare(x.serialNo, y.serialNo);
                } else {
                  return cmp;
                }
              }), x);
}

function replace(js, j) {
  return sortJobs(js.map(function (job) {
                  if (Caml_obj.equal(job.uuid, j.uuid)) {
                    return j;
                  } else {
                    return job;
                  }
                }));
}

function add(js, j) {
  return sortJobs(Belt_Array.concatMany([
                  js,
                  [j]
                ]));
}

function remove(js, rUuid) {
  return RCore.$$Array.keep(js, (function (param) {
                return Caml_obj.notequal(rUuid, param.uuid);
              }));
}

function includes(scheduledJobs, uuid) {
  return Js_array.includes(uuid, Js_array.map((function (param) {
                    return param.uuid;
                  }), scheduledJobs));
}

function scheduled(jobs) {
  return RCore.$$Array.keep(jobs, (function (param) {
                var status = param.status;
                if (status !== "Posted") {
                  return status !== "Scheduled";
                } else {
                  return false;
                }
              }));
}

function unscheduled(jobs) {
  return RCore.$$Array.keep(jobs, (function (param) {
                var status = param.status;
                if (status === "Posted") {
                  return true;
                } else {
                  return status === "Scheduled";
                }
              }));
}

function blurJob(channel, filter, job) {
  var options_today = filter.period === "Today";
  var options = {
    focused: false,
    today: options_today
  };
  MapTypes.EngineChannel.removeJob(channel)(job);
  MapTypes.EngineChannel.addJob(channel)(job, options);
}

function focusJob(channel, filter, job) {
  var options_today = filter.period === "Today";
  var options = {
    focused: true,
    today: options_today
  };
  MapTypes.EngineChannel.locateJob(channel)(job);
  MapTypes.EngineChannel.removeJob(channel)(job);
  MapTypes.EngineChannel.addJob(channel)(job, options);
}

function replaceJobs(showUnscheduled, channel, filter, prevJobs, nextJobs) {
  var options_today = filter.period === "Today";
  var options = {
    focused: false,
    today: options_today
  };
  prevJobs.forEach(MapTypes.EngineChannel.removeJob(channel));
  var jobs = showUnscheduled ? nextJobs : scheduled(nextJobs);
  var addSingle = MapTypes.EngineChannel.addJob(channel);
  jobs.forEach(function (__x) {
        addSingle(__x, options);
      });
}

function eachLocation(workers, locations, channel, cb) {
  locations.forEach(function ($$location) {
        var worker = RCore.$$Array.getBy(workers, (function (w) {
                return Caml_obj.equal(w.uuid, $$location.workerUuid);
              }));
        if (worker !== undefined) {
          return cb(channel, worker, $$location);
        }
        
      });
}

function replaceLocations(channel, prevWorkers, prevLocations, nextWorkers, nextLocations, today, dates) {
  eachLocation(prevWorkers, prevLocations, channel, MapTypes.EngineChannel.removeWorker);
  var showLocations = Core__List.length(dates) === 1 && RCore.$$Option.mapWithDefault(Core__List.head(dates), false, (function (x) {
          return Locale.T.equal(today, x);
        }));
  if (showLocations) {
    return eachLocation(nextWorkers, nextLocations, channel, MapTypes.EngineChannel.addWorker);
  }
  
}

var $$Map = {
  JobUtils: undefined,
  blurJob: blurJob,
  focusJob: focusJob,
  replaceJobs: replaceJobs,
  eachLocation: eachLocation,
  replaceLocations: replaceLocations
};

var Jobs = {
  replace: replace,
  add: add,
  remove: remove,
  includes: includes,
  unscheduled: unscheduled
};

export {
  Jobs ,
  $$Map ,
}
/* Locale Not a pure module */
