import { recurringJobsPath, recurringJobPath } from 'planado/routes.js'
import { sendJSONRequest } from 'planado/utils/network.js'
import { presentTime } from 'planado/stores/admin/recurring_jobs_show'
import { BLANK_ADDRESS } from 'planado/types/address'
import { serializeAssignees } from 'planado/utils/serialize_assignees.js'
import { concatDateWithTime } from 'planado/utils/time/index.js'

export const SET_FIELD = 'SET_FIELD'
export const SET_ASSIGNEES = 'SET_ASSIGNEES'
export const SET_ERROR = 'SET_ERROR'
export const SET_CLIENT = 'SET_CLIENT'
export const SET_SITE = 'SET_SITE'
export const SET_RECURRING_JOB = 'SET_RECURRING_JOB'
export const CANCEL_SET_CLIENT_OR_SITE = 'CANCEL_SET_CLIENT_OR_SITE'

export const UPDATE_FIELD = 'UPDATE_FIELD'

export const SEND_REQUEST_START = 'SEND_REQUEST_START'
export const SEND_REQUEST_FINISH = 'SEND_REQUEST_FINISH'

export const SHOW_POPOVER = 'SHOW_POPOVER'
export const HIDE_POPOVER = 'HIDE_POPOVER'

export const setField = (name, value) => ({ type: SET_FIELD, name, value })

export const setError = (name, value) => ({ type: SET_ERROR, name, value })

export const setAssignees = value => ({ type: SET_ASSIGNEES, value })

const buildJobQuery = (
  ctx,
  {
    typeId: _typeId,
    template: _template,
    createBeforeScheduledAtDays,
    scheduledDuration,
    nextScheduledAt,
    stopRepeatingAt,
    client,
    site,
    address,
    assignees,
    ...rest
  }
) => ({
  recurringJob: {
    assignees: serializeAssignees(assignees),
    address: address || BLANK_ADDRESS,
    createBeforeScheduledAtDays: createBeforeScheduledAtDays,
    scheduledDuration: scheduledDuration,
    nextScheduledAt: concatDateWithTime({ ...nextScheduledAt, ctx }),
    stopRepeatingAt: concatDateWithTime({ ...stopRepeatingAt, ctx }),
    clientUuid: client && client.uuid,
    siteUuid: site && site.uuid,
    ...rest
  }
})

export const setClient = ({ data }) => dispatch => {
  if (data) {
    dispatch({
      type: SET_CLIENT,
      addressDescription: data.addressDescription,
      address: data.siteAddress,
      entranceNo: data.entranceNo,
      apartment: data.apartment,
      floor: data.floor,
      contacts: data.contacts,
      client: {
        name: data.name,
        uuid: data.uuid,
        organization: data.organization
      },
      site: null
    })
  } else {
    throw 'Select data must not be null!'
  }
}

export const setSite = ({ data, value }) => dispatch => {
  if (data) {
    dispatch({
      type: SET_SITE,
      addressDescription: data.addressDescription,
      address: data.address,
      entranceNo: data.entranceNo,
      apartment: data.apartment,
      floor: data.floor,
      contacts: data.contacts,
      client: data.client && {
        name: data.client.name,
        id: data.client.id,
        organization: data.client.organization
      },
      site: {
        name: data.name,
        uuid: value
      }
    })
  } else {
    throw 'Select data must not be null!'
  }
}

export const cancelSetClientOrSite = () => ({
  type: CANCEL_SET_CLIENT_OR_SITE
})

export const sendForm = (ctx, onSubmitJob) => (dispatch, getState) => {
  const { isNew, recurringJob } = getState()

  const url = isNew ? recurringJobsPath : recurringJobPath(recurringJob.uuid)

  const body = buildJobQuery(ctx, recurringJob)

  dispatch({ type: SEND_REQUEST_START })

  sendJSONRequest(url, ctx, {
    method: isNew ? 'POST' : 'PATCH',
    body
  }).then(res => {
    dispatch({ type: SEND_REQUEST_FINISH })

    const { recurringJob, errors, ...data } = res

    const { nextScheduledAt, stopRepeatingAt } = recurringJob

    const newState = {
      ...data,
      recurringJob: {
        ...recurringJob,
        ...presentTime(nextScheduledAt, stopRepeatingAt, ctx)
      },
      errors: {
        name: [],
        nextScheduledAtDate: [],
        stopRepeatingAtDate: []
      },
      popover: { targetId: null }
    }

    dispatch({
      type: SET_RECURRING_JOB,
      newState
    })

    if (errors !== null) {
      console.error(errors)
    }

    onSubmitJob(recurringJob.uuid, res)
  })
}

export const showPopover = (node, dom, evt, buttonId) => (
  dispatch,
  getState
) => {
  if (getState().popover.targetId !== buttonId) {
    setTimeout(
      () =>
        dispatch({
          type: SHOW_POPOVER,
          dom,
          buttonId,
          node
        }),
      0
    )
  }
}

export const closePopover = () => ({ type: HIDE_POPOVER })
