import React from 'react'
import { Provider } from 'react-redux'
import {
  HeaderLink,
  TableLink
} from 'planado/containers/admin/dictionaries_index'

export default props => (
  <Provider {...props}>
    <>
      <HeaderLink />
      <TableLink />
    </>
  </Provider>
)
