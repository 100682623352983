

import * as RCore from "../../../../libraries/RCore.mjs";
import * as Locale from "../../../../libraries/Locale.mjs";
import * as Schedule_Constants from "../Schedule_Constants.mjs";

function hoursInDay(businessHours) {
  if (businessHours !== undefined) {
    return Locale.T.Schedule.finishDurationToHours(businessHours.finish) - Locale.T.Schedule.startDurationToHours(businessHours.start) | 0;
  } else {
    return Schedule_Constants.hoursInDay;
  }
}

function startOfDay(date, wire, businessHours) {
  if (businessHours !== undefined) {
    return Locale.T.nextHours(Locale.T.Schedule.startDurationToHours(businessHours.start))(Locale.T.startOf("day", RCore.$$Option.getOr(date, Locale.T.now(wire))));
  } else {
    return Locale.T.startOf("day", RCore.$$Option.getOr(date, Locale.T.now(wire)));
  }
}

export {
  hoursInDay ,
  startOfDay ,
}
/* Locale Not a pure module */
