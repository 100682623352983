

import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as MapTypes from "../MapTypes.mjs";
import * as PromiseF from "../../../libraries/PromiseF.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as Types_Date from "../../../types/Types_Date.mjs";
import * as JobTypeSelect from "../../inputs/JobTypeSelect.mjs";
import * as Shared_Lib_IO from "../../../shared/lib/Shared_Lib_IO.mjs";
import * as TemplateSelect from "../../inputs/TemplateSelect.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              scheduledJobs: field.required("scheduledJobs", JsonDecode.array(MapTypes.Job.decode)),
              unscheduledJobs: field.required("unscheduledJobs", JsonDecode.array(MapTypes.Job.decode)),
              start: field.required("start", Types_Date.decode),
              finish: field.required("finish", Types_Date.decode),
              templates: field.required("templates", JsonDecode.array(TemplateSelect.Template.decode)),
              types: field.required("types", JsonDecode.array(JobTypeSelect.JobType.decode)),
              territories: field.required("territories", JsonDecode.array(MapTypes.Territory.decode)),
              workers: field.required("workers", JsonDecode.array(MapTypes.$$Worker.decode)),
              locations: field.required("locations", JsonDecode.array(MapTypes.$$Location.decode))
            };
    });

function $$fetch(wire, filter) {
  return Shared_Lib_IO.debounce(undefined, 300, (function () {
                  return PromiseF.toIOLazy(function () {
                              return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                                              NAME: "API",
                                              VAL: {
                                                NAME: "Map",
                                                VAL: {
                                                  NAME: "Jobs",
                                                  VAL: filter
                                                }
                                              }
                                            }), decode);
                            });
                }))();
}

function $$fetch$1(wire, uuid) {
  return Shared_Lib_IO.debounce(undefined, 300, (function () {
                  return PromiseF.toIOLazy(function () {
                              return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                                              NAME: "API",
                                              VAL: {
                                                NAME: "Map",
                                                VAL: {
                                                  NAME: "Job",
                                                  VAL: uuid
                                                }
                                              }
                                            }), MapTypes.Job.decode);
                            });
                }))();
}

var Job = {
  $$fetch: $$fetch$1
};

function $$fetch$2(wire, uuid) {
  return Shared_Lib_IO.debounce(undefined, 300, (function () {
                  return PromiseF.toIOLazy(function () {
                              return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                                              NAME: "API",
                                              VAL: {
                                                NAME: "Map",
                                                VAL: {
                                                  NAME: "Location",
                                                  VAL: uuid
                                                }
                                              }
                                            }), MapTypes.$$Location.decode);
                            });
                }))();
}

var $$Location = {
  $$fetch: $$fetch$2
};

var Index = {
  $$fetch: $$fetch
};

export {
  Index ,
  Job ,
  $$Location ,
}
/* decode Not a pure module */
