

import * as React from "react";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as DayTimeline_State from "../../lib/state/DayTimeline_State.mjs";
import * as DayTimeline_Timeline from "../timeline/DayTimeline_Timeline.mjs";
import * as Schedule_Unscheduled from "../../../../components/uscheduled_jobs/Schedule_Unscheduled.mjs";
import * as DayTimeline_Unassigned from "../unassigned/DayTimeline_Unassigned.mjs";
import * as Schedule_Types_Timeline from "../../../../lib/types/Schedule_Types_Timeline.mjs";
import * as Schedule_TimelineSidebar from "../../../../components/timeline/sidebar/Schedule_TimelineSidebar.mjs";
import * as DayTimeline_ContentModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/day_timeline/components/content/DayTimeline_Content.module.css";

var styles = DayTimeline_ContentModuleCss;

function DayTimeline_Content$Component(Props) {
  var param = Props.elements;
  var viewportEl = param.viewportEl;
  var wire = AppContext.useWire();
  var filter = Schedule_State.useFilter();
  var jobs = Schedule_State.useAssignedJobs();
  var scheduleTimelines = Schedule_State.useScheduleTimelines();
  var bars = React.useMemo((function () {
          return Schedule_Types_Timeline.Bars.make(wire, filter, jobs, Belt_Array.concatMany([
                          scheduleTimelines.teamTimelines,
                          scheduleTimelines.workersTimelines
                        ]));
        }), [jobs]);
  var match = DayTimeline_State.useTimelineSubscriptions();
  return React.createElement("div", {
              className: styles.container
            }, React.createElement("div", {
                  ref: param.scrollAreaEl,
                  className: styles.viewport + " " + wire.tour.anchor("schedule-viewport")
                }, React.createElement(Schedule_TimelineSidebar.make, {
                      elementRef: param.sidebarEl,
                      assignees: bars.map(function (param) {
                            return param.assignee;
                          }),
                      onActiveAssigneeChanged: match.onActiveAssigneeChanged
                    }), React.createElement(DayTimeline_Timeline.make, {
                      viewportEl: viewportEl,
                      scaleEl: param.scaleEl,
                      bars: bars
                    }), React.createElement(DayTimeline_Unassigned.make, {
                      viewportEl: viewportEl,
                      unassignedEl: param.unassignedEl
                    })), React.createElement("div", {
                  className: styles.separator
                }), React.createElement(Schedule_Unscheduled.make, {}));
}

var make = React.memo(DayTimeline_Content$Component, (function (_prevProps, nextProps) {
        return nextProps.locked;
      }));

function DayTimeline_Content(Props) {
  var elements = Props.elements;
  var locked = Schedule_State.useLocked();
  return React.createElement(make, {
              elements: elements,
              locked: locked
            });
}

var make$1 = DayTimeline_Content;

export {
  make$1 as make,
}
/* styles Not a pure module */
