

import * as El from "../../libraries/El.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as React from "react";
import * as Portal from "../../Portal.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as MessengerItem from "./MessengerItem.mjs";
import * as MessengerTypes from "./MessengerTypes.mjs";
import * as MessengerModuleCss from "/home/runner/work/planado/planado/client/rescript/components/messenger/Messenger.module.css";

var styles = MessengerModuleCss;

function containerStyle(pos) {
  var tmp;
  switch (pos) {
    case "TopLeft" :
        tmp = El.Cn.concat(styles.top, styles.left);
        break;
    case "TopRight" :
        tmp = El.Cn.concat(styles.top, styles.right);
        break;
    case "BottomLeft" :
        tmp = El.Cn.concat(styles.bottom, styles.left);
        break;
    case "BottomRight" :
        tmp = El.Cn.concat(styles.bottom, styles.right);
        break;
    
  }
  return styles.area + " " + tmp;
}

function Messenger(Props) {
  var param = Props.wire;
  var subscriptions = param.subscriptions;
  var match = React.useState(function () {
        return [];
      });
  var setMessages = match[1];
  var messages = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setHovered = match$1[1];
  var hovered = match$1[0];
  var removeMessage = function (message) {
    setMessages(function (messages) {
          return RCore.$$Array.keep(messages, (function (x) {
                        return x !== message;
                      }));
        });
  };
  React.useEffect((function () {
          return subscriptions.messenger.subscribe(function ($$event) {
                      switch ($$event.TAG) {
                        case "Show" :
                            var message = $$event._0;
                            return setMessages(function (messages) {
                                        return Belt_Array.concatMany([
                                                    messages,
                                                    [message]
                                                  ]);
                                      });
                        case "Close" :
                            return removeMessage($$event._0);
                        case "ChangeHover" :
                            var hovered = $$event._0;
                            return setHovered(function (param) {
                                        return hovered;
                                      });
                        
                      }
                    });
        }), []);
  return React.createElement(Portal.make, {
              root: param.footer,
              children: React.createElement("div", {
                    className: styles.messenger
                  }, MessengerTypes.Position.containers.map(function (pos) {
                        var className = containerStyle(pos);
                        var ms = RCore.$$Array.keep(messages, (function (m) {
                                return m.position === pos;
                              }));
                        return React.createElement("div", {
                                    key: className,
                                    className: className
                                  }, ms.map(function (message) {
                                        return React.createElement(MessengerItem.make, {
                                                    message: message,
                                                    onClose: (function () {
                                                        removeMessage(message);
                                                      }),
                                                    hovered: hovered,
                                                    onHover: (function (param) {
                                                        setHovered(function (param) {
                                                              return true;
                                                            });
                                                      }),
                                                    onLeave: (function (param) {
                                                        setHovered(function (param) {
                                                              return false;
                                                            });
                                                      }),
                                                    key: message.id
                                                  });
                                      }));
                      }))
            });
}

var make = Messenger;

export {
  make ,
}
/* styles Not a pure module */
