

import * as Fun from "./Fun.mjs";
import * as RCore from "./RCore.mjs";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as MomentRe from "../bindings/moment/MomentRe.mjs";
import * as Core__Int from "@rescript/core/lib/es6/src/Core__Int.mjs";
import * as JsContext from "./JsContext.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as LocaleBase from "./LocaleBase.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IndexJs from "planado/utils/time/index.js";
import * as HelpersJs from "planado/utils/time/helpers.js";

var _formatString = LocaleBase.T._formatString;

function toString(rawFormat) {
  switch (rawFormat) {
    case "Date" :
        return "DD.MM.YYYY";
    case "DateISO" :
        return "YYYY-MM-DD";
    case "DateTimeISO" :
        return "YYYY-MM-DDTHH:mm:ssZZ";
    
  }
}

var RawFormat = {
  toString: toString
};

function parse(format, ctx) {
  return function (value) {
    return MomentRe.momentWithFormat(value, HelpersJs.toMomentJSFormat(HelpersJs.presentFormat(_formatString(format), ctx)));
  };
}

function fmtS(formatOpt, wire) {
  var format = formatOpt !== undefined ? formatOpt : "Date";
  return function (s) {
    return IndexJs.showTime(s, _formatString(format), JsContext.get(wire));
  };
}

function fmtM(formatOpt, wire) {
  var format = formatOpt !== undefined ? formatOpt : "Date";
  return function (m) {
    return IndexJs.showTime(m, _formatString(format), JsContext.get(wire));
  };
}

function fmtMwContext(formatOpt, ctx, m) {
  var format = formatOpt !== undefined ? formatOpt : "Date";
  return IndexJs.showTime(m, _formatString(format), ctx);
}

function formatM(rawFormat) {
  return function (m) {
    return MomentRe.Moment.format(m, toString(rawFormat));
  };
}

function isoDate(m) {
  return MomentRe.Moment.format(m, "YYYY-MM-DD");
}

var toIsoM = (function(m) { return m.format() });

function concatDateWithTime(dateTime, wire) {
  return Caml_option.nullable_to_opt(IndexJs.concatDateWithTime({
                  date: Js_null.fromOption(dateTime.date),
                  time: Js_null.fromOption(dateTime.time),
                  ctx: JsContext.get(wire)
                }));
}

function toDateTime(dateTime, wire) {
  return {
          date: fmtM("Date", wire)(dateTime),
          time: fmtM("Time", wire)(dateTime)
        };
}

function fmt(formatOpt, wire) {
  var format = formatOpt !== undefined ? formatOpt : "Date";
  return function (d) {
    return IndexJs.showTime(d, _formatString(format), JsContext.get(wire));
  };
}

function now(wire) {
  return IndexJs.nowM(JsContext.get(wire));
}

function setDefaultTimeZone(wire) {
  IndexJs.setDefaultTimeZone(JsContext.get(wire));
}

var add = MomentRe.Moment.add;

var subtract = MomentRe.Moment.subtract;

var hour = MomentRe.Moment.hour;

var minute = MomentRe.Moment.minute;

var equal = MomentRe.Moment.isSame;

function equalDay(t1, t2) {
  return MomentRe.Moment.isSameWithGranularity(t1, t2, "day");
}

function equalDay3(t1, t2, t3) {
  if (equalDay(t1, t2)) {
    return equalDay(t1, t3);
  } else {
    return false;
  }
}

function equalDay4(t1, t2, t3, t4) {
  if (equalDay3(t1, t2, t3)) {
    return equalDay(t1, t4);
  } else {
    return false;
  }
}

function equalMonth(t1, t2) {
  return MomentRe.Moment.isSameWithGranularity(t1, t2, "month");
}

var lessThan = MomentRe.Moment.isBefore;

var equalOrLessThan = MomentRe.Moment.isSameOrBefore;

var moreThan = MomentRe.Moment.isAfter;

var equalOrMoreThan = MomentRe.Moment.isSameOrAfter;

var isValid = MomentRe.Moment.isValid;

function compare(a, b) {
  if (lessThan(a, b)) {
    return -1;
  } else if (equal(a, b)) {
    return 0;
  } else {
    return 1;
  }
}

function withinInterval(date, param) {
  var b = param[1];
  var a = param[0];
  if (moreThan(date, a) || equal(date, a)) {
    if (lessThan(date, b)) {
      return true;
    } else {
      return equal(date, b);
    }
  } else {
    return false;
  }
}

function fromDateTime(dateTime, wire) {
  return RCore.$$Option.map(concatDateWithTime(dateTime, wire), MomentRe.momentDefaultFormat);
}

function intersectInterval(param, param$1) {
  var bEnd = param$1[1];
  var bStart = param$1[0];
  var aEnd = param[1];
  var aStart = param[0];
  if (lessThan(aStart, bEnd) || equal(aStart, bEnd)) {
    if (moreThan(aEnd, bStart)) {
      return true;
    } else {
      return equal(aEnd, bStart);
    }
  } else {
    return false;
  }
}

function intersectIntervalStrict(param, param$1) {
  if (lessThan(param[0], param$1[1])) {
    return moreThan(param[1], param$1[0]);
  } else {
    return false;
  }
}

function min(a, b) {
  if (lessThan(a, b)) {
    return a;
  } else {
    return b;
  }
}

function max(a, b) {
  if (lessThan(a, b)) {
    return b;
  } else {
    return a;
  }
}

function makeIsoDate(x) {
  return MomentRe.momentWithFormat("YYYY-MM-DD", x);
}

function nowRoundTime(step, bias, wire) {
  var props = {
    ctx: JsContext.get(wire),
    step: Js_null.fromOption(step),
    bias: Js_null.fromOption(bias)
  };
  return fmtM("Time", wire)(MomentRe.momentDefaultFormat(IndexJs.nowRound(props)));
}

function next(d, duration) {
  var variant = duration.NAME;
  var match = variant === "months" ? [
      "months",
      duration.VAL
    ] : (
      variant === "weeks" ? [
          "weeks",
          duration.VAL
        ] : (
          variant === "years" ? [
              "years",
              duration.VAL
            ] : [
              "days",
              duration.VAL
            ]
        )
    );
  return add(MomentRe.duration(match[1], match[0]), d);
}

function nextMinutes(n, d) {
  return add(MomentRe.duration(n, "minutes"), d);
}

function prevMinutes(n, date) {
  return subtract(MomentRe.duration(n, "minutes"), date);
}

function prevMinutesD(duration, date) {
  return subtract(duration, date);
}

function nextHours(n) {
  return function (d) {
    return add(MomentRe.duration(n, "hours"), d);
  };
}

function nextDays(n) {
  return function (d) {
    return add(MomentRe.duration(n, "days"), d);
  };
}

function nextWeeks(n) {
  return function (d) {
    return add(MomentRe.duration(n, "weeks"), d);
  };
}

function nextMonths(n) {
  return function (d) {
    return add(MomentRe.duration(n, "months"), d);
  };
}

function prevWeeks(n) {
  return nextWeeks(-n | 0);
}

function prevMonths(n) {
  return nextMonths(-n | 0);
}

function nextDay(d) {
  return add(MomentRe.duration(1, "days"), d);
}

function nextWeek(d) {
  return add(MomentRe.duration(1, "weeks"), d);
}

function nextMonth(d) {
  return add(MomentRe.duration(1, "months"), d);
}

function prevDays(n) {
  return nextDays(-n | 0);
}

function prevDay(d) {
  return add(MomentRe.duration(-1, "days"), d);
}

function prevWeek(d) {
  return add(MomentRe.duration(-1, "weeks"), d);
}

function prevMonth(d) {
  return add(MomentRe.duration(-1, "months"), d);
}

var startOf = MomentRe.Moment.startOf;

var endOf = MomentRe.Moment.endOf;

function dateRange(size, start) {
  return Core__List.mapWithIndex(Fun.repeat(size, start), (function (x, i) {
                return nextDays(i)(x);
              }));
}

function daysBetween(a, b) {
  return MomentRe.diff(a, b, "days") | 0;
}

function hoursBetween(a, b) {
  return MomentRe.diff(a, b, "hours") | 0;
}

function minutesBetween(a, b) {
  return MomentRe.diff(a, b, "minutes") | 0;
}

var weekday = MomentRe.Moment.weekday;

var getDay = MomentRe.Moment.day;

var month = MomentRe.Moment.month;

var year = MomentRe.Moment.year;

var week = MomentRe.Moment.week;

var monthDay = MomentRe.Moment.date;

function dateToMinutes(wire, date) {
  var time = fmtM("Time", wire)(date);
  var match = Js_string.split(":", time);
  if (match.length !== 2) {
    return 0;
  }
  var hourStr = match[0];
  var minuteStr = match[1];
  var hour = Core__Int.fromString(hourStr, undefined);
  var minute = Core__Int.fromString(minuteStr, undefined);
  if (hour !== undefined && minute !== undefined) {
    return Math.imul(hour, 60) + minute | 0;
  } else {
    return 0;
  }
}

function secondsToTime(wire, seconds) {
  return IndexJs.secondsToTime(seconds, JsContext.get(wire));
}

function presentDatetime(wire, datetime) {
  return IndexJs.presentDatetime(datetime, JsContext.get(wire));
}

function durationFormatWithLocalizator(format, localizator, value) {
  return MomentRe.durationFormat(value, HelpersJs.toMomentJSFormat(HelpersJs.presentFormatWithLocalizator(_formatString(format), localizator)));
}

function isoWeekday(date) {
  return date.isoWeekday() - 1 | 0;
}

function durationToUnit(duration) {
  var variant = duration.NAME;
  if (variant === "months") {
    return "month";
  } else if (variant === "weeks") {
    return "week";
  } else if (variant === "years") {
    return "year";
  } else {
    return "day";
  }
}

function makeRange(from, duration) {
  var start = next(from, duration);
  var timeUnit = durationToUnit(duration);
  return [
          startOf(timeUnit, start),
          endOf(timeUnit, start)
        ];
}

function isRangeValid(a, b) {
  var match = Fun.both(a, b);
  if (match === undefined) {
    return false;
  }
  var b$1 = match[1];
  var a$1 = match[0];
  if (isValid(a$1) && isValid(b$1)) {
    if (lessThan(a$1, b$1)) {
      return true;
    } else {
      return equalDay(a$1, b$1);
    }
  } else {
    return false;
  }
}

function range(wire, param) {
  var b = param[1];
  var a = param[0];
  var start = fmtM(year(a) === year(b) ? "DateFullNoYear" : "DateFullMonthAbbr", wire)(a);
  var finish = fmtM("DateFullNoYear", wire)(b);
  var y = year(b).toString();
  return Js_string.replaceByRe(/\./g, "", start + " – " + finish + ", " + y);
}

function startOfCurrentDay(wire) {
  var __x = IndexJs.nowM(JsContext.get(wire));
  return startOf("day", __x);
}

function startOfCurrentWeek(wire) {
  var __x = startOfCurrentDay(wire);
  return startOf("isoWeek", __x);
}

function startOfCurrentMonth(wire) {
  var __x = startOfCurrentDay(wire);
  return startOf("month", __x);
}

function endOfCurrentDay(wire) {
  var __x = IndexJs.nowM(JsContext.get(wire));
  return endOf("day", __x);
}

function endOfCurrentWeek(wire) {
  var __x = endOfCurrentDay(wire);
  return endOf("isoWeek", __x);
}

function endOfCurrentMonth(wire) {
  var __x = endOfCurrentDay(wire);
  return endOf("month", __x);
}

function durationFormatWithLocalizator$1(format, localizator, value) {
  return MomentRe.durationFormat(value, HelpersJs.toMomentJSFormat(HelpersJs.presentFormatWithLocalizator(_formatString(format), localizator)));
}

function startDurationToHours(v) {
  return Js_math.floor(MomentRe.Duration.asHours(v));
}

function finishDurationToHours(v) {
  return Js_math.ceil(MomentRe.Duration.asHours(v));
}

function durationToMinutes(v) {
  return MomentRe.Duration.asMinutes(v) | 0;
}

var Schedule = {
  range: range,
  startOfCurrentDay: startOfCurrentDay,
  startOfCurrentWeek: startOfCurrentWeek,
  startOfCurrentMonth: startOfCurrentMonth,
  endOfCurrentDay: endOfCurrentDay,
  endOfCurrentWeek: endOfCurrentWeek,
  endOfCurrentMonth: endOfCurrentMonth,
  durationFormatWithLocalizator: durationFormatWithLocalizator$1,
  startDurationToHours: startDurationToHours,
  finishDurationToHours: finishDurationToHours,
  durationToMinutes: durationToMinutes
};

var T = {
  Duration: undefined,
  Moment: undefined,
  _formatString: _formatString,
  RawFormat: RawFormat,
  parse: parse,
  fmtS: fmtS,
  fmtM: fmtM,
  fmtMwContext: fmtMwContext,
  formatM: formatM,
  isoDate: isoDate,
  toIsoM: toIsoM,
  concatDateWithTime: concatDateWithTime,
  toDateTime: toDateTime,
  fmt: fmt,
  now: now,
  setDefaultTimeZone: setDefaultTimeZone,
  add: add,
  subtract: subtract,
  hour: hour,
  minute: minute,
  make: MomentRe.momentDefaultFormat,
  makeWithFormat: MomentRe.momentWithFormat,
  equal: equal,
  equalDay: equalDay,
  equalDay3: equalDay3,
  equalDay4: equalDay4,
  equalMonth: equalMonth,
  lessThan: lessThan,
  equalOrLessThan: equalOrLessThan,
  moreThan: moreThan,
  equalOrMoreThan: equalOrMoreThan,
  isValid: isValid,
  compare: compare,
  withinInterval: withinInterval,
  fromDateTime: fromDateTime,
  intersectInterval: intersectInterval,
  intersectIntervalStrict: intersectIntervalStrict,
  min: min,
  max: max,
  makeIsoDate: makeIsoDate,
  nowRoundTime: nowRoundTime,
  duration: MomentRe.duration,
  floorToHourStart: MomentRe.floorToHourStart,
  next: next,
  nextMinutes: nextMinutes,
  prevMinutes: prevMinutes,
  prevMinutesD: prevMinutesD,
  nextHours: nextHours,
  nextDays: nextDays,
  nextWeeks: nextWeeks,
  nextMonths: nextMonths,
  prevWeeks: prevWeeks,
  prevMonths: prevMonths,
  nextDay: nextDay,
  nextWeek: nextWeek,
  nextMonth: nextMonth,
  prevDays: prevDays,
  prevDay: prevDay,
  prevWeek: prevWeek,
  prevMonth: prevMonth,
  startOf: startOf,
  endOf: endOf,
  dateRange: dateRange,
  daysBetween: daysBetween,
  hoursBetween: hoursBetween,
  minutesBetween: minutesBetween,
  weekday: weekday,
  getDay: getDay,
  month: month,
  year: year,
  week: week,
  monthDay: monthDay,
  dateToMinutes: dateToMinutes,
  secondsToTime: secondsToTime,
  presentDatetime: presentDatetime,
  durationFormatWithLocalizator: durationFormatWithLocalizator,
  isoWeekday: isoWeekday,
  durationToUnit: durationToUnit,
  makeRange: makeRange,
  isRangeValid: isRangeValid,
  Schedule: Schedule
};

export {
  T ,
}
/* MomentRe Not a pure module */
