

import * as Fun from "../../../../../../../libraries/Fun.mjs";
import * as Types from "../../../../../../../types/Types.mjs";
import * as Units from "../../../../../../../types/Units.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Schedule_State from "../../../../../lib/state/Schedule_State.mjs";
import * as DayTimeline_State from "../../../lib/state/DayTimeline_State.mjs";
import * as Schedule_UtilsTimeline from "../../../../../lib/utils/Schedule_UtilsTimeline.mjs";
import * as DayTimeline_ScaleModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/day_timeline/components/timeline/scale/DayTimeline_Scale.module.css";

var styles = DayTimeline_ScaleModuleCss;

function elementBounds(element, content) {
  var rect = element.getBoundingClientRect();
  var point1_0 = Units.Px.fromFloat(rect.left);
  var point1_1 = Units.Px.fromFloat(rect.y);
  var point1 = [
    point1_0,
    point1_1
  ];
  var point2_0 = Units.Px.fromFloat(rect.right);
  var point2_1 = Units.Px.fromFloat(rect.y);
  var point2 = [
    point2_0,
    point2_1
  ];
  var match = Schedule_UtilsTimeline.ConvertPoint.fromPageToNode(content, point1);
  var match$1 = Schedule_UtilsTimeline.ConvertPoint.fromPageToNode(content, point2);
  return [
          match[0],
          match$1[0]
        ];
}

function inBounds(x, param) {
  if (Caml_obj.greaterequal(x, param[0])) {
    return Caml_obj.lessequal(x, param[1]);
  } else {
    return false;
  }
}

function use(viewportEl) {
  var locked = Schedule_State.useLocked();
  var active = React.useRef(false);
  var elementRef = Types.ReactRef.use();
  var match = DayTimeline_State.useTimelineSubscriptions();
  var onMouseLeave = match.onMouseLeave;
  var onMouseMove = match.onMouseMove;
  var resetActive = function (element) {
    requestAnimationFrame(function (_time) {
          element.classList.remove(styles.visible);
          active.current = false;
        });
  };
  React.useEffect((function () {
          if (locked) {
            return ;
          }
          var element = Types.ReactRef.toOption(elementRef);
          var content = Schedule_UtilsTimeline.contentEl(viewportEl);
          var match = Fun.both(element, content);
          if (match === undefined) {
            return ;
          }
          var element$1 = match[0];
          var bounds = elementBounds(element$1, match[1]);
          if (bounds === undefined) {
            return ;
          }
          var unsubscribeFromMouseMove = onMouseMove.subscribe(function (coordinate) {
                var inBounds$1 = inBounds(coordinate[0], bounds);
                if (!active.current && inBounds$1) {
                  requestAnimationFrame(function (_time) {
                        element$1.classList.add(styles.visible);
                        active.current = true;
                      });
                  return ;
                } else if (active.current && !inBounds$1) {
                  return resetActive(element$1);
                } else {
                  return ;
                }
              });
          var unsubscribeFromMouseLeave = onMouseLeave.subscribe(function () {
                resetActive(element$1);
              });
          return (function () {
                    resetActive(element$1);
                    unsubscribeFromMouseMove();
                    unsubscribeFromMouseLeave();
                  });
        }), [locked]);
  return elementRef;
}

function DayTimeline_ScaleHour(Props) {
  var hour = Props.hour;
  var viewportEl = Props.viewportEl;
  var ref = use(viewportEl);
  return React.createElement("div", {
              className: styles.hour
            }, hour, React.createElement("div", {
                  ref: ref,
                  className: styles.active
                }));
}

var make = React.memo(DayTimeline_ScaleHour);

export {
  make ,
}
/* styles Not a pure module */
