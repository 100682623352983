


function fromString(s) {
  return s;
}

function toString(s) {
  return s;
}

function btoa$1(prim) {
  return btoa(prim);
}

function atob$1(prim) {
  return atob(prim);
}

export {
  fromString ,
  toString ,
  btoa$1 as btoa,
  atob$1 as atob,
}
/* No side effect */
