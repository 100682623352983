// extracted by mini-css-extract-plugin
export var buttonsContainer = "buttons-container-xNAchoo0LdEN8UXqmdoR";
export var description = "description-NoToUjVpWQqf5dmXAaqQ";
export var documentationUrl = "documentation-url-Usjt3CLQl7jMwD4Ub8XG";
export var header = "header-fphyEwBBgQ5dlRTHD1G5";
export var input = "input-mnzRkO8pCkVrmGpjx5Tj";
export var label = "label-rZs2az3FMcVg3v96loM7";
export var modalBody = "modal-body-loQ0vhvrCzcGFnhdtOP2";
export var modalDialog = "modal-dialog-yXJBcP4cfH6rJrpXUjec";
export var result = "result-MgEGftlr5i3sluCEM4y7";
export var spinner = "spinner-bNB31_wgX5EuyiwoZU3g";
export var status = "status-j4v5LfD7mqtynwS2cXeT";
export var title = "title-r4PvCDH3bl_hAiI5eUkl";
export var uploadButton = "upload-button-modhoMIc_AyeShHhG0V1";