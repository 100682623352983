

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Types from "../../../types/Types.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              formatted: field.required("formatted", JsonDecode.option(JsonDecode.string)),
              floor: field.required("floor", JsonDecode.option(JsonDecode.string)),
              apartment: field.required("apartment", JsonDecode.option(JsonDecode.string)),
              entranceNo: field.required("entranceNo", JsonDecode.option(JsonDecode.string))
            };
    });

var decode$1 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              clientId: field.required("clientId", Types.Id.decode),
              name: field.required("name", JsonDecode.option(JsonDecode.string))
            };
    });

var decode$2 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              siteId: field.required("siteId", Types.Id.decode),
              name: field.required("name", JsonDecode.string),
              client: field.required("client", JsonDecode.option(decode$1)),
              address: field.required("address", decode),
              phone: field.required("phone", JsonDecode.option(JsonDecode.string))
            };
    });

var Site = {
  decode: decode$2
};

var Bounds = {};

var Address = {};

var Client = {};

function Filter_toJson(prim) {
  return prim;
}

var Filter_initial = {
  bounds: {
    offset: 0,
    limit: 50
  }
};

var Filter = {
  toJson: Filter_toJson,
  initial: Filter_initial
};

export {
  Address ,
  Client ,
  Site ,
  Bounds ,
  Filter ,
}
/* decode Not a pure module */
