

import * as Locale from "../libraries/Locale.mjs";
import * as JsonDecode from "../libraries/JsonDecode.mjs";

var decode = JsonDecode.map(JsonDecode.string, (function (x) {
        return Locale.T.make(x);
      }));

export {
  decode ,
}
/* decode Not a pure module */
