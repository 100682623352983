

import * as Uuid from "../../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JobEdit_API from "../api/JobEdit_API.mjs";
import * as Context_Types from "../../../../../../context/Context_Types.mjs";
import * as JobEdit_Types from "../../types/JobEdit_Types.mjs";
import * as Shared_Lib_IO from "../../../../../../shared/lib/Shared_Lib_IO.mjs";
import * as Types_Contact from "../../../../../../types/Types_Contact.mjs";
import * as JobEdit_StateTypes from "../JobEdit_StateTypes.mjs";
import * as JobEdit_PossibleAssigneesUtils from "../../utils/JobEdit_PossibleAssigneesUtils.mjs";

function setExternalId(state, value) {
  var newrecord = Caml_obj.obj_dup(state.job);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.externalId = value, newrecord),
            changedData: state.changedData
          }
        };
}

function setDescription(state, value) {
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.description = value, newrecord),
            changedData: (newrecord$1.description = JobEdit_Types.ChangedData.Change.fromOption(value), newrecord$1)
          }
        };
}

function replaceReportField(fields, field) {
  return fields.map(function (f) {
              if (Uuid.equal(f.typeUuid, field.typeUuid)) {
                return field;
              } else {
                return f;
              }
            });
}

function changeReportField(fields, field) {
  var idx = RCore.$$Array.findIndexOpt(fields, (function (rf) {
          return Uuid.equal(rf.typeUuid, field.typeUuid);
        }));
  if (idx !== undefined) {
    return fields.with(idx, field);
  } else {
    return Belt_Array.concatMany([
                fields,
                [field]
              ]);
  }
}

function updateReportField(state, field) {
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.reportFields = replaceReportField(state.job.reportFields, field), newrecord),
            changedData: (newrecord$1.reportFields = changeReportField(state.changedData.reportFields, field), newrecord$1)
          }
        };
}

function replaceReportField$1(fields, uuid, func) {
  return fields.map(function (field) {
              var files = field.value;
              var typeUuid = field.typeUuid;
              switch (files.TAG) {
                case "File" :
                    if (Uuid.equal(typeUuid, uuid)) {
                      return {
                              typeUuid: field.typeUuid,
                              name: field.name,
                              value: {
                                TAG: "File",
                                _0: func(files._0)
                              }
                            };
                    } else {
                      return field;
                    }
                case "Image" :
                    if (Uuid.equal(typeUuid, uuid)) {
                      return {
                              typeUuid: field.typeUuid,
                              name: field.name,
                              value: {
                                TAG: "Image",
                                _0: func(files._0)
                              }
                            };
                    } else {
                      return field;
                    }
                case "Signature" :
                    var match = files._0;
                    if (Uuid.equal(typeUuid, uuid)) {
                      return {
                              typeUuid: field.typeUuid,
                              name: field.name,
                              value: {
                                TAG: "Signature",
                                _0: {
                                  attachments: func(match.attachments),
                                  signedFieldTypeUuid: match.signedFieldTypeUuid
                                }
                              }
                            };
                    } else {
                      return field;
                    }
                default:
                  return field;
              }
            });
}

function updateReportFieldFiles(state, fieldUuid, updateFiles) {
  var reportFields = replaceReportField$1(state.job.reportFields, fieldUuid, updateFiles);
  var newField = RCore.$$Option.getExn(RCore.$$Array.getBy(reportFields, (function (rf) {
              return Uuid.equal(rf.typeUuid, fieldUuid);
            })), undefined);
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.reportFields = reportFields, newrecord),
            changedData: (newrecord$1.reportFields = changeReportField(state.changedData.reportFields, newField), newrecord$1)
          }
        };
}

function setReportFieldFile(state, fieldUuid, file) {
  return updateReportFieldFiles(state, fieldUuid, (function (files) {
                return Belt_Array.concatMany([
                            files,
                            [file]
                          ]);
              }));
}

function removeReportFieldFile(state, fieldUuid, fileUuid) {
  return updateReportFieldFiles(state, fieldUuid, (function (files) {
                return RCore.$$Array.keep(files, (function (file) {
                              return !Uuid.equal(file.uuid, fileUuid);
                            }));
              }));
}

function replaceCustomField(fields, field) {
  return fields.map(function (f) {
              if (Uuid.equal(f.typeUuid, field.typeUuid)) {
                return field;
              } else {
                return f;
              }
            });
}

function changeCustomFields(fields, field) {
  var idx = RCore.$$Array.findIndexOpt(fields, (function (rf) {
          return Uuid.equal(rf.typeUuid, field.typeUuid);
        }));
  if (idx !== undefined) {
    return fields.with(idx, field);
  } else {
    return Belt_Array.concatMany([
                fields,
                [field]
              ]);
  }
}

function updateCustomField(state, field) {
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.customFields = replaceCustomField(state.job.customFields, field), newrecord),
            changedData: (newrecord$1.customFields = changeCustomFields(state.changedData.customFields, field), newrecord$1)
          }
        };
}

function updateCustomFieldFiles(state, fieldUuid, updateFiles) {
  var customFields = state.job.customFields.map(function (field) {
        var files = field.value;
        if (files.TAG === "File" && Uuid.equal(field.typeUuid, fieldUuid)) {
          return {
                  typeUuid: field.typeUuid,
                  name: field.name,
                  value: {
                    TAG: "File",
                    _0: updateFiles(files._0)
                  },
                  readOnly: field.readOnly,
                  required: field.required
                };
        } else {
          return field;
        }
      });
  var newField = RCore.$$Option.getExn(RCore.$$Array.getBy(customFields, (function (rf) {
              return Uuid.equal(rf.typeUuid, fieldUuid);
            })), undefined);
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.customFields = customFields, newrecord),
            changedData: (newrecord$1.customFields = changeCustomFields(state.changedData.customFields, newField), newrecord$1)
          }
        };
}

function setCustomFieldFile(state, fieldUuid, file) {
  return updateCustomFieldFiles(state, fieldUuid, (function (files) {
                return Belt_Array.concatMany([
                            files,
                            [file]
                          ]);
              }));
}

function removeCustomFieldFile(state, fieldUuid, fileUuid) {
  return updateCustomFieldFiles(state, fieldUuid, (function (files) {
                return RCore.$$Array.keep(files, (function (file) {
                              return !Uuid.equal(file.uuid, fileUuid);
                            }));
              }));
}

function setOdometerM(state, odometerM) {
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.odometerM = odometerM, newrecord),
            changedData: (newrecord$1.odometerM = JobEdit_Types.ChangedData.Change.fromOption(odometerM), newrecord$1)
          }
        };
}

function setTypeUuid(state, typeUuid) {
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.typeUuid = typeUuid, newrecord),
            changedData: (newrecord$1.typeUuid = typeUuid, newrecord$1)
          }
        };
}

function setSkillUuids(state, uuids) {
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.skillUuids = uuids, newrecord),
            changedData: (newrecord$1.skillUuids = {
                TAG: "Data",
                _0: uuids
              }, newrecord$1)
          }
        };
}

function setPossibleAssignees(state, assignees) {
  var newrecord = Caml_obj.obj_dup(state.job);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.possibleAssignees = assignees, newrecord),
            changedData: state.changedData
          }
        };
}

function setSkills(state, uuids) {
  var territoryUuid = JobEdit_StateTypes.State.territoryUuid(state);
  var assignees = JobEdit_PossibleAssigneesUtils.sortAssigneesBySkillsAndTerritories(JobEdit_StateTypes.State.possibleAssignees(state), uuids, territoryUuid);
  return {
          TAG: "SideEffect",
          _0: (function (param) {
              var send = param.send;
              send({
                    TAG: "Job",
                    _0: {
                      TAG: "SetSkillUuids",
                      _0: uuids
                    }
                  });
              send({
                    TAG: "Job",
                    _0: {
                      TAG: "SetPossibleAssignees",
                      _0: assignees
                    }
                  });
            })
        };
}

function setScheduledAt(state, scheduledAt) {
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.scheduledAt = scheduledAt, newrecord),
            changedData: (newrecord$1.scheduledAt = JobEdit_Types.ChangedData.Change.fromOption(scheduledAt), newrecord$1)
          }
        };
}

function setScheduledDuration(state, scheduledDuration) {
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.scheduledDuration = scheduledDuration, newrecord),
            changedData: (newrecord$1.scheduledDuration = {
                TAG: "Data",
                _0: scheduledDuration
              }, newrecord$1)
          }
        };
}

function addContact(state) {
  var contacts = Belt_Array.concatMany([
        state.job.contacts,
        [Types_Contact.empty]
      ]);
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.contacts = contacts, newrecord),
            changedData: (newrecord$1.contacts = contacts, newrecord$1)
          }
        };
}

function editContact(state, index, contact) {
  var contacts = state.job.contacts.with(index, contact);
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.contacts = contacts, newrecord),
            changedData: (newrecord$1.contacts = contacts, newrecord$1)
          }
        };
}

function deleteContact(state, index) {
  if (index === 0) {
    return "NoUpdate";
  }
  var contacts = RCore.$$Array.removeOne(state.job.contacts, index);
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.contacts = contacts, newrecord),
            changedData: (newrecord$1.contacts = contacts, newrecord$1)
          }
        };
}

function setAssignees(state, assignees) {
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.assignees = assignees, newrecord),
            changedData: (newrecord$1.assignees = {
                TAG: "Data",
                _0: assignees
              }, newrecord$1)
          }
        };
}

function setClientSite(state, clientSite) {
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.clientSite = clientSite, newrecord),
            changedData: (newrecord$1.clientSite = {
                TAG: "Data",
                _0: clientSite
              }, newrecord$1)
          }
        };
}

function removeClientSite(state) {
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.addressDescription = undefined, newrecord.entranceNo = undefined, newrecord.floor = undefined, newrecord.apartment = undefined, newrecord.address = undefined, newrecord.contacts = [
                Types_Contact.empty,
                Types_Contact.empty
              ], newrecord.clientSite = undefined, newrecord.territoryUuid = undefined, newrecord),
            changedData: (newrecord$1.contacts = [
                Types_Contact.empty,
                Types_Contact.empty
              ], newrecord$1.addressDescription = "Delete", newrecord$1.entranceNo = "Delete", newrecord$1.floor = "Delete", newrecord$1.apartment = "Delete", newrecord$1.address = "Delete", newrecord$1.clientSite = "Delete", newrecord$1.territoryUuid = "Delete", newrecord$1)
          }
        };
}

function setClient(state, client) {
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.clientSite = {
                TAG: "Client",
                _0: client
              }, newrecord),
            changedData: (newrecord$1.clientSite = {
                TAG: "Data",
                _0: {
                  TAG: "Client",
                  _0: client
                }
              }, newrecord$1)
          }
        };
}

function setSite(state, site) {
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.clientSite = {
                TAG: "Site",
                _0: site
              }, newrecord),
            changedData: (newrecord$1.clientSite = {
                TAG: "Data",
                _0: {
                  TAG: "Site",
                  _0: site
                }
              }, newrecord$1)
          }
        };
}

function setTerritoryUuid(state, territoryUuid) {
  var init = state.form;
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: {
              id: init.id,
              locked: init.locked,
              saving: init.saving,
              events: init.events,
              confirmation: init.confirmation,
              confirmationLocked: init.confirmationLocked,
              showHistory: init.showHistory,
              errors: init.errors,
              autoTerritory: false
            },
            job: (newrecord.territoryUuid = territoryUuid, newrecord),
            changedData: (newrecord$1.territoryUuid = JobEdit_Types.ChangedData.Change.fromOption(territoryUuid), newrecord$1)
          }
        };
}

function setAutoTerritoryUuid(state, territoryUuid) {
  var init = state.form;
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: {
              id: init.id,
              locked: init.locked,
              saving: init.saving,
              events: init.events,
              confirmation: init.confirmation,
              confirmationLocked: init.confirmationLocked,
              showHistory: init.showHistory,
              errors: init.errors,
              autoTerritory: true
            },
            job: (newrecord.territoryUuid = territoryUuid, newrecord),
            changedData: (newrecord$1.territoryUuid = JobEdit_Types.ChangedData.Change.fromOption(territoryUuid), newrecord$1)
          }
        };
}

function setAddress(state, address, wire) {
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  var state_form = state.form;
  var state_job = (newrecord.address = address, newrecord);
  var state_changedData = (newrecord$1.address = {
      TAG: "Data",
      _0: address
    }, newrecord$1);
  var state$1 = {
    form: state_form,
    job: state_job,
    changedData: state_changedData
  };
  var match = address.geolocation;
  var match$1 = state_job.territoryUuid;
  var match$2 = state_form.autoTerritory;
  var match$3 = Context_Types.Features.hasFlag("territories", wire.ctx.features);
  var geolocation;
  if (match === undefined) {
    return {
            TAG: "Update",
            _0: state$1
          };
  }
  if (match$1 !== undefined) {
    if (!match$2) {
      return {
              TAG: "Update",
              _0: state$1
            };
    }
    if (!match$3) {
      return {
              TAG: "Update",
              _0: state$1
            };
    }
    geolocation = match;
  } else {
    if (!match$3) {
      return {
              TAG: "Update",
              _0: state$1
            };
    }
    geolocation = match;
  }
  return {
          TAG: "UpdateWithIO",
          _0: state$1,
          _1: Shared_Lib_IO.bimap(JobEdit_API.JobTerritory.$$fetch(geolocation, wire), (function (r) {
                  if (r !== undefined && r.TAG === "Ok") {
                    return {
                            TAG: "Job",
                            _0: {
                              TAG: "SetAutoTerritoryUuid",
                              _0: r._0
                            }
                          };
                  } else {
                    return {
                            TAG: "Job",
                            _0: "NoOp"
                          };
                  }
                }), (function (param) {
                  return {
                          TAG: "Job",
                          _0: "NoOp"
                        };
                }))
        };
}

function setApartment(state, apartment) {
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.apartment = apartment, newrecord),
            changedData: (newrecord$1.apartment = JobEdit_Types.ChangedData.Change.fromOption(apartment), newrecord$1)
          }
        };
}

function setEntranceNo(state, entranceNo) {
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.entranceNo = entranceNo, newrecord),
            changedData: (newrecord$1.entranceNo = JobEdit_Types.ChangedData.Change.fromOption(entranceNo), newrecord$1)
          }
        };
}

function setAddressDescription(state, addressDescription) {
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.addressDescription = addressDescription, newrecord),
            changedData: (newrecord$1.addressDescription = JobEdit_Types.ChangedData.Change.fromOption(addressDescription), newrecord$1)
          }
        };
}

function setFloor(state, floor) {
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.floor = floor, newrecord),
            changedData: (newrecord$1.floor = JobEdit_Types.ChangedData.Change.fromOption(floor), newrecord$1)
          }
        };
}

function setOrderedServices(state, orderedServices) {
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.orderedServices = orderedServices, newrecord),
            changedData: (newrecord$1.orderedServices = orderedServices, newrecord$1)
          }
        };
}

function setProvidedServices(state, providedServices) {
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          TAG: "Update",
          _0: {
            form: state.form,
            job: (newrecord.providedServices = providedServices, newrecord),
            changedData: (newrecord$1.providedServices = providedServices, newrecord$1)
          }
        };
}

function fetchedClientState(state, data, clientSite) {
  var newrecord = Caml_obj.obj_dup(state.job);
  var newrecord$1 = Caml_obj.obj_dup(state.changedData);
  return {
          form: state.form,
          job: (newrecord.addressDescription = data.addressDescription, newrecord.entranceNo = data.entranceNo, newrecord.floor = data.floor, newrecord.apartment = data.apartment, newrecord.address = data.address, newrecord.contacts = data.contacts, newrecord.clientSite = clientSite, newrecord.territoryUuid = data.territoryUuid, newrecord),
          changedData: (newrecord$1.contacts = data.contacts, newrecord$1.addressDescription = JobEdit_Types.ChangedData.Change.fromOption(data.addressDescription), newrecord$1.entranceNo = JobEdit_Types.ChangedData.Change.fromOption(data.entranceNo), newrecord$1.floor = JobEdit_Types.ChangedData.Change.fromOption(data.floor), newrecord$1.apartment = JobEdit_Types.ChangedData.Change.fromOption(data.apartment), newrecord$1.address = JobEdit_Types.ChangedData.Change.fromOption(data.address), newrecord$1.clientSite = JobEdit_Types.ChangedData.Change.fromOption(clientSite), newrecord$1.territoryUuid = JobEdit_Types.ChangedData.Change.fromOption(data.territoryUuid), newrecord$1)
        };
}

function setFoundClient(state, data, wire) {
  if (data === undefined) {
    return removeClientSite(state);
  }
  var address = data.address;
  var value = data.value;
  var clientSite;
  switch (value.TAG) {
    case "Organization" :
        clientSite = {
          TAG: "Client",
          _0: {
            TAG: "Organization",
            _0: {
              TAG: "Edit",
              _0: value._0
            }
          }
        };
        break;
    case "Individual" :
        clientSite = {
          TAG: "Client",
          _0: {
            TAG: "Individual",
            _0: {
              TAG: "Edit",
              _0: value._0
            }
          }
        };
        break;
    case "Site" :
        clientSite = {
          TAG: "Site",
          _0: {
            TAG: "Edit",
            _0: value._0
          }
        };
        break;
    
  }
  var state$1 = fetchedClientState(state, data, clientSite);
  var match = Context_Types.Features.hasFlag("territories", wire.ctx.features);
  if (address === undefined) {
    return {
            TAG: "Update",
            _0: state$1
          };
  }
  var geo = address.geolocation;
  if (geo !== undefined && !(data.territoryUuid !== undefined || !match)) {
    return {
            TAG: "UpdateWithIO",
            _0: state$1,
            _1: Shared_Lib_IO.bimap(JobEdit_API.JobTerritory.$$fetch(geo, wire), (function (r) {
                    if (r !== undefined && r.TAG === "Ok") {
                      return {
                              TAG: "Job",
                              _0: {
                                TAG: "SetAutoTerritoryUuid",
                                _0: r._0
                              }
                            };
                    } else {
                      return {
                              TAG: "Job",
                              _0: "NoOp"
                            };
                    }
                  }), (function (param) {
                    return {
                            TAG: "Job",
                            _0: "NoOp"
                          };
                  }))
          };
  } else {
    return {
            TAG: "Update",
            _0: state$1
          };
  }
}

function setFoundSiteClient(state, data) {
  var state$1;
  if (data !== undefined) {
    var value = data.value;
    var org;
    switch (value.TAG) {
      case "Organization" :
          org = value._0;
          break;
      case "Individual" :
      case "Site" :
          org = undefined;
          break;
      
    }
    var match = state.job.clientSite;
    var clientSite;
    if (match !== undefined && match.TAG !== "Client") {
      var site = match._0;
      if (site.TAG === "Edit") {
        var site$1 = site._0;
        clientSite = {
          TAG: "Site",
          _0: {
            TAG: "Edit",
            _0: {
              uuid: site$1.uuid,
              name: site$1.name,
              description: site$1.description,
              client: org
            }
          }
        };
      } else {
        var site$2 = site._0;
        clientSite = {
          TAG: "Site",
          _0: {
            TAG: "New",
            _0: {
              name: site$2.name,
              description: site$2.description,
              client: org
            }
          }
        };
      }
    } else {
      clientSite = state.job.clientSite;
    }
    state$1 = fetchedClientState(state, data, clientSite);
  } else {
    var match$1 = state.job.clientSite;
    var clientSite$1;
    if (match$1 !== undefined && match$1.TAG !== "Client") {
      var site$3 = match$1._0;
      if (site$3.TAG === "Edit") {
        var site$4 = site$3._0;
        clientSite$1 = {
          TAG: "Site",
          _0: {
            TAG: "Edit",
            _0: {
              uuid: site$4.uuid,
              name: site$4.name,
              description: site$4.description,
              client: undefined
            }
          }
        };
      } else {
        var site$5 = site$3._0;
        clientSite$1 = {
          TAG: "Site",
          _0: {
            TAG: "New",
            _0: {
              name: site$5.name,
              description: site$5.description,
              client: undefined
            }
          }
        };
      }
    } else {
      clientSite$1 = state.job.clientSite;
    }
    var newrecord = Caml_obj.obj_dup(state.job);
    state$1 = {
      form: state.form,
      job: (newrecord.clientSite = clientSite$1, newrecord),
      changedData: state.changedData
    };
  }
  return {
          TAG: "Update",
          _0: state$1
        };
}

export {
  setExternalId ,
  setDescription ,
  changeReportField ,
  updateReportField ,
  replaceReportField$1 as replaceReportField,
  updateReportFieldFiles ,
  setReportFieldFile ,
  removeReportFieldFile ,
  replaceCustomField ,
  changeCustomFields ,
  updateCustomField ,
  updateCustomFieldFiles ,
  setCustomFieldFile ,
  removeCustomFieldFile ,
  setOdometerM ,
  setTypeUuid ,
  setSkillUuids ,
  setPossibleAssignees ,
  setSkills ,
  setScheduledAt ,
  setScheduledDuration ,
  addContact ,
  editContact ,
  deleteContact ,
  setAssignees ,
  setClientSite ,
  removeClientSite ,
  setClient ,
  setSite ,
  setTerritoryUuid ,
  setAutoTerritoryUuid ,
  setAddress ,
  setApartment ,
  setEntranceNo ,
  setAddressDescription ,
  setFloor ,
  setOrderedServices ,
  setProvidedServices ,
  fetchedClientState ,
  setFoundClient ,
  setFoundSiteClient ,
}
/* Uuid Not a pure module */
