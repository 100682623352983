

import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Units from "../../../../../../types/Units.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DayTimeline_Types from "../types/DayTimeline_Types.mjs";
import * as Schedule_Types_Job from "../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as DayTimeline_Constants from "../DayTimeline_Constants.mjs";
import * as DayTimeline_JobPositionsHelper from "./DayTimeline_JobPositionsHelper.mjs";

function findGap(_positionOpt, _indexOpt, jobPosition) {
  while(true) {
    var positionOpt = _positionOpt;
    var indexOpt = _indexOpt;
    var position = positionOpt !== undefined ? Caml_option.valFromOption(positionOpt) : Units.Px.zero;
    var index = indexOpt !== undefined ? indexOpt : 0;
    var minHeight = Units.Px.ceil(DayTimeline_JobPositionsHelper.minHeight(DayTimeline_Constants.rowHeight));
    var rendered = RCore.$$Array.keep(jobPosition.children.contents, (function (j) {
            if (j.rendered.contents) {
              return !j.hidden.contents;
            } else {
              return false;
            }
          }));
    var found = rendered.every((function(position){
        return function (p) {
          return Caml_obj.notequal(position, p.top.contents);
        }
        }(position)));
    if (Caml_obj.greaterthan(position, Units.Px.multiplyWithInt(minHeight, DayTimeline_Constants.numberOfVisibleJobs - 1 | 0))) {
      return ;
    }
    if (found) {
      return Caml_option.some(position);
    }
    var j = rendered[index];
    if (j === undefined) {
      return ;
    }
    var position$1 = Units.Px.plus(j.top.contents, minHeight);
    var index$1 = index + 1 | 0;
    _indexOpt = index$1;
    _positionOpt = Caml_option.some(position$1);
    continue ;
  };
}

function setPosition(job) {
  if (job.rendered.contents) {
    return ;
  }
  if (Caml_obj.equal(job.children.contents, [])) {
    job.rendered.contents = true;
    job.height.contents = DayTimeline_Constants.rowHeight;
    job.top.contents = Units.Px.zero;
    return ;
  }
  var minHeight = DayTimeline_JobPositionsHelper.minHeight(DayTimeline_Constants.rowHeight);
  job.height.contents = minHeight;
  job.rendered.contents = true;
  var gap = findGap(undefined, undefined, job);
  if (gap !== undefined) {
    job.top.contents = Caml_option.valFromOption(gap);
  } else {
    job.top.contents = Units.Px.multiplyWithInt(Units.Px.ceil(minHeight), DayTimeline_Constants.numberOfVisibleJobs);
    job.hidden.contents = true;
  }
}

function updatePosition(job) {
  var sizeOfJobs = job.children.contents.length;
  if (!(sizeOfJobs === 1 || sizeOfJobs > 0 && job.children.contents.every(function (j) {
            return j.children.contents.length === 1;
          }))) {
    if (sizeOfJobs === 0) {
      job.size.contents = "Full";
    } else {
      job.size.contents = "OneThird";
    }
    return ;
  }
  var halfOfRowHeight = Units.Px.divideByInt(DayTimeline_Constants.rowHeight, 2);
  job.height.contents = halfOfRowHeight;
  job.top.contents = Units.Px.eq(job.top.contents, Units.Px.zero) ? Units.Px.zero : halfOfRowHeight;
  job.size.contents = "Half";
}

function make(wire, businessHours, currentDay, sizeOfMinute, jobs) {
  var filtered = RCore.$$Array.keep(jobs, (function (job) {
          return DayTimeline_JobPositionsHelper.inBusinessHours(businessHours, currentDay, Schedule_Types_Job.AssignedJob.startAt(job), Schedule_Types_Job.AssignedJob.finishAt(job));
        }));
  var positions = DayTimeline_JobPositionsHelper.sortByWidth(filtered.map(function (job, index) {
            return DayTimeline_JobPositionsHelper.makePosition(wire, businessHours, currentDay, sizeOfMinute, Schedule_Types_Job.AssignedJob.startAt(job), Schedule_Types_Job.AssignedJob.finishAt(job), index);
          }));
  var tree = DayTimeline_JobPositionsHelper.IntervalTree.make(positions);
  positions.forEach(function (position) {
        position.children.contents = RCore.$$Array.keep(DayTimeline_JobPositionsHelper.IntervalTree.rangeSearch(undefined, tree, DayTimeline_Types.Position.start(position) + 1 | 0, DayTimeline_Types.Position.finish(position) - 1 | 0), (function (p) {
                return p.index !== position.index;
              }));
      });
  positions.forEach(setPosition);
  positions.forEach(updatePosition);
  return DayTimeline_JobPositionsHelper.sortByPosition(positions).map(function (p) {
              return [
                      p,
                      filtered[p.index]
                    ];
            });
}

export {
  make ,
}
/* DayTimeline_Types Not a pure module */
