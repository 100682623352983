

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Catalog_Types from "./Catalog_Types.mjs";

function parentSelectValue(categoryUuid, categories) {
  return Js_null.fromOption(RCore.$$Option.map(RCore.$$Option.flatMap(categoryUuid, (function (uuid) {
                        return RCore.$$Array.getBy(categories, (function (c) {
                                      return Uuid.equal(c.uuid, uuid);
                                    }));
                      })), (function (c) {
                    return Catalog_Types.uuidToId(c.uuid);
                  })));
}

function unitSelectValue(unitUuid, units) {
  return Js_null.fromOption(RCore.$$Option.map(RCore.$$Option.flatMap(unitUuid, (function (uuid) {
                        return RCore.$$Array.getBy(units, (function (u) {
                                      return Uuid.equal(u.uuid, uuid);
                                    }));
                      })), (function (u) {
                    return Catalog_Types.uuidToId(u.uuid);
                  })));
}

function rednerParent(categoryUuid, categories) {
  if (categoryUuid === undefined) {
    return "—";
  }
  var uuid = Caml_option.valFromOption(categoryUuid);
  return RCore.$$Option.mapWithDefault(RCore.$$Array.getBy(categories, (function (c) {
                    return Uuid.equal(c.uuid, uuid);
                  })), "–", (function (c) {
                return c.name;
              }));
}

function handleResponse(entities, response, equal) {
  return function (succCb, errorCb) {
    if (response.TAG === "Ok") {
      var entity = response._0;
      succCb();
      var match = RCore.$$Array.getBy(entities, (function (s) {
              return equal(s, entity);
            }));
      if (match !== undefined) {
        return entities.map(function (e) {
                    if (equal(e, entity)) {
                      return entity;
                    } else {
                      return e;
                    }
                  });
      } else {
        return Belt_Array.concatMany([
                    [entity],
                    entities
                  ]);
      }
    }
    var errors = response._0;
    if (typeof errors !== "object") {
      console.log(errors);
      return entities;
    }
    if (errors.TAG === "ValidationError") {
      var errors$1 = errors._0;
      errorCb(errors$1);
      console.log(errors$1);
      return entities;
    }
    console.log(errors);
    return entities;
  };
}

function handleServiceResponse(services, response) {
  return handleResponse(services, response, (function (s1, s2) {
                return Uuid.equal(s1.uuid, s2.uuid);
              }));
}

function handleProductsResponse(products, response) {
  return handleResponse(products, response, (function (p1, p2) {
                return Uuid.equal(p1.uuid, p2.uuid);
              }));
}

function handleCategoriesResponse(categories, response) {
  return handleResponse(categories, response, (function (c1, c2) {
                return Uuid.equal(c1.uuid, c2.uuid);
              }));
}

function handleUnitResponse(units, response) {
  return handleResponse(units, response, (function (u1, u2) {
                return Uuid.equal(u1.uuid, u2.uuid);
              }));
}

export {
  parentSelectValue ,
  unitSelectValue ,
  rednerParent ,
  handleResponse ,
  handleServiceResponse ,
  handleProductsResponse ,
  handleCategoriesResponse ,
  handleUnitResponse ,
}
/* Uuid Not a pure module */
