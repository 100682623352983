import { createStore } from 'planado/stores/utils'
import reducer from 'planado/reducers/admin/recurring_jobs_show'
import { showTime, nowRound, updateDay } from 'planado/utils/time/index.js'

const INITIAL_ERRORS = {
  name: [],
  nextScheduledAtDate: [],
  stopRepeatingAtDate: []
}

const nextScheduledAt = (dateTime, ctx) => {
  if (dateTime) {
    return {
      date: showTime(dateTime, 'date', ctx),
      time: showTime(dateTime, 'time', ctx)
    }
  } else {
    const now = nowRound({ ctx, step: 10 })
    return {
      date: showTime(updateDay({ dateTime: now, bias: 1, ctx }), 'date', ctx),
      time: showTime(now, 'time', ctx)
    }
  }
}

const stopRepeatingAt = (dateTime, ctx) => {
  if (dateTime) {
    return {
      date: showTime(dateTime, 'date', ctx),
      time: showTime(dateTime, 'time', ctx)
    }
  } else {
    const now = nowRound({ ctx, step: 10, bias: 30 })
    return {
      date: null,
      time: showTime(now, 'time', ctx)
    }
  }
}

export const presentTime = (firstDate, stopDate, ctx) => ({
  nextScheduledAt: nextScheduledAt(firstDate, ctx),
  stopRepeatingAt: stopRepeatingAt(stopDate, ctx)
})

export default ({ errors, recurringJob: recurringJob, ...props }, ctx) =>
  createStore(reducer, {
    ...props,
    recurringJob: {
      ...recurringJob,
      nextScheduledAt: nextScheduledAt(recurringJob.nextScheduledAt, ctx),
      stopRepeatingAt: stopRepeatingAt(recurringJob.stopRepeatingAt, ctx)
    },
    popover: { targetid: null },
    errors: errors === null ? INITIAL_ERRORS : errors,
    foremanError: false
  })
