

import * as RCore from "../libraries/RCore.mjs";
import * as JsonDecode from "../libraries/JsonDecode.mjs";
import * as Core__Float from "@rescript/core/lib/es6/src/Core__Float.mjs";
import * as StringGeolocationParser from "./StringGeolocationParser.mjs";

function toNumber(value) {
  return value;
}

function fromNumber(n) {
  return n;
}

function fromString(str) {
  return RCore.$$Option.map(Core__Float.fromString(str), fromNumber);
}

function equalOrLessThan(a, b) {
  return a <= b;
}

var decode = JsonDecode.map(JsonDecode.$$float, fromNumber);

function add(a, b) {
  return a + b;
}

function sub(a, b) {
  return a - b;
}

function mul(a, b) {
  return a * b;
}

function toNumber$1(value) {
  return value;
}

function fromNumber$1(n) {
  return n;
}

function fromString$1(str) {
  return RCore.$$Option.map(Core__Float.fromString(str), fromNumber$1);
}

function equalOrLessThan$1(a, b) {
  return a <= b;
}

var decode$1 = JsonDecode.map(JsonDecode.$$float, fromNumber$1);

function add$1(a, b) {
  return a + b;
}

function sub$1(a, b) {
  return a - b;
}

function mul$1(a, b) {
  return a * b;
}

function toArray(param) {
  return [
          param.latitude,
          param.longitude
        ];
}

function fromArray(geolocation) {
  if (geolocation.length !== 2) {
    return ;
  }
  var latitude = geolocation[0];
  var longitude = geolocation[1];
  return {
          longitude: longitude,
          latitude: latitude
        };
}

function fromString$2(input) {
  var match = StringGeolocationParser.parse(input);
  if (match !== undefined) {
    return {
            longitude: match[1],
            latitude: match[0]
          };
  }
  
}

function add$2(param, param$1) {
  return {
          longitude: param.longitude + param$1.longitude,
          latitude: param.latitude + param$1.latitude
        };
}

function sub$2(param, param$1) {
  return {
          longitude: param$1.longitude - param.longitude,
          latitude: param$1.latitude - param.latitude
        };
}

function mul$2(param, num) {
  return {
          longitude: param.longitude * num,
          latitude: param.latitude * num
        };
}

function fromStringStrict(str) {
  if (StringGeolocationParser.isGeolocationString(str)) {
    return fromString$2(str);
  }
  
}

function vectorLength(start, finish) {
  return Math.sqrt(Math.pow(finish.latitude - start.latitude, 2.0) + Math.pow(finish.longitude - start.longitude, 2.0));
}

function scaleToLength(start, finish, targetLength) {
  return mul$2(sub$2(finish, start), targetLength / vectorLength(start, finish));
}

function make(latitude, longitude) {
  return {
          longitude: longitude,
          latitude: latitude
        };
}

var decode$2 = JsonDecode.object(function (field) {
      return {
              longitude: field.required("longitude", decode),
              latitude: field.required("latitude", decode$1)
            };
    });

var $$Geolocation = {
  toArray: toArray,
  fromArray: fromArray,
  fromString: fromString$2,
  add: add$2,
  sub: sub$2,
  mul: mul$2,
  fromStringStrict: fromStringStrict,
  vectorLength: vectorLength,
  scaleToLength: scaleToLength,
  make: make,
  decode: decode$2
};

function withinBoundingBox(param, param$1) {
  var northEast = param$1.northEast;
  var southWest = param$1.southWest;
  var latitude = param.latitude;
  var longitude = param.longitude;
  if (longitude <= northEast.longitude && southWest.longitude <= longitude && latitude <= northEast.latitude) {
    return southWest.latitude <= latitude;
  } else {
    return false;
  }
}

var BoundingBox = {
  withinBoundingBox: withinBoundingBox
};

var InitialRegion = {};

function Longitude_toString(prim) {
  return prim.toString();
}

function Longitude_fromJson(prim) {
  return prim;
}

var Longitude = {
  toString: Longitude_toString,
  toNumber: toNumber,
  fromJson: Longitude_fromJson,
  fromNumber: fromNumber,
  fromString: fromString,
  equalOrLessThan: equalOrLessThan,
  add: add,
  sub: sub,
  mul: mul
};

function Latitude_toString(prim) {
  return prim.toString();
}

function Latitude_fromJson(prim) {
  return prim;
}

var Latitude = {
  toString: Latitude_toString,
  toNumber: toNumber$1,
  fromJson: Latitude_fromJson,
  fromNumber: fromNumber$1,
  fromString: fromString$1,
  equalOrLessThan: equalOrLessThan$1,
  add: add$1,
  sub: sub$1,
  mul: mul$1
};

export {
  Longitude ,
  Latitude ,
  $$Geolocation ,
  BoundingBox ,
  InitialRegion ,
}
/* decode Not a pure module */
