

import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../common/Optional.mjs";
import * as UI_Button from "../../ui/UI_Button.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TeamModuleCss from "/home/runner/work/planado/planado/client/rescript/components/team/Team.module.css";

var delete$p = Utils.Translations.tr("js.teams.buttons.delete");

var deletePrompt$p = Utils.Translations.tr("js.teams.show.confirm_delete.prompt");

var deleteConfirm$p = Utils.Translations.tr("js.teams.show.confirm_delete.delete");

var cancel$p = Utils.Translations.tr("common.cancel");

var submitEdit$p = Utils.Translations.tr("js.teams.buttons.submit_edit");

var submitNew$p = Utils.Translations.tr("js.teams.buttons.submit_new");

var styles = TeamModuleCss;

function TeamEditFooter(Props) {
  var showDestroyConfirmation = Props.showDestroyConfirmation;
  var confirmDestroy = Props.confirmDestroy;
  var destroyTeam = Props.destroyTeam;
  var toggleDestroyFooter = Props.toggleDestroyFooter;
  var close = Props.close;
  var ctx = Props.ctx;
  var team = Props.team;
  var locked = Props.locked;
  var match = ctx.permissions;
  var hasPermissions = match !== undefined ? match.teams === "ViewEdit" : false;
  var uuid = team.uuid;
  return React.createElement(Optional.make, {
              show: hasPermissions,
              children: uuid !== undefined ? React.createElement("div", {
                      className: styles.footer
                    }, React.createElement("div", {
                          style: {
                            objectPosition: "relative"
                          }
                        }, React.createElement(UI_Button.make, {
                              flavor: "danger",
                              onClick: confirmDestroy,
                              children: delete$p(ctx)
                            }), React.createElement(Optional.make, {
                              show: showDestroyConfirmation,
                              children: React.createElement("div", {
                                    className: styles.deletePopoverWrapper
                                  }, React.createElement("div", {
                                        className: "p-popover-team team-delete-prompt"
                                      }, React.createElement("h5", undefined, deletePrompt$p(ctx)), React.createElement("div", {
                                            className: styles.buttonGroup
                                          }, React.createElement(UI_Button.make, {
                                                flavor: "outline",
                                                onClick: toggleDestroyFooter,
                                                children: cancel$p(ctx)
                                              }), React.createElement(UI_Button.make, {
                                                flavor: "danger",
                                                onClick: destroyTeam(Caml_option.valFromOption(uuid)),
                                                children: deleteConfirm$p(ctx)
                                              }))))
                            })), React.createElement("div", {
                          className: styles.buttonGroup
                        }, React.createElement(UI_Button.make, {
                              flavor: "outline",
                              onClick: close,
                              children: cancel$p(ctx)
                            }), React.createElement(UI_Button.make, {
                              purpose: "primary",
                              disabled: locked,
                              children: submitEdit$p(ctx)
                            }))) : React.createElement("div", {
                      className: styles.footerCreate
                    }, React.createElement("div", {
                          className: styles.buttonGroup
                        }, React.createElement(UI_Button.make, {
                              flavor: "outline",
                              onClick: close,
                              children: cancel$p(ctx)
                            }), React.createElement(UI_Button.make, {
                              purpose: "primary",
                              disabled: locked,
                              children: submitNew$p(ctx)
                            })))
            });
}

var make = TeamEditFooter;

export {
  make ,
}
/* delete' Not a pure module */
