

import * as Tour from "../libraries/tours/Tour.mjs";
import * as React from "react";
import * as Websocket from "../libraries/Websocket.mjs";
import * as Context_Auth from "../context/Context_Auth.mjs";

function Pages_Authenticated(Props) {
  var user = Props.user;
  var wire = Props.wire;
  var children = Props.children;
  var tour = Tour.use(wire);
  React.useEffect((function () {
          var match = wire.route;
          var match$1 = match.path;
          if (typeof match$1 === "object" && match$1.NAME === "Schedule") {
            var match$2 = match$1.VAL;
            if (typeof match$2 === "object" && match$2.NAME === "Timeline" && match$2.VAL === "Day" && !match.modals) {
              tour.trigger("start");
            }
            
          }
          
        }), []);
  var socket = React.useMemo((function () {
          var match = wire.websocketParams;
          if (match !== null) {
            return Websocket.make(25000.0, match.enabled, match.token, match.host);
          } else {
            return {
                    TAG: "Null",
                    _0: Websocket.Null.make()
                  };
          }
        }), []);
  var value = {
    user: user,
    socket: socket
  };
  return React.createElement(Context_Auth.make, {
              value: value,
              children: children
            });
}

var make = Pages_Authenticated;

export {
  make ,
}
/* Tour Not a pure module */
