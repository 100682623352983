import { sendJSONRequest } from 'planado/utils/network.js'
import { resolutionsPath } from 'planado/routes.js'
import { decamelize } from 'planado/utils/index.js'

export const RESOLUTION_SET_FIELD = 'RESOLUTION_SET_FIELD'
export const RESOLUTION_ADD = 'RESOLUTION_ADD'
export const RESOLUTION_ADD_CANCEL = 'RESOLUTION_ADD_CANCEL'
export const RESOLUTION_DELETE = 'RESOLUTION_DELETE'
export const RESOLUTION_RESTORE = 'RESOLUTION_RESTORE'
export const RESOLUTION_EDIT = 'RESOLUTION_EDIT'
export const SEND_FORM_REQUEST = 'SEND_FORM_REQUEST'
export const SEND_FORM_SUCCESS = 'SEND_FORM_SUCCESS'
export const SEND_FORM_FAIL = 'SEND_FORM_FAIL'

export const setResolution = (resolutionId, name, value) => ({
  type: RESOLUTION_SET_FIELD,
  resolutionId,
  name,
  value
})

export const deleteResolution = resolutionId => ({
  type: RESOLUTION_DELETE,
  resolutionId
})

export const restoreResolution = resolutionId => ({
  type: RESOLUTION_RESTORE,
  resolutionId
})

export const addResolution = () => ({
  type: RESOLUTION_ADD
})

export const cancelAddResolution = resolutionId => ({
  type: RESOLUTION_ADD_CANCEL,
  resolutionId
})

export const editResolution = resolutionId => ({
  type: RESOLUTION_EDIT,
  resolutionId
})

export const sendForm = (ctx, onSuccess, onFailure) =>
  async function(dispatch, getState) {
    dispatch({ type: SEND_FORM_REQUEST })

    const { resolutions } = getState()

    const body = decamelize({
      resolutions: resolutions.map(
        ({
          resolutionId,
          name,
          isCommentRequired,
          isSuccessful,
          hasComment,
          destroy
        }) => {
          const r = {
            name,
            successful: isSuccessful,
            hasComment,
            commentRequired: isCommentRequired,
            destroy
          }

          if (resolutionId > 0) {
            return { ...r, resolutionId }
          } else {
            return { ...r, resolutionId: null }
          }
        }
      )
    })

    try {
      const { resolutions: updatedResolutions } = await sendJSONRequest(
        resolutionsPath,
        ctx,
        {
          method: 'PATCH',
          body
        }
      )

      dispatch({ type: SEND_FORM_SUCCESS, resolutions: updatedResolutions })

      onSuccess()
    } catch (response) {
      const { errors } = await response.json()

      dispatch({ type: SEND_FORM_FAIL, errors })

      onFailure()
    }
  }
