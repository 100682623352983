

import * as React from "react";
import * as Router from "../../Router.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as Pages_Activation_API from "./Pages_Activation_API.mjs";
import * as Pages_Activation_Form from "./Pages_Activation_Form.mjs";

function Pages_Activation(Props) {
  var wire = Props.wire;
  var token = Props.token;
  var match = React.useState(function () {
        
      });
  var setInitialState = match[1];
  var initialState = match[0];
  React.useEffect((function () {
          Backend.$$finally(Pages_Activation_API.Get.show(wire, token), (function (r) {
                  if (r.TAG === "Ok") {
                    var r$1 = r._0;
                    return setInitialState(function (param) {
                                return r$1;
                              });
                  }
                  var match = r._0;
                  if (typeof match !== "object") {
                    return ;
                  }
                  if (match.TAG !== "HTTPError") {
                    return ;
                  }
                  var tmp = match._0[0];
                  if (typeof tmp !== "object" && tmp === "NotFound") {
                    return Router.redirectToLogin();
                  }
                  
                }));
        }), []);
  if (initialState !== undefined) {
    return React.createElement(Pages_Activation_Form.make, {
                initialState: initialState,
                token: token
              });
  } else {
    return null;
  }
}

var make = Pages_Activation;

export {
  make ,
}
/* react Not a pure module */
