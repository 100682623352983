import styleNames from 'classnames'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import {
  isValidTime,
  secondsToTime,
  timeToSeconds,
} from 'planado/utils/time/index.js'
import React, { PureComponent } from 'react'
import { footer } from 'rscrpt/types/Wire.mjs'
import { make as Panel } from 'rscrpt/components/common/Panel/Panel.mjs'
import { v6 as uuidv6 } from 'uuid-with-v6'
import * as styles from './styles.module.css'

const Popup = React.forwardRef(
  (
    {
      ctx,
      inputId,
      onWheel,
      startHoldTimer,
      onMouseUp,
      onMouseOut,
      hours,
      minutes,
      placement,
    },
    ref
  ) => {
    const wrapperClasses = styleNames({
      'test-picker-time-popup': true,
      [styles.timepicker]: true,
    })

    return (
      <Panel footer={footer(ctx.wire)} position={placement} domId={inputId}>
        <div className={wrapperClasses} ref={ref}>
          <div className={styles.col} onWheel={(evt) => onWheel(evt, 'hour')}>
            <div className={styles.cell}>
              <button
                className={`${styles.button} ${styles.buttonUp}`}
                onMouseDown={() => startHoldTimer('inc', 'hour')}
                onMouseUp={() => onMouseUp('inc', 'hour')}
                onMouseOut={() => onMouseOut()}
                type="button"
              />
            </div>

            <div className={styles.cell}>{hours}</div>

            <div className={styles.cell}>
              <button
                className={`${styles.button} ${styles.buttonDown}`}
                onMouseDown={() => startHoldTimer('dec', 'hour')}
                onMouseUp={() => onMouseUp('dec', 'hour')}
                onMouseOut={() => onMouseOut()}
                type="button"
              />
            </div>
          </div>

          <div className={styles.separator}>:</div>

          <div className={styles.col} onWheel={(evt) => onWheel(evt, 'minute')}>
            <div className={styles.cell}>
              <button
                className={`${styles.button} ${styles.buttonUp}`}
                onMouseDown={() => startHoldTimer('inc', 'minute')}
                onMouseUp={() => onMouseUp('inc', 'minute')}
                onMouseOut={() => onMouseOut()}
                type="button"
              />
            </div>

            <div className={styles.cell}>
              {minutes < 10 ? `0${minutes}` : minutes}
            </div>

            <div className={styles.cell}>
              <button
                className={`${styles.button} ${styles.buttonDown}`}
                onMouseDown={() => startHoldTimer('dec', 'minute')}
                onMouseUp={() => onMouseUp('dec', 'minute')}
                onMouseOut={() => onMouseOut()}
                type="button"
              />
            </div>
          </div>
        </div>
      </Panel>
    )
  }
)

class TimePicker extends PureComponent {
  static defaultProps = {
    disabled: false,
    minSeconds: 0,
    maxSeconds: 86100,
    secondsStep: 300,
    errors: [],
    outlineIcons: false,
    popupPlacement: 'bottom',
    wrapperClass: '',
    nullable: false
  }

  popupRef = React.createRef()
  inputId = uuidv6()
  picker = null
  spinTimer = null
  holdTimer = null

  state = {
    focused: false,
    time: this.props.time,
    prevPropsTime: this.props.time,
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.time !== state.prevPropsTime ||
      (!state.focused && props.time !== state.time)
    ) {
      return {
        ...state,
        prevPropsTime: props.time,
        time: props.time,
      }
    } else {
      return null
    }
  }

  get seconds() {
    const { time, ctx } = this.props
    return timeToSeconds(time, ctx)
  }

  get minutes() {
    return (this.seconds % 3600) / 60
  }

  get hours() {
    return Math.floor(this.seconds / 3600)
  }

  onFocus = (_event) => {
    if (this.props.disabled) {
      return null
    }

    this.setState(
      () => ({ focused: true }),
      () => {
        const {
          ctx: { dom },
        } = this.props

        dom.onScroll(this.scrollHandler)
        dom.addEventListener('click', this.clickHandler, true)
      }
    )
  }

  onBlur = (_event) => {
    const { onChange, time: oldTime, ctx, nullable } = this.props
    const { time: newTime } = this.state

    if (nullable && newTime === '') {
      const cont = onChange(newTime)

      if (cont !== false) {
        this.setState(() => ({ time: newTime }))
      }
    } else if (oldTime !== newTime && typeof newTime === 'string') {
      const time = isValidTime({ date: newTime, ctx, format: 'time' })
        ? newTime
        : '00:00'

      const cont = onChange(time)

      if (cont !== false) {
        this.setState(() => ({ time }))
      }
    }
  }

  scrollHandler = (_event) => {
    this.setState(
      () => ({ focused: false }),
      () => {
        const {
          ctx: { dom },
        } = this.props

        dom.removeEventListener('click', this.clickHandler, false)
        dom.removeScrollHandler(this.scrollHandler)
      }
    )
  }

  clickHandler = (event) => {
    const target = event.target
    if (
      this.picker &&
      target instanceof Node &&
      !this.picker.contains(target) &&
      this.popupRef &&
      this.popupRef.current &&
      !this.popupRef.current.contains(target)
    ) {
      this.setState(
        () => ({ focused: false }),
        () => {
          const {
            ctx: { dom },
          } = this.props
          dom.removeEventListener('click', this.clickHandler, false)
        }
      )
    }
  }

  onInputChange = (event) => {
    const target = event.target
    if (target instanceof HTMLInputElement) {
      this.setState(() => ({ time: target.value }))
    }
  }

  onChange = (seconds) => {
    const { minSeconds, maxSeconds, onChange, ctx } = this.props

    let time
    if (seconds >= minSeconds && seconds <= maxSeconds) {
      time = secondsToTime(seconds, ctx)
    } else if (seconds > maxSeconds) {
      time = secondsToTime(seconds % 3600, ctx)
    } else {
      time = secondsToTime(23 * 3600 + seconds + 3600, ctx)
    }

    this.setState(
      () => ({ time }),
      () => {
        const dayChanged =
          seconds > maxSeconds ? +1 : seconds < minSeconds ? -1 : null
        onChange(time, dayChanged)
      }
    )
  }

  handleAction = (action, context) => {
    const { secondsStep } = this.props
    const step = context === 'hour' ? 3600 : secondsStep

    switch (action) {
      case 'inc':
        this.onChange(this.seconds + step)
        break
      case 'dec':
        this.onChange(this.seconds - step)
        break
    }
  }

  onWheel = (event, context) => {
    event.preventDefault()
    const action = event.deltaY < 0 ? 'inc' : 'dec'
    this.handleAction(action, context)
  }

  onMouseUp = (action, context) => {
    if (this.holdTimer !== null) {
      this.handleAction(action, context)
    }

    this.stopHoldTimer()
    this.stopSpinTimer()
  }

  onMouseOut = () => {
    this.stopHoldTimer()
    this.stopSpinTimer()
  }

  stopSpinTimer = () => {
    if (this.spinTimer !== null) {
      clearInterval(this.spinTimer)
      this.spinTimer = null
    }
  }

  stopHoldTimer = () => {
    if (this.holdTimer !== null) {
      clearTimeout(this.holdTimer)
      this.holdTimer = null
    }
  }

  startSpinTimer = (action, context) => {
    this.stopSpinTimer()
    this.stopHoldTimer()

    this.spinTimer = setInterval(() => {
      this.handleAction(action, context)
    }, 75)
  }

  startHoldTimer = (action, context) => {
    this.stopHoldTimer()

    this.holdTimer = setTimeout(() => {
      this.startSpinTimer(action, context)
    }, 600)
  }

  onClose = () => {
    if (this.props.onClose !== undefined) {
      this.props.onClose()
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.focused && !this.state.focused) {
      this.onClose()
    }
  }

  get popup() {
    if (this.state.focused) {
      return (
        <Popup
          ref={this.popupRef}
          ctx={this.props.ctx}
          inputId={this.inputId}
          onWheel={this.onWheel}
          startHoldTimer={this.startHoldTimer}
          onMouseUp={this.onMouseUp}
          onMouseOut={this.onMouseOut}
          hours={this.hours}
          minutes={this.minutes}
          placement={this.props.popupPlacement}
        />
      )
    } else {
      return null
    }
  }

  get clockIcon() {
    if (this.props.outlineIcons && this.state.time) {
      return null
    } else {
      return <div className={`${styles.icon} ${styles.iconTime}`} />
    }
  }

  render() {
    const { errors, disabled, inputClass, wrapperClass } = this.props
    const { time } = this.state

    const inputStyle = styleNames({
      [styles.input]: true,
      [styles.inputDisabled]: disabled,
    })

    const wrapperClasses = styleNames({
      [styles.picker]: true,
      [styles.pickerTime]: true,
      [styles.outlineIcons]: this.props.outlineIcons,
      'test-picker-time': true,
      [wrapperClass]: true,
    })

    return (
      <div
        className={wrapperClasses}
        ref={(el) => (this.picker = el)}
        id={this.inputId}
      >
        <input
          type="text"
          className={`${inputClass || ''} ${inputStyle}`}
          value={time || ''}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          readOnly={disabled}
          onChange={this.onInputChange}
        />

        {this.clockIcon}

        {errors.length === 0 ? null : (
          <div className={styles.error}>
            {errors.map((x, idx) => (
              <div key={idx}>{x}</div>
            ))}
          </div>
        )}

        {this.popup}
      </div>
    )
  }
}

export default withContext(TimePicker)
