/* eslint-disable no-undef */
import React from 'react'
import AddressLookupField from './base'
import { gmapsPromise } from 'planado/globals'
import POSSIBLE_COUNTRIES from 'planado/constants/possible_countries'
import { BLANK_ADDRESS } from 'planado/types/address'

export class GoogleAddressField extends React.Component {
  static defaultProps = {
    xhr: false,
    readOnly: false,
    wrapperClass: {},
  }

  state = {
    lookupInput: null,
  }

  translateCountry = (name) => (POSSIBLE_COUNTRIES.includes(name) ? name : null)

  fillAddress = () => {
    const place = this.autocomplete.getPlace()

    this.props.wire.usedResources.track("googlePlacesRequest")

    if (place.address_components) {
      const data = place.address_components.reduce((memo, c, _, __) => {
        if (c.types != undefined) {
          c.types.forEach((type, _, __) =>
            type === 'country'
              ? (memo[type] = c.short_name)
              : (memo[type] = c.long_name)
          )
        }
        return memo
      }, {})

      const location = place.geometry.location
      let geolocation
      if (location.lng && location.lat) {
        geolocation = {
          longitude: Number(location.lng()),
          latitude: Number(location.lat()),
        }
      } else {
        geolocation = null
      }

      const address = {
        streetNumber: data.street_number || null,
        route: data.route || null,
        sublocality: data.sublocality || null,
        locality: data.locality || null,
        subadministrativeArea: data.administrative_area_level_2 || null,
        administrativeArea: data.administrative_area_level_1 || null,
        country: this.translateCountry(data.country.toLowerCase()),
        postalCode: data.postal_code || null,
        extId: place.place_id || null,
        source: 'google',
        formatted: place.formatted_address || place.name || null,
        formattedFull: place.formatted_address || null,
        geolocation: geolocation || null,
        geolocationResolved: geolocation ? true : false
      }

      this.props.onChange(address)
    } else {
      this.props.onChange({
        ...BLANK_ADDRESS,
        source: 'google',
        geolocationResolved: false,
        formatted: place.name,
        formattedFull: place.name,
      })
    }
  }

  onBlur = () => {
    // NOOP
  }

  addLocationAutocomplete = () => {
    const { lookupInput } = this.state

    if (lookupInput) {
      gmapsPromise.then(() => {
        this.autocomplete = new google.maps.places.Autocomplete(lookupInput, {
          fields: ['name', 'geometry.location', 'formatted_address', 'address_components', 'place_id']
        })
        this.autocomplete.addListener('place_changed', this.fillAddress)
        this.autocompleteAdded = true
      })
    } else {
      throw new Error('Field didn`t seem to render')
    }
  }

  onLookupMount = (lookupInput) => {
    this.setState(() => ({ lookupInput }), this.addLocationAutocomplete)
  }

  removeLocationAutocomplete = () => {
    const { lookupInput } = this.state

    if (this.autocomplete && lookupInput !== null) {
      this.autocomplete.unbindAll()
      google.maps.event.clearInstanceListeners(lookupInput)

      delete this.autocomplete
      this.autocompleteAdded = false
    }
  }

  componentWillUnmount() {
    if (this.autocompleteAdded) {
      this.removeLocationAutocomplete()
    }
  }

  componentDidUpdate(prevProps) {
    const currentReadOnly = this.props.readOnly

    if (currentReadOnly !== prevProps.readOnly) {
      if (currentReadOnly) {
        this.removeLocationAutocomplete()
      } else {
        this.addLocationAutocomplete()
      }
    }
  }

  render() {
    return (
      <AddressLookupField
        id={this.props.id}
        attributes={this.props.attributes}
        onChange={this.props.onChange}
        onBlur={this.onBlur}
        readOnly={this.props.readOnly}
        namePrefix={this.props.inputName}
        lookupAddress={this.props.lookupAddress}
        wrapperClass={this.props.wrapperClass}
        xhr={this.props.xhr}
        wire={this.props.wire}
        onLookupMount={this.onLookupMount}
      />
    )
  }
}
