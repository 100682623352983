

import * as Utils from "../../../utils/Utils.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as EncodeJson from "../../../libraries/EncodeJson.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as Types_Address from "../../../types/Types_Address.mjs";
import * as Pages_Site_Types from "./Pages_Site_Types.mjs";
import * as AddressInput_Types from "../../../components/address_input/AddressInput_Types.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              canManage: field.required("canManage", JsonDecode.bool),
              locked: false,
              site: field.required("site", Pages_Site_Types.Site.decode),
              addressConstraints: field.required("addressConstraints", AddressInput_Types.AddressConstraint.decode),
              errors: []
            };
    });

function send(wire, subRoute) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Site",
                    VAL: subRoute
                  }
                }), decode);
}

function send$1(wire, uuid) {
  return Backend.$$delete(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "Site",
                VAL: {
                  NAME: "Edit",
                  VAL: uuid
                }
              }
            });
}

var Delete = {
  send: send$1
};

function encodeSiteValues(site) {
  return EncodeJson.object([
              [
                "name",
                EncodeJson.optionString(site.name)
              ],
              [
                "addressDescription",
                EncodeJson.optionString(site.addressDescription)
              ],
              [
                "description",
                EncodeJson.optionString(site.description)
              ],
              [
                "externalId",
                EncodeJson.optionString(site.externalId)
              ],
              [
                "apartment",
                EncodeJson.optionString(site.apartment)
              ],
              [
                "floor",
                EncodeJson.optionString(site.floor)
              ],
              [
                "entranceNo",
                EncodeJson.optionString(site.entranceNo)
              ],
              [
                "clientUuid",
                EncodeJson.optionUuid(site.clientUuid)
              ],
              [
                "territoryUuid",
                EncodeJson.optionUuid(site.territoryUuid)
              ],
              [
                "contacts",
                EncodeJson.jsonArray(site.contacts.map(function (c) {
                          if (c.TAG === "Phone") {
                            var match = c._0;
                            return EncodeJson.object([
                                        [
                                          "type",
                                          EncodeJson.string("phone")
                                        ],
                                        [
                                          "value",
                                          EncodeJson.optionString(match.value)
                                        ],
                                        [
                                          "name",
                                          EncodeJson.optionString(match.name)
                                        ]
                                      ]);
                          }
                          var match$1 = c._0;
                          return EncodeJson.object([
                                      [
                                        "type",
                                        EncodeJson.string("phone")
                                      ],
                                      [
                                        "value",
                                        EncodeJson.optionString(match$1.value)
                                      ],
                                      [
                                        "name",
                                        EncodeJson.optionString(match$1.name)
                                      ]
                                    ]);
                        }))
              ],
              [
                "address",
                Types_Address.encode(site.address)
              ]
            ]);
}

function send$2(wire, site) {
  return Backend.post(undefined, undefined, undefined, true, wire, {
              NAME: "API",
              VAL: {
                NAME: "Site",
                VAL: {
                  NAME: "Index",
                  VAL: undefined
                }
              }
            }, {
              NAME: "Obj",
              VAL: Utils.jsonToObj(EncodeJson.object([[
                          "site",
                          encodeSiteValues(site)
                        ]]))
            });
}

var Post = {
  send: send$2
};

function send$3(wire, uuid, site) {
  return Backend.patch(undefined, undefined, undefined, true, wire, {
              NAME: "API",
              VAL: {
                NAME: "Site",
                VAL: {
                  NAME: "Edit",
                  VAL: uuid
                }
              }
            }, {
              NAME: "Obj",
              VAL: Utils.jsonToObj(EncodeJson.object([[
                          "site",
                          encodeSiteValues(site)
                        ]]))
            });
}

var Patch = {
  send: send$3
};

var Get = {
  send: send
};

export {
  Get ,
  Delete ,
  Post ,
  Patch ,
}
/* decode Not a pure module */
