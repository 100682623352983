

import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Switch from "../../../components/inputs/Switch.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Optional from "../../../components/common/Optional.mjs";
import * as FormInput from "../../../components/inputs/FormInput.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as Types_Contact from "../../../types/Types_Contact.mjs";
import * as UI_ContactList from "../../../ui/UI_ContactList.mjs";
import * as Pages_Client_Types from "./Pages_Client_Types.mjs";
import * as Pages_Client_AddressBlock from "./Pages_Client_AddressBlock.mjs";

var individual$p = Utils.Translations.t("js.clients.labels.add_individual");

var organization$p = Utils.Translations.t("js.clients.labels.add_organization");

var externalId$p = Utils.Translations.t("js.clients.labels.external_id");

var mobilePhone$p = Utils.Translations.t("js.clients.labels.mobile_phone");

var organizationName$p = Utils.Translations.t("js.clients.labels.organization_name");

var firstName$p = Utils.Translations.t("js.clients.labels.first_name");

var middleName$p = Utils.Translations.t("js.clients.labels.middle_name");

var lastName$p = Utils.Translations.t("js.clients.labels.last_name");

function Pages_Client_Body$ExternalIdBlock(Props) {
  var state = Props.state;
  var setState = Props.setState;
  var ctx = Props.ctx;
  var client = state.client;
  return React.createElement(Optional.make, {
              show: client.isNewRecord && client.showingExternalId,
              children: React.createElement(FormInput.make, {
                    _type: "text",
                    value: Pages_Client_Types.fieldToString(client.externalId),
                    onValueChange: (function (value) {
                        setState(function (current) {
                              var init = current.client;
                              return {
                                      limits: current.limits,
                                      canManage: current.canManage,
                                      locked: current.locked,
                                      client: {
                                        uuid: init.uuid,
                                        isNewRecord: init.isNewRecord,
                                        organization: init.organization,
                                        showingExternalId: init.showingExternalId,
                                        externalId: value,
                                        name: init.name,
                                        firstName: init.firstName,
                                        lastName: init.lastName,
                                        middleName: init.middleName,
                                        organizationName: init.organizationName,
                                        contacts: init.contacts,
                                        floor: init.floor,
                                        apartment: init.apartment,
                                        addressDescription: init.addressDescription,
                                        entranceNo: init.entranceNo,
                                        territoryUuid: init.territoryUuid,
                                        availableTerritories: init.availableTerritories,
                                        createdAt: init.createdAt,
                                        address: init.address
                                      },
                                      addressConstraints: current.addressConstraints,
                                      errors: current.errors
                                    };
                            });
                      }),
                    id: "client-external_id",
                    readOnly: !state.canManage,
                    name: "client[external_id]",
                    labelText: externalId$p(ctx),
                    errors: Pages_Client_Types.$$Error.message(state.errors, "externalId"),
                    wrapperClass: Js_dict.fromList({
                          hd: [
                            "pd-form-group",
                            true
                          ],
                          tl: /* [] */0
                        })
                  })
            });
}

function Pages_Client_Body$NameBlock(Props) {
  var state = Props.state;
  var setState = Props.setState;
  var ctx = Props.ctx;
  var client = state.client;
  if (state.client.organization) {
    return React.createElement("div", {
                className: "pd-form-group-container"
              }, React.createElement(FormInput.make, {
                    _type: "text",
                    value: Pages_Client_Types.fieldToString(client.organizationName),
                    onValueChange: (function (value) {
                        setState(function (current) {
                              var init = current.client;
                              return {
                                      limits: current.limits,
                                      canManage: current.canManage,
                                      locked: current.locked,
                                      client: {
                                        uuid: init.uuid,
                                        isNewRecord: init.isNewRecord,
                                        organization: init.organization,
                                        showingExternalId: init.showingExternalId,
                                        externalId: init.externalId,
                                        name: init.name,
                                        firstName: init.firstName,
                                        lastName: init.lastName,
                                        middleName: init.middleName,
                                        organizationName: value,
                                        contacts: init.contacts,
                                        floor: init.floor,
                                        apartment: init.apartment,
                                        addressDescription: init.addressDescription,
                                        entranceNo: init.entranceNo,
                                        territoryUuid: init.territoryUuid,
                                        availableTerritories: init.availableTerritories,
                                        createdAt: init.createdAt,
                                        address: init.address
                                      },
                                      addressConstraints: current.addressConstraints,
                                      errors: current.errors
                                    };
                            });
                      }),
                    id: "client-organization_name",
                    readOnly: !state.canManage,
                    name: "client[organization_name]",
                    labelText: organizationName$p(ctx),
                    maxLength: state.limits.organizationName,
                    errors: Pages_Client_Types.$$Error.message(state.errors, "organizationName"),
                    wrapperClass: Js_dict.fromList({
                          hd: [
                            "pd-form-group",
                            true
                          ],
                          tl: {
                            hd: [
                              "pd-form-group-full",
                              true
                            ],
                            tl: /* [] */0
                          }
                        }),
                    autoFocus: client.isNewRecord
                  }));
  } else {
    return React.createElement("div", undefined, React.createElement("div", {
                    className: "pd-form-group-container"
                  }, React.createElement(FormInput.make, {
                        _type: "text",
                        value: Pages_Client_Types.fieldToString(client.firstName),
                        onValueChange: (function (value) {
                            setState(function (current) {
                                  var init = current.client;
                                  return {
                                          limits: current.limits,
                                          canManage: current.canManage,
                                          locked: current.locked,
                                          client: {
                                            uuid: init.uuid,
                                            isNewRecord: init.isNewRecord,
                                            organization: init.organization,
                                            showingExternalId: init.showingExternalId,
                                            externalId: init.externalId,
                                            name: init.name,
                                            firstName: value,
                                            lastName: init.lastName,
                                            middleName: init.middleName,
                                            organizationName: init.organizationName,
                                            contacts: init.contacts,
                                            floor: init.floor,
                                            apartment: init.apartment,
                                            addressDescription: init.addressDescription,
                                            entranceNo: init.entranceNo,
                                            territoryUuid: init.territoryUuid,
                                            availableTerritories: init.availableTerritories,
                                            createdAt: init.createdAt,
                                            address: init.address
                                          },
                                          addressConstraints: current.addressConstraints,
                                          errors: current.errors
                                        };
                                });
                          }),
                        id: "client-first_name",
                        readOnly: !state.canManage,
                        name: "client[first_name]",
                        labelText: firstName$p(ctx),
                        maxLength: state.limits.firstName,
                        errors: Pages_Client_Types.$$Error.message(state.errors, "firstName"),
                        wrapperClass: Js_dict.fromList({
                              hd: [
                                "pd-form-group",
                                true
                              ],
                              tl: {
                                hd: [
                                  "pd-form-group-half",
                                  true
                                ],
                                tl: /* [] */0
                              }
                            }),
                        autoFocus: client.isNewRecord
                      }), React.createElement(FormInput.make, {
                        _type: "text",
                        value: Pages_Client_Types.fieldToString(client.middleName),
                        onValueChange: (function (value) {
                            setState(function (current) {
                                  var init = current.client;
                                  return {
                                          limits: current.limits,
                                          canManage: current.canManage,
                                          locked: current.locked,
                                          client: {
                                            uuid: init.uuid,
                                            isNewRecord: init.isNewRecord,
                                            organization: init.organization,
                                            showingExternalId: init.showingExternalId,
                                            externalId: init.externalId,
                                            name: init.name,
                                            firstName: init.firstName,
                                            lastName: init.lastName,
                                            middleName: value,
                                            organizationName: init.organizationName,
                                            contacts: init.contacts,
                                            floor: init.floor,
                                            apartment: init.apartment,
                                            addressDescription: init.addressDescription,
                                            entranceNo: init.entranceNo,
                                            territoryUuid: init.territoryUuid,
                                            availableTerritories: init.availableTerritories,
                                            createdAt: init.createdAt,
                                            address: init.address
                                          },
                                          addressConstraints: current.addressConstraints,
                                          errors: current.errors
                                        };
                                });
                          }),
                        id: "client-middle_name",
                        readOnly: !state.canManage,
                        name: "client[middle_name]",
                        labelText: middleName$p(ctx),
                        maxLength: state.limits.middleName,
                        wrapperClass: Js_dict.fromList({
                              hd: [
                                "pd-form-group",
                                true
                              ],
                              tl: {
                                hd: [
                                  "pd-form-group-half",
                                  true
                                ],
                                tl: /* [] */0
                              }
                            })
                      })), React.createElement("div", {
                    className: "pd-form-group-container"
                  }, React.createElement(FormInput.make, {
                        _type: "text",
                        value: Pages_Client_Types.fieldToString(client.lastName),
                        onValueChange: (function (value) {
                            setState(function (current) {
                                  var init = current.client;
                                  return {
                                          limits: current.limits,
                                          canManage: current.canManage,
                                          locked: current.locked,
                                          client: {
                                            uuid: init.uuid,
                                            isNewRecord: init.isNewRecord,
                                            organization: init.organization,
                                            showingExternalId: init.showingExternalId,
                                            externalId: init.externalId,
                                            name: init.name,
                                            firstName: init.firstName,
                                            lastName: value,
                                            middleName: init.middleName,
                                            organizationName: init.organizationName,
                                            contacts: init.contacts,
                                            floor: init.floor,
                                            apartment: init.apartment,
                                            addressDescription: init.addressDescription,
                                            entranceNo: init.entranceNo,
                                            territoryUuid: init.territoryUuid,
                                            availableTerritories: init.availableTerritories,
                                            createdAt: init.createdAt,
                                            address: init.address
                                          },
                                          addressConstraints: current.addressConstraints,
                                          errors: current.errors
                                        };
                                });
                          }),
                        id: "client-last_name",
                        readOnly: !state.canManage,
                        name: "client[last_name]",
                        labelText: lastName$p(ctx),
                        maxLength: state.limits.lastName,
                        wrapperClass: Js_dict.fromList({
                              hd: [
                                "pd-form-group",
                                true
                              ],
                              tl: {
                                hd: [
                                  "pd-form-group-half",
                                  true
                                ],
                                tl: /* [] */0
                              }
                            })
                      })));
  }
}

function Pages_Client_Body$ContactBlock(Props) {
  var state = Props.state;
  var setState = Props.setState;
  var client = state.client;
  var initialContacts = React.useMemo((function () {
          return client.contacts;
        }), []);
  return React.createElement(UI_ContactList.make, {
              contacts: client.contacts,
              addContact: (function (param) {
                  setState(function (current) {
                        var init = current.client;
                        return {
                                limits: current.limits,
                                canManage: current.canManage,
                                locked: current.locked,
                                client: {
                                  uuid: init.uuid,
                                  isNewRecord: init.isNewRecord,
                                  organization: init.organization,
                                  showingExternalId: init.showingExternalId,
                                  externalId: init.externalId,
                                  name: init.name,
                                  firstName: init.firstName,
                                  lastName: init.lastName,
                                  middleName: init.middleName,
                                  organizationName: init.organizationName,
                                  contacts: Belt_Array.concatMany([
                                        current.client.contacts,
                                        [Types_Contact.empty]
                                      ]),
                                  floor: init.floor,
                                  apartment: init.apartment,
                                  addressDescription: init.addressDescription,
                                  entranceNo: init.entranceNo,
                                  territoryUuid: init.territoryUuid,
                                  availableTerritories: init.availableTerritories,
                                  createdAt: init.createdAt,
                                  address: init.address
                                },
                                addressConstraints: current.addressConstraints,
                                errors: current.errors
                              };
                      });
                }),
              removeContact: (function (idx) {
                  setState(function (current) {
                        var init = current.client;
                        return {
                                limits: current.limits,
                                canManage: current.canManage,
                                locked: current.locked,
                                client: {
                                  uuid: init.uuid,
                                  isNewRecord: init.isNewRecord,
                                  organization: init.organization,
                                  showingExternalId: init.showingExternalId,
                                  externalId: init.externalId,
                                  name: init.name,
                                  firstName: init.firstName,
                                  lastName: init.lastName,
                                  middleName: init.middleName,
                                  organizationName: init.organizationName,
                                  contacts: current.client.contacts.filter(function (param, i) {
                                        return i !== idx;
                                      }),
                                  floor: init.floor,
                                  apartment: init.apartment,
                                  addressDescription: init.addressDescription,
                                  entranceNo: init.entranceNo,
                                  territoryUuid: init.territoryUuid,
                                  availableTerritories: init.availableTerritories,
                                  createdAt: init.createdAt,
                                  address: init.address
                                },
                                addressConstraints: current.addressConstraints,
                                errors: current.errors
                              };
                      });
                }),
              editContact: (function (idx, value) {
                  setState(function (current) {
                        var init = current.client;
                        return {
                                limits: current.limits,
                                canManage: current.canManage,
                                locked: current.locked,
                                client: {
                                  uuid: init.uuid,
                                  isNewRecord: init.isNewRecord,
                                  organization: init.organization,
                                  showingExternalId: init.showingExternalId,
                                  externalId: init.externalId,
                                  name: init.name,
                                  firstName: init.firstName,
                                  lastName: init.lastName,
                                  middleName: init.middleName,
                                  organizationName: init.organizationName,
                                  contacts: current.client.contacts.map(function (contact, i) {
                                        if (i === idx) {
                                          return value;
                                        } else {
                                          return contact;
                                        }
                                      }),
                                  floor: init.floor,
                                  apartment: init.apartment,
                                  addressDescription: init.addressDescription,
                                  entranceNo: init.entranceNo,
                                  territoryUuid: init.territoryUuid,
                                  availableTerritories: init.availableTerritories,
                                  createdAt: init.createdAt,
                                  address: init.address
                                },
                                addressConstraints: current.addressConstraints,
                                errors: current.errors
                              };
                      });
                }),
              readOnly: !state.canManage,
              isFirstContactHidden: !client.organization && RCore.$$Option.getOr(RCore.$$Option.map(initialContacts[0], (function (c) {
                          if (c.TAG === "Phone") {
                            return true;
                          } else {
                            return false;
                          }
                        })), true)
            });
}

function Pages_Client_Body$Form(Props) {
  var state = Props.state;
  var setState = Props.setState;
  var wire = Props.wire;
  var client = state.client;
  var ctx = wire.ctx;
  var match = client.contacts[0];
  var tmp;
  if (match !== undefined && match.TAG === "Phone") {
    var value = match._0.value;
    tmp = value !== undefined ? value : "";
  } else {
    tmp = "";
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", undefined, React.createElement(Pages_Client_Body$NameBlock, {
                      state: state,
                      setState: setState,
                      ctx: ctx
                    }), React.createElement(Optional.make, {
                      show: !client.organization,
                      children: React.createElement("div", {
                            className: "pd-form-group-container"
                          }, React.createElement("input", {
                                name: "client[contacts][][type]",
                                type: "hidden",
                                value: "phone"
                              }), React.createElement("input", {
                                name: "client[contacts][][name]",
                                type: "hidden",
                                value: ""
                              }), React.createElement(FormInput.make, {
                                _type: "text",
                                value: tmp,
                                onValueChange: (function (value) {
                                    setState(function (current) {
                                          var init = current.client;
                                          var match = Core__List.fromArray(current.client.contacts);
                                          var tmp;
                                          if (match) {
                                            var match$1 = match.hd;
                                            tmp = match$1.TAG === "Phone" ? Core__List.toArray({
                                                    hd: {
                                                      TAG: "Phone",
                                                      _0: {
                                                        value: value,
                                                        name: match$1._0.name
                                                      }
                                                    },
                                                    tl: match.tl
                                                  }) : [];
                                          } else {
                                            tmp = [];
                                          }
                                          return {
                                                  limits: current.limits,
                                                  canManage: current.canManage,
                                                  locked: current.locked,
                                                  client: {
                                                    uuid: init.uuid,
                                                    isNewRecord: init.isNewRecord,
                                                    organization: init.organization,
                                                    showingExternalId: init.showingExternalId,
                                                    externalId: init.externalId,
                                                    name: init.name,
                                                    firstName: init.firstName,
                                                    lastName: init.lastName,
                                                    middleName: init.middleName,
                                                    organizationName: init.organizationName,
                                                    contacts: tmp,
                                                    floor: init.floor,
                                                    apartment: init.apartment,
                                                    addressDescription: init.addressDescription,
                                                    entranceNo: init.entranceNo,
                                                    territoryUuid: init.territoryUuid,
                                                    availableTerritories: init.availableTerritories,
                                                    createdAt: init.createdAt,
                                                    address: init.address
                                                  },
                                                  addressConstraints: current.addressConstraints,
                                                  errors: current.errors
                                                };
                                        });
                                  }),
                                id: "client-mobile_phone",
                                readOnly: !state.canManage,
                                name: "client[contacts][][value]",
                                labelText: mobilePhone$p(ctx),
                                maxLength: state.limits.mobilePhone,
                                wrapperClass: Js_dict.fromList({
                                      hd: [
                                        "pd-form-group-half",
                                        true
                                      ],
                                      tl: /* [] */0
                                    })
                              }))
                    }), React.createElement(Pages_Client_AddressBlock.make, {
                      state: state,
                      setState: setState,
                      wire: wire
                    }), React.createElement(Pages_Client_Body$ExternalIdBlock, {
                      state: state,
                      setState: setState,
                      ctx: ctx
                    })), React.createElement(Pages_Client_Body$ContactBlock, {
                  state: state,
                  setState: setState
                }));
}

function Pages_Client_Body$ClientTypeSwitch(Props) {
  var state = Props.state;
  var setState = Props.setState;
  var ctx = Props.ctx;
  var updateClientType = function (organizationPicked) {
    if (state.canManage && organizationPicked !== state.client.organization) {
      return setState(function (current) {
                  var init = current.client;
                  return {
                          limits: current.limits,
                          canManage: current.canManage,
                          locked: current.locked,
                          client: {
                            uuid: init.uuid,
                            isNewRecord: init.isNewRecord,
                            organization: organizationPicked,
                            showingExternalId: init.showingExternalId,
                            externalId: init.externalId,
                            name: init.name,
                            firstName: init.firstName,
                            lastName: init.lastName,
                            middleName: init.middleName,
                            organizationName: init.organizationName,
                            contacts: init.contacts,
                            floor: init.floor,
                            apartment: init.apartment,
                            addressDescription: init.addressDescription,
                            entranceNo: init.entranceNo,
                            territoryUuid: init.territoryUuid,
                            availableTerritories: init.availableTerritories,
                            createdAt: init.createdAt,
                            address: init.address
                          },
                          addressConstraints: current.addressConstraints,
                          errors: current.errors
                        };
                });
    }
    
  };
  return React.createElement("div", {
              className: "pd-form-group-container"
            }, React.createElement("div", {
                  className: "pd-form-group"
                }, React.createElement(Switch.Bool.make, {
                      name: "client[organization]",
                      onValueChange: updateClientType,
                      value: state.client.organization,
                      states: [
                        {
                          name: individual$p(ctx),
                          value: false
                        },
                        {
                          name: organization$p(ctx),
                          value: true
                        }
                      ]
                    })));
}

function Pages_Client_Body(Props) {
  var state = Props.state;
  var setState = Props.setState;
  var wire = AppContext.useWire();
  return React.createElement(React.Fragment, undefined, React.createElement(Optional.make, {
                  show: state.client.isNewRecord,
                  children: React.createElement(Pages_Client_Body$ClientTypeSwitch, {
                        state: state,
                        setState: setState,
                        ctx: wire.ctx
                      })
                }), React.createElement(Pages_Client_Body$Form, {
                  state: state,
                  setState: setState,
                  wire: wire
                }));
}

var make = Pages_Client_Body;

export {
  make ,
}
/* individual' Not a pure module */
