

import * as WeekTimeline_AssignedJobPositionsHelper from "../positions/WeekTimeline_AssignedJobPositionsHelper.mjs";

function make(assignee, onClick, wire, sizes, period, startAt, finishAt, tooltip) {
  return {
          assignee: assignee,
          onClick: onClick,
          left: WeekTimeline_AssignedJobPositionsHelper.JobLeft.get(wire, sizes, period, startAt),
          width: WeekTimeline_AssignedJobPositionsHelper.JobWidth.get(wire, sizes, period, startAt, finishAt),
          tooltip: tooltip
        };
}

function assignee(block) {
  return block.assignee;
}

function onClick(block) {
  return block.onClick;
}

function left(block) {
  return block.left;
}

function width(block) {
  return block.width;
}

function tooltip(block) {
  return block.tooltip;
}

export {
  make ,
  assignee ,
  onClick ,
  left ,
  width ,
  tooltip ,
}
/* WeekTimeline_AssignedJobPositionsHelper Not a pure module */
