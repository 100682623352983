

import * as React from "react";
import * as Optional from "../Optional.mjs";
import * as SpinnerModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/Spinner/Spinner.module.css";

var styles = SpinnerModuleCss;

function Spinner(Props) {
  var showOpt = Props.show;
  var show = showOpt !== undefined ? showOpt : true;
  return React.createElement(Optional.make, {
              show: show,
              children: React.createElement("div", {
                    className: styles.spinLoader
                  })
            });
}

var make = Spinner;

export {
  make ,
}
/* styles Not a pure module */
