import {
  extractDate,
  isBeforeTime,
  isAfterTime,
  isBeforeDay,
  isAfterDay
} from 'planado/utils/time/index.js'

const newJobGroup = (job, ctx) => ({
  date: job.displayedAt === null ? null : extractDate(job.displayedAt, ctx),
  jobs: [job]
})

export const groupJobsByDate = (jobs, ctx) => {
  if (jobs.length === 0) {
    return []
  } else {
    const groups = jobs.reduce(
      (groups, job, index) => {
        if (index > 0) {
          const currentGroup = groups[groups.length - 1]
          const currentGroupDate = currentGroup.jobs[0].displayedAt

          if (currentGroupDate) {
            if (
              extractDate(currentGroupDate, ctx) ===
              extractDate(job.displayedAt, ctx)
            ) {
              currentGroup.jobs.push(job)
            } else {
              groups.push(newJobGroup(job, ctx))
            }
          } else {
            if (job.displayedAt === null) {
              currentGroup.jobs.push(job)
            } else {
              groups.push(newJobGroup(job, ctx))
            }
          }
        }

        return groups
      },
      [newJobGroup(jobs[0], ctx)]
    )

    const groupsWithSubgroups = groups.map(({ jobs, date }) => ({
      date,
      numberOfJobs: jobs.length,
      jobs: jobs.reduce((subGroups, job) => {
        const scheduledAt =
          job.scheduledAt === null ? null : extractDate(job.scheduledAt, ctx)
        const displayedAt = extractDate(job.displayedAt, ctx)

        if (!('scheduled' in subGroups)) {
          subGroups.scheduled = []
        }

        if (scheduledAt === displayedAt || scheduledAt === null) {
          subGroups.scheduled.unshift(job)
        } else {
          if (scheduledAt in subGroups) {
            subGroups[scheduledAt].unshift(job)
          } else {
            subGroups[scheduledAt] = [job]
          }
        }

        return subGroups
      }, {})
    }))

    return groupsWithSubgroups.map(({ jobs, date, numberOfJobs }) => {
      const arrayOfSubGroups = Object.keys(jobs).map(key => ({
        date: key,
        jobs: jobs[key]
      }))

      arrayOfSubGroups.forEach(subgroup => {
        subgroup.jobs.sort(
          (
            { scheduledAt: dateA, serialNo: idA },
            { scheduledAt: dateB, serialNo: idB }
          ) => {
            const hasDates = dateA !== null && dateB !== null
            const compareIds = idA < idB ? 1 : idA > idB ? -1 : 0

            return hasDates
              ? isAfterTime(dateA, dateB)
                ? -1
                : isBeforeTime(dateA, dateB)
                ? 1
                : compareIds
              : compareIds
          }
        )
      })

      arrayOfSubGroups.sort(({ date: a }, { date: b }) =>
        a === 'scheduled'
          ? -1
          : isAfterDay(a, b)
          ? -1
          : isBeforeDay(a, b)
          ? 1
          : 0
      )

      return {
        date,
        numberOfJobs,
        jobs: arrayOfSubGroups
      }
    })
  }
}
