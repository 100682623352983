const validateJob = ({ ctx, actions, job: { name, nextScheduledAt } }) => {
  let hasError = false

  if (name === null || name.trim() === '') {
    actions.setError(
      'name',
      Array.of(ctx.t('js.recurring_jobs.errors.name_empty'))
    )
    hasError = true
  }

  if (nextScheduledAt.date === null || nextScheduledAt.date === '') {
    hasError = true
    actions.setError(
      'nextScheduledAtDate',
      Array.of(ctx.t('js.recurring_jobs.errors.date_empty'))
    )
  }

  return hasError
}

const validateForm = ({ ctx, job, errors, actions }) => ({
  job: validateJob({ ctx, job, errors, actions })
})

const formHasError = errors => {
  switch (true) {
    case errors.job:
      return true
    default:
      return false
  }
}

export { validateForm, formHasError }
