import * as React from 'react'
import { SessionContext, createContext } from 'planado/utils/context.js'
import Dom from 'planado/dom'

const wrapper = props => {
  const context = React.useMemo(() => {
    const { wire, ctx, dom = new Dom(window) } = props
    return createContext({ wire, ctx, dom })
  }, [props.ctx, props.wire.route])

  return (
    <SessionContext.Provider value={context}>
      {props.children}
    </SessionContext.Provider>
  )
}

export default wrapper
