import { connect } from 'react-redux'
import { openWorkerHistory, toggleFocusedWorker } from 'planado/actions/map'

import WorkerItem from 'planado/components/map/worker_item'

const mapStateToProps = (state, { worker, hasDayLocation }) => {
  const currentLocation = state.latestLocations[worker.uuid] || null
  const jobUuids =
    currentLocation === null ? [] : currentLocation.jobUuids || []
  let currentJob

  if (hasDayLocation) {
    currentJob = state.currentJobs[jobUuids[jobUuids.length - 1]] || null
  } else {
    currentJob = null
  }

  return {
    worker: worker,
    location: currentLocation,
    focused: state.focusedWorkerUuid === worker.uuid,
    hasLocation: hasDayLocation,
    showDate: !hasDayLocation,
    currentTime: state.currentTime,
    currentJob: currentJob,
    engineChannel: state.engineChannel
  }
}

const mapDispatchToProps = (dispatch, { worker }) => {
  return {
    onHistoryClick: () => dispatch(openWorkerHistory(worker)),
    onWorkerClick: () => dispatch(toggleFocusedWorker(worker))
  }
}

const WorkerItemLink = connect(mapStateToProps, mapDispatchToProps)(WorkerItem)

export default WorkerItemLink
