

import * as Caml_option from "rescript/lib/es6/caml_option.js";

function Impl(Data) {
  var readyState = function (fileReader) {
    var match = fileReader.readyState;
    switch (match) {
      case 0 :
          return "EMPTY";
      case 1 :
          return "LOADING";
      default:
        return "DONE";
    }
  };
  var ReadyState = {
    readyState: readyState
  };
  var result = function (fileReader) {
    return Caml_option.null_to_opt(fileReader.result);
  };
  return {
          ReadyState: ReadyState,
          result: result
        };
}

function readyState(fileReader) {
  var match = fileReader.readyState;
  switch (match) {
    case 0 :
        return "EMPTY";
    case 1 :
        return "LOADING";
    default:
      return "DONE";
  }
}

var ReadyState = {
  readyState: readyState
};

function result(fileReader) {
  return Caml_option.null_to_opt(fileReader.result);
}

var AsArrayBuffer = {
  ReadyState: ReadyState,
  result: result
};

function readyState$1(fileReader) {
  var match = fileReader.readyState;
  switch (match) {
    case 0 :
        return "EMPTY";
    case 1 :
        return "LOADING";
    default:
      return "DONE";
  }
}

var ReadyState$1 = {
  readyState: readyState$1
};

function result$1(fileReader) {
  return Caml_option.null_to_opt(fileReader.result);
}

var AsBinaryString = {
  ReadyState: ReadyState$1,
  result: result$1
};

function readyState$2(fileReader) {
  var match = fileReader.readyState;
  switch (match) {
    case 0 :
        return "EMPTY";
    case 1 :
        return "LOADING";
    default:
      return "DONE";
  }
}

var ReadyState$2 = {
  readyState: readyState$2
};

function result$2(fileReader) {
  return Caml_option.null_to_opt(fileReader.result);
}

var AsDataUrl = {
  ReadyState: ReadyState$2,
  result: result$2
};

function readyState$3(fileReader) {
  var match = fileReader.readyState;
  switch (match) {
    case 0 :
        return "EMPTY";
    case 1 :
        return "LOADING";
    default:
      return "DONE";
  }
}

var ReadyState$3 = {
  readyState: readyState$3
};

function result$3(fileReader) {
  return Caml_option.null_to_opt(fileReader.result);
}

function readBlob(encoding, fileReader, blob) {
  fileReader.readAsText(blob, encoding);
}

function readFile(encoding, fileReader, file) {
  fileReader.readAsText(file, encoding);
}

var AsText = {
  ReadyState: ReadyState$3,
  result: result$3,
  readBlob: readBlob,
  readFile: readFile
};

export {
  Impl ,
  AsArrayBuffer ,
  AsBinaryString ,
  AsDataUrl ,
  AsText ,
}
/* No side effect */
