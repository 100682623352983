import 'planado/global_dependencies'
import { initGmaps, gmapsPromise } from 'planado/globals'
import { initApp } from 'rscrpt/ReScript.mjs'
import { onCaptchaSolve } from 'rscrpt/components/login/Login.mjs'

document.addEventListener('DOMContentLoaded', () => {
  const { ctx, footer, websocketParams, backgroundTasks } =
    window.initialPayload

  initApp(ctx, footer, websocketParams, backgroundTasks)
})

export { initGmaps, gmapsPromise, onCaptchaSolve }
