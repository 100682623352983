

import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as Optional from "../../../../../common/Optional.mjs";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_Types_Job from "../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as Schedule_JobPreviewFooterModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/popovers/job_preview/footer/Schedule_JobPreviewFooter.module.css";

var styles = Schedule_JobPreviewFooterModuleCss;

function enRouteAt$p(time, ctx) {
  return Utils.Translations.txr("js.schedule.v2.job.en_route_at")({
              time: time
            }, ctx);
}

function startedAt$p(time, ctx) {
  return Utils.Translations.txr("js.schedule.v2.job.started_at")({
              time: time
            }, ctx);
}

function suspendedAt$p(time, ctx) {
  return Utils.Translations.txr("js.schedule.v2.job.suspended_at")({
              time: time
            }, ctx);
}

function finishedAt$p(time, ctx) {
  return Utils.Translations.txr("js.schedule.v2.job.finished_at")({
              time: time
            }, ctx);
}

var warning$p = Utils.Translations.tr("js.schedule.v2.job.not_completed_on_time");

function Schedule_JobPreviewFooterDates(Props) {
  var job = Props.job;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var scheduledAt = Schedule_Types_Job.ScheduledJob.scheduledAt(job);
  if (scheduledAt === undefined) {
    return null;
  }
  var scheduledAt$1 = Caml_option.valFromOption(scheduledAt);
  var match = Schedule_Types_Job.ScheduledJob.core(job);
  var finishedAt = match.finishedAt;
  var suspendedAt = match.suspendedAt;
  var startedAt = match.startedAt;
  var enRouteAt = match.enRouteAt;
  var status = match.status;
  var scheduledFinishAt = Locale.T.nextMinutes(match.duration, scheduledAt$1);
  var block;
  var exit = 0;
  if (enRouteAt !== undefined && startedAt === undefined) {
    var enRouteAt$1 = Caml_option.valFromOption(enRouteAt);
    if (suspendedAt !== undefined) {
      if (finishedAt !== undefined || status !== "Suspended") {
        block = null;
      } else {
        var suspendedAt$1 = Caml_option.valFromOption(suspendedAt);
        var format = Locale.T.equalDay3(enRouteAt$1, suspendedAt$1, scheduledAt$1) ? "Time" : "DateTime";
        block = React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: styles.date
                }, enRouteAt$p(Locale.T.fmtM(format, wire)(enRouteAt$1), ctx)), React.createElement("div", {
                  className: styles.date
                }, suspendedAt$p(Locale.T.fmtM(format, wire)(suspendedAt$1), ctx)));
      }
    } else if (finishedAt !== undefined || status !== "EnRoute") {
      block = null;
    } else {
      var format$1 = Locale.T.equalDay(enRouteAt$1, scheduledAt$1) ? "Time" : "DateTime";
      block = React.createElement("div", {
            className: styles.date
          }, enRouteAt$p(Locale.T.fmtM(format$1, wire)(enRouteAt$1), ctx));
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    if (startedAt !== undefined) {
      var startedAt$1 = Caml_option.valFromOption(startedAt);
      var exit$1 = 0;
      if (suspendedAt !== undefined && !(finishedAt !== undefined || status !== "Suspended")) {
        var suspendedAt$2 = Caml_option.valFromOption(suspendedAt);
        var format$2 = Locale.T.equalDay3(startedAt$1, suspendedAt$2, scheduledAt$1) ? "Time" : "DateTime";
        block = React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: styles.date
                }, startedAt$p(Locale.T.fmtM(format$2, wire)(startedAt$1), ctx)), React.createElement("div", {
                  className: styles.date
                }, suspendedAt$p(Locale.T.fmtM(format$2, wire)(suspendedAt$2), ctx)));
      } else {
        exit$1 = 2;
      }
      if (exit$1 === 2) {
        if (finishedAt !== undefined) {
          if (status === "Finished") {
            var finishedAt$1 = Caml_option.valFromOption(finishedAt);
            var format$3 = Locale.T.equalDay4(startedAt$1, finishedAt$1, scheduledAt$1, scheduledFinishAt) ? "Time" : "DateTime";
            block = React.createElement(React.Fragment, undefined, React.createElement("div", {
                      className: styles.date
                    }, startedAt$p(Locale.T.fmtM(format$3, wire)(startedAt$1), ctx)), React.createElement("div", {
                      className: styles.date
                    }, finishedAt$p(Locale.T.fmtM(format$3, wire)(finishedAt$1), ctx)));
          } else {
            block = null;
          }
        } else if (status === "Started") {
          var notCompletedOnTimeBlock = React.createElement(Optional.make, {
                show: Locale.T.moreThan(Locale.T.now(wire), scheduledFinishAt),
                children: React.createElement("div", {
                      className: styles.warning
                    }, warning$p(ctx))
              });
          var format$4 = Locale.T.equalDay(startedAt$1, scheduledAt$1) ? "Time" : "DateTime";
          block = React.createElement(React.Fragment, undefined, React.createElement("div", {
                    className: styles.date
                  }, startedAt$p(Locale.T.fmtM(format$4, wire)(startedAt$1), ctx)), notCompletedOnTimeBlock);
        } else {
          block = null;
        }
      }
      
    } else {
      block = null;
    }
  }
  return React.createElement("div", {
              className: styles.dates
            }, block);
}

var make = React.memo(Schedule_JobPreviewFooterDates);

export {
  make ,
}
/* styles Not a pure module */
