export const SHOW_POPOVER = 'SHOW_POPOVER'

export const showPopover = (node, dom, evt, buttonId) => (
  dispatch,
  getState
) => {
  if (getState().popover.targetId !== buttonId) {
    setTimeout(
      () =>
        dispatch({
          type: SHOW_POPOVER,
          dom,
          buttonId,
          node
        }),
      0
    )
  }
}
