

import * as Fun from "../../../../../../libraries/Fun.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as $$Number from "../../../../../../libraries/Number.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JobForm_StatsPanelModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/edit/form/components/stats_panel/JobForm_StatsPanel.module.css";

var styles = JobForm_StatsPanelModuleCss;

var workDuration$p = Utils.Translations.tr("js.jobs.history.header.work_duration");

var enRouteDuration$p = Utils.Translations.tr("js.jobs.history.header.en_route_duration");

var suspensionDuration$p = Utils.Translations.tr("js.jobs.history.header.suspension_duration");

var totalDuration$p = Utils.Translations.tr("js.jobs.history.header.total_duration");

var traveled$p = Utils.Translations.tr("js.jobs.history.header.traveled");

function meters$p(distance, c) {
  return Utils.Translations.txr("js.jobs.history.distance.meter")({
              distance: distance
            }, c);
}

function kilometers$p(distance, c) {
  return Utils.Translations.txr("js.jobs.history.distance.kilometer")({
              distance: distance
            }, c);
}

function feet$p(distance, c) {
  return Utils.Translations.txr("js.jobs.history.distance.foot")({
              distance: distance
            }, c);
}

function miles$p(distance, c) {
  return Utils.Translations.txr("js.jobs.history.distance.mile")({
              distance: distance
            }, c);
}

function formatDuration(durationM, ctx) {
  var m = durationM % 60;
  var h = durationM / 60 | 0;
  if (h === 0) {
    return Utils.Translations.txr("js.jobs.history.time.minute")({
                m: m
              }, ctx);
  } else if (m === 0) {
    return Utils.Translations.txr("js.jobs.history.time.hour")({
                h: h
              }, ctx);
  } else {
    return Utils.Translations.txr("js.jobs.history.time.hour_and_minute")({
                h: h,
                m: m
              }, ctx);
  }
}

var enRoute = React.createElement("svg", {
      height: "14",
      width: "14",
      fill: "none",
      viewBox: "0 0 14 14",
      xmlns: "http://www.w3.org/2000/svg"
    }, React.createElement("path", {
          clipRule: "evenodd",
          d: "M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM2.97404 6.73276L9.17532 3.87065C9.74775 3.63202 10.3679 4.25217 10.1294 4.82474L7.26727 11.0259C6.93333 11.7415 5.88392 11.5029 5.88392 10.7397V8.11609H3.26025C2.49706 8.11609 2.25857 7.0667 2.97404 6.73276Z",
          fill: "#A8C1D0",
          fillRule: "evenodd"
        }));

var movement = React.createElement("svg", {
      height: "14",
      width: "14",
      fill: "none",
      viewBox: "0 0 14 14",
      xmlns: "http://www.w3.org/2000/svg"
    }, React.createElement("path", {
          clipRule: "evenodd",
          d: "M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM5.64531 9.33776C5.94069 9.75856 6.28678 10.2516 6.69169 10.8386C6.84066 11.0538 7.15933 11.0538 7.30831 10.8386C7.71321 10.2516 8.05931 9.75856 8.35469 9.33776C9.7155 7.39914 10 6.99385 10 6C10 4.34314 8.65686 3 7 3C5.34314 3 4 4.34314 4 6C4 6.99385 4.2845 7.39914 5.64531 9.33776ZM8.25 6C8.25 6.69036 7.69036 7.25 7 7.25C6.30964 7.25 5.75 6.69036 5.75 6C5.75 5.30964 6.30964 4.75 7 4.75C7.69036 4.75 8.25 5.30964 8.25 6Z",
          fill: "#A8C1D0",
          fillRule: "evenodd"
        }));

var work = React.createElement("svg", {
      height: "14",
      width: "14",
      fill: "none",
      viewBox: "0 0 14 14",
      xmlns: "http://www.w3.org/2000/svg"
    }, React.createElement("path", {
          d: "M7 0C3.13306 0 0 3.13306 0 7C0 10.8669 3.13306 14 7 14C10.8669 14 14 10.8669 14 7C14 3.13306 10.8669 0 7 0ZM10.2657 7.67742L5.29798 10.5282C4.85202 10.7766 4.29032 10.4577 4.29032 9.93548V4.06452C4.29032 3.54516 4.84919 3.22339 5.29798 3.47177L10.2657 6.49194C10.7286 6.75161 10.7286 7.42056 10.2657 7.67742Z",
          fill: "#A8C1D0"
        }));

var suspension = React.createElement("svg", {
      height: "14",
      width: "14",
      fill: "none",
      viewBox: "0 0 14 14",
      xmlns: "http://www.w3.org/2000/svg"
    }, React.createElement("path", {
          d: "M7 0C3.13306 0 0 3.13306 0 7C0 10.8669 3.13306 14 7 14C10.8669 14 14 10.8669 14 7C14 3.13306 10.8669 0 7 0ZM6.54839 9.25806C6.54839 9.50645 6.34516 9.70968 6.09677 9.70968H4.74194C4.49355 9.70968 4.29032 9.50645 4.29032 9.25806V4.74194C4.29032 4.49355 4.49355 4.29032 4.74194 4.29032H6.09677C6.34516 4.29032 6.54839 4.49355 6.54839 4.74194V9.25806ZM9.70968 9.25806C9.70968 9.50645 9.50645 9.70968 9.25806 9.70968H7.90323C7.65484 9.70968 7.45161 9.50645 7.45161 9.25806V4.74194C7.45161 4.49355 7.65484 4.29032 7.90323 4.29032H9.25806C9.50645 4.29032 9.70968 4.49355 9.70968 4.74194V9.25806Z",
          fill: "#A8C1D0"
        }));

var total = React.createElement("svg", {
      height: "14",
      width: "14",
      fill: "none",
      viewBox: "0 0 14 14",
      xmlns: "http://www.w3.org/2000/svg"
    }, React.createElement("path", {
          d: "M7 0C3.13306 0 0 3.13306 0 7C0 10.8669 3.13306 14 7 14C10.8669 14 14 10.8669 14 7C14 3.13306 10.8669 0 7 0ZM9.6106 8.83468L9.04609 9.54032C9.00904 9.58664 8.96323 9.62521 8.91128 9.65382C8.85933 9.68243 8.80225 9.70053 8.74331 9.70708C8.68436 9.71364 8.6247 9.70851 8.56773 9.69201C8.51076 9.6755 8.4576 9.64794 8.41129 9.61089L6.52016 8.2075C6.38802 8.10171 6.28137 7.96755 6.20808 7.81497C6.13479 7.66239 6.09675 7.49528 6.09677 7.32601V2.93548C6.09677 2.81571 6.14435 2.70084 6.22905 2.61615C6.31374 2.53145 6.42861 2.48387 6.54839 2.48387H7.45161C7.57139 2.48387 7.68626 2.53145 7.77095 2.61615C7.85565 2.70084 7.90323 2.81571 7.90323 2.93548V7L9.54032 8.1996C9.58666 8.23667 9.62524 8.2825 9.65385 8.33449C9.68246 8.38648 9.70055 8.44359 9.70708 8.50257C9.7136 8.56155 9.70844 8.62124 9.69189 8.67822C9.67534 8.73521 9.64771 8.78837 9.6106 8.83468Z",
          fill: "#A8C1D0"
        }));

function JobForm_StatsPanel$HeaderItem(Props) {
  var value = Props.value;
  var description = Props.description;
  var icon = Props.icon;
  return React.createElement("div", {
              className: styles.item
            }, React.createElement("div", {
                  className: styles.itemIcon
                }, icon), React.createElement("div", undefined, React.createElement("p", {
                      className: styles.itemValue
                    }, value), React.createElement("p", {
                      className: styles.itemDescription
                    }, description)));
}

function JobForm_StatsPanel(Props) {
  var param = Props.wire;
  var job = Props.job;
  var totalTraveledDistance = job.totalTraveledDistance;
  var actualDurationMin = job.actualDurationMin;
  var suspensionDurationMin = job.suspensionDurationMin;
  var workDurationMin = job.workDurationMin;
  var enRouteDurationMin = job.enRouteDurationMin;
  var ctx = param.ctx;
  var enRouteDuration;
  if (enRouteDurationMin !== undefined) {
    var value = formatDuration(enRouteDurationMin, ctx);
    var description = enRouteDuration$p(ctx);
    enRouteDuration = Caml_option.some(React.createElement(JobForm_StatsPanel$HeaderItem, {
              value: value,
              description: description,
              icon: enRoute
            }));
  } else {
    enRouteDuration = undefined;
  }
  var totalTraveledDistance$1;
  if (totalTraveledDistance !== undefined) {
    switch (totalTraveledDistance.TAG) {
      case "Meters" :
          var meters = totalTraveledDistance._0;
          totalTraveledDistance$1 = meters !== 0 ? Caml_option.some(React.createElement(JobForm_StatsPanel$HeaderItem, {
                      value: meters$p(meters, ctx),
                      description: traveled$p(ctx),
                      icon: movement
                    })) : undefined;
          break;
      case "Kilometers" :
          var kilometers = totalTraveledDistance._0;
          if (kilometers !== 0.0) {
            var formatted = $$Number.formatFloat(ctx, kilometers);
            totalTraveledDistance$1 = Caml_option.some(React.createElement(JobForm_StatsPanel$HeaderItem, {
                      value: kilometers$p(formatted, ctx),
                      description: traveled$p(ctx),
                      icon: movement
                    }));
          } else {
            totalTraveledDistance$1 = undefined;
          }
          break;
      case "Feet" :
          var feet = totalTraveledDistance._0;
          totalTraveledDistance$1 = feet !== 0 ? Caml_option.some(React.createElement(JobForm_StatsPanel$HeaderItem, {
                      value: feet$p(feet, ctx),
                      description: traveled$p(ctx),
                      icon: movement
                    })) : undefined;
          break;
      case "Miles" :
          var miles = totalTraveledDistance._0;
          if (miles !== 0.0) {
            var formatted$1 = $$Number.formatFloat(ctx, miles);
            totalTraveledDistance$1 = Caml_option.some(React.createElement(JobForm_StatsPanel$HeaderItem, {
                      value: miles$p(formatted$1, ctx),
                      description: traveled$p(ctx),
                      icon: movement
                    }));
          } else {
            totalTraveledDistance$1 = undefined;
          }
          break;
      
    }
  } else {
    totalTraveledDistance$1 = undefined;
  }
  var workDuration;
  if (workDurationMin !== undefined) {
    var value$1 = formatDuration(workDurationMin, ctx);
    var description$1 = workDuration$p(ctx);
    workDuration = Caml_option.some(React.createElement(JobForm_StatsPanel$HeaderItem, {
              value: value$1,
              description: description$1,
              icon: work
            }));
  } else {
    workDuration = undefined;
  }
  var suspensionDurationMin$1;
  if (suspensionDurationMin !== undefined) {
    var value$2 = formatDuration(suspensionDurationMin, ctx);
    var description$2 = suspensionDuration$p(ctx);
    suspensionDurationMin$1 = Caml_option.some(React.createElement(JobForm_StatsPanel$HeaderItem, {
              value: value$2,
              description: description$2,
              icon: suspension
            }));
  } else {
    suspensionDurationMin$1 = undefined;
  }
  var totalDuration;
  if (actualDurationMin !== undefined) {
    var value$3 = formatDuration(actualDurationMin, ctx);
    var description$3 = totalDuration$p(ctx);
    totalDuration = Caml_option.some(React.createElement(JobForm_StatsPanel$HeaderItem, {
              value: value$3,
              description: description$3,
              icon: total
            }));
  } else {
    totalDuration = undefined;
  }
  var infoBlock = RCore.$$Array.filterMap([
          enRouteDuration,
          totalTraveledDistance$1,
          workDuration,
          suspensionDurationMin$1,
          totalDuration
        ], Fun.id).map(function (el, idx) {
        return React.createElement("div", {
                    key: idx.toString(),
                    className: styles.infoItem
                  }, el);
      });
  return React.createElement("div", {
              className: styles.header
            }, React.createElement("div", {
                  className: styles.info
                }, infoBlock));
}

var make = JobForm_StatsPanel;

export {
  make ,
}
/* styles Not a pure module */
