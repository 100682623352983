

import * as Subscription from "../libraries/Subscription.mjs";

function make() {
  return {
          click: Subscription.make(),
          mouseMove: Subscription.make(),
          scroll: Subscription.make(),
          resize: Subscription.make(),
          keydown: Subscription.Stacked.create(),
          tracker: Subscription.make(),
          messenger: Subscription.make(),
          jobs: Subscription.make(),
          clients: Subscription.make(),
          sites: Subscription.make(),
          templates: Subscription.make(),
          timer: Subscription.make(),
          backgroundTasks: Subscription.make()
        };
}

export {
  make ,
}
/* No side effect */
