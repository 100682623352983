export default class Dom {
  constructor(window) {
    this.window = window
    this.document = window.document
    this.navigator = window.navigator
  }

  createElement = tag => {
    return this.document.createElement(tag)
  }

  get viewportDimensions() {
    return {
      height: Math.max(
        this.document.documentElement.clientHeight,
        this.window.innerHeight || 0
      ),
      width: Math.max(
        this.document.documentElement.clientWidth,
        this.window.innerWidth || 0
      )
    }
  }

  getElementDimensions = selector => {
    const element = this.querySelector(selector)

    if (element !== null) {
      return {
        height: element.offsetHeight,
        width: element.offsetWidth
      }
    } else {
      return {
        height: 0,
        width: 0
      }
    }
  }

  getElementPosition = selector => {
    const element = this.querySelector(selector)
    const { top, bottom, left, right } = element.getBoundingClientRect()
    const { scrollX, scrollY } = this.pageOffset

    return {
      right: right + scrollX,
      bottom: bottom + scrollY,
      top: top + this.topOffset,
      left
    }
  }

  get topOffset() {
    return this.document.documentElement.scrollTop
  }

  get scrollHeight() {
    return this.document.documentElement.scrollHeight
  }

  get pageOffset() {
    const supportPageOffset = this.window.pageXOffset !== undefined
    const isCSS1Compat = (this.document.compatMode || '') === 'CSS1Compat'

    return {
      scrollX: supportPageOffset
        ? this.window.pageXOffset
        : isCSS1Compat
          ? this.document.documentElement.scrollLeft
          : this.document.body.scrollLeft,
      scrollY: supportPageOffset
        ? this.window.pageYOffset
        : isCSS1Compat
          ? this.document.documentElement.scrollTop
          : this.document.body.scrollTop
    }
  }

  querySelector = selector => {
    return this.document.querySelector(selector)
  }

  querySelectorAll = selector => {
    return this.document.querySelectorAll(selector)
  }

  addEventListener = (...args) => {
    this.document.addEventListener(...args)
  }

  removeEventListener = (...args) => {
    this.document.removeEventListener(...args)
  }

  getElementsByClassName = classname => {
    return this.document.getElementsByClassName(classname)
  }

  open = (...args) => this.window.open(...args)

  setLocation = pathname => (this.window.location = pathname)

  get scrollbarHeight() {
    const div = this.createElement('div')

    div.style.overflowX = 'scroll'
    div.style.width = '50px'
    div.style.height = '50px'
    div.style.visibility = 'hidden'

    this.querySelector('body').appendChild(div)
    const scrollHeight = div.offsetHeight - div.clientHeight
    this.querySelector('body').removeChild(div)

    return scrollHeight
  }

  onResize = cb => this.window.addEventListener('resize', cb, false)

  removeResizeHandler = cb =>
    this.window.removeEventListener('resize', cb, false)

  onScroll = cb => this.window.addEventListener('scroll', cb, true)

  removeScrollHandler = cb =>
    this.window.removeEventListener('scroll', cb, true)

  setTimeout = (...args) => this.window.setTimeout(...args)

  clearTimeout = (...args) => this.window.clearTimeout(...args)

  setInterval = (...args) => this.window.setInterval(...args)

  clearInterval = (...args) => this.window.clearInterval(...args)

  get showHorizontalScroll() {
    const scrollBottom =
      this.pageOffset.scrollY + this.viewportDimensions.height
    const elementHeight = this.getElementDimensions('.jobs-table').height + 137

    return elementHeight - scrollBottom > 0
  }

  get supportHidingScroll() {
    const userAgent = this.navigator.userAgent
    if (userAgent.indexOf('Firefox') > -1 || userAgent.indexOf('MSIE') > -1) {
      return false
    } else {
      return true
    }
  }
}
