

import * as IndexJsx from "planado/components/admin/dictionaries/show/index.jsx";

var make = IndexJsx.Footer;

export {
  make ,
}
/* make Not a pure module */
