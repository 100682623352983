

import * as Fun from "../../../Fun.mjs";
import * as RCore from "../../../RCore.mjs";
import * as Strscan from "strscan";

function scan(ss, regExp) {
  return RCore.$$Option.flatMap(ss.scan(regExp), Fun.optString);
}

function scanChar(ss) {
  return RCore.$$Option.flatMap(ss.scanChar(), Fun.optString);
}

function make(prim) {
  return new (Strscan.default.StringScanner)(prim);
}

function scanUntil(prim0, prim1) {
  return prim0.scanUntil(prim1);
}

function skip(prim0, prim1) {
  prim0.skip(prim1);
}

function skipUntil(prim0, prim1) {
  prim0.skipUntil(prim1);
}

function check(prim0, prim1) {
  return prim0.check(prim1);
}

function checkUntil(prim0, prim1) {
  return prim0.checkUntil(prim1);
}

function peek(prim0, prim1) {
  prim0.peek(prim1);
}

function getSource(prim) {
  return prim.getSource();
}

function getRemainder(prim) {
  return prim.getRemainder();
}

function getPosition(prim) {
  return prim.getPosition();
}

function hasTerminated(prim) {
  return prim.hasTerminated();
}

function getPreMatch(prim) {
  return prim.getPreMatch();
}

function getMatch(prim) {
  return prim.getMatch();
}

function getPostMatch(prim) {
  return prim.getPostMatch();
}

function getCapture(prim0, prim1) {
  return prim0.getCapture(prim1);
}

function reset(prim) {
  prim.reset();
}

function terminate(prim) {
  prim.terminate();
}

function concat(prim0, prim1) {
  prim0.concat(prim1);
}

function unscan(prim) {
  prim.unscan();
}

export {
  make ,
  scan ,
  scanUntil ,
  scanChar ,
  skip ,
  skipUntil ,
  check ,
  checkUntil ,
  peek ,
  getSource ,
  getRemainder ,
  getPosition ,
  hasTerminated ,
  getPreMatch ,
  getMatch ,
  getPostMatch ,
  getCapture ,
  reset ,
  terminate ,
  concat ,
  unscan ,
}
/* strscan Not a pure module */
