

import * as JobEdit_JobStateReducerHelper from "./JobEdit_JobStateReducerHelper.mjs";

function reducer(state, action) {
  if (typeof action !== "object") {
    switch (action) {
      case "AddContact" :
          return JobEdit_JobStateReducerHelper.addContact(state);
      case "RemoveClientSite" :
          return JobEdit_JobStateReducerHelper.removeClientSite(state);
      case "NoOp" :
          return "NoUpdate";
      
    }
  } else {
    switch (action.TAG) {
      case "SetExternalId" :
          return JobEdit_JobStateReducerHelper.setExternalId(state, action._0);
      case "SetDescription" :
          return JobEdit_JobStateReducerHelper.setDescription(state, action._0);
      case "UpdateReportField" :
          return JobEdit_JobStateReducerHelper.updateReportField(state, action._0);
      case "SetReportFieldFile" :
          return JobEdit_JobStateReducerHelper.setReportFieldFile(state, action._0, action._1);
      case "RemoveReportFieldFile" :
          return JobEdit_JobStateReducerHelper.removeReportFieldFile(state, action._0, action._1);
      case "SetOdometerM" :
          return JobEdit_JobStateReducerHelper.setOdometerM(state, action._0);
      case "SetTypeUuid" :
          return JobEdit_JobStateReducerHelper.setTypeUuid(state, action._0);
      case "SetSkillUuids" :
          return JobEdit_JobStateReducerHelper.setSkillUuids(state, action._0);
      case "SetPossibleAssignees" :
          return JobEdit_JobStateReducerHelper.setPossibleAssignees(state, action._0);
      case "SetSkills" :
          return JobEdit_JobStateReducerHelper.setSkills(state, action._0);
      case "SetScheduledAt" :
          return JobEdit_JobStateReducerHelper.setScheduledAt(state, action._0);
      case "SetScheduledDuration" :
          return JobEdit_JobStateReducerHelper.setScheduledDuration(state, action._0);
      case "UpdateCustomField" :
          return JobEdit_JobStateReducerHelper.updateCustomField(state, action._0);
      case "SetCustomFieldFile" :
          return JobEdit_JobStateReducerHelper.setCustomFieldFile(state, action._0, action._1);
      case "RemoveCustomFieldFile" :
          return JobEdit_JobStateReducerHelper.removeCustomFieldFile(state, action._0, action._1);
      case "EditContact" :
          return JobEdit_JobStateReducerHelper.editContact(state, action._0, action._1);
      case "DeleteContact" :
          return JobEdit_JobStateReducerHelper.deleteContact(state, action._0);
      case "SetAssignees" :
          return JobEdit_JobStateReducerHelper.setAssignees(state, action._0);
      case "SetClientSite" :
          return JobEdit_JobStateReducerHelper.setClientSite(state, action._0);
      case "SetClient" :
          return JobEdit_JobStateReducerHelper.setClient(state, action._0);
      case "SetTerritoryUuid" :
          return JobEdit_JobStateReducerHelper.setTerritoryUuid(state, action._0);
      case "SetAutoTerritoryUuid" :
          return JobEdit_JobStateReducerHelper.setAutoTerritoryUuid(state, action._0);
      case "SetSite" :
          return JobEdit_JobStateReducerHelper.setSite(state, action._0);
      case "SetApartment" :
          return JobEdit_JobStateReducerHelper.setApartment(state, action._0);
      case "SetEntranceNo" :
          return JobEdit_JobStateReducerHelper.setEntranceNo(state, action._0);
      case "SetFloor" :
          return JobEdit_JobStateReducerHelper.setFloor(state, action._0);
      case "SetAddressDescription" :
          return JobEdit_JobStateReducerHelper.setAddressDescription(state, action._0);
      case "SetAddress" :
          return JobEdit_JobStateReducerHelper.setAddress(state, action._0, action._1);
      case "SetOrderedServices" :
          return JobEdit_JobStateReducerHelper.setOrderedServices(state, action._0);
      case "SetProvidedServices" :
          return JobEdit_JobStateReducerHelper.setProvidedServices(state, action._0);
      case "SetFoundClient" :
          return JobEdit_JobStateReducerHelper.setFoundClient(state, action._0, action._1);
      case "SetFoundSiteClient" :
          return JobEdit_JobStateReducerHelper.setFoundSiteClient(state, action._0);
      
    }
  }
}

export {
  reducer ,
}
/* JobEdit_JobStateReducerHelper Not a pure module */
