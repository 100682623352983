

import * as El from "../../../../../../../libraries/El.mjs";
import * as Fun from "../../../../../../../libraries/Fun.mjs";
import * as Uuid from "../../../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Errors from "../../../../../../../Errors.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as FormInput from "../../../../../../inputs/FormInput.mjs";
import * as AppContext from "../../../../../../../context/AppContext.mjs";
import * as ClientSelect from "../../../../../../inputs/ClientSelect.mjs";
import * as UI_ContactList from "../../../../../../../ui/UI_ContactList.mjs";
import * as JobForm_AddressBlock from "./JobForm_AddressBlock.mjs";
import * as JobForm_ClientSiteSwitch from "./JobForm_ClientSiteSwitch.mjs";

var heading$p = Utils.Translations.tr("js.jobs.labels.add_site_heading");

var name$p = Utils.Translations.t("js.jobs.labels.site.name");

var description$p = Utils.Translations.t("js.jobs.labels.site.description");

var organization$p = Utils.Translations.tr("js.jobs.labels.addresses.organization");

var goToClient$p = Utils.Translations.tr("js.jobs.labels.go_to_client");

function JobForm_Site(Props) {
  var onSwitch = Props.onSwitch;
  var site = Props.site;
  var job = Props.job;
  var dispatch = Props.dispatch;
  var errors = Props.errors;
  var inputId = Props.inputId;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var updateSite = function (site) {
    dispatch({
          TAG: "Job",
          _0: {
            TAG: "SetClientSite",
            _0: {
              TAG: "Site",
              _0: {
                TAG: "New",
                _0: site
              }
            }
          }
        });
  };
  var match = site.client;
  return React.createElement("div", {
              className: "client-data client-data-add"
            }, React.createElement("span", {
                  className: "client-data-add-close-icon close",
                  onClick: (function (param) {
                      dispatch({
                            TAG: "Job",
                            _0: "RemoveClientSite"
                          });
                    })
                }, React.createElement("span", {
                      className: "fa fa-times"
                    })), React.createElement("div", {
                  className: "pd-form-group pd-form-group__huge_padding"
                }, React.createElement("span", {
                      className: "text-semibold"
                    }, heading$p(ctx))), React.createElement(JobForm_ClientSiteSwitch.make, {
                  ctx: ctx,
                  onValueChange: onSwitch,
                  value: "site"
                }), React.createElement(FormInput.make, {
                  _type: "text",
                  value: RCore.$$Option.getOr(site.name, ""),
                  onValueChange: (function (v) {
                      updateSite({
                            name: Fun.optString(v),
                            description: site.description,
                            client: site.client
                          });
                    }),
                  id: "site-name",
                  name: "site[name]",
                  labelText: name$p(ctx),
                  maxLength: 300,
                  errors: Errors.getArray("site_name", errors),
                  wrapperClass: Js_dict.fromList({
                        hd: [
                          "site-name",
                          true
                        ],
                        tl: {
                          hd: [
                            "pd-form-group__huge_padding",
                            true
                          ],
                          tl: /* [] */0
                        }
                      })
                }), React.createElement(FormInput.make, {
                  _type: "textarea",
                  value: RCore.$$Option.getOr(site.description, ""),
                  onValueChange: (function (v) {
                      updateSite({
                            name: site.name,
                            description: Fun.optString(v),
                            client: site.client
                          });
                    }),
                  id: "site-description",
                  name: "site[description]",
                  labelText: description$p(ctx),
                  maxLength: 1000,
                  errors: [],
                  wrapperClass: Js_dict.fromList({
                        hd: [
                          "site-description",
                          true
                        ],
                        tl: {
                          hd: [
                            "pd-form-group__huge_padding",
                            true
                          ],
                          tl: /* [] */0
                        }
                      })
                }), React.createElement("div", {
                  className: "pd-form-group pd-form-group__huge_padding job-site-client_uuid"
                }, React.createElement("label", {
                      className: "pd-label",
                      htmlFor: "site-client_uuid"
                    }, organization$p(ctx)), match !== undefined ? React.createElement("span", {
                        className: "pd-form-label-action-link-container"
                      }, React.createElement("a", {
                            className: "pd-form-label-action-link",
                            href: "/clients/" + Uuid.toString(match.uuid),
                            target: "_blank"
                          }, goToClient$p(ctx), El.nbsp, React.createElement("span", {
                                className: "fa fa-external-link-alt"
                              }))) : null, React.createElement(ClientSelect.make, {
                      id: "site-client_uuid",
                      value: RCore.$$Option.map(site.client, (function (o) {
                              return {
                                      TAG: "Client",
                                      _0: {
                                        TAG: "Organization",
                                        _0: {
                                          TAG: "Edit",
                                          _0: o
                                        }
                                      }
                                    };
                            })),
                      _type: "organization",
                      onValueChange: (function (v) {
                          dispatch({
                                TAG: "Job",
                                _0: {
                                  TAG: "SetFoundSiteClient",
                                  _0: v
                                }
                              });
                        }),
                      readOnly: false
                    })), React.createElement(JobForm_AddressBlock.make, {
                  setApartment: (function (a) {
                      dispatch({
                            TAG: "Job",
                            _0: {
                              TAG: "SetApartment",
                              _0: a
                            }
                          });
                    }),
                  setFloor: (function (f) {
                      dispatch({
                            TAG: "Job",
                            _0: {
                              TAG: "SetFloor",
                              _0: f
                            }
                          });
                    }),
                  setEntranceNo: (function (e) {
                      dispatch({
                            TAG: "Job",
                            _0: {
                              TAG: "SetEntranceNo",
                              _0: e
                            }
                          });
                    }),
                  setTerritory: (function (t) {
                      dispatch({
                            TAG: "Job",
                            _0: {
                              TAG: "SetTerritoryUuid",
                              _0: t
                            }
                          });
                    }),
                  setAddressDescription: (function (a) {
                      dispatch({
                            TAG: "Job",
                            _0: {
                              TAG: "SetAddressDescription",
                              _0: a
                            }
                          });
                    }),
                  setAddress: (function (a) {
                      dispatch({
                            TAG: "Job",
                            _0: {
                              TAG: "SetAddress",
                              _0: a,
                              _1: wire
                            }
                          });
                    }),
                  inputId: inputId,
                  job: job,
                  wire: wire,
                  readOnly: false
                }), React.createElement("div", {
                  className: "pd-form-group-container__huge_padding"
                }, React.createElement(UI_ContactList.make, {
                      contacts: job.contacts,
                      addContact: (function (param) {
                          dispatch({
                                TAG: "Job",
                                _0: "AddContact"
                              });
                        }),
                      removeContact: (function (i) {
                          dispatch({
                                TAG: "Job",
                                _0: {
                                  TAG: "DeleteContact",
                                  _0: i
                                }
                              });
                        }),
                      editContact: (function (i, c) {
                          dispatch({
                                TAG: "Job",
                                _0: {
                                  TAG: "EditContact",
                                  _0: i,
                                  _1: c
                                }
                              });
                        }),
                      readOnly: false
                    })));
}

var make = JobForm_Site;

export {
  make ,
}
/* heading' Not a pure module */
