

import * as Link from "../common/Link/Link.mjs";
import * as React from "react";
import * as HeaderTypes from "./HeaderTypes.mjs";
import * as HeaderModuleCss from "/home/runner/work/planado/planado/client/rescript/components/header/Header.module.css";

var styles = HeaderModuleCss;

function HeaderLogo(Props) {
  var wire = Props.wire;
  var ctx = wire.ctx;
  var menu = HeaderTypes.Menu.make(ctx);
  var match = ctx.branding;
  var logoImage;
  var exit = 0;
  if (match !== undefined) {
    var logo = match.logo;
    if (logo !== undefined) {
      logoImage = React.createElement("span", {
            className: styles.whiteLabelLogo
          }, React.createElement("img", {
                src: logo
              }));
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    logoImage = React.createElement("span", {
          className: styles.logo
        });
  }
  if (!ctx.enabled) {
    return logoImage;
  }
  var route = HeaderTypes.Menu.defaultRoute(menu);
  if (route !== undefined) {
    return React.createElement(Link.make, {
                wire: wire,
                route: route,
                children: logoImage
              });
  } else {
    return logoImage;
  }
}

var make = HeaderLogo;

export {
  make ,
}
/* styles Not a pure module */
