import React from 'react'
import classNames from 'classnames'

const BaseFormInput = ({
  id,
  labelText = null,
  wrapperClass = {},
  labelClass = {},
  errors = null,
  horizontal = false,
  children
}) => {
  const hasErrors = errors !== null && errors.length > 0

  let errorBlock

  if (hasErrors) {
    errorBlock = (
      <div className="pd-control-error">
        {errors.map((x, idx) => (
          <div key={idx}>{x}</div>
        ))}
      </div>
    )
  } else {
    errorBlock = null
  }

  const wrapperClassNames = classNames({
    'pd-form-group': true,
    'pd-form-group_horizontal': horizontal,
    'has-error': hasErrors,
    ...wrapperClass
  })

  const labelClassNames = classNames({
    'pd-label': true,
    ...labelClass
  })

  const labelBlock =
    labelText === null ? null : (
      <label htmlFor={id} className={labelClassNames}>
        {labelText}
      </label>
    )

  return (
    <div className={wrapperClassNames}>
      {labelBlock}

      {children}

      {errorBlock}
    </div>
  )
}

export default BaseFormInput
