

import * as JsonDecode from "../libraries/JsonDecode.mjs";

function makeCustom(name) {
  return {
          TAG: "Custom",
          name: name
        };
}

function key(f) {
  if (typeof f !== "object") {
    return "default";
  } else {
    return f.name;
  }
}

var decode = JsonDecode.object(function (field) {
      if (field.required("default", JsonDecode.bool)) {
        return "Default";
      } else {
        return {
                TAG: "Custom",
                name: field.required("name", JsonDecode.string)
              };
      }
    });

var $$default = "Default";

export {
  makeCustom ,
  $$default as default,
  key ,
  decode ,
}
/* decode Not a pure module */
