

import * as El from "../../../../libraries/El.mjs";
import * as Fun from "../../../../libraries/Fun.mjs";
import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Types from "../../../../types/Types.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as DomUtils from "../../../../DomUtils.mjs";
import * as Optional from "../../../common/Optional.mjs";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Context_Types from "../../../../context/Context_Types.mjs";
import * as Schedule_AddJobPanelModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/add_job_panel/Schedule_AddJobPanel.module.css";

var withoutTemplate$p = Utils.Translations.tr("js.components.job_list.without_template");

var emptyTemplateList$p = Utils.Translations.tr("js.components.job_list.empty_template_list");

function scheduledAt$p(time, ctx) {
  return Utils.Translations.txr("js.schedule.v2.job.new")({
              time: time
            }, ctx);
}

var styles = Schedule_AddJobPanelModuleCss;

function Schedule_AddJobPanel(Props) {
  var scheduledAt = Props.scheduledAt;
  var addJob = Props.addJob;
  var close = Props.close;
  var wire = AppContext.useWire();
  var subscriptions = wire.subscriptions;
  var ctx = wire.ctx;
  var templates = ctx.templates;
  var popoverRef = Types.ReactRef.use();
  var match = React.useState(function () {
        return "";
      });
  var setQuery = match[1];
  var query = match[0];
  var scroll = function ($$event) {
    var target = $$event.target;
    var node = Types.ReactRef.toOption(popoverRef);
    if (node !== undefined && DomUtils.contains(Caml_option.valFromOption(node), target)) {
      return ;
    } else {
      return close();
    }
  };
  var onDocumentClick = function ($$event) {
    var target = $$event.target;
    var node = Types.ReactRef.toOption(popoverRef);
    if (node !== undefined && DomUtils.contains(Caml_option.valFromOption(node), target)) {
      return ;
    } else {
      return close();
    }
  };
  React.useEffect((function () {
          var unsubscribeFromClick = subscriptions.click.subscribe(onDocumentClick);
          var unsubscribeFromScroll = subscriptions.scroll.subscribe(scroll);
          return (function () {
                    unsubscribeFromClick();
                    unsubscribeFromScroll();
                  });
        }), []);
  var onQueryChange = function ($$event) {
    var value = $$event.target.value;
    setQuery(function (param) {
          return value;
        });
  };
  var scheduledAtBlock = scheduledAt !== undefined ? React.createElement("div", {
          className: styles.scheduledAt
        }, scheduledAt$p(Locale.T.fmtM("Time", wire)(Caml_option.valFromOption(scheduledAt)), ctx)) : null;
  var filterEnabled = templates.length > 7;
  var filterBlock = React.createElement(Optional.make, {
        show: filterEnabled,
        children: React.createElement("div", {
              className: styles.filterWrapper
            }, React.createElement("input", {
                  className: styles.filter,
                  value: query,
                  onChange: onQueryChange
                }), query === "" ? React.createElement("div", {
                    className: styles.filterIcon
                  }) : React.createElement("button", {
                    className: El.Cn.concat(styles.reset, "fa fa-times"),
                    type: "button",
                    onClick: (function (param) {
                        setQuery(function (param) {
                              return "";
                            });
                      })
                  }))
      });
  var filteredTemplates = RCore.$$Array.filterMap(templates, (function (template) {
          if (query.length !== 0) {
            if (Fun.stringContains(query, Context_Types.Template.name(template))) {
              return Caml_option.some(React.createElement("div", {
                              key: Uuid.toString(Context_Types.Template.uuid(template)),
                              className: styles.itemWrapper
                            }, React.createElement("button", {
                                  className: styles.item,
                                  type: "button",
                                  onClick: addJob(Caml_option.some(Context_Types.Template.uuid(template)))
                                }, Context_Types.Template.name(template))));
            } else {
              return ;
            }
          } else {
            return Caml_option.some(React.createElement("div", {
                            key: Uuid.toString(Context_Types.Template.uuid(template)),
                            className: styles.itemWrapper
                          }, React.createElement("button", {
                                className: styles.item,
                                type: "button",
                                onClick: addJob(Caml_option.some(Context_Types.Template.uuid(template)))
                              }, Context_Types.Template.name(template))));
          }
        }));
  return React.createElement("div", {
              ref: popoverRef,
              className: styles.dropdown
            }, scheduledAtBlock, filterBlock, React.createElement("div", {
                  className: styles.items
                }, Caml_obj.equal(filteredTemplates, []) ? React.createElement("div", {
                        className: styles.emptyList
                      }, emptyTemplateList$p(ctx)) : filteredTemplates, React.createElement("div", {
                      className: styles.separator
                    }), React.createElement("div", {
                      className: styles.itemWrapper
                    }, React.createElement("button", {
                          className: styles.item,
                          type: "button",
                          onClick: addJob(undefined)
                        }, withoutTemplate$p(ctx)))));
}

var make = Schedule_AddJobPanel;

export {
  make ,
}
/* withoutTemplate' Not a pure module */
