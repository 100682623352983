const formatWorker = (uuid, access) => ({
  uuid: uuid,
  access: access,
  permanent: true
})

const teamMember = (team, uuid, access, permanent) => ({
  uuid: uuid,
  access: access,
  team_uuid: team,
  permanent: permanent
})

const teams = assignees =>
  assignees.flatMap(a => {
    const nonForemanWorkers =
      a.workers.map(w => teamMember(a.uuid, w.uuid, 'view', true)).concat(
        a.temporaryWorkers.map(t => teamMember(a.uuid, t.uuid, 'view', false))
      )

    if (a.foreman === null) {
      return nonForemanWorkers
    } else {
      return nonForemanWorkers
        .concat([teamMember(a.uuid, a.foreman.uuid, 'edit', true)])
    }
  })

const workers = assignees =>
  assignees.map(a => formatWorker(a.uuid, a.access))

export const serializeAssignees = assignees =>
  teams(assignees.teams).concat(workers(assignees.workers))
