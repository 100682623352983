

import * as Fun from "../Fun.mjs";
import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as RCore from "../RCore.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as DND_Constants from "./DND_Constants.mjs";

function Make(Context) {
  var use = function (isDraggingOpt, onEnter, onLeave, acceptOpt, onDrop, param) {
    var isDragging = isDraggingOpt !== undefined ? isDraggingOpt : false;
    var accept = acceptOpt !== undefined ? acceptOpt : [];
    var droppableUuid = React.useMemo((function () {
            return Uuid.createV4();
          }), []);
    var elementRef = React.useRef(null);
    var match = Context.use();
    var dropHandlers = match.dropHandlers;
    var item = match.item;
    var subscriptions = match.subscriptions;
    var match$1 = React.useState(function () {
          return isDragging;
        });
    var setIsDragging = match$1[1];
    var isDragging$1 = match$1[0];
    React.useEffect((function () {
            var unsubscribeFromOnDragStart = subscriptions.onDragStart.subscribe(function () {
                  setIsDragging(function (param) {
                        return true;
                      });
                });
            var unsubscribeFromOnDragEnd = subscriptions.onDragEnd.subscribe(function () {
                  setIsDragging(function (param) {
                        return false;
                      });
                });
            return (function () {
                      unsubscribeFromOnDragStart();
                      unsubscribeFromOnDragEnd();
                    });
          }), []);
    React.useEffect((function () {
            var element = elementRef.current;
            if (!(element == null)) {
              element.setAttribute(DND_Constants.dataDroppableUuid, Uuid.toString(droppableUuid));
              return (function () {
                        element.removeAttribute(DND_Constants.dataDroppableUuid);
                      });
            }
            
          }), [elementRef]);
    React.useEffect((function () {
            var onDrop$1 = function (item) {
              if (accept.some(function (accept) {
                      return accept === item.payloadType;
                    })) {
                return onDrop(item);
              }
              
            };
            var dropHandler = {
              uuid: droppableUuid,
              handler: onDrop$1
            };
            dropHandlers.contents = Belt_Array.concatMany([
                  dropHandlers.contents,
                  [dropHandler]
                ]);
            return (function () {
                      dropHandlers.contents = RCore.$$Array.keep(dropHandlers.contents, (function (param) {
                              return Caml_obj.notequal(param.uuid, droppableUuid);
                            }));
                    });
          }), [onDrop]);
    React.useEffect((function () {
            var element = elementRef.current;
            if (element == null) {
              return ;
            }
            if (!isDragging$1) {
              return ;
            }
            var onMouseEnter = function (_event) {
              var match = Fun.both(onEnter, item.contents);
              if (match === undefined) {
                return ;
              }
              var payloadType = match[1].payloadType;
              if (accept.some(function (accept) {
                      return accept === payloadType;
                    })) {
                return match[0]();
              }
              
            };
            element.addEventListener("mouseenter", onMouseEnter);
            var onMouseLeave = function (_event) {
              var match = Fun.both(onLeave, item.contents);
              if (match === undefined) {
                return ;
              }
              var payloadType = match[1].payloadType;
              if (accept.some(function (accept) {
                      return accept === payloadType;
                    })) {
                return match[0]();
              }
              
            };
            element.addEventListener("mouseout", onMouseLeave);
            return (function () {
                      element.removeEventListener("mouseenter", onMouseEnter);
                      element.removeEventListener("mouseout", onMouseLeave);
                    });
          }), [
          elementRef,
          isDragging$1
        ]);
    return elementRef;
  };
  return {
          use: use
        };
}

export {
  Make ,
}
/* Uuid Not a pure module */
