

import * as RCore from "../../../../../../../libraries/RCore.mjs";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Types_Address from "../../../../../../../types/Types_Address.mjs";
import * as JobEdit_Encoder from "../../../../lib/state/api/JobEdit_Encoder.mjs";
import * as JobEdit_JobType from "../../../../lib/types/JobEdit_JobType.mjs";
import * as AddressInput_Types from "../../../../../../address_input/AddressInput_Types.mjs";
import Address_block from "planado/components/jobs/show/address/address_block";

function JobForm_AddressBlock(Props) {
  var setApartment = Props.setApartment;
  var setFloor = Props.setFloor;
  var setEntranceNo = Props.setEntranceNo;
  var setTerritory = Props.setTerritory;
  var setAddressDescription = Props.setAddressDescription;
  var setAddress = Props.setAddress;
  var inputId = Props.inputId;
  var job = Props.job;
  var wire = Props.wire;
  var readOnly = Props.readOnly;
  return React.createElement(Address_block, {
              address: Types_Address.encode(job.address),
              setApartment: (function (v) {
                  setApartment(v === null ? undefined : Caml_option.some(v));
                }),
              setFloor: (function (v) {
                  setFloor(v === null ? undefined : Caml_option.some(v));
                }),
              setEntranceNo: (function (v) {
                  setEntranceNo(v === null ? undefined : Caml_option.some(v));
                }),
              setTerritory: (function (v) {
                  setTerritory(v === null ? undefined : Caml_option.some(v));
                }),
              setAddressDescription: (function (v) {
                  setAddressDescription(v === null ? undefined : Caml_option.some(v));
                }),
              setAddress: (function (v) {
                  setAddress(Types_Address.fromInputType(AddressInput_Types.RegularAddress.makeFromJs(RCore.$$Option.getOr(v === null ? undefined : Caml_option.some(v), AddressInput_Types.RegularAddress.makeJs(AddressInput_Types.RegularAddress.empty)))));
                }),
              inputId: inputId,
              job: JobEdit_Encoder.encode(job, wire, []),
              wire: wire,
              readOnly: readOnly,
              availableTerritories: job.availableTerritories,
              possibleAssignees: job.possibleAssignees.map(JobEdit_JobType.PossibleAssignee.makeJs),
              constraints: job.addressConstraints
            });
}

var make = JobForm_AddressBlock;

export {
  make ,
}
/* react Not a pure module */
