

import * as El from "../../libraries/El.mjs";
import * as Hooks from "../../libraries/hooks/Hooks.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as ResendActivationAPI from "./ResendActivationAPI.mjs";
import * as LoginModuleCss from "/home/runner/work/planado/planado/client/rescript/components/login/Login.module.css";

var styles = LoginModuleCss;

var sendMailAgain$p = Utils.Translations.tr("js.login.send_mail_again");

var tenantNotActivated$p = Utils.Translations.txr("js.login.tenant_not_activated");

var mailSent$p = Utils.Translations.tr("js.login.mail_sent");

function ResendActivation(Props) {
  var wire = Props.wire;
  var activated = Props.activated;
  var ownerEmail = Props.ownerEmail;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return false;
      });
  var setMailSent = match[1];
  var match$1 = Hooks.useLocked(false);
  var lock = match$1[1];
  var submit = function () {
    lock();
    ResendActivationAPI.sendActivationMail(wire);
    setMailSent(function (param) {
          return true;
        });
  };
  if (ownerEmail !== undefined) {
    if (match[0]) {
      if (activated) {
        return null;
      } else {
        return React.createElement("div", {
                    className: "alert alert-info"
                  }, mailSent$p(ctx));
      }
    } else if (activated) {
      return null;
    } else {
      return React.createElement("div", {
                  className: "alert alert-info"
                }, tenantNotActivated$p({
                      email: ownerEmail
                    }, ctx), React.createElement("button", {
                      className: El.Cn.concat("btn btn-xs", styles.activationButton),
                      disabled: match$1[0],
                      type: "button",
                      onClick: (function (param) {
                          submit();
                        })
                    }, sendMailAgain$p(ctx)));
    }
  } else {
    return null;
  }
}

var make = ResendActivation;

export {
  make ,
}
/* styles Not a pure module */
