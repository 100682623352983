

import * as El from "../../../../../../../../../libraries/El.mjs";
import * as Units from "../../../../../../../../../types/Units.mjs";
import * as Schedule_Types_Job from "../../../../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as WeekTimeline_JobModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/timeline/bar/components/job/WeekTimeline_Job.module.css";

var styles = WeekTimeline_JobModuleCss;

function make(param) {
  return {
          height: Units.Px.toString(param.height.contents),
          left: Units.Px.toString(param.left),
          top: Units.Px.toString(param.top.contents),
          width: Units.Px.toString(Units.Px.max(param.width, Units.Px.fromInt(2)))
        };
}

var Style = {
  make: make
};

function byStatus(job) {
  var match = Schedule_Types_Job.AssignedJob.status(job);
  switch (match) {
    case "EnRoute" :
        return styles.enRoute;
    case "Started" :
        return styles.started;
    case "Suspended" :
        return styles.suspended;
    case "Canceled" :
        return "";
    case "Finished" :
        var match$1 = Schedule_Types_Job.AssignedJob.resolution(job);
        var className = match$1 !== undefined && match$1.successful ? styles.successful : styles.unsuccessful;
        return El.Cn.concat(styles.finished, className);
    default:
      return styles.posted;
  }
}

function byWidth(position) {
  if (Units.Px.equalOrLessThan(position.width, Units.Px.fromInt(3))) {
    return styles.compact;
  } else {
    return "";
  }
}

function get(job, position, active, draggableForAssignee) {
  return El.Cn.concat(El.Cn.concat(El.Cn.concat(El.Cn.concat(El.Cn.concat(El.Cn.concat(styles.job, byWidth(position)), byStatus(job)), position.startedOutside ? styles.startedOutside : ""), position.finishedOutside ? styles.finishedOutside : ""), active ? styles.active : ""), draggableForAssignee ? "" : styles.notDraggable);
}

var Cn = {
  byStatus: byStatus,
  byWidth: byWidth,
  get: get
};

export {
  Style ,
  Cn ,
}
/* styles Not a pure module */
