

import * as Fun from "../Fun.mjs";
import * as RCore from "../RCore.mjs";
import * as Webapi2_Base64 from "./Webapi2_Base64.mjs";
import * as Shared_Lib_String from "../../shared/lib/Shared_Lib_String.mjs";

var Data = {};

var regex = /^data:([a-z]+\/[a-z0-9-+.]+(;[a-z0-9-.!#$%*+.{}|~`]+=[a-z0-9-.!#$%*+.{}()|~`]+)*)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@\/?%\s<>]*?)$/i;

function parse(dataUrl) {
  var parts = Shared_Lib_String.matchh(dataUrl.trim(), regex);
  if (parts === undefined) {
    return ;
  }
  if (parts.length === 0) {
    return ;
  }
  var mediaType = RCore.$$Option.flatMap(parts[1], (function (part) {
          return Fun.optString(part.toLowerCase());
        }));
  var match;
  if (mediaType !== undefined) {
    var parts$1 = mediaType.split(";");
    var contentType = RCore.$$Option.flatMap(parts$1[0], Fun.optString);
    var charsetAttribute = RCore.$$Array.getBy(parts$1.slice(1), (function (attribute) {
            return attribute.startsWith("charset");
          }));
    var charset;
    if (charsetAttribute !== undefined) {
      var match$1 = charsetAttribute.split("=");
      if (match$1.length !== 2) {
        charset = undefined;
      } else {
        var match$2 = match$1[0];
        if (match$2 === "charset") {
          var charset$1 = match$1[1];
          charset = Fun.optString(charset$1);
        } else {
          charset = undefined;
        }
      }
    } else {
      charset = undefined;
    }
    match = [
      contentType,
      charset
    ];
  } else {
    match = [
      undefined,
      undefined
    ];
  }
  var part = parts[parts.length - 2 | 0];
  var isBase64 = part !== undefined ? part.includes("base64") : false;
  var data = RCore.$$Option.getOr(RCore.$$Option.flatMap(parts[parts.length - 1 | 0], Fun.optString), "");
  var data$1 = isBase64 ? ({
        TAG: "Base64",
        _0: Webapi2_Base64.fromString(data)
      }) : ({
        TAG: "Other",
        _0: data
      });
  return {
          mediaType: mediaType,
          contentType: match[0],
          charset: match[1],
          data: data$1
        };
}

var Parse = {
  Data: Data,
  regex: regex,
  parse: parse
};

export {
  Parse ,
  parse ,
}
/* No side effect */
