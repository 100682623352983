

import * as El from "../../../libraries/El.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as React from "react";
import * as Locale from "../../../libraries/Locale.mjs";
import * as MapTypes from "../MapTypes.mjs";
import * as WSChannel from "../../../libraries/WSChannel.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.mjs";
import * as MapJobsState from "./MapJobsState.mjs";
import * as MapJobsFilter from "./filter/MapJobsFilter.mjs";
import * as Shared_Lib_IO from "../../../shared/lib/Shared_Lib_IO.mjs";
import * as MapJobsStorage from "./MapJobsStorage.mjs";
import * as MapJobsScheduled from "./MapJobsScheduled.mjs";
import * as MapJobsFocusedJob from "./MapJobsFocusedJob.mjs";
import * as MapJobsInProgress from "./MapJobsInProgress.mjs";
import * as MapJobsUnscheduled from "./MapJobsUnscheduled.mjs";
import * as Shared_Lib_Reducer from "../../../shared/lib/Shared_Lib_Reducer.mjs";
import * as MapJobsRefreshControl from "./refresh_control/MapJobsRefreshControl.mjs";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";
import * as MapShowModuleCss from "/home/runner/work/planado/planado/client/rescript/components/map/MapShow.module.css";

var mapStyles = MapShowModuleCss;

function MapJobs(Props) {
  var wire = Props.wire;
  var channel = Props.channel;
  var openJob = Props.openJob;
  var unfocusJob = Props.unfocusJob;
  var updates = Props.updates;
  var engineEvents = Props.engineEvents;
  var boundingBox = Props.boundingBox;
  var ctx = wire.ctx;
  var today = React.useMemo(function () {
        return Locale.T.startOf("day", Locale.T.now(wire));
      });
  var match = Shared_Lib_Reducer.use((function (param) {
          return MapJobsState.reducer(wire, channel, today, param[0], param[1]);
        }), MapJobsState.initial(boundingBox));
  var send = match[1];
  var match$1 = match[0];
  var rendered = match$1.rendered;
  var focusedJob = match$1.focusedJob;
  var match$2 = match$1.jobs;
  var jobsByDate = match$2.jobsByDate;
  var jobs = match$2.jobs;
  var onEngineEvent = function ($$event) {
    send({
          TAG: "JobClicked",
          _0: $$event._0
        });
  };
  React.useEffect((function () {
          unfocusJob();
          var unsubscribe = engineEvents.subscribe(onEngineEvent);
          return (function () {
                    unsubscribe();
                    jobs.forEach(MapTypes.EngineChannel.removeJob(channel));
                  });
        }), [jobs]);
  React.useEffect((function () {
          var onWsUpdate = function (message) {
            var decodeUpdate = function (json) {
              var e = Json_Decode$JsonCombinators.decode(json, WSChannel.MapEvent.JobUpdate.decode);
              if (e.TAG !== "Ok") {
                return {
                        TAG: "Error",
                        _0: e._0
                      };
              }
              var match = e._0;
              var l = match.location;
              if (l === undefined) {
                return Core__Result.map(Json_Decode$JsonCombinators.decode(match.job, MapTypes.Job.decode), (function (j) {
                              return [
                                      j,
                                      undefined
                                    ];
                            }));
              }
              var match$1 = Json_Decode$JsonCombinators.decode(match.job, MapTypes.Job.decode);
              var match$2 = Json_Decode$JsonCombinators.decode(l, WSChannel.Channel.Message.$$Location.decode);
              if (match$1.TAG === "Ok") {
                if (match$2.TAG === "Ok") {
                  return {
                          TAG: "Ok",
                          _0: [
                            match$1._0,
                            match$2._0
                          ]
                        };
                } else {
                  return {
                          TAG: "Error",
                          _0: match$2._0
                        };
                }
              } else {
                return {
                        TAG: "Error",
                        _0: match$1._0
                      };
              }
            };
            var variant = message.NAME;
            if (variant === "LocationUpdate") {
              var ls = message.VAL;
              var update = Json_Decode$JsonCombinators.decode(ls, WSChannel.Channel.Message.LocationUpdate.decode);
              if (update.TAG === "Ok") {
                return send({
                            TAG: "AddLocations",
                            _0: update._0
                          });
              }
              console.log(ls);
              console.log(update._0);
              return ;
            }
            if (variant === "JobUpdated") {
              var j = message.VAL;
              var e = decodeUpdate(j);
              if (e.TAG === "Ok") {
                return send({
                            TAG: "FetchJobSuccess",
                            _0: e._0[0],
                            _1: "JobUpdated"
                          });
              }
              console.log(j);
              console.log(e._0);
              return ;
            }
            if (variant === "JobRemoved") {
              var j$1 = message.VAL;
              var e$1 = Json_Decode$JsonCombinators.decode(j$1, WSChannel.Channel.Message.JobRemoved.decode);
              if (e$1.TAG === "Ok") {
                return send({
                            TAG: "RemoveJob",
                            _0: e$1._0.uuid
                          });
              }
              console.log(j$1);
              console.log(e$1._0);
              return ;
            }
            var j$2 = message.VAL;
            var e$2 = decodeUpdate(j$2);
            if (e$2.TAG === "Ok") {
              return send({
                          TAG: "FetchJobSuccess",
                          _0: e$2._0[0],
                          _1: "JobCreated"
                        });
            }
            console.log(j$2);
            console.log(e$2._0);
          };
          return updates.subscribe(onWsUpdate);
        }), []);
  React.useEffect((function () {
          MapTypes.EngineChannel.updateContext(wire, channel);
        }), [ctx]);
  React.useEffect((function () {
          if (RCore.$$Option.isSome(boundingBox) && rendered) {
            send({
                  TAG: "SetFilter",
                  _0: {
                    TAG: "SetBoundingBox",
                    _0: boundingBox
                  }
                });
          } else if (RCore.$$Option.isSome(boundingBox)) {
            var match = ctx.user;
            if (match !== undefined) {
              Shared_Lib_IO.unsafeRunAsync(Shared_Lib_IO.tap(MapJobsStorage.get(match.uuid), (function (result) {
                          if (result !== undefined && result.useBoundingBox) {
                            return send({
                                        TAG: "EnableUseBoundingBox",
                                        _0: boundingBox
                                      });
                          } else {
                            return send({
                                        TAG: "DisableUseBoundingBox",
                                        _0: boundingBox
                                      });
                          }
                        })), (function (prim) {
                      
                    }));
            }
            
          }
          
        }), [boundingBox]);
  var focusedUuid = RCore.$$Option.map(focusedJob, (function (param) {
          return param.uuid;
        }));
  var jobsBlock = jobsByDate !== undefined ? React.createElement(React.Fragment, undefined, El.list((function (param) {
                var date = param[0];
                var key = Locale.T.isoDate(date);
                return React.createElement(MapJobsScheduled.make, {
                            wire: wire,
                            focus: (function (job) {
                                return function (_event) {
                                  send({
                                        TAG: "FocusJob",
                                        _0: job
                                      });
                                };
                              }),
                            focusedUuid: focusedUuid,
                            date: date,
                            jobs: param[1],
                            key: key
                          });
              }), jobsByDate), React.createElement(MapJobsInProgress.make, {
              wire: wire,
              focus: (function (job) {
                  return function (_event) {
                    send({
                          TAG: "FocusJob",
                          _0: job
                        });
                  };
                }),
              focusedUuid: focusedUuid,
              jobs: RCore.$$Array.keep(jobs, (function (param) {
                      var tmp;
                      switch (param.status) {
                        case "Posted" :
                        case "Scheduled" :
                            tmp = false;
                            break;
                        default:
                          tmp = true;
                      }
                      if (tmp) {
                        return RCore.$$Option.isNone(param.scheduledAt);
                      } else {
                        return false;
                      }
                    }))
            }), React.createElement(MapJobsUnscheduled.make, {
              wire: wire,
              onToggle: (function () {
                  send("ToggleUnscheduled");
                }),
              checked: match$1.showUnscheduled,
              focusedUuid: focusedUuid,
              focus: (function (job) {
                  return function (_event) {
                    send({
                          TAG: "FocusJob",
                          _0: job
                        });
                  };
                }),
              jobs: match$2.unscheduledJobs
            })) : null;
  var toggleUseBoundingBox = function () {
    send("ToggleUseBoundingBox");
  };
  return React.createElement("div", {
              className: mapStyles.jobsPanel
            }, React.createElement(MapJobsFilter.make, {
                  wire: wire,
                  fetching: match$1.fetching,
                  filter: match$1.filter,
                  setFilter: (function (f) {
                      send({
                            TAG: "SetFilter",
                            _0: f
                          });
                    }),
                  filterValues: match$1.filterValues
                }), React.createElement(MapJobsRefreshControl.make, {
                  wire: wire,
                  checked: match$1.useBoundingBox,
                  onToggle: toggleUseBoundingBox
                }), jobsBlock, React.createElement(MapJobsFocusedJob.make, {
                  wire: wire,
                  channel: channel,
                  focusedJob: focusedJob,
                  onClose: (function () {
                      send("BlurJob");
                    }),
                  onClick: openJob
                }));
}

var make = MapJobs;

export {
  make ,
}
/* mapStyles Not a pure module */
