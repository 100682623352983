

import * as RCore from "../../../../libraries/RCore.mjs";
import * as Types from "../../../../types/Types.mjs";
import * as Units from "../../../../types/Units.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function fromPageToNode(el, param) {
  var rect = el.getBoundingClientRect();
  return [
          Units.Px.minus(param[0], Units.Px.fromFloat(rect.left)),
          Units.Px.minus(param[1], Units.Px.fromFloat(rect.top))
        ];
}

function fromNodeToPage(el, param) {
  var rect = el.getBoundingClientRect();
  return [
          Units.Px.plus(param[0], Units.Px.fromFloat(rect.left)),
          Units.Px.plus(param[1], Units.Px.fromFloat(rect.top))
        ];
}

var ConvertPoint = {
  fromPageToNode: fromPageToNode,
  fromNodeToPage: fromNodeToPage
};

function contentEl(viewportEl) {
  return RCore.$$Option.flatMap(Types.ReactRef.toOption(viewportEl), (function (prim) {
                return Caml_option.nullable_to_opt(prim.firstElementChild);
              }));
}

export {
  ConvertPoint ,
  contentEl ,
}
/* Types Not a pure module */
