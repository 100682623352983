

import * as IndexJs from "planado/utils/index.js";

function camelize(prim) {
  return IndexJs.camelize(prim);
}

function camelizeString(prim) {
  return IndexJs.camelizeString(prim);
}

function decamelize(prim) {
  return IndexJs.decamelize(prim);
}

function decamelizeString(prim) {
  return IndexJs.decamelizeString(prim);
}

export {
  camelize ,
  camelizeString ,
  decamelize ,
  decamelizeString ,
}
/* planado/utils/index.js Not a pure module */
