

import * as Uuid from "../bindings/uuid/Uuid.mjs";
import * as JsonDecode from "../libraries/JsonDecode.mjs";

function uuid(territory) {
  return territory.uuid;
}

function name(territory) {
  return territory.name;
}

var decode = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

function encode(t) {
  return {
          uuid: Uuid.toString(t.uuid),
          name: t.name
        };
}

export {
  uuid ,
  name ,
  decode ,
  encode ,
}
/* decode Not a pure module */
