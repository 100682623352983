

import * as React from "react";
import * as UI_PageModuleCss from "/home/runner/work/planado/planado/client/rescript/ui/UI_Page.module.css";

var styles = UI_PageModuleCss;

function UI_Page(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: styles.contentWrapper
            }, React.createElement("div", {
                  className: styles.content
                }, children));
}

var make = UI_Page;

export {
  make ,
}
/* styles Not a pure module */
