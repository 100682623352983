

import * as El from "../../../../libraries/El.mjs";
import * as Icon from "../../../../Icon.mjs";
import * as Link from "../../../common/Link/Link.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Core__Int from "@rescript/core/lib/es6/src/Core__Int.mjs";
import * as Billing_Types from "../Billing_Types.mjs";
import * as Billing_Utils from "../Billing_Utils.mjs";
import * as BillingModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/billing/Billing.module.css";

var billing$p = Utils.Translations.tr("js.billing.title.view");

var freeOfCharge$p = Utils.Translations.txr("js.billing.free_of_charge");

var days$p = Utils.Translations.tx("js.billing.wizard.payment_method.days");

var payment$p = Utils.Translations.tr("js.billing.acknowledge.payment");

var invoiceTerms$p = Utils.Translations.txh("js.billing.acknowledge.invoice_terms");

var dueAmount$p = Utils.Translations.tr("js.billing.acknowledge.due_amount");

var forPeriod$p = Utils.Translations.tr("js.billing.acknowledge.period");

function date$p(v, w) {
  return Utils.Translations.dateFromDate(Utils.Translations.dateFromIsoStr(v), w);
}

var seats$p = Utils.Translations.txr("js.billing.licenses_no_count");

var styles = BillingModuleCss;

var choosePlan$p = Utils.Translations.tr("js.billing.wizard.choose_plan");

var chooseMethod$p = Utils.Translations.tr("js.billing.wizard.choose_method");

var payAndStart$p = Utils.Translations.tr("js.billing.wizard.pay_and_start");

function Billing_Wizard_Components$Steps(Props) {
  var current = Props.current;
  var ctx = Props.ctx;
  var step = function (s) {
    if (Billing_Types.Wizard.intFromStep(s) < Billing_Types.Wizard.intFromStep(current)) {
      return React.createElement("i", {
                  className: Icon.style(undefined, undefined, "check")
                });
    } else {
      return Billing_Types.Wizard.intFromStep(s).toString();
    }
  };
  return React.createElement("div", {
              className: styles.wizardWrapper
            }, React.createElement("ul", {
                  className: styles.wizardSteps
                }, React.createElement("li", {
                      className: "ChoosePlan" === current ? styles.active : ""
                    }, React.createElement("span", {
                          className: styles.wizardStepNumber
                        }, step("ChoosePlan")), React.createElement("span", {
                          className: styles.wizardStepCaption
                        }, choosePlan$p(ctx))), React.createElement("li", {
                      className: "ChoosePaymentMethod" === current ? styles.active : ""
                    }, React.createElement("span", {
                          className: styles.wizardStepNumber
                        }, step("ChoosePaymentMethod")), React.createElement("span", {
                          className: styles.wizardStepCaption
                        }, chooseMethod$p(ctx))), React.createElement("li", {
                      className: "Confirm" === current ? styles.active : ""
                    }, React.createElement("span", {
                          className: styles.wizardStepNumber
                        }, step("Confirm")), React.createElement("span", {
                          className: styles.wizardStepCaption
                        }, payAndStart$p(ctx)))));
}

function Billing_Wizard_Components$PageHeader(Props) {
  var ctx = Props.ctx;
  return React.createElement("div", {
              className: "page-header"
            }, React.createElement("div", {
                  className: "row"
                }, React.createElement("h1", {
                      className: "col-xs-12 col-sm-4 text-center text-left-sm"
                    }, billing$p(ctx))));
}

var PageHeader = {
  make: Billing_Wizard_Components$PageHeader
};

function Billing_Wizard_Components$Confirmation(Props) {
  var bill = Props.bill;
  var paymentMethod = Props.paymentMethod;
  var wire = Props.wire;
  var ctx = wire.ctx;
  var tmp;
  switch (paymentMethod) {
    case "Invoice" :
        tmp = React.createElement("div", {
              className: El.Cn.concat("row", styles.terms)
            }, React.createElement("div", {
                  className: "col-md-10"
                }, invoiceTerms$p({
                      days: days$p({
                            count: 5
                          }, ctx),
                      due_date: date$p(bill.due, wire)
                    }, ctx)));
        break;
    case "Card" :
    case "Crypto" :
        tmp = null;
        break;
    
  }
  return React.createElement("div", {
              className: El.Cn.concat(styles.billingBlock, styles.acknowledge)
            }, React.createElement("div", {
                  className: El.Cn.concat("row", styles.title)
                }, React.createElement("div", {
                      className: "col-md-6"
                    }, React.createElement("h4", undefined, payment$p(ctx)))), React.createElement("div", {
                  className: El.Cn.concat("row", styles.content)
                }, React.createElement("div", {
                      className: "col-md-12"
                    }, React.createElement("div", {
                          className: El.Cn.concat("row", styles.header)
                        }, React.createElement("div", {
                              className: "col-md-4"
                            }, dueAmount$p(ctx)), React.createElement("div", {
                              className: "col-md-8"
                            }, forPeriod$p(ctx)))), React.createElement("div", {
                      className: "col-md-12"
                    }, React.createElement("div", {
                          className: "row"
                        }, React.createElement("div", {
                              className: "col-md-4"
                            }, Billing_Utils.Format.money$p(bill.totalAmount)), React.createElement("div", {
                              className: "col-md-8"
                            }, date$p(bill.periodStart, wire), El.ndash, date$p(bill.periodEnd, wire))))), tmp);
}

var Confirmation = {
  make: Billing_Wizard_Components$Confirmation
};

var licensesWarning$p = Utils.Translations.txh("js.billing.wizard.licenses_warning");

var users$p = Utils.Translations.txr("js.billing.wizard.users_dative");

var userSettings$p = Utils.Translations.th("js.billing.wizard.settings_users");

function Billing_Wizard_Components$Licenses(Props) {
  var wire = Props.wire;
  var order = Props.order;
  var currentSeats = Props.currentSeats;
  var onOrderUpdate = Props.onOrderUpdate;
  var children = Props.children;
  var ctx = wire.ctx;
  var updateSeats = function ($$event) {
    var paidSeats = $$event.target.value;
    var paidSeats$1 = Core__Int.fromString(paidSeats, undefined);
    if (paidSeats$1 !== undefined) {
      return onOrderUpdate({
                  planTypeId: order.planTypeId,
                  paidSeats: paidSeats$1,
                  freeSeats: order.freeSeats,
                  monthsCycle: order.monthsCycle,
                  organizationName: order.organizationName,
                  billingAddress: order.billingAddress,
                  paymentMethod: order.paymentMethod,
                  currency: order.currency
                });
    }
    
  };
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: El.Cn.concat(styles.licenses, styles.paid)
                }, React.createElement("span", {
                      className: styles.spinner
                    }, React.createElement("input", {
                          className: "form-control",
                          autoComplete: "off",
                          min: "1",
                          type: "number",
                          value: order.paidSeats.toString(),
                          onChange: updateSeats
                        })), El.space, React.createElement("span", {
                      className: styles.word
                    }, seats$p({
                          count: order.paidSeats
                        }, ctx), order.freeSeats > 0 ? React.createElement(React.Fragment, undefined, " + ", freeOfCharge$p({
                                count: order.freeSeats
                              }, ctx)) : null, RCore.$$Option.getOr(children, null))), currentSeats > (order.paidSeats + order.freeSeats | 0) ? React.createElement("p", {
                    className: El.Cn.concat(styles.licensesWarning, styles.paid)
                  }, licensesWarning$p({
                        users: users$p({
                              count: currentSeats
                            }, ctx)
                      }, ctx), React.createElement(Link.make, {
                        wire: wire,
                        route: {
                          NAME: "User",
                          VAL: "Index"
                        },
                        children: userSettings$p(ctx)
                      })) : null);
}

var Types;

var Steps = {
  make: Billing_Wizard_Components$Steps
};

var Licenses = {
  make: Billing_Wizard_Components$Licenses
};

export {
  Types ,
  Steps ,
  PageHeader ,
  Confirmation ,
  Licenses ,
}
/* billing' Not a pure module */
