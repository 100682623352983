

import * as El from "../../libraries/El.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as ActivateFieldUserApi from "./ActivateFieldUserApi.mjs";
import * as ActivateFieldUserInput from "./ActivateFieldUserInput.mjs";
import * as ActivateFieldUserModuleCss from "/home/runner/work/planado/planado/client/rescript/components/activate_field_user/ActivateFieldUser.module.css";

var email$p = Utils.Translations.t("js.activate_field_user.email");

var mobilePhone$p = Utils.Translations.t("js.activate_field_user.mobile_phone");

var password$p = Utils.Translations.t("js.activate_field_user.password");

var passwordConfirmation$p = Utils.Translations.t("js.activate_field_user.password_confirmation");

var submit$p = Utils.Translations.tr("js.activate_field_user.submit");

var passwordMustBeFilled$p = Utils.Translations.t("js.activate_field_user.errors.password_must_be_filled");

var passwordCannotContainOnlySpaces$p = Utils.Translations.t("js.activate_field_user.errors.password_cannot_contain_only_spaces");

var mustBeFilled$p = Utils.Translations.t("js.activate_field_user.errors.must_be_filled");

var passwordsNotEqual$p = Utils.Translations.t("js.activate_field_user.errors.passwords_not_equal");

var incorrectPhone$p = Utils.Translations.t("js.activate_field_user.errors.incorrect_phone");

var registrationFinished$p = Utils.Translations.tr("js.activate_field_user.activation_finished");

var googlePlayUrl$p = Utils.Translations.t("js.activate_field_user.badge.android.app_url");

var androidBadgeUrl$p = Utils.Translations.t("js.activate_field_user.badge.android.badge_url");

var androidBadgeAlt$p = Utils.Translations.t("js.activate_field_user.badge.android.alt");

var appStoreUrl$p = Utils.Translations.t("js.activate_field_user.badge.ios.app_url");

var iosBadgeUrl$p = Utils.Translations.t("js.activate_field_user.badge.ios.badge_url");

var downloadApp$p = Utils.Translations.tr("js.activate_field_user.download_app");

var loginAfterInstall$p = Utils.Translations.tr("js.activate_field_user.login_after_install");

var userInvitationNotFound$p = Utils.Translations.tr("js.activate_field_user.user_invitation_not_found");

var contactYourDispatcher$p = Utils.Translations.tr("js.activate_field_user.contact_your_dispatcher");

var styles = ActivateFieldUserModuleCss;

var userAgent = window.navigator.userAgent.toLowerCase();

var device = Js_string.includes("iphone", userAgent) || Js_string.includes("ipad", userAgent) ? "IOS" : (
    Js_string.includes("android", userAgent) ? "Android" : "Other"
  );

function ActivateFieldUser$RegistrationSuccess(Props) {
  var ctx = Props.ctx;
  var androidBadge = React.createElement("a", {
        className: styles.androidBadge,
        href: googlePlayUrl$p(ctx)
      }, React.createElement("img", {
            alt: androidBadgeAlt$p(ctx),
            src: androidBadgeUrl$p(ctx)
          }));
  var iosBadge = React.createElement("a", {
        className: styles.iosBadge,
        href: appStoreUrl$p(ctx)
      }, React.createElement("img", {
            src: iosBadgeUrl$p(ctx)
          }));
  var tmp;
  switch (device) {
    case "Android" :
        tmp = React.createElement(React.Fragment, undefined, React.createElement("p", {
                  className: styles.download
                }, downloadApp$p(ctx)), androidBadge, React.createElement("p", {
                  className: styles.download
                }, loginAfterInstall$p(ctx)));
        break;
    case "IOS" :
        tmp = React.createElement(React.Fragment, undefined, React.createElement("p", {
                  className: styles.download
                }, downloadApp$p(ctx)), iosBadge, React.createElement("p", {
                  className: styles.download
                }, loginAfterInstall$p(ctx)));
        break;
    case "Other" :
        tmp = React.createElement(React.Fragment, undefined, React.createElement("p", {
                  className: styles.download
                }, downloadApp$p(ctx)), androidBadge, iosBadge, React.createElement("p", {
                  className: styles.download
                }, loginAfterInstall$p(ctx)));
        break;
    
  }
  return React.createElement(React.Fragment, undefined, React.createElement("h1", {
                  className: styles.registrationFinished
                }, registrationFinished$p(ctx)), tmp);
}

function ActivateFieldUser$RegistrationFailure(Props) {
  var ctx = Props.ctx;
  return React.createElement("div", undefined, React.createElement("p", undefined, userInvitationNotFound$p(ctx)), React.createElement("p", undefined, contactYourDispatcher$p(ctx)));
}

function ActivateFieldUser(Props) {
  var wire = Props.wire;
  var token = Props.token;
  var notificationMethod = Props.notificationMethod;
  var subscriptions = wire.subscriptions;
  var ctx = wire.ctx;
  var emptyErrors_email = [];
  var emptyErrors_password = [];
  var emptyErrors_passwordConfirmation = [];
  var emptyErrors_mobilePhone = [];
  var emptyErrors = {
    email: emptyErrors_email,
    password: emptyErrors_password,
    passwordConfirmation: emptyErrors_passwordConfirmation,
    mobilePhone: emptyErrors_mobilePhone
  };
  var match = React.useState(function () {
        return "Form";
      });
  var setScreen = match[1];
  var match$1 = React.useState(function () {
        return "";
      });
  var setEmail = match$1[1];
  var email = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setMobilePhone = match$2[1];
  var mobilePhone = match$2[0];
  var match$3 = React.useState(function () {
        return "";
      });
  var setPassword = match$3[1];
  var password = match$3[0];
  var match$4 = React.useState(function () {
        return "";
      });
  var setPasswordConfirmation = match$4[1];
  var passwordConfirmation = match$4[0];
  var match$5 = React.useState(function () {
        return true;
      });
  var toggleHidePassword = match$5[1];
  var passwordHidden = match$5[0];
  var match$6 = React.useState(function () {
        return emptyErrors;
      });
  var setErrors = match$6[1];
  var errors = match$6[0];
  var emailErrors = notificationMethod === "Sms" ? (
      email === "" ? [mustBeFilled$p(ctx)] : []
    ) : [];
  var mobilePhoneErrors;
  if (notificationMethod === "Email") {
    if (mobilePhone === "") {
      mobilePhoneErrors = [];
    } else {
      var digitsCount = Utils.extractDigits(mobilePhone).length;
      mobilePhoneErrors = digitsCount < 7 || digitsCount > 25 ? [incorrectPhone$p(ctx)] : [];
    }
  } else {
    mobilePhoneErrors = [];
  }
  var passwordErrors = password.length < 8 ? [passwordMustBeFilled$p(ctx)] : (
      password.trim() === "" ? [passwordCannotContainOnlySpaces$p(ctx)] : []
    );
  var passwordConfirmationErrors = passwordConfirmation === "" ? [mustBeFilled$p(ctx)] : (
      passwordConfirmation !== password ? [passwordsNotEqual$p(ctx)] : []
    );
  var formErrors = {
    email: emailErrors,
    password: passwordErrors,
    passwordConfirmation: passwordConfirmationErrors,
    mobilePhone: mobilePhoneErrors
  };
  var validateEmail = function () {
    setErrors(function (errors) {
          return {
                  email: emailErrors,
                  password: errors.password,
                  passwordConfirmation: errors.passwordConfirmation,
                  mobilePhone: errors.mobilePhone
                };
        });
  };
  var validateMobilePhone = function () {
    setErrors(function (errors) {
          return {
                  email: errors.email,
                  password: errors.password,
                  passwordConfirmation: errors.passwordConfirmation,
                  mobilePhone: mobilePhoneErrors
                };
        });
  };
  var validatePassword = function () {
    setErrors(function (errors) {
          return {
                  email: errors.email,
                  password: passwordErrors,
                  passwordConfirmation: errors.passwordConfirmation,
                  mobilePhone: errors.mobilePhone
                };
        });
  };
  var validatePasswordConfirmation = function () {
    setErrors(function (errors) {
          return {
                  email: errors.email,
                  password: errors.password,
                  passwordConfirmation: passwordConfirmationErrors,
                  mobilePhone: errors.mobilePhone
                };
        });
  };
  var handleResponse = function (res) {
    if (res.TAG === "Ok") {
      return setScreen(function (param) {
                  return "RegistrationSuccess";
                });
    }
    var match = res._0;
    if (typeof match !== "object") {
      return ;
    }
    if (match.TAG !== "HTTPError") {
      return ;
    }
    var match$1 = match._0;
    var tmp = match$1[0];
    if (typeof tmp === "object") {
      return ;
    }
    switch (tmp) {
      case "Forbidden" :
          return setScreen(function (param) {
                      return "RegistrationFailure";
                    });
      case "UnprocessableEntity" :
          var errors = ActivateFieldUserApi.Post.$$Response.UnprocessableEntity.errorsFromString(match$1[1]);
          return setErrors(function (param) {
                      return errors;
                    });
      default:
        return ;
    }
  };
  var handleSubmit = function (evt) {
    evt.preventDefault();
    if (Caml_obj.notequal(formErrors, emptyErrors)) {
      return setErrors(function (param) {
                  return formErrors;
                });
    } else {
      setErrors(function (param) {
            return emptyErrors;
          });
      return Backend.$$finally(ActivateFieldUserApi.create(wire, token, email, mobilePhone, password, passwordConfirmation, notificationMethod), handleResponse);
    }
  };
  var match$7 = React.useState(function () {
        return false;
      });
  var setRequestInProgress = match$7[1];
  var requestInProgress = match$7[0];
  React.useEffect((function () {
          return subscriptions.tracker.subscribe(function ($$event) {
                      if ($$event._0.TAG === "Started") {
                        return setRequestInProgress(function (param) {
                                    return true;
                                  });
                      } else {
                        return setRequestInProgress(function (param) {
                                    return false;
                                  });
                      }
                    });
        }), []);
  var tmp;
  switch (match[0]) {
    case "Form" :
        var tmp$1;
        if (notificationMethod === "Email") {
          var tmp$2;
          switch (device) {
            case "Android" :
                tmp$2 = "tel";
                break;
            case "IOS" :
                tmp$2 = "number";
                break;
            case "Other" :
                tmp$2 = "text";
                break;
            
          }
          tmp$1 = React.createElement(ActivateFieldUserInput.make, {
                ctx: ctx,
                id: "inputMobilePhone",
                label: mobilePhone$p(ctx),
                type_: tmp$2,
                value: mobilePhone,
                onChange: (function (string) {
                    return setMobilePhone(function (param) {
                                return string;
                              });
                  }),
                onBlur: (function () {
                    validateMobilePhone();
                  }),
                errors: errors.mobilePhone,
                limit: 50,
                autoFocus: true
              });
        } else {
          tmp$1 = React.createElement(ActivateFieldUserInput.make, {
                ctx: ctx,
                id: "inputEmail",
                label: email$p(ctx),
                type_: "email",
                value: email,
                onChange: (function (string) {
                    return setEmail(function (param) {
                                return string;
                              });
                  }),
                onBlur: (function () {
                    validateEmail();
                  }),
                errors: errors.email,
                limit: 100,
                autoFocus: true
              });
        }
        tmp = React.createElement(React.Fragment, undefined, tmp$1, React.createElement(ActivateFieldUserInput.make, {
                  ctx: ctx,
                  id: "inputPassword",
                  label: password$p(ctx),
                  type_: passwordHidden ? "password" : "text",
                  value: password,
                  onChange: (function (string) {
                      return setPassword(function (param) {
                                  return string;
                                });
                    }),
                  onBlur: (function () {
                      validatePassword();
                    }),
                  revealPasswordBtn: true,
                  onClickRevealPassword: (function () {
                      toggleHidePassword(function (v) {
                            return !v;
                          });
                    }),
                  errors: errors.password,
                  limit: 128
                }), React.createElement(ActivateFieldUserInput.make, {
                  ctx: ctx,
                  id: "inputPasswordConfirmation",
                  label: passwordConfirmation$p(ctx),
                  type_: passwordHidden ? "password" : "text",
                  value: passwordConfirmation,
                  onChange: (function (string) {
                      return setPasswordConfirmation(function (param) {
                                  return string;
                                });
                    }),
                  onBlur: (function () {
                      validatePasswordConfirmation();
                    }),
                  revealPasswordBtn: true,
                  onClickRevealPassword: (function () {
                      toggleHidePassword(function (v) {
                            return !v;
                          });
                    }),
                  errors: errors.passwordConfirmation,
                  limit: 128
                }), React.createElement("button", {
                  className: El.Cn.concat(styles.button, requestInProgress ? styles.loader : ""),
                  disabled: requestInProgress,
                  onClick: handleSubmit
                }, React.createElement("span", undefined, submit$p(ctx))));
        break;
    case "RegistrationSuccess" :
        tmp = React.createElement(ActivateFieldUser$RegistrationSuccess, {
              ctx: ctx
            });
        break;
    case "RegistrationFailure" :
        tmp = React.createElement(ActivateFieldUser$RegistrationFailure, {
              ctx: ctx
            });
        break;
    
  }
  return React.createElement("div", {
              className: styles.container
            }, React.createElement("div", {
                  className: styles.logo
                }), React.createElement("div", {
                  className: styles.form
                }, tmp));
}

var make = ActivateFieldUser;

export {
  make ,
}
/* email' Not a pure module */
