import React, { Component } from 'react'
import Spinner from './stateless.jsx'

class StatefulSpinner extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.defaultValue
    }
  }

  onValueChange = value => {
    this.setState((prevState, _props) => ({
      ...prevState,
      value
    }))
  }

  render() {
    return <Spinner {...this.props} onValueChange={this.onValueChange} />
  }
}

StatefulSpinner.defaultProps = {
  id: '',
  name: '',
  defaultValue: 1,
  step: 1,
  min: 0,
  max: 1000,
  onValueChange: () => {}
}

export default StatefulSpinner
