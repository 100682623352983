

import * as Fun from "../libraries/Fun.mjs";
import * as Uuid from "../bindings/uuid/Uuid.mjs";
import * as Locale from "../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as JsonDecode from "../libraries/JsonDecode.mjs";
import * as Types_Date from "./Types_Date.mjs";

function fromString(error) {
  switch (error) {
    case "cannot_lock" :
        return "CannotLock";
    case "timed_out" :
        return "TimedOut";
    case "too_many_errors" :
        return "TooManyErrors";
    case "too_many_jobs" :
        return "TooManyJobs";
    case "unknown_error" :
        return "UnknownError";
    default:
      return ;
  }
}

var decode = JsonDecode.fromVariant(JsonDecode.string, fromString);

function fromString$1(specificError) {
  switch (specificError) {
    case "cannot_assign_canceled" :
    case "cannot_schedule_canceled" :
        return "CantChangeCanceled";
    case "cannot_assign_en_route" :
    case "cannot_schedule_en_route" :
        return "CantChangeEnroute";
    case "cannot_assign_finished" :
    case "cannot_schedule_finished" :
        return "CantChangeFinished";
    case "cannot_assign_started" :
    case "cannot_schedule_started" :
        return "CantChangeStarted";
    case "cannot_assign_suspended" :
    case "cannot_schedule_suspended" :
        return "CantChangeSuspended";
    case "no_edit_assignee" :
        return "NoEditAssignee";
    case "no_team" :
        return "NoTeam";
    case "no_user" :
        return "NoUser";
    case "too_many_assignees" :
        return "TooManyAssignees";
    case "too_many_edit_assignees" :
        return "TooManyEditAssignees";
    case "version_changed" :
        return "VersionChanged";
    default:
      return ;
  }
}

var decode$1 = JsonDecode.fromVariant(JsonDecode.string, fromString$1);

function fromString$2(error) {
  switch (error) {
    case "cannot_lock" :
        return {
                TAG: "General",
                _0: "CannotLock"
              };
    case "timed_out" :
        return {
                TAG: "General",
                _0: "TimedOut"
              };
    case "too_many_errors" :
        return {
                TAG: "General",
                _0: "TooManyErrors"
              };
    case "too_many_jobs" :
        return {
                TAG: "General",
                _0: "TooManyJobs"
              };
    case "unknown_error" :
        return {
                TAG: "General",
                _0: "UnknownError"
              };
    default:
      return ;
  }
}

function decodeSpecific(uuid) {
  return function (json) {
    var value = fromString$1(json);
    if (value !== undefined) {
      return {
              TAG: "Specific",
              _0: uuid,
              _1: value
            };
    }
    
  };
}

var decode$2 = JsonDecode.object(function (field) {
      var v = field.required("tag", JsonDecode.string);
      switch (v) {
        case "general" :
            return {
                    TAG: "General",
                    _0: field.required("error", decode)
                  };
        case "specific" :
            return {
                    TAG: "Specific",
                    _0: field.required("entityUuid", Uuid.decode),
                    _1: field.required("error", decode$1)
                  };
        default:
          return JsonDecode.cannotDecode("Unknown tag: " + v);
      }
    });

var decode$3 = JsonDecode.object(function (field) {
      var v = field.required("type", JsonDecode.string);
      switch (v) {
        case "assign_multiple" :
            return {
                    TAG: "AssignMultiple",
                    _0: field.required("count", JsonDecode.$$int),
                    _1: field.required("newValue", JsonDecode.$$int)
                  };
        case "delete" :
            return {
                    TAG: "Delete",
                    _0: field.required("count", JsonDecode.$$int)
                  };
        case "schedule" :
            return {
                    TAG: "Schedule",
                    _0: field.required("count", JsonDecode.$$int),
                    _1: field.required("newValue", JsonDecode.option(Types_Date.decode))
                  };
        default:
          return JsonDecode.cannotDecode("Unknown type: " + v);
      }
    });

function decode$4(field) {
  var v = field.required("status", JsonDecode.string);
  switch (v) {
    case "canceled" :
        return "Canceled";
    case "fail" :
        return {
                TAG: "Fail",
                _0: field.required("errors", JsonDecode.array(decode$1))
              };
    case "not_started" :
        return "NotStarted";
    case "success" :
        return "Success";
    default:
      return JsonDecode.cannotDecode("Unknown status: " + v);
  }
}

var decode$5 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              serialNo: field.required("serialNo", JsonDecode.$$int),
              template: field.required("template", JsonDecode.option(JsonDecode.string)),
              scheduledAt: field.required("scheduledAt", JsonDecode.option(Types_Date.decode)),
              assignee: field.required("assignee", JsonDecode.option(JsonDecode.string)),
              status: decode$4(field)
            };
    });

var decode$6 = JsonDecode.object(function (field) {
      return {
              jobUuids: field.required("jobUuids", JsonDecode.array(Uuid.decode)),
              jobs: field.required("jobs", JsonDecode.array(decode$5))
            };
    });

function decode$7(field) {
  var v = field.required("type", JsonDecode.string);
  switch (v) {
    case "bulk_operation" :
        return {
                TAG: "BulkOperation",
                _0: field.required("payload", decode$3)
              };
    case "export_jobs" :
        return "ExportJobs";
    default:
      return JsonDecode.cannotDecode("Unknown type: " + v);
  }
}

function decode$8(field) {
  var v = field.required("status", JsonDecode.string);
  switch (v) {
    case "canceled" :
        return "Canceled";
    case "finished" :
        return {
                TAG: "Finished",
                _0: field.required("successful", JsonDecode.bool)
              };
    case "in_progress" :
        return "InProgress";
    case "waiting" :
        return "Waiting";
    default:
      return JsonDecode.cannotDecode("Unknown status: " + v);
  }
}

var decode$9 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              type_: decode$7(field),
              status: decode$8(field),
              fileUrl: field.required("fileUrl", JsonDecode.option(JsonDecode.string)),
              startedAt: field.required("startedAt", JsonDecode.option(Types_Date.decode)),
              finishedAt: field.required("finishedAt", JsonDecode.option(Types_Date.decode)),
              readAt: field.optional("readAt", Types_Date.decode),
              errors: field.required("errors", JsonDecode.option(JsonDecode.array(decode$2)))
            };
    });

function unread(task) {
  var match = Fun.both(task.readAt, task.finishedAt);
  if (match !== undefined) {
    return Locale.T.moreThan(match[1], match[0]);
  } else {
    return true;
  }
}

function noActiveTasks(tasks) {
  if (Caml_obj.equal(tasks, [])) {
    return false;
  } else {
    return tasks.every(function (t) {
                var match = t.status;
                if (typeof match !== "object" && match === "Canceled") {
                  return true;
                } else {
                  return false;
                }
              });
  }
}

function haveUnfinishedTasks(tasks) {
  if (Caml_obj.equal(tasks, [])) {
    return false;
  } else {
    return tasks.some(function (t) {
                var match = t.status;
                if (typeof match === "object") {
                  return false;
                }
                switch (match) {
                  case "Waiting" :
                  case "InProgress" :
                      return true;
                  case "Canceled" :
                      return false;
                  
                }
              });
  }
}

var Error_General = {};

var Error_Specific = {};

var $$Error = {
  General: Error_General,
  Specific: Error_Specific,
  fromString: fromString$2,
  decodeSpecific: decodeSpecific
};

var OperationType = {};

var BulkOperation_Job = {
  Status: {}
};

var BulkOperation = {
  Job: BulkOperation_Job,
  decode: decode$6
};

var Type = {};

var Status = {};

export {
  $$Error ,
  OperationType ,
  BulkOperation ,
  Type ,
  Status ,
  decode$9 as decode,
  unread ,
  noActiveTasks ,
  haveUnfinishedTasks ,
}
/* decode Not a pure module */
