

import * as Utils from "../../../../../utils/Utils.mjs";
import * as React from "react";
import * as AppContext from "../../../../../context/AppContext.mjs";

var reset$p = Utils.Translations.tr("js.jobs.index.filters.reset");

function JobIndexFilter_Reset(Props) {
  var onResetClick = Props.onResetClick;
  var match = AppContext.useWire();
  return React.createElement("button", {
              className: "j-filter__reset",
              type: "button",
              onClick: onResetClick
            }, reset$p(match.ctx));
}

var make = JobIndexFilter_Reset;

export {
  make ,
}
/* reset' Not a pure module */
