

import * as Note from "../../common/Note/Note.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Types from "../../../types/Types.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Billing_API from "./Billing_API.mjs";
import * as Billing_Show from "./Billing_Show.mjs";
import * as Billing_Pending from "./Billing_Pending.mjs";
import * as Billing_FakeData from "./Billing_FakeData.mjs";
import * as Billing_Wizard_Page from "./wizard/Billing_Wizard_Page.mjs";

function Billing_Page(Props) {
  var wire = Props.wire;
  var match = React.useState(function () {
        
      });
  var setData = match[1];
  var data = match[0];
  var updateData = function (f) {
    setData(function (__x) {
          return RCore.$$Option.map(__x, f);
        });
  };
  var match$1 = React.useState(function () {
        return false;
      });
  var setLoaded = match$1[1];
  var $$fetch = function (reload) {
    if (reload) {
      wire.reloadContext();
    }
    var fetched = function (response) {
      setLoaded(function (param) {
            return true;
          });
      if (response.TAG === "Ok") {
        var match = response._0;
        var currentOrder = match.currentOrder;
        var planTypes = match.planTypes;
        if (currentOrder === undefined) {
          return ;
        }
        var order = match.order;
        if (order === undefined) {
          return ;
        }
        var subscription = match.subscription;
        var currentPlan = match.currentPlan;
        var planType = RCore.$$Array.getBy(planTypes, (function (t) {
                return Types.Id.equal(t.id, order.planTypeId);
              }));
        if (planType === undefined) {
          return ;
        }
        var data_availablePlans = match.availablePlans;
        var data_subscriptionOrder = RCore.$$Option.map(subscription, (function (param) {
                return currentOrder;
              }));
        var data_subscriptionPlan = RCore.$$Option.flatMap(subscription, (function (param) {
                return currentPlan;
              }));
        var data_currentSeats = match.currentSeats;
        var data_enterprise = RCore.$$Array.getBy(planTypes, (function (param) {
                return param.name.code === "Enterprise";
              }));
        var data_free = RCore.$$Array.getBy(planTypes, (function (param) {
                return param.name.code === "Free";
              }));
        var data_unpaidBill = match.unpaidBill;
        var data_latestPayments = match.latestPayments;
        var data_canChangePlan = match.canChangePlan;
        var data_card = match.card;
        var data_availablePaymentMethods = match.availablePaymentMethods;
        var data_cryptoChillSettings = match.cryptoChillSettings;
        var data_paddleSettings = match.paddleSettings;
        var data_paddleSubscription = match.paddleSubscription;
        var data = {
          availablePlans: data_availablePlans,
          planTypes: planTypes,
          subscriptionOrder: data_subscriptionOrder,
          subscriptionPlan: data_subscriptionPlan,
          subscription: subscription,
          order: order,
          plan: currentPlan,
          currentSeats: data_currentSeats,
          enterprise: data_enterprise,
          free: data_free,
          currentPlanType: planType,
          unpaidBill: data_unpaidBill,
          latestPayments: data_latestPayments,
          canChangePlan: data_canChangePlan,
          card: data_card,
          availablePaymentMethods: data_availablePaymentMethods,
          cryptoChillSettings: data_cryptoChillSettings,
          paddleSettings: data_paddleSettings,
          paddleSubscription: data_paddleSubscription
        };
        return setData(function (param) {
                    return data;
                  });
      }
      var e = response._0;
      if (typeof e !== "object") {
        return ;
      }
      if (e.TAG !== "DecodeError") {
        return ;
      }
      console.log(e._0);
    };
    Backend.$$finally(Billing_API.Subscription.show(wire), fetched);
  };
  React.useEffect((function () {
          $$fetch(false);
        }), []);
  var tmp;
  if (data !== undefined) {
    var exit = 0;
    var bill = data.unpaidBill;
    if (bill !== undefined && bill.status === "Prepared") {
      tmp = React.createElement(Billing_Pending.make, {
            fetch: $$fetch
          });
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp = data.subscription !== undefined ? React.createElement(Billing_Show.make, {
              data: data,
              updateData: updateData,
              wire: wire,
              reload: (function () {
                  $$fetch(true);
                })
            }) : React.createElement(Billing_Wizard_Page.make, {
              data: data,
              updateData: updateData,
              reload: (function () {
                  $$fetch(true);
                }),
              onComplete: (function () {
                  $$fetch(true);
                })
            });
    }
    
  } else {
    tmp = Utils.isDevelopment(wire) && false ? React.createElement(Billing_Show.make, {
            data: Billing_FakeData.state,
            updateData: updateData,
            wire: wire,
            reload: (function () {
                $$fetch(true);
              })
          }) : (
        match$1[0] ? React.createElement(Note.make, {
                children: null,
                flavor: "info"
              }, "The trial has expired, thanks for giving Planado a try! Contact ", React.createElement("a", {
                    href: "mailto:support@planado.app"
                  }, "sales"), " if you interested in using our product.") : null
      );
  }
  return React.createElement("div", {
              id: "content-wrapper"
            }, React.createElement("div", {
                  className: "page-container narrow"
                }, tmp));
}

var make = Billing_Page;

export {
  make ,
}
/* Note Not a pure module */
