

import * as El from "../libraries/El.mjs";
import * as Qs from "qs";
import * as RCore from "../libraries/RCore.mjs";
import * as React from "react";
import * as Locale from "../libraries/Locale.mjs";
import * as $$String from "rescript/lib/es6/string.js";
import * as Context from "../context/Context.mjs";
import * as PromiseF from "../libraries/PromiseF.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as ReactDom from "react-dom";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import Md5OMatic from "md5-o-matic";
import * as Context_Lang from "../context/Context_Lang.mjs";
import * as Shared_Lib_Array from "../shared/lib/Shared_Lib_Array.mjs";
import * as Belt_Lib_SortArray from "../libraries/Belt_Lib_SortArray.mjs";
import * as IndexJs from "planado/utils/index.js";
import DigestJs from "planado/utils/digest.js";

function capitalize(input) {
  if (input === "") {
    return "";
  } else {
    return input.charAt(0).toUpperCase() + $$String.sub(input, 1, input.length - 1 | 0);
  }
}

var partial_arg = / /g;

function toHtml(html) {
  return React.createElement("span", {
              dangerouslySetInnerHTML: {
                __html: html
              }
            });
}

function t(s) {
  return function (_ctx) {
    return Context.translate(s);
  };
}

function tr(key) {
  return function (ctx) {
    return Context.translate(key);
  };
}

function tn(key) {
  return function (ctx) {
    var param = (function (_ctx) {
          return Context.translate(key);
        })(ctx);
    return Js_string.replaceByRe(partial_arg, "&nbsp;", param);
  };
}

function th(s) {
  return function (c) {
    return toHtml((function (_ctx) {
                    return Context.translate(s);
                  })(c));
  };
}

function tx(s) {
  return function (values, _ctx) {
    return Context.translateWithOptions(s, values);
  };
}

function txr(s) {
  return function (v, c) {
    return Context.translateWithOptions(s, v);
  };
}

function txh(s) {
  return function (v, c) {
    return toHtml((function (values, _ctx) {
                    return Context.translateWithOptions(s, values);
                  })(v, c));
  };
}

function dateFromIsoStr(date) {
  return new Date(date);
}

function timeFromStr(date, wire) {
  return Locale.T.fmtS("Time", wire)(date);
}

function dateFromDate(date, wire) {
  return Locale.T.fmt("Date", wire)(date);
}

function datetimeFromStr(date, wire) {
  var datetime = Locale.T.fmtS("DateTime", wire)(date);
  var match = Js_string.split(" ", datetime);
  if (match.length !== 2) {
    return "";
  }
  var date$1 = match[0];
  var time = match[1];
  return date$1 + ", " + time;
}

function fileSize(fileSizeInBytes) {
  return Context.toHumanSize(fileSizeInBytes);
}

function concat(list, ctx) {
  if (Context_Lang.rtl(ctx.localizator.lang)) {
    return RCore.$$Array.reduce(list, "", (function (a, b) {
                  return El.Cn.concat(b, a);
                }));
  } else {
    return RCore.$$Array.reduce(list, "", El.Cn.concat);
  }
}

function concatEl(list, ctx) {
  if (Context_Lang.rtl(ctx.localizator.lang)) {
    return list.toReversed();
  } else {
    return list;
  }
}

function joinWithComma(list) {
  return Shared_Lib_Array.$$String.joinWith(", ", list);
}

function scrollToCoords(param) {
  window.scrollTo({
        top: param.top,
        left: param.left,
        behavior: param.behavior
      });
}

function loadScript(url) {
  return PromiseF.fromJs(IndexJs.loadScript(url));
}

function size(e) {
  return [
          e.offsetWidth,
          e.offsetHeight
        ];
}

function extractDigits(str) {
  var regex = /[^\d]/g;
  return Js_string.replaceByRe(regex, "", str);
}

function currentFrontendVersion() {
  return Caml_option.undefined_to_opt(window.currentFrontendVersion);
}

var isIOS = (function(_) {
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
  });

var copyToClipboard = (function(el) {
    if (el) {
      el.focus();
      el.select();
      document.execCommand('copy');
    }
  });

var SplitViews = {};

function make(lang) {
  if (typeof lang === "object") {
    return lang.VAL;
  } else if (lang === "bg") {
    return "bg";
  } else if (lang === "cs") {
    return "cs";
  } else if (lang === "en") {
    return "en";
  } else if (lang === "es") {
    return "es";
  } else if (lang === "fr") {
    return "fr";
  } else if (lang === "pl") {
    return "pl";
  } else if (lang === "ro") {
    return "ro";
  } else if (lang === "ru") {
    return "ru";
  } else if (lang === "uz") {
    return "uz";
  } else if (lang === "uz_cyrl") {
    return "ru";
  } else {
    return "ar";
  }
}

function toString(x) {
  return x;
}

var Lang = {
  make: make,
  toString: toString
};

function localeCompare(left, right, lang, options) {
  return left.localeCompare(right, make(lang), options);
}

function alphabeticalOrder(left, right, lang) {
  return localeCompare(left, right, lang, {
              sensitivity: "base"
            });
}

function sortAlphabetical(list, get, wire) {
  return Belt_Lib_SortArray.stableSortBy(list, (function (a, b) {
                return alphabeticalOrder(get(a), get(b), wire.ctx.localizator.lang);
              }));
}

var CompareLocale = {
  Lang: Lang,
  localeCompare: localeCompare,
  alphabeticalOrder: alphabeticalOrder,
  sortAlphabetical: sortAlphabetical
};

function isDevelopment(wire) {
  var match = wire.ctx.env;
  return match === "development";
}

function sortFloatArray(array) {
  return Belt_Lib_SortArray.stableSortBy(array, (function (a, b) {
                if (a - b > 0) {
                  return 1;
                } else if (a - b < 0) {
                  return -1;
                } else {
                  return 0;
                }
              }));
}

var Translations = {
  toHtml: toHtml,
  t: t,
  tr: tr,
  tn: tn,
  th: th,
  tx: tx,
  txr: txr,
  txh: txh,
  dateFromIsoStr: dateFromIsoStr,
  timeFromStr: timeFromStr,
  dateFromDate: dateFromDate,
  datetimeFromStr: datetimeFromStr,
  fileSize: fileSize,
  concat: concat,
  concatEl: concatEl,
  joinWithComma: joinWithComma
};

function createPortal(prim0, prim1) {
  return ReactDom.createPortal(prim0, prim1);
}

var $$document = window.document;

var $$window$1 = window;

function createElement(prim) {
  return window.document.createElement(prim);
}

function setAttribute(prim0, prim1, prim2) {
  prim0.setAttribute(prim1, prim2);
}

function setOnload(prim0, prim1) {
  prim0.onload = prim1;
}

function appendChild(prim0, prim1) {
  prim0.appendChild(prim1);
}

function removeChild(prim0, prim1) {
  prim0.removeChild(prim1);
}

function focus(prim) {
  prim.focus();
}

function offsetWidth(prim) {
  return prim.offsetWidth;
}

function offsetHeight(prim) {
  return prim.offsetHeight;
}

function offsetTop(prim) {
  return prim.offsetTop;
}

function scrollTo(prim) {
  window.scrollTo(prim);
}

function stringLengthWithNewLines(prim) {
  return IndexJs.stringLengthWithNewLines(prim);
}

function objToJson(prim) {
  return prim;
}

function jsonToObj(prim) {
  return prim;
}

function getElementById(prim) {
  return Caml_option.nullable_to_opt(document.getElementById(prim));
}

function debounce1(prim0, prim1, prim2) {
  return IndexJs.debounce(prim0, prim1, prim2);
}

function debounce0(prim0, prim1, prim2) {
  return IndexJs.debounce(prim0, prim1, prim2);
}

function splitViews(prim) {
  return IndexJs.splitViews(prim);
}

function stringify(prim) {
  return Qs.stringify(prim);
}

function md5(prim) {
  return Md5OMatic(prim);
}

function digest(prim) {
  return DigestJs(prim);
}

export {
  capitalize ,
  Translations ,
  createPortal ,
  $$document ,
  $$window$1 as $$window,
  createElement ,
  setAttribute ,
  setOnload ,
  appendChild ,
  removeChild ,
  focus ,
  offsetWidth ,
  offsetHeight ,
  offsetTop ,
  scrollTo ,
  scrollToCoords ,
  stringLengthWithNewLines ,
  loadScript ,
  size ,
  extractDigits ,
  objToJson ,
  jsonToObj ,
  currentFrontendVersion ,
  getElementById ,
  isIOS ,
  copyToClipboard ,
  debounce1 ,
  debounce0 ,
  SplitViews ,
  splitViews ,
  stringify ,
  CompareLocale ,
  isDevelopment ,
  md5 ,
  digest ,
  sortFloatArray ,
}
/* document Not a pure module */
