

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var Template = {
  decode: decode
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var JobType = {
  decode: decode$1
};

function fromString(s) {
  if (s === "job_layout") {
    return "JobLayout";
  }
  
}

var decode$2 = JsonDecode.fromVariant(JsonDecode.string, fromString);

var StencilType = {
  fromString: fromString,
  decode: decode$2
};

var decode$3 = JsonDecode.object(function (field) {
      return {
              TAG: "Saved",
              name: field.required("name", JsonDecode.string)
            };
    });

var $$File = {
  decode: decode$3
};

function fromString$1(s) {
  switch (s) {
    case "html" :
        return "HTML";
    case "pdf" :
        return "PDF";
    default:
      return ;
  }
}

function toString(f) {
  if (f === "HTML") {
    return "html";
  } else {
    return "pdf";
  }
}

function toHumanString(f) {
  return toString(f).toUpperCase();
}

var decode$4 = JsonDecode.fromVariant(JsonDecode.string, fromString$1);

var OutputFormat = {
  fromString: fromString$1,
  toString: toString,
  toHumanString: toHumanString,
  decode: decode$4
};

var Custom = {};

var decode$5 = JsonDecode.object(function (field) {
      var uuid = field.required("uuid", Uuid.decode);
      var match = field.required("name", JsonDecode.option(JsonDecode.string));
      var match$1 = field.required("default", JsonDecode.bool);
      if (match !== undefined) {
        if (match$1) {
          return JsonDecode.cannotDecode("Stencil is invalid");
        } else {
          return {
                  TAG: "Custom",
                  _0: {
                    uuid: uuid,
                    name: match,
                    outputFormat: field.required("outputFormat", decode$4),
                    file: field.required("file", decode$3)
                  }
                };
        }
      } else if (match$1) {
        return {
                TAG: "Default",
                uuid: uuid
              };
      } else {
        return JsonDecode.cannotDecode("Stencil is invalid");
      }
    });

function file(s) {
  if (s.TAG === "Default") {
    return ;
  } else {
    return s._0.file;
  }
}

function name(s) {
  if (s.TAG === "Default") {
    return ;
  } else {
    return s._0.name;
  }
}

function uuid(s) {
  if (s.TAG === "Default") {
    return s.uuid;
  } else {
    return s._0.uuid;
  }
}

function outputFormat(s) {
  if (s.TAG === "Default") {
    return "PDF";
  } else {
    return s._0.outputFormat;
  }
}

var Stencil = {
  Custom: Custom,
  decode: decode$5,
  file: file,
  name: name,
  uuid: uuid,
  outputFormat: outputFormat
};

var Custom$1 = {};

function make(stencil, templates, jobTypes) {
  if (stencil !== undefined && stencil.TAG === "Default") {
    return {
            TAG: "Default",
            uuid: stencil.uuid
          };
  }
  return {
          TAG: "Custom",
          _0: {
            uuid: RCore.$$Option.map(stencil, uuid),
            name: RCore.$$Option.getOr(RCore.$$Option.flatMap(stencil, name), ""),
            templates: templates,
            jobTypes: jobTypes,
            file: RCore.$$Option.flatMap(stencil, file),
            domFile: undefined,
            outputFormat: RCore.$$Option.getOr(RCore.$$Option.map(stencil, outputFormat), "PDF")
          }
        };
}

var State = {
  Custom: Custom$1,
  make: make
};

var Show = {
  State: State
};

function uuid$1(s) {
  return s.uuid;
}

var decode$6 = JsonDecode.object(function (field) {
      var uuid = field.required("uuid", Uuid.decode);
      var type_ = field.required("type", decode$2);
      var filename = field.required("filename", JsonDecode.string);
      var match = field.required("name", JsonDecode.option(JsonDecode.string));
      var match$1 = field.required("default", JsonDecode.bool);
      if (match !== undefined) {
        if (match$1) {
          return JsonDecode.cannotDecode("Stencil is invalid");
        } else {
          return {
                  TAG: "Custom",
                  uuid: uuid,
                  name: match,
                  type_: type_,
                  templates: field.required("templates", JsonDecode.array(decode)),
                  jobTypes: field.required("jobTypes", JsonDecode.array(decode$1)),
                  outputFormat: field.required("outputFormat", decode$4),
                  filename: filename
                };
        }
      } else if (match$1) {
        return {
                TAG: "Default",
                uuid: uuid,
                type_: type_,
                filename: filename
              };
      } else {
        return JsonDecode.cannotDecode("Stencil is invalid");
      }
    });

var Stencil$1 = {
  uuid: uuid$1,
  decode: decode$6
};

function make$1(stencils) {
  return {
          stencils: stencils
        };
}

var decode$7 = JsonDecode.object(function (field) {
      return {
              stencils: field.required("stencils", JsonDecode.array(decode$6))
            };
    });

var State$1 = {
  make: make$1,
  decode: decode$7
};

var Index = {
  Stencil: Stencil$1,
  State: State$1
};

export {
  Template ,
  JobType ,
  StencilType ,
  $$File ,
  OutputFormat ,
  Stencil ,
  Show ,
  Index ,
}
/* decode Not a pure module */
