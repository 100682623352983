

import * as El from "../../../../../libraries/El.mjs";
import * as Icon from "../../../../../Icon.mjs";
import * as React from "react";
import * as Schedule_State from "../../../lib/state/Schedule_State.mjs";
import * as Schedule_UnscheduledModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/uscheduled_jobs/Schedule_Unscheduled.module.css";

var styles = Schedule_UnscheduledModuleCss;

function Schedule_UnscheduledButton(Props) {
  var toggleBlock = Props.toggleBlock;
  var visible = Schedule_State.useShowUnscheduledJobs();
  var buttonClassName = visible ? Icon.style(undefined, undefined, "chevron-right") : El.Cn.concat(styles.buttonIcon, Icon.style(undefined, undefined, "chevron-left"));
  return React.createElement("button", {
              className: styles.button,
              onClick: toggleBlock
            }, React.createElement("i", {
                  className: buttonClassName
                }));
}

var make = Schedule_UnscheduledButton;

export {
  make ,
}
/* styles Not a pure module */
