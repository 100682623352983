

import * as El from "../../../../../../../../libraries/El.mjs";
import * as Fun from "../../../../../../../../libraries/Fun.mjs";
import * as Types from "../../../../../../../../types/Types.mjs";
import * as Units from "../../../../../../../../types/Units.mjs";
import * as React from "react";
import * as Schedule_TimelineJob from "../../../../../../components/timeline/job/Schedule_TimelineJob.mjs";
import * as WeekTimeline_Constants from "../../../../lib/WeekTimeline_Constants.mjs";
import * as WeekTimeline_UnassignedJobDrag from "./hooks/WeekTimeline_UnassignedJobDrag.mjs";
import * as WeekTimeline_UnassignedJobContent from "./content/WeekTimeline_UnassignedJobContent.mjs";
import * as WeekTimeline_UnassignedJobListeners from "./hooks/WeekTimeline_UnassignedJobListeners.mjs";
import * as WeekTimeline_UnassignedJobAssignButton from "./assign_button/WeekTimeline_UnassignedJobAssignButton.mjs";
import * as WeekTimeline_UnassignedJobModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_timeline/components/unassigned/components/job/WeekTimeline_UnassignedJob.module.css";

var columnGap = Units.Px.fromInt(8);

function make(param) {
  return {
          height: Units.Px.toString(Units.Px.minus(WeekTimeline_Constants.unassignedRowHeight, columnGap)),
          left: Units.Px.toString(Units.Px.plus(param.left, Units.Px.divideByInt(columnGap, 2))),
          top: Units.Px.toString(param.top.contents),
          width: Units.Px.toString(Units.Px.minus(param.width, columnGap))
        };
}

var styles = WeekTimeline_UnassignedJobModuleCss;

function WeekTimeline_UnassignedJob(Props) {
  var position = Props.position;
  var job = Props.job;
  var match = WeekTimeline_UnassignedJobDrag.use(job);
  var elementRef = match[0];
  var match$1 = WeekTimeline_UnassignedJobListeners.use(elementRef, {
        TAG: "Unassigned",
        _0: job
      });
  var clickCoordinate = match$1[0];
  var match$2 = Fun.both(clickCoordinate, Types.ReactRef.toOption(elementRef));
  var previewBlock = match$2 !== undefined ? React.createElement(Schedule_TimelineJob.JobPreview.make, {
          close: match$1[1],
          element: match$2[1],
          clickCoordinate: match$2[0],
          job: {
            TAG: "Unassigned",
            _0: job
          }
        }) : null;
  var match$3 = React.useState(function () {
        return false;
      });
  var setIsActive = match$3[1];
  var toggleIsActive = React.useCallback((function () {
          setIsActive(function (isActive) {
                return !isActive;
              });
        }), []);
  var className = El.Cn.concat(styles.job, match$3[0] || match[1] || clickCoordinate !== undefined ? styles.active : "");
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: className,
                  style: make(position)
                }, React.createElement("div", {
                      ref: elementRef,
                      className: styles.inner
                    }, React.createElement(WeekTimeline_UnassignedJobContent.make, {
                          job: job
                        })), React.createElement(WeekTimeline_UnassignedJobAssignButton.make, {
                      job: job,
                      toggleIsActive: toggleIsActive
                    })), previewBlock);
}

var make$1 = React.memo(WeekTimeline_UnassignedJob);

export {
  make$1 as make,
}
/* columnGap Not a pure module */
