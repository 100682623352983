

import * as RCore from "../../../../../libraries/RCore.mjs";
import * as Units from "../../../../../types/Units.mjs";
import * as React from "react";
import * as Locale from "../../../../../libraries/Locale.mjs";
import * as Schedule_State from "../../../lib/state/Schedule_State.mjs";
import * as DayTimeline_Constants from "./DayTimeline_Constants.mjs";
import * as Schedule_Scroll_InitialPosition from "../../../components/scroll/Schedule_Scroll_InitialPosition.mjs";

function useBusinessHours() {
  var businessHours = Schedule_State.useBusinessHours();
  if (businessHours !== undefined) {
    return Locale.T.Schedule.durationToMinutes(businessHours.start);
  }
  
}

function use(wire, sizes) {
  var currentDay = Schedule_State.useCurrentDay();
  var businessHours = useBusinessHours();
  var initialPositionY = Schedule_Scroll_InitialPosition.useInitialPositionY();
  return React.useMemo((function () {
                var now = Locale.T.now(wire);
                var startOfDay = RCore.$$Option.getOr(businessHours, (DayTimeline_Constants.minutesInHour << 3));
                var startOfDay$1;
                if (Locale.T.equalDay(now, currentDay)) {
                  var nowInMinutes = Locale.T.dateToMinutes(wire, now);
                  var halfOfViewport = Units.Px.toFloat(Units.Px.divide(sizes.viewport, sizes.minute)) / 2.0;
                  startOfDay$1 = Math.max(startOfDay, nowInMinutes - (halfOfViewport | 0) | 0);
                } else {
                  startOfDay$1 = startOfDay;
                }
                var startOfDay$2 = RCore.$$Option.mapWithDefault(businessHours, startOfDay$1, (function (hours) {
                        return hours - startOfDay$1 | 0;
                      }));
                var x = Units.Px.inverse(Units.Px.min(Units.Px.multiplyWithInt(sizes.minute, startOfDay$2), Units.Px.minus(sizes.timeline, sizes.viewport)));
                return {
                        x: x,
                        y: initialPositionY
                      };
              }), []);
}

export {
  use ,
}
/* react Not a pure module */
