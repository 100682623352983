

import * as Webapi2_Base64 from "./Webapi2_Base64.mjs";

function fromBase64(base64) {
  var binaryString = Webapi2_Base64.atob(base64);
  return new Uint8Array(binaryString.length).map(function (_byte, index) {
              return binaryString.charCodeAt(index);
            });
}

function makeUint8Array(prim) {
  return new Uint8Array(prim);
}

function charCodeAt(prim0, prim1) {
  return prim0.charCodeAt(prim1);
}

export {
  makeUint8Array ,
  charCodeAt ,
  fromBase64 ,
}
/* No side effect */
