// extracted by mini-css-extract-plugin
export var button = "button-H9qDLiP0MuN2qawTqv6q";
export var buttonIcon = "button-icon-ZYyBkxmE58sdQvkcAw0z";
export var container = "container-YOMzvennhRAZWPjSlW1w";
export var content = "content-MHBKD6Ly5V4srtiTdJhs";
export var emptyJobs = "empty-jobs-AyBnLp01iO7YRcperqFT";
export var header = "header-K0GwgDtejaNkjuBS46AW";
export var hovered = "hovered-rhj58CClD6y137B3EbTg";
export var jobs = "jobs-Z8aHWeeF4AOtKKogaZFC";
export var visible = "visible-WAfPjswX3hc239ZtDEQV";
export var wrapper = "wrapper-ODGw4j2_Cu1Up_qVwPIF";