import { loadScript } from 'planado/utils/index.js'
import { stringify } from 'qs'
import { LOCALES } from '../constants'

const successApiLoaded = '_$_api_success'
const url = ({ ctx, coordorder, ...rest }) => {
  const params = {
    onload: successApiLoaded,
    lang: LOCALES[ctx.localizator.lang],
    coordorder,
    ...rest
  }

  return `//api-maps.yandex.ru/2.1/?${stringify(params)}`
}

let loadApiPromise = null
const loadApi = options => {
  if (loadApiPromise !== null) {
    return loadApiPromise
  } else {
    loadApiPromise = new Promise(resolve => {
      window[successApiLoaded] = ymaps => {
        resolve(ymaps)
        window[successApiLoaded] = null
      }

      loadScript(url(options))
    })

    return loadApiPromise
  }
}

class Ymaps {
  constructor() {
    this.api = typeof window !== undefined && window.ymaps ? window.ymaps : null
  }

  getApi() {
    return this.api
  }

  isAvailible() {
    return this.api !== null
  }

  load(options = {}) {
    return loadApi(options).then(api => (this.api = api))
  }
}

export default new Ymaps()
