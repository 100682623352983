import React from 'react'
import TextArea from 'react-textarea-autosize'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import { stringLengthWithNewLines } from 'planado/utils/index.js'

const LimitedInput = (
  { maxLength, onValueChange, value, type, disabled = false, ctx, ...rest },
  ref
) => {
  const lengthRemaining = maxLength - stringLengthWithNewLines(value)

  const onChangeCallback = evt => {
    const newValue = evt.target.value.slice(0, maxLength)

    if (newValue !== value) {
      onValueChange(newValue)
    }
  }

  let maxLengthText

  if (lengthRemaining <= maxLength / 10 && lengthRemaining > 0 && !disabled) {
    maxLengthText = (
      <span className="pd-form-control-comment">
        {ctx.t('js.components.limited_input.remaining', {
          count: maxLength,
          current: stringLengthWithNewLines(value)
        })}
      </span>
    )
  } else if (lengthRemaining === 0 && !disabled) {
    maxLengthText = (
      <span className="pd-form-control-comment">
        {ctx.t('js.components.limited_input.maxlength', { count: maxLength })}
      </span>
    )
  } else {
    maxLengthText = null
  }

  let inputComponent

  if (type === 'textarea') {
    inputComponent = (
      <TextArea
        value={value}
        onChange={onChangeCallback}
        maxLength={maxLength}
        disabled={disabled}
        {...rest}
      />
    )
  } else {
    inputComponent = (
      <input
        onChange={onChangeCallback}
        maxLength={maxLength}
        value={value}
        type={type}
        disabled={disabled}
        ref={ref}
        {...rest}
      />
    )
  }

  return (
    <div className="pd-form-limited-input-wrapper">
      {inputComponent}

      {maxLengthText}
    </div>
  )
}

export default withContext(React.forwardRef(LimitedInput))
