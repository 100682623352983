import React from 'react'

export const Popover = ({
  targetId,
  node,
  left,
  bottom,
  top,
  place = 'bottom'
}) => {
  if (targetId === null) {
    return null
  } else {
    return (
      <div
        className={`p-popover popover-for__${targetId} ${place}`}
        onClick={e => {
          e.stopPropagation()
        }}
        style={{ left, bottom, top }}
      >
        {node}
      </div>
    )
  }
}

export const getPosition = (dom, elementId, place = 'bottom', width = 315) => {
  const { left, right, top, bottom } = dom.getElementPosition(`#${elementId}`)

  switch (place) {
    case 'bottom':
      return { left: right, top: bottom }
    case 'top':
      return {
        left: left + width,
        bottom: dom.scrollHeight - top + 10
      }
    case 'left':
      return {
        left: left - 10,
        top: bottom - 20
      }
  }
}
