

import * as RCore from "./RCore.mjs";
import * as React from "react";
import * as $$String from "rescript/lib/es6/string.js";
import * as Runtime from "../Runtime.mjs";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import Classnames from "classnames";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var nbsp = React.createElement("span", {
      dangerouslySetInnerHTML: {
        __html: "&nbsp;"
      }
    });

var mdash = React.createElement("span", {
      dangerouslySetInnerHTML: {
        __html: "&mdash;"
      }
    });

var ndash = React.createElement("span", {
      dangerouslySetInnerHTML: {
        __html: "&ndash;"
      }
    });

var times = React.createElement("span", {
      dangerouslySetInnerHTML: {
        __html: "&times;"
      }
    });

function list(f, a) {
  return Core__List.toArray(Core__List.map(a, f));
}

function listi(f, a) {
  return Core__List.toArray(Core__List.mapWithIndex(a, f));
}

var dot = React.createElement("span", {
      dangerouslySetInnerHTML: {
        __html: "&middot;"
      }
    });

function stopClick(prim) {
  prim.stopPropagation();
}

function replaceClick(f) {
  return function (e) {
    e.preventDefault();
    return f();
  };
}

function cancelSubmit(prim) {
  prim.preventDefault();
}

function getStringValueFromEvent($$event) {
  var value = $$event.target.value;
  return RCore.$$Option.getOr(value, "");
}

function focusRef(moveCursorToLastCharOpt, inputRef) {
  var moveCursorToLastChar = moveCursorToLastCharOpt !== undefined ? moveCursorToLastCharOpt : false;
  RCore.$$Option.map(RCore.$$Option.map(Caml_option.nullable_to_opt(inputRef.current), (function (ref) {
              return ref;
            })), (function (node) {
          return Runtime.Timeout.defer(10, (function () {
                        node.focus();
                        if (!moveCursorToLastChar) {
                          return ;
                        }
                        var textValue = node.value;
                        var length = textValue.length;
                        node.setSelectionRange(length, length);
                      }));
        }));
}

function cons(a, b) {
  return {
          hd: a,
          tl: b
        };
}

function add(a, b, l) {
  return {
          hd: [
            a,
            b
          ],
          tl: l
        };
}

function fromList(l) {
  var __x = Core__List.map(Core__List.filter(l, (function (prim) {
              return prim[1];
            })), (function (prim) {
          return prim[0];
        }));
  return $$String.concat(" ", __x);
}

function concat(x, y) {
  return x + " " + y;
}

function concatOpt(x, y) {
  if (y !== undefined) {
    return concat(x, y);
  } else {
    return x;
  }
}

function concatIf(x, param) {
  if (param[0]) {
    return concat(x, param[1]);
  } else {
    return x;
  }
}

function concatManyOpt(x, ys) {
  return RCore.$$Array.reduce(ys, x, (function (acc, y) {
                return concatOpt(acc, y);
              }));
}

var space = " ";

function Cn_fromRecord(prim) {
  return Classnames(prim);
}

var Cn = {
  cons: cons,
  add: add,
  fromList: fromList,
  fromRecord: Cn_fromRecord,
  concat: concat,
  concatOpt: concatOpt,
  concatIf: concatIf,
  concatManyOpt: concatManyOpt
};

export {
  space ,
  nbsp ,
  mdash ,
  ndash ,
  times ,
  list ,
  listi ,
  dot ,
  stopClick ,
  replaceClick ,
  cancelSubmit ,
  getStringValueFromEvent ,
  focusRef ,
  Cn ,
}
/* nbsp Not a pure module */
