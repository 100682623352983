

import * as Fun from "../../../libraries/Fun.mjs";
import * as Icon from "../../../Icon.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Types from "../../../types/Types.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Select from "../../inputs/Select.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../../common/Optional.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as TimePicker from "../../inputs/TimePicker.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FieldErrors from "../../common/FieldError/FieldErrors.mjs";
import * as Context_Types from "../../../context/Context_Types.mjs";
import * as MultipleAssignee from "../../../types/MultipleAssignee.mjs";
import * as ShiftsShowIntervalModuleCss from "/home/runner/work/planado/planado/client/rescript/components/shifts/show/ShiftsShowInterval.module.css";

var interval$p = Utils.Translations.tr("js.shifts.show.interval");

var jobType$p = Utils.Translations.tr("js.shifts.show.job_type");

var territory$p = Utils.Translations.tr("js.shifts.show.territory");

var team$p = Utils.Translations.tr("js.shifts.show.team");

var all$p = Utils.Translations.t("js.shifts.show.all");

var noJobTypes$p = Utils.Translations.t("js.shifts.show.no_job_types");

var noTerritories$p = Utils.Translations.t("js.shifts.show.no_territories");

function missingTerritory$p(str, ctx) {
  return Utils.Translations.txr("js.shifts.show.missing_territory_name")({
              name: str
            }, ctx);
}

var styles = ShiftsShowIntervalModuleCss;

function ShiftsShowInterval(Props) {
  var interval = Props.interval;
  var jobTypes = Props.jobTypes;
  var territories = Props.territories;
  var onClickRemoveInterval = Props.onClickRemoveInterval;
  var onUpdateInterval = Props.onUpdateInterval;
  var onCloseTimePicker = Props.onCloseTimePicker;
  var workerTerritory = Props.workerTerritory;
  var isTeam = Props.isTeam;
  var territoryUuids = interval.territoryUuids;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var setTimeFrom = function (time) {
    var t = Types.Time.HoursMinutes.fromString(time);
    var timeFrom = t !== undefined ? Types.Time.HoursMinutes.roundMinutes(Caml_option.valFromOption(t), 30) : Types.Time.HoursMinutes.unsafeFromPair(0, 0);
    onUpdateInterval({
          timeFrom: timeFrom,
          timeTo: interval.timeTo,
          jobTypeUuids: interval.jobTypeUuids,
          territoryUuids: interval.territoryUuids,
          errors: interval.errors,
          teamUuid: interval.teamUuid
        });
  };
  var setTimeTo = function (time) {
    var t = Types.Time.HoursMinutes.fromString(time);
    var timeTo = t !== undefined ? Types.Time.HoursMinutes.roundMinutes(Caml_option.valFromOption(t), 30) : Types.Time.HoursMinutes.unsafeFromPair(0, 0);
    onUpdateInterval({
          timeFrom: interval.timeFrom,
          timeTo: Types.Time.HoursMinutes.to24(timeTo),
          jobTypeUuids: interval.jobTypeUuids,
          territoryUuids: interval.territoryUuids,
          errors: interval.errors,
          teamUuid: interval.teamUuid
        });
  };
  var setJobType = function (id) {
    if (id !== null) {
      return onUpdateInterval({
                  timeFrom: interval.timeFrom,
                  timeTo: interval.timeTo,
                  jobTypeUuids: [id],
                  territoryUuids: interval.territoryUuids,
                  errors: interval.errors,
                  teamUuid: interval.teamUuid
                });
    } else {
      return onUpdateInterval({
                  timeFrom: interval.timeFrom,
                  timeTo: interval.timeTo,
                  jobTypeUuids: [],
                  territoryUuids: interval.territoryUuids,
                  errors: interval.errors,
                  teamUuid: interval.teamUuid
                });
    }
  };
  var setTerritory = function (id) {
    if (id !== null) {
      return onUpdateInterval({
                  timeFrom: interval.timeFrom,
                  timeTo: interval.timeTo,
                  jobTypeUuids: interval.jobTypeUuids,
                  territoryUuids: [id],
                  errors: interval.errors,
                  teamUuid: interval.teamUuid
                });
    } else {
      return onUpdateInterval({
                  timeFrom: interval.timeFrom,
                  timeTo: interval.timeTo,
                  jobTypeUuids: interval.jobTypeUuids,
                  territoryUuids: [],
                  errors: interval.errors,
                  teamUuid: interval.teamUuid
                });
    }
  };
  var match$1 = Fun.both(workerTerritory, territoryUuids[0]);
  var territoryWarning;
  if (match$1 !== undefined) {
    var workerTerritory$1 = match$1[0];
    territoryWarning = Caml_obj.notequal(workerTerritory$1.value, match$1[1]) ? React.createElement("div", {
            className: styles.warning
          }, missingTerritory$p(workerTerritory$1.label, ctx)) : null;
  } else {
    territoryWarning = null;
  }
  var teamOptions = React.useMemo((function () {
          return MultipleAssignee.PossibleAssignee.selectTeams(ctx.allAssignees).map(function (w) {
                      return {
                              label: w.name,
                              value: w.uuid
                            };
                    });
        }), []);
  var onClickRemoveInterval$1 = function ($$event) {
    $$event.preventDefault();
    onClickRemoveInterval();
  };
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: styles.interval
                }, React.createElement("div", {
                      className: styles.timeInputs
                    }, React.createElement("p", {
                          className: styles.intervalLabel
                        }, interval$p(ctx)), React.createElement(TimePicker.make, {
                          time: Types.Time.HoursMinutes.toString(interval.timeFrom),
                          onChange: setTimeFrom,
                          secondsStep: 1800,
                          outlineIcons: true,
                          errors: [],
                          inputClass: styles.timeInput,
                          wrapperClass: styles.time,
                          onClose: onCloseTimePicker
                        }), React.createElement("div", {
                          className: styles.separator
                        }), " – ", React.createElement("div", {
                          className: styles.separator
                        }), React.createElement(TimePicker.make, {
                          time: Types.Time.HoursMinutes.toString(interval.timeTo),
                          onChange: setTimeTo,
                          secondsStep: 1800,
                          outlineIcons: true,
                          errors: [],
                          inputClass: styles.timeInput,
                          wrapperClass: styles.time,
                          onClose: onCloseTimePicker
                        })), React.createElement("div", {
                      className: styles.separator
                    }), React.createElement("div", {
                      className: styles.selectWrapper
                    }, React.createElement("p", {
                          className: styles.intervalLabel
                        }, jobType$p(ctx)), React.createElement(Select.make, {
                          placeholder: all$p(ctx),
                          onChange: setJobType,
                          value: Js_null.fromOption(RCore.$$Option.map(interval.jobTypeUuids[0], (function (prim) {
                                      return prim;
                                    }))),
                          disabled: false,
                          options: jobTypes,
                          noResultsText: noJobTypes$p(ctx)
                        })), React.createElement(Optional.make, {
                      show: Context_Types.Features.hasFlag("territories", ctx.features),
                      children: null
                    }, React.createElement("div", {
                          className: styles.separator
                        }), React.createElement("div", {
                          className: styles.selectWrapper
                        }, React.createElement("p", {
                              className: styles.intervalLabel
                            }, territory$p(ctx)), React.createElement(Select.make, {
                              placeholder: all$p(ctx),
                              onChange: setTerritory,
                              value: Js_null.fromOption(RCore.$$Option.map(territoryUuids[0], (function (prim) {
                                          return prim;
                                        }))),
                              disabled: false,
                              options: territories,
                              noResultsText: noTerritories$p(ctx)
                            }), territoryWarning)), React.createElement(Optional.make, {
                      show: !isTeam && Context_Types.Features.hasFlag("teams", ctx.features),
                      children: null
                    }, React.createElement("div", {
                          className: styles.separator
                        }), React.createElement("div", {
                          className: styles.selectWrapper
                        }, React.createElement("p", {
                              className: styles.intervalLabel
                            }, team$p(ctx)), React.createElement(Select.make, {
                              placeholder: "—",
                              onChange: (function (prim) {
                                  
                                }),
                              value: Js_null.fromOption(RCore.$$Option.map(interval.teamUuid, (function (prim) {
                                          return prim;
                                        }))),
                              disabled: true,
                              options: teamOptions
                            }))), React.createElement("div", {
                      className: styles.separator
                    }), React.createElement("button", {
                      className: styles.deleteButton,
                      disabled: false,
                      onClick: onClickRemoveInterval$1
                    }, React.createElement("span", {
                          className: Icon.style(undefined, undefined, "trash")
                        }))), React.createElement(FieldErrors.make, {
                  customClass: styles.error,
                  errors: interval.errors
                }));
}

var make = ShiftsShowInterval;

export {
  make ,
}
/* interval' Not a pure module */
