

import * as Locale from "../../../../../libraries/Locale.mjs";
import * as Schedule_Types from "../../../lib/types/Schedule_Types.mjs";
import * as Schedule_Types_Job from "../../../lib/types/job/Schedule_Types_Job.mjs";
import * as WeekCalendar_Constants from "./WeekCalendar_Constants.mjs";

var JobGroup = {};

function updateInterval(date, param) {
  var finishAt = param.finishAt;
  var startAt = param.startAt;
  if (Locale.T.equalDay(date, startAt) && Locale.T.equalDay(date, finishAt)) {
    return {
            startAt: startAt,
            finishAt: finishAt
          };
  } else if (Locale.T.equalDay(date, startAt)) {
    return {
            startAt: startAt,
            finishAt: Locale.T.endOf("day", date)
          };
  } else if (Locale.T.equalDay(date, finishAt)) {
    return {
            startAt: Locale.T.startOf("day", date),
            finishAt: finishAt
          };
  } else {
    return {
            startAt: Locale.T.startOf("day", date),
            finishAt: Locale.T.endOf("day", date)
          };
  }
}

function updateJobInterval(job, date) {
  var interval = updateInterval(date, Schedule_Types_Job.ScheduledJob.interval(job));
  return Schedule_Types_Job.ScheduledJob.$$setInterval(job, interval);
}

function make(wire, businessHours, weekDay) {
  return function (job, index) {
    var job$1 = updateJobInterval(job, weekDay);
    var start = Locale.T.dateToMinutes(wire, Schedule_Types_Job.ScheduledJob.startAt(job$1));
    var minutes = Locale.T.dateToMinutes(wire, Schedule_Types_Job.ScheduledJob.finishAt(job$1));
    var finish = minutes > WeekCalendar_Constants.minutesInDay ? WeekCalendar_Constants.minutesInDay : minutes;
    var match;
    if (businessHours !== undefined) {
      var min = Math.imul(Locale.T.Schedule.startDurationToHours(businessHours.start), 60);
      var max = Locale.T.Schedule.durationToMinutes(businessHours.finish);
      var updatedStart = start <= min ? 0 : start - min | 0;
      var updatedFinish = finish > max ? max - min | 0 : finish - min | 0;
      match = [
        updatedStart,
        updatedFinish
      ];
    } else {
      match = [
        start,
        finish
      ];
    }
    return {
            index: index,
            start: match[0],
            finish: match[1],
            left: {
              contents: 0.0
            },
            width: {
              contents: 0.0
            },
            rendered: {
              contents: false
            },
            hidden: {
              contents: false
            },
            children: {
              contents: []
            }
          };
  };
}

var Time = Schedule_Types.Time;

var ReactRef = Schedule_Types.ReactRef;

var JobCore = Schedule_Types.JobCore;

var JobInterval = Schedule_Types.JobInterval;

var ScheduledJob = Schedule_Types.ScheduledJob;

var DraggableJob = Schedule_Types.DraggableJob;

var Assignee = Schedule_Types.Assignee;

var BusinessHours = Schedule_Types.BusinessHours;

var $$Error = Schedule_Types.$$Error;

var Position = {
  updateJobInterval: updateJobInterval,
  make: make
};

export {
  Time ,
  ReactRef ,
  JobCore ,
  JobInterval ,
  ScheduledJob ,
  DraggableJob ,
  Assignee ,
  BusinessHours ,
  $$Error ,
  JobGroup ,
  Position ,
}
/* Locale Not a pure module */
