

import * as RCore from "../../../libraries/RCore.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as PrintLayoutTypes from "./PrintLayoutTypes.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              templates: field.required("templates", JsonDecode.array(PrintLayoutTypes.Template.decode)),
              jobTypes: field.required("jobTypes", JsonDecode.array(PrintLayoutTypes.JobType.decode))
            };
    });

function new_(wire) {
  return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "PrintLayout",
                    VAL: "New"
                  }
                }), decode);
}

var NewFile = {};

function fromState(state) {
  var match = state.file;
  if (match !== undefined && match.TAG === "New") {
    return {
            name: state.name,
            file: {
              name: match.name,
              body: match.body
            },
            outputFormat: state.outputFormat
          };
  }
  
}

var $$Request = {
  fromState: fromState
};

function create(wire, request) {
  return Backend.discard(Backend.post(undefined, undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "PrintLayout",
                    VAL: "Index"
                  }
                }, {
                  NAME: "Obj",
                  VAL: {
                    name: request.name,
                    output_format: PrintLayoutTypes.OutputFormat.toString(request.outputFormat),
                    file: {
                      name: request.file.name,
                      body: request.file.body
                    }
                  }
                }));
}

var Create = {
  $$Request: $$Request,
  create: create
};

function fromState$1(state) {
  var match = state.file;
  if (match !== undefined && match.TAG === "New") {
    return {
            name: state.name,
            file: {
              name: match.name,
              body: match.body
            },
            outputFormat: state.outputFormat
          };
  } else {
    return {
            name: state.name,
            file: undefined,
            outputFormat: state.outputFormat
          };
  }
}

var $$Request$1 = {
  fromState: fromState$1
};

function update(wire, uuid, request) {
  return Backend.discard(Backend.patch(undefined, true, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "PrintLayout",
                    VAL: {
                      NAME: "Edit",
                      VAL: uuid
                    }
                  }
                }, {
                  NAME: "Obj",
                  VAL: {
                    name: request.name,
                    output_format: PrintLayoutTypes.OutputFormat.toString(request.outputFormat),
                    file: RCore.$$Option.map(request.file, (function (f) {
                            return {
                                    name: f.name,
                                    body: f.body
                                  };
                          }))
                  }
                }));
}

var Update = {
  $$Request: $$Request$1,
  update: update
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              templates: field.required("templates", JsonDecode.array(PrintLayoutTypes.Template.decode)),
              jobTypes: field.required("jobTypes", JsonDecode.array(PrintLayoutTypes.JobType.decode)),
              stencil: field.required("stencil", PrintLayoutTypes.Stencil.decode)
            };
    });

function show(wire, uuid) {
  return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "PrintLayout",
                    VAL: {
                      NAME: "Edit",
                      VAL: uuid
                    }
                  }
                }), decode$1);
}

var decode$2 = JsonDecode.object(function (field) {
      return {
              stencils: field.required("stencils", JsonDecode.array(PrintLayoutTypes.Index.Stencil.decode))
            };
    });

function index(wire) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "PrintLayout",
                    VAL: "Index"
                  }
                }), decode$2);
}

function $$delete(wire, uuid) {
  return Backend.$$delete(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "PrintLayout",
                VAL: {
                  NAME: "Edit",
                  VAL: uuid
                }
              }
            });
}

var Delete = {
  $$delete: $$delete
};

var New_Response = {};

var New = {
  $$Response: New_Response,
  new_: new_
};

var Show_Response = {};

var Show = {
  $$Response: Show_Response,
  show: show
};

var Index_Response = {};

var Index = {
  $$Response: Index_Response,
  index: index
};

var Errors;

export {
  New ,
  NewFile ,
  Create ,
  Update ,
  Show ,
  Index ,
  Delete ,
  Errors ,
}
/* decode Not a pure module */
