

import * as Hooks from "../../libraries/hooks/Hooks.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Router from "../../Router.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as FormInput from "../inputs/FormInput.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Backend_Errors from "../../libraries/backend/Backend_Errors.mjs";
import * as ResetPasswordAPI from "./ResetPasswordAPI.mjs";
import * as ResetPasswordModuleCss from "/home/runner/work/planado/planado/client/rescript/components/reset_password/ResetPassword.module.css";

var styles = ResetPasswordModuleCss;

var title$p = Utils.Translations.tr("js.reset_password.title");

var submit$p = Utils.Translations.tr("js.reset_password.submit");

var password$p = Utils.Translations.t("js.reset_password.password");

var passwordConfirmation$p = Utils.Translations.t("js.reset_password.password_confirmation");

function ResetPassword(Props) {
  var wire = Props.wire;
  var token = Props.token;
  var navigate = wire.navigate;
  var ctx = wire.ctx;
  var goToLogin = function ($$event) {
    $$event.preventDefault();
    navigate(undefined, "Login");
  };
  var match = React.useState(function () {
        return "";
      });
  var setPassword = match[1];
  var password = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setPasswordConfirmation = match$1[1];
  var passwordConfirmation = match$1[0];
  var match$2 = React.useState(function () {
        return Backend_Errors.empty;
      });
  var setErrors = match$2[1];
  var errors = match$2[0];
  var match$3 = Hooks.useLocked(false);
  var unlock = match$3[2];
  var lock = match$3[1];
  var locked = match$3[0];
  var onChangePassword = function (pwd) {
    setPassword(function (param) {
          return pwd;
        });
  };
  var onChangePasswordConfirmation = function (pwd) {
    setPasswordConfirmation(function (param) {
          return pwd;
        });
  };
  var complete = function (data) {
    if (data.TAG === "Ok") {
      return Router.redirectToRoot(wire);
    }
    var e = data._0;
    if (typeof e !== "object") {
      console.log(e);
      return ;
    }
    if (e.TAG === "ValidationError") {
      var errors = e._0;
      unlock();
      return setErrors(function (param) {
                  return errors;
                });
    }
    console.log(e);
  };
  var submit = function () {
    lock();
    Backend.$$finally(Backend.discard(ResetPasswordAPI.reset(wire, {
                  resetToken: token,
                  password: password,
                  passwordConfirmation: passwordConfirmation
                })), complete);
  };
  var handleSubmit = function ($$event) {
    $$event.preventDefault();
    submit();
  };
  var match$4 = ctx.branding;
  var tmp;
  var exit = 0;
  if (match$4 !== undefined) {
    var logo = match$4.logo;
    if (logo !== undefined) {
      tmp = React.createElement("span", {
            className: styles.whiteLabelLogo,
            onClick: goToLogin
          }, React.createElement("img", {
                src: logo
              }));
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement("span", {
          className: styles.logo,
          onClick: goToLogin
        });
  }
  var errors$1 = Backend_Errors.get(errors, "token");
  var tmp$1 = {
    _type: "password",
    value: password,
    onValueChange: onChangePassword,
    disabled: locked,
    labelText: password$p(ctx)
  };
  var tmp$2 = Backend_Errors.get(errors, "password");
  if (tmp$2 !== undefined) {
    tmp$1.errors = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = {
    _type: "password",
    value: passwordConfirmation,
    onValueChange: onChangePasswordConfirmation,
    disabled: locked,
    labelText: passwordConfirmation$p(ctx)
  };
  var tmp$4 = Backend_Errors.get(errors, "password_confirmation");
  if (tmp$4 !== undefined) {
    tmp$3.errors = Caml_option.valFromOption(tmp$4);
  }
  return React.createElement("div", {
              className: styles.wrapper
            }, React.createElement("div", {
                  className: styles.header
                }, tmp), React.createElement("div", {
                  className: styles.form
                }, React.createElement("form", {
                      className: "panel",
                      onSubmit: handleSubmit
                    }, React.createElement("div", {
                          className: "panel-heading"
                        }, React.createElement("span", {
                              className: "panel-title"
                            }, title$p(ctx))), React.createElement("div", {
                          className: "panel-body"
                        }, errors$1 !== undefined ? errors$1.map(function (msg, i) {
                                return React.createElement("div", {
                                            key: i.toString(),
                                            className: "alert alert-danger"
                                          }, msg);
                              }) : null, React.createElement(FormInput.make, tmp$1), React.createElement(FormInput.make, tmp$3)), React.createElement("div", {
                          className: "panel-footer footer"
                        }, React.createElement("button", {
                              className: "btn btn-primary btn",
                              type: "submit"
                            }, submit$p(ctx))))));
}

var make = ResetPassword;

export {
  make ,
}
/* styles Not a pure module */
