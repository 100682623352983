

import * as Hooks from "../../../../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as React from "react";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Schedule_API from "../../../../lib/state/api/Schedule_API.mjs";
import * as Shared_Lib_IO from "../../../../../../shared/lib/Shared_Lib_IO.mjs";
import * as Schedule_Header_FilterBySkills from "./Schedule_Header_FilterBySkills.mjs";
import * as Schedule_Header_FilterByTerritory from "./Schedule_Header_FilterByTerritory.mjs";

function Schedule_Header_Filter(Props) {
  var wire = AppContext.useWire();
  var match = React.useState(function () {
        
      });
  var setVariants = match[1];
  var variants = match[0];
  Hooks.useOnMount(function () {
        Shared_Lib_IO.unsafeRunAsync(Shared_Lib_IO.tap(Schedule_API.FilterVariants.$$fetch(wire), (function (result) {
                    if (result.TAG !== "Ok") {
                      return ;
                    }
                    var variants = result._0;
                    setVariants(function (param) {
                          return variants;
                        });
                  })), (function (prim) {
                
              }));
      });
  var territoriesFilterVariants = RCore.$$Option.mapWithDefault(variants, [], (function (v) {
          return v.territories;
        }));
  var skillsFilterVariants = RCore.$$Option.mapWithDefault(variants, [], (function (v) {
          return v.skills;
        }));
  return React.createElement(React.Fragment, undefined, React.createElement(Schedule_Header_FilterBySkills.make, {
                  filterVariants: skillsFilterVariants,
                  wire: wire
                }), React.createElement(Schedule_Header_FilterByTerritory.make, {
                  filterVariants: territoriesFilterVariants.map(function (v) {
                        return {
                                uuid: v.uuid,
                                name: v.name
                              };
                      }),
                  wire: wire
                }));
}

var make = Schedule_Header_Filter;

export {
  make ,
}
/* Hooks Not a pure module */
