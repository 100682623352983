import { createStore } from 'planado/stores/utils'
import reducer from 'planado/reducers/admin/resolutions'

const createResolutionStore = ({ resolutions }) =>
  createStore(reducer, {
    formDisabled: false,
    resolutions: resolutions.map(r => ({ ...r, destroy: false }))
  })

export default createResolutionStore
