

import * as El from "../../libraries/El.mjs";
import * as Login from "../login/Login.mjs";
import * as React from "react";
import * as Errors from "../../Errors.mjs";
import * as Router from "../../Router.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as SignInByTokenAPI from "./SignInByTokenAPI.mjs";
import * as SignInByTokenModuleCss from "/home/runner/work/planado/planado/client/rescript/components/sign_in_by_token/SignInByToken.module.css";

var styles = SignInByTokenModuleCss;

function SignInByToken(Props) {
  var wire = Props.wire;
  var token = Props.token;
  var redirect = Props.redirect;
  var match = React.useState(function () {
        return /* [] */0;
      });
  var setErrors = match[1];
  var showed = function (data) {
    if (data.TAG === "Ok") {
      var url = data._0;
      if (url !== undefined) {
        return Router.redirect(url);
      } else {
        return Router.redirectToLogin();
      }
    }
    var e = data._0;
    if (typeof e !== "object") {
      console.log(e);
      return ;
    }
    if (e.TAG === "HTTPError") {
      var match = e._0;
      var tmp = match[0];
      if (typeof tmp !== "object") {
        if (tmp === "UnprocessableEntity") {
          var text = match[1];
          return setErrors(function (param) {
                      return Errors.parse(text);
                    });
        }
        console.log(e);
        return ;
      } else {
        console.log(e);
        return ;
      }
    } else {
      console.log(e);
      return ;
    }
  };
  React.useEffect((function () {
          Backend.$$finally(SignInByTokenAPI.create(wire, token, redirect), showed);
        }), []);
  var errors = Errors.getMany({
        hd: "token",
        tl: /* [] */0
      }, match[0]);
  if (errors !== undefined) {
    return React.createElement(React.Fragment, undefined, El.listi((function (msg, i) {
                      return React.createElement("div", {
                                  key: i.toString(),
                                  className: styles.alert
                                }, msg);
                    }), errors), React.createElement(Login.make, {
                    wire: wire
                  }));
  } else {
    return null;
  }
}

var make = SignInByToken;

export {
  make ,
}
/* styles Not a pure module */
