

import * as Case from "../../utils/Case.mjs";
import * as RCore from "../RCore.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as JsonDecode from "../JsonDecode.mjs";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";

function make(json) {
  return Case.camelize(json);
}

var empty = Case.camelize({});

function json(errors) {
  return errors;
}

function get(errors, path) {
  var segments_1 = Core__List.fromArray(Js_string.split(".", path));
  var segments = {
    hd: "errors",
    tl: segments_1
  };
  var loop = function (field, segments) {
    if (!segments) {
      return ;
    }
    var tail = segments.tl;
    var last = segments.hd;
    if (tail) {
      return field.required(last, JsonDecode.object(function (f) {
                      return loop(f, tail);
                    }));
    } else {
      return field.required(last, JsonDecode.array(JsonDecode.string));
    }
  };
  var decode = JsonDecode.object(function (field) {
        return loop(field, segments);
      });
  var x = Json_Decode$JsonCombinators.decode(errors, decode);
  if (x.TAG === "Ok") {
    return x._0;
  }
  
}

function getArray(errors, path) {
  return RCore.$$Option.getOr(get(errors, path), []);
}

function getFirst(errors, path) {
  return RCore.$$Option.flatMap(get(errors, path), (function (xs) {
                return xs[0];
              }));
}

function getMany(errors, paths) {
  return RCore.$$Array.reduce(paths.map(function (p) {
                  return get(errors, p);
                }), undefined, (function (x, y) {
                if (x !== undefined) {
                  if (y !== undefined) {
                    return Belt_Array.concatMany([
                                x,
                                y
                              ]);
                  } else {
                    return x;
                  }
                } else if (y !== undefined) {
                  return y;
                } else {
                  return ;
                }
              }));
}

function allErrorKeys(errors) {
  return RCore.$$Array.keep(RCore.$$Option.getOr(RCore.$$Option.map(RCore.$$Option.flatMap(RCore.$$Option.flatMap(Js_json.decodeObject(Case.camelize(errors)), (function (x) {
                                return Js_dict.get(x, "errors");
                              })), Js_json.decodeObject), (function (d) {
                        return Object.keys(d);
                      })), []), (function (k) {
                return k !== "message";
              }));
}

export {
  make ,
  empty ,
  json ,
  get ,
  getArray ,
  getFirst ,
  getMany ,
  allErrorKeys ,
}
/* empty Not a pure module */
