

import * as El from "../../../../../../libraries/El.mjs";
import * as Fun from "../../../../../../libraries/Fun.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../types/Types.mjs";
import * as React from "react";
import * as Schedule_Types_Job from "../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as WeekCalendar_Utils from "../../lib/WeekCalendar_Utils.mjs";
import * as WeekCalendar_JobDrag from "./hooks/WeekCalendar_JobDrag.mjs";
import * as WeekCalendar_JobStatus from "./components/WeekCalendar_JobStatus.mjs";
import * as WeekCalendar_JobContent from "./components/WeekCalendar_JobContent.mjs";
import * as WeekCalendar_JobPreview from "./components/WeekCalendar_JobPreview.mjs";
import * as WeekCalendar_JobListeners from "./hooks/WeekCalendar_JobListeners.mjs";
import * as WeekCalendar_JobModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/views/week_calendar/components/job/WeekCalendar_Job.module.css";

var styles = WeekCalendar_JobModuleCss;

function WeekCalendar_Job(Props) {
  var position = Props.position;
  var job = Props.job;
  var match = WeekCalendar_JobDrag.use(job);
  var dragRef = match[0];
  var match$1 = WeekCalendar_JobListeners.use(dragRef, job);
  var match$2 = match$1[1];
  var clickCoordinate = match$2[0];
  var match$3 = Fun.both(clickCoordinate, Types.ReactRef.toOption(dragRef));
  var previewBlock = match$3 !== undefined ? React.createElement(WeekCalendar_JobPreview.make, {
          close: match$2[1],
          element: match$3[1],
          clickCoordinate: match$3[0],
          job: job
        }) : null;
  var className = El.Cn.concat(El.Cn.concat(El.Cn.concat(El.Cn.concat(El.Cn.concat(El.Cn.concat(styles.job, Schedule_Types_Job.ScheduledJob.isEnRoute(job) ? styles.enRoute : ""), Schedule_Types_Job.ScheduledJob.isStarted(job) ? styles.started : ""), Schedule_Types_Job.ScheduledJob.isFinished(job) ? styles.finished : ""), Schedule_Types_Job.ScheduledJob.isUnassigned(job) ? styles.notPublished : ""), match$1[0] || RCore.$$Option.isSome(clickCoordinate) ? styles.hovered : ""), match[1] || RCore.$$Option.isSome(clickCoordinate) ? styles.active : "");
  var match$4 = WeekCalendar_Utils.JobStyle.make(position);
  var compact = match$4[1].heightPx < 25.0;
  return React.createElement("div", {
              ref: dragRef,
              className: className,
              style: match$4[0]
            }, React.createElement(WeekCalendar_JobContent.make, {
                  compact: compact,
                  job: job
                }), React.createElement(WeekCalendar_JobStatus.make, {
                  job: job
                }), previewBlock);
}

var make = WeekCalendar_Job;

export {
  make ,
}
/* styles Not a pure module */
