

import * as El from "../../../../../libraries/El.mjs";
import * as Utils from "../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../../../common/Optional.mjs";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as Schedule_TimelineUnassignedHeaderModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/timeline/unassigned_header/Schedule_TimelineUnassignedHeader.module.css";

var styles = Schedule_TimelineUnassignedHeaderModuleCss;

var unassigned$p = Utils.Translations.tr("js.schedule.v2.jobs.unassigned");

function Schedule_TimelineUnassignedHeader(Props) {
  var compactMode = Props.compactMode;
  var toggleMode = Props.toggleMode;
  var jobsCount = Props.jobsCount;
  var match = AppContext.useWire();
  var onClick = React.useCallback((function (_event) {
          toggleMode();
        }), [toggleMode]);
  var chevronClassName = El.Cn.concat(El.Cn.concat("fa", compactMode ? "fa-chevron-up" : "fa-chevron-down"), styles.icon);
  var countClassName = El.Cn.concat(styles.count, compactMode ? styles.countActive : "");
  return React.createElement("div", {
              className: styles.header,
              onClick: onClick
            }, React.createElement("span", {
                  className: chevronClassName
                }), unassigned$p(match.ctx), React.createElement(Optional.make, {
                  show: jobsCount > 0,
                  children: React.createElement("span", {
                        className: countClassName
                      }, jobsCount.toString())
                }));
}

var make = React.memo(Schedule_TimelineUnassignedHeader);

export {
  make ,
}
/* styles Not a pure module */
