

import * as Job_typesJs from "planado/containers/admin/job_types.js";

var make = Job_typesJs.SaveButtonLink;

export {
  make ,
}
/* make Not a pure module */
