

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Types from "../../../types/Types.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as Types_Date from "../../../types/Types_Date.mjs";
import * as Types_Address from "../../../types/Types_Address.mjs";
import * as Types_Contact from "../../../types/Types_Contact.mjs";
import * as Types_Territory from "../../../types/Types_Territory.mjs";

function fieldToString(field) {
  return RCore.$$Option.getOr(field, "");
}

function toString(key) {
  switch (key.TAG) {
    case "ExternalId" :
        return "externalId";
    case "Name" :
        return "name";
    case "Description" :
        return "description";
    case "Apartment" :
        return "apartment";
    case "Floor" :
        return "floor";
    case "EntranceNo" :
        return "entranceNo";
    case "AddressDescription" :
        return "addressDescription";
    
  }
}

function fromString(param) {
  var text = param[1];
  switch (param[0]) {
    case "addressDescription" :
        return {
                TAG: "AddressDescription",
                _0: text
              };
    case "apartment" :
        return {
                TAG: "Apartment",
                _0: text
              };
    case "description" :
        return {
                TAG: "Description",
                _0: text
              };
    case "entranceNo" :
        return {
                TAG: "EntranceNo",
                _0: text
              };
    case "externalId" :
        return {
                TAG: "ExternalId",
                _0: text
              };
    case "floor" :
        return {
                TAG: "Floor",
                _0: text
              };
    case "name" :
        return {
                TAG: "Name",
                _0: text
              };
    default:
      return ;
  }
}

function message(errors, key) {
  return RCore.$$Option.getOr(RCore.$$Option.map(RCore.$$Array.getBy(errors, (function (e) {
                        return toString(e) === key;
                      })), (function (error) {
                    return [error._0];
                  })), []);
}

var decode = JsonDecode.object(function (field) {
      return {
              userId: field.required("userId", Types.Id.decodeOpaque),
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var decode$1 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", JsonDecode.option(Uuid.decode)),
              isNewRecord: field.required("isNewRecord", JsonDecode.bool),
              name: field.required("name", JsonDecode.option(JsonDecode.string)),
              description: field.required("description", JsonDecode.option(JsonDecode.string)),
              externalId: field.required("externalId", JsonDecode.option(JsonDecode.string)),
              showingExternalId: field.required("showingExternalId", JsonDecode.bool),
              contacts: field.required("contacts", JsonDecode.array(Types_Contact.decode)),
              floor: field.required("floor", JsonDecode.option(JsonDecode.string)),
              apartment: field.required("apartment", JsonDecode.option(JsonDecode.string)),
              addressDescription: field.required("addressDescription", JsonDecode.option(JsonDecode.string)),
              entranceNo: field.required("entranceNo", JsonDecode.option(JsonDecode.string)),
              clientUuid: field.required("clientUuid", JsonDecode.option(Uuid.decode)),
              clientName: field.required("clientName", JsonDecode.option(JsonDecode.string)),
              territoryUuid: field.required("territoryUuid", JsonDecode.option(Uuid.decode)),
              availableTerritories: field.required("availableTerritories", JsonDecode.array(Types_Territory.decode)),
              createdAt: field.required("createdAt", JsonDecode.option(Types_Date.decode)),
              address: field.required("address", JsonDecode.option(Types_Address.decode)),
              creator: field.required("creator", JsonDecode.option(decode))
            };
    });

var Site = {
  decode: decode$1
};

var $$Error = {
  fromString: fromString,
  message: message
};

var Creator = {};

export {
  fieldToString ,
  $$Error ,
  Creator ,
  Site ,
}
/* decode Not a pure module */
