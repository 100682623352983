/* eslint-disable no-undef */
import { loadScript } from 'planado/utils/index.js'
import { getAccuracyColor, hasLocation } from 'planado/utils/map'
import RichMarker, { RichMarkerPosition } from './google/richmarker'
import { gmapsPromise } from 'planado/globals'
import classNames from 'classnames'
import { scheduledTime } from 'planado/utils/time/index.js'

const url = (ctx, apiKey) => {
  const lang = ctx.localizator.lang
  return `//maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&language=${lang}`
}

const latlng = ({ longitude, latitude }) =>
  new google.maps.LatLng(latitude, longitude)

function processCommand(type, command, engine) {
  switch (type) {
    case 'ADD_LOCATION':
      engine.addLocation(command.location)
      break
    case 'REMOVE_LOCATION':
      engine.removeLocation(command.location)
      break
    case 'HIGHLIGHT_LOCATION':
      engine.highlightLocation(command.location, command.measurementSystem)
      break
    case 'REMOVE_LOCATION_HIGHLIGHT':
      engine.removeLocationHighlight(command.location)
      break
    case 'ADD_WORKER_JOB':
      engine.addWorkerJob(command.job)
      break
    case 'REMOVE_WORKER_JOB':
      engine.removeWorkerJob(command.job)
      break
    case 'ADD_JOB':
      engine.addJob(command.job, command.options)
      break
    case 'REMOVE_JOB':
      engine.removeJob(command.job)
      break
    case 'ADD_WORKER':
      engine.addWorker(command.worker, command.location)
      break
    case 'REMOVE_WORKER':
      engine.removeWorker(command.worker, command.location)
      break
    case 'LOCATE_WORKER':
      engine.locateWorker(command.worker, command.location)
      break
    case 'LOCATE_JOB':
    case 'LOCATE_WORKER_JOB':
      engine.locateJob(command.job)
      break
    case 'SHOW_LOCATIONS':
      engine.showLocations(command)
      break
    case 'HIGHLIGHT_WORKER':
      engine.highlightWorker(command.worker, command.location)
      break
    case 'REMOVE_WORKER_HIGHLIGHT':
      engine.removeWorkerHighlight(command.worker, command.location)
      break
    case 'UPDATE_CONTEXT':
      engine.updateContext(command.ctx)
      break
    default:
      console.error('Unhandled command', command)
  }
}

const emptyCollection = () => ({ records: {} })

const getCollectionSize = c => Object.keys(c.records).length

const getCollectionBounds = c => {
  const locations = Object.values(c.records).map(r => latlng(r.location))

  if (locations.length > 0) {
    const bounds = new google.maps.LatLngBounds()
    locations.forEach(l => bounds.extend(l))

    return bounds
  } else {
    return null
  }
}

const locationRecord = (location, measurementSystem) => {
  const color = getAccuracyColor(location, measurementSystem)

  return {
    uuid: location.compositeId,
    type: 'location',
    location: location,
    objects: [
      new google.maps.Marker({
        position: latlng(location),
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          fillOpacity: 1.0,
          fillColor: color,
          strokeOpacity: 1.0,
          strokeColor: color,
          strokeWeight: 1.0,
          scale: 3 //pixels
        },
        zIndex: 9
      })
    ]
  }
}

const pinSymbol = color => {
  return {
    path:
      'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
    fillColor: color,
    fillOpacity: 1,
    strokeColor: '#000',
    strokeWeight: 2,
    scale: 1
  }
}

const highlightedLocationRecord = (location, measurementSystem) => {
  return {
    uuid: location.compositeId,
    type: 'highlightedLocation',
    location: location,
    objects: [
      new google.maps.Circle({
        strokeColor: '#c1b4a7',
        strokeOpacity: 0.4,
        strokeWeight: 0,
        fillColor: '#c1b4a7',
        fillOpacity: 0.4,
        center: { lat: location.latitude, lng: location.longitude },
        radius: parseFloat(location.precision)
      }),
      new google.maps.Marker({
        position: latlng(location),
        icon: pinSymbol(getAccuracyColor(location, measurementSystem)),
        zIndex: 10
      })
    ]
  }
}

const workerMarker = ({ name }, location) => {
  return new RichMarker({
    position: latlng(location),
    flat: true,
    anchor: RichMarkerPosition.MIDDLE,
    content: `<div class="gmaps-worker-marker">
                <span class="bubble">
                  ${name}
                  <span class="pointer-back"></span>
                  <span class="pointer"></span>
                </span>
              </div>`
  })
}

const jobMarker = ({
  colorEnabled = false,
  status,
  scheduledTime,
  serialNo,
  assigneeName,
  ...job
}) => {
  const classes = classNames({
    'gmaps-job-marker': true,
    [status]: colorEnabled
  })

  const row = scheduledTime ? `${scheduledTime}` : serialNo

  return new RichMarker({
    position: latlng(job.location),
    flat: true,
    anchor: RichMarkerPosition.MIDDLE,
    content: `<div class="${classes}">
              <span class="bubble">
                ${row}
                <br/>
                ${assigneeName || ''}
                <span class="pointer-back"></span>
                <span class="pointer"></span>
              </span>
            </div>`
  })
}

const workerJobMarker = job => {
  const markerContent = job.scheduledTime
    ? job.scheduledTime
    : '№ ' + job.serialNo

  return new RichMarker({
    position: latlng(job.location),
    flat: true,
    anchor: RichMarkerPosition.MIDDLE,
    content: `<div class="gmaps-worker-job-marker">
                <span class="bubble">
                  ${markerContent}
                  <span class="pointer-back"></span>
                  <span class="pointer"></span>
                </span>
              </div>`
  })
}

const workerJobRecord = (ctx, job) => {
  let timeParams = {
    scheduledAt: job.scheduledAt,
    scheduledFinishAt: job.scheduledFinishAt,
    ctx,
    today: false
  }
  return {
    uuid: job.uuid,
    type: 'workerJob',
    job: job,
    objects: [
      workerJobMarker({
        ...job,
        scheduledTime: scheduledTime(timeParams)
      })
    ]
  }
}

const jobRecord = (ctx, job, params = {}) => {
  const { today = true } = params
  let timeParams = {
    scheduledAt: job.scheduledAt,
    scheduledFinishAt: job.scheduledFinishAt,
    ctx,
    today
  }

  const marker = jobMarker({
    ...job,
    serialNo: ctx.t('js.map.serial_no', job),
    scheduledTime: scheduledTime(timeParams)
  })

  return {
    uuid: job.uuid,
    type: 'job',
    job: job,
    params,
    objects: [marker]
  }
}

const workerRecord = (worker, location, top = false) => {
  return {
    uuid: worker.uuid,
    type: 'worker',
    worker: worker,
    location: location,
    top,
    objects: [workerMarker(worker, location)]
  }
}

const highlightedWorkerRecord = (worker, location) => {
  return {
    uuid: worker.uuid,
    type: 'highlightedWorker',
    worker: worker,
    location: location,
    objects: [
      new google.maps.Circle({
        strokeColor: '#c1b4a7',
        strokeOpacity: 0.4,
        strokeWeight: 0,
        fillColor: '#c1b4a7',
        fillOpacity: 0.4,
        center: latlng(location),
        radius: parseFloat(location.precision)
      })
    ]
  }
}

class GoogleEngine {
  constructor({ ctx, onJobClick, onWorkersJobClick, onBoundsChange, apiKey }) {
    this.ctx = ctx
    this.map = null
    this.currentWorker = null
    this.apiKey = apiKey
    this.onJobClick = onJobClick
    this.onWorkersJobClick = onWorkersJobClick
    this.jobsClickHandlers = {}
    this.onBoundsChange = onBoundsChange
  }

  initCollections() {
    this.locations = emptyCollection()
    this.highlightedLocations = emptyCollection()
    this.jobs = emptyCollection()
    this.workers = emptyCollection()
    this.workerJobs = emptyCollection()
    this.highlightedWorkers = emptyCollection()
  }

  start(viewpoint, channel) {
    if (!GoogleEngine.activated) {
      let loadDefer
      if (window.gmapsRequested) {
        loadDefer = gmapsPromise
      } else {
        loadDefer = loadScript(url(this.ctx, this.apiKey))
      }

      loadDefer.then(() => {
        this.initCollections()
        this.renderTo(viewpoint)
          .then(_ => (GoogleEngine.activated = true))
          .then(_ => channel.subscribe(c => this.execute(c)))
          .then(_ => this.centerMap())
          .then(_ => this.addBoundsListener())
      })
    } else {
      this.initCollections()
      this.renderTo(viewpoint)
        .then(_ => (GoogleEngine.activated = true))
        .then(_ => channel.subscribe(c => this.execute(c)))
        .then(_ => this.centerMap())
        .then(_ => this.addBoundsListener())
    }
  }

  renderTo(viewpoint) {
    return new Promise((resolve, _reject) => {
      const renderMap = (center = { lat: 0.0, lng: 0.0 }) => {
        this.map = new google.maps.Map(viewpoint, {
          zoom: 13,
          center,
          styles: [{ featureType: "poi", elementType: "labels", stylers: [{ visibility: "off" }] }]
        })

        RichMarker.prototype.__proto__ = google.maps.OverlayView.prototype
        resolve(this.map)
      }

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }

            renderMap(pos)
          },
          () => {
            renderMap()
          }
        )
      } else {
        renderMap()
      }
    })
  }

  execute(command) {
    processCommand(command.type, command, this)
  }

  centerMap() {
    const bounds = getCollectionBounds(this.workers)

    if (bounds !== null) {
      const colSize = getCollectionSize(this.workers)
      if (colSize > 1) {
        this.setBounds(bounds)
      } else {
        this.setCenter(bounds.getCenter())
        this.setZoom(13)
      }
    }
  }

  setBounds(bounds) {
    this.map.fitBounds(bounds)
  }

  zoomOut() {
    if (this.map.zoom > 10) {
      this.map.setZoom(10)
    }
  }

  goTo(bounds, _ignoreCurrentZoom) {
    if (bounds !== null) {
      this.map.panToBounds(bounds)
    }
  }

  setCenter(center) {
    this.map.setCenter(center)
  }

  setZoom(zoom) {
    this.map.setZoom(zoom)
  }

  addLocation(location) {
    if (!(location.compositeId in this.highlightedLocations)) {
      this.addRecord(
        locationRecord(location, this.ctx.localizator.measurementSystem),
        this.locations
      )
    }
  }

  panTo(loc) {
    this.map.panTo(latlng(loc))
  }

  removeLocation(location) {
    this.removeRecord(location.compositeId, this.locations)
  }

  highlightLocation(location) {
    this.removeLocation(location)
    this.addRecord(
      highlightedLocationRecord(location, this.ctx.localizator.measurementSystem),
      this.highlightedLocations
    )

    if (!this.isVisible(location)) {
      this.panTo(location)
    }
  }

  isVisible(loc) {
    return this.map.getBounds().contains(latlng(loc))
  }

  removeLocationHighlight(location) {
    this.removeRecord(location.compositeId, this.highlightedLocations)
    this.addLocation(location)
  }

  addWorker(worker, location, top = false) {
    this.addRecord(workerRecord(worker, location, top), this.workers)
  }

  removeWorker(worker, _location) {
    this.removeRecord(worker.uuid, this.workers)
  }

  addWorkerJob(job) {
    this.addRecord(workerJobRecord(this.ctx, job), this.workerJobs)
  }

  removeWorkerJob(job) {
    this.removeRecord(job.uuid, this.workerJobs)
  }

  addJob = (job, options = {}) =>
    this.addRecord(jobRecord(this.ctx, job, options), this.jobs)

  removeJob(job) {
    google.maps.event.removeListener(this.jobsClickHandlers[job.uuid])
    this.removeRecord(job.uuid, this.jobs)
  }

  redrawWorker({ worker, location }, top = false) {
    this.removeWorker(worker, location)
    this.addWorker(worker, location, top)
  }

  locateWorker(worker, location) {
    this.panTo(location)

    if (this.currentWorker !== null) {
      this.redrawWorker(this.currentWorker)
    }

    this.currentWorker = { worker, location }
    this.redrawWorker(this.currentWorker, true)
  }

  locateJob(job) {
    this.panTo(job.location)
  }

  showLocations({ job }) {
    const bounds = getCollectionBounds(this.locations)

    if (bounds !== null) {
      if (hasLocation(job)) {
        bounds.extend(latlng(job.location))
        this.setBounds(bounds)
        this.zoomOut()
      } else {
        const colSize = getCollectionSize(this.locations)

        if (colSize > 1) {
          this.setBounds(bounds)
          this.zoomOut()
        } else {
          this.setCenter(bounds.getCenter())
          this.setZoom(10)
        }
      }
    }
  }

  highlightWorker(worker, location) {
    this.addRecord(
      highlightedWorkerRecord(worker, location),
      this.highlightedWorkers
    )
  }

  removeWorkerHighlight(worker, _location) {
    this.removeRecord(worker.uuid, this.highlightedWorkers)
  }

  addRecord(record, { records, _collection }) {
    if (!(record.uuid in records)) {
      record.objects.forEach(o => o.setMap(this.map))

      if (record.type === 'job' || record.type === 'workerJob') {
        this.jobsClickHandlers[
          record.uuid
        ] = google.maps.event.addListener(record.objects[0], 'click', () =>
          this.jobPopupClickHandler(record)
        )
      }

      records[record.uuid] = record
    }
  }

  jobPopupClickHandler(record) {
    switch (record.type) {
      case 'job':
        return this.onJobClick(record.uuid)
      case 'workerJob':
        return this.onWorkersJobClick(record.job)
      default:
        return
    }
  }

  removeRecord(uuid, { records, _collection }) {
    if (uuid in records) {
      records[uuid].objects.forEach(o => o.setMap(null))
      delete records[uuid]
    }
  }

  updateJobs() {
    Object.values(this.jobs.records).map(({ uuid, job, params }) => {
      google.maps.event.removeListener(this.jobsClickHandlers[uuid])
      this.removeRecord(uuid, this.jobs)
      this.addRecord(jobRecord(this.ctx, job, params), this.jobs)
    })
  }

  updateWorkers() {
    Object.values(this.workers.records).map(
      ({ uuid, worker, location, top }) => {
        this.removeRecord(uuid, this.workers)
        this.addRecord(workerRecord(worker, location, top), this.workers)
      }
    )
  }

  updateLocations() {
    Object.values(this.locations.records).map(({ location }) => {
      this.removeRecord(location.compositeId, this.locations)
      if (!(location.compositeId in this.highlightedLocations)) {
        this.addRecord(
          locationRecord(location, this.ctx.localizator.measurementSystem),
          this.locations
        )
      }
    })
  }

  updateWorkerJobs() {
    Object.values(this.workerJobs.records).map(({ uuid, job }) => {
      this.removeRecord(uuid, this.workerJobs)
      this.addRecord(workerJobRecord(this.ctx, job), this.workerJobs)
    })
  }

  updateContext(ctx) {
    this.ctx = ctx

    this.updateJobs()
    this.updateWorkerJobs()
    this.updateLocations()
    this.updateWorkerJobs()
  }

  handleBounds = (bounds = null) => {
    if (bounds !== null) {
      const southWest = bounds.getSouthWest()
      const northEast = bounds.getNorthEast()

      this.onBoundsChange({
        southWest: {
          longitude: southWest.lng(),
          latitude: southWest.lat()
        },
        northEast: {
          longitude: northEast.lng(),
          latitude: northEast.lat()
        }
      })
    }
  }

  addBoundsListener = () => {
    google.maps.event.addListener(this.map, 'idle', () => {
      const bounds = this.map.getBounds()
      this.handleBounds(bounds)
    })
  }
}

GoogleEngine.activated = false

export default GoogleEngine
