

import * as El from "../../../../../libraries/El.mjs";
import * as RCore from "../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../types/Types.mjs";
import * as Utils from "../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Tooltip from "../../../../common/Tooltip/Tooltip.mjs";
import * as Optional from "../../../../common/Optional.mjs";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Webapi__Dom__HtmlElement from "rescript-webapi/lib/es6/src/Webapi/Dom/Webapi__Dom__HtmlElement.mjs";
import * as Schedule_TimelineWidgetFull_Sizes from "./Schedule_TimelineWidgetFull_Sizes.mjs";
import * as Schedule_TimelineWidgetModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/timeline/widget/Schedule_TimelineWidget.module.css";

function jobsCount$p(count, c) {
  return Utils.Translations.tx("js.schedule.v2.jobs_count")({
              count: count
            }, c);
}

function published$p(count, ctx) {
  return Utils.Translations.tx("js.schedule.v2.widget_tooltips.published")({
              count: " (" + jobsCount$p(count, ctx) + ")"
            }, ctx);
}

function enRoute$p(count, ctx) {
  return Utils.Translations.tx("js.schedule.v2.widget_tooltips.en_route")({
              count: " (" + jobsCount$p(count, ctx) + ")"
            }, ctx);
}

function started$p(count, ctx) {
  return Utils.Translations.tx("js.schedule.v2.widget_tooltips.started")({
              count: " (" + jobsCount$p(count, ctx) + ")"
            }, ctx);
}

function suspended$p(count, ctx) {
  return Utils.Translations.tx("js.schedule.v2.widget_tooltips.suspended")({
              count: " (" + jobsCount$p(count, ctx) + ")"
            }, ctx);
}

function successfullyFinished$p(count, ctx) {
  return Utils.Translations.tx("js.schedule.v2.widget_tooltips.successfully_finished")({
              count: " (" + jobsCount$p(count, ctx) + ")"
            }, ctx);
}

function unsuccessfullyFinished$p(count, ctx) {
  return Utils.Translations.tx("js.schedule.v2.widget_tooltips.unsuccessfully_finished")({
              count: " (" + jobsCount$p(count, ctx) + ")"
            }, ctx);
}

var styles = Schedule_TimelineWidgetModuleCss;

function Schedule_TimelineWidgetFull$CloseIcon(Props) {
  return React.createElement("svg", {
              height: "14",
              width: "14",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "m9.654 7 3.98-3.98a1.251 1.251 0 0 0 0-1.77l-.885-.884a1.251 1.251 0 0 0-1.769 0L7 4.346 3.02.366a1.251 1.251 0 0 0-1.77 0l-.884.885a1.251 1.251 0 0 0 0 1.769L4.346 7l-3.98 3.98a1.251 1.251 0 0 0 0 1.77l.885.884c.488.488 1.28.488 1.769 0L7 9.654l3.98 3.98c.489.488 1.28.488 1.77 0l.884-.885a1.251 1.251 0 0 0 0-1.769L9.654 7Z",
                  fill: "#a2aab9"
                }));
}

function Schedule_TimelineWidgetFull$StatsItem(Props) {
  var tooltip = Props.tooltip;
  var className = Props.className;
  var count = Props.count;
  var width = Props.width;
  var elementRef = Types.ReactRef.use();
  var style = {
    width: width.toString() + "px"
  };
  return React.createElement(Optional.make, {
              show: width !== 0.0,
              children: React.createElement(Tooltip.make, {
                    elementRef: elementRef,
                    position: "top",
                    content: tooltip,
                    children: React.createElement("div", {
                          ref: elementRef,
                          className: El.Cn.concat(styles.status, className),
                          style: style
                        }, React.createElement("div", {
                              className: styles.statusContent
                            }, count.toString()))
                  })
            });
}

function Schedule_TimelineWidgetFull$StatsItems(Props) {
  var stats = Props.stats;
  var fullWidthPx = Props.fullWidthPx;
  var match = AppContext.useWire();
  var unsuccessfullyFinished = stats.unsuccessfullyFinished;
  var successfullyFinished = stats.successfullyFinished;
  var suspended = stats.suspended;
  var started = stats.started;
  var enRoute = stats.enRoute;
  var published = stats.published;
  var ctx = match.ctx;
  var sizes = Schedule_TimelineWidgetFull_Sizes.use(stats, fullWidthPx);
  var width = sizes.publishedPx;
  var published$1 = width !== undefined ? React.createElement(Schedule_TimelineWidgetFull$StatsItem, {
          tooltip: published$p(published, ctx),
          className: styles.published,
          count: published,
          width: width
        }) : null;
  var width$1 = sizes.inWorkPx;
  var inWork;
  if (width$1 !== undefined) {
    var inWork$1 = (enRoute + started | 0) + suspended | 0;
    var style = {
      width: width$1.toString() + "px"
    };
    inWork = React.createElement("div", {
          className: styles.inWork,
          style: style
        }, React.createElement(Schedule_TimelineWidgetFull$StatsItem, {
              tooltip: enRoute$p(enRoute, ctx),
              className: styles.enRoute,
              count: enRoute,
              width: width$1 * enRoute / inWork$1
            }), React.createElement(Schedule_TimelineWidgetFull$StatsItem, {
              tooltip: started$p(started, ctx),
              className: styles.started,
              count: started,
              width: width$1 * started / inWork$1
            }), React.createElement(Schedule_TimelineWidgetFull$StatsItem, {
              tooltip: suspended$p(suspended, ctx),
              className: styles.suspended,
              count: suspended,
              width: width$1 * suspended / inWork$1
            }));
  } else {
    inWork = null;
  }
  var width$2 = sizes.finishedPx;
  var finished;
  if (width$2 !== undefined) {
    var finished$1 = successfullyFinished + unsuccessfullyFinished | 0;
    var style$1 = {
      width: width$2.toString() + "px"
    };
    finished = React.createElement("div", {
          className: styles.finished,
          style: style$1
        }, React.createElement(Schedule_TimelineWidgetFull$StatsItem, {
              tooltip: successfullyFinished$p(successfullyFinished, ctx),
              className: styles.successfullyFinished,
              count: successfullyFinished,
              width: width$2 * successfullyFinished / finished$1
            }), React.createElement(Schedule_TimelineWidgetFull$StatsItem, {
              tooltip: unsuccessfullyFinished$p(unsuccessfullyFinished, ctx),
              className: styles.unsuccessfullyFinished,
              count: unsuccessfullyFinished,
              width: width$2 * unsuccessfullyFinished / finished$1
            }));
  } else {
    finished = null;
  }
  return React.createElement(React.Fragment, undefined, published$1, inWork, finished);
}

function Schedule_TimelineWidgetFull(Props) {
  var stats = Props.stats;
  var close = Props.close;
  var view = Props.view;
  var withUnassignedJobs = Props.withUnassignedJobs;
  var match = AppContext.useWire();
  var className = El.Cn.concat(El.Cn.concat(styles.container, withUnassignedJobs ? styles.withUnassignedJobs : ""), view === "day" ? styles.day : styles.week);
  var statsRef = Types.ReactRef.use();
  var match$1 = React.useState(function () {
        
      });
  var setStatsWidthPx = match$1[1];
  var statsWidthPx = match$1[0];
  React.useEffect((function () {
          var htmlElement = RCore.$$Option.flatMap(Types.ReactRef.toOption(statsRef), Webapi__Dom__HtmlElement.ofElement);
          if (htmlElement !== undefined) {
            var widthPx = Caml_option.valFromOption(htmlElement).offsetWidth;
            setStatsWidthPx(function (param) {
                  return widthPx;
                });
          }
          
        }), []);
  return React.createElement("div", {
              className: className
            }, React.createElement("div", {
                  className: styles.jobsCount
                }, jobsCount$p(stats.all, match.ctx)), React.createElement("div", {
                  ref: statsRef,
                  className: styles.stats
                }, statsWidthPx !== undefined ? React.createElement(Schedule_TimelineWidgetFull$StatsItems, {
                        stats: stats,
                        fullWidthPx: statsWidthPx
                      }) : null), React.createElement("button", {
                  className: styles.closeButton,
                  type: "button",
                  onClick: close
                }, React.createElement(Schedule_TimelineWidgetFull$CloseIcon, {})));
}

var make = Schedule_TimelineWidgetFull;

export {
  make ,
}
/* styles Not a pure module */
