

import * as Fun from "../../../Fun.mjs";
import * as RCore from "../../../RCore.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Bindings_Slate from "../../../../bindings/Bindings_Slate.mjs";
import * as Liquid_Variable from "../../parser/Liquid_Variable.mjs";
import * as Liquid_EditorModuleCss from "/home/runner/work/planado/planado/client/rescript/libraries/liquid/editor/Liquid_Editor.module.css";

var styles = Liquid_EditorModuleCss;

function withInlines(editor) {
  var isInline = Bindings_Slate.Editor.getIsInline(editor);
  Bindings_Slate.Editor.setIsInline(editor, (function (element) {
          var match = Bindings_Slate.$$Element.payload(element);
          if (match !== undefined && typeof match === "object") {
            return true;
          } else {
            return isInline(element);
          }
        }));
  var isVoid = Bindings_Slate.Editor.getIsVoid(editor);
  Bindings_Slate.Editor.setIsVoid(editor, (function (element) {
          var match = Bindings_Slate.$$Element.payload(element);
          if (match !== undefined && typeof match === "object") {
            return true;
          } else {
            return isVoid(element);
          }
        }));
  var insertText = Bindings_Slate.Editor.getInsertText(editor);
  Bindings_Slate.Editor.setInsertText(editor, (function (text) {
          var selection = Bindings_Slate.Editor.getSelection(editor);
          if (selection === undefined) {
            return insertText(text);
          }
          var selection$1 = Caml_option.valFromOption(selection);
          if (!(text === " " && Bindings_Slate.SlateRange.isCollapsed(selection$1))) {
            return insertText(text);
          }
          var match = Bindings_Slate.SlateRange.edges(selection$1);
          var start = match[0];
          var characterBefore = Bindings_Slate.SlateEditor.before(Caml_option.some(Bindings_Slate.SlateEditor.BeforeOptions.make(undefined, "character", undefined, undefined)), editor, start);
          var before = RCore.$$Option.flatMap(characterBefore, (function (characterBefore) {
                  return Bindings_Slate.SlateEditor.before(undefined, editor, characterBefore);
                }));
          var beforeRange = RCore.$$Option.flatMap(before, (function (before) {
                  return Bindings_Slate.SlateEditor.range(editor, before, start);
                }));
          var beforeText = RCore.$$Option.map(beforeRange, (function (beforeRange) {
                  return Bindings_Slate.SlateEditor.stringAtRange(editor, beforeRange);
                }));
          var match$1 = Fun.both(beforeRange, beforeText);
          if (match$1 === undefined) {
            return insertText(text);
          }
          if (match$1[1] !== "{{") {
            return insertText(text);
          }
          Bindings_Slate.SlateTransforms.select(editor, match$1[0]);
          Bindings_Slate.SlateTransforms.$$delete(editor);
          var variableElement = [Bindings_Slate.$$Element.make(undefined, [Bindings_Slate.$$Element.make("", undefined, undefined, undefined)], {
                  TAG: "Variable",
                  _0: Liquid_Variable.empty
                }, undefined)];
          Bindings_Slate.SlateTransforms.insertNodes(editor, variableElement);
        }));
  return editor;
}

export {
  styles ,
  withInlines ,
}
/* styles Not a pure module */
