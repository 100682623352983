

import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as PromiseF from "../../../libraries/PromiseF.mjs";
import * as RelatedEntitiesTypes from "./RelatedEntitiesTypes.mjs";

function request(wire, options) {
  return PromiseF.toIOLazy(function () {
              return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                              NAME: "API",
                              VAL: {
                                NAME: "RelatedEntities",
                                VAL: RelatedEntitiesTypes.Options.toQuery(options)
                              }
                            }), RelatedEntitiesTypes.Index.decode);
            });
}

var Show = {
  request: request
};

export {
  Show ,
}
/* Backend Not a pure module */
