

import * as React from "react";
import * as DayTimeline_State from "./lib/state/DayTimeline_State.mjs";
import * as DayTimeline_Content from "./components/content/DayTimeline_Content.mjs";
import * as DayTimeline_DragLayer from "./components/drag_layer/DayTimeline_DragLayer.mjs";
import * as DayTimeline_StateContext from "./lib/state/DayTimeline_StateContext.mjs";

function DayTimeline(Props) {
  var match = DayTimeline_State.useContext();
  var elements = match[0];
  return React.createElement(DayTimeline_StateContext.Provider.make, DayTimeline_StateContext.Provider.makeProps(match[1], null, undefined), React.createElement(DayTimeline_Content.make, {
                  elements: elements
                }), React.createElement(DayTimeline_DragLayer.make, {
                  viewportEl: elements.viewportEl
                }));
}

var make = DayTimeline;

export {
  make ,
}
/* react Not a pure module */
