

import * as React from "react";
import * as WeekTimeline_State from "./lib/state/WeekTimeline_State.mjs";
import * as WeekTimeline_Content from "./components/content/WeekTimeline_Content.mjs";
import * as WeekTimeline_DragLayer from "./components/drag_layer/WeekTimeline_DragLayer.mjs";
import * as WeekTimeline_StateContext from "./lib/state/WeekTimeline_StateContext.mjs";
import * as WeekTimeline_ActiveDayState from "./components/active_day/lib/state/WeekTimeline_ActiveDayState.mjs";
import * as WeekTimeline_ActiveDayStateContext from "./components/active_day/lib/state/WeekTimeline_ActiveDayStateContext.mjs";

function WeekTimeline(Props) {
  var match = WeekTimeline_State.useContext();
  var timelineElements = match[0];
  var match$1 = WeekTimeline_ActiveDayState.useContext();
  return React.createElement(WeekTimeline_StateContext.Provider.make, WeekTimeline_StateContext.Provider.makeProps(match[1], React.createElement(WeekTimeline_ActiveDayStateContext.StateContext.Provider.make, WeekTimeline_ActiveDayStateContext.StateContext.Provider.makeProps(match$1[0], React.createElement(WeekTimeline_ActiveDayStateContext.SetStateContext.Provider.make, WeekTimeline_ActiveDayStateContext.SetStateContext.Provider.makeProps(match$1[1], null, undefined), React.createElement(WeekTimeline_Content.make, {
                                  elements: timelineElements
                                }), React.createElement(WeekTimeline_DragLayer.make, {
                                  viewportEl: timelineElements.viewportEl
                                })), undefined)), undefined));
}

var make = React.memo(WeekTimeline);

export {
  make ,
}
/* make Not a pure module */
