

import * as El from "../../../libraries/El.mjs";
import * as Panel from "../Panel/Panel.mjs";
import * as React from "react";
import * as PopoverModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/Popover/Popover.module.css";

var styles = PopoverModuleCss;

function _offsetX(offsetX) {
  if (offsetX === "left") {
    return -10.0;
  } else if (offsetX === "right") {
    return 10.0;
  } else {
    return 0.0;
  }
}

function _offsetY(offsetY) {
  if (offsetY === "right" || offsetY === "left") {
    return -20.0;
  } else {
    return 0.0;
  }
}

function Popover(Props) {
  var footer = Props.footer;
  var showOpt = Props.show;
  var buttonId = Props.buttonId;
  var positionOpt = Props.position;
  var classNameOpt = Props.className;
  var children = Props.children;
  var show = showOpt !== undefined ? showOpt : true;
  var position = positionOpt !== undefined ? positionOpt : "bottom";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var className$1 = El.Cn.concat(El.Cn.concat(styles.popover, position === "left" ? styles.left : (
              position === "bottom" ? styles.bottom : (
                  position === "right" ? styles.right : (
                      position === "top" ? styles.top : ""
                    )
                )
            )), className);
  return React.createElement(Panel.make, {
              footer: footer,
              show: show,
              domId: buttonId,
              position: position,
              className: className$1,
              offsetX: _offsetX(position),
              offsetY: _offsetY(position),
              children: children
            });
}

var make = Popover;

export {
  make ,
}
/* styles Not a pure module */
