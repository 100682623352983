

import * as Uuid from "uuid";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";
import * as UuidWithV6 from "uuid-with-v6";

function createV4(prim) {
  return Uuid.v4();
}

function createV6(prim) {
  return UuidWithV6.v6();
}

function toString(s) {
  return s;
}

function fromString(s) {
  return s;
}

function test(str) {
  var regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return regex.test(str);
}

function equal(a, b) {
  return a === b;
}

var decode = JsonDecode.map(JsonDecode.string, (function (s) {
        if (test(s)) {
          return s;
        } else {
          return JsonDecode.cannotDecode("Invalid UUID");
        }
      }));

function fromJson(prim) {
  return prim;
}

export {
  equal ,
  fromJson ,
  createV4 ,
  createV6 ,
  toString ,
  test ,
  fromString ,
  decode ,
}
/* decode Not a pure module */
