

import * as El from "../../../../../libraries/El.mjs";
import * as Icon from "../../../../../Icon.mjs";
import * as React from "react";
import * as FileInput_PreviewModuleCss from "/home/runner/work/planado/planado/client/rescript/components/file/file_input/components/preview/FileInput_Preview.module.css";

var styles = FileInput_PreviewModuleCss;

function FileInput_Preview_RemoveButton(Props) {
  var removeFile = Props.removeFile;
  var decoration = Props.decoration;
  var className = El.Cn.concatOpt(El.Cn.concat(Icon.style(undefined, undefined, "trash"), styles.iconTrash), decoration === "Dark" ? styles.dark : undefined);
  return React.createElement("button", {
              className: className,
              type: "button",
              onClick: removeFile
            });
}

var make = FileInput_Preview_RemoveButton;

export {
  make ,
}
/* styles Not a pure module */
