

import * as Hooks from "../../../../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../types/Types.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DateTimePicker from "../../../../../inputs/DateTimePicker.mjs";
import * as MessengerTypes from "../../../../../messenger/MessengerTypes.mjs";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as MultipleAssignee from "../../../../../../types/MultipleAssignee.mjs";
import * as MultipleAssignees from "../../../../../common/MultipleAssignees/MultipleAssignees.mjs";
import * as Schedule_Types_Job from "../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as Schedule_StateTypes from "../../../../lib/state/Schedule_StateTypes.mjs";
import * as MultipleAssignees_Utils from "../../../../../common/MultipleAssignees/MultipleAssignees_Utils.mjs";
import * as Schedule_JobPreviewFormModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/popovers/job_preview/form/Schedule_JobPreviewForm.module.css";

var styles = Schedule_JobPreviewFormModuleCss;

var scheduledAt$p = Utils.Translations.t("js.schedule.v2.job.form.scheduled_at");

var save$p = Utils.Translations.tr("js.schedule.v2.job.form.save");

var cancel$p = Utils.Translations.tr("js.schedule.v2.job.form.cancel");

var noEditAccess$p = Utils.Translations.t("js.schedule.errors.no_edit_access");

var tooManyAssignees$p = Utils.Translations.t("js.schedule.errors.too_many_assignees");

function displayError(messenger, text) {
  messenger.trigger({
        TAG: "Show",
        _0: MessengerTypes.Message.make(undefined, "Danger", {
              TAG: "Text",
              _0: text
            })
      });
}

function Schedule_JobPreviewForm(Props) {
  var job = Props.job;
  var closePreview = Props.closePreview;
  var closeForm = Props.closeForm;
  var id = Hooks.useId(undefined);
  var wire = AppContext.useWire();
  var messenger = wire.subscriptions.messenger;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        var scheduledAt = Schedule_Types_Job.ScheduledJob.scheduledAt(job);
        if (scheduledAt !== undefined) {
          return Locale.T.toDateTime(Caml_option.valFromOption(scheduledAt), wire);
        } else {
          return Types.Time.DateTime.empty;
        }
      });
  var setScheduledAt = match[1];
  var scheduledAt = match[0];
  var scheduledAtMoment = Locale.T.fromDateTime(scheduledAt, wire);
  var match$1 = React.useState(function () {
        return Schedule_Types_Job.ScheduledJob.assignees(job);
      });
  var setAssignees = match$1[1];
  var assignees = match$1[0];
  var dispatch = Schedule_State.useDispatch();
  var submitForm = function ($$event) {
    $$event.preventDefault();
    if (Caml_obj.notequal(assignees, []) && !assignees.some(MultipleAssignee.canEdit)) {
      return displayError(messenger, noEditAccess$p(ctx));
    } else if (Caml_obj.notequal(assignees, []) && !MultipleAssignee.workerNumberValid(assignees)) {
      return displayError(messenger, tooManyAssignees$p(ctx));
    } else {
      closePreview();
      return dispatch(Schedule_StateTypes.Action.jobSubmitted(Schedule_Types_Job.ScheduledJob.uuid(job), RCore.$$Option.map(Locale.T.concatDateWithTime(scheduledAt, wire), Locale.T.make), assignees));
    }
  };
  var tmp = {
    selectedAssignees: assignees,
    territoryUuid: Schedule_Types_Job.ScheduledJob.territoryUuid(job),
    skillUuids: Schedule_Types_Job.ScheduledJob.skillUuids(job),
    availableSkills: ctx.availableSkills,
    disabled: false,
    onChange: (function (a) {
        setAssignees(function (param) {
              return a;
            });
      }),
    openUp: false
  };
  if (scheduledAtMoment !== undefined) {
    tmp.availableAt = Caml_option.valFromOption(scheduledAtMoment);
  }
  return React.createElement("form", {
              className: styles.form,
              onSubmit: submitForm
            }, React.createElement(DateTimePicker.make, {
                  id: id,
                  label: scheduledAt$p(ctx),
                  dateTime: scheduledAt,
                  onChange: (function (d) {
                      var withActualTempWorkers = MultipleAssignees_Utils.withActualTempWorkers(assignees, Locale.T.fromDateTime(d, wire), ctx.allAssignees);
                      if (Caml_obj.equal(assignees, withActualTempWorkers)) {
                        
                      } else {
                        setAssignees(function (param) {
                              return withActualTempWorkers;
                            });
                      }
                      setScheduledAt(function (param) {
                            return d;
                          });
                      return true;
                    }),
                  calendarPlacement: "bottom",
                  outlineIcons: true
                }), React.createElement(MultipleAssignees.make, tmp), React.createElement("div", {
                  className: styles.buttons
                }, React.createElement("button", {
                      className: "btn btn-primary btn-32px",
                      type: "submit"
                    }, save$p(ctx)), React.createElement("button", {
                      className: "btn btn-32px",
                      type: "button",
                      onClick: (function (param) {
                          closeForm();
                        })
                    }, cancel$p(ctx))));
}

var make = React.memo(Schedule_JobPreviewForm);

export {
  make ,
}
/* styles Not a pure module */
