

import * as Fun from "../../../../libraries/Fun.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Checkbox from "../../../inputs/Checkbox.mjs";
import * as MapJobsRefreshControlModuleCss from "/home/runner/work/planado/planado/client/rescript/components/map/jobs/refresh_control/MapJobsRefreshControl.module.css";

var styles = MapJobsRefreshControlModuleCss;

var refreshControl$p = Utils.Translations.t("js.map.refresh_control");

function MapJobsRefreshControl(Props) {
  var wire = Props.wire;
  var checked = Props.checked;
  var onToggle = Props.onToggle;
  return React.createElement("div", {
              className: styles.container
            }, React.createElement(Checkbox.make, {
                  onToggle: Fun.omit(onToggle),
                  checked: checked,
                  label: refreshControl$p(wire.ctx),
                  id: "refreshControl",
                  wrapperClasses: Js_dict.fromArray([[
                          styles.checkbox,
                          true
                        ]])
                }));
}

var make = MapJobsRefreshControl;

export {
  make ,
}
/* styles Not a pure module */
