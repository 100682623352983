

import * as RCore from "../../libraries/RCore.mjs";
import * as Route from "../../types/Route.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";

var noWorkers$p = Utils.Translations.tr("js.teams.index.no_workers");

function TeamIndexItem(Props) {
  var team = Props.team;
  var openTeam = Props.openTeam;
  var ctx = Props.ctx;
  var workers = team.workers.length > 0 ? Utils.Translations.joinWithComma(team.workers.map(function (w) {
              return w.name;
            })) : noWorkers$p(ctx);
  var foreman = RCore.$$Option.getOr(team.foreman, "");
  var description = RCore.$$Option.getOr(team.description, "");
  return React.createElement("tr", undefined, React.createElement("td", undefined, React.createElement("a", {
                      href: Route.pagePath(undefined, {
                            NAME: "Team",
                            VAL: {
                              NAME: "Edit",
                              VAL: team.uuid
                            }
                          }),
                      onClick: openTeam(team.uuid)
                    }, team.name)), React.createElement("td", undefined, foreman), React.createElement("td", undefined, workers), React.createElement("td", undefined, description));
}

var make = TeamIndexItem;

export {
  make ,
}
/* noWorkers' Not a pure module */
