// extracted by mini-css-extract-plugin
export var buttonLoading = "button-loading-ryKk0jrKWtWz4gzi8JFZ";
export var isLoading = "is-loading-ckOE6I_eLlahD86G5D4i";
export var sitesList = "sites-list-podxZStJ6ZSY52ZhKokn";
export var sitesListBtn = "sites-list__btn-ThFS8voexGPn8JvBBu0d";
export var sitesListDesc = "sites-list__desc-ew_z9ddcLBMS9TYMwERJ";
export var sitesListHeadline = "sites-list__headline-bAwUVfRsDXroAXMKE0JH";
export var sitesTable = "sites-table-KHeQb8eEbFe_ODYpdB5e";
export var sitesTableAddress = "sites-table-address-DxZjLQCRdjsKFbhJvDHr";
export var sitesTableClient = "sites-table-client-GT3O1_I2b4YxPu6Mlr61";
export var sitesTableName = "sites-table-name-lFMdpREhun1MFWhEBBaY";
export var sitesTablePhone = "sites-table-phone-u445dJiI8AlMUJxNuzvj";