

import * as Fun from "../../../../../../../libraries/Fun.mjs";
import * as RCore from "../../../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../../../../../common/Optional.mjs";
import * as FormInput from "../../../../../../inputs/FormInput.mjs";

var externalId$p = Utils.Translations.t("js.jobs.labels.external_id");

function JobForm_BodyExternalIdBlock(Props) {
  var param = Props.wire;
  var id = Props.id;
  var name = Props.name;
  var value = Props.value;
  var show = Props.show;
  var onValueChange = Props.onValueChange;
  var $staropt$star = Props.disabled;
  var $staropt$star$1 = Props.readOnly;
  var $staropt$star$2 = Props.errors;
  var $staropt$star$3 = Props.maxLength;
  var disabled = $staropt$star !== undefined ? $staropt$star : false;
  var readOnly = $staropt$star$1 !== undefined ? $staropt$star$1 : false;
  var errors = $staropt$star$2 !== undefined ? $staropt$star$2 : [];
  var maxLength = $staropt$star$3 !== undefined ? $staropt$star$3 : 200;
  return React.createElement(Optional.make, {
              show: show,
              children: React.createElement(FormInput.make, {
                    _type: "text",
                    value: RCore.$$Option.getOr(value, ""),
                    onValueChange: (function (v) {
                        onValueChange(Fun.optString(v));
                      }),
                    id: id,
                    readOnly: readOnly,
                    name: name,
                    disabled: disabled,
                    labelText: externalId$p(param.ctx),
                    maxLength: maxLength,
                    errors: errors
                  })
            });
}

var make = JobForm_BodyExternalIdBlock;

var $$default = JobForm_BodyExternalIdBlock;

export {
  make ,
  $$default as default,
}
/* externalId' Not a pure module */
