

import * as Tour from "../../../../../../../libraries/tours/Tour.mjs";
import * as Utils from "../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../../../../libraries/Locale.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DateTimePicker from "../../../../../../inputs/DateTimePicker.mjs";
import * as DurationSpinnerJs from "../../../../../../common/DurationSpinnerJs.mjs";

var scheduledAtLabel$p = Utils.Translations.t("js.jobs.labels.scheduled_at");

var durationLabel$p = Utils.Translations.tr("js.jobs.labels.duration");

function JobForm_BodyScheduleBlock$ScheduledAt(Props) {
  var wire = Props.wire;
  var id = Props.id;
  var label = Props.label;
  var disabled = Props.disabled;
  var dateTime = Props.dateTime;
  var onChange = Props.onChange;
  var businessHours = Props.businessHours;
  var tmp = {
    id: id,
    label: label,
    dateTime: dateTime,
    onChange: onChange,
    disabled: disabled,
    dateClass: "intercom-job-add-scheduled-at-date " + wire.tour.anchor("job-form-scheduled_at-date"),
    timeClass: "intercom-job-add-scheduled-at-time"
  };
  if (businessHours !== undefined) {
    tmp.businessHours = Caml_option.valFromOption(businessHours);
  }
  return React.createElement(DateTimePicker.make, tmp);
}

function JobForm_BodyScheduleBlock$ScheduledDuration(Props) {
  var id = Props.id;
  var labelText = Props.labelText;
  var max = Props.max;
  var defaultValue = Props.defaultValue;
  var disabled = Props.disabled;
  var onChange = Props.onChange;
  return React.createElement("div", {
              className: "pd-form-group-container job-schedule-container transparent-border-1px"
            }, React.createElement("div", {
                  className: "pd-form-group job-duration"
                }, React.createElement("label", {
                      className: "pd-label",
                      htmlFor: id
                    }, labelText), React.createElement(DurationSpinnerJs.make, {
                      id: id,
                      min: 5,
                      max: max,
                      name: "job[scheduled_duration][]",
                      step: 5,
                      defaultValue: defaultValue,
                      onValueChange: onChange,
                      disabled: disabled
                    })));
}

function JobForm_BodyScheduleBlock(Props) {
  var wire = Props.wire;
  var inputId = Props.inputId;
  var disabled = Props.disabled;
  var setScheduledAt = Props.setScheduledAt;
  var setScheduledDuration = Props.setScheduledDuration;
  var scheduledAt = Props.scheduledAt;
  var maxScheduledDuration = Props.maxScheduledDuration;
  var scheduledDuration = Props.scheduledDuration;
  var tour = Tour.use(wire);
  var onScheduledAtChange = function (datetime) {
    var dt = Locale.T.fromDateTime(datetime, wire);
    var match;
    if (dt !== undefined) {
      var dt$1 = Caml_option.valFromOption(dt);
      match = [
        Caml_option.some(dt$1),
        Locale.T.equalDay(Locale.T.now(wire), dt$1)
      ];
    } else {
      match = [
        undefined,
        false
      ];
    }
    var dateTime = match[0];
    if (tour.trigger({
            NAME: "jobScheduled",
            VAL: [
              dateTime,
              match[1]
            ]
          })) {
      setScheduledAt(dateTime);
      return true;
    } else {
      return false;
    }
  };
  var dateTime = scheduledAt !== undefined ? Locale.T.toDateTime(Caml_option.valFromOption(scheduledAt), wire) : ({
        date: undefined,
        time: undefined
      });
  var tmp = {
    wire: wire,
    id: inputId("scheduled_at-date"),
    label: scheduledAtLabel$p(wire.ctx),
    disabled: disabled,
    dateTime: dateTime,
    onChange: onScheduledAtChange
  };
  if (wire.ctx.businessHours !== undefined) {
    tmp.businessHours = Caml_option.valFromOption(wire.ctx.businessHours);
  }
  return React.createElement("div", {
              className: "pd-form-group_scheduled-at"
            }, React.createElement(JobForm_BodyScheduleBlock$ScheduledAt, tmp), React.createElement(JobForm_BodyScheduleBlock$ScheduledDuration, {
                  id: inputId("duration"),
                  labelText: durationLabel$p(wire.ctx),
                  max: maxScheduledDuration,
                  defaultValue: scheduledDuration / 60 | 0,
                  disabled: disabled,
                  onChange: setScheduledDuration
                }));
}

var make = JobForm_BodyScheduleBlock;

var $$default = JobForm_BodyScheduleBlock;

export {
  make ,
  $$default as default,
}
/* scheduledAtLabel' Not a pure module */
