import React from 'react'
import { Select } from 'planado/components/input'
import Link from 'rscrpt/components/common/Link/Link.mjs'
import { skillsPath } from 'planado/routes.js'
import { withContext } from 'planado/utils/contextConsumer.jsx'

const skillsToOptions = (skills) =>
  skills.map((s) => ({
    value: s.uuid,
    label: s.name,
  }))

export const SkillsSelect = withContext(
  ({
    id,
    inputName,
    selectedSkillUuids,
    availableSkills,
    onChange,
    placeholder,
    disabled,
    ctx,
  }) => {
    const noAvailableSkills = availableSkills.length === 0

    let placeholderNoSkills

    if (ctx.permissions.admin) {
      placeholderNoSkills = (
        <p>
          {ctx.t('js.components.skills_select.skills_not_created_add_them_in')}
          <Link wire={ctx.wire} pathname={skillsPath}>
            {ctx.t('js.components.skills_select.settings')}
          </Link>
        </p>
      )
    } else {
      placeholderNoSkills = ctx.t(
        'js.components.skills_select.skills_not_created'
      )
    }

    return (
      <Select
        autosize={false}
        id={id}
        name={inputName}
        value={selectedSkillUuids}
        multi={true}
        options={skillsToOptions(availableSkills)}
        onChange={onChange}
        placeholder={noAvailableSkills ? placeholderNoSkills : placeholder}
        disabled={disabled || noAvailableSkills}
      />
    )
  }
)
