

import * as Hooks from "../../../libraries/hooks/Hooks.mjs";
import * as Modal from "../../modal/Modal.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Radio from "../../inputs/Radio.mjs";
import * as Title from "../../common/Title.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Button from "../../common/Button.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as DOMFile from "../../../libraries/DOMFile.mjs";
import * as Labeled from "../../inputs/Labeled.mjs";
import * as Popover from "../../common/Popover/Popover.mjs";
import * as Spinner from "../../common/Spinner/Spinner.mjs";
import * as ApiRoute from "../../../types/ApiRoute.mjs";
import * as PromiseF from "../../../libraries/PromiseF.mjs";
import * as FormInput from "../../inputs/FormInput.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as OldFileInput from "../../inputs/OldFileInput/OldFileInput.mjs";
import * as Backend_Errors from "../../../libraries/backend/Backend_Errors.mjs";
import * as PrintLayoutAPI from "./PrintLayoutAPI.mjs";
import * as PrintLayoutTypes from "./PrintLayoutTypes.mjs";
import * as PrintLayoutModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/print_layouts/PrintLayout.module.css";

var save$p = Utils.Translations.tr("common.save");

var add$p = Utils.Translations.tr("common.add");

var cancel$p = Utils.Translations.tr("common.cancel");

var delete$p = Utils.Translations.tr("common.remove");

var confirm$p = Utils.Translations.tr("common.yes_remove");

var name$p = Utils.Translations.t("js.admin.print_layouts.name");

var layout$p = Utils.Translations.t("js.admin.print_layouts.layout");

var replace$p = Utils.Translations.tr("js.admin.print_layouts.replace");

var deleteLayout$p = Utils.Translations.tr("js.admin.print_layouts.delete_layout");

var default$p = Utils.Translations.t("js.titles.print_layouts.default_layout");

var download$p = Utils.Translations.t("js.admin.print_layouts.download");

var outputFormat$p = Utils.Translations.t("js.admin.print_layouts.output_format");

var limitMsg$p = Utils.Translations.t("js.admin.print_layouts.limit_msg");

var styles = PrintLayoutModuleCss;

function PrintLayoutShow$DownloadLink(Props) {
  var uuid = Props.uuid;
  var replaceFile = Props.replaceFile;
  var name = Props.name;
  var match = AppContext.useWire();
  return React.createElement("div", {
              className: styles.downloadLink
            }, React.createElement("a", {
                  download: "download",
                  href: ApiRoute.path({
                        NAME: "API",
                        VAL: {
                          NAME: "PrintLayout",
                          VAL: {
                            NAME: "Download",
                            VAL: uuid
                          }
                        }
                      }),
                  target: "_blank"
                }, name), replaceFile !== undefined ? React.createElement("div", undefined, React.createElement(Button.make, {
                        onClick: replaceFile,
                        children: replace$p(match.ctx)
                      })) : null);
}

function PrintLayoutShow(Props) {
  var mode = Props.mode;
  var onAction = Props.onAction;
  var wire = AppContext.useWire();
  var navigate = wire.navigate;
  var route = wire.route;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        
      });
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        return Backend_Errors.empty;
      });
  var setErrors = match$1[1];
  var errors = match$1[0];
  var updateState = function (f) {
    setState(function (s) {
          if (s !== undefined && s.TAG !== "Default") {
            return {
                    TAG: "Custom",
                    _0: f(s._0)
                  };
          } else {
            return s;
          }
        });
  };
  var new_ = typeof mode === "object" ? false : true;
  var match$2 = React.useMemo((function () {
          if (state === undefined) {
            return [
                    undefined,
                    null
                  ];
          }
          if (new_) {
            return [
                    undefined,
                    null
                  ];
          }
          var values;
          values = state.TAG === "Default" ? ({
                name: default$p(ctx)
              }) : ({
                name: state._0.name
              });
          return [
                  Caml_option.some(values),
                  React.createElement(Title.make, {
                        route: route,
                        ctx: ctx,
                        values: values,
                        wire: wire
                      })
                ];
        }), [RCore.$$Option.isSome(state)]);
  var match$3 = Hooks.useLocked(false);
  var unlock = match$3[2];
  var lock = match$3[1];
  var locked = true;
  if (!match$3[0]) {
    var tmp;
    tmp = state !== undefined && state.TAG !== "Default" ? false : true;
    locked = tmp;
  }
  var match$4 = React.useState(function () {
        return false;
      });
  var setConfirmation = match$4[1];
  var showConfirmation = match$4[0];
  var removeButtonId = Hooks.useId(undefined);
  var removeButton = React.useRef(null);
  Hooks.useToggleOnClick1(removeButton, showConfirmation, (function (show) {
          setConfirmation(function (param) {
                return show;
              });
        }));
  React.useEffect((function () {
          if (typeof mode === "object") {
            var loaded = function (r) {
              if (r.TAG !== "Ok") {
                return ;
              }
              var s = r._0;
              setState(function (param) {
                    return PrintLayoutTypes.Show.State.make(s.stencil, s.templates, s.jobTypes);
                  });
            };
            Backend.$$finally(PrintLayoutAPI.Show.show(wire, mode.VAL), (function (l) {
                    Utils.debounce1(loaded, 300, false)(l);
                  }));
          } else {
            var loaded$1 = function (r) {
              if (r.TAG !== "Ok") {
                return ;
              }
              var s = r._0;
              setState(function (param) {
                    return PrintLayoutTypes.Show.State.make(undefined, s.templates, s.jobTypes);
                  });
            };
            Backend.$$finally(PrintLayoutAPI.New.new_(wire), (function (l) {
                    Utils.debounce1(loaded$1, 300, false)(l);
                  }));
          }
        }), []);
  var fileId = Hooks.useId(undefined);
  var onSubmit = function (param) {
    if (state === undefined) {
      return ;
    }
    if (state.TAG === "Default") {
      return ;
    }
    var s = state._0;
    var uuid = s.uuid;
    if (uuid !== undefined) {
      var uploaded = function (result) {
        if (result.TAG === "Ok") {
          onAction();
          return navigate(undefined, {
                      NAME: "PrintLayout",
                      VAL: "Index"
                    });
        }
        var errors = result._0;
        if (typeof errors !== "object") {
          return unlock();
        }
        if (errors.TAG !== "ValidationError") {
          return unlock();
        }
        var errors$1 = errors._0;
        unlock();
        setErrors(function (param) {
              return errors$1;
            });
      };
      lock();
      return Backend.$$finally(PrintLayoutAPI.Update.update(wire, Caml_option.valFromOption(uuid), PrintLayoutAPI.Update.$$Request.fromState(s)), uploaded);
    }
    var request = PrintLayoutAPI.Create.$$Request.fromState(s);
    if (request === undefined) {
      return ;
    }
    var uploaded$1 = function (result) {
      if (result.TAG === "Ok") {
        onAction();
        return navigate(undefined, {
                    NAME: "PrintLayout",
                    VAL: "Index"
                  });
      }
      var errors = result._0;
      if (typeof errors !== "object") {
        return unlock();
      }
      if (errors.TAG !== "ValidationError") {
        return unlock();
      }
      var errors$1 = errors._0;
      unlock();
      setErrors(function (param) {
            return errors$1;
          });
    };
    lock();
    Backend.$$finally(PrintLayoutAPI.Create.create(wire, request), uploaded$1);
  };
  var setFile = function (file) {
    updateState(function (s) {
          return {
                  uuid: s.uuid,
                  name: s.name,
                  templates: s.templates,
                  jobTypes: s.jobTypes,
                  file: s.file,
                  domFile: Caml_option.some(file),
                  outputFormat: s.outputFormat
                };
        });
    var __x = DOMFile.base64Content(file);
    PromiseF.$$finally((function (body) {
            updateState(function (s) {
                  return {
                          uuid: s.uuid,
                          name: s.name,
                          templates: s.templates,
                          jobTypes: s.jobTypes,
                          file: {
                            TAG: "New",
                            name: file.name,
                            body: body
                          },
                          domFile: s.domFile,
                          outputFormat: s.outputFormat
                        };
                });
          }), (function (prim) {
            
          }), __x);
  };
  var clearFile = function () {
    updateState(function (s) {
          return {
                  uuid: s.uuid,
                  name: s.name,
                  templates: s.templates,
                  jobTypes: s.jobTypes,
                  file: undefined,
                  domFile: undefined,
                  outputFormat: s.outputFormat
                };
        });
  };
  var replaceFile = function (param) {
    clearFile();
  };
  var tmp$1 = {};
  var tmp$2 = match$2[0];
  if (tmp$2 !== undefined) {
    tmp$1.values = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3;
  if (state !== undefined) {
    if (state.TAG === "Default") {
      tmp$3 = React.createElement(PrintLayoutShow$DownloadLink, {
            uuid: state.uuid,
            name: download$p(ctx)
          });
    } else {
      var s = state._0;
      var tmp$4 = {
        _type: "input",
        value: s.name,
        onValueChange: (function (name) {
            updateState(function (s) {
                  return {
                          uuid: s.uuid,
                          name: name,
                          templates: s.templates,
                          jobTypes: s.jobTypes,
                          file: s.file,
                          domFile: s.domFile,
                          outputFormat: s.outputFormat
                        };
                });
          }),
        labelText: name$p(ctx),
        autoFocus: true
      };
      var tmp$5 = Backend_Errors.get(errors, "name");
      if (tmp$5 !== undefined) {
        tmp$4.errors = Caml_option.valFromOption(tmp$5);
      }
      var match$5 = s.domFile;
      var match$6 = s.file;
      var match$7 = s.uuid;
      var tmp$6;
      var exit = 0;
      if (match$5 !== undefined || !(match$6 !== undefined && !(match$6.TAG === "New" || match$7 === undefined))) {
        exit = 1;
      } else {
        tmp$6 = React.createElement(PrintLayoutShow$DownloadLink, {
              uuid: Caml_option.valFromOption(match$7),
              replaceFile: replaceFile,
              name: match$6.name
            });
      }
      if (exit === 1) {
        var tmp$7 = {
          ctx: ctx,
          onFileChooseAction: setFile,
          onFileClearAction: clearFile,
          file: s.domFile,
          limit: [
            1048576,
            limitMsg$p(ctx)
          ]
        };
        var tmp$8 = Backend_Errors.getFirst(errors, "file.name");
        if (tmp$8 !== undefined) {
          tmp$7.error = tmp$8;
        }
        tmp$6 = React.createElement(OldFileInput.make, tmp$7);
      }
      tmp$3 = React.createElement("form", undefined, React.createElement(FormInput.make, tmp$4), React.createElement(Labeled.make, {
                label: layout$p(ctx),
                id: fileId,
                children: tmp$6
              }), React.createElement(Labeled.make, {
                label: outputFormat$p(ctx),
                children: null
              }, React.createElement(Radio.make, {
                    onChange: (function (param) {
                        updateState(function (s) {
                              return {
                                      uuid: s.uuid,
                                      name: s.name,
                                      templates: s.templates,
                                      jobTypes: s.jobTypes,
                                      file: s.file,
                                      domFile: s.domFile,
                                      outputFormat: "HTML"
                                    };
                            });
                      }),
                    checked: s.outputFormat === "HTML",
                    labelText: "HTML"
                  }), React.createElement(Radio.make, {
                    onChange: (function (param) {
                        updateState(function (s) {
                              return {
                                      uuid: s.uuid,
                                      name: s.name,
                                      templates: s.templates,
                                      jobTypes: s.jobTypes,
                                      file: s.file,
                                      domFile: s.domFile,
                                      outputFormat: "PDF"
                                    };
                            });
                      }),
                    checked: s.outputFormat === "PDF",
                    labelText: "PDF"
                  })));
    }
  } else {
    tmp$3 = React.createElement(Spinner.make, {});
  }
  var tmp$9;
  if (typeof mode === "object") {
    var uuid = mode.VAL;
    tmp$9 = React.createElement(Popover.make, {
          footer: wire.footer,
          show: showConfirmation,
          buttonId: removeButtonId,
          position: "right",
          children: React.createElement("div", {
                className: styles.removalConfirmation
              }, React.createElement("h5", undefined, deleteLayout$p(ctx)), React.createElement("footer", undefined, React.createElement(Button.make, {
                        onClick: (function (param) {
                            lock();
                            setConfirmation(function (param) {
                                  return false;
                                });
                            Backend.$$finally(PrintLayoutAPI.Delete.$$delete(wire, uuid), (function (param) {
                                    onAction();
                                    navigate(undefined, {
                                          NAME: "PrintLayout",
                                          VAL: "Index"
                                        });
                                  }));
                          }),
                        flavor: "danger",
                        children: confirm$p(ctx)
                      }), React.createElement(Button.make, {
                        onClick: (function (param) {
                            setConfirmation(function (param) {
                                  return false;
                                });
                          }),
                        children: cancel$p(ctx)
                      })))
        });
  } else {
    tmp$9 = null;
  }
  return React.createElement(React.Fragment, undefined, match$2[1], React.createElement(Modal.make, {
                  wire: wire,
                  onClose: (function () {
                      navigate(undefined, {
                            NAME: "PrintLayout",
                            VAL: "Index"
                          });
                    }),
                  children: React.createElement(Modal.Dialog.make, {
                        children: null
                      }, React.createElement(Modal.HeaderTitle.make, tmp$1), React.createElement(Modal.Body.make, {
                            children: React.createElement("div", {
                                  className: styles.editForm
                                }, tmp$3)
                          }), React.createElement(Modal.Footer.make, {
                            children: null
                          }, typeof mode === "object" ? React.createElement(React.Fragment, undefined, React.createElement(Modal.RemoveButton.make, {
                                      id: removeButtonId,
                                      forwardRef: removeButton,
                                      disabled: locked,
                                      children: delete$p(ctx)
                                    })) : null, React.createElement(Modal.CloseButton.make, {
                                ctx: ctx
                              }), React.createElement(Modal.SubmitButton.make, {
                                onClick: onSubmit,
                                disabled: locked,
                                children: new_ ? add$p(ctx) : save$p(ctx)
                              })))
                }), tmp$9);
}

var make = PrintLayoutShow;

export {
  make ,
}
/* save' Not a pure module */
