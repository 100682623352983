

import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as Pages_Sites_Types from "./Pages_Sites_Types.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              sites: field.required("sites", JsonDecode.array(Pages_Sites_Types.Site.decode)),
              totalClients: field.required("totalClients", JsonDecode.$$int),
              totalSites: field.required("totalSites", JsonDecode.$$int),
              canManage: field.required("canManage", JsonDecode.bool),
              isAdmin: field.required("isAdmin", JsonDecode.bool)
            };
    });

function get(wire, filter) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Site",
                    VAL: {
                      NAME: "Index",
                      VAL: filter
                    }
                  }
                }), decode);
}

var $$Response = {};

export {
  $$Response ,
  get ,
}
/* decode Not a pure module */
