import React from 'react'
import { withContext } from 'planado/utils/contextConsumer.jsx'
import { make as Filter } from 'rscrpt/components/common/AssigneesFilter/AssigneesFilter.mjs'

export const AssigneesFilter = withContext(
  ({ ctx, controller, onChange, ...props }) => (
    <Filter
      {...props}
      onChange={(filter) => onChange(ctx, controller, filter)}
    />
  )
)
