

import * as Wire from "../../../../../../../../../types/Wire.mjs";
import * as Hooks from "../../../../../../../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../../../../types/Types.mjs";
import * as Units from "../../../../../../../../../types/Units.mjs";
import * as React from "react";
import * as AppContext from "../../../../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as WeekTimeline_State from "../../../../../lib/state/WeekTimeline_State.mjs";

function use(elementRef) {
  var wire = AppContext.useWire();
  var match = WeekTimeline_State.useTimelineSubscriptions();
  var onScrollStart = match.onScrollStart;
  var match$1 = React.useState(function () {
        
      });
  var setDoubleClickCoordinate = match$1[1];
  var doubleClickCoordinate = match$1[0];
  var resetDoubleClickCoordinate = function () {
    setDoubleClickCoordinate(function (param) {
          
        });
  };
  var onDoubleClick = function ($$event) {
    setDoubleClickCoordinate(function (param) {
          return [
                  Units.Px.fromInt($$event.pageX),
                  Units.Px.fromInt($$event.pageY)
                ];
        });
  };
  React.useEffect((function () {
          var targetOpt = RCore.$$Option.map(Types.ReactRef.toOption(elementRef), (function (prim) {
                  return prim;
                }));
          if (targetOpt === undefined) {
            return ;
          }
          var target = Caml_option.valFromOption(targetOpt);
          if (Wire.hasRightsToEditJobs(wire)) {
            target.addEventListener("dblclick", onDoubleClick);
            return (function () {
                      target.removeEventListener("dblclick", onDoubleClick);
                    });
          }
          
        }), []);
  React.useEffect((function () {
          if (doubleClickCoordinate !== undefined) {
            return onScrollStart.subscribe(function (_event) {
                        setDoubleClickCoordinate(function (param) {
                              
                            });
                      });
          }
          
        }), [doubleClickCoordinate]);
  Hooks.useOnEscPress(doubleClickCoordinate === undefined, resetDoubleClickCoordinate);
  return [
          doubleClickCoordinate,
          resetDoubleClickCoordinate
        ];
}

export {
  use ,
}
/* Wire Not a pure module */
