const countMatchingSkills = (assignee, requiredSkillUuids) =>
  assignee.skills.filter(s => requiredSkillUuids.includes(s.uuid)).length


const hasPerfectSkillsMatch = (assignee, requiredSkillUuids) =>
  requiredSkillUuids.every(uuid =>
    assignee.skills.map(s => s.uuid).includes(uuid)
  )

export const hasAssigneeWithRequiredSkills = (assignees, requiredSkillUuids) =>
  requiredSkillUuids.length === 0 ||
  assignees.some(a => hasPerfectSkillsMatch(a, requiredSkillUuids))

export const hasAssigneeWithRequiredTerritory = (
  assignees,
  requiredTerritoryUuid,
  availableTerritories
) => {
  const hasAssignee = assignees.some(
    a => a.territory !== null && a.territory !== undefined && a.territory.uuid === requiredTerritoryUuid
  )

  const isAvailable = availableTerritories.some(
    t => t.uuid === requiredTerritoryUuid
  )

  return hasAssignee && isAvailable
}

export const sortByMatchingSkills = (assignees, requiredSkillUuids) =>
  assignees.slice().sort((a1, a2) => {
    const count1 = countMatchingSkills(a1, requiredSkillUuids)
    const count2 = countMatchingSkills(a2, requiredSkillUuids)
    return count2 - count1
  })

const sortAlphabetically = assignees =>
  assignees.slice().sort((a1, a2) => {
    if (a1.type === 'user' && a2.type === 'team') return 1
    if (a1.type === 'team' && a2.type === 'user') return -1
    else {
      if (a1.name > a2.name) return 1
      if (a1.name < a2.name) return -1
    }
  })

export const sortAssigneesBySkills = (assignees, requiredSkillUuids) => {
  if (requiredSkillUuids.length === 0) {
    return sortAlphabetically(assignees)
  } else {
    return sortByMatchingSkills(assignees, requiredSkillUuids)
  }
}

const matchAssigneeByTerritory = ({ territory }, territoryUuid) => {
  if (territory !== null && territory.uuid === territoryUuid) {
    return 1
  } else if (territory === null) {
    return 0
  } else {
    return -1
  }
}

const matchAssigneesByTerritory = (a, b, territoryUuid) => {
  const matchTerritoryA = matchAssigneeByTerritory(a, territoryUuid)
  const matchTerritoryB = matchAssigneeByTerritory(b, territoryUuid)

  if (territoryUuid === null || matchTerritoryA === matchTerritoryB) {
    return 0
  } else if (matchTerritoryA < matchTerritoryB) {
    return 1
  } else {
    return -1
  }
}

const matchAssigneesBySkills = (a, b, skillUuids) => {
  const countA = countMatchingSkills(a, skillUuids)
  const countB = countMatchingSkills(b, skillUuids)

  if (skillUuids.length === 0 || countA === countB) {
    return 0
  } else if (countA < countB) {
    return 1
  } else {
    return -1
  }
}

const sortByMatchingSkillsAndTerritories = (
  assignees,
  requiredSkillUuids,
  requiredTerritoryUuid
) =>
  assignees
    .slice()
    .sort(
      (a, b) =>
        matchAssigneesByTerritory(a, b, requiredTerritoryUuid) +
        matchAssigneesBySkills(a, b, requiredSkillUuids)
    )

export const sortAssigneesBySkillsAndTerritories = (
  assignees,
  requiredSkillUuids,
  requiredTerritoryUuid
) => {
  if (requiredSkillUuids.length === 0 && requiredTerritoryUuid === null) {
    return sortAlphabetically(assignees)
  } else {
    return sortByMatchingSkillsAndTerritories(
      sortAlphabetically(assignees),
      requiredSkillUuids,
      requiredTerritoryUuid
    )
  }
}
