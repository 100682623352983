

import * as Fun from "../../../../../libraries/Fun.mjs";
import * as RCore from "../../../../../libraries/RCore.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function use(stats, fullWidthPx) {
  var unsuccessfullyFinished = stats.unsuccessfullyFinished;
  var successfullyFinished = stats.successfullyFinished;
  var suspended = stats.suspended;
  var started = stats.started;
  var enRoute = stats.enRoute;
  var published = stats.published;
  var all = stats.all;
  var publishedPx;
  if (published === 0) {
    publishedPx = undefined;
  } else {
    var width = fullWidthPx * published / all;
    publishedPx = width >= 15.0 ? [
        width,
        0.0
      ] : [
        15.0,
        15.0 - width
      ];
  }
  var inWork = (enRoute + started | 0) + suspended | 0;
  var blockCount = RCore.$$Array.keep([
        enRoute,
        started,
        suspended
      ], (function (jobs) {
          return jobs !== 0;
        })).length;
  var inWorkPx;
  if (blockCount === 0) {
    inWorkPx = undefined;
  } else {
    var minWidth = 15.0 * blockCount - 2.0;
    var width$1 = fullWidthPx * inWork / all - 2.0;
    inWorkPx = width$1 >= minWidth ? [
        width$1,
        0.0
      ] : [
        minWidth,
        minWidth - width$1
      ];
  }
  var finished = successfullyFinished + unsuccessfullyFinished | 0;
  var blockCount$1 = RCore.$$Array.keep([
        successfullyFinished,
        unsuccessfullyFinished
      ], (function (jobs) {
          return jobs !== 0;
        })).length;
  var finishedPx;
  if (blockCount$1 === 0) {
    finishedPx = undefined;
  } else {
    var minWidth$1 = 15.0 * blockCount$1 - 2.0;
    var width$2 = fullWidthPx * finished / all - 2.0;
    finishedPx = width$2 >= minWidth$1 ? [
        width$2,
        0.0
      ] : [
        minWidth$1,
        minWidth$1 - width$2
      ];
  }
  var blockCount$2 = RCore.$$Array.filterMap([
        publishedPx,
        inWorkPx,
        finishedPx
      ], Fun.id).length;
  var minWidthBlockCount = RCore.$$Array.filterMap([
        publishedPx,
        inWorkPx,
        finishedPx
      ], (function (block) {
          if (block !== undefined && block[1] !== 0.0) {
            return Caml_option.some(undefined);
          }
          
        })).length;
  if (minWidthBlockCount === 0.0) {
    return {
            publishedPx: RCore.$$Option.map(publishedPx, (function (param) {
                    return param[0];
                  })),
            inWorkPx: RCore.$$Option.map(inWorkPx, (function (param) {
                    return param[0];
                  })),
            finishedPx: RCore.$$Option.map(finishedPx, (function (param) {
                    return param[0];
                  }))
          };
  }
  var allDiffWidth = RCore.$$Array.reduce([
        publishedPx,
        inWorkPx,
        finishedPx
      ], 0.0, (function (acc, block) {
          if (block === undefined) {
            return acc;
          }
          var diff = block[1];
          if (diff !== 0.0) {
            return acc + diff;
          } else {
            return acc;
          }
        }));
  var diffWidth = allDiffWidth / (blockCount$2 - minWidthBlockCount);
  return {
          publishedPx: RCore.$$Option.map(publishedPx, (function (param) {
                  var publishedPx = param[0];
                  if (param[1] === 0.0) {
                    return publishedPx - diffWidth;
                  } else {
                    return publishedPx;
                  }
                })),
          inWorkPx: RCore.$$Option.map(inWorkPx, (function (param) {
                  var inWorkPx = param[0];
                  if (param[1] === 0.0) {
                    return inWorkPx - diffWidth;
                  } else {
                    return inWorkPx;
                  }
                })),
          finishedPx: RCore.$$Option.map(finishedPx, (function (param) {
                  var finishedPx = param[0];
                  if (param[1] === 0.0) {
                    return finishedPx - diffWidth;
                  } else {
                    return finishedPx;
                  }
                }))
        };
}

export {
  use ,
}
/* No side effect */
