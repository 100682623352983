

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Locale from "../../../libraries/Locale.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ShiftsTypes from "../ShiftsTypes.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              users: field.required("users", JsonDecode.array(ShiftsTypes.$$Worker.decode)),
              teams: field.required("teams", JsonDecode.array(ShiftsTypes.Team.decode)),
              jobTypes: field.required("jobTypes", JsonDecode.array(ShiftsTypes.SelectOption.decode)),
              territories: field.required("territories", JsonDecode.array(ShiftsTypes.SelectOption.decode))
            };
    });

function list(wire, currentInterval, userUuids, teamUuids) {
  var format = Locale.T.formatM("DateISO");
  return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Shifts",
                    VAL: {
                      NAME: "Index",
                      VAL: [
                        format(currentInterval[0]),
                        format(currentInterval[1]),
                        userUuids,
                        teamUuids
                      ]
                    }
                  }
                }), decode);
}

function encodeConstructorWorker(repeatMonths) {
  return function (worker) {
    var match = worker.dates;
    if (match === undefined) {
      return ;
    }
    var max = match[1];
    if (max !== undefined) {
      return {
              userUuid: Uuid.toString(worker.uuid),
              dateFrom: Locale.T.isoDate(match[0]),
              dateTo: Locale.T.isoDate(Caml_option.valFromOption(max)),
              repeatMonths: repeatMonths
            };
    }
    
  };
}

function makePayload(repeatMonths, constructorWorkers) {
  return {
          users: RCore.$$Array.filterMap(constructorWorkers, encodeConstructorWorker(repeatMonths))
        };
}

function patch(wire, repeatMonths, constructorWorkers) {
  return Backend.discard(Backend.patch(undefined, true, undefined, true, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Shifts",
                    VAL: "WorkersConstructor"
                  }
                }, {
                  NAME: "Obj",
                  VAL: makePayload(repeatMonths, constructorWorkers)
                }));
}

function encodeConstructorWorker$1(repeatMonths) {
  return function (worker) {
    var match = worker.dates;
    if (match === undefined) {
      return ;
    }
    var max = match[1];
    if (max !== undefined) {
      return {
              teamUuid: Uuid.toString(worker.uuid),
              dateFrom: Locale.T.isoDate(match[0]),
              dateTo: Locale.T.isoDate(Caml_option.valFromOption(max)),
              repeatMonths: repeatMonths
            };
    }
    
  };
}

function makePayload$1(repeatMonths, constructorWorkers) {
  return {
          teams: RCore.$$Array.filterMap(constructorWorkers, encodeConstructorWorker$1(repeatMonths))
        };
}

function patch$1(wire, repeatMonths, constructorWorkers) {
  return Backend.discard(Backend.patch(undefined, true, undefined, true, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Shifts",
                    VAL: "TeamsConstructor"
                  }
                }, {
                  NAME: "Obj",
                  VAL: makePayload$1(repeatMonths, constructorWorkers)
                }));
}

var $$Response = {};

var WorkersConstructor = {
  patch: patch
};

var TeamsConstructor = {
  patch: patch$1
};

export {
  $$Response ,
  list ,
  WorkersConstructor ,
  TeamsConstructor ,
}
/* decode Not a pure module */
