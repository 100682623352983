

import * as Fun from "../../../../../../../../libraries/Fun.mjs";
import * as RCore from "../../../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../../../types/Types.mjs";
import * as Units from "../../../../../../../../types/Units.mjs";
import * as React from "react";
import * as DomUtils from "../../../../../../../../DomUtils.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as WeekTimeline_StateHooks from "../../../../lib/state/WeekTimeline_StateHooks.mjs";
import * as Webapi__Dom__HtmlElement from "rescript-webapi/lib/es6/src/Webapi/Dom/Webapi__Dom__HtmlElement.mjs";

function use(elementRef, jobs) {
  var match = WeekTimeline_StateHooks.useTimelineSubscriptions();
  var onScrollStart = match.onScrollStart;
  React.useEffect((function () {
          var element = Types.ReactRef.toOption(elementRef);
          if (element === undefined) {
            return ;
          }
          var element$1 = Caml_option.valFromOption(element);
          var onWheel = function ($$event) {
            $$event.stopPropagation();
            onScrollStart.trigger([
                  Units.Px.zero,
                  Units.Px.zero
                ]);
          };
          element$1.addEventListener("wheel", onWheel);
          return (function () {
                    element$1.removeEventListener("wheel", onWheel);
                  });
        }), [
        jobs,
        elementRef.current
      ]);
}

var StopPropagateWheelEvent = {
  use: use
};

var basePadding = Units.Px.fromInt(16);

function use$1(elementRef, jobs) {
  React.useEffect((function () {
          var elementOpt = Types.ReactRef.toOption(elementRef);
          var htmlElementOpt = RCore.$$Option.flatMap(elementOpt, Webapi__Dom__HtmlElement.ofElement);
          var match = Fun.both(elementOpt, htmlElementOpt);
          if (match !== undefined) {
            var htmlElement = match[1];
            var offsetHeight = htmlElement.offsetHeight;
            var scrollHeight = match[0].scrollHeight;
            var style = htmlElement.style;
            var padding = scrollHeight > offsetHeight ? basePadding : Units.Px.plus(DomUtils.scrollbarWidth(), basePadding);
            style.setProperty("padding-right", Units.Px.toString(padding));
          }
          
        }), [
        jobs,
        elementRef.current
      ]);
}

var UpdateRightPadding = {
  use: use$1
};

export {
  StopPropagateWheelEvent ,
  UpdateRightPadding ,
}
/* basePadding Not a pure module */
