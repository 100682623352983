import { connect } from 'react-redux'
import Footer from 'planado/components/footer'

const FooterC = {
  mapStateToProps: ({ popover }) => ({
    popover
  }),
  mapDispatchToProps: () => ({})
}

const FooterLink = connect(
  FooterC.mapStateToProps,
  FooterC.mapDispatchToProps
)(Footer)

export default FooterLink
