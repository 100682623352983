

import * as El from "../libraries/El.mjs";
import * as React from "react";
import * as UI_PageHeaderModuleCss from "/home/runner/work/planado/planado/client/rescript/ui/UI_PageHeader.module.css";

var styles = UI_PageHeaderModuleCss;

function UI_PageHeader(Props) {
  var wrapperClassName = Props.wrapperClassName;
  var headerClassName = Props.headerClassName;
  var children = Props.children;
  return React.createElement("div", {
              className: El.Cn.concatOpt(styles.wrapper, wrapperClassName)
            }, React.createElement("div", {
                  className: El.Cn.concatOpt(styles.header, headerClassName)
                }, children));
}

var make = UI_PageHeader;

export {
  make ,
}
/* styles Not a pure module */
