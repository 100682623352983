


function reducer(param) {
  var action = param[1];
  var state = param[0];
  if (action.TAG === "SizesChanged") {
    return {
            TAG: "Update",
            _0: {
              sizes: action._0,
              timelineSubscriptions: state.timelineSubscriptions,
              assignJobBlocks: state.assignJobBlocks
            }
          };
  } else {
    return {
            TAG: "Update",
            _0: {
              sizes: state.sizes,
              timelineSubscriptions: state.timelineSubscriptions,
              assignJobBlocks: action._0
            }
          };
  }
}

export {
  reducer ,
}
/* No side effect */
