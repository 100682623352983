

import * as El from "../../../../../../libraries/El.mjs";
import * as Fun from "../../../../../../libraries/Fun.mjs";
import * as Icon from "../../../../../../Icon.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Radio from "../../../../../inputs/Radio.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as $$Number from "../../../../../../libraries/Number.mjs";
import * as Optional from "../../../../../common/Optional.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as JobIndexFilter_CustomWrapper from "./JobIndexFilter_CustomWrapper.mjs";
import * as JobIndexFilter_CustomModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/index/job_index_filter/components/custom/JobIndexFilter_Custom.module.css";

var styles = JobIndexFilter_CustomModuleCss;

var filled$p = Utils.Translations.tr("js.jobs.index.filters.number.filled.name");

var any$p = Utils.Translations.t("js.jobs.index.filters.number.filled.any");

var notFilled$p = Utils.Translations.tr("js.jobs.index.filters.number.not_filled");

var less$p = Utils.Translations.t("js.jobs.index.filters.number.filled.less");

var greater$p = Utils.Translations.t("js.jobs.index.filters.number.filled.greater");

var interval$p = Utils.Translations.t("js.jobs.index.filters.number.filled.interval");

var intervalWithBounds$p = Utils.Translations.txr("js.jobs.index.filters.number.filled.interval_with_bounds");

var equal$p = Utils.Translations.t("js.jobs.index.filters.number.filled.equal");

function JobIndexFilter_CustomNumber$NumberInput(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var onBlur = Props.onBlur;
  var onKeyPress = Props.onKeyPress;
  var disabled = Props.disabled;
  var withSublabelOpt = Props.withSublabel;
  var withSublabel = withSublabelOpt !== undefined ? withSublabelOpt : false;
  var wrapperClass = withSublabel ? styles.textInputContainerLessMargin : styles.textInputContainer;
  return React.createElement("div", {
              className: wrapperClass
            }, React.createElement("div", {
                  className: El.Cn.concat(El.Cn.concat(styles.searchSymbol, styles.textInputSearchSymbol), Icon.style(undefined, undefined, "search"))
                }), React.createElement("div", undefined, React.createElement("input", {
                      className: El.Cn.concat("pd-form-control", styles.textInput),
                      autoFocus: true,
                      disabled: disabled,
                      type: "text",
                      value: value,
                      onKeyPress: onKeyPress,
                      onBlur: onBlur,
                      onChange: onChange
                    })));
}

var empty = "";

function JobIndexFilter_CustomNumber(Props) {
  var label = Props.label;
  var toggleFilter = Props.toggleFilter;
  var show = Props.show;
  var resetFilter = Props.resetFilter;
  var filterByField = Props.filterByField;
  var dataType = Props.dataType;
  var value = Props.value;
  var disabled = Props.disabled;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var $$default = dataType === "Integer" ? "0" : "0.0";
  var validateString = function (p) {
    if (p === undefined) {
      return ;
    }
    if (dataType !== "Decimal") {
      return RCore.$$Option.map($$Number.$$BigInt.make(p), $$Number.$$BigInt.toString);
    }
    var valid = $$Number.Decimal.fromString(p, ctx);
    if (valid.TAG === "Ok") {
      return valid._0;
    }
    
  };
  var validateInterval = function (v) {
    return {
            start: validateString(v.start),
            finish: validateString(v.finish)
          };
  };
  var makeNumberString = function (stringValue) {
    if (stringValue === "-" || stringValue === empty) {
      return stringValue;
    } else if (dataType === "Integer") {
      return RCore.$$Option.getOr(RCore.$$Option.map($$Number.$$BigInt.make(stringValue), $$Number.$$BigInt.toString), $$default);
    } else {
      var string = $$Number.Decimal.fromString(stringValue, ctx);
      if (string.TAG !== "Ok") {
        return $$default;
      }
      var string$1 = string._0;
      if (Js_string.endsWith(".", stringValue)) {
        return string$1 + ".";
      } else {
        return string$1;
      }
    }
  };
  var swapBounds = function (value) {
    if (value === undefined) {
      return value;
    }
    var s = value.start;
    if (s === undefined) {
      return value;
    }
    var f = value.finish;
    if (f === undefined) {
      return value;
    }
    var match = Fun.both($$Number.Decimal.make(s), $$Number.Decimal.make(f));
    if (match !== undefined && match[0].gt(match[1])) {
      return {
              start: f,
              finish: s
            };
    } else {
      return value;
    }
  };
  var intervalValue = function (value) {
    if (value === undefined) {
      return ;
    }
    if (typeof value !== "object") {
      return ;
    }
    if (value.TAG !== "Interval") {
      return ;
    }
    var i = value._0;
    return {
            start: i.start,
            finish: i.finish
          };
  };
  var exactValue = function (value) {
    if (value !== undefined && !(typeof value !== "object" || value.TAG !== "Eql")) {
      return value._0;
    }
    
  };
  var match$1 = React.useState(function () {
        return intervalValue(value);
      });
  var $$setInterval = match$1[1];
  var interval = match$1[0];
  var match$2 = React.useState(function () {
        return exactValue(value);
      });
  var setExact = match$2[1];
  var exact = match$2[0];
  var valuePart = function (part) {
    var exit = 0;
    if (exact !== undefined) {
      if (part === "Exact") {
        return makeNumberString(exact);
      }
      exit = 1;
    } else {
      exit = 1;
    }
    if (exit === 1) {
      if (interval === undefined) {
        return empty;
      }
      var s = interval.start;
      var exit$1 = 0;
      var f = interval.finish;
      if (f !== undefined) {
        if (part === "Finish") {
          return makeNumberString(f);
        }
        exit$1 = 2;
      } else {
        exit$1 = 2;
      }
      if (exit$1 === 2) {
        if (s !== undefined && part === "Start") {
          return makeNumberString(s);
        } else {
          return empty;
        }
      }
      
    }
    
  };
  var onResetClick = function (param) {
    resetFilter();
    toggleFilter(false);
  };
  var onChange = function (evt, part) {
    var value = evt.target.value;
    if (part === "Exact") {
      setExact(function (param) {
            return makeNumberString(value);
          });
      return $$setInterval(function (param) {
                  
                });
    } else {
      $$setInterval(function (current) {
            if (current !== undefined) {
              if (part === "Start") {
                return {
                        start: makeNumberString(value),
                        finish: current.finish
                      };
              } else if (part === "Finish") {
                return {
                        start: current.start,
                        finish: makeNumberString(value)
                      };
              } else {
                return current;
              }
            } else {
              return current;
            }
          });
      return setExact(function (param) {
                  
                });
    }
  };
  var filterByNumberValue = function () {
    var match = validateString(exact);
    var match$1 = swapBounds(RCore.$$Option.map(interval, validateInterval));
    if (match !== undefined) {
      return filterByField({
                  TAG: "Eql",
                  _0: match
                });
    } else if (match$1 !== undefined && (RCore.$$Option.isSome(match$1.start) || RCore.$$Option.isSome(match$1.finish))) {
      return filterByField({
                  TAG: "Interval",
                  _0: {
                    start: match$1.start,
                    finish: match$1.finish
                  }
                });
    } else {
      return ;
    }
  };
  var onKeyPress = function (evt) {
    if (evt.key === "Enter") {
      return filterByNumberValue();
    }
    
  };
  var onBlur = function (_evt) {
    filterByNumberValue();
  };
  var tmp;
  if (value !== undefined) {
    if (typeof value !== "object") {
      tmp = value === "NotFilled" ? notFilled$p(ctx) : any$p(ctx);
    } else if (value.TAG === "Eql") {
      tmp = equal$p(ctx) + El.Cn.concat(":", value._0);
    } else {
      var match$3 = value._0;
      var s = match$3.start;
      if (s !== undefined) {
        var f = match$3.finish;
        tmp = f !== undefined ? (
            s === f ? equal$p(ctx) + El.Cn.concat(":", f) : intervalWithBounds$p({
                    start: s,
                    finish: f
                  }, ctx)
          ) : El.Cn.concat(greater$p(ctx), s);
      } else {
        var f$1 = match$3.finish;
        tmp = f$1 !== undefined ? El.Cn.concat(less$p(ctx), f$1) : any$p(ctx);
      }
    }
  } else {
    tmp = null;
  }
  var renderSelected = React.createElement("div", {
        className: "j-filter__selected"
      }, tmp);
  var match$4 = value !== undefined ? (
      typeof value !== "object" ? (
          value === "NotFilled" ? [
              true,
              false,
              false,
              false
            ] : [
              true,
              true,
              false,
              false
            ]
        ) : (
          value.TAG === "Eql" ? [
              true,
              true,
              true,
              false
            ] : [
              true,
              true,
              false,
              true
            ]
        )
    ) : [
      false,
      false,
      false,
      false
    ];
  var hasInterval = match$4[3];
  var hasExactValue = match$4[2];
  var hasFilledValue = match$4[1];
  var hasValue = match$4[0];
  var setFilledWithInterval = function () {
    var valueOnToggle = exact !== undefined && exact !== empty ? exact : $$default;
    filterByField({
          TAG: "Interval",
          _0: {
            start: valueOnToggle,
            finish: valueOnToggle
          }
        });
  };
  React.useEffect((function () {
          $$setInterval(function (param) {
                return intervalValue(value);
              });
          setExact(function (param) {
                return exactValue(value);
              });
        }), [value]);
  return React.createElement(JobIndexFilter_CustomWrapper.make, {
              toggleFilter: toggleFilter,
              onResetClick: onResetClick,
              renderSelected: renderSelected,
              label: label,
              show: show,
              hasValue: hasValue,
              leftButton: {
                onClick: (function (param) {
                    filterByField("Any");
                  }),
                checked: hasFilledValue,
                text: filled$p(ctx)
              },
              rightButton: {
                onClick: (function (param) {
                    filterByField("NotFilled");
                  }),
                checked: hasValue && !hasFilledValue,
                text: notFilled$p(ctx)
              },
              disabled: disabled,
              children: React.createElement(Optional.make, {
                    show: hasFilledValue,
                    children: null
                  }, React.createElement(Radio.make, {
                        onChange: (function (param) {
                            filterByField("Any");
                          }),
                        checked: !hasExactValue && !hasInterval,
                        labelText: any$p(ctx),
                        wrapperClass: styles.radio,
                        disabled: disabled
                      }), React.createElement(Radio.make, {
                        onChange: (function (param) {
                            filterByField({
                                  TAG: "Eql",
                                  _0: $$default
                                });
                          }),
                        checked: hasExactValue,
                        labelText: equal$p(ctx),
                        wrapperClass: styles.radio,
                        disabled: disabled
                      }), React.createElement(Optional.make, {
                        show: hasExactValue,
                        children: React.createElement(JobIndexFilter_CustomNumber$NumberInput, {
                              value: valuePart("Exact"),
                              onChange: (function (e) {
                                  onChange(e, "Exact");
                                }),
                              onBlur: onBlur,
                              onKeyPress: onKeyPress,
                              disabled: disabled
                            })
                      }), React.createElement(Radio.make, {
                        onChange: (function (param) {
                            setFilledWithInterval();
                          }),
                        checked: hasInterval,
                        labelText: interval$p(ctx),
                        wrapperClass: styles.radio,
                        disabled: disabled
                      }), React.createElement(Optional.make, {
                        show: hasInterval,
                        children: null
                      }, React.createElement("div", {
                            className: styles.textInputSublabel
                          }, greater$p(ctx)), React.createElement(JobIndexFilter_CustomNumber$NumberInput, {
                            value: valuePart("Start"),
                            onChange: (function (e) {
                                onChange(e, "Start");
                              }),
                            onBlur: onBlur,
                            onKeyPress: onKeyPress,
                            disabled: disabled,
                            withSublabel: true
                          }), React.createElement("div", {
                            className: styles.textInputSublabel
                          }, less$p(ctx)), React.createElement(JobIndexFilter_CustomNumber$NumberInput, {
                            value: valuePart("Finish"),
                            onChange: (function (e) {
                                onChange(e, "Finish");
                              }),
                            onBlur: onBlur,
                            onKeyPress: onKeyPress,
                            disabled: disabled,
                            withSublabel: true
                          })))
            });
}

var make = JobIndexFilter_CustomNumber;

export {
  make ,
}
/* styles Not a pure module */
