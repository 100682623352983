

import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../../common/Optional.mjs";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as Schedule_StateHooks from "../../lib/state/Schedule_StateHooks.mjs";
import * as Schedule_HeaderSwitch from "./switch/Schedule_HeaderSwitch.mjs";
import * as Schedule_Header_Right from "./right/Schedule_Header_Right.mjs";
import * as Schedule_HeaderWarning from "./warning/Schedule_HeaderWarning.mjs";
import * as Schedule_HeaderInterval from "./interval/Schedule_HeaderInterval.mjs";
import * as Schedule_HeaderModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/header/Schedule_Header.module.css";

var heading$p = Utils.Translations.tr("js.schedule.v2.heading");

var styles = Schedule_HeaderModuleCss;

function Schedule_Header(Props) {
  var view = Props.view;
  var match = AppContext.useWire();
  var isNewSchedule = Schedule_StateHooks.IsNew.use();
  return React.createElement("div", {
              className: styles.header
            }, React.createElement("h1", {
                  className: styles.headline
                }, heading$p(match.ctx)), React.createElement(Schedule_HeaderSwitch.make, {
                  view: view
                }), React.createElement(Optional.make, {
                  show: isNewSchedule,
                  children: null
                }, React.createElement(Schedule_HeaderInterval.make, {
                      view: view
                    }), React.createElement(Schedule_HeaderWarning.make, {})), React.createElement(Schedule_Header_Right.make, {}));
}

var make = React.memo(Schedule_Header);

export {
  make ,
}
/* heading' Not a pure module */
