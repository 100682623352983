import React from 'react'
import ymaps from './ymaps'
import { eventsDecorator, supportEvents } from '../events'
import { GEOLOCATION_OPTIONS } from '../constants'
import { MapEngineContext } from './map'

class MarkerEngine {
  createMarker(coordinates, props = {}, options = {}) {
    return new Promise((resolve) => {
      ymaps
        .getApi()
        .geolocation.get(GEOLOCATION_OPTIONS)
        .done((location) => {
          if (coordinates === null) {
            coordinates = location.geoObjects.position
          }

          this.coordinates = coordinates
          this.props = props
          this.options = options

          this.marker = new (ymaps.getApi().Placemark)(
            coordinates,
            options,
            null
          )

          this.setProps()
          this.setOptions()
          this.events = this.marker.events.group()

          resolve(location.geoObjects.position)
        })
    })
  }

  setProp(name, value) {
    this.marker.properties.set(name, value)
  }

  setProps() {
    const { props } = this
    Object.keys(props).forEach((name) => this.setProp(name, props[name]))
  }

  setOption(name, value) {
    this.marker.options.set(name, value)
  }

  setOptions() {
    const { options } = this
    Object.keys(options).forEach((name) => this.setOption(name, options[name]))
  }

  setCoordinates(coordinates) {
    this.marker.geometry.setCoordinates(coordinates.map(Number))
  }

  setEvent(name, cb) {
    this.events.events.add(name, cb)
  }

  destroy() {
    this.events.removeAll()
    this.marker.setParent(null)
    this.marker = null
  }
}

class Marker extends React.Component {
  static contextType = MapEngineContext

  componentDidMount() {
    const { coordinates, properties, options } = this.props

    this.engine = new MarkerEngine()
    this.engine
      .createMarker(coordinates, properties, options)
      .then((coordinates) => {
        this.defaultCoordinates = coordinates
        this.setupEvents()
        this.context.mapEngine.addMarker(this.engine)
      })
  }

  componentWillReceiveProps(nextProps) {
    const { coordinates, properties, options } = nextProps

    this.updateCoordinates(coordinates)
    this.updateProperties(properties)
    this.updateOptions(options)
  }

  updateCoordinates(coordinates) {
    if (coordinates === null) {
      this.engine.setCoordinates(this.defaultCoordinates)
      this.context.mapEngine.setCenterSmoothly(this.defaultCoordinates)
    } else {
      const currentCoordinates =
        this.props.coordinates !== null
          ? this.props.coordinates
          : this.defaultCoordinates

      if (
        coordinates[0] !== currentCoordinates[0] ||
        coordinates[1] !== currentCoordinates[1]
      ) {
        this.engine.setCoordinates(coordinates)
      }
    }
  }

  updateProperties(properties = {}) {
    const { properties: currentProperties = {} } = this.props

    Object.keys(properties).forEach((name) => {
      if (!currentProperties || properties[name] !== currentProperties[name]) {
        this.engine.setProp(name, properties[name])
      }
    })
  }

  updateOptions(options = {}) {
    const { options: currentOptions = {} } = this.props

    Object.keys(options).forEach((name) => {
      if (!currentOptions || options[name] !== currentOptions[name]) {
        this.engine.setOption(name, options[name])
      }
    })
  }

  shouldComponentUpdate() {
    return false
  }

  componentWillUnmount() {
    this.engine.destroy()
  }

  getEngine() {
    return this.engine ? this.engine : null
  }

  render() {
    return null
  }
}

Marker.defaultProps = {
  coordinates: null,
  properties: {},
  options: {
    preset: 'islands#dotIcon',
    iconColor: '#5ebd5e',
    draggable: true,
  },
}

export default eventsDecorator(Marker, supportEvents['yandex']['marker'])
