

import * as Fun from "../../libraries/Fun.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as QueryUtils from "../../utils/QueryUtils.mjs";

var fileNotFound$p = Utils.Translations.t("js.errors.files.file_not_found");

var fileIsLoading$p = Utils.Translations.t("js.errors.files.file_is_loading");

function parseErrorFromQuery(params) {
  var match = QueryUtils.query(params, "file_upload_error");
  if (match === undefined) {
    return ;
  }
  switch (match) {
    case "file_is_loading" :
        return "FileIsLoading";
    case "file_not_found" :
        return "FileNotFound";
    default:
      return ;
  }
}

function getErrorMessage(wire, params) {
  var match = Fun.both(wire, parseErrorFromQuery(params));
  if (match === undefined) {
    return ;
  }
  var wire$1 = match[0];
  if (match[1] === "FileNotFound") {
    return fileNotFound$p(wire$1.ctx);
  } else {
    return fileIsLoading$p(wire$1.ctx);
  }
}

export {
  getErrorMessage ,
}
/* fileNotFound' Not a pure module */
